export interface ChecklistDetails {
  id: number;
  name: string;
  createdAt: string;
  status: ChecklistStatusType;
  responsible?: string;
  comment?: string;
}

export interface ChecklistQuestions {
  moduleId: number;
  questions: {
    questionId: number;
    question: string;
    isRequired: boolean;
    answerType: string;
    answerOptions?: string[];
  }[];
}

export interface ChecklistMobileQuestions extends ChecklistQuestions {
  shippingName: string;
  mainBoard: string;
  secondBoard?: string;
  thirdBoard?: string;
}

export interface ChecklistAnswer {
  moduleId: number;
  name: string;
  status: ChecklistStatusType;
  checklistModule: string;
  answers: {
    questionId: number;
    question: string;
    isRequired: boolean;
    answer?: string;
    answerType: string;
    answerOptions?: string[];
  }[];
  comment?: string;
}

export interface ChecklistMobileAnswer extends ChecklistAnswer {
  shippingName: string;
  mainBoard: string;
  secondBoard?: string;
  thirdBoard?: string;
}

export type ChecklistStatusType = 'Aprovado' | 'Reprovado' | 'Aprovado com Restrição' | '';

export const answerInitialValues: ChecklistAnswer = {
  moduleId: 0,
  name: '',
  status: '',
  checklistModule: 'schedule',
  answers: [
    {
      questionId: 0,
      question: '',
      isRequired: false,
      answer: '',
      answerType: '',
      answerOptions: [''],
    },
  ],
  comment: '',
};

export const answerMobileInitialValues: ChecklistMobileAnswer = {
  moduleId: 0,
  name: '',
  status: '',
  checklistModule: '',
  answers: [
    {
      questionId: 0,
      question: '',
      isRequired: false,
      answer: '',
      answerType: '',
      answerOptions: [''],
    },
  ],
  comment: '',
  shippingName: '',
  mainBoard: '',
};
