import API from 'utils/API/API';

export const downloadPalletPDF = async (palletId: number[]): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.post(
      '/v2/pallet/pdf',
      { palletId },
      {
        responseType: 'arraybuffer',
      },
    );
    return data;
  } catch (error) {
    throw new Error('Ocorreu um erro ao fazer o download do PDF');
  }
};
