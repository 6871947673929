import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TransactionModalTable } from './content-table/list';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { TransactionListParams } from 'pages-v2/transactions/types/list';
import { transactionsListHook } from 'pages-v2/transactions/hooks/list';
import { IBranch } from 'pages/fiscal-document/models';
import { CalcFinanceList } from 'domain-v2/transactions';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';
import { initialPageInfo } from 'domain-v2/inputs/page-info';
import { ICompany } from 'domain/company';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { FooterPage } from 'components-v2/common/footer-page';

interface FreightPayableListProps {
  company: ICompany;
  branch?: IBranch;
  invoices: Array<ConnectedInvoices>;
  handleAddTransaction: (invoice: Array<ConnectedInvoices>) => void;
  handleClose: () => void;
}

export const defaultFilter = {
  page: 1,
  activeTabName: 'A Faturar',
  order: 'DESC',
  column: 'Data Entrega',
  financialStatus: 'A Faturar',
  search: '',
  limit: 8,
} as TransactionListParams;

export const TransactionListModal: React.FC<FreightPayableListProps> = ({ handleAddTransaction, handleClose }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [ids, setIds] = useState<Array<number>>([]);
  const [scheduleIds, setScheduleIds] = useState<Array<number>>([]);

  const [companyNames, setCompanyNames] = useState<Array<string>>([]);

  const [total, setTotal] = useState<Array<number>>([]);
  const [transactions, setTransactions] = useState<Array<ConnectedInvoices>>([]);

  const [checkboxAllState, setCheckBoxAllState] = useState<boolean>(false);
  const [alterChecked, setAlterChecked] = useState(0);

  const navigate = useNavigate();

  const handleSelectedFreight = (id: string) => navigate(`/transacoes/${id}`);

  const { isLoading, data } = useQuery({
    queryKey: ['transactionList', currentPage],
    queryFn: () =>
      transactionsListHook({
        ...defaultFilter,
        page: currentPage,
      }),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const handleCheckAllBoxes = (clicked: boolean, freight: Array<CalcFinanceList>) => {
    if (clicked) {
      const filteredFreight = freight.filter(
        (item) =>
          item.tag !== 'compra' && item.financialStatus !== 'Faturado' && item.financialStatus !== 'Em Auditoria',
      );

      const filteredIds = filteredFreight.map((item) => item.id);

      const filteredScheduleIds = filteredFreight.map((item) => item.schedule.id);
      const filteredSchedule = filteredFreight.map((item) => item.schedule);

      const filteredTotalData = filteredFreight.map((item) => item.totalService);
      const filteredNamesData = filteredFreight.map((item) => item.taker);
      const filteredInvoicedData = filteredFreight.map((item) => item.invoicedBy);

      const uniqueIdsData = filteredIds.filter((item) => !ids.includes(item));
      const uniqueScheduleIdsData = filteredScheduleIds.filter((item) => !scheduleIds.includes(item));

      const uniqueNamesData = filteredNamesData.filter((item) => !companyNames.includes(item));
      const uniqueTotalData = filteredTotalData.filter((item) => !total.includes(item));

      setCompanyNames([...companyNames, ...uniqueNamesData]);
      setTotal([...total, ...uniqueTotalData]);
      setIds([...ids, ...uniqueIdsData]);
      setScheduleIds([...scheduleIds, ...uniqueScheduleIdsData]);

      const newTransactions = uniqueIdsData.map((id, index) => ({
        id: 0,
        invoiceId: 0,
        schedule: filteredSchedule[index],
        calcFinance: {
          id: uniqueIdsData[index],
          taker: uniqueNamesData[index],
          invoicedBy: filteredInvoicedData[index],
          totalService: uniqueTotalData[index],
        },
      }));

      setTransactions([...transactions, ...newTransactions]);

      return;
    }

    for (const item of freight) {
      const idArray = [item.id];
      const scheduleIdsArray = [item.id];

      const totalArray = freight.map((item) => item.totalService);
      const companyName = item.schedule?.companyBuyer?.id
        ? [item.schedule?.companyBuyer?.name]
        : [item.schedule.company.tradeName];

      setIds((prevIds) => prevIds.filter((id) => !idArray.includes(id)));
      setScheduleIds((prevIds) => prevIds.filter((id) => !scheduleIdsArray.includes(id)));

      setTotal((prevIds) => prevIds.filter((id) => !totalArray.includes(id)));

      setCompanyNames((prevNames) => {
        const remainingNames = [...prevNames];
        for (const n of companyName) {
          const index = remainingNames.indexOf(n);
          if (index !== -1) {
            remainingNames.splice(index, 1);
            break;
          }
        }
        return remainingNames;
      });

      setTransactions([]);
    }
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    scheduleIds: Array<number>,
    ids: Array<number>,
    name: Array<string>,
    price: Array<number>,
    companyName: string,
    schedule: any,
    transaction: CalcFinanceList,
  ) => {
    const { checked } = event.target;
    if (checked) {
      setIds((prevIds) => Array.from(new Set([...prevIds, ...ids])));
      setScheduleIds((prevIds) => Array.from(new Set([...prevIds, ...scheduleIds])));
      setCompanyNames((prevNames) => [...prevNames, ...name]);
      setTotal((prevTotal) => [...prevTotal, ...price]);

      const newTransactions = ids.map((id) => ({
        id: 0,
        invoiceId: 0,
        schedule: schedule,
        calcFinance: {
          id: id,
          taker: transaction.taker,
          invoicedBy: transaction.invoicedBy,
          totalService: transaction.totalService,
        },
      }));

      setTransactions([...transactions, ...newTransactions]);
    } else {
      setIds((prevIds) => prevIds.filter((id) => !ids.includes(id)));
      setScheduleIds((prevIds) => prevIds.filter((id) => !scheduleIds.includes(id)));
      setCompanyNames((prevNames) => {
        const remainingTotal = [...prevNames];

        for (const n of name) {
          const index = remainingTotal.indexOf(n);
          if (index !== -1) {
            remainingTotal.splice(index, 1);
            break;
          }
        }
        return remainingTotal;
      });

      setTotal((prevTotal) => {
        const remainingTotal = [...prevTotal];
        for (const n of price) {
          const index = remainingTotal.indexOf(n);
          if (index !== -1) {
            remainingTotal.splice(index, 1);
            break;
          }
        }
        return remainingTotal;
      });

      setTransactions([]);
    }
  };

  const handleClearSelection = useCallback(() => {
    setCompanyNames([]);
    setIds([]);
    setScheduleIds([]);
    setTotal([]);
  }, []);

  useEffect(() => {
    if (data?.items) {
      handleCheckAllBoxes(checkboxAllState, data?.items);
    }
  }, [alterChecked]);

  return (
    <ModalBark size={'lg'} title={'Selecione uma Transação'} handleClose={handleClose}>
      <div style={{ height: 500, overflow: 'hidden' }}>
        <TransactionModalTable
          transactions={data?.items || []}
          pageInfo={data?.meta || initialPageInfo}
          isLoading={isLoading}
          selectedIds={ids}
          changePage={setCurrentPage}
          handleSelectedFreight={handleSelectedFreight}
          handleCheckboxAll={(clicked) => {
            setCheckBoxAllState(clicked), setAlterChecked((initial) => initial + 1);
          }}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button
            label="Adicionar"
            color="blue"
            onPress={() => handleAddTransaction(transactions)}
            disabled={ids.length < 1}
          />
          <FooterPage.Button
            label="Limpar Seleção"
            color="pink"
            onPress={handleClearSelection}
            disabled={ids.length < 1}
          />

          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <div
              style={{
                fontSize: '11.5px',
              }}
            >
              Total Selecionado(s)
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {convertCurrencyFormat.format(Number(total.reduce((initial, current) => initial + current, 0) + 0.001))}
            </div>
          </div>
        </FooterPage.RightContent>
      </FooterPage.Root>
    </ModalBark>
  );
};
