import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IGetSaleOfferValues } from './types/use-get-sale-offer-values.types';
import { ISaleOfferValuesDomain } from 'domain-v2/schedule/cost';
import { getSaleOfferValueService } from 'pages-v2/schedule/services/get-sale-offer-value/get-sale-offer-value.service';

export const useGetSaleOfferValue = () => {
  return useCallback(async (values: IGetSaleOfferValues): Promise<ISaleOfferValuesDomain | undefined> => {
    try {
      return getSaleOfferValueService(values);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao obter valores da oferta de venda.',
        type: 'error',
      });
    }
  }, []);
};
