import React, { useMemo } from 'react';

import VehicleRegisterFormView from './vehicle-register';
import AllocationModalTable from './table';
import * as S from './styled';

import DriverRegisterForm from 'pages/driver/driver-register/components/driver-register-form/driver-register-form';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface AllocationModalListProps {
  name: string;
  title: string;
  contentTable?: IContentTable[];
  headerTable?: IContentTable;
  pageInfo?: PageInfo;
  isLoading?: boolean;
  isModalRegister?: boolean;
  handleClose: () => void;
  changePage?: (page: number) => void;
  handleChangeSearch?: (e: string) => void;
  handleRegisterModal?: () => void;
  handleSelectCurrentLine: (id: string) => void;
}

const AllocationModalList: React.FC<AllocationModalListProps> = ({
  name,
  title,
  contentTable = [] as IContentTable[],
  headerTable = {} as IContentTable,
  pageInfo,
  isLoading = false,
  isModalRegister,
  changePage = () => null,
  handleClose,
  handleChangeSearch = () => null,
  handleRegisterModal,
  handleSelectCurrentLine,
}) => {
  const modalForm = useMemo(() => {
    if (isModalRegister && name === 'vehicle') {
      return (
        <VehicleRegisterFormView handleCloseRegister={handleClose} handleSelectCurrentLine={handleSelectCurrentLine} />
      );
    }
    if (isModalRegister && name === 'driver') {
      return <DriverRegisterForm handleCloseRegister={handleClose} handleSelectCurrentLine={handleSelectCurrentLine} />;
    }

    return (
      <AllocationModalTable
        name={name}
        contentTable={contentTable}
        headerTable={headerTable}
        pageInfo={pageInfo}
        changePage={changePage}
        isLoading={isLoading}
        handleChangeSearch={handleChangeSearch}
        handleSelectCurrentLine={handleSelectCurrentLine}
        handleRegisterModal={handleRegisterModal}
      />
    );
  }, [name, isModalRegister, contentTable]);

  return (
    <S.WrapperStyled>
      <S.ContainerStyled>
        <S.WrapperHeaderStyled>
          <div>
            <S.DescriptionStyled>{isModalRegister ? 'Cadastrar' : 'Selecionar'}</S.DescriptionStyled>
            <S.TitleStyled>{title}</S.TitleStyled>
          </div>

          <S.CloseButtonStyled onClick={handleClose}>
            <S.DescriptionStyled>{'fechar'}</S.DescriptionStyled>
          </S.CloseButtonStyled>
        </S.WrapperHeaderStyled>

        {modalForm}
      </S.ContainerStyled>
    </S.WrapperStyled>
  );
};

export default AllocationModalList;
