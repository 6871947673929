import API from 'utils/API/API';

export const updateOthersDocumentsDataService = async (scheduleId: number, files: FileList, statusCode: string) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  formData.append('statusCode', statusCode);

  await API.post(`v2/schedules/status/file-upload/${scheduleId}`, formData);
};
