import { Button } from 'logshare-ui-kit';
import { FC, useMemo } from 'react';
import { ISaleOfferFormRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { SaleOfferFormMountTariff } from '../sale-offer-register-form-mount-tariff/sale-offer-register-form-mount-tariff.component';
import * as S from './sale-offer-register-form-mount-section-view.styled';

interface ISaleOfferFormMountSectionViewProps {
  values: ISaleOfferFormRegisterPayloadDomain;
  handleOpenOfferShipperTariffModal: (index: number) => void;
}

export const SaleOfferFormMountSectionView: FC<ISaleOfferFormMountSectionViewProps> = ({
  values,
  handleOpenOfferShipperTariffModal,
}) => {
  return useMemo(() => {
    return (
      <S.Main>
        {values.saleOfferDetails.map((saleShipper, index) => {
          const verifyTariff = saleShipper.tariff.length === 0;
          const firstSection = index === 0;

          const buttonModalTariffOpen = (
            <Button
              label="Cadastrar Tarifa"
              color="green"
              onPress={() => handleOpenOfferShipperTariffModal(index)}
              size="md"
              variant="solid"
            />
          );

          return (
            <S.Wrapper key={index}>
              <S.WrapperHeader>
                <S.WrapperHeaderShipperInfo>
                  <S.Text>
                    {firstSection ? saleShipper.partnerShipper.name.split('Embarcadores', 1) : 'Exclusiva para'}
                  </S.Text>
                  <S.Strong>
                    {firstSection
                      ? saleShipper.partnerShipper.name.split('Para todos')[1].toUpperCase()
                      : saleShipper.partnerShipper.name.toUpperCase()}
                  </S.Strong>
                </S.WrapperHeaderShipperInfo>
              </S.WrapperHeader>

              <S.WrapperBody verifyTariff={verifyTariff}>
                <S.Text fontSize={0.8} color="#929292">
                  {verifyTariff ? (
                    'Não há tarifas cadastradas'
                  ) : (
                    <S.WrapperTariffs>
                      <SaleOfferFormMountTariff saleOfferShipperIndex={index} />
                    </S.WrapperTariffs>
                  )}
                </S.Text>
                {verifyTariff && <S.WrapperButton>{buttonModalTariffOpen}</S.WrapperButton>}
              </S.WrapperBody>
              {!verifyTariff && <S.WrapperButton>{buttonModalTariffOpen}</S.WrapperButton>}
            </S.Wrapper>
          );
        })}
      </S.Main>
    );
  }, [handleOpenOfferShipperTariffModal, values.saleOfferDetails]);
};
