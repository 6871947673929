/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  LabelStyled,
  MessageErrorStyled,
  TextareaStyled,
  TextareaWrapperStyled,
  WrapperStyled,
} from './input-textarea.styled';

export type InputTextareaProps = {
  id: string;
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: any;
};

const InputTextareaView: React.FC<InputTextareaProps> = ({
  id,
  name,
  value,
  label = '',
  placeholder = '',
  disabled = false,
  hasError = false,
  errorMessage = 'Invalid value',
  onChange,
}) => {
  return (
    <WrapperStyled>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <TextareaWrapperStyled disabled={disabled} hasError={hasError}>
        <TextareaStyled
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          hasError={hasError}
        />
      </TextareaWrapperStyled>
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};

export default InputTextareaView;
