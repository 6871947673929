import { useCallback } from 'react';

import { driverDeleteService } from 'pages/driver/services/driver-delete/driver-delete.service';

const useDeleteDriverHook = () => {
  return useCallback(async (ids: number[]): Promise<boolean> => {
    return await driverDeleteService(ids);
  }, []);
};

export { useDeleteDriverHook };
