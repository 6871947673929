import { ScheduleRequestStatusActionType } from '../../schedule.action';
import { IScheduleRequestStatusActions } from '../../types/schedule-request-status.types';
import { IScheduleContext } from '../../types/schedule.types';

export const scheduleRequestStatusReducer = (
  state: IScheduleContext,
  action: IScheduleRequestStatusActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleRequestStatusActionType.SCHEDULE_REQUEST_STATUS:
      return {
        ...state,
        scheduleRequestStatus: {
          ...state.scheduleRequestStatus,
          statusRequest: 'PROCESSING',
        },
      };
    case ScheduleRequestStatusActionType.SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        scheduleRequestStatus: {
          ...state.scheduleRequestStatus,
          statusRequest: 'SUCCESS',
        },
      };
    case ScheduleRequestStatusActionType.SCHEDULE_REQUEST_ERROR:
      return {
        ...state,
        scheduleRequestStatus: {
          ...state.scheduleRequestStatus,
          statusRequest: 'ERROR',
        },
      };
    case ScheduleRequestStatusActionType.SCHEDULE_REQUEST_RESET:
      return {
        ...state,
        scheduleRequestStatus: {
          ...state.scheduleRequestStatus,
          statusRequest: 'INITIAL',
        },
      };
    default:
      return state;
  }
};
