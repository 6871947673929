import { useCallback } from 'react';

import { acceptedMatchesDelete } from 'pages/accepted-matches/service/accepted-matches-delete/accepted-matches-delete.service';
import { CheckDeleteResponse } from 'domain/global';

export const useAcceptedMatchesDelete = () => {
  return useCallback(async (ids: number[]): Promise<CheckDeleteResponse[]> => {
    return await acceptedMatchesDelete(ids);
  }, []);
};
