/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { ContainerInfoStyle, ContainerStyled, DescriptionStyled, StatusStyled } from './style';
import { RenderFileUploadedData } from './render-uploaded-file-data';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { FileListUploaded } from 'pages/freight/types';
import { useHandleGetFileUploadedListHook, useHandleUploadFileFileHook } from 'pages/digital-certificate/hooks';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';
import { ShieldReleasedIcon } from 'assets/icons/shield-released.icon';
import { ShieldBlockedIcon } from 'assets/icons/shield-blocked.icon';

export const DocumentCertificate: React.FC = () => {
  const { user } = useContext(UserContext);

  const id = user?.companyId;

  const handleUploadFileHook = useHandleUploadFileFileHook();
  const handleGetFileUploadListHook = useHandleGetFileUploadedListHook();

  const [fileUploadList, setFileUploadList] = useState<FileListUploaded[]>();

  const handleUpdateStatusCteData = useCallback(async () => {
    if (id) {
      const [responseFileUploadList] = await Promise.resolve([await handleGetFileUploadListHook(id)]);

      setFileUploadList(responseFileUploadList);
    }
  }, [id]);

  const handleUpdateCteData = useCallback(async () => {
    if (!id) return;

    handleUpdateStatusCteData();
  }, [id, handleUpdateStatusCteData]);

  const handleChangeFile = async (value: any) => {
    if (id) {
      await handleUploadFileHook(value, id);
      await handleUpdateCteData();
    }
  };
  useEffect(() => {
    handleUpdateCteData();
  }, [handleUpdateCteData]);

  const handleOnExclude = useCallback(async () => {
    await handleUpdateCteData();
  }, [handleUpdateCteData]);

  const renderFilesList = useMemo(() => {
    const list: any[] = [];

    if (fileUploadList && fileUploadList.length > 0) {
      fileUploadList?.forEach((file, index) => {
        list.push(
          <>
            <ShieldReleasedIcon />
            <StatusStyled>Certificado Cadastrado</StatusStyled>
            <DescriptionStyled>
              Certificado válido até <span />
              {moment().add(1, 'years').format('DD/MM/YYYY')}
            </DescriptionStyled>

            {fileUploadList.length === 1 ? (
              <Button
                title="Atualizar Certificado"
                bgColor="green"
                callback={() =>
                  showMessageFormatted({
                    message: 'Delete o certificado existente para atualizar.',
                    type: 'error',
                  })
                }
                size="large"
              />
            ) : (
              <InputFile onChange={handleChangeFile} pfxOnly={true}>
                <Button title="Atualizar Certificado" bgColor="green" size="large" />
              </InputFile>
            )}

            <RenderFileUploadedData key={file.id} index={index + 1} file={file} onSuccess={handleUpdateCteData} />
          </>,
        );
      });
    }

    if (list.length === 0)
      return (
        <>
          <ShieldBlockedIcon />
          <StatusStyled>Pendente</StatusStyled>
          <DescriptionStyled>Você ainda não possui nenhum certificado digital configurado.</DescriptionStyled>
          <InputFile onChange={handleChangeFile} pfxOnly={true}>
            <Button title="Adicionar Certificado" bgColor="blue" size="large" />
          </InputFile>
        </>
      );

    return list;
  }, [fileUploadList, handleOnExclude]);

  return (
    <ContainerStyled>
      <ContainerInfoStyle>{renderFilesList}</ContainerInfoStyle>
    </ContainerStyled>
  );
};
