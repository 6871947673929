import { ISaleOfferListFilterDomain } from '../domains/sale-offer-list-filters.domain';

export const saleOfferListFilterInitialValues: ISaleOfferListFilterDomain = {
  search: '',
  page: 1,
  limitPage: 50,
  orderBy: 'DESC',
  column: '',
  isActive: true,
  originUf: '',
  originCity: '',
  destinationCity: '',
  destinationUf: '',
  vehicleType: '',
  vehicleCategory: '',
  operationType: '',
};
