import { ThemeColorsV2Type } from 'styles/types/colors';
import { IOption } from 'domain/global-inputs';
import {
  canceledStatus,
  finishedStatus,
  onGoingStatus,
  programmingStatus,
  prospectedStatus,
  startingStatus,
  status,
  programmingStatusFilter,
  startingStatusFilter,
  onGoingStatusFilter,
  canceledStatusFilter,
  statusFilter,
} from './schedule-tab-name.constants';
import { ITabContent } from './schedule-tab-name.types';
import { IActiveTabName } from '../../types/schedule-list-filters/schedule-list-filters.types';
import { TabNames } from '../../types/schedule-tab-names/schedule-tab-names.types';
import { IScheduleListTotalRow } from '../../types/schedule-list-total-row/schedule-list-total-row.types';
import { ReactElement } from 'react';

const tabColors = [
  ThemeColorsV2Type.Blue,
  ThemeColorsV2Type.Blue50,
  ThemeColorsV2Type.Aqua50,
  ThemeColorsV2Type.Aqua,
  ThemeColorsV2Type.Blue,
  ThemeColorsV2Type.DarkGray,
  ThemeColorsV2Type.Red,
];

const tabsName = Object.values(TabNames);

export const tabStatusCode = (activeTabName: string) => {
  const tabNameStatusCode: { [key: string]: string[] } = {
    [TabNames.Em_Alocacao]: programmingStatus,
    [TabNames.A_Iniciar]: startingStatus,
    [TabNames.Em_Andamento]: onGoingStatus,
    [TabNames.Finalizados]: finishedStatus,
    [TabNames.Prospectados]: prospectedStatus,
    [TabNames.Todos]: status,
    [TabNames.Cancelados]: canceledStatus,
  };

  return tabNameStatusCode[activeTabName] ?? startingStatus;
};

export const tabContentName = (content: ReactElement, isAdmin: boolean, totalRow: IScheduleListTotalRow) => {
  const tabContent: Array<ITabContent> = tabsName
    .filter((name) => name !== 'Prospectados' || (name === 'Prospectados' && isAdmin))
    .map((name: TabNames, index: number) => ({ name, content, color: tabColors[index], totalRow: totalRow[name] }));

  return tabContent;
};

export const getActiveTabName = (): { [key in IActiveTabName]: string } => ({
  'Em Alocação': 'Em Alocação',
  Programadas: 'Programadas',
  Cancelados: 'Cancelados',
  'A Iniciar': 'A Iniciar',
  'Em Andamento': 'Em Andamento',
  Finalizados: 'Finalizados',
  Prospectados: 'Prospectados',
  Todos: 'Todos',
});

export const convertStatusCodeOptions = (activeTabName: IActiveTabName): IOption[] => {
  const statusCodeFilterOptions: { [key: string]: IOption[] } = {
    [TabNames.Em_Alocacao]: programmingStatusFilter,
    [TabNames.A_Iniciar]: startingStatusFilter,
    [TabNames.Em_Andamento]: onGoingStatusFilter,
    [TabNames.Todos]: statusFilter,
    [TabNames.Cancelados]: canceledStatusFilter,
  };

  return statusCodeFilterOptions[activeTabName];
};
