/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext } from 'react';

import { RegisterCompanySchema } from '../company-register-schemas-validation';

import FormRegisterCompanyView from './company-register-form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useRegisterCompanyHook } from 'pages/company/hooks/company-register/company-register.hook';
import {
  PATH_TO_LIST_COMPANIES,
  initialValues,
  optionsPlan,
  optionsStatus,
  optionsRiskManager,
} from 'pages/company/company-update/components/company-update-form/form-values/form-values';
import { useCepHook } from 'hooks/cep/cep.hook';
import { ICompany } from 'domain/company';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const FormRegisterCompany: React.FC = () => {
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const registerCompany = useRegisterCompanyHook();
  const cepHook = useCepHook();

  const [formValues, setFormValues] = React.useState<ICompany>(initialValues);

  const goBackToListCompanies = useCallback(() => navigate(PATH_TO_LIST_COMPANIES), [navigate]);

  const handleSubmit = useCallback(
    async (company: ICompany) => {
      try {
        setFormValues(company);

        setLoading(true);

        await registerCompany(company);

        showMessageFormatted({ message: TOAST_MESSAGE.REGISTER, type: 'success' });

        goBackToListCompanies();
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [registerCompany, goBackToListCompanies],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      const address = await cepHook(cep);
      return address;
    },
    [cepHook],
  );

  return (
    <FormRegisterCompanyView
      optionsPlan={optionsPlan}
      optionsStatus={optionsStatus}
      optionsRiskManager={optionsRiskManager}
      initialValues={formValues}
      handleGoBack={goBackToListCompanies}
      handleRegister={handleSubmit}
      validationSchema={RegisterCompanySchema}
      onBlurCep={onBlurCep}
    />
  );
};

export default FormRegisterCompany;
