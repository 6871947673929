import React from 'react';

import LocationDetailsForm from '../location-details-form/location-details-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const LocationDetailsBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="location" breadcrumbList={['Cadastro', 'Unidades']} hasBackButton />
      <Title title="Atualização Cadastral" hasTrace isForm />
      <LocationDetailsForm />
    </React.Fragment>
  );
};

export default LocationDetailsBodyView;
