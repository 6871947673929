import API from 'utils/API/API';
import { convertScheduleLTLQuotation } from 'pages-v2/schedule-ltl/mappers/convert-quotation';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';
import { ITagsQuotation } from 'domain-v2/schedule/quotation';

export const quotationScheduleLTLService = async (payload: ScheduleLTLRegisterInput, tags: ITagsQuotation[]) => {
  const { data } = await API.post('/v3/schedule/1/quottion', convertScheduleLTLQuotation(payload, tags), {
    responseType: 'blob',
  });

  return data;
};
