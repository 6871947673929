import styled, { css } from 'styled-components';

export const LabelStyled = styled.div`
  ${() => css`
    padding-left: 3px;
    color: #000;
    font-family: Montserrat, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const InfoContainerStyled = styled.div`
  display: flex;
  padding-top: 3px;
`;

export const InfoIconTitleContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2px;
`;

export const InfoIconContentTooltipStyled = styled.div``;

export const InfoIconLabelStyled = styled.p``;
