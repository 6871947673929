/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'utils/API/API';
import { FileListUploaded } from 'pages/freight/types';
import { showToast } from 'components/toast/toast';

export const uploadFiles = async (companyId: number | string, files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'FOTOS/REGISTROS');
    formData.append('module', 'company');
    formData.append('codigo', String(companyId));

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Anexo',
    });
    throw new Error('Houve um erro ao realizar o upload do Anexo.');
  }
};

export const listUploadedFiles = async (companyId: number | string): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>(`v2/file/management?module=company&type=FOTOS/REGISTROS&codigo=${companyId}`);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao listar o Anexo',
    });
    throw new Error('Houve um erro ao listar o Anexo');
  }
};

export const deleteUploadedFiles = async (companyId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${companyId}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o Anexo',
    });
    throw new Error('Houve um erro ao excluir o Anexo.');
  }
};
