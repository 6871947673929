import React from 'react';
import { FormShippingCompany } from '../components/form';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { RegistrationsContextProvider } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

export const ShippingCompanyUpdatePage: React.FC = () => {
  return (
    <RegistrationsContextProvider>
      <HeaderPage breadcrumbList={['Cadastro', 'Transportadoras']} hasBackButton />
      <Title title="Atualização Cadastral" hasTrace isForm />
      <FormShippingCompany />
    </RegistrationsContextProvider>
  );
};
