import { InactiveMatchesKanbanListActionType } from '../../matches.action';
import { IInactiveMatchesKanbanListActions } from '../../types/inactive-matches-kanban.types';
import { IMatchesContext } from '../../types/matches.types';

export const inactiveMatchesKanbanListReducer = (
  state: IMatchesContext,
  action: IInactiveMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_REQUEST:
      return {
        ...state,
        inactiveMatchesKanbanList: {
          ...state.inactiveMatchesKanbanList,
          statusRequest: 'PROCESSING',
          inactiveKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_SUCCESS:
      return {
        ...state,
        inactiveMatchesKanbanList: {
          ...state.inactiveMatchesKanbanList,
          statusRequest: 'SUCCESS',
          inactiveKanbanList: action.payload,
        },
      };
    case InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_ERROR:
      return {
        ...state,
        inactiveMatchesKanbanList: {
          ...state.inactiveMatchesKanbanList,
          statusRequest: 'ERROR',
          inactiveKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_RESET:
      return {
        ...state,
        inactiveMatchesKanbanList: {
          ...state.inactiveMatchesKanbanList,
          statusRequest: 'INITIAL',
          inactiveKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    default:
      return state;
  }
};
