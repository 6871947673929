import React from 'react';
import LocationRegisterBody from './components/location-register-body/location-register-body';
import { RegistrationsContextProvider } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

const LocationRegisterPage: React.FC = () => {
  return (
    <RegistrationsContextProvider>
      <LocationRegisterBody />
    </RegistrationsContextProvider>
  );
};

export default LocationRegisterPage;
