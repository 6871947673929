import API from 'utils/API/API';
import { IScheduleDetailsResponse } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-schedule-bidding-by-id/get-schedule-bidding-by-id.types';
import { converterScheduleDetailsResponseToDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-schedule-bidding-by-id/mappers/converter-schedule-details-response-to-domain/converter-schedule-details-response-to-domain.mapper';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { AxiosError } from 'axios';

export const getScheduleByIdService = async (id: string): Promise<IScheduleDetailsDomain | undefined> => {
  try {
    const { data } = await API.get<IScheduleDetailsResponse>(`v3/schedules/${id}`);
    return converterScheduleDetailsResponseToDomain(data);
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 400) {
      return undefined;
    }
    throw error;
  }
};
