import { IScheduleDetailsAllocationShippingDomain } from '../../../../domains/schedule-details-allocation-shipping.domain';
import { IScheduleDetailsAllocationShippingResponse } from '../../get-shipping-list.types';

export const converterScheduleDetailsAllocationShippingResponseToDomain = (
  scheduleDetailAllocationShippingsResponse: IScheduleDetailsAllocationShippingResponse,
): IScheduleDetailsAllocationShippingDomain => {
  return {
    id: scheduleDetailAllocationShippingsResponse.id,
    name: scheduleDetailAllocationShippingsResponse.name,
    cnpj: scheduleDetailAllocationShippingsResponse.cnpj,
  };
};
