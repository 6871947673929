import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { TransactionListParams } from 'pages-v2/transactions/types/list';
import { defaultFilter } from 'pages-v2/transactions/constants/list';
import { UserContext } from 'state/user-context';

interface FiltersContextProps {
  filters: TransactionListParams;
  resetFilters: (hard?: boolean, toKeep?: Partial<TransactionListParams>) => void;
  updateFilters: (filter: TransactionListParams) => void;
}

interface FiltersProviderProps {
  children: ReactNode;
}

const FiltersContext = createContext<FiltersContextProps>({} as FiltersContextProps);

const FiltersProvider: FC<FiltersProviderProps> = ({ children }) => {
  const { isShippingCompany } = useContext(UserContext);

  const initialFilters = useMemo(() => {
    const cachedFilters = sessionStorage.getItem('pagination-transaction-list');

    if (cachedFilters) return JSON.parse(cachedFilters);

    if (isShippingCompany) {
      return { ...defaultFilter, activeTabName: 'A Receber', financialStatus: 'receber transportador' };
    }

    return defaultFilter;
  }, [isShippingCompany]);

  const [filters, setFilter] = useState<TransactionListParams>(initialFilters);

  useEffect(() => {
    const cachedFilters = sessionStorage.getItem('pagination-transaction-list');

    if (cachedFilters) {
      setFilter(JSON.parse(cachedFilters));
    }
  }, []);

  const updateFilters = useCallback((filter: TransactionListParams) => {
    const taker = filter.taker ? filter.taker.split(' - ') : '';
    setFilter({ ...filter, taker: taker[0] });
    sessionStorage.setItem('pagination-transaction-list', JSON.stringify({ ...filter, taker: taker[0] }));
  }, []);

  const resetFilters = useCallback((hard?: boolean, toKeep?: Partial<TransactionListParams>) => {
    const filter = { ...defaultFilter, ...toKeep };

    if (hard) {
      setFilter(defaultFilter);
      return;
    }

    setFilter(filter);
  }, []);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        resetFilters,
        updateFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilter = () => {
  return useContext(FiltersContext);
};

export default FiltersProvider;
