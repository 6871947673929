import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IListPage } from '../../../../domain/page-info';
import { IDriver } from '../../../../domain/driver';

import { IDriversContext } from './drivers.types';
import { driversReducer } from './drivers.reducer';
import { IDriversAction, initialState } from './drivers.actions';

interface IDriversContextProps {
  children: ReactNode;
}

export const DriversContext = createContext<IDriversContext>(initialState);

const DriversContextProvider = ({ children }: IDriversContextProps) => {
  const [state, dispatch] = useReducer(driversReducer, initialState);

  const setDriversRequest = useCallback(() => {
    dispatch({
      type: IDriversAction.DRIVERS_REQUEST,
      payload: null,
    });
  }, []);

  const setDriversSuccess = useCallback((driverList: IListPage<IDriver>) => {
    dispatch({
      type: IDriversAction.DRIVERS_SUCCESS,
      payload: driverList,
    });
  }, []);

  const setDriversError = useCallback((error: string) => {
    dispatch({
      type: IDriversAction.DRIVERS_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      driversIsLoading: state.driversIsLoading,
      drivers: state.drivers,
      driversError: state.driversError,
      driversPageInfo: state.driversPageInfo,
      setDriversRequest,
      setDriversSuccess,
      setDriversError,
    };
  }, [
    setDriversError,
    setDriversRequest,
    setDriversSuccess,
    state.drivers,
    state.driversError,
    state.driversIsLoading,
    state.driversPageInfo,
  ]);

  return <DriversContext.Provider value={value}>{children}</DriversContext.Provider>;
};

export default React.memo(DriversContextProvider);
