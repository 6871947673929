import { IOption } from 'domain/global-inputs';

export type VehicleCategoryDomain =
  | ''
  | 'Baú Seco'
  | 'Baú Frigorificado'
  | 'Baú Refrigerado'
  | 'Sider'
  | 'Caçamba Basculante'
  | 'Cegonha'
  | 'Complemento'
  | 'Florestal'
  | 'Grade Baixa'
  | 'Graneleiro'
  | 'Plataforma'
  | 'Porta Container 20'
  | 'Porta Container 40'
  | 'Tanque'
  | 'NÃO MAPEADO';

export type VehicleCategoryResponse =
  | 'bau_seco'
  | 'bau_frigorificado'
  | 'bau_refrigerado'
  | 'bau_sider'
  | 'cacamba_basculante'
  | 'cegonha'
  | 'complemento'
  | 'florestal'
  | 'grade_baixa'
  | 'graneleiro'
  | 'plataforma'
  | 'porta_container'
  | 'porta_container_20'
  | 'porta_container_40'
  | 'porta_container40'
  | 'porta_container2'
  | 'tanque'
  | 'invalid'
  | '';

export const vehicleCategoryOptionsDomain: IOption[] = [
  {
    value: 'Caçamba Basculante',
    label: 'BASCULANTE',
  },
  { value: 'Baú Frigorificado', label: 'BAÚ FRIGORIFICADO' },
  { value: 'Baú Refrigerado', label: 'BAÚ REFRIGERADO' },
  { value: 'Baú Seco', label: 'BAÚ SECO' },
  { value: 'Cegonha', label: 'CEGONHA' },
  { value: 'Florestal', label: 'FLORESTAL' },
  { value: 'Grade Baixa', label: 'GRADE BAIXA' },
  { value: 'Graneleiro', label: 'GRANELEIRO' },
  { value: 'Plataforma', label: 'PLATAFORMA' },
  {
    value: 'Porta Container 20',
    label: 'PORTA CONTAINER 20',
  },
  {
    value: 'Porta Container 40',
    label: 'PORTA CONTAINER 40',
  },
  { value: 'Sider', label: 'SIDER' },
  { value: 'Tanque', label: 'TANQUE' },
];
