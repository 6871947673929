import { useNavigate } from 'react-router-dom';
import { FC, memo, useContext, useMemo } from 'react';
import { Button } from 'logshare-ui-kit';

import { UserContext } from 'state/user-context';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import CrownIcon from 'assets/icons/crown.icon';
import { EssencialButton } from './components/essencial-button';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface ModalNewFreightProps {
  handleCreateSchedulePage: (_args: boolean) => void;
}

const ModalNewFreight: FC<ModalNewFreightProps> = ({ handleCreateSchedulePage }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const isEssencial = useMemo((): boolean => user?.plan === 'basic-buyer' || user?.plan === 'basic-seller', [user]);

  const description = useMemo(() => {
    if (isEssencial) {
      return (
        <div style={{ display: 'flex', gap: 20 }}>
          <EssencialButton
            label="FTL"
            color={ThemeColorsV2Type.Blue}
            onClick={() => {
              handleCreateSchedulePage(false);
              navigate('/alocacao/novo');
            }}
            size="medium"
          />
          <EssencialButton
            label="STL"
            color={ThemeColorsV2Type.LightGray}
            onClick={() => {
              handleCreateSchedulePage(false);
              navigate('/alocacao-stl/novo');
            }}
            size="medium"
            icon={<CrownIcon />}
            disabled
          />
          <EssencialButton
            label="LTL"
            color={ThemeColorsV2Type.LightGray}
            onClick={() => {
              handleCreateSchedulePage(false);
              navigate('/alocacao-ltl/novo');
            }}
            size="medium"
            icon={<CrownIcon />}
            disabled
          />
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', gap: 20 }}>
        <Button
          label="FTL"
          color="blue"
          variant="solid"
          onPress={() => {
            handleCreateSchedulePage(false);
            navigate('/alocacao/novo');
          }}
          size="md"
        />
        <EssencialButton
          label="STL"
          color={ThemeColorsV2Type.LightGray}
          onClick={() => {
            handleCreateSchedulePage(false);
            navigate('/alocacao-stl/novo');
          }}
          size="medium"
          icon={<CrownIcon />}
          disabled
        />
        <Button
          label="LTL"
          color="aqua"
          variant="solid"
          onPress={() => {
            handleCreateSchedulePage(false);
            navigate('/alocacao-ltl/novo');
          }}
          size="md"
        />
      </div>
    );
  }, [handleCreateSchedulePage, isEssencial, navigate]);

  return (
    <ModalConfirmation
      title="Novo Agendamento"
      secondTitle="Escolha a modalidade do frete."
      description={description}
      handleXCancel={() => handleCreateSchedulePage(false)}
      legacy
      size="small"
      type="normal"
    />
  );
};

export default memo(ModalNewFreight);
