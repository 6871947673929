import { convertUserStatusEntityToDomain } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { IListPageUserEntity } from 'pages/user/services/entities/user.entity';
import { IListPageUser } from 'domain/user';

export const convertUserListEntityToDomainList = (userEntity: IListPageUserEntity): IListPageUser => ({
  id: userEntity.id,
  name: userEntity.name,
  login: userEntity.login,
  status: convertUserStatusEntityToDomain(userEntity.status),
  profiles: userEntity.profile,
  isMaster: userEntity.isMaster,
});
