import React, { createContext, useMemo, useReducer } from 'react';
import { locationsInitialState } from './locations.constants';
import { locationsReducer } from './reducers/locations.reducer';
import { IRegistrationsContextProps } from './locations.types';
import { ILocationsContext } from './types/locations.types';
import { useLocationsListContextValues } from 'src-new/pages/registrations/pages/location/contexts/locations/context-values/locations-list/locations-list-values.context';
import { ILocationsList } from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations-list.types';

export const LocationsContext = createContext<ILocationsContext>(locationsInitialState);

export const LocationsContextProvider = ({ children }: IRegistrationsContextProps) => {
  const [state] = useReducer(locationsReducer, locationsInitialState);

  const locationsList: ILocationsList = useLocationsListContextValues();

  const value: ILocationsContext = useMemo(
    (): ILocationsContext => ({
      ...state,
      locationsList,
    }),
    [locationsList, state],
  );

  return <LocationsContext.Provider value={value}>{children}</LocationsContext.Provider>;
};
