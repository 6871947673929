import { FC } from 'react';
import { FormikErrors } from 'formik';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import Select from 'components/select/select';
import AsyncSelectField from 'components/async-select/async-select';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';

interface IOriginAndDestinyBackhaulPurchaseFormProps {
  type: 'origin' | 'destination';
  typeKeyLabel: string;
  hasError: boolean;
  values: IModalBackhaulPurchaseForm;
  handleChange: (field: string, value: string) => Promise<void | FormikErrors<IModalBackhaulPurchaseForm>>;
  handleCompleteInputs: (id: number, label: string) => Promise<void>;
}
export const OriginAndDestinyBackhaulPurchaseFormView: FC<IOriginAndDestinyBackhaulPurchaseFormProps> = ({
  type,
  typeKeyLabel,
  hasError,
  values,
  handleChange,
  handleCompleteInputs,
}) => {
  return (
    <SectionLayout name={`${type} information`}>
      <LineSectionLayout columns="0.93fr 3fr 0.07fr">
        <Select
          label={typeKeyLabel}
          id={`${type}.type`}
          name={`${type}.type`}
          value={values[type].type}
          setFieldValue={handleChange}
          options={OriginAndDestinyTypeOptions}
          hasError={hasError && !values[type].type}
          errorMessage="Campo Obrigatório"
        />

        <AsyncSelectField
          label="Informe o Nome *"
          id={`${type}.name`}
          name={`${type}.name`}
          isClearable={true}
          cacheOptions={true}
          defaultOptions={true}
          value={{
            label: values[type].name,
            value: values[type].id,
          }}
          onChange={async (e) => handleCompleteInputs(e?.value ?? 0, e?.label ?? '')}
          apiCallFunction={async (e: string) => await searchOriginOrDestiny(e, values[type].type)}
          loadOptions={async (e: string) => await searchOriginOrDestiny(e, values[type].type)}
          hasError={hasError && !values[type].name}
          errorMessage="Campo Obrigatório"
          disabled={!values[type].type}
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};
