import API from 'utils/API/API';
import { showToast } from 'components/toast/toast';
import { IDriverCreatedDomain } from 'src-new/pages/registrations/domains/driver.domain';

export const driverDetailService = async (driverId: string): Promise<IDriverCreatedDomain> => {
  try {
    const { data } = await API.get<IDriverCreatedDomain>(`drivers/${driverId}`);

    return data;
  } catch (error) {
    showToast({
      message: 'Você não tem permissão para acessar esse motorista',
      type: 'error',
    });
    throw new Error('');
  }
};
