import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;
  height: 350px;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
`;
