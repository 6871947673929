/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import * as S from './pin-map.styled';

interface PinMapProps {
  coord?: {
    lat: number;
    lng: number;
  };
  setCoord: React.Dispatch<React.SetStateAction<{ lat: number; lng: number } | undefined>>;
}

const apiKey = 'AIzaSyDGYgmthF-_tOTUmHTfHtmEeqxFju1C1pw';

const mapCenter = { lat: -23.5475, lng: -46.63611 };

const PinMap: React.FC<PinMapProps> = ({ coord, setCoord }) => {
  return (
    <S.WrappedFullMap>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={coord || mapCenter}
          zoom={coord ? 16 : 8}
          onClick={(e: any) => setCoord({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
        >
          {coord && <Marker position={{ lat: coord.lat, lng: coord.lng }} />}
        </GoogleMap>
      </LoadScript>
    </S.WrappedFullMap>
  );
};

export default React.memo(PinMap);
