import { FC, ReactNode } from 'react';

import * as S from './styled';

interface IPaperProps {
  children: ReactNode;
}

export const Paper: FC<IPaperProps> = ({ children }) => {
  return <S.WrapperPaper>{children}</S.WrapperPaper>;
};
