/* eslint-disable */
import { IListPage, PageInfo } from 'domain/page-info';
import { IVehicleCreated, IVehicleStatus } from 'domain/vehicle';
import { convertVehicleEntityToSelectItem } from 'pages/schedule/mappers/convert-client-entity-to-select-item/convert-vehicle-entity-to-select-item.mapper';
import { convertVehicleCreatedEntityToDomainMapper } from 'pages/vehicle/mappers/convert-vehicle-created-entity-to-domain/convert-driver-created-entity-to-domain.mapper';
import API from 'utils/API/API';
import { IVehicleEntity } from '../entities/vehicle.entity';

export interface IVehicleListProps {
  search: string;
  page?: number;
  status?: IVehicleStatus;
  LIMIT_PAGE?: number;
  order: string;
  column: string;
  shippingCompanyId?: number;
}

export interface IVehicleListEntity {
  list: Array<IVehicleEntity>;
  pageInfo: PageInfo;
}

export const vehicleListService = async ({
  page,
  search,
  status,
  LIMIT_PAGE = 50,
  order,
  column,
  shippingCompanyId,
}: IVehicleListProps): Promise<IListPage<IVehicleCreated>> => {
  try {
    let url = `v2/vehicles?search=${search}&status=active&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }

    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }

    if (shippingCompanyId) {
      url += `&shippingCompanyId=${shippingCompanyId}`;
    }

    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertVehicleCreatedEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar veículos');
  }
};

export const vehicleFilterService = async ({
  page,
  search,
  status,
  LIMIT_PAGE = 50,
  order,
  column,
}: IVehicleListProps): Promise<IListPage<IVehicleCreated>> => {
  try {
    let url = `v2/vehicles?search=${search}&status=active&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { items, meta } = data;

    return items.map(convertVehicleEntityToSelectItem);
  } catch (error) {
    throw new Error('Houve um erro ao listar veículos');
  }
};
