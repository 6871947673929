import React, { MouseEvent } from 'react';
import { ModalDeleteView } from './modal-delete-view.component';

interface IModalDeleteProps {
  title: string;
  subtitle: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ModalDelete: React.FC<IModalDeleteProps> = ({ title, subtitle, handleClose, handleConfirm }) => {
  const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return <ModalDeleteView title={title} subtitle={subtitle} handleClose={handleClose} handleConfirm={handleConfirm} handleBackgroundClick={handleBackgroundClick} />;
};
