import { saleOfferFormDetailsInitialValuesDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details-initial-values.domain';
import { SaleOfferDetailsActionType } from '../../sale-offer.action';
import { ISaleOfferDetailsActions } from '../../types/sale-offer-details.types';
import { ISaleOfferContext } from '../../types/sale-offer.types';

export const saleOfferDetailsReducer = (
  state: ISaleOfferContext,
  action: ISaleOfferDetailsActions,
): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferDetailsActionType.SALE_OFFER_DETAILS_REQUEST:
      return {
        ...state,
        saleOfferDetails: {
          ...state.saleOfferDetails,
          statusRequest: 'PROCESSING',
          saleOfferDetails: saleOfferFormDetailsInitialValuesDomain,
        },
      };
    case SaleOfferDetailsActionType.SALE_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        saleOfferDetails: {
          ...state.saleOfferDetails,
          statusRequest: 'SUCCESS',
          saleOfferDetails: action.payload,
        },
      };
    case SaleOfferDetailsActionType.SALE_OFFER_DETAILS_ERROR:
      return {
        ...state,
        saleOfferDetails: {
          ...state.saleOfferDetails,
          statusRequest: 'ERROR',
          saleOfferDetails: saleOfferFormDetailsInitialValuesDomain,
        },
      };
    case SaleOfferDetailsActionType.SALE_OFFER_DETAILS_RESET:
      return {
        ...state,
        saleOfferDetails: {
          ...state.saleOfferDetails,
          statusRequest: 'INITIAL',
          saleOfferDetails: saleOfferFormDetailsInitialValuesDomain,
        },
      };
    default:
      return state;
  }
};
