export const axisNumberFixed = (vehicleType: string): number => {
  switch (vehicleType) {
    case 'utilitario':
      return 2;
    case 'van':
      return 2;
    case 'vuc':
      return 2;
    case '3/4':
      return 2;
    case 'toco':
      return 2;
    case 'truck':
      return 3;
    case 'bitruck':
      return 3;
    case 'carreta':
      return 5;
    case 'bitrem':
      return 7;
    case 'rodotrem':
      return 9;
    case 'romeu&julieta':
      return 5;
    case 'vanderleia':
      return 6;
    case 'carreta_ls':
      return 6;
    case 'carreta_sete_eixos':
      return 7;
    default:
      return 0;
  }
};
