import { IDriverEntity } from '../entities/driver.entity';
import { IListPage, PageInfo } from '../../../../domain/page-info';
import { IDriverCreated, IDriverStatus } from '../../../../domain/driver';

import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/driver/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { convertDriverCreatedEntityToDomainMapper } from 'pages/driver/mappers/convert-driver-created-entity-to-domain/convert-driver-created-entity-to-domain.mapper';

export interface IDriverListProps {
  search: string;
  page: number;
  status?: IDriverStatus;
  order: string;
  column: string;
}

export interface IDriverListEntity {
  list: Array<IDriverEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const driverListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IDriverListProps): Promise<IListPage<IDriverCreated>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/drivers?search=${search}&status=${parsedStatus}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertDriverCreatedEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar motorista');
  }
};
