export interface PageInfoResponse<T> {
  items: Array<T>;
  meta: PageInfo;
}

export interface PageInfo {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export const initialPageInfo: PageInfo = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 20,
  totalPages: 0,
  currentPage: 1,
};
