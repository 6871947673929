import React from 'react';
import { AllocationRegisterContextProvider } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { FreightContextProvider } from 'pages/freight/contexts/freight/freight.context';
import FreightPageBody from 'pages/freight/freight-update/freight-page-body/freight-page-body.component';

const TaskUpdatePage = () => {
  return (
    <FreightContextProvider>
      <AllocationRegisterContextProvider>
        <FreightPageBody />
      </AllocationRegisterContextProvider>
    </FreightContextProvider>
  );
};

export default TaskUpdatePage;
