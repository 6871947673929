import { useNavigate } from 'react-router-dom';

import { getPalletByIdService } from 'services/pallet/get-pallet-by-id';

export const usePalletCustomNavigation = () => {
  const navigate = useNavigate();

  const handlePalletCopy = async (palletId: number) => {
    const pallet = await getPalletByIdService(palletId);
    navigate('/pallet/novo', {
      state: {
        equipmentVoucher: pallet.equipmentVoucher,
        operationType: pallet.operationType,
        collectDate: pallet.collectDate,
        clientId: pallet.clientId,
        originCity: pallet.originCity,
        distributionCenterId: pallet.distributionCenterId,
        scheduleId: pallet.scheduleId,
        palletType: pallet.palletType,
        palletQuantity: pallet.palletQuantity,
        equipmentVoucherDate: pallet.equipmentVoucherDate,
        expirationDate: pallet.expirationDate,
        withdrawalDate: pallet.withdrawalDate,
        entryDate: pallet.entryDate,
        liberationDate: pallet.liberationDate,
        checkStub: pallet.checkStub,
        note: pallet.note,
        status: pallet.status,
        protocol: pallet.protocol,
        client: pallet.client,
        distributionCenter: pallet.distributionCenter,
      },
    });
  };

  return {
    handlePalletCopy,
  };
};
