import { IMonitoringSnapshot } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-executed-route-monytor/use-get-executed-route-monytor.types';
import { IMapsSnapshotContent } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/schedule-maps.types';

export const convertSnapshotToContent = (snapshot: IMonitoringSnapshot): IMapsSnapshotContent => {
  return {
    latitude: snapshot.latitude.toString(),
    created_at: snapshot.created_at,
    longitude: snapshot.longitude.toString(),
    speed: snapshot.speed,
    address: snapshot.address,
    ignition: snapshot.ignition,
  };
};
