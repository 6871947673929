import { FC } from 'react';

export const PdfIconNew: FC = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8881 16H1.1121C0.829328 16 0.600098 15.7708 0.600098 15.488V0.512C0.600098 0.22923 0.829328 0 1.1121 0H9.8641V2.91648C9.8641 3.25863 10.1415 3.536 10.4836 3.536H13.4001V15.488C13.4001 15.7708 13.1709 16 12.8881 16ZM2.90704 10.368H3.55754V9.48096H4.05175C4.71491 9.48096 5.24714 9.12615 5.24714 8.44186V8.43341C5.24714 7.82938 4.82051 7.4112 4.11511 7.4112H2.90704V10.368ZM3.55754 8.90227V7.99834H4.06019C4.38544 7.99834 4.58819 8.15462 4.58819 8.44608V8.45453C4.58819 8.70797 4.39811 8.90227 4.07287 8.90227H3.55754ZM5.72867 10.368H6.88183C7.81111 10.368 8.45315 9.72173 8.45315 8.8896V8.88115C8.45315 8.04902 7.81111 7.4112 6.88183 7.4112H5.72867V10.368ZM6.88183 7.99834C7.41405 7.99834 7.77309 8.36582 7.77309 8.8896V8.89805C7.77309 9.42182 7.41405 9.78086 6.88183 9.78086H6.37917V7.99834H6.88183ZM9.03184 10.368H9.68234V9.2233H11.0932V8.63194H9.68234V8.00256H11.2832V7.4112H9.03184V10.368ZM10.2001 2.688V0L13.4001 3.2H10.7121C10.4293 3.2 10.2001 2.97077 10.2001 2.688Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
