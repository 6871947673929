import { useCallback } from 'react';
import { saveUploadFileService } from 'src-new/pages/registrations/services/save-upload-file/save-upload-file.service';
import { showToast } from 'components/toast/toast';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';

export const useSaveUploadFile = () => {
  return useCallback(
    async (
      id: number,
      uploadFiles: Array<IAttachmentDomain>,
      module: 'client' | 'location' | 'shipping-company' | 'driver' | 'vehicle',
    ) => {
      try {
        const uploadFilesPromises = uploadFiles.map(async ({ file, fileName, validateDate, attachDocumentType }) => {
          if (file) {
            return saveUploadFileService({
              code: id,
              file: file,
              fileType: attachDocumentType,
              fileName: fileName,
              validateDate: validateDate,
              module: module,
            });
          }
        });

        await Promise.all(uploadFilesPromises);
      } catch (error) {
        showToast({
          type: 'error',
          message: 'Houve um erro ao realizar o upload dos arquivos',
        });
      }
    },
    [],
  );
};
