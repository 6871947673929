import { ICompanyTypeEntity } from 'pages/company/services/entities/company.entity';
import { ICompanyType } from 'domain/company';

export const convertTypeDomainToEntityMapper = (typeEntity: ICompanyType): ICompanyTypeEntity => {
  switch (typeEntity) {
    case 'Embarcador':
      return 'shipper';
    case 'Transportadora':
      return 'shipping-company';
    case 'Condomínio':
      return 'condominium';
    default:
      return 'shipper';
  }
};
