import React from 'react';

import { DeliveryTrackingBody } from '../components/body/delivery-tracking-body';

import { Form } from './components/form/form';

export const DeliveryTrackingPage: React.FC = () => {
  return (
    <DeliveryTrackingBody description="RELACIONAMENTO@LOGSHARE.COM.BR">
      <Form />
    </DeliveryTrackingBody>
  );
};
