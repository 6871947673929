/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { dateKeys } from '../utils/filter/date';
import { IdlenessBiddingModal } from '../types';
import { IdlenessBiddingsFilter, getIdlenessBiddingsList } from '../services/list-idleness-biddings';
import { convertField } from '../mappers/filter';
import { useFilter } from '../context';
import { ModalIdlenessBiddings } from '../components/modal-biddings/list/content-table';
import * as S from './styled';
import { ListIdlenessBiddingsTable } from './content-table/list';
import { hiddenFilters } from 'utils-v2/filters/hidden-filters';
import { initialPageInfo } from 'domain-v2/inputs/page-info';
import { ModalState } from 'domain-v2/inputs/modals';
import { IdlenessBiddingsList } from 'domain-v2/idleness-bidding/list';
import { FooterPage } from 'components-v2/common/footer-page';
import Input from 'components/input/input';

interface ParamsArray {
  key: string;
  value: string | number;
}

const initialListState = [] as Array<IdlenessBiddingsList>;

export const List: React.FC = () => {
  const { filters, resetFilters, updateFilters } = useFilter();

  const [buyModal, setBuyModal] = useState<ModalState<IdlenessBiddingModal>>({
    open: false,
    data: {
      idlenessId: 0,
    },
  });

  const { isLoading, data } = useQuery({
    queryKey: ['idlenessBiddingList', filters],
    queryFn: () => getIdlenessBiddingsList(filters),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const filterChip: ParamsArray[] = useMemo(() => {
    const entries = Object.entries(filters).map(([key, value]) => ({
      key,
      value: convertField(key, value),
    }));

    const filteredParams = entries.filter(({ key, value }) => !hiddenFilters.includes(key) && value);

    const convertedDateParams = filteredParams.map(({ key, value }) => {
      if (dateKeys.includes(key)) {
        return { key, value: moment(value).format('DD/MM/YYYY') };
      }

      return { key, value };
    });

    return convertedDateParams;
  }, [filters]);

  const handleSearch = (search: string) => {
    if (search) updateFilters({ ...filters, search, page: 1 });
  };

  const handlePaginate = (page: number) => {
    updateFilters({ ...filters, page });
  };

  const removeFilterChip = (indexToRemove: number) => {
    const updatedParamsArray = [...filterChip];
    updatedParamsArray.splice(indexToRemove, 1);

    if (updatedParamsArray.length === 0) {
      resetFilters(false);

      return;
    }

    const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
      accumulator[key as keyof ParamsArray] = filters[key as keyof IdlenessBiddingsFilter];
      return accumulator;
    }, {});

    updateFilters({
      ...updatedParams,
      page: 1,
    });
  };

  return (
    <>
      {buyModal.open && <ModalIdlenessBiddings idlenessId={buyModal.data.idlenessId} setBuyModal={setBuyModal} />}

      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pelo ID, Unidade, Parceiro ou Cidade"
          type="text"
          changeValue={handleSearch}
          filters={filterChip.map((item) => item.value)}
          handleClearFilters={removeFilterChip}
          isClear={false}
        />
      </S.ContainerSearch>
      <ListIdlenessBiddingsTable
        idlenessBiddings={data?.items || initialListState}
        pageInfo={data?.meta || initialPageInfo}
        isLoading={isLoading}
        handlePaginate={handlePaginate}
        orderSelected={{
          orderBy: filters.column,
          sortDirection: filters.order,
        }}
        clickOrderTableBy={(column) => {
          updateFilters({
            ...filters,
            column,
            order: filters.order === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        setBuyModal={setBuyModal}
      />
      <FooterPage.Root />
    </>
  );
};
