import React, { useContext } from 'react';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { TransactionsListPageBody } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.component';
import { TransactionsContextProvider } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { PageHeaderFilterContextProvider } from 'src-new/contexts/page-header-filter/page-header-filter.context';

export const TransactionsListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <PageHeaderFilterContextProvider
        initialFilters={{
          search: '',
          category: '',
        }}
      >
        <InfiniteScrollListTableContextProvider>
          <TransactionsContextProvider>
            <TransactionsListPageBody />
          </TransactionsContextProvider>
        </InfiniteScrollListTableContextProvider>
      </PageHeaderFilterContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
