import { FC, useCallback, useContext, useMemo } from 'react';
import { ScheduleDetailsOriginAndDestinyView } from './schedule-details-origin-and-destination.component-view';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { IScheduleOriginAndDestinationPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.types';
import { useUpdateScheduleOriginAndDestinationDetails } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-origin-and-destination-details/use-update-schedule-origin-and-destination-details.hook';

interface IScheduleDetailsOriginAndDestinationProps {
  type: 'origin' | 'destination';
}

export const ScheduleDetailsOriginAndDestiny: FC<IScheduleDetailsOriginAndDestinationProps> = ({ type }) => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { isAdmin, isZMaster } = useContext(UserContext);

  const updateScheduleOriginAndDestinationDetailsHook = useUpdateScheduleOriginAndDestinationDetails();

  const handleModalOriginAndDestination = useCallback(
    (open: boolean, type?: 'origin' | 'destination') => {
      if (open) {
        scheduleDetails.setScheduleDetailsAllocationOpen(type === 'origin' ? 'origin_details' : 'destination_details');
      } else {
        scheduleDetails.setScheduleDetailsAllocationClose();
      }
    },
    [scheduleDetails],
  );

  const handleSubmit = useCallback(
    (values: IScheduleOriginAndDestinationPayload) => {
      if (values.locationType) {
        updateScheduleOriginAndDestinationDetailsHook(values);

        handleModalOriginAndDestination(false);
      }
    },
    [handleModalOriginAndDestination, updateScheduleOriginAndDestinationDetailsHook],
  );

  const location = useMemo(() => {
    return scheduleDetails.scheduleDetails[type];
  }, [scheduleDetails.scheduleDetails, type]);

  const disabledButtonEditLocation = useMemo(() =>{
    return !(isAdmin || isZMaster);
  }, [isAdmin, isZMaster])

  const modalInitialValues = useMemo((): IScheduleOriginAndDestinationPayload | undefined => {
    const { isOpen, allocatedEntity } = scheduleDetails.allocationActions;

    if (isOpen && (allocatedEntity === 'origin_details' || allocatedEntity === 'destination_details')) {
      if (allocatedEntity === 'origin_details') {
        return { ...scheduleDetails.scheduleDetails.origin, locationType: 'origin' };
      }

      return { ...scheduleDetails.scheduleDetails.destination, locationType: 'destination' };
    }
  }, [
    scheduleDetails.allocationActions,
    scheduleDetails.scheduleDetails.destination,
    scheduleDetails.scheduleDetails.origin,
  ]);

  return (
    <ScheduleDetailsOriginAndDestinyView
      type={type}
      isAdmin={disabledButtonEditLocation}
      location={location}
      modalInitialValues={modalInitialValues}
      handleSubmit={handleSubmit}
      handleModalOriginAndDestination={handleModalOriginAndDestination}
    />
  );
};
