import styled, { css } from 'styled-components';

import theme from 'styles/theme';

export const WrapperRequirements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 7px 35px 0 0;
`;

export const LabelRequirements = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: rgb(3, 5, 23);
  background-color: white;
  cursor: pointer;
`;

type RequirementProps = { columns: string };
export const Requirement = styled.section<RequirementProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 0 5px;
    grid-auto-flow: column;
    align-items: center;
  `}
`;

type ArrowButtonProps = { disabled: boolean };
export const ArrowButton = styled.div<ArrowButtonProps>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;

    svg {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      fill: ${disabled ? theme.colorsV2.lightGray : theme.colorsV2.black};
    }
  `}
`;
