import { useCallback, useContext, useMemo } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useQueryClient } from 'react-query';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IScheduleDetailsComplementDomain } from '../../domains/schedule-details.domain';
import { convertStatusCodeDates } from '../../utils/converter-status-code-dates.util';
import { updateScheduleDetailsDateTimeService } from '../../services/update-schedule-details-date-time/update-schedule-details-date-time.service';
import { updateScheduleDetailsStatusCodeService } from '../../services/update-schedule-details-status-code/update-schedule-details-status-code.service';

export const useUpdateScheduleDetailsDateTime = () => {
  const { scheduleRequestStatus, scheduleDetails } = useContext(ScheduleContext);

  const scheduleId = useMemo((): number => scheduleDetails.scheduleDetails.id, [scheduleDetails.scheduleDetails.id]);
  const scheduleDetailId = useMemo(
    (): number => scheduleDetails.scheduleDetails.detail.id,
    [scheduleDetails.scheduleDetails.detail.id],
  );
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payloadDomain: IScheduleDetailsComplementDomain) => {
      try {
        scheduleRequestStatus.setScheduleRequestStatus();

        const statusCode = convertStatusCodeDates(payloadDomain);

        await Promise.all([
          updateScheduleDetailsDateTimeService(scheduleDetailId, payloadDomain),
          updateScheduleDetailsStatusCodeService(scheduleId, statusCode),
        ]);

        await reactQueryClient.invalidateQueries(['schedule-details-by-id']);

        scheduleDetails.setScheduleDetailsAllocationClose();
        scheduleRequestStatus.setScheduleRequestStatusSuccess();
      } catch (error: any) {
        scheduleRequestStatus.setScheduleRequestStatusError();
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleDetailId, scheduleDetails, scheduleId, scheduleRequestStatus],
  );
};
