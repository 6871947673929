import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AttachDocumentsModalView } from './attach-documents-modal-view.component';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';
import { FieldArrayRenderProps, FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { documentInitialValues } from 'src-new/pages/registrations/components/attach-documents-section/components/attach-documents-modal/attach-documents-modal.constants';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';

export interface IAttachDocumentsModalParams {
  arrayHelpers: FieldArrayRenderProps;
}

export const AttachDocumentsModal: React.FC<IAttachDocumentsModalParams> = ({ arrayHelpers }) => {
  const { attachDocumentsSection } = useContext(RegistrationsContext);
  const { setModalClose } = attachDocumentsSection;
  const { setTouched, setFieldValue, validateForm, values, getFieldProps } =
    useFormikContext<RegistrationAttachmentValues>();
  const [position, setPosition] = useState<number>(values.attachments.length);
  const [existPosition, setExistPosition] = useState<boolean>(false);
  const { remove } = arrayHelpers;

  const handleCloseModal = useCallback(() => {
    remove(position);
    setModalClose();
  }, [position, remove, setModalClose]);

  const handleSaveButton = useCallback(async () => {
    const item: IAttachmentDomain = getFieldProps(`attachments[${position}]`).value;

    if (item) {
      const formErrors = await validateForm();
      const errorsAttachments = formErrors.attachments ? { attachments: formErrors.attachments } : undefined;

      if (errorsAttachments && Object.keys(errorsAttachments).length > 0) {
        setTouched(setNestedObjectValues<FormikTouched<RegistrationAttachmentValues>>(errorsAttachments, true));
      } else {
        setFieldValue(`attachments[${position}]`, item);
        setModalClose();
      }
    }
  }, [getFieldProps, position, setFieldValue, setModalClose, setTouched, validateForm]);

  const documents = useMemo((): Array<IAttachmentDomain> => values.attachments ?? [], [values]);

  useEffect(() => {
    if (!existPosition) {
      arrayHelpers.insert(documents.length, documentInitialValues);
      setPosition(documents.length);
      setExistPosition(true);
    }
  }, [arrayHelpers, documents.length, existPosition]);

  return (
    <AttachDocumentsModalView
      handleModalClose={handleCloseModal}
      handleSaveButton={handleSaveButton}
      position={position}
    />
  );
};
