import { Buffer } from 'buffer';

import React from 'react';
import moment from 'moment';
import { getDistance } from 'geolib';
import { getDatabase, ref, onChildAdded, onValue, query, limitToLast } from 'firebase/database';
// import axios from 'axios';

import MapWrapper from './components/MapWrapper';
import GoogleMap from './components/GoogleMaps';

//import { normalizeGeopoint } from 'utils/normalizes';
//import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

import { TrackerScheduleService } from 'services/maps/tracker.service';

// const defaultRouteURL = import.meta.env.VITE_HERE_ROUTES_URL;

let markToShowDetails: number = 0;

const MiniMap: React.FC<any> = ({ origin, destiny, schedule }) => {
  const [firebaseTruckRoutes, setFirebaseTruckRoutes] = React.useState<{
    isChecked: boolean;
    routes: any[];
  }>({ isChecked: false, routes: [] });
  const [monytorTruckRoutes, setMonytorTruckRoutes] = React.useState<{
    isChecked: boolean;
    routes: any[];
  }>({ isChecked: false, routes: [] });

  React.useEffect(() => {
    (async () => {
      if (!schedule?.id) return;

      getTruckPositionByTracker();
      getTruckPositionByFirebase();
    })();
  }, [schedule]);

  const generateHistoricRoute = async (array: any[]) => {
    const result: any[] = [];

    for (let i = 0; i < array.length; i += 2) {
      const obj: any = {};

      obj.lat = Number(array[i]);

      if (array[i + 1] !== undefined) {
        obj.lng = Number(array[i + 1]);
      } else {
        obj.lng = null;
      }

      result.push(obj);
    }

    return result;
  };

  const getTruckPositionByTracker = async () => {
    const { data } = await TrackerScheduleService(schedule.id);

    if (data?.latitude && data?.longitude) {
      const formatedHistory = await generateHistoricRoute(data.historicPositions);
      setMonytorTruckRoutes({
        isChecked: true,
        routes: formatedHistory,
      });
    }
  };

  const getTruckPositionByFirebase = () => {
    const { driver } = schedule;

    if (!driver) return;

    const row = new Buffer(`${driver.cpf}:${driver.id}:${schedule.id}`).toString('base64');

    const db = getDatabase();
    const dbRef = ref(db, 'trackings/' + row);
    const lastTruckRoute = query(dbRef, limitToLast(1));

    onValue(
      dbRef,
      (data) => {
        const snapshots = data.val();

        if (snapshots) {
          const snapshotsKeys = Object.keys(snapshots);

          if (snapshotsKeys.length > 0) {
            const formatedSnapshots: any[] = snapshotsKeys.map((key: any, index: number) => {
              const { latitude, longitude, created_at } = snapshots[key];

              const showDetail = markToShowDetails >= 15 ? true : false;

              if (showDetail) markToShowDetails = 0;

              markToShowDetails += 1;

              let speed = 0;

              if (showDetail && index > 0) {
                speed = calculateSpeed(snapshots[snapshotsKeys[index - 14]], {
                  latitude,
                  longitude,
                  created_at,
                });
              }

              return {
                lat: Number(latitude),
                lng: Number(longitude),
                speed,
                showDetail,
                created_at,
              };
            });

            setFirebaseTruckRoutes({
              isChecked: true,
              routes: formatedSnapshots,
            });

            setMonytorTruckRoutes((prev) => ({
              ...prev,
              isChecked: false,
            }));
          }
        }
      },
      { onlyOnce: true },
    );

    onChildAdded(lastTruckRoute, (data) => {
      const { latitude, longitude, created_at } = data.val();

      const lat = Number(latitude);
      const lng = Number(longitude);

      const showDetail = markToShowDetails >= 15 ? true : false;

      let speed = 0;

      if (showDetail) {
        speed = calculateSpeed(firebaseTruckRoutes.routes[firebaseTruckRoutes.routes.length - 14], {
          latitude,
          longitude,
          created_at,
        });
      }

      if (showDetail) markToShowDetails = 0;

      markToShowDetails += 1;

      const location = { lat, lng, speed, created_at, showDetail };

      setFirebaseTruckRoutes((prev) => ({
        ...prev,
        routes: [...prev.routes, location],
      }));
    });
  };

  const calculateSpeed = (initPoint: any, finalPoint: any) => {
    if (!initPoint || !finalPoint) return 0;

    const distance = getDistance(initPoint, finalPoint, 0.01);

    const finalPointTime = moment(finalPoint.created_at);
    const startPointTime = moment(initPoint.created_at);

    const diff = moment.duration(finalPointTime.diff(startPointTime));

    const durationInSec = diff.asSeconds();

    return Math.round((distance / durationInSec) * 3.6);
  };

  const handleMapKind = (mapKind: string) => {
    if (mapKind === 'firebase') {
      setFirebaseTruckRoutes((prev) => ({
        ...prev,
        isChecked: !prev.isChecked,
      }));
    } else if (mapKind === 'monytor') {
      setMonytorTruckRoutes((prev) => ({
        ...prev,
        isChecked: !prev.isChecked,
      }));
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (!destiny?.lat || !destiny?.lng) return;
      } catch (err: any) {
        console.log('mapa não carregado');
      }
    })();
  }, [origin, destiny]);

  return (
    <MapWrapper>
      <GoogleMap
        origin={origin}
        destination={destiny}
        schedule={schedule}
        firebaseTruckRoutes={firebaseTruckRoutes}
        monytorTruckRoutes={monytorTruckRoutes}
        handleMapKind={handleMapKind}
      />
    </MapWrapper>
  );
};

export default MiniMap;
