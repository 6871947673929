import {
  getFreightCalculation,
  IGetFreightCalculation,
} from 'pages/schedule/services/get-freight-calculation/get-freight-calculation.service';

const useGetFreightCalculationHook = () => {
  return async (freight: IGetFreightCalculation) => {
    return await getFreightCalculation(freight);
  };
};

export { useGetFreightCalculationHook };
