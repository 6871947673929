import React, { useCallback } from 'react';

import { ContainerStyled, OptionStyled } from './content-export-report.styled';

import { ReportOption } from 'domain/report-files';
interface ContentExportReportParams {
  options: Array<ReportOption>;
  onClick: (value: ReportOption) => void;
}

const ContentExportReportView: React.FC<ContentExportReportParams> = ({ options, onClick }) => {
  const renderOptions = useCallback(() => {
    return options.map((option) => (
      <OptionStyled key={option.label} onClick={() => onClick(option)}>
        {option.label}
      </OptionStyled>
    ));
  }, [onClick, options]);

  return <ContainerStyled>{renderOptions()}</ContainerStyled>;
};
export default ContentExportReportView;
