import React from 'react';

import ContentExportReportView from './content-export-report-view';

import { ReportOption } from 'domain/report-files';
interface ContentExportReportParams {
  options: Array<ReportOption>;
  onClick: (value: ReportOption) => void;
}
const ContentExportReport: React.FC<ContentExportReportParams> = ({ options, onClick }) => {
  return <ContentExportReportView options={options} onClick={onClick} />;
};

export default ContentExportReport;
