import styled, { css } from 'styled-components';

interface ILineSectionProps {
  columns: string;
}
export const LineSection = styled.section<ILineSectionProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const AddButtonWrapper = styled.div`
  margin-top: 15px;
`;

export const DeleteButtonWrapper = styled.div`
  margin-top: 23px;
`;
