import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { LocationRegisterSchema } from './schemas-validation/schemas-validation';
import LocationRegisterFormView from './location-register-form-view';
import { initialValuesLocation, PATH_TO_LOCATION_LIST } from './form-values/form-values';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useLocationRegisterHook } from 'pages/location/hooks/use-location-register/use-location-register.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';

const LocationRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const locationRegisterHook = useLocationRegisterHook();
  const cepHook = useCepHook();
  const goBackToLocationList = useCallback(() => navigate(PATH_TO_LOCATION_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (locality: ILocationDomain) => {
      setIsLoading(true);

      const newLocality = {
        ...locality,
        lat: locality.lat ?? '',
        lng: locality.lng ?? '',
      };

      locationRegisterHook(newLocality, newLocality.attachments)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToLocationList();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToLocationList, locationRegisterHook],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      const address = await cepHook(cep);

      return address;
    },
    [cepHook],
  );

  return (
    <LocationRegisterFormView
      locationValues={initialValuesLocation}
      handleGoback={goBackToLocationList}
      handleRegister={handleSubmit}
      schemaValidation={LocationRegisterSchema}
      onBlurCep={onBlurCep}
      isLoading={isLoading}
    />
  );
};

export default LocationRegisterForm;
