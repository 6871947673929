import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getMDFeDataService } from '../../services/get-mdfe-data/get-mdfe-data.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetMDFeData = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);

  return useCallback(async () => {
    scheduleDocuments.mdfeDataProps.setMDFeDataRequest();

    try {
      const data = await getMDFeDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.mdfeDataProps.setMDFeDataSuccess(data);
    } catch (error: any) {
      scheduleDocuments.mdfeDataProps.setMDFeDataError();

      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.mdfeDataProps]);
};
