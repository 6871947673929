import moment from 'moment';
import dayjs from 'dayjs';

export const formatDate = (date: string) => {
  if (moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY');
  }

  return date;
};

export const formatDateTime = (date: string, dateFormat?: string) => {
  if (dateFormat && moment(date, dateFormat).isValid()) {
    return moment(date, dateFormat).format('DD/MM/YYYY HH:mm');
  }
  if (moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  return date;
};

export const formatDateAndTime = (date: string) => dayjs(date).format('DD-MM-YYYY HH:mm');

export const formatDateAndTimeISOtoLocale = (date: string) => dayjs(date).format('DD-MM-YYYY HH:mm');
