import { FC, useCallback, useEffect, useState } from 'react';
import { ModalBackhaulPurchaseFormView } from './modal-backhaul-purchase-form-view.component';
import { useFormikContext } from 'formik';
import { handleValidateDates } from 'src-new/utils/validate-dates/validate-dates.util';
import { IValidateDates } from 'src-new/utils/validate-dates/validate-dates.types';
import { IModalBackhaulPurchaseForm } from '../../types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from '../../types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { useGetLeadTime } from 'src-new/hooks/use-get-leadtime/use-get-leadtime.hook';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { useGetSurplusValue } from 'src-new/hooks/use-get-surplus-value/use-get-surplus-value.hook';
import dayjs from 'dayjs';

interface IModalBackhaulPurchaseFormProps {
  backhaulPurchase: IBackhaulPurchase;
  handleClose: () => void;
}
export const ModalBackhaulPurchaseForm: FC<IModalBackhaulPurchaseFormProps> = ({ backhaulPurchase, handleClose }) => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<IModalBackhaulPurchaseForm>();

  const getLeadTimeHook = useGetLeadTime();
  const getSurplusValueHook = useGetSurplusValue();

  const [datesError, setDatesError] = useState<Array<IValidateDates>>([
    {
      initialLevyDate: '',
      finalLevyDate: '',
      initialDeliveryDate: '',
      finalDeliveryDate: '',
    },
  ]);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleValidation = useCallback(() => {
    const validation = handleValidateDates(values.dates);

    if (validation || values.cost.freightValue === 0 || !values.origin || !values.destination) {
      if (validation) {
        setDatesError(validation.validationDates);
      }

      setHasError(true);
    } else {
      handleSubmit();
    }
  }, [handleSubmit, values.cost.freightValue, values.dates, values.destination, values.origin]);

  const handleDatesError = useCallback((dates: Array<IValidateDates>) => setDatesError(dates), []);

  useEffect(() => {
    if (!values.origin.id || !values.destination.id) return;

    (async () => {
      const leadTime = await getLeadTimeHook({
        originId: values.origin.id,
        originType: values.origin.type,
        destinationId: values.destination.id,
        destinationType: values.destination.type,
        vehicleType: convertVehicleTypeToEntity(values.cargo.vehicle.type),
        vehicleCategory: convertVehicleCategoryToEntity(values.cargo.vehicle.category),
      });

      if (!leadTime) return;

      setFieldValue('leadTime', leadTime.leadTime);
    })();
  }, [
    getLeadTimeHook,
    setFieldValue,
    values.cargo.vehicle.category,
    values.cargo.vehicle.type,
    values.destination.id,
    values.destination.type,
    values.origin.id,
    values.origin.type,
  ]);

  useEffect(() => {
    if (!values.leadTime || !values.dates.every((date) => date.finalLevyDate && date.initialDeliveryDate)) return;
    (async () => {
      const loadingDate = values.dates.map((date) => dayjs(date.finalLevyDate).toISOString());
      const unloadDate = values.dates.map((date) => dayjs(date.initialDeliveryDate).toISOString());

      const surplusValue = await getSurplusValueHook({
        leadTime: values.leadTime,
        loadingDate,
        unloadDate,
        vehicleType: convertVehicleTypeToEntity(values.cargo.vehicle.type),
      });

      if (!surplusValue) return;

      setFieldValue('cost.surplusValue', surplusValue.surplusValue);
    })();
  }, [getSurplusValueHook, setFieldValue, values.cargo.vehicle.type, values.dates, values.leadTime]);

  return (
    <ModalBackhaulPurchaseFormView
      backhaulPurchase={backhaulPurchase}
      values={values}
      hasError={hasError}
      datesError={datesError}
      handleClose={handleClose}
      handleValidation={handleValidation}
      handleDatesError={handleDatesError}
    />
  );
};
