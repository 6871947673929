import React from 'react';

import { BalloonStyled } from './balloon.styled';

interface BalloonProps {
  firstColor?: string;
  secondColor?: string;
  firstText?: string;
  secondText?: string;
  ballonColor?: string;
  open?: boolean;
}

const Balloon: React.FC<BalloonProps> = ({ firstColor, secondColor, firstText, secondText, ballonColor, open }) => {
  if (!open) return <></>;
  return (
    <BalloonStyled balloonColor={ballonColor} firstColor={firstColor} secondColor={secondColor}>
      <span>
        {firstText}
        <b> {secondText}</b>
      </span>
    </BalloonStyled>
  );
};

export default Balloon;
