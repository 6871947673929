import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { converterWeighingDetailsResponseToDomain } from './mappers/converter-weighing-details-response-to-domain/converter-weighing-details-response-to-domain.mapper';
import { getWeighingByIdService } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/services/get-weighing-by-id/get-weighing-by-id.service';
import { IWeighingResponse } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/services/get-weighing-by-id/get-weighing-by-id.types';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';

export const UseGetWeighingById = () => {
  const { weighingDetails } = useContext(WeighingContext);
  const { setWeighingDetailsRequest, setWeighingDetailsSuccess, setWeighingDetailsError } = weighingDetails;

  const { id } = useParams<{ id: string }>();
  const weighingId = useMemo(() => id ?? '', [id]);
  const { isLoading } = useQuery<IWeighingResponse>({
    queryKey: ['weighingId-details-by-id', weighingId],
    queryFn: () => {
      setWeighingDetailsRequest();
      return getWeighingByIdService(weighingId);
    },
    onSuccess(data) {
      setWeighingDetailsSuccess(converterWeighingDetailsResponseToDomain(data));
    },
    onError(error) {
      setWeighingDetailsError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
  });

  return useCallback(() => {
    if (isLoading) {
      setWeighingDetailsRequest();
    }
  }, [isLoading, setWeighingDetailsRequest]);
};
