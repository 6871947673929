import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FooterPage } from 'components-v2/common/footer-page';
import Button from 'components/button/button';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import InputTextarea from 'components/input-textarea/input-textarea';
import InputFieldTime from 'components/input-time/input-time';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import MultiAsyncSelectField from 'components/multi-async-select/multi-async-select';
import MultiSelect from 'components/multi-select/multi-select';
import ResponsibleSolution from 'components/responsible-solution/responsible-solution';
import SectionDivider from 'components/section-divider/section-divider';
import Select from 'components/select/select';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { IAddress } from 'domain/address';
import { vehicleTypeOptions } from 'domain/global-inputs';
import { uf } from 'domain/states';
import { FormikProps, withFormik } from 'formik';
import { useConvertAddressToGeoPositionHook } from 'hooks/maps/map-convert-address-to-geo.hook';
import { ContainerOperationStyled } from 'pages/client/client-register/components/client-register-form/client-register-form.styled';
import { optionsClient } from 'pages/client/client-register/components/form-values/form-values';
import PinMap from 'pages/location/location-register/components/pin-map/pin-map-view';
import {
  VehicleCategoryEntity,
  VehicleTypeEntity,
} from 'pages/schedule/services/get-freight-by-filter/get-freight-by-filter.service';
import { optionsVehicleCategory } from 'pages/tariff/constants';
import React, { useCallback, useMemo, useState } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ClientUpdateSchema } from '../schemas-validation/schemas-validation';
import {
  CargoInsurance,
  ClientBilling,
  ContainerButtonStyled,
  ContainerDaysStyled,
  ContainerHoursStyled,
  ContractualPermissionStyled,
  FormStyled,
  LabelDaysStyled,
  LabelInAndOutStyled,
  LabelSemanalDaysStyled,
  LabelStyled,
  LineSectionStyled,
  SectionStyled,
  Textarea,
  WrapperSessions,
  WrapperSessionsFaturamento,
  WrapperSessionsSeguroCarga,
} from './client-update-form.styled';
import { ContactDetailsSection } from 'src-new/pages/registrations/components/contact-details-section/contact-details-section.component';
import { AttachDocumentsSection } from 'src-new/pages/registrations/components/attach-documents-section/attach-documents-section.component';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import AsyncSelectField from 'components/async-select/async-select';
import {
  IClientDomain,
  optionsClosingCycle,
  optionsInsurancePolicy,
  optionsPaymentMethod,
  optionsPaymentTerm,
  optionsRequiresPreInvoice,
} from 'src-new/pages/registrations/domains/client.domain';
import { InputCurrency } from 'components/input-currency/input-currency';
import { ServicesType, servicesTypeOptions } from 'src-new/pages/registrations/domains/services-type.domain';
import { MultiValue } from 'react-select';
import {
  MultiSelect as NewMultiSelect,
  IMultiSelectOption,
} from 'src-new/pages/registrations/components/multi-select/multi-select.component';

type ParceiroUpdateFormProps = {
  initialValues: IClientDomain;
  isLoading: boolean;
  loggedCompany: string;
  handleGoback: () => void;
  handleUpdate: (values: IClientDomain) => void;
  localityApiCall: (searchValue: string) => Promise<Array<{ label: string; value: number }>>;
  localityOptions: (localityIds: number[]) => {
    label: string;
    value: number;
  }[];
  onBlurCep: (cep: string) => Promise<IAddress>;
  apiCallContractualAnelyst?: (searchValue: string) => Promise<Array<{ label: string; value: number }>>;
};

const ClientUpdateFormView: React.FC<ParceiroUpdateFormProps> = ({
  initialValues,
  isLoading,
  loggedCompany,
  handleGoback,
  handleUpdate,
  localityApiCall,
  localityOptions,
  onBlurCep,
  apiCallContractualAnelyst,
}) => {
  const InnerForm = (props: FormikProps<IClientDomain>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;
    const [openMap, setOpenMap] = useState(false);
    const [coord, setCoord] = useState<{ lat: number; lng: number } | undefined>({
      lat: Number(values.lat),
      lng: Number(values.lng),
    });

    const convertConvertToAddressToGeoHook = useConvertAddressToGeoPositionHook();

    const onBlurCEPField = async (cep: string) => {
      const address = await onBlurCep(cep);
      setFieldValue('address.cep', address.cep || values.address.cep);
      setFieldValue('address.city', address.city);
      setFieldValue('address.complement', address.complement);
      setFieldValue('address.neighborhood', address.neighborhood);
      setFieldValue('address.numberHouse', address.numberHouse);
      setFieldValue('address.street', address.street);
      setFieldValue('address.uf', address.uf);
    };

    const handleGetLatLng = async () => {
      const geoposition = await convertConvertToAddressToGeoHook({
        address: `${values.address.cep} ${values.address.street} ${values.address.city} ${values.address.numberHouse}`,
      });

      setFieldValue('lat', String(geoposition?.lat));
      setFieldValue('lng', String(geoposition?.lng));
      setCoord({ lat: Number(geoposition?.lat), lng: Number(geoposition?.lng) });
    };

    const convertedVehicleCategoryType = (): {
      label: string;
      value: string;
    }[] => {
      const new_array: { label: string; value: string }[] = [];

      optionsVehicleCategory.map((operation) => {
        if (values.vehicleCategoryType && values.vehicleCategoryType.length > 0) {
          const hasvalue = values.vehicleCategoryType.includes(operation.value as VehicleCategoryEntity);
          hasvalue && new_array.push(operation);
        }
      });

      return new_array;
    };

    const verifyTime = (first: string, second: string) => {
      if (first && second?.length === 5) {
        const firstNumber = Number(first?.replace(':', ''));
        const secondNumber = Number(second?.replace(':', ''));
        if (firstNumber >= secondNumber) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const setServiceType = useCallback(
      (e: MultiValue<IMultiSelectOption<ServicesType>>) => {
        const hasAll = e.find((e) => e.value === 'TODOS');
        const services = hasAll ? servicesTypeOptions.filter((v) => v.value != 'TODOS') : e.filter((e) => e.value);
        const options = services.map((v) => v.value);
        setFieldValue('operationType', options);
      },
      [setFieldValue],
    );

    const handleVerifyInputs = () => {
      if (
        verifyTime(values.inbound?.dom?.firstTime, values.inbound?.dom?.secondTime) ||
        verifyTime(values.inbound?.seg?.firstTime, values.inbound?.seg?.secondTime) ||
        verifyTime(values.inbound?.ter?.firstTime, values.inbound?.ter?.secondTime) ||
        verifyTime(values.inbound?.qua?.firstTime, values.inbound?.qua?.secondTime) ||
        verifyTime(values.inbound?.qui?.firstTime, values.inbound?.qui?.secondTime) ||
        verifyTime(values.inbound?.sex?.firstTime, values.inbound?.sex?.secondTime) ||
        verifyTime(values.inbound?.sab?.firstTime, values.inbound?.sab?.secondTime) ||
        verifyTime(values.outbound?.dom?.firstTime, values.outbound?.dom?.secondTime) ||
        verifyTime(values.outbound?.seg?.firstTime, values.outbound?.seg?.secondTime) ||
        verifyTime(values.outbound?.ter?.firstTime, values.outbound?.ter?.secondTime) ||
        verifyTime(values.outbound?.qua?.firstTime, values.outbound?.qua?.secondTime) ||
        verifyTime(values.outbound?.qui?.firstTime, values.outbound?.qui?.secondTime) ||
        verifyTime(values.outbound?.sex?.firstTime, values.outbound?.sex?.secondTime) ||
        verifyTime(values.outbound?.sab?.firstTime, values.outbound?.sab?.secondTime)
      ) {
        showMessageFormatted({
          message: 'Há algum problemas com os horários',
          type: 'error',
        });
      } else if (values.address.complement === null) {
        setFieldValue('address.complement', '');
      } else {
        handleSubmit();
      }
    };

    const renderContractualPermission = useMemo(() => {
      return (
        <ContractualPermissionStyled>
          <DateTimePikers
            label="Ativação do Cadastro"
            format="DD/MM/YYYY HH:mm"
            onChange={(date) => setFieldValue('contractualPremises.createdAt', date)}
            value={dayjs(values.contractualPremises?.createdAt)}
            disabled
          />
          <DateTimePikers
            label="Assinatura de Contrato"
            format="DD/MM/YYYY HH:mm"
            onChange={(date) => setFieldValue('contractualPremises.contractSignatureDate', date)}
            value={dayjs(values.contractualPremises?.contractSignatureDate)}
          />
          <DateTimePikers
            label="Inicio do Contrato"
            format="DD/MM/YYYY HH:mm"
            onChange={(date) => setFieldValue('contractualPremises.contractStartDate', date)}
            value={dayjs(values.contractualPremises?.contractStartDate)}
          />
          <DateTimePikers
            label="Término do Contrato"
            format="DD/MM/YYYY HH:mm"
            onChange={(date) => setFieldValue('contractualPremises.contractEndDate', date)}
            value={dayjs(values.contractualPremises?.contractEndDate)}
          />

          <AsyncSelectField
            placeholder=""
            label="Analista"
            isClearable={true}
            name="analyst"
            id="analyst"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              setFieldValue('contractualPremises.analyst', e && e.label);
            }}
            defaultValue={{ value: 0, label: values.contractualPremises?.analyst || '' }}
            hasError={!!errors.contractualPremises && !!touched.contractualPremises}
            errorMessage={String(errors.contractualPremises)}
            apiCallFunction={apiCallContractualAnelyst || (async () => [])}
            loadOptions={apiCallContractualAnelyst || (async () => [])}
          />
        </ContractualPermissionStyled>
      );
    }, [
      errors.contractualPremises,
      setFieldValue,
      touched.contractualPremises,
      values.contractualPremises?.createdAt,
      values.contractualPremises?.analyst,
      values.contractualPremises?.contractEndDate,
      values.contractualPremises?.contractSignatureDate,
      values.contractualPremises?.contractStartDate,
    ]);

    const convertedOptions = useMemo(() => {
      return optionsInsurancePolicy.map((option) => {
        const label = option.label == 'Embarcador' ? loggedCompany : option.label;
        return { ...option, label: label };
      });
    }, []);

    const renderCargoInsurance = useMemo(() => {
      return (
        <CargoInsurance>
          <Select
            label="Apólice de Seguro"
            id="cargoInsurance.insurancePolicy"
            name="cargoInsurance.insurancePolicy"
            value={values.cargoInsurance?.insurancePolicy}
            setFieldValue={setFieldValue}
            options={convertedOptions}
            hasError={!!errors.cargoInsurance && !!touched.cargoInsurance}
            errorMessage={String(errors.cargoInsurance)}
          />

          <InputCurrency
            label="Gris (%)"
            id="cargoInsurance.gris"
            name="cargoInsurance.gris"
            suffix={' %'}
            defaultValue={0}
            decimalsLimit={3}
            decimalSeparator=","
            groupSeparator="."
            decimalScale={3}
            onValueChange={(value, name) => setFieldValue(name ?? '', value)}
            value={values.cargoInsurance?.gris || ''}
          />

          <InputCurrency
            label="AdValorem (%)"
            id="cargoInsurance.advalorem"
            name="cargoInsurance.advalorem"
            suffix={' %'}
            defaultValue={0}
            decimalsLimit={3}
            decimalSeparator=","
            groupSeparator="."
            decimalScale={3}
            onValueChange={(value, name) => setFieldValue(name ?? '', value)}
            value={values.cargoInsurance?.advalorem || ''}
          />
        </CargoInsurance>
      );
    }, [
      convertedOptions,
      errors.cargoInsurance,
      setFieldValue,
      touched.cargoInsurance,
      values.cargoInsurance?.advalorem,
      values.cargoInsurance?.gris,
      values.cargoInsurance?.insurancePolicy,
    ]);

    const renderClientBilling = useMemo(() => {
      return (
        <ClientBilling>
          <Select
            label="Forma de Pagamento"
            id="clientBilling.paymentMethod"
            name="clientBilling.paymentMethod"
            value={values.clientBilling?.paymentMethod}
            setFieldValue={setFieldValue}
            options={optionsPaymentMethod}
            hasError={!!errors.clientBilling && !!touched.clientBilling}
            errorMessage={String(errors.clientBilling)}
          />
          <Select
            label="Ciclo de Fechamento"
            id="clientBilling.closingCycle"
            name="clientBilling.closingCycle"
            value={values.clientBilling?.closingCycle}
            setFieldValue={setFieldValue}
            options={optionsClosingCycle}
            hasError={!!errors.clientBilling && !!touched.clientBilling}
            errorMessage={String(errors.clientBilling)}
          />
          <Select
            label="Prazo de Pagamento (Dias)"
            id="clientBilling.paymentTerm"
            name="clientBilling.paymentTerm"
            value={values.clientBilling?.paymentTerm}
            setFieldValue={setFieldValue}
            options={optionsPaymentTerm}
            hasError={!!errors.clientBilling && !!touched.clientBilling}
            errorMessage={String(errors.clientBilling)}
          />
          <Select
            label="Requer Pré-Fatura?"
            id="clientBilling.requiresPreInvoice"
            name="type_of_partner"
            value={values.clientBilling?.requiresPreInvoice}
            setFieldValue={setFieldValue}
            options={optionsRequiresPreInvoice}
            hasError={!!errors.clientBilling && !!touched.clientBilling}
            errorMessage={String(errors.clientBilling)}
          />

          {renderCargoInsurance}
        </ClientBilling>
      );
    }, [
      errors.clientBilling,
      renderCargoInsurance,
      setFieldValue,
      touched.clientBilling,
      values.clientBilling?.closingCycle,
      values.clientBilling?.paymentMethod,
      values.clientBilling?.paymentTerm,
      values.clientBilling?.requiresPreInvoice,
    ]);

    return (
      <>
        {openMap && (
          <ModalConfirmation
            title="Selecione o Local"
            handleXCancel={() => {
              setCoord({
                lat: Number(values.lat),
                lng: Number(values.lng),
              });
              setOpenMap(false);
            }}
            onConfirmation={() => {
              setFieldValue('lat', String(coord?.lat));
              setFieldValue('lng', String(coord?.lng));
              setOpenMap(false);
            }}
            size="large"
            type="normal"
            description={<PinMap coord={coord} setCoord={setCoord} />}
          />
        )}
        <FormStyled>
          <SectionStyled name="Informações do Parceiro">
            <LineSectionStyled columns="0.6fr 1fr 3.2fr 1fr">
              <InputField
                label="ID *"
                id="id"
                name="id"
                type="text"
                onChange={handleChange}
                value={String(values.id)}
                disabled={true}
              />
              <Select
                label="Tipo de Parceiro *"
                id="type_of_partner"
                name="type_of_partner"
                value={values.type_of_partner}
                setFieldValue={setFieldValue}
                options={optionsClient}
              />
              <InputField
                label="Razão Social *"
                id="tradeName"
                name="tradeName"
                type="text"
                onChange={handleChange}
                value={values.tradeName}
                hasError={!!errors.tradeName && !!touched.tradeName}
                errorMessage={String(errors.tradeName)}
              />
              <InputFieldCnpj
                label="CNPJ *"
                id="cnpj"
                name="cnpj"
                type="text"
                onChange={handleChange}
                value={values.cnpj}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="2fr 2fr 0.5fr 1.4fr 0.8fr">
              <InputField
                label="Nome Fantasia *"
                id="fantasyName"
                name="fantasyName"
                type="text"
                onChange={handleChange}
                value={values.fantasyName}
                hasError={!!errors.fantasyName && !!touched.fantasyName}
                errorMessage={String(errors.fantasyName)}
              />

              <InputField
                label="Nome da Planta"
                id="plantName"
                name="plantName"
                type="text"
                onChange={handleChange}
                value={values.plantName}
                hasError={!!errors.plantName && !!touched.plantName}
                errorMessage={String(errors.plantName)}
              />

              <InputField
                label="Código Interno"
                id="internalCode"
                name="internalCode"
                type="text"
                onChange={handleChange}
                value={values.internalCode}
                hasError={!!errors.internalCode && !!touched.internalCode}
                errorMessage={String(errors.internalCode)}
              />

              <InputField
                label="Analista Responsável"
                id="responsibleAnalyst"
                name="responsibleAnalyst"
                type="text"
                onChange={handleChange}
                value={values.responsibleAnalyst}
              />

              <InputField
                label="Inscrição Estadual"
                id="ie"
                name="ie"
                type="text"
                onChange={handleChange}
                value={values.ie}
                hasError={!!errors.ie && !!touched.ie}
                errorMessage={String(errors.ie)}
              />
            </LineSectionStyled>
          </SectionStyled>

          <SectionDivider label="Endereço" />
          <SectionStyled name="Endereço">
            <LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
              <InputFieldCep
                label="CEP *"
                id="address.cep"
                name="address.cep"
                type="text"
                onBlur={(e) => onBlurCEPField(e.target.value)}
                onChange={handleChange}
                value={values.address.cep}
                hasError={!!errors.address?.cep && !!touched.address?.cep}
                errorMessage={String(errors.address?.cep)}
              />

              <InputField
                label="Bairro *"
                id="address.neighborhood"
                name="address.neighborhood"
                type="text"
                onChange={handleChange}
                value={values.address?.neighborhood}
                hasError={!!errors.address?.neighborhood && !!touched.address?.neighborhood}
                errorMessage={String(errors.address?.neighborhood)}
              />

              <InputField
                label="Cidade *"
                id="address.city"
                name="address.city"
                type="text"
                onChange={handleChange}
                value={values.address.city}
                hasError={!!errors.address?.city && !!touched.address?.city}
                errorMessage={String(errors.address?.city)}
              />

              <Select
                label="UF *"
                id="address.uf"
                name="address.uf"
                value={values.address.uf}
                setFieldValue={setFieldValue}
                hasError={!!errors.address?.uf && !!touched.address?.uf}
                errorMessage={String(errors.address?.uf)}
                options={uf}
              />
            </LineSectionStyled>

            <LineSectionStyled columns="1.5fr 0.4fr 1.1fr 0.1fr 0.5fr 0.5fr">
              <InputField
                label="Endereço *"
                id="address.street"
                name="address.street"
                type="text"
                onChange={handleChange}
                value={values.address?.street}
                hasError={!!errors.address?.street && !!touched.address?.street}
                errorMessage={String(errors.address?.street)}
              />

              <InputField
                label="Nº *"
                id="address.numberHouse"
                name="address.numberHouse"
                type="text"
                onBlur={handleGetLatLng}
                onChange={handleChange}
                value={values.address.numberHouse}
                hasError={!!errors.address?.numberHouse && !!touched.address?.numberHouse}
                errorMessage={String(errors.address?.numberHouse)}
              />

              <InputField
                label="Complemento"
                id="address.complement"
                name="address.complement"
                type="text"
                onChange={handleChange}
                value={values.address?.complement ?? ''}
                hasError={!!errors.address?.complement && !!touched.address?.complement}
                errorMessage={String(errors.address?.complement)}
              />

              <ContainerButtonStyled>
                <Button
                  bgColor="blue"
                  title="Adicionar Latitude e Longitude"
                  size="very-small"
                  icon={<LocationOnIcon />}
                  callback={() => setOpenMap(true)}
                />
              </ContainerButtonStyled>

              <InputField
                label="Latitude *"
                id="lat"
                name="lat"
                type="text"
                value={values.lat}
                onChange={handleChange}
                hasError={!!errors.lat && !!touched.lat}
                errorMessage={String(errors.lat)}
              />

              <InputField
                label="Longitude *"
                id="lng"
                name="lng"
                type="text"
                value={values.lng}
                onChange={handleChange}
                hasError={!!errors.lng && !!touched.lng}
                errorMessage={String(errors.lng)}
              />
            </LineSectionStyled>
          </SectionStyled>

          <SectionDivider label="E-mail para Agendamento e Monitoramento" />
          <SectionStyled name="Detalhes da Ocorrência">
            <LineSectionStyled columns="1fr 1fr">
              <ResponsibleSolution
                responsables={values?.schedulingEmail}
                placeholder=" Inserir e-mail para Agendamentos e Ordem de Coleta"
                addResponsable={(res) => {
                  setFieldValue('schedulingEmail', res);
                }}
              />

              <ResponsibleSolution
                responsables={values?.occurrenceEmail}
                placeholder="Inserir e-mail para Monitoramento"
                addResponsable={(res) => {
                  setFieldValue('occurrenceEmail', res);
                }}
              />
            </LineSectionStyled>
          </SectionStyled>

          <SectionDivider label="Horário de Funcionamento" />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              marginBottom: 20,
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '48%',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <LabelInAndOutStyled>Inbound</LabelInAndOutStyled>

                <div
                  style={{
                    gap: 29,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 0 10px 0',
                  }}
                >
                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.dom.able"
                      name="inbound.dom.able"
                      checked={values.inbound?.dom?.able === true}
                      onChange={(e) => setFieldValue('inbound.dom.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Domingo</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.seg.able"
                      name="inbound.seg.able"
                      checked={values.inbound?.seg?.able === true}
                      onChange={(e) => setFieldValue('inbound.seg.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Segunda-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.ter.able"
                      name="inbound.ter.able"
                      checked={values.inbound?.ter?.able === true}
                      onChange={(e) => setFieldValue('inbound.ter.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Terça-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.qua.able"
                      name="inbound.qua.able"
                      checked={values.inbound?.qua?.able === true}
                      onChange={(e) => setFieldValue('inbound.qua.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Quarta-feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.qui.able"
                      name="inbound.qui.able"
                      checked={values.inbound?.qui?.able === true}
                      onChange={(e) => setFieldValue('inbound.qui.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Quinta-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.sex.able"
                      name="inbound.sex.able"
                      checked={values.inbound?.sex?.able === true}
                      onChange={(e) => setFieldValue('inbound.sex.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Sexta-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.sab.able"
                      name="inbound.sab.able"
                      checked={values.inbound?.sab?.able === true}
                      onChange={(e) => setFieldValue('inbound.sab.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Sábado</LabelDaysStyled>
                  </LabelSemanalDaysStyled>
                </div>
              </div>

              <ContainerHoursStyled>
                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.dom.firstTime"
                    name="inbound.dom.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.dom?.firstTime}
                    disabled={values.inbound?.dom?.able === null || values.inbound?.dom?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.dom.secondTime"
                    name="inbound.dom.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.dom?.secondTime}
                    disabled={values.inbound?.dom?.able === null || values.inbound?.dom?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.dom?.firstTime, values.inbound?.dom?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.seg.firstTime"
                    name="inbound.seg.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.seg?.firstTime}
                    disabled={values.inbound?.seg?.able === null || values.inbound?.seg?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.seg.secondTime"
                    name="inbound.seg.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.seg?.secondTime}
                    disabled={values.inbound?.seg?.able === null || values.inbound?.seg?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.seg?.firstTime, values.inbound?.seg?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.ter.firstTime"
                    name="inbound.ter.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.ter?.firstTime}
                    disabled={values.inbound?.ter?.able === null || values.inbound?.ter?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.ter.secondTime"
                    name="inbound.ter.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.ter?.secondTime}
                    disabled={values.inbound?.ter?.able === null || values.inbound?.ter?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.ter?.firstTime, values.inbound?.ter?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qua.firstTime"
                    name="inbound.qua.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.qua?.firstTime}
                    disabled={values.inbound?.qua?.able === null || values.inbound?.qua?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qua.secondTime"
                    name="inbound.qua.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.qua?.secondTime}
                    disabled={values.inbound?.qua?.able === null || values.inbound?.qua?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.qua?.firstTime, values.inbound?.qua?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qui.firstTime"
                    name="inbound.qui.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.qui?.firstTime}
                    disabled={values.inbound?.qui?.able === null || values.inbound?.qui?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qui.secondTime"
                    name="inbound.qui.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.qui?.secondTime}
                    disabled={values.inbound?.qui?.able === null || values.inbound?.qui?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.qui?.firstTime, values.inbound?.qui?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sex.firstTime"
                    name="inbound.sex.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.sex?.firstTime}
                    disabled={values.inbound?.sex?.able === null || values.inbound?.sex?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sex.secondTime"
                    name="inbound.sex.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.sex?.secondTime}
                    disabled={values.inbound?.sex?.able === null || values.inbound?.sex?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.sex?.firstTime, values.inbound?.sex?.secondTime)}
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sab.firstTime"
                    name="inbound.sab.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.sab?.firstTime}
                    disabled={values.inbound?.sab?.able === null || values.inbound?.sab?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sab.secondTime"
                    name="inbound.sab.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound?.sab?.secondTime}
                    disabled={values.inbound?.sab?.able === null || values.inbound?.sab?.able === true ? false : true}
                    smallInput
                    hasError={verifyTime(values.inbound?.sab?.firstTime, values.inbound?.sab?.secondTime)}
                  />
                </ContainerDaysStyled>
              </ContainerHoursStyled>
            </div>
            <div
              style={{
                backgroundColor: '#d9d9d9',
                height: '100%',
                width: 1,
              }}
            />

            <div
              style={{
                display: 'flex',
                width: '48%',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <LabelInAndOutStyled>Outbound</LabelInAndOutStyled>

                <div
                  style={{
                    gap: 29,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 0 10px 0',
                  }}
                >
                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.dom.able"
                      name="outbound.dom.able"
                      checked={values.outbound?.dom?.able === true}
                      onChange={(e) => setFieldValue('outbound.dom.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Domingo</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.seg.able"
                      name="outbound.seg.able"
                      checked={values.outbound?.seg?.able === true}
                      onChange={(e) => setFieldValue('outbound.seg.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Segunda-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.ter.able"
                      name="outbound.ter.able"
                      checked={values.outbound?.ter?.able === true}
                      onChange={(e) => setFieldValue('outbound.ter.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Terça-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.qua.able"
                      name="outbound.qua.able"
                      checked={values.outbound?.qua?.able === true}
                      onChange={(e) => setFieldValue('outbound.qua.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Quarta-feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.qui.able"
                      name="outbound.qui.able"
                      checked={values.outbound?.qui?.able === true}
                      onChange={(e) => setFieldValue('outbound.qui.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Quinta-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.sex.able"
                      name="outbound.sex.able"
                      checked={values.outbound?.sex?.able === true}
                      onChange={(e) => setFieldValue('outbound.sex.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Sexta-Feira</LabelDaysStyled>
                  </LabelSemanalDaysStyled>

                  <LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.sab.able"
                      name="outbound.sab.able"
                      checked={values.outbound?.sab?.able === true}
                      onChange={(e) => setFieldValue('outbound.sab.able', e.currentTarget.checked ? true : false)}
                    />
                    <LabelDaysStyled>Sábado</LabelDaysStyled>
                  </LabelSemanalDaysStyled>
                </div>
              </div>

              <ContainerHoursStyled>
                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.dom.firstTime"
                    name="outbound.dom.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.dom?.firstTime}
                    disabled={values.outbound?.dom?.able === null || values.outbound?.dom?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.dom.secondTime"
                    name="outbound.dom.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.dom?.secondTime}
                    disabled={values.outbound?.dom?.able === null || values.outbound?.dom?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.dom?.firstTime, values.outbound?.dom?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.seg.firstTime"
                    name="outbound.seg.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.seg?.firstTime}
                    disabled={values.outbound?.seg?.able === null || values.outbound?.seg?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.seg.secondTime"
                    name="outbound.seg.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.seg?.secondTime}
                    disabled={values.outbound?.seg?.able === null || values.outbound?.seg?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.seg?.firstTime, values.outbound?.seg?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.ter.firstTime"
                    name="outbound.ter.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.ter?.firstTime}
                    disabled={values.outbound?.ter?.able === null || values.outbound?.ter?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.ter.secondTime"
                    name="outbound.ter.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.ter?.secondTime}
                    disabled={values.outbound?.ter?.able === null || values.outbound?.ter?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.ter?.firstTime, values.outbound?.ter?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qua.firstTime"
                    name="outbound.qua.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.qua?.firstTime}
                    disabled={values.outbound?.qua?.able === null || values.outbound?.qua?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qua.secondTime"
                    name="outbound.qua.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.qua?.secondTime}
                    disabled={values.outbound?.qua?.able === null || values.outbound?.qua?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.qua?.firstTime, values.outbound?.qua?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qui.firstTime"
                    name="outbound.qui.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.qui?.firstTime}
                    disabled={values.outbound?.qui?.able === null || values.outbound?.qui?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qui.secondTime"
                    name="outbound.qui.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.qui?.secondTime}
                    disabled={values.outbound?.qui?.able === null || values.outbound?.qui?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.qui?.firstTime, values.outbound?.qui?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sex.firstTime"
                    name="outbound.sex.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.sex?.firstTime}
                    disabled={values.outbound?.sex?.able === null || values.outbound?.sex?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sex.secondTime"
                    name="outbound.sex.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.sex?.secondTime}
                    disabled={values.outbound?.sex?.able === null || values.outbound?.sex?.able === true ? false : true}
                    hasError={verifyTime(values.outbound?.sex?.firstTime, values.outbound?.sex?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>

                <ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sab.firstTime"
                    name="outbound.sab.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.sab?.firstTime}
                    disabled={values.outbound?.sab?.able === null || values.outbound?.sab?.able === true ? false : true}
                    smallInput
                  />
                  <LabelStyled>às</LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sab.secondTime"
                    name="outbound.sab.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound?.sab?.secondTime}
                    disabled={!(values.outbound?.sab?.able === null || values.outbound?.sab?.able === true)}
                    hasError={verifyTime(values.outbound?.sab?.firstTime, values.outbound?.sab?.secondTime)}
                    smallInput
                  />
                </ContainerDaysStyled>
              </ContainerHoursStyled>
            </div>
          </div>

          <div style={{ marginTop: 5 }}>
            <SectionDivider label="Requerimento de Qualidade e Segurança" />
            <Textarea>
              <InputTextarea
                id="quality_and_safety"
                name="quality_and_safety"
                onChange={handleChange}
                value={values.quality_and_safety}
                hasError={!!errors?.quality_and_safety && !!touched?.quality_and_safety}
                errorMessage={String(errors.quality_and_safety)}
              />
            </Textarea>
          </div>
          <ContactDetailsSection />

          <SectionDivider label="Premissas Contratuais" />
          {renderContractualPermission}

          <WrapperSessions>
            <WrapperSessionsFaturamento>
              <SectionDivider label="Faturamento" />
            </WrapperSessionsFaturamento>
            <WrapperSessionsSeguroCarga>
              <SectionDivider label="Seguro de Carga" />
            </WrapperSessionsSeguroCarga>
          </WrapperSessions>
          {renderClientBilling}

          <SectionDivider label="Permissões Operacionais" />
          <ContainerOperationStyled>
            <SectionStyled name="Motoristas">
              <LineSectionStyled columns="1fr">
                <MultiSelect
                  label="Veículos Permitidos"
                  options={[{ value: 'ALL', label: 'TODOS' }, ...vehicleTypeOptions]}
                  menuPosition="fixed"
                  values={vehicleTypeOptions.filter((v) => {
                    if (values?.vehicleType) return values?.vehicleType.includes(v.value as VehicleTypeEntity);
                  })}
                  onChange={(e) => {
                    const vehicles = e.map((option) => option.value);

                    let vehiclesToAdd = vehicles;

                    if (vehicles.includes('ALL')) vehiclesToAdd = vehicleTypeOptions.map((v) => v.value);
                    setFieldValue('vehicleType', vehiclesToAdd);
                  }}
                />
                <MultiSelect
                  label="Categorias Permitidas"
                  values={convertedVehicleCategoryType()}
                  options={optionsVehicleCategory}
                  menuPosition="fixed"
                  onChange={(e) => {
                    const categories = e.map((option) => option.value);
                    setFieldValue('vehicleCategoryType', categories);
                  }}
                />

                <NewMultiSelect<ServicesType>
                  label="Tipos de Serviço"
                  options={[...servicesTypeOptions]}
                  menuPosition="fixed"
                  values={servicesTypeOptions.filter((v) => values.operationType.includes(v.value))}
                  onChange={setServiceType}
                />

                <MultiAsyncSelectField
                  label="Localidades Permitidas"
                  isClearable={true}
                  name="localityIds"
                  id="localityIds"
                  isDisabled={false}
                  cacheOptions={true}
                  defaultOptions={true}
                  onChange={(e) => {
                    setFieldValue('localityIds', e && e.map((e) => e.value));
                  }}
                  hasError={!!errors?.localityIds && !!touched?.localityIds}
                  errorMessage={String(errors.localityIds)}
                  apiCallFunction={localityApiCall}
                  loadOptions={localityApiCall}
                  defaultValue={localityOptions(values.localityIds)}
                />
              </LineSectionStyled>
            </SectionStyled>
          </ContainerOperationStyled>

          <AttachDocumentsSection editingProps={{ code: values.id ?? 0, module: 'client' }} />

          <FooterPage.Root>
            <FooterPage.RightContent>
              <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleVerifyInputs} />
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
            </FooterPage.RightContent>
            <FooterPage.LeftContent>
              <ToggleCheckboxView
                id="status"
                name="status"
                label="Ativo"
                checked={values.status === 'active'}
                onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'active' : 'inactive')}
              />
            </FooterPage.LeftContent>
          </FooterPage.Root>
        </FormStyled>
      </>
    );
  };

  const ClientUpdateFormView = withFormik<IClientDomain, IClientDomain>({
    validationSchema: ClientUpdateSchema,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleUpdate({ ...values, companyId: 1 }),
  })(InnerForm);

  return <ClientUpdateFormView {...initialValues} />;
};

export default ClientUpdateFormView;
