import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Search, Trash2 } from 'lucide-react';
import { Button } from 'logshare-ui-kit';
import { ButtonContainerStyled, LabelContainerStyled } from './attach-documents-table-view.styled';

interface IAttachDocumentsTableViewProps {
  documents: Array<IAttachmentDomain>;
  removeItem: (index: number) => void;
  viewItem: (file?: File | string) => void;
}

export const AttachDocumentsTableView: React.FC<IAttachDocumentsTableViewProps> = ({
  documents,
  removeItem,
  viewItem,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const columnsConfig = '0.7fr 3fr 0.7fr 0.15fr 0.15fr';
    const headerColumns = [
      { value: 'Tipo' },
      { value: 'Nome do Arquivo' },
      { value: 'Validade' },
      { value: '' },
      { value: '' },
    ];

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderButton = useCallback(
    (index: number, type: 'VIEW' | 'REMOVE', file?: File | string) => {
      const isRemove = type === 'REMOVE';
      const icon: ReactElement = isRemove ? (
        <Trash2 size={18} strokeWidth={2.75} />
      ) : (
        <Search size={18} strokeWidth={2.75} />
      );
      const onPressFunction = isRemove ? () => removeItem(index) : () => viewItem(file);

      return (
        <ButtonContainerStyled>
          <Button label="" color="white" onPress={onPressFunction} LeftIcon={icon} size="x-sm" variant="ghost" />
        </ButtonContainerStyled>
      );
    },
    [removeItem, viewItem],
  );

  const renderFormattedDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return documents.map((document, index) => {
      return {
        key: `${index}-${document.fileName?.replace(' ', '-').toLowerCase()}`,
        onClick: () => undefined,
        valuesColumns: [
          { value: <LabelContainerStyled>{document.attachDocumentType}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{document.fileName}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderFormattedDate(document.validateDate)}</LabelContainerStyled> },
          { value: renderButton(index, 'REMOVE') },
          { value: renderButton(index, 'VIEW', document.id ? document.fileUrl : document.file) },
        ],
      };
    });
  }, [documents, renderButton, renderFormattedDate]);

  if (!documents.length) {
    return <EmptyState subTitle="não há documento anexado" height={'100px'} hideIcon />;
  }

  return (
    <Fragment>
      <Table
        alignLinesCenter
        columnFontBold
        config={{ body: { isDisabledScroll: true } }}
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={renderRows}
        height={'auto'}
      />
    </Fragment>
  );
};
