import moment from 'moment';

import { IPortScheduleEntity } from 'pages/santos-port/service/entities/santos-port.entity';
import { IPortSchedule } from 'domain/santos-port';

export const convertSantosPortEntityToDomainMapper = (entity: IPortScheduleEntity): IPortSchedule => ({
  dleId: entity.dleId,
  placa: entity.placa,
  statusDle: entity.statusDle,
  detalheTipoCarga: entity.detalheTipoCarga,
  dtAcessoPortoDle: moment(entity.dtAcessoPortoDle).format('DD/MM/YY HH:mm'),
  dtEntradaPuxada: moment(entity.dtEntradaPuxada).format('DD/MM/YY HH:mm'),
  dtEntradaTerminal: moment(entity.dtEntradaTerminal).format('DD/MM/YY HH:mm'),
  dtEntradaTerminalpp: moment(entity.dtEntradaTerminalpp).format('DD/MM/YY HH:mm'),
  dtSaidaPuxada: moment(entity.dtSaidaPuxada ?? '').format('DD/MM/YY HH:mm'),
  dtSaidaTerminal: moment(entity.dtSaidaTerminal ?? '').format('DD/MM/YY HH:mm'),
  empresaOrigemCep: entity.empresaOrigemCep,
  empresaOrigemCnpj: entity.empresaOrigemCnpj,
  terminalDestino: entity.terminalDestino,
  client: {
    cnpj: entity.client?.cnpj || '',
    companyName: entity.client?.companyName || '',
    address: entity.client?.address || '',
    addressType: entity.client?.addressType || '',
    addressNumber: entity.client?.addressNumber || '',
    complement: entity.client?.complement || '',
    district: entity.client?.district || '',
    cep: entity.client?.cep || '',
    city: entity.client?.city || '',
    sp: entity.client?.sp || '',
  },
  vehicle: {
    nome: entity.vehicle?.nome || '',
    placa: entity.vehicle?.placa || '',
    cidade: entity.vehicle?.cidade || '',
    codigoMunicipio: entity.vehicle?.codigoMunicipio || '',
    cor: entity.vehicle?.cor || '',
    municipio: entity.vehicle?.municipio || '',
    quantidadeEixos: entity.vehicle?.quantidadeEixos || '',
    tipo: entity.vehicle?.tipo || '',
  },
});
