import API from 'utils/API/API';
import { IScheduleCustomFields } from '../../domains/schedule-details.domain';
import { IScheduleCustomFieldsResponse } from './get-schedule-custom-fields.types';
import { converterScheduleCustomFieldsResponseToDomain } from './mappers/converter-schedule-custom-fields-response-to-domain.mapper';

export const getScheduleCustomFieldsService = async (scheduleId: string): Promise<Array<IScheduleCustomFields>> => {
  const { data } = await API.get<Array<IScheduleCustomFieldsResponse>>(`v3/schedule/${scheduleId}/custom-fields`);

  return converterScheduleCustomFieldsResponseToDomain(data);
};
