import { IUserStatusEntity } from 'pages/user/services/entities/user.entity';
import { IUserStatus } from 'domain/user';

export const convertUserStatusEntityToDomain = (statusEntity: IUserStatusEntity): IUserStatus => {
  switch (statusEntity) {
    case 'active':
      return 'ativo';
    case 'inactive':
      return 'inativo';
    default:
      return 'suspenso';
  }
};
