import { FC, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsResumeOperationView } from './schedule-details-resume-operation-view.component';

export const ScheduleDetailsResumeOperation: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const isSpot = useMemo(
    (): boolean => scheduleDetails.scheduleDetails.operation === 'SPOT',
    [scheduleDetails.scheduleDetails.operation],
  );

  const isTracking = useMemo(
    (): boolean => scheduleDetails.scheduleDetails.operation === 'TRACKING',
    [scheduleDetails.scheduleDetails.operation],
  );

  return (
    <ScheduleDetailsResumeOperationView
      isSpot={isSpot}
      isTracking={isTracking}
      company={scheduleDetails.scheduleDetails.company}
      companyBuyer={scheduleDetails.scheduleDetails.companyBuyer}
      shipping={scheduleDetails.scheduleDetails.shipping}
    />
  );
};
