import { AttachDocumentsSectionActionType } from '../../registrations.action';
import { registrationsInitialState } from '../../registrations.constants';
import { IAttachDocumentsSectionActions } from '../../types/attach-documents-section.types';
import { IRegistrationsContext } from '../../types/registrations.types';

export const attachDocumentsSectionReducer = (
  state: IRegistrationsContext,
  action: IAttachDocumentsSectionActions,
): IRegistrationsContext => {
  switch (action.type) {
    case AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_REQUEST:
      return {
        ...state,
        attachDocumentsSection: {
          ...registrationsInitialState.attachDocumentsSection,
          getAttachDocuments: {
            ...registrationsInitialState.attachDocumentsSection.getAttachDocuments,
            statusRequest: 'PROCESSING',
          },
        },
      };
    case AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        attachDocumentsSection: {
          ...registrationsInitialState.attachDocumentsSection,
          getAttachDocuments: {
            ...registrationsInitialState.attachDocumentsSection.getAttachDocuments,
            statusRequest: 'SUCCESS',
          },
        },
      };
    case AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_ERROR:
      return {
        ...state,
        attachDocumentsSection: {
          ...registrationsInitialState.attachDocumentsSection,
          getAttachDocuments: {
            ...registrationsInitialState.attachDocumentsSection.getAttachDocuments,
            statusRequest: 'ERROR',
          },
        },
      };
    case AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_MODAL_OPEN:
      return {
        ...state,
        attachDocumentsSection: {
          ...state.attachDocumentsSection,
          isOpenModal: true,
        },
      };
    case AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_MODAL_CLOSE:
      return {
        ...state,
        attachDocumentsSection: {
          ...state.attachDocumentsSection,
          isOpenModal: false,
        },
      };

    case AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_RESET:
      return {
        ...state,
        attachDocumentsSection: {
          ...registrationsInitialState.attachDocumentsSection,
          getAttachDocuments: {
            ...registrationsInitialState.attachDocumentsSection.getAttachDocuments,
            statusRequest: 'INITIAL',
          },
          isOpenModal: false,
        },
      };
    default:
      return state;
  }
};
