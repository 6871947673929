import * as Yup from 'yup';
import { ContactSchema } from 'src-new/pages/registrations/utils/schemas/contact-schema-validation';
import { AttachmentsSchema } from 'src-new/pages/registrations/utils/schemas/attachment-schema-validation';

export const LocationDetailsSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Texto Muito Curto!').required('Campo Obrigatório'),
  type: Yup.string().required('Campo Obrigatório'),
  address: Yup.object().shape({
    cep: Yup.string().required('Campo Obrigatório'),
    city: Yup.string().required('Campo Obrigatório'),
    neighborhood: Yup.string().required('Campo Obrigatório'),
    street: Yup.string().required('Campo Obrigatório'),
    numberHouse: Yup.string().required('Campo Obrigatório'),
    uf: Yup.string().required('Campo Obrigatório'),
  }),
  contacts: Yup.array().of(ContactSchema),
  attachments: Yup.array().of(AttachmentsSchema),
});
