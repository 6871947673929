import { useCallback, useReducer } from 'react';
import { freightInitialState } from '../../freight.constants';
import { FreightChatActionType } from '../../freight.action';
import { freightReducer } from '../../reducers/freight.reducer';
import { FreightChatPhotoUrl, IFreightChat } from '../../types/freight-chat.types';

export const useFreightChatContextValues = (): IFreightChat => {
  const [state, dispatch] = useReducer(freightReducer, freightInitialState);

  const setFreightChatRequest = useCallback(() => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_REQUEST, payload: undefined });
  }, []);

  const setFreightChatSuccess = useCallback((url: FreightChatPhotoUrl) => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_SUCCESS, payload: url });
  }, []);

  const setFreightChatError = useCallback(() => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_ERROR, payload: undefined });
  }, []);

  const setChatOpen = useCallback(() => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_OPEN, payload: undefined });
  }, []);

  const setChatClose = useCallback(() => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_CLOSE, payload: undefined });
  }, []);

  const setFreightChatReset = useCallback(() => {
    dispatch({ type: FreightChatActionType.FREIGHT_CHAT_RESET, payload: undefined });
  }, []);

  return {
    ...state.freightChat,
    setFreightChatRequest,
    setFreightChatSuccess,
    setFreightChatError,
    chatActions: {
      ...state.freightChat.chatActions,
      setChatOpen,
      setChatClose,
    },
    setFreightChatReset,
  };
};
