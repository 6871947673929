import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import { ScheduleDetailsCargoModalFormView } from './schedule-details-cargo-modal-form-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export interface IScheduleDetailsCargoModalFormProps {
  handleCloseModalCargo: () => void;
}

export const ScheduleDetailsCargoModalForm: FC<IScheduleDetailsCargoModalFormProps> = ({ handleCloseModalCargo }) => {
  const { setFieldValue, values, handleSubmit } = useFormikContext<IUpdateScheduleCargoPayload>();
  const { scheduleRequestStatus } = useContext(ScheduleContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleOnSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updateIsLoading = useMemo(
    () => scheduleRequestStatus.statusRequest === 'PROCESSING',
    [scheduleRequestStatus.statusRequest],
  );

  return (
    <ScheduleDetailsCargoModalFormView
      values={values}
      updateIsLoading={updateIsLoading}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleOnSubmit={handleOnSubmit}
      handleCloseModalCargo={handleCloseModalCargo}
    />
  );
};
