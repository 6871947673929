import styled from 'styled-components';
export const InfoStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 11px;
`;

export const ReactNodeStyled = styled.div``;
