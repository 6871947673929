import { IScheduleOccurrenceListDomain } from '../../../domains/schedule-occurrence-list/schedule-occurrence-list.domain';
import { IScheduleOccurrenceListResponse } from '../get-schedule-occurrence-list.types';
import { convertOccurrenceExpectedDate } from 'src-new/pages/execution/pages/schedule/utils/convert-occurrences-list-dates/convert-occurrence-expected-date.util';
import { convertOccurrenceOverdue } from 'src-new/pages/execution/pages/schedule/utils/convert-occurrences-list-dates/convert-occurrence-overdue-date.util';

export const convertScheduleOccurrenceListResponseToDomain = (
  occurrenceResponse: IScheduleOccurrenceListResponse,
): IScheduleOccurrenceListDomain => {
  const client = occurrenceResponse.client?.tradeName ?? occurrenceResponse.locality?.name ?? '-';

  const occurrenceExpectedDate = convertOccurrenceExpectedDate(
    occurrenceResponse.endDate,
    occurrenceResponse.endTime,
    occurrenceResponse.occurrenceType.sla ?? 0,
    occurrenceResponse.occurrenceTime,
    occurrenceResponse.occurrenceDate,
  );

  const statusResolution = convertOccurrenceOverdue(
    occurrenceResponse.statusResolution,
    occurrenceResponse.occurrenceType?.sla ?? '0',
    `${occurrenceResponse.occurrenceDate} ${occurrenceResponse.occurrenceTime}`,
  );

  return {
    id: occurrenceResponse.id,
    scheduleId: occurrenceResponse.osNumber,
    impact: occurrenceResponse.occurrenceType.impact,
    occurrenceType: occurrenceResponse.occurrenceType.description,
    client,
    initialDate: occurrenceResponse.occurrenceDate,
    endDate: occurrenceExpectedDate,
    status: statusResolution,
    sla: occurrenceResponse.occurrenceType.sla,
  };
};
