import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 20px;
  background-color: #05050581;
  z-index: 999;
  color: #000;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  min-height: 237px;
  max-height: 600px;
  width: 650px;
  overflow-y: auto;
`;

export const ContainerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 45px 0px 45px;
`;

export const ContainerTitlesStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.font.bold};
    padding-bottom: 10px;
  `}
`;

export const SecondTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
`;

export const ContainerCloseModalStyled = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 13px;
  cursor: pointer;
`;

export const SectionStyled = styled.section`
  display: grid;
  grid-gap: 12px;
  flex-direction: column;
  padding: 15px 45px 35px 45px;
`;

export const ContainerFooterStyled = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const PhoneContainer = styled.div`
  width: 70%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 27px;
  gap: 10px;
`;
