/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FinancialCreate,
  IInvoice,
  FinancialListFilter,
  FinancialListSchedulesParams,
  FinancialUploadFile,
  FinancialExcelListFilter,
} from '../models';

import API from 'utils/API/API';
import { ISchedule } from 'domain/scheduling';
import { IListPageV3 } from 'domain/page-info';
import { showToast } from 'components/toast/toast';

export const listFinancialService = async (payload: FinancialListFilter) => {
  const { data } = await API.get('v2/invoicing', {
    params: payload,
  });

  return data;
};

export const excelListFinancialService = async (payload: FinancialExcelListFilter) => {
  const { data } = await API.get('v2/invoicing/export', {
    params: payload,
    responseType: 'arraybuffer',
  });

  return data;
};

export const getInvoicingService = async (id: number): Promise<IInvoice> => {
  const { data } = await API.get(`v2/invoicing/${id}`);

  return data;
};

export const createFinancialService = async (body: FinancialCreate): Promise<IInvoice> => {
  const payload = {
    issuanceDate: body.issuanceDate,
    discount: body.discount,
    addition: body.addition,
    description: body.description,
    cnpjEmissor: body.cnpjEmissor,
    branchId: body.branchId,
    calcFinanceIds: body.calcFinanceIds,
  };

  const { data } = await API.post('v2/invoicing', payload);

  return data;
};

export const createFinancialServiceTransporter = async (payload: FinancialCreate): Promise<IInvoice> => {
  const { data } = await API.post('v2/invoicing/by-transporter', payload);

  return data;
};

export const verifyCompanyOfShippingCompanyIf = async (shippingCompanyId: number): Promise<boolean> => {
  const { data } = await API.get(`v2/companies/find-by-shipping-id/${shippingCompanyId}`);
  if (data) return true;
  return false;
};

export const listAvailableSchedulesByCompanyId = async (
  payload: FinancialListSchedulesParams,
): Promise<IListPageV3<ISchedule>> => {
  const { data } = await API.get('v2/invoicing/schedules', {
    params: payload,
  });

  return data;
};

export const updateInvoiceService = async (
  id: number,
  payload: {
    issuanceDate: string;
    paymentDay: string;
    dueDate: string;
    addition: number;
    discount: number;
    description: string;
    branchId: number;
    calcFinanceIds: number[];
  },
) => {
  return await API.put(`v2/invoicing/${id}/schedules`, payload);
};

export const uploadFileInvoice = async (invoiceId: number, payload: FinancialUploadFile): Promise<void> => {
  const formData = new FormData();

  if (payload.invoiceFile) formData.append('invoiceFile', payload.invoiceFile);
  if (payload.bankSlipFile) formData.append('bankSlipFile', payload.bankSlipFile);
  if (payload.attachment) formData.append('attachment', payload.attachment);

  await API.post(`v2/invoicing/${invoiceId}/files`, formData);
};

export const deleteUploadedFiles = async (id: number, type: string): Promise<void> => {
  try {
    await API.delete(`v2/invoicing/${id}/file/${type}`);
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o Anexo',
    });
  }
};

export const UploadedColumn = async (id: string, data: { dueDate: string; createdAt: string }) => {
  try {
    const res = await API.patch<any>(`v2/invoicing/${id}`, {
      data: data,
    });

    return res;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o Anexo',
    });
  }
};
