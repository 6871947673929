import Select, { MultiValue } from 'react-select';
import React from 'react';

import { ContainerStyled, customStyles, LabelStyled, SelectContainer, MessageErrorStyled } from './multi-select.styled';

import { AllValuesType } from 'domain/global-inputs';

interface Option {
  value: string | Array<string> | number | AllValuesType;
  label: string;
}

interface MultiSelectViewParams {
  id?: string;
  options: Array<Option>;
  placeholder?: string;
  isSearchable?: boolean;
  menuPosition: 'fixed' | 'absolute';
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  values?: Array<Option>;
  onChange: (value: MultiValue<Option>) => void;
}
const MultiSelectView: React.FC<MultiSelectViewParams> = ({
  options,
  hasError = false,
  isSearchable,
  menuPosition,
  placeholder = '',
  label,
  errorMessage,
  values,
  id,
  onChange,
}) => {
  return (
    <ContainerStyled>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <SelectContainer>
        <Select
          styles={customStyles}
          options={options}
          isMulti
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          isSearchable={isSearchable}
          menuPosition={menuPosition}
          value={values}
        />
        {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
      </SelectContainer>
    </ContainerStyled>
  );
};
export default MultiSelectView;
