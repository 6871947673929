import React from 'react';
import { useFormik } from 'formik';

import { InfoStyled, Wrapper, TraceStyled, InputStyled } from './form.styled';

import { FilterService } from 'domain/filter-delivery-tracking';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';
import { DeliveryTrackingIcon } from 'assets/icons/tracking-icon';

interface FromProps {
  values: FilterService;
  filter: (values: FilterService) => void;
}

export const FormView: React.FC<FromProps> = ({ filter, values }) => {
  const formik = useFormik({
    initialValues: {
      ...values,
    },
    onSubmit: (values: FilterService) => {
      filter(values);
    },
  });
  return (
    <Wrapper>
      <DeliveryTrackingIcon />

      <InfoStyled fontSize={20} fontWeight={700}>
        LOCALIZE UM PEDIDO
      </InfoStyled>

      <InfoStyled fontSize={15} fontWeight={500}>
        INFORME OS DADOS PARA RASTREIO
      </InfoStyled>

      <TraceStyled />

      <InputStyled>
        <InfoStyled fontSize={16}>
          Informe o <strong>Código de Rastreio</strong>
        </InfoStyled>
        <InputField
          id="schedulingCode"
          name="schedulingCode"
          type="text"
          upperCase={false}
          onChange={formik.handleChange}
          value={formik.values.schedulingCode}
        />
      </InputStyled>

      <Button title={'Localizar'} callback={formik.handleSubmit} size="large" />
    </Wrapper>
  );
};
