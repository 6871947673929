import React from 'react';

import CompaniesContextProvider from '../states/companies/companies.context';

import CompanyBody from './components/company-body/company-body';

const ListCompaniesManagement: React.FC = () => {
  return (
    <CompaniesContextProvider>
      <CompanyBody />
    </CompaniesContextProvider>
  );
};

export default ListCompaniesManagement;
