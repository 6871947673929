import { ICell } from 'src-new/components/table/table.types';

export const chatModalHeaderColumns: Array<ICell> = [
  { value: 'ID' },
  { value: '' },
  { value: 'Conversa com' },
  { value: 'Parceiro' },
  { value: 'Tipo de Operação' },
  { value: 'Iniciado em' },
  { value: 'Última Mensagem' },
  { value: 'Não Lidas' },
  { value: '' },
];

export const chatModalColumnsConfig = '0.15fr 0.15fr 0.6fr 0.8fr 0.8fr 0.6fr 0.6fr 0.5fr 0.5fr';
