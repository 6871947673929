import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colorsV4.black};
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
  `}
`;
