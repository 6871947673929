import { ICompanyInformationContext, ICompanyReducerAction } from './company-information.types';
import { ICompanyAction } from './company-information.actions';

export const companyReducer = (
  state: ICompanyInformationContext,
  action: ICompanyReducerAction,
): ICompanyInformationContext => {
  switch (action.type) {
    case ICompanyAction.COMPANY_REQUEST: {
      return {
        ...state,
        companyIsLoading: true,
        companyError: null,
      };
    }
    case ICompanyAction.COMPANY_SUCCESS: {
      return {
        ...state,
        companyIsLoading: false,
        company: action.payload,
        companyError: null,
      };
    }
    case ICompanyAction.COMPANY_ERROR: {
      return {
        ...state,
        companyIsLoading: false,
        companyError: action.payload,
      };
    }
    default:
      return state;
  }
};
