import dayjs from 'dayjs';
import { IWeighingListFilterDomain } from '../../pages/weighing-list/domains/weighing-list-filters/weighing-list-filters.domain';

const convertToIsoString = (date: string) => {
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export function buildURL(filters: IWeighingListFilterDomain) {
  const params = new URLSearchParams();

  params.append('search', filters.search);
  if (filters.page) params.append('page', filters.page.toString());
  if (filters.limit) params.append('limit', filters.limit.toString());
  if (filters.ticketId) params.append('ticketId', filters.ticketId.toString());
  if (filters.status) filters.status.forEach((status) => params.append('ticketStatus[]', status));
  if (filters.driverCpf) params.append('driverCpf', filters.driverCpf);
  if (filters.driverName) params.append('driverName', filters.driverName);
  if (filters.shippingCompany) params.append('shippingComanyName', filters.shippingCompany);
  if (filters.vehiclePlate) params.append('vehicleMainPlate', filters.vehiclePlate);
  if (filters.vehicleCategory) params.append('vehicleCategory', filters.vehicleCategory);
  if (filters.collectOrder) params.append('collectOrder', filters.collectOrder);
  if (filters.operation) params.append('operation', filters.operation);
  if (filters.startEntryDate) params.append('startEntryDate', convertToIsoString(filters.startEntryDate));
  if (filters.endEntryDate) params.append('endEntryDate', convertToIsoString(filters.endEntryDate));
  if (filters.startExitDate) params.append('startExitDate', convertToIsoString(filters.startExitDate));
  if (filters.endExitDate) params.append('endExitDate', convertToIsoString(filters.endExitDate));
  if (filters.startScheduledDate) params.append('startScheduledDate', convertToIsoString(filters.startScheduledDate));
  if (filters.endScheduledDate) params.append('endScheduledDate', convertToIsoString(filters.endScheduledDate));
  if (filters.originUf) params.append('originUf', filters.originUf);
  if (filters.originCity) params.append('originCity', filters.originCity);
  if (filters.destinyCity) params.append('destinationCity', filters.destinyCity);
  if (filters.destinyUf) params.append('destinationUf', filters.destinyUf);
  if (filters.column) params.append('column', filters.column);
  if (filters.order) params.append('order', filters.order);
  if (filters.itemId) params.append('itemId', filters.itemId.toString());

  return `${params.toString()}`;
}
