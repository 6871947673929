import React, { ReactElement, useCallback, useMemo } from 'react';
import { ThemeProvider, styled } from '@mui/material/styles';
import { DateOrTimeView, DateTimeValidationError, PickersDay, PickersDayProps, TimeView } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import {
  ContainerStyled,
  DateTimePikerStyled,
  Div,
  LabelStyled,
  MessageErrorStyled,
  appTheme,
  customStyles,
} from './input-date-time-pikers-formik-view.styled';
import { ICustomPickerDayProps } from './types/input-date-time-pickers-formik.types';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<ICustomPickerDayProps>(({ isSelected }) => ({
  borderRadius: '20%',
  ...(isSelected && {
    backgroundColor: '#1F2B4F',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#1F2B4F',
      color: '#fff',
    },
  }),
})) as React.ComponentType<ICustomPickerDayProps>;

interface IInputDateTimePikersFormikViewProps {
  label: string;
  format: string;
  invalidDate: string | null;
  open: boolean;
  hovered: Dayjs | null;
  value?: dayjs.Dayjs | null;
  placeholder?: string;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  views?: Array<DateOrTimeView>;
  paintDays?: boolean;
  weekDaysToPaint?: Array<number>;
  disabled?: boolean;
  disablePast?: boolean;
  errorMessage?: string;
  validateField?: boolean;
  isInSameWeek: (dayA: Dayjs, dayB?: Dayjs | null) => boolean;
  onChange?: (date: dayjs.Dayjs | null) => void;
  handleError: (error: DateTimeValidationError) => void;
  handleOpen: () => void;
  handleClose: () => void;
  handleClick: (isDisabledDay: boolean, day: dayjs.Dayjs) => void;
  handleHovered: (currentMonth: dayjs.Dayjs | null) => void;
  shouldDisableTime?: (value: Dayjs, view: TimeView) => boolean;
}

export const InputDateTimePikersFormikView = ({
  label,
  format,
  invalidDate,
  open,
  hovered,
  value,
  placeholder,
  minDate,
  maxDate,
  views,
  paintDays,
  weekDaysToPaint,
  disabled,
  disablePast,
  errorMessage,
  validateField,
  isInSameWeek,
  onChange,
  handleError,
  handleOpen,
  handleClose,
  handleClick,
  handleHovered,
  shouldDisableTime,
}: Readonly<IInputDateTimePikersFormikViewProps>) => {
  const Day = useCallback(
    (
      props: PickersDayProps<Dayjs> & {
        daysToPaint: Array<{ date: Dayjs }>;
        selectedDay?: Dayjs | null;
        hoveredDay?: Dayjs | null;
      },
    ): ReactElement => {
      const { day, selectedDay, ...other } = props;

      const isDisabledDay = weekDaysToPaint?.includes(day.day());

      const sameDay = isInSameWeek(day, selectedDay);

      return (
        <CustomPickersDay
          {...other}
          day={day}
          onClick={() => handleClick(isDisabledDay ?? false, day)}
          sx={{
            px: 2.3,
            color: isDisabledDay ? '#FF0000' : dayjs(day).isBefore(selectedDay) ? '#5858588d' : '',
            backgroundColor: isDisabledDay ? '#FFE6E6' : '',
          }}
          paintDays={!!paintDays}
          disableMargin
          selected={false}
          isSelected={sameDay}
        />
      );
    },
    [handleClick, isInSameWeek, paintDays, weekDaysToPaint],
  );

  const messageFormated = useMemo(() => {
    if (errorMessage) {
      return errorMessage;
    }

    if (invalidDate) {
      return 'Campo Inválido';
    }
  }, [invalidDate, errorMessage]);

  const renderMessageError = useMemo((): ReactElement | undefined => {
    if (messageFormated && validateField) {
      return <MessageErrorStyled>{messageFormated}</MessageErrorStyled>;
    }
  }, [messageFormated, validateField]);

  return (
    <Div>
      <ContainerStyled>
        <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
          <LabelStyled hasError={!!messageFormated && validateField}>{label}</LabelStyled>
          <DateTimePikerStyled>
            <ThemeProvider theme={appTheme}>
              <DateTimePicker
                dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}.`}
                sx={customStyles(!!disabled)}
                ampm={false}
                views={views ?? ['year', 'month', 'day', 'hours', 'minutes']}
                format={format}
                disabled={disabled}
                open={open}
                onChange={onChange}
                onOpen={handleOpen}
                onClose={handleClose}
                disablePast={disablePast}
                minDateTime={minDate}
                maxDateTime={maxDate}
                shouldDisableTime={shouldDisableTime}
                value={value}
                slots={{
                  day: (props) =>
                    Day({
                      ...props,
                      daysToPaint: [
                        { date: dayjs() },
                        { date: dayjs().add(1, 'day') },
                        { date: dayjs().add(2, 'day') },
                      ],
                    }),
                }}
                slotProps={{
                  textField: {
                    inputProps: {
                      placeholder: placeholder,
                    },
                    focused: false,
                    size: 'small',
                    error: !!validateField && !!messageFormated,
                  },
                  day: (ownerState) => ({
                    selectedDay: value,
                    hovered,
                    onPointerEnter: () => handleHovered(ownerState.currentMonth),
                    onPointerLeave: () => handleHovered(null),
                  }),
                  digitalClockSectionItem: {
                    sx: {
                      '&.Mui-disabled': {
                        backgroundColor: weekDaysToPaint ? '#FFE6E6' : 'transparent',
                        color: weekDaysToPaint ? '#FF0000' : 'black',
                        opacity: weekDaysToPaint ? 1 : 0.38,
                      },
                    },
                  },
                }}
                localeText={{
                  fieldHoursPlaceholder: () => 'HH',
                  fieldMinutesPlaceholder: () => 'MM',
                  fieldDayPlaceholder: () => 'DD',
                  fieldMonthPlaceholder: () => 'MM',
                  fieldYearPlaceholder: () => 'AA',
                }}
                onError={handleError}
              />
            </ThemeProvider>
          </DateTimePikerStyled>
        </LocalizationProvider>
      </ContainerStyled>

      {renderMessageError}
    </Div>
  );
};
