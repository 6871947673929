import { homeCardsInitialValuesDomain } from 'src-new/pages/home/pages/home/domains/home-cards/home-cards-initial-values.domain';
import { HomeCardsActionType } from '../../home.action';
import { IHomeCardsActions } from '../../types/home-cards.types';
import { IHomeContext } from '../../types/home.types';

export const homeCardsReducer = (state: IHomeContext, action: IHomeCardsActions): IHomeContext => {
  switch (action.type) {
    case HomeCardsActionType.HOME_CARDS_REQUEST:
      return {
        ...state,
        homeCards: {
          ...state.homeCards,
          statusRequest: 'PROCESSING',
          homeCards: homeCardsInitialValuesDomain,
        },
      };
    case HomeCardsActionType.HOME_CARDS_SUCCESS:
      return {
        ...state,
        homeCards: {
          ...state.homeCards,
          statusRequest: 'SUCCESS',
          homeCards: action.payload,
        },
      };
    case HomeCardsActionType.HOME_CARDS_ERROR:
      return {
        ...state,
        homeCards: {
          ...state.homeCards,
          statusRequest: 'ERROR',
          homeCards: homeCardsInitialValuesDomain,
        },
      };
    case HomeCardsActionType.HOME_CARDS_RESET:
      return {
        ...state,
        homeCards: {
          ...state.homeCards,
          statusRequest: 'INITIAL',
          homeCards: homeCardsInitialValuesDomain,
        },
      };
    default:
      return state;
  }
};
