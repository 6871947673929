import styled, { css } from 'styled-components';

export const ContainerInputStyled = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  border: 1px solid rgb(244, 240, 245);
  background-color: rgb(234, 234, 234);
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 5px;

  ::placeholder {
    color: '#ABABAB';
  }
`;

export const InputStyled = styled.input`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  border: none;
  background-color: rgb(234, 234, 234);
  outline: none;
  width: 100%;
  border-radius: 5px;
  ::placeholder {
    color: '#ABABAB';
  }
`;

export const ClearValueStyled = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  font-size: 14px;
  font-weight: 700;
  color: #d9d9d9;
`;

export const FiltersSelected = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  background-color: #e8e8e8;
  border-radius: 25px;
  white-space: nowrap;
`;

export const FiltersTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text5};
  `}
`;

export const HandleCloseFilters = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    padding: 5px;
    background-color: #e04747;
    border-radius: 50%;
    font-size: 9px;
    color: ${theme.colorsV2.white};
    cursor: pointer;
  `}
`;
