import { includes } from 'lodash';
import { IFinancialActions, IFinancialContext } from '../types/financial.types';
import { FinancialActionType, PalletFormActionType } from '../financial.action';
import { IPalletFormActions } from '../types/pallet-form.types';
import { palletFormReducer } from './pallet-form/pallet-form.reducer';
import { financialReducerResetState } from './financial-reducer.constants';

export const financialReducer = (state: IFinancialContext, action: IFinancialActions): IFinancialContext => {
  if (includes(Object.values(PalletFormActionType), action.type)) {
    return palletFormReducer(state, action as IPalletFormActions);
  }

  if (action.type === FinancialActionType.FINANCIAL_RESET) {
    return financialReducerResetState(state);
  }

  return state;
};
