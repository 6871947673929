import React, { ChangeEvent, useMemo, useState } from 'react';
import * as S from './weighing-totten-details-form.styled';
import InputField from 'components/input-field/input-field';
import { FormikErrors, FormikTouched } from 'formik';
import { IWeighingTottenDetailsForm } from '../../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import { WeighingTottenButton } from 'src-new/mobile/pages/weighing-totten/components/weighing-totten-button/weighing-totten-button.component';
import { WeighingTottenModalKeyboard } from '../../weighing-totten-modals/weighing-totten-modal-keyboard/weighing-totten-modal-keyboard.component';

interface IWeighingTottenFormViewProps {
  values: IWeighingTottenDetailsForm;
  errors: FormikErrors<IWeighingTottenDetailsForm>;
  touched: FormikTouched<IWeighingTottenDetailsForm>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<IWeighingTottenDetailsForm>>
}

export const WeighingTottenFormView: React.FC<IWeighingTottenFormViewProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleOnSubmit,
  setFieldValue
}) => {
  const [showKeyboard, setShowKeyboard] = useState(false);

  const buildKeyboardModal = useMemo(
    () =>
      showKeyboard ? (
        <WeighingTottenModalKeyboard
          handleClose={() => setShowKeyboard(false)}
          onChange={(e) => setFieldValue('driverCpf', e)}
        />
      ) : null,
    [setFieldValue, showKeyboard],
  );

  return (
    <S.FormStyled>
      <S.WrapperFormStyled>
        <S.WrapperInputStyled>
          CPF Motorista
          <InputField
            label=""
            id="driverCpf"
            name="driverCpf"
            type="text"
            onChange={handleChange}
            value={values.driverCpf}
            hasError={!!errors.driverCpf && !!touched.driverCpf}
            errorMessage={String(errors.driverCpf)}
            upperCase={false}
            onClick={() => setShowKeyboard(true)}
          />
        </S.WrapperInputStyled>
        <S.WrapperLine />
        <S.ButtonSubmit>
          <WeighingTottenButton callback={handleOnSubmit}>Continuar</WeighingTottenButton>
        </S.ButtonSubmit>
        <S.GoBack onClick={() => window.location.reload()}>Voltar</S.GoBack>
        <S.Keyboard>{buildKeyboardModal}</S.Keyboard>
      </S.WrapperFormStyled>
    </S.FormStyled>
  );
};
