import API from 'utils/API/API';
import { convertLocalityEnityToSelectItem } from 'pages/schedule/mappers/convert-locality-entity-to-select-item/convert-locality-entity-to-select-item.mapper';
import { convertClientEntityToSelectItem } from 'pages/schedule/mappers/convert-client-entity-to-select-item/convert-client-entity-to-select-item.mapper';
import { SelectItem } from 'domain/select-item';

export class OriginAndDestinyService {
  searchOriginAndDestiny = async (searchValue: string, clientType: string): Promise<SelectItem[]> => {
    if (clientType === 'CLIENT') {
      if (searchValue) return await this.clientsFilterSearchService(searchValue);
    } else if (clientType === 'LOCALITY') {
      if (searchValue) {
        return await this.localitiesFilterSearchService(searchValue);
      }
    }

    return [];
  };

  private clientsFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
    const { data } = await API.get(`clients-filter?search=${searchValue}`);
    return data.map((client: { tradeName: string; id: number }) => convertClientEntityToSelectItem(client));
  };

  private localitiesFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
    const { data } = await API.get(`localities-filter?search=${searchValue}`);
    return data.map((locality: { name: string; id: number }) => convertLocalityEnityToSelectItem(locality));
  };
}
