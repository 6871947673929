import React, { ReactNode, memo, useMemo } from 'react';

import * as S from './styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface HeaderTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (transportId: string) => void;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: () => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  clickOrderTableBy,
  clickSortDirection,
  arrowOrder,
  isLoading,
  orderSelected,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.1,
      },
      {
        value: 'ID',
        flex: 0.2,
      },

      {
        value: 'Tipo de Serviço',
        flex: 0.5,
      },

      {
        value: 'Data da Conclusão',
        flex: 0.5,
      },

      {
        value: 'Cliente',
        flex: 1,
      },
      {
        value: 'Valor do Serviço',
        flex: 0.5,
      },
      {
        value: 'Nota Fiscal',
        flex: 0.5,
      },
      {
        value: 'Pedido',
        flex: 0.5,
      },
      {
        value: 'Status',
        flex: 0.5,
      },

      {
        value: '',
        flex: 0.35,
      },
    ];
  }, []);

  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.ContainerPagination>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </S.ContainerPagination>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <div>
      <Table
        orderSelected={orderSelected}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        clickOrderBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        arrowOrder={arrowOrder}
        zIndex
      />
      {renderPagination}
    </div>
  );
};

export default memo(HeaderTable);
