import axios, { AxiosError } from 'axios';
import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';
import { IConversationListResponse, IConversationResponse } from './get-conversations-list-by-user.types';

export const getConversationsListByUserService = async (
  userId: number,
  nextPage?: string,
): Promise<Array<IConversationResponse>> => {
  try {
    const chatEnvironment = getChatEnvironment();
    const startingAfter = nextPage ? `&startingAfter=${nextPage}` : '';

    const { data } = await axios.get<IConversationListResponse>(
      `https://api.talkjs.com/v1/${chatEnvironment.appId}/users/${userId}/conversations?limit=100${startingAfter}`,
      {
        headers: {
          Authorization: `Bearer ${chatEnvironment.secretKey}`,
        },
      },
    );

    return data.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      return [];
    }
    throw error;
  }
};
