import styled from 'styled-components';

export const Wrapper = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-left: 5px;
  color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Label = styled.span`
  font-size: 11px;
  font-weight: 700;
  color: red;
`;

export const Div = styled.div``;
