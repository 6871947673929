import React from 'react';

import { TextStyled } from '../map/map-view.styled';

import Loading from 'components/loading/loading';

interface FloatingLoadingProps {
  isLoading?: boolean;
  anchorText?: string;
}

const FloatingLoading: React.FC<FloatingLoadingProps> = ({ anchorText, isLoading }) => {
  return (
    <div data-testid="floating-loading" style={loadingStyles}>
      {isLoading && <Loading />}
      {anchorText && <TextStyled data-testid="floating-loading-text">{anchorText}</TextStyled>}
    </div>
  );
};

const loadingStyles: React.CSSProperties = {
  height: '100px',
  position: 'absolute',
  padding: 10,
  right: 60,
  top: 7,
  zIndex: 1050,
  borderRadius: 5,
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

export default FloatingLoading;
