import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';
import { ScheduleRefusedPageBodyView } from './schedule-refused-page-body-view.component';
import { UserContext } from 'state/user-context';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { convertScheduleRefusedFilters } from 'src-new/pages/execution/pages/schedule-refused/utils/convert-schedule-refused-filters/convert-schedule-refused-filters.util';
import { scheduleRefusedListFiltersInitialValues } from 'src-new/pages/execution/pages/schedule-refused/types/schedule-refused-list-filters/schedule-refused-list-filters-initial-values.types';
import { IScheduleRefusedListParams } from 'src-new/pages/execution/pages/schedule-refused/types/schedule-refused-list-filters/schedule-refused-list-filters.types';
import { ScheduleRefusedListModalType } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/types/schedule-refused-list.types';
import { useCreateScheduleRefusedExcel } from '../../hooks/use-create-schedule-refused-excel/use-create-schedule-refused-excel.hook';

export const ScheduleRefusedPageBody: React.FC = () => {
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);
  const { isShippingCompany } = useContext(UserContext);

  const createScheduleRefusedExcelHook = useCreateScheduleRefusedExcel();

  const scheduleRefusedFilters = useMemo(
    (): Array<IParamsFilter> => convertScheduleRefusedFilters(scheduleRefusedList.scheduleRefusedListFilters.filters),
    [scheduleRefusedList.scheduleRefusedListFilters.filters],
  );

  const appliedFilters = useMemo(
    (): number => scheduleRefusedFilters.filter((filter) => !Array.isArray(filter.value)).length,
    [scheduleRefusedFilters],
  );

  const handleExportExcel = useCallback(
    async () => await createScheduleRefusedExcelHook(),
    [createScheduleRefusedExcelHook],
  );

  const handleChangeSearch = useCallback(
    (search: string) => {
      const { filters, setScheduleRefusedFilters } = scheduleRefusedList.scheduleRefusedListFilters;

      const updatedFilters = {
        ...filters,
        search,
      };

      setScheduleRefusedFilters(updatedFilters);

      sessionStorage.setItem('schedule-refused-list-filters', JSON.stringify(updatedFilters));
    },
    [scheduleRefusedList.scheduleRefusedListFilters],
  );

  const removeScheduleRefusedFilter = useCallback(
    (indexToRemove: number) => {
      const { filters, setScheduleRefusedFilters } = scheduleRefusedList.scheduleRefusedListFilters;

      const updatedParamsArray = [...scheduleRefusedFilters];
      updatedParamsArray.splice(indexToRemove, 1);

      const newFilters: IScheduleRefusedListParams =
        updatedParamsArray.length === 0
          ? scheduleRefusedListFiltersInitialValues
          : updatedParamsArray.reduce((accumulator: any, { key }) => {
              accumulator[key as keyof IParamsFilter] = filters[key as keyof IScheduleRefusedListParams];
              return accumulator;
            }, {});

      const updatedFilters = {
        ...newFilters,
        page: 1,
      };

      setScheduleRefusedFilters(updatedFilters);
      sessionStorage.setItem('schedule-refused-list-filters', JSON.stringify(updatedFilters));
    },
    [scheduleRefusedFilters, scheduleRefusedList.scheduleRefusedListFilters],
  );

  const handleOpenModal = useCallback(
    (modalType: ScheduleRefusedListModalType) =>
      scheduleRefusedList.scheduleRefusedListModals.setScheduleRefusedModalOpen({ modalType, open: true }),
    [scheduleRefusedList.scheduleRefusedListModals],
  );

  useEffect(() => {
    if (scheduleRefusedList.statusRequest === 'INITIAL') {
      const cachedFilters = sessionStorage.getItem('schedule-refused-list-filters');

      if (cachedFilters) {
        scheduleRefusedList.scheduleRefusedListFilters.setScheduleRefusedFilters(JSON.parse(cachedFilters));
      }
    }
  }, [scheduleRefusedList.scheduleRefusedListFilters, scheduleRefusedList.statusRequest]);

  return (
    <ScheduleRefusedPageBodyView
      scheduleRefusedFilters={scheduleRefusedFilters}
      appliedFilters={appliedFilters}
      isShippingCompany={isShippingCompany}
      handleExportExcel={handleExportExcel}
      handleChangeSearch={handleChangeSearch}
      removeScheduleRefusedFilter={removeScheduleRefusedFilter}
      handleOpenModal={handleOpenModal}
    />
  );
};
