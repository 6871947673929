import React from 'react';
import { Formik } from 'formik';
import { invoiceInitialValues } from 'domain-v2/invoice/register/initial-values';
import { ModalForm } from 'components/modal-form/modal-form';
import InvoiceModal from 'pages/freight-payable/freight-payable-list/freight-payable-list-body/components/invoice-form';
import { InvoiceRegisterInput } from 'domain-v2/invoice/register';

interface IModalCreateInvoiceProps {
  scheduleIds: Array<number>;
  companyTakerName: string;
  totalService: number;
  handleCancel: () => void;
  handleSubmit: (values: InvoiceRegisterInput) => void;
}

export const ModalCreateInvoice: React.FC<IModalCreateInvoiceProps> = ({
  handleSubmit,
  handleCancel,
  scheduleIds,
  companyTakerName,
  totalService,
}) => {
  return (
    <Formik initialValues={invoiceInitialValues} onSubmit={(values) => handleSubmit(values)}>
      {(formikHelpers) => (
        <ModalForm
          title="Resumo"
          description={
            <InvoiceModal companyName={companyTakerName} schedules={scheduleIds} totalService={totalService} />
          }
          onConfirmation={() => formikHelpers.handleSubmit()}
          handleCancel={handleCancel}
          size="medium"
          buttonLabel="Faturar"
        />
      )}
    </Formik>
  );
};
