import styled, { css } from 'styled-components';

import { IStatus } from './status-circle-view';

type WrapperProps = {
  isSquare?: boolean;
} & Pick<IStatus, 'bgColor'>;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StickerStyled = styled.div<WrapperProps>`
  ${({ bgColor = 'blue', isSquare = false }) => css`
    display: flex;
    background-color: ${bgColor};
    width: ${!isSquare ? '12px' : '12px'};
    height: ${!isSquare ? '12px' : '12px'};
    border-radius: ${!isSquare ? '50%' : '3px'};
    margin: ${!isSquare ? '3px 0px 0px 0px' : '1px 0px 0px 0px'};
  `}
`;

export const TitleStyled = styled.div`
  font-size: 11px;
  font-weight: 400;
  margin-left: 10px;
  width: fit-content;
`;
