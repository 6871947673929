import React, { useContext } from 'react';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { EquipmentVoucherListPageBody } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/components/equipment-voucher-list-page-body/equipment-voucher-list-page-body.component';
import { EquipmentVoucherContextProvider } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.context';
import { PageHeaderFilterContextProvider } from 'src-new/contexts/page-header-filter/page-header-filter.context';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { equipmentListFilterInitialValues } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/constants/equipment-voucher-list-filters-initial-values.constant';

export const EquipmentVoucherListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <PageHeaderFilterContextProvider initialFilters={equipmentListFilterInitialValues}>
        <InfiniteScrollListTableContextProvider>
          <EquipmentVoucherContextProvider>
            <EquipmentVoucherListPageBody />
          </EquipmentVoucherContextProvider>
        </InfiniteScrollListTableContextProvider>
      </PageHeaderFilterContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
