import { ThemeColorsV2Type } from 'styles/types/colors';

export const convertOccurrenceTag = (occurrenceImpact: string) => {
  const tagType: {
    [key: string]: { label: string; color: ThemeColorsV2Type };
  } = {
    low: {
      label: 'Baixa',
      color: ThemeColorsV2Type.LightGray,
    },
    medium: {
      label: 'Média',
      color: ThemeColorsV2Type.ProYellow,
    },
    high: {
      label: 'Alta',
      color: ThemeColorsV2Type.Red,
    },
    hight: {
      label: 'Alta',
      color: ThemeColorsV2Type.Red,
    },
    atrasado: {
      label: 'Atrasado',
      color: ThemeColorsV2Type.Red,
    },
    no_prazo: {
      label: 'No Prazo',
      color: ThemeColorsV2Type.Green,
    },
  };

  return tagType[occurrenceImpact];
};
