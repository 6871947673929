import React from 'react';

import ScheduleCargoDetailsFormView from './schedule-cargo-details-form-view';

import { IFormSectionProps } from 'pages/schedule/types/form-section.types';

const ScheduleCargoDetailsForm: React.FC<IFormSectionProps> = ({
  errors,
  values,
  handleChange,
  touched,
  setFieldValue,
  handleChangeValues,
  minDeliveryDate,
  autoSelectedDate,
}) => {
  return (
    <ScheduleCargoDetailsFormView
      {...{
        errors,
        values,
        handleChange,
        touched,
        setFieldValue,
        handleChangeValues,
        minDeliveryDate,
        autoSelectedDate,
      }}
    />
  );
};

export default ScheduleCargoDetailsForm;
