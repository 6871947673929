import { IMyAccountEntity } from '../entities/my-account.entity';

import API from 'utils/API/API';
import { convertMyAccountCreatedEntityToDomainMapper } from 'pages/my-account/mappers/convert-my-account-created-entity-to-domain/convert-my-account-created-entity-to-domain.mapper';
import { IMyAccount } from 'domain/my-account';
import { IUser } from 'domain/login';

export const myAccountDetailsService = async (myAccountId: number): Promise<IMyAccount> => {
  const { data } = await API.get<IMyAccountEntity>(`user/${myAccountId}`);
  return convertMyAccountCreatedEntityToDomainMapper(data);
};

export const myAccountMicrosoftDetailsService = async (email: string): Promise<IUser> => {
  const { data } = await API.get(`user/microsoft/${email}`);
  return data;
};