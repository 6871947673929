import dayjs from 'dayjs';

import { PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';
import { ScheduleMyRouteRegisterInput } from 'domain-v2/my-route/register-schedule';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';

export const convertAllScheduleCreate = (
  routeValues: MyRouterRegisterInput,
  allowedVehicle: PlanningRouteVehicle,
  allowedShipping: PlanningRouteShipping,
  scheduleArray: any[],
): ScheduleMyRouteRegisterInput[] => {
  const mappedArray: ScheduleMyRouteRegisterInput[] = scheduleArray.map((schedule) => {
    const time = (schedule.initialTime || '00:00')?.split(':');
    const initialDate = dayjs(schedule.initialDate).set('hour', time[0]).set('minute', time[1]).toISOString();

    return {
      scheduleClientOrigin: {
        clientType: routeValues.origin.type === 'CLIENT' ? 'client' : 'plant',
        clientId: routeValues.origin.type === 'CLIENT' ? routeValues.origin.id : undefined,
        localityId: routeValues.origin.type === 'CLIENT' ? undefined : routeValues.origin.id,
      },
      scheduleClientDestiny: {
        clientType: routeValues.destination.type === 'CLIENT' ? 'client' : 'plant',
        clientId: routeValues.destination.type === 'CLIENT' ? routeValues.destination.id : undefined,
        localityId: routeValues.destination.type === 'CLIENT' ? undefined : routeValues.destination.id,
      },
      scheduleCargo: {
        weight: 0,
        capacity: 0,
        shippingValue: '',
        inPalet: false,
        pallet: 0,
        vehicleType: allowedVehicle.vehicleType,
        vehicleCategoryType: allowedVehicle.vehicleCategory,
        shippingReturn: '',
        operationType: '',
        needHelper: '',
        needBodyGuard: false,
        invoice: '',
        orderNumber: '',
      },
      scheduleDetails: {
        levyInitialDate: initialDate,
        levyEndDate: initialDate,
        levyInitialTime: '',
        levyEndTime: '',
        deliveryInitialDate: schedule.finalDate,
        deliveryEndDate: schedule.finalDate,
        deliveryInitialTime: '',
        deliveryEndTime: '',
        internalNumber: schedule.internalCode,
        ocInternalNumber: '',
        boardingNumber: '',
        schedulingPassword: '',
      },
      scheduleShippingCompanyId: allowedShipping.companyShippingId,
      myRoute: {
        idRoute: Number(routeValues.id),
        relationRouteId: Number(allowedShipping?.id),
        validity: Number(allowedShipping.validity),
      },
      status: 'agendamento',
      statusCode: 'OT01',
      cubagem: '',
      currentFreight: 0,
      value_freight: 0,
      helper_quantity: 0,
      helper_amount: 0,
      helper_total: 0,
      toll: 0,
      gris: 0,
      advalorem: 0,
      value_goods: 0,
      value_total_fee: 0,
      taxes: 0,
      total_freight: 0,
      distance: 0,
      operation: 'TRACKING',
      comments: schedule?.comments,
      isCteLogshare: false,
    };
  });

  return mappedArray;
};
