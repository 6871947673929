import styled, { css } from 'styled-components';

export const ContainerTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 6px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    color: white;
    background-color: ${theme.colors.blue};
    padding: 10px 7px;
    font-weight: 700;
    line-height: 10px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Trace = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.transparent};
    margin-top: 0px;
  `}
`;
