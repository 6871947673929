import { useCallback, useReducer } from 'react';
import { purchaseOfferListReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-list/purchase-offer-list.reducer';
import { PurchaseOfferListActionType } from '../../purchase-offer.action';
import { purchaseOfferInitialState } from '../../purchase-offer.constants';
import { IPurchaseOfferList } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list.types';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { purchaseOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-list-filters-initial-values.constant';

export const usePurchaseOfferListContextValues = (): IPurchaseOfferList => {
  const [state, dispatch] = useReducer(purchaseOfferListReducer, purchaseOfferInitialState);

  const setPurchaseOfferListRequest = useCallback(() => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_LIST_REQUEST, payload: undefined });
  }, []);

  const setPurchaseOfferListSuccess = useCallback((list: Array<IPurchaseOfferListDomain>) => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_LIST_SUCCESS, payload: list });
  }, []);

  const setPurchaseOfferListError = useCallback(() => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_LIST_ERROR, payload: undefined });
  }, []);

  const setPurchaseOfferListReset = useCallback(() => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_LIST_RESET, payload: undefined });
  }, []);

  const setSelectIds = useCallback((ids: Array<number>) => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_SELECT_IDS, payload: ids });
  }, []);

  const setSelectFilters = useCallback((filters: IPurchaseOfferListFilterDomain) => {
    dispatch({ type: PurchaseOfferListActionType.PURCHASE_OFFER_SELECT_FILTERS, payload: filters });
  }, []);

  const setResetFilters = useCallback(() => {
    dispatch({
      type: PurchaseOfferListActionType.PURCHASE_OFFER_SELECT_FILTERS,
      payload: purchaseOfferListFilterInitialValues,
    });
  }, []);

  return {
    ...state.purchaseOfferList,
    setPurchaseOfferListRequest,
    setPurchaseOfferListSuccess,
    setPurchaseOfferListError,
    setPurchaseOfferListReset,
    setSelectIds,
    filters: {
      ...state.purchaseOfferList.filters,
      setSelectFilters,
      setResetFilters,
    },
  };
};
