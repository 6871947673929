import { FC, ReactElement, useMemo } from 'react';
import * as S from './modal-backhaul-purchase-subtitle-view.styled';
import { Tag } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { HotIdeal } from 'assets/icons/hot-ideal';
import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';
import { convertHaulType } from 'src-new/utils/convert-haul-type/convert-haul-type.util';
import { OperationsDomain } from 'src-new/domains/operations.domain';
import { convertDecimalFormat } from 'src-new/utils/convert-decimal-format.util';
import { IRequirementsDomain } from 'src-new/domains/requirements.domain';
import { IBackhaulPurchase } from '../../types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface IModalBackhaulPurchaseSubtitleViewProps {
  backhaulPurchase: IBackhaulPurchase;
  haulType: OperationsDomain;
  co2: number;
  requirements?: IRequirementsDomain;
}

export const ModalBackhaulPurchaseSubtitleView: FC<IModalBackhaulPurchaseSubtitleViewProps> = ({
  backhaulPurchase,
  haulType,
  co2,
  requirements,
}) => {
  const renderRequirements = useMemo((): Array<ReactElement> | undefined => {
    if (!requirements) return;

    const securityValues = requirements.security.values.map((tag) => (
      <Tag key={tag} label={tag} color={ThemeColorsV2Type.Blue} variant="solid" display="inline-flex" size="lg" />
    ));

    const qualityValues = requirements.quality.values.map((tag) => (
      <Tag key={tag} label={tag} color={ThemeColorsV2Type.Blue} variant="solid" display="inline-flex" size="lg" />
    ));

    return [...securityValues, ...qualityValues];
  }, [requirements]);

  const renderExclusiveRate = useMemo((): ReactElement | undefined => {
    if (!backhaulPurchase.saleOffer.backhaulPartner) return;

    return (
      <Tag
        label="Tarifa Exclusiva"
        color={ThemeColorsV2Type.Red}
        LeftIcon={<HotIdeal />}
        variant="ghost"
        display="flex"
        size="lg"
      />
    );
  }, [backhaulPurchase.saleOffer.backhaulPartner]);

  return (
    <S.WrapperTags>
      <Tag
        label={convertHaulType(haulType).label}
        color={convertHaulType(haulType).color}
        variant="solid"
        display="inline-flex"
        size="lg"
      />

      {renderExclusiveRate}

      <Tag
        LeftIcon={
          <S.TagText>
            <DoubleArrowDownBlueIcon />
            CO₂
          </S.TagText>
        }
        label={`${convertDecimalFormat.format(co2)} KG`}
        color={ThemeColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />

      <Tag
        label={backhaulPurchase.saleOffer.typeSchedule}
        color={ThemeColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />

      <Tag
        label={backhaulPurchase.saleOffer.operationType}
        color={ThemeColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />

      {renderRequirements}
    </S.WrapperTags>
  );
};
