import styled from 'styled-components';

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

export const NegotiationLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: left;
  text-transform: uppercase;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ValueLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: left;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 5px;
  color: #7b7b7b;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
