import React, { ReactNode } from 'react';

import { IContentTable } from './table.types';
import TableView from './table-view';

interface ITableProps {
  header: IContentTable;
  content: Array<IContentTable>;
  clickRowAction?: (id: string) => void;
  clickOrderBy?: (type: string) => void;
  clickSortDirection?: (type: string) => void;
  isLoading: boolean;
  arrowOrder?: ReactNode;
  indexColumnId?: number;
  orderSelected?: { orderBy: string; sortDirection: string };
  zIndex?: boolean;
  cursorDefault?: boolean;
}

const Table: React.FC<ITableProps> = ({
  header,
  content,
  orderSelected,
  clickRowAction = () => null,
  clickOrderBy = () => null,
  isLoading,
  clickSortDirection = () => null,
  arrowOrder,
  indexColumnId,
  zIndex,
  cursorDefault,
}: ITableProps) => {
  return (
    <TableView
      orderSelected={orderSelected}
      header={header}
      clickSortDirection={clickSortDirection}
      arrowOrder={arrowOrder}
      content={content}
      clickRowAction={clickRowAction}
      clickOrderBy={clickOrderBy}
      isLoading={isLoading}
      indexColumnId={indexColumnId}
      zIndex={zIndex}
      cursorDefault={cursorDefault}
    />
  );
};

export default Table;
