import styled, { css } from 'styled-components';

export const ContainerVehicleListStyled = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    padding: 20px;
    height: auto;
    border: 1px solid ${theme.colors.forBorder};
    border-radius: 7px;
    margin: 20px 0 20px 0;
  `}
`;

export const HeaderTitleDealings = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.black};
  `}
`;

export const HeaderDivisionLine = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 15px;
    margin-bottom: 20px;
  `}
`;

export const TableStyled = styled.div`
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerInfoStyled = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
