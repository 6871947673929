import { useCallback, useReducer } from 'react';
import { RiskManagementSectionActionType } from '../../registrations.action';
import { registrationsInitialState } from '../../registrations.constants';
import { riskManagementSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/risk-management-section/risk-management-section.reducer';
import { IRiskManagementSection } from 'src-new/pages/registrations/contexts/registrations/types/risk-management-section.types';

export const useRiskManagementSectionContext = (): IRiskManagementSection => {
  const [state, dispatch] = useReducer(riskManagementSectionReducer, registrationsInitialState);

  const setModalOpen = useCallback(() => {
    dispatch({ type: RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_MODAL_OPEN, payload: undefined });
  }, []);

  const setModalClose = useCallback(() => {
    dispatch({ type: RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_MODAL_CLOSE, payload: undefined });
  }, []);

  const setRiskManagementSectionReset = useCallback(() => {
    dispatch({ type: RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_RESET, payload: undefined });
  }, []);

  return {
    ...state.riskManagementSection,
    modalProps: {
      ...state.riskManagementSection.modalProps,
      setModalOpen,
      setModalClose,
    },
    setRiskManagementSectionReset,
  };
};
