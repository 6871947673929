import moment from 'moment';

export const checkOccurrenceOverdue = (sla: string, dateStart: string): boolean => {
  const today = moment();
  const dateStartParsed = moment(dateStart, 'DD/MM/YYYY HH:mm');

  const diffHours = today.diff(dateStartParsed, 'hours');

  return diffHours >= Number(sla?.split(' ')[0]);
};
