import React, { ReactElement, useMemo } from 'react';
import { BreadcrumbBranches } from 'src-new/pages/execution/pages/schedule/utils/breadcrumbs/breadcrumbs-pages.util';
import { ScheduleBreadcrumbEnum } from 'src-new/pages/execution/pages/schedule/utils/breadcrumbs/breadcrumbs-pages.types';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import * as S from './schedule-page-body-view.styled';
import { Search } from 'src-new/components/application-page-header/components/search/search.component';
import { ToogleList } from 'src-new/components/toogle-list/toogle-list.component';
import { ListIcon } from 'assets/icons/list.icon';
import { KanbanListIcon } from 'assets/icons/kanban-list.icon';
import { ScheduleKanbanList } from '../schedule-kanban-list/schedule-kanban-list.component';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { HeaderButtons } from './components/header-buttons/header-buttons.component';
import { HeaderModals } from './components/header-modals/header-modals.component';
import { ScheduleTableTabs } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-table-tabs/schedule-table-tabs.component';
import { ListSubtitle } from 'src-new/components/application-page-header/components/list-subtitle/list-subtitle.component';
import { Breadcrumb } from 'src-new/components/application-page-header/components/header-page/components/breadcrumb/breadcrumb.component';
import LogoIcon from 'assets/logos/logo-icon';
import { Button } from 'logshare-ui-kit';

interface ISchedulePageBodyViewProps {
  viewMode: 'KANBAN' | 'LIST';
  scheduleFilters: Array<IParamsFilter>;
  appliedFilters: number;
  hasCheck: boolean;
  isShippingCompany: boolean;
  handleExportExcel: () => void;
  handleToggleClick: () => void;
  handleModalOpen: (modalType: ScheduleListModalType) => void;
  handleChangeSearch: (search: string) => void;
  removeScheduleFilter: (indexToRemove: number) => void;
}

export const SchedulePageBodyView: React.FC<ISchedulePageBodyViewProps> = ({
  viewMode,
  scheduleFilters,
  appliedFilters,
  hasCheck,
  isShippingCompany,
  handleExportExcel,
  handleToggleClick,
  handleModalOpen,
  handleChangeSearch,
  removeScheduleFilter,
}) => {
  const renderContent = useMemo((): ReactElement => {
    if (viewMode === 'KANBAN') return <ScheduleKanbanList />;

    return <ScheduleTableTabs />;
  }, [viewMode]);

  const renderHeader = useMemo((): ReactElement => {
    if (hasCheck) {
      return (
        <S.HeaderWrapper>
          <Breadcrumb breadcrumb={BreadcrumbBranches()[ScheduleBreadcrumbEnum.SCHEDULE_LIST]} separator={': :'} />
          <S.WrapperButtonsAndIcon>
            <HeaderButtons />
            <S.WrapperLogo>
              <LogoIcon />
            </S.WrapperLogo>
          </S.WrapperButtonsAndIcon>
        </S.HeaderWrapper>
      );
    }

    return <HeaderPage breadcrumbList={BreadcrumbBranches()[ScheduleBreadcrumbEnum.SCHEDULE_LIST]} />;
  }, [hasCheck]);

  const renderNewFreightButton = useMemo((): ReactElement | undefined => {
    if (!isShippingCompany) {
      return (
        <Button
          label="Novo Frete"
          size="md"
          color="blue"
          onPress={() => handleModalOpen('newFreight')}
          variant="solid"
        />
      );
    }
  }, [handleModalOpen, isShippingCompany]);

  return (
    <S.Wrapper>
      <HeaderModals />
      <S.Header>
        {renderHeader}
        <ListSubtitle title="Acompanhe aqui o status da sua operação de transporte." />
      </S.Header>
      <S.ContainerSearch>
        <Search
          placeholder="Pesquise pelo ID, Unidade, Parceiro ou Cidade"
          filters={scheduleFilters.map((item) => item.value)}
          appliedFilters={appliedFilters}
          changeValue={handleChangeSearch}
          handleClearFilters={removeScheduleFilter}
          handleFilterButton={() => handleModalOpen('filter')}
          handleExportButton={handleExportExcel}
          handleImportButton={() => handleModalOpen('importExcel')}
        />
        <ToogleList
          isPressed={viewMode === 'LIST'}
          leftIcon={<ListIcon />}
          rightIcon={<KanbanListIcon />}
          handleToogleClick={handleToggleClick}
        />
        {renderNewFreightButton}
      </S.ContainerSearch>
      <S.Content>{renderContent}</S.Content>
    </S.Wrapper>
  );
};
