import { SingleDatePicker } from 'react-dates';
import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';

import {
  DatePicketSelect,
  IconStyled,
  InputDate,
  InputDateContainer,
  MessageErrorStyled,
  StyledDatePickerWrapper,
} from './styled';

import { CalendarIcon } from 'assets/icons/calendar.icon';

export interface InputFieldCalendarProps {
  date: Moment | null | undefined;
  minDate?: Moment | null;
  errorMessage?: string;
  selectPastDays?: boolean;
  hasError?: boolean;
  onDateChange: (date: moment.Moment | null | any) => void;
  onDatePaste?: (date: any) => void;
}

interface MonthPickerProps {
  month: moment.Moment;
  isVisible: boolean;
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
}

export const InputFieldCalendar: React.FC<InputFieldCalendarProps> = ({
  date,
  minDate,
  errorMessage,
  selectPastDays,
  hasError = false,
  onDateChange,
  onDatePaste,
}): JSX.Element => {
  const [focusedDate, setFocusedDate] = useState(false);
  const [dateInput, setDateInput] = useState('');
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    if (date) {
      setDateInput(date.format('DD/MM/YYYY'));
      setDateError('');
    }
  }, [date]);

  const handleDateChange = () => {
    if (dateInput.length === 10) {
      const [day, month, year] = dateInput.split('/');
      const dataObj = new Date(`${year}-${month}-${day} 12:00`);
      const dateChange = moment(dataObj).toISOString();
      onDateChange(dateChange);
    } else {
      setDateError('Data Inválida');
    }
  };

  const handleInputDate = (event: any, paste?: boolean) => {
    const inputDate = paste
      ? event.clipboardData.getData('text').trim().replace(/-/g, '/').split('\r\n')
      : event.target.value.replace(/\D/g, '');
    let formattedInputDate: Array<{ date: string }> = [];
    if (paste && onDatePaste) {
      inputDate.map((date: string) => {
        if (date.length === 8 || date.length === 7 || date.length === 6) {
          const [day, month, year] = date.split('/');
          const formattedDay = day.length === 1 ? `0${day}` : day;
          const formattedMonth = month.length === 1 ? `0${month}` : month;
          const formattedYear = year.length === 2 ? `20${year}` : year;
          const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
          formattedInputDate = [...formattedInputDate, { date: formattedDate }];
        } else {
          formattedInputDate = [...formattedInputDate, { date: date }];
        }
      });
      onDatePaste(formattedInputDate);
    } else if (!paste) {
      const formattedDate = formatToMask(inputDate);
      setDateInput(formattedDate);
    } else {
      setDateInput(inputDate);
    }
  };

  const formatToMask = (date: any) => {
    if (!date) return '';
    const day = date.slice(0, 2);
    const month = date.slice(2, 4);
    const year = date.slice(4, 8);

    if (day > 31 || month > 12) {
      setDateError('Data inválida');
    } else {
      setDateError('');
    }

    let formattedDate = day;

    if (month) {
      formattedDate += `/${month}`;
    }

    if (year) {
      formattedDate += `/${year}`;
    }

    return formattedDate;
  };

  const monthYearSelectRender = useCallback(({ month, onMonthSelect, onYearSelect }: MonthPickerProps) => {
    const maxSelectableYear = Math.max(moment(moment.now()).add(20, 'year').year(), month.year());
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <DatePicketSelect
            value={month.month()}
            onChange={(e) => {
              onMonthSelect(month, e.target.value);
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </DatePicketSelect>
        </div>
        <div>
          <DatePicketSelect
            value={month.year()}
            onChange={(e) => {
              onYearSelect(month, e.target.value);
            }}
          >
            {Array.from({ length: 100 }, (v, k) => maxSelectableYear - k).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </DatePicketSelect>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <InputDateContainer hasError={hasError || dateError ? true : false}>
        <InputDate
          type="text"
          value={dateInput}
          maxLength={10}
          onChange={handleInputDate}
          onBlur={handleDateChange}
          onPaste={(e: any) => handleInputDate(e, true)}
        />
        <IconStyled onClick={() => setFocusedDate(!focusedDate)}>
          <CalendarIcon />
        </IconStyled>
      </InputDateContainer>
      {focusedDate && (
        <StyledDatePickerWrapper>
          <SingleDatePicker
            id={'date'}
            date={!date?.isValid ? null : date}
            focused={focusedDate}
            onDateChange={onDateChange}
            onFocusChange={({ focused }) => setFocusedDate(focused)}
            isOutsideRange={selectPastDays ? () => false : (day) => day.isBefore(minDate ? minDate : moment(), 'day')}
            renderMonthElement={monthYearSelectRender}
            displayFormat={'DD/MM/YYYY'}
            numberOfMonths={1}
            verticalSpacing={80}
            verticalHeight={450}
            customInputIcon={null}
            hideKeyboardShortcutsPanel
            withPortal
          />
        </StyledDatePickerWrapper>
      )}
      {hasError || (dateError && <MessageErrorStyled>{errorMessage || dateError}</MessageErrorStyled>)}
    </>
  );
};
