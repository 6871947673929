import { includes } from 'lodash';
import { LocationsActionType, LocationsListActionType } from '../locations.action';
import { locationsReducerResetState } from './locations-reducer.constants';
import {
  ILocationsActions,
  ILocationsContext,
} from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations.types';
import { locationsListReducer } from 'src-new/pages/registrations/pages/location/contexts/locations/reducers/locations-list/locations-list.reducer';
import { ILocationsListActions } from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations-list.types';

export const locationsReducer = (state: ILocationsContext, action: ILocationsActions): ILocationsContext => {
  if (includes(Object.values(LocationsListActionType), action.type)) {
    return locationsListReducer(state, action as ILocationsListActions);
  }

  if (action.type === LocationsActionType.LOCATIONS_RESET) {
    return locationsReducerResetState(state);
  }

  return state;
};
