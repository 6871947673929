import styled from 'styled-components';

export const WrapperTags = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
`;

export const TagText = styled.span`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;
