import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { ScheduleFilterView } from './schedule-filter-view.component';
import { scheduleListFiltersInitialValues } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters-initial-values.types';
import { useQueryClient } from 'react-query';
import { UserContext } from 'state/user-context';
import { useGetSavedFilters } from '../../../../hooks/use-get-saved-filters/use-get-saved-filters.hook';
import { TabNames } from 'src-new/pages/execution/pages/schedule/types/schedule-tab-names/schedule-tab-names.types';
import { verifyAppliedFilters } from 'src-new/pages/execution/pages/schedule/utils/applied-filters/applied-filters.util';

interface IScheduleFilterProps {
  scheduleFilters: Array<IParamsFilter>;
  handleModalFilter: () => void;
}

export const ScheduleFilter: React.FC<IScheduleFilterProps> = ({ scheduleFilters, handleModalFilter }) => {
  const { user } = useContext(UserContext);
  const { scheduleList } = useContext(ScheduleContext);
  const reactQueryClient = useQueryClient();
  const getSavedFilters = useGetSavedFilters();

  useEffect(() => {
    if (user && scheduleList.scheduleSavedFiltersProps.savedFilters.length === 0) {
      (async () => {
        await getSavedFilters(user);
      })();
    }
  }, [getSavedFilters, scheduleList.scheduleSavedFiltersProps.savedFilters.length, user]);

  const scheduleFiltersValues = useMemo((): IScheduleListParams => {
    const activeTabName =
      scheduleList.viewModeProps.listViewMode === 'KANBAN'
        ? TabNames.Todos
        : scheduleList.scheduleListFiltersProps.filters.activeTabName;

    return { ...scheduleList.scheduleListFiltersProps.filters, activeTabName, page: 1 };
  }, [scheduleList.scheduleListFiltersProps.filters, scheduleList.viewModeProps.listViewMode]);

  const appliedFilters = useMemo((): number => {
    return verifyAppliedFilters(scheduleFilters);
  }, [scheduleFilters]);

  const handleResetFilter = useCallback(async () => {
    scheduleList.scheduleListFiltersProps.setFilters(scheduleListFiltersInitialValues);
    handleModalFilter();
    await reactQueryClient.invalidateQueries(['schedule-kanban-list', scheduleListFiltersInitialValues]);
  }, [handleModalFilter, reactQueryClient, scheduleList.scheduleListFiltersProps]);

  const onFilterSubmit = useCallback(
    (values: IScheduleListParams) => {
      scheduleList.scheduleListFiltersProps.setFilters({ ...values, page: 1 });
      sessionStorage.setItem('schedule-list-filters', JSON.stringify({ ...values }));
      handleModalFilter();
    },
    [handleModalFilter, scheduleList.scheduleListFiltersProps],
  );

  return (
    <ScheduleFilterView
      scheduleFiltersValues={scheduleFiltersValues}
      appliedFilters={appliedFilters}
      handleResetFilter={handleResetFilter}
      handleModalFilter={handleModalFilter}
      onFilterSubmit={onFilterSubmit}
    />
  );
};
