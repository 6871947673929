import React, { MouseEvent } from 'react';
import * as S from './modal-delete-view.styled';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import { Button } from 'logshare-ui-kit';

interface IModalDeleteViewProps {
  title: string;
  subtitle: string;
  handleClose: () => void;
  handleConfirm: () => void;
  handleBackgroundClick: (event: MouseEvent<HTMLDivElement>) => void;
}

export const ModalDeleteView: React.FC<IModalDeleteViewProps> = ({
  title,
  subtitle,
  handleClose,
  handleConfirm,
  handleBackgroundClick,
}) => {
  return (
    <S.Wrapper onClick={handleBackgroundClick}>
      <S.Content>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <WarningDeleteIcon />
            <S.Title>{title}</S.Title>
            <S.ContainerSubtitle>
              <S.Subtitle>{subtitle}</S.Subtitle>
              <S.Subtitle>Essa ação não poderá ser desfeita</S.Subtitle>
            </S.ContainerSubtitle>
          </S.ContainerTitle>

          <S.ContainerCloseModal>
            <S.CloseModal onClick={handleClose}>X</S.CloseModal>
          </S.ContainerCloseModal>
        </S.ContainerHeader>

        <S.ButtonContainer>
          <Button label="Excluir" color="red" size="md" onPress={handleConfirm} variant="solid" />
        </S.ButtonContainer>
      </S.Content>
    </S.Wrapper>
  );
};
