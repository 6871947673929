import { FC, useCallback, useContext, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalDeleteUploadedMdfe } from '../modal-delete';

import { RenderMdfeInfo } from './styled';

import { downloadDocument, downloadFromUrl } from 'utils/download-file/download-file';
import { UserContext } from 'state/user-context';
import { FileListUploaded } from 'pages/freight/types';
import { ScheduleDomain } from 'domain/schedule';
import Button from 'components/button/button';

interface Props {
  mdfe: FileListUploaded;
  freightValues: ScheduleDomain;
  handleUpdateMdfeData: () => Promise<void>;
}

export const RenderMdfeUploadedData: FC<Props> = ({ mdfe, freightValues, handleUpdateMdfeData }) => {
  const { user, isAdmin } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteMdfe = async () => {
    await handleUpdateMdfeData();
    setIsModalOpen(false);
  };

  const chaveAcesso = useMemo(() => {
    return `${mdfe.name.split('.')[0]?.toUpperCase()}`;
  }, [mdfe]);

  const deleteDisabled = useMemo(() => {
    if (isAdmin) return false;

    if (mdfe.type !== 'MDFe') return false;

    if (
      freightValues.companyIdBuyer === user?.companyId ||
      (freightValues.currentFreight == 2 && !isAdmin && mdfe.type == 'MDFe')
    ) {
      return true;
    }

    return false;
  }, [freightValues, mdfe, user]);

  const updateMdfeBeforeShow = useCallback(async () => {
    downloadFromUrl(mdfe.url);
  }, [mdfe.url]);

  const renderActions = useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <Button
          title="Download"
          callback={() => downloadDocument(mdfe.url, mdfe.name)}
          bgColor="white"
          icon={<DownloadIcon />}
          size="very-small"
        />
        <Button
          title="Visualizar"
          callback={updateMdfeBeforeShow}
          bgColor="white"
          icon={<SearchIcon />}
          size="very-small"
        />
        {user?.plan !== 'shipping-company' && (
          <Button
            title="Deletar"
            callback={() => setIsModalOpen(true)}
            bgColor="pink"
            icon={<DeleteIcon />}
            size="very-small"
            disabled={deleteDisabled}
          />
        )}
      </div>
    );
  }, [mdfe]);

  if (!mdfe) return null;

  return (
    <>
      <ModalDeleteUploadedMdfe
        mdfeData={mdfe}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleDeleteMdfe}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <RenderMdfeInfo>{chaveAcesso}</RenderMdfeInfo>
        {renderActions}
      </div>
    </>
  );
};
