import { ColorsV2Type } from 'styles/types/colors';

type FinancialType = {
  label: string;
  color: ColorsV2Type;
};

type FinancialTypeMap = {
  [key: string]: FinancialType;
};

const defaultValue: FinancialType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertFinancialType = (type: string): FinancialType => {
  const financialTypeMap: FinancialTypeMap = {
    compra: {
      label: 'PAGAR',
      color: ColorsV2Type.Aqua,
    },
    venda: {
      label: 'RECEBER',
      color: ColorsV2Type.Green,
    },
    outros: {
      label: 'OUTRO',
      color: ColorsV2Type.Blue,
    },
  };

  return financialTypeMap[type] || defaultValue;
};
