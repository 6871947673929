import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import FreightUpdateBodyView from './freight-update-form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getScheduleByIdService, schedulingKeysService } from 'services/schedule';
import { queryClient } from 'services/query-client';
import { getCustomFieldsService } from 'pages-v2/schedule/services/custom-fields';
import { updateStatusCodeScheduleService } from 'pages/freight/services/use-status-code-update/use-status-code-update.service';
import { UseSendEmailAllocation } from 'pages/freight/hooks/use-send-email-allocation/use-send-email-allocation.hook';
import { useScheduleHistory } from 'pages/freight/hooks/use-schedule-history/use-schedule-history.hook';
import { ISchedulingKeys, ScheduleDomain } from 'domain/schedule';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface IProps {
  handleGetAllocation: (allocationID: number, statusCode?: string) => void;
  handleAccessRestricted: (accessRestricted: boolean) => void;
}

export interface IScheduleHistory extends IScheduleVehicleHistory {
  id: number;
  created_at: string;
  driver_id: number;
  fisrt_phone: string;
  name_driver: string;
  schedule_id: number;
  vehicle_id: number;
  vehicle_main_board: string;
  vehicle_second_board: string;
  cpf?: number;
  cnh?: number;
}

export interface IScheduleVehicleHistory {
  id: number;
  created_at: string;
  driver_id: number;
  vehicle_type: string;
  vehicle_category: string;
  mainBoard: string;
  secondBoard: string;
  third_board: string;
  capacity: string;
  pallet_capacity: string;
  cubage_capacity: string;
}

const TELA_ANTERIOR = -1;

const FreightUpdateBody: React.FC<IProps> = ({ handleGetAllocation, handleAccessRestricted }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const reactQueryClient = useQueryClient();
  const initialValues = {} as ScheduleDomain;
  const [refresh, setRefresh] = useState<number>(0);
  const [schedulingKeys, setSchedulingKeys] = useState<ISchedulingKeys[]>();
  const [schedule, setSchedule] = useState<ScheduleDomain>(initialValues);
  const [scheduleDriverHistory, setScheduleDriverHistory] = useState<IScheduleHistory[]>();
  const [scheduleVehicleHistory, setScheduleVehicleHistory] = useState<IScheduleHistory[]>();
  const [modalDeleteAllocation, setModalDeleteAllocation] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const getScheduleHistory = useScheduleHistory();
  const sendEmailAllocationHook = UseSendEmailAllocation();

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const findValues = await getScheduleByIdService(id);

          let findKeys = await schedulingKeysService(id);

          if (findKeys.length === 0) {
            findKeys = await getCustomFieldsService();
          }

          setSchedule(findValues);

          setSchedulingKeys(findKeys);

          await reactQueryClient.invalidateQueries(['tripsList']);
        }
      } catch (err: any) {
        if (err.response?.data?.message[0] === 'Não autorizado') {
          return handleAccessRestricted(true);
        }

        showToast({
          message: 'Houve um erro ao buscar os detalhes',
          type: 'error',
        });
      }
    })();
  }, [id, refresh]);

  const goToAllocationList = useCallback(() => {
    navigate(TELA_ANTERIOR);
  }, [TELA_ANTERIOR, navigate]);

  const goToOccurrenceForm = useCallback(() => {
    window.location.href = `/tracking-ocorrencias/novo/${id}`;
  }, [id]);

  useEffect(() => {
    handleGetAllocation(schedule.id, schedule.statusCode);
  }, [schedule]);

  const handleSendEmailAllocation = useCallback(
    async (id: number, destinyEmail: string) => {
      sendEmailAllocationHook(id, destinyEmail)
        .then(() => {
          showMessageFormatted({
            message: 'E-mail enviado com sucesso.',
            type: 'success',
          });
        })
        .catch((error) => {
          showMessageFormatted({
            message: error,
            type: 'error',
          });
        });
    },
    [sendEmailAllocationHook],
  );

  const handleGetScheduleHistory = useCallback(async () => {
    try {
      const shDriver = await getScheduleHistory(Number(id), 'MOTORISTA');
      const shVehicle = await getScheduleHistory(Number(id), 'VEICULO');
      setScheduleDriverHistory(shDriver);
      setScheduleVehicleHistory(shVehicle);
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      handleGetScheduleHistory();
    }
  }, [id]);

  const handleAllocationCancel = useCallback(async (id: number, type: string) => {
    try {
      await updateStatusCodeScheduleService(id, type === 'withoutCost' ? 'AL99' : 'AL90');
      showToast({
        message: 'Viagem cancelada com sucesso',
        type: 'success',
      });
      navigate('/fretes');
    } catch (error) {
      showToast({
        message: 'Erro ao cancelar viagem',
        type: 'error',
      });
    }
  }, []);

  const description = (
    <>
      <p>Você tem certeza que deseja cancelar</p>
      <p>essa viagem?</p>
    </>
  );

  return (
    <>
      {modalDeleteAllocation.open && (
        <ModalConfirmation
          title="Cancelar Viagem"
          cancelWithCost={() => handleAllocationCancel(schedule.id, 'withCost')}
          secondTitle={description}
          handleCancel={() => setModalDeleteAllocation((old) => ({ ...old, open: false }))}
          onConfirmation={() => {
            handleAllocationCancel(schedule.id, 'withoutCost');
            queryClient.invalidateQueries(['tripsList']);
            setModalDeleteAllocation((old) => ({ ...old, open: false }));
          }}
          size="small"
          type="delete"
          description={undefined}
          deleteTitle="Cancelar"
        />
      )}
      <FreightUpdateBodyView
        currentAllocation={schedule}
        schedulingKeys={schedulingKeys}
        scheduleDriverHistory={scheduleDriverHistory}
        scheduleVehicleHistory={scheduleVehicleHistory}
        onRefresh={setRefresh}
        goToAllocationList={goToAllocationList}
        goToOccurrenceForm={goToOccurrenceForm}
        handleSendEmailAllocation={handleSendEmailAllocation}
        handleOpenAuditModal={() => null}
        handleAllocationCancel={() => setModalDeleteAllocation((old) => ({ ...old, open: true }))}
      />
    </>
  );
};

export default FreightUpdateBody;
