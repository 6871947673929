import React from 'react';

import { ICardProps } from './card.types';
import {
  CardDetailsCardModal,
  CardDetailsOnLeftStyled,
  ContainerCardDetailsStyled,
  ContainerDateStyled,
  ContainerPriceTariffStyled,
  ContainerStyled,
  StrongStyled,
  SubNameStyled,
  TariffCompanyStyled,
  TextTariffStyled,
} from './card.styled';

interface IProps {
  cards?: ICardProps[];
}

const CardView: React.FC<IProps> = ({ cards }) => {
  return (
    <ContainerStyled>
      <ContainerCardDetailsStyled>
        {cards &&
          cards.map((card, index) => (
            <CardDetailsOnLeftStyled
              bgColor={card.bgColor}
              color={card.color}
              border={card.border}
              key={index}
              id={card.id}
              onClick={!card.disabled ? card.onClickAction : undefined}
              disabled={card.disabled}
            >
              <div>
                <TariffCompanyStyled>
                  <TextTariffStyled style={{ width: '100%' }}>
                    <div>
                      {card.name}
                      <SubNameStyled> {card.subName}</SubNameStyled>
                    </div>
                    {card.triggerIcon && (
                      <CardDetailsCardModal style={{ width: '20px' }}>{card.triggerIcon}</CardDetailsCardModal>
                    )}
                  </TextTariffStyled>
                  <StrongStyled>{card.partnerName}</StrongStyled>
                  <ContainerPriceTariffStyled></ContainerPriceTariffStyled>
                </TariffCompanyStyled>
                <ContainerDateStyled>
                  {card.dateOf && <TextTariffStyled>{card.dateOf}</TextTariffStyled>}
                  <TextTariffStyled>{card.tariffName}</TextTariffStyled>
                </ContainerDateStyled>
              </div>
            </CardDetailsOnLeftStyled>
          ))}
      </ContainerCardDetailsStyled>
    </ContainerStyled>
  );
};

export default CardView;
