import { LineSectionLayout } from 'components-v2/layout/line-section';
import { FC } from 'react';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';
import { FormikErrors } from 'formik';
import { getVehicleService } from 'src-new/pages/execution/pages/weighing/pages/services/get-vehicle.service/get-vehicle.service';
export interface IAsyncSelectField{
    label: string;
    value: number;
    email?: { name: string; email: string; telephone: string } | undefined;
  }
interface IWeighingFormMountVehiclesViewProps {
  values: IWeighingDomain;
  isMaster: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDomain>>;
  onChange: (values: IAsyncSelectField) => void;
}

export const WeighingFormMountVehiclesView: FC<IWeighingFormMountVehiclesViewProps> = ({
  values,
  isMaster,
  onChange,
}) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr 1fr 1fr 1fr">
        <AsyncSelectField
          placeholder=""
          label="Veículo *"
          name="vehicle.id"
          id="vehicle.id"
          cacheOptions={true}
          defaultOptions={true}
          onChange={(e) => !!e && onChange(e)}
          apiCallFunction={getVehicleService}
          loadOptions={getVehicleService}
          value={{
            label: values.vehicle.mainPlate,
            value: values.vehicle.id,
          }}
          disabled={!isMaster}
        />

        <InputField
          label="Tipo"
          id="vehicle.type"
          name="vehicle.type"
          type="text"
          value={values.vehicle.type}
          disabled
        />
        <InputField
          label="Categoria"
          id="vehicle.category"
          name="vehicle.category"
          type="text"
          value={values.vehicle.category}
          disabled
        />
        <InputField
          label="Placa Secundária"
          id="vehicle.secondPlate"
          name="vehicle.secondPlate"
          type="text"
          value={values.vehicle.secondPlate}
          disabled
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
