import React, { FC, Fragment, useCallback, useContext, useMemo } from 'react';
import { EquipmentVoucherHeaderView } from './equipment-voucher-header-view.component';
import { useNavigate } from 'react-router-dom';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import {
  FilterProps,
  IButtonProps,
  IHeaderProps,
  IInputSearchProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { IEquipmentVoucherListFiltersDomain } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/domains/equipment-voucher-list-filters.domain';
import { EquipmentVoucherContext } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.context';

export const EquipmentVoucherHeader: FC = () => {
  const pageHeaderFilterContext = usePageHeaderFilterContext<IEquipmentVoucherListFiltersDomain>();
  const { equipmentVoucherList } = useContext(EquipmentVoucherContext);
  const navigate = useNavigate();
  const { filters, setFilters } = pageHeaderFilterContext;

  const handleNewVoucher = useCallback(() => {
    navigate('/vale-equipamentos/novo');
  }, [navigate]);

  const handleOpenDeleteModal = useCallback(() => {
    equipmentVoucherList.deleteModalProps.setOpenModal();
  }, [equipmentVoucherList.deleteModalProps]);

  const removeSelectedButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Excluir',
      isVisible: false,
      isLoading: false,
      handleActionButton: handleOpenDeleteModal,
    }),
    [handleOpenDeleteModal],
  );

  const openDeleteModal = useMemo(
    (): boolean => equipmentVoucherList.deleteModalProps.isOpen,
    [equipmentVoucherList.deleteModalProps.isOpen],
  );

  const newButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Nova Registro',
      isVisible: true,
      isLoading: false,
      handleActionButton: handleNewVoucher,
    }),
    [handleNewVoucher],
  );

  const filterProps = useMemo(
    (): FilterProps => ({
      handleOpenModal: () => undefined,
      isDisabledButton: true,
      filterContent: <Fragment />,
      filterCount: 0,
      isVisibleModal: false,
      onCloseButton: () => undefined,
      onClearButton: () => undefined,
    }),
    [],
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilters({ ...filters, search, page: 1 });
    },
    [filters, setFilters],
  );

  const inputProps = useMemo(
    (): IInputSearchProps => ({
      searchPlaceHolder: 'Pesquise pelo ID ou Cliente',
      handleSearchInput: handleSearch,
    }),
    [handleSearch],
  );

  const headerProps = useMemo(
    (): IHeaderProps => ({
      breadcrumbList: ['Financeiro', 'Vale Equipamentos'],
      subtitle: 'Controle o recebimento, armazenagem e devolução de paletes da operação.',
      inputSearchProps: inputProps,
      primaryButtonProps: newButtonProps,
      secondaryButtonProps: removeSelectedButtonProps,
      filterProps,
    }),
    [filterProps, inputProps, newButtonProps, removeSelectedButtonProps],
  );

  return <EquipmentVoucherHeaderView headerProps={headerProps} deleteModalProps={{ isOpen: openDeleteModal }} />;
};
