import { ColorsV2Type } from 'styles/types/colors';

type FreightTypeMap = {
  [key: string]: FreightType;
};

type FreightType = {
  label: string;
  color: ColorsV2Type;
  icon?: React.ReactNode;
};

type OperationType = {
  label: string;
  color: ColorsV2Type;
};

type OperationTypeMap = {
  [key: string]: OperationType;
};

const defaultValue: FreightType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.GrayDark,
};

export const convertFreightType = (freightTypes: string): FreightType => {
  const freightTypeMap: FreightTypeMap = {
    ftl: {
      label: 'FTL',
      color: ColorsV2Type.Blue,
    },
    stl: {
      label: 'STL',
      color: ColorsV2Type.Blue100,
    },
    ltl: {
      label: 'LTL',
      color: ColorsV2Type.Aqua100,
    },
  };

  return freightTypeMap[freightTypes] || defaultValue;
};

export const convertOperationType = (operationTypes: string): OperationType => {
  const operationTypeMap: OperationTypeMap = {
    OUTBOUND: {
      label: 'OUT',
      color: ColorsV2Type.Aqua,
    },
    INBOUND: {
      label: 'IN',
      color: ColorsV2Type.Blue,
    },
    TRANSFERENCIA: {
      label: 'TR',
      color: ColorsV2Type.Red,
    },
    OUT: {
      label: 'OUTBOUND',
      color: ColorsV2Type.Aqua,
    },
    IN: {
      label: 'INBOUND',
      color: ColorsV2Type.Blue,
    },
    TR: {
      label: 'TRANSFERENCIA',
      color: ColorsV2Type.Red,
    },
  };

  return operationTypeMap[operationTypes] || defaultValue;
};
