import React, { useCallback, useContext } from 'react';
import { KanbanSectionView } from './kanban-section-view.component';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { IScheduleKanbanDetails } from '../../../../domains/schedule-kanban-list/schedule-kanban.domain';
import { useNavigate } from 'react-router-dom';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

interface IKanbanSectionProps {
  title: string;
  color: ThemeColorsV2Type;
  kanbanListDetails: Array<IScheduleKanbanDetails>;
  totalItems: number;
}

export const KanbanSection: React.FC<IKanbanSectionProps> = (props) => {
  const { scheduleList } = useContext(ScheduleContext);

  const navigate = useNavigate();

  const handleSelectedFreight = useCallback((id: number) => navigate(`/fretes/${id}`), [navigate]);

  const handleCancelFreight = useCallback(
    (id: number) => {
      scheduleList.modalsProps.setModalOpen({ open: true, ids: [id], modalType: 'cancel' });
    },
    [scheduleList],
  );

  const handleOpenOcurrenceModel = useCallback(
    (id: number) => {
      scheduleList.modalsProps.setModalOpen({ open: true, ids: [id], modalType: 'occurrences' });
    },
    [scheduleList],
  );

  return (
    <KanbanSectionView
      {...props}
      handleSelectedFreight={handleSelectedFreight}
      handleOpenOcurrenceModel={handleOpenOcurrenceModel}
      handleCancelFreight={handleCancelFreight}
    />
  );
};
