import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITransactionsListTableStatus, TransactionsListTableView } from './transactions-list-table-view.component';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { TransactionsContext } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { ISelectedIdsProps } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.types';
import { isEqual } from 'lodash';
import {
  ITransactionsListFilter,
  TransactionActiveTabName,
} from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';
import { transactionsInitialState } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.constants';
import { useGetTransactionsList } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/hooks/use-get-transactions-list/use-get-transactions-list.hook';

interface ITransactionsListTableProps {
  tabName: TransactionActiveTabName;
  selectedIdsProps: ISelectedIdsProps;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const TransactionsListTable: React.FC<ITransactionsListTableProps> = ({
  tabName,
  selectedIdsProps,
  handleInfinityScroll,
}) => {
  const [currentFilter, setCurrentFilter] = useState<ITransactionsListFilter>(
    transactionsInitialState.transactionsList.filterActions.filters,
  );
  const { transactionsList } = useContext(TransactionsContext);
  const { transactions, statusRequest } = transactionsList;
  const { filters, setFilters } = transactionsList.filterActions;
  const navigate = useNavigate();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const [isAllIdsChecked, setIsAllIdsChecked] = useState<boolean>(false);
  const getTransactionsList = useGetTransactionsList();
  const { selectedIds, setSelectedIds } = selectedIdsProps;

  const goToDetailsPage = useCallback(
    (scheduleId: string) => {
      navigate(`/fretes/${scheduleId}`);
    },
    [navigate],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      if (columnName.toUpperCase() !== 'STATUS' && columnName.toUpperCase() !== '') {
        setFilters({ ...filters, order: order, column: columnName });
      }
    },
    [filters, setFilters],
  );

  const orderTable = useMemo(
    (): IOrdering => ({ order: filters.order, columnName: filters.column, handleClick: handleOrdering }),
    [filters.column, filters.order, handleOrdering],
  );

  const isLazyLoading = useMemo(
    (): boolean => statusRequest === 'PROCESSING' && !initialLoading.loading && !filters.isPageLimit,
    [filters.isPageLimit, initialLoading.loading, statusRequest],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, onInfinityScroll: handleInfinityScroll }),
    [handleInfinityScroll, isLazyLoading],
  );

  const handleSelectIds = useCallback(
    (checked: boolean, id: number) => {
      if (checked) {
        const newIds = [...selectedIds, id];
        return setSelectedIds(newIds);
      }

      const removeIds = selectedIds.filter((removeId) => removeId !== id);
      setSelectedIds(removeIds);
    },
    [selectedIds, setSelectedIds],
  );

  const isDisabledTransaction = useCallback((financialStatus: string, tag: string) => {
    const isDisabled = ['Em Auditoria', 'Faturado', 'Pago', 'Liquidado'].includes(financialStatus);
    const isToPay = tag !== 'venda';

    return isDisabled || isToPay;
  }, []);

  const isDisabledAllCheckbox = useMemo(() => {
    const allDisabledTransactions = transactions.filter((transaction) =>
      isDisabledTransaction(transaction.financialStatus, transaction.tag),
    ).length;

    return allDisabledTransactions === transactions.length;
  }, [isDisabledTransaction, transactions]);

  const handleAllSelectIds = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedIds(
          transactions
            .filter((transaction) => !isDisabledTransaction(transaction.financialStatus, transaction.tag))
            .map((transaction) => transaction.id),
        );
        setIsAllIdsChecked(true);
      } else {
        setSelectedIds([]);
        setIsAllIdsChecked(false);
      }
    },
    [isDisabledTransaction, setSelectedIds, transactions],
  );

  const statusProps = useMemo(
    (): ITransactionsListTableStatus => ({
      isError: statusRequest === 'ERROR',
      isInitialLoading: statusRequest === 'PROCESSING' && initialLoading.loading,
      isEmpty: !transactions.length,
    }),
    [initialLoading.loading, transactions.length, statusRequest],
  );

  const getFinancialStatusName = useCallback((name: string): string => {
    const activeTabMap: {
      [key: string]: string;
    } = {
      'A Pagar': 'pagar',
      'A Receber': 'receber',
      'Crédito Transportadora': 'receber transportador',
      'CTe Pendente': 'cte',
    };

    return activeTabMap[name];
  }, []);

  const handleCurrentFilter = useCallback(() => {
    setCurrentFilter(filters);
  }, [filters]);

  useEffect(() => {
    if (statusRequest === 'INITIAL') {
      getTransactionsList(tabName, getFinancialStatusName(tabName));
    }
  }, [getFinancialStatusName, getTransactionsList, statusRequest, tabName]);

  useEffect(() => {
    if ((statusRequest === 'SUCCESS' || statusRequest === 'ERROR') && !isEqual(filters, currentFilter)) {
      handleCurrentFilter();
      getTransactionsList(tabName, getFinancialStatusName(tabName));
    }
  }, [
    currentFilter,
    filters,
    getFinancialStatusName,
    getTransactionsList,
    handleCurrentFilter,
    statusRequest,
    tabName,
  ]);

  useEffect(() => {
    if (!selectedIds.length) {
      setIsAllIdsChecked(false);
    }
  }, [selectedIds.length]);

  return (
    <TransactionsListTableView
      transactions={transactions}
      statusProps={statusProps}
      handleRowAction={goToDetailsPage}
      ordering={orderTable}
      infiniteScrollProps={infiniteScrollProps}
      selectedIds={selectedIds}
      handleSelectIds={handleSelectIds}
      isAllIdsChecked={isAllIdsChecked}
      handleAllSelectIds={handleAllSelectIds}
      isDisabledCheckbox={isDisabledTransaction}
      isDisabledAllCheckbox={isDisabledAllCheckbox}
    />
  );
};
