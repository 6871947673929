import React, { createContext, useCallback, useMemo, useReducer } from 'react';

import {
  IScheduleLTLDriver,
  IScheduleLTLUpdateContext,
  IScheduleLTLShippingCompany,
  IScheduleLTLVehicle,
} from './schedule-ltl.types';
import { allocationRegisterReducer } from './schedule-ltl.reducer';
import { IAllocationsAction, initialState } from './schedule-ltl.actions';

import { ScheduleLTLItem } from 'pages/schedule-ltl/types/schedule-ltl-lts.types';
import { IResumeInfo } from 'components/resume/resume-info.types';

interface IScheduleLTLUpdateContextProviderProps {
  children: React.ReactNode;
}

export const ScheduleLTLUpdateContext = createContext<IScheduleLTLUpdateContext>(initialState);

export const ScheduleLTLUpdateContextProvider: React.FC<IScheduleLTLUpdateContextProviderProps> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(allocationRegisterReducer, initialState);

  const setResumeRules = useCallback((infoRules: IResumeInfo) => {
    dispatch({
      type: IAllocationsAction.SET_RESUME_RULES,
      payload: infoRules,
    });
  }, []);

  const setDriver = useCallback((driver: IScheduleLTLDriver) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_DRIVER_LOAD,
      payload: { driver },
    });
  }, []);

  const setShippingCompany = useCallback((shippingCompany: IScheduleLTLShippingCompany) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_SHIPPING_COMPANY_LOAD,
      payload: { shippingCompany },
    });
  }, []);

  const setVehicle = useCallback((vehicle: IScheduleLTLVehicle) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_VEHICLE_LOAD,
      payload: { vehicle },
    });
  }, []);

  const currentAllocationError = useCallback((message: string) => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_ERROR,
      payload: { message },
    });
  }, []);

  const currentAllocationRequest = useCallback(() => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_REQUEST,
      payload: null,
    });
  }, []);

  const currentAllocationSuccess = useCallback((allocation: ScheduleLTLItem) => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_SUCCESS,
      payload: { allocation },
    });
  }, []);

  const value = useMemo((): IScheduleLTLUpdateContext => {
    return {
      allocation: state.allocation,
      loading: state.loading,
      infoRules: state.infoRules,
      setDriver,
      setShippingCompany,
      setVehicle,
      currentAllocationError,
      currentAllocationRequest,
      currentAllocationSuccess,
      setResumeRules,
    };
  }, [
    state.allocation,
    state.loading,
    state.infoRules,
    setDriver,
    setShippingCompany,
    setVehicle,
    currentAllocationError,
    currentAllocationRequest,
    currentAllocationSuccess,
    setResumeRules,
  ]);

  return <ScheduleLTLUpdateContext.Provider value={value}>{children}</ScheduleLTLUpdateContext.Provider>;
};
