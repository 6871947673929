/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Tag } from 'logshare-ui-kit';
import { width } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalDeleteUploadedCte } from '../modal-delete/modal-delete-uploaded-cte';

import { RenderCTeInfo } from './index.style';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { downloadDocument, downloadFromUrl } from 'utils/download-file/download-file';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { FileListUploaded } from 'pages/freight/types';
import { ScheduleDomain } from 'domain/schedule';
import Button from 'components/button/button';

interface Props {
  index: number;
  cte: FileListUploaded;
  cteKey?: string;
  value?: number;
  onRefresh: () => Promise<any> | any;
  freightValues: ScheduleDomain;
}

export const RenderCTeUploadedData: FC<Props> = (props) => {
  const { user, isAdmin } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteCte = async () => {
    setIsModalOpen(false);
    await props.onRefresh();
  };

  const chaveAcesso = useMemo(() => {
    return `${props.cte.name?.split('.')[0]?.toUpperCase()}`;
  }, [props.cte]);

  const deleteDisabled = useMemo(() => {
    if (isAdmin) return false;

    if (props.cte.type !== 'CTE') return false;

    if (
      props.freightValues.companyIdBuyer === user?.companyId ||
      (props.freightValues.currentFreight == 2 && !isAdmin && props.cte.type == 'CTE')
    ) {
      return true;
    }

    return false;
  }, [props.freightValues, props.cte, user]);

  const updateCTeBeforeShow = useCallback(async () => {
    await props.onRefresh();

    downloadFromUrl(props.cte.url);
  }, []);

  const renderActions = useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <Button
          title="Download"
          callback={() => downloadDocument(props.cte.url, props.cte.name)}
          bgColor="white"
          icon={<DownloadIcon />}
          size="small"
        />
        <Button
          title="Visualizar"
          callback={updateCTeBeforeShow}
          bgColor="white"
          icon={<SearchIcon />}
          size="small"
        />
        {user?.plan !== 'shipping-company' && (
          <Button
            title="Deletar"
            callback={() => setIsModalOpen(true)}
            bgColor="pink"
            icon={<DeleteIcon />}
            size="small"
            disabled={deleteDisabled}
          />
        )}
      </div>
    );
  }, [props.cte]);

  const cteValue = useMemo(() => {
    if (isNaN(Number(props.value))) {
      return 'R$ 0,00';
    }

    return convertCurrencyFormat.format(Number(props.value));
  }, [props.value]);

  if (!props.cte) return null;

  return (
    <>
      <ModalDeleteUploadedCte
        cteData={props.cte}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleDeleteCte}
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1.8fr 0.5fr 0.5fr 0.5fr 1fr 0.1fr',
          alignItems: 'center',
        }}
      >
        <RenderCTeInfo>{chaveAcesso}</RenderCTeInfo>
        <RenderCTeInfo>
          <Tag
            label={props.cte.name?.split('.').pop() ?? ''}
            color={ColorsV2Type.Blue}
            variant="solid"
            display="inline-flex"
            size="md"
          />
        </RenderCTeInfo>
        <RenderCTeInfo>{props.cte.type === 'CTE' ? 'PRIMÁRIO' : 'SUBCONTRATAÇÃO'}</RenderCTeInfo>
        <RenderCTeInfo>{props.cteKey}</RenderCTeInfo>
        <RenderCTeInfo>{cteValue}</RenderCTeInfo>
        <div style={{ width: '165px', marginLeft: '38px'}}>{renderActions}</div>
      </div>
    </>
  );
};
