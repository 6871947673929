import { IOccurrencesTrackingContext, IOccurrencesTrackingReducerAction } from './tracking-occurrence.types';
import { IOccurrencesTrackingAction } from './tracking-occurrence.actions';

export const occurrencesTrackingReducer = (
  state: IOccurrencesTrackingContext,
  action: IOccurrencesTrackingReducerAction,
): IOccurrencesTrackingContext => {
  switch (action.type) {
    case IOccurrencesTrackingAction.OCCURRENCES_TRACKING_REQUEST: {
      return {
        ...state,
        occurrencesTrackingIsLoading: true,
        occurrencesTracking: [],
        occurrencesTrackingError: undefined,
      };
    }
    case IOccurrencesTrackingAction.OCCURRENCES_TRACKING_SUCCESS: {
      return {
        ...state,
        occurrencesTrackingIsLoading: false,
        occurrencesTracking: action.payload.list,
        occurrencesTrackingPageInfo: action.payload.pageInfo,
        occurrencesTrackingError: undefined,
      };
    }
    case IOccurrencesTrackingAction.OCCURRENCES_TRACKING_ERROR: {
      return {
        ...state,
        occurrencesTrackingIsLoading: false,
        occurrencesTracking: [],
        occurrencesTrackingError: action.payload,
        occurrencesTrackingPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
