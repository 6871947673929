/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';

import { ColorsV2Type } from 'styles/types/colors';
import { StickerProps } from 'components/sticker/types/sticker-type';
import BackhaulStickerIcon from 'components/sticker/sticker';

interface Props extends Omit<StickerProps, 'bgColor'> {
  impactType?: string;
}

export const StickerOccurrenceImpactType: React.FC<Props> = (props) => {
  const stickerColor = useMemo(() => {
    if (props.impactType === 'low') return ColorsV2Type.VeryLightGray;
    if (props.impactType === 'medium') return ColorsV2Type.Yellow;
    if (props.impactType === 'high') return ColorsV2Type.Pink;
    if (props.impactType === 'atrasado') return ColorsV2Type.Pink;
    if (props.impactType === 'no_prazo') return ColorsV2Type.Green;

    return ColorsV2Type.Blue;
  }, [props.impactType]);

  const stickerLabel = useMemo(() => {
    if (props.impactType === 'low') return 'Baixa';
    if (props.impactType === 'medium') return 'Média';
    if (props.impactType === 'high') return 'Alta';
    if (props.impactType === 'atrasado') return 'Atrasado';
    if (props.impactType === 'no_prazo') return 'No Prazo';

    return 'a';
  }, [props.impactType]);

  return <BackhaulStickerIcon title={stickerLabel} bgColor={stickerColor} />;
};
