import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { InputMaskCPFEntityToDomain } from 'utils/remove-input-mask/remove-input-mask';
import { IDriverCreatedEntity } from 'pages/driver/services/entities/driver.entity';
import { IDriverCreated } from 'domain/driver';

export const convertDriverCreatedEntityToDomainMapper = (driverEntity: IDriverCreatedEntity): IDriverCreated => ({
  id: driverEntity.id,
  name: driverEntity.name,
  firstPhone: formatCelphoneNumberEntityToDomain(driverEntity.firstPhone),
  secondPhone: formatCelphoneNumberEntityToDomain(driverEntity.secondPhone),
  cnhNumber: driverEntity.cnhNumber,
  cnhValidate: driverEntity.cnhValidate,
  cnhCategory: driverEntity.cnhCategory,
  cpf: InputMaskCPFEntityToDomain(driverEntity.cpf),
  rg: driverEntity.rg,
  ssp: driverEntity.ssp,
  issueDate: driverEntity.issueDate,
  tac: driverEntity.tac,
  certification: driverEntity.certification,
  cep: driverEntity.cep,
  city: driverEntity.city,
  uf: driverEntity.uf,
  neighborhood: driverEntity.neighborhood,
  street: driverEntity.street,
  numberHouse: driverEntity.numberHouse,
  complement: driverEntity.complement,
  driverPhoto: driverEntity.driverPhoto,
  cnhPhoto: driverEntity.cnhPhoto,
  certificationPhoto: driverEntity.certificationPhoto,
  addressProofPhoto: driverEntity.addressProofPhoto,
  shippingCompanyId: Number(driverEntity.shippingCompanyId),
  fleetType: driverEntity.fleetType,
  companyId: driverEntity.companyId,
  status: convertStatusEntityToDomainMapper(driverEntity.status),
  internalCode: driverEntity.internalCode
});
