import React, { ReactNode, useMemo, useState } from 'react';

import { IContentTable } from './table.types';
import {
  ContainerLoadingStyled,
  ContentTableStyled,
  DisplayNoneStyled,
  HeaderTableStyled,
  LineHeaderTableStyled,
  LineTableStyled,
  ObjectStyled,
  SubTitleStyled,
  TableBodyStyled,
  TableHeadStyled,
  TableStyled,
  TextStyled,
  HeaderTextStyled,
  TableRow,
  DoubleReactValues,
} from './table.styled';

import EmptyStateTable from 'components/empty-state-table/empty-state-table';
import SearchEmptyListIcon from 'assets/icons/search-empty-list.icon';
import ArrowOrderUpIcon from 'assets/icons/arrow-order-up.icon';
import ArrowOrderDownIcon from 'assets/icons/arrow-order-down.icon';
import ArrowDown from 'assets/icons/arrow-down.icon';
import { Loading } from 'src-new/components/loading/loading.component';

interface ITableViewProps {
  header: IContentTable;
  arrowOrder: ReactNode;
  content: Array<IContentTable>;
  clickRowAction: (id: string) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: (type: string) => void;
  isLoading: boolean;
  indexColumnId?: number;
  orderSelected?: { orderBy: string; sortDirection: string };
  zIndex?: boolean;
  cursorDefault?: boolean;
}

const TableView: React.FC<ITableViewProps> = ({
  header,
  content,
  orderSelected,
  clickRowAction,
  clickOrderBy,
  isLoading,
  indexColumnId,
  arrowOrder,
  clickSortDirection,
  zIndex,
  cursorDefault,
}: ITableViewProps) => {
  const DEFAULT_COLUMN_ID = 1;
  const columnId = useMemo(() => {
    return indexColumnId ?? DEFAULT_COLUMN_ID;
  }, [indexColumnId]);

  const verifyOrderBy = header.map((item) => item.value).includes('Data Entrega')
    ? 'Data Entrega'
    : header.map((item) => item.value).includes('Tarifa Teto')
      ? 'Data da Oferta'
      : 'ID';

  const [columnState, setColumnState] = useState<string>(
    orderSelected?.orderBy ? orderSelected.orderBy : verifyOrderBy,
  );
  const [orderDirectionState, setOrderDirectionState] = useState<string>(
    orderSelected?.sortDirection ? orderSelected.sortDirection : 'ASC',
  );

  const handleOrder = (orderBy: string, value: string) => {
    setOrderDirectionState(orderDirectionState === 'DESC' ? 'ASC' : 'DESC');
    setColumnState(value);
    clickOrderBy(value);
    clickSortDirection(value);
  };

  const renderHeaderTable = useMemo(() => {
    return (
      <LineHeaderTableStyled>
        {header.map(({ value, flex, justifyContent }, index) => {
          return (
            <HeaderTableStyled
              key={index}
              onClick={() => {
                if (typeof value === 'string') {
                  handleOrder(orderDirectionState, value); // Ordenar pelo valor clicado
                }
              }}
              flex={flex}
              justifyContent={justifyContent}
              cursorDefault={cursorDefault}
            >
              <HeaderTextStyled>{value}</HeaderTextStyled>
              {value ? (
                <div
                  style={{
                    display: 'flex-root',
                    flexDirection: 'column',
                    marginLeft: 5,
                  }}
                >
                  {columnState === value && orderDirectionState === 'DESC' ? (
                    <ArrowOrderDownIcon />
                  ) : columnState === value && orderDirectionState === 'ASC' ? (
                    <ArrowOrderUpIcon />
                  ) : null}
                </div>
              ) : null}
            </HeaderTableStyled>
          );
        })}
      </LineHeaderTableStyled>
    );
  }, [arrowOrder, header, clickOrderBy]);

  const renderContentTable = useMemo(() => {
    return content?.map((contentLine, index) => {
      return (
        <TableRow key={index}>
          <LineTableStyled>
            {contentLine.map(
              ({ value, secondValue, flex, justifyContent, subtitle, isStrong, style, display }, indexLine) => {
                return (
                  <ContentTableStyled
                    key={indexLine}
                    flex={flex}
                    justifyContent={justifyContent}
                    margin={style?.margin}
                    hasAction={!!clickRowAction}
                    display={display}
                    onClick={() => !React.isValidElement(value) && clickRowAction(String(contentLine[columnId].value))}
                    cursorDefault={cursorDefault}
                  >
                    {React.isValidElement(value) ? (
                      <DoubleReactValues>
                        {value}
                        {secondValue}
                      </DoubleReactValues>
                    ) : (
                      <DisplayNoneStyled display={display}>
                        <TextStyled isStrong={isStrong}>{value?.toString().toUpperCase()}</TextStyled>

                        {style?.trace && <ArrowDown />}

                        <ObjectStyled style={style} />

                        <TextStyled isStrong={isStrong}>{secondValue?.toString().toUpperCase()}</TextStyled>

                        <SubTitleStyled isStrong={isStrong}>{subtitle?.toString().toUpperCase()}</SubTitleStyled>
                      </DisplayNoneStyled>
                    )}
                  </ContentTableStyled>
                );
              },
            )}
          </LineTableStyled>
        </TableRow>
      );
    });
  }, [clickRowAction, columnId, content]);

  const renderTableBody = useMemo(() => {
    if (isLoading) {
      return (
        <ContainerLoadingStyled>
          <Loading />
        </ContainerLoadingStyled>
      );
    }

    if (!content?.length)
      return (
        <ContainerLoadingStyled>
          <EmptyStateTable
            isLoading={isLoading}
            text="Lista Vazia"
            subText="Nenhum registro localizado."
            icon={<SearchEmptyListIcon />}
          />
        </ContainerLoadingStyled>
      );

    return renderContentTable;
  }, [content?.length, isLoading, renderContentTable]);

  return (
    <TableStyled>
      <TableHeadStyled zIndex={zIndex}>{renderHeaderTable}</TableHeadStyled>
      <TableBodyStyled>{renderTableBody}</TableBodyStyled>
    </TableStyled>
  );
};

export default TableView;
