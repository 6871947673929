import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { showMessageFormatted } from '../../../../../../../../../utils/message/show-message-formatted/show-message-formatted';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { convertMatchesKanbanListResponseToDomain } from '../../mappers/convert-matches-kanban-list-response-to-domain/convert-matches-kanban-list-response-to-domain.mapper';
import { getInactiveMatchesKanbanListService } from '../../services/get-inactive-matches-kanban-list/get-inactive-matches-kanban-list.service';

export const useGetInactiveMatchesKanbanList = () => {
  const { inactiveMatchesKanbanList, matchesKanbanList } = useContext(MatchesContext);
  const {
    setInactiveMatchesKanbanListRequest,
    setInactiveMatchesKanbanListSuccess,
    setInactiveMatchesKanbanListError,
  } = inactiveMatchesKanbanList;

  const { data } = useQuery<IListPage<IMatchesKanbanListResponse>>({
    queryKey: ['inactive-matches-kanban-list', matchesKanbanList.matchesListFiltersProps.filters],
    queryFn: () => {
      setInactiveMatchesKanbanListRequest();

      return getInactiveMatchesKanbanListService(matchesKanbanList.matchesListFiltersProps.filters);
    },
    onSuccess: (data) => {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);

      setInactiveMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    },
    onError: (error) => {
      setInactiveMatchesKanbanListError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);
      setInactiveMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    }
  }, [data, setInactiveMatchesKanbanListSuccess]);
};
