import { useCallback } from 'react';

import { transportDetailsService } from '../../services/transport-details/transport-details.service';

import { ITransportCompanyCreated } from 'domain/transport-company';

const useDetailsTransportHook = () => {
  return useCallback(async (transportId: number): Promise<ITransportCompanyCreated> => {
    const transport = await transportDetailsService(transportId);
    return transport;
  }, []);
};

export { useDetailsTransportHook };
