/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'utils/API/API';
import { FileListUploaded } from 'pages/freight/types';
import { showToast } from 'components/toast/toast';

export const uploadFiles = async (vehicleId: number | string, files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'FOTOS/REGISTROS');
    formData.append('module', 'vehicle');
    formData.append('codigo', String(vehicleId));

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Anexo',
    });
    throw new Error('Houve um erro ao realizar o upload do Anexo');
  }
};

export const listUploadedFiles = async (vehicleId: number | string): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>(`v2/file/management?module=vehicle&type=FOTOS/REGISTROS&codigo=${vehicleId}`);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao listar o Anexo',
    });
    throw new Error('Houve um erro ao listar o Anexo.');
  }
};

export const deleteUploadedFiles = async (vehicleId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${vehicleId}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o Anexo',
    });
    throw new Error('Houve um erro ao excluir o Anexo.');
  }
};

export const uploadTemplate = async (files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'TEMPLATE-VEHICLE');
    formData.append('module', 'vehicle');
    formData.append('codigo', '01');
    formData.append('file', files);

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Template',
    });
    throw new Error('Houve um erro ao realizar o upload do Template.');
  }
};

export const listUploadedTemplate = async (): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>('v2/file/management?module=vehicle&type=TEMPLATE-VEHICLE&codigo=01');

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao baixar o template',
    });
    throw new Error('Houve um erro ao baixar o template.');
  }
};

export const deleteUploadedTemplate = async (templateId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${templateId}`);
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o template',
    });
    throw new Error('Houve um erro ao excluir o template.');
  }
};
