import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { WeighingDuplicatedPageBody } from './components/weighing-duplicated-page-body/weighing-duplicated-page-body.component';
import { WeighingContextProvider } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';

export const WeighingDuplicatedFormPage: FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <WeighingContextProvider>
        <WeighingDuplicatedPageBody />
      </WeighingContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
