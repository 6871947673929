import React from 'react';

import SantosPortContextProvider from '../contexts/table-states/santos-port.context';

import SantosPortListBody from './components/santos-port-list-body/santos-port-list-body';

const SantosPortListPage: React.FC = () => {
  return (
    <SantosPortContextProvider>
      <SantosPortListBody />
    </SantosPortContextProvider>
  );
};

export default SantosPortListPage;
