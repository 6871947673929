import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBreadcrumb } from './breadcrumb.types';
import { BreadcrumbView } from './breadcrumb-view.component';

export const Breadcrumb: React.FC<IBreadcrumb> = ({ breadcrumb, separator, hasBackButton, handleGoBackButton }) => {
  const navigate = useNavigate();

  const handleBackScreen = useCallback(() => {
    navigate(-1);

    if (handleGoBackButton) {
      handleGoBackButton();
    }
  }, [handleGoBackButton, navigate]);

  return (
    <BreadcrumbView
      breadcrumb={breadcrumb}
      separator={separator}
      hasBackButton={hasBackButton}
      handleBackScreen={handleBackScreen}
    />
  );
};
