import { locationRegisterService } from 'pages/location/services/location-register/location-register.service';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { useSaveUploadFile } from 'src-new/pages/registrations/hooks/use-save-upload-file/use-save-upload-file.hook';

const useLocationRegisterHook = () => {
  const saveUploadFile = useSaveUploadFile();

  return async (location: ILocationDomain, uploadedList: Array<IAttachmentDomain>) => {
    const { id } = await locationRegisterService(location);

    await saveUploadFile(id, uploadedList, 'location');
  };
};

export { useLocationRegisterHook };
