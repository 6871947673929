import { useCallback, useReducer } from 'react';
import { IPurchaseOfferListDownloadReport } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list-download-report.types';
import { purchaseOfferListDownloadReportReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-list-download-report/purchase-offer-list-download-report.reducer';
import { purchaseOfferInitialState } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.constants';
import { PurchaseOfferListDownloadReportActionType } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.action';

export const usePurchaseOfferListDownloadReportContextValues = (): IPurchaseOfferListDownloadReport => {
  const [state, dispatch] = useReducer(purchaseOfferListDownloadReportReducer, purchaseOfferInitialState);

  const setPurchaseOfferListDownloadReportRequest = useCallback(() => {
    dispatch({
      type: PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferListDownloadReportSuccess = useCallback(() => {
    dispatch({
      type: PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferListDownloadReportError = useCallback(() => {
    dispatch({
      type: PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_ERROR,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferListDownloadReportReset = useCallback(() => {
    dispatch({
      type: PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_RESET,
      payload: undefined,
    });
  }, []);

  return {
    ...state.purchaseOfferListDownloadReport,
    setPurchaseOfferListDownloadReportRequest,
    setPurchaseOfferListDownloadReportSuccess,
    setPurchaseOfferListDownloadReportError,
    setPurchaseOfferListDownloadReportReset,
  };
};
