import styled, { css } from 'styled-components';

export const HeaderMenuStyled = styled.div<{ updateLayout: boolean }>`
  ${({ updateLayout }) => css`
    position: absolute;
    left: ${updateLayout ? '160px' : '0px'};
    transition: all 0.18s ease-in-out;
  `}
`;

type SalutationStyledProps = { updateLayout: boolean; disabled: boolean };
export const SalutationStyled = styled.div<SalutationStyledProps>`
  ${({ theme, updateLayout, disabled }) => css`
    display: ${disabled ? 'none' : 'flex'};
    position: fixed;
    width: 120px;
    height: 24px;
    left: ${updateLayout ? '250px' : '96px'};
    top: 88px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${theme.colors.blue};
    transition: all 0.18s ease-in-out;

    @media (max-width: 600px) {
      display: none;
    }
  `}
`;
export const UsernameStyled = styled.span`
  font-weight: 700;
  margin-left: 5px;
`;
