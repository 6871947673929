import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Button } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';
import dayjs, { Dayjs } from 'dayjs';

import * as S from './styled';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { scheduleRegisterAllServiceByRoute } from 'pages-v2/my-route/service/create-all-schedule';
import { PlanningScheduleInput } from 'pages-v2/my-route/components/form/types';
import { planningScheduleInitialValues } from 'pages-v2/my-route/components/form/initial-values';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { ModalState } from 'domain-v2/inputs/modals';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { SectionStyled } from 'components-v2/layout/section/styled';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import InputFieldTime from 'components/input-time/input-time';
import { InputFieldCalendar } from 'components/input-field-calendar';
import InputField from 'components/input-field/input-field';
import { useQueryClient } from 'react-query';

interface PlanningScheduleModalProps {
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
  handleClosePlanningScheduleModal: () => void;
  planningSchedulesModal: ModalState<{
    planningRouteIndex: number;
    allowedVehicleIndex: number;
    allowedShippingIndex: number;
  }>;
}

export const PlanningScheduleModal: FC<PlanningScheduleModalProps> = ({
  values,
  handleClosePlanningScheduleModal,
  planningSchedulesModal,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reactQueryClient = useQueryClient();

  const valuesPlanning = useMemo(() => {
    const allowedVehicle =
      values.planningRoute[planningSchedulesModal.data.planningRouteIndex].allowedVehicles[
        planningSchedulesModal.data.allowedVehicleIndex
      ];

    const allowedShipping = values.planningRoute[planningSchedulesModal.data.planningRouteIndex].allowedVehicles[
      planningSchedulesModal.data.allowedVehicleIndex
    ].allowedShipping.filter((remove) => remove.companyShippingId)[planningSchedulesModal.data.allowedShippingIndex];

    const plannedTripsCount = allowedShipping.plannedTrips - allowedShipping.offeredTrips;

    return { allowedVehicle, allowedShipping, plannedTripsCount };
  }, [values.planningRoute]);

  const initialDetails = Array.from(
    { length: valuesPlanning.plannedTripsCount },
    (): PlanningScheduleInput => planningScheduleInitialValues,
  );

  const [planningScheduleForm, setPlanningScheduleForm] = useState<{
    planningSchedule: {
      details: Array<PlanningScheduleInput>;
    };
  }>({
    planningSchedule: { details: initialDetails },
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value, name } = event.target;
    setPlanningScheduleForm((prevState) => ({
      ...prevState,
      planningSchedule: {
        ...prevState.planningSchedule,
        details: [
          ...prevState.planningSchedule.details.slice(0, index),
          {
            ...prevState.planningSchedule.details[index],
            [name]: value,
          },
          ...prevState.planningSchedule.details.slice(index + 1),
        ],
      },
    }));
  }, []);

  const handleFieldDateChange = useCallback(
    (name: string, value: Dayjs | null, index: number, leadTime?: boolean, valueTime?: string) => {
      setPlanningScheduleForm((prevState) => ({
        ...prevState,
        planningSchedule: {
          ...prevState.planningSchedule,
          details: [
            ...prevState.planningSchedule.details.slice(0, index),
            {
              ...prevState.planningSchedule.details[index],
              [name]: value?.toISOString() ?? valueTime,
            },
            ...prevState.planningSchedule.details.slice(index + 1),
          ],
        },
      }));

      if (leadTime) {
        setPlanningScheduleForm((prevState) => ({
          ...prevState,
          planningSchedule: {
            ...prevState.planningSchedule,
            details: [
              ...prevState.planningSchedule.details.slice(0, index),
              {
                ...prevState.planningSchedule.details[index],
                finalDate: dayjs(prevState.planningSchedule.details[index].initialDate)
                  ?.add(values.leadTime, 'days')
                  ?.toISOString(),
              },
              ...prevState.planningSchedule.details.slice(index + 1),
            ],
          },
        }));
      }
    },
    [],
  );

  const handleSavePlanningShipping = useCallback(async () => {
    try {
      setIsLoading(true);
      const filledDetails = planningScheduleForm.planningSchedule.details.filter(
        (detail) => detail.initialDate && detail.finalDate,
      );

      showMessageFormatted({
        message: 'Aguarde a criação dos agendamentos...',
        type: 'info',
      });

      const createdSchedule = await scheduleRegisterAllServiceByRoute(
        values,
        values.planningRoute[planningSchedulesModal.data.planningRouteIndex].allowedVehicles[
          planningSchedulesModal.data.allowedVehicleIndex
        ],
        values.planningRoute[planningSchedulesModal.data.planningRouteIndex].allowedVehicles[
          planningSchedulesModal.data.allowedVehicleIndex
        ].allowedShipping.filter((remove) => remove.companyShippingId)[
          planningSchedulesModal.data.allowedShippingIndex
        ],
        filledDetails,
      );

      showMessageFormatted({
        message: `${createdSchedule.countScheduleCreated} agendamento(s) criado(s) com sucesso.`,
        type: 'success',
      });
      setIsLoading(false);

      reactQueryClient.invalidateQueries(['myRouteGet']);
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    } finally {
      handleClosePlanningScheduleModal();
      setIsLoading(false);
    }
  }, [
    planningScheduleForm.planningSchedule.details,
    values,
    planningSchedulesModal.data.planningRouteIndex,
    planningSchedulesModal.data.allowedVehicleIndex,
    planningSchedulesModal.data.allowedShippingIndex,
    reactQueryClient,
    handleClosePlanningScheduleModal,
  ]);

  const handlePaste = useCallback(
    (
      event: Array<{ date: string }> | React.ClipboardEvent<HTMLInputElement>,
      planningScheduleIndex: number,
      isInitialDate?: boolean,
      fieldName?: string,
    ) => {
      if (Array.isArray(event)) {
        event.forEach((value: { date: string }, dateIndex: number) => {
          if (value.date.length === 10) {
            const [day, month, year] = value.date.split('/');
            const dataObj = new Date(`${year}-${month}-${day} 12:00`);
            const dateChange = moment(dataObj).toISOString();
            const newValue = moment(dateChange).format('yyyy-MM-DD');

            if (isInitialDate) {
              handleFieldDateChange('initialDate', dayjs(newValue), planningScheduleIndex + dateIndex, true);
            } else {
              handleFieldDateChange('finalDate', dayjs(newValue), planningScheduleIndex + dateIndex);
            }
          }
        });
      } else if (fieldName) {
        const clipboardValue = event.clipboardData.getData('text').trim();
        const values = clipboardValue.split('\r\n');

        values.forEach((value: string, internalCodeIndex: number) => {
          const index = planningScheduleIndex + internalCodeIndex;
          setTimeout(() => {
            setPlanningScheduleForm((prevState) => ({
              ...prevState,
              planningSchedule: {
                ...prevState.planningSchedule,
                details: [
                  ...prevState.planningSchedule.details.slice(0, index),
                  {
                    ...prevState.planningSchedule.details[index],
                    [fieldName]: value,
                  },
                  ...prevState.planningSchedule.details.slice(index + 1),
                ],
              },
            }));
          }, 100);
        });
      } else {
        const time = event.clipboardData.getData('text').trim();
        const timeValues = time.split('\r\n');

        timeValues.forEach((value: string, timeIndex: number) => {
          handleFieldDateChange('initialTime', null, planningScheduleIndex + timeIndex, false, value);
        });
      }
    },
    [handleFieldDateChange],
  );

  const renderPlanningScheduleForm = useMemo(() => {
    const divArray = [];

    for (let i = 0; i < valuesPlanning.plannedTripsCount; i++) {
      divArray.push(
        <div key={i}>
          <LineSectionStyled columns="0.2fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr">
            <S.TableInfoStyled key={i}>{i + 1}</S.TableInfoStyled>

            <S.TableInfoStyled>{valuesPlanning.allowedShipping.companyShippingName}</S.TableInfoStyled>

            <S.TableInfoStyled>
              {convertVehicleTypeTypeToName(valuesPlanning.allowedVehicle.vehicleType) +
                ' - ' +
                convertVehicleCategoryTypeToName(valuesPlanning.allowedVehicle.vehicleCategory)}
            </S.TableInfoStyled>

            <InputField
              id={'internalCode'}
              name={'internalCode'}
              type="text"
              onChange={(event: any) => handleChange(event, i)}
              onPaste={(e) => handlePaste(e, i, false, 'internalCode')}
              value={planningScheduleForm.planningSchedule.details[i]?.internalCode}
            />

            <S.DateContainer>
              <InputFieldCalendar
                onDateChange={(e) => handleFieldDateChange('initialDate', dayjs(e), i, true)}
                onDatePaste={(date) => handlePaste(date, i, true)}
                date={
                  planningScheduleForm.planningSchedule.details[i]?.initialDate
                    ? moment(planningScheduleForm.planningSchedule.details[i]?.initialDate)
                    : null
                }
              />
            </S.DateContainer>

            <S.DateContainer>
              <InputFieldTime
                id="collectHour"
                name="collectHour"
                type="text"
                onChange={(e) => handleFieldDateChange('initialTime', null, i, false, e.target.value)}
                onPaste={(e) => handlePaste(e, i)}
                value={
                  planningScheduleForm.planningSchedule.details[i]?.initialTime
                    ? planningScheduleForm.planningSchedule.details[i]?.initialTime
                    : null
                }
              />
            </S.DateContainer>

            <S.DateContainer>
              <InputFieldCalendar
                onDateChange={(e) => handleFieldDateChange('finalDate', dayjs(e), i)}
                onDatePaste={(date) => handlePaste(date, i)}
                date={
                  planningScheduleForm.planningSchedule.details[i]?.finalDate
                    ? moment(planningScheduleForm.planningSchedule.details[i]?.finalDate)
                    : null
                }
              />
            </S.DateContainer>

            <InputField
              id={'comments'}
              name={'comments'}
              type="text"
              onChange={(event: any) => handleChange(event, i)}
              onPaste={(e) => handlePaste(e, i, false, 'comments')}
              value={planningScheduleForm.planningSchedule.details[i]?.comments}
            />
          </LineSectionStyled>
        </div>,
      );
    }

    return divArray;
  }, [
    planningSchedulesModal.data,
    planningSchedulesModal.data.planningRouteIndex,
    planningSchedulesModal.data.allowedShippingIndex,
    planningSchedulesModal.data.allowedVehicleIndex,
    planningScheduleForm,
    setPlanningScheduleForm,
  ]);

  return (
    <ModalBark
      size={'lg'}
      title={'Oferta de Carga'}
      subtitle={`${values.origin.origin.address.city} - ${values.origin.origin.address.uf} | ${values.destination.destination.address.city} - ${values.destination.destination.address.uf}`}
      handleClose={handleClosePlanningScheduleModal}
    >
      <SectionStyled name="Informações do Planejamento">
        <LineSectionStyled columns="0.2fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr">
          <S.TableHeaderStyled>ID</S.TableHeaderStyled>

          <S.TableHeaderStyled>Transportadora</S.TableHeaderStyled>

          <S.TableHeaderStyled>Veículo</S.TableHeaderStyled>

          <S.TableHeaderStyled>SMP</S.TableHeaderStyled>

          <S.TableHeaderStyled>Data Coleta</S.TableHeaderStyled>

          <S.TableHeaderStyled>Hora Coleta</S.TableHeaderStyled>

          <S.TableHeaderStyled>Data Entrega</S.TableHeaderStyled>

          <S.TableHeaderStyled>Observações</S.TableHeaderStyled>
        </LineSectionStyled>
      </SectionStyled>

      <SectionStyled name="Informações do Planejamento">
        <S.WrapperForm>{renderPlanningScheduleForm}</S.WrapperForm>
      </SectionStyled>

      <S.WrapperButton>
        <Button
          label={'Salvar'}
          color={'blue'}
          onPress={handleSavePlanningShipping}
          variant={'solid'}
          size={'md'}
          disabled={isLoading}
        />
      </S.WrapperButton>
    </ModalBark>
  );
};
