import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ExportReportProps,
  FilterProps,
  IButtonProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { TransactionsListPageBodyView } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body-view.component';
import {
  IInvoiceModalProps,
  ISelectedIdsProps,
} from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.types';
import { TransactionsContext } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { TransactionActiveTabName } from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';
import { showToast } from 'components/toast/toast';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { useDownloadTransactionReport } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/hooks/use-download-transaction-report/use-download-transaction-report.hook';
import { useCreateInvoice } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/hooks/use-create-invoice/use-create-invoice.hook';
import { ICreateInvoiceServiceParams } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/create-invoice/create-invoice.service';

export const TransactionsListPageBody: React.FC = (): ReactElement => {
  const { transactionsList } = useContext(TransactionsContext);
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [activeTabName, setActiveTabName] = useState<TransactionActiveTabName>('A Pagar');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [isShowInvoiceModal, setIsShowInvoiceModal] = useState<boolean>(false);
  const downloadTransactionReport = useDownloadTransactionReport();
  const createInvoice = useCreateInvoice();
  const { transactions } = transactionsList;
  const { filters, setFilters } = transactionsList.filterActions;

  const handleSearchInput = useCallback(
    (value: string) => {
      setPageInfinityScroll(1);
      setFilters({ ...filters, page: 1, search: value });
    },
    [filters, setFilters],
  );

  const handleExportReport = useCallback(() => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      downloadTransactionReport(setIsLoadingExport);
    }
  }, [downloadTransactionReport, isLoadingExport]);

  const exportReportProps = useMemo(
    (): ExportReportProps => ({
      isLoading: isLoadingExport,
      onHandleExport: handleExportReport,
    }),
    [handleExportReport, isLoadingExport],
  );

  const selectedIdsProps = useMemo(
    (): ISelectedIdsProps => ({
      selectedIds,
      setSelectedIds,
    }),
    [selectedIds],
  );

  const handleClearSelection = useCallback(() => {
    if (!selectedIds.length) {
      showToast({
        message: 'Selecione pelo menos um item',
        type: 'error',
      });

      return;
    }

    setSelectedIds([]);
  }, [selectedIds.length]);

  const selectedButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Limpar Seleção',
      isVisible: selectedIds.length > 0,
      isLoading: false,
      handleActionButton: handleClearSelection,
    }),
    [handleClearSelection, selectedIds],
  );

  const validateInvoices = useCallback((): boolean => {
    const list = transactions.filter((transaction) => selectedIds.includes(transaction.id));

    if (list.length === 0) return false;

    const { invoicedBy, schedule } = list[0];

    const hasValidTaker = list.every((item) => item.invoicedBy === invoicedBy);
    const hasValidCategory = list.every((item) => item.schedule.typeSchedule === schedule.typeSchedule);

    if (!hasValidTaker) {
      showToast({
        message: 'Selecione somente transações com o mesmo recebedor.',
        type: 'error',
      });
      return false;
    }

    if (!hasValidCategory) {
      showToast({
        message: 'Selecione somente um tipo de faturamento',
        type: 'error',
      });
      return false;
    }

    return true;
  }, [selectedIds, transactions]);

  const handleInvoicing = useCallback(() => {
    const isOpenModal: boolean = validateInvoices();

    if (isOpenModal) {
      setIsShowInvoiceModal(true);
    }
  }, [validateInvoices]);

  const invoiceButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Faturar',
      isVisible: !!selectedIds.length,
      isLoading: false,
      handleActionButton: handleInvoicing,
    }),
    [handleInvoicing, selectedIds.length],
  );

  const getCountServices = useCallback((): number => {
    if (selectedIds.length) {
      return transactions
        .map((transaction) => {
          if (selectedIds.includes(transaction.id)) {
            return transaction.totalService;
          }

          return undefined;
        })
        .filter((transaction): transaction is number => transaction !== undefined)
        .reduce((acc, num) => acc + num, 0);
    }

    return 0;
  }, [selectedIds, transactions]);

  const countServicesFormated = useMemo((): string | undefined => {
    const countServices = getCountServices();

    if (countServices > 0) {
      return convertCurrencyFormat.format(countServices);
    }
  }, [getCountServices]);

  const filterProps = useMemo(
    (): FilterProps => ({
      filterCount: 0,
      onClearButton: () => undefined,
      onCloseButton: () => undefined,
      handleOpenModal: () => undefined,
      isVisibleModal: false,
      filterContent: <Fragment />,
      isDisabledButton: true,
    }),
    [],
  );

  const handleInvoiceSubmit = useCallback(
    (values: ICreateInvoiceServiceParams) => {
      createInvoice({ ...values, calcFinanceIds: selectedIds }, setIsShowInvoiceModal);
    },
    [createInvoice, selectedIds],
  );

  const getCompanyTakerName = useCallback(
    (): string => transactions.find((transaction) => transaction.id === selectedIds[0])?.taker ?? '',
    [selectedIds, transactions],
  );

  const getScheduleIds = useCallback(
    (): Array<number> =>
      transactions
        .map((transaction) => {
          if (selectedIds.includes(transaction.id)) {
            return transaction.schedule.id;
          }

          return undefined;
        })
        .filter((transaction): transaction is number => transaction !== undefined),
    [selectedIds, transactions],
  );

  const invoiceModalProps = useMemo(
    (): IInvoiceModalProps => ({
      showModal: isShowInvoiceModal,
      totalService: getCountServices(),
      scheduleIds: getScheduleIds(),
      handleSubmit: handleInvoiceSubmit,
      handleCancel: () => setIsShowInvoiceModal(false),
      companyTakerName: getCompanyTakerName(),
    }),
    [getCompanyTakerName, getCountServices, getScheduleIds, handleInvoiceSubmit, isShowInvoiceModal],
  );

  useEffect(() => {
    if (!filters.isPageLimit && pageInfinityScroll !== filters.page) {
      setFilters({ ...filters, page: pageInfinityScroll });
    }
  }, [filters, pageInfinityScroll, setFilters]);

  return (
    <TransactionsListPageBodyView
      handleSearchInput={handleSearchInput}
      setActiveTabName={setActiveTabName}
      exportReportProps={exportReportProps}
      activeTab={activeTabName}
      handleInfinityScroll={setPageInfinityScroll}
      selectedIdsProps={selectedIdsProps}
      selectedButtonProps={selectedButtonProps}
      invoiceButtonProps={invoiceButtonProps}
      totalServices={countServicesFormated}
      filterProps={filterProps}
      invoiceModalProps={invoiceModalProps}
    />
  );
};
