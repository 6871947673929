import React, { useState } from 'react';

import { FooterStyled, FormikStyled, FormStyled, LineSectionStyled } from './form-change-password.styled';

import Theme from 'styles/theme';
import { IChangePassword } from 'domain/change-password';
import { FooterPage } from 'components-v2/common/footer-page';
import InputField from 'components/input-field/input-field';

type FormChangePasswordProps = {
  changePasswordValues: IChangePassword;
  validationSchema: object;
  handleSubmit: (values: IChangePassword) => void;
  handleGoBack: () => void;
  login: string | undefined;
};

const FormChangePasswordView: React.FC<FormChangePasswordProps> = ({
  changePasswordValues,
  validationSchema,
  handleSubmit,
  login,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <FormikStyled
      initialValues={changePasswordValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const valuesChangePassword: IChangePassword = {
          login: login,
          password: values.password,
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword,
        };
        handleSubmit(valuesChangePassword);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <FormStyled>
          <LineSectionStyled columns="1fr 1fr">
            <InputField
              label="LOGIN"
              id="login"
              name="login"
              type="text"
              onChange={handleChange}
              value={login}
              hidden
            />
            <InputField
              label="Nova Senha"
              id="newPassword"
              name="newPassword"
              type="password"
              onChange={handleChange}
              value={values.newPassword}
              passwordVisible={showPassword}
              changePasswordVisible={() => setShowPassword(!showPassword)}
              reactNode={<Theme.icons.eyePasswordIcon />}
              reactNodePosition="right"
              hasError={!!errors.newPassword && !!touched.newPassword}
              errorMessage={String(errors.newPassword)}
              upperCase={false}
            />
            <InputField
              label="Confirmar Nova Senha"
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              onChange={handleChange}
              value={values.confirmNewPassword}
              passwordVisible={showConfirmPassword}
              changePasswordVisible={() => setShowConfirmPassword(!showConfirmPassword)}
              reactNode={<Theme.icons.eyePasswordIcon />}
              reactNodePosition="right"
              hasError={!!errors.confirmNewPassword && !!touched.confirmNewPassword}
              errorMessage={String(errors.confirmNewPassword)}
              upperCase={false}
            />
          </LineSectionStyled>
          <FooterStyled>
            <FooterPage.Root>
              <FooterPage.RightContent>
                <FooterPage.Button label="Atualizar" color="pink" onPress={handleSubmit} />
              </FooterPage.RightContent>
            </FooterPage.Root>
          </FooterStyled>
        </FormStyled>
      )}
    </FormikStyled>
  );
};

export default FormChangePasswordView;
