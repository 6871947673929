import React from 'react';

import { TerritorialVisionBody } from './territorial-vision-body/territorial-vision-body';
import { TerritorialVisionContextProvider } from './context/territorial-vision.context';

export const TerritorialVisionPage: React.FC = (): JSX.Element => {
  return (
    <TerritorialVisionContextProvider>
      <TerritorialVisionBody />
    </TerritorialVisionContextProvider>
  );
};
