import { useCallback, useReducer } from 'react';
import { homeInitialState } from '../../home.constants';
import { IHomeMatches } from '../../types/home-matches.types';
import { homeMatchesReducer } from '../../reducers/home-matches/home-matches.reducer';
import { HomeMatchesActionType } from '../../home.action';
import { IHomeMatchesDomain } from 'src-new/pages/home/pages/home/domains/home-matches/home-matches.domain';

export const useHomeMatchesContextValues = (): IHomeMatches => {
  const [state, dispatch] = useReducer(homeMatchesReducer, homeInitialState);

  const setHomeMatchesRequest = useCallback(() => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_REQUEST, payload: undefined });
  }, []);

  const setHomeMatchesSuccess = useCallback((homeMatches: Array<IHomeMatchesDomain>) => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_SUCCESS, payload: homeMatches });
  }, []);

  const setHomeMatchesError = useCallback(() => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_ERROR, payload: undefined });
  }, []);

  const setHomeMatchesReset = useCallback(() => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_RESET, payload: undefined });
  }, []);

  const setNextPage = useCallback((page?: number) => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_NEXT_PAGE, payload: page });
  }, []);

  const setPageLimit = useCallback(() => {
    dispatch({ type: HomeMatchesActionType.HOME_MATCHES_PAGE_LIMIT, payload: undefined });

  }, []);

  return {
    ...state.homeMatches,
    setHomeMatchesRequest,
    setHomeMatchesSuccess,
    setHomeMatchesError,
    setHomeMatchesReset,
    paginationActions: {
      ...state.homeMatches.paginationActions,
      setNextPage,
      setPageLimit,
    },
  };
};
