import { FC, useContext, useEffect, useMemo } from 'react';
import { SaleOfferUpdatePageBodyView } from './sale-offer-update-page-body-view.component';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { UseGetSaleOfferById } from '../../hooks/use-get-sale-offer-by-id/use-get-sale-offer-by-id.hook';

export const SaleOfferUpdatePageBody: FC = () => {
  const { saleOfferDetails } = useContext(SaleOfferContext);
  const getSaleOfferByIdHook = UseGetSaleOfferById();

  const isLoading = useMemo(() => saleOfferDetails.statusRequest === 'PROCESSING', [saleOfferDetails.statusRequest]);

  const isError = useMemo(() => saleOfferDetails.statusRequest === 'ERROR', [saleOfferDetails.statusRequest]);
  useEffect(() => {
    if (saleOfferDetails.statusRequest === 'INITIAL') {
      getSaleOfferByIdHook();
    }
  }, [getSaleOfferByIdHook, saleOfferDetails.statusRequest]);

  return <SaleOfferUpdatePageBodyView isLoading={isLoading} isError={isError} />;
};
