import { useCallback } from 'react';

interface IdentityProvider {
  clientName: string;
  emailDomain: string;
  clientId: string;
  redirectUri: string;
  providerType: string;
  identityProvider: string;
}

const integrationIdentityProviders: IdentityProvider[] = [
  {
    clientName: 'BRF',
    emailDomain: 'brf.com',
    clientId: '44sp0583nh3pe891oth8ht79hb',
    redirectUri: 'https://logshare.app/login',
    providerType: 'microsoft',
    identityProvider: 'client-brf',
  },
];

export const useBuildAuthUrlMicrosoft = () => {
  return useCallback(
    (email: string): string | null => {
      const emailDomain = email.split('@')[1];
      const provider = integrationIdentityProviders.find(provider => provider.emailDomain === emailDomain);

      if (!provider) {
        return null;
      }
      return `${import.meta.env.VITE_LINK_API_AUTH_COGNITO}?response_type=code&client_id=${provider.clientId}&redirect_uri=${provider.redirectUri}&state=STATE&identity_provider=${provider.identityProvider}&scope=openid+email&login_hint=${email}`;
    },
    []
  );
};
