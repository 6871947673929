/* eslint-disable @typescript-eslint/no-explicit-any */
const extractProperty = (obj: any, key: string) => obj?.[key] || undefined;

const extractLocationData = (location: any, type: string) => {
  const locationData = location?.state?.[type];

  return {
    id: extractProperty(locationData, 'id'),
    type: extractProperty(locationData, 'type'),
    name: extractProperty(locationData, 'name'),
    cep: extractProperty(locationData, 'cep'),
    neighborhood: extractProperty(locationData, 'neighborhood'),
    city: extractProperty(locationData, 'city'),
    uf: extractProperty(locationData, 'uf'),
    street: extractProperty(locationData, 'street'),
    numberHouse: extractProperty(locationData, 'numberHouse'),
    complement: extractProperty(locationData, 'complement'),
  };
};

export const LocationScheduleData = (location: any) => {
  const scheduleCreated = {
    allowedShippingId: extractProperty(location?.state?.myRoute, 'allowedShippingId'),
    idRoute: extractProperty(location?.state?.myRoute, 'idRoute'),
    shippingCompanyId: extractProperty(location?.state?.shippingCompany, 'id'),
    vehicleType: extractProperty(location?.state?.vehicle, 'type'),
    vehicleCategory: extractProperty(location?.state?.vehicle, 'category'),
    helperValue: extractProperty(location?.state?.cargoDetails, 'helperValue'),
    palletValue: extractProperty(location?.state?.cargoDetails, 'palletValue'),

    origin: extractLocationData(location, 'origin'),
    destination: extractLocationData(location, 'destination'),
  };

  return scheduleCreated;
};
