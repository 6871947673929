import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQueryClient } from 'react-query';
import { updateReofferTripService } from '../../services/update-reoffer-trip/update-reoffer-trip.service';
import { useLocation } from 'react-router-dom';

export const useUpdateReofferTrip = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const reactQueryClient = useQueryClient();
  const location = useLocation();

  return useCallback(async () => {
    try {
      const shippingCompanyId =
        scheduleDetails.allocationShipping.shippingCompanyId !== 0
          ? scheduleDetails.allocationShipping.shippingCompanyId
          : scheduleDetails.scheduleDetails.shippingId ?? 0;

      await updateReofferTripService(
        scheduleDetails.scheduleDetails.id,
        shippingCompanyId,
        location.state?.scheduleRefusedId,
      );

      reactQueryClient.invalidateQueries(['schedule-details-by-id']);
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
  }, [
    location.state?.scheduleRefusedId,
    reactQueryClient,
    scheduleDetails.allocationShipping.shippingCompanyId,
    scheduleDetails.scheduleDetails.id,
    scheduleDetails.scheduleDetails.shippingId,
  ]);
};
