import { Button } from 'logshare-ui-kit';
import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import { IScheduleDetailsAllocationDriverDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-driver.domain';
import {
  WrapperTitleStyled,
  AllocationModalVehicleTitleStyled,
  AllocationModalVehicleStyled,
  WrapperDriverInfoStyled,
  WrapperButtons,
} from './confirm-driver-modal-view.styled';

interface IConfirmDriverViewProps {
  handleModalClose: () => void;
  handleConfirm: () => void;
  driver: IScheduleDetailsAllocationDriverDomain;
}

export const ConfirmDriverView: FC<IConfirmDriverViewProps> = ({ handleModalClose, handleConfirm, driver }) => {
  return (
    <ModalBark
      size={'xsm'}
      title={
        <WrapperTitleStyled>
          <WarningDeleteIcon />
          <AllocationModalVehicleTitleStyled>
            Deseja alocar o motorista abaixo
            <br />
            vinculado a esta Placa?
          </AllocationModalVehicleTitleStyled>
        </WrapperTitleStyled>
      }
      handleClose={handleModalClose}
    >
      <AllocationModalVehicleStyled>
        <WrapperDriverInfoStyled>
          ID {driver.id} - {driver.name} <span>CPF {driver.cpf}</span>
        </WrapperDriverInfoStyled>

        <WrapperButtons>
          <Button label={'Confirmar'} color={'red'} onPress={() => handleConfirm()} variant={'solid'} size={'md'} />
        </WrapperButtons>
      </AllocationModalVehicleStyled>
    </ModalBark>
  );
};
