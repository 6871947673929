import { useCallback } from 'react';
import { IGetFreightCalculation, getFreightCalculation } from '../../services/freight-calculate';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetFreightCalculate = () => {
  return useCallback(async (freight: IGetFreightCalculation) => {
    try {
      return await getFreightCalculation(freight);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao obter cálculo do frete.',
        type: 'error',
      });
    }
  }, []);
};
