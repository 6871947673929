import React from 'react';
import { useEffect, useState } from 'react';

import PortSantosDetailFormView from './santos-port-details-form-view';
import { initialValues } from './form-values/form-values';

import { usePortSantosDetailHook } from 'pages/santos-port/hooks/use-port-santos-details/use-port-santos-details.hook';
import { IPortSchedule } from 'domain/santos-port';

interface PortSantosForm {
  id: string;
}

const PortSantosDetailForm: React.FC<PortSantosForm> = ({ id }) => {
  const [portSantos, setPortSantos] = useState<IPortSchedule>(initialValues);

  const portSantosDetailHook = usePortSantosDetailHook();

  useEffect(() => {
    async function run() {
      const response = await portSantosDetailHook(id);
      setPortSantos(response);
    }
    run();
  }, [id, portSantosDetailHook]);

  return <PortSantosDetailFormView santosPortValues={portSantos} />;
};

export default PortSantosDetailForm;
