import { ICreatedUserEntity, IFunctionalityEntity } from '../entities/user.entity';

import API from 'utils/API/API';
import { convertUserEntityToDomain } from 'pages/user/mappers/convert-user-entity-to-domain/convert-user-entity-to-domain.mapper';
import { ICreatedUser } from 'domain/user';

export const userDetailsService = async (
  userId: string,
  functionalities: Array<IFunctionalityEntity>,
): Promise<ICreatedUser> => {
  const { data } = await API.get<ICreatedUserEntity>(`user/${userId}`);
  return convertUserEntityToDomain(data, functionalities);
};
