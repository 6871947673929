/* eslint-disable @typescript-eslint/no-explicit-any */
type IServiceTotal = {
  ICMS: number;
  otherTaxes: number;
  totalFreight: number;
};

export const sumTotalFreight = (values: any) => {
  const keys = Object.keys(values);

  const valuesToReduce = keys.map((key) => normalizeCurrency(values[key]));

  const total = valuesToReduce.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  });

  return total;
};

export const normalizeNumber = (value: string | number) => {
  const valueToNumber = normalizeDecimal(Number(value));

  if (Number.isNaN(value)) return 0;
  return valueToNumber;
};

export const normalizeDecimal = (value: number | string) => {
  if (typeof value === 'string') return value;

  if (typeof value === 'number') return Number(value.toFixed(2));
};

export const calculateServiceTotal = (values: IServiceTotal) => {
  const { otherTaxes, ICMS, totalFreight } = values;

  const ICMSTax = calculateICMS(ICMS, totalFreight);

  const freightPlusICMS = totalFreight + ICMSTax;

  if (Number.isNaN(freightPlusICMS)) return 0;

  const freightPlusOtherTaxes = freightPlusICMS + normalizeCurrency(otherTaxes);

  if (Number.isNaN(freightPlusOtherTaxes)) return 0;

  return freightPlusOtherTaxes.toFixed(2);
};

export const calculateICMS = (ICMS: number, freightTotal: number) => {
  const tax = freightTotal / (1 - ICMS / 100) - freightTotal;

  if (Number.isNaN(tax)) return 0;

  return Number(tax.toFixed(2));
};

export const calculateFeeLogshare = (
  feeLogshare: number,
  freightValue: number,
) => {
  const tax = freightValue * (feeLogshare / 100)

  if (Number.isNaN(tax)) return 0

  return Number(tax.toFixed(2))
}

export const normalizeCurrency = (amount: number | string) => {
  const formatted = String(amount).replace(',', '.');

  if (formatted.endsWith('.')) {
    return Number(`${formatted}00`);
  }

  return Number(formatted);
};
