/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'utils/API/API';
import { FreightPriceFTL } from 'domain/freight';

export interface IGetFreightCalculation {
  originId: number;
  originType: string;
  destinyId: number;
  destinyType: string;
  weight: string;
  capacity: string;
  merchValue: string;
  vehicleType: string;
  vehicleCategory: string;
  needHelper?: string;
  pickUpDate?: string;
  pallet?: number;
  override?: any;
  dates?: {
    levyInitialDate: Date;
    levyFinalDate: Date;
    deliveryInitialDate: Date;
    deliveryFinalDate: Date;
  };
}

export const getFreightCalculation = async (payload: IGetFreightCalculation): Promise<FreightPriceFTL> => {
  const url = 'v2/schedules/freight-calculate';
  const response = await API.post<FreightPriceFTL>(url, payload);

  return response.data;
};
