import React, { ReactElement, useMemo } from 'react';
import * as S from './filter-form-view.styled';
import {
  haulTypeOptions,
  IOption,
  operationBy,
  operationTypeOptions,
  optionsInvoicedBy,
  vehicleCategoryOptions,
  vehicleTypeOptions,
} from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';
import AsyncSelectField from 'components/async-select/async-select';
import { useFormikContext } from 'formik';
import {
  IScheduleListParams,
  ScheduleOperationType,
} from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { SelectItem } from 'domain/select-item';
import dayjs from 'dayjs';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import MultiSelect from 'components/multi-select/multi-select';

interface IAsyncSelectFieldValue {
  label: string;
  value: number;
}

interface IFilterFormViewProps {
  isAdmin: boolean;
  statusCodeOptions: Array<IOption>;
  apiCallCompany: (searchValue: string) => Promise<SelectItem[]>;
  apiCallShippingCompany: (searchValue: string) => Promise<Array<ISelectItemDomain>>;
}

export const FilterFormView: React.FC<IFilterFormViewProps> = ({
  isAdmin,
  statusCodeOptions,
  apiCallCompany,
  apiCallShippingCompany,
}) => {
  const { values, setFieldValue, handleChange, submitForm } = useFormikContext<IScheduleListParams>();

  const hasShippingCompanyValue = useMemo(
    (): IAsyncSelectFieldValue | undefined =>
      values.shippingCompanyName && values.shippingCompanyId
        ? { label: values.shippingCompanyName, value: values.shippingCompanyId }
        : undefined,
    [values.shippingCompanyId, values.shippingCompanyName],
  );

  const renderCompanyFilter = useMemo((): ReactElement | undefined => {
    if (isAdmin) {
      return (
        <S.ContainerRow>
          <S.SecondTitle>Empresa</S.SecondTitle>
          <S.LineSection columns="1fr">
            <AsyncSelectField
              placeholder="Solicitado por"
              name="company"
              id="company"
              isClearable={true}
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (e) => {
                setFieldValue('name', e && e.label);
                setFieldValue('nameId', e && e.value);
              }}
              apiCallFunction={apiCallCompany}
              loadOptions={apiCallCompany}
            />
          </S.LineSection>
        </S.ContainerRow>
      );
    }
  }, [apiCallCompany, isAdmin, setFieldValue]);

  return (
    <S.Wrapper>
      {renderCompanyFilter}

      <S.ContainerRow>
        <S.SecondTitle>Data Coleta</S.SecondTitle>
        <S.LineSection columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.levyInitialDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('levyInitialDate', date?.format('DD/MM/YYYY'))}
          />

          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.levyEndDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('levyEndDate', date?.format('DD/MM/YYYY'))}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Data Entrega</S.SecondTitle>
        <S.LineSection columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.deliveryInitialDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('deliveryInitialDate', date?.format('DD/MM/YYYY'))}
          />

          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.deliveryEndDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('deliveryEndDate', date?.format('DD/MM/YYYY'))}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Cidade de Origem </S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="originCity"
            name="originCity"
            type="text"
            value={values.originCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Cidade de Destino</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            value={values.destinationCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Operado Por</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Informe o responsável pela operação"
            id="operationBy"
            name="operationBy"
            value={values.operationBy}
            setFieldValue={setFieldValue}
            options={operationBy}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Faturado Por</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Informe o responsável pelo faturamento"
            id="invoicedBy"
            name="invoicedBy"
            value={values.invoicedBy}
            setFieldValue={setFieldValue}
            options={optionsInvoicedBy}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Tipo de Contratação</S.SecondTitle>
        <S.LineSection columns="1fr">
          <MultiSelect
            label=""
            placeholder="Informe o tipo de contratação"
            options={haulTypeOptions}
            menuPosition="fixed"
            values={haulTypeOptions.filter((typeOperation) => {
              return values.typeOperation?.includes(typeOperation.value as ScheduleOperationType);
            })}
            onChange={(e) => {
              const haulType = e.map((option) => option.value);
              let haulTypeToAdd = haulType;
              if (haulType.includes('ALL')) haulTypeToAdd = haulTypeOptions.map((typeOperation) => typeOperation.value);
              setFieldValue('typeOperation', haulTypeToAdd);
            }}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Status</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Informe o status"
            id="statusCode"
            name="statusCode"
            value={values.statusCode}
            setFieldValue={setFieldValue}
            options={statusCodeOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Pedido</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe o N° do Pedido"
            id="orderNumber"
            name="orderNumber"
            type="text"
            value={values.orderNumber}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Número do CTe</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe o N° do CTe"
            id="cteSearch"
            name="cteSearch"
            type="text"
            value={values.cteSearch}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Transportadora</S.SecondTitle>
        <S.LineSection columns="1fr">
          <AsyncSelectField
            placeholder="Informe o nome da transportadora"
            name="shippingCompany"
            id="shippingCompany"
            isClearable={true}
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              setFieldValue('shippingCompanyName', e && e.label);
              setFieldValue('shippingCompanyId', e && e.value);
            }}
            value={hasShippingCompanyValue}
            apiCallFunction={apiCallShippingCompany}
            loadOptions={apiCallShippingCompany}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Motorista</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe o nome do motorista"
            id="driverName"
            name="driverName"
            type="text"
            value={values.driverName}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Placa do Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a placa do veículo"
            id="vehicleBoard"
            name="vehicleBoard"
            type="text"
            value={values.vehicleBoard}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Tipo de Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Selecione o Tipo"
            id="vehicleType"
            name="vehicleType"
            value={values.vehicleType}
            setFieldValue={setFieldValue}
            options={vehicleTypeOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Categoria do Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Selecione a Categoria"
            id="vehicleCategory"
            name="vehicleCategory"
            value={values.vehicleCategory}
            setFieldValue={setFieldValue}
            options={vehicleCategoryOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Tipo de Operação</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Tipo de Operação"
            id="scheduleOperationType"
            name="scheduleOperationType"
            value={values.scheduleOperationType}
            setFieldValue={setFieldValue}
            options={operationTypeOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerButton>
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
      </S.ContainerButton>
    </S.Wrapper>
  );
};
