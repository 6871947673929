import API from 'utils/API/API';
import { IScheduleKanbanListDomain } from '../../domains/schedule-kanban-list/schedule-kanban.domain';
import { IScheduleKanbanListResponse } from './get-kanban-list.types';
import { converterKanbanListResponseToDomain } from './mappers/converter-kanban-list-response-to-domain/converter-kanban-list-response-to-domain.mapper';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';

export const getKanbanListService = async (params: IScheduleListParams): Promise<IScheduleKanbanListDomain> => {
  const statusCodes = typeof params.statusCode === 'string' ? [params.statusCode] : params.statusCode
  const { data } = await API.get<IScheduleKanbanListResponse>('v2/schedules/kanban', {
    params: {
      ...params,
      statusCode: statusCodes,
      limit: 100,
    },
  });

  return converterKanbanListResponseToDomain(data);
};
