import { ICompaniesContext } from './companies.types';

export const initialState: ICompaniesContext = {
  companiesIsLoading: false,
  companies: [],
  companiesError: null,
  companiesPageInfo: null,
  setCompaniesRequest: () => undefined,
  setCompaniesSuccess: () => undefined,
  setCompaniesErro: () => undefined,
};

export enum ICompaniesAction {
  COMPANIES_REQUEST = 'COMPANIES_REQUEST',
  COMPANIES_SUCCESS = 'COMPANIES_SUCCESS',
  COMPANIES_ERROR = 'COMPANIES_ERROR',
}
