import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IGetSaleOfferValues } from 'pages-v2/schedule/hooks/use-get-sale-offer-value/types/use-get-sale-offer-values.types';
import { ISaleOfferValuesDomain } from 'domain-v2/schedule/cost';

export const getSaleOfferValueService = async (values: IGetSaleOfferValues): Promise<ISaleOfferValuesDomain> => {
  const { data } = await API_TRAVELS_SVC.get<ISaleOfferValuesDomain>(
    `/v1/sales-offers/schedule-values?originId=${values.originId}&destinationId=${values.destinationId}&vehicleType=${values.vehicleType}&vehicleCategory=${values.vehicleCategoryType}`,
  );

  return data;
};
