import React, { useMemo } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import GavelIcon from '@mui/icons-material/Gavel';

import { IBiddingList } from '../../types/list';

import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { convertOperationType } from 'utils/operation-type';
import { ColorsV2Type } from 'styles/types/colors';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import BigTable from 'components-v2/common/big-list';

export const BiddingTable: React.FC<IBiddingList> = ({
  idleness,
  openModal,
  handlePageChange,
  activePage,
  totalPages,
  isLoading,
}) => {
  const headerTable = useMemo(() => {
    return [
      {
        value: 'ID',
        flex: 0.3,
      },
      {
        value: 'Empresa',
        flex: 0.5,
      },
      {
        value: 'Categoria',
        flex: 0.4,
      },
      {
        value: 'Origem/Destino',
        flex: 1.2,
      },
      {
        value: 'Veículo',
        flex: 1,
      },
      {
        value: 'Capacidade',
        flex: 1.3,
      },
      {
        value: 'Frequência',
        flex: 1.3,
      },
    ];
  }, []);

  const contentTable = useMemo(() => {
    return idleness?.map((item) => {
      const getFrequency = (value: number): string => {
        const thresholds = [10, 30, 50, 100];
        const labels = ['Até 10', 'Até 30', 'Até 50', 'Até 100', 'Acima de 100'];

        const index = thresholds.findIndex((threshold) => value <= threshold);

        return labels[index] || labels[labels.length - 1];
      };

      return [
        {
          value: item.id,
          flex: 0.3,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{item.companyName}</strong>

              {item.companyLogo && (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 25,
                    marginTop: 3,
                  }}
                  src={item.companyLogo}
                  alt={item.companyName}
                />
              )}
            </div>
          ),
          flex: 0.5,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Tag label="FTL" color={ColorsV2Type.Blue} variant="solid" display="flex" size="md" />
              <Tag
                label={convertOperationType(item.operationType).label}
                color={convertOperationType(item.operationType).color}
                variant="ghost"
                display="flex"
                size="md"
              />
            </div>
          ),
          flex: 0.4,
        },
        {
          connection: {
            first: item.clientOrigin
              ? `${item.clientOrigin.city} - ${item.clientOrigin?.uf}`
              : `${item.localityOrigin.city} - ${item.localityOrigin?.uf}`,
            second: item.clientDestination
              ? `${item.clientDestination.city} - ${item.clientDestination?.uf}`
              : `${item.localityDestination.city} - ${item.localityDestination?.uf}`,
          },
          flex: 1.2,
        },
        {
          value: convertVehicleTypeTypeToName(item.vehicleType),
          subtitle: convertVehicleCategoryTypeToName(item.category),
          flex: 1,
        },
        {
          value: offeredWeight(item.offeredWeight),
          subtitle: offeredPallets(item.offeredPallet),
          flex: 1.3,
        },
        {
          value: item.frequency === 0 ? 'N.I' : getFrequency(item.frequency),
          subtitle: 'VIAGENS/MÊS',
          flex: 1.3,
        },
        {
          button: (
            <Button
              label="Ofertar"
              color="blue"
              size="md"
              onPress={() =>
                openModal({
                  isOpen: true,
                  idlenessId: item.id,
                })
              }
              RightIcon={
                <div style={{ marginLeft: 5 }}>
                  <GavelIcon sx={{ width: 16 }} />
                </div>
              }
              variant="solid"
            />
          ),
        },
      ];
    });
  }, [idleness, activePage]);

  return (
    <BigTable
      header={headerTable}
      content={contentTable || []}
      isLoading={isLoading}
      activePage={activePage}
      totalPages={totalPages}
      setPage={handlePageChange}
    />
  );
};

export default React.memo(BiddingTable);
