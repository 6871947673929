import { useCallback } from 'react';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { useSaveUploadFile } from 'src-new/pages/registrations/hooks/use-save-upload-file/use-save-upload-file.hook';
import { useDeleteUploadFiles } from 'src-new/pages/registrations/hooks/use-delete-upload-files/use-delete-upload-files.hook';
import { isEqual } from 'lodash';

export const useManageAttachmentsEdit = () => {
  const saveUploadFile = useSaveUploadFile();
  const deleteUploadFiles = useDeleteUploadFiles();

  const areListsIdentical = useCallback((list1: Array<IAttachmentDomain>, list2: Array<IAttachmentDomain>) => {
    if (list1.length !== list2.length) return false;

    return list1.every((file1) => list2.some((file2) => file1.id === file2.id && file1.fileName === file2.fileName));
  }, []);

  return useCallback(
    async (
      id: number,
      module: 'client' | 'location' | 'shipping-company' | 'driver' | 'vehicle',
      originalFiles: Array<IAttachmentDomain>,
      updateFiles: Array<IAttachmentDomain>,
    ): Promise<void> => {
      if (updateFiles && !areListsIdentical(originalFiles, updateFiles)) {
        const filesToUpdate: Array<IAttachmentDomain> = [];

        if (originalFiles) {
          const filesToDelete: Array<number> = [];

          originalFiles.forEach((file) => {
            const hasFile = updateFiles.find((originalFile) => originalFile?.id === file?.id);
            if (!hasFile) {
              if (file.id) {
                filesToDelete.push(file.id);
              }
            }
          });

          await deleteUploadFiles(filesToDelete);
        }

        updateFiles.forEach((file) => {
          const originalFile = originalFiles.find((originalFile) => originalFile?.id === file?.id);

          if (!isEqual(originalFile, file)) {
            filesToUpdate.push(file);
          }
        });

        await saveUploadFile(id, filesToUpdate, module);
      }
    },
    [areListsIdentical, deleteUploadFiles, saveUploadFile],
  );
};
