import React, { useMemo } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { Tooltip } from '@mui/material';

import HeaderTable from '../header';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { convertBiddingStatus } from 'pages-v2/biddings/mappers';
import { PageInfo } from 'domain-v2/inputs/page-info';
import { BiddingsList } from 'domain-v2/bidding/list';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';

interface ContentTable {
  biddings: Array<BiddingsList>;
  pageInfo: PageInfo;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  orderSelected: { orderBy: string; sortDirection: string };
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: (type: string) => void;
}

export const ListBiddingsTable: React.FC<ContentTable> = ({
  biddings,
  pageInfo,
  isLoading,
  handlePaginate,
  orderSelected,
  clickOrderTableBy,
  clickSortDirection,
}) => {
  const mountTable = useMemo((): Array<IContentTable> => {
    return biddings.map((bidding) => {
      return [
        {
          value: '',
          flex: 0,
        },
        {
          value: bidding.id,
          flex: 0.5,
        },
        {
          value: moment(bidding.createdAt).format('DD/MM/YYYY'),
          subtitle: moment(bidding.createdAt).format('HH:mm'),
          flex: 1,
        },

        {
          value: moment(bidding.schedule.collectDate).format('DD/MM/YYYY'),
          subtitle: moment(bidding.schedule.collectDate).format('HH:mm'),
          flex: 0.8,
        },

        {
          value: bidding.schedule.origin.uf + ' - ' + bidding.schedule.origin.city,
          subtitle: bidding.schedule.origin.name,
          flex: 1.2,
        },
        {
          value: bidding.schedule.destination.uf + ' - ' + bidding.schedule.destination.city,
          subtitle: bidding.schedule.destination.name,
          flex: 1.2,
        },
        {
          value: convertVehicleTypeTypeToName(bidding.schedule.vehicle.type),
          subtitle: convertVehicleCategoryTypeToName(bidding.schedule.vehicle.category),
          flex: 1,
        },
        {
          value: convertCurrencyFormat.format(bidding.value),
          flex: 1,
        },

        {
          value: (
            <Tooltip
              title={<span style={{ fontSize: '11px' }}>{convertBiddingStatus(bidding.status).label}</span>}
              placement="left"
            >
              <S.Info>
                <Tag
                  label={convertBiddingStatus(bidding.status).label}
                  color={convertBiddingStatus(bidding.status).color}
                  variant="solid"
                  display="flex"
                  size="md"
                />
              </S.Info>
            </Tooltip>
          ),
          flex: 0.7,
        },
      ];
    });
  }, [biddings]);

  const renderAllocationTable = useMemo(() => {
    return (
      <HeaderTable
        orderSelected={orderSelected}
        contentTable={mountTable}
        pageInfo={pageInfo}
        changePage={handlePaginate}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        isLoading={isLoading}
        clickRowAction={() => null}
      />
    );
  }, [mountTable, pageInfo, isLoading, clickOrderTableBy]);

  const tabContent = useMemo(() => {
    const tabs = [
      {
        name: 'Ofertados',
        content: renderAllocationTable,
      },
    ];

    return tabs;
  }, [renderAllocationTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={() => null} tabActive={'Ofertados'} />;
};
