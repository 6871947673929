import React, { useMemo } from 'react';
import {
  ButtonDownload,
  SpinnerButton,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/route-report-download-button/route-report-download-button-view.styled';
import { MapDownloadButtonIcon } from 'assets/icons/map-download-button.icon';

interface IRouteReportDownloadButtonViewProps {
  isLoading: boolean;
  handleReportDownload: () => void;
}

export const RouteReportDownloadButtonView: React.FC<IRouteReportDownloadButtonViewProps> = ({
  isLoading,
  handleReportDownload,
}) => {
  const renderIconButton = useMemo(() => {
    if (isLoading) {
      return <SpinnerButton />;
    }

    return <MapDownloadButtonIcon />;
  }, [isLoading]);

  return <ButtonDownload onClick={handleReportDownload}>{renderIconButton}</ButtonDownload>;
};
