import React from 'react';

import UserRegisterForm from '../user-register-form/user-register-form';

import { UserRegisterContainerStyled } from './user-register-body.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const UserRegisterBodyView: React.FC = () => {
  return (
    <UserRegisterContainerStyled>
      <HeaderPage breadcrumbList={['Cadastro de Usuários']} hasBackButton />
      <Title title="Usuário" hasTrace isForm />
      <UserRegisterForm />
    </UserRegisterContainerStyled>
  );
};
export default UserRegisterBodyView;
