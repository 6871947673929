import React, { useCallback } from 'react';
import InputFieldTimeView from './input-time-view';

type InputFieldTimeProps = {
  id: string;
  name: string;
  value?: any;
  type: 'text';
  label?: string;
  labelFor?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  smallInput?: boolean;
  justTime?: boolean;
};

const InputFieldTime: React.FC<InputFieldTimeProps> = ({
  id,
  name,
  value = '',
  type,
  label,
  justTime,
  placeholder,
  disabled,
  hasError,
  errorMessage,
  smallInput,
  onPaste = () => null,
  onChange = () => null,
  onBlur = () => null,
}) => {
  const [clockTime, setClockTime] = React.useState<string>('');

  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.currentTarget.value.replace(/\D/g, '');
      if (value.length >= 2) {
        if (+value.substring(0, 2) > 23) {
          value = `23${value.substring(2)}`;
        }

        if (!justTime) {
          if (+value.substring(0, 2) > 11) {
            setClockTime('PM');
          } else {
            setClockTime('AM');
          }
        }
      } else {
        setClockTime('');
      }

      if (value.length >= 3) {
        if (value.length >= 4) {
          if (+value.substring(2) > 59) {
            value = `${value.substring(0, 2)}59`;
          }
        }
        value = value.replace(/(\d{2})(\d)/, '$1:$2');
      }

      e.currentTarget.value = value;
      onChange && onChange(e as React.ChangeEvent<HTMLInputElement>);
    },
    [justTime, onChange],
  );

  return (
    <InputFieldTimeView
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      placeholder={placeholder}
      reactNode={clockTime}
      reactNodePosition={'right'}
      disabled={disabled}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={onChange}
      onPaste={onPaste}
      onBlur={onBlur}
      handleKeyUp={handleKeyUp}
      smallInput={smallInput}
    />
  );
};

export default InputFieldTime;
