export function formatPhoneNumber(phoneNumber: string) {
  let cleanedNumber = phoneNumber.replace(/[+\s()-]/g, '');

  if (cleanedNumber.length >= 13) {
    cleanedNumber = cleanedNumber.slice(2);
  }

  if (cleanedNumber.length === 11) {
    const ddd = cleanedNumber.slice(0, 2);
    const firstPart = cleanedNumber.slice(2, 7);
    const secondPart = cleanedNumber.slice(7, 11);
    return `(${ddd}) ${firstPart}-${secondPart}`;
  } else if (cleanedNumber.length === 10) {
    const ddd = cleanedNumber.slice(0, 2);
    const firstPart = cleanedNumber.slice(2, 6);
    const secondPart = cleanedNumber.slice(6, 10);
    return `(${ddd}) ${firstPart}-${secondPart}`;
  } else {
    return phoneNumber;
  }
}
