import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseShippingList } from '../../../../../../../../hooks/use-shipping-list/use-shipping-list.hook';
import { ScheduleDetailsAllocationShippingModalView } from './schedule-details-allocation-shipping-modal-view.component';
import { useUpdateScheduleAllocation } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-allocation/use-update-schedule-allocation.hook';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { IScheduleDetailsAllocationShippingListProps } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-shipping-list/get-shipping-list.types';
import { isEqual } from 'lodash';

const initialStateFilter: IScheduleDetailsAllocationShippingListProps = {
  search: '',
  page: 1,
  status: 'active',
  limitRegistry: 50,
};

export const ScheduleDetailsAllocationShippingModal: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const [shippingSelected, setShippingSelected] = useState<{ checked: boolean; shippingId: number }>({
    checked: false,
    shippingId: 0,
  });
  const [isLimitRequest, setIsLimitRequest] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<IScheduleDetailsAllocationShippingListProps>(initialStateFilter);
  const [currentFilter, setCurrentFilter] = useState<IScheduleDetailsAllocationShippingListProps>({
    ...initialStateFilter,
    page: 0,
  });
  const getShippingListHook = UseShippingList();
  const updateScheduleAllocationHook = useUpdateScheduleAllocation();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { loading, setInitialLoadingReset } = initialLoading;

  const isLoading = useMemo(() => loading, [loading]);

  const isLazyLoading = useMemo(
    () => scheduleDetails.allocationShipping.statusRequest === 'PROCESSING' && !isLoading,
    [isLoading, scheduleDetails.allocationShipping.statusRequest],
  );

  const isError = useMemo(
    () => scheduleDetails.allocationShipping.statusRequest === 'ERROR',
    [scheduleDetails.allocationShipping.statusRequest],
  );

  const isEmpty = useMemo(
    () =>
      scheduleDetails.allocationShipping.statusRequest === 'SUCCESS' &&
      !scheduleDetails.allocationShipping.shippingList.length,
    [scheduleDetails.allocationShipping.shippingList.length, scheduleDetails.allocationShipping.statusRequest],
  );

  const handleModalClose = useCallback(() => {
    setInitialLoadingReset();
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails, setInitialLoadingReset]);

  const handleSearch = useCallback((search: string) => {
    const filter: IScheduleDetailsAllocationShippingListProps = { ...initialStateFilter, search };

    setPage(1);
    setFilter(filter);
    setCurrentFilter({ ...filter, page: 0 });
  }, []);

  const handleSelectedShipping = useCallback((checked: boolean, shippingId: number) => {
    setShippingSelected({ checked, shippingId });
  }, []);

  const handleShippingAllocation = useCallback(
    async (shippingId: number) => {
      if (scheduleDetails.scheduleDetails.statusCode !== 'OT99') {
        updateScheduleAllocationHook({ shippingId });
      }
    },
    [scheduleDetails.scheduleDetails.statusCode, updateScheduleAllocationHook],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (!isEqual(filter, currentFilter)) {
      setCurrentFilter(filter);
      getShippingListHook(filter, setIsLimitRequest);
    }
  }, [currentFilter, filter, getShippingListHook]);

  useEffect(() => {
    if (!isLimitRequest && page > filter.page) {
      setFilter({ ...filter, page });
    }
  }, [filter, isLimitRequest, page]);

  return (
    <ScheduleDetailsAllocationShippingModalView
      statusProps={{ isError, isLoading, isEmpty }}
      shippings={scheduleDetails.allocationShipping.shippingList}
      handleModalClose={handleModalClose}
      handleSearch={handleSearch}
      infiniteScrollProps={infiniteScrollProps}
      handleShippingAllocation={handleShippingAllocation}
      handleSelectedShipping={handleSelectedShipping}
      shippingSelected={shippingSelected}
    />
  );
};
