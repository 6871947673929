export const qualityRequirementsValues = [
  'Ajudante',
  'Controle de temperatura',
  'AFE Saneantes',
  'AFE Correlatos',
  'IBAMA',
  'Sassmaq',
  'AFE Medicamentos',
  'AFE Alimentos',
  'AFE Cosméticos',
  'ISO',
  'Lona',
  'Chapatex'
];

export const securityRequirementsValues = [
  'Escolta',
  'Pamcary',
  'MOPP',
  'Sinalização',
  'Carga especial',
  'Tie Belt',
  'Rastreador',
  'EPIs',
];
