import { useCallback, useReducer } from 'react';
import { clientsInitialState } from '../../clients.constants';
import { clientsListReducer } from 'src-new/pages/registrations/pages/client/contexts/clients/reducers/clients-list/clients-list.reducer';
import { IClientsList, IClientsListFilter } from '../../types/clients-list.types';
import { ClientsListActionType } from 'src-new/pages/registrations/pages/client/contexts/clients/clients.action';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';

export const useClientsListContextValues = (): IClientsList => {
  const [state, dispatch] = useReducer(clientsListReducer, clientsInitialState);

  const setClientsListRequest = useCallback(() => {
    dispatch({ type: ClientsListActionType.CLIENTS_LIST_REQUEST, payload: undefined });
  }, []);

  const setClientsListSuccess = useCallback((clients: Array<IClientDomain>) => {
    dispatch({ type: ClientsListActionType.CLIENTS_LIST_SUCCESS, payload: clients });
  }, []);

  const setClientsListError = useCallback(() => {
    dispatch({ type: ClientsListActionType.CLIENTS_LIST_ERROR, payload: undefined });
  }, []);

  const setFilters = useCallback((filters: IClientsListFilter) => {
    dispatch({ type: ClientsListActionType.CLIENTS_LIST_SET_FILTERS, payload: filters });
  }, []);

  const setClientsListReset = useCallback(() => {
    dispatch({ type: ClientsListActionType.CLIENTS_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.clientsList,
    setClientsListRequest,
    setClientsListSuccess,
    setClientsListError,
    setClientsListReset,
    filterActions: {
      ...state.clientsList.filterActions,
      setFilters,
    },
  };
};
