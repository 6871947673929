import React from 'react';

const BoxOneIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      // width="20"
      // height="20"
      viewBox="0 0 20 20"
      x="0px"
      y="0px"
    >
      <path
        d="M16,3.81L4.09,10.07v11.86l11.91,6.26,11.91-6.26V10.07L16,3.81Zm0,1.22l10.21,5.37-4.53,2.38L11.47,7.41l4.53-2.38Zm-.54,21.66l-10.29-5.41V11.29l10.29-5.41v9.99ZM5.79,10.4l4.53-2.38,10.21,5.37-4.53,2.37L5.79,10.4Zm21.04,10.88l-10.29,5.41v-9.99l4.6-2.42v4.71h1.08v-5.28l4.61-2.42v9.99Z"
        fill="white"
      />
      <polygon points="6.022 18.251 6.022 20.723 8.531 22.043 8.531 19.568 6.022 18.251" fill="white" />
      <text
        x="0"
        y="47"
        fill="#000000"
        fontSize="5px" // Corrija o nome do atributo para "fontSize"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        Created by IGraphics
      </text>
      <text
        x="0"
        y="52"
        fill="#000000"
        fontSize="5px" // Corrija o nome do atributo para "fontSize"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        from the Noun Project
      </text>
    </svg>
  );
};

export default BoxOneIcon;
