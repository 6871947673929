export const convertDecimalFormat = Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const convertAndFormatDecimalNumber = (value: string | number) => {
  if (typeof value === 'string' && /^\d+,\d{2}$/.test(value)) {
    const numberValue = value.replace(',', '.');

    return convertDecimalFormat.format(Number(numberValue));
  }

  const formattedValue = Number(value);

  return convertDecimalFormat.format(formattedValue);
};
