import React, { useMemo } from 'react';

import {
  HeaderTitleStyled,
  SectionContainerStyled,
  UploadImageStyled,
  WrapperSectionStyled,
} from './freight-cargo-photos-section.styled';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleFileUrl } from 'pages/freight/services/schedule-file-url/schedule-file-url.service';
import { useHandleRemoveStatusFileHook, useUploadStatusFileHook } from 'pages/freight/hooks';
import { showToast } from 'components/toast/toast';
import InputUploadImage from 'components/input-upload-image/input-upload-image';

interface IProps {
  getImages: () => void;
  statusCode?: string;
  photos?: ScheduleFileUrl[];
  id?: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FreightCargoPhotosSectionView: React.FC<IProps> = ({
  getImages,
  statusCode,
  photos,
  id,
  isLoading,
  setIsLoading,
}) => {
  const handleUploadStatusFile = useUploadStatusFileHook();
  const handleRemoveStatusFileHook = useHandleRemoveStatusFileHook();

  const handleRemovePhoto = async (photoId: number) => {
    if (photoId && id) {
      try {
        setIsLoading(true);
        await handleRemoveStatusFileHook(+id, photoId);
        getImages();
      } catch (error: any) {
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    }
  };

  const renderPhotos = useMemo(() => {
    return photos?.map((photo, index) => {
      const separetedUrlFromKey = photo?.fileKey?.split('?');
      const fileExtention = separetedUrlFromKey?.[0]?.split('.')?.pop();
      const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];
      let fileType;

      if (fileExtention) {
        fileType = allowedImageFormats.includes(`image/${fileExtention}`) ? 'image' : 'file';
      }
      return (
        <SectionContainerStyled key={index}>
          <InputUploadImage
            urlImage={photo.fileKey}
            hasError={false}
            handleSelectedImage={() => null}
            deleteImage={() => handleRemovePhoto(photo.id)}
            fileType={fileType}
          />
        </SectionContainerStyled>
      );
    });
  }, [photos]);

  const handleAddPhoto = async (_: object, file: File) => {
    if (file && id && statusCode) {
      try {
        setIsLoading(true);
        await handleUploadStatusFile(id, statusCode, [file]);
        getImages();
        showToast({
          type: 'success',
          message: 'Foto adicionada com sucesso',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    }
  };

  return (
    <WrapperSectionStyled>
      <HeaderTitleStyled>Fotos/Registros</HeaderTitleStyled>
      <div style={{ overflow: 'auto', paddingBottom: 15 }}>
        <SectionContainerStyled>
          {isLoading ? (
            <div style={{ fontSize: '12px', fontWeight: 600 }}>Carregando...</div>
          ) : (
            <UploadImageStyled>
              {renderPhotos}
              <InputUploadImage hasError={false} handleSelectedImage={handleAddPhoto} />
            </UploadImageStyled>
          )}
        </SectionContainerStyled>
      </div>
    </WrapperSectionStyled>
  );
};

export default React.memo(FreightCargoPhotosSectionView);
