import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

export const Wrapper = styled.div`
  height: 165px;
  min-width: 38%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  background-color: #f2f2f2;
  border: 2px solid #eeeeee;
  border-radius: 5px;
`;

export const HeaderContainer = styled.div``;

interface IStrongProps {
  fontSize?: number;
  color?: ThemeColorsV2Type;
}
export const Strong = styled.strong<IStrongProps>`
  ${({ theme, fontSize = 20, color }) => css`
    font-size: ${fontSize}px;
    color: ${color && theme.colorsV2[color]};
  `}
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 7px;
`;

export const Text = styled.p`
  font-size: 12px;
`;

export const Div = styled.div``;
