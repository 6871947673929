import styled from 'styled-components';

export const ContainerSearchStyled = styled.div`
  display: flex;
`;

export const UploadDownStyled = styled.div`
  margin: 0 10px 0 10px;
`;

export const ImageStyled = styled.img`
  height: 80px;
  width: 80px;
`;

export const ContainerButtonStyled = styled.div`
  width: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 5px;

  button {
    height: 35px;
  }
`;
export const MatchPotentialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MatchPotentialContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const MatchPotentialCard = styled.div`
  color: white;
  text-align: center;
  padding: 2px 7px;
  border-radius: 5px;
  background-color: grey;
`;

export const Link = styled.a`
  color: rgb(9, 22, 50);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 5px;
  overflow: hidden;
`;
