import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${theme.colorsV2.forBorder};
    padding: 15px;
    border-radius: 5px;
  `}
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colorsV2.blue};
    padding-bottom: 5px;
  `}
`;

export const HeaderTags = styled.div`
  display: flex;
  gap: 10px;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

export const SectionContainer = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type IContainerInfoStyled = {
  cursor?: string;
};

export const ContainerInfoStyled = styled.div<IContainerInfoStyled>`
  ${({ cursor }) => css`
    font-size: 12px;
    color: #000;
    cursor: ${cursor ?? 'default'};
  `}
`;

export const IconSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Info = styled.span`
  display: flex;
  font-weight: 700;
  padding: 5px 0;
  text-transform: uppercase;
`;

export const TitleInfo = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
  gap: 3px;
`;
