import { ModalMatchDetails } from '..';

export const modalMatchInitialValues: ModalMatchDetails = {
  company: {
    fileKey: '',
    id: 0,
  },
  origin: {
    id: 0,
    city: '',
    uf: '',
    name: '',
    type: '',
  },
  destination: {
    id: 0,
    city: '',
    uf: '',
    name: '',
    type: '',
  },
  vehicle: {
    type: '',
    category: '',
  },
  typeSchedule: 'ftl',
  operationType: 'INBOUND',
  haulType: 'TRACKING',
  co2Economy: 0,
  freightValue: 0,
  logshareValue: 0,
  backhaulPartner: false,
  weight: 0,
  cubage: 0,
  pallets: 0,
  id: 0,
  invoicedBy: '',
  exclusiveRate: false,
  matchOfferId: 0,
  saleOfferId: 0,
  saleOfferTariffId: 0,
};
