import { ModalMatchDetails } from '../types';

import { MatchRegisterInput } from 'domain-v2/match/register';
import { IdlenessValues } from 'domain/idleness';

type ValueType = 'origin' | 'destination';

export const convertRegisterMatch = (
  input: MatchRegisterInput,
  transportRouter: ModalMatchDetails,
  idleness?: IdlenessValues,
) => {
  const { origin, destination, cargo, haulType, cost, dates } = input;

  const merchValue = cargo.merchValue.toString().replace(',', '.');

  const originDestinyValues = (valueType: ValueType) => {
    const map: Record<ValueType, { id: number; type: string }> = {
      origin: {
        id: origin.id || (idleness?.originId ?? transportRouter.origin.id),
        type: origin.type || (idleness?.originType ?? transportRouter.origin.type),
      },
      destination: {
        id: destination.id || (idleness?.destinyId ?? transportRouter.destination.id),
        type: destination.type || (idleness?.destinyType ?? transportRouter.destination.type),
      },
    };

    return map[valueType];
  };

  return {
    matchOfferId: input.matchOfferId,
    saleOfferId: transportRouter.saleOfferId,
    saleOfferTariffId: transportRouter.saleOfferTariffId,
    companySeller: transportRouter.company.id,
    origin: {
      id: originDestinyValues('origin').id,
      type: originDestinyValues('origin').type,
    },
    destination: {
      id: originDestinyValues('destination').id,
      type: originDestinyValues('destination').type,
    },
    cargo: {
      vehicleType: cargo.vehicleType,
      vehicleCategoryType: cargo.vehicleCategoryType,
      shippingReturn: cargo.shippingReturn,
      product: cargo.product,
      weight: Number(cargo.weight),
      cubagem: Number(cargo.cubagem),
      volume: Number(cargo.volume),
      merchValue: Number(merchValue),
      inPallet: Number(cargo.pallet) > 0,
      pallet: Number(cargo.pallet),
      order: cargo.order,
      comments: cargo.comments,
      requirements: {
        quality: cargo.requirements.quality?.values ?? [],
        security: cargo.requirements.security?.values ?? [],
        custom: cargo.requirements.custom?.values ?? [],
      },
    },
    dates: dates
      .map((date) => {
        if (date.initialLevyDate && date.finalLevyDate && date.initialDeliveryDate && date.finalDeliveryDate) {
          return {
            initialLevyDate: date.initialLevyDate,
            finalLevyDate: date.finalLevyDate,
            initialDeliveryDate: date.initialDeliveryDate,
            finalDeliveryDate: date.finalDeliveryDate,
          };
        }
      })
      .filter((date) => date !== undefined),
    invoicedBy: transportRouter?.invoicedBy,
    cost: {
      freightValue: cost.freightValue,
      tollValue: cost.tollValue,
      grisAdvalorem: cost.grisAdvalorem,
      freightTotal: cost.freightTotal,
      icms: cost.icms,
      totalService: cost.totalService,
      otherTaxes: cost.otherTaxes,
    },
    haulType,
  };
};
