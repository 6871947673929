import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import React from 'react';

import { useFreightList } from '../hooks/use-task-list/use-task-list.hook';

import TaskVisionView from './task-vision-view';

import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';

const initialScheduleKanbanList = {} as ICurrentAllocation;

const TaskVision: React.FC = () => {
  const freightListHook = useFreightList();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery({
    queryKey: ['scheduleKanbanList'],
    queryFn: () => freightListHook(),
    refetchOnWindowFocus: false,
    staleTime: 600 * 1000,
  });

  const handleSelectedFreight = (id: string) => navigate(`/fretes/${id}`);

  return (
    <TaskVisionView
      schedules={data || [initialScheduleKanbanList]}
      isLoading={isLoading}
      handleSelectedFreight={handleSelectedFreight}
    />
  );
};

export default React.memo(TaskVision);
