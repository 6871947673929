import { FC } from 'react';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import AsyncSelectField from 'components/async-select/async-select';
import { FormikErrors } from 'formik';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { itemsFilterSearchService } from 'pages/vehicle/services/items-search/items.filter-search.service';

interface IWeighingFormMountItemsViewProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<IWeighingFormRegisterDomain>>;
}

export const WeighingFormMountItemsView: FC<IWeighingFormMountItemsViewProps> = ({ setFieldValue }) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Item"
          isClearable={true}
          name="itemId"
          id="itemId"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue('itemId', e && e.value);
          }}
          apiCallFunction={itemsFilterSearchService}
          loadOptions={itemsFilterSearchService}
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
