import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useFormikContext } from 'formik';

import { SectionStyled } from 'pages/schedule/schedule-register/components/section/section.styled';
import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { LineSectionStyled } from 'pages/accepted-matches/accepted-matches-update/accepted-matches-update-form/accepted-matches-update-form.styled';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import { InputCalendar } from 'components/input-calendar/input-calendar';
import Button from 'components/button/button';

const HoursUpdateModal: React.FC = () => {
  const { setFieldValue, submitForm, values } = useFormikContext<IAllocationShippingDetails>();

  const [dueDate, setAcceptDate] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  useEffect(() => {
    setFieldValue('dueDate', dueDate.date ? dueDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : undefined);
  }, [dueDate.date, setFieldValue]);

  const [createdAt, setCreatedAt] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  useEffect(() => {
    setFieldValue('createdAt', createdAt.date ? createdAt.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : undefined);
  }, [createdAt.date, setFieldValue]);

  const [paymentDay, setPaymentDay] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  useEffect(() => {
    setFieldValue('paymentDay', paymentDay.date ? paymentDay.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : undefined);
  }, [paymentDay.date, setFieldValue]);

  return (
    <div style={{ width: 550 }}>
      <SectionStyled name="Informações do Empresa">
        <LineSectionStyled columns="1fr 1fr  1fr 1fr">
          <InputCalendar
            label="Emitida em"
            onDateChange={(date) =>
              setCreatedAt((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setCreatedAt((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={createdAt.focused}
            date={createdAt.date}
            id="createdAt"
            selectPastDays
            hasPicker
          />
          <InputCalendar
            label="Vencimento em"
            onDateChange={(date) =>
              setAcceptDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setAcceptDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={dueDate.focused}
            date={dueDate.date}
            id="dueDate"
            selectPastDays
            hasPicker
          />
          <InputCalendar
            label="Liquidada em"
            onDateChange={(date) =>
              setPaymentDay((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setPaymentDay((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={paymentDay.focused}
            date={paymentDay.date ?? undefined}
            id="paymentDay"
            selectPastDays
            hasPicker
          />
          <InputCurrency
            label="Acréscimo"
            id="addition"
            name="addition"
            prefix={'R$ '}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value, name) => setFieldValue(name ?? '', value)}
            value={values.addition}
          />
        </LineSectionStyled>

        <LineSectionStyled columns="1fr 3fr">
          <InputCurrency
            label="Desconto"
            id="discount"
            name="discount"
            prefix={'R$ '}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value, name) => setFieldValue(name ?? '', value)}
            value={values.discount}
          />
          <InputField
            label="Descrição"
            id="description"
            name="description"
            type="text"
            onChange={(e: any) => setFieldValue('description', e.target.value)}
            value={values.description}
          />
        </LineSectionStyled>

        <LineSectionStyled columns="1fr 1fr"></LineSectionStyled>

        <div
          style={{
            marginTop: 30,
            display: 'flex',
            gap: 20,
            justifyContent: 'flex-end',
          }}
        >
          <Button bgColor="blue" title="Salvar" callback={submitForm} size="large" />
        </div>
      </SectionStyled>
    </div>
  );
};

export default HoursUpdateModal;
