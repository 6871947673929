import React from 'react';
import { useFormikContext } from 'formik';

import * as S from './styled';

import { IMatchesListParams } from 'domain/matches';
import {
  fleetTypeOptions,
  offerToOptions,
  operationTypeOptions,
  potencialMatchesOptions,
  vehicleTypeOptions,
} from 'domain/global-inputs';
import Select from 'components/select/select';
import Button from 'components/button/button';

export const ModalMatchFilterForm: React.FC = () => {
  const { setFieldValue, values, resetForm, submitForm } = useFormikContext<IMatchesListParams>();

  return (
    <S.HireBackhaulBuyMatchFormStyled>
      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Tipo de Veículo</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Selecione o Tipo"
            id="vehicleType"
            name="vehicleType"
            value={values.vehicleType}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={vehicleTypeOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Tipo de Operação</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Tipo de Operação"
            id="operationType"
            name="operationType"
            value={values.operationType}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={operationTypeOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Tipo de Frota</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Tipo de Frota"
            id="fleetType"
            name="fleetType"
            value={values.fleetType}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={fleetTypeOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Classificação</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Tipo de Classificação"
            id="offerTo"
            name="offerTo"
            value={values.offerTo}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={offerToOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Match Origem</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Match Potencial de Origem"
            id="matchOrigin"
            name="matchOrigin"
            value={values.matchOrigin}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={potencialMatchesOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Match Destino</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Match Potencial de Destino"
            id="matchDestiny"
            name="matchDestiny"
            value={values.matchDestiny}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={potencialMatchesOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <S.ContainerRowStyled>
        <S.SecondTitleStyled>Match Veículo</S.SecondTitleStyled>
        <S.LineSectionStyled columns="1fr">
          <Select
            placeholder="Match Potencial de Veículo"
            id="matchVehicle"
            name="matchVehicle"
            value={values.matchVehicle}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={potencialMatchesOptions}
            isClearable
          />
        </S.LineSectionStyled>
      </S.ContainerRowStyled>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 20,
          gap: 15,
        }}
      >
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
        <Button title="Limpar" bgColor="white" callback={resetForm} size="large" />
      </div>
    </S.HireBackhaulBuyMatchFormStyled>
  );
};
