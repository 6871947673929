/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { FormikHelpers, FormikProps, useFormikContext } from 'formik';

import { FinancialSchedulesTable } from '../financial-schedules-table/table';
import { defaultInitialPage } from '../../../../../default_constants';

import { ModalSelectInvoicingStyle, ModalSelectionInvoiceData, ModalSelectionInvoiceForm } from './index.style';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { UserContext } from 'state/user-context';
import { invoicingAvailableScheduleTableListFormat } from 'pages/fiscal-document/utils/table-list-format';
import { financialCreateValidationScheme } from 'pages/fiscal-document/utils/financial-validations';
import { useFinancialAvailableScheduleListHook, useFinancialCreateHook } from 'pages/fiscal-document/hooks';
import { ISchedule } from 'domain/scheduling';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import { SelectCompanies } from 'components/molecules/select-companies';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { InputCalendar } from 'components/input-calendar/input-calendar';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface ModalSelectForm {
  ids: number[];
  dueDate: Moment;
}

export const ModalSelectInvoicing: FC<Props> = (props) => {
  const formRef = useRef<FormikProps<any>>(null);

  const financialCreateHook = useFinancialCreateHook();

  const handleCreateInvoicing = async (values: ModalSelectForm, helpers: FormikHelpers<ModalSelectForm>) => {
    if (values.ids.length === 0) {
      showMessageFormatted({
        message: 'Selecione um agendamento para criar a fatura.',
        type: 'error',
      });

      return;
    }

    const response = await financialCreateHook({
      dueDate: values.dueDate.toISOString(),
      schedules: values.ids,
      invoiceType: '',
      calcFinanceIds: [],
    });

    if (!response) return;
    helpers.resetForm();
    props.onSuccess();
    props.onClose();
  };

  if (!props.isOpen) return null;

  return (
    <ModalConfirmation
      description={
        <ModalSelectInvoicingStyle
          innerRef={formRef}
          initialValues={{
            ids: [],
            dueDate: moment(),
          }}
          onSubmit={(values, helpers) =>
            handleCreateInvoicing(values as ModalSelectForm, helpers as FormikHelpers<ModalSelectForm>)
          }
          validationSchema={financialCreateValidationScheme}
        >
          <ModalSelectInvoicingDescription />
        </ModalSelectInvoicingStyle>
      }
      title="Nova Fatura"
      type="financial"
      size="large"
      onConfirmation={() => formRef.current?.submitForm()}
      handleCancel={props.onClose}
      handleXCancel={props.onClose}
    />
  );
};

const ModalSelectInvoicingDescription: FC = () => {
  const { isAdmin, user } = useContext(UserContext);
  const { setFieldValue, values, errors } = useFormikContext<ModalSelectForm>();

  const financialAvailableScheduleListHook = useFinancialAvailableScheduleListHook();

  const [selectedClient, setSelectedClient] = useState<number>(-1);
  const [scheduleTotal, setScheduleTotal] = useState<number>(0);
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultInitialPage);
  const [scheduleItems, setSchedulesItems] = useState<ISchedule[]>([]);

  const [dueDateInput, setDueDateInput] = useState<{
    focused: boolean;
    date: Moment | null;
    setted: boolean;
  }>({ focused: false, date: null, setted: false });

  useEffect(() => {
    setFieldValue('ids', selectedSchedules);
  }, [selectedSchedules]);

  const handleGetScheduleList = useCallback(async () => {
    setHasLoading(true);
    const response = await financialAvailableScheduleListHook({
      companyId: selectedClient,
      page: currentPage,
    });

    if (response) {
      setPageInfo(response.meta);
      setSchedulesItems(response.items);
    }
    setHasLoading(false);
  }, [selectedClient, currentPage]);

  useEffect(() => {
    if (isAdmin) setSelectedClient(-1);
    else user && setSelectedClient(user.companyId);
  }, [isAdmin, user]);

  useEffect(() => {
    handleGetScheduleList();
  }, [handleGetScheduleList]);

  const handleSelectSchedule = (id: number, value: number) => {
    if (selectedSchedules.includes(id)) {
      setSelectedSchedules(selectedSchedules.filter((item) => item !== id));
      setScheduleTotal((oldTotal) => oldTotal - value);
    } else {
      setSelectedSchedules([...selectedSchedules, id]);
      setScheduleTotal((oldTotal) => oldTotal + value);
    }
  };

  const financialSchedulesItems = useMemo((): IContentTable[] => {
    return scheduleItems.map((item) =>
      invoicingAvailableScheduleTableListFormat(item, selectedSchedules, handleSelectSchedule, 0),
    );
  }, [scheduleItems, selectedSchedules]);

  return (
    <ModalSelectionInvoiceForm>
      <ModalSelectionInvoiceData>
        <div>
          <InputCalendar
            label="Data de Emissão"
            placeholder=""
            onDateChange={() => null}
            onFocusChange={() => null}
            focused={false}
            date={moment()}
            id="schedulingDetails.deliveryInitialDate"
            hasPicker
            disabled
          />
        </div>
        <div>
          <InputCalendar
            label="Data de Vencimento"
            placeholder=""
            onDateChange={(date) => {
              setFieldValue('dueDate', date);
            }}
            onFocusChange={({ focused }) => {
              setDueDateInput({ ...dueDateInput, focused });
            }}
            focused={dueDateInput.focused}
            date={moment(values.dueDate)}
            id="schedulingDetails.deliveryInitialDate"
            hasError={!!errors.dueDate}
            errorMessage={String(errors.dueDate)}
            hasPicker
            withPortal={false}
          />
        </div>
        <div>
          {isAdmin ? (
            <SelectCompanies
              onChange={(value) => {
                setSelectedClient(value);
              }}
              id="invoicingSelectClient"
              name="invoicingSelectClient"
              defaultValue={{
                label: user?.name || '',
                value: selectedClient,
              }}
            />
          ) : (
            <SelectCompanies
              onChange={(value) => {
                setSelectedClient(value);
              }}
              id="invoicingSelectClient"
              name="invoicingSelectClient"
              defaultValue={{
                label: user?.companyLogin || '',
                value: selectedClient,
              }}
              disabled
            />
          )}
        </div>
      </ModalSelectionInvoiceData>
      <div
        style={{
          margin: '15px 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        Total da Fatura: <strong style={{ marginLeft: 5 }}>{convertCurrencyFormat.format(scheduleTotal)}</strong>
      </div>
      <div>
        <FinancialSchedulesTable
          contentTable={financialSchedulesItems}
          pageInfo={pageInfo}
          changePage={setCurrentPage}
          isLoading={hasLoading}
          goToDetailsPage={() => null}
        />
      </div>
    </ModalSelectionInvoiceForm>
  );
};
