import React, { ReactElement, useMemo } from 'react';
import { HomeBidOffersTable } from 'src-new/pages/home/pages/home/components/home-bid-offers/components/home-bid-offers-table/home-bid-offers-table.component';
import {
  ContentContainer,
  EmptyContainer,
  ErrorContainer,
  HeaderContainer,
  LoadingContainer,
  TextLink,
  Title,
  TitleContainer,
  TitleStrong,
  Wrapper,
} from './home-bid-offers-view.styled';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export interface IHomeBidOffersStateRequest {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
}

interface IHomeBidOffersViewProps {
  infiniteScrollProps: IInfiniteScrollTable;
  stateRequest: IHomeBidOffersStateRequest;
  isLoadingInfinityScroll: boolean;
  redirectToSaleOfferListPage: () => void;
}

export const HomeBidOffersView: React.FC<IHomeBidOffersViewProps> = ({
  infiniteScrollProps,
  stateRequest,
  redirectToSaleOfferListPage,
}) => {
  const renderContent = useMemo((): ReactElement => {
    if (stateRequest.isLoading) {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }

    if (stateRequest.isError) {
      return (
        <ErrorContainer>
          <ErrorState />
        </ErrorContainer>
      );
    }

    if (stateRequest.isEmpty) {
      return (
        <EmptyContainer>
          <EmptyState subTitle={'Você ainda não recebeu oportunidades para bidding'} />
        </EmptyContainer>
      );
    }

    return <HomeBidOffersTable infiniteScrollProps={infiniteScrollProps} />;
  }, [infiniteScrollProps, stateRequest.isEmpty, stateRequest.isError, stateRequest.isLoading]);

  return (
    <Wrapper>
      <HeaderContainer>
        <TitleContainer>
          <Title>Ofertas para </Title>
          <TitleStrong>Bid</TitleStrong>
        </TitleContainer>
        <TextLink onClick={redirectToSaleOfferListPage}>Ver Todos</TextLink>
      </HeaderContainer>
      <ContentContainer>{renderContent}</ContentContainer>
    </Wrapper>
  );
};
