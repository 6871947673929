import { useCallback, useReducer } from 'react';
import { ISaleOfferListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list.domain';
import { saleOfferListReducer } from '../../reducers/sale-offer-list/sale-offer-list.reducer';
import { SaleOfferListActionType } from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import { ISaleOfferList } from '../../types/sale-offer-list.types';

export const useSaleOfferListContextValues = (): ISaleOfferList => {
  const [state, dispatch] = useReducer(saleOfferListReducer, saleOfferInitialState);

  const setSaleOfferListRequest = useCallback(() => {
    dispatch({ type: SaleOfferListActionType.SALE_OFFER_LIST_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferListSuccess = useCallback((list: Array<ISaleOfferListDomain>) => {
    dispatch({ type: SaleOfferListActionType.SALE_OFFER_LIST_SUCCESS, payload: list });
  }, []);

  const setSaleOfferListError = useCallback(() => {
    dispatch({ type: SaleOfferListActionType.SALE_OFFER_LIST_ERROR, payload: undefined });
  }, []);

  const setSaleOfferListReset = useCallback(() => {
    dispatch({ type: SaleOfferListActionType.SALE_OFFER_LIST_RESET, payload: undefined });
  }, []);

  const setSelectIds = useCallback((ids: Array<number>) => {
    dispatch({ type: SaleOfferListActionType.SALE_OFFER_SELECT_IDS, payload: ids });
  }, []);

  return {
    ...state.saleOfferList,
    setSaleOfferListRequest,
    setSaleOfferListSuccess,
    setSaleOfferListError,
    setSaleOfferListReset,
    setSelectIds,
  };
};
