import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  height: 78vh;
  overflow: auto;
  padding-bottom: 5px;
`;

export const ButtonOpenOrClose = styled.button`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  top: 1rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid rgb(213, 213, 213);
`;

export const MarginEditButton = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FinancialButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

interface IContainerButtonsProps {
  isDisabled?: boolean;
}
export const ContainerButtons = styled.div<IContainerButtonsProps>`
  ${({ isDisabled }) => css`
    background-color: ${!isDisabled && 'white'};
    border-radius: 5px;
  `}
`;
