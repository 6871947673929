import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HomeMatchesTableView, IMatchesTableViewStatesProps } from './home-matches-table-view.component';
import { useNavigate } from 'react-router-dom';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { useGetHomeMatches } from '../../hooks/use-get-home-matches/use-get-home-matches.hook';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export const HomeMatchesTable: React.FC = () => {
  const { homeMatches } = useContext(HomeContext);
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const getHomeMatchesHook = useGetHomeMatches();

  const handlePurchaseList = useCallback(() => navigate('/ofertas-compra'), [navigate]);

  const states = useMemo((): IMatchesTableViewStatesProps => {
    return {
      isLoading: isLoading,
      isError: homeMatches.statusRequest === 'ERROR',
      isEmpty: homeMatches.statusRequest === 'SUCCESS' && !homeMatches.homeMatches.length,
    };
  }, [homeMatches.homeMatches.length, homeMatches.statusRequest, isLoading]);

  const isLazyLoading = useMemo(
    () => !isLoading && homeMatches.statusRequest === 'PROCESSING',
    [homeMatches.statusRequest, isLoading],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPageInfinityScroll }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (homeMatches.statusRequest === 'INITIAL') {
      getHomeMatchesHook();
    }
  }, [getHomeMatchesHook, homeMatches.statusRequest]);

  useEffect(() => {
    const statusRequest = homeMatches.statusRequest;
    if (statusRequest === 'SUCCESS' || statusRequest === 'ERROR') {
      setIsLoading(false);
    }
  }, [homeMatches.statusRequest]);

  useEffect(() => {
    if (
      !homeMatches.paginationActions.isPageLimit &&
      pageInfinityScroll > currentPage &&
      homeMatches.statusRequest !== 'ERROR'
    ) {
      setCurrentPage(pageInfinityScroll);
      getHomeMatchesHook();
    }
  }, [
    homeMatches.paginationActions.isPageLimit,
    currentPage,
    getHomeMatchesHook,
    pageInfinityScroll,
    homeMatches.statusRequest,
  ]);

  return (
    <HomeMatchesTableView
      infiniteScrollProps={infiniteScrollProps}
      states={states}
      handlePurchaseList={handlePurchaseList}
    />
  );
};
