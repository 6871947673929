import styled, { DefaultTheme, css } from 'styled-components';

export const ContainerLoading = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div``;

export const Identification = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontV2.family};
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: ${theme.font.normal};
    font-style: normal;
    line-height: normal;
  `}
`;

export const WrapperActions = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
  `}
`;

export const WrapperToogle = styled.div`
  ${() => css`
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 20px;
  `}
`;

export interface BoxWrapper {
  gridColumns: string;
}
export const BoxWrapper = styled.div<BoxWrapper>`
  ${({ gridColumns }) => css`
    display: grid;
    grid-template-columns: ${gridColumns};
    gap: 15px;
    overflow: auto;

    max-height: 380px;

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background: transparent;
      border-radius: 50px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #12264e;
      width: 50px;
    }
  `}
`;

export const Box = styled.div`
  min-width: 230px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: #000;
    font-weight: ${theme.font.semiBold};
    font-style: normal;
    line-height: normal;
    font-size: 11px;
    margin-bottom: 5px;
  `}
`;

export const Subtitle = styled.div`
  ${() => css`
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const InputUploadFile = styled.div`
  div {
    display: flex;
    align-self: center;
    width: 100%;
    margin-bottom: 5px;
  }
`;

const StatusModifier = {
  aprovado: (theme: DefaultTheme) => css`
    background: ${theme.colorsV2['aqua-100']};
  `,
  restricao: (theme: DefaultTheme) => css`
    background: ${theme.colorsV4.logshareBlue};
  `,
  reprovado: (theme: DefaultTheme) => css`
    background: ${theme.colorsV2.red};
  `,
};
export interface StatusProps {
  status: string;
}
export const Status = styled.div<StatusProps>`
  ${({ theme, status }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: fit-content;
    border-radius: 30px;
    background: ${theme.colorsV2['blue']};
    color: ${theme.colorsV2['white']};
    font-size: 10px;
    font-weight: 600;
    ${status == 'aprovado' && StatusModifier[status](theme)}
    ${status == 'restrição' && StatusModifier.restricao(theme)}
    ${status == 'reprovado' && StatusModifier.reprovado(theme)}
  `}
`;

export const InfoWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
  `}
`;

export const InfoLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: space-between;
`;

export const ContainerButtons = styled.div`
  background-color: white;
  border-radius: 5px;
`;
