import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateCTeDataService } from '../../services/update-cte-data/update-cte-data.service';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { getCTeDataService } from '../../services/get-cte-data/get-cte-data.service';

export const useUpdateCTeData = () => {
  const { isShippingCompany, isAdmin } = useContext(UserContext);
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(
    async (files: FileList) => {
      try {
        scheduleDocuments.cteDataProps.setCTeDataRequest();

        const isSubCTe = isShippingCompany || (scheduleDetails.scheduleDetails.operatedBy !== 'LOGSHARE' && !isAdmin);

        const fileArray = Array.from(files);

        const filesPromises = fileArray.map((file) =>
          updateCTeDataService(
            scheduleDetails.scheduleDetails.id,
            scheduleDetails.scheduleDetails.invoicedBy,
            file,
            isSubCTe,
          ),
        );

        await Promise.all(filesPromises);

        const data = await getCTeDataService(scheduleDetails.scheduleDetails.id);

        scheduleDocuments.cteDataProps.setCTeDataSuccess(data);
      } catch (error) {
        scheduleDocuments.cteDataProps.setCTeDataError();
        showMessageFormatted({
          message: 'Erro ao enviar CTe.',
          type: 'error',
        });
      }
    },
    [
      isAdmin,
      isShippingCompany,
      scheduleDetails.scheduleDetails.id,
      scheduleDetails.scheduleDetails.invoicedBy,
      scheduleDetails.scheduleDetails.operatedBy,
      scheduleDocuments.cteDataProps,
    ],
  );
};
