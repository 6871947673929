import React from 'react';

import { InfoStyled, Wrapper } from './status.styled';

import { convertStatusCode } from 'utils/status-code';

interface Status {
  statusCode: string;
  previsionDate: string;
}

export const DeliveryTrackingStatus: React.FC<Status> = ({ statusCode, previsionDate }) => {
  return (
    <Wrapper>
      <InfoStyled fontSize={15} fontWeight={500}>
        STATUS DE ENTREGA
      </InfoStyled>

      <InfoStyled fontSize={20} fontWeight={700}>
        {convertStatusCode(statusCode)}
      </InfoStyled>

      <InfoStyled fontSize={14} fontWeight={400}>
        PREVISÃO DE ENTREGA - {previsionDate}
      </InfoStyled>
    </Wrapper>
  );
};
