import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button } from 'logshare-ui-kit';
import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IScheduleDetailsFinanceDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import * as S from './schedule-finance-audit-modal-form-view.styled';

interface IScheduleFinanceAuditModalFormViewProps {
  transactionDetails: IScheduleDetailsFinanceDomain;
  handleOnSubmit: () => void;
  handleCloseModalFinanceAudit: () => void;
}

export const ScheduleFinanceAuditModalFormView: FC<IScheduleFinanceAuditModalFormViewProps> = ({
  transactionDetails,
  handleOnSubmit,
  handleCloseModalFinanceAudit,
}) => {
  return (
    <ModalBark
      size={'xsm'}
      title={
        <S.ContainerModalTitle>
          <S.ModalTitle>Confirmar</S.ModalTitle>
          <S.ModalTitleBold>Auditoria</S.ModalTitleBold>
        </S.ContainerModalTitle>
      }
      handleClose={handleCloseModalFinanceAudit}
    >
      <S.ModalWrapper>
        <S.FormWrapper>
          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <S.InformationWrapper>
              <S.InformationTitle>Frete Valor</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.valueFreight)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Ajudante</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.helperTotal)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Pedágio</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.toll)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Descarga</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.discharge)}</S.InformationValue>
            </S.InformationWrapper>
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <S.InformationWrapper>
              <S.InformationTitle>Desconto</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.penaltyBonus)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Taxa Logshare</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.totalFee)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Gris/Advalorem</S.InformationTitle>
              <S.InformationValue>
                {convertCurrencyFormat.format(transactionDetails.totalGrisAdvalorem)}
              </S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Tempo Excedente</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.surplusTime)}</S.InformationValue>
            </S.InformationWrapper>
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <S.InformationWrapper>
              <S.InformationTitle>Frete Total</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.totalFreight)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Taxa Logshare</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.icms)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Outros Impostos</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.otherTaxes)}</S.InformationValue>
            </S.InformationWrapper>

            <S.InformationWrapper>
              <S.InformationTitle>Total do Serviço</S.InformationTitle>
              <S.InformationValue>{convertCurrencyFormat.format(transactionDetails.totalService)}</S.InformationValue>
            </S.InformationWrapper>
          </LineSectionLayout>
        </S.FormWrapper>

        <S.WrapperButtonSubmit>
          <Button label="Confirmar" color="red" onPress={handleOnSubmit} size="medium" variant="solid" />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
