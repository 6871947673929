/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useContext, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalDeleteUploadedNfe } from '../modal-delete/modal-delete-uploaded-nfe';

import { RenderCTeDataStyled, RenderCTeInfo } from './index.style';

import { downloadDocument, downloadFromUrl } from 'utils/download-file/download-file';
import { UserContext } from 'state/user-context';
import { FileListUploaded } from 'pages/freight/types';
import { ScheduleDomain } from 'domain/schedule';
import Button from 'components/button/button';

interface Props {
  index: number;
  cte: FileListUploaded;
  onSuccess?: () => void;
  freightValues: ScheduleDomain;
}

export const RenderNFeUploadedData: FC<Props> = (props) => {
  const { user } = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteCte = async () => {
    setIsModalOpen(false);
    props.onSuccess?.();
  };

  const chaveAcesso = useMemo(() => {
    return `${props.cte.name}`;
  }, [props.cte]);

  const renderActions = useMemo(() => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <Button
          title="Download"
          callback={() => downloadDocument(props.cte.url, props.cte.name)}
          bgColor="white"
          icon={<DownloadIcon />}
          size="very-small"
        />
        <Button
          title="Visualizar"
          callback={() => downloadFromUrl(props.cte.url)}
          bgColor="white"
          icon={<SearchIcon />}
          size="very-small"
        />
        {user?.plan !== 'shipping-company' && (
          <Button
            title="Deletar"
            callback={() => setIsModalOpen(true)}
            bgColor="pink"
            icon={<DeleteIcon />}
            size="very-small"
          />
        )}
      </div>
    );
  }, [props.cte]);

  if (!props.cte) return null;

  return (
    <>
      <ModalDeleteUploadedNfe
        cteData={props.cte}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleDeleteCte}
      />

      <RenderCTeDataStyled>
        <div>
          <div>
            <RenderCTeInfo>{chaveAcesso}</RenderCTeInfo>
          </div>
        </div>
        {renderActions}
      </RenderCTeDataStyled>
    </>
  );
};
