import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { DriverRegisterSchema } from './schemas-validation/schemas-validation';
import {
  initialValues,
  optionsCategory,
  optionsCertifications,
  optionsSSP,
  PATH_TO_DRIVER_LIST,
} from './form-values/form-values';
import DriverRegisterFormView from './driver-update-form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { optionsFleetType } from 'pages/vehicle/vehicle-update/components/vehicle-update-form/form-values/form-values';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useUpdateDriverHook } from 'pages/driver/hooks/use-driver-update/use-driver-update.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { IDriverCreatedDomain } from 'src-new/pages/registrations/domains/driver.domain';
import { useGetDetailsDriver } from 'src-new/pages/registrations/pages/driver/pages/driver-update/hooks/use-get-details-driver/use-get-details-driver.hook';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';

const DriverUpdateForm: React.FC = () => {
  const [driverValues, setDriverValues] = useState<IDriverCreatedDomain>(initialValues);
  const [shippingCompanyName, setShippingCompanyName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const getDetailsDriver = useGetDetailsDriver();
  const updateDriverHook = useUpdateDriverHook();
  const cepHook = useCepHook();
  const goBackToDriverList = useCallback(() => navigate(PATH_TO_DRIVER_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (driver: IDriverCreatedDomain) => {
      updateDriverHook(driver)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToDriverList();
        })
        .catch((error) => {
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          });
        });
    },
    [goBackToDriverList, updateDriverHook],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  const apiCallShipping = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return shippingCompanyFilterSearchService(searchValue);
    }
    return [];
  };

  useEffect(() => {
    if (id) {
      getDetailsDriver(id, setIsLoading, setIsError, setDriverValues, setShippingCompanyName);
    }
  }, [getDetailsDriver, id]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <DriverRegisterFormView
      driverUpdateValues={driverValues}
      optionsCategoryType={optionsCategory}
      optionsCertificationsType={optionsCertifications}
      optionsSSPType={optionsSSP}
      handleGoBack={goBackToDriverList}
      handleRegister={handleSubmit}
      schemaValidation={DriverRegisterSchema}
      onBlurCep={onBlurCep}
      optionFleetType={optionsFleetType}
      shippingApiCall={apiCallShipping}
      shippingCompanyName={shippingCompanyName}
    />
  );
};

export default DriverUpdateForm;
