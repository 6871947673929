import React, { Fragment, useEffect, useMemo } from 'react';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { useGetScheduleRefusedList } from '../../hooks/use-schedule-refused-list/use-schedule-refused-list.hook';
import { ScheduleRefusedTableTabsView } from './schedule-refused-table-tabs-view.component';

export const ScheduleRefusedTableTabs: React.FC = () => {
  const getScheduleRefusedListHook = useGetScheduleRefusedList();

  const tabsContent = useMemo((): Array<ITabContent> => [{ name: 'Cargas Recusadas', content: <Fragment /> }], []);

  useEffect(() => {
    getScheduleRefusedListHook();
  }, [getScheduleRefusedListHook]);

  return <ScheduleRefusedTableTabsView tabsContent={tabsContent} />;
};
