import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { IAcceptedMatchEntityCreatedV2 } from 'pages/accepted-matches/service/entities/accepted-matches.entity';
import { IAcceptedMatchesCreatedV2 } from 'domain/accepted-matches';

export const convertAcceptedMatchesCreatedEntityToDomainMapperV2 = (
  acceptedMatches: IAcceptedMatchEntityCreatedV2,
): IAcceptedMatchesCreatedV2 => ({
  id: acceptedMatches.id,
  internalCode: acceptedMatches.internalCode,
  operationType: acceptedMatches.operationType,
  processType: acceptedMatches.processType,

  allowedVehicles: acceptedMatches.allowedVehicles,
  allowedShipping: acceptedMatches.allowedShipping,
  leadTime: Number(acceptedMatches.leadTime),
  loadingTime: acceptedMatches.loadingTime,
  timeDownload: acceptedMatches.timeDownload,
  helper: acceptedMatches.helper,
  paletizado: acceptedMatches.paletizado,

  originType: acceptedMatches.idClientOrigin ? 'client' : 'plant',
  destinationType: acceptedMatches.idClientDestination ? 'client' : 'plant',
  idOrigin: acceptedMatches.idClientOrigin ?? acceptedMatches.idLocalityOrigin,
  idDestination: acceptedMatches.idClientDestination ?? acceptedMatches.idLocalityDestination,
  companyId: acceptedMatches.companyId,
  buyRoute: acceptedMatches.buyRoute === false ? false : true,
  sellRoute: acceptedMatches.sellRoute === false ? false : true,
  status: convertStatusEntityToDomainMapper(acceptedMatches.status),
  clientOrigin: acceptedMatches.clientOrigin,
  clientDestination: acceptedMatches.clientDestination,
  localityOrigin: acceptedMatches.localityOrigin,
  localityDestination: acceptedMatches.localityDestination,
});
