import { useCallback, useReducer } from 'react';
import { equipmentVoucherInitialState } from '../../equipment-voucher.constants';
import { IEquipmentVoucherList } from '../../types/equipment-voucher-list.types';
import { EquipmentVoucherListActionType } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.action';
import { IEquipmentVoucher } from 'src-new/pages/financial/domains/equipment-voucher.domain';
import { equipmentVoucherListReducer } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/reducers/equipment-voucher-list/equipment-voucher-list.reducer';

export const useEquipmentVoucherListContextValues = (): IEquipmentVoucherList => {
  const [state, dispatch] = useReducer(equipmentVoucherListReducer, equipmentVoucherInitialState);

  const setEquipmentVoucherListRequest = useCallback(() => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_REQUEST, payload: undefined });
  }, []);

  const setEquipmentVoucherListSuccess = useCallback((list: Array<IEquipmentVoucher>) => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SUCCESS, payload: list });
  }, []);

  const setEquipmentVoucherListError = useCallback(() => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_ERROR, payload: undefined });
  }, []);

  const setSelectedIds = useCallback((ids: Array<number>) => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SET_IDS, payload: ids });
  }, []);

  const setOpenModal = useCallback(() => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_OPEN_MODAL, payload: undefined });
  }, []);

  const setCloseModal = useCallback(() => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_CLOSE_MODAL, payload: undefined });
  }, []);

  const setIsLoadingModal = useCallback((isLoading: boolean) => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SET_LOADING_MODAL, payload: isLoading });
  }, []);

  const setEquipmentVoucherListReset = useCallback(() => {
    dispatch({ type: EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.equipmentVoucherList,
    setEquipmentVoucherListRequest,
    setEquipmentVoucherListSuccess,
    setEquipmentVoucherListError,
    setEquipmentVoucherListReset,
    selectionProps: {
      ...state.equipmentVoucherList.selectionProps,
      setSelectedIds,
    },
    deleteModalProps: {
      ...state.equipmentVoucherList.deleteModalProps,
      setOpenModal,
      setCloseModal,
      setIsLoadingModal,
    },
  };
};
