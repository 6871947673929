import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { getHomeMatchesService } from '../../services/get-home-matches/get-home-matches.service';

export const useGetHomeMatches = () => {
  const { homeMatches } = useContext(HomeContext);
  const { setHomeMatchesRequest, setHomeMatchesSuccess, setHomeMatchesError, paginationActions } = homeMatches;
  const { nextPage, setNextPage, setPageLimit } = paginationActions;

  return useCallback(async () => {
    setHomeMatchesRequest();
    try {
      const data = await getHomeMatchesService(nextPage ?? 1);

      if (data.length) {
        const nextPageNumber = (nextPage ?? 1) + 1;
        setNextPage(nextPageNumber);

        const homeMatchesValues = homeMatches.homeMatches.concat(data);

        setHomeMatchesSuccess(homeMatchesValues);
      } else {
        setHomeMatchesSuccess(homeMatches.homeMatches);
        setPageLimit();
      }
    } catch (error) {
      setHomeMatchesError();
      showMessageFormatted({
        message: 'Erro ao obter a lista de top matches para compra.',
        type: 'error',
      });
    }
  }, [
    homeMatches.homeMatches,
    nextPage,
    setHomeMatchesError,
    setHomeMatchesRequest,
    setHomeMatchesSuccess,
    setNextPage,
    setPageLimit,
  ]);
};
