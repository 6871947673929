import API from 'utils/API/API';
import { registerShippingCompanyMap } from 'pages-v2/shipping-company/mappers/register';
import { IShippingCompanyRegisterDomain } from 'src-new/pages/registrations/domains/shipping-company';

export const registerShippingCompanyService = async (
  shippingCompany: IShippingCompanyRegisterDomain,
): Promise<number> => {
  const { data } = await API.post<number>('v3/shipping-company', registerShippingCompanyMap(shippingCompany));

  return data;
};
