import { ThemeColorsV2Type } from 'styles/types/colors';
import { WeighingStatus, WeighingStatusMap } from './convert-weighing-status.types';

const defaultValue: WeighingStatus = {
  label: 'NÃO MAPEADO',
  color: ThemeColorsV2Type.DarkGray,
};

export const convertWeighingStatus = (weighingStatus: string): WeighingStatus => {
  const weighingStatusMap: WeighingStatusMap = {
    AGUARDANDO_PATIO: {
      label: 'Portaria',
      color: ThemeColorsV2Type.Red,
    },
    AGUARDANDO_CHECKING: {
      label: 'Aguardando Check-in',
      color: ThemeColorsV2Type.Aqua,
    },    
    PESAGEM_ENTRADA_REALIZADA: {
      label: 'Entrada Realizada',
      color: ThemeColorsV2Type.Green,
    },   
    PESAGEM_SAIDA_REALIZADA: {
      label: 'Saída Realizada',
      color: ThemeColorsV2Type.Blue25,

    },   
    VAPORIZACAO: {
      label: 'Vaporização',
      color: ThemeColorsV2Type.Aqua50,
    },           
  };

  return weighingStatusMap[weighingStatus] || defaultValue;
};
