import { ICompanyEntity } from '../entities/company.entity';
import { convertStatusDomainToEntityMapper } from '../../mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import API from 'utils/API/API';
import { convertCompanyEntityToDomain } from 'pages/company/mappers/convert-company-entity-to-domain/convert-company-entity-to-domain.mapper';
import { IListPage } from 'domain/page-info';
import { ICompany, ICompanyStatus } from 'domain/company';

interface IListCompaniesParameter {
  currentPage: number;
  search: string;
  status: ICompanyStatus | null;
  order: string;
  column: string;
  typeCompany?: string;
}

const LIMIT_PAGE = 50;

export const listCompaniesService = async ({
  currentPage,
  search,
  status,
  order,
  column,
  typeCompany = '',
}: IListCompaniesParameter): Promise<IListPage<ICompany>> => {
  const convertedStatus = convertStatusDomainToEntityMapper(status);
  try {
    let url = `v2/companies?search=${search}&status=${convertedStatus}&page=${currentPage}&limit=${LIMIT_PAGE}&order=${order}&column=${column}&typeCompany=${typeCompany}`;
    if (search === '') {
      url = url.replace('search=&', '');
    }
    if (status === null) {
      url = url.replace('status=null&', '');
    }
    const { data } = await API.get(url);

    return {
      list: data.items.map((company: ICompanyEntity) => convertCompanyEntityToDomain(company)),
      pageInfo: data.meta,
    };
  } catch (error) {
    throw new Error('Ocorreu na API');
  }
};
