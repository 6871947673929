import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';
import { Button } from 'logshare-ui-kit';
import { RefreshCcw } from 'lucide-react';
import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IUpdateTransactionPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-transaction/update-transaction.types';
import { normalizeDecimal } from 'src-new/utils/normalize-currency.util';
import * as S from './schedule-finance-calculate-modal-form-view.styled';

interface IScheduleFinanceCalculateModalFormViewProps {
  isAdmin: boolean;
  isMaster: boolean;
  values: IUpdateTransactionPayload;
  handleTotalValue: (
    name: keyof IUpdateTransactionPayload,
    value: string,
    increase: boolean,
    increaseValueIn: 'totalFreight' | 'totalService',
  ) => void;
  handleFeeLogshare: () => void;
  handleGetTotalGrisAdvalorem: () => void;
  handleGetTotalFreetime: () => void;
  handleGetICMS: () => void;
  handleOnSubmit: () => void;
  handleCloseModalFinanceCalculate: () => void;
}

const INPUT_CURRENCY_CONFIG = { locale: 'pt-BR', currency: 'BRL' };

export const ScheduleFinanceCalculateModalFormView: FC<IScheduleFinanceCalculateModalFormViewProps> = ({
  isAdmin,
  isMaster,
  values,
  handleTotalValue,
  handleFeeLogshare,
  handleGetTotalGrisAdvalorem,
  handleGetTotalFreetime,
  handleGetICMS,
  handleOnSubmit,
  handleCloseModalFinanceCalculate,
}) => {
  return (
    <ModalBark
      size={'sm'}
      title={
        <S.ContainerModalTitle>
          <S.ModalTitle>Atualizar</S.ModalTitle>
          <S.ModalTitleBold>Valores do Frete</S.ModalTitleBold>
        </S.ContainerModalTitle>
      }
      handleClose={handleCloseModalFinanceCalculate}
    >
      <S.ModalWrapper>
        <S.FormWrapper>
          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <InputCurrency
              label="Frete Valor"
              id="valueFreight"
              name="valueFreight"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              onValueChange={(value) => handleTotalValue('valueFreight', value ?? '0', true, 'totalFreight')}
              intlConfig={INPUT_CURRENCY_CONFIG}
              value={normalizeDecimal(values.valueFreight)}
            />
            <InputCurrency
              label="Ajudante"
              id="helperTotal"
              name="helperTotal"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              onValueChange={(value) => handleTotalValue('helperTotal', value ?? '0', true, 'totalFreight')}
              value={normalizeDecimal(values.helperTotal)}
            />
            <InputCurrency
              label="Pedágio"
              id="toll"
              name="toll"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              onValueChange={(value) => handleTotalValue('toll', value ?? '0', true, 'totalFreight')}
              value={normalizeDecimal(values.toll)}
            />
            <InputCurrency
              label="Descarga"
              id="discharge"
              name="discharge"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              value={normalizeDecimal(values.discharge)}
              intlConfig={INPUT_CURRENCY_CONFIG}
              onValueChange={(value) => handleTotalValue('discharge', value ?? '0', true, 'totalFreight')}
            />
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <InputCurrency
              label="Desconto"
              id="penaltyBonus"
              name="penaltyBonus"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              onValueChange={(value) => handleTotalValue('penaltyBonus', value ?? '0', false, 'totalFreight')}
              value={normalizeDecimal(values.penaltyBonus)}
            />
            <LineSectionLayout columns="1fr 0fr" alignItems="end">
              <InputCurrency
                label="Taxa Logshare"
                id="totalFee"
                name="totalFee"
                prefix={'R$ '}
                defaultValue={0}
                decimalsLimit={2}
                decimalSeparator=","
                step={1}
                groupSeparator="."
                intlConfig={INPUT_CURRENCY_CONFIG}
                onValueChange={(value) => handleTotalValue('totalFee', value ?? '0', false, 'totalFreight')}
                value={normalizeDecimal(values.totalFee)}
                disabled={!isAdmin}
              />
              <Button
                label=""
                color="aqua"
                onPress={handleFeeLogshare}
                LeftIcon={<RefreshCcw size={20} color="#FFF" strokeWidth={2.75} />}
                size="x-sm"
                variant="solid"
              />
            </LineSectionLayout>

            <LineSectionLayout columns="1.5fr 0fr" alignItems="end">
              <InputCurrency
                label="Gris/Advalorem"
                id="totalGrisAdvalorem"
                name="totalGrisAdvalorem"
                prefix={'R$ '}
                defaultValue={0}
                decimalsLimit={2}
                decimalSeparator=","
                step={1}
                groupSeparator="."
                intlConfig={INPUT_CURRENCY_CONFIG}
                onValueChange={(value) => handleTotalValue('totalGrisAdvalorem', value ?? '0', true, 'totalFreight')}
                value={normalizeDecimal(values.totalGrisAdvalorem)}
              />
              <Button
                label=""
                color="aqua"
                onPress={handleGetTotalGrisAdvalorem}
                LeftIcon={<RefreshCcw size={20} color="#FFF" strokeWidth={2.75} />}
                size="x-sm"
                variant="solid"
              />
            </LineSectionLayout>

            <LineSectionLayout columns="1fr 0fr" alignItems="end">
              <InputCurrency
                label="Tempo Excedente"
                id="surplusTime"
                name="surplusTime"
                prefix={'R$ '}
                defaultValue={0}
                decimalsLimit={2}
                decimalSeparator=","
                step={1}
                groupSeparator="."
                intlConfig={INPUT_CURRENCY_CONFIG}
                onValueChange={(value) => handleTotalValue('surplusTime', value ?? '0', true, 'totalFreight')}
                value={normalizeDecimal(values.surplusTime)}
                disabled={!isMaster}
              />
              <Button
                label=""
                color="aqua"
                onPress={handleGetTotalFreetime}
                LeftIcon={<RefreshCcw size={20} color="#FFF" strokeWidth={2.75} />}
                size="x-sm"
                variant="solid"
                disabled={!isMaster}
              />
            </LineSectionLayout>
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <InputCurrency
              label="Frete Total"
              id="totalFreight"
              name="totalFreight"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              value={values?.totalFreight}
              disabled
            />
            <LineSectionLayout columns="1fr 0fr" alignItems="end">
              <InputCurrency
                label="ICMS"
                id="icms"
                name="icms"
                prefix={'R$ '}
                defaultValue={0}
                decimalsLimit={2}
                decimalSeparator=","
                step={1}
                groupSeparator="."
                intlConfig={INPUT_CURRENCY_CONFIG}
                onValueChange={(value) => handleTotalValue('icms', value ?? '0', true, 'totalService')}
                value={normalizeDecimal(values?.icms)}
                disabled={!isAdmin}
              />
              <Button
                label=""
                color="aqua"
                onPress={handleGetICMS}
                LeftIcon={<RefreshCcw size={20} color="#FFF" strokeWidth={2.75} />}
                size="x-sm"
                variant="solid"
                disabled={!isAdmin}
              />
            </LineSectionLayout>

            <InputCurrency
              label="Outros Impostos"
              id="otherTaxes"
              name="otherTaxes"
              type="number"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              onValueChange={(value) => handleTotalValue('otherTaxes', value ?? '0', true, 'totalService')}
              value={normalizeDecimal(values?.otherTaxes ?? 0)}
            />
            <InputCurrency
              label="Total do Serviço"
              id="totalService"
              name="totalService"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={INPUT_CURRENCY_CONFIG}
              value={normalizeDecimal(values?.totalService)}
              disabled
            />
          </LineSectionLayout>
        </S.FormWrapper>

        <S.WrapperButtonSubmit>
          <Button label="Atualizar" color="blue" onPress={handleOnSubmit} size="medium" variant="solid" />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
