import React, { ReactElement, useMemo } from 'react';
import * as S from './filter-form-view.styled';

import Button from 'components/button/button';
import { useFormikContext } from 'formik';
import Select from 'components/select/select';
import { optionsMatchType, vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import { IMatchesListFilters } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';
import AsyncSelectField from 'components/async-select/async-select';
import { SelectItem } from 'domain/select-item';
import InputField from 'components/input-field/input-field';
import { uf } from 'domain/states';

interface IFilterFormViewProps {
  apiCallCompanyBuyer: (searchValue: string) => Promise<SelectItem[]>;
}

export const FilterFormView: React.FC<IFilterFormViewProps> = ({ apiCallCompanyBuyer }) => {
  const { values, setFieldValue, handleChange, submitForm } = useFormikContext<IMatchesListFilters>();

  const renderCompanyBuyer = useMemo((): ReactElement | undefined => {
    return (
      <S.ContainerRow>
        <S.SecondTitle>Comprador</S.SecondTitle>
        <S.LineSection columns="1fr">
          <AsyncSelectField
            placeholder="Informe o Comprador"
            name="companyBuyer"
            id="companyBuyer"
            isClearable={true}
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              setFieldValue('companyBuyer', e && e.label);
            }}
            apiCallFunction={apiCallCompanyBuyer}
            loadOptions={apiCallCompanyBuyer}
          />
        </S.LineSection>
      </S.ContainerRow>
    );
  }, [apiCallCompanyBuyer, setFieldValue]);

  return (
    <S.Wrapper>
      {renderCompanyBuyer}
      <S.ContainerRow>
        <S.SecondTitle>Tipo de Match</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Informe o tipo de match"
            id="offerType"
            name="offerType"
            value={values.offerType}
            setFieldValue={setFieldValue}
            options={optionsMatchType}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>Cidade Origem </S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="originCity"
            name="originCity"
            type="text"
            value={values.originCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>Cidade Destino </S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            value={values.destinationCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>UF de Origem</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Origem"
            id="originUf"
            name="originUf"
            value={values.originUf}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={uf}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>UF de Destino</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Origem"
            id="destinationUf"
            name="destinationUf"
            value={values.destinationUf}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={uf}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>Tipo de Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Selecione o Tipo"
            id="vehicleType"
            name="vehicleType"
            value={values.vehicleType}
            setFieldValue={setFieldValue}
            options={vehicleTypeOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerRow>
        <S.SecondTitle>Categoria do Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Selecione a Categoria"
            id="vehicleCategory"
            name="vehicleCategory"
            value={values.vehicleCategory}
            setFieldValue={setFieldValue}
            options={vehicleCategoryOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>
      <S.ContainerButton>
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
      </S.ContainerButton>
    </S.Wrapper>
  );
};
