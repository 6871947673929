import { UploadFileResponse } from 'src-new/pages/registrations/services/get-upload-files/get-upload-files.types';
import { UploadFileDomain } from 'src-new/pages/registrations/domains/upload-file.domain';

export const convertUploadFileResponseToDomain = (response: UploadFileResponse): UploadFileDomain => {
  return {
    id: response.id,
    name: response.name,
    url: response.url,
    typeFile: response.typeFile,
    expirationTime: response.expirationTime,
  };
};
