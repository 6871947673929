import { IWeighingFormRegisterDomain, IWeighingLocationsRegisterDomain } from './weighing-register.domain';

const weighingLocationsDetailsInitialValuesDomain: IWeighingLocationsRegisterDomain = {
  id: 0,
  type: '',
  name: '',
  cnpj: '',
  address: {
    city: '',
    uf: '',
    complement: '',
    neighborhood: '',
    numberHouse: '',
    street: '',
    cep: '',
    lat: 0,
    lng: 0,
  },
};

export const weighingFormRegisterInitialValuesDomain: IWeighingFormRegisterDomain = {
  documentNumber: '',
  entryDate: '',
  entryWeight: 0,
  exitDate: '',
  exitWeight: 0,
  netWeight: 0,
  originWeight: 0,
  nfWeight: 0,
  operation: '',
  origin: weighingLocationsDetailsInitialValuesDomain,
  destination: weighingLocationsDetailsInitialValuesDomain,
  shippingCompany: { id: 0, name: '' },
  itemId: 0,
  driverId: 0,
  vehicleId: 0,
  scheduleId: 0,
  smpValidation: '',
  po: '',
  scheduledDateTime: '',
  palletCapacity: 0,
  tonCapacity: 0,
  collectionOrder: '',
  observation: '',
  ticketStatus: ''
};
