import styled from 'styled-components';

export const ContainerPaginationStyled = styled.div`
  position: relative;
  bottom: -20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ContainerTableStyled = styled.div``;
