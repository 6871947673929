/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import {
  AccordionBody,
  AccordionBodyStyled,
  AccordionContainerContentStyled,
  AccordionContainerLabelStyled,
  AccordionContainerStyled,
  WrapperStyled,
} from './accordion.styled';

export type BodyProps = {
  data: Array<{
    content: React.ReactNode;
    label: string;
    hidden?: boolean;
    formikErrorKey?: string;
  }>;
  refs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
  currentAccordion: number;
  setCurrentAccordion: React.Dispatch<React.SetStateAction<number>>;
  bodyHeight: number;
  setBodyHeight: React.Dispatch<React.SetStateAction<number>>;
  renderRightIcon?: IRenderRighticon[];
};

export interface IRenderRighticon {
  icon: React.ReactNode;
  sectionIndex: number | undefined;
}

const AccordionView: React.FC<BodyProps> = ({
  data,
  refs,
  currentAccordion,
  setCurrentAccordion,
  bodyHeight,
  setBodyHeight,
  renderRightIcon,
}) => {
  const formikHelper = useFormikContext();

  const formikHasError = useCallback(
    (formikErrorKey?: string) => {
      if (formikErrorKey && formikHelper) {
        const { errors } = formikHelper;

        const errorObject = (errors as any)[formikErrorKey];

        if (errorObject) {
          const hasError = Object.keys(errorObject).length > 0;

          if (hasError) return <div style={{ color: '#12264e' }}>✕</div>;
        }
        return <div style={{ color: 'lightgreen' }}>✓</div>;
      }

      return '';
    },
    [formikHelper.errors],
  );

  return (
    <WrapperStyled>
      <AccordionBodyStyled>
        {data &&
          data.map((item, index) => (
            <AccordionContainerStyled key={`accordion-item-${index}`} hidden={item.hidden}>
              <AccordionContainerLabelStyled
                active={currentAccordion === index}
                onClick={() => {
                  setCurrentAccordion(index);
                  setBodyHeight(Number(refs.current[index].current?.clientHeight));
                }}
              >
                <div style={{ display: 'flex', gap: 5 }}>
                  {item.label} {formikHasError(item.formikErrorKey)}
                </div>
                <div style={{ color: 'rgb(255 244 0)', fontStyle: 'italic' }}>
                  {currentAccordion === index &&
                    renderRightIcon &&
                    renderRightIcon.find((render) => render.sectionIndex === index)?.icon}
                </div>
              </AccordionContainerLabelStyled>
              <AccordionBody active={currentAccordion === index} bodyHeight={bodyHeight}>
                <AccordionContainerContentStyled ref={refs.current[index]}>
                  {item.content}
                </AccordionContainerContentStyled>
              </AccordionBody>
            </AccordionContainerStyled>
          ))}
      </AccordionBodyStyled>
    </WrapperStyled>
  );
};

export default AccordionView;
