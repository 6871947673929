import { ColorsV2Type } from 'styles/types/colors';

type FinancialStatus = {
  label: string;
  color: ColorsV2Type;
};

type FinancialStatusMap = {
  [key: string]: FinancialStatus;
};

const defaultValue: FinancialStatus = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertFinancialStatus = (status: string): FinancialStatus => {
  const financialStatusMap: FinancialStatusMap = {
    'Em Auditoria': {
      label: 'EM AUDITORIA',
      color: ColorsV2Type.Red,
    },
    Liberado: {
      label: 'LIBERADO',
      color: ColorsV2Type.Green,
    },
    'A Vencer': {
      label: 'A FATURAR',
      color: ColorsV2Type.Blue,
    },
    Faturado: {
      label: 'Faturado',
      color: ColorsV2Type.Aqua50,
    },
    'Aguardando Canhoto': {
      label: 'AGUARDANDO CANHOTO',
      color: ColorsV2Type.Blue,
    },
    'Pagamento Programado': {
      label: 'PAGAMENTO PROGRAMADO',
      color: ColorsV2Type.Blue75,
    },
    Liquidado: {
      label: 'LIQUIDADO',
      color: ColorsV2Type.Aqua50,
    },
    Pago: {
      label: 'Faturado',
      color: ColorsV2Type.Aqua50,
    },
  };

  return financialStatusMap[status] || defaultValue;
};

export const convertTransferFinancialStatus = (status: string): FinancialStatus => {
  const financialStatusMap: FinancialStatusMap = {
    'Em Auditoria': {
      label: 'EM AUDITORIA',
      color: ColorsV2Type.Red,
    },
    Liberado: {
      label: 'LIBERADO',
      color: ColorsV2Type.Green,
    },
    'A Vencer': {
      label: 'A PAGAR',
      color: ColorsV2Type.Blue,
    },
    Faturado: {
      label: 'ATRASADO',
      color: ColorsV2Type.Aqua50,
    },
    Pago: {
      label: 'PAGO',
      color: ColorsV2Type.Aqua50,
    },
  };

  return financialStatusMap[status] || defaultValue;
};
