import styled from 'styled-components';

interface DoubleMapProps {
  doubleMap?: boolean;
}

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #05050581;
  color: #000;
`;

export const TruckInfoWrapper = styled.div<DoubleMapProps>`
  position: absolute;
  bottom: ${({ doubleMap }) => (doubleMap ? '100px' : '20px')};
  left: 10px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FocusBtnWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 5px 5px 15px #7b7b7b;
`;

export const FocusButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CoordinatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 5px;
  gap: 5px;
`;

interface ToolTipProps {
  online?: boolean;
}

export const CoordinatesToolTip = styled.div<ToolTipProps>`
  background-color: ${({ online }) => (online ? '#04d361' : '#eb2521')};
  padding: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 15px #7b7b7b;
`;

export const ToolTipText = styled.p`
  color: #fff;
  font-size: 11px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const ButtonText = styled.span`
  margin-left: 10px;
  font-size: 13px;
  margin: 0 5px;
`;

export const ModalButton = styled.button`
  padding: 12px;
  border-radius: 5px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 11px;
  background-color: #12264e;
  border: none;
  cursor: pointer;
  color: white;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 99;
  min-height: 40px;
`;

export const InfoWrapper = styled.div`
  padding: 5px;
`;

export const InfoText = styled.p`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
`;

export const InfoSpan = styled(InfoText)`
  margin-left: 2px;
  font-weight: bold;
`;
