import React, { useMemo } from 'react';
import { Chatbox } from '@talkjs/react';
import { ConversationBuilder, SendMessageEvent, Session } from 'talkjs/types/talk.types';
import { ChatLoadingContainerStyled, chatStyled, ChatWrapper, Spinner } from './chat-view.styled';
import { ChatModalSkeleton } from './components/chat-modal-skeleton/chat-modal-skeleton.component';

interface IChatViewProps {
  syncConversation: (session: Session) => ConversationBuilder;
  handleConversationEmailUpdate: (event: SendMessageEvent) => void;
  isLoading: boolean;
}

export const ChatView: React.FC<IChatViewProps> = ({ syncConversation, handleConversationEmailUpdate, isLoading }) => {
  const renderButtonLoading = useMemo(() => {
    if (isLoading) {
      return (
        <ChatLoadingContainerStyled>
          <Spinner />
        </ChatLoadingContainerStyled>
      );
    }
  }, [isLoading]);

  return (
    <ChatWrapper>
      <Chatbox
        syncConversation={syncConversation}
        style={chatStyled}
        loadingComponent={<ChatModalSkeleton />}
        onSendMessage={(event: SendMessageEvent) => handleConversationEmailUpdate(event)}
      />
      {renderButtonLoading}
    </ChatWrapper>
  );
};
