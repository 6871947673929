import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { updateScheduleCustomFieldsService } from '../../services/update-schedule-custom-fields/update-schedule-custom-fields.service';
import { IScheduleCustomFields } from '../../domains/schedule-details.domain';

export const useUpdateScheduleCustomFields = () => {
  const { scheduleRequestStatus } = useContext(ScheduleContext);

  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);

  return useCallback(
    async (payloadDomain: Array<IScheduleCustomFields>) => {
      try {
        scheduleRequestStatus.setScheduleRequestStatus();

        await updateScheduleCustomFieldsService(Number(scheduleId), payloadDomain);

        showMessageFormatted({
          message: 'Dados atualizados com sucesso.',
          type: 'success',
        });

        scheduleRequestStatus.setScheduleRequestStatusSuccess();
        scheduleRequestStatus.setScheduleRequestStatusReset();
      } catch (error: any) {
        scheduleRequestStatus.setScheduleRequestStatusError();
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [scheduleId, scheduleRequestStatus],
  );
};
