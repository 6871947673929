import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { Formik } from 'formik';
import { ModalMatchDetails } from '../../types';
import { TransportRouterDetails } from './transport-route-details';
import * as S from './styled';
import SecurityRequirementsForm from './security-requirements';
import CargoDetailsForm from './schedule-cargo';
import { OriginAndDestinyMatchForm } from './origin-and-destiny';
import DetailsForm from './dates-and-hours';
import { handleValidateDates } from 'utils-v2/validate-date/validate-modal-dates';
import { DatesMatchInput, MatchRegisterInput } from 'domain-v2/match/register';
import { IUser } from 'domain/login';
import { IdlenessValues } from 'domain/idleness';
import { ICompany } from 'domain/company';
import { Paper } from 'components-v2/layout/paper';
import { Loading } from 'src-new/components/loading/loading.component';

interface MatchFormProps {
  transportRouter: ModalMatchDetails;
  idleness?: IdlenessValues;
  matchInitialValues: MatchRegisterInput;
  companyLogo?: string;
  handleSubmit: (values: MatchRegisterInput) => void;
  setRequirements: Dispatch<SetStateAction<string[] | undefined>>;
  company?: ICompany;
  userCompany?: ICompany;
  user?: IUser;
  isLoading: boolean;
}

export const MatchForm: FC<MatchFormProps> = ({
  transportRouter,
  idleness,
  matchInitialValues,
  companyLogo,
  handleSubmit,
  setRequirements,
  company,
  userCompany,
  user,
  isLoading,
}) => {
  const [datesError, setDatesError] = useState<DatesMatchInput[]>();
  const [valueError, setValueError] = useState<boolean>(false);

  return (
    <Formik
      initialValues={matchInitialValues}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => {
        const handleValidation = () => {
          const validation = handleValidateDates(values.dates);

          if (validation || values.cost.freightValue === 0 || !values.origin || !values.destination) {
            if (validation) {
              setDatesError(validation.validationDates);
            }

            setValueError(true);
          } else {
            handleSubmit();
          }
        };

        if (!transportRouter.origin.name) return <Loading />;

        return (
          <S.WrapperModalMatchForm>
            <S.WrapperForm>
              <S.SectionForm>
                <S.TitleForm>ORIGEM E DESTINO</S.TitleForm>

                <S.LocalityContainer>
                  <OriginAndDestinyMatchForm type="origin" valueError={valueError} />
                  <OriginAndDestinyMatchForm type="destination" valueError={valueError} />
                </S.LocalityContainer>
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>DETALHES DA CARGA</S.TitleForm>

                <CargoDetailsForm transportRouter={transportRouter} />
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>DETALHES DO AGENDAMENTO</S.TitleForm>

                <DetailsForm datesError={datesError} setDatesError={setDatesError} />
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>REQUISITOS ADICIONAIS</S.TitleForm>

                <SecurityRequirementsForm setRequirements={setRequirements} />
              </S.SectionForm>
            </S.WrapperForm>

            <S.ProposedContainer>
              <Paper>
                <TransportRouterDetails
                  transportRouter={transportRouter}
                  hasIdlenessValue={!!idleness}
                  company={company}
                  userCompany={userCompany}
                  user={user}
                  companyLogo={companyLogo}
                  valueError={valueError}
                />
              </Paper>

              <S.WrapperButton>
                <Button
                  label="Agendar"
                  color="blue"
                  onPress={handleValidation}
                  size="md"
                  variant="solid"
                  isLoading={isLoading}
                />
              </S.WrapperButton>
            </S.ProposedContainer>
          </S.WrapperModalMatchForm>
        );
      }}
    </Formik>
  );
};
