import React, { useMemo } from 'react';
import {
  ContainerEmptyStateTableStyled,
  ContainerStyled,
  SubTextContainerStyled,
  TextContainerStyled,
} from './empty-state-view.styled';
import SearchEmptyListIcon from 'assets/icons/search-empty-list.icon';

interface IEmptyStateViewProps {
  title?: string;
  subTitle?: string;
  height?: string;
  hideIcon?: boolean;
}

export const EmptyStateView: React.FC<IEmptyStateViewProps> = ({ title, subTitle, height, hideIcon }) => {
  const renderTitle = useMemo(() => {
    if (!title && hideIcon) {
      return '';
    }

    return title ? title : 'Lista Vazia';
  }, [hideIcon, title]);

  const renderSubTitle = useMemo(() => {
    return subTitle ? subTitle : 'Nenhum registro localizado.';
  }, [subTitle]);

  const renderIcon = useMemo(() => {
    if (!hideIcon) {
      return <SearchEmptyListIcon />;
    }
  }, [hideIcon]);

  return (
    <ContainerStyled height={height}>
      <ContainerEmptyStateTableStyled height={height}>
        {renderIcon}
        <TextContainerStyled>{renderTitle}</TextContainerStyled>
        <SubTextContainerStyled>{renderSubTitle}</SubTextContainerStyled>
      </ContainerEmptyStateTableStyled>
    </ContainerStyled>
  );
};
