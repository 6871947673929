import { ITableFreightContext } from './table-freight.types';

export const initialState: ITableFreightContext = {
  tableFreightIsLoading: false,
  tableFreight: [],
  tableFreightError: undefined,
  tableFreightPageInfo: undefined,
  setTableFreightRequest: () => undefined,
  setTableFreightSuccess: () => undefined,
  setTableFreightError: () => undefined,
};

export enum ITableFreightAction {
  TABLE_FREIGHT_REQUEST = 'TABLE_FREIGHT_REQUEST',
  TABLE_FREIGHT_SUCCESS = 'TABLE_FREIGHT_SUCCESS',
  TABLE_FREIGHT_ERROR = 'TABLE_FREIGHT_ERROR',
}
