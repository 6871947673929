import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { ScheduleDocumentsActionType } from '../../schedule.action';
import {
  ICTeData,
  IChecklistDocuments,
  IManagementData,
  IOthersDocuments,
  IScheduleDocuments,
  ScheduleDocumentsModals,
} from '../../types/schedule-documents';
import { scheduleDocumentsReducer } from '../../reducers/schedule-documents/schedule-documents.reducer';
import { ChecklistAnswer, ChecklistQuestions } from 'domain-v2/checklist';

export const useScheduleDocumentsContextValues = (): IScheduleDocuments => {
  const [state, dispatch] = useReducer(scheduleDocumentsReducer, scheduleInitialState);

  const setNFeDataRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_REQUEST, payload: undefined });
  }, []);

  const setNFeDataSuccess = useCallback((nfe: Array<IManagementData>) => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_SUCCESS, payload: nfe });
  }, []);

  const setNFeDataError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_ERROR, payload: undefined });
  }, []);

  const setCTeDataRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_REQUEST, payload: undefined });
  }, []);

  const setCTeDataSuccess = useCallback((cte: Array<ICTeData>) => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_SUCCESS, payload: cte });
  }, []);

  const setCTeDataError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_ERROR, payload: undefined });
  }, []);

  const setMDFeDataRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_REQUEST, payload: undefined });
  }, []);

  const setMDFeDataSuccess = useCallback((mdfe: Array<IManagementData>) => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_SUCCESS, payload: mdfe });
  }, []);

  const setMDFeDataError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_ERROR, payload: undefined });
  }, []);

  const setOthersDocumentsRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_REQUEST, payload: undefined });
  }, []);

  const setOthersDocumentsSuccess = useCallback((othersDocuments: Array<IOthersDocuments>) => {
    dispatch({
      type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_SUCCESS,
      payload: othersDocuments,
    });
  }, []);

  const setOthersDocumentsError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_ERROR, payload: undefined });
  }, []);

  const setChecklistQuestionsError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_ERROR, payload: undefined });
  }, []);

  const setChecklistQuestionsRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_REQUEST, payload: undefined });
  }, []);

  const setChecklistQuestionsSuccess = useCallback((checklistQuestions: ChecklistQuestions) => {
    dispatch({
      type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_SUCCESS,
      payload: checklistQuestions,
    });
  }, []);

  const setChecklistAnswerError = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_ERROR, payload: undefined });
  }, []);

  const setChecklistAnswerRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_REQUEST, payload: undefined });
  }, []);

  const setChecklistAnswerSuccess = useCallback((checklistAnswer: ChecklistAnswer) => {
    dispatch({
      type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_SUCCESS,
      payload: checklistAnswer,
    });
  }, []);

  const setChecklistDocumentsRequest = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_REQUEST, payload: undefined });
  }, []);

  const setChecklistDocumentsSuccess = useCallback((checklistDocuments: IChecklistDocuments[]) => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_SUCCESS, payload: checklistDocuments });
  }, []);

  const setChecklistDocumentsError = useCallback(() => {
    dispatch({
      type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ERROR,
      payload: undefined,
    });
  }, []);

  const setModalOpen = useCallback((modalOptions: ScheduleDocumentsModals) => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MODALS, payload: modalOptions });
  }, []);

  const setScheduleDocumentsReset = useCallback(() => {
    dispatch({ type: ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleDocuments,
    nfeDataProps: {
      ...state.scheduleDocuments.nfeDataProps,
      setNFeDataRequest,
      setNFeDataSuccess,
      setNFeDataError,
    },
    cteDataProps: {
      ...state.scheduleDocuments.cteDataProps,
      setCTeDataRequest,
      setCTeDataSuccess,
      setCTeDataError,
    },
    mdfeDataProps: {
      ...state.scheduleDocuments.mdfeDataProps,
      setMDFeDataRequest,
      setMDFeDataSuccess,
      setMDFeDataError,
    },
    othersDocumentsProps: {
      ...state.scheduleDocuments.othersDocumentsProps,
      setOthersDocumentsRequest,
      setOthersDocumentsSuccess,
      setOthersDocumentsError,
    },
    checklistQuestionsProps: {
      ...state.scheduleDocuments.checklistQuestionsProps,
      setChecklistQuestionsError,
      setChecklistQuestionsRequest,
      setChecklistQuestionsSuccess,
    },
    checklistAnswerProps: {
      ...state.scheduleDocuments.checklistAnswerProps,
      setChecklistAnswerError,
      setChecklistAnswerRequest,
      setChecklistAnswerSuccess,
    },
    checklistDocumentsProps: {
      ...state.scheduleDocuments.checklistDocumentsProps,
      setChecklistDocumentsError,
      setChecklistDocumentsRequest,
      setChecklistDocumentsSuccess,
    },
    modalsProps: {
      ...state.scheduleDocuments.modalsProps,
      setModalOpen,
    },
    setScheduleDocumentsReset,
  };
};
