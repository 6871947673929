import {
  canceledStatus,
  finishedStatus,
  onGoingStatus,
  programmingStatus,
  prospectedStatus,
  startingStatus,
  status,
} from './status-code';

import { IActiveTabName } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { IOption } from 'domain/global-inputs';
import { ITabContent } from 'components/tabs/tabs-view';

export enum TabNames {
  'Em_Alocacao' = 'Em Alocação',
  'A_Iniciar' = 'A Iniciar',
  'Em_Andamento' = 'Em Andamento',
  'Finalizados' = 'Finalizados',
  'Prospectados' = 'Prospectados',
  'Todos' = 'Todos',
  'Cancelados' = 'Cancelados',
}

const tabsName = Object.values(TabNames);

export const allocationTabActive = (activeTabName: string) => {
  return activeTabName.includes(TabNames.Em_Alocacao) || activeTabName.includes(TabNames.Prospectados);
};

export const tabStatusCode = (activeTabName: string) => {
  const tabNameStatusCode: { [key: string]: string[] } = {
    [TabNames.Em_Alocacao]: programmingStatus,
    [TabNames.A_Iniciar]: startingStatus,
    [TabNames.Em_Andamento]: onGoingStatus,
    [TabNames.Finalizados]: finishedStatus,
    [TabNames.Prospectados]: prospectedStatus,
    [TabNames.Todos]: status,
    [TabNames.Cancelados]: canceledStatus,
  };

  return tabNameStatusCode[activeTabName] ?? startingStatus;
};

export const tabContentName = (content: JSX.Element, isAdmin: boolean) => {
  const tabContent: Array<ITabContent> = tabsName
    .filter((name) => name !== 'Prospectados' || (name === 'Prospectados' && isAdmin))
    .map((name: string) => ({ name, content }));

  return tabContent;
};

export const convertStatusCodeOptions = (activeTabName: IActiveTabName): IOption[] => {
  const statusCodeFilterOptions: { [key: string]: IOption[] } = {
    [TabNames.Em_Alocacao]: [
      { value: 'SH01', label: 'EM BIDDING' },
      { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
      { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
      { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
    ],

    [TabNames.A_Iniciar]: [
      { value: 'Al20', label: 'ALOCADO' },
      { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
      { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
    ],
    [TabNames.Em_Andamento]: [
      { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
      { value: 'IN30', label: 'AGUARDANDO CARREGAMENTO' },
      { value: 'IN40', label: 'EM CARREGAMENTO' },
      { value: 'IN50', label: 'COLETA REALIZADA' },
      { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
      { value: 'IN70', label: 'EM DESCARGA' },
    ],
    [TabNames.Finalizados]: [
      { value: 'IN90', label: 'DESCARGA FINALIZADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
    ],
    [TabNames.Todos]: [
      { value: 'SH01', label: 'EM BIDDING' },
      { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
      { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
      { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
      { value: 'Al20', label: 'ALOCADO' },
      { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
      { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
      { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
      { value: 'IN30', label: 'AGUARDANDO CARREGAMENTO' },
      { value: 'IN40', label: 'EM CARREGAMENTO' },
      { value: 'IN50', label: 'COLETA REALIZADA' },
      { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
      { value: 'IN70', label: 'EM DESCARGA' },
      { value: 'IN90', label: 'DESCARGA FINALIZADA' },
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
    ],
    [TabNames.Cancelados]: [
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
    ],
  };

  return statusCodeFilterOptions[activeTabName];
};
