import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    background-color: ${theme.modalsV2.background};
    color: #000;
    z-index: 1050;
  `}
`;

export const Content = styled.div`
  min-height: 190px;
  max-height: 600px;
  max-width: 630px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 6px;
  padding: 30px;
  border-radius: 5px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.font.bold};
    margin-right: 10px;
  `}
`;

export const ContainerSubtitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgb(111, 111, 116);
  margin-right: 10px;
  margin-top: 10px;
`;

export const Subtitle = styled.p``;

export const ContainerCloseModal = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
`;

export const CloseModal = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 10px;
`;
