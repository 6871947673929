import API from 'utils/API/API';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';

export const getFreightByIdService = async (scheduleId: string): Promise<ICurrentAllocation> => {
  try {
    const { data } = await API.get(`v2/schedules/${scheduleId}`);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao buscar detalhes da agendamento');
  }
};
