import { includes } from 'lodash';
import {
  ScheduleDetailsActionType,
  ScheduleDownloadMapReportActionType,
  ScheduleExecutionActionType,
  ScheduleKanbanListActionType,
  ScheduleListActionType,
  ScheduleListInitialLoadingActionType,
  ScheduleRequestStatusActionType,
  ScheduletActionType,
} from '../schedule.action';
import { scheduleReducerResetState } from './schedule-reducer.constants';
import { IScheduleActions, IScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule.types';
import { scheduleDetailsReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-details/schedule-details.reducer';
import { IScheduleDetailsActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import { scheduleExecutionReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-execution/schedule-execution.reducer';
import { IScheduleExecutionActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';
import { scheduleKanbanListReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-kanban-list/schedule-kanban-list.reducer';
import { IScheduleKanbanListActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-kanban.types';
import { scheduleListReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-list/schedule-list.reducer';
import { IScheduleListActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { IScheduleRequestStatusActions } from '../types/schedule-request-status.types';
import { scheduleRequestStatusReducer } from './schedule-request-status/schedule-request-status.reducer';
import { scheduleListInitialLoadingReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-list-initial-loading/schedule-list-initial-loading.reducer';
import { IScheduleListInitialLoadingActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list-initial-loading.types';
import { scheduleDownloadMapReportReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-download-map-report/schedule-download-map-report.reducer';
import { IScheduleDownloadMapReportActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-download-map-report.types';

export const scheduleReducer = (state: IScheduleContext, action: IScheduleActions): IScheduleContext => {
  if (includes(Object.values(ScheduleDetailsActionType), action.type)) {
    return scheduleDetailsReducer(state, action as IScheduleDetailsActions);
  }

  if (includes(Object.values(ScheduleExecutionActionType), action.type)) {
    return scheduleExecutionReducer(state, action as IScheduleExecutionActions);
  }

  if (includes(Object.values(ScheduleKanbanListActionType), action.type)) {
    return scheduleKanbanListReducer(state, action as IScheduleKanbanListActions);
  }

  if (includes(Object.values(ScheduleListActionType), action.type)) {
    return scheduleListReducer(state, action as IScheduleListActions);
  }

  if (includes(Object.values(ScheduleRequestStatusActionType), action.type)) {
    return scheduleRequestStatusReducer(state, action as IScheduleRequestStatusActions);
  }

  if (includes(Object.values(ScheduleListInitialLoadingActionType), action.type)) {
    return scheduleListInitialLoadingReducer(state, action as IScheduleListInitialLoadingActions);
  }

  if (includes(Object.values(ScheduleDownloadMapReportActionType), action.type)) {
    return scheduleDownloadMapReportReducer(state, action as IScheduleDownloadMapReportActions);
  }

  if (action.type === ScheduletActionType.SCHEDULE_RESET) {
    return scheduleReducerResetState(state);
  }

  return state;
};
