/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

interface ITableStyledProps {
  flex?: number;
  justifyContent?: string;
  hasAction?: boolean;
  isStrong?: boolean;
  margin?: string;
  onClick?: (type: string) => void;
  style?: any;
  display?: boolean;
  zIndex?: boolean;
  cursorDefault?: boolean;
}

export const LineTableStyled = styled.tr`
  display: flex;
  padding: 11px 0px 11px 0;
  border-bottom: 1px solid #d1d1d1;
`;

export const TableRow = styled.div`
  :hover {
    background-color: #f9f9f9;
  }
`;

export const LineHeaderTableStyled = styled.tr`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 11px;
`;

export const TableStyled = styled.table`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  table-layout: fixed;
`;

export const HeaderTableStyled = styled.th<ITableStyledProps>`
  ${({ flex, justifyContent, cursorDefault }) => css`
    display: flex;
    flex: ${flex ?? 1};
    justify-content: ${justifyContent ?? 'flex-start'};
    margin-right: 10px;
    cursor: ${cursorDefault ? 'default' : 'pointer'};
    font-weight: 400;
    height: 16px;
    overflow: hidden;
  `}
`;

export const HeaderTextStyled = styled.p<ITableStyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentTableStyled = styled.td<ITableStyledProps>`
  ${({ flex, justifyContent, display, cursorDefault, margin }) => css`
    display: flex;
    background-color: ${display ? 'white' : ''};
    position: ${display ? 'absolute' : ''};
    width: ${display ? '100%' : ''};
    flex: ${flex ?? 1};
    justify-content: ${justifyContent ?? 'flex-start'};
    pointer-events: ${display ? 'none' : ''};
    cursor: ${display || cursorDefault ? 'default' : 'pointer'};
    margin-right: ${margin ?? '10px'};
  `}
  overflow: hidden;
  text-transform: uppercase;
`;

export const ContainerTextStyled = styled.div``;

export const TextStyled = styled.p<ITableStyledProps>`
  ${({ isStrong }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${isStrong ? 700 : 600};
  `}
`;

export const ObjectStyled = styled.div<ITableStyledProps>`
  ${({ style }) => css`
    ${style?.image
      ? css`
          height: 25px;
          width: 25px;
        `
      : ''}
  `}
`;

export const SubTitleStyled = styled.p<ITableStyledProps>`
  ${({ isStrong }) => css`
    color: rgb(123, 123, 123);
    font-size: ${!isStrong && '10px'};
    font-weight: ${isStrong ? 600 : 500};
    font-style: ${isStrong ? 'italic' : 'normal'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
`;

export const DisplayNoneStyled = styled.div<ITableStyledProps>`
  ${({ display }) => css`
    width: ${display ? '0px' : ''};
  `}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableHeadStyled = styled.thead<ITableStyledProps>`
  ${({ zIndex }) => css`
    background-color: white;
    position: sticky;
    top: 0;
    z-index: ${zIndex ? '0' : '1'};
    box-shadow: 0px 4px 10px -7px rgba(0, 0, 0, 0.2);
  `}
`;
export const TableBodyStyled = styled.tbody``;

export const DoubleReactValues = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 100%;
`;
