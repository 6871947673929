import { IScheduleCustomFields } from '../../../domains/schedule-details.domain';
import { IScheduleCustomFieldsResponse } from '../get-schedule-custom-fields.types';

export const converterScheduleCustomFieldsResponseToDomain = (
  scheduleCustomFieldsResponse: Array<IScheduleCustomFieldsResponse>,
): Array<IScheduleCustomFields> => {
  return scheduleCustomFieldsResponse.map((data) => ({
    id: data.id,
    isRequired: data.isRequired,
    label: data.label,
    customField: {
      id: data.id,
      companyId: data.companyId,
      isRequired: data.isRequired,
      label: data.label,
    },
    scheduleId: data.scheduleId,
    value: data.value,
  }));
};
