import {
  ISaleOfferFormUpdateResponse,
  ISaleOfferShipperTariffUpdateResponse,
  ISaleOfferShipperUpdateResponse,
  LocationTypeEntity,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/services/sale-offer-update/sale-offer-update.types';
import { CargoTypesResponse } from 'src-new/domains/cargo-type.domain';
import { ISaleOfferDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertOperationTypeToResponse } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertCurrencyBRToNumber } from 'src-new/utils/currency-br-to-number.util';

export const convertSaleOfferUpdateDomainToEntity = (domain: ISaleOfferDomain): ISaleOfferFormUpdateResponse => {
  const saleOfferDetails = domain.saleOfferDetails.filter(e => e.tariff.length > 0)
  const mappingSaleOfferDetails: Array<ISaleOfferShipperUpdateResponse> = saleOfferDetails.map((saleOfferDetail) => {
    return {
      id: saleOfferDetail.id,
      partnerShipperId: saleOfferDetail.partnerShipper.id,
      initialDate: saleOfferDetail.initialDate,
      finalDate: saleOfferDetail.finalDate,
      tariff: saleOfferDetail.tariff.map((saleOfferDetailTariff) => {
        return <ISaleOfferShipperTariffUpdateResponse>{
          id: saleOfferDetailTariff.id,
          shippingId: saleOfferDetailTariff.shipping.id || 34,
          vehicleType: convertVehicleTypeToEntity(saleOfferDetailTariff.vehicleType),
          vehicleCategory: convertVehicleCategoryToEntity(saleOfferDetailTariff.vehicleCategory),
          weight: convertCurrencyBRToNumber(saleOfferDetailTariff.weight),
          pallets: convertCurrencyBRToNumber(saleOfferDetailTariff.pallets),
          cubage: convertCurrencyBRToNumber(saleOfferDetailTariff.cubage),
          frequency: convertCurrencyBRToNumber(saleOfferDetailTariff.frequency),
          invoicedBy: saleOfferDetailTariff.invoicedBy,
          gris: convertCurrencyBRToNumber(saleOfferDetailTariff.gris),
          advalorem: convertCurrencyBRToNumber(saleOfferDetailTariff.advalorem),
          valueShipping: convertCurrencyBRToNumber(saleOfferDetailTariff.valueShipping),
          valueShipper: convertCurrencyBRToNumber(saleOfferDetailTariff.valueShipper),
          feeLogshare: convertCurrencyBRToNumber(saleOfferDetailTariff.feeLogshare) / 100,
          valueLogshare: convertCurrencyBRToNumber(saleOfferDetailTariff.valueLogshare),
          valueTotal: saleOfferDetailTariff.valueTotal,
          isActive: saleOfferDetailTariff.isActive,
        };
      }),
    };
  });

  return {
    saleOfferId: domain.id,
    origin: {
      ...domain.origin,
      type: domain.origin.type as LocationTypeEntity,
    },
    destination: {
      ...domain.destination,
      type: domain.destination.type as LocationTypeEntity,
    },
    operationType: convertOperationTypeToResponse(domain.operationType),
    prohibitedCargo: domain.prohibitedCargo,
    prohibitedOtherCargo: domain.prohibitedOtherCargo,
    cargoType: domain.cargoType.toUpperCase() as CargoTypesResponse,
    saleOfferDetails: mappingSaleOfferDetails,
  };
};
