import styled, { css } from 'styled-components';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IProps {
  isPressed: boolean;
}

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TabIcon = styled.span`
  cursor: pointer;
`;

export const TabHeader = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

interface ITabContainer extends IProps {
  hasTotalRow: boolean;
  color?: ThemeColorsV2Type;
}
export const TabContainer = styled.ul<ITabContainer>`
  ${({ theme, isPressed, hasTotalRow, color = ThemeColorsV2Type.Blue }) => css`
    min-width: 160px;
    max-width: 190px;
    display: flex;
    align-items: center;
    justify-content: ${hasTotalRow ? 'space-between' : 'center'};
    padding: 8px 16px;
    border-radius: 6px 6px 0 0;
    background-color: ${isPressed ? theme.colorsV2[color] : 'transparent'};
    cursor: pointer;

    &:hover {
      background-color: ${!isPressed && '#EEEEEE'};
    }
  `}
`;

export const TabDivider = styled.div`
  ${({ theme }) => css`
    margin: 0 3px;
    &:not(:last-child) {
      height: 16px;
      width: 1px;
      background-color: ${theme.colorsV2.forBorder};
    }
  `}
`;

export const TabLabel = styled.li<IProps>`
  ${({ theme, isPressed }) => css`
    font-size: 13px;
    font-weight: 500;
    color: ${isPressed ? theme.colorsV2.white : theme.colorsV2.darkGray};
    list-style-type: none;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const TabRowNumber = styled.li<IProps>`
  ${({ theme, isPressed }) => css`
    font-size: 12px;
    font-weight: 500;
    color: ${isPressed ? theme.colorsV2.white : theme.colorsV2.darkGray};
    list-style-type: none;
  `}
`;

export const TabContent = styled.div`
  display: inherit;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

export const Trace = styled.div`
  border-bottom: 1px solid rgb(217, 217, 217);
  width: 100%;
`;
