import { IDriversContext, IDriversReducerAction } from './drivers.types';
import { IDriversAction } from './drivers.actions';

export const driversReducer = (state: IDriversContext, action: IDriversReducerAction): IDriversContext => {
  switch (action.type) {
    case IDriversAction.DRIVERS_REQUEST: {
      return {
        ...state,
        driversIsLoading: true,
        drivers: [],
        driversError: undefined,
      };
    }
    case IDriversAction.DRIVERS_SUCCESS: {
      return {
        ...state,
        driversIsLoading: false,
        drivers: action.payload.list,
        driversPageInfo: action.payload.pageInfo,
        driversError: undefined,
      };
    }
    case IDriversAction.DRIVERS_ERROR: {
      return {
        ...state,
        driversIsLoading: false,
        drivers: [],
        driversError: action.payload,
        driversPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
