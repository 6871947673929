import React from 'react';

import AcceptedMatchesContextProvider from '../context/table-states/accepted-matches.context';

import AcceptedMatchesListBody from './accepted-matches-list-body/accepted-matches-list-body';

const AcceptedMatchesPage: React.FC = () => {
  return (
    <AcceptedMatchesContextProvider>
      <AcceptedMatchesListBody />
    </AcceptedMatchesContextProvider>
  );
};

export default AcceptedMatchesPage;
