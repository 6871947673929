import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseGetFreetime } from 'src-new/hooks/use-get-freetime/use-get-freetime.hook';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { saleOfferShipperTariffDetailsInitialValuesDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details-initial-values.domain';
import {
  ISaleOfferDomain,
  ISaleOfferShipperTariffDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { SaleOfferUpdateTariffFormModalView } from './sale-offer-update-tariff-form-modal-view.component';
import { UserContext } from 'state/user-context';
import { ICompany } from 'domain/company';
import { companyDetailsService } from 'services/company/details';
import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';
import { useUpdateSaleOffer } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/hooks/use-sale-offer-update/use-sale-offer-update.hook';
import { IOptionsDomain } from 'src-new/domains/options.domain';
import { vehicleTypeOptionsDomain } from 'src-new/domains/vehicle-type.domain';
import { vehicleCategoryOptionsDomain } from 'src-new/domains/vehicle-category.domain';

const LOGSHARE_COMPANY_ID = 2;

export const SaleOfferUpdateTariffFormModal: FC = () => {
  const { values, setFieldValue } = useFormikContext<ISaleOfferDomain>();
  const { user, isAdmin, isZMaster } = useContext(UserContext);
  const { saleOfferModals, saleOfferFreetime } = useContext(SaleOfferContext);

  const [company, setCompany] = useState<ICompany>();
  const [offerShipperTariff, setOfferShipperTariff] = useState<ISaleOfferShipperTariffDomain>(
    saleOfferShipperTariffDetailsInitialValuesDomain,
  );

  const getRetentionFineByVehicleHook = UseGetFreetime();
  const saleOfferUpdateHook = useUpdateSaleOffer();

  const indexShipperSection = useMemo(() => {
    if (saleOfferModals.indexSection.saleOfferShipper) return saleOfferModals.indexSection.saleOfferShipper;
    return 0;
  }, [saleOfferModals.indexSection]);

  const indexShipperTariffSection = useMemo(() => {
    if (saleOfferModals.indexSection.saleOfferShipperTariff !== undefined) {
      return saleOfferModals.indexSection.saleOfferShipperTariff;
    }
    return undefined;
  }, [saleOfferModals.indexSection]);

  const userShippingCompany = useMemo(() => {
    return user?.profile === 'shipping-company';
  }, [user?.profile]);

  const companyName = useMemo((): string => company?.tradeName ?? '', [company?.tradeName]);

  const partnerShipperName = useMemo((): string => {
    const { partnerShipper } = values.saleOfferDetails[indexShipperSection];

    return partnerShipper.id !== 0 ? partnerShipper.name : 'EMBARCADOR';
  }, [indexShipperSection, values.saleOfferDetails]);

  const feeLogshare = useMemo(() => Number(company?.feeLogshare) || 0, [company?.feeLogshare]);

  const vehicleOptions = useMemo((): { vehicleType: Array<IOptionsDomain>; vehicleCategory: Array<IOptionsDomain> } => {
    const combinedVehicleType = [
      ...(values.origin?.vehicleRestriction ?? []),
      ...(values.destination?.vehicleRestriction ?? []),
    ];

    const combinedVehicleCategory = [
      ...(values.origin?.vehicleCategoryType ?? []),
      ...(values.destination?.vehicleCategoryType ?? []),
    ];

    const vehicleType = vehicleTypeOptionsDomain.map((option) => ({
      ...option,
      isDisabled: combinedVehicleType.length > 0 && !combinedVehicleType.some((item) => item === option.value),
    }));

    const vehicleCategory = vehicleCategoryOptionsDomain.map((option) => {
      const isDisabled =
        combinedVehicleCategory.length > 0 && !combinedVehicleCategory.some((item) => item === option.value);

      if (option.value === 'Porta Container 20') {
        return { ...option, label: 'CONTAINER 20', isDisabled };
      }
      if (option.value === 'Porta Container 40') {
        return { ...option, label: 'CONTAINER 40', isDisabled };
      }

      return { ...option, isDisabled };
    });

    return { vehicleType, vehicleCategory };
  }, [
    values.destination?.vehicleCategoryType,
    values.destination?.vehicleRestriction,
    values.origin?.vehicleCategoryType,
    values.origin?.vehicleRestriction,
  ]);

  const handleCloseRegisterShipperTariffModal = useCallback(() => {
    saleOfferModals.setModalClose();
  }, [saleOfferModals]);

  const handleChange = useCallback(
    (name: keyof ISaleOfferShipperTariffDomain, value: any) => {
      setOfferShipperTariff((prevOfferShipper) => ({
        ...prevOfferShipper,
        [name]: value,
      }));
    },
    [setOfferShipperTariff],
  );

  const handleAddOfferShipper = useCallback(async () => {
    if (indexShipperTariffSection === undefined) {
      setFieldValue(`saleOfferDetails[${indexShipperSection}].tariff`, [
        ...values.saleOfferDetails[indexShipperSection].tariff,
        offerShipperTariff,
      ]);
    } else {
      setFieldValue(
        `saleOfferDetails[${indexShipperSection}].tariff[${indexShipperTariffSection}]`,
        offerShipperTariff,
      );

      await saleOfferUpdateHook({
        ...values,
        saleOfferDetails: values.saleOfferDetails.map((item, index) => {
          if (index === indexShipperSection) {
            return {
              ...item,
              ...{
                tariff: [...item.tariff, offerShipperTariff],
              },
            };
          }
          return item;
        }),
      });
    }

    await saleOfferUpdateHook({
      ...values,
      saleOfferDetails: values.saleOfferDetails.map((item, index) => {
        if (index === indexShipperSection) {
          return {
            ...item,
            ...{
              tariff: [...item.tariff, offerShipperTariff],
            },
          };
        }
        return item;
      }),
    });

    handleCloseRegisterShipperTariffModal();
  }, [
    indexShipperTariffSection,
    handleCloseRegisterShipperTariffModal,
    setFieldValue,
    indexShipperSection,
    values,
    offerShipperTariff,
    saleOfferUpdateHook,
  ]);

  useEffect(() => {
    if (indexShipperTariffSection !== undefined) {
      setOfferShipperTariff(values.saleOfferDetails[indexShipperSection].tariff[indexShipperTariffSection]);
    }
  }, [indexShipperSection, indexShipperTariffSection, values.saleOfferDetails]);

  useEffect(() => {
    (async () => {
      if (offerShipperTariff.vehicleType) {
        await getRetentionFineByVehicleHook(offerShipperTariff.vehicleType, LOGSHARE_COMPANY_ID);
      }
    })();
  }, [getRetentionFineByVehicleHook, offerShipperTariff.vehicleType]);

  useEffect(() => {
    const sum =
      normalizeCurrency(offerShipperTariff.valueShipper) +
      normalizeCurrency(offerShipperTariff.valueShipping) +
      normalizeCurrency(offerShipperTariff.valueLogshare);

    const total = Number.isNaN(sum) ? 0 : Number(sum.toFixed(2));
    handleChange('valueTotal', total);
  }, [
    handleChange,
    offerShipperTariff.valueLogshare,
    offerShipperTariff.valueShipper,
    offerShipperTariff.valueShipping,
  ]);

  useEffect(() => {
    if (company) {
      const calcFee =
        (normalizeCurrency(offerShipperTariff.valueShipping) + normalizeCurrency(offerShipperTariff.valueShipper)) *
        (normalizeCurrency(offerShipperTariff.feeLogshare) / 100);

      if (!isNaN(calcFee)) {
        handleChange('valueLogshare', calcFee.toFixed(2).replace('.', ','));
      } else {
        handleChange('valueLogshare', '0,00');
      }
    }

    if (offerShipperTariff.invoicedBy === 'transportadora' && company?.feeLogshareWithoutCTe !== 'null') {
      const feeLogshareFixed = company?.feeLogshareWithoutCTe;
      handleChange('valueShipper', '0');
      handleChange('valueLogshare', feeLogshareFixed?.replace('.', ','));
    }
  }, [
    company,
    handleChange,
    offerShipperTariff.feeLogshare,
    offerShipperTariff.invoicedBy,
    offerShipperTariff.valueShipper,
    offerShipperTariff.valueShipping,
  ]);

  useEffect(() => {
    (async () => {
      if (user) {
        const findCompany = await companyDetailsService(user.companyId);
        setCompany(findCompany);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (company && offerShipperTariff.invoicedBy === 'logshare') {
      handleChange('gris', company.gris);
      handleChange('advalorem', company.advalorem);
    }
    handleChange('freetime', saleOfferFreetime.saleOfferFreetime);
  }, [company, company?.gris, handleChange, offerShipperTariff.invoicedBy, saleOfferFreetime.saleOfferFreetime]);

  useEffect(() => {
    (async () => {
      if (user && userShippingCompany) {
        handleChange('invoicedBy', 'transportadora');
        handleChange('shipping', { id: user.shippingCompanyId, name: user.login });
      }
    })();
  }, [handleChange, user, userShippingCompany]);

  useEffect(() => {
    if (indexShipperTariffSection === undefined) {
      return handleChange('feeLogshare', feeLogshare);
    }
  }, [feeLogshare, handleChange, indexShipperTariffSection]);

  return (
    <SaleOfferUpdateTariffFormModalView
      values={offerShipperTariff}
      isAdmin={isAdmin}
      isZMaster={isZMaster}
      userShippingCompany={userShippingCompany}
      indexShipperSection={indexShipperSection}
      companyName={companyName}
      partnerShipperName={partnerShipperName}
      vehicleOptions={vehicleOptions}
      handleChange={handleChange}
      handleOnSubmit={handleAddOfferShipper}
      handleCloseRegisterShipperTariffModal={handleCloseRegisterShipperTariffModal}
    />
  );
};
