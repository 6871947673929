import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { Loading } from 'src-new/components/loading/loading.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { TabsContainerStyled, WrapperStateStyled } from './location-list-tabs-view.styled';
import { LocationTable } from './components/location-table/location-table.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';

export interface ILocationListTabsStatus {
  isError: boolean;
  isInitialLoading: boolean;
  isEmpty: boolean;
}

export interface ILocationListTabsViewProps {
  activeTab: string;
  statusProps: ILocationListTabsStatus;
  handleChangeTab: (tabName: string) => void;
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const LocationListTabsView: React.FC<ILocationListTabsViewProps> = ({
  activeTab,
  handleChangeTab,
  statusProps,
  handleIdsToRemove,
  handleInfinityScroll,
}) => {
  const renderContentTab = useMemo(() => {
    if (statusProps.isInitialLoading) {
      return (
        <WrapperStateStyled>
          <Loading />
        </WrapperStateStyled>
      );
    }

    if (statusProps.isError) {
      return (
        <WrapperStateStyled>
          <ErrorState />
        </WrapperStateStyled>
      );
    }

    if (statusProps.isEmpty) {
      return (
        <WrapperStateStyled>
          <EmptyState />
        </WrapperStateStyled>
      );
    }

    return <LocationTable handleIdsToRemove={handleIdsToRemove} handleInfinityScroll={handleInfinityScroll} />;
  }, [handleIdsToRemove, handleInfinityScroll, statusProps.isEmpty, statusProps.isError, statusProps.isInitialLoading]);

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Ativas',
        content: renderContentTab,
      },
      {
        name: 'Inativas',
        content: renderContentTab,
      },
    ];
  }, [renderContentTab]);

  return (
    <TabsContainerStyled>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
    </TabsContainerStyled>
  );
};
