import React from 'react';

import * as S from './styled';

interface LineConnectorProps {
  bgColor?: string;
  height?: number;
}

export const LineConnectorHome: React.FC<LineConnectorProps> = ({ height, bgColor }) => {
  return (
    <S.Wrapper>
      <S.StepIcon bgColor={bgColor} />
      <S.Connector height={height} bgColor={bgColor} />
      <S.StepIcon bgColor={bgColor} />
    </S.Wrapper>
  );
};
