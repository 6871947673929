import API from 'utils/API/API';

export const deleteUsersService = async (ids: number[]): Promise<boolean> => {
  try {
    await API.delete('v2/users', {
      data: {
        ids,
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};
