import { useContext, useMemo } from 'react';
import { PageHeaderFilterContext } from 'src-new/contexts/page-header-filter/page-header-filter.context';
import { IPageHeaderFilterContext } from 'src-new/contexts/page-header-filter/types/page-header-filter.types';

export const usePageHeaderFilterContext = <T>() => {
  const context = useContext(PageHeaderFilterContext);

  return useMemo(() => {
    if (!context) {
      throw new Error('O contexto PageHeaderFilterProvider deve ser inicializado.');
    }

    return context as IPageHeaderFilterContext<T>;
  }, [context]);
};
