import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { limitToLast, off, onChildAdded, onValue, query, ref } from 'firebase/database';
import { database } from 'App';
import { ITruckRoutes } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';
import { IMapsSnapshot } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/schedule-maps.types';
import { ITruckRoute } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/truck-route.domain';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import {
  calculateSpeed,
  filterListByFiveMinuteInterval,
  formatSnapshots,
} from '../../utils/route-tracking-functions/route-tracking-functions.util';
import { IMonitoringSnapshotMap } from './use-get-executed-route-monytor.types';
import { convertSnapshotToContent } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-executed-route-monytor/mappers/convert-snapshot-to-content/convert-snapshot-to-content.mapper';

export const useGetExecutedRouteMonytor = () => {
  const { scheduleExecution, scheduleDetails } = useContext(ScheduleContext);
  const { id, driver, vehicle } = scheduleDetails.scheduleDetails;
  const { setMonitoringTruckRoutes } = scheduleExecution.mapAccordionProps;

  return useCallback(
    (setIsLoadingMonitoring: Dispatch<SetStateAction<boolean>>) => {
      if (driver?.cpf && vehicle?.mainBoard) {
        const row = Buffer.from(`${driver.cpf}:${vehicle.mainBoard}:${id}`).toString('base64');
        const dbRef = ref(database, 'trackings/' + row);
        const lastTruckRoute = query(dbRef, limitToLast(1));
        let monitoringTruckRoutes: ITruckRoutes = {
          routes: [],
          routePositions: [],
        };

        onValue(
          dbRef,
          (data) => {
            const snapshots: IMonitoringSnapshotMap = data.val();

            if (snapshots) {
              const snapshotsKeys: Array<string> = Object.keys(snapshots);

              if (snapshotsKeys.length > 1) {
                const orderedList: Array<IMapsSnapshot> = snapshotsKeys
                  .map((timestamp) => ({
                    timestamp: Number(timestamp),
                    content: convertSnapshotToContent(snapshots[timestamp]),
                  }))
                  .sort((a, b) => a.timestamp - b.timestamp);

                monitoringTruckRoutes.routes = formatSnapshots(orderedList, true);
                monitoringTruckRoutes.routePositions = filterListByFiveMinuteInterval(orderedList, true);
                setMonitoringTruckRoutes(monitoringTruckRoutes);
              }
            }
            setIsLoadingMonitoring(false);
          },
          { onlyOnce: true },
        );

        onChildAdded(lastTruckRoute, (data) => {
          const { latitude, longitude, created_at, speed, address, ignition } = data.val();
          const location: ITruckRoute = {
            latitude: Number(latitude),
            longitude: Number(longitude),
            speed: calculateSpeed(speed),
            created_at: created_at ?? 0,
            address,
            ignition,
          };
          const routes = [...monitoringTruckRoutes.routes, location];

          monitoringTruckRoutes = {
            ...monitoringTruckRoutes,
            routes: routes,
            routePositions: routes,
          };
          setMonitoringTruckRoutes(monitoringTruckRoutes);
        });

        return () => {
          off(dbRef);
        };
      } else {
        setIsLoadingMonitoring(false);
      }
    },
    [driver, id, setMonitoringTruckRoutes, vehicle],
  );
};
