import styled, { css } from 'styled-components';

type ContainerProps = {
  overflow?: boolean;
  legacy?: boolean;
  size?: 'large' | 'medium' | 'small' | 'very-small';
};

export const WrapperStyled = styled.div<ContainerProps>`
  ${({ legacy }) => css`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 20px;
    background-color: #05050581;
    z-index: ${legacy ? '1' : '2'};
    color: #000;
  `}
`;

export const ContentStyled = styled.div<ContainerProps>`
  ${({ overflow = false, size }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    padding: 30px;

    overflow-y: ${overflow ? 'hidden' : 'auto'};
    min-height: 190px;
    max-height: 600px;

    overflow-y: auto;

    ${size === 'large' &&
    css`
      width: 1200px;
      border-radius: 5px;
    `}

    ${size === 'medium' &&
    css`
      width: 800px;
      border-radius: 5px;
    `}

    ${size === 'small' &&
    css`
      max-width: 630px;
      border-radius: 5px;
    `}
  `}
`;

export const ContainerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.font.bold};
  `}
`;

export const CloseModalStyled = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 15px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  ${() => css`
    display: flex;
    justify-content: flex-end;
    padding-top: 35px;
  `}
`;
