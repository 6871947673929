import { useCallback, useReducer } from 'react';
import { purchaseOfferMatchListReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-match-list/purchase-offer-match-list.reducer';
import {
  PurchaseOfferMatchListActionType,
  PurchaseOfferMatchListSelectedActionType,
  PurchaseOfferMatchProposalActionType,
} from '../../purchase-offer.action';
import { purchaseOfferInitialState } from '../../purchase-offer.constants';
import { IPurchaseOfferMatchList } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-match-list.types';
import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';
import { purchaseOfferMatchListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-list-filters-initial-values.constant';
import { IPurchaseOfferMatchListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list-filters.domain';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';

export const usePurchaseOfferMatchListContextValues = (): IPurchaseOfferMatchList => {
  const [state, dispatch] = useReducer(purchaseOfferMatchListReducer, purchaseOfferInitialState);

  const setPurchaseOfferMatchListRequest = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_REQUEST, payload: undefined });
  }, []);

  const setPurchaseOfferMatchListSuccess = useCallback((list: Array<IPurchaseOfferMatchListDomain>) => {
    dispatch({ type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_SUCCESS, payload: list });
  }, []);

  const setPurchaseOfferMatchListError = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_ERROR, payload: undefined });
  }, []);

  const setPurchaseOfferMatchListReset = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_RESET, payload: undefined });
  }, []);

  const setPurchaseOfferMatchProposalRequest = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_REQUEST, payload: undefined });
  }, []);

  const setPurchaseOfferMatchProposalSuccess = useCallback((proposal: IPurchaseOfferListDomain) => {
    dispatch({ type: PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_SUCCESS, payload: proposal });
  }, []);

  const setPurchaseOfferMatchProposalError = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_ERROR, payload: undefined });
  }, []);

  const setPurchaseOfferMatchProposalReset = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_RESET, payload: undefined });
  }, []);

  const setPurchaseOfferMatchListSelectedRequest = useCallback(() => {
    dispatch({
      type: PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_REQUEST,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferMatchListSelectedSuccess = useCallback((match: IPurchaseOfferMatchListDomain) => {
    dispatch({ type: PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_SUCCESS, payload: match });
  }, []);

  const setPurchaseOfferMatchListSelectedError = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_ERROR, payload: undefined });
  }, []);

  const setPurchaseOfferMatchListSelectedReset = useCallback(() => {
    dispatch({ type: PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_RESET, payload: undefined });
  }, []);

  const setSelectFilters = useCallback((filters: IPurchaseOfferMatchListFilterDomain) => {
    dispatch({ type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_SELECT_FILTERS, payload: filters });
  }, []);

  const setResetFilters = useCallback(() => {
    dispatch({
      type: PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_SELECT_FILTERS,
      payload: purchaseOfferMatchListFilterInitialValues,
    });
  }, []);

  return {
    ...state.purchaseOfferMatchList,
    setPurchaseOfferMatchListRequest,
    setPurchaseOfferMatchListSuccess,
    setPurchaseOfferMatchListError,
    setPurchaseOfferMatchListReset,
    purchaseOfferMatchProposal: {
      ...state.purchaseOfferMatchList.purchaseOfferMatchProposal,
      setPurchaseOfferMatchProposalRequest,
      setPurchaseOfferMatchProposalSuccess,
      setPurchaseOfferMatchProposalError,
      setPurchaseOfferMatchProposalReset,
    },
    purchaseOfferMatchListSelected: {
      ...state.purchaseOfferMatchList.purchaseOfferMatchListSelected,
      setPurchaseOfferMatchListSelectedRequest,
      setPurchaseOfferMatchListSelectedSuccess,
      setPurchaseOfferMatchListSelectedError,
      setPurchaseOfferMatchListSelectedReset,
    },
    filters: {
      ...state.purchaseOfferMatchList.filters,
      setSelectFilters,
      setResetFilters,
    },
  };
};
