import { HomeBidsActionType } from '../../home.action';
import { IHomeBidsActions } from '../../types/home-bids.types';
import { IHomeContext } from '../../types/home.types';

export const homeBidsReducer = (state: IHomeContext, action: IHomeBidsActions): IHomeContext => {
  switch (action.type) {
    case HomeBidsActionType.HOME_BIDS_REQUEST:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          statusRequest: 'PROCESSING',
        },
      };
    case HomeBidsActionType.HOME_BIDS_SUCCESS:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          statusRequest: 'SUCCESS',
          homeBids: action.payload,
        },
      };
    case HomeBidsActionType.HOME_BIDS_ERROR:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          statusRequest: 'ERROR',
          homeBids: [],
        },
      };
    case HomeBidsActionType.HOME_BIDS_NEXT_PAGE:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          paginationActions: {
            ...state.homeBids.paginationActions,
            nextPage: action.payload,
          },
        },
      };
    case HomeBidsActionType.HOME_BIDS_PAGE_LIMIT:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          paginationActions: {
            ...state.homeBids.paginationActions,
            isPageLimit: true,
          },
        },
      };
    case HomeBidsActionType.HOME_BIDS_RESET:
      return {
        ...state,
        homeBids: {
          ...state.homeBids,
          statusRequest: 'INITIAL',
          homeBids: [],
        },
      };
    default:
      return state;
  }
};
