import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useState } from 'react';
import { normalizeGeopoint } from 'utils/normalizes';
import { IGoogleMapsLocation, IMapsDirection } from 'src-new/components/google-maps/google-maps-view.types';

interface IGoogleMapsDirectionsProps {
  direction?: IMapsDirection;
  realtimeRoute?: boolean;
}

const MAX_WAYPOINTS = 25;
const INTERVAL_ROUND = 5;

export const GoogleMapsDirections: React.FC<IGoogleMapsDirectionsProps> = ({ direction, realtimeRoute }) => {
  const map = useMap();
  const maps = useMapsLibrary('routes');
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService | null>(null);
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer | null>(null);

  const getIntervalByRouteSize = useCallback((routes: Array<IGoogleMapsLocation>) => {
    if (routes.length === 0) {
      return 0;
    }

    let totalMinutes = 0;

    for (let i = 1; i < routes.length; i++) {
      const prevTimestamp = routes[i - 1].timestamp;
      const currentTimestamp = routes[i].timestamp;

      if (prevTimestamp && currentTimestamp) {
        const intervalMinutes = (currentTimestamp - prevTimestamp) / (1000 * 60);
        totalMinutes += intervalMinutes;
      }
    }

    let roundedInterval = Math.ceil(totalMinutes / MAX_WAYPOINTS);
    roundedInterval = Math.ceil(roundedInterval / INTERVAL_ROUND) * INTERVAL_ROUND;

    return roundedInterval;
  }, []);

  const getWayPoints = useCallback(
    (routes?: Array<IGoogleMapsLocation>): google.maps.DirectionsWaypoint[] => {
      if (routes && routes.length) {
        const waypoints: google.maps.DirectionsWaypoint[] = [];
        let lastTimestamp: number | null = null;

        routes.forEach((route, index) => {
          if (route.timestamp) {
            if (lastTimestamp === null) {
              waypoints.push({
                location: { lat: route.lat, lng: route.lng },
                stopover: false,
              });

              lastTimestamp = route.timestamp;
            } else {
              const intervalMinutes = (route.timestamp - lastTimestamp) / (1000 * 60);

              if (intervalMinutes >= getIntervalByRouteSize(routes)) {
                waypoints.push({
                  location: { lat: route.lat, lng: route.lng },
                  stopover: false,
                });

                lastTimestamp = route.timestamp;
              }
            }

            if (index < routes.length - 1) {
              const nextTimestamp = routes[index + 1].timestamp!;
              const daysDifference = Math.floor((nextTimestamp - route.timestamp) / (1000 * 60 * 60 * 24));

              if (daysDifference >= 1) {
                lastTimestamp = route.timestamp + daysDifference * 24 * 60 * 60 * 1000;
              }
            }
          }
        });

        return waypoints.slice(0, MAX_WAYPOINTS);
      }

      return [];
    },
    [getIntervalByRouteSize],
  );

  const renderDirection = useCallback(
    (direction: IMapsDirection, renderer: google.maps.DirectionsRenderer | null) => {
      if (direction && renderer && directionsService) {
        const waypoints = getWayPoints(direction.routePositions);

        directionsService
          .route({
            origin: {
              lat: Number(normalizeGeopoint(direction.origin.lat)),
              lng: Number(normalizeGeopoint(direction.origin.lng)),
            },
            destination: {
              lat: Number(normalizeGeopoint(direction.destination.lat)),
              lng: Number(normalizeGeopoint(direction.destination.lng)),
            },
            waypoints: waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            renderer.setDirections(response);
          });
      }
    },
    [directionsService, getWayPoints],
  );

  useEffect(() => {
    if (maps && map) {
      setDirectionsService(new maps.DirectionsService());
    }
  }, [maps, map]);

  useEffect(() => {
    if (maps && map && directionsService) {
      if (direction) {
        if (!directionsRenderer) {
          const renderer = new maps.DirectionsRenderer({
            map,
            suppressMarkers: direction.iconProps.hasIconGroup,
            polylineOptions: {
              strokeWeight: 8,
              strokeOpacity: realtimeRoute ? 1 : 0.6,
              strokeColor: direction.routeColor,
              zIndex: realtimeRoute ? 10 : 1,
            },
            preserveViewport: true,
          });
          setDirectionsRenderer(renderer);
          renderDirection(direction, renderer);
        } else {
          renderDirection(direction, directionsRenderer);
        }
      } else if (directionsRenderer) {
        directionsRenderer.setMap(null);
        setDirectionsRenderer(null);
      }
    }
  }, [maps, map, directionsService, direction, renderDirection, directionsRenderer, realtimeRoute]);

  return null;
};
