/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { ModalFormSubTitle } from './components/sub-title';
import { BiddingForm } from './components/form';

import { UserContext } from 'state/user-context';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { companyDetailsService } from 'services/company/details';
import { retentionFinesService } from 'pages/company/services/retention-fines/retention-fines.service';
import { ICompany, IRetention } from 'domain/company';

interface ModalMatchFormProps {
  idleness: any;
  handleClose: () => void;
  handleBuyBidding: (body: any) => void;
}

export const ModalBiddingForm: FC<ModalMatchFormProps> = ({ idleness, handleClose, handleBuyBidding }) => {
  const { user } = useContext(UserContext);

  const [requirements, setRequirements] = useState<Array<string> | undefined>(undefined);
  const [company, setCompany] = useState<ICompany>();
  const [userCompany, setUserCompany] = useState<ICompany>();

  const getRetentionByVehicleType = useCallback(
    async (retentions: IRetention[], vehicleType: string) =>
      retentions?.find((retention: { vehicleType: string }) => retention.vehicleType == vehicleType),
    [],
  );

  const setCompanyRetentionWithLogshareValues = useCallback(
    async (companyFound: ICompany, vehicleType: string) => {
      const logshareRetentions = await retentionFinesService(2);

      const logshareRetentionFound = await getRetentionByVehicleType(logshareRetentions, vehicleType);

      companyFound.freeTime = logshareRetentionFound?.freeTime ?? '0';
      companyFound.hourlyFine = logshareRetentionFound?.hourlyFine ?? 0;
      companyFound.dailyFine = logshareRetentionFound?.dailyFine ?? 0;
    },
    [getRetentionByVehicleType],
  );

  useEffect(() => {
    (async () => {
      const findCompany = await companyDetailsService(idleness.company.id);
      const findUserCompany = await companyDetailsService(user?.companyId ?? 0);

      const companyRetentions = await retentionFinesService(Number(findCompany.id));
      const companyRetentionFound = await getRetentionByVehicleType(companyRetentions, 'carreta');

      if (!companyRetentionFound) {
        await setCompanyRetentionWithLogshareValues(findCompany, idleness.vehicleType);
      } else {
        findCompany.freeTime = companyRetentionFound?.freeTime ?? '0';
        findCompany.hourlyFine = companyRetentionFound?.hourlyFine ?? 0;
        findCompany.dailyFine = companyRetentionFound?.dailyFine ?? 0;
      }

      setCompany(findCompany);
      setUserCompany(findUserCompany);
    })();
  }, [
    getRetentionByVehicleType,
    idleness.company.id,
    idleness.vehicleType,
    setCompanyRetentionWithLogshareValues,
    user,
  ]);

  return (
    <ModalBark
      size="lg"
      title={`Agendamento de Bidding - Oferta ID ${idleness.biddingId}`}
      subtitle={
        <ModalFormSubTitle
          haulType={'BACKHAUL EXTERNO'}
          scheduleType={'ftl'}
          operationType={idleness.operationType}
          co2={idleness.co2Economy}
          requirements={requirements}
        />
      }
      handleClose={handleClose}
    >
      <BiddingForm
        handleSubmit={handleBuyBidding}
        idleness={idleness}
        company={company}
        userCompany={userCompany}
        setRequirements={setRequirements}
        user={user}
      />
    </ModalBark>
  );
};
