import { ScheduleRefusedListActionType } from '../../schedule-refused.action';
import { IScheduleRefusedListActions } from '../../types/schedule-refused-list.types';
import { IScheduleRefusedContext } from '../../types/schedule-refused.types';

export const scheduleRefusedListReducer = (
  state: IScheduleRefusedContext,
  action: IScheduleRefusedListActions,
): IScheduleRefusedContext => {
  switch (action.type) {
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_REQUEST:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          statusRequest: 'PROCESSING',
          scheduleRefusedList: [],
        },
      };
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_SUCCESS:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          statusRequest: 'SUCCESS',
          scheduleRefusedList: action.payload,
        },
      };
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_ERROR:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          statusRequest: 'ERROR',
          scheduleRefusedList: [],
        },
      };
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_MODALS:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          scheduleRefusedListModals: {
            ...state.scheduleRefusedList.scheduleRefusedListModals,
            modalOptions: action.payload,
          },
        },
      };
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_FILTERS:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          scheduleRefusedListFilters: {
            ...state.scheduleRefusedList.scheduleRefusedListFilters,
            filters: action.payload,
          },
        },
      };
    case ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_RESET:
      return {
        ...state,
        scheduleRefusedList: {
          ...state.scheduleRefusedList,
          statusRequest: 'INITIAL',
          scheduleRefusedList: [],
        },
      };
    default:
      return state;
  }
};
