import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { HomePageBody } from './components/home-page-body/home-page-body.component';
import { HomeContextProvider } from '../../context/home/home.context';

export const HomePage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (!user) return <Navigate to={'/login'} replace />;

  return (
    <HomeContextProvider>
      <HomePageBody />
    </HomeContextProvider>
  );
};
