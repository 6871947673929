import React, { createContext, useMemo, useReducer } from 'react';
import { registrationsInitialState } from './registrations.constants';
import { registrationsReducer } from './reducers/registrations.reducer';
import { IRegistrationsContextProps } from './registrations.types';
import { IRegistrationsContext } from './types/registrations.types';
import { IContactDetailsSection } from 'src-new/pages/registrations/contexts/registrations/types/contact-details-section.types';
import { useContactDetailsSectionContextValues } from 'src-new/pages/registrations/contexts/registrations/context-values/contact-details-section/contact-details-section.context';
import { IAttachDocumentsSection } from './types/attach-documents-section.types';
import { useAttachDocumentsSectionContextValues } from './context-values/attach-documents-section/attach-documents-section.context';
import { useRiskManagementSectionContext } from 'src-new/pages/registrations/contexts/registrations/context-values/risk-management-section/risk-management-section.context';
import { IRiskManagementSection } from 'src-new/pages/registrations/contexts/registrations/types/risk-management-section.types';

export const RegistrationsContext = createContext<IRegistrationsContext>(registrationsInitialState);

export const RegistrationsContextProvider = ({ children }: IRegistrationsContextProps) => {
  const [state] = useReducer(registrationsReducer, registrationsInitialState);
  const contactDetailsSection: IContactDetailsSection = useContactDetailsSectionContextValues();
  const attachDocumentsSection: IAttachDocumentsSection = useAttachDocumentsSectionContextValues();
  const riskManagementSection: IRiskManagementSection = useRiskManagementSectionContext();

  const value: IRegistrationsContext = useMemo(
    (): IRegistrationsContext => ({
      ...state,
      contactDetailsSection,
      attachDocumentsSection,
      riskManagementSection,
    }),
    [attachDocumentsSection, contactDetailsSection, riskManagementSection, state],
  );

  return <RegistrationsContext.Provider value={value}>{children}</RegistrationsContext.Provider>;
};
