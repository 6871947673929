import styled from 'styled-components';

export const WrapperStyled = styled.div`
  z-index: 1051;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`;

export const ContainerStyled = styled.div`
  position: fixed;
  background: white;
  width: 70%;
  height: 80%;
  border-radius: 8px;
  top: 10%;
  left: 15%;
`;

export const WrapperHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #12264e;
  border-radius: 5px 5px 0px 0px;
  padding: 25px 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
`;

export const CloseButtonStyled = styled.div`
  cursor: pointer;
`;

export const TitleStyled = styled.h3`
  font-size: 17px;
`;

export const DescriptionStyled = styled.p`
  font-size: 13px;
`;

export const BodyStyled = styled.div``;
