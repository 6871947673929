import { DatesMatchInput, MatchRegisterInput, OriginAndDestinationMatchInput } from '..';

import { scheduleCargoInitialValues } from 'domain-v2/schedule/cargo/initial-values';

const initialValuesOriginAndDestiny: OriginAndDestinationMatchInput = {
  id: 0,
  type: '',
  tradeName: '',
};

export const initialValuesDate: DatesMatchInput = {
  initialLevyDate: '',
  finalLevyDate: '',
  initialDeliveryDate: '',
  finalDeliveryDate: '',
};

export const matchRegisterInitialValues: MatchRegisterInput = {
  origin: initialValuesOriginAndDestiny,
  destination: initialValuesOriginAndDestiny,
  cargo: scheduleCargoInitialValues,
  dates: [initialValuesDate],
  haulType: 'BACKHAUL EXTERNO',
  saleOfferId: 0,
  saleOfferShipperTarifId: 0,
  matchOfferId: null,
  companySeller: 0,
  invoicedBy: '',
  cost: {
    freightValue: 0,
    tollValue: 0,
    grisAdvalorem: 0,
    freightTotal: 0,
    icms: 0,
    totalService: 0,
  },
};
