import styled, { css } from 'styled-components';

export const WrapperRequirements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 7px 35px 0 0;
`;

interface IRequirementProps {
  columns: string;
}
export const Requirement = styled.section<IRequirementProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 0 5px;
    grid-auto-flow: column;
    align-items: center;
  `}
`;

interface IArrowButtonProps {
  disabled: boolean;
}
export const ArrowButton = styled.div<IArrowButtonProps>`
  ${({ theme, disabled }) => css`
    display: flex;
    align-items: center;

    svg {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      fill: ${disabled ? theme.colorsV2.lightGray : theme.colorsV2.black};
    }
  `}
`;
