import React, { FC, useCallback, useState } from 'react';
import { PurchaseConfirmationModalView } from './purchase-confirmation-modal-view.component';
import { usePurchaseOfferRemove } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/hooks/use-purchase-offer-remove/use-purchase-offer-remove.hook';
import {
  IModalButtonProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';

export interface IPurchaseConfirmationModalProps {
  handleCloseModal: () => void;
}

export const PurchaseConfirmationModal: FC<IPurchaseConfirmationModalProps> = ({ handleCloseModal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const purchaseOfferTariffRemove = usePurchaseOfferRemove();

  const handleIsLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);

  const handleDeleteOfferList = useCallback(() => {
    purchaseOfferTariffRemove(handleCloseModal, handleIsLoading);
  }, [handleCloseModal, handleIsLoading, purchaseOfferTariffRemove]);

  const secondaryButtonProps: IModalButtonProps = {
    label: 'Voltar',
    handleActionButton: handleCloseModal,
  };
  const primaryButtonProps: IModalPrimaryButtonProps = {
    label: 'Excluir',
    isLoading: isLoading,
    handleActionButton: handleDeleteOfferList,
    color: 'red',
  };

  return (
    <PurchaseConfirmationModalView
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    />
  );
};
