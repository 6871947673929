import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { PurchaseOfferContextProvider } from '../../contexts/purchase-offer/purchase-offer.context';
import { PurchaseOfferPageBody } from './components/purchase-offer-page-body/purchase-offer-page-body.component';
import { MatchesContextProvider } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { PageHeaderFilterContextProvider } from 'src-new/contexts/page-header-filter/page-header-filter.context';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { purchaseOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-list-filters-initial-values.constant';

export const PurchaseOfferListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <PageHeaderFilterContextProvider initialFilters={purchaseOfferListFilterInitialValues}>
        <InfiniteScrollListTableContextProvider>
          <MatchesContextProvider>
            <PurchaseOfferContextProvider>
              <PurchaseOfferPageBody />
            </PurchaseOfferContextProvider>
          </MatchesContextProvider>
        </InfiniteScrollListTableContextProvider>
      </PageHeaderFilterContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
