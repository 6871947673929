import {
  IShippingCompanyDomain,
  IShippingCompanyRntrcs,
  IShippingCompanySecurity,
} from 'src-new/pages/registrations/domains/shipping-company';

export const rntrcsInitialValues: IShippingCompanyRntrcs = {
  anntNumber: 0,
  initialDate: {
    date: {
      date: null,
      focused: false,
      setted: false,
    },
    hour: '',
  },
  finalDate: {
    date: {
      date: null,
      focused: false,
      setted: false,
    },
    hour: '',
  },
  branch: '',
} as IShippingCompanyRntrcs;

export const securityInitialValues: IShippingCompanySecurity = {
  code: 0,
  initialDate: {
    date: {
      date: null,
      focused: false,
      setted: false,
    },
    hour: '',
  },
  finalDate: {
    date: {
      date: null,
      focused: false,
      setted: false,
    },
    hour: '',
  },
  coverageValue: 0,
} as IShippingCompanySecurity;

export const initialValuesCompany: IShippingCompanyDomain = {
  id: 0,
  name: '',
  internalCode: '',
  cnpj: '',
  userName: '',
  userEmail: '',
  address: {
    cep: '',
    city: '',
    uf: '',
    neighborhood: '',
    street: '',
    numberHouse: '',
    complement: '',
    lng: '',
    lat: '',
  },
  bank: {
    agency: '',
    CC: '',
    code: '',
    pix: '',
  },
  contacts: [],
  attachments: [],
  originalAttachments: [],
  status: 'active',
  rntrcs: [rntrcsInitialValues],
  security: [securityInitialValues],
  vehiclesServiced: {
    category: [],
    typology: [],
  },
};
