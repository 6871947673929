import axios from 'axios';

export const convertProfileUrlToFile = async (url: string) => {
  try {
    const fileName = url.split('?')[0].split('/')[3];
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    if (response) {
      return new File([response.data], fileName);
    } else return '';
  } catch (error) {
    return 'Houve um erro ao buscar detalhes do Parceiro';
  }
};

export const convertS3PathToImage = (url: string) => {
  return `${import.meta.env.VITE_AWS_S3}${url}`;
};
