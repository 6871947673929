import { SelectItem } from '../entities/select-items.entity';

import API from 'utils/API/API';
import { convertShippingEntityToSelectItem } from 'pages/vehicle/mappers/convert-shipping-entity-to-select-item/convert-shipping-entity-to-select-item.mapper';

export const shippingCompanyFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`shipping-company-name?name=${searchValue}`);
  return data.map((shipping: { name: string; id: number }) =>
    convertShippingEntityToSelectItem(shipping.name.toUpperCase(), shipping.id),
  );
};
