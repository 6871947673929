import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  margin-top: -25px;
  padding-bottom: 34px;
`;

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const TitleText = styled.h1`
  ${({ theme }) => css`
    font-size: 32px;
    font-weight: bold;
    color: ${theme.colorsV2.black};
  `}
`;

export const SubtitleText = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.normal};
    color: #727272;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ContentCards = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
`;

export const TablesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
