import styled, { css } from 'styled-components';

export const WrapperModal = styled.main`
  display: flex;
  flex-direction: column;

  width: 16.5rem;
  height: 10rem;
  background-color: white;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const AlertsList = styled.div``;

export const WrapperAlertsMessage = styled.div<{ viewed: boolean }>`
  ${({ viewed }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0.4rem;
    background-color: ${viewed ? 'transparent' : 'rgb(245 245 245)'};

    p {
      font-size: 0.8rem;
    }

    :hover {
      background-color: #f2f2f2;
    }
  `}
`;

export const WrapperIconAlert = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const AlertsMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding 1rem 0rem 0.5rem 0rem;

  p {
    font-size: 0.8rem;
  }

  :hover {
    background-color: #f2f2f2;
  }
`;

export const AlertsEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;

  gap: 1rem;
  padding 1rem;

  p {
    font-size: 0.8rem;
    color: #c3c3c3;
    font-weight: bold;
  }
`;

export const Point = styled.div<{ viewed: boolean }>`
  ${({ viewed }) => css`
    min-width: 7px;
    height: 7px;
    border-radius: 100%;
    position: relative;
    right: 12px;
    bottom: 12px;
    background-color: ${viewed ? 'transparent' : '#e04747'};
  `}
`;

export const Line = styled.hr`
  border: 0.1px solid #d9d9d9;
`;

export const ViewedAlert = styled.span`
  font-size: 0.7rem;
  text-align: end;
  font-style: italic;
`;
