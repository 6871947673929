import { useParams } from 'react-router-dom';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';
import dayjs from 'dayjs';

import * as S from './styled';

import { UseUpdatePlanningRoute } from 'pages-v2/my-route/hooks/update-planning-route';
import { ModalPlanningType } from 'pages-v2/my-route/components/form/types/modal';
import { planningRouteInitialValues } from 'pages-v2/my-route/components/form/initial-values';
import { PlanningRouteInput } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { ModalState } from 'domain-v2/inputs/modals';
import { SectionStyled } from 'components-v2/layout/section/styled';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import InputField from 'components/input-field/input-field';

interface PlanningRouteModalProps {
  userName: string;
  values: MyRouterRegisterInput;
  planningRouteModal: ModalState<{
    type: ModalPlanningType;
    payload: PlanningRouteInput;
  }>;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
  handleClosePlanningRouteModal: () => void;
}

export const PlanningRouteModal: FC<PlanningRouteModalProps> = ({
  userName,
  values,
  planningRouteModal,
  setFieldValue,
  handleClosePlanningRouteModal,
}) => {
  const { id } = useParams();

  const [planningRouteForm, setPlanningRouteForm] = useState<PlanningRouteInput>({
    ...planningRouteInitialValues,
    plannedBy: userName,
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPlanningRouteForm((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleFieldDateChange = useCallback((name: string, date: dayjs.Dayjs | null) => {
    setPlanningRouteForm((prevState) => ({ ...prevState, [name]: date?.toISOString() }));
  }, []);

  const buttonDisabled = useMemo(() => {
    if (!planningRouteForm.name || !planningRouteForm.initialDate || !planningRouteForm.finalDate) {
      return true;
    }

    return false;
  }, [planningRouteForm.name, planningRouteForm.initialDate, planningRouteForm.finalDate]);

  const handleUpdatePlanningRoute = useCallback(() => {
    setFieldValue(`planningRoute[${planningRouteModal.index}]`, {
      ...planningRouteForm,
      allowedVehicles: [...values.planningRoute[planningRouteModal.index || 0].allowedVehicles],
    });

    handleClosePlanningRouteModal();
  }, [planningRouteModal, planningRouteForm, setFieldValue]);

  const handleSavePlanningRoute = useCallback(async () => {
    if (planningRouteModal.data.type === 'update') {
      handleUpdatePlanningRoute();

      if (id) UseUpdatePlanningRoute({ ...planningRouteForm, routeId: Number(id) });

      return;
    }

    setFieldValue('planningRoute', [...values.planningRoute, planningRouteForm]);

    handleClosePlanningRouteModal();
  }, [setFieldValue, planningRouteModal, planningRouteForm]);

  useEffect(() => {
    if (planningRouteModal.data.type === 'update') {
      setPlanningRouteForm(values.planningRoute[planningRouteModal.index || 0]);
    }
  }, [planningRouteModal.open]);

  return (
    <ModalBark size={'xsm'} title={'Novo Planejamento'} handleClose={handleClosePlanningRouteModal}>
      <SectionStyled name="Informações do Planejamento">
        <LineSectionStyled columns="1fr">
          <InputField
            label="Título do Planejamento *"
            id="name"
            name="name"
            type="text"
            onChange={handleChange}
            value={planningRouteForm.name}
          />
        </LineSectionStyled>

        <LineSectionStyled columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial *"
            format="DD/MM/YYYY"
            value={dayjs(planningRouteForm.initialDate)}
            minDate={dayjs()}
            onChange={(date) => handleFieldDateChange('initialDate', date)}
            errorMessage={!planningRouteForm.initialDate ? 'Campo Obrigatório' : ''}
            views={['year', 'month', 'day']}
          />

          <DateTimePikers
            label="Data Final *"
            format="DD/MM/YYYY"
            value={dayjs(planningRouteForm.finalDate)}
            minDate={dayjs(planningRouteForm.initialDate)}
            onChange={(date) => handleFieldDateChange('finalDate', date)}
            errorMessage={!planningRouteForm.finalDate ? 'Campo Obrigatório' : ''}
            views={['year', 'month', 'day']}
          />
        </LineSectionStyled>
      </SectionStyled>

      <S.WrapperButton>
        <Button
          label={'Salvar'}
          color={'blue'}
          onPress={handleSavePlanningRoute}
          variant={'solid'}
          size={'md'}
          disabled={buttonDisabled}
        />
      </S.WrapperButton>
    </ModalBark>
  );
};
