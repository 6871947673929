import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;

  overflow-y: auto;
  margin-top: 5px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.h4`
  font-weight: 400;
  color: #7f7f7f;
  font-size: 0.875rem;
`;

export const WrapperSubTitleAndTotalValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubTitle = styled.h4`
  font-weight: 600;
  font-size: 0.875rem;
`;

export const WrapperInformation = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0.2rem 0;

  white-space: nowrap;
  overflow: hidden;
`;

export const WrapperCurrency = styled.div`
  width: 95px;
  display: flex;
  justify-content: space-between;
`;

export const InformationTitle = styled.span<{ contrastValue?: boolean }>`
  ${({ contrastValue }) => css`
    font-size: 0.75rem;
    font-weight: ${contrastValue ? '600' : '300'};
  `}
`;

export const InformationValue = styled.p<{ discountValue: boolean; contrastValue?: boolean }>`
  ${({ discountValue, contrastValue }) => css`
    color: ${discountValue ? '#00' : '#000'};
    font-size: 0.75rem;
    font-weight: ${contrastValue ? '600' : '400'};
    text-transform: capitalize;

    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;
