import { IContactAlert, IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';

export const mountAlertList = (): Array<IContactAlert> => {
  return [
    {
      workflow: 'AGENDAMENTO',
      isActive: false,
    },
    {
      workflow: 'ORDEM_COLETA',
      isActive: false,
    },
    {
      workflow: 'OCORRENCIAS',
      isActive: false,
    },
    {
      workflow: 'FATURAMENTO',
      isActive: false,
    },
  ];
};

export const contactInitialValues: IContactDomain = {
  name: '',
  area: '',
  email: '',
  telephone: '',
  alerts: mountAlertList(),
};
