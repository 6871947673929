import styled, { css } from 'styled-components';

interface IFontStyledProps {
  size?: number;
  weight?: number;
  color?: string;
}

interface IScoreMatchStyledProps {
  color: 'green' | 'proYellow' | 'darkGray';
}

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export const ScoreMatch = styled.div<IScoreMatchStyledProps>`
  ${({ theme, color }) => css`
    display: flex;
    justify-content: flex-end;
    width: 15px;
    height: 15px;
    border-radius: 0.2rem;
    background-color: ${theme.colorsV2[color]};
  `}
`;

export const Text = styled.p<IFontStyledProps>`
  ${({ size = 11, weight = 500, color = '#000' }) => css`
    font-size: ${size}px;
    font-weight: ${weight};
    color: ${color};
  `}
`;
