import React from 'react';

import { ICardProps } from './card.types';
import CardView from './card-view';

interface IProps {
  cards?: ICardProps[];
}

const Card: React.FC<IProps> = ({ cards }) => {
  return <CardView cards={cards} />;
};

export default Card;
