import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { SchedulePageBodyView } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-page-body/schedule-page-body-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { convertScheduleFilters } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.util';
import { tabStatusCode } from 'src-new/pages/execution/pages/schedule/utils/schedule-tab-name/schedule-tab-name.util';
import { scheduleListFiltersInitialValues } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters-initial-values.types';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { useCreateScheduleExcel } from '../../hooks/use-create-schedule-excel/use-create-schedule-excel.hook';
import { UserContext } from 'state/user-context';
import { verifyAppliedFilters } from 'src-new/pages/execution/pages/schedule/utils/applied-filters/applied-filters.util';

export const SchedulePageBody: React.FC = () => {
  const { scheduleList, scheduleKanbanList } = useContext(ScheduleContext);
  const { isShippingCompany } = useContext(UserContext);
  const createScheduleExcel = useCreateScheduleExcel();

  const scheduleFilters: Array<IParamsFilter> = useMemo(
    () => convertScheduleFilters(scheduleList.scheduleListFiltersProps.filters),
    [scheduleList.scheduleListFiltersProps.filters],
  );

  const handleExportExcel = useCallback(async () => {
    await createScheduleExcel(scheduleList.scheduleListFiltersProps.filters);
  }, [createScheduleExcel, scheduleList.scheduleListFiltersProps.filters]);

  const handleToggleClick = useCallback(
    () =>
      scheduleList.viewModeProps.setListViewMode(
        scheduleList.viewModeProps.listViewMode === 'LIST' ? 'KANBAN' : 'LIST',
      ),
    [scheduleList.viewModeProps],
  );

  const handleChangeSearch = useCallback(
    (search: string) => {
      const mountSearchFilter: IScheduleListParams = {
        ...scheduleList.scheduleListFiltersProps.filters,
        search: search,
        page: 1,
      };
      scheduleList.scheduleListFiltersProps.setFilters(mountSearchFilter);
      sessionStorage.setItem('schedule-list-filters', JSON.stringify(mountSearchFilter));
    },
    [scheduleList.scheduleListFiltersProps],
  );

  const handleModalOpen = useCallback(
    (modalType: ScheduleListModalType) => {
      scheduleList.modalsProps.setModalOpen({ open: true, ids: [], modalType });
    },
    [scheduleList.modalsProps],
  );

  const removeScheduleFilter = useCallback(
    (indexToRemove: number) => {
      const { filters, setFilters } = scheduleList.scheduleListFiltersProps;

      const updatedParamsArray = [...scheduleFilters];
      updatedParamsArray.splice(indexToRemove, 1);

      const newFilters =
        updatedParamsArray.length === 0
          ? scheduleListFiltersInitialValues
          : updatedParamsArray.reduce((accumulator: any, { key }) => {
              if (key !== 'typeOperation') {
                accumulator[key as keyof IParamsFilter] = filters[key as keyof IScheduleListParams];
              } else {
                accumulator[key as keyof IParamsFilter] = updatedParamsArray
                  .filter((filter) => filter.key === 'typeOperation')
                  .map((filter) => filter.value);
              }

              return accumulator;
            }, {});

      const newStatusCode =
        newFilters.statusCode?.length === 1 ? newFilters.statusCode : tabStatusCode(filters.activeTabName);

      let updatedFilters: IScheduleListParams = {
        ...newFilters,
        page: 1,
        statusCode: newStatusCode,
        activeTabName: filters.activeTabName,
      };

      if (scheduleFilters[indexToRemove].key === 'name') {
        updatedFilters = {
          ...updatedFilters,
          nameId: undefined,
        };
      }

      setFilters(updatedFilters);
      sessionStorage.setItem('schedule-list-filters', JSON.stringify(updatedFilters));
    },
    [scheduleFilters, scheduleList.scheduleListFiltersProps],
  );

  const appliedFilters = useMemo((): number => {
    return verifyAppliedFilters(scheduleFilters);
  }, [scheduleFilters]);

  const hasCheck = useMemo(
    (): boolean => scheduleList.scheduleListCheckbox.checkbox.length > 0,
    [scheduleList.scheduleListCheckbox.checkbox.length],
  );

  useEffect(() => {
    if (scheduleList.statusRequest === 'INITIAL' && scheduleKanbanList.statusRequest === 'INITIAL') {
      const cachedFilters = sessionStorage.getItem('schedule-list-filters');
      if (cachedFilters) {
        scheduleList.scheduleListFiltersProps.setFilters(JSON.parse(cachedFilters));
      }
    }
  }, [scheduleKanbanList.statusRequest, scheduleList.scheduleListFiltersProps, scheduleList.statusRequest]);

  return (
    <SchedulePageBodyView
      viewMode={scheduleList.viewModeProps.listViewMode}
      scheduleFilters={scheduleFilters}
      appliedFilters={appliedFilters}
      hasCheck={hasCheck}
      isShippingCompany={isShippingCompany}
      handleExportExcel={handleExportExcel}
      handleToggleClick={handleToggleClick}
      handleModalOpen={handleModalOpen}
      handleChangeSearch={handleChangeSearch}
      removeScheduleFilter={removeScheduleFilter}
    />
  );
};
