import { FilterService } from 'domain/filter-delivery-tracking';

export const initialValues: FilterService = {
  id: 0,
  schedulingCode: '',

  companyId: 0,

  scheduleDetails: {
    acceptDate: '',
    startDate: '',
    checkinDate: '',
    checkoutDate: '',
    finishDate: '',
    levyInitialDate: '',
    levyInitialTime: '',
    deliveryInitialDate: '',
    deliveryInitialTime: '',
    levyEndDate: '',
    levyEndTime: '',
    deliveryEndDate: '',
    deliveryEndTime: '',
    loadDate: '',
  },

  scheduleClientOrigin: {
    id: 0,
    client: undefined,
    locality: undefined,
  },
  scheduleClientDestiny: {
    id: 0,
    client: undefined,
    locality: undefined,
  },
  scheduleCargo: {
    capacity: 0,
    id: 0,
    inPalet: false,
    invoice: '',
    needBodyGuard: false,
    needHelper: '',
    operationType: '',
    orderNumber: '',
    pallet: 0,
    shippingReturn: '',
    shippingValue: '',
    vehicleCategoryType: '',
    vehicleType: '',
    weight: 0,
  },
  typeSchedule: '',
  statusCode: '',
  total_freight: 0,
  updated_at: '',
  created_at: '',
};

export const StepperStatusCode = (statusCode: string) => {
  switch (statusCode) {
    case 'AG10':
      return 0;
    case 'AL20':
      return 0;
    case 'IN10':
      return 0;
    case 'IN11':
      return 0;
    case 'IN20':
      return 1;
    case 'IN30':
      return 1;
    case 'IN40':
      return 1;
    case 'IN50':
      return 2;
    case 'IN60':
      return 2;
    case 'IN90':
      return 3;
    default:
      return 0;
  }
};
