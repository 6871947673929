import React from 'react';
import * as S from './connect-points-view.styled';

interface ILineConnectorViewProps {
  bgColor: string;
  height: number;
  width?: number;
}

export const LineConnectorView: React.FC<ILineConnectorViewProps> = ({ height, width, bgColor }) => {
  return (
    <S.Wrapper>
      <S.StepIcon bgColor={bgColor} />
      <S.Connector height={height} width={width} bgColor={bgColor} />
      <S.StepIcon bgColor={bgColor} />
    </S.Wrapper>
  );
};
