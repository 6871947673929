import { FC, useMemo } from 'react';
import { Accordion } from 'src-new/components/accordion/accordion.component';
import { AccordionContentType } from 'src-new/components/accordion/accordion.types';
import { ScheduleDetailsCargo } from './components/schedule-details-cargo/schedule-details-cargo.component';
import { ScheduleDetailsComments } from './components/schedule-details-comments/schedule-details-comments.component';
import { ScheduleDetailsDates } from './components/schedule-details-dates/schedule-details-dates.component';
import { ScheduleDetailsOriginAndDestiny } from './components/schedule-details-origin-and-destination/schedule-details-origin-and-destination.component';
import { ScheduleDetailsRequirements } from './components/schedule-details-requirements/schedule-details-requirements.component';
import * as S from './schedule-details-accordions-view.style';

interface IScheduleDetailsAccordionsViewProps {
  checkedAccordion: boolean;
}

export const ScheduleDetailsAccordionsView: FC<IScheduleDetailsAccordionsViewProps> = ({ checkedAccordion }) => {
  const mountAccordions = useMemo(() => {
    const sections: Array<AccordionContentType> = [
      {
        title: 'Detalhes do Agendamento',
        buttonOpen: true,
        content: (
          <S.WrapperDetails>
            <ScheduleDetailsOriginAndDestiny type={'origin'} />
            <ScheduleDetailsOriginAndDestiny type={'destination'} />
            <ScheduleDetailsCargo />
            <ScheduleDetailsComments />
            <ScheduleDetailsRequirements />
            <ScheduleDetailsDates />
          </S.WrapperDetails>
        ),
        checked: checkedAccordion,
      },
    ];

    return sections;
  }, [checkedAccordion]);

  return (
    <S.WrapperAccordion>
      <Accordion contents={mountAccordions} />
    </S.WrapperAccordion>
  );
};
