import React from 'react';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';

import { ContainerBarChart, ContainerHeaderStyled, TitleStyled, TraceStyled } from './dashboard-bar.styled';

interface IBarDashboardProps {
  anos?: {
    compra: number;
    country: string;
    venda: number;
  }[];

  pedidosFinalizadosPeriodo: {
    country: string;
    pedidosFinalizados: number;
    pedidosNaoFinalizados: number;
  }[];
}

const DashboardBarView: React.FC<IBarDashboardProps> = ({ pedidosFinalizadosPeriodo }) => {
  return (
    <ContainerBarChart>
      <ContainerHeaderStyled>
        <TitleStyled>Fretes Transacionados por Mês (2024)</TitleStyled>
        {/* <ContainerStatusStyled>
          <Status bgColor="#00a8a4" isSquare title="Pedidos Finalizados" />
        </ContainerStatusStyled> */}
      </ContainerHeaderStyled>
      <TraceStyled />
      <ResponsiveBar
        data={pedidosFinalizadosPeriodo}
        keys={['pedidosFinalizados', 'pedidosNaoFinalizados']}
        indexBy="country"
        margin={{ top: 0, right: -45, bottom: 68, left: -48 }}
        padding={0.5}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'blues' }}
        axisLeft={null}
        enableGridY={false}
        isInteractive={true}
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
          linearGradientDef(
            'gradientB',
            [
              { offset: 100, color: '#00a8a4' },
              { offset: 0, color: '#2E75B5' },
            ],
            {
              gradientTransform: 'rotate(90 0.5 0.5)',
            },
          ),
          {
            id: 'gradientC',
            type: 'linearGradient',
            colors: [
              { offset: 100, color: '#12264e' },
              { offset: 0, color: '#1d4165' },
            ],
          },
        ]}
        fill={[
          {
            match: {
              id: 'pedidosFinalizados',
            },
            id: 'gradientB',
          },
          {
            match: {
              id: 'pedidosNaoFinalizados',
            },
            id: 'gradientC',
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        labelTextColor="#ffffff"
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
        }}
      />
    </ContainerBarChart>
  );
};

export default DashboardBarView;
