import { Button } from 'logshare-ui-kit';
import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { ScheduleDetailsAllocationDriverListTable } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-body/components/schedule-details-allocation-accordions/components/schedule-details-allocation-driver-modal/components/schedule-details-allocation-driver-list-table/schedule-details-allocation-driver-list-table.component';
import * as S from './schedule-details-allocation-driver-modal-view.styled';

interface IScheduleDetailsAllocationDriverModalViewProps {
  driverSelected: {
    checked: boolean;
    driverId: number;
  };
  handleModalClose: () => void;
  handleRegisterDriver: () => void;
  handleDriverAllocation: (driverId: number) => Promise<void>;
}

export const ScheduleDetailsAllocationDriverModalView: FC<IScheduleDetailsAllocationDriverModalViewProps> = ({
  driverSelected,
  handleModalClose,
  handleRegisterDriver,
  handleDriverAllocation,
}) => {
  return (
    <ModalBark
      size={'md'}
      title={<S.AllocationModalDriverTitle>Motoristas Cadastrados</S.AllocationModalDriverTitle>}
      handleClose={handleModalClose}
    >
      <S.AllocationModalDriverWrapper>
        <ScheduleDetailsAllocationDriverListTable />
        <S.WrapperButtons>
          <Button label={'Cadastrar'} color={'blue'} onPress={handleRegisterDriver} variant={'ghost'} size={'md'} />
          <Button
            label={'Avançar'}
            color={'blue'}
            onPress={() => handleDriverAllocation(driverSelected.driverId)}
            variant={'solid'}
            size={'md'}
          />
        </S.WrapperButtons>
      </S.AllocationModalDriverWrapper>
    </ModalBark>
  );
};
