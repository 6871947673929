import { listFinancialService } from 'pages/fiscal-document/services';

export const UseFinancialListHook = async (filter: any) => {
  return listFinancialService({
    limit: filter.limit || 50,
    page: filter.page,
    search: filter.search,
    type: filter.type === 'Pago' ? 'Liquidado' : filter.type,
    order: filter.order,
  });
};
