import React, { useCallback, useContext, useMemo } from 'react';
import { HeaderModalsView } from './header-modals-view.component';
import { UserContext } from 'state/user-context';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { convertScheduleFilters } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.util';
import { ICancelAllocation, IDenyAllocation } from '../../schedule-page-body.types';
import { useUpdateCancelSchedule } from '../../../../hooks/use-update-cancel-schedule/use-update-cancel-schedule.hook';
import { useUpdateDenySchedule } from '../../../../hooks/use-update-deny-schedule/use-update-deny-schedule.hook';

export const HeaderModals: React.FC = () => {
  const { isAdmin } = useContext(UserContext);
  const { scheduleList } = useContext(ScheduleContext);

  const updateCancelScheduleHook = useUpdateCancelSchedule();
  const updateDenyScheduleHook = useUpdateDenySchedule();

  const cancelButtonLabel = useMemo((): 'Negar' | 'Cancelar' => {
    const validStatus = [
      'Em Negociação',
      'Ag. Aceite Transportadora',
      'Em Rota para Carregamento',
      'Em Carregamento',
      'Em Rota para Descarga',
      'Em Descarga',
      'Alocação Cancelada',
      'Oferta Negada',
      'Alocação Cancelada com Custo',
      'Descarga Finalizada',
    ];

    if (scheduleList.scheduleListCheckbox.checkbox.length) {
      const cancelButton = scheduleList.scheduleListCheckbox.checkbox.some(
        (schedule) => !validStatus.includes(schedule.status),
      );

      if (cancelButton) return 'Cancelar';

      return 'Negar';
    }

    if(scheduleList.modalsProps.modalOptions.ids.length){
      const modalId = scheduleList.modalsProps.modalOptions.ids[0]
      const schedule = scheduleList.scheduleList.find(item => item.id === modalId)

      const cancelButton = !validStatus.includes(schedule?.statusCode ?? '')

      if (cancelButton) return 'Cancelar';

      return 'Negar';
    }

    return 'Cancelar';
  }, [scheduleList.modalsProps.modalOptions.ids, scheduleList.scheduleList, scheduleList.scheduleListCheckbox.checkbox]);

  const scheduleFilters: Array<IParamsFilter> = useMemo(
    () => convertScheduleFilters(scheduleList.scheduleListFiltersProps.filters),
    [scheduleList.scheduleListFiltersProps.filters],
  );

  const handleTripCancel = useCallback(
    async (values: ICancelAllocation) => {
      await updateCancelScheduleHook(values);
    },
    [updateCancelScheduleHook],
  );

  const handleTripDeny = useCallback(
    async (values: IDenyAllocation) => {
      await updateDenyScheduleHook(values);
    },
    [updateDenyScheduleHook],
  );

  const handleModalClose = useCallback(() => {
    scheduleList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });
  }, [scheduleList.modalsProps]);

  return (
    <HeaderModalsView
      modalOptions={scheduleList.modalsProps.modalOptions}
      isAdmin={isAdmin}
      cancelButtonLabel={cancelButtonLabel}
      scheduleFilters={scheduleFilters}
      handleTripCancel={handleTripCancel}
      handleTripDeny={handleTripDeny}
      handleModalClose={handleModalClose}
    />
  );
};
