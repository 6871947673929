import React, { ReactElement, useMemo } from 'react';
import { IParamsFilter } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.types';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ModalMatches } from 'src-new/pages/opportunities/pages/matches/contexts/matches/types/modal-matches-kanban.types';
import { MatchesModals } from 'src-new/pages/opportunities/pages/matches/contexts/matches/types/matches-kanban.types';
import { MatchesFilter } from '../matches-filter/metches-filter.component';
import { PurchaseOfferModalMatch } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/purchase-offer-modal-match.component';
import { IPurchaseOfferModals } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-modals.types';
import { ISaleOfferModals } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-modals.types';
import { SaleOfferModalMatch } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-modal-match/sale-offer-modal-match.component';
import { ModalBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/modal-backhaul-purchase.component';
import { ModalBiddingOffer } from 'src-new/components/modal-bidding-offer/modal-bidding-offer.component';

interface IHeaderModalsViewProps {
  modalOptions: MatchesModals;
  modalOfferOptions: ModalMatches;
  purchaseOfferModalsOptions: IPurchaseOfferModals;
  saleOfferModalsOptions: ISaleOfferModals;
  matchesFilters: Array<IParamsFilter>;
  handleModalClose: () => void;
  handleModalOfferClose: () => void;
}

export const HeaderModalsView: React.FC<IHeaderModalsViewProps> = ({
  modalOptions,
  modalOfferOptions,
  purchaseOfferModalsOptions,
  saleOfferModalsOptions,
  matchesFilters,
  handleModalClose,
  handleModalOfferClose,
}) => {
  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      if (modalOptions.modalType === 'filter') {
        return <MatchesFilter matchesFilters={matchesFilters} handleModalFilter={handleModalClose} />;
      }

      if (modalOptions.modalType === 'newFreight') {
        return <ModalNewFreight handleCreateSchedulePage={handleModalClose} />;
      }
    }

    if (modalOfferOptions.open) {
      if (modalOfferOptions.modalType === 'saleOffer') {
        return (
          <ModalBiddingOffer
            biddingOffer={modalOfferOptions.saleOfferMatch}
            isSaleOffer
            handleClose={handleModalOfferClose}
          />
        );
      }

      if (modalOfferOptions.modalType === 'purchaseOffer') {
        return (
          <ModalBackhaulPurchase
            backhaulPurchase={modalOfferOptions.purchaseOfferMatch}
            handleClose={handleModalOfferClose}
          />
        );
      }
    }

    if (purchaseOfferModalsOptions.isOpen && purchaseOfferModalsOptions.modalType === 'purchase_offer_match') {
      return <PurchaseOfferModalMatch />;
    }

    if (saleOfferModalsOptions.isOpen && saleOfferModalsOptions.modalType === 'sale_offer_match') {
      return <SaleOfferModalMatch />;
    }
  }, [
    handleModalClose,
    handleModalOfferClose,
    matchesFilters,
    modalOfferOptions,
    modalOptions,
    purchaseOfferModalsOptions,
    saleOfferModalsOptions,
  ]);

  return renderModals;
};
