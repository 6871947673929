/* eslint-disable @typescript-eslint/no-explicit-any */
export const ErrorMessage = (error: any) => {
  if (error?.message && error.message !== '') {
    if (JSON.parse(error.message)) {
      return JSON.parse(error.message);
    } else {
      return '';
    }
  }
  if (error.response) {
    return JSON.parse(error.response.data.message);
  }
  return error;
};
