import { IUser } from 'domain/login';
import { Button, Tag } from 'logshare-ui-kit';
import { ChevronDown, ChevronUp, CircleDollarSign, ClipboardCheck, Pencil, ScrollText } from 'lucide-react';
import { FC, Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Box } from 'src-new/components/box/box.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import {
  FinanceStatus,
  FinanceType,
  OpenButtonType,
  TransactionType,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-finance/components/schedule-finance-accordions/schedule-finance-accordions.types';
import {
  IScheduleDetailsDomain,
  IScheduleDetailsFinanceDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { ColorsV2Type } from 'styles/types/colors';
import { ScheduleFinanceResume } from './components/schedule-finance-resume/schedule-finance-resume.component';
import { ScheduleFinanceResumeTitle } from './components/schedule-finance-title/schedule-finance-title.component';
import * as S from './schedule-finance-accordions-view.styled';
import TooltipComponent from 'components/Tooltip';

interface IScheduleFinanceAccordionsViewProps {
  user?: IUser;
  isAdmin: boolean;
  schedule: IScheduleDetailsDomain;
  isOpen: {
    isOpen: {
      transactionPayable: boolean;
      transactionReceive: boolean;
      transactionShippingReceive: boolean;
    };
  };
  isLogshareSeller: boolean;
  freightPayable?: IScheduleDetailsFinanceDomain;
  freightReceivable?: IScheduleDetailsFinanceDomain;
  freightShipping?: IScheduleDetailsFinanceDomain;
  handleOpenModalFinanceCalculate: (financeType: FinanceType) => void;
  handleChangeFinanceStatus: (type: TransactionType, financeStatus: FinanceStatus) => void;
  handleOpenOrCloseFinance: (isOpen: {
    transactionPayable: boolean;
    transactionReceive: boolean;
    transactionShippingReceive: boolean;
  }) => void;
}

export const ScheduleFinanceAccordionsView: FC<IScheduleFinanceAccordionsViewProps> = ({
  user,
  isAdmin,
  schedule,
  isOpen,
  isLogshareSeller,
  freightPayable,
  freightReceivable,
  freightShipping,
  handleOpenModalFinanceCalculate,
  handleChangeFinanceStatus,
  handleOpenOrCloseFinance,
}) => {
  const renderButtonIcons = useCallback(
    (isOpenType: OpenButtonType) => {
      if (!isOpen.isOpen[isOpenType]) {
        return <ChevronDown />;
      }
      return <ChevronUp />;
    },
    [isOpen.isOpen],
  );

  const isTracking = useMemo(() => {
    return schedule.operation === 'TRACKING' && !schedule.transportRouterId;
  }, [schedule.operation, schedule.transportRouterId]);

  const isEditAndAuditValuesTransaction = useCallback(
    (type: TransactionType) => {
      const transactionStatus = {
        freightPayable: freightPayable?.financialStatus,
        freightReceivable: freightReceivable?.financialStatus,
        freightShipping: freightShipping?.financialStatus,
      };

      return user?.profile !== 'logshare' || transactionStatus[type] !== 'Em Auditoria';
    },
    [
      freightPayable?.financialStatus,
      freightReceivable?.financialStatus,
      freightShipping?.financialStatus,
      user?.profile,
    ],
  );

  const renderMargin = useMemo(() => {
    if (user?.profile === 'logshare' && freightPayable?.status === 'Pagar' && schedule.operation === 'SPOT') {
      return (
        <Tag
          label={freightPayable.margin?.toFixed(2) + ' %'}
          variant="solid"
          display="flex"
          size="md"
          color={freightPayable.margin > 0 ? ColorsV2Type.Green : ColorsV2Type.Red}
        />
      );
    }
  }, [freightPayable?.margin, freightPayable?.status, schedule.operation, user?.profile]);

  const disableCheckStubButton = useCallback(
    (freightReceivableStatus?: string, freightShippingStatus?: string, profile?: string): boolean => {
      if (freightReceivableStatus && freightShippingStatus) {
        return (
          freightReceivableStatus !== 'Aguardando Canhoto' ||
          freightShippingStatus !== 'Aguardando Canhoto' ||
          profile !== 'logshare'
        );
      }

      if (freightReceivableStatus) {
        return freightReceivableStatus !== 'Aguardando Canhoto' || profile !== 'logshare';
      }

      return freightShippingStatus !== 'Aguardando Canhoto' || profile !== 'logshare';
    },
    [],
  );

  const renderFooterButtons = useCallback(
    (
      type: TransactionType,
      primaryButtonType: FinanceType,
      secondaryButtonType: FinanceType,
      financialStatus: string,
    ) => {
      return (
        <Fragment>
          <S.FinancialButtons>
            <TooltipComponent
              key={0}
              title="Confirmar Auditoria"
              location="bottom"
              icon={
                <S.ContainerButtons isDisabled={isEditAndAuditValuesTransaction(type)}>
                  <Button
                    label=""
                    color="white"
                    onPress={() => handleOpenModalFinanceCalculate(secondaryButtonType)}
                    LeftIcon={<ClipboardCheck size={20} strokeWidth={2.75} />}
                    size="x-sm"
                    variant="ghost"
                    disabled={isEditAndAuditValuesTransaction(type)}
                  />
                </S.ContainerButtons>
              }
            />

            {type !== 'freightPayable' && (
              <TooltipComponent
                key={1}
                title="Confirmar Canhoto"
                location="bottom"
                icon={
                  <S.ContainerButtons
                    isDisabled={disableCheckStubButton(
                      freightReceivable?.financialStatus,
                      freightShipping?.financialStatus,
                      user?.profile,
                    )}
                  >
                    <Button
                      label=""
                      color="white"
                      onPress={() => handleChangeFinanceStatus(type, 'Pagamento Programado')}
                      LeftIcon={<ScrollText size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={disableCheckStubButton(
                        freightReceivable?.financialStatus,
                        freightShipping?.financialStatus,
                        user?.profile,
                      )}
                    />
                  </S.ContainerButtons>
                }
              />
            )}

            <TooltipComponent
              key={2}
              title="Confirmar Pagamento"
              location="bottom"
              icon={
                <S.ContainerButtons
                  isDisabled={
                    (financialStatus !== 'Pagamento Programado' &&
                      financialStatus !== 'Aguardando Canhoto' &&
                      financialStatus !== 'Pago' &&
                      financialStatus !== 'Faturado') ||
                    !isAdmin
                  }
                >
                  <Button
                    label=""
                    color="white"
                    onPress={() => handleChangeFinanceStatus(type, 'Liquidado')}
                    LeftIcon={<CircleDollarSign size={23} color="white" fill="#5d5d5d" strokeWidth={1.5} />}
                    size="x-sm"
                    variant="ghost"
                    disabled={
                      (financialStatus !== 'Pagamento Programado' &&
                        financialStatus !== 'Aguardando Canhoto' &&
                        financialStatus !== 'Pago' &&
                        financialStatus !== 'Faturado') ||
                      !isAdmin
                    }
                  />
                </S.ContainerButtons>
              }
            />
          </S.FinancialButtons>

          <S.MarginEditButton>
            {type === 'freightPayable' && renderMargin}

            <TooltipComponent
              key={3}
              title="Editar"
              location="bottom"
              icon={
                <S.ContainerButtons isDisabled={isEditAndAuditValuesTransaction(type)}>
                  <Button
                    label=""
                    color="white"
                    onPress={() => handleOpenModalFinanceCalculate(primaryButtonType)}
                    LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
                    size="x-sm"
                    variant="ghost"
                    disabled={isEditAndAuditValuesTransaction(type)}
                  />
                </S.ContainerButtons>
              }
            />
          </S.MarginEditButton>
        </Fragment>
      );
    },
    [
      disableCheckStubButton,
      freightReceivable?.financialStatus,
      freightShipping?.financialStatus,
      handleChangeFinanceStatus,
      handleOpenModalFinanceCalculate,
      isAdmin,
      isEditAndAuditValuesTransaction,
      renderMargin,
      user?.profile,
    ],
  );

  const renderFreightPayable = useMemo(() => {
    if (freightPayable?.id) {
      const contentBox = (
        <Fragment>
          <ScheduleFinanceResumeTitle typeTransaction={'freithgtPayable'} />
          <S.ButtonOpenOrClose
            onClick={() =>
              handleOpenOrCloseFinance({
                ...isOpen.isOpen,
                transactionPayable: !isOpen.isOpen.transactionPayable,
              })
            }
          >
            {renderButtonIcons('transactionPayable')}
          </S.ButtonOpenOrClose>
          <hr />
          {isOpen.isOpen.transactionPayable && <ScheduleFinanceResume typeTransaction={'freithgtPayable'} />}
        </Fragment>
      );

      const footerButons = [
        renderFooterButtons(
          'freightPayable',
          'transaction_payable',
          'audit_transaction_payable',
          freightPayable.financialStatus,
        ),
      ];

      return (
        <Box key="freightPayable" footerButtons={footerButons}>
          {contentBox}
        </Box>
      );
    }
  }, [
    freightPayable?.financialStatus,
    freightPayable?.id,
    handleOpenOrCloseFinance,
    isOpen.isOpen,
    renderButtonIcons,
    renderFooterButtons,
  ]);

  const renderFreightReceivable = useMemo(() => {
    if (freightReceivable?.id) {
      const contentBox = (
        <Fragment>
          <ScheduleFinanceResumeTitle typeTransaction={'freithgtReceivable'} />
          <S.ButtonOpenOrClose
            onClick={() =>
              handleOpenOrCloseFinance({
                ...isOpen.isOpen,
                transactionReceive: !isOpen.isOpen.transactionReceive,
              })
            }
          >
            {renderButtonIcons('transactionReceive')}
          </S.ButtonOpenOrClose>
          <hr />
          {isOpen.isOpen.transactionReceive && <ScheduleFinanceResume typeTransaction={'freithgtReceivable'} />}
        </Fragment>
      );
      const footerButons = [
        renderFooterButtons(
          'freightReceivable',
          'transaction_receive',
          'audit_transaction_receive',
          freightReceivable.financialStatus,
        ),
      ];

      return (
        <Box key="freightReceivable" footerButtons={footerButons}>
          {contentBox}
        </Box>
      );
    }
  }, [
    freightReceivable?.financialStatus,
    freightReceivable?.id,
    handleOpenOrCloseFinance,
    isOpen.isOpen,
    renderButtonIcons,
    renderFooterButtons,
  ]);

  const renderFreightShipping = useMemo(() => {
    if (freightShipping?.id) {
      const contentBox = (
        <Fragment>
          <ScheduleFinanceResumeTitle typeTransaction={'freithgtShipping'} />
          <S.ButtonOpenOrClose
            onClick={() =>
              handleOpenOrCloseFinance({
                ...isOpen.isOpen,
                transactionShippingReceive: !isOpen.isOpen.transactionShippingReceive,
              })
            }
          >
            {renderButtonIcons('transactionShippingReceive')}
          </S.ButtonOpenOrClose>
          <hr />
          {isOpen.isOpen.transactionShippingReceive && <ScheduleFinanceResume typeTransaction={'freithgtShipping'} />}
        </Fragment>
      );
      const footerButons = [
        renderFooterButtons(
          'freightShipping',
          'transaction_shipping_receive',
          'audit_transaction_shipping_receive',
          freightShipping.financialStatus,
        ),
      ];
      return (
        <Box key="freightShipping" footerButtons={footerButons}>
          {contentBox}
        </Box>
      );
    }
  }, [
    freightShipping?.financialStatus,
    freightShipping?.id,
    handleOpenOrCloseFinance,
    isOpen.isOpen,
    renderButtonIcons,
    renderFooterButtons,
  ]);

  const renderFreightByInvoiced = useMemo((): ReactElement => {
    if (user?.profile === 'shipping-company') {
      return <Fragment>{renderFreightShipping}</Fragment>;
    }

    if (schedule.operation === 'BACKHAUL EXTERNO') {
      if (isLogshareSeller) {
        return (
          <Fragment>
            {renderFreightReceivable}
            {renderFreightShipping}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {renderFreightPayable}
          {renderFreightShipping}
          {renderFreightReceivable}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {renderFreightPayable}
        {renderFreightReceivable}
        {renderFreightShipping}
      </Fragment>
    );
  }, [
    isLogshareSeller,
    renderFreightPayable,
    renderFreightReceivable,
    renderFreightShipping,
    schedule.operation,
    user?.profile,
  ]);

  if (isTracking) {
    return (
      <Box footerButtons={[]}>
        <EmptyState title="Sem Valor" height="auto" />
      </Box>
    );
  }

  return <S.Wrapper>{renderFreightByInvoiced}</S.Wrapper>;
};
