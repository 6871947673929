import { includes } from 'lodash';
import { chatReducerResetState } from 'src-new/contexts/chat/reducers/chat-reducer.constants';
import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import {
  ChatActionType,
  ChatConversationListActionType,
  ChatEligibleUsersActionType,
  ChatMessageQuantityActionType,
  ChatUnreadMessagesActionType,
  ChatUserEmailsActionType,
} from 'src-new/contexts/chat/chat.action';
import { chatMessageQuantityReducer } from 'src-new/contexts/chat/reducers/chat-message-quantity/chat-message-quantity.reducer';
import { MessageQuantityActions } from 'src-new/contexts/chat/types/chat-message-quantity.types';
import { chatConversationListReducer } from 'src-new/contexts/chat/reducers/chat-conversation-list/chat-conversation-list.reducer';
import { ChatConversationListActions } from 'src-new/contexts/chat/types/chat-conversation-list.types';
import { chatEligibleUsersReducer } from 'src-new/contexts/chat/reducers/chat-eligible-users/chat-eligible-users.reducer';
import { ChatEligibleUsersActions } from 'src-new/contexts/chat/types/chat-eligible-users-chat.types';
import { chatUnreadMessagesReducer } from 'src-new/contexts/chat/reducers/chat-unread-messages/chat-unread-messages.reducer';
import { ChatUnreadMessagesActions } from 'src-new/contexts/chat/types/chat-unread-messages.types';
import { chatUserEmailsReducer } from 'src-new/contexts/chat/reducers/chat-user-emails/chat-user-emails.reducer';
import { ChatUserEmailsActions } from 'src-new/contexts/chat/types/chat-user-emails.types';

export const chatReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  if (includes(Object.values(ChatMessageQuantityActionType), action.type)) {
    return chatMessageQuantityReducer(state, action as MessageQuantityActions);
  }

  if (includes(Object.values(ChatConversationListActionType), action.type)) {
    return chatConversationListReducer(state, action as ChatConversationListActions);
  }

  if (includes(Object.values(ChatEligibleUsersActionType), action.type)) {
    return chatEligibleUsersReducer(state, action as ChatEligibleUsersActions);
  }

  if (includes(Object.values(ChatUnreadMessagesActionType), action.type)) {
    return chatUnreadMessagesReducer(state, action as ChatUnreadMessagesActions);
  }

  if (includes(Object.values(ChatUserEmailsActionType), action.type)) {
    return chatUserEmailsReducer(state, action as ChatUserEmailsActions);
  }

  if (action.type === ChatActionType.CHAT_RESET) {
    return chatReducerResetState(state);
  }

  return state;
};
