import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useUpdateScheduleAllocation } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-allocation/use-update-schedule-allocation.hook';
import { ScheduleDetailsAllocationDriverModalView } from './schedule-details-allocation-driver-modal-view.component';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const ScheduleDetailsAllocationDriverModal: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const navigate = useNavigate();
  const updateScheduleAllocationHook = useUpdateScheduleAllocation();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { setInitialLoadingReset } = initialLoading;

  const handleModalClose = useCallback(() => {
    setInitialLoadingReset();
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails, setInitialLoadingReset]);

  const handleRegisterDriver = useCallback(() => {
    navigate('/motoristas/novo');
  }, [navigate]);

  const handleDriverAllocation = useCallback(
    async (driverId: number) => {
      updateScheduleAllocationHook({ driverId });
    },
    [updateScheduleAllocationHook],
  );

  return (
    <ScheduleDetailsAllocationDriverModalView
      driverSelected={scheduleDetails.allocationDriver.modalProps.driverSelected}
      handleModalClose={handleModalClose}
      handleRegisterDriver={handleRegisterDriver}
      handleDriverAllocation={handleDriverAllocation}
    />
  );
};
