import { useCallback } from 'react';
import Talk from 'talkjs';
import { IUser } from 'domain/login';

export const useUserSessionChat = () => {
  return useCallback((photoUrl: string, user?: IUser): Talk.User => {
    if (user) {
      return new Talk.User({
        id: user.id,
        name: user.name,
        email: user.email,
        role: 'default',
        ...(photoUrl !== ('NOT_FOUND' && '') && { photoUrl: photoUrl }),
      });
    }

    return new Talk.User({
      id: 123,
      name: 'Erro',
      welcomeMessage: 'Ocorreu um erro ao instanciar um usuário',
      photoUrl: 'src/assets/img/LogShare-logo.png',
    });
  }, []);
};
