import API from 'utils/API/API';
import { ScheduleCustomFields } from 'domain-v2/schedule/register';
import { ISchedulingKeys } from 'domain/schedule';

export const getCustomFieldsService = async (): Promise<ISchedulingKeys[]> => {
  const { data } = await API.get('v3/custom-fields');

  return data;
};

export const createScheduleCustomField = async (
  customFields: Array<ScheduleCustomFields>,
  scheduleId: number,
): Promise<ISchedulingKeys[]> => {
  const customFieldsValues = customFields.map(({ ...customFieldValue }) => customFieldValue);

  const { data } = await API.post(`v3/schedule/${scheduleId}/custom-fields`, {
    customFields: customFieldsValues,
  });

  return data;
};
