import React, { useCallback, useContext, useMemo } from 'react';
import { ContactDetailsSectionView } from 'src-new/pages/registrations/components/contact-details-section/contact-details-section-view.component';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

export const ContactDetailsSection: React.FC = () => {
  const { contactDetailsSection } = useContext(RegistrationsContext);
  const { isOpenModal, setModalOpen, setModalClose } = contactDetailsSection;

  const handleOpenRegistrationModal = useCallback(() => setModalOpen(), [setModalOpen]);

  const handleCloseRegistrationModal = useCallback(() => setModalClose(), [setModalClose]);

  const isOpenModalContactRegistration = useMemo(() => isOpenModal, [isOpenModal]);

  return (
    <ContactDetailsSectionView
      handleOpenRegistrationModal={handleOpenRegistrationModal}
      handleCloseRegistrationModal={handleCloseRegistrationModal}
      isOpenModal={isOpenModalContactRegistration}
    />
  );
};
