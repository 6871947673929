/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionMeta, MultiValue } from 'react-select';
import React from 'react';

import MultiAsyncSelectView from './multi-async-select-view';

export interface IMultiAsyncSelectFieldProps {
  name: string;
  id: string;
  placeholder?: string;
  isDisabled?: boolean;
  cacheOptions?: boolean;
  defaultOptions?: boolean;
  hasError?: boolean;
  label?: string;
  errorMessage?: string;
  isClearable?: boolean;
  items?: Array<{ label: string; value: number }>;
  apiCallFunction: (searchValue: string) => Promise<any>;
  loadOptions: (inputValue: string, callback: (options: Array<any>) => void) => Promise<any>;
  onChange: (
    newValue: MultiValue<{
      label: string;
      value: number;
    }>,
    actionMeta: ActionMeta<{
      label: string;
      value: number;
    }>,
  ) => void;
  defaultValue?: { label: string; value: number }[];
}

const MultiAsyncSelectField: React.FC<IMultiAsyncSelectFieldProps> = ({
  name,
  id,
  placeholder,
  isDisabled,
  cacheOptions,
  items,
  defaultOptions,
  apiCallFunction,
  onChange,
  hasError,
  errorMessage,
  label,
  defaultValue,
}) => {
  return (
    <MultiAsyncSelectView
      name={name}
      id={id}
      placeholder={placeholder}
      isDisabled={isDisabled}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      items={items}
      apiCallFunction={apiCallFunction}
      onChange={onChange}
      loadOptions={apiCallFunction}
      hasError={hasError}
      errorMessage={errorMessage}
      label={label}
      defaultValue={defaultValue}
    />
  );
};

export default MultiAsyncSelectField;
