export const initialBackhaulHomeFilter = {
  company: '',
  freightValue: '',
  vehicleCategory: '',
  vehicleType: '',
  originCity: '',
  destinationCity: '',
  destinationUf: '',
  originUf: '',
  page: 0,
};
