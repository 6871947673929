import React, { useCallback, useMemo } from 'react';

import { FreightMap, IFreightValidationValues, IFreightValues, IValidationValues, ScheduleOperation } from './types';
import * as S from './styled';
import { CardResumeValues } from './CardResumeValues';

import { ScheduleInput } from 'domain-v2/schedule';
import { IUser } from 'domain/login';

interface CardResumeValuesProps {
  user?: IUser;
  isAdmin?: boolean;
  schedule?: ScheduleInput;
  valuesFeeLogShare?: number;
  valuesPayable?: IFreightValues;
  valuesReceive?: IFreightValues;
  valuesShipping?: IFreightValues;
}

export const CardResumeValuesValidation: React.FC<CardResumeValuesProps> = ({
  user,
  isAdmin,
  schedule,
  valuesFeeLogShare,
  valuesPayable,
  valuesReceive,
  valuesShipping,
}) => {
  const handleSpotOperation = useCallback(
    ({ isLogShare, isShippingCompany, isCompanySeller, companySellerName, shippingCompanyName }: IValidationValues) => {
      const freightMap: FreightMap = {
        adminCase: [
          { valuesPayable, title: 'Valor a Receber', companyPayable: `Pagador: ${companySellerName}` },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor a Pagar',
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        companySellerCase: [{ valuesPayable, title: 'Valor a Pagar', companyReceive: 'Recebedor: LogShare' }],
        shippingCompanyCase: [{ valuesShipping, title: 'Valor a Receber', companyShipping: 'Pagador: LogShare' }],
      };

      return freightMap[isLogShare ?? isShippingCompany ?? isCompanySeller ?? ''];
    },
    [valuesPayable, valuesShipping],
  );

  const handleTrackingOperation = useCallback(
    ({ isLogShare, isShippingCompany, isCompanySeller, companySellerName, shippingCompanyName }: IValidationValues) => {
      const freightMap: FreightMap = {
        adminCase: [
          {
            valuesPayable,
            title: 'Valor do Frete',
            companyPayable: `Pagador: ${companySellerName}`,
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        companySellerCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor a Pagar',
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        shippingCompanyCase: [
          {
            valuesShipping: valuesShipping,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
          },
        ],
      };

      return freightMap[isLogShare ?? isShippingCompany ?? isCompanySeller ?? ''];
    },
    [valuesPayable, valuesShipping],
  );

  const handleInternalBackhaulOperation = useCallback(
    ({ isLogShare, isShippingCompany, isCompanySeller, companySellerName, shippingCompanyName }: IValidationValues) => {
      const freightMap: FreightMap = {
        adminCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor do Frete',
          },
          {
            valuesFeeLogShare: valuesFeeLogShare,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
          },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor Transportador',
            companyPayable: `Pagador: ${companySellerName}`,
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        companySellerCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor do Frete',
          },
          {
            valuesFeeLogShare: valuesFeeLogShare,
            title: 'Valor a Pagar LogShare',
            companyReceive: 'Recebedor: LogShare',
          },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor a Pagar Transportador',
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        shippingCompanyCase: [
          {
            valuesShipping: valuesShipping,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
          },
        ],
      };

      return freightMap[isLogShare ?? isShippingCompany ?? isCompanySeller ?? ''];
    },
    [valuesPayable, valuesShipping, valuesFeeLogShare],
  );

  const handleExternalBackhaulLogShareOperation = useCallback(
    ({
      isLogShare,
      isShippingCompany,
      isCompanyBuyer,
      isCompanySeller,
      companySellerName,
      companyBuyerName,
      shippingCompanyName,
    }: IValidationValues) => {
      const freightMap: FreightMap = {
        adminCase: [
          {
            valuesReceive: valuesReceive,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companyBuyerName}`,
          },
          {
            valuesPayable: valuesPayable,
            title: 'Valor a Pagar',
            companyReceive: `Recebedor: ${companySellerName}`,
          },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor Transportador',
            companyPayable: `Pagador: ${companySellerName}`,
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        companyBuyerCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor a Pagar',
            companyReceive: 'Recebedor: LogShare',
          },
        ],
        companySellerCase: [
          {
            valuesReceive: valuesReceive,
            title: 'Valor a Receber',
            companyPayable: 'Pagador: LogShare',
          },
          {
            valuesPayable: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor a Pagar',
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        shippingCompanyCase: [
          {
            valuesShipping: valuesShipping,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
          },
        ],
      };

      return freightMap[isLogShare ?? isShippingCompany ?? isCompanyBuyer ?? isCompanySeller ?? ''];
    },
    [valuesReceive, valuesPayable, valuesShipping],
  );

  const handleExternalBackhaulShipperOperation = useCallback(
    ({
      isLogShare,
      isShippingCompany,
      isCompanyBuyer,
      isCompanySeller,
      companySellerName,
      companyBuyerName,
      shippingCompanyName,
    }: IValidationValues) => {
      const freightMap: FreightMap = {
        adminCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor do Frete',
            companyPayable: `Pagador: ${companyBuyerName}`,
            companyReceive: `Recebedor: ${companySellerName}`,
          },
          {
            valuesFeeLogShare: valuesFeeLogShare,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
          },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor Transportador',
            companyPayable: `Pagador: ${companySellerName}`,
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        companyBuyerCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor a Pagar',
            companyReceive: `Recebedor: ${companySellerName}`,
          },
        ],
        companySellerCase: [
          {
            valuesPayable: valuesPayable,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companyBuyerName}`,
            companyReceive: `Recebedor: ${companySellerName}`,
          },
          {
            valuesFeeLogShare: valuesFeeLogShare,
            title: 'Valor a Pagar LogShare',
            companyShipping: 'Recebedor: LogShare',
          },
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor a Pagar Transportador',
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
        shippingCompanyCase: [
          {
            valuesShipping: shippingCompanyName ? valuesShipping : undefined,
            title: 'Valor a Receber',
            companyPayable: `Pagador: ${companySellerName}`,
            companyShipping: `Recebedor: ${shippingCompanyName}`,
          },
        ],
      };

      return freightMap[isLogShare ?? isShippingCompany ?? isCompanyBuyer ?? isCompanySeller ?? ''];
    },
    [valuesPayable, valuesShipping, valuesFeeLogShare],
  );

  const financialValues = useMemo(() => {
    const isLogShare = isAdmin ? 'adminCase' : undefined;
    const isCompanySeller = schedule?.company.id === user?.companyId ? 'companySellerCase' : undefined;
    const isCompanyBuyer = schedule?.companyBuyer?.id === user?.companyId ? 'companyBuyerCase' : undefined;
    const isShippingCompany = user?.profile === 'shipping-company' ? 'shippingCompanyCase' : undefined;

    const companySellerName = schedule?.company.tradeName;
    const companyBuyerName = schedule?.companyBuyer?.name;
    const shippingCompanyName = schedule?.shipping?.name;

    let freightCases = schedule?.operation as ScheduleOperation;

    if (schedule?.operation === 'BACKHAUL EXTERNO' || schedule?.operation === 'BACKHAUL PARCEIRO') {
      freightCases = schedule?.invoicedBy === 'LOGSHARE' ? 'BACKHAUL EXTERNO LOGSHARE' : 'BACKHAUL EXTERNO EMBARCADOR';
    }

    const operationMap = {
      SPOT: handleSpotOperation,
      'BACKHAUL EXTERNO SPOT': handleSpotOperation,
      TRACKING: handleTrackingOperation,
      'BACKHAUL INTERNO': handleInternalBackhaulOperation,
      'BACKHAUL PARCEIRO': handleExternalBackhaulLogShareOperation,
      'BACKHAUL EXTERNO': handleExternalBackhaulLogShareOperation,
      'BACKHAUL EXTERNO LOGSHARE': handleExternalBackhaulLogShareOperation,
      'BACKHAUL EXTERNO EMBARCADOR': handleExternalBackhaulShipperOperation,
    };

    if (schedule?.operation && operationMap[freightCases]) {
      const freightValues: IFreightValidationValues[] = operationMap[freightCases]({
        isLogShare,
        isCompanySeller,
        isCompanyBuyer,
        isShippingCompany,
        companySellerName,
        companyBuyerName,
        shippingCompanyName,
      });

      return <CardResumeValues freightValues={freightValues} />;
    }

    return <CardResumeValues freightValues={[{ valuesPayable: valuesPayable, title: 'Valor a Pagar' }]} />;
  }, [
    isAdmin,
    schedule?.company.id,
    schedule?.company.tradeName,
    schedule?.companyBuyer?.id,
    schedule?.companyBuyer?.name,
    schedule?.shipping?.name,
    schedule?.operation,
    schedule?.invoicedBy,
    user?.companyId,
    user?.profile,
    handleSpotOperation,
    handleTrackingOperation,
    handleInternalBackhaulOperation,
    handleExternalBackhaulLogShareOperation,
    handleExternalBackhaulShipperOperation,
    valuesPayable,
  ]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 3.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>
        <S.Text fontWeight uppercase>
          Valores
        </S.Text>

        <S.TextContent abbreviateText gap>
          {financialValues ?? (
            <S.Text italic uppercase>
              Não Informado
            </S.Text>
          )}
        </S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
