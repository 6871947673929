import API from 'utils/API/API';
import { ChecklistQuestions } from 'domain-v2/checklist/index';

export const getChecklistQuestionsService = async (
  module: string,
  checklistName: string,
  companyId?: number,
): Promise<ChecklistQuestions> => {
  const { data } = await API.get('v2/checklist', {
    params: {
      module: module,
      name: checklistName,
      companyId: companyId,
    },
  });

  return data;
};
