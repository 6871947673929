import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { OccurrencesTrackingContext } from '../../contexts/table-states/tracking-occurrence.context';

import TrackingOccurrenceListBodyView from './tracking-occurrence-body-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useTrackingOccurrenceList } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-list/use-tracking-occurrence-list.hook';
import { ITrackingOccurrenceCreated, ITrackingOccurrenceStatus } from 'domain/tracking-occurrence';

const TrackingOccurrenceListBody: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Em Tratativa');

  const [currentPage, setCurrentPage] = useState(1);
  const { occurrencesTracking, occurrencesTrackingIsLoading, occurrencesTrackingPageInfo } =
    useContext(OccurrencesTrackingContext);

  const trackingOccurrenceList = useTrackingOccurrenceList();

  const goToUpdatePage = useCallback(
    (trackingOccurrenceId: string) => {
      const trackingOccurrence = occurrencesTracking.find(
        (occurrence: ITrackingOccurrenceCreated) => occurrence.id === +trackingOccurrenceId,
      );

      if (trackingOccurrence) {
        navigate(`/tracking-ocorrencias/atualizar/${trackingOccurrenceId}/${trackingOccurrence.osNumber}`, {
          state: {
            sla: trackingOccurrence.occurrenceType?.sla,
            impact: trackingOccurrence.occurrenceType?.impact,
            description: trackingOccurrence.occurrenceType?.description,
          },
        });
      } else {
        showMessageFormatted({
          message: 'Ocorreu um erro ao tentar selecionar a ocorrência',
          type: 'error',
        });
      }
    },
    [navigate, occurrencesTracking],
  );

  const renderStatusByTabName = useMemo((): ITrackingOccurrenceStatus | undefined => {
    if (activeTabName === 'Em Tratativa') {
      return 'em_tratativa';
    }

    if (activeTabName === 'Não Atendidas') {
      return 'nao_atendidas';
    }

    if (activeTabName === 'Finalizadas') {
      return 'finalizadas';
    }

    return undefined;
  }, [activeTabName]);

  useEffect(() => {
    trackingOccurrenceList({
      searchValue: searchInput,
      currentPage: searchInput ? 1 : currentPage,
      status: renderStatusByTabName,
      order: 'desc',
      column: 'id',
    });
  }, [currentPage, renderStatusByTabName, searchInput, trackingOccurrenceList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  return (
    <TrackingOccurrenceListBodyView
      occurrencesTracking={occurrencesTracking}
      occurrencesTrackingPageInfo={occurrencesTrackingPageInfo}
      hasLoading={occurrencesTrackingIsLoading}
      goToUpdatePage={goToUpdatePage}
      setCurrentPage={setCurrentPage}
      setSearchInput={setSearchInput}
      setActiveTabName={setActiveTabName}
    />
  );
};

export { TrackingOccurrenceListBody };
