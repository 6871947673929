import React, { useEffect, useMemo, useState } from 'react';
import { ITimeValues, TimerCountdownView } from './timer-countdown-view.component';

interface ITimerCountdownProps {
  timer: string;
}
export const TimerCountdown: React.FC<ITimerCountdownProps> = ({ timer }) => {
  const [remainingTime, setRemainingTime] = useState<number>(0);

  const timeValues = useMemo((): ITimeValues => {
    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
    const minutes = Math.floor((remainingTime / (1000 * 60)) % 60).toString().padStart(2, '0');
    const seconds = Math.floor((remainingTime / 1000) % 60).toString().padStart(2, '0');

    return { days, hours, minutes, seconds };
  }, [remainingTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const dateTime: Date = new Date(timer);

      const remainingDate = dateTime.getTime() - Date.now();

      if (remainingDate <= 0) {
        setRemainingTime(0);
        clearInterval(intervalId);
      } else {
        setRemainingTime(remainingDate);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  return <TimerCountdownView remainingTime={remainingTime} timeValues={timeValues} />;
};
