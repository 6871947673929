import { showToast } from 'components/toast/toast';
import { useFormikContext } from 'formik';
import { clientDetailsService } from 'pages/client/services/client.service';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { FC, useCallback, useMemo } from 'react';
import { WeighingFormMountLocationsView } from './weighing-register-form-mount-locations-view.component';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';

interface IWeighingFormMountLocationsProps {
  hasError: boolean;
  type: 'origin' | 'destination';
}

export const WeighingFormMountLocations: FC<IWeighingFormMountLocationsProps> = ({ hasError, type }) => {
  const { values, setFieldValue } = useFormikContext<IWeighingFormRegisterDomain>();

  const typeKeyMemo = useMemo(() => {
    if (type === 'origin') return 'origin';
    return 'destination';
  }, [type]);

  const handleCompleteInputs = useCallback(
    async (id: number, type: '' | 'CLIENT' | 'LOCALITY') => {
      if ((type === 'CLIENT' || type === 'LOCALITY') && id) {
        try {
          const details = await (type === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          const fieldValuesToUpdate = {
            [`${typeKeyMemo}.id`]: details.id,
            [`${typeKeyMemo}.address.city`]: details.address.city,
            [`${typeKeyMemo}.address.uf`]: details.address.uf,
            [`${typeKeyMemo}.address.complement`]: details.address.complement,
            [`${typeKeyMemo}.address.neighborhood`]: details.address.neighborhood,
            [`${typeKeyMemo}.address.numberHouse`]: details.address.numberHouse,
            [`${typeKeyMemo}.address.street`]: details.address.street,
            [`${typeKeyMemo}.address.cep`]: details.address.cep,
            [`${typeKeyMemo}.cnpj`]: details.cnpj,
            [`${typeKeyMemo}.lat`]: details.lat,
            [`${typeKeyMemo}.lng`]: details.lng,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue, typeKeyMemo],
  );

  const handleChangeLocations = useCallback(
    async (id: number) => {
      await handleCompleteInputs(id, values[typeKeyMemo].type);
    },
    [handleCompleteInputs, typeKeyMemo, values],
  );

  return (
    <WeighingFormMountLocationsView
      type={typeKeyMemo}
      values={values}
      hasError={hasError}
      setFieldValue={setFieldValue}
      handleChangeLocations={handleChangeLocations}
    />
  );
};
