import { userRegisterService } from 'pages/user/services/user-register-service/user-register.service';
import { INewUser } from 'domain/user';

const useUserRegisterHook = () => {
  return async (user: INewUser) => {
    await userRegisterService(user);
  };
};

export { useUserRegisterHook };
