import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { MatchType } from '../../domains/matches-kanban-list/matches-kanban-list.domain';

export const convertMatchesOfferType = (matchesKanban: IMatchesKanbanListResponse) => {
  const { purchaseOffer, saleOffer } = matchesKanban;

  if (matchesKanban.offerType === 'purchaseOffer') {
    return {
      id: purchaseOffer.id,
      offerTypeId: saleOffer.id,
      isBackhaulPartner: purchaseOffer.isBackhaulPartner,
      type: MatchType.PURCHASE,
      company: {
        id: purchaseOffer.company.id,
        name: purchaseOffer.company.name,
        image: purchaseOffer.company.image,
      },
      origin: {
        id: purchaseOffer.origin.id,
        name: purchaseOffer.origin.name,
        city: purchaseOffer.origin.city,
        uf: purchaseOffer.origin.uf,
        type: purchaseOffer.origin.type,
      },
      destiny: {
        id: purchaseOffer.destination.id,
        name: purchaseOffer.destination.name,
        city: purchaseOffer.destination.city,
        uf: purchaseOffer.destination.uf,
        type: purchaseOffer.destination.type,
      },
      vehicle: {
        type: convertVehicleType(purchaseOffer.vehicleType),
        category: convertVehicleCategory(purchaseOffer.category),
      },
      capacity: {
        weight: purchaseOffer.offeredWeight,
        pallet: purchaseOffer.offeredPallet,
      },
      freightValue: purchaseOffer.freightValue,
      saleOfferTariffId: saleOffer.saleOfferTariffId,
    };
  }

  return {
    id: saleOffer.saleOfferTariffId,
    offerTypeId: purchaseOffer.id,
    isBackhaulPartner: saleOffer.isBackhaulPartner,
    type: MatchType.SALE,
    company: {
      id: saleOffer.company.id,
      name: saleOffer.company.name,
      image: saleOffer.company.image,
    },
    origin: {
      id: saleOffer.origin.id,
      name: saleOffer.origin.name,
      city: saleOffer.origin.city,
      uf: saleOffer.origin.uf,
      type: saleOffer.origin.type,
    },
    destiny: {
      id: saleOffer.destination.id,
      name: saleOffer.destination.name,
      city: saleOffer.destination.city,
      uf: saleOffer.destination.uf,
      type: saleOffer.destination.type,
    },
    vehicle: {
      type: convertVehicleType(saleOffer.vehicleType),
      category: convertVehicleCategory(saleOffer.category),
    },
    capacity: {
      weight: saleOffer.offeredWeight,
      pallet: saleOffer.offeredPallet,
      cubage: saleOffer.offeredCubage,
    },
    freightValue: saleOffer.freightValue,
    invoicedBy: saleOffer.invoicedBy,
    saleOfferTariffId: saleOffer.saleOfferTariffId,
    scheduleType: saleOffer.scheduleType,
    operationType: saleOffer.operationType,
    co2Economy: saleOffer.co2Economy,
  };
};
