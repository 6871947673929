import styled, { css } from 'styled-components';

interface IPageProps {
  activePage: boolean;
}

export const PageStyled = styled.p<IPageProps>`
  ${({ theme, activePage }) => css`
    font-family: ${theme.font.family};
    color: ${activePage ? '#12264e' : '#4b4b4b'};
    font-style: normal;
    font-weight: ${activePage ? '700' : '500'};
    font-size: 12px;
    line-height: 18px;
    margin-right: 12px;
    cursor: pointer;
    z-index: ${theme.layers.modal};
  `}
`;

export const ContainerPageStyled = styled.div`
  display: flex;
  align-items: center;
`;
