import dayjs from 'dayjs';
import { FC, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsResumeOriginAndDestinationView } from './schedule-details-resume-origin-and-destination-view.component';

export const ScheduleDetailsResumeOriginAndDestination: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const convertDates = useCallback((date: string) => {
    if (!date) return '';

    const converting = dayjs(date).format('DD/MM/YYYY HH:mm');
    return converting;
  }, []);

  return (
    <ScheduleDetailsResumeOriginAndDestinationView
      origin={scheduleDetails.scheduleDetails.origin}
      destination={scheduleDetails.scheduleDetails.destination}
      detail={scheduleDetails.scheduleDetails.detail}
      convertDates={convertDates}
    />
  );
};
