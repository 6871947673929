import React, { Fragment, useCallback, useMemo } from 'react';
import * as S from './execution-documents-view.styled';
import { Accordion } from 'src-new/components/accordion/accordion.component';
import { AccordionContentType } from 'src-new/components/accordion/accordion.types';
import { DocumentsNFe } from './components/documents-nfe/documents-nfe.component';
import { DocumentsCTe } from './components/documents-cte/documents-cte.component';
import { Computer, Paperclip, Smartphone } from 'lucide-react';
import { Button } from 'logshare-ui-kit';
import { DocumentsMDFe } from './components/documents-mdfe/documents-mdfe.component';
import { DocumentsOthers } from './components/documents-others/documents-others.component';
import { InputFile } from 'components/input-file';
import {
  IScheduleDocuments,
  ScheduleDocumentsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { DocumentsModalsDelete } from './components/documents-modals-delete/documents-modals-delete.component';
import { DocumentsChecklist } from './components/documents-checklist/documents-checklist.component';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { ModalForm } from 'components/modal-form/modal-form';
import { Formik } from 'formik';
import { ModalFormChecklist } from 'components-v2/common/modals/checklist';
import { ChecklistAnswer } from 'domain-v2/checklist';
import { DropdownButtonTable } from 'src-new/components/dropdown-button-table/dropdown-button-table.component';
import { IModalDelete } from './execution-documents.component';

interface IModal {
  open: boolean;
  isEdit: boolean;
}
export interface ExecutionDocumentsViewProps {
  scheduleDocuments: IScheduleDocuments;
  indexToOpenAccordion: number;
  openModalLink: boolean;
  openModal: IModal;
  openDeleteModal: boolean;
  linkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  mobileLink: React.ReactNode;
  handleUploadFile: (files: FileList, documentType: ScheduleDocumentsModalType) => void;
  handleGetQuestions: () => void;
  setOpenModalLink: (value: boolean) => void;
  setOpenModal: (value: IModal) => void;
  handleCopyLink: () => void;
  handleChecklistSubmit: (checklistAnswer: ChecklistAnswer) => Promise<void>;
  handleVisible: (id: number) => void;
  handleDelete: () => void;
  setShowDeleteModal: (value: IModalDelete) => void;
}

export const ExecutionDocumentsView: React.FC<ExecutionDocumentsViewProps> = ({
  scheduleDocuments,
  indexToOpenAccordion,
  openModalLink,
  openModal,
  linkRef,
  mobileLink,
  openDeleteModal,
  handleDelete,
  handleGetQuestions,
  handleVisible,
  handleUploadFile,
  handleCopyLink,
  setOpenModalLink,
  setOpenModal,
  handleChecklistSubmit,
  setShowDeleteModal,
}) => {
  const button = useCallback(
    (
      callback: (files: FileList, documentType: ScheduleDocumentsModalType) => void,
      documentType: ScheduleDocumentsModalType,
    ) => {
      return (
        <InputFile name={documentType} onChange={(files: FileList) => callback(files, documentType)} multiple>
          <Button
            label="Anexar"
            color="white"
            variant="ghost"
            LeftIcon={<Paperclip size={16} strokeWidth={2} />}
            size="medium"
          />
        </InputFile>
      );
    },
    [],
  );

  const mountAccordions = useMemo((): Array<AccordionContentType> => {
    return [
      {
        title: `Checklist ( ${scheduleDocuments.checklistDocumentsProps.checklistDocuments.length} )`,
        content: <DocumentsChecklist handleVisible={handleVisible} setShowDeleteModal={setShowDeleteModal} />,
        buttonOpen: true,
        button: (
          <S.DropdownButtonTable>
            <DropdownButtonTable
              options={[
                {
                  label: 'Computador',
                  onClick: () => {
                    handleGetQuestions();
                    setOpenModal({ open: true, isEdit: false });
                  },
                  icon: <Computer width={18} color="gray" />,
                },
                {
                  label: 'Celular',
                  onClick: () => {
                    handleGetQuestions();
                    setOpenModalLink(true);
                  },
                  icon: <Smartphone width={18} color="gray" />,
                },
              ]}
              iconPosition="horizontal"
            />
          </S.DropdownButtonTable>
        ),
        checked: false,
      },
      {
        title: `Notas Fiscais ( ${scheduleDocuments.nfeDataProps.nfeList.length} )`,
        content: <DocumentsNFe />,
        buttonOpen: true,
        button: button(handleUploadFile, 'nfe'),
        checked: false,
      },
      {
        title: `Conhecimento de Transporte Eletrônico ( ${scheduleDocuments.cteDataProps.cteList.length} )`,
        content: <DocumentsCTe />,
        buttonOpen: true,
        button: button(handleUploadFile, 'cte'),
        checked: false,
      },
      {
        title: `Manifesto Eletrônico de Documentos Fiscais ( ${scheduleDocuments.mdfeDataProps.mdfeList.length} )`,
        content: <DocumentsMDFe />,
        buttonOpen: true,
        button: button(handleUploadFile, 'mdfe'),
        checked: false,
      },
      {
        title: `Registros e Comprovantes ( ${scheduleDocuments.othersDocumentsProps.otherDocumentsList.length} )`,
        content: <DocumentsOthers />,
        buttonOpen: true,
        button: button(handleUploadFile, 'othersDocuments'),
        checked: false,
      },
    ];
  }, [
    button,
    handleGetQuestions,
    handleUploadFile,
    handleVisible,
    scheduleDocuments.checklistDocumentsProps.checklistDocuments.length,
    scheduleDocuments.cteDataProps.cteList.length,
    scheduleDocuments.mdfeDataProps.mdfeList.length,
    scheduleDocuments.nfeDataProps.nfeList.length,
    scheduleDocuments.othersDocumentsProps.otherDocumentsList.length,
    setOpenModal,
    setOpenModalLink,
    setShowDeleteModal,
  ]);

  const buildModalLink = useMemo(() => {
    return (
      <ModalConfirmation
        title="Link Gerado"
        description={
          <S.InfoLink ref={linkRef} onClick={handleCopyLink}>
            {mobileLink}
          </S.InfoLink>
        }
        size="small"
        type="normal"
        buttonTitleConfirmation="Copiar"
        handleXCancel={() => setOpenModalLink(false)}
        onConfirmation={handleCopyLink}
      />
    );
  }, [handleCopyLink, linkRef, mobileLink, setOpenModalLink]);

  const buildModal = useMemo(() => {
    return (
      <ModalForm
        title="Checklist"
        description={
          <Formik
            initialValues={scheduleDocuments.checklistAnswerProps.checklistAnswer}
            onSubmit={handleChecklistSubmit}
          >
            <ModalFormChecklist isEdit={openModal.isEdit} />
          </Formik>
        }
        handleCancel={() => {
          setOpenModal({ open: false, isEdit: false });
        }}
        size="large"
      />
    );
  }, [scheduleDocuments, handleChecklistSubmit, openModal.isEdit, setOpenModal]);

  const buildOpenDeleteModal = useMemo(() => {
    return (
      <ModalConfirmation
        secondTitle={
          <>
            <p>Tem certeza que deseja excluir o Checklist?</p>
            <p>Essa ação não poderá ser desfeita.</p>
          </>
        }
        title="Exclusão de Checklist"
        size="small"
        type="delete"
        handleCancel={() => setShowDeleteModal({ id: 0, open: false })}
        onConfirmation={handleDelete}
        description={undefined}
      />
    );
  }, [handleDelete, setShowDeleteModal]);

  return (
    <Fragment>
      {openModalLink && buildModalLink}
      {openModal.open && buildModal}
      {openDeleteModal && buildOpenDeleteModal}
      <DocumentsModalsDelete />
      <S.WrapperAccordions>
        <Accordion contents={mountAccordions} indexToOpenAccordion={indexToOpenAccordion} />
      </S.WrapperAccordions>
    </Fragment>
  );
};
