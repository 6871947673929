import styled, { css } from 'styled-components';
import { Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `}
`;

export const WrapperFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperInputStyled = styled.div`
  ${({ theme }) => css`
    width: 50vw;
    text-align: center;
    color: ${theme.colorsV4.white};
    font-size: ${theme.fontV2.sizes.text4};
    margin: 20px 0;

    div {
      width: 50vw;
      margin-top: 5px;
    }
  `}
`;

export const WrapperLine = styled.div`
  width: 70vw;
  border-bottom: 1px solid white;
  margin: 5px 0;
`;

export const ButtonSubmit = styled.div`
  width: 50vw;
  display: flex;
  margin: 5vh;
`;

export const Keyboard = styled.div`
  width: 90vw;
  display: flex;
  margin-bottom: 10vh;
`;

export const GoBack = styled.div`
  color: white;
  margin: 5vh;
  cursor: pointer;
`