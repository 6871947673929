import Checkbox from 'components/checkbox/checkbox';
import React, { Fragment, useCallback, useMemo } from 'react';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import { ISaleOfferListDomain } from '../../../../domains/sale-offer-list.domain';
import * as S from './salle-offer-table-view.styled';
import { Bell } from 'components-v2/common/bell';
import { Tag } from 'logshare-ui-kit';
import { convertOperationType } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-table-tabs/components/schedule-table/schedule-table.types';
import TooltipComponent from 'components/Tooltip';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

interface ISaleOfferLisViewProps {
  saleOffer: Array<ISaleOfferListDomain>;
  isAdmin: boolean;
  orderingColumns: IOrdering;
  isLoading: boolean;
  isEmpty: boolean;
  selectedIds: Array<number>;
  infiniteScrollProps: IInfiniteScrollTable;
  handleLineClick: (id: number) => void;
  handleSelectIds: (checked: boolean, salesOffersTariffsId: number) => void;
  handleOpenModalMatch: (saleOfferProposal: ISaleOfferListDomain) => void;
  handleSaleOfferCopy: (saleOfferProposal: ISaleOfferListDomain) => void;
}

export const SaleOfferTableView: React.FC<ISaleOfferLisViewProps> = ({
  saleOffer,
  isAdmin,
  orderingColumns,
  isLoading,
  isEmpty,
  selectedIds,
  infiniteScrollProps,
  handleLineClick,
  handleSelectIds,
  handleOpenModalMatch,
  handleSaleOfferCopy,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: isAdmin && 'Empresa' },
      { value: 'Tipo' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Tipo de Veículo' },
      { value: 'Parceiro(s)' },
      { value: 'Frete Valor' },
      { value: '' },
      { value: 'Matches' },
    ];

    const columnsConfig = isAdmin
      ? '0.2fr 0.3fr 0.8fr 0.6fr 1.5fr 1.5fr 1fr 1.4fr 0.75fr 0.25fr 0.5fr'
      : '0.2fr 0.3fr 0fr 0.6fr 1.5fr 1.5fr 1fr 1.4fr 0.75fr 0.25fr 0.5fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, [isAdmin]);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title?.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle?.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return saleOffer.map((offer) => {
      return {
        key: offer.id,
        onClick: () => handleLineClick(offer.id),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-${offer.id}`}
                id={'id'}
                name={''}
                onChange={(event) => handleSelectIds(event.target.checked, offer.idTariff)}
                checked={selectedIds.includes(offer.idTariff)}
              />
            ),
            isClicable: false,
          },
          { value: offer.idTariff },
          { value: renderTitleSubtitle(offer.companyName, '') },
          {
            value: (
              <div>
                <Tag
                  label={offer.operationType}
                  color={convertOperationType(offer.operationType).color}
                  variant="ghost"
                  display="inline-flex"
                  size="md"
                />
              </div>
            ),
          },
          {
            value: renderTitleSubtitle(offer.origin.name, `${offer.origin.address.uf} - ${offer.origin.address.city}`),
          },
          {
            value: renderTitleSubtitle(
              offer.destination.name,
              `${offer.destination.address.uf} - ${offer.destination.address.city}`,
            ),
          },
          {
            value: renderTitleSubtitle(offer.vehicle.type, offer.vehicle.category),
          },
          {
            value: renderTitleSubtitle(offer.partnerShipper, offer.shippingName),
          },
          {
            value: renderTitleSubtitle(offer.valueTotal, 'sem impostos'),
          },
          {
            value: (
              <TooltipComponent
                title="Duplicar Oferta"
                location="left"
                icon={
                  <ContentCopyRoundedIcon
                    sx={{ width: 18.5, height: 18.5 }}
                    htmlColor="grey"
                    onClick={() => handleSaleOfferCopy(offer)}
                  />
                }
              />
            ),
            isClicable: false,
          },
          {
            value: <Bell id={offer.id} count={offer.totalMatchOffers} onClick={() => handleOpenModalMatch(offer)} />,
            isClicable: false,
          },
        ],
      };
    });
  }, [
    handleLineClick,
    handleOpenModalMatch,
    handleSaleOfferCopy,
    handleSelectIds,
    renderTitleSubtitle,
    saleOffer,
    selectedIds,
  ]);

  if (isLoading)
    return (
      <S.Wrapper>
        <Loading />
      </S.Wrapper>
    );

  if (isEmpty)
    return (
      <S.EmptyListWrapper>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.EmptyListWrapper>
    );

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={orderingColumns}
      rows={renderRows}
      height={'77vh'}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
