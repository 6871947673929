import styled, { css } from 'styled-components';

interface IStyledProps {
  isOpen: boolean;
}

export const Wrapper = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    background-color: ${theme.colorsV4.white};
    border: 1px solid #c6c6c6;
    border-radius: 0.375rem;
    padding: 1rem 1.5rem;

    overflow: hidden;
  `}
`;

export const Summary = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 32px;
    margin-bottom: 5px;
    background-color: ${theme.colorsV4.white};
  `}
`;

export const SummaryTitle = styled.h5`
  ${({ theme }) => css`
    font-weight: 600;
    color: ${theme.colorsV4.black};
    text-transform: none;
  `}
`;

export const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Expander = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.1rem;

  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
    border-radius: 99999px;
  }
`;

export const Details = styled.div<IStyledProps>`
  ${({ isOpen }) => css`
    display: ${isOpen ? '' : 'none'};
  `}
`;
