import { ShippingReturnEnum } from 'domain/global-inputs';
import { VehicleCategoryEnum, VehicleTypeEnum } from 'domain/global';
import { IResumeInfo } from 'components/resume/resume-info.types';

export const rulesResumeMaps: IResumeInfo = {
  principalHeader: 'Resumo do Frete',
  description: 'Operado por Logshare',
  sectionHeader: [
    'Origem',
    'Destino',
    'Detalhes da Carga',
    'Dados do Agendamento',
    'Veículo',
    'Motorista',
    'Transportadora',
    'Economia de CO2',
    'Frete Previsto',
  ],
  sectionHeaderName: [
    'origin',
    'destiny',
    'scheduleCargo',
    'scheduleDetails',
    'vehicle',
    'driver',
    'shippingCompany',
    'co2Data',
    'shippingFixed',
  ],
  excludeFields: [
    'clientId',
    'localityId',
    'id',
    'internalCode',
    'companyId',
    'status',
    'fantasyName',
    'ie',
    'lng',
    'lat',
    'quality_and_safety',
  ],
  describes: [],
  enumerables: [
    {
      field: 'localityType',
      values: [
        {
          name: 'Centro de Distribuição',
          value: 'distribution_center',
        },
        {
          name: 'Loja',
          value: 'shop',
        },
        {
          name: 'Hub',
          value: 'hub',
        },
        {
          name: 'Outros',
          value: 'others',
        },
      ],
    },
    {
      field: 'type',
      values: [
        {
          name: '',
          value: 'distribution_center',
        },
        {
          name: '',
          value: 'shop',
        },
        {
          name: '',
          value: 'hub',
        },
        {
          name: '',
          value: 'others',
        },
      ],
    },
    {
      field: 'clientType',
      values: [
        {
          name: '',
          value: 'client',
        },
        {
          name: '',
          value: 'provider',
        },
        {
          name: '',
          value: 'partner',
        },
      ],
    },
    {
      field: 'inPalet',
      values: [
        {
          name: 'Paletizado',
          value: 'yes',
        },
        {
          name: 'Não Paletizado',
          value: 'no',
        },
      ],
    },
    {
      field: 'needBodyGuard',
      values: [
        {
          name: 'Escolta - Com Escolta',
          value: 'yes',
        },
        {
          name: 'Escolta - Sem Escolta',
          value: 'no',
        },
      ],
    },
    {
      field: 'needHelper',
      values: [
        {
          name: 'Ajudante - No Carregamento',
          value: 'carregamento',
        },
        {
          name: 'Ajudante - Na Descarga',
          value: 'descarga',
        },
        {
          name: 'Ajudante - Ambos',
          value: 'ambos',
        },
      ],
    },

    {
      field: 'operationType',
      values: [
        { value: 'delivery', name: 'Tipo de Operação - Entrega' },
        { value: 'collect', name: 'Tipo de Operação - Coleta' },
        { value: 'transfer', name: 'Tipo de Operação - Transferência' },
        { value: 'displacement', name: 'Tipo de Operação - Deslocamento' },
        { value: 'devolution', name: 'Tipo de Operação - Devolução' },
      ],
    },
    {
      field: 'vehicleType',
      values: [
        {
          value: VehicleTypeEnum.UTILITARIO,
          name: '',
        },
        { value: VehicleTypeEnum.VAN, name: '' },
        { value: VehicleTypeEnum.VUC, name: '' },
        { value: VehicleTypeEnum.TOCO, name: '' },
        { value: VehicleTypeEnum.TRUCK, name: '' },
        { value: VehicleTypeEnum.BITRUCK, name: '' },
        { value: VehicleTypeEnum.CARRETA, name: '' },
        { value: VehicleTypeEnum.RODOTREM, name: '' },
        { value: VehicleTypeEnum.TRES_QUARTO, name: '' },
      ],
    },
    {
      field: 'vehicleCategoryType',
      values: [
        {
          value: VehicleCategoryEnum.BAU_SECO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_FRIGORIFICADO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_REFRIGERADO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_SIDER,
          name: '',
        },
        {
          value: VehicleCategoryEnum.CACAMBA_BASCULANTE,
          name: '',
        },
        { value: VehicleCategoryEnum.CEGONHA, name: '' },
        {
          value: VehicleCategoryEnum.FLORESTAL,
          name: '',
        },
        {
          value: VehicleCategoryEnum.GRADE_BAIXA,
          name: '',
        },
        {
          value: VehicleCategoryEnum.GRANELEIRO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PLATAFORMA,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PORTA_CONTAINER_20,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PORTA_CONTAINER_40,
          name: '',
        },
        { value: VehicleCategoryEnum.TANQUE, name: '' },
      ],
    },

    {
      field: 'shippingReturn',
      values: [
        {
          value: ShippingReturnEnum.RETORNA_VAZIO,
          name: 'Retorno - Retorna Vazio',
        },
        {
          value: ShippingReturnEnum.RETORNA_CARREGADO,
          name: 'Retorno - Retorna Carregado',
        },
        { value: ShippingReturnEnum.NAO_RETORNA, name: 'Retorno - Não Retorna' },
      ],
    },
  ],
  rules: [
    {
      fields: ['zipCode'],
      value: 'CEP: {zipCode}',
      ruleIndex: 1,
    },
    {
      fields: ['street', 'numberHouse'],
      value: '{street}, {numberHouse}',
      ruleIndex: 2,
    },
    {
      fields: ['neighborhood', 'city', 'uf'],
      value: '{neighborhood} - {city} - {uf}',
      ruleIndex: 3,
    },
    {
      fields: ['firstPhone'],
      value: 'Telefone Principal: {firstPhone}',
      ruleIndex: 3,
    },
    {
      fields: ['secondPhone'],
      value: 'Telefone Secundário: {secondPhone}',
      ruleIndex: 3,
    },
    {
      fields: ['driverDocument'],
      value: 'CPF: {driverDocument}',
      ruleIndex: 3,
    },
    {
      fields: ['levyInitialDate', 'levyEndDate', 'levyInitialTime', 'levyEndTime'],
      value: 'Coleta {levyInitialDate} a {levyEndDate} - {levyInitialTime} às {levyEndTime}',
      ruleIndex: 1,
    },
    {
      fields: ['deliveryInitialDate', 'deliveryEndDate', 'deliveryInitialTime', 'deliveryEndTime'],
      value: 'Entrega {deliveryInitialDate} a {deliveryEndDate} - {deliveryInitialTime} às {deliveryEndTime}',
      ruleIndex: 2,
    },
    {
      fields: ['internalNumber'],
      value: '',
      ruleIndex: 3,
    },
    {
      fields: ['ocInternalNumber'],
      value: '',
      ruleIndex: 4,
    },
    {
      fields: ['boardingNumber', 'schedulingPassword'],
      value: '',
      ruleIndex: 4,
      format: 'decimal',
    },
    {
      fields: ['weight'],
      value: 'Peso - {weight} Kg',
      ruleIndex: 1,
      format: 'decimal',
    },
    {
      fields: ['capacity'],
      value: 'Volumes - {capacity}',
      ruleIndex: 1,
      format: 'decimal',
    },
    {
      fields: ['shippingValue'],
      value: 'Valor da Mercadoria - {shippingValue}',
      ruleIndex: 2,
      format: 'price',
    },
    {
      fields: ['inPalet', 'pallet'],
      value: '{inPalet} {pallet}',
      ruleIndex: 3,
    },
    {
      fields: ['invoice'],
      value: '',
      ruleIndex: 4,
    },

    {
      fields: ['distance'],
      value: 'Distância: {distance} Km',
      ruleIndex: 1,
    },
    {
      fields: ['co2Economy'],
      value: 'Economia de CO2: {co2Economy} Kg',
      ruleIndex: 1,
    },

    {
      fields: ['freightValue'],
      moreThanOneColumns: true,
      value: 'Frete Valor: {freightValue}',
      ruleIndex: 2,
    },
    {
      fields: ['freightHelperValue'],
      moreThanOneColumns: true,
      value: 'Ajudante: {freightHelperValue}',
      ruleIndex: 2,
    },
    {
      fields: ['tollValue'],
      moreThanOneColumns: true,
      value: 'Pedágio: {tollValue}',
      ruleIndex: 3,
    },
    {
      fields: ['grisAdvalorem'],
      moreThanOneColumns: true,
      value: 'Gris/Advalorem: {grisAdvalorem}',
      ruleIndex: 4,
    },
    {
      fields: ['taxValue'],
      moreThanOneColumns: true,
      value: 'Imposto Estimado: {taxValue}',
      ruleIndex: 5,
    },
    {
      fields: ['totalFreight'],
      moreThanOneColumns: true,
      value: 'Frete Total: {totalFreight}',
      ruleIndex: 6,
    },
  ],
};
