import { convertDecimalUSFormat } from 'utils-v2/converters/decimal';
import { convertTypeDomainToEntityMapper } from '../convert-type-domain-to-entity/convert-type-domain-to-entity.mapper';

import { ICompany, IRetention } from 'domain/company';

export const contactTypes = ['compras', 'logistica', 'contratacao_frete', 'torre_controle', 'financeiro', 'comercial'];

export const converterCompanyDomainToFormData = (
  company: ICompany,
): { formData: FormData; retentionFines: Array<IRetention> | undefined } => {
  const formData = new FormData();
  formData.append('name', company.name);
  formData.append('tradeName', company.tradeName);
  formData.append('cnpj', company.cnpj);
  formData.append('email', company.email);
  formData.append('login', company.login);
  formData.append('ie', company.ie);
  formData.append('type', convertTypeDomainToEntityMapper(company.type));
  formData.append('status', company.status);
  formData.append('plan', company.plan);
  formData.append('cep', company.cep);
  formData.append('city', company.city);
  formData.append('neighborhood', company.neighborhood);
  formData.append('street', company.street);
  formData.append('numberHouse', company.numberHouse);
  formData.append('complement', company.complement);
  formData.append('uf', company.uf);
  formData.append('paymentTerm', company.paymentTerm);
  formData.append('modalityPayment', company.modalityPayment);
  formData.append('receiptDeadline', company.receiptDeadline);
  formData.append('modalityDeadline', company.modalityDeadline);
  formData.append('closingCycle', company.closingCycle);
  formData.append('companysId', JSON.stringify(company.companysId));
  formData.append('feeLogshare', company.feeLogshare?.replace(',', '.') ?? '');
  formData.append('feeLogshareWithoutCTe', company.feeLogshareWithoutCTe?.replace(',', '.') ?? '');
  formData.append('advalorem', company.advalorem?.replace(',', '.'));
  formData.append('gris', company.gris?.replace(',', '.'));
  formData.append('file', company.file as Blob);
  formData.append('hasDdr', String(company.hasDdr));
  formData.append('riskManager', company.riskManager);

  formData.append('shippingCompanyId', company.shippingCompanyId ? String(company.shippingCompanyId) : '');

  company?.contacts?.map((contact, index) => {
    formData.append(`contacts[${index}][name]`, contact.name.toLowerCase() ?? '');
    formData.append(`contacts[${index}][email]`, contact.email.toLowerCase() ?? '');
    formData.append(`contacts[${index}][telephone]`, contact.telephone.toLowerCase() ?? '');
    formData.append(`contacts[${index}][type]`, contactTypes[index].toLowerCase() ?? '');
  });

  const retentionFines: Array<IRetention> | undefined = company.retentionFines?.map((fines) => {
    const hourlyFineFormatted = isNaN(fines.hourlyFine)
      ? convertDecimalUSFormat(String(fines.hourlyFine))
      : fines.hourlyFine;
    const dailyFineFormatted = isNaN(fines.dailyFine)
      ? convertDecimalUSFormat(String(fines.dailyFine))
      : fines.dailyFine;

    return {
      id: fines.id,
      companyId: fines.companyId,
      freeTime: fines.freeTime,
      hourlyFine: hourlyFineFormatted,
      dailyFine: dailyFineFormatted,
      vehicleType: fines.vehicleType,
      vehicleCategory: fines.vehicleCategory,
      created_at: fines.created_at,
    };
  });

  return { formData, retentionFines };
};
