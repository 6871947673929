import { removeInputMaskDomainToEntity } from 'utils/remove-input-mask/remove-input-mask';
import API from 'utils/API/API';
import { IModalTerms, ILastTerms } from 'pages/login/services/entities/login.entity';

export const userTermsConditions = async (username: string) => {
  return await API.get(`v2/user-terms/login/${username}`);
};

export const lastTermsConditions = async (): Promise<ILastTerms> => {
  const { data } = await API.get('v2/terms/last');
  return data;
};

export const termsConditionsRegister = async (values: IModalTerms) => {
  const cpf = values?.userCpf ? removeInputMaskDomainToEntity(values?.userCpf) : 'nao_encontrado';
  await API.post('v2/user-terms', {
    ...values,
    userCpf: cpf,
  });
};
