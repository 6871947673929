import { useFormikContext } from 'formik';
import { FC } from 'react';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { WeighingFormMountShippingView } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-shipping/weighing-register-form-mount-shipping-view.component';

interface IWeighingFormMountShippingProps {
  hasError: boolean;
}

export const WeighingFormMountShipping: FC<IWeighingFormMountShippingProps> = ({ hasError }) => {
  const { values, setFieldValue } = useFormikContext<IWeighingFormRegisterDomain>();

  return <WeighingFormMountShippingView values={values} hasError={hasError} setFieldValue={setFieldValue} />;
};
