import { VehicleCategoryDomain, VehicleCategoryResponse } from 'src-new/domains/vehicle-category.domain';

const defaultValue = '';

export const convertVehicleCategory = (vehicleType: VehicleCategoryResponse): VehicleCategoryDomain => {
  const vehicleTypeToLower = vehicleType?.toLowerCase() as VehicleCategoryResponse;
  const vehicleCategoryMap: Record<VehicleCategoryResponse, VehicleCategoryDomain> = {
    bau_seco: 'Baú Seco',
    bau_frigorificado: 'Baú Frigorificado',
    bau_refrigerado: 'Baú Refrigerado',
    bau_sider: 'Sider',
    cacamba_basculante: 'Caçamba Basculante',
    cegonha: 'Cegonha',
    complemento: 'Complemento',
    florestal: 'Florestal',
    grade_baixa: 'Grade Baixa',
    graneleiro: 'Graneleiro',
    plataforma: 'Plataforma',
    porta_container: 'Porta Container 20',
    porta_container_20: 'Porta Container 20',
    porta_container_40: 'Porta Container 40',
    porta_container40: 'Porta Container 40',
    porta_container2: 'Porta Container 40',
    tanque: 'Tanque',
    invalid: 'NÃO MAPEADO',
    '': '',
  };

  return vehicleCategoryMap[vehicleTypeToLower] || defaultValue;
};

export const convertVehicleCategoryToEntity = (vehicleType: VehicleCategoryDomain): VehicleCategoryResponse => {
  const vehicleCategoryMap: Record<VehicleCategoryDomain, VehicleCategoryResponse> = {
    'Baú Seco': 'bau_seco',
    'Baú Frigorificado': 'bau_frigorificado',
    'Baú Refrigerado': 'bau_refrigerado',
    Sider: 'bau_sider',
    'Caçamba Basculante': 'cacamba_basculante',
    Cegonha: 'cegonha',
    Complemento: 'complemento',
    Florestal: 'florestal',
    'Grade Baixa': 'grade_baixa',
    Graneleiro: 'graneleiro',
    Plataforma: 'plataforma',
    'Porta Container 20': 'porta_container_20',
    'Porta Container 40': 'porta_container_40',
    Tanque: 'tanque',
    '': '',
    'NÃO MAPEADO': 'invalid',
  };

  return vehicleCategoryMap[vehicleType] || defaultValue;
};
