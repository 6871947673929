import { locationUpdateService } from 'pages/location/services/location-update/location-update.service';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { useManageAttachmentsEdit } from 'src-new/pages/registrations/hooks/use-manage-attachments-edit/use-manage-attachments-edit.hook';

const useUpdateTransportHook = () => {
  const manageAttachmentsEdit = useManageAttachmentsEdit();

  return async (location: ILocationDomain) => {
    await manageAttachmentsEdit(location?.id ?? 0, 'location', location.originalAttachments, location.attachments);
    await locationUpdateService(location);
  };
};

export { useUpdateTransportHook };
