import React, { Fragment, ReactElement, ReactNode, useMemo } from 'react';
import {
  ClearButtonStyled,
  ModalCloseButtonStyled,
  FooterTextStyled,
  FilterModalContentStyled,
  FilterModalFooterStyled,
  FilterModalHeaderStyled,
  FilterModalWrapperStyled,
  OverlayStyled,
  Title,
} from './filter-modal-view.styled';
import { AiOutlineClose } from 'react-icons/ai';

interface IFilterModalViewProps {
  isOpen: boolean;
  filterCount: number;
  onCloseButton: () => void;
  onClearButton: () => void;
  children: ReactNode;
}

export const FilterModalView: React.FC<IFilterModalViewProps> = ({
  isOpen,
  filterCount,
  onCloseButton,
  onClearButton,
  children,
}): ReactElement => {
  const isDisabledClearButton = useMemo(() => {
    return filterCount === 0;
  }, [filterCount]);

  const renderCountFilters = useMemo(() => {
    return isDisabledClearButton ? 'Nenhum filtro aplicado' : `${filterCount} filtro(s) aplicado(s)`;
  }, [filterCount, isDisabledClearButton]);

  const renderOverlay = useMemo((): ReactElement | undefined => {
    if (isOpen) {
      return <OverlayStyled />;
    }
  }, [isOpen]);

  return (
    <Fragment>
      {renderOverlay}
      <FilterModalWrapperStyled isOpen={isOpen}>
        <FilterModalHeaderStyled>
          <Title>Escolha o Filtro</Title>
          <ModalCloseButtonStyled type={'button'} onClick={onCloseButton}>
            <AiOutlineClose />
          </ModalCloseButtonStyled>
        </FilterModalHeaderStyled>
        <FilterModalContentStyled>{children}</FilterModalContentStyled>
        <FilterModalFooterStyled>
          <FooterTextStyled>{renderCountFilters}</FooterTextStyled>
          <ClearButtonStyled
            type={'button'}
            disabled={isDisabledClearButton}
            isDisabled={isDisabledClearButton}
            onClick={onClearButton}
          >
            Limpar
          </ClearButtonStyled>
        </FilterModalFooterStyled>
      </FilterModalWrapperStyled>
    </Fragment>
  );
};
