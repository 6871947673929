import { FC } from 'react';
import { ScheduleFinanceAccordions } from './components/schedule-finance-accordions/schedule-finance-accordions.component';
import * as S from './schedule-finance-view.styled';

export const ScheduleFinanceView: FC = () => {
  return (
    <S.Wrapper>
      <ScheduleFinanceAccordions />
    </S.Wrapper>
  );
};
