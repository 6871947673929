import { useCallback } from 'react';

import { getScheduleFileUrlByIdService } from 'pages/freight/services/schedule-file-url/schedule-file-url.service';

const useGetFileUrlScheduleByIdHook = () => {
  return useCallback(async (scheduleId: string) => {
    return getScheduleFileUrlByIdService(scheduleId);
  }, []);
};

export { useGetFileUrlScheduleByIdHook };
