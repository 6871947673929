import { FC } from 'react';

import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { AccessRestricted } from 'components/access-restricted/indext';

export const FreightPayableShippingPage: FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Financeiro', 'Fretes a Pagar']} />
      <div
        style={{
          margin: 'auto 0',
        }}
      >
        <AccessRestricted />
      </div>
    </>
  );
};
