import { ColorsV2Type } from 'styles/types/colors';
import { Status } from 'domain-v2/inputs/status';

type StatusType = {
  label: string;
  color: ColorsV2Type;
};

type StatusMap = {
  [key: string]: StatusType;
};

const defaultValue: StatusType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertStatus = (status: Status): StatusType => {
  const statusMap: StatusMap = {
    active: {
      label: 'ATIVO',
      color: ColorsV2Type.Green,
    },
    inactive: {
      label: 'INATIVO',
      color: ColorsV2Type.Red,
    },
  };

  return statusMap[status] || defaultValue;
};
