import React, { Fragment, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { IIdlenessHeaderModal } from './types';
import * as S from './styled';
import { HeaderBackhaulModal } from './header';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { Loading } from 'src-new/components/loading/loading.component';

interface IForm {
  idleness?: IIdlenessHeaderModal;
  closeModal: () => void;
  list: ReactNode;
}

export const ModalMatches: React.FC<IForm> = ({ idleness, closeModal, list }) => {
  const [companyImage, setCompanyImage] = useState<string>('');

  const getCompanyHook = useGetCompany();

  const renderHeader = useMemo((): ReactElement | undefined => {
    if (!idleness || !companyImage) return;

    return <HeaderBackhaulModal idleness={idleness} companyImage={companyImage} />;
  }, [companyImage, idleness]);

  const renderModalContent = useMemo((): ReactElement => {
    if (!idleness || !companyImage) {
      return (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      );
    }

    return (
      <Fragment>
        <S.ModalTitle>
          <S.Title>BID - Ofertas Recebidas</S.Title>

          <S.ButtonClose onClick={closeModal}>X</S.ButtonClose>
        </S.ModalTitle>

        {renderHeader}

        <S.ContentWrapper>{list}</S.ContentWrapper>
      </Fragment>
    );
  }, [closeModal, companyImage, idleness, list, renderHeader]);

  useEffect(() => {
    if (!idleness) return;

    (async () => {
      const company = await getCompanyHook(idleness.companyId);

      if (!company || !company.fileKey) return;

      setCompanyImage(company.fileKey);
    })();
  }, [getCompanyHook, idleness]);

  return (
    <S.Wrapper>
      <S.Content>{renderModalContent}</S.Content>
    </S.Wrapper>
  );
};
