import { ILocationTypeEntity } from 'pages/location/services/entities/location.entity';
import { LocationType } from 'domain/location';

export const convertTypeEntityToDomainMapper = (typeEntity: ILocationTypeEntity): LocationType => {
  switch (typeEntity) {
    case 'x-dock':
      return 'ARMAZÉM';
    case 'distribution center':
      return 'CD';
    case 'factory':
      return 'FÁBRICA';
    case 'farm':
      return 'FAZENDA';
    case 'hub':
      return 'HUB';
    case 'shop':
      return 'LOJA';
    case 'port terminal':
      return 'TERMINAL PORTUÁRIO';
    case 'transit point':
      return 'TRANSIT POINT';
    case 'plant':
      return 'USINA';
    default:
      return 'OUTROS';
  }
};
