import styled, { css } from 'styled-components';

interface PropsStyle {
  display?: 'flex' | 'grid';
  justify?: 'center' | 'space-between' | 'flex-end';
  direction?: 'row' | 'column';
  columns?: string;
  isTraced?: boolean;
  width?: number;
  gap?: number;
  border?: boolean;
  padding?: boolean;
  upperCase?: boolean;
}

export const Wrapper = styled.div`
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ButtonContainer = styled.div`
  ${() => css`
    display: flex;
    justify-content: flex-end;
    padding-top: 35px;
  `}
`;

export const SectionContainer = styled.div<PropsStyle>`
  ${({ columns, display, justify, direction, width }) => css`
    display: ${display};
    grid-template-columns: ${columns};
    justify-content: ${justify ?? ''};
    flex-direction: ${direction};
    grid-gap: 15px;
    width: ${width}%;
    align-items: center;
    font-size: 12px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const InfoTitle = styled.span`
  flex-direction: column;
  padding-bottom: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleContainer = styled.div`
  flex-direction: column;
  padding-bottom: 10px;

  span {
    color: red;
    font-size: 14px;
  }
`;

export const Info = styled.span<PropsStyle>`
  ${({ upperCase = true }) => css`
    display: flex;
    font-weight: 700;
    padding: 5px 0;
    text-transform: ${upperCase ? 'uppercase' : ''};
  `}
`;

export const DescriptionTitle = styled.div<PropsStyle>`
  ${({ theme, display, justify, isTraced = false }) => css`
    display: ${display};
    justify-content: ${justify ?? ''};
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 10px;
    color: ${theme.colors.black};
    padding-bottom: 5px;
    ${isTraced &&
    css`
      border-bottom: 1px solid ${theme.colors.forBorder};
      padding-bottom: 15px;
      margin-bottom: 15px;
    `}
  `}
`;
