import React from 'react';

import TransportCompaniesContextProvider from '../contexts/table-states/transport-companies.context';

import { TransportCompanyListBody } from './transport-company-list-body/transport-company-list-body';

const TransportCompanyPage: React.FC = () => {
  return (
    <TransportCompaniesContextProvider>
      <TransportCompanyListBody />
    </TransportCompaniesContextProvider>
  );
};

export default TransportCompanyPage;
