import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
  `}
`;
