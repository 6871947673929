import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const ContainerCRLVAndVehicleRegisterStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ContainerCRLVStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 10%;
  max-width: 20%;
`;

export const ContainerVehiclePhotoRegisterStyled = styled.div`
  display: flex;
  flex: 0 0 83%;
  max-width: 83%;
  flex-direction: column;
`;

export const UploadImageStyled = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 170px;
    height: 170px;
  }
`;

export const OptionStyled = styled.option``;

export const ConatinerMultiSelectStyled = styled.div`
  margin-top: 10px;
`;
