import { includes } from 'lodash';
import { MatchesKanbanListActionType } from '../matches.action';
import { IMatchesActions, IMatchesContext } from '../types/matches.types';
import { IMatchesKanbanListActions } from '../types/matches-kanban.types';
import { matchesKanbanListReducer } from './matches-kanban-list/matches-kanban-list.reducer';

export const MatchesReducer = (state: IMatchesContext, action: IMatchesActions): IMatchesContext => {

  if (includes(Object.values(MatchesKanbanListActionType), action.type)) {
    return matchesKanbanListReducer(state, action as IMatchesKanbanListActions);
  }

  return state;
};
