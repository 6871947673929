import { FooterPage } from 'components-v2/common/footer-page';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import MultiSelect from 'components/multi-select/multi-select';
import Select from 'components/select/select';
import { FormikErrors, FormikTouched } from 'formik';
import { Button } from 'logshare-ui-kit';
import { ChangeEvent, FC, Fragment, useMemo } from 'react';
import SectionFormDivider from 'src-new/components/section-form-divider/section-form-divider';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { ISaleOfferModals } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-modals.types';
import {
  cargoTypeOptions,
  prohibitedCargoOptions,
} from '../../../../domains/sale-offer-form-register-initial-values.domain';
import { ISaleOfferFormRegisterPayloadDomain } from '../../../../domains/sale-offer-form-register.domain';
import { SaleOfferFormMountLocations } from './components/sale-offer-register-form-mount-locations/sale-offer-register-form-mount-locations.component';
import { SaleOfferFormMountSection } from './components/sale-offer-register-form-mount-section/sale-offer-register-form-mount-section.component';
import { SaleOfferRegisterShipperFormModal } from './components/sale-offer-register-shipper-form-modal/sale-offer-register-shipper-form-modal.component';
import { SaleOfferRegisterTariffFormModal } from './components/sale-offer-register-tariff-form-modal/sale-offer-register-tariff-form-modal.component';
import * as S from './sale-offer-register-form-mount-view.styled';

interface ISaleOfferRegisterFormMountViewProps {
  values: ISaleOfferFormRegisterPayloadDomain;
  errors: FormikErrors<ISaleOfferFormRegisterPayloadDomain>;
  touched: FormikTouched<ISaleOfferFormRegisterPayloadDomain>;
  saleOfferModals: ISaleOfferModals;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<ISaleOfferFormRegisterPayloadDomain>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  handleOpenOfferShipperModal: () => void;
  handleGoBackPage: () => void;
}

export const SaleOfferRegisterFormMountView: FC<ISaleOfferRegisterFormMountViewProps> = ({
  values,
  errors,
  touched,
  saleOfferModals,
  setFieldValue,
  handleChange,
  handleOnSubmit,
  handleOpenOfferShipperModal,
  handleGoBackPage,
}) => {
  const renderOfferShipperModal = useMemo(() => {
    if (saleOfferModals.isOpen && saleOfferModals.modalType === 'offer_shipper') {
      return <SaleOfferRegisterShipperFormModal />;
    }
  }, [saleOfferModals.isOpen, saleOfferModals.modalType]);

  const renderOfferTariffModal = useMemo(() => {
    if (saleOfferModals.isOpen && saleOfferModals.modalType === 'offer_shipper_tariff') {
      return <SaleOfferRegisterTariffFormModal />;
    }
  }, [saleOfferModals.isOpen, saleOfferModals.modalType]);

  return (
    <Fragment>
      {renderOfferShipperModal}
      {renderOfferTariffModal}
      <S.WrapperForm>
        <SectionForm>
          <LineSectionLayout columns={'0.48fr 0.5fr 3fr 1fr'}>
            <InputField
              label="ID da Oferta"
              id="id"
              name="id"
              type="text"
              onChange={handleChange}
              value={''}
              disabled
            />

            <Select
              label="Tipo de Carga *"
              id="cargoType"
              name="cargoType"
              value={values.cargoType}
              setFieldValue={(field, value) => setFieldValue(field, value)}
              options={cargoTypeOptions}
              hasError={!!errors.cargoType && !!touched.cargoType}
              errorMessage={String(errors.cargoType)}
              disabled
            />

            <MultiSelect
              label="Cargas Não Permitidas *"
              options={prohibitedCargoOptions}
              menuPosition="fixed"
              onChange={(event: any) => {
                const notAllowedLoads = event.map((option: any) => option.value);
                setFieldValue('prohibitedCargo', notAllowedLoads);
              }}
              values={prohibitedCargoOptions.filter((option) => values.prohibitedCargo?.includes(option.value))}
              hasError={!!errors.prohibitedCargo && !!touched.prohibitedCargo}
              errorMessage={String(errors.prohibitedCargo)}
            />

            <InputField
              label="Outros"
              id="prohibitedOtherCargo"
              name="prohibitedOtherCargo"
              type="text"
              value={values.prohibitedOtherCargo}
              onChange={handleChange}
              disabled={!values.prohibitedCargo?.includes('Outros')}
            />
          </LineSectionLayout>

          <SectionFormDivider label={'Origem'} hidePinLocation={false} />
          <SaleOfferFormMountLocations type={'origin'} />

          <SectionFormDivider label={'Destino'} hidePinLocation={false} />
          <SaleOfferFormMountLocations type={'destination'} />

          <SectionFormDivider
            label={'Detalhes da Oferta'}
            secondItem={
              <Button
                label="Adicionar Parceiro"
                color="green"
                onPress={handleOpenOfferShipperModal}
                size="md"
                variant="solid"
                disabled
              />
            }
          />
          <SaleOfferFormMountSection />
        </SectionForm>

        <FooterPage.Root withoutTrace>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={false} onPress={handleOnSubmit} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBackPage} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </S.WrapperForm>
    </Fragment>
  );
};
