import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 180px auto;
  margin-top: 20px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-size: 13px;
  `}
`;

export const ContainerScheduleDetails = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
  justify-content: space-between;
`;

export const ContainerScheduleDetailsItem = styled.div`
  display: flex;
  flex: 0 0 45%;
  max-width: 50%;
  margin-top: 20px;
  flex-direction: column;
`;
