import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { getBidOffersListService } from 'src-new/pages/home/pages/home/services/get-bid-offers-list/get-bid-offers-list.service';

export const useGetBidOffersList = () => {
  const { homeBids } = useContext(HomeContext);
  const { setHomeBidsRequest, setHomeBidsSuccess, setHomeBidsError, paginationActions } = homeBids;
  const { nextPage, setNextPage, setPageLimit } = paginationActions;

  return useCallback(async () => {
    setHomeBidsRequest();

    try {
      const data = await getBidOffersListService(nextPage ?? 1);

      if (data.items.length) {
        const nextPageNumber = (nextPage ?? 1) + 1;

        setNextPage(nextPageNumber);

        const homeBidsValues = homeBids.homeBids.concat(data.items)

        setHomeBidsSuccess(homeBidsValues);
      } else {
        setHomeBidsSuccess(homeBids.homeBids);
        setPageLimit();
      }
    } catch (error) {
      setHomeBidsError();
      showMessageFormatted({
        message: 'Erro ao obter a lista de ofertas para bid.',
        type: 'error',
      });
    }
  }, [
    homeBids.homeBids,
    nextPage,
    setHomeBidsError,
    setHomeBidsRequest,
    setHomeBidsSuccess,
    setNextPage,
    setPageLimit,
  ]);
};
