import React from 'react';

import UsersContextProvider from '../states/users.context';

import UsersListBody from './components/user-list-body/user-list-body';

const UserListPage: React.FC = () => {
  return (
    <UsersContextProvider>
      <UsersListBody />
    </UsersContextProvider>
  );
};

export default UserListPage;
