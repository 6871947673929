import { LineSectionLayout } from 'components-v2/layout/line-section';
import AsyncSelectField from 'components/async-select/async-select';
import InputField from 'components/input-field/input-field';
import Select from 'components/select/select';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { FormikErrors } from 'formik';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { FC, useMemo } from 'react';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { ISaleOfferDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';

interface ISaleOfferFormMountLocationsProps {
  type: 'origin' | 'destination';
  values: ISaleOfferDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<ISaleOfferDomain>>;
}

export const SaleOfferFormMountLocationsView: FC<ISaleOfferFormMountLocationsProps> = ({
  type,
  values,
  setFieldValue,
}) => {
  const typePtBr = useMemo(() => {
    if (type === 'origin') return 'Origem';
    return 'Destino';
  }, [type]);

  return (
    <SectionForm>
      <LineSectionLayout columns="1.04fr 4fr">
        <Select
          label="Tipo *"
          id={`${type}.type`}
          name={`${type}.type`}
          value={values[type].type}
          setFieldValue={setFieldValue}
          options={OriginAndDestinyTypeOptions}
          disabled
        />

        <AsyncSelectField
          placeholder=""
          label={`Informar Nome de ${typePtBr} *`}
          isClearable={true}
          name={`${type}.name`}
          id={`${type}.name`}
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue(`${type}.name`, e?.label);
          }}
          apiCallFunction={async (event: string) => await searchOriginOrDestiny(event, values[type].type)}
          loadOptions={async (event: string) => await searchOriginOrDestiny(event, values[type].type)}
          defaultValue={{
            value: values[type].id,
            label: values[type].name,
          }}
          disabled
        />
      </LineSectionLayout>

      <LineSectionLayout columns="0.81fr 1.3fr 1.3fr 0.4fr ">
        <InputField
          label="CEP"
          name={'address.cep'}
          id={'address.cep'}
          type="text"
          value={values[type].address.cep}
          disabled
        />

        <InputField
          label="Bairro"
          name={'address.neighborhood'}
          id={'address.neighborhood'}
          type="text"
          value={values[type].address.neighborhood}
          disabled
        />

        <InputField
          label="Cidade"
          name={'address.city'}
          id={'address.city'}
          type="text"
          value={values[type].address.city}
          disabled
        />

        <InputField
          label="UF"
          name={'address.uf'}
          id={'address.uf'}
          type="text"
          value={values[type].address.uf}
          disabled
        />
      </LineSectionLayout>

      <LineSectionLayout columns="2.18fr 0.4fr 1.3fr">
        <InputField
          label="Endereço"
          name={'address.street'}
          id={'address.street'}
          type="text"
          value={values[type].address.street}
          disabled
        />

        <InputField
          label="Nº"
          name={'address.numberHouse'}
          id={'address.numberHouse'}
          type="text"
          value={values[type].address.numberHouse}
          disabled
        />

        <InputField
          label="Complemento"
          name={'address.complement'}
          id={'address.complement'}
          type="text"
          value={values[type].address.complement}
          disabled
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
