import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, Fragment, memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styled';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import Line from 'components-v2/layout/line';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { SectionLayout } from 'components-v2/layout/section';
import InputField from 'components/input-field/input-field';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { ISchedulingKeys } from 'domain/schedule';
import { useValidationFields } from 'pages-v2/schedule/context/validation-fields';
import { WeekDaysGlobal } from 'src-new/utils/convert-week-days/convert-week-days.types';
import { convertWeekDays } from 'src-new/utils/convert-week-days/convert-week-days.util';
import { WeekDaysBrazil } from 'src-new/domains/week-day.domain';
import { IClientInboundAndOutbound } from 'src-new/domains/client.domain';
import { InputDateTimePikersFormik } from 'src-new/components/formik/input-date-time-pikers/input-date-time-pikers-formik.component';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { formatAndCalcDate } from 'src-new/utils/format-date';

const HOURS_IN_A_DAY = 24;

interface ScheduleDetailsFormProps {
  schedulingKeys?: ISchedulingKeys[];
  isLtl?: boolean;
  disabledDates?: boolean;
  handleDisabledCards: (disable: boolean, type: 'SPOT' | 'BACKHAUL') => void;
}

const ScheduleDetailsForm: React.FC<ScheduleDetailsFormProps> = ({
  schedulingKeys,
  isLtl,
  disabledDates,
  handleDisabledCards,
}) => {
  const { id } = useParams();
  const { values, setFieldValue } = useFormikContext<ScheduleRegisterInput>();
  const { validateField, setScheduleValues } = useValidationFields();
  const { detail, customFields } = values;

  const [openModal, setOpenModal] = useState<{ open: boolean; fieldName: string; date?: dayjs.Dayjs | null }>({
    open: false,
    fieldName: '',
  });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const customFieldsValues = useMemo(() => {
    return schedulingKeys?.map(({ id, label, isRequired, customField }, index) => {
      const asterisk = isRequired || customField?.isRequired ? '*' : '';

      const labelValue = customField ? customField.label : label;

      const fieldValue = values.customFields ? values.customFields[index]?.value : '';

      return (
        <InputField
          key={id}
          label={`${labelValue} ${asterisk}`}
          id={`customFields[${index}].value`}
          name={`customFields[${index}].value`}
          type="text"
          onChange={handleChange}
          value={fieldValue}
          hasError={isRequired && validateField && !fieldValue}
          errorMessage="Campo Obrigatório"
        />
      );
    });
  }, [schedulingKeys, values.customFields, handleChange, validateField]);

  const rederDetails = () => {
    if (schedulingKeys && schedulingKeys.length > 0) return customFieldsValues;
    if (isLtl) return undefined;
    return (
      <>
        <InputField
          label="Nº Interno"
          id="detail.internalNumber"
          name="detail.internalNumber"
          type="text"
          onChange={handleChange}
          value={values?.detail.internalNumber}
        />

        <InputField
          label="Nº OC Interna"
          id="detail.ocInternalNumber"
          name="detail.ocInternalNumber"
          type="text"
          onChange={handleChange}
          value={values?.detail.ocInternalNumber}
        />

        <InputField
          label="Nº Embarque"
          id="detail.boardingNumber"
          name="detail.boardingNumber"
          type="text"
          onChange={handleChange}
          value={values?.detail.boardingNumber}
        />

        <InputField
          label="Senha"
          id="detail.schedulingPassword"
          name="detail.schedulingPassword"
          type="text"
          onChange={handleChange}
          value={values?.detail.schedulingPassword}
        />
      </>
    );
  };

  const calcMinDate = useCallback(
    (inputName: string): dayjs.Dayjs | undefined => {
      const today = dayjs();
      const levyInitialDate = dayjs(values.detail.levyInitialDate);
      const levyEndDate = dayjs(values.detail.levyEndDate);
      const deliveryInitialDate = dayjs(values.detail.deliveryInitialDate);

      if (!id) {
        switch (inputName) {
          case 'detail.levyInitialDate':
            return;
          case 'detail.levyEndDate':
            return levyInitialDate.isValid() ? levyInitialDate : today;
          case 'detail.deliveryInitialDate':
            return levyEndDate.isValid() ? levyEndDate : today;
          case 'detail.deliveryEndDate':
            return deliveryInitialDate.isValid() ? deliveryInitialDate : today;
          default:
            return undefined;
        }
      }
      return undefined;
    },
    [id, values.detail.deliveryInitialDate, values.detail.levyEndDate, values.detail.levyInitialDate],
  );

  const getMinDateForInitialDate = useCallback(() => {
    const today = dayjs();
    const dayOfWeek = today.day();

    switch (dayOfWeek) {
      case 6: // Saturday
        return today.add(66, 'hours');
      case 0: // Sunday
        return today.add(42, 'hours');
      default: // Monday to Friday
        return today.add(18, 'hours');
    }
  }, []);

  const getMinDateForInitialDateBackhaul = useCallback(() => {
    const today = dayjs();
    const dayOfWeek = today.day();

    switch (dayOfWeek) {
      case 6: // Saturday
        return today.add(6, 'hours');
      case 0: // Sunday
        return today.add(6, 'hours');
      default: // Monday to Friday
        return today.add(6, 'hours');
    }
  }, []);

  const handleCalcLeadTime = useCallback(
    (date: dayjs.Dayjs) => {
      const leadTimeHours = values.leadTime * HOURS_IN_A_DAY;

      const leadTime = formatAndCalcDate(date.toISOString(), 'add', leadTimeHours);

      const leadTimeDate = dayjs(leadTime, 'DD/MM/YYYY HH:mm');

      if (leadTimeDate.isValid()) {
        setFieldValue('detail.deliveryInitialDate', leadTimeDate.toISOString());
        setFieldValue('detail.deliveryEndDate', leadTimeDate.toISOString());
        return;
      }

      setFieldValue('detail.deliveryInitialDate', '');
      setFieldValue('detail.deliveryEndDate', '');
    },
    [setFieldValue, values.leadTime],
  );

  const handleNameToUpdate = useCallback(
    (fieldName: string, date: dayjs.Dayjs | null) => {
      if (!date) return;

      switch (fieldName) {
        case 'detail.levyInitialDate': {
          setFieldValue('detail.levyEndDate', date.toISOString());
          handleCalcLeadTime(date);
          break;
        }
        case 'detail.levyEndDate':
          handleCalcLeadTime(date);
          break;
        case 'detail.deliveryInitialDate':
          setFieldValue('detail.deliveryEndDate', date.toISOString());
          break;
        default:
          break;
      }
    },
    [handleCalcLeadTime, setFieldValue],
  );

  const handleFieldDateChange = useCallback(
    (fieldName: string, date: dayjs.Dayjs | null) => {
      setFieldValue(fieldName, date?.toISOString());
      handleNameToUpdate(fieldName, date);
    },
    [handleNameToUpdate, setFieldValue],
  );

  const handleShouldDisableTime = useCallback(
    (timeValue: dayjs.Dayjs): boolean => {
      if (values.origin.type !== 'CLIENT') return false;

      const weekday: WeekDaysBrazil = convertWeekDays(timeValue.format('ddd') as WeekDaysGlobal);
      const schedule = values.origin.origin.inbound?.[weekday];

      if (!schedule) return false;

      const firstTime = dayjs(schedule.firstTime, 'HH:mm').format('HH');
      const secondTime = dayjs(schedule.secondTime, 'HH:mm').format('HH');
      const currentTime = timeValue.format('HH');

      if (isNaN(Number(firstTime)) || isNaN(Number(secondTime))) return false;

      return !(currentTime >= firstTime && currentTime <= secondTime);
    },
    [values.origin.origin.inbound, values.origin.type],
  );

  const handleOpenModal = useCallback(
    (open: boolean, fieldName: string, date?: dayjs.Dayjs | null) => setOpenModal({ open, fieldName, date }),
    [],
  );

  const weekDaysToPaint = useMemo((): Array<number> | undefined => {
    if (values.origin.type === 'CLIENT' && !!values.origin.origin.inbound) {
      const { inbound } = values.origin.origin;

      const dayMapping: { [key: string]: number } = {
        dom: 0,
        seg: 1,
        ter: 2,
        qua: 3,
        qui: 4,
        sex: 5,
        sab: 6,
      };

      return Object.keys(inbound)
        .filter((day) => !inbound[day as keyof IClientInboundAndOutbound].able)
        .map((day) => dayMapping[day]);
    }
  }, [values.origin.origin, values.origin.type]);

  const renderModal = useMemo((): ReactElement | undefined => {
    if (openModal.open) {
      handleFieldDateChange(`detail.${openModal.fieldName}`, null);

      return (
        <ModalConfirmation
          title="Confirmar Janela"
          secondTitle="Isso criará o agendamento fora da janela especificada pelo cliente. Tem certeza?"
          handleCancel={() => handleOpenModal(false, '')}
          onConfirmation={() => {
            handleFieldDateChange('detail.levyInitialDate', openModal.date ?? null);
            handleOpenModal(false, '');
          }}
          size="small"
          type="delete"
          description={undefined}
        />
      );
    }
  }, [handleFieldDateChange, handleOpenModal, openModal.date, openModal.fieldName, openModal.open]);

  useEffect(() => {
    schedulingKeys?.forEach((schedulingKey, index) => {
      setFieldValue(`customFields[${index}].value`, schedulingKey.value || '');

      setFieldValue(`customFields[${index}].customFieldId`, schedulingKey.id || '');

      setFieldValue(
        `customFields[${index}].isRequired`,
        schedulingKey.isRequired || schedulingKey.customField?.isRequired,
      );
    });
  }, [schedulingKeys, setFieldValue]);

  useEffect(() => {
    setScheduleValues({ ...values, detail, customFields });
  }, [detail, customFields, values, setScheduleValues]);

  useEffect(() => {
    const isLessThanHour = getMinDateForInitialDate().isAfter(values.detail.levyInitialDate);
    const isLessThanFourtyEightHour = getMinDateForInitialDateBackhaul().isAfter(values.detail.levyInitialDate);
    const isLessThanLeadTime =
      dayjs(values.detail.deliveryInitialDate).diff(values.detail.levyEndDate, 'hour') <
      values.leadTime * HOURS_IN_A_DAY;

    if (isLessThanFourtyEightHour || isLessThanLeadTime) {
      handleDisabledCards(true, 'BACKHAUL');
    } else {
      handleDisabledCards(false, 'BACKHAUL');
    }

    if (isLessThanHour || isLessThanLeadTime) {
      handleDisabledCards(true, 'SPOT');
    } else {
      handleDisabledCards(false, 'SPOT');
    }

    if (isLessThanLeadTime) {
      setFieldValue('operation', 'TRACKING');
    }
  }, [
    getMinDateForInitialDate,
    getMinDateForInitialDateBackhaul,
    handleDisabledCards,
    setFieldValue,
    values.detail.deliveryInitialDate,
    values.detail.levyEndDate,
    values.detail.levyInitialDate,
    values.leadTime,
  ]);

  useEffect(() => {
    const { levyInitialDate, levyEndDate, deliveryInitialDate } = values.detail;

    if (dayjs(levyEndDate).isBefore(levyInitialDate)) {
      setFieldValue('detail.levyEndDate', '');
    }

    if (dayjs(deliveryInitialDate).isBefore(levyInitialDate) && dayjs(deliveryInitialDate).isBefore(levyEndDate)) {
      setFieldValue('detail.deliveryInitialDate', '');
      setFieldValue('detail.deliveryEndDate', '');
    }
  }, [setFieldValue, values.detail, values.detail.levyEndDate, values.detail.levyInitialDate]);

  return (
    <Fragment>
      {renderModal}

      <SectionLayout name="Informações da Empresa">
        <S.TitleContainer>
          <div style={{ marginBottom: '1rem' }}>
            <S.Title>Horário Programado</S.Title>
          </div>
          <div style={{ marginBottom: '0.5rem' }}>
            <Line />
          </div>
        </S.TitleContainer>

        <LineSectionLayout columns="1fr 1fr 1fr 1fr">
          <InputDateTimePikersFormik
            label="Inicio de Carregamento *"
            format="DD/MM/YYYY HH:mm"
            disabled={disabledDates}
            value={dayjs(detail.levyInitialDate)}
            minDate={calcMinDate('detail.levyInitialDate')}
            onChange={(date) => handleFieldDateChange('detail.levyInitialDate', date)}
            shouldDisableTime={handleShouldDisableTime}
            errorMessage={validateField && !detail.levyInitialDate ? 'Campo Obrigatório' : ''}
            validateField={validateField}
            weekDaysToPaint={weekDaysToPaint}
            onWeekDaysToPaintClick={(selectedDay) => handleOpenModal(true, 'levyInitialDate', selectedDay)}
          />

          <InputDateTimePikersFormik
            label="Fim de Carregamento *"
            format="DD/MM/YYYY HH:mm"
            disabled={disabledDates}
            value={dayjs(detail.levyEndDate)}
            minDate={calcMinDate('detail.levyEndDate')}
            onChange={(date) => handleFieldDateChange('detail.levyEndDate', date)}
            shouldDisableTime={handleShouldDisableTime}
            errorMessage={validateField && !detail.levyEndDate ? 'Campo Obrigatório' : ''}
            validateField={validateField}
            weekDaysToPaint={weekDaysToPaint}
            onWeekDaysToPaintClick={(selectedDay) => handleOpenModal(true, 'levyEndDate', selectedDay)}
          />

          <DateTimePikers
            label="Inicio da Descarga *"
            format="DD/MM/YYYY HH:mm"
            disabled={disabledDates}
            value={dayjs(detail.deliveryInitialDate)}
            minDate={calcMinDate('detail.deliveryInitialDate')}
            onChange={(date) => handleFieldDateChange('detail.deliveryInitialDate', date)}
            errorMessage={validateField && !detail.deliveryInitialDate ? 'Campo Obrigatório' : ''}
            validateField={validateField}
          />

          <DateTimePikers
            label="Fim da Descarga *"
            format="DD/MM/YYYY HH:mm"
            disabled={disabledDates}
            value={dayjs(detail.deliveryEndDate)}
            minDate={calcMinDate('detail.deliveryEndDate')}
            onChange={(date) => handleFieldDateChange('detail.deliveryEndDate', date)}
            errorMessage={validateField && !detail.deliveryEndDate ? 'Campo Obrigatório' : ''}
            validateField={validateField}
          />
        </LineSectionLayout>

        {!isLtl && (
          <>
            <S.TitleContainer>
              <S.Title>Chaves de Agendamento</S.Title>
              <Line />
            </S.TitleContainer>
            <LineSectionLayout columns="1fr 1fr 1fr 1fr">{rederDetails()}</LineSectionLayout>
          </>
        )}
      </SectionLayout>
    </Fragment>
  );
};

export default memo(ScheduleDetailsForm);
