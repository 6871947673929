import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { defaultFilter } from '../utils/filter';
import { convertField } from '../mappers/convert-filter';
import { scheduleListHook } from '../hooks/schedule-list';
import { IScheduleReportExcelProps } from '../hooks/export-excel/types';
import { generateScheduleExcelHook } from '../hooks/export-excel';
import { cancelScheduleHook } from '../hooks/cancel-schedule';
import { acceptScheduleHook } from '../hooks/accept-schedule';
import { FilterForm } from '../components/filter';

import { ISelectedSchedules } from './types';
import * as S from './styled';
import ListScheduleTable from './content-table/list';

import { UserContext } from 'state/user-context';
import { ScheduleListParams } from 'services/schedule/types';
import { queryClient } from 'services/query-client';
import { normalizeDate } from 'pages-v2/trips/utils/date';
import { CancelAllocationForm } from 'pages-v2/rework/components/modal-cancel';
import { IActiveTabName } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { FooterPage } from 'components-v2/common/footer-page';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Button from 'components/button/button';

const initialExcelParams = {} as IScheduleReportExcelProps;

export const ScheduleList: React.FC = () => {
  const getSessionStorage = sessionStorage.getItem('pagination-allocation');

  const listParams: ScheduleListParams = getSessionStorage ? JSON.parse(getSessionStorage) : defaultFilter;

  const { user, isAdmin, isShippingCompany } = useContext(UserContext);

  const navigate = useNavigate();

  const goToCreateSchedule = () => {
    navigate('/alocacao/novo');
  };

  const goToAllocationSchedule = (id: string) => {
    navigate(`/alocacao/${id}`);
  };

  const [selectedSchedules, setSelectedSchedules] = useState<Array<ISelectedSchedules>>([]);

  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [modalCancel, setModalCancel] = useState<boolean>(false);

  const [isExcelReport, setIsExcelReport] = useState<boolean>(false);

  const [params, setParams] = useState<ScheduleListParams>(listParams);

  const handleSearch = (search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  };

  const handlePaginate = (page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  };

  const tabStatusCode = (activeTabName: string) => {
    if (activeTabName === 'Programadas') {
      return ['SH01', 'OT10', 'AG10', 'OT01'];
    }

    if (activeTabName === 'Canceladas') {
      return ['AL99', 'OT99', 'AL90'];
    }

    return ['SH01', 'OT10', 'AG10', 'OT01'];
  };

  const renderStatusByTabName = (activeTabName: string) => {
    const statusCode = tabStatusCode(activeTabName);

    setParams((prevState) => ({
      ...prevState,
      statusCode,
      activeTabName: activeTabName as IActiveTabName,
      page: 1,
    }));
  };

  const { isLoading, data } = useQuery({
    queryKey: ['scheduleList', params],
    queryFn: () => scheduleListHook(params, user),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const FilterFormRef = useRef<FormikProps<ScheduleListParams>>(null);

  const handleExportExcel = useCallback(async () => {
    try {
      setIsExcelReport(true);
      await generateScheduleExcelHook(initialExcelParams);
      setIsExcelReport(false);
    } catch (error) {
      showToast({
        message: 'Erro ao tentar baixar o relatório',
        type: 'error',
      });
    }
  }, []);

  const acceptButton = selectedSchedules.some(
    (schedule) => schedule.status === 'Em Negociação' || schedule.status === 'Ag. Aceite Transportadora',
  );

  const cancelButton = selectedSchedules.some(
    (schedule) => schedule.status !== 'Em Negociação' && schedule.status !== 'Ag. Aceite Transportadora',
  );

  const typeCancel = useMemo(() => {
    if (!cancelButton) return 'backhaul';
    else return 'spot';
  }, [cancelButton]);

  const secondButton = useMemo(() => {
    if (acceptButton && !cancelButton) {
      return 'Aceitar';
    }
    return null;
  }, [acceptButton, cancelButton]);

  const thirdButton = useMemo(() => {
    if (acceptButton && !cancelButton) {
      return 'Negar';
    }
    if (!acceptButton && cancelButton && !isShippingCompany) {
      return 'Cancelar';
    }
    return null;
  }, [acceptButton, cancelButton, isShippingCompany]);

  const paramsArray: Array<{ key: string; value: string | number }> = Object.entries(params).map(([key, value]) => ({
    key,
    value: convertField(key, value),
  }));

  const newParamsArray = paramsArray.filter(
    (item) =>
      (item.key !== 'search' || (item.key === 'search' && item.value !== '')) &&
      (item.key !== 'levyInitialDate' || (item.key === 'levyInitialDate' && item.value !== '')) &&
      (item.key !== 'levyEndDate' || (item.key === 'levyEndDate' && item.value !== '')) &&
      (item.key !== 'deliveryInitialDate' || (item.key === 'deliveryInitialDate' && item.value !== '')) &&
      (item.key !== 'deliveryEndDate' || (item.key === 'deliveryEndDate' && item.value !== '')) &&
      (item.key !== 'statusCode' || (item.key === 'statusCode' && item.value !== '')) &&
      !['page', 'column', 'order', 'activeTabName'].includes(item.key),
  );

  const deleteFilters = (indexToRemove: number) => {
    const updatedParamsArray = [...newParamsArray];
    updatedParamsArray.splice(indexToRemove, 1);
    let newStatusCode = tabStatusCode(params.activeTabName);

    if (updatedParamsArray.length === 0) {
      setParams({
        ...defaultFilter,
        activeTabName: params.activeTabName,
        statusCode: newStatusCode,
      });
      queryClient.invalidateQueries(['scheduleList', defaultFilter]);
    } else {
      const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
        accumulator[key] = params[key as keyof ScheduleListParams];
        return accumulator;
      }, {});

      if (updatedParams?.statusCode && updatedParams.statusCode.length === 1) {
        newStatusCode = updatedParams.statusCode;
      }

      setParams({
        ...updatedParams,
        page: 1,
        statusCode: newStatusCode,
      });

      queryClient.invalidateQueries(['scheduleList', updatedParams]);
    }
  };

  const onFilterSubmit = (values: ScheduleListParams) => {
    const { levyInitialDateStart, levyInitialDateEnd, deliveryInitialStart, deliveryInitialEnd } = values;

    setParams({
      ...values,
      page: 1,
      activeTabName: params.activeTabName,
      levyInitialDateStart: normalizeDate(levyInitialDateStart),
      levyInitialDateEnd: normalizeDate(levyInitialDateEnd),
      deliveryInitialStart: normalizeDate(deliveryInitialStart),
      deliveryInitialEnd: normalizeDate(deliveryInitialEnd),
    });

    setModalFilter(false);
  };

  const handleCancelSchedule = (values: { descriptionCancel: string }) => {
    cancelScheduleHook(
      selectedSchedules.map((selectedSchedule) => selectedSchedule.id),
      values.descriptionCancel,
      typeCancel,
    );

    queryClient.invalidateQueries(['scheduleList', params]);

    setModalCancel(false);
    setSelectedSchedules([]);
  };

  return (
    <>
      {modalFilter && (
        <ModalConfirmation
          title="Escolha o Filtro"
          description={
            <>
              <Formik innerRef={FilterFormRef} initialValues={params} onSubmit={onFilterSubmit}>
                <FilterForm />
              </Formik>
            </>
          }
          handleXCancel={() => setModalFilter(false)}
          fullButtonWidth
          isRight
          legacy
          dateIndex
          size="large"
          type="normal"
        />
      )}

      {modalCancel && (
        <Formik initialValues={{ descriptionCancel: '' }} onSubmit={handleCancelSchedule}>
          {(formikHelpers) => (
            <CancelAllocationForm
              ids={selectedSchedules}
              buttonLabel={typeCancel === 'spot' ? 'excluir' : 'negar'}
              closeModal={() => setModalCancel(false)}
              handleConfirm={formikHelpers.handleSubmit}
            />
          )}
        </Formik>
      )}

      <HeaderPage breadcrumbList={['Fretes Dedicados (FTL)']} />
      <Title title="Acompanhe o status dos fretes contratados" hasTrace />

      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pelo ID, Unidade, Parceiro ou Cidade"
          type="text"
          changeValue={(event) => handleSearch(event)}
          filters={newParamsArray.map((item) => item.value)}
          handleClearFilters={(indexToRemove) => deleteFilters(indexToRemove)}
          isClear={false}
        />

        <Button
          title={'Filtrar'}
          size={'small'}
          bgColor={'aqua'}
          callback={() => setModalFilter(true)}
          icon={<FilterAltIcon />}
        />

        <Button
          title={'Exportar'}
          size={'small'}
          bgColor={'pink'}
          callback={handleExportExcel}
          disabled={isExcelReport}
          icon={<CloudDownloadIcon />}
        />
      </S.ContainerSearch>

      <ListScheduleTable
        schedules={data?.list || []}
        pageInfo={data?.pageInfo}
        isAdmin={isAdmin}
        setSelectedSchedule={setSelectedSchedules}
        selectedSchedule={selectedSchedules}
        isLoading={isLoading}
        handlePaginate={handlePaginate}
        orderSelected={{
          orderBy: params.column,
          sortDirection: params.order,
        }}
        clickOrderTableBy={(column) => {
          setParams((prevParams) => ({
            ...prevParams,
            column: column,
          }));
        }}
        clickSortDirection={() => {
          setParams((prevParams) => ({
            ...prevParams,
            order: params.order === 'ASC' ? 'DESC' : 'ASC',
          }));
        }}
        setActiveTabName={renderStatusByTabName}
        tabActive={params.activeTabName}
        goToAllocationSchedule={goToAllocationSchedule}
      />

      <FooterPage.Root>
        <FooterPage.RightContent>
          {!isShippingCompany && <FooterPage.Button label="Novo Frete" color="blue" onPress={goToCreateSchedule} />}
          {secondButton && (
            <FooterPage.Button
              label={secondButton}
              color="green"
              onPress={() => {
                acceptScheduleHook(selectedSchedules.map((selectedSchedule) => selectedSchedule.id));
                queryClient.invalidateQueries(['scheduleList', params]);
                setSelectedSchedules([]);
              }}
            />
          )}
          {thirdButton && <FooterPage.Button label={thirdButton} color="red" onPress={() => setModalCancel(true)} />}
        </FooterPage.RightContent>
      </FooterPage.Root>
    </>
  );
};
