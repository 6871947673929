import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { LocationDetailsSchema } from './schemas-validation/schemas-validation';
import LocationDetailsFormView from './location-details-form-view';
import { initialValues, PATH_TO_LOCATION_LIST } from './form-values/form-values';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useUpdateTransportHook } from 'pages/location/hooks/use-location-update/use-location-update.hook';
import { useLocationDetailstHook } from 'pages/location/hooks/use-location-details/use-location-details.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { clientsFilterSearchService } from 'pages/schedule/services/clients-search/clients-filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useDetailsClientHook } from 'pages/client/hooks/client-details/client-details.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { Loading } from 'src-new/components/loading/loading.component';

const LocationDetailsForm: React.FC = () => {
  const [location, setLocation] = useState<ILocationDomain>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const getClientDetail = useDetailsClientHook();
  const locationDetailsHook = useLocationDetailstHook();
  const locationUpdateHook = useUpdateTransportHook();
  const cepHook = useCepHook();
  const { id } = useParams();

  const goBackToLocationList = useCallback(() => navigate(PATH_TO_LOCATION_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (locality: ILocationDomain) => {
      setIsLoading(true);
      const newLocality: ILocationDomain = {
        ...locality,
        lat: locality.lat ?? '',
        lng: locality.lng ?? '',
      };
      locationUpdateHook(newLocality)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToLocationList();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToLocationList, locationUpdateHook],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  useEffect(() => {
    async function run() {
      if (id) {
        setIsDetailsLoading(true);
        const location = await locationDetailsHook(id);
        setLocation(location);
        setIsDetailsLoading(false);
      }
    }

    if (initial) {
      run();
      setInitial(false);
    }
  }, [id, initial, locationDetailsHook]);

  const clientApiCall = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return clientsFilterSearchService(searchValue);

    return [];
  }, []);

  const clientOptions = useCallback(
    (clientIds: number[]): Array<{ label: string; value: number }> => {
      const new_array: { label: string; value: number }[] = [];

      if (clientIds && clientIds.length > 0) {
        clientIds.map(async (id) => {
          const client = await getClientDetail(String(id));
          new_array.push({ label: client.tradeName, value: id });
        });
      }

      return new_array;
    },
    [getClientDetail],
  );

  if (isLoading || isDetailsLoading) {
    return <Loading />;
  }

  return (
    <LocationDetailsFormView
      locationValues={location}
      schemaValidation={LocationDetailsSchema}
      isLoading={isLoading}
      handleGoback={goBackToLocationList}
      handleRegister={handleSubmit}
      onBlurCep={onBlurCep}
      clientApiCall={clientApiCall}
      clientOptions={clientOptions}
    />
  );
};

export default React.memo(LocationDetailsForm);
