import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './styled';

interface EssencialButtonProps {
  label: string;
  size: 'small' | 'medium' | 'large' | 'full';
  color: ThemeColorsV2Type;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EssencialButton: React.FC<EssencialButtonProps> = ({
  label,
  size,
  color,
  icon,
  disabled = false,
  onClick,
}) => {
  return (
    <div>
      <S.Wrapper size={size} color={color} disabled={disabled} onClick={(e) => onClick(e)}>
        <S.ContainerLabel>
          <S.Label>{label}</S.Label>
        </S.ContainerLabel>
      </S.Wrapper>
      <S.ContainerIcon disabled={disabled}>{!!icon && icon}</S.ContainerIcon>
    </div>
  );
};
