export enum ScheduleRefusedListActionType {
  SCHEDULE_REFUSED_LIST_REQUEST = 'SCHEDULE_REFUSED_LIST_REQUEST',
  SCHEDULE_REFUSED_LIST_SUCCESS = 'SCHEDULE_REFUSED_LIST_SUCCESS',
  SCHEDULE_REFUSED_LIST_ERROR = 'SCHEDULE_REFUSED_LIST_ERROR',
  SCHEDULE_REFUSED_LIST_FILTERS = 'SCHEDULE_REFUSED_LIST_FILTERS',
  SCHEDULE_REFUSED_LIST_MODALS = 'SCHEDULE_REFUSED_LIST_MODALS',
  SCHEDULE_REFUSED_LIST_RESET = 'SCHEDULE_REFUSED_LIST_RESET',
}

export enum ScheduleRefusedActionType {
  SCHEDULE_REFUSED_RESET = 'SCHEDULE_REFUSED_RESET',
}
