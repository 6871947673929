import styled, { css, keyframes } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 30%;
  width: 276px;

  flex-direction: column;
  gap: 1rem;
  position: relative;
  bottom: 1.8rem;
`;

export const WrapperValueProposed = styled.div`
  height: 41px;
  width: 400px;
  font-size: 10px;
  text-align: right;
  right: 125px;
  position: relative;
`;

const fadeIn = keyframes`
  0% {
    left: 25%;
  }
  100% {
    left: 84%;
  }
`;

export const ContainerLoading = styled.div`
  width: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 1s ease-in-out infinite;
`;

export const HeaderValueProposed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ContainerAcceptancePotential = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

export const TextAcceptancePotential = styled.p``;

export const TextBoldAcceptancePotential = styled.strong``;

interface ITagAcceptancePotentialProps {
  color: ThemeColorsV2Type;
}
export const TagAcceptancePotential = styled.p<ITagAcceptancePotentialProps>`
  ${({ theme, color }) => css`
    width: fit-content;
    padding: 5px;
    color: white;
    background-color: ${theme.colorsV2[color]};
    border-radius: 3px;
    font-weight: 600;
  `}
`;

export const WrapperContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 18px;
    box-shadow: 5px 5px 10px -5px #7b7b7b;
    border-radius: 5px;
    background-color: ${theme.colorsV2.blue};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 5px;
`;

interface ITitleTextProps {
  isBold?: boolean;
}
export const TitleText = styled.p<ITitleTextProps>`
  ${({ theme, isBold }) => css`
    font-size: ${theme.fontV2.sizes.text3};
    font-weight: ${isBold && 700};
    color: ${theme.colorsV2.white};
    padding-bottom: 10px;
    text-transform: uppercase;
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    font-size: ${theme.fontV2.sizes.text4};
    color: ${theme.colorsV2.white};
    text-transform: uppercase;
    font-size: 9px;
    padding: 5px 0;
  `}
`;

export const ContainerValues = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: 400;
    color: ${theme.colorsV2.white};
    text-transform: uppercase;
  `}
`;

export const TooltipContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 5px;
`;

export const TooltipIcon = styled.div`
  display: flex;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: 600;
    color: ${theme.colorsV2.white};
    padding-bottom: 10px;
    text-transform: uppercase;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    display: flex;
    font-size: ${theme.fontV2.sizes.text5};
    font-weight: 400;
    color: ${theme.colorsV2.white};
    text-transform: uppercase;
  `}
`;

export const Div = styled.div``;
