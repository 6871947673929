import React, { ReactNode } from 'react';
import { ContentStyled, SidebarStyled, WrapperStyled } from './body-view.styled';
import Sidebar from 'components/sidebar/sidebar';
import { ChatSession } from 'src-new/components/chat-session/chat-session.component';

export type IBodyViewProps = {
  children: ReactNode;
};

export const BodyView: React.FC<IBodyViewProps> = ({ children }) => {
  return (
    <ChatSession>
      <WrapperStyled>
        <SidebarStyled aria-label="menu lateral" id="sidebar">
          <Sidebar />
        </SidebarStyled>
        <ContentStyled aria-label="conteudo">{children}</ContentStyled>
      </WrapperStyled>
    </ChatSession>
  );
};
