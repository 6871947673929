import { useCallback } from 'react';
import { showToast } from 'components/toast/toast';
import { deleteUploadFileService } from 'src-new/pages/registrations/services/delete-upload-file/delete-upload-file.service';

export const useDeleteUploadFiles = () => {
  return useCallback(async (ids: Array<number>): Promise<void> => {
    try {
      const promises: Array<Promise<void>> = ids.map((id: number) => deleteUploadFileService(id));

      await Promise.all(promises);
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Ocorreu um erro ao deletar os arquivos',
      });
    }
  }, []);
};
