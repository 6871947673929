import React, { useMemo } from 'react';
import { Button } from 'logshare-ui-kit';

import * as S from './styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';
import Input from 'components/input/input';

interface IProps {
  name: string;
  contentTable: IContentTable[];
  headerTable: IContentTable;
  pageInfo?: PageInfo;
  isLoading: boolean;
  changePage: (page: number) => void;
  handleChangeSearch: (e: string) => void;
  handleRegisterModal?: () => void;
  handleSelectCurrentLine: (id: string) => void;
}

const AllocationModalTable: React.FC<IProps> = ({
  name,
  contentTable,
  headerTable,
  changePage,
  pageInfo,
  isLoading,
  handleChangeSearch,
  handleSelectCurrentLine,
  handleRegisterModal,
}) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.WrapperPaginationStyled>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />

          {name !== 'shipping' && (
            <Button label="Cadastrar Novo" color="blue" size="md" variant="solid" onPress={handleRegisterModal} />
          )}
        </S.WrapperPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <S.WrapperTableStyled>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 30,
        }}
      >
        <Input
          id={`${name}-search-input`}
          type={`${name}-search-input`}
          name={`${name}-search-input`}
          placeholder={'Busque pelos dados cadastrais'}
          changeValue={handleChangeSearch}
        />
        <Table
          header={headerTable}
          content={contentTable}
          isLoading={isLoading}
          clickRowAction={handleSelectCurrentLine}
        />
      </div>
      {contentTable.length > 0 && renderPagination}
    </S.WrapperTableStyled>
  );
};

export default AllocationModalTable;
