import { IUserStatus } from 'domain/user';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): IUserStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativos':
      return 'ativo';
    case 'Inativos':
      return 'inativo';
    case 'Suspenso':
      return 'suspenso';
  }
};
