import { ColorsV2Type } from 'styles/types/colors';

type InvoiceStatusType = {
  label: string;
  color: ColorsV2Type;
};

type InvoiceStatusMap = {
  [key: string]: InvoiceStatusType;
};

const defaultValue: InvoiceStatusType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertInvoiceStatusTag = (label: string): InvoiceStatusType => {
  const invoiceStatusMap: InvoiceStatusMap = {
    'A Vencer': {
      label: 'A Vencer',
      color: ColorsV2Type.Blue100,
    },
    Liquidado: {
      label: 'Pago',
      color: ColorsV2Type.Aqua,
    },
    Atrasado: {
      label: 'Atrasado',
      color: ColorsV2Type.Blue,
    },
    Cancelado: {
      label: 'Cancelado',
      color: ColorsV2Type.Red,
    },
  };

  return invoiceStatusMap[label] || defaultValue;
};
