import React from 'react';
import { Tag } from 'logshare-ui-kit';

import {
  CardInfoStyled,
  ContainerStyled,
  ContentStyled,
  HeaderCardStyled,
  IdStyled,
  TitleStyled,
} from './card-content.styled';

import { convertOperationType } from 'utils/operation-type';
import { convertFreightType } from 'utils/freight-type';
import { IKanbanCardProps } from 'pages/task-vision/types/types';

const CardContentView: React.FC<IKanbanCardProps> = ({
  id,
  name,
  vehicle,
  origin,
  destiny,
  collect,
  delivery,
  typeSchedule,
  typeOperation,
  handleSelected,
}) => {
  return (
    <ContainerStyled onClick={handleSelected}>
      <HeaderCardStyled>
        <IdStyled>ID {id}</IdStyled>
        <div style={{ display: 'flex', gap: 10 }}>
          <Tag
            LeftIcon={convertFreightType(typeSchedule).icon}
            label={convertFreightType(typeSchedule).label}
            color={convertFreightType(typeSchedule).color}
            variant="solid"
            display="inline-flex"
            size="md"
          />
          <Tag
            label={convertOperationType(typeOperation).label}
            color={convertOperationType(typeOperation).color}
            variant="ghost"
            display="flex"
            size="md"
          />
        </div>
      </HeaderCardStyled>
      <TitleStyled>{`${name?.toUpperCase()}`}</TitleStyled>
      <ContentStyled>
        Origem <CardInfoStyled>{origin?.toUpperCase()}</CardInfoStyled>
      </ContentStyled>
      <ContentStyled>
        Destino <CardInfoStyled>{destiny?.toUpperCase()}</CardInfoStyled>
      </ContentStyled>
      <ContentStyled>
        Veículo <CardInfoStyled>{vehicle?.toUpperCase()}</CardInfoStyled>
      </ContentStyled>
      <ContentStyled>
        Coleta <CardInfoStyled>{collect}</CardInfoStyled>
      </ContentStyled>
      <ContentStyled>
        Entrega
        <CardInfoStyled>{delivery}</CardInfoStyled>
      </ContentStyled>
    </ContainerStyled>
  );
};

export default CardContentView;
