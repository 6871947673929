import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { Formik, FormikProps, withFormik } from 'formik';
import dayjs from 'dayjs';
import CreateIcon from '@mui/icons-material/Create';

import { HeaderTitle, Info, InfoTag, InfoTitle, SectionContainer, Wrapper } from '../styled';

import HoursUpdateModal from './components/modal-form';

import { ColorsV2Type } from 'styles/types/colors';
import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { ScheduleDomain } from 'domain/schedule';
import { ModalForm } from 'components/modal-form/modal-form';
import Button from 'components/button/button';

interface IProps {
  values: IAllocationShippingDetails;
  currentAllocation?: ScheduleDomain;
  handleRegister: (values: IAllocationShippingDetails) => void;
}

const FreightOperationSectionView: React.FC<IProps> = ({ values, currentAllocation, handleRegister }) => {
  const InnerForm = (props: FormikProps<IAllocationShippingDetails>) => {
    const { values } = props;
    const [modalEdit, setModalEdit] = useState(false);

    const CargoOperationFormRef = useRef<FormikProps<IAllocationShippingDetails>>(null);

    const [cargoOperationForm, setCargoOperationForm] = useState(values);

    useEffect(() => {
      setCargoOperationForm(values);
    }, [values, values.finishDate]);

    return (
      <>
        {modalEdit && (
          <ModalForm
            title="Registro de Horários"
            description={
              <Formik
                innerRef={CargoOperationFormRef}
                initialValues={cargoOperationForm}
                onSubmit={(values) => {
                  handleRegister(values);
                  setCargoOperationForm(values);
                  setModalEdit(false);
                }}
                onReset={() => {
                  setCargoOperationForm(values);
                }}
              >
                <HoursUpdateModal values={cargoOperationForm} id={values.id} FreightID={currentAllocation?.id || 0} />
              </Formik>
            }
            handleCancel={() => setModalEdit(false)}
            size="medium"
            legacy
          />
        )}
        <Wrapper>
          <HeaderTitle isTraced display="flex" justify="space-between">
            Registro de Horários
            <Button
              title="Editar"
              size="very-small"
              icon={<CreateIcon />}
              callback={() => setModalEdit(true)}
              bgColor="white"
            />
          </HeaderTitle>

          <SectionContainer display="grid" columns={'1fr 1fr 1fr 1fr 1fr'}>
            <InfoTitle>
              Solicitação da Viagem
              <Info>
                {currentAllocation?.created_at ? moment(currentAllocation?.created_at).format('DD/MM/YYYY HH:mm') : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              Aceite da Transportadora
              <Info>
                {cargoOperationForm?.acceptShipping
                  ? moment(cargoOperationForm?.acceptShipping).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              Alocação do Veículo
              <Info>
                {cargoOperationForm?.allocationVehicleDate
                  ? moment(cargoOperationForm?.allocationVehicleDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              Alocação do Motorista
              <Info>
                {cargoOperationForm?.allocationDriverDate
                  ? moment(cargoOperationForm?.allocationDriverDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              <InfoTag>
                Aceite do Motorista
                {!!cargoOperationForm.acceptDateAgent && (
                  <Tag
                    label={cargoOperationForm.acceptDateAgent}
                    color={cargoOperationForm.acceptDateAgent === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.acceptDate
                  ? moment(cargoOperationForm?.acceptDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>
          </SectionContainer>

          <SectionContainer display="grid" columns={'1fr 1fr 1fr 1fr 1fr'}>
            <InfoTitle>
              <InfoTag>
                Inicio da Viagem
                {!!cargoOperationForm.startDateAgennt && (
                  <Tag
                    label={cargoOperationForm.startDateAgennt}
                    color={cargoOperationForm.startDateAgennt === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.startDate ? moment(cargoOperationForm?.startDate).format('DD/MM/YYYY HH:mm') : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              <InfoTag>
                Chegada para Coleta
                {!!cargoOperationForm.checkinDateAgent && (
                  <Tag
                    label={cargoOperationForm.checkinDateAgent}
                    color={cargoOperationForm.checkinDateAgent === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.checkinDate
                  ? moment(cargoOperationForm?.checkinDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              <InfoTag>
                Coleta Finalizada
                {!!cargoOperationForm.checkoutDateAgent && (
                  <Tag
                    label={cargoOperationForm.checkoutDateAgent}
                    color={cargoOperationForm.checkoutDateAgent === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.checkoutDate
                  ? moment(cargoOperationForm?.checkoutDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              <InfoTag>
                Chegada para Descarga
                {!!cargoOperationForm.loadDateAgent && (
                  <Tag
                    label={cargoOperationForm.loadDateAgent}
                    color={cargoOperationForm.loadDateAgent === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.loadDate ? moment(cargoOperationForm?.loadDate).format('DD/MM/YYYY HH:mm') : '-'}
              </Info>
            </InfoTitle>

            <InfoTitle>
              <InfoTag>
                Descarga Finalizada
                {!!cargoOperationForm.finishDateAgent && (
                  <Tag
                    label={cargoOperationForm.finishDateAgent}
                    color={cargoOperationForm.finishDateAgent === 'web' ? ColorsV2Type.Blue : ColorsV2Type.Aqua}
                    display="flex"
                    variant="solid"
                    size="x-sm"
                  />
                )}
              </InfoTag>
              <Info>
                {cargoOperationForm?.finishDate
                  ? dayjs(cargoOperationForm?.finishDate).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Info>
            </InfoTitle>
          </SectionContainer>
        </Wrapper>
      </>
    );
  };

  const DetailsScheduleForm = withFormik<IAllocationShippingDetails, IAllocationShippingDetails>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
  })(InnerForm);

  return <DetailsScheduleForm {...values} />;
};

export default React.memo(FreightOperationSectionView);
