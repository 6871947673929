import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { scheduleInitialValues } from 'pages-v2/schedule/register/form/initial-values';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';

interface IValidationFieldContextProps {
  children: ReactNode;
}

interface ValidationFieldProps {
  isFilled: {
    originValues: boolean;
    destinyValues: boolean;
    cargoValues: boolean;
    detailValues: boolean;
    customFieldsValues: boolean;
  };
  schedule: ScheduleRegisterInput;
  validateField: boolean;
  setScheduleValues: (input: ScheduleRegisterInput) => void;
  handleValidateField: () => void;
}

const initialState = {} as ValidationFieldProps;
const initialScheduleState = scheduleInitialValues;

const ValidationFieldContext = createContext<ValidationFieldProps>(initialState);

export const ValidationFieldProvider = ({ children }: IValidationFieldContextProps) => {
  const [schedule, setSchedule] = useState<ScheduleRegisterInput>(initialScheduleState);
  const [validateField, setValidateField] = useState<boolean>(false);

  const { origin, destination, cargo, detail, customFields } = schedule;

  const setScheduleValues = useCallback((input: ScheduleRegisterInput) => {
    setSchedule(input);
  }, []);

  const handleValidateField = () => {
    setValidateField(true);
  };

  const isChecked = useMemo(() => {
    const originValues = !!(origin.id && origin.type);
    const destinyValues = !!(destination.id && destination.type);
    const cargoValues = !!(cargo.vehicleType && cargo.vehicleCategoryType);

    const detailValues = !!(
      detail.levyInitialDate &&
      detail.levyEndDate &&
      detail.deliveryInitialDate &&
      detail.deliveryEndDate &&
      (dayjs(detail.levyInitialDate).isBefore(detail.levyEndDate) ||
        dayjs(detail.levyInitialDate).isSame(detail.levyEndDate)) &&
      (dayjs(detail.levyEndDate).isBefore(detail.deliveryInitialDate) ||
        dayjs(detail.levyEndDate).isSame(detail.deliveryInitialDate)) &&
      (dayjs(detail.deliveryInitialDate).isBefore(detail.deliveryEndDate) ||
        dayjs(detail.deliveryInitialDate).isSame(detail.deliveryEndDate))
    );
    const customFieldsValid = customFields && Object.values(customFields);
    let customFieldsValues = true;
    if (customFieldsValid) {
      customFieldsValues = customFieldsValid?.every((field) => {
        return !field?.isRequired || (!!field?.value && field?.value?.trim() !== '');
      });
    }

    return {
      originValues,
      destinyValues,
      cargoValues,
      detailValues,
      customFieldsValues,
    };
  }, [schedule]);

  const contextValue = useMemo(
    () => ({
      isFilled: isChecked,
      schedule,
      validateField,
      setScheduleValues,
      handleValidateField,
    }),
    [schedule, validateField],
  );

  return <ValidationFieldContext.Provider value={contextValue}>{children}</ValidationFieldContext.Provider>;
};

export const useValidationFields = () => {
  const context = useContext(ValidationFieldContext);

  if (!context) {
    throw new Error('useValidationFields deve ser usado dentro de um ValidationFieldProvider');
  }

  return context;
};
