import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SidebarContext } from '../../contexts/sidebar.context';

import HeaderView from './header-view';

import { UserContext } from 'state/user-context';
import { useMyAccountDetailsHook } from 'pages/my-account/hooks/use-my-account-details/use-my-account-details.hook';
import { useDetailsCompanyHook } from 'pages/company/hooks/company-details/company-details.hook';
import { initialValues } from 'pages/company/company-update/components/company-update-form/form-values/form-values';
import { IMyAccount } from 'domain/my-account';
import { ICompany } from 'domain/company';

const MyAccountValues: IMyAccount = {
  name: '',
  login: '',
  email: '',
  telephone: '',
  celphone: '',
};

const Header: React.FC = () => {
  const { updateLayout, textIsVisible, handleHover } = React.useContext(SidebarContext);

  const [companyInformation, setCompanyInformation] = useState<ICompany>(initialValues);

  const [myAccount, setMyAccount] = useState<IMyAccount>(MyAccountValues);

  const { user } = useContext(UserContext);
  const companyDetailHook = useDetailsCompanyHook();
  const myAccountDetailsHook = useMyAccountDetailsHook();

  const navigate = useNavigate();
  const goBackToHomePage = useCallback(() => {
    handleHover(false);
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    async function run() {
      if (user?.companyId) {
        const companyUserImage = await companyDetailHook(user?.companyId);
        setCompanyInformation(companyUserImage);
      }
    }
    run();
  }, [companyDetailHook, user?.companyId]);

  useEffect(() => {
    if (user?.id) {
      myAccountDetailsHook(user?.id).then((myAccount) => setMyAccount(myAccount));
    }
  }, [myAccountDetailsHook, user?.id]);

  return (
    <HeaderView
      updateLayout={updateLayout}
      showUserName={textIsVisible}
      companyValues={companyInformation}
      myAccountValues={myAccount}
      handleGoBack={goBackToHomePage}
    />
  );
};

export default Header;
