import styled, { css } from 'styled-components';

export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    margin-top: 10px;
    width: 100%;
  `}
`;

export const ContainerNegotiationsStyled = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  padding: 20px 0;
`;

export const ContainerPhotosStyled = styled.div`
  padding-top: 0px;
  margin-top: -40px;
`;

export const UploadImageStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 20px;
`;

export const DividerStyled = styled.div``;

export const OptionStyled = styled.option``;

export const ContainerModalStyled = styled.div``;

export const ContainerListStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleTableStyled = styled.div`
  font-size: 13px;
`;
