import { IScheduleHistoryDriverAndVehicleDomain } from '../../../../domains/schedule-details-history-driver-and-vehicle.domain';
import { IScheduleHistoryDriverAndVehicleResponse } from '../../get-schedule-history-driver-and-vehicle-list.types';

export const converterScheduleHistoryDriverAndVehicleResponseToDomain = (
  scheduleDetailHistoryDriverAndVehicleResponse: IScheduleHistoryDriverAndVehicleResponse,
): IScheduleHistoryDriverAndVehicleDomain => {
  return {
    id: scheduleDetailHistoryDriverAndVehicleResponse.id,
    scheduleId: scheduleDetailHistoryDriverAndVehicleResponse.schedule_id,
    driverId: scheduleDetailHistoryDriverAndVehicleResponse.driver_id,
    vehicleId: scheduleDetailHistoryDriverAndVehicleResponse.vehicle_id,
    typeUpdate: scheduleDetailHistoryDriverAndVehicleResponse.type_update,
    driverName: scheduleDetailHistoryDriverAndVehicleResponse.name_driver,
    firstPhone: scheduleDetailHistoryDriverAndVehicleResponse.fisrt_phone,
    cnh: scheduleDetailHistoryDriverAndVehicleResponse.cnh,
    cpf: scheduleDetailHistoryDriverAndVehicleResponse.cpf,
    vehicleMainBoard: scheduleDetailHistoryDriverAndVehicleResponse.vehicle_main_board,
    vehicleSecondBoard: scheduleDetailHistoryDriverAndVehicleResponse.vehicle_second_board,
    thirdBoard: scheduleDetailHistoryDriverAndVehicleResponse.third_board,
    vehicleType: scheduleDetailHistoryDriverAndVehicleResponse.vehicle_type,
    vehicleCategory: scheduleDetailHistoryDriverAndVehicleResponse.vehicle_category,
    capacity: scheduleDetailHistoryDriverAndVehicleResponse.capacity,
    palletCapacity: scheduleDetailHistoryDriverAndVehicleResponse.pallet_capacity,
    cubageCapacity: scheduleDetailHistoryDriverAndVehicleResponse.cubage_capacity,
    createdAt: scheduleDetailHistoryDriverAndVehicleResponse.created_at,
  };
};
