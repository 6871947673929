import Input from 'components/input/input';
import { FC, Fragment, useMemo } from 'react';
import { InputRadio } from 'src-new/components/input-radio/input.radio.component';
import { Table } from 'src-new/components/table/table.component';
import { IScheduleDetailsAllocationDriverDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-driver.domain';
import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';

interface IStatusProps {
  isError: boolean;
  isLoading: boolean;
  isEmpty: boolean;
}

interface IScheduleDetailsAllocationDriverListModalViewProps {
  statusProps: IStatusProps;
  drivers: Array<IScheduleDetailsAllocationDriverDomain>;
  driverSelected: {
    checked: boolean;
    driverId: number;
  };
  handleSearch: (search: string) => void;
  infiniteScrollProps: IInfiniteScrollTable;
  handleSelectedDriver: (checked: boolean, driverId: number) => void;
}

export const ScheduleDetailsAllocationDriverListTableView: FC<IScheduleDetailsAllocationDriverListModalViewProps> = ({
  statusProps,
  drivers,
  driverSelected,
  handleSearch,
  infiniteScrollProps,
  handleSelectedDriver,
}) => {
  const renderTableRows = useMemo(() => {
    return drivers.map((driver, index) => {
      return {
        key: index,
        valuesColumns: [
          {
            value: (
              <InputRadio
                id={driver.id ? driver.id.toString() : '0'}
                name={`driver_${driver.id}`}
                value={driver.id}
                onChange={(event) => handleSelectedDriver(event.target.checked, driver.id)}
                checked={driverSelected.driverId === driver.id}
              />
            ),
          },
          { value: driver.name },
          { value: driver.cpf },
          { value: formatCelphoneNumberEntityToDomain(driver.telephone) },
        ],
      };
    });
  }, [drivers, driverSelected, handleSelectedDriver]);

  const renderContent = useMemo(() => {
    if (statusProps.isLoading) return <Loading />;

    if (statusProps.isError) return <ErrorState />;

    if (statusProps.isEmpty) return <EmptyState />;

    return (
      <Table
        header={[{ value: '' }, { value: 'Nome' }, { value: 'CPF' }, { value: 'Telefone' }]}
        rows={renderTableRows}
        gridColumns={'0.2fr 1fr 1fr 1fr'}
        infiniteScrollProps={infiniteScrollProps}
        height="48vh"
      />
    );
  }, [infiniteScrollProps, renderTableRows, statusProps.isEmpty, statusProps.isError, statusProps.isLoading]);

  return (
    <Fragment>
      <Input
        id="search"
        name="search"
        placeholder="Busque pelos dados cadastrais"
        type="text"
        changeValue={(text) => handleSearch(text)}
        isClear={false}
      />
      {renderContent}
    </Fragment>
  );
};
