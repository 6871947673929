export enum ScheduleListActionType {
  SCHEDULE_LIST_REQUEST = 'SCHEDULE_LIST_REQUEST',
  SCHEDULE_LIST_SUCCESS = 'SCHEDULE_LIST_SUCCESS',
  SCHEDULE_LIST_ERROR = 'SCHEDULE_LIST_ERROR',
  SCHEDULE_LIST_VIEW_MODE = 'SCHEDULE_LIST_VIEW_MODE',
  SCHEDULE_LIST_MODALS = 'SCHEDULE_LIST_MODALS',
  SCHEDULE_LIST_FILTERS = 'SCHEDULE_LIST_FILTERS',
  SCHEDULE_SAVED_FILTERS = 'SCHEDULE_SAVED_FILTERS',
  SCHEDULE_LIST_CHECKBOX = 'SCHEDULE_LIST_CHECKBOX',
  SCHEDULE_LIST_TOTAL_ROW = 'SCHEDULE_LIST_TOTAL_ROW',
  SCHEDULE_LIST_RESET = 'SCHEDULE_LIST_RESET',
}

export enum ScheduleKanbanListActionType {
  SCHEDULE_KANBAN_LIST_REQUEST = 'SCHEDULE_KANBAN_LIST_REQUEST',
  SCHEDULE_KANBAN_LIST_SUCCESS = 'SCHEDULE_KANBAN_LIST_SUCCESS',
  SCHEDULE_KANBAN_LIST_ERROR = 'SCHEDULE_KANBAN_LIST_ERROR',
  SCHEDULE_KANBAN_LIST_RESET = 'SCHEDULE_KANBAN_LIST_RESET',
}

export enum ScheduleOccurrenceListActionType {
  SCHEDULE_OCCURRENCE_LIST_REQUEST = 'SCHEDULE_OCCURRENCE_LIST_REQUEST',
  SCHEDULE_OCCURRENCE_LIST_SUCCESS = 'SCHEDULE_OCCURRENCE_LIST_SUCCESS',
  SCHEDULE_OCCURRENCE_LIST_ERROR = 'SCHEDULE_OCCURRENCE_LIST_ERROR',
  SCHEDULE_OCCURRENCE_LIST_RESET = 'SCHEDULE_OCCURRENCE_LIST_RESET',
}

export enum ScheduleDetailsActionType {
  SCHEDULE_DETAILS_REQUEST = 'SCHEDULE_DETAILS_REQUEST',
  SCHEDULE_DETAILS_SUCCESS = 'SCHEDULE_DETAILS_SUCCESS',
  SCHEDULE_DETAILS_ERROR = 'SCHEDULE_DETAILS_ERROR',
  SCHEDULE_DETAILS_RESET = 'SCHEDULE_DETAILS_RESET',
  SCHEDULE_CUSTOM_FIELDS_SUCCESS = 'SCHEDULE_CUSTOM_FIELDS_SUCCESS',
  SCHEDULE_ALLOCATION_SHIPPING_REQUEST = 'SCHEDULE_ALLOCATION_SHIPPING_REQUEST',
  SCHEDULE_ALLOCATION_SHIPPING_SUCCESS = 'SCHEDULE_ALLOCATION_SHIPPING_SUCCESS',
  SCHEDULE_ALLOCATION_SHIPPING_ERROR = 'SCHEDULE_ALLOCATION_SHIPPING_ERROR',
  SCHEDULE_ALLOCATION_SELECTED_SHIPPING_ID = 'SCHEDULE_ALLOCATION_SELECTED_SHIPPING_ID',
  SCHEDULE_ALLOCATION_VEHICLE_REQUEST = 'SCHEDULE_ALLOCATION_VEHICLE_REQUEST',
  SCHEDULE_ALLOCATION_VEHICLE_SUCCESS = 'SCHEDULE_ALLOCATION_VEHICLE_SUCCESS',
  SCHEDULE_ALLOCATION_VEHICLE_ERROR = 'SCHEDULE_ALLOCATION_VEHICLE_ERROR',
  SCHEDULE_ALLOCATION_DRIVER_REQUEST = 'SCHEDULE_ALLOCATION_DRIVER_REQUEST',
  SCHEDULE_ALLOCATION_DRIVER_SUCCESS = 'SCHEDULE_ALLOCATION_DRIVER_SUCCESS',
  SCHEDULE_ALLOCATION_DRIVER_ERROR = 'SCHEDULE_ALLOCATION_DRIVER_ERROR',
  SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_REQUEST = 'SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_REQUEST',
  SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_SUCCESS = 'SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_SUCCESS',
  SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_ERROR = 'SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_ERROR',
  SCHEDULE_SELECTED_DRIVER = 'SCHEDULE_SELECTED_DRIVER',
  SCHEDULE_SELECTED_VEHICLE = 'SCHEDULE_SELECTED_VEHICLE',
  SCHEDULE_ALLOCATION_OPEN = 'SCHEDULE_ALLOCATION_OPEN',
  SCHEDULE_SET_DRIVER_ID_VEHICLE = 'SCHEDULE_SET_DRIVER_ID_VEHICLE',
  SCHEDULE_ALLOCATION_CLOSE = 'SCHEDULE_ALLOCATION_CLOSE',
  SCHEDULE_ACTIVE_TAB_NAME = 'SCHEDULE_ACTIVE_TAB_NAME',
}

export enum ScheduleRequestStatusActionType {
  SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
  SCHEDULE_REQUEST_SUCCESS = 'SCHEDULE_REQUEST_SUCCESS',
  SCHEDULE_REQUEST_ERROR = 'SCHEDULE_REQUEST_ERROR',
  SCHEDULE_REQUEST_RESET = 'SCHEDULE_REQUEST_RESET',
}

export enum ScheduleListInitialLoadingActionType {
  SCHEDULE_LIST_INITIAL_LOADING_SUCCESS = 'SCHEDULE_LIST_INITIAL_LOADING_SUCCESS',
  SCHEDULE_LIST_INITIAL_LOADING_RESET = 'SCHEDULE_LIST_INITIAL_LOADING_RESET',
}

export enum ScheduleDownloadMapReportActionType {
  SCHEDULE_DOWNLOAD_MAP_REPORT_REQUEST = 'SCHEDULE_DOWNLOAD_MAP_REPORT_REQUEST',
  SCHEDULE_DOWNLOAD_MAP_REPORT_SUCCESS = 'SCHEDULE_DOWNLOAD_MAP_REPORT_SUCCESS',
  SCHEDULE_DOWNLOAD_MAP_REPORT_ERROR = 'SCHEDULE_DOWNLOAD_MAP_REPORT_ERROR',
  SCHEDULE_DOWNLOAD_MAP_REPORT_RESET = 'SCHEDULE_DOWNLOAD_MAP_REPORT_RESET',
}

export enum ScheduleExecutionActionType {
  SCHEDULE_EXECUTION_MAP_ACCORDION_REQUEST = 'SCHEDULE_EXECUTION_MAP_ACCORDION_REQUEST',
  SCHEDULE_EXECUTION_MAP_ACCORDION_SUCCESS = 'SCHEDULE_EXECUTION_MAP_ACCORDION_SUCCESS',
  SCHEDULE_EXECUTION_MAP_ACCORDION_ERROR = 'SCHEDULE_EXECUTION_MAP_ACCORDION_ERROR',
  SCHEDULE_EXECUTION_MAP_ACCORDION_FIREBASE = 'SCHEDULE_EXECUTION_MAP_ACCORDION_FIREBASE',
  SCHEDULE_EXECUTION_MAP_ACCORDION_MONITORING = 'SCHEDULE_EXECUTION_MAP_ACCORDION_MONITORING',
  SCHEDULE_EXECUTION_MAP_ACCORDION_PLANNED = 'SCHEDULE_EXECUTION_MAP_ACCORDION_PLANNED',
  SCHEDULE_EXECUTION_SET_MAP_TYPE = 'SCHEDULE_EXECUTION_SET_MAP_TYPE',
  SCHEDULE_EXECUTION_RESET = 'SCHEDULE_EXECUTION_RESET',
}

export enum ScheduleDocumentsActionType {
  SCHEDULE_DOCUMENTS_NFE_DATA_REQUEST = 'SCHEDULE_DOCUMENTS_NFE_DATA_REQUEST',
  SCHEDULE_DOCUMENTS_NFE_DATA_SUCCESS = 'SCHEDULE_DOCUMENTS_NFE_DATA_SUCCESS',
  SCHEDULE_DOCUMENTS_NFE_DATA_ERROR = 'SCHEDULE_DOCUMENTS_NFE_DATA_ERROR',
  SCHEDULE_DOCUMENTS_CTE_DATA_REQUEST = 'SCHEDULE_DOCUMENTS_CTE_DATA_REQUEST',
  SCHEDULE_DOCUMENTS_CTE_DATA_SUCCESS = 'SCHEDULE_DOCUMENTS_CTE_DATA_SUCCESS',
  SCHEDULE_DOCUMENTS_CTE_DATA_ERROR = 'SCHEDULE_DOCUMENTS_CTE_DATA_ERROR',
  SCHEDULE_DOCUMENTS_MDFE_DATA_REQUEST = 'SCHEDULE_DOCUMENTS_MDFE_DATA_REQUEST',
  SCHEDULE_DOCUMENTS_MDFE_DATA_SUCCESS = 'SCHEDULE_DOCUMENTS_MDFE_DATA_SUCCESS',
  SCHEDULE_DOCUMENTS_MDFE_DATA_ERROR = 'SCHEDULE_DOCUMENTS_MDFE_DATA_ERROR',
  SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_REQUEST = 'SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_REQUEST',
  SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_SUCCESS = 'SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_SUCCESS',
  SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_ERROR = 'SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_ERROR',
  SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_REQUEST = 'SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_REQUEST',
  SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_SUCCESS = 'SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_SUCCESS',
  SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_ERROR = 'SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_ERROR',
  SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_REQUEST = 'SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_REQUEST',
  SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_SUCCESS = 'SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_SUCCESS',
  SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_ERROR = 'SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_ERROR',
  SCHEDULE_DOCUMENTS_CHECKLIST_REQUEST = 'SCHEDULE_DOCUMENTS_CHECKLIST_REQUEST',
  SCHEDULE_DOCUMENTS_CHECKLIST_SUCCESS = 'SCHEDULE_DOCUMENTS_CHECKLIST_SUCCESS',
  SCHEDULE_DOCUMENTS_CHECKLIST_ERROR = 'SCHEDULE_DOCUMENTS_CHECKLIST_ERROR',
  SCHEDULE_DOCUMENTS_MODALS = 'SCHEDULE_DOCUMENTS_MODALS',
  SCHEDULE_DOCUMENTS_RESET = 'SCHEDULE_DOCUMENTS_RESET',
}

export enum ScheduleImportModalActionType {
  SCHEDULE_IMPORT_MODAL_REQUEST = 'SCHEDULE_IMPORT_MODAL_REQUEST',
  SCHEDULE_IMPORT_MODAL_SUCCESS = 'SCHEDULE_IMPORT_MODAL_SUCCESS',
  SCHEDULE_IMPORT_MODAL_ERROR = 'SCHEDULE_IMPORT_MODAL_ERROR',
  SCHEDULE_IMPORT_MODAL_RESET = 'SCHEDULE_IMPORT_MODAL_RESET',
}

export enum ScheduletActionType {
  SCHEDULE_RESET = 'SCHEDULE_RESET',
}
