import styled from 'styled-components';

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 5rem;
`;

export const Title = styled.h4`
  font-weight: 400;
  color: #7f7f7f;
  font-size: 0.875rem;
`;

export const WrapperSubTitleAndTotalValue = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const SubTitle = styled.h4`
  font-weight: 600;
  font-size: 0.875rem;
`;

export const WrapperCurrency = styled.div`
  min-width: 95px;
  max-width: 95px;
  display: flex;
  justify-content: space-between;
`;
