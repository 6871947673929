/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteCTeService,
  deleteNFeService,
  deleteUploadedCte,
  deleteUploadedMdfe,
  deleteUploadedNfe,
  getCTeService,
  getFreightNFeGenericsService,
  getFreightNFeService,
  getScheduleInvoice,
  getUpdateCTeService,
  listUploadedCte,
  listUploadedMdfe,
  postCTeService,
  removeStatusFile,
  sendNFeService,
  sendStatusFile,
  uploadCte,
  uploadMdfe,
} from 'pages/freight/services';
import { showToast } from 'components/toast/toast';

export const useGetFreightNFesHook = () => {
  return async (freightId: string) => {
    return await getFreightNFeService(freightId);
  };
};

export const useGetFreightNFesGenericksHook = () => {
  return async (freightId: string) => {
    const data = await getFreightNFeGenericsService(freightId);
    return data;
  };
};

export const useUploadNFeHook = () => {
  return async (freightId: string, files: any) => {
    return await sendNFeService(freightId, files);
  };
};

export const useDeleteNFeByIdHook = () => {
  return async (freightId: string) => {
    return await deleteNFeService(freightId);
  };
};

export const useGetCTedHook = () => {
  return async (freightId: string) => {
    return await getCTeService(freightId);
  };
};

export const useUpdateCTedHook = () => {
  return async (refId: string) => {
    return await getUpdateCTeService(refId);
  };
};

export const usePostCTedHook = () => {
  return async (freightId: string) => {
    showToast({
      message: 'Gerando CTe',
      type: 'loading',
    });
    return await postCTeService(freightId);
  };
};

export const useDeleteCTedHook = () => {
  return async (freightId: string, reason: string) => {
    showToast({
      message: 'Removendo CTe',
      type: 'loading',
    });
    return await deleteCTeService(freightId, reason);
  };
};

export const useUploadStatusFileHook = () => {
  return async (freightId: string, statusCode: string, files: any) => {
    return await sendStatusFile(freightId, statusCode, files);
  };
};

export const useHandleRemoveStatusFileHook = () => {
  return async (scheduleId: number, fileId: number) => {
    return await removeStatusFile(scheduleId, fileId);
  };
};

export const useHandleUploadCteFileHook = () => {
  return async (freightId: number | string, file: any, isSubCte?: boolean) => {
    return await uploadCte(file, freightId, isSubCte);
  };
};

export const useHandleGetCteUploadedListHook = () => {
  return async (freightId: number | string) => {
    return await listUploadedCte(freightId);
  };
};

export const useHandleDeleteCteUploadedListHook = () => {
  return async (cteId: number | string) => {
    return await deleteUploadedCte(cteId);
  };
};

export const useHandleDeleteNfeUploadedListHook = () => {
  return async (id: number) => {
    return await deleteUploadedNfe(id);
  };
};

export const useGetScheduleInvoicingHook = () => {
  return async (scheduleId: number | string) => {
    try {
      return await getScheduleInvoice(scheduleId);
    } catch (error) {
      console.error(error);
    }
  };
};

export const useHandleUploadMdfeFileHook = () => {
  return async (file: FileList, freightId: number | string) => {
    return await uploadMdfe(file, freightId);
  };
};

export const useHandleGetMdfeUploadedListHook = () => {
  return async (freightId: number | string) => {
    return await listUploadedMdfe(freightId);
  };
};

export const useHandleDeleteMdfeUploadedListHook = () => {
  return async (mdfeId: number | string) => {
    return await deleteUploadedMdfe(mdfeId);
  };
};
