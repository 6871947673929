import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { UserContext } from 'state/user-context';
import { ISaleOfferListDomain } from '../../../../domains/sale-offer-list.domain';
import { SaleOfferTableView } from './sale-offer-table-view.component';
import { paginationInitialValues } from 'src-new/domains/list-page.domain';
import { useQueryClient } from 'react-query';
import { SaleOfferListServiceResponse } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/services/get-sale-offer-list/get-sale-offer-list.service';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';

export const SaleOfferTable: React.FC = () => {
  const { saleOfferModals, saleOfferList, saleOfferMatchList } = useContext(SaleOfferContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { isAdmin } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { filters, setFilters } = pageHeaderFilterContext;
  const filtersRef = useRef(filters);
  const { setModalOpen } = saleOfferModals;
  const { selectedIds, setSelectIds } = saleOfferList;
  const { saleOfferMatchProposal } = saleOfferMatchList;
  const { setSaleOfferMatchProposalRequest, setSaleOfferMatchProposalSuccess } = saleOfferMatchProposal;
  const { loading } = initialLoading;
  filtersRef.current = filters;

  const cache = queryClient.getQueryData<SaleOfferListServiceResponse>(['sale-offer-list-table', filters]);

  const getMetaData = useMemo(() => {
    if (cache) return cache.meta;
    return paginationInitialValues;
  }, [cache]);

  const handleOpenModalMatch = useCallback(
    (saleOfferProposal: ISaleOfferListDomain) => {
      setSaleOfferMatchProposalRequest();
      setModalOpen({
        indexes: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
        modalType: 'sale_offer_match',
      });
      setSaleOfferMatchProposalSuccess(saleOfferProposal);
    },
    [setModalOpen, setSaleOfferMatchProposalRequest, setSaleOfferMatchProposalSuccess],
  );

  const handleSaleOfferCopy = useCallback(
    (saleOfferProposal: ISaleOfferListDomain) =>
      navigate('/ofertas-venda/novo', { state: { saleOfferId: saleOfferProposal.id } }),
    [navigate],
  );

  const handleLineClick = useCallback(
    (id: number) => {
      navigate(`/ofertas-venda/atualizar/${id}`);
    },
    [navigate],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      const selectedFiltersToSend = {
        ...filtersRef.current,
        orderBy: order,
        column: columnName,
      };
      if (columnName === 'ID') {
        selectedFiltersToSend.column = 'TARIFF_ID';
      }
      setFilters(selectedFiltersToSend);
    },
    [setFilters],
  );

  const handleSelectIds = useCallback(
    (checked: boolean, id: number) => {
      if (checked) {
        const newIds = [...selectedIds, id];
        return setSelectIds(newIds);
      }
      const removeIds = selectedIds.filter((removeId) => removeId !== id);
      setSelectIds(removeIds);
    },
    [selectedIds, setSelectIds],
  );

  const getSaleOffer = useMemo(() => saleOfferList.saleOfferList, [saleOfferList.saleOfferList]);

  const isLoading = useMemo(() => loading, [loading]);

  const isLazyLoading = useMemo(
    () => saleOfferList.statusRequest === 'PROCESSING' && !loading,
    [loading, saleOfferList.statusRequest],
  );

  const isEmpty = useMemo(
    () => saleOfferList.statusRequest === 'SUCCESS' && !getSaleOffer.length,
    [getSaleOffer.length, saleOfferList.statusRequest],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: filters.column,
      order: filters.orderBy,
      handleClick: handleOrdering,
    };
  }, [handleOrdering, filters.column, filters.orderBy]);

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (page < getMetaData.totalPages) {
      setFilters({ ...filtersRef.current, page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMetaData.totalPages, page]);
  return (
    <SaleOfferTableView
      saleOffer={getSaleOffer}
      isAdmin={isAdmin}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      isEmpty={isEmpty}
      selectedIds={selectedIds}
      infiniteScrollProps={infiniteScrollProps}
      handleLineClick={handleLineClick}
      handleSelectIds={handleSelectIds}
      handleOpenModalMatch={handleOpenModalMatch}
      handleSaleOfferCopy={handleSaleOfferCopy}
    />
  );
};
