import { IRegistrationsContext } from '../types/registrations.types';

export const registrationsReducerResetState = (state: IRegistrationsContext): IRegistrationsContext => {
  return {
    ...state,
    contactDetailsSection: {
      ...state.contactDetailsSection,
      isOpenModal: false,
    },
    attachDocumentsSection: {
      ...state.attachDocumentsSection,
      getAttachDocuments: {
        ...state.attachDocumentsSection.getAttachDocuments,
        statusRequest: 'INITIAL',
      },
      isOpenModal: false,
    },
    riskManagementSection: {
      ...state.riskManagementSection,
      modalProps: {
        ...state.riskManagementSection.modalProps,
        isOpenModal: false,
      },
    },
  };
};
