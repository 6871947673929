import { useNavigate } from 'react-router-dom';
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { downloadTemplate } from 'utils/download-file/download-file';
import { LocationsContext } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.context';
import { LocationListPageBodyView } from './location-list-page-body-view.component';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { useExportReport } from 'src-new/pages/registrations/hooks/use-export-report/use-export-report.hook';
import { useImportRecordsSpreadsheet } from 'src-new/pages/registrations/hooks/use-import-records-spreadsheet/use-import-records-spreadsheet.hook';
import { useDeleteLocations } from '../../hooks/use-delete-locations/use-delete-locations.hook';
import { useGetLocationList } from '../../hooks/use-get-location-list/use-get-location-list.hook';
import { IConfirmDeleteModalProps, IRemoveItemsProps } from './location-list-page-body.types';
import {
  ExportReportProps,
  IButtonProps,
  UploadSpreadsheetProps,
} from 'src-new/components/application-page-header/application-page-header.types';

export const LocationListPageBody: React.FC = (): ReactElement => {
  const { locationsList } = useContext(LocationsContext);
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [activeTabName, setActiveTabName] = useState<string>('Ativas');
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [isLoadingExclude, setIsLoadingExclude] = useState<boolean>(false);
  const navigate = useNavigate();
  const deleteLocations = useDeleteLocations();
  const getLocationList = useGetLocationList();
  const importLocations = useImportRecordsSpreadsheet();
  const generateExcel = useExportReport();
  const { statusRequest } = locationsList;
  const { filters, setFilters } = locationsList.filterActions;

  const goToCreateLocation = useCallback(() => {
    navigate('/unidades/novo');
  }, [navigate]);

  const handleRemoveLocation = useCallback(async () => {
    deleteLocations({ ids: idsToRemove, setIdsToRemove, setOpenConfirmModal, setIsLoadingExclude });
  }, [deleteLocations, idsToRemove]);

  const handleOpenModalRemove = useCallback(() => {
    setOpenConfirmModal(true);
  }, []);

  const handleCloseConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  const handleGenerateExcel = useCallback(async () => {
    setIsLoadingExport(true);
    const file: ArrayBuffer | undefined = await generateExcel(
      filters.searchValue,
      'LOCATION',
      activeTabName === 'Ativas' ? 'ativo' : 'inativo',
    );

    if (file) {
      downloadXlsxFileUtil(file, 'relatório-unidades');
    }
    setIsLoadingExport(false);
  }, [generateExcel, filters.searchValue, activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Unidades.xlsx');
  }, []);

  const handleOpenUploadXlsx = useCallback(async () => {
    setShowModal(true);
  }, []);

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    (formData: FormData) => {
      importLocations(formData, setShowModal, 'LOCATION');
    },
    [importLocations],
  );

  const handleSearchInput = useCallback(
    (value: string) => {
      setPageInfinityScroll(1);
      setFilters({ ...filters, page: 1, searchValue: value });
    },
    [filters, setFilters],
  );

  const uploadModalProps = useMemo(
    (): UploadSpreadsheetProps => ({
      showModal,
      uploadModalProps: {
        title: 'Cadastro de Unidades',
        description: 'Selecione o arquivo',
        handleCancel: showModalConfirmation,
        handleSuccess,
        handleDownload,
      },
      handleOpenUploadXlsx: handleOpenUploadXlsx,
    }),
    [handleDownload, handleOpenUploadXlsx, handleSuccess, showModal, showModalConfirmation],
  );

  const confirmDeleteModalProps = useMemo(
    (): IConfirmDeleteModalProps => ({
      isOpen: openConfirmModal,
      isLoading: isLoadingExclude,
      handleRemoveLocation,
      handleCloseConfirmModal,
    }),
    [handleCloseConfirmModal, handleRemoveLocation, isLoadingExclude, openConfirmModal],
  );
  const removeItemsProps = useMemo(
    (): IRemoveItemsProps => ({
      label: 'Excluir',
      isVisible: idsToRemove.length > 0,
      isLoading: false,
      setIds: setIdsToRemove,
      handleActionButton: handleOpenModalRemove,
    }),
    [handleOpenModalRemove, idsToRemove.length],
  );

  const exportReportProps = useMemo(
    (): ExportReportProps => ({
      isLoading: isLoadingExport,
      onHandleExport: handleGenerateExcel,
    }),
    [handleGenerateExcel, isLoadingExport],
  );

  const createButtonProps = useMemo((): IButtonProps => {
    return {
      isVisible: true,
      isLoading: false,
      handleActionButton: goToCreateLocation,
      label: 'Novo Registro',
    };
  }, [goToCreateLocation]);

  useEffect(() => {
    if (statusRequest === 'INITIAL') {
      getLocationList();
    }
  }, [getLocationList, statusRequest]);

  useEffect(() => {
    if (!filters.isPageLimit && pageInfinityScroll !== filters.page) {
      setFilters({ ...filters, page: pageInfinityScroll });
    }
  }, [filters, pageInfinityScroll, setFilters]);

  return (
    <Fragment>
      <LocationListPageBodyView
        goToCreateLocation={goToCreateLocation}
        handleSearchInput={handleSearchInput}
        setActiveTabName={setActiveTabName}
        exportReportProps={exportReportProps}
        removeItemsProps={removeItemsProps}
        activeTab={activeTabName}
        handleInfinityScroll={setPageInfinityScroll}
        uploadModalProps={uploadModalProps}
        confirmDeleteModalProps={confirmDeleteModalProps}
        createButtonProps={createButtonProps}
      />
    </Fragment>
  );
};
