import { IWeighingDomain, IWeighingLocationsDomain } from './weighing-details.domain';
import dayjs from 'dayjs';

const weighingLocationsDetailsInitialValuesDomain: IWeighingLocationsDomain = {
  id: 0,
  type: '',
  name: '',
  city: '',
  uf: '',
  lat: '',
  lng: '',
  complement: '',
  neighborhood: '',
  numberHouse: '',
  street: '',
  cep: '',
};

export const weighingFormDetailsInitialValuesDomain: IWeighingDomain = {
  id: 0,
  ticketStatus: '',
  documentNumber: '',
  entryDate: dayjs().toDate().toISOString(),
  entryWeight: 0,
  exitDate: dayjs().toDate().toISOString(),
  exitWeight: 0,
  netWeight: 0,
  originWeight: 0,
  operation: '',
  smpValidation: '',
  po: '',
  scheduledDateTime: dayjs().toDate().toISOString(),
  palletCapacity: 0,
  tonCapacity: 0,
  collectionOrder: '',
  schedule: {
    id: 0,
  },
  vehicle: {
    id: 0,
    mainPlate: '',
    category: '',
    type: '',
    secondPlate: ''
  },
  driver: {
    id: 0,
    name: '',
    phone: '',
    cpf: '',
  },
  shippingCompany: {
    id: 0,
    name: '',
  },
  origin: weighingLocationsDetailsInitialValuesDomain,
  destination: weighingLocationsDetailsInitialValuesDomain,
  item: {
    id: 0,
    description: '',
  },
  ticketId: 0,
  nfWeight: 0,
  observation: ''
};
