import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { Button } from 'logshare-ui-kit';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import * as S from './styled';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getScheduleByIdService } from 'pages-v2/schedule/services/get-schedule-by-id';
import { clientDetailsService } from 'pages/client/services/client.service';
import { localitiesFilterSearchService } from 'pages/backhaul/services/locality-search/locality-filter-search.service';
import { clientsFilterSearchService } from 'pages/backhaul/services/clients-search/clients-filter-search.service';
import {
  IPallet,
  optionsCheckStubStatus,
  optionsPalletType,
  palletOptionsOperationType,
} from 'domain-v2/pallet/register';
import { checkStubsInitialValues } from 'domain-v2/check-stub';
import { FooterPage } from 'components-v2/common/footer-page';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import InputTextarea from 'components/input-textarea/input-textarea';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';
import TooltipComponent from 'components/Tooltip';

export const InnerForm = (props: FormikProps<IPallet>) => {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (values.checkStub?.checkStubs && values.checkStub.checkStubs.length > 0) return;

    setFieldValue('checkStub.checkStubs', checkStubsInitialValues);
  }, [values.checkStub?.checkStubs, setFieldValue]);

  const handleGoback = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const clientApiCall = useCallback(async (searchValue: string) => {
    if (searchValue) return await clientsFilterSearchService(searchValue);

    return [];
  }, []);

  const localityApiCall = useCallback(async (searchValue: string) => {
    if (searchValue) return await localitiesFilterSearchService(searchValue);

    return [];
  }, []);

  const handleCompleteInputsOrigin = useCallback(
    (originId: number) => {
      clientDetailsService(String(originId)).then((client) => {
        setFieldValue('clientId', originId);
        setFieldValue('originCity', client.address.city);
        setFieldValue('client.tradeName', client.tradeName);
      });
    },
    [setFieldValue],
  );

  const handleCompleteInputSchedule = useCallback(async () => {
    try {
      showMessageFormatted({
        message: 'Aguarde enquanto carrega as informações da viagem.',
        type: 'info',
      });

      const schedule = await getScheduleByIdService(values.scheduleId ?? 0);

      if (schedule.origin.type === schedule.destination.type) {
        const type = schedule.origin.type === 'CLIENT' ? 'Centro de Distribuição' : 'Cliente';

        showMessageFormatted({
          message: `${type} não encontrado!`,
          type: 'error',
        });

        return;
      }

      if (schedule.origin.type === 'LOCALITY') {
        setFieldValue('clientId', schedule.destination.id);
        setFieldValue('client.tradeName', schedule.destination.name);
      } else {
        setFieldValue('clientId', schedule.origin.id);
        setFieldValue('client.tradeName', schedule.origin.name);
      }

      if (schedule.destination.type === 'CLIENT') {
        setFieldValue('distributionCenterId', schedule.origin.id);
        setFieldValue('distributionCenter.name', schedule.origin.name);
      } else {
        setFieldValue('distributionCenterId', schedule.destination.id);
        setFieldValue('distributionCenter.name', schedule.destination.name);
      }

      setFieldValue('operationType', 'coleta');
      setFieldValue('collectDate', dayjs(schedule.detail.levyInitialDate));
      setFieldValue('originCity', schedule.origin.address.city);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [values.scheduleId, setFieldValue]);

  const handleDate = useCallback(
    (date: dayjs.Dayjs | null) => {
      if (!date) return;

      const expirationDate = new Date(date.toISOString());

      expirationDate.setMonth(expirationDate.getMonth() + 6);

      setFieldValue('equipmentVoucherDate', date.toISOString());
      setFieldValue('expirationDate', expirationDate.toISOString());
    },
    [setFieldValue],
  );

  const handleAddCheckStub = useCallback(() => {
    const newCheckStubId =
      values.checkStub?.checkStubs && values.checkStub.checkStubs.length > 0
        ? values.checkStub.checkStubs[values.checkStub.checkStubs.length - 1].id + 1
        : 0;

    setFieldValue(
      'checkStub.checkStubs',
      values.checkStub?.checkStubs.concat({
        id: newCheckStubId,
        number: 0,
      }),
    );
  }, [values.checkStub?.checkStubs, setFieldValue]);

  const handleRemoveCheckStub = useCallback(
    (checkStubId: number) => {
      const checkStub = values.checkStub?.checkStubs.filter((item) => item.id !== checkStubId);
      setFieldValue('checkStub.checkStubs', checkStub);
    },
    [values.checkStub?.checkStubs, setFieldValue],
  );

  const renderCheckStub = useMemo(() => {
    return values.checkStub?.checkStubs.map((item, index) => {
      return (
        <S.LineSection key={item.id} columns="1fr 0.01fr">
          <InputField
            label="Número do Canhoto"
            id={`checkStub.checkStubs[${index}].number`}
            name={`checkStub.checkStubs[${index}].number`}
            type="number"
            value={item.number}
            onChange={handleChange}
          />

          <S.ContainerButtons>
            {index === 0 ? (
              <TooltipComponent
                title="Adicionar"
                location="bottom"
                icon={
                  <Button
                    label=""
                    color="blue"
                    onPress={handleAddCheckStub}
                    LeftIcon={<AddIcon sx={{ width: 18 }} />}
                    size="x-sm"
                    variant="solid"
                  />
                }
              />
            ) : (
              <TooltipComponent
                title="Deletar"
                location="bottom"
                icon={
                  <Button
                    label=""
                    color="red"
                    onPress={() => handleRemoveCheckStub(item.id ?? 0)}
                    LeftIcon={<DeleteIcon sx={{ width: 18 }} />}
                    size="x-sm"
                    variant="solid"
                  />
                }
              />
            )}
          </S.ContainerButtons>
        </S.LineSection>
      );
    });
  }, [values.checkStub, handleAddCheckStub, handleRemoveCheckStub, handleChange]);

  return (
    <S.ContentForm>
      <SectionDivider label="Dados da Viagem" />
      <S.Section>
        <S.LineSection columns="1fr 1fr 1fr 1fr">
          <S.LineSection columns="1fr 0.1fr">
            <InputField
              label="ID da Viagem"
              id="scheduleId"
              name="scheduleId"
              type="number"
              value={values.scheduleId}
              onChange={handleChange}
            />

            <S.ContainerButtons>
              <TooltipComponent
                title="Buscar"
                location="bottom"
                icon={
                  <Button
                    label=""
                    color="aqua"
                    onPress={handleCompleteInputSchedule}
                    LeftIcon={<SearchTwoToneIcon sx={{ width: 18 }} />}
                    size="x-sm"
                    variant="solid"
                  />
                }
              />
            </S.ContainerButtons>
          </S.LineSection>

          <InputField
            label="Vale Equipamento *"
            id="equipmentVoucher"
            name="equipmentVoucher"
            type="text"
            value={values.equipmentVoucher}
            onChange={handleChange}
            hasError={!!errors.equipmentVoucher && !!touched.equipmentVoucher}
            errorMessage={String(errors.equipmentVoucher)}
          />

          <Select
            label="Tipo de Operação"
            id="operationType"
            name="operationType"
            value={values.operationType}
            setFieldValue={setFieldValue}
            options={palletOptionsOperationType}
            hasError={!!errors.operationType && !!touched.operationType}
            errorMessage={String(errors.operationType)}
          />

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Coleta"
              format="DD/MM/YYYY  HH:mm"
              views={['year', 'month', 'day', 'hours', 'minutes']}
              value={dayjs(values.collectDate ?? '')}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('collectDate', date?.toISOString());
              }}
            />
          </S.ContainerInputDates>
        </S.LineSection>

        <S.LineSection columns="1fr 1fr 1fr">
          <AsyncSelectField
            label="Cliente"
            placeholder="Selecione o Cliente"
            name="clientId"
            id="clientId"
            value={{ label: values.client?.tradeName ?? '', value: values.clientId ?? 0 }}
            onChange={(e) => handleCompleteInputsOrigin(e?.value ?? 0)}
            apiCallFunction={clientApiCall}
            loadOptions={clientApiCall}
            cacheOptions
            defaultOptions
          />

          <InputField
            label="Cidade de Origem"
            id="originCity"
            name="originCity"
            type="text"
            value={values.originCity}
            onChange={handleChange}
          />

          <AsyncSelectField
            label="Centro de Distribuição"
            placeholder="Selecione o Centro de Distribuição"
            name="distributionCenterId"
            id="distributionCenterId"
            value={{ label: values.distributionCenter?.name ?? '', value: values.distributionCenterId ?? 0 }}
            onChange={(e) => {
              setFieldValue('distributionCenterId', e && e.value);
              setFieldValue('distributionCenter.name', e && e.label);
            }}
            apiCallFunction={localityApiCall}
            loadOptions={localityApiCall}
            cacheOptions
            defaultOptions
          />
        </S.LineSection>
      </S.Section>

      <SectionDivider label="Dados do Palete" />
      <S.Section>
        <S.LineSection columns="1fr 1fr">
          <Select
            label="Tipo de Palete *"
            id="palletType"
            name="palletType"
            value={values.palletType}
            setFieldValue={setFieldValue}
            options={optionsPalletType}
            hasError={!!errors.palletType && !!touched.palletType}
            errorMessage={String(errors.palletType)}
          />

          <InputField
            label="Quantidade *"
            id="palletQuantity"
            name="palletQuantity"
            type="number"
            value={values.palletQuantity}
            onChange={handleChange}
            hasError={!!errors.palletQuantity && !!touched.palletQuantity}
            errorMessage={String(errors.palletQuantity)}
          />
        </S.LineSection>

        <S.LineSection columns="1fr 1fr 1fr 1fr 1fr">
          <S.ContainerInputDates>
            <DateTimePikers
              label="Data do Vale Palete *"
              format="DD/MM/YYYY"
              views={['year', 'month', 'day']}
              value={dayjs(values.equipmentVoucherDate)}
              minDate={dayjs().startOf('day')}
              onChange={handleDate}
              validateField={!!errors.equipmentVoucherDate && !!touched.equipmentVoucherDate}
              errorMessage={String(errors.equipmentVoucherDate)}
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Validade"
              format="DD/MM/YYYY"
              views={['year', 'month', 'day']}
              value={dayjs(values.expirationDate ?? '')}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('expirationDate', date?.toISOString())}
              disabled
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Retirada *"
              format="DD/MM/YYYY"
              views={['year', 'month', 'day']}
              value={dayjs(values.withdrawalDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('withdrawalDate', date?.toISOString())}
              validateField={!!errors.withdrawalDate && !!touched.withdrawalDate}
              errorMessage={String(errors.withdrawalDate)}
            />
          </S.ContainerInputDates>
          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Lançamento *"
              format="DD/MM/YYYY"
              views={['year', 'month', 'day']}
              value={dayjs(values.entryDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('entryDate', date?.toISOString())}
              validateField={!!errors.entryDate && !!touched.entryDate}
              errorMessage={String(errors.entryDate)}
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Liberação"
              format="DD/MM/YYYY"
              views={['year', 'month', 'day']}
              value={dayjs(values.liberationDate ?? '')}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('liberationDate', date?.toISOString())}
              disabled
            />
          </S.ContainerInputDates>
        </S.LineSection>
      </S.Section>

      <SectionDivider label="Canhotos" />
      <S.Section>
        <S.LineSection columns="1fr 1fr 1fr 1fr 1fr">
          <Select
            label="Status"
            id="checkStub.status"
            name="checkStub.status"
            value={values.checkStub?.status}
            setFieldValue={setFieldValue}
            options={optionsCheckStubStatus}
          />

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Recebimento"
              format="DD/MM/YYYY  HH:mm"
              views={['year', 'month', 'day', 'hours', 'minutes']}
              value={dayjs(values.checkStub?.receiptDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('checkStub.receiptDate', date?.toISOString())}
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Entrega"
              format="DD/MM/YYYY  HH:mm"
              views={['year', 'month', 'day', 'hours', 'minutes']}
              value={dayjs(values.checkStub?.deliveryDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('checkStub.deliveryDate', date?.toISOString())}
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Retirada"
              format="DD/MM/YYYY  HH:mm"
              views={['year', 'month', 'day', 'hours', 'minutes']}
              value={dayjs(values.checkStub?.withdrawalDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('checkStub.withdrawalDate', date?.toISOString())}
            />
          </S.ContainerInputDates>

          <S.ContainerInputDates>
            <DateTimePikers
              label="Data de Lançamento"
              format="DD/MM/YYYY  HH:mm"
              views={['year', 'month', 'day', 'hours', 'minutes']}
              value={dayjs(values.checkStub?.entryDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => setFieldValue('checkStub.entryDate', date?.toISOString())}
            />
          </S.ContainerInputDates>
        </S.LineSection>

        {renderCheckStub}
      </S.Section>

      <SectionDivider label="Observações" />
      <S.Section>
        <S.LineSection columns="1fr">
          <InputTextarea id="note" name="note" value={values.note} onChange={handleChange} />
        </S.LineSection>
      </S.Section>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Salvar" color="pink" onPress={handleSubmit} />
          <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </S.ContentForm>
  );
};
