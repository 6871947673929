import { IPortSchedule } from 'domain/santos-port';

export const initialValues: IPortSchedule = {
  dleId: '',
  placa: '',
  statusDle: '',
  detalheTipoCarga: '',
  dtAcessoPortoDle: '',
  dtEntradaPuxada: '',
  dtEntradaTerminal: '',
  dtEntradaTerminalpp: '',
  dtSaidaPuxada: '',
  dtSaidaTerminal: '',
  empresaOrigemCep: '',
  empresaOrigemCnpj: '',
  terminalDestino: '',
  client: {
    cnpj: '',
    companyName: '',
    address: '',
    addressType: '',
    addressNumber: '',
    complement: '',
    district: '',
    cep: '',
    city: '',
    sp: '',
  },
  vehicle: {
    nome: '',
    placa: '',
    cidade: '',
    codigoMunicipio: '',
    cor: '',
    municipio: '',
    quantidadeEixos: '',
    tipo: '',
  },
};
