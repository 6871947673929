import { IFreightContext, IFreightContextError } from './types/freight.types';

export const initialError: IFreightContextError = {
  title: '',
  description: '',
};

export const freightInitialState: IFreightContext = {
  freightChat: {
    statusRequest: 'INITIAL',
    photoUrl: '',
    chatActions: {
      isOpen: false,
      setChatOpen: () => undefined,
      setChatClose: () => undefined,
    },
    setFreightChatRequest: () => undefined,
    setFreightChatSuccess: () => undefined,
    setFreightChatError: () => undefined,
    setFreightChatReset: () => undefined,
  },
  setFreightResetContext: () => undefined,
};
