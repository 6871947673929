/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useMemo } from 'react';

import SantosPortTable from '../santos-port-table/santos-port-table';

import { ContainerSearchStyled } from './santos-port-list-body-view.styled';

import { IPortSchedule } from 'domain/santos-port';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Button from 'components/button/button';
import BackhaulIcon from 'assets/icons/backhaul.icon';

interface ISantosPortListBodyViewProps {
  santosPort: Array<IPortSchedule>;
  hasLoading: boolean;
  santosPortPageInfo?: PageInfo;
  setCurrentPageSantosPorts: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  goToDetailsPage: (santosPortId: any) => void;
  onHandleExport: (value: ReportOption) => void;
  handleUpdateSantosPortList: () => void;
  options: Array<ReportOption>;
}

const dataTestId = 'location-list-body-view';

const SantosPortListBodyView: React.FC<ISantosPortListBodyViewProps> = ({
  santosPort,
  santosPortPageInfo,
  hasLoading,
  setCurrentPageSantosPorts,
  setSearchInput,
  goToDetailsPage,
  setActiveTabName,
  onHandleExport,
  handleUpdateSantosPortList,
  options,
}: ISantosPortListBodyViewProps): JSX.Element => {
  const contentTable = useMemo((): Array<IContentTable> => {
    return santosPort.map((santosPort) => {
      return [
        {
          value: santosPort.placa,
          flex: 0.8,
        },
        {
          value: santosPort.dleId,
          flex: 0.7,
        },
        {
          value: santosPort.statusDle,
          flex: 1.8,
        },
        {
          value: santosPort.detalheTipoCarga,
          flex: 1.8,
        },
        {
          value: santosPort.empresaOrigemCnpj,
          flex: 0.9,
        },

        {
          value: santosPort.dtAcessoPortoDle,
          flex: 1,
        },
        {
          value: santosPort.dtSaidaTerminal,
          flex: 1,
        },
      ];
    });
  }, [santosPort]);

  const renderSantosPortTable = useMemo(() => {
    return (
      <SantosPortTable
        contentTable={contentTable}
        pageInfo={santosPortPageInfo}
        changePage={setCurrentPageSantosPorts}
        isLoading={hasLoading}
        goToDetailsPage={goToDetailsPage}
      />
    );
  }, [contentTable, goToDetailsPage, hasLoading, santosPortPageInfo, setCurrentPageSantosPorts]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Todos',
        content: renderSantosPortTable,
      },
    ];
  }, [renderSantosPortTable]);

  return (
    <Fragment>
      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Porto de Santos', 'Registros']} />
      <Title dataTestId={dataTestId} title="Registros" hasTrace />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Nome ou Cidade"
          type="text"
          changeValue={setSearchInput}
        />

        <DropDown title="Exportar">
          <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
        </DropDown>

        <Button
          title="Atualizar"
          bgColor="green"
          callback={handleUpdateSantosPortList}
          icon={<BackhaulIcon />}
          size="large"
        />
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />
    </Fragment>
  );
};

export default SantosPortListBodyView;
