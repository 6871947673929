import { FC, Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import { Laptop2, Smartphone, Pencil } from 'lucide-react';
import * as S from './execution-time-recording-view.styled';
import { Box } from 'src-new/components/box/box.component';
import {
  ITimeRecording,
  ITimeRecordingLabel,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/components/execution-time-recording/execution-time-recording.types';
import { TimeRecordingModal } from './components/execution-time-recording-edit-modal/execution-time-recording-edit-modal.component';
import { TimeRecordingCheckModal } from './components/execution-time-recording-check-modal/execution-time-recording-check-modal.component';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { Formik } from 'formik';
import { IScheduleDetailsComplementDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { ThemeColorsV2Type } from 'styles/types/colors';
import Checkbox from 'components/checkbox/checkbox';
import { Check } from 'lucide-react';
import TooltipComponent from 'components/Tooltip';
import {
  IScheduleDetailsAllocationActionsProps,
  IScheduleDetailsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import theme from 'styles/theme';

interface IExecutionTimeRecordingViewProps {
  timeRecordingData: Array<ITimeRecording>;
  timeRecording: IScheduleDetailsComplementDomain;
  modalOptions: IScheduleDetailsAllocationActionsProps;
  disabledAllocationCheckbox: boolean;
  handleModal: (open: boolean, modalType: IScheduleDetailsModalType) => void;
  handleSubmit: (values: IScheduleDetailsComplementDomain) => void;
  handleCheckTimeRecording: (isChecked: boolean, label: ITimeRecordingLabel) => void;
}

const dataTestId = 'execution-time-recording';

export const ExecutionTimeRecordingView: FC<IExecutionTimeRecordingViewProps> = ({
  timeRecordingData,
  timeRecording,
  modalOptions,
  disabledAllocationCheckbox,
  handleModal,
  handleSubmit,
  handleCheckTimeRecording,
}) => {
  const disabledCheckbox = useCallback(
    (index: number, timeLine: ITimeRecording): boolean =>
      disabledAllocationCheckbox || !timeRecordingData[index - 1]?.active || timeLine.active,
    [disabledAllocationCheckbox, timeRecordingData],
  );

  const hasStatus = useCallback((index: number, timeLine: ITimeRecording): boolean => timeLine.realDate !== '-', []);

  const renderModalEdit = useMemo((): ReactElement | undefined => {
    if (modalOptions.isOpen) {
      if (modalOptions.allocatedEntity === 'time-recording') {
        return (
          <ModalBark title="Registro de Horários" size="sm" handleClose={() => handleModal(false, 'time-recording')}>
            <Formik initialValues={timeRecording} onSubmit={handleSubmit}>
              <TimeRecordingModal />
            </Formik>
          </ModalBark>
        );
      }

      const timeRecordingLabelValues = Object.values(ITimeRecordingLabel);
      if (timeRecordingLabelValues.includes(modalOptions.allocatedEntity as ITimeRecordingLabel)) {
        return <TimeRecordingCheckModal />;
      }
    }
  }, [handleModal, handleSubmit, modalOptions.allocatedEntity, modalOptions.isOpen, timeRecording]);

  const renderTimeline = useMemo((): ReactElement => {
    return (
      <S.TimeLineWrapper>
        <S.TimeLineHeader>
          <LineSectionLayout columns={'1.5fr 0.8fr 0.8fr 0.5fr'}>
            <S.LabelTitle>Etapas</S.LabelTitle>
            <S.LabelTitle>Planejado</S.LabelTitle>
            <S.LabelTitle>Realizado</S.LabelTitle>
            <S.LabelTitle>Status</S.LabelTitle>
          </LineSectionLayout>
        </S.TimeLineHeader>
        {timeRecordingData.map((timeLine, index) => (
          <S.TimeLineContainer
            key={`${dataTestId}-${timeLine.label.toLowerCase().replace(' ', '-')}`}
            backgroundGrayColor={index % 2 === 0}
          >
            <LineSectionLayout columns={'1.5fr 0.8fr 0.8fr 0.5fr'}>
              <S.TimeLineContent>
                <S.TimeLineContentDot active={timeLine.active}>
                  <Check size={11} strokeWidth={2} color="white" />
                </S.TimeLineContentDot>
                <S.TimeLineContentLine active={timeLine.active} hide={timeRecordingData.length - 1 == index} />
                <S.LabelContent>{timeLine.label}</S.LabelContent>
              </S.TimeLineContent>

              <S.TimeLineContent>
                <S.LabelTime>{timeLine.planDate}</S.LabelTime>
              </S.TimeLineContent>

              <S.TimeLineContent>
                <S.DateContainer active={timeLine.active}>
                  <S.LabelTime>{timeLine.realDate}</S.LabelTime>

                  {timeLine.realDate !== '-' &&
                    (timeLine.agent === 'web' ? (
                      <TooltipComponent
                        title="Baixa via WEB"
                        location="right"
                        icon={<Laptop2 size={13} color="#7F7F7F" strokeWidth={2.75} />}
                      />
                    ) : (
                      <TooltipComponent
                        title="Baixa via APP"
                        location="right"
                        icon={<Smartphone size={13} color="#7F7F7F" strokeWidth={2.75} />}
                      />
                    ))}
                </S.DateContainer>
              </S.TimeLineContent>

              <S.TimeLineContent>
                <S.TimeLineStatusCotent>
                  {hasStatus(index, timeLine) ? (
                    <Tag
                      label={timeLine.status ? 'ON TIME' : 'ATRASADO'}
                      color={timeLine.status ? ThemeColorsV2Type.Blue : ThemeColorsV2Type.Red}
                      variant="ghost"
                      display="flex"
                      size="md"
                    />
                  ) : (
                    <S.Div />
                  )}
                  {disabledCheckbox(index, timeLine) ? (
                    <Checkbox
                      id="timeRecording"
                      name="timeRecording"
                      onChange={() => handleCheckTimeRecording(timeLine.active, timeLine.label)}
                      checked={timeLine.active}
                      backgroundColor={theme.colorsV2.lightGray}
                      disabled
                    />
                  ) : (
                    <TooltipComponent
                      title="Baixa manual"
                      location="bottom"
                      icon={
                        <Checkbox
                          id="timeRecording"
                          name="timeRecording"
                          onChange={() => handleCheckTimeRecording(timeLine.active, timeLine.label)}
                          checked={timeLine.active}
                          backgroundColor={theme.colorsV2.lightGray}
                        />
                      }
                    />
                  )}
                </S.TimeLineStatusCotent>
              </S.TimeLineContent>
            </LineSectionLayout>
          </S.TimeLineContainer>
        ))}
      </S.TimeLineWrapper>
    );
  }, [disabledCheckbox, handleCheckTimeRecording, hasStatus, timeRecordingData]);

  return (
    <Fragment>
      {renderModalEdit}
      <Box
        footerButtons={[
          <S.ButtonBoxContainer key={dataTestId}>
            <S.ContainerButtons>
              <Button
                label=""
                color="white"
                onPress={() => handleModal(true, 'time-recording')}
                LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
                size="x-sm"
                variant="ghost"
              />
            </S.ContainerButtons>
          </S.ButtonBoxContainer>,
        ]}
      >
        <S.BoxContainer>{renderTimeline}</S.BoxContainer>
      </Box>
    </Fragment>
  );
};
