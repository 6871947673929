import { useCallback, useReducer } from 'react';
import { purchaseOfferModalsReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-modals/purchase-offer-modals.reducer';
import { PurchaseOfferModalsActionType } from '../../purchase-offer.action';
import { purchaseOfferInitialState } from '../../purchase-offer.constants';
import {
  IPurchaseOfferModals,
  IPurchaseOfferModalsType,
  IPurchaseOfferSecondModalsType,
} from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-modals.types';

export const usePurchaseOfferModalContextValues = (): IPurchaseOfferModals => {
  const [state, dispatch] = useReducer(purchaseOfferModalsReducer, purchaseOfferInitialState);

  const setModalOpen = useCallback(
    (payload: { modalType: IPurchaseOfferModalsType; secondModalType?: IPurchaseOfferSecondModalsType }) => {
      dispatch({ type: PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_OPEN, payload });
    },
    [],
  );

  const setModalClose = useCallback(() => {
    dispatch({ type: PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_CLOSE, payload: undefined });
  }, []);

  const setModalSecondaryClose = useCallback(() => {
    dispatch({ type: PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_SECONDARY_CLOSE, payload: undefined });
  }, []);

  return {
    ...state.purchaseOfferModals,
    setModalOpen,
    setModalClose,
    setModalSecondaryClose,
  };
};
