import { clientRegisterService } from 'pages/client/services/client.service';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { useSaveUploadFile } from 'src-new/pages/registrations/hooks/use-save-upload-file/use-save-upload-file.hook';

const useClientRegisterHook = () => {
  const saveUploadFile = useSaveUploadFile();

  return async (client: IClientDomain, uploadedList: Array<IAttachmentDomain>) => {
    const id = await clientRegisterService(client);

    await saveUploadFile(id, uploadedList, 'client');
  };
};

export { useClientRegisterHook };
