import React, { Fragment, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { ModalSelectInvoicing } from '../components/modal-select-invoicing';
import FinancialTable from '../components/financial-table/table';
import { defaultInitialPage } from '../../../../default_constants';

import { ContainerSearchStyled } from './fiscal-document-list-body-view.styled';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UseFinancialListHook } from 'pages-v2/invoicing/hooks/list';
import { ModalInvoicingUpdate } from 'pages-v2/invoicing/components/modals/invoicing-update';
import { financialTableListFormat } from 'pages/fiscal-document/utils/table-list-format';
import { invoicePdfService } from 'pages/fiscal-document/services/create-invoice-pdf';
import { IBranch, IInvoice } from 'pages/fiscal-document/models';
import { useCancelInvoincing } from 'pages/fiscal-document/hooks/cancel-invoincing/use-cancel-invoincing.hook';
import { UseFinancialExcelListHook } from 'pages/fiscal-document/hooks';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { UserContext } from 'state/user-context';
import TooltipComponent from 'components/Tooltip';
import { Button } from 'logshare-ui-kit';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

const dataTestId = 'occurrence-tracking-list-body-view';

const initialFinancialFilter = {
  companyId: 0,
  page: 0,
  limit: 0,
  search: '',
  order: '',
  column: '',
};

const FiscalDocumentListBodyView: React.FC = (): JSX.Element => {
  const { user, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<{
    id: number;
    branch?: IBranch;
  }>({ id: -1 });
  const [items, setItems] = useState<IInvoice[]>([]);
  const [activeTabName, setActiveTabName] = useState<string>('A Vencer');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>(defaultInitialPage);
  const [idsToCancel, setIdsToCancel] = useState<Array<number>>([]);
  const [financialFilter, setFinancialFilter] = useState(initialFinancialFilter);
  const [orderBy, setOrderBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const [excelButtonDisabled, setExcelButtonDisabled] = useState<boolean>(false);

  const cancelInvoincing = useCancelInvoincing();

  const handleRemoveIds = useCallback(
    async (ids: number[], exclude = false) => {
      if (exclude) {
        try {
          await cancelInvoincing(ids);
          setModalConfirmDelete({ description: '', open: false });
          setIdsToCancel([]);
          showToast({
            message: 'Faturas excluídas com sucesso',
            type: 'success',
          });
        } catch (error) {
          showToast({
            message: 'Não foi possível excluir as faturas',
            type: 'error',
          });
        }
      }
    },
    [currentPage],
  );

  const handleFinancialList = useCallback(
    async (value?: string) => {
      setHasLoading(true);
      const response = await UseFinancialListHook({
        page: currentPage,
        search: value,
        type: activeTabName,
        order: financialFilter.order,
      });

      if (response) {
        setItems(response.items);
        setPageInfo(response.meta);
      }

      setHasLoading(false);
    },
    [currentPage, activeTabName, financialFilter],
  );

  const handleFinancialExcelList = useCallback(async () => {
    try {
      setExcelButtonDisabled(true);

      await UseFinancialExcelListHook({
        search: '',
        type: activeTabName,
        order: financialFilter.order,
      });

      showMessageFormatted({
        message: 'Relatório gerado com sucesso.',
        type: 'success',
      });
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao gerar o relatório.',
        type: 'error',
      });
    } finally {
      setExcelButtonDisabled(false);
    }
  }, [activeTabName, financialFilter]);

  const handleTabList = useCallback(
    (value: string) => {
      setActiveTabName(value);
      setCurrentPage(1);
    },
    [activeTabName],
  );

  useEffect(() => {
    handleFinancialList();
  }, [handleFinancialList]);

  useEffect(() => {
    setFinancialFilter({
      ...financialFilter,
      order: sortDirection,
      column: orderBy,
    });
  }, [orderBy, sortDirection]);

  const handleSelected = (id: string): void => {
    const itemId = items.find((objeto) => objeto.id === Number(id));
    setSelectedId({ id: +id, branch: itemId?.branch });
    setIsOpenUpdate(true);
  };

  const handleSelectInvoincing = (event: React.ChangeEvent<HTMLInputElement>, freight: number[]) => {
    const { checked } = event.target;

    if (checked) {
      setIdsToCancel((prevIds) => Array.from(new Set([...prevIds, ...freight])));
    } else {
      setIdsToCancel((prevIds) => prevIds.filter((id) => !freight.includes(id)));
    }
  };

  const handleCancelInvoincing = () => {
    if (!idsToCancel.length) {
      showToast({
        message: 'Escolha pelo menos uma fatura para excluir',
        type: 'error',
      });

      return;
    }

    setModalConfirmDelete({ description: '', open: true });
  };

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_fatura_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const handleCreatePdf = useCallback(
    async (connectedInvoices: ConnectedInvoices[], invoiceId: number) => {
      try {
        const pdf = await invoicePdfService(connectedInvoices, invoiceId);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdf);
        link.download = fileNamePdf;
        link.click();

        URL.revokeObjectURL(link.href);
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error,
          type: 'error',
        });
      }
    },
    [fileNamePdf],
  );

  const contentTable = useMemo((): IContentTable[] => {
    if (items && items.length > 0) {
      return items?.map((item) =>
        financialTableListFormat({ item, selectInvoincing: handleSelectInvoincing, handleCreatePdf }),
      );
    }

    return [];
  }, [items]);

  const renderFinancialTable = useMemo(() => {
    return (
      <FinancialTable
        clickOrderBy={(e: string) => setOrderBy(e)}
        clickSortDirection={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        contentTable={contentTable}
        pageInfo={pageInfo}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        goToDetailsPage={handleSelected}
      />
    );
  }, [contentTable, hasLoading, pageInfo]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'A Vencer',
        content: renderFinancialTable,
      },
      {
        name: 'Pago',
        content: renderFinancialTable,
      },
      {
        name: 'Atrasado',
        content: renderFinancialTable,
      },
      {
        name: 'Cancelado',
        content: renderFinancialTable,
      },
      {
        name: 'Todos',
        content: renderFinancialTable,
      },
    ];
  }, [renderFinancialTable]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <ModalSelectInvoicing isOpen={isOpen} onClose={() => setIsOpen(false)} onSuccess={handleFinancialList} />
      {isOpenUpdate && (
        <ModalInvoicingUpdate
          isOpen={isOpenUpdate}
          selectedId={selectedId}
          onClose={() => setIsOpenUpdate(false)}
          onSuccess={handleFinancialList}
          handleCreatePdf={handleCreatePdf}
        />
      )}

      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Faturas"
          secondTitle={'Confirme a exclusão das faturas.'}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveIds(idsToCancel, true)}
          size="small"
          type="delete"
          description={undefined}
        />
      )}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Financeiro', 'Faturas']} />
      <Title dataTestId={dataTestId} title="Controle de faturas a Pagar e a Receber." hasTrace />
      <ContainerSearchStyled>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pelo ID da Fatura ou Tomador"
          type="text"
          changeValue={handleFinancialList}
        />

        <TooltipComponent
          icon={
            <Button
              disabled={excelButtonDisabled}
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={handleFinancialExcelList}
              LeftIcon={<CloudDownloadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Exportar'}
          location={'bottom'}
        />

        {!isShippingCompany && (
          <Button
            label="Novo Frete"
            size="md"
            color="blue"
            onPress={() => handleCreateSchedulePage(true)}
            variant="solid"
          />
        )}
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={handleTabList} />

      <FooterPage.Root withoutButton={!idsToCancel.length}>
        <FooterPage.RightContent>
          {idsToCancel.length && <FooterPage.Button label="Cancelar" color="red" onPress={handleCancelInvoincing} />}
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default FiscalDocumentListBodyView;
