import { FormikErrors } from 'formik';
import { FC, useCallback, useContext } from 'react';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { IAsyncSelectField, WeighingFormMountVehiclesView } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/components/weighing-update-form/components/weighing-update-form-mount/components/weighing-update-form-mount-vehicles/weighing-update-form-mount-vehicles-view.component';
import { UserContext } from 'state/user-context';
import { getVehicleService } from 'src-new/pages/execution/pages/weighing/pages/services/get-vehicle.service/get-vehicle.service';

interface IWeighingFormMountVehiclesProps {
  values: IWeighingDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDomain>>;
}

export const WeighingFormMountVehicles: FC<IWeighingFormMountVehiclesProps> = ({ values, setFieldValue }) => {
  const { user } = useContext(UserContext);

  const onChange = useCallback(async (e: IAsyncSelectField) => {
    const selectedMainPlate = e?.label.split('-')[0] ? e?.label.split('-')[0] : '';
            const secondPlate = e?.label.split('-')[1] ? e?.label.split('-')[1] : '';

            const vehicles = await getVehicleService('');

            vehicles.map((vehicle) => {
              const mainPlate = vehicle?.label.split('-')[0] ? vehicle?.label.split('-')[0] : '';
              if (mainPlate == selectedMainPlate) {
                setFieldValue('vehicle.id', e && e.value);
                setFieldValue('vehicle.mainPlate', selectedMainPlate);
                setFieldValue('vehicle.secondPlate', secondPlate);
                setFieldValue('vehicle.type', vehicle.type);
                setFieldValue('vehicle.category', vehicle.category);
              }
            });
  }, [setFieldValue]);

  return (
    <WeighingFormMountVehiclesView
      values={values}
      setFieldValue={setFieldValue}
      isMaster={!!user?.isMaster}
      onChange={onChange}
    />
  );
};
