import API from 'utils/API/API';

export const allocationAccept = async (payload: { ids: number[] }): Promise<void> => {
  await API.post('v2/schedules/accept-or-decline', {
    ...payload,
    statusCode: 'AG10',
  });
};

export const allocationCancel = async (
  allocationsId: Array<number>,
  type: 'spot' | 'backhaul',
  descriptionCancel?: string,
): Promise<void> => {
  await API.delete('v2/schedules/cancel', {
    data: {
      ids: allocationsId,
      type: type,
      descriptionCancel: descriptionCancel,
    },
  });
};

export const allocationFinish = async (
  allocationsId: Array<number>
): Promise<void> => {
  await API.delete('v2/schedules/finishSchedule', {
    data: {
      ids: allocationsId,
    },
  });
};