import styled from 'styled-components';

export const RenderCTeDataStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  max-width: 100%;
  gap: 20px;

  > div {
    display: flex;

    &:first-child {
      gap: 10px;

      > div:first-child {
        min-width: 365px;
      }
    }

    &:last-child {
      flex-direction: row;
      gap: 10px;
    }
  }
`;

export const RenderCTeHeaders = styled.div`
  color: #000;
  font-size: 12px;
`;

export const RenderCTeInfo = styled.div`
  font-weight: bold;
  font-size: 13px;
`;
