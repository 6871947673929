import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateScheduleAllocationService } from '../../services/update-schedule-allocation/update-schedule-allocation.service';
import { IUpdateScheduleAllocationPayload } from '../../services/update-schedule-allocation/update-schedule-details.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { useUpdateScheduleHistoryDriver } from '../use-update-schedule-history-driver/use-update-schedule-history-driver.hook';
import { useUpdateScheduleHistoryVehicle } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-history-vehicle/use-update-schedule-history-vehicle.hook';
import { getDriverHasLinkWithVehicleService } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-driver-has-link-with-vehicle/get-driver-has-link-with-vehicle.service';

export const useUpdateScheduleAllocation = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { id } = useParams<{ id: string }>();
  const reactQueryClient = useQueryClient();
  const { setInitialLoadingReset } = initialLoading;
  const updateUpdateScheduleHistoryDriverHook = useUpdateScheduleHistoryDriver();
  const updateScheduleHistoryVehicleHook = useUpdateScheduleHistoryVehicle();

  const scheduleId = useMemo(() => id ?? '', [id]);

  const handleModalClose = useCallback(() => {
    setInitialLoadingReset();
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails, setInitialLoadingReset]);

  const handleOpenVehicleModal = useCallback(
    (driverId: number) => {
      if (!scheduleDetails.scheduleDetails.vehicleId) {
        setTimeout(() => {
          scheduleDetails.setScheduleDetailsAllocationOpen('vehicle');
          scheduleDetails.allocationActions.setDriverIdVehicleModal(driverId);
        }, 1000);
      }
    },
    [scheduleDetails],
  );

  const handleUpdateDriverHistory = useCallback(async () => {
    if (scheduleDetails.scheduleDetails.driverId && scheduleDetails.scheduleDetails.vehicleId) {
      await updateUpdateScheduleHistoryDriverHook({
        driverId: scheduleDetails.allocationDriver.modalProps.driverSelected.driverId,
        vehicleId: scheduleDetails.scheduleDetails.vehicleId,
        typeUpdate: 'MOTORISTA',
      });
    }
  }, [
    scheduleDetails.allocationDriver.modalProps.driverSelected.driverId,
    scheduleDetails.scheduleDetails.driverId,
    scheduleDetails.scheduleDetails.vehicleId,
    updateUpdateScheduleHistoryDriverHook,
  ]);

  const handleUpdateVehicleHistory = useCallback(async () => {
    if (scheduleDetails.scheduleDetails.driverId && scheduleDetails.scheduleDetails.vehicleId) {
      updateScheduleHistoryVehicleHook({
        driverId: scheduleDetails.scheduleDetails.driverId,
        vehicleId: scheduleDetails.allocationVehicle.modalProps.vehicleSelected.vehicleId,
        typeUpdate: 'VEICULO',
      });
    }
  }, [
    scheduleDetails.allocationVehicle.modalProps.vehicleSelected.vehicleId,
    scheduleDetails.scheduleDetails.driverId,
    scheduleDetails.scheduleDetails.vehicleId,
    updateScheduleHistoryVehicleHook,
  ]);

  const handleUpdateHistory = useCallback(
    async ({ driverId, vehicleId }: IUpdateScheduleAllocationPayload) => {
      if (driverId) {
        await handleUpdateDriverHistory();
      } else if (vehicleId) {
        await handleUpdateVehicleHistory();
      }
    },
    [handleUpdateDriverHistory, handleUpdateVehicleHistory],
  );

  const handleOpenConfirmDriverModal = useCallback(
    (vehicleId: number) => {
      if (!scheduleDetails.scheduleDetails.driverId) {
        setTimeout(async () => {
          const driverVehicle = await getDriverHasLinkWithVehicleService(vehicleId);

          if (driverVehicle) {
            scheduleDetails.allocationDriver.modalProps.setScheduleDetailsAllocationDriverSuccess([driverVehicle]);
            scheduleDetails.setScheduleDetailsAllocationOpen('confirm-driver');
          }
        }, 1000);
      }
    },
    [scheduleDetails],
  );

  return useCallback(
    async (payload: IUpdateScheduleAllocationPayload) => {
      try {
        await updateScheduleAllocationService(scheduleId, payload);
        await handleUpdateHistory(payload);

        handleModalClose();
        showMessageFormatted({
          message: 'Alocação realizada com sucesso.',
          type: 'success',
        });
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);

        if (payload.driverId) {
          handleOpenVehicleModal(payload.driverId);
        }

        if (payload.vehicleId) {
          await handleOpenConfirmDriverModal(payload.vehicleId);
        }

        if (payload.shippingId) {
          scheduleDetails.allocationShipping.setScheduleDetailsAllocationSelectedShipping(payload.shippingId);
        }
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [
      handleModalClose,
      handleOpenConfirmDriverModal,
      handleOpenVehicleModal,
      handleUpdateHistory,
      reactQueryClient,
      scheduleDetails.allocationShipping,
      scheduleId,
    ],
  );
};
