import { PurchaseOfferListActionType } from '../../purchase-offer.action';
import { IPurchaseOfferListActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list.types';
import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';

export const purchaseOfferListReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferListActions,
): IPurchaseOfferContext => {
  switch (action.type) {
    case PurchaseOfferListActionType.PURCHASE_OFFER_LIST_REQUEST:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          statusRequest: 'PROCESSING',
        },
      };
    case PurchaseOfferListActionType.PURCHASE_OFFER_LIST_SUCCESS:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          statusRequest: 'SUCCESS',
          purchaseOfferList: action.payload,
        },
      };
    case PurchaseOfferListActionType.PURCHASE_OFFER_LIST_ERROR:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          statusRequest: 'ERROR',
          purchaseOfferList: [],
        },
      };
    case PurchaseOfferListActionType.PURCHASE_OFFER_LIST_RESET:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          statusRequest: 'INITIAL',
          purchaseOfferList: [],
        },
      };
    case PurchaseOfferListActionType.PURCHASE_OFFER_SELECT_IDS:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          selectedIds: action.payload,
        },
      };
    case PurchaseOfferListActionType.PURCHASE_OFFER_SELECT_FILTERS:
      return {
        ...state,
        purchaseOfferList: {
          ...state.purchaseOfferList,
          filters: {
            ...state.purchaseOfferList.filters,
            selectedFilter: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
