/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

import Theme from 'styles/theme';

export const WrapperStyled = styled.div`
  min-width: 90px;
  outline: none;
`;

export const customStyles = (disabled: boolean, hasError: boolean) => {
  return {
    menu: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: `${Theme.colorsV2.black}`,
      fontWeight: '500',
      backgroundColor: disabled ? 'rgb(231, 231, 231)' : '#fff',
    }),
    control: (base: any) => {
      const baseStyles = {
        ...base,
        minHeight: 36,
        positions: 'absolute',
        color: `${Theme.colorsV2.black}`,
        fontSize: 12,
        fontWeight: '600',
        border: `1px solid ${hasError ? Theme.colors.error : Theme.colors.gray}`,
        borderRadius: 4,
        marginTop: 3,
        backgroundColor: disabled ? 'rgb(231, 231, 231)' : '#fff',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };

      return disabled
        ? baseStyles
        : {
            ...baseStyles,
            '&:hover, &:focus-within, &:-webkit-autofill:hover, &:-webkit-autofill:focus-within': {
              borderColor: `${Theme.colorsV2.blue}`,
              boxShadow: `inset 0 0 0 1px ${Theme.colorsV2.blue} !important`,
            },
          };
    },
    singleValue: (provided: any, base: any) => ({
      ...provided,
      color: base.isDisabled ? `${Theme.colors.black}` : `${Theme.colorsV2.black}`,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: 'gray',
    }),
  };
};

export const LabelStyled = styled.label<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    font-size: 11px;
    font-weight: 400;
    color: ${hasError ? theme.colors.error : theme.colorsV2.black};
    background-color: 'white';
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
