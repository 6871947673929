import styled, { css } from 'styled-components';
import { Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled.form``;

export const WrapperSectionResetPasswordStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 35%;
  grid-gap: 5px 20px;
`;
export const CurrentPasswordWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const NewPasswordWrapperStyled = styled.div`
  grid-column-start: 3;
  grid-column-end: 5;
`;

export const ConfirmNewPasswordWrapperStyled = styled.div`
  grid-column-start: 5;
  grid-column-end: 5;
`;

export const FooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
  `}
`;
