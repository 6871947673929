import { ReactNode, useMemo } from 'react';

import * as S from './styled';

interface FooterPageRootProps {
  children?: ReactNode;
  withoutButton?: boolean;
  withoutTrace?: boolean;
}

export function FooterPageRoot({ children, withoutButton = false, withoutTrace = false }: FooterPageRootProps) {
  const renderContent = useMemo((): ReactNode | undefined => !withoutButton && children, [children, withoutButton]);
  return (
    <S.RootWrapper>
      <S.Trace withoutButton={withoutButton} withoutTrace={withoutTrace} />
      <S.Container>{renderContent}</S.Container>
    </S.RootWrapper>
  );
}
