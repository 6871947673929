import React from 'react';

export const ListIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M4.74571 5H2.50571C2.36571 5 2.22571 5.14 2.22571 5.28V7.45C2.22571 7.66 2.36571 7.8 2.50571 7.8H4.67571C4.88571 7.8 5.02571 7.66 5.02571 7.52V5.28C5.02571 5.14 4.88571 5 4.74571 5Z" />
      <path d="M21.5457 5H8.10568C7.96568 5 7.82568 5.14 7.82568 5.28V7.45C7.82568 7.59 7.96568 7.73 8.10568 7.73H21.4757C21.6157 7.73 21.7557 7.59 21.7557 7.45V5.28C21.8257 5.14 21.6857 5 21.5457 5Z" />
      <path d="M4.74571 10.6H2.50571C2.36571 10.6 2.22571 10.74 2.22571 10.88V13.05C2.22571 13.26 2.36571 13.4 2.50571 13.4H4.67571C4.88571 13.4 5.02571 13.26 5.02571 13.12V10.95C5.02571 10.74 4.88571 10.6 4.74571 10.6Z" />
      <path d="M21.5457 10.6H8.10568C7.96568 10.6 7.82568 10.74 7.82568 10.88V13.05C7.82568 13.19 7.96568 13.33 8.10568 13.33H21.4757C21.6157 13.33 21.7557 13.19 21.7557 13.05V10.88C21.8257 10.74 21.6857 10.6 21.5457 10.6Z" />
      <path d="M4.74571 16.2H2.50571C2.36571 16.2 2.22571 16.34 2.22571 16.48V18.65C2.22571 18.86 2.36571 19 2.50571 19H4.67571C4.88571 19 5.02571 18.86 5.02571 18.72V16.55C5.02571 16.34 4.88571 16.2 4.74571 16.2Z" />
      <path d="M21.5457 16.2H8.10568C7.96568 16.2 7.82568 16.34 7.82568 16.48V18.65C7.82568 18.79 7.96568 18.93 8.10568 18.93H21.4757C21.6157 18.93 21.7557 18.79 21.7557 18.65V16.48C21.8257 16.34 21.6857 16.2 21.5457 16.2Z" />
    </svg>
  );
};
