import { IOccurrencesContext, IOccurrencesReducerAction } from './occurrences.types';
import { IOccurrencesAction } from './occurrences.actions';

export const occurrencesReducer = (
  state: IOccurrencesContext,
  action: IOccurrencesReducerAction,
): IOccurrencesContext => {
  switch (action.type) {
    case IOccurrencesAction.OCCURRENCES_REQUEST: {
      return {
        ...state,
        occurrencesIsLoading: true,
        occurrences: [],
        occurrencesError: undefined,
      };
    }
    case IOccurrencesAction.OCCURRENCES_SUCCESS: {
      return {
        ...state,
        occurrencesIsLoading: false,
        occurrences: action.payload.list,
        occurrencesPageInfo: action.payload.pageInfo,
        occurrencesError: undefined,
      };
    }
    case IOccurrencesAction.OCCURRENCES_ERROR: {
      return {
        ...state,
        occurrencesIsLoading: false,
        occurrences: [],
        occurrencesError: action.payload,
        occurrencesPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
