import React, { useMemo } from 'react';

import { ContainerStyled } from './user-permissions.styled';

import { Permission } from 'domain/user';
import ToggleCheckbox from 'components/toggle-checkbox/toggle-checkbox';

interface UserSubPermissionsView {
  subPermissions: Permission[];
  handleChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const UserPermissionsView: React.FC<UserSubPermissionsView> = ({ subPermissions, handleChecked }) => {
  const translates = [
    { value: 'company', label: 'Empresa' },
    { value: 'company-info', label: 'Informações da Empresa' },
    { value: 'user-management', label: 'Usuários' },
    { value: 'account-info', label: 'Minha Conta' },
    { value: 'reset-password', label: 'Alterar Senha' },

    { value: 'client', label: 'Parceiros' },
    { value: 'locality', label: 'Unidades' },
    { value: 'vehicle', label: 'Veículos' },
    { value: 'shipping-company', label: 'Transportadoras' },
    { value: 'driver', label: 'Motoristas' },
    { value: 'occurrence-type', label: 'Tipo de Ocorrência' },

    { value: 'schedule-ftl', label: 'Frete Dedicado' },
    { value: 'schedule-stl', label: 'Frete Compartilhado' },
    { value: 'schedule-ltl', label: 'Frete Fracionado' },
    { value: 'schedule-new-ftl', label: 'Novo Frete Dedicado' },
    { value: 'circuit', label: 'Circuito' },
    { value: 'my-routes', label: 'Minhas Rotas' },

    { value: 'matches', label: 'Matches' },
    { value: 'transport-route', label: 'Oferta de Venda' },
    { value: 'idleness', label: 'Oferta de Compra' },
    { value: 'bidding', label: 'Bidding' },

    { value: 'freight', label: 'Fretes' },
    { value: 'map', label: 'Mapa' },
    { value: 'weighing', label: 'Pesagem' },
    { value: 'toll-voucher', label: 'Vale Pedágio' },
    { value: 'occurrence', label: 'Ocorrências' },
    { value: 'check-list', label: 'Checklist' },

    { value: 'freight-payable', label: 'Fretes a Pagar' },
    { value: 'freight-receivable', label: 'Fretes a Receber' },
    { value: 'fiscal-document', label: 'Faturamento' },
    { value: 'digital-wallet', label: 'Carteira Digital' },

    {
      value: 'alocacao-de-transportadora',
      label: 'Alocação de Transportadora',
    },
    { value: 'oferta-de-compra-aceita', label: 'Oferta de Compra Aceita' },
    { value: 'venda-de-ociosidade', label: 'Venda de Ociosidade' },
    { value: 'atraso-na-coleta', label: 'Atraso na Coleta' },
    { value: 'agendamento-concluido', label: 'Agendamento Concluído' },
    { value: 'oferta-de-compra-negada', label: 'Oferta de Compra Negada' },
    { value: 'aloracao-de-motorista', label: 'Alocação de Motorista' },
    { value: 'atraso-no-inicio-da-rota', label: 'Atraso no Início da Rota' },
  ];

  function tradutor(label: string) {
    let newLabel = '';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const translateLabel of translates) {
      if (label === translateLabel.value) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        newLabel = translateLabel.label;
      }
    }
    if (newLabel === '') {
      return label;
    }

    return newLabel;
  }

  const renderSubPermissions = useMemo(() => {
    return subPermissions.map(({ label, value }) => (
      <ToggleCheckbox
        key={label}
        id={label}
        name={label}
        label={tradutor(label)}
        checked={value}
        onChange={(e) => handleChecked(e)}
      />
    ));
  }, [handleChecked, subPermissions]);
  return (
    <>
      <ContainerStyled>{renderSubPermissions}</ContainerStyled>
    </>
  );
};
export default UserPermissionsView;
