import { FC } from 'react';

export const WarningNewIcon: FC = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8885 13.571C17.8833 13.2466 17.7951 12.9289 17.6323 12.6483L10.6157 1.4015C10.065 0.509329 8.89527 0.232523 8.00309 0.783243C7.75362 0.937247 7.54322 1.14693 7.38835 1.39585L0.360417 12.6615C-0.158917 13.5723 0.158433 14.7316 1.06925 15.251C1.34712 15.4094 1.66052 15.4951 1.98036 15.5H16.0199C17.0686 15.4833 17.9052 14.6196 17.8885 13.571ZM9.10351 13.0403C9.07101 13.0443 9.0383 13.0461 9.00557 13.0459C8.57617 13.0434 8.23008 12.6933 8.23248 12.2639C8.23469 11.8722 8.52786 11.5433 8.91672 11.4962C9.34311 11.4446 9.73059 11.7485 9.78217 12.1749C9.83375 12.6013 9.52989 12.9887 9.10351 13.0403ZM9.74896 7.06412H9.74959L9.52632 9.63107C9.51673 9.87266 9.46384 10.1106 9.37016 10.3335C9.25854 10.5518 8.99105 10.6384 8.77269 10.5267C8.69236 10.4857 8.62643 10.4211 8.5837 10.3416C8.49437 10.1109 8.44188 9.86749 8.42817 9.62041L8.26134 7.12684C8.22873 6.62511 8.05754 5.81419 8.44072 5.40652C8.73234 5.09295 9.42284 5.03901 9.6323 5.47363C9.81128 5.98413 9.85152 6.53297 9.74896 7.06412Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
