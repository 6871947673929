import { FC, useContext, useEffect, useMemo } from 'react';
import { WeighingDuplicatedPageBodyView } from './weighing-duplicated-page-body-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { UseGetWeighingToCloneById } from '../../hooks/use-get-duplicated-weighing-by-id/use-get-duplicated-weighing-by-id.hook';

export const WeighingDuplicatedPageBody: FC = () => {
  const { weighingDetails } = useContext(WeighingContext);
  const getWeighingToCloneByIdHook = UseGetWeighingToCloneById();

  const isLoading = useMemo(() => weighingDetails.statusRequest === 'PROCESSING', [weighingDetails.statusRequest]);

  const isError = useMemo(() => weighingDetails.statusRequest === 'ERROR', [weighingDetails.statusRequest]);
  useEffect(() => {
    if (weighingDetails.statusRequest === 'INITIAL') {
      getWeighingToCloneByIdHook();
    }
  }, [getWeighingToCloneByIdHook, weighingDetails.statusRequest]);

  return <WeighingDuplicatedPageBodyView isLoading={isLoading} isError={isError} />;
};
