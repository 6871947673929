import React, { createContext, useMemo, useReducer } from 'react';
import { matchesInitialState } from './matches.constants';
import { MatchesReducer } from './reducers/matches.reducer';
import { IMatchesContext, IMatchesContextProps } from './types/matches.types';
import { IMatchesKanbanList } from './types/matches-kanban.types';
import { useMatchesKanbanListContextValues } from './context-value/matches-kanban/matches-kanban-values.context';
import { useInNegotiationMatchesKanbanListContextValues } from 'src-new/pages/opportunities/pages/matches/contexts/matches/context-value/in-negotiation-matches-kanban/in-negotiation-matches-kanban-values.context';
import { IInNegociationMatchesKanbanList } from './types/in-negotiation-matches-kanban.types';
import { IOperatedMatchesKanbanList } from './types/operated-matches-kanban.types';
import { useOperatedMatchesKanbanListContextValues } from './context-value/operated-matches-kanban/operated-matches-kanban-values.context';
import { IStabilizedMatchesKanbanList } from './types/stabilized-matches-kanban.types';
import { useStabilizedMatchesKanbanListContextValues } from './context-value/stabilized-matches-kanban/stabilized-matches-kanban-values.context';
import { IInactiveMatchesKanbanList } from './types/inactive-matches-kanban.types';
import { useInactiveMatchesKanbanListContextValues } from './context-value/inactive-matches-kanban/inactive-matches-kanban-values.context';
import { useFavoriteMatchesKanbanListContextValues } from './context-value/favorite-matches-kanban/favorite-matches-kanban-values.context';
import { IModalMatchesKanban } from './types/modal-matches-kanban.types';
import { useModalMatchesKanbanContextValues } from './context-value/modal-matches-kanban/modal-matches-kanban.context';
import { IFavoriteMatchesKanbanList } from './types/favorite-matches-kanban.types';

export const MatchesContext = createContext<IMatchesContext>(matchesInitialState);

export const MatchesContextProvider = ({ children }: IMatchesContextProps) => {
  const [state] = useReducer(MatchesReducer, matchesInitialState);

  const matchesKanbanList: IMatchesKanbanList = useMatchesKanbanListContextValues();
  const modalMatchesKanban: IModalMatchesKanban = useModalMatchesKanbanContextValues();
  const favoriteMatchesKanbanList: IFavoriteMatchesKanbanList = useFavoriteMatchesKanbanListContextValues();
  const inNegotiationMatchesKanbanList: IInNegociationMatchesKanbanList =
    useInNegotiationMatchesKanbanListContextValues();
  const operatedMatchesKanbanList: IOperatedMatchesKanbanList = useOperatedMatchesKanbanListContextValues();
  const stabilizedMatchesKanbanList: IStabilizedMatchesKanbanList = useStabilizedMatchesKanbanListContextValues();
  const inactiveMatchesKanbanList: IInactiveMatchesKanbanList = useInactiveMatchesKanbanListContextValues();

  const value: IMatchesContext = useMemo(
    (): IMatchesContext => ({
      ...state,
      matchesKanbanList,
      modalMatchesKanban,
      favoriteMatchesKanbanList,
      inNegotiationMatchesKanbanList,
      operatedMatchesKanbanList,
      stabilizedMatchesKanbanList,
      inactiveMatchesKanbanList,
    }),
    [
      state,
      matchesKanbanList,
      modalMatchesKanban,
      favoriteMatchesKanbanList,
      inNegotiationMatchesKanbanList,
      operatedMatchesKanbanList,
      stabilizedMatchesKanbanList,
      inactiveMatchesKanbanList,
    ],
  );

  return <MatchesContext.Provider value={value}>{children}</MatchesContext.Provider>;
};
