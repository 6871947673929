import { santosPortReportExcelService } from 'pages/santos-port/service/location-report-excel/location-report-excel.service';

export interface ILocationReportExcelProps {
  search: string;
}

const useReportExcelHook = () => {
  return async (params: ILocationReportExcelProps): Promise<BlobPart | undefined> => {
    return await santosPortReportExcelService(params);
  };
};

export { useReportExcelHook };
