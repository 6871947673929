import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { ScheduleOfferModalMatchTableTabs } from './components/purchase-offer-modal-match-table-tabs/modal-match-table-tabs.component';
import * as S from './modal-match-view.styled';
import { ICompany } from 'domain/company';
import { ScheduleOfferModalMatchHeader } from 'pages-v2/schedule/components/modals/modal-match/components/modal-match-header/modal-match-header.component';
import { ScheduleOfferModalMatchFooter } from 'pages-v2/schedule/components/modals/modal-match/components/modal-match-footer/modal-match-footer.component';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';

interface IScheduleOfferModalMatchViewProps {
  scheduleValues: ScheduleRegisterInput;
  saleOffersMatches: Array<any>;
  company: ICompany;
  handleMatchBuy: (backhaul: any) => void;
  handleCloseModalMatch: () => void;
}

export const ScheduleOfferModalMatchView: FC<IScheduleOfferModalMatchViewProps> = ({
  scheduleValues,
  saleOffersMatches,
  company,
  handleMatchBuy,
  handleCloseModalMatch,
}) => {
  return (
    <ModalBark size={'lg'} title={`Proposta de Compra - ${company.tradeName}`} handleClose={handleCloseModalMatch}>
      <S.Wrapper>
        <ScheduleOfferModalMatchHeader scheduleValues={scheduleValues} companyImage={company.fileKey || ''} />
        <ScheduleOfferModalMatchTableTabs saleOffersMatches={saleOffersMatches} handleMatchBuy={handleMatchBuy} />
        <ScheduleOfferModalMatchFooter />
      </S.Wrapper>
    </ModalBark>
  );
};
