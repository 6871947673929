import API from 'utils/API/API';
import { ITrackingOccurrence, ITrackingOccurrenceStatus } from 'domain/tracking-occurrence';
import { PageMeta } from 'domain/page-info';

export interface ITrackingOccurrenceListProps {
  search: string;
  page: number;
  status?: ITrackingOccurrenceStatus;
  order: string;
  column: string;
  scheduleId?: number;
}

export interface ITrackingOccurrenceList {
  items: Array<ITrackingOccurrence>;
  meta: PageMeta;
}

const LIMIT_PAGE = 5;

export const trackingOccurrencesListService = async ({
  page,
  search,
  order,
  column,
  scheduleId,
}: ITrackingOccurrenceListProps): Promise<ITrackingOccurrenceList> => {
  try {
    const url = `v2/occurrence-tracking?search=${search}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}&freightId=${scheduleId}`;

    const { data } = await API.get(url);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar as ocorrências');
  }
};
