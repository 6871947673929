import React, { useCallback } from 'react';
import { FilterFormView } from './filter-form-view.component';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';
import { SelectItem } from 'domain/select-item';
import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';

interface IFilterFormProps {
  savedFilters: Array<IMatchesListParams>;
}

export const FilterForm: React.FC<IFilterFormProps> = () => {
  const apiCallCompanyBuyer = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await listCompanyByName(searchValue);
    return [];
  }, []);

  return <FilterFormView  apiCallCompanyBuyer={apiCallCompanyBuyer}/>;
};
