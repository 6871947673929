import styled from 'styled-components';

export const Wrapper = styled.p`
  color: #000;
  font-family: Montserrat, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin-bottom: 10px;
`;
