import { FC } from 'react';
import * as S from './weighing-duplicated-page-body-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Title from 'components/title/title';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { WeighingDuplicatedForm } from '../weighing-duplicated-form/weighing-duplicated-form.component';

interface IWeighingDuplicatedPageBodyViewProps {
  isLoading: boolean;
  isError: boolean;
}

export const WeighingDuplicatedPageBodyView: FC<IWeighingDuplicatedPageBodyViewProps> = ({ isLoading, isError }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <S.Wrapper>
      <HeaderPage breadcrumbList={['Programação', 'Pesagem']} hasBackButton />
      <Title title="Nova Pesagem Clone" hasTrace isForm />
      <WeighingDuplicatedForm />
    </S.Wrapper>
  );
};
