import styled, { css } from 'styled-components';

export const InputDateContainer = styled.div<{
  hasError?: boolean;
}>`
  ${({ hasError = false }) => css`
    display: flex;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    align-items: center;
    margin-top: 3px;
    border-color: ${hasError && 'red'};

    &:focus-within {
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    }
  `}
`;

export const InputDate = styled.input`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    background-color: transparent;
    border: 0;
    font-weight: 600;
    outline: none;
    width: 80%;
    height: 36px;
    padding: 10px;
    border: 1px solid #ccc;
    border: none;
  `}
`;

export const IconStyled = styled.div`
  cursor: pointer;
`;

export const StyledDatePickerWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;

export const DatePicketSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;
