import { IOccurrencesContext } from './occurrences.types';

export const initialState: IOccurrencesContext = {
  occurrencesIsLoading: false,
  occurrences: [],
  occurrencesError: undefined,
  occurrencesPageInfo: undefined,
  setOccurrencesRequest: () => undefined,
  setOccurrencesSuccess: () => undefined,
  setOccurrencesError: () => undefined,
};

export enum IOccurrencesAction {
  OCCURRENCES_REQUEST = 'OCCURRENCES_REQUEST',
  OCCURRENCES_SUCCESS = 'OCCURRENCES_SUCCESS',
  OCCURRENCES_ERROR = 'OCCURRENCES_ERROR',
}
