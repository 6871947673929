import {
  ButtonContainer,
  CloseModalStyled,
  ContainerHeaderStyled,
  ContentStyled,
  TitleStyled,
  WrapperStyled,
} from './modal-form.style';
import { ModalFormProps } from './modal-form';

import Button from 'components/button/button';

export const ModalFormView: React.FC<ModalFormProps> = ({
  description,
  title,
  onConfirmation,
  handleCancel,
  size,
  legacy,
  buttonLabel,
}) => {
  return (
    <WrapperStyled legacy={legacy}>
      <ContentStyled size={size}>
        <ContainerHeaderStyled>
          <TitleStyled>{title}</TitleStyled>

          <CloseModalStyled onClick={handleCancel}>X</CloseModalStyled>
        </ContainerHeaderStyled>

        {description}

        {onConfirmation && (
          <ButtonContainer>
            <Button title={buttonLabel ?? 'Aplicar'} callback={onConfirmation} bgColor={'blue'} size="large" />
          </ButtonContainer>
        )}
      </ContentStyled>
    </WrapperStyled>
  );
};
