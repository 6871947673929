import axios from 'axios';

export interface IHereMapsDistanceCalculate {
  origin: string;
  destiny: string;
  returnType: string;
}

export interface IHereMapsRoutesDistanceResponse {
  routes: Array<{
    sections: Array<{
      summary: {
        length: number;
      };
    }>;
  }>;
}

export const getDistanceBetweenPoints = async (params: IHereMapsDistanceCalculate): Promise<number | null> => {
  const { origin, destiny, returnType } = params;
  const { data } = await axios.get<IHereMapsRoutesDistanceResponse>(
    `https://router.hereapi.com/v8/routes?origin=${origin}&destination=${destiny}&transportMode=car&return=${returnType}&apikey=OVmzU8iQb9pA3a0s9YJsPbjOK04Ded-W7g01-UKkKPc`,
  );
  if (data.routes.length > 0) {
    return data.routes[0].sections[0].summary.length;
  }

  return null;
};
