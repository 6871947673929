import { IListPageUserEntity } from '../entities/user.entity';

import API from 'utils/API/API';
import { convertUserListEntityToDomainList } from 'pages/user/mappers/convert-user-list-entity-to-domain-list/convert-user-list-entity-to-domain-list.mapper';
import { convertUserStatusDomainToEntity } from 'pages/user/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { IListPageUser, IUserStatus } from 'domain/user';
import { IListPage } from 'domain/page-info';

interface IListUsersParameter {
  currentPage: number;
  search: string;
  status: IUserStatus | null;
  order: string;
  column: string;
  excluded?: string;
}

const LIMIT_PAGE = 50;

export const listUsersService = async (payload: IListUsersParameter): Promise<IListPage<IListPageUser>> => {
  const { data } = await API.get('v2/users', {
    params: {
      ...payload,
      limit: LIMIT_PAGE,
      page: payload.currentPage,
      status: payload.status ? convertUserStatusDomainToEntity(payload.status) : undefined,
    },
  });
  const { items, meta } = data;

  return {
    list: items.map((user: IListPageUserEntity) => convertUserListEntityToDomainList(user)),
    pageInfo: meta,
  };
};
