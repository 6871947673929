import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { DownloadFile } from 'utils/download-file/download-file';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getScheduleDetailsCollectionOrderService } from '../../services/get-schedule-collect-order/get-schedule-collect-order.service';

export const UseGetScheduleDetailsCollectionOrder = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Ordem_Coleta_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  return useCallback(async () => {
    try {
      showMessageFormatted({
        message: 'Gerando ordem de coleta...',
        type: 'info',
      });

      const pdf = await getScheduleDetailsCollectionOrderService(scheduleId);

      if (!pdf) return;

      DownloadFile(pdf, 'pdf', fileNamePdf);

      showMessageFormatted({
        message: 'Ordem de coleta gerada com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [fileNamePdf, scheduleId]);
};
