import React, { Fragment, useCallback, useMemo } from 'react';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IOrdering, IRow } from 'src-new/components/table/table.types';
import { PurchaseOfferModalMatchScore } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-score/purchase-offer-modal-match-score.component';
import * as S from './purchase-offer-modal-match-table-view.styled';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';
import {
  IPurchaseOfferMatchListDomain,
  IPurchaseOfferMatchListLocationsDomain,
  ScoreDomain,
} from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';
import { PurchaseOfferModalMatchScoreLabel } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-score/purchase-offer-modal-match-score-label/purchase-offer-modal-match-score-label.component';
import { Tag } from 'src-new/components/tag/tag.component';
import TooltipComponent from 'components/Tooltip';
import { Star } from 'lucide-react';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';
import { convertOperationType } from 'src-new/utils/convert-operation-type/convert-operation-type.util';

interface IPurchaseOfferModalMatchTableViewProps {
  purchaseOfferMatch: Array<IPurchaseOfferMatchListDomain>;
  orderingColumns: IOrdering;
  isLoading: boolean;
  handleOpenModalMatchBuy: (matchSelected: IPurchaseOfferMatchListDomain) => void;
  handleFavoriteMatch: (matchId: number, isFavorite: boolean) => void;
  handleOpenProposalChat: (tariffOfferId: string, saleOfferId: string, matchId: string) => void;
}

export const PurchaseOfferModalMatchTableView: React.FC<IPurchaseOfferModalMatchTableViewProps> = ({
  purchaseOfferMatch,
  orderingColumns,
  isLoading,
  handleOpenModalMatchBuy,
  handleFavoriteMatch,
  handleOpenProposalChat,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: '' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Match Potencial' },
      { value: 'Classificação' },
      { value: 'Frete Valor' },
    ];

    const columnsConfig = '0.25fr 0.15fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderFavoriteMatch = useCallback(
    (matchId: number, isFavorite?: boolean) => {
      const title = isFavorite ? 'Desfavoritar Match' : 'Favoritar Match';
      const color = isFavorite ? '#FF9800' : '#D9D9D9';

      return (
        <TooltipComponent
          title={title}
          location="bottom-start"
          icon={<Star size={15} color={color} fill={color} onClick={() => handleFavoriteMatch(matchId, !isFavorite)} />}
        />
      );
    },
    [handleFavoriteMatch],
  );

  const renderPotentionalMatches = useCallback(
    (score: { origin: ScoreDomain; destination: ScoreDomain; vehicle: ScoreDomain }) => {
      return (
        <Fragment>
          <PurchaseOfferModalMatchScoreLabel sumScore={score.vehicle + score.origin + score.destination} />
          <S.WrapperMatchScore>
            <TooltipComponent
              title="Veículo"
              location="bottom"
              icon={<PurchaseOfferModalMatchScore label={'V'} score={score.vehicle} />}
            />
            <TooltipComponent
              title="Origem"
              location="bottom"
              icon={<PurchaseOfferModalMatchScore label={'O'} score={score.origin} />}
            />
            <TooltipComponent
              title="Destino"
              location="bottom"
              icon={<PurchaseOfferModalMatchScore label={'D'} score={score.destination} />}
            />
          </S.WrapperMatchScore>
        </Fragment>
      );
    },
    [],
  );

  const renderCompanyImageNode = useCallback((id: number, partner: boolean, src: string) => {
    return (
      <S.WrapperIdAndImage>
        <S.Title>{id}</S.Title>
        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: 25,
            marginTop: 3,
          }}
          src={src}
          alt={src}
        />
      </S.WrapperIdAndImage>
    );
  }, []);

  const renderLocationText = useCallback(
    (origin: IPurchaseOfferMatchListLocationsDomain, destination: IPurchaseOfferMatchListLocationsDomain) => {
      return (
        <S.WrapperInformationLocations>
          <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
          <div>
            <S.Title>
              {origin.address.city.toUpperCase()} - {origin.address.uf.toUpperCase()}
            </S.Title>
            <S.Title>
              {destination.address.city.toUpperCase()} - {destination.address.uf.toUpperCase()}
            </S.Title>
          </div>
        </S.WrapperInformationLocations>
      );
    },
    [],
  );

  const renderRows = useMemo((): Array<IRow> => {
    return purchaseOfferMatch.map((match) => {
      const operationType = convertOperationType(match.operationType);

      return {
        key: match.saleOfferShipperTariffId,
        onClick: () => undefined,
        valuesColumns: [
          {
            value: renderCompanyImageNode(match.saleOfferShipperTariffId, match.backhaulPartner, match.company.image),
          },
          {
            value: renderFavoriteMatch(match.matchOfferId, match.isFavorite),
            isClicable: false,
          },
          { value: renderLocationText(match.origin, match.destination) },
          { value: renderTitleSubtitle(match.vehicle.type, match.vehicle.category) },
          { value: renderTitleSubtitle('- KG', '- PALLETS') },
          {
            value: renderTitleSubtitle(String(match.frequency ?? 0), 'VIAGENS/MÊS'),
          },
          {
            value: renderPotentionalMatches(match.score),
          },
          {
            value: (
              <S.WrapperTags>
                <S.Div>
                  <Tag
                    label={'BACKHAUL' + ' ' + match.backhaulType}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={match.co2Economy.toFixed(2)}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                    LeftIcon={
                      <S.TagText>
                        <DoubleArrowDownIcon />
                        CO₂
                      </S.TagText>
                    }
                  />
                  <Tag
                    label={operationType.label}
                    color={operationType.color}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={'DESVIO ' + ' ' + Number(match.additionalDistance).toFixed(0) + ' KM'}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>
              </S.WrapperTags>
            ),
          },
          {
            value: renderTitleSubtitle(convertCurrencyFormat.format(match.values.freightValue), '+ IMPOSTOS'),
          },
          {
            value: (
              <S.Div>
                <Button
                  label="Negociar"
                  size="sm"
                  color="red"
                  onPress={() =>
                    handleOpenProposalChat(
                      match.saleOfferShipperTariffId.toString(),
                      match.saleOfferId.toString(),
                      match.id.toString(),
                    )
                  }
                  variant="ghost"
                />
              </S.Div>
            ),
          },
          {
            value: (
              <S.Div>
                <Button
                  label="Comprar"
                  size="sm"
                  color="blue"
                  onPress={() => handleOpenModalMatchBuy(match)}
                  variant="solid"
                />
              </S.Div>
            ),
          },
        ],
      };
    });
  }, [
    handleOpenModalMatchBuy,
    handleOpenProposalChat,
    purchaseOfferMatch,
    renderCompanyImageNode,
    renderFavoriteMatch,
    renderLocationText,
    renderPotentionalMatches,
    renderTitleSubtitle,
  ]);

  if (isLoading)
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    );

  if (!purchaseOfferMatch.length)
    return (
      <S.EmptyListWrapper>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.EmptyListWrapper>
    );

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        ordering={orderingColumns}
        rows={renderRows}
        height={'380px'}
      />
      <ChatButton chatProps={{ typeChat: 'SINGLE', name: '' }} />
    </S.Wrapper>
  );
};
