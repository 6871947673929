export const normalizeGeopoint = (point: string | number) => {
  const pointString = String(point);

  const slicedPoint = pointString.slice(0, 11);

  const hasSignal = slicedPoint.indexOf('-');

  if (hasSignal === -1) {
    return `-${pointString}`;
  }

  return point;
};
