import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import API from 'utils/API/API';
import { FileListUploaded } from 'pages/freight/types';
import { showToast } from 'components/toast/toast';

export const occurrenceDeleteImageService = async (id: number): Promise<boolean> => {
  try {
    return await API.delete(`v2/occurrence-tracking/image/${id}`);
  } catch (error) {
    showMessageFormatted({
      message: 'Houve um erro ao remover a imagem',
      type: 'error',

      error: error as any,
    });

    return false;
  }
};

export const uploadTemplate = async (files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'TEMPLATE-OCCURRENCE');
    formData.append('module', 'occurrence');
    formData.append('codigo', '01');
    formData.append('file', files);

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Template',
    });
    throw new Error('Houve um erro ao realizar o upload do Template.');
  }
};

export const listUploadedTemplate = async (): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>('v2/file/management?module=occurrence&type=TEMPLATE-OCCURRENCE&codigo=01');

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao baixar o template',
    });
    throw new Error('Houve um erro ao baixar o template.');
  }
};

export const deleteUploadedTemplate = async (templateId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${templateId}`);
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o template',
    });
    throw new Error('Houve um erro ao excluir o template.');
  }
};
