import { ITransactionsContext } from './types/transactions.types';

export const transactionsInitialState: ITransactionsContext = {
  transactionsList: {
    statusRequest: 'INITIAL',
    transactions: [],
    filterActions: {
      filters: {
        page: 1,
        activeTabName: 'A Pagar',
        order: 'DESC',
        column: 'Data Entrega',
        financialStatus: 'pagar',
        limit: 100,
        search: '',
      },
      setFilters: () => undefined,
    },
    setTransactionsListRequest: () => undefined,
    setTransactionsListSuccess: () => undefined,
    setTransactionsListError: () => undefined,
    setTransactionsListReset: () => undefined,
  },
  setTransactionsResetContext: () => undefined,
};
