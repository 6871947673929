import { useCallback, useReducer } from 'react';
import { HomeCardsActionType } from '../../home.action';
import { IHomeCards } from '../../types/home-cards.types';
import { homeInitialState } from '../../home.constants';
import { homeCardsReducer } from '../../reducers/home-cards/home-cards.reducer';
import { IHomeCardsDomain } from 'src-new/pages/home/pages/home/domains/home-cards/home-cards.domain';

export const useHomeCardsContextValues = (): IHomeCards => {
  const [state, dispatch] = useReducer(homeCardsReducer, homeInitialState);

  const setHomeCardsRequest = useCallback(() => {
    dispatch({ type: HomeCardsActionType.HOME_CARDS_REQUEST, payload: undefined });
  }, []);

  const setHomeCardsSuccess = useCallback((homeCards: IHomeCardsDomain) => {
    dispatch({ type: HomeCardsActionType.HOME_CARDS_SUCCESS, payload: homeCards });
  }, []);

  const setHomeCardsError = useCallback(() => {
    dispatch({ type: HomeCardsActionType.HOME_CARDS_ERROR, payload: undefined });
  }, []);

  const setHomeCardsReset = useCallback(() => {
    dispatch({ type: HomeCardsActionType.HOME_CARDS_RESET, payload: undefined });
  }, []);

  return {
    ...state.homeCards,
    setHomeCardsRequest,
    setHomeCardsSuccess,
    setHomeCardsError,
    setHomeCardsReset,
  };
};
