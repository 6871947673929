import { Button, Tag } from 'logshare-ui-kit';
import { Pencil } from 'lucide-react';
import { FC, useMemo } from 'react';
import { Box } from 'src-new/components/box/box.component';
import * as S from './schedule-details-requirements-view.styled';

interface IScheduleDetailsRequirementsViewProps {
  requirements: Array<string>;
}

export const ScheduleDetailsRequirementsView: FC<IScheduleDetailsRequirementsViewProps> = ({ requirements }) => {
  const renderRequirements = useMemo(() => {
    const rendering = requirements.map((requirement, index) => {
      return <Tag key={index} label={requirement} color={'blue'} variant="solid" display="flex" size="md" />;
    });

    if (rendering.length === 0) return <S.InformationBody>Não há requerimento</S.InformationBody>;

    return <S.WrapperTags>{rendering}</S.WrapperTags>;
  }, [requirements]);

  return (
    <Box
      footerButtons={[
        <span key={0}/>,
        <Button
          key={1}
          label=""
          color="white"
          onPress={() => null}
          LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
          size="x-sm"
          variant="ghost"
          disabled
        />,
      ]}
    >
      <S.Wrapper>
        <S.Title>Requisitos Adicionais</S.Title>
        {renderRequirements}
      </S.Wrapper>
    </Box>
  );
};
