import { useCallback } from 'react';
import { IAssignPlan } from 'src-new/components/modal-assign-plan/modal-assign-plan.types';
import { sendEmailAssignPlanService } from 'src-new/services/send-email-assign-plan/send-email-assign-plan.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useSendEmailAssignPlan = () => {
  return useCallback(async (form: IAssignPlan, handleCloseModal: () => void) => {
    try {
      await sendEmailAssignPlanService(form);

      showMessageFormatted({
        message: 'Cadastramos o seu interesse.',
        type: 'success',
      });

      handleCloseModal();
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, []);
};
