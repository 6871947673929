import React from 'react';
import { InputFieldFormikView } from './input-field-formik-view.component';

interface IInputFieldFormikProps {
  id: string;
  name: string;
  value?: any;
  type: 'text' | 'password' | 'email' | 'number' | 'file' | 'date';
  label?: string;
  labelFor?: string;
  placeholder?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  paymentStyle?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  passwordVisible?: boolean;
  className?: string;
  upperCase?: boolean;
  onChange?: any;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  changePasswordVisible?: () => void;
  hidden?: boolean;
  autoComplete?: string;
  maxLength?: number;
  hasFocusEffect?: boolean;
}

export const InputFieldFormik: React.FC<IInputFieldFormikProps> = ({
  id,
  name,
  value = '',
  type,
  label,
  placeholder,
  reactNode,
  reactNodePosition,
  disabled,
  paymentStyle,
  hasError,
  errorMessage,
  passwordVisible,
  className,
  upperCase,
  maxLength,
  onChange = () => null,
  onBlur = () => null,
  onClick = () => null,
  onPaste = () => null,
  changePasswordVisible,
  hidden,
  autoComplete,
  hasFocusEffect = false,
}) => {
  return (
    <InputFieldFormikView
      maxLength={maxLength}
      hasFocusEffect={hasFocusEffect}
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      placeholder={placeholder}
      reactNode={reactNode}
      reactNodePosition={reactNodePosition}
      disabled={disabled}
      paymentStyle={paymentStyle}
      hasError={hasError}
      errorMessage={errorMessage}
      passwordVisible={passwordVisible}
      upperCase={upperCase}
      onClick={onClick}
      onPaste={onPaste}
      onChange={onChange}
      onBlur={onBlur}
      changePasswordVisible={changePasswordVisible}
      className={className}
      hidden={hidden}
      autoComplete={autoComplete}
    />
  );
};
