import { useCallback, useReducer } from 'react';
import { SaleOfferUpdateActionType } from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import {
  saleOfferUpdateReducer,
} from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/reducers/sale-offer-update/sale-offer-update.reducer';
import { ISaleOfferUpdate } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-update.types';

export const useSaleOfferUpdateContextValues = (): ISaleOfferUpdate => {
  const [state, dispatch] = useReducer(saleOfferUpdateReducer, saleOfferInitialState);

  const setSaleOfferUpdateRequest = useCallback(() => {
    dispatch({ type: SaleOfferUpdateActionType.SALE_OFFER_UPDATE_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferUpdateSuccess = useCallback(() => {
    dispatch({ type: SaleOfferUpdateActionType.SALE_OFFER_UPDATE_SUCCESS, payload: undefined });
  }, []);

  const setSaleOfferUpdateError = useCallback(() => {
    dispatch({ type: SaleOfferUpdateActionType.SALE_OFFER_UPDATE_ERROR, payload: undefined });
  }, []);

  const setSaleOfferUpdateReset = useCallback(() => {
    dispatch({ type: SaleOfferUpdateActionType.SALE_OFFER_UPDATE_RESET, payload: undefined });
  }, []);

  return {
    ...state.saleOfferUpdate,
    setSaleOfferUpdateRequest,
    setSaleOfferUpdateSuccess,
    setSaleOfferUpdateError,
    setSaleOfferUpdateReset,
  };
};
