import { FC } from 'react';
import { useFormikContext } from 'formik';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { WeighingFormMountVehiclesView } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-vehicles/weighing-register-form-mount-vehicles-view.component';

interface IWeighingFormMountVehiclesProps {
  hasError: boolean;
}

export const WeighingFormMountVehicles: FC<IWeighingFormMountVehiclesProps> = ({ hasError }) => {
  const { values, setFieldValue } = useFormikContext<IWeighingFormRegisterDomain>();

  return <WeighingFormMountVehiclesView values={values} hasError={hasError} setFieldValue={setFieldValue} />;
};
