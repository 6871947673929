/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useTransportCompanyList } from '../../hooks/use-transport-company-list/use-transport-company-list.hook';
import { TransportCompaniesContext } from '../../contexts/table-states/transport-companies.context';

import TransportCompanyListBodyView from './transport-company-list-body-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile, downloadTemplate } from 'utils/download-file/download-file';
import {
  convertActiveTabToStatusDomainMapper,
} from 'pages/transport-company/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import {
  useReportExcelHook,
} from 'pages/transport-company/hooks/use-transport-report-excel/use-trasnport-report-excel.hook';
import { useDeleteTransportHook } from 'pages/transport-company/hooks/use-transport-delete/use-transport-delete.hook';
import {
  useTransportCompanyUpload,
} from 'pages/transport-company/hooks/use-transport-company-upload/use-transport-company-upload.hook';
import { ITransportCompanyStatus } from 'domain/transport-company';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';
import ModalUpload from 'components/modal-upload/modal-upload';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const OptionsOrder: Array<ReportOption> = [
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Registro mais Antigo', value: 'asc' },
];

const TransportCompanyListBody: React.FC = (): JSX.Element => {
  const { transportCompanies, transportCompaniesIsLoading, transportCompaniesPageInfo } =
    useContext(TransportCompaniesContext);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Ativas');
  const [showModal, setShowModal] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('Transportadora');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const transportCompanyListHook = useTransportCompanyList();
  const transportCompanyUploadHook = useTransportCompanyUpload();
  const GenerateExcelHook = useReportExcelHook();
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);
  const deleteClientHook = useDeleteTransportHook();
  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const goToCreateTransportCompany = useCallback(() => {
    navigate('/transportadoras/novo');
  }, [navigate]);

  const goToDetailsPage = useCallback(
    (transportId: string) => {
      navigate(`/transportadoras/atualizar/${transportId}`);
    },
    [navigate],
  );

  const handleRemoveTransport = async (ids: number[], exclude = false) => {
    if (ids.length === 0) {
      showToast({
        message: 'Escolha pelo menos uma transportadora para excluir',
        type: 'error',
      });

      return;
    }

    if (exclude) {
      setModalConfirmDelete({ description: '', open: false });
      await deleteClientHook(ids);
      transportCompanyListHook({
        searchValue: searchInput,
        currentPage,
        status: renderStatusByTabName,
        order: sortDirection,
        column: orderBy,
      });
      setIdsToRemove([]);
    } else {
      const description = (
        <>
          <p>Isso excluirá a transportadora da listagem.</p>
          <p style={{ marginTop: 5 }}>Tem certeza?</p>
        </>
      );
      setModalConfirmDelete({ description, open: true });
    }
  };

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName),
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'TRANSPORTE');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  const renderStatusByTabName = useMemo((): ITransportCompanyStatus | undefined => {
    if (activeTabName === 'Todos') {
      return undefined;
    }

    if (activeTabName === 'Ativas') {
      return 'ativo';
    }

    if (activeTabName === 'Inativos') {
      return 'inativo';
    }

    if (activeTabName === 'Excluídos') {
      return 'deletado';
    }

    return undefined;
  }, [activeTabName]);

  const handleOrderList = useCallback(
    async (optionOrder: ReportOption) => {
      if (optionOrder.value === 'name') {
        transportCompanyListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'name',
        });
      }
      if (optionOrder.value === 'asc') {
        transportCompanyListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'id',
        });
      }
      showToast({
        type: 'info',
        message: 'Atualizando listagem',
      });
    },
    [currentPage, renderStatusByTabName, searchInput, transportCompanyListHook],
  );

  useEffect(() => {
    transportCompanyListHook({
      searchValue: searchInput,
      currentPage: searchInput ? 1 : currentPage,
      status: renderStatusByTabName,
      order: sortDirection,
      column: orderBy,
    });
  }, [orderBy, currentPage, sortDirection, renderStatusByTabName, searchInput, transportCompanyListHook]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Transportadora.xlsx');
  }, []);

  const handleOpenUploadXlsx = async () => {
    setShowModal(true);
  };

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await transportCompanyUploadHook(formData);
        if (data.uploadDate === 'error') {
          showToast({
            message: `Erro ao importar dados ${data.error.message}`,
            type: 'error',
          });
        } else {
          showToast({
            message: `Importação de ${data.total} arquivo(s) realizada com sucesso`,
            type: 'success',
          });
          transportCompanyListHook({
            searchValue: '',
            currentPage: 1,
            status: 'ativo',
            order: 'desc',
            column: 'id',
          });
          setShowModal(false);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    [transportCompanyUploadHook, setShowModal],
  );

  return (
    <>
      {showModal && (
        <ModalUpload
          title="Cadastro de Transportadoras"
          description="Selecione o arquivo"
          handleCancel={showModalConfirmation}
          handleSuccess={handleSuccess}
          handleDownload={handleDownload}
        />
      )}
      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Transportadora(s)"
          secondTitle={modalConfirmDelete.description}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveTransport(idsToRemove, true)}
          size="small"
          type="delete"
          description={undefined}
        />
      )}
      <TransportCompanyListBodyView
        transportCompanies={transportCompanies}
        hasLoading={transportCompaniesIsLoading}
        goToCreateTransportCompany={goToCreateTransportCompany}
        goToDetailsPage={goToDetailsPage}
        setCurrentPage={setCurrentPage}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        handleOpenUploadXlsx={handleOpenUploadXlsx}
        transportCompaniesPageInfo={transportCompaniesPageInfo}
        onHandleExport={handleGenerateExcel}
        options={Options}
        handleOrderList={handleOrderList}
        OptionsOrder={OptionsOrder}
        handleRemoveTransport={handleRemoveTransport}
        idsToRemove={idsToRemove}
        setIdsToRemove={setIdsToRemove}
        clickOrderBy={(e: string) => setOrderBy(e)}
        clickSortDirection={() => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')}
      />
    </>
  );
};

export { TransportCompanyListBody };
