import API from 'utils/API/API';
import { IGetPriceScheduleLTLCalculation, SchedulePriceLTL } from 'domain-v2/schedule-ltl/price';

export const getPriceScheduleLTLCalculationService = async (
  payload: IGetPriceScheduleLTLCalculation,
): Promise<SchedulePriceLTL> => {
  const { data } = await API.post('v3/schedules/price-ltl', payload);

  return data;
};
