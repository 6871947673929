import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FormikValues } from 'formik';

import { useScheduleLTLCalculateFreightHook, useScheduleLTLCreateHook } from '../hooks';
import { rulesResumeScheduleSTLForm } from '../constants/resume';
import { FreightPriceLTL, FreightPriceLTLData, FreightTLTTypes } from '../../schedule-ltl/types/freight-selection';
import { convertScheduleLTLToEntity } from '../../schedule-ltl/mappers/convert-schedule-to-enity.mapper';
import { initialStateFreightLTLTotal } from '../../schedule-ltl/constants/schedule-ltl-freight-price';

import ScheduleLtlListBodyView from './register-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { localitiesFilterSearchService } from 'pages/schedule/services/locality-search/locality-filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { clientsFilterSearchService } from 'pages/schedule/services/clients-search/clients-filter-search.service';
import { useCardList } from 'pages/schedule/hooks/use-card-list/use-card-list.types';
import { SchedulesContext } from 'pages/schedule/context/schedule-register/schedule.context';
import { useDetailsCompanyHook } from 'pages/company/hooks/company-details/company-details.hook';
import { useResumeRules } from 'pages/allocation/hooks/use-resume-rules/use-resume-rules.hook';
import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { useMapDistanceCalculateHook } from 'hooks/maps/map-distance-calculate.hook';
import { useConvertAddressToGeoPositionHook } from 'hooks/maps/map-convert-address-to-geo.hook';
import { showToast } from 'components/toast/toast';

const ScheduleStlListBody: React.FC = (): JSX.Element => {
  const [freightLTLTotal, setFreightLTLTotal] = useState<Partial<FreightPriceLTL>>();
  const [selectedFreight, setSelectedFreight] = useState<FreightTLTTypes>('recommended');
  const navigate = useNavigate();
  const resumeHook = useResumeRules();
  const cardsListHook = useCardList();
  const registerSchedule = useScheduleLTLCreateHook();
  const calculateFreight = useScheduleLTLCalculateFreightHook();
  const calculateDistanceHook = useMapDistanceCalculateHook();
  const convertConvertToAddressToGeoHook = useConvertAddressToGeoPositionHook();
  const { cards, setCardsList } = useContext(SchedulesContext);
  const { infoRules, setResumeRules } = useContext(AllocationRegisterContext);
  const [companyName, setCompanyName] = useState<string>('');
  const { user } = useContext(UserContext);
  const companyDetailHook = useDetailsCompanyHook();
  const [autoSelectedDate, setAutoSelectedDate] = useState<string>('');

  useEffect(() => {
    if (user?.companyId) {
      companyDetailHook(user?.companyId).then((company) => {
        setCompanyName(company.tradeName);
      });
    }
  }, [companyDetailHook, user?.companyId]);

  const goBackToListSchedules = useCallback(() => navigate('/alocacao-stl'), [navigate]);

  useEffect(() => {
    const descriptionName = {
      recommended: 'Frete Recomendado',
      early: 'Frete Antecipado',
      later: 'Frete Otimizado',
    };
    resumeHook({
      infoRules: {
        ...rulesResumeScheduleSTLForm,
        description: descriptionName[selectedFreight],
      },
    });
    cardsListHook({
      cards: [
        {
          name: 'Frete',
          subName: 'Recomendado',
          partnerName: '',
          dateOf: freightLTLTotal?.recommended?.date || '-',
          tariffName: `${
            freightLTLTotal?.recommended?.price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(Number(freightLTLTotal.recommended.price.total))
              : 'R$ -'
          }`,
          bgColor: '#D9D9D9',
          color: '#000',
          id: 'card-freight-recommended',
          onClickAction: () => displayVisualSelectFreight(0),
          border: selectedFreight === 'recommended' ? 'none' : 'none',
        },
        {
          name: 'Frete',
          subName: 'Antecipado',
          partnerName: '',
          dateOf: freightLTLTotal?.early?.date || '-',
          tariffName: `${
            freightLTLTotal?.early?.price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(Number(freightLTLTotal.early.price.total))
              : 'R$ -'
          }`,
          bgColor: '#D9D9D9',
          color: '#000',
          id: 'card-freight-early',
          onClickAction: () => displayVisualSelectFreight(1),
          border: selectedFreight === 'early' ? 'none' : 'none',
        },
        {
          name: 'Frete',
          subName: 'Otimizado',
          partnerName: '',
          dateOf: selectedFreight === 'later' ? autoSelectedDate : freightLTLTotal?.later?.date || '-',
          tariffName: `${
            freightLTLTotal?.later?.price
              ? new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(Number(freightLTLTotal.later.price.total))
              : 'R$ -'
          }`,
          bgColor: '#D9D9D9',
          color: '#000',
          id: 'card-freight-later',
          onClickAction: () => displayVisualSelectFreight(2),
          border: selectedFreight === 'later' ? 'none' : 'none',
        },
      ],
    });
  }, [cardsListHook, freightLTLTotal, resumeHook, selectedFreight, autoSelectedDate]);

  useEffect(() => {
    const customFreightCard = document.getElementById('card-custom-freight');

    if (customFreightCard) {
      customFreightCard.style.color = '#fff';
      customFreightCard.style.background = '#00B050';
      setSelectedFreight('recommended');
    }
  }, [cardsListHook, resumeHook, companyName]);

  const displayVisualSelectFreight = (type: number): void => {
    const cardFreightRecommended = document.getElementById('card-freight-recommended') as HTMLDivElement;
    const cardFreightEarly = document.getElementById('card-freight-early') as HTMLDivElement;
    const cardFreightLater = document.getElementById('card-freight-later') as HTMLDivElement;

    const buttonCards = [cardFreightRecommended, cardFreightEarly, cardFreightLater];

    const setButtonStyle = (button: HTMLDivElement, active: boolean, selected: number): void => {
      if (active) {
        button.style.color = '#fff';
        button.style.background = '#00B050';
        button.style.border = 'none';
        const freightId = ['recommended', 'early', 'later'];
        setSelectedFreight(freightId[selected] as FreightTLTTypes);
      } else {
        button.style.color = '#000';
        button.style.background = '#D9D9D9';
        button.style.border = 'none';
      }
    };

    const toggleButtons = (buttons: HTMLDivElement[]): void => {
      buttons.forEach((item, index) => {
        setButtonStyle(item, index === type, index);
      });
    };

    toggleButtons(buttonCards);
  };

  useEffect(() => {
    const customFreightCard = document.getElementById('card-freight-recommended');
    if (customFreightCard) {
      customFreightCard.style.color = '#FFFFFF';
      customFreightCard.style.background = '#00B050';
      setSelectedFreight('recommended');
    }
  }, []);

  const apiCall = async (searchValue: string, clientType: string): Promise<SelectItem[]> => {
    if (clientType === 'client') {
      if (searchValue) return await clientsFilterSearchService(searchValue);
    } else if (clientType === 'plant') {
      if (searchValue) return await localitiesFilterSearchService(searchValue);
    }

    return [];
  };

  const handleSubmit = useCallback(
    async (schedule: FormikValues) => {
      registerSchedule(
        convertScheduleLTLToEntity({
          ...schedule,
          selectedFreight,
        }),
      )
        .then(() => {
          showMessageFormatted({
            message: 'Agendamento realizado com sucesso.',
            type: 'success',
          });
          goBackToListSchedules();
        })
        .catch((err) => {
          showMessageFormatted({
            message: err.message,
            type: 'error',
          });
        });
    },
    [goBackToListSchedules, registerSchedule, selectedFreight],
  );

  const handleChangeValues = useCallback(
    async (values: FormikValues): Promise<FreightPriceLTLData | undefined> => {
      if (values.cargoDetails.pallet < 6 || values.cargoDetails.pallet > 18) {
        showToast({
          message: 'O número de pallets deve ser entre 6 e 18',
          type: 'warning',
        });

        return undefined;
      }

      const originId = values.origin.clientId || values.origin.localityId;
      const destinyId = values.destiny.clientId || values.destiny.localityId;

      if (
        originId &&
        destinyId &&
        values.cargoDetails.shippingValue &&
        values.cargoDetails.pallet &&
        values.cargoDetails.pickUpDate
      ) {
        try {
          let selectedFreightByDate: FreightTLTTypes = selectedFreight;
          let selectedFreightByDateIndex = 0;

          const response = await calculateFreight(
            originId,
            values.origin.clientType,
            destinyId,
            values.destiny.clientType,
            values.cargoDetails.pallet,
            values.cargoDetails.shippingValue,
            values.cargoDetails.pickUpDate,
          );

          const freightSelected = response[selectedFreightByDate];

          const currentAutoDateDelivery = moment(autoSelectedDate, 'DD/MM/YYYY');
          const userSelectedDateDelivery = moment(values.cargoDetails.deliveryDate, 'DD/MM/YYYY');
          let newAutoSelectedDate = currentAutoDateDelivery;

          if (currentAutoDateDelivery.isSame(userSelectedDateDelivery)) {
            const freightRecommendedDate = moment(freightSelected.date, 'DD/MM/YYYY');
            if (!currentAutoDateDelivery.isSame(freightRecommendedDate)) {
              newAutoSelectedDate = freightRecommendedDate;
            }
          } else {
            newAutoSelectedDate = userSelectedDateDelivery;
          }

          if (!newAutoSelectedDate.isValid()) {
            newAutoSelectedDate = moment(freightSelected.date, 'DD/MM/YYYY');
          }

          setFreightLTLTotal(response);
          setAutoSelectedDate(newAutoSelectedDate.format('DD/MM/YYYY'));

          if (values.cargoDetails.pickUpDate && newAutoSelectedDate) {
            const recommendedDate = moment(response['recommended'].date, 'DD/MM/YYYY');
            const earlyDate = moment(response['early'].date, 'DD/MM/YYYY');
            const laterDate = moment(response['later'].date, 'DD/MM/YYYY');

            if (newAutoSelectedDate.isSameOrAfter(earlyDate) && newAutoSelectedDate.isBefore(recommendedDate)) {
              selectedFreightByDate = 'early';
              selectedFreightByDateIndex = 1;
            } else if (newAutoSelectedDate.isSameOrAfter(earlyDate) && newAutoSelectedDate.isBefore(laterDate)) {
              selectedFreightByDate = 'recommended';
              selectedFreightByDateIndex = 0;
            } else {
              selectedFreightByDate = 'later';
              selectedFreightByDateIndex = 2;
            }
          }

          setSelectedFreight(selectedFreightByDate);
          displayVisualSelectFreight(selectedFreightByDateIndex);

          return freightSelected;
        } catch {
          setFreightLTLTotal(initialStateFreightLTLTotal);
        }
      }

      return undefined;
    },
    [calculateDistanceHook, convertConvertToAddressToGeoHook, selectedFreight, autoSelectedDate],
  );

  return (
    <ScheduleLtlListBodyView
      info={infoRules}
      setInfo={setResumeRules}
      cards={cards}
      setCardsList={setCardsList}
      apiCall={apiCall}
      handleScheduleRegister={handleSubmit}
      handleGoback={goBackToListSchedules}
      handleChangeValues={handleChangeValues}
      selectedFreight={selectedFreight}
      freightLTLTotal={freightLTLTotal}
      autoSelectedDate={autoSelectedDate}
    />
  );
};

export { ScheduleStlListBody };
