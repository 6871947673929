import React from 'react';

import { NegotiationsModalBodyView } from './negotiations-modal-body-view';

import { IOccurrenceResolution } from 'domain/tracking-occurrence';

interface IProps {
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitModal: (values: IOccurrenceResolution) => void;
}

const NegotiationsModalBody: React.FC<IProps> = ({ title, showModal, setShowModal, submitModal }) => {
  return (
    <NegotiationsModalBodyView
      title={title}
      showModal={showModal}
      setShowModal={setShowModal}
      submitModal={submitModal}
    />
  );
};

export { NegotiationsModalBody };
