import { FC } from 'react';

import { ButtonContainer, DescriptionTitle, Info, InfoTitle, SectionContainer, Wrapper } from './style';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { TransactionDetails } from 'domain-v2/transactions/details';
import { ModalBark } from 'components-v2/layout/modal-bark';
import Button from 'components/button/button';

interface ModalAuditTransactionProps {
  title: string;
  transaction: TransactionDetails;
  onConfirmation: () => void;
  handleCancel: () => void;
}

export const ModalAuditTransaction: FC<ModalAuditTransactionProps> = ({
  title,
  transaction,
  onConfirmation,
  handleCancel,
}) => {
  return (
    <ModalBark
      size="md"
      title="Confirme os dados"
      subtitle="Após a validação não será possível altera-los"
      handleClose={handleCancel}
    >
      <Wrapper>
        {transaction.status === 'Receber Transportador' && (
          <SectionContainer display="grid" columns="1fr 1fr">
            <InfoTitle>
              Transportadora
              <Info>{transaction.shippingCompany?.name}</Info>
            </InfoTitle>
            <InfoTitle>
              Prazo de Pagamento
              <Info>{'23 dias'}</Info>
            </InfoTitle>
          </SectionContainer>
        )}

        <DescriptionTitle>{title}</DescriptionTitle>
        <SectionContainer display="grid" columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <InfoTitle>
            Frete Valor
            <Info>{convertCurrencyFormat.format(transaction.valueFreight)}</Info>
          </InfoTitle>

          <InfoTitle>
            Ajudante
            <Info>{convertCurrencyFormat.format(transaction.helperTotal)}</Info>
          </InfoTitle>

          <InfoTitle>
            Pedágio
            <Info>{convertCurrencyFormat.format(transaction.toll)}</Info>
          </InfoTitle>

          <InfoTitle>
            Descarga
            <Info>{convertCurrencyFormat.format(transaction.discharge)}</Info>
          </InfoTitle>

          <InfoTitle>
            Tempo Excedente
            <Info>{convertCurrencyFormat.format(transaction.surplusTime)}</Info>
          </InfoTitle>

          <InfoTitle>
            Desconto
            <Info>{convertCurrencyFormat.format(transaction.penaltyBonus)}</Info>
          </InfoTitle>
        </SectionContainer>

        <SectionContainer display="grid" columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <InfoTitle>
            {transaction.status !== 'Receber Transportador' ? 'Taxa Logshare' : 'Taxa de Antecipação'}
            <Info>
              {transaction.status !== 'Receber Transportador'
                ? convertCurrencyFormat.format(transaction.totalFee)
                : convertCurrencyFormat.format(transaction.feeAnticipation)}
            </Info>
          </InfoTitle>

          <InfoTitle>
            Gris/Advalorem
            <Info>{convertCurrencyFormat.format(transaction.totalGrisAdvalorem)}</Info>
          </InfoTitle>

          <InfoTitle>
            Frete Total
            <Info>{convertCurrencyFormat.format(transaction.totalFreight)}</Info>
          </InfoTitle>

          <InfoTitle>
            ICMS
            <Info>{convertCurrencyFormat.format(transaction.icms)}</Info>
          </InfoTitle>

          <InfoTitle>
            Outros Impostos
            <Info>{convertCurrencyFormat.format(transaction.otherTaxes)}</Info>
          </InfoTitle>

          <InfoTitle>
            Total do Serviço
            <Info>{convertCurrencyFormat.format(transaction.totalService)}</Info>
          </InfoTitle>
        </SectionContainer>

        <ButtonContainer>
          <Button title={'Validar'} callback={onConfirmation} bgColor={'blue'} size="large" />
        </ButtonContainer>
      </Wrapper>
    </ModalBark>
  );
};
