import React, { Dispatch, Fragment, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IOrdering, IRow } from 'src-new/components/table/table.types';
import * as S from './weighing-table-view.styled';
import { IWeighingListDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list.domain';
import { Tag } from 'logshare-ui-kit';
import { convertWeighingStatus } from 'src-new/utils/convert-weighing-status/convert-weighing-status.util';
import { convertDecimalFormat } from 'src-new/utils/convert-decimal-format.util';
import { Loading } from 'src-new/components/loading/loading.component';
import { DropdownButtonTable } from 'src-new/components/dropdown-button-table/dropdown-button-table.component';
import { Files } from 'lucide-react';
import TooltipComponent from 'components/Tooltip';
interface IWeighingTableViewProps {
  weighing: Array<IWeighingListDomain>;
  isLoading: boolean;
  orderingColumns: IOrdering;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
  handleLineClick: (id: number) => void;
  handleDuplicatedClick: (id: number) => void;
}

export const WeighingTableView: React.FC<IWeighingTableViewProps> = ({
  weighing,
  isLoading,
  orderingColumns,
  handleInfinityScroll,
  handleLineClick,
  handleDuplicatedClick,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID' },
      { value: 'OC' },
      { value: 'Programada' },
      { value: 'Entrada' },
      { value: 'Saída' },
      { value: 'Check-in' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Veículo' },
      { value: 'Transportadora' },
      { value: 'Item' },
      { value: 'Status' },
      { value: '' },
    ];

    const columnsConfig = '0.1fr 0.2fr 0.3fr 0.3fr 0.3fr 0.3fr 0.45fr 0.45fr 0.3fr 0.4fr 0.3fr 0.4fr 0.2fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderReactNode = useCallback((reactNode: ReactNode) => {
    return <Fragment>{reactNode}</Fragment>;
  }, []);

  const renderTag = useCallback((weigh: IWeighingListDomain) => {
    return (
      <Tag
        label={convertWeighingStatus(weigh.ticketStatus).label}
        color={convertWeighingStatus(weigh.ticketStatus).color}
        variant="solid"
        display="inline-flex"
        size="md"
      />
    );
  }, []);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title?.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle?.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return weighing.map((weigh) => {
      return {
        key: weigh.id,
        onClick: () => handleLineClick(weigh.id),
        valuesColumns: [
          { value: weigh.id },
          { value: renderTitleSubtitle(weigh.collectionOrder ? weigh.collectionOrder : '-', '') },
          { value: renderTitleSubtitle(weigh.scheduledDateTime, '') },
          {
            value: renderTitleSubtitle(
              weigh.entryDate ? weigh.entryDate : '-',
              `${convertDecimalFormat.format(weigh.entryWeight)} KG`,
            ),
          },
          {
            value: renderTitleSubtitle(
              weigh.exitDate ? weigh.exitDate : '-',
              `${convertDecimalFormat.format(weigh.exitWeight)} KG`,
            ),
          },
          { value: renderTitleSubtitle(weigh.checkingDate ? weigh.checkingDate : '-', '') },
          { value: renderTitleSubtitle(weigh.origin.name, `${weigh.origin.uf} - ${weigh.origin.city}`) },
          { value: renderTitleSubtitle(weigh.destination.name, `${weigh.destination.uf} - ${weigh.destination.city}`) },
          { value: renderTitleSubtitle(weigh.vehicle.type, weigh.vehicle.mainPlate) },
          { value: renderTitleSubtitle(weigh.shippingCompany.name, weigh.vehicle.category) },
          {
            value: (
              <TooltipComponent
                title={renderTitleSubtitle(weigh.item.description ?? '', '')}
                location="top"
                icon={renderTitleSubtitle(weigh.item.description ?? '', '')}
              />
            ),
          },
          {
            value: renderReactNode(
              <TooltipComponent
                title={convertWeighingStatus(weigh.ticketStatus).label}
                location="top"
                icon={
                  <Tag
                    label={convertWeighingStatus(weigh.ticketStatus).label}
                    color={convertWeighingStatus(weigh.ticketStatus).color}
                    variant="solid"
                    display="flex"
                    size="md"
                  />
                }
              />,
            ),            
          },
          {
            value: (
              <S.DropdownButtonTable>
                <DropdownButtonTable
                  options={[
                    {
                      label: 'Duplicar',
                      onClick: () => handleDuplicatedClick(weigh.id),
                      icon: <Files width={18} color="gray" />,
                    },
                  ]}
                  iconPosition="horizontal"
                />
              </S.DropdownButtonTable>
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [handleDuplicatedClick, handleLineClick, renderTag, renderTitleSubtitle, weighing]);

  if (isLoading) return <Loading />;

  if (!weighing.length) return <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />;

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      rows={renderRows}
      height={'77vh'}
      infiniteScrollProps={{ isLoading: isLoading, onInfinityScroll: handleInfinityScroll }}
      ordering={orderingColumns}
    />
  );
};
