import request, { AxiosResponse } from 'axios';

import API from 'utils/API/API';

interface StatusError {
  statusCode: number;
  message: string;
}

export const driverDeleteService = async (ids: number[]): Promise<boolean> => {
  try {
    return await API.delete('v2/drivers', { data: { ids } });
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao remover os motoristas');
  }
};
