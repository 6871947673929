import { vehicleUpdateService } from 'pages/vehicle/services/vehicle-update/vehicle-update.service';
import { IVehicleCreated } from 'domain/vehicle';

const useUpdateVehicleHook = () => {
  return async (vehicle: IVehicleCreated) => {
    await vehicleUpdateService(vehicle);
  };
};

export { useUpdateVehicleHook };
