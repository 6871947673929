import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { HeaderTitle, Wrapper } from '../styled';

import {
  ContainerCTEAndButtonStyled,
  NFeItemHeader,
  NFeItemsContentGenerics,
  TitleNFEStyled,
} from './freight-cargo-nfe-section.styled';
import { RenderNFeUploadedData } from './components/render-uploaded-nfe-data';

import { SendNFeResponse } from 'pages/freight/types';
import { useGetFreightNFesGenericksHook, useUploadNFeHook } from 'pages/freight/hooks';
import { ScheduleDomain } from 'domain/schedule';
import Loading from 'components-v2/common/loading/index';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';

interface IProps {
  freightValues: ScheduleDomain;
}

const FreightNFESectionView: React.FC<IProps> = ({ freightValues }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [infosGenerics, setInfosGenectics] = useState<SendNFeResponse[]>([]);

  const handleUseListNFeGenerics = useGetFreightNFesGenericksHook();
  const handleUseUploadNFe = useUploadNFeHook();

  const handleGetNFeList = useCallback(async () => {
    if (id) {
      setIsLoading(true);
      const responseGenerics = await handleUseListNFeGenerics(id);

      if (responseGenerics) {
        setInfosGenectics(responseGenerics);
      }

      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    handleGetNFeList();
  }, [handleGetNFeList]);

  const handleUploadFile = async (files: any) => {
    if (id) {
      for (let i = 0; i < files.length; i++) {
        await handleUseUploadNFe(id, files[i]);
      }

      await handleGetNFeList();
    }
  };

  useEffect(() => {
    handleGetNFeList();
  }, [handleGetNFeList]);

  const renderInfosGenerics = useMemo(() => {
    if (infosGenerics) {
      return (
        <NFeItemsContentGenerics>
          {infosGenerics.map((info) => {
            return (
              <RenderNFeUploadedData
                key={info.id}
                index={info.id}
                cte={info}
                onSuccess={handleGetNFeList}
                freightValues={freightValues}
              />
            );
          })}
        </NFeItemsContentGenerics>
      );
    }

    return <TitleNFEStyled>Anexar uma NFe para Gerar o CTe</TitleNFEStyled>;
  }, [infosGenerics, freightValues]);

  return (
    <Wrapper>
      <HeaderTitle display="flex" justify="space-between" isTraced>
        <div>NFe</div>
        <div style={{ display: 'flex', gap: 10 }}>
          <InputFile onChange={handleUploadFile} multiple>
            <Button title="Anexar" bgColor="aqua" size="very-small" icon={<AttachFileIcon />} />
          </InputFile>
        </div>
      </HeaderTitle>

      <ContainerCTEAndButtonStyled>
        {isLoading ? (
          <div style={{ width: 0, margin: '0 auto' }}>
            <Loading />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '2fr 0.2fr 0fr',
                paddingBottom: 10,
              }}
            >
              <NFeItemHeader className="title">Identificação</NFeItemHeader>
              <div style={{ display: 'flex', gap: 10 }}>
                <div style={{ minWidth: '40px' }}></div>
                <div style={{ minWidth: '40px' }}></div>
              </div>
            </div>
            {renderInfosGenerics}
          </div>
        )}
      </ContainerCTEAndButtonStyled>
    </Wrapper>
  );
};

export default FreightNFESectionView;
