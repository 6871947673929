import styled, { css } from 'styled-components';

interface IStyledProps {
  primaryButton?: boolean;
  activeButton: boolean;
  left: string;
}

export const MapTypeButton = styled.button<IStyledProps>`
  position: absolute;
  width: 80px;
  height: 28px;
  left: ${({ left }) => (left ? left : '0px')};
  top: 25px;
  border-style: none;
  border-radius: ${({ primaryButton }) => (primaryButton ? '6px 0 0 6px' : '0 6px 6px 0')};
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  border-right: solid;
  border-right-width: thin;
  border-right-color: rgba(240, 240, 240, 0.66);

  &:hover {
    background-color: rgba(240, 240, 240, 0.66);
    backdrop-filter: blur(5px);
  }

  ${(props) =>
    props.activeButton &&
    css`
      background-color: rgba(240, 240, 240, 0.66);
      backdrop-filter: blur(5px);
    `}
`;

export const MapTypeLabelButton = styled.div`
  font-family: Montserrat, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
