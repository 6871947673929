import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getKanbanListService } from '../../services/get-kanban-list/get-kanban-list.service';
import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { IScheduleKanbanListDomain } from '../../domains/schedule-kanban-list/schedule-kanban.domain';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetKanbanList = () => {
  const { scheduleKanbanList, scheduleList } = useContext(ScheduleContext);
  const { setScheduleKanbanListRequest, setScheduleKanbanListSuccess, setScheduleKanbanListError } = scheduleKanbanList;

  const { data } = useQuery<IScheduleKanbanListDomain>({
    queryKey: ['schedule-kanban-list', scheduleList.scheduleListFiltersProps.filters],
    queryFn: () => {
      setScheduleKanbanListRequest();
      return getKanbanListService(scheduleList.scheduleListFiltersProps.filters);
    },
    onSuccess: (data) => setScheduleKanbanListSuccess(data),
    onError: (error) => {
      setScheduleKanbanListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      setScheduleKanbanListSuccess(data);
    }
  }, [data, setScheduleKanbanListSuccess]);
};
