import { useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useParams } from 'react-router-dom';
import { getScheduleCustomFieldsService } from '../../services/get-schedule-custom-fields/get-schedule-custom-fields.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getCustomFieldsService } from '../../services/get-custom-fields/get-custom-fields.service';

export const useGetScheduleCustomFields = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { id } = useParams<{ id: string }>();

  const scheduleId = useMemo(() => id ?? '', [id]);

  return useCallback(async () => {
    try {
      let customFields = await getScheduleCustomFieldsService(scheduleId);

      if (customFields.length === 0) {
        customFields = await getCustomFieldsService();
      }

      scheduleDetails.scheduleCustomFields.setScheduleCustomFieldsDataSuccess(customFields);
    } catch (error: any) {
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    }
  }, [scheduleDetails, scheduleId]);
};
