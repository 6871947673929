import { FC, useMemo } from 'react';
import * as S from './sale-offer-backhaul-purchase-form-view.styled';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { convertDecimalFormat } from 'utils-v2/converters/decimal';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';
import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import { ICostInput } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchaseSaleOffer } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface ISaleOfferDetailsBackhaulPurchaseFormViewProps {
  costValues: ICostInput;
  saleOffer: IBackhaulPurchaseSaleOffer;
  companyImage: string;
  freightValues: {
    totalFreight: number;
    icmsValue: number;
    totalService: number;
  };
  grisAdvalorem: {
    total: number;
    grisPercent: number;
    advaloremPercent: number;
  };
  freetime: IFreeTimeDomain;
  hasPurchaseValues: boolean;
  hasError: boolean;
  handleChange: (fieldName: string, value: string) => void;
}

export const SaleOfferDetailsBackhaulPurchaseFormView: FC<ISaleOfferDetailsBackhaulPurchaseFormViewProps> = ({
  costValues,
  saleOffer,
  companyImage,
  freightValues,
  grisAdvalorem,
  freetime,
  hasPurchaseValues,
  hasError,
  handleChange,
}) => {
  const renderCapacityValues = useMemo((): string => {
    const weight = convertDecimalFormat.format(saleOffer.capacity.weight ?? 0);
    const pallets = saleOffer.capacity.pallets ?? '-';
    const cubage = saleOffer.capacity.cubage ?? '-';

    return `${weight} KG | ${pallets} PLTS | ${cubage} M³`;
  }, [saleOffer.capacity.cubage, saleOffer.capacity.pallets, saleOffer.capacity.weight]);

  const renderTitleForm = useMemo(
    (): string => (saleOffer.backhaulPartner && !hasPurchaseValues ? 'PROPOSTA ACORDADA' : 'INFORME SUA PROPOSTA'),
    [hasPurchaseValues, saleOffer.backhaulPartner],
  );

  const renderFreightValue = useMemo(
    (): string => Number.parseFloat(costValues.freightValue.toString()).toFixed(2).toString(),
    [costValues.freightValue],
  );

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.TitleForm>DETALHES DA OFERTA DE VENDA</S.TitleForm>

        <S.WrapperCompanyImage src={companyImage} />
      </S.TitleContainer>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Origem</S.TitleValues>

          <S.LocationContainer>
            <S.TextValue>
              {saleOffer.origin.city.toUpperCase()} - {saleOffer.origin.uf.toUpperCase()}
            </S.TextValue>
          </S.LocationContainer>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Destino</S.TitleValues>

          <S.LocationContainer>
            <S.TextValue>
              {saleOffer.destination.city.toUpperCase()} - {saleOffer.destination.uf.toUpperCase()}
            </S.TextValue>
          </S.LocationContainer>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Veículo</S.TitleValues>

          <S.LocationContainer>
            <S.TextValue>
              {saleOffer.vehicle.type.toUpperCase()} - {saleOffer.vehicle.category.toUpperCase()}
            </S.TextValue>
          </S.LocationContainer>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Capacidade</S.TitleValues>

          <S.TextValue>{renderCapacityValues}</S.TextValue>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperTitleProposalValue>
          <S.TitleForm>{renderTitleForm}</S.TitleForm>
        </S.WrapperTitleProposalValue>

        <InputCurrency
          id="cost.freightValue"
          name="cost.freightValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          decimalSeparator=","
          step={1}
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => handleChange(name ?? '', value ?? '')}
          value={renderFreightValue}
          hasError={hasError && costValues.freightValue === 0}
          errorMessage="Campo Obrigatório"
          disabled={saleOffer.backhaulPartner && !hasPurchaseValues}
        />
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperOfferValues>
          <S.TextValue>FRETE VALOR</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(costValues.freightValue ?? 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>PEDÁGIO</S.TextValue>
          <S.SubTitle>A INCLUIR</S.SubTitle>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.WrapperTooltip>
            <S.TextValue>GRIS/ADVALOREM</S.TextValue>
            <Tooltip
              title={
                <S.Div>
                  <S.TextTooltip>Gris: {convertDecimalFormat.format(grisAdvalorem.grisPercent)}%</S.TextTooltip>

                  <S.TextTooltip>
                    Advalorem: {convertDecimalFormat.format(grisAdvalorem.advaloremPercent)}%
                  </S.TextTooltip>
                </S.Div>
              }
              placement="right"
            >
              <S.Div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </S.Div>
            </Tooltip>
          </S.WrapperTooltip>

          <S.TextValue>{convertCurrencyFormat.format(grisAdvalorem.total)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.WrapperTooltip>
            <S.TextValue>EXCEDENTE</S.TextValue>
            <Tooltip
              title={
                <S.Div>
                  <S.TextTooltip>Freetime: {freetime.freeTime}</S.TextTooltip>

                  <S.TextTooltip>
                    Valor da Hora Parado: {convertCurrencyFormat.format(freetime.hourlyFine ?? 0)}
                  </S.TextTooltip>

                  <S.TextTooltip>
                    Valor da Diária Parado: {convertCurrencyFormat.format(freetime.dailyFine ?? 0)}
                  </S.TextTooltip>
                </S.Div>
              }
              placement="right"
            >
              <S.Div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </S.Div>
            </Tooltip>
          </S.WrapperTooltip>

          <S.TextValue>{convertCurrencyFormat.format(costValues.surplusValue[0] ?? 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>FRETE TOTAL</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(freightValues.totalFreight)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>ICMS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(freightValues.icmsValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>OUTROS IMPOSTOS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(costValues.otherTaxes ?? 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>TOTAL A PAGAR</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(freightValues.totalService)}</S.TextValue>
        </S.WrapperOfferValues>
      </LineSectionLayout>
    </S.Wrapper>
  );
};
