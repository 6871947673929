import { formatDateAndTime } from 'utils-v2/converters/date';
import {
  CardResumeOriginAndDestinyInput,
  IDeliveryScheduleDetailsValues,
  IFreightValues,
  ILevyScheduleDetailsValues,
  IOperationValues,
} from 'pages-v2/schedule-ltl/components/card-resume/types';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';

export const convertResumeCreate = (schedule: ScheduleLTLRegisterInput) => {
  const { origin, destination, cargo, detail, cost, distance } = schedule;

  const originValues: CardResumeOriginAndDestinyInput = {
    tradeName: origin.tradeName,
    cnpj: origin.origin?.cnpj,
    address: {
      cep: origin.origin?.address?.city,
      street: origin.origin?.address?.complement || '',
      city: origin.origin?.address?.neighborhood,
      neighborhood: origin.origin?.address?.numberHouse,
      uf: origin.origin?.address?.uf,
      numberHouse: origin.origin?.address?.street,
      complement: origin.origin?.address?.cep,
    },
  };

  const scheduleDetailsValuesOrigin: ILevyScheduleDetailsValues = {
    levyInitialDate: formatDateAndTime(detail.levyInitialDate),
    levyEndDate: formatDateAndTime(detail.levyInitialDate),
  };

  const destinationValues: CardResumeOriginAndDestinyInput = {
    tradeName: destination.tradeName,
    cnpj: destination.destination?.cnpj,
    address: {
      cep: destination.destination?.address?.city,
      street: destination.destination?.address?.complement || '',
      city: destination.destination?.address?.neighborhood,
      neighborhood: destination.destination?.address?.numberHouse,
      uf: destination.destination?.address?.uf,
      numberHouse: destination.destination?.address?.street,
      complement: destination.destination?.address?.cep,
    },
  };

  const scheduleDetailsValuesDestination: IDeliveryScheduleDetailsValues = {
    deliveryInitialDate: formatDateAndTime(detail.deliveryEndDate),
    deliveryEndDate: formatDateAndTime(detail.deliveryEndDate),
  };

  const scheduleCargoValues: IOperationValues = {
    weight: cargo.weight,
    volume: cargo.volume,
    inPallet: cargo.inPallet,
    pallet: cargo.pallet,
    product: cargo.product,
    merchValue: cargo.merchValue,
    vehicleCategoryType: cargo.vehicleCategoryType,
    vehicleType: cargo.vehicleType,
    distance: distance,
  };

  const scheduleCostValues: IFreightValues = {
    value: cost.valueFreight,
    helperPrice: cost.helperTotal,
    grisAdvaloremPrice: cost.totalGrisAdvalorem,
    taxPrice: cost.taxes,
    tollPrice: cost.toll,
    totalFreight: cost.totalFreight,
    icms: cost.icms,
    totalService: cost.totalService,
  };

  return {
    originValues,
    scheduleDetailsValuesOrigin,
    destinationValues,
    scheduleDetailsValuesDestination,
    scheduleCargoValues,
    scheduleCostValues,
  };
};
