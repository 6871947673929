import { useNavigate } from 'react-router-dom';
import { FC, useCallback, useMemo } from 'react';
import moment from 'moment';

import * as S from './styled';

import { convertAlertsRedirects } from 'utils-v2/converters/alerts-redirects';
import { convertIconAlerts } from 'utils-v2/converters/alerts-icons';
import { viewAlertService } from 'services/alerts/view';
import { Alerts } from 'domain-v2/alerts';
import { DrawerBarker } from 'components-v2/layout/drawer-bark';

interface ModalAlerts {
  userId: number;
  alerts: Array<Alerts>;
  refetchAlerts: () => void;
  handleClose: () => void;
}

export const ModalAlerts: FC<ModalAlerts> = ({ alerts, userId, refetchAlerts, handleClose }) => {
  const navigate = useNavigate();

  const newDate = moment();

  const viewAlert = useCallback(
    async (alertId: number) => {
      await viewAlertService(alertId, userId);
      refetchAlerts();
    },
    [alerts],
  );

  const renderAlertsList = useMemo(() => {
    if (!alerts.length)
      return (
        <S.AlertsEmptyMessage>
          <p>
            Você não possui <br /> novos alertas
          </p>
        </S.AlertsEmptyMessage>
      );

    const mapping = alerts.map((alert) => {
      const isViewed = alert.isview;
      const createdAlert = moment(alert.viewdate);

      const checkViewedAlert = () => {
        if (isViewed && newDate.diff(createdAlert, 'days') > 0) {
          return `Visualizado há ${newDate.diff(createdAlert, 'days')} dias`;
        }

        if (isViewed && newDate.diff(createdAlert, 'days') === 0) {
          return `Visualizado hoje às ${createdAlert.format('HH:mm')}`;
        }

        return '';
      };

      return (
        <S.AlertsList
          key={alert.id}
          onClick={() => navigate(convertAlertsRedirects(alert.redirect))}
          onMouseEnter={async () => {
            viewAlert(alert.id);
          }}
        >
          <S.WrapperAlertsMessage viewed={alert.isview}>
            <S.AlertsMessage>
              <S.WrapperIconAlert>
                {convertIconAlerts(alert.event)}
                <S.Point viewed={alert.isview} />
              </S.WrapperIconAlert>

              <p>{alert.message}</p>
            </S.AlertsMessage>

            <S.ViewedAlert>{checkViewedAlert()} </S.ViewedAlert>
          </S.WrapperAlertsMessage>
          <S.Line />
        </S.AlertsList>
      );
    });

    return mapping;
  }, [alerts, refetchAlerts]);

  return (
    <DrawerBarker title={'Alertas'} handleClose={handleClose}>
      {renderAlertsList}
    </DrawerBarker>
  );
};
