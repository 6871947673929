import {
  IScheduleDetailsCargoDomain,
  IScheduleDetailsCompanyBuyerDomain,
  IScheduleDetailsCompanyDomain,
  IScheduleDetailsComplementDomain,
  IScheduleDetailsDomain,
  IScheduleDetailsDriverDomain,
  IScheduleDetailsFinanceDomain,
  IScheduleDetailsMyRouteDomain,
  IScheduleDetailsOriginAndDestinationDomain,
  IScheduleDetailsShippingDomain,
  IScheduleDetailsVehicleDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';

export const scheduleDetailsCompanyInitialValuesDomain: IScheduleDetailsCompanyDomain = {
  id: 0,
  name: '',
  tradeName: '',
  cnpj: '',
  gris: 0,
  advalorem: 0,
  feeLogshare: 0,
};

export const scheduleDetailsCompanyBuyerInitialValuesDomain: IScheduleDetailsCompanyBuyerDomain = {
  id: 0,
  name: '',
  gris: 0,
  advalorem: 0,
};

export const scheduleDetailsOriginAndDestinationInitialValuesDomain: IScheduleDetailsOriginAndDestinationDomain = {
  id: 0,
  type: 'CLIENT',
  name: '',
  cnpj: '',
  address: {
    cep: '',
    city: '',
    complement: '',
    neighborhood: '',
    number: '',
    street: '',
    uf: '',
    lat: '-23.5966212',
    lng: '-46.6868131',
  },
  qualityAndSafety: {
    custom: [''],
    quality: [''],
    security: [''],
  },
  emails: [''],
  emailsOcurrence: [''],
};

export const scheduleDetailsCargoInitialValuesDomain: IScheduleDetailsCargoDomain = {
  vehicleType: '',
  vehicleCategoryType: '',
  shippingReturn: '',
  product: '',
  weight: 0,
  cubagem: 0,
  volume: 0,
  merchValue: 0,
  inPallet: false,
  pallet: 0,
  order: '',
  comments: '',
  requirements: {
    quality: {
      id: 0,
      values: [],
    },
    security: {
      id: 0,
      values: [],
    },
    custom: {
      id: 0,
      values: [],
    },
  },
};

export const scheduleDetailsComplementInitialValuesDomain: IScheduleDetailsComplementDomain = {
  id: 0,
  levyInitialDate: '',
  levyEndDate: '',
  deliveryInitialDate: '',
  deliveryEndDate: '',
  internalNumber: '',
  ocInternalNumber: '',
  boardingNumber: '',
  schedulingPassword: '',
  checkinDate: '',
  checkoutDate: '',
  loadDate: '',
  finishDate: '',
  acceptDate: '',
  startDate: '',
  acceptDateAgent: '',
  startDateAgent: '',
  checkinDateAgent: '',
  checkoutDateAgent: '',
  loadDateAgent: '',
  finishDateAgent: '',
  acceptShipping: '',
  allocationVehicleDate: '',
  allocationDriverDate: '',
  allocationShippingDate: '',
  loadSchedulingDate: '',
  loadSchedulingAgent: '',
  arrivalLoadingDate: '',
  arrivalLoadingAgent: '',
  startTripUnloadingDate: '',
  startTripUnloadingAgent: '',
  arrivalUnloadingDate: '',
  arrivalUnloadingAgent: '',
};

export const scheduleDetailsShippingInitialValuesDomain: IScheduleDetailsShippingDomain = {
  id: 0,
  name: '',
  cnpj: '',
};

export const scheduleDetailsVehicleInitialValuesDomain: IScheduleDetailsVehicleDomain = {
  id: 0,
  type: '',
  category: '',
  brand: '',
  model: '',
  mainBoard: '',
  secondBoard: '',
  thirdBoard: '',
  capacity: 0,
  palletCapacity: 0,
  cubageCapacity: 0,
  shippingCompanyId: 0,
  allocationDate: '',
};

export const scheduleDetailsDriverInitialValuesDomain: IScheduleDetailsDriverDomain = {
  id: 0,
  name: '',
  cnh: '',
  cpf: '',
  telephone: '',
  shippingCompanyId: 0,
  allocationDate: '',
};

export const scheduleDetailsFinancialInitialValuesDomain: IScheduleDetailsFinanceDomain = {
  id: 0,
  title: '',
  owner: '',
  valueFreight: 0,
  gris: 0,
  advalorem: 0,
  totalFreight: 0,
  totalService: 0,
  helperTotal: 0,
  toll: 0,
  totalGrisAdvalorem: 0,
  icms: 0,
  discharge: 0,
  feeAnticipation: 0,
  iss: 0,
  otherTaxes: 0,
  totalFee: 0,
  surplusTime: 0,
  penaltyBonus: 0,
  margin: 0,
  financialStatus: '',
  status: 'Pagar',
};

export const scheduleDetailsMyRouterInitialValuesDomain: IScheduleDetailsMyRouteDomain = {
  idRoute: 0,
  allowedShippingId: 0,
};

export const scheduleDetailsInitialValuesDomain: IScheduleDetailsDomain = {
  id: 0,
  type: 'ftl',
  freightModality: '',
  statusCode: '',
  distance: 0,
  operation: 'SPOT',
  invoicedBy: '',
  co2: 0,
  expirationTime: '',
  vehicleId: 0,
  shippingId: 0,
  driverId: 0,
  operationType: '',
  descriptionCancel: '',
  transportRouterId: 0,
  companyIdBuyer: 0,
  operatedBy: '',
  origin: scheduleDetailsOriginAndDestinationInitialValuesDomain,
  destination: scheduleDetailsOriginAndDestinationInitialValuesDomain,
  cargo: scheduleDetailsCargoInitialValuesDomain,
  detail: scheduleDetailsComplementInitialValuesDomain,
  myRoute: scheduleDetailsMyRouterInitialValuesDomain,
  company: scheduleDetailsCompanyInitialValuesDomain,
  companyBuyer: scheduleDetailsCompanyBuyerInitialValuesDomain,
  shipping: scheduleDetailsShippingInitialValuesDomain,
  vehicle: scheduleDetailsVehicleInitialValuesDomain,
  driver: scheduleDetailsDriverInitialValuesDomain,
  freithgtPayable: {
    ...scheduleDetailsFinancialInitialValuesDomain,
    status: 'Pagar',
  },
  freithgtReceivable: {
    ...scheduleDetailsFinancialInitialValuesDomain,
    status: 'Receber',
  },
  freithgtShipping: {
    ...scheduleDetailsFinancialInitialValuesDomain,
    status: 'Receber Transportador',
  },
  schedulingCode: '',
};
