import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import * as S from './styled';

import { UseDisabledTransaction } from 'pages-v2/schedule/hooks/disabled-transaction';
import { TransactionDetails } from 'domain-v2/transactions/details';
import Button from 'components/button/button';

export interface ValuesDetailsProps {
  title: string;
  ownerTransaction: string;
  isAdmin: boolean;
  transaction: TransactionDetails;
  onRefresh: Dispatch<SetStateAction<number>>;
}

export const ValuesDisabledDetails: FC<ValuesDetailsProps> = ({ title, ownerTransaction, transaction, onRefresh }) => {
  const handleDisabledTransaction = useCallback(
    async (isDisabled: boolean) => {
      await UseDisabledTransaction(transaction.id, { isDisabled });
      onRefresh((prev) => prev + 1);
    },
    [onRefresh, transaction.id],
  );

  const renderSection = useMemo(() => {
    return (
      <S.WrapperSection>
        <S.WrapperHeader>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <h5>{title}</h5>
            <h5 style={{ color: 'black' }}>{ownerTransaction?.toUpperCase()}</h5>
          </div>

          <S.WrapperHeaderItensRight>
            <Button title="Reabilitar" size="medium" callback={() => handleDisabledTransaction(false)} bgColor="aqua" />
          </S.WrapperHeaderItensRight>
        </S.WrapperHeader>

        <S.WrapperInfo>
          <DeleteIcon htmlColor="gray" />
          <S.Info>Transação Desabilitada</S.Info>
        </S.WrapperInfo>
      </S.WrapperSection>
    );
  }, [handleDisabledTransaction, title]);

  return renderSection;
};
