import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateTransactionService } from '../../services/update-transaction/update-transaction.service';
import { IUpdateTransactionPayload } from '../../services/update-transaction/update-transaction.types';
import { convertTransactionPayloadToEntity } from './mappers/convert-update-transaction/convert-update-transaction.mapper';

export const UseUpdateTransaction = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (transactionId: number, payloadDomain: IUpdateTransactionPayload) => {
      try {
        await updateTransactionService(transactionId, convertTransactionPayloadToEntity(payloadDomain));
        showMessageFormatted({
          message: 'Dados atualizados com sucesso.',
          type: 'success',
        });

        // INVALIDATE QUERY DATA
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
