import API from 'utils/API/API';
import { IScheduleRefusedListParams } from 'src-new/pages/execution/pages/schedule-refused/types/schedule-refused-list-filters/schedule-refused-list-filters.types';

export const createScheduleRefusedExcelService = async (
  params: IScheduleRefusedListParams,
): Promise<BlobPart | undefined> => {
  const { data } = await API.get<Promise<BlobPart | undefined>>('v2/schedules-refused/export', {
    params: {
      ...params,
    },
    responseType: 'arraybuffer',
  });

  return data;
};
