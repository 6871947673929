import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { PurchaseOfferHeaderView } from './purchase-offer-header-view.component';
import { useNavigate } from 'react-router-dom';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import {
  ExportReportProps,
  FilterProps,
  IButtonProps,
  IHeaderProps,
  IInputSearchProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { convertField } from 'pages-v2/trips/mappers';
import { useFormikContext } from 'formik';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { purchaseOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-list-filters-initial-values.constant';
import { useDownloadPurchaseOfferReport } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/hooks/use-download-purchase-offer-report/use-download-purchase-offer-report.hook';
import { FilterForm } from './components/filter-form/filter-form.component';

export const PurchaseOfferHeader: FC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const { setValues, resetForm } = useFormikContext<IPurchaseOfferListFilterDomain>();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { purchaseOfferList, purchaseOfferListDownloadReport } = useContext(PurchaseOfferContext);

  const pageHeaderFilterContext = usePageHeaderFilterContext<IPurchaseOfferListFilterDomain>();
  const downloadPurchaseOfferReport = useDownloadPurchaseOfferReport();
  const navigate = useNavigate();

  const { modalProps, filters, setFilters } = pageHeaderFilterContext;
  const { isOpen, setOpenModal, setCloseModal } = modalProps;
  const { setInitialLoadingReset } = initialLoading;
  const { selectedIds } = purchaseOfferList;
  const { statusRequest } = purchaseOfferListDownloadReport;

  const isLoadingExport = useMemo((): boolean => statusRequest === 'PROCESSING', [statusRequest]);

  const handleNewPurchaseOffer = useCallback(() => {
    navigate('/ofertas-compra/novo');
  }, [navigate]);

  const handleOpenDeleteModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false);
  }, []);

  const removeSelectedOfferButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Excluir',
      isVisible: selectedIds.length > 0,
      isLoading: false,
      handleActionButton: handleOpenDeleteModal,
    }),
    [handleOpenDeleteModal, selectedIds],
  );

  const newOfferButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Nova Oferta',
      isVisible: true,
      isLoading: false,
      handleActionButton: handleNewPurchaseOffer,
    }),
    [handleNewPurchaseOffer],
  );

  const onOpenModal = useCallback(() => {
    setOpenModal();
  }, [setOpenModal]);

  const onCloseModal = useCallback(() => {
    setCloseModal();
    setValues(filters);
  }, [filters, setCloseModal, setValues]);

  const convertSaleOfferFilters = useCallback((filter: IPurchaseOfferListFilterDomain): Array<IParamsFilter> => {
    const entriesBlackList = ['search', 'page', 'limitPage', 'orderBy', 'column', 'isActive'];
    const entries = Object.entries(filter).map(([key, value]) => ({
      key,
      value: convertField(key, value),
    }));

    return entries.filter(({ key, value }) => !entriesBlackList.includes(key) && value);
  }, []);

  const getAppliedFiltersCount = useMemo((): number => {
    return convertSaleOfferFilters(filters).filter((filter) => !Array.isArray(filter.value)).length;
  }, [convertSaleOfferFilters, filters]);

  const clearFilters = useCallback(() => {
    setFilters(purchaseOfferListFilterInitialValues);
    setCloseModal();
    setInitialLoadingReset();
    resetForm();
  }, [resetForm, setCloseModal, setFilters, setInitialLoadingReset]);

  const filterProps = useMemo(
    (): FilterProps => ({
      handleOpenModal: onOpenModal,
      filterContent: <FilterForm />,
      filterCount: getAppliedFiltersCount,
      isVisibleModal: isOpen,
      onCloseButton: onCloseModal,
      onClearButton: clearFilters,
    }),
    [onOpenModal, getAppliedFiltersCount, isOpen, onCloseModal, clearFilters],
  );

  const handleExportReport = useCallback(() => {
    if (!isLoadingExport) {
      downloadPurchaseOfferReport();
    }
  }, [downloadPurchaseOfferReport, isLoadingExport]);

  const exportReportProps = useMemo(
    (): ExportReportProps => ({
      isLoading: isLoadingExport,
      isDisabledButton: true,
      onHandleExport: handleExportReport,
    }),
    [handleExportReport, isLoadingExport],
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilters({ ...filters, search, page: 1 });
    },
    [filters, setFilters],
  );

  const inputProps = useMemo(
    (): IInputSearchProps => ({
      searchPlaceHolder: 'Pesquise pelo ID, Unidade, Parceiro ou Cidade',
      handleSearchInput: handleSearch,
    }),
    [handleSearch],
  );

  const headerProps = useMemo(
    (): IHeaderProps => ({
      breadcrumbList: ['Oportunidades', 'Ofertas de Compra'],
      subtitle: 'Configure os parâmetros do frete que deseja comprar de outros embarcadores.',
      inputSearchProps: inputProps,
      primaryButtonProps: newOfferButtonProps,
      secondaryButtonProps: removeSelectedOfferButtonProps,
      filterProps,
      exportReportProps,
    }),
    [exportReportProps, filterProps, inputProps, newOfferButtonProps, removeSelectedOfferButtonProps],
  );

  return (
    <PurchaseOfferHeaderView
      headerProps={headerProps}
      deleteModalProps={{ isOpen: openDeleteModal, handleCloseModal: handleCloseDeleteModal }}
    />
  );
};
