import React, { ReactNode, useMemo } from 'react';

import {
  ContainerLoadingStyled,
  ContentTableStyled,
  DisplayNoneStyled,
  HeaderTableStyled,
  HeaderTextStyled,
  LineHeaderTableStyled,
  LineTableStyled,
  SubTitleStyled,
  TableBodyStyled,
  TableHeadStyled,
  TableStyled,
  TextStyled,
} from './styled';

import { LineConnectorHome } from 'pages/home/backhaul/components/connect-points';
import Loading from 'components-v2/common/loading/index';
import Pagination from 'components/pagination/pagination';
import EmptyStateTable from 'components/empty-state-table/empty-state-table';
import SearchEmptyListIcon from 'assets/icons/search-empty-list.icon';

interface IContent {
  value?: string | ReactNode;
  connection?: {
    first: string | ReactNode;
    second: string | ReactNode;
  };
  subtitle?: string | ReactNode;
  button?: ReactNode;
  flex?: number;
}

export type IContentTable = Array<IContent>;

interface ITableViewProps {
  header: IContentTable;
  content: Array<IContentTable>;
  isLoading: boolean;
  activePage: number;
  setPage: (page: number) => void;
  totalPages: number;
}

const BigTable: React.FC<ITableViewProps> = ({
  header,
  content,
  isLoading,
  activePage,
  setPage,
  totalPages,
}: ITableViewProps) => {
  const renderHeaderTable = useMemo(() => {
    return (
      <LineHeaderTableStyled>
        {header.map(({ button, value, flex }, index) => {
          if (!button)
            return (
              <HeaderTableStyled key={index} flex={flex}>
                <HeaderTextStyled>{value}</HeaderTextStyled>
              </HeaderTableStyled>
            );
        })}
        <div style={{ minWidth: 118 }}></div>
      </LineHeaderTableStyled>
    );
  }, [header]);

  const renderContentTable = useMemo(() => {
    return content?.map((contentLine, index) => {
      const renderedContent = contentLine.map(({ value, flex, subtitle, connection, button }, indexLine) => {
        if (!button) {
          return (
            <ContentTableStyled key={indexLine} flex={flex}>
              {React.isValidElement(value) ? (
                value
              ) : (
                <DisplayNoneStyled>
                  {!connection && <TextStyled>{value?.toString().toUpperCase()}</TextStyled>}

                  {connection && (
                    <div
                      style={{
                        display: 'flex',
                        gap: 5,
                        overflow: 'hidden',
                      }}
                    >
                      <LineConnectorHome height={52} bgColor="#D9D9D9" />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 36,
                          marginLeft: 5,
                          overflow: 'hidden',
                        }}
                      >
                        <TextStyled>{connection?.first}</TextStyled>
                        <TextStyled>{connection?.second}</TextStyled>
                      </div>
                    </div>
                  )}

                  <SubTitleStyled>{subtitle}</SubTitleStyled>
                </DisplayNoneStyled>
              )}
            </ContentTableStyled>
          );
        }
        return null;
      });

      const renderedButtons = contentLine.map(({ button }, indexLine) => <div key={indexLine}>{button}</div>);

      return (
        <React.Fragment key={index}>
          <LineTableStyled>
            {renderedContent}
            {renderedButtons}
          </LineTableStyled>
        </React.Fragment>
      );
    });
  }, [content]);

  const renderTableBody = useMemo(() => {
    if (isLoading) {
      return (
        <ContainerLoadingStyled>
          <Loading />
        </ContainerLoadingStyled>
      );
    }
    return renderContentTable;
  }, [isLoading, renderContentTable]);

  return content?.length ? (
    <>
      <TableStyled>
        <TableHeadStyled>{renderHeaderTable}</TableHeadStyled>
        <TableBodyStyled>{renderTableBody}</TableBodyStyled>
      </TableStyled>
      <Pagination activePage={activePage} totalPage={totalPages} changePage={setPage} />
    </>
  ) : (
    <EmptyStateTable
      isLoading={isLoading}
      text="Lista Vazia"
      subText="Nenhum registro localizado."
      icon={<SearchEmptyListIcon />}
    />
  );
};

export default BigTable;
