import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface TagProps {
  color: ThemeColorsV2Type;
  variant: 'ghost' | 'solid';
  size?: 'x-sm' | 'md' | 'lg';
  display?: 'flex' | 'inline-flex';
}

export const ContentTag = styled.div<TagProps>`
  ${({ theme, color, variant, size = 'md', display = 'flex' }) => css`
    height: 18px;
    min-width: 55px;
    display: ${display};
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 600;
    padding: 0 10px;
    border-radius: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: solid 1px ${theme.colorsV2[color]};

    ${variant === 'ghost'
      ? css`
          background-color: transparent;
          color: ${theme.colorsV2[color]};
        `
      : css`
          background-color: ${theme.colorsV2[color]};
          color: ${theme.colorsV2.white};
        `};

    ${size === 'lg'
      ? css`
          height: 19px;
          font-size: 10px;
        `
      : size === 'x-sm' &&
        css`
          height: 20px;
          font-size: 9px;
        `};
  `}
`;

export const Label = styled.span`
  padding: 0px 3px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
