export const extractNameFromUrl = (url: string): string => {
  const decodedUrl = decodeURIComponent(url);
    const match = decodedUrl.match(/([^\\/?#]+)\.([a-zA-Z0-9]+)(?=[?#]|$)/);
    if (match) {
      const fileNameWithExtension = match[0];
      const fileNameWithoutExtension = fileNameWithExtension.replace(/\.[a-zA-Z0-9]+$/, '');
      return fileNameWithoutExtension;
    }
    return '-';
};
