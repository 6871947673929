import React from 'react';

import { TitleStyled, TraceVerticalStyled, WrapperStyled } from './section-divider.styled';
import { PinOriginDestiny } from 'assets/icons/pin-origin-destiny';

type SectionDividerProps = {
  label: string;
  secondItem?: React.ReactElement;
  hidePin: boolean;
};
const SectionDividerView: React.FC<SectionDividerProps> = ({ label, secondItem, hidePin }) => {

  return (
    <WrapperStyled>
      <TitleStyled>
        {hidePin? <TraceVerticalStyled />: <PinOriginDestiny width={18} height={23}/>}
        {label}
      </TitleStyled>
      {secondItem}
    </WrapperStyled>
  );
};
export default SectionDividerView;
