import { logoutService } from 'services/auth/logout.service';

const useLogoutHook = () => {
  return async () => {
    try {
      await logoutService();
      sessionStorage.clear();
      // await queryClient.invalidateQueries()
    } catch (error) {
      sessionStorage.clear();
    }
  };
};

export { useLogoutHook };
