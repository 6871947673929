/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteUploadedFiles,
  listUploadedFiles,
  uploadFiles,
  deleteUploadedTemplate,
  listUploadedTemplate,
  uploadTemplate,
} from '../services/vehicle.service';

export const useHandleUploadFileFileHook = () => {
  return async (vehicleId: number | string, file: any) => {
    return await uploadFiles(file, vehicleId);
  };
};

export const useHandleGetFileUploadedListHook = () => {
  return async (vehicleId: number | string) => {
    return await listUploadedFiles(vehicleId);
  };
};

export const useHandleDeleteFileUploadedListHook = () => {
  return async (vehicleId: number | string) => {
    return await deleteUploadedFiles(vehicleId);
  };
};

export const useHandleUploadTemplateHook = () => {
  return async (file: any) => {
    return await uploadTemplate(file);
  };
};

export const useHandleGetTemplateHook = () => {
  return async () => {
    return await listUploadedTemplate();
  };
};

export const useHandleDeleteTemplateListHook = () => {
  return async (templateId: number | string) => {
    return await deleteUploadedTemplate(templateId);
  };
};
