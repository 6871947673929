import styled from 'styled-components';

export const ModalHeaderContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const IconStyled = styled.div`
  font-size: 50px;
  margin-bottom: 10px;
`;

export const TitleStyled = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

export const ModalBodyContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 10px;
`;

export const DescriptionStyled = styled.p`
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
  color: rgb(111, 111, 116);
`;

export const ModalFooterContainerStyled = styled.div`
  display: flex;
  margin-top: 9vh;
  width: 100%;
  gap: 10px;
  justify-content: center;
`;
