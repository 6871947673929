import { ITransportCompanyEntityV2 } from '../entities/transport-companies.entity';

import API from 'utils/API/API';
import { convertTransportCompanyCreatedEntityToDomainMapperV2 } from 'pages/transport-company/mappers/convert-transport-created-entity-to-domain-v2/convert-transport-created-entity-to-domain-v2';
import { convertStatusDomainToEntityMapper } from 'pages/transport-company/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { ITransportCompanyV2, ITransportCompanyStatus } from 'domain/transport-company';
import { IListPage, PageInfo } from 'domain/page-info';

export interface ITransportCompanyListProps {
  search: string;
  page: number;
  status?: ITransportCompanyStatus;
  order: string;
  column: string;
  limitPage?: number;
}

export interface ITransportCompanyListEntity {
  dataList: Array<ITransportCompanyEntityV2>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const transportCompanyListService = async ({
  page,
  search,
  status,
  order,
  column,
  limitPage,
}: ITransportCompanyListProps): Promise<IListPage<ITransportCompanyV2>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/shipping/companies?search=${search}&status=${parsedStatus}&page=${page}&limit=${
      limitPage ?? LIMIT_PAGE
    }&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search=&', '');
    }

    if (parsedStatus === undefined) {
      url = url.replace('status=undefined&', '');
    }

    const { data } = await API.get(url);

    const { items, meta } = data;
    return {
      list: items.map(convertTransportCompanyCreatedEntityToDomainMapperV2),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar transportadora');
  }
};

export const shippingCompanies = async () => {
  const url = 'v2/shipping/companies/map-listing';
  const { data } = await API.get(url);
  return data;
};
