import React, { useEffect } from 'react';

import ScheduleDestinyFormView from './schedule-destiny-form-view';

import { IFormSectionProps } from 'pages/schedule/types/form-section.types';
import { optionsLocality, optionTypes } from 'pages/schedule/constants/label-options';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';

const ScheduleDestinyForm: React.FC<IFormSectionProps> = ({
  errors,
  values,
  handleChange,
  touched,
  setFieldValue,
  apiCall,
  handleChangeValues,
}) => {
  useEffect(() => {
    setFieldValue('destiny.cnpj', '');
    setFieldValue('destiny.zipCode', '');
    setFieldValue('destiny.city', '');
    setFieldValue('destiny.state', '');
    setFieldValue('destiny.complement', '');
    setFieldValue('destiny.neighborhood', '');
    setFieldValue('destiny.number', '');
    setFieldValue('destiny.street', '');
  }, [values.destiny.clientType]);

  useEffect(() => {
    const originType = values.origin.clientType;
    const destinyType = values.destiny.clientType;

    if (originType === 'plant' && destinyType === 'client') setFieldValue('cargoDetails.operationType', 'OUTBOUND');
    if ((originType === 'client' && destinyType === 'client') || (originType === 'plant' && destinyType === 'plant'))
      setFieldValue('cargoDetails.operationType', 'TRANSFERENCIA');
    if (originType === 'client' && destinyType === 'plant') setFieldValue('cargoDetails.operationType', 'INBOUND');
  }, [values.origin.clientType, values.destiny.clientType]);

  const handleCompleteInputs = (clientId: number, localityId: number, clientType: string) => {
    setFieldValue('destiny.lat', undefined);
    setFieldValue('destiny.lng', undefined);
    if (clientType === 'client' && clientId) {
      clientDetailsService(String(clientId)).then((client) => {
        setFieldValue('destiny.cnpj', client.cnpj);
        setFieldValue('destiny.zipCode', client.address.cep);
        setFieldValue('destiny.city', client.address.city);
        setFieldValue('destiny.state', client.address.uf);
        setFieldValue('destiny.complement', client.address.complement);
        setFieldValue('destiny.neighborhood', client.address.neighborhood);
        setFieldValue('destiny.number', client.address.numberHouse);
        setFieldValue('destiny.street', client.address.street);
        setFieldValue('destiny.lat', client.lat);
        setFieldValue('destiny.lng', client.lng);
      });
    } else if (clientType === 'plant' && localityId) {
      locationDetailsService(String(localityId)).then((locality) => {
        setFieldValue('destiny.cnpj', locality.cnpj);
        setFieldValue('destiny.zipCode', locality.address.cep);
        setFieldValue('destiny.city', locality.address.city);
        setFieldValue('destiny.state', locality.address.uf);
        setFieldValue('destiny.complement', locality.address.complement);
        setFieldValue('destiny.neighborhood', locality.address.neighborhood);
        setFieldValue('destiny.number', locality.address.numberHouse);
        setFieldValue('destiny.street', locality.address.street);
        setFieldValue('destiny.lat', locality.lat);
        setFieldValue('destiny.lng', locality.lng);
      });
    }
  };

  return (
    <ScheduleDestinyFormView
      {...{
        errors,
        values,
        handleChange,
        touched,
        optionTypes,
        optionsLocality,
        setFieldValue,
        apiCall,
        handleChangeValues,
        handleCompleteInputs,
      }}
    />
  );
};

export default ScheduleDestinyForm;
