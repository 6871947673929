/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteUploadedFiles,
  listUploadedFiles,
  uploadFiles,
  deleteUploadedTemplate,
  listUploadedTemplate,
  uploadTemplate,
} from '../services/client.service';

export const useHandleUploadFileFileHook = () => {
  return async (clientId: number | string, file: any) => {
    return await uploadFiles(file, clientId);
  };
};

export const useHandleGetFileUploadedListHook = () => {
  return async (clientId: number | string) => {
    return await listUploadedFiles(clientId);
  };
};

export const useHandleDeleteFileUploadedListHook = () => {
  return async (id: number | string) => {
    return await deleteUploadedFiles(id);
  };
};

export const useHandleUploadTemplateHook = () => {
  return async (file: any) => {
    return await uploadTemplate(file);
  };
};

export const useHandleGetTemplateHook = () => {
  return async () => {
    return await listUploadedTemplate();
  };
};

export const useHandleDeleteTemplateListHook = () => {
  return async (templateId: number | string) => {
    return await deleteUploadedTemplate(templateId);
  };
};
