import styled from 'styled-components';

export const AllocationModalVehicleWrapper = styled.div`
  height: 550px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const AllocationModalVehicleTitle = styled.p`
  font-size: 0.85rem;
  font-weight: 600;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
