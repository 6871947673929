import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { ICompanyInformationContext } from './company-information.types';
import { companyReducer } from './company-information.reducer';
import { ICompanyAction, initialState } from './company-information.actions';

import { ICompany } from 'domain/company';

interface ICompanyInformationContextProps {
  children: ReactNode;
}

export const CompanyInformationContext = createContext<ICompanyInformationContext>(initialState);

const CompanyInformationContextProvider = ({ children }: ICompanyInformationContextProps) => {
  const [state, dispatch] = useReducer(companyReducer, initialState);

  const setCompanyRequest = useCallback(() => {
    dispatch({ type: ICompanyAction.COMPANY_REQUEST, payload: null });
  }, []);

  const setCompanySuccess = useCallback((company: ICompany) => {
    dispatch({
      type: ICompanyAction.COMPANY_SUCCESS,
      payload: company,
    });
  }, []);

  const setCompanyError = useCallback((erro: string) => {
    dispatch({ type: ICompanyAction.COMPANY_ERROR, payload: erro });
  }, []);

  const value = useMemo(() => {
    return {
      companyIsLoading: state.companyIsLoading,
      company: state.company,
      companyError: state.companyError,
      setCompanyRequest,
      setCompanySuccess,
      setCompanyError,
    };
  }, [
    setCompanyError,
    setCompanyRequest,
    setCompanySuccess,
    state.company,
    state.companyError,
    state.companyIsLoading,
  ]);

  return <CompanyInformationContext.Provider value={value}>{children}</CompanyInformationContext.Provider>;
};

export default React.memo(CompanyInformationContextProvider);
