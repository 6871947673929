import { IPurchaseOfferListDomain } from '../domains/purchase-offer-list.domain';

export const proposalOfferMatchProposalInitialValues: IPurchaseOfferListDomain = {
  id: 0,
  companyName: '',
  operationType: 'IN',
  cargoType: 'FTL',
  companyName: '',
  company: {
    id: 0,
    name: '',
    image: '',
  },
  origin: {
    id: 0,
    type: '',
    name: '',
    address: {
      cep: '',
      street: '',
      city: '',
      neighborhood: '',
      numberHouse: '',
      complement: '',
      uf: '',
    },
  },
  destination: {
    id: 0,
    type: '',
    name: '',
    address: {
      cep: '',
      street: '',
      city: '',
      neighborhood: '',
      numberHouse: '',
      complement: '',
      uf: '',
    },
  },
  vehicle: {
    type: '',
    category: '',
  },
  initialDate: '',
  capacity: {
    weight: 0,
    pallet: 0,
  },
  values: {
    freight: 0,
    logshare: 0,
  },
  frequency: 0,
  totalMatchOffers: 0,
  isActive: 'ativa',
};
