export enum HomeCardsActionType {
  HOME_CARDS_REQUEST = 'HOME_CARDS_REQUEST',
  HOME_CARDS_SUCCESS = 'HOME_CARDS_SUCCESS',
  HOME_CARDS_ERROR = 'HOME_CARDS_ERROR',
  HOME_CARDS_RESET = 'HOME_CARDS_RESET',
}

export enum HomeMatchesActionType {
  HOME_MATCHES_REQUEST = 'HOME_MATCHES_REQUEST',
  HOME_MATCHES_SUCCESS = 'HOME_MATCHES_SUCCESS',
  HOME_MATCHES_ERROR = 'HOME_MATCHES_ERROR',
  HOME_MATCHES_RESET = 'HOME_MATCHES_RESET',
  HOME_MATCHES_NEXT_PAGE = 'HOME_MATCHES_NEXT_PAGE',
  HOME_MATCHES_PAGE_LIMIT = 'HOME_MATCHES_PAGE_LIMIT',
}

export enum HomeBidsActionType {
  HOME_BIDS_REQUEST = 'HOME_BIDS_REQUEST',
  HOME_BIDS_SUCCESS = 'HOME_BIDS_SUCCESS',
  HOME_BIDS_ERROR = 'HOME_BIDS_ERROR',
  HOME_BIDS_NEXT_PAGE = 'HOME_BIDS_NEXT_PAGE',
  HOME_BIDS_PAGE_LIMIT = 'HOME_BIDS_PAGE_LIMIT',
  HOME_BIDS_RESET = 'HOME_BIDS_RESET',
}

export enum HomeModalsActionType {
  HOME_MODALS_OPEN = 'HOME_MODALS_OPEN',
  HOME_MODALS_CLOSE = 'HOME_MODALS_CLOSE',
}

export enum HomeActionType {
  HOME_RESET = 'HOME_RESET',
}
