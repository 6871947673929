import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

export const Wrapper = styled.div`
  height: 400px;
  width: 100%;
  display: inline-grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 15px;
`;

export const InputContent = styled.div`
  padding-top: 15px;
  padding-bottom: 45px;
`;

export const TableContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const FilesStatusContent = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  font-size: 10px;
`;

interface IFilesStatusTextProps {
  color?: ThemeColorsV2Type;
}
export const FilesStatusText = styled.p<IFilesStatusTextProps>`
  ${({ theme, color }) => css`
    color: ${theme.colorsV2[color ?? 'black']};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  button {
    width: 137px;
  }
`;
