import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { ChatConversationListActionType } from 'src-new/contexts/chat/chat.action';
import { initialError } from 'src-new/contexts/chat/chat.constants';

export const chatConversationListReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  switch (action.type) {
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_REQUEST:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          statusRequest: 'PROCESSING',
          error: initialError,
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_SUCCESS:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          statusRequest: 'SUCCESS',
          conversations: action.payload,
          error: initialError,
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_ERROR:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          statusRequest: 'ERROR',
          conversations: [],
          error: action.payload,
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_MODAL_OPEN:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          modalActions: {
            ...state.chatConversationList.modalActions,
            isOpenModal: action.payload,
          },
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_NEXT_PAGE:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          paginationActions: {
            ...state.chatConversationList.paginationActions,
            nextPage: action.payload,
          },
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_PAGE_LIMIT:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          paginationActions: {
            ...state.chatConversationList.paginationActions,
            isPageLimit: true,
          },
        },
      };
    case ChatConversationListActionType.CHAT_CONVERSATION_LIST_RESET:
      return {
        ...state,
        chatConversationList: {
          ...state.chatConversationList,
          statusRequest: 'INITIAL',
          conversations: [],
          error: initialError,
          paginationActions: {
            ...state.chatConversationList.paginationActions,
            nextPage: undefined,
            isPageLimit: false,
          },
          modalActions: {
            ...state.chatConversationList.modalActions,
            isOpenModal: false,
          },
        },
      };
    default:
      return state;
  }
};
