import { IScheduleImportModalDomain } from '../../../domains/schedule-import-modal/schedule-import-modal.domain';
import { IRowsValidations } from '../send-selected-file.types';

export const convertScheduleImportModalResponseToDomain = (
  importResponse: IRowsValidations,
): IScheduleImportModalDomain => {
  return {
    id: importResponse.row,
    originName: importResponse.originCity,
    destinyName: importResponse.destinationCity,
    vehicleType: importResponse.vehicleType,
    shippingCompanyName: importResponse.shippingCompanyName,
    levyDate: importResponse.loadingStartDate,
    deliveryDate: importResponse.unloadingStartDate,
    status: importResponse.hasError ? 'refused' : 'approved',
    processingCode: importResponse.processingCode,
  };
};
