import React, { ReactElement, useMemo } from 'react';
import * as S from './weighing-totten-details-form.styled';
import { WeighingTottenForm } from './components/weighing-totten-details-form.component';
import { Formik } from 'formik';
import { weighingTottenDetailsFormInitialValues } from '../../types/weighing-totten-details-form/weighing-totten-details-form-initial-values.constants';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import { WeighingTottenDetailsConfirmation } from '../weighing-totten-modals/weighing-totten-details-confirmation/weighing-totten-details-confirmation.component';
import FooterTotten from 'src/assets/footer-totten.png'
import LoadingV4 from 'src/assets/loading-v4-unscreen.gif'

interface IWeighingTottenDetailsFormViewProps {
  confirmationPage: boolean;
  isLoading: boolean;
  handleSubmit: (values: IWeighingTottenDetailsForm) => void;
}

export const WeighingTottenDetailsFormView: React.FC<IWeighingTottenDetailsFormViewProps> = ({
  confirmationPage,
  isLoading,
  handleSubmit,
}) => {
  const renderForm = useMemo((): ReactElement => {
    if (isLoading) {
      return (
        <S.Wrapper>
          <S.WrapperSearching>
            <S.Title>
              Buscando <strong>Agendamento</strong>
            </S.Title>
            <S.Loading src={LoadingV4} alt="" />
          </S.WrapperSearching>

          <S.Footer>
            <img src={FooterTotten} alt="" />
          </S.Footer>
        </S.Wrapper>
      );
    }

    if (confirmationPage) {
      return <WeighingTottenDetailsConfirmation />;
    }

    return (
      <S.Wrapper>
        <S.Title>
          Informe a <strong>Ordem de Coleta</strong>
        </S.Title>

        <S.Form>
          <Formik initialValues={weighingTottenDetailsFormInitialValues} onSubmit={handleSubmit}>
            <WeighingTottenForm />
          </Formik>
        </S.Form>

        <S.Footer>
          <img src={FooterTotten} alt="" />
        </S.Footer>
      </S.Wrapper>
    );
  }, [confirmationPage, handleSubmit, isLoading]);

  return renderForm;
};
