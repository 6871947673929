import dayjs from 'dayjs';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IValidateDates } from './validate-dates.types';

const isAfterDate = (dateA: string, dateB: string) => dayjs(dateA).isAfter(dateB);

export const handleValidateDates = (dates: Array<IValidateDates>) => {
  const validationDates = dates.map((date) => {
    const { initialLevyDate, initialDeliveryDate, finalLevyDate, finalDeliveryDate } = date;

    if (!initialLevyDate || !initialDeliveryDate || !finalLevyDate || !finalDeliveryDate) {
      return {
        initialLevyDate: !initialLevyDate ? 'Campo Obrigatório' : '',
        initialDeliveryDate: !initialDeliveryDate ? 'Campo Obrigatório' : '',
        finalLevyDate: !finalLevyDate ? 'Campo Obrigatório' : '',
        finalDeliveryDate: !finalDeliveryDate ? 'Campo Obrigatório' : '',
      };
    }

    if (
      isAfterDate(initialLevyDate, finalLevyDate) ||
      isAfterDate(finalLevyDate, initialDeliveryDate) ||
      isAfterDate(initialDeliveryDate, finalDeliveryDate)
    ) {
      return {
        initialLevyDate: '',
        finalLevyDate: isAfterDate(initialLevyDate, finalLevyDate) ? 'Campo Inválido' : '',
        initialDeliveryDate: isAfterDate(finalLevyDate, initialDeliveryDate) ? 'Campo Inválido' : '',
        finalDeliveryDate: isAfterDate(initialDeliveryDate, finalDeliveryDate) ? 'Campo Inválido' : '',
      };
    }

    return {
      initialLevyDate: '',
      finalLevyDate: '',
      initialDeliveryDate: '',
      finalDeliveryDate: '',
    };
  });

  const hasDatesError = validationDates.map(
    (date) =>
      (date && Object.keys(date).length !== 0 && date.initialLevyDate !== '') ||
      date.finalLevyDate !== '' ||
      date.initialDeliveryDate !== '' ||
      date.finalDeliveryDate !== '',
  );

  if (hasDatesError.includes(true)) {
    showMessageFormatted({
      message: 'Complete os Dados Obrigatórios para Agendamento.',
      type: 'error',
    });

    return { validationDates };
  } else {
    return;
  }
};
