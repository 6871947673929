import styled, { css } from 'styled-components';

interface DetailsAccordionProps {
  isOpen: boolean;
}

export const WrapperAccordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const SummaryAccordion = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 8px 10px;
    height: 32px;
    background-color: ${theme.colorsV2.blue};
    margin-bottom: 5px;
  `}
`;

export const SummaryTitleAccordion = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 600;
    color: ${theme.colorsV2.white};
  `}
`;

export const DetailsAccordion = styled.div<DetailsAccordionProps>`
  ${({ isOpen }) => css`
    display: ${isOpen ? 'flex' : 'none'};
  `}
`;

export const RightText = styled.span`
  font-size: 12px;
  color: rgb(254 188 46);
  font-weight: bold;
  font-style: italic;
`;
