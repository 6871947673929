import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';

interface IOption {
  value: RiskManagementType;
  label: string;
}

enum RiskManagementType {
  APROVADO = 'Aprovado',
  REPROVADO = 'Reprovado',
}

export const riskManagementTypeOptions: IOption[] = [
  { value: RiskManagementType.APROVADO, label: 'Aprovado' },
  { value: RiskManagementType.REPROVADO, label: 'Reprovado' },
];

export const riskManagementInitialValues: IRiskManagementDomain = {
  driverId: 0,
  gr: '',
  registerDate: '',
  validateDate: '',
  consultedBy: '',
  consultationRecord: '',
  status: 'Vencido',
};
