/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InfoStyled, Wrapper } from './origin-and-destiny.styled';

import TruckEmptyListIcon from 'assets/icons/truck-empty-list.icon';
import { CollectIcon } from 'assets/icons/collect.icon';

interface OriginAndDestiny {
  collectionStreet: any;
  collectionNumberHouse: any;
  collectionNeighborhood: any;
  collectionCity: any;
  collectionState: any;
  collectionCep: any;

  deliveryAddressStreet: any;
  deliveryAddressNumberHouse: any;
  deliveryAddressNeighborhood: any;
  deliveryAddressCity: any;
  deliveryAddressState: any;
  deliveryAddressCep: any;
}

export const OriginAndDestiny: React.FC<OriginAndDestiny> = ({
  collectionStreet,
  collectionNumberHouse,
  collectionNeighborhood,
  collectionCity,
  collectionState,
  collectionCep,

  deliveryAddressStreet,
  deliveryAddressNumberHouse,
  deliveryAddressNeighborhood,
  deliveryAddressCity,
  deliveryAddressState,
  deliveryAddressCep,
}) => {
  return (
    <Wrapper border>
      <Wrapper display="flex" justify="space-between">
        <Wrapper display="flex" direction="column" gap={5}>
          <InfoStyled textAlign="left">
            <CollectIcon />
          </InfoStyled>

          <InfoStyled textAlign="left" fontSize={14} fontWeight={700}>
            Endereço de Coleta
          </InfoStyled>

          <InfoStyled display="flex" direction="column" textAlign="left" fontSize={13} fontWeight={500}>
            <div>
              {collectionStreet}, {collectionNumberHouse}
            </div>
            <div>
              {collectionNeighborhood} - {collectionCity} -{collectionState} - CEP {collectionCep}
            </div>
          </InfoStyled>
        </Wrapper>

        <Wrapper display="flex" direction="column" gap={5}>
          <InfoStyled textAlign="right">
            <TruckEmptyListIcon />
          </InfoStyled>

          <InfoStyled textAlign="right" fontSize={14} fontWeight={700}>
            Endereço de Entrega
          </InfoStyled>

          <InfoStyled display="flex" direction="column" textAlign="right" fontSize={13} fontWeight={500}>
            <div>
              {deliveryAddressStreet}, {deliveryAddressNumberHouse}
            </div>
            <div>
              {deliveryAddressNeighborhood} - {deliveryAddressCity} -{deliveryAddressState} - CEP {deliveryAddressCep}
            </div>
          </InfoStyled>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
