import React, { ReactNode } from 'react';

import ContentBoxViewTime from './content-box-time-view';

interface IContentBoxProps {
  title: string;
  subtitle?: string;
  hours: number;
  minutes: number;
  percentValue?: string;
  prefix?: 'R$ ' | 'US$';
  sufix?: ' VOL' | ' KG' | ' TON';
  color: 'white' | 'lightgray';
  decimals: 2 | 0;
  isLarge?: boolean;
  isSmall?: boolean;
  icon?: ReactNode;
}

const ContentBoxTime: React.FC<IContentBoxProps> = ({
  title,
  subtitle,
  hours,
  minutes,
  percentValue,
  prefix,
  sufix,
  color,
  decimals,
  isLarge,
  isSmall,
  icon,
}) => {
  return (
    <ContentBoxViewTime
      color={color}
      title={title}
      subtitle={subtitle}
      hours={hours}
      minutes={minutes}
      percentValue={percentValue}
      prefix={prefix}
      sufix={sufix}
      decimals={decimals}
      isLarge={isLarge}
      isSmall={isSmall}
      icon={icon}
    />
  );
};

export default ContentBoxTime;
