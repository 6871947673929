import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import {
  IPurchaseOfferListResponse,
} from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-list/get-purchase-offer-list.types';
import {
  IPurchaseOfferListDomain,
} from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import dayjs from 'dayjs';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';

export const convertPurchaseOfferListResponseToDomain = (
  purchaseOfferResponse: IPurchaseOfferListResponse,
): IPurchaseOfferListDomain => {
  return {
    ...purchaseOfferResponse,
    companyName: purchaseOfferResponse.company.name,
    initialDate: dayjs(purchaseOfferResponse.initialDate).format('DD/MM/YYYY'),
    vehicle: {
      type: convertVehicleType(purchaseOfferResponse.vehicle.type),
      category: convertVehicleCategory(purchaseOfferResponse.vehicle.category),
    },
    capacity: {
      weight: purchaseOfferResponse.capacity.weight ?? 0,
      pallet: purchaseOfferResponse.capacity.pallet ?? 0,
    },
    frequency: purchaseOfferResponse.frequency ?? 0,
    operationType: convertOperationTypeToDomain(purchaseOfferResponse.operationType),
    cargoType: 'FTL',
    totalMatchOffers: Number(purchaseOfferResponse.totalMatchOffers),
    isActive: purchaseOfferResponse.isActive ? 'ativa' : 'inativa',
  };
};
