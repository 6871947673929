import styled, { css } from 'styled-components';

export const WrapperBoxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const WrapperInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  white-space: nowrap;
  overflow: hidden;
`;

export const InformationTitle = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
`;

export const InformationBody = styled.span`
  font-size: 0.75rem;
  text-transform: uppercase;

  text-overflow: ellipsis;
  overflow: hidden;
`;

interface IWrapperButtons {
  width: string
}
export const WrapperButtons = styled.div<IWrapperButtons>`
${({width}) => css`
  display: flex;
  justify-content: space-between;
  justify-self: right;
  align-items: center;
  width: ${width};
  cursor: pointer;
`}
`

export const TooltipLabel = styled.div`
  font-size: 11px;
  z-index: 10;
`;

interface IContainerButtonsProps {
  isDisabled?: boolean;
}
export const ContainerButtons = styled.div<IContainerButtonsProps>`
  ${({ isDisabled }) => css`
    background-color: ${!isDisabled && 'white'};
    border-radius: 5px;
  `}
`;
