import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Title = styled.h4`
  font-weight: 400;
  color: #7f7f7f;
  font-size: 0.875rem;
`;

export const WrapperInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  white-space: nowrap;
  overflow: hidden;
`;

export const InformationTitle = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
`;

export const InformationBody = styled.p`
  font-size: 0.75rem;
  text-transform: uppercase;

  text-overflow: ellipsis;
  overflow: hidden;
`;
