import { useCallback, useContext } from 'react';

import { Hook } from './use-accepted-matches-list.types';

import { acceptedMatchesListService } from 'pages/accepted-matches/service/accepted-matches-list/accepted-matches-list.service';
import { AcceptedMatchesContext } from 'pages/accepted-matches/context/table-states/accepted-matches.context';

const useAcceptMatchesList: Hook = () => {
  const { setAcceptedMatchesRequest, setAcceptedMatchesSuccess, setAcceptedMatchesError } =
    useContext(AcceptedMatchesContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setAcceptedMatchesRequest();

      try {
        const response = await acceptedMatchesListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setAcceptedMatchesSuccess(response);
      } catch (error) {
        setAcceptedMatchesError('Ocorreu um erro ao buscar suas rotas, tente novamente!');
      }
    },
    [setAcceptedMatchesError, setAcceptedMatchesRequest, setAcceptedMatchesSuccess],
  );
};

export { useAcceptMatchesList };
