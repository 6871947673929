import { FC, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { convertStatusCodeColor } from 'utils-v2/converters/status-code';
import { convertStatusCode } from 'utils/status-code';
import { ScheduleDetailsResumeStepByStepView } from './schedule-details-resume-step-step-view.component';

export const ScheduleDetailsResumeStepByStep: FC = () => {
  const { user } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const statusCode = useMemo(() => {
    const labelStatus = convertStatusCode(scheduleDetails.scheduleDetails.statusCode, user);
    const colorStatus = convertStatusCodeColor(labelStatus.toLowerCase());

    return { labelStatus, colorStatus };
  }, [scheduleDetails.scheduleDetails.statusCode, user]);

  return (
    <ScheduleDetailsResumeStepByStepView
      statusCode={statusCode}
      co2={scheduleDetails.scheduleDetails.co2}
      haulType={scheduleDetails.scheduleDetails.operation}
      scheduleType={scheduleDetails.scheduleDetails.type}
      operationType={scheduleDetails.scheduleDetails.operationType}
      transportRouterId={scheduleDetails.scheduleDetails.transportRouterId}
      invoicedBy={scheduleDetails.scheduleDetails.invoicedBy}
    />
  );
};
