import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQuery } from 'react-query';
import { IChecklistDocuments } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getChecklistDocumentsDataService } from '../../services/get-checklist-documents-data/get-checklist-documents-data.service';

export const useGetChecklistDocumentsData = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);

  const { data } = useQuery<Array<IChecklistDocuments>>({
    queryKey: ['get-checklist-documents', scheduleDetails.scheduleDetails.id],
    queryFn: () => {
      scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsRequest();
      return getChecklistDocumentsDataService(scheduleDetails.scheduleDetails.id, 'schedule');
    },
    onSuccess: (data) => {
      scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsSuccess(data);
    },
    onError: (error) => {
      scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 600 * 1000,
  });

  return useCallback(() => {
    if (data) {
      scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsSuccess(data);
    }
  }, [data, scheduleDocuments.checklistDocumentsProps]);
};
