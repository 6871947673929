import { IRework, ITransportedCargo, IVehicleActuators, IYesNo, ReworkTime } from 'domain/rework';

export const PATH_TO_REWORK_LIST = '/retrabalho';

export const optionsTime: ReworkTime[] = [
  { label: '5 HORAS', value: '5 horas' },
  { label: '6 HORAS', value: '6 horas' },
  { label: '7 HORAS', value: '7 horas' },
  { label: '8 HORAS', value: '8 horas' },
  { label: '9 HORAS', value: '9 horas' },
  { label: '10 HORAS', value: '10 horas' },
  { label: '11 HORAS', value: '11 horas' },
  { label: '12 HORAS', value: '12 horas' },
  { label: '24 HORAS', value: '24 horas' },
  { label: '48 HORAS', value: '48 horas' },
];

export const destinationInitialValue = {
  id: 0,
  type: 'PARCEIRO',
  name: '',
  cnpj: '',
  status: '',
  cep: '',
  city: '',
  uf: '',
  neighborhood: '',
  street: '',
  numberHouse: '',
  complement: '',
  lat: '',
  lng: '',
};

export const initialValues: IRework = {
  destinations: [destinationInitialValue],
  freightValue: 0,
  originType: '',
  destinationType: [''],
  idOrigin: undefined,
  originName: undefined,
  companyId: 0,
  status: 'AG RETRABALHO',
  volume: 0,
  merchandiseValue: 0,
  shippingCompanyName: '',
  observation: '',
  order: '',
  invoiceService: '',
  serviceType: '',
  conclusionDate: '',
  conclusionHour: '',
  cubagem: 0,
  collectHour: '',
  collectDate: '',
  internalNumber: undefined,
  ocInternalNumber: undefined,
  boardingNumber: undefined,
  schedulingPassword: undefined,
  invoice: undefined,
  weight: 0,
  vehiclePlate: undefined,
  pallet: 0,
  shippingValue: undefined,
  scheduleId: undefined,
  servicePlate: '',
  trNumber: undefined,
  pullDate: '',
  pullHour: '',
  endReworkDate: '',
  endReworkHour: '',
  startReworkDate: '',
  startReworkHour: '',
  receiptDate: '',
  receiptHour: '',
  schedulingDate: '',
  schedulingHour: '',
  endDate: '',
  endHour: '',
  serviceValue: '',
  shippingCompanyId: 0,
};

export const optionsVehicleActuators: Array<{
  label: string;
  value: IVehicleActuators;
}> = [
  { label: 'TECLADO INTERNO', value: 'Teclado Interno' },
  { label: 'BLOQUEADOR DE COMBUSTÍVEL', value: 'Bloqueador de Combustível' },
  { label: 'TRAVA DE 5º', value: 'Trava de 5º' },
  { label: 'TRAVA DE BAÚ', value: 'Trava de Baú' },
  { label: 'SENSOR DE PORTA DA CABINA', value: 'Sensor de Porta da Cabina' },
  { label: 'SENSOR DE BAÚ', value: 'Sensor de Baú' },
  { label: 'SENSOR DE DESENGATE', value: 'Sensor de Desengate' },
  { label: 'SENSOR DE VELOCIDADE', value: 'Sensor de Velocidade' },
  { label: 'SENSOR DE GRADE DE JANELA', value: 'Sensor de Grade de Janela' },
  {
    label: 'SENSOR DE VIOLAÇÃO DE PAINEL',
    value: 'Sensor de Violação de Painel',
  },
  { label: 'SENSOR DE TEMPERATURA', value: 'Sensor de Temperatura' },
];

export const optionsTransportedCargo: Array<{
  label: string;
  value: ITransportedCargo;
}> = [
  { label: 'ÁGUA', value: 'Água' },
  { label: 'ALIMENTOS', value: 'Alimentos' },
  { label: 'CELULOSE', value: 'Celulose' },
  { label: 'CIGARROS', value: 'Cigarros' },
  { label: 'COMBUSTÍVEIS', value: 'Combustíveis' },
  { label: 'COSMÉTICOS', value: 'Cosméticos' },
  { label: 'EMBALAGENS', value: 'Embalagens' },
  { label: 'ELETRÔNICOS', value: 'Eletrônicos' },
  { label: 'FLVs', value: 'FLVs' },
  { label: 'GRÃOS AGRÍCOLAS', value: 'Grãos Agrícolas' },
  { label: 'GRÃOS MINERAIS', value: 'Grãos Minerais' },
  { label: 'MADEIRA', value: 'Madeira' },
  { label: 'ÓLEO VEGETAL', value: 'Óleo Vegetal' },
  { label: 'PAPEL', value: 'Papel' },
  { label: 'QUÍMICOS', value: 'Químicos' },
  { label: 'ROUPAS', value: 'Roupas' },
  { label: 'SUCO', value: 'Suco' },
  { label: 'VASILHAMES', value: 'Vasilhames' },
  { label: 'OUTROS', value: 'Outros' },
];

export const optionsYesOrNo: Array<{
  label: string;
  value: IYesNo;
}> = [
  { label: 'SIM', value: 'Sim' },
  { label: 'NÃO', value: 'Não' },
];

export const optionsServiceType: Array<{
  label: string;
  value: string;
}> = [
  { label: 'DEVOLUCAO DE PALETE', value: 'Devolução de Palete' },
  { label: 'DEVOLUCAO DE MERCADORIA', value: 'Devolução de Mercadoria' },
  { label: 'TRANSFERENCIA', value: 'Transferência' },
  { label: 'REPALETIZACAO', value: 'Repaletização' },
  { label: 'PALETIZACAO', value: 'Paletização' },
  { label: 'CROSSDOCKING', value: 'Crossdocking' },
  { label: 'DESLOCAMENTO', value: 'Deslocamento' },
  { label: 'COLETA', value: 'Coleta' },
  { label: 'REPALETIZACAO SPOT', value: 'Repaletização SPOT' },
  { label: 'IMPORTADOS', value: 'Importados' },
  { label: 'FILME STRECHING', value: 'Filme Streching' },
  { label: 'TROCA DE PALETE QUEBRADO', value: 'Troca de Palete Quebrado' },
];
