import {useCallback} from 'react';
import {DownloadFile} from 'utils/download-file/download-file';
import {IScheduleListParams} from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import {showMessageFormatted} from 'utils/message/show-message-formatted/show-message-formatted';
import {createScheduleExcelService} from '../../services/create-schedule-excel/create-schedule-excel.service';

export const useCreateScheduleExcel = () => {
  return useCallback(async (params: IScheduleListParams) => {
    try {
      const file = await createScheduleExcelService(params);

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'AGENDAMENTO_FTL');
      }
    } catch (error: any) {
      showMessageFormatted({
        message: 'Erro ao tentar baixar o relatório.',
        type: 'error',
      });
    }
  }, []);
};
