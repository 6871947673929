import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGetSaleOfferList } from '../../hooks/use-sale-offer-list/use-sale-offer-list.hook';
import { SaleOfferTableTabsView } from './sale-offer-table-tabs-view.component';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const SaleOfferTableTabs: React.FC = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { filters, setFilters } = pageHeaderFilterContext;
  const { setInitialLoadingReset } = initialLoading;
  const [activeTab, setActiveTab] = useState<string>('Ativas');
  const getSaleOfferListHook = useGetSaleOfferList();

  const handleSaleOfferTabs = useCallback(
    (name: string) => {
      setInitialLoadingReset();
      setActiveTab(name);
      setFilters({ ...filters, isActive: name === 'Ativas', page: 1 });
    },
    [filters, setInitialLoadingReset, setFilters],
  );

  useEffect(() => {
    getSaleOfferListHook();
  }, [getSaleOfferListHook]);

  return <SaleOfferTableTabsView activeTab={activeTab} handleChangeTab={handleSaleOfferTabs} />;
};
