import styled from 'styled-components';

export const WrapperModalContainerStyled = styled.div`
  z-index: auto;
  display: ${({ show }: { show: boolean }) => (show ? 'flex' : 'none')};
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`;

export const ModalContainerStyled = styled.div`
  position: fixed;
  background: white;
  width: 70%;
  height: 80%;
  margin: auto;
  border-radius: 8px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: 8888889c;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #12264e;
    width: 50px;
  }
`;

export const ModalHeaderStyled = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  background: #12264e;
  border-radius: 5px 0px 0px 0px;
  padding: 25px 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
`;

export const ModalHeaderCloseButtonStyled = styled.div`
  cursor: pointer;
`;
export const ModalHeaderTitleStyled = styled.h3`
  font-size: 17px;
`;

export const ModalHeaderDescriptionStyled = styled.p`
  font-size: 15px;
`;

export const ModalHeaderWrapperStyled = styled.div``;

export const ModalBodyStyled = styled.div`
  padding: 10px;
  margin-bottom: 30px;
`;
export const ModalFooterStyled = styled.div``;
