import React from 'react';

import * as S from './styled';

import TruckLoadingIcon from 'assets/loading-v4.gif';

const Loading: React.FC = () => {
  return (
    <S.LoadingStyled>
      <S.LoadingContainer>
        <img style={{ width: 70, height: 70 }} src={TruckLoadingIcon} alt={'spinner'} />
      </S.LoadingContainer>
    </S.LoadingStyled>
  );
};

export default React.memo(Loading);
