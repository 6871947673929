import { userUpdateService } from 'pages/user/services/user-update-service/user-update.service';
import { ICreatedUser } from 'domain/user';

const useUpdateUserHook = () => {
  return async (user: ICreatedUser) => {
    await userUpdateService(user);
  };
};

export { useUpdateUserHook };
