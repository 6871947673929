import { ScheduleImportModalActionType } from '../../schedule.action';
import { IScheduleImportModalActions } from '../../types/schedule-import-modal.types';
import { IScheduleContext } from '../../types/schedule.types';

export const scheduleImportModalReducer = (
  state: IScheduleContext,
  action: IScheduleImportModalActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_REQUEST:
      return {
        ...state,
        scheduleImportModal: {
          ...state.scheduleImportModal,
          statusRequest: 'PROCESSING',
          scheduleImportModal: [],
        },
      };
    case ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_SUCCESS:
      return {
        ...state,
        scheduleImportModal: {
          ...state.scheduleImportModal,
          statusRequest: 'SUCCESS',
          scheduleImportModal: action.payload,
        },
      };
    case ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_ERROR:
      return {
        ...state,
        scheduleImportModal: {
          ...state.scheduleImportModal,
          statusRequest: 'ERROR',
          scheduleImportModal: [],
        },
      };
    case ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_RESET:
      return {
        ...state,
        scheduleImportModal: {
          ...state.scheduleImportModal,
          statusRequest: 'INITIAL',
          scheduleImportModal: [],
        },
      };
    default:
      return state;
  }
};
