import { useCallback } from 'react';

import { trackingOccurrenceDeleteService } from 'pages/tracking-occurrence/services/tracking-occurrence-delete/tracking-occurrence-delete.service';

const useDeleteTrackingOccurrenceHook = () => {
  return useCallback(async (id: number): Promise<boolean> => {
    return await trackingOccurrenceDeleteService(id);
  }, []);
};

export { useDeleteTrackingOccurrenceHook };
