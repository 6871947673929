import { IScheduleDetailsDomain } from '../../../../domains/schedule-details.domain';
import { IScheduleDetailsResponse } from '../../get-schedule-bidding-by-id.types';

export const converterScheduleDetailsResponseToDomain = (
  scheduleDetailsResponse: IScheduleDetailsResponse,
): IScheduleDetailsDomain => {
  return {
    id: scheduleDetailsResponse.id,
    type: scheduleDetailsResponse.type,
    freightModality: scheduleDetailsResponse.freightModality,
    statusCode: scheduleDetailsResponse.statusCode,
    distance: scheduleDetailsResponse.distance,
    operation: scheduleDetailsResponse.operation,
    invoicedBy: scheduleDetailsResponse.invoicedBy,
    co2: scheduleDetailsResponse.co2,
    expirationTime: scheduleDetailsResponse.expirationTime,
    vehicleId: scheduleDetailsResponse.vehicleId,
    shippingId: scheduleDetailsResponse.shippingId,
    driverId: scheduleDetailsResponse.driverId,
    operationType: scheduleDetailsResponse.operationType,
    descriptionCancel: scheduleDetailsResponse.descriptionCancel,
    transportRouterId: scheduleDetailsResponse.transportRouterId,
    companyIdBuyer: scheduleDetailsResponse.companyIdBuyer,
    operatedBy: scheduleDetailsResponse.operatedBy,
    origin: {
      id: scheduleDetailsResponse.origin.id,
      type: scheduleDetailsResponse.origin.type,
      name: scheduleDetailsResponse.origin.name ?? '',
      cnpj: scheduleDetailsResponse.origin.cnpj ?? '',
      address: scheduleDetailsResponse.origin.address,
      emails: scheduleDetailsResponse.origin.emails ?? [],
      emailsOcurrence: scheduleDetailsResponse.origin.emailsOcurrence ?? [],
      qualityAndSafety: scheduleDetailsResponse.origin.qualityAndSafety ?? '',
    },
    destination: {
      id: scheduleDetailsResponse.destination.id,
      type: scheduleDetailsResponse.destination.type,
      name: scheduleDetailsResponse.destination.name ?? '',
      cnpj: scheduleDetailsResponse.destination.cnpj ?? '',
      address: scheduleDetailsResponse.destination.address,
      emails: scheduleDetailsResponse.destination.emails ?? [],
      emailsOcurrence: scheduleDetailsResponse.destination.emailsOcurrence ?? [],
      qualityAndSafety: scheduleDetailsResponse.destination.qualityAndSafety ?? '',
    },
    cargo: scheduleDetailsResponse.cargo,
    detail: scheduleDetailsResponse.detail,
    myRoute: scheduleDetailsResponse.myRoute,
    company: scheduleDetailsResponse.company,
    companyBuyer: scheduleDetailsResponse.companyBuyer,
    shipping: scheduleDetailsResponse.shipping,
    vehicle: scheduleDetailsResponse.vehicle,
    driver: scheduleDetailsResponse.driver,
    schedulingCode: scheduleDetailsResponse.schedulingCode,
    freithgtPayable: scheduleDetailsResponse.freithgtPayable,
    freithgtReceivable: scheduleDetailsResponse.freithgtReceivable,
    freithgtShipping: scheduleDetailsResponse.freithgtShipping,
    retentionFines: scheduleDetailsResponse.retentionFines,
  };
};
