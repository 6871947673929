import React from 'react';

import FreightCTESectionView from './freight-cargo-mdfe-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  freightValues: ScheduleDomain;
}

const FreightMDFESection: React.FC<IProps> = ({ freightValues }) => {
  return <FreightCTESectionView freightValues={freightValues} />;
};

export default React.memo(FreightMDFESection);
