import { IVehiclesContext } from './vehicles.types';

export const initialState: IVehiclesContext = {
  vehiclesIsLoading: false,
  vehicles: [],
  vehiclesError: undefined,
  vehiclesPageInfo: undefined,
  setVehiclesRequest: () => undefined,
  setVehiclesSuccess: () => undefined,
  setVehiclesError: () => undefined,
};

export enum IVehiclesAction {
  VEHICLES_REQUEST = 'VEHICLES_REQUEST',
  VEHICLES_SUCCESS = 'VEHICLES_SUCCESS',
  VEHICLES_ERROR = 'VEHICLES_ERROR',
}
