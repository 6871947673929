/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button } from 'logshare-ui-kit';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import * as S from './filter.style';

import { IBackhaulFilter } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { uf } from 'domain/states';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import TooltipComponent from 'components/Tooltip';
import { ClearIcon } from 'assets/icons/clear.icon';

type FilterProps = {
  backhaulFilter: IBackhaulFilter | undefined;
  handleOriginCityChange: (values: any, name: string) => void;
  setBackhaulFilter: React.Dispatch<React.SetStateAction<IBackhaulFilter>>;
  handleFilter: () => void;
  handleCleanFilter: () => void;
};

export const BiddingFilterForm: React.FC<FilterProps> = ({
  backhaulFilter,
  handleOriginCityChange,
  setBackhaulFilter,
  handleFilter,
  handleCleanFilter,
}) => {
  return (
    <S.FilterBackhaulContainer>
      <S.LineSection columns={'0.4fr 0.55fr 1fr 0.55fr 1fr 1fr 1fr 0.1fr 0.1fr'}>
        <S.ContainerRow>
          <InputField
            placeholder="ID"
            id="id"
            name="id"
            type="number"
            onChange={(value: any) => handleOriginCityChange(value, 'id')}
            value={backhaulFilter?.id}
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <Select
            placeholder="Origem"
            id="originUf"
            name="originUf"
            value={backhaulFilter?.originUf}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                originUf: value,
              }))
            }
            options={uf}
            isClearable
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <InputField
            placeholder="Cidade"
            id="originCity"
            name="originCity"
            type="text"
            onChange={(value: any) => handleOriginCityChange(value, 'originCity')}
            value={backhaulFilter?.originCity}
            reactNode={<LocationOnIcon color="disabled" sx={{ width: 17, height: 17 }} />}
            reactNodePosition="right"
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <Select
            placeholder="Destino"
            id="destinationUf"
            name="destinationUf"
            value={backhaulFilter?.destinationUf}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                destinationUf: value,
              }))
            }
            options={uf}
            isClearable
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <InputField
            placeholder="Cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            onChange={(value: any) => handleOriginCityChange(value, 'destinationCity')}
            value={backhaulFilter?.destinationCity}
            reactNode={<LocationOnIcon color="disabled" sx={{ width: 17, height: 17 }} />}
            reactNodePosition="right"
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <Select
            placeholder="Veículo"
            id="vehicleType"
            name="vehicleType"
            value={backhaulFilter?.vehicleType}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                vehicleType: value,
              }))
            }
            options={vehicleTypeOptions}
            isClearable
          />
        </S.ContainerRow>

        <S.ContainerRow>
          <Select
            placeholder="Categoria"
            id="vehicleCategory"
            name="vehicleCategory"
            value={backhaulFilter?.vehicleCategory}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                vehicleCategory: value,
              }))
            }
            options={vehicleCategoryOptions}
            isClearable
          />
        </S.ContainerRow>

        <TooltipComponent
          title="Buscar"
          location="bottom"
          icon={
            <Button
              label=""
              color="gray-light-25"
              onPress={handleFilter}
              LeftIcon={<SearchTwoToneIcon sx={{ width: '21px', height: '21px', color: '#333333' }} />}
              size="x-sm"
              variant="solid"
            />
          }
        />
        <TooltipComponent
          title="Limpar"
          location="bottom"
          icon={
            <Button
              label=""
              color="gray-light-25"
              onPress={handleCleanFilter}
              LeftIcon={<ClearIcon />}
              size="x-sm"
              variant="solid"
            />
          }
        />
      </S.LineSection>
    </S.FilterBackhaulContainer>
  );
};
