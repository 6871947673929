import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const ToastContainerCustom = styled(ToastContainer)`
  &&& {
    .Toastify__toast-container {
    }
    .Toastify__toast-theme--colored.Toastify__toast--success {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.toast.colors.success};
    }
    .Toastify__toast-theme--colored.Toastify__toast--error {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.toast.colors.error};
    }
    .Toastify__toast-theme--colored.Toastify__toast--loading {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.toast.colors.loading};
    }
    .Toastify__toast {
      position: relative;
      min-height: 64px;
      max-height: 800px;
      box-sizing: border-box;
      margin-bottom: 1rem;
      padding: 5px;
      border-radius: 5px;
      box-shadow:
        0 1px 10px 0 rgba(0, 0, 0, 0.1),
        0 2px 15px 0 rgba(0, 0, 0, 0.05);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      overflow: hidden;
      font-family: ${({ theme }) => theme.font.family};
      font-size: 16px;
      font-weight: ${({ theme }) => theme.font.semiBold};
      cursor: pointer;
    }
  }
`;

export default ToastContainerCustom;
