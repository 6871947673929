import dayjs from 'dayjs';

export const compareDates = (
  initialDate: string,
  finalDate: string,
  compareType: 'sameOrBefore' | 'isBefore',
  formatInitialDate?: string,
  formatFinalDate?: string,
  betweenDate?: string,
  formatBetweenDate?: string,
) => {
  if (dayjs(initialDate, formatInitialDate).isValid() && dayjs(finalDate, formatFinalDate).isValid()) {
    const formattedLevyDate = dayjs(initialDate, formatInitialDate);
    const formattedAcceptDate = dayjs(finalDate, formatFinalDate);

    if (compareType === 'sameOrBefore') {
      return formattedAcceptDate.isSame(formattedLevyDate) || formattedAcceptDate.isBefore(formattedLevyDate);
    }

    if (compareType === 'isBefore') {
      if (dayjs(betweenDate, formatBetweenDate).isValid()) {
        const formattedBetweenDate = dayjs(betweenDate, formatBetweenDate);

        return formattedBetweenDate.isBefore(formattedAcceptDate);
      }
    }
  }

  return true;
};
