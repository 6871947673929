import { IImpactOccurrenceEntity } from 'pages/occurrence/services/entities/occurrence.entity';
import { IImpactOccurrence } from 'domain/occurrence';

export const convertTypeEntityToDomainMapper = (typeEntity: IImpactOccurrenceEntity): IImpactOccurrence => {
  switch (typeEntity) {
    case 'low':
      return 'baixo';
    case 'medium':
      return 'médio';
    case 'high':
      return 'alto';
    default:
      return 'baixo';
  }
};
