import { FC, useCallback, useMemo } from 'react';
import { Button as ButtonUI } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';

import * as S from './styled';
import { SectionPlanningShipping } from './components/section-planning-shipping';

import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { UseDeletePlanningVehicle } from 'pages-v2/my-route/hooks/delete-planning-vehicle';
import { ModalPlanningType } from 'pages-v2/my-route/components/form/types/modal';
import { PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import Button from 'components/button/button';

interface SectionPlanningVehicleProps {
  indexPlanningVehicle: number;
  indexPlanningRoute: number;
  planningVehicle: PlanningRouteVehicle;
  handleDeletePlanningVehicle: (indexPlanningVehicle: number) => void;
  handleOpenPlanningShippingModal: (
    open: boolean,
    planningRouteIndex: number,
    planningVehicleIndex: number,
    planningShippingIndex: number,
    type: ModalPlanningType,
    data?: PlanningRouteShipping,
  ) => void;
  handlePlanningScheduleModal: (
    open: boolean,
    planningRouteIndex: number,
    allowedVehicleIndex: number,
    allowedShippingIndex: number,
  ) => void;
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
}

export const SectionPlanningVehicle: FC<SectionPlanningVehicleProps> = ({
  indexPlanningVehicle,
  indexPlanningRoute,
  planningVehicle,
  handleDeletePlanningVehicle,
  handleOpenPlanningShippingModal,
  handlePlanningScheduleModal,
  setFieldValue,
}) => {
  const handleDeletePlanningShipping = useCallback(
    (indexPlanningShipping: number) => {
      const updatedPlanningShipping = planningVehicle.allowedShipping
        .filter((remove) => remove.companyShippingId)
        .filter((_, i) => i !== indexPlanningShipping);
      setFieldValue(
        `planningRoute[${indexPlanningRoute}].allowedVehicles[${indexPlanningVehicle}].allowedShipping`,
        updatedPlanningShipping,
      );
    },
    [planningVehicle.allowedShipping, setFieldValue],
  );

  const handleDeletePlanningRoute = useCallback(async () => {
    if (planningVehicle.id) {
      await UseDeletePlanningVehicle(planningVehicle.id);
    }

    handleDeletePlanningVehicle(indexPlanningVehicle);
  }, [planningVehicle]);

  const renderPlanningShipping = useMemo(() => {
    if (planningVehicle.allowedShipping.length === 0) return;

    const mapping = planningVehicle.allowedShipping
      .filter((remove) => remove.companyShippingId)
      .map((value, allowedShippingIndex) => {
        return (
          <SectionPlanningShipping
            key={allowedShippingIndex}
            indexPlanningRoute={indexPlanningRoute}
            allowedShippingIndex={allowedShippingIndex}
            indexPlanningVehicle={indexPlanningVehicle}
            planningShipping={value}
            handleDeletePlanningShipping={handleDeletePlanningShipping}
            handlePlanningScheduleModal={handlePlanningScheduleModal}
            handleOpenPlanningShippingModal={handleOpenPlanningShippingModal}
          />
        );
      });

    return mapping;
  }, [planningVehicle]);

  const planningSchedules = useMemo(() => {
    const plannedTrips = planningVehicle.allowedShipping.reduce((total, planned) => total + planned.plannedTrips, 0);
    const offeredTrips = planningVehicle.allowedShipping.reduce((total, planned) => total + planned.offeredTrips, 0);
    const acceptedTrips = planningVehicle.allowedShipping.reduce((total, planned) => total + planned.acceptedTrips, 0);
    const refusedTrips = planningVehicle.allowedShipping.reduce((total, planned) => total + planned.refusedTrips, 0);

    return { plannedTrips, offeredTrips, acceptedTrips, refusedTrips };
  }, [planningVehicle]);

  return (
    <S.ContainerVehicleListStyled key={indexPlanningVehicle}>
      <LineSectionStyled columns="0.93fr 0.5fr 1fr 1fr 1fr 1fr 0.75fr">
        <S.TableStyled>
          Veículo
          <S.ContainerInfoStyled>{convertVehicleType(planningVehicle.vehicleType)}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Categoria
          <S.ContainerInfoStyled>{convertVehicleCategory(planningVehicle.vehicleCategory)}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Planejadas
          <S.ContainerInfoStyled>{planningSchedules.plannedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Ofertadas
          <S.ContainerInfoStyled>{planningSchedules.offeredTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Aceitas
          <S.ContainerInfoStyled>{planningSchedules.acceptedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Recusadas
          <S.ContainerInfoStyled>{planningSchedules.refusedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 15,
              marginTop: 2,
            }}
          >
            <ButtonUI
              label={''}
              LeftIcon={<DeleteIcon />}
              color={'red'}
              onPress={() => handleDeletePlanningRoute()}
              variant={'solid'}
              size={'x-sm'}
            />
          </div>
        </div>
      </LineSectionStyled>

      {renderPlanningShipping}

      <div style={{ marginTop: 30 }}>
        <Button
          title="Adicionar"
          bgColor="green"
          text=" + Transportadora"
          size="very-small"
          callback={() =>
            handleOpenPlanningShippingModal(true, indexPlanningRoute, indexPlanningVehicle, 0, 'register')
          }
        />
      </div>
    </S.ContainerVehicleListStyled>
  );
};
