import { Id, toast } from 'react-toastify';

import ToastContainerCustom from './toast-view';
import { removeEndingPunctuation } from 'utils-v2/message/remove-ending-punctuation/remove-ending-punctuation';

type showToastProps = {
  message: string;
  type: 'loading' | 'success' | 'warning' | 'error' | 'info';
  delay?: number;
  toastId?: string;
};
const showToast = ({ message, type, toastId, delay }: showToastProps): Id => {
  const formattedMessage = removeEndingPunctuation(message)
  return toast[type](<ToastContainerCustom message={formattedMessage} />, {
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
    toastId: toastId || message,
    delay,
    icon: true,
  });
};

export { showToast };
