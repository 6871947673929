import styled, { css } from 'styled-components';

type InputUploadImageProps = {
  dataUrlImage: string;
};

export const ContainerCircleStyled = styled.div<InputUploadImageProps>`
  ${() => css`
    position: absolute;
    width: 65px;
    height: 64px;
    left: 20px;
    top: 65px;
    background-color: white;
    border: solid 3px #e9e9e9;
    border-radius: 300px;
    transition-duration: 0.1s;
    cursor: pointer;
  `}
`;

export const CompanyImageStyled = styled.img<InputUploadImageProps>`
  ${({ dataUrlImage }) => css`
    height: ${dataUrlImage < '170px' ? '100%' : '58px'};
    width: ${dataUrlImage < '170px' ? '100%' : '59px'};
    border-radius: 300px;
  `}
`;

export const ImageNullStyled = styled.div`
  background-color: red;
`;
