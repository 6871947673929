import { IMyAccountEntity } from 'pages/my-account/services/entities/my-account.entity';
import { IMyAccount } from 'domain/my-account';

export const convertMyAccountCreatedToEntityMapper = (myAccountDomain: IMyAccount): IMyAccountEntity => ({
  name: myAccountDomain.name,
  login: myAccountDomain.login,
  email: myAccountDomain.email,
  telephone: myAccountDomain.telephone,
  celphone: myAccountDomain.celphone,
});
