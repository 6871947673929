import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { SecurityRequirementsBackhaulPurchaseFormView } from './security-requirements-backhaul-purchase-form-view.component';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { RequirementsTypes } from 'src-new/components/modal-backhaul-purchase/types/security-requirements-backhaul-purchase-form/security-requirements-backhaul-purchase-form.types';
import { requirementsValues } from 'src-new/components/modal-backhaul-purchase/types/security-requirements-backhaul-purchase-form/security-requirements-backhaul-purchase-form-initial-values.constants';

export const SecurityRequirementsBackhaulPurchaseForm: FC = () => {
  const { values, setFieldValue } = useFormikContext<IModalBackhaulPurchaseForm>();

  const [requirementsIndex, setRequirementsIndex] = useState<number>(0);

  const handleCheck = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      currentValues: Array<string>,
      value: {
        label: string;
        type: RequirementsTypes;
      },
    ) => {
      const requirementValues =
        event.target.checked && values.cargo.requirements[value.type]
          ? [...currentValues, value.label]
          : currentValues.filter((v) => v !== value.label);

      setFieldValue(`cargo.requirements[${value.type}].values`, requirementValues);
    },
    [setFieldValue, values.cargo.requirements],
  );

  const prevTags = useCallback(() => setRequirementsIndex((prevState) => (prevState !== 0 ? prevState - 1 : 0)), []);

  const nextTags = useCallback(
    () =>
      setRequirementsIndex((prevState) => (prevState === requirementsValues.length - 1 ? prevState : prevState + 1)),
    [],
  );

  return (
    <SecurityRequirementsBackhaulPurchaseFormView
      requirements={values.cargo.requirements}
      requirementsIndex={requirementsIndex}
      prevTags={prevTags}
      nextTags={nextTags}
      handleCheck={handleCheck}
    />
  );
};
