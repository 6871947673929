import React from 'react';

import {
  CloseModalStyled,
  ContainerHeaderStyled,
  ContainerStyled,
  ContainerTitlesStyled,
  ContentStyled,
  ModalHeaderCloseButtonStyled,
  TitleStyled,
} from './negotiations-modal-view.styled';

interface IProps {
  show: boolean;
  useCallBackCloseButton?: () => void;
  children?: React.ReactNode;
  title: string;
  closeTitle?: string;
  showDescriptionHeader?: boolean;
  buttonIconCloseModal?: React.ReactNode;
}

const NegotiationsModalView: React.FC<IProps> = ({
  children,
  title,
  buttonIconCloseModal,
  show,
  useCallBackCloseButton,
}) => {
  return (
    <ContainerStyled show={show}>
      <ContentStyled>
        <ContainerHeaderStyled>
          <ContainerTitlesStyled>
            <TitleStyled>{title}</TitleStyled>
          </ContainerTitlesStyled>
          <ModalHeaderCloseButtonStyled onClick={useCallBackCloseButton}>
            {React.isValidElement(buttonIconCloseModal) ? buttonIconCloseModal : <CloseModalStyled>X</CloseModalStyled>}
          </ModalHeaderCloseButtonStyled>
        </ContainerHeaderStyled>

        {children}
      </ContentStyled>
    </ContainerStyled>
  );
};

export default NegotiationsModalView;
