import { useCallback, useContext } from 'react';

import { occurrenceListService } from '../../services/occurrence-list/occurrence-list.service';
import { OccurrencesContext } from '../../contexts/table-states/occurrences.context';

import { Hook } from './use-occurrence-list.types';

const useOccurrenceList: Hook = () => {
  const { setOccurrencesRequest, setOccurrencesSuccess, setOccurrencesError } = useContext(OccurrencesContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setOccurrencesRequest();

      try {
        const response = await occurrenceListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setOccurrencesSuccess(response);
      } catch (error) {
        setOccurrencesError('Ocorreu um erro ao buscar as ocorrências, tente novamente!');
      }
    },
    [setOccurrencesError, setOccurrencesRequest, setOccurrencesSuccess],
  );
};

export { useOccurrenceList };
