import React, {Dispatch, memo, SetStateAction, useCallback, useMemo} from 'react';
import {useFormikContext} from 'formik';
import dayjs from 'dayjs';

import * as S from './styled';

import {DatesMatchInput, MatchRegisterInput} from 'domain-v2/match/register';
import {SectionLayout} from 'components-v2/layout/section';
import {LineSectionLayout} from 'components-v2/layout/line-section';
import Line from 'components-v2/layout/line';
import {DateTimePikers} from 'components-v2/common/date-time-pikers';

interface Props {
  datesError?: DatesMatchInput[];
  setDatesError: Dispatch<SetStateAction<DatesMatchInput[] | undefined>>;
}

const DetailsForm: React.FC<Props> = ({datesError, setDatesError}) => {
  const {values, setFieldValue} = useFormikContext<MatchRegisterInput>();

  const handleFieldDateChange = (fieldName: string, date: dayjs.Dayjs | null, index: number) => {
    setFieldValue(fieldName, date?.toISOString());
    const name = fieldName.split('.')[1];
    switch (name) {
      case 'initialLevyDate':
        setFieldValue(`dates[${index}].finalLevyDate`, date?.add(1, 'hours').toISOString());
        setFieldValue(`dates[${index}].initialDeliveryDate`, '');
        setFieldValue(`dates[${index}].finalDeliveryDate`, '');
        setDatesError((prevState) => {
          const newState = [...(prevState ?? [])];
          newState[index] = {
            ...newState[index],
            initialLevyDate: '',
            finalLevyDate: '',
          };
          return newState;
        });
        break;
      case 'initialDeliveryDate':
        setFieldValue(`dates[${index}].finalDeliveryDate`, dayjs(date)?.add(1, 'hours').toISOString());
        setDatesError((prevState) => {
          const newState = [...(prevState ?? [])];
          newState[index] = {
            ...newState[index],
            initialDeliveryDate: '',
            finalDeliveryDate: '',
          };
          return newState;
        });
        break;
      case 'finalLevyDate':
        setFieldValue(`dates[${index}].initialDeliveryDate`, '');
        setFieldValue(`dates[${index}].finalDeliveryDate`, '');
        break;
    }
  };

  const getMinDateForInitialDate = useCallback(() => {
    const today = dayjs();
    const dayOfWeek = today.day();

    switch (dayOfWeek) {
      case 6: // Saturday
        return today.add(6, 'hours');
      case 0: // Sunday
        return today.add(6, 'hours');
      default: // Monday to Friday
        return today.add(6, 'hours');
    }
  }, []);


  const calcMinDate = useCallback(
    (inputName: string, index: number): dayjs.Dayjs | undefined => {
      switch (inputName) {
        case 'initialLevyDate':
          return getMinDateForInitialDate();
        case 'finalLevyDate':
          return dayjs(values.dates[index].initialLevyDate).isValid()
            ? dayjs(values.dates[index].initialLevyDate)
            : dayjs();
        case 'initialDeliveryDate':
          return dayjs(values.dates[index].finalLevyDate).isValid()
            ? dayjs(values.dates[index].finalLevyDate)
            : dayjs();
        case 'finalDeliveryDate':
          return dayjs(values.dates[index].initialDeliveryDate).isValid()
            ? dayjs(values.dates[index].initialDeliveryDate)
            : dayjs();
      }
    },
    [values.dates],
  );

  const renderDatesForm = useMemo(() => {
    const mapping = values?.dates?.map((_, index) => {
      return (
        <div key={index}>
          <LineSectionLayout columns={'1fr 1fr 1fr 1fr 0.07fr'}>
            <DateTimePikers
              label="Inicio de Carregamento *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values.dates[index].initialLevyDate)}
              onChange={(date) => {
                handleFieldDateChange(`dates[${index}].initialLevyDate`, date, index);

                setDatesError((prevState) => {
                  const newState = [...(prevState ?? [])];
                  newState[index] = {
                    ...newState[index],
                    initialLevyDate: '',
                  };
                  return newState;
                });
              }}
              minDate={calcMinDate('initialLevyDate', index)}
              validateField={datesError && !!datesError[index]?.initialLevyDate}
              errorMessage={datesError && (datesError[index]?.initialLevyDate ?? '')}
            />

            <DateTimePikers
              label="Fim de Carregamento *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values.dates[index].finalLevyDate)}
              onChange={(date) => {
                handleFieldDateChange(`dates[${index}].finalLevyDate`, date, index);
                setDatesError((prevState) => {
                  const newState = [...(prevState ?? [])];
                  newState[index] = {
                    ...newState[index],
                    finalLevyDate: '',
                  };
                  return newState;
                });
              }}
              minDate={calcMinDate('finalLevyDate', index)}
              validateField={datesError && !!datesError[index]?.finalLevyDate}
              errorMessage={datesError && (datesError[index]?.finalLevyDate ?? '')}
            />

            <DateTimePikers
              label="Inicio da Descarga *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values?.dates[index]?.initialDeliveryDate)}
              onChange={(date) => {
                handleFieldDateChange(`dates[${index}].initialDeliveryDate`, date, index);
                setDatesError((prevState) => {
                  const newState = [...(prevState ?? [])];
                  newState[index] = {
                    ...newState[index],
                    initialDeliveryDate: '',
                  };
                  return newState;
                });
              }}
              minDate={calcMinDate('initialDeliveryDate', index)}
              validateField={datesError && !!datesError[index]?.initialDeliveryDate}
              errorMessage={datesError && (datesError[index]?.initialDeliveryDate ?? '')}
            />

            <DateTimePikers
              label="Fim da Descarga *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values?.dates[index]?.finalDeliveryDate)}
              onChange={(date) => {
                handleFieldDateChange(`dates[${index}].finalDeliveryDate`, date, index);
                setDatesError((prevState) => {
                  const newState = [...(prevState ?? [])];
                  newState[index] = {
                    ...newState[index],
                    finalDeliveryDate: '',
                  };
                  return newState;
                });
              }}
              minDate={calcMinDate('finalDeliveryDate', index)}
              validateField={datesError && !!datesError[index]?.finalDeliveryDate}
              errorMessage={datesError && (datesError[index]?.finalDeliveryDate ?? '')}
            />
          </LineSectionLayout>

          <S.WrapperButton>
            <LineSectionLayout columns="0.955fr">
              <Line/>
            </LineSectionLayout>
          </S.WrapperButton>
        </div>
      );
    });

    return mapping;
  }, [values.dates, calcMinDate, datesError, handleFieldDateChange, setDatesError]);

  return <SectionLayout name="Informações do Agendamento">{renderDatesForm}</SectionLayout>;
};

export default memo(DetailsForm);
