import React from 'react';

const HomeIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="14" height="22" fill="url(#tower_0)" fillOpacity="1" />
      <defs>
        <pattern id="tower_0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_205_2" transform="matrix(0.00328587 0 0 0.00200803 -0.00109529 0)" />
        </pattern>
        <image
          id="image0_205_2"
          width="305"
          height="498"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATEAAAHyAQMAAAC512sxAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAEiZO6H8hYgAAAAJ0Uk5TAP9bkSK1AAACeElEQVR4nO3bQXKjMBAFULtYsPQRdBQdDR1NR9ERspwFZc3ERESg7tbHg2vGld+bVMWvyuE3INl0LhcWWDlD7JbzB+KmnO8AG/KfitDbQm/sP93cd9On6/+B1/yo0HPD4mLPjYvrHohb3K+e84vrHvC0uO4B56/qsGtxwXZDcRF0yXYj6G7FdYJeXSdoBzpfXKchqJuK6zQOdXmts12w2PV1LlpueJ1LZ7jxfZx5If1gl97ZnX0+n329odf52fcX9L52+v10Kuyk+70v7qT1yBXXWQcPr78dh6776H4D3b+gDt03wfswD7UD3yeOUHz4PhbdF6P7bHjfTkdHR0dHR9f+3uVHhZ7zGaiZjo6Ojo7u/3CX+ssUucra13MXOjo6wM2bn4/yX697Ojo6Ojo6Ojq6f++Wz0CIs+qnuslm91c5b7v5qHO2W58Z32y3PpofbZeOusF2sbir7cJ6jttuZXZDqmfV3nLzcecsVz3CNxtSTUqYQadvZwYdv50ZdDjuzIZUzGrIZnTA626undPdZlLCCPrjGWc0JNXOaEisnRF0HbMV9IbpQd+fc15z89Y5ze0GV9Sgd/NBatBp69Sg43NObci2HXpDdkwLuhmY8bKb987JrpkPQp3SkGZcS2lI2jsl6Lh3StD7mLWgGwa7SWLC/JKXXNMOJWhhXEsMWpiKE4NOzzuxIbF1YkNC68SgBSYFLY6J+dYJMePOtU6cnhMaIg4pCkEnyQlBR8kJQYe/cUJDRNY2RJna83snxiwErQwpNkErs6CoaxqSZNc0JMquCTrIrglaYSwWyyjo/wGF65KOjo6Ojo6Ojo6Ojo6Ojo6Ojo7uvd1v7reia8i3WVUAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};

export default HomeIcon;
