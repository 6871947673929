import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetIcms } from 'src-new/hooks/use-get-icms/get-icms.hook';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IUpdateTransactionPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-transaction/update-transaction.types';
import {
  calculateFeeLogshare,
  calculateICMS,
  calculateServiceTotal,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/utils/calculator-finance';
import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';
import { UserContext } from 'state/user-context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleFinanceCalculateModalFormView } from './schedule-finance-calculate-modal-form-view.component';
import { IRetentionFinesFinanceDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-finance-retention-fines.domain';
import { UseGetRetentionFineByVehicle } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-retention-fines-by-vehicle/use-get-retention-fines-by-vehicle.hook';
import { checkFreeTimeValue } from 'src-new/utils/check-freetime.util';
import moment from 'moment';

export interface IScheduleFinanceCalculateModalFormProps {
  handleCloseModalFinanceCalculate: () => void;
}

export const ScheduleFinanceCalculateModalForm: FC<IScheduleFinanceCalculateModalFormProps> = ({
  handleCloseModalFinanceCalculate,
}) => {
  const { setFieldValue, values, handleSubmit } = useFormikContext<IUpdateTransactionPayload>();
  const { isAdmin, isZMaster, user } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const [icms, setIcms] = useState<number>(0);
  const [retentionFine, setRetentionFine] = useState<IRetentionFinesFinanceDomain>({} as IRetentionFinesFinanceDomain);

  const getRetentionFineHook = UseGetRetentionFineByVehicle();
  const getIcmsHook = useGetIcms();

  const checkIsAdmin = useMemo(() => {
    if (isAdmin || isZMaster) return true;
    return false;
  }, [isAdmin, isZMaster]);

  const checkIsMaster = useMemo(
    (): boolean => user?.name === 'MASTER' && user?.login === 'LOGSHARE',
    [user?.login, user?.name],
  );

  const getOperation = useMemo(() => {
    const { operation } = scheduleDetails.scheduleDetails;
    const isBackhaulOperation = ['BACKHAUL EXTERNO', 'BACKHAUL PARCEIRO', 'BACKHAUL INTERNO'].includes(operation);

    return isBackhaulOperation;
  }, [scheduleDetails.scheduleDetails]);

  const handleTotalService = useCallback(
    (total: string | number) => {
      const totalFreight = normalizeCurrency(total);

      const totalService = calculateServiceTotal({
        ICMSPercentage: 0,
        ICMSField: values.icms,
        otherTaxes: values.otherTaxes,
        totalFreight,
      });

      setFieldValue('totalService', totalService);
    },
    [setFieldValue, values.icms, values.otherTaxes],
  );

  const handleTotalValue = useCallback(
    (
      name: keyof IUpdateTransactionPayload,
      value: string | number,
      increase: boolean,
      increaseValueIn: 'totalFreight' | 'totalService',
    ) => {
      const currentValue = normalizeCurrency(value);
      const previousValue = normalizeCurrency(values[name]);
      const valueToIncrease = (previousValue - currentValue) * (increase ? -1 : 1);

      const freightTotal = Number((normalizeCurrency(values.totalFreight) + valueToIncrease).toFixed(2));

      setFieldValue(name, value);
      setFieldValue(increaseValueIn, freightTotal);

      handleTotalService(freightTotal);
    },
    [handleTotalService, setFieldValue, values],
  );

  const handleFeeLogshare = useCallback(() => {
    if (!scheduleDetails.scheduleDetails.company.feeLogshare) {
      showMessageFormatted({
        message: 'Fee Logshare não cadastrado.',
        type: 'error',
      });
    }

    handleTotalValue(
      'totalFee',
      calculateFeeLogshare(scheduleDetails.scheduleDetails.company.feeLogshare, values.valueFreight),
      true,
      'totalFreight',
    );
  }, [handleTotalValue, scheduleDetails.scheduleDetails.company.feeLogshare, values.valueFreight]);

  const handleGetTotalGrisAdvalorem = useCallback(() => {
    const { cargo, company, companyBuyer } = scheduleDetails.scheduleDetails;
    const shippingValue = cargo.merchValue;
    const companyValues = getOperation ? companyBuyer : company;
    const totalGrisAdvalorem = (
      shippingValue *
      ((Number(companyValues?.gris) + Number(companyValues?.advalorem)) / 100)
    ).toFixed(2);

    const total = totalGrisAdvalorem;

    handleTotalValue('totalGrisAdvalorem', total, true, 'totalFreight');
  }, [scheduleDetails.scheduleDetails, getOperation, handleTotalValue]);

  const handleGetTotalFreetime = useCallback(() => {
    const { detail } = scheduleDetails.scheduleDetails;

    const initialCollectDate = moment(detail.levyInitialDate).subtract(3, 'hours').toISOString().split('.')[0];
    const initialDeliveryData = moment(detail.deliveryInitialDate).subtract(3, 'hours').toISOString().split('.')[0];

    const freeTimeValues = checkFreeTimeValue({
      expectedCollectionFinalDate: initialCollectDate,
      expectedDeliveryFinalDate: initialDeliveryData,
      collectionDate: {
        initialDate: detail.checkinDate,
        finalDate: detail.checkoutDate,
      },
      deliveryDate: {
        initialDate: detail.loadDate,
        finalDate: detail.finishDate,
      },
      dailyFine: retentionFine.seller?.dailyFine ?? 0,
      hourlyFine: retentionFine.seller?.hourlyFine ?? 0,
    });

    if (isNaN(freeTimeValues.freeTimeTotal)) return;

    if (values.status === 'Receber Transportador') {
      return handleTotalValue('surplusTime', freeTimeValues.freeTimeTotal * 0.8, true, 'totalFreight');
    }

    handleTotalValue('surplusTime', freeTimeValues.freeTimeTotal, true, 'totalFreight');
  }, [
    handleTotalValue,
    retentionFine.seller?.dailyFine,
    retentionFine.seller?.hourlyFine,
    scheduleDetails.scheduleDetails,
    values.status,
  ]);

  const handleGetICMS = useCallback(() => {
    const totalService = calculateServiceTotal({
      ICMSPercentage: icms,
      ICMSField: 0,
      otherTaxes: values.otherTaxes,
      totalFreight: values.totalFreight,
    });

    setFieldValue('icms', calculateICMS(icms, values.totalFreight));
    setFieldValue('totalService', totalService);
  }, [icms, setFieldValue, values.otherTaxes, values.totalFreight]);

  const handleOnSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    (async () => {
      const icmsValue = await getIcmsHook(
        scheduleDetails.scheduleDetails.origin.address.uf,
        scheduleDetails.scheduleDetails.destination.address.uf,
      );

      if (scheduleDetails.scheduleDetails.freithgtPayable?.id && icmsValue) {
        return setIcms(icmsValue);
      }

      return setIcms(0);
    })();
  }, [
    getIcmsHook,
    scheduleDetails.scheduleDetails.origin.address.uf,
    scheduleDetails.scheduleDetails.destination.address.uf,
    scheduleDetails.scheduleDetails.freithgtPayable?.id,
    scheduleDetails.allocationActions.allocatedEntity,
  ]);

  useEffect(() => {
    (async () => {
      const { cargo, companyBuyer, company, operation } = scheduleDetails.scheduleDetails;

      const isBackhaulOperation = operation === 'BACKHAUL EXTERNO' || operation === 'BACKHAUL INTERNO';

      if (companyBuyer?.id) {
        const companyId = isBackhaulOperation ? 2 : companyBuyer.id;

        const buyerRetentionFines = await getRetentionFineHook(cargo.vehicleType, companyId);

        const freetime = isBackhaulOperation ? '03:00' : buyerRetentionFines.freeTime;

        setRetentionFine((prevState) => ({
          ...prevState,
          buyer: {
            dailyFine: buyerRetentionFines?.dailyFine,
            freeTime: freetime,
            hourlyFine: buyerRetentionFines?.hourlyFine,
          },
        }));
      }

      const companyId = isBackhaulOperation ? 2 : company.id;

      const sellerRetentionFines = await getRetentionFineHook(cargo.vehicleType, companyId);

      const freetime = isBackhaulOperation ? '03:00' : sellerRetentionFines.freeTime;

      setRetentionFine((prevState) => ({
        ...prevState,
        seller: {
          dailyFine: sellerRetentionFines?.dailyFine,
          freeTime: freetime,
          hourlyFine: sellerRetentionFines?.hourlyFine,
        },
      }));
    })();
  }, [getRetentionFineHook, scheduleDetails.scheduleDetails]);

  return (
    <ScheduleFinanceCalculateModalFormView
      isAdmin={checkIsAdmin}
      isMaster={checkIsMaster}
      values={values}
      handleTotalValue={handleTotalValue}
      handleFeeLogshare={handleFeeLogshare}
      handleGetTotalGrisAdvalorem={handleGetTotalGrisAdvalorem}
      handleGetTotalFreetime={handleGetTotalFreetime}
      handleGetICMS={handleGetICMS}
      handleOnSubmit={handleOnSubmit}
      handleCloseModalFinanceCalculate={handleCloseModalFinanceCalculate}
    />
  );
};
