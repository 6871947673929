import React from 'react';
import * as S from './weighing-totten-modal-details.styled';
import { WeighingTottenButton } from 'src-new/mobile/pages/weighing-totten/components/weighing-totten-button/weighing-totten-button.component';
import { IWeighingTottensDetailsDomain } from '../../../domain/weighing-totten-details/weighing-totten-details.domain';
import { ModalBark } from '../../../../../components/weighing-totten-modal/weighing-totten-modal.component';

interface IWeighingTottenModalsDetailsView {
  schedule: IWeighingTottensDetailsDomain;
  callback: () => void;
}
export const WeighingTottenModalsDetailsView: React.FC<IWeighingTottenModalsDetailsView> = ({ schedule, callback }) => {
  return (
    <ModalBark>
      <S.Wrapper>
        <S.Title>
          Confirme o seu <strong>agendamento</strong>
        </S.Title>

        <S.WrapperText>
          <S.Subtitle>Transportadora</S.Subtitle>
          <S.TextValue>{schedule.shippingCompanyName}</S.TextValue>
        </S.WrapperText>

        <S.WrapperText>
          <S.Subtitle>Motorista</S.Subtitle>
          <S.TextValue>{schedule.driver.name}</S.TextValue>
          <S.TextValue>CPF - {schedule.driver.cpf.slice(0,3).padEnd(11, '*')}</S.TextValue>
        </S.WrapperText>

        <S.WrapperText>
          <S.Subtitle>Veículo</S.Subtitle>
          <S.TextValue>
            {schedule.vehicle.mainPlate}
            {schedule.vehicle.secondPlate && ` / ${schedule.vehicle.secondPlate}`}
            {schedule.vehicle.thirdPlate && ` / ${schedule.vehicle.thirdPlate}`}
          </S.TextValue>
        </S.WrapperText>

        <S.WrapperText>
          <S.Subtitle>Origem</S.Subtitle>
          <S.TextValue>{schedule.origin.name}</S.TextValue>
          <S.TextValue>
            {schedule.origin.uf} - {schedule.origin.city}
          </S.TextValue>
        </S.WrapperText>

        <S.WrapperText>
          <S.Subtitle>Destino</S.Subtitle>
          <S.TextValue>{schedule.destiny.name}</S.TextValue>
          <S.TextValue>
            {schedule.destiny.uf} - {schedule.destiny.city}
          </S.TextValue>
        </S.WrapperText>

        <S.Footer>
          <WeighingTottenButton callback={callback}>Realizar Check-In</WeighingTottenButton>
          <S.GoBack onClick={() => window.location.reload()}>Voltar</S.GoBack>
        </S.Footer>
      </S.Wrapper>
    </ModalBark>
  );
};
