import { convertScheduleGet } from '../../mappers/convert-details';

import API from 'utils/API/API';
import { ScheduleInput } from 'domain-v2/schedule';

export const getScheduleByIdService = async (id: number): Promise<ScheduleInput> => {
  const { data } = await API.get(`v3/schedules/bidding/${id}`);
  const mapper = convertScheduleGet(data);

  return mapper;
};
