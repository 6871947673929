import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalBiddingOfferView } from './modal-bidding-offer-view.component';
import { UserContext } from 'state/user-context';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { useGetFreetimeByVehicle } from 'src-new/hooks/use-get-freetime/use-get-freetime-by-vehicle.hook';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { useGetShippingCompany } from 'src-new/hooks/use-get-shipping-company/use-get-shipping-company.hook';
import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import { IShippingCompanyDomain } from 'src-new/domains/shipping-company.domain';
import { ICompany } from 'domain/company';
import { Formik } from 'formik';
import { IModalBiddingOffer } from './types/bidding-offer/bidding-offer.types';
import { IBiddingOfferForm } from './types/bidding-offer-form/bidding-offer-form.types';
import { useSendTermsConditionsRegister } from 'src-new/hooks/use-send-terms-conditions-register/use-send-terms-conditions-register.hook';
import { useCreateBidding } from 'src-new/hooks/use-create-bidding/use-create-bidding.hook';
import { useRegisterSaleOfferBidding } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/hooks/use-sale-offer-bidding-register/use-sale-offer-register.hook';

interface IModalBiddingOfferProps {
  biddingOffer: IModalBiddingOffer;
  isSaleOffer?: boolean;
  handleClose: () => void;
}

export const ModalBiddingOffer: React.FC<IModalBiddingOfferProps> = ({ biddingOffer, isSaleOffer, handleClose }) => {
  const { user, isShippingCompany } = useContext(UserContext);

  const [company, setCompany] = useState<ICompany>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userCompany, setUserCompany] = useState<ICompany>();
  const [freeTime, setFreeTime] = useState<IFreeTimeDomain>();
  const [companyShipping, setCompanyShipping] = useState<IShippingCompanyDomain>();

  const getCompanyHook = useGetCompany();
  const getFreetimeByVehicleHook = useGetFreetimeByVehicle();
  const getShippingCompanyHook = useGetShippingCompany();
  const sendTermsConditionsRegisterHook = useSendTermsConditionsRegister();
  const createBiddingHook = useCreateBidding();
  const registerSaleOfferBiddingHook = useRegisterSaleOfferBidding();

  const getTermsAndFreetime = useCallback(async () => {
    const [company, userCompany, responseFreeTime] = await Promise.all([
      getCompanyHook(biddingOffer.company.id),
      getCompanyHook(user?.companyId ?? 0),
      getFreetimeByVehicleHook(convertVehicleTypeToEntity(biddingOffer.vehicle.type), biddingOffer.company.id),
    ]);

    if (!company || !responseFreeTime) return;

    const dailyFine = responseFreeTime.dailyFine * 0.8;
    const hourlyFine = responseFreeTime.hourlyFine * 0.8;

    setCompany(company);
    setUserCompany(userCompany);
    setFreeTime({
      ...responseFreeTime,
      dailyFine: dailyFine,
      hourlyFine: hourlyFine,
    });
    setIsLoading(false);
  }, [biddingOffer.company.id, biddingOffer.vehicle.type, getCompanyHook, getFreetimeByVehicleHook, user?.companyId]);

  useEffect(() => {
    (async () => {
      if (isShippingCompany) {
        const shippingCompany = await getShippingCompanyHook(user?.shippingCompanyId ?? 0);

        setCompanyShipping(shippingCompany);
      }

      await getTermsAndFreetime();
    })();
  }, [getShippingCompanyHook, getTermsAndFreetime, isShippingCompany, user?.shippingCompanyId]);

  const handleCreateSaleOfferBidding = useCallback(
    async (values: IBiddingOfferForm) => {
      await registerSaleOfferBiddingHook({
        purchaseId: values.id,
        value: values.cost.totalService,
        proposalValue: values.proposedValue,
      });

      handleClose();
    },
    [registerSaleOfferBiddingHook, handleClose],
  );

  const handleCreateScheduleBidding = useCallback(
    async (values: IBiddingOfferForm) => {
      await Promise.all([
        sendTermsConditionsRegisterHook({
          scheduleId: values.id,
          userName: user?.companyLogin ?? '',
          login: user?.login ?? '',
          termId: 1,
        }),
        createBiddingHook({
          ...values,
          scheduleId: values.id,
          termsId: 1,
        }),
      ]);

      handleClose();
    },
    [createBiddingHook, handleClose, sendTermsConditionsRegisterHook, user?.companyLogin, user?.login],
  );

  const handleSubmitForm = useCallback(
    async (values: IBiddingOfferForm) => {
      if (isSaleOffer) {
        handleCreateSaleOfferBidding(values);
      } else {
        handleCreateScheduleBidding(values);
      }
    },
    [handleCreateSaleOfferBidding, handleCreateScheduleBidding, isSaleOffer],
  );

  const disableButton = useCallback(
    (freightValue: number, termAgree: boolean): boolean => termAgree || !freightValue || freightValue === 0,
    [],
  );

  const biddinfOfferFormInitialValues = useMemo((): IBiddingOfferForm => {
    return {
      ...biddingOffer,
      proposedValue: biddingOffer.cost.freightValue,
      isSaleOffer: isSaleOffer ?? false,
      isShippingCompany,
      termAgree: false,
    };
  }, [biddingOffer, isSaleOffer, isShippingCompany]);

  return (
    <Formik initialValues={biddinfOfferFormInitialValues} onSubmit={handleSubmitForm}>
      <ModalBiddingOfferView
        company={company}
        companyShipping={companyShipping}
        userCompany={userCompany}
        freeTime={freeTime}
        isLoading={isLoading}
        handleClose={handleClose}
        disableButton={disableButton}
      />
    </Formik>
  );
};
