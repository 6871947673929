import API from 'utils/API/API';
import { RetentionFines } from 'pages/schedule/services/entities/retention-fines.entity';
import { ICompany, IRetention } from 'domain/company';

export const retentionFinesService = async (companyId: number): Promise<IRetention[]> => {
  const { data } = await API.get(`v2/retention-fines/${companyId}`);
  return data;
};

export const removeRetentionFineRow = async (companyId: number): Promise<ICompany> => {
  const { data } = await API.delete(`v2/retention-fines/${companyId}`);
  return data;
};

export const deleteRetentionFines = async (arrayIds: number[]) => {
  await API.post('v2/retention-fines/delete', arrayIds);
};

export const updateRetentionFines = async (retentionFinesData: RetentionFines[]) => {
  await API.put('v2/retention-fines', { retentionFinesData });
};
