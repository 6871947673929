import { InitialLoadingActionType } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.action';
import { IInfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/types/infinite-scroll-list-table.types';
import { InitialLoadingActions } from 'src-new/contexts/infinite-scroll-list-table/types/initial-loading.types';

export const initialLoadingReducer = (
  state: IInfiniteScrollListTableContext,
  action: InitialLoadingActions,
): IInfiniteScrollListTableContext => {
  switch (action.type) {
    case InitialLoadingActionType.INITIAL_LOADING_SUCCESS:
      return {
        ...state,
        initialLoading: {
          ...state.initialLoading,
          loading: false,
        },
      };
    case InitialLoadingActionType.INITIAL_LOADING_RESET:
      return {
        ...state,
        initialLoading: {
          ...state.initialLoading,
          loading: true,
        },
      };
    default:
      return state;
  }
};
