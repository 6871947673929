import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useGetScheduleById } from '../../hooks/use-get-schedule-by-id/use-get-schedule-by-id.hook';
import { ScheduleDetailsPageBodyView } from './schedule-details-page-body-view.component';
import { useGetScheduleCustomFields } from '../../hooks/use-get-schedule-custom-fields/use-get-schedule-custom-fields.hook';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { IDenyAllocation } from '../../../schedule-list/components/schedule-page-body/schedule-page-body.types';
import { useUpdateDenySchedule } from '../../../schedule-list/hooks/use-update-deny-schedule/use-update-deny-schedule.hook';

export const ScheduleDetailsPageBody: FC = () => {
  const { scheduleDetails, scheduleList } = useContext(ScheduleContext);
  const { chatConversationList } = useContext(ChatContext);
  const getScheduleByIdHook = useGetScheduleById();
  const getScheduleCustomFieldsHook = useGetScheduleCustomFields();
  const updateDenyScheduleHook = useUpdateDenySchedule();

  const isLoading = useMemo(() => scheduleDetails.statusRequest === 'PROCESSING', [scheduleDetails.statusRequest]);

  const isError = useMemo(() => scheduleDetails.statusRequest === 'ERROR', [scheduleDetails.statusRequest]);

  const isOpenConversationListModal = useMemo(
    () => chatConversationList.modalActions.isOpenModal,
    [chatConversationList.modalActions.isOpenModal],
  );

  const handleTripDeny = useCallback(
    async (values: IDenyAllocation) => {
      await updateDenyScheduleHook(values);
    },
    [updateDenyScheduleHook],
  );

  const handleModalClose = useCallback(() => {
    scheduleList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });
  }, [scheduleList.modalsProps]);

  useEffect(() => {
    if (scheduleDetails.statusRequest === 'INITIAL') {
      getScheduleByIdHook();
      getScheduleCustomFieldsHook();
    }
  }, [getScheduleByIdHook, getScheduleCustomFieldsHook, scheduleDetails.statusRequest]);

  return (
    <ScheduleDetailsPageBodyView
      isLoading={isLoading}
      isError={isError}
      modalOptions={scheduleList.modalsProps.modalOptions}
      isOpenConversationListModal={isOpenConversationListModal}
      handleTripDeny={handleTripDeny}
      handleModalClose={handleModalClose}
    />
  );
};
