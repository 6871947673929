import React from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import * as S from './schedule-duplicate-modal-view.styled';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IScheduleDuplicateModalViewProps {
  handleModalClose: () => void;
  handleDuplicateFreight: () => void;
}

export const ScheduleDuplicateModalView: React.FC<IScheduleDuplicateModalViewProps> = ({ handleModalClose, handleDuplicateFreight }) => {
  return (
    <ModalBark handleClose={handleModalClose} size="xxsm" title="Deseja duplicar o agendamento?">
      <S.Wrapper>
        <Button label="Cancelar" size="md" onPress={handleModalClose} color={ThemeColorsV2Type.Red} variant="solid" />
        <Button label="Duplicar" size="md" onPress={handleDuplicateFreight} color={ThemeColorsV2Type.Blue} variant="solid" />
      </S.Wrapper>
    </ModalBark>
  );
};
