import { Formik } from 'formik';
import React from 'react';
import { Filter } from 'src-new/components/filter/filter.component';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { FilterForm } from './components/filter-form/filter-form.component';

interface IScheduleFilterViewProps {
  scheduleFiltersValues: IScheduleListParams;
  appliedFilters: number;
  handleResetFilter: () => void;
  handleModalFilter: (open: boolean) => void;
  onFilterSubmit: (values: IScheduleListParams) => void;
}

export const ScheduleFilterView: React.FC<IScheduleFilterViewProps> = ({
  scheduleFiltersValues,
  appliedFilters,
  handleResetFilter,
  handleModalFilter,
  onFilterSubmit,
}) => {
  return (
    <Filter
      appliedFilters={appliedFilters}
      handleClose={() => handleModalFilter(false)}
      handleResetFilter={handleResetFilter}
    >
      <Formik initialValues={scheduleFiltersValues} onSubmit={onFilterSubmit}>
        <FilterForm />
      </Formik>
    </Filter>
  );
};
