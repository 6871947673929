import { RetentionFines } from 'pages/schedule/services/entities/retention-fines.entity';

export const convertRetentionFinesToResume = (retentionFines: {
  freeTime: string;
  vehicleType: string;
  hourlyFine: number;
  dailyFine: number;
}): RetentionFines => ({
  freeTime: retentionFines.freeTime ?? 0,
  vehicleType: retentionFines.vehicleType,
  hourlyFine: retentionFines.hourlyFine ?? 0,
  dailyFine: retentionFines.dailyFine ?? 0,
});
