import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
  `}
`;

export const StreetWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const FooterWrapperStyled = styled.div`
  position: absolute;
  bottom: 32px;
`;

export const ContainerHoursStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
`;

export const ContainerOperationStyled = styled.div`
  margin-top: 10px;
`;

export const ContainerDaysStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LabelDaysStyled = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const LabelInAndOutStyled = styled.div`
  font-size: 13px;
  font-weight: 700;

  position: relative;
  bottom: 10px;
  margin-top: 10px;
`;

export const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const LabelSemanalDaysStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerButtonStyled = styled.div`
  margin-top: 23px;
  margin-left: -9px;
`;
