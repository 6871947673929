/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAddress } from 'domain/address';

export const convertViaCEPAddressEntityToDomainMapper = (addressViaCepEntity: any): IAddress => ({
  cep: addressViaCepEntity?.cep || '',
  city: addressViaCepEntity?.localidade || '',
  complement: addressViaCepEntity?.complemento || '',
  neighborhood: addressViaCepEntity?.bairro || '',
  numberHouse: '',
  street: addressViaCepEntity?.logradouro || '',
  uf: addressViaCepEntity?.uf || '',
});

export const convertGoogleCepAddressEntityToDomainMapper = (addressGoogleEntity: any): IAddress => ({
  cep: addressGoogleEntity[0].long_name || '',
  city: addressGoogleEntity[3].long_name || '',
  complement: '',
  neighborhood: addressGoogleEntity[3].long_name || '',
  numberHouse: '',
  street: addressGoogleEntity[2].long_name || '',
  uf: addressGoogleEntity[4].short_name || '',
});
