import styled, { css } from 'styled-components';
import { Form } from 'formik';

type FormStyledProps = { isAllocation?: boolean };
export const FormStyled = styled(Form)<FormStyledProps>`
  ${({ isAllocation = false }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    ${isAllocation &&
    `
      height: calc(100% - 98px);
      padding: 30px;
      gap: 30px;
      box-sizing: border-box;
      overflow: auto;
    `}
  `}
`;

export const GridTemplate = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    align-items: end;
    gap: 15px;
  `}
`;

interface GridSize {
  colunmStart: number;
  colunmEnd: number;
  lineStart: number;
}
export const GridSpan = styled.div<GridSize>`
  ${({ colunmStart, lineStart, colunmEnd }) => css`
    display: grid;
    grid-column-start: ${colunmStart};
    grid-column-end: ${colunmEnd};
    grid-row-start: ${lineStart};
  `}
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    flex-direction: column;
  `}
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    align-items: flex-end;
    width: 100%;
  `}
`;

export const UploadPhotoStyled = styled.div`
  padding-top: 20px;
  padding-left: 15px;
  border-radius: 5px;
  height: 100%;
  div {
    border-radius: 5px;
    height: 100%;
  }
`;

export const CertificationWrapperStyled = styled.div`
  grid-column-start: 1;
  margin-top: 10px;
  grid-column-end: 8;
`;

export const ContainerLabelCPFTwoStyled = styled.div`
  margin-top: 3px;
`;

export const ContainerDateStyled = styled.div`
  margin-top: 4px;
`;

export const LineStyled = styled.div<{ bold?: boolean }>`
  ${({ theme, bold }) => css`
    display: flex;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${bold ? theme.font.bold : ''};
  `}
`;

type WrapperModalItems = { columns: string };
export const WrapperModalItemsStyled = styled.section<WrapperModalItems>`
  ${({ theme, columns }) => css`
    display: grid;
    justify-content: center;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    align-items: end;
    font-family: ${theme.font.family};
    font-size: 11px;
    font-weight: 400;
    color: ${theme.colors.black};
  `}
`;

export const WrapperModalButtomStyled = styled.div`
  margin-top: 160px;
  text-align: right;
`;

export const WrapperGRButtonStyled = styled.div`
  margin-top: 20px;
`;

type GRStatusProps = { Aprovado?: string };
export const GRStatusStyled = styled.span<GRStatusProps>`
  ${({ theme, Aprovado }) => css`
    font-size: ${theme.font.sizes.xsmall};
    padding: 5px 15px;
    border-radius: 20px;
    color: ${theme.colors.white};
    background-color: ${Aprovado === 'Aprovado' ? theme.colors.aqua : theme.colors.red};
  `}
`;
