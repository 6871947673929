import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';
import { IUpdateTransactionPayload } from '../../../../services/update-transaction/update-transaction.types';

export const convertTransactionPayloadToEntity = (
  transactionPayload: IUpdateTransactionPayload,
): Omit<IUpdateTransactionPayload, 'margin'> => {
  return {
    id: normalizeCurrency(transactionPayload.id),
    scheduleId: transactionPayload.scheduleId,
    totalFreight: normalizeCurrency(transactionPayload.totalFreight),
    valueFreight: normalizeCurrency(transactionPayload.valueFreight),
    helperTotal: normalizeCurrency(transactionPayload.helperTotal),
    discharge: normalizeCurrency(transactionPayload.discharge),
    totalGrisAdvalorem: normalizeCurrency(transactionPayload.totalGrisAdvalorem),
    surplusTime: normalizeCurrency(transactionPayload.surplusTime),
    penaltyBonus: normalizeCurrency(transactionPayload.penaltyBonus),
    totalFee: normalizeCurrency(transactionPayload.totalFee),
    toll: normalizeCurrency(transactionPayload.toll),
    otherTaxes: normalizeCurrency(transactionPayload.otherTaxes),
    totalService: normalizeCurrency(transactionPayload.totalService),
    icms: normalizeCurrency(transactionPayload.icms),
    iss: normalizeCurrency(transactionPayload.iss),
    feeAnticipation: normalizeCurrency(transactionPayload.feeAnticipation),
  };
};
