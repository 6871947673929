import { useCallback, useContext } from 'react';
import { IListPage } from 'src-new/domains/list-page.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { ClientsContext } from 'src-new/pages/registrations/pages/client/contexts/clients/clients.context';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { getClientsListService } from '../../services/get-clients-list/get-clients-list.service';

export const useGetClientList = () => {
  const { clientsList } = useContext(ClientsContext);
  const { clients, setClientsListRequest, setClientsListSuccess, setClientsListError, filterActions } = clientsList;
  const { filters, setFilters } = filterActions;
  const { status, order, column, searchValue, page } = filters;
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { setInitialLoadingSuccess } = initialLoading;

  const handlePageLimit = useCallback(
    (page: number, totalPages: number) => {
      if (page === totalPages || totalPages === 0) {
        setFilters({ ...filters, isPageLimit: true });
      }
    },
    [filters, setFilters],
  );

  return useCallback(async () => {
    setClientsListRequest();

    try {
      const response: IListPage<IClientDomain> = await getClientsListService({
        search: searchValue,
        page: page,
        status,
        order,
        column,
      });
      const oldClients = page === 1 ? [] : clients;

      handlePageLimit(page, response.meta.totalPages);
      setClientsListSuccess([...oldClients, ...response.items]);
    } catch (error) {
      setClientsListError();
    }
    setInitialLoadingSuccess();
  }, [
    clients,
    column,
    handlePageLimit,
    order,
    page,
    searchValue,
    setClientsListError,
    setClientsListRequest,
    setClientsListSuccess,
    setInitialLoadingSuccess,
    status,
  ]);
};
