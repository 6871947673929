/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FormikProps, withFormik } from 'formik';

import { FormStyled, LineSectionStyled, SectionStyled } from './occurrence-register-form-view.styled';

import { IImpactOccurrence, IOccurrence, ISlaOccurrence, IoptionsTreatmentSector } from 'domain/occurrence';
import { FooterPage } from 'components-v2/common/footer-page';
import ToggleCheckboxView from 'components/toggle-checkbox/toggle-checkbox';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import ResponsibleSolution from 'components/responsible-solution/responsible-solution';
import InputField from 'components/input-field/input-field';
interface IOccurrenceRegisterFormView {
  occurrenceRegisterValues: IOccurrence;
  optionsImpactOccurrence: Array<{ label: string; value: IImpactOccurrence }>;
  optionsTreatmentSector: Array<{ label: string; value: IoptionsTreatmentSector }>;
  optionsSlaOccurrence: Array<{ label: string; value: ISlaOccurrence }>;
  handleRegister: (occurrenceRegister: IOccurrence) => void;
  handleGoback: () => void;
  schemaValidation: object;
  isLoading: boolean;
}

const OccurrenceRegisterFormView: React.FC<IOccurrenceRegisterFormView> = ({
  occurrenceRegisterValues,
  optionsImpactOccurrence,
  optionsTreatmentSector,
  optionsSlaOccurrence,
  handleRegister,
  handleGoback,
  schemaValidation,
  isLoading,
}) => {
  const InnerForm = (props: FormikProps<IOccurrence>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const [submit, setSubmit] = useState(false);

    useEffect(() => {
      function verificarObjetoVazio(obj: any) {
        if (Object.keys(obj).length !== 0) {
          return true;
        } else {
          return false;
        }
      }

      if (verificarObjetoVazio(errors) && verificarObjetoVazio(touched)) {
        showToast({
          message: 'É necessário preencher todos os campos obrigatórios',
          type: 'error',
        });
        setSubmit(false);
      }
    }, [submit]);

    const handleVerifyInputs = () => {
      !values.sla && setFieldValue('sla', '24 horas');
      handleSubmit();
      setSubmit(true);
    };

    return (
      <FormStyled>
        <SectionStyled name="Detalhes da Ocorrência">
          <LineSectionStyled columns="0.7fr 2fr 0.7fr 0.7fr 0.7fr">
            <InputField label="Código *" id="id" name="id" type="text" disabled={true} />
            <InputField
              label="Nome da Ocorrência *"
              id="description"
              name="description"
              type="text"
              onChange={handleChange}
              value={values.description}
              hasError={!!errors?.description && !!touched?.description}
              errorMessage={String(errors.description)}
            />
            <Select
              label="Tempo de Solução"
              id="sla"
              name="sla"
              value={values.sla}
              setFieldValue={setFieldValue}
              hasError={!!errors?.sla && !!touched?.sla}
              errorMessage={String(errors.sla)}
              options={optionsSlaOccurrence}
            />
            <Select
              label="Impacto *"
              id="impact"
              name="impact"
              value={values.impact}
              setFieldValue={setFieldValue}
              hasError={!!errors?.impact && !!touched?.impact}
              errorMessage={String(errors.impact)}
              options={optionsImpactOccurrence}
            />
            <Select
              label="Setor de Tratativa"
              id="treatmentSector"
              name="treatmentSector"
              value={values.treatmentSector}
              setFieldValue={setFieldValue}
              hasError={!!errors?.treatmentSector && !!touched?.treatmentSector}
              errorMessage={String(errors.treatmentSector)}
              options={optionsTreatmentSector}
            />
          </LineSectionStyled>
        </SectionStyled>
        <SectionDivider label="E-mails de Acionamento e Monitoramento" />
        <SectionStyled name="Detalhes da Ocorrência">
          <LineSectionStyled columns="1fr 1fr">
            <ResponsibleSolution
              responsables={values.responsibleSolution}
              placeholder="E-mail do Responsável pela Solução"
              addResponsable={(res) => {
                setFieldValue('responsibleSolution', res);
              }}
              hasError={!!errors?.responsibleSolution && !!touched?.responsibleSolution}
              errorMessage={String(errors.responsibleSolution)}
            />

            <ResponsibleSolution
              responsables={values.impactedList}
              placeholder="E-mail dos Impactados pela Ocorrência"
              addResponsable={(res) => {
                setFieldValue('impactedList', res);
              }}
            />
          </LineSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleVerifyInputs} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'ativo'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const OccurrenceInformationForm = withFormik<IOccurrence, IOccurrence>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <OccurrenceInformationForm {...occurrenceRegisterValues} />;
};

export default OccurrenceRegisterFormView;
