import React, { ReactElement, useMemo } from 'react';
import FreightUpdateBody from '../freight-update-form/freight-update-form';
import {
  AccessRestrictContainerStyled,
  ChatButtonStyled,
  ChatButtonWrapper,
  ChatIconStyled,
  ChatWrapper,
  FreightUpdateContainerStyled,
  TitleContainerStyled,
  chatStyled,
  SpinnerContainerStyled,
  Spinner,
  UnreadsChatContainerStyled,
  UnreadsChatStyled,
} from './freight-update-view.styled';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import VectorMessage from 'assets/img/vector.svg';
import { AccessRestricted } from 'components/access-restricted/indext';
import { IAllocationInfo } from 'pages/freight/freight-update/freight-update-body/freight-update.types';
import { CancelWhiteIcon } from 'assets/icons/cancel-white.icon';
import { ConversationBuilder, Session, UnreadConversation } from 'talkjs/types/talk.types';
import { Chatbox } from '@talkjs/react';

interface IFreightUpdateViewProps {
  accessRestricted: boolean;
  allocationInfo: IAllocationInfo;
  handleGetAllocation: (allocationID: number, statusCode?: string) => void;
  handleAccessRestricted: (accessRestricted: boolean) => void;
  syncConversation?: (session: Session) => ConversationBuilder;
  handleOpenChat: () => void;
  handleCloseChat: () => void;
  isShowChat: boolean;
  hasDisabledChat: boolean;
  unreadsChat?: UnreadConversation;
}

const FreightUpdateViewComponent: React.FC<IFreightUpdateViewProps> = ({
  accessRestricted,
  allocationInfo,
  handleGetAllocation,
  handleAccessRestricted,
  syncConversation,
  handleOpenChat,
  handleCloseChat,
  isShowChat,
  hasDisabledChat,
  unreadsChat,
}) => {
  const renderChatComponent = useMemo((): ReactElement | undefined => {
    if (!hasDisabledChat && syncConversation && isShowChat) {
      const loading: ReactElement = (
        <SpinnerContainerStyled>
          <Spinner />
        </SpinnerContainerStyled>
      );

      return (
        <ChatWrapper>
          <Chatbox syncConversation={syncConversation} style={chatStyled} loadingComponent={loading} />
        </ChatWrapper>
      );
    }
  }, [hasDisabledChat, isShowChat, syncConversation]);

  const renderUnreadChat = useMemo((): ReactElement | undefined => {
    if (unreadsChat) {
      return (
        <UnreadsChatContainerStyled>
          <UnreadsChatStyled>{unreadsChat.unreadMessageCount}</UnreadsChatStyled>
        </UnreadsChatContainerStyled>
      );
    }
  }, [unreadsChat]);

  const renderChatButton = useMemo((): ReactElement | undefined => {
    if (import.meta.env.VITE_FIREBASE_TARGET === 'development') {
      const button = isShowChat ? (
        <ChatButtonStyled onClick={handleCloseChat}>
          <CancelWhiteIcon />
        </ChatButtonStyled>
      ) : (
        <ChatButtonStyled onClick={handleOpenChat}>
          {renderUnreadChat}
          <ChatIconStyled src={VectorMessage} />
        </ChatButtonStyled>
      );

      return <ChatButtonWrapper>{button}</ChatButtonWrapper>;
    }
  }, [handleCloseChat, handleOpenChat, isShowChat, renderUnreadChat]);

  if (accessRestricted) {
    return (
      <AccessRestrictContainerStyled>
        <AccessRestricted />
      </AccessRestrictContainerStyled>
    );
  }

  return (
    <FreightUpdateContainerStyled>
      <HeaderPage
        breadcrumbList={['Tracking ', `Frete ID ${allocationInfo.allocationID}`]}
        hasBackButton
        renderCustomBackButton
      />
      <TitleContainerStyled>
        <Title title="Detalhamento do Frete" hasTrace isForm />
      </TitleContainerStyled>
      {renderChatButton}
      {renderChatComponent}
      <FreightUpdateBody handleGetAllocation={handleGetAllocation} handleAccessRestricted={handleAccessRestricted} />
    </FreightUpdateContainerStyled>
  );
};

export default FreightUpdateViewComponent;
