import React, { ReactNode } from 'react';

import * as S from './styled';

type LineSectionProps = {
  children: ReactNode;
  columns: string;
  alignItems?: 'start' | 'center' | 'end';
};

export const LineSectionLayout: React.FC<LineSectionProps> = ({ children, columns, alignItems = '' }) => {
  return (
    <S.LineSectionStyled columns={columns} alignItems={alignItems}>
      {children}
    </S.LineSectionStyled>
  );
};
