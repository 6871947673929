import { ColorsV2Type } from 'styles/types/colors';
import { IPalletStatus } from 'domain-v2/pallet/register';

type PalletStatus = {
  label: string;
  color: ColorsV2Type;
};

type PalletStatusMap = {
  [key in IPalletStatus]: PalletStatus;
};

const defaultValue: PalletStatus = {
  label: 'Não Mapeado',
  color: ColorsV2Type.DarkGray,
};

export const convertPalletStatus = (palletStatus: IPalletStatus): PalletStatus => {
  const palletStatusMap: PalletStatusMap = {
    em_aberto: {
      label: 'Em Aberto',
      color: ColorsV2Type.Blue,
    },
    liberado: {
      label: 'Liberado',
      color: ColorsV2Type.Aqua,
    },
  };

  return palletStatusMap[palletStatus] || defaultValue;
};
