import { useCallback, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import {
  getPurchaseOfferListService,
  PurchaseOfferListServiceResponse,
} from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-list/get-purchase-offer-list.service';
import { convertPurchaseOfferListResponseToDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/hooks/use-purchase-offer-list/mappers/convert-purchase-offer-list-response-to-domain/convert-purchase-offer-list-response-to-domain.mapper';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';

export const useGetPurchaseOfferList = () => {
  const { purchaseOfferList } = useContext(PurchaseOfferContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<IPurchaseOfferListFilterDomain>();
  const queryClient = useQueryClient();
  const { filters } = pageHeaderFilterContext;
  const { setInitialLoadingSuccess } = initialLoading;
  const { setPurchaseOfferListSuccess, setPurchaseOfferListRequest, setPurchaseOfferListError } = purchaseOfferList;

  const { data } = useQuery<PurchaseOfferListServiceResponse>({
    queryKey: ['purchase-offer-list-table', filters],
    queryFn: () => {
      setPurchaseOfferListRequest();

      return getPurchaseOfferListService(filters);
    },
    onError: (error) => {
      setPurchaseOfferListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const values = data.items.map((item) => convertPurchaseOfferListResponseToDomain(item));

      const cachedData: PurchaseOfferListServiceResponse[] = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        const cache = queryClient.getQueryData<PurchaseOfferListServiceResponse>([
          'purchase-offer-list-table',
          { ...filters, page: i },
        ]);
        if (cache) {
          cachedData.push(cache);
        }
      }

      const combinedData: IPurchaseOfferListDomain[] = [];

      cachedData.forEach((cache) => {
        cache.items.forEach((item) => {
          const convertedItem = convertPurchaseOfferListResponseToDomain(item);
          if (!combinedData.some((existingItem) => existingItem.id === convertedItem.id)) {
            combinedData.push(convertedItem);
          }
        });
      });

      values.forEach((purchase) => {
        if (!combinedData.some((existingItem) => existingItem.id === purchase.id)) {
          combinedData.push(purchase);
        }
      });

      setPurchaseOfferListSuccess(combinedData);
      setInitialLoadingSuccess();
    }
  }, [data, queryClient, filters, setInitialLoadingSuccess, setPurchaseOfferListSuccess]);
};
