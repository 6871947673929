import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { HeaderTitle, InfoTitle, SectionContainer, Wrapper, MdfeListContent } from '../styled';

import { RenderMdfeUploadedData } from './components/render-uploaded-mdfe-data';

import { FileListUploaded } from 'pages/freight/types';
import { useHandleGetMdfeUploadedListHook, useHandleUploadMdfeFileHook } from 'pages/freight/hooks';
import { ScheduleDomain } from 'domain/schedule';
import Loading from 'components-v2/common/loading/index';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';

interface IProps {
  freightValues: ScheduleDomain;
}

const FreightMDFESectionView: React.FC<IProps> = ({ freightValues }) => {
  const { id } = useParams();

  const [mdfeUploadedList, setMdfeUploadedList] = useState<FileListUploaded[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUploadMdfeFileHook = useHandleUploadMdfeFileHook();
  const handleGetMdfeUploadedListHook = useHandleGetMdfeUploadedListHook();

  const handleUpdateMdfeData = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);

    const responseMdfeUploadedList = await handleGetMdfeUploadedListHook(id);

    if (responseMdfeUploadedList) {
      setMdfeUploadedList(responseMdfeUploadedList);
    }

    setIsLoading(false);
  }, [id]);

  const handleChangeFile = async (value: FileList) => {
    if (!id) return;

    await handleUploadMdfeFileHook(value, id);
    await handleUpdateMdfeData();
  };

  useEffect(() => {
    (async () => {
      await handleUpdateMdfeData();
    })();
  }, []);

  const renderMdfeList = useMemo(() => {
    if (isLoading)
      return (
        <div style={{ width: 40, margin: '0 auto' }}>
          <Loading />
        </div>
      );

    if (mdfeUploadedList.length > 0) {
      const list = mdfeUploadedList.map((mdfe) => {
        return (
          <RenderMdfeUploadedData
            key={mdfe.id}
            mdfe={mdfe}
            freightValues={freightValues}
            handleUpdateMdfeData={handleUpdateMdfeData}
          />
        );
      });

      return list;
    }

    return [];
  }, [mdfeUploadedList, isLoading]);

  return (
    <Wrapper>
      <HeaderTitle isTraced display="flex" justify="space-between">
        <div>MDFe</div>
        <div style={{ display: 'flex', gap: 10 }}>
          <InputFile onChange={handleChangeFile}>
            <Button title="Anexar" bgColor="aqua" size="very-small" icon={<AttachFileIcon />} />
          </InputFile>
        </div>
      </HeaderTitle>

      <SectionContainer display="grid" columns="1fr 1.04fr 0.1fr">
        <InfoTitle>Identificação</InfoTitle>
      </SectionContainer>

      <MdfeListContent>{renderMdfeList}</MdfeListContent>
    </Wrapper>
  );
};

export default React.memo(FreightMDFESectionView);
