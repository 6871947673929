/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext } from 'react';
import moment from 'moment';

import {
  checkDeleteScheduleLTLService,
  createScheduleLTLService,
  deleteScheduleLTLService,
  getScheduleLTLByIdService,
  scheduleLTLCalculateFreight,
  scheduleSTLGetList,
  updateScheduleLTLService,
} from '../service';
import { ScheduleLTLUpdateContext } from '../../schedule-ltl/context/schedule-ltl-update/schedule-ltl.context';
import { SchedulingsLTLContext } from '../../schedule-ltl/context/schedule-ltl-list/table-states/schedulings-ltl.context';

import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { CheckDeleteResponse } from 'domain/global';

export const useScheduleLTLCalculateFreightHook = () => {
  return async (
    originId: number,
    originType: string,
    destinyId: number,
    destinyType: string,
    pallet: number,
    merchValue: string,
    pickUpDate: string,
  ) => {
    const response = await scheduleLTLCalculateFreight({
      originId,
      originType,
      destinyId,
      destinyType,
      pallet,
      merchValue,
      pickUpDate,
    });

    return response.data;
  };
};

export const useScheduleLTLGetListHook = () => {
  const { setSchedulingsRequest, setSchedulingsSuccess, setSchedulingsError } = useContext(SchedulingsLTLContext);

  return useCallback(
    async (payload: any) => {
      sessionStorage.setItem('pagination-stl', JSON.stringify(payload));
      setSchedulingsRequest();
      try {
        const response = await scheduleSTLGetList(payload);

        setSchedulingsSuccess({
          list: response.data.items.map((item) => {
            const origin = item.scheduleClientOrigin?.client || item.scheduleClientOrigin?.locality;
            const destiny = item.scheduleClientDestiny?.client || item.scheduleClientDestiny?.locality;

            return {
              id: item.id,
              company: item.company,
              freightValue: item.freightValue || '',
              vehicleCategory: item.vehicleCategory || '',
              vehicleType: item.vehicleType || '',
              originCity: item.originCity || '',
              destinationCity: item.destinationCity || '',

              scheduleClientOrigin: item.scheduleClientOrigin,
              scheduleClientDestiny: item.scheduleClientDestiny,

              pickUpDate: moment(item.scheduleDetails.levyInitialDate as any)
                .add(4, 'hours')
                .format('DD/MM/YYYY'),
              deliveryDate: moment(item.scheduleDetails.deliveryInitialDate as any)
                .add(4, 'hours')
                .format('DD/MM/YYYY'),
              clientOrigin:
                item.scheduleClientOrigin?.client?.tradeName || item.scheduleClientOrigin?.locality?.name || '',
              clientDestiny:
                item.scheduleClientDestiny?.client?.tradeName || item.scheduleClientDestiny?.locality?.name || '',
              origin: `${origin?.uf} - ${origin?.city}`,
              destiny: `${destiny?.uf} - ${destiny?.city}`,
              statusCode: item.statusCode,
              order: item.order,
              column: item.column,
            };
          }),
          pageInfo: {
            currentPage: response.data.meta.currentPage,
            totalItems: response.data.meta.totalItems,
            itemCount: response.data.meta.itemCount,
            itemsPerPage: response.data.meta.itemsPerPage,
            totalPages: response.data.meta.totalPages,
          },
        });
      } catch (error) {
        setSchedulingsError('Ocorreu um erro ao buscar as tarifas, tente novamente!');
      }
    },
    [setSchedulingsError, setSchedulingsRequest, setSchedulingsSuccess],
  );
};

export const useGetScheduleLTLByIdHook = () => {
  const { currentAllocationError, currentAllocationRequest, currentAllocationSuccess } =
    useContext(ScheduleLTLUpdateContext);

  return useCallback(
    async (scheduleId: string) => {
      currentAllocationRequest();

      try {
        const schedule = await getScheduleLTLByIdService(scheduleId);

        currentAllocationSuccess(schedule);
      } catch (err) {
        currentAllocationError('Ocorreu um erro ao tentar consultar a Alocação');
      }
    },
    [currentAllocationError, currentAllocationRequest, currentAllocationSuccess],
  );
};

export const useGetAllocationScheduleLTLByIdHook = () => {
  const { currentAllocationError, currentAllocationRequest, currentAllocationSuccess } =
    useContext(AllocationRegisterContext);

  return useCallback(
    async (scheduleId: string) => {
      currentAllocationRequest();

      try {
        const schedule = await getScheduleLTLByIdService(scheduleId);

        currentAllocationSuccess(schedule as any);
      } catch (err) {
        currentAllocationError('Ocorreu um erro ao tentar consultar a Alocação');
      }
    },
    [currentAllocationError, currentAllocationRequest, currentAllocationSuccess],
  );
};

export const useScheduleLTLUpdateHook = () => {
  return async (scheduleId: string, payload: any) => {
    await updateScheduleLTLService(scheduleId, payload);
  };
};

export const useScheduleLTLCreateHook = () => {
  return async (payload: any) => {
    await createScheduleLTLService(payload);
  };
};

export const useScheduleLTLCheckDeleteHook = () => {
  return async (payload: any): Promise<CheckDeleteResponse[]> => {
    return await checkDeleteScheduleLTLService(payload);
  };
};
export const useScheduleLTLDeleteHook = () => {
  return async (payload: any) => {
    await deleteScheduleLTLService(payload);
  };
};
