/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

interface StyledProps {
  isBold?: boolean;
  withoutPadding?: boolean;
  isTransportRouter?: boolean;
}

export const CustomWrapper = styled.div`
  ${(p: any) => p.styles}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.width.fullView};
    height: ${theme.height.fullView};
    top: 0;
    left: 0;
    position: absolute;
    padding: 20px;
    background-color: ${theme.modals.background};
    z-index: 1050;
    color: ${theme.colorsV2.black};
  `}
`;

export const Content = styled.div<StyledProps>`
  ${({ theme, isTransportRouter }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${isTransportRouter ? '565px' : '530px'};
    width: 970px;
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    padding: 25px;
    overflow-y: auto;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text2};
    font-weight: 700;
    padding-bottom: 10px;
  `}
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p<StyledProps>`
  ${({ theme, isBold, withoutPadding }) => css`
    font-size: ${isBold ? theme.fontV2.sizes.text3 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 700 : 600};
    color: ${isBold ? theme.colorsV2.blue : theme.colorsV2.black};
    padding-bottom: ${withoutPadding ? 0 : 10}px;
    text-transform: uppercase;
  `}
`;

export const Label = styled.p<StyledProps>`
  ${({ theme, isBold }) => css`
    display: ${isBold ? '' : 'flex'};
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 600 : 400};
    text-transform: uppercase;
  `}
`;

export const ScheduleDetailsBold = styled.p`
  font-weight: 600;
  color: #000;
  padding-left: 5px;
`;

export const WrapperLocations = styled.div<StyledProps>`
  ${({ isTransportRouter }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${isTransportRouter ? '92%' : '100%'};
  `}
`;

export const WrapperScheduleDetails = styled.div<StyledProps>`
  ${({ isTransportRouter }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${isTransportRouter ? '92%' : '100%'};
  `}
`;

export const ScheduleDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

export const ButtonClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const CheckboxSection = styled.div`
  display: flex;
  align-items: center;
`;

export const TermsText = styled.p<StyledProps>`
  ${({ theme }) => css`
    display: inline;
    flex-direction: column;
    align-items: center;
    font-size: ${theme.fontV2.sizes.text5};
    padding-left: 10px;
    text-transform: uppercase;
  `}
`;

export const StepIcon = styled.div`
  background-color: #d9d9d9;
  z-index: 1;
  color: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const Connector = styled.div`
  height: 88px;
  border: 0;
  width: 2px;
  margin-left: 4px;
  background-color: #d9d9d9;
`;
