export const normalizeCurrency = (amount: number | string) => {
  const formatted = String(amount).replace(',', '.');

  if (formatted.endsWith('.')) {
    return Number(`${formatted}00`);
  }

  return Number(formatted);
};

export const normalizeDecimal = (value: number | string) => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return Number(value.toFixed(2));
};
