import { useCallback, useContext } from 'react';

import { Hook } from './use-table-freight-list.types';

import { tableFreightListService } from 'pages/table-freight/services/table-freight-list/table-freight-list.service';
import { TableFreightContext } from 'pages/table-freight/contexts/table-states/table-freight.context';

const useTableFreightList: Hook = () => {
  const { setTableFreightRequest, setTableFreightSuccess, setTableFreightError } = useContext(TableFreightContext);

  return useCallback(
    async ({ currentPage }) => {
      setTableFreightRequest();

      try {
        const response = await tableFreightListService({
          page: currentPage,
        });

        setTableFreightSuccess(response);
      } catch (error) {
        setTableFreightError('Ocorreu um erro ao lista a tabela de fretes');
      }
    },
    [setTableFreightError, setTableFreightRequest, setTableFreightSuccess],
  );
};

export { useTableFreightList };
