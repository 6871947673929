import { formatDecimal } from 'utils/format-numbers';

export const offeredWeight = (value: number | string) => {
  const weight = Number(value);
  if (weight !== 0) {
    return `${formatDecimal(weight)} KG`;
  }

  return '- KG';
};

export const offeredPallets = (value: number | string) => {
  const pallet = Number(value);
  if (pallet !== 0) {
    return `${pallet} PALLETS`;
  }

  return '- PALLETS';
};
