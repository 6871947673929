import styled, { css } from 'styled-components';

export const WrapperStyled = styled.nav`
  ${({ theme }) => css`
    background-color: rgb(243 243 243);
    border-right: 1px solid ${theme.sidebar.colors.forBorder};
    padding-top: 162px;

    @media (max-width: 600px) {
      display: none;
    }
  `}
`;

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    padding: 0 15px;
    display: flex;
    height: 42px;
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
    align-items: center;
  `}
`;

export const MenuStyled = styled.ul`
  ${() => css`
    list-style: none;
    text-transform: none;
    width: 185px;
  `}
`;

export const ContainerModal = styled.div`
  ${() => css`
    display: flex;
    margin-top: 17px;
    flex-direction: column;
    padding: 12px;
    justify-content: space-betwen;
  `}
`;

export const TextContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 8px;
  `}
`;

export const LogSharePro = styled.div`
  margin-top: 20px;
  width: 200px;
  height: 50px;
  background-color: #1f2b4f;
  margin-left: -18px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const LogShareText = styled.div`
  color: #fff;
  font-size: 20px;
  size: 12px;
  font-weight: 600;
`;

export const ProText = styled.div`
  color: #ffb743;
  font-size: 20px;
  size: 12px;
  margin-left: 8px;
  font-weight: 600;
  margin-right: 12px;
`;

export const Text = styled.span`
  font-size: 15px;
  size: 12px;
  margin-left: 8px;
`;
