import { ScheduleDownloadMapReportActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';
import { IScheduleDownloadMapReportActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-download-map-report.types';

export const scheduleDownloadMapReportReducer = (
  state: IScheduleContext,
  action: IScheduleDownloadMapReportActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_REQUEST:
      return {
        ...state,
        scheduleDownloadMapReport: {
          ...state.scheduleDownloadMapReport,
          statusRequest: 'PROCESSING',
        },
      };
    case ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_SUCCESS:
      return {
        ...state,
        scheduleDownloadMapReport: {
          ...state.scheduleDownloadMapReport,
          statusRequest: 'SUCCESS',
        },
      };
    case ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_ERROR:
      return {
        ...state,
        scheduleDownloadMapReport: {
          ...state.scheduleDownloadMapReport,
          statusRequest: 'ERROR',
        },
      };
    case ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_RESET:
      return {
        ...state,
        scheduleDownloadMapReport: {
          ...state.scheduleDownloadMapReport,
          statusRequest: 'INITIAL',
        },
      };
    default:
      return state;
  }
};
