import { IBackhaulValues } from 'domain/backhaul';
import { ModalMatchDetails } from 'components-v2/common/modals/match-form/types';

export const convertBackhaulToModalMatchMapper = (saleOffer: IBackhaulValues): ModalMatchDetails => {
  return {
    id: saleOffer.id,
    saleOfferId: saleOffer.id,
    saleOfferTariffId: saleOffer.idTariff,
    matchOfferId: null,
    company: {
      id: saleOffer.companyPartner.id,
      fileKey: saleOffer.companyPartner.image,
    },
    origin: {
      id: saleOffer.origin.id,
      name: saleOffer.origin.name,
      city: saleOffer.origin.address.city,
      uf: saleOffer.origin.address.uf,
      type: saleOffer.origin.type,
    },
    destination: {
      id: saleOffer.destination.id,
      name: saleOffer.destination.name,
      city: saleOffer.destination.address.city,
      uf: saleOffer.destination.address.uf,
      type: saleOffer.destination.type,
    },
    vehicle: {
      type: saleOffer.vehicle.type.toLowerCase(),
      category: saleOffer.vehicle.category.toLowerCase(),
    },
    typeSchedule: saleOffer.cargoType,
    operationType: saleOffer.operationType,
    haulType: saleOffer.backhaulPartner ? 'BACKHAUL PARCEIRO' : 'BACKHAUL EXTERNO',
    co2Economy: 0,
    weight: saleOffer.weight,
    cubage: saleOffer.cubage,
    pallets: saleOffer.pallets,
    invoicedBy: 'LOGSHARE',
    freightValue: saleOffer.valueTotal,
    logshareValue: 0,
    exclusiveRate: saleOffer.backhaulPartner,
    backhaulPartner: saleOffer.backhaulPartner,
  };
};
