import { useCallback } from 'react';
import { ILocalityDomain } from 'src-new/domains/locality.domain';
import { getLocalityService } from 'src-new/services/get-locality/get-locality.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetLocality = () => {
  return useCallback(async (localityId: number): Promise<ILocalityDomain | undefined> => {
    try {
      return getLocalityService(localityId);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter detalhes da unidade.',
        type: 'error',
      });
    }
  }, []);
};
