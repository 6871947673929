import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { showMessageFormatted } from '../../../../../../../../../utils/message/show-message-formatted/show-message-formatted';
import { getFavoriteMatchesKanbanListService } from '../../services/get-favorite-matches-kanban-list/get-favorite-matches-kanban-list.service';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { convertMatchesKanbanListResponseToDomain } from '../../mappers/convert-matches-kanban-list-response-to-domain/convert-matches-kanban-list-response-to-domain.mapper';

export const useGetFavoriteMatchesKanbanList = () => {
  const { favoriteMatchesKanbanList, matchesKanbanList } = useContext(MatchesContext);
  const {
    setFavoriteMatchesKanbanListRequest,
    setFavoriteMatchesKanbanListSuccess,
    setFavoriteMatchesKanbanListError,
  } = favoriteMatchesKanbanList;

  const { data } = useQuery<IListPage<IMatchesKanbanListResponse>>({
    queryKey: ['favorite-matches-kanban-list', matchesKanbanList.matchesListFiltersProps.filters],
    queryFn: () => {
      setFavoriteMatchesKanbanListRequest();

      return getFavoriteMatchesKanbanListService(matchesKanbanList.matchesListFiltersProps.filters);
    },
    onSuccess: (data) => {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);

      setFavoriteMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    },
    onError: (error) => {
      setFavoriteMatchesKanbanListError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);
      setFavoriteMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    }
  }, [data, setFavoriteMatchesKanbanListSuccess]);
};
