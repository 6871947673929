/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { convertStatusDomainToEntityMapper } from '../convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import { IHireBackhaulEntity } from 'pages/hire-backhaul/services/entities/hire-backhaul.entity';
import { IHireBackhaul } from 'domain/hire-backhaul';

export const convertHireBackhaulDomainToEntityMapper = (domain: IHireBackhaul): IHireBackhaulEntity => {
  const formatPrice = (value: any) => {
    return value.toString().replace('.', '').replace(',', '.');
  };

  return {
    ...domain,
    initialDate:moment(domain.initialDate).toISOString(),
    endDate: moment(domain.endDate).toISOString(),
    category: domain.category || '',
    offeredCubage: Number(domain.offeredCubage),
    offeredWeight: Number(domain.offeredWeight),
    freightValue: Number(formatPrice(domain.freightValue)),
    status: convertStatusDomainToEntityMapper(domain.status as any),
  };
};
