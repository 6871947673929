import React, { PropsWithChildren } from 'react';

import * as S from './styles';

/**
 *
 * @param children : JSX.Element
 *
 * Component made to emcapsulate map content,
 * here we can define propreties like width or
 * height that will adjust map in relation to
 * his neighbor components
 *
 * @returns
 */

const MapWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <S.Wrapper>{children}</S.Wrapper>;
};

export default MapWrapper;
