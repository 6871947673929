import React, { ReactNode, useMemo } from 'react';

import ITrackingOccurrenceTableViewProps from './tracking-occurrence-table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface ITrackingOccurrenceTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToUpdatePage: (vehicleId: string) => void;
  isLoading: boolean;
  notAbsolute?: boolean;
  arrowOrder?: string | ReactNode;
}

const TrackingOccurrenceTable: React.FC<ITrackingOccurrenceTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToUpdatePage,
  isLoading,
  arrowOrder,
  notAbsolute,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0,
      },
      {
        value: 'ID',
        flex: 0.25,
      },
      {
        value: 'Frete ID',
        flex: 0.4,
      },
      {
        value: 'Impacto',
        flex: 0.5,
      },
      {
        value: 'Tipo',
        flex: 1,
      },
      {
        value: 'Cliente',
        flex: 1.2,
      },
      {
        value: 'Data Abertura',
        flex: 0.6,
      },
      {
        value: 'Data Fechamento',
        flex: 0.8,
      },
      {
        value: 'Status',
        flex: 0.5,
      }
    ];
  }, []);

  return (
    <ITrackingOccurrenceTableViewProps
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      clickRowAction={goToUpdatePage}
      isLoading={isLoading}
      notAbsolute={notAbsolute}
    />
  );
};

export default TrackingOccurrenceTable;
