import { StabilizedMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesContext } from '../../types/matches.types';
import { IStabilizedMatchesKanbanListActions } from '../../types/stabilized-matches-kanban.types';

export const stabilizedMatchesKanbanListReducer = (
  state: IMatchesContext,
  action: IStabilizedMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_REQUEST:
      return {
        ...state,
        stabilizedMatchesKanbanList: {
          ...state.stabilizedMatchesKanbanList,
          statusRequest: 'PROCESSING',
          stabilizedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_SUCCESS:
      return {
        ...state,
        stabilizedMatchesKanbanList: {
          ...state.stabilizedMatchesKanbanList,
          statusRequest: 'SUCCESS',
          stabilizedKanbanList: action.payload,
        },
      };
    case StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_ERROR:
      return {
        ...state,
        stabilizedMatchesKanbanList: {
          ...state.stabilizedMatchesKanbanList,
          statusRequest: 'ERROR',
          stabilizedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_RESET:
      return {
        ...state,
        stabilizedMatchesKanbanList: {
          ...state.stabilizedMatchesKanbanList,
          statusRequest: 'INITIAL',
          stabilizedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    default:
      return state;
  }
};
