import { FC, useCallback, useMemo, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';

import * as S from './styled';

import { planningVehicleInitialValues } from 'pages-v2/my-route/components/form/initial-values';
import { PlanningRouteVehicle } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { ModalState } from 'domain-v2/inputs/modals';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import { SectionStyled } from 'components-v2/layout/section/styled';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import Select from 'components/select/select';

interface PlanningRouteModalProps {
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
  handleClosePlanningVehicleModal: () => void;
  planningVehicleModal: ModalState<{
    planningRouteIndex: number;
  }>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const PlanningVehicleModal: FC<PlanningRouteModalProps> = ({
  values,
  setFieldValue,
  handleClosePlanningVehicleModal,
  planningVehicleModal,
  handleSubmit,
}) => {
  const [planningVehicleForm, setPlanningVehicleForm] = useState<PlanningRouteVehicle>(planningVehicleInitialValues);

  const handleChange = useCallback((name: string, value: string) => {
    setPlanningVehicleForm((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const buttonDisabled = useMemo(() => {
    if (!planningVehicleForm.vehicleType || !planningVehicleForm.vehicleCategory) {
      return true;
    }

    return false;
  }, [planningVehicleForm.vehicleType, planningVehicleForm.vehicleCategory]);

  const handleSavePlanningVehicle = useCallback(async () => {
    setFieldValue(`planningRoute[${planningVehicleModal.data.planningRouteIndex}].allowedVehicles`, [
      ...values.planningRoute[planningVehicleModal.data.planningRouteIndex].allowedVehicles,
      planningVehicleForm,
    ]);

    handleClosePlanningVehicleModal();

    if (values.id) {
      handleSubmit();
    }
  }, [setFieldValue, planningVehicleForm]);

  return (
    <ModalBark size={'xsm'} title={'Veículo Permitido'} handleClose={handleClosePlanningVehicleModal}>
      <SectionStyled name="Informações do Planejamento">
        <LineSectionStyled columns="1fr 1fr">
          <Select
            label="Tipo de Veículo *"
            id="vehicleType"
            name="vehicleType"
            value={planningVehicleForm.vehicleType}
            setFieldValue={handleChange}
            options={vehicleTypeOptions}
            isClearable
          />
          <Select
            label="Categoria *"
            id="vehicleCategory"
            name="vehicleCategory"
            value={planningVehicleForm.vehicleCategory}
            setFieldValue={handleChange}
            options={vehicleCategoryOptions}
            isClearable
          />
        </LineSectionStyled>
      </SectionStyled>

      <S.WrapperButton>
        <Button
          label={'Salvar'}
          color={'blue'}
          onPress={handleSavePlanningVehicle}
          variant={'solid'}
          size={'md'}
          disabled={buttonDisabled}
        />
      </S.WrapperButton>
    </ModalBark>
  );
};
