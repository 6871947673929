import { loginDemo } from '../services/login-demo.service';
import { ILoginDemo } from '../services/entities/login.entity';

const useLoginDemoHook = () => {
  return async (values: ILoginDemo) => {
    await loginDemo(values);
  };
};

export { useLoginDemoHook };
