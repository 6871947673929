import { FC } from 'react';

import { List } from './list';
import FiltersProvider from './context';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

export const BiddingsListPage: FC = () => {
  return (
    <FiltersProvider>
      <HeaderPage breadcrumbList={['Fretes', 'Ofertados']} />
      <Title title={'Acompanhe os biddings enviados.'} />
      <List />
    </FiltersProvider>
  );
};
