import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const NameWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
`;

export const TradeNameWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const UploadImageStyled = styled.div`
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
`;

export const OptionStyled = styled.option``;

export const LoginParceiroStyled = styled.span`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.gray};
    border-radius: 100px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: ${theme.font.normal};
    font-family: ${theme.font.family};
  `}
`;
