import { FC, ReactNode } from 'react';

import * as S from './styled';

interface FooterPageLeftContentProps {
  children: ReactNode;
}

export const FooterPageLeftContent: FC<FooterPageLeftContentProps> = ({ children }) => {
  return <S.LeftContent>{children}</S.LeftContent>;
};
