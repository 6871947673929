import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const WrapperButtonsAndIcon = styled.main`
  width: 366px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperLogo = styled.div`
  position: relative;
  top: 4px;
  left: 8px;
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem 0rem 0rem 0rem;
`;

export const ContainerButton = styled.div`
  button {
    min-width: 100px !important;
  }
`;
