import React from 'react';

import { TitleStyled, TraceVerticalStyled, WrapperStyled } from './section-divider-view.styled';
import { PinOriginDestiny } from 'assets/icons/pin-origin-destiny';

interface ISectionDividerViewProps {
  label: string;
  secondItem?: React.ReactElement;
  hidePin?: boolean;
}

export const SectionDividerView: React.FC<ISectionDividerViewProps> = ({ label, secondItem, hidePin }) => {
  return (
    <WrapperStyled>
      <TitleStyled>
        {hidePin ? <TraceVerticalStyled /> : <PinOriginDestiny width={18} height={23} />}
        {label}
      </TitleStyled>
      {secondItem}
    </WrapperStyled>
  );
};
