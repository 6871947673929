import { FC, useCallback, useState } from 'react';
import { WeighingTottenDetailsPageBodyView } from './weighing-totten-details-page-body-view.component';

export const WeighingTottenDetailsPageBody: FC = () => {
  const [formPage, setFormPage] = useState<boolean>(false);

  const handleInitialButtonClick = useCallback(() => setFormPage(true), []);

  return <WeighingTottenDetailsPageBodyView formPage={formPage} handleInitialButtonClick={handleInitialButtonClick} />;
};
