import React from 'react';

import KanbanView from './kanban-header-view';

import { KanbanHeaderProps } from 'pages/task-vision/types/types';

const Kanban: React.FC<KanbanHeaderProps> = ({ title, bgColor, totalItems }) => {
  return <KanbanView title={title} bgColor={bgColor} totalItems={totalItems} />;
};

export default Kanban;
