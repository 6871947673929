import { useCallback, useContext } from 'react';
import { IMessageChatDomain } from 'src-new/domains/message-chat.domain';
import { getConversationMessagesByIdService } from 'src-new/services/chat/get-conversation-messages-by-id/get-conversation-messages-by-id.service';
import {
  IMessageErrorResponse,
  IMessageResponse,
} from 'src-new/services/chat/get-conversation-messages-by-id/get-conversation-messages-by-id.types';
import { ChatContext } from 'src-new/contexts/chat/chat.context';

export const useGetMessageQuantityChat = () => {
  const { chatMessageQuantity } = useContext(ChatContext);

  return useCallback(
    async (idList: Array<string>): Promise<void> => {
      const promises: Promise<Array<IMessageResponse | IMessageErrorResponse>>[] = idList.map((id: string) => {
        return getConversationMessagesByIdService(id, true);
      });
      const conversations: Array<Array<IMessageResponse | IMessageErrorResponse>> = await Promise.all(promises);

      const messageResponses: Array<IMessageResponse> = conversations
        .flat()
        .filter((item): item is IMessageResponse => 'text' in item);

      const filteredMessages: Array<IMessageResponse> = [];
      const checkedValues: {
        [key: string]: boolean;
      } = {};

      messageResponses.forEach((message) => {
        const value = message['conversationId'];

        if (!(value in checkedValues)) {
          checkedValues[value] = true;
          filteredMessages.push(message);
        }
      });

      const messageCountList: Array<IMessageChatDomain> = filteredMessages.map((conversation) => {
        return {
          id: conversation.conversationId,
          messagesCount: messageResponses
            .filter((message) => message.conversationId === conversation.conversationId)
            .reduce((count, message) => {
              return message.text !== '' ? count + 1 : count;
            }, 0),
        };
      });

      chatMessageQuantity.setMessageQuantitySuccess(messageCountList);
    },
    [chatMessageQuantity],
  );
};
