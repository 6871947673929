import React, { FC, useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { PurchaseOfferTable } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-table-tabs/components/purchase-offer-table/purchase-offer-table.component';
import { TabContainerStyled } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-table-tabs/purchase-offer-table-tabs-view.styled';

export interface IPurchaseOfferTableTabsViewProps {
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const PurchaseOfferTableTabsView: FC<IPurchaseOfferTableTabsViewProps> = ({ activeTab, handleChangeTab }) => {
  const renderTabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Ativas',
        content: <PurchaseOfferTable />,
      },
      {
        name: 'Inativas',
        content: <PurchaseOfferTable />,
      },
    ];
  }, []);

  return (
    <TabContainerStyled>
      <Tab tabsContent={renderTabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
    </TabContainerStyled>
  );
};
