import React, { useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import { FormikProps, withFormik } from 'formik';

import { FormStyled, LineSectionStyled, SectionStyled } from './occurrence-update-form-view.styled';

import { ColorsV2Type } from 'styles/types/colors';
import { checkOccurrenceBlocked } from 'pages/occurrence/constants';
import { IImpactOccurrence, IOccurrenceCreated, ISlaOccurrence, IoptionsTreatmentSector } from 'domain/occurrence';
import { FooterPage } from 'components-v2/common/footer-page';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import Title from 'components/title/title';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import ResponsibleSolution from 'components/responsible-solution/responsible-solution';
import InputField from 'components/input-field/input-field';

interface IOccurrenceUpdateFormView {
  occurrenceRegisterValues: IOccurrenceCreated;
  optionsImpactOccurrence: Array<{ label: string; value: IImpactOccurrence }>;
  optionsSlaOccurrence: Array<{ label: string; value: ISlaOccurrence }>;
  optionsTreatmentSector: Array<{ label: string; value: IoptionsTreatmentSector }>;
  handleRegister: (values: IOccurrenceCreated) => void;
  handleGoback: () => void;
  schemaValidation: object;
  isLoading: boolean;
}

const OccurrenceUpdateFormView: React.FC<IOccurrenceUpdateFormView> = ({
  occurrenceRegisterValues,
  optionsImpactOccurrence,
  optionsSlaOccurrence,
  optionsTreatmentSector,
  handleRegister,
  handleGoback,
  schemaValidation,
  isLoading,
}) => {
  const InnerForm = (props: FormikProps<IOccurrenceCreated>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const isOccurrenceBlocked = useMemo(() => {
      return checkOccurrenceBlocked(occurrenceRegisterValues.description);
    }, [occurrenceRegisterValues.description]);

    return (
      <FormStyled>
        <Title title="Atualização Cadastral" hasTrace isForm />
        <SectionStyled name="Detalhes da Ocorrência">
          <LineSectionStyled columns="0.7fr 2fr 0.7fr 0.7fr 0.7fr">
            <InputField
              label="ID *"
              id="id"
              name="id"
              placeholder="ID"
              value={values.id.toString()}
              type="text"
              disabled={true}
            />
            <InputField
              label="Nome da Ocorrência *"
              id="description"
              name="description"
              type="text"
              onChange={handleChange}
              value={values.description}
              hasError={!!errors?.description && !!touched?.description}
              errorMessage={String(errors.description)}
              disabled={isOccurrenceBlocked}
              reactNodePosition="right"
              reactNode={
                isOccurrenceBlocked && (
                  <Tag label="Padrão" color={ColorsV2Type.Red} variant="solid" display="inline-flex" size="md" />
                )
              }
            />
            <Select
              label="Tempo de Solução"
              id="sla"
              name="sla"
              value={values.sla}
              setFieldValue={setFieldValue}
              hasError={!!errors?.sla && !!touched?.sla}
              errorMessage={String(errors.sla)}
              options={optionsSlaOccurrence}
            />
            <Select
              label="Impacto *"
              id="impact"
              name="impact"
              value={values.impact}
              setFieldValue={setFieldValue}
              hasError={!!errors?.impact && !!touched?.impact}
              errorMessage={String(errors.impact)}
              options={optionsImpactOccurrence}
            />
            <Select
              label="Setor de Tratativa"
              id="treatmentSector"
              name="treatmentSector"
              value={values.treatmentSector}
              setFieldValue={setFieldValue}
              hasError={!!errors?.treatmentSector && !!touched?.treatmentSector}
              errorMessage={String(errors.treatmentSector)}
              options={optionsTreatmentSector}
            />
          </LineSectionStyled>
        </SectionStyled>
        <SectionDivider label="E-mails de Acionamento e Monitoramento" />
        <SectionStyled name="Detalhes da Ocorrência">
          <LineSectionStyled columns="1fr 1fr">
            <ResponsibleSolution
              responsables={values.responsibleSolution}
              placeholder="E-mail do Responsável pela Solução"
              addResponsable={(res) => {
                setFieldValue('responsibleSolution', res);
              }}
              hasError={!!errors?.responsibleSolution && !!touched?.responsibleSolution}
              errorMessage={String(errors.responsibleSolution)}
            />

            <ResponsibleSolution
              responsables={values.impactedList}
              placeholder="E-mail dos Impactados pela Ocorrência"
              addResponsable={(res) => {
                setFieldValue('impactedList', res);
              }}
            />
          </LineSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleSubmit} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'ativo'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
              disabled
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const OccurrenceInformationForm = withFormik<IOccurrenceCreated, IOccurrenceCreated>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <OccurrenceInformationForm {...occurrenceRegisterValues} />;
};

export default OccurrenceUpdateFormView;
