import { useCallback, useReducer } from 'react';
import { saleOfferFreetimeReducer } from '../../reducers/sale-offer-freetime/sale-offer-freetime.reducer';
import { SaleOfferFreetimeActionType } from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import { ISaleOfferFreetime } from '../../types/sale-offer-freetime.types';
import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';

export const useSaleOfferFreetimeContextValues = (): ISaleOfferFreetime => {
  const [state, dispatch] = useReducer(saleOfferFreetimeReducer, saleOfferInitialState);

  const setSaleOfferFreetimeRequest = useCallback(() => {
    dispatch({ type: SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferFreetimeSuccess = useCallback((freetime: IFreeTimeDomain) => {
    dispatch({ type: SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_SUCCESS, payload: freetime });
  }, []);

  const setSaleOfferFreetimeError = useCallback(() => {
    dispatch({ type: SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_ERROR, payload: undefined });
  }, []);

  const setSaleOfferFreetimeReset = useCallback(() => {
    dispatch({ type: SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_RESET, payload: undefined });
  }, []);

  return {
    ...state.saleOfferFreetime,
    setSaleOfferFreetimeRequest,
    setSaleOfferFreetimeSuccess,
    setSaleOfferFreetimeError,
    setSaleOfferFreetimeReset,
  };
};
