import { TypeLocation } from 'src-new/domains/type-location.domain';
import { VehicleCategoryDomain } from 'src-new/domains/vehicle-category.domain';
import { VehicleTypeDomain } from 'src-new/domains/vehicle-type.domain';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { ISaleOfferListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list.domain';

export enum MatchType {
  PURCHASE = 'QUER COMPRAR',
  SALE = 'QUER VENDER',
}

export interface IMatchesKanbanListDomain {
  items: Array<IMatchesKanbanDetails>;
  totalItems: number;
}

export interface IMatchesKanbanCompany {
  id: number;
  name: string;
  image: string;
}

export interface IMatchesKanbanLocation {
  id: number;
  name: string;
  city: string;
  uf: string;
  type: TypeLocation;
}

export interface IMatchesKanbanVehicle {
  type: VehicleTypeDomain;
  category: VehicleCategoryDomain;
}

export interface IMatchesKanbanCapacity {
  weight: number;
  pallet: number;
  cubage?: number;
}

export interface IMatchesKanbanDetails {
  id: number;
  offerTypeId: number;
  isBackhaulPartner: boolean;
  matchOffersRoutesId: number;
  type: MatchType;
  company: IMatchesKanbanCompany;
  origin: IMatchesKanbanLocation;
  destiny: IMatchesKanbanLocation;
  vehicle: IMatchesKanbanVehicle;
  capacity: IMatchesKanbanCapacity;
  freightValue: number;
  totalSchedulesBackhaul: string;
  additionalDistance: number;
  isFavorite?: boolean;
  invoicedBy?: string;
  scheduleType?: string;
  operationType?: string;
  co2Economy?: number;
  purchaseOffer: IPurchaseOfferListDomain;
  saleOffer: ISaleOfferListDomain;
  saleOfferTariffId: number;
  purchaseOfferId: number;
  saleOfferId: number;
}
