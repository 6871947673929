import React from 'react';

import FormUpdateCompany from './components/company-update-form/company-update-form';
import { CompanyUpdateContainerStyled } from './company-update-page.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const CompanyUpdateView: React.FC = () => {
  return (
    <CompanyUpdateContainerStyled>
      <HeaderPage breadcrumbList={['Configurações', 'Empresa']} hasBackButton />
      <Title title="Atualizar Empresa" hasTrace isForm />
      <FormUpdateCompany />
    </CompanyUpdateContainerStyled>
  );
};

export default CompanyUpdateView;
