import { ColorsV2Type } from 'styles/types/colors';
import { Operation } from 'domain-v2/inputs/operation';

type HaulType = {
  label: Operation;
  color: ColorsV2Type;
};

type HaulTypeMap = {
  // eslint-disable-next-line no-unused-vars
  [key in Operation]: HaulType;
};

const defaultValue: HaulType = {
  label: 'TRACKING',
  color: ColorsV2Type.DarkGray,
};

export const convertHaulType = (haulTypes: Operation): HaulType => {
  const haulTypeMap: HaulTypeMap = {
    TRACKING: {
      label: 'TRACKING',
      color: ColorsV2Type.Aqua,
    },
    SPOT: {
      label: 'SPOT',
      color: ColorsV2Type.Blue,
    },
    'BACKHAUL EXTERNO': {
      label: 'BACKHAUL EXTERNO',
      color: ColorsV2Type.Blue,
    },
    'BACKHAUL INTERNO': {
      label: 'BACKHAUL INTERNO',
      color: ColorsV2Type.Aqua,
    },
    'BACKHAUL PARCEIRO': {
      label: 'BACKHAUL PARCEIRO',
      color: ColorsV2Type.Aqua,
    },
    'BACKHAUL EXTERNO SPOT': {
      label: 'BACKHAUL EXTERNO',
      color: ColorsV2Type.Blue,
    },
  };

  return haulTypeMap[haulTypes] || defaultValue;
};
