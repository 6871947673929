import { IOccurrenceEntityStatus } from 'pages/occurrence/services/entities/occurrence.entity';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): IOccurrenceEntityStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativos':
      return 'active';
    case 'Inativos':
      return 'inactive';
    case 'Excluídos':
      return 'deleted';
  }
};
