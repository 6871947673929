import { FC, Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { ScheduleDetailsBody } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-body/schedule-details-body.component';
import { ScheduleDetailsExecution } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/schedule-details-execution.component';
import { ScheduleFinance } from './components/schedule-finance/schedule-finance.component';
import { ScheduleDetailsTabsView } from './schedule-details-tabs-view.component';
import { ScheduleMaps } from './components/schedule-maps/schedule-maps.component';
import { useLocation } from 'react-router-dom';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const ScheduleDetailsTabs: FC = () => {
  const { scheduleDetails, scheduleList } = useContext(ScheduleContext);
  const { setScheduleDetailsActiveTabName } = scheduleDetails.activeTabName;
  const { modalOptions, setModalOpen } = scheduleList.modalsProps;
  const location = useLocation();

  const activeTabName = useMemo(
    (): string => scheduleDetails.activeTabName.activeTabName,
    [scheduleDetails.activeTabName.activeTabName],
  );

  const handleOpenModal = useCallback(() => {
    setModalOpen({
      open: true,
      ids: [scheduleDetails.scheduleDetails.id],
      modalType: 'occurrences',
    });
  }, [scheduleDetails.scheduleDetails.id, setModalOpen]);

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Agendamento',
        content: <ScheduleDetailsBody />,
      },
      {
        name: 'Execução',
        content: <ScheduleDetailsExecution />,
      },
      {
        name: 'Financeiro',
        content: <ScheduleFinance />,
      },
      {
        name: 'Mapa',
        content: <ScheduleMaps />,
      },
      {
        name: 'Ocorrências',
        content: <Fragment />,
      },
    ];
  }, []);

  const handleChangeTab = useCallback(
    (tabName: string) => {
      if (tabName === 'Ocorrências') {
        handleOpenModal();
      } else {
        setScheduleDetailsActiveTabName(tabName);
      }
    },
    [handleOpenModal, setScheduleDetailsActiveTabName],
  );

  useEffect(() => {
    const tabName = location.state?.activeTab ?? 'Agendamento';

    setScheduleDetailsActiveTabName(tabName);
  }, [location.state?.activeTab, scheduleDetails.scheduleDetails.statusCode, setScheduleDetailsActiveTabName]);

  return (
    <ScheduleDetailsTabsView
      tabsContent={tabsContent}
      modalOptions={modalOptions}
      activeTab={activeTabName}
      handleChangeTab={handleChangeTab}
    />
  );
};
