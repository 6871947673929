import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ModalMatchDetails } from '../../../types';
import * as S from './styled';
import { convertDecimalFormat } from 'utils-v2/converters/decimal';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getIcmsService } from 'utils/get-icms/service/get-icms.service';
import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { getGrisAdvaloremValueHook } from 'hooks/gris-advalorem';
import { MatchRegisterInput } from 'domain-v2/match/register';
import { IUser } from 'domain/login';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { ICompany } from 'domain/company';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';

interface TransportRouterDetailsProps {
  transportRouter: ModalMatchDetails;
  hasIdlenessValue: boolean;
  company?: ICompany;
  userCompany?: ICompany;
  user?: IUser;
  companyLogo?: string;
  valueError: boolean;
}

export const TransportRouterDetails: FC<TransportRouterDetailsProps> = ({
  transportRouter,
  hasIdlenessValue,
  userCompany,
  user,
  companyLogo,
  company,
  valueError,
}) => {
  const { values, setFieldValue } = useFormikContext<MatchRegisterInput>();

  const [icms, setIcms] = useState<number>(0);
  const [grisAdvalorem, setGrisAdvalorem] = useState<number>(0);
  const [grisAdvaloremPercent, setGrisAdvaloremPercent] = useState<{
    gris: number;
    advalorem: number;
  }>();

  const getIcmsValue = useCallback(async () => {
    try {
      const response = await getIcmsService({
        ufOrigin: transportRouter.origin.uf,
        ufDestiny: transportRouter.destination.uf,
      });
      setIcms(response);
    } catch (err: any) {
      showMessageFormatted({
        message: err,
        type: 'error',
      });
    }
  }, [transportRouter]);

  const companyImage = useMemo(() => {
    if (companyLogo) {
      return companyLogo;
    }

    if (transportRouter.company.id === user?.companyId || user?.profile == 'logshare') {
      return companyLogo;
    }

    return 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/logshare.png';
  }, [companyLogo, transportRouter.company.id, user?.companyId, user?.profile]);

  const freightValues = useMemo(() => {
    const totalFreight = values.cost.freightValue + (grisAdvalorem || 0);

    const icmsValue = totalFreight / (1 - icms / 100) - totalFreight;

    const totalService = totalFreight + icmsValue;

    return { icmsValue, totalService, totalFreight };
  }, [values.cost.freightValue, icms, grisAdvalorem]);

  useEffect(() => {
    (async () => {
      const grisAdvaloremValue = getGrisAdvaloremValueHook({
        company: {
          gris: Number(userCompany?.gris),
          advalorem: Number(userCompany?.advalorem),
        },
        mechValue: values.cargo.merchValue,
      });

      setGrisAdvaloremPercent({
        gris: Number(userCompany?.gris),
        advalorem: Number(userCompany?.advalorem),
      });

      setGrisAdvalorem(grisAdvaloremValue);
    })();
  }, [userCompany, values.cargo.merchValue]);

  useEffect(() => {
    if (transportRouter.freightValue > 0) {
      setFieldValue('cost.freightValue', transportRouter.freightValue);
    } else {
      setFieldValue('cost.freightValue', transportRouter.logshareValue);
    }
  }, [setFieldValue, transportRouter.freightValue, transportRouter.logshareValue]);

  useEffect(() => {
    if (!transportRouter.origin.uf || !transportRouter.destination.uf) return;

    getIcmsValue();
  }, [getIcmsValue, transportRouter.destination.uf, transportRouter.origin.uf]);

  useEffect(() => {
    setFieldValue('cost.grisAdvalorem', grisAdvalorem || 0);
    setFieldValue('cost.freightTotal', freightValues.totalFreight);
    setFieldValue('cost.icms', freightValues.icmsValue);
    setFieldValue('cost.otherTaxes', 0);
    setFieldValue('cost.totalService', freightValues.totalService);
  }, [
    freightValues.icmsValue,
    freightValues.totalFreight,
    freightValues.totalService,
    grisAdvalorem,
    setFieldValue,
    values.cost.freightValue,
  ]);

  return (
    <S.WrapperTransportRouteDetails>
      <S.TitleContainer>
        <S.TitleForm>DETALHES DA OFERTA DE VENDA</S.TitleForm>

        <S.WrapperCompanyImage src={companyImage} />
      </S.TitleContainer>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Origem</S.TitleValues>
          <S.TextValue>
            {transportRouter.origin.city.toUpperCase()} - {transportRouter.origin.uf.toUpperCase()}
          </S.TextValue>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Destino</S.TitleValues>
          <S.TextValue>
            {transportRouter.destination.city.toUpperCase()} - {transportRouter.destination.uf.toUpperCase()}
          </S.TextValue>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Veículo</S.TitleValues>
          <S.TextValue>
            {`${convertVehicleTypeTypeToName(transportRouter.vehicle.type)} - ${convertVehicleCategoryTypeToName(
              transportRouter.vehicle.category,
            )}`}
          </S.TextValue>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Capacidade</S.TitleValues>
          <S.TextValue>
            {`${convertDecimalFormat.format(transportRouter.weight ?? 0)} KG | ${
              transportRouter.pallets ?? '-'
            } PLTS | ${transportRouter.cubage ?? '-'} M³`}
          </S.TextValue>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperTitleProposalValue>
          <S.TitleForm>
            {transportRouter.exclusiveRate && !hasIdlenessValue ? 'PROPOSTA ACORDADA' : 'INFORME SUA PROPOSTA'}
          </S.TitleForm>
        </S.WrapperTitleProposalValue>
        <InputCurrency
          id="cost.freightValue"
          name="cost.freightValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          decimalSeparator=","
          step={1}
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', converterNumberPtBrToUs(value ?? '0'))}
          value={Number.parseFloat(values.cost.freightValue.toString()).toFixed(2).toString()}
          hasError={valueError && values.cost.freightTotal === 0}
          errorMessage="Campo Obrigatório"
          disabled={transportRouter.exclusiveRate && !hasIdlenessValue}
        />
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperOfferValues>
          <S.TextValue>FRETE VALOR</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(values.cost.freightValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>PEDÁGIO</S.TextValue>
          <S.SubTitle>A INCLUIR</S.SubTitle>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <div style={{ display: 'flex', gap: 5 }}>
            <S.TextValue>GRIS/ADVALOREM</S.TextValue>
            <Tooltip
              title={
                <div>
                  <p style={{ fontSize: '11px' }}>
                    Gris: {convertDecimalFormat.format(grisAdvaloremPercent?.gris || 0)}%
                  </p>
                  <p style={{ fontSize: '11px' }}>
                    Advalorem: {convertDecimalFormat.format(grisAdvaloremPercent?.advalorem || 0)}%
                  </p>
                </div>
              }
              placement="right"
            >
              <div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </div>
            </Tooltip>
          </div>

          <S.TextValue>{convertCurrencyFormat.format(grisAdvalorem || 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <div style={{ display: 'flex', gap: 5 }}>
            <S.TextValue>FREETIME</S.TextValue>
            <Tooltip
              title={
                <div>
                  <p style={{ fontSize: '11px' }}>Freetime: {company?.freeTime}</p>
                  <p style={{ fontSize: '11px' }}>
                    Valor da Hora Parado: {convertCurrencyFormat.format(company?.hourlyFine ?? 0)}
                  </p>
                  <p style={{ fontSize: '11px' }}>
                    Valor da Diária Parado: {convertCurrencyFormat.format(company?.dailyFine ?? 0)}
                  </p>
                </div>
              }
              placement="right"
            >
              <div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </div>
            </Tooltip>
          </div>

          <S.TextValue>{convertCurrencyFormat.format(0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>FRETE TOTAL</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(freightValues.totalFreight || 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>ICMS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(freightValues.icmsValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>OUTROS IMPOSTOS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(values.cost.otherTaxes ?? 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>TOTAL A PAGAR</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(freightValues.totalService || 0)}</S.TextValue>
        </S.WrapperOfferValues>
      </LineSectionLayout>
    </S.WrapperTransportRouteDetails>
  );
};
