import React from 'react';

import {
  LogSharePro,
  LogShareText,
  ModalBodyStyled,
  ModalContainerStyled,
  ModalHeaderCloseButtonStyled,
  ModalHeaderDescriptionStyled,
  ProText,
  ContainerTextsWrap,
  Text,
  TextContainer,
  WrapperModalContainerStyled,
  InputContainer,
  FirstBlockText,
  SecondBlockText,
  LeftContainerModal,
  RightContainerModal,
  CloseModalStyled,
} from './modal-assign-plan-styled';

import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';
import CrownIconIntern from 'assets/icons/crown.icon-intern';
import { CheckIcon } from 'assets/icons/check.icon';

interface Props {
  handleCloseModal: () => void;
  setForm: (type: string, value: string) => void;
  form: any;
  handleSubmit: () => void;
  openModal: boolean;
}

const AllocationModalView: React.FC<Props> = ({ handleCloseModal, setForm, form, handleSubmit, openModal }) => {
  return (
    <WrapperModalContainerStyled show={openModal}>
      <ModalContainerStyled>
        <ModalHeaderCloseButtonStyled>
          <ModalHeaderDescriptionStyled onClick={handleCloseModal}>
            <div
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #d9d9d9',
              }}
            >
              <CloseModalStyled onClick={handleCloseModal}>X</CloseModalStyled>
            </div>
          </ModalHeaderDescriptionStyled>
        </ModalHeaderCloseButtonStyled>
        <ModalBodyStyled>
          <LeftContainerModal>
            <LogSharePro>
              <LogShareText>LogShare</LogShareText>
              <ProText>pro</ProText>
              <CrownIconIntern />
            </LogSharePro>
            <ContainerTextsWrap>
              <FirstBlockText>
                <Text style={{ wordWrap: 'break-word' }}>
                  Usuários pro otimizam em até <b>28% seu custo operacional </b>
                  monetizando ociosidade das suas malhas logísticas
                </Text>
              </FirstBlockText>
              <SecondBlockText>
                <TextContainer>
                  <CheckIcon />
                  <Text>Vendas de ociosidade</Text>
                </TextContainer>
                <TextContainer>
                  <CheckIcon />
                  <Text>Fretes STL e LTL</Text>
                </TextContainer>
                <TextContainer>
                  <CheckIcon />
                  <Text>Tracking</Text>
                </TextContainer>
              </SecondBlockText>
            </ContainerTextsWrap>
          </LeftContainerModal>

          <RightContainerModal>
            <Text>
              <b>Entre em contato conosco</b>{' '}
            </Text>
            <InputContainer>
              <InputField
                label="Nome *"
                id="name"
                name="name"
                type="text"
                onChange={(e: any) => setForm(e.target.name, e.target.value)}
                value={form.name}
              />
              <InputField
                label="Email *"
                id="email"
                name="email"
                type="text"
                onChange={(e: any) => setForm(e.target.name, e.target.value)}
                value={form.email}
              />
              <InputFieldPhone
                label="Telefone *"
                id="phone"
                name="phone"
                type="text"
                onChange={(e: any) => setForm(e.target.name, e.target.value)}
                value={form.phone}
              />
              <div style={{ height: 26, width: '100%' }} />
              <Button callback={handleSubmit} title="TENHO INTERESSE" bgColor="aqua" size="large" />
            </InputContainer>
          </RightContainerModal>
        </ModalBodyStyled>
      </ModalContainerStyled>
    </WrapperModalContainerStyled>
  );
};

export default AllocationModalView;
