import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { ChatUnreadMessagesActionType } from '../../chat.action';

export const chatUnreadMessagesReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  switch (action.type) {
    case ChatUnreadMessagesActionType.CHAT_SET_UNREAD_MESSAGES:
      return {
        ...state,
        chatUnreadMessages: {
          ...state.chatUnreadMessages,
          unreadMessages: action.payload,
        },
      };
    default:
      return state;
  }
};
