import styled, { css } from 'styled-components';

import { ModalConfirmationTypes } from './modal-confirmation.type';

type ContainerProps = {
  overflow?: boolean;
  isRight?: boolean;
  legacy?: boolean;
  dateIndex?: boolean;
  rightSecondTitle?: boolean;
  size?: 'large' | 'medium' | 'small' | 'very-small';
  type?: ModalConfirmationTypes;
};

export const ContainerStyled = styled.div<ContainerProps>`
  ${({ isRight = false, legacy, dateIndex }) => css`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: ${isRight ? 'right' : 'center'};
    justify-content: ${isRight ? 'right' : 'center'};
    position: absolute;
    padding: ${isRight ? '' : '20px '};
    background-color: #05050581;
    z-index: ${dateIndex ? '1' : legacy ? '2' : '999'};
    color: #000;
  `}
`;

export const ContentStyled = styled.div<ContainerProps>`
  ${({ overflow = false, isRight = false, size, type }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: ${isRight ? '5px 0 0 5px' : '5px'};
    padding: 30px;

    overflow-y: ${overflow ? 'hidden' : 'auto'};
    min-height: 190px;
    max-height: ${isRight ? '100vh' : '600px'};

    overflow-y: auto;

    ${isRight &&
    css`
      width: 400px !important;
      border-radius: 5px;
    `}

    ${size === 'large' &&
    css`
      width: 1200px;
      border-radius: 5px;
    `}

    ${size === 'medium' &&
    css`
      width: 800px;
      border-radius: 5px;
    `}

    ${size === 'small' &&
    css`
      max-width: 630px;
      border-radius: 5px;
    `}

    ${type === 'delete' &&
    css`
      align-items: center;
      gap: 20px;
      overflow-y: ${overflow ? 'hidden' : 'auto'};
    `}

    ${type === 'e-mail' &&
    css`
      align-items: center;
      gap: 20px;
      overflow-y: ${overflow ? 'hidden' : 'auto'};
    `}

    ${type === 'finished' &&
    css`
      align-items: center;
      gap: 20px;
      overflow-y: ${overflow ? 'hidden' : 'auto'};
    `}
  `}
`;

export const ContainerHeaderStyled = styled.div<ContainerProps>`
  ${({ rightSecondTitle }) => css`
    display: ${rightSecondTitle ? '' : 'flex'};
    justify-content: ${rightSecondTitle ? '' : 'space-between'};
  `}
`;

export const ContainerTitlesStyled = styled.div<ContainerProps>`
  ${({ type }) => css`
    display: flex;
    flex-direction: column;

    ${type === 'delete'
      ? css`
          align-items: center;
          gap: 20px;
        `
      : `padding-bottom: 20px;
    `}

    ${type === 'e-mail'
      ? css`
          align-items: center;
          gap: 20px;
        `
      : `padding-bottom: 20px;
    `}

    ${type === 'finished'
      ? css`
          align-items: center;
          gap: 20px;
        `
      : `padding-bottom: 20px;
  `}
  `}
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const SecondTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin-right: 10px;
  font-weight: 400;
  margin-top: 10px;
  color: rgb(111, 111, 116);
`;

export const AddictionalTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  color: red;
  text-align: center;
  margin-right: 10px;
`;

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    margin-right: 10px;
    font-weight: ${theme.font.bold};
  `}
`;

export const DescriptionStyled = styled.h2`
  font-size: 12px;
  margin-top: 5px;
  color: black;
`;

export const ButtonContainer = styled.div<ContainerProps>`
  ${() => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    gap: 10px;
  `}
`;
