import { VehicleTypeNameWithEmpty, VehicleTypeWithEmpty } from 'domain/global';

export const convertVehicleType = (
  vehicleType: VehicleTypeWithEmpty | string | undefined,
): VehicleTypeNameWithEmpty | 'NÃO MAPEADO' => {
  switch (vehicleType) {
    case '3/4':
      return '3/4';
    case 'bitrem':
      return 'BITREM';
    case 'bitruck':
      return 'BITRUCK';
    case 'carreta':
      return 'CARRETA';
    case 'carreta_ls':
      return 'CARRETA LS';
    case 'carreta_sete_eixos':
      return 'CARRETA 7 EIXOS';
    case 'complemento':
      return 'COMPLEMENTO';
    case 'rodotrem':
      return 'RODOTREM';
    case 'romeu&julieta':
      return 'ROMEU & JULIETA';
    case 'romeu_julieta':
      return 'ROMEU & JULIETA';
    case 'toco':
      return 'TOCO';
    case 'truck':
      return 'TRUCK';
    case 'utilitario':
      return 'UTILITÁRIO';
    case 'van':
      return 'VAN';
    case 'vuc':
      return 'VUC';
    case 'vanderleia':
      return 'VANDERLÉIA';
    default:
      return '';
  }
};
