import { FC } from 'react';
import { SaleOfferUpdateForm } from '../sale-offer-update-form/sale-offer-update-form.component';
import * as S from './sale-offer-update-page-body-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Title from 'components/title/title';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';

interface ISaleOfferUpdatePageBodyViewProps {
  isLoading: boolean;
  isError: boolean;
}

export const SaleOfferUpdatePageBodyView: FC<ISaleOfferUpdatePageBodyViewProps> = ({ isLoading, isError }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <S.Wrapper>
      <HeaderPage breadcrumbList={['Backhaul', 'Oferta de Venda']} hasBackButton />
      <Title title="Nova Oferta" hasTrace isForm />
      <SaleOfferUpdateForm />
    </S.Wrapper>
  );
};
