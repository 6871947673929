import React, { ReactNode, useContext, useMemo } from 'react';

import FreightTableView from './freight-table-view';

import { UserContext } from 'state/user-context';
import { FreightContentTableMountProps } from 'pages/freight/utils/freight-table-content';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IFreightTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToDetailsPage: (freightId: string) => void;
  isLoading: boolean;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: () => void;
  arrowOrder?: string | ReactNode;
  notAbsolute?: boolean;
  filters?: FreightContentTableMountProps;
  orderSelected?: { orderBy: string; sortDirection: string };
  zIndex?: boolean;
}

const FreightTable: React.FC<IFreightTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  orderSelected,
  goToDetailsPage,
  clickOrderTableBy = () => null,
  isLoading,
  arrowOrder,
  notAbsolute,
  clickSortDirection = () => null,
  filters,
  zIndex,
}) => {
  const { isAdmin } = useContext(UserContext);

  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.1,
      },
      {
        value: 'ID',
        flex: isAdmin ? 0.2 : 0.15,
      },

      isAdmin
        ? {
            value: 'Solicitado Por',
            flex: 0.5,
          }
        : {
            value: '',
            flex: 0,
          },

      {
        value: 'Categoria',
        flex: 0.3,
      },

      {
        value: 'Data Coleta',
        flex: isAdmin ? 0.5 : 0.45,
      },
      {
        value: 'Data Entrega',
        flex: isAdmin ? 0.5 : 0.45,
      },
      {
        value: 'Origem',
        flex: 0.8,
      },
      {
        value: 'Destino',
        flex: isAdmin ? 0.8 : 0.85,
      },
      {
        value: 'Veículo',
        flex: isAdmin ? 0.4 : 0.45,
      },
      {
        value: 'Operado Por',
        flex: 0.5,
      },
      {
        value: 'Status',
        flex: isAdmin ? 0.5 : 0.8,
      },
      {
        value: '',
        flex: 0.1,
      },
      {
        value: '',
        flex: 0.1,
      },

      filters?.hasNewOccurrence
        ? {
            value: '',
            flex: 0.48,
          }
        : {
            value: '',
            flex: 0,
          },
    ];
  }, [isAdmin, filters]);

  return (
    <FreightTableView
      orderSelected={orderSelected}
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      isLoading={isLoading}
      clickOrderTableBy={clickOrderTableBy}
      clickRowAction={goToDetailsPage}
      notAbsolute={notAbsolute}
      clickSortDirection={clickSortDirection}
      zIndex={zIndex}
    />
  );
};

export default FreightTable;
