import API from 'utils/API/API';
import { convertUserStatusDomainToEntity } from 'pages/user/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { IUserStatus } from 'domain/user';

interface LocatioReportExcelServiceProps {
  search: string;
  status: IUserStatus | undefined;
}

export const userReportExcelService = async ({
  search,
  status,
}: LocatioReportExcelServiceProps): Promise<BlobPart | undefined> => {
  try {
    const convertedStatus = status ? convertUserStatusDomainToEntity(status) : status;

    const { data } = await API.get('v2/users/export', {
      params: {
        status: convertedStatus,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar localidade');
  }
};
