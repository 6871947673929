import React, { useMemo } from 'react';
import { TimerClockIcon } from 'assets/icons/timer-clock.icon';
import { Tag } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface ITagTimerViewProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const TagTimerView: React.FC<ITagTimerViewProps> = ({ days, hours, minutes, seconds }) => {
  const daysLabel = useMemo(() => {
    if (days > 0) {
      return `${days} dias`;
    }

    return '';
  }, [days]);

  const tagLabel = useMemo(() => {
    const hoursLabel = hours.toString().padStart(2, '0');
    const minutesLabel = minutes.toString().padStart(2, '0');
    const secondsLabel = seconds.toString().padStart(2, '0');

    return `${daysLabel} ${hoursLabel}:${minutesLabel}:${secondsLabel}`;
  }, [daysLabel, hours, minutes, seconds]);

  return (
    <Tag
      label={tagLabel}
      color={ThemeColorsV2Type.Red}
      display="inline-flex"
      size="md"
      variant="ghost"
      LeftIcon={<TimerClockIcon />}
    />
  );
};
