import { useCallback, useReducer } from 'react';
import { chatInitialState } from '../../chat.constants';
import { ChatEligibleUsersActionType } from 'src-new/contexts/chat/chat.action';
import { IChatEligibleUsers } from 'src-new/contexts/chat/types/chat-eligible-users-chat.types';
import { chatEligibleUsersReducer } from 'src-new/contexts/chat/reducers/chat-eligible-users/chat-eligible-users.reducer';
import { IEligibleUserDomain } from 'src-new/domains/eligible-user-chat.domain';

export const useChatEligibleUsersValuesContextValues = (): IChatEligibleUsers => {
  const [state, dispatch] = useReducer(chatEligibleUsersReducer, chatInitialState);

  const setEligibleUsersChatRequest = useCallback(() => {
    dispatch({ type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_REQUEST, payload: undefined });
  }, []);

  const setEligibleUsersChatSuccess = useCallback((users: Array<IEligibleUserDomain>) => {
    dispatch({ type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_SUCCESS, payload: users });
  }, []);

  const setEligibleUsersChatError = useCallback(() => {
    dispatch({ type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_ERROR, payload: undefined });
  }, []);

  const setValidateThreadRequest = useCallback(() => {
    dispatch({
      type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_REQUEST,
      payload: undefined,
    });
  }, []);

  const setValidateThreadSuccess = useCallback((haveCreated: boolean) => {
    dispatch({
      type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_SUCCESS,
      payload: haveCreated,
    });
  }, []);

  const setValidateThreadError = useCallback(() => {
    dispatch({
      type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_ERROR,
      payload: undefined,
    });
  }, []);

  const setEligibleUsersChatReset = useCallback(() => {
    dispatch({ type: ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_RESET, payload: undefined });
  }, []);

  return {
    ...state.chatEligibleUsers,
    setEligibleUsersChatRequest,
    setEligibleUsersChatSuccess,
    setEligibleUsersChatError,
    setEligibleUsersChatReset,
    validateThreadsActions: {
      ...state.chatEligibleUsers.validateThreadsActions,
      setValidateThreadRequest,
      setValidateThreadSuccess,
      setValidateThreadError,
    },
  };
};
