import { weighingTottensDetailsDomainInitialValues } from 'src-new/mobile/pages/weighing-totten/pages/weighing-totten-details/domain/weighing-totten-details/weighing-totten-details-initial-values.constants';
import { IWeighingTottensDetailsActions } from '../../types/weighing-tottens-details.types';
import { IWeighingTottensContext } from '../../types/weighing-tottens.types';
import { WeighingTottensDetailsActionType } from '../../weighing-tottens.action';

export const weighingTottensDetailsReducer = (
  state: IWeighingTottensContext,
  action: IWeighingTottensDetailsActions,
): IWeighingTottensContext => {
  switch (action.type) {
    case WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_REQUEST:
      return {
        ...state,
        weighingTottensDetails: {
          ...state.weighingTottensDetails,
          statusRequest: 'PROCESSING',
          weighingTottensDetails: weighingTottensDetailsDomainInitialValues,
        },
      };
    case WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_SUCCESS:
      return {
        ...state,
        weighingTottensDetails: {
          ...state.weighingTottensDetails,
          statusRequest: 'SUCCESS',
          weighingTottensDetails: action.payload,
        },
      };
    case WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_ERROR:
      return {
        ...state,
        weighingTottensDetails: {
          ...state.weighingTottensDetails,
          statusRequest: 'ERROR',
          weighingTottensDetails: weighingTottensDetailsDomainInitialValues,
        },
      };
    case WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_RESET:
      return {
        ...state,
        weighingTottensDetails: {
          ...state.weighingTottensDetails,
          statusRequest: 'INITIAL',
          weighingTottensDetails: weighingTottensDetailsDomainInitialValues,
        },
      };
    case WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_FILTERS:
      return {
        ...state,
        weighingTottensDetails: {
          ...state.weighingTottensDetails,
          weighingTottensDetailsFiltersProps: {
            ...state.weighingTottensDetails.weighingTottensDetailsFiltersProps,
            filters: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
