import { useCallback, useContext } from 'react';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { showToast } from 'components/toast/toast';
import { getEligibleUsersChatService } from 'src-new/services/chat/get-eligible-users-chat/get-eligible-users-chat.service';
import { GetEligibleUsersChatServiceProps } from 'src-new/services/chat/get-eligible-users-chat/get-eligible-users-chat.types';

export const useGetEligibleUsersChat = () => {
  const { chatEligibleUsers } = useContext(ChatContext);

  return useCallback(
    async (props: GetEligibleUsersChatServiceProps): Promise<void> => {
      chatEligibleUsers.setEligibleUsersChatRequest();
      try {
        const eligibleUsers = await getEligibleUsersChatService(props);

        chatEligibleUsers.setEligibleUsersChatSuccess(eligibleUsers);
      } catch (error) {
        chatEligibleUsers.setEligibleUsersChatError();
        showToast({
          message: 'Ocorreu um erro ao obter participantes da conversa',
          type: 'error',
        });
      }
    },
    [chatEligibleUsers],
  );
};
