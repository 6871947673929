import { IListPage } from 'src-new/domains/list-page.domain';
import API from 'utils/API/API';
import { IScheduleListResponse } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/services/get-schedule-list/get-schedule-list.types';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { IScheduleListTotalRow } from 'src-new/pages/execution/pages/schedule/types/schedule-list-total-row/schedule-list-total-row.types';

export type ScheduleListServiceResponse = IListPage<IScheduleListResponse> & { totalRow: IScheduleListTotalRow };

export const getScheduleListService = async (
  params: IScheduleListParams,
): Promise<IListPage<IScheduleListResponse> & { totalRow: IScheduleListTotalRow }> => {
  const { data } = await API.get<ScheduleListServiceResponse>('v2/schedules/list-v2', {
    params: {
      ...params,
      status: undefined,
      limit: 100,
    },
  });

  return {
    items: data.items,
    meta: data.meta,
    totalRow: data.totalRow,
  };
};
