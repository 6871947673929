import React, { useCallback, useContext, useMemo } from 'react';
import { HeaderChatModalTableView } from './header-chat-modal-table-view.component';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { FlowConversation } from 'src-new/contexts/chat-button/types/chat-button.types';

interface HeaderChatModalTableProps {
  infiniteScrollProps: IInfiniteScrollTable;
}

export const HeaderChatModalTable: React.FC<HeaderChatModalTableProps> = ({ infiniteScrollProps }) => {
  const { chatConversationList, chatUnreadMessages } = useContext(ChatContext);
  const { chatActions } = useContext(ChatButtonContext);

  const getUnreadChat = useCallback(
    (chatId: string) => {
      return (
        chatUnreadMessages.unreadMessages?.find((unread) => unread.conversation.id === chatId)?.unreadMessageCount ?? 0
      );
    },
    [chatUnreadMessages.unreadMessages],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: 'Não Lidas',
      order: 'DESC',
      handleClick: () => undefined,
    };
  }, []);

  const handleOpenChat = useCallback(
    (id: string, name: string) => {
      const flowConversation: FlowConversation =
        id.split('-')[1] === 'proposal' ? 'CONVERSATION_LIST_OFFER' : 'SCHEDULE';

      chatActions.setChatOpen({
        chatId: id,
        flowConversation,
        name: id.split('-')[1] === 'proposal' ? 'NEGOCIAÇÃO' : name,
      });
    },
    [chatActions],
  );

  const conversationsListChat = useMemo(() => chatConversationList.conversations, [chatConversationList.conversations]);

  return (
    <HeaderChatModalTableView
      conversationsListChat={conversationsListChat}
      orderingColumns={orderingColumns}
      handleOpenChat={handleOpenChat}
      getUnreadChat={getUnreadChat}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
