import { SaleOfferUpdateActionType } from '../../sale-offer.action';
import { ISaleOfferContext } from '../../types/sale-offer.types';
import { ISaleOfferUpdateActions } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-update.types';

export const saleOfferUpdateReducer = (state: ISaleOfferContext, action: ISaleOfferUpdateActions): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferUpdateActionType.SALE_OFFER_UPDATE_REQUEST:
      return {
        ...state,
        saleOfferUpdate: {
          ...state.saleOfferUpdate,
          statusRequest: 'PROCESSING',
        },
      };
    case SaleOfferUpdateActionType.SALE_OFFER_UPDATE_SUCCESS:
      return {
        ...state,
        saleOfferUpdate: {
          ...state.saleOfferUpdate,
          statusRequest: 'SUCCESS',
        },
      };
    case SaleOfferUpdateActionType.SALE_OFFER_UPDATE_ERROR:
      return {
        ...state,
        saleOfferUpdate: {
          ...state.saleOfferUpdate,
          statusRequest: 'ERROR',
        },
      };
    case SaleOfferUpdateActionType.SALE_OFFER_UPDATE_RESET:
      return {
        ...state,
        saleOfferUpdate: {
          ...state.saleOfferUpdate,
          statusRequest: 'INITIAL',
        },
      };
    default:
      return state;
  }
};
