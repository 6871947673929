import styled from 'styled-components';

export const HeaderContainerStyled = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: rgb(3, 5, 23);
  background-color: rgb(255, 255, 255);
`;

export const LabelFieldContainerStyled = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  font-size: 11px;
  font-weight: 600;
  color: rgb(3, 5, 23);
  background-color: rgb(255, 255, 255);
  align-content: center;
`;

export const AlertsContainerStyled = styled.div`
  display: grid;
  margin-top: 30px;
`;

export const ContentContainerStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: 20px;
`;

export const FieldContainerStyled = styled.div`
  display: flex;
`;
