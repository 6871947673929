import { useCallback, useContext } from 'react';

import { Hook } from './use-card-list.hooks';

import { SchedulesContext } from 'pages/schedule/context/schedule-register/schedule.context';

const useCardList: Hook = () => {
  const { setCardsList } = useContext(SchedulesContext);

  return useCallback(
    ({ cards }) => {
      setCardsList(cards);
    },
    [setCardsList],
  );
};

export { useCardList };
