import styled, { keyframes } from 'styled-components';

export const ButtonDownload = styled.button`
  position: absolute;
  width: 35px;
  height: 35px;
  right: 15px;
  bottom: 25px;
  border-style: none;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  background: rgba(53, 51, 51, 0.62);
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px 0;
  cursor: pointer;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerButton = styled.div`
  margin-left: 3px;
  background-color: transparent;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #d7d5d5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 0.7s linear infinite;
`;
