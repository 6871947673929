import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import FreightCargoPhotosSectionView from './freight-cargo-photos-section-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleFileUrl } from 'pages/freight/services/schedule-file-url/schedule-file-url.service';
import { useGetFileUrlScheduleByIdHook } from 'pages/freight/hooks/use-url-image/use-url-image.hook';

interface Props {
  statusCode?: string;
}

const FreightCargoPhotosSection: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [urlsPhotos, setUrlsPhotos] = useState<Array<ScheduleFileUrl>>();
  const getFileUrlImage = useGetFileUrlScheduleByIdHook();
  const { id } = useParams();

  const handleGetFileUrlImage = useCallback(async () => {
    if (id && Number(id)) {
      try {
        setIsLoading(true);
        const response = await getFileUrlImage(id);
        if (response) {
          setUrlsPhotos(response);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: error,
          type: error,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [getFileUrlImage, id]);

  useEffect(() => {
    handleGetFileUrlImage();
  }, [handleGetFileUrlImage]);

  return (
    <FreightCargoPhotosSectionView
      photos={urlsPhotos}
      id={id}
      getImages={handleGetFileUrlImage}
      statusCode={props.statusCode}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );
};

export default FreightCargoPhotosSection;
