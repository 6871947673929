import dayjs from 'dayjs';
import {
  ISaleOfferDomain,
  ISaleOfferLocationsDomain,
  ISaleOfferShipperDomain,
  ISaleOfferShipperTariffDomain,
} from './sale-offer-details.domain';
import {IOptionsDomain} from 'src-new/domains/options.domain';

const saleOfferLocationsDetailsInitialValuesDomain: ISaleOfferLocationsDomain = {
  id: 0,
  type: 'CLIENT',
  name: '',
  cnpj: '',
  address: {
    city: '',
    uf: '',
    complement: '',
    neighborhood: '',
    numberHouse: '',
    street: '',
    cep: '',
  },
};

export const saleOfferShipperTariffDetailsInitialValuesDomain: ISaleOfferShipperTariffDomain = {
  id: 0,
  shipping: {
    id: 0,
    name: '',
  },
  vehicleType: '',
  vehicleCategory: '',
  weight: '0',
  pallets: '0',
  cubage: '0',
  frequency: '0',
  invoicedBy: 'logshare',
  freetime: {
    time: 0,
    dailyFine: 0,
    hourlyFine: 0,
  },
  gris: '0',
  advalorem: '0',
  valueShipping: '0.00',
  valueShipper: '0.00',
  feeLogshare: 0,
  valueLogshare: '0.00',
  valueTotal: 0.0,
  isActive: true,
};

export const saleOfferShipperDetailsInitialValuesDomain: ISaleOfferShipperDomain = {
  id: 0,
  partnerShipper: {
    id: 0,
    name: '',
  },
  initialDate: dayjs().toISOString(),
  finalDate: dayjs().add(5, 'years').toISOString(),
  tariff: [],
};

export const saleOfferFormDetailsInitialValuesDomain: ISaleOfferDomain = {
  id: 0,
  companyId: 0,
  origin: saleOfferLocationsDetailsInitialValuesDomain,
  destination: saleOfferLocationsDetailsInitialValuesDomain,
  operationType: 'IN',
  cargoType: 'FTL',
  prohibitedCargo: [''],
  prohibitedOtherCargo: '',
  saleOfferDetails: [saleOfferShipperDetailsInitialValuesDomain],
};

export const cargoTypeOptions: IOptionsDomain[] = [
  {value: undefined, label: ''},
  {value: 'FTL', label: 'FTL'},
  {value: 'LTL', label: 'LTL'},
  {value: 'STL', label: 'STL'},
];

export const prohibitedCargoOptions: Array<{ value: string; label: string }> = [
  {value: 'Alimentos Naturais', label: 'ALIMENTOS NATURAIS'},
  {value: 'Carga Viva', label: 'CARGA VIVA'},
  {value: 'Cigarros', label: 'CIGARROS'},
  {value: 'Eletrônicos', label: 'ELETRÔNICOS'},
  {value: 'Fertilizantes', label: 'FERTILIZANTES'},
  {value: 'Químicos', label: 'QUÍMICOS'},
  {value: 'Vestuário', label: 'VESTUÁRIO'},
  {value: 'Sem Restrição', label: 'SEM RESTRIÇÃO'},
  {value: 'Outros', label: 'OUTROS'},
];

export const invoicedByOptions: Array<{ value: 'transportadora' | 'logshare'; label: string }> = [
  {value: 'transportadora', label: 'TRANSPORTADORA'},
  {value: 'logshare', label: 'LOGSHARE'},
];
