import { homeCardsInitialValuesDomain } from '../../pages/home/domains/home-cards/home-cards-initial-values.domain';
import { IHomeContext, IHomePaginationActions } from './types/home.types';

const pageActionsInitialState: IHomePaginationActions = {
  isPageLimit: false,
  nextPage: undefined,
  setNextPage: () => undefined,
  setPageLimit: () => undefined,
};

export const homeInitialState: IHomeContext = {
  homeCards: {
    statusRequest: 'INITIAL',
    homeCards: homeCardsInitialValuesDomain,
    setHomeCardsRequest: () => undefined,
    setHomeCardsSuccess: () => undefined,
    setHomeCardsError: () => undefined,
    setHomeCardsReset: () => undefined,
  },
  homeMatches: {
    statusRequest: 'INITIAL',
    homeMatches: [],
    paginationActions: pageActionsInitialState,
    setHomeMatchesRequest: () => undefined,
    setHomeMatchesSuccess: () => undefined,
    setHomeMatchesError: () => undefined,
    setHomeMatchesReset: () => undefined,
  },
  homeBids: {
    statusRequest: 'INITIAL',
    homeBids: [],
    paginationActions: pageActionsInitialState,
    setHomeBidsRequest: () => undefined,
    setHomeBidsSuccess: () => undefined,
    setHomeBidsError: () => undefined,
    setHomeBidsReset: () => undefined,
  },
  homeModals: {
    open: false,
    homeModalType: '',
    setHomeModalsOpen: () => undefined,
    setHomeModalsClose: () => undefined,
  },
  setHomeResetContext: () => undefined,
};
