import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { ISaleOfferMatchListResponse } from '../../../../services/get-sale-offer-match-list/get-sale-offer-match-list.types';
import { ISaleOfferMatchListDomain } from '../../../../domains/sale-offer-match-list.domain';

export const convertSaleOfferMatchListResponseToDomain = (
  saleOfferMatchResponse: ISaleOfferMatchListResponse,
): ISaleOfferMatchListDomain => {
  return {
    ...saleOfferMatchResponse,
    id: saleOfferMatchResponse.purchaseOfferId,
    matchId: saleOfferMatchResponse.id,
    origin: {
      ...saleOfferMatchResponse.origin,
      address: {
        city: saleOfferMatchResponse.origin.city,
        uf: saleOfferMatchResponse.origin.uf,
      },
    },
    destination: {
      ...saleOfferMatchResponse.destination,
      address: {
        city: saleOfferMatchResponse.destination.city,
        uf: saleOfferMatchResponse.destination.uf,
      },
    },

    vehicle: {
      type: convertVehicleType(saleOfferMatchResponse.vehicle.type),
      category: convertVehicleCategory(saleOfferMatchResponse.vehicle.category),
    },
  };
};
