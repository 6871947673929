import { convertUserStatusDomainToEntity } from '../convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import { formatCelphoneNumberDomainToEntity } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { IFunctionalityEntity, IUpdateUserEntity } from 'pages/user/services/entities/user.entity';
import { ICreatedUser, SubPermissions } from 'domain/user';

const convertPermissionsToFunctionalities = (permissions: Array<SubPermissions>) => {
  const functionalities: Array<IFunctionalityEntity> = [];
  permissions.forEach((permission: SubPermissions) => {
    if (permission.value) {
      functionalities.push({
        id: permission.id,
        name: permission.name,
        profiles: permission.profiles,
        parent: permission.parent,
        routePath: permission.routePath,
      });
    }
  });
  return functionalities;
};

const convertFunctionalitiesDomainToEntity = (userDomain: ICreatedUser) => {
  const functionalities = [
    ...convertPermissionsToFunctionalities(userDomain.home),
    ...convertPermissionsToFunctionalities(userDomain.register),
    ...convertPermissionsToFunctionalities(userDomain.schedules),
    ...convertPermissionsToFunctionalities(userDomain.tracking),
    ...convertPermissionsToFunctionalities(userDomain.opportunities),
    ...convertPermissionsToFunctionalities(userDomain.execution),
    ...convertPermissionsToFunctionalities(userDomain.financial),
    ...convertPermissionsToFunctionalities(userDomain.services),
    ...convertPermissionsToFunctionalities(userDomain.settings),
  ];
  return functionalities;
};

export const convertUpdatedUserDomainToEntity = (userDomain: ICreatedUser): IUpdateUserEntity => {
  return {
    name: userDomain.name,
    email: userDomain.email,
    telephone: formatCelphoneNumberDomainToEntity(userDomain?.telephone),
    status: convertUserStatusDomainToEntity(userDomain.status),
    password: userDomain.password || '',
    passwordConfirmation: userDomain.passwordConfirmation || '',
    isMaster: userDomain.isMaster,
    functionalities: convertFunctionalitiesDomainToEntity(userDomain),
  };
};
