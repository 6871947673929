import { convertTypeEntityToDomainMapper } from '../convert-types-occurrence-entity-to-domain/convert-types-occurrence-entity-to-domain.mapper';
import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { IOccurrenceCreatedEntity } from 'pages/occurrence/services/entities/occurrence.entity';
import { IOccurrenceCreated } from 'domain/occurrence';

export const convertOccurrenceCreatedEntityToDomainMapper = (
  occurrenceEntity: IOccurrenceCreatedEntity,
): IOccurrenceCreated => ({
  id: Number(occurrenceEntity.id),
  description: occurrenceEntity.description,
  sla: occurrenceEntity.sla,
  status: convertStatusEntityToDomainMapper(occurrenceEntity.status),
  impact: convertTypeEntityToDomainMapper(occurrenceEntity.impact),
  responsibleSolution: occurrenceEntity.responsibleSolution ? occurrenceEntity.responsibleSolution : [],
  impactedList: occurrenceEntity.impactedList ? occurrenceEntity.impactedList : [],
  companyId: occurrenceEntity.companyId,
  treatmentSector: occurrenceEntity.treatmentSector,
});
