import styled from 'styled-components';

export const WrappedResume = styled.div`
  min-width: 300px;
  max-width: 300px;
  background: #fff; // #f9f9f9
  border-radius: 10px;
  overflow-y: auto;
  padding: 14px 17px;
  margin-top: 3px;
  max-height: 650px;
  @media (max-height: 800px) {
    max-height: 470px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    margin: 50px;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: #d8d8d8;
    width: 1px;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: #12264e;
    width: 5px;
    border-radius: 50px;
    margin: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #12264e;
    margin-left: 10px;
    border-radius: 50px;
  }
`;
export const HeaderSectionResume = styled.div`
  margin-bottom: 30px;
`;
export const HeaderResume = styled.h5`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0px;
  margin-bottom: 15px;
  color: #000;
`;
export const HeaderSectionDescriptionResume = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 0px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 12px;
`;
export const SectionResume = styled.div`
  margin-top: 10px;
`;
export const InfoDescriptionSectionResume = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0;
`;

export const WrapperButtonIconStyled = styled.div`
  cursor: pointer;
  display: none;
`;

export const InfoSubTitleResume = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #574f4f;
`;

export const InfoDescriptionResume = styled.p`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000;
  padding: 2px 0;
`;

export const InfoDescriptionNotInformedResume = styled.p`
  display: flex;
  align-items: left;
  justify-content: left;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  color: #000;
`;

export const TitleSectionStyled = styled.p`
  width: fit-content;
  background: #f9f9f9;
  padding: 0 10px 0 0;
`;

export const TraceStyled = styled.hr`
  width: 100%;
  position: absolute;
  right: 0px;
  top: 10px;
  border: 1px solid rgb(240 240 240);
  height: 1px;
  z-index: -1;
  margin-top: 9px;
`;

export const InfoFirtDescriptionResume = styled.p`
  color: red;
  font-size: 20px;
`;

export const ButtonClosedStyled = styled.span`
  cursor: pointer;
  font-size: 12px;
  top: -30px;
  position: relative;
`;
