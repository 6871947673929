import API from 'utils/API/API';
import { convertFormToPayload } from './mappers/convert-form-to-payload/convert-form-to-payload.mapper';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';

export const sendBackhaulPurchaseService = async (
  formValues: IModalBackhaulPurchaseForm,
  backhaulPurchase: IBackhaulPurchase,
) => {
  const convertPayload = convertFormToPayload(formValues, backhaulPurchase);

  return await API.post('v3/schedules/backhaul/all', convertPayload);
};
