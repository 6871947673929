import styled from 'styled-components';

export const WrapperButton = styled.div`
  margin-top: 18px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
`;

export const LineSectionStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.48fr;
  grid-gap: 5px 20px;

  .MuiButtonBase-root {
    svg {
      width: 18px;
    }
    margin-right: 8px;
  }
`;

export const Div = styled.div``;
