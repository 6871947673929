import { IChatContext, IChatContextError } from 'src-new/contexts/chat/types/chat.types';

export const initialError: IChatContextError = {
  title: '',
  description: '',
};

export const chatInitialState: IChatContext = {
  chatConversationList: {
    statusRequest: 'INITIAL',
    error: initialError,
    conversations: [],
    modalActions: {
      isOpenModal: false,
      setIsOpenModal: () => undefined,
    },
    paginationActions: {
      isPageLimit: false,
      nextPage: undefined,
      setNextPage: () => undefined,
      setPageLimit: () => undefined,
    },
    setChatConversationListRequest: () => undefined,
    setChatConversationListSuccess: () => undefined,
    setChatConversationListError: () => undefined,
    setChatConversationListReset: () => undefined,
  },
  chatEligibleUsers: {
    statusRequest: 'INITIAL',
    users: [],
    validateThreadsActions: {
      statusRequest: 'INITIAL',
      haveCreated: true,
      setValidateThreadRequest: () => undefined,
      setValidateThreadSuccess: () => undefined,
      setValidateThreadError: () => undefined,
    },
    setEligibleUsersChatRequest: () => undefined,
    setEligibleUsersChatSuccess: () => undefined,
    setEligibleUsersChatError: () => undefined,
    setEligibleUsersChatReset: () => undefined,
  },
  chatMessageQuantity: {
    statusRequest: 'INITIAL',
    chatMessages: [],
    setMessageQuantitySuccess: () => undefined,
  },
  chatUnreadMessages: {
    unreadMessages: [],
    setUnreadMessages: () => undefined,
  },
  chatUserEmails: {
    statusRequest: 'INITIAL',
    userEmails: undefined,
    setChatUserEmailsRequest: () => undefined,
    setChatUserEmailsSuccess: () => undefined,
    setChatUserEmailsError: () => undefined,
    setChatUserEmailsReset: () => undefined,
  },
  setChatReset: () => undefined,
};
