import { ScheduleExecutionActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';
import {
  IScheduleExecutionActions,
  ITruckRoutes,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';

export const initialStateTruckRoutes: ITruckRoutes = {
  routes: [],
  routePositions: [],
};

export const scheduleExecutionReducer = (
  state: IScheduleContext,
  action: IScheduleExecutionActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_REQUEST:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            statusRequest: 'PROCESSING',
            plannedRoute: true,
            firebaseTruckRoutes: initialStateTruckRoutes,
            monitoringTruckRoutes: initialStateTruckRoutes,
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_SUCCESS:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            statusRequest: 'SUCCESS',
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_ERROR:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            statusRequest: 'ERROR',
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_FIREBASE:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            firebaseTruckRoutes: action.payload,
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_MONITORING:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            monitoringTruckRoutes: action.payload,
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_PLANNED:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            plannedRoute: action.payload,
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_SET_MAP_TYPE:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            mapTypeActions: {
              ...state.scheduleExecution.mapAccordionProps.mapTypeActions,
              mapType: action.payload,
            },
          },
        },
      };
    case ScheduleExecutionActionType.SCHEDULE_EXECUTION_RESET:
      return {
        ...state,
        scheduleExecution: {
          ...state.scheduleExecution,
          mapAccordionProps: {
            ...state.scheduleExecution.mapAccordionProps,
            statusRequest: 'INITIAL',
            plannedRoute: true,
            firebaseTruckRoutes: initialStateTruckRoutes,
            monitoringTruckRoutes: initialStateTruckRoutes,
          },
        },
      };
    default:
      return state;
  }
};
