/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { initialValues } from './initial-values/initial-values';
import { BiddingTable } from './components/list/list';
import { CompanyBiddingTable } from './components/company-list';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { transportDetailsService } from 'pages/transport-company/services/transport-details/transport-details.service';
import { termsConditionsRegister } from 'pages/login/services/terms-conditions.service';
import { createBiddingService } from 'pages/home/services/bidding/create-bidding/create-bidding.service';
import { ScheduleDomain } from 'domain/schedule';
import { IBiddingCreate } from 'domain/bidding';
import { showToast } from 'components/toast/toast';
import { BiddingForm } from 'components/bidding/modal-form';

type BiddingProps = {
  scheduleList: {
    items: ScheduleDomain[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };
  isLoading: boolean;
  handlePageChange: (page: number) => void;
};

const BiddingList: React.FC<BiddingProps> = ({ scheduleList, isLoading, handlePageChange }) => {
  const { user } = useContext(UserContext);

  const [schedule, setSchedule] = useState<ScheduleDomain[]>(scheduleList.items);
  const [formBidding, setFormBidding] = useState<{
    isOpen: boolean;
    scheduleId: number;
  }>({
    isOpen: false,
    scheduleId: 0,
  });
  const biddingFormRef = useRef<FormikProps<IBiddingCreate>>(null);

  useEffect(() => {
    if (!scheduleList?.items) return;

    getCompanyImage();
  }, [scheduleList, user]);

  const contentTable = useMemo(() => {
    if (user?.profile === 'shipping-company') {
      return (
        <BiddingTable
          schedule={schedule}
          openModal={setFormBidding}
          handlePageChange={handlePageChange}
          activePage={scheduleList?.meta.currentPage ?? 0}
          totalPages={scheduleList?.meta.totalPages ?? 0}
          isLoading={isLoading}
        />
      );
    }

    return (
      <CompanyBiddingTable
        schedule={schedule}
        openModal={setFormBidding}
        handlePageChange={handlePageChange}
        activePage={scheduleList?.meta.currentPage ?? 0}
        totalPages={scheduleList?.meta.totalPages ?? 0}
        isLoading={isLoading}
      />
    );
  }, [scheduleList, schedule]);

  const getShippingCompany = useMemo(async () => {
    if (user?.profile === 'shipping-company') {
      return await transportDetailsService(user?.shippingCompanyId ?? 0);
    }
  }, [user]);

  const getCompanyImage = useCallback(async () => {
    const promises = scheduleList.items.map(async (schedule) => {
      const companyUserImage = await companyDetailsService(schedule.companyId);

      if (user?.profile === 'shipping-company') {
        const findShippingCompany = await getShippingCompany;

        if (findShippingCompany && findShippingCompany.company.id !== schedule?.companyId) {
          return {
            ...schedule,
            companyName: 'CONFIDENCIAL',
            companyLogo: '',
          };
        }

        return {
          ...schedule,
          companyName: companyUserImage.tradeName,
          companyLogo: companyUserImage.fileKey,
        };
      }

      return {
        ...schedule,
        companyName: Number(companyUserImage.id) === user?.companyId ? companyUserImage.tradeName : 'CONFIDENCIAL',
        companyLogo: Number(companyUserImage.id) === user?.companyId ? companyUserImage.fileKey : '',
      };
    });

    const resolvedPromises = await Promise.all(promises);

    setSchedule(resolvedPromises);
  }, [scheduleList, user]);

  const converterValue = (value: number) => {
    const valueConverted = value.toString().replace(',', '.');

    return Number(valueConverted);
  };

  const handleCreateBidding = useCallback(
    async (values: IBiddingCreate) => {
      try {
        await termsConditionsRegister({
          userName: user?.companyLogin || '',
          termId: values.termsId,
          login: user?.login || '',
          scheduleId: values.scheduleId,
        });
        await createBiddingService({
          ...values,
          cost: {
            freightValue: converterValue(values.cost.freightValue),
            tollValue: converterValue(values.cost.tollValue),
            grisAdvalorem: converterValue(values.cost.grisAdvalorem),
            totalFreight: converterValue(values.cost.totalFreight),
            icms: converterValue(values.cost.icms),
            totalService: converterValue(values.cost.totalService),
            feeLogshare: converterValue(values.cost.feeLogshare),
          },
        });
        showToast({
          message: 'Oferta enviada com sucesso',
          type: 'success',
        });
        setFormBidding((old) => ({ ...old, isOpen: false }));
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message || error.message,
          type: 'error',
        });
      }
    },
    [user?.companyLogin, user?.login],
  );

  return (
    <Fragment>
      {formBidding.isOpen && (
        <Formik
          innerRef={biddingFormRef}
          initialValues={initialValues}
          onSubmit={handleCreateBidding}
          enableReinitialize
        >
          <BiddingForm
            scheduleId={formBidding.scheduleId}
            closeModal={() => setFormBidding((old) => ({ ...old, isOpen: false }))}
          />
        </Formik>
      )}
      {contentTable}
    </Fragment>
  );
};

export default BiddingList;
