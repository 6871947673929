import React, { useCallback } from 'react';
import { ScheduleDuplicateModalView } from './schedule-duplicate-modal-view.component';
import { useDuplicateSchedule } from '../../../../hooks/use-duplicate-schedule/use-duplicate-schedule.hook';

interface IScheduleDuplicateModalProps {
  handleModalClose: () => void;
}

export const ScheduleDuplicateModal: React.FC<IScheduleDuplicateModalProps> = (props) => {
  const duplicateScheduleHook = useDuplicateSchedule();

  const handleDuplicateFreight = useCallback(async () => duplicateScheduleHook(), [duplicateScheduleHook]);

  return <ScheduleDuplicateModalView {...props} handleDuplicateFreight={handleDuplicateFreight} />;
};
