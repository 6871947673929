import dayjs from 'dayjs';
import { ContractualPremises, ICargoInsurance, IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { IHoursOperationDomain } from 'src-new/pages/registrations/domains/hours-operation.domain';

interface Output extends IClientDomain {
  oubound: IHoursOperationDomain;
}

export const convertClientDomainToEntityMapper = (domain: IClientDomain): Output => {
  const contractStartDate = domain.contractualPremises?.contractStartDate;
  const contractEndDate = domain.contractualPremises?.contractEndDate;
  const contractSignatureDate = domain.contractualPremises?.contractSignatureDate;

  const contractualPremises: ContractualPremises = {
    contractStartDate: contractStartDate ? dayjs(contractStartDate).toISOString() : null,
    contractEndDate: contractEndDate ? dayjs(contractEndDate).toISOString() : null,
    contractSignatureDate: contractSignatureDate ? dayjs(contractSignatureDate).toISOString() : null,
    analyst: domain.contractualPremises?.analyst ?? null,
  };

  const cargoInsurance: ICargoInsurance = {
    insurancePolicy: domain.cargoInsurance?.insurancePolicy ?? null,
    advalorem: domain.cargoInsurance?.advalorem ?? null,
    gris: domain.cargoInsurance?.gris ?? null,
  };

  return {
    ...domain,
    contractualPremises: contractualPremises,
    cargoInsurance: cargoInsurance,
    oubound: domain.outbound,
  };
};
