import React from 'react';
import {
  MessageSkeleton,
  ChatBodySkeleton,
  ChatHeaderPlaceholder,
  ChatHeaderSkeleton,
  ChatSkeletonContainer,
  AvatarPlaceholder,
  MessageContentPlaceholder,
  ChatFooterSkeleton,
  InputPlaceholder,
  IconsPlaceholder,
} from './chat-modal-skeleton-view.styled';

export const ChatModalSkeletonView: React.FC = () => {
  return (
    <ChatSkeletonContainer>
      <ChatHeaderSkeleton>
        <ChatHeaderPlaceholder />
      </ChatHeaderSkeleton>
      <ChatBodySkeleton>
        <MessageSkeleton align="left">
          <AvatarPlaceholder />
          <MessageContentPlaceholder />
        </MessageSkeleton>
        <MessageSkeleton align="right">
          <MessageContentPlaceholder />
          <AvatarPlaceholder />
        </MessageSkeleton>
        <MessageSkeleton align="right">
          <MessageContentPlaceholder />
          <AvatarPlaceholder />
        </MessageSkeleton>
      </ChatBodySkeleton>
      <ChatFooterSkeleton>
        <InputPlaceholder />
        <IconsPlaceholder />
      </ChatFooterSkeleton>
    </ChatSkeletonContainer>
  );
};
