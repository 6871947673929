import { WeighingListActionType } from '../../weighing.action';
import { IWeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing.types';
import { IWeighingListActions } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';

export const weighingListReducer = (state: IWeighingContext, action: IWeighingListActions): IWeighingContext => {
  switch (action.type) {
    case WeighingListActionType.WEIGHING_LIST_REQUEST:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          statusRequest: 'PROCESSING',
        },
      };
    case WeighingListActionType.WEIGHING_LIST_SUCCESS:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          statusRequest: 'SUCCESS',
          weighingList: action.payload,
        },
      };
    case WeighingListActionType.WEIGHING_LIST_ERROR:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          statusRequest: 'ERROR',
          weighingList: [],
        },
      };
    case WeighingListActionType.WEIGHING_LIST_RESET:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          statusRequest: 'INITIAL',
          weighingList: [],
        },
      };
    case WeighingListActionType.WEIGHING_SELECT_FILTERS:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          filters: {
            ...state.weighingList.filters,
            selectedFilter: action.payload,
          },
        },
      };
    case WeighingListActionType.WEIGHING_LIST_MODALS:
      return {
        ...state,
        weighingList: {
          ...state.weighingList,
          modalsProps: {
            ...state.weighingList.modalsProps,
            modalOptions: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
