import React, { useCallback, useContext, useMemo } from 'react';
import { HomeBidOffersTableView } from './home-bid-offers-table-view.component';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { convertHomeBidToBiddingOffer } from '../../../../mappers/convert-home-bid-to-bidding-offer/convert-home-bid-to-bidding-offer.mapper';
import { IModalBiddingOffer } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer.types';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

interface IHomeMatchesListProps {
  infiniteScrollProps: IInfiniteScrollTable;
}

export const HomeBidOffersTable: React.FC<IHomeMatchesListProps> = ({ infiniteScrollProps }) => {
  const { homeBids, homeModals } = useContext(HomeContext);
  const { setHomeModalsOpen, setHomeModalsClose, open, id, homeModalType } = homeModals;

  const getBidOffersList = useMemo(() => homeBids.homeBids, [homeBids.homeBids]);

  const formatDateTime = useCallback((dateValue: string): { formattedDate: string; formattedTime: string } => {
    const date = new Date(dateValue);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return { formattedDate, formattedTime };
  }, []);

  const handleOpenModal = useCallback(
    (id: number) => setHomeModalsOpen({ modalType: 'proposeBid', id }),
    [setHomeModalsOpen],
  );

  const handleCloseModal = useCallback(() => setHomeModalsClose(), [setHomeModalsClose]);

  const saleOfferMatch = useMemo((): IModalBiddingOffer | undefined => {
    if (open && homeModalType === 'proposeBid' && id) {
      const homeMatchValue = getBidOffersList.find((item) => item.id === homeModals.id);

      if (!homeMatchValue) return;

      return convertHomeBidToBiddingOffer(homeMatchValue);
    }
  }, [getBidOffersList, homeModalType, homeModals.id, id, open]);

  return (
    <HomeBidOffersTableView
      bidOffersList={getBidOffersList}
      infiniteScrollProps={infiniteScrollProps}
      formatDateTime={formatDateTime}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      saleOfferMatch={saleOfferMatch}
    />
  );
};
