import { ITransactionsContext } from '../types/transactions.types';

export const transactionsReducerResetState = (state: ITransactionsContext): ITransactionsContext => {
  return {
    ...state,
    transactionsList: {
      ...state.transactionsList,
      statusRequest: 'INITIAL',
      transactions: [],
      filterActions: {
        ...state.transactionsList.filterActions,
        filters: {
          page: 1,
          activeTabName: 'A Pagar',
          order: 'DESC',
          column: 'Data Entrega',
          financialStatus: 'pagar',
          limit: 100,
          search: '',
        },
      },
    },
  };
};
