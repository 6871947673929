export enum ChatActionType {
  CHAT_RESET = 'CHAT_PHOTO_URL_RESET',
}

export enum ChatMessageQuantityActionType {
  CHAT_MESSAGE_QUANTITY_SUCCESS = 'CHAT_MESSAGE_QUANTITY_SUCCESS',
}

export enum ChatUnreadMessagesActionType {
  CHAT_SET_UNREAD_MESSAGES = 'CHAT_SET_UNREAD_MESSAGES',
}

export enum ChatConversationListActionType {
  CHAT_CONVERSATION_LIST_REQUEST = 'CHAT_CONVERSATION_LIST_REQUEST',
  CHAT_CONVERSATION_LIST_SUCCESS = 'CHAT_CONVERSATION_LIST_SUCCESS',
  CHAT_CONVERSATION_LIST_ERROR = 'CHAT_CONVERSATION_LIST_ERROR',
  CHAT_CONVERSATION_LIST_RESET = 'CHAT_CONVERSATION_LIST_RESET',
  CHAT_CONVERSATION_LIST_MODAL_OPEN = 'CHAT_CONVERSATION_LIST_MODAL_OPEN',
  CHAT_CONVERSATION_LIST_NEXT_PAGE = 'CHAT_CONVERSATION_LIST_NEXT_PAGE',
  CHAT_CONVERSATION_LIST_PAGE_LIMIT = 'CHAT_CONVERSATION_LIST_PAGE_LIMIT',
}

export enum ChatEligibleUsersActionType {
  CHAT_ELIGIBLE_USERS_REQUEST = 'CHAT_ELIGIBLE_USERS_REQUEST',
  CHAT_ELIGIBLE_USERS_SUCCESS = 'CHAT_ELIGIBLE_USERS_SUCCESS',
  CHAT_ELIGIBLE_USERS_ERROR = 'CHAT_ELIGIBLE_USERS_ERROR',
  CHAT_ELIGIBLE_USERS_RESET = 'CHAT_ELIGIBLE_USERS_RESET',
  CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_REQUEST = 'CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_REQUEST',
  CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_SUCCESS = 'CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_SUCCESS',
  CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_ERROR = 'CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_ERROR',
}

export enum ChatUserEmailsActionType {
  CHAT_SET_USER_EMAILS_REQUEST = 'CHAT_SET_USER_EMAILS_REQUEST',
  CHAT_SET_USER_EMAILS_SUCCESS = 'CHAT_SET_USER_EMAILS_SUCCESS',
  CHAT_SET_USER_EMAILS_ERROR = 'CHAT_SET_USER_EMAILS_ERROR',
  CHAT_SET_USER_EMAILS_RESET = 'CHAT_SET_USER_EMAILS_RESET',
}
