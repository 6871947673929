import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useFormikContext } from 'formik';

import { ContainerStyled, HeaderStyled, LineSectionStyled, LineSeparatorStyled, ModalFormStyled } from './index.style';

import { useFinishScheduleHook } from 'pages/freight/hooks/use-finish-schedule/use-finish-schedule.hook';
import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { showToast } from 'components/toast/toast';
import InputFieldTime from 'components/input-time/input-time';
import { InputCalendar } from 'components/input-calendar/input-calendar';
import Button from 'components/button/button';

interface IProps {
  values: IAllocationShippingDetails;
  id: number;
  FreightID: number;
}

interface DateTimeFields {
  value: string;
  label: string;
  id: string;
  date: {
    focused: boolean;
    date: Moment | null;
  };
  setDate: React.Dispatch<
    React.SetStateAction<{
      focused: boolean;
      date: Moment | null;
      initialBlockedDate: Moment | null;
      finalBlockedDate: Moment | null;
    }>
  >;
}

export type DateTimeValues = {
  [key: string]: DateTimeFields;
};

interface BlockedTimeFields {
  startDateTime: boolean;
  checkinDateTime: boolean;
  checkoutDateTime: boolean;
  loadDateTime: boolean;
  finishDateTime: boolean;
}

interface BlockedTimeValues {
  [key: string]: BlockedTimeFields;
}

const HoursUpdateModal: React.FC<IProps> = ({ values, id, FreightID }) => {
  const { setFieldValue, submitForm, handleChange } = useFormikContext<IAllocationShippingDetails>();

  const [acceptDate, setAcceptDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.acceptDate ? moment(values.acceptDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [startDate, setStartDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.startDate ? moment(values.startDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [checkinDate, setCheckinDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.checkinDate ? moment(values.checkinDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [checkoutDate, setCheckoutDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.checkoutDate ? moment(values.checkoutDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [loadDate, setLoadDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.loadDate ? moment(values.loadDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [finishDate, setFinishDate] = useState<{
    focused: boolean;
    date: Moment | null;
    initialBlockedDate: Moment | null;
    finalBlockedDate: Moment | null;
  }>({
    focused: false,
    date: values.finishDate ? moment(values.finishDate) : null,
    initialBlockedDate: null,
    finalBlockedDate: null,
  });

  const [dateTime, setDateTime] = useState<DateTimeValues>({
    acceptDateTime: {
      label: 'Horário',
      id: 'acceptDateTime',
      date: acceptDate,
      setDate: setAcceptDate,
      value: acceptDate.date ? `${acceptDate.date?.format('HH:mm')}` : '',
    },
    startDateTime: {
      label: 'Horário',
      id: 'startDateTime',
      date: startDate,
      setDate: setStartDate,
      value: startDate.date ? `${startDate.date?.format('HH:mm')}` : '',
    },
    checkinDateTime: {
      label: 'Horário',
      id: 'checkinDateTime',
      date: checkinDate,
      setDate: setCheckinDate,
      value: checkinDate.date ? `${checkinDate.date?.format('HH:mm')}` : '',
    },
    checkoutDateTime: {
      label: 'Horário',
      id: 'checkoutDateTime',
      date: checkoutDate,
      setDate: setCheckoutDate,
      value: checkoutDate.date ? `${checkoutDate.date?.format('HH:mm')}` : '',
    },
    loadDateTime: {
      label: 'Horário',
      id: 'loadDateTime',
      date: loadDate,
      setDate: setLoadDate,
      value: loadDate.date ? `${loadDate.date?.format('HH:mm')}` : '',
    },
    finishDateTime: {
      label: 'Horário',
      id: 'finishDateTime',
      date: finishDate,
      setDate: setFinishDate,
      value: finishDate.date ? `${finishDate.date?.format('HH:mm')}` : '',
    },
  });

  const [blockedTime, setBlockedTime] = useState<BlockedTimeValues>({});

  useEffect(() => {
    setAcceptDate((prevState) => ({
      ...prevState,
      finalBlockedDate: startDate.date || checkinDate.date || checkoutDate.date || loadDate.date || finishDate.date,
    }));
    setStartDate((prevState) => ({
      ...prevState,
      initialBlockedDate: acceptDate.date,
      finalBlockedDate: checkinDate.date || checkoutDate.date || loadDate.date || finishDate.date,
    }));
    setCheckinDate((prevState) => ({
      ...prevState,
      initialBlockedDate: startDate.date || acceptDate.date,
      finalBlockedDate: checkoutDate.date || loadDate.date || finishDate.date,
    }));
    setCheckoutDate((prevState) => ({
      ...prevState,
      initialBlockedDate: checkinDate.date || startDate.date || acceptDate.date,
      finalBlockedDate: loadDate.date || finishDate.date,
    }));
    setLoadDate((prevState) => ({
      ...prevState,
      initialBlockedDate: checkoutDate.date || checkinDate.date || startDate.date || acceptDate.date,
      finalBlockedDate: finishDate.date,
    }));
    setFinishDate((prevState) => ({
      ...prevState,
      initialBlockedDate: loadDate.date || checkoutDate.date || checkinDate.date || startDate.date || acceptDate.date,
    }));
  }, [acceptDate.date, startDate.date, checkinDate.date, checkoutDate.date, loadDate.date, finishDate.date]);

  useEffect(() => {
    setFieldValue(
      'acceptDate',
      acceptDate.date ? acceptDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.acceptDate,
    );
  }, [acceptDate.date, setFieldValue]);

  useEffect(() => {
    setFieldValue('startDate', startDate.date ? startDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.startDate);
    dateBlockedTime(
      acceptDate.date,
      startDate.date,
      dateTime.acceptDateTime.value,
      dateTime.startDateTime.value,
      'startDateTime',
    );
  }, [startDate.date, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'checkinDate',
      checkinDate.date ? checkinDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.checkinDate,
    );
    dateBlockedTime(
      startDate.date,
      checkinDate.date,
      dateTime.startDateTime.value,
      dateTime.checkinDateTime.value,
      'checkinDateTime',
    );
  }, [checkinDate.date, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'checkoutDate',
      checkoutDate.date ? checkoutDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.checkoutDate,
    );
    dateBlockedTime(
      checkinDate.date,
      checkoutDate.date,
      dateTime.checkinDateTime.value,
      dateTime.checkoutDateTime.value,
      'checkoutDateTime',
    );
  }, [checkoutDate.date, setFieldValue]);

  useEffect(() => {
    setFieldValue('loadDate', loadDate.date ? loadDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.loadDate);
    dateBlockedTime(
      checkoutDate.date,
      loadDate.date,
      dateTime.checkoutDateTime.value,
      dateTime.loadDateTime.value,
      'loadDateTime',
    );
  }, [loadDate.date, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'finishDate',
      finishDate.date ? finishDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS') : values.finishDate,
    );
    dateBlockedTime(
      loadDate.date,
      finishDate.date,
      dateTime.loadDateTime.value,
      dateTime.finishDateTime.value,
      'finishDateTime',
    );
  }, [finishDate.date, setFieldValue, values.finishDate]);

  useEffect(() => {
    setFieldValue('id', id);
  }, [setFieldValue, id]);

  useEffect(() => {
    setDateTime((oldValues) => ({
      acceptDateTime: {
        ...oldValues['acceptDateTime'],
        date: {
          focused: oldValues['acceptDateTime'].date.focused,
          date: acceptDate.date,
        },
      },
      startDateTime: {
        ...oldValues['startDateTime'],
        date: {
          focused: oldValues['startDateTime'].date.focused,
          date: startDate.date,
        },
      },
      checkinDateTime: {
        ...oldValues['checkinDateTime'],
        date: {
          focused: oldValues['checkinDateTime'].date.focused,
          date: checkinDate.date,
        },
      },
      checkoutDateTime: {
        ...oldValues['checkoutDateTime'],
        date: {
          focused: oldValues['checkoutDateTime'].date.focused,
          date: checkoutDate.date,
        },
      },
      loadDateTime: {
        ...oldValues['loadDateTime'],
        date: {
          focused: oldValues['loadDateTime'].date.focused,
          date: loadDate.date,
        },
      },
      finishDateTime: {
        ...oldValues['finishDateTime'],
        date: {
          focused: oldValues['finishDateTime'].date.focused,
          date: finishDate.date,
        },
      },
    }));
  }, [acceptDate, startDate, checkinDate, checkoutDate, loadDate, finishDate]);

  const handleEditChange = useCallback((column: string, value: string) => {
    setDateTime((oldValues) => ({
      ...oldValues,
      [column]: {
        ...oldValues[column],
        value,
      },
    }));
    handleChange(value);
    switch (column) {
      case 'acceptDateTime':
        setFieldValue('statusCode', 'IN11');
        return;
      case 'startDateTime':
        setFieldValue('statusCode', 'IN20');
        return;
      case 'checkinDateTime':
        setFieldValue('statusCode', 'IN40');
        return;
      case 'checkoutDateTime':
        setFieldValue('statusCode', 'IN60');
        return;
      case 'loadDateTime':
        setFieldValue('statusCode', 'IN70');
        return;
      case 'finishDateTime':
        setFieldValue('statusCode', 'IN90');
        return;
    }
  }, []);

  const submitEditForm = () => {
    const valuesKeys = Object.keys(dateTime);
    for (const key of valuesKeys) {
      const valueObj = dateTime[key];
      const time = moment(valueObj.value, 'HH:mm');
      const newData = moment(valueObj.date.date?.format('DD-MM-YYYY') + ' ' + time.format('HH:mm'), 'DD-MM-YYYY HH:mm');
      {
        valueObj.date.date !== null && valueObj.setDate((old) => ({ ...old, date: newData }));
      }
    }
    const hasBlockedTime = Object.keys(dateTime).some((key) => {
      const blockedFields = blockedTime[key];
      if (!blockedFields) return false;
      return true;
    });
    !hasBlockedTime && submitForm();
  };

  const dateBlockedTime = (
    initialDate: Moment | null,
    finalDate: Moment | null,
    initialTime: string,
    finalTime: string,
    field: string,
  ) => {
    if (
      initialDate &&
      finalDate &&
      initialDate.format('DD/MM/YYYY') === finalDate.format('DD/MM/YYYY') &&
      finalTime.length > 4 &&
      Number(initialTime.replace(':', '')) > Number(finalTime.replace(':', ''))
    ) {
      setBlockedTime((prvState: any) => ({
        ...prvState,
        [field]: true,
      }));
    } else {
      setBlockedTime((prvState: any) => ({
        ...prvState,
        [field]: false,
      }));
    }
  };

  const renderEditFields = useMemo(() => {
    const modificationsKeys = Object.keys(dateTime);
    const modifications = modificationsKeys.map((key) => dateTime[key]);

    return modifications.map((modification, index) => {
      const blocked = blockedTime && blockedTime[modification.id] ? true : false;
      if (index !== 0) {
        dateBlockedTime(
          modifications[index - 1].date.date,
          modification.date.date,
          modifications[index - 1].value,
          modification.value,
          modification.id,
        );
      }
      return (
        <InputFieldTime
          key={modification.label}
          label={modification.label}
          id={modification.id}
          name={modification.id}
          type="text"
          onChange={(e: any) => handleEditChange(e.target.name, e.target.value)}
          value={modification.value}
          hasError={blocked}
        />
      );
    });
  }, [dateTime, handleEditChange]);

  const finishScheduleHook = useFinishScheduleHook();

  const handleFinish = useCallback(() => {
    finishScheduleHook(FreightID, id)
      .then(() => {
        showToast({
          message: 'Entrega Finalizada',
          type: 'success',
        });
        submitEditForm();
      })
      .catch(() => {
        showToast({
          message: 'Houve ao finalizar a entrega',
          type: 'error',
        });
      });
  }, []);

  return (
    <ModalFormStyled>
      <ContainerStyled>
        <HeaderStyled style={{ marginTop: 20 }}>
          Data e Hora Inicial
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCalendar
            label="Aceite do Motorista"
            onDateChange={(date) =>
              setAcceptDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setAcceptDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={acceptDate.focused}
            date={acceptDate.date}
            id="acceptDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: null,
                finalDate: acceptDate?.finalBlockedDate,
              },
            }}
          />

          {renderEditFields[0]}

          <InputCalendar
            label="Início da Viagem"
            onDateChange={(date) => {
              setStartDate((prvState) => {
                return { ...prvState, date };
              });
            }}
            onFocusChange={({ focused }) =>
              setStartDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={startDate.focused}
            date={startDate.date}
            id="startDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: startDate?.initialBlockedDate,
                finalDate: startDate?.finalBlockedDate,
              },
            }}
          />

          {renderEditFields[1]}
        </LineSectionStyled>

        <HeaderStyled style={{ marginTop: 20 }}>
          Data e Hora da Coleta
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCalendar
            label="Chegada para Coleta"
            onDateChange={(date) =>
              setCheckinDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setCheckinDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={checkinDate.focused}
            date={checkinDate.date}
            id="checkinDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: checkinDate?.initialBlockedDate,
                finalDate: checkinDate?.finalBlockedDate,
              },
            }}
          />

          {renderEditFields[2]}

          <InputCalendar
            label="Coleta Finalizada"
            onDateChange={(date) =>
              setCheckoutDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setCheckoutDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={checkoutDate.focused}
            date={checkoutDate.date}
            id="checkoutDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: checkoutDate?.initialBlockedDate,
                finalDate: checkoutDate?.finalBlockedDate,
              },
            }}
          />

          {renderEditFields[3]}
        </LineSectionStyled>

        <HeaderStyled style={{ marginTop: 20 }}>
          Data e Hora da Descarga
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCalendar
            label="Chegada para Descarga"
            onDateChange={(date) =>
              setLoadDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setLoadDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={loadDate.focused}
            date={loadDate.date}
            id="loadDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: loadDate?.initialBlockedDate,
                finalDate: loadDate?.finalBlockedDate,
              },
            }}
          />

          {renderEditFields[4]}

          <InputCalendar
            label="Descarga Finalizada"
            onDateChange={(date) =>
              setFinishDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setFinishDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={finishDate.focused}
            date={finishDate.date}
            id="finishDate"
            selectPastDays
            hasPicker
            addDateBlocked={{
              isBetween: {
                initialDate: finishDate?.initialBlockedDate,
                finalDate: null,
              },
            }}
          />

          {renderEditFields[5]}
        </LineSectionStyled>

        <div
          style={{
            marginTop: 30,
            display: 'flex',
            gap: 20,
            justifyContent: 'space-between',
          }}
        >
          <Button bgColor="pink" title="Salvar e Finalizar" callback={handleFinish} size="large" />

          <Button bgColor="blue" title="Salvar" callback={() => submitEditForm()} size="large" />
        </div>
      </ContainerStyled>
    </ModalFormStyled>
  );
};

export default HoursUpdateModal;
