import React, {Dispatch, memo, SetStateAction, useCallback, useMemo} from 'react';
import {useFormikContext} from 'formik';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import * as S from './styled';

import {initialValuesDate} from 'domain-v2/match/register/initial-values';
import {DatesMatchInput, MatchRegisterInput} from 'domain-v2/match/register';
import {SectionLayout} from 'components-v2/layout/section';
import {LineSectionLayout} from 'components-v2/layout/line-section';
import Line from 'components-v2/layout/line';
import {DateTimePikers} from 'components-v2/common/date-time-pikers';
import Button from 'components/button/button';

interface Props {
  datesError?: DatesMatchInput[];
  setDatesError: Dispatch<SetStateAction<DatesMatchInput[] | undefined>>;
}

const DetailsForm: React.FC<Props> = ({datesError, setDatesError}) => {
  const {values, setFieldValue} = useFormikContext<MatchRegisterInput>();

  const handleAddDate = useCallback(
    (initialValues: DatesMatchInput) => {
      setFieldValue('dates', [...values['dates'], initialValues]);
    },
    [values, setFieldValue],
  );

  const handleRemoveDate = useCallback(
    (index: number) => {
      const updateDates = values['dates'].filter((_, i) => i !== index);
      setFieldValue('dates', updateDates);
    },
    [values, setFieldValue],
  );

  const handleFieldDateChange = (fieldName: string, date: dayjs.Dayjs | null, index: number) => {
    setFieldValue(fieldName, date?.toISOString());
    const name = fieldName.split('.')[1];
    switch (name) {
      case 'initialLevyDate':
        setFieldValue(`dates[${index}].finalLevyDate`, date?.toISOString());
        setFieldValue(`dates[${index}].initialDeliveryDate`, '');
        setFieldValue(`dates[${index}].finalDeliveryDate`, '');
        setDatesError((prevState) => {
          const newState = [...(prevState ?? [])];
          newState[index] = {
            ...newState[index],
            initialLevyDate: '',
            finalLevyDate: '',
          };
          return newState;
        });
        break;
      case 'initialDeliveryDate':
        setFieldValue(`dates[${index}].finalDeliveryDate`, dayjs(date)?.toISOString());
        setDatesError((prevState) => {
          const newState = [...(prevState ?? [])];
          newState[index] = {
            ...newState[index],
            initialDeliveryDate: '',
            finalDeliveryDate: '',
          };
          return newState;
        });
        break;
      case 'finalLevyDate':
        setFieldValue(`dates[${index}].initialDeliveryDate`, '');
        setFieldValue(`dates[${index}].finalDeliveryDate`, '');
        break;
    }
  };

  const getMinDateForInitialDate = useCallback(() => {
    const today = dayjs();
    const dayOfWeek = today.day();

    switch (dayOfWeek) {
      case 6: // Saturday
        return today.add(6, 'hours');
      case 0: // Sunday
        return today.add(6, 'hours');
      default: // Monday to Friday
        return today.add(6, 'hours');
    }
  }, []);

  const calcMinDate = useCallback(
    (inputName: string, index: number): dayjs.Dayjs | undefined => {
      switch (inputName) {
        case 'initialLevyDate':
          return getMinDateForInitialDate();
        case 'finalLevyDate':
          return dayjs(values.dates[index].initialLevyDate).isValid()
            ? dayjs(values.dates[index].initialLevyDate)
            : dayjs();
        case 'initialDeliveryDate':
          return dayjs(values.dates[index].finalLevyDate).isValid()
            ? dayjs(values.dates[index].finalLevyDate)
            : dayjs();
        case 'finalDeliveryDate':
          return dayjs(values.dates[index].initialDeliveryDate).isValid()
            ? dayjs(values.dates[index].initialDeliveryDate)
            : dayjs();
      }
    },
    [values.dates],
  );

  const renderDatesForm = useMemo(() => {
    return values?.dates?.map((_, index) => {
      return (
        <div key={index}>
          <LineSectionLayout columns={'1fr'}>
            <S.WrapperInputs>
              <S.LineSectionStyled columns={'1fr 1fr 1fr 1fr 0.48fr'}>
                <DateTimePikers
                  label="Inicio de Carregamento *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(values.dates[index].initialLevyDate)}
                  onChange={(date) => {
                    handleFieldDateChange(`dates[${index}].initialLevyDate`, date, index);

                    setDatesError((prevState) => {
                      const newState = [...(prevState ?? [])];
                      newState[index] = {
                        ...newState[index],
                        initialLevyDate: '',
                      };
                      return newState;
                    });
                  }}
                  minDate={calcMinDate('initialLevyDate', index)}
                  validateField={datesError && !!datesError[index]?.initialLevyDate}
                  errorMessage={datesError && (datesError[index]?.initialLevyDate ?? '')}
                />

                <DateTimePikers
                  label="Fim de Carregamento *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(values.dates[index].finalLevyDate)}
                  onChange={(date) => {
                    handleFieldDateChange(`dates[${index}].finalLevyDate`, date, index);
                    setDatesError((prevState) => {
                      const newState = [...(prevState ?? [])];
                      newState[index] = {
                        ...newState[index],
                        finalLevyDate: '',
                      };
                      return newState;
                    });
                  }}
                  minDate={calcMinDate('finalLevyDate', index)}
                  validateField={datesError && !!datesError[index]?.finalLevyDate}
                  errorMessage={datesError && (datesError[index]?.finalLevyDate ?? '')}
                />

                <DateTimePikers
                  label="Inicio da Descarga *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(values?.dates[index]?.initialDeliveryDate)}
                  onChange={(date) => {
                    handleFieldDateChange(`dates[${index}].initialDeliveryDate`, date, index);
                    setDatesError((prevState) => {
                      const newState = [...(prevState ?? [])];
                      newState[index] = {
                        ...newState[index],
                        initialDeliveryDate: '',
                      };
                      return newState;
                    });
                  }}
                  minDate={calcMinDate('initialDeliveryDate', index)}
                  validateField={datesError && !!datesError[index]?.initialDeliveryDate}
                  errorMessage={datesError && (datesError[index]?.initialDeliveryDate ?? '')}
                />

                <DateTimePikers
                  label="Fim da Descarga *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(values?.dates[index]?.finalDeliveryDate)}
                  onChange={(date) => {
                    handleFieldDateChange(`dates[${index}].finalDeliveryDate`, date, index);
                    setDatesError((prevState) => {
                      const newState = [...(prevState ?? [])];
                      newState[index] = {
                        ...newState[index],
                        finalDeliveryDate: '',
                      };
                      return newState;
                    });
                  }}
                  minDate={calcMinDate('finalDeliveryDate', index)}
                  validateField={datesError && !!datesError[index]?.finalDeliveryDate}
                  errorMessage={datesError && (datesError[index]?.finalDeliveryDate ?? '')}
                />

                <S.WrapperButton>
                  {index > 0 ? (
                    <Button
                      title="Deletar"
                      bgColor="newRed"
                      callback={() => handleRemoveDate(index)}
                      icon={<DeleteIcon/>}
                      size="very-small"
                    />
                  ) : (
                    <Button
                      title="Adicionar"
                      bgColor="blue"
                      callback={() => handleAddDate(initialValuesDate)}
                      icon={<AddIcon/>}
                      size="very-small"
                    />
                  )}
                </S.WrapperButton>
              </S.LineSectionStyled>
            </S.WrapperInputs>
          </LineSectionLayout>
          <S.WrapperButton>
            <LineSectionLayout columns="0.96fr">
              <Line/>
            </LineSectionLayout>
          </S.WrapperButton>
        </div>
      );
    });
  }, [values, datesError, setFieldValue]);

  return <SectionLayout name="Informações do Agendamento">{renderDatesForm}</SectionLayout>;
};

export default memo(DetailsForm);
