import { useCallback, useContext } from 'react';
import { DownloadFile } from 'utils/download-file/download-file';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';
import { createScheduleRefusedExcelService } from '../../services/create-schedule-refused-excel/create-schedule-refused-excel.service';

export const useCreateScheduleRefusedExcel = () => {
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);

  const { filters } = scheduleRefusedList.scheduleRefusedListFilters;

  return useCallback(async () => {
    try {
      const file = await createScheduleRefusedExcelService(filters);

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'AGENDAMENTOS_RECUSADOS');
      }
    } catch (error: any) {
      showMessageFormatted({
        message: 'Erro ao tentar baixar o relatório.',
        type: 'error',
      });
    }
  }, [filters]);
};
