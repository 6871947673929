import { useCallback, useContext, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import {
  getScheduleListService,
  ScheduleListServiceResponse,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-list/services/get-schedule-list/get-schedule-list.service';
import { UserContext } from 'state/user-context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { convertScheduleListResponseToDomain } from './mappers/convert-schedule-list-response-to-domain/convert-schedule-list-response-to-domain.mapper';
import { IScheduleListDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-list/schedule-list.domain';

export const useGetScheduleList = () => {
  const { scheduleList, scheduleListInitialLoading } = useContext(ScheduleContext);
  const { setScheduleListSuccess, setScheduleListRequest, setScheduleListError } = scheduleList;
  const { setScheduleListInitialLoadingSuccess } = scheduleListInitialLoading;
  const { setScheduleListTotalRow } = scheduleList.scheduleListTotalRowProps;

  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  const statusCode = useMemo(() => {
    if (Array.isArray(scheduleList.scheduleListFiltersProps.filters.statusCode)) {
      return [...scheduleList.scheduleListFiltersProps.filters.statusCode];
    }

    return [scheduleList.scheduleListFiltersProps.filters.statusCode];
  }, [scheduleList.scheduleListFiltersProps.filters.statusCode]);

  const { data } = useQuery<ScheduleListServiceResponse>({
    queryKey: ['schedule-list-table', scheduleList.scheduleListFiltersProps.filters],
    queryFn: () => {
      setScheduleListRequest();
      return getScheduleListService({ ...scheduleList.scheduleListFiltersProps.filters, statusCode });
    },
    onError: (error) => {
      setScheduleListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const values = data.items.map((item) => convertScheduleListResponseToDomain(item, user!));
      const cachedData: ScheduleListServiceResponse[] = [];
      for (let i = 1; i <= data.meta.totalPages; i++) {
        const cache = queryClient.getQueryData<ScheduleListServiceResponse>([
          'schedule-list-table',
          { ...scheduleList.scheduleListFiltersProps.filters, page: i },
        ]);
        if (cache) {
          cachedData.push(cache);
        }
      }

      const combinedData: IScheduleListDomain[] = [];

      cachedData.forEach((cache) => {
        cache.items.forEach((item) => {
          const convertedItem = convertScheduleListResponseToDomain(item, user!);
          if (!combinedData.some((existingItem) => existingItem.id === convertedItem.id)) {
            combinedData.push(convertedItem);
          }
        });
      });

      values.forEach((schedule) => {
        if (!combinedData.some((existingItem) => existingItem.id === schedule.id)) {
          combinedData.push(schedule);
        }
      });

      setScheduleListSuccess(combinedData);
      setScheduleListTotalRow(data.totalRow);
      setScheduleListInitialLoadingSuccess();
    }
  }, [
    data,
    queryClient,
    scheduleList.scheduleListFiltersProps.filters,
    setScheduleListInitialLoadingSuccess,
    setScheduleListSuccess,
    setScheduleListTotalRow,
    user,
  ]);
};
