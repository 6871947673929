import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQueryClient } from 'react-query';
import { updateAcceptSchedulesService } from '../../services/update-accept-schedules/update-accept-schedules.service';

export const useUpdateAcceptSchedules = () => {
  const { scheduleList } = useContext(ScheduleContext);

  const reactQueryClient = useQueryClient();

  return useCallback(async () => {
    try {
      const checkboxScheduleIds = scheduleList.scheduleListCheckbox.checkbox.map((checkbox) => checkbox.id);

      await updateAcceptSchedulesService({ ids: checkboxScheduleIds });

      showMessageFormatted({
        message: 'Viagem(ns)  Aceita(s).',
        type: 'success',
      });

      scheduleList.scheduleListCheckbox.setScheduleListCheckbox([]);

      reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);
      reactQueryClient.invalidateQueries(['schedule-kanban-list', scheduleList.scheduleListFiltersProps.filters]);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao aceitar viagem(ns).',
        type: 'error',
      });
    }
  }, [reactQueryClient, scheduleList.scheduleListCheckbox, scheduleList.scheduleListFiltersProps.filters]);
};
