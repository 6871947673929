import styled from 'styled-components';

export const ContainerPaginationStyled = styled.div<{ isRelative?: boolean }>`
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
  margin-top: ${({ isRelative }) => (isRelative ? '20px' : '0')};
  top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ContainerTableStyled = styled.div`
  max-height: 270px;
  overflow: scroll;
`;
