import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 1.25rem;

  button {
    min-width: 100px !important;
  }
`;
