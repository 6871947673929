import React, { useMemo } from 'react';
import * as S from './filter-form-view.styled';
import { vehicleCategoryOptions, weighingTicketStatusOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';
import { useFormikContext } from 'formik';
import { SelectItem } from 'domain/select-item';
import dayjs from 'dayjs';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import { IWeighingListFilters } from 'src-new/pages/execution/pages/weighing/types/weighing-list-filters/weighing-list-filters.types';
import { WeighingFormMountItems } from '../../../../../weighing-list-form-mount-items/weighing-list-form-mount-items.component';
import MultiSelect from 'components/multi-select/multi-select';
import { WeighingStatus } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters.domain';

interface IFilterFormViewProps {
  apiCallCompany: (searchValue: string) => Promise<SelectItem[]>;
}

export const FilterFormView: React.FC<IFilterFormViewProps> = () => {
  const { values, setFieldValue, handleChange, submitForm } = useFormikContext<IWeighingListFilters>();

  const renderStatus = useMemo((): Array<{
    label: string;
    value: string;
  }> => {
    const new_array: { label: string; value: string }[] = [];

    weighingTicketStatusOptions.map((operation) => {
      if (values.status && values.status.length) {
        const hasvalue = values.status.includes(operation.value as WeighingStatus);
        hasvalue && new_array.push(operation);
      }
    });

    return new_array;
  }, [values.status]);
  return (
    <S.Wrapper>
      <S.ContainerRow>
        <S.SecondTitle>Data Entrada</S.SecondTitle>
        <S.LineSection columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.startEntryDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('startEntryDate', date?.format('DD/MM/YYYY'))}
          />

          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.endEntryDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('endEntryDate', date?.format('DD/MM/YYYY'))}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Data Saída</S.SecondTitle>
        <S.LineSection columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.startExitDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('startExitDate', date?.format('DD/MM/YYYY'))}
          />

          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.endExitDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('endExitDate', date?.format('DD/MM/YYYY'))}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Data Programada</S.SecondTitle>
        <S.LineSection columns="1fr 1fr">
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.startScheduledDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('startScheduledDate', date?.format('DD/MM/YYYY'))}
          />

          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY"
            views={['year', 'month', 'day']}
            value={dayjs(values.endScheduledDate ?? '', 'DD/MM/YYYY')}
            onChange={(date) => setFieldValue('endScheduledDate', date?.format('DD/MM/YYYY'))}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Motorista</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe o nome do motorista"
            id="driverName"
            name="driverName"
            type="text"
            value={values.driverName}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Placa do Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a placa do veículo"
            id="vehiclePlate"
            name="vehiclePlate"
            type="text"
            value={values.vehiclePlate}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Categoria do Veículo</S.SecondTitle>
        <S.LineSection columns="1fr">
          <Select
            placeholder="Selecione a Categoria do Veículo"
            id="vehicleCategory"
            name="vehicleCategory"
            value={values.vehicleCategory}
            setFieldValue={setFieldValue}
            options={vehicleCategoryOptions}
            isClearable
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Status</S.SecondTitle>
        <S.LineSection columns="1fr">
          <MultiSelect
            values={renderStatus}
            options={weighingTicketStatusOptions}
            menuPosition="fixed"
            onChange={(e) => {
              const operations = e.map((option) => option.value);
              setFieldValue('status', operations);
            }}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Cidade de Origem </S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="originCity"
            name="originCity"
            type="text"
            value={values.originCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Cidade de Destino</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="destinyCity"
            name="destinyCity"
            type="text"
            value={values.destinyCity}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Transportadora</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a Transportadora"
            id="shippingCompany"
            name="shippingCompany"
            type="text"
            value={values.shippingCompany}
            onChange={handleChange}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Ordem de Coleta</S.SecondTitle>
        <S.LineSection columns="1fr">
          <InputField
            placeholder="Informe a ordem de coleta"
            id="collectOrder"
            name="collectOrder"
            type="text"
            value={values.collectOrder}
            onChange={handleChange}
            upperCase={false}
          />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerRow>
        <S.SecondTitle>Item</S.SecondTitle>
        <S.LineSection columns="1fr">
          <WeighingFormMountItems />
        </S.LineSection>
      </S.ContainerRow>

      <S.ContainerButton>
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
      </S.ContainerButton>
    </S.Wrapper>
  );
};
