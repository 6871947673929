import React, { createContext, useCallback, useMemo, useReducer } from 'react';

import {
  IAllocationDriver,
  IAllocationRegisterContext,
  IAllocationShippingCompany,
  IAllocationVehicle,
  ICurrentAllocation,
} from './allocation.types';
import { allocationRegisterReducer } from './allocation.reducer';
import { IAllocationsAction, initialState } from './allocation.actions';

import { IResumeInfo } from 'components/resume/resume-info.types';

interface IAllocationRegisteContexProviderProps {
  children: React.ReactNode;
}

export const AllocationRegisterContext = createContext<IAllocationRegisterContext>(initialState);

export const AllocationRegisterContextProvider: React.FC<IAllocationRegisteContexProviderProps> = ({
  children,
}): JSX.Element => {
  const [state, dispatch] = useReducer(allocationRegisterReducer, initialState);
  const [nfeIsLoading, setNfeIsLoading] = React.useState(0);

  const setResumeRules = useCallback((infoRules: IResumeInfo) => {
    dispatch({
      type: IAllocationsAction.SET_RESUME_RULES,
      payload: infoRules,
    });
  }, []);

  const setDriver = useCallback((driver: IAllocationDriver) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_DRIVER_LOAD,
      payload: { driver },
    });
  }, []);

  const setShippingCompany = useCallback((shippingCompany: IAllocationShippingCompany) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_SHIPPING_COMPANY_LOAD,
      payload: { shippingCompany },
    });
  }, []);

  const setVehicle = useCallback((vehicle: IAllocationVehicle) => {
    dispatch({
      type: IAllocationsAction.ALLOCATION_VEHICLE_LOAD,
      payload: { vehicle },
    });
  }, []);

  const currentAllocationError = useCallback((message: string) => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_ERROR,
      payload: { message },
    });
  }, []);

  const currentAllocationRequest = useCallback(() => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_REQUEST,
      payload: null,
    });
  }, []);

  const currentAllocationSuccess = useCallback((allocation: ICurrentAllocation) => {
    dispatch({
      type: IAllocationsAction.GET_CURRENT_ALLOCATION_SUCCESS,
      payload: { allocation },
    });
  }, []);

  const nfeLoading = useCallback(
    (isLoading: number) => {
      setNfeIsLoading(isLoading);
    },
    [setNfeIsLoading],
  );

  const value = useMemo((): IAllocationRegisterContext => {
    return {
      allocation: state.allocation,
      loading: state.loading,
      infoRules: state.infoRules,
      nfeIsLoading: nfeIsLoading,
      nfeLoading,
      setDriver,
      setShippingCompany,
      setVehicle,
      currentAllocationError,
      currentAllocationRequest,
      currentAllocationSuccess,
      setResumeRules,
    };
  }, [
    state.allocation,
    state.loading,
    state.infoRules,
    nfeIsLoading,
    nfeLoading,
    setDriver,
    setShippingCompany,
    setVehicle,
    currentAllocationError,
    currentAllocationRequest,
    currentAllocationSuccess,
    setResumeRules,
  ]);

  return <AllocationRegisterContext.Provider value={value}>{children}</AllocationRegisterContext.Provider>;
};
