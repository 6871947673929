import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';

export const getOperatedMatchesKanbanListService = async (
  params: IMatchesListParams,
): Promise<IListPage<IMatchesKanbanListResponse>> => {
  const { data } = await API_TRAVELS_SVC.get<IListPage<IMatchesKanbanListResponse>>('/v1/matches', {
    params: { ...params, matchType: 'operated' },
  });

  return data;
};
