import React, { Dispatch, SetStateAction, useMemo } from 'react';
import moment from 'moment';
import { Button, Tag } from 'logshare-ui-kit';
import { Rating } from '@mui/material';

import HeaderTable from '../header';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { ColorsV2Type } from 'styles/types/colors';
import { BiddingModal } from 'pages-v2/idleness-biddings/types';
import { PageInfo } from 'domain-v2/inputs/page-info';
import { ModalState } from 'domain-v2/inputs/modals';
import { IIdlenessBidding } from 'domain-v2/bidding';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';

interface ContentTable {
  biddings: Array<IIdlenessBidding>;
  pageInfo: PageInfo;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  setBuyBidding: Dispatch<SetStateAction<ModalState<BiddingModal>>>;
  setDiscardBidding: Dispatch<SetStateAction<ModalState<BiddingModal>>>;
}

export const ListBiddingsTable: React.FC<ContentTable> = ({
  biddings,
  pageInfo,
  isLoading,
  handlePaginate,
  setBuyBidding,
}) => {
  const mountTable = useMemo((): Array<IContentTable> => {
    return biddings.map((bidding) => {
      return [
        {
          value: (
            <S.CompanyImageContainer>
              <S.Text>{bidding.id}</S.Text>

              <img
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 25,
                  marginTop: 3,
                }}
                src={bidding.companyImage}
                alt={bidding.companyImage}
              />
            </S.CompanyImageContainer>
          ),
          flex: 0.6,
        },
        {
          value: moment(bidding.created_at).format('DD/MM/YYYY'),
          flex: 0.6,
        },

        {
          value: bidding.company.name,
          flex: 1.8,
        },

        {
          value: <Tag label="Não" color={ColorsV2Type.Red} variant="ghost" display="flex" size="md" />,
          flex: 0.6,
        },

        {
          value: <Rating name="disabled" value={0} size="small" disabled />,
          flex: 0.8,
        },

        {
          value: convertCurrencyFormat.format(bidding.value),
          subtitle: '+ Impostos',
          flex: 0.6,
        },
        {
          value: (
            <S.ContainerButton>
              <Button label="Negociar" size="sm" color="red" onPress={() => null} variant="ghost" disabled />
            </S.ContainerButton>
          ),
          flex: 0.5,
        },
        {
          value: (
            <S.ContainerButton>
              <Button
                label="Comprar"
                size="sm"
                color="blue"
                onPress={() =>
                  setBuyBidding((oldModal) => ({
                    ...oldModal,
                    open: true,
                    data: {
                      biddingId: bidding.id,
                      proposalValue: bidding.value,
                    },
                  }))
                }
                variant="solid"
                disabled={!bidding.value}
              />
            </S.ContainerButton>
          ),
          flex: 0.5,
        },
      ];
    });
  }, [biddings, setBuyBidding]);

  const renderAllocationTable = useMemo(() => {
    return (
      <HeaderTable
        contentTable={mountTable}
        pageInfo={pageInfo}
        changePage={handlePaginate}
        isLoading={isLoading}
        clickRowAction={() => null}
      />
    );
  }, [mountTable, pageInfo, handlePaginate, isLoading]);

  const tabContent = useMemo(() => {
    const tabs = [
      {
        name: 'Ofertas Recebidas',
        content: renderAllocationTable,
      },
    ];

    return tabs;
  }, [renderAllocationTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={() => null} tabActive={'Ofertas Recebidas'} />;
};
