import React from 'react';
import { VirtualKeyboardView } from './virtual-keyboard-view.component';

export interface VirtualKeyboardParams {
  onChange: (input: string) => void;
  onKeyPress?: ((button: string, e?: MouseEvent | undefined) => any) | undefined;
  keyboardType?: 'numbers' | 'default';
}
export const VirtualKeyboard: React.FC<VirtualKeyboardParams> = (params) => {
  return <VirtualKeyboardView {...params} />;
};
