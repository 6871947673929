import { ScheduleList, ScheduleListParams } from './types';
import { listMapper } from './mappers';

import API from 'utils/API/API';
import { ISchedulingKeys, ScheduleDomain } from 'domain/schedule';
import { IListPage, PageMeta } from 'domain/page-info';
import { IUser } from 'domain/login';

export interface ISchedulingListEntity {
  items: Array<ScheduleDomain>;
  meta: PageMeta;
}

const LIMIT_PAGE = 50;

export const scheduleRegisterService = async (schedule: ScheduleDomain) => {
  await API.post('v2/schedules', schedule);
};

export const scheduleList = async (params: ScheduleListParams, user?: IUser): Promise<IListPage<ScheduleList>> => {
  const { data } = await API.get<ISchedulingListEntity>('v2/schedules', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
      scheduleType: 'ftl,vr',
    },
  });

  return {
    list: data.items.map((item) => listMapper(item, user)),
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};

export const getScheduleByIdService = async (scheduleId: string): Promise<ScheduleDomain> => {
  const { data } = await API.get(`v2/schedules/${scheduleId}`);
  return data;
};

export const scheduleListV2 = async (params: ScheduleListParams, user?: IUser): Promise<IListPage<ScheduleList>> => {
  const { data } = await API.get<ISchedulingListEntity>('v2/schedules/list-v2', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
      scheduleType: 'ftl,vr',
    },
  });

  return {
    list: data.items.map((item) => listMapper(item, user)),
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};

export const schedulingKeysService = async (scheduleId: string): Promise<ISchedulingKeys[]> => {
  const { data } = await API.get(`v3/schedule/${scheduleId}/custom-fields`);

  return data;
};
