import { FC, useCallback } from 'react';
import { Button as ButtonUI } from 'logshare-ui-kit';
import NearMeIcon from '@mui/icons-material/NearMe';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import * as S from './styled';

import { UseDeletePlanningVehicle } from 'pages-v2/my-route/hooks/delete-planning-vehicle';
import { ModalPlanningType } from 'pages-v2/my-route/components/form/types/modal';
import { PlanningRouteShipping } from 'domain-v2/planning-route';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';

interface SectionPlanningVehicleProps {
  indexPlanningRoute: number;
  allowedShippingIndex: number;
  indexPlanningVehicle: number;
  planningShipping: PlanningRouteShipping;
  handleOpenPlanningShippingModal: (
    open: boolean,
    planningRouteIndex: number,
    planningVehicleIndex: number,
    allowedShippingIndex: number,
    type: ModalPlanningType,
    data?: PlanningRouteShipping,
  ) => void;
  handleDeletePlanningShipping: (allowedShippingIndex: number) => void;
  handlePlanningScheduleModal: (
    open: boolean,
    indexPlanningRoute: number,
    allowedVehicleIndex: number,
    allowedShippingIndex: number,
  ) => void;
}

export const SectionPlanningShipping: FC<SectionPlanningVehicleProps> = ({
  indexPlanningRoute,
  allowedShippingIndex,
  indexPlanningVehicle,
  planningShipping,
  handleOpenPlanningShippingModal,
  handleDeletePlanningShipping,
  handlePlanningScheduleModal,
}) => {
  const handleOpenModalDetails = useCallback(() => {
    handleOpenPlanningShippingModal(
      true,
      indexPlanningRoute,
      indexPlanningVehicle,
      allowedShippingIndex,
      'update',
      planningShipping,
    );
  }, [handleOpenPlanningShippingModal]);

  const handleDeleteShipping = useCallback(async () => {
    if (planningShipping.id) {
      await UseDeletePlanningVehicle(planningShipping.id);
    }

    handleDeletePlanningShipping(allowedShippingIndex);
  }, [planningShipping]);

  return (
    <S.ContainerVehicleListStyled key={allowedShippingIndex}>
      <LineSectionStyled columns="1.42fr 1fr 1fr 1fr 1fr 0.65fr">
        <S.TableStyled>
          Transportadora
          <S.ContainerInfoStyled>{planningShipping.companyShippingName}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Planejadas
          <S.ContainerInfoStyled>{planningShipping.plannedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Ofertadas
          <S.ContainerInfoStyled>{planningShipping.offeredTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Aceitas
          <S.ContainerInfoStyled>{planningShipping.acceptedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <S.TableStyled>
          Viagens Recusadas
          <S.ContainerInfoStyled>{planningShipping.refusedTrips}</S.ContainerInfoStyled>
        </S.TableStyled>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 15,
              marginTop: 2,
            }}
          >
            <ButtonUI
              label={''}
              LeftIcon={<EditIcon />}
              color={'aqua'}
              onPress={handleOpenModalDetails}
              variant={'solid'}
              size={'x-sm'}
            />
            <ButtonUI
              label={''}
              LeftIcon={<NearMeIcon />}
              color={'green'}
              onPress={() =>
                handlePlanningScheduleModal(true, indexPlanningRoute, indexPlanningVehicle, allowedShippingIndex)
              }
              variant={'solid'}
              size={'x-sm'}
            />
            <ButtonUI
              label={''}
              LeftIcon={<DeleteIcon />}
              color={'red'}
              onPress={handleDeleteShipping}
              variant={'solid'}
              size={'x-sm'}
              disabled={planningShipping.offeredTrips > 0}
            />
          </div>
        </div>
      </LineSectionStyled>
    </S.ContainerVehicleListStyled>
  );
};
