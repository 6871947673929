/* eslint-disable @typescript-eslint/no-explicit-any */
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import { Button as ButtonLogshare } from 'logshare-ui-kit';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import {
  ForgatPassawordContainerStyled,
  FormStyled,
  FormikStyled,
  IconWrapper,
  InputDivider,
  LoginContainerButton,
  MicrosoftIcon,
  SectionStyled,
  WrapperSectionLoginStyled,
} from './form-register-login.styled';

import Theme from 'styles/theme';
import ResetPassword from 'components/reset-password/reset-password';
import OutlineInput from 'components/layout/material-input/outline-input';
import Button from 'components/button/button';

const secretKey = import.meta.env.VITE_RE_CAPTCHA;
interface IUser {
  usuario: string;
  password: string;
  recaptchaLogin?: string;
}

type FormRegisterLoginProps = {
  initialValues: IUser;
  validationSchema: object;
  handleRegister: (usuario: string, password: string, recaptchaLogin: string) => void;
  handleReset: () => void;
  handleMicrosoft: () => void;
  isLoading: boolean;
};

const FormRegisterLoginView: React.FC<FormRegisterLoginProps> = ({
  isLoading,
  initialValues,
  validationSchema,
  handleRegister,
  handleReset,
  handleMicrosoft,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginAttempts, setLoginAttempts] = useState<number>(0);
  const [recaptchaResponse, setRecaptchaResponse] = useState('');

  const handleRecaptchaResolved = (response: any) => {
    setRecaptchaResponse(response);
  };

  return (
    <FormikStyled
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setLoginAttempts(loginAttempts + 1);
        handleRegister(values.email, values.password, recaptchaResponse);
        setSubmitting(false);
      }}
    >
      {({ handleChange, handleSubmit, errors, touched }) => (
        <FormStyled onSubmit={handleSubmit}>
          <SectionStyled name="Informações de Login">
            <WrapperSectionLoginStyled>
              <OutlineInput
                id="email"
                name="email"
                label="Usuário"
                type="text"
                fullWidth
                upperCase
                InputProps={{
                  startAdornment: (
                    <IconWrapper>
                      <PersonIcon style={{ color: '#909EA7', width: '15px', marginRight: 5 }} />
                    </IconWrapper>
                  ),
                }}
                onChange={(e) => {
                  const uppercaseValue = e.target.value.toUpperCase();
                  handleChange({ target: { name: 'email', value: uppercaseValue } });
                }}
                error={!!errors.email && !!touched.email}
                errormessage={String(errors.email) ?? ''}
              />

              <InputDivider />

              <OutlineInput
                id="password"
                label="Senha"
                name="password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <IconWrapper>
                      <LockIcon style={{ color: '#909EA7', width: '15px', marginRight: 5 }} />
                    </IconWrapper>
                  ),
                  endAdornment: (
                    <IconWrapper onClick={() => setShowPassword(!showPassword)}>
                      <Theme.icons.eyePasswordIcon />
                    </IconWrapper>
                  ),
                }}
                onChange={handleChange}
                error={!!errors.password && !!touched.password}
                errormessage={String(errors.password) ?? ''}
              />
            </WrapperSectionLoginStyled>

            {loginAttempts >= 2 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <ReCAPTCHA sitekey={String(secretKey)} onChange={handleRecaptchaResolved} size="normal" />
              </div>
            )}
          </SectionStyled>
          <LoginContainerButton>
            <ButtonLogshare type="submit" variant="solid" size="md" color="blue" label="Entrar" isLoading={isLoading} />            
            <Button
              title="Entrar com Microsoft"
              callback={handleMicrosoft}
              bgColor="white"
              icon={<MicrosoftIcon />}
              iconPosition="left"
              size="full"
            />
          </LoginContainerButton>
          <ForgatPassawordContainerStyled>
            <ResetPassword callback={handleReset} />
          </ForgatPassawordContainerStyled>
        </FormStyled>
      )}
    </FormikStyled>
  );
};

export default FormRegisterLoginView;
