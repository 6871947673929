import { includes } from 'lodash';
import { EquipmentVoucherActionType, EquipmentVoucherListActionType } from '../equipment-voucher.action';
import { equipmentVoucherReducerResetState } from './equipment-voucher-reducer.constants';
import { IEquipmentVoucherListActions } from '../types/equipment-voucher-list.types';
import {
  IEquipmentVoucherContext,
  ITransactionsActions,
} from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/types/equipment-voucher.types';
import { equipmentVoucherListReducer } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/reducers/equipment-voucher-list/equipment-voucher-list.reducer';

export const equipmentVoucherReducer = (
  state: IEquipmentVoucherContext,
  action: ITransactionsActions,
): IEquipmentVoucherContext => {
  if (includes(Object.values(EquipmentVoucherListActionType), action.type)) {
    return equipmentVoucherListReducer(state, action as IEquipmentVoucherListActions);
  }

  if (action.type === EquipmentVoucherActionType.EQUIPMENT_VOUCHER_RESET) {
    return equipmentVoucherReducerResetState(state);
  }

  return state;
};
