import React from 'react';

import CardContentView from './card-content-view';

import { IKanbanCardProps } from 'pages/task-vision/types/types';

const CardContent: React.FC<IKanbanCardProps> = ({
  id,
  name,
  vehicle,
  origin,
  destiny,
  collect,
  delivery,
  vehicleCategory,
  typeSchedule,
  typeOperation,
  handleSelected,
}) => {
  return (
    <CardContentView
      id={id}
      name={name}
      vehicle={vehicle}
      origin={origin}
      destiny={destiny}
      collect={collect}
      delivery={delivery}
      vehicleCategory={vehicleCategory}
      typeOperation={typeOperation}
      typeSchedule={typeSchedule}
      handleSelected={handleSelected}
    />
  );
};

export default CardContent;
