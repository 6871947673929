/* eslint-disable @typescript-eslint/no-explicit-any */
import { showToast } from 'components/toast/toast';

type DownloadFileType =
  | 'pdf'
  | 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'vnd.ms-excel.sheet.macroEnabled.12'
  | 'vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'vnd.ms-excel;';

export const DownloadFile = (file: BlobPart, type: DownloadFileType, name: string) => {
  const url = window.URL.createObjectURL(new Blob([file], { type: `application/${type}` }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};

export const downloadFromUrl = async (filePath: string, name?: string) => {
  if (!filePath) return;

  const link = document.createElement('a');
  link.href = filePath;
  link.target = '_blank';
  link.download = name ? name : filePath.substring(filePath.lastIndexOf('/') + 1);
  link.click();
};

export const downloadDocument = async (filePath: string, name?: string) => {
  if (!filePath) return;

  try {
    const response = await fetch(filePath);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = name || filePath.substring(filePath.lastIndexOf('/') + 1);
    link.click();

    URL.revokeObjectURL(link.href);
  } catch (error) {
    showToast({
      message: 'Ocorreu um erro ao fazer o download',
      type: 'error',
    });
  }
};

export const downloadTemplate = async (link: any) => {
  const element = document.createElement('a');
  element.target = '_blank';
  element.setAttribute('href', link);
  element.setAttribute('download', '');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
