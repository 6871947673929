import React, { useCallback, useContext, useMemo } from 'react';
import { CompanyInformationFreetimeView } from './company-information-freetime-view.component';
import { ICompany } from 'domain/company';
import { useFormikContext } from 'formik';
import { UserContext } from 'state/user-context';
import { vehicleTypeOptions } from 'domain/global-inputs';
import { SelectPropsOptions } from 'components/select/select';

export const CompanyInformationFreetime: React.FC = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<ICompany>();

  const { isAdmin, isZMaster } = useContext(UserContext);

  const handleChange = useCallback(
    (field: string, value: string, index: number) => setFieldValue(`retentionFines[${index}].${field}`, value),
    [setFieldValue],
  );

  const handleAddRow = useCallback(
    () =>
      setFieldValue(`retentionFines[${values.retentionFines?.length ?? 0}]`, {
        vehicleType: '',
        freeTime: '',
        hourlyFine: 0,
        dailyFine: 0,
      }),
    [setFieldValue, values.retentionFines?.length],
  );

  const handleRemoveRow = useCallback(
    (index: number) => setFieldValue('retentionFines', values.retentionFines?.filter((_, i) => i !== index)),
    [setFieldValue, values.retentionFines],
  );

  const disableForm = useMemo((): boolean => !isAdmin && !isZMaster, [isAdmin, isZMaster]);

  const retentionVehicleTypeOptions = useMemo((): Array<SelectPropsOptions> => {
    if (!values.retentionFines) return [];

    const retentionVehicleTypes = values.retentionFines.map((item) => item.vehicleType);

    const vehicleOptions = [{ value: 'todos', label: 'TODOS' }, ...vehicleTypeOptions];

    return vehicleOptions.map((option) => ({
      ...option,
      isDisabled: retentionVehicleTypes.includes(String(option.value)),
    }));
  }, [values.retentionFines]);

  return (
    <CompanyInformationFreetimeView
      retentionFines={values.retentionFines}
      retentionVehicleTypeOptions={retentionVehicleTypeOptions}
      errors={errors}
      touched={touched}
      disableForm={disableForm}
      handleChange={handleChange}
      handleAddRow={handleAddRow}
      handleRemoveRow={handleRemoveRow}
    />
  );
};
