import { ILocationsContext } from './types/locations.types';

export const locationsInitialState: ILocationsContext = {
  locationsList: {
    statusRequest: 'INITIAL',
    locations: [],
    filterActions: {
      filters: {
        column: 'Nome',
        isPageLimit: false,
        order: 'ASC',
        page: 1,
        searchValue: '',
        status: 'active',
      },
      setFilters: () => undefined,
    },
    setLocationsListRequest: () => undefined,
    setLocationsListSuccess: () => undefined,
    setLocationsListError: () => undefined,
    setLocationsListReset: () => undefined,
  },
  setLocationsResetContext: () => undefined,
};
