import { ITransportCompanyCreatedEntity } from '../entities/transport-companies.entity';

import API from 'utils/API/API';
import { convertTransportCompanyCreatedEntityToDomainMapper } from 'pages/transport-company/mappers/convert-transport-created-entity-to-domain/convert-transport-created-entity-to-domain';
import { ITransportCompanyCreated } from 'domain/transport-company';
import { showToast } from 'components/toast/toast';

export const transportDetailsService = async (transportId: number): Promise<ITransportCompanyCreated> => {
  try {
    const { data } = await API.get<ITransportCompanyCreatedEntity>(`shipping-company/${transportId}`);

    return convertTransportCompanyCreatedEntityToDomainMapper(data);
  } catch (error) {
    showToast({
      message: 'Você não tem permissão para acessar essa transportadora',
      type: 'error',
    });
    throw new Error('');
  }
};
