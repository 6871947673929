import { IEquipmentVoucherContext } from '../types/equipment-voucher.types';

export const equipmentVoucherReducerResetState = (state: IEquipmentVoucherContext): IEquipmentVoucherContext => {
  return {
    ...state,
    equipmentVoucherList: {
      ...state.equipmentVoucherList,
      statusRequest: 'INITIAL',
      equipmentList: [],
      selectionProps: {
        ...state.equipmentVoucherList.selectionProps,
        selectedIds: [],
      },
      deleteModalProps: {
        ...state.equipmentVoucherList.deleteModalProps,
        isOpen: false,
        isLoading: false,
      },
    },
  };
};
