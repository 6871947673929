import { FC } from 'react';

export const ResumeNewIcons: FC = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 2.625H11.125V1.875C11.125 1.668 10.957 1.5 10.75 1.5H8.83712C8.66312 0.645375 7.90525 0 7 0C6.09475 0 5.33687 0.645375 5.16288 1.5H3.25C3.043 1.5 2.875 1.668 2.875 1.875V2.625H2.5C1.46613 2.625 0.625 3.46613 0.625 4.5V16.125C0.625 17.1589 1.46613 18 2.5 18H11.5C12.5339 18 13.375 17.1589 13.375 16.125V4.5C13.375 3.46613 12.5339 2.625 11.5 2.625ZM9.71462 9.64725L7 12.3619C6.53875 12.8231 5.79063 12.8231 5.32938 12.3619L4.28537 11.3179C3.93925 10.9718 3.93925 10.4111 4.28537 10.065C4.6315 9.71887 5.19212 9.71887 5.53825 10.065L6.1645 10.6912L8.46137 8.39437C8.8075 8.04825 9.36812 8.04825 9.71425 8.39437C10.0604 8.7405 10.0607 9.30113 9.71462 9.64725ZM10.375 3.75H3.625V2.25H5.5C5.707 2.25 5.875 2.082 5.875 1.875C5.875 1.25475 6.37975 0.75 7 0.75C7.62025 0.75 8.125 1.25475 8.125 1.875C8.125 2.082 8.293 2.25 8.5 2.25H10.375V3.75Z"
        fill="black"
      />
    </svg>
  );
};
