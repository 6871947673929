import React, { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { ScheduleContextProvider } from '../../contexts/schedule/schedule.context';
import { ScheduleDetailsPageBody } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-page-body/schedule-details-page-body.component';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const ScheduleDetailsPage: FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <InfiniteScrollListTableContextProvider>
        <ScheduleContextProvider>
          <ScheduleDetailsPageBody />
        </ScheduleContextProvider>
      </InfiniteScrollListTableContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
