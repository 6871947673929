/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Tag } from 'logshare-ui-kit';

import { convertColumnName, convertNameColumn } from '../utils/convert-column-name/index';
import { convertIdlenessValues, convertTransportRouteValues } from '../mappers/convert-idleness-values';

import * as S from './styled';
import BackhaulListBodyView from './list';
import HeaderModalMatchList from './header';
import FooterModalMatchList from './footer';

import { convertOperationType } from 'utils/operation-type';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { MatchesListService, TransportRouterMatchesListService } from 'services/matches/list';
import { companyDetailsService } from 'services/company/details';
import { ISelectedBackhaul } from 'pages-v2/schedule/register/form/types/backhaul';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { PageMeta } from 'domain/page-info';
import { IMatchesListParams, IResponseMatches, IResponseSchedulesMatches } from 'domain/matches';
import { IdlenessListInput } from 'domain/idleness';
import { IBackhaulCreated } from 'domain/backhaul';
import { showToast } from 'components/toast/toast';

interface IForm {
  idlenessId?: string;
  idleness?: IdlenessListInput;
  transportRoute?: IBackhaulCreated;
  scheduleValues?: ScheduleRegisterInput;
  matchesFromBackhaul?: IResponseSchedulesMatches[];
  wrapperStyles?: React.CSSProperties;
  matchesModalStyles?: React.CSSProperties;
  matchesScheduleModalStyles?: React.CSSProperties;
  matchesPickDateModalStyles?: React.CSSProperties;
  closeModal: () => void;
  setSelectedBackhaul?: React.Dispatch<React.SetStateAction<ISelectedBackhaul>>;
}

const initialPageMeta = {} as PageMeta;
const initialParams = {} as IMatchesListParams;

export const ModalMatches: React.FC<IForm> = ({
  idlenessId,
  idleness,
  transportRoute,
  scheduleValues,
  matchesFromBackhaul,
  wrapperStyles,
  matchesModalStyles,
  matchesScheduleModalStyles,
  matchesPickDateModalStyles,
  closeModal,
  setSelectedBackhaul,
}) => {
  const { user, isAdmin, isZMaster } = useContext(UserContext);
  const [matches, setMatches] = useState<IResponseMatches[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [params, setParams] = useState<IMatchesListParams>({
    ...initialParams,
    column: 'match',
    order: 'ASC',
    offerTo: 'BACKHAUL EXTERNO',
  });
  const [pageMeta, setPageMeta] = useState<PageMeta>({
    ...initialPageMeta,
    currentPage: 1,
  });

  const userImage = useMemo(async () => {
    const logshare = await companyDetailsService(2);
    const company = await companyDetailsService(user?.companyId ?? 0);
    return { logshare, company };
  }, [user?.companyId]);

  const handleCompanyImage = async (matches: IResponseMatches[]) => {
    const company = (await userImage).company;
    const logshareImage = (await userImage).logshare;

    const promises = matches.map(async (item) => {
      if (item.transportRouter.companyId === user?.companyId) {
        return {
          ...item,
          companyLogo: company.fileKey,
        };
      }
      if (item.transportRouter?.company?.companysId?.includes(company.id) || item.transportRouter.backhaulPartner) {
        const companyUserImage = await companyDetailsService(item.transportRouter?.companyId);
        return {
          ...item,
          companyLogo: companyUserImage.fileKey,
        };
      }
      if (item.company?.companysId?.includes(company.id)) {
        const companyUserImage = await companyDetailsService(item.company.id);
        return {
          ...item,
          companyLogo: companyUserImage.fileKey,
        };
      }

      if (isAdmin || isZMaster) {
        const companyUserImage = await companyDetailsService(item.transportRouter?.companyId);
        return {
          ...item,
          companyLogo: companyUserImage.fileKey,
        };
      }

      return {
        ...item,
        companyLogo: logshareImage.fileKey,
      };
    });

    return promises;
  };

  useEffect(() => {
    (async () => {
      if (matchesFromBackhaul) {
        const matches = convertTransportRouteValues(matchesFromBackhaul);

        const promises = await handleCompanyImage(matches);

        const resolvedPromises = await Promise.all(promises);
        setPageMeta((prevState) => ({
          ...prevState,
          totalItems: matchesFromBackhaul.length,
          totalPages: Math.ceil(matchesFromBackhaul.length / 20),
        }));

        const itemsPerPage = 20;
        const startIndex = (pageMeta?.currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        const matchesToDisplay = resolvedPromises.slice(startIndex, endIndex);

        setMatches(matchesToDisplay);
        return;
      }

      if (transportRoute) {
        try {
          setIsLoading(true);

          const { meta, matches } = await TransportRouterMatchesListService(
            params.currentPage ?? pageMeta.currentPage,
            transportRoute.id,
            params,
          );

          const newMatches = matches.map((match: any) => ({
            ...match,
            transportRouter: match.idleness,
            idleness: match.transportRouter,
          }));

          const promises = await handleCompanyImage(newMatches);

          const resolvedPromises = await Promise.all(promises);

          setPageMeta(meta);
          setMatches(resolvedPromises);
        } catch (err) {
          showToast({
            message: 'Houve um erro ao listar os matches',
            type: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      }

      if (!idlenessId) return;

      try {
        setIsLoading(true);
        const { meta, matches } = await MatchesListService(
          params.currentPage ?? pageMeta.currentPage,
          idlenessId,
          params,
        );

        const promises = await handleCompanyImage(matches);

        const resolvedPromises = await Promise.all(promises);

        setPageMeta(meta);
        setMatches(resolvedPromises);
      } catch (err) {
        showToast({
          message: 'Houve um erro ao listar os matches',
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [pageMeta.currentPage, idlenessId, params, transportRoute, matchesFromBackhaul]);

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleOrderTable = (column: string) => {
    const columnName = convertColumnName(column);

    if (columnName) {
      setParams((prevState) => ({
        ...prevState,
        column: columnName,
      }));
    }
  };

  const idlenessValues = useMemo(() => {
    return convertIdlenessValues(idleness || scheduleValues || transportRoute);
  }, [idleness, scheduleValues, transportRoute]);

  const orderSelectedValues = useMemo(() => {
    const orderBy = convertNameColumn(params.column ?? '');
    const sortDirection = params.order ?? '';
    return { orderBy, sortDirection };
  }, [params.column, params.order]);

  return (
    <S.Wrapper onClick={handleBackgroundClick} style={wrapperStyles}>
      <S.Content style={matchesModalStyles}>
        <S.CustomWrapper style={{ display: 'flex', flexDirection: 'column' }}>
          <S.ModalTitle>
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <S.Title>Matches Propostos {transportRoute ? 'para Venda' : 'para Compra'}</S.Title>
              {(idlenessId || !!transportRoute) && (
                <>
                  <Tag
                    label={`OFERTA ${idlenessId ?? transportRoute?.id}`}
                    color={ColorsV2Type.GrayDark}
                    variant="ghost"
                    display="flex"
                    size="md"
                  />
                  <Tag
                    label={
                      convertOperationType(
                        idleness?.operationType ?? scheduleValues?.operation ?? transportRoute?.operationType ?? '',
                      ).label
                    }
                    color={
                      convertOperationType(
                        idleness?.operationType ?? scheduleValues?.operation ?? transportRoute?.operationType ?? '',
                      ).color
                    }
                    variant="ghost"
                    display="flex"
                    size="md"
                  />
                </>
              )}
            </div>

            <S.ButtonClose onClick={closeModal}>X</S.ButtonClose>
          </S.ModalTitle>
          <HeaderModalMatchList idleness={idlenessValues} />

          <BackhaulListBodyView
            matches={matches || []}
            idleness={idlenessValues}
            pageInfo={pageMeta || initialPageMeta}
            backhaulBuyId={Number(idlenessId)}
            matchesPickDateModalStyles={matchesPickDateModalStyles}
            matchesScheduleModalStyles={matchesScheduleModalStyles}
            isLoading={isLoading}
            isScheduleMatches={!idlenessId && !transportRoute}
            isTransportRouteMatches={!!transportRoute}
            orderSelected={orderSelectedValues}
            handleFilter={(values) => setParams({ ...values })}
            closeModal={closeModal}
            setSelectedBackhaul={setSelectedBackhaul}
            clickOrderTableBy={handleOrderTable}
            clickSortDirection={() => {
              setParams((prevParams) => ({
                ...prevParams,
                order: params.order === 'ASC' ? 'DESC' : 'ASC',
              }));
            }}
          />
        </S.CustomWrapper>

        <S.CustomWrapper>
          <FooterModalMatchList
            pageInfo={pageMeta}
            changePage={(page) => setPageMeta((prev) => ({ ...prev, currentPage: page }))}
          />
        </S.CustomWrapper>
      </S.Content>
    </S.Wrapper>
  );
};
