import { IAcceptedMatchEntity } from '../entities/accepted-matches.entity';

import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/accepted-matches/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { convertAcceptedMatchesCreatedEntityToDomainMapperV2 } from 'pages/accepted-matches/mappers/convert-accepted-matches-created-entity-to-domain-v2/convert-accepted-matches-created-entity-to-domain-v2.mapper';
import { IListPage, PageInfo } from 'domain/page-info';
import { IAcceptedMatchesCreatedV2, IAcceptedMatchesStatus } from 'domain/accepted-matches';

export interface IAcceptedMatchesListProps {
  search: string;
  page: number;
  status?: IAcceptedMatchesStatus;
  order: string;
  column: string;
}

export interface IAcceptedMatchesListEntity {
  list: Array<IAcceptedMatchEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const acceptedMatchesListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IAcceptedMatchesListProps): Promise<IListPage<IAcceptedMatchesCreatedV2>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/my-router?search=${search}&status=${parsedStatus}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);

    const { items, meta } = data;
    return {
      list: items.map(convertAcceptedMatchesCreatedEntityToDomainMapperV2),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar suas rotas');
  }
};
