import { useCallback } from 'react';

import { trackingOccurrenceDetailService } from 'pages/tracking-occurrence/services/tracking-occurrence-details/tracking-occurrence-details.service';
import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';

const useTrackingOccurrenceDetailHook = () => {
  return useCallback(async (trackingId: string): Promise<ITrackingOccurrenceCreated> => {
    const trackingOccurrence = await trackingOccurrenceDetailService(trackingId);
    return trackingOccurrence;
  }, []);
};

export { useTrackingOccurrenceDetailHook };
