import { ICompany } from 'domain/company';
import { useCallback } from 'react';
import { getCompanyService } from 'src-new/services/get-company/get-company.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetCompany = () => {
  return useCallback(async (companyId: number): Promise<ICompany | undefined> => {
    try {
      return getCompanyService(companyId);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter detalhes da empresa.',
        type: 'error',
      });
    }
  }, []);
};
