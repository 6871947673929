import React from 'react';

import FloatingLoading from '../floating-loading/floating-loading';

import { formatSchedule } from './floating-resume.utils';

import { getScheduleByIdService } from 'services/schedule';
import Resume from 'components/resume/resume';

interface FloatingProps {
  scheduleId: number;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const FloatingResume: React.FC<FloatingProps> = (props) => {
  const { scheduleId, onClose } = props;

  const [schedule, setSchedule] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const schedule = await getScheduleByIdService(String(scheduleId));

        const formattedSchedule = formatSchedule(schedule);

        setSchedule(formattedSchedule);
      } catch (err) {
        alert(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [scheduleId]);

  if (isLoading) return <FloatingLoading isLoading={true} />;

  if (schedule?.id)
    return (
      <Resume
        idSchedule={schedule.id}
        style={resumeStyles}
        setInfo={() => null}
        values={schedule}
        canShareInfoData={false}
        canClose={true}
        paddingTop="10"
        handleCloseResume={onClose}
      />
    );

  return <></>;
};

export const resumeStyles: React.CSSProperties = {
  width: '100px',
  height: '100%',
  position: 'absolute',
  right: 60,
  top: 7,
  zIndex: 999,
  borderRadius: 5,
};

export default React.memo(FloatingResume);
