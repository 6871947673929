import React from 'react';

import { FormMyRoute } from '../components/form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

export const MyRouteRegisterPage: React.FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Cadastro', 'Minhas Rotas']} hasBackButton />
      <Title title="Nova Rota" hasTrace isForm />
      <FormMyRoute />
    </>
  );
};
