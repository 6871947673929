import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { ContainerRowStyled, HireBackhaulBuyMatchFormStyled, LineSectionStyled } from './styled';
import { checkFreeTimeValue } from 'utils-v2/check-freetime';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getIcmsService } from 'utils/get-icms/service/get-icms.service';
import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import {
  calculateFeeLogshare,
  calculateICMS,
  calculateServiceTotal,
  normalizeCurrency,
  normalizeDecimal,
  sumTotalFreight,
} from 'pages/freight/utils/calculator';
import { IFinanceCalculator } from 'pages/freight/types/freight-receive';
import { RetentionFinesFinance } from 'domain-v2/transactions';
import { ICompany } from 'domain/company';
import { InputCurrency } from 'components/input-currency/input-currency';
import Button from 'components/button/button';
import SettingsIcon from 'assets/icons/settings.icon';

interface CalculatorPaymentFormProps {
  schedule: any;
  transactionIsShipping: boolean;
  transactionIsReceive: boolean;
  hasFeeLogshare: boolean;
  retentionFine?: RetentionFinesFinance;
}

interface CalculatorPaymentFormProps {
  schedule: any;
  transactionIsShipping: boolean;
  transactionIsReceive: boolean;
}

const initialCompanyValues = {
  tradeName: '',
  feeLogshare: '0',
} as ICompany;

export const CalculatorPaymentForm: React.FC<CalculatorPaymentFormProps> = ({
  schedule,
  transactionIsShipping,
  transactionIsReceive,
  hasFeeLogshare,
  retentionFine,
}) => {
  const { isZMaster, isAdmin } = useContext(UserContext);
  const { setFieldValue, values, submitForm } = useFormikContext<IFinanceCalculator>();

  const [company, setCompany] = useState<ICompany>(initialCompanyValues);

  const [icmsValue, setIcmsValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const ufOrigin = useMemo(() => {
    if (!schedule?.scheduleClientOrigin) return '';

    const { locality, client } = schedule.scheduleClientOrigin;

    if (locality && Reflect.ownKeys(locality).length) {
      return locality.uf;
    }

    if (client && Reflect.ownKeys(client).length) {
      return client.uf;
    }
  }, [schedule]);

  const ufDestiny = useMemo(() => {
    if (!schedule?.scheduleClientDestiny) return '';

    const { locality, client } = schedule.scheduleClientDestiny;

    if (locality && Reflect.ownKeys(locality).length) {
      return locality.uf;
    }

    if (client && Reflect.ownKeys(client).length) {
      return client.uf;
    }
  }, [schedule]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getIcmsService({ ufOrigin, ufDestiny });

        if (!transactionIsShipping && !transactionIsReceive) return setIcmsValue(data);

        return setIcmsValue(0);
      } catch (err) {
        showMessageFormatted({
          message: 'Houve um erro ao obter seu ICMS, tente novamente! ',
          type: 'error',
        });
      }
    })();
  }, [ufOrigin, ufDestiny]);

  useEffect(() => {
    const { totalGrisAdvalorem, toll, discharge, helperTotal, surplusTime, penaltyBonus, valueFreight, totalFee } =
      values;

    const freightTotal = sumTotalFreight({
      totalGrisAdvalorem,
      toll,
      discharge,
      helperTotal,
      surplusTime,
      valueFreight,
    });

    const total = freightTotal - normalizeCurrency(penaltyBonus) - normalizeCurrency(totalFee);

    setFieldValue('totalFreight', total);

    handleTotalService(total);

    setIsLoading(false);
  }, [icmsValue, values]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTotalValue = async (name: keyof IFinanceCalculator, value: string | number) => {
    const { totalFreight } = values;

    const currentValue = normalizeCurrency(value);
    const currentTotal = normalizeCurrency(totalFreight);
    const previousValue = normalizeCurrency(values[name] as any);

    const valueToIncrease = (previousValue - currentValue) * -1;

    const freightTotal = Number((currentTotal + valueToIncrease).toFixed(2));

    await setFieldValue(name, value);

    await setFieldValue('totalFreight', freightTotal);

    handleTotalService(freightTotal);
  };

  const handleDiscount = (value: string) => {
    const { totalFreight, penaltyBonus } = values;

    const currentValue = normalizeCurrency(value);
    const currentTotal = normalizeCurrency(totalFreight);
    const previousValue = normalizeCurrency(penaltyBonus);

    const valueToDecrease = (previousValue - currentValue) * -1;

    const freightTotal = Number((currentTotal - valueToDecrease).toFixed(2));

    setFieldValue('penaltyBonus', value);

    setFieldValue('totalFreight', freightTotal);

    handleTotalService(freightTotal);
  };

  const handleMoreTax = (value: string) => {
    setFieldValue('otherTaxes', value);

    handleTotalService(values.totalFreight);
  };

  const handleTotalService = (total: string | number) => {
    const { otherTaxes, valueFreight } = values;

    const totalFreight = normalizeCurrency(total);

    const totalService = calculateServiceTotal({
      ICMS: icmsValue,
      otherTaxes,
      totalFreight,
    });

    if (hasFeeLogshare) {
      setFieldValue('totalFee', calculateFeeLogshare(Number(company.feeLogshare), valueFreight));
    }

    setFieldValue('icms', calculateICMS(icmsValue, totalFreight));
    setFieldValue('totalService', totalService);
  };

  const getOperation = useMemo(() => {
    const operation =
      schedule.operation === 'BACKHAUL EXTERNO' ||
      schedule.operation === 'BACKHAUL PARCEIRO' ||
      schedule.operation === 'BACKHAUL INTERNO';

    return operation;
  }, [schedule]);

  const handleGetTotalGrisAdvalorem = useCallback(() => {
    const shippingValue = Number(schedule.scheduleCargo.shippingValue.replace(',', '.'));

    const companyValues = getOperation ? schedule.companyBuyer : schedule.company;

    const totalGrisAdvalorem = (
      shippingValue *
      ((Number(companyValues.gris) + Number(companyValues.advalorem)) / 100)
    ).toFixed(2);

    const total = Number(totalGrisAdvalorem);

    handleTotalValue('totalGrisAdvalorem', total);
  }, [schedule, getOperation, handleTotalValue]);

  const handleCalculateTaxaLogshare = useCallback(() => {
    setFieldValue('totalFee', calculateFeeLogshare(Number(company.feeLogshare), values.valueFreight));
  }, [values, setFieldValue, company.feeLogshare]);

  const handleGetTotalFreetime = useCallback(() => {
    const initialCollectDate = moment(schedule.scheduleDetails.levyInitialDate)
      .subtract(3, 'hours')
      .toISOString()
      .split('.')[0];
    const initialDeliveryyData = moment(schedule.scheduleDetails.deliveryInitialDate)
      .subtract(3, 'hours')
      .toISOString()
      .split('.')[0];

    const freeTimeValues = checkFreeTimeValue({
      expectedCollectionFinalDate: initialCollectDate,
      expectedDeliveryFinalDate: initialDeliveryyData,
      collectionDate: {
        initialDate: schedule.scheduleDetails.checkinDate,
        finalDate: schedule.scheduleDetails.checkoutDate,
      },
      deliveryDate: {
        initialDate: schedule.scheduleDetails.loadDate,
        finalDate: schedule.scheduleDetails.finishDate,
      },
      dailyFine: retentionFine?.seller.dailyFine ?? 0,
      hourlyFine: retentionFine?.seller.hourlyFine ?? 0,
    });

    if (isNaN(freeTimeValues.freeTimeTotal)) return;

    if (transactionIsShipping) {
      handleTotalValue('surplusTime', freeTimeValues.freeTimeTotal / 2);

      return;
    }

    handleTotalValue('surplusTime', freeTimeValues.freeTimeTotal);
  }, [
    schedule.scheduleDetails.levyEndDate,
    schedule.scheduleDetails.deliveryEndDate,
    schedule.scheduleDetails.checkinDate,
    schedule.scheduleDetails.checkoutDate,
    schedule.scheduleDetails.loadDate,
    schedule.scheduleDetails.finishDate,
    retentionFine?.seller.dailyFine,
    retentionFine?.seller.hourlyFine,
    transactionIsShipping,
    handleTotalValue,
  ]);

  useEffect(() => {
    (async () => {
      const findCompany = await companyDetailsService(schedule.companyId);

      setCompany(findCompany);
    })();
  }, [schedule]);

  if (isLoading) return <></>;

  return (
    <HireBackhaulBuyMatchFormStyled>
      <ContainerRowStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCurrency
            label="Frete Valor"
            id="valueFreight"
            name="valueFreight"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            onValueChange={(value) => handleTotalValue('valueFreight', value ?? '0')}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={normalizeDecimal(values?.valueFreight)}
          />
          <InputCurrency
            label="Ajudante"
            id="helperTotal"
            name="helperTotal"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value) => handleTotalValue('helperTotal', value ?? '0')}
            value={normalizeDecimal(values?.helperTotal)}
          />
          <InputCurrency
            label="Pedágio"
            id="toll"
            name="toll"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value) => handleTotalValue('toll', value ?? '0')}
            value={normalizeDecimal(values?.toll)}
          />
          <InputCurrency
            label="Descarga"
            id="discharge"
            name="discharge"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            value={normalizeDecimal(values?.discharge)}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value) => handleTotalValue('discharge', value ?? '0')}
          />
        </LineSectionStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCurrency
            label="Desconto"
            id="penaltyBonus"
            name="penaltyBonus"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value) => handleDiscount(value ?? '0')}
            value={normalizeDecimal(values?.penaltyBonus)}
          />
          <LineSectionStyled columns="1fr 0.2fr">
            <InputCurrency
              label="Taxa Logshare"
              id="totalFee"
              name="totalFee"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => handleTotalValue('totalFee', value ?? '0')}
              value={normalizeDecimal(values?.totalFee)}
              disabled={isZMaster && isAdmin}
            />
            <div style={{ marginTop: 24 }}>
              <Button
                type="button"
                bgColor="middleBlue"
                callback={handleCalculateTaxaLogshare}
                title="Calcular Taxa Logshare"
                size="very-small"
                icon={<SettingsIcon />}
              />
            </div>
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 0.2fr">
            <InputCurrency
              label="Gris/Advalorem"
              id="TotalGrisAdvalorem"
              name="TotalGrisAdvalorem"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => handleTotalValue('totalGrisAdvalorem', value ?? '0')}
              value={normalizeDecimal(values?.totalGrisAdvalorem)}
            />
            <div style={{ marginTop: 24 }}>
              <Button
                type="button"
                bgColor="middleBlue"
                callback={handleGetTotalGrisAdvalorem}
                title="Calcular Gris/Advalorem"
                size="very-small"
                icon={<SettingsIcon />}
              />
            </div>
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 0.2fr">
            <InputCurrency
              label="Tempo Excedente"
              id="surplusTime"
              name="surplusTime"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => handleTotalValue('surplusTime', value ?? '0')}
              value={values?.surplusTime}
            />
            <div style={{ marginTop: 24 }}>
              <Button
                type="button"
                bgColor="middleBlue"
                title="Calcular Multa"
                size="very-small"
                icon={<SettingsIcon />}
                callback={handleGetTotalFreetime}
              />
            </div>
          </LineSectionStyled>
        </LineSectionStyled>

        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCurrency
            label="Frete Total"
            id="totalFreight"
            name="totalFreight"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={normalizeDecimal(values?.totalFreight)}
            disabled
          />
          <InputCurrency
            label="ICMS"
            id="icms"
            name="icms"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={normalizeDecimal(values?.icms)}
            disabled
          />
          <InputCurrency
            label="Outros Impostos"
            id="otherTaxes"
            name="otherTaxes"
            type="number"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value) => handleMoreTax(value ?? '0')}
            value={values?.otherTaxes ?? 0}
          />
          <InputCurrency
            label="Total do Serviço"
            id="totalService"
            name="totalService"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={normalizeDecimal(values?.totalService)}
            disabled
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: 14,
        }}
      >
        <Button title="Aplicar" bgColor="blue" callback={submitForm} size="medium" />
      </div>
    </HireBackhaulBuyMatchFormStyled>
  );
};
