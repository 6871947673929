import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';

import { RegisterLoginSchema } from '../schemas-validation/schemas-validation';

import FormRegisterLoginView from './form-register-login-view';

const initialValues = {
  usuario: '',
  password: '',
  recaptchaLogin: '',
};
interface LoginViewProps {
  handleLogin: (usuario: string, password: string, recaptchaLogin: string) => void;
  isLoading: boolean;
}

const FormRegisterLogin: React.FC<LoginViewProps> = ({ handleLogin, isLoading}) => {
  const navigate = useNavigate();

  const ResetPassword = useCallback(() => navigate('/reset-password'), [navigate]);
  const goToLoginMicrosoft = useCallback(() => navigate('/login-microsoft'), [navigate]);

  return (
    <FormRegisterLoginView
      isLoading={isLoading}
      initialValues={initialValues}
      validationSchema={RegisterLoginSchema}
      handleRegister={handleLogin}
      handleReset={ResetPassword}
      handleMicrosoft={goToLoginMicrosoft}
    />
  );
};

export default FormRegisterLogin;
