import React, { ChangeEvent, FC } from 'react';
import * as S from './filter-form-view.styled';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import {
  ContainerRowStyled,
  LineSectionStyled,
  SecondTitleStyled,
} from 'pages-v2/transport-route/components/filter/styled';
import { uf } from 'domain/states';
import { vehicleTypeOptionsDomain } from 'src-new/domains/vehicle-type.domain';
import { vehicleCategoryOptionsDomain } from 'src-new/domains/vehicle-category.domain';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { FormikErrors } from 'formik';
import { Button } from 'logshare-ui-kit';
import { operationTypeOptions } from 'src-new/domains/operation-type';

interface IFilterFormViewProps {
  values: ISaleOfferListFilterDomain;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ISaleOfferListFilterDomain>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmitForm: () => void;
}

export const FilterFormView: FC<IFilterFormViewProps> = ({ values, setFieldValue, handleChange, onSubmitForm }) => {
  return (
    <S.Wrapper>
      <S.ContainerRow>
        <SecondTitleStyled>UF de Origem</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Origem"
            id="originUf"
            name="originUf"
            value={values.originUf}
            setFieldValue={setFieldValue}
            options={uf}
            isClearable
          />
        </LineSectionStyled>
      </S.ContainerRow>

      <ContainerRowStyled>
        <SecondTitleStyled>Cidade de Origem</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <InputField
            placeholder="Cidade"
            id="originCity"
            name="originCity"
            type="text"
            onChange={handleChange}
            value={values.originCity}
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>UF de Destino</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Destino"
            id="destinationUf"
            name="destinationUf"
            value={values.destinationUf}
            setFieldValue={setFieldValue}
            options={uf}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Cidade de Destino</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <InputField
            placeholder="Cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            onChange={handleChange}
            value={values.destinationCity}
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Tipo de Veículo</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Selecione o Tipo"
            id="vehicleType"
            name="vehicleType"
            value={values.vehicleType}
            setFieldValue={setFieldValue}
            options={vehicleTypeOptionsDomain}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Categoria do Veículo</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Categoria"
            id="vehicleCategory"
            name="vehicleCategory"
            value={values.vehicleCategory}
            setFieldValue={setFieldValue}
            options={vehicleCategoryOptionsDomain}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Tipo de Operação</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Tipo de Operação"
            id="operationType"
            name="operationType"
            value={values.operationType}
            setFieldValue={setFieldValue}
            options={operationTypeOptions}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <S.ContainerButton>
        <Button label={'Filtrar'} size="lg" color="blue" variant="solid" type={'submit'} onPress={onSubmitForm} />
      </S.ContainerButton>
    </S.Wrapper>
  );
};
