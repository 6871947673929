import { trackingOccurrenceUpdateService } from 'pages/tracking-occurrence/services/tracking-occurrence-update/tracking-occurrence-update.service';
import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';

const useUpdateTrackingOccurrence = () => {
  return async (trackingOccurrence: ITrackingOccurrenceCreated) => {
    await trackingOccurrenceUpdateService(trackingOccurrence);
  };
};

export { useUpdateTrackingOccurrence };
