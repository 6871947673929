import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HomeBidOffersView, IHomeBidOffersStateRequest } from './home-bid-offers-view.component';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { useGetBidOffersList } from 'src-new/pages/home/pages/home/hooks/use-get-bid-offers-list/use-get-bid-offers-list.hook';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export const HomeBidOffers: React.FC = () => {
  const { homeBids } = useContext(HomeContext);
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getBidOffersList = useGetBidOffersList();
  const navigate = useNavigate();

  const isLazyLoading = useMemo(
    (): boolean => homeBids.statusRequest === 'PROCESSING' && !isLoading,
    [homeBids.statusRequest, isLoading],
  );

  const isError = useMemo((): boolean => homeBids.statusRequest === 'ERROR', [homeBids.statusRequest]);

  const isEmpty = useMemo(
    (): boolean => homeBids.statusRequest === 'SUCCESS' && !homeBids.homeBids.length,
    [homeBids.homeBids.length, homeBids.statusRequest],
  );

  const stateRequest = useMemo(
    (): IHomeBidOffersStateRequest => ({ isLoading, isError, isEmpty }),
    [isEmpty, isError, isLoading],
  );

  const redirectToSaleOfferListPage = useCallback(() => navigate('/ofertas-venda'), [navigate]);

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPageInfinityScroll }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (homeBids.statusRequest === 'INITIAL') {
      getBidOffersList();
    }
  }, [getBidOffersList, homeBids.statusRequest]);

  useEffect(() => {
    const statusRequest = homeBids.statusRequest;
    if (statusRequest === 'SUCCESS' || statusRequest === 'ERROR') {
      setIsLoading(false);
    }
  }, [homeBids.statusRequest]);

  useEffect(() => {
    if (
      !homeBids.paginationActions.isPageLimit &&
      pageInfinityScroll > currentPage &&
      homeBids.statusRequest !== 'ERROR'
    ) {
      setCurrentPage(pageInfinityScroll);
      getBidOffersList();
    }
  }, [
    currentPage,
    getBidOffersList,
    homeBids.paginationActions.isPageLimit,
    homeBids.statusRequest,
    pageInfinityScroll,
  ]);

  return (
    <HomeBidOffersView
      infiniteScrollProps={infiniteScrollProps}
      stateRequest={stateRequest}
      isLoadingInfinityScroll={isLazyLoading}
      redirectToSaleOfferListPage={redirectToSaleOfferListPage}
    />
  );
};
