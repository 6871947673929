import { IVehicleCreated } from 'domain/vehicle';
import { IContentTable } from 'components/table/table.types';

export const listVehicleContent = (vehicle: IVehicleCreated): IContentTable => {
  return [
    {
      value: '',
      flex: 0,
    },
    {
      value: vehicle.id,
      flex: 0.5,
    },
    {
      value: vehicle.vehicleType,
      flex: 1,
    },
    {
      value: vehicle.vehicleCategory,
      flex: 1,
    },
    {
      value: vehicle.mainBoard,
      flex: 1,
    },
    {
      value: vehicle.secondBoard,
      flex: 1,
    },
    {
      value: vehicle.thirdBoard,
      flex: 1,
    },
    {
      value: vehicle.palletCapacity,
      flex: 1,
    },
    {
      value: vehicle.cubageCapacity,
      flex: 1,
    },
  ];
};
