import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { CalculatorPaymentForm } from '../calculate';
import { ModalAuditTransaction } from '../audit';
import { ValuesDetails } from '../../../../components/transaction-values';

import { convertToNumber } from 'utils-v2/converters/to-number';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { transactionUpdateHook } from 'pages-v2/schedule/hooks/update-transaction';
import { calculateMargin } from 'pages-v2/schedule/hooks/calculate-margin';
import { auditTransactionHook } from 'pages-v2/schedule/hooks/audit-transaction';
import { TransactionRegisterInput } from 'domain-v2/transactions/register';
import { TransactionDetails } from 'domain-v2/transactions/details';
import { RetentionFinesFinance } from 'domain-v2/transactions';
import { ModalState } from 'domain-v2/inputs/modals';
import { IUser } from 'domain/login';
import { ModalForm } from 'components/modal-form/modal-form';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface EditPossibilityTransaction {
  index: number;
  user: IUser;
  isAdmin: boolean;
  transaction: TransactionDetails;
  transactionReceive: TransactionDetails;
  transactionPayable: TransactionDetails;
  transactionShipping: TransactionDetails;
  schedule: any;
  retentionFine: RetentionFinesFinance;
  calculateModal: ModalState<{ index: number }>;
  setCalculateModal: Dispatch<SetStateAction<ModalState<{ index: number }>>>;
  auditModal: ModalState<{ index: number }>;
  setAuditModal: Dispatch<SetStateAction<ModalState<{ index: number }>>>;
  onRefresh?: Dispatch<SetStateAction<number>>;
}

export const TransactionEditPossibility: FC<EditPossibilityTransaction> = ({
  index,
  user,
  isAdmin,
  transaction,
  transactionReceive,
  transactionPayable,
  transactionShipping,
  schedule,
  retentionFine,
  calculateModal,
  setCalculateModal,
  auditModal,
  setAuditModal,
  onRefresh,
}) => {
  const [margin, setMargin] = useState<number>(0);
  const calculatorFormRef = useRef<FormikProps<TransactionRegisterInput>>(null);

  const UseTransactionUpdate = transactionUpdateHook();
  const UseAuditTransaction = auditTransactionHook();

  const handleUpdateTransaction = useCallback(
    async (transactionId: number, transactionValues: TransactionRegisterInput) => {
      try {
        await UseTransactionUpdate(transactionId, {
          ...transactionValues,
          totalFreight: convertToNumber(transactionValues.totalFreight),
          valueFreight: convertToNumber(transactionValues.valueFreight),
          helperTotal: convertToNumber(transactionValues.helperTotal),
          discharge: convertToNumber(transactionValues.discharge),
          totalGrisAdvalorem: convertToNumber(transactionValues.totalGrisAdvalorem),
          surplusTime: convertToNumber(transactionValues.surplusTime),
          penaltyBonus: convertToNumber(transactionValues.penaltyBonus),
          totalFee: convertToNumber(transactionValues.totalFee),
          toll: convertToNumber(transactionValues.toll),
          otherTaxes: convertToNumber(transactionValues.otherTaxes),
          totalService: convertToNumber(transactionValues.totalService),
          icms: convertToNumber(transactionValues.icms),
          iss: convertToNumber(transactionValues.iss),
          margin,
        });
        onRefresh((prev) => prev + 1);
        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });

        setCalculateModal((old) => ({ ...old, open: false }));
      } catch (error) {
        showMessageFormatted({
          message: '',
          type: 'error',
        });
      }
    },
    [margin],
  );

  const handleAuditTransaction = useCallback(async (transactionId: number) => {
    try {
      await UseAuditTransaction(transactionId);
      onRefresh((prev) => prev + 1);

      showMessageFormatted({
        message: 'Auditoria Realizada.',
        type: 'success',
      });

      setAuditModal((old) => ({ ...old, open: false }));
    } catch (error) {
      showMessageFormatted({
        message: '',
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    const marginValue = calculateMargin(transactionPayable, transactionReceive, transactionShipping);

    setMargin(marginValue);
  }, [transactionShipping, transactionPayable]);

  return (
    <div key={index}>
      {calculateModal.open && calculateModal.data.index === index && (
        <ModalForm
          title={transaction.title}
          description={
            <Formik
              innerRef={calculatorFormRef}
              initialValues={transaction}
              onSubmit={(values) => {
                handleUpdateTransaction(transaction.id, values);
              }}
              onReset={() => null}
            >
              <CalculatorPaymentForm
                schedule={schedule}
                transactionIsShipping={transaction.status === 'Receber Transportador'}
                transactionIsReceive={transaction.status === 'Receber'}
                hasFeeLogshare={false}
                retentionFine={retentionFine}
              />
            </Formik>
          }
          handleCancel={() => setCalculateModal((old) => ({ ...old, open: false }))}
          size="medium"
        />
      )}
      {auditModal.open && auditModal.data.index === index && (
        <ModalAuditTransaction
          title={transaction.title}
          transaction={transaction}
          onConfirmation={() => handleAuditTransaction(transaction.id)}
          handleCancel={() => setAuditModal({ open: false, data: { index } })}
        />
      )}
      <ValuesDetails
        title={transaction.title}
        ownerTransaction={transaction.ownerTransaction}
        transactionIndex={index}
        user={user}
        isAdmin={isAdmin}
        transaction={transaction}
        margin={margin}
        schedule={schedule}
        retentionFine={retentionFine}
        setCalculateModal={setCalculateModal}
        setAuditModal={setAuditModal}
        onRefresh={onRefresh}
      />
    </div>
  );
};
