import { IUser } from 'domain/login';

export const validateMenuPermissionsService = (name: string, profiles: string[], user: IUser | undefined): boolean => {
  if (!user?.profile) return false;

  const hasFunctionalities = user?.functionalities?.filter(
    (functionality) => functionality.parent === name || profiles.includes('full'),
  );
  return !!hasFunctionalities?.length;
};
