import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WeighingTableTabsView } from './weighing-table-tabs-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import {
  IWeighingListFilterDomain,
  WeighingListOperationType,
} from '../../domains/weighing-list-filters/weighing-list-filters.domain';
import { weighingListFilterInitialValues } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters-initial-values.domain';
import { useGetWeighingList } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/hooks/use-weighing-list/use-weighing-list.hook';
import { isEqual } from 'lodash';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const WeighingTableTabs: React.FC = () => {
  const { weighingList } = useContext(WeighingContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const [page, setPage] = useState<number>(1);
  const [isLimitRequest, setIsLimitRequest] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<IWeighingListFilterDomain>({
    ...weighingListFilterInitialValues,
    page: 0,
  });
  const getWeighingListHook = useGetWeighingList();
  const { selectedFilter, setSelectFilters } = weighingList.filters;
  const { loading, setInitialLoadingReset } = initialLoading;

  const isInitialLoading = useMemo(() => loading, [loading]);

  const isEmpty = useMemo(
    () => weighingList.statusRequest === 'SUCCESS' && !weighingList.weighingList.length,
    [weighingList.statusRequest, weighingList.weighingList.length],
  );

  const isError = useMemo(() => weighingList.statusRequest === 'ERROR', [weighingList.statusRequest]);

  const handleWeighingTabs = useCallback(
    (name: string) => {
      const sessionFilters = sessionStorage.getItem('weighing-list-filters');
      const filters = sessionFilters? JSON.parse(sessionFilters) : selectedFilter
      const operationValue = name.toUpperCase() as WeighingListOperationType;
      const data: IWeighingListFilterDomain = {
        ...filters,
        operation: operationValue,
      };

      setInitialLoadingReset();
      setPage(1);
      setSelectFilters(data);
      sessionStorage.setItem('weighing-list-filters', JSON.stringify(data));
    },
    [selectedFilter, setInitialLoadingReset, setSelectFilters],
  );

  useEffect(() => {
    if (!isLimitRequest && page > selectedFilter.page) {
      setSelectFilters({ ...selectedFilter, page });
    }
  }, [isLimitRequest, page, selectedFilter, setSelectFilters]);

  useEffect(() => {
    if (!isEqual(selectedFilter, currentFilter)) {
      setCurrentFilter(selectedFilter);
      getWeighingListHook(setIsLimitRequest);
    }
  }, [currentFilter, getWeighingListHook, selectedFilter]);

  return (
    <WeighingTableTabsView
      statusProps={{ isInitialLoading, isEmpty, isError }}
      handleInfinityScroll={setPage}
      activeTab={selectedFilter?.operation}
      handleChangeTab={handleWeighingTabs}
    />
  );
};
