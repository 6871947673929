import { FC, ReactNode } from 'react';

import { ToogleListView } from './toogle-list-view.component';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IToogleListProps {
  isPressed: boolean;
  leftIcon: ReactNode;
  rightIcon: ReactNode;
  color?: ThemeColorsV2Type;
  handleToogleClick: (active: boolean) => void;
}

export const ToogleList: FC<IToogleListProps> = (props) => {
  return <ToogleListView {...props} />;
};
