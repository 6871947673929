import { FC, useContext, useEffect, useMemo } from 'react';
import { WeighingUpdatePageBodyView } from './weighing-update-page-body-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { UseGetWeighingById } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/hooks/use-get-weighing-by-id/use-get-weighing-by-id.hook';

export const WeighingUpdatePageBody: FC = () => {
  const { weighingDetails } = useContext(WeighingContext);
  const getWeighingByIdHook = UseGetWeighingById();

  const isLoading = useMemo(() => weighingDetails.statusRequest === 'PROCESSING', [weighingDetails.statusRequest]);

  const isError = useMemo(() => weighingDetails.statusRequest === 'ERROR', [weighingDetails.statusRequest]);
  useEffect(() => {
    if (weighingDetails.statusRequest === 'INITIAL') {
      getWeighingByIdHook();
    }
  }, [getWeighingByIdHook, weighingDetails.statusRequest]);

  return <WeighingUpdatePageBodyView isLoading={isLoading} isError={isError} />;
};
