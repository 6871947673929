import React, { useCallback } from 'react';

import InputFieldPhoneView from './input-field-phone-view';

type InputFieldPhoneProps = {
  id: string;
  name: string;
  value?: string;
  type: 'text';
  label?: string;
  labelFor?: string;
  placeholder?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFieldPhone: React.FC<InputFieldPhoneProps> = ({
  id,
  name,
  value = '',
  type,
  label,
  placeholder,
  reactNode,
  reactNodePosition,
  disabled,
  hasError,
  errorMessage,
  onChange = () => null,
  onBlur = () => null,
}) => {
  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value.replace(/\D/g, '');
    if (value.length < 7) value = value.replace(/^(\d{2})(\d)/, '($1) $2');
    if (value.length === 7) value = value.replace(/^(\d{2})(\d{5})/, '($1) $2');
    if (value.length > 7 && value.length <= 11) value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
    if (value.length > 11) value = value.replace(/^(\d{2})(\d{2})(\d{5})(\d)/, '($2) $3-$4');
    e.currentTarget.value = value;
  }, []);

  return (
    <InputFieldPhoneView
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      placeholder={placeholder}
      reactNode={reactNode}
      reactNodePosition={reactNodePosition}
      disabled={disabled}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={onBlur}
      handleKeyUp={handleKeyUp}
    />
  );
};

export default InputFieldPhone;
