import React from 'react';
import { TitleView } from './title-view.component';

interface ITitleProps {
  title: string;
}

export const Title: React.FC<ITitleProps> = ({ title }) => {
  return <TitleView title={title} />;
};
