import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IAcceptedMatchesContext } from './accepted-matches.types';
import { acceptedMatchesReducer } from './accepted-matches.reducer';
import { initialState, IAcceptedMatchesAction } from './accepted-matches.actions';

import { IListPage } from 'domain/page-info';
import { IAcceptedMatchesV2 } from 'domain/accepted-matches';

interface IAcceptedMatchesContextProps {
  children: ReactNode;
}

export const AcceptedMatchesContext = createContext<IAcceptedMatchesContext>(initialState);

const AcceptedMatchesContextProvider = ({ children }: IAcceptedMatchesContextProps) => {
  const [state, dispatch] = useReducer(acceptedMatchesReducer, initialState);

  const setAcceptedMatchesRequest = useCallback(() => {
    dispatch({
      type: IAcceptedMatchesAction.ACCEPTED_MATCHES_REQUEST,
      payload: null,
    });
  }, []);

  const setAcceptedMatchesSuccess = useCallback((acceptedMatchesList: IListPage<IAcceptedMatchesV2>) => {
    dispatch({
      type: IAcceptedMatchesAction.ACCEPTED_MATCHES_SUCCESS,
      payload: acceptedMatchesList,
    });
  }, []);

  const setAcceptedMatchesError = useCallback((error: string) => {
    dispatch({
      type: IAcceptedMatchesAction.ACCEPTED_MATCHES_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      acceptedMatchesIsLoading: state.acceptedMatchesIsLoading,
      acceptedMatches: state.acceptedMatches,
      acceptedMatchesError: state.acceptedMatchesError,
      acceptedMatchesPageInfo: state.acceptedMatchesPageInfo,
      setAcceptedMatchesRequest,
      setAcceptedMatchesSuccess,
      setAcceptedMatchesError,
    };
  }, [
    setAcceptedMatchesError,
    setAcceptedMatchesRequest,
    setAcceptedMatchesSuccess,
    state.acceptedMatches,
    state.acceptedMatchesError,
    state.acceptedMatchesIsLoading,
    state.acceptedMatchesPageInfo,
  ]);

  return <AcceptedMatchesContext.Provider value={value}>{children}</AcceptedMatchesContext.Provider>;
};

export default React.memo(AcceptedMatchesContextProvider);
