import React from 'react';
import moment from 'moment';

import { DeliveryTrackingStatus } from './components/status/status';
import { CustomizedSteppers } from './components/progress-bar/progress-bar';
import { OriginAndDestiny } from './components/origin-and-destiny/origin-and-destiny-view';

import { FilterService } from 'domain/filter-delivery-tracking';

interface PortSantosFormView {
  result: FilterService;
}

const DeliveryTrackingDetailFormView: React.FC<PortSantosFormView> = ({ result }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 60,
        width: '90vw',
      }}
    >
      <DeliveryTrackingStatus
        statusCode={result.statusCode}
        previsionDate={moment(result.scheduleDetails?.deliveryEndDate).format('DD/MM/YYYY')}
      />

      <CustomizedSteppers
        statusCode={result.statusCode}
        createAt={moment(result.created_at).format('DD/MM/YYYY HH:mm:ss')}
        startDate={
          result.scheduleDetails.startDate
            ? moment(result.scheduleDetails.startDate).format('DD/MM/YYYY HH:mm:ss')
            : '-'
        }
        checkoutDate={
          result.scheduleDetails.checkoutDate
            ? moment(result.scheduleDetails.checkoutDate).format('DD/MM/YYYY HH:mm:ss')
            : '-'
        }
        finishDate={
          result.scheduleDetails.finishDate
            ? moment(result.scheduleDetails.finishDate).format('DD/MM/YYYY HH:mm:ss')
            : '-'
        }
      />

      <div style={{ width: '90vw', position: 'absolute', bottom: 0 }}>
        <OriginAndDestiny
          collectionStreet={result.scheduleClientOrigin.client?.street || result.scheduleClientOrigin.locality?.street}
          collectionNumberHouse={
            result.scheduleClientOrigin.client?.numberHouse || result.scheduleClientOrigin.locality?.numberHouse
          }
          collectionNeighborhood={
            result.scheduleClientOrigin.client?.neighborhood || result.scheduleClientOrigin.locality?.neighborhood
          }
          collectionCity={result.scheduleClientOrigin.client?.city || result.scheduleClientOrigin.locality?.city}
          collectionState={result.scheduleClientOrigin.client?.uf || result.scheduleClientOrigin.locality?.uf}
          collectionCep={result.scheduleClientOrigin.client?.cep || result.scheduleClientOrigin.locality?.cep}
          deliveryAddressStreet={
            result.scheduleClientDestiny.client?.street || result.scheduleClientDestiny.locality?.street
          }
          deliveryAddressNumberHouse={
            result.scheduleClientDestiny.client?.numberHouse || result.scheduleClientDestiny.locality?.numberHouse
          }
          deliveryAddressNeighborhood={
            result.scheduleClientDestiny.client?.neighborhood || result.scheduleClientDestiny.locality?.neighborhood
          }
          deliveryAddressCity={result.scheduleClientDestiny.client?.city || result.scheduleClientDestiny.locality?.city}
          deliveryAddressState={result.scheduleClientDestiny.client?.uf || result.scheduleClientDestiny.locality?.uf}
          deliveryAddressCep={result.scheduleClientDestiny.client?.cep || result.scheduleClientDestiny.locality?.cep}
        />
      </div>
    </div>
  );
};

export default DeliveryTrackingDetailFormView;
