import React from 'react';

import * as S from './styled';

import { formatDate } from 'utils/format-numbers';
import { IAddressValues, ILevyScheduleDetailsValues } from 'domain/card-resume';

interface CardResumeCollectionProps {
  originValues: IAddressValues;
  scheduleDetailsValues?: ILevyScheduleDetailsValues;
  hasConnector?: boolean;
}

export const CardResumeCollection: React.FC<CardResumeCollectionProps> = ({
  originValues,
  scheduleDetailsValues,
  hasConnector,
}) => {
  const levyDate = React.useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { levyInitialDate, levyInitialTime, levyEndDate, levyEndTime, pickUpDate } = scheduleDetailsValues;

    if ([levyInitialDate, levyInitialTime, levyEndDate, levyEndTime, pickUpDate].includes('Invalid date')) {
      return null;
    }

    if (pickUpDate) return pickUpDate;

    if (levyInitialDate && levyEndDate) {
      return `${formatDate(levyInitialDate, levyInitialTime || '')} - ${formatDate(levyEndDate, levyEndTime || '')}`;
    }

    if (levyInitialDate) {
      return formatDate(levyInitialDate, levyInitialTime || '');
    }

    if (levyEndDate) {
      return formatDate(levyEndDate, levyEndTime || '');
    }

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
          {hasConnector && <S.Connector size={originValues?.tradingName ? '110' : '150'} />}
        </S.StepIconSection>

        <S.Text blueColor fontWeight>
          Coleta
        </S.Text>

        {originValues?.tradingName ? (
          <S.TextContent uppercase>
            <S.Text blueColor fontWeight>
              {originValues.tradingName}
            </S.Text>

            <S.Text>{`${originValues.street} ${originValues.number}`}</S.Text>

            <S.Text>{`${originValues.neighborhood} - ${originValues.city} - ${originValues.state}`}</S.Text>

            <S.Text>{originValues.zipCode}</S.Text>

            <S.Text padding="0 0 10px 0">{originValues.cnpj}</S.Text>

            <S.Text>{levyDate || ''}</S.Text>
          </S.TextContent>
        ) : (
          <S.TextContent italic>
            <S.Text>Não informado</S.Text>
          </S.TextContent>
        )}
      </S.LineSection>
    </S.Content>
  );
};
