import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';

import CompaniesTable from '../companies-table/companies-table';

import { ContainerSearchStyled } from './company-body.styled';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ColorsV2Type } from 'styles/types/colors';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { ICompany } from 'domain/company';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';
import { UserContext } from 'state/user-context';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

interface ICompanyBodyViewProps {
  companies: Array<ICompany>;
  companiesPageInfo: PageInfo | null;
  hasLoading: boolean;
  showModal: boolean;
  goToCreateCompany: () => void;
  showModalConfirmation: () => void;
  handleDeletePartner: () => void;
  setCurrentPageCompanies: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  goToDetailsPage: (companyId: string) => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const CompanyBodyView: React.FC<ICompanyBodyViewProps> = ({
  companies,
  companiesPageInfo,
  onHandleExport,
  options,
  hasLoading,
  showModal,
  goToCreateCompany,
  showModalConfirmation,
  handleDeletePartner,
  setCurrentPageCompanies,
  setActiveTabName,
  setSearchInput,
  goToDetailsPage,
  clickOrderBy,
  clickSortDirection,
}: ICompanyBodyViewProps) => {
  const { isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.2,
      },
      {
        value: 'ID',
        flex: 0.5,
      },
      {
        value: 'Empresa',
        flex: 2,
      },
      {
        value: 'CNPJ',
        flex: 1,
      },
      {
        value: 'Tipo',
        flex: 0.8,
      },
      {
        value: 'Plano',
        flex: 0.7,
      },
      {
        value: 'Status',
        flex: 0.5,
      },
    ];
  }, []);

  const convertPlanType = (plan: string) => {
    switch (plan) {
      case 'basic-seller':
        return 'essential';
      case 'basic-buyer':
        return 'essential';
      case 'pro':
        return 'pro';
      case 'shipping-company':
        return 'transportadora';
      default:
        return 'essential-seller';
    }
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return companies.map((company) => [
      {
        value: <Checkbox id="vehicle.id" name="vehicle.id" value={String(0)} onChange={() => null} />,
        flex: 0.2,
      },
      {
        value: company.id,
        flex: 0.5,
      },
      {
        value: company.name,
        flex: 2,
      },
      {
        value: company.cnpj,
        flex: 1,
      },
      {
        value: company.type,
        flex: 0.8,
      },
      {
        value: convertPlanType(company.plan),
        flex: 0.7,
      },
      {
        value: (
          <Tag
            label={company.status}
            color={company.status === 'active' ? ColorsV2Type.Green : ColorsV2Type.Red}
            variant="solid"
            display="flex"
            size="md"
          />
        ),
        flex: 0.5,
      },
    ]);
  }, [companies]);

  const renderCompaniesTable = useMemo(() => {
    return (
      <CompaniesTable
        headerTable={headerTable}
        contentTable={contentTable}
        pageInfo={companiesPageInfo}
        changePage={setCurrentPageCompanies}
        isLoading={hasLoading}
        clickRowAction={goToDetailsPage}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [companiesPageInfo, contentTable, goToDetailsPage, hasLoading, headerTable, setCurrentPageCompanies]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Ativos',
        content: renderCompaniesTable,
      },
      {
        name: 'Inativos',
        content: renderCompaniesTable,
      },
      {
        name: 'Bloqueados',
        content: renderCompaniesTable,
      },
    ];
  }, [renderCompaniesTable]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage breadcrumbList={['Cadastro', 'Empresas']} />
      <Title title="Empresas Cadastradas" hasTrace />
      <ContainerSearchStyled>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Empresa ou CNPJ"
          type="text"
          changeValue={setSearchInput}
        />

        <DropDown title="Exportar">
          <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
        </DropDown>

        {!isShippingCompany && (
          <Button
            label="Novo Frete"
            size="md"
            color="blue"
            onPress={() => handleCreateSchedulePage(true)}
            variant="solid"
          />
        )}
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateCompany} />
          <FooterPage.Button label="Excluir" color="white" variant="ghost" onPress={showModalConfirmation} />
        </FooterPage.RightContent>
      </FooterPage.Root>

      {showModal && (
        <ModalConfirmation
          title="Confirma exclusão da Empresa?"
          description="Essa operação não poderá ser desfeita.*"
          handleCancel={showModalConfirmation}
          onConfirmation={handleDeletePartner}
          size="large"
          type="normal"
        />
      )}
    </Fragment>
  );
};

export default CompanyBodyView;
