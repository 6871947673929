import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import KanbanSection from '../components/kanban-content';

import { ContainerCardsStyled, ContainerLoadingStyled } from './task-vision.styled';

import { ColorsV2Type } from 'styles/types/colors';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import Loading from 'components-v2/common/loading';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

interface IFreightListBodyViewProps {
  schedules: ICurrentAllocation[];
  isLoading: boolean;
  handleSelectedFreight: (freightId: string) => void;
}

const verifyToStartArray = ['AG10', 'IN10', 'IN11'];

const isStatusCodeAllowedInProgress = (statusCode: string) => {
  return !['SH01', 'SH02', 'OT01', 'OT10', 'AG10', 'IN10', 'IN11', 'IN90', 'AL90', 'AL99', 'OT99'].includes(statusCode);
};

const isStatusCodeAllowed = (statusCode: string) => {
  return !['IN90', 'AL90', 'AL99', 'OT99'].includes(statusCode);
};

const TaskVisionView: React.FC<IFreightListBodyViewProps> = ({
  schedules,
  isLoading,
  handleSelectedFreight,
}: IFreightListBodyViewProps): JSX.Element => {
  const [dataFiltered, setDataFiltered] = useState<{
    toStart: ICurrentAllocation[];
    inProgress: ICurrentAllocation[];
    scheduleLate: ICurrentAllocation[];
    finished: ICurrentAllocation[];
  }>({
    toStart: [],
    inProgress: [],
    scheduleLate: [],
    finished: [],
  });

  useEffect(() => {
    const toStart = schedules?.filter(
      (value) =>
        verifyToStartArray.includes(value.statusCode) &&
        moment(value.scheduleDetails?.deliveryEndDate).isSameOrAfter(moment()),
    );

    const inProgress = schedules?.filter(
      (value) =>
        isStatusCodeAllowedInProgress(value.statusCode) &&
        moment(value.scheduleDetails?.deliveryEndDate).isSameOrAfter(moment()),
    );

    const finished = schedules?.filter((value) => value.statusCode === 'IN90');

    const scheduleLate = schedules?.filter(
      (value) =>
        isStatusCodeAllowed(value.statusCode) &&
        moment(value.scheduleDetails?.levyEndDate || value.scheduleDetails?.deliveryEndDate).isBefore(moment()),
    );

    setDataFiltered({ toStart, inProgress, scheduleLate, finished });
  }, [schedules]);

  return (
    <Fragment>
      <HeaderPage breadcrumbList={['Tracking', 'Kanban']} />
      <Title title="Acompanhe aqui o status da sua operação de transporte." hasTrace />
      {isLoading ? (
        <ContainerLoadingStyled>
          <Loading />
        </ContainerLoadingStyled>
      ) : (
        <ContainerCardsStyled>
          <KanbanSection
            title="A Iniciar"
            bgColor={ColorsV2Type.DarkGray}
            totalItems={dataFiltered.toStart.length}
            data={dataFiltered.toStart}
            handleSelectedFreight={handleSelectedFreight}
          />

          <KanbanSection
            title="Em Andamento"
            bgColor={ColorsV2Type.Blue}
            totalItems={dataFiltered.inProgress.length}
            data={dataFiltered.inProgress}
            handleSelectedFreight={handleSelectedFreight}
          />

          <KanbanSection
            title="Finalizados"
            bgColor={ColorsV2Type.Aqua}
            totalItems={dataFiltered.finished.length}
            data={dataFiltered.finished}
            handleSelectedFreight={handleSelectedFreight}
          />

          <KanbanSection
            title="Em Atraso"
            bgColor={ColorsV2Type.MiddleRed}
            totalItems={dataFiltered.scheduleLate.length}
            data={dataFiltered.scheduleLate}
            handleSelectedFreight={handleSelectedFreight}
          />
        </ContainerCardsStyled>
      )}
    </Fragment>
  );
};

export default React.memo(TaskVisionView);
