import React, { ReactElement, useCallback, useMemo } from 'react';
import { IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import { IConversationChatDomain } from 'src-new/domains/conversation-chat.domain';
import {
  ChatButtonStyled,
  ChatButtonTextBoldStyled,
  ChatButtonTextContainerStyled,
  EndDateContainerStyled,
  OperationTypeContainerStyled,
  PartnerContainerStyled,
  StartDateContainerStyled,
  TagContainerStyled,
  TextContainerStyled,
  UnreadTagContainerStyled,
  ContainerTooltipStyled,
  ContainerInfosTooltipStyled,
  LabelTooltipStyled,
  ContainerInfosPurchaseStyled,
  ContainerInfosSaleStyled,
} from './header-chat-modal-table-view.styled';
import { Tag } from 'logshare-ui-kit';
import { ColorsV2Type } from 'styles/types/colors';
import {
  chatModalColumnsConfig,
  chatModalHeaderColumns,
} from 'src-new/components/application-page-header/components/header-page/components/header-chat-modal/components/header-chat-modal-table/header-chat-modal-table.constants';
import { IChatObject } from 'src-new/components/chat-button/chat-button.types';
import { Tooltip } from '@mui/material';
import TooltipComponent from 'components/Tooltip';
import { Info } from 'lucide-react';

interface IHeaderChatModalTableViewProps {
  conversationsListChat: Array<IConversationChatDomain>;
  orderingColumns: IOrdering;
  handleOpenChat: (chatId: string, chatName: string) => void;
  getUnreadChat: (chatId: string) => number;
  infiniteScrollProps: IInfiniteScrollTable;
}

export const HeaderChatModalTableView: React.FC<IHeaderChatModalTableViewProps> = ({
  conversationsListChat,
  orderingColumns,
  handleOpenChat,
  getUnreadChat,
  infiniteScrollProps,
}) => {
  const renderText = useCallback((value: string, bold?: boolean, hasTooltip?: boolean) => {
    if (hasTooltip) {
      return (
        <Tooltip title={<div style={{ fontSize: '11px' }}>{value}</div>}>
          <TextContainerStyled isBold={bold}>{value}</TextContainerStyled>
        </Tooltip>
      );
    }

    return <TextContainerStyled isBold={bold}>{value}</TextContainerStyled>;
  }, []);

  const renderInfos = useCallback((information: IConversationChatDomain): ReactElement => {
    if (information.purchaseOfferId && information.saleOfferId) {
      return (
        <ContainerTooltipStyled>
          <TooltipComponent
            title={
              <ContainerTooltipStyled>
                <ContainerInfosTooltipStyled>
                  <ContainerInfosPurchaseStyled>
                    <LabelTooltipStyled>Oferta de Compra</LabelTooltipStyled>
                    <LabelTooltipStyled>ID {information.purchaseOfferId}</LabelTooltipStyled>
                  </ContainerInfosPurchaseStyled>
                  <ContainerInfosSaleStyled>
                    <LabelTooltipStyled>Oferta de Venda</LabelTooltipStyled>
                    <LabelTooltipStyled>ID {information.saleOfferId}</LabelTooltipStyled>
                  </ContainerInfosSaleStyled>
                </ContainerInfosTooltipStyled>
              </ContainerTooltipStyled>
            }
            location="top"
            icon={<Info size={16} color="#6e6e6e" />}
          />
        </ContainerTooltipStyled>
      );
    }

    return (
      <ContainerTooltipStyled>
        <TooltipComponent
          title={
            <ContainerTooltipStyled>
              <ContainerInfosTooltipStyled>
                <LabelTooltipStyled>Frete ID {information.threadId}</LabelTooltipStyled>
              </ContainerInfosTooltipStyled>
            </ContainerTooltipStyled>
          }
          location="top"
          icon={<Info size={16} color="#6e6e6e" />}
        />
      </ContainerTooltipStyled>
    );
  }, []);

  const getColorTag = useMemo((): { [key in string]: string } => {
    return {
      TRANSPORTADORA: ColorsV2Type.Red,
      LOGSHARE: ColorsV2Type.Aqua1,
      EMBARCADOR: ColorsV2Type.Blue,
      COMPRADOR: ColorsV2Type.Red,
      VENDEDOR: ColorsV2Type.Green,
      NEGOCIAÇÃO: 'yellow',
    };
  }, []);

  const renderTag = useCallback(
    (value: IChatObject) => {
      return (
        <Tag
          label={value.name}
          color={getColorTag[value.name.toUpperCase()]}
          variant="ghost"
          display="inline-flex"
          size="md"
        />
      );
    },
    [getColorTag],
  );

  const renderUnreadTag = useCallback(
    (chatId: string) => {
      const countUnread = getUnreadChat(chatId);
      const count = countUnread ? countUnread : 0;

      if (count === 0) {
        return (
          <TagContainerStyled>
            <TextContainerStyled isBold>{count}</TextContainerStyled>
          </TagContainerStyled>
        );
      }

      return <UnreadTagContainerStyled>{count}</UnreadTagContainerStyled>;
    },
    [getUnreadChat],
  );

  const renderButton = useCallback(
    (id: string, chatName: string) => {
      return (
        <ChatButtonStyled>
          <ChatButtonTextContainerStyled onClick={() => handleOpenChat(id, chatName)}>
            Abrir <ChatButtonTextBoldStyled>Chat</ChatButtonTextBoldStyled>
          </ChatButtonTextContainerStyled>
        </ChatButtonStyled>
      );
    },
    [handleOpenChat],
  );

  const renderRows = useMemo((): Array<IRow> => {
    return conversationsListChat.map((conversation) => {
      return {
        key: conversation.id,
        onClick: () => undefined,
        valuesColumns: [
          { value: renderText(conversation.threadId) },
          { value: renderInfos(conversation) },
          { value: renderTag(conversation.typePartner) },
          {
            value: (
              <PartnerContainerStyled>
                {renderText(conversation.partner.toUpperCase(), true, true)}
              </PartnerContainerStyled>
            ),
            width: '95%',
          },
          {
            value: (
              <OperationTypeContainerStyled>
                {renderText(conversation.operation.toUpperCase())}
              </OperationTypeContainerStyled>
            ),
          },
          {
            value: (
              <StartDateContainerStyled>{renderText(conversation.startDate.toUpperCase())}</StartDateContainerStyled>
            ),
          },
          {
            value: (
              <EndDateContainerStyled>{renderText(conversation.lastMessage.toUpperCase())}</EndDateContainerStyled>
            ),
          },

          { value: renderUnreadTag(conversation.id) },
          {
            value: renderButton(conversation.id, conversation.typePartner.name),
            isClicable: false,
          },
        ],
      };
    });
  }, [conversationsListChat, renderButton, renderInfos, renderTag, renderText, renderUnreadTag]);

  return (
    <Table
      gridColumns={chatModalColumnsConfig}
      header={chatModalHeaderColumns}
      ordering={orderingColumns}
      rows={renderRows}
      height={'64vh'}
      infiniteScrollProps={infiniteScrollProps}
      config={{ body: { cursorInitial: true }, textTransform: 'none' }}
      alignLinesCenter={true}
    />
  );
};
