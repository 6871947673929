import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useVehicleList } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-vehicle-list/use-vehicle-list.hook';
import { ScheduleDetailsAllocationVehicleListModalView } from './schedule-details-allocation-vehicle-list-modal-view.component';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { IScheduleDetailsAllocationVehicleListProps } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-vehicle-list/get-vehicle-list.types';
import { isEqual } from 'lodash';

const initialStateFilter: IScheduleDetailsAllocationVehicleListProps = {
  search: '',
  page: 1,
  limitRegistry: 50,
  status: 'active',
  order: 'DESC',
  column: 'driver.id',
};

export const ScheduleDetailsAllocationVehicleListModal: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const [isLimitRequest, setIsLimitRequest] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<IScheduleDetailsAllocationVehicleListProps>(initialStateFilter);
  const [currentFilter, setCurrentFilter] = useState<IScheduleDetailsAllocationVehicleListProps>({
    ...initialStateFilter,
    page: 0,
  });
  const { loading } = initialLoading;
  const getVehicleListHook = useVehicleList();

  const isLoading = useMemo(() => loading, [loading]);

  const isLazyLoading = useMemo(
    () => scheduleDetails.allocationVehicle.modalProps.statusRequest === 'PROCESSING' && !isLoading,
    [isLoading, scheduleDetails.allocationVehicle.modalProps.statusRequest],
  );

  const isError = useMemo(
    () => scheduleDetails.allocationVehicle.modalProps.statusRequest === 'ERROR',
    [scheduleDetails.allocationVehicle.modalProps.statusRequest],
  );

  const isEmpty = useMemo(
    () =>
      scheduleDetails.allocationVehicle.modalProps.statusRequest === 'SUCCESS' &&
      !scheduleDetails.allocationVehicle.modalProps.vehicleList.length,
    [
      scheduleDetails.allocationVehicle.modalProps.statusRequest,
      scheduleDetails.allocationVehicle.modalProps.vehicleList.length,
    ],
  );

  const handleSearch = useCallback((search: string) => {
    const filter: IScheduleDetailsAllocationVehicleListProps = { ...initialStateFilter, search };

    setPage(1);
    setCurrentFilter({ ...filter, page: 0 });
    setFilter(filter);
  }, []);

  const handleSelectedVehicle = useCallback(
    (checked: boolean, vehicleId: number) => {
      scheduleDetails.allocationVehicle.modalProps.setScheduleDetailsSelectedVehicle(checked, vehicleId);
    },
    [scheduleDetails.allocationVehicle.modalProps],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (!isEqual(filter, currentFilter)) {
      setCurrentFilter(filter);
      getVehicleListHook(filter, setIsLimitRequest);
    }
  }, [currentFilter, filter, getVehicleListHook]);

  useEffect(() => {
    if (!isLimitRequest && page > filter.page) {
      setFilter({ ...filter, page });
    }
  }, [filter, isLimitRequest, page, scheduleDetails.allocationDriver.modalProps.statusRequest]);

  return (
    <ScheduleDetailsAllocationVehicleListModalView
      statusProps={{ isError, isLoading, isEmpty }}
      vehicles={scheduleDetails.allocationVehicle.modalProps.vehicleList}
      vehicleSelected={scheduleDetails.allocationVehicle.modalProps.vehicleSelected}
      handleSearch={handleSearch}
      infiniteScrollProps={infiniteScrollProps}
      handleSelectedVehicle={handleSelectedVehicle}
    />
  );
};
