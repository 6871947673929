export const convertColumnName = (columnName: string): string => {
  const columns: { [key: string]: string } = {
    Oferta: 'id',
    Tipo: 'operationType',
    'Origem / Destino': '',
    Veiculo: 'vehicleType',
    Capacidade: '',
    Classificação: 'offerTo',
    'Match Potencial': 'match',
    Frequência: 'frequency',
    'Frete Valor': 'freightValue',
  };

  return columns[columnName] ?? '';
};

export const convertNameColumn = (nameColumn: string): string => {
  const columns: { [key: string]: string } = {
    id: 'Oferta',
    operationType: 'Tipo',
    'Origem / Destino': '',
    vehicleType: 'Veiculo',
    Capacidade: '',
    offerTo: 'Classificação',
    match: 'Match Potencial',
    frequency: 'Frequência',
    freightValue: 'Frete Valor',
  };

  return columns[nameColumn] ?? '';
};
