import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { getIcmsService } from 'utils/get-icms/service/get-icms.service';
import { formatCurrency } from 'utils/format-numbers';
import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { IBiddingCost } from 'domain/bidding';
import { showToast } from 'components/toast/toast';
import { InputCurrency } from 'components/input-currency/input-currency';
import { ThemeColorsV2Type } from 'styles/types/colors';
import TruckLoadingIcon from 'assets/icons/truck-loading.icon';
import { ICompany } from 'domain/company';

interface ICardProposedValueProps {
  proposedValue: number;
  shippingHomologate?: boolean;
  isIdlenessBidding?: boolean;
  companyId?: number;
  merchValue?: number;
  originUf?: string;
  destinyUf?: string;
  handleProposedValue: (value: IBiddingCost) => void;

  isSchedule?: boolean;
  spotValue: number;
  userCompany?: ICompany;
}

export const CardProposedValue: React.FC<ICardProposedValueProps> = ({
  proposedValue,
  shippingHomologate,
  isIdlenessBidding,
  companyId,
  merchValue,
  originUf,
  destinyUf,
  handleProposedValue,

  isSchedule,
  spotValue,
  userCompany,
}) => {
  const { user } = useContext(UserContext);

  const [valueProposed, setValueProposed] = useState<string>();
  const [grisAdv, setGrisAdv] = useState<number>(0);
  const [company, setCompany] = useState<any>();
  const [icms, setIcms] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getGrisAdvaloremValue();
  }, [companyId]);

  useEffect(() => {
    getIcmsValue();
  }, [originUf, destinyUf]);

  const getGrisAdvaloremValue = useCallback(async () => {
    if (!companyId) return;

    try {
      const company = await companyDetailsService(Number(user?.companyId));

      const grisAdvalorem = ((Number(company.advalorem) + Number(company.gris)) * (merchValue || 0)) / 100;

      setCompany(company);
      setGrisAdv(grisAdvalorem);
    } catch (err: any) {
      showToast({
        message: err,
        type: 'error',
      });
    }
  }, [companyId, merchValue, user?.companyId]);

  const getIcmsValue = useCallback(async () => {
    if (!originUf || !destinyUf) return;

    try {
      const response = await getIcmsService({
        ufOrigin: originUf,
        ufDestiny: destinyUf,
      });

      setIcms(response);
    } catch (err: any) {
      showToast({
        message: err,
        type: 'error',
      });
    }
  }, [originUf, destinyUf]);

  const logshareFee = useMemo(() => {
    if (shippingHomologate && !isSchedule) return 62;

    const proposedValueFormatted = String(proposedValue).replace(',', '.');

    if (isSchedule && userCompany?.feeLogshare) {
      const valueFee = Number(proposedValueFormatted) * (Number(userCompany.feeLogshare) / 100);

      if (!isNaN(valueFee)) return valueFee;
    }

    const valueFee = Number(proposedValueFormatted) * (company?.feeLogshare / 100);

    if (!isNaN(valueFee)) return valueFee;

    return 0;
  }, [shippingHomologate, proposedValue, isSchedule, userCompany?.feeLogshare, company?.feeLogshare]);

  const totalFreight = useMemo(() => {
    const proposedValueFormatted = String(proposedValue).replace(',', '.');
    const logshareFeeFormatted = String(logshareFee).replace(',', '.');

    let icmsValue = 0;

    const total =
      (Number(proposedValueFormatted) || Number(logshareFeeFormatted)) - Number(logshareFeeFormatted) + grisAdv;

    if (company?.type !== 'shipper' && shippingHomologate) {
      icmsValue = total / (1 - icms / 100) - total;

      const totalService = total + icmsValue;

      return { total, icmsValue, totalService };
    }

    const totalService = total;

    return { total, totalService, icmsValue };
  }, [company, logshareFee, grisAdv, proposedValue, icms, shippingHomologate, isIdlenessBidding]);

  useEffect(() => {
    if (spotValue) {
      handleProposedValue({
        freightValue: spotValue,
        tollValue: 0,
        grisAdvalorem: grisAdv,
        totalFreight: totalFreight.total,
        icms: totalFreight.icmsValue,
        totalService: totalFreight.totalService,
        feeLogshare: logshareFee,
      });
    }
  }, [spotValue, grisAdv, icms]);

  const handleValueChange = useCallback(
    (value?: string) => {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      const freightValue = Number(value?.replace(',', '.') ?? 0);

      setValueProposed(value);

      handleProposedValue({
        freightValue: freightValue,
        tollValue: 0,
        grisAdvalorem: grisAdv,
        totalFreight: totalFreight.total,
        icms: totalFreight.icmsValue,
        totalService: totalFreight.totalService,
        feeLogshare: logshareFee,
      });
    },
    [grisAdv, totalFreight, logshareFee],
  );

  const acceptancePotential = useMemo((): { color: ThemeColorsV2Type; text: string } => {
    const valueFreight = totalFreight.totalService;

    if (valueFreight < spotValue * 0.8) {
      return { color: ThemeColorsV2Type.Green, text: 'ALTO POTENCIAL' };
    }

    if (valueFreight > spotValue * 1.05) {
      return { color: ThemeColorsV2Type.Red, text: 'BAIXO POTENCIAL' };
    }

    return { color: ThemeColorsV2Type.ProYellow, text: 'MÉDIO POTENCIAL' };
  }, [spotValue, totalFreight.totalService]);

  const renderValueProposed = useMemo((): ReactElement | undefined => {
    if (isSchedule && isLoading) {
      return (
        <S.ContainerLoading>
          <TruckLoadingIcon />
        </S.ContainerLoading>
      );
    }

    if (isSchedule && totalFreight.totalService !== 0) {
      return (
        <S.HeaderValueProposed>
          <S.ContainerAcceptancePotential>
            <S.TextAcceptancePotential>BASEADO NO VALOR HISTÓRICO DO FRETE TRANSACIONADO</S.TextAcceptancePotential>
          </S.ContainerAcceptancePotential>

          <S.ContainerAcceptancePotential>
            O <S.TextBoldAcceptancePotential>FRETE PROPOSTO</S.TextBoldAcceptancePotential> APRESENTA
            <S.TagAcceptancePotential color={acceptancePotential.color}>
              {acceptancePotential.text}
            </S.TagAcceptancePotential>
            DE ACEITE.
          </S.ContainerAcceptancePotential>
        </S.HeaderValueProposed>
      );
    }
  }, [acceptancePotential, isLoading, isSchedule, totalFreight.totalService]);

  return (
    <S.WrapperCardProposed>
      <S.WrapperValueProposed>{renderValueProposed}</S.WrapperValueProposed>

      <S.Wrapper>
        <S.Content>
          <S.CustomWrapper>
            <S.CustomWrapper style={{ display: 'flex', whiteSpace: 'break-spaces' }}>
              <S.TitleText>{'Informe o '}</S.TitleText>
              <S.TitleText isBold>{'Frete Proposto'}</S.TitleText>
            </S.CustomWrapper>
            <InputCurrency
              id="cost.freightValue"
              name="cost.freightValue"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={handleValueChange}
              value={valueProposed ?? proposedValue?.toFixed(2)}
            />
            <S.Info>Sem ICMS e pedágio</S.Info>
          </S.CustomWrapper>

          <S.Container>
            <S.Label>Frete Valor</S.Label>
            <S.Label>{formatCurrency(proposedValue ?? 0)}</S.Label>
          </S.Container>

          <S.Container>
            <S.TooltipContent>
              <S.Label>Taxa LogShare</S.Label>
              <Tooltip
                title={
                  <span style={{ fontSize: '11px' }}>
                    PARA TRANSPORTADORAS NÃO HOMOLOGADAS, COBRAMOS UM PERCENTUAL DE 10% EM CIMA DO VALOR PROPOSTO. JÁ
                    PARA HOMOLOGADAS UMA TAXA FIXA DE R$62,00.
                  </span>
                }
                placement="right"
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon
                    sx={{
                      height: '11px',
                      width: '11px',
                      color: 'white',
                    }}
                  />
                </div>
              </Tooltip>
            </S.TooltipContent>
            <S.Label>{formatCurrency(logshareFee)}</S.Label>
          </S.Container>

          <S.Container>
            <S.Label>Pedágio</S.Label>
            <S.SubTitle>A INCLUIR</S.SubTitle>
          </S.Container>

          <S.Container>
            <S.Label>Gris/Advalorem</S.Label>
            <S.Label>{formatCurrency(grisAdv)}</S.Label>
          </S.Container>

          <S.Container>
            <S.Title>Frete Total</S.Title>
            <S.Title>{formatCurrency(totalFreight.total)}</S.Title>
          </S.Container>

          <S.Container>
            <S.Label>ICMS</S.Label>
            <S.Label>{convertCurrencyFormat.format(totalFreight.icmsValue)}</S.Label>
          </S.Container>

          <S.Container>
            <S.TooltipContent>
              <S.Title>Total a Receber</S.Title>
              <Tooltip
                title={<span style={{ fontSize: '11px' }}>O CTE SERÁ EMITIDO COM ESSE VALOR.</span>}
                placement="right"
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon sx={{ height: '11px', width: '11px', color: 'white' }} />
                </div>
              </Tooltip>
            </S.TooltipContent>
            <S.Title>{formatCurrency(totalFreight.totalService)}</S.Title>
          </S.Container>
        </S.Content>
      </S.Wrapper>
    </S.WrapperCardProposed>
  );
};
