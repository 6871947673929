import styled, { css } from 'styled-components';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IProps {
  color: ThemeColorsV2Type;
  listEmpty?: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-right: 6px;
  overflow: hidden;
`;

export const ContainerHeader = styled.div<IProps>`
  ${({ theme, color }) => css`
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colorsV2[color]};
    padding: 16px;
    margin-right: 8px;
    border-radius: 6px;
    font-size: ${theme.fontV2.sizes.text4};
    color: ${theme.colorsV2.white};
    cursor: default;
  `}
`;

export const Label = styled.span``;

export const ContainerScroll = styled.div<IProps>`
  ${({ listEmpty }) => css`
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    justify-content: ${listEmpty ? 'center' : ''};

    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: inset 0 0 5px rgb(243 243 243);
      border-radius: 4px;
      border-left: 1.3px solid transparent;
      border-right: 1.3px solid transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #8888889c;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #12264e;
      width: 50px;
    }
  `}
`;

export const CardRouterBody = styled.div`
  padding-right: 6px;

  & > div {
    margin-top: 20px;
  }
`;

interface IWrapperListProps {
  cursor: boolean;
}

export const WrapperList = styled.div<IWrapperListProps>`
  ${({ theme, cursor }) => css`
    height: fit-content;
    width: 100%;
    background-color: ${theme.colorsV2.white};
    border: 1px solid ${theme.colorsV2.forBorder};
    border-radius: 6px;
    font-family: ${theme.font.family};
    font-size: ${theme.fontV2.sizes.text4};
    cursor: ${cursor && 'pointer'};

    &:hover {
      background-color: #f3f3f3;
    }

    &:hover ${ContainerButton} {
      display: flex;
    }
  `}
`;

export const ContainerKanban = styled.div`
  padding: 15px 15px 10px 15px;
  cursor: pointer;
`;

export const ContainerHeaderCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const ContainerCardCompany = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CompanyLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 25px;
  margin-top: 3px;
`;

export const ContainerCompany = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LabelId = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const LabelCompanyName = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerFrequency = styled.div`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${theme.colorsV2.circleCautMatch};
    font-weight: 500;
  `}
`;

export const ContainerContent = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 10px 0;
`;

export const ContainerLocation = styled.div`
  width: 95%;
  display: flex;
  gap: 0.5rem;
`;

export const ContentLocation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ContentSaleButton = styled.div`
  button {
    min-width: 80px !important;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px 15px 15px;
`;

export const CardFavoriteMatch = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ContainerButton = styled.div`
  display: flex;
  gap: 10px;
`;

export const CardInfo = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Div = styled.div``;

export const WrapperEmpty = styled.p`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: rgb(131, 131, 131);
`;
