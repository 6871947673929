import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IStyledProps {
  fontSize?: number;
  fontWeight?: number;
  color?: ThemeColorsV2Type;
  secondaryLabel?: boolean;
}
export const ValueContainer = styled.p<IStyledProps>`
  ${({ theme, fontSize, fontWeight, color, secondaryLabel }) => css`
    font-size: ${fontSize ?? 11}px;
    font-weight: ${fontWeight};
    color: ${theme.colorsV2[color ?? 'black']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: ${secondaryLabel ? 6 : 0}px;
  `}
`;

export const ValueContainerWrapper = styled.div`
  margin-top: 1px;
`;

export const Wrapper = styled.div`
  min-width: 100%;
`;

export const ButtonContainer = styled.div`
  button {
    max-height: 37px;
    max-width: 37px;
  }
`;

export const LabelWrapper = styled.div`
  justify-content: left;
  align-items: normal;
  margin-top: 1px;
`;

export const TimerCountdown = styled.div`
  ${() => css`
    display: flex;
  `}
`;
