import React, { ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface IFreightTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (freightId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  indexColumnId?: number;
  clickOrderBy?: (type: string) => void;
  clickSortDirection?: () => void;
}

const dataTestId = 'freight-table-view';

const FreightTableView: React.FC<IFreightTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  clickOrderBy,
  clickSortDirection,
  arrowOrder,
  isLoading,
  indexColumnId,
}: IFreightTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <ContainerPaginationStyled data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
        indexColumnId={indexColumnId}
      />

      {renderPagination}
    </ContainerTableStyled>
  );
};

export default FreightTableView;
