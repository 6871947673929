export const tableCBackhaul = (axis: number): number => {
  switch (axis) {
    case 2:
      return 2.6051;
    case 3:
      return 3.2768;
    case 4:
      return 3.7747;
    case 5:
      return 4.2519;
    case 6:
      return 4.8058;
    case 7:
      return 5.2635;
    case 9:
      return 5.9825;
    default:
      return 0;
  }
};
