import {
  IShippingCompanyDomain,
  IShippingCompanyUpdateDomain,
} from 'src-new/pages/registrations/domains/shipping-company';

export const updateShippingCompanyMap = (input: IShippingCompanyDomain): IShippingCompanyUpdateDomain => {
  return {
    id: input?.id,
    internalCode: input.internalCode,
    name: input.name,
    status: input.status,
    address: {
      cep: input.address.cep,
      uf: input.address.uf,
      city: input.address.city,
      neighborhood: input.address.neighborhood,
      street: input.address.street,
      numberHouse: input.address.numberHouse,
      complement: input.address.complement,
      lat: input.address.lat,
      lng: input.address.lng,
    },
    bank: {
      code: input.bank.code,
      agency: input.bank.agency,
      CC: input.bank.CC,
      pix: input.bank.pix,
    },
    rntrcs: input.rntrcs,
    security: input.security,
    contacts: input.contacts,
    attachments: input.attachments,
    userEmail: input.userEmail,
    userName: input.userName,
    vehiclesServiced: input.vehiclesServiced,
    originalAttachments: input.originalAttachments,
    approvedTrackers: input.approvedTrackers,
  };
};
