import React, { useContext, useEffect, useMemo } from 'react';
import { ScheduleKanbanListView } from './schedule-kanban-list-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useGetKanbanList } from '../../hooks/use-get-kanban-list/use-get-kanban-list.hook';

export const ScheduleKanbanList: React.FC = () => {
  const { scheduleKanbanList } = useContext(ScheduleContext);
  const getKanbanListHook = useGetKanbanList();

  const isLoading = useMemo(
    () => scheduleKanbanList.statusRequest === 'PROCESSING',
    [scheduleKanbanList.statusRequest],
  );

  useEffect(() => {
    getKanbanListHook();
  }, [getKanbanListHook]);

  return <ScheduleKanbanListView kanbanList={scheduleKanbanList.scheduleKanbanList} isLoading={isLoading} />;
};
