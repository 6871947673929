/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useVehicleList } from '../../hooks/use-vehicle-list/use-vehicle-list.hook';
import { VehiclesContext } from '../../contexts/table-states/vehicles.context';

import VehicleListBodyView from './vehicle-list-body-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile, downloadTemplate } from 'utils/download-file/download-file';
import { convertActiveTabToStatusDomainMapper } from 'pages/vehicle/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import { useVehicleUpload } from 'pages/vehicle/hooks/use-vehicle-upload/use-vehicle-upload.hook';
import { useReportExcelHook } from 'pages/vehicle/hooks/use-vehicle-report-excel/use-vehicle-report-excel.hook';
import { useDeleteVehicleHook } from 'pages/vehicle/hooks/use-vehicle-delete/use-vehicle-delete.hook';
import { IVehicleStatus } from 'domain/vehicle';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';
import ModalUpload from 'components/modal-upload/modal-upload';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const OptionsOrder: Array<ReportOption> = [
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Registro mais Antigo', value: 'asc' },
];

const VehicleListBody: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Ativos');
  const [orderBy, setOrderBy] = useState<string>('vehicle.id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const vehicleListHook = useVehicleList();
  const vehicleUploadHook = useVehicleUpload();
  const GenerateExcelHook = useReportExcelHook();
  const { vehicles, vehiclesIsLoading, vehiclesPageInfo } = useContext(VehiclesContext);
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);
  const deleteVehicleHook = useDeleteVehicleHook();
  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const goToCreateVehicle = () => {
    navigate('/veiculos/novo');
  };

  const goToUpdatePage = useCallback(
    (vehicleId: string) => {
      navigate(`/veiculos/atualizar/${vehicleId}`);
    },
    [navigate],
  );

  const handleRemoveVehicle = async (ids: number[], exclude = false) => {
    if (ids.length === 0) {
      showToast({
        message: 'Escolha pelo menos um veículo para excluir',
        type: 'error',
      });

      return;
    }

    if (exclude) {
      await deleteVehicleHook(ids);
      setModalConfirmDelete({ description: '', open: false });
      vehicleListHook({
        searchValue: searchInput,
        currentPage,
        status: renderStatusByTabName,
        order: sortDirection,
        column: orderBy,
      });
      setIdsToRemove([]);
    } else {
      const description = (
        <>
          <p>Isso excluirá o veículo da listagem.</p>
          <p style={{ marginTop: 5 }}>Tem certeza?</p>
        </>
      );
      setModalConfirmDelete({ description, open: true });
    }
  };

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName) as IVehicleStatus,
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'VEICULO');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  const renderStatusByTabName = useMemo((): IVehicleStatus | undefined => {
    if (activeTabName === 'Todos') {
      return undefined;
    }

    if (activeTabName === 'Ativos') {
      return 'ativo';
    }

    if (activeTabName === 'Inativos') {
      return 'inativo';
    }

    if (activeTabName === 'Excluídos') {
      return 'deletado';
    }

    return undefined;
  }, [activeTabName]);

  const handleOrderList = useCallback(
    async (optionOrder: ReportOption) => {
      if (optionOrder.value === 'name') {
        vehicleListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'vehicleType',
        });
      }
      if (optionOrder.value === 'asc') {
        vehicleListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'id',
        });
      }
      showToast({
        type: 'info',
        message: 'Atualizando listagem',
      });
    },
    [currentPage, renderStatusByTabName, searchInput, vehicleListHook],
  );

  useEffect(() => {
    vehicleListHook({
      searchValue: searchInput,
      currentPage: searchInput ? 1 : currentPage,
      status: renderStatusByTabName,
      order: sortDirection,
      column: orderBy,
    });
  }, [currentPage, sortDirection, orderBy, renderStatusByTabName, searchInput, vehicleListHook]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Veiculos.xlsx');
  }, []);

  const handleOpenUploadXlsx = async () => {
    setShowModal(true);
  };

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await vehicleUploadHook(formData);
        if (data.uploadDate === 'error') {
          showToast({
            message: `Erro ao importar dados ${data.error.message}`,
            type: 'error',
          });
        } else {
          showToast({
            message: `Importação de ${data.total} arquivo(s) realizada com sucesso`,
            type: 'success',
          });
          vehicleListHook({
            searchValue: '',
            currentPage: 1,
            status: 'ativo',
            order: 'desc',
            column: 'id',
          });
          setShowModal(false);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    [vehicleUploadHook, setShowModal],
  );

  const handleOrderBy = (order: string) => {
    const orderMap: Record<string, string> = {
      ID: 'vehicle.id',
      Parceiro: 'company.tradeName',
      'Tipo de Veículo': 'vehicle.vehicleType',
      Placa: 'vehicle.mainBoard',
      'Tipo de Frota': 'vehicle.fleetType',
      Transportadora: 'shippingCompany.name',
      Status: 'vehicle.status',
    };

    const newOrderBy = orderMap[order];
    setOrderBy(newOrderBy || '');
  };

  return (
    <>
      {showModal && (
        <ModalUpload
          title="Cadastro de Veículos"
          description="Selecione o arquivo"
          handleCancel={showModalConfirmation}
          handleSuccess={handleSuccess}
          handleDownload={handleDownload}
        />
      )}
      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Veículo(s)"
          secondTitle={modalConfirmDelete?.description}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveVehicle(idsToRemove, true)}
          description={undefined}
          size="small"
          type="delete"
        />
      )}
      <VehicleListBodyView
        vehicles={vehicles}
        hasLoading={vehiclesIsLoading}
        goToCreateVehicle={goToCreateVehicle}
        goToUpdatePage={goToUpdatePage}
        setCurrentPage={setCurrentPage}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        handleOpenUploadXlsx={handleOpenUploadXlsx}
        vehiclesPageInfo={vehiclesPageInfo}
        onHandleExport={handleGenerateExcel}
        options={Options}
        handleOrderList={handleOrderList}
        OptionsOrder={OptionsOrder}
        handleRemoveVehicle={handleRemoveVehicle}
        idsToRemove={idsToRemove}
        setIdsToRemove={setIdsToRemove}
        clickOrderBy={(e: string) => handleOrderBy(e)}
        clickSortDirection={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
      />
    </>
  );
};

export { VehicleListBody };
