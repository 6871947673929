import React, { ReactNode } from 'react';

import {
  IconStyled,
  InputStyled,
  InputWrapperStyled,
  LabelStyled,
  MessageErrorStyled,
  WrapperStyled,
} from './input-currency.styled';
import { InputCurrencyFieldProps } from './input-currency';

export type InputFieldProps = {
  label?: ReactNode;
  placeholder?: string;
  initialValue?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  passwordVisible?: boolean;
  className?: string;
  upperCase?: boolean;
  changePasswordVisible?: () => void;
  decimalScale?: number;
  decimalsLimit?: number;
  decimalSeparator?: string;
  allowDecimals?: boolean;
  groupSeparator?: string;
};

export const InputCurrencyView: React.FC<InputCurrencyFieldProps> = ({
  id,
  name,
  label = '',
  placeholder = '',
  reactNode,
  reactNodePosition = 'left',
  disabled = false,
  hasError = false,
  errorMessage = 'Invalid value',
  className = '',
  onChange,
  onBlur,
  allowDecimals,
  changePasswordVisible,
  prefix,
  defaultValue,
  decimalsLimit,
  decimalScale,
  decimalSeparator = ',',
  groupSeparator = '.',
  intlConfig,
  onValueChange,
  suffix,
  value,
}) => {
  return (
    <WrapperStyled className={className}>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <InputWrapperStyled iconPosition={reactNodePosition} disabled={disabled} hasError={hasError}>
        {!!reactNode && <IconStyled onClick={changePasswordVisible}>{reactNode}</IconStyled>}
        <InputStyled
          disabled={disabled}
          id={id}
          name={name}
          placeholder={placeholder}
          prefix={prefix}
          allowDecimals={allowDecimals}
          suffix={suffix}
          defaultValue={defaultValue}
          decimalScale={decimalScale}
          decimalsLimit={decimalsLimit}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          intlConfig={intlConfig}
          onValueChange={onValueChange}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          hasError={hasError}
          disableAbbreviations
        />
      </InputWrapperStyled>
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};
