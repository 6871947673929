import { PlanningScheduleInput } from '../types';

import { PlanningRouteInput, PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';
import { ScheduleMyRouteRegisterInput } from 'domain-v2/my-route/register-schedule';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';

export const initialValues: MyRouterRegisterInput = {
  internalCode: '',
  operationType: 'inbound',
  processType: 'frota dedicada',
  planningRoute: [],
  leadTime: 0,
  helper: '',
  paletizado: 'false',
  origin: {
    id: 0,
    type: '',
    tradeName: '',
    origin: {
      id: 0,
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      oubound: undefined as any,
      internalCode: '',
      occurrenceEmail: [''],
      quality_and_safety: '',
      schedulingEmail: [''],
      status: 'ativo',
      type: '',
      name: '',
      vehicleRestriction: [''],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
  },
  destination: {
    id: 0,
    type: '',
    tradeName: '',
    destination: {
      id: 0,
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      oubound: undefined as any,
      internalCode: '',
      occurrenceEmail: [''],
      quality_and_safety: '',
      schedulingEmail: [''],
      status: 'ativo',
      type: '',
      name: '',
      vehicleRestriction: [''],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
  },
  planningSchedule: [],
  status: 'active',
};

export const planningRouteInitialValues: PlanningRouteInput = {
  name: '',
  plannedBy: '',
  allowedVehicles: [],
  expander: false,
  initialDate: '',
  finalDate: '',
};

export const planningVehicleInitialValues: PlanningRouteVehicle = {
  vehicleType: '',
  vehicleCategory: '',
  allowedShipping: [],
};

export const planningShippingInitialValues: PlanningRouteShipping = {
  companyShippingId: 0,
  companyShippingName: '',
  validity: '',
  plannedTrips: 0,
  offeredTrips: 0,
  acceptedTrips: 0,
  refusedTrips: 0,
};

export const planningScheduleInitialValues: PlanningScheduleInput = {
  internalCode: '',
  initialDate: '',
  initialTime: '',
  finalDate: '',
  comments: '',
};

export const createAllSchedules: ScheduleMyRouteRegisterInput = {
  scheduleClientOrigin: {
    clientType: 'client',
    clientId: 0,
    localityId: 0,
  },
  scheduleClientDestiny: {
    clientType: 'client',
    clientId: 0,
    localityId: 0,
  },
  scheduleCargo: {
    weight: 0,
    capacity: 0,
    shippingValue: '',
    inPalet: false,
    pallet: 0,
    vehicleType: '',
    vehicleCategoryType: '',
    shippingReturn: '',
    operationType: '',
    needHelper: '',
    needBodyGuard: false,
    invoice: '',
    orderNumber: '',
  },
  scheduleDetails: {
    levyInitialDate: '',
    levyEndDate: '',
    levyInitialTime: '',
    levyEndTime: '',
    deliveryInitialDate: '',
    deliveryEndDate: '',
    deliveryInitialTime: '',
    deliveryEndTime: '',
    internalNumber: '',
    ocInternalNumber: '',
    boardingNumber: '',
    schedulingPassword: '',
  },
  scheduleShippingCompanyId: 0,
  myRoute: {
    idRoute: 0,
    relationRouteId: 0,
    validity: 0,
  },
  status: '',
  statusCode: '',
  cubagem: '',
  currentFreight: 0,
  value_freight: 0,
  helper_quantity: 0,
  helper_amount: 0,
  helper_total: 0,
  toll: 0,
  gris: 0,
  advalorem: 0,
  value_goods: 0,
  value_total_fee: 0,
  taxes: 0,
  total_freight: 0,
  distance: 0,
  operation: '',
  comments: '',
  isCteLogshare: false,
};
