import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { IListPage } from 'src-new/domains/list-page.domain';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getPurchaseOfferMatchListService } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-match-list/get-purchase-offer-match-list.service';
import { IPurchaseOfferMatchListResponse } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-match-list/get-purchase-offer-match-list.types';
import { convertPurchaseOfferMatchListResponseToDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/hooks/use-purchase-offer-match-list/mappers/convert-purchase-offer-match-list-response-to-domain/convert-purchase-offer-match-list-reponse-to-domain.mapper';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';

export const useGetPurchaseOfferMatchList = () => {
  const { purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const {
    purchaseOfferMatchProposal,
    filters,
    setPurchaseOfferMatchListSuccess,
    setPurchaseOfferMatchListRequest,
    setPurchaseOfferMatchListError,
  } = purchaseOfferMatchList;
  const { selectedFilter } = filters;
  const { proposal } = purchaseOfferMatchProposal;

  const { data } = useQuery<IListPage<IPurchaseOfferMatchListResponse>>({
    queryKey: ['purchase-offer-match-list-table', selectedFilter, proposal.id],
    queryFn: () => {
      setPurchaseOfferMatchListRequest();
      return getPurchaseOfferMatchListService(proposal.id, selectedFilter);
    },
    onSuccess: (data: IListPage<IPurchaseOfferMatchListResponse>) => {
      const findAll = data.items.map((item) => convertPurchaseOfferMatchListResponseToDomain(item));
      setPurchaseOfferMatchListSuccess(findAll);
    },
    onError: (error) => {
      setPurchaseOfferMatchListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const findAll = data.items.map((item) => convertPurchaseOfferMatchListResponseToDomain(item));
      setPurchaseOfferMatchListSuccess(findAll);
    }
  }, [data, setPurchaseOfferMatchListSuccess]);
};
