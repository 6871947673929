import { IListPage } from 'src-new/domains/list-page.domain';
import { ISaleOfferMatchListFilterDomain } from '../../domains/sale-offer-match-list-filters.domain';
import { ISaleOfferMatchListResponse } from './get-sale-offer-match-list.types';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';

export const getSaleOfferMatchListService = async (
  saleOfferId: number,
  saleOfferTariffId: number,
  filters: ISaleOfferMatchListFilterDomain,
): Promise<IListPage<ISaleOfferMatchListResponse>> => {
  const { page, limitPage, backhaulType, matchId } = filters;

  const { data } = await API_TRAVELS_SVC.get<IListPage<ISaleOfferMatchListResponse>>(
    `/v1/matchs/sale-offer?offerId=${saleOfferId}&saleOfferShipperTariffId=${saleOfferTariffId}&page=${page}&limit=${limitPage}&backhaulType=${backhaulType}&matchId=${
      matchId ?? ''
    }`,
  );

  return data;
};
