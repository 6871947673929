import { freeTimeInitialValuesDomain } from 'src-new/domains/fretime.domain';
import { saleOfferFormDetailsInitialValuesDomain } from '../../pages/sale-offer-update/domains/sale-offer-details-initial-values.domain';
import { ISaleOfferContext, ISaleOfferError } from './types/sale-offer.types';
import { saleOfferMatchProposalInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-proposal-initial-values.constant';
import { saleOfferMatchSelectedInitialValuesConstant } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-selected-initial-values.constant';
import { saleOfferMatchListFilterInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-list-filters-initial-values.constant';

export const initialError: ISaleOfferError = {
  title: '',
  description: '',
};

export const saleOfferInitialState: ISaleOfferContext = {
  saleOfferModals: {
    indexSection: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
    isOpen: false,
    modalType: '',
    secondModalType: '',
    setModalOpen: () => undefined,
    setModalClose: () => undefined,
    setModalSecondaryClose: () => undefined,
  },
  saleOfferDetails: {
    statusRequest: 'INITIAL',
    saleOfferDetails: saleOfferFormDetailsInitialValuesDomain,
    setSaleOfferDetailsRequest: () => undefined,
    setSaleOfferDetailsSuccess: () => undefined,
    setSaleOfferDetailsError: () => undefined,
    setSaleOfferDetailsReset: () => undefined,
  },
  saleOfferList: {
    statusRequest: 'INITIAL',
    saleOfferList: [],
    selectedIds: [],
    setSaleOfferListRequest: () => undefined,
    setSaleOfferListSuccess: () => undefined,
    setSaleOfferListError: () => undefined,
    setSaleOfferListReset: () => undefined,
    setSelectIds: () => undefined,
  },
  saleOfferUpdate: {
    statusRequest: 'INITIAL',
    setSaleOfferUpdateRequest: () => undefined,
    setSaleOfferUpdateSuccess: () => undefined,
    setSaleOfferUpdateError: () => undefined,
    setSaleOfferUpdateReset: () => undefined,
  },
  saleOfferMatchList: {
    statusRequest: 'INITIAL',
    saleOfferMatchList: [],
    setSaleOfferMatchListRequest: () => undefined,
    setSaleOfferMatchListSuccess: () => undefined,
    setSaleOfferMatchListError: () => undefined,
    setSaleOfferMatchListReset: () => undefined,
    filters: {
      selectedFilter: saleOfferMatchListFilterInitialValues,
      setSelectFilters: () => undefined,
      setResetFilters: () => undefined,
    },
    saleOfferMatchProposal: {
      statusRequest: 'INITIAL',
      proposal: saleOfferMatchProposalInitialValues,
      setSaleOfferMatchProposalRequest: () => undefined,
      setSaleOfferMatchProposalSuccess: () => undefined,
      setSaleOfferMatchProposalError: () => undefined,
      setSaleOfferMatchProposalReset: () => undefined,
    },
    saleOfferMatchListSelected: {
      statusRequest: 'INITIAL',
      selectedMatch: saleOfferMatchSelectedInitialValuesConstant,
      setSaleOfferMatchListSelectedRequest: () => undefined,
      setSaleOfferMatchListSelectedSuccess: () => undefined,
      setSaleOfferMatchListSelectedError: () => undefined,
      setSaleOfferMatchListSelectedReset: () => undefined,
    },
  },
  saleOfferFreetime: {
    statusRequest: 'INITIAL',
    saleOfferFreetime: freeTimeInitialValuesDomain,
    setSaleOfferFreetimeRequest: () => undefined,
    setSaleOfferFreetimeSuccess: () => undefined,
    setSaleOfferFreetimeError: () => undefined,
    setSaleOfferFreetimeReset: () => undefined,
  },
  saleOfferListDownloadReport: {
    statusRequest: 'INITIAL',
    setSaleListDownloadReportRequest: () => undefined,
    setSaleListDownloadReportSuccess: () => undefined,
    setSaleListDownloadReportError: () => undefined,
    setSaleListDownloadReportReset: () => undefined,
  },
  setSaleOfferResetContext: () => undefined,
};
