import { LineSectionLayout } from 'components-v2/layout/line-section';
import AsyncSelectField from 'components/async-select/async-select';
import { FormikErrors } from 'formik';
import { vehicleFilterSearchService } from 'pages/vehicle/services/vehicle-search/vehicle.filter-search.service';
import { FC } from 'react';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';

interface IWeighingFormMountVehiclesViewProps {
  values: IWeighingFormRegisterDomain;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingFormRegisterDomain>>;
}

export const WeighingFormMountVehiclesView: FC<IWeighingFormMountVehiclesViewProps> = ({
  values,
  hasError,
  setFieldValue,
}) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Veículo *"
          isClearable={true}
          name="vehicleId"
          id="vehicleId"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue('vehicleId', e && e.value);
          }}
          apiCallFunction={vehicleFilterSearchService}
          loadOptions={vehicleFilterSearchService}
          hasError={hasError && !values.vehicleId}
          errorMessage="Campo Obrigatório"
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
