import axios from 'axios';

export const obterToken = async (authorizationCode: string) => {
  const url = 'https://logshare.auth.sa-east-1.amazoncognito.com/oauth2/token';
  const clientId = '44sp0583nh3pe891oth8ht79hb';
  const redirectUri = 'https://logshare.app/login';

  const resposta = await axios.post(
    url,
    `grant_type=authorization_code&client_id=${clientId}&=openid+email&code=${authorizationCode}&redirect_uri=${redirectUri}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

  const url2 = 'https://logshare.auth.sa-east-1.amazoncognito.com/oauth2/userInfo';
  const resultado = await axios.get(url2, {
    headers: {
      'Content-Type': 'application/x-amz-json-1.1',
      'Authorization': `Bearer ${resposta.data.access_token}`,
    },
  });
  return {
    token: resposta.data.access_token,
    email: resultado.data.email,
  }
}


