import React, { Fragment, useCallback, useMemo } from 'react';
import * as S from './schedule-table-view.styled';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import { ColorsV2Type } from 'styles/types/colors';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import { convertFreightType, convertOperationType, RenderStatus, StatusCode } from './schedule-table.types';
import { IUser } from 'domain/login';
import dayjs from 'dayjs';
import { IScheduleInformation, IScheduleListDomain } from '../../../../domains/schedule-list/schedule-list.domain';
import { Loading } from 'src-new/components/loading/loading.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Files, Info, Trash2 } from 'lucide-react';
import { DropdownButtonTable } from 'src-new/components/dropdown-button-table/dropdown-button-table.component';
import TooltipComponent from 'components/Tooltip';
import { OccurenceListIcon } from 'assets/icons/occurrence-list.icon';
import { convertOccurrenceIconColor } from 'src-new/utils/convert-occurrence-icon-color/convert-occurence-icon-color.util';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { TagTimer } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-table-tabs/components/tag-timer/tag-timer.component';
import { convertStatusCodeScheduleColor } from 'src-new/utils/convert-status-code-schedule/convert-status-code-schedule.util';

interface IScheduleLisViewProps {
  schedules: Array<IScheduleListDomain>;
  isAdmin: boolean;
  isShippingCompany: boolean;
  user: IUser | undefined;
  orderingColumns: IOrdering;
  isLoading: boolean;
  isEmpty: boolean;
  disabledCancelSchedule: Array<string>;
  infiniteScrollProps: IInfiniteScrollTable;
  handleLineClick: (id: number, statusCode: string) => void;
  handleCheckbox: (checked: boolean, scheduleId: number, statusCode: string) => void;
  handleOpenModals: (id: number, modalType: ScheduleListModalType) => void;
}

export const ScheduleTableView: React.FC<IScheduleLisViewProps> = ({
  schedules,
  isAdmin,
  isShippingCompany,
  user,
  orderingColumns,
  isLoading,
  isEmpty,
  disabledCancelSchedule,
  infiniteScrollProps,
  handleLineClick,
  handleCheckbox,
  handleOpenModals,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: '' },
      { value: 'Categoria' },
      { value: isAdmin && 'Solicitado Por' },
      { value: 'Operado Por' },
      { value: 'Data Coleta' },
      { value: 'Data Entrega' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Veículo' },
      { value: 'Status' },
      { value: '' },
      { value: '' },
    ];

    const columnsConfig = isAdmin
      ? '0.2fr 0.3fr 0.3fr 0.5fr 1.2fr 0.8fr 0.7fr 0.7fr 1fr 1fr 0.7fr 1fr 0.15fr 0.35fr'
      : '0.15fr 0.21fr 0.19fr 0.35fr 0fr 0.7fr 0.5fr 0.5fr 1fr 1fr 0.6fr 1fr 0.15fr 0.35fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, [isAdmin]);

  const statusCode = useCallback(
    (params: StatusCode) => {
      if (!isAdmin && params.statusCode === 'Em Bidding') return 'Aguardando Alocação';

      if (params.statusCode === 'Aguardando Alocação' && isAdmin) return 'Aguardando Transportadora';

      if (params.statusCode === 'Em Bidding' && isAdmin) return `Bidding - ${params.biddings?.length} Ofertas`;

      if (params.statusCode === 'Em Negociação' && params.companyBuyerId === user?.companyId)
        return 'Aguardando Alocação';

      if (params.statusCode === 'Prospecção') return `${params.biddings} Ofertas`;

      return params.statusCode;
    },
    [isAdmin, user?.companyId],
  );

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderStatus = useCallback(
    (params: RenderStatus) => {
      const elBackground = convertStatusCodeScheduleColor(params.statusCode.toLowerCase());

      return (
        <S.StatusWrapper>
          <TooltipComponent
            title={<span style={{ fontSize: '11px', marginBottom: '5px' }}>{statusCode({ ...params })}</span>}
            location="left"
            icon={
              <Tag label={statusCode({ ...params })} color={elBackground} variant="solid" display="flex" size="md" />
            }
          />
          {params.cteIssuer && user?.profile !== 'shipping-company' && (
            <TooltipComponent
              title={<span style={{ fontSize: '11px', marginBottom: '5px' }}>{statusCode({ ...params })}</span>}
              location="left"
              icon={<Tag label={params.cteIssuer} color={ColorsV2Type.Red} variant="ghost" display="flex" size="md" />}
            />
          )}
          {!!params.myRouterValidity && params.statusCode === 'Ag. Aceite Transportadora' && (
            <TagTimer tagDate={params.myRouterValidity} />
          )}
        </S.StatusWrapper>
      );
    },
    [statusCode, user?.profile],
  );

  const renderScheduleInfos = useCallback((scheduleInformation: IScheduleInformation) => {
    return (
      <S.ContainerTooltip>
        <TooltipComponent
          title={
            <S.ContainerTooltip>
              <S.ContainerHeaderTooltip>
                <strong>Informações Adicionais</strong>
              </S.ContainerHeaderTooltip>

              <S.ContainerInfosTooltip>
                <S.LabelTooltip>Pedidos: {scheduleInformation.orders}</S.LabelTooltip>
                <S.LabelTooltip>Nº Interno: {scheduleInformation.internalNumber}</S.LabelTooltip>
                <S.LabelTooltip>Nº OC: {scheduleInformation.ocInternalNumber}</S.LabelTooltip>
                <S.LabelTooltip>Nº Embarque: {scheduleInformation.boardingNumber}</S.LabelTooltip>
                <S.LabelTooltip>Senha: {scheduleInformation.schedulingPassword}</S.LabelTooltip>
              </S.ContainerInfosTooltip>
            </S.ContainerTooltip>
          }
          location="top"
          icon={<Info size={16} color="#6e6e6e" />}
        />
      </S.ContainerTooltip>
    );
  }, []);

  const renderDate = useCallback(
    (date: string) => {
      return renderTitleSubtitle(dayjs(date).format('DD/MM/YYYY'), dayjs(date).format('HH:mm'));
    },
    [renderTitleSubtitle],
  );

  const renderCategory = useCallback((key: string, typeSchedule: string, operationType: string) => {
    const value = (
      <Tag
        label={convertFreightType(typeSchedule).label}
        color={convertFreightType(typeSchedule).color}
        variant="solid"
        display="flex"
        size="md"
      />
    );

    const secondValue = (
      <Tag
        label={convertOperationType(operationType).label}
        color={convertOperationType(operationType).color}
        variant="ghost"
        display="flex"
        size="md"
      />
    );

    return (
      <S.Category key={`category-${key}-${typeSchedule ?? operationType}`}>
        {value}
        {secondValue}
      </S.Category>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return schedules.map((schedule) => {
      return {
        key: schedule.id,
        onClick: () => handleLineClick(schedule.id, schedule.statusCode),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-${schedule.id}`}
                id={'id'}
                name={''}
                onChange={(e) => handleCheckbox(e.target.checked, schedule.id, statusCode(schedule))}
              />
            ),
            isClicable: false,
          },
          { value: renderTitleSubtitle(schedule.id.toString(), '') },
          {
            value: (
              <TooltipComponent
                title={
                  <S.ContainerTooltip>
                    <S.ContainerHeaderTooltip>
                      <strong>Ocorrências</strong>
                    </S.ContainerHeaderTooltip>

                    <S.ContainerInfosTooltip>
                      <S.LabelTooltip>Pendentes: {schedule.pendingOccurrences.unattended}</S.LabelTooltip>
                      <S.LabelTooltip>Em Andamento: {schedule.pendingOccurrences.progress}</S.LabelTooltip>
                      <S.LabelTooltip>Finalizadas: {schedule.pendingOccurrences.completed}</S.LabelTooltip>
                    </S.ContainerInfosTooltip>
                  </S.ContainerTooltip>
                }
                location="top"
                icon={
                  <S.ContainerTooltip onClick={() => handleOpenModals(schedule.id, 'occurrences')}>
                    <OccurenceListIcon color={convertOccurrenceIconColor(schedule.pendingOccurrences)} />
                  </S.ContainerTooltip>
                }
              />
            ),
            isClicable: false,
          },
          { value: renderCategory(schedule.id.toString(), schedule.typeSchedule, schedule.operationType) },
          { value: isAdmin && renderTitleSubtitle(schedule.requestedBy, '') },
          { value: renderTitleSubtitle(schedule.operatedBy, schedule.haulType) },
          { value: renderDate(schedule.levyDate) },
          { value: renderDate(schedule.finishDate ?? schedule.deliveryDate) },
          { value: renderTitleSubtitle(schedule.origin.name, `${schedule.origin.uf} - ${schedule.origin.city}`) },
          { value: renderTitleSubtitle(schedule.destiny.name, `${schedule.destiny.uf} - ${schedule.destiny.city}`) },
          { value: renderTitleSubtitle(schedule.vehicle.vehicleType, schedule.vehicle.vehicleCategory) },
          { value: renderStatus({ ...schedule }) },
          { value: renderScheduleInfos(schedule.scheduleInformation) },
          {
            value: (
              <S.DropdownButtonTable>
                <DropdownButtonTable
                  options={[
                    {
                      label: 'Duplicar',
                      onClick: () => handleOpenModals(schedule.id, 'duplicate'),
                      icon: <Files width={18} color="gray" />,
                      disabled: isShippingCompany,
                    },
                    {
                      label: 'Cancelar',
                      onClick: () => handleOpenModals(schedule.id, 'cancel'),
                      icon: <Trash2 width={18} color="gray" />,
                      disabled: (!isAdmin && disabledCancelSchedule.includes(schedule.statusCode)) || isShippingCompany,
                    },
                  ]}
                  iconPosition="horizontal"
                />
              </S.DropdownButtonTable>
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [
    disabledCancelSchedule,
    handleCheckbox,
    handleLineClick,
    handleOpenModals,
    isAdmin,
    isShippingCompany,
    renderCategory,
    renderDate,
    renderScheduleInfos,
    renderStatus,
    renderTitleSubtitle,
    schedules,
    statusCode,
  ]);

  if (isLoading)
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    );

  if (isEmpty)
    return (
      <S.EmptyListWrapper>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.EmptyListWrapper>
    );

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={orderingColumns}
      rows={renderRows}
      height={'74.7vh'}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
