import API from 'utils/API/API';
import { IRetentionFinesDomain } from '../../domains/schedule-finance-retention-fines.domain';
import { IRetentionFinesResponse } from './get-retention-fine-by-vehicle.types';
import { converterRetentionFinesResponseToDomain } from './mappers/converter-retention-fines-response-to-domain/converter-retention-fines-response-to-domain.mapper';

export const getRetentionFinesByVehicleService = async (
  vehicleType: string,
  companyId: number,
): Promise<IRetentionFinesDomain> => {
  const { data } = await API.post<IRetentionFinesResponse>('v2/retention-fines/vehicle-type-company', {
    vehicleType,
    companyId,
  });

  return converterRetentionFinesResponseToDomain(data);
};
