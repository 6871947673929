import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { BiddingFilter } from '../services/list-biddings';
import { defaultBiddingFilter } from '../constants/list';

interface FiltersContextProps {
  filters: BiddingFilter;
  resetFilters: (hard?: boolean, toKeep?: Partial<BiddingFilter>) => void;
  updateFilters: (filter: BiddingFilter) => void;
}

interface FiltersProviderProps {
  children: ReactNode;
}

const FiltersContext = createContext<FiltersContextProps>({} as FiltersContextProps);

const FiltersProvider: FC<FiltersProviderProps> = ({ children }) => {
  const initialFilters = useMemo(() => {
    const cachedFilters = sessionStorage.getItem('pagination-biddings');

    if (cachedFilters) return JSON.parse(cachedFilters);

    return defaultBiddingFilter;
  }, []);

  const [filters, setFilters] = useState<BiddingFilter>(initialFilters);

  useEffect(() => {
    const cachedFilters = sessionStorage.getItem('pagination-biddings');

    if (cachedFilters) {
      setFilters(JSON.parse(cachedFilters));
    }
  }, []);

  const updateFilters = useCallback((filter: BiddingFilter) => {
    setFilters(filter);
    sessionStorage.setItem('pagination-biddings', JSON.stringify(filter));
  }, []);

  const resetFilters = useCallback((hard?: boolean, toKeep?: Partial<BiddingFilter>) => {
    const filter = { ...defaultBiddingFilter, ...toKeep };

    if (hard) {
      setFilters(defaultBiddingFilter);
      return;
    }

    setFilters(filter);
  }, []);

  const contextValue = useMemo(() => {
    return {
      filters,
      resetFilters,
      updateFilters,
    };
  }, [filters, resetFilters, updateFilters]);

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};

export const useFilter = () => {
  return useContext(FiltersContext);
};

export default FiltersProvider;
