import styled from 'styled-components';

export const SectionContentStyled = styled.section`
  display: grid;
  grid-gap: 5px;
  margin-bottom: 20px;
  flex-direction: column;
`;

export const ContactButtonContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const ContactButtonStyled = styled.button`
  display: flex;
  width: 140px;
  height: 40px;
  border-style: none;
  border-radius: 5px;
  border-width: 1px;
  backdrop-filter: blur(5px);
  background: rgb(0, 176, 80);
  cursor: pointer;

  font-family: Montserrat, serif;
  font-size: 12px;
  color: #fafafa;
  font-weight: 600;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(1, 163, 84);
  }
`;
