import { FC, Fragment, useCallback, useContext, useMemo } from 'react';

import dayjs from 'dayjs';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseUpdateScheduleDetails } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-details/use-update-schedule-details.hook';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import { ScheduleDetailsDatesView } from './schedule-details-dates.component-view';
import { Formik } from 'formik';
import { ScheduleDetailsDatesModalForm } from './components/schedule-details-dates-modal-form/schedule-details-dates-modal-form.component';
import { UserContext } from 'state/user-context';

export const ScheduleDetailsDates: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { user, isShippingCompany } = useContext(UserContext);
  const { operation } = scheduleDetails.scheduleDetails;
  const updateScheduleDetailsHook = UseUpdateScheduleDetails();

  const handleOpenModalDates = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationOpen('dates');
  }, [scheduleDetails]);

  const handleCloseModalComments = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails]);

  const handleSubmit = useCallback(
    (values: IUpdateScheduleCargoPayload) => {
      updateScheduleDetailsHook(values);
      handleCloseModalComments();
    },
    [handleCloseModalComments, updateScheduleDetailsHook],
  );

  const convertDates = useCallback((date: string) => {
    const converting = dayjs(date).format('DD/MM/YYYY - HH:mm');
    return converting;
  }, []);

  const renderModalComments = useMemo(() => {
    if (scheduleDetails.allocationActions.allocatedEntity === 'dates' && scheduleDetails.allocationActions.isOpen) {
      return (
        <Formik
          initialValues={{
            id: scheduleDetails.scheduleDetails.id,
            comments: scheduleDetails.scheduleDetails.cargo.comments,
            cargo: scheduleDetails.scheduleDetails.cargo,
            detail: scheduleDetails.scheduleDetails.detail,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleDetailsDatesModalForm handleCloseModalComments={handleCloseModalComments} />
        </Formik>
      );
    }

    return;
  }, [
    handleCloseModalComments,
    handleSubmit,
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.allocationActions.isOpen,
    scheduleDetails.scheduleDetails.cargo,
    scheduleDetails.scheduleDetails.detail,
    scheduleDetails.scheduleDetails.id,
  ]);

  const disableEditButton = useMemo(() => {
    if (isShippingCompany) return true;
    if (user?.profile === 'logshare') return false;
    if (operation === 'TRACKING') return false;
    return true;
  }, [isShippingCompany, operation, user?.profile]);

  return (
    <Fragment>
      {renderModalComments}
      <ScheduleDetailsDatesView
        dates={scheduleDetails.scheduleDetails.detail}
        disableEditButton={disableEditButton}
        convertDates={convertDates}
        handleOpenModalDates={handleOpenModalDates}
      />
    </Fragment>
  );
};
