import { IVehicleCreatedEntity } from '../entities/vehicle.entity';

import API from 'utils/API/API';
import { convertVehicleCreatedEntityToDomainMapper } from 'pages/vehicle/mappers/convert-vehicle-created-entity-to-domain/convert-driver-created-entity-to-domain.mapper';
import { IVehicleCreated } from 'domain/vehicle';
import { showToast } from 'components/toast/toast';

export const vehicleDetailsService = async (vehicleId: string): Promise<IVehicleCreated> => {
  try {
    const { data } = await API.get<IVehicleCreatedEntity>(`vehicle/${vehicleId}`);
    return convertVehicleCreatedEntityToDomainMapper(data);
  } catch (error) {
    showToast({
      message: 'Você não tem permissão para acessar esse veículo',
      type: 'error',
    });
    throw new Error('');
  }
};
