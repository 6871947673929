import styled, { css } from 'styled-components';

export const LoginContainerStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: right;
`;

export const LoginImage = styled.div``;

export const TextContainerStyled = styled.div`
  margin-bottom: 20px;
`;

export const HelloStyled = styled.h2`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.xlarge};
    margin-bottom: 2px;
  `}
`;

export const UserInformationStyled = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 15px;
    font-weight: ${theme.font.normal};
    text-align: left;

    strong {
      font-weight: 600;
    }
  `}
`;

export const FooterContainerStyled = styled.div`
  ${({ theme }) => css`
    margin-top: 100px;
    text-align: center;

    button {
      margin-top: 20px;
      width: 100%;
      background-color: ${theme.colors.transparent};
      border: 1px solid ${theme.colors.blue};
      color: ${theme.colors.blue};
      font-weight: ${theme.font.bold};
    }

    @media (max-width: 600px) {
      display: none;
    }
  `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  position: relative;
  top: 50px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const FooterTextStyled = styled.h6`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 14px;
    font-weight: ${theme.font.medium};
    margin-bottom: 20px;
  `}
`;

export const LoadingStyled = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media (max-width: 600px) {
    bottom: 10px;
    right: 10px;
  }
`;

export const ErrorMessageStyled = styled.span`
  text-align: center;
  color: red;
  margin-top: 20px;
`;

export const ContainerLogoPortStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    top: 30px;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    font-size: 11px;
    color: ${theme.colors.black};

    svg {
      position: relative;
      left: 4%;
      top: -6px;
    }

    @media (max-width: 600px) {
      display: none;
    }
  `}
`;

export const ButtonStyled = styled.a`
  ${({ theme }) => css`
    height: 40px;
    background-color: ${theme.colors.white};
    padding: 10px 140px;
    border: 1px solid #d9d9d9;
    font-family: ${theme.font.family};
    font-size: 13px;
    font-weight: ${theme.font.bold};
    font-style: none;
    text-decoration: none;
    color: ${theme.colors.black};
    cursor: pointer;
    border-radius: 25px;

    :hover {
      opacity: 0.88;
    }

    :active {
      box-shadow: ${theme.border.shadow};
    }

    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}
`;
