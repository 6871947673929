import { IEquipmentVoucherListFiltersDomain } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/domains/equipment-voucher-list-filters.domain';

export const equipmentListFilterInitialValues: IEquipmentVoucherListFiltersDomain = {
  search: '',
  page: 1,
  limitPage: 100,
  orderBy: 'DESC',
  column: '',
  status: 'Em aberto',
};
