import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10vw;
  padding-bottom: 2vh;
`;

export const Text = styled.div`
  ${() => css`
    text-align: center;
    font-size: 1.8rem;
  `}
`;

export const Icon = styled.img`
  ${() => css`
    width: 150px;
  `}
`;
