import styled from 'styled-components';

export const Info = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const ContainerButton = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  button {
    min-width: 80px !important;
  }
`;

export const CompanyImageContainer = styled.div`
  width: 97px;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;
