import React from 'react';

import OccurrenceRegisterForm from '../occurrence-register-form/occurrence-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const OccurrenceRegisterBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="occurrence-register" breadcrumbList={['Cadastro', 'Tipo de Ocorrência']} hasBackButton />
      <Title title="Nova Ocorrência" hasTrace isForm />
      <OccurrenceRegisterForm />
    </React.Fragment>
  );
};

export default OccurrenceRegisterBodyView;
