import React from 'react';

import OccurrenceUpdateForm from '../occurrence-register-form/occurrence-update-form';

import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const OccurrenceUpdateBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="occurrence-register" breadcrumbList={['Cadastro', 'Tipo de Ocorrência']} hasBackButton />
      <OccurrenceUpdateForm />
    </React.Fragment>
  );
};

export default OccurrenceUpdateBodyView;
