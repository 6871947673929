import styled, { css } from 'styled-components';

export const LabelStyled = styled.label<{ disabled?: boolean }>`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.font.sizes.small};
  `}

  ${({ disabled }) =>
    disabled === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const InputStyled = styled.input`
  ${({ theme }) => css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked + i {
      background: #00b050;
      flex-direction: row-reverse;
    }

    &:checked + i:before {
      -webkit-box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 3px 0 -3px 0 rgba(0, 0, 0, 0.2);
    }

    &:checked + i:after {
      opacity: 1;
    }

    &:checked + i {
      &:before {
        background: ${theme.colors.white};
      }
    }
  `}
`;

export const IconStyled = styled.i`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 41px;
  padding: 3px 0;
  margin-right: 5px;
  border-radius: 15px;
  background: #e6e6e6;

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background: gray;
    border-radius: 100%;
    -webkit-box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
  }

  &:after {
    content: '';
  }

  transition: all 0.2s ease-in-out;
`;
