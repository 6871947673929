import API from 'utils/API/API';
import { IVehicleStatus } from 'domain/vehicle';

export interface IVehicleReportExcelProps {
  search: string;
  status: IVehicleStatus | undefined;
}

export const vehicleReportExcelService = async ({
  search,
  status,
}: IVehicleReportExcelProps): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('v2/vehicles/export', {
      params: {
        status,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao gerar xls de veiculos');
  }
};
