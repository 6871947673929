import React, { Fragment } from 'react';
import { UnreadContainerStyled, UnreadStyled } from './tag-unread-messages-view.styled';

interface ITagUnreadMessagesViewProps {
  unreadMessageCount: number;
}

export const TagUnreadMessagesView: React.FC<ITagUnreadMessagesViewProps> = ({ unreadMessageCount }) => {
  if (unreadMessageCount > 0) {
    return (
      <UnreadContainerStyled>
        <UnreadStyled>{unreadMessageCount}</UnreadStyled>
      </UnreadContainerStyled>
    );
  }

  return <Fragment />;
};
