import { IUser } from 'domain/login';
import { ColorsV2Type } from 'styles/types/colors';

export const convertStatusCodeSchedule = (statusCode: string, user?: IUser): string => {
  const isAdmin = user?.profile === 'logshare';

  const statusMap: { [key: string]: string } = {
    AG10: 'Aguardando Alocação',
    AL10: 'Aguardando Motorista',
    AL20: 'Aguardando Veículo',
    IN10: 'Aguardando Aceite',
    IN11: 'Aguardando Início de Rota',
    IN20: 'Em Rota para Carregamento',
    IN30: 'Aguardando Carregamento',
    IN40: 'Em Carregamento',
    IN50: 'Coleta Realizada',
    IN60: 'Em Rota para Descarga',
    IN61: 'Início da viagem para descarga',
    IN62: 'Chegada para descarga',
    IN70: 'Em Descarga',
    IN80: 'Descarregando',
    IN90: 'Descarga Finalizada',
    AL90: 'Alocação Cancelada com Custo',
    AL99: 'Alocação Cancelada',
    OT80: 'Expirado',

    //BACKHAUL
    OT10: 'Em Negociação',
    OT99: 'Oferta Negada',

    //TRANSPORTADORA
    OT01: 'Ag. Aceite Transportadora',
    SH01: isAdmin ? 'Em Bidding' : 'Aguardando Alocação',

    //FORA DO FLUXO NORMAL DE AGENDAMENTO
    SH02: 'Prospecção',
    LG90: 'Descarga Finalizada',
  };

  return statusMap[statusCode] || '---';
};

export const convertStatusCodeScheduleColor = (statusCode: string): ColorsV2Type => {
  const statusColors: { [key: string]: ColorsV2Type } = {
    'oferta negada': ColorsV2Type.Red,
    'alocação cancelada': ColorsV2Type.Red,
    'alocação cancelada com custo': ColorsV2Type.Red,
    'em negociação': ColorsV2Type.Blue,
    'em bidding': ColorsV2Type.Blue,
    'aguardando alocação': ColorsV2Type.Blue25,
    'aguardando transportadora': ColorsV2Type.Blue25,
    'ag. aceite transportadora': ColorsV2Type.Blue50,
    'aguardando motorista': ColorsV2Type.Blue75,
    'aguardando início de rota': ColorsV2Type.Blue100,
    'em rota para carregamento': ColorsV2Type.Aqua25,
    'em carregamento': ColorsV2Type.Aqua50,
    'em rota para descarga': ColorsV2Type.Aqua75,
    'em descarga': ColorsV2Type.Aqua100,
    'descarga finalizada': ColorsV2Type.Aqua,
    expirado: ColorsV2Type.GrayDark,
  };

  return statusColors[statusCode] || ColorsV2Type.Blue;
};
