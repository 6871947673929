import { useCallback } from 'react';
import { IWeighingTottenCheckin } from '../../types/weighing-totten-checkin/weighing-totten-details-form.types';
import { putWeighingTottenCheckinService } from '../../services/put-weighing-totten-checkin/put-weighing-totten-checkin.service';

export const usePutWeighingTottenCheckin = () => {
  return useCallback(async (values: IWeighingTottenCheckin) => {
    try {
      await putWeighingTottenCheckinService({ ...values });
    } catch (error) {
      console.error(error);
    }
  }, []);
};
