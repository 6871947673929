import API from 'utils/API/API';
import { convertScheduleQuotation } from 'pages-v2/schedule/mappers/convert-quotation';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { ITagsQuotation } from 'domain-v2/schedule/quotation';

export const quotationScheduleService = async (
  payload: ScheduleRegisterInput,
  tags: ITagsQuotation[],
  scheduleId?: number,
) => {
  if (scheduleId) {
    const { data } = await API.post(`/v3/schedule/${scheduleId}/collection-report`, {}, { responseType: 'blob' });
    return data;
  }

  const { data } = await API.post('/v3/schedule/1/quottion', convertScheduleQuotation(payload, tags), {
    responseType: 'blob',
  });

  return data;
};
