import styled, { css } from 'styled-components';
export const ContainerStyled = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 20px;
  background-color: #05050581;
  z-index: 99;
`;

export const ContentStyled = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  line-height: 30px;
  width: 493px;
  height: 237px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.bold};
  `}
`;

export const DescriptionStyled = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.medium};
  `}
`;

export const WrapperTextsStyled = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftButton = styled.div`
  margin-right: auto;
`;

export const RightButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 10px;
`;
