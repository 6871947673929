import styled, { css } from 'styled-components';

interface IContainerCards {
  sidebarSize: number;
  isSubMenuOpen: boolean;
}
export const ContainerCards = styled.div<IContainerCards>`
  ${({ sidebarSize, isSubMenuOpen }) => css`
    width: calc(100vw - ${sidebarSize === 0 ? '105' : sidebarSize}px);
    display: flex;
    flex-direction: row;
    overflow: hidden;

    ${!isSubMenuOpen &&
    css`
      transition: all 0.2s ease-in-out 0s;
    `}
  `};
`;
