import { FC, useMemo } from 'react';
import { SaleOfferModalMatchScoreLabelView } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-modal-match/components/sale-offer-modal-match-score/components/sale-offer-modal-match-score-label/sale-offer-modal-match-score-label-view.component';

interface ISaleOfferModalMatchScoreProps {
  sumScore: number;
}

export const SaleOfferModalMatchScoreLabel: FC<ISaleOfferModalMatchScoreProps> = ({ sumScore }) => {
  const scoreMatchLabel = useMemo(() => {
    if (sumScore === 3) return 'ALTO';

    if (sumScore < 3 && sumScore > 1) return 'MODERADO';

    return 'BAIXO';
  }, [sumScore]);

  return <SaleOfferModalMatchScoreLabelView label={scoreMatchLabel} />;
};
