import React, { Fragment, ReactNode, useMemo } from 'react';
import {
  IconStyled,
  DescriptionStyled,
  ModalBodyContainerStyled,
  ModalFooterContainerStyled,
  ModalHeaderContainerStyled,
  TitleStyled,
} from './confirmation-modal-view.styled';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { Button } from 'logshare-ui-kit';
import {
  IModalPrimaryButtonProps,
  IModalDescriptionProps,
  IModalButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';

interface IConfirmationModalViewProps {
  title: string;
  description: IModalDescriptionProps;
  icon: ReactNode;
  primaryButtonProps: IModalPrimaryButtonProps;
  secondaryButtonProps: IModalButtonProps;
}

export const ConfirmationModalView: React.FC<IConfirmationModalViewProps> = ({
  title,
  description,
  icon,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const renderDescription = useMemo(() => {
    const secondaryRow = description.secondaryRow ? (
      <DescriptionStyled>{description.secondaryRow}</DescriptionStyled>
    ) : (
      <Fragment />
    );

    return (
      <Fragment>
        <DescriptionStyled>{description.primaryRow}</DescriptionStyled>
        {secondaryRow}
      </Fragment>
    );
  }, [description.primaryRow, description.secondaryRow]);

  return (
    <ModalBark size={'xxsm'}>
      <ModalHeaderContainerStyled>
        <IconStyled>{icon}</IconStyled>
        <TitleStyled>{title}</TitleStyled>
      </ModalHeaderContainerStyled>
      <ModalBodyContainerStyled>{renderDescription}</ModalBodyContainerStyled>
      <ModalFooterContainerStyled>
        <Button
          label={secondaryButtonProps.label}
          size="lg"
          color="white"
          onPress={secondaryButtonProps.handleActionButton}
          variant="ghost"
          type="button"
        />
        <Button
          label={primaryButtonProps.label}
          size="lg"
          color={primaryButtonProps.color}
          onPress={primaryButtonProps.handleActionButton}
          variant="solid"
          type="button"
          isLoading={primaryButtonProps.isLoading}
        />
      </ModalFooterContainerStyled>
    </ModalBark>
  );
};
