type ConversionMapping = Record<string, Record<string, string>>;

const fieldMappings: ConversionMapping = {
  operationBy: {
    '1': 'LOGSHARE',
    '2': 'EMBARCADOR',
  },
  vehicleType: {
    '3/4': '3/4',
    bitrem: 'BITREM',
    bitruck: 'BITRUCK',
    carreta: 'CARRETA',
    carreta_ls: 'CARRETA LS',
    complemento: 'COMPLEMENTO',
    rodotrem: 'RODOTREM',
    'romeu&julieta': 'ROMEU & JULIETA',
    romeu_julieta: 'ROMEU & JULIETA',
    toco: 'TOCO',
    truck: 'TRUCK',
    utilitario: 'UTILITÁRIO',
    van: 'VAN',
    vuc: 'VUC',
    vanderleia: 'VANDERLÉIA',
  },
  vehicleCategory: {
    bau_seco: 'BAÚ SECO',
    bau_frigorificado: 'BAÚ FRIGORIFICADO',
    bau_refrigerado: 'BAÚ REFRIGERADO',
    bau_sider: 'BAÚ SIDER',
    cacamba_basculante: 'BASCULANTE',
    cegonha: 'CEGONHA',
    florestal: 'FLORESTAL',
    grade_baixa: 'GRADE BAIXA',
    graneleiro: 'GRANELEIRO',
    plataforma: 'PLATAFORMA',
    porta_container_20: 'PORTA CONTAINER 20',
    porta_container_40: 'PORTA CONTAINER 40',
    tanque: 'TANQUE',
  },
  scheduleOperationType: {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    TRANSFERENCIA: 'TRANSFERÊNCIA',
  },
  statusCode: {
    SH01: 'EM BIDDING',
    OT10: 'EM NEGOCIAÇÃO',
    AG10: 'AGUARDANDO ALOCAÇÃO',
    OT01: 'AG. ACEITE TRANSPORTADORA',
    AL99: 'ALOCAÇÃO CANCELADA',
    OT99: 'OFERTA NEGADA',
    AL90: 'ALOCAÇÃO CANCELADA COM CUSTO',
  },
};

const deleteFiltersMappings: ConversionMapping = {
  operationBy: {
    LOGSHARE: '1',
    EMBARCADOR: '2',
  },
  vehicleType: {
    '3/4': '3/4',
    BITREM: 'bitrem',
    BITRUCK: 'bitruck',
    CARRETA: 'carreta',
    'CARRETA LS': 'carreta_ls',
    COMPLEMENTO: 'complemento',
    RODOTREM: 'rodotrem',
    'ROMEU & JULIETA': 'romeu&julieta',
    TOCO: 'toco',
    TRUCK: 'truck',
    UTILITÁRIO: 'utilitario',
    VAN: 'van',
    VUC: 'vuc',
    VANDERLÉIA: 'vanderleia',
  },
  vehicleCategory: {
    'BAÚ SECO': 'bau_seco',
    'BAÚ FRIGORIFICADO': 'bau_frigorificado',
    'BAÚ REFRIGERADO': 'bau_refrigerado',
    'BAÚ SIDER': 'bau_sider',
    BASCULANTE: 'cacamba_basculante',
    CEGONHA: 'cegonha',
    FLORESTAL: 'florestal',
    'GRADE BAIXA': 'grade_baixa',
    GRANELEIRO: 'graneleiro',
    PLATAFORMA: 'plataforma',
    'PORTA CONTAINER 20': 'porta_container_20',
    'PORTA CONTAINER 40': 'porta_container_40',
    TANQUE: 'tanque',
  },
  scheduleOperationType: {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    TRANSFERÊNCIA: 'TRANSFERENCIA',
  },
  statusCode: {
    'EM BIDDING': 'SH01',
    'EM NEGOCIAÇÃO': 'OT10',
    'AGUARDANDO ALOCAÇÃO': 'AG10',
    'AG. ACEITE TRANSPORTADORA': 'OT01',
    'ALOCAÇÃO CANCELADA': 'AL99',
    'OFERTA NEGADA': 'OT99',
    'ALOCAÇÃO CANCELADA COM CUSTO': 'AL90',
  },
};

export const convertField = (field: string, value: string | number, deleteFilters?: boolean): string | number => {
  if (deleteFilters) {
    const deleteFiltersMapping = deleteFiltersMappings[field];
    return deleteFiltersMapping && deleteFiltersMapping[value] ? deleteFiltersMapping[value] : value;
  }

  const fieldMapping = fieldMappings[field];

  return fieldMapping?.[value] ?? value;
};
