import { useCallback, useContext } from 'react';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useQueryClient } from 'react-query';
import { removeSaleOfferTariffService } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/services/remove-sale-offer-tariff/remove-sale-offer-tariff.service';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const useSaleOfferTariffListRemove = () => {
  const reactQueryClient = useQueryClient();
  const { saleOfferList } = useContext(SaleOfferContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { selectedIds, setSelectIds, setSaleOfferListReset } = saleOfferList;
  const { setInitialLoadingReset } = initialLoading;

  return useCallback(
    async (handleCloseDeleteModal: () => void, handleIsLoading: (isLoading: boolean) => void) => {
      handleIsLoading(true);

      try {
        const removalPromises = selectedIds.map((id) => removeSaleOfferTariffService(id));

        await Promise.all(removalPromises);
        reactQueryClient.invalidateQueries(['sale-offer-list-table']);

        setSelectIds([]);
        handleIsLoading(false);
        handleCloseDeleteModal();
        setInitialLoadingReset();
        setSaleOfferListReset();
        showMessageFormatted({
          message: 'Oferta(s) excluidas com sucesso.',
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, selectedIds, setInitialLoadingReset, setSaleOfferListReset, setSelectIds],
  );
};
