import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { MyAccontRegisterSchema } from './schemas-validation/schemas-validation';
import { initialValues } from './form-values/form-values';
import FormMyAccountView from './form-my-account-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useMyAccountRegisterHook } from 'pages/my-account/hooks/use-my-account-register/use-my-account-register.hook';
import { useMyAccountDetailsHook } from 'pages/my-account/hooks/use-my-account-details/use-my-account-details.hook';
import { IMyAccount } from 'domain/my-account';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const FormMyAccount: React.FC = () => {
  const PATH_TO_HOME = '/home';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [myAccount, setMyAccount] = useState<IMyAccount>(initialValues);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const myAccountDetailsHook = useMyAccountDetailsHook();
  const myAccountRegisterHook = useMyAccountRegisterHook();

  const goBackToHome = useCallback(() => navigate(PATH_TO_HOME), [navigate]);

  const handleSubmit = useCallback(
    async (account: IMyAccount) => {
      if (user?.id) {
        setIsLoading(true);
        myAccountRegisterHook(user?.id, account)
          .then(() => {
            showMessageFormatted({
              message: TOAST_MESSAGE.UPDATE,
              type: 'success',
            });
            goBackToHome();
          })
          .catch((error) =>
            showMessageFormatted({
              error: error,
              type: 'error',
            }),
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [goBackToHome, myAccountRegisterHook, user?.id],
  );

  useEffect(() => {
    if (user?.id) {
      myAccountDetailsHook(user?.id).then((myAccount) => setMyAccount(myAccount));
    }
  }, [myAccountDetailsHook, user?.id]);

  return (
    <FormMyAccountView
      myAccountValues={myAccount}
      handleGoBack={goBackToHome}
      handleRegister={handleSubmit}
      schemaValidation={MyAccontRegisterSchema}
      isLoading={isLoading}
    />
  );
};

export default FormMyAccount;
