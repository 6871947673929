import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ILocationListTabsStatus, LocationListTabsView } from './location-list-tabs-view.component';
import { LocationsContext } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.context';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { locationsInitialState } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.constants';
import { isEqual } from 'lodash';
import { ILocationsListFilter } from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations-list.types';
import { useGetLocationList } from '../../hooks/use-get-location-list/use-get-location-list.hook';

interface ILocationListTabsProps {
  activeTab: string;
  setActiveTab: (name: string) => void;
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const LocationListTabs: React.FC<ILocationListTabsProps> = ({
  activeTab,
  setActiveTab,
  handleIdsToRemove,
  handleInfinityScroll,
}) => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { locationsList } = useContext(LocationsContext);
  const [currentFilter, setCurrentFilter] = useState<ILocationsListFilter>(
    locationsInitialState.locationsList.filterActions.filters,
  );
  const getLocationList = useGetLocationList();
  const { filterActions, statusRequest, locations } = locationsList;
  const { filters, setFilters } = filterActions;
  const { loading, setInitialLoadingReset } = initialLoading;

  const handleResetRequest = useCallback(() => {
    setInitialLoadingReset();
    handleInfinityScroll(1);
  }, [handleInfinityScroll, setInitialLoadingReset]);

  const handleChangeTab = useCallback(
    (name: string) => {
      handleResetRequest();
      setActiveTab(name);
      setFilters({
        ...locationsInitialState.locationsList.filterActions.filters,
        searchValue: filters.searchValue,
        status: name === 'Ativas' ? 'active' : 'inactive',
      });
    },
    [filters.searchValue, handleResetRequest, setActiveTab, setFilters],
  );

  const statusProps = useMemo(
    (): ILocationListTabsStatus => ({
      isError: statusRequest === 'ERROR',
      isInitialLoading: loading,
      isEmpty: !locations.length,
    }),
    [loading, locations.length, statusRequest],
  );

  useEffect(() => {
    if (!isEqual(filters, currentFilter) && statusRequest !== 'ERROR') {
      setCurrentFilter(filters);
      getLocationList();
    }
  }, [currentFilter, filters, getLocationList, handleResetRequest, statusRequest]);

  return (
    <LocationListTabsView
      handleIdsToRemove={handleIdsToRemove}
      handleInfinityScroll={handleInfinityScroll}
      activeTab={activeTab}
      handleChangeTab={handleChangeTab}
      statusProps={statusProps}
    />
  );
};
