import React from 'react';

import { WrapperStyled } from './kanban-title.styled';

import { KanbanTitleProps } from 'pages/task-vision/types/types';

const KanbanTitleView: React.FC<KanbanTitleProps> = ({ title, color }) => (
  <WrapperStyled color={color}>{title}</WrapperStyled>
);

export default KanbanTitleView;
