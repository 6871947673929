import React from 'react';

import { ResetPasswordContainerStyled, TextResetPasswordStyled } from './reset-password.styled';

export type ResetLoginPasswordProps = {
  callback: () => void;
};

const ResetPasswordView: React.FC<ResetLoginPasswordProps> = ({ callback }) => (
  <ResetPasswordContainerStyled onClick={callback}>
    <TextResetPasswordStyled>Esqueci minha senha</TextResetPasswordStyled>
  </ResetPasswordContainerStyled>
);

export default ResetPasswordView;
