import { TabNames } from '../schedule-tab-names/schedule-tab-names.types';
import { IScheduleListParams } from './schedule-list-filters.types';

export const scheduleListFiltersInitialValues: IScheduleListParams = {
  page: 1,
  status: ['SH01', 'AG10', 'OT01', 'OT10', 'AL10', 'AL20'],
  statusCode: ['SH01', 'OT10', 'AG10', 'OT01', 'AL10', 'AL20'],
  order: 'DESC',
  column: 'ID',
  activeTabName: TabNames.Em_Alocacao,
  search: '',
  limitRegistry: 200,
};
