import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { ScheduleListActionType } from '../../schedule.action';
import { scheduleListReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-list/schedule-list.reducer';
import {
  IScheduleCheckbox,
  IScheduleList,
  ScheduleListModals,
  ScheduleListViewMode,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { IScheduleListDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-list/schedule-list.domain';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { IScheduleListTotalRow } from 'src-new/pages/execution/pages/schedule/types/schedule-list-total-row/schedule-list-total-row.types';

export const useScheduleListContextValues = (): IScheduleList => {
  const [state, dispatch] = useReducer(scheduleListReducer, scheduleInitialState);

  const setScheduleListRequest = useCallback(() => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_REQUEST, payload: undefined });
  }, []);

  const setScheduleListSuccess = useCallback((list: Array<IScheduleListDomain>) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_SUCCESS, payload: list });
  }, []);

  const setScheduleListError = useCallback(() => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_ERROR, payload: undefined });
  }, []);

  const setListViewMode = useCallback((viewMode: ScheduleListViewMode) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_VIEW_MODE, payload: viewMode });
  }, []);

  const setModalOpen = useCallback((modalOptions: ScheduleListModals) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_MODALS, payload: modalOptions });
  }, []);

  const setFilters = useCallback((filters: IScheduleListParams) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_FILTERS, payload: filters });
  }, []);

  const setSavedFilters = useCallback((filters: Array<IScheduleListParams>) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_SAVED_FILTERS, payload: filters });
  }, []);

  const setScheduleListCheckbox = useCallback((checkbox: Array<IScheduleCheckbox>) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_CHECKBOX, payload: checkbox });
  }, []);

  const setScheduleListTotalRow = useCallback((totalRow: IScheduleListTotalRow) => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_TOTAL_ROW, payload: totalRow });
  }, []);

  const setScheduleListReset = useCallback(() => {
    dispatch({ type: ScheduleListActionType.SCHEDULE_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleList,
    setScheduleListRequest,
    setScheduleListSuccess,
    setScheduleListError,
    setScheduleListReset,
    viewModeProps: {
      ...state.scheduleList.viewModeProps,
      setListViewMode,
    },
    modalsProps: {
      ...state.scheduleList.modalsProps,
      setModalOpen,
    },
    scheduleListFiltersProps: {
      ...state.scheduleList.scheduleListFiltersProps,
      setFilters,
    },
    scheduleSavedFiltersProps: {
      ...state.scheduleList.scheduleSavedFiltersProps,
      setSavedFilters,
    },
    scheduleListCheckbox: {
      ...state.scheduleList.scheduleListCheckbox,
      setScheduleListCheckbox,
    },
    scheduleListTotalRowProps: {
      ...state.scheduleList.scheduleListTotalRowProps,
      setScheduleListTotalRow,
    },
  };
};
