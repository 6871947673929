import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { InactiveMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListDomain } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/domains/matches-kanban-list/matches-kanban-list.domain';
import { IInactiveMatchesKanbanList } from '../../types/inactive-matches-kanban.types';
import { inactiveMatchesKanbanListReducer } from '../../reducers/inactive-matches-kanban-list copy/inactive-matches-kanban-list.reducer';

export const useInactiveMatchesKanbanListContextValues = (): IInactiveMatchesKanbanList => {
  const [state, dispatch] = useReducer(inactiveMatchesKanbanListReducer, matchesInitialState);

  const setInactiveMatchesKanbanListRequest = useCallback(() => {
    dispatch({ type: InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_REQUEST, payload: undefined });
  }, []);

  const setInactiveMatchesKanbanListSuccess = useCallback((kanban: IMatchesKanbanListDomain) => {
    dispatch({ type: InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_SUCCESS, payload: kanban });
  }, []);

  const setInactiveMatchesKanbanListError = useCallback(() => {
    dispatch({ type: InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_ERROR, payload: undefined });
  }, []);

  const setInactiveMatchesKanbanListReset = useCallback(() => {
    dispatch({ type: InactiveMatchesKanbanListActionType.INACTIVE_MATCHES_KANBAN_RESET, payload: undefined });
  }, []);

  return {
    ...state.inactiveMatchesKanbanList,
    setInactiveMatchesKanbanListRequest,
    setInactiveMatchesKanbanListSuccess,
    setInactiveMatchesKanbanListError,
    setInactiveMatchesKanbanListReset,
  };
};
