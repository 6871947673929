import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useEffect } from 'react';
import { withFormik } from 'formik';

import { RegisterPalletValidation } from './validation/schemas-validation';
import { InnerForm } from './inner-form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updatePalletService } from 'services/pallet/update-pallet';
import { registerPalletService } from 'services/pallet/register-pallet';
import { getPalletByIdService } from 'services/pallet/get-pallet-by-id';
import { IPallet, palletInitialValues } from 'domain-v2/pallet/register';
import { checkStubsInitialValues } from 'domain-v2/check-stub';
import { Loading } from 'src-new/components/loading/loading.component';

export const FormPallet: React.FC = () => {
  const { id } = useParams();
  const reactQueryClient = useQueryClient();

  const navigate = useNavigate();

  const location = useLocation();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['palletRegister', id],
    queryFn: () => (id ? getPalletByIdService(Number(id)) : undefined),
    enabled: false,
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  const handleSubmit = useCallback(
    async (values: IPallet) => {
      try {
        const { checkStub, ...pallet } = values;

        let palletValues: IPallet = pallet;

        if (checkStub?.checkStubs !== checkStubsInitialValues) {
          palletValues = {
            ...palletValues,
            checkStub: checkStub,
          };
        }

        id ? await updatePalletService(Number(id), palletValues) : await registerPalletService(palletValues);

        await reactQueryClient.invalidateQueries('palletList');
        await reactQueryClient.invalidateQueries('checkStubList');

        navigate(-1);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [id, reactQueryClient, navigate],
  );

  const FormRegisterPalletView = withFormik<IPallet, IPallet>({
    validationSchema: RegisterPalletValidation,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleSubmit(values);
    },
  })(InnerForm);

  if (isLoading) {
    return <Loading />;
  }

  return <FormRegisterPalletView {...(id ? data ?? palletInitialValues : location.state ?? palletInitialValues)} />;
};
