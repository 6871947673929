import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';

export const convertTrackingOccurrenceCreatedDomainToEntityMapper = (
  trackingOccurrenceDomain: ITrackingOccurrenceCreated,
): FormData => {
  const occurrencesResolutions = () => {
    trackingOccurrenceDomain.occurrenceResolutions?.forEach((item, index) => {
      formData.append(
        `occurrenceResolutions[${index}]`,
        JSON.stringify({
          ...item,
          resolutionDate: new Date(item.resolutionDate).toISOString(),
        }),
      );
    });
  };

  const imagesForm = () => {
    if (trackingOccurrenceDomain.occurrenceNewFiles && trackingOccurrenceDomain.occurrenceNewFiles.length > 0) {
      trackingOccurrenceDomain.occurrenceNewFiles?.forEach((item) => {
        if (item) formData.append('occurrenceNewFiles', item);
      });
    }
  };

  const formData = new FormData();

  occurrencesResolutions();
  imagesForm();

  formData.append('id', trackingOccurrenceDomain.id.toString());
  formData.append('occurrenceDate', trackingOccurrenceDomain.occurrenceDate);
  formData.append('occurrenceTime', trackingOccurrenceDomain.occurrenceTime);
  formData.append('occurrenceTypeId', trackingOccurrenceDomain.occurrenceTypeId.toString());
  formData.append('occurrenceTypeName', trackingOccurrenceDomain.occurrenceTypeName);

  formData.append('processType', trackingOccurrenceDomain.processType);
  formData.append('step', trackingOccurrenceDomain.step);
  formData.append('osNumber', trackingOccurrenceDomain.osNumber);
  formData.append('ticketNumber', trackingOccurrenceDomain?.ticketNumber ?? '');
  formData.append('cnpj', trackingOccurrenceDomain.cnpj);
  formData.append('endDate', trackingOccurrenceDomain.endDate);
  formData.append('endTime', trackingOccurrenceDomain.endTime || '');
  formData.append('resolutionTime', trackingOccurrenceDomain.resolutionTime || '');
  formData.append('scheduleId', trackingOccurrenceDomain.osNumber);
  formData.append('companyId', trackingOccurrenceDomain.companyId.toString());

  if (trackingOccurrenceDomain.clientId && trackingOccurrenceDomain.clientId !== 0)
    formData.append('clientId', String(trackingOccurrenceDomain.clientId));

  if (trackingOccurrenceDomain.localityId && trackingOccurrenceDomain.localityId !== 0)
    formData.append('localityId', String(trackingOccurrenceDomain.localityId));

  return formData;
};
