import API from 'utils/API/API';
import { IListPage } from 'src-new/domains/list-page.domain';
import { LocationStatus } from 'src-new/pages/registrations/domains/location.domain';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { IClientResponse } from './get-clients-list.types';
import { convertClientResponseToDomain } from './mappers/convert-client-response-to-domain/convert-client-response-to-domain.mapper';

const LIMIT_PAGE = 50;

export interface IGetClientsListProps {
  search: string;
  page: number;
  status?: LocationStatus;
  order: string;
  column: string;
}

export const getClientsListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IGetClientsListProps): Promise<IListPage<IClientDomain>> => {
  let url = `v2/clients?&search=${search}&status=${status}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

  if (search === '') {
    url = url.replace('search=&', '');
  }
  if (!status) {
    url = url.replace('status=undefined&', '');
  }

  const { data } = await API.get<IListPage<IClientResponse>>(url);

  return {
    items: data.items.map(convertClientResponseToDomain),
    meta: data.meta,
  };
};
