import { useCallback, useReducer } from 'react';
import { saleOfferModalsReducer } from '../../reducers/sale-offer-modals/sale-offer-modals.reducer';
import { SaleOfferModalsActionType } from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import {
  IPayloadModalIndex,
  ISaleOfferModals,
  ISaleOfferModalsType,
  ISaleOfferSecondModalsType,
} from '../../types/sale-offer-modals.types';

export const useSaleOfferModalContextValues = (): ISaleOfferModals => {
  const [state, dispatch] = useReducer(saleOfferModalsReducer, saleOfferInitialState);

  const setModalOpen = useCallback(
    (payload: {
      indexes: IPayloadModalIndex;
      modalType: ISaleOfferModalsType;
      secondModalType?: ISaleOfferSecondModalsType;
    }) => {
      dispatch({ type: SaleOfferModalsActionType.SALE_OFFER_MODAL_OPEN, payload });
    },
    [],
  );

  const setModalClose = useCallback(() => {
    dispatch({ type: SaleOfferModalsActionType.SALE_OFFER_MODAL_CLOSE, payload: undefined });
  }, []);

  const setModalSecondaryClose = useCallback(() => {
    dispatch({ type: SaleOfferModalsActionType.SALE_OFFER_MODAL_SECONDARY_CLOSE, payload: undefined });
  }, []);

  return {
    ...state.saleOfferModals,
    setModalOpen,
    setModalClose,
    setModalSecondaryClose,
  };
};
