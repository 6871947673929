import axios from 'axios';

import {
  convertGoogleCepAddressEntityToDomainMapper,
  convertViaCEPAddressEntityToDomainMapper,
} from 'services/cep/mapper/convert-viacep-address-to-domain';
import { IAddress } from 'domain/address';

export const getAddressInfoByCep = async (cep: string): Promise<IAddress> => {
  try {
    const cepNumber = cep.replace(/\D/g, '');
    const { data } = await axios.get(`https://viacep.com.br/ws/${cepNumber}/json/`);
    if (data && data.erro !== true) {
      return convertViaCEPAddressEntityToDomainMapper(data);
    } else {
      console.error('Erro ao obter endereço pelo ViaCEP:');
      return getAddressInfoByCepGoogle(cep);
    }
  } catch (error) {
    console.error('Erro ao obter endereço pelo ViaCEP:', error);
    return getAddressInfoByCepGoogle(cep);
  }
};

const getAddressInfoByCepGoogle = async (cep: string): Promise<IAddress> => {
  const apiKey = 'AIzaSyC1JrN0KI_bcARKOEg-IGiQoaGvPZbL8tc';
  const zipCode = cep.replace(/\D/g, '');

  try {
    const { data } = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        components: `postal_code:${zipCode}`,
        key: apiKey,
      },
    });
    const result = data.results[0];
    if (result) {
      return convertGoogleCepAddressEntityToDomainMapper(result.address_components);
    }
  } catch (error) {
    console.error('Erro ao obter endereço pelo Google:', error);
  }

  // Caso não encontre nada, retorne um valor padrão ou trate de outra forma
  return {
    cep: '',
    city: '',
    uf: '',
    neighborhood: '',
    street: '',
    numberHouse: '',
    complement: '',
  };
};
