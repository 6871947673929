import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { showToast } from 'components/toast/toast';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { LocationsContext } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.context';
import { deleteRecordsService } from 'src-new/pages/registrations/services/delete-records/delete-records.service';

interface IDeleteLocationsProps {
  ids: Array<number>;
  setIdsToRemove: Dispatch<SetStateAction<number[]>>;
  setIsLoadingExclude: Dispatch<SetStateAction<boolean>>;
  setOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
}

export const useDeleteLocations = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { locationsList } = useContext(LocationsContext);
  const { setLocationsListReset } = locationsList;
  const { filters, setFilters } = locationsList.filterActions;
  const { setInitialLoadingReset } = initialLoading;

  return useCallback(
    async ({ ids, setIdsToRemove, setIsLoadingExclude, setOpenConfirmModal }: IDeleteLocationsProps): Promise<void> => {
      setIsLoadingExclude(true);
      const hasList: boolean = ids.length > 1;

      try {
        await deleteRecordsService(ids, 'LOCATION');

        setFilters({
          ...filters,
          page: 1,
        });
        setIdsToRemove([]);
        setIsLoadingExclude(false);
        setOpenConfirmModal(false);
        setInitialLoadingReset();
        setLocationsListReset();
        showToast({
          message: !hasList ? 'A unidade foi excluída com sucesso.' : 'As unidades foram excluídas com sucesso.',
          type: 'success',
        });
      } catch (error) {
        showToast({
          message: !hasList
            ? 'Erro ao tentar excluir a unidade selecionada.'
            : 'Erro ao tentar excluir as unidades selecionadas.',
          type: 'error',
        });
      }
    },
    [filters, setFilters, setInitialLoadingReset, setLocationsListReset],
  );
};
