import Imagem from '../img/pin.png'

interface IPinOriginDestiny {
    width: number;
    height: number;
}
export const PinOriginDestiny:React.FC<IPinOriginDestiny> = ({width, height}) => {
  return (
    <img src={Imagem} alt="Descrição" width={width} height={height}/>
  )
}