import React, { useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import {
  InfoContainerStyled,
  InfoIconContentTooltipStyled,
  InfoIconLabelStyled,
  InfoIconTitleContainerStyled,
  LabelStyled,
} from './transactions-list-table-view.styled';
import { ITransactionDomain } from 'src-new/pages/financial/domains/transactions.domain';
import { getScheduleTypeTag } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/utils/get-schedule-type-tag/get-schedule-type-tag.util';
import moment from 'moment/moment';
import { getFinancialStatus } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/utils/get-financial-status-tag/get-financial-status-tag.util';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import TooltipComponent from 'components/Tooltip';
import { Info } from 'lucide-react';
import {
  convertStatusCodeSchedule,
  convertStatusCodeScheduleColor,
} from 'src-new/utils/convert-status-code-schedule/convert-status-code-schedule.util';
import { WrapperStateStyled } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-tabs/transactions-list-tabs-view.styled';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';

export interface ITransactionsListTableStatus {
  isError: boolean;
  isInitialLoading: boolean;
  isEmpty: boolean;
}

interface ITransactionsListTableViewProps {
  transactions: Array<ITransactionDomain>;
  statusProps: ITransactionsListTableStatus;
  infiniteScrollProps: IInfiniteScrollTable;
  selectedIds: Array<number>;
  handleSelectIds: (checked: boolean, id: number) => void;
  handleAllSelectIds: (checked: boolean) => void;
  handleRowAction: (locationId: string) => void;
  ordering: IOrdering;
  isAllIdsChecked: boolean;
  isDisabledCheckbox: (financialStatus: string, tag: string) => boolean;
  isDisabledAllCheckbox: boolean;
}

export const TransactionsListTableView: React.FC<ITransactionsListTableViewProps> = ({
  transactions,
  statusProps,
  ordering,
  selectedIds,
  infiniteScrollProps,
  handleSelectIds,
  handleRowAction,
  handleAllSelectIds,
  isAllIdsChecked,
  isDisabledCheckbox,
  isDisabledAllCheckbox,
}: ITransactionsListTableViewProps) => {
  const renderLabelRow = useCallback((label?: string) => {
    return <LabelStyled>{label ? label.toUpperCase() : '---'}</LabelStyled>;
  }, []);

  const renderCheckboxAll = useCallback((): ICell => {
    return {
      value: (
        <Checkbox
          key={'checkbox-all-transactions'}
          id={'ids'}
          name={'ids'}
          onChange={(event) => handleAllSelectIds(event.target.checked)}
          checked={isAllIdsChecked}
          disabled={isDisabledAllCheckbox}
        />
      ),
      isClicable: false,
    };
  }, [handleAllSelectIds, isAllIdsChecked, isDisabledAllCheckbox]);

  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns: Array<ICell> = [
      renderCheckboxAll(),
      { value: 'ID' },
      { value: '' },
      { value: 'Tipo de Serviço' },
      { value: 'Categoria' },
      { value: 'Data de Coleta' },
      { value: 'Recebedor' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Total de Serviço' },
      { value: 'Status da OC' },
      { value: 'Status' },
    ];

    const columnsConfig = '0.2fr 0.2fr 0.1fr 0.6fr 0.5fr 0.6fr 0.8fr 1.2fr 1.4fr 0.8fr 1.2fr 0.8fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, [renderCheckboxAll]);

  const renderCategoryRow = useCallback((typeSchedule: 'FTL' | 'STL' | 'LTL' | '---') => {
    const categoryType = getScheduleTypeTag(typeSchedule);

    return (
      <Tag label={categoryType.label} color={categoryType.color} variant="solid" display="inline-flex" size="md" />
    );
  }, []);

  const renderFinanceStatusRow = useCallback((financeStatus: string) => {
    const financeStatusType = getFinancialStatus(financeStatus);

    return (
      <Tag
        label={financeStatusType.label}
        color={financeStatusType.color}
        variant="solid"
        display="inline-flex"
        size="md"
      />
    );
  }, []);

  const renderStatusCodeRow = useCallback((statusCode: string) => {
    const statusCodeLabel = convertStatusCodeSchedule(statusCode);
    const statusCodeColor = convertStatusCodeScheduleColor(statusCode);

    return <Tag label={statusCodeLabel} color={statusCodeColor} variant="solid" display="inline-flex" size="md" />;
  }, []);

  const renderInfoIcon = useCallback((ctePrimary: string, cteSub: string) => {
    const primary = ctePrimary !== '' ? ctePrimary : '-';
    const sub = cteSub !== '' ? cteSub : '-';
    const renderTitle = (
      <InfoIconTitleContainerStyled>
        <strong>Informações Adicionais</strong>
        <InfoIconContentTooltipStyled>
          <InfoIconLabelStyled>CTe Primário: {primary}</InfoIconLabelStyled>
          <InfoIconLabelStyled>CTe de Subcontratação: {sub}</InfoIconLabelStyled>
        </InfoIconContentTooltipStyled>
      </InfoIconTitleContainerStyled>
    );

    return (
      <InfoContainerStyled>
        <TooltipComponent title={renderTitle} location="top" icon={<Info size={16} color="#6e6e6e" />} />
      </InfoContainerStyled>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return transactions.map((transaction): IRow => {
      const isDisabled = isDisabledCheckbox(transaction.financialStatus, transaction.tag);

      return {
        key: `${transaction.id}`,
        onClick: () => handleRowAction(transaction.schedule.id?.toString() ?? ''),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-${transaction.id}`}
                id={'id'}
                name={'id'}
                onChange={(event) => handleSelectIds(event.target.checked, transaction.id)}
                checked={selectedIds.includes(transaction.id)}
                disabled={isDisabled}
              />
            ),
            isClicable: false,
          },
          {
            value: transaction.schedule.id,
          },
          {
            value: renderInfoIcon(transaction.schedule.cte.primary, transaction.schedule.cte.sub),
          },
          {
            value: renderLabelRow(transaction.schedule.scheduleCargo.operationSubType ?? '---'),
          },
          {
            value: renderCategoryRow(transaction.schedule.typeSchedule),
          },
          {
            value: renderLabelRow(moment(transaction.schedule.scheduleDetails.loadDate).format('DD/MM/YYYY')),
          },
          {
            value: renderLabelRow(transaction.invoicedBy),
          },
          {
            value: renderLabelRow(transaction.schedule.origin?.name),
          },
          {
            value: renderLabelRow(transaction.schedule.destination?.name),
          },
          {
            value: renderLabelRow(convertCurrencyFormat.format(transaction.totalService)),
          },
          {
            value: renderStatusCodeRow(transaction.schedule.statusCode ?? '---'),
          },
          {
            value: renderFinanceStatusRow(transaction.financialStatus),
          },
        ],
      };
    });
  }, [
    handleRowAction,
    handleSelectIds,
    isDisabledCheckbox,
    renderCategoryRow,
    renderFinanceStatusRow,
    renderInfoIcon,
    renderLabelRow,
    renderStatusCodeRow,
    selectedIds,
    transactions,
  ]);

  if (statusProps.isInitialLoading) {
    return (
      <WrapperStateStyled>
        <Loading />
      </WrapperStateStyled>
    );
  }

  if (statusProps.isError) {
    return (
      <WrapperStateStyled>
        <ErrorState />
      </WrapperStateStyled>
    );
  }

  if (statusProps.isEmpty) {
    return (
      <WrapperStateStyled>
        <EmptyState />
      </WrapperStateStyled>
    );
  }

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={ordering}
      rows={renderRows}
      height={'77vh'}
      alignLinesCenter
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
