import React, { ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './accepted-matches-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface IAcceptedMatchesTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  meta?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (acceptedMatchesId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'accepted-matches-table-view';

const AcceptedMatchesTableView: React.FC<IAcceptedMatchesTableViewProps> = ({
  headerTable,
  contentTable,
  meta,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
  changePage,
  clickRowAction,
  arrowOrder,
  isLoading,
}: IAcceptedMatchesTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (meta) {
      return (
        <ContainerPaginationStyled data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={meta.currentPage} changePage={changePage} totalPage={meta.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, meta]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
      />
      {renderPagination}
    </ContainerTableStyled>
  );
};

export default AcceptedMatchesTableView;
