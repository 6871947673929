import { ISchedulesContext } from 'pages/schedule/context/schedule-register/schedule.types';

export const initialState: ISchedulesContext = {
  infoRules: {},
  cards: [],
  setResumeRules: () => undefined,
  setCardsList: () => undefined,
};

export enum ISchedulesAction {
  SET_RESUME_RULES = 'SET_RESUME_RULES',
  SET_CARDS_LIST = 'SET_CARDS_LIST',
}
