import { scheduleKanbanListInitialValuesDomain } from '../../pages/schedule-list/domains/schedule-kanban-list/schedule-kanban-list-initial-values.domain';
import { scheduleListFiltersInitialValues } from '../../types/schedule-list-filters/schedule-list-filters-initial-values.types';
import { IScheduleContext } from './types/schedule.types';
import { scheduleDetailsInitialValuesDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-initial-values.domain';
import { initialStateTruckRoutes } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-execution/schedule-execution.reducer';
import { totalRowInitialValues } from '../../types/schedule-list-total-row/schedule-list-total-row-initial-values.constants';
import { answerInitialValues } from 'domain-v2/checklist';
import { checklistDetailsInitialValues } from '../../pages/schedule-details/domains/schedule-checklist-details-initial-values.domain';
import { MapTypeId } from 'src-new/components/google-maps/google-maps-view.types';

export const scheduleInitialState: IScheduleContext = {
  scheduleList: {
    statusRequest: 'INITIAL',
    scheduleList: [],
    viewModeProps: {
      listViewMode: 'LIST',
      setListViewMode: () => undefined,
    },
    modalsProps: {
      modalOptions: { open: false, ids: [], modalType: '' },
      setModalOpen: () => undefined,
    },
    scheduleListFiltersProps: {
      filters: scheduleListFiltersInitialValues,
      setFilters: () => undefined,
    },
    scheduleSavedFiltersProps: {
      savedFilters: [],
      setSavedFilters: () => undefined,
    },
    scheduleListCheckbox: {
      checkbox: [],
      setScheduleListCheckbox: () => undefined,
    },
    scheduleListTotalRowProps: {
      totalRow: totalRowInitialValues,
      setScheduleListTotalRow: () => undefined,
    },
    setScheduleListRequest: () => undefined,
    setScheduleListSuccess: () => undefined,
    setScheduleListError: () => undefined,
    setScheduleListReset: () => undefined,
  },
  scheduleKanbanList: {
    statusRequest: 'INITIAL',
    scheduleKanbanList: scheduleKanbanListInitialValuesDomain,
    setScheduleKanbanListRequest: () => undefined,
    setScheduleKanbanListSuccess: () => undefined,
    setScheduleKanbanListError: () => undefined,
    setScheduleKanbanListReset: () => undefined,
  },
  scheduleOccurrenceList: {
    statusRequest: 'INITIAL',
    scheduleOccurrenceList: [],
    setScheduleOccurrenceListRequest: () => undefined,
    setScheduleOccurrenceListSuccess: () => undefined,
    setScheduleOccurrenceListError: () => undefined,
    setScheduleOccurrenceListReset: () => undefined,
  },
  scheduleDetails: {
    statusRequest: 'INITIAL',
    scheduleDetails: scheduleDetailsInitialValuesDomain,
    scheduleCustomFields: {
      scheduleCustomFields: [],
      setScheduleCustomFieldsDataSuccess: () => undefined,
    },
    allocationShipping: {
      shippingCompanyId: 0,
      statusRequest: 'INITIAL',
      shippingList: [],
      setScheduleDetailsAllocationShippingRequest: () => undefined,
      setScheduleDetailsAllocationShippingSuccess: () => undefined,
      setScheduleDetailsAllocationShippingError: () => undefined,
      setScheduleDetailsAllocationSelectedShipping: () => undefined,
    },
    allocationVehicle: {
      modalProps: {
        statusRequest: 'INITIAL',
        vehicleList: [],
        vehicleSelected: {
          checked: false,
          vehicleId: 0,
        },
        setScheduleDetailsAllocationVehicleRequest: () => undefined,
        setScheduleDetailsAllocationVehicleSuccess: () => undefined,
        setScheduleDetailsAllocationVehicleError: () => undefined,
        setScheduleDetailsSelectedVehicle: () => undefined,
      },
      accordionProps: {
        statusRequest: 'INITIAL',
        historyList: {
          historyVehicleList: [],
          historyDriverList: [],
        },
        setScheduleDetailsScheduleHistoryDriverAndVehicleRequest: () => undefined,
        setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess: () => undefined,
        setScheduleDetailsScheduleHistoryDriverAndVehicleError: () => undefined,
      },
    },
    allocationDriver: {
      modalProps: {
        statusRequest: 'INITIAL',
        driverList: [],
        driverSelected: {
          checked: false,
          driverId: 0,
        },

        setScheduleDetailsAllocationDriverRequest: () => undefined,
        setScheduleDetailsAllocationDriverSuccess: () => undefined,
        setScheduleDetailsAllocationDriverError: () => undefined,
        setScheduleDetailsSelectedDriver: () => undefined,
      },
      accordionProps: {
        statusRequest: 'INITIAL',
        historyList: {
          historyVehicleList: [],
          historyDriverList: [],
        },
        setScheduleDetailsScheduleHistoryDriverAndVehicleRequest: () => undefined,
        setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess: () => undefined,
        setScheduleDetailsScheduleHistoryDriverAndVehicleError: () => undefined,
      },
    },
    allocationActions: {
      isOpen: false,
      allocatedEntity: '',
      setDriverIdVehicleModal: () => undefined,
      setAllocationClose: () => undefined,
    },
    activeTabName: {
      activeTabName: '',
      setScheduleDetailsActiveTabName: () => undefined,
    },
    setScheduleDetailsRequest: () => undefined,
    setScheduleDetailsSuccess: () => undefined,
    setScheduleDetailsError: () => undefined,
    setScheduleDetailsReset: () => undefined,
    setScheduleDetailsAllocationOpen: () => undefined,
    setScheduleDetailsAllocationClose: () => undefined,
  },
  scheduleExecution: {
    mapAccordionProps: {
      statusRequest: 'INITIAL',
      monitoringTruckRoutes: initialStateTruckRoutes,
      firebaseTruckRoutes: initialStateTruckRoutes,
      plannedRoute: true,
      mapTypeActions: {
        mapType: MapTypeId.ROADMAP,
        setMapType: () => undefined,
      },
      setMapAccordionRequest: () => undefined,
      setMapAccordionSuccess: () => undefined,
      setMapAccordionError: () => undefined,
      setFirebaseTruckRoutes: () => undefined,
      setMonitoringTruckRoutes: () => undefined,
      setPlannedRoute: () => undefined,
    },
    setScheduleExecutionReset: () => undefined,
  },
  scheduleDocuments: {
    nfeDataProps: {
      statusRequest: 'INITIAL',
      nfeList: [],
      setNFeDataRequest: () => undefined,
      setNFeDataSuccess: () => undefined,
      setNFeDataError: () => undefined,
    },
    cteDataProps: {
      statusRequest: 'INITIAL',
      cteList: [],
      setCTeDataRequest: () => undefined,
      setCTeDataSuccess: () => undefined,
      setCTeDataError: () => undefined,
    },
    mdfeDataProps: {
      statusRequest: 'INITIAL',
      mdfeList: [],
      setMDFeDataRequest: () => undefined,
      setMDFeDataSuccess: () => undefined,
      setMDFeDataError: () => undefined,
    },
    othersDocumentsProps: {
      statusRequest: 'INITIAL',
      otherDocumentsList: [],
      setOthersDocumentsRequest: () => undefined,
      setOthersDocumentsSuccess: () => undefined,
      setOthersDocumentsError: () => undefined,
    },
    checklistDocumentsProps: {
      statusRequest: 'INITIAL',
      checklistDocuments: [],
      setChecklistDocumentsRequest: () => undefined,
      setChecklistDocumentsSuccess: () => undefined,
      setChecklistDocumentsError: () => undefined,
    },
    checklistQuestionsProps: {
      statusRequest: 'INITIAL',
      checklistQuestions: { moduleId: 0, questions: [] },
      setChecklistQuestionsRequest: () => undefined,
      setChecklistQuestionsSuccess: () => undefined,
      setChecklistQuestionsError: () => undefined,
    },
    checklistAnswerProps: {
      statusRequest: 'INITIAL',
      checklistAnswer: answerInitialValues,
      setChecklistAnswerRequest: () => undefined,
      setChecklistAnswerSuccess: () => undefined,
      setChecklistAnswerError: () => undefined,
    },
    checklistDetailsProps: {
      statusRequest: 'INITIAL',
      checklistDetails: checklistDetailsInitialValues,
      setChecklistDetailsError: () => undefined,
      setChecklistDetailsRequest: () => undefined,
      setChecklistDetailsSuccess: () => undefined,
    },
    modalsProps: {
      modalOptions: { open: false, id: 0, modalType: '' },
      setModalOpen: () => undefined,
    },
    setScheduleDocumentsReset: () => undefined,
  },
  scheduleRequestStatus: {
    statusRequest: 'INITIAL',
    setScheduleRequestStatus: () => undefined,
    setScheduleRequestStatusSuccess: () => undefined,
    setScheduleRequestStatusError: () => undefined,
    setScheduleRequestStatusReset: () => undefined,
  },
  scheduleListInitialLoading: {
    loading: true,
    setScheduleListInitialLoadingSuccess: () => undefined,
    setScheduleListInitialLoadingReset: () => undefined,
  },
  scheduleImportModal: {
    statusRequest: 'INITIAL',
    scheduleImportModal: [],
    setScheduleImportModalRequest: () => undefined,
    setScheduleImportModalSuccess: () => undefined,
    setScheduleImportModalError: () => undefined,
    setScheduleImportModalReset: () => undefined,
  },
  scheduleDownloadMapReport: {
    statusRequest: 'INITIAL',
    setScheduleDownloadMapReportRequest: () => undefined,
    setScheduleDownloadMapReportSuccess: () => undefined,
    setScheduleDownloadMapReportError: () => undefined,
    setScheduleDownloadMapReportReset: () => undefined,
  },
  setScheduleResetContext: () => undefined,
};
