import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ICancelAllocation } from '../../components/schedule-page-body/schedule-page-body.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQueryClient } from 'react-query';
import { updateCancelScheduleService } from '../../services/update-cancel-schedule/update-cancel-schedule.service';
import { useParams } from 'react-router-dom';

export const useUpdateCancelSchedule = () => {
  const { scheduleList } = useContext(ScheduleContext);
  const { id } = useParams<{ id: string }>();
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (values: ICancelAllocation) => {
      try {
        if (values.descriptionCancel.trim() === '') {
          showMessageFormatted({
            message: 'Selecione o Motivo do Cancelamento!',
            type: 'info',
          });
          return;
        }

        await updateCancelScheduleService(values);

        showMessageFormatted({
          message: 'Cancelamento Confirmado',
          type: 'success',
        });

        scheduleList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });

        reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);
        reactQueryClient.invalidateQueries(['schedule-kanban-list', scheduleList.scheduleListFiltersProps.filters]);

        if (id) {
          reactQueryClient.invalidateQueries(['schedule-details-by-id', id]);
        }
      } catch (error) {
        showMessageFormatted({
          message: `Erro ao cancelar viagem(ns) ${values.ids}.`,
          type: 'error',
        });
      }
    },
    [id, reactQueryClient, scheduleList.modalsProps, scheduleList.scheduleListFiltersProps.filters],
  );
};
