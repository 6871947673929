import React, { ReactElement, useMemo } from 'react';
import {
  ContainerIconStyled,
  NavigateToStyled,
  NavigateToStyledDisabled,
  NewFreightStyled,
  TextStyled,
  WrapperStyled,
} from './item.styled';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import CrownIcon from 'assets/icons/crown.icon';
import { UserContext } from 'state/user-context';

type ItemProps = {
  name: string;
  path: string;
  icon?: JSX.Element;
  line?: JSX.Element;
  subtitles?: JSX.Element;
  title: string;
  disabled?: boolean;
  shippingTerms: boolean;
  shippingPGR: boolean;
  isNewSchedule: boolean;
  disabledSubtitles: boolean;
  isEssencial: boolean;
  openModals: (modalType: 'newFreight' | 'newPlan') => void;
  isShippingCompany: boolean;
  isShipping: boolean;
};

const shippingTermsLink = 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Termos+Logshare+%5BV1%5D.pdf';

//TEMPORÁRIO
const shippingPGRLink = 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Regras_de_GR.pdf';

const ItemView: React.FC<ItemProps> = ({
  name,
  path,
  icon,
  disabled = false,
  line,
  title,
  shippingTerms,
  shippingPGR,
  isNewSchedule,
  disabledSubtitles,
  isEssencial,
  openModals,
  isShippingCompany,
  isShipping,
}) => {
  const { handleVisibleText } = React.useContext(SidebarContext);
  const { user } = React.useContext(UserContext);

  const verifyProPlan = useMemo((): Array<string> => {
    if (isEssencial) {
      return [
        'Tipo de Ocorrência',
        'Ofertas de Compra',
        'Ofertas Recebidas',
        'Mapa',
        'Gestão de Usuários',
        'Dashboard',
        'Alertas',
        'Vale Pedágio',
        'Checklist',
        'Faturas',
        'Carteira Digital',
      ];
    }

    return ['Vale Pedágio', 'Checklist', 'Carteira Digital'];
  }, [isEssencial]);

  const verifyMondelez = useMemo((): Array<string> => {
    return ['Pesagem'];
  }, []);

  const verifyGPA = useMemo((): Array<string> => {
    return ['Retrabalho'];
  }, []);

  const verifyShippingCompany = useMemo((): Array<string> => {
    return ['Ociosidade', 'Ocorrências', 'Atendidos', 'Homologados'];
  }, []);

  const verifyShipping = useMemo((): Array<string> => {
    return ['Ocorrências', 'Atendidos', 'Homologados'];
  }, []);

  const renderNavigateTo = useMemo((): ReactElement => {
    if ((disabled || disabledSubtitles) && verifyProPlan.includes(name)) {
      return (
        <NavigateToStyledDisabled onClick={() => isEssencial && openModals('newPlan')}>
          <TextStyled>{name}</TextStyled>
          <CrownIcon />
        </NavigateToStyledDisabled>
      );
    }

    if (user?.companyId !== 26 && verifyMondelez.includes(name)) {
      return (
        <NavigateToStyledDisabled>
          <TextStyled>{name}</TextStyled>
          <CrownIcon />
        </NavigateToStyledDisabled>
      );
    }

    if (user?.companyId !== 10 && verifyGPA.includes(name)) {
      return (
        <NavigateToStyledDisabled>
          <TextStyled>{name}</TextStyled>
          <CrownIcon />
        </NavigateToStyledDisabled>
      );
    }

    if (isShippingCompany && verifyShippingCompany.includes(name)) {
      return (
        <NavigateToStyledDisabled>
          <TextStyled>{name}</TextStyled>
          <CrownIcon />
        </NavigateToStyledDisabled>
      );
    }

    if (isShipping && verifyShipping.includes(name)) {
      return (
        <NavigateToStyledDisabled>
          <TextStyled>{name}</TextStyled>
          <CrownIcon />
        </NavigateToStyledDisabled>
      );
    }

    if (disabledSubtitles && title.toUpperCase() === 'INTELIGÊNCIA' && isEssencial) {
      return (
        <NavigateToStyledDisabled onClick={() => openModals('newPlan')}>
          <TextStyled>{name}</TextStyled>
        </NavigateToStyledDisabled>
      );
    }

    if (disabledSubtitles) {
      return (
        <NavigateToStyledDisabled>
          <TextStyled>{name}</TextStyled>
        </NavigateToStyledDisabled>
      );
    }

    if (isNewSchedule) {
      return (
        <NewFreightStyled onClick={() => openModals('newFreight')}>
          <TextStyled>{name}</TextStyled>
        </NewFreightStyled>
      );
    }

    return (
      <NavigateToStyled
        to={shippingTerms ? shippingTermsLink : shippingPGR ? shippingPGRLink : path}
        target={shippingTerms || shippingPGR ? '_blank' : ''}
        aria-label={`link-to-${path}`}
      >
        <TextStyled>{name}</TextStyled>
        <ContainerIconStyled>{icon}</ContainerIconStyled>
      </NavigateToStyled>
    );
  }, [
    disabled,
    disabledSubtitles,
    icon,
    isEssencial,
    isNewSchedule,
    isShippingCompany,
    name,
    openModals,
    path,
    shippingPGR,
    shippingTerms,
    title,
    user?.companyId,
    verifyMondelez,
    verifyProPlan,
    verifyShipping,
  ]);

  return (
    <>
      {line}
      <WrapperStyled onClick={() => handleVisibleText(false)}>{renderNavigateTo}</WrapperStyled>
    </>
  );
};

export default ItemView;
