import { INewUser } from 'domain/user';

export const PATH_TO_USER_LIST = '/usuarios';

export const initialValues: INewUser = {
  name: '',
  login: '',
  email: '',
  status: 'ativo',
  password: '',
  passwordConfirmation: '',
  resetPassword: false,
  home: [],
  register: [],
  schedules: [],
  tracking: [],
  opportunities: [],
  execution: [],
  financial: [],
  services: [],
  settings: [],
  telephone: '',
  isMaster: false,
};
