import * as Yup from 'yup';

export const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  area: Yup.string().required('Área é obrigatória'),
  telephone: Yup.string().matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Telefone inválido'),
  alerts: Yup.array().of(
    Yup.object().shape({
      workflow: Yup.string(),
      isActive: Yup.boolean(),
    }),
  ),
});
