import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { initialValuesHours } from './initial-values/form-values';
import FreightOperationSectionView from './freight-cargo-operation-section-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useUpdateHoursDetailsHook } from 'pages/freight/hooks/use-hours-details-update/use-cargo-details-update.hook';
import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { ScheduleDomain } from 'domain/schedule';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface IProps {
  values?: IAllocationShippingDetails;
  currentAllocation?: ScheduleDomain;
  onRefresh: Dispatch<SetStateAction<number>>;
}

const FreightOperationSection: React.FC<IProps> = ({ values, onRefresh, currentAllocation }) => {
  const [detailsSchedule, setDetailsSchedule] = useState<IAllocationShippingDetails>(values || initialValuesHours);

  const detailsScheduleUpdateHook = useUpdateHoursDetailsHook();

  const handleSubmit = useCallback(
    async (detailsSchedule: any) => {
      try {
        if (!currentAllocation?.id) {
          showMessageFormatted({
            message: 'Não foi possível encontrar o agendamento',
            type: 'error',
          });
          return;
        }

        await detailsScheduleUpdateHook(detailsSchedule, currentAllocation?.id, currentAllocation?.scheduleDetails?.id);

        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });

        onRefresh((prev) => prev + 1);
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    },
    [detailsScheduleUpdateHook],
  );

  useEffect(() => {
    setDetailsSchedule(values || initialValuesHours);
  }, [values]);

  return (
    <FreightOperationSectionView
      values={detailsSchedule}
      handleRegister={handleSubmit}
      currentAllocation={currentAllocation}
    />
  );
};

export default React.memo(FreightOperationSection);
