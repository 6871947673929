/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import { LoginDemoSchema } from './schemas-validation/schemas-validation';
import {
  ContainerCloseModalStyled,
  ContainerHeaderStyled,
  ContainerStyled,
  ContainerTitlesStyled,
  ContentStyled,
  SecondTitleStyled,
  TitleStyled,
} from './login-demo.styled';
import LoginDemoView from './login-demo-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ILoginDemo } from 'pages/login/services/entities/login.entity';
import { useLoginDemoHook } from 'pages/login/hooks/login-demo.hook';

type LoginDemoProps = {
  modal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const initialValues: ILoginDemo = {
  name: '',
  email: '',
  phone: '',
};

const LoginDemo: React.FC<LoginDemoProps> = ({ modal }) => {
  const loginDemo = useLoginDemoHook();

  const handleLogin = useCallback(
    (values: ILoginDemo) => {
      loginDemo(values)
        .then(() => {
          showMessageFormatted({
            message: 'Solicitação realizada',
            type: 'success',
          });
          modal(false);
        })
        .catch((error: any) => {
          showMessageFormatted({
            error: error,
            type: 'error',
          });
        });
    },
    [modal],
  );

  return (
    <ContainerStyled>
      <ContentStyled>
        <ContainerHeaderStyled>
          <ContainerTitlesStyled>
            <TitleStyled>SOLICITAR DEMONSTRAÇÃO</TitleStyled>

            <SecondTitleStyled>
              Faça parte desse ecossistema e reduza a emissão de carbono por caminhões vazios.
            </SecondTitleStyled>
          </ContainerTitlesStyled>

          <ContainerCloseModalStyled onClick={() => modal(false)}>X</ContainerCloseModalStyled>
        </ContainerHeaderStyled>
        <LoginDemoView handleLogin={handleLogin} loginDemoValues={initialValues} schemaValidation={LoginDemoSchema} />
      </ContentStyled>
    </ContainerStyled>
  );
};

export default LoginDemo;
