import styled, { css } from 'styled-components';

export const ContainerTable = styled.div`
  background-color: #fff;
  z-index: 1;
  width: 100%;
`;

export const ContainerBackhaulTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  z-index: 1;
`;

export const ContainerHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 5px;
`;
export const TextShippingContainer = styled.div`
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  justify-content: center;
  align-items: Left;
`;
export const TextBackhaulContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: Left;
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const FilterBackhaulContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
`;
export const BackhaulTitle = styled.span`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: bold;
    color: ${theme.colors.black};
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
export const SalutationBackhaul = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: ${theme.font.normal};
    color: #727272;
    margin-bottom: -35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
export const Container = styled.div`
  display: flex;
  margin-top: -4px;
  padding-bottom: 34px;
  position: relative;
  background-color: #fff;
  width: 100%;
  justify-content: space-between;
`;
export const ContainerTextAndButton = styled.div`
  margin-top: -11px;
  button:nth-last-child(2) {
    margin: 0px 10px;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  margin @media (max-width: 600px) {
    display: none;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  justify-content: center;
  align-items: Left;
  margin-bottom: 15px;
  gap: 10px;
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  flex: 0 0 40%;
  max-width: 50%;
  position: absolute;
  right: 0px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const HelloUser = styled.h1`
  ${({ theme }) => css`
    font-size: 36px;
    font-weight: bold;
    color: ${theme.colors.blue};
    margin-bottom: -3px;
    background-color: white;
  `}
`;
export const Salutation = styled.h5`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.font.normal};
    color: #727272;
    margin-bottom: 15px;
  `}
`;
export const SubText = styled.h6`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.medium};
    color: #aaaaaa;
  `}
`;
export const SecondTitle = styled.div`
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
`;
type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 10px;
    padding-bottom: 10px;
    align-items: center;
  `}
`;
export const ContainerRow = styled.div`
  width: 100%;
  margin-bottom: 2px;
`;
