import { FC } from 'react';
import { ModalBackhaulPurchaseSubtitleView } from './modal-backhaul-purchase-subtitle-view.component';
import { OperationsDomain } from 'src-new/domains/operations.domain';
import { IRequirementsDomain } from 'src-new/domains/requirements.domain';
import { IBackhaulPurchase } from '../../types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface IModalBackhaulPurchaseSubtitleProps {
  backhaulPurchase: IBackhaulPurchase;
  haulType: OperationsDomain;
  co2: number;
  requirements?: IRequirementsDomain;
}

export const ModalBackhaulPurchaseSubtitle: FC<IModalBackhaulPurchaseSubtitleProps> = (props) => {
  return <ModalBackhaulPurchaseSubtitleView {...props} />;
};
