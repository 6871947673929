import { PurchaseOfferBaitRouteActionType } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.action';
import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { IPurchaseOfferBaitRouteActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-bait-route.types';

export const purchaseOfferBaitRouteReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferBaitRouteActions,
): IPurchaseOfferContext => {
  switch (action.type) {
    case PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_REQUEST:
      return {
        ...state,
        purchaseOfferBaitRoute: {
          ...state.purchaseOfferBaitRoute,
          statusRequest: 'PROCESSING',
        },
      };
    case PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_SUCCESS:
      return {
        ...state,
        purchaseOfferBaitRoute: {
          ...state.purchaseOfferBaitRoute,
          statusRequest: 'SUCCESS',
        },
      };
    case PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_ERROR:
      return {
        ...state,
        purchaseOfferBaitRoute: {
          ...state.purchaseOfferBaitRoute,
          statusRequest: 'ERROR',
        },
      };
    case PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_RESET:
      return {
        ...state,
        purchaseOfferBaitRoute: {
          ...state.purchaseOfferBaitRoute,
          statusRequest: 'INITIAL',
        },
      };
    default:
      return state;
  }
};
