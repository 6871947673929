import { scheduleKanbanListInitialValuesDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-kanban-list/schedule-kanban-list-initial-values.domain';
import { ScheduleKanbanListActionType } from '../../schedule.action';
import { IScheduleKanbanListActions } from '../../types/schedule-kanban.types';
import { IScheduleContext } from '../../types/schedule.types';

export const scheduleKanbanListReducer = (
  state: IScheduleContext,
  action: IScheduleKanbanListActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_REQUEST:
      return {
        ...state,
        scheduleKanbanList: {
          ...state.scheduleKanbanList,
          statusRequest: 'PROCESSING',
          scheduleKanbanList: scheduleKanbanListInitialValuesDomain,
        },
      };
    case ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_SUCCESS:
      return {
        ...state,
        scheduleKanbanList: {
          ...state.scheduleKanbanList,
          statusRequest: 'SUCCESS',
          scheduleKanbanList: action.payload,
        },
      };
    case ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_ERROR:
      return {
        ...state,
        scheduleKanbanList: {
          ...state.scheduleKanbanList,
          statusRequest: 'ERROR',
          scheduleKanbanList: scheduleKanbanListInitialValuesDomain,
        },
      };
    case ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_RESET:
      return {
        ...state,
        scheduleKanbanList: {
          ...state.scheduleKanbanList,
          statusRequest: 'INITIAL',
          scheduleKanbanList: scheduleKanbanListInitialValuesDomain,
        },
      };
    default:
      return state;
  }
};
