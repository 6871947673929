export interface IPagination {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface IListPage<T> {
  items: Array<T>;
  meta: IPagination;
}

export const paginationInitialValues: IPagination = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0,
};
