import { FC, useContext, useMemo } from 'react';
import { ScheduleDetailsBodyView } from './schedule-details-body-view.component';
import { UserContext } from 'state/user-context';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const ScheduleDetailsBody: FC = () => {
  const { isShippingCompany } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const isDenied = useMemo(
    (): boolean => scheduleDetails.scheduleDetails.statusCode === 'OT99' && !isShippingCompany,
    [isShippingCompany, scheduleDetails.scheduleDetails.statusCode],
  );

  return <ScheduleDetailsBodyView isDenied={isDenied} />;
};
