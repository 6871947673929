import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useContext, useEffect, useMemo } from 'react';
import { useGetChecklistDocumentsData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-checklist-documents-data/use-get-checklist-documents-data.hook';
import { DocumentsChecklistView } from './documents-checklist-view.component';
import { IModalDelete } from '../../execution-documents.component';

interface IDocumentsChecklistProps {
  handleVisible: (id: number) => void;
  setShowDeleteModal: (value:IModalDelete) => void;
}
export const DocumentsChecklist: React.FC<IDocumentsChecklistProps> = (params) => {
  const { scheduleDocuments } = useContext(ScheduleContext);

  const getChecklistDocumentsData = useGetChecklistDocumentsData();

  useEffect(() => {
    if (scheduleDocuments.checklistDocumentsProps.statusRequest === 'INITIAL') {
      getChecklistDocumentsData();
    }
  }, [getChecklistDocumentsData, scheduleDocuments.checklistDocumentsProps.statusRequest]);

  const isLoading = useMemo(
    () => scheduleDocuments.checklistDocumentsProps.statusRequest === 'PROCESSING',
    [scheduleDocuments.checklistDocumentsProps.statusRequest],
  );

  return (
    <DocumentsChecklistView
      checklistDocuments={scheduleDocuments.checklistDocumentsProps.checklistDocuments}
      isLoading={isLoading}
      handleVisible={params.handleVisible}
      setShowDeleteModal={params.setShowDeleteModal}
    />
  );
};
