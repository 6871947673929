import { Button } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';

import * as S from './styled';

import { optionsCheckStubStatus } from 'domain-v2/pallet/register';
import { ICheckStubStatus } from 'domain-v2/check-stub';
import Select from 'components/select/select';

export const StatusForm: React.FC = () => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<{ status: ICheckStubStatus }>();

  return (
    <S.WrapperForm>
      <Select
        label="Status"
        id="status"
        name="status"
        value={values.status}
        setFieldValue={setFieldValue}
        options={optionsCheckStubStatus}
      />

      <S.WrapperButton>
        <Button label="Confirmar" color="blue" onPress={handleSubmit} size="md" variant="solid" />
      </S.WrapperButton>
    </S.WrapperForm>
  );
};
