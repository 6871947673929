import React, { Fragment, ReactElement, useMemo } from 'react';
import * as S from './list-subtitle-view.styled';

interface IListSubtitleViewProps {
  dataTestId?: string;
  title: string;
  hasTrace?: boolean;
}

export const ListSubtitleView: React.FC<IListSubtitleViewProps> = ({ dataTestId, title, hasTrace }) => {
  const renderTrace = useMemo((): ReactElement => {
    if (hasTrace) {
      return <S.Trace />;
    }

    return <Fragment />;
  }, [hasTrace]);

  return (
    <S.ContainerTitle data-testid={`${dataTestId}-title`}>
      <S.Title>{title}</S.Title>
      {renderTrace}
    </S.ContainerTitle>
  );
};
