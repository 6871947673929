import { IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { initialError } from 'src-new/contexts/chat/chat.constants';

export const chatReducerResetState = (state: IChatContext): IChatContext => {
  return {
    ...state,
    chatConversationList: {
      ...state.chatConversationList,
      statusRequest: 'INITIAL',
      conversations: [],
      error: initialError,
      paginationActions: {
        ...state.chatConversationList.paginationActions,
        nextPage: undefined,
        isPageLimit: false,
      },
      modalActions: {
        ...state.chatConversationList.modalActions,
        isOpenModal: false,
      },
    },
    chatEligibleUsers: {
      ...state.chatEligibleUsers,
      statusRequest: 'INITIAL',
      users: [],
      validateThreadsActions: {
        ...state.chatEligibleUsers.validateThreadsActions,
        statusRequest: 'INITIAL',
        haveCreated: true,
      },
    },
    chatMessageQuantity: {
      ...state.chatMessageQuantity,
      statusRequest: 'INITIAL',
      chatMessages: [],
    },
    chatUnreadMessages: {
      ...state.chatUnreadMessages,
      unreadMessages: [],
    },
    chatUserEmails: {
      ...state.chatUserEmails,
      userEmails: undefined,
      statusRequest: 'INITIAL',
    },
  };
};
