import React, { useCallback, useEffect, useState } from 'react';

import ModalAssintPlanView from './modal-assign-plan -view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { sendEmailAssignPlanHook } from 'pages/accepted-matches/hooks/use-assing-plan/use-assign-plan.hook';
import { IAssignPlan } from 'domain/assing-plan';

interface Props {
  handleCloseModal: () => void;
  openModal: boolean;
}

const ModalAssintPlan: React.FC<Props> = ({ handleCloseModal, openModal }) => {
  const sendEmailAssignPlan = sendEmailAssignPlanHook();
  const { user } = React.useContext(UserContext);

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    companyId: user?.companyId,
  };

  const [form, setForm] = useState<IAssignPlan>(initialValues);

  useEffect(() => {
    if (user?.companyId) {
      setForm({ ...form, companyId: user.companyId });
    }
  }, [user]);

  const handleSubmit = useCallback(
    async (assignPlanData: IAssignPlan) => {
      if (form.name === '') {
        showMessageFormatted({
          message: 'É necessário informar o nome.',
          type: 'error',
        });
        return;
      }
      if (form.email === '') {
        showMessageFormatted({
          message: 'É necessário informar o email.',
          type: 'error',
        });
        return;
      }
      if (form.phone === '') {
        showMessageFormatted({
          message: 'É necessário informar o telefone.',
          type: 'error',
        });
        return;
      }
      sendEmailAssignPlan(assignPlanData)
        .then(() => {
          showMessageFormatted({
            message: 'Cadastramos o seu interesse.',
            type: 'success',
          });
          handleCloseModal();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        );
    },
    [form.name, form.email, form.phone, sendEmailAssignPlan, handleCloseModal],
  );

  return (
    <ModalAssintPlanView
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      form={form}
      setForm={(type: string, value: string) => setForm({ ...form, [type]: value })}
      handleSubmit={() => handleSubmit(form)}
    />
  );
};

export default ModalAssintPlan;
