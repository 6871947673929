import React, { useMemo } from 'react';

import { IOperationValues } from './types';
import * as S from './styled';

import { capitalize } from 'utils/string/capitalize';
import { formatCurrency, formatDecimal } from 'utils/format-numbers';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';

interface CardResumeOperationProps {
  cargoDetailsValues: IOperationValues;
  operationBy: string;
}

export const CardResumeOperation: React.FC<CardResumeOperationProps> = ({ cargoDetailsValues, operationBy }) => {
  const { vehicleType, vehicleCategoryType, volume, pallet, merchValue, weight, distance, inPallet, product } =
    cargoDetailsValues;

  const palletValue = useMemo(() => {
    if (inPallet) return `${formatDecimal(pallet)} Pallets`;

    return <S.Text>Não Paletizado</S.Text>;
  }, [inPallet, pallet]);

  const renderVehicleValue = useMemo(() => {
    const vehicleTypeToUse = vehicleType || '';
    const vehicleCategoryToUse = vehicleCategoryType || '';

    const vehicleTypeText = convertVehicleTypeTypeToName(vehicleTypeToUse);
    const vehicleCategoryText = convertVehicleCategoryTypeToName(vehicleCategoryToUse);

    return (
      <S.Text uppercase={!!(vehicleTypeText || vehicleCategoryText)} italic={!(vehicleTypeText || vehicleCategoryText)}>
        {`${vehicleTypeText} ${vehicleCategoryText}`}
      </S.Text>
    );
  }, [vehicleType, vehicleCategoryType]);

  return (
    <S.LineSection columns="0.5fr 2.5fr 8fr">
      <S.StepIconSection>
        <S.StepIcon />
      </S.StepIconSection>

      <S.Text fontWeight>Operação</S.Text>

      <S.OperationInformationContent>
        <S.Text>Operado por {capitalize(operationBy)}</S.Text>

        <div>
          <S.Text fontWeight>Veículo</S.Text>
          {renderVehicleValue}
        </div>

        <div>
          <S.Text fontWeight>Carga</S.Text>
          <S.Text uppercase>
            <S.Text>{product}</S.Text>
            <S.Text>{`${formatDecimal(weight)} kg`}</S.Text>
            <S.Text>{`${formatDecimal(volume)} vol`}</S.Text>
            <S.Text>{palletValue}</S.Text>
            <S.Text>{`Valor: ${formatCurrency(merchValue)}`}</S.Text>
          </S.Text>
        </div>

        <div>
          <S.Text fontWeight>Distância</S.Text>
          <S.Text>{`${distance.toFixed(0)} km`}</S.Text>
        </div>
      </S.OperationInformationContent>
    </S.LineSection>
  );
};
