import { convertUserStatusEntityToDomain } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { ICreatedUserEntity, IFunctionalityEntity } from 'pages/user/services/entities/user.entity';
import { ICreatedUser, SubPermissions } from 'domain/user';

const userHasPerrmision = (idFunctionality: number, userEntity: ICreatedUserEntity): boolean => {
  const functionality = userEntity.functionalities.filter((functionality) => {
    if (functionality.id === idFunctionality) {
      return functionality;
    }
  });
  if (functionality.length) {
    return true;
  }
  return false;
};

const convertFunctionalitiesToPermissions = (
  permissionName: string,
  functionalities: Array<IFunctionalityEntity>,
  userEntity: ICreatedUserEntity,
): Array<SubPermissions> => {
  const permissions: Array<SubPermissions> = [];
  functionalities.map((functionality: IFunctionalityEntity) => {
    if (functionality.parent === permissionName) {
      permissions.push({
        id: functionality.id,
        label: functionality.name,
        name: functionality.name,
        profiles: functionality.profiles,
        value: userHasPerrmision(functionality.id, userEntity),
        parent: functionality.parent,
        routePath: functionality.routePath,
      });
    }
  });
  return permissions;
};

export const convertUserEntityToDomain = (
  userEntity: ICreatedUserEntity,
  companyFunctionalies: Array<IFunctionalityEntity>,
): ICreatedUser => ({
  id: userEntity.id,
  name: userEntity.name,
  plan: userEntity.plan,
  email: userEntity.email,
  login: userEntity.login,
  telephone: formatCelphoneNumberEntityToDomain(userEntity?.telephone),
  status: convertUserStatusEntityToDomain(userEntity.status),
  password: userEntity.password,
  passwordConfirmation: userEntity.passwordConfirmation,
  resetPassword: userEntity.resetPassword,
  isMaster: userEntity.isMaster,
  settings: convertFunctionalitiesToPermissions('settings', companyFunctionalies, userEntity),
  home: convertFunctionalitiesToPermissions('home', companyFunctionalies, userEntity),
  register: convertFunctionalitiesToPermissions('register', companyFunctionalies, userEntity),
  schedules: convertFunctionalitiesToPermissions('schedules', companyFunctionalies, userEntity),
  tracking: convertFunctionalitiesToPermissions('tracking', companyFunctionalies, userEntity),
  financial: convertFunctionalitiesToPermissions('financial', companyFunctionalies, userEntity),
  services: convertFunctionalitiesToPermissions('services', companyFunctionalies, userEntity),
  opportunities: convertFunctionalitiesToPermissions('opportunities', companyFunctionalies, userEntity),
  execution: convertFunctionalitiesToPermissions('execution', companyFunctionalies, userEntity),
});
