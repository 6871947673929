import { includes } from 'lodash';
import { TransactionsActionType, TransactionsListActionType } from '../transactions.action';
import { transactionsReducerResetState } from './transactions-reducer.constants';
import {
  ITransactionsActions,
  ITransactionsContext,
} from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions.types';
import { transactionsListReducer } from 'src-new/pages/financial/pages/transactions/contexts/transactions/reducers/transactions-list/transactions-list.reducer';
import { ITransactionsListActions } from '../types/transactions-list.types';

export const transactionsReducer = (
  state: ITransactionsContext,
  action: ITransactionsActions,
): ITransactionsContext => {
  if (includes(Object.values(TransactionsListActionType), action.type)) {
    return transactionsListReducer(state, action as ITransactionsListActions);
  }

  if (action.type === TransactionsActionType.TRANSACTIONS_RESET) {
    return transactionsReducerResetState(state);
  }

  return state;
};
