import styled, { css } from 'styled-components';

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    flex-direction: column;
    width: 100%;
  `}
`;
