import API from 'utils/API/API';
import { TransactionListParams } from 'pages-v2/transactions/types/list';

export const exportTransactionsExcelService = async (params: TransactionListParams) => {
  const { data } = await API.get('/v2/financial-calculation/transactions-excel', {
    params,
    responseType: 'arraybuffer',
  });
  return data;
};
