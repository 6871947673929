import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import DashboardBar from '../components/dashboard-bar/dashboard-bar';
import ContentBox from '../../../components/content-box/content-box';

import {
  ContainerDashboardStyled,
  ContainerRow,
  DashboardContainerStyled,
  IconFormatedStyled,
  LineSection,
  OneContainerStyled,
  TwoContainerStyled,
} from './dashboard-content.styled';

import { shippingCompanySearchService } from 'services/shipping-company/search';
import { IDashboard } from 'domain/dashboard';
import SectionDivider from 'components/section-divider/section-divider';
import InputField from 'components/input-field/input-field';
import ContentBoxTime from 'components/content-box-time/content-box-time';
import Button from 'components/button/button';
import AsyncSelectField from 'components/async-select/async-select';
import FinanceIcon from 'assets/icons/finance.icon';
import CoinsIcon from 'assets/icons/coins.icon';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs, { Dayjs } from 'dayjs';

interface IDashboardView {
  values: IDashboard;
  dashboardFilter: (
    startDate: string,
    endDate: string,
    origin: string,
    destiny: string,
    shippingCompany: string,
  ) => void;
}

const DashboardContentView: React.FC<IDashboardView> = ({ values, dashboardFilter }) => {
  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      origin: '',
      destiny: '',
      shippingCompany: '',
    },
    onSubmit: ({ startDate, endDate, origin, destiny, shippingCompany }) => {
      dashboardFilter(startDate, endDate, origin, destiny, shippingCompany);
    },
  });

  const [origin, setOrigin] = useState('');
  const [destiny, setDestiny] = useState('');
  const [shippingCompany, setShippingCompany] = useState<any>();
  const [startDate, setStartDate] = useState<{
    focused: boolean;
    date: Dayjs | null;
  }>({ focused: false, date: dayjs('2024-01-01') });

  const [endDate, setEndDate] = useState<{
    focused: boolean;
    date: Dayjs | null;
  }>({ focused: false, date: dayjs('2024-12-31') });

  useEffect(() => {
    formik.setFieldValue('startDate', startDate.date ? startDate.date.format('YYYY-MM-DD') : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate.date, startDate, formik.setFieldValue]);

  useEffect(() => {
    formik.setFieldValue('endDate', endDate.date ? endDate.date.format('YYYY-MM-DD') : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, endDate.date, formik.setFieldValue]);

  useEffect(() => {
    formik.setFieldValue('origin', origin ? origin : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);

  useEffect(() => {
    formik.setFieldValue('destiny', destiny ? destiny : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destiny]);

  useEffect(() => {
    formik.setFieldValue('shippingCompany', shippingCompany ? shippingCompany : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCompany]);
  return (
    <>
      <LineSection columns={'1fr 1fr 1fr 0.7fr 0.7fr 0.5fr'}>
        <ContainerRow>
          <InputField
            label="Origem"
            id="neighborhood"
            name="neighborhood"
            type="text"
            onChange={(e: any) => setOrigin(e.target.value)}
            value={origin}
          />
        </ContainerRow>
        <ContainerRow>
          <InputField
            label="Destino"
            id="neighborhood"
            name="neighborhood"
            type="text"
            onChange={(e: any) => setDestiny(e.target.value)}
            value={destiny}
          />
        </ContainerRow>
        <ContainerRow>
          <AsyncSelectField
            placeholder=""
            label="Transportadora"
            isClearable={true}
            name="shippingCompanyId"
            id="shippingCompanyId"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              setShippingCompany(e && e.value);
            }}
            apiCallFunction={shippingCompanySearchService || (async () => [])}
            loadOptions={shippingCompanySearchService || (async () => [])}
          />
        </ContainerRow>

        <ContainerRow style={{ marginTop: 5 }}>
          <DateTimePikers
            label="Data Inicial"
            format="DD/MM/YYYY  HH:mm"
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={startDate.date}
            onChange={(date) => setStartDate((prvState) => ({ ...prvState, date }))}
          />
        </ContainerRow>
        <ContainerRow style={{ marginTop: 5 }}>
          <DateTimePikers
            label="Data Final"
            format="DD/MM/YYYY  HH:mm"
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={endDate.date}
            onChange={(date) => setEndDate((prvState) => ({ ...prvState, date }))}
          />
        </ContainerRow>
        <ContainerRow style={{ marginTop: 27 }}>
          <Button bgColor="blue" title="Atualizar" callback={formik.handleSubmit} size="large" />
        </ContainerRow>
      </LineSection>
      <ContainerDashboardStyled>
        <SectionDivider label="Resumo" />
        <TwoContainerStyled>
          <ContentBox
            title="Fretes Transacionados"
            amount={Number(values.pedidosFinalizados)}
            decimals={0}
            color="lightgray"
            subtitle="Viagens"
          />
          <ContentBox
            title="Receita de Frete"
            amount={Number(values?.receita_despesa.receita)}
            prefix="R$ "
            decimals={2}
            color="lightgray"
            icon={
              <IconFormatedStyled>
                <FinanceIcon />
              </IconFormatedStyled>
            }
          />
          <ContentBox
            title="Despesa de Frete"
            amount={Number(values.receita_despesa.despesa)}
            prefix="R$ "
            decimals={2}
            color="lightgray"
            icon={
              <IconFormatedStyled>
                <CoinsIcon />
              </IconFormatedStyled>
            }
          />
        </TwoContainerStyled>
        <DashboardContainerStyled>
          <DashboardBar pedidosFinalizadosPeriodo={values.pedidosFinalizadosPeriodo} />
        </DashboardContainerStyled>
        <SectionDivider label="Backhaul e Economia de CO2" />
        <OneContainerStyled>
          <ContentBox
            title="Oportunidades de Backhaul"
            subtitle="Matches"
            amount={Number(values.opportunitiesBackhaul)}
            decimals={0}
            color="lightgray"
          />
          <ContentBox
            title="Economia de CO2"
            subtitle="CO2 Economizados"
            amount={values.co2}
            decimals={0}
            sufix={' KG'}
            color="lightgray"
          />
          <ContentBox
            title="Fretes Backhaul Executados"
            subtitle="Viagens"
            amount={values?.backhaulExecutedCount}
            decimals={0}
            color="lightgray"
          />
          <ContentBox
            title="Compras de Backhaul"
            subtitle="Viagens"
            amount={Number(values.idlenessCount)}
            decimals={0}
            color="lightgray"
          />
          <ContentBox
            title="Vendas de Backhaul"
            subtitle="Viagens"
            amount={Number(values.transportRouteCount)}
            decimals={0}
            color="lightgray"
          />
        </OneContainerStyled>
        <SectionDivider label="Operação" />
        <OneContainerStyled>
          <ContentBox
            title="OnTime"
            amount={Number(values.dataToTracking.onTime)}
            decimals={0}
            sufix="%"
            color="lightgray"
            subtitle="Finalizadas no Prazo"
          />
          <ContentBoxTime
            title="TMO"
            subtitle="Tempo Médio por Operação"
            hours={Number(values.dataToTracking?.tmo?.hours)}
            minutes={Number(values.dataToTracking?.tmo?.minutes)}
            decimals={0}
            color="lightgray"
          />
          <ContentBoxTime
            title="Carregamento"
            subtitle="Tempo Médio"
            hours={Number(values.dataToTracking?.averageLoading?.hours)}
            minutes={Number(values.dataToTracking.averageLoading?.minutes)}
            decimals={0}
            color="lightgray"
          />
          <ContentBoxTime
            title="Trajeto"
            subtitle="Tempo Médio"
            hours={Number(values.dataToTracking.averageDisplacement?.hours)}
            minutes={Number(values.dataToTracking.averageDisplacement?.minutes)}
            decimals={0}
            color="lightgray"
          />
          <ContentBoxTime
            title="Descarregamento"
            subtitle="Tempo Médio"
            hours={Number(values.dataToTracking?.averageUnloading?.hours)}
            minutes={Number(values.dataToTracking?.averageUnloading?.minutes)}
            decimals={0}
            color="lightgray"
          />
        </OneContainerStyled>
        <OneContainerStyled>
          <ContentBoxTime
            title="Alocação"
            subtitle="Tempo Médio"
            hours={Number(values.dataToTracking?.averageShippingCompanyAccepted?.hours)}
            minutes={Number(values.dataToTracking?.averageShippingCompanyAccepted?.minutes)}
            decimals={0}
            color="lightgray"
          />
          <ContentBoxTime
            title="Tempo de Aceite da Trasportadora"
            subtitle="Tempo Médio"
            hours={Number(values.dataToTracking?.averageShippingCompanyAccepted?.hours)}
            minutes={Number(values.dataToTracking?.averageShippingCompanyAccepted?.minutes)}
            decimals={0}
            color="lightgray"
          />
          <ContentBox
            title="Percentual de Aceite da Transportadora"
            subtitle="Das Viagens Ofertadas"
            amount={Number(values.dataToTracking?.percentOfTripsAccepted) as any}
            decimals={0}
            sufix="%"
            color="lightgray"
          />
        </OneContainerStyled>
      </ContainerDashboardStyled>
    </>
  );
};

export default DashboardContentView;
