import { DownloadFile } from 'utils/download-file/download-file';
import { TransactionListParams } from 'pages-v2/transactions/types/list';
import { exportTransactionsExcelService } from 'pages-v2/transactions/services/export-excel';

export const UseTransactionsExcelListHook = async (filter: TransactionListParams) => {
  const file = await exportTransactionsExcelService(filter);

  if (file) {
    DownloadFile(file, 'vnd.ms-excel;', 'FATURAMENTO');
  }
};
