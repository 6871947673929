import { useCallback, useReducer } from 'react';
import { chatInitialState } from '../../chat.constants';
import { ChatUnreadMessagesActionType } from 'src-new/contexts/chat/chat.action';
import { UnreadConversation } from 'talkjs/types/talk.types';
import { IChatUnreadMessages } from 'src-new/contexts/chat/types/chat-unread-messages.types';
import { chatUnreadMessagesReducer } from 'src-new/contexts/chat/reducers/chat-unread-messages/chat-unread-messages.reducer';

export const useChatUnreadMessagesContextValues = (): IChatUnreadMessages => {
  const [state, dispatch] = useReducer(chatUnreadMessagesReducer, chatInitialState);

  const setUnreadMessages = useCallback((unreadConversations: Array<UnreadConversation>) => {
    dispatch({ type: ChatUnreadMessagesActionType.CHAT_SET_UNREAD_MESSAGES, payload: unreadConversations });
  }, []);

  return {
    ...state.chatUnreadMessages,
    setUnreadMessages,
  };
};
