import React, { useCallback, useContext, useMemo } from 'react';
import { CardView } from './card-view.component';
import { ICardKeyType } from './card.types';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { IPurchaseAndSaleCard } from '../../../../domains/home-cards/home-cards.domain';
import { useNavigate } from 'react-router-dom';

interface ICardProps {
  cardType: 'purchaseOffer' | 'saleOffer';
}

export const Card: React.FC<ICardProps> = ({ cardType }) => {
  const { homeCards } = useContext(HomeContext);

  const navigate = useNavigate();

  const cardDetails = useMemo(
    (): IPurchaseAndSaleCard => homeCards.homeCards[cardType],
    [cardType, homeCards.homeCards],
  );

  const cardKeyType = useMemo((): ICardKeyType => {
    if (cardType === 'purchaseOffer') {
      return { label: 'compra', color: ThemeColorsV2Type.Red, valuePotentialType: 'saving' };
    }

    return { label: 'venda', color: ThemeColorsV2Type.Blue, valuePotentialType: 'receita' };
  }, [cardType]);

  const handleNavigateToList = useCallback(
    () => navigate(cardType === 'purchaseOffer' ? '/ofertas-compra' : '/ofertas-venda'),
    [cardType, navigate],
  );

  return <CardView cardKeyType={cardKeyType} cardDetails={cardDetails} handleNavigateToList={handleNavigateToList} />;
};
