import React, { ReactNode, useContext, useMemo } from 'react';

import AcceptedMatchesTableView from './accepted-matches-table-view';

import { UserContext } from 'state/user-context';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IAcceptedMatchesTableProps {
  contentTable: Array<IContentTable>;
  meta?: PageInfo;
  changePage: (page: number) => void;
  goToUpdatePage: (acceptedMatchesId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const AcceptedMatchesTable: React.FC<IAcceptedMatchesTableProps> = ({
  contentTable,
  meta,
  changePage,
  goToUpdatePage,
  isLoading,
  arrowOrder,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}) => {
  const { isAdmin } = useContext(UserContext);
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.1,
      },
      {
        value: 'ID',
        flex: 0.2,
      },
      {
        value: 'Categoria',
        flex: 0.3,
      },
      {
        value: 'ID Rota',
        flex: 0.4,
      },
      {
        value: isAdmin ? 'Parceiro' : ' ',
        flex: isAdmin ? 0.5 : 0,
      },
      {
        value: 'Origem',
        flex: 1.3,
      },
      {
        value: 'Destino',
        flex: 1.3,
      },
      {
        value: 'Tipo de Operação',
        flex: 0.6,
      },
      {
        value: 'Tipo de Frota',
        flex: 0.6,
      },
      {
        value: 'Status',
        flex: 0.35,
      },
      {
        value: '',
        flex: 0.1,
      },
      {
        value: '',
        flex: 0.1,
      },
    ];
  }, []);

  return (
    <AcceptedMatchesTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      meta={meta}
      changePage={changePage}
      clickRowAction={goToUpdatePage}
      isLoading={isLoading}
      clickOrderBy={clickOrderBy}
      clickSortDirection={clickSortDirection}
    />
  );
};

export default AcceptedMatchesTable;
