import React from 'react';

import * as S from './styled';

import { formatDate } from 'utils/format-numbers';
import { IAddressValues, IDeliveryScheduleDetailsValues } from 'domain/card-resume';

interface CardResumeDeliveryProps {
  destinyValues: IAddressValues;
  scheduleDetailsValues?: IDeliveryScheduleDetailsValues;
  hasConnector?: boolean;
}

export const CardResumeDelivery: React.FC<CardResumeDeliveryProps> = ({
  destinyValues,
  scheduleDetailsValues,
  hasConnector,
}) => {
  const deliveryDate = React.useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { deliveryDate, deliveryInitialDate, deliveryInitialTime, deliveryEndDate, deliveryEndTime } =
      scheduleDetailsValues;

    if (
      [deliveryDate, deliveryInitialDate, deliveryInitialTime, deliveryEndDate, deliveryEndTime].includes(
        'Invalid date',
      )
    ) {
      return null;
    }

    if (deliveryDate) return deliveryDate;

    if (deliveryInitialDate && deliveryEndDate)
      return `${formatDate(deliveryInitialDate, deliveryInitialTime)} - ${formatDate(
        deliveryEndDate,
        deliveryEndTime,
      )}`;

    if (deliveryInitialDate) return formatDate(deliveryInitialDate, deliveryInitialTime);

    if (deliveryEndDate) return formatDate(deliveryEndDate, deliveryEndTime);

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
          {hasConnector && <S.Connector size={destinyValues?.tradingName ? '110' : '150'} />}
        </S.StepIconSection>

        <S.Text blueColor fontWeight>
          Entrega
        </S.Text>

        {destinyValues?.tradingName ? (
          <S.TextContent uppercase>
            <S.Text blueColor fontWeight>
              {destinyValues.tradingName}
            </S.Text>

            <S.Text>{`${destinyValues.street} ${destinyValues.number}`}</S.Text>

            <S.Text>{`${destinyValues.neighborhood} - ${destinyValues.city} - ${destinyValues.state}`}</S.Text>

            <S.Text>{destinyValues.zipCode}</S.Text>

            <S.Text padding="0 0 10px 0">{destinyValues.cnpj}</S.Text>

            <S.Text>{deliveryDate ?? ''}</S.Text>
          </S.TextContent>
        ) : (
          <S.TextContent italic>
            <S.Text>Não informado</S.Text>
          </S.TextContent>
        )}
      </S.LineSection>
    </S.Content>
  );
};
