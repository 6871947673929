import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import { initialValuesDriver, PATH_TO_DRIVER_LIST } from './form-values/form-values';
import DriverRegisterFormView from './driver-register-form-view';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useDriverRegisterHook } from 'pages/driver/hooks/use-driver-register/use-driver-register.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface DriverRegisterFormProps {
  handleCloseRegister?: () => void;
  handleSelectCurrentLine?: (id: string) => void;
}

const DriverRegisterForm: React.FC<DriverRegisterFormProps> = ({ handleCloseRegister, handleSelectCurrentLine }) => {
  const navigate = useNavigate();
  const driverRegisterHook = useDriverRegisterHook();
  const cepHook = useCepHook();

  const goBackToDriverList = useCallback(() => navigate(PATH_TO_DRIVER_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (driver: any) => {
      if (!driver.cnhValidate) {
        showMessageFormatted({
          message: 'Preencha o Campo Validade CNH',
          type: 'error',
        });

        return;
      }
      if (!driver.issueDate) {
        showMessageFormatted({
          message: 'Preencha o Campo Data de Emissão',
          type: 'info',
        });

        return;
      }

      try {
        const id = await driverRegisterHook(driver);

        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });

        if (handleSelectCurrentLine && handleCloseRegister) {
          handleSelectCurrentLine(id);
          handleCloseRegister();
        } else {
          goBackToDriverList();
        }
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    },
    [driverRegisterHook, goBackToDriverList],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  const apiCallShipping = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return shippingCompanyFilterSearchService(searchValue);
    }
    return [];
  };

  return (
    <DriverRegisterFormView
      driverRegisterValues={initialValuesDriver}
      handleGoBack={goBackToDriverList}
      handleRegister={handleSubmit}
      onBlurCep={onBlurCep}
      shippingApiCall={apiCallShipping}
      isAllocation={!!(handleSelectCurrentLine && handleCloseRegister)}
    />
  );
};

export default DriverRegisterForm;
