import { ContactDetailsSectionActionType } from '../../registrations.action';
import { IContactDetailsSectionActions } from '../../types/contact-details-section.types';
import { IRegistrationsContext } from '../../types/registrations.types';

export const contactDetailsSectionReducer = (
  state: IRegistrationsContext,
  action: IContactDetailsSectionActions,
): IRegistrationsContext => {
  switch (action.type) {
    case ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_MODAL_OPEN:
      return {
        ...state,
        contactDetailsSection: {
          ...state.contactDetailsSection,
          isOpenModal: true,
        },
      };
    case ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_MODAL_CLOSE:
      return {
        ...state,
        contactDetailsSection: {
          ...state.contactDetailsSection,
          isOpenModal: false,
        },
      };
    case ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_RESET:
      return {
        ...state,
        contactDetailsSection: {
          ...state.contactDetailsSection,
        },
      };
    default:
      return state;
  }
};
