import API from 'utils/API/API';
import { convertShippingEntityToSelectItem } from 'pages/vehicle/mappers/convert-shipping-entity-to-select-item/convert-shipping-entity-to-select-item.mapper';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';

export const vehicleFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`v2/vehicles/search?name=${searchValue}`);

  return data.map((vehicle: { id: number; main_board: string; second_board: string; company_id: number }) => {
    const secondPlate = vehicle.second_board ? ` - ${vehicle.second_board}` : '';
    return convertShippingEntityToSelectItem(
      `${vehicle.main_board?.toUpperCase()} ${secondPlate.toUpperCase()}`,
      vehicle.id,
    );
  });
};
