import { FC, useCallback } from 'react'

import { FileListUploaded } from 'pages/freight/types'
import { useHandleDeleteMdfeUploadedListHook } from 'pages/freight/hooks'
import { showToast } from 'components/toast/toast'
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation'


interface Props {
  mdfeData: FileListUploaded
  isOpen: boolean
  onSuccess: () => void
  onClose: () => void
}

export const ModalDeleteUploadedMdfe: FC<Props> = ({
  mdfeData,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const handleDeleteMdfeUploaded = useHandleDeleteMdfeUploadedListHook()

  const handleDeleteMdfe = useCallback(async () => {
    try {
      await handleDeleteMdfeUploaded(mdfeData.id)
      onSuccess()
    } catch (error) {
      showToast({
        message: 'Erro ao deletar CTe',
        type: 'error',
      })
    }
  }, [mdfeData, handleDeleteMdfeUploaded, onSuccess])

  if (!isOpen) return null

  return (
    <ModalConfirmation
      secondTitle={
        <>
          <p>Tem certeza que deseja excluir o MDFe?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </>
      }
      title="Exclusão de MDFe"
      size="small"
      type="delete"
      handleCancel={onClose}
      onConfirmation={handleDeleteMdfe}
      description={undefined}
    />
  )
}
