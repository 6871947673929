import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { uploadImportFileService } from '../../services/upload-import-file/upload-import-file.service';
import { useQueryClient } from 'react-query';

export const useUploadImportFile = () => {
  const { scheduleList, scheduleImportModal } = useContext(ScheduleContext);
  const { setScheduleImportModalError, setScheduleImportModalRequest, setScheduleImportModalSuccess } =
    scheduleImportModal;
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (processingCode: number) => {
      try {
        setScheduleImportModalRequest();

        showMessageFormatted({
          message: 'Iniciando upload de dados.',
          type: 'info',
        });

        await uploadImportFileService(processingCode);

        showMessageFormatted({
          message: 'Upload dos dados realizado com sucesso.',
          type: 'success',
        });

        reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);

        setScheduleImportModalSuccess([]);
      } catch (error) {
        setScheduleImportModalError();

        showMessageFormatted({
          message: 'Erro ao realizar upload dos dados.',
          type: 'error',
        });
      }
    },
    [
      reactQueryClient,
      scheduleList.scheduleListFiltersProps.filters,
      setScheduleImportModalError,
      setScheduleImportModalRequest,
      setScheduleImportModalSuccess,
    ],
  );
};
