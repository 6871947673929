import React, { useMemo } from 'react';

import { CardResumeOriginAndDestinyInput, ILevyScheduleDetailsValues } from './types';
import * as S from './styled';

interface CardResumeCollectionProps {
  origin: CardResumeOriginAndDestinyInput;
  scheduleDetailsValues?: ILevyScheduleDetailsValues;
}

export const CardResumeCollection: React.FC<CardResumeCollectionProps> = ({ origin, scheduleDetailsValues }) => {
  const levyDate = useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { levyInitialDate, levyEndDate } = scheduleDetailsValues;

    if (levyInitialDate && levyEndDate) {
      const formattedLevyInitialDate = levyInitialDate !== 'null ' ? levyInitialDate : null;
      const formattedLevyEndDate = levyEndDate !== 'null ' ? levyEndDate : null;

      if (formattedLevyInitialDate && formattedLevyEndDate) {
        return `${formattedLevyInitialDate} - ${formattedLevyEndDate}`;
      }

      if (formattedLevyInitialDate) return formattedLevyInitialDate;

      if (formattedLevyEndDate) return formattedLevyEndDate;
    }

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.LineSection columns="0.5fr 2.5fr 8fr">
      <S.StepIconSection>
        <S.StepIcon />
        <S.Connector size={origin.tradeName ? '110' : '150'} />
      </S.StepIconSection>

      <S.Text fontWeight>Coleta</S.Text>

      {origin.tradeName ? (
        <S.TextContent uppercase>
          <S.Text fontWeight>{origin.tradeName}</S.Text>
          <S.Text>{`${origin.address.street} ${origin.address.numberHouse}`}</S.Text>
          <S.Text>{`${origin.address.neighborhood} - ${origin.address.city} - ${origin.address.uf}`}</S.Text>
          <S.Text>{origin.address.cep}</S.Text>
          <S.Text>{origin.cnpj}</S.Text>
          <S.Text>{levyDate}</S.Text>
        </S.TextContent>
      ) : (
        <S.TextContent italic>
          <S.Text>Não informado</S.Text>
        </S.TextContent>
      )}
    </S.LineSection>
  );
};
