import { FC } from 'react';
import { IModalBark } from './modal-bark.types';
import { ModalBarkView } from './modal-bark-view.component';

export const ModalBark: FC<IModalBark> = ({ size, title, children, handleClose, hiddenOverFlow }) => {
  return (
    <ModalBarkView size={size} title={title} handleClose={handleClose} hiddenOverFlow={hiddenOverFlow}>
      {children}
    </ModalBarkView>
  );
};
