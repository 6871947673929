import { IOccurrenceTypesList } from '../../../../domain/occurrence';

import API from 'utils/API/API';

export const occurrenceListTypesService = async (): Promise<IOccurrenceTypesList[]> => {
  try {
    const url = '/v2/occurrences/types';

    const { data } = await API.get(url);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar os tipos de ocorrência');
  }
};

export const getAllOccurrenceTypesService = async (): Promise<IOccurrenceTypesList[]> => {
  try {
    const url = '/v2/occurrences/ocurrence-types';

    const { data } = await API.get(url);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar os tipos de ocorrência');
  }
};


