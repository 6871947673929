import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateScheduleShippingAllocationService } from '../../services/update-schedule-shipping-allocation/update-schedule-shipping-allocation.service';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const useUpdateScheduleShippingAllocation = () => {
  const { scheduleList } = useContext(ScheduleContext);
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const reactQueryClient = useQueryClient();

  return useCallback(async () => {
    try {
      await updateScheduleShippingAllocationService(Number(scheduleId));

      showMessageFormatted({
        message: 'Transportadora removida com sucesso.',
        type: 'success',
      });

      // INVALIDATE QUERY DATA
      reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [reactQueryClient, scheduleId, scheduleList.scheduleListFiltersProps.filters]);
};
