import React, { FC, useCallback, useState } from 'react';
import { DeleteModalView } from './delete-modal-view.component';
import {
  IModalButtonProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';
import { useSaleOfferTariffListRemove } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/hooks/use-sale-offer-tariff-list-remove/use-sale-offer-tariff-list-remove.hook';

export interface IDeleteModalProps {
  handleCloseModal: () => void;
}

export const DeleteModal: FC<IDeleteModalProps> = ({ handleCloseModal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const saleOfferTariffRemoveHook = useSaleOfferTariffListRemove();

  const handleIsLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);

  const handleDeleteOfferList = useCallback(() => {
    saleOfferTariffRemoveHook(handleCloseModal, handleIsLoading);
  }, [handleCloseModal, handleIsLoading, saleOfferTariffRemoveHook]);

  const secondaryButtonProps: IModalButtonProps = {
    label: 'Voltar',
    handleActionButton: handleCloseModal,
  };
  const primaryButtonProps: IModalPrimaryButtonProps = {
    label: 'Excluir',
    isLoading: isLoading,
    handleActionButton: handleDeleteOfferList,
    color: 'red',
  };

  return <DeleteModalView primaryButtonProps={primaryButtonProps} secondaryButtonProps={secondaryButtonProps} />;
};
