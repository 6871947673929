import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import { IFreetimeResponse } from '../../get-freetime.types';

export const converterFreetimeResponseToDomain = (freeTimeResponse: IFreetimeResponse): IFreeTimeDomain => {
  const [hours] = freeTimeResponse.freeTime.split(':');
  const timeInHours = Number(hours);

  return {
    ...freeTimeResponse,
    freeTime: timeInHours,
  };
};
