import { Dispatch, SetStateAction, useCallback } from 'react';
import { driverDetailService } from 'pages/driver/services/driver-detail/driver-detail.service';
import dayjs from 'dayjs';
import { IDriverCreatedDomain } from 'src-new/pages/registrations/domains/driver.domain';
import { useDetailsTransportHook } from 'pages/transport-company/hooks/transport-details/transport-details.hook';
import { useGetRiskManagerList } from '../use-get-risk-manager-list/use-get-risk-manager-list.hook';
import { showToast } from 'components/toast/toast';

export const useGetDetailsDriver = () => {
  const detailShippingCompany = useDetailsTransportHook();
  const riskManagerList = useGetRiskManagerList();

  return useCallback(
    async (
      driverId: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      setIsError: Dispatch<SetStateAction<boolean>>,
      setDriverValues: Dispatch<SetStateAction<IDriverCreatedDomain>>,
      setShippingCompanyName: Dispatch<SetStateAction<string | undefined>>,
    ): Promise<void> => {
      try {
        const driverResponse = await driverDetailService(driverId);

        const driver: IDriverCreatedDomain = {
          ...driverResponse,
          certification: driverResponse.certification || [],
          createdAt: dayjs(driverResponse.createdAt).format('DD/MM/YYYY'),
          tac: driverResponse.tac || '',
        };

        if (driver.shippingCompanyId) {
          const shippingCompany = await detailShippingCompany(driver.shippingCompanyId);
          setShippingCompanyName(shippingCompany.name);
        }

        driver.riskManagers = await riskManagerList(Number(driverId));

        setDriverValues(driver);
      } catch (error) {
        showToast({
          message: 'Erro ao obter os detalhes do motorista.',
          type: 'error',
        });
        setIsError(true);
      }
      setIsLoading(false);
    },
    [detailShippingCompany, riskManagerList],
  );
};
