import React, { useCallback, useEffect, useState } from 'react';
import { IAccordion, IAccordionState } from './accordion.types';
import { AccordionView } from './accordion-view.component';

export const Accordion: React.FC<IAccordion> = ({ contents, indexToOpenAccordion }) => {
  const [accordionState, setAccordionState] = useState<IAccordionState>({
    index: undefined,
    isOpen: false,
  });

  const handleOpenAccordion = useCallback((index: number) => {
    setAccordionState({ index, isOpen: true });
  }, []);

  const handleCloseAccordion = useCallback(() => {
    setAccordionState({ index: undefined, isOpen: false });
  }, []);

  useEffect(() => {
    setAccordionState({ index: indexToOpenAccordion, isOpen: true });
  },[indexToOpenAccordion])

  return (
    <AccordionView
      contents={contents}
      accordionState={accordionState}
      openAccordion={handleOpenAccordion}
      closeAccordion={handleCloseAccordion}
    />
  );
};
