import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { createChecklistDetailsService } from 'services/checklist/create-checklist-details';
import { ChecklistAnswer, answerInitialValues } from 'domain-v2/checklist';
import { useQueryClient } from 'react-query';

export const useCreateChecklistDocuments = () => {
  const { scheduleDocuments } = useContext(ScheduleContext);
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (id: number, checklistAnswer: ChecklistAnswer) => {
      scheduleDocuments.checklistAnswerProps.setChecklistAnswerRequest();

      try {
        await createChecklistDetailsService(id, checklistAnswer);
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerSuccess(answerInitialValues);
        reactQueryClient.invalidateQueries(['get-checklist-documents']);
      } catch (error: any) {
        scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsError();
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleDocuments.checklistAnswerProps, scheduleDocuments.checklistDocumentsProps],
  );
};
