export function mapServiceEntityType(serviceType: string) {
  switch (serviceType) {
    case 'DEVOLUCAO_DE_PALETE':
      return 'Devolução de Palete';
    case 'DEVOLUCAO_DE_MERCADORIA':
      return 'Devolução de Mercadoria';
    case 'TRANSFERENCIA':
      return 'Transferência';
    case 'REPALETIZACAO':
      return 'Repaletização';
    case 'PALETIZACAO':
      return 'Paletização';
    case 'CROSSDOCKING':
      return 'Crossdocking';
    case 'DESLOCAMENTO':
      return 'Deslocamento';
    case 'COLETA':
      return 'Coleta';
    case 'REPALETIZACAO_SPOT':
      return 'Repaletização SPOT';
    case 'IMPORTADOS':
      return 'Importados';
    case 'FILME_STRECHING':
      return 'Filme Streching';
    case 'TROCA_DE_PALETE_QUEBRADO':
      return 'Troca de Palete Quebrado';
    default:
      return null;
  }
}
