export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const infoWindowKeys = [
  {
    key: 'Data e Hora',
    data: 'created_at',
  },
  {
    key: 'Latitude',
    data: 'lat',
  },
  {
    key: 'Longitude',
    data: 'lng',
  },
  {
    key: 'Velocidade',
    data: 'speed',
  },
];
