/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { StickerStyled, TitleStyled, WrapperStyled } from './status-circle-view.styled';

export type IStatus = {
  bgColor: string;
  isSquare?: boolean;
  title?: any;
};

const StatusView: React.FC<IStatus> = ({ bgColor = 'blue', isSquare, title }) => {
  return (
    <WrapperStyled>
      <StickerStyled bgColor={bgColor} isSquare={isSquare} />
      <TitleStyled>{title}</TitleStyled>
    </WrapperStyled>
  );
};

export default StatusView;
