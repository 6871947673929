/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { ContainerModalStyled } from './santos-port-list-body-view.styled';
import SantosPortListBodyView from './santos-port-list-body-view';

import { DownloadFile } from 'utils/download-file/download-file';
import PortSantosDetailForm from 'pages/santos-port/santos-port-details/santos-port-details-form/santos-port-details-form';
import { useSantosPortUpdateList } from 'pages/santos-port/hooks/use-santos-port-update-list/use-santos-port-update-list.hook';
import { useSantosPortList } from 'pages/santos-port/hooks/use-santos-port-list/use-santos-port-list.hook';
import { useReportExcelHook } from 'pages/santos-port/hooks/use-location-report-excel/use-location-report-excel.hook';
import { SantosPortContext } from 'pages/santos-port/contexts/table-states/santos-port.context';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const SantosPortListBody: React.FC = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Todos');
  const [currentPageLocations, setCurrentPageLocations] = useState(1);
  const santosPortListHook = useSantosPortList();
  const updateList = useSantosPortUpdateList();
  const GenerateExcelHook = useReportExcelHook();

  const [modalDetails, setModalDetails] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const { santosPort, santosPortIsLoading, santosPortPageInfo } = useContext(SantosPortContext);

  const handleUpdateSantosPortList = useCallback(async () => {
    try {
      updateList();
      showToast({
        type: 'success',
        message: 'Lista Atualizada',
      });
    } catch (error: any) {
      showToast({
        type: 'error',
        message: error.message,
      });
    }
  }, [updateList]);

  useEffect(() => {
    santosPortListHook({
      searchValue: searchInput,
      currentPage: currentPageLocations,
    });
  }, [currentPageLocations, searchInput, santosPortListHook]);

  useEffect(() => {
    setCurrentPageLocations(1);
  }, [activeTabName]);

  const goToDetailsPage = async (id: string) => {
    const description = (
      <ContainerModalStyled>
        <PortSantosDetailForm id={id} />
      </ContainerModalStyled>
    );
    setModalDetails({ description, open: true });
  };

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'PORTO DE SANTOS');
      }
    },
    [GenerateExcelHook, searchInput],
  );

  return (
    <>
      {modalDetails.open && (
        <ModalConfirmation
          title="Detalhes do Registros"
          description={modalDetails.description}
          handleCancel={() => setModalDetails((old) => ({ ...old, open: false }))}
          size="large"
          type="normal"
        />
      )}

      <SantosPortListBodyView
        santosPort={santosPort}
        hasLoading={santosPortIsLoading}
        santosPortPageInfo={santosPortPageInfo}
        setCurrentPageSantosPorts={setCurrentPageLocations}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        goToDetailsPage={goToDetailsPage}
        onHandleExport={handleGenerateExcel}
        handleUpdateSantosPortList={handleUpdateSantosPortList}
        options={Options}
      />
    </>
  );
};

export default SantosPortListBody;
