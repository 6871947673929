import styled, { css } from 'styled-components';

import { StickerProps } from './types/sticker-type';

export const ContainerStickerStyled = styled.div<StickerProps>`
  ${({ theme, bgColor, circle, width, isButton }) => css`
    background-color: ${bgColor ? theme.colors[bgColor] : 'transparent'};
    border-radius: ${circle ? '50%' : '50px'};
    height: ${circle ? '25px' : '20px'};
    width: ${circle ? '25px' : width || '50px'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${width ? '10px 15px' : '5px'};
    color: ${theme.colors.white};
    font-size: ${circle ? '15px' : '9px'};
    font-weight: 600;
    cursor: pointer;

    ${isButton &&
    css`
      border-radius: 3px;
      height: 20px;
      width: 40px;
    `}
  `}
`;

export const ContentSticker = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const Icon = styled.div`
  svg {
    width: 15px;
    height: 10px;
  }
`;
