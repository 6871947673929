import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/company/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { ICompanyStatus } from 'domain/company';

interface LocatioReportExcelServiceProps {
  search: string;
  status: ICompanyStatus | undefined;
}

export const CompanyReportExcelService = async ({
  search,
  status,
}: LocatioReportExcelServiceProps): Promise<BlobPart | undefined> => {
  try {
    const convertedStatus = status ? convertStatusDomainToEntityMapper(status) : status;

    const { data } = await API.get('v2/companies/export', {
      params: {
        status: convertedStatus,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar localidade');
  }
};
