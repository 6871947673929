import API from 'utils/API/API';
import { TransactionListParams } from 'pages-v2/transactions/types/list';
import { CalcFinanceList } from 'domain-v2/transactions';
import { PageInfoResponse } from 'domain-v2/inputs/page-info';

export const transactionListService = async (
  params: TransactionListParams,
): Promise<PageInfoResponse<CalcFinanceList>> => {
  const { data } = await API.get<PageInfoResponse<CalcFinanceList>>('v2/financial-calculation/transactions', {
    params: {
      ...params,
      limit: 100,
    },
  });

  return {
    items: data.items,
    meta: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};
