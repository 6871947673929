import API from 'utils/API/API';
import { IListPage } from 'src-new/domains/list-page.domain';
import { ITransactionDomain } from 'src-new/pages/financial/domains/transactions.domain';
import {
  IGetTransactionsListRequest,
  ITransactionResponse,
} from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/get-transactions-list.types';
import { convertTransactionResponseToDomain } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/mappers/convert-client-response-to-domain/convert-transaction-response-to-domain.mapper';

export const getTransactionsListService = async (
  params: IGetTransactionsListRequest,
): Promise<IListPage<ITransactionDomain>> => {
  const { data } = await API.get<IListPage<ITransactionResponse>>('v2/financial-calculation/transactions', {
    params,
  });

  return {
    items: data.items.map(convertTransactionResponseToDomain),
    meta: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};
