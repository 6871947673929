import styled from 'styled-components';

export const TableHeaderStyled = styled.p`
  font-size: 11px;
  margin-bottom 15px;
`;

export const TableInfoStyled = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const WrapperForm = styled.div`
  height: 30rem;
  overflow: auto;
`;

export const DateContainer = styled.div`
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
