import React from 'react';

import { ModalConfirmationTypes } from 'components/modal-confirmation/modal-confirmation.type';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import FinishedModalIcon from 'assets/icons/finished.icon';
import EmailModalIcon from 'assets/icons/email-modal.icon';

interface IProps {
  type: ModalConfirmationTypes;
}

export const ImageType: React.FC<IProps> = ({ type }) => {
  if (type === 'delete') {
    return <WarningDeleteIcon />;
  } else if (type === 'e-mail') {
    return <EmailModalIcon />;
  } else if (type === 'finished') {
    return <FinishedModalIcon />;
  } else {
    return <div></div>;
  }
};
