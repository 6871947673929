import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { ISchedulingLTL } from '../../../../../domain/scheduling';
import { IListPage } from '../../../../../domain/page-info';

import { ISchedulingsLTLContext } from './schedulings-ltl.types';
import { schedulingsReducer } from './schedulings-ltl.reducer';
import { initialState, ISchedulingsAction } from './schedulings-ltl.actions';

interface ISchedulingsLTLContextProps {
  children: ReactNode;
}

export const SchedulingsLTLContext = createContext<ISchedulingsLTLContext>(initialState);

const SchedulingsLTLContextProvider = ({ children }: ISchedulingsLTLContextProps) => {
  const [state, dispatch] = useReducer(schedulingsReducer, initialState);

  const setSchedulingsRequest = useCallback(() => {
    dispatch({
      type: ISchedulingsAction.SCHEDULINGS_REQUEST,
      payload: null,
    });
  }, []);

  const setSchedulingsSuccess = useCallback((schedulingList: IListPage<ISchedulingLTL>) => {
    dispatch({
      type: ISchedulingsAction.SCHEDULINGS_SUCCESS,
      payload: schedulingList,
    });
  }, []);

  const setSchedulingsError = useCallback((error: string) => {
    dispatch({
      type: ISchedulingsAction.SCHEDULINGS_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      schedulingsIsLoading: state.schedulingsIsLoading,
      schedulings: state.schedulings,
      schedulingsError: state.schedulingsError,
      schedulingsPageInfo: state.schedulingsPageInfo,
      statusCode: state.statusCode,
      setSchedulingsRequest,
      setSchedulingsSuccess,
      setSchedulingsError,
    };
  }, [
    setSchedulingsError,
    setSchedulingsRequest,
    setSchedulingsSuccess,
    state.schedulings,
    state.schedulingsError,
    state.schedulingsIsLoading,
    state.schedulingsPageInfo,
    state.statusCode,
  ]);

  return <SchedulingsLTLContext.Provider value={value}>{children}</SchedulingsLTLContext.Provider>;
};

export default React.memo(SchedulingsLTLContextProvider);
