import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { SectionDivider } from 'src-new/components/section-divider/section-divider.component';
import { ContainerStateStyled, SectionContentStyled, SpinnerModal } from './attach-documents-section-view.styled';
import { AttachDocumentsTable } from './components/attach-documents-table/attach-documents-table.component';
import AddIcon from '@mui/icons-material/Add';
import { AttachDocumentsModal } from './components/attach-documents-modal/attach-documents-modal.component';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IAttachDocumentsSectionViewProps {
  isOpenModal: boolean;
  handleOpenAttachDocumentsModal: () => void;
  isLoading: boolean;
  isError: boolean;
}

export const AttachDocumentsSectionView: React.FC<IAttachDocumentsSectionViewProps> = ({
  handleOpenAttachDocumentsModal,
  isOpenModal,
  isLoading,
  isError,
}) => {
  const renderContent = useCallback((arrayHelpers: FieldArrayRenderProps) => {
    return <AttachDocumentsTable arrayHelpers={arrayHelpers} />;
  }, []);

  const renderModal = useCallback(
    (arrayHelpers: FieldArrayRenderProps): ReactElement | undefined => {
      if (isOpenModal) {
        return <AttachDocumentsModal arrayHelpers={arrayHelpers} />;
      }
    },
    [isOpenModal],
  );

  const renderContentSection = useMemo(() => {
    if (isLoading) {
      return (
        <ContainerStateStyled>
          <SpinnerModal />
        </ContainerStateStyled>
      );
    }

    if (isError) {
      return <ErrorState />;
    }

    return (
      <FieldArray
        name="attachments"
        render={(arrayHelpers) => (
          <Fragment>
            <SectionContentStyled name="Anexos">{renderContent(arrayHelpers)}</SectionContentStyled>
            {renderModal(arrayHelpers)}
          </Fragment>
        )}
      />
    );
  }, [isError, isLoading, renderContent, renderModal]);

  return (
    <Fragment>
      <SectionDivider
        label="Anexos"
        hidePin
        secondItem={
          <Button
            label=""
            color={ThemeColorsV2Type.Blue}
            size="small"
            variant="solid"
            onPress={handleOpenAttachDocumentsModal}
            LeftIcon={<AddIcon fontSize="small" style={{ color: '#fff' }} />}
          />
        }
      />
      {renderContentSection}
    </Fragment>
  );
};
