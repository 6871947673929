import { scheduleListFiltersInitialValues } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters-initial-values.types';
import { ScheduleListActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';
import { IScheduleListActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { totalRowInitialValues } from 'src-new/pages/execution/pages/schedule/types/schedule-list-total-row/schedule-list-total-row-initial-values.constants';

export const scheduleListReducer = (state: IScheduleContext, action: IScheduleListActions): IScheduleContext => {
  switch (action.type) {
    case ScheduleListActionType.SCHEDULE_LIST_REQUEST:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          statusRequest: 'PROCESSING',
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          statusRequest: 'SUCCESS',
          scheduleList: action.payload,
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_ERROR:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          statusRequest: 'ERROR',
          scheduleList: [],
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_VIEW_MODE:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          viewModeProps: {
            ...state.scheduleList.viewModeProps,
            listViewMode: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_MODALS:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          modalsProps: {
            ...state.scheduleList.modalsProps,
            modalOptions: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_FILTERS:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          scheduleListFiltersProps: {
            ...state.scheduleList.scheduleListFiltersProps,
            filters: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_SAVED_FILTERS:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          scheduleSavedFiltersProps: {
            ...state.scheduleList.scheduleSavedFiltersProps,
            savedFilters: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_CHECKBOX:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          scheduleListCheckbox: {
            ...state.scheduleList.scheduleListCheckbox,
            checkbox: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_TOTAL_ROW:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          scheduleListTotalRowProps: {
            ...state.scheduleList.scheduleListTotalRowProps,
            totalRow: action.payload,
          },
        },
      };
    case ScheduleListActionType.SCHEDULE_LIST_RESET:
      return {
        ...state,
        scheduleList: {
          ...state.scheduleList,
          statusRequest: 'INITIAL',
          scheduleList: [],
          viewModeProps: {
            ...state.scheduleList.viewModeProps,
            listViewMode: 'LIST',
          },
          modalsProps: {
            ...state.scheduleList.modalsProps,
            modalOptions: { open: false, ids: [], modalType: '' },
          },
          scheduleListFiltersProps: {
            ...state.scheduleList.scheduleListFiltersProps,
            filters: scheduleListFiltersInitialValues,
          },
          scheduleSavedFiltersProps: {
            ...state.scheduleList.scheduleSavedFiltersProps,
            savedFilters: [],
          },
          scheduleListTotalRowProps: {
            ...state.scheduleList.scheduleListTotalRowProps,
            totalRow: totalRowInitialValues,
          },
        },
      };
    default:
      return state;
  }
};
