import { ModalMatchesKanbanActionType } from '../../matches.action';
import { IMatchesContext } from '../../types/matches.types';
import { IModalMatchesKanbanActions } from '../../types/modal-matches-kanban.types';

export const modalMatchesKanbanReducer = (
  state: IMatchesContext,
  action: IModalMatchesKanbanActions,
): IMatchesContext => {
  switch (action.type) {
    case ModalMatchesKanbanActionType.MODAL_MATCHES_KANBAN:
      return {
        ...state,
        modalMatchesKanban: {
          ...state.modalMatchesKanban,
          modalMatchesOptions: action.payload,
        },
      };
    default:
      return state;
  }
};
