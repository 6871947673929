import React, { useContext, useMemo } from 'react';
import * as S from './weighing-totten-modal-checkin.styled';
import { WeighingTottenButton } from 'src-new/mobile/pages/weighing-totten/components/weighing-totten-button/weighing-totten-button.component';
import { ModalBark } from '../../../../../components/weighing-totten-modal/weighing-totten-modal.component';
import SucessMessage from 'src/assets/sucess-message.svg';
import dayjs from 'dayjs';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';
import { formatPhoneNumber } from 'src-new/utils/convert-phone-number-format.util';

export const WeighingTottenModalCheckinView: React.FC = () => {
  const { weighingTottensDetails } = useContext(WeighingTottensContext);
  const { schedule, driver } = weighingTottensDetails.weighingTottensDetails;

  const checkingDateFormated = useMemo(() => dayjs(new Date()).format('DD/MM/YYYY HH:mm'), []);

  const collectionOrder = useMemo(() => schedule.id, [schedule.id]);

  const buildMessage = useMemo(
    () => (
      <>
        Por favor aguarde ser chamado no telefone:
        <br />
        <strong>{formatPhoneNumber(driver.phone)}</strong>
        Caso o telefone informado não esteja correto, por favor dirija-se a Central.
      </>
    ),
    [driver.phone],
  );

  const messageCheckinSuccess = useMemo(
    () => (
      <>
        <p>
          Check-In da <strong>Ordem de Coleta {collectionOrder}</strong> realizado com sucesso!
        </p>
        <p>{checkingDateFormated}</p>
        <br />
        {buildMessage}
      </>
    ),
    [buildMessage, checkingDateFormated, collectionOrder],
  );

 

  return (
    <ModalBark>
      <S.Wrapper>
        <S.Text>Pronto!</S.Text>
        {messageCheckinSuccess}
        <S.Icon src={SucessMessage} alt="" />
        <WeighingTottenButton callback={() => window.location.reload()}>Tela Inicial</WeighingTottenButton>
      </S.Wrapper>
    </ModalBark>
  );
};
