import React, { createContext, useMemo, useReducer } from 'react';
import { scheduleInitialState } from './schedule.constants';
import { scheduleReducer } from './reducers/schedule.reducer';
import { IScheduleContextProps } from './schedule.types';
import { IScheduleContext } from './types/schedule.types';
import { useScheduleListContextValues } from 'src-new/pages/execution/pages/schedule/contexts/schedule/context-values/schedule-list/schedule-list-values.context';
import { IScheduleList } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { IScheduleDetails } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import { useScheduleDetailsContextValues } from 'src-new/pages/execution/pages/schedule/contexts/schedule/context-values/schedule-details/schedule-details-values.context';
import { IScheduleKanbanList } from './types/schedule-kanban.types';
import { useScheduleKanbanListContextValues } from './context-values/schedule-kanban-list/schedule-kanban-list-values.context';
import { useScheduleExecutionContextValues } from 'src-new/pages/execution/pages/schedule/contexts/schedule/context-values/schedule-execution/schedule-execution-values.context';
import { IScheduleExecution } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';
import { IScheduleDocuments } from './types/schedule-documents';
import { useScheduleDocumentsContextValues } from './context-values/schedule-documents/schedule-documents.context';
import { IScheduleRequestStatus } from './types/schedule-request-status.types';
import { useScheduleRequestStatusContextValues } from './context-values/schedule-request-status/schedule-request-status.context';
import { IScheduleOccurrenceList } from './types/schedule-occurrence.types';
import { useScheduleOccurrenceListContextValues } from './context-values/schedule-occurrence-list/schedule-occurrence-list.context';
import { useScheduleListInitialLoadingContextValues } from 'src-new/pages/execution/pages/schedule/contexts/schedule/context-values/schedule-list-initial-loading/schedule-list-initial-loading-values.context';
import { IScheduleListInitialLoading } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list-initial-loading.types';
import { IScheduleImportModal } from './types/schedule-import-modal.types';
import { useScheduleImportModalContextValues } from './context-values/schedule-import-modal/schedule-import-modal.context';
import { IScheduleDownloadMapReport } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-download-map-report.types';
import { useScheduleDownloadMapReportContextValues } from 'src-new/pages/execution/pages/schedule/contexts/schedule/context-values/schedule-download-map-report/schedule-download-map-report-values.context';

export const ScheduleContext = createContext<IScheduleContext>(scheduleInitialState);

export const ScheduleContextProvider = ({ children }: IScheduleContextProps) => {
  const [state] = useReducer(scheduleReducer, scheduleInitialState);

  const scheduleList: IScheduleList = useScheduleListContextValues();
  const scheduleKanbanList: IScheduleKanbanList = useScheduleKanbanListContextValues();
  const scheduleOccurrenceList: IScheduleOccurrenceList = useScheduleOccurrenceListContextValues();
  const scheduleDetails: IScheduleDetails = useScheduleDetailsContextValues();
  const scheduleExecution: IScheduleExecution = useScheduleExecutionContextValues();
  const scheduleDocuments: IScheduleDocuments = useScheduleDocumentsContextValues();
  const scheduleRequestStatus: IScheduleRequestStatus = useScheduleRequestStatusContextValues();
  const scheduleListInitialLoading: IScheduleListInitialLoading = useScheduleListInitialLoadingContextValues();
  const scheduleImportModal: IScheduleImportModal = useScheduleImportModalContextValues();
  const scheduleDownloadMapReport: IScheduleDownloadMapReport = useScheduleDownloadMapReportContextValues();

  const value: IScheduleContext = useMemo(
    (): IScheduleContext => ({
      ...state,
      scheduleList,
      scheduleKanbanList,
      scheduleOccurrenceList,
      scheduleDetails,
      scheduleExecution,
      scheduleDocuments,
      scheduleRequestStatus,
      scheduleListInitialLoading,
      scheduleImportModal,
      scheduleDownloadMapReport,
    }),
    [
      scheduleDetails,
      scheduleDocuments,
      scheduleExecution,
      scheduleImportModal,
      scheduleKanbanList,
      scheduleList,
      scheduleListInitialLoading,
      scheduleOccurrenceList,
      scheduleRequestStatus,
      scheduleDownloadMapReport,
      state,
    ],
  );

  return <ScheduleContext.Provider value={value}>{children}</ScheduleContext.Provider>;
};
