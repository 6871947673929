import API from 'utils/API/API';
import { convertTableFreightEntityToDomainMapper } from 'pages/table-freight/mappers/convert-santos-port-entity-to-domain/table-freight-entity-to-domain.mapper';
import { ITableFreight } from 'domain/table-freight';
import { IListPage } from 'domain/page-info';

export interface ITableFreightListProps {
  search?: string;
  page: number;
}

const LIMIT_PAGE = 50;

export const tableFreightListService = async ({ page }: ITableFreightListProps): Promise<IListPage<ITableFreight>> => {
  try {
    const url = `v2/freight?page=${page}&limit=${LIMIT_PAGE}`;

    const { data } = await API.get(url);

    const { items, meta } = data;
    return {
      list: items.map(convertTableFreightEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erros ao lista a tabela de fretes');
  }
};
