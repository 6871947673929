import { useFormikContext } from 'formik';
import { FC } from 'react';
import { WeighingFormMountItemsView } from './weighing-list-form-mount-items-view.component';
import { IWeighingListFilterDomain } from '../../domains/weighing-list-filters/weighing-list-filters.domain';

export const WeighingFormMountItems: FC = () => {
  const { setFieldValue, values } = useFormikContext<IWeighingListFilterDomain>();

  return <WeighingFormMountItemsView setFieldValue={setFieldValue} values={values}/>;
};
