import { ILoginDemo } from './entities/login.entity';

import API from 'utils/API/API';

export const loginDemo = async (values: ILoginDemo) => {
  const phone = `${values.phone.replace(/\D/g, '')}`;
  return await API.post('v2/users/request', {
    name: values.name,
    email: values.email,
    phone: phone,
  });
};
