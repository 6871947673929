import React from 'react';

import DashboardContent from '../dashboard-content/dashboard-content';

import { ContainerTitle } from './dashboard-body.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const DashboardBodyView: React.FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Dashboard', 'Relatório de Performance']} />
      <ContainerTitle>
        <Title title="Acompanhe os resultados de suas operações." hasTrace />
      </ContainerTitle>
      <DashboardContent />
    </>
  );
};

export default DashboardBodyView;
