import React, { ReactElement, useMemo } from 'react';
import * as S from './bidding-offer-proposed-value-view.styled';
import { ThemeColorsV2Type } from 'styles/types/colors';
import TruckLoadingIcon from 'assets/icons/truck-loading.icon';
import { InputCurrency } from 'components/input-currency/input-currency';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import TooltipComponent from 'components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { IBiddingOfferForm } from '../../types/bidding-offer-form/bidding-offer-form.types';

export interface IAcceptancePotential {
  color: ThemeColorsV2Type;
  text: string;
}

export interface ITotalFreight {
  total: number;
  totalService: number;
  icmsValue: number;
  grisAdvalorem: number;
}

interface IBiddingOfferProposedValueViewProps {
  values: IBiddingOfferForm;
  isLoading: boolean;
  logshareFee: number;
  totalFreight: ITotalFreight;
  acceptancePotential: IAcceptancePotential;
  handleChange: (value?: string, name?: string) => void;
}
export const BiddingOfferProposedValueView: React.FC<IBiddingOfferProposedValueViewProps> = ({
  values,
  isLoading,
  logshareFee,
  totalFreight,
  acceptancePotential,
  handleChange,
}) => {
  const renderValueProposed = useMemo((): ReactElement | undefined => {
    if (values.isSaleOffer) return;

    if (isLoading) {
      return (
        <S.ContainerLoading>
          <TruckLoadingIcon />
        </S.ContainerLoading>
      );
    }

    if (totalFreight.totalService !== 0) {
      return (
        <S.HeaderValueProposed>
          <S.TextAcceptancePotential>BASEADO NO VALOR HISTÓRICO DO FRETE TRANSACIONADO</S.TextAcceptancePotential>

          <S.ContainerAcceptancePotential>
            O <S.TextBoldAcceptancePotential>FRETE PROPOSTO</S.TextBoldAcceptancePotential> APRESENTA
            <S.TagAcceptancePotential color={acceptancePotential.color}>
              {acceptancePotential.text}
            </S.TagAcceptancePotential>
            DE ACEITE.
          </S.ContainerAcceptancePotential>
        </S.HeaderValueProposed>
      );
    }
  }, [acceptancePotential, isLoading, totalFreight.totalService, values.isSaleOffer]);

  return (
    <S.Wrapper>
      <S.WrapperValueProposed>{renderValueProposed}</S.WrapperValueProposed>

      <S.WrapperContent>
        <S.Content>
          <S.Div>
            <S.TitleContainer>
              <S.TitleText>Informe o</S.TitleText>
              <S.TitleText isBold>Frete Proposto</S.TitleText>
            </S.TitleContainer>

            <InputCurrency
              id="proposedValue"
              name="proposedValue"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={handleChange}
              value={values.proposedValue?.toFixed(2)}
            />

            <S.Info>Sem ICMS e pedágio</S.Info>
          </S.Div>

          <S.ContainerValues>
            <S.Label>Frete Valor</S.Label>
            <S.Label>{convertCurrencyFormat.format(values.proposedValue)}</S.Label>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.TooltipContent>
              <S.Label>Taxa LogShare</S.Label>

              <TooltipComponent
                title="PARA TRANSPORTADORAS NÃO HOMOLOGADAS, COBRAMOS UM PERCENTUAL DE 10% EM CIMA DO VALOR PROPOSTO. JÁ PARA HOMOLOGADAS UMA TAXA FIXA DE R$62,00."
                location="right"
                icon={
                  <S.TooltipIcon>
                    <InfoIcon sx={{ height: '11px', width: '11px', color: 'white' }} />
                  </S.TooltipIcon>
                }
              />
            </S.TooltipContent>

            <S.Label>{convertCurrencyFormat.format(logshareFee)}</S.Label>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.Label>Pedágio</S.Label>
            <S.SubTitle>A INCLUIR</S.SubTitle>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.Label>Gris/Advalorem</S.Label>
            <S.Label>{convertCurrencyFormat.format(totalFreight.grisAdvalorem)}</S.Label>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.Title>Frete Total</S.Title>
            <S.Title>{convertCurrencyFormat.format(totalFreight.total)}</S.Title>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.Label>ICMS</S.Label>
            <S.Label>{convertCurrencyFormat.format(totalFreight.icmsValue)}</S.Label>
          </S.ContainerValues>

          <S.ContainerValues>
            <S.TooltipContent>
              <S.Title>Total a Receber</S.Title>

              <TooltipComponent
                title="O CTE SERÁ EMITIDO COM ESSE VALOR."
                location="right"
                icon={
                  <S.TooltipIcon>
                    <InfoIcon sx={{ height: '11px', width: '11px', color: 'white' }} />
                  </S.TooltipIcon>
                }
              />
            </S.TooltipContent>

            <S.Title>{convertCurrencyFormat.format(totalFreight.totalService)}</S.Title>
          </S.ContainerValues>
        </S.Content>
      </S.WrapperContent>
    </S.Wrapper>
  );
};
