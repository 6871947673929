/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { dateKeys } from '../utils/filter/date';
import { BiddingFilter, getBiddingsList } from '../services/list-biddings';
import { convertField } from '../mappers/filter';
import { useFilter } from '../context';
import BiddingFilterForm from '../components/filter';

import * as S from './styled';
import { ListBiddingsTable } from './content-table/list';
import { hiddenFilters } from 'utils-v2/filters/hidden-filters';
import { normalizeDate } from 'utils-v2/converters/date/normalize-date';
import { initialPageInfo } from 'domain-v2/inputs/page-info';
import { BiddingsList } from 'domain-v2/bidding/list';
import { FooterPage } from 'components-v2/common/footer-page';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import TooltipComponent from 'components/Tooltip';
import { Button } from 'logshare-ui-kit';

interface ParamsArray {
  key: string;
  value: string | number;
}

const initialListState = [] as Array<BiddingsList>;

export const List: React.FC = () => {
  const { filters, resetFilters, updateFilters } = useFilter();
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const FilterFormRef = useRef<FormikProps<BiddingFilter>>(null);
  const { isLoading, data } = useQuery({
    queryKey: ['biddingList', filters],
    queryFn: () => getBiddingsList(filters),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const filterChip: ParamsArray[] = useMemo(() => {
    const entries = Object.entries(filters).map(([key, value]) => ({
      key,
      value: convertField(key, value),
    }));

    const filteredParams = entries.filter(({ key, value }) => !hiddenFilters.includes(key) && value);

    const convertedDateParams = filteredParams.map(({ key, value }) => {
      if (dateKeys.includes(key)) {
        return { key, value: moment(value).format('DD/MM/YYYY') };
      }

      return { key, value };
    });

    return convertedDateParams;
  }, [filters]);

  const handleSearch = (search: string) => {
    if (search) updateFilters({ ...filters, search, page: 1 });
  };

  const handlePaginate = (page: number) => {
    updateFilters({ ...filters, page });
  };

  const onFilterSubmit = (values: BiddingFilter) => {
    const { createdAt, collectDate } = values;

    updateFilters({
      ...values,
      page: 1,
      createdAt: normalizeDate(createdAt),
      collectDate: normalizeDate(collectDate),
    });

    setModalFilter(false);
  };

  const removeFilterChip = (indexToRemove: number) => {
    const updatedParamsArray = [...filterChip];
    updatedParamsArray.splice(indexToRemove, 1);

    if (updatedParamsArray.length === 0) {
      resetFilters(false);

      return;
    }

    const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
      accumulator[key as keyof ParamsArray] = filters[key as keyof BiddingFilter];
      return accumulator;
    }, {});

    updateFilters({
      ...updatedParams,
      page: 1,
    });
  };

  return (
    <>
      {modalFilter && (
        <ModalConfirmation
          title="Escolha o Filtro"
          description={
            <Formik innerRef={FilterFormRef} initialValues={filters} onSubmit={onFilterSubmit}>
              <BiddingFilterForm />
            </Formik>
          }
          handleXCancel={() => setModalFilter(false)}
          fullButtonWidth
          isRight
          legacy
          dateIndex
          size="large"
          type="normal"
        />
      )}

      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pela Unidade, Parceiro ou Cidade"
          type="text"
          changeValue={handleSearch}
          filters={filterChip.map((item) => item.value)}
          handleClearFilters={removeFilterChip}
          isClear={false}
        />

         <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => setModalFilter(true)}
              LeftIcon={<FilterAltIcon sx={{ width: 22 }} />}
            />
          }
          title={'Filtrar'}
          location={'bottom'}
        />
      </S.ContainerSearch>

      <ListBiddingsTable
        biddings={data?.items || initialListState}
        pageInfo={data?.meta || initialPageInfo}
        isLoading={isLoading}
        handlePaginate={handlePaginate}
        orderSelected={{
          orderBy: filters.column,
          sortDirection: filters.order,
        }}
        clickOrderTableBy={(column) => {
          updateFilters({
            ...filters,
            column,
            order: filters.order === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        clickSortDirection={() => null}
      />

      <FooterPage.Root />
    </>
  );
};
