import React, { useContext, useEffect, useMemo } from 'react';
import * as S from './execution-time-recording-edit-modal-view.styles';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import {
  IScheduleDetailsComplementDomain,
  IScheduleDetailsDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { Button } from 'logshare-ui-kit';
import { Loading } from 'src-new/components/loading/loading.component';
import { UserContext } from 'state/user-context';

interface ITimeRecordingModalViewProps {
  values: IScheduleDetailsComplementDomain;
  scheduleDetails: IScheduleDetailsDomain;
  isLoading: boolean;
  lotsUser: boolean;
  handleFieldValue: (field: string, date: dayjs.Dayjs | null) => void;
  handleFinish: () => void;
  handleSubmit: () => void;
}

export const TimeRecordingModalView: React.FC<ITimeRecordingModalViewProps> = ({
  values,
  scheduleDetails,
  isLoading,
  lotsUser,
  handleFieldValue,
  handleFinish,
  handleSubmit,
}) => {
  const { isAdmin } = useContext(UserContext);

  const isEdited = useMemo(() => {
    if (scheduleDetails.operation === 'TRACKING') return false;
    if (lotsUser) return false;
    return !isAdmin;
  }, [isAdmin, lotsUser, scheduleDetails.operation]);

  useEffect(() => {
    if (values.acceptDate && values.startDate) {
      if (dayjs(values.acceptDate).isBefore(values.startDate)) return;
      handleFieldValue('startDate', dayjs(values.acceptDate));
    }
  }, [handleFieldValue, values.acceptDate, values.startDate]);

  useEffect(() => {
    if (values.startDate && values.checkinDate) {
      if (dayjs(values.startDate).isBefore(values.checkinDate)) return;
      handleFieldValue('checkinDate', dayjs(values.startDate));
    }
  }, [handleFieldValue, values.checkinDate, values.startDate]);

  useEffect(() => {
    if (values.checkinDate && values.checkoutDate) {
      if (dayjs(values.checkinDate).isBefore(values.checkoutDate)) return;
      handleFieldValue('checkoutDate', dayjs(values.checkinDate));
    }
  }, [handleFieldValue, values.checkinDate, values.checkoutDate]);

  useEffect(() => {
    if (values.checkoutDate && values.loadDate) {
      if (dayjs(values.checkoutDate).isBefore(values.loadDate)) return;
      handleFieldValue('loadDate', dayjs(values.checkoutDate));
    }
  }, [handleFieldValue, values.checkoutDate, values.loadDate]);

  useEffect(() => {
    if (values.finishDate && values.loadDate) {
      if (dayjs(values.loadDate).isBefore(values.finishDate)) return;
      handleFieldValue('finishDate', dayjs(values.loadDate));
    }
  }, [handleFieldValue, values.finishDate, values.loadDate]);

  if (isLoading) {
    return (
      <S.StateContainer>
        <Loading />
      </S.StateContainer>
    );
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header style={{ marginTop: 20 }}>
          Data e Hora Inicial
          <S.LineSeparator />
        </S.Header>
        <S.LineSection columns="1fr 1fr 1fr">
          <DateTimePikers
            label="Aceite do Motorista"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.acceptDate ?? '')}
            minDate={dayjs(values.allocationVehicleDate ?? values.allocationDriverDate ?? '')}
            onChange={(date) => handleFieldValue('acceptDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Início da Viagem para Carregamento"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.startDate ?? '')}
            minDate={dayjs(values.acceptDate ?? '')}
            onChange={(date) => handleFieldValue('startDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Chegada para Carregamento"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.arrivalLoadingDate ?? '')}
            minDate={dayjs(values.startDate ?? '')}
            onChange={(date) => handleFieldValue('arrivalLoadingDate', date)}
            disabled={isEdited}
          />
        </S.LineSection>

        <S.Header style={{ marginTop: 20 }}>
          Data e Hora da Coleta
          <S.LineSeparator />
        </S.Header>
        <S.LineSection columns="1fr 1fr 1fr">
          <DateTimePikers
            label="Chegada para Coleta"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.checkinDate ?? '')}
            minDate={dayjs(values.arrivalLoadingDate ?? '')}
            onChange={(date) => handleFieldValue('checkinDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Coleta Finalizada"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.checkoutDate ?? '')}
            minDate={dayjs(values.checkinDate ?? '')}
            onChange={(date) => handleFieldValue('checkoutDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Início da Viagem para Descarga"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.startTripUnloadingDate ?? '')}
            minDate={dayjs(values.checkoutDate ?? '')}
            onChange={(date) => handleFieldValue('startTripUnloadingDate', date)}
            disabled={isEdited}
          />
        </S.LineSection>

        <S.Header style={{ marginTop: 20 }}>
          Data e Hora da Descarga
          <S.LineSeparator />
        </S.Header>
        <S.LineSection columns="1fr 1fr 1fr">
          <DateTimePikers
            label="Chegada para Descarga"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.arrivalUnloadingDate ?? '')}
            minDate={dayjs(values.startTripUnloadingDate ?? '')}
            onChange={(date) => handleFieldValue('arrivalUnloadingDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Inicio da Descarga"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.loadDate ?? '')}
            minDate={dayjs(values.arrivalUnloadingDate ?? '')}
            onChange={(date) => handleFieldValue('loadDate', date)}
            disabled={isEdited}
          />
          <DateTimePikers
            label="Descarga Finalizada"
            format=""
            views={['year', 'month', 'day', 'hours', 'minutes']}
            value={dayjs(values.finishDate ?? '')}
            minDate={dayjs(values.loadDate ?? '')}
            onChange={(date) => handleFieldValue('finishDate', date)}
            disabled={isEdited}
          />
        </S.LineSection>

        <S.ContainerButton>
          <Button
            label="Salvar e Finalizar"
            color="red"
            size="md"
            onPress={handleFinish}
            variant="solid"
            disabled={isEdited}
          />
          <Button label="Salvar" color="blue" size="md" onPress={handleSubmit} variant="solid" disabled={isEdited} />
        </S.ContainerButton>
      </S.Container>
    </S.Wrapper>
  );
};
