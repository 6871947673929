import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { IIdlenessBiddingForm } from './types/form';
import { BiddingTable } from './components/list/list';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { transportDetailsService } from 'pages/transport-company/services/transport-details/transport-details.service';
import { createIdlenessBiddingService } from 'pages/home/services/bidding/create-bidding/create-bidding.service';
import { ScheduleDomain } from 'domain/schedule';
import { IIdlenessBiddingCreate } from 'domain/bidding';
import { IdlenessBiddingForm } from 'components-v2/idleness-bidding/modal-form';
import { showToast } from 'components/toast/toast';

type BiddingProps = {
  idlenessList?: {
    items: any;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };
  isLoading: boolean;
  handlePageChange: (page: number) => void;
};

const initialValues: IIdlenessBiddingForm = {
  idlenessId: 0,
  proposalValue: 0,
  value: 0,
  termsId: 0,
};

const BiddingList: React.FC<BiddingProps> = ({ idlenessList, isLoading, handlePageChange }) => {
  const { user } = useContext(UserContext);

  const [idleness, setIdleness] = useState<ScheduleDomain[]>();
  const [formBidding, setFormBidding] = useState<{
    isOpen: boolean;
    idlenessId: number;
  }>({
    isOpen: false,
    idlenessId: 0,
  });
  const biddingFormRef = useRef<FormikProps<IIdlenessBiddingCreate>>(null);

  useEffect(() => {
    if (!idlenessList?.items) return;

    getCompanyImage();
  }, [idlenessList]);

  const contentTable = useMemo(() => {
    return (
      <BiddingTable
        idleness={idleness ?? idlenessList?.items}
        openModal={setFormBidding}
        handlePageChange={handlePageChange}
        activePage={idlenessList?.meta.currentPage || 0}
        totalPages={idlenessList?.meta.totalPages || 0}
        isLoading={isLoading}
      />
    );
  }, [idleness, idlenessList]);

  const getShippingCompany = useMemo(async () => {
    return await transportDetailsService(user?.shippingCompanyId || 0);
  }, [user]);

  const getCompanyImage = useCallback(async () => {
    const promises = idlenessList?.items.map(async (idleness: ScheduleDomain) => {
      if (user?.profile === 'shipping-company') {
        const findShippingCompany = await getShippingCompany;

        if (findShippingCompany.company.id !== idleness?.companyId) {
          return {
            ...idleness,
            companyName: 'CONFIDENCIAL',
            companyLogo: '',
          };
        }

        const companyUserImage = await companyDetailsService(idleness.companyId);
        return {
          ...idleness,
          companyName: companyUserImage.tradeName,
          companyLogo: companyUserImage.fileKey,
        };
      }

      return {
        ...idleness,
        companyName: 'LOGSHARE',
      };
    });

    const resolvedPromises = await Promise.all(promises);

    setIdleness(resolvedPromises);
  }, [idlenessList]);

  const handleCreateBidding = useCallback(async (values: IIdlenessBiddingCreate) => {
    try {
      await createIdlenessBiddingService({
        ...values,
        value: Number(values.value),
        proposalValue: converterValue(values.proposalValue),
      });
      showToast({
        message: 'Oferta enviada com sucesso',
        type: 'success',
      });
      setFormBidding((old) => ({ ...old, isOpen: false }));
    } catch (error: any) {
      showMessageFormatted({
        message: error.response.data.message || error.message,
        type: 'error',
      });
    }
  }, []);

  const converterValue = (value: number) => {
    const valueConverted = value.toString().replace(',', '.');

    return Number(valueConverted);
  };

  return (
    <Fragment>
      {formBidding.isOpen && (
        <Formik
          innerRef={biddingFormRef}
          initialValues={initialValues}
          onSubmit={(values) =>
            handleCreateBidding({
              ...values,
              value: converterValue(values.value),
            })
          }
          enableReinitialize
        >
          <IdlenessBiddingForm
            idlenessId={formBidding.idlenessId}
            closeModal={() => setFormBidding((old) => ({ ...old, isOpen: false }))}
          />
        </Formik>
      )}
      {contentTable}
    </Fragment>
  );
};

export default BiddingList;
