import styled from 'styled-components';

interface IStyledText {
  fontSize?: number;
  color?: string;
}

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 1.5rem 1rem;
  border-radius: 0.75rem;
`;

export const Div = styled.div`
  width: 100%;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 15px;
`;

export const TitleStrong = styled.strong`
  font-size: 0.85rem;
  color: #000;
  text-transform: uppercase;
`;

export const Text = styled.p<IStyledText>`
  ${({ fontSize = 0.85, color = '#000' }) => `
    font-size: ${fontSize}rem;
    color: ${color};
    text-transform: uppercase;
  `}
`;

export const TagContent = styled.div`
  display: flex;
  gap: 5px;
  padding-bottom: 15px;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoContent = styled.div`
  width: 90%;
  display: flex;
  padding: 10px 0;
`;

export const InfoText = styled.p`
  min-width: 175px;
  font-size: 0.85rem;
  text-transform: uppercase;
`;

export const Strong = styled.strong`
  font-size: 0.85rem;
  color: #000;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const ValuesContainer = styled.div`
  padding-top: 10px;
`;

export const ValuesContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
`;

export const ValueText = styled.div`
  width: 106px;
  display: flex;
  justify-content: space-between;
`;

export const LineContainer = styled.div`
  padding: 5px 0;
`;

interface ILineProps {
  height?: string;
  width?: string;
}
export const Line = styled.hr<ILineProps>`
  ${({ height, width }) => `
  height: ${height ?? '1px'};
  width: ${width ?? '1px'};
  border: 1px solid #d9d9d9;

  `}
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
`;

export const FooterButtons = styled.div`
  display: flex;
  gap: 1rem;
`;
