import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';
import { IUpdateScheduleCargoPayload } from '../../update-schedule-details.types';

export const convertScheduleDetailsDomainToResponse = (
  schedule: IUpdateScheduleCargoPayload,
): IUpdateScheduleCargoPayload => {
  const { id, cargo, detail } = schedule;
  return {
    id,
    cargo: {
      vehicleType: cargo.vehicleType,
      vehicleCategoryType: cargo.vehicleCategoryType,
      shippingReturn: cargo.shippingReturn,
      product: cargo.product,
      weight: Number(cargo.weight),
      cubagem: Number(cargo.cubagem),
      volume: Number(cargo.volume),
      merchValue: normalizeCurrency(cargo.merchValue),
      inPallet: cargo.inPallet,
      pallet: Number(cargo.pallet),
      order: cargo.order,
      comments: cargo.comments,
      requirements: {
        quality: {
          id: cargo.requirements.quality?.id ?? 0,
          values: cargo.requirements.quality?.values ?? [''],
        },
        security: {
          id: cargo.requirements.security?.id ?? 0,
          values: cargo.requirements.security?.values ?? [''],
        },
        custom: {
          id: cargo.requirements.custom?.id ?? 0,
          values: cargo.requirements.custom?.values ?? [''],
        },
      },
    },
    detail: {
      levyInitialDate: detail.levyInitialDate,
      levyEndDate: detail.levyEndDate,
      deliveryInitialDate: detail.deliveryInitialDate,
      deliveryEndDate: detail.deliveryEndDate,
      internalNumber: detail.internalNumber,
      ocInternalNumber: detail.ocInternalNumber,
      boardingNumber: detail.boardingNumber,
      schedulingPassword: detail.schedulingPassword,
    },
  };
};
