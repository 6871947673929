import React from 'react';

import { LogoContainerStyled, ContentStyled, LoginContainerStyled } from './template-authentication-styled';

import LogoBranco from 'assets/logos/logo-branco-icon';

type TemplateAuthenticationProps = {
  children: React.ReactNode;
};

const TemplateAuthenticationView: React.FC<TemplateAuthenticationProps> = ({ children }) => {
  return (
    <LoginContainerStyled>
      <LogoContainerStyled>
        <LogoBranco />
      </LogoContainerStyled>
      <ContentStyled>{children}</ContentStyled>
    </LoginContainerStyled>
  );
};

export default TemplateAuthenticationView;
