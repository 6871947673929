import React, { createContext, useMemo, useReducer } from 'react';
import { clientsInitialState } from './clients.constants';
import { clientsReducer } from './reducers/clients.reducer';
import { IClientsContextProps } from './clients.types';
import { IClientsContext } from './types/clients.types';
import { IClientsList } from 'src-new/pages/registrations/pages/client/contexts/clients/types/clients-list.types';
import { useClientsListContextValues } from 'src-new/pages/registrations/pages/client/contexts/clients/context-values/clients-list/clients-list-values.context';

export const ClientsContext = createContext<IClientsContext>(clientsInitialState);

export const ClientsContextProvider = ({ children }: IClientsContextProps) => {
  const [state] = useReducer(clientsReducer, clientsInitialState);

  const clientsList: IClientsList = useClientsListContextValues();

  const value: IClientsContext = useMemo(
    (): IClientsContext => ({
      ...state,
      clientsList,
    }),
    [clientsList, state],
  );

  return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;
};
