import styled, { css } from 'styled-components';

interface IRowStyledProps {
  gridColumn: string;
  isZebraLine: boolean;
  changeLineOnMouseOver: boolean;
  cursorType: 'initial' | 'pointer';
}

interface IStyledProps {
  disabledScroll?: boolean;
  alignLinesCenter?: boolean;
  width?: string;
}

export const TBodyStyled = styled.tbody<IStyledProps>`
  ${({ disabledScroll }) => css`
    align-self: stretch;
    text-align: left;

    ${!disabledScroll &&
    css`
      overflow-y: scroll;
    `}

    ::-webkit-scrollbar {
      width: 5px;
    }

    &.active-scrollbar::-webkit-scrollbar {
      height: 5px;
      background: rgb(243 243 243);
      border-radius: 50px;
      background-color: #f0f0f0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #8888889c;
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #12264e;
      width: 50px;
    }
  `}
`;

export const TRowStyled = styled.tr<IRowStyledProps>`
  ${({ theme, gridColumn, isZebraLine, changeLineOnMouseOver, cursorType }) => css`
    &:nth-child(even) {
      background-color: ${isZebraLine && theme.table.row.backgroundColor['isZebra']};
    }

    &:hover {
      background-color: ${changeLineOnMouseOver && theme.table.row.backgroundColor['onMouseEnter']};
    }

    display: grid;
    grid-template-columns: ${gridColumn};
    grid-gap: ${theme.table.row.gridGap};
    height: fit-content;
    padding: ${theme.table.body.row.padding};
    padding-left: 5px;
    margin-right: 5px;
    border-bottom: ${theme.table.body.row.borderBottom};
    cursor: ${cursorType};
  `}
`;

export const TDataStyled = styled.td<IStyledProps>`
  ${({ theme }) => css`
    font-family: ${theme.table.font.family};
    font-size: ${theme.table.font.sizes.header};
    font-style: ${theme.table.font.style};
    font-weight: ${theme.table.font.lineWeight};
    line-height: ${theme.table.font.lineHeight};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ alignLinesCenter }) =>
    alignLinesCenter &&
    css`
      align-self: center;
    `}
`;
