import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 48%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 25px 0 12px 0;
`;

export const TitleStrong = styled.strong`
  font-size: 20px;
`;

export const Title = styled.p``;

export const TextLink = styled.p`
  font-size: 11px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 460px);
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 10px 10px 10px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const ContentError = styled.div`
  div {
    height: calc(100vh - 460px);
  }
`;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 40px;
    width: inherit;
  `}
`;
