import { useCallback, useContext } from 'react';
import { FreightContext } from 'pages/freight/contexts/freight/freight.context';
import { IUser } from 'domain/login';
import { companyDetailsService } from 'pages/company/services/company-details/company-details.service';

export const usePhotoChatFreight = () => {
  const { freightChat } = useContext(FreightContext);

  return useCallback(
    async (user: IUser): Promise<void> => {
      freightChat.setFreightChatRequest();

      try {
        const companyDetails = await companyDetailsService(user.companyId);

        freightChat.setFreightChatSuccess(companyDetails.fileKey ?? 'NOT_FOUND');
      } catch (error) {
        freightChat.setFreightChatError();
      }
    },
    [freightChat],
  );
};
