import { ContactSchema } from 'src-new/pages/registrations/utils/schemas/contact-schema-validation';
import * as Yup from 'yup';
import { AttachmentsSchema } from 'src-new/pages/registrations/utils/schemas/attachment-schema-validation';

export const ClientRegisterSchema = Yup.object().shape({
  tradeName: Yup.string().min(2, 'Texto muito curto!').required('Campo obrigatório'),
  cnpj: Yup.string().length(18, 'CNPJ precisa ter 18 dígitos').required('Campo obrigatório'),
  fantasyName: Yup.string().min(2, 'Texto muito curto!').required('Campo obrigatório'),
  type_of_partner: Yup.string().required('Campo obrigatório'),
  lat: Yup.string().required('Campo obrigatório'),
  lng: Yup.string().required('Campo obrigatório'),
  address: Yup.object().shape({
    cep: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
    uf: Yup.string().required('Campo obrigatório'),
    neighborhood: Yup.string().required('Campo obrigatório'),
    street: Yup.string().required('Campo obrigatório'),
    numberHouse: Yup.string().required('Campo obrigatório'),
  }),
  contacts: Yup.array().of(ContactSchema),
  attachments: Yup.array().of(AttachmentsSchema),
});
