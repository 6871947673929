import API from 'utils/API/API';
import { ChecklistDetails } from 'domain-v2/checklist/index';

export const getChecklistDetailsService = async (moduleId: number, module: string): Promise<ChecklistDetails[]> => {
  const { data } = await API.get(`v2/checklist/details/${moduleId}`, {
    params: {
      module,
    },
  });

  return data;
};
