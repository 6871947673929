import { ISchedulingsLTLContext, ISchedulingsLTLReducerAction } from './schedulings-ltl.types';
import { ISchedulingsAction } from './schedulings-ltl.actions';

export const schedulingsReducer = (
  state: ISchedulingsLTLContext,
  action: ISchedulingsLTLReducerAction,
): ISchedulingsLTLContext => {
  switch (action.type) {
    case ISchedulingsAction.SCHEDULINGS_REQUEST: {
      return {
        ...state,
        schedulingsIsLoading: true,
        schedulings: [],
        schedulingsError: undefined,
      };
    }
    case ISchedulingsAction.SCHEDULINGS_SUCCESS: {
      return {
        ...state,
        schedulingsIsLoading: false,
        schedulings: action.payload.list,
        schedulingsPageInfo: action.payload.pageInfo,
        schedulingsError: undefined,
      };
    }
    case ISchedulingsAction.SCHEDULINGS_ERROR: {
      return {
        ...state,
        schedulingsIsLoading: false,
        schedulings: [],
        schedulingsError: action.payload,
        schedulingsPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
