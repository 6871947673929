export interface IGetPriceScheduleLTLCalculation {
  origin: {
    id: number;
    type: '' | 'CLIENT' | 'LOCALITY';
    uf: string;
    lat: number;
    lng: number;
  };

  destination: {
    id: number;
    type: '' | 'CLIENT' | 'LOCALITY';
    uf: string;
    lat: number;
    lng: number;
  };
  weight: number;
  volume: number;
  levyInitialDate: string;
  deliveryInitialDate: string;
}

interface FreightLTLValuesDetails {
  value: number;
  valueTotal: number;
  icms: number;
  date: string;
}

export interface SchedulePriceLTL {
  details: {
    weight: number;
    additionalWeight: number;
    icms: number;
  };
  recommended: FreightLTLValuesDetails;
  optimized: FreightLTLValuesDetails;
  advance: FreightLTLValuesDetails;
  air: FreightLTLValuesDetails;
  distance: number;
  leadTime: number;
}

const initialFreightLTLValuesDetails: FreightLTLValuesDetails = {
  value: 0,
  valueTotal: 0,
  icms: 0,
  date: '',
};

export const initialSchedulePriceLTLValues: SchedulePriceLTL = {
  details: {
    weight: 0,
    additionalWeight: 0,
    icms: 0,
  },
  recommended: initialFreightLTLValuesDetails,
  optimized: initialFreightLTLValuesDetails,
  advance: initialFreightLTLValuesDetails,
  air: initialFreightLTLValuesDetails,
  distance: 0,
  leadTime: 0,
};
