import styled from 'styled-components';
import { TextField } from '@mui/material';

import theme from 'styles/theme';

interface InputProps {
  readonly upperCase?: boolean;
}

export const CustomOutlineInput = styled(TextField)<InputProps>`
  & .MuiOutlinedInput-root {
    height: 37px;
    font-size: 13px;
    position: relative;
  }

  & .MuiInputLabel-root {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: ${theme.colors.black};

    &::placeholder : {
      align-self: center;
    }
  }

  & .MuiFormHelperText-root {
    font-size: 13px;
    margin-right: 0;
    margin-left: 0;
  }

  & .MuiInputBase-input {
    text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  }
`;
