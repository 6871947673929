import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { ScheduleTableTabsView } from './schedule-table-tabs-view.component';
import {
  IActiveTabName,
  IScheduleListParams,
} from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import {
  getActiveTabName,
  tabContentName,
  tabStatusCode,
} from 'src-new/pages/execution/pages/schedule/utils/schedule-tab-name/schedule-tab-name.util';
import { UserContext } from 'state/user-context';
import { useGetScheduleList } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/hooks/use-schedule-list/use-schedule-list.hook';

export const ScheduleTableTabs: React.FC = () => {
  const { isAdmin } = useContext(UserContext);
  const { scheduleList, scheduleListInitialLoading } = useContext(ScheduleContext);
  const { setScheduleListInitialLoadingReset } = scheduleListInitialLoading;
  const [activeTab, setActiveTab] = useState<string>('Em Alocação');
  const getScheduleListHook = useGetScheduleList();

  const tabsContent = useMemo((): Array<ITabContent> => {
    return tabContentName(<Fragment />, isAdmin, scheduleList.scheduleListTotalRowProps.totalRow);
  }, [isAdmin, scheduleList.scheduleListTotalRowProps.totalRow]);

  const getTabName = (): { [key in string]: IActiveTabName } => ({
    'Em Alocação': 'Em Alocação',
    Cancelados: 'Cancelados',
    'A Iniciar': 'A Iniciar',
    'Em Andamento': 'Em Andamento',
    Finalizados: 'Finalizados',
    Prospectados: 'Prospectados',
    Todos: 'Todos',
  });

  const handleChangeTab = useCallback(
    (tabName: string) => {
      const defaultFilters = scheduleList.scheduleListFiltersProps.filters;
      setActiveTab(tabName);

      let filters: IScheduleListParams = {
        ...defaultFilters,
        page: 1,
        column: 'Data Entrega',
        order: 'ASC',
        statusCode: tabStatusCode(tabName),
        activeTabName: getTabName()[tabName],
      };

      if (tabName === 'Finalizados' || tabName === 'Todos' || tabName === 'Cancelados') {
        filters = {
          ...filters,
          column: 'Data Entrega',
          order: 'DESC',
        };
      }

      if (tabName === 'Em Alocação' || tabName === 'A Iniciar' || tabName === 'Em Andamento') {
        filters = {
          ...filters,
          column: 'Data Coleta',
          order: 'ASC',
        };
      }

      setScheduleListInitialLoadingReset();
      scheduleList.scheduleListFiltersProps.setFilters(filters);
      sessionStorage.setItem('schedule-list-filters', JSON.stringify(filters));
    },
    [scheduleList.scheduleListFiltersProps, setScheduleListInitialLoadingReset],
  );

  useEffect(() => {
    getScheduleListHook();
    const cachedFilters = sessionStorage.getItem('schedule-list-filters');

    if (cachedFilters) {
      const filters: IScheduleListParams = JSON.parse(cachedFilters);

      setActiveTab(getActiveTabName()[filters.activeTabName]);
    }
  }, [getScheduleListHook]);

  return <ScheduleTableTabsView tabsContent={tabsContent} activeTab={activeTab} handleChangeTab={handleChangeTab} />;
};
