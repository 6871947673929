import styled, { css } from 'styled-components';

type InputTextProps = {
  iconPosition: 'left' | 'right';
  disabled: boolean;
  hasError: boolean;
};

type InputWrapperProps = {
  hidden: boolean;
};

export const WrapperStyled = styled.div<InputWrapperProps>`
  ${({ hidden }) => css`
    display: ${hidden ? 'none' : ''};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  `}
`;

export const InputWrapperStyled = styled.div<InputTextProps>`
  ${({ theme, iconPosition, disabled = false, hasError }) => css`
    display: flex;
    flex-direction: ${iconPosition === 'right' ? 'row-reverse' : 'row'};
    margin-top: 3px;
    border: 1px solid;
    border-color: ${hasError ? theme.colors.error : theme.colors.gray};
    border-radius: 4px;
    background-color: ${disabled ? theme.colors.veryLightGray : theme.colors.white};

    ${!disabled &&
    `&:hover,
     &:focus-within {
      border: 1px solid ${theme.colorsV2.blue} !important;
      box-shadow: inset 0 0 0 1px ${theme.colorsV2.blue} !important;
     }`}

    ${hasError &&
    css`
      svg {
        color: ${theme.colors.error};
      }
    `}
  `}
`;

export const InputStyled = styled.input<{
  disabled: boolean;
  paymentStyle: boolean;
  type: 'text' | 'password' | 'email' | 'number' | 'file' | 'date';
}>`
  ${({ theme, disabled, paymentStyle, type }) => css`
    min-height: 36px;
    width: 100%;
    color: ${theme.colors.black};
    font-family: ${theme.font.family};
    font-size: 12px;
    font-weight: ${paymentStyle ? '700' : '600'};
    background-color: ${disabled ? `${theme.colors.veryLightGray}` : 'transparent'};
    border: 0;
    border-radius: 4px;
    outline: none;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${!disabled &&
    `&:-webkit-autofill:hover,
     &:-webkit-autofill:focus-within {
      box-shadow: inset 0 0 0 1px ${theme.colorsV2.blue} !important;
     }`}

    ${type === 'date' &&
    css`
      ::-webkit-calendar-date {
        background-color: red;
        color: white;
      }

      ::-moz-calendar-date {
        background-color: red;
        color: white;
      }
    `}
  `}
`;

export const LabelStyled = styled.label<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    font-size: 11px;
    font-weight: 400;
    color: ${hasError ? theme.colors.error : theme.colors.black};
    background-color: ${theme.colorsV2.white};
  `}
`;

export const IconStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    margin-right: 10px;

    & svg {
      width: 100%;
    }
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
