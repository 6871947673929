import { IScheduleContext } from '../types/schedule.types';
import { initialStateTruckRoutes } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-execution/schedule-execution.reducer';

export const scheduleReducerResetState = (state: IScheduleContext): IScheduleContext => {
  return {
    ...state,
    scheduleExecution: {
      ...state.scheduleExecution,
      mapAccordionProps: {
        ...state.scheduleExecution.mapAccordionProps,
        statusRequest: 'INITIAL',
        plannedRoute: true,
        firebaseTruckRoutes: initialStateTruckRoutes,
        monitoringTruckRoutes: initialStateTruckRoutes,
      },
    },
  };
};
