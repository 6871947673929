import { IImpactOccurrence, IOccurrence, ISlaOccurrence, IoptionsTreatmentSector } from 'domain/occurrence';

export const PATH_TO_OCCURRENCE_LIST = '/tipos-ocorrencias';

export const initialValues: IOccurrence = {
  description: '',
  status: 'ativo',
  impact: '',
  responsibleSolution: [],
  impactedList: [],
  sla: '',
  treatmentSector: null,
};

export const optionsImpact: Array<{
  label: string;
  value: IImpactOccurrence;
}> = [
  { label: 'BAIXO', value: 'baixo' },
  { label: 'MÉDIO', value: 'médio' },
  { label: 'ALTO', value: 'alto' },
];

export const optionsTreatmentSector: Array<{
  label: string;
  value: IoptionsTreatmentSector;
}> = [
  { label: 'OPERAÇÃO', value: 'OPERATION' },
  { label: 'TRANSPORTE', value: 'TRANSPORT' },
];

export const optionsSla: Array<{
  label: string;
  value: ISlaOccurrence;
}> = [
  { label: '1 HORA', value: '1 hora' },
  { label: '2 HORAS', value: '2 horas' },
  { label: '6 HORAS', value: '6 horas' },
  { label: '12 HORAS', value: '12 horas' },
  { label: '24 HORAS', value: '24 horas' },
  { label: '48 HORAS', value: '48 horas' },
  { label: '72 HORAS', value: '72 horas' },
];
