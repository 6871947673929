import React from 'react';
import * as S from './weighing-totten-modal-keyboard.styled';
import { VirtualKeyboard } from 'src-new/mobile/pages/weighing-totten/components/virtual-keyboard/virtual-keyboard.component';

export interface WeighingTottenModalKeyboardViewParams {
  onChange: (input: string) => void;
  onKeyPress?: ((button: string, e?: MouseEvent | undefined) => any) | undefined;
  keyboardType?: 'numbers' | 'default';
}
export const WeighingTottenModalKeyboardView: React.FC<WeighingTottenModalKeyboardViewParams> = (params) => {
  return (
    <S.Wrapper>
      <VirtualKeyboard {...params} />
    </S.Wrapper>
  );
};
