import { useCallback } from 'react';
import { VehicleTypeResponse } from 'src-new/domains/vehicle-type.domain';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getFreetimeByVehicleService } from '../../services/get-freetime/get-freetime.service';

export const useGetFreetimeByVehicle = () => {
  return useCallback(async (vehicleType: VehicleTypeResponse, companyId: number) => {
    try {
      return getFreetimeByVehicleService(vehicleType, companyId);
    } catch (err) {
      showMessageFormatted({
        message: 'Informe um veículo válido para calcular as tarifas.',
        type: 'error',
      });
    }
  }, []);
};
