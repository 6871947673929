import React, { ReactElement, useMemo } from 'react';
import * as S from './home-matches-table-view.styled';
import { HomeMatchesList } from './components/home-matches-list/home-matches-list.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export interface IMatchesTableViewStatesProps {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
}

interface IHomeMatchesTableViewProps {
  states: IMatchesTableViewStatesProps;
  handlePurchaseList: () => void;
  infiniteScrollProps: IInfiniteScrollTable;
}

export const HomeMatchesTableView: React.FC<IHomeMatchesTableViewProps> = ({
  states,
  handlePurchaseList,
  infiniteScrollProps,
}) => {
  const renderContentMatches = useMemo((): ReactElement => {
    if (states.isLoading) {
      return (
        <S.ContentContainer>
          <Loading />
        </S.ContentContainer>
      );
    }

    if (states.isError) {
      return (
        <S.ContentError>
          <ErrorState />
        </S.ContentError>
      );
    }

    if (states.isEmpty) {
      return (
        <S.ContentContainer>
          <EmptyState />
        </S.ContentContainer>
      );
    }

    return <HomeMatchesList infiniteScrollProps={infiniteScrollProps} />;
  }, [infiniteScrollProps, states.isEmpty, states.isError, states.isLoading]);

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.TitleContainer>
          <S.TitleStrong>Top Matches</S.TitleStrong>
          <S.Title>para compra</S.Title>
        </S.TitleContainer>
        <S.TextLink onClick={handlePurchaseList}>Ver Todos</S.TextLink>
      </S.HeaderContainer>
      <S.TableContainer>{renderContentMatches}</S.TableContainer>
    </S.Wrapper>
  );
};
