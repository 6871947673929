import React, { useState } from 'react';
import { Marker } from '@react-google-maps/api';

import MapFloatingMenu from '../map-menu/map-menu';
import FloatingResume from '../floating-resume/floating-resume';
import CompanyResume from '../company-resume/company-resume';

import {
  IAllCompanies,
  IMapViewProps,
} from 'pages/territorial-vision/territorial-vision-body/territorial-vision-body.types';
import trackingYellow from 'assets/img/tracking-yellow.svg';
import trackingOrange from 'assets/img/tracking-orange.svg';
import pinShippingCompanies from 'assets/img/pin-shipping-companies.svg';
import pinLocations from 'assets/img/pin-location.svg';
import pinClients from 'assets/img/pin-clients.svg';

const scheduledPickupStatus = ['AG10', 'AL10', 'IN10', 'IN11'];
const routeStatus = ['IN20', 'IN30', 'IN40', 'IN50', 'IN60', 'IN70'];

export const MapView: React.FC<IMapViewProps> = ({ clients, schedules, localities, shippingCompany }) => {
  // const { setSelectedBackHaul } = useTerritorial()

  const [renderUnities, setRenderUnities] = useState<boolean>(false);
  const [renderPartners, setRenderPartners] = useState<boolean>(false);
  // const [renderBackhaul, setRenderBackhaul] = useState<boolean>(false)
  const [renderTransportes, setRenderTransporters] = useState<boolean>(false);
  const [renderRoadVehicles, setRenderRoadVehicles] = useState<boolean>(false);
  const [renderScheduledPickups, setRenderScheduledPickups] = useState<boolean>(false);

  // const [backhaulRoutes, setBackhaulRoutes] = useState<any[]>([])
  // const [backhaulCurrentPage, setBackhaulCurrentPage] = useState<number>(1)

  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedSchedule, setSelectedSchedule] = useState<any>();

  // const { data } = queryBuilder(
  //   ['matchProposal', backhaulCurrentPage],
  //   async () => await matchesCoords(backhaulCurrentPage),
  //   { keepPreviousData: true },
  // )

  // const fetchBackhaulRoutes = useCallback(
  //   async (rows: any[], totalPages: number) => {
  //     try {
  //       const shapes = await fetchRoutePoints(rows)

  //       setBackhaulRoutes((prev) => [...prev, ...shapes])

  //       await new Promise((resolve) => setTimeout(resolve, 15000))

  //       if (backhaulCurrentPage <= totalPages) {
  //         setBackhaulCurrentPage((prev) => prev + 1)
  //       }
  //     } catch (error) {
  //       showToast({
  //         title: 'Houve um erro ao carregar uma rota',
  //         message: '',
  //         type: 'error',
  //       })
  //     }
  //   },
  //   [data, backhaulCurrentPage],
  // )

  // useEffect(() => {
  //   ;(async () => {
  //     if (
  //       !renderBackhaul ||
  //       !data?.rows.length ||
  //       !Reflect.ownKeys(data?.meta).length
  //     )
  //       return

  //     const { rows, meta } = data

  //     await fetchBackhaulRoutes(rows, meta.totalPages)
  //   })()
  // }, [data, renderBackhaul])

  const filterCompanies = (companyType: IAllCompanies[]) => {
    if (!companyType.length) return [];

    return companyType.filter(
      ({ companyId, lat, lng, status }) => companyId && lat && lng && lat !== '' && lng !== '' && status === 'active',
    );
  };

  const filterVehiclesByStatus = React.useCallback(
    (status: any[]) => {
      return schedules
        .filter((item) => status.includes(item.statusCode))
        .map((item) => {
          const { scheduleClientOrigin } = item;

          const lat = (scheduleClientOrigin?.locality || scheduleClientOrigin?.client)?.lat ?? null;

          const lng = (scheduleClientOrigin?.locality || scheduleClientOrigin?.client)?.lng ?? null;

          return { ...item, lat, lng };
        });
    },
    [schedules],
  );

  const renderMarkers = React.useCallback((data: any, iconUrl: string, onClick: (data: any) => void) => {
    return data
      .filter((element: any) => element.lat && element.lng)
      .map((item: any, index: number) => {
        const position = { lat: Number(item.lat), lng: Number(item.lng) };
        return (
          <Marker
            key={index}
            position={position}
            icon={{
              url: iconUrl,
              scaledSize: new window.google.maps.Size(28, 28),
            }}
            onClick={() => onClick(item)}
          />
        );
      });
  }, []);

  // const renderBackhaulRoutes = React.useCallback((routes: any[]) => {
  //   return routes.map(({ id, shapes }, index) => {
  //     return (
  //       <Polyline
  //         key={index}
  //         path={shapes}
  //         options={{
  //           strokeOpacity: 5,
  //           strokeWeight: 5,
  //           strokeColor: theme.colors.blue,
  //         }}
  //         onClick={() => setSelectedBackHaul({ ...id })}
  //       />
  //     )
  //   })
  // }, [])

  return (
    <>
      <MapFloatingMenu
        renderUnities={setRenderUnities}
        renderPartners={setRenderPartners}
        renderTransportes={setRenderTransporters}
        renderRoadVehicles={setRenderRoadVehicles}
        renderScheduledPickups={setRenderScheduledPickups}
      />

      {/* {renderBackhaul && renderBackhaulRoutes(backhaulRoutes)} */}

      {renderRoadVehicles && renderMarkers(filterVehiclesByStatus(routeStatus), trackingOrange, setSelectedSchedule)}

      {renderScheduledPickups &&
        renderMarkers(filterVehiclesByStatus(scheduledPickupStatus), trackingYellow, setSelectedSchedule)}

      {renderUnities && renderMarkers(filterCompanies(localities), pinLocations, setSelectedCompany)}

      {renderPartners && renderMarkers(filterCompanies(clients), pinClients, setSelectedCompany)}

      {renderTransportes && renderMarkers(shippingCompany, pinShippingCompanies, setSelectedCompany)}

      {/* {backhaulRoutes.length && renderBackhaul && (
        <FloatingLoading
          isLoading={backhaulCurrentPage < data?.meta.totalPages ?? false}
          anchorText={`Exibindo Oportunidades ${backhaulRoutes.length} de ${
            data?.meta.totalItems ?? '0'
          }`}
        />
      )} */}

      {selectedSchedule && (
        <FloatingResume scheduleId={selectedSchedule.id} onClose={() => setSelectedSchedule(undefined)} />
      )}

      {selectedCompany && <CompanyResume company={selectedCompany} onClose={() => setSelectedCompany(undefined)} />}
    </>
  );
};
