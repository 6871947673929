import React from 'react';

import { ICardProps } from '../card.types';

import CardTariffView from './card-tariff-view';

interface IProps {
  cards?: ICardProps[];
}

const CardTariff: React.FC<IProps> = ({ cards }) => {
  return <CardTariffView cards={cards} />;
};

export default CardTariff;
