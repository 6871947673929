import { MultiValue } from 'react-select';
import React from 'react';

import MultiSelectView from './multi-select-view';

import { AllValuesType } from 'domain/global-inputs';

export interface Option {
  value: string | Array<string> | number | AllValuesType;
  label: string;
}

interface MultiSelectParams {
  options: Array<Option>;
  placeholder?: string;
  isSearchable?: boolean;
  menuPosition: 'fixed' | 'absolute';
  label?: string;
  values?: Array<Option>;
  hasError?: boolean;
  errorMessage?: string;
  onChange: (value: MultiValue<Option>) => void;
}
const MultiSelect: React.FC<MultiSelectParams> = ({
  options,
  isSearchable,
  placeholder,
  label,
  menuPosition,
  values,
  hasError,
  errorMessage,
  onChange,
}) => {
  return (
    <MultiSelectView
      label={label}
      placeholder={placeholder}
      options={options}
      isSearchable={isSearchable}
      menuPosition={menuPosition}
      values={values}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={onChange}
    />
  );
};

export default MultiSelect;
