import { ISidebarContext } from './sidebar.types';

export const initialState: ISidebarContext = {
  currentSelectedMenu: 'inicio',
  textIsVisible: false,
  updateLayout: false,
  menus: [],
  isHovered: false,
  updateMenus: () => undefined,
  handleVisibleText: () => undefined,
  handleSelectedMenu: () => undefined,
  handleHover: () => undefined,
};
