import React, { Fragment, useMemo } from 'react';
import { MapTypeButton, MapTypeLabelButton } from './map-type-button-view.styled';
import { MapTypeId } from 'src-new/components/google-maps/google-maps-view.types';

export type ActiveButtonType = 'Primary' | 'Secondary';

interface IRouteReportDownloadButtonViewProps {
  handleMapType: (type: MapTypeId) => void;
  activeButton: ActiveButtonType;
}

export const RouteReportDownloadButtonView: React.FC<IRouteReportDownloadButtonViewProps> = ({
  handleMapType,
  activeButton,
}) => {
  const isActivePrimaryButton = useMemo(() => activeButton === 'Primary', [activeButton]);
  const isActiveSecondaryButton = useMemo(() => activeButton === 'Secondary', [activeButton]);

  return (
    <Fragment>
      <MapTypeButton
        activeButton={isActivePrimaryButton}
        primaryButton
        left={'35px'}
        onClick={() => handleMapType(MapTypeId.ROADMAP)}
      >
        <MapTypeLabelButton>Mapa</MapTypeLabelButton>
      </MapTypeButton>
      <MapTypeButton
        activeButton={isActiveSecondaryButton}
        left={'115px'}
        onClick={() => handleMapType(MapTypeId.HYBRID)}
      >
        <MapTypeLabelButton>Satélite</MapTypeLabelButton>
      </MapTypeButton>
    </Fragment>
  );
};
