import { IUserStatusEntity } from 'pages/user/services/entities/user.entity';
import { IUserStatus } from 'domain/user';

export const convertUserStatusDomainToEntity = (statusDomain: IUserStatus): IUserStatusEntity => {
  switch (statusDomain) {
    case 'ativo':
      return 'active';
    case 'suspenso':
      return 'suspendend';
    default:
      return 'inactive';
  }
};
