import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

import { IVehicleCreatedEntity } from 'pages/vehicle/services/entities/vehicle.entity';
import { IVehicleCreated } from 'domain/vehicle';

export const convertVehicleCreatedEntityToDomainMapper = (vehicleEntity: IVehicleCreatedEntity): IVehicleCreated => ({
  id: vehicleEntity.id,
  vehicleType: vehicleEntity.vehicleType,
  mainBoard: vehicleEntity.mainBoard,
  secondBoard: vehicleEntity.secondBoard,
  thirdBoard: vehicleEntity.thirdBoard,
  capacity: vehicleEntity.capacity,
  cubageCapacity: vehicleEntity.cubageCapacity,
  palletCapacity: vehicleEntity.palletCapacity,
  fuel: vehicleEntity.fuel,
  vehicleCategory: vehicleEntity.vehicleCategory,
  vehicleBrand: vehicleEntity.vehicleBrand,
  vehicleModel: vehicleEntity.vehicleModel,
  vehicleTracker: vehicleEntity.vehicleTracker,
  year: vehicleEntity.year,
  sensors: vehicleEntity.sensors,
  actuators: vehicleEntity.actuators,
  fleetType: vehicleEntity.fleetType,
  shippingCompany: vehicleEntity.shippingCompany,
  shippingCompanyId: vehicleEntity.shippingCompanyId,
  crlvPhoto: vehicleEntity.crlvPhoto,
  vehiclePhotos1: vehicleEntity.vehiclePhotos && vehicleEntity.vehiclePhotos[0],
  vehiclePhotos2: vehicleEntity.vehiclePhotos && vehicleEntity.vehiclePhotos[1],
  vehiclePhotos3: vehicleEntity.vehiclePhotos && vehicleEntity.vehiclePhotos[2],
  vehiclePhotos4: vehicleEntity.vehiclePhotos && vehicleEntity.vehiclePhotos[3],
  vehiclePhotos5: vehicleEntity.vehiclePhotos && vehicleEntity.vehiclePhotos[4],
  company: vehicleEntity.company,
  status: convertStatusEntityToDomainMapper(vehicleEntity.status),
});
