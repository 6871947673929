import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { CancelWhiteIcon } from 'assets/icons/cancel-white.icon';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  ChatButtonStyled,
  ChatButtonWrapper,
  GroupButtonContainerStyled,
  GroupButtonNameStyled,
  GroupButtonNameWrapperStyled,
  GroupButtonStyled,
  GroupButtonWrapperStyled,
  MessageCountStyled,
  MessageCountWrapperStyled,
  UnreadsGroupChatStyled,
  UnreadsGroupWrapperStyled,
  UnreadsSingleChatContainerStyled,
  UnreadsSingleChatStyled,
} from './chat-button-view.styled';
import { Chat } from 'src-new/components/chat-button/components/chat/chat.component';
import { ChatType, IChatObject, IGroupChatProps } from 'src-new/components/chat-button/chat-button.types';
import { ISetChatOpenProps } from 'src-new/contexts/chat-button/types/chat-button.types';

interface IChatButtonViewProps {
  chatProps: ChatType;
  groupChatProps: IGroupChatProps;
  handleOpenChat: (props: ISetChatOpenProps) => void;
  handleCloseChat: () => void;
  isShowChat: boolean;
  isRequestCompletedUserEmails: boolean;
  hasDisabledChat: boolean;
  getUnreadChat: (id: string) => number;
  getMessageCount: (id: string) => number;
}

export const ChatButtonView: React.FC<IChatButtonViewProps> = ({
  hasDisabledChat,
  groupChatProps,
  isShowChat,
  isRequestCompletedUserEmails,
  handleOpenChat,
  handleCloseChat,
  getUnreadChat,
  chatProps,
  getMessageCount,
}) => {
  const renderChat = useMemo((): ReactElement | undefined => {
    if (!hasDisabledChat && isShowChat) {
      return <Chat />;
    }
  }, [hasDisabledChat, isShowChat]);

  const renderUnreadSingleChat = useCallback(
    (idChat: string): ReactElement | undefined => {
      const unreadChat = getUnreadChat(idChat);

      if (unreadChat && unreadChat > 0) {
        return (
          <UnreadsSingleChatContainerStyled>
            <UnreadsSingleChatStyled>{unreadChat}</UnreadsSingleChatStyled>
          </UnreadsSingleChatContainerStyled>
        );
      }
    },
    [getUnreadChat],
  );

  const renderUnreadGroupChat = useCallback(
    (idChatList: Array<string>): ReactElement | undefined => {
      let unreadMessages = 0;

      idChatList.forEach((id) => {
        const unreadChat = getUnreadChat(id);

        if (unreadChat) {
          unreadMessages = unreadMessages + unreadChat;
        }
      });

      if (unreadMessages) {
        return (
          <UnreadsSingleChatContainerStyled>
            <UnreadsSingleChatStyled>{unreadMessages}</UnreadsSingleChatStyled>
          </UnreadsSingleChatContainerStyled>
        );
      }
    },
    [getUnreadChat],
  );

  const renderUnreadMessage = useCallback(
    (id: string): ReactElement | undefined => {
      const unreadChat = getUnreadChat(id);

      if (unreadChat) {
        return (
          <UnreadsGroupWrapperStyled>
            <UnreadsGroupChatStyled>{unreadChat}</UnreadsGroupChatStyled>
          </UnreadsGroupWrapperStyled>
        );
      }
    },
    [getUnreadChat],
  );

  const renderMessageCount = useCallback(
    (id: string, isDisabled?: boolean): ReactElement => {
      const count = getMessageCount(id);

      return (
        <MessageCountWrapperStyled>
          <MessageCountStyled disabled={isDisabled}>{count}</MessageCountStyled>
        </MessageCountWrapperStyled>
      );
    },
    [getMessageCount],
  );

  const renderGroupChat = useCallback(
    (chats: Array<IChatObject>): ReactElement | undefined => {
      if (groupChatProps.showGroupChat && !isShowChat) {
        return (
          <GroupButtonWrapperStyled>
            <GroupButtonContainerStyled>
              {chats.map((chat) => {
                return (
                  <GroupButtonStyled
                    key={chat.name}
                    backgroundColor={chat.colorButton}
                    onClick={() => handleOpenChat({ chatId: chat.chatId, name: chat.name })}
                    disabled={chat.isDisabled}
                  >
                    <GroupButtonNameWrapperStyled>
                      Chat com<GroupButtonNameStyled>{chat.name}</GroupButtonNameStyled>
                    </GroupButtonNameWrapperStyled>
                    {renderUnreadMessage(chat.chatId)}
                    {renderMessageCount(chat.chatId, chat.isDisabled)}
                  </GroupButtonStyled>
                );
              })}
            </GroupButtonContainerStyled>
          </GroupButtonWrapperStyled>
        );
      }
    },
    [groupChatProps.showGroupChat, handleOpenChat, isShowChat, renderMessageCount, renderUnreadMessage],
  );

  const renderSingleButton = useCallback(
    (chatId?: string, name?: string): ReactElement => {
      if (chatId && name) {
        return isShowChat ? (
          <ChatButtonStyled onClick={handleCloseChat}>
            <CancelWhiteIcon width={'18'} height={'18'} />
          </ChatButtonStyled>
        ) : (
          <ChatButtonStyled onClick={() => handleOpenChat({ chatId, name })}>
            {renderUnreadSingleChat(chatId)}
            <QuestionAnswerIcon fontSize={'large'} />
          </ChatButtonStyled>
        );
      }

      return isShowChat && isRequestCompletedUserEmails ? (
        <ChatButtonStyled onClick={handleCloseChat}>
          <CancelWhiteIcon width={'18'} height={'18'} />
        </ChatButtonStyled>
      ) : (
        <Fragment />
      );
    },
    [handleCloseChat, handleOpenChat, isRequestCompletedUserEmails, isShowChat, renderUnreadSingleChat],
  );

  const renderButtonGroupChat = useCallback(
    (ids: Array<string>): ReactElement => {
      return groupChatProps.showGroupChat ? (
        <ChatButtonStyled onClick={groupChatProps.handleGroupChatClose}>
          <CancelWhiteIcon width={'18'} height={'18'} />
        </ChatButtonStyled>
      ) : (
        <ChatButtonStyled onClick={groupChatProps.handleGroupChatOpen}>
          {renderUnreadGroupChat(ids)}
          <QuestionAnswerIcon fontSize={'large'} />
        </ChatButtonStyled>
      );
    },
    [
      groupChatProps.handleGroupChatClose,
      groupChatProps.handleGroupChatOpen,
      groupChatProps.showGroupChat,
      renderUnreadGroupChat,
    ],
  );

  const renderChatType = useMemo(() => {
    if (chatProps.typeChat === 'SINGLE') {
      return <ChatButtonWrapper>{renderSingleButton(chatProps.chatId, chatProps.name)}</ChatButtonWrapper>;
    }

    return (
      <Fragment>
        <ChatButtonWrapper>
          {renderButtonGroupChat(chatProps.chatsObjects.map((chat) => chat.chatId))}
        </ChatButtonWrapper>
        {renderGroupChat(chatProps.chatsObjects)}
      </Fragment>
    );
  }, [chatProps, renderButtonGroupChat, renderGroupChat, renderSingleButton]);

  return (
    <Fragment>
      {renderChatType}
      {renderChat}
    </Fragment>
  );
};
