import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { IScheduleImportModal } from '../../types/schedule-import-modal.types';
import { ScheduleImportModalActionType } from '../../schedule.action';
import { scheduleImportModalReducer } from '../../reducers/schedule-import-modal/schedule-import-modal.reducer';
import { IScheduleImportModalDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-import-modal/schedule-import-modal.domain';

export const useScheduleImportModalContextValues = (): IScheduleImportModal => {
  const [state, dispatch] = useReducer(scheduleImportModalReducer, scheduleInitialState);

  const setScheduleImportModalRequest = useCallback(() => {
    dispatch({ type: ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_REQUEST, payload: undefined });
  }, []);

  const setScheduleImportModalSuccess = useCallback((scheduleImportModal: Array<IScheduleImportModalDomain>) => {
    dispatch({ type: ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_SUCCESS, payload: scheduleImportModal });
  }, []);

  const setScheduleImportModalError = useCallback(() => {
    dispatch({ type: ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_ERROR, payload: undefined });
  }, []);

  const setScheduleImportModalReset = useCallback(() => {
    dispatch({ type: ScheduleImportModalActionType.SCHEDULE_IMPORT_MODAL_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleImportModal,
    setScheduleImportModalRequest,
    setScheduleImportModalSuccess,
    setScheduleImportModalError,
    setScheduleImportModalReset,
  };
};
