import API from 'utils/API/API';
import { IShippingCompanyDomain } from 'src-new/pages/registrations/domains/shipping-company';
import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import { mountAlertList } from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal.constants';

export const getShippingCompanyByIdService = async (id: number): Promise<IShippingCompanyDomain> => {
  const { data } = await API.get<IShippingCompanyDomain>(`v3/shipping-company/${id}`);

  return { ...data, contacts: handleAlertsContacts(data.contacts) };
};

const handleAlertsContacts = (contacts: Array<IContactDomain>): Array<IContactDomain> => {
  return contacts.map((contact) => {
    if (!contact.alerts || !contact.alerts.length) {
      contact.alerts = mountAlertList();
    }

    return contact;
  });
};
