export enum WeighingListActionType {
  WEIGHING_LIST_MODALS = 'WEIGHING_LIST_MODALS',
  WEIGHING_LIST_REQUEST = 'WEIGHING_LIST_REQUEST',
  WEIGHING_LIST_SUCCESS = 'WEIGHING_LIST_SUCCESS',
  WEIGHING_LIST_ERROR = 'WEIGHING_LIST_ERROR',
  WEIGHING_LIST_RESET = 'WEIGHING_LIST_RESET',
  WEIGHING_SELECT_FILTERS = 'WEIGHING_SELECT_FILTERS',
  WEIGHING_RESET_FILTERS = 'WEIGHING_RESET_FILTERS',
}

export enum WeighingDetailsActionType {
  WEIGHING_DETAILS_REQUEST = 'WEIGHING_DETAILS_REQUEST',
  WEIGHING_DETAILS_SUCCESS = 'WEIGHING_DETAILS_SUCCESS',
  WEIGHING_DETAILS_ERROR = 'WEIGHING_DETAILS_ERROR',
  WEIGHING_DETAILS_RESET = 'WEIGHING_DETAILS_RESET',
}

export enum WeighingActionType {
  WEIGHING_RESET = 'WEIGHING_RESET',
}
