import React from 'react';

import * as S from './map-menu.styles';

import theme from 'styles/theme';
import Checkbox from 'components/checkbox/checkbox';
import trackingYellow from 'assets/img/tracking-yellow.svg';
import trackingOrange from 'assets/img/tracking-orange.svg';
import pinShippingCompanies from 'assets/img/pin-shipping-companies.svg';
import pinLogshareBlue from 'assets/img/pin-logshare-blue.svg';
import pinClients from 'assets/img/pin-clients.svg';
import ArrowUp from 'assets/icons/arrow-up.icon';
// import backhaukIcon from 'assets/img/backhaul-icon.svg'

type DispatchFunctions =
  | 'renderUnities'
  | 'renderPartners'
  | 'renderTransportes'
  | 'renderRoadVehicles'
  // | 'renderBackhaulRoutes'
  | 'renderScheduledPickups';

type MapFloatingMenuProps = {
  [key in DispatchFunctions]: React.Dispatch<React.SetStateAction<boolean>>;
};

const MapFloatingMenu: React.FC<MapFloatingMenuProps> = (props) => {
  const {
    renderUnities,
    renderPartners,
    renderTransportes,
    renderRoadVehicles,
    // renderBackhaulRoutes,
    renderScheduledPickups,
  } = props;

  const [expandPanel, setExpandPanel] = React.useState<boolean>(true);

  return (
    <S.WrappedMenu data-testid="wrapper-menu">
      <S.MuiAccordion expanded={expandPanel}>
        <S.MuiAccordionSummary
          expandIcon={<ArrowUp />}
          aria-controls="menu-summary"
          onClick={() => setExpandPanel(!expandPanel)}
        >
          <S.TextStyled weight="600" color={theme.colors.darkGray}>
            Legenda
          </S.TextStyled>
        </S.MuiAccordionSummary>
        <S.MuiAccordionDetails>
          <S.WrapperInputs data-testid="wrapper-menu">
            {/* Temporary remotion
            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={backhaukIcon} />
                <S.TextStyled>Oportunidades de backhaul</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'backhaul'}
                  name={'backhaul'}
                  onChange={({ target: { checked } }) =>
                    renderBackhaulRoutes(checked)
                  }
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled> */}

            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={trackingYellow} />
                <S.TextStyled>Coletas agendadas</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'viagens-andamento'}
                  name={'viagens-andamento'}
                  onChange={({ target: { checked } }) => renderScheduledPickups(checked)}
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled>

            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={trackingOrange} />
                <S.TextStyled>Viagens em Andamento</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'viagens-andamento'}
                  name={'viagens-andamento'}
                  onChange={({ target: { checked } }) => renderRoadVehicles(checked)}
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled>

            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={pinLogshareBlue} />
                <S.TextStyled>Unidades</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'unidades'}
                  name={'unidades'}
                  onChange={({ target: { checked } }) => renderUnities(checked)}
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled>

            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={pinClients} />
                <S.TextStyled>Parceiros</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'parceiros'}
                  name={'parceiros'}
                  onChange={({ target: { checked } }) => renderPartners(checked)}
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled>

            <S.MenuRowStyled>
              <S.MenuRowLabel>
                <S.ImageStyled src={pinShippingCompanies} />
                <S.TextStyled>Transportadoras</S.TextStyled>
              </S.MenuRowLabel>
              <S.CheckboxStyled>
                <Checkbox
                  id={'transportadoras'}
                  name={'transportadoras'}
                  onChange={({ target: { checked } }) => renderTransportes(checked)}
                />
              </S.CheckboxStyled>
            </S.MenuRowStyled>
          </S.WrapperInputs>
        </S.MuiAccordionDetails>
      </S.MuiAccordion>
    </S.WrappedMenu>
  );
};

export default MapFloatingMenu;
