import styled, { css } from 'styled-components';

interface ITextStyle {
  isStrong?: boolean;
}

interface IStepStyle {
  connectorHeight: number;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperFinance = styled.div`
  display: flex;
`;

export const WrapperFinanceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  width: 100%;

  gap: 2rem;
`;

export const StepIconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2px;
`;

export const Connector = styled.div<IStepStyle>`
  border-left: 2px solid #d3d3d3;
  height: ${(props) => props.connectorHeight}px;
`;

export const StepIcon = styled.div`
  background-color: #d3d3d3;
  z-index: 1;
  color: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10px 0 0 10px;

  white-space: nowrap;
  overflow: hidden;
`;

export const WrapperTransactionValues = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const WrapperCurrency = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerCurrency = styled.div`
  min-width: 95px;
  max-width: 95px;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p<ITextStyle>`
  ${({ isStrong }) => css`
    font-size: 0.75rem;
    font-weight: ${isStrong ? '600' : '400'};
    text-transform: uppercase;

    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const TextValue = styled.p<ITextStyle>`
  ${({ isStrong }) => css`
    font-size: 0.75rem;
    font-weight: ${isStrong ? '600' : '400'};
    text-transform: uppercase;

    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

export const TextGR = styled.p<ITextStyle>`
  ${({ isStrong }) => css`
    font-size: 0.75rem;
    font-weight: ${isStrong ? '600' : '400'};
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;

    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

export const ModalGRInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
`;

export const ModalGRContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const Div = styled.div``;
