import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    margin-top: 10px;
  `}
`;

export const ContainerNegotiationsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  padding: 20px 0 0 0;
`;

export const ContainerResolutionsStyled = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 0 20px 0;
`;

export const ContainerListStyled = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 0 20px 0;
`;

export const ContainerTableStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const TableStyled = styled.div`
  font-size: 11px;
`;

export const ContainerInfoStyled = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  flex: 0 0 50%;
  max-width: 400x;
`;

export const TableDescriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  max-width: 800px;
`;

export const TableDescriptionModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 700;
`;

export const ContainerInfoDescriptionStyled = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;

  @media (min-width: 768px) {
    max-width: 450px;
  }
`;

export const DividerStyled = styled.div``;

export const ContainerModalStyled = styled.div``;

export const WrapperSectionStyled = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  margin-top: 15px;
`;

export const HeaderTitleStyled = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 20px;
    margin-bottom: 15px;
  `}
`;

export const HeaderTitleDealings = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.black};

    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 15px;
  `}
`;

export const SectionContainerStyled = styled.div`
  margin-right: 20px;
`;
