import React from 'react';

import UserUpdateForm from '../user-update-form/user-update-form';

import { UserUpdateContainerStyled } from './user-update-body.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const UserUpdateBodyView: React.FC = () => {
  return (
    <UserUpdateContainerStyled>
      <HeaderPage breadcrumbList={['Configurações', 'Usuários']} hasBackButton />
      <Title title="Atualizar Usuário" hasTrace isForm />
      <UserUpdateForm />
    </UserUpdateContainerStyled>
  );
};
export default UserUpdateBodyView;
