import styled, { css } from 'styled-components';

export const LabelStyled = styled.div`
  ${() => css`
    padding-left: 3px;
    color: #000;
    font-family: Montserrat, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;
