import styled from 'styled-components';
import { Form, Formik } from 'formik';

export const ContainerSearchStyled = styled.div`
  display: flex;
`;

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const AccessRestrictedStyled = styled.div`
  color: rgb(235 235 235);
  font-weight: 700;
  font-size: 60px;
`;
export const ContainerAccessStyled = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const WrapperScheduleStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  row-gap: 10px;
  column-gap: 15px;
`;
