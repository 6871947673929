import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { InNegociationMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListDomain } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/domains/matches-kanban-list/matches-kanban-list.domain';
import { IInNegociationMatchesKanbanList } from '../../types/in-negotiation-matches-kanban.types';
import { inNegotiationMatchesKanbanListReducer } from 'src-new/pages/opportunities/pages/matches/contexts/matches/reducers/in-negotiation-matches-kanban-list/in-negotiation-matches-kanban-list.reducer';

export const useInNegotiationMatchesKanbanListContextValues = (): IInNegociationMatchesKanbanList => {
  const [state, dispatch] = useReducer(inNegotiationMatchesKanbanListReducer, matchesInitialState);

  const setInNegotiationMatchesKanbanListRequest = useCallback(() => {
    dispatch({
      type: InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_REQUEST,
      payload: undefined,
    });
  }, []);

  const setInNegotiationMatchesKanbanListSuccess = useCallback((kanban: IMatchesKanbanListDomain) => {
    dispatch({ type: InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_SUCCESS, payload: kanban });
  }, []);

  const setInNegotiationMatchesKanbanListError = useCallback(() => {
    dispatch({
      type: InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_ERROR,
      payload: undefined,
    });
  }, []);

  const setInNegotiationMatchesKanbanListReset = useCallback(() => {
    dispatch({
      type: InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_RESET,
      payload: undefined,
    });
  }, []);

  return {
    ...state.inNegotiationMatchesKanbanList,
    setInNegotiationMatchesKanbanListRequest,
    setInNegotiationMatchesKanbanListSuccess,
    setInNegotiationMatchesKanbanListError,
    setInNegotiationMatchesKanbanListReset,
  };
};
