import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { ContainerSearchStyled, DropDownStyles, NewFreight } from './transport-company-list-body-view.styled';
import TransportCompanyTable from './components/transport-company-table/transport-company-table';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { ITransportCompanyV2 } from 'domain/transport-company';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';
import TooltipComponent from 'components/Tooltip';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

interface ITransportCompanyListBodyViewProps {
  transportCompanies: Array<ITransportCompanyV2>;
  transportCompaniesPageInfo?: PageInfo;
  hasLoading: boolean;
  goToCreateTransportCompany: () => void;
  goToDetailsPage: (transportId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  handleOpenUploadXlsx: () => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  idsToRemove: number[];
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
  handleRemoveTransport: (ids: number[]) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'transport-company-list-body-view';

const TransportCompanyListBodyView: React.FC<ITransportCompanyListBodyViewProps> = ({
  transportCompanies,
  transportCompaniesPageInfo,
  hasLoading,
  goToCreateTransportCompany,
  goToDetailsPage,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
  handleOpenUploadXlsx,
  onHandleExport,
  options,
  idsToRemove,
  setIdsToRemove,
  handleRemoveTransport,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}: ITransportCompanyListBodyViewProps): JSX.Element => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return transportCompanies.map((transportCompany) => {
      return [
        {
          value: (
            <Checkbox
              id="transportCompany.id"
              name="transportCompany.id"
              value={String(transportCompany.id)}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, transportCompany.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== transportCompany.id)])
              }
            />
          ),
          flex: 0.2,
        },
        {
          value: transportCompany.id,
          flex: 0.5,
        },
        {
          value: isAdmin ? transportCompany.company?.tradeName : ' ',
          flex: isAdmin ? 0.8 : 0,
        },
        {
          value: transportCompany.name,
          flex: 2,
        },
        {
          value: transportCompany.cnpj,
          flex: 1,
        },
        {
          value: transportCompany.city + ' - ' + transportCompany.uf,
          flex: 1.3,
        },
        {
          value: (
            <Tag
              label={transportCompany.status}
              color={transportCompany.status === 'ativo' ? ColorsV2Type.Green : ColorsV2Type.Red}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.45,
        },
      ];
    });
  }, [transportCompanies]);

  const renderTransportCompanyTable = useMemo(() => {
    return (
      <TransportCompanyTable
        contentTable={contentTable}
        pageInfo={transportCompaniesPageInfo}
        changePage={setCurrentPage}
        goToDetailsPage={goToDetailsPage}
        isLoading={hasLoading}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [contentTable, goToDetailsPage, hasLoading, setCurrentPage, transportCompaniesPageInfo]);

  const tabContent = useMemo(() => {
    const availableTabs = [
      {
        name: 'Ativas',
        content: renderTransportCompanyTable,
      },
      {
        name: 'Inativos',
        content: renderTransportCompanyTable,
      },
    ];

    return availableTabs;
  }, [isAdmin, renderTransportCompanyTable]);

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Cadastro', 'Minhas Transportadoras']} />
      <Title
        dataTestId={dataTestId}
        title="Cadastre as transportadoras parceiras para realizar operações de venda de ociosidade."
        hasTrace
      />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input id="search" name="search" placeholder="Pesquise por Nome" type="text" changeValue={setSearchInput} />

        <DropDownStyles>
          <DropDown title="Exportar">
            <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
          </DropDown>
        </DropDownStyles>

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => handleOpenUploadXlsx()}
              LeftIcon={<CloudUploadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Importar'}
          location={'bottom'}
        />
        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>

      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateTransportCompany} />
          <FooterPage.Button
            label="Excluir"
            color="white"
            variant="ghost"
            onPress={() => handleRemoveTransport(idsToRemove)}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default TransportCompanyListBodyView;
