import React from 'react';

import ContentStyled from '../../components/template-authentication/template-authentication';

import FormResetPassword from './reset-password-validation/components/form-reset-password/form-reset-password';
import {
  ResetLoginContainerStyled,
  TextContainerStyled,
  HelloStyled,
  EmailInformationStyled,
  ResetPasswordFormStyled,
} from './reset-login.styled';

const ResetLoginView = () => {
  return (
    <ContentStyled>
      <ResetLoginContainerStyled>
        <TextContainerStyled>
          <HelloStyled>Recupere sua senha!</HelloStyled>
          <EmailInformationStyled>
            Informe seu <strong>login</strong> e <strong>e-mail cadastrado</strong> <br /> para receber sua nova{' '}
            <strong>senha</strong>.
          </EmailInformationStyled>
        </TextContainerStyled>
        <ResetPasswordFormStyled>
          <FormResetPassword />
        </ResetPasswordFormStyled>
      </ResetLoginContainerStyled>
    </ContentStyled>
  );
};

export default ResetLoginView;
