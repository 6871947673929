import { homeCardsInitialValuesDomain } from 'src-new/pages/home/pages/home/domains/home-cards/home-cards-initial-values.domain';
import { IHomeContext } from '../types/home.types';

export const homeReducerResetState = (state: IHomeContext): IHomeContext => {
  return {
    ...state,
    homeCards: {
      ...state.homeCards,
      statusRequest: 'INITIAL',
      homeCards: homeCardsInitialValuesDomain,
    },
    homeMatches: {
      ...state.homeMatches,
      statusRequest: 'INITIAL',
      homeMatches: [],
      paginationActions: {
        ...state.homeMatches.paginationActions,
        nextPage: undefined,
        isPageLimit: false,
      },
    },
    homeBids: {
      ...state.homeBids,
      statusRequest: 'INITIAL',
      homeBids: [],
    },
    homeModals: {
      ...state.homeModals,
      open: false,
      homeModalType: '',
    },
  };
};
