import { FC, useContext, useMemo } from 'react';
import { ScheduleDetailsRequirementsView } from './schedule-details-requirements.component-view';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const ScheduleDetailsRequirements: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const requirements = useMemo(() => {
    const qualityValues = scheduleDetails.scheduleDetails.cargo.requirements.quality?.values ?? [];
    const securityValues = scheduleDetails.scheduleDetails.cargo.requirements.security?.values ?? [];

    return [...qualityValues, ...securityValues];
  }, [
    scheduleDetails.scheduleDetails.cargo.requirements.quality?.values,
    scheduleDetails.scheduleDetails.cargo.requirements.security?.values,
  ]);

  return <ScheduleDetailsRequirementsView requirements={requirements} />;
};
