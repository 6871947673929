import { ChangeEvent } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { convertFinancialStatus } from 'utils-v2/converters/financial-status';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { convertFreightType } from 'utils/freight-type';
import { CalcFinanceList } from 'domain-v2/transactions';
import Checkbox from 'components/checkbox/checkbox';
import dayjs from 'dayjs';

export const mountReceiveList = (
  transaction: CalcFinanceList,
  ids: Array<number>,
  isAdmin: boolean,
  handleCheckboxChange: (
    event: ChangeEvent<HTMLInputElement>,
    scheduleIds: Array<number>,
    calcFinanceIds: Array<number>,
    takeNames: Array<string>,
    sellerNames: Array<string>,
    price: Array<number>,
    takerName: string,
    type: Array<string>,
  ) => void,
  isTransactionDisabled: (transaction: CalcFinanceList) => boolean,
) => {
  const takerName = transaction.taker;
  const sellerNames = transaction.invoicedBy;

  return [
    {
      value: (
        <Checkbox
          checked={ids.includes(transaction.id)}
          id="transaction.schedule.id"
          name="transaction.schedule.id"
          value={String(0)}
          onChange={(event) => {
            handleCheckboxChange(
              event,
              [transaction.schedule.id],
              [transaction.id],
              [takerName],
              [sellerNames],
              [transaction.totalService],
              takerName,
              [transaction.status],
            );
          }}
          disabled={isTransactionDisabled(transaction)}
        />
      ),
      flex: 0.1,
    },
    {
      value: transaction.schedule.id,
      flex: 0.2,
    },
    {
      value: (
        <Tag
          label={convertFreightType(transaction.schedule.typeSchedule).label}
          color={convertFreightType(transaction.schedule.typeSchedule).color}
          variant="solid"
          display="flex"
          size="md"
        />
      ),
      flex: 0.4,
    },
    {
      value: moment(transaction.schedule.scheduleDetails.deliveryEndDate).format('DD/MM/YYYY'),
      flex: 0.4,
    },
    {
      value: transaction.taker,
      flex: 0.7,
    },
    {
      value: !isAdmin ? '' : transaction.invoicedBy,
      flex: !isAdmin ? 0 : 0.7,
    },
    {
      value: transaction.schedule.cte.primary || '-',
      flex: 0.8,
    },
    {
      value: transaction.schedule.cte.sub || '-',
      flex: 0.8,
    },
    {
      value: dayjs(transaction.dueDate).format('DD/MM/YYYY'),
      flex: 0.5,
    },
    {
      value: convertCurrencyFormat.format(transaction.totalService),
      flex: 0.5,
    },
    {
      value: (
        <Tag
          label={convertFinancialStatus(transaction.financialStatus).label}
          color={convertFinancialStatus(transaction.financialStatus).color}
          variant="solid"
          display="flex"
          size="md"
        />
      ),
      flex: 0.6,
    },
  ];
};

export const mountReceiveHeader = (
  isAdmin: boolean,
  ids: Array<number>,
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleClearSelection: () => void,
) => {
  return [
    {
      value: (
        <div style={{ marginTop: -1 }}>
          <Checkbox
            id="checkboxAll"
            name="checkboxAll"
            checked={ids.length > 0}
            value={String(0)}
            onChange={(event) => {
              if (ids.length > 0) {
                handleClearSelection();
              } else {
                handleChangeCheckbox(event);
              }
            }}
          />
        </div>
      ),
      flex: 0.1,
    },
    {
      value: 'ID',
      flex: 0.2,
    },
    {
      value: 'Categoria',
      flex: 0.4,
    },

    {
      value: 'Data Entrega',
      flex: 0.4,
    },
    {
      value: 'Pagador',
      flex: 0.7,
    },
    {
      value: !isAdmin ? '' : 'Recebedor',
      flex: !isAdmin ? 0 : 0.7,
    },
    {
      value: 'CTe Primário',
      flex: 0.8,
    },
    {
      value: 'CTe Subcontratação',
      flex: 0.8,
    },
    {
      value: 'Data de Vencto',
      flex: 0.5,
    },
    {
      value: 'Total do Serviço',
      flex: 0.5,
    },
    {
      value: 'Status',
      flex: 0.6,
    },
  ];
};
