import React, { useEffect } from 'react';

import ScheduleOriginFormView from './schedule-origin-form-view';

import { IFormSectionProps } from 'pages/schedule-ltl/types/form-section.types';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';

const ScheduleOriginForm: React.FC<IFormSectionProps> = ({
  errors,
  values,
  handleChange,
  touched,
  setFieldValue,
  apiCall,
  handleChangeValues,
  selectedFreight,
}) => {
  const handleCompleteInputs = (clientId: number, localityId: number, clientType: string) => {
    if (clientType === 'client' && clientId) {
      clientDetailsService(String(clientId)).then((client) => {
        setFieldValue('origin.cnpj', client.cnpj);
        setFieldValue('origin.zipCode', client.address.cep);
        setFieldValue('origin.city', client.address.city);
        setFieldValue('origin.state', client.address.uf);
        setFieldValue('origin.complement', client.address.complement);
        setFieldValue('origin.neighborhood', client.address.neighborhood);
        setFieldValue('origin.number', client.address.numberHouse);
        setFieldValue('origin.street', client.address.street);
      });
    } else if (clientType === 'plant' && localityId) {
      locationDetailsService(String(localityId)).then((locality) => {
        setFieldValue('origin.cnpj', locality.cnpj);
        setFieldValue('origin.zipCode', locality.address.cep);
        setFieldValue('origin.city', locality.address.city);
        setFieldValue('origin.state', locality.address.uf);
        setFieldValue('origin.complement', locality.address.complement);
        setFieldValue('origin.neighborhood', locality.address.neighborhood);
        setFieldValue('origin.number', locality.address.numberHouse);
        setFieldValue('origin.street', locality.address.street);
      });
    }
  };

  useEffect(() => {
    handleChangeValues && handleChangeValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values, selectedFreight]);

  return (
    <ScheduleOriginFormView
      errors={errors}
      values={values}
      handleChange={handleChange}
      touched={touched}
      setFieldValue={setFieldValue}
      apiCall={apiCall}
      handleChangeValues={handleChangeValues}
      handleCompleteInputs={handleCompleteInputs}
    />
  );
};

export default ScheduleOriginForm;
