import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useLoginHook } from '../../hooks/login.hook';
import LoginBodyView from './login-body-view';
import ModalTerms from './components/modal-terms/modal-terms';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useUserTermsConditionsHook } from 'pages/login/hooks/terms-conditions.hook';
import { IUser } from 'domain/login';
import { obterToken } from 'src-new/services/get-token-aws-cognito/get-token-aws-cognito.service';
import { LoginResponse } from 'pages/login/services/entities/login.entity';

const LoginBody: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalTerms, setModalTerms] = useState<{
    openModal: boolean;
    username: string;
    token: string;
  }>({ openModal: false, username: '', token: '' });
  const { clearUser, setUser } = useContext(UserContext);

  const login = useLoginHook();
  const userTermsConditions = useUserTermsConditionsHook();

  useEffect(() => {
    const obterParametrosURL = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return Object.fromEntries(urlParams.entries());
    };

    const verificarErroURL = async () => {
      const parametrosURL = obterParametrosURL();
      if (parametrosURL.error) {
        console.error('Erro de autorização:', parametrosURL.error);
        console.error('Descrição do erro:', parametrosURL.error_description);
        navigate('/login');
      } else {
        try {
          const resultado = await obterToken(parametrosURL.code);
          sessionStorage.setItem('token', resultado.token);
          sessionStorage.setItem('microsoftEmail', resultado.email);
          await setUser();
          navigate('/home');
        } catch (error) {
          console.log('ERROR DE DESENVOLVIENT', error);
        }
      }
    };
    verificarErroURL();
  }, []);

  const handleTermsConditions = useCallback(
    async (username: string) => {
      try {
        const { data } = await userTermsConditions(username);
        return data;
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    },
    [userTermsConditions],
  );

  const handleConditions = useCallback(
    async (login: LoginResponse, username: string) => {
      const userData: IUser = jwt(login.access_token);

      if (userData.profile === 'driver') {
        showMessageFormatted({
          type: 'error',
          message: 'Usuário/Senha invalido',
        });

        return;
      }

      if (userData.profile === 'shipping-company') {
        const hasTerms = await handleTermsConditions(username);

        if (!hasTerms) {
          setModalTerms({
            openModal: true,
            username: username,
            token: login.access_token,
          });

          return;
        }
      }

      sessionStorage.setItem('token', login.access_token);
      setUser();
      navigate('/home', { state: { from: 'login' } });
    },
    [handleTermsConditions, navigate, setUser],
  );

  const handleLogin = useCallback(
    async (email: string, password: string, recaptchaLogin: string) => {
      setIsLoading(true);
      try {
        const res = await login(email, password, recaptchaLogin);

        if (!res.access_token) {
          showMessageFormatted({
            message: '',
            type: 'error',
          });
        } else {
          handleConditions(res, email);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [handleConditions, login],
  );

  useEffect(() => {
    sessionStorage.clear();
    clearUser();
  }, [clearUser]);

  return (
    <>
      {modalTerms.openModal && (
        <ModalTerms
          token={modalTerms.token}
          login={modalTerms.username}
          onClose={() => setModalTerms({ openModal: false, username: '', token: '' })}
        />
      )}

      <LoginBodyView isLoading={isLoading} handleLogin={handleLogin} />
    </>
  );
};

export default LoginBody;
