import React, { useState } from 'react';

import TrackingOccurrenceUpdateForm from '../tracking-occurrence-update-form/tracking-occurrence-update-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const TrackingOccurrenceBodyView: React.FC = () => {
  const [allocationInfo, setAllocationInfo] = useState<{
    allocationID: number;
  }>({
    allocationID: 0,
  });

  const handleGetAllocation = (allocationID: number) => {
    setAllocationInfo({ allocationID });
  };

  return (
    <React.Fragment>
      <HeaderPage
        dataTestId="driver-register-body"
        breadcrumbList={['Tracking', `Ocorrência ID ${allocationInfo?.allocationID}`]}
        hasBackButton
      />
      <Title title="Atualizar Ocorrência" hasTrace isForm />
      <TrackingOccurrenceUpdateForm handleGetAllocation={handleGetAllocation} />
    </React.Fragment>
  );
};

export default TrackingOccurrenceBodyView;
