import { Dispatch, FC, SetStateAction, memo, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import { TransactionListModal } from '../list';
import HoursUpdateModal from '../form-hours/form-hours';

import * as S from './styles';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { AllocationIcon } from 'pages/freight/freight-update/freight-update-form/freight-register-section/styled';
import { invoicingAvailableScheduleTableListFormat } from 'pages/fiscal-document/utils/table-list-format';
import { headerValuesCalculation } from 'pages/fiscal-document/utils/header-values-calculation';
import { IBranch, IHourUpdate, IInvoice } from 'pages/fiscal-document/models';
import { useDeleteFileUploadedHook, useUploadFileInvoiceHook } from 'pages/fiscal-document/hooks';
import { FinancialSchedulesTable } from 'pages/fiscal-document/fiscal-document-list/components/financial-schedules-table/table';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';

interface InvoiceSchedulesProps {
  invoice: IInvoice;
  branch?: IBranch;
  hoursUpdate: IHourUpdate;
  onSuccess: (id?: number) => void;
  onClose: () => void;
  transactionIds: Array<number>;
  selectTransaction: (transactionId: number) => void;
  setTransactionIds: Dispatch<SetStateAction<Array<number>>>;
  handleHoursUpdate: (values: IHourUpdate) => void;
  handleCreatePdf: (connectedInvoices: ConnectedInvoices[], invoiceId: number) => void;
}

const InvoiceSchedulesModal: FC<InvoiceSchedulesProps> = ({
  invoice,
  branch,
  hoursUpdate,
  transactionIds,
  onClose,
  onSuccess,
  selectTransaction,
  setTransactionIds,
  handleHoursUpdate,
  handleCreatePdf,
}) => {
  const [invoiceValues, setInvoiceValues] = useState<IInvoice>(invoice);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [modalAddIds, setModalAddIds] = useState<boolean>(false);
  const [modalClose, setModalClose] = useState<boolean>(false);
  const [headerValues, setHeaderValues] = useState<{
    addition: number;
    discount: number;
    total: number;
  }>({
    addition: 0,
    discount: 0,
    total: 0,
  });

  const invoicingFormRef = useRef<FormikProps<IHourUpdate>>(null);

  const uploadFileInvoiceHook = useUploadFileInvoiceHook();

  const handleDeleteImage = useDeleteFileUploadedHook();

  useEffect(() => {
    const headerValue = headerValuesCalculation(
      invoiceValues.connectedInvoices,
      headerValues.addition === 0 ? invoiceValues.addition : headerValues.addition,
      headerValues.discount === 0 ? invoiceValues.discount : headerValues.discount,
    );

    setHeaderValues(headerValue);
  }, [invoiceValues, transactionIds]);

  const handleUploadFile = async (file: File[], name: string) => {
    if (name === 'invoicing') await uploadFileInvoiceHook(invoice.id, { invoiceFile: file[0] });
    if (name === 'bankSlip') await uploadFileInvoiceHook(invoice.id, { bankSlipFile: file[0] });
    if (name === 'attachment') await uploadFileInvoiceHook(invoice.id, { attachment: file[0] });

    onSuccess();
  };

  const handleDeleteFile = async (invoiceId: number, type: string) => {
    await handleDeleteImage(invoiceId, type);

    onSuccess();
  };

  const handleAddTransaction = (invoice: Array<ConnectedInvoices>) => {
    setInvoiceValues((prevState) => ({
      ...prevState,
      connectedInvoices: prevState.connectedInvoices.concat(invoice),
    }));

    setModalAddIds(false);
    const selectedIds = invoice.map((invoice) => {
      return invoice.calcFinance.id;
    });

    setTransactionIds((prevState) => prevState.concat(selectedIds));
  };

  const handleClose = () => {
    if (
      invoice.connectedInvoices.length !== invoiceValues.connectedInvoices.length ||
      Object.keys(hoursUpdate).length !== 0
    ) {
      setModalClose(true);
      return;
    }

    onClose();
  };

  const handleHeaderValues = (values: IHourUpdate) => {
    const headerValue = headerValuesCalculation(invoiceValues.connectedInvoices, values.addition, values.discount);

    setHeaderValues(headerValue);

    handleHoursUpdate(values);

    setModalEdit(false);
  };

  const contentTable = useMemo(() => {
    return invoiceValues.connectedInvoices.map((connectedInvoice) =>
      invoicingAvailableScheduleTableListFormat(
        connectedInvoice.schedule,
        transactionIds,
        selectTransaction,
        connectedInvoice.calcFinance,
      ),
    );
  }, [invoiceValues, transactionIds]);

  const invoiceDates = useMemo(() => {
    const createdAt = moment(hoursUpdate?.createdAt ?? invoice.createdAt);

    const dueDate = moment(hoursUpdate?.dueDate ?? invoice.dueDate);

    const paymentDay = moment(hoursUpdate?.paymentDay ?? invoice.paymentDay);

    return { createdAt, dueDate, paymentDay };
  }, [invoiceValues, hoursUpdate, invoice]);

  const renderInvoiceButton = useMemo(() => {
    if (!invoice.attachment) {
      return (
        <InputFile onChange={handleUploadFile} name="attachment">
          <Button title="Anexar" bgColor="blue" size="medium" />
        </InputFile>
      );
    }

    const regex = /\/(\d+)\/([^/]+\.pdf)/;
    const match = invoice.attachment?.match(regex);
    const attachmentName = match && match[2];

    if (attachmentName !== 'attachment_file.pdf') {
      return (
        <Button
          title="Visualizar"
          size="medium"
          bgColor="blue"
          callback={() => handleCreatePdf(invoice.connectedInvoices, invoice.id)}
        />
      );
    }

    return (
      <a href={invoice.attachment} target="_blank" rel="noreferrer">
        <Button title="Visualizar" bgColor="blue" size="medium" />
      </a>
    );
  }, [invoice.id, invoice.attachment, invoice.connectedInvoices, handleCreatePdf]);

  return (
    <>
      {modalEdit && (
        <ModalConfirmation
          title="Horários"
          description={
            <Formik
              innerRef={invoicingFormRef}
              initialValues={{
                createdAt: moment(invoiceDates.createdAt).format('DD/MM/YYYY'),
                dueDate: moment(invoiceDates.dueDate).format('DD/MM/YYYY'),
                paymentDay: moment(invoiceDates.paymentDay).format('DD/MM/YYYY'),
                addition: String(headerValues.addition),
                discount: String(headerValues.discount),
                description: '',
              }}
              onSubmit={handleHeaderValues}
            >
              <HoursUpdateModal />
            </Formik>
          }
          handleXCancel={() => setModalEdit(false)}
          fullButtonWidth
          size="small"
          type="form"
        />
      )}

      {modalAddIds && (
        <TransactionListModal
          company={invoice.connectedInvoices[0].schedule.company}
          branch={branch}
          invoices={invoiceValues.connectedInvoices}
          handleAddTransaction={handleAddTransaction}
          handleClose={() => setModalAddIds(false)}
        />
      )}

      {modalClose && (
        <ModalConfirmation
          description={
            <p>
              Tem certeza de que deseja fechar sem salvar suas alterações? Todas as mudanças não salvas serão perdidas.
            </p>
          }
          type="normal"
          size="medium"
          title="Alterações Não Salvas"
          handleCancel={() => setModalClose(false)}
          legacy
          FooterChildren={<Button title="Confirmar" bgColor="blue" size="medium" callback={onClose} />}
        />
      )}

      <S.Wrapper>
        <S.CloseModalContainer>
          <S.CloseModal onClick={handleClose}>X</S.CloseModal>
        </S.CloseModalContainer>

        <S.Header>
          <S.HeaderContainer>
            <S.HeaderValues>
              Pagador
              <S.HeaderText>{invoice.companyBuyer.tradeName}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Recebedor
              <S.HeaderText>{invoice.companySeller.tradeName}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Emitida em:
              <S.HeaderText>{invoiceDates.createdAt.format('DD/MM/YYYY')}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Vencimento em:
              <S.HeaderText>{invoiceDates.dueDate.format('DD/MM/YYYY')}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Liquidada em:
              <S.HeaderText>
                {invoiceDates.paymentDay.isValid() ? invoiceDates.paymentDay.format('DD/MM/YYYY') : '-'}
              </S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Acréscimo:
              <S.HeaderText>{convertCurrencyFormat.format(headerValues.addition)}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Desconto:
              <S.HeaderText>{convertCurrencyFormat.format(headerValues.discount)}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderValues>
              Valor da Fatura:
              <S.HeaderText>{convertCurrencyFormat.format(headerValues.total)}</S.HeaderText>
            </S.HeaderValues>

            <S.HeaderButton onClick={() => setModalEdit(true)}>
              Editar
              <AllocationIcon>
                <CreateIcon sx={{ height: 35, width: 20 }} />
              </AllocationIcon>
            </S.HeaderButton>
          </S.HeaderContainer>
        </S.Header>

        <FinancialSchedulesTable
          contentTable={contentTable}
          changePage={() => null}
          isLoading={false}
          goToDetailsPage={() => null}
          hideSelect
        />

        <div style={{ marginTop: '10px' }}>
          <Button
            title="Adicionar"
            bgColor="blue"
            size="very-small"
            callback={() => setModalAddIds(true)}
            icon={<AddIcon />}
          />
        </div>

        <S.Footer>
          <S.FooterContainer>
            <S.FooterValues>
              <S.FooterText>Nota Fiscal</S.FooterText>

              <S.FooterButton>
                {invoice.invoice ? (
                  <a href={invoice.invoice} target="_blank" rel="noreferrer">
                    <Button title="Visualizar" bgColor="blue" size="medium" />
                  </a>
                ) : (
                  <InputFile onChange={handleUploadFile} name="invoicing">
                    <Button title="Anexar" bgColor="blue" size="medium" />
                  </InputFile>
                )}

                <Button
                  title="Deletar"
                  bgColor="pink"
                  size="small"
                  icon={<DeleteIcon />}
                  callback={() => handleDeleteFile(invoice.id, 'NOTA_FISCAL')}
                  disabled={!invoice.invoice}
                />
              </S.FooterButton>
            </S.FooterValues>

            <S.FooterValues>
              <S.FooterText>Boleto Bancário</S.FooterText>

              <S.FooterButton>
                {invoice.bankSlip ? (
                  <a href={invoice.bankSlip} target="_blank" rel="noreferrer">
                    <Button title="Visualizar" bgColor="blue" size="medium" />
                  </a>
                ) : (
                  <InputFile onChange={handleUploadFile} name="bankSlip">
                    <Button title="Anexar" bgColor="blue" size="medium" />
                  </InputFile>
                )}

                <Button
                  title="Deletar"
                  bgColor="pink"
                  size="small"
                  icon={<DeleteIcon />}
                  callback={() => handleDeleteFile(invoice.id, 'BOLETO')}
                  disabled={!invoice.bankSlip}
                />
              </S.FooterButton>
            </S.FooterValues>

            <S.FooterValues>
              <S.FooterText>Fatura</S.FooterText>

              <S.FooterButton>
                {renderInvoiceButton}

                <Button
                  title="Deletar"
                  bgColor="pink"
                  size="small"
                  icon={<DeleteIcon />}
                  callback={() => handleDeleteFile(invoice.id, 'FATURA')}
                  disabled={!invoice.attachment}
                />
              </S.FooterButton>
            </S.FooterValues>
          </S.FooterContainer>
        </S.Footer>
      </S.Wrapper>
    </>
  );
};

export default memo(InvoiceSchedulesModal);
