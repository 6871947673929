import { IScheduleListTotalRow } from './schedule-list-total-row.types';

export const totalRowInitialValues: IScheduleListTotalRow = {
  'A Iniciar': 0,
  'Em Alocação': 0,
  'Em Andamento': 0,
  Cancelados: 0,
  Finalizados: 0,
  Prospectados: 0,
  Todos: 0,
};
