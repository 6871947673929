import React, { useCallback, useContext } from 'react';
import { TimeRecordingCheckModalView } from './execution-time-recording-check-modal-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { convertTimeRecordingLabelValues } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/utils/convert-time-recording-label-values.util';
import { useUpdateScheduleDetailsDateTime } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-details-date-time/use-update-schedule-details-date-time.hook';
import dayjs from 'dayjs';

export const TimeRecordingCheckModal: React.FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleDetailsDateTime = useUpdateScheduleDetailsDateTime();

  const handleSubmit = useCallback(() => {
    const timeRecordingLabelValues = convertTimeRecordingLabelValues(scheduleDetails.allocationActions.allocatedEntity);
    const currentDate = dayjs().toISOString();

    updateScheduleDetailsDateTime({
      ...scheduleDetails.scheduleDetails.detail,
      [timeRecordingLabelValues]: currentDate,
    });
  }, [
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.scheduleDetails.detail,
    updateScheduleDetailsDateTime,
  ]);

  const handleCloseModal = useCallback(() => scheduleDetails.setScheduleDetailsAllocationClose(), [scheduleDetails]);

  return <TimeRecordingCheckModalView handleSubmit={handleSubmit} handleCloseModal={handleCloseModal} />;
};
