import { PurchaseOfferModalsActionType } from '../../purchase-offer.action';
import { IPurchaseOfferModalsActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-modals.types';
import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';

export const purchaseOfferModalsReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferModalsActions,
): IPurchaseOfferContext => {
  switch (action.type) {
    case PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_OPEN:
      return {
        ...state,
        purchaseOfferModals: {
          ...state.purchaseOfferModals,
          isOpen: true,
          modalType: action.payload.modalType,
          secondModalType: action.payload.secondModalType,
        },
      };
    case PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_CLOSE:
      return {
        ...state,
        purchaseOfferModals: {
          ...state.purchaseOfferModals,
          indexSection: undefined,
          isOpen: false,
          modalType: '',
          secondModalType: '',
        },
      };
    case PurchaseOfferModalsActionType.PURCHASE_OFFER_MODAL_SECONDARY_CLOSE:
      return {
        ...state,
        purchaseOfferModals: {
          ...state.purchaseOfferModals,
          indexSection: undefined,
          isOpen: true,
          secondModalType: '',
        },
      };
    default:
      return state;
  }
};
