import styled, { keyframes } from 'styled-components';

export const SpinnerContainerStyled = styled.div`
  position: fixed;
  bottom: 31px;
  right: 30px;
  padding: 4px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 4px solid rgb(18, 38, 78);
  border-left-color: #ececec;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: ${spin} 0.7s linear infinite;
`;
