import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { IResponseMatches, IResponseSchedulesMatches } from 'domain/matches';
import { IdlenessListInput, IdlenessValues } from 'domain/idleness';
import { IBackhaulCreated } from 'domain/backhaul';
import { getLocationValues } from 'components/matches/utils/get-location-values';

export const convertIdlenessValues = (
  values?: IdlenessListInput | ScheduleRegisterInput | IBackhaulCreated,
): IdlenessValues => {
  const idlenessValues = values as IdlenessListInput;
  const scheduleValues = values as ScheduleRegisterInput;
  const transportRouteValues = values as IBackhaulCreated;

  const originValues = getLocationValues('origin', idlenessValues, scheduleValues, transportRouteValues);

  const destinyValues = getLocationValues('destination', idlenessValues, scheduleValues, transportRouteValues);

  const vehicleType =
    idlenessValues?.vehicle_type ??
    idlenessValues?.vehicleType ??
    transportRouteValues?.vehicleType ??
    scheduleValues?.cargo?.vehicleType;

  const vehicleCategory =
    idlenessValues?.vehicle_category ??
    idlenessValues?.category ??
    transportRouteValues?.vehicleCategory ??
    scheduleValues?.cargo?.vehicleCategoryType;

  const freightValue =
    idlenessValues?.freightValue ?? transportRouteValues?.freightValue ?? scheduleValues?.cost?.valueFreight ?? 0;

  const offeredWeight =
    idlenessValues?.offeredWeight ?? transportRouteValues?.offeredWeight ?? scheduleValues?.cargo?.weight ?? 0;

  const offeredPallet =
    idlenessValues?.offeredPallet ?? transportRouteValues?.offeredPallet ?? scheduleValues?.cargo?.pallet ?? 0;

  const frequency = idlenessValues?.frequency ?? transportRouteValues?.frequency ?? 0;

  return {
    originId: Number(originValues.id),
    originType: originValues.type,
    originName: originValues.name,
    originCity: originValues.city,
    originUf: originValues.uf,
    destinyId: Number(destinyValues.id),
    destinyType: destinyValues.type,
    destinyName: destinyValues.name,
    destinyCity: destinyValues.city,
    destinyUf: destinyValues.uf,
    vehicleType,
    vehicleCategory,
    freightValue,
    offeredWeight,
    offeredPallet,
    frequency,
  };
};

export const convertTransportRouteValues = (values: IResponseSchedulesMatches[]) => {
  const transportRouterValues = values.map((value) => {
    const { transportRouter } = value;

    const origin = transportRouter?.clientOrigin || transportRouter?.localityOrigin;

    const destination = transportRouter?.clientDestination || transportRouter?.localityDestination;

    return {
      id: 0,
      co2Economy: value.co2Economy,
      company: null,
      synergy: {
        ...value.synergy,
      },
      transportRouter: {
        ...value.transportRouter,
        origin: origin,
        destination: destination,
      },
    };
  });

  return transportRouterValues as IResponseMatches[];
};
