import React, { useCallback, useMemo } from 'react';
import { AttachDocumentsModalFieldsView } from './attach-documents-modal-fields-view.component';
import { useFormikContext } from 'formik';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IAttachDocumentsModalFieldsProps {
  position: number;
}

export const AttachDocumentsModalFields: React.FC<IAttachDocumentsModalFieldsProps> = ({ position }) => {
  const { getFieldMeta, getFieldProps, handleChange, setFieldValue } = useFormikContext<RegistrationAttachmentValues>();

  const getErrorMessage = useCallback((name: string): string => getFieldMeta(name).error ?? '', [getFieldMeta]);

  const hasErrorField = useCallback(
    (name: string): boolean => !!getFieldMeta(name).error && getFieldMeta(name).touched,
    [getFieldMeta],
  );

  const handleInputDateTimeChange = useCallback(
    (date?: string): void => {
      if (date) {
        setFieldValue(`attachments[${position}].validateDate`, date);
      }
    },
    [position, setFieldValue],
  );

  const document = useMemo(
    (): IAttachmentDomain => getFieldProps(`attachments[${position}]`).value,
    [getFieldProps, position],
  );

  return (
    <AttachDocumentsModalFieldsView
      document={document}
      getErrorMessage={getErrorMessage}
      hasErrorField={hasErrorField}
      position={position}
      handleChange={handleChange}
      handleInputDateTimeChange={handleInputDateTimeChange}
      handleInputSelectChange={setFieldValue}
    />
  );
};
