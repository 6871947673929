import * as Yup from 'yup';

export const BackhaulRegisterSchema = Yup.object().shape({
  offeredPallet: Yup.string().max(32, 'Máximo 99 Paletes').required('Campo Obrigatório'),
  vehicleType: Yup.string().required('Campo Obrigatório'),
  initialDate: Yup.string().required('Campo Obrigatório'),
  endDate: Yup.string().required('Campo Obrigatório'),
  idOrigin: Yup.string().required('Campo Obrigatório').nullable(),
  idDestination: Yup.string().required('Campo Obrigatório').nullable(),
});
