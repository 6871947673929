import { dashboardDetailService } from 'pages/dashboard/services/dashboard-details.service';
import { IDashboard } from 'domain/dashboard';

interface IDasboardProps {
  startDate?: string;
  endDate?: string;
  companyId?: number;
  origin?: string;
  destiny?: string;
  shippingCompanyId?: string;
}

const useDashboardDetails = () => {
  return async (params: IDasboardProps): Promise<IDashboard> => {
    return await dashboardDetailService(params);
  };
};

export { useDashboardDetails };
