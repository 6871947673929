import { ColorsV2Type } from 'styles/types/colors';

type OperationType = {
  label: string;
  color: ColorsV2Type;
};

type OperationTypeMap = {
  [key: string]: OperationType;
};

const defaultValue: OperationType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertOperationType = (operationTypes: string): OperationType => {
  const operationTypeMap: OperationTypeMap = {
    OUTBOUND: {
      label: 'OUT',
      color: ColorsV2Type.Aqua,
    },
    INBOUND: {
      label: 'IN',
      color: ColorsV2Type.Blue,
    },
    TRANSFERENCIA: {
      label: 'TR',
      color: ColorsV2Type.Red,
    },
    OUT: {
      label: 'OUTBOUND',
      color: ColorsV2Type.Aqua,
    },
    IN: {
      label: 'INBOUND',
      color: ColorsV2Type.Blue,
    },
    TR: {
      label: 'TRANSFERENCIA',
      color: ColorsV2Type.Red,
    },
  };

  return operationTypeMap[operationTypes] || defaultValue;
};
