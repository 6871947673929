import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Subtitle = styled.div`
  color: #7b7b7b;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  text-transform: uppercase;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
