import { IPalletCheckStubsDomain, IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';
import { IPalletResponse } from 'src-new/pages/financial/services/get-pallet-by-id/get-pallet-by-id.types';

export const converterPalletResponseToDomain = (palletResponse: IPalletResponse): IPalletDomain => {
  const checkStubsValues: IPalletCheckStubsDomain = {
    id: 0,
    number: 0,
  };

  return {
    id: palletResponse.id,
    note: palletResponse.note ?? '',
    status: palletResponse.status,
    freightData: {
      scheduleId: palletResponse.scheduleId ?? 0,
      equipmentVoucher: palletResponse.equipmentVoucher,
      operationType: palletResponse.operationType ?? 'coleta',
      collectDate: palletResponse.collectDate ?? '',
      clientId: palletResponse.clientId ?? 0,
      clientPlant: palletResponse.clientPlant ?? '',
      clientName: palletResponse.client?.tradeName ?? '',
      distributionCenterId: palletResponse.distributionCenterId ?? 0,
      distributionCenterName: palletResponse.distributionCenter?.name ?? '',
    },
    palletData: {
      boardingNumber: palletResponse.boardingNumber ?? '',
      palletType: palletResponse.palletType,
      palletQuantity: palletResponse.palletQuantity,
      equipmentVoucherDate: palletResponse.equipmentVoucherDate,
      expirationDate: palletResponse.expirationDate ?? '',
      withdrawalDate: palletResponse.withdrawalDate,
      entryDate: palletResponse.entryDate,
      liberationDate: palletResponse.liberationDate ?? '',
    },
    checkStub: {
      status: palletResponse.checkStub?.status ?? 'nao_informado',
      analist: palletResponse.checkStub?.analist ?? '',
      receiptDate: palletResponse.checkStub?.receiptDate ?? '',
      deliveryDate: palletResponse.checkStub?.deliveryDate ?? '',
      withdrawalDate: palletResponse.checkStub?.withdrawalDate ?? '',
      entryDate: palletResponse.checkStub?.entryDate ?? '',
      checkStubs: palletResponse.checkStub?.checkStubs ?? [checkStubsValues],
    },
  };
};
