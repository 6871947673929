import { FC } from 'react';
import { useFormikContext } from 'formik';
import { WeighingTottenFormView } from './weighing-totten-details-form-view.component';
import { IWeighingTottenDetailsForm } from '../../../types/weighing-totten-details-form/weighing-totten-details-form.types';

export const WeighingTottenForm: FC = () => {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormikContext<IWeighingTottenDetailsForm>();

  return (
    <WeighingTottenFormView
      values={values}
      errors={errors}
      touched={touched}
      handleChange={handleChange}
      handleOnSubmit={handleSubmit}
      setFieldValue={setFieldValue}
    />
  );
};
