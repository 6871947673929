import React from 'react';

const ArrowUp: React.FC = () => {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 3.12132C-0.0976311 3.51184 -0.0976311 4.14501 0.292893 4.53553C0.683417 4.92606 1.31658 4.92606 1.70711 4.53553L3.91415 2.32849L6.12129 4.53563C6.51182 4.92615 7.14498 4.92615 7.53551 4.53563C7.92603 4.1451 7.92603 3.51194 7.53551 3.12141L4.70708 0.292986C4.49012 0.076027 4.19827 -0.0203986 3.91474 0.0037095C3.63088 -0.0207381 3.33856 0.0756565 3.12132 0.292893L0.292893 3.12132Z"
        fill="#5D5D5D"
      />
    </svg>
  );
};

export default ArrowUp;
