import { ColorsV2Type } from 'styles/types/colors';

type FinancialStatus = {
  label: string;
  color: ColorsV2Type;
};

export const getFinancialStatus = (status: string): FinancialStatus => {
  const defaultValue: FinancialStatus = {
    label: '---',
    color: ColorsV2Type.GrayDark,
  };
  const financialStatusMap: {
    [key: string]: FinancialStatus;
  } = {
    'Em Auditoria': {
      label: 'EM AUDITORIA',
      color: ColorsV2Type.Red,
    },
    Liberado: {
      label: 'LIBERADO',
      color: ColorsV2Type.Green,
    },
    'A Vencer': {
      label: 'A FATURAR',
      color: ColorsV2Type.Blue,
    },
    Faturado: {
      label: 'Faturado',
      color: ColorsV2Type.Aqua50,
    },
    'Aguardando Canhoto': {
      label: 'AGUARDANDO CANHOTO',
      color: ColorsV2Type.Blue,
    },
    'Pagamento Programado': {
      label: 'PAGAMENTO PROGRAMADO',
      color: ColorsV2Type.Blue75,
    },
    Liquidado: {
      label: 'LIQUIDADO',
      color: ColorsV2Type.Aqua50,
    },
    Pago: {
      label: 'Faturado',
      color: ColorsV2Type.Aqua50,
    },
  };

  return financialStatusMap[status] ?? defaultValue;
};
