import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './essencial-button-view.styled';

interface EssencialButtonProps {
  label: string;
  size: 'small' | 'medium' | 'large' | 'full';
  color: ThemeColorsV2Type;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick: () => void;
}

export const EssencialButtonView: React.FC<EssencialButtonProps> = ({
  label,
  size,
  color,
  icon,
  disabled,
  onClick,
}) => {
  return (
    <S.Div>
      <S.Wrapper size={size} color={color} disabled={disabled} onClick={onClick}>
        <S.ContainerLabel>
          <S.Label>{label}</S.Label>
        </S.ContainerLabel>
      </S.Wrapper>
      <S.ContainerIcon disabled={disabled}>{icon}</S.ContainerIcon>
    </S.Div>
  );
};
