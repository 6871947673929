export enum WeighingTottensDetailsActionType {
  WEIGHING_TOTTENS_DETAILS_REQUEST = 'WEIGHING_TOTTENS_DETAILS_REQUEST',
  WEIGHING_TOTTENS_DETAILS_SUCCESS = 'WEIGHING_TOTTENS_DETAILS_SUCCESS',
  WEIGHING_TOTTENS_DETAILS_ERROR = 'WEIGHING_TOTTENS_DETAILS_ERROR',
  WEIGHING_TOTTENS_DETAILS_RESET = 'WEIGHING_TOTTENS_DETAILS_RESET',
  WEIGHING_TOTTENS_DETAILS_FILTERS = 'WEIGHING_TOTTENS_DETAILS_FILTERS',
}

export enum WeighingTottensActionType {
  WEIGHING_TOTTENS_RESET = 'WEIGHING_TOTTENS_RESET',
}
