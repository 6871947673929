import { useCallback, useReducer } from 'react';
import { IPalletForm } from '../../types/pallet-form.types';
import { palletFormReducer } from '../../reducers/pallet-form/pallet-form.reducer';
import { financialInitialState } from '../../financial.constants';
import { PalletFormActionType } from '../../financial.action';
import { IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';

export const usePalletFormContext = (): IPalletForm => {
  const [state, dispatch] = useReducer(palletFormReducer, financialInitialState);

  const setGetPalletDetailsRequest = useCallback(() => {
    dispatch({ type: PalletFormActionType.GET_PALLET_DETAILS_REQUEST, payload: undefined });
  }, []);

  const setGetPalletDetailsSuccess = useCallback((pallet: IPalletDomain) => {
    dispatch({ type: PalletFormActionType.GET_PALLET_DETAILS_SUCCESS, payload: pallet });
  }, []);

  const setGetPalletDetailsError = useCallback(() => {
    dispatch({ type: PalletFormActionType.GET_PALLET_DETAILS_ERROR, payload: undefined });
  }, []);

  const setPalletFormSubmit = useCallback(() => {
    dispatch({ type: PalletFormActionType.PALLET_FORM_SUBMIT, payload: undefined });
  }, []);

  const setPalletFormSuccess = useCallback(() => {
    dispatch({ type: PalletFormActionType.PALLET_FORM_SUCCESS, payload: undefined });
  }, []);

  const setPalletFormError = useCallback(() => {
    dispatch({ type: PalletFormActionType.PALLET_FORM_ERROR, payload: undefined });
  }, []);

  const setPalletFormReset = useCallback(() => {
    dispatch({ type: PalletFormActionType.PALLET_FORM_RESET, payload: undefined });
  }, []);

  return {
    ...state.palletForm,
    getPalletDetails: {
      ...state.palletForm.getPalletDetails,
      setGetPalletDetailsRequest,
      setGetPalletDetailsSuccess,
      setGetPalletDetailsError,
    },
    setPalletFormSubmit,
    setPalletFormSuccess,
    setPalletFormError,
    setPalletFormReset,
  };
};
