import { ThemeColorsV2Type } from 'styles/types/colors';
import { OperationType, OperationTypeMap } from './convert-operation-type.types';
import { OperationTypeDomain, OperationTypeResponse } from 'src-new/domains/operation-type';

const defaultValue: OperationType = {
  label: 'NÃO MAPEADO',
  color: ThemeColorsV2Type.DarkGray,
};

export const convertOperationType = (operationTypes: string): OperationType => {
  const operationTypeMap: OperationTypeMap = {
    OUTBOUND: {
      label: 'OUT',
      color: ThemeColorsV2Type.Aqua,
    },
    INBOUND: {
      label: 'IN',
      color: ThemeColorsV2Type.Blue,
    },
    TRANSFERENCIA: {
      label: 'TR',
      color: ThemeColorsV2Type.Red,
    },
    TRANSFER: {
      label: 'TR',
      color: ThemeColorsV2Type.Red,
    },
    OUT: {
      label: 'OUTBOUND',
      color: ThemeColorsV2Type.Aqua,
    },
    IN: {
      label: 'INBOUND',
      color: ThemeColorsV2Type.Blue,
    },
    TR: {
      label: 'TRANSFERENCIA',
      color: ThemeColorsV2Type.Red,
    },
  };

  return operationTypeMap[operationTypes] || defaultValue;
};

export const convertOperationTypeToDomain = (operationType: OperationTypeResponse | string): OperationTypeDomain => {
  const operationTypeMap: Record<OperationTypeResponse | string, OperationTypeDomain> = {
    INBOUND: 'IN',
    OUTBOUND: 'OUT',
    TRANSFER: 'TR',
    TRANSFERENCIA: 'TR',
  };

  return operationTypeMap[operationType];
};

export const convertOperationTypeToResponse = (operationType: OperationTypeDomain): OperationTypeResponse => {
  const operationTypeMap: Record<OperationTypeDomain, OperationTypeResponse> = {
    IN: 'INBOUND',
    OUT: 'OUTBOUND',
    TR: 'TRANSFER',
  };

  return operationTypeMap[operationType];
};
