import { IListPage } from 'src-new/domains/list-page.domain';
import { IPurchaseOfferMatchListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list-filters.domain';
import { IPurchaseOfferMatchListResponse } from './get-purchase-offer-match-list.types';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';

export const getPurchaseOfferMatchListService = async (
  purchaseOfferId: number,
  filters: IPurchaseOfferMatchListFilterDomain,
): Promise<IListPage<IPurchaseOfferMatchListResponse>> => {
  const { page, limitPage, backhaulType, matchId } = filters;
  const { data } = await API_TRAVELS_SVC.get<IListPage<IPurchaseOfferMatchListResponse>>(
    `/v1/matchs/purchase-offer?offerId=${purchaseOfferId}&page=${page}&limit=${limitPage}&backhaulType=${backhaulType}&matchId=${
      matchId ?? ''
    }`,
  );
  return data;
};
