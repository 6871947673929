import React, { useCallback } from 'react';
import { ModalScheduleCancelView } from './modal-schedule-cancel-view.component';
import { useFormikContext } from 'formik';
import { ButtonLabelType, IScheduleCancel } from './modal-schedule-cancel.types';

interface IModalScheduleCancelProps {
  subtitle?: string;
  buttonLabel: ButtonLabelType;
  closeModal: () => void;
  handleConfirm: () => void;
}

export const ModalScheduleCancel: React.FC<IModalScheduleCancelProps> = (props) => {
  const { values, setFieldValue, handleChange } = useFormikContext<IScheduleCancel>();

  const handleSubmit = useCallback(
    (value: string, withCost: boolean) => {
      setFieldValue(value, withCost);
      if (values.descriptionCancel === 'Outros') {
        setFieldValue('descriptionCancel', values.inputDescriptionCancel);
      }

      props.handleConfirm();
    },
    [props, setFieldValue, values.descriptionCancel, values.inputDescriptionCancel],
  );

  return (
    <ModalScheduleCancelView
      {...props}
      values={values}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};
