import { useCallback } from 'react';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { sendBackhaulPurchaseService } from 'src-new/services/send-backhaul-purchase/send-backhaul-purchase.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useSendBackhaulPurchase = () => {
  return useCallback(async (formValues: IModalBackhaulPurchaseForm, backhaulPurchase: IBackhaulPurchase) => {
    try {
      showMessageFormatted({
        message: 'Realizando compra...',
        type: 'info',
      });

      await sendBackhaulPurchaseService(formValues, backhaulPurchase);

      showMessageFormatted({
        message: TOAST_MESSAGE.REGISTER,
        type: 'success',
      });
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao realizar compra.',
        type: 'error',
      });
    }
  }, []);
};
