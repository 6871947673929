import React, { ReactElement, useMemo } from 'react';
import { WeighingListModals } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { IParamsFilter } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.types';
import { WeighingFilter } from '../weighing-filter/weighing-filter.component';

interface IHeaderModalsViewProps {
  modalOptions: WeighingListModals;
  weighingFilters: Array<IParamsFilter>;
  handleModalClose: () => void;
}

export const HeaderModalsView: React.FC<IHeaderModalsViewProps> = ({
  modalOptions,
  weighingFilters,
  handleModalClose,
}) => {
  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      if (modalOptions.modalType === 'filter') {
        return <WeighingFilter weighingFilters={weighingFilters} handleModalFilter={handleModalClose} />;
      }
    }
  }, [handleModalClose, modalOptions.modalType, modalOptions.open, weighingFilters]);

  return renderModals;
};
