import React, { ReactNode } from 'react';

import EmptyStateTableView from './empty-state-table-view';

type EmptyStateTableProps = {
  icon: ReactNode;
  text: string | ReactNode;
  subText: string;
  isLoading: boolean;
};
const EmptyStateTable: React.FC<EmptyStateTableProps> = ({ icon, text, subText, isLoading }) => {
  return <EmptyStateTableView isLoading={isLoading} text={text} icon={icon} subText={subText} />;
};

export default EmptyStateTable;
