import { saleOfferMatchProposalInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-proposal-initial-values.constant';
import {
  SaleOfferMatchListActionType,
  SaleOfferMatchListSelectedActionType,
  SaleOfferMatchProposalActionType,
} from '../../sale-offer.action';
import { ISaleOfferMatchListActions } from '../../types/sale-offer-match-list.types';
import { ISaleOfferContext } from '../../types/sale-offer.types';
import { saleOfferMatchSelectedInitialValuesConstant } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-selected-initial-values.constant';

export const saleOfferMatchListReducer = (
  state: ISaleOfferContext,
  action: ISaleOfferMatchListActions,
): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_REQUEST:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          statusRequest: 'PROCESSING',
          saleOfferMatchList: [],
        },
      };
    case SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_SUCCESS:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          statusRequest: 'SUCCESS',
          saleOfferMatchList: action.payload,
        },
      };
    case SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_ERROR:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          statusRequest: 'ERROR',
          saleOfferMatchList: [],
        },
      };
    case SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_RESET:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          statusRequest: 'INITIAL',
          saleOfferMatchList: [],
        },
      };
    case SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_REQUEST:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchProposal: {
            ...state.saleOfferMatchList.saleOfferMatchProposal,
            statusRequest: 'PROCESSING',
            proposal: saleOfferMatchProposalInitialValues,
          },
        },
      };
    case SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchProposal: {
            ...state.saleOfferMatchList.saleOfferMatchProposal,
            statusRequest: 'SUCCESS',
            proposal: action.payload,
          },
        },
      };
    case SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_ERROR:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchProposal: {
            ...state.saleOfferMatchList.saleOfferMatchProposal,
            statusRequest: 'ERROR',
            proposal: saleOfferMatchProposalInitialValues,
          },
        },
      };
    case SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_RESET:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchProposal: {
            ...state.saleOfferMatchList.saleOfferMatchProposal,
            statusRequest: 'INITIAL',
            proposal: saleOfferMatchProposalInitialValues,
          },
        },
      };
    case SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_REQUEST:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchListSelected: {
            ...state.saleOfferMatchList.saleOfferMatchListSelected,
            statusRequest: 'PROCESSING',
            selectedMatch: saleOfferMatchSelectedInitialValuesConstant,
          },
        },
      };
    case SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_SUCCESS:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchListSelected: {
            ...state.saleOfferMatchList.saleOfferMatchListSelected,
            statusRequest: 'SUCCESS',
            selectedMatch: action.payload,
          },
        },
      };
    case SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_ERROR:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchListSelected: {
            ...state.saleOfferMatchList.saleOfferMatchListSelected,
            statusRequest: 'ERROR',
            selectedMatch: saleOfferMatchSelectedInitialValuesConstant,
          },
        },
      };
    case SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_RESET:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          saleOfferMatchListSelected: {
            ...state.saleOfferMatchList.saleOfferMatchListSelected,
            statusRequest: 'INITIAL',
            selectedMatch: saleOfferMatchSelectedInitialValuesConstant,
          },
        },
      };
    case SaleOfferMatchListActionType.SALE_OFFER_MATCH_SELECT_FILTERS:
      return {
        ...state,
        saleOfferMatchList: {
          ...state.saleOfferMatchList,
          filters: {
            ...state.saleOfferMatchList.filters,
            selectedFilter: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
