import API from 'utils/API/API';
export interface IUpdateVehicle {
  driverId: number;
  vehicleId?: number;
  typeUpdate: 'VEICULO'
}

export const updateVehicleService = async (id: string, values: IUpdateVehicle) => {
  const url = await API.put(`v2/schedules/update/driver/vehicle/${id}`, {
    vehicleId: values.vehicleId,
    driverId: values.driverId,
    typeUpdate: values.typeUpdate
  });

  return url;
};
