import { IInfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/types/infinite-scroll-list-table.types';

export const infiniteScrollReducerResetState = (
  state: IInfiniteScrollListTableContext,
): IInfiniteScrollListTableContext => {
  return {
    ...state,
    initialLoading: {
      ...state.initialLoading,
      loading: true,
    },
  };
};
