import styled, { css, keyframes } from 'styled-components';

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperBreadcrumb = styled.div``;

export const WrapperLinksAndLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: end;
  justify-items: stretch;
`;

interface IWrapperLinkProps {
  disabled?: boolean;
}
export const WrapperLink = styled.div<IWrapperLinkProps>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    opacity: ${disabled ? 0.5 : 1};

    cursor: pointer;
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    padding-left: 10px;
    font-size: 13px;
    font-weight: 600;
    color: #5a5a5a;

    text-decoration: none;

    :hover {
      color: ${theme.colors.blue};
      font-weight: 600;
    }
  `}
`;

export const WrapperCount = styled.div`
  width: 0;
`;

export const Div = styled.div``;

export const WrapperLogo = styled.div`
  position: relative;
  top: 4px;
  left: 8px;
`;

export const WrapperAlertLogo = styled.div`
  position: relative;
  bottom: 10px;
  left: 15px;

  svg {
    height: 12px;
  }
`;

export const WrapperDashboardLogo = styled.div`
  position: relative;
  bottom: 10px;
  left: 17px;

  svg {
    height: 12px;
  }
`;

export const SpinnerContainerStyled = styled.div`
  position: fixed;
  top: 40px;
  right: 21.2rem;
  padding: 4px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-color: #5a5a5a;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 0.7s linear infinite;
`;
