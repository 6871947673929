import API from 'utils/API/API';

export const downloadEmailPDF = async (scheduleId: number): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.post(
      `/v3/schedule/${scheduleId}/collection-report`,
      {},
      {
        responseType: 'arraybuffer',
      },
    );
    return data;
  } catch (error) {
    throw new Error('Ocorreu um erro ao fazer o download do PDF');
  }
};
