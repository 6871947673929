type ConversionMapping = Record<string, Record<string, string>>;

const fieldMappings: ConversionMapping = {
  vehicleType: {
    '3/4': '3/4',
    bitrem: 'BITREM',
    bitruck: 'BITRUCK',
    carreta: 'CARRETA',
    carreta_ls: 'CARRETA LS',
    complemento: 'COMPLEMENTO',
    rodotrem: 'RODOTREM',
    'romeu&julieta': 'ROMEU & JULIETA',
    romeu_julieta: 'ROMEU & JULIETA',
    toco: 'TOCO',
    truck: 'TRUCK',
    utilitario: 'UTILITÁRIO',
    van: 'VAN',
    vuc: 'VUC',
    vanderleia: 'VANDERLÉIA',
  },
};

export const convertField = (field: string, value: string | number): string | number => {
  const fieldMapping = fieldMappings[field];

  return fieldMapping && fieldMapping[value] ? fieldMapping[value] : value;
};
