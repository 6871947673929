import styled, { css } from 'styled-components';

import theme from 'styles/theme';

export const ContainerBackhaulTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  height: 100%;

  z-index: 1;
`;

export const ContainerLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoadingStyled = styled.div`
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

type ButtonStyledProps = {
  color: 'blue' | 'white' | 'pink' | 'green' | 'orange' | 'darkGray' | 'middleBlue' | 'newRed' | 'aqua' | 'lightGray';
  variant: 'ghost' | 'solid';
};
export const ButtonStyled = styled.button<ButtonStyledProps>`
  ${({ color, variant }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 130px;
    padding: 1px 20px;
    cursor: pointer;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.semiBold};
    font-size: 12px;
    color: ${theme.colors.white};
    white-space: nowrap;
    gap: 5px;
    border-radius: ${theme.border.radius};

    ${variant === 'solid' &&
    css`
      background-color: ${!!color && theme.colors[color]};
      border: none;
      ${color === 'white' && `border: solid 1px ${theme.colors.forBorder}`};
    `}

    ${variant === 'ghost' &&
    css`
      background-color: ${theme.colorsV2.white};
      border: solid 1px #aaaaaa;
      color: ${theme.colors[color]};
    `}
  `}
`;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `}
`;

export const EmptyListWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `}
`;