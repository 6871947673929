import { IDriverCreated } from 'domain/driver';
import { IContentTable } from 'components/table/table.types';

export const listDriverContent = (driver: IDriverCreated): IContentTable => {
  return [
    {
      value: '',
      flex: 0,
    },
    {
      value: driver.id,
      flex: 0.2,
    },
    {
      value: driver.name,
    },
    {
      value: driver.cpf,
    },
  ];
};
