import styled, { css } from 'styled-components';

export const HireBackhaulBuyMatchFormStyled = styled.div`
  height: calc(100vh - 210px);
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const ContainerRowStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  font-weight: 500;
`;
export const SecondTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  margin-bottom: 8px;
  font-weight: 600;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 12px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;
