import { useLocation, useParams } from 'react-router-dom';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { useValidationFields } from 'pages-v2/schedule/context/validation-fields';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';
import { useGetLocalityFilterIds } from 'src-new/hooks/use-get-locality-filter-ids/use-get-locality-filter-ids.hook';
import { TypeLocation } from 'src-new/domains/type-location.domain';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { useGetTollValue } from 'pages-v2/schedule/hooks/use-get-toll-value/use-get-toll-value.hook';
import { IScheduleVehicleRestriction } from '../types';
import { useGetLeadTime } from 'src-new/hooks/use-get-leadtime/use-get-leadtime.hook';

interface ScheduleOriginAndDestinyFormProps {
  type: 'origin' | 'destination';
}

type LocationDetails = {
  tradeName: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  numberHouse: string;
  street: string;
  uf: string;
};

const initialLocationDetails = {} as LocationDetails;

const ScheduleOriginAndDestinyForm: React.FC<ScheduleOriginAndDestinyFormProps> = ({ type }) => {
  const { values, setFieldValue } = useFormikContext<ScheduleRegisterInput>();

  const { id } = useParams();

  const { origin, destination, cargo } = values;

  const { validateField, setScheduleValues } = useValidationFields();

  const [locationsDetails, setLocationsDetails] = useState<LocationDetails>(initialLocationDetails);
  const [defaultDestinationValues, setDefaultDestinationValues] = useState<Array<{ label: string; value: number }>>([]);

  const location = useLocation();
  const getLocalityFilterIdsHook = useGetLocalityFilterIds();
  const getTollValueHook = useGetTollValue();
  const getLeadTimeHook = useGetLeadTime();

  const valuesType = useMemo(() => {
    if (type === 'origin') {
      return values.origin.origin;
    }

    return values.destination.destination;
  }, [type, values.destination, values.origin]);

  const selectFieldValues = useMemo((): { value: number; label: string } => {
    if (type === 'origin') {
      const originValue = location.state?.origin?.id ?? values[type].id;
      const originLabel = location.state?.origin?.name ?? values[type].tradeName;

      return { value: originValue, label: originLabel };
    }

    const destinationValue = location.state?.destination?.id ?? values[type].id;
    const destinationLabel = location.state?.destination?.name ?? values[type].tradeName;

    return {
      value: destinationValue,
      label: destinationLabel,
    };
  }, [location.state, type, values]);

  const handleCompleteInputs = useCallback(
    async (id: number, typeInput: '' | 'CLIENT' | 'LOCALITY') => {
      if (typeInput !== '' && id) {
        try {
          const details = await (typeInput === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          setLocationsDetails({
            ...initialLocationDetails,
            ...details?.address,
          });

          let vehicleRestriction: IScheduleVehicleRestriction = { type: 'vehicleType', restriction: [] };

          if ('vehicleType' in details) {
            vehicleRestriction = { type: 'vehicleType', restriction: details.vehicleType };
          } else {
            vehicleRestriction = { type: 'vehicleRestriction', restriction: details.vehicleRestriction };
          }

          const fieldValuesToUpdate = {
            [`${type}.id`]: details.id,
            [`${type}.${type}.address.city`]: details.address.city,
            [`${type}.${type}.address.uf`]: details.address.uf,
            [`${type}.${type}.address.complement`]: details.address.complement,
            [`${type}.${type}.address.neighborhood`]: details.address.neighborhood,
            [`${type}.${type}.address.numberHouse`]: details.address.numberHouse,
            [`${type}.${type}.address.street`]: details.address.street,
            [`${type}.${type}.address.cep`]: details.address.cep,
            [`${type}.${type}.cnpj`]: details.cnpj,
            [`${type}.${type}.lat`]: details.lat,
            [`${type}.${type}.lng`]: details.lng,
            [`${type}.${type}.inbound`]: details.inbound,
            [`${type}.${type}.outbound`]: details.outbound,
            [`${type}.${type}.${vehicleRestriction.type}`]: vehicleRestriction.restriction,
            [`${type}.${type}.vehicleCategoryType`]: details.vehicleCategoryType,
            [`${type}.${type}.operationSubType`]: type === 'origin' ? details.operationType : undefined,
            [`${type}.${type}.localityIds`]:
              type === 'origin' && details.localityIds && details.localityIds.length > 0
                ? details.localityIds
                : undefined,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue, type],
  );

  const handleChangeLocations = useCallback(
    (id: number) => {
      handleCompleteInputs(id, values[type].type);
    },
    [handleCompleteInputs, type, values],
  );

  const handleSearchSelect = useCallback(
    async (search: string, locationType: TypeLocation): Promise<Array<ISelectItemDomain>> => {
      if (type === 'destination' && locationType === 'LOCALITY' && values.origin.origin.localityIds) {
        const localities = await getLocalityFilterIdsHook(values.origin.origin.localityIds, search);

        if (!localities) return [];

        return localities;
      }

      return searchOriginOrDestiny(search, locationType);
    },
    [getLocalityFilterIdsHook, type, values.origin.origin.localityIds],
  );

  useEffect(() => {
    (async () => {
      if (values.destination.type === 'LOCALITY' && values.origin.origin.localityIds) {
        const localities = await getLocalityFilterIdsHook(values.origin.origin.localityIds);

        if (!localities) return;

        setDefaultDestinationValues(localities);

        return;
      }

      setDefaultDestinationValues([]);
    })();
  }, [getLocalityFilterIdsHook, values.destination.type, values.origin.origin.localityIds]);

  useEffect(() => {
    if (location.state?.origin && location.state?.destination) {
      setFieldValue('origin.type', location.state.origin.type);
      setFieldValue('destination.type', location.state.destination.type);
      setFieldValue('origin.tradeName', location.state.origin.name);
      setFieldValue('destination.tradeName', location.state.destination.name);
      type === 'origin'
        ? handleCompleteInputs(location.state.origin.id, location.state.origin.type)
        : handleCompleteInputs(location.state.destination.id, location.state.destination.type);
    }
  }, [location.state]);

  useEffect(() => {
    if (!id) {
      setScheduleValues({
        ...values,
        origin: {
          type: origin.type,
          id: origin.id,
          origin: origin.origin,
          tradeName: origin.tradeName,
        },
        destination: {
          type: destination.type,
          id: destination.id,
          destination: destination.destination,
          tradeName: destination.tradeName,
        },
      });
    }
  }, [origin.id, destination.id]);

  useEffect(() => {
    if (!origin.id || !destination.id || !cargo.vehicleType) return;

    (async () => {
      setFieldValue('cost.calcToll', true);

      const tollValue = await getTollValueHook({
        latOrigin: origin.origin.lat,
        lngOrigin: origin.origin.lng,
        latDestiny: destination.destination.lat,
        lngDestiny: destination.destination.lng,
        vehicleType: cargo.vehicleType,
      });

      setFieldValue('cost.toll', tollValue);
      setFieldValue('cost.calcToll', false);
    })();
  }, [
    cargo.vehicleType,
    destination.destination.lat,
    destination.destination.lng,
    destination.id,
    getTollValueHook,
    origin.id,
    origin.origin.lat,
    origin.origin.lng,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!origin.id || !destination.id) return;

    (async () => {
      const leadTime = await getLeadTimeHook({
        originId: origin.id,
        originType: origin.type,
        destinationId: destination.id,
        destinationType: destination.type,
        vehicleType: 'carreta',
        vehicleCategory: 'bau_seco',
      });

      if (!leadTime) return;

      setFieldValue('leadTime', leadTime.leadTime);
      setFieldValue('distance', leadTime.distance);
    })();
  }, [destination.id, destination.type, getLeadTimeHook, origin.id, origin.type, setFieldValue]);

  return (
    <SectionLayout name={`${type} information`}>
      <LineSectionLayout columns="1fr 4fr">
        <Select
          label="Tipo *"
          id={`${type}.type`}
          name={`${type}.type`}
          value={values[type].type}
          setFieldValue={setFieldValue}
          options={OriginAndDestinyTypeOptions}
          hasError={validateField && !values[type].type}
          disabled={type === 'destination' && !values.origin.id}
          errorMessage="Campo Obrigatório"
        />

        <AsyncSelectField
          placeholder=""
          label="Informe o Nome *"
          isClearable={true}
          name={`${type}.tradeName`}
          id={`${type}.tradeName`}
          disabled={!values[type].type}
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            handleChangeLocations(e?.value || 0);
            setFieldValue(`${type}.tradeName`, e?.label);
          }}
          apiCallFunction={async (e: string) => await handleSearchSelect(e, values[type].type)}
          loadOptions={async (e: string) => await searchOriginOrDestiny(e, values[type].type)}
          items={defaultDestinationValues}
          value={selectFieldValues}
          hasError={validateField && !values[type].tradeName}
          errorMessage="Campo Obrigatório"
        />
      </LineSectionLayout>

      <LineSectionLayout columns="0.782fr 1.3fr 1.3fr 0.4fr ">
        <InputField
          label="CEP"
          name={`${type}.cep`}
          id={`${type}.cep`}
          type="text"
          value={locationsDetails?.cep ?? valuesType.address.cep}
          disabled
        />

        <InputField
          label="Bairro"
          name={`${type}.neighborhood`}
          id={`${type}.neighborhood`}
          type="text"
          value={locationsDetails?.neighborhood ?? valuesType.address.neighborhood}
          disabled
        />

        <InputField
          label="Cidade"
          name={`${type}.city`}
          id={`${type}.city`}
          type="text"
          value={locationsDetails?.city ?? valuesType.address.city}
          disabled
        />

        <InputField
          label="UF"
          name={`${type}.uf`}
          id={`${type}.uf`}
          type="text"
          value={locationsDetails?.uf ?? valuesType.address.uf}
          disabled
        />
      </LineSectionLayout>

      <LineSectionLayout columns="3fr 0.5fr 1.5fr">
        <InputField
          label="Endereço"
          name={`${type}.street`}
          id={`${type}.street`}
          type="text"
          value={locationsDetails?.street ?? valuesType.address.street}
          disabled
        />

        <InputField
          label="Nº"
          name={`${type}.numberHouse`}
          id={`${type}.numberHouse`}
          type="text"
          value={locationsDetails?.numberHouse ?? valuesType.address.numberHouse}
          disabled
        />

        <InputField
          label="Complemento"
          name={`${type}.complement`}
          id={`${type}.complement`}
          type="text"
          value={locationsDetails?.complement ?? valuesType.address.complement}
          disabled
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};

export default memo(ScheduleOriginAndDestinyForm);
