import React from 'react';

import CompanyRegisterForm from '../form/company-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const RegisterCompany: React.FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Cadastro', 'Empresas']} hasBackButton />
      <Title title="Nova Empresa" hasTrace isForm />
      <CompanyRegisterForm />
    </>
  );
};

export default RegisterCompany;
