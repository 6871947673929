import moment from 'moment';

import { HireBackhaulTime, IHireBackhaul, ITransportedCargo, IVehicleActuators, IYesNo } from 'domain/hire-backhaul';

export const PATH_TO_HIRE_BACKHAUL_LIST = '/ofertas-compra';

const initialDate = moment(new Date());

export const optionsTime: HireBackhaulTime[] = [
  { label: '5 HORAS', value: '5 horas' },
  { label: '6 HORAS', value: '6 horas' },
  { label: '7 HORAS', value: '7 horas' },
  { label: '8 HORAS', value: '8 horas' },
  { label: '9 HORAS', value: '9 horas' },
  { label: '10 HORAS', value: '10 horas' },
  { label: '11 HORAS', value: '11 horas' },
  { label: '12 HORAS', value: '12 horas' },
  { label: '24 HORAS', value: '24 horas' },
  { label: '48 HORAS', value: '48 horas' },
];

export const initialValues: IHireBackhaul = {
  secondVehicleCategory: [],
  vehicleType: '',
  category: '',
  transportedCargo: '',
  initialDate: initialDate.toString(),
  endDate: moment(initialDate).add(1, 'years').toString(),
  offeredPallet: 0,
  offeredCubage: 0,
  offeredWeight: 0,
  freightValue: 0,
  feeLogshare: 0,
  loadingTime: optionsTime[0].value,
  timeDownload: optionsTime[0].value,
  helper: 'Não',
  paletizado: 'Sim',
  originType: '',
  destinationType: '',
  idOrigin: undefined,
  idDestination: undefined,
  companyId: 0,
  status: 'ativo',
  destiny: undefined,
  origin: undefined,
  otherCargoCarried: '',
  initial_date: initialDate.format('DD/MM/YYYY'),
  frequency: 0,
};

export const optionsVehicleActuators: Array<{
  label: string;
  value: IVehicleActuators;
}> = [
  { label: 'TECLADO INTERNO', value: 'Teclado Interno' },
  { label: 'BLOQUEADOR DE COMBUSTÍVEL', value: 'Bloqueador de Combustível' },
  { label: 'TRAVA DE 5º', value: 'Trava de 5º' },
  { label: 'TRAVA DE BAÚ', value: 'Trava de Baú' },
  { label: 'SENSOR DE PORTA DA CABINA', value: 'Sensor de Porta da Cabina' },
  { label: 'SENSOR DE BAÚ', value: 'Sensor de Baú' },
  { label: 'SENSOR DE DESENGATE', value: 'Sensor de Desengate' },
  { label: 'SENSOR DE VELOCIDADE', value: 'Sensor de Velocidade' },
  { label: 'SENSOR DE GRADE DE JANELA', value: 'Sensor de Grade de Janela' },
  {
    label: 'SENSOR DE VIOLAÇÃO DE PAINEL',
    value: 'Sensor de Violação de Painel',
  },
  { label: 'SENSOR DE TEMPERATURA', value: 'Sensor de Temperatura' },
];

export const optionsTransportedCargo: Array<{
  label: string;
  value: ITransportedCargo;
}> = [
  { label: 'ÁGUA', value: 'Água' },
  { label: 'ALIMENTOS', value: 'Alimentos' },
  { label: 'CELULOSE', value: 'Celulose' },
  { label: 'CIGARROS', value: 'Cigarros' },
  { label: 'COMBUSTÍVEIS', value: 'Combustíveis' },
  { label: 'COSMÉTICOS', value: 'Cosméticos' },
  { label: 'EMBALAGENS', value: 'Embalagens' },
  { label: 'ELETRÔNICOS', value: 'Eletrônicos' },
  { label: 'FLVs', value: 'FLVs' },
  { label: 'GRÃOS AGRÍCOLAS', value: 'Grãos Agrícolas' },
  { label: 'GRÃOS MINERAIS', value: 'Grãos Minerais' },
  { label: 'MADEIRA', value: 'Madeira' },
  { label: 'ÓLEO VEGETAL', value: 'Óleo Vegetal' },
  { label: 'PAPEL', value: 'Papel' },
  { label: 'QUÍMICOS', value: 'Químicos' },
  { label: 'ROUPAS', value: 'Roupas' },
  { label: 'SUCO', value: 'Suco' },
  { label: 'VASILHAMES', value: 'Vasilhames' },
  { label: 'OUTROS', value: 'Outros' },
];

export const optionsYesOrNo: Array<{
  label: string;
  value: IYesNo;
}> = [
  { label: 'SIM', value: 'Sim' },
  { label: 'NÃO', value: 'Não' },
];
