import { Button } from 'logshare-ui-kit';
import { Pencil } from 'lucide-react';
import { FC } from 'react';

import * as S from './schedule-details-comments-view.styled';

import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Box } from 'src-new/components/box/box.component';
import { capitalize } from 'src-new/utils/capitalize.util';
import { Tooltip } from '@mui/material';

interface IScheduleDetailsCommentsViewProps {
  cargo: {
    comments: string;
    order: string;
  };
  handleOpenModalComments: () => void;
}

export const ScheduleDetailsCommentsView: FC<IScheduleDetailsCommentsViewProps> = ({
  cargo,
  handleOpenModalComments,
}) => {
  return (
    <Box
      footerButtons={[
        <span key={0} />,
        <S.ContainerButtons key={1}>
          <Button
            label=""
            color="white"
            onPress={handleOpenModalComments}
            LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
            size="x-sm"
            variant="ghost"
          />
        </S.ContainerButtons>,
      ]}
    >
      <S.Wrapper>
        <S.Title>Pedidos e Observações Gerais</S.Title>

        <LineSectionLayout columns={'1fr 1fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Observações Gerais</S.InformationTitle>

            <Tooltip
              title={<S.InformationBody>{capitalize(cargo.comments || 'Não há pedidos')}</S.InformationBody>}
              placement="top-start"
            >
              <S.InformationBody>{capitalize(cargo.comments || 'Não há pedidos')}</S.InformationBody>
            </Tooltip>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Pedidos</S.InformationTitle>
            <S.InformationBody>{capitalize(cargo.order || '-')}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>
      </S.Wrapper>
    </Box>
  );
};
