import { Dispatch, SetStateAction } from 'react';

import ScheduleOriginForm from '../../../components/forms/origin-and-destiny';
import ScheduleDetailsForm from '../../../components/forms/details';
import ScheduleCargoDetailsForm from '../../../components/forms/cargo';

import { ISchedulingKeys } from 'domain/schedule';
import { ICompany } from 'domain/company';

interface IScheduleFormProps {
  company: ICompany;
  schedulingKeys: ISchedulingKeys[];
  schedule: boolean;
  isFilled: {
    originValues: boolean;
    destinyValues: boolean;
    cargoValues: boolean;
    detailValues: boolean;
    customFieldsValues: boolean;
  };
  setLoadingFreightValues: Dispatch<SetStateAction<boolean>>;
  handleDisabledCards: (disable: boolean, type: 'SPOT' | 'BACKHAUL') => void;
}

export const RenderForms = ({
  company,
  schedulingKeys,
  schedule,
  isFilled,
  setLoadingFreightValues,
  handleDisabledCards,
}: IScheduleFormProps) => {
  return [
    {
      section: 'Origem',
      content: <ScheduleOriginForm type="origin" />,
      checked: isFilled.originValues,
      openSection: !schedule,
    },
    {
      section: 'Destino',
      content: <ScheduleOriginForm type="destination" />,
      checked: isFilled.destinyValues,
    },
    {
      section: 'Detalhes da Carga',
      content: <ScheduleCargoDetailsForm company={company} setLoadingFreightValues={setLoadingFreightValues} />,
      checked: isFilled.cargoValues,
    },
    {
      section: 'Detalhes do Agendamento',
      content: <ScheduleDetailsForm schedulingKeys={schedulingKeys} handleDisabledCards={handleDisabledCards} />,
      checked: isFilled.detailValues && isFilled.customFieldsValues,
      openSection: schedule,
    },
  ];
};
