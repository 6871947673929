import { IPalletStatus } from '../register';

export interface IPalletList {
  id: number;
  scheduleId?: number;
  equipmentVoucher: string;
  clientName?: string;
  originCity?: string;
  distributionCenterName?: string;
  palletQuantity: number;
  liberationDate: string;
  protocol?: number;
  status: IPalletStatus;
}

export interface PalletListParams {
  page: number;
  order: string;
  column: string;
  search?: string;
  status?: IPalletStatus;
  activeTabName: string;
}

export const defaultPalletFilter: PalletListParams = {
  page: 1,
  order: 'ASC',
  column: 'ID',
  activeTabName: 'Todos',
};

export interface ISelectedPallets {
  id: number;
  palletQuantity: number;
  clientName: string;
  originCity: string;
}
