import styled from 'styled-components';

export const Wrapper = styled.main``;

export const WrapperBody = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 15px;
  height: 84vh;
  overflow: hidden;
`;

export const WrapperContent = styled.div`
  width: 100%;
`;
