import styled, { css } from 'styled-components';

type WrapperStyledProps = {
  selected: boolean;
  showText: boolean;
};
export const WrapperStyled = styled.li<WrapperStyledProps>`
  ${({ theme, selected, showText }) => css`
    display: grid;
    grid-template-columns: ${showText ? '25% 1fr' : 'auto'};
    align-items: center;
    height: ${theme.sidebar.menu.height};
    padding: ${showText ? '0 20px' : '0 15px'};
    margin: 2px 0;
    color: ${selected ? theme.colors.blue : theme.colors.white};
    font-weight: ${selected ? theme.font.bold : theme.font.semiBold};
    border-radius: 48px 0px 0px 48px;
    background-color: ${selected ? 'rgb(243 243 243)' : theme.colors.transparent};
    cursor: pointer;

    &:hover {
      background-color: rgb(243 243 243);
      color: ${theme.colors.black};
      svg {
        filter: opacity(1) brightness(0);
      }
    }
  `}
`;

type MenuIconStyledProps = { selected: boolean };
export const MenuIconStyled = styled.div<MenuIconStyledProps>`
  ${({ selected }) => css`
    display: flex;
    width: 19px;
    height: 19px;

    ${selected && ' filter: opacity(1) brightness(0);'}
  `}
`;

type TitleStyledProps = {
  showText: boolean;
};
export const TitleStyled = styled.span<TitleStyledProps>`
  ${({ theme, showText }) => css`
    display: ${showText ? 'block' : 'none'};
    font-size: ${theme.font.sizes.xsmall};
    text-transform: uppercase;
  `}
`;
