import { FC, useMemo } from 'react';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { convertDecimalCurrencyFormat } from 'utils-v2/converters/decimal';
import * as S from './schedule-finance-title-view.styled';

interface IScheduleFinanceResumeTitleViewProps {
  schedule: IScheduleDetailsDomain;
  typeTransaction: 'freithgtPayable' | 'freithgtReceivable' | 'freithgtShipping';
}

export const ScheduleFinanceResumeTitleView: FC<IScheduleFinanceResumeTitleViewProps> = ({
  schedule,
  typeTransaction,
}) => {
  const getTypeTransaction = useMemo(() => {
    return schedule[typeTransaction];
  }, [schedule, typeTransaction]);

  return (
    <S.WrapperHeader>
      <S.Title>{getTypeTransaction.title}</S.Title>

      <S.WrapperSubTitleAndTotalValue>
        <S.SubTitle>{getTypeTransaction.owner}</S.SubTitle>
        <S.WrapperCurrency>
          <S.SubTitle>R$</S.SubTitle>
          <S.SubTitle>{convertDecimalCurrencyFormat.format(getTypeTransaction.totalService)}</S.SubTitle>
        </S.WrapperCurrency>
      </S.WrapperSubTitleAndTotalValue>
    </S.WrapperHeader>
  );
};
