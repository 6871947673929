import React from 'react';

import { ColorsV2Type } from 'styles/types/colors';

type FreightType = {
  label: string;
  color: ColorsV2Type;
  icon?: React.ReactNode;
};

type FreightTypeMap = {
  [key: string]: FreightType;
};

const defaultValue: FreightType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.GrayDark,
};

export const convertFreightType = (freightTypes: string): FreightType => {
  const freightTypeMap: FreightTypeMap = {
    ftl: {
      label: 'FTL',
      color: ColorsV2Type.Blue,
    },
    stl: {
      label: 'STL',
      color: ColorsV2Type.Blue100,
    },
    ltl: {
      label: 'LTL',
      color: ColorsV2Type.Aqua100,
    },
  };

  return freightTypeMap[freightTypes] || defaultValue;
};
