import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ConversationBuilder, SendMessageEvent, Session } from 'talkjs/types/talk.types';
import { ChatView } from 'src-new/components/chat-button/components/chat/chat-view.component';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import {
  getProductionEmailsByFlow,
  getUserResponsibleReceivingEmailDevelopment,
  getUserResponsibleReceivingEmailProduction,
} from 'src-new/components/chat-button/components/chat/chat.constants';
import Talk from 'talkjs';
import { FlowConversation } from 'src-new/contexts/chat-button/types/chat-button.types';
import { useGenerateParticipantsConversationsChat } from 'src-new/hooks/chat/use-generate-participants-conversations-chat/use-generate-participants-conversations-chat.hook';
import { useCreateUpdateUserChat } from 'src-new/hooks/chat/use-create-update-user-chat/use-create-update-user-chat.hook';
import { UserContext } from 'state/user-context';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { useGetUserEmailsChat } from 'src-new/hooks/chat/use-get-user-emails-chat/use-get-user-emails-chat.hook';

export const Chat: React.FC = () => {
  const { user } = useContext(UserContext);
  const { chatActions } = useContext(ChatButtonContext);
  const { chatEligibleUsers, chatUserEmails } = useContext(ChatContext);
  const generateParticipantsConversations = useGenerateParticipantsConversationsChat();
  const createUpdateUserChat = useCreateUpdateUserChat();
  const getUserEmailsChat = useGetUserEmailsChat();

  const getLabelByFlow = useCallback((conversationId: string, flowConversation?: FlowConversation): string => {
    if (flowConversation === 'SALE_OFFER') {
      return `Oferta de Venda ID ${conversationId.split('-')[3]}`;
    }

    if (flowConversation === 'BUY_OFFER') {
      return `Oferta de Compra ID ${conversationId.split('-')[2]}`;
    }

    if (flowConversation === 'CONVERSATION_LIST_OFFER') {
      return `Oferta de Compra ID ${conversationId.split('-')[2]} | Oferta de Venda ID ${conversationId.split('-')[3]}`;
    }

    return `Frete ID ${conversationId.split('-')[2]}`;
  }, []);

  const insertObserverUsersAllConversations = useCallback(
    (conversationId: string, conversation: ConversationBuilder) => {
      const environment: string = conversationId.split('-')[0];

      if (environment === 'development') {
        conversation.setParticipant(new Talk.User(getUserResponsibleReceivingEmailDevelopment(conversationId)), {
          access: undefined,
          notify: true,
        });
      } else {
        conversation.setParticipant(new Talk.User(getUserResponsibleReceivingEmailProduction(conversationId)), {
          access: undefined,
          notify: true,
        });
      }

      return conversation;
    },
    [],
  );

  const getHeaderLabel = useCallback(() => {
    const flow = chatActions.chatId.split('-')[1];

    if (flow === 'schedule') {
      return `${chatActions.name} - ID ${chatActions.chatId.split('-')[2]}`;
    }

    return `${chatActions.name} - ID ${chatActions.chatId.split('-')[4]}`;
  }, [chatActions.name, chatActions.chatId]);

  const syncConversation = useCallback(
    (session: Session): ConversationBuilder => {
      const conversation = session.getOrCreateConversation(chatActions.chatId);
      const participantsConversation = generateParticipantsConversations();

      conversation.setParticipant(session.me);

      if (participantsConversation.participants.length) {
        participantsConversation.participants.forEach((participant) => {
          conversation.setParticipant(new Talk.User(participant));
        });
      }

      conversation.setAttributes({
        custom: {
          conversationLabel: getLabelByFlow(chatActions.chatId, chatActions.flowConversation),
          headerLabel: getHeaderLabel(),
          ...(!conversation?.custom?.saleOfferCompanyId &&
            chatActions.saleOfferCompanyId && { saleOfferCompanyId: chatActions.saleOfferCompanyId }),
          ...(!conversation?.custom?.buyOfferCompanyId &&
            chatActions.buyOfferCompanyId && { buyOfferCompanyId: chatActions.buyOfferCompanyId }),
        },
      });

      return chatEligibleUsers.validateThreadsActions.haveCreated
        ? conversation
        : insertObserverUsersAllConversations(chatActions.chatId, conversation);
    },
    [
      chatActions.chatId,
      chatActions.flowConversation,
      chatActions.saleOfferCompanyId,
      chatActions.buyOfferCompanyId,
      generateParticipantsConversations,
      getLabelByFlow,
      getHeaderLabel,
      chatEligibleUsers.validateThreadsActions.haveCreated,
      insertObserverUsersAllConversations,
    ],
  );

  const getPreviousEmailsToUpdateConversation = useCallback(
    (environment: string, conversationId: string): Array<string> => {
      if (environment === 'development') {
        return ['joao.almeida@logshare.com.br'];
      }

      return getProductionEmailsByFlow(conversationId);
    },
    [],
  );

  const updateUserEmails = useCallback(
    (userEmailId: string, updatedEmails: Array<string>) => {
      createUpdateUserChat({
        id: userEmailId,
        name: 'LogShareUserEmails',
        email: updatedEmails,
      });
    },
    [createUpdateUserChat],
  );

  const handleConversationEmailUpdate = useCallback(
    async (event: SendMessageEvent) => {
      if (user) {
        const conversationData = event.conversation;
        const userEmailId: string = `${conversationData.id}-user-emails`;
        const environment: string = conversationData.id.split('-')[0];
        const updatedEmails: Array<string> = [];
        const userEmailsConversation = chatUserEmails.userEmails;

        if (userEmailsConversation && Array.isArray(userEmailsConversation.email)) {
          if (!userEmailsConversation.email.find((email) => email === user.email)) {
            updatedEmails.push(...userEmailsConversation.email);
            updatedEmails.push(user.email);

            updateUserEmails(userEmailId, updatedEmails);
          }
        } else {
          const previousEmails: Array<string> = getPreviousEmailsToUpdateConversation(environment, conversationData.id);

          updatedEmails.push(...previousEmails);
          updatedEmails.push(user.email);

          updateUserEmails(userEmailId, updatedEmails);
        }
      }
    },
    [chatUserEmails.userEmails, getPreviousEmailsToUpdateConversation, updateUserEmails, user],
  );

  const isLoading = useMemo(() => chatUserEmails.statusRequest === 'PROCESSING', [chatUserEmails.statusRequest]);

  useEffect(() => {
    if (chatUserEmails.statusRequest === 'INITIAL') {
      getUserEmailsChat(`${chatActions.chatId}-user-emails`);
    }
  }, [
    chatActions.chatId,
    chatEligibleUsers.validateThreadsActions.haveCreated,
    chatUserEmails.statusRequest,
    getUserEmailsChat,
  ]);

  return (
    <ChatView
      syncConversation={syncConversation}
      handleConversationEmailUpdate={handleConversationEmailUpdate}
      isLoading={isLoading}
    />
  );
};
