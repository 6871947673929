import { IAcceptedMatchesContext, IAcceptedMatchesReducerAction } from './accepted-matches.types';
import { IAcceptedMatchesAction } from './accepted-matches.actions';

export const acceptedMatchesReducer = (
  state: IAcceptedMatchesContext,
  action: IAcceptedMatchesReducerAction,
): IAcceptedMatchesContext => {
  switch (action.type) {
    case IAcceptedMatchesAction.ACCEPTED_MATCHES_REQUEST: {
      return {
        ...state,
        acceptedMatchesIsLoading: true,
        acceptedMatches: [],
        acceptedMatchesError: undefined,
      };
    }
    case IAcceptedMatchesAction.ACCEPTED_MATCHES_SUCCESS: {
      return {
        ...state,
        acceptedMatchesIsLoading: false,
        acceptedMatches: action.payload.list,
        acceptedMatchesPageInfo: action.payload.pageInfo,
        acceptedMatchesError: undefined,
      };
    }
    case IAcceptedMatchesAction.ACCEPTED_MATCHES_ERROR: {
      return {
        ...state,
        acceptedMatchesIsLoading: false,
        acceptedMatches: [],
        acceptedMatchesError: action.payload,
        acceptedMatchesPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
