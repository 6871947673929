import { FC, ReactElement, useCallback, useMemo } from 'react';
import * as S from './matches-section-view.styled';
import { ThemeColorsV2Type } from 'styles/types/colors';
import TooltipComponent from 'components/Tooltip';
import { Star } from 'lucide-react';
import { Button } from 'logshare-ui-kit';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { IMatchesKanban } from '../../matches-kanban.types';
import { Loading } from 'src-new/components/loading/loading.component';
import { IMatchesKanbanDetails, MatchType } from '../../../../domains/matches-kanban-list/matches-kanban-list.domain';

export interface IKanbanSectionViewProps {
  title: string;
  color: ThemeColorsV2Type;
  kanbanListDetails: IMatchesKanban;
  handleFavoriteMatch: (matchId: number, isFavorite: boolean) => void;
  handleOpenModalMatch: (matchesKanbanDetails: IMatchesKanbanDetails) => void;
  handleOpenModalMatchBidding: (item: IMatchesKanbanDetails) => void;
  isEmptyList: boolean;
  handleOpenProposalChat: (
    purchaseOfferId: string,
    saleOfferId: string,
    tariffOfferId: string,
    matchId: string,
    saleOfferCompanyId: string,
    buyOfferCompanyId: string,
    type: MatchType,
  ) => Promise<void>;
}

export const KanbanSectionView: FC<IKanbanSectionViewProps> = ({
  title,
  color,
  kanbanListDetails,
  handleFavoriteMatch,
  handleOpenModalMatch,
  handleOpenModalMatchBidding,
  isEmptyList,
  handleOpenProposalChat,
}) => {
  const renderIDs = useCallback((item: IMatchesKanbanDetails): ReactElement => {
    const saleOfferId = item.type === MatchType.SALE ? item.id : item.saleOfferTariffId;
    const purchaseOfferId = item.type === MatchType.PURCHASE ? item.id : item.offerTypeId;

    return (
      <S.LabelCompanyName>
        V-{saleOfferId} | C-{purchaseOfferId}
      </S.LabelCompanyName>
    );
  }, []);

  const renderTotalBackhaul = useCallback((totalSchedulesBackhaul: number): ReactElement => {
    const title = totalSchedulesBackhaul === 1 ? 'Agendamento Criado' : 'Agendamentos Criados';

    return (
      <TooltipComponent
        title={`${totalSchedulesBackhaul} - ${title}`}
        location="bottom-start"
        icon={<S.ContainerFrequency>{totalSchedulesBackhaul}</S.ContainerFrequency>}
      />
    );
  }, []);

  const renderFavoriteMatch = useCallback(
    (matchId: number, isFavorite?: boolean): ReactElement => {
      const title = isFavorite ? 'Desfavoritar Match' : 'Favoritar Match';
      const color = isFavorite ? '#FF9800' : '#D9D9D9';

      return (
        <TooltipComponent
          title={title}
          location="bottom-start"
          icon={<Star size={15} color={color} fill={color} onClick={() => handleFavoriteMatch(matchId, !isFavorite)} />}
        />
      );
    },
    [handleFavoriteMatch],
  );

  const renderButton = useCallback(
    (item: IMatchesKanbanDetails): ReactElement => {
      const buttonLabel = item.type === MatchType.PURCHASE ? 'Vender' : 'Comprar';

      return (
        <S.ContentSaleButton>
          <Button
            label={buttonLabel}
            color={ThemeColorsV2Type.Blue}
            size="sm"
            variant="solid"
            onPress={() => handleOpenModalMatchBidding(item)}
          />
        </S.ContentSaleButton>
      );
    },
    [handleOpenModalMatchBidding],
  );

  const renderKanbanList = useMemo(() => {
    if (kanbanListDetails.isLoading) {
      return <Loading />;
    }

    if (kanbanListDetails.items.length === 0) {
      return <S.WrapperEmpty>Sem Match</S.WrapperEmpty>;
    }

    return kanbanListDetails.items.map((item) => {
      return (
        <S.WrapperList key={item.id} cursor={false}>
          <S.ContainerKanban onClick={() => handleOpenModalMatch(item)}>
            <S.ContainerHeaderCard>
              <S.ContainerCardCompany>
                <TooltipComponent
                  title={item.company.name}
                  location="bottom-start"
                  icon={<S.CompanyLogo src={item.company.image} alt={item.company.name} />}
                />
                <S.ContainerCompany>
                  <S.LabelId>{item.type}</S.LabelId>
                  <S.LabelCompanyName>
                    {item.vehicle.type} - {item.vehicle.category}
                  </S.LabelCompanyName>
                  {renderIDs(item)}
                </S.ContainerCompany>
              </S.ContainerCardCompany>

              <S.Div>{renderTotalBackhaul(Number(item.totalSchedulesBackhaul))}</S.Div>
            </S.ContainerHeaderCard>

            <S.ContainerContent>
              <S.ContainerLocation>
                <S.Div style={{ paddingTop: '3px' }}>
                  <LineConnector bgColor={'#d9d9d9'} height={32} />
                </S.Div>

                <S.ContentLocation>
                  <S.CardInfo>
                    {item.origin.uf} - {item.origin.city}
                  </S.CardInfo>

                  <S.CardInfo>
                    {item.destiny.uf} - {item.destiny.city}
                  </S.CardInfo>
                </S.ContentLocation>
              </S.ContainerLocation>
            </S.ContainerContent>
          </S.ContainerKanban>

          <S.ContainerFooter>
            <S.CardFavoriteMatch>{renderFavoriteMatch(item.matchOffersRoutesId, item.isFavorite)}</S.CardFavoriteMatch>

            <S.ContainerButton>
              <Button
                label="Negociar"
                color={ThemeColorsV2Type.Red}
                size="sm"
                variant="ghost"
                onPress={() =>
                  handleOpenProposalChat(
                    item.purchaseOfferId.toString(),
                    item.saleOfferId.toString(),
                    item.saleOfferTariffId.toString(),
                    item.matchOffersRoutesId.toString(),
                    item.saleOffer.companyId.toString(),
                    item.purchaseOffer.company.id.toString(),
                    item.type,
                  )
                }
              />
              {renderButton(item)}
            </S.ContainerButton>
          </S.ContainerFooter>
        </S.WrapperList>
      );
    });
  }, [
    kanbanListDetails.isLoading,
    kanbanListDetails.items,
    renderIDs,
    renderTotalBackhaul,
    renderFavoriteMatch,
    renderButton,
    handleOpenModalMatch,
    handleOpenProposalChat,
  ]);

  return (
    <S.Wrapper>
      <S.ContainerHeader color={color}>
        <S.Label>{title}</S.Label>
        <S.Label>{kanbanListDetails.totalItems}</S.Label>
      </S.ContainerHeader>
      <S.ContainerScroll color={color} listEmpty={isEmptyList}>
        <S.CardRouterBody>{renderKanbanList}</S.CardRouterBody>
      </S.ContainerScroll>
    </S.Wrapper>
  );
};
