import API from 'utils/API/API';
import { ScheduleUpdateInput } from 'domain-v2/schedule/update';

export const updateScheduleService = async (id: number, payload: ScheduleUpdateInput) => {
  const call = await API.put(`v3/schedules/${id}`, payload);
  return call;
};

export const updateCommentsScheduleService = async (id: number, comments: string) => {
  const payload = { comments };
  const call = await API.put(`v3/schedules/comments/${id}`, payload);
  return call;
};
