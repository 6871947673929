import { useCallback } from 'react';

import {
  occurrenceDeleteImageService,
  deleteUploadedTemplate,
  listUploadedTemplate,
  uploadTemplate,
} from '../services';

const useDeleteOccurrenceImageHook = () => {
  return useCallback(async (id: number): Promise<boolean> => {
    return await occurrenceDeleteImageService(id);
  }, []);
};

export const useHandleUploadTemplateHook = () => {
  return async (file: any) => {
    return await uploadTemplate(file);
  };
};

export const useHandleGetTemplateHook = () => {
  return async () => {
    return await listUploadedTemplate();
  };
};

export const useHandleDeleteTemplateListHook = () => {
  return async (templateId: number | string) => {
    return await deleteUploadedTemplate(templateId);
  };
};

export { useDeleteOccurrenceImageHook };
