import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PageInfo } from '../../../../domain/page-info';
import { IOccurrenceCreated } from '../../../../domain/occurrence';
import Title from '../../../../components/title/title';
import { Tabs } from '../../../../components/tabs/tabs';
import { IContentTable } from '../../../../components/table/table.types';
import Input from '../../../../components/input/input';
import { ContainerSearchStyled, DropDownStyles, NewFreight } from './occurrence-list-body-view.styled';
import OccurrenceTable from './components/occurrence-table/occurrence-table';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { checkOccurrenceBlocked } from 'pages/occurrence/constants';
import { ReportOption } from 'domain/report-files';
import { FooterPage } from 'components-v2/common/footer-page';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import TooltipComponent from 'components/Tooltip';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import ModalNewFreight from 'components-v2/common/modals/new-freight';

interface IOccurrenceListBodyViewProps {
  occurrences: Array<IOccurrenceCreated>;
  occurrencesPageInfo?: PageInfo;
  hasLoading: boolean;
  goToCreateOccurrence: () => void;
  goToUpdateOccurrence: (occurrenceId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  handleOpenUploadXlsx: () => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  idsToRemove: number[];
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
  handleRemoveOccurrence: (ids: number[]) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'occurrence-list-body-view';

const OccurrenceListBodyView: React.FC<IOccurrenceListBodyViewProps> = ({
  occurrences,
  occurrencesPageInfo,
  hasLoading,
  goToCreateOccurrence,
  goToUpdateOccurrence,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
  handleOpenUploadXlsx,
  onHandleExport,
  options,
  idsToRemove,
  setIdsToRemove,
  handleRemoveOccurrence,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}: IOccurrenceListBodyViewProps): JSX.Element => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const isOccurrenceBlocked = (occurrence: string) => {
    return checkOccurrenceBlocked(occurrence);
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return occurrences.map((occurrence) => {
      return [
        {
          value: (
            <Checkbox
              id="occurrence.id"
              name="occurrence.id"
              value={String(occurrence.id)}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, occurrence.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== occurrence.id)])
              }
            />
          ),
          flex: 0.2,
        },
        {
          value: occurrence.id,
          flex: 0.5,
        },
        {
          value: isAdmin ? occurrence.companyId?.tradeName : ' ',
          flex: isAdmin ? 0.8 : 0,
        },
        {
          value: occurrence.description,
          flex: 4.8,
        },

        {
          value: isOccurrenceBlocked(occurrence.description) ? (
            <Tag label="Padrão" color={ColorsV2Type.Red} variant="solid" display="flex" size="md" />
          ) : (
            '-'
          ),
          flex: 1,
        },

        {
          value: (
            <Tag
              label={occurrence.status}
              color={occurrence.status === 'ativo' ? ColorsV2Type.Green : ColorsV2Type.Red}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.55,
        },
      ];
    });
  }, [isAdmin, occurrences, setIdsToRemove]);

  const renderTable = useMemo(() => {
    return (
      <OccurrenceTable
        goToUpdatePage={goToUpdateOccurrence}
        contentTable={contentTable}
        pageInfo={occurrencesPageInfo}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [goToUpdateOccurrence, contentTable, occurrencesPageInfo, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    const availableTabs = [
      {
        name: 'Ativos',
        content: renderTable,
      },
      {
        name: 'Inativos',
        content: renderTable,
      },
    ];

    return availableTabs;
  }, [isAdmin, renderTable]);

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Cadastro', 'Tipo de Ocorrências']} />
      <Title
        dataTestId={dataTestId}
        title="Cadastre os tipos de ocorrência que deseja monitorar durante as operações de transporte."
        hasTrace
      />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Descrição"
          type="text"
          changeValue={setSearchInput}
        />

        <DropDownStyles>
          <DropDown title="Exportar">
            <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
          </DropDown>
        </DropDownStyles>

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => handleOpenUploadXlsx()}
              LeftIcon={<CloudUploadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Importar'}
          location={'bottom'}
        />

        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>

      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateOccurrence} />
          <FooterPage.Button
            label="Excluir"
            color="white"
            variant="ghost"
            onPress={() => handleRemoveOccurrence(idsToRemove)}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default OccurrenceListBodyView;
