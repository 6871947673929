import {LineSectionLayout} from 'components-v2/layout/line-section';
import {FC} from 'react';
import * as S from './modal-match-footer-view.styled';

export const ScheduleOfferModalMatchFooterView: FC = () => {
  return (
    <S.Footer>
      <LineSectionLayout columns="0.05fr 1fr 0.05fr 1fr 0.05fr 1fr">
        <S.ScoreMatch color="green"/>
        <S.Text>Alto Potencial</S.Text>
        <S.ScoreMatch color="proYellow"/>
        <S.Text>Médio Potencial</S.Text>
        <S.ScoreMatch color="darkGray"/>
        <S.Text>Baixo Potencial</S.Text>
      </LineSectionLayout>
    </S.Footer>
  );
};
