/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import InputTextareaView from './input-textarea-view';

type InputTextareaProps = {
  id: string;
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: any;
};

const InputTextarea: React.FC<InputTextareaProps> = ({
  id,
  name,
  value = '',
  label,
  placeholder,
  disabled,
  hasError,
  errorMessage,
  onChange = () => null,
}) => {
  return (
    <InputTextareaView
      id={id}
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default InputTextarea;
