export const convertDecimalFormat = Intl.NumberFormat('pt-BR', {
  style: 'decimal',
});

export const convertDecimalCurrencyFormat = Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const convertDecimalUSFormat = (value: string): number => {
  const valueFormatted = Number(value.replace(/\./g, '').replace(',', '.'));

  if (isNaN(valueFormatted)) {
    return 0;
  }

  return valueFormatted;
};
