import { convertTypeEntityToDomainMapper } from 'pages/location/mappers/convert-type-entity-to-domain/convert-type-entity-to-domain.mapper';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { ILocationResponse } from '../../get-location-list.types';

export const convertLocationResponseToDomain = (location: ILocationResponse): ILocationDomain => ({
  id: location.id,
  type: convertTypeEntityToDomainMapper(location.type),
  name: location.name,
  cnpj: location.cnpj,
  internalCode: location.internalCode,
  vehicleCategoryType: [],
  vehicleRestriction: location.vehicleRestriction,
  schedulingEmail: location.schedulingEmail,
  occurrenceEmail: location.occurrenceEmail,
  status: location.status,
  clientIds: [],
  operationType: [],
  quality_and_safety: location.quality_and_safety,
  lat: location.lat,
  lng: location.lng,
  address: {
    cep: location.cep,
    city: location.city,
    uf: location.uf,
    street: location.street,
    lat: location.lat,
    lng: location.lng,
    numberHouse: location.numberHouse,
    neighborhood: location.neighborhood,
    complement: location.complement,
  },
  company: location.company,
  inbound: location.inbound,
  outbound: location.outbound,
  contacts: [],
  attachments: [],
  originalAttachments: [],
});
