import { useParams } from 'react-router-dom';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { HeaderTitle, Wrapper } from '../styled';

import { CteListContent } from './freight-cargo-cte-section.styled';
import { RenderCTeHeaders, RenderEmptyHeaders } from './components/render-uploaded-cte-data/index.style';
import { RenderCTeUploadedData } from './components/render-uploaded-cte-data';

import { convertToNumber } from 'utils-v2/converters/to-number';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { UseTransactionUpdateCteLogshare } from 'pages-v2/schedule/hooks/update-transaction-cte-logshare';
import { CalculatorPaymentForm } from 'pages-v2/schedule/details/sections/transactions/calculate';
import { FileListUploaded, GetCTeResponse } from 'pages/freight/types';
import { getCTeService } from 'pages/freight/services';
import { useHandleGetCteUploadedListHook, useHandleUploadCteFileHook } from 'pages/freight/hooks';
import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { TransactionRegisterInput } from 'domain-v2/transactions/register';
import { transactionDetailsInitialValues } from 'domain-v2/transactions/details';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { ScheduleDomain } from 'domain/schedule';
import Loading from 'components-v2/common/loading/index';
import { ModalForm } from 'components/modal-form/modal-form';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface IProps {
  freightValues: ScheduleDomain;
  onRefresh: Dispatch<SetStateAction<number>>;
}

export const FreightCTESectionView: React.FC<IProps> = ({ freightValues, onRefresh }) => {
  const { id } = useParams();

  const { user, isAdmin } = useContext(UserContext);
  const { nfeIsLoading } = useContext(AllocationRegisterContext);

  const handleUploadCteFileHook = useHandleUploadCteFileHook();
  const handleGetCteUploadedListHook = useHandleGetCteUploadedListHook();

  const [calculateModal, setCalculateModal] = useState<ModalState>({
    ...initialModalState,
  });
  const [cteUploadedList, setCteUploadedList] = useState<FileListUploaded[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const calculatorFormRef = useRef<FormikProps<TransactionRegisterInput>>(null);

  const handleUpdateStatusCteData = useCallback(
    async (cteList: GetCTeResponse[]) => {
      if (cteList && id) {
        const responseCteUploadedList = await handleGetCteUploadedListHook(id);
        setCteUploadedList(responseCteUploadedList);
      }

      setIsLoading(false);
    },
    [id],
  );

  const handleUpdateCteData = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);

    const response = await getCTeService(id);
    await handleUpdateStatusCteData(response);
  }, [id, handleUpdateStatusCteData]);

  const handleChangeFile = async (value: any) => {
    if (id) {
      await handleUploadCteFileHook(
        value,
        id,
        user?.profile === 'shipping-company' || (freightValues.currentFreight == 2 && !isAdmin),
      );
      await handleUpdateCteData();
    }
  };

  const renderCtesList = useMemo(() => {
    if (isLoading)
      return (
        <div style={{ width: 40, margin: '0 auto' }}>
          <Loading />
        </div>
      );

    if (cteUploadedList.length > 0) {
      const list = cteUploadedList.map((cte, index) => {
        let cteKey = '-';

        if (cte.name?.split('.').pop() === 'xml') {
          cteKey = cte.key ?? '';
        }

        return (
          <RenderCTeUploadedData
            key={cte.id}
            index={index + 1}
            cte={cte}
            cteKey={cteKey}
            value={cte.value}
            onRefresh={handleUpdateCteData}
            freightValues={freightValues}
          />
        );
      });

      return list;
    }

    return [];
  }, [cteUploadedList, isLoading]);

  const handleUpdateTransaction = useCallback(
    async (transactionId: number, transactionValues: TransactionRegisterInput) => {
      try {
        await UseTransactionUpdateCteLogshare(transactionId, {
          ...transactionValues,
          totalFreight: convertToNumber(transactionValues.totalFreight),
          valueFreight: convertToNumber(transactionValues.valueFreight),
          helperTotal: convertToNumber(transactionValues.helperTotal),
          discharge: convertToNumber(transactionValues.discharge),
          totalGrisAdvalorem: convertToNumber(transactionValues.totalGrisAdvalorem),
          surplusTime: convertToNumber(transactionValues.surplusTime),
          penaltyBonus: convertToNumber(transactionValues.penaltyBonus),
          totalFee: convertToNumber(transactionValues.totalFee),
          toll: convertToNumber(transactionValues.toll),
          otherTaxes: convertToNumber(transactionValues.otherTaxes),
          totalService: convertToNumber(transactionValues.totalService),
          icms: convertToNumber(transactionValues.icms),
          iss: convertToNumber(transactionValues.iss),
        });

        onRefresh((prev) => prev + 1);

        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });

        setCalculateModal((old) => ({ ...old, open: false }));
      } catch (error) {
        showMessageFormatted({
          message: '',
          type: 'error',
        });
      }
    },
    [],
  );

  const getTransaction = useMemo(() => {
    const transactionPayable =
      freightValues.calcFinanceData?.find((value) => value.status === 'Pagar') || transactionDetailsInitialValues;

    const isTransactionPayable = freightValues.calcFinanceData?.some((value) => value.status === 'Pagar');

    const isTransactionReceive = freightValues.calcFinanceData?.some((value) => value.status === 'Receber');

    const isTransactionShippingReceive = freightValues.calcFinanceData?.some(
      (value) => value.status === 'Receber Transportador',
    );

    return {
      transactionPayable,
      isTransactionPayable,
      isTransactionReceive,
      isTransactionShippingReceive,
    };
  }, [freightValues]);

  useEffect(() => {
    (async () => {
      await handleUpdateCteData();
    })();
  }, [nfeIsLoading]);

  return (
    <>
      {calculateModal.open && (
        <ModalForm
          title={'Informações para emissão do CTE'}
          description={
            <Formik
              innerRef={calculatorFormRef}
              initialValues={getTransaction.transactionPayable}
              onSubmit={(values) => {
                handleUpdateTransaction(getTransaction.transactionPayable.id, values);
              }}
              onReset={() => null}
            >
              <CalculatorPaymentForm
                schedule={freightValues}
                transactionIsShipping={getTransaction.isTransactionPayable}
                transactionIsReceive={getTransaction.isTransactionReceive}
                hasFeeLogshare
              />
            </Formik>
          }
          handleCancel={() => setCalculateModal((old) => ({ ...old, open: false }))}
          size="medium"
        />
      )}
      <Wrapper>
        <HeaderTitle display="flex" justify="space-between" isTraced>
          <div>CTe</div>
          <div style={{ display: 'flex', gap: 10 }}>
            {freightValues.operation === 'TRACKING' &&
              !freightValues.cteIssuer &&
              (freightValues.companyId === user?.companyId || isAdmin) &&
              user?.profile !== 'shipping-company' && (
                <Button
                  title="Emitir via LogShare"
                  size="medium"
                  callback={() => setCalculateModal((old) => ({ ...old, open: true }))}
                  bgColor="newRed"
                />
              )}
            <InputFile onChange={handleChangeFile}>
              <Button title="Anexar" bgColor="aqua" size="very-small" icon={<AttachFileIcon />} />
            </InputFile>
          </div>
        </HeaderTitle>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1.8fr 0.5fr 0.5fr 0.5fr 1fr 0.1fr',
            marginBottom: 10,
          }}
        >
          <RenderCTeHeaders>Identificação</RenderCTeHeaders>
          <RenderCTeHeaders>Extensão</RenderCTeHeaders>
          <RenderCTeHeaders>Tipo</RenderCTeHeaders>
          <RenderCTeHeaders>Número do CTe</RenderCTeHeaders>
          <RenderCTeHeaders>Valor do CTe</RenderCTeHeaders>
          <div style={{ display: 'flex', gap: 15 }}>
            <RenderEmptyHeaders>.</RenderEmptyHeaders>
            <RenderEmptyHeaders>.</RenderEmptyHeaders>
            <RenderEmptyHeaders>.</RenderEmptyHeaders>
            <RenderEmptyHeaders>.</RenderEmptyHeaders>
          </div>
        </div>

        <CteListContent>{renderCtesList}</CteListContent>
      </Wrapper>
    </>
  );
};
