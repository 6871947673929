import { IUsersContext } from './users.types';

export const initialState: IUsersContext = {
  usersIsLoading: false,
  users: [],
  usersError: null,
  usersPageInfo: null,
  setUsersRequest: () => undefined,
  setUsersSuccess: () => undefined,
  setUsersErro: () => undefined,
};

export enum IUsersAction {
  USERS_REQUEST = 'USERS_REQUEST',
  USERS_SUCCESS = 'USERS_SUCCESS',
  USERS_ERROR = 'USERS_ERROR',
}
