import React, { Fragment } from 'react';
import { PGRPage } from 'pages-v2/PGR';
import { BiddingsListPage } from 'pages-v2/biddings';
import { BranchesPage } from 'pages-v2/branches';
import { CheckStubListPage } from 'pages-v2/check-stub/list';
import { FreightPayableShippingPage } from 'pages-v2/freight-payable-shipping';
import { IdlenessBiddingsListPage } from 'pages-v2/idleness-biddings';
import { MyRouteRegisterPage } from 'pages-v2/my-route/register';
import { MyRouteUpdatePage } from 'pages-v2/my-route/update';
import { PalletListPage } from 'pages-v2/pallet/list';
import { PalletRegisterPage } from 'pages-v2/pallet/register';
import { ReWorkListPage } from 'pages-v2/rework/list';
import ReworkRegisterPage from 'pages-v2/rework/rework-register/rework-register-page';
import ScheduleRegisterLTLPage from 'pages-v2/schedule-ltl/register';
import { ScheduleList } from 'pages-v2/schedule/list';
import ScheduleRegisterPageV2 from 'pages-v2/schedule/register';
import ScheduleUpdatePageV2 from 'pages-v2/schedule/update';
import { ShippersAnswerPage } from 'pages-v2/shippers-answer';
import { ShippersHomologatePage } from 'pages-v2/shippers-homologate';
import { ShippingCompanyRegisterPage } from 'pages-v2/shipping-company/mappers/update/register';
import { ShippingCompanyUpdatePage } from 'pages-v2/shipping-company/update';
import { TerritoriesPage } from 'pages-v2/territories';
import { Transactions } from 'pages-v2/transactions/list';
import AcceptedMatchesPage from 'pages/accepted-matches/accepted-matches-list/accepted-matches.page';
import ChangePassword from 'pages/change-password/change-password-register/change-password-register-page';
import ClientRegisterPage from 'pages/client/client-register/client-register';
import ClientUpdatePage from 'pages/client/client-update/client-update';
import CompanyInformation from 'pages/company/company-information/company-information-page';
import ListCompaniesManagement from 'pages/company/company-list/company-list-page';
import CompanyRegister from 'pages/company/company-register/company-register-page';
import CompanyUpdatePage from 'pages/company/company-update/company-update-page';
import DashboardPage from 'pages/dashboard/dashboard.page';
import { DigitalCertificatePage } from 'pages/digital-certificate/digital-certificate.page';
import DriverPage from 'pages/driver/driver-list/driver.page';
import DriverRegisterPage from 'pages/driver/driver-register/driver-register-page';
import DriverUpdatePage from 'pages/driver/driver-update/driver-update-page';
import { EcosystemOfferPage } from 'pages/ecosystem-offer/list';
import FiscalDocumentPage from 'pages/fiscal-document/fiscal-document-list/new-invoice.page';
import FreightReceivablePage from 'pages/freight-receivable/freight-receivable-list/freight-receivable.page';
import { default as FreightUpdatePage } from 'pages/freight/freight-update/freight-upadate-page';
import HireBackhaulRegisterPage from 'pages/hire-backhaul/hire-backhaul-register/hire-backhaul-register-page';
import HireBackhaulUpdatePage from 'pages/hire-backhaul/hire-backhaul-update/hire-backhaul-update-page';
import LocationUpdatePage from 'pages/location/location-details/location-details-page';
import LocationRegisterPage from 'pages/location/location-register/location-register-page';
import MyAccountRegisterPage from 'pages/my-account/form-my-account/my-account-register-page';
import OccurrencePage from 'pages/occurrence/occurrence-list/occurrence.page';
import OccurrenceRegisterPage from 'pages/occurrence/occurrence-register/occurrence-register-page';
import OccurrenceUpdatePage from 'pages/occurrence/occurrence-update/occurrence-update-page';
import QuotesPage from 'pages/quotes/quotes.page';
import SantosPortListPage from 'pages/santos-port/santos-port-list/santos-port-list-page';
import ScheduleLtlRegisterPage from 'pages/schedule-stl/schedule-stl-page';
import ScheduleRegisterPage from 'pages/schedule/schedule-register/schedule-page';
import TableFreightListPage from 'pages/table-freight/table-freight-list/table-freight-list-page';
import TariffPage from 'pages/tariff/tariff-list/tariff.page';
import TaskVisionPage from 'pages/task-vision/task-vision.page';
import TermsOfUse from 'pages/terms-of-use/terms-of-use';
import { TerritorialVisionPage } from 'pages/territorial-vision/territorial-vision-page';
import TrackingOccurrencePage from 'pages/tracking-occurrence/tracking-occurrence-list/tracking-occurrence.page';
import TrackingOccurrenceRegisterPage from 'pages/tracking-occurrence/tracking-occurrence-register/tracking-occurrence-page';
import TrackingOccurrenceUpdatePage from 'pages/tracking-occurrence/tracking-occurrence-update/tracking-occurrence-update-page';
import TransportCompanyPage from 'pages/transport-company/transport-company-list/transport-company.page';
import UserListPage from 'pages/user/user-list/user-list-page';
import UserRegisterPage from 'pages/user/user-register/user-register-page';
import UserUpdatePage from 'pages/user/user-update/user-update-page';
import VehiclePage from 'pages/vehicle/vehicle-list/vehicle.page';
import VehicleRegisterPage from 'pages/vehicle/vehicle-register/vehicle-register-page';
import VehicleUpdatePage from 'pages/vehicle/vehicle-update/vehicle-update-page';
import { SaleOfferListPage } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/sale-offer-list.page';
import { SaleOfferFormRegisterPage } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/sale-offer-register.page';
import { SaleOfferFormUpdatePage } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/sale-offer-update.page';
import { ScheduleRefusedListPage } from 'src-new/pages/execution/pages/schedule-refused/pages/schedule-refused-list/schedule-refused-list.page';
import { MatchesListPage } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/matches-list.page';
import { ScheduleDetailsPage } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/schedule-details.page';
import { ScheduleListPage } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/schedule-list.page';
import { PurchaseOfferListPage } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/purchase-offer-list.page';
import { WeighingListPage } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/weighing-list.page';
import { WeighingFormUpdatePage } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/weighing-update.page';
import { WeighingFormRegisterPage } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/weighing-register.page';
import HomePage from 'pages/home/home';
import { LocationListPage } from 'src-new/pages/registrations/pages/location/pages/location-list/location-list.page';
import { ClientListPage } from 'src-new/pages/registrations/pages/client/pages/client-list/client-list.page';
import { isFeatureEnabled } from 'src-new/infrastructure/feature-toggle/features-toggles';
import { FeaturesToggleEnum } from 'src-new/infrastructure/feature-toggle/features-toggles.enum';
import { TransactionsListPage } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/transactions-list.page';
import { WeighingDuplicatedFormPage } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/weighing-duplicated.page';
import { EquipmentVoucherRegisterPage } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-register/equipment-voucher-register.page';
import { EquipmentVoucherUpdatePage } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-update/equipment-voucher-update.page';
import { EquipmentVoucherListPage } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/equipment-voucher-list.page';

interface RoutesProps {
  path: string;
  profiles: string[];
  name: string;
  component: React.ReactNode;
}

const transactionsPage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_TRANSACTION_LISTING) ? (
  <TransactionsListPage />
) : (
  <Transactions />
);

const equipmentVoucherListPage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <EquipmentVoucherListPage />
) : (
  <PalletListPage />
);

const equipmentVoucherUpdatePage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <EquipmentVoucherUpdatePage />
) : (
  <PalletRegisterPage />
);

const equipmentVoucherRegisterPage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <EquipmentVoucherRegisterPage />
) : (
  <PalletRegisterPage />
);

const checkStubListPage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <Fragment />
) : (
  <CheckStubListPage />
);

const checkStubUpdatePage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <Fragment />
) : (
  <PalletRegisterPage />
);

const checkStubRegisterPage = isFeatureEnabled(FeaturesToggleEnum.ENABLE_PALLET_FORM) ? (
  <Fragment />
) : (
  <PalletRegisterPage />
);

const routesApplication: RoutesProps[] = [
  {
    path: '/home',
    component: <HomePage />,
    name: 'home',
    profiles: ['full'],
  },
  {
    path: '/unidades',
    component: <LocationListPage />,
    name: 'locality',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/unidades/novo',
    component: <LocationRegisterPage />,
    name: 'locality',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/unidades/atualizar/:id',
    component: <LocationUpdatePage />,
    name: 'locality',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/parceiros',
    component: <ClientListPage />,
    name: 'client',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/parceiros/novo',
    component: <ClientRegisterPage />,
    name: 'client',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/parceiros/atualizar/:id',
    component: <ClientUpdatePage />,
    name: 'client',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/transportadoras',
    component: <TransportCompanyPage />,
    name: 'shipping-company',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/transportadoras/novo',
    component: <ShippingCompanyRegisterPage />,
    name: 'shipping-company',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/transportadoras/atualizar/:id',
    component: <ShippingCompanyUpdatePage />,
    name: 'shipping-company',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/veiculos',
    component: <VehiclePage />,
    name: 'vehicle',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/veiculos/novo',
    component: <VehicleRegisterPage />,
    name: 'vehicle',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/veiculos/atualizar/:id',
    component: <VehicleUpdatePage />,
    name: 'vehicle',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/motoristas',
    component: <DriverPage />,
    name: 'driver',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/motoristas/novo',
    component: <DriverRegisterPage />,
    name: 'driver',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/motoristas/atualizar/:id',
    component: <DriverUpdatePage />,
    name: 'driver',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/tipos-ocorrencias',
    component: <OccurrencePage />,
    name: 'occurrence-type',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/ocorrencias/novo',
    component: <OccurrenceRegisterPage />,
    name: 'occurrence-type',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ocorrencias/atualizar/:id',
    component: <OccurrenceUpdatePage />,
    name: 'occurrence-type',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/tarifas',
    component: <TariffPage />,
    name: 'tariff',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/cotações',
    component: <QuotesPage />,
    name: 'bidding',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/ofertas-venda',
    component: <SaleOfferListPage />,
    name: 'transport-route',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ofertas-venda/novo',
    component: <SaleOfferFormRegisterPage />,
    name: 'transport-route',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ofertas-venda/atualizar/:id',
    component: <SaleOfferFormUpdatePage />,
    name: 'transport-route',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/ofertas-venda',
    component: <SaleOfferListPage />,
    name: 'offer-freight',
    profiles: ['shipping-company'],
  },
  {
    path: '/ofertas-venda/novo',
    component: <SaleOfferFormRegisterPage />,
    name: 'offer-freight',
    profiles: ['shipping-company'],
  },
  {
    path: '/ofertas-venda/atualizar/:id',
    component: <SaleOfferFormUpdatePage />,
    name: 'offer-freight',
    profiles: ['shipping-company'],
  },

  {
    path: '/ofertas-compra',
    component: <PurchaseOfferListPage />,
    name: 'idleness',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ofertas-compra/novo',
    component: <HireBackhaulRegisterPage />,
    name: 'idleness',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ofertas-compra/atualizar/:id',
    component: <HireBackhaulUpdatePage />,
    name: 'idleness',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/ofertas-ecossistema',
    component: <EcosystemOfferPage />,
    name: 'ecosystem-offer',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/matches-realizados',
    component: <MatchesListPage />,
    name: 'matches',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/ofertados',
    component: <BiddingsListPage />,
    name: 'offer-freight',
    profiles: ['shipping-company'],
  },

  {
    path: '/ofertas-bid',
    component: <IdlenessBiddingsListPage />,
    name: 'idleness',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/atendidos',
    component: <ShippersAnswerPage />,
    name: 'answer',
    profiles: ['shipping-company'],
  },

  {
    path: '/filiais',
    component: <BranchesPage />,
    name: 'branches',
    profiles: ['shipping-company'],
  },

  {
    path: '/homologados',
    component: <ShippersHomologatePage />,
    name: 'branches',
    profiles: ['shipping-company'],
  },

  {
    path: '/territorios',
    component: <TerritoriesPage />,
    name: 'territories',
    profiles: ['shipping-company'],
  },

  {
    path: '/territorios',
    component: <TerritoriesPage />,
    name: 'territories',
    profiles: ['shipping-company'],
  },

  {
    path: '/alocacao',
    component: <ScheduleList />,
    name: 'schedule-ftl',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/alocacao/novo',
    component: <ScheduleRegisterPageV2 />,
    name: 'schedule-ftl',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/alocacao/novo/V1',
    component: <ScheduleRegisterPage />,
    name: 'schedule-ftl',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/alocacao/:id',
    component: <ScheduleUpdatePageV2 />,
    name: 'schedule-ftl',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/alocacao/:id',
    component: <ScheduleUpdatePageV2 />,
    name: 'offer-freight',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/alocacao-stl/novo',
    component: <ScheduleLtlRegisterPage />,
    name: 'schedule-stl',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/alocacao-ltl/novo',
    component: <ScheduleRegisterLTLPage />,
    name: 'schedule-ltl',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/fretes',
    component: <ScheduleListPage />,
    name: 'freight',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/fretes',
    component: <ScheduleListPage />,
    name: 'schedule-freight',
    profiles: ['shipping-company'],
  },
  {
    path: '/fretes/:id',
    component: <ScheduleDetailsPage />,
    name: 'freight',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/mapa-tracking',
    component: <TerritorialVisionPage />,
    name: 'map',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/tarefas-tracking',
    component: <TaskVisionPage />,
    name: 'task-vision',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },
  {
    path: '/tracking-ocorrencias',
    component: <TrackingOccurrencePage />,
    name: 'occurrence',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/tracking-ocorrencias/novo/:scheduleId',
    component: <TrackingOccurrenceRegisterPage />,
    name: 'occurrence',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/tracking-ocorrencias/novo/:scheduleId',
    component: <TrackingOccurrenceRegisterPage />,
    name: 'shipping-occurrence',
    profiles: ['shipping-company'],
  },
  {
    path: '/tracking-ocorrencias/atualizar/:id/:scheduleId',
    component: <TrackingOccurrenceUpdatePage />,
    name: 'occurrence',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/tracking-ocorrencias/atualizar/:id/:scheduleId',
    component: <TrackingOccurrenceUpdatePage />,
    name: 'shipping-occurrence',
    profiles: ['shipping-company'],
  },
  {
    path: '/check-stub',
    component: checkStubListPage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/check-stub/novo',
    component: checkStubRegisterPage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/check-stub/atualizar/:id',
    component: checkStubUpdatePage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pallet',
    component: equipmentVoucherListPage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pallet/novo',
    component: equipmentVoucherRegisterPage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pallet/atualizar/:id',
    component: equipmentVoucherUpdatePage,
    name: 'pallet',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/transacoes',
    component: transactionsPage,
    name: 'freight-payable',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/transacoes/:id',
    component: <FreightUpdatePage />,
    name: 'freight-payable',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/fretes-receber',
    component: <FreightReceivablePage />,
    name: 'freight-receivable',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/fretes-transportador',
    component: transactionsPage,
    name: 'freight-transporter',
    profiles: ['shipping-company'],
  },
  {
    path: '/fretes/:id',
    component: <ScheduleDetailsPage />,
    name: 'freight-transporter',
    profiles: ['shipping-company'],
  },
  {
    path: '/fretes-pagar-transportador',
    component: <FreightPayableShippingPage />,
    name: 'freight-payable-transporter',
    profiles: ['shipping-company'],
  },

  {
    path: '/faturamento',
    component: <FiscalDocumentPage />,
    name: 'fiscal-document',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/empresas',
    component: <ListCompaniesManagement />,
    name: 'company',
    profiles: ['logshare'],
  },
  {
    path: '/empresas/novo',
    component: <CompanyRegister />,
    name: 'company',
    profiles: ['logshare'],
  },
  {
    path: '/empresas/atualizar/:id',
    component: <CompanyUpdatePage />,
    name: 'company',
    profiles: ['logshare'],
  },
  {
    path: '/dados-do-parceiro/',
    component: <CompanyInformation />,
    name: 'company-info',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/minha-conta',
    component: <MyAccountRegisterPage />,
    name: 'account-info',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/usuarios',
    component: <UserListPage />,
    name: 'user-management',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/usuarios/novo',
    component: <UserRegisterPage />,
    name: 'user-management',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/usuarios/atualizar/:id',
    component: <UserUpdatePage />,
    name: 'user-management',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/alterar-senha',
    component: <ChangePassword />,
    name: 'reset-password',
    profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
  },

  {
    path: '/termos-de-uso',
    component: <TermsOfUse />,
    name: 'reset-password',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/pgr',
    component: <PGRPage />,
    name: 'pgr',
    profiles: ['shipping-company'],
  },

  {
    path: '/dashboard',
    component: <DashboardPage />,
    name: 'reset-password',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/porto-santos',
    component: <SantosPortListPage />,
    name: 'port-santos',
    profiles: ['logshare'],
  },
  {
    path: '/tabela-fretes',
    component: <TableFreightListPage />,
    name: '',
    profiles: ['logshare'],
  },

  {
    path: '/cargas-recusadas',
    component: <ScheduleRefusedListPage />,
    name: 'refused-loads',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/minhas-rotas',
    component: <AcceptedMatchesPage />,
    name: 'my-routes',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pesagem',
    component: <WeighingListPage />,
    name: 'weighing',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pesagem/novo',
    component: <WeighingFormRegisterPage />,
    name: 'weighing',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pesagem/atualizar/:id',
    component: <WeighingFormUpdatePage />,
    name: 'weighing',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/pesagem/duplicated/:id',
    component: <WeighingDuplicatedFormPage />,
    name: 'weighing',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/minhas-rotas/novo',
    component: <MyRouteRegisterPage />,
    name: 'my-routes',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/minhas-rotas/atualizar/:id',
    component: <MyRouteUpdatePage />,
    name: 'my-routes',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/certificado-digital',
    component: <DigitalCertificatePage />,
    name: 'digital-certificate',
    profiles: ['shipper', 'condominium', 'driver'],
  },

  {
    path: '/retrabalho',
    component: <ReWorkListPage />,
    name: 'rework',
    profiles: ['shipper', 'condominium', 'driver'],
  },
  {
    path: '/retrabalho/novo',
    component: <ReworkRegisterPage />,
    name: 'rework',
    profiles: ['shipper', 'condominium', 'driver', 'full'],
  },
  {
    path: '/retrabalho/atualizar/:id',
    component: <ReworkRegisterPage />,
    name: 'rework',
    profiles: ['shipper', 'condominium', 'driver'],
  },
];

export { routesApplication };
