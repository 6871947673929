import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { UseGetPriceScheduleLTLCalculate } from 'pages-v2/schedule-ltl/hooks/get-price-calculate';
import { useValidationFields } from 'pages-v2/schedule-ltl/context/validation-fields';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';
import { SchedulePriceLTL, initialSchedulePriceLTLValues } from 'domain-v2/schedule-ltl/price';
import { Operation } from 'domain-v2/inputs/operation';
import { ModalityType } from 'domain-v2/inputs/modality-type';
import { ICompany } from 'domain/company';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';

interface IScheduleFormProps {
  company: ICompany;
  freightModality: ModalityType;
  priceScheduleLTL: SchedulePriceLTL;
  setPriceScheduleLTL: Dispatch<SetStateAction<SchedulePriceLTL>>;
  setLoadingFreightValues: Dispatch<SetStateAction<boolean>>;
  handleChangeOperation: (
    operation: Operation,
    operationBy: string,
    modalityType: ModalityType,
    setFieldValue: (field: string, value: any) => Promise<any>,
  ) => void;
}

const ScheduleCargoDetailsForm: React.FC<IScheduleFormProps> = ({
  company,
  freightModality,
  priceScheduleLTL,
  setPriceScheduleLTL,
  setLoadingFreightValues,
  handleChangeOperation,
}) => {
  const { values, setFieldValue } = useFormikContext<ScheduleLTLRegisterInput>();

  const { setScheduleLTLValues } = useValidationFields();

  const [grisAdvalorem, setGrisAdvalorem] = useState<number>(0);

  const [initialFreightCalculateValues, setInitialFreightCalculateValues] =
    useState<SchedulePriceLTL>(initialSchedulePriceLTLValues);

  const { origin, destination, cargo, detail } = values;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const valueDateTime = (value: string) => {
    return dayjs(value).isValid() ? dayjs(value) : undefined;
  };

  const handleFieldDateChange = useCallback(
    (fieldName: string, date: dayjs.Dayjs | null) => {
      setFieldValue(fieldName, date?.toISOString());
      switch (fieldName) {
        case 'detail.levyInitialDate':
          setFieldValue('detail.levyInitialDate', date?.toISOString());
          setFieldValue('detail.levyEndDate', date?.toISOString());
          setFieldValue('detail.deliveryInitialDate', date?.add(priceScheduleLTL.leadTime, 'days').toISOString());
          setFieldValue('detail.deliveryEndDate', date?.add(priceScheduleLTL.leadTime, 'days').toISOString());
          break;
        case 'detail.deliveryInitialDate':
          setFieldValue('detail.deliveryInitialDate', date?.toISOString());
          setFieldValue('detail.deliveryEndDate', date?.toISOString());
          break;
      }
    },
    [priceScheduleLTL.leadTime, setFieldValue],
  );

  const levyInitialMinDate = useMemo(() => {
    const day = dayjs().add(1, 'day').startOf('day');

    return day;
  }, []);

  useEffect(() => {
    const gris = (Number(cargo.merchValue) * Number(company?.gris?.replace(',', '.'))) / 100;

    const advalorem = (Number(cargo.merchValue) * Number(company?.advalorem?.replace(',', '.'))) / 100;

    const sum = gris + advalorem;

    setGrisAdvalorem(sum);
  }, [cargo.merchValue, company?.advalorem, company?.gris]);

  useEffect(() => {
    const getValues = async () => {
      if (!origin.id || !destination.id || !cargo.weight) {
        return;
      }

      setLoadingFreightValues(true);

      const responseFreight = await UseGetPriceScheduleLTLCalculate({
        origin: {
          id: origin.id,
          type: origin.type,
          uf: origin.origin.address.uf,
          lat: Number(origin.origin.lat),
          lng: Number(origin.origin.lng),
        },
        destination: {
          id: destination.id,
          type: destination.type,
          uf: destination.destination.address.uf,
          lat: Number(destination.destination.lat),
          lng: Number(destination.destination.lng),
        },
        weight: Number(cargo.weight),
        volume: Number(cargo.volume),
        levyInitialDate: detail.levyInitialDate,
        deliveryInitialDate: detail.deliveryInitialDate,
      });

      setPriceScheduleLTL(responseFreight);
      setInitialFreightCalculateValues(responseFreight);

      setLoadingFreightValues(false);
    };

    getValues();
  }, [
    values.origin.id,
    values.destination.id,
    cargo.weight,
    cargo.volume,
    origin.id,
    origin.type,
    origin.origin.address.uf,
    origin.origin.lat,
    origin.origin.lng,
    destination.id,
    destination.type,
    destination.destination.address.uf,
    destination.destination.lat,
    destination.destination.lng,
    setPriceScheduleLTL,
  ]);

  useEffect(() => {
    const costFields = {
      'cost.valueFreight': priceScheduleLTL[freightModality].value,
      'cost.valueGoods': 0,
      'cost.valueTotalFee': 0,
      'cost.taxes': 0,
      'cost.totalFreight': priceScheduleLTL[freightModality].value + (grisAdvalorem || 0),
      'cost.helperTotal': 0,
      'cost.toll': 0,
      'cost.totalGrisAdvalorem': grisAdvalorem || 0,
      'cost.totalService': priceScheduleLTL[freightModality].valueTotal + (grisAdvalorem || 0),
      'cost.leadTime': 0,
      'cost.icms': priceScheduleLTL[freightModality].icms,
      'cost.date': priceScheduleLTL[freightModality].date,
      distance: priceScheduleLTL.distance,
    };

    Object.entries(costFields).forEach(([field, value]) => {
      setFieldValue(field, value);
    });
  }, [priceScheduleLTL, grisAdvalorem, setFieldValue, freightModality]);

  useEffect(() => {
    setScheduleLTLValues({ ...values, cargo, detail });
  }, [cargo, detail, setScheduleLTLValues, values]);

  useEffect(() => {
    setFieldValue('cargo.vehicleType', 'fracionado');
    setFieldValue('cargo.vehicleCategoryType', 'bau_seco');
  }, [setFieldValue]);

  const handlePriceScheduleLTL = useCallback(
    (modalityType: ModalityType, resetState: boolean) => {
      setLoadingFreightValues(true);
      if (!resetState) {
        setPriceScheduleLTL((prev) => ({
          ...prev,
          [modalityType]: { ...prev[modalityType], date: detail.deliveryInitialDate },
        }));
        setLoadingFreightValues(false);

        return;
      }

      setPriceScheduleLTL((prev) => ({
        ...prev,
        [modalityType]: { ...initialFreightCalculateValues[modalityType] },
      }));
      setLoadingFreightValues(false);
    },
    [setLoadingFreightValues, setPriceScheduleLTL, detail.deliveryInitialDate, initialFreightCalculateValues],
  );

  useEffect(() => {
    const checkAndHandlePriceSchedule = (modality: ModalityType) => {
      const priceSchedule = priceScheduleLTL[modality];

      if (moment(detail.deliveryInitialDate).isSameOrAfter(moment(priceSchedule.date))) {
        handlePriceScheduleLTL(modality, false);
        handleChangeOperation('SPOT', 'Logshare', modality, setFieldValue);
        return true;
      }

      handlePriceScheduleLTL(modality, true);
      handleChangeOperation('SPOT', 'Logshare', modality, setFieldValue);
    };

    if (checkAndHandlePriceSchedule('optimized')) return;
    if (checkAndHandlePriceSchedule('recommended') && checkAndHandlePriceSchedule('advance')) return;
    if (checkAndHandlePriceSchedule('advance')) return;
    if (checkAndHandlePriceSchedule('air')) return;
    if (checkAndHandlePriceSchedule('recommended')) return;
  }, [
    detail.deliveryInitialDate,
    handleChangeOperation,
    handlePriceScheduleLTL,
    setFieldValue,
    setLoadingFreightValues,
  ]);

  return (
    <SectionLayout name="Cargo details information">
      <LineSectionLayout columns="1fr 1fr 1fr 1fr 1fr">
        <div style={{ marginTop: 6 }}>
          <DateTimePikers
            label="Data Inicial Da Coleta *"
            format="DD/MM/YYYY HH:mm"
            minDate={levyInitialMinDate}
            value={valueDateTime(detail.levyInitialDate)}
            onChange={(date) => {
              handleFieldDateChange('detail.levyInitialDate', date);
            }}
          />
        </div>

        <div style={{ marginTop: 6 }}>
          <DateTimePikers
            label="Data Final Da Entrega *"
            format="DD/MM/YYYY HH:mm"
            minDate={dayjs(detail.levyInitialDate)}
            onChange={(date) => {
              handleFieldDateChange('detail.deliveryInitialDate', date);
            }}
            value={valueDateTime(detail.deliveryInitialDate)}
          />
        </div>

        <InputField
          label="Nº OC Interna"
          id="detail.ocInternalNumber"
          name="detail.ocInternalNumber"
          type="text"
          onChange={handleChange}
          value={detail.ocInternalNumber}
        />

        <InputField
          label="Nº Embarque"
          id="detail.boardingNumber"
          name="detail.boardingNumber"
          type="text"
          onChange={handleChange}
          value={detail.boardingNumber}
        />

        <InputField
          label="Senha"
          id="detail.schedulingPassword"
          name="detail.schedulingPassword"
          type="text"
          onChange={handleChange}
          value={detail.schedulingPassword}
        />
      </LineSectionLayout>

      <LineSectionLayout columns="1fr 1fr 1fr 1fr 1fr">
        <InputCurrency
          label="Peso (Kg) *"
          id="cargo.weight"
          name="cargo.weight"
          type="text"
          allowDecimals={false}
          decimalScale={0}
          decimalsLimit={0}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.weight}
          suffix=" Kg"
        />

        <InputCurrency
          label="Valor da Mercadoria"
          id="cargo.merchValue"
          name="cargo.merchValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          decimalSeparator=","
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', converterNumberPtBrToUs(value ?? '0'))}
          value={Number.parseFloat(cargo.merchValue.toString()).toFixed(2).toString()}
        />

        <InputCurrency
          label="Altura"
          id="cargo.high"
          name="cargo.high"
          type="number"
          suffix=" cm"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.high}
        />
        <InputCurrency
          label="Largura"
          id="cargo.width"
          name="cargo.width"
          type="number"
          suffix=" cm"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.width}
        />
        <InputCurrency
          label="Comprimento"
          id="cargo.length"
          name="cargo.length"
          type="number"
          suffix=" cm"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.length}
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};

export default memo(ScheduleCargoDetailsForm);
