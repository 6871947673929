import { FC, useCallback, useContext, useState } from 'react';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { saleOfferShipperModalRegisterInitialValuesDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register-initial-values.domain';
import {
  ISaleOfferFormRegisterPayloadDomain,
  ISaleOfferShipperRegisterPayloadDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { SaleOfferRegisterShipperFormModalView } from './sale-offer-register-shipper-form-modal-view.component';
import { useFormikContext } from 'formik';

export const SaleOfferRegisterShipperFormModal: FC = () => {
  const { values, setFieldValue } = useFormikContext<ISaleOfferFormRegisterPayloadDomain>();
  const { saleOfferModals } = useContext(SaleOfferContext);

  const [offerShipper, setOfferShipper] = useState<ISaleOfferShipperRegisterPayloadDomain>(
    saleOfferShipperModalRegisterInitialValuesDomain,
  );

  const handleCloseRegisterShipperModal = useCallback(() => {
    saleOfferModals.setModalClose();
  }, [saleOfferModals]);

  const handleChange = useCallback(
    (name: keyof ISaleOfferShipperRegisterPayloadDomain, value: any) => {
      setOfferShipper((prevOfferShipper) => ({
        ...prevOfferShipper,
        [name]: value,
      }));
    },
    [setOfferShipper],
  );

  const handleAddOfferShipper = useCallback(() => {
    setFieldValue('saleOfferDetails', [...values.saleOfferDetails, offerShipper]);
    handleCloseRegisterShipperModal();
  }, [handleCloseRegisterShipperModal, offerShipper, setFieldValue, values.saleOfferDetails]);

  return (
    <SaleOfferRegisterShipperFormModalView
      values={offerShipper}
      handleChange={handleChange}
      handleOnSubmit={handleAddOfferShipper}
      handleCloseRegisterShipperModal={handleCloseRegisterShipperModal}
    />
  );
};
