import { FC, useContext, useMemo } from 'react';
import { PurchaseOfferModalMatchHeaderView } from './purchase-offer-modal-match-header-view.component';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';

export const PurchaseOfferModalMatchHeader: FC = () => {
  const { purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const { purchaseOfferMatchProposal } = purchaseOfferMatchList;
  const { statusRequest, proposal } = purchaseOfferMatchProposal;

  const getProposalMatch = useMemo(() => {
    return proposal;
  }, [proposal]);

  const isLoading = useMemo(() => {
    return statusRequest === 'PROCESSING';
  }, [statusRequest]);

  return <PurchaseOfferModalMatchHeaderView isLoading={isLoading} proposal={getProposalMatch} />;
};
