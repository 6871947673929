/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

export const CustomWrapper = styled.div`
  ${(p: any) => p.styles}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.width.fullView};
    height: ${theme.height.fullView};
    top: 0;
    left: 0;
    position: absolute;
    padding: 20px;
    background-color: ${theme.modals.background};
    z-index: 1050;
    color: ${theme.colorsV2.black};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${theme.modals.height.large};
    width: ${theme.modals.width.xlarge};
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    padding: 30px;
    overflow-y: auto;
    gap: 20px;
  `}
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const ButtonClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid rgb(217, 217, 217);
`;
