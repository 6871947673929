import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { ScheduleExecutionActionType } from '../../schedule.action';
import {
  IScheduleExecution,
  ITruckRoutes,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';
import { scheduleExecutionReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-execution/schedule-execution.reducer';
import MapTypeId = google.maps.MapTypeId;

export const useScheduleExecutionContextValues = (): IScheduleExecution => {
  const [state, dispatch] = useReducer(scheduleExecutionReducer, scheduleInitialState);

  const setMapAccordionRequest = useCallback(() => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_REQUEST, payload: undefined });
  }, []);

  const setMapAccordionSuccess = useCallback(() => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_SUCCESS, payload: undefined });
  }, []);

  const setMapAccordionError = useCallback(() => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_ERROR, payload: undefined });
  }, []);

  const setFirebaseTruckRoutes = useCallback((truckRoutes: ITruckRoutes) => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_FIREBASE, payload: truckRoutes });
  }, []);

  const setMonitoringTruckRoutes = useCallback((truckRoutes: ITruckRoutes) => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_MONITORING, payload: truckRoutes });
  }, []);

  const setPlannedRoute = useCallback((plannedRoute: boolean) => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_MAP_ACCORDION_PLANNED, payload: plannedRoute });
  }, []);

  const setMapType = useCallback((type: MapTypeId) => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_SET_MAP_TYPE, payload: type });
  }, []);

  const setScheduleExecutionReset = useCallback(() => {
    dispatch({ type: ScheduleExecutionActionType.SCHEDULE_EXECUTION_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleExecution,
    mapAccordionProps: {
      ...state.scheduleExecution.mapAccordionProps,
      setMapAccordionRequest,
      setMapAccordionSuccess,
      setMapAccordionError,
      setFirebaseTruckRoutes,
      setMonitoringTruckRoutes,
      setPlannedRoute,
      mapTypeActions: {
        ...state.scheduleExecution.mapAccordionProps.mapTypeActions,
        setMapType,
      },
    },
    setScheduleExecutionReset,
  };
};
