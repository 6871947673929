type LinkMap = {
  [key: string]: string;
};

export const convertAlertsRedirects = (link: string): string => {
  const linksMap: LinkMap = {
    SCHEDULE_LIST: '/fretes',
    BIDDING_LIST: '/home',
  };

  return linksMap[link] || 'Não Mapeado';
};
