import React from 'react';
import * as S from './title-view.styled';

interface ITitleViewProps {
  title: string;
}

export const TitleView: React.FC<ITitleViewProps> = ({ title }) => {
  return (
    <S.ContainerTitle>
      <S.Title>{title}</S.Title>

      <S.Trace />
    </S.ContainerTitle>
  );
};
