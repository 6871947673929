export const downloadXlsxFileUtil = (blobPart: ArrayBuffer, filename: string, extensionType?: string) => {
  const blob = new Blob([blobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = `${filename}.${extensionType ?? 'xlsx'}`;
  document.body.appendChild(a);

  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
