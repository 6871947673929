/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UserRegisterSchema } from '../schemas-validation/schemas-validation';

import UserRegisterFormView from './user-register-form-view';
import { PATH_TO_USER_LIST } from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useUserRegisterHook } from 'pages/user/hooks/user-register-hooks/user-register.hook';
import { INewUser, SubPermissions } from 'domain/user';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const initialValues = {
  name: '',
  login: '',
  email: '',
  status: 'ativo',
  telephone: '',
  password: '',
  passwordConfirmation: '',
  resetPassword: false,
  home: [],
  register: [],
  schedules: [],
  tracking: [],
  opportunities: [],
  execution: [],
  financial: [],
  services: [],
  settings: [],
} as any;

const UserRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialPermissions, setInitialPermissions] = useState(initialValues);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const userRegisterHook = useUserRegisterHook();

  const goBackToUserList = useCallback(() => navigate(PATH_TO_USER_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (user: INewUser) => {
      setIsLoading(true);
      userRegisterHook(user)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToUserList();
        })
        .catch((error) => {
          showMessageFormatted({
            error: error,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToUserList, userRegisterHook],
  );

  useEffect(() => {
    initialValues['home'] = [];
    initialValues['register'] = [];
    initialValues['schedules'] = [];
    initialValues['tracking'] = [];
    initialValues['opportunities'] = [];
    initialValues['execution'] = [];
    initialValues['financial'] = [];
    initialValues['services'] = [];
    initialValues['settings'] = [];

    user?.functionalities?.map((functionality) => {
      if (functionality.parent) {
        const subPermissions: SubPermissions = {
          id: functionality.id,
          label: functionality.name,
          name: functionality.name,
          value: true,
          parent: functionality.parent,
          profiles: functionality.profiles,
          routePath: functionality.routePath,
        };
        initialValues[functionality.parent]?.push(subPermissions);
      }
    });
    setInitialPermissions({ ...initialValues });
  }, [user]);

  return (
    <UserRegisterFormView
      initialValues={initialPermissions}
      handleGoback={goBackToUserList}
      handleRegister={handleSubmit}
      validationSchema={UserRegisterSchema}
      login={`@${user?.companyLogin}`}
      isLoading={isLoading}
    />
  );
};

export default UserRegisterForm;
