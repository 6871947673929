import { FC, Fragment, useMemo } from 'react';
import { IModalBark } from './modal-bark.types';
import * as S from './modal-bark-view.styled';
import { ModalCloseStyled } from './modal-bark-view.styled';

export const ModalBarkView: FC<IModalBark> = ({ size, title, children, handleClose, hiddenOverFlow }) => {
  const renderHeader = useMemo(() => {
    if (title || handleClose) {
      const closeButton = handleClose ? (
        <S.ModalBarkClose onClick={(e) => e.stopPropagation()}>
          <ModalCloseStyled onClick={() => handleClose()}>X</ModalCloseStyled>
        </S.ModalBarkClose>
      ) : (
        <Fragment />
      );

      return (
        <S.ModalBarkHeader>
          <S.ModalBarkWrapperTitles>
            <S.ModalBarkTitle>{title}</S.ModalBarkTitle>
          </S.ModalBarkWrapperTitles>
          {closeButton}
        </S.ModalBarkHeader>
      );
    }
  }, [handleClose, title]);

  return (
    <S.ModalBarkWrapper>
      <S.ModalBarkContent size={size} hiddenOverFlow={hiddenOverFlow}>
        {renderHeader}
        <S.ModalBarkWrapperChildren hiddenOverFlow={hiddenOverFlow}>{children}</S.ModalBarkWrapperChildren>
      </S.ModalBarkContent>
    </S.ModalBarkWrapper>
  );
};
