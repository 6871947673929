import { IOccurrencesTrackingContext } from './tracking-occurrence.types';

export const initialState: IOccurrencesTrackingContext = {
  occurrencesTrackingIsLoading: false,
  occurrencesTracking: [],
  occurrencesTrackingError: undefined,
  occurrencesTrackingPageInfo: undefined,
  setOccurrencesTrackingRequest: () => undefined,
  setOccurrencesTrackingSuccess: () => undefined,
  setOccurrencesTrackingError: () => undefined,
};

export enum IOccurrencesTrackingAction {
  OCCURRENCES_TRACKING_REQUEST = 'OCCURRENCES_TRACKING_REQUEST',
  OCCURRENCES_TRACKING_SUCCESS = 'OCCURRENCES_TRACKING_SUCCESS',
  OCCURRENCES_TRACKING_ERROR = 'OCCURRENCES_TRACKING_ERROR',
}
