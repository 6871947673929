import React, { Fragment, ReactElement, useMemo } from 'react';
import * as S from './equipment-voucher-update-page-body-view.styled';
import { Formik } from 'formik';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import { PalletForm } from 'src-new/pages/financial/components/pallet-form/pallet-form.component';
import { RegisterPalletFormValidation } from 'src-new/pages/financial/components/pallet-form/validations/yup-validation';
import { IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';
import { Loading } from 'src-new/components/loading/loading.component';

interface IEquipmentVoucherUpdatePageBodyViewProps {
  palletDetails: IPalletDomain;
  isLoading: boolean;
  handleSubmit: (palletValues: IPalletDomain) => void;
}

export const EquipmentVoucherUpdatePageBodyView: React.FC<IEquipmentVoucherUpdatePageBodyViewProps> = ({
  palletDetails,
  isLoading,
  handleSubmit,
}) => {
  const renderContent = useMemo((): ReactElement => {
    if (isLoading) {
      return (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      );
    }

    return (
      <Formik initialValues={palletDetails} validationSchema={RegisterPalletFormValidation} onSubmit={handleSubmit}>
        <PalletForm />
      </Formik>
    );
  }, [handleSubmit, isLoading, palletDetails]);

  return (
    <Fragment>
      <ApplicationPageHeader
        headerProps={{
          breadcrumbList: ['Financeiro', 'Vale Equipamentos'],
          title: 'Atualização Cadastral',
          hasBackButton: true,
        }}
      />

      {renderContent}
    </Fragment>
  );
};
