import dayjs from 'dayjs';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { ISaleOfferListDomain } from '../../../../domains/sale-offer-list.domain';
import { ISaleOfferListResponse } from '../../../../services/get-sale-offer-list/get-sale-offer-list.types';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';

export const convertSaleOfferListResponseToDomain = (
  saleOfferResponse: ISaleOfferListResponse,
): ISaleOfferListDomain => {
  return {
    ...saleOfferResponse,
    shippingName: saleOfferResponse.shippingName === 'CARGONECT LTDA' ? 'A DEFINIR' : saleOfferResponse.shippingName,
    partnerShipper: saleOfferResponse.partnerShipper ?? 'todos embarcadores',
    initialDate: dayjs(saleOfferResponse.initialDate).format('DD/MM/YYYY'),
    vehicle: {
      type: convertVehicleType(saleOfferResponse.vehicle.type),
      category: convertVehicleCategory(saleOfferResponse.vehicle.category),
    },
    operationType: convertOperationTypeToDomain(saleOfferResponse.operationType),
    valueTotal: convertCurrencyFormat.format(saleOfferResponse.valueTotal),
    totalMatchOffers: Number(saleOfferResponse.totalMatchOffers),
    idTariff: saleOfferResponse.idTariff,
    isActive: saleOfferResponse.isActive ? 'ativa' : 'inativa',
  };
};
