import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getTemplateFilesService } from '../../services/get-template-files/get-template-files.service';
import { downloadFromUrl } from 'src-new/utils/download-file.util';

export const useGetTemplateFiles = () => {
  return useCallback(async (companyId: number) => {
    try {
      const templateLink = await getTemplateFilesService(companyId);

      downloadFromUrl(templateLink);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao realizar o download do template.',
        type: 'error',
      });
    }
  }, []);
};
