import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PageInfo } from '../../../../domain/page-info';
import Title from '../../../../components/title/title';
import { Tabs } from '../../../../components/tabs/tabs';
import { IContentTable } from '../../../../components/table/table.types';
import Input from '../../../../components/input/input';
import { ContainerSearchStyled, DropDownStyles, NewFreight } from './driver-list-body-view.styled';
import DriverTable from './components/driver-table/driver-table';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { ReportOption } from 'domain/report-files';
import { IDriverCreated } from 'domain/driver';
import { FooterPage } from 'components-v2/common/footer-page';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import TooltipComponent from 'components/Tooltip';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import ModalNewFreight from 'components-v2/common/modals/new-freight';

interface IDriverListBodyViewProps {
  drivers: Array<IDriverCreated>;
  driversPageInfo?: PageInfo;
  hasLoading: boolean;
  goToCreateDriver: () => void;
  goToUpdatePage: (driverId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  handleOpenUploadXlsx: () => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  idsToRemove: number[];
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  handleRemoveDriver: (ids: number[]) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'driver-list-body-view';

const DriverListBodyView: React.FC<IDriverListBodyViewProps> = ({
  drivers,
  driversPageInfo,
  hasLoading,
  goToCreateDriver,
  goToUpdatePage,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
  handleOpenUploadXlsx,
  onHandleExport,
  options,
  idsToRemove,
  setIdsToRemove,
  handleRemoveDriver,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}: IDriverListBodyViewProps): JSX.Element => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return drivers.map((driver) => {
      const driverCity = driver.city && driver.uf ? `${driver.city} - ${driver.uf}` : '-';

      return [
        {
          value: (
            <Checkbox
              id="driver.id"
              name="driver.id"
              value={String(driver.id)}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, driver.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== driver.id)])
              }
            />
          ),
          flex: 0.2,
        },
        {
          value: driver.id,
          flex: 0.5,
        },
        {
          value: isAdmin ? driver.companyId?.tradeName : ' ',
          flex: isAdmin ? 0.8 : 0,
        },
        {
          value: driver.name,
          flex: 2.2,
        },
        {
          value: driver.cnhCategory,
          flex: 0.8,
        },
        {
          value: driverCity,
          flex: 1.3,
        },
        {
          value: (
            <Tag
              label={driver.status}
              color={driver.status === 'ativo' ? ColorsV2Type.Green : ColorsV2Type.Red}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.45,
        },
      ];
    });
  }, [drivers]);

  const renderTable = useMemo(() => {
    return (
      <DriverTable
        contentTable={contentTable}
        pageInfo={driversPageInfo}
        goToUpdatePage={goToUpdatePage}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [contentTable, driversPageInfo, goToUpdatePage, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    const availableTabs = [
      {
        name: 'Ativos',
        content: renderTable,
      },
      {
        name: 'Inativos',
        content: renderTable,
      },
    ];

    return availableTabs;
  }, [isAdmin, renderTable]);

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Cadastro', 'Meus Motoristas']} />
      <Title
        dataTestId={dataTestId}
        title="Cadastre os motoristas atuantes nas operações de venda de ociosidade."
        hasTrace
      />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input id="search" name="search" placeholder="Pesquise por Nome" type="text" changeValue={setSearchInput} />

        <DropDownStyles>
          <DropDown title="Exportar">
            <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
          </DropDown>
        </DropDownStyles>

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => handleOpenUploadXlsx()}
              LeftIcon={<CloudUploadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Importar'}
          location={'bottom'}
        />
        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>

      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateDriver} />
          <FooterPage.Button
            label="Excluir"
            color="white"
            variant="ghost"
            onPress={() => handleRemoveDriver(idsToRemove)}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default DriverListBodyView;
