import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ExecutionDocumentsView } from './execution-documents-view.component';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useUpdateNFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-nfe-data/use-update-nfe-data.hook';
import { ScheduleDocumentsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { useUpdateCTeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-cte-data/use-update-cte-data.hook';
import { useUpdateMDFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-mdfe-data/use-update-mdfe-data.hook';
import { useUpdateOthersDocumentsData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-others-documents-data/use-update-others-documents-data.hook';
import { useCreateChecklistDocuments } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-create-checklist-documents/use-create-checklist-documents.hook';
import { useGetChecklistQuestions } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-checklist-questions-documents-data/use-get-checklist-questions-documents-data.hook';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ChecklistAnswer } from 'domain-v2/checklist';
import { useGetChecklistAnswer } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-checklist-answer-documents-data/use-get-checklist-answer-documents-data.hook';
import { useDeleteeChecklistDocuments } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-checklist-documents/use-delete-checklist-documents.hook';

interface IModal {
  open: boolean;
  isEdit: boolean;
}

export interface IModalDelete {
  open: boolean;
  id: number;
}

export const ExecutionDocuments: React.FC = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);
  const updateNFeData = useUpdateNFeData();
  const updateCTeData = useUpdateCTeData();
  const updateMDFeData = useUpdateMDFeData();
  const updateOthersDocumentsData = useUpdateOthersDocumentsData();
  const createChecklistDocuments = useCreateChecklistDocuments();
  const getChecklistQuestions = useGetChecklistQuestions();
  const getChecklistAnswer = useGetChecklistAnswer();
  const deleteeChecklistDocuments = useDeleteeChecklistDocuments();

  const [openModalLink, setOpenModalLink] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<IModal>({ open: false, isEdit: false });
  const [openDeleteModal, setShowDeleteModal] = useState<IModalDelete>({ open: false, id: 0 });

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const handleDelete = useCallback(async () => {
    await deleteeChecklistDocuments(openDeleteModal.id);
    setShowDeleteModal({ open: false, id: 0 });
  }, [deleteeChecklistDocuments, openDeleteModal.id]);

  const handleVisible = async (id: number) => {
    await getChecklistAnswer(id);
    setOpenModal({ open: true, isEdit: true });
  };

  const handleUploadFile = useCallback(
    async (files: FileList, documentType: ScheduleDocumentsModalType) => {
      switch (documentType) {
        case 'nfe':
          await updateNFeData(files);
          setIndexToOpenAccordion(1);
          break;
        case 'cte':
          await updateCTeData(files);
          setIndexToOpenAccordion(2);
          break;
        case 'mdfe':
          await updateMDFeData(files);
          setIndexToOpenAccordion(3);
          break;
        default:
          await updateOthersDocumentsData(files);
          setIndexToOpenAccordion(4);
      }
    },
    [updateCTeData, updateMDFeData, updateNFeData, updateOthersDocumentsData],
  );

  const handleChecklistSubmit = async (checklistAnswer: ChecklistAnswer) => {
    await createChecklistDocuments(scheduleDetails.scheduleDetails.id, checklistAnswer);
    setOpenModal({ open: false, isEdit: false });
  };

  const handleCopyLink = useCallback(() => {
    if (!linkRef.current) return;

    const range = document.createRange();
    range.selectNode(linkRef.current);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand('copy');

        showMessageFormatted({
          message: 'Link copiado!',
          type: 'success',
        });

        setOpenModalLink(false);
      } catch (error) {
        showMessageFormatted({
          message: 'Erro ao copiar link',
          type: 'error',
        });
      } finally {
        selection.removeAllRanges();
      }
    }
  }, [linkRef]);

  const mobileLink = useMemo(() => {
    return `${import.meta.env.VITE_LINK}checklist/${scheduleDocuments.checklistAnswerProps.checklistAnswer.moduleId}/${
      scheduleDetails.scheduleDetails.id
    }`;
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.checklistAnswerProps.checklistAnswer.moduleId]);

  const [indexToOpenAccordion, setIndexToOpenAccordion] = useState(-1);

  return (
    <ExecutionDocumentsView
      indexToOpenAccordion={indexToOpenAccordion}
      handleUploadFile={handleUploadFile}
      scheduleDocuments={scheduleDocuments}
      handleGetQuestions={getChecklistQuestions}
      setOpenModal={setOpenModal}
      setOpenModalLink={setOpenModalLink}
      handleCopyLink={handleCopyLink}
      linkRef={linkRef}
      mobileLink={mobileLink}
      openModalLink={openModalLink}
      openModal={openModal}
      handleChecklistSubmit={handleChecklistSubmit}
      handleVisible={handleVisible}
      handleDelete={handleDelete}
      setShowDeleteModal={setShowDeleteModal}
      openDeleteModal={openDeleteModal?.open}
    />
  );
};
