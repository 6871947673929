import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

interface IInputContentProps {
  disabled?: boolean;
}
export const InputContent = styled.div<IInputContentProps>`
  ${({ disabled }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.75rem;
    border: 1px dashed #1c6ea4;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const Text = styled.div`
  font-size: 14px;
`;

export const TextItalic = styled.i`
  font-size: 14px;
`;

export const TemplateContent = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
`;

interface ITemplateDownloadProps {
  disabled?: boolean;
}
export const TemplateDownload = styled.div<ITemplateDownloadProps>`
  ${({ disabled }) => css`
    font-weight: bold;
    font-style: italic;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;
