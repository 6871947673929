import React, { Fragment, useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { IFreightValidationValues, IFreightValues } from './types';
import * as S from './styled';

import { convertDecimalCurrencyFormat } from 'utils-v2/converters/decimal';
import { ScheduleInput } from 'domain-v2/schedule';

interface CardResumeValuesProps {
  schedule?: ScheduleInput;
  freightValues?: Array<IFreightValidationValues>;
}

export const CardResumeValues: React.FC<CardResumeValuesProps> = ({ schedule, freightValues }) => {
  const renderValues = useCallback(
    (freightValues: IFreightValues) => {
      return (
        <>
          <S.WrapperJustifyInformation>
            <S.Text uppercase>Frete Valor</S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertDecimalCurrencyFormat.format(freightValues.value)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          {freightValues.helperPrice > 0 && (
            <S.WrapperJustifyInformation>
              <S.Text uppercase>Ajudante</S.Text>
              <S.ContainerCurrency>
                <S.Text>R$</S.Text>
                <S.Text>{`${convertDecimalCurrencyFormat.format(freightValues.helperPrice)}`}</S.Text>
              </S.ContainerCurrency>
            </S.WrapperJustifyInformation>
          )}

          <S.WrapperJustifyInformation>
            <S.Text uppercase>Pedágio</S.Text>
            <S.ContainerCurrency>
              {freightValues.calcToll ? (
                <S.Text>Calculando...</S.Text>
              ) : (
                <Fragment>
                  <S.Text>R$</S.Text>
                  <S.Text>{`${convertDecimalCurrencyFormat.format(freightValues.tollPrice ?? 0)}`}</S.Text>
                </Fragment>
              )}
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <div style={{ display: 'flex', gap: 5 }}>
              <S.Text uppercase>Gris/Advalorem</S.Text>
              <Tooltip
                title={
                  <div>
                    <p style={{ fontSize: '11px' }}>
                      Gris: {convertDecimalCurrencyFormat.format(schedule?.companyBuyer?.gris ?? 0)}%
                    </p>
                    <p style={{ fontSize: '11px' }}>
                      Advalorem: {convertDecimalCurrencyFormat.format(schedule?.companyBuyer?.advalorem ?? 0)}%
                    </p>
                  </div>
                }
                placement="right"
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon
                    sx={{
                      height: '14px',
                      width: '14px',
                      color: 'gray',
                    }}
                  />
                </div>
              </Tooltip>
            </div>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertDecimalCurrencyFormat.format(freightValues.grisAdvaloremPrice ?? 0)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text uppercase fontWeight>
              Frete Total
            </S.Text>
            <S.ContainerCurrency>
              <S.Text fontWeight>R$</S.Text>
              <S.Text fontWeight>{`${convertDecimalCurrencyFormat.format(freightValues.totalFreight)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text uppercase>ICMS</S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertDecimalCurrencyFormat.format(freightValues.icms ?? 0)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text uppercase>OUTROS IMPOSTOS</S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertDecimalCurrencyFormat.format(0)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text fontWeight uppercase>
              Total
            </S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text fontWeight>{`${convertDecimalCurrencyFormat.format(freightValues.totalService)}`}</S.Text>{' '}
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>
        </>
      );
    },
    [schedule?.companyBuyer?.advalorem, schedule?.companyBuyer?.gris],
  );

  const renderValuesEntity = useMemo(() => {
    const financeValues = freightValues?.map((values) => {
      const header = (
        <S.Text padding="0">
          <S.Text fontWeight uppercase>
            {values.title}
          </S.Text>
          <S.Text abbreviateText uppercase>
            {values.companyPayable}
          </S.Text>
          <S.Text abbreviateText uppercase>
            {values.companyReceive}
          </S.Text>
          <S.Text abbreviateText uppercase>
            {values.companyShipping}
          </S.Text>
        </S.Text>
      );

      if (values.valuesPayable) {
        return (
          <div key={values.title}>
            {header}
            {renderValues(values.valuesPayable)}
          </div>
        );
      }
      if (values.valuesReceive) {
        return (
          <div key={values.title}>
            {header}
            {renderValues(values.valuesReceive)}
          </div>
        );
      }
      if (values.valuesShipping) {
        return (
          <div key={values.title}>
            {header}
            {renderValues(values.valuesShipping)}
          </div>
        );
      }
      if (values.valuesFeeLogShare) {
        return (
          <div key={values.title}>
            {header}
            <S.WrapperJustifyInformation>
              <S.Text uppercase>Taxa LogShare</S.Text>
              <S.ContainerCurrency>
                <S.Text>R$</S.Text>
                <S.Text>{`${convertDecimalCurrencyFormat.format(values.valuesFeeLogShare)}`}</S.Text>{' '}
              </S.ContainerCurrency>
            </S.WrapperJustifyInformation>
            <S.WrapperJustifyInformation>
              <S.Text fontWeight uppercase>
                Total
              </S.Text>
              <S.ContainerCurrency>
                <S.Text>R$</S.Text>
                <S.Text fontWeight>{`${convertDecimalCurrencyFormat.format(values.valuesFeeLogShare)}`}</S.Text>{' '}
              </S.ContainerCurrency>
            </S.WrapperJustifyInformation>
          </div>
        );
      }
    });

    return financeValues;
  }, [freightValues, renderValues]);

  return renderValuesEntity;
};
