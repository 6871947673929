import React from 'react';

export const MapDownloadButtonIcon: React.FC = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#d7d5d5" stroke="none">
        <path
          d="M1658 4218 l-3 -903 -598 -5 -597 -5 1050 -1050 1050 -1050 1050
1050 1050 1050 -597 5 -598 5 -3 903 -2 902 -900 0 -900 0 -2 -902z"
        />
        <path d="M450 300 l0 -300 2110 0 2110 0 0 300 0 300 -2110 0 -2110 0 0 -300z" />
      </g>
    </svg>
  );
};
