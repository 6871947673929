import { Dispatch, SetStateAction, useCallback } from 'react';
import { showToast } from 'components/toast/toast';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import {
  importRecordsSpreadsheetService,
  ImportRecordsType,
} from 'src-new/pages/registrations/services/import-records-excel/import-records-spreadsheet.service';

export const useImportRecordsSpreadsheet = () => {
  return useCallback(
    async (
      formData: FormData,
      setShowModal: Dispatch<SetStateAction<boolean>>,
      type: ImportRecordsType,
    ): Promise<void> => {
      try {
        const response = await importRecordsSpreadsheetService(formData, type);

        setShowModal(false);
        showToast({
          message: `${response.data.message}`,
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    [],
  );
};
