import React from 'react';

import FormResetPasswordView from '../login-microsoft-form/login-microsoft-form.component';
import TemplateAuthentication from 'components/template-authentication/template-authentication';
import { EmailInformationStyled, MicrosoftIcon, LoginMicrosoftContainerStyled, LoginMocrosoftFormStyled, TextContainerStyled, WrapperSectionLoginLogoStyled } from './login-microsoft.styled';
import { ILoginMicrsoft } from '../../../../types/login-microsof.types';

interface LoginMicrosoftView {
  initialValues: any;
  validationSchema: object;
  handleSubmit: (values: ILoginMicrsoft) => void;
  goBackToLogin: () => void;
}

const LoginMicrosoftView: React.FC<LoginMicrosoftView> = ({goBackToLogin, initialValues, handleSubmit, validationSchema}) => {
  return (
    <TemplateAuthentication>
      <LoginMicrosoftContainerStyled>
        <TextContainerStyled>
          <WrapperSectionLoginLogoStyled><MicrosoftIcon /><span>Microsoft</span></WrapperSectionLoginLogoStyled>
          <EmailInformationStyled>
            Informe seu <strong>e-mail corporativo</strong>. <br />
          </EmailInformationStyled>
        </TextContainerStyled>
        <LoginMocrosoftFormStyled>
          <FormResetPasswordView
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
            goBackToLogin={goBackToLogin}
        />    
        </LoginMocrosoftFormStyled>
      </LoginMicrosoftContainerStyled>   
    </TemplateAuthentication>    
  );
};

export default LoginMicrosoftView;
