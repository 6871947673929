import { IOccurrenceEntityStatus } from '../entities/occurrence.entity';

import API from 'utils/API/API';

export interface IOcurrenceReportExcelProps {
  search: string;
  status: IOccurrenceEntityStatus | undefined;
}

export const ocurrenceReportExcelService = async ({
  search,
  status,
}: IOcurrenceReportExcelProps): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('v2/occurrences/export', {
      params: { status, search },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao gerar xls de ocorrencia');
  }
};
