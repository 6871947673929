import { Button } from 'logshare-ui-kit';
import { FC, Fragment } from 'react';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { Search } from 'src-new/components/application-page-header/components/search/search.component';
import * as S from './weighing-header-view.styled';
import { WeighingListModalType } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { HeaderModals } from '../weighing-modals/header-modals.component';
import { IParamsFilter } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.types';

interface IWeighingHeaderViewProps {
  weighingFilters: Array<IParamsFilter>;
  appliedFilters: number;
  handleNewWeighing: () => void;
  handleSearch: (search: string) => void;
  removeFilter: (indexToRemove: number) => void;
  handleModalOpen: (modalType: WeighingListModalType) => void;
  handleExportExcel: () => void;
}

export const WeighingHeaderView: FC<IWeighingHeaderViewProps> = ({
  weighingFilters,
  appliedFilters,
  handleNewWeighing,
  handleSearch,
  removeFilter,
  handleModalOpen,
  handleExportExcel,
}) => {
  return (
    <Fragment>
      <HeaderModals />
      <S.Header>
        <HeaderPage breadcrumbList={['Programação', 'Pesagem']} />
      </S.Header>
      <S.ContainerSearch>
        <Search
          placeholder="Pesquise pelo ID, Ordem de Coleta, Unidade, Parceiro ou Cidade"
          filters={weighingFilters.map((item) => item.value)}
          appliedFilters={appliedFilters}
          changeValue={handleSearch}
          handleClearFilters={removeFilter}
          handleExportButton={handleExportExcel}
          handleFilterButton={() => handleModalOpen('filter')}
        />

        <Button label="Novo" size="md" color="blue" onPress={handleNewWeighing} variant="solid" />
      </S.ContainerSearch>
    </Fragment>
  );
};
