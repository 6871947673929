import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { ChatMessageQuantityActionType } from '../../chat.action';

export const chatMessageQuantityReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  switch (action.type) {
    case ChatMessageQuantityActionType.CHAT_MESSAGE_QUANTITY_SUCCESS:
      return {
        ...state,
        chatMessageQuantity: {
          ...state.chatMessageQuantity,
          statusRequest: 'SUCCESS',
          chatMessages: action.payload,
        },
      };
    default:
      return state;
  }
};
