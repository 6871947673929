import styled, { css } from 'styled-components';

type RootWrapperProps = { resumeMap?: boolean };
export const RootWrapper = styled.div<RootWrapperProps>`
  ${({ theme, resumeMap }) => css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    min-width: 360px;
    max-width: 370px;

    ${resumeMap
      ? css`
          padding: 15px;
          position: absolute;
          right: 60px;
          top: 10px;
          z-index: 999;
        `
      : css`
          margin-top: 6px;
          padding: 10px;
          padding-bottom: 150px;
        `};
  `};
`;

export const Content = styled.div``;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const ButtonClosed = styled.span`
  cursor: pointer;
  font-size: 12px;
  top: -15px;
  padding-left: 15px;
  position: relative;
`;

export const TagSection = styled.div`
  height: 27px;
  display: flex;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: inline-block;
  padding-top: 3px;
  padding-bottom: 9px;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    display: grid;
    gap: 1px;
    grid-template-columns: ${columns};
  `};
`;

export const StepIcon = styled.div`
  background-color: #d3d3d3;
  z-index: 1;
  color: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
`;

type ConectorProps = { size?: string };
export const Connector = styled.div<ConectorProps>`
  ${({ size }) => css`
    height: ${size}%;
    border: 0;
    width: 2px;
    margin-left: 4px;
    background-color: #d3d3d3;
  `};
`;

type TextProps = {
  blueColor?: boolean;
  fontWeight?: boolean;
  uppercase?: boolean;
  padding?: string;
  italic?: boolean;
  size?: string;
  textColor?: boolean;
  gap?: boolean;
  abbreviateText?: boolean;
};
export const TextContent = styled.div<TextProps>`
  ${({ uppercase, italic, gap = false, abbreviateText = false }) => css`
    font-size: 12px;
    color: #5d5d5d;
    text-transform: ${uppercase && 'uppercase'};
    font-style: ${italic && 'italic'};

    ${gap &&
    `
      display: flex;
      flex-direction: column;
      gap: 15px;
    `}

    ${abbreviateText &&
    `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  `}
`;

export const Text = styled.div<TextProps>`
  ${({
    theme,
    fontWeight,
    italic,
    uppercase = false,
    padding = '0',
    size = '12px',
    textColor,
    abbreviateText = false,
  }) => css`
    padding: ${padding};
    color: ${textColor ? '#C0B8B8' : theme.colorsV2.blue};
    font-weight: ${fontWeight ? '700' : '0'};
    font-size: ${size};
    font-style: ${italic && 'italic'};
    text-transform: ${uppercase ? 'uppercase' : ''};
    ${abbreviateText &&
    `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `};
`;

export const ContainerCurrency = styled.div`
  min-width: 80px;
  max-width: 80px;
  display: flex;
  justify-content: space-between;
`;

export const LinkText = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `};
`;

export const CotationContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `}
`;

type CotationProps = { hideQuotation?: boolean };
export const CotationImageContainer = styled.div<CotationProps>`
  ${({ hideQuotation = false }) => css`
    margin: 13px 0 0 5px;
    cursor: ${hideQuotation ? 'pointer' : 'not-allowed'};
  `};
`;

export const CotationImage = styled.img<CotationProps>`
  ${({ hideQuotation = false }) => css`
    width: 25px;
    opacity: ${hideQuotation ? 0.6 : 0.15};
  `};
`;

export const HeaderButtons = styled.div``;
export const StepIconSection = styled.div``;

export const OperationInformationContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const WrapperJustifyInformation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperTags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
