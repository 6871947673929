import styled from 'styled-components';

export const WrapperCount = styled.div`
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.54rem;
  right: -0.7rem;
  color: white;
  background-color: #e04747;
  border-radius: 100%;
  text-align: center;
  font-size: 9px;
`;
