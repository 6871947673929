import React, { ReactElement, useContext, useMemo } from 'react';
import BackhaulPage from './backhaul/backhaul.page';
import { UserContext } from 'state/user-context';
import BindingsPage from 'pages/biddings';
import { HomePage } from 'src-new/pages/home/pages/home/home.page';

const HomePageView: React.FC = () => {
  const { user } = useContext(UserContext);

  const renderHome = useMemo((): ReactElement => {
    if (user?.profile === 'shipping-company') {
      return <BindingsPage />;
    }

    if (user?.plan === 'basic-seller' || user?.plan === 'basic-buyer') {
      return <BackhaulPage user={user} />;
    }

    return <HomePage />;
  }, [user]);

  return renderHome;
};

export default HomePageView;
