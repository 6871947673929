import { Dispatch, SetStateAction } from 'react';

import ScheduleOriginForm from '../../../components/forms/origin-and-destiny';
import ScheduleCargoDetailsForm from '../../../components/forms/cargo';

import { SchedulePriceLTL } from 'domain-v2/schedule-ltl/price';
import { Operation } from 'domain-v2/inputs/operation';
import { ModalityType } from 'domain-v2/inputs/modality-type';
import { ICompany } from 'domain/company';

interface IScheduleFormProps {
  company: ICompany;
  isFilled: {
    originValues: boolean;
    destinyValues: boolean;
    detailValues: boolean;
  };
  freightModality: ModalityType;
  priceScheduleLTL: SchedulePriceLTL;
  setPriceScheduleLTL: Dispatch<SetStateAction<SchedulePriceLTL>>;
  setLoadingFreightValues: Dispatch<SetStateAction<boolean>>;
  handleChangeOperation: (
    operation: Operation,
    operationBy: string,
    modalityType: ModalityType,
    setFieldValue: (field: string, value: any) => Promise<any>,
  ) => void;
}

export const RenderForms = ({
  company,
  isFilled,
  freightModality,
  priceScheduleLTL,
  setPriceScheduleLTL,
  setLoadingFreightValues,
  handleChangeOperation,
}: IScheduleFormProps) => {
  return [
    {
      section: 'Origem',
      content: <ScheduleOriginForm type="origin" />,
      checked: isFilled.originValues,
      openSection: true,
    },
    {
      section: 'Destino',
      content: <ScheduleOriginForm type="destination" />,
      checked: isFilled.destinyValues,
    },
    {
      section: 'Detalhes da Carga',
      content: (
        <ScheduleCargoDetailsForm
          company={company}
          freightModality={freightModality}
          priceScheduleLTL={priceScheduleLTL}
          setPriceScheduleLTL={setPriceScheduleLTL}
          setLoadingFreightValues={setLoadingFreightValues}
          handleChangeOperation={handleChangeOperation}
        />
      ),
      checked: isFilled.detailValues,
    },
  ];
};
