import { weighingTottensDetailsDomainInitialValues } from '../../pages/weighing-totten-details/domain/weighing-totten-details/weighing-totten-details-initial-values.constants';
import { weighingTottensDetailsParamsInitialValues } from '../../pages/weighing-totten-details/types/weighing-totten-details-filter/weighing-totten-details-filter-initial-values.constants';
import { IWeighingTottensContext } from './types/weighing-tottens.types';

export const weighingTottensInitialState: IWeighingTottensContext = {
  weighingTottensDetails: {
    statusRequest: 'INITIAL',
    weighingTottensDetails: weighingTottensDetailsDomainInitialValues,
    weighingTottensDetailsFiltersProps: {
      filters: weighingTottensDetailsParamsInitialValues,
      setFilters: () => undefined,
    },
    setWeighingTottensDetailsRequest: () => undefined,
    setWeighingTottensDetailsSuccess: () => undefined,
    setWeighingTottensDetailsError: () => undefined,
    setWeighingTottensDetailsReset: () => undefined,
  },
  setWeighingTottensResetContext: () => undefined,
};
