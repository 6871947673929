import * as Yup from 'yup';

export const ChangePasswordSchemas = Yup.object().shape({
  newPassword: Yup.string().required('Nova Senha Obrigatória'),
  // .min(8, 'Senha Deve Ter No Mínimo 8 Caracteres')
  // .matches(/[A-Z]/, 'Senha Deve Conter Pelo Menos Uma Letra Maiúscula'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'As Senhas Devem Corresponder')
    .required('Confirmação De Senha Obrigatória'),
});
