export enum ClientsListActionType {
  CLIENTS_LIST_REQUEST = 'CLIENTS_LIST_REQUEST',
  CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS',
  CLIENTS_LIST_ERROR = 'CLIENTS_LIST_ERROR',
  CLIENTS_LIST_SET_FILTERS = 'CLIENTS_LIST_SET_FILTERS',
  CLIENTS_LIST_RESET = 'CLIENTS_LIST_RESET',
}

export enum ClientsActionType {
  CLIENTS_RESET = 'CLIENTS_RESET',
}
