import { ChangeEvent, FC, useMemo } from 'react';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import Select from 'components/select/select';
import { FormikErrors } from 'formik';
import { Button } from 'logshare-ui-kit';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import * as S from './schedule-details-origin-and-destination-modal-form-view.styled';
import AsyncSelectField from 'components/async-select/async-select';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import InputField from 'components/input-field/input-field';
import { IScheduleOriginAndDestinationPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.types';

interface IScheduleDetailsOriginAndDestinationModalFormViewProps {
  values: IScheduleOriginAndDestinationPayload;
  updateIsLoading: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IScheduleOriginAndDestinationPayload>>;
  handleOnSubmit: () => void;
  handleCompleteInputs: (id: number, type: '' | 'CLIENT' | 'LOCALITY') => Promise<void>;
  handleCloseModalOriginAndDestination: () => void;
}

export const ScheduleDetailsOriginAndDestinationModalFormView: FC<
  IScheduleDetailsOriginAndDestinationModalFormViewProps
> = ({
  values,
  updateIsLoading,
  setFieldValue,
  handleOnSubmit,
  handleCompleteInputs,
  handleCloseModalOriginAndDestination,
}) => {
  const typeOriginAndDestination = useMemo((): string => {
    if (values.locationType === 'origin') return 'Origem';

    return 'Destino';
  }, [values.locationType]);

  return (
    <ModalBark
      size={'sm'}
      title={<S.ModalTitle>Detalhes de {typeOriginAndDestination}</S.ModalTitle>}
      handleClose={handleCloseModalOriginAndDestination}
    >
      <S.ModalWrapper>
        <S.FormWrapper>
          <LineSectionLayout columns="1.04fr 4fr">
            <Select
              label="Tipo *"
              id="type"
              name="type"
              value={values.type}
              setFieldValue={setFieldValue}
              options={OriginAndDestinyTypeOptions}
            />

            <AsyncSelectField
              placeholder=""
              label="Informe o Nome *"
              name="name"
              id="name"
              disabled={!values.type}
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (e) => {
                await handleCompleteInputs((e && e.value) ?? 0, values.type as 'CLIENT' | 'LOCALITY');
                setFieldValue('name', e?.label);
              }}
              apiCallFunction={async (e: string) =>
                await searchOriginOrDestiny(e, values.type as 'CLIENT' | 'LOCALITY')
              }
              loadOptions={async (e: string) => await searchOriginOrDestiny(e, values.type as 'CLIENT' | 'LOCALITY')}
              value={{
                label: values.name?.toUpperCase(),
                value: values.id,
              }}
              errorMessage="Campo Obrigatório"
            />
          </LineSectionLayout>

          <LineSectionLayout columns="0.81fr 1.3fr 1.3fr 0.4fr ">
            <InputField
              label="CEP"
              name={'address.cep'}
              id={'address.cep'}
              type="text"
              value={values.address.cep}
              disabled
            />

            <InputField
              label="Bairro"
              name={'address.neighborhood'}
              id={'address.neighborhood'}
              type="text"
              value={values.address.neighborhood}
              disabled
            />

            <InputField
              label="Cidade"
              name={'address.city'}
              id={'address.city'}
              type="text"
              value={values.address.city}
              disabled
            />

            <InputField
              label="UF"
              name={'address.uf'}
              id={'address.uf'}
              type="text"
              value={values.address.uf}
              disabled
            />
          </LineSectionLayout>

          <LineSectionLayout columns="3fr 0.5fr 1.5fr">
            <InputField
              label="Endereço"
              name={'address.street'}
              id={'address.street'}
              type="text"
              value={values.address.street}
              disabled
            />

            <InputField
              label="Nº"
              name={'address.numberHouse'}
              id={'address.numberHouse'}
              type="text"
              value={values.address.number}
              disabled
            />

            <InputField
              label="Complemento"
              name={'address.complement'}
              id={'address.complement'}
              type="text"
              value={values.address.complement}
              disabled
            />
          </LineSectionLayout>
        </S.FormWrapper>

        <S.WrapperButtonSubmit>
          <Button
            label="Atualizar"
            color="blue"
            onPress={handleOnSubmit}
            size="medium"
            variant="solid"
            isLoading={updateIsLoading}
          />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
