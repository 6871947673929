import styled, { css } from 'styled-components';

export const ContainerStyledOne = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
`;

export const CardRouterBodyStyled = styled.div`
  & > div {
    margin-top: 20px;
    margin-right: 12px;
  }
`;

export const FirstInfoContentStyled = styled.div``;

type SecondInfoContentStyled = { columns: string };
export const SecondInfoContentStyled = styled.div<SecondInfoContentStyled>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    padding-bottom: 10px;
  `}
`;

export const InfoDobleStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoLabelStyled = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
`;

export const ContainerScrollStyled = styled.div`
  margin-right: -15px;
  z-index: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background: rgb(243 243 243);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: 8888889c;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #12264e;
    width: 50px;
  }
`;
