type ConversionMapping = Record<string, Record<string, string>>;

const fieldMappings: ConversionMapping = {
  vehicle: {
    '3/4': '3/4',
    bitrem: 'BITREM',
    bitruck: 'BITRUCK',
    carreta: 'CARRETA',
    carreta_ls: 'CARRETA LS',
    complemento: 'COMPLEMENTO',
    rodotrem: 'RODOTREM',
    'romeu&julieta': 'ROMEU & JULIETA',
    romeu_julieta: 'ROMEU & JULIETA',
    toco: 'TOCO',
    truck: 'TRUCK',
    utilitario: 'UTILITÁRIO',
    van: 'VAN',
    vuc: 'VUC',
    vanderleia: 'VANDERLÉIA',
  },
  category: {
    bau_seco: 'BAÚ SECO',
    bau_frigorificado: 'BAÚ FRIGORIFICADO',
    bau_refrigerado: 'BAÚ REFRIGERADO',
    bau_sider: 'BAÚ SIDER',
    cacamba_basculante: 'BASCULANTE',
    cegonha: 'CEGONHA',
    florestal: 'FLORESTAL',
    grade_baixa: 'GRADE BAIXA',
    graneleiro: 'GRANELEIRO',
    plataforma: 'PLATAFORMA',
    porta_container_20: 'PORTA CONTAINER 20',
    porta_container_40: 'PORTA CONTAINER 40',
    tanque: 'TANQUE',
  },
  status: {
    lance: 'EM LEILÃO',
    aceito: 'ARREMATADO',
    recusado: 'BID RECUSADO',
    cancelado: 'CANCELADO',
  },
};

export const convertField = (field: string, value: string | number): string | number => {
  const fieldMapping = fieldMappings[field];

  return fieldMapping?.[value] ?? value;
};
