import { FC, ReactElement, useMemo } from 'react';
import * as S from './modal-new-freight-view.styled';
import { Button } from 'logshare-ui-kit';
import CrownIcon from 'assets/icons/crown.icon';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { EssencialButton } from './components/essencial-button/essencial-button.component';

interface IModalNewFreightViewProps {
  isEssencial: boolean;
  handleNavigate: (url: string) => void;
  handleClose: () => void;
}
export const ModalNewFreightView: FC<IModalNewFreightViewProps> = ({ isEssencial, handleNavigate, handleClose }) => {
  const renderButtons = useMemo((): ReactElement => {
    if (isEssencial) {
      return (
        <S.Content>
          <EssencialButton
            label="FTL"
            color={ThemeColorsV2Type.Blue}
            onClick={() => handleNavigate('/alocacao/novo')}
            size="medium"
          />

          <EssencialButton
            label="STL"
            color={ThemeColorsV2Type.LightGray}
            onClick={() => handleNavigate('/alocacao-stl/novo')}
            size="medium"
            icon={<CrownIcon />}
            disabled
          />

          <EssencialButton
            label="LTL"
            color={ThemeColorsV2Type.LightGray}
            onClick={() => handleNavigate('/alocacao-ltl/novo')}
            size="medium"
            icon={<CrownIcon />}
            disabled
          />
        </S.Content>
      );
    }

    return (
      <S.Content>
        <Button label="FTL" color="blue" variant="solid" onPress={() => handleNavigate('/alocacao/novo')} size="md" />

        <EssencialButton
          label="STL"
          color={ThemeColorsV2Type.LightGray}
          onClick={() => handleNavigate('/alocacao-stl/novo')}
          size="medium"
          icon={<CrownIcon />}
          disabled
        />

        <Button
          label="LTL"
          color="aqua"
          variant="solid"
          onPress={() => handleNavigate('/alocacao-ltl/novo')}
          size="md"
        />
      </S.Content>
    );
  }, [handleNavigate, isEssencial]);

  return (
    <S.Wrapper>
      <S.Containter>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Novo Agendamento</S.Title>
            <S.Subtitle>Escolha a modalidade do frete.</S.Subtitle>
          </S.ContainerTitle>

          <S.ContainerClose onClick={handleClose}>
            <S.CloseModal>X</S.CloseModal>
          </S.ContainerClose>
        </S.ContainerHeader>

        {renderButtons}
      </S.Containter>
    </S.Wrapper>
  );
};
