import { Navigate, Route, Routes } from 'react-router-dom';
import React, { ReactElement, useContext, useMemo } from 'react';
import { routesApplication } from 'src-new/pages/app/routes/private-app/routes-application/routes-application';
import { validatePermissionsService } from 'src-new/services/validate-permissions/validate-permissions.service';
import { UserContext } from 'state/user-context';
import { Body } from 'src-new/components/body/body';
import { routesPublic } from 'src-new/pages/app/routes/public-app/routes-public/routes-public';
import { ChatButtonContextProvider } from 'src-new/contexts/chat-button/chat-button.context';
import { ChatContextProvider } from 'src-new/contexts/chat/chat.context';

export const PrivateAppRoute: React.FC = () => {
  const { user } = useContext(UserContext);

  const pageRoutes = useMemo((): Array<ReactElement> => {
    const privateRoutes: Array<ReactElement> = routesApplication
      .map(({ component, path, name, profiles }) => {
        if (validatePermissionsService(name, profiles, user))
          return <Route path={path} element={component} key={path} />;
      })
      .filter((route): route is ReactElement => route !== undefined);

    const trackingRoutes: Array<ReactElement> = routesPublic.map(({ component, path }) => (
      <Route path={path} element={component} key={path} />
    ));

    return [...privateRoutes, ...trackingRoutes];
  }, [user]);

  return (
    <ChatButtonContextProvider>
      <ChatContextProvider>
        <Body>
          <Routes>
            {pageRoutes}
            <Route path="/*" element={<Navigate to="/home" replace />} />
          </Routes>
        </Body>
      </ChatContextProvider>
    </ChatButtonContextProvider>
  );
};
