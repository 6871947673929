/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import * as S from './styles';
import InvoiceSchedulesModal from './components/invoice-schedules';
import GenerateInvoice from './components/generate-invoice';

import { IBranch, IHourUpdate, IInvoice } from 'pages/fiscal-document/models';
import { useGetInvoiceHook } from 'pages/fiscal-document/hooks';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';
import Loading from 'components-v2/common/loading/index';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Button from 'components/button/button';

interface Props {
  isOpen: boolean;
  selectedId: {
    id: number;
    branch?: IBranch;
  };
  onClose: () => void;
  onSuccess: () => void;
  handleCreatePdf: (connectedInvoices: ConnectedInvoices[], invoiceId: number) => void;
}

const initialHoursUpdateValues = {} as IHourUpdate;

export const ModalInvoicingUpdate: FC<Props> = ({ isOpen, selectedId, onClose, onSuccess, handleCreatePdf }) => {
  const [invoice, setInvoice] = useState<IInvoice>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hoursUpdate, setHoursUpdate] = useState<IHourUpdate>(initialHoursUpdateValues);
  const [transactionIds, setTransactionIds] = useState<number[]>([]);
  const [generateInvoiceOpen, setGenerateInvoiceOpen] = useState<boolean>(false);

  const getInvoiceHook = useGetInvoiceHook();

  useEffect(() => {
    handleGetInvoice();
  }, [isOpen]);

  const handleGetInvoice = useCallback(
    async (id?: number) => {
      try {
        setHasLoading(true);

        const response = await getInvoiceHook(id ?? selectedId.id);

        if (response) {
          setInvoice(response);

          const transactionIds = response.connectedInvoices.map((transaction) => {
            return transaction.calcFinance.id;
          });

          setTransactionIds(transactionIds);
          setHoursUpdate(initialHoursUpdateValues);
        }
      } catch (error: any) {
        showToast({
          type: 'error',
          message: 'Houve um erro ao carregar as faturas',
        });
      } finally {
        setHasLoading(false);
        if (id) {
          onSuccess();
        }
      }
    },
    [selectedId],
  );

  const selectTransaction = (transactionId: number) => {
    const isSelected = transactionIds.indexOf(transactionId);

    if (isSelected === -1) {
      setTransactionIds((prev) => [...prev, transactionId]);
      return;
    }

    const selectedCopy = [...transactionIds];

    selectedCopy.splice(isSelected, 1);

    setTransactionIds(selectedCopy);
  };

  const handleHoursUpdate = (values: IHourUpdate) => {
    setHoursUpdate(values);
  };

  const renderProperContent = useMemo(() => {
    if (hasLoading)
      return (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      );

    if (invoice)
      return (
        <InvoiceSchedulesModal
          invoice={invoice}
          branch={selectedId.branch}
          hoursUpdate={hoursUpdate}
          onClose={onClose}
          onSuccess={handleGetInvoice}
          transactionIds={transactionIds}
          selectTransaction={selectTransaction}
          setTransactionIds={setTransactionIds}
          handleHoursUpdate={handleHoursUpdate}
          handleCreatePdf={handleCreatePdf}
        />
      );

    if (!invoice && !hasLoading) return <div>Não foi possível encontrar a fatura.</div>;

    return null;
  }, [hasLoading, transactionIds, invoice, hoursUpdate, onSuccess]);

  return (
    <>
      <ModalConfirmation
        description={renderProperContent}
        type="normal"
        size="large"
        title={`Fatura - N° ${invoice?.id ?? ''}`}
        legacy
        FooterChildren={
          invoice && (
            <Button
              title="Reemitir Fatura"
              bgColor="orange"
              size="medium"
              callback={() => setGenerateInvoiceOpen(true)}
            />
          )
        }
      />

      {generateInvoiceOpen && invoice && (
        <GenerateInvoice
          invoice={invoice}
          transactionIds={transactionIds}
          hoursUpdate={hoursUpdate}
          onClose={() => setGenerateInvoiceOpen(false)}
          onRefresh={handleGetInvoice}
        />
      )}
    </>
  );
};
