import { IMatchesContext } from './types/matches.types';
import { matchesListFiltersInitialValues } from '../../types/matches-list-filters/matches-list-filters-initial-values.types';
import { modalBiddingOfferInitialValues } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer-initial-values.constants';
import { backhaulPurchaseInitialValues } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.constants';

export const matchesInitialState: IMatchesContext = {
  matchesKanbanList: {
    modalsProps: {
      modalOptions: { open: false, ids: [], modalType: '' },
      setModalOpen: () => undefined,
    },
    statusRequest: 'INITIAL',
    matchesListFiltersProps: {
      filters: matchesListFiltersInitialValues,
      setFilters: () => undefined,
    },
  },
  modalMatchesKanban: {
    modalMatchesOptions: {
      open: false,
      saleOfferMatch: modalBiddingOfferInitialValues,
      purchaseOfferMatch: backhaulPurchaseInitialValues,
      modalType: '',
    },
    setModalMatchesOpen: () => undefined,
  },
  favoriteMatchesKanbanList: {
    favoriteMatchesKanbanList: {
      items: [],
      totalItems: 0,
    },
    setFavoriteMatchesKanbanListRequest: () => undefined,
    setFavoriteMatchesKanbanListSuccess: () => undefined,
    setFavoriteMatchesKanbanListError: () => undefined,
    setFavoriteMatchesKanbanListReset: () => undefined,
    statusRequest: 'INITIAL',
  },
  inNegotiationMatchesKanbanList: {
    inNegotiationMatchesKanbanList: {
      items: [],
      totalItems: 0,
    },
    setInNegotiationMatchesKanbanListRequest: () => undefined,
    setInNegotiationMatchesKanbanListSuccess: () => undefined,
    setInNegotiationMatchesKanbanListError: () => undefined,
    setInNegotiationMatchesKanbanListReset: () => undefined,
    statusRequest: 'INITIAL',
  },
  operatedMatchesKanbanList: {
    operatedKanbanList: {
      items: [],
      totalItems: 0,
    },
    setOperatedMatchesKanbanListRequest: () => undefined,
    setOperatedMatchesKanbanListSuccess: () => undefined,
    setOperatedMatchesKanbanListError: () => undefined,
    setOperatedMatchesKanbanListReset: () => undefined,
    statusRequest: 'INITIAL',
  },
  stabilizedMatchesKanbanList: {
    stabilizedKanbanList: {
      items: [],
      totalItems: 0,
    },
    setStabilizedMatchesKanbanListRequest: () => undefined,
    setStabilizedMatchesKanbanListSuccess: () => undefined,
    setStabilizedMatchesKanbanListError: () => undefined,
    setStabilizedMatchesKanbanListReset: () => undefined,
    statusRequest: 'INITIAL',
  },
  inactiveMatchesKanbanList: {
    inactiveKanbanList: {
      items: [],
      totalItems: 0,
    },
    setInactiveMatchesKanbanListRequest: () => undefined,
    setInactiveMatchesKanbanListSuccess: () => undefined,
    setInactiveMatchesKanbanListError: () => undefined,
    setInactiveMatchesKanbanListReset: () => undefined,
    statusRequest: 'INITIAL',
  },

  setMatchesResetContext: () => undefined,
};
