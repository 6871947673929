import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { WeighingPageBody } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-page-body/weighing-page-body.component';
import { WeighingContextProvider } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const WeighingListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <InfiniteScrollListTableContextProvider>
        <WeighingContextProvider>
          <WeighingPageBody />
        </WeighingContextProvider>
      </InfiniteScrollListTableContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
