import { FC, ReactNode } from 'react';
import { Button } from 'logshare-ui-kit';

interface FooterPageButtonProps {
  label: string;
  color: string;
  size?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isLoading?: boolean;
  variant?: 'solid' | 'ghost';
  disabled?: boolean;
  onPress: () => void;
}

export const FooterPageButton: FC<FooterPageButtonProps> = ({
  label,
  color,
  size = 'md',
  leftIcon,
  rightIcon,
  isLoading,
  variant = 'solid',
  disabled,
  onPress,
}) => {
  return (
    <Button
      label={label}
      color={color}
      onPress={onPress}
      LeftIcon={leftIcon}
      RightIcon={rightIcon}
      isLoading={isLoading}
      variant={variant}
      size={size}
      disabled={disabled}
    />
  );
};
