/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import {
  useHandleGetFileUploadedListHook,
  useHandleUploadFileFileHook,
  useHandleDeleteFileUploadedListHook,
} from '../../../hooks/index';

import { FileListContent } from './vehicle-file-upload.styled';

import { FileListUploaded } from 'pages/freight/types';
import Loading from 'components-v2/common/loading/index';
import UploadFile from 'components/upload-file/upload-file';
import { showToast } from 'components/toast/toast';
import SectionDivider from 'components/section-divider/section-divider';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';

interface IProps {
  handleFile?: (value?: any, id?: number) => void;
}

const VehicleFileSectionView: React.FC<IProps> = ({ handleFile }) => {
  const { id } = useParams();

  const handleUploadFileHook = useHandleUploadFileFileHook();
  const handleUploadedListHook = useHandleGetFileUploadedListHook();
  const handleDeleteFileUploadedList = useHandleDeleteFileUploadedListHook();

  const [uploadedList, setUploadedList] = useState<FileListUploaded[]>();
  const [register, setRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateStatusCteData = useCallback(async () => {
    const responseUploadedList = await Promise.resolve(await handleUploadedListHook(id || ''));
    setUploadedList(responseUploadedList);
    setIsLoading(false);
  }, [id]);

  const handleUpdateFile = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);

    await handleUpdateStatusCteData();
  }, [id, handleUpdateStatusCteData]);

  const handleChangeFile = async (value: any) => {
    if (id) {
      setRegister(false);
      await handleUploadFileHook(value, id);
      await handleUpdateFile();
    } else {
      setRegister(true);
      const listUpload: FileListUploaded = {
        id: Date.now(),
        name: `${value[0].name}`,
        url: `${value[0].name}`,
      };
      setUploadedList([...(uploadedList ?? []), listUpload]);
      {
        handleFile && handleFile(value, undefined);
      }
    }
  };

  const handleDeleteFile = useCallback(
    async (fileId: number, index: number) => {
      try {
        id && (await handleDeleteFileUploadedList(fileId));
        setUploadedList(uploadedList?.filter((uploadedList) => uploadedList.id !== fileId));
        {
          handleFile && handleFile(undefined, index);
        }
      } catch (error) {
        showToast({
          message: 'Erro ao deletar arquivo',
          type: 'error',
        });
      }
    },
    [uploadedList, handleFile, handleDeleteFileUploadedList],
  );

  useEffect(() => {
    handleUpdateFile();
  }, [handleUpdateFile]);

  const renderFilesList = useMemo(() => {
    if (isLoading)
      return (
        <div style={{ width: 40, margin: '0 auto' }}>
          <Loading />
        </div>
      );
    const list: any[] = [];

    if (uploadedList && uploadedList.length > 0) {
      uploadedList?.forEach((file, index) => {
        list.push(
          <UploadFile
            key={file.id}
            index={index + 1}
            file={file}
            register={register}
            handleDeleteFile={() => handleDeleteFile(file.id, index)}
          />,
        );
      });
    }

    if (list.length === 0) return <div style={{ fontSize: 12, fontWeight: 'bold' }}>Nenhum Arquivo Anexado</div>;

    return list;
  }, [uploadedList, isLoading]);

  return (
    <>
      <SectionDivider
        label="Anexos"
        secondItem={
          <InputFile onChange={handleChangeFile}>
            <Button bgColor="blue" title="Adicionar" size="very-small" icon={<AddIcon fontSize="small" />} />
          </InputFile>
        }
      />
      <FileListContent>{renderFilesList}</FileListContent>
    </>
  );
};

export default React.memo(VehicleFileSectionView);
