import React, { useMemo } from 'react';

import TableFreightTable from '../table-freight-list-table/table-freight-list-table';

import { ContainerSearchStyled } from './table-freight-list-body.styled';

import { convertVehicleCategoryTypeEntityToLabel } from 'pages/freight/mappers/convert-vehicle-category-type-entity-to-label/convert-vehicle-category-type-entity-to-label.mapper';
import { ITableFreight } from 'domain/table-freight';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Button from 'components/button/button';
import ExelUploadIcon from 'assets/icons/excel-upload.ivon';

interface ITableFreightProps {
  tableFreight: Array<ITableFreight>;
  hasLoading: boolean;
  tableFreightPageInfo?: PageInfo;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  setCurrentPage: (page: number) => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOpenUploadXlsx: (value: string) => void;
  handleDeleteList: (ids: number[]) => void;
  idsToRemove: number[];
}

const TableFreightListBodyView: React.FC<ITableFreightProps> = ({
  tableFreight,
  hasLoading,
  tableFreightPageInfo,
  setSearchInput,
  setActiveTabName,
  setCurrentPage,
  onHandleExport,
  options,
  handleOpenUploadXlsx,
  handleDeleteList,
  idsToRemove,
}: ITableFreightProps): JSX.Element => {
  const contentTable = useMemo((): Array<IContentTable> => {
    return tableFreight.map((tableFreight) => {
      const currencyFormat = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      const porcentFormat = Intl.NumberFormat('pt-BR', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return [
        {
          value: tableFreight.vehicleType,
        },
        {
          value: convertVehicleCategoryTypeEntityToLabel(tableFreight.vehicleCategory),
        },
        {
          value: currencyFormat.format(Number(tableFreight.value)),
        },
        {
          value: tableFreight.initialDistance + ' Km',
        },
        {
          value: tableFreight.finalDistance + ' Km',
        },
        {
          value: tableFreight.maxCargo + ' Kg',
        },
        {
          value: currencyFormat.format(tableFreight.helperPrice),
        },
        {
          value: porcentFormat.format(tableFreight.percentGris),
        },
        {
          value: porcentFormat.format(tableFreight.percentAdvalorem),
        },
        {
          value: tableFreight.leadTime,
          flex: 0.8,
        },
      ];
    });
  }, [tableFreight]);

  const renderTableFreightTable = useMemo(() => {
    return (
      <TableFreightTable
        contentTable={contentTable}
        meta={tableFreightPageInfo}
        changePage={setCurrentPage}
        isLoading={hasLoading}
      />
    );
  }, [contentTable, hasLoading, setCurrentPage, tableFreightPageInfo]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Todos',
        content: renderTableFreightTable,
      },
    ];
  }, [renderTableFreightTable]);

  return (
    <>
      <HeaderPage breadcrumbList={['Configurações', 'Tabela de Fretes']} />
      <Title title="Tabela de Fretes" hasTrace />
      <ContainerSearchStyled>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Nome ou Cidade"
          type="text"
          changeValue={setSearchInput}
        />
        <DropDown title="Exportar">
          <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
        </DropDown>
        <Button
          callback={() => handleOpenUploadXlsx('teste')}
          title="Importar"
          bgColor="blue"
          icon={<ExelUploadIcon />}
          size="large"
        />
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Limpar Base Geral" color="pink" onPress={() => handleDeleteList(idsToRemove)} />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </>
  );
};

export default TableFreightListBodyView;
