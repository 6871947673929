import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';

import { FormTrackingOccurrenceUpdate } from './components/form-tracking-occurrence';

import {
  occurrenceListTypesService,
  getAllOccurrenceTypesService,
} from 'pages/occurrence/services/occurrence-list-types/occurrence-list-types.service';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { ITrackingOccurrenceCreated, ITrackingOccurrenceStatus } from 'domain/tracking-occurrence';
import { showToast } from 'components/toast/toast';
import { SelectPropsOptions } from 'components/select/select';
import { UserContext } from 'state/user-context';

interface TrackingRegisterFormViewProps {
  trackingOccurrenceRegisterValues: ITrackingOccurrenceCreated;
  optionsStatusType: Array<{ label: string; value: ITrackingOccurrenceStatus }>;
  occurrenceType: {
    sla: string;
    impact: 'low' | 'medium' | 'high';
    description: string;
  };
  handleGoBack: () => void;
  handleFinishTrackingOccurrence: () => void;
  handleRegister: (values: ITrackingOccurrenceCreated) => void;
  schemaValidation: object;
  openModalNegotiations?: () => void;
  currentAllocation: ICurrentAllocation;
  goToDescriptionView: (item: any) => void;
  photosFiles: File[];
  setPhotosFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handleRemoveOccurrence: () => void;
  getAllocation: () => void;
  idToRemove: number;
}

const TrackingUpdateFormView: React.FC<TrackingRegisterFormViewProps> = ({
  trackingOccurrenceRegisterValues,
  occurrenceType,
  handleGoBack,
  handleFinishTrackingOccurrence,
  handleRegister,
  schemaValidation,
  currentAllocation,
  goToDescriptionView,
  handleRemoveOccurrence,
  getAllocation,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAdmin } = useContext(UserContext);
  const [occurrencesTypes, setOccurrencesTypes] = useState<SelectPropsOptions[]>([]);

  const occurrenceTypesApiCall = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = isAdmin ? await getAllOccurrenceTypesService() : await occurrenceListTypesService();

      setOccurrencesTypes(
        response.map((item) => ({
          label: item.description.toUpperCase(),
          value: item.description.toUpperCase(),
        })),
      );
    } catch {
      showToast({
        type: 'error',
        message: 'Erro ao buscar tipos de ocorrências',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    occurrenceTypesApiCall();
  }, [occurrenceTypesApiCall]);

  return (
    <Formik
      initialValues={trackingOccurrenceRegisterValues}
      onSubmit={handleRegister}
      validationSchema={schemaValidation}
      enableReinitialize
    >
      <FormTrackingOccurrenceUpdate
        currentAllocation={currentAllocation}
        occurrenceType={occurrenceType}
        occurrencesTypes={occurrencesTypes}
        trackingOccurrenceRegisterValues={trackingOccurrenceRegisterValues}
        isLoading={isLoading}
        isAdmin={isAdmin}
        handleGoBack={handleGoBack}
        handleFinishTrackingOccurrence={handleFinishTrackingOccurrence}
        goToDescriptionView={goToDescriptionView}
        handleRemoveOccurrence={handleRemoveOccurrence}
        getAllocation={getAllocation}
      />
    </Formik>
  );
};

export default TrackingUpdateFormView;
