import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import * as S from './schedule-refused-table-view.styled';
import { ICell, IOrdering, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import { Loading } from 'src-new/components/loading/loading.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { IScheduleRefusedListDomain } from '../../../../domains/schedule-refused-list/schedule-refused-list.domain';
import { convertOperationType } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertFreightType } from 'src-new/utils/convert-freight-type/convert-freight-type.util';
import TooltipComponent from 'components/Tooltip';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IScheduleRefusedLisViewProps {
  schedulesRefused: Array<IScheduleRefusedListDomain>;
  orderingColumns: IOrdering;
  isLoading: boolean;
  handleLineClick: (scheduleRefusedId: number, scheduleId: number) => void;
  handleNavigateNewFreight: (scheduleId: number) => void;
}

export const ScheduleRefusedTableView: React.FC<IScheduleRefusedLisViewProps> = ({
  schedulesRefused,
  orderingColumns,
  isLoading,
  handleLineClick,
  handleNavigateNewFreight,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: 'Categoria' },
      { value: 'Data Coleta' },
      { value: 'Transportadora' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Veículo' },
      { value: 'Data Recusa' },
      { value: 'Motivo da Recusa' },
      { value: '' },
      { value: '' },
    ];

    const columnsConfig = '0.15fr 0.45fr 0.6fr 0.6fr 1.3fr 1.5fr 1.5fr 0.6fr 0.7fr 0.8fr 0.1fr 0.3fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderCategory = useCallback((key: string, typeSchedule: string, operationType: string) => {
    return (
      <S.Category key={`category-${key}-${typeSchedule ?? operationType}`}>
        <Tag
          label={convertFreightType(typeSchedule).label}
          color={convertFreightType(typeSchedule).color}
          variant="solid"
          display="flex"
          size="md"
        />
        <Tag
          label={convertOperationType(operationType).label}
          color={convertOperationType(operationType).color}
          variant="ghost"
          display="flex"
          size="md"
        />
      </S.Category>
    );
  }, []);

  const renderRefusedTag = useCallback((key: string, refusedReason: string) => {
    return (
      <TooltipComponent
        key={`category-${key}-${refusedReason}`}
        title={refusedReason}
        location="bottom"
        icon={<Tag label={refusedReason} color={ThemeColorsV2Type.Red} variant="solid" display="flex" size="md" />}
      />
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return schedulesRefused.map((schedule) => {
      return {
        key: schedule.id,
        onClick: () => handleLineClick(schedule.id, schedule.scheduleId),
        valuesColumns: [
          {
            value: <Checkbox key={`checkbox-${schedule.scheduleId}`} id="id" name="" onChange={() => null} />,
            isClicable: false,
          },
          { value: renderTitleSubtitle(schedule.scheduleId.toString(), '') },
          { value: renderCategory(schedule.id.toString(), schedule.scheduleType, schedule.operationType) },
          { value: renderTitleSubtitle(schedule.collectionDate, schedule.collectionTime) },
          { value: renderTitleSubtitle(schedule.shippingCompany, '') },
          { value: renderTitleSubtitle(`${schedule.origin.uf} - ${schedule.origin.name}`, schedule.origin.city) },
          { value: renderTitleSubtitle(`${schedule.destiny.uf} - ${schedule.destiny.name}`, schedule.destiny.city) },
          { value: renderTitleSubtitle(schedule.vehicle.type, schedule.vehicle.category) },
          { value: renderTitleSubtitle(schedule.refusalDate, schedule.refusalTime) },
          { value: renderRefusedTag(schedule.id.toString(), schedule.refusalReason) },
          { value: '' },
          {
            value: (
              <TooltipComponent
                title="Ofertar para Bidding"
                location="right-end"
                icon={
                  <InsertInvitationIcon
                    sx={{ width: 18.5, height: 18.5 }}
                    htmlColor="grey"
                    onClick={() => handleNavigateNewFreight(schedule.scheduleId)}
                  />
                }
              />
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [
    handleLineClick,
    handleNavigateNewFreight,
    renderCategory,
    renderRefusedTag,
    renderTitleSubtitle,
    schedulesRefused,
  ]);

  const renderTable = useMemo((): ReactElement => {
    if (isLoading)
      return (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      );

    if (!schedulesRefused.length)
      return (
        <S.EmptyListWrapper>
          <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
        </S.EmptyListWrapper>
      );

    return (
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        ordering={orderingColumns}
        rows={renderRows}
        height={'67vh'}
      />
    );
  }, [isLoading, orderingColumns, renderRows, schedulesRefused.length, tableColumns]);

  return <S.Wrapper>{renderTable}</S.Wrapper>;
};
