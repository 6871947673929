import { FC } from 'react';
import * as S from './purchase-offer-modal-match-score-view.styled';

interface IPurchaseOfferModalMatchScoreViewProps {
  label: string;
  color: 'green' | 'proYellow' | 'darkGray';
}

export const PurchaseOfferModalMatchScoreView: FC<IPurchaseOfferModalMatchScoreViewProps> = ({ label, color }) => {
  return <S.Wrapper color={color}>{label}</S.Wrapper>;
};
