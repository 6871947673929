import React, { createContext, useMemo, useReducer } from 'react';
import { useFreightChatContextValues } from 'pages/freight/contexts/freight/context-values/freight-chat/freight-chat-values.context';
import { freightInitialState } from './freight.constants';
import { freightReducer } from './reducers/freight.reducer';
import { IFreightChat } from './types/freight-chat.types';
import { IFreightContextProps } from './freight.types';
import { IFreightContext } from './types/freight.types';

export const FreightContext = createContext<IFreightContext>(freightInitialState);

export const FreightContextProvider = ({ children }: IFreightContextProps) => {
  const [state] = useReducer(freightReducer, freightInitialState);

  const freightChat: IFreightChat = useFreightChatContextValues();

  const value = useMemo(
    (): IFreightContext => ({
      ...state,
      freightChat,
    }),
    [state, freightChat],
  );

  return <FreightContext.Provider value={value}>{children}</FreightContext.Provider>;
};
