import React from 'react';

import { FormPallet } from '../components/form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

export const PalletRegisterPage: React.FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Execução', 'Vale Equipamentos']} hasBackButton />
      <Title title="Novo Palete" hasTrace isForm />
      <FormPallet />
    </>
  );
};
