/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { convertResumeCreate } from '../../mappers/convert-resume/register';
import { Card } from '../../components/freight-cards';
import { ScheduleCardResume } from '../../components/card-resume';
import { ISelectOperation } from './types/select-operation';
import { IDisableCards, IScheduleFormProps } from './types/form';
import { initialSelectedBackhaulValues, ISelectedBackhaul } from './types/backhaul';
import * as S from './styled';
import { RenderForms } from './render';
import { scheduleInitialValues } from './initial-values';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { createScheduleCustomField } from 'pages-v2/schedule/services/custom-fields';
import { createScheduleService } from 'pages-v2/schedule/services/create-schedule';
import { useValidationFields } from 'pages-v2/schedule/context/validation-fields';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { Operation } from 'domain-v2/inputs/operation';
import { FooterPage } from 'components-v2/common/footer-page';
import Accordion from 'components-v2/common/accordion';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import TruckLoadingIcon from 'assets/loading-v4-unscreen.gif';
import { useQueryClient } from 'react-query';
import { ScheduleOfferModalMatch } from 'pages-v2/schedule/components/modals/modal-match/modal-match.component';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { createScheduleBackhaulService } from 'pages-v2/schedule/services/buy-backhaul';
import { UserContext } from 'state/user-context';

const initialSelectOperationState = {
  operation: 'TRACKING',
  operationBy: '',
} as ISelectOperation;

export const ScheduleRegisterForm: React.FC<IScheduleFormProps> = ({ company, schedulingKeys, schedule }) => {
  const reactQueryClient = useQueryClient();

  const { isHovered, currentSelectedMenu } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const { isFilled, handleValidateField } = useValidationFields();

  const [selectedOperation, setSelectedOperation] = useState<ISelectOperation>(
    schedule ? { ...initialSelectOperationState, operation: 'SPOT' } : initialSelectOperationState,
  );

  const [selectedBackhaul, setSelectedBackhaul] = useState<any>(initialSelectedBackhaulValues);

  const [sidebarSize, setSidebarSize] = useState<number>(0);

  const [modalBackhaulOpen, setModalBackhaulOpen] = useState<boolean>(false);

  const [loadingFreightValues, setLoadingFreightValues] = useState<boolean>(false);

  const [disableCardSpot, setDisableCardSpot] = useState<IDisableCards>({
    disable: false,
    message: 'Sem Tarifa',
  });

  const [disableCardBackhaul, setDisableCardBackhaul] = useState<IDisableCards>({
    disable: false,
    message: 'Sem Tarifa',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const menuSize = isHovered ? 282 : 0;
    const submenuSize = currentSelectedMenu ? 185 : 0;
    setSidebarSize(menuSize + submenuSize);
  }, [isHovered, currentSelectedMenu]);

  const handleFormSubmit = async (values: ScheduleRegisterInput) => {
    handleValidateField();
    if (
      !(
        isFilled.originValues &&
        isFilled.destinyValues &&
        isFilled.cargoValues &&
        isFilled.detailValues &&
        (isFilled.customFieldsValues ?? true)
      )
    ) {
      showMessageFormatted({
        message: 'Preencha todos os campos obrigatórios.',
        type: 'error',
      });
      if (!isFilled.originValues) {
        showMessageFormatted({
          message: '*Origem',
          type: 'error',
        });
      }
      if (!isFilled.destinyValues) {
        showMessageFormatted({
          message: '*Destino',
          type: 'error',
        });
      }
      if (!isFilled.cargoValues) {
        showMessageFormatted({
          message: '*Detalhes da Carga',
          type: 'error',
        });
      }
      if (!isFilled.detailValues) {
        showMessageFormatted({
          message: '*Detalhes do Agendamento',
          type: 'error',
        });
      }
      return;
    }

    try {
      setLoadingFreightValues(true);
      if (selectedOperation.operation.includes('BACKHAUL EXTERNO')) {
        showMessageFormatted({
          type: 'info',
          message: 'Realizando compra, aguarde...',
        });
        await createScheduleBackhaulService({
          matchOfferId: null,
          saleOfferId: selectedBackhaul.saleOfferId,
          saleOfferTariffId: selectedBackhaul.saleOfferShipperTariffId,
          companySeller: selectedBackhaul.saleOfferDetails.company_id,
          origin: {
            id: values.origin.id,
            type: values.origin.type,
          },
          destination: {
            id: values.destination.id,
            type: values.destination.type,
          },
          cargo: {
            vehicleType: values.cargo.vehicleType,
            vehicleCategoryType: values.cargo.vehicleCategoryType,
            shippingReturn: values.cargo.shippingReturn,
            product: values.cargo.product,
            weight: values.cargo.weight,
            cubagem: values.cargo.cubagem,
            volume: values.cargo.volume,
            merchValue: values.cargo.merchValue,
            inPallet: values.cargo.inPallet,
            pallet: values.cargo.pallet,
            order: values.cargo.order,
            comments: values.cargo.comments,
            requirements: {
              quality: values.cargo.requirements.quality?.values,
              security: values.cargo.requirements.security?.values,
              custom: values.cargo.requirements.custom?.values,
            },
          },
          dates: [
            {
              initialLevyDate: values.detail.levyInitialDate,
              finalLevyDate: values.detail.levyEndDate,
              initialDeliveryDate: values.detail.deliveryInitialDate,
              finalDeliveryDate: values.detail.deliveryEndDate,
            },
          ],
          cost: {
            freightValue: selectedBackhaul.saleOfferDetails.value_total,
            tollValue: 0,
            grisAdvalorem: 0,
            freightTotal: selectedBackhaul.saleOfferDetails.value_total,
            icms: 0,
            totalService: 0,
            otherTaxes: 0,
          },
          invoicedBy: 'LOGSHARE',
          haulType: `BACKHAUL ${selectedBackhaul.backhaulType}`,
          retentionFines: {
            freeTime: values.retentionFines.freeTime,
            hourlyFine: values.retentionFines.hourlyFine,
            dailyFine: values.retentionFines.dailyFine,
          },
        });
      } else {
        const scheduleId = await createScheduleService(values);
        if (schedulingKeys && schedulingKeys.length > 0) {
          await createScheduleCustomField(values.customFields, scheduleId.data.id);
        }
      }

      showMessageFormatted({
        message: TOAST_MESSAGE.REGISTER,
        type: 'success',
      });

      reactQueryClient.invalidateQueries(['schedule-list-table']);

      navigate(-1);
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    } finally {
      setLoadingFreightValues(false);
    }
  };

  const handleChangeOperation = useCallback(
    (
      operation: Operation,
      operationBy: string,
      cost: {
        value: number;
        helperPrice: number;
        grisAdvaloremPrice: number;
        taxPrice: number;
        tollPrice: number;
        totalFreight: number;
        icms: number;
        totalService: number;
      },
      setFieldValue: (field: string, value: any) => Promise<any>,
    ) => {
      setFieldValue('operation', operation);
      setSelectedOperation({
        operation,
        operationBy,
        cost,
      });
    },
    [setSelectedOperation],
  );

  const handleMatchBuy = useCallback((backhaul: ISelectedBackhaul) => {
    setSelectedBackhaul(backhaul);
    setModalBackhaulOpen(false);
  }, []);

  useEffect(() => {
    setSelectedOperation({
      operation: selectedOperation.operation,
      operationBy: selectedOperation.operationBy,
      cost: {
        value: selectedBackhaul.saleOfferDetails?.value_total ?? 0,
        helperPrice: 0,
        grisAdvaloremPrice: 0,
        taxPrice: 0,
        tollPrice: 0,
        totalFreight: selectedBackhaul.saleOfferDetails?.value_total ?? 0,
        icms: 0,
        totalService: selectedBackhaul.saleOfferDetails?.value_total ?? 0,
      },
    });
  }, [selectedBackhaul, selectedOperation.operation, selectedOperation.operationBy, setSelectedBackhaul]);

  useEffect(() => {
    if (selectedOperation.operation === 'TRACKING') {
      setSelectedOperation((prevState) => ({
        ...prevState,
        operationBy: company.tradeName,
      }));
    }
  }, [company, selectedOperation.operation]);

  const tarifSaleOfferMsg = useCallback((valueFreight?: number): string => {
    if (valueFreight) {
      return `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valueFreight)}`;
    }

    return 'Apenas Monitorar';
  }, []);

  const tarifSpotMsg = (valueFreight: number, deliveryDate: boolean) => {
    if (valueFreight && deliveryDate && disableCardSpot.message !== 'Sem Tarifa') {
      return `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valueFreight)}`;
    }
    return 'Sem Tarifa';
  };

  const tarifBackhaulMsg = (valueFreight: number, bestPrice: number, deliveryDate: boolean) => {
    if (deliveryDate && (valueFreight || bestPrice) && disableCardBackhaul.message !== 'Sem Tarifa') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valueFreight || bestPrice);
    }
    return 'Sem Tarifa';
  };

  const handleDisabledCards = useCallback(
    (disable: boolean, type: 'SPOT' | 'BACKHAUL') => {
      const setCardState = (setDisableCard: React.Dispatch<React.SetStateAction<IDisableCards>>) => {
        if (disable) {
          setDisableCard({ disable, message: 'Sem Tarifa' });

          setSelectedOperation({
            operation: 'TRACKING',
            operationBy: company.tradeName,
            cost: {
              value: 0,
              helperPrice: 0,
              grisAdvaloremPrice: 0,
              taxPrice: 0,
              tollPrice: 0,
              totalFreight: 0,
              icms: 0,
              totalService: 0,
            },
          });
        } else {
          setDisableCard({ disable });
        }
      };

      if (type === 'SPOT') {
        setCardState(setDisableCardSpot);
      } else if (type === 'BACKHAUL') {
        setCardState(setDisableCardBackhaul);
      }
    },
    [company.tradeName],
  );

  return (
    <Formik
      initialValues={schedule ?? scheduleInitialValues}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <>
            {modalBackhaulOpen && (
              <ScheduleOfferModalMatch
                scheduleValues={values}
                saleOffersMatches={values.backhaul.saleOfferMatches}
                setModalBackhaulOpen={setModalBackhaulOpen}
                handleMatchBuy={handleMatchBuy}
              />
            )}
            <S.WrapperForm>
              <Accordion
                contents={RenderForms({
                  company,
                  schedulingKeys,
                  isFilled,
                  schedule: !!schedule,
                  setLoadingFreightValues,
                  handleDisabledCards,
                })}
              />
              <ScheduleCardResume.Root>
                <ScheduleCardResume.Header
                  scheduleType="ftl"
                  operationType={values.operation}
                  requirementTags={values.cargo.requirements}
                  co2={selectedBackhaul.saleOfferDetails?.purchaseOfferC02}
                  user={user}
                  values={values}
                  hideQuotation={
                    isFilled.originValues && isFilled.destinyValues && isFilled.cargoValues && isFilled.detailValues
                  }
                />
                <ScheduleCardResume.Collection
                  origin={convertResumeCreate(values).originValues}
                  scheduleDetailsValues={convertResumeCreate(values).scheduleDetailsValuesOrigin}
                />
                <ScheduleCardResume.Delivery
                  destinyValues={convertResumeCreate(values).destinationValues}
                  scheduleDetailsValues={convertResumeCreate(values).scheduleDetailsValuesDestination}
                />
                <ScheduleCardResume.Operation
                  cargoDetailsValues={convertResumeCreate(values).scheduleCargoValues}
                  operationBy={selectedOperation.operationBy}
                  distance={values.distance}
                  leadTime={values.leadTime}
                />
                <ScheduleCardResume.Fines retentionFinesValues={values.retentionFines} />
                <ScheduleCardResume.ValuesValidation
                  user={user}
                  valuesPayable={convertResumeCreate(values, selectedOperation.cost).scheduleCostValues}
                />
              </ScheduleCardResume.Root>
            </S.WrapperForm>

            <S.WrapperFooterPage sidebarIsHovered={sidebarSize}>
              {loadingFreightValues && (
                <S.ContainerLoading>
                  <img alt="" style={{ width: 70, height: 70 }} src={TruckLoadingIcon} />
                </S.ContainerLoading>
              )}
              <FooterPage.Root>
                <FooterPage.RightContent>
                  <FooterPage.Button
                    label="Agendar"
                    color="pink"
                    onPress={handleSubmit}
                    isLoading={loadingFreightValues}
                  />
                  <FooterPage.Button label="Voltar" color="white" variant="ghost" onPress={() => navigate(-1)} />
                </FooterPage.RightContent>
                <FooterPage.LeftContent>
                  <Card
                    cards={[
                      {
                        id: 1,
                        name: company.tradeName,
                        tariffValue: tarifSaleOfferMsg(values.cost.saleOfferValue?.valueTotal),
                        dateOf: !values.detail.deliveryEndDate
                          ? '-'
                          : dayjs(values.detail.deliveryEndDate).format('DD/MM/YYYY'),

                        onClickAction: () => {
                          handleChangeOperation(
                            'TRACKING',
                            company.tradeName,
                            {
                              value: 0,
                              helperPrice: 0,
                              grisAdvaloremPrice: 0,
                              taxPrice: 0,
                              tollPrice: 0,
                              totalFreight: 0,
                              icms: 0,
                              totalService: 0,
                            },
                            setFieldValue,
                          );
                          setSelectedBackhaul(initialSelectedBackhaulValues);
                        },
                        checked: selectedOperation.operation === 'TRACKING',
                        tooltipInfo:
                          'Selecione essa opção para programação de fretes com seus transportadores homologados.',
                        isLoading: loadingFreightValues,
                      },
                      {
                        id: 2,
                        name: 'SPOT',
                        tag: !!values.detail.deliveryEndDate &&
                          disableCardSpot.message === 'EMERGENCIAL' &&
                          values.cost.valueFreight && <S.TagSpot>EMERGENCIAL</S.TagSpot>,
                        tariffValue: tarifSpotMsg(values.cost.valueFreight, !!values.detail.deliveryEndDate),
                        dateOf: !values.detail.deliveryEndDate
                          ? '-'
                          : dayjs(values.detail.deliveryEndDate).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation(
                            'SPOT',
                            'Logshare',
                            {
                              value: values.cost.valueFreight,
                              helperPrice: values.cost?.helperTotal,
                              grisAdvaloremPrice: values.cost?.totalGrisAdvalorem,
                              taxPrice: values.cost.taxes,
                              tollPrice: values.cost.toll,
                              totalFreight:
                                values.cost.valueFreight +
                                values.cost?.helperTotal +
                                values.cost?.totalGrisAdvalorem +
                                values.cost.toll,
                              icms: values.cost.icms,
                              totalService:
                                values.cost.valueFreight +
                                values.cost?.helperTotal +
                                values.cost.toll +
                                (values.cost.totalGrisAdvalorem || 0) +
                                values.cost.icms,
                            },
                            setFieldValue,
                          );
                          setSelectedBackhaul(initialSelectedBackhaulValues);
                        },
                        disabled: !values.cost.valueFreight || disableCardSpot.disable,
                        checked:
                          selectedOperation.operation === 'SPOT' ||
                          selectedOperation.operation === 'BACKHAUL EXTERNO SPOT',
                        tooltipInfo:
                          'Selecione essa opção para contratação de fretes com transportadores homologados no ecossistema LogShare.',
                        isLoading: loadingFreightValues,
                      },
                      {
                        id: 3,
                        name: 'BACKHAUL',
                        tariffValue: tarifBackhaulMsg(
                          selectedBackhaul.saleOfferDetails?.value_total,
                          values.backhaul.saleOfferMatches?.[0]?.saleOfferDetails?.value_total || 0,
                          !!values.detail.deliveryEndDate,
                        ),
                        dateOf: !values.detail.deliveryEndDate
                          ? '-'
                          : dayjs(values.detail.deliveryEndDate).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation(
                            'BACKHAUL EXTERNO',
                            company.tradeName,
                            {
                              value: selectedBackhaul.saleOfferDetails?.value_total ?? values.backhaul.freightValue,
                              helperPrice: 0,
                              grisAdvaloremPrice: 0,
                              taxPrice: 0,
                              tollPrice: 0,
                              totalFreight:
                                selectedBackhaul.saleOfferDetails?.value_total ?? values.backhaul.totalFreight,
                              icms: 0,
                              totalService:
                                selectedBackhaul.saleOfferDetails?.value_total ?? values.backhaul.totalService,
                            },
                            setFieldValue,
                          );
                          setModalBackhaulOpen(true);
                        },
                        disabled: disableCardBackhaul.disable || values.backhaul.saleOfferMatches.length === 0,
                        tooltipInfo:
                          'Selecione essa opção para contratação de frete retorno de outros embarcadores com no mínimo 48h de antecedência.',
                        checked: selectedOperation.operation === 'BACKHAUL EXTERNO',
                        isLoading: loadingFreightValues,
                      },
                    ]}
                  />
                </FooterPage.LeftContent>
              </FooterPage.Root>
            </S.WrapperFooterPage>
          </>
        );
      }}
    </Formik>
  );
};
