import { useFormikContext } from 'formik';
import { FC, useContext } from 'react';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';
import { UserContext } from 'state/user-context';
import { WeighingDuplicatedFormMountItemsView } from './weighing-duplicated-form-mount-items-view.component';

export const WeighingDuplicatedFormMountItems: FC = () => {
  const { values, setFieldValue } = useFormikContext<IWeighingDuplicatedDomain>();
  const { user } = useContext(UserContext);

  return <WeighingDuplicatedFormMountItemsView setFieldValue={setFieldValue} values={values} isMaster={!!user?.isMaster}/>;
};
