import React, { useCallback } from 'react';

import ItemView from './menu-item-view';

import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';

type MenuItemProps = {
  title: string;
  icon: JSX.Element;
};

const MenuItem: React.FC<MenuItemProps> = ({ title, icon }) => {
  const { textIsVisible, currentSelectedMenu, handleSelectedMenu } = React.useContext(SidebarContext);

  const handleClick = useCallback(() => {
    sessionStorage.setItem('selectedMenu', title); //Para renderizar o menu sempre que a pagina for atualizada
    handleSelectedMenu(title);
  }, [handleSelectedMenu, title]);

  return (
    <ItemView
      title={title}
      icon={icon}
      selected={currentSelectedMenu === title}
      showText={textIsVisible}
      handleClick={handleClick}
    />
  );
};

export default MenuItem;
