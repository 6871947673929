import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  HeaderChatModalView,
  IModalViewStatesProps,
} from 'src-new/components/application-page-header/components/header-page/components/header-chat-modal/header-chat-modal-view.component';
import { useGetConversationListChat } from 'src-new/hooks/chat/use-get-conversation-list-chat/use-get-conversation-list-chat.hook';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export const HeaderChatModal: React.FC = () => {
  const { chatConversationList } = useContext(ChatContext);
  const getConversationList = useGetConversationListChat();
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const states = useMemo((): IModalViewStatesProps => {
    return {
      isLoading: isLoading,
      isError: chatConversationList.statusRequest === 'ERROR',
      isEmpty: chatConversationList.statusRequest === 'SUCCESS' && !chatConversationList.conversations.length,
    };
  }, [chatConversationList.conversations.length, chatConversationList.statusRequest, isLoading]);

  const handleChatModalClose = useCallback(
    () => chatConversationList.setChatConversationListReset(),
    [chatConversationList],
  );

  const isLazyLoading = useMemo(
    () => !isLoading && chatConversationList.statusRequest === 'PROCESSING',
    [chatConversationList.statusRequest, isLoading],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPageInfinityScroll }),
    [isLazyLoading],
  );

  useEffect(() => {
    const statusRequest = chatConversationList.statusRequest;
    if (statusRequest === 'SUCCESS' || statusRequest === 'ERROR') {
      setIsLoading(false);
    }
  }, [chatConversationList.statusRequest]);

  useEffect(() => {
    if (chatConversationList.statusRequest === 'INITIAL') {
      getConversationList();
    }
  }, [chatConversationList.statusRequest, getConversationList]);

  useEffect(() => {
    if (!chatConversationList.paginationActions.isPageLimit && pageInfinityScroll > currentPage) {
      setCurrentPage(pageInfinityScroll);
      getConversationList();
    }
  }, [chatConversationList.paginationActions.isPageLimit, currentPage, getConversationList, pageInfinityScroll]);

  return (
    <HeaderChatModalView
      states={states}
      error={chatConversationList.error}
      handleCloseModal={handleChatModalClose}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
