import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { deleteOthersDocumentsDataService } from '../../services/delete-others-documents-data/delete-others-documents-data.service';
import { getOthersDocumentsDataService } from '../../services/get-others-documents-data/get-others-documents-data.service';

export const useDeleteOthersDocumentsData = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);
  const { modalsProps } = scheduleDocuments;

  return useCallback(async () => {
    try {
      scheduleDocuments.othersDocumentsProps.setOthersDocumentsRequest();
      modalsProps.setModalOpen({ ...modalsProps.modalOptions, open: false });

      await deleteOthersDocumentsDataService(
        scheduleDetails.scheduleDetails.id,
        scheduleDocuments.modalsProps.modalOptions.id,
      );

      const data = await getOthersDocumentsDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.othersDocumentsProps.setOthersDocumentsSuccess(data);
    } catch (error: any) {
      scheduleDocuments.othersDocumentsProps.setOthersDocumentsError();
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
    scheduleDocuments.modalsProps.setModalOpen({ open: false, id: 0, modalType: '' });
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.modalsProps, scheduleDocuments.othersDocumentsProps]);
};
