import styled from 'styled-components';

export const ContainerSearch = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerTotal = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const TotalTitle = styled.div`
  font-size: 11.5px;
`;

export const TotalLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
