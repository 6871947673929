import { FC } from 'react';
import { IWeighingTottenModal } from './weighing-totten-modal.types';
import * as S from './weighing-totten-modal-view.styled';

export const WeighingTottenModalView: FC<IWeighingTottenModal> = ({ children }) => {
  return (
    <S.WeighingTottenModalWrapper>
      <S.WeighingTottenModalContent>
        <S.WeighingTottenModalWrapperChildren>{children}</S.WeighingTottenModalWrapperChildren>
      </S.WeighingTottenModalContent>
    </S.WeighingTottenModalWrapper>
  );
};
