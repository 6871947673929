import { IAcceptedMatchesContext } from './accepted-matches.types';

export const initialState: IAcceptedMatchesContext = {
  acceptedMatchesIsLoading: false,
  acceptedMatches: [],
  acceptedMatchesError: undefined,
  acceptedMatchesPageInfo: undefined,
  setAcceptedMatchesRequest: () => undefined,
  setAcceptedMatchesSuccess: () => undefined,
  setAcceptedMatchesError: () => undefined,
};

export enum IAcceptedMatchesAction {
  ACCEPTED_MATCHES_REQUEST = 'ACCEPTED_MATCHES_REQUEST',
  ACCEPTED_MATCHES_SUCCESS = 'ACCEPTED_MATCHES_SUCCESS',
  ACCEPTED_MATCHES_ERROR = 'ACCEPTED_MATCHES_ERROR',
}
