import { LocationsListActionType } from '../../locations.action';
import { ILocationsContext } from '../../types/locations.types';
import { ILocationsListActions } from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations-list.types';

export const locationsListReducer = (state: ILocationsContext, action: ILocationsListActions): ILocationsContext => {
  switch (action.type) {
    case LocationsListActionType.LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          statusRequest: 'PROCESSING',
        },
      };
    case LocationsListActionType.LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          statusRequest: 'SUCCESS',
          locations: action.payload,
        },
      };
    case LocationsListActionType.LOCATIONS_LIST_ERROR:
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          statusRequest: 'ERROR',
          locations: [],
        },
      };
    case LocationsListActionType.LOCATIONS_LIST_SET_FILTERS:
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          filterActions: {
            ...state.locationsList.filterActions,
            filters: action.payload,
          },
        },
      };
    case LocationsListActionType.LOCATIONS_LIST_RESET:
      return {
        ...state,
        locationsList: {
          ...state.locationsList,
          statusRequest: 'INITIAL',
          locations: [],
        },
      };
    default:
      return state;
  }
};
