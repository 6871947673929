import { IWeighingTottensDetailsResponse } from '../../../services/get-weighing-totten-details/get-weighing-totten-details.types';
import { IWeighingTottensDetailsDomain } from '../../../domain/weighing-totten-details/weighing-totten-details.domain';

export const convertWeighingDetailsResponseToDomain = (
  weighingResponse: IWeighingTottensDetailsResponse,
): IWeighingTottensDetailsDomain => {
  return {
    ...weighingResponse,
    destiny: {
      ...weighingResponse.destination,
    },
    shippingCompanyName: weighingResponse.shippingCompany.name,
  };
};
