import { FC, Fragment, ReactElement, useCallback, useMemo } from 'react';
import { IScheduleDetailsDomain, IScheduleDetailsFinanceDomain } from '../../../../domains/schedule-details.domain';
import * as S from './schedule-details-resume-finance-view.styled';
import { IFinanceObjects } from './schedule-details-resume-finance.types';

interface IScheduleDetailsResumeFinanceViewProps {
  freightPayable?: IScheduleDetailsFinanceDomain;
  freightReceivable?: IScheduleDetailsFinanceDomain;
  freightShipping?: IScheduleDetailsFinanceDomain;
  verifyFinanceObjects: IFinanceObjects;
  schedule: IScheduleDetailsDomain;
  shippingCompanyId: number;
  userCompanyId: number;
  isShippingCompany: boolean;
  convertCurrencyFormatToCurrency: (currency?: number) => string | 0;
}

export const ScheduleDetailsResumeFinanceView: FC<IScheduleDetailsResumeFinanceViewProps> = ({
  freightPayable,
  freightReceivable,
  freightShipping,
  verifyFinanceObjects,
  schedule,
  shippingCompanyId,
  userCompanyId,
  isShippingCompany,
  convertCurrencyFormatToCurrency,
}) => {
  const renderFreightPayableTitle = useMemo((): ReactElement => <S.Text isStrong>valor do frete</S.Text>, []);

  const renderFreightReceivableTitle = useMemo((): ReactElement => {
    if (
      schedule.invoicedBy === 'LOGSHARE' &&
      schedule.company.id === userCompanyId &&
      schedule.operation === 'BACKHAUL EXTERNO'
    ) {
      return (
        <Fragment>
          <S.Text isStrong>valor a receber por</S.Text>
          <S.Text isStrong>{schedule.company.tradeName}</S.Text>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <S.Text isStrong>valor a receber de</S.Text>
        <S.Text isStrong>{freightReceivable?.owner}</S.Text>
      </Fragment>
    );
  }, [
    freightReceivable?.owner,
    schedule.company.id,
    schedule.company.tradeName,
    schedule.invoicedBy,
    schedule.operation,
    userCompanyId,
  ]);

  const renderFreightShippingTitle = useMemo((): ReactElement => {
    if (isShippingCompany) {
      return <S.Text isStrong>frete a receber</S.Text>;
    }

    if (
      schedule.invoicedBy === 'EMBARCADOR' ||
      (schedule.shippingId === shippingCompanyId &&
        (schedule.operation === 'SPOT' || schedule.invoicedBy === 'LOGSHARE'))
    ) {
      return <S.Text isStrong>valor do frete</S.Text>;
    }

    if (
      schedule.invoicedBy === 'LOGSHARE' &&
      schedule.company.id === userCompanyId &&
      schedule.operation === 'BACKHAUL EXTERNO'
    ) {
      return (
        <Fragment>
          <S.Text isStrong>valor a receber por </S.Text>
          <S.Text isStrong>{schedule.shipping?.name}</S.Text>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <S.Text isStrong>valor a pagar para</S.Text>
        <S.Text isStrong>{freightShipping?.owner}</S.Text>
      </Fragment>
    );
  }, [
    freightShipping?.owner,
    isShippingCompany,
    schedule.company.id,
    schedule.invoicedBy,
    schedule.operation,
    schedule.shipping?.name,
    schedule.shippingId,
    shippingCompanyId,
    userCompanyId,
  ]);

  const renderFreightValues = useCallback(
    (title: ReactElement, values?: IScheduleDetailsFinanceDomain): ReactElement => {
      return (
        <S.WrapperTransactionValues>
          <S.WrapperTitle>{title}</S.WrapperTitle>
          <S.WrapperCurrency>
            <S.Text>frete valor</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.valueFreight)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>ajudante</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.helperTotal)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>pedágio</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.toll)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>descarga</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.discharge)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>tempo excedente</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.surplusTime)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>gris/advalorem</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.totalGrisAdvalorem)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>taxa logshare</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.totalFee)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>desconto</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.penaltyBonus)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text isStrong>frete total</S.Text>
            <S.ContainerCurrency>
              <S.TextValue isStrong>R$</S.TextValue>
              <S.TextValue isStrong>{convertCurrencyFormatToCurrency(values?.totalFreight)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>icms</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.icms)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text>outros impostos</S.Text>
            <S.ContainerCurrency>
              <S.TextValue>R$</S.TextValue>
              <S.TextValue>{convertCurrencyFormatToCurrency(values?.otherTaxes)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>

          <S.WrapperCurrency>
            <S.Text isStrong>total</S.Text>
            <S.ContainerCurrency>
              <S.TextValue isStrong>R$</S.TextValue>
              <S.TextValue isStrong>{convertCurrencyFormatToCurrency(values?.totalService)}</S.TextValue>
            </S.ContainerCurrency>
          </S.WrapperCurrency>
        </S.WrapperTransactionValues>
      );
    },
    [convertCurrencyFormatToCurrency],
  );

  const renderResumeFinanceValues = useMemo((): ReactElement => {
    if (
      schedule.invoicedBy === 'LOGSHARE' &&
      schedule.company.id === userCompanyId &&
      schedule.operation === 'BACKHAUL EXTERNO'
    ) {
      return (
        <Fragment>
          {renderFreightValues(renderFreightShippingTitle, freightShipping)}
          {renderFreightValues(renderFreightReceivableTitle, freightReceivable)}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {verifyFinanceObjects.freightPayableView && renderFreightValues(<Fragment />, freightPayable)}
        {verifyFinanceObjects.freightReceivableView &&
          renderFreightValues(renderFreightReceivableTitle, freightReceivable)}
        {verifyFinanceObjects.freightShippingView && renderFreightValues(renderFreightShippingTitle, freightShipping)}
      </Fragment>
    );
  }, [
    freightPayable,
    freightReceivable,
    freightShipping,
    renderFreightReceivableTitle,
    renderFreightShippingTitle,
    renderFreightValues,
    schedule.company.id,
    schedule.invoicedBy,
    schedule.operation,
    userCompanyId,
    verifyFinanceObjects,
  ]);

  return (
    <S.Wrapper>
      <S.WrapperFinance>
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.WrapperFinanceInfo>
          <S.Div>
            {verifyFinanceObjects.freightPayableView && renderFreightPayableTitle}
            <S.WrapperInfos isPayable={verifyFinanceObjects.freightPayableView}>
              {renderResumeFinanceValues}
            </S.WrapperInfos>
          </S.Div>
        </S.WrapperFinanceInfo>
      </S.WrapperFinance>
    </S.Wrapper>
  );
};
