import { Tag } from 'logshare-ui-kit';
import { FC, useMemo } from 'react';
import * as S from './schedule-details-resume-requeriments-view.styled';

interface IScheduleDetailsResumeRequirementsViewProps {
  requirements: Array<string>;
}

export const ScheduleDetailsResumeRequirementsView: FC<IScheduleDetailsResumeRequirementsViewProps> = ({
  requirements,
}) => {
  const renderRequirements = useMemo(() => {
    const rendering = requirements.map((requirement, index) => {
      return <Tag key={index} label={requirement} color={'blue'} variant="solid" display="flex" size="md" />;
    });

    if (rendering.length === 0) return;

    return <S.WrapperTags>{rendering}</S.WrapperTags>;
  }, [requirements]);

  return renderRequirements;
};
