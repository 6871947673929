import { includes } from 'lodash';
import { IScheduleRefusedActions, IScheduleRefusedContext } from '../types/schedule-refused.types';
import { ScheduleRefusedActionType, ScheduleRefusedListActionType } from '../schedule-refused.action';
import { scheduleRefusedListReducer } from './schedule-refused-list/schedule-refused-list.reducer';
import { IScheduleRefusedListActions } from '../types/schedule-refused-list.types';
import { scheduleRefusedReducerResetState } from './schedule-refused-reducer.constants';

export const scheduleRefusedReducer = (
  state: IScheduleRefusedContext,
  action: IScheduleRefusedActions,
): IScheduleRefusedContext => {
  if (includes(Object.values(ScheduleRefusedListActionType), action.type)) {
    return scheduleRefusedListReducer(state, action as IScheduleRefusedListActions);
  }

  if (action.type === ScheduleRefusedActionType.SCHEDULE_REFUSED_RESET) {
    return scheduleRefusedReducerResetState(state);
  }

  return state;
};
