import React, { useMemo, useState } from 'react';

import {
  ContainerStyled,
  InputStyled,
  ResponsableListStyled,
  ResponsableContainerStyled,
  DeleteResponsableStyled,
  MessageErrorStyled,
  ButtonADDStyled,
} from './responsible-solution.styled';

interface ResponsibleSolutionViewParams {
  handleAddResponsable: (values: string) => void;
  handleRemoveResponsable: (values: string) => void;
  responsables?: Array<string>;
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
}

const ResponsibleSolutionView: React.FC<ResponsibleSolutionViewParams> = ({
  handleAddResponsable,
  handleRemoveResponsable,
  responsables,
  hasError = false,
  errorMessage = 'Invalid value',
  placeholder,
}) => {
  const [responsable, setResponsable] = useState('');

  const renderResponsables = useMemo(() => {
    if (!responsables || (responsables && responsables.length === 0)) return null;

    return responsables.map((responsable) => (
      <ResponsableContainerStyled key={responsable}>
        {responsable}
        <DeleteResponsableStyled onClick={() => handleRemoveResponsable(responsable)}>X</DeleteResponsableStyled>
      </ResponsableContainerStyled>
    ));
  }, [handleRemoveResponsable, responsables]);

  return (
    <>
      <ContainerStyled hasError={hasError}>
        <InputStyled
          placeholder={placeholder}
          onChange={(e) => setResponsable(e.target.value)}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              handleAddResponsable(responsable);
              setResponsable('');
            }
          }}
          value={responsable}
        />
        <ButtonADDStyled
          onClick={() => {
            handleAddResponsable(responsable);
            setResponsable('');
          }}
        >
          +
        </ButtonADDStyled>
        <ResponsableListStyled>{renderResponsables}</ResponsableListStyled>
        {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
      </ContainerStyled>
    </>
  );
};
export default ResponsibleSolutionView;
