import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback, useContext, useEffect } from 'react';
import { SaleOfferRegisterFormMountView } from './sale-offer-register-form-mount-view.component';
import { ISaleOfferFormRegisterPayloadDomain } from '../../../../domains/sale-offer-form-register.domain';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSaleOfferById } from '../../../../hooks/use-get-sale-offer-by-id/use-get-sale-offer-by-id.hook';

export const SaleOfferRegisterFormMount: FC = () => {
  const { values, setFieldValue, errors, touched, handleSubmit, setValues } =
    useFormikContext<ISaleOfferFormRegisterPayloadDomain>();

  const { saleOfferModals, saleOfferDetails } = useContext(SaleOfferContext);

  const getSaleOfferByIdHook = useGetSaleOfferById();

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleOpenOfferShipperModal = useCallback(() => {
    saleOfferModals.setModalOpen({
      indexes: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
      modalType: 'offer_shipper',
    });
  }, [saleOfferModals]);

  const handleGoBackPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (location.state?.saleOfferId && saleOfferDetails.statusRequest === 'INITIAL') {
        const saleOfferRegisterValues = await getSaleOfferByIdHook();

        if (saleOfferRegisterValues) {
          setValues(saleOfferRegisterValues);
        }
      }
    })();
  }, [getSaleOfferByIdHook, location.state?.saleOfferId, saleOfferDetails.statusRequest, setValues]);

  return (
    <SaleOfferRegisterFormMountView
      values={values}
      errors={errors}
      touched={touched}
      saleOfferModals={saleOfferModals}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleOnSubmit={handleSubmit}
      handleOpenOfferShipperModal={handleOpenOfferShipperModal}
      handleGoBackPage={handleGoBackPage}
    />
  );
};
