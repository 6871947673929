export const reasonCancellation = [
  {
    label: 'Carga Recusada',
    value: 'Carga Recusada',
  },
  {
    label: 'Cliente Fechado',
    value: 'Cliente Fechado',
  },
  {
    label: 'Endereço Incorreto',
    value: 'Endereço Incorreto',
  },
  {
    label: 'Veículo Quebrado',
    value: 'Veículo Quebrado',
  },
  {
    label: 'Mudança na Programação',
    value: 'Mudança na Programação',
  },
  {
    label: 'Fora do Horário Agendado',
    value: 'Fora do Horário Agendado',
  },
  {
    label: 'Indisponibilidade de Veículo',
    value: 'Indisponibilidade de Veículo',
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
];
