import { useCallback } from 'react';

import { companyDetailsService } from '../../services/company-details/company-details.service';

import { retentionFinesService } from 'pages/company/services/retention-fines/retention-fines.service';
import { convertProfileUrlToFileService } from 'pages/company/services/company-details/convert-profile-url-to-file.service';
import { branchService } from 'pages/company/services/branch/branch.service';
import { ICompany } from 'domain/company';

const useDetailsCompanyHook = () => {
  return useCallback(async (companyId: number): Promise<ICompany> => {
    const retentionData = await retentionFinesService(companyId);
    const branchData = await branchService(companyId);
    let file: File = new File([], '');
    const company = await companyDetailsService(companyId);
    if (company.file) {
      file = await convertProfileUrlToFileService(company.fileKey || '');
    }
    return {
      ...company,
      file,
      retentionFines: retentionData,
      branches: branchData,
    };
  }, []);
};

export { useDetailsCompanyHook };
