import { RetentionFines } from '../entities/retention-fines.entity';

import API from 'utils/API/API';
import { convertRetentionFinesToResume } from 'pages/schedule/mappers/convert-retention-fines/convert-retention-fines.mapper';

export const getRetentionFinesByVehicle = async (vehicleType: string, companyId: number): Promise<RetentionFines> => {
  const { data } = await API.post('v2/retention-fines/vehicle-type-company', { vehicleType, companyId });

  return convertRetentionFinesToResume(data);
};
