import { convertTypeDomainToEntityMapper } from '../convert-types-occurrence-domain-to-entity/convert-types-occurrence-domain-to-entity.mapper';
import { convertStatusDomainToEntityMapper } from '../convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import { IOccurrenceCreatedEntity } from 'pages/occurrence/services/entities/occurrence.entity';
import { IOccurrenceCreated } from 'domain/occurrence';

export const convertOccurrenceCreatedDomainToEntityMapper = (
  occurrenceDomain: IOccurrenceCreated,
): IOccurrenceCreatedEntity => ({
  id: String(occurrenceDomain.id),
  description: occurrenceDomain.description,
  status: convertStatusDomainToEntityMapper(occurrenceDomain.status),
  impact: convertTypeDomainToEntityMapper(occurrenceDomain.impact),
  responsibleSolution: occurrenceDomain.responsibleSolution,
  impactedList: occurrenceDomain.impactedList,
  sla: occurrenceDomain.sla,
  treatmentSector: occurrenceDomain.treatmentSector,
});
