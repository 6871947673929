import styled, { css } from 'styled-components';

export const TitleHeaderForm = styled.h4`
  font-size: 0.75rem;
  font-weight: 700;
  padding-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const TitleForm = styled.h4`
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  padding-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const CheckboxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 2px;
`;

export const Info = styled.p`
  font-size: 13px;
  color: #000;
  padding: 5px;
`;

export const SectionForm = styled.section``;

type WrapperValuesProps = { columns: string; backgroundColor?: boolean };
export const WrapperValues = styled.div<WrapperValuesProps>`
  ${({ columns, backgroundColor }) => css`
    display: grid;
    grid-template-columns: 70% ${columns};
    grid-gap: 15px;
    padding: 5px 0;
    background-color: ${backgroundColor ? '#F1F1F1' : 'white'};

    @media (max-width: 600px) {
      grid-template-columns: 42% ${columns};
    }
  `}
`;
