import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { WeighingTottenCpfConfirmationView } from './weighing-totten-cpf-confirmation-view.component';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';
import * as S from './weighing-totten-cpf-confirmation.styled'
import ErrorMessageSvg from 'src/assets/error-message.svg';
import { WeighingTottenButton } from 'src-new/mobile/pages/weighing-totten/components/weighing-totten-button/weighing-totten-button.component';

interface IWeighingTottenCpfConfirmation {
  callback: () => void;
}
export const WeighingTottenCpfConfirmation: FC<IWeighingTottenCpfConfirmation> = ({ callback }) => {
  const [cpfInvalid, setCpfInvalid] = useState(false);
  const { weighingTottensDetails } = useContext(WeighingTottensContext);

  const handleSubmit = useCallback(
    (values: IWeighingTottenDetailsForm) => {
      if (values.driverCpf !== weighingTottensDetails.weighingTottensDetails.driver.cpf) {
        setCpfInvalid(true);
        return;
      }
      callback();
    },
    [callback, weighingTottensDetails.weighingTottensDetails.driver.cpf],
  );

  const isLoading = useMemo(
    (): boolean => weighingTottensDetails.statusRequest === 'PROCESSING',
    [weighingTottensDetails.statusRequest],
  );

  if (cpfInvalid)
    return (
      <>
        <S.Text>CPF Inválido</S.Text>
        <S.Icon src={ErrorMessageSvg} alt="" />
        <WeighingTottenButton callback={() => setCpfInvalid(false)}>Ok</WeighingTottenButton>
      </>
    );
  return <WeighingTottenCpfConfirmationView isLoading={isLoading} handleSubmit={handleSubmit} />;
};
