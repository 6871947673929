import React, { Fragment } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { PurchaseOfferModalMatchTable } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-table-tabs/components/purchase-offer-modal-match-table/purchase-offer-modal-match-table.component';

export interface IPurchaseOfferModalMatchTableTabsViewProps {
  tabsContent: Array<ITabContent>;
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const PurchaseOfferModalMatchTableTabsView: React.FC<IPurchaseOfferModalMatchTableTabsViewProps> = ({
  tabsContent,
  activeTab,
  handleChangeTab,
}) => {
  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
      <PurchaseOfferModalMatchTable />
    </Fragment>
  );
};
