import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { DocumentsMDFeView } from './documents-mdfe-view.component';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleDocumentsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { useGetMDFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-mdfe-data/use-get-mdfe-data.hook';

export const DocumentsMDFe: React.FC = () => {
  const [viewMode, setViewMode] = useState<'list' | 'kanban'>('list');
  const { scheduleDocuments } = useContext(ScheduleContext);
  const getMDFeData = useGetMDFeData();

  const handleToggleClick = useCallback(() => setViewMode(viewMode === 'list' ? 'kanban' : 'list'), [viewMode]);

  const handleOpenModal = useCallback(
    (modalType: ScheduleDocumentsModalType, id: number) =>
      scheduleDocuments.modalsProps.setModalOpen({ open: true, id, modalType }),
    [scheduleDocuments.modalsProps],
  );

  const isLoading = useMemo(
    () => scheduleDocuments.mdfeDataProps.statusRequest === 'PROCESSING',
    [scheduleDocuments.mdfeDataProps.statusRequest],
  );

  const isEmptyList = useMemo(
    () =>
      scheduleDocuments.mdfeDataProps.statusRequest === 'SUCCESS' && !scheduleDocuments.mdfeDataProps.mdfeList.length,
    [scheduleDocuments.mdfeDataProps.statusRequest, scheduleDocuments.mdfeDataProps.mdfeList.length],
  );

  useEffect(() => {
    if (scheduleDocuments.mdfeDataProps.statusRequest === 'INITIAL') {
      getMDFeData();
    }
  }, [getMDFeData, scheduleDocuments.mdfeDataProps.statusRequest]);

  return (
    <DocumentsMDFeView
      mdfes={scheduleDocuments.mdfeDataProps.mdfeList}
      isLoading={isLoading}
      handleOpenModal={handleOpenModal}
      handleToggleClick={handleToggleClick}
      viewMode={viewMode}
      isEmptyList={isEmptyList}
    />
  );
};
