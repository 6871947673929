import styled, { css } from 'styled-components';

export const WrapperSectionStyled = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  border-radius: 5px;
  padding: 15px;
  width: 100vw;

  overflow: hidden;
`;

export const HeaderTitleStyled = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colorsV2.blue};
    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 20px;
    margin-bottom: 15px;
    width: 100%;
  `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const ContainerStyled = styled.div``;

export const SectionContainerStyled = styled.div`
  margin-right: 20px;
`;

export const UploadImageStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const UploadCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  font-size: 3em;
  color: #e0e0e0;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #e0e0e0;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
`;
