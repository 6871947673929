import React, { useState } from 'react';
import TooltipComponent from 'components/Tooltip';
import { ContainerLogoStyled, ContentStyled, WrapperStyled } from './dropdown.styled';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OrderIcon from 'assets/icons/order.icon';
import { Button } from 'logshare-ui-kit';

type DropdownProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  isButtonOrder?: boolean;
};

const DropwdownView: React.FC<DropdownProps> = ({ children, isButtonOrder }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipComponent
    icon={
      <WrapperStyled isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <ContainerLogoStyled>
          {isButtonOrder ? <OrderIcon /> : <Button
          label=""
          color="white"
          size="x-sm"
          variant="ghost"
          LeftIcon={<CloudDownloadIcon sx={{ width: 22 }} />}
      />}
        </ContainerLogoStyled>
        <ContentStyled aria-hidden={!isOpen} onClick={() => setIsOpen(!isOpen)}>
          {children}
        </ContentStyled>
      </WrapperStyled>
    }
    title={'Exportar'}
    location={'bottom'}
  />
  );
};

export default DropwdownView;
