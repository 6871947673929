import React, { ReactElement, useMemo } from 'react';
import { InputStyled, LabelStyled, WrapperStyled } from './checkbox-view.styled';

interface CheckboxViewProps {
  id: string;
  name: string;
  label?: string;
  labelColor?: string;
  backgroundColor?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  hasError?: boolean;
}

export const CheckboxView: React.FC<CheckboxViewProps> = ({
  id,
  name,
  label,
  labelColor,
  backgroundColor,
  onChange,
  value,
  disabled,
  checked,
  hasError,
}) => {
  const renderLabel = useMemo((): ReactElement | undefined => {
    if (label && label !== '') {
      return (
        <LabelStyled htmlFor={id} labelColor={labelColor}>
          {label}
        </LabelStyled>
      );
    }
  }, [id, label, labelColor]);

  return (
    <WrapperStyled>
      <InputStyled
        id={id}
        type="checkbox"
        value={value}
        backgroundColor={backgroundColor}
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        hasError={hasError}
      />
      {renderLabel}
    </WrapperStyled>
  );
};
