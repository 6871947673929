import styled, { css } from 'styled-components';

type WrapperValuesProps = { columns: string; padding?: string };
export const WrapperValues = styled.div<WrapperValuesProps>`
  ${({ columns, padding }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 15px;
    padding-top: ${padding ?? '15px'};
  `}
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 3.5rem 0;
`;
