import styled, { css } from 'styled-components';

import { ButtonProps } from './button';

export type WrapperProps = {
  hasIcon: boolean;
  isLoading?: () => void;
  getPlan?: string;
  borderColor?:
    | 'blue'
    | 'white'
    | 'pink'
    | 'green'
    | 'orange'
    | 'darkGray'
    | 'middleBlue'
    | 'newRed'
    | 'aqua'
    | 'lightGray';
  size: 'xlarge' | 'large' | 'medium' | 'small' | 'very-small' | 'full' | 'narrow';
  iconPosition?: 'right' | 'left';
} & Pick<ButtonProps, 'bgColor'>;

const wrapperModifier = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
    }
  `,
};

const wrapperLargeModifier = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      margin-left: 15px;
    }
  `,
};

const wrapperLargeModifierLeft = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      margin-right: 5px;
    }
  `,
};

const wrapperLargeModifierCrown = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      margin-right: -27px;
      margin-top: -15px;
      margin-left: 15px;
    }
  `,
};

export const WrapperStyled = styled.button<WrapperProps>`
  ${({ theme, bgColor = 'blue', borderColor = 'white', hasIcon, size, getPlan, iconPosition = 'right' }) => css`
    background-color: ${!!bgColor && theme.colors[bgColor]};
    border: none;
    ${bgColor === 'white' && `border: solid 1px ${theme.colors.forBorder}`};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.semiBold};
    color: ${bgColor === 'white'
      ? 'rgb(80, 80, 80)'
      : bgColor === 'lightGray' && getPlan !== 'pro'
        ? theme.colors.white
        : bgColor === 'lightGray' && getPlan === 'pro'
          ? theme.colors.black
          : theme.colors.white};
    cursor: pointer;
    white-space: nowrap;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${hasIcon && wrapperModifier.withIcon()}

    ${size === 'xlarge' &&
    css`
      width: 165px;
      height: 40px;
      padding: 1px 20px;
      border-radius: ${theme.border.radius};
      font-size: 12px;

      ${hasIcon && iconPosition !== 'left' && wrapperLargeModifier.withIcon()}

      ${hasIcon && iconPosition === 'left' && wrapperLargeModifierLeft.withIcon()}

      ${hasIcon && getPlan !== 'pro' && iconPosition !== 'left' && wrapperLargeModifierCrown.withIcon()}
    `}

    ${size === 'large' &&
    css`
      min-width: 115px;
      height: 40px;
      padding: 1px 20px;
      border-radius: ${theme.border.radius};
      font-size: 12px;

      ${hasIcon && iconPosition !== 'left' && wrapperLargeModifier.withIcon()}

      ${hasIcon && iconPosition === 'left' && wrapperLargeModifierLeft.withIcon()}
    `}

    ${size === 'full' &&
    css`
      width: 100%;
      height: 40px;
      padding: 1px 18px;
      border-radius: ${theme.border.radius};
      font-size: 12px;

      ${hasIcon && wrapperLargeModifier.withIcon()}
    `}

    ${size === 'medium' &&
    css`
      min-width: 115px;
      height: 36px;
      padding: 1px 20px;
      border-radius: ${theme.border.radius};
      font-size: 12px;
    `}

    ${size === 'small' &&
    css`
      max-width: 80px;
      min-width: 45px;
      height: 39px;
      padding: 5px 5px;
      border-radius: ${theme.border.radius};
      font-size: 10px;
    `}

    ${size === 'narrow' &&
    css`
      max-width: 110px;
      min-width: 110px;
      height: 27px;
      padding: 5px 5px;
      color: ${theme.colors[borderColor]};
      border: 3px solid ${theme.colors[borderColor]};
      border-radius: ${theme.border.radius};
      font-size: 10px;

      svg path {
        fill: ${theme.colors[borderColor]};
      }

      &:hover {
        background-color: ${theme.colors[borderColor]};
        color: white;
        border-color:;
      }

      &:hover svg path {
        fill: ${theme.colors.white};
      }
    `}

    ${size === 'very-small' &&
    css`
      min-width: 40px;
      height: 36px;
      padding: 5px 12px;
      border-radius: ${theme.border.radius};
      font-size: 11px;
    `}

    :hover {
      opacity: 0.88;
    }

    :active {
      box-shadow: ${theme.border.shadow};
    }

    :disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    @media (max-width: 600px) {
      min-width: 50px;
      padding: 5px 5px;
      height: 35px;
      font-size: 12px;
    }
  `}
`;
