import {useCallback, useContext, useMemo} from 'react';
import {useQueryClient} from 'react-query';
import {showMessageFormatted} from 'utils/message/show-message-formatted/show-message-formatted';
import {
  convertSaleOfferUpdateDomainToEntity,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/hooks/use-sale-offer-update/mappers/convert-sale-offer-update-domain-to-entity/convert-sale-offer-update-domain-to-entity.mapper';
import {
  saleOfferUpdateService,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/services/sale-offer-update/sale-offer-update.service';
import {useParams} from 'react-router-dom';
import {ISaleOfferDomain} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import {SaleOfferContext} from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

export const useUpdateSaleOffer = () => {
  const {saleOfferUpdate} = useContext(SaleOfferContext);
  const {
    setSaleOfferUpdateRequest,
    setSaleOfferUpdateSuccess,
    setSaleOfferUpdateError,
    setSaleOfferUpdateReset,
  } = saleOfferUpdate;

  const reactQueryClient = useQueryClient();
  const {id} = useParams<{ id: string }>();
  const saleOfferId = useMemo(() => Number(id) ?? '', [id]);

  return useCallback(
    async (payload: ISaleOfferDomain) => {
      try {
        setSaleOfferUpdateRequest();
        await saleOfferUpdateService(saleOfferId, convertSaleOfferUpdateDomainToEntity(payload));

        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });
        setSaleOfferUpdateSuccess();

        reactQueryClient.invalidateQueries(['sale-offer-details-by-id']);
        reactQueryClient.invalidateQueries(['sale-offer-list-table']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
        setSaleOfferUpdateError();
        setSaleOfferUpdateReset();
      } finally {
        setSaleOfferUpdateReset();
      }
    },
    [reactQueryClient, saleOfferId, setSaleOfferUpdateError, setSaleOfferUpdateRequest, setSaleOfferUpdateReset, setSaleOfferUpdateSuccess],
  );
};
