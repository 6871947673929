import { FC } from 'react';
import { Formik } from 'formik';
import { ModalBackhaulPurchaseForm } from './components/modal-backhaul-purchase-form/modal-backhaul-purchase-form.component';
import { convertBackhaulPurchaseToFormInitialValues } from './mappers/convert-backhaul-purchase-to-form-initial-values/convert-backhaul-purchase-to-form-initial-values.mapper';
import { IModalBackhaulPurchaseForm } from './types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from './types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface IModalBackhaulPurchaseViewProps {
  backhaulPurchase: IBackhaulPurchase;
  handleSubmit: (values: IModalBackhaulPurchaseForm) => void;
  handleClose: () => void;
}

export const ModalBackhaulPurchaseView: FC<IModalBackhaulPurchaseViewProps> = ({
  backhaulPurchase,
  handleSubmit,
  handleClose,
}) => {
  return (
    <Formik initialValues={convertBackhaulPurchaseToFormInitialValues(backhaulPurchase)} onSubmit={handleSubmit}>
      <ModalBackhaulPurchaseForm backhaulPurchase={backhaulPurchase} handleClose={handleClose} />
    </Formik>
  );
};
