import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';
import { Operation } from 'domain-v2/inputs/operation';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { convertFreightTypeToDomain } from 'src-new/utils/convert-freight-type/convert-freight-type.util';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { IPurchaseOfferListDomain } from '../../domains/purchase-offer-list.domain';

export const convertPurchaseToModalBackhaulPurchaseMapper = (
  purchaseOffer: IPurchaseOfferMatchListDomain,
  purchaseOfferProposal: IPurchaseOfferListDomain,
): IBackhaulPurchase => {
  return {
    saleOffer: {
      ...purchaseOffer,
      saleOfferTariffId: purchaseOffer.saleOfferShipperTariffId,
      company: {
        ...purchaseOffer.company,
        image: purchaseOffer.backhaulPartner ? purchaseOffer.company.image : '',
      },
      origin: {
        ...purchaseOffer.origin,
        city: purchaseOffer.origin.address.city,
        uf: purchaseOffer.origin.address.uf,
      },
      destination: {
        ...purchaseOffer.destination,
        city: purchaseOffer.destination.address.city,
        uf: purchaseOffer.destination.address.uf,
      },
      typeSchedule: convertFreightTypeToDomain(purchaseOffer.scheduleType),
      operationType: convertOperationTypeToDomain(purchaseOffer.operationType),
      haulType: `BACKHAUL ${purchaseOffer.backhaulType}` as Operation,
      co2Economy: purchaseOffer.co2Economy,
      capacity: {
        ...purchaseOffer.capacity,
        pallets: purchaseOffer.capacity.pallet,
      },
      invoicedBy: purchaseOffer.invoicedBy,
      values: {
        freightValue: purchaseOffer.values.freightValue,
        logshareValue: purchaseOffer.values.logshareValue,
      },
      backhaulPartner: purchaseOffer.backhaulPartner,
    },
    purchaseOffer: {
      ...purchaseOfferProposal,
      origin: {
        ...purchaseOfferProposal.origin,
        city: purchaseOffer.origin.address.city,
        uf: purchaseOffer.origin.address.uf,
        name: `${purchaseOfferProposal.origin.name.toUpperCase()} - ${purchaseOfferProposal.origin.address.city.toUpperCase()} - ${purchaseOfferProposal.origin.address.uf.toUpperCase()}`,
        type: purchaseOfferProposal.origin.type === 'CLIENT' ? 'CLIENT' : 'LOCALITY',
      },
      destination: {
        ...purchaseOfferProposal.destination,
        city: purchaseOfferProposal.destination.address.city,
        uf: purchaseOfferProposal.destination.address.uf,
        name: `${purchaseOfferProposal.destination.name.toUpperCase()} - ${purchaseOfferProposal.destination.address.city.toUpperCase()} - ${purchaseOfferProposal.destination.address.uf.toUpperCase()}`,
        type: purchaseOfferProposal.destination.type === 'CLIENT' ? 'CLIENT' : 'LOCALITY',
      },
      capacity: {
        ...purchaseOfferProposal.capacity,
        pallets: purchaseOfferProposal.capacity.pallet,
      },
      operationType: convertOperationTypeToDomain(purchaseOfferProposal.operationType ?? ''),
      cargoType: convertFreightTypeToDomain(purchaseOfferProposal.cargoType ?? 'FTL'),
      initialDate: '',
      values: {
        freightValue: purchaseOfferProposal.values.freight,
        logshareValue: purchaseOfferProposal.values.logshare,
      },
      totalMatchOffers: purchaseOfferProposal.totalMatchOffers,
    },
  };
};
