import API from 'utils/API/API';
import { ScheduleRefusedListServiceResponse } from './get-schedule-refused-list.types';
import { IScheduleRefusedListParams } from 'src-new/pages/execution/pages/schedule-refused/types/schedule-refused-list-filters/schedule-refused-list-filters.types';

export const getScheduleRefusedListService = async (
  params: IScheduleRefusedListParams,
): Promise<ScheduleRefusedListServiceResponse> => {
  const { data } = await API.get<ScheduleRefusedListServiceResponse>('v2/schedules-refused', {
    params: {
      ...params,
      limit: 100,
    },
  });

  return {
    items: data.items,
    meta: data.meta,
  };
};
