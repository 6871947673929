import React, { useContext, useMemo } from 'react';

import * as S from './styled';
import { cargoDetailsInitialValues } from './constants/card-resume-values';

import { formatCurrency, formatDecimal, formatDistanceDecimal } from 'utils/format-numbers';
import { UserContext } from 'state/user-context';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { IOperationValues } from 'domain/card-resume';

interface CardResumeOperationProps {
  cargoDetailsValues: IOperationValues;
  distanceFreight?: number;
  companyName?: string;
  driverValue?: string;
  hasConnector?: boolean;
  vehicleValues?: {
    vehicleType: string;
    vehicleCategory: string;
  };
  shippingValues?: {
    name: string;
    cnpj: string;
  };
}

export const CardResumeOperation: React.FC<CardResumeOperationProps> = ({
  cargoDetailsValues = cargoDetailsInitialValues,
  distanceFreight,
  companyName,
  driverValue,
  hasConnector,
  vehicleValues,
  shippingValues,
}) => {
  const { user } = useContext(UserContext);
  const { inPalet, pallet, vehicleType, vehicleCategoryType, invoice, weight, capacity, shippingValue } =
    cargoDetailsValues;

  const palletValue = useMemo(() => {
    if (inPalet === 'yes' || inPalet === 'true' || inPalet === true) {
      return <S.Text>{Number(pallet) !== 0 ? `${formatDecimal(Number(pallet))} Pallets` : 'Pallets'}</S.Text>;
    }

    return <S.Text>Não Paletizado</S.Text>;
  }, [inPalet, pallet]);

  const renderVehicleValue = useMemo(() => {
    const vehicleTypeToUse = vehicleValues?.vehicleType || vehicleType || '';
    const vehicleCategoryToUse = vehicleValues?.vehicleCategory || vehicleCategoryType || '';

    const vehicleTypeText = convertVehicleTypeTypeToName(vehicleTypeToUse);
    const vehicleCategoryText = convertVehicleCategoryTypeToName(vehicleCategoryToUse);

    return (
      <S.Text
        uppercase={!!(vehicleTypeText || vehicleCategoryText)}
        italic={!(vehicleTypeText || vehicleCategoryText)}
        padding="0 0 10px 0"
      >
        {vehicleTypeText || vehicleCategoryText
          ? `${vehicleTypeText || ''} ${vehicleCategoryText || ''}`
          : 'Não Informado'}
      </S.Text>
    );
  }, [vehicleType, vehicleCategoryType, vehicleValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />

          {hasConnector && <S.Connector size={'110'} />}
        </S.StepIconSection>

        <S.Text blueColor fontWeight>
          Operação
        </S.Text>

        <S.TextContent>
          {user?.profile !== 'shipping-company' && companyName && (
            <S.Text blueColor fontWeight padding="0 0 10px 0">
              Operado por {companyName}
            </S.Text>
          )}

          {shippingValues?.name && (
            <>
              <S.Text blueColor fontWeight>
                Transportadora
              </S.Text>

              <S.Text>{shippingValues.name}</S.Text>

              <S.Text padding="0 0 10px 0">{shippingValues.cnpj}</S.Text>
            </>
          )}

          {driverValue && (
            <>
              <S.Text blueColor fontWeight>
                Motorista
              </S.Text>

              <S.Text padding="0 0 10px 0">{driverValue}</S.Text>
            </>
          )}

          <S.Text blueColor fontWeight>
            Veículo
          </S.Text>

          {renderVehicleValue}

          <S.Text blueColor fontWeight>
            Carga
          </S.Text>
          <S.Text uppercase padding="0 0 10px 0">
            <S.Text>{invoice}</S.Text>

            <S.Text>{`${isNaN(Number(weight)) ? '0' : formatDecimal(Number(weight || 0))} KG`}</S.Text>

            <S.Text>{`${isNaN(Number(capacity)) ? '0' : formatDecimal(Number(capacity || 0))} Vol`}</S.Text>

            <S.Text>{`Valor: ${isNaN(Number(shippingValue)) ? '0' : formatCurrency(Number(shippingValue))}`}</S.Text>

            {palletValue}
          </S.Text>

          <S.Text blueColor fontWeight>
            Distância
          </S.Text>
          <S.Text uppercase>
            <S.Text>{`${formatDistanceDecimal(distanceFreight || 0)} KM`}</S.Text>
          </S.Text>
        </S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
