import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { CardProposedValue } from '../card-proposed';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { findByVehicleCompanyService } from 'services/free-time';
import { getScheduleByIdService } from 'pages-v2/schedule/services/get-schedule-by-id';
import { transportDetailsService } from 'pages/transport-company/services/transport-details/transport-details.service';
import { lastTermsConditions } from 'pages/login/services/terms-conditions.service';
import { ILastTerms } from 'pages/login/services/entities/login.entity';
import { ScheduleInput } from 'domain-v2/schedule';
import { ITransportCompanyCreated } from 'domain/transport-company';
import { FreeTimeDomain } from 'domain/free-time';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { IBiddingCost, IBiddingCreate } from 'domain/bidding';
import { showToast } from 'components/toast/toast';
import Checkbox from 'components/checkbox/checkbox';
import Button from 'components/button/button';
import { SendIcon } from 'assets/icons/send-icon';
import { companyDetailsService } from 'services/company/details';
import { Loading } from 'src-new/components/loading/loading.component';
import { ISaleOfferMatchListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list.domain';
import { convertUf } from 'utils/convert-uf/convert-uf';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { ICompany } from 'domain/company';

const SHIPPING_FREETIME_PERCENT = 0.8;

interface IForm {
  scheduleId: number;
  saleOffer?: ISaleOfferMatchListDomain;
  closeModal: () => void;
}

const requirementsUrl = 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Regras_de_GR.pdf';

const initialCompanyShippingValues = {} as ITransportCompanyCreated;

const convertNumberFormat = Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const BiddingForm: React.FC<IForm> = ({ scheduleId, saleOffer, closeModal }) => {
  const { user } = useContext(UserContext);

  const { setFieldValue, values, handleSubmit } = useFormikContext<IBiddingCreate>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [termAgree, setTermAgree] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<ScheduleInput>();
  const [freeTime, setFreeTime] = useState<FreeTimeDomain>();
  const [lastTerms, setLastTerms] = useState<ILastTerms>();
  const [userCompany, setUserCompany] = useState<ICompany>();
  const [companyShipping, setCompanyShipping] = useState<ITransportCompanyCreated>(initialCompanyShippingValues);
  const [companyPaymentTerm, setCompanyPaymentTerm] = useState<string>('60');

  const handleSaleOffer = useCallback(async () => {
    try {
      setIsLoading(true);
      const scheduleCompany = await companyDetailsService(saleOffer?.company.id ?? 0);

      if (!!scheduleCompany.paymentTerm && scheduleCompany.paymentTerm !== 'null') {
        setCompanyPaymentTerm(scheduleCompany.paymentTerm);
      }

      const responseFreeTime = await findByVehicleCompanyService(
        saleOffer?.company.id ?? 0,
        convertVehicleTypeToEntity(saleOffer?.vehicle.type ?? ''),
      );

      const responseTerms = await lastTermsConditions();

      setFreeTime(responseFreeTime);
      setLastTerms(responseTerms);
    } catch (err: any) {
      showToast({
        message: err,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [saleOffer?.company.id, saleOffer?.vehicle.type]);

  const handleSchedule = useCallback(async () => {
    try {
      setIsLoading(true);
      const responseSchedule = await getScheduleByIdService(scheduleId);

      const scheduleCompany = await companyDetailsService(responseSchedule.company.id);
      const userCompany = await companyDetailsService(user?.companyId ?? 0);

      if (!!scheduleCompany.paymentTerm && scheduleCompany.paymentTerm !== 'null') {
        setCompanyPaymentTerm(scheduleCompany.paymentTerm);
      }

      const responseFreeTime = await findByVehicleCompanyService(
        responseSchedule?.company.id ?? 0,
        responseSchedule?.cargo.vehicleType ?? '',
      );

      const dailyFine = responseFreeTime.dailyFine * SHIPPING_FREETIME_PERCENT;
      const hourlyFine = responseFreeTime.hourlyFine * SHIPPING_FREETIME_PERCENT;

      const responseTerms = await lastTermsConditions();

      setFreeTime({ ...responseFreeTime, dailyFine, hourlyFine });
      setSchedule(responseSchedule);
      setLastTerms(responseTerms);
      setUserCompany(userCompany);
    } catch (err) {
      showToast({
        message: 'Houve um erro ao buscar detalhes da agendamento',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [scheduleId]);

  useEffect(() => {
    (async () => {
      if (saleOffer) {
        await handleSaleOffer();
      } else {
        await handleSchedule();
      }
    })();
  }, [handleSaleOffer, handleSchedule, saleOffer]);

  useEffect(() => {
    setFieldValue('scheduleId', scheduleId);
  }, []);

  useEffect(() => {
    setFieldValue('termsId', lastTerms?.id);
  }, [lastTerms]);

  useEffect(() => {
    if (user?.profile === 'shipping-company')
      (async () => {
        try {
          const findCompany = await transportDetailsService(user?.shippingCompanyId || 0);

          setCompanyShipping(findCompany);
        } catch (error) {
          showToast({
            message: 'Erro ao obter detalhes da empresa',
            type: 'error',
          });
        }
      })();
  }, [user]);

  function formatTimeSegment(initialTime?: string, endTime?: string) {
    const formattedInitialTime = moment(initialTime).format('HH:mm');

    const formattedEndTime = moment(endTime).format('HH:mm');

    return `${formattedInitialTime} - ${formattedEndTime}`;
  }

  const handleProposedValue = useCallback((cost: IBiddingCost) => setFieldValue('cost', cost), [setFieldValue]);

  const scheduleLevyTime = useMemo(() => {
    if (saleOffer) return;

    const formattedEndDate = moment(schedule?.detail?.levyEndDate).format('DD/MM') || '';
    const formattedTimeSegment = formatTimeSegment(schedule?.detail?.levyInitialDate, schedule?.detail?.levyEndDate);

    return <S.Label>{`${formattedEndDate} ${formattedTimeSegment}`}</S.Label>;
  }, [saleOffer, schedule?.detail?.levyEndDate, schedule?.detail?.levyInitialDate]);

  const scheduleDeliveryTime = useMemo(() => {
    if (saleOffer) return;

    const formattedEndDate = moment(schedule?.detail?.deliveryEndDate).format('DD/MM') || '';
    const formattedTimeSegment = formatTimeSegment(
      schedule?.detail?.deliveryInitialDate,
      schedule?.detail?.deliveryEndDate,
    );

    return <S.Label>{`${formattedEndDate} ${formattedTimeSegment}`}</S.Label>;
  }, [saleOffer, schedule?.detail?.deliveryEndDate, schedule?.detail?.deliveryInitialDate]);

  const shippingHomologate = useMemo(() => {
    if (
      schedule?.company.id === companyShipping?.company?.id ||
      saleOffer?.company.id === companyShipping?.company?.id
    ) {
      const label = 'HOMOLOGADO';
      const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELO TRANSPORTADOR';
      return { label, text };
    }

    const label = 'NÃO HOMOLOGADO';
    const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELA LOGSHARE E CONTRA-CTE PELA TRANSPORTADORA';
    return { label, text };
  }, [schedule?.company.id, companyShipping?.company?.id, saleOffer?.company.id]);

  const spotValue = useMemo(
    (): number =>
      Number(
        saleOffer?.values.freightValue ??
          schedule?.freithgtPayable?.totalService ??
          schedule?.freithgtShipping?.totalService ??
          '0',
      ),
    [saleOffer?.values.freightValue, schedule?.freithgtPayable?.totalService, schedule?.freithgtShipping?.totalService],
  );

  const converterValue = useCallback((value: number) => {
    const valueConverted = value.toString().replace(',', '.');

    return Number(valueConverted);
  }, []);

  const disableButton = useMemo((): boolean => {
    const value = converterValue(values.cost?.freightValue ?? 0);

    return !value || value === 0;
  }, [converterValue, values.cost.freightValue]);

  const productLabel = useMemo((): string => {
    if (
      !saleOffer?.predominantProduct ||
      saleOffer?.predominantProduct === '' ||
      !schedule?.cargo?.product ||
      schedule?.cargo?.product === ''
    ) {
      return 'NÃO INFORMADO';
    }

    return saleOffer?.predominantProduct ?? schedule?.cargo?.product;
  }, [saleOffer?.predominantProduct, schedule?.cargo?.product]);

  const volumeLabel = useMemo((): number => {
    if (saleOffer) {
      if (saleOffer.capacity.pallet) {
        return saleOffer.capacity.pallet;
      }

      return 0;
    }

    if (schedule?.cargo.inPallet === true) {
      return schedule?.cargo.pallet;
    }

    return schedule?.cargo.volume ?? 0;
  }, [saleOffer, schedule?.cargo.inPallet, schedule?.cargo.pallet, schedule?.cargo.volume]);

  const titleLabel = useMemo((): string => {
    if (saleOffer?.id) {
      return `Proposta de Venda - Rota ID ${saleOffer.id}`;
    }

    return `Proposta de Venda - Frete ID ${scheduleId}`;
  }, [saleOffer?.id, scheduleId]);

  const renderContent = useMemo((): ReactElement => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <Fragment>
        <S.Header>
          <S.CustomWrapper style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <S.HeaderTitle>{titleLabel}</S.HeaderTitle>

            <S.CustomWrapper style={{ display: 'flex', gap: 265, paddingBottom: '30px' }}>
              <S.CustomWrapper style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <S.CustomWrapper style={{ display: 'flex', gap: 5 }}>
                  {!saleOffer && (
                    <Tag label="Spot" color={ColorsV2Type.Blue} variant="solid" display="inline-flex" size="md" />
                  )}
                  {user?.profile === 'shipping-company' && (
                    <Tag
                      label={shippingHomologate.label}
                      color={shippingHomologate.label === 'HOMOLOGADO' ? ColorsV2Type.Green : ColorsV2Type.Red}
                      variant="solid"
                      display="inline-flex"
                      RightIcon={
                        <Tooltip
                          title={<span style={{ fontSize: '11px' }}>{shippingHomologate.text}</span>}
                          placement="right"
                        >
                          <div style={{ display: 'flex' }}>
                            <InfoIcon sx={{ height: '11px', width: '11px' }} />
                          </div>
                        </Tooltip>
                      }
                      size="md"
                    />
                  )}

                  {schedule?.cargo.requirements.quality?.values.map((requirement, index) => {
                    return (
                      <Tag
                        key={index}
                        label={requirement}
                        color={ColorsV2Type.Blue}
                        variant="solid"
                        display="inline-flex"
                        size="md"
                      />
                    );
                  })}
                  {schedule?.cargo.requirements.security?.values.map((requirement, index) => {
                    return (
                      <Tag
                        key={index}
                        label={requirement}
                        color={ColorsV2Type.Blue}
                        variant="solid"
                        display="inline-flex"
                        size="md"
                      />
                    );
                  })}
                </S.CustomWrapper>
              </S.CustomWrapper>
            </S.CustomWrapper>
          </S.CustomWrapper>

          <S.ButtonClose onClick={closeModal}>
            <S.CloseModalStyled>X</S.CloseModalStyled>
          </S.ButtonClose>
        </S.Header>

        <S.Main>
          <S.WrapperLocations>
            <S.CustomWrapper style={{ display: 'flex' }}>
              <S.CustomWrapper>
                <div style={{ marginTop: '3px' }}>
                  <S.StepIcon />
                  <S.Connector isSaleOffer={!!saleOffer} />
                  <S.StepIcon />
                </div>
              </S.CustomWrapper>

              <S.CustomWrapper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                  marginLeft: 5,
                }}
              >
                <S.CustomWrapper>
                  <S.Title>Origem</S.Title>

                  <S.CustomWrapper>
                    <S.CustomWrapper style={{ width: '220px' }}>
                      <S.Title isBold>
                        {saleOffer?.origin.address.city ?? schedule?.origin?.address.city}
                        {' - '}
                        {saleOffer?.origin.address.uf ?? schedule?.origin?.address.uf}
                      </S.Title>
                    </S.CustomWrapper>

                    <S.CustomWrapper>{scheduleLevyTime}</S.CustomWrapper>
                  </S.CustomWrapper>
                </S.CustomWrapper>

                <S.CustomWrapper>
                  <S.Title>Destino</S.Title>

                  <S.CustomWrapper>
                    <S.CustomWrapper style={{ width: '220px' }}>
                      <S.Title isBold>
                        {saleOffer?.destination.address.city ?? schedule?.destination.address.city}
                        {' - '}
                        {saleOffer?.destination.address.uf ?? schedule?.destination.address.uf}
                      </S.Title>
                    </S.CustomWrapper>

                    <S.CustomWrapper>{scheduleDeliveryTime}</S.CustomWrapper>
                  </S.CustomWrapper>
                </S.CustomWrapper>
              </S.CustomWrapper>
            </S.CustomWrapper>

            <S.CustomWrapper style={{ marginLeft: 17 }}>
              <S.Title>Veículo</S.Title>

              <S.CustomWrapper style={{ width: '220px' }}>
                <S.Title isBold>
                  {saleOffer?.vehicle.type ?? convertVehicleTypeTypeToName(schedule?.cargo.vehicleType)} -{' '}
                  {saleOffer?.vehicle.category ?? convertVehicleCategoryTypeToName(schedule?.cargo.vehicleCategoryType)}
                </S.Title>
              </S.CustomWrapper>
            </S.CustomWrapper>

            <S.CustomWrapper style={{ marginLeft: 17 }}>
              <S.Title>Prazo de Pagamento</S.Title>

              <S.CustomWrapper>
                <S.Title isBold withoutPadding>
                  {companyPaymentTerm} DIAS CORRIDOS
                </S.Title>
              </S.CustomWrapper>
            </S.CustomWrapper>
          </S.WrapperLocations>

          <S.WrapperScheduleDetails>
            <S.CustomWrapper>
              <S.Title>Detalhes do Frete</S.Title>

              <S.ScheduleDetails>
                <S.Label>
                  Peso:{' '}
                  <S.ScheduleDetailsBold>
                    {saleOffer?.capacity.weight ?? schedule?.cargo.weight} KG
                  </S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Cubagem: <S.ScheduleDetailsBold>{schedule?.cargo.cubagem ?? 0} M³</S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Paletizado:{' '}
                  <S.ScheduleDetailsBold>
                    {saleOffer?.capacity.pallet || schedule?.cargo.inPallet ? 'SIM' : 'NÃO'}
                  </S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Volume: <S.ScheduleDetailsBold>{volumeLabel}</S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Produto Predominante: <S.ScheduleDetailsBold>{productLabel}</S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Distância:{' '}
                  <S.ScheduleDetailsBold>
                    {convertNumberFormat.format(saleOffer?.additionalDistance ?? schedule?.distance ?? 0)}
                    KM
                  </S.ScheduleDetailsBold>
                </S.Label>
              </S.ScheduleDetails>
            </S.CustomWrapper>

            <S.CustomWrapper>
              <S.Title>Regras de Freetime</S.Title>

              <S.ScheduleDetails>
                <S.Label>
                  Freetime: <S.ScheduleDetailsBold>{freeTime?.freeTime || '0'} HORAS</S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Diária:{' '}
                  <S.ScheduleDetailsBold>
                    {convertCurrencyFormat.format(freeTime?.dailyFine ?? 0)}
                  </S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Hora Adicional:{' '}
                  <S.ScheduleDetailsBold>
                    {convertCurrencyFormat.format(freeTime?.hourlyFine ?? 0)}
                  </S.ScheduleDetailsBold>
                </S.Label>
              </S.ScheduleDetails>
            </S.CustomWrapper>
          </S.WrapperScheduleDetails>

          <CardProposedValue
            handleProposedValue={handleProposedValue}
            proposedValue={values.cost?.freightValue ?? 0}
            shippingHomologate={!saleOffer ? shippingHomologate.label === 'HOMOLOGADO' : false}
            companyId={saleOffer?.company.id ?? schedule?.company.id}
            merchValue={schedule?.cargo.merchValue}
            originUf={convertUf(saleOffer?.origin.address.uf ?? '') ?? schedule?.origin?.address.uf}
            destinyUf={convertUf(saleOffer?.destination.address.uf ?? '') ?? schedule?.destination?.address.uf}
            isSchedule={!!Number(schedule?.id)}
            spotValue={spotValue}
            userCompany={userCompany}
          />
        </S.Main>

        <S.WrapperInput>
          {saleOffer ? (
            <S.InfoSection>
              <InfoIcon sx={{ display: 'flex', width: '15px' }} />
              <S.TermsText>A proposta de venda será enviada para o embarcador responsável pela rota.</S.TermsText>
            </S.InfoSection>
          ) : (
            <S.CheckboxSection>
              <Checkbox id="terms" name="terms" onChange={() => setTermAgree(!termAgree)} />

              <S.TermsText>
                {'Ao ofertar, confirmo que estou de acordo com o '}
                <S.LinkText target="_blank" href={`${requirementsUrl}`}>
                  plano de gerenciamento de riscos
                </S.LinkText>
                {' e '}
                <S.LinkText target="_blank" href={lastTerms?.content}>
                  termos de uso
                </S.LinkText>
                {' da logshare.'}
              </S.TermsText>
            </S.CheckboxSection>
          )}

          <S.CustomWrapper style={{ marginTop: 2.5 }}>
            <Button
              bgColor="blue"
              title="Ofertar"
              size="medium"
              callback={handleSubmit}
              icon={
                <div style={{ marginLeft: 5 }}>
                  <SendIcon />
                </div>
              }
              disabled={(!saleOffer && termAgree) || disableButton}
            />
          </S.CustomWrapper>
        </S.WrapperInput>
      </Fragment>
    );
  }, [
    closeModal,
    companyPaymentTerm,
    disableButton,
    freeTime,
    handleProposedValue,
    handleSubmit,
    isLoading,
    lastTerms?.content,
    productLabel,
    saleOffer,
    schedule,
    scheduleDeliveryTime,
    scheduleLevyTime,
    shippingHomologate,
    spotValue,
    termAgree,
    titleLabel,
    user?.profile,
    values.cost?.freightValue,
    volumeLabel,
  ]);

  return (
    <S.Wrapper>
      <S.Content>{renderContent}</S.Content>
    </S.Wrapper>
  );
};
