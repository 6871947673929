import { FeatureToggleConfig } from 'src-new/infrastructure/feature-toggle/features-toggles.types';

export const featureToggles: FeatureToggleConfig = {
  dev: {
    ENABLE_TRANSACTION_LISTING: true,
    ENABLE_PALLET_FORM: true,
  },
  prod: {
    ENABLE_TRANSACTION_LISTING: false,
    ENABLE_PALLET_FORM: false,
  },
};
