import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex: 0 0 40%;
  max-width: 1px;
`;

export const CardDetailsOnLeftStyled = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  ${({ checked, disabled }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    min-width: 235px;
    padding: 12px;
    background-color: ${checked ? '#008018' : '#d9d9d9'};
    border-radius: 4px;

    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${checked ? '#fff' : '#000'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: 1;

    > div {
      &:first-child {
        width: 100%;
      }
    }

    @media screen and (max-width: 1350px) {
      min-width: calc(35vw - 245px);
    }
  `}
`;

export const CardDetailsCardModal = styled.div`
  width: 40px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerCardDetailsStyled = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  column-gap: 10px;
  cursor: pointer;
`;

export const TariffCompanyStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StrongStyled = styled.strong``;

export const TextTariffStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const ContainerPriceTariffStyled = styled.div`
  position: absolute;
  top: 2px;
`;

export const SubNameStyled = styled.p`
  margin-left: 5px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerDateStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
