import React, { ReactNode } from 'react';

import { DeliveryTrackingBodyView } from './delivery-tracking-body-view';

interface IProps {
  children: ReactNode;
  description: string;
  id?: string;
}

export const DeliveryTrackingBody: React.FC<IProps> = ({ children, description, id }) => {
  return (
    <DeliveryTrackingBodyView description={description} id={id}>
      {children}
    </DeliveryTrackingBodyView>
  );
};
