import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IHomeCardsDomain } from '../../domains/home-cards/home-cards.domain';
import { IHomeCardsResponse } from './get-home-cards.types';
import { convertHomeCardsResponseToDomain } from './mappers/convert-home-cards-response-to-domain/convert-home-cards-response-to-domain.mapper';

export const getHomeCardsService = async (): Promise<IHomeCardsDomain> => {
  const { data } = await API_TRAVELS_SVC.get<IHomeCardsResponse>('v1/matches/stats');

  return convertHomeCardsResponseToDomain(data);
};
