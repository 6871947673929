import React from 'react';

import { StickerProps } from './types/sticker-type';
import BackhaulStickerIconView from './sticker-view';

const BackhaulStickerIcon: React.FC<StickerProps> = ({ callback, title, bgColor, isButton, width, circle, icon }) => {
  return (
    <BackhaulStickerIconView
      callback={callback}
      title={title}
      bgColor={bgColor}
      isButton={isButton}
      circle={circle}
      width={width}
      icon={icon}
    />
  );
};

export default BackhaulStickerIcon;
