import React from 'react';

import OurPrivacyPoliciesModal from '../our-privacy-policies-modal';
import OurPrivacyPoliciesModalTable from '../components/our-privacy-policies-modal-table/our-privacy-policies-modal-table';

interface IProps {
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OurPrivacyPoliciesModalBodyView: React.FC<IProps> = ({ title, showModal, setShowModal }) => {
  return (
    <OurPrivacyPoliciesModal title={title} show={showModal} useCallBackCloseButton={() => setShowModal(!showModal)}>
      <OurPrivacyPoliciesModalTable />
    </OurPrivacyPoliciesModal>
  );
};

export { OurPrivacyPoliciesModalBodyView };
