import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button } from 'logshare-ui-kit';
import { Pencil } from 'lucide-react';
import { FC, Fragment, useMemo } from 'react';
import { Box } from 'src-new/components/box/box.component';
import { capitalize } from 'src-new/utils/capitalize.util';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { convertDecimalFormat } from 'utils-v2/converters/decimal';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import {
  IScheduleCustomFields,
  IScheduleDetailsCargoDomain,
  IScheduleDetailsComplementDomain,
} from '../../../../../../../../domains/schedule-details.domain';
import * as S from './schedule-details-cargo-view.styled';

interface IScheduleDetailsCargoViewProps {
  cargo: IScheduleDetailsCargoDomain;
  detail: IScheduleDetailsComplementDomain;
  customFields?: Array<IScheduleCustomFields>;
  handleOpenModalCargo: () => void;
}

export const ScheduleDetailsCargoView: FC<IScheduleDetailsCargoViewProps> = ({
  cargo,
  detail,
  customFields,
  handleOpenModalCargo,
}) => {
  const customFieldsValues = useMemo(() => {
    if (customFields && customFields.length > 0) {
      return customFields.map(({ id, value, label }) => {
        return (
          <S.InformationTitle key={id}>
            {label}
            <S.InformationBody>{value ?? '-'}</S.InformationBody>
          </S.InformationTitle>
        );
      });
    }

    return (
      <Fragment>
        <S.WrapperInformation>
          <S.InformationTitle>Nº Interno</S.InformationTitle>
          <S.InformationBody>{detail.internalNumber || '-'}</S.InformationBody>
        </S.WrapperInformation>

        <S.WrapperInformation>
          <S.InformationTitle>Nº OC Interna</S.InformationTitle>
          <S.InformationBody>{detail.ocInternalNumber || '-'}</S.InformationBody>
        </S.WrapperInformation>

        <S.WrapperInformation>
          <S.InformationTitle>Nº Embarque</S.InformationTitle>
          <S.InformationBody>{detail.boardingNumber || '-'}</S.InformationBody>
        </S.WrapperInformation>

        <S.WrapperInformation>
          <S.InformationTitle>Senha</S.InformationTitle>
          <S.InformationBody>{detail.schedulingPassword || '-'}</S.InformationBody>
        </S.WrapperInformation>
      </Fragment>
    );
  }, [customFields, detail.boardingNumber, detail.internalNumber, detail.ocInternalNumber, detail.schedulingPassword]);

  return (
    <Box
      footerButtons={[
        <span key={0} />,
        <S.ContainerButtons key={1}>
          <Button
            label=""
            color="white"
            onPress={handleOpenModalCargo}
            LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
            size="x-sm"
            variant="ghost"
          />
        </S.ContainerButtons>,
      ]}
    >
      <S.Wrapper>
        <S.Title>Detalhes da Carga</S.Title>

        <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Tipo do Veículo</S.InformationTitle>
            <S.InformationBody>{capitalize(convertVehicleType(cargo.vehicleType))}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Categoria</S.InformationTitle>
            <S.InformationBody>{capitalize(convertVehicleCategory(cargo.vehicleCategoryType))}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Frete Retorno</S.InformationTitle>
            <S.InformationBody>{capitalize(cargo.shippingReturn || '-')}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Tipo de Operação</S.InformationTitle>
            <S.InformationBody>{capitalize(cargo.operationSubType || '-')}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>

        <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Produto Predominante</S.InformationTitle>
            <S.InformationBody>{capitalize(cargo.product || '-')}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Peso(KG)</S.InformationTitle>
            <S.InformationBody>{convertDecimalFormat.format(cargo.weight)}Kg</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Cubagem</S.InformationTitle>
            <S.InformationBody>{cargo.cubagem}m</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Volume</S.InformationTitle>
            <S.InformationBody>{cargo.volume}v</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>

        <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Valor da Mercadoria</S.InformationTitle>
            <S.InformationBody>{convertCurrencyFormat.format(cargo.merchValue)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Paletizado</S.InformationTitle>
            <S.InformationBody>{cargo.inPallet ? 'Sim' : 'Não'}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Paletes</S.InformationTitle>
            <S.InformationBody>{cargo.pallet || '-'}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>

        <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>{customFieldsValues}</LineSectionLayout>
      </S.Wrapper>
    </Box>
  );
};
