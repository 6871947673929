import React, { Fragment, ReactElement, useMemo } from 'react';
import { ModalDelete } from 'src-new/components/modal-delete/modal-delete.component';
import { ScheduleDocumentsModals } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';

export interface IDocumentsModalsDeleteViewProps {
  modalOptions: ScheduleDocumentsModals;
  handleClose: () => void;
  handleDelete: () => void;
}

export const DocumentsModalsDeleteView: React.FC<IDocumentsModalsDeleteViewProps> = ({
  modalOptions,
  handleClose,
  handleDelete,
}) => {
  const getLabelName = (modalType: string) => {
    const name: { [key: string]: string } = {
      nfe: 'NFe',
      cte: 'CTe',
      mdfe: 'MDFe',
      othersDocuments: 'documento',
    };

    return name[modalType] ?? '';
  };

  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      const label = getLabelName(modalOptions.modalType);

      return (
        <ModalDelete
          title={`Exclusão de ${label}`}
          subtitle={`Tem certeza que deseja excluir o ${label}?`}
          handleClose={handleClose}
          handleConfirm={handleDelete}
        />
      );
    }
  }, [handleClose, handleDelete, modalOptions.modalType, modalOptions.open]);

  return <Fragment>{renderModals}</Fragment>;
};
