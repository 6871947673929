import { IListPage } from 'src-new/domains/list-page.domain';
import API from 'utils/API/API';
import { IScheduleDetailsAllocationShippingDomain } from '../../domains/schedule-details-allocation-shipping.domain';
import {
  IScheduleDetailsAllocationShippingListProps,
  IScheduleDetailsAllocationShippingResponse,
} from './get-shipping-list.types';
import { converterScheduleDetailsAllocationShippingResponseToDomain } from './mappers/converter-shipping-response-to-domain/converter-shipping-response-to-domain.mapper';

export const getShippingListService = async (
  params: IScheduleDetailsAllocationShippingListProps,
): Promise<IListPage<IScheduleDetailsAllocationShippingDomain>> => {
  const { data } = await API.get<IListPage<IScheduleDetailsAllocationShippingResponse>>('v2/shipping/companies', {
    params,
  });

  return {
    items: data.items.map(converterScheduleDetailsAllocationShippingResponseToDomain),
    meta: data.meta,
  };
};
