import {
  ICargoInput,
  ICostInput,
  ILocationInput,
  IModalBackhaulPurchaseForm,
} from './modal-backhaul-purchase-form.types';

const locationInitialValues: ILocationInput = {
  id: 0,
  name: '',
  type: '',
};

const cargoInitialValues: ICargoInput = {
  product: '',
  merchValue: 0,
  order: '',
  comments: '',
  shippingReturn: '',
  vehicle: {
    type: '',
    category: '',
  },
  capacity: {
    inPallet: false,
    pallets: 0,
    weight: 0,
    cubage: 0,
    volume: 0,
  },
  requirements: {
    quality: {
      id: 0,
      values: [],
    },
    security: {
      id: 0,
      values: [],
    },
    custom: {
      id: 0,
      values: [],
    },
  },
};

const costInitialValues: ICostInput = {
  freightValue: 0,
  tollValue: 0,
  grisAdvalorem: 0,
  freightTotal: 0,
  icms: 0,
  totalService: 0,
  surplusValue: [],
};

export const modalBackhaulPurchaseFormInitialValues: IModalBackhaulPurchaseForm = {
  saleOfferId: 0,
  saleOfferShipperTarifId: 0,
  matchOfferId: 0,
  companySellerId: 0,
  invoicedBy: '',
  haulType: 'TRACKING',
  co2Economy: 0,
  origin: locationInitialValues,
  destination: locationInitialValues,
  cargo: cargoInitialValues,
  dates: [
    {
      initialLevyDate: '',
      finalLevyDate: '',
      initialDeliveryDate: '',
      finalDeliveryDate: '',
    },
  ],
  cost: costInitialValues,
  retentionFines: {
    freeTime: '',
    dailyFine: 0,
    hourlyFine: 0,
  },
  leadTime: 0,
};
