import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { EquipmentVoucherContext } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.context';
import { removeEquipmentVoucherService } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/services/remove-equipment-voucher/remove-equipment-voucher.service';

export const useEquipmentVoucherRemoveList = () => {
  const { equipmentVoucherList } = useContext(EquipmentVoucherContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { selectionProps, deleteModalProps, setEquipmentVoucherListReset } = equipmentVoucherList;
  const { setCloseModal, setIsLoadingModal } = deleteModalProps;
  const { selectedIds, setSelectedIds } = selectionProps;
  const { setInitialLoadingReset } = initialLoading;

  return useCallback(async () => {
    setIsLoadingModal(true);

    try {
      const removeListPromises: Array<Promise<void>> = selectedIds.map((id: number) =>
        removeEquipmentVoucherService(id),
      );

      await Promise.all(removeListPromises);

      setSelectedIds([]);
      setIsLoadingModal(false);
      setCloseModal();
      setInitialLoadingReset();
      setEquipmentVoucherListReset();

      showMessageFormatted({
        message: 'Os vales equipamentos foram excluidos com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      setIsLoadingModal(false);
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [
    selectedIds,
    setCloseModal,
    setEquipmentVoucherListReset,
    setInitialLoadingReset,
    setIsLoadingModal,
    setSelectedIds,
  ]);
};
