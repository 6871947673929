import styled from 'styled-components';

export const ButtonContainerStyled = styled.div`
  border-radius: 5px;

  button {
    height: 36px !important;
    width: 42px !important;
  }
`;

export const LabelContainerStyled = styled.div`
  margin-left: 3px;
`;
