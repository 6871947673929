import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { checkStubListHook } from '../hooks/check-stub-list';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import * as S from './styled';
import ListCheckStubTable from './content-table/list';
import { StatusForm } from './components/status-form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateCheckStubStatusService } from 'services/check-stub/update-check-stub-status';
import { deleteCheckStubsService } from 'services/check-stub/delete-check-stub';
import {
  CheckStubListParams,
  ICheckStubStatus,
  ISelectedCheckStubs,
  defaultCheckStubFilter,
} from 'domain-v2/check-stub';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { Button } from 'logshare-ui-kit';
import { UserContext } from 'state/user-context';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

const getSessionStorage = sessionStorage.getItem('pagination-check-stub');

const listParams: CheckStubListParams = getSessionStorage ? JSON.parse(getSessionStorage) : defaultCheckStubFilter;

const statusInitialValue = { status: 'nao_informado' } as FormikValues;

export const CheckStubListPage: React.FC = () => {
  const { isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);
  const reactQueryClient = useQueryClient();

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const navigate = useNavigate();

  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [params, setParams] = useState<CheckStubListParams>(listParams);
  const [selectedCheckStubs, setSelectedCheckStubs] = useState<Array<ISelectedCheckStubs>>([]);

  const { isLoading, data } = useQuery({
    queryKey: ['checkStubList', params],
    queryFn: () => checkStubListHook(params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const goToCreatePallet = useCallback(() => {
    navigate('/pallet/novo');
  }, [navigate]);

  const goToUpdatePallet = useCallback(
    (palletId: string) => {
      navigate(`/pallet/atualizar/${palletId}`);
    },
    [navigate],
  );

  const handleSearch = (search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  };

  const handlePaginate = (page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  };

  const paramsArray: Array<{ key: string; value: string | number }> = useMemo(() => {
    return Object.entries(params).map(([key, value]) => ({
      key,
      value: value as string,
    }));
  }, [params]);

  const newParamsArray = useMemo(() => {
    return paramsArray.filter(
      (item) =>
        (item.key !== 'search' || (item.key === 'search' && item.value !== '')) &&
        !['page', 'order', 'column'].includes(item.key),
    );
  }, [paramsArray]);

  const deleteFilters = useCallback(
    (indexToRemove: number) => {
      const updatedParamsArray = [...newParamsArray];
      updatedParamsArray.splice(indexToRemove, 1);

      if (updatedParamsArray.length === 0) {
        setParams({
          ...defaultCheckStubFilter,
        });

        reactQueryClient.invalidateQueries(['checkStubList', defaultCheckStubFilter]);
      } else {
        const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
          accumulator[key] = params[key as keyof CheckStubListParams];
          return accumulator;
        }, {});

        setParams({
          ...updatedParams,
          page: 1,
        });

        reactQueryClient.invalidateQueries(['checkStubList', updatedParams]);
      }
    },
    [newParamsArray, params, reactQueryClient],
  );

  const handleCheckboxChange = useCallback(
    (checked: boolean, palletId: number, statusCode: string) => {
      if (checked) {
        const newSelectedCheckStubs = [...selectedCheckStubs, { id: palletId, status: statusCode }];
        setSelectedCheckStubs(newSelectedCheckStubs);
      } else {
        const updatedSelectedCheckStubs = selectedCheckStubs.filter((item) => item.id !== palletId);
        setSelectedCheckStubs(updatedSelectedCheckStubs);
      }
    },
    [selectedCheckStubs],
  );

  const handleDeleteCheckStubs = useCallback(async () => {
    try {
      await Promise.all(
        selectedCheckStubs.map(async (item) => {
          await deleteCheckStubsService(item.id);
        }),
      );

      showMessageFormatted({
        message: selectedCheckStubs.length > 1 ? 'Canhotos deletados!' : 'Canhoto deletado!',
        type: 'success',
      });

      await reactQueryClient.invalidateQueries(['checkStubList', params]);

      setModalCancel(false);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [selectedCheckStubs, reactQueryClient, params]);

  const handleSubmit = useCallback(
    async (statusValue: ICheckStubStatus) => {
      try {
        await Promise.all(
          selectedCheckStubs.map(async (item) => {
            await updateCheckStubStatusService(item.id, statusValue);
          }),
        );

        showMessageFormatted({
          message: selectedCheckStubs.length > 1 ? 'Status alterados!' : 'Status alterado!',
          type: 'success',
        });

        await reactQueryClient.invalidateQueries(['checkStubList', params]);

        setModalStatus(false);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [selectedCheckStubs, reactQueryClient, params],
  );

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      {modalCancel && (
        <ModalConfirmation
          title={`Deletar ${selectedCheckStubs.length > 1 ? 'Canhotos' : 'Canhoto'}`}
          description={`Tem certeza que deseja deletar ${selectedCheckStubs.length > 1 ? 'os canhotos' : 'o canhoto'}?`}
          handleCancel={() => setModalCancel(false)}
          onConfirmation={handleDeleteCheckStubs}
          size="small"
          type="delete"
        />
      )}
      {modalStatus && (
        <ModalBark title="Alterar Status" handleClose={() => setModalStatus(false)} size="xsm">
          <Formik initialValues={statusInitialValue} onSubmit={(values) => handleSubmit(values.status)}>
            <StatusForm />
          </Formik>
        </ModalBark>
      )}
      <HeaderPage breadcrumbList={['Serviços', 'Canhotos']} />
      <Title title="" hasTrace />
      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por ID ou Cliente"
          type="text"
          changeValue={(event) => handleSearch(event)}
          filters={newParamsArray.map((item) => item.value)}
          handleClearFilters={(indexToRemove) => deleteFilters(indexToRemove)}
          isClear={false}
        />

        {!isShippingCompany && (
          <Button
            label="Novo Frete"
            size="md"
            color="blue"
            onPress={() => handleCreateSchedulePage(true)}
            variant="solid"
          />
        )}
      </S.ContainerSearch>

      <ListCheckStubTable
        checkStubs={data?.list ?? []}
        pageInfo={data?.pageInfo}
        isLoading={isLoading}
        orderSelected={{
          orderBy: params.column,
          sortDirection: params.order,
        }}
        selectedCheckStubs={selectedCheckStubs}
        handlePaginate={handlePaginate}
        clickOrderTableBy={(column: string) => {
          setParams((prevParams) => ({
            ...prevParams,
            column: column,
          }));
        }}
        clickSortDirection={() => {
          setParams((prevParams) => ({
            ...prevParams,
            order: params.order === 'ASC' ? 'DESC' : 'ASC',
          }));
        }}
        goToUpdatePallet={goToUpdatePallet}
        handleCheckboxChange={handleCheckboxChange}
      />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Novo" color="pink" onPress={goToCreatePallet} />
          {selectedCheckStubs.length > 0 && (
            <>
              <FooterPage.Button label="Excluir" color="white" variant="ghost" onPress={() => setModalCancel(true)} />
              <FooterPage.Button
                label="Status"
                color="green"
                leftIcon={<SwapHorizIcon />}
                onPress={() => setModalStatus(true)}
              />
            </>
          )}
        </FooterPage.RightContent>
      </FooterPage.Root>
    </>
  );
};
