import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tag } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import GavelIcon from '@mui/icons-material/Gavel';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { findByVehicleCompanyService } from 'services/free-time';
import { transportDetailsService } from 'pages/transport-company/services/transport-details/transport-details.service';
import { getIdlenessBiddingById } from 'pages/home/services/bidding/get-schedule/get-schedule.service';
import { ITransportCompanyCreated } from 'domain/transport-company';
import { FreeTimeDomain } from 'domain/free-time';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { IIdlenessBiddingCreate, IIdlenessBiddingModalForm } from 'domain/bidding';
import { showToast } from 'components/toast/toast';
import { CardProposedValueIdleness } from 'components/idleness-bidding/card-proposed';
import Button from 'components/button/button';
import { companyDetailsService } from 'services/company/details';

const SHIPPING_FREETIME_PERCENT = 0.8;

interface IForm {
  idlenessId: number;
  isTransportRouter?: boolean;
  closeModal: () => void;
}

const initialCompanyShippingValues = {} as ITransportCompanyCreated;

export const IdlenessBiddingForm: React.FC<IForm> = ({ idlenessId, isTransportRouter = false, closeModal }) => {
  const { user } = useContext(UserContext);

  const { setFieldValue, values, handleSubmit } = useFormikContext<IIdlenessBiddingCreate>();

  const [idleness, setIdleness] = useState<IIdlenessBiddingModalForm>();
  const [freeTime, setFreeTime] = useState<FreeTimeDomain>();
  const [companyShipping, setCompanyShipping] = useState<ITransportCompanyCreated>(initialCompanyShippingValues);
  const [companyPaymentTerm, setCompanyPaymentTerm] = useState<string>('60');

  useEffect(() => {
    (async () => {
      try {
        const responseIdleness = await getIdlenessBiddingById(idlenessId);

        const idlenessCompany = await companyDetailsService(responseIdleness.companyId);

        if (!!idlenessCompany.paymentTerm && idlenessCompany.paymentTerm !== 'null') {
          setCompanyPaymentTerm(idlenessCompany.paymentTerm);
        }

        const responseFreeTime = await findByVehicleCompanyService(
          responseIdleness?.companyId || 0,
          responseIdleness?.vehicleType || '',
        );

        const dailyFine = responseFreeTime.dailyFine * SHIPPING_FREETIME_PERCENT;
        const hourlyFine = responseFreeTime.hourlyFine * SHIPPING_FREETIME_PERCENT;

        setIdleness(responseIdleness);
        setFreeTime({ ...responseFreeTime, dailyFine, hourlyFine });
      } catch (err) {
        showToast({
          message: 'Houve um erro ao buscar detalhes da agendamento',
          type: 'error',
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (user?.profile === 'shipping-company')
      (async () => {
        try {
          const findCompany = await transportDetailsService(user?.shippingCompanyId || 0);

          setCompanyShipping(findCompany);
        } catch (error) {
          showToast({
            message: 'Erro ao obter detalhes da empresa',
            type: 'error',
          });
        }
      })();
  }, [user]);

  useEffect(() => {
    setFieldValue('idlenessId', idlenessId);
  }, []);

  const locationOrigin = useMemo(() => idleness?.clientOrigin || idleness?.localityOrigin, [idleness]);

  const locationDestination = useMemo(() => idleness?.clientDestination || idleness?.localityDestination, [idleness]);

  const spotValue = useMemo(() => {
    let freightValue = 0;
    if (idleness?.freightValue && idleness?.freightValue > 0) {
      freightValue = idleness?.freightValue;
    }

    return { freightValue, logshareValue: idleness?.logshareValue };
  }, [idleness]);

  const headerTag = useMemo(() => {
    if (!isTransportRouter) {
      return <Tag label="Bidding" color="blue" variant="solid" display="flex" size="md" />;
    }

    if (
      (idleness?.clientOrigin?.companyId ||
        idleness?.localityOrigin?.companyId ||
        idleness?.clientDestination?.companyId ||
        idleness?.localityDestination?.companyId) === user?.companyId
    ) {
      return (
        <Tag label="Backhaul Interno" color={ColorsV2Type.Green} variant="solid" display="inline-flex" size="md" />
      );
    }

    return <Tag label="Backhaul Externo" color={ColorsV2Type.Blue} variant="solid" display="inline-flex" size="md" />;
  }, [idleness, isTransportRouter]);

  const handleProposedValue = (proposalValue?: string, value?: string) => {
    setFieldValue('proposalValue', proposalValue);
    setFieldValue('value', Number(value));
  };

  const shippingHomologate = useMemo(() => {
    if (idleness?.companyId === companyShipping?.company?.id) {
      const label = 'HOMOLOGADO';
      const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELO TRANSPORTADOR';
      return { label, text };
    }

    const label = 'NÃO HOMOLOGADO';
    const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELA LOGSHARE E CONTRA-CTE PELA TRANSPORTADORA';
    return { label, text };
  }, [idleness, companyShipping]);

  const converterValue = useCallback((value: string) => {
    const valueConverted = value.replace(',', '.');

    return Number(valueConverted);
  }, []);

  const disableButton = useMemo((): boolean => {
    const value = converterValue(String(values.proposalValue));

    return !value || value === 0;
  }, [converterValue, values.proposalValue]);

  return (
    <S.Wrapper>
      <S.Content isTransportRouter={isTransportRouter}>
        <S.Header>
          <S.CustomWrapper style={{ display: 'flex', gap: 265, paddingBottom: '30px' }}>
            <S.CustomWrapper style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
              <S.HeaderTitle>
                {isTransportRouter ? `Proposta de Venda - Rota ID ${idlenessId}` : `Bidding - Rota ID ${idlenessId}`}
              </S.HeaderTitle>
              <S.CustomWrapper style={{ display: 'flex', gap: 5 }}>
                {headerTag}

                {!isTransportRouter && user?.profile === 'shipping-company' && (
                  <Tag
                    label={shippingHomologate.label}
                    color={shippingHomologate.label === 'HOMOLOGADO' ? ColorsV2Type.Green : ColorsV2Type.Red}
                    variant="solid"
                    display="inline-flex"
                    RightIcon={
                      <Tooltip
                        title={<span style={{ fontSize: '11px' }}>{shippingHomologate.text}</span>}
                        placement="right"
                      >
                        <div style={{ display: 'flex' }}>
                          <InfoIcon sx={{ height: '11px', width: '11px' }} />
                        </div>
                      </Tooltip>
                    }
                    size="md"
                  />
                )}
              </S.CustomWrapper>
            </S.CustomWrapper>
          </S.CustomWrapper>

          <S.ButtonClose onClick={closeModal}>
            <S.CloseModalStyled>X</S.CloseModalStyled>
          </S.ButtonClose>
        </S.Header>

        <S.Main>
          <S.WrapperLocations isTransportRouter={isTransportRouter}>
            <S.CustomWrapper style={{ display: 'flex' }}>
              <S.CustomWrapper>
                <div style={{ marginTop: '3px' }}>
                  <S.StepIcon />
                  <S.Connector />
                  <S.StepIcon />
                </div>
              </S.CustomWrapper>

              <S.CustomWrapper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 44,
                  marginLeft: 5,
                }}
              >
                <S.CustomWrapper>
                  <S.Title>Origem</S.Title>
                  <S.CustomWrapper>
                    <S.CustomWrapper>
                      <S.Title isBold>
                        {locationOrigin?.city}-{locationOrigin?.uf}
                      </S.Title>
                    </S.CustomWrapper>
                  </S.CustomWrapper>
                </S.CustomWrapper>

                <S.CustomWrapper>
                  <S.Title>Destino</S.Title>
                  <S.CustomWrapper>
                    <S.CustomWrapper>
                      <S.Title isBold>
                        {locationDestination?.city}-{locationDestination?.uf}
                      </S.Title>
                    </S.CustomWrapper>
                  </S.CustomWrapper>
                </S.CustomWrapper>
              </S.CustomWrapper>
            </S.CustomWrapper>

            <S.CustomWrapper style={{ marginLeft: 17 }}>
              <S.Title>Veículo</S.Title>
              <S.CustomWrapper>
                <div>
                  <S.Title isBold>
                    {convertVehicleTypeTypeToName(idleness?.vehicleType)} -{' '}
                    {convertVehicleCategoryTypeToName(idleness?.category)}
                  </S.Title>
                </div>
              </S.CustomWrapper>
            </S.CustomWrapper>

            <S.CustomWrapper style={{ marginLeft: 17 }}>
              <S.Title>Prazo de Pagamento</S.Title>

              <S.CustomWrapper>
                <S.Title isBold withoutPadding>
                  {companyPaymentTerm} DIAS CORRIDOS
                </S.Title>
              </S.CustomWrapper>
            </S.CustomWrapper>
          </S.WrapperLocations>

          <S.WrapperScheduleDetails isTransportRouter={isTransportRouter}>
            <S.CustomWrapper>
              <S.Title>Detalhes do Frete</S.Title>
              <S.ScheduleDetails>
                <S.Label>
                  Peso: <S.ScheduleDetailsBold>{idleness?.offeredWeight} KG</S.ScheduleDetailsBold>
                </S.Label>
                <S.Label>
                  Cubagem: <S.ScheduleDetailsBold>{idleness?.offeredCubage} M³</S.ScheduleDetailsBold>
                </S.Label>
                <S.Label>
                  Paletizado: <S.ScheduleDetailsBold>{idleness?.paletizado}</S.ScheduleDetailsBold>
                </S.Label>{' '}
                <S.Label>
                  Pallets: <S.ScheduleDetailsBold>{idleness?.offeredPallet}</S.ScheduleDetailsBold>
                </S.Label>
                <S.Label>
                  Ajudante: <S.ScheduleDetailsBold>{idleness?.helper}</S.ScheduleDetailsBold>
                </S.Label>
                <S.Label>
                  Produto Predominante: <S.ScheduleDetailsBold>{idleness?.transportedCargo}</S.ScheduleDetailsBold>
                </S.Label>
              </S.ScheduleDetails>
            </S.CustomWrapper>

            <S.CustomWrapper>
              <S.Title>Freetime</S.Title>

              <S.ScheduleDetails>
                <S.Label>
                  Freetime: <S.ScheduleDetailsBold>{freeTime?.freeTime || '0'} HORAS</S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Diária:{' '}
                  <S.ScheduleDetailsBold>
                    {convertCurrencyFormat.format(freeTime?.dailyFine ?? 0)}
                  </S.ScheduleDetailsBold>
                </S.Label>

                <S.Label>
                  Hora Adicional:{' '}
                  <S.ScheduleDetailsBold>
                    {convertCurrencyFormat.format(freeTime?.hourlyFine ?? 0)}
                  </S.ScheduleDetailsBold>
                </S.Label>
              </S.ScheduleDetails>
            </S.CustomWrapper>
          </S.WrapperScheduleDetails>

          <CardProposedValueIdleness
            handleProposedValue={handleProposedValue}
            proposedValue={values.proposalValue}
            companyId={idleness?.companyId}
            originUf={locationOrigin?.uf}
            destinyUf={locationDestination?.uf}
            spotValue={spotValue}
            isTransportRouter={isTransportRouter}
          />
        </S.Main>

        <S.WrapperInput>
          <S.CheckboxSection>
            <InfoIcon sx={{ display: 'flex', width: '15px' }} />
            <S.TermsText>
              {isTransportRouter
                ? 'A proposta de venda será enviada para o embarcador responsável pela rota.'
                : 'Proposta válida por 30 dias a partir da data de envio desta oferta.'}
            </S.TermsText>
          </S.CheckboxSection>
          <Button
            bgColor="blue"
            title="Ofertar"
            size="medium"
            callback={handleSubmit}
            icon={
              <div style={{ marginLeft: 5 }}>
                <GavelIcon />
              </div>
            }
            disabled={disableButton}
          />
        </S.WrapperInput>
      </S.Content>
    </S.Wrapper>
  );
};
