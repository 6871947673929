import { useCallback } from 'react';

import { cargoDetailsService } from 'pages/freight/services/schedule-cargo-services/schedule-cargo-by-id/schedule-cargo-by-id.service';
import { ScheduleCargo } from 'domain/schedule';

const useCargoDetailsHook = () => {
  return useCallback(async (id: number): Promise<ScheduleCargo> => {
    return cargoDetailsService(id);
  }, []);
};

export { useCargoDetailsHook };
