import { includes } from 'lodash';
import { infiniteScrollReducerResetState } from './infinite-scroll-list-table-reducer.constants';
import {
  IInfiniteScrollActions,
  IInfiniteScrollListTableContext,
} from 'src-new/contexts/infinite-scroll-list-table/types/infinite-scroll-list-table.types';
import {
  InfiniteScrollActionType,
  InitialLoadingActionType,
} from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.action';
import { initialLoadingReducer } from 'src-new/contexts/infinite-scroll-list-table/reducers/initial-loading/initial-loading.reducer';
import { InitialLoadingActions } from 'src-new/contexts/infinite-scroll-list-table/types/initial-loading.types';

export const infiniteScrollListTableReducer = (
  state: IInfiniteScrollListTableContext,
  action: IInfiniteScrollActions,
): IInfiniteScrollListTableContext => {
  if (includes(Object.values(InitialLoadingActionType), action.type)) {
    return initialLoadingReducer(state, action as InitialLoadingActions);
  }

  if (action.type === InfiniteScrollActionType.INFINITE_SCROLL_RESET) {
    return infiniteScrollReducerResetState(state);
  }

  return state;
};
