import { RiskManagementSectionActionType } from '../../registrations.action';
import { IRegistrationsContext } from '../../types/registrations.types';
import { IRiskManagementSectionActions } from 'src-new/pages/registrations/contexts/registrations/types/risk-management-section.types';

export const riskManagementSectionReducer = (
  state: IRegistrationsContext,
  action: IRiskManagementSectionActions,
): IRegistrationsContext => {
  switch (action.type) {
    case RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_MODAL_OPEN:
      return {
        ...state,
        riskManagementSection: {
          ...state.riskManagementSection,
          modalProps: {
            ...state.riskManagementSection.modalProps,
            isOpenModal: true,
          },
        },
      };
    case RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_MODAL_CLOSE:
      return {
        ...state,
        riskManagementSection: {
          ...state.riskManagementSection,
          modalProps: {
            ...state.riskManagementSection.modalProps,
            isOpenModal: false,
          },
        },
      };
    case RiskManagementSectionActionType.RISK_MANAGEMENT_SECTION_RESET:
      return {
        ...state,
        riskManagementSection: {
          ...state.riskManagementSection,
          modalProps: {
            ...state.riskManagementSection.modalProps,
            isOpenModal: false,
          },
        },
      };
    default:
      return state;
  }
};
