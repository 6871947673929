import { useCallback, useReducer } from 'react';
import { contactDetailsSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/contact-details-section/contact-details-section.reducer';
import { ContactDetailsSectionActionType } from '../../registrations.action';
import { registrationsInitialState } from '../../registrations.constants';
import { IContactDetailsSection } from '../../types/contact-details-section.types';

export const useContactDetailsSectionContextValues = (): IContactDetailsSection => {
  const [state, dispatch] = useReducer(contactDetailsSectionReducer, registrationsInitialState);

  const setModalOpen = useCallback(() => {
    dispatch({ type: ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_MODAL_OPEN, payload: undefined });
  }, []);

  const setModalClose = useCallback(() => {
    dispatch({ type: ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_MODAL_CLOSE, payload: undefined });
  }, []);

  const setContactDetailsSectionReset = useCallback(() => {
    dispatch({ type: ContactDetailsSectionActionType.CONTACT_DETAILS_SECTION_RESET, payload: undefined });
  }, []);

  return {
    ...state.contactDetailsSection,
    setModalOpen,
    setModalClose,
    setContactDetailsSectionReset,
  };
};
