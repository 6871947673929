import { FC, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useGetClient } from 'src-new/hooks/use-get-client/use-get-client.hook';
import { useGetLocality } from 'src-new/hooks/use-get-locality/use-get-locality.hook';
import { OriginAndDestinyBackhaulPurchaseFormView } from './origin-and-destiny-backhaul-purchase-form-view.component';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';

interface IOriginAndDestinyBackhaulPurchaseFormProps {
  type: 'origin' | 'destination';
  hasError: boolean;
}
export const OriginAndDestinyBackhaulPurchaseForm: FC<IOriginAndDestinyBackhaulPurchaseFormProps> = ({
  type,
  hasError,
}) => {
  const { values, setFieldValue } = useFormikContext<IModalBackhaulPurchaseForm>();

  const getClientHook = useGetClient();
  const getLocalityHook = useGetLocality();

  const handleChange = useCallback((field: string, value: string) => setFieldValue(field, value), [setFieldValue]);

  const handleCompleteInputs = useCallback(
    async (id: number, label: string) => {
      if (values[type].type === '') return;

      setFieldValue(`${type}.name`, label);

      const details = await (values[type].type === 'CLIENT' ? getClientHook(id) : getLocalityHook(id));

      if (!details) return;

      setFieldValue(`${type}.id`, details.id);
    },
    [getClientHook, getLocalityHook, setFieldValue, type, values],
  );

  const typeKeyLabel = useMemo((): string => (type === 'origin' ? 'Origem *' : 'Destino *'), [type]);

  return (
    <OriginAndDestinyBackhaulPurchaseFormView
      type={type}
      typeKeyLabel={typeKeyLabel}
      hasError={hasError}
      values={values}
      handleChange={handleChange}
      handleCompleteInputs={handleCompleteInputs}
    />
  );
};
