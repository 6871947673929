import React, {FC, Fragment, useContext, useEffect, useState} from 'react';
import {ScheduleOfferModalMatchView} from './modal-match-view.component';
import {companyDetailsService} from 'services/company/details';
import {UserContext} from 'state/user-context';
import {ICompany} from 'domain/company';
import {ScheduleRegisterInput} from 'domain-v2/schedule/register';

interface IScheduleOfferModalMatchProps {
  scheduleValues: ScheduleRegisterInput;
  saleOffersMatches: Array<any>
  setModalBackhaulOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleMatchBuy: (backhaul: any) => void
}

export const ScheduleOfferModalMatch: FC<IScheduleOfferModalMatchProps> = ({
                                                                             scheduleValues,
                                                                             saleOffersMatches,
                                                                             setModalBackhaulOpen,
                                                                             handleMatchBuy
                                                                           }) => {
  const {user} = useContext(UserContext);
  const [company, setCompany] = useState<ICompany>();

  useEffect(() => {
    (async () => {
      if (user) {
        const findCompany = await companyDetailsService(user.companyId);
        setCompany(findCompany);
      }
    })();
  }, [user]);

  if (company) {
    return <ScheduleOfferModalMatchView
      scheduleValues={scheduleValues}
      saleOffersMatches={saleOffersMatches}
      company={company}
      handleMatchBuy={handleMatchBuy}
      handleCloseModalMatch={() => setModalBackhaulOpen(false)}/>;
  }

  return <Fragment/>;
};
