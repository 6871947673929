import React, { useCallback, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { InputFieldFormik } from 'src-new/components/formik/input-field/input-field-formik.component';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';
import { InputDateTimePikersFormik } from 'src-new/components/formik/input-date-time-pikers/input-date-time-pikers-formik.component';
import dayjs from 'dayjs';
import { InputSelectFormik } from 'src-new/components/formik/input-select/input-select-formik.component';
import { FormikErrors } from 'formik';
import { RiskManagementValues } from 'src-new/pages/registrations/pages/driver/domains/risk-management-values.domain';
import {
  DateTimeFieldContainerStyled,
  EmptySpinnerContainer,
  FieldContainerStyled,
  GroupFieldContainerStyled,
  InputSelectFieldContainerStyled,
  ModalContainerStyled,
  SaveButtonContainerStyled,
  SaveButtonStyled,
} from './risk-management-modal-view.styled';
import { riskManagementTypeOptions } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/components/risk-management-modal/risk-management-modal.constants';

interface IRiskManagementModalViewProps {
  riskManager?: IRiskManagementDomain;
  handleCloseModal: () => void;
  hasErrorField: (name: string) => boolean;
  getErrorMessage: (name: string) => string;
  position: number;
  handleSaveButton: (risk: IRiskManagementDomain) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputDateTimeChange: (field: string, date?: string) => void;
  handleInputSelectChange: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<RiskManagementValues>>;
}

export const RiskManagementModalView: React.FC<IRiskManagementModalViewProps> = ({
  riskManager,
  handleCloseModal,
  getErrorMessage,
  hasErrorField,
  position,
  handleSaveButton,
  handleChange,
  handleInputDateTimeChange,
  handleInputSelectChange,
}) => {
  const renderSaveButton = useCallback(
    (contact: IRiskManagementDomain) => {
      return (
        <SaveButtonContainerStyled>
          <SaveButtonStyled type={'button'} onClick={() => handleSaveButton(contact)}>
            Salvar
          </SaveButtonStyled>
        </SaveButtonContainerStyled>
      );
    },
    [handleSaveButton],
  );

  const renderContent = useMemo(() => {
    if (!riskManager) return <EmptySpinnerContainer />;

    return (
      <ModalContainerStyled>
        <GroupFieldContainerStyled>
          <FieldContainerStyled>
            <InputFieldFormik
              label="Gerenciadora"
              id={`riskManagers[${position}].gr`}
              name={`riskManagers[${position}].gr`}
              type="text"
              value={riskManager.gr}
              onChange={handleChange}
              hasError={hasErrorField(`riskManagers[${position}].gr`)}
              errorMessage={getErrorMessage(`riskManagers[${position}].gr`)}
              placeholder="Gerenciadora"
            />
          </FieldContainerStyled>
          <InputSelectFieldContainerStyled>
            <InputSelectFormik
              label="Status"
              widthContainer={'100%'}
              id={`riskManagers[${position}].status`}
              name={`riskManagers[${position}].status`}
              value={riskManager.status}
              setFieldValue={handleInputSelectChange}
              options={riskManagementTypeOptions}
              hasError={hasErrorField(`riskManagers[${position}].status`)}
              errorMessage={getErrorMessage(`riskManagers[${position}].status`)}
              placeholder="Status"
            />
          </InputSelectFieldContainerStyled>
        </GroupFieldContainerStyled>
        <GroupFieldContainerStyled secondaryField>
          <FieldContainerStyled>
            <InputFieldFormik
              label="Registro da Consulta"
              id={`riskManagers[${position}].consultationRecord`}
              name={`riskManagers[${position}].consultationRecord`}
              type="text"
              value={riskManager.consultationRecord}
              onChange={handleChange}
              hasError={hasErrorField(`riskManagers[${position}].consultationRecord`)}
              errorMessage={getErrorMessage(`riskManagers[${position}].consultationRecord`)}
              placeholder="Registro da Consulta"
            />
          </FieldContainerStyled>
          <FieldContainerStyled secondaryField>
            <InputFieldFormik
              label="Consultado por"
              id={`riskManagers[${position}].consultedBy`}
              name={`riskManagers[${position}].consultedBy`}
              type="text"
              value={riskManager.consultedBy}
              onChange={handleChange}
              hasError={hasErrorField(`riskManagers[${position}].consultedBy`)}
              errorMessage={getErrorMessage(`riskManagers[${position}].consultedBy`)}
              placeholder="Consultado por"
            />
          </FieldContainerStyled>
        </GroupFieldContainerStyled>
        <GroupFieldContainerStyled secondaryField>
          <DateTimeFieldContainerStyled>
            <InputDateTimePikersFormik
              label="Data da Consulta"
              format="DD/MM/YY HH:mm"
              onChange={(date) => handleInputDateTimeChange('registerDate', date?.toISOString())}
              value={riskManager.registerDate ? dayjs(riskManager.registerDate) : undefined}
              errorMessage={getErrorMessage(`riskManagers[${position}].registerDate`)}
              validateField={hasErrorField(`riskManagers[${position}].registerDate`)}
            />
          </DateTimeFieldContainerStyled>
          <DateTimeFieldContainerStyled>
            <InputDateTimePikersFormik
              label="Data de Validade"
              format="DD/MM/YY HH:mm"
              onChange={(date) => handleInputDateTimeChange('validateDate', date?.toISOString())}
              value={riskManager.validateDate ? dayjs(riskManager.validateDate) : undefined}
              errorMessage={getErrorMessage(`riskManagers[${position}].validateDate`)}
              validateField={hasErrorField(`riskManagers[${position}].validateDate`)}
            />
          </DateTimeFieldContainerStyled>
        </GroupFieldContainerStyled>
        {renderSaveButton(riskManager)}
      </ModalContainerStyled>
    );
  }, [
    getErrorMessage,
    handleChange,
    handleInputDateTimeChange,
    handleInputSelectChange,
    hasErrorField,
    position,
    renderSaveButton,
    riskManager,
  ]);

  return (
    <ModalBark size={'md'} title={'Gerenciamento de Risco'} handleClose={handleCloseModal}>
      {renderContent}
    </ModalBark>
  );
};
