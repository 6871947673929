import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { showToast } from 'components/toast/toast';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { deleteRecordsService } from 'src-new/pages/registrations/services/delete-records/delete-records.service';
import { ClientsContext } from '../../../../contexts/clients/clients.context';

interface IDeleteClientsProps {
  ids: Array<number>;
  setIdsToRemove: Dispatch<SetStateAction<number[]>>;
  setIsLoadingExclude: Dispatch<SetStateAction<boolean>>;
  setOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
}

export const useDeleteClients = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { clientsList } = useContext(ClientsContext);
  const { setClientsListReset } = clientsList;
  const { filters, setFilters } = clientsList.filterActions;
  const { setInitialLoadingReset } = initialLoading;

  return useCallback(
    async ({ ids, setIdsToRemove, setOpenConfirmModal, setIsLoadingExclude }: IDeleteClientsProps): Promise<void> => {
      setIsLoadingExclude(true);
      const hasList: boolean = ids.length > 1;

      try {
        await deleteRecordsService(ids, 'CLIENT');

        setIdsToRemove([]);
        setIsLoadingExclude(false);
        setOpenConfirmModal(false);
        setFilters({
          ...filters,
          page: 1,
        });
        setInitialLoadingReset();
        setClientsListReset();
        showToast({
          message: !hasList ? 'O parceiro foi excluído com sucesso.' : 'O parceiros foram excluídas com sucesso.',
          type: 'success',
        });
      } catch (error) {
        showToast({
          message: !hasList
            ? 'Erro ao tentar excluir o parceiro selecionado.'
            : 'Erro ao tentar excluir os parceiros selecionados.',
          type: 'error',
        });
      }
    },
    [filters, setFilters, setInitialLoadingReset, setClientsListReset],
  );
};
