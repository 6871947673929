/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';

import { ModalFormView } from './modal-form-view';

export type ModalFormProps = {
  title: any;
  description: ReactNode;
  onConfirmation?: () => void;
  handleCancel: () => void;
  overflow?: boolean;
  size: 'large' | 'medium' | 'small' | 'very-small';
  legacy?: boolean;
  buttonLabel?: string;
};

export const ModalForm: React.FC<ModalFormProps> = ({
  title,
  description,
  onConfirmation,
  handleCancel,
  overflow,
  size,
  legacy,
  buttonLabel,
}) => {
  return (
    <ModalFormView
      title={title}
      description={description}
      onConfirmation={onConfirmation}
      handleCancel={handleCancel}
      overflow={overflow}
      size={size}
      legacy={legacy}
      buttonLabel={buttonLabel}
    />
  );
};
