import { IResumeInfo } from '../../../components/resume/resume-info.types';

import { ShippingReturnEnum } from 'domain/global-inputs';
import { VehicleCategoryEnum, VehicleTypeEnum } from 'domain/global';

export const rulesResumeScheduleSTLForm: IResumeInfo = {
  principalHeader: 'Resumo do Frete',
  description: 'Operado por Logshare',
  sectionHeader: ['Origem', 'Destino', 'Detalhes da Carga', 'Detalhes do Frete'],
  sectionHeaderName: ['origin', 'destiny', 'cargoDetails', 'price'],
  excludeFields: ['clientId', 'localityId', 'shipmentNumber', 'password', 'cubage', 'capacity'],
  describes: [
    {
      name: 'operationType',
      title: 'Origem',
      firstValue: 'delivery',
      secondValue: 'delivery',
      oneColumns: true,
    },
  ],
  enumerables: [
    {
      field: 'localityType',
      values: [
        {
          name: 'Centro de Distribuição',
          value: 'distribution_center',
        },
        {
          name: 'Loja',
          value: 'shop',
        },
        {
          name: 'Hub',
          value: 'hub',
        },
        {
          name: 'Outros',
          value: 'others',
        },
      ],
    },
    {
      field: 'clientType',
      values: [
        {
          name: '',
          value: 'client',
        },
        {
          name: '',
          value: 'plant',
        },
        {
          name: '',
          value: 'new_client',
        },
      ],
    },

    {
      field: 'operationType',
      values: [
        { value: 'delivery', name: '' },
        { value: 'collect', name: '' },
        { value: 'transfer', name: '' },
        { value: 'displacement', name: '' },
        { value: 'devolution', name: '' },
      ],
    },
    {
      field: 'inPalet',
      values: [
        {
          name: 'Paletes',
          value: 'yes',
        },
        {
          name: 'Não Paletizado',
          value: 'no',
        },
      ],
    },
    {
      field: 'needBodyGuard',
      values: [
        {
          name: 'Com Escolta',
          value: 'yes',
        },
        {
          name: 'Sem Escolta',
          value: 'no',
        },
      ],
    },
    {
      field: 'needHelper',
      values: [
        {
          name: 'Ajudante - No Carregamento',
          value: 'carregamento',
        },
        {
          name: 'Ajudante - Na Descarga',
          value: 'descarga',
        },
        {
          name: 'Ajudante - Ambos',
          value: 'ambos',
        },
      ],
    },

    {
      field: 'vehicleType',
      values: [
        {
          value: VehicleTypeEnum.UTILITARIO,
          name: '',
        },
        { value: VehicleTypeEnum.VAN, name: '' },
        { value: VehicleTypeEnum.VUC, name: '' },
        { value: VehicleTypeEnum.TOCO, name: '' },
        { value: VehicleTypeEnum.TRUCK, name: '' },
        { value: VehicleTypeEnum.BITRUCK, name: '' },
        { value: VehicleTypeEnum.CARRETA, name: '' },
        { value: VehicleTypeEnum.RODOTREM, name: '' },
        { value: VehicleTypeEnum.TRES_QUARTO, name: '' },
      ],
    },
    {
      field: 'vehicleCategoryType',
      values: [
        {
          value: VehicleCategoryEnum.BAU_SECO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_FRIGORIFICADO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_REFRIGERADO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.BAU_SIDER,
          name: '',
        },
        {
          value: VehicleCategoryEnum.CACAMBA_BASCULANTE,
          name: '',
        },
        { value: VehicleCategoryEnum.CEGONHA, name: '' },
        {
          value: VehicleCategoryEnum.FLORESTAL,
          name: '',
        },
        {
          value: VehicleCategoryEnum.GRADE_BAIXA,
          name: '',
        },
        {
          value: VehicleCategoryEnum.GRANELEIRO,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PLATAFORMA,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PORTA_CONTAINER_20,
          name: '',
        },
        {
          value: VehicleCategoryEnum.PORTA_CONTAINER_40,
          name: '',
        },
        { value: VehicleCategoryEnum.TANQUE, name: '' },
      ],
    },
    {
      field: 'shippingReturn',
      values: [
        {
          value: ShippingReturnEnum.RETORNA_VAZIO,
          name: 'Retorna Vazio',
        },
        {
          value: ShippingReturnEnum.RETORNA_CARREGADO,
          name: 'Retorna Carregado',
        },
        { value: ShippingReturnEnum.NAO_RETORNA, name: 'Não Retorna' },
      ],
    },
  ],

  rules: [
    {
      fields: ['street', 'number', 'neighborhood', 'city', 'state', 'zipCode'],
      value: '{street}, {number} - {neighborhood} - {city} - {state} - {zipCode}',
      ruleIndex: 0,
    },
    {
      fields: ['pickUpDate'],
      value: 'Coleta - {pickUpDate}',
      ruleIndex: 2,
      moreThanOneColumns: true,
    },
    {
      fields: ['deliveryDate'],
      value: 'Entrega - {deliveryDate}',
      ruleIndex: 3,
      moreThanOneColumns: true,
    },
    {
      fields: ['internalNumber'],
      value: '',
      ruleIndex: 3,
    },
    {
      fields: ['ocInternalNumber'],
      value: '',
      ruleIndex: 4,
    },
    {
      fields: ['boardingNumber', 'schedulingPassword'],
      value: '',
      ruleIndex: 4,
    },
    {
      fields: ['weight'],
      value: 'Peso  -  {weight} Kg',
      ruleIndex: 5,
      format: 'decimal',
    },
    {
      fields: ['inPalet', 'pallet'],
      value: '{pallet} {inPalet}',
      ruleIndex: 6,
    },
    {
      fields: ['shippingValue'],
      value: 'Valor da Mercadoria - {shippingValue}',
      ruleIndex: 2,
      format: 'price',
    },
    {
      fields: ['invoice'],
      value: 'Produto Predominante -  {invoice}',
      ruleIndex: 4,
    },
    {
      fields: ['orderNumber'],
      value: 'Pedidos - {orderNumber}',
      ruleIndex: 4,
    },

    {
      fields: ['distance'],
      moreThanOneColumns: true,
      value: 'Distancia: {distance} Km',
      ruleIndex: 2,
      format: 'decimal',
    },
    {
      fields: ['value'],
      moreThanOneColumns: true,
      value: 'Frete Valor: {value}',
      ruleIndex: 2,
      format: 'price',
    },
    {
      fields: ['helperPrice'],
      moreThanOneColumns: true,
      value: 'Ajudante: {helperPrice}',
      ruleIndex: 2,
      format: 'price',
    },
    {
      fields: ['tollPrice'],
      moreThanOneColumns: true,
      value: 'Pedágio: {tollPrice}',
      ruleIndex: 3,
      format: 'price',
    },
    {
      fields: ['grisAdvaloremPrice'],
      moreThanOneColumns: true,
      value: 'Gris/Advalorem: {grisAdvaloremPrice}',
      ruleIndex: 4,
      format: 'price',
    },
    {
      fields: ['taxPrice'],
      moreThanOneColumns: true,
      value: 'Imposto Estimado: {taxPrice}',
      ruleIndex: 5,
      format: 'price',
    },
    {
      fields: ['total'],
      moreThanOneColumns: true,
      value: 'Frete Total: {total}',
      ruleIndex: 6,
      format: 'price',
    },
  ],
};
