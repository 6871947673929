import { SaleOfferModalsActionType } from '../../sale-offer.action';
import { IPayloadModalIndex, ISaleOfferModalsActions } from '../../types/sale-offer-modals.types';
import { ISaleOfferContext } from '../../types/sale-offer.types';

const saleOfferModalsIndexUndefined: IPayloadModalIndex = {
  saleOfferShipper: undefined,
  saleOfferShipperTariff: undefined,
};

export const saleOfferModalsReducer = (
  state: ISaleOfferContext,
  action: ISaleOfferModalsActions,
): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferModalsActionType.SALE_OFFER_MODAL_OPEN:
      return {
        ...state,
        saleOfferModals: {
          ...state.saleOfferModals,
          indexSection: action.payload.indexes,
          isOpen: true,
          modalType: action.payload.modalType,
          secondModalType: action.payload.secondModalType,
        },
      };
    case SaleOfferModalsActionType.SALE_OFFER_MODAL_CLOSE:
      return {
        ...state,
        saleOfferModals: {
          ...state.saleOfferModals,
          indexSection: saleOfferModalsIndexUndefined,
          isOpen: false,
          modalType: '',
          secondModalType: '',
        },
      };
    case SaleOfferModalsActionType.SALE_OFFER_MODAL_SECONDARY_CLOSE:
      return {
        ...state,
        saleOfferModals: {
          ...state.saleOfferModals,
          indexSection: saleOfferModalsIndexUndefined,
          isOpen: true,
          secondModalType: '',
        },
      };
    default:
      return state;
  }
};
