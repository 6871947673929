import styled, { css } from 'styled-components';

export const ModalFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 10px;
  `}
`;
