import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ISaleOfferFormRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { SaleOfferFormMountTariffView } from './sale-offer-register-form-mount-tariff-view.component';
import { UserContext } from 'state/user-context';
import { getCompanyService } from 'src-new/services/get-company/get-company.service';

interface ISaleOfferFormMountTariffProps {
  saleOfferShipperIndex: number;
}

export const SaleOfferFormMountTariff: FC<ISaleOfferFormMountTariffProps> = ({ saleOfferShipperIndex }) => {
  const { user } = useContext(UserContext);
  const { values, setFieldValue } = useFormikContext<ISaleOfferFormRegisterPayloadDomain>();
  const { saleOfferDetails } = values;

  const [companyName, setCompanyName] = useState<string>('');

  const getSaleOfferTariffs = useMemo(() => {
    return saleOfferDetails[saleOfferShipperIndex].tariff;
  }, [saleOfferDetails, saleOfferShipperIndex]);

  const handleExcludeTariffSection = useCallback(
    (tariffIndex: number) => {
      const tariffs = saleOfferDetails[saleOfferShipperIndex].tariff;
      const findTariff = saleOfferDetails[saleOfferShipperIndex].tariff[tariffIndex];
      const removeTariff = tariffs.filter((tariff) => tariff !== findTariff);

      setFieldValue(`saleOfferDetails[${saleOfferShipperIndex}].tariff`, removeTariff);
    },
    [saleOfferDetails, saleOfferShipperIndex, setFieldValue],
  );

  useEffect(() => {
    (async () => {
      if (!user) return;

      const company = await getCompanyService(user.companyId);

      setCompanyName(company.tradeName);
    })();
  }, [user]);

  return (
    <SaleOfferFormMountTariffView
      values={getSaleOfferTariffs}
      companyName={companyName}
      handleExcludeTariffSection={handleExcludeTariffSection}
    />
  );
};
