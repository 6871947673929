import React, { useCallback, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { ICardProps } from './types';
import {
  CardDetailsOnLeftStyled,
  ContainerCardDetailsStyled,
  ContainerDateStyled,
  ContainerStyled,
  SubNameStyled,
  TariffCompanyStyled,
  TextTariffStyled,
} from './styled';

import TooltipComponent from 'components/Tooltip';

interface IProps {
  cards: ICardProps[];
}

const Card: React.FC<IProps> = ({ cards }) => {
  const changeCheck = useCallback((onClickAction: () => void = () => {}) => {
    onClickAction();
  }, []);

  const renderCardInfos = useMemo(() => {
    const mapping = cards.map((card, index) => {
      if (card.isLoading) {
        return (
          <div key={card.id}>
            <Skeleton variant="rectangular" height={70} width={240} sx={{ borderRadius: '4px' }} />
          </div>
        );
      }

      return (
        <CardDetailsOnLeftStyled
          key={card.id + index}
          checked={card.checked}
          onClick={!card.disabled ? () => changeCheck(card.onClickAction) : undefined}
          disabled={card.disabled}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <TariffCompanyStyled>
              <TextTariffStyled>
                Frete
                <SubNameStyled> {card.name}</SubNameStyled>
              </TextTariffStyled>
              <div style={{ display: 'flex', gap: '5px' }}>
                {card.icon}
                {card.tooltipInfo && (
                  <TooltipComponent
                    title={card.tooltipInfo}
                    location="top"
                    icon={
                      <div style={{ display: 'flex' }}>
                        <InfoIcon
                          sx={{
                            height: '15px',
                            width: '15px',
                            color: card.checked ? 'white' : 'gray',
                          }}
                        />
                      </div>
                    }
                  />
                )}
              </div>
            </TariffCompanyStyled>
            <ContainerDateStyled>
              {card.dateOf && <TextTariffStyled>{card.dateOf}</TextTariffStyled>}
              <TextTariffStyled>{card.tariffValue}</TextTariffStyled>
            </ContainerDateStyled>
          </div>
        </CardDetailsOnLeftStyled>
      );
    });

    return mapping;
  }, [cards, changeCheck]);

  return (
    <ContainerStyled>
      <ContainerCardDetailsStyled>{renderCardInfos}</ContainerCardDetailsStyled>
    </ContainerStyled>
  );
};

export default Card;
