import { IPageHeaderFilterContext } from './types/page-header-filter.types';

export const pageHeaderFilterInitialState = <T>(initialState: T): IPageHeaderFilterContext<T> => {
  return {
    modalProps: {
      isOpen: false,
      setOpenModal: () => undefined,
      setCloseModal: () => undefined,
    },
    filters: initialState,
    setFilters: () => undefined,
    setPageHeaderFilterReset: () => undefined,
  };
};
