import { Dispatch, FC, SetStateAction, memo, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import * as S from './styled';
import { requirementsValues } from './constants';

import { MatchRegisterInput } from 'domain-v2/match/register';
import CheckboxTag from 'components-v2/common/checkbox-tag';

interface ISecurityRequirementsProps {
  setRequirements: Dispatch<SetStateAction<string[] | undefined>>;
}

const SecurityRequirementsForm: FC<ISecurityRequirementsProps> = ({ setRequirements }) => {
  const { values, setFieldValue } = useFormikContext<MatchRegisterInput>();

  const [requirementsIndex, setRequirementsIndex] = useState<number>(0);

  const { cargo } = values;

  useEffect(() => {
    const qualityRequirements = values.cargo.requirements.quality?.values ?? [];
    const securityRequirements = values.cargo.requirements.security?.values ?? [];

    const combinedRequirements = [...qualityRequirements, ...securityRequirements].filter(Boolean);

    setRequirements(combinedRequirements);
  }, [values.cargo.requirements]);

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentValues: string[],
    value: {
      label: string;
      type: 'quality' | 'security';
    },
  ) => {
    const isChecked = event.target.checked;
    setFieldValue(
      `cargo.requirements[${value.type}].values`,
      isChecked && cargo.requirements[value.type]
        ? [...currentValues, value.label]
        : currentValues.filter((v) => v !== value.label),
    );
  };

  const prevTags = () => {
    setRequirementsIndex((prevState) => (prevState !== 0 ? prevState - 1 : 0));
  };

  const nextTags = () => {
    setRequirementsIndex((prevState) => (prevState === requirementsValues.length - 1 ? prevState : prevState + 1));
  };

  const renderRequirementsValue = useMemo(() => {
    return requirementsValues[requirementsIndex].tags.map((value, index) => {
      const valuesArray = cargo.requirements[value.type]?.values || [];

      const currentValues = Array.isArray(valuesArray) ? valuesArray : [];

      return (
        <CheckboxTag
          key={value.label}
          id={`cargo.requirements[${value.type}].values.${index}`}
          name={`cargo.requirements[${value.type}].values.${index}`}
          label={value.label}
          bgColor="blue"
          onChange={(event) => handleCheck(event, currentValues, value)}
          value=""
          checked={currentValues.includes(value.label)}
        />
      );
    });
  }, [requirementsValues, requirementsIndex, cargo.requirements]);

  return (
    <S.WrapperRequirements>
      <S.Requirement columns={requirementsValues[requirementsIndex].columns}>
        <S.ArrowButton disabled={requirementsIndex === 0}>
          <ArrowBackIosIcon onClick={prevTags} />
        </S.ArrowButton>

        {renderRequirementsValue}

        <S.ArrowButton disabled={requirementsIndex === 2}>
          <ArrowForwardIosIcon onClick={nextTags} />
        </S.ArrowButton>
      </S.Requirement>
    </S.WrapperRequirements>
  );
};

export default memo(SecurityRequirementsForm);
