import React from 'react';

import VehiclesContextProvider from '../contexts/table-states/vehicles.context';

import { VehicleListBody } from './vehicle-list-body/vehicle-list-body';

const VehiclePage: React.FC = () => {
  return (
    <VehiclesContextProvider>
      <VehicleListBody />
    </VehiclesContextProvider>
  );
};

export default VehiclePage;
