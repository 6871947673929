import API from 'utils/API/API';
import { SelectItem } from 'domain/select-item';
import { convertEntityToSelectItem } from 'utils/convert-entity-to-select';

export const analystSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get('v2/users', {
    params: {
      column: 'name',
      currentPage: 1,
      excluded: '',
      order: 'asc',
      search: searchValue,
      status: 'active',
      limit: 200,
    },
  });
  const { items } = data;

  // const { data } = await API.get(`shipping-company-name?name=${searchValue}`);
  return items.map((shipping: { name: string; id: number }) =>
    convertEntityToSelectItem(shipping.name.toUpperCase(), shipping.id),
  );
};
