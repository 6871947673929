import styled, { css } from 'styled-components';

export const THeadStyled = styled.thead`
  ${({ theme }) => css`
    align-self: stretch;
    text-align: left;
    margin-right: 5px;
    border-bottom: ${theme.table.header.row.borderBottom};
    margin-right: 5px;
  `}
`;

type TRowStyledProps = { columns: string };
export const TRowStyled = styled.tr<TRowStyledProps>`
  ${({ theme, columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: ${theme.table.row.gridGap};
    padding: ${theme.table.header.row.padding};
  `}
`;

interface IHeaderTableProps {
  cursorPointer?: boolean;
  columnFontBold?: boolean;
}

export const HeaderTable = styled.div<IHeaderTableProps>`
  ${({ cursorPointer }) => css`
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: ${cursorPointer ? 'pointer' : 'default'};
  `}
`;

export const ThStyled = styled.th<IHeaderTableProps>`
  ${({ theme, columnFontBold }) => css`
    font-family: ${theme.table.font.family};
    font-size: ${theme.table.font.sizes.header};
    text-transform: none;
    font-weight: ${columnFontBold ? '600px' : theme.table.font.lineWeight};
    line-height: ${theme.table.font.lineHeight};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const TableOrdering = styled.div`
  display: flow-root;
  flex-direction: column;
  margin-left: 5px;
`;
