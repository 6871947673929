import { useCallback } from 'react';
import { ISurplusValueDomain } from 'src-new/domains/surplus-value.domain';
import { getSurplusValueService } from 'src-new/services/get-surplus-value/get-surplus-value.service';
import { ISurplusValueParams } from 'src-new/services/get-surplus-value/get-surplus-value.types';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetSurplusValue = () => {
  return useCallback(async (params: ISurplusValueParams): Promise<ISurplusValueDomain | undefined> => {
    try {
      return await getSurplusValueService(params);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter tempo excedente.',
        type: 'error',
      });
    }
  }, []);
};
