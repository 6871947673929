import styled, { css } from 'styled-components';

interface IStyledProps {
  fontSize?: number;
  textAlign?: string;
  fontWeight?: number;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
`;

export const InfoStyled = styled.p<IStyledProps>`
  ${({ fontSize, textAlign, fontWeight }) => css`
    font-size: ${fontSize ?? 10}px;
    text-align: ${textAlign ?? 'center'};
    font-weight: ${fontWeight};
    color: #000;
  `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.forBorder};
  `}
  margin: 20px 0;
  width: 120%;
`;

export const InputStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 120%;
  gap: 10px;
`;
