import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { FreeTime } from 'domain-v2/free-time';
import { convertAndFormatDecimalNumber } from 'src-new/utils/convert-decimal-format.util';

interface CardResumeValuesProps {
  retentionFinesValues: FreeTime;
  operationIsTracking?: boolean;
}

export const CardResumeFines: React.FC<CardResumeValuesProps> = ({ retentionFinesValues, operationIsTracking }) => {
  const [freeTime, setFreeTime] = useState<FreeTime>();

  useEffect(() => {
    setFreeTime(retentionFinesValues);
  }, [retentionFinesValues]);

  const renderRetentionFinesValues = React.useMemo(() => {
    if (freeTime?.freeTime && !operationIsTracking) {
      return (
        <>
          <S.WrapperJustifyInformation>
            <S.Text uppercase>FreeTime</S.Text>
            <S.Text uppercase>{`${freeTime.freeTime ?? '0 Horas'}`}</S.Text>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text uppercase>Hora Adicional</S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertAndFormatDecimalNumber(freeTime.hourlyFine ?? 0)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>

          <S.WrapperJustifyInformation>
            <S.Text uppercase>Diária</S.Text>
            <S.ContainerCurrency>
              <S.Text>R$</S.Text>
              <S.Text>{`${convertAndFormatDecimalNumber(freeTime.dailyFine ?? 0)}`}</S.Text>
            </S.ContainerCurrency>
          </S.WrapperJustifyInformation>
        </>
      );
    }
    return (
      <S.Text italic uppercase>
        Não Informado
      </S.Text>
    );
  }, [freeTime, operationIsTracking]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 3.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.Text fontWeight uppercase>
          Políticas
        </S.Text>

        <S.TextContent>
          <S.Text fontWeight uppercase padding="0 0 0 0">
            FreeTime e Diárias
          </S.Text>

          {renderRetentionFinesValues}
        </S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
