import styled, { css } from 'styled-components';

import { KanbanTitleProps } from './kanban-title';

type WrapperProps = Pick<KanbanTitleProps, 'color'>;

export const WrapperStyled = styled.div<WrapperProps>`
  ${({ theme, color }) => css`
    color: ${theme.colors[color]};
    font-size: 13px;
    font-weight: ${theme.font.semiBold};
    cursor: default;
    margin-bottom: 5px;
    margin-top: -10px;
  `}
`;
