import { createContext, useMemo, useReducer } from 'react';
import { IWeighingTottensContextProps } from './weighing-tottens.types';
import { IWeighingTottensContext } from './types/weighing-tottens.types';
import { weighingTottensInitialState } from './weighing-tottens.constants';
import { IWeighingTottensDetails } from './types/weighing-tottens-details.types';
import { useWeighingTottensDetailsContextValues } from './context-values/weighing-tottens-details/weighing-tottens-details-values.context';
import { weighingTottensReducer } from './reducers/weighing.reducer';

export const WeighingTottensContext = createContext<IWeighingTottensContext>(weighingTottensInitialState);

export const WeighingTottensContextProvider = ({ children }: IWeighingTottensContextProps) => {
  const [state] = useReducer(weighingTottensReducer, weighingTottensInitialState);

  const weighingTottensDetails: IWeighingTottensDetails = useWeighingTottensDetailsContextValues();

  const value: IWeighingTottensContext = useMemo(
    (): IWeighingTottensContext => ({
      ...state,
      weighingTottensDetails,
    }),
    [state, weighingTottensDetails],
  );

  return <WeighingTottensContext.Provider value={value}>{children}</WeighingTottensContext.Provider>;
};
