/* eslint-disable @typescript-eslint/no-explicit-any */
import { IOccurrenceTrackingEntityCreated } from 'pages/tracking-occurrence/services/entities/tracking-occurrence.entity';
import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';

export const convertTrackingOccurrenceCreatedEntityToDomainMapper = (
  trackingOccurrenceEntity: IOccurrenceTrackingEntityCreated,
): ITrackingOccurrenceCreated => ({
  id: trackingOccurrenceEntity.id,
  occurrenceTypeName: trackingOccurrenceEntity.occurrenceTypeName,
  occurrenceDate: trackingOccurrenceEntity.occurrenceDate,
  occurrenceTime: trackingOccurrenceEntity.occurrenceTime,
  occurrenceType: trackingOccurrenceEntity.occurrenceType as any,
  occurrenceTypeId: trackingOccurrenceEntity.occurrenceTypeId,
  occurrenceFiles: trackingOccurrenceEntity.occurrenceFiles,
  occurrenceImagesUrl: trackingOccurrenceEntity.occurrenceImagesUrl,
  statusResolution: trackingOccurrenceEntity.statusResolution as any,
  processType: trackingOccurrenceEntity.processType,
  step: trackingOccurrenceEntity.step,
  osNumber: trackingOccurrenceEntity.osNumber,
  ticketNumber: trackingOccurrenceEntity.ticketNumber,
  clientId: trackingOccurrenceEntity.clientId,
  client: trackingOccurrenceEntity.client,
  localityId: trackingOccurrenceEntity.localityId,
  locality: trackingOccurrenceEntity.locality,

  cnpj: trackingOccurrenceEntity.cnpj,
  endDate: trackingOccurrenceEntity.endDate,
  endTime: trackingOccurrenceEntity.endTime,
  resolutionTime: trackingOccurrenceEntity.resolutionTime,
  sla: trackingOccurrenceEntity.sla,
  occurrenceResolutions: trackingOccurrenceEntity.occurrenceResolutions,
  scheduleId: trackingOccurrenceEntity.scheduleId,
  companyId: trackingOccurrenceEntity.companyId,
});
