import styled from 'styled-components';

export const ContainerPaginationStyled = styled.div<{ notAbsolute?: boolean }>`
  position: ${({ notAbsolute }) => (notAbsolute ? 'inherit' : 'absolute')};
  bottom: 32.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerTableStyled = styled.div``;
