export const convertVehicleTypeEntityToLabel = (vehicleType: string): string => {
  switch (vehicleType) {
    case '':
      return '';
    case 'utilitario':
      return 'Utilitário';
    case 'van':
      return 'Van';
    case 'vuc':
      return 'Vuc';
    case '3/4':
      return '3/4';
    case 'toco':
      return 'Toco';
    case 'truck':
      return 'Truck';
    case 'bitruck':
      return 'Bitruck';
    case 'carreta':
      return 'Carreta';
    case 'bitrem':
      return 'Bitrem';
    case 'rodotrem':
      return 'Rodotrem';
    case 'romeu&julieta':
      return 'Romeu & Julieta';
    case 'romeu_julieta':
      return 'Romeu & Julieta';
    case 'vanderleia':
      return 'Vanderleia';
    case 'carreta_ls':
      return 'Carreta LS';
    case 'complemento':
      return 'Complemento';
    default:
      return '-';
  }
};
