export const formatCelphoneNumberDomainToEntity = (firstPhone: string): string => {
  if (firstPhone) {
    if (firstPhone.replace(/\D/g, '').length <= 2) return `${firstPhone.replace(/\D/g, '')}`;
    return `+55${firstPhone.replace(/\D/g, '')}`;
  }
  return '';
};

export const formatCelphoneNumberEntityToDomain = (firstPhone: string): string => {
  if (firstPhone) {
    if (firstPhone.replace(/\D/g, '').length <= 2) return '';
    if (firstPhone.replace(/\D/g, '').length <= 11)
      return firstPhone.replace(/\D/g, '').replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
    return firstPhone.replace(/\D/g, '').replace(/^(\d{2})(\d{2})(\d{5})(\d)/, '+$1 ($2) $3-$4');
  }
  return '';
};
