import * as Yup from 'yup';

export const RegisterCompanySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(100, 'O Campo Deve Ter 100 Caracteres!')
    .required('Campo Obrigatório'),

  email: Yup.string().email('Email Inválido!').required('Campo Obrigatório'),

  tradeName: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(100, 'O Campo Deve Ter 100 Caracteres!')
    .required('Campo Obrigatório'),

  cnpj: Yup.string()
    .min(18, 'Formatação errada!')
    .max(18, 'O Campo Deve Ter 18 Caracteres!')
    .required('Campo Obrigatório'),

  login: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(50, 'O Campo Deve Ter 50 Caracteres!')
    .required('Campo Obrigatório'),

  plan: Yup.string().required('Campo Obrigatório'),

  type: Yup.string().required('Campo Obrigatório'),

  cep: Yup.string().required('Campo Obrigatório'),
  city: Yup.string().required('Campo Obrigatório'),
  uf: Yup.string().required('Campo Obrigatório'),
  neighborhood: Yup.string().required('Campo Obrigatório'),
  street: Yup.string().required('Campo Obrigatório'),
  numberHouse: Yup.string().required('Campo Obrigatório'),

  feeLogshare: Yup.string().required('Campo Obrigatório'),
  receiptDeadline: Yup.string().required('Campo Obrigatório'),
  modalityDeadline: Yup.string().required('Campo Obrigatório'),
  closingCycle: Yup.string().required('Campo Obrigatório'),
  gris: Yup.string().required('Campo Obrigatório'),
  advalorem: Yup.string().required('Campo Obrigatório'),
});
