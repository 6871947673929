import React, { createContext, ReactNode } from 'react';
import { IPageHeaderFilterContext } from './types/page-header-filter.types';
import { usePageHeaderFilterContextValues } from 'src-new/contexts/page-header-filter/context-values/page-header-filter-context.values';

export const PageHeaderFilterContext = createContext<IPageHeaderFilterContext<any> | undefined>(undefined);

interface PageHeaderFilterProviderProps<T> {
  children: ReactNode;
  initialFilters: T;
}

export const PageHeaderFilterContextProvider = <T,>({ children, initialFilters }: PageHeaderFilterProviderProps<T>) => {
  const value: IPageHeaderFilterContext<T> = usePageHeaderFilterContextValues<T>(initialFilters);

  return <PageHeaderFilterContext.Provider value={value}>{children}</PageHeaderFilterContext.Provider>;
};
