import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { IScheduleKanbanList } from '../../types/schedule-kanban.types';
import { scheduleKanbanListReducer } from '../../reducers/schedule-kanban-list/schedule-kanban-list.reducer';
import { ScheduleKanbanListActionType } from '../../schedule.action';
import { IScheduleKanbanListDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-kanban-list/schedule-kanban.domain';

export const useScheduleKanbanListContextValues = (): IScheduleKanbanList => {
  const [state, dispatch] = useReducer(scheduleKanbanListReducer, scheduleInitialState);

  const setScheduleKanbanListRequest = useCallback(() => {
    dispatch({ type: ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_REQUEST, payload: undefined });
  }, []);

  const setScheduleKanbanListSuccess = useCallback((kanban: IScheduleKanbanListDomain) => {
    dispatch({ type: ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_SUCCESS, payload: kanban });
  }, []);

  const setScheduleKanbanListError = useCallback(() => {
    dispatch({ type: ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_ERROR, payload: undefined });
  }, []);

  const setScheduleKanbanListReset = useCallback(() => {
    dispatch({ type: ScheduleKanbanListActionType.SCHEDULE_KANBAN_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleKanbanList,
    setScheduleKanbanListRequest,
    setScheduleKanbanListSuccess,
    setScheduleKanbanListError,
    setScheduleKanbanListReset,
  };
};
