import * as Yup from 'yup';

const tariffSchema = Yup.object().shape({
  shippingId: Yup.number().required('ID do transporte é obrigatório'),
  shippingName: Yup.string().required('Nome do transporte é obrigatório'),
  vehicleType: Yup.string().required('Tipo de veículo é obrigatório'),
  vehicleCategory: Yup.string().required('Categoria do veículo é obrigatória'),
  weight: Yup.number().required('Peso é obrigatório'),
  pallets: Yup.number().required('Quantidade de pallets é obrigatória'),
  cubage: Yup.number().required('Cubagem é obrigatória'),
  frequency: Yup.number().required('Frequência é obrigatória'),
  invoicedBy: Yup.string().required('Faturado por é obrigatório'),
  gris: Yup.number().required('GRIS é obrigatório'),
  advalorem: Yup.number().required('Ad valorem é obrigatório'),
  valueShipping: Yup.number().required('Valor do transporte é obrigatório'),
  valueShipper: Yup.number().required('Valor do embarcador é obrigatório'),
  valueLogshare: Yup.number().required('Valor do logshare é obrigatório'),
  valueTotal: Yup.number().required('Valor total é obrigatório'),
  freetime: Yup.object().shape({
    time: Yup.number().required('Tempo é obrigatório'),
    dailyFine: Yup.number().required('Multa diária é obrigatória'),
    hourlyFine: Yup.number().required('Multa por hora é obrigatória'),
  }),
  isActive: Yup.boolean().required('Status ativo é obrigatório'),
});

export const saleOfferShipperSchema = Yup.object().shape({
  initialDate: Yup.string().required('Data inicial é obrigatória'),
  finalDate: Yup.string().required('Data final é obrigatória'),
  tariff: Yup.array().of(tariffSchema).min(1, 'Pelo menos um item na tarifa é obrigatório'),
});

export const RegisterSaleOfferValidation = Yup.object().shape({
  cargoType: Yup.string().required('Campo Obrigatório'),
  origin: Yup.object().shape({
    type: Yup.string().required('Campo Obrigatório'),
    name: Yup.string().required('Campo Obrigatório'),
  }),
  destination: Yup.object().shape({
    type: Yup.string().required('Campo Obrigatório'),
    name: Yup.string().required('Campo Obrigatório'),
  }),
});
