import { createContext, useMemo, useReducer } from 'react';
import { IScheduleRefusedContextProps } from './schedule-refused.types';
import { scheduleRefusedInitialState } from './schedule-refused.constants';
import { useScheduleRefusedListContextValues } from './context-values/schedule-refused-list/schedule-refused-list-values.context';
import { IScheduleRefusedList } from './types/schedule-refused-list.types';
import { IScheduleRefusedContext } from './types/schedule-refused.types';
import { scheduleRefusedReducer } from './reducers/schedule-refused.reducer';

export const ScheduleRefusedContext = createContext<IScheduleRefusedContext>(scheduleRefusedInitialState);

export const ScheduleRefusedContextProvider = ({ children }: IScheduleRefusedContextProps) => {
  const [state] = useReducer(scheduleRefusedReducer, scheduleRefusedInitialState);

  const scheduleRefusedList: IScheduleRefusedList = useScheduleRefusedListContextValues();

  const value: IScheduleRefusedContext = useMemo(
    (): IScheduleRefusedContext => ({
      ...state,
      scheduleRefusedList,
    }),
    [scheduleRefusedList, state],
  );

  return <ScheduleRefusedContext.Provider value={value}>{children}</ScheduleRefusedContext.Provider>;
};
