import { Moment } from 'moment';

import API from 'utils/API/API';
import { PageInfoResponse } from 'domain-v2/inputs/page-info';
import { IdlenessBiddingsList } from 'domain-v2/idleness-bidding/list';

export interface IdlenessBiddingsFilter {
  page: number;
  limit: number;
  search: string;
  column: string;
  order: string;

  operationType: string;
  initialDate: Moment | null;
  originCity: string;
  destinationCity: string;
  vehicleType: string;
  vehicleCategory: string;
}

export const getIdlenessBiddingsList = async (
  filters: IdlenessBiddingsFilter,
): Promise<PageInfoResponse<IdlenessBiddingsList>> => {
  const { data } = await API.get<PageInfoResponse<IdlenessBiddingsList>>('v3/idleness/bidding', {
    params: { ...filters, limit: 20 },
  });

  return data;
};
