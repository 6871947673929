import { useCallback, useReducer } from 'react';
import { scheduleRefusedInitialState } from '../../schedule-refused.constants';
import { ScheduleRefusedListActionType } from '../../schedule-refused.action';
import { IScheduleRefusedList, ScheduleRefusedListModals } from '../../types/schedule-refused-list.types';
import { scheduleRefusedListReducer } from '../../reducers/schedule-refused-list/schedule-refused-list.reducer';
import { IScheduleRefusedListDomain } from 'src-new/pages/execution/pages/schedule-refused/pages/schedule-refused-list/domains/schedule-refused-list/schedule-refused-list.domain';
import { IScheduleRefusedListParams } from 'src-new/pages/execution/pages/schedule-refused/types/schedule-refused-list-filters/schedule-refused-list-filters.types';

export const useScheduleRefusedListContextValues = (): IScheduleRefusedList => {
  const [state, dispatch] = useReducer(scheduleRefusedListReducer, scheduleRefusedInitialState);

  const setScheduleRefusedListRequest = useCallback(() => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_REQUEST, payload: undefined });
  }, []);

  const setScheduleRefusedListSuccess = useCallback((list: Array<IScheduleRefusedListDomain>) => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_SUCCESS, payload: list });
  }, []);

  const setScheduleRefusedListError = useCallback(() => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_ERROR, payload: undefined });
  }, []);

  const setScheduleRefusedFilters = useCallback((filters: IScheduleRefusedListParams) => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_FILTERS, payload: filters });
  }, []);

  const setScheduleRefusedModalOpen = useCallback((options: ScheduleRefusedListModals) => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_MODALS, payload: options });
  }, []);

  const setScheduleRefusedListReset = useCallback(() => {
    dispatch({ type: ScheduleRefusedListActionType.SCHEDULE_REFUSED_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleRefusedList,
    setScheduleRefusedListRequest,
    setScheduleRefusedListSuccess,
    setScheduleRefusedListError,
    setScheduleRefusedListReset,
    scheduleRefusedListFilters: {
      ...state.scheduleRefusedList.scheduleRefusedListFilters,
      setScheduleRefusedFilters,
    },
    scheduleRefusedListModals: {
      ...state.scheduleRefusedList.scheduleRefusedListModals,
      setScheduleRefusedModalOpen,
    },
  };
};
