import { useCallback, useReducer } from 'react';
import { ISaleOfferDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { saleOfferDetailsReducer } from '../../reducers/sale-offer-details/sale-offer-details.reducer';
import { SaleOfferDetailsActionType } from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import { ISaleOfferDetails } from '../../types/sale-offer-details.types';

export const useSaleOfferDetailsContextValues = (): ISaleOfferDetails => {
  const [state, dispatch] = useReducer(saleOfferDetailsReducer, saleOfferInitialState);

  const setSaleOfferDetailsRequest = useCallback(() => {
    dispatch({ type: SaleOfferDetailsActionType.SALE_OFFER_DETAILS_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferDetailsSuccess = useCallback((details: ISaleOfferDomain) => {
    dispatch({ type: SaleOfferDetailsActionType.SALE_OFFER_DETAILS_SUCCESS, payload: details });
  }, []);

  const setSaleOfferDetailsError = useCallback(() => {
    dispatch({ type: SaleOfferDetailsActionType.SALE_OFFER_DETAILS_ERROR, payload: undefined });
  }, []);

  const setSaleOfferDetailsReset = useCallback(() => {
    dispatch({ type: SaleOfferDetailsActionType.SALE_OFFER_DETAILS_RESET, payload: undefined });
  }, []);

  return {
    ...state.saleOfferDetails,
    setSaleOfferDetailsRequest,
    setSaleOfferDetailsSuccess,
    setSaleOfferDetailsError,
    setSaleOfferDetailsReset,
  };
};
