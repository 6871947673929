import API from 'utils/API/API';
import { IScheduleHistoryDriverAndVehicleDomain } from '../../domains/schedule-details-history-driver-and-vehicle.domain';
import { IScheduleHistoryDriverAndVehicleResponse } from './get-schedule-history-driver-and-vehicle-list.types';
import { converterScheduleHistoryDriverAndVehicleResponseToDomain } from './mappers/converter-history-driver-and-vehicle-response-to-domain/converter-history-driver-and-vehicle-response-to-domain.mapper';

export const getScheduleHistoryDriverAndVehicleListService = async (
  id: string,
  typeUpdate: 'MOTORISTA' | 'VEICULO',
): Promise<Array<IScheduleHistoryDriverAndVehicleDomain>> => {
  const { data } = await API.get<Array<IScheduleHistoryDriverAndVehicleResponse>>(
    `v2/schedule-history/${id}/${typeUpdate}`,
  );

  return data.map(converterScheduleHistoryDriverAndVehicleResponseToDomain);
};
