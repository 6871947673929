import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateScheduleDetailsService } from '../../services/update-schedule-details/update-schedule-details.service';
import { IUpdateScheduleCargoPayload } from '../../services/update-schedule-details/update-schedule-details.types';
import { useQueryClient } from 'react-query';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const UseUpdateScheduleDetails = () => {
  const { scheduleRequestStatus } = useContext(ScheduleContext);

  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payloadDomain: IUpdateScheduleCargoPayload) => {
      try {
        scheduleRequestStatus.setScheduleRequestStatus();
        await updateScheduleDetailsService(scheduleId, payloadDomain);
        showMessageFormatted({
          message: 'Dados atualizados com sucesso.',
          type: 'success',
        });
        scheduleRequestStatus.setScheduleRequestStatusSuccess();
        scheduleRequestStatus.setScheduleRequestStatusReset();
        // INVALIDATE QUERY DATA
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      } catch (error: any) {
        scheduleRequestStatus.setScheduleRequestStatusError();
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleId, scheduleRequestStatus],
  );
};
