import React, { useMemo } from 'react';

import { CardResumeOriginAndDestinyInput, IDeliveryScheduleDetailsValues } from './types';
import * as S from './styled';

import { formatTime } from 'utils-v2/converters/time';
import { formatDateTime } from 'utils-v2/converters/date';

interface CardResumeDeliveryProps {
  destinyValues: CardResumeOriginAndDestinyInput;
  scheduleDetailsValues?: IDeliveryScheduleDetailsValues;
}

export const CardResumeDelivery: React.FC<CardResumeDeliveryProps> = ({
  destinyValues,
  scheduleDetailsValues,
}) => {
  const deliveryDate = useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { deliveryInitialDate, deliveryEndDate } = scheduleDetailsValues;

    if (deliveryInitialDate && deliveryInitialDate.toUpperCase() !== 'INVALID DATE') {
      if (deliveryEndDate && deliveryEndDate.toUpperCase() !== 'INVALID DATE') {
        return `${formatDateTime(deliveryInitialDate, 'DD-MM-YYYY HH:mm')} - ${formatTime(
          deliveryEndDate,
          'DD-MM-YYYY HH:mm',
        )}`;
      }
      return `${formatDateTime(deliveryInitialDate, 'DD-MM-YYYY HH:mm')}`;
    }

    if (deliveryEndDate && deliveryEndDate.toUpperCase() !== 'INVALID DATE') {
      return `${formatDateTime(deliveryEndDate, 'DD-MM-YYYY HH:mm')}`;
    }

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 3.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.Text fontWeight uppercase>
          Destino
        </S.Text>
        <S.TextContent>
          {destinyValues.tradeName ? (
            <S.TextContent uppercase>
              <S.Text fontWeight>{destinyValues.tradeName}</S.Text>
              <S.Text>{`${destinyValues.address.street} ${destinyValues.address.numberHouse}`}</S.Text>
              <S.Text>{`${destinyValues.address.city} - ${destinyValues.address.uf}`}</S.Text>
              <S.Text>{deliveryDate}</S.Text>
            </S.TextContent>
          ) : (
            <S.TextContent italic>
              <S.Text uppercase>Não informado</S.Text>
            </S.TextContent>
          )}
        </S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
