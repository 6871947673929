import { ITableFreightEntity } from 'pages/table-freight/services/entities/table-freight.entity';
import { ITableFreight } from 'domain/table-freight';

export const convertTableFreightEntityToDomainMapper = (entity: ITableFreightEntity): ITableFreight => ({
  vehicleType: entity.vehicleType,
  vehicleCategory: entity.vehicleCategory,
  value: entity.value,
  initialDistance: entity.initialDistance,
  finalDistance: entity.finalDistance,
  maxCargo: entity.maxCargo,
  helperPrice: entity.helperPrice,
  percentGris: entity.percentGris,
  percentAdvalorem: entity.percentAdvalorem,
  leadTime: entity.leadTime,
});
