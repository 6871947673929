import dayjs from 'dayjs';

export const formatDate = (date: string | number, format: string): string => {
  return dayjs(date).format(format);
};

export const formatAndCalcDate = (date?: string, operation?: 'subtract' | 'add', hoursToCalc?: number) => {
  if (!date) return '-';

  if (operation === 'subtract' && hoursToCalc) {
    return dayjs(date).subtract(hoursToCalc, 'hours').format('DD/MM/YYYY HH:mm');
  }

  if (operation === 'add' && hoursToCalc) {
    return dayjs(date).add(hoursToCalc, 'hours').format('DD/MM/YYYY HH:mm');
  }

  return dayjs(date).format('DD/MM/YYYY HH:mm');
};
