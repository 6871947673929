import React, { Dispatch, SetStateAction, useMemo } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';

import HeaderTable from '../header';

import { convertStatus } from 'utils-v2/converters/status';
import { convertOperationType } from 'utils-v2/converters/operation';
import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { IdlenessBiddingModal } from 'pages-v2/idleness-biddings/types';
import { PageInfo } from 'domain-v2/inputs/page-info';
import { ModalState } from 'domain-v2/inputs/modals';
import { IdlenessBiddingsList } from 'domain-v2/idleness-bidding/list';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { Bell } from 'components-v2/common/bell';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';

interface ContentTable {
  idlenessBiddings: Array<IdlenessBiddingsList>;
  pageInfo: PageInfo;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  orderSelected: { orderBy: string; sortDirection: string };
  clickOrderTableBy: (type: string) => void;
  setBuyModal: Dispatch<SetStateAction<ModalState<IdlenessBiddingModal>>>;
}

export const ListIdlenessBiddingsTable: React.FC<ContentTable> = ({
  idlenessBiddings,
  pageInfo,
  isLoading,
  handlePaginate,
  orderSelected,
  clickOrderTableBy,
  setBuyModal,
}) => {
  const mountTable = useMemo((): Array<IContentTable> => {
    return idlenessBiddings.map((idlenessBidding) => {
      return [
        {
          value: '',
          flex: 0,
        },
        {
          value: idlenessBidding.id,
          flex: 0.4,
        },
        {
          value: (
            <Tag
              label={convertOperationType(idlenessBidding.operationType).label}
              color={convertOperationType(idlenessBidding.operationType).color}
              variant="ghost"
              display="flex"
              size="md"
            />
          ),
          flex: 0.5,
        },
        {
          value: moment(idlenessBidding.initialDate).format('DD/MM/YYYY'),
          flex: 0.8,
        },

        {
          value: idlenessBidding.origin.uf + ' - ' + idlenessBidding.origin.city,
          subtitle: idlenessBidding.origin.name,
          flex: 2,
        },
        {
          value: idlenessBidding.destination.uf + ' - ' + idlenessBidding.destination.city,
          subtitle: idlenessBidding.destination.name,
          flex: 2,
        },
        {
          value: convertVehicleTypeTypeToName(idlenessBidding.vehicle.type),
          subtitle: convertVehicleCategoryTypeToName(idlenessBidding.vehicle.category),
          flex: 1,
        },

        {
          value: idlenessBidding.frequency === 0 ? 'N.I' : idlenessBidding.frequency,
          subtitle: 'viagens/mês',
          flex: 0.8,
        },

        {
          value: offeredWeight(idlenessBidding.weight ?? 0),
          subtitle: offeredPallets(idlenessBidding.pallets ?? 0),
          flex: 0.8,
        },
        {
          value: convertStatus(idlenessBidding.status).label,
          flex: 0.5,
        },
        {
          value: (
            <Bell
              id={idlenessBidding.id}
              count={idlenessBidding.biddings}
              onClick={() =>
                setBuyModal((oldModal) => ({
                  ...oldModal,
                  data: { idlenessId: idlenessBidding.id },
                  open: true,
                }))
              }
            />
          ),
          flex: 0.4,
        },
      ];
    });
  }, [idlenessBiddings]);

  const renderAllocationTable = useMemo(() => {
    return (
      <HeaderTable
        orderSelected={orderSelected}
        contentTable={mountTable}
        pageInfo={pageInfo}
        changePage={handlePaginate}
        clickOrderTableBy={clickOrderTableBy}
        isLoading={isLoading}
        clickRowAction={() => null}
      />
    );
  }, [mountTable, pageInfo, isLoading, clickOrderTableBy]);

  const tabContent = useMemo(() => {
    const tabs = [
      {
        name: 'Recebidos',
        content: renderAllocationTable,
      },
    ];

    return tabs;
  }, [renderAllocationTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={() => null} tabActive={'Recebidos'} />;
};
