import { IOccurrenceCreatedEntity } from '../entities/occurrence.entity';

import API from 'utils/API/API';
import { convertOccurrenceCreatedEntityToDomainMapper } from 'pages/occurrence/mappers/convert-occurrence-created-entity-to-domain/convert-occurrence-created-entity-to-domain.mapper';
import { IOccurrenceCreated } from 'domain/occurrence';

export const occurrenceDetailsService = async (occurrenceId: string): Promise<IOccurrenceCreated> => {
  const { data } = await API.get<IOccurrenceCreatedEntity>(`occurrences/${occurrenceId}`);
  return convertOccurrenceCreatedEntityToDomainMapper(data);
};
