import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { removePurchaseOfferService } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/remove-purchase-offer/remove-purchase-offer-list.service';
import { useQueryClient } from 'react-query';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const usePurchaseOfferRemove = () => {
  const reactQueryClient = useQueryClient();
  const { purchaseOfferList } = useContext(PurchaseOfferContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { selectedIds, setSelectIds, setPurchaseOfferListReset } = purchaseOfferList;
  const { setInitialLoadingReset } = initialLoading;

  return useCallback(
    async (handleCloseDeleteModal: () => void, handleIsLoading: (isLoading: boolean) => void) => {
      handleIsLoading(true);

      try {
        await removePurchaseOfferService(selectedIds);
        reactQueryClient.invalidateQueries(['purchase-offer-list-table']);

        setSelectIds([]);
        handleIsLoading(false);
        handleCloseDeleteModal();
        setInitialLoadingReset();
        setPurchaseOfferListReset();
        showMessageFormatted({
          message: 'Oferta(s) excluidas com sucesso.',
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, selectedIds, setInitialLoadingReset, setPurchaseOfferListReset, setSelectIds],
  );
};
