import { FC, useCallback, useMemo } from 'react';
import * as S from './modal-match-header-view.styled';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { Tag } from 'src-new/components/tag/tag.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import {
  ScheduleDestinationRegisterInput,
  ScheduleOriginRegisterInput,
  ScheduleRegisterInput,
} from 'domain-v2/schedule/register';

interface IScheduleOfferModalMatchHeaderViewProps {
  scheduleValues: ScheduleRegisterInput;
  companyImage: string;
}

export const ScheduleOfferModalMatchHeaderView: FC<IScheduleOfferModalMatchHeaderViewProps> = ({
  scheduleValues,
  companyImage,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: '' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Classificação' },
    ];

    const columnsConfig = '0.25fr 0.15fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string | number, subtitle: string) => {
    return (
      <S.WrapperTextsInformationHeader>
        <S.Text weight={700}>{title}</S.Text>
        <S.Text size={10} weight={400} color="#7b7b7b">
          {subtitle}
        </S.Text>
      </S.WrapperTextsInformationHeader>
    );
  }, []);

  const renderCompanyImageNode = useCallback((src: string) => {
    return (
      <S.WrapperIdAndImage>
        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: 25,
            marginTop: 3,
          }}
          src={src}
          alt={src}
        />
      </S.WrapperIdAndImage>
    );
  }, []);

  const renderLocationText = useCallback(
    (origin: ScheduleOriginRegisterInput, destination: ScheduleDestinationRegisterInput) => {
      return (
        <S.WrapperInformationLocationsHeader>
          <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
          <div>
            <S.Text weight={700}>
              {origin.origin.address.city} - {origin.origin.address.uf}
            </S.Text>
            <S.Text weight={700}>
              {destination.destination.address.city} - {destination.destination.address.uf}
            </S.Text>
          </div>
        </S.WrapperInformationLocationsHeader>
      );
    },
    [],
  );

  const renderRows = useMemo((): IRow => {
    return {
      key: scheduleValues.operation,
      onClick: () => undefined,
      valuesColumns: [
        { value: renderCompanyImageNode(companyImage) },
        { value: <S.Text /> },
        { value: renderLocationText(scheduleValues.origin, scheduleValues.destination) },
        { value: renderTitleSubtitle(scheduleValues.cargo.vehicleType, scheduleValues.cargo.vehicleCategoryType) },
        { value: renderTitleSubtitle(`${scheduleValues.cargo.weight} KG`, `${scheduleValues.cargo.pallet} Pallets`) },
        { value: renderTitleSubtitle(0, 'VIAGENS/MÊS') },
        {
          value: (
            <S.WrapperTags>
              <Tag
                label={'BACKHAUL EXTERNO'}
                color={ThemeColorsV2Type.DarkGray}
                variant="ghost"
                display="inline-flex"
                size="md"
              />
              <Tag label={'FTL'} color={ThemeColorsV2Type.DarkGray} variant="ghost" display="inline-flex" size="md" />
            </S.WrapperTags>
          ),
        },
      ],
    };
  }, [
    companyImage,
    renderCompanyImageNode,
    renderLocationText,
    renderTitleSubtitle,
    scheduleValues.cargo.pallet,
    scheduleValues.cargo.vehicleCategoryType,
    scheduleValues.cargo.vehicleType,
    scheduleValues.cargo.weight,
    scheduleValues.destination,
    scheduleValues.operation,
    scheduleValues.origin,
  ]);

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={[renderRows]}
        height={'155px'}
      />
    </S.Wrapper>
  );
};
