import React, { ReactNode } from 'react';
import { Tag } from 'logshare-ui-kit';
import VerifiedIcon from '@mui/icons-material/Verified';

import * as S from './styled';
import { backhaulTypeValue } from './constants/card-resume-values';

import { convertStatusCode, convertStatusCodeColor } from 'utils/status-code';
import { formatDecimal } from 'utils/format-numbers';
import { ColorsV2Type } from 'styles/types/colors';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Button from 'components/button/button';
import { TruckStl } from 'assets/icons/truck-stl';
import { TruckLtl } from 'assets/icons/truck-ltl';
import { TruckFtl } from 'assets/icons/truck-ftl';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';

interface CardResumeHeaderProps {
  id?: number;
  co2?: number;
  canClose?: boolean;
  statusCode?: string;
  freightType?: string;
  internalBackhaul?: string;
  qualityAndSafety?: string;
  hasQualityAndSafety?: boolean;
  handleCloseResume?: (close: boolean) => void;
}

export const CardResumeHeader: React.FC<CardResumeHeaderProps> = ({
  id,
  co2,
  canClose,
  statusCode,
  freightType,
  internalBackhaul,
  qualityAndSafety,
  hasQualityAndSafety,
  handleCloseResume,
}) => {
  const [modalQualityAndSafety, setModalQualityAndSafety] = React.useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const renderStatusCode = React.useMemo(() => {
    if (!statusCode) return null;

    const labelStatus = statusCode.length <= 4 ? convertStatusCode(statusCode) : statusCode;

    const colorStatus = convertStatusCodeColor(labelStatus.toLowerCase());

    return (
      <S.TagSection>
        <Tag label={labelStatus} color={colorStatus} variant="solid" display="inline-flex" size="lg" />
      </S.TagSection>
    );
  }, [statusCode]);

  const renderCo2 = React.useMemo(() => {
    if (!co2) return null;

    const parsedCo2 = Number(co2);

    if (parsedCo2 !== 0) {
      return (
        <S.TagSection>
          <Tag
            LeftIcon={
              <S.TagText>
                <DoubleArrowDownIcon />
                CO₂
              </S.TagText>
            }
            label={`${formatDecimal(parsedCo2)} KG`}
            color={ColorsV2Type.Aqua}
            variant="ghost"
            display="inline-flex"
            size="lg"
          />
        </S.TagSection>
      );
    }

    return null;
  }, [co2]);

  const renderBackhaulType = React.useMemo(() => {
    if (!internalBackhaul) return null;

    if (internalBackhaul in backhaulTypeValue) {
      const backhaulInfo = backhaulTypeValue[internalBackhaul];

      return (
        <S.TagSection>
          <Tag label={backhaulInfo.label} color={backhaulInfo.color} variant="solid" display="inline-flex" size="lg" />
        </S.TagSection>
      );
    }
  }, [internalBackhaul]);

  const renderFreightType = React.useMemo(() => {
    if (!freightType) return null;

    switch (freightType) {
      case 'stl':
        return (
          <S.TagSection>
            <Tag
              LeftIcon={<TruckStl width="15" height="10" />}
              label="stl"
              color={ColorsV2Type.Blue100}
              variant="solid"
              display="inline-flex"
              size="lg"
            />
          </S.TagSection>
        );
      case 'ltl':
        return (
          <S.TagSection>
            <Tag
              LeftIcon={<TruckLtl width="15" height="10" />}
              label="ltl"
              color={ColorsV2Type.Aqua100}
              variant="solid"
              display="inline-flex"
              size="lg"
            />
          </S.TagSection>
        );
      default:
        return (
          <S.TagSection>
            <Tag
              LeftIcon={<TruckFtl width="15" height="10" />}
              label="ftl"
              color={ColorsV2Type.Blue}
              variant="solid"
              display="inline-flex"
              size="lg"
            />
          </S.TagSection>
        );
    }
  }, [freightType]);

  const goToQualityAndSafety = () => {
    const description = <S.Text padding="10px 0">{qualityAndSafety || 'Não há requerimento'}</S.Text>;
    setModalQualityAndSafety({ open: true, description: description });
  };

  return (
    <>
      {modalQualityAndSafety.open && (
        <ModalConfirmation
          description={modalQualityAndSafety.description}
          handleXCancel={() => {
            setModalQualityAndSafety({
              open: false,
              description: '',
            });
          }}
          title="Requerimento de Qualidade e Segurança"
          size={'medium'}
          type={'normal'}
        />
      )}
      <S.Content>
        <S.HeaderSection>
          <S.Text blueColor fontWeight size="18px">
            {id ? `Viagem ${id}` : 'Resumo'}
          </S.Text>

          <S.HeaderButtons>
            {hasQualityAndSafety && (
              <Button
                title={'Requerimentos'}
                size={'medium'}
                bgColor="orange"
                callback={goToQualityAndSafety}
                icon={<VerifiedIcon />}
              />
            )}

            {canClose && (
              <S.ButtonClosed onClick={() => handleCloseResume && handleCloseResume(true)}>X</S.ButtonClosed>
            )}
          </S.HeaderButtons>
        </S.HeaderSection>

        <S.TagContainer>
          {renderStatusCode}

          {renderBackhaulType}

          {renderCo2}

          {renderFreightType}
        </S.TagContainer>
      </S.Content>
    </>
  );
};
