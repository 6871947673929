import { FC, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IScheduleDetailsFinanceDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { ScheduleFinanceAuditModalFormView } from './schedule-finance-audit-modal-form-view.component';
import { useUpdateFinanceStatus } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-finance-status/use-update-finance-status.hook';

export interface IScheduleFinanceAuditModalFormProps {
  transactionDetails: IScheduleDetailsFinanceDomain;
}

export const ScheduleFinanceAuditModalForm: FC<IScheduleFinanceAuditModalFormProps> = ({ transactionDetails }) => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateFinanceStatusHook = useUpdateFinanceStatus();

  const handleCloseModalFinanceAudit = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails]);

  const handleOnSubmit = useCallback(() => {
    if (transactionDetails.status === 'Pagar') {
      updateFinanceStatusHook(transactionDetails.id, 'A Vencer');
    } else {
      updateFinanceStatusHook(transactionDetails.id, 'Aguardando Canhoto');
    }

    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails, transactionDetails.id, transactionDetails.status, updateFinanceStatusHook]);

  return (
    <ScheduleFinanceAuditModalFormView
      transactionDetails={transactionDetails}
      handleOnSubmit={handleOnSubmit}
      handleCloseModalFinanceAudit={handleCloseModalFinanceAudit}
    />
  );
};
