/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IModalType, WeighingTottenMessageModalView } from './weighing-totten-modals-view';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';
import ErrorMessageSvg from 'src/assets/error-message.svg';
import SuccessMessageSvg from 'src/assets/sucess-message.svg';
import { usePutWeighingTottenCheckin } from '../../hooks/use-get-weighing-totten-checkin/use-get-weighing-totten-checkin.hook';
import { formatPhoneNumber } from 'src-new/utils/convert-phone-number-format.util';
import dayjs from 'dayjs';

export const WeighingTottenMessageModal: React.FC = () => {
  const [modalType, setModalType] = useState<IModalType>('');
  const { weighingTottensDetails } = useContext(WeighingTottensContext);
  const putWeighingTottenCheckin = usePutWeighingTottenCheckin();
  const { driver, checkingDate, schedule } = weighingTottensDetails.weighingTottensDetails;

  const hasError = useMemo(
    (): boolean => weighingTottensDetails.statusRequest === 'ERROR',
    [weighingTottensDetails.statusRequest],
  );

  const getIfAlreadyChecked = () => {
    return !hasError && checkingDate;
  };

  const alreadyChecked = getIfAlreadyChecked();

  const checkingDateFormated = useMemo(() => dayjs(checkingDate).format('DD/MM/YYYY'), []);
  const checkingTimeFormated = useMemo(() => dayjs(checkingDate).format('HH:mm'), []);

  const buildTilte = useMemo(() => {
    if (hasError) {
      return <>Agendamento não encontrado!</>;
    } else {
      if (!checkingDate) {
        return <>Agendamento encontrado!</>;
      }
    }

    return (
      <div>
        O Check-in da <strong>Ordem de Coleta {schedule.id}</strong> já foi realizado em {checkingDateFormated} às {checkingTimeFormated}
      </div>
    );
  }, []);

  const buildMessage = useMemo(() => {
    if (alreadyChecked) {
      return (
        <>
          Por favor aguarde ser chamado no telefone:
          <br />
          <strong>{formatPhoneNumber(driver.phone)}</strong>
          <br />
          <br />
          Caso o telefone informado não esteja correto, por favor dirija-se a Central.
        </>
      );
    }
    return undefined;
  }, []);

  const url = hasError ? ErrorMessageSvg : SuccessMessageSvg;
  const btnLabel = hasError || checkingDate ? 'Continuar' : 'Ver Detalhes';

  useEffect(() => {
    if (modalType === 'checkin') {
      putWeighingTottenCheckin({
        id: weighingTottensDetails.weighingTottensDetails.id,
        checkingDate: new Date().toISOString(),
      });
    }
  }, [modalType]);

  return (
    <WeighingTottenMessageModalView
      hasError={hasError}
      url={url}
      btnLabel={alreadyChecked ? 'Tela Inicial' : btnLabel}
      title={buildTilte}
      message={buildMessage}
      modalType={alreadyChecked ? 'accomplished' : modalType}
      setModalType={setModalType}
    />
  );
};
