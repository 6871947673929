import React from 'react';

import { IScheduleHistory } from '../../freight-update-form';

import FreightDriverSectionView from './freight-driver-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  values?: ScheduleDomain;
  scheduleHistory?: IScheduleHistory[];
  onRefresh: React.Dispatch<React.SetStateAction<number>>;
}
const FreightDriverSection: React.FC<IProps> = ({ values, scheduleHistory, onRefresh }) => {
  return <FreightDriverSectionView values={values} scheduleHistory={scheduleHistory} onRefresh={onRefresh} />;
};

export default React.memo(FreightDriverSection);
