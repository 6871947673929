import styled, { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const ChatSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  border-color: #c8c8c8;
`;

export const ChatHeaderSkeleton = styled.div`
  height: 55px;
  background: rgb(18, 38, 78);
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const ChatHeaderPlaceholder = styled.div`
  width: 200px;
  height: 20px;
  background: rgb(42, 58, 90);
  border-radius: 4px;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const ChatBodySkeleton = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background: #ffffff;
`;

export const MessageSkeleton = styled.div<{ align: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-direction: ${({ align }) => (align === 'right' ? 'row-reverse' : 'row')};
`;

export const AvatarPlaceholder = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(176, 176, 177, 0.5);
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const MessageContentPlaceholder = styled.div`
  width: 60%;
  height: 35px;
  background: rgba(176, 176, 177, 0.5);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const ChatFooterSkeleton = styled.div`
  height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const InputPlaceholder = styled.div`
  flex: 1;
  height: 30px;
  background: rgba(228, 228, 228, 0.75);
  border-radius: 4px;
  margin-right: 8px;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const IconsPlaceholder = styled.div`
  width: 100px;
  height: 30px;
  background: rgba(228, 228, 228, 0.75);
  border-radius: 4px;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;
