import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';

interface ScheduleMatchParams {
  originLatitude: string;
  originLongitude: string;
  destinationLatitude: string;
  destinationLongitude: string;
  vehicleType: string;
  vehicleCategory: string;
}

export const getScheduleOfferMatchService = async (params: ScheduleMatchParams) => {
  const {
    originLatitude,
    originLongitude,
    destinationLatitude,
    destinationLongitude,
    vehicleType,
    vehicleCategory
  } = params
  const {data} = await API_TRAVELS_SVC.get(`v1/matchs/schedule?originLatitude=${originLatitude}&originLongitude=${originLongitude}&destinationLatitude=${destinationLatitude}&destinationLongitude=${destinationLongitude}&vehicleType=${vehicleType}&vehicleCategory=${vehicleCategory}`);
  return data.items;
};
