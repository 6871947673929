/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react';

interface ITerritorialVisionContext {
  selectedBackHaul: any;
  setSelectedBackHaul: React.Dispatch<React.SetStateAction<undefined>>;
}

interface ITerritorialVisionContextProviderProps {
  children: React.ReactNode;
}

export const TerritorialVisionContext = createContext<ITerritorialVisionContext>({} as ITerritorialVisionContext);

export const TerritorialVisionContextProvider: React.FC<ITerritorialVisionContextProviderProps> = ({
  children,
}): JSX.Element => {
  const [selectedBackHaul, setSelectedBackHaul] = React.useState();

  return (
    <TerritorialVisionContext.Provider value={{ selectedBackHaul, setSelectedBackHaul }}>
      {children}
    </TerritorialVisionContext.Provider>
  );
};

export const useTerritorial = () => {
  return React.useContext(TerritorialVisionContext);
};
