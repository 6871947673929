import React, { ReactNode } from 'react';

import TitleView from './title-view';

interface ITitleProps {
  dataTestId?: string;
  title: string;
  hasTrace?: boolean;
  isForm?: boolean;
  rightIcon?: ReactNode;
}

const Title: React.FC<ITitleProps> = ({ dataTestId, title, hasTrace, isForm, rightIcon }: ITitleProps) => {
  return <TitleView dataTestId={dataTestId} title={title} hasTrace={hasTrace} isForm={isForm} rightIcon={rightIcon} />;
};

export default Title;
