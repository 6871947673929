import { IScheduleReportExcelProps } from './types';

import { DownloadFile } from 'utils/download-file/download-file';
import { allocationReportExcelService } from 'pages/allocation/services/location-report-excel/location-report-excel.service';

export const generateScheduleExcelHook = async (params: IScheduleReportExcelProps) => {
  const file = await allocationReportExcelService(params);

  if (file) {
    DownloadFile(file, 'vnd.ms-excel;', 'AGENDAMENTO_FTL');
  }
};
