import styled, { css } from 'styled-components';

interface IStyledProps {
  disabledSearch?: boolean;
}

export const WrapperSearch = styled.div`
  width: 100%;
`;

export const ContainerInput = styled.div<IStyledProps>`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: rgb(234, 234, 234);
  padding: 8px 16px;
  outline: none;
  border-radius: 6px;

  ${({ disabledSearch }) => (disabledSearch ? 'opacity: 0.5 !important; cursor: not-allowed' : '')};
`;

export const Input = styled.input<IStyledProps>`
  ${({ theme }) => css`
    width: 100%;
    font-family: ${theme.font.family};
    background-color: rgb(234, 234, 234);
    border: none;
    border-radius: 5px;
    outline: none;

    ::placeholder {
      font-size: ${theme.fontV2.sizes.text3};
      font-weight: 400;
      color: ${theme.colorsV2.darkGray};
    }
  `}
  ${({ disabledSearch }) => (disabledSearch ? 'cursor: not-allowed' : '')};
`;

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const ContainerFilter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FilterSelected = styled.div`
  height: 28px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 8px;
  gap: 8px;
  background-color: rgba(17, 152, 149, 0.26);
  border-radius: 32px;
  white-space: nowrap;
`;

export const FilterTitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text5};
    color: #0a6260;
  `}
`;

export const CleanFilter = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  cursor: pointer;
`;

export const ContainerFilterCount = styled.div`
  display: flex;
`;

export const FilterCount = styled.div`
  height: 15px;
  width: 15px;
  position: relative;
  left: 38px;
  top: 3px;
  border-radius: 100%;
  text-align: center;
  font-size: 0.7rem;
  color: white;
  background-color: #e04747;
  z-index: 1;
`;
