import { useCallback } from 'react';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';

const useLocationDetailstHook = () => {
  return useCallback(async (locationId: string): Promise<ILocationDomain> => {
    return await locationDetailsService(locationId);
  }, []);
};

export { useLocationDetailstHook };
