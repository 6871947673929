import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';
import { ScheduleRefusedListServiceResponse } from '../../services/get-schedule-refused-list/get-schedule-refused-list.types';
import { getScheduleRefusedListService } from '../../services/get-schedule-refused-list/get-schedule-refused-list.service';
import { convertScheduleRefusedListResponseToDomain } from './mappers/convert-schedule-refused-list-response-to-domain/convert-schedule-refused-list-response-to-domain.mapper';

export const useGetScheduleRefusedList = () => {
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);

  const { setScheduleRefusedListSuccess, setScheduleRefusedListRequest, setScheduleRefusedListError } =
    scheduleRefusedList;

  const { data } = useQuery<ScheduleRefusedListServiceResponse>({
    queryKey: ['schedule-refused-list-table', scheduleRefusedList.scheduleRefusedListFilters.filters],
    queryFn: () => {
      setScheduleRefusedListRequest();

      return getScheduleRefusedListService({ ...scheduleRefusedList.scheduleRefusedListFilters.filters });
    },
    onSuccess: (data: ScheduleRefusedListServiceResponse) => {
      const values = data.items.map(convertScheduleRefusedListResponseToDomain);

      setScheduleRefusedListSuccess(values);
    },
    onError: (error) => {
      setScheduleRefusedListError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      setScheduleRefusedListSuccess(data.items.map(convertScheduleRefusedListResponseToDomain));
    }
  }, [data, setScheduleRefusedListSuccess]);
};
