import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { DocumentsNFeView } from './documents-nfe-view.component';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetNFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-nfe-data/use-get-nfe-data.hook';
import { ScheduleDocumentsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';

export type ViewModeType = 'list' | 'kanban';

export const DocumentsNFe: React.FC = () => {
  const [viewMode, setViewMode] = useState<ViewModeType>('list');
  const { scheduleDocuments } = useContext(ScheduleContext);
  const getNFeData = useGetNFeData();

  const handleOpenModal = useCallback(
    (modalType: ScheduleDocumentsModalType, id: number) =>
      scheduleDocuments.modalsProps.setModalOpen({ open: true, id, modalType }),
    [scheduleDocuments.modalsProps],
  );

  const handleToggleClick = useCallback(() => setViewMode(viewMode === 'list' ? 'kanban' : 'list'), [viewMode]);

  const isEmptyList = useMemo(
    () => scheduleDocuments.nfeDataProps.statusRequest === 'SUCCESS' && !scheduleDocuments.nfeDataProps.nfeList.length,
    [scheduleDocuments.nfeDataProps.nfeList, scheduleDocuments.nfeDataProps.statusRequest],
  );

  const isLoading = useMemo(
    () => scheduleDocuments.nfeDataProps.statusRequest === 'PROCESSING',
    [scheduleDocuments.nfeDataProps.statusRequest],
  );

  useEffect(() => {
    if (scheduleDocuments.nfeDataProps.statusRequest === 'INITIAL') {
      getNFeData();
    }
  }, [getNFeData, scheduleDocuments.nfeDataProps.statusRequest]);

  return (
    <DocumentsNFeView
      isEmptyList={isEmptyList}
      viewMode={viewMode}
      nfes={scheduleDocuments.nfeDataProps.nfeList}
      isLoading={isLoading}
      handleOpenModal={handleOpenModal}
      handleToggleClick={handleToggleClick}
    />
  );
};
