import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IVehicle } from '../../../../domain/vehicle';
import { IListPage } from '../../../../domain/page-info';

import { IVehiclesContext } from './vehicles.types';
import { vehiclesReducer } from './vehicles.reducer';
import { initialState, IVehiclesAction } from './vehicles.actions';

interface IVehiclesContextProps {
  children: ReactNode;
}

export const VehiclesContext = createContext<IVehiclesContext>(initialState);

const VehiclesContextProvider = ({ children }: IVehiclesContextProps) => {
  const [state, dispatch] = useReducer(vehiclesReducer, initialState);

  const setVehiclesRequest = useCallback(() => {
    dispatch({
      type: IVehiclesAction.VEHICLES_REQUEST,
      payload: null,
    });
  }, []);

  const setVehiclesSuccess = useCallback((vehicleList: IListPage<IVehicle>) => {
    dispatch({
      type: IVehiclesAction.VEHICLES_SUCCESS,
      payload: vehicleList,
    });
  }, []);

  const setVehiclesError = useCallback((error: string) => {
    dispatch({
      type: IVehiclesAction.VEHICLES_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      vehiclesIsLoading: state.vehiclesIsLoading,
      vehicles: state.vehicles,
      vehiclesError: state.vehiclesError,
      vehiclesPageInfo: state.vehiclesPageInfo,
      setVehiclesRequest,
      setVehiclesSuccess,
      setVehiclesError,
    };
  }, [
    setVehiclesError,
    setVehiclesRequest,
    setVehiclesSuccess,
    state.vehicles,
    state.vehiclesError,
    state.vehiclesIsLoading,
    state.vehiclesPageInfo,
  ]);

  return <VehiclesContext.Provider value={value}>{children}</VehiclesContext.Provider>;
};

export default React.memo(VehiclesContextProvider);
