import styled, { css } from 'styled-components';

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 12px;
    flex-direction: column;
    padding: 15px 0px 0px 0px;
  `}
`;
