/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITrackingOccurrence } from 'domain/tracking-occurrence';

export const converterTrackingOccurrenceDomainToFormData = (trackingOccurrence: ITrackingOccurrence): FormData => {
  const occurrencesResolutions = () => {
    trackingOccurrence.occurrenceResolutions?.forEach((item, index) => {
      formData.append(
        `occurrenceResolutions[${index}]`,
        JSON.stringify({
          ...item,
          resolutionDate: new Date(item.resolutionDate).toISOString(),
        }),
      );
    });
  };

  const imagesForm = () => {
    trackingOccurrence.occurrenceFiles?.forEach((item: any) => {
      formData.append('occurrenceFiles', item);
    });
  };

  const formData = new FormData();
  occurrencesResolutions();
  imagesForm();
  formData.append('occurrenceDate', trackingOccurrence.occurrenceDate);
  formData.append('occurrenceTime', trackingOccurrence.occurrenceTime ?? '');
  formData.append('occurrenceTypeId', trackingOccurrence.occurrenceTypeId.toString());
  formData.append('companyId', trackingOccurrence.companyId.toString());
  formData.append('processType', trackingOccurrence.processType);
  formData.append('statusResolution', trackingOccurrence.statusResolution);

  formData.append('step', trackingOccurrence.step);
  formData.append('osNumber', trackingOccurrence.osNumber);
  formData.append('ticketNumber', trackingOccurrence?.ticketNumber ?? '');

  if (trackingOccurrence.clientId && trackingOccurrence.clientId !== 0)
    formData.append('clientId', String(trackingOccurrence.clientId));

  if (trackingOccurrence.localityId && trackingOccurrence.localityId !== 0)
    formData.append('localityId', String(trackingOccurrence.localityId));

  formData.append('cnpj', trackingOccurrence.cnpj);
  formData.append('endDate', trackingOccurrence.endDate || '');
  formData.append('endTime', trackingOccurrence.endTime || '');
  formData.append('resolutionTime', trackingOccurrence.resolutionTime || '');
  formData.append('scheduleId', String(trackingOccurrence.scheduleId));

  return formData;
};
