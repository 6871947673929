import API from 'utils/API/API';

export interface IUpdateDriver {
  driverId: number;
  vehicleId?: number;
  typeUpdate: 'MOTORISTA'
}

export const updateDriverService = async (id: string, values: IUpdateDriver) => {
  const url = API.put(`v2/schedules/update/driver/vehicle/${id}`, {
    vehicleId: values.vehicleId,
    driverId: values.driverId,
    typeUpdate: values.typeUpdate
  });

  return await url;
};
