import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { SaleOfferModalMatchTableTabsView } from './sale-offer-modal-match-table-tabs-view.component';
import { useGetSaleOfferMatchList } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/hooks/use-sale-offer-match-list/use-sale-offer-match-list.hook';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { useGetFavoriteMatchesKanbanList } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/hooks/use-get-favorite-matches-kanban-list/use-get-favorite-matches-kanban-list.hook';
import { ModalBiddingOffer } from 'src-new/components/modal-bidding-offer/modal-bidding-offer.component';
import { convertSaleOfferToBiddingOffer } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/mappers/convert-sale-offer-to-bidding-offer/convert-sale-offer-to-bidding-offer.mapper';

export const SaleOfferModalMatchTableTabs: React.FC = () => {
  const { saleOfferModals, saleOfferMatchList } = useContext(SaleOfferContext);
  const { filters, saleOfferMatchListSelected } = saleOfferMatchList;
  const { selectedFilter, setSelectFilters } = filters;
  const { isOpen, modalType, secondModalType, setModalSecondaryClose } = saleOfferModals;

  const [activeTab, setActiveTab] = useState<string>('Backhaul Externo');
  const getSaleOfferMatchListHook = useGetSaleOfferMatchList();
  const getFavoriteMatchesKanbanListHook = useGetFavoriteMatchesKanbanList();

  const handleMatchesTabs = useCallback(
    (name: string) => {
      setActiveTab(name);
      setSelectFilters({ ...selectedFilter, backhaulType: name === 'Backhaul Interno' ? 'INTERNO' : 'EXTERNO' });
    },
    [selectedFilter, setSelectFilters],
  );

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Backhaul Externo',
        content: <Fragment />,
      },
      {
        name: 'Backhaul Interno',
        content: <Fragment />,
      },
    ];
  }, []);

  useEffect(() => {
    getSaleOfferMatchListHook();
    getFavoriteMatchesKanbanListHook();
  }, [getFavoriteMatchesKanbanListHook, getSaleOfferMatchListHook]);

  const renderModalMatchBidding = useMemo(() => {
    if (isOpen && modalType === 'sale_offer_match' && secondModalType === 'sale_offer_match_bidding')
      return (
        <ModalBiddingOffer
          biddingOffer={convertSaleOfferToBiddingOffer(saleOfferMatchListSelected.selectedMatch)}
          isSaleOffer
          handleClose={setModalSecondaryClose}
        />
      );
  }, [isOpen, modalType, saleOfferMatchListSelected.selectedMatch, secondModalType, setModalSecondaryClose]);

  return (
    <Fragment>
      {renderModalMatchBidding}
      <SaleOfferModalMatchTableTabsView
        tabsContent={tabsContent}
        activeTab={activeTab}
        handleChangeTab={handleMatchesTabs}
      />
    </Fragment>
  );
};
