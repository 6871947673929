import { useCallback, useReducer } from 'react';
import { IWeighingList, WeighingListModals } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { weighingInitialState } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.constants';
import { weighingListReducer } from 'src-new/pages/execution/pages/weighing/contexts/weighing/reducers/weighing-list/weighing-list.reducer';
import { WeighingListActionType } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.action';
import { IWeighingListDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list.domain';
import { IWeighingListFilterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters.domain';
import { weighingListFilterInitialValues } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters-initial-values.domain';

export const useWeighingOfferListContextValues = (): IWeighingList => {
  const [state, dispatch] = useReducer(weighingListReducer, weighingInitialState);

  const setWeighingListRequest = useCallback(() => {
    dispatch({ type: WeighingListActionType.WEIGHING_LIST_REQUEST, payload: undefined });
  }, []);

  const setWeighingListSuccess = useCallback((list: Array<IWeighingListDomain>) => {
    dispatch({ type: WeighingListActionType.WEIGHING_LIST_SUCCESS, payload: list });
  }, []);

  const setWeighingListError = useCallback(() => {
    dispatch({ type: WeighingListActionType.WEIGHING_LIST_ERROR, payload: undefined });
  }, []);

  const setWeighingListReset = useCallback(() => {
    dispatch({ type: WeighingListActionType.WEIGHING_LIST_RESET, payload: undefined });
  }, []);

  const setSelectFilters = useCallback((filters: IWeighingListFilterDomain) => {
    dispatch({ type: WeighingListActionType.WEIGHING_SELECT_FILTERS, payload: filters });
  }, []);

  const setModalOpen = useCallback((modalOptions: WeighingListModals) => {
    dispatch({ type: WeighingListActionType.WEIGHING_LIST_MODALS, payload: modalOptions });
  }, []);

  const setResetFilters = useCallback(() => {
    dispatch({
      type: WeighingListActionType.WEIGHING_RESET_FILTERS,
      payload: weighingListFilterInitialValues,
    });
  }, []);

  return {
    ...state.weighingList,
    setWeighingListRequest,
    setWeighingListSuccess,
    setWeighingListError,
    setWeighingListReset,
    filters: {
      ...state.weighingList.filters,
      setSelectFilters,
      setResetFilters,
    },
    modalsProps: {
      ...state.weighingList.modalsProps,
      setModalOpen,
    },
  };
};
