import { IShippingCompanyDomain } from 'src-new/domains/shipping-company.domain';
import { IShippingCompanyResponse } from '../../get-shipping-company.types';

export const convertShippingCompanyResponseToDomain = (
  shippingCompanyResponse: IShippingCompanyResponse,
): IShippingCompanyDomain => {
  return {
    ...shippingCompanyResponse,
    address: {
      cep: shippingCompanyResponse.cep,
      city: shippingCompanyResponse.city,
      complement: shippingCompanyResponse.complement,
      neighborhood: shippingCompanyResponse.neighborhood,
      number: shippingCompanyResponse.numberHouse,
      street: shippingCompanyResponse.street,
      uf: shippingCompanyResponse.uf,
    },
  };
};
