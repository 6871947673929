import { IScheduleDetailsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';

export const convertTimeRecordingLabelValues = (value: IScheduleDetailsModalType) => {
  const labelToEnumMap: { [key: string]: string } = {
    'Programação da Carga': 'loadSchedulingDate',
    'Alocação da Transportadora': 'allocationShippingDate',
    'Aceite da Transportadora': 'acceptShipping',
    'Alocação do Motorista': 'allocationDriverDate',
    'Alocação do Veículo': 'allocationVehicleDate',
    'Início da Viagem para Carregamento': 'startDate',
    'Chegada para Carregamento': 'arrivalLoadingDate',
    'Início da Coleta': 'checkinDate',
    'Fim da Coleta': 'checkoutDate',
    'Início da Viagem para Descarga': 'startTripUnloadingDate',
    'Chegada para Descarga': 'arrivalUnloadingDate',
    'Início da Descarga': 'loadDate',
    'Fim da Descarga': 'finishDate',
  };

  return labelToEnumMap[value];
};
