import React from 'react';

import HireBackhaulRegisterForm from '../hire-backhaul-register-form/hire-backhaul-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const HireBackhaulRegisterBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="backhaul" breadcrumbList={['Backhaul', 'Oferta de Compra']} hasBackButton />
      <Title title="Nova Oferta de Compra" hasTrace isForm />
      <HireBackhaulRegisterForm />
    </React.Fragment>
  );
};

export default HireBackhaulRegisterBodyView;
