import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { palletListHook } from '../hooks/pallet-list';

import * as S from './styled';
import ListPalletTable from './content-table/list';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';
import { UserContext } from 'state/user-context';
import { downloadPalletPDF } from 'services/pallet/download-pallet-pdf';
import { deletePalletsService } from 'services/pallet/delete-pallet';
import { IPalletStatus } from 'domain-v2/pallet/register';
import { IPalletList, ISelectedPallets, PalletListParams, defaultPalletFilter } from 'domain-v2/pallet/list';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import Pagination from 'components/pagination/pagination';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { Button } from 'logshare-ui-kit';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

const getSessionStorage = sessionStorage.getItem('pagination-pallet');

const listParams: PalletListParams = getSessionStorage ? JSON.parse(getSessionStorage) : defaultPalletFilter;

export const PalletListPage: React.FC = () => {
  const { user, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const reactQueryClient = useQueryClient();

  const navigate = useNavigate();

  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [params, setParams] = useState<PalletListParams>(listParams);
  const [selectedPallets, setSelectedPallets] = useState<Array<ISelectedPallets>>([]);

  const { isLoading, data } = useQuery({
    queryKey: ['palletList', params],
    queryFn: () => palletListHook(params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const goToCreatePallet = useCallback(() => {
    navigate('/pallet/novo');
  }, [navigate]);

  const goToUpdatePallet = useCallback(
    (palletId: string) => {
      navigate(`/pallet/atualizar/${palletId}`);
    },
    [navigate],
  );

  const handleSearch = (search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  };

  const handlePaginate = (page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  };

  const renderStatusByTabName = useCallback((activeTabName: string) => {
    const tabStatusCode: { [key: string]: IPalletStatus } = {
      'Em Aberto': 'em_aberto',
      Liberado: 'liberado',
    };

    const status = tabStatusCode[activeTabName];

    setParams((prevState) => ({
      ...prevState,
      status,
      activeTabName: activeTabName,
    }));
  }, []);

  const paramsArray: Array<{ key: string; value: string | number }> = useMemo(() => {
    return Object.entries(params).map(([key, value]) => ({
      key,
      value: value as string,
    }));
  }, [params]);

  const newParamsArray = useMemo(() => {
    return paramsArray.filter(
      (item) =>
        (item.key !== 'search' || (item.key === 'search' && item.value !== '')) &&
        !['activeTabName', 'page', 'order', 'column', 'status'].includes(item.key),
    );
  }, [paramsArray]);

  const fileNameOrder = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_palete_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const palletQuantityTotal = useMemo(() => {
    return selectedPallets.reduce((total, item) => total + item.palletQuantity, 0);
  }, [selectedPallets]);

  const deleteFilters = useCallback(
    (indexToRemove: number) => {
      const updatedParamsArray = [...newParamsArray];
      updatedParamsArray.splice(indexToRemove, 1);

      if (updatedParamsArray.length === 0) {
        setParams({
          ...defaultPalletFilter,
          activeTabName: params.activeTabName,
        });

        reactQueryClient.invalidateQueries(['palletList', defaultPalletFilter]);
      } else {
        const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
          accumulator[key] = params[key as keyof PalletListParams];
          return accumulator;
        }, {});

        setParams({
          ...updatedParams,
          page: 1,
        });

        reactQueryClient.invalidateQueries(['palletList', updatedParams]);
      }
    },
    [newParamsArray, params, reactQueryClient],
  );

  const handleCheckboxChange = useCallback(
    (checked: boolean, pallet: IPalletList) => {
      if (checked) {
        const newSelectedPallets = [
          ...selectedPallets,
          {
            id: pallet.id,
            palletQuantity: pallet.palletQuantity,
            clientName: pallet.clientName ?? '-',
            originCity: pallet.originCity ?? '-',
          },
        ];
        setSelectedPallets(newSelectedPallets);
      } else {
        const updatedSelectedPallets = selectedPallets.filter((item) => item.id !== pallet.id);
        setSelectedPallets(updatedSelectedPallets);
      }
    },
    [selectedPallets],
  );

  const handleGeneratePDF = useCallback(
    async (palletId: number[]) => {
      try {
        showMessageFormatted({
          message: 'Gerando PDF.',
          type: 'info',
        });

        const pdfFile = await downloadPalletPDF(palletId);

        showMessageFormatted({
          message: 'PDF gerado com sucesso.',
          type: 'success',
        });

        await reactQueryClient.invalidateQueries('palletList');

        DownloadFile(pdfFile ?? '', 'pdf', fileNameOrder);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [fileNameOrder, reactQueryClient],
  );

  const handleValidatePDF = useCallback(() => {
    const isEqual = selectedPallets.every((item, index, array) => {
      return item.clientName === array[0].clientName && item.originCity === array[0].originCity;
    });

    if (!isEqual) {
      showMessageFormatted({
        message: 'Selecione o mesmo cliente e cidade de origem!',
        type: 'error',
      });
      return;
    }

    const ids = selectedPallets.map((item) => item.id);
    handleGeneratePDF(ids);
  }, [selectedPallets, handleGeneratePDF]);

  const handleDeletePallets = useCallback(async () => {
    try {
      await Promise.all(
        selectedPallets.map(async (item) => {
          await deletePalletsService(item.id);
        }),
      );

      showMessageFormatted({
        message: selectedPallets.length > 1 ? 'Paletes deletados!' : 'Palete deletado!',
        type: 'success',
      });

      await reactQueryClient.invalidateQueries(['palletList', params]);

      setModalCancel(false);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [selectedPallets, reactQueryClient, params]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      {modalCancel && (
        <ModalConfirmation
          title={`Deletar ${selectedPallets.length > 1 ? 'Paletes' : 'Palete'}`}
          description={`Tem certeza que deseja deletar ${selectedPallets.length > 1 ? 'os paletes' : 'o palete'}?`}
          handleCancel={() => setModalCancel(false)}
          onConfirmation={handleDeletePallets}
          size="small"
          type="delete"
        />
      )}
      <HeaderPage breadcrumbList={['Execução', 'Vale Equipamentos']} />
      <Title title="Controle o recebimento, armazenagem e devolução de paletes da operação." hasTrace />
      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por ID ou Cliente"
          type="text"
          changeValue={(event) => handleSearch(event)}
          filters={newParamsArray.map((item) => item.value)}
          handleClearFilters={(indexToRemove) => deleteFilters(indexToRemove)}
          isClear={false}
        />

        {!isShippingCompany && (
          <Button
            label="Novo Frete"
            size="md"
            color="blue"
            onPress={() => handleCreateSchedulePage(true)}
            variant="solid"
          />
        )}
      </S.ContainerSearch>

      <ListPalletTable
        pallets={data?.list ?? []}
        isLoading={isLoading}
        tabActive={params.activeTabName}
        orderSelected={{
          orderBy: params.column,
          sortDirection: params.order,
        }}
        selectedPallets={selectedPallets}
        handleGeneratePDF={handleGeneratePDF}
        clickOrderTableBy={(column: string) => {
          setParams((prevParams) => ({
            ...prevParams,
            column: column,
          }));
        }}
        clickSortDirection={() => {
          setParams((prevParams) => ({
            ...prevParams,
            order: params.order === 'ASC' ? 'DESC' : 'ASC',
          }));
        }}
        setActiveTabName={renderStatusByTabName}
        goToUpdatePallet={goToUpdatePallet}
        handleCheckboxChange={handleCheckboxChange}
      />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Novo" color="pink" onPress={goToCreatePallet} />
          {selectedPallets.length > 0 && (
            <FooterPage.Button label="Excluir" color="white" variant="ghost" onPress={() => setModalCancel(true)} />
          )}
        </FooterPage.RightContent>
        <FooterPage.LeftContent>
          <Pagination
            activePage={data?.pageInfo.currentPage ?? 1}
            changePage={handlePaginate}
            totalPage={data?.pageInfo.totalPages ?? 1}
          />
          {selectedPallets.length > 0 && <FooterPage.Button label="Liberar" color="aqua" onPress={handleValidatePDF} />}

          <S.FooterText>Selecionados: {selectedPallets.length}</S.FooterText>
          <S.FooterText>Total de Equipamentos: {palletQuantityTotal}</S.FooterText>
        </FooterPage.LeftContent>
      </FooterPage.Root>
    </>
  );
};
