import React, { useCallback } from 'react';

import InputFieldCepView from './input-field-cep-view';

type InputFieldCepProps = {
  id: string;
  name: string;
  value?: string;
  type: 'text';
  label?: string;
  labelFor?: string;
  placeholder?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFieldCep: React.FC<InputFieldCepProps> = ({
  id,
  name,
  value = '',
  type,
  label,
  placeholder,
  reactNode,
  reactNodePosition,
  disabled,
  hasError,
  errorMessage,
  onChange = () => null,
  onBlur = () => null,
}) => {
  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value.replace(/\D/g, '');
    if (value.length <= 12)
      value = value
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    e.currentTarget.value = value;
  }, []);

  return (
    <InputFieldCepView
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      placeholder={placeholder}
      reactNode={reactNode}
      reactNodePosition={reactNodePosition}
      disabled={disabled}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={onBlur}
      handleKeyUp={handleKeyUp}
    />
  );
};

export default InputFieldCep;
