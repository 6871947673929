import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IScheduleMapReportDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-map-report.domain';

export const generateMapReportFileService = async (
  mapReportList: Array<IScheduleMapReportDomain>,
): Promise<ArrayBuffer> => {
  const { data } = await API_TRAVELS_SVC.post<ArrayBuffer>(
    '/v1/schedules/routes-map-report',
    {
      mapReportList,
    },
    { responseType: 'arraybuffer' },
  );

  return data;
};
