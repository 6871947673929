import React, { useContext, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';

import UserListTable from '../user-list-table/user-list-table';

import { AccessRestrictedStyled, ContainerAccessStyled, ContainerSearchStyled } from './user-list-body.styled';

import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { IListPageUser } from 'domain/user';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';

interface IUserListBodyViewProps {
  users: Array<IListPageUser>;
  usersPageInfo: PageInfo | null;
  hasLoading: boolean;
  showModal: boolean;
  goToCreateUser: () => void;
  goToDetailsPage: (userId: string) => void;
  showModalConfirmation: () => void;
  handleDeleteUsers: () => void;
  setCurrentPageUsers: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  setOrder: (value: string) => void;
  setColumn: (value: string) => void;
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
}

const userType: {
  [key: string]: { label: string; color: ColorsV2Type };
} = {
  'shipping-company': {
    label: 'Transportadora',
    color: ColorsV2Type.Orange,
  },
  condominium: {
    label: 'Parceiro',
    color: ColorsV2Type.Aqua,
  },
  driver: {
    label: 'Motorista',
    color: ColorsV2Type.Green,
  },
  default: {
    label: 'Embarcador',
    color: ColorsV2Type.Blue,
  },
};

const UserListBodyView: React.FC<IUserListBodyViewProps> = ({
  users,
  usersPageInfo,
  hasLoading,
  showModal,
  onHandleExport,
  options,
  goToCreateUser,
  goToDetailsPage,
  showModalConfirmation,
  handleDeleteUsers,
  setCurrentPageUsers,
  setSearchInput,
  setActiveTabName,
  setIdsToRemove,
}) => {
  const { isAdmin, user } = useContext(UserContext);

  const description = (
    <>
      <p>Isso excluirá o usuário da listagem.</p>
      <p style={{ marginTop: 5 }}>Tem certeza?</p>
    </>
  );

  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.2,
      },
      {
        value: 'ID',
        flex: 0.5,
      },
      {
        value: 'Nome',
        flex: 2.4,
      },
      {
        value: 'Login',
        flex: 2.4,
      },
      {
        value: 'Tipo',
        flex: 1.2,
      },
      {
        value: 'Status',
        flex: 0.55,
      },
    ];
  }, []);

  const contentTable = useMemo((): Array<IContentTable> => {
    return users.map((users) => {
      return [
        {
          value: (
            <Checkbox
              id="user.id"
              name="user.id"
              value={users.id.toString()}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, users.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== users.id)])
              }
              disabled={users.name === 'MASTER'}
            />
          ),
          flex: 0.2,
        },
        {
          value: users.id,
          flex: 0.5,
        },
        {
          value: users.name,
          flex: 2.4,
        },
        {
          value: users.login,
          flex: 2.4,
        },
        {
          value: !users.isMaster ? (
            <Tag
              label={(userType[users.profiles] || userType.default).label}
              color={(userType[users.profiles] || userType.default).color}
              variant="solid"
              display="flex"
              size="md"
            />
          ) : (
            <Tag label="Master" color={ColorsV2Type.Red} variant="solid" display="flex" size="md" />
          ),
          flex: 1.2,
        },

        {
          value: (
            <Tag
              label={users.status}
              color={users.status === 'ativo' ? ColorsV2Type.Green : ColorsV2Type.Red}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.55,
        },
      ];
    });
  }, [users]);

  const renderUsersTable = useMemo(() => {
    return (
      <UserListTable
        headerTable={headerTable}
        contentTable={contentTable}
        pageInfo={usersPageInfo}
        changePage={setCurrentPageUsers}
        isLoading={hasLoading}
        goToDetailsPage={goToDetailsPage}
      />
    );
  }, [contentTable, goToDetailsPage, hasLoading, headerTable, setCurrentPageUsers, usersPageInfo]);

  const tabContent = useMemo(() => {
    const availableTabs = [
      {
        name: 'Ativos',
        content: renderUsersTable,
      },
      {
        name: 'Inativos',
        content: renderUsersTable,
      },
      {
        name: 'Bloqueados',
        content: renderUsersTable,
      },
    ];

    return availableTabs;
  }, [renderUsersTable, isAdmin]);

  if (user?.name !== 'MASTER' && user?.name !== 'CONSULTOR LOGSHARE') {
    return (
      <>
        <HeaderPage breadcrumbList={['Configurações', 'Gestão de Usuários']} />
        <Title title="Personalize os acessos conforme as necessidades da sua empresa." hasTrace />
        <ContainerAccessStyled>
          <AccessRestrictedStyled>Acesso Restrito</AccessRestrictedStyled>
        </ContainerAccessStyled>
      </>
    );
  }

  return (
    <>
      <HeaderPage breadcrumbList={['Configurações', 'Gestão de Usuários']} />
      <Title title="Personalize os acessos conforme as necessidades da sua empresa." hasTrace />
      <ContainerSearchStyled>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Nome ou Login"
          type="text"
          changeValue={setSearchInput}
        />

        <DropDown title="">
          <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
        </DropDown>
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateUser} />
          <FooterPage.Button label="Excluir" color="white" variant="ghost" onPress={showModalConfirmation} />
        </FooterPage.RightContent>
      </FooterPage.Root>
      {showModal && (
        <ModalConfirmation
          title="Confirma exclusão do Usuário?"
          secondTitle={description}
          handleCancel={showModalConfirmation}
          onConfirmation={handleDeleteUsers}
          size="small"
          type="delete"
          description={undefined}
        />
      )}
    </>
  );
};

export default UserListBodyView;
