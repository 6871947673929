import { IUserChat } from 'src-new/domains/user-chat.domain';

export const getUserResponsibleReceivingEmailDevelopment = (threadId: string): IUserChat => ({
  id: `${threadId}-user-emails`,
  name: 'LogShareUserEmails',
  role: 'default',
  email: ['joao.almeida@logshare.com.br'],
});

export const getUserResponsibleReceivingEmailProduction = (threadId: string): IUserChat => {
  return {
    id: `${threadId}-user-emails`,
    name: 'LogShareUserEmails',
    role: 'default',
    email: getProductionEmailsByFlow(threadId),
  };
};

export const getProductionEmailsByFlow = (threadId: string): Array<string> => {
  const flow: string = threadId.split('-')[1];
  const defaultEmails: Array<string> = [];

  if (flow === 'proposal') {
    defaultEmails.push('relacionamento@logshare.com.br');
  } else {
    defaultEmails.push('torredecontrole@logshare.com.br');
  }

  defaultEmails.push('monitoramento@logshare.com.br');

  return defaultEmails;
};
