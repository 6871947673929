import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';

export const getOfferFileReportService = async (
  params: ISaleOfferListFilterDomain | IPurchaseOfferListFilterDomain,
  type: 'purchase' | 'sale',
): Promise<ArrayBuffer | undefined> => {
  const { data } = await API_TRAVELS_SVC.get(`v1/report/export/${type === 'sale' ? 'sales-offer' : 'purchase-offer'}`, {
    params,
    responseType: 'arraybuffer',
  });

  return data;
};
