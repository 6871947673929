import styled, { css } from 'styled-components';

export const ContainerFilterStyled = styled.div`
  display: flex;
  gap: 20px;
  justify-content: right;

  button:nth-last-child(1) {
    margin-top: 3px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const CalendarContainerStyled = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 20px;
`;

export const ContainerDashboardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 40px;
  }
`;

export const OneContainerStyled = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const TwoContainerStyled = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const DashboardContainerStyled = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const IconFormatedStyled = styled.div`
  position: relative;
  top: 5px;
`;
type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 10px;
    align-items: center;
  `}
`;

export const ContainerRow = styled.div`
  width: 100%;
  margin-bottom: 2px;
`;
