import { useCallback } from 'react';
import { IShippingCompanyDomain } from 'src-new/domains/shipping-company.domain';
import { getShippingCompanyService } from 'src-new/services/get-shipping-company/get-shipping-company.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetShippingCompany = () => {
  return useCallback(async (shippingCompanyId: number): Promise<IShippingCompanyDomain | undefined> => {
    try {
      return getShippingCompanyService(shippingCompanyId);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter detalhes da transportadora.',
        type: 'error',
      });
    }
  }, []);
};
