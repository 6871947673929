import { Button } from 'logshare-ui-kit';
import React, { Fragment, useCallback, useMemo } from 'react';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IOrdering, IRow } from 'src-new/components/table/table.types';
import {
  ISaleOfferMatchListDomain,
  ISaleOfferMatchListLocationsDomain,
  ScoreDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list.domain';
import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './sale-offer-modal-match-table-view.styled';
import { Tag } from 'src-new/components/tag/tag.component';
import { SaleOfferModalMatchScoreLabel } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-modal-match/components/sale-offer-modal-match-score/components/sale-offer-modal-match-score-label/sale-offer-modal-match-score-label.component';
import { SaleOfferModalMatchScore } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-modal-match/components/sale-offer-modal-match-score/sale-offer-modal-match-score.component';
import { DoubleArrowDownGrayIcon } from 'assets/icons/double-arrow-down-gray.icon';
import { Star } from 'lucide-react';
import TooltipComponent from 'components/Tooltip';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';
import { convertOperationType } from 'src-new/utils/convert-operation-type/convert-operation-type.util';

interface ISaleOfferModalMatchTableViewProps {
  saleOfferMatch: Array<ISaleOfferMatchListDomain>;
  orderingColumns: IOrdering;
  isLoading: boolean;
  handleFavoriteMatch: (matchId: number, isFavorite: boolean) => void;
  handleOpenModalMatchBidding: (matchSelected: ISaleOfferMatchListDomain) => void;
  handleOpenProposalChat: (purchaseOfferId: string, matchId: string) => void;
}

export const SaleOfferModalMatchTableView: React.FC<ISaleOfferModalMatchTableViewProps> = ({
  saleOfferMatch,
  orderingColumns,
  isLoading,
  handleFavoriteMatch,
  handleOpenModalMatchBidding,
  handleOpenProposalChat,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: '' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Match Potencial' },
      { value: 'Classificação' },
      { value: 'Frete Valor' },
    ];

    const columnsConfig = '0.25fr 0.15fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title.toUpperCase()}</S.Title>
        <S.Subtitle>{subtitle.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderCompanyImageNode = useCallback((id: number, src: string) => {
    return (
      <S.WrapperIdAndImage>
        <S.Title>{id}</S.Title>
        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: 25,
            marginTop: 3,
          }}
          src={src}
          alt={src}
        />
      </S.WrapperIdAndImage>
    );
  }, []);

  const renderFavoriteMatch = useCallback(
    (matchId: number, isFavorite?: boolean) => {
      const title = isFavorite ? 'Desfavoritar Match' : 'Favoritar Match';
      const color = isFavorite ? '#FF9800' : '#D9D9D9';

      return (
        <TooltipComponent
          title={title}
          location="bottom-start"
          icon={<Star size={15} color={color} fill={color} onClick={() => handleFavoriteMatch(matchId, !isFavorite)} />}
        />
      );
    },
    [handleFavoriteMatch],
  );

  const renderPotentionalMatches = useCallback(
    (score: { origin: ScoreDomain; destination: ScoreDomain; vehicle: ScoreDomain }) => {
      return (
        <Fragment>
          <SaleOfferModalMatchScoreLabel sumScore={score.vehicle + score.origin + score.destination} />
          <S.WrapperMatchScore>
            <TooltipComponent
              title="Veículo"
              location="bottom"
              icon={<SaleOfferModalMatchScore label={'V'} score={score.vehicle} />}
            />
            <TooltipComponent
              title="Origem"
              location="bottom"
              icon={<SaleOfferModalMatchScore label={'O'} score={score.origin} />}
            />
            <TooltipComponent
              title="Destino"
              location="bottom"
              icon={<SaleOfferModalMatchScore label={'D'} score={score.destination} />}
            />
          </S.WrapperMatchScore>
        </Fragment>
      );
    },
    [],
  );

  const renderLocationText = useCallback(
    (origin: ISaleOfferMatchListLocationsDomain, destination: ISaleOfferMatchListLocationsDomain) => {
      return (
        <S.WrapperInformationLocations>
          <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
          <div>
            <S.Title>
              {origin.address.city.toUpperCase()} - {origin.address.uf.toUpperCase()}
            </S.Title>
            <S.Title>
              {destination.address.city.toUpperCase()} - {destination.address.uf.toUpperCase()}
            </S.Title>
          </div>
        </S.WrapperInformationLocations>
      );
    },
    [],
  );

  const renderRows = useMemo((): Array<IRow> => {
    return saleOfferMatch.map((match) => {
      const operationType = convertOperationType(match.operationType);
      return {
        key: match.id,
        onClick: () => undefined,
        valuesColumns: [
          { value: renderCompanyImageNode(match.id, match.company.image) },
          {
            value: renderFavoriteMatch(match.matchId, match.isFavorite),
            isClicable: false,
          },
          { value: renderLocationText(match.origin, match.destination) },
          { value: renderTitleSubtitle(match.vehicle.type, match.vehicle.category) },
          {
            value: renderTitleSubtitle(
              (match.capacity.weight || '0') + ' KG',
              (match.capacity.pallet || '0') + ' PALLETS',
            ),
          },
          {
            value: renderTitleSubtitle(match.frequency.toString(), 'VIAGENS/MÊS'),
          },
          {
            value: renderPotentionalMatches(match.score),
          },
          {
            value: (
              <S.WrapperTags>
                <S.Div>
                  <Tag
                    label={'BACKHAUL' + ' ' + match.backhaulType}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={match.co2Economy.toFixed(2)}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                    LeftIcon={
                      <S.TagText>
                        <DoubleArrowDownGrayIcon />
                        CO₂
                      </S.TagText>
                    }
                  />
                  <Tag
                    label={operationType.label}
                    color={operationType.color}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={'DESVIO ' + ' ' + Number(match.additionalDistance).toFixed(0) + ' KM'}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>
              </S.WrapperTags>
            ),
          },
          {
            value: renderTitleSubtitle(convertCurrencyFormat.format(match.values.freightValue), '+ IMPOSTOS'),
          },
          {
            value: (
              <S.Div>
                <Button
                  label="Negociar"
                  size="sm"
                  color="red"
                  onPress={() => handleOpenProposalChat(match.id.toString(), match.matchId.toString())}
                  variant="ghost"
                />
              </S.Div>
            ),
          },
          {
            value: (
              <S.Div>
                <Button
                  label="Vender"
                  size="sm"
                  color="blue"
                  onPress={() => handleOpenModalMatchBidding(match)}
                  variant="solid"
                />
              </S.Div>
            ),
          },
        ],
      };
    });
  }, [
    handleOpenModalMatchBidding,
    handleOpenProposalChat,
    renderCompanyImageNode,
    renderFavoriteMatch,
    renderLocationText,
    renderPotentionalMatches,
    renderTitleSubtitle,
    saleOfferMatch,
  ]);

  if (isLoading)
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    );

  if (!saleOfferMatch.length)
    return (
      <S.EmptyListWrapper>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.EmptyListWrapper>
    );

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        ordering={orderingColumns}
        rows={renderRows}
        height={'380px'}
      />
      <ChatButton chatProps={{ typeChat: 'SINGLE', name: '' }} />
    </S.Wrapper>
  );
};
