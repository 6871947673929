import { FC } from 'react';
import { WeighingTottensContextProvider } from '../../contexts/weighing-totten/weighing-tottens.context';
import { WeighingTottenDetailsPageBody } from './components/weighing-totten-details-body/weighing-totten-details-page-body.component';

export const WeighingTottenDetails: FC = () => {
  return (
    <WeighingTottensContextProvider>
      <WeighingTottenDetailsPageBody />
    </WeighingTottensContextProvider>
  );
};
