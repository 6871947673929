import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FooterPage } from 'components-v2/common/footer-page';
import Button from 'components/button/button';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import InputTextarea from 'components/input-textarea/input-textarea';
import InputFieldTime from 'components/input-time/input-time';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import MultiSelect from 'components/multi-select/multi-select';
import ResponsibleSolution from 'components/responsible-solution/responsible-solution';
import SectionDivider from 'components/section-divider/section-divider';
import Select from 'components/select/select';
import { showToast } from 'components/toast/toast';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { IAddress } from 'domain/address';
import { uf } from 'domain/states';
import { FormikProps, withFormik } from 'formik';
import { useConvertAddressToGeoPositionHook } from 'hooks/maps/map-convert-address-to-geo.hook';
import React, { useCallback, useEffect, useState } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import PinMap from '../pin-map/pin-map-view';
import { optionsType, optionsVehicleType } from './form-values/form-values';
import * as S from './location-register-form.styled';
import MultiAsyncSelectField from 'components/multi-async-select/multi-async-select';
import { optionsVehicleCategory } from 'domain-v2/inputs/vehicles';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { clientsFilterSearchService } from 'pages/schedule/services/clients-search/clients-filter-search.service';
import { MultiValue } from 'react-select';
import { IOptionsDomain } from 'src-new/domains/options.domain';
import { ContactDetailsSection } from 'src-new/pages/registrations/components/contact-details-section/contact-details-section.component';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { AttachDocumentsSection } from 'src-new/pages/registrations/components/attach-documents-section/attach-documents-section.component';
import {
  MultiSelect as NewMultiSelect,
  IMultiSelectOption,
} from 'src-new/pages/registrations/components/multi-select/multi-select.component';
import { ServicesType, servicesTypeOptions } from 'src-new/pages/registrations/domains/services-type.domain';

interface LocationFormView {
  locationValues: ILocationDomain;
  handleGoback: () => void;
  handleRegister: (values: ILocationDomain) => void;
  schemaValidation: object;
  onBlurCep: (cep: string) => Promise<IAddress>;
  isLoading: boolean;
}

const LocationFormView: React.FC<LocationFormView> = ({
  locationValues,
  schemaValidation,
  isLoading,
  handleGoback,
  handleRegister,
  onBlurCep,
}) => {
  const InnerForm = (props: FormikProps<ILocationDomain>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const convertConvertToAddressToGeoHook = useConvertAddressToGeoPositionHook();

    const [submit, setSubmit] = useState<boolean>(false);
    const [openMap, setOpenMap] = useState<boolean>(false);
    const [coord, setCoord] = useState<{ lat: number; lng: number }>();

    const setServiceType = useCallback(
      (e: MultiValue<IMultiSelectOption<ServicesType>>) => {
        const hasAll = e.find((e) => e.value === 'TODOS');
        const services = hasAll ? servicesTypeOptions.filter((v) => v.value != 'TODOS') : e.filter((e) => e.value);
        const options = services.map((v) => v.value);
        setFieldValue('operationType', options);
      },
      [setFieldValue],
    );

    useEffect(() => {
      function verificarObjetoVazio(obj: any) {
        if (Object.keys(obj).length !== 0 && values.lat === '') {
          return true;
        } else {
          return false;
        }
      }

      if (verificarObjetoVazio(errors) && verificarObjetoVazio(touched)) {
        showToast({
          message: 'Preencha todos os campos obrigatórios',
          type: 'error',
        });
        setSubmit(false);
      }
    }, [errors, submit, touched, values.lat]);

    const onBlurCEPField = async (cep: string) => {
      const address = await onBlurCep(cep);
      setFieldValue('address.cep', address.cep || values.address.cep);
      setFieldValue('address.city', address.city);
      setFieldValue('address.complement', address.complement);
      setFieldValue('address.uf', address.uf);
      setFieldValue('address.neighborhood', address.neighborhood);
      setFieldValue('address.numberHouse', address.numberHouse);
      setFieldValue('address.street', address.street);
    };

    const handleGetLatLng = async () => {
      const geoposition = await convertConvertToAddressToGeoHook({
        address: `${values.address.cep} ${values.address.street} ${values.address.city} ${values.address.numberHouse}`,
      });

      setFieldValue('lat', String(geoposition?.lat));
      setFieldValue('lng', String(geoposition?.lng));
      setCoord({ lat: Number(geoposition?.lat), lng: Number(geoposition?.lng) });
    };

    const verifyTime = (first: string, second: string) => {
      if (first && second?.length === 5) {
        const firstNumber = Number(first?.replace(':', ''));
        const secondNumber = Number(second?.replace(':', ''));
        if (firstNumber >= secondNumber) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const handleVerifyInputs = () => {
      if (
        verifyTime(values.inbound.dom?.firstTime, values.inbound.dom?.secondTime) ||
        verifyTime(values.inbound.seg?.firstTime, values.inbound.seg?.secondTime) ||
        verifyTime(values.inbound.ter?.firstTime, values.inbound.ter?.secondTime) ||
        verifyTime(values.inbound.qua?.firstTime, values.inbound.qua?.secondTime) ||
        verifyTime(values.inbound.qui?.firstTime, values.inbound.qui?.secondTime) ||
        verifyTime(values.inbound.sex?.firstTime, values.inbound.sex?.secondTime) ||
        verifyTime(values.inbound.sab?.firstTime, values.inbound.sab?.secondTime) ||
        verifyTime(values.outbound.dom?.firstTime, values.outbound.dom?.secondTime) ||
        verifyTime(values.outbound.seg?.firstTime, values.outbound.seg?.secondTime) ||
        verifyTime(values.outbound.ter?.firstTime, values.outbound.ter?.secondTime) ||
        verifyTime(values.outbound.qua?.firstTime, values.outbound.qua?.secondTime) ||
        verifyTime(values.outbound.qui?.firstTime, values.outbound.qui?.secondTime) ||
        verifyTime(values.outbound.sex?.firstTime, values.outbound.sex?.secondTime) ||
        verifyTime(values.outbound.sab?.firstTime, values.outbound.sab?.secondTime)
      ) {
        showMessageFormatted({
          message: 'Há algum problemas com os horários',
          type: 'error',
        });
      } else {
        handleSubmit();
        setSubmit(true);
      }
    };

    const clientApiCall = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
      if (searchValue) return clientsFilterSearchService(searchValue);

      return [];
    }, []);

    const handleVehicleOption = useCallback(
      (vehicle: MultiValue<IOptionsDomain>) => {
        const vehicles = vehicle.map((option) => option.value);

        let vehiclesToAdd = vehicles;

        if (vehicles.includes('ALL')) vehiclesToAdd = optionsVehicleType.map((v) => v.value);

        setFieldValue('vehicleRestriction', vehiclesToAdd);
      },
      [setFieldValue],
    );

    return (
      <>
        {openMap && (
          <ModalConfirmation
            title="Selecione o Local"
            handleXCancel={() => {
              setCoord(undefined);
              setOpenMap(false);
            }}
            onConfirmation={() => {
              setFieldValue('lat', String(coord?.lat));
              setFieldValue('lng', String(coord?.lng));
              setOpenMap(false);
            }}
            size="large"
            type="normal"
            description={<PinMap coord={coord} setCoord={setCoord} />}
          />
        )}
        <S.FormStyled>
          <S.SectionStyled name="Unidades">
            <S.LineSectionStyled columns="0.43fr 0.7fr 1.3fr 0.57fr 0.8fr">
              <InputField label="ID *" id="code" name="code" type="text" disabled />

              <Select
                label="Tipo de Unidade *"
                id="type"
                name="type"
                value={values.type}
                setFieldValue={setFieldValue}
                hasError={!!errors.type && !!touched.type}
                errorMessage={String(errors.type)}
                options={optionsType}
              />

              <InputField
                label="Nome da Unidade *"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
                errorMessage={String(errors.name)}
              />

              <InputField
                label="Código Interno"
                id="internalCode"
                name="internalCode"
                type="text"
                onChange={handleChange}
                value={values.internalCode}
                hasError={!!errors.internalCode && !!touched.internalCode}
                errorMessage={String(errors.internalCode)}
              />

              <InputFieldCnpj
                label="CNPJ *"
                id="cnpj"
                name="cnpj"
                type="text"
                onChange={handleChange}
                value={values.cnpj}
                hasError={!!errors.cnpj && !!touched.cnpj}
                errorMessage={String(errors.cnpj)}
              />
            </S.LineSectionStyled>
          </S.SectionStyled>
          <SectionDivider label="Endereço" />
          <S.SectionStyled name="Unidades">
            <S.LineSectionStyled columns="0.45fr 1.35fr 2.2fr 0.4fr">
              <InputFieldCep
                label="CEP *"
                id="address.cep"
                name="address.cep"
                type="text"
                value={values.address.cep}
                onChange={handleChange}
                onBlur={(e) => onBlurCEPField(e.target.value)}
                hasError={!!errors.address?.cep && !!touched.address?.cep}
                errorMessage={String(errors.address?.cep)}
              />

              <InputField
                label="Bairro *"
                id="address.neighborhood"
                name="address.neighborhood"
                type="text"
                onChange={handleChange}
                value={values.address.neighborhood}
                hasError={!!errors.address?.neighborhood && !!touched.address?.neighborhood}
                errorMessage={String(errors.address?.neighborhood)}
              />

              <InputField
                label="Cidade *"
                id="address.city"
                name="address.city"
                type="text"
                onChange={handleChange}
                value={values.address.city}
                hasError={!!errors.address?.city && !!touched.address?.city}
                errorMessage={String(errors.address?.city)}
              />

              <Select
                label="UF *"
                id="address.uf"
                name="address.uf"
                value={values.address.uf}
                setFieldValue={setFieldValue}
                hasError={!!errors.address?.uf && !!touched.address?.uf}
                errorMessage={String(errors.address?.uf)}
                options={uf}
              />
            </S.LineSectionStyled>

            <S.LineSectionStyled columns="1.5fr 0.4fr 1.1fr 0.1fr 0.5fr 0.5fr">
              <InputField
                label="Endereço *"
                id="address.street"
                name="address.street"
                type="text"
                value={values.address.street}
                onChange={handleChange}
                hasError={!!errors.address?.street && !!touched.address?.street}
                errorMessage={String(errors.address?.street)}
              />

              <InputField
                label="Nº *"
                id="address.numberHouse"
                name="address.numberHouse"
                type="text"
                onChange={handleChange}
                onBlur={handleGetLatLng}
                value={values.address.numberHouse}
                hasError={!!errors.address?.numberHouse && !!touched.address?.numberHouse}
                errorMessage={String(errors.address?.numberHouse)}
              />

              <InputField
                label="Complemento"
                id="address.complement"
                name="address.complement"
                type="text"
                onChange={handleChange}
                value={values.address?.complement ?? ''}
                hasError={!!errors.address?.complement && !!touched.address?.complement}
                errorMessage={String(errors.address?.complement)}
              />

              <S.ContainerButtonStyled>
                <Button
                  bgColor="blue"
                  title="Adicionar Latitude e Longitude"
                  size="very-small"
                  icon={<LocationOnIcon />}
                  callback={() => setOpenMap(true)}
                />
              </S.ContainerButtonStyled>

              <InputField
                label="Latitude *"
                id="lat"
                name="lat"
                type="text"
                value={values.lat}
                onChange={handleChange}
                hasError={!!errors.lat && !!touched.lat}
                errorMessage={String(errors.lat)}
              />

              <InputField
                label="Longitude *"
                id="lng"
                name="lng"
                type="text"
                value={values.lng}
                onChange={handleChange}
                hasError={!!errors.lng && !!touched.lng}
                errorMessage={String(errors.lng)}
              />
            </S.LineSectionStyled>
          </S.SectionStyled>
          <SectionDivider label="E-mail para Agendamento e Monitoramento" />
          <S.SectionStyled name="Detalhes da Ocorrência">
            <S.LineSectionStyled columns="1fr 1fr">
              <ResponsibleSolution
                responsables={values?.schedulingEmail}
                placeholder="Inserir e-mail para Agendamentos e Ordem de Coleta"
                addResponsable={(res) => {
                  setFieldValue('schedulingEmail', res);
                }}
              />

              <ResponsibleSolution
                responsables={values?.occurrenceEmail}
                placeholder="Inserir e-mail para Monitoramento"
                addResponsable={(res) => {
                  setFieldValue('occurrenceEmail', res);
                }}
              />
            </S.LineSectionStyled>
          </S.SectionStyled>

          <SectionDivider label="Horário de Funcionamento" />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              marginBottom: 20,
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '48%',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <S.LabelInAndOutStyled>Inbound</S.LabelInAndOutStyled>

                <div
                  style={{
                    gap: 29,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 0 10px 0',
                  }}
                >
                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.dom.able"
                      name="inbound.dom.able"
                      checked={values.inbound.dom?.able === true}
                      onChange={(e) => setFieldValue('inbound.dom.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Domingo</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.seg.able"
                      name="inbound.seg.able"
                      checked={values.inbound.seg?.able === true}
                      onChange={(e) => setFieldValue('inbound.seg.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Segunda-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.ter.able"
                      name="inbound.ter.able"
                      checked={values.inbound.ter?.able === true}
                      onChange={(e) => setFieldValue('inbound.ter.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Terça-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.qua.able"
                      name="inbound.qua.able"
                      checked={values.inbound.qua?.able === true}
                      onChange={(e) => setFieldValue('inbound.qua.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Quarta-feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.qui.able"
                      name="inbound.qui.able"
                      checked={values.inbound.qui?.able === true}
                      onChange={(e) => setFieldValue('inbound.qui.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Quinta-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.sex.able"
                      name="inbound.sex.able"
                      checked={values.inbound.sex?.able === true}
                      onChange={(e) => setFieldValue('inbound.sex.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Sexta-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="inbound.sab.able"
                      name="inbound.sab.able"
                      checked={values.inbound.sab?.able === true}
                      onChange={(e) => setFieldValue('inbound.sab.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Sábado</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>
                </div>
              </div>

              <S.ContainerHoursStyled>
                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.dom.firstTime"
                    name="inbound.dom.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.dom?.firstTime}
                    disabled={values.inbound.dom?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.dom.secondTime"
                    name="inbound.dom.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.dom?.secondTime}
                    disabled={values.inbound.dom?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.dom?.firstTime, values.inbound.dom?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.seg.firstTime"
                    name="inbound.seg.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.seg?.firstTime}
                    disabled={values.inbound.seg?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.seg.secondTime"
                    name="inbound.seg.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.seg?.secondTime}
                    disabled={values.inbound.seg?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.seg?.firstTime, values.inbound.seg?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.ter.firstTime"
                    name="inbound.ter.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.ter?.firstTime}
                    disabled={values.inbound.ter?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.ter.secondTime"
                    name="inbound.ter.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.ter?.secondTime}
                    disabled={values.inbound.ter?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.ter?.firstTime, values.inbound.ter?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qua.firstTime"
                    name="inbound.qua.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.qua?.firstTime}
                    disabled={values.inbound.qua?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qua.secondTime"
                    name="inbound.qua.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.qua?.secondTime}
                    disabled={values.inbound.qua?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.qua?.firstTime, values.inbound.qua?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qui.firstTime"
                    name="inbound.qui.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.qui?.firstTime}
                    disabled={values.inbound.qui?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.qui.secondTime"
                    name="inbound.qui.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.qui?.secondTime}
                    disabled={values.inbound.qui?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.qui?.firstTime, values.inbound.qui?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sex.firstTime"
                    name="inbound.sex.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.sex?.firstTime}
                    disabled={values.inbound.sex?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sex.secondTime"
                    name="inbound.sex.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.sex?.secondTime}
                    disabled={values.inbound.sex?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.sex?.firstTime, values.inbound.sex?.secondTime)}
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sab.firstTime"
                    name="inbound.sab.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.sab?.firstTime}
                    disabled={values.inbound.sab?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="inbound.sab.secondTime"
                    name="inbound.sab.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.inbound.sab?.secondTime}
                    disabled={values.inbound.sab?.able === false}
                    smallInput
                    hasError={verifyTime(values.inbound.sab?.firstTime, values.inbound.sab?.secondTime)}
                  />
                </S.ContainerDaysStyled>
              </S.ContainerHoursStyled>
            </div>

            <div style={{ backgroundColor: '#d9d9d9', height: '100%', width: 1 }} />

            <div
              style={{
                display: 'flex',
                width: '48%',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <S.LabelInAndOutStyled>Outbound</S.LabelInAndOutStyled>

                <div
                  style={{
                    gap: 29,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px 0 10px 0',
                  }}
                >
                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.dom.able"
                      name="outbound.dom.able"
                      checked={values.outbound.dom?.able === true}
                      onChange={(e) => setFieldValue('outbound.dom.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Domingo</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.seg.able"
                      name="outbound.seg.able"
                      checked={values.outbound.seg?.able === true}
                      onChange={(e) => setFieldValue('outbound.seg.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Segunda-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.ter.able"
                      name="outbound.ter.able"
                      checked={values.outbound.ter?.able === true}
                      onChange={(e) => setFieldValue('outbound.ter.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Terça-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.qua.able"
                      name="outbound.qua.able"
                      checked={values.outbound.qua?.able === true}
                      onChange={(e) => setFieldValue('outbound.qua.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Quarta-feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.qui.able"
                      name="outbound.qui.able"
                      checked={values.outbound.qui?.able === true}
                      onChange={(e) => setFieldValue('outbound.qui.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Quinta-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.sex.able"
                      name="outbound.sex.able"
                      checked={values.outbound.sex?.able === true}
                      onChange={(e) => setFieldValue('outbound.sex.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Sexta-Feira</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>

                  <S.LabelSemanalDaysStyled>
                    <ToggleCheckboxView
                      id="outbound.sab.able"
                      name="outbound.sab.able"
                      checked={values.outbound.sab?.able === true}
                      onChange={(e) => setFieldValue('outbound.sab.able', e.currentTarget.checked ? true : false)}
                    />
                    <S.LabelDaysStyled>Sábado</S.LabelDaysStyled>
                  </S.LabelSemanalDaysStyled>
                </div>
              </div>

              <S.ContainerHoursStyled>
                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.dom.firstTime"
                    name="outbound.dom.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.dom?.firstTime}
                    disabled={values.outbound.dom?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.dom.secondTime"
                    name="outbound.dom.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.dom?.secondTime}
                    disabled={values.outbound.dom?.able === false}
                    hasError={verifyTime(values.outbound.dom?.firstTime, values.outbound.dom?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.seg.firstTime"
                    name="outbound.seg.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.seg?.firstTime}
                    disabled={values.outbound.seg?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.seg.secondTime"
                    name="outbound.seg.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.seg?.secondTime}
                    disabled={values.outbound.seg?.able === false}
                    hasError={verifyTime(values.outbound.seg?.firstTime, values.outbound.seg?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.ter.firstTime"
                    name="outbound.ter.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.ter?.firstTime}
                    disabled={values.outbound.ter?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.ter.secondTime"
                    name="outbound.ter.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.ter?.secondTime}
                    disabled={values.outbound.ter?.able === false}
                    hasError={verifyTime(values.outbound.ter?.firstTime, values.outbound.ter?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qua.firstTime"
                    name="outbound.qua.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.qua?.firstTime}
                    disabled={values.outbound.qua?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qua.secondTime"
                    name="outbound.qua.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.qua?.secondTime}
                    disabled={values.outbound.qua?.able === false}
                    hasError={verifyTime(values.outbound.qui?.firstTime, values.outbound.qui?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qui.firstTime"
                    name="outbound.qui.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.qui?.firstTime}
                    disabled={values.outbound.qui?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.qui.secondTime"
                    name="outbound.qui.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.qui?.secondTime}
                    disabled={values.outbound.qui?.able === false}
                    hasError={verifyTime(values.outbound.sex?.firstTime, values.outbound.sex?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sex.firstTime"
                    name="outbound.sex.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.sex?.firstTime}
                    disabled={values.outbound.sex?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sex.secondTime"
                    name="outbound.sex.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.sex?.secondTime}
                    disabled={values.outbound.sex?.able === false}
                    hasError={verifyTime(values.outbound.sab?.firstTime, values.outbound.sab?.secondTime)}
                    smallInput
                  />
                </S.ContainerDaysStyled>

                <S.ContainerDaysStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sab.firstTime"
                    name="outbound.sab.firstTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.sab?.firstTime}
                    disabled={values.outbound.sab?.able === false}
                    smallInput
                  />
                  <S.LabelStyled>às</S.LabelStyled>
                  <InputFieldTime
                    label=""
                    id="outbound.sab.secondTime"
                    name="outbound.sab.secondTime"
                    type="text"
                    onChange={handleChange}
                    value={values.outbound.sab?.secondTime}
                    disabled={values.outbound.sab?.able === false}
                    smallInput
                  />
                </S.ContainerDaysStyled>
              </S.ContainerHoursStyled>
            </div>
          </div>

          <ContactDetailsSection />

          <SectionDivider label="Permissões Operacionais" />
          <S.ContainerOperationStyled>
            <S.SectionStyled name="Detalhes da Operação">
              <S.LineSectionStyled columns="1fr">
                <MultiSelect
                  label="Veículos Permitidos"
                  options={[{ value: 'ALL', label: 'TODOS' }, ...optionsVehicleType]}
                  menuPosition="fixed"
                  values={optionsVehicleType.filter((v) => values.vehicleRestriction.includes(v.value as any))}
                  onChange={handleVehicleOption}
                />
                <MultiSelect
                  label="Categorias Permitidas"
                  options={optionsVehicleCategory}
                  menuPosition="fixed"
                  onChange={(e) => {
                    const categories = e.map((option) => option.value);
                    setFieldValue('vehicleCategoryType', categories);
                  }}
                />

                <NewMultiSelect<ServicesType>
                  label="Tipos de Serviço"
                  options={[...servicesTypeOptions]}
                  menuPosition="fixed"
                  values={servicesTypeOptions.filter((v) => values.operationType.includes(v.value))}
                  onChange={setServiceType}
                />

                <MultiAsyncSelectField
                  label="Localidades Permitidas"
                  isClearable={true}
                  name="clientIds"
                  id="clientIds"
                  isDisabled={false}
                  cacheOptions={true}
                  defaultOptions={true}
                  onChange={(e) => {
                    setFieldValue('clientIds', e && e.map((e) => e.value));
                  }}
                  apiCallFunction={clientApiCall}
                  loadOptions={clientApiCall}
                />
              </S.LineSectionStyled>
            </S.SectionStyled>
          </S.ContainerOperationStyled>

          <div style={{ marginTop: -10 }}>
            <SectionDivider label="Requerimento de Qualidade e Segurança" />
            <div style={{ position: 'relative', top: 15, marginBottom: 30 }}>
              <InputTextarea
                id="quality_and_safety"
                name="quality_and_safety"
                onChange={handleChange}
                value={values.quality_and_safety}
                hasError={!!errors?.quality_and_safety && !!touched?.quality_and_safety}
                errorMessage={String(errors.quality_and_safety)}
              />
            </div>
          </div>
          <AttachDocumentsSection />
          <FooterPage.Root>
            <FooterPage.RightContent>
              <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleVerifyInputs} />
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
            </FooterPage.RightContent>
            <FooterPage.LeftContent>
              <ToggleCheckboxView
                id="status"
                name="status"
                label="Ativo"
                checked={values.status === 'active'}
                onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
              />
            </FooterPage.LeftContent>
          </FooterPage.Root>
        </S.FormStyled>
      </>
    );
  };

  const LocationForm = withFormik<ILocationDomain, ILocationDomain>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <LocationForm {...locationValues} />;
};

export default LocationFormView;
