import React, { FC, useCallback, useContext } from 'react';
import { EquipmentConfirmationModalView } from './equipment-confirmation-modal-view.component';
import {
  IModalButtonProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';
import { useEquipmentVoucherRemoveList } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/hooks/use-equipment-voucher-remove-list/use-equipment-voucher-remove-list.hook';
import { EquipmentVoucherContext } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.context';

export const EquipmentConfirmationModal: FC = () => {
  const { equipmentVoucherList } = useContext(EquipmentVoucherContext);
  const equipmentVoucherRemoveListHook = useEquipmentVoucherRemoveList();
  const { setCloseModal, isLoading } = equipmentVoucherList.deleteModalProps;

  const handleDeleteSelectedList = useCallback(() => {
    equipmentVoucherRemoveListHook();
  }, [equipmentVoucherRemoveListHook]);

  const secondaryButtonProps: IModalButtonProps = {
    label: 'Voltar',
    handleActionButton: setCloseModal,
  };

  const primaryButtonProps: IModalPrimaryButtonProps = {
    label: 'Excluir',
    isLoading: isLoading,
    handleActionButton: handleDeleteSelectedList,
    color: 'red',
  };

  return (
    <EquipmentConfirmationModalView
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    />
  );
};
