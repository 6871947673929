import {
  PurchaseOfferMatchListActionType,
  PurchaseOfferMatchListSelectedActionType,
  PurchaseOfferMatchProposalActionType,
} from '../../purchase-offer.action';
import { IPurchaseOfferMatchListActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-match-list.types';
import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { proposalOfferMatchProposalInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-proposal-initial-values.constant';
import { purchaseOfferMatchSelectedInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-selected-initial-values.constant';

export const purchaseOfferMatchListReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferMatchListActions,
): IPurchaseOfferContext => {
  switch (action.type) {
    case PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_REQUEST:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          statusRequest: 'PROCESSING',
          purchaseOfferMatchList: [],
        },
      };
    case PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_SUCCESS:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          statusRequest: 'SUCCESS',
          purchaseOfferMatchList: action.payload,
        },
      };
    case PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_ERROR:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          statusRequest: 'ERROR',
          purchaseOfferMatchList: [],
        },
      };
    case PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_LIST_RESET:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          statusRequest: 'INITIAL',
          purchaseOfferMatchList: [],
        },
      };
    case PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_REQUEST:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchProposal: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchProposal,
            statusRequest: 'PROCESSING',
            proposal: proposalOfferMatchProposalInitialValues,
          },
        },
      };
    case PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_SUCCESS:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchProposal: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchProposal,
            statusRequest: 'SUCCESS',
            proposal: action.payload,
          },
        },
      };
    case PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_ERROR:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchProposal: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchProposal,
            statusRequest: 'ERROR',
            proposal: proposalOfferMatchProposalInitialValues,
          },
        },
      };
    case PurchaseOfferMatchProposalActionType.PURCHASE_OFFER_MATCH_PROPOSAL_RESET:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchProposal: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchProposal,
            statusRequest: 'INITIAL',
            proposal: proposalOfferMatchProposalInitialValues,
          },
        },
      };
    case PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_REQUEST:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchListSelected: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchListSelected,
            statusRequest: 'PROCESSING',
            selectedMatch: purchaseOfferMatchSelectedInitialValues,
          },
        },
      };
    case PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_SUCCESS:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchListSelected: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchListSelected,
            statusRequest: 'SUCCESS',
            selectedMatch: action.payload,
          },
        },
      };
    case PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_ERROR:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchListSelected: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchListSelected,
            statusRequest: 'ERROR',
            selectedMatch: purchaseOfferMatchSelectedInitialValues,
          },
        },
      };
    case PurchaseOfferMatchListSelectedActionType.PURCHASE_OFFER_LIST_SELECTED_RESET:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          purchaseOfferMatchListSelected: {
            ...state.purchaseOfferMatchList.purchaseOfferMatchListSelected,
            statusRequest: 'INITIAL',
            selectedMatch: purchaseOfferMatchSelectedInitialValues,
          },
        },
      };
    case PurchaseOfferMatchListActionType.PURCHASE_OFFER_MATCH_SELECT_FILTERS:
      return {
        ...state,
        purchaseOfferMatchList: {
          ...state.purchaseOfferMatchList,
          filters: {
            ...state.purchaseOfferMatchList.filters,
            selectedFilter: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
