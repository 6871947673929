import { GoogleMap } from '@react-google-maps/api';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { ModalMatches } from 'components/matches/modal-match-list';
import Title from 'components/title/title';
import React from 'react';
import { Map } from '../components/map/map';
import { WrappedFullMap } from '../components/map/map-view.styled';
import { useTerritorial } from '../context/territorial-vision.context';

const mapCenter = { lat: -23.5475, lng: -46.63611 };

type FloatingModalStyles = 'wrapper' | 'matchesPickDateModal';

type CssStyles = {
  [key in FloatingModalStyles]: React.CSSProperties;
};

export const TerritorialVisionBodyView: React.FC = () => {
  const { selectedBackHaul, setSelectedBackHaul } = useTerritorial();

  return (
    <React.Fragment>
      <HeaderPage breadcrumbList={['Tracking', 'Mapa']} />
      <Title title="Acompanhe em tempo real a localização dos veículos contratados." hasTrace />
      <WrappedFullMap>
        <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={mapCenter} zoom={6}>
          <Map />
        </GoogleMap>
      </WrappedFullMap>
      {selectedBackHaul && (
        <ModalMatches
          idleness={selectedBackHaul}
          idlenessId={selectedBackHaul.id}
          closeModal={() => setSelectedBackHaul(undefined)}
          wrapperStyles={styles.wrapper}
          matchesPickDateModalStyles={styles.matchesPickDateModal}
        />
      )}
    </React.Fragment>
  );
};

const styles: CssStyles = {
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  matchesPickDateModal: {
    zIndex: 1050,
  },
};
