import { ScheduleInput } from 'domain-v2/schedule';

export const convertScheduleGet = (schedule: ScheduleInput): ScheduleInput => {
  return {
    id: schedule.id,
    origin: {
      id: schedule.origin.id,
      type: schedule.origin.type,
      name: schedule.origin.name,
      cnpj: schedule.origin.cnpj,
      address: {
        cep: schedule.origin.address?.cep,
        city: schedule.origin.address?.city,
        complement: schedule.origin.address?.complement,
        neighborhood: schedule.origin.address?.neighborhood,
        number: schedule.origin.address?.number,
        qualityAndSafety: schedule.origin.address?.qualityAndSafety,
        street: schedule.origin.address?.street,
        uf: schedule.origin.address?.uf,
      },
      emails: schedule.origin.emails,
      qualityAndSafety: schedule.origin.qualityAndSafety,
    },
    destination: {
      id: schedule.destination.id,
      type: schedule.destination.type,
      name: schedule.destination.name,
      cnpj: schedule.destination.cnpj,
      address: {
        cep: schedule.destination.address?.cep,
        city: schedule.destination.address?.city,
        complement: schedule.destination.address?.complement,
        neighborhood: schedule.destination.address?.neighborhood,
        number: schedule.destination.address?.number,
        qualityAndSafety: schedule.destination.address?.qualityAndSafety,
        street: schedule.destination.address?.street,
        uf: schedule.destination.address?.uf,
      },
      emails: schedule.destination.emails,
      qualityAndSafety: schedule.destination.qualityAndSafety,
    },
    cargo: {
      shippingReturn: schedule.cargo.shippingReturn,
      product: schedule.cargo.product,
      weight: Number(schedule.cargo.weight),
      cubagem: Number(schedule.cargo.cubagem),
      volume: Number(schedule.cargo.volume),
      merchValue: Number(schedule.cargo.merchValue),
      inPallet: schedule.cargo.inPallet,
      pallet: Number(schedule.cargo.pallet),
      order: schedule.cargo.order,
      comments: schedule.cargo.comments,
      requirements: {
        quality: {
          id: schedule.cargo.requirements.quality?.id ?? 0,
          values: schedule.cargo.requirements.quality?.values ?? [],
        },
        security: {
          id: schedule.cargo.requirements.security?.id ?? 0,
          values: schedule.cargo.requirements.security?.values ?? [],
        },
        custom: {
          id: schedule.cargo.requirements.custom?.id ?? 0,
          values: schedule.cargo.requirements.custom?.values ?? [],
        },
      },
      vehicleType: schedule.cargo.vehicleType,
      vehicleCategoryType: schedule.cargo.vehicleCategoryType,
    },
    detail: {
      levyInitialDate: schedule.detail.levyInitialDate,
      levyEndDate: schedule.detail.levyEndDate,
      deliveryInitialDate: schedule.detail.deliveryInitialDate,
      deliveryEndDate: schedule.detail.deliveryEndDate,
      internalNumber: schedule.detail.internalNumber,
      ocInternalNumber: schedule.detail.ocInternalNumber,
      boardingNumber: schedule.detail.boardingNumber,
      schedulingPassword: schedule.detail.schedulingPassword,
    },
    retentionFines: {
      freeTime: schedule.retentionFines?.freeTime,
      vehicleType: schedule.retentionFines?.vehicleType,
      hourlyFine: schedule.retentionFines?.hourlyFine,
      dailyFine: schedule.retentionFines?.dailyFine,
    },
    company: {
      id: schedule.company.id,
      tradeName: schedule.company.tradeName,
    },
    customFields: schedule.customFields,
    freightModality: schedule.freightModality,
    myRoute: schedule.myRoute,
    distance: schedule.distance,
    operation: schedule.operation,
    statusCode: schedule.statusCode,
    type: schedule.type,
    co2: schedule.co2,
    invoicedBy: schedule.invoicedBy,
    operationType: schedule.operationType,
    expirationTime: schedule.expirationTime,
    descriptionCancel: schedule.descriptionCancel,
    shippingId: schedule.shippingId,
    driverId: schedule.driverId,
    vehicleId: schedule.vehicleId,
    shipping: schedule.shipping,
    driver: schedule.driver,
    vehicle: schedule.vehicle,
    companyBuyer: schedule.companyBuyer,
    freithgtPayable: schedule.freithgtPayable,
    freithgtReceivable: schedule.freithgtReceivable,
    freithgtShipping: schedule.freithgtShipping,
  };
};
