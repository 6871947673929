import { useCallback, useReducer } from 'react';
import { homeInitialState } from '../../home.constants';
import { IHomeBids } from '../../types/home-bids.types';
import { homeBidsReducer } from '../../reducers/home-bids/home-bids.reducer';
import { HomeBidsActionType } from '../../home.action';
import { IBidOfferDomain } from 'src-new/pages/home/pages/home/domains/home-bids/bid-offer.domain';

export const useHomeBidsContextValues = (): IHomeBids => {
  const [state, dispatch] = useReducer(homeBidsReducer, homeInitialState);

  const setHomeBidsRequest = useCallback(() => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_REQUEST, payload: undefined });
  }, []);

  const setHomeBidsSuccess = useCallback((homeBids: Array<IBidOfferDomain>) => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_SUCCESS, payload: homeBids });
  }, []);

  const setHomeBidsError = useCallback(() => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_ERROR, payload: undefined });
  }, []);

  const setNextPage = useCallback((page?: number) => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_NEXT_PAGE, payload: page });
  }, []);

  const setPageLimit = useCallback(() => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_PAGE_LIMIT, payload: undefined });
  }, []);

  const setHomeBidsReset = useCallback(() => {
    dispatch({ type: HomeBidsActionType.HOME_BIDS_RESET, payload: undefined });
  }, []);

  return {
    ...state.homeBids,
    setHomeBidsRequest,
    setHomeBidsSuccess,
    setHomeBidsError,
    setHomeBidsReset,
    paginationActions: {
      ...state.homeBids.paginationActions,
      setNextPage,
      setPageLimit,
    },
  };
};
