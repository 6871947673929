import { useCallback, useContext, useMemo } from 'react';
import { HomeButtonsView } from './home-buttons-view.component';
import { useNavigate } from 'react-router-dom';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { IModalOptions } from './home-buttons.types';

export const HomeButtons: React.FC = () => {
  const { homeModals } = useContext(HomeContext);
  const navigate = useNavigate();

  const handleNewFreight = useCallback(
    (open: boolean) =>
      open ? homeModals.setHomeModalsOpen({ modalType: 'newFreight' }) : homeModals.setHomeModalsClose(),
    [homeModals],
  );

  const handleTracking = useCallback(() => navigate('/fretes'), [navigate]);

  const handleMatches = useCallback(() => navigate('/matches-realizados'), [navigate]);

  const modalOptions = useMemo(
    (): IModalOptions => ({ open: homeModals.open, type: homeModals.homeModalType }),
    [homeModals.homeModalType, homeModals.open],
  );

  return (
    <HomeButtonsView
      modalOptions={modalOptions}
      handleNewFreight={handleNewFreight}
      handleTracking={handleTracking}
      handleMatches={handleMatches}
    />
  );
};
