import { registerCompanyService } from '../../services/company-register/company-register.service';

import { ICompany } from 'domain/company';

const useRegisterCompanyHook = () => {
  return async (company: ICompany) => {
    await registerCompanyService(company);
  };
};

export { useRegisterCompanyHook };
