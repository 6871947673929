/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import TableFreightListBodyView from './table-freight-list-body-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';
import { useTableFreightUpload } from 'pages/table-freight/hooks/use-table-freight-upload/use-table-freight-upload.hook';
import { useReportExcelHook } from 'pages/table-freight/hooks/use-table-freight-report-excel/use-table-freight-report-excel.hook';
import { useTableFreightList } from 'pages/table-freight/hooks/use-table-freight-list/use-table-freight-list.hook';
import { useDeleteTableFreightListHook } from 'pages/table-freight/hooks/use-delete-table-freight-list/use-delete-table-freight-list.hook';
import { TableFreightContext } from 'pages/table-freight/contexts/table-states/table-freight.context';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';
import ModalUpload from 'components/modal-upload/modal-upload';

const Options: Array<ReportOption> = [{ label: 'Extrair Relatório', value: 'all-excel' }];

const TableFreightListBody: React.FC = () => {
  const { tableFreight, tableFreightIsLoading, tableFreightPageInfo } = useContext(TableFreightContext);

  const [showModal, setShowModal] = useState(false);
  const [, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Todos');
  const [currentPage, setCurrentPage] = useState(1);

  const deleteTableFreightHook = useDeleteTableFreightListHook();
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);

  const tableFreightListHook = useTableFreightList();
  const GenerateExcelHook = useReportExcelHook();
  const tableFreightUploadHook = useTableFreightUpload();

  useEffect(() => {
    tableFreightListHook({
      currentPage: currentPage,
    });
  }, [currentPage, tableFreightListHook]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'all-excel':
          file = await GenerateExcelHook();
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'TABELA_FRETES');
      }
    },
    [GenerateExcelHook],
  );

  const handleDeleteList = async (ids: number[]) => {
    deleteTableFreightHook(ids)
      .then(() => {
        showMessageFormatted({
          message: 'Registros deletados com sucesso.',
          type: 'success',
        });
      })
      .catch((error) =>
        showMessageFormatted({
          error: error,
          type: 'error',
        }),
      );
    tableFreightListHook({
      currentPage: currentPage,
    });
    setIdsToRemove;
  };

  const handleOpenUploadXlsx = async () => {
    setShowModal(true);
  };

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await tableFreightUploadHook(formData);
        if (data.uploadDate === 'error') {
          showToast({
            message: `Erro ao importar dados ${data.error.message}`,
            type: 'error',
          });
        } else {
          showToast({
            message: 'Importação realizada com sucesso',
            type: 'success',
          });
          setSearchInput('');
          tableFreightListHook({
            currentPage: 1,
          });
          setShowModal(false);
        }
      } catch (error: any) {
        showToast({
          message: `Erro ao importar dados ${error.message}`,
          type: 'error',
        });
      }
    },
    [tableFreightUploadHook, setShowModal],
  );

  return (
    <>
      {showModal && (
        <ModalUpload
          title="Upload de Tarifas"
          description="Selecione o arquivo"
          handleCancel={showModalConfirmation}
          handleSuccess={handleSuccess}
        />
      )}
      <TableFreightListBodyView
        tableFreight={tableFreight}
        hasLoading={tableFreightIsLoading}
        tableFreightPageInfo={tableFreightPageInfo}
        setCurrentPage={setCurrentPage}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        onHandleExport={handleGenerateExcel}
        options={Options}
        handleOpenUploadXlsx={handleOpenUploadXlsx}
        handleDeleteList={handleDeleteList}
        idsToRemove={idsToRemove}
      />
    </>
  );
};

export default TableFreightListBody;
