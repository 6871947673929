import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { SectionStyled } from '../section/section.styled';
import { LineSectionStyled } from '../line-section/line-section.styled';

import { LocationScheduleData } from 'utils/create-schedule-location/create-schedule-location';
import { IFormSectionProps } from 'pages/schedule-ltl/types/form-section.types';
import { optionTypes } from 'pages/schedule/constants/label-options';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';

const ScheduleOriginFormView: React.FC<IFormSectionProps> = ({
  errors,
  values,
  handleChange,
  touched,
  setFieldValue,
  apiCall,
  handleCompleteInputs,
}) => {
  const handleChangeOriginClientType = (value?: number) => {
    if (values.origin.clientType === 'plant') {
      setFieldValue('origin.localityId', value);
      setFieldValue('origin.clientId', null);
      handleCompleteInputs && handleCompleteInputs(0, value ?? 0, 'plant');
    } else if (values.origin.clientType === 'client') {
      setFieldValue('origin.clientId', value);
      setFieldValue('origin.localityId', null);
      handleCompleteInputs && handleCompleteInputs(value ?? 0, 0, 'client');
    }
  };

  const location = useLocation();

  const originInfoCreated = LocationScheduleData(location);

  useEffect(() => {
    if (originInfoCreated.originType === 'plant') {
      setFieldValue('origin.localityId', originInfoCreated.originId);
      setFieldValue('origin.clientId', null);
      handleCompleteInputs && handleCompleteInputs(0, originInfoCreated.originId ?? 0, 'plant');
    } else if (originInfoCreated.originType === 'client') {
      setFieldValue('origin.clientId', originInfoCreated.originId);
      setFieldValue('origin.localityId', null);
      handleCompleteInputs && handleCompleteInputs(originInfoCreated.originId ?? 0, 0, 'client');
    }

    setFieldValue('origin.clientType', originInfoCreated.originType);
    setFieldValue('origin.tradingName', originInfoCreated.originName);
    setFieldValue('origin.zipCode', originInfoCreated.originCep);
    setFieldValue('origin.neighborhood', originInfoCreated.originNeighborhood);
    setFieldValue('origin.city', originInfoCreated.originCity);
    setFieldValue('origin.state', originInfoCreated.originUf);
    setFieldValue('origin.street', originInfoCreated.originStreet);
    setFieldValue('origin.number', originInfoCreated.originNumberHouse);
    setFieldValue('origin.complement', originInfoCreated.originComplement);
  }, []);

  return (
    <SectionStyled name="Informações do Empresa">
      <LineSectionStyled columns="1.04fr 4fr">
        <Select
          label="Tipo *"
          id="origin.clientType"
          name="origin.clientType"
          value={values.origin.clientType}
          setFieldValue={setFieldValue}
          hasError={!!errors.origin?.clientType && !!touched.origin?.clientType}
          errorMessage={String(errors.origin?.clientType)}
          options={optionTypes}
          disabled={originInfoCreated.originType ? true : false}
        />

        {originInfoCreated.originName ? (
          <InputField
            label="Informe o Nome"
            id="origin.tradingName"
            name="origin.tradingName"
            type="text"
            onChange={handleChange}
            value={values.origin.tradingName}
            disabled
          />
        ) : (
          <AsyncSelectField
            placeholder=""
            label="Informe o Nome *"
            isClearable={true}
            name="origin.tradingName"
            id="origin.tradingName"
            isDisabled={!values.origin.clientType && true}
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleChangeOriginClientType(e?.value);
              setFieldValue('origin.tradingName', e && e.label);
            }}
            hasError={!!errors.origin?.tradingName && !!touched.origin?.tradingName}
            errorMessage={String(errors.origin?.tradingName)}
            apiCallFunction={async (e: string) => {
              return apiCall && (await apiCall(e, values.origin.clientType));
            }}
            loadOptions={async (e: string) => apiCall && (await apiCall(e, values.origin.clientType))}
            defaultValue={{
              value: 1,
              label: values.tradingName,
            }}
          />
        )}
      </LineSectionStyled>

      <LineSectionStyled columns="0.8fr 1.3fr 1.3fr 0.4fr ">
        <InputField
          label="CEP"
          id="origin.zipCode"
          name="origin.zipCode"
          type="text"
          onChange={handleChange}
          value={values.origin.zipCode}
          hasError={!!errors.origin?.zipCode && !!touched.origin?.zipCode}
          errorMessage={String(errors.origin?.zipCode)}
          disabled
        />

        <InputField
          label="Bairro"
          id="origin.neighborhood"
          name="origin.neighborhood"
          type="text"
          onChange={handleChange}
          value={values.origin.neighborhood}
          hasError={!!errors.origin?.neighborhood && !!touched.origin?.neighborhood}
          errorMessage={String(errors.origin?.neighborhood)}
          disabled
        />

        <InputField
          label="Cidade"
          id="origin.city"
          name="origin.city"
          type="text"
          onChange={handleChange}
          value={values.origin.city}
          hasError={!!errors.origin?.city && !!touched.origin?.city}
          errorMessage={String(errors.origin?.city)}
          disabled
        />

        <InputField
          label="UF"
          id="origin.state"
          name="origin.state"
          type="text"
          onChange={handleChange}
          value={values.origin.state}
          hasError={!!errors.origin?.state && !!touched.origin?.state}
          errorMessage={String(errors.origin?.state)}
          disabled
        />
      </LineSectionStyled>

      <LineSectionStyled columns="3fr 0.5fr 1.5fr">
        <InputField
          label="Endereço"
          id="origin.street"
          name="origin.street"
          type="text"
          onChange={handleChange}
          value={values.origin.street}
          hasError={!!errors.origin?.street && !!touched.origin?.street}
          errorMessage={String(errors.origin?.street)}
          disabled
        />

        <InputField
          label="Nº"
          id="origin.number"
          name="origin.number"
          type="text"
          onChange={handleChange}
          value={values.origin.number}
          hasError={!!errors.origin?.number && !!touched.origin?.number}
          errorMessage={String(errors.origin?.number)}
          disabled
        />

        <InputField
          label="Complemento"
          id="origin.complement"
          name="origin.complement"
          type="text"
          onChange={handleChange}
          value={values.origin.complement}
          hasError={!!errors.origin?.complement && !!touched.origin?.complement}
          errorMessage={String(errors.origin?.complement)}
          disabled
        />
      </LineSectionStyled>
    </SectionStyled>
  );
};

export default ScheduleOriginFormView;
