import React, { ReactNode } from 'react';

import ModalImageView from './modal-image-view';

type ModalImageProps = {
  title: string;
  description: ReactNode;
  handleCancel?: () => void;
  overflow?: boolean;
  smallModal?: boolean;
  height?: number;
};
const ModalImage: React.FC<ModalImageProps> = ({ title, description, handleCancel, smallModal, height, overflow }) => {
  return (
    <ModalImageView
      title={title}
      description={description}
      overflow={overflow}
      smallModal={smallModal}
      height={height}
      handleCancel={handleCancel}
    />
  );
};

export default ModalImage;
