import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { IScheduleRefusedListParams } from '../../types/schedule-refused-list-filters/schedule-refused-list-filters.types';

export const convertScheduleRefusedFilters = (filters: IScheduleRefusedListParams): Array<IParamsFilter> => {
  const entriesBlackList = ['page', 'column', 'order'];

  const entries = Object.entries(filters).map(([key, value]) => ({
    key,
    value,
  }));

  const filteredParams = entries.filter(({ key, value }) => !entriesBlackList.includes(key) && value);

  return filteredParams;
};
