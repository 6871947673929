/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { MatchRegisterInput } from 'domain-v2/match/register';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';

interface ScheduleOriginAndDestinyFormProps {
  type: 'origin' | 'destination';
  idleness?: any;
}

export const OriginAndDestinyBiddingForm: React.FC<ScheduleOriginAndDestinyFormProps> = ({ type, idleness }) => {
  const { values, setFieldValue } = useFormikContext<MatchRegisterInput>();

  const typeMemo = useMemo(() => {
    return type as 'origin' | 'destination';
  }, [type]);

  const typeKeyMemo = useMemo(() => {
    if (type === 'origin') return 'origin';
    return 'destination';
  }, [type]);

  const idlenessValues = useMemo(() => {
    if (!idleness) return;

    if (type === 'origin') {
      return {
        type: idleness.clientOrigin ? 'CLIENT' : 'LOCALITY',
        name: idleness.clientOrigin
          ? `${idleness.clientOrigin?.tradeName} - ${idleness.clientOrigin?.city} - ${idleness.clientOrigin?.uf}`
          : `${idleness.localityOrigin?.name} - ${idleness.localityOrigin?.city} - ${idleness.localityOrigin?.uf}`,
      };
    }

    return {
      type: idleness.clientDestination ? 'CLIENT' : 'LOCALITY',
      name: idleness.clientDestination
        ? `${idleness.clientDestination?.tradeName} - ${idleness.clientDestination?.city} - ${idleness.clientDestination?.uf}`
        : `${idleness.localityDestination?.name} - ${idleness.localityDestination?.city} - ${idleness.localityDestination?.uf}`,
    };
  }, [idleness, type]);

  useEffect(() => {
    setFieldValue(`${typeKeyMemo}.type`, idlenessValues?.type);
  }, [idleness, idlenessValues]);

  return (
    <SectionLayout name={`${typeMemo} information`}>
      <LineSectionLayout columns="0.93fr 3fr 0.07fr">
        <Select
          label={type === 'origin' ? 'Origem' : 'Destino'}
          id={`${typeKeyMemo}.type`}
          name={`${typeKeyMemo}.type`}
          value={values[typeKeyMemo].type}
          setFieldValue={setFieldValue}
          options={OriginAndDestinyTypeOptions}
          disabled
        />

        <InputField
          id={idlenessValues?.name ?? 'name'}
          name={idlenessValues?.name ?? 'name'}
          label="Informe o Nome *"
          type={'text'}
          value={idlenessValues?.name}
          disabled
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};
