import { IWeighingListResponse } from './get-weighing-list.types';
import { IWeighingListFilterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters.domain';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { buildURL } from 'src-new/pages/execution/pages/weighing/utils/build-url/build-url';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IWeighingListDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list.domain';
import { convertWeighingResponseToDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/services/get-weighing-list/mappers/convert-weighing-response-to-domain/convert-weighing-response-to-domain.mapper';

export const getWeighingListService = async (
  filters: IWeighingListFilterDomain,
): Promise<IListPage<IWeighingListDomain>> => {
  const urlParams = buildURL(filters);

  const { data } = await API_TRAVELS_SVC.get<IListPage<IWeighingListResponse>>(`v1/weighing?${urlParams}`);

  return {
    items: data.items.map(convertWeighingResponseToDomain),
    meta: data.meta,
  };
};
