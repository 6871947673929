import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const DiviserStyled = styled.div`
  width: 100%;
  margin-top: 20px;
  border-bottom: solid 1px #d1d1d1;
`;

export const WrapperSectionCompanyDataStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px 20px;
`;

export const NameWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const TradeNameWrapperStyled = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
`;

export const UploadImageStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-start: 6;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 8;
  margin-top: 24px;
`;

export const OptionStyled = styled.option``;

export const ContainerTelephoneStyled = styled.div`
  margin-top: 3px;
`;

export const ContainerButtonStyled = styled.div`
  margin-top: 35px;
`;
