import API from 'utils/API/API';

export type ExportReportType = 'CLIENT' | 'LOCATION';

export const exportReportService = async (
  search: string,
  type: ExportReportType,
  status?: 'ativo' | 'inativo',
): Promise<ArrayBuffer | undefined> => {
  const convertedStatus = status ? convertStatus(status) : undefined;
  const { data } = await API.get(getUrlRequestByType(type), {
    params: {
      status: convertedStatus,
      search,
    },
    responseType: 'arraybuffer',
  });

  return data;
};

const getUrlRequestByType = (type: ExportReportType) => {
  switch (type) {
    case 'LOCATION':
      return 'localities-export-excel';
    case 'CLIENT':
      return 'v2/clients/export-file';
  }
};

const convertStatus = (status: 'ativo' | 'inativo'): 'active' | 'inactive' => {
  return status === 'ativo' ? 'active' : 'inactive';
};
