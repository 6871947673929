import React, { ReactElement, useCallback, useMemo } from 'react';
import * as S from './modal-bidding-offer-view.styled';
import { Button, Tag } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';
import TooltipComponent from 'components/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import { SendIcon } from 'assets/icons/send-icon';
import { BiddingOfferProposedValue } from './components/bidding-offer-proposed-value/bidding-offer-proposed-value.component';
import { useFormikContext } from 'formik';
import { IBiddingOfferForm } from './types/bidding-offer-form/bidding-offer-form.types';
import Checkbox from 'components/checkbox/checkbox';
import { ICompany } from 'domain/company';
import { IShippingCompanyDomain } from 'src-new/domains/shipping-company.domain';
import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { convertNumberFormat } from 'src-new/utils/convert-number-format.util';
import { Loading } from '../loading/loading.component';

interface IModalBiddingOfferViewProps {
  company?: ICompany;
  companyShipping?: IShippingCompanyDomain;
  userCompany?: ICompany;
  freeTime?: IFreeTimeDomain;
  isLoading: boolean;
  handleClose: () => void;
  disableButton: (freightValue: number, termAgree: boolean) => boolean;
}
export const ModalBiddingOfferView: React.FC<IModalBiddingOfferViewProps> = ({
  company,
  companyShipping,
  userCompany,
  freeTime,
  isLoading,
  handleClose,
  disableButton,
}) => {
  const { values, handleSubmit, setFieldValue } = useFormikContext<IBiddingOfferForm>();

  const renderTitle = useMemo((): string => {
    if (values.isSaleOffer) {
      return `Proposta de Venda - Rota ID ${values.id}`;
    }

    return `Proposta de Venda - Frete ID ${values.id}`;
  }, [values.id, values.isSaleOffer]);

  const shippingHomologate = useMemo((): { label: string; text: string; color: ThemeColorsV2Type } => {
    if (Number(company?.id ?? 0) === companyShipping?.company.id) {
      const label = 'HOMOLOGADO';
      const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELO TRANSPORTADOR';

      return { label, text, color: ThemeColorsV2Type.Green };
    }

    const label = 'NÃO HOMOLOGADO';
    const text = 'CTE PRIMÁRIO DEVERÁ SER EMITIDO PELA LOGSHARE E CONTRA-CTE PELA TRANSPORTADORA';

    return { label, text, color: ThemeColorsV2Type.Red };
  }, [companyShipping?.company.id, company?.id]);

  const renderTags = useMemo((): ReactElement => {
    return (
      <S.HeaderTag>
        {!values.isSaleOffer && (
          <Tag label="Spot" color={ThemeColorsV2Type.Blue} variant="solid" display="inline-flex" size="md" />
        )}

        {values.isShippingCompany && (
          <Tag
            label={shippingHomologate.label}
            color={shippingHomologate.color}
            variant="solid"
            display="inline-flex"
            RightIcon={
              <TooltipComponent
                title={shippingHomologate.text}
                location="right"
                icon={
                  <div style={{ display: 'flex' }}>
                    <InfoIcon sx={{ height: '11px', width: '11px' }} />
                  </div>
                }
              />
            }
            size="md"
          />
        )}

        {values.cargo?.requirements.quality.values.map((requirement) => (
          <Tag
            key={requirement}
            label={requirement}
            color={ThemeColorsV2Type.Blue}
            variant="solid"
            display="inline-flex"
            size="md"
          />
        ))}

        {values.cargo?.requirements.security.values.map((requirement) => (
          <Tag
            key={requirement}
            label={requirement}
            color={ThemeColorsV2Type.Blue}
            variant="solid"
            display="inline-flex"
            size="md"
          />
        ))}
      </S.HeaderTag>
    );
  }, [
    shippingHomologate,
    values.cargo?.requirements.quality.values,
    values.cargo?.requirements.security.values,
    values.isSaleOffer,
    values.isShippingCompany,
  ]);

  const renderDates = useCallback(
    (initialDate?: string, endDate?: string): ReactElement | undefined => {
      if (values.isSaleOffer || !initialDate || !endDate) return;

      const formattedEndDate = dayjs(endDate).format('DD/MM');
      const formattedInitialTime = dayjs(initialDate).format('HH:mm');
      const formattedEndTime = dayjs(endDate).format('HH:mm');

      return <S.Label>{`${formattedEndDate} ${formattedInitialTime} - ${formattedEndTime}`}</S.Label>;
    },
    [values.isSaleOffer],
  );

  const renderPaymentTerm = useMemo((): string => {
    if (!!company?.paymentTerm && company?.paymentTerm !== 'null') {
      return `${company.paymentTerm} DIAS CORRIDOS`;
    }

    return '60 DIAS CORRIDOS';
  }, [company?.paymentTerm]);

  const renderFooterInfo = useMemo((): ReactElement => {
    if (values.isSaleOffer) {
      return (
        <S.SaleFooter>
          <InfoIcon sx={{ display: 'flex', width: '15px' }} />
          <S.TermsText>A proposta de venda será enviada para o embarcador responsável pela rota.</S.TermsText>
        </S.SaleFooter>
      );
    }

    return (
      <S.CheckboxSection>
        <Checkbox id="terms" name="terms" onChange={() => setFieldValue('termAgree', !values.termAgree)} />

        <S.TermsText>
          {'Ao ofertar, confirmo que estou de acordo com o '}
          <S.LinkText target="_blank" href="https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Regras_de_GR.pdf">
            plano de gerenciamento de riscos
          </S.LinkText>
          {' e '}
          <S.LinkText
            target="_blank"
            href="https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Termos+Logshare+%5BV1%5D.pdf"
          >
            termos de uso
          </S.LinkText>
          {' da logshare.'}
        </S.TermsText>
      </S.CheckboxSection>
    );
  }, [setFieldValue, values.isSaleOffer, values.termAgree]);

  if (isLoading) {
    return (
      <S.Wrapper>
        <S.Content>
          <Loading />
        </S.Content>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Header>
          <S.HeaderContent>
            <S.HeaderTitle>{renderTitle}</S.HeaderTitle>
            {renderTags}
          </S.HeaderContent>

          <S.ButtonCloseContainer onClick={handleClose}>
            <S.CloseModal>X</S.CloseModal>
          </S.ButtonCloseContainer>
        </S.Header>

        <S.Main>
          <S.WrapperLocations>
            <S.ContainerLocations>
              <S.ContainerConnector>
                <S.StepIcon />
                <S.Connector height={values.isSaleOffer ? 75 : 90} />
                <S.StepIcon />
              </S.ContainerConnector>

              <S.ContainerOriginAndDestination>
                <S.Div>
                  <S.Title>Origem</S.Title>

                  <S.Title isBold>
                    {values.origin.city} - {values.origin.uf}
                  </S.Title>

                  <S.Div>{renderDates(values.detail?.levyInitialDate, values.detail?.levyEndDate)}</S.Div>
                </S.Div>

                <S.Div>
                  <S.Title>Destino</S.Title>

                  <S.Title isBold>
                    {values.destination.city} - {values.destination.uf}
                  </S.Title>

                  <S.Div>{renderDates(values.detail?.deliveryInitialDate, values.detail?.deliveryEndDate)}</S.Div>
                </S.Div>
              </S.ContainerOriginAndDestination>
            </S.ContainerLocations>

            <S.ContainerVehicle>
              <S.Title>Veículo</S.Title>

              <S.Title isBold>
                {values.vehicle.type} - {values.vehicle.category}
              </S.Title>
            </S.ContainerVehicle>

            <S.ContainerPaymentTerm>
              <S.Title>Prazo de Pagamento</S.Title>

              <S.Title isBold withoutPadding>
                {renderPaymentTerm}
              </S.Title>
            </S.ContainerPaymentTerm>
          </S.WrapperLocations>

          <S.WrapperDetails>
            <S.Div>
              <S.Title>Detalhes do Frete</S.Title>

              <S.ContainerDetails>
                <S.Label>
                  Peso: <S.Title withoutPadding>{values.capacity.weight ?? 0} KG</S.Title>
                </S.Label>

                <S.Label>
                  Cubagem: <S.Title withoutPadding>{values.capacity.cubagem ?? 0} M³</S.Title>
                </S.Label>

                <S.Label>
                  Paletizado:
                  <S.Title withoutPadding>{values.capacity.inPallet}</S.Title>
                </S.Label>

                <S.Label>
                  Volume: <S.Title withoutPadding>{values.capacity.volume}</S.Title>
                </S.Label>

                <S.Label>
                  Produto Predominante: <S.Title withoutPadding>{values.capacity.product}</S.Title>
                </S.Label>

                <S.Label>
                  Distância:
                  <S.Title withoutPadding>{convertNumberFormat.format(values.additionalDistance)}KM</S.Title>
                </S.Label>
              </S.ContainerDetails>
            </S.Div>

            <S.Div>
              <S.Title>Regras de Freetime</S.Title>

              <S.ContainerDetails>
                <S.Label>
                  Freetime: <S.Title withoutPadding>{freeTime?.freeTime ?? 0} HORAS</S.Title>
                </S.Label>

                <S.Label>
                  Diária:
                  <S.Title withoutPadding>{convertCurrencyFormat.format(freeTime?.dailyFine ?? 0)}</S.Title>
                </S.Label>

                <S.Label>
                  Hora Adicional:
                  <S.Title withoutPadding>{convertCurrencyFormat.format(freeTime?.hourlyFine ?? 0)}</S.Title>
                </S.Label>
              </S.ContainerDetails>
            </S.Div>
          </S.WrapperDetails>

          <BiddingOfferProposedValue
            company={company}
            userCompany={userCompany}
            shippingHomologate={!values.isSaleOffer && shippingHomologate.label === 'HOMOLOGADO'}
          />
        </S.Main>

        <S.WrapperFooter>
          {renderFooterInfo}

          <Button
            label="Ofertar"
            color={ThemeColorsV2Type.Blue}
            size="md"
            variant="solid"
            onPress={handleSubmit}
            RightIcon={<SendIcon />}
            disabled={disableButton(values.proposedValue, !values.isSaleOffer && !values.termAgree)}
          />
        </S.WrapperFooter>
      </S.Content>
    </S.Wrapper>
  );
};
