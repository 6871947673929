import styled, { css } from 'styled-components';

type InputProps = { bgColor: 'aqua' | 'blue' | 'red' };

export const WrapperStyled = styled.div`
  width: 100%;
  height: 28px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
`;

export const InputStyled = styled.input<InputProps>`
  ${({ theme, bgColor }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 100%;
    height: 28px;
    border: 1px solid ${theme.colorsV2[bgColor]};
    border-radius: 50px;
    position: relative;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      content: '';
      width: 100%;
      height: 34px;
      opacity: 0;
      transition: opacity 0.2s;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:focus {
      box-shadow: 0 0 0.5rem #fff;
    }

    &:checked + label {
      color: white;
    }

    &:checked {
      background: ${theme.colorsV2[bgColor]};

      &::before {
        opacity: 1;
      }
    }
  `}
`;

export const LabelStyled = styled.label<InputProps>`
  ${({ theme, bgColor }) => css`
    text-align: center;
    position: absolute;
    padding: 0 8px;
    width: 100%;
    cursor: pointer;
    font-size: 9px;
    font-weight: 700;
    color: ${theme.colorsV2[bgColor]};
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:checked {
      color: white;

      &::before {
        opacity: 1;
      }
    }
  `}
`;
