import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';
import axios from 'axios';
import { IUserChat } from 'src-new/domains/user-chat.domain';
import { convertUserChatDomainToRequest } from 'src-new/services/chat/create-update-user-chat/convert-user-chat-domain-to-request/convert-user-chat-domain-to-request.mapper';

export const createUpdateUserChatService = async (userDomain: IUserChat): Promise<void> => {
  const { id, user } = convertUserChatDomainToRequest(userDomain);
  const chatEnvironment = getChatEnvironment();

  await axios.put(`https://api.talkjs.com/v1/${chatEnvironment.appId}/users/${id}`, user, {
    headers: {
      Authorization: `Bearer ${chatEnvironment.secretKey}`,
    },
  });
};
