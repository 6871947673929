import dayjs from 'dayjs';
import { IOptionsDomain } from 'src-new/domains/options.domain';
import {
  ISaleOfferFormRegisterPayloadDomain,
  ISaleOfferLocationsRegisterPayloadDomain,
  ISaleOfferShipperRegisterPayloadDomain,
  ISaleOfferShipperTariffRegisterPayloadDomain,
} from './sale-offer-form-register.domain';

const saleOfferLocationsRegisterInitialValuesDomain: ISaleOfferLocationsRegisterPayloadDomain = {
  id: 0,
  type: '',
  name: '',
  cnpj: '',
  address: {
    city: '',
    uf: '',
    complement: '',
    neighborhood: '',
    numberHouse: '',
    street: '',
    cep: '',
    lat: 0,
    lng: 0,
  },
};

export const saleOfferShipperTariffRegisterInitialValuesDomain: ISaleOfferShipperTariffRegisterPayloadDomain = {
  shipping: {
    id: 0,
    name: '',
  },
  vehicleType: '',
  vehicleCategory: '',
  weight: '0',
  pallets: '0',
  cubage: '0',
  frequency: '0',
  invoicedBy: 'logshare',
  gris: '0',
  advalorem: '0',
  valueShipping: '0.00',
  valueShipper: '0.00',
  valueLogshare: '0.00',
  valueTotal: 0.0,
  freetime: {
    time: 0,
    dailyFine: 0,
    hourlyFine: 0,
  },
  feeLogshare: 0,
  isActive: true,
};

const saleOfferShipperRegisterInitialValuesDomain: ISaleOfferShipperRegisterPayloadDomain = {
  partnerShipper: {
    id: 0,
    name: 'Para todos Embarcadores',
  },
  initialDate: dayjs().toISOString(),
  finalDate: dayjs().add(5, 'year').toISOString(),
  tariff: [],
};

export const saleOfferShipperModalRegisterInitialValuesDomain: ISaleOfferShipperRegisterPayloadDomain = {
  partnerShipper: {
    id: 0,
    name: '',
  },
  initialDate: dayjs().toISOString(),
  finalDate: dayjs().add(5, 'year').toISOString(),
  tariff: [],
};

export const saleOfferFormRegisterInitialValuesDomain: ISaleOfferFormRegisterPayloadDomain = {
  origin: saleOfferLocationsRegisterInitialValuesDomain,
  destination: saleOfferLocationsRegisterInitialValuesDomain,
  cargoType: 'ftl',
  prohibitedCargo: ['Sem Restrição'],
  prohibitedOtherCargo: '',
  saleOfferDetails: [saleOfferShipperRegisterInitialValuesDomain],
};

export const cargoTypeOptions: IOptionsDomain[] = [
  { value: 'ftl', label: 'FTL' },
  { value: 'ltl', label: 'LTL' },
  { value: 'stl', label: 'STL' },
];

export const prohibitedCargoOptions: Array<{ value: string; label: string }> = [
  { value: 'Alimentos Naturais', label: 'ALIMENTOS NATURAIS' },
  { value: 'Carga Viva', label: 'CARGA VIVA' },
  { value: 'Cigarros', label: 'CIGARROS' },
  { value: 'Eletrônicos', label: 'ELETRÔNICOS' },
  { value: 'Fertilizantes', label: 'FERTILIZANTES' },
  { value: 'Químicos', label: 'QUÍMICOS' },
  { value: 'Vestuário', label: 'VESTUÁRIO' },
  { value: 'Outros', label: 'OUTROS' },
  { value: 'Sem Restrição', label: 'SEM RESTRIÇÃO' },
];

export const invoicedByOptions: Array<{ value: 'transportadora' | 'logshare'; label: string }> = [
  { value: 'transportadora', label: 'TRANSPORTADORA' },
  { value: 'logshare', label: 'LOGSHARE' },
];
