import { useCallback, useContext } from 'react';
import { updateNFeDataService } from '../../services/update-nfe-data/update-nfe-data.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getNFeDataService } from '../../services/get-nfe-data/get-nfe-data.service';

export const useUpdateNFeData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(
    async (files: FileList) => {
      try {
        scheduleDocuments.nfeDataProps.setNFeDataRequest();
        const fileArray = Array.from(files);

        const filesPromises = fileArray.map((file) => updateNFeDataService(file, scheduleDetails.scheduleDetails.id));

        await Promise.all(filesPromises);

        const data = await getNFeDataService(scheduleDetails.scheduleDetails.id);

        scheduleDocuments.nfeDataProps.setNFeDataSuccess(data);
      } catch (error: any) {
        scheduleDocuments.nfeDataProps.setNFeDataError();
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    },
    [scheduleDetails.scheduleDetails.id, scheduleDocuments.nfeDataProps],
  );
};
