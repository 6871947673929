export enum PurchaseOfferModalsActionType {
  PURCHASE_OFFER_MODAL_OPEN = 'PURCHASE_OFFER_MODAL_OPEN',
  PURCHASE_OFFER_MODAL_CLOSE = 'PURCHASE_OFFER_MODAL_CLOSE',
  PURCHASE_OFFER_MODAL_SECONDARY_CLOSE = 'PURCHASE_OFFER_MODAL_SECONDARY_CLOSE',
}

export enum PurchaseOfferListActionType {
  PURCHASE_OFFER_LIST_REQUEST = 'PURCHASE_OFFER_LIST_REQUEST',
  PURCHASE_OFFER_LIST_SUCCESS = 'PURCHASE_OFFER_LIST_SUCCESS',
  PURCHASE_OFFER_LIST_ERROR = 'PURCHASE_OFFER_LIST_ERROR',
  PURCHASE_OFFER_LIST_RESET = 'PURCHASE_OFFER_LIST_RESET',
  PURCHASE_OFFER_SELECT_IDS = 'PURCHASE_OFFER_SELECT_IDS',
  PURCHASE_OFFER_SELECT_FILTERS = 'PURCHASE_OFFER_SELECT_FILTERS',
}

export enum PurchaseOfferMatchListActionType {
  PURCHASE_OFFER_MATCH_LIST_REQUEST = 'PURCHASE_OFFER_MATCH_LIST_REQUEST',
  PURCHASE_OFFER_MATCH_LIST_SUCCESS = 'PURCHASE_OFFER_MATCH_LIST_SUCCESS',
  PURCHASE_OFFER_MATCH_LIST_ERROR = 'PURCHASE_OFFER_MATCH_LIST_ERROR',
  PURCHASE_OFFER_MATCH_LIST_RESET = 'PURCHASE_OFFER_MATCH_LIST_RESET',
  PURCHASE_OFFER_MATCH_SELECT_FILTERS = 'PURCHASE_OFFER_MATCH_SELECT_FILTERS',
}

export enum PurchaseOfferMatchProposalActionType {
  PURCHASE_OFFER_MATCH_PROPOSAL_REQUEST = 'PURCHASE_OFFER_MATCH_PROPOSAL_REQUEST',
  PURCHASE_OFFER_MATCH_PROPOSAL_SUCCESS = 'PURCHASE_OFFER_MATCH_PROPOSAL_SUCCESS',
  PURCHASE_OFFER_MATCH_PROPOSAL_ERROR = 'PURCHASE_OFFER_MATCH_PROPOSAL_ERROR',
  PURCHASE_OFFER_MATCH_PROPOSAL_RESET = 'PURCHASE_OFFER_MATCH_PROPOSAL_RESET',
}

export enum PurchaseOfferMatchListSelectedActionType {
  PURCHASE_OFFER_LIST_SELECTED_REQUEST = 'PURCHASE_OFFER_LIST_SELECTED_REQUEST',
  PURCHASE_OFFER_LIST_SELECTED_SUCCESS = 'PURCHASE_OFFER_LIST_SELECTED_SUCCESS',
  PURCHASE_OFFER_LIST_SELECTED_ERROR = 'PURCHASE_OFFER_LIST_SELECTED_ERROR',
  PURCHASE_OFFER_LIST_SELECTED_RESET = 'PURCHASE_OFFER_LIST_SELECTED_RESET',
}

export enum PurchaseOfferListDownloadReportActionType {
  PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST = 'PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST',
  PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS = 'PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS',
  PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_ERROR = 'PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_ERROR',
  PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_RESET = 'PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_RESET',
}

export enum PurchaseOfferBaitRouteActionType {
  PURCHASE_OFFER_BAIT_ROUTE_REQUEST = 'PURCHASE_OFFER_BAIT_ROUTE_REQUEST',
  PURCHASE_OFFER_BAIT_ROUTE_SUCCESS = 'PURCHASE_OFFER_BAIT_ROUTE_SUCCESS',
  PURCHASE_OFFER_BAIT_ROUTE_ERROR = 'PURCHASE_OFFER_BAIT_ROUTE_ERROR',
  PURCHASE_OFFER_BAIT_ROUTE_RESET = 'PURCHASE_OFFER_BAIT_ROUTE_RESET',
}

export enum PurchaseOfferActionType {
  PURCHASE_OFFER_RESET = 'PURCHASE_OFFER_RESET',
}
