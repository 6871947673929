import { FC } from 'react';

export const PinLocationNewIcon: FC = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00017 0C2.83447 0 0.257324 2.57715 0.257324 5.74285C0.257324 11.4057 5.50589 15.7229 5.7316 15.9057C5.80875 15.9686 5.9059 16 6.00017 16C6.09446 16 6.19161 15.9686 6.26874 15.9057C6.49447 15.7229 11.743 11.4057 11.743 5.74285C11.743 2.57715 9.16589 0 6.00017 0ZM6.00017 7.64C4.95447 7.64 4.10303 6.78857 4.10303 5.74285C4.10303 4.69713 4.95447 3.84571 6.00017 3.84571C7.04588 3.84571 7.89732 4.69713 7.89732 5.74285C7.89732 6.78857 7.0459 7.64 6.00017 7.64Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
