import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import BackhaulTableRow from './components/list/index';

import { UserContext } from 'state/user-context';
import { IBackhaulListValues, IBackhaulValues } from 'domain/backhaul';
import { ModalMatchForm } from 'components-v2/common/modals/match-form';
import EmptyStateTable from 'components/empty-state-table/empty-state-table';
import SearchEmptyListIcon from 'assets/icons/search-empty-list.icon';
import { convertBackhaulToModalMatchMapper } from 'pages/home/mappers/convert-backhaul-to-modal-match/convert-backhaul-to-modal-match.mapper';
import { EmptyListWrapper } from './backhaul.style';

export interface BackhaulListProps {
  backhaul: IBackhaulListValues[];
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const BackhaulListBodyView: React.FC<BackhaulListProps> = ({ backhaul, isLoading, setPage }) => {
  const { user } = useContext(UserContext);

  const [comprarModal, setComprarModal] = useState<{
    isOpen: boolean;
    description: ReactNode;
    backhaul?: IBackhaulValues;
    operation?: string;
    sellId: number;
    exclusiveRate: boolean;
  }>({
    isOpen: false,
    description: null,
    sellId: 0,
    exclusiveRate: false,
  });

  useEffect(() => {
    if (!backhaul) return;
  }, [backhaul, user]);

  const contentTable = useMemo(() => {
    if (backhaul.length === 0) {
      return (
        <EmptyListWrapper>
          <EmptyStateTable
            isLoading={isLoading}
            text="Lista Vazia"
            subText="Nenhum registro localizado."
            icon={<SearchEmptyListIcon />}
          />
        </EmptyListWrapper>
      );
    }

    return (
      <BackhaulTableRow
        backhaul={backhaul}
        setComprarModal={setComprarModal}
        loggedInUser={user}
        setPage={setPage}
        isLoading={isLoading}
      />
    );
  }, [backhaul, isLoading, setPage, user]);

  return (
    <>
      {comprarModal.backhaul && comprarModal.isOpen && (
        <ModalMatchForm
          saleOfferValues={convertBackhaulToModalMatchMapper(comprarModal.backhaul)}
          handleClose={() => setComprarModal((prevState) => ({ ...prevState, isOpen: false }))}
          exclusiveRate={comprarModal.exclusiveRate}
          companyLogo={comprarModal.backhaul.companyPartner.image}
        />
      )}
      {contentTable}
    </>
  );
};
