/* eslint-disable @typescript-eslint/no-explicit-any */
import { SingleDatePicker } from 'react-dates';
import React, { useCallback } from 'react';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';

import { InputDateContainer, LabelStyled, MessageErrorStyled, StyledDatePickerWrapper } from './input-calendar.styled';
import { DatePicketSelect } from './index.style';

import { CalendarIcon } from 'assets/icons/calendar.icon';

import 'react-datetime/css/react-datetime.css';

interface InputCalendarDateHighlight {
  isBetween?: {
    initialDate: Moment | null | undefined;
    finalDate: Moment | null | undefined;
  };
  days?: {
    value: number;
    relation: 'before' | 'after';
  };
  specific?: string[];
}

export interface IInputCalendarProps {
  onDateChange: (date: moment.Moment | null | any) => void;
  onFocusChange: ({ focused }: { focused: boolean }) => void;
  focused: boolean;
  date: Moment | null | undefined;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  label?: string;
  showClearDate?: boolean;
  selectPastDays?: boolean;
  hasPicker?: boolean;
  addDateBlocked?: InputCalendarDateHighlight;
  dateHighlight?: InputCalendarDateHighlight;
  withPortal?: boolean;
  minDate?: Moment | null;
}

interface MonthPickerProps {
  month: moment.Moment;
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  isVisible: boolean;
}

export const InputCalendarView: React.FC<IInputCalendarProps> = ({
  onDateChange,
  onFocusChange,
  focused,
  label,
  date,
  placeholder = '',
  id,
  disabled = false,
  hasError = false,
  errorMessage,
  showClearDate,
  selectPastDays,
  hasPicker,
  addDateBlocked,
  dateHighlight,
  minDate,
  withPortal = true,
}): JSX.Element => {
  const monthYearSelectRender = useCallback(
    ({ month, onMonthSelect, onYearSelect }: MonthPickerProps) => {
      if (hasPicker) {
        const maxSelectableYear = Math.max(moment(moment.now()).add(20, 'year').year(), month.year());
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <DatePicketSelect
                value={month.month()}
                onChange={(e) => {
                  onMonthSelect(month, e.target.value);
                }}
              >
                {moment.months().map((label, value) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </DatePicketSelect>
            </div>
            <div>
              <DatePicketSelect
                value={month.year()}
                onChange={(e) => {
                  onYearSelect(month, e.target.value);
                }}
              >
                {Array.from({ length: 100 }, (v, k) => maxSelectableYear - k).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </DatePicketSelect>
            </div>
          </div>
        );
      }

      return month.format('MMMM YYYY');
    },
    [hasPicker],
  );

  const handleAddDateBlocked = useCallback(
    (day: moment.Moment) => {
      if (addDateBlocked) {
        if (addDateBlocked.days) {
          if (addDateBlocked.days.relation === 'before') {
            return day.isBefore(moment().add(addDateBlocked.days.value + 1, 'days'), 'day');
          } else {
            return day.isAfter(moment().add(addDateBlocked.days.value, 'days'), 'day');
          }
        } else if (addDateBlocked.isBetween?.initialDate && addDateBlocked.isBetween?.finalDate) {
          return !day.isBetween(addDateBlocked.isBetween.initialDate, addDateBlocked.isBetween.finalDate, 'day', '[]');
        } else if (addDateBlocked.isBetween?.initialDate) {
          return day.isBefore(addDateBlocked.isBetween?.initialDate, 'day');
        } else if (addDateBlocked.isBetween?.finalDate) {
          return day.isAfter(addDateBlocked.isBetween?.finalDate, 'day');
        } else if (addDateBlocked.specific) {
          return addDateBlocked.specific.includes(day.format('YYYY-MM-DD'));
        }
      }
      return false;
    },
    [addDateBlocked],
  );

  const handleDaysHighlighted = useCallback(
    (day: moment.Moment) => {
      if (dateHighlight) {
        if (dateHighlight.days) {
          if (dateHighlight.days.relation === 'before') {
            return day.isBefore(moment().add(dateHighlight.days.value, 'days'));
          } else {
            return day.isAfter(moment().add(dateHighlight.days.value, 'days'));
          }
        } else if (dateHighlight.specific) {
          return dateHighlight.specific.includes(day.format('YYYY-MM-DD'));
        }
      }
      return false;
    },
    [dateHighlight],
  );

  return (
    <InputDateContainer>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <StyledDatePickerWrapper hasError={hasError} disabled={disabled}>
        <SingleDatePicker
          verticalSpacing={80}
          verticalHeight={450}
          hideKeyboardShortcutsPanel={true}
          id={id ?? ''}
          numberOfMonths={1}
          onDateChange={onDateChange}
          onFocusChange={onFocusChange}
          focused={focused}
          date={!date?.isValid ? null : date}
          showClearDate={showClearDate}
          withPortal={withPortal}
          placeholder={placeholder}
          disabled={disabled}
          displayFormat={'DD/MM/YYYY hh:MM'}
          isDayHighlighted={dateHighlight ? handleDaysHighlighted : undefined}
          isOutsideRange={selectPastDays ? () => false : (day) => day.isBefore(minDate ? minDate : moment(), 'day')}
          isDayBlocked={addDateBlocked ? (date) => handleAddDateBlocked(date) : undefined}
          customInputIcon={<CalendarIcon />}
          renderMonthElement={monthYearSelectRender}
        />
      </StyledDatePickerWrapper>
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </InputDateContainer>
  );
};
