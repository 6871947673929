import React, {useMemo} from 'react';

import ItemView from './item-view';
import {UserContext} from 'state/user-context';

type ItemProps = {
  name: string;
  path: string;
  icon?: JSX.Element;
  line?: JSX.Element;
  subtitles?: JSX.Element;
  title: string;
  disabled?: boolean;
  shippingTerms: boolean;
  shippingPGR: boolean;
  isNewSchedule: boolean;
  disabledSubtitles: boolean;
  openModals: (modalType: 'newFreight' | 'newPlan') => void;
};

const Item: React.FC<ItemProps> = ({
                                     name,
                                     path,
                                     icon,
                                     line,
                                     subtitles,
                                     title,
                                     disabled = false,
                                     shippingTerms,
                                     shippingPGR,
                                     isNewSchedule,
                                     disabledSubtitles,
                                     openModals,
                                   }) => {
  const {user} = React.useContext(UserContext);

  const isEssencial = useMemo(
    (): boolean => user?.plan === 'basic-buyer' || user?.plan === 'basic-seller',
    [user?.plan],
  );

  const isShippingCompany = useMemo(
    (): boolean => user?.profile === 'shipping-company' && user?.login.includes('@'),
    [user?.login, user?.profile],
  );

  const isShipping = useMemo(
    (): boolean => user?.profile === 'shipping-company',
    [user?.profile],
  );

  return (
    <ItemView
      disabled={disabled}
      name={name}
      path={path}
      icon={icon}
      line={line}
      shippingTerms={shippingTerms}
      shippingPGR={shippingPGR}
      isNewSchedule={isNewSchedule}
      subtitles={subtitles}
      title={title}
      disabledSubtitles={disabledSubtitles}
      isEssencial={isEssencial}
      openModals={openModals}
      isShippingCompany={isShippingCompany}
      isShipping={isShipping}
    />
  );
};

export default Item;
