import { Formik } from 'formik';
import { FC, useCallback } from 'react';
import { saleOfferFormRegisterInitialValuesDomain } from '../../domains/sale-offer-form-register-initial-values.domain';
import { ISaleOfferFormRegisterPayloadDomain } from '../../domains/sale-offer-form-register.domain';
import { useRegisterSaleOffer } from '../../hooks/use-sale-offer-register/use-sale-offer-register.hook';
import { RegisterSaleOfferValidation } from '../../validations/yup-validation';
import { SaleOfferRegisterFormView } from './sale-offer-register-form-view.component';

export const SaleOfferRegisterForm: FC = () => {
  const saleOfferRegisterHook = useRegisterSaleOffer();

  const handleSubmit = useCallback(
    (values: ISaleOfferFormRegisterPayloadDomain) => {
      saleOfferRegisterHook(values);
    },
    [saleOfferRegisterHook],
  );

  return (
    <Formik
      initialValues={saleOfferFormRegisterInitialValuesDomain}
      validationSchema={RegisterSaleOfferValidation}
      onSubmit={handleSubmit}
    >
      <SaleOfferRegisterFormView />
    </Formik>
  );
};
