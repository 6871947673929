import { IContentTable } from 'components/table/table.types';

export const headerVehicleContent = (): IContentTable => {
  return [
    {
      value: '',
      flex: 0,
    },
    {
      value: 'ID',
      flex: 0.5,
    },
    {
      value: 'TIPO',
    },
    {
      value: 'CATEGORIA',
    },
    {
      value: 'PLACA',
    },
    {
      value: 'PLACA 2',
    },
    {
      value: 'PLACA 3',
    },
    {
      value: 'PALLET',
    },
    {
      value: 'CUBAGEM',
    }
  ];
};
