import { ILocationStatus } from 'domain/location';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): ILocationStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativas':
      return 'ativo';
    case 'Inativos':
      return 'inativo';
    case 'Excluídos':
      return 'deletado';
  }
};
