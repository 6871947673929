import styled from 'styled-components';

export const ContainerSearch = styled.div`
  display: flex;
  margin-top: 10px ;

  & > div {
    margin-right: 40px;
  }
`;
