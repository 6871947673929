import React from 'react';

import { ContainerToastStyled, DescriptionStyled } from './toast.styled';

interface ToastViewProps {
  message: string;
}

const ToastView: React.FC<ToastViewProps> = ({ message }) => {
  return (
    <ContainerToastStyled>
      <DescriptionStyled>{message}</DescriptionStyled>
    </ContainerToastStyled>
  );
};

export default ToastView;
