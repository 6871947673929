import { InNegociationMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesContext } from '../../types/matches.types';
import { IInNegociationMatchesKanbanListActions } from '../../types/in-negotiation-matches-kanban.types';

export const inNegotiationMatchesKanbanListReducer = (
  state: IMatchesContext,
  action: IInNegociationMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_REQUEST:
      return {
        ...state,
        inNegotiationMatchesKanbanList: {
          ...state.inNegotiationMatchesKanbanList,
          statusRequest: 'PROCESSING',
          inNegotiationMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_SUCCESS:
      return {
        ...state,
        inNegotiationMatchesKanbanList: {
          ...state.inNegotiationMatchesKanbanList,
          statusRequest: 'SUCCESS',
          inNegotiationMatchesKanbanList: action.payload,
        },
      };
    case InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_ERROR:
      return {
        ...state,
        inNegotiationMatchesKanbanList: {
          ...state.inNegotiationMatchesKanbanList,
          statusRequest: 'ERROR',
          inNegotiationMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case InNegociationMatchesKanbanListActionType.IN_NEGOCIATION_MATCHES_KANBAN_RESET:
      return {
        ...state,
        inNegotiationMatchesKanbanList: {
          ...state.inNegotiationMatchesKanbanList,
          statusRequest: 'INITIAL',
          inNegotiationMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    default:
      return state;
  }
};
