import React from 'react';

import { ICardProps } from '../card.types';
import Card from '../card';

interface IProps {
  cards?: ICardProps[];
}

const CardTariffView: React.FC<IProps> = ({ cards }) => {
  return <Card cards={cards} />;
};

export default React.memo(CardTariffView);
