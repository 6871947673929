import API from 'utils/API/API';
import { ScheduleCustomFields } from 'domain-v2/schedule/register';

export const updateCustomFields = async (scheduleId: number, customFields: Array<ScheduleCustomFields>) => {
  const customFieldsValues = customFields.map(({ ...customFieldValue }) => {
    return {
      id: customFieldValue.customFieldId,
      value: customFieldValue.value,
    };
  });
  const call = await API.put(`v3/schedule/${scheduleId}/custom-fields`, {
    customFields: customFieldsValues,
  });
  return call;
};
