import React, { FC, useCallback, useContext, useMemo } from 'react';
import { SaleOfferPageBodyView } from './sale-offer-page-body-view.component';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const SaleOfferPageBody: FC = () => {
  const { saleOfferModals } = useContext(SaleOfferContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { setInitialLoadingReset } = initialLoading;
  const { modalProps, setFilters } = pageHeaderFilterContext;
  const { setCloseModal } = modalProps;
  const { isOpen, modalType } = saleOfferModals;

  const isOpenModal = useMemo(() => isOpen && modalType === 'sale_offer_match', [isOpen, modalType]);

  const onFilterSubmit = useCallback(
    (values: ISaleOfferListFilterDomain) => {
      setFilters({
        ...values,
        page: 1,
      });
      setCloseModal();
      setInitialLoadingReset();
    },
    [setCloseModal, setFilters, setInitialLoadingReset],
  );

  return <SaleOfferPageBodyView isOpenModal={isOpenModal} onFilterSubmit={onFilterSubmit} />;
};
