import { IModalBiddingOffer } from './bidding-offer.types';

export const modalBiddingOfferInitialValues: IModalBiddingOffer = {
  id: 0,
  additionalDistance: 0,
  company: {
    id: 0,
    name: '',
  },
  origin: {
    name: '',
    city: '',
    uf: '',
  },
  destination: {
    name: '',
    city: '',
    uf: '',
  },
  capacity: {
    cubagem: 0,
    inPallet: '',
    merchValue: 0,
    pallets: 0,
    product: '',
    volume: 0,
    weight: 0,
  },
  cost: {
    feeLogshare: 0,
    freightValue: 0,
    grisAdvalorem: 0,
    icms: 0,
    tollValue: 0,
    totalFreight: 0,
    totalService: 0,
  },
  vehicle: {
    type: '',
    category: '',
  },
};
