import API from 'utils/API/API';
import { IListPage } from 'src-new/domains/list-page.domain';
import { ILocationDomain, LocationStatus } from 'src-new/pages/registrations/domains/location.domain';
import { ILocationResponse } from './get-location-list.types';
import { convertLocationResponseToDomain } from './mappers/convert-location-response-to-domain/convert-location-response-to-domain.mapper';

const LIMIT_PAGE = 50;

export interface IGetLocationListProps {
  search: string;
  page: number;
  status?: LocationStatus;
  order: string;
  column: string;
}

export const getLocationListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IGetLocationListProps): Promise<IListPage<ILocationDomain>> => {
  let url = `v2/localities?&search=${search}&status=${status}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

  if (search === '') {
    url = url.replace('search=&', '');
  }
  if (!status) {
    url = url.replace('status=undefined&', '');
  }

  const { data } = await API.get<IListPage<ILocationResponse>>(url);

  return {
    items: data.items.map(convertLocationResponseToDomain),
    meta: data.meta,
  };
};
