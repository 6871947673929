import styled from 'styled-components';

interface IStyledProps {
  secondaryField?: boolean;
}

export const ModalContainerStyled = styled.div`
  display: grid;
  width: 100%;
`;

export const FieldContainerStyled = styled.div<IStyledProps>`
  display: flex;
  width: ${({ secondaryField }) => (secondaryField ? '40%' : '60%')};
  margin-left: ${({ secondaryField }) => (secondaryField ? '25px' : '0')};
`;

export const GroupFieldContainerStyled = styled.div<IStyledProps>`
  display: flex;
  width: 100%;
  margin-top: ${({ secondaryField }) => (secondaryField ? '30px' : '0')};
`;

export const SaveButtonContainerStyled = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: end;
`;

export const SaveButtonStyled = styled.button`
  display: flex;
  width: 115px;
  height: 40px;
  border-style: none;
  border-radius: 5px;
  border-width: 1px;
  backdrop-filter: blur(5px);
  background: rgb(224 43 43 / 1);
  cursor: pointer;

  font-family: Montserrat, serif;
  font-size: 12px;
  color: #fafafa;
  font-weight: 600;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(189, 2, 2);
  }
`;

export const EmptySpinnerContainer = styled.div`
  height: 324px;
  align-content: center;
`;
