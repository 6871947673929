import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { initialValues } from '../types/types';
import { useFilterDeliveryTracking } from '../hooks/use-filter.hook';
import { DeliveryTrackingBody } from '../components/body/delivery-tracking-body';

import DeliveryTrackingDetailForm from './delivery-tracking-details/delivery-tracking-details';

import { FilterService } from 'domain/filter-delivery-tracking';

const DeliveryTrackingDetailFormPage: React.FC = () => {
  const [delivery, setDelivery] = useState<FilterService>(initialValues);

  const resultDeliveryTracking = useFilterDeliveryTracking();

  const { id } = useParams();

  useEffect(() => {
    async function run() {
      if (id) {
        const location = await resultDeliveryTracking({
          ...delivery,
        });
        setDelivery(location);
      }
    }
    run();
  }, []);

  return (
    <DeliveryTrackingBody description={'CÓDIGO DE RASTREIO '} id={id}>
      <DeliveryTrackingDetailForm schedulingCode={id || ''} />
    </DeliveryTrackingBody>
  );
};

export default DeliveryTrackingDetailFormPage;
