import { VehicleCategory, VehicleCategoryName } from 'domain/global';

export const convertVehicleCategory = (vehicleCategory: VehicleCategory | string | undefined): VehicleCategoryName => {
  switch (vehicleCategory) {
    case 'bau_seco':
      return 'BAÚ SECO';
    case 'bau_frigorificado':
      return 'BAÚ FRIGORIFICADO';
    case 'bau_refrigerado':
      return 'BAÚ REFRIGERADO';
    case 'bau_sider':
      return 'SIDER';
    case 'cacamba_basculante':
      return 'BASCULANTE';
    case 'carreta':
      return 'CARRETA';
    case 'cegonha':
      return 'CEGONHA';
    case 'complemento':
      return 'COMPLEMENTO';
    case 'florestal':
      return 'FLORESTAL';
    case 'grade_baixa':
      return 'GRADE BAIXA';
    case 'graneleiro':
      return 'GRANELEIRO';
    case 'plataforma':
      return 'PLATAFORMA';
    case 'porta_container_20':
      return 'PORTA CONTAINER 20 PES';
    case 'porta_container_40':
      return 'PORTA CONTAINER 40 PES';
    case 'tanque':
      return 'TANQUE';
    default:
      return '';
  }
};
