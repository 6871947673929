import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { createUpdateUserChatService } from 'src-new/services/chat/create-update-user-chat/create-update-user-chat.service';
import { IUserChat } from 'src-new/domains/user-chat.domain';

export const useCreateUpdateUserChat = () => {
  return useCallback(async (userDomain: IUserChat) => {
    try {
      await createUpdateUserChatService(userDomain);
    } catch (err) {
      showMessageFormatted({
        message: 'Ocorreu um erro ao criar/atualizar os detalhes de usuário do chat. Por favor, tente novamente! ',
        type: 'error',
      });
    }
  }, []);
};
