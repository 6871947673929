import React from 'react';

import { LabelStyled, InputStyled, IconStyled } from './toggle-checkbox.styled';

type ToggleCheckboxViewProps = {
  id: string;
  name: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const ToggleCheckboxView: React.FC<ToggleCheckboxViewProps> = ({ id, name, label, checked, onChange, disabled }) => {
  return (
    <LabelStyled disabled={disabled}>
      <InputStyled id={id} name={name} type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
      <IconStyled />
      {label && <label style={{ marginRight: '0.75rem' }}> {label}</label>}
    </LabelStyled>
  );
};

export default ToggleCheckboxView;
