import { useCallback, useContext } from 'react';
import Talk from 'talkjs';
import { UserContext } from 'state/user-context';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';

export const useGetUserSessionChat = () => {
  const { user } = useContext(UserContext);
  const { userDetails } = useContext(ChatButtonContext);

  return useCallback((): Talk.User => {
    if (userDetails.photoUrl && user) {
      return new Talk.User({
        id: user.id,
        name: user.name,
        role: 'default',
        custom: {
          companyName: userDetails.companyName,
        },
        ...(userDetails.photoUrl !== ('NOT_FOUND' && '') && { photoUrl: userDetails.photoUrl }),
      });
    }

    return new Talk.User({
      id: 123,
      name: 'Erro',
      welcomeMessage: 'Ocorreu um erro ao instanciar um usuário',
      photoUrl: 'src/assets/img/LogShare-logo.png',
    });
  }, [user, userDetails.companyName, userDetails.photoUrl]);
};
