import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getSaleOfferByIdService } from '../../services/get-sale-offer-by-id/get-sale-offer-by-id.service';
import { ISaleOfferResponse } from '../../services/get-sale-offer-by-id/get-sale-offer-by-id.types';
import { converterSaleOfferDetailsResponseToDomain } from './mappers/converter-sale-offer-details-response-to-domain/converter-sale-offer-details-response-to-domain.mapper';

export const UseGetSaleOfferById = () => {
  const { saleOfferDetails } = useContext(SaleOfferContext);
  const { setSaleOfferDetailsRequest, setSaleOfferDetailsSuccess, setSaleOfferDetailsError } = saleOfferDetails;

  const { id } = useParams<{ id: string }>();
  const saleOfferId = useMemo(() => id ?? '', [id]);
  const { isLoading } = useQuery<ISaleOfferResponse>({
    queryKey: ['sale-offer-details-by-id', saleOfferId],
    queryFn: () => {
      setSaleOfferDetailsRequest();
      return getSaleOfferByIdService(saleOfferId);
    },
    onSuccess(data) {
      setSaleOfferDetailsSuccess(converterSaleOfferDetailsResponseToDomain(data));
    },
    onError(error) {
      setSaleOfferDetailsError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
  });

  return useCallback(() => {
    if (isLoading) {
      setSaleOfferDetailsRequest();
    }
  }, [isLoading, setSaleOfferDetailsRequest]);
};
