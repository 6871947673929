import { tableCBackhaul } from 'utils/table-c-backhaul/table-c-backhaul';
import { axisNumberFixed } from 'utils/axis-number/axis-number';
import { VehicleTypeEntity } from 'pages/schedule/services/get-freight-by-filter/get-freight-by-filter.service';

const randomRangeToCalc = () => {
  const random = Math.random();

  const minValue = 0.66;
  const maxValue = 0.85;
  const range = maxValue - minValue;
  const scaledRandom = random * range + minValue;

  return scaledRandom;
};

export const calcPriceSuggestion = (distance: number, vehicleType: VehicleTypeEntity, internalPrice: number) => {
  const numberOfAxis = axisNumberFixed(vehicleType);
  const randomNumber = randomRangeToCalc();
  const tableCValue = tableCBackhaul(numberOfAxis);

  const priceCalculated = distance * tableCValue;

  if (priceCalculated > internalPrice) {
    return priceCalculated;
  } else return internalPrice * randomNumber;
};
