import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
`;

export const SidebarStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.pink};
    margin-right: 20px;
  `}
`;

export const ContentStyled = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  `}
`;

export const LoadingPageStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundModal};
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: ${theme.layers.alwaysOnTop};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
