import React, { Fragment, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Tag } from 'logshare-ui-kit';

import { ContainerSearchStyled, NewFreight } from './tracking-occurrence-body-view.styled';
import TrackingOccurrenceTable from './components/tracking-occurrence-table/tracking-occurrence-table';
import { ModalSelectOccurrence } from './components/modal-select-occurrence';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ColorsV2Type } from 'styles/types/colors';
import { checkOccurrenceOverdue } from 'pages/tracking-occurrence/utils';
import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { UserContext } from 'state/user-context';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import TooltipComponent from 'components/Tooltip';

const stickerType: {
  [key: string]: { label: string; color: ColorsV2Type };
} = {
  low: {
    label: 'Baixa',
    color: ColorsV2Type.GrayLight,
  },
  medium: {
    label: 'Média',
    color: ColorsV2Type.Yellow,
  },
  high: {
    label: 'Alta',
    color: ColorsV2Type.Red,
  },
  atrasado: {
    label: 'Atrasado',
    color: ColorsV2Type.Red,
  },
  no_prazo: {
    label: 'No Prazo',
    color: ColorsV2Type.Green,
  },
};

interface IVehicleListBodyViewProps {
  occurrencesTracking: Array<ITrackingOccurrenceCreated>;
  occurrencesTrackingPageInfo?: PageInfo;
  hasLoading: boolean;
  goToUpdatePage: (vehicleId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
}

const dataTestId = 'vehicle-list-body-view';

const TrackingOccurrenceListBodyView: React.FC<IVehicleListBodyViewProps> = ({
  occurrencesTracking,
  occurrencesTrackingPageInfo,
  hasLoading,
  goToUpdatePage,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
}: IVehicleListBodyViewProps): JSX.Element => {
  const { isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);
  const [modalNewOccurrenceOpen, setModalNewOccurrenceOpen] = useState(false);

  const contentTable = useMemo((): Array<IContentTable> => {
    return occurrencesTracking.map((occurrencesTracking) => {
      const isOverdue = checkOccurrenceOverdue(
        occurrencesTracking.occurrenceType?.sla,
        `${occurrencesTracking.occurrenceDate} ${occurrencesTracking.occurrenceTime}`,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        occurrencesTracking.occurrenceType?.impact,
      );

      const occurrenceTime = Number(occurrencesTracking?.occurrenceType?.sla?.replace(/ horas?/, '')) || 0;

      const occurrenceExpectedTime = moment(occurrencesTracking.occurrenceTime, 'HH:mm');

      const setStatusStyle = (value: string) => {
        const label = value === 'finalizadas' ? 'FINALIZADA' : isOverdue ? 'Atrasado' : 'No Prazo';

        let elBackground = ColorsV2Type.Blue

        if(label === 'FINALIZADA'){
          elBackground = ColorsV2Type.Aqua
        }

        if(label === 'Atrasado'){
          elBackground = ColorsV2Type.Red
        }

        return (
          <TooltipComponent
            title={<span style={{ fontSize: '11px', marginBottom: '5px' }}>{label}</span>}
            location="left"
            icon={
              <Tag label={label} color={elBackground} variant="solid" display="flex" size="md" />
            }
          />
        );
      };

      const occurrenceExpectedDate = moment(occurrencesTracking.occurrenceDate, 'DD/MM/YYYY')
        .add(occurrenceExpectedTime.hours(), 'hours')
        .add(occurrenceExpectedTime.minutes(), 'minutes')
        .add(occurrenceTime, 'hours');

      return [
        {
          value: '',
          flex: 0,
        },
        {
          value: occurrencesTracking.id,
          flex: 0.25,
        },
        {
          value: occurrencesTracking.osNumber,
          flex: 0.4,
        },

        {
          value: (
            <div style={{ display: 'flex', gap: 10 }}>
              <Tag
                label={stickerType[occurrencesTracking?.occurrenceType?.impact]?.label}
                color={stickerType[occurrencesTracking?.occurrenceType?.impact]?.color}
                variant="solid"
                display="flex"
                size="md"
              />
            </div>
          ),
          flex: 0.5,
        },

        {
          value: occurrencesTracking.occurrenceType?.description,
          flex: 1,
        },

        {
          value: occurrencesTracking?.clientId
            ? occurrencesTracking.client?.tradeName
            : occurrencesTracking.locality?.name,
          flex: 1.2,
        },

        {
          value: occurrencesTracking.occurrenceDate,
          flex: 0.6,
        },
        {
          value: occurrencesTracking.endDate && `${occurrencesTracking.endDate} ${occurrencesTracking.endTime}`,
          subtitle: !occurrencesTracking.endDate ? occurrenceExpectedDate.format('DD/MM/YYYY HH:mm') : '',
          isStrong: !occurrencesTracking.endDate && true,
          flex: 0.8,
        },
        {
          value: setStatusStyle(occurrencesTracking.statusResolution),
          flex: 0.5,
        }
      ];
    });
  }, [occurrencesTracking]);

  const renderVehicleTable = useMemo(() => {
    return (
      <TrackingOccurrenceTable
        contentTable={contentTable}
        pageInfo={occurrencesTrackingPageInfo}
        goToUpdatePage={goToUpdatePage}
        changePage={setCurrentPage}
        isLoading={hasLoading}
      />
    );
  }, [contentTable, occurrencesTrackingPageInfo, goToUpdatePage, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Em Tratativa',
        content: renderVehicleTable,
      },
      {
        name: 'Não Atendidas',
        content: renderVehicleTable,
      },
      {
        name: 'Finalizadas',
        content: renderVehicleTable,
      },
    ];
  }, [renderVehicleTable]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <ModalSelectOccurrence isOpen={modalNewOccurrenceOpen} onClose={() => setModalNewOccurrenceOpen(false)} />

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Tracking', 'Ocorrências']} />
      <Title dataTestId={dataTestId} title="Monitore as ocorrências na sua operação de transporte." hasTrace />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise por Nome ou Número do Pedido"
          type="text"
          changeValue={setSearchInput}
        />

        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Nova Ocorrência" color="pink" onPress={() => setModalNewOccurrenceOpen(true)} />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default TrackingOccurrenceListBodyView;
