import { FC } from 'react';

export const Airplane: FC = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="64" height="64" fill="url(#airplane0)" />
      <defs>
        <pattern id="airplane0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_329_2" transform="scale(0.015625)" />
        </pattern>
        <image
          id="image0_329_2"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAR0SURBVHic3dtNqBVlGAfwX169ftyya1lYRkJaoJVhkBVUFFELRSSRCL20y00Q4aZ1Ltq3qE0gmkhoEZolgWlfq6gUIvqkwiCtyK5d0yyz0+J14Hg6c87MOzPvufmH/2rgef7/Z+aded6P4cLFXDyDz3Aax7EfY5gyQF1JcDd+QSuH+zFnYOoaxj04Kd98exEuuCfhdkzobz7j2GBkNoOlOKa4+Rb2DfUJugLv4kb8he9xtgHxVbEEB4QXXxmM9rq4Qnh7tlfsV2zBSgyXVdkQFuGIcnc+48m8oN3Md3IcW7EK02u3VQwLcLiHxn78pFvQIuY7eRzbsBoz6naZg6vxdUmdndzUGTTGfCcnsB0PYWbNpjNcITQ4VXT+jMvag9ZhvpMn8BLWqK8Yc3Cooq6TuKs96OIGzHfyd+zAWsyKND8bH1TUMSE0S//Bvao/VkV5CnvwKC4paH4W3qmYdxx39koyDU/hj0SFaJ3LlRVjdo6uYeytmOcYbutlvh0L8WbCIvQqxjS8VjHuj7i5qPl2rMPRARQiK8Yu1e/8YaFZisaleBZ/D6gQVfgdrqtivh3LVH8Dp+TnmF+X+QxTsAG/TQKDvXhIaJYawzy8OAmMduNHuLw56+fjPuFRG7TpjO/L/5Q2hmHpe4dufBsXN+y1JxYZTO/QwuvSzUL7Yr3QeKQyv1doliYVUvYOuxJ5isKtmu8dThvAi68MUvQO65K5qYCrNNc7vJrQRyUsV2zXpixPGfAnsAiW6r1fV5WPpLNSHjdoflr9SjI3JbEQP2jWfEsYWpNuGFyDbzVvPuPDaWwVw5XST5R2JnFWAHPxqbTms2EwksBfT8zBQfEGqhZhbfMW8zEizMljhI/jDuExrlKAHY27zMFMYU4eI/on3HIuzjxhs/V/NQyG8Uak4KO4qSPek5GxMq5pxGUOpmF3pNC8NfshfBwZsyVsyFbCVKF17XfwYUgYczEiv8K1PWIvF47jxMQ+ocJu9JCwv98SFjS+wT5hgWODsK08govwQqTAomv2z0fGbwnnE0pjqmJ39Kz43v6g4mv2o+KX17aXNd9+55vih8qv2Y9F5ppQYhikMP+e+KWrtyJzrp4s5g+oNlO7Xty+w7YiwTc3ZDrjbvUco9sUkft4kdwzhLN+O/FnBaPduEN9a/bT8UWEhlVlkowKpzP24ExEsnZuF74qdeLBCB1bY5PNx0bhzV026VHNHUUv23iNq+FY7wJhA7ToAsdZ8Ufh+mGeYKpMEVbWKWBlwaTL6kzagScKasi4pW4BRc4Trq87aRumKLflljsMYsdpkbW3xZGxi+AfPK74vwujuL9OAYv0r3qK7arnCujIuLnu5P2+Dl/WnbALRhWflB2pO/nGPgnPSPMjxbo+OlpCg1eqISqC+fovWEQdT43Avh4aTgu/AjSCAz0Sv6z+TjAPeZOlRu58Ox7rkjS1+QxPS2yesAnS+aPFIMxz/mQpifkM7SvCgzKf4QGJzRMOJrSEScogzWdYkjrhiNBkTAbzpfEvFUKfBP7/FEgAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};
