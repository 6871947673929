import { useCallback } from 'react';
import { sendTermsConditionsRegisterService } from 'src-new/services/send-terms-conditions-register/send-terms-conditions-register.service';
import { ITermsConditionsRegister } from 'src-new/services/send-terms-conditions-register/send-terms-conditions-register.types';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useSendTermsConditionsRegister = () => {
  return useCallback(async (values: ITermsConditionsRegister) => {
    try {
      await sendTermsConditionsRegisterService(values);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao registrar termos e condições.',
        type: 'error',
      });
    }
  }, []);
};
