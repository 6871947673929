export const reasonDeny = [
  {
    label: 'Veículo Indisponível',
    value: 'Veículo Indisponível',
  },
  {
    label: 'Capacidade de Carga',
    value: 'Capacidade de Carga',
  },
  {
    label: 'Restrição na Rota',
    value: 'Restrição na Rota',
  },
  {
    label: 'Natureza da Carga',
    value: 'Natureza da Carga',
  },
  {
    label: 'Valor da Carga',
    value: 'Valor da Carga',
  },
  {
    label: 'Informações Incompletas ou Insuficientes',
    value: 'Informações Incompletas ou Insuficientes',
  },
  {
    label: 'Problemas de Pagamento',
    value: 'Problemas de Pagamento',
  },
  {
    label: 'Tempo Insuficiente para Programação',
    value: 'Tempo Insuficiente para Programação',
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
];
