import styled, { css, keyframes } from 'styled-components';
import { CSSProperties } from 'react';

export const FreightUpdateContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AccessRestrictContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TitleContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr; //REMOVENDO POREM IREMOS CONFIRMAR COM A OPEREÇÃO isMaster ? '3fr 1fr' : '1fr',
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-items: baseline;
`;

export const ChatWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
`;

export const ChatButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export const ChatButtonStyled = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-style: none;
  background-color: rgb(18, 38, 78);
  background-position: center 17px;
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 4px 0 #00000040;
  cursor: pointer;
`;

export const ChatIconStyled = styled.img<{ src: string }>`
  ${({ src }) => css`
    src: ${src};
    width: 30px;
    height: 30px;
  `}
`;

export const chatStyled: CSSProperties = {
  width: '356px',
  height: '502px',
};

export const SpinnerContainerStyled = styled.div`
  position: fixed;
  bottom: 30px;
  right: 27px;
  padding: 4px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ececec;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: ${spin} 0.7s linear infinite;
`;

export const UnreadsChatContainerStyled = styled.span`
  width: 17px;
  height: 17px;
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
`;

export const UnreadsChatStyled = styled.div``;
