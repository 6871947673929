/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useGetTrackingOccurrencesListHook } from 'pages/tracking-occurrence/hooks';
import { freightContentTableMount, FreightContentTableMountProps } from 'pages/freight/utils/freight-table-content';
import FreightTable from 'pages/freight/freight-list/freight-list-body/components/freight-table/freight-table';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { PageInfo } from 'domain/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const defaultFilters: FreightContentTableMountProps = {
  hasNewOccurrence: true,
  hideCheckbox: true,
};

export const ModalSelectOccurrence: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { isAdmin } = useContext(UserContext);

  const [hasLoading, setHasLoading] = useState(false);
  const [occurrencesTrackingPageInfo, setOccurrencesTrackingPageInfo] = useState<PageInfo>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    itemCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTabName, setActiveTabName] = useState('Não Atendidas');
  const [allocations, setAllocation] = useState<ICurrentAllocation[]>([]);
  const [statusCode, setStatusCode] = useState<string[]>(['OT01', 'AG10', 'IN10', 'IN11']);
  const [statusCte, setStatusCte] = useState<string>('');

  const getTrackingOccurrencesListHook = useGetTrackingOccurrencesListHook();

  const goToUpdatePage = (id: string) => {
    navigate(`/tracking-ocorrencias/novo/${id}`, {
      replace: true,
    });
  };

  const renderStatusByTabName = useCallback(() => {
    if (activeTabName === 'A Iniciar') {
      setStatusCode(['OT01', 'AG10', 'IN10', 'IN11']);
      setStatusCte('');
      return 'alocacao';
    }

    if (activeTabName === 'Aguardando CT-e') {
      setStatusCode(['IN40', 'IN50']);
      setStatusCte('cte_nao_autorizado');
      return 'inbound';
    }

    if (activeTabName === 'Em Andamento') {
      setStatusCode(['IN20', 'IN30', 'IN40', 'IN50', 'IN60', 'IN70']);
      setStatusCte('');
      return 'outbound';
    }

    if (activeTabName === 'Finalizado') {
      setStatusCode(['IN90']);
      setStatusCte('');
      return 'backhoul';
    }

    if (activeTabName === 'Todos') {
      setStatusCode(['OT01', 'AG10', 'IN10', 'IN11', 'IN20', 'IN30', 'IN40', 'IN50', 'IN60', 'IN90']);
      setStatusCte('');
      return 'backhoul';
    }

    return undefined;
  }, [activeTabName]);

  const getOccurrenceList = useCallback(async () => {
    setHasLoading(true);
    setAllocation([]);

    try {
      let lastFinishedDays: number | undefined = undefined;

      if (activeTabName === 'Finalizado') lastFinishedDays = 7;

      const response = await getTrackingOccurrencesListHook({
        page: currentPage,
        statusCode,
        statusCte,
        lastFinishedDays,
      });

      setAllocation(response.list);
      setOccurrencesTrackingPageInfo(response.pageInfo);
    } catch (error) {
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    } finally {
      setHasLoading(false);
    }
  }, [currentPage, statusCode, statusCte]);

  useEffect(() => {
    renderStatusByTabName();
  }, [renderStatusByTabName]);

  useEffect(() => {
    getOccurrenceList();
  }, []);

  useEffect(() => {
    getOccurrenceList();
  }, [getOccurrenceList]);

  const contentTable = useMemo((): Array<IContentTable> => {
    return allocations.map((freight) => freightContentTableMount(freight, isAdmin, undefined));
  }, [allocations, isAdmin]);

  const renderFreightTable = useMemo(() => {
    return (
      <FreightTable
        contentTable={contentTable}
        pageInfo={occurrencesTrackingPageInfo}
        goToDetailsPage={goToUpdatePage}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        notAbsolute
        filters={defaultFilters}
      />
    );
  }, [contentTable, occurrencesTrackingPageInfo, goToUpdatePage, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'A Iniciar',
        content: renderFreightTable,
      },
      {
        name: 'Em Andamento',
        content: renderFreightTable,
      },
      {
        name: 'Aguardando CT-e',
        content: renderFreightTable,
      },
      {
        name: 'Finalizado',
        content: renderFreightTable,
      },
    ];
  }, [renderFreightTable]);

  if (!props.isOpen) return null;

  return (
    <ModalConfirmation
      title="SELECIONE A VIAGEM PARA ABERTURA DE OCORRÊNCIA"
      description={
        <div>
          <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />
        </div>
      }
      size="large"
      type="normal"
      handleXCancel={props.onClose}
    ></ModalConfirmation>
  );
};
