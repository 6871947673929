import {FC, useMemo} from 'react';
import {ScheduleOfferModalMatchHeaderView} from './modal-match-header-view.component';
import {ScheduleRegisterInput} from 'domain-v2/schedule/register';

interface IScheduleOfferModalMatchHeaderProps {
  scheduleValues: ScheduleRegisterInput;
  companyImage: string
}

export const ScheduleOfferModalMatchHeader: FC<IScheduleOfferModalMatchHeaderProps> = ({
                                                                                         scheduleValues,
                                                                                         companyImage
                                                                                       }) => {
  const getProposalMatch = useMemo(() => {
    return scheduleValues;
  }, [scheduleValues]);

  return <ScheduleOfferModalMatchHeaderView scheduleValues={getProposalMatch} companyImage={companyImage}/>;
};
