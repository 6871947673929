import React from 'react';
import { ReactNode, memo, useMemo } from 'react';

import * as S from './styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface HeaderTableProps {
  contentTable: { contentTable: Array<IContentTable>; headerTable: IContentTable };
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (transportId: string) => void;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: (type: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  pageInfo,
  arrowOrder,
  isLoading,
  orderSelected,
  changePage,
  clickRowAction,
  clickOrderTableBy,
  clickSortDirection,
}) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.ContainerPagination>
          <Pagination activePage={pageInfo.currentPage} totalPage={pageInfo.totalPages} changePage={changePage} />
        </S.ContainerPagination>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <div>
      <Table
        orderSelected={orderSelected}
        header={contentTable.headerTable}
        content={contentTable.contentTable}
        isLoading={isLoading}
        arrowOrder={arrowOrder}
        clickRowAction={clickRowAction}
        clickOrderBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        zIndex
      />
      {renderPagination}
    </div>
  );
};

export default memo(HeaderTable);
