/* eslint-disable */
import { convertDriverCreatedEntityToDomainMapper } from 'pages/driver/mappers/convert-driver-created-entity-to-domain/convert-driver-created-entity-to-domain.mapper';
import { IDriverEntity } from 'pages/driver/services/entities/driver.entity';
import { convertDriverEntityToSelectItem } from 'pages/schedule/mappers/convert-client-entity-to-select-item/convert-driver-entity-to-select-item.mapper';
import API from 'utils/API/API';
import { IDriverCreated, IDriverStatus } from '../../../../domain/driver';
import { IListPage, PageInfo } from '../../../../domain/page-info';

export interface IDriverListProps {
  search: string;
  page?: number;
  status?: IDriverStatus;
  limit?: number;
  order: string;
  column: string;
  shippingCompanyId?: number;
}

export interface IDriverListEntity {
  list: Array<IDriverEntity>;
  pageInfo: PageInfo;
}

export const driverListService = async ({
  search,
  page,
  status,
  limit,
  order,
  column,
  shippingCompanyId,
}: IDriverListProps): Promise<IListPage<IDriverCreated>> => {
  const LIMIT_PAGE = limit ? limit : 50;

  try {
    let url = `v2/drivers?search=${search}&status=active&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }

    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }

    if (shippingCompanyId) {
      url += `&shippingCompanyId=${shippingCompanyId}`;
    }

    const res = await API.get(url);
    if (res.data) {
      const { items, meta } = res.data;

      return {
        list: items.map(convertDriverCreatedEntityToDomainMapper),
        pageInfo: meta,
      };
    } else {
      return {
        list: [],
        pageInfo: res.data.meta,
      };
    }
  } catch (error) {
    throw new Error('Houve um erro ao listar motorista');
  }
};

export const driverFilterService = async ({
  search,
  page,
  status,
  limit,
  order,
  column,
}: IDriverListProps): Promise<IListPage<IDriverCreated>> => {
  const LIMIT_PAGE = limit ? limit : 50;

  try {
    let url = `v2/drivers?search=${search}&status=active&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const res = await API.get(url);
    if (res.data) {
      const { items, meta } = res.data;
      return items.map(convertDriverEntityToSelectItem);
    } else {
      return {
        list: [],
        pageInfo: res.data.meta,
      };
    }
  } catch (error) {
    throw new Error('Houve um erro ao listar motorista');
  }
};

export const driverSearchService = async (search: string) => {
  try {
    let url = `v2/drivers?search=${search}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }

    const res = await API.get(url);

    if (res.data) {
      const { items, meta } = res.data;
      return items.map(convertDriverEntityToSelectItem);
    } else {
      return {
        list: [],
        pageInfo: res.data.meta,
      };
    }
  } catch (error) {
    throw new Error('Houve um erro ao listar motorista');
  }
};
