/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import VehicleFileSectionView from './vehicle-file-upload-view';

interface IProps {
  handleChangeFile?: (value?: any, id?: number) => void;
}

const VehicleFileSection: React.FC<IProps> = ({ handleChangeFile }) => {
  return <VehicleFileSectionView handleFile={handleChangeFile} />;
};

export default React.memo(VehicleFileSection);
