import styled from 'styled-components';

export const CloseModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  margin-bottom: 27px;
`;

export const CloseModal = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 15px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  bottom: 37px;
`;

export const HeaderContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 42px;
`;

export const HeaderValues = styled.span`
  font-weight: 400;
  display: flex;
  font-size: 11px;
  align-items: start;
  gap: 2px;
  position: relative;
  bottom: 12px;
  flex-direction: column;
`;

export const HeaderText = styled.strong`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
`;

export const HeaderButton = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  bottom: 20px;
`;

export const FooterContainer = styled.div`
  display: flex;
  gap: 37px;
  position: relative;
  top: 51px;
  width: fit-content;
`;

export const FooterText = styled.strong`
  font-size: 11px;
`;

export const FooterButton = styled.div`
  display: flex;
  gap: 7px;
  margin-top: 8px;
`;

export const Wrapper = styled.div``;

export const Footer = styled.div``;

export const FooterValues = styled.div``;
