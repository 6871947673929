import moment from 'moment';

export const formatTime = (date: string, dateFormat?: string) => {
  if (dateFormat && moment(date, dateFormat).isValid()) {
    return moment(date, dateFormat).format('HH:mm');
  }
  if (moment(date).isValid()) {
    return moment(date).format('HH:mm');
  }

  return date;
};
