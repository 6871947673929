import API from 'utils/API/API';
import { IScheduleCustomFields } from '../../domains/schedule-details.domain';

export const updateScheduleCustomFieldsService = async (
  scheduleId: number,
  customFields: Array<IScheduleCustomFields>,
) => {
  const customFieldsValues = customFields.map(({ ...customFieldValue }) => {
    return {
      id: customFieldValue.customField?.id,
      value: customFieldValue.value,
    };
  });

  await API.put(`v3/schedule/${scheduleId}/custom-fields`, {
    customFields: customFieldsValues,
  });
};
