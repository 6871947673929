import { MultiValue } from 'react-select';
import React from 'react';

import MultiSelectView from './multi-select-view.component';

export interface IMultiSelectOption<T> {
  value: T;
  label: string;
}

interface MultiSelectParams<T> {
  options: IMultiSelectOption<T>[];
  placeholder?: string;
  isSearchable?: boolean;
  menuPosition: 'fixed' | 'absolute';
  label?: string;
  values?: Array<IMultiSelectOption<T>>;
  hasError?: boolean;
  errorMessage?: string;
  onChange: (value: MultiValue<IMultiSelectOption<T>>) => void;
}
export const MultiSelect = <T,>({
  options,
  hasError,
  isSearchable,
  menuPosition,
  placeholder = '',
  label = '',
  errorMessage,
  values,
  onChange,
}: MultiSelectParams<T>) => {
  return (
    <MultiSelectView
      label={label}
      placeholder={placeholder}
      options={options}
      isSearchable={isSearchable}
      menuPosition={menuPosition}
      values={values}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={onChange}
    />
  );
};

