export const validateConversation = (id: string): boolean => {
  const conversationId = id.split('-')[0];

  switch (conversationId) {
    case 'development': {
      return true;
    }
    case 'production': {
      return true;
    }
    default: {
      return false;
    }
  }
};
