import { useCallback, useReducer } from 'react';
import { IInitialLoading } from 'src-new/contexts/infinite-scroll-list-table/types/initial-loading.types';
import { infiniteScrollInitialState } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.constants';
import { initialLoadingReducer } from 'src-new/contexts/infinite-scroll-list-table/reducers/initial-loading/initial-loading.reducer';
import { InitialLoadingActionType } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.action';

export const useInitialLoadingContextValues = (): IInitialLoading => {
  const [state, dispatch] = useReducer(initialLoadingReducer, infiniteScrollInitialState);

  const setInitialLoadingSuccess = useCallback(() => {
    dispatch({ type: InitialLoadingActionType.INITIAL_LOADING_SUCCESS, payload: undefined });
  }, []);

  const setInitialLoadingReset = useCallback(() => {
    dispatch({ type: InitialLoadingActionType.INITIAL_LOADING_RESET, payload: undefined });
  }, []);

  return {
    ...state.initialLoading,
    setInitialLoadingSuccess,
    setInitialLoadingReset,
  };
};
