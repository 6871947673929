import { includes } from 'lodash';
import { IFreightActionType, FreightChatActionType } from '../freight.action';
import { IFreightChatActions } from '../types/freight-chat.types';
import { freightChatReducer } from 'pages/freight/contexts/freight/reducers/freight-chat/freight-chat.reducer';
import { freightReducerResetState } from './freight-reducer.constants';
import { IFreightActions, IFreightContext } from 'pages/freight/contexts/freight/types/freight.types';

export const freightReducer = (state: IFreightContext, action: IFreightActions): IFreightContext => {
  if (includes(Object.values(FreightChatActionType), action.type)) {
    return freightChatReducer(state, action as IFreightChatActions);
  }

  if (action.type === IFreightActionType.FREIGHT_RESET) {
    return freightReducerResetState(state);
  }

  return state;
};
