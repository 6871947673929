import { useCallback } from 'react';

import { occurrenceDetailsService } from 'pages/occurrence/services/occurrence-details/occurrence-details.service';
import { IOccurrenceCreated } from 'domain/occurrence';

const useOccurrenceDetailsHook = () => {
  return useCallback(async (companyId: string): Promise<IOccurrenceCreated> => {
    return await occurrenceDetailsService(companyId);
  }, []);
};

export { useOccurrenceDetailsHook };
