import React from 'react';

import ResetPasswordView from './reset-password-view';

export type ResetLoginPasswordProps = {
  callback: () => void;
};

const ResetLoginPassword: React.FC<ResetLoginPasswordProps> = ({ callback }) => {
  return <ResetPasswordView callback={callback} />;
};

export default ResetLoginPassword;
