import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import {
  ContainerBodyStyled,
  ContainerCountContentStyled,
  ContainerCountServiceStyled,
  CountLabelStyled,
  CountTitleStyled,
} from './transactions-list-page-body-view.styled';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import {
  ExportReportProps,
  FilterProps,
  IButtonProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { TransactionsListTabs } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-tabs/transactions-list-tabs.component';
import {
  IInvoiceModalProps,
  ISelectedIdsProps,
} from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.types';
import { TransactionActiveTabName } from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';
import { ModalCreateInvoice } from 'pages-v2/transactions/list/content-table/modal/modal-create-invoice.component';

interface ITransactionsListPageBodyViewProps {
  handleSearchInput: (value: string) => void;
  setActiveTabName: (name: TransactionActiveTabName) => void;
  activeTab: TransactionActiveTabName;
  exportReportProps: ExportReportProps;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
  selectedIdsProps: ISelectedIdsProps;
  selectedButtonProps: IButtonProps;
  invoiceButtonProps: IButtonProps;
  totalServices?: string;
  filterProps: FilterProps;
  invoiceModalProps: IInvoiceModalProps;
}

export const TransactionsListPageBodyView: React.FC<ITransactionsListPageBodyViewProps> = ({
  handleSearchInput,
  setActiveTabName,
  exportReportProps,
  activeTab,
  handleInfinityScroll,
  selectedIdsProps,
  selectedButtonProps,
  invoiceButtonProps,
  totalServices,
  filterProps,
  invoiceModalProps,
}: ITransactionsListPageBodyViewProps): ReactElement => {
  const renderCountService = useMemo((): ReactElement | undefined => {
    if ((activeTab === 'A Pagar' || activeTab === 'A Receber') && totalServices) {
      return (
        <ContainerCountServiceStyled>
          <ContainerCountContentStyled>
            <CountTitleStyled>Total Selecionado(s)</CountTitleStyled>
            <CountLabelStyled>{totalServices}</CountLabelStyled>
          </ContainerCountContentStyled>
        </ContainerCountServiceStyled>
      );
    }
  }, [activeTab, totalServices]);

  const renderCreateInvoiceModal = useMemo((): ReactElement | undefined => {
    if (invoiceModalProps.showModal) {
      return (
        <ModalCreateInvoice
          scheduleIds={invoiceModalProps.scheduleIds}
          totalService={invoiceModalProps.totalService}
          handleCancel={invoiceModalProps.handleCancel}
          companyTakerName={invoiceModalProps.companyTakerName}
          handleSubmit={invoiceModalProps.handleSubmit}
        />
      );
    }
  }, [
    invoiceModalProps.companyTakerName,
    invoiceModalProps.handleCancel,
    invoiceModalProps.handleSubmit,
    invoiceModalProps.scheduleIds,
    invoiceModalProps.showModal,
    invoiceModalProps.totalService,
  ]);

  return (
    <ContainerBodyStyled>
      <ApplicationPageHeader
        headerProps={{
          breadcrumbList: ['Financeiro', 'Transações'],
          subtitle: 'Acompanhe o faturamento e cobrança dos fretes realizados',
          inputSearchProps: { searchPlaceHolder: 'Pesquise pelo ID da Viagem ou Tomador', handleSearchInput },
          primaryButtonProps: invoiceButtonProps,
          secondaryButtonProps: selectedButtonProps,
          filterProps,
          exportReportProps,
        }}
      />
      <TransactionsListTabs
        activeTab={activeTab}
        setActiveTab={setActiveTabName}
        selectedIdsProps={selectedIdsProps}
        handleInfinityScroll={handleInfinityScroll}
      />
      {renderCountService}
      {renderCreateInvoiceModal}
    </ContainerBodyStyled>
  );
};
