import React, { ReactElement, useMemo } from 'react';
import * as S from './modal-schedule-cancel-view.styled';
import Select from 'components/select/select';
import Button from 'components/button/button';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import { reasonCancellation } from 'default_constants/reason-cancel';
import { ModalBark } from 'components-v2/layout/modal-bark';
import InputField from 'components/input-field/input-field';
import { ButtonLabelType, IHandleChangeValue, IScheduleCancel } from './modal-schedule-cancel.types';
import { FormikErrors } from 'formik';

interface IModalScheduleCancelViewProps {
  values: IScheduleCancel;
  subtitle?: string;
  buttonLabel: ButtonLabelType;
  setFieldValue: (field: string, value: string | boolean) => Promise<void | FormikErrors<IScheduleCancel>>;
  handleChange: IHandleChangeValue;
  handleSubmit: (value: string, withCost: boolean) => void;
  closeModal: () => void;
}

export const ModalScheduleCancelView: React.FC<IModalScheduleCancelViewProps> = ({
  values,
  subtitle,
  buttonLabel,
  setFieldValue,
  handleChange,
  handleSubmit,
  closeModal,
}) => {
  const headerLabel = useMemo((): string => {
    const headerLabels = {
      Negar: {
        multiple: 'Negar Viagens',
        single: 'Negar Viagem',
      },
      Cancelar: {
        multiple: 'Cancelar Viagens',
        single: 'Cancelar Viagem',
      },
    };

    const labelInfo = headerLabels[buttonLabel];

    return values.ids.length > 1 ? labelInfo.multiple : labelInfo.single;
  }, [values.ids, buttonLabel]);

  const modalSize = useMemo((): 'xsm' | 'sm' => (buttonLabel === 'Negar' ? 'xsm' : 'sm'), [buttonLabel]);

  const disabledButton = useMemo((): boolean => {
    return !values.descriptionCancel || (values.descriptionCancel === 'Outros' && !values.inputDescriptionCancel);
  }, [values.descriptionCancel, values.inputDescriptionCancel]);

  const renderButtonLabel = useMemo((): ReactElement | undefined => {
      return (
        <S.Main>
          <Select
            label="Motivo do Cancelamento *"
            id="descriptionCancel"
            name="descriptionCancel"
            value={values.descriptionCancel}
            setFieldValue={setFieldValue}
            options={reasonCancellation}
            isClearable
          />
        </S.Main>
      );
    
  }, [setFieldValue, values.descriptionCancel]);

  const renderInputText = useMemo((): ReactElement | undefined => {
    if (values.descriptionCancel === 'Outros') {
      return (
        <S.Main>
          <InputField
            label=""
            placeholder="Detalhe o motivo do cancelamento"
            id="inputDescriptionCancel"
            name="inputDescriptionCancel"
            value={values.inputDescriptionCancel}
            type="text"
            onChange={handleChange}
          />
        </S.Main>
      );
    }
  }, [handleChange, values.descriptionCancel, values.inputDescriptionCancel]);

  const renderButtonWithCost = useMemo((): ReactElement | undefined => {
    if (values.withCost && buttonLabel !== 'Negar') {
      return (
        <S.WrapperButton>
          <Button
            title={'Cancelar com Custo'}
            size={'medium'}
            callback={() => handleSubmit('withCost', true)}
            bgColor="newRed"
            disabled={disabledButton}
          />
        </S.WrapperButton>
      );
    }
  }, [buttonLabel, disabledButton, handleSubmit, values.withCost]);

  return (
    <ModalBark
      handleClose={closeModal}
      size={modalSize}
      title={headerLabel}
      subtitle={subtitle}
      icon={<WarningDeleteIcon />}
      alingText={true}
    >
      <S.Wrapper>
        {renderButtonLabel}
        {renderInputText}

        <S.ModalFooter>
          <S.WrapperButton>
            <Button
              title="Confirmar"
              size={'medium'}
              callback={() => handleSubmit('withCost', false)}
              bgColor="newRed"
              disabled={disabledButton}
            />
          </S.WrapperButton>

          {renderButtonWithCost}
        </S.ModalFooter>
      </S.Wrapper>
    </ModalBark>
  );
};
