interface IRequirementsValues {
  tags: {
    label: string;
    type: 'quality' | 'security';
  }[];
  columns: string;
}

export const requirementsValues: IRequirementsValues[] = [
  {
    tags: [
      { label: 'Ajudante', type: 'quality' },
      { label: 'Controle de temperatura', type: 'quality' },
      { label: 'Sassmaq', type: 'quality' },
      { label: 'AFE Cosméticos', type: 'quality' },
      { label: 'AFE Correlatos', type: 'quality' },
      { label: 'AFE Saneantes', type: 'quality' },
    ],
    columns: '0.1fr 0.5fr 1.2fr 0.5fr 0.7fr 0.7fr 0.7fr 0.1fr',
  },
  {
    tags: [
      { label: 'AFE Medicamentos', type: 'quality' },
      { label: 'AFE Alimentos', type: 'quality' },
      { label: 'IBAMA', type: 'quality' },
      { label: 'ISO', type: 'quality' },
      { label: 'Escolta', type: 'security' },
      { label: 'Pamcary', type: 'security' },
    ],
    columns: '0.1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr 0.1fr',
  },
  {
    tags: [
      { label: 'MOPP', type: 'security' },
      { label: 'Sinalização', type: 'security' },
      { label: 'Carga especial', type: 'security' },
      { label: 'Tie Belt', type: 'security' },
      { label: 'Rastreador', type: 'security' },
      { label: 'EPIs', type: 'security' },
    ],
    columns: '0.1fr 0.4fr 0.5fr 0.6fr 0.5fr 0.5fr 0.5fr 0.1fr',
  },
];
