import { TabNames } from './tab-name';

import type { ScheduleListParams } from 'services/schedule/types';

export const defaultFilter = {
  page: 1,
  statusCode: ['SH01', 'AG10', 'OT01', 'OT10'],
  order: 'ASC',
  column: 'Data Entrega',
  filterIdentifier: '',
  activeTabName: TabNames.Em_Alocacao,
} as ScheduleListParams;

export const dateKeys = ['levyInitialDateStart', 'levyInitialDateEnd', 'deliveryInitialStart', 'deliveryInitialEnd'];

export const entriesBlackList = ['page', 'column', 'order', 'activeTabName', 'filterIdentifier'];
