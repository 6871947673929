import * as Yup from 'yup';

export const NewScheduleSchema = Yup.object().shape({
  origin: Yup.object().shape({
    tradingName: Yup.string().required('campo obrigatório'),
    clientType: Yup.string()
      .min(2, 'texto muito curto!')
      .max(100, 'o campo deve ter 100 caracteres!')
      .required('campo obrigatório'),
  }),
  destiny: Yup.object().shape({
    tradingName: Yup.string().required('campo obrigatório'),
    clientType: Yup.string()
      .min(2, 'texto muito curto!')
      .max(50, 'o campo deve ter 50 caracteres!')
      .required('campo obrigatório'),
  }),
  cargoDetails: Yup.object().shape({
    weight: Yup.string().required('campo obrigatório'),
    capacity: Yup.string().required('campo obrigatório'),
    shippingValue: Yup.string().required('campo obrigatório'),
    inPalet: Yup.string().required('campo obrigatório'),
    pallet: Yup.number().when('inPalet', {
      is: 'yes',
      then: Yup.number()
        .moreThan(5, 'o número mínimo de pallets é 6')
        .lessThan(19, 'o número máximo de pallets é 18')
        .required('campo obrigatório'),
    }),
    operationType: Yup.string().required('campo obrigatório'),
    invoice: Yup.string().required('campo obrigatório'),
    pickUpDate: Yup.string().required('campo obrigatório'),
  }),
});
