import { IScheduleDetailsActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import { scheduleDetailsInitialValuesDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-initial-values.domain';
import { ScheduleDetailsActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';

export const scheduleDetailsReducer = (state: IScheduleContext, action: IScheduleDetailsActions): IScheduleContext => {
  switch (action.type) {
    case ScheduleDetailsActionType.SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          statusRequest: 'PROCESSING',
          scheduleDetails: scheduleDetailsInitialValuesDomain,
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          statusRequest: 'SUCCESS',
          scheduleDetails: action.payload,
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          statusRequest: 'ERROR',
          scheduleDetails: scheduleDetailsInitialValuesDomain,
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          scheduleCustomFields: {
            ...state.scheduleDetails.scheduleCustomFields,
            scheduleCustomFields: action.payload,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_REQUEST:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationShipping: {
            ...state.scheduleDetails.allocationShipping,
            statusRequest: 'PROCESSING',
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationShipping: {
            ...state.scheduleDetails.allocationShipping,
            statusRequest: 'SUCCESS',
            shippingList: action.payload,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_ERROR:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationShipping: {
            ...state.scheduleDetails.allocationShipping,
            statusRequest: 'ERROR',
            shippingList: [],
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SELECTED_SHIPPING_ID:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationShipping: {
            ...state.scheduleDetails.allocationShipping,
            shippingCompanyId: action.payload.shippingId,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_REQUEST:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            modalProps: {
              ...state.scheduleDetails.allocationVehicle.modalProps,
              statusRequest: 'PROCESSING',
            },
          },
          allocationShipping: {
            ...state.scheduleDetails.allocationShipping,
            shippingCompanyId: 0,
            statusRequest: 'INITIAL',
            shippingList: [],
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            modalProps: {
              ...state.scheduleDetails.allocationVehicle.modalProps,
              statusRequest: 'SUCCESS',
              vehicleList: action.payload,
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_ERROR:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            modalProps: {
              ...state.scheduleDetails.allocationVehicle.modalProps,
              statusRequest: 'ERROR',
              vehicleList: [],
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_REQUEST:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            modalProps: {
              ...state.scheduleDetails.allocationDriver.modalProps,
              statusRequest: 'PROCESSING',
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            modalProps: {
              ...state.scheduleDetails.allocationDriver.modalProps,
              statusRequest: 'SUCCESS',
              driverList: action.payload,
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_ERROR:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            modalProps: {
              ...state.scheduleDetails.allocationDriver.modalProps,
              statusRequest: 'ERROR',
              driverList: [],
            },
          },
        },
      };

    case ScheduleDetailsActionType.SCHEDULE_SELECTED_DRIVER:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            modalProps: { ...state.scheduleDetails.allocationDriver.modalProps, driverSelected: action.payload },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_SELECTED_VEHICLE:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            modalProps: { ...state.scheduleDetails.allocationVehicle.modalProps, vehicleSelected: action.payload },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_REQUEST:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            accordionProps: {
              ...state.scheduleDetails.allocationDriver.accordionProps,
              statusRequest: 'PROCESSING',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            accordionProps: {
              ...state.scheduleDetails.allocationVehicle.accordionProps,
              statusRequest: 'PROCESSING',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_SUCCESS:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            accordionProps: {
              ...state.scheduleDetails.allocationDriver.accordionProps,
              statusRequest: 'SUCCESS',
              historyList: {
                ...state.scheduleDetails.allocationDriver.accordionProps.historyList,
                historyDriverList: action.payload.historyDriverList,
              },
            },
          },
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            accordionProps: {
              ...state.scheduleDetails.allocationVehicle.accordionProps,
              statusRequest: 'SUCCESS',
              historyList: {
                ...state.scheduleDetails.allocationDriver.accordionProps.historyList,
                historyVehicleList: action.payload.historyVehicleList,
              },
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_ERROR:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationDriver: {
            ...state.scheduleDetails.allocationDriver,
            accordionProps: {
              ...state.scheduleDetails.allocationDriver.accordionProps,
              statusRequest: 'ERROR',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
          allocationVehicle: {
            ...state.scheduleDetails.allocationVehicle,
            accordionProps: {
              ...state.scheduleDetails.allocationVehicle.accordionProps,
              statusRequest: 'ERROR',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_OPEN:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationActions: {
            ...state.scheduleDetails.allocationActions,
            isOpen: true,
            allocatedEntity: action.payload,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_SET_DRIVER_ID_VEHICLE:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationActions: {
            ...state.scheduleDetails.allocationActions,
            driverId: action.payload,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ALLOCATION_CLOSE:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          allocationActions: {
            ...state.scheduleDetails.allocationActions,
            isOpen: false,
            allocatedEntity: '',
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_ACTIVE_TAB_NAME:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          activeTabName: {
            ...state.scheduleDetails.activeTabName,
            activeTabName: action.payload,
          },
        },
      };
    case ScheduleDetailsActionType.SCHEDULE_DETAILS_RESET:
      return {
        ...state,
        scheduleDetails: {
          ...state.scheduleDetails,
          statusRequest: 'INITIAL',
          scheduleDetails: scheduleDetailsInitialValuesDomain,
          scheduleCustomFields: {
            ...state.scheduleDetails.scheduleCustomFields,
            scheduleCustomFields: [],
          },
          allocationVehicle: {
            modalProps: {
              ...state.scheduleDetails.allocationVehicle.modalProps,
              statusRequest: 'INITIAL',
              vehicleList: [],
              vehicleSelected: {
                checked: false,
                vehicleId: 0,
              },
            },
            accordionProps: {
              ...state.scheduleDetails.allocationVehicle.accordionProps,
              statusRequest: 'INITIAL',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
          allocationDriver: {
            modalProps: {
              ...state.scheduleDetails.allocationDriver.modalProps,
              statusRequest: 'INITIAL',
              driverList: [],
              driverSelected: {
                checked: false,
                driverId: 0,
              },
            },
            accordionProps: {
              ...state.scheduleDetails.allocationDriver.accordionProps,
              statusRequest: 'INITIAL',
              historyList: {
                historyVehicleList: [],
                historyDriverList: [],
              },
            },
          },
          allocationActions: {
            ...state.scheduleDetails.allocationActions,
            isOpen: false,
            allocatedEntity: '',
          },
          activeTabName: {
            ...state.scheduleDetails.activeTabName,
            activeTabName: '',
          },
        },
      };
    default:
      return state;
  }
};
