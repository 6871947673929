import React, { FC, useEffect, useState } from 'react';

import { getInvoicingService } from './service';

import AsyncSelectField, { IAsyncSelectFieldProps } from 'components/async-select/async-select';

interface Props extends Omit<IAsyncSelectFieldProps, 'onChange' | 'apiCallFunction' | 'loadOptions'> {
  onChange: (id: number) => void;
}

export const SelectCompanies: FC<Props> = ({ onChange, disabled, defaultValue, ...rest }) => {
  const [selectedId, setSelectedId] = useState<number>(0);

  useEffect(() => {
    onChange(selectedId);
  }, [selectedId]);

  const companyCall = async (searchValue: string): Promise<any[]> => {
    if (disabled) return [defaultValue];
    if (searchValue) return await getInvoicingService(searchValue);
    else return [];
  };

  return (
    <AsyncSelectField
      onChange={(e) => {
        e && setSelectedId(+e.value);
      }}
      apiCallFunction={companyCall}
      loadOptions={companyCall}
      defaultValue={defaultValue}
      disabled={disabled}
      {...rest}
    />
  );
};
