import { FC, useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ExecutionTimeRecordingView } from './execution-time-recording-view.component';
import {
  ITimeRecording,
  ITimeRecordingLabel,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/components/execution-time-recording/execution-time-recording.types';
import { IScheduleDetailsComplementDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { useUpdateScheduleDetailsDateTime } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-details-date-time/use-update-schedule-details-date-time.hook';
import { scheduleTimeRecordingData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/utils/schedule-recording-data';
import {
  IScheduleDetailsAllocationActionsProps,
  IScheduleDetailsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';

export const ExecutionTimeRecording: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleDetailsDateTime = useUpdateScheduleDetailsDateTime();

  const timeRecording = useMemo(
    (): IScheduleDetailsComplementDomain => scheduleDetails.scheduleDetails.detail,
    [scheduleDetails.scheduleDetails.detail],
  );

  const timeRecordingData = useMemo(
    (): Array<ITimeRecording> => scheduleTimeRecordingData(timeRecording),
    [timeRecording],
  );

  const disabledAllocationCheckbox = useMemo(
    (): boolean =>
      !timeRecording.allocationShippingDate ||
      !timeRecording.allocationDriverDate ||
      !timeRecording.allocationVehicleDate,
    [timeRecording.allocationDriverDate, timeRecording.allocationShippingDate, timeRecording.allocationVehicleDate],
  );

  const modalOptions = useMemo(
    (): IScheduleDetailsAllocationActionsProps => scheduleDetails.allocationActions,
    [scheduleDetails.allocationActions],
  );

  const handleModal = useCallback(
    (open: boolean, modalType: IScheduleDetailsModalType) => {
      if (open) {
        scheduleDetails.setScheduleDetailsAllocationOpen(modalType);
      } else {
        scheduleDetails.setScheduleDetailsAllocationClose();
      }
    },
    [scheduleDetails],
  );

  const handleSubmit = useCallback(
    (values: IScheduleDetailsComplementDomain) => {
      updateScheduleDetailsDateTime(values);
    },
    [updateScheduleDetailsDateTime],
  );

  const handleCheckTimeRecording = useCallback(
    (isChecked: boolean, label: ITimeRecordingLabel) => {
      if (!isChecked) {
        handleModal(true, label);
      }
    },
    [handleModal],
  );

  return (
    <ExecutionTimeRecordingView
      timeRecordingData={timeRecordingData}
      timeRecording={timeRecording}
      modalOptions={modalOptions}
      disabledAllocationCheckbox={disabledAllocationCheckbox}
      handleModal={handleModal}
      handleSubmit={handleSubmit}
      handleCheckTimeRecording={handleCheckTimeRecording}
    />
  );
};
