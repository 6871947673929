import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { proposalOfferMatchProposalInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-proposal-initial-values.constant';
import { purchaseOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-list-filters-initial-values.constant';
import { purchaseOfferMatchListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-list-filters-initial-values.constant';
import { purchaseOfferMatchSelectedInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-match-selected-initial-values.constant';

export const purchaseOfferInitialState: IPurchaseOfferContext = {
  purchaseOfferModals: {
    indexSection: undefined,
    isOpen: false,
    modalType: '',
    secondModalType: '',
    setModalOpen: () => undefined,
    setModalClose: () => undefined,
    setModalSecondaryClose: () => undefined,
  },
  purchaseOfferList: {
    statusRequest: 'INITIAL',
    purchaseOfferList: [],
    selectedIds: [],
    setPurchaseOfferListRequest: () => undefined,
    setPurchaseOfferListSuccess: () => undefined,
    setPurchaseOfferListError: () => undefined,
    setPurchaseOfferListReset: () => undefined,
    setSelectIds: () => undefined,
    filters: {
      selectedFilter: purchaseOfferListFilterInitialValues,
      setSelectFilters: () => undefined,
      setResetFilters: () => undefined,
    },
  },
  purchaseOfferMatchList: {
    statusRequest: 'INITIAL',
    purchaseOfferMatchList: [],
    setPurchaseOfferMatchListRequest: () => undefined,
    setPurchaseOfferMatchListSuccess: () => undefined,
    setPurchaseOfferMatchListError: () => undefined,
    setPurchaseOfferMatchListReset: () => undefined,
    filters: {
      selectedFilter: purchaseOfferMatchListFilterInitialValues,
      setSelectFilters: () => undefined,
      setResetFilters: () => undefined,
    },
    purchaseOfferMatchProposal: {
      statusRequest: 'INITIAL',
      proposal: proposalOfferMatchProposalInitialValues,
      setPurchaseOfferMatchProposalRequest: () => undefined,
      setPurchaseOfferMatchProposalSuccess: () => undefined,
      setPurchaseOfferMatchProposalError: () => undefined,
      setPurchaseOfferMatchProposalReset: () => undefined,
    },
    purchaseOfferMatchListSelected: {
      statusRequest: 'INITIAL',
      selectedMatch: purchaseOfferMatchSelectedInitialValues,
      setPurchaseOfferMatchListSelectedRequest: () => undefined,
      setPurchaseOfferMatchListSelectedSuccess: () => undefined,
      setPurchaseOfferMatchListSelectedError: () => undefined,
      setPurchaseOfferMatchListSelectedReset: () => undefined,
    },
  },
  purchaseOfferListDownloadReport: {
    statusRequest: 'INITIAL',
    setPurchaseOfferListDownloadReportRequest: () => undefined,
    setPurchaseOfferListDownloadReportSuccess: () => undefined,
    setPurchaseOfferListDownloadReportError: () => undefined,
    setPurchaseOfferListDownloadReportReset: () => undefined,
  },
  purchaseOfferBaitRoute: {
    statusRequest: 'INITIAL',
    setPurchaseOfferBaitRouteRequest: () => undefined,
    setPurchaseOfferBaitRouteSuccess: () => undefined,
    setPurchaseOfferBaitRouteError: () => undefined,
    setPurchaseOfferBaitRouteReset: () => undefined,
  },

  setPurchaseOfferResetContext: () => undefined,
};
