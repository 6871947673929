import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { IPurchaseOfferListDomain } from '../../domains/purchase-offer-list.domain';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { IScheduleSpot } from '../../types/schedule-spot.types';

export const convertPurchaseDomainToScheduleSpot = (purchaseDomain: IPurchaseOfferListDomain): IScheduleSpot => {
  return {
    ...purchaseDomain,
    vehicle: {
      type: convertVehicleTypeToEntity(purchaseDomain.vehicle.type),
      category: convertVehicleCategoryToEntity(purchaseDomain.vehicle.category),
    },
  };
};
