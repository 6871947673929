import React from 'react';
import { WeighingTottenModalKeyboardView } from './weighing-totten-modal-keyboard-view';

export interface WeighingTottenModalKeyboardViewParams {
  keyboardType?: 'numbers' | 'default';
  handleClose: () => void;
  onChange: (input: string) => void;
  onKeyPress?: ((button: string, e?: MouseEvent | undefined) => any) | undefined;
}
export const WeighingTottenModalKeyboard: React.FC<WeighingTottenModalKeyboardViewParams> = (params) => {

  return (
    <WeighingTottenModalKeyboardView {...params}/>
  );
};
