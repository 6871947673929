import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button } from 'logshare-ui-kit';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import * as S from './schedule-details-dates-modal-form-view.styled';
import dayjs from 'dayjs';

interface IScheduleDetailsDatesModalFormViewProps {
  values: IUpdateScheduleCargoPayload;
  handleFieldDateChange: (fieldName: string, date: dayjs.Dayjs | null) => void;
  handleOnSubmit: () => void;
  handleCloseModalComments: () => void;
}

export const ScheduleDetailsDatesModalFormView: React.FC<IScheduleDetailsDatesModalFormViewProps> = ({
  values,
  handleFieldDateChange,
  handleOnSubmit,
  handleCloseModalComments,
}) => {
  return (
    <ModalBark
      size={'sm'}
      title={<S.ModalTitle>Datas Programadas</S.ModalTitle>}
      handleClose={handleCloseModalComments}
    >
      <S.ModalWrapper>
        <LineSectionLayout columns="1fr 1fr 1fr 1fr">
          <DateTimePikers
            label="Inicio de Carregamento *"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.detail.levyInitialDate)}
            minDate={dayjs(values.detail.levyInitialDate)}
            onChange={(date) => handleFieldDateChange('detail.levyInitialDate', date)}
            errorMessage={!dayjs(values.detail.levyInitialDate).isValid() ? 'Campo Obrigatório' : ''}
            validateField
          />

          <DateTimePikers
            label="Fim de Carregamento *"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.detail.levyEndDate)}
            minDate={dayjs(values.detail.levyInitialDate)}
            onChange={(date) => handleFieldDateChange('detail.levyEndDate', date)}
            errorMessage={!dayjs(values.detail.levyEndDate).isValid() ? 'Campo Obrigatório' : ''}
            validateField
          />

          <DateTimePikers
            label="Inicio da Descarga *"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.detail.deliveryInitialDate)}
            minDate={dayjs(values.detail.levyInitialDate)}
            onChange={(date) => handleFieldDateChange('detail.deliveryInitialDate', date)}
            errorMessage={!dayjs(values.detail.deliveryInitialDate).isValid() ? 'Campo Obrigatório' : ''}
            validateField
          />

          <DateTimePikers
            label="Fim da Descarga *"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.detail.deliveryEndDate)}
            minDate={dayjs(values.detail.deliveryInitialDate)}
            onChange={(date) => handleFieldDateChange('detail.deliveryEndDate', date)}
            errorMessage={!values.detail.deliveryEndDate ? 'Campo Obrigatório' : ''}
            validateField
          />
        </LineSectionLayout>

        <S.WrapperButtonSubmit>
          <Button label="Atualizar" color="blue" onPress={handleOnSubmit} size="medium" variant="solid" />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
