import { IMyAccountEntity } from 'pages/my-account/services/entities/my-account.entity';
import { IMyAccount } from 'domain/my-account';

export const convertMyAccountCreatedEntityToDomainMapper = (myAccountEntity: IMyAccountEntity): IMyAccount => ({
  name: myAccountEntity.name,
  login: myAccountEntity.login,
  email: myAccountEntity.email,
  telephone: myAccountEntity.telephone,
  celphone: myAccountEntity.celphone,
});
