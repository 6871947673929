import { useCallback } from 'react';
import { DownloadFile } from 'utils/download-file/download-file';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IWeighingListFilters } from 'src-new/pages/execution/pages/weighing/types/weighing-list-filters/weighing-list-filters.types';
import { createWeighingExcelService } from '../../services/create-weighing-excel/create-weighing-excel.service';

export const useCreateWeighingExcel = () => {
  return useCallback(async (params: IWeighingListFilters) => {
    try {
      const file = await createWeighingExcelService({ ...params });

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'Tickets Pesagem');
      }
    } catch (error: any) {
      showMessageFormatted({
        message: 'Erro ao tentar baixar o relatório.',
        type: 'error',
      });
    }
  }, []);
};
