import React, { FC, Fragment, useMemo } from 'react';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import { IHeaderProps } from 'src-new/components/application-page-header/application-page-header.types';
import { EquipmentConfirmationModal } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/components/equipment-voucher-list-page-body/components/equipment-voucher-header/components/equipment-confirmation-modal/equipment-confirmation-modal.component';

interface IModalProps {
  isOpen: boolean;
}

interface IEquipmentVoucherHeaderViewProps {
  headerProps: IHeaderProps;
  deleteModalProps: IModalProps;
}

export const EquipmentVoucherHeaderView: FC<IEquipmentVoucherHeaderViewProps> = ({ headerProps, deleteModalProps }) => {
  const renderDeleteModal = useMemo(() => {
    if (deleteModalProps.isOpen) {
      return <EquipmentConfirmationModal />;
    }
  }, [deleteModalProps.isOpen]);

  return (
    <Fragment>
      <ApplicationPageHeader headerProps={headerProps} />
      {renderDeleteModal}
    </Fragment>
  );
};
