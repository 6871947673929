import { ICompanyInformationContext } from './company-information.types';

export const initialState: ICompanyInformationContext = {
  companyIsLoading: false,
  company: {
    cnpj: '',
    id: '0',
    ie: '',
    login: '',
    email: '',
    name: '',
    plan: 'basic-buyer',
    status: 'active',
    tradeName: '',
    type: 'Condomínio',
    contentFile: undefined,
    contacts: [],
    tariff: '',
    cep: '',
    city: '',
    neighborhood: '',
    street: '',
    numberHouse: '',
    complement: '',
    uf: '',
    paymentTerm: '',
    modalityPayment: '',
    receiptDeadline: '',
    modalityDeadline: '',
    closingCycle: '',
    feeLogshare: '',
    advalorem: '',
    gris: '',
    hasDdr: false,
    riskManager: '',
  },
  companyError: null,
  setCompanyRequest: () => undefined,
  setCompanySuccess: () => undefined,
  setCompanyError: () => undefined,
};

export enum ICompanyAction {
  COMPANY_REQUEST = 'COMPANY_REQUEST',
  COMPANY_SUCCESS = 'COMPANY_SUCCESS',
  COMPANY_ERROR = 'COMPANY_ERROR',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
}
