/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { CustomInputFile, InputFileMessage } from './index.style';

interface Props {
  onChange: (value: any, name: string) => void;
  multiple?: boolean;
  name?: string;
  validFiles?: string;
  children?: React.ReactElement;
  pfxOnly?: boolean;
}

export const InputFile: FC<Props> = (props) => {
  const [files, setFiles] = React.useState<FileList | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const inputName = useMemo(() => {
    if (props.name) return props.name;

    return 'file';
  }, [props.name]);

  const message = useMemo(() => {
    if (props.children) return props.children;
    if (props.multiple) return '+ Selecionar Arquivos';

    return '+ Selecionar Arquivo';
  }, [props.children, props.multiple]);

  const accept = useMemo(() => {
    if (props.pfxOnly) return 'application/x-pkcs12,application/pkcs12,application/octet-stream';
    return props.validFiles;
  }, [props.pfxOnly, props.validFiles]);

  useEffect(() => {
    if (files) props.onChange(files, inputName);
    inputFileRef.current && (inputFileRef.current.value = '');
  }, [files]);

  const handleOpenFileExplorer = () => {
    if (inputFileRef.current) {
      // Se a prop `pfxOnly` for `true`, use o tipo de arquivo que aceita apenas PFX e P12
      const accept = props.pfxOnly ? '.pfx,.p12,application/x-pkcs12,application/pkcs12' : props.validFiles;
      inputFileRef.current.accept = accept || '';
      inputFileRef.current.click();
    }
  };

  return (
    <>
      <InputFileMessage onClick={handleOpenFileExplorer}>{message}</InputFileMessage>
      <CustomInputFile
        ref={inputFileRef}
        type="file"
        name={props.name}
        accept={accept}
        multiple={props.multiple}
        onChange={(e) => {
          setFiles(e.target.files);
        }}
      />
    </>
  );
};
