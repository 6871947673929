import API from 'utils/API/API';
import { IBidding, IBiddingCreate, IIdlenessBidding, IIdlenessBiddingCreate } from 'domain/bidding';

export const createBiddingService = async (payload: IBiddingCreate): Promise<IBidding> => {
  const { data } = await API.post('v2/bidding', payload);
  return data;
};

export const createIdlenessBiddingService = async (payload: IIdlenessBiddingCreate): Promise<IIdlenessBidding> => {
  const { data } = await API.post('v2/idleness-bidding', payload);
  return data;
};
