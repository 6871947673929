import React, { FC, useMemo } from 'react';
import { ConfirmationModal } from 'src-new/components/confirmation-modal/confirmation-modal.component';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import {
  IModalButtonProps,
  IModalDescriptionProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';

interface IDeleteModalViewProps {
  primaryButtonProps: IModalPrimaryButtonProps;
  secondaryButtonProps: IModalButtonProps;
}

export const DeleteModalView: FC<IDeleteModalViewProps> = ({ primaryButtonProps, secondaryButtonProps }) => {
  const renderDescription = useMemo((): IModalDescriptionProps => {
    return {
      primaryRow: 'Isso excluirá as ofertas de venda selecionadas.',
      secondaryRow: 'Tem certeza?',
    };
  }, []);

  return (
    <ConfirmationModal
      title="Excluir Oferta(s)"
      description={renderDescription}
      icon={<WarningDeleteIcon />}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    />
  );
};
