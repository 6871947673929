import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { TabsContainerStyled } from './transactions-list-tabs-view.styled';
import { ISelectedIdsProps } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.types';
import { TransactionsListTable } from './components/transactions-list-table/transactions-list-table.component';
import { TransactionActiveTabName } from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';
import { ThemeColorsV2Type } from 'styles/types/colors';

export interface ITransactionsListTabsViewProps {
  activeTab: TransactionActiveTabName;
  handleChangeTab: (tabName: string) => void;
  getActiveTabName: (name: string) => TransactionActiveTabName;
  selectedIdsProps: ISelectedIdsProps;
  isCompanyGPA: boolean;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const TransactionsListTabsView: React.FC<ITransactionsListTabsViewProps> = ({
  activeTab,
  isCompanyGPA,
  getActiveTabName,
  handleChangeTab,
  selectedIdsProps,
  handleInfinityScroll,
}) => {
  const tabsContent = useMemo((): Array<ITabContent> => {
    const tabs: Array<ITabContent> = [
      {
        name: 'A Pagar',
        content: (
          <TransactionsListTable
            tabName={getActiveTabName('A Pagar')}
            selectedIdsProps={selectedIdsProps}
            handleInfinityScroll={handleInfinityScroll}
          />
        ),
      },
      {
        name: 'A Receber',
        content: (
          <TransactionsListTable
            tabName={getActiveTabName('A Receber')}
            selectedIdsProps={selectedIdsProps}
            handleInfinityScroll={handleInfinityScroll}
          />
        ),
      },
      {
        name: 'Crédito Transportadora',
        content: (
          <TransactionsListTable
            tabName={getActiveTabName('Crédito Transportadora')}
            selectedIdsProps={selectedIdsProps}
            handleInfinityScroll={handleInfinityScroll}
          />
        ),
      },
    ];

    if (isCompanyGPA) {
      tabs.push({
        name: 'CTe Pendente',
        content: (
          <TransactionsListTable
            tabName={getActiveTabName('CTe Pendente')}
            selectedIdsProps={selectedIdsProps}
            handleInfinityScroll={handleInfinityScroll}
          />
        ),
        color: ThemeColorsV2Type.Red,
      });
    }
    return tabs;
  }, [getActiveTabName, handleInfinityScroll, isCompanyGPA, selectedIdsProps]);

  return (
    <TabsContainerStyled>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
    </TabsContainerStyled>
  );
};
