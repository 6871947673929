import { FormikErrors } from 'formik';
import { FC, useContext } from 'react';
import { UserContext } from 'state/user-context';
import { WeighingDuplicatedFormMountShippingView } from './weighing-duplicated-form-mount-shipping-view.component';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';

interface IWeighingDuplicatedFormMountShippingProps {
  values: IWeighingDuplicatedDomain;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDuplicatedDomain>>;
}

export const WeighingDuplicatedFormMountShipping: FC<IWeighingDuplicatedFormMountShippingProps> = ({ values,hasError, setFieldValue }) => {
  const { user } = useContext(UserContext);

  return <WeighingDuplicatedFormMountShippingView hasError={hasError} values={values} setFieldValue={setFieldValue} isMaster={!!user?.isMaster}/>;
};
