import React, { useContext } from 'react';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { ClientsContextProvider } from 'src-new/pages/registrations/pages/client/contexts/clients/clients.context';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { ClientsListPageBody } from './components/client-list-page-body/client-list-page-body.component';

export const ClientListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <InfiniteScrollListTableContextProvider>
        <ClientsContextProvider>
          <ClientsListPageBody />
        </ClientsContextProvider>
      </InfiniteScrollListTableContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
