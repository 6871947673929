import React, { ReactNode } from 'react';
import DeliveryTrackingDetailFormPage from 'pages/delivery-tracking/result-page/page';
import { DeliveryTrackingPage } from 'pages/delivery-tracking/consult-page/delivery-tracking.page';
import { MobileChecklistSection } from 'mobile/pages/checklist';
import { WeighingTottenDetails } from 'src-new/mobile/pages/weighing-totten/pages/weighing-totten-details/weighing-totten-details';

export interface RouteProps {
  path: string;
  name: string;
  profiles: string;
  component: ReactNode | undefined;
}

const routesPublic: RouteProps[] = [
  {
    path: '/rastreio',
    component: <DeliveryTrackingPage />,
    name: 'rastreio',
    profiles: 'all',
  },
  {
    path: '/rastreio/:id',
    component: <DeliveryTrackingDetailFormPage />,
    name: 'rastreio',
    profiles: 'all',
  },
  {
    path: '/checklist/:moduleId/:id',
    component: <MobileChecklistSection />,
    name: 'mobile-checklist',
    profiles: 'all',
  },
  {
    path: '/weighing-totten',
    component: <WeighingTottenDetails />,
    name: 'weighing-totten',
    profiles: 'all',
  }
];

export { routesPublic };
