import React, { useContext } from 'react';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { ScheduleRefusedContextProvider } from '../../contexts/schedule-refused/schedule-refused.context';
import { ScheduleRefusedPageBody } from './components/schedule-refused-page-body/schedule-refused-page-body.component';

export const ScheduleRefusedListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <ScheduleRefusedContextProvider>
        <ScheduleRefusedPageBody />
      </ScheduleRefusedContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
