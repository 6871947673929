import React, { ReactElement, useCallback } from 'react';
import { EquipmentVoucherRegisterPageBodyView } from './equipment-voucher-register-page-body-view.component';
import { Formik } from 'formik';
import { IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';
import { palletFormRegisterInitialValues } from 'src-new/pages/financial/components/pallet-form/constants/pallet-form-register-initial-values.constants';
import { RegisterPalletFormValidation } from 'src-new/pages/financial/components/pallet-form/validations/yup-validation';
import { useRegisterPalletForm } from 'src-new/pages/financial/hooks/use-register-pallet-form/use-register-pallet-form.hook';

export const EquipmentVoucherRegisterPageBody: React.FC = (): ReactElement => {
  const registerPalletFormHook = useRegisterPalletForm();

  const handleSubmit = useCallback((values: IPalletDomain) => registerPalletFormHook(values), [registerPalletFormHook]);

  return (
    <Formik
      initialValues={palletFormRegisterInitialValues}
      validationSchema={RegisterPalletFormValidation}
      onSubmit={handleSubmit}
    >
      <EquipmentVoucherRegisterPageBodyView />
    </Formik>
  );
};
