import { IMultiSelectOption } from '../components/multi-select/multi-select.component';

export type ServicesType =
  | 'TODOS'
  | 'COLETA'
  | 'CROSS_DOCKING'
  | 'REPALETIZACAO'
  | 'PALETIZACAO'
  | 'REPALETIZACAO_SPOT'
  | 'ARMAZENAGEM'
  | 'TRANSFERENCIA'
  | 'DEVOLUÇÃO_DE_MERCADORIA'
  | 'DEVOLUÇÃO_DE_EQUIPAMENTO'
  | 'DESLOCAMENTO'
  | 'REPALETIZAÇÃO INTERNA'
  | 'ABASTECIMENTO'
  | 'ETIQUETAGEM'
  | 'REPALETIZAÇÃO EXTERNA'
  | 'RETRABALHO'
  | 'TRANSPORTE';

export const servicesTypeOptions: IMultiSelectOption<ServicesType>[] = [
  { value: 'TODOS', label: 'TODOS' },
  { value: 'ARMAZENAGEM', label: 'ARMAZENAGEM' },
  { value: 'COLETA', label: 'COLETA' },
  { value: 'CROSS_DOCKING', label: 'CROSS DOCKING' },
  { value: 'DESLOCAMENTO', label: 'DESLOCAMENTO' },
  { value: 'DEVOLUÇÃO_DE_EQUIPAMENTO', label: 'DEVOLUÇÃO DE EQUIPAMENTO' },
  { value: 'DEVOLUÇÃO_DE_MERCADORIA', label: 'DEVOLUÇÃO DE MERCADORIA' },
  { value: 'PALETIZACAO', label: 'PALETIZAÇÃO' },
  { value: 'REPALETIZACAO', label: 'REPALETIZAÇÃO' },
  { value: 'REPALETIZACAO_SPOT', label: 'REPALETIZAÇÃO SPOT' },
  { value: 'TRANSFERENCIA', label: 'TRANSFERÊNCIA' },
];
