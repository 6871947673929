import React, { useCallback, useContext } from 'react';
import { HeaderModalsView } from './header-modals-view.component';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';

export const HeaderModals: React.FC = () => {
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);

  const handleModalClose = useCallback(() => {
    scheduleRefusedList.scheduleRefusedListModals.setScheduleRefusedModalOpen({ open: false, modalType: '' });
  }, [scheduleRefusedList.scheduleRefusedListModals]);

  return (
    <HeaderModalsView
      modalOptions={scheduleRefusedList.scheduleRefusedListModals.modalOptions}
      handleModalClose={handleModalClose}
    />
  );
};
