import React, { useCallback, useContext, useMemo } from 'react';
import { IOrdering } from 'src-new/components/table/table.types';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { SaleOfferModalMatchTableView } from './sale-offer-modal-match-table-view.component';
import { ISaleOfferMatchListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list.domain';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { useUpdateFavoriteMatch } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/hooks/use-update-favorite-match/use-update-favorite-match.hook';
import { useOpenProposalChat } from 'src-new/hooks/chat/use-open-proposal-chat/use-open-proposal-chat.hook';

export const SaleOfferModalMatchTable: React.FC = () => {
  const { saleOfferModals, saleOfferMatchList } = useContext(SaleOfferContext);
  const { favoriteMatchesKanbanList } = useContext(MatchesContext);
  const { statusRequest, saleOfferMatchListSelected, saleOfferMatchProposal } = saleOfferMatchList;
  const { setSaleOfferMatchListSelectedRequest, setSaleOfferMatchListSelectedSuccess } = saleOfferMatchListSelected;
  const { modalType, setModalOpen } = saleOfferModals;
  const openProposalChat = useOpenProposalChat();

  const updateFavoriteMatchHook = useUpdateFavoriteMatch();

  const handleFavoriteMatch = useCallback(
    (matchId: number, isFavorite: boolean) => updateFavoriteMatchHook(matchId, isFavorite),
    [updateFavoriteMatchHook],
  );

  const handleOpenModalMatchBidding = useCallback(
    (matchSelected: ISaleOfferMatchListDomain) => {
      setSaleOfferMatchListSelectedRequest();
      setModalOpen({
        indexes: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
        modalType,
        secondModalType: 'sale_offer_match_bidding',
      });
      setSaleOfferMatchListSelectedSuccess(matchSelected);
    },
    [setSaleOfferMatchListSelectedRequest, setModalOpen, modalType, setSaleOfferMatchListSelectedSuccess],
  );

  const handleOpenProposalChat = useCallback(
    async (purchaseId: string, matchId: string) => {
      const buyOffer = saleOfferMatchList.saleOfferMatchList.filter(
        (buyOffer) => buyOffer.matchId === Number(matchId),
      )[0];
      await openProposalChat({
        purchaseOfferId: purchaseId,
        saleTariffOfferId: saleOfferMatchProposal.proposal.idTariff.toString(),
        saleOfferId: saleOfferMatchProposal.proposal.id.toString(),
        matchId,
        saleOfferCompanyId: saleOfferMatchProposal.proposal.companyId.toString(),
        buyOfferCompanyId: buyOffer.company.id.toString(),
        pageType: 'SALE_OFFER',
      });
    },
    [
      openProposalChat,
      saleOfferMatchList.saleOfferMatchList,
      saleOfferMatchProposal.proposal.companyId,
      saleOfferMatchProposal.proposal.id,
      saleOfferMatchProposal.proposal.idTariff,
    ],
  );

  const getSaleOffer = useMemo((): Array<ISaleOfferMatchListDomain> => {
    return saleOfferMatchList.saleOfferMatchList.map((matchList) => {
      const favoriteMatchList = favoriteMatchesKanbanList.favoriteMatchesKanbanList.items.find(
        (favoriteMatch) => favoriteMatch.matchOffersRoutesId === matchList.matchId,
      );

      if (favoriteMatchList) {
        return { ...matchList, isFavorite: favoriteMatchList.isFavorite };
      }

      return { ...matchList, isFavorite: false };
    });
  }, [favoriteMatchesKanbanList.favoriteMatchesKanbanList.items, saleOfferMatchList.saleOfferMatchList]);

  const isLoading = useMemo(() => statusRequest === 'PROCESSING', [statusRequest]);

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: 'ID',
      order: 'DESC',
      handleClick: () => undefined,
    };
  }, []);

  return (
    <SaleOfferModalMatchTableView
      saleOfferMatch={getSaleOffer}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      handleFavoriteMatch={handleFavoriteMatch}
      handleOpenModalMatchBidding={handleOpenModalMatchBidding}
      handleOpenProposalChat={handleOpenProposalChat}
    />
  );
};
