import { VehicleTypeDomain, VehicleTypeResponse } from 'src-new/domains/vehicle-type.domain';

export const convertVehicleType = (vehicleType: VehicleTypeResponse): VehicleTypeDomain => {
  const vehicleToLower = vehicleType.toLowerCase() as VehicleTypeResponse;
  const vehicleTypeMap: Record<VehicleTypeResponse, VehicleTypeDomain> = {
    '3/4': '3/4',
    tres_quartos: '3/4',
    bitrem: 'Bitrem',
    bitruck: 'Bitruck',
    carreta: 'Carreta',
    carreta_ls: 'Carreta LS',
    carreta_sete_eixos: 'Carreta 7 Eixos',
    complemento: 'Complemento',
    rodotrem: 'Rodotrem',
    'romeu&julieta': 'Romeu & Julieta',
    toco: 'Toco',
    truck: 'Truck',
    utilitario: 'Utilitário',
    van: 'Van',
    vanderleia: 'Vanderleia',
    vuc: 'Vuc',
    '': '',
  };

  return vehicleTypeMap[vehicleToLower];
};

export const convertVehicleTypeToEntity = (vehicleType: VehicleTypeDomain): VehicleTypeResponse => {
  const vehicleTypeMap: Record<VehicleTypeDomain, VehicleTypeResponse> = {
    '3/4': 'tres_quartos',
    Bitrem: 'bitrem',
    Bitruck: 'bitruck',
    Carreta: 'carreta',
    'Carreta LS': 'carreta_ls',
    'Carreta 7 Eixos': 'carreta_sete_eixos',
    Complemento: 'complemento',
    Rodotrem: 'rodotrem',
    'Romeu & Julieta': 'romeu&julieta',
    Toco: 'toco',
    Truck: 'truck',
    Utilitário: 'utilitario',
    Van: 'van',
    Vanderleia: 'vanderleia',
    Vuc: 'vuc',
    '': '',
  };

  return vehicleTypeMap[vehicleType];
};
