type StatusEntity = 'atrasada' | 'nao_atendidas' | 'em_tratativa' | 'finalizadas' | undefined;
type StatusDomain = 'Atrasada' | 'Não Atendida' | 'Em Tratativa' | 'Finalizada' | undefined;

export const convertStatusEntityToDomainMapper = (statusDomain: StatusEntity): StatusDomain => {
  switch (statusDomain) {
    case 'nao_atendidas':
      return 'Não Atendida';
    case 'em_tratativa':
      return 'Em Tratativa';
    case 'finalizadas':
      return 'Finalizada';
    case 'atrasada':
      return 'Finalizada';

    default:
      return statusDomain;
  }
};
