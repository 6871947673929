import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { VehicleCategory, VehicleType } from 'domain-v2/inputs/vehicles';

export const convertSchedule = (schedule: IScheduleDetailsDomain): ScheduleRegisterInput => {
  const { origin, destination, cargo, freithgtPayable, freithgtReceivable } = schedule;

  const cost = freithgtPayable ?? freithgtReceivable;

  return {
    origin: {
      ...origin,
      tradeName: origin.name,
      origin: {
        ...origin,
        lat: origin.address.lat,
        lng: origin.address.lng,
        inbound: undefined as any,
        outbound: undefined as any,
        internalCode: '',
        occurrenceEmail: origin.emailsOcurrence,
        quality_and_safety: origin.qualityAndSafety,
        schedulingEmail: origin.emails,
        status: 'active',
        type: '',
        name: origin.name,
        vehicleRestriction: [''],
        address: {
          ...origin.address,
          numberHouse: origin.address.number,
        },
        clientIds: [],
        operationType: [],
        vehicleCategoryType: [],
      },
    },
    destination: {
      ...destination,
      tradeName: destination.name,
      destination: {
        ...destination,
        lat: destination.address.lat,
        lng: destination.address.lng,
        inbound: undefined as any,
        outbound: undefined as any,
        internalCode: '',
        occurrenceEmail: destination.emailsOcurrence,
        quality_and_safety: destination.qualityAndSafety,
        schedulingEmail: destination.emails,
        status: 'active',
        type: '',
        vehicleRestriction: [''],
        address: {
          ...destination.address,
          numberHouse: destination.address.number,
        },
        clientIds: [],
        operationType: [],
        vehicleCategoryType: [],
      },
    },
    cargo: {
      ...cargo,
      vehicleType: cargo.vehicleType as VehicleType,
      vehicleCategoryType: cargo.vehicleCategoryType as VehicleCategory,
    },
    detail: {
      levyInitialDate: '',
      levyEndDate: '',
      deliveryInitialDate: '',
      deliveryEndDate: '',
      internalNumber: '',
      ocInternalNumber: '',
      boardingNumber: '',
      schedulingPassword: '',
      dateOfDeliveryLogShare: '',
      dateToShowDeliveryCustomTariff: '',
    },
    cost: {
      valueFreight: cost.valueFreight,
      valueGoods: cost.otherTaxes,
      valueTotalFee: cost.totalFee,
      taxes: cost.otherTaxes,
      totalFreight: cost.totalFreight,
      helperTotal: cost.helperTotal,
      toll: cost.toll,
      totalGrisAdvalorem: cost.totalGrisAdvalorem,
      totalService: cost.totalService,
      fine: 0,
      leadTime: 0,
      icms: cost.icms,
    },
    distance: schedule.distance,
    leadTime: 0,
    operation: 'SPOT',
    retentionFines: {
      freeTime: '',
      vehicleType: '',
      hourlyFine: 0,
      dailyFine: 0,
    },
    backhaul: {
      saleOfferMatches: [],
      transportRoutes: [],
      bestPrice: 0,
      sellId: 0,
      companySellerId: 0,
      invoicedBy: '',
      helperPrice: 0,
      tollPrice: 0,
      grisAdvaloremPrice: 0,
      taxPrice: 0,
      icms: 0,
      totalService: 0,
      totalFreight: 0,
      freightValue: 0,
      matchesProposal: {
        matches: [],
        meta: {
          currentPage: 0,
          itemCount: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
        proposal: {
          capacity: {
            pallet: 0,
            weight: 0,
          },
          company: {
            companiesId: [],
            id: 0,
            name: '',
            image: '',
          },
          destiny: {
            city: '',
            id: 0,
            name: '',
            type: '',
            uf: '',
          },
          distance: 0,
          freightValue: 0,
          frequency: 0,
          id: 0,
          matchCounter: 0,
          operationType: '',
          origin: {
            city: '',
            id: 0,
            name: '',
            type: '',
            uf: '',
          },
          proposalType: 'backhaul',
          scheduleType: 'FTL',
          vehicle: {
            category: '',
            type: '',
          },
          fleetType: '',
        },
        totalRow: {
          externalBackhaul: 0,
          internalBackhaul: 0,
        },
      },
    },
    customFields: [],
  };
};
