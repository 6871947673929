import API from 'utils/API/API';

export const hoursDetailsUpdateService = async (
  id: number,
  acceptDate: string,
  startDate: string,
  checkinDate: string,
  checkoutDate: string,
  loadDate: string,
  finishDate: string,
) => {
  await API.put(`v2/schedule-details/${id}`, {
    acceptDate,
    startDate,
    checkinDate,
    checkoutDate,
    loadDate,
    finishDate,
  });
};
