import styled from 'styled-components';
import { Formik } from 'formik';

export const ModalSelectInvoicingStyle = styled(Formik)``;

export const ModalSelectionInvoiceForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div
`;

export const ModalSelectionInvoiceData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;

    p {
      font-weight: bold;
    }

    &:last-child {
      flex: 1;
    }
  }
`;
