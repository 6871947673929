import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { deleteCTeDataService } from '../../services/delete-cte-data/delete-cte-data.service';
import { getCTeDataService } from '../../services/get-cte-data/get-cte-data.service';

export const useDeleteCTeData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(async () => {
    try {
      scheduleDocuments.cteDataProps.setCTeDataRequest();

      await deleteCTeDataService(scheduleDocuments.modalsProps.modalOptions.id);

      const data = await getCTeDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.cteDataProps.setCTeDataSuccess(data);
    } catch (error: any) {
      scheduleDocuments.cteDataProps.setCTeDataError();
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
    scheduleDocuments.modalsProps.setModalOpen({ open: false, id: 0, modalType: '' });
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.cteDataProps, scheduleDocuments.modalsProps]);
};
