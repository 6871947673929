import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { OperatedMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListDomain } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/domains/matches-kanban-list/matches-kanban-list.domain';
import { IOperatedMatchesKanbanList } from '../../types/operated-matches-kanban.types';
import { operatedMatchesKanbanListReducer } from '../../reducers/operated-matches-kanban-list/operated-matches-kanban-list.reducer';

export const useOperatedMatchesKanbanListContextValues = (): IOperatedMatchesKanbanList => {
  const [state, dispatch] = useReducer(operatedMatchesKanbanListReducer, matchesInitialState);

  const setOperatedMatchesKanbanListRequest = useCallback(() => {
    dispatch({ type: OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_REQUEST, payload: undefined });
  }, []);

  const setOperatedMatchesKanbanListSuccess = useCallback((kanban: IMatchesKanbanListDomain) => {
    dispatch({ type: OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_SUCCESS, payload: kanban });
  }, []);

  const setOperatedMatchesKanbanListError = useCallback(() => {
    dispatch({ type: OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_ERROR, payload: undefined });
  }, []);

  const setOperatedMatchesKanbanListReset = useCallback(() => {
    dispatch({ type: OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_RESET, payload: undefined });
  }, []);

  return {
    ...state.operatedMatchesKanbanList,
    setOperatedMatchesKanbanListRequest,
    setOperatedMatchesKanbanListSuccess,
    setOperatedMatchesKanbanListError,
    setOperatedMatchesKanbanListReset,
  };
};
