import { SelectItem } from '../entities/select-items.entity';

import API from 'utils/API/API';
import { convertClientEntityToSelectItem } from 'pages/backhaul/mappers/convert-client-entity-to-select-item/convert-client-entity-to-select-item.mapper';

export const clientsFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`clients-filter?search=${searchValue}`);

  return data.map((client: { tradeName: string; id: number }) => convertClientEntityToSelectItem(client));
};
