import styled from 'styled-components';
import { Form } from 'formik';

export const WrapperForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;
