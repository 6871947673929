import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';
import { ITransportCompanyEntityV2 } from '../../services/entities/transport-companies.entity';

import { ITransportCompanyV2 } from 'domain/transport-company';

export const convertTransportCompanyCreatedEntityToDomainMapperV2 = (
  entity: ITransportCompanyEntityV2,
): ITransportCompanyV2 => ({
  id: entity.id,
  name: entity.name,
  cnpj: entity.cnpj,
  bankAgency: entity.bankAgency,
  bankCC: entity.bankCC,
  bankCode: entity.bankCode,
  bankPix: entity.bankPix,
  schedulingEmail: entity.schedulingEmail,
  occurrenceEmail: entity.occurrenceEmail,
  status: convertStatusEntityToDomainMapper(entity.status),
  cep: entity.cep,
  city: entity.city,
  uf: entity.uf,
  neighborhood: entity.neighborhood,
  street: entity.street,
  numberHouse: entity.numberHouse,
  complement: entity.complement,
  rntrc: String(entity.rntrc),
  internalCode: entity.internalCode,
  userName: entity.userName,
  userEmail: entity.userEmail,
  contacts: entity.contacts,
  company: entity.company,
});
