import React, { ChangeEvent, DragEvent, useCallback, useMemo, useRef } from 'react';
import { InputDropFileView } from './input-drop-file-view.component';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export type AcceptFiles = '.xlsx' | '.xls';

interface IInputDropFileProps {
  acceptFiles: Array<AcceptFiles>;
  disabled?: boolean;
  allowMultipleFiles?: boolean;
  handleSelectedFile: (files: FileList) => void;
  handleTemplateDownload?: () => void;
}

export const InputDropFile: React.FC<IInputDropFileProps> = ({
  acceptFiles,
  disabled,
  allowMultipleFiles,
  handleSelectedFile,
  handleTemplateDownload,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (disabled) return;

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [disabled]);

  const handleValidateFileType = useCallback(
    (files: FileList): boolean => {
      const isValidFileType = acceptFiles.some((type) => files[0].name.endsWith(type));

      if (!isValidFileType) {
        showMessageFormatted({
          message: `Selecione arquivos com o(s) formato(s) ${acceptFiles.join(' ou ')}!`,
          type: 'error',
        });

        return false;
      }

      return true;
    },
    [acceptFiles],
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (disabled) return;

      const files = event.dataTransfer.files;

      const validateFileType = handleValidateFileType(files);

      if (validateFileType) {
        handleSelectedFile(files);
      }
    },
    [disabled, handleSelectedFile, handleValidateFileType],
  );

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;

      const files = event.target.files;

      if (files && files.length > 0) {
        const validateFileType = handleValidateFileType(files);

        if (validateFileType) {
          handleSelectedFile(files);
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    },
    [disabled, handleSelectedFile, handleValidateFileType],
  );

  const handleTemplate = useCallback(() => {
    if (!disabled && handleTemplateDownload) {
      handleTemplateDownload();
    }
  }, [disabled, handleTemplateDownload]);

  const inputAcceptFiles = useMemo((): string => acceptFiles.join(', '), [acceptFiles]);

  return (
    <InputDropFileView
      inputAcceptFiles={inputAcceptFiles}
      fileInputRef={fileInputRef}
      disabled={disabled}
      allowMultipleFiles={allowMultipleFiles}
      handleClick={handleClick}
      handleDrop={handleDrop}
      handleDragOver={handleDragOver}
      handleFileChange={handleFileChange}
      handleTemplate={handleTemplate}
    />
  );
};
