import React, { useCallback, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import {
  EmptySpinnerContainer,
  FieldContainerStyled,
  GroupFieldContainerStyled,
  ModalContainerStyled,
  SaveButtonContainerStyled,
  SaveButtonStyled,
} from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal-view.styled';
import { ContactRegistrationAlerts } from './components/contact-registration-alerts/contact-registration-alerts.component';
import { InputFieldFormik } from 'src-new/components/formik/input-field/input-field-formik.component';
import { InputFieldPhoneFormik } from 'src-new/components/formik/input-field-phone/input-field-phone-formik.component';

interface IContactRegistrationModalViewProps {
  contact?: IContactDomain;
  handleCloseModal: () => void;
  hasErrorField: (name: string) => boolean;
  getErrorMessage: (name: string) => string;
  position: number;
  handleSaveButton: (contact: IContactDomain) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ContactRegistrationModalView: React.FC<IContactRegistrationModalViewProps> = ({
  contact,
  handleCloseModal,
  getErrorMessage,
  hasErrorField,
  position,
  handleSaveButton,
  handleChange,
}) => {
  const renderSaveButton = useCallback(
    (contact: IContactDomain) => {
      return (
        <SaveButtonContainerStyled>
          <SaveButtonStyled type={'button'} onClick={() => handleSaveButton(contact)}>
            Salvar
          </SaveButtonStyled>
        </SaveButtonContainerStyled>
      );
    },
    [handleSaveButton],
  );

  const renderContent = useMemo(() => {
    if (!contact) return <EmptySpinnerContainer />;

    return (
      <ModalContainerStyled>
        <GroupFieldContainerStyled>
          <FieldContainerStyled>
            <InputFieldFormik
              label="Nome"
              id={`contacts[${position}].name`}
              name={`contacts[${position}].name`}
              type="text"
              value={contact.name}
              onChange={handleChange}
              hasError={hasErrorField(`contacts[${position}].name`)}
              errorMessage={getErrorMessage(`contacts[${position}].name`)}
            />
          </FieldContainerStyled>
          <FieldContainerStyled secondaryField>
            <InputFieldFormik
              label="Área"
              id={`contacts[${position}].area`}
              name={`contacts[${position}].area`}
              type="text"
              value={contact.area}
              onChange={handleChange}
              hasError={hasErrorField(`contacts[${position}].area`)}
              errorMessage={getErrorMessage(`contacts[${position}].area`)}
            />
          </FieldContainerStyled>
        </GroupFieldContainerStyled>
        <GroupFieldContainerStyled secondaryField>
          <FieldContainerStyled>
            <InputFieldFormik
              label="E-mail"
              id={`contacts[${position}].email`}
              name={`contacts[${position}].email`}
              type="text"
              value={contact.email}
              onChange={handleChange}
              hasError={hasErrorField(`contacts[${position}].email`)}
              errorMessage={getErrorMessage(`contacts[${position}].email`)}
            />
          </FieldContainerStyled>
          <FieldContainerStyled secondaryField>
            <InputFieldPhoneFormik
              label="Telefone"
              id={`contacts[${position}].telephone`}
              name={`contacts[${position}].telephone`}
              type="text"
              value={contact.telephone}
              onChange={handleChange}
              hasError={hasErrorField(`contacts[${position}].telephone`)}
              errorMessage={getErrorMessage(`contacts[${position}].telephone`)}
            />
          </FieldContainerStyled>
        </GroupFieldContainerStyled>
        <ContactRegistrationAlerts position={position} alerts={contact.alerts} />
        {renderSaveButton(contact)}
      </ModalContainerStyled>
    );
  }, [contact, getErrorMessage, handleChange, hasErrorField, position, renderSaveButton]);

  return (
    <ModalBark size={'md'} title={'Cadastrar Contato'} handleClose={handleCloseModal}>
      {renderContent}
    </ModalBark>
  );
};
