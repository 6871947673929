import React, { useEffect, useState } from 'react';

import * as S from './styled';

import { formatCurrency } from 'utils/format-numbers';
import { UserContext } from 'state/user-context';
import { useGetRetentionFinesByVehicleHook } from 'pages/schedule/hooks/use-get-retention-fines/use-get-retention-fines';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { RetentionFines } from 'domain/schedule-v2/register';
import { IFreightValues } from 'domain/card-resume';
import { showToast } from 'components/toast/toast';

interface CardResumeValuesProps {
  retentionFinesValues?: RetentionFines;
  freightValues?: IFreightValues;
  hasConnector?: boolean;
  resumeValues?: ICurrentAllocation;
}

export const CardResumeValues: React.FC<CardResumeValuesProps> = ({
  retentionFinesValues,
  freightValues,
  hasConnector,
  resumeValues,
}) => {
  const { user } = React.useContext(UserContext);

  const getRetentionFinesHook = useGetRetentionFinesByVehicleHook();

  const [freeTime, setFreeTime] = useState<RetentionFines>();

  useEffect(() => {
    if (retentionFinesValues) setFreeTime(retentionFinesValues);
  }, [retentionFinesValues]);

  useEffect(() => {
    (async () => {
      try {
        if (!resumeValues) return;

        const { scheduleCargo, companyId } = resumeValues;

        if (!scheduleCargo || !companyId) return;

        const { vehicleType, dailyFine, hourlyFine, freeTime } = await getRetentionFinesHook(
          scheduleCargo?.vehicleType,
          companyId,
        );

        setFreeTime({
          vehicleType,
          dailyFine,
          hourlyFine,
          freeTime: `${freeTime} Horas`,
        });
      } catch (error) {
        showToast({
          message: 'Houve um erro ao carregar Free Time, tente novamente',
          type: 'error',
        });
      }
    })();
  }, [resumeValues]);

  const renderRetentionFinesValues = React.useMemo(() => {
    if (freeTime?.freeTime) {
      const hourlyFine = formatCurrency(freeTime.hourlyFine);
      const dailyFine = formatCurrency(freeTime.dailyFine);

      return (
        <S.LineSection columns="1.5fr 1fr">
          <S.Text>Free Time</S.Text>
          <S.Text>{`${freeTime.freeTime ?? '0 Horas'}`}</S.Text>

          <S.Text>Hora Adicional</S.Text>
          <S.Text>{`${hourlyFine ?? 'R$ 0'}`}</S.Text>

          <S.Text>Diária</S.Text>
          <S.Text padding="0 0 10px 0">{`${dailyFine ?? 'R$ 0'}`}</S.Text>
        </S.LineSection>
      );
    }
    return (
      <S.Text italic padding="0 0 10px 0">
        Não Informado
      </S.Text>
    );
  }, [freeTime]);

  const renderFreightValues = React.useMemo(() => {
    const parsedFreightFine = Number(freightValues?.fine);
    const valueFreight = (freightValues?.value || 0) + (parsedFreightFine || 0);
    const totalFreight =
      valueFreight +
      (freightValues?.helperPrice || 0) +
      (freightValues?.tollPrice || 0) +
      ((user?.plan !== 'shipping-company' && freightValues?.grisAdvaloremPrice) || 0);
    const totalService = (freightValues?.total || 0) + (parsedFreightFine || 0);

    if (freightValues?.value) {
      return (
        <>
          <S.LineSection columns="1.5fr 1fr">
            <S.Text>Frete Valor</S.Text>
            {!isNaN(valueFreight) ? (
              <S.Text>{`${formatCurrency(valueFreight)}`}</S.Text>
            ) : (
              <S.Text>{`${formatCurrency(freightValues?.value)}`}</S.Text>
            )}

            <S.Text>Ajudante</S.Text>
            <S.Text>{`${formatCurrency(freightValues?.helperPrice)}`}</S.Text>

            {user?.plan !== 'shipping-company' && (
              <>
                <S.Text>Gris\Advalorem</S.Text>
                <S.Text>{`${formatCurrency(freightValues?.grisAdvaloremPrice)}`}</S.Text>
              </>
            )}

            <S.Text>Pedágio</S.Text>
            <S.Text>{`${formatCurrency(freightValues?.tollPrice)}`}</S.Text>

            <S.Text fontWeight>Frete Total</S.Text>
            <S.Text fontWeight padding="0 0 10px 0">{`${formatCurrency(totalFreight)}`}</S.Text>
          </S.LineSection>
          {user?.plan !== 'shipping-company' && (
            <S.Text>
              <S.LineSection columns="1.5fr 1fr">
                <S.Text>ICMS</S.Text>
                <S.Text>{`${formatCurrency(freightValues?.icms || 0)}`}</S.Text>

                <S.Text>Outros Impostos</S.Text>
                <S.Text>R$ 0,00</S.Text>

                <S.Text fontWeight>Total do Serviço</S.Text>
                <S.Text fontWeight>{`${formatCurrency(totalService)}`}</S.Text>
              </S.LineSection>
            </S.Text>
          )}
        </>
      );
    }
    return (
      <S.Text italic padding="0 0 10px 0">
        Não Informado
      </S.Text>
    );
  }, [freightValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
          {hasConnector && <S.Connector size={'110'} />}
        </S.StepIconSection>

        <S.Text blueColor fontWeight>
          Valores
        </S.Text>

        <S.TextContent>
          <S.Text blueColor fontWeight>
            Free Time e Diária
          </S.Text>

          {renderRetentionFinesValues}

          <S.Text blueColor fontWeight>
            Frete Previsto
          </S.Text>

          {renderFreightValues}
        </S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
