import API from 'utils/API/API';
import { IUpdateScheduleCargoPayload } from './update-schedule-details.types';
import { convertScheduleDetailsDomainToResponse } from './mappers/converter-schedule-details-domain-to-payload/converter-schedule-details-domain-to-response.mapper';

export const updateScheduleDetailsService = async (id: string, payload: IUpdateScheduleCargoPayload) => {
  const call = await API.put<IUpdateScheduleCargoPayload>(
    `v3/schedules/${id}`,
    convertScheduleDetailsDomainToResponse(payload),
  );
  return call;
};
