import React from 'react';

import LocationRegisterForm from '../location-register-form/location-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const LocationRegisterBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="location" breadcrumbList={['Cadastro', 'Unidades']} hasBackButton />
      <Title title="Nova Unidade" hasTrace isForm />
      <LocationRegisterForm />
    </React.Fragment>
  );
};

export default LocationRegisterBodyView;
