import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const WrapperLoading = styled.div`
  margin: auto;
`;

export const Title = styled.div`
  ${() => css`
    color: #000;
    font-family: Montserrat, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const Subtitle = styled.div`
  ${() => css`
    color: #7b7b7b;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const WrapperInformationLocations = styled.div`
  display: flex;
  gap: 0.5rem;

  > div:last-child {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

export const WrapperMatchScore = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const WrapperTags = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.5rem;
`;

export const Div = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  button {
    min-width: 80px !important;
  }
`;

export const WrapperIdAndImage = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.5rem;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 53vh;
  `}
`;

export const EmptyListWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 53vh;
  `}
`;