import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { FinanceStatus } from '../../components/schedule-details-tabs/components/schedule-finance/components/schedule-finance-accordions/schedule-finance-accordions.types';
import { updateFinanceService } from '../../services/update-finance-status/update-finance-status.service';

export const useUpdateFinanceStatus = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (transactionId: number, financeStatus: FinanceStatus) => {
      try {
        await updateFinanceService(transactionId, financeStatus);

        showMessageFormatted({
          message: 'Status atualizado.',
          type: 'success',
        });

        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
