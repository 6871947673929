import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseGetFreetime } from 'src-new/hooks/use-get-freetime/use-get-freetime.hook';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { saleOfferShipperTariffRegisterInitialValuesDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register-initial-values.domain';
import {
  ISaleOfferFormRegisterPayloadDomain,
  ISaleOfferShipperTariffRegisterPayloadDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { SaleOfferRegisterTariffFormModalView } from './sale-offer-register-tariff-form-modal-view.component';
import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';
import { companyDetailsService } from 'services/company/details';
import { UserContext } from 'state/user-context';
import { ICompany } from 'domain/company';
import { IOptionsDomain } from 'src-new/domains/options.domain';
import { vehicleTypeOptionsDomain } from 'src-new/domains/vehicle-type.domain';
import { vehicleCategoryOptionsDomain } from 'src-new/domains/vehicle-category.domain';

const LOGSHARE_COMPANY_ID = 2;

export const SaleOfferRegisterTariffFormModal: FC = () => {
  const { values, setFieldValue } = useFormikContext<ISaleOfferFormRegisterPayloadDomain>();
  const { user, isAdmin, isZMaster } = useContext(UserContext);
  const { saleOfferModals, saleOfferFreetime } = useContext(SaleOfferContext);

  const [company, setCompany] = useState<ICompany>();
  const [offerShipper, setOfferShipper] = useState<ISaleOfferShipperTariffRegisterPayloadDomain>(
    saleOfferShipperTariffRegisterInitialValuesDomain,
  );

  const getRetentionFineByVehicleHook = UseGetFreetime();

  const indexSection = useMemo(() => {
    if (saleOfferModals.indexSection.saleOfferShipper) return saleOfferModals.indexSection.saleOfferShipper;
    return 0;
  }, [saleOfferModals.indexSection]);

  const userShippingCompany = useMemo(() => {
    return user?.profile === 'shipping-company';
  }, [user?.profile]);

  const companyName = useMemo((): string => company?.tradeName ?? '', [company?.tradeName]);

  const buttonDisabled = useMemo(
    (): boolean =>
      !offerShipper.vehicleType ||
      !offerShipper.vehicleCategory ||
      !offerShipper.valueShipper ||
      !offerShipper.valueLogshare ||
      offerShipper.valueTotal === 0,
    [
      offerShipper.valueLogshare,
      offerShipper.valueShipper,
      offerShipper.valueTotal,
      offerShipper.vehicleCategory,
      offerShipper.vehicleType,
    ],
  );

  const feeLogshare = useMemo(() => Number(company?.feeLogshare) || 0, [company?.feeLogshare]);

  const vehicleOptions = useMemo((): { vehicleType: Array<IOptionsDomain>; vehicleCategory: Array<IOptionsDomain> } => {
    const combinedVehicleType = [
      ...(values.origin?.vehicleRestriction ?? []),
      ...(values.destination?.vehicleRestriction ?? []),
    ];

    const combinedVehicleCategory = [
      ...(values.origin?.vehicleCategoryType ?? []),
      ...(values.destination?.vehicleCategoryType ?? []),
    ];

    const vehicleType = vehicleTypeOptionsDomain.map((option) => ({
      ...option,
      isDisabled: combinedVehicleType.length > 0 && !combinedVehicleType.some((item) => item === option.value),
    }));

    const vehicleCategory = vehicleCategoryOptionsDomain.map((option) => {
      const isDisabled =
        combinedVehicleCategory.length > 0 && !combinedVehicleCategory.some((item) => item === option.value);

      if (option.value === 'Porta Container 20') {
        return { ...option, label: 'CONTAINER 20', isDisabled };
      }
      if (option.value === 'Porta Container 40') {
        return { ...option, label: 'CONTAINER 40', isDisabled };
      }
      return { ...option, isDisabled };
    });

    return { vehicleType, vehicleCategory };
  }, [
    values.destination?.vehicleCategoryType,
    values.destination?.vehicleRestriction,
    values.origin?.vehicleCategoryType,
    values.origin?.vehicleRestriction,
  ]);

  const handleCloseRegisterShipperTariffModal = useCallback(() => {
    saleOfferModals.setModalClose();
  }, [saleOfferModals]);

  const handleChange = useCallback(
    (name: keyof ISaleOfferShipperTariffRegisterPayloadDomain, value: any) => {
      setOfferShipper((prevOfferShipper) => ({
        ...prevOfferShipper,
        [name]: value,
      }));
    },
    [setOfferShipper],
  );

  const handleAddOfferShipper = useCallback(() => {
    setFieldValue(`saleOfferDetails[${indexSection}].tariff`, [
      ...values.saleOfferDetails[indexSection].tariff,
      offerShipper,
    ]);
    handleCloseRegisterShipperTariffModal();
  }, [handleCloseRegisterShipperTariffModal, indexSection, offerShipper, setFieldValue, values.saleOfferDetails]);

  useEffect(() => {
    (async () => {
      if (offerShipper.vehicleType) {
        await getRetentionFineByVehicleHook(offerShipper.vehicleType, LOGSHARE_COMPANY_ID);
      }
    })();
  }, [getRetentionFineByVehicleHook, offerShipper.vehicleType]);

  useEffect(() => {
    const sum =
      normalizeCurrency(offerShipper.valueShipper) +
      normalizeCurrency(offerShipper.valueShipping) +
      normalizeCurrency(offerShipper.valueLogshare);

    const total = Number.isNaN(sum) ? 0 : Number(sum.toFixed(2));

    handleChange('valueTotal', total);
  }, [handleChange, offerShipper.valueLogshare, offerShipper.valueShipper, offerShipper.valueShipping]);

  useEffect(() => {
    const calcFee =
      (normalizeCurrency(offerShipper.valueShipping) + normalizeCurrency(offerShipper.valueShipper)) *
      (normalizeCurrency(offerShipper.feeLogshare) / 100);
    handleChange('valueLogshare', calcFee.toFixed(2).replace('.', ','));

    if (offerShipper.invoicedBy === 'transportadora') {
      const feeLogshareFixed = company?.feeLogshareWithoutCTe;
      handleChange('valueShipper', '0');
      handleChange('valueLogshare', feeLogshareFixed?.replace('.', ','));
    }
  }, [
    company?.feeLogshareWithoutCTe,
    handleChange,
    offerShipper.feeLogshare,
    offerShipper.invoicedBy,
    offerShipper.valueShipper,
    offerShipper.valueShipping,
  ]);

  useEffect(() => {
    (async () => {
      if (user) {
        const findCompany = await companyDetailsService(user.companyId);
        setCompany(findCompany);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (company && offerShipper.invoicedBy === 'logshare') {
      handleChange('gris', company.gris);
      handleChange('advalorem', company.advalorem);
    }

    handleChange('freetime', saleOfferFreetime.saleOfferFreetime);
  }, [company, company?.gris, handleChange, offerShipper.invoicedBy, saleOfferFreetime.saleOfferFreetime]);

  useEffect(() => {
    (async () => {
      if (user && userShippingCompany) {
        handleChange('invoicedBy', 'transportadora');
        handleChange('shipping', { id: user.shippingCompanyId, name: user.login });
      }
    })();
  }, [handleChange, user, userShippingCompany]);

  useEffect(() => handleChange('feeLogshare', feeLogshare), [feeLogshare, handleChange]);

  return (
    <SaleOfferRegisterTariffFormModalView
      values={offerShipper}
      isAdmin={isAdmin}
      isZMaster={isZMaster}
      userShippingCompany={userShippingCompany}
      indexSection={indexSection}
      companyName={companyName}
      buttonDisabled={buttonDisabled}
      vehicleOptions={vehicleOptions}
      handleChange={handleChange}
      handleOnSubmit={handleAddOfferShipper}
      handleCloseRegisterShipperTariffModal={handleCloseRegisterShipperTariffModal}
    />
  );
};
