import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { DocumentsOthersView } from './documents-others-view.component';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleDocumentsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { useGetOthersDocumentsData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-others-documents-data/use-get-others-documents-data.hook';

export const DocumentsOthers: React.FC = () => {
  const [viewMode, setViewMode] = useState<'list' | 'kanban'>('list');
  const { scheduleDocuments } = useContext(ScheduleContext);
  const getOthersDocumentsData = useGetOthersDocumentsData();

  const handleOpenModal = useCallback(
    (modalType: ScheduleDocumentsModalType, id: number) =>
      scheduleDocuments.modalsProps.setModalOpen({ open: true, id, modalType }),
    [scheduleDocuments.modalsProps],
  );

  const isLoading = useMemo(
    () => scheduleDocuments.othersDocumentsProps.statusRequest === 'PROCESSING',
    [scheduleDocuments.othersDocumentsProps.statusRequest],
  );

  const isEmptyList = useMemo(
    () =>
      scheduleDocuments.othersDocumentsProps.statusRequest === 'SUCCESS' &&
      !scheduleDocuments.othersDocumentsProps.otherDocumentsList.length,
    [
      scheduleDocuments.othersDocumentsProps.otherDocumentsList.length,
      scheduleDocuments.othersDocumentsProps.statusRequest,
    ],
  );

  const othersDocuments = useMemo(
    () => scheduleDocuments.othersDocumentsProps.otherDocumentsList,
    [scheduleDocuments.othersDocumentsProps.otherDocumentsList],
  );

  const handleToggleClick = useCallback(() => setViewMode(viewMode === 'list' ? 'kanban' : 'list'), [viewMode]);

  useEffect(() => {
    if (scheduleDocuments.othersDocumentsProps.statusRequest === 'INITIAL') {
      getOthersDocumentsData();
    }
  }, [getOthersDocumentsData, scheduleDocuments.othersDocumentsProps.statusRequest]);

  return (
    <DocumentsOthersView
      othersDocuments={othersDocuments}
      isLoading={isLoading}
      handleOpenModal={handleOpenModal}
      isEmptyList={isEmptyList}
      handleToggleClick={handleToggleClick}
      viewMode={viewMode}
    />
  );
};
