import React, { Fragment } from 'react';

import { AccessRestrictedStyled, ContainerAccessStyled } from './freight-receivable-list-body-view.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const dataTestId = 'occurrence-tracking-list-body-view';

const FreightReceivableListBodyView: React.FC = (): JSX.Element => {
  return (
    <Fragment>
      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Financeiro', 'Fretes a Receber']} />
      <Title dataTestId={dataTestId} title="Fretes a Receber" hasTrace />
      <ContainerAccessStyled>
        <AccessRestrictedStyled>Acesso Restrito</AccessRestrictedStyled>
      </ContainerAccessStyled>
    </Fragment>
  );
};

export default FreightReceivableListBodyView;
