import { clientUpdateService } from 'pages/client/services/client.service';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { useManageAttachmentsEdit } from 'src-new/pages/registrations/hooks/use-manage-attachments-edit/use-manage-attachments-edit.hook';

export const useUpdateClientHook = () => {
  const manageAttachmentsEdit = useManageAttachmentsEdit();

  return async (client: IClientDomain) => {
    await manageAttachmentsEdit(client?.id ?? 0, 'client', client.originalAttachments, client.attachments);

    await clientUpdateService(client);
  };
};
