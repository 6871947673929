import React, { useContext, useEffect, useMemo } from 'react';
import { FreightContext } from 'pages/freight/contexts/freight/freight.context';
import { UserContext } from 'state/user-context';
import { usePhotoChatFreight } from 'pages/freight/hooks/use-photo-chat-freight/use-photo-chat-freight.hook';
import FreightPageBodyView from 'pages/freight/freight-update/freight-page-body/freight-page-body-view.component';

const FreightPageBody: React.FC = () => {
  const { freightChat } = useContext(FreightContext);
  const { user } = useContext(UserContext);
  const photoChatFreight = usePhotoChatFreight();

  const isLoadingChat = useMemo(() => freightChat.statusRequest === 'PROCESSING', [freightChat.statusRequest]);

  useEffect(() => {
    if (user && freightChat.statusRequest === 'INITIAL') {
      photoChatFreight(user);
    }
  }, [freightChat.statusRequest, photoChatFreight, user]);

  return <FreightPageBodyView isLoadingChat={isLoadingChat} />;
};

export default FreightPageBody;
