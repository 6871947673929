import { useCallback, useContext } from 'react';

import { Hook } from './use-user-list.types';

import { UsersContext } from 'pages/user/states/users.context';
import { listUsersService } from 'pages/user/services/user-list-service/user-list.service';

const useUsersHook: Hook = () => {
  const { setUsersRequest, setUsersErro, setUsersSuccess } = useContext(UsersContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column, excluded }) => {
      setUsersRequest();

      try {
        const usersResponse = await listUsersService({
          currentPage: currentPage,
          search: searchValue,
          status,
          order,
          column,
          excluded,
        });
        setUsersSuccess(usersResponse);
      } catch (error) {
        setUsersErro('Ocorreu um erro na API, tente novamente');
      }
    },
    [setUsersRequest, setUsersErro, setUsersSuccess],
  );
};

export { useUsersHook };
