import { ILocationTypeEntity } from 'pages/location/services/entities/location.entity';
import { LocationType } from 'domain/location';

export const convertTypeDomainToEntityMapper = (typeDomain: LocationType): ILocationTypeEntity => {
  switch (typeDomain) {
    case 'ARMAZÉM':
      return 'x-dock';
    case 'CD':
      return 'distribution center';
    case 'FÁBRICA':
      return 'factory';
    case 'FAZENDA':
      return 'farm';
    case 'HUB':
      return 'hub';
    case 'LOJA':
      return 'shop';
    case 'TERMINAL PORTUÁRIO':
      return 'port terminal';
    case 'TRANSIT POINT':
      return 'transit point';
    case 'USINA':
      return 'plant';
    default:
      return 'others';
  }
};
