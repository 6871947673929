import React, { useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import * as S from './attach-documents-modal-view.styled';
import { AttachDocumentsModalFields } from 'src-new/pages/registrations/components/attach-documents-section/components/attach-documents-modal/components/attach-documents-modal-fields/attach-documents-modal-fields.component';
import { InputDropFile } from 'src-new/pages/registrations/components/attach-documents-section/components/attach-documents-modal/components/attach-documents-input-drop-file/attach-documento-input-drop.component';

interface IAttachDocumentsModalViewProps {
  handleModalClose: () => void;
  handleSaveButton: () => void;
  position: number;
}

export const AttachDocumentsModalView: React.FC<IAttachDocumentsModalViewProps> = ({
  handleModalClose,
  position,
  handleSaveButton,
}) => {
  const renderSaveButton = useMemo(() => {
    return (
      <S.SaveButtonContainerStyled>
        <S.SaveButtonStyled type={'button'} onClick={handleSaveButton}>
          Salvar
        </S.SaveButtonStyled>
      </S.SaveButtonContainerStyled>
    );
  }, [handleSaveButton]);

  return (
    <ModalBark handleClose={handleModalClose} size="sm" title="Novo Anexo" hiddenOverFlow>
      <S.Wrapper>
        <S.InputDropFile>
          <InputDropFile acceptFiles={['.xlsx', '.xls', '.pdf', '.png']} position={position} />
        </S.InputDropFile>
        <AttachDocumentsModalFields position={position} />
      </S.Wrapper>
      {renderSaveButton}
    </ModalBark>
  );
};
