import { ModalMatchDetails } from '../../types';
import { convertRegisterMatch } from '../../mappers';

import API from 'utils/API/API';
import { MatchRegisterInput } from 'domain-v2/match/register';
import { IdlenessValues } from 'domain/idleness';

export const buyMatchService = async (
  payload: MatchRegisterInput,
  transportRouter: ModalMatchDetails,
  idleness?: IdlenessValues,
) => {
  const convertPayload = convertRegisterMatch(payload, transportRouter, idleness);
  return await API.post('v3/schedules/backhaul/all', convertPayload);
};
