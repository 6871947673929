import { IDashboard, IDeliveryDetails } from 'domain/dashboard';

export const initialValues: IDashboard = {
  scheduleCount: '-',
  idlenessCount: '-',
  transportRouteCount: '-',
  idlenessMatchCount: '-',
  pedidosFinalizados: '-',
  co2: 0,
  receita_despesa: {
    despesa: 0,
    receita: 0,
  },
  anos: [
    {
      country: '-',
      compra: 0,
      venda: 0,
    },
  ],
  pedidosFinalizadosPeriodo: [
    {
      country: '-',
      pedidosFinalizados: 0,
      pedidosNaoFinalizados: 0,
    },
  ],
  backhaulExecutedCount: 0,
  dataToTracking: {
    onTime: 0,
    tmo: {
      hours: 0,
      minutes: 0,
    },
    averageLoading: {
      hours: 0,
      minutes: 0,
    },
    averageUnloading: {
      hours: 0,
      minutes: 0,
    },
    averageDisplacement: {
      hours: 0,
      minutes: 0,
    },
    averageShippingCompanyAccepted: {
      hours: 0,
      minutes: 0,
    },
  },
};

export const deliveryDetailsInitialValues: IDeliveryDetails = {
  noPrazo: '',
  atraso: '',
};
