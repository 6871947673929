import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { StabilizedMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListDomain } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/domains/matches-kanban-list/matches-kanban-list.domain';
import { IStabilizedMatchesKanbanList } from '../../types/stabilized-matches-kanban.types';
import { stabilizedMatchesKanbanListReducer } from '../../reducers/stabilized-matches-kanban-list/stabilized-matches-kanban-list.reducer';

export const useStabilizedMatchesKanbanListContextValues = (): IStabilizedMatchesKanbanList => {
  const [state, dispatch] = useReducer(stabilizedMatchesKanbanListReducer, matchesInitialState);

  const setStabilizedMatchesKanbanListRequest = useCallback(() => {
    dispatch({ type: StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_REQUEST, payload: undefined });
  }, []);

  const setStabilizedMatchesKanbanListSuccess = useCallback((kanban: IMatchesKanbanListDomain) => {
    dispatch({ type: StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_SUCCESS, payload: kanban });
  }, []);

  const setStabilizedMatchesKanbanListError = useCallback(() => {
    dispatch({ type: StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_ERROR, payload: undefined });
  }, []);

  const setStabilizedMatchesKanbanListReset = useCallback(() => {
    dispatch({ type: StabilizedMatchesKanbanListActionType.STABILIZED_MATCHES_KANBAN_RESET, payload: undefined });
  }, []);

  return {
    ...state.stabilizedMatchesKanbanList,
    setStabilizedMatchesKanbanListRequest,
    setStabilizedMatchesKanbanListSuccess,
    setStabilizedMatchesKanbanListError,
    setStabilizedMatchesKanbanListReset,
  };
};
