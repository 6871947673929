import React from 'react';

import { ContainerInfoStyle, DescriptionStyled, HeaderContainerStyled, TitleStyled } from './style';

import { DigitalCertificateIcon } from 'assets/icons/digital-certificate.icon';

export const InstructionUse: React.FC = () => {
  return (
    <HeaderContainerStyled>
      <DigitalCertificateIcon />
      <ContainerInfoStyle>
        <TitleStyled>Instruções de Uso</TitleStyled>

        <DescriptionStyled>
          A utilização do certificado digital cadastrado na <i>LogShare</i>, permite que transportadoras e embarcadores
          emitam os documentos fiscais de transporte automaticamente através da plataforma. As extensões permitidas para
          anexo são PFX e P12.
        </DescriptionStyled>
      </ContainerInfoStyle>
    </HeaderContainerStyled>
  );
};
