import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routesAuthentication } from './routes-authentication/routes-authentication';
import { routesPublic } from './routes-public/routes-public';

export const PublicAppRoute: React.FC = () => {
  return (
    <Routes>
      {routesPublic.map(({ component, path }) => (
        <Route path={path} element={component} key={path} />
      ))}
      {routesAuthentication.map(({ component, path }) => (
        <Route path={path} element={component} key={path} />
      ))}
    </Routes>
  );
};
