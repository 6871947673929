import {
  IScheduleMapReportDomain,
  MapReportType,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-map-report.domain';
import { ITruckRoute } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/truck-route.domain';
import { formatTimestampToDateHourUtil } from 'src-new/utils/format-timestamp-to-date-hour/format-timestamp-to-date-hour.util';

export const convertTruckRouteToMapReportDomain = (
  truckRoute: ITruckRoute,
  scheduleId: string,
  plate: string,
  type: MapReportType,
): IScheduleMapReportDomain => {
  return {
    latitude: truckRoute.latitude.toString(),
    longitude: truckRoute.longitude.toString(),
    routeId: scheduleId,
    type: type,
    date: formatTimestampToDateHourUtil(truckRoute.created_at),
    plate: plate,
    speed: `${truckRoute.speed ? Math.ceil(truckRoute.speed) : 0}` + ' Km/h',
  };
};
