import { FormikErrors } from 'formik';
import { ChangeEvent, FC, useCallback } from 'react';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';
import { WeighingDuplicatedFormMountDriversView } from './weighing-duplicated-form-mount-drivers-view.component';

interface IWeighingDuplicatedFormMountDriversProps {
  values: IWeighingDuplicatedDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDuplicatedDomain>>;
}

export const WeighingDuplicatedFormMountDrivers: FC<IWeighingDuplicatedFormMountDriversProps> = ({ values, setFieldValue }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  return <WeighingDuplicatedFormMountDriversView values={values} handleChange={handleChange} />;
};
