import React, { ReactNode } from 'react';

import CompaniesTableView from './companies-table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface ICompaniesTableProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo: PageInfo | null;
  changePage: (page: number) => void;
  clickRowAction: (transportId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const CompaniesTable: React.FC<ICompaniesTableProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  isLoading,
  arrowOrder,
  clickOrderBy,
  clickSortDirection,
}) => {
  return (
    <CompaniesTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      isLoading={isLoading}
      clickRowAction={clickRowAction}
      clickOrderBy={clickOrderBy}
      clickSortDirection={clickSortDirection}
    />
  );
};

export default CompaniesTable;
