import React from 'react';
import { ITableProps } from './table.types';
import { TableView } from './table-view.component';

export const Table: React.FC<ITableProps> = ({
  header,
  ordering,
  rows,
  gridColumns,
  config,
  height,
  alignLinesCenter,
  columnFontBold,
  infiniteScrollProps,
}) => {
  return (
    <TableView
      height={height}
      rows={rows}
      header={header}
      gridColumns={gridColumns}
      ordering={ordering}
      config={config}
      alignLinesCenter={alignLinesCenter}
      columnFontBold={columnFontBold}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
