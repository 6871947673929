import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    border-bottom: solid 1px #d1d1d1;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    padding-bottom: 10px;
    margin: 30px 0 20px 0;
  `}
`;

export const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TraceVerticalStyled = styled.div`
  width: 3px;
  height: 13px;
  background-color: #12264e;
`;
