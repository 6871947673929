import API from 'utils/API/API';
import { IBidOfferListResponse } from './get-bid-offers-list.types';
import { convertBidOfferResponseToDomain } from 'src-new/pages/home/pages/home/services/get-bid-offers-list/mappers/convert-bid-offer-response-to-domain/convert-bid-offer-response-to-domain.mapper';
import { IBidOfferDomain } from 'src-new/pages/home/pages/home/domains/home-bids/bid-offer.domain';
import { IListPage } from 'src-new/domains/list-page.domain';

export type BidOffersResponse = IListPage<IBidOfferDomain>;

export const getBidOffersListService = async (page: number): Promise<BidOffersResponse> => {
  const { data } = await API.get<IBidOfferListResponse>('v2/schedules/bidding/shipping', {
    params: {
      limit: 30,
      order: 'DESC',
      column: 'ID',
      page,
    },
  });

  return {
    items: data.items.map(convertBidOfferResponseToDomain),
    meta: data.meta,
  };
};
