import React from 'react';

import ResetLogin from 'pages/reset-login/reset-login';
import Login from 'pages/login/login-page';
import { LoginMicrosoftPage } from 'src-new/pages/login/pages/login-microsoft/login-microsoft.page';

interface RoutesProps {
  path: string;
  profiles: string;
  name: string;
  component: React.ReactNode | undefined;
}

const routesAuthentication: RoutesProps[] = [
  {
    path: '/',
    component: <Login />,
    name: 'auth',
    profiles: 'all',
  },
  {
    path: '/login',
    component: <Login />,
    name: 'auth',
    profiles: 'all',
  },
  {
    path: '/reset-password',
    component: <ResetLogin />,
    name: 'auth',
    profiles: 'all',
  },
  {
    path: '/login-microsoft',
    component: <LoginMicrosoftPage />,
    name: 'auth',
    profiles: 'all',
  },      
];

export { routesAuthentication };
