import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 25px;
  border: 1px solid #d9d9d9;
  height: 100%;
  border-radius: 10px;
`;

export const ContainerInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const StatusStyled = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

export const DescriptionStyled = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
