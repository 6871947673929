import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';
import { downloadEmailPDFHook } from 'pages/freight/hooks/dowload-pdf/index.hook';
import { IUser } from 'domain/login';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

interface IProps {
  id: number;
  user: IUser | undefined;
}

const SendEmailModal: React.FC<IProps> = ({ id, user }) => {
  const { errors, setFieldValue, values, touched, submitForm } = useFormikContext<{ emailAddress: string }>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const downloadPDFHook = downloadEmailPDFHook();

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_ordem_coleta_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const handleGeneratePDF = useCallback(async () => {
    await downloadPDFHook(id)
      .then((pdfFile: BlobPart | undefined) => {
        showMessageFormatted({
          message: 'Ordem de Coleta gerada com sucesso.',
          type: 'success',
        });
        DownloadFile(pdfFile || '', 'pdf', fileNamePdf);
      })
      .catch((error: any) =>
        showMessageFormatted({
          message: '',
          error: error.message,
          type: 'error',
        }),
      );
  }, []);

  return (
    <>
      <InputField
        label="E-mail do Destinatário"
        id="emailAddress"
        name="emailAddress"
        type="text"
        onChange={handleChange}
        value={values.emailAddress.toLowerCase()}
        hasError={!!errors?.emailAddress && !!touched?.emailAddress}
        errorMessage={String(errors.emailAddress)}
      />

      <div
        style={{
          marginTop: 30,
          display: 'flex',
          gap: 20,
          justifyContent: 'flex-end',
        }}
      >
        <Button title="Download PDF" size="large" callback={handleGeneratePDF} />

        <Button bgColor="blue" title="Enviar" callback={submitForm} size="large" disabled={!values.emailAddress} />
      </div>
    </>
  );
};

export default SendEmailModal;
