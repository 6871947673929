import styled, { css } from 'styled-components';

export const Wrapper = styled.section``;

export const WrapperTextsInformationHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface IFontStyled {
  size?: number;
  weight?: number;
  color?: string;
}
export const Text = styled.p<IFontStyled>`
  ${({ size = 11, weight = 500, color = '#000' }) => css`
    font-size: ${size}px;
    font-weight: ${weight};
    color: ${color};
    text-transform: uppercase;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const WrapperIdAndImage = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.5rem;
`;

export const Title = styled.div`
  ${() => css`
    color: #000;
    font-family: Montserrat, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const WrapperInformationLocationsHeader = styled.header`
  display: flex;
  gap: 0.5rem;

  > div:last-child {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

export const WrapperTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
