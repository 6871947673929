import { useCallback, useReducer } from 'react';
import { transactionsInitialState } from '../../transactions.constants';
import { ITransactionsList, ITransactionsListFilter } from '../../types/transactions-list.types';
import { TransactionsListActionType } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.action';
import { transactionsListReducer } from 'src-new/pages/financial/pages/transactions/contexts/transactions/reducers/transactions-list/transactions-list.reducer';
import { ITransactionDomain } from 'src-new/pages/financial/domains/transactions.domain';

export const useTransactionsListContextValues = (): ITransactionsList => {
  const [state, dispatch] = useReducer(transactionsListReducer, transactionsInitialState);

  const setTransactionsListRequest = useCallback(() => {
    dispatch({ type: TransactionsListActionType.TRANSACTIONS_LIST_REQUEST, payload: undefined });
  }, []);

  const setTransactionsListSuccess = useCallback((transactions: Array<ITransactionDomain>) => {
    dispatch({ type: TransactionsListActionType.TRANSACTIONS_LIST_SUCCESS, payload: transactions });
  }, []);

  const setTransactionsListError = useCallback(() => {
    dispatch({ type: TransactionsListActionType.TRANSACTIONS_LIST_ERROR, payload: undefined });
  }, []);

  const setFilters = useCallback((filters: ITransactionsListFilter) => {
    dispatch({ type: TransactionsListActionType.TRANSACTIONS_LIST_SET_FILTERS, payload: filters });
  }, []);

  const setTransactionsListReset = useCallback(() => {
    dispatch({ type: TransactionsListActionType.TRANSACTIONS_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.transactionsList,
    setTransactionsListRequest,
    setTransactionsListSuccess,
    setTransactionsListError,
    setTransactionsListReset,
    filterActions: {
      ...state.transactionsList.filterActions,
      setFilters,
    },
  };
};
