import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { ChatButtonView } from './chat-button-view.component';
import { ChatType, IChatObject, IGroupChatProps } from 'src-new/components/chat-button/chat-button.types';
import { useGetMessageQuantityChat } from 'src-new/hooks/chat/use-get-message-quantity-chat/use-get-message-quantity-chat.hook';
import { ChatContext } from 'src-new/contexts/chat/chat.context';

const ENVIRONMENT = import.meta.env.VITE_FIREBASE_TARGET;

interface IChatButtonProps {
  chatProps: ChatType;
}

export const ChatButton: React.FC<IChatButtonProps> = ({ chatProps }) => {
  const [showGroupChat, setShowGroupChat] = useState<boolean>(false);
  const { statusRequest, chatActions } = useContext(ChatButtonContext);
  const { chatMessageQuantity, chatEligibleUsers, chatUnreadMessages, chatUserEmails } = useContext(ChatContext);
  const getMessageQuantityHook = useGetMessageQuantityChat();

  const hasDisabledChat = useMemo(
    () => statusRequest === 'ERROR' || chatEligibleUsers.statusRequest === 'ERROR',
    [chatEligibleUsers.statusRequest, statusRequest],
  );

  const getUnreadChat = useCallback(
    (id: string) =>
      chatUnreadMessages.unreadMessages?.find((unreadChat) => unreadChat.conversation.id === id)?.unreadMessageCount ??
      0,
    [chatUnreadMessages.unreadMessages],
  );

  const getMessageCount = useCallback(
    (id: string): number =>
      chatMessageQuantity.chatMessages.find((conversation) => conversation.id === id)?.messagesCount ?? 0,
    [chatMessageQuantity.chatMessages],
  );

  const handleCloseChat = useCallback(
    (type: 'SINGLE' | 'GROUP') => {
      chatActions.setChatClose();

      if (type === 'GROUP') {
        setShowGroupChat(false);
      }

      chatUserEmails.setChatUserEmailsReset();
    },
    [chatActions, chatUserEmails],
  );

  const handleGroupChatOpen = useCallback(() => setShowGroupChat(true), []);

  const handleGroupChatClose = useCallback(() => handleCloseChat('GROUP'), [handleCloseChat]);

  const handleSingleChatClose = useCallback(() => handleCloseChat('SINGLE'), [handleCloseChat]);

  const groupChatProps = useMemo(
    (): IGroupChatProps => ({ showGroupChat, handleGroupChatOpen, handleGroupChatClose }),

    [handleGroupChatClose, handleGroupChatOpen, showGroupChat],
  );

  const getChatProps = useMemo((): ChatType => {
    if (chatProps.typeChat === 'SINGLE') {
      return {
        typeChat: 'SINGLE',
        chatId: chatProps.chatId ? `${ENVIRONMENT}-${chatProps.chatId}` : undefined,
        name: chatProps.name,
      };
    }

    const chatObjects: Array<IChatObject> = chatProps.chatsObjects.map((chat) => {
      return {
        ...chat,
        chatId: `${ENVIRONMENT}-${chat.chatId}`,
      };
    });

    return { typeChat: 'GROUP', chatsObjects: chatObjects };
  }, [chatProps]);

  const isRequestCompletedUserEmails = useMemo(
    () => chatUserEmails.statusRequest === 'SUCCESS' || chatUserEmails.statusRequest === 'ERROR',
    [chatUserEmails.statusRequest],
  );

  useEffect(() => {
    if (getChatProps.typeChat === 'GROUP') {
      const INTERVAL_API_CALL_MINUTES = chatMessageQuantity.statusRequest === 'INITIAL' ? 1000 : 5000;
      const interval = setInterval(() => {
        getMessageQuantityHook(getChatProps.chatsObjects.map((chat) => chat.chatId));
      }, INTERVAL_API_CALL_MINUTES);

      return () => clearInterval(interval);
    }
  }, [chatMessageQuantity.statusRequest, getChatProps, getMessageQuantityHook]);

  return (
    <ChatButtonView
      groupChatProps={groupChatProps}
      chatProps={getChatProps}
      hasDisabledChat={hasDisabledChat}
      getUnreadChat={getUnreadChat}
      isShowChat={chatActions.isOpen}
      isRequestCompletedUserEmails={isRequestCompletedUserEmails}
      handleOpenChat={chatActions.setChatOpen}
      handleCloseChat={handleSingleChatClose}
      getMessageCount={getMessageCount}
    />
  );
};
