import { useCallback } from 'react';

import { transportDeleteService } from 'pages/transport-company/services/transport-delete/transport-delete.service';

const useDeleteTransportHook = () => {
  return useCallback(async (ids: number[]): Promise<boolean> => {
    return await transportDeleteService(ids);
  }, []);
};

export { useDeleteTransportHook };
