import styled, { css } from 'styled-components';

interface PropsStyle {
  date?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ContainerStatus = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  flex: 1 1 0%;
  position: relative;
`;

export const ContainerIcon = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const ContainerLabel = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 500;
`;

export const LabelStepOneStyled = styled.div`
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  color: #000;

  @media (max-width: 600px) {
    font-size: 10px;
    font-weight: 600;
  }
`;

export const LabelStepTwoStyled = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  color: #000;
  margin-top: 16px;

  @media (max-width: 600px) {
    font-family: Montserrat;
    font-size: 9px;
  }
`;

export const ColorlibStepIconRoot = styled.div<PropsStyle>`
  ${({ date }) => css`
    background-color: '#ccc';
    z-index: 1;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-image: ${date !== '-'
      ? 'linear-gradient(136deg, rgb(31, 43, 79, 1) 0%, rgb(31, 43, 79, 1) 50%, rgb(31, 43, 79, 1) 100%)'
      : 'linear-gradient(136deg, rgb(234, 234, 240, 1) 0%, rgb(234, 234, 240, 1) 50%, rgb(234, 234, 240, 1) 100%)'};
  `}
`;

export const ColorlibConnector = styled.div<PropsStyle>`
  ${({ date }) => css`
    height: 3px;
    border: 0;
    width: 100%;
    margin-top: 22px;
    position: absolute;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
    background-image: ${date !== '-'
      ? 'linear-gradient(136deg, rgb(31, 43, 79, 1) 0%, rgb(31, 43, 79, 1) 50%, rgb(31, 43, 79, 1) 100%)'
      : 'linear-gradient(136deg, rgb(234, 234, 240, 1) 0%, rgb(234, 234, 240, 1) 50%, rgb(234, 234, 240, 1) 100%)'};
  `}
`;

export const GraylibConnector = styled.div`
  border-top-style: solid;
  border-top-width: 1px;
  width: 90%;
  margin-top: 12px;
  position: absolute;
  left: calc(50% + 20px);
  right: calc(-50% + 20px);
  border-color: #fff;
  border-radius: 50px;
`;
