import React from 'react';

import SchedulesContextProvider from '../schedule/context/schedule-register/schedule.context';

import { ScheduleStlListBody } from './register/register-body';

import { AllocationRegisterContextProvider } from 'pages/allocation/contexts/allocation-register/allocation.context';

const ScheduleLtlRegisterPage: React.FC = () => {
  return (
    <AllocationRegisterContextProvider>
      <SchedulesContextProvider>
        <ScheduleStlListBody />
      </SchedulesContextProvider>
    </AllocationRegisterContextProvider>
  );
};

export default ScheduleLtlRegisterPage;
