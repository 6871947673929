import React, { useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import HeaderTable from '../header';

import * as S from './styled';

import { convertPalletStatus } from 'utils-v2/converters/pallet-status';
import { formatDateTime } from 'utils-v2/converters/date';
import { usePalletCustomNavigation } from 'pages-v2/pallet/hooks/copy-pallet';
import { IPalletList, ISelectedPallets } from 'domain-v2/pallet/list';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';
import TooltipComponent from 'components/Tooltip';

interface ContentTable {
  pallets: Array<IPalletList>;
  isLoading: boolean;
  tabActive: string;
  orderSelected: { orderBy: string; sortDirection: string };
  selectedPallets: Array<ISelectedPallets>;
  handleGeneratePDF: (id: number[]) => void;
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
  setActiveTabName: (name: string) => void;
  goToUpdatePallet: (id: string) => void;
  handleCheckboxChange: (checked: boolean, pallet: IPalletList) => void;
}

const ListPalletTable: React.FC<ContentTable> = ({
  pallets,
  isLoading,
  tabActive,
  orderSelected,
  selectedPallets,
  handleGeneratePDF,
  clickOrderTableBy,
  clickSortDirection,
  setActiveTabName,
  goToUpdatePallet,
  handleCheckboxChange,
}) => {
  const { handlePalletCopy } = usePalletCustomNavigation();

  const mountTable = useMemo((): Array<IContentTable> => {
    return pallets.map((pallet) => {
      return [
        {
          value: (
            <Checkbox
              id="pallet"
              name="pallet"
              onChange={(e) => {
                handleCheckboxChange(e.target.checked, pallet);
              }}
              checked={selectedPallets?.some((selected) => selected.id === pallet.id)}
            />
          ),
          flex: 0.4,
        },
        {
          value: pallet.id,
          flex: 0.5,
        },
        {
          value: pallet.scheduleId ?? '-',
          flex: 1,
        },
        {
          value: pallet.equipmentVoucher,
          flex: 1,
        },
        {
          value: pallet.clientName ?? '-',
          flex: 2.2,
        },
        {
          value: pallet.originCity ?? '-',
          flex: 1.5,
        },
        {
          value: pallet.distributionCenterName ?? '-',
          flex: 2.2,
        },
        {
          value: pallet.palletQuantity,
          flex: 0.8,
        },
        {
          value: formatDateTime(pallet.liberationDate ?? '-'),
          flex: 1.5,
        },
        {
          value: pallet.protocol ?? '-',
          flex: 1,
        },
        {
          value: (
            <Tag
              label={convertPalletStatus(pallet.status).label}
              color={convertPalletStatus(pallet.status).color}
              display="flex"
              size="md"
              variant="solid"
            />
          ),
          flex: 1.5,
        },
        {
          value: (
            <TooltipComponent
              title="Duplicar Palete"
              location="left"
              icon={<ContentCopyRoundedIcon htmlColor="gray" onClick={() => handlePalletCopy(pallet.id)} />}
            />
          ),
          flex: 0.4,
        },
        {
          value: (
            <S.Info
              onClick={() => {
                handleGeneratePDF([pallet.id]);
              }}
            >
              <PictureAsPdfIcon htmlColor="gray" />
            </S.Info>
          ),
          flex: 0.4,
        },
      ];
    });
  }, [pallets, selectedPallets, handleGeneratePDF, handlePalletCopy, handleCheckboxChange]);

  const renderPalletTable = useMemo(() => {
    return (
      <HeaderTable
        contentTable={mountTable}
        isLoading={isLoading}
        orderSelected={orderSelected}
        clickRowAction={goToUpdatePallet}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [mountTable, isLoading, orderSelected, clickOrderTableBy, clickSortDirection, goToUpdatePallet]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Todos',
        content: renderPalletTable,
      },
      {
        name: 'Em Aberto',
        content: renderPalletTable,
      },
      {
        name: 'Liberado',
        content: renderPalletTable,
      },
    ];
  }, [renderPalletTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} tabActive={tabActive} />;
};

export default ListPalletTable;
