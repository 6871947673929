import styled, { css } from 'styled-components';

export const WrapperSection = styled.section`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};

    border-radius: 5px;
    border: 1px solid ${theme.colors.forBorder};

    padding: 1rem;
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.3rem;

    border-bottom: 1px solid ${theme.colors.forBorder};
    color: ${theme.colorsV2.blue};

    padding-bottom: 15px;
    margin-bottom: 15px;
  `}
`;

export const WrapperHeaderItensRight = styled.div``;

export const WrapperHeaderButtons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

type WrapperHeaderTagProps = { padding: boolean };
export const WrapperHeaderTag = styled.div<WrapperHeaderTagProps>`
  ${({ padding }) => css`
    display: flex;
    justify-content: flex-end;
    padding-top: ${padding && '1rem'};
  `}
`;

type WrapperValuesProps = { columns: string };
export const WrapperValues = styled.div<WrapperValuesProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 15px;
  `}
`;

export const WrapperInfo = styled.div`
  padding-bottom: 0.62rem;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const InfoTitle = styled.p`
  font-size: 12px;
  color: #000;
`;

export const Info = styled.p`
  font-size: 12px;
  color: #000;
  font-weight: 700;
  padding: 5px 0;
  text-transform: uppercase;
`;

export const InfoLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `}
`;
