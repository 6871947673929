/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

export const CustomWrapper = styled.div`
  ${(p: any) => p.styles}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.width.fullView};
    height: ${theme.height.fullView};
    top: 0;
    left: 0;
    position: absolute;
    padding: 20px;
    background-color: ${theme.modals.background};
    z-index: 2;
    color: ${theme.colorsV2.black};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${theme.modals.height.small};
    width: ${theme.modals.width.small};
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    padding: 30px;
    overflow-y: auto;
    gap: 10px;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text2};
    font-weight: 700;
    padding-bottom: 10px;
  `}
`;

export const Main = styled.div``;

export const ButtonClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
