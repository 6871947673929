import { useCallback } from 'react';
import { IChatObject } from 'src-new/components/chat-button/chat-button.types';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { IUser } from 'domain/login';
import { IEligibleUserDomain } from 'src-new/domains/eligible-user-chat.domain';

const SCHEDULE_FLOW = 'schedule';

export const useGetGroupChatScheduleConversationsChat = () => {
  const buildChatSpotAndTracking = useCallback(
    (
      scheduleId: string,
      isLogShareUser: boolean,
      isShippingCompanyUser: boolean,
      isShipperUser: boolean,
      shippingCompanyIdUser?: number,
      shippingCompanyId?: number,
    ): Array<IChatObject> => {
      const chatShipperAndShippingCompany: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-shipper-${shippingCompanyId}`,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };

      const chatShippingCompanyLogshare: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-logshare-${shippingCompanyId}`,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };

      const chatShipperLogShare: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipper-logshare`,
        name: isLogShareUser ? 'Embarcador' : 'LogShare',
        colorButton: isLogShareUser ? '#12264EFF' : '#777777FF',
      };

      if (isLogShareUser) {
        if (shippingCompanyId) {
          return [chatShipperLogShare, chatShippingCompanyLogshare];
        }
        return [chatShipperLogShare];
      }

      if (isShippingCompanyUser && shippingCompanyId && shippingCompanyId === shippingCompanyIdUser) {
        return [chatShippingCompanyLogshare, chatShipperAndShippingCompany];
      }

      if (isShipperUser) {
        if (shippingCompanyId) {
          return [chatShipperLogShare, chatShipperAndShippingCompany];
        }
        return [chatShipperLogShare];
      }

      return [];
    },
    [],
  );

  const chatBackHaulInternal = useCallback(
    (
      scheduleId: string,
      scheduleCompanyId: number,
      isLogShareUser: boolean,
      isShippingCompanyUser: boolean,
      isShipperUser: boolean,
      shippingCompanyIdUser?: number,
      shippingCompanyId?: number,
    ): Array<IChatObject> => {
      const chatShipper: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipper-${scheduleCompanyId}`,
        name: 'Embarcador',
        colorButton: '#12264EFF',
      };

      const chatShipperAndShippingCompany: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-shipper-${shippingCompanyId}`,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };

      const chatShippingCompanyLogshare: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-logshare-${shippingCompanyId}`,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };

      const chatShipperLogShare: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipper-logshare`,
        name: isLogShareUser ? 'Embarcador' : 'LogShare',
        colorButton: isLogShareUser ? '#12264EFF' : '#777777FF',
      };

      if (isLogShareUser) {
        if (shippingCompanyId) {
          return [chatShipperLogShare, chatShippingCompanyLogshare];
        }
        return [chatShipperLogShare];
      }

      if (isShippingCompanyUser && shippingCompanyId && shippingCompanyId === shippingCompanyIdUser) {
        return [chatShippingCompanyLogshare, chatShipperAndShippingCompany];
      }

      if (isShipperUser) {
        if (shippingCompanyId) {
          return [chatShipper, chatShippingCompanyLogshare, chatShipperLogShare, chatShipperAndShippingCompany];
        }
        return [chatShipper, chatShipperLogShare];
      }

      return [];
    },
    [],
  );

  const chatBackHaulExternal = useCallback(
    (
      scheduleId: string,
      isCompanySellerUser: boolean,
      isCompanyBuyerUser: boolean,
      isLogShareUser: boolean,
      isShippingCompanyUser: boolean,
      shippingCompanyIdUser?: number,
      shippingCompanyId?: number,
    ): Array<IChatObject> => {
      let labelChatCompanyBuyerCompanySeller: string;

      if (isLogShareUser) {
        labelChatCompanyBuyerCompanySeller = 'Embarcadores';
      } else if (isCompanySellerUser) {
        labelChatCompanyBuyerCompanySeller = 'Embarcador';
      } else {
        labelChatCompanyBuyerCompanySeller = 'Embarcador';
      }

      const chatCompanyBuyerCompanySeller: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-company-buyer-company-seller`,
        name: labelChatCompanyBuyerCompanySeller,
        colorButton: '#12264EFF',
      };

      const chatShippingCompanyLogshare: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-logshare-${shippingCompanyId}`,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };

      const chatLogShareCompanySeller: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-logshare-company-seller`,
        name: isLogShareUser ? 'Vendedor' : 'LogShare',
        colorButton: isLogShareUser ? '#d58d37' : '#777777FF',
      };

      const chatLogShareCompanyBuyer: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-logshare-company-buyer`,
        name: isLogShareUser ? 'Comprador' : 'LogShare',
        colorButton: isLogShareUser ? '#be382e' : '#777777FF',
      };

      const chatShippingCompanyCompanySeller: IChatObject = {
        chatId: `${SCHEDULE_FLOW}-${scheduleId}-shipping-company-company-seller-${shippingCompanyId}`,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };

      if (isLogShareUser) {
        if (shippingCompanyId) {
          return [
            chatCompanyBuyerCompanySeller,
            chatShippingCompanyLogshare,
            chatLogShareCompanySeller,
            chatLogShareCompanyBuyer,
          ];
        }
        return [chatCompanyBuyerCompanySeller, chatLogShareCompanySeller, chatLogShareCompanyBuyer];
      }

      if (isShippingCompanyUser && shippingCompanyId && shippingCompanyId === shippingCompanyIdUser) {
        return [chatShippingCompanyCompanySeller, chatShippingCompanyLogshare];
      }

      if (isCompanySellerUser) {
        if (shippingCompanyId) {
          return [chatCompanyBuyerCompanySeller, chatShippingCompanyCompanySeller, chatLogShareCompanySeller];
        }
        return [chatCompanyBuyerCompanySeller, chatLogShareCompanySeller];
      }

      if (isCompanyBuyerUser) {
        return [chatCompanyBuyerCompanySeller, chatLogShareCompanyBuyer];
      }

      return [];
    },
    [],
  );

  return useCallback(
    (schedule: IScheduleDetailsDomain, user?: IUser | IEligibleUserDomain): Array<IChatObject> => {
      const scheduleId = schedule.id.toString();
      const isCompanySellerUser = user?.companyId === schedule.company.id;
      const isCompanyBuyerUser = user?.companyId === schedule.companyBuyer?.id;
      const isLogShareUser = user?.profile === 'logshare';
      const isShippingCompanyUser = user?.profile === 'shipping-company';
      const isShipperUser = user?.profile === 'shipper';

      switch (schedule.operation) {
        case 'TRACKING':
          return buildChatSpotAndTracking(
            scheduleId,
            isLogShareUser,
            isShippingCompanyUser,
            isShipperUser,
            user?.shippingCompanyId,
            schedule.shippingId,
          );
        case 'SPOT':
          return buildChatSpotAndTracking(
            scheduleId,
            isLogShareUser,
            isShippingCompanyUser,
            isShipperUser,
            user?.shippingCompanyId,
            schedule.shippingId,
          );
        case 'BACKHAUL EXTERNO SPOT':
          return buildChatSpotAndTracking(
            scheduleId,
            isLogShareUser,
            isShippingCompanyUser,
            isShipperUser,
            user?.shippingCompanyId,
            schedule.shippingId,
          );
        case 'BACKHAUL INTERNO':
          return chatBackHaulInternal(
            scheduleId,
            schedule.company.id,
            isLogShareUser,
            isShippingCompanyUser,
            isShipperUser,
            user?.shippingCompanyId,
            schedule.shippingId,
          );
        default:
          return chatBackHaulExternal(
            scheduleId,
            isCompanySellerUser,
            isCompanyBuyerUser,
            isLogShareUser,
            isShippingCompanyUser,
            user?.shippingCompanyId,
            schedule.shippingId,
          );
      }
    },
    [buildChatSpotAndTracking, chatBackHaulExternal, chatBackHaulInternal],
  );
};
