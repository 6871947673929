import React, { ReactNode } from 'react';

import { ChildrenStyled, FooterStyled, HeaderStyled, InfoStyled, WrapperStyled } from './delivery-tracking-body.styled';

import LogoBrancoSmall from 'assets/logos/logo-branco-small';

interface IProps {
  children: ReactNode;
  description: string;
  id?: string;
}

export const DeliveryTrackingBodyView: React.FC<IProps> = ({ children, description, id }) => {
  return (
    <WrapperStyled>
      <HeaderStyled>
        <LogoBrancoSmall />
        <div>
          <InfoStyled fontSize={13}>RASTREAMENTO</InfoStyled>
          <InfoStyled fontSize={13}>
            {description}
            <strong>{id}</strong>
          </InfoStyled>
        </div>
      </HeaderStyled>

      <ChildrenStyled>{children}</ChildrenStyled>

      <FooterStyled />
    </WrapperStyled>
  );
};
