import React, { useContext, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import { Tooltip } from '@mui/material';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';

import HeaderTable from '../header';
import { ISelectedSchedules } from '../../types';

import * as S from './styled';

import { convertStatusCodeColor } from 'utils/status-code';
import { convertOperationType } from 'utils/operation-type';
import { UserContext } from 'state/user-context';
import { ScheduleList } from 'services/schedule/types';
import { PageInfo } from 'domain/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';
import TooltipComponent from 'components/Tooltip';

interface ContentTable {
  schedules: Array<ScheduleList>;
  pageInfo?: PageInfo;
  isAdmin: boolean;
  setSelectedSchedule: React.Dispatch<React.SetStateAction<Array<ISelectedSchedules>>>;
  selectedSchedule: Array<ISelectedSchedules>;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  orderSelected: { orderBy: string; sortDirection: string };
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
  setActiveTabName: (name: string) => void;
  goToAllocationSchedule: (id: string) => void;
  tabActive: string;
}

const ListScheduleTable: React.FC<ContentTable> = ({
  schedules,
  pageInfo,
  isAdmin,
  setSelectedSchedule,
  selectedSchedule,
  isLoading,
  handlePaginate,
  orderSelected,
  clickOrderTableBy,
  clickSortDirection,
  setActiveTabName,
  tabActive,
  goToAllocationSchedule,
}) => {
  const { user } = useContext(UserContext);

  const mountTable = useMemo((): Array<IContentTable> => {
    return schedules.map((schedule) => {
      const statusCode = () => {
        if (!isAdmin && schedule.statusCode === 'Em Bidding') return 'Aguardando Alocação';

        if (schedule.statusCode === 'Aguardando Alocação' && isAdmin) return 'Aguardando Transportadora';

        return schedule.statusCode;
      };

      const handleCheckboxChange = (checked: boolean, allocationId: number, statusCode: string) => {
        if (checked) {
          const newSelectedSchedules = [...selectedSchedule, { id: allocationId, status: statusCode }];
          setSelectedSchedule(newSelectedSchedules);
        } else {
          const updatedSelectedSchedules = selectedSchedule.filter((schedule) => schedule.id !== allocationId);
          setSelectedSchedule(updatedSelectedSchedules);
        }
      };

      const elBackground = convertStatusCodeColor(schedule?.statusCode.toLowerCase());

      return [
        {
          value: (
            <Checkbox
              id={'check-allocation'}
              name={'check-allocation'}
              onChange={(e) => {
                const status = statusCode();
                handleCheckboxChange(e.target.checked, schedule.id, status);
              }}
              checked={selectedSchedule.some((selected) => selected.id === schedule.id)}
            />
          ),
          flex: 0.1,
        },
        {
          value: schedule.id,
          flex: isAdmin ? 0.2 : 0.15,
        },

        isAdmin
          ? {
              value: schedule.companyName,
              flex: 0.5,
            }
          : {
              value: '',
              flex: 0,
            },

        {
          value: (
            <Tag
              label={convertOperationType(schedule.operationType).label}
              color={convertOperationType(schedule.operationType).color}
              variant="ghost"
              display="flex"
              size="md"
            />
          ),
          flex: 0.3,
        },

        {
          value: schedule.levyDate,
          subtitle: schedule.levyTime,
          flex: isAdmin ? 0.5 : 0.45,
        },
        {
          value: schedule.deliveryDate,
          subtitle: schedule.deliveryTime,
          flex: isAdmin ? 0.5 : 0.45,
        },
        {
          value: schedule.origin.uf + ' - ' + schedule.origin.name,
          subtitle: schedule.origin.city,
          flex: 0.8,
        },
        {
          value: schedule.destiny.uf + ' - ' + schedule.destiny.name,
          subtitle: schedule.destiny.city,
          flex: isAdmin ? 0.8 : 0.85,
        },
        {
          value: schedule.vehicleType,
          subtitle: schedule.vehicleCategory,
          flex: isAdmin ? 0.4 : 0.45,
        },

        user?.profile !== 'shipping-company'
          ? {
              value: schedule.operatedBy,
              subtitle: schedule.operation,
              flex: 0.5,
            }
          : {
              value: '',
              flex: 0,
            },

        {
          value: (
            <Tooltip title={<span style={{ fontSize: '11px' }}>{statusCode()}</span>} placement="left">
              <S.Info>
                <Tag label={statusCode()} color={elBackground} variant="solid" display="flex" size="md" />
              </S.Info>
            </Tooltip>
          ),
          flex: isAdmin ? 0.5 : 0.8,
        },
        {
          value: (
            <TooltipComponent
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                  }}
                >
                  <strong>Dados Adicionais</strong>

                  {!schedule.cargoInformation && 'Sem Informações'}

                  {schedule.cargoInformation?.orderNumber && <p>Pedidos: {schedule.cargoInformation?.orderNumber}</p>}
                  {schedule.cargoInformation?.schedulingPassword && (
                    <p>Senha: {schedule.cargoInformation?.schedulingPassword}</p>
                  )}
                  {schedule.cargoInformation?.internalNumber && (
                    <p>N° Interno: {schedule.cargoInformation?.internalNumber}</p>
                  )}
                  {schedule.cargoInformation?.ocInternalNumber && (
                    <p>N° OC: {schedule.cargoInformation?.ocInternalNumber}</p>
                  )}
                  {schedule.cargoInformation?.boardingNumber && (
                    <p>N° Embarque: {schedule.cargoInformation?.boardingNumber}</p>
                  )}
                </div>
              }
              icon={
                !schedule.cargoInformation ? (
                  <InfoSharpIcon color="disabled" sx={{ width: 18, height: 18 }} />
                ) : (
                  <InfoSharpIcon sx={{ width: 18, height: 18 }} />
                )
              }
              location={'left'}
            />
          ),
          flex: 0.1,
        },
        {
          value: <ReportSharpIcon color="disabled" sx={{ width: 19, height: 19 }} />,
          flex: 0.1,
        },
      ];
    });
  }, [schedules, selectedSchedule]);

  const renderAllocationTable = useMemo(() => {
    return (
      <HeaderTable
        orderSelected={orderSelected}
        contentTable={mountTable}
        pageInfo={pageInfo}
        changePage={handlePaginate}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        isLoading={isLoading}
        clickRowAction={goToAllocationSchedule}
      />
    );
  }, [mountTable, pageInfo, isLoading]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Programadas',
        content: renderAllocationTable,
      },
      {
        name: 'Canceladas',
        content: renderAllocationTable,
      },
    ];
  }, [renderAllocationTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} tabActive={tabActive} />;
};

export default ListScheduleTable;
