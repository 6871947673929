import React, { FC, useCallback } from 'react';

import { FileListUploaded } from 'pages/freight/types';
import { useHandleDeleteCteUploadedListHook } from 'pages/freight/hooks';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface Props {
  cteData: FileListUploaded;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export const ModalDeleteUploadedCte: FC<Props> = ({ cteData, isOpen, onClose, onSuccess }) => {
  const handleDeleteCte = useHandleDeleteCteUploadedListHook();

  const handleDeleteCTe = useCallback(async () => {
    try {
      await handleDeleteCte(cteData.id);
      onSuccess();
    } catch (error) {
      showToast({
        message: 'Erro ao deletar CTe',
        type: 'error',
      });
    }
  }, [cteData, handleDeleteCte, onSuccess]);

  if (!isOpen) return null;

  return (
    <ModalConfirmation
      secondTitle={
        <>
          <p>Tem certeza que deseja excluir o CT-e?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </>
      }
      title="Exclusão de CT-e"
      size="small"
      type="delete"
      handleCancel={onClose}
      onConfirmation={handleDeleteCTe}
      description={undefined}
    />
  );
};
