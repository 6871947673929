import React from 'react';

import { convertTypeEntityToDomainMapper } from '../mapper/convert-type';

import * as S from './company-resume.styles';

interface CompanyResumeProps {
  company: any;
  onClose: () => void;
}

const CompanyResume: React.FC<CompanyResumeProps> = ({ company, onClose }) => {
  return (
    <S.WrapperResume>
      <div>
        <S.CloseModalStyled onClick={onClose}>X</S.CloseModalStyled>
      </div>
      <S.ContainerModalStyled>
        <S.TextModalStyled fontWeight="bold">{company.name || company.fantasyName}</S.TextModalStyled>

        {company.name ? (
          <S.TextModalStyled>{convertTypeEntityToDomainMapper(company.type).toUpperCase()}</S.TextModalStyled>
        ) : (
          ''
        )}

        <S.TextModalStyled>CNPJ: {company.cnpj}</S.TextModalStyled>

        <S.TextModalStyled>
          {company.street} - {company.neighborhood}
        </S.TextModalStyled>
        <S.TextModalStyled>
          {company.city} - {company.uf}
        </S.TextModalStyled>
      </S.ContainerModalStyled>
    </S.WrapperResume>
  );
};

export default CompanyResume;
