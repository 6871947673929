export const gradeSystemLabel = {
  sss: 'Alto',
  ss: 'Alto',
  s: 'Alto',
  a: 'Moderado',
  b: 'Moderado',
  c: 'Moderado',
  d: 'Baixo',
  e: 'Baixo',
  f: 'Baixo',
} as const;

export type GradeSystemLabel = keyof typeof gradeSystemLabel;

export const getGradeSystemLabel = (rules: GradeSystemColor[]) => {
  const alto = rules.filter((rule) => ['sss', 'ss', 's'].includes(rule)).length;
  const baixo = rules.filter((rule) => ['d', 'e', 'f'].includes(rule)).length;

  if (baixo >= 1) return 'Baixo';
  if (alto === 3) return 'Perfeito';
  if (alto === 2) return 'Alto';

  return 'Moderado';
};

export const gradeSystemColor = {
  sss: '#5AC742',
  ss: '#5AC742',
  s: '#5AC742',
  a: '#FFC000',
  b: '#FFC000',
  c: '#FFC000',
  d: '#5D5D5D',
  e: '#5D5D5D',
  f: '#5D5D5D',
} as const;

export type GradeSystemColor = keyof typeof gradeSystemColor;
