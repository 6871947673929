import React from 'react';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalHeaderCloseButtonStyled,
  ModalHeaderDescriptionStyled,
  ModalHeaderStyled,
  ModalHeaderTitleStyled,
  ModalHeaderWrapperStyled,
  WrapperModalContainerStyled,
} from './our-privacy-policies-modal-view.styled';

interface IProps {
  show: boolean;
  useCallBackCloseButton?: () => void;
  children?: React.ReactNode;
  title: string;
  closeTitle?: string;
  showDescriptionHeader?: boolean;
  buttonIconCloseModal?: React.ReactNode;
}

const OurPrivacyPoliciesModalView: React.FC<IProps> = ({
  children,
  title,
  closeTitle,
  showDescriptionHeader,
  buttonIconCloseModal,
  show,
  useCallBackCloseButton,
}) => {
  return (
    <WrapperModalContainerStyled show={show}>
      <ModalContainerStyled>
        <ModalHeaderStyled>
          <ModalHeaderWrapperStyled>
            {showDescriptionHeader || <ModalHeaderDescriptionStyled>Logshare</ModalHeaderDescriptionStyled>}
            <ModalHeaderTitleStyled>{title}</ModalHeaderTitleStyled>
          </ModalHeaderWrapperStyled>
          <ModalHeaderCloseButtonStyled onClick={useCallBackCloseButton}>
            {React.isValidElement(buttonIconCloseModal) ? (
              buttonIconCloseModal
            ) : (
              <ModalHeaderDescriptionStyled>{closeTitle || 'Fechar'}</ModalHeaderDescriptionStyled>
            )}
          </ModalHeaderCloseButtonStyled>
        </ModalHeaderStyled>
        <ModalBodyStyled>{children}</ModalBodyStyled>
      </ModalContainerStyled>
    </WrapperModalContainerStyled>
  );
};

export default OurPrivacyPoliciesModalView;
