import React, { useMemo } from 'react';
import { Button } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';

import * as S from './styled';

import { ChecklistMobileAnswer } from 'domain-v2/checklist';
import { checklistStatusOptions } from 'domain/global-inputs';
import { ChecklistCheckbox } from 'components-v2/common/modals/checklist/components/checkbox';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';

interface MobileChecklistFormProps {
  hasError: boolean;
}

export const MobileChecklistForm: React.FC<MobileChecklistFormProps> = ({ hasError }) => {
  const { values, setFieldValue, handleChange, handleSubmit } = useFormikContext<ChecklistMobileAnswer>();

  const renderChecklistValues = useMemo(() => {
    if (values.answers.every((question) => question.answerType === 'checkbox')) {
      return <ChecklistCheckbox hasError={hasError} />;
    }
  }, [values, hasError]);

  return (
    <>
      {renderChecklistValues}

      <S.WrapperValues columns="1fr">
        <InputField
          id="name"
          name="name"
          label="Nome do Checklist *"
          type="text"
          value={values.name}
          onChange={handleChange}
          hasError={hasError && !values.name}
          errorMessage="Campo Obrigatório"
        />
        <Select
          id="status"
          name="status"
          label="Status *"
          value={values.status}
          setFieldValue={setFieldValue}
          options={checklistStatusOptions}
          hasError={hasError && !values.status}
          errorMessage="Campo Obrigatório"
        />
      </S.WrapperValues>

      <S.WrapperButton>
        <Button label="Salvar" color="blue" onPress={handleSubmit} size="md" variant="solid" />
      </S.WrapperButton>
    </>
  );
};
