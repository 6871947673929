import React, { memo, useMemo } from 'react';

import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';

interface HeaderTableProps {
  contentTable: Array<IContentTable>;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
  clickRowAction: (transportId: string) => void;
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  isLoading,
  orderSelected,
  clickRowAction,
  clickOrderTableBy,
  clickSortDirection,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.4,
      },
      {
        value: 'ID',
        flex: 0.5,
      },

      {
        value: 'ID da Viagem',
        flex: 1,
      },
      {
        value: 'Vale Equipamento',
        flex: 1,
      },

      {
        value: 'Cliente',
        flex: 2.2,
      },
      {
        value: 'Cidade de Origem',
        flex: 1.5,
      },
      {
        value: 'CD',
        flex: 2.2,
      },
      {
        value: 'Paletes',
        flex: 0.8,
      },
      {
        value: 'Data de Liberação',
        flex: 1.5,
      },
      {
        value: 'Protocolo',
        flex: 1,
      },
      {
        value: 'Status',
        flex: 1.5,
      },
      {
        value: '',
        flex: 0.4,
      },
      {
        value: '',
        flex: 0.4,
      },
    ];
  }, []);

  return (
    <Table
      header={headerTable}
      content={contentTable}
      isLoading={isLoading}
      orderSelected={orderSelected}
      clickRowAction={clickRowAction}
      clickOrderBy={clickOrderTableBy}
      clickSortDirection={clickSortDirection}
      zIndex
    />
  );
};

export default memo(HeaderTable);
