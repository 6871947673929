import * as React from 'react';
import CheckIcon from '@mui/icons-material/Check';

import {
  ColorlibConnector,
  ColorlibStepIconRoot,
  Container,
  ContainerIcon,
  ContainerLabel,
  ContainerStatus,
  ContainerStyled,
  GraylibConnector,
  LabelStepOneStyled,
  LabelStepTwoStyled,
} from './progress-bar.styled';

interface ProgressProps {
  statusCode: string;
  createAt: string;
  startDate: string;
  checkoutDate: string;
  finishDate: string;
}

export const CustomizedSteppers: React.FC<ProgressProps> = ({ createAt, startDate, checkoutDate, finishDate }) => {
  const steps = ['Pedido Agendado', 'Em Rota para Carregamento', 'Em Rota para Entrega', 'Pedido Entregue'];

  const date = [createAt, startDate, checkoutDate, finishDate];

  return (
    <Container>
      <ContainerStyled>
        {steps.map((label, index) => (
          <ContainerStatus key={index}>
            <ContainerIcon>
              <ColorlibStepIconRoot date={date[index]}>
                <CheckIcon />
              </ColorlibStepIconRoot>
              {index !== 0 && <ColorlibConnector date={date[index]} />}
            </ContainerIcon>
            <ContainerLabel>
              <LabelStepOneStyled>{label}</LabelStepOneStyled>
            </ContainerLabel>
          </ContainerStatus>
        ))}
      </ContainerStyled>
      <ContainerStyled>
        {date.map((label, index) => (
          <ContainerStatus key={index}>
            <ContainerIcon>
              {index !== 3 && <GraylibConnector />}
              <LabelStepTwoStyled>{label}</LabelStepTwoStyled>
            </ContainerIcon>
          </ContainerStatus>
        ))}
      </ContainerStyled>
    </Container>
  );
};
