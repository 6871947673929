import React from 'react';

import FreightShippingSectionView from './freight-shipping-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  values?: ScheduleDomain;
}
const FreightShippingSection: React.FC<IProps> = ({ values }) => {
  return <FreightShippingSectionView values={values} />;
};

export default React.memo(FreightShippingSection);
