import React, { useEffect, useState } from 'react';

import { TabsView } from './tabs-view';

export interface ITabContent {
  name: string;
  content: JSX.Element;
}

interface ITabsProps {
  tabsContent: Array<ITabContent>;
  setActiveTabName: (name: string) => void;
  icon?: JSX.Element;
  tabActive?: string;
}

const Tabs: React.FC<ITabsProps> = ({ tabsContent, setActiveTabName, icon, tabActive }: ITabsProps) => {
  const [activeTab, setActiveTab] = useState(tabsContent[0]);

  useEffect(() => {
    if (tabActive) {
      const index = tabsContent.findIndex((item) => item.name === tabActive);
      setActiveTab(tabsContent[index]);
    } else {
      const indexTabActive: number = tabsContent.findIndex((tab) => tab.name === activeTab.name);
      setActiveTab(tabsContent[indexTabActive]);
    }
  }, [activeTab.name, tabActive, tabsContent]);

  return (
    <TabsView
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      icon={icon}
      tabsContent={tabsContent}
      setActiveTabName={setActiveTabName}
    />
  );
};

export { Tabs };
