import React from 'react';

import { ContainerStyled } from './kanban-header.styled';

import { KanbanHeaderProps } from 'pages/task-vision/types/types';

const KanbanView: React.FC<KanbanHeaderProps> = ({ title, bgColor, totalItems }) => (
  <ContainerStyled bgColor={bgColor}>
    <span>{title}</span>
    <span>{totalItems}</span>
  </ContainerStyled>
);

export default KanbanView;
