import API from 'utils/API/API';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';

export const invoicePdfService = async (connectedInvoices: ConnectedInvoices[], invoiceId: number) => {
  const transactions = connectedInvoices.map((value) => {
    return { schedule: value.schedule, calcFinance: value.calcFinance };
  });
  const { data } = await API.post(
    `/v2/invoicing/${invoiceId}/invoice-pdf`,
    { transactions },
    {
      responseType: 'blob',
    },
  );

  return data;
};
