/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button } from 'logshare-ui-kit';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { IBackhaulFilter } from '../../types/filter/filter-backhaul';

import * as S from './filter.style';

import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';
import { SelectItem } from 'pages/choose-company/service/entities/logshare-access';
import { uf } from 'domain/states';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';
import TooltipComponent from 'components/Tooltip';
import { ClearIcon } from 'assets/icons/clear.icon';

type FilterProps = {
  profile: string;
  backhaulFilter: IBackhaulFilter | undefined;
  handleOriginCityChange: (values: any, name: string) => void;
  setBackhaulFilter: React.Dispatch<React.SetStateAction<IBackhaulFilter>>;
  handleFilter: () => void;
  handleCleanFilter: () => void;
};

export const FilterForm: React.FC<FilterProps> = ({
  profile,
  backhaulFilter,
  handleOriginCityChange,
  setBackhaulFilter,
  handleFilter,
  handleCleanFilter,
}) => {
  const apiCallCompany = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await listCompanyByName(searchValue);
    else return [];
  };

  return (
    <S.FilterBackhaulContainer>
      <S.LineSection
        columns={
          profile === 'logshare'
            ? '0.4fr 0.5fr 0.8fr 0.5fr 1fr 1fr 1fr 1fr 0fr 0fr'
            : '0.4fr 0.55fr 1fr 0.55fr 1fr 1fr 1fr 0.1fr 0.1fr'
        }
      >
        <S.ContainerRow>
          <InputField
            placeholder="ID"
            id="id"
            name="id"
            type="number"
            onChange={(value: any) => handleOriginCityChange(value, 'id')}
            value={backhaulFilter?.id}
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <Select
            placeholder="Origem"
            id="originUf"
            name="originUf"
            value={backhaulFilter?.originUf}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                originUf: value,
              }))
            }
            options={uf}
            isClearable
          />
        </S.ContainerRow>
        <S.ContainerRow>
          <InputField
            placeholder="Cidade"
            id="originCity"
            name="originCity"
            type="text"
            onChange={(value: any) => handleOriginCityChange(value, 'originCity')}
            value={backhaulFilter?.originCity}
            reactNode={<LocationOnIcon color="disabled" sx={{ width: 17, height: 17 }} />}
            reactNodePosition="right"
          />
        </S.ContainerRow>

        <S.ContainerRow>
          <Select
            placeholder="Destino"
            id="destinationUf"
            name="destinationUf"
            value={backhaulFilter?.destinationUf}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                destinationUf: value,
              }))
            }
            options={uf}
            isClearable
          />
        </S.ContainerRow>

        <S.ContainerRow>
          <InputField
            placeholder="Cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            onChange={(value: any) => handleOriginCityChange(value, 'destinationCity')}
            value={backhaulFilter?.destinationCity}
            reactNode={<LocationOnIcon color="disabled" sx={{ width: 17, height: 17 }} />}
            reactNodePosition="right"
          />
        </S.ContainerRow>

        <S.ContainerRow>
          <Select
            placeholder="Veículo"
            id="vehicleType"
            name="vehicleType"
            value={backhaulFilter?.vehicleType}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                vehicleType: value,
              }))
            }
            options={vehicleTypeOptions}
            isClearable
          />
        </S.ContainerRow>

        <S.ContainerRow>
          <Select
            placeholder="Categoria"
            id="vehicleCategory"
            name="vehicleCategory"
            value={backhaulFilter?.vehicleCategory}
            setFieldValue={(field, value) =>
              setBackhaulFilter((prevState) => ({
                ...prevState,
                vehicleCategory: value,
              }))
            }
            options={vehicleCategoryOptions}
            isClearable
          />
        </S.ContainerRow>

        {profile === 'logshare' && (
          <S.ContainerRow>
            <AsyncSelectField
              isClearable
              placeholder="Empresa"
              name="company"
              id="company"
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (value: any) =>
                setBackhaulFilter((prevState) => ({
                  ...prevState,
                  company: value?.value ?? '',
                }))
              }
              apiCallFunction={apiCallCompany || (async () => [])}
              loadOptions={apiCallCompany || (async () => [])}
            />
          </S.ContainerRow>
        )}

        <TooltipComponent
          title="Buscar"
          location="bottom"
          icon={
            <Button
              label=""
              color="gray-light-25"
              onPress={handleFilter}
              LeftIcon={<SearchTwoToneIcon sx={{ width: '21px', height: '21px', color: '#333333' }} />}
              size="x-sm"
              variant="solid"
            />
          }
        />
        <TooltipComponent
          title="Limpar"
          location="bottom"
          icon={
            <Button
              label=""
              color="gray-light-25"
              onPress={handleCleanFilter}
              LeftIcon={<ClearIcon />}
              size="x-sm"
              variant="solid"
            />
          }
        />
      </S.LineSection>
    </S.FilterBackhaulContainer>
  );
};
