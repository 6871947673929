import { IBackhaulPurchase } from './modal-backhaul-purchase.types';

export const backhaulPurchaseInitialValues: IBackhaulPurchase = {
  saleOffer: {
    id: 0,
    saleOfferId: 0,
    saleOfferTariffId: 0,
    matchOfferId: 0,
    company: {
      id: 0,
      name: '',
    },
    origin: {
      id: 0,
      name: '',
      city: '',
      uf: '',
      type: '',
    },
    destination: {
      id: 0,
      name: '',
      city: '',
      uf: '',
      type: '',
    },
    vehicle: {
      type: '',
      category: '',
    },
    typeSchedule: 'FTL',
    operationType: 'IN',
    haulType: 'BACKHAUL EXTERNO',
    co2Economy: 0,
    capacity: {
      pallets: 0,
      weight: 0,
    },
    invoicedBy: '',
    values: {
      freightValue: 0,
      logshareValue: 0,
    },
    backhaulPartner: false,
  },
};
