import API from 'utils/API/API';

export const updateCTeDataService = async (scheduleId: number, invoicedBy: string, file: File, isSubCte: boolean) => {
  let typeCte = 'CTE';

  if (isSubCte) typeCte = 'CTE_SUB';

  const formData = new FormData();
  formData.append('type', typeCte);
  formData.append('module', 'schedule');
  formData.append('invoicedBy', invoicedBy);
  formData.append('codigo', String(scheduleId));
  formData.append('file', file);

  await API.post('v2/file/management/upload', formData);
};
