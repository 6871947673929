import { FC, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import {
  ISaleOfferDomain,
  ISaleOfferShipperDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { SaleOfferFormMountTariffView } from './sale-offer-update-form-mount-tariff-view.component';
import { useSaleOfferTariffRemove } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/hooks/use-sale-offer-tariff-remove/use-sale-offer-tariff-remove.hook';
import { useFormikContext } from 'formik';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { getCompanyService } from 'src-new/services/get-company/get-company.service';

interface ISaleOfferFormMountTariffProps {
  saleOfferShipperIndex: number;
}

export const SaleOfferFormMountTariff: FC<ISaleOfferFormMountTariffProps> = ({ saleOfferShipperIndex }) => {
  const { values, setFieldValue } = useFormikContext<ISaleOfferDomain>();
  const { saleOfferModals, saleOfferDetails } = useContext(SaleOfferContext);

  const [companyName, setCompanyName] = useState<string>('');

  const saleOfferTariffRemoveHook = useSaleOfferTariffRemove();

  const getTariffsByIndexShipper = useMemo(() => {
    return values.saleOfferDetails[saleOfferShipperIndex].tariff;
  }, [saleOfferShipperIndex, values.saleOfferDetails]);

  const handleRemoveTariff = useCallback(
    async (tariffId: number) => {
      await saleOfferTariffRemoveHook(tariffId);
    },
    [saleOfferTariffRemoveHook],
  );

  const handleOpenOfferShipperTariffModalEdit = useCallback(
    (indexTariff: number) => {
      saleOfferModals.setModalOpen({
        indexes: { saleOfferShipper: saleOfferShipperIndex, saleOfferShipperTariff: indexTariff },
        modalType: 'offer_shipper_tariff',
      });
    },
    [saleOfferModals, saleOfferShipperIndex],
  );

  const handleActive = useCallback(
    (indexSectionTariff: number, isActive: boolean) => {
      setFieldValue(`saleOfferDetails[${saleOfferShipperIndex}].tariff[${indexSectionTariff}].isActive`, isActive);
    },
    [saleOfferShipperIndex, setFieldValue],
  );

  const shouldBlockDelete = useCallback((values: Array<ISaleOfferShipperDomain>) => {
    const totalTariffs = values.reduce((acc, curr) => acc + curr.tariff.length, 0);
    return totalTariffs === 1;
  }, []);

  useEffect(() => {
    (async () => {
      const company = await getCompanyService(saleOfferDetails.saleOfferDetails.companyId);

      setCompanyName(company.tradeName);
    })();
  }, [saleOfferDetails.saleOfferDetails.companyId]);

  return (
    <SaleOfferFormMountTariffView
      values={getTariffsByIndexShipper}
      companyName={companyName}
      valuesShippersSections={values.saleOfferDetails}
      handleRemoveTariff={handleRemoveTariff}
      handleOpenOfferShipperTariffModalEdit={handleOpenOfferShipperTariffModalEdit}
      handleActive={handleActive}
      shouldBlockDelete={shouldBlockDelete}
    />
  );
};
