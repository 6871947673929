import CellTowerIcon from '@mui/icons-material/CellTower';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoIcon from 'assets/logos/logo-icon';
import { Count } from 'components-v2/common/count';
import { ModalAlerts } from 'components-v2/common/modals/alerts';
import { Alerts } from 'domain-v2/alerts';
import { ModalState } from 'domain-v2/inputs/modals';
import { IUser } from 'domain/login';
import React, { Fragment, MouseEvent, ReactElement, useMemo } from 'react';
import { Breadcrumb } from 'src-new/components/application-page-header/components/header-page/components/breadcrumb/breadcrumb.component';
import * as S from './header-page-view.styled';
import { Tooltip } from '@mui/material';
import { HeaderChatModal } from 'src-new/components/application-page-header/components/header-page/components/header-chat-modal/header-chat-modal.component';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { TagUnreadMessages } from 'src-new/components/application-page-header/components/header-page/components/tag-unread-messages/tag-unread-messages.component';
import { ModalAssintPlan } from '../../../modal-assign-plan/modal-assign-plan.component';
import CrownIcon from 'assets/icons/crown.icon';
import TooltipComponent from 'components/Tooltip';

export interface IModalProps {
  modalAlerts: ModalState;
  setModalAlerts: (modalState: ModalState) => void;
}

interface IHeaderPageViewProps {
  dataTestId?: string;
  breadcrumbList?: Array<string>;
  renderCustomBackButton?: boolean;
  customButtomRender?: React.ReactNode;
  handleModalAssingPlan: (open: boolean) => void;
  handleModalAlert: (prevState: MouseEvent<HTMLDivElement>) => void;
  handleChatModalOpen: () => void;
  isOpenChatModal: boolean;
  goToDashboardPage: () => void;
  goToWhatsApp: () => Window | null;
  hasBackButton?: boolean;
  user?: IUser;
  modalAssignPlan: ModalState;
  modalProps: IModalProps;
  isUserEssencial: boolean;
  data?: Array<Alerts>;
  refetch: () => void;
  handleGoBackButton?: () => void;
}

const initialAlertsState = {} as Array<Alerts>;

export const HeaderPageView: React.FC<IHeaderPageViewProps> = ({
  breadcrumbList,
  dataTestId,
  renderCustomBackButton,
  customButtomRender,
  handleModalAssingPlan,
  handleModalAlert,
  handleChatModalOpen,
  isOpenChatModal,
  goToDashboardPage,
  goToWhatsApp,
  hasBackButton,
  user,
  modalAssignPlan,
  modalProps,
  isUserEssencial,
  data,
  refetch,
  handleGoBackButton,
}: IHeaderPageViewProps) => {
  const getDataTestId = useMemo((): string => dataTestId ?? 'header-page', [dataTestId]);

  const renderBreadcrumb = useMemo((): ReactElement | undefined => {
    if (breadcrumbList) {
      return (
        <Breadcrumb
          breadcrumb={breadcrumbList}
          separator={': :'}
          hasBackButton={hasBackButton}
          handleGoBackButton={handleGoBackButton}
        />
      );
    }
  }, [breadcrumbList, handleGoBackButton, hasBackButton]);

  const renderChatIcon = useMemo((): ReactElement | undefined => {
    return (
      <S.WrapperLink onClick={goToWhatsApp}>
        <Tooltip title={<div style={{ fontSize: '11px' }}>Suporte</div>}>
          <CellTowerIcon fontSize={'small'} htmlColor="gray" />
        </Tooltip>
      </S.WrapperLink>
    );
  }, [goToWhatsApp]);

  const renderAlertCount = useMemo((): ReactElement | undefined => {
    if (((data && data.filter((alerts) => !alerts.isview)?.length) || 0) > 0) {
      return <Count count={data?.filter((alerts) => !alerts.isview)?.length || 0} />;
    }
  }, [data]);

  const renderModalAssignPlan = useMemo((): ReactElement | undefined => {
    if (modalAssignPlan.open) {
      return <ModalAssintPlan handleCloseModal={() => handleModalAssingPlan(false)} />;
    }
  }, [handleModalAssingPlan, modalAssignPlan.open]);

  const renderModalAlert = useMemo((): ReactElement | undefined => {
    if (modalProps.modalAlerts.open) {
      return (
        <ModalAlerts
          userId={Number(user?.id)}
          alerts={data || initialAlertsState}
          refetchAlerts={refetch}
          handleClose={() =>
            modalProps.setModalAlerts({
              open: false,
            })
          }
        />
      );
    }
  }, [data, modalProps, refetch, user?.id]);

  const renderChatModal = useMemo((): ReactElement | undefined => {
    if (isOpenChatModal) {
      return <HeaderChatModal />;
    }
  }, [isOpenChatModal]);

  const renderTagUnread = useMemo((): ReactElement => {
    return <TagUnreadMessages />;
  }, []);

  const renderChatListIcon = useMemo((): ReactElement => {
    return (
      <S.WrapperLink onClick={handleChatModalOpen}>
        <Tooltip title={<div style={{ fontSize: '11px' }}>Chat entre Parceiros</div>}>
          <QuestionAnswerIcon htmlColor="gray" fontSize={'small'} />
        </Tooltip>
        <S.WrapperCount>{renderTagUnread}</S.WrapperCount>
        {renderChatModal}
      </S.WrapperLink>
    );
  }, [handleChatModalOpen, renderChatModal, renderTagUnread]);

  const renderIcons = useMemo((): ReactElement => {
    if (user?.profile === 'shipping-company') {
      return (
        <Fragment>
          {renderChatListIcon}
          {renderChatIcon}
          <S.WrapperLink onClick={handleModalAlert}>
            <S.WrapperCount>
              <S.Div>{renderAlertCount}</S.Div>
            </S.WrapperCount>
            <Tooltip title={<div style={{ fontSize: '11px' }}>Alerta</div>}>
              <NotificationsIcon fontSize={'small'} htmlColor="gray" />
            </Tooltip>
            {renderModalAlert}
          </S.WrapperLink>
          <S.WrapperLogo>
            <LogoIcon />
          </S.WrapperLogo>
        </Fragment>
      );
    }

    if (isUserEssencial) {
      return (
        <Fragment>
          {renderChatListIcon}
          <S.WrapperLink onClick={() => handleModalAssingPlan(true)} disabled>
            <TooltipComponent
              title="Alerta"
              location="bottom"
              icon={
                <S.Div style={{ display: 'flex' }}>
                  <S.WrapperCount>
                    <S.WrapperAlertLogo>
                      <CrownIcon />
                    </S.WrapperAlertLogo>
                    <S.Div>{renderAlertCount}</S.Div>
                  </S.WrapperCount>
                  <NotificationsIcon fontSize={'small'} htmlColor="gray" />
                </S.Div>
              }
            />
          </S.WrapperLink>
          <S.WrapperLink onClick={() => handleModalAssingPlan(true)} disabled>
            <TooltipComponent
              title="Dashboard"
              location="bottom"
              icon={
                <S.Div style={{ display: 'flex' }}>
                  <S.WrapperCount>
                    <S.WrapperDashboardLogo>
                      <CrownIcon />
                    </S.WrapperDashboardLogo>
                  </S.WrapperCount>
                  <LeaderboardIcon fontSize={'small'} htmlColor="gray" />
                </S.Div>
              }
            />
          </S.WrapperLink>
          {renderChatIcon}
          <S.WrapperLogo>
            <LogoIcon />
          </S.WrapperLogo>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {renderChatListIcon}
        <S.WrapperLink onClick={handleModalAlert}>
          <S.WrapperCount>
            <S.Div>{renderAlertCount}</S.Div>
          </S.WrapperCount>
          <Tooltip title={<div style={{ fontSize: '11px' }}>Alerta</div>}>
            <NotificationsIcon fontSize={'small'} htmlColor="gray" />
          </Tooltip>
          {renderModalAlert}
        </S.WrapperLink>
        <S.WrapperLink onClick={goToDashboardPage}>
          <Tooltip title={<div style={{ fontSize: '11px' }}>Dashboard</div>}>
            <LeaderboardIcon fontSize={'small'} htmlColor="gray" />
          </Tooltip>
        </S.WrapperLink>
        {renderChatIcon}
        <S.WrapperLogo>
          <LogoIcon />
        </S.WrapperLogo>
      </Fragment>
    );
  }, [
    goToDashboardPage,
    handleModalAlert,
    handleModalAssingPlan,
    isUserEssencial,
    renderAlertCount,
    renderChatIcon,
    renderChatListIcon,
    renderModalAlert,
    user?.profile,
  ]);

  return (
    <S.WrapperHeader data-testid={`${getDataTestId}-container`}>
      <S.WrapperBreadcrumb>
        {renderModalAssignPlan}
        {renderCustomBackButton && customButtomRender}
        {renderBreadcrumb}
      </S.WrapperBreadcrumb>
      <S.WrapperLinksAndLogo>{renderIcons}</S.WrapperLinksAndLogo>
    </S.WrapperHeader>
  );
};
