import { finishScheduleService } from 'pages/freight/services/finish-schedule/finish-schedule.service';

export const useFinishScheduleHook = () => {
  return async (id: number, scheduleDetailsId: number) => {
    const service = await finishScheduleService(id, scheduleDetailsId);
    setTimeout(() => {
      location.reload();
    }, 1000);
    return service;
  };
};
