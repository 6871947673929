import React, { useContext } from 'react';
import { WeighingTottenModalsDetailsView } from './weighing-totten-modal-details-view';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';

interface IWeighingTottenModalsDetails {
  callback: () => void;
}
export const WeighingTottenModalsDetails: React.FC<IWeighingTottenModalsDetails> = ({ callback }) => {
  const { weighingTottensDetails } = useContext(WeighingTottensContext);
  const schedule = weighingTottensDetails.weighingTottensDetails;

  return <WeighingTottenModalsDetailsView callback={callback} schedule={schedule} />;
};
