import { IUser } from 'domain/login';

export const validatePermissionsService = (name: string, profiles: string[], user: IUser | undefined): boolean => {
  if (profiles.includes('logshare') && user?.profile != 'logshare') {
    return false;
  }
  const hasFunctionalities = user?.functionalities?.filter(
    (functionality) => functionality.name === name || profiles.includes('full'),
  );
  return !!hasFunctionalities?.length;
};
