import { ColorsV2Type } from 'styles/types/colors';
import { IActiveTabName } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { ScheduleType } from 'domain-v2/inputs/schedule-type';
import { IUser } from 'domain/login';
import { IOption } from 'domain/global-inputs';

type StatusMap = {
  [key: string]: string;
};

export const convertStatusCode = (statusCode: string, user?: IUser): string => {
  const isAdmin = user?.profile === 'logshare';

  const statusMap: StatusMap = {
    AG10: 'Aguardando Alocação',
    AL10: 'Aguardando Motorista',
    AL20: 'Aguardando Veículo',
    IN10: 'Aguardando Aceite',
    IN11: 'Aguardando Início de Rota',
    IN20: 'Em Rota para Carregamento',
    IN30: 'Aguardando Carregamento',
    IN40: 'Em Carregamento',
    IN50: 'Coleta Realizada',
    IN60: 'Em Rota para Descarga',
    IN61: 'Início da viagem para descarga',
    IN62: 'Chegada para descarga',
    IN70: 'Em Descarga',
    IN80: 'Descarregando',
    IN90: 'Descarga Finalizada',
    AL90: 'Alocação Cancelada com Custo',
    AL99: 'Alocação Cancelada',
    OT80: 'Expirado',

    //BACKHAUL
    OT10: 'Em Negociação',
    OT99: 'Oferta Negada',

    //TRANSPORTADORA
    OT01: 'Ag. Aceite Transportadora',
    SH01: isAdmin ? 'Em Bidding' : 'Aguardando Alocação',

    //FORA DO FLUXO NORMAL DE AGENDAMENTO
    SH02: 'Prospecção',
    LG90: 'Descarga Finalizada',
  };

  return statusMap[statusCode] || 'Não Mapeado';
};

export const convertStatusCodeColor = (statusCode: string): ColorsV2Type => {
  const statusColors: { [key: string]: ColorsV2Type } = {
    'oferta negada': ColorsV2Type.Red,
    'alocação cancelada': ColorsV2Type.Red,
    'alocação cancelada com custo': ColorsV2Type.Red,
    'em negociação': ColorsV2Type.Blue,
    'em bidding': ColorsV2Type.Blue,
    'aguardando alocação': ColorsV2Type.Blue25,
    'aguardando transportadora': ColorsV2Type.Blue25,
    'ag. aceite transportadora': ColorsV2Type.Blue50,
    'aguardando motorista': ColorsV2Type.Blue75,
    'aguardando início de rota': ColorsV2Type.Blue100,
    'em rota para carregamento': ColorsV2Type.Aqua25,
    'em carregamento': ColorsV2Type.Aqua50,
    'em rota para descarga': ColorsV2Type.Aqua75,
    'em descarga': ColorsV2Type.Aqua100,
    'descarga finalizada': ColorsV2Type.Aqua,
    expirado: ColorsV2Type.GrayDark,
  };

  return statusColors[statusCode] || ColorsV2Type.Blue;
};

export const convertScheduleTypeColor = (scheduleType: ScheduleType): ColorsV2Type => {
  const scheduleTypeColors: { [key: string]: ColorsV2Type } = {
    ftl: ColorsV2Type.Blue,
    ltl: ColorsV2Type.Aqua100,
    stl: ColorsV2Type.Blue100,
  };

  return scheduleTypeColors[scheduleType] || ColorsV2Type.Blue;
};

export const convertStatusCodeOptions = (activeTabName: IActiveTabName): IOption[] => {
  const statusCodeFilterOptions: { [key: string]: IOption[] } = {
    'Em Alocação': [
      { value: 'SH01', label: 'EM BIDDING' },
      { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
      { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
      { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
    ],
    Cancelados: [
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
    ],
    'A Iniciar': [
      { value: 'Al20', label: 'ALOCADO' },
      { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
      { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
    ],
    'Em Andamento': [
      { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
      { value: 'IN30', label: 'AGUARDANDO CARREGAMENTO' },
      { value: 'IN40', label: 'EM CARREGAMENTO' },
      { value: 'IN50', label: 'COLETA REALIZADA' },
      { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
      { value: 'IN61', label: 'INÍCIO DA VIAGEM PARA DESCARGA' },
      { value: 'IN62', label: 'CHEGADA PARA DESCARGA' },
      { value: 'IN70', label: 'EM DESCARGA' },
    ],
    Finalizados: [
      { value: 'IN90', label: 'DESCARGA FINALIZADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
    ],
    Todos: [
      { value: 'SH01', label: 'EM BIDDING' },
      { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
      { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
      { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
      { value: 'Al20', label: 'ALOCADO' },
      { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
      { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
      { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
      { value: 'IN30', label: 'AGUARDANDO CARREGAMENTO' },
      { value: 'IN40', label: 'EM CARREGAMENTO' },
      { value: 'IN50', label: 'COLETA REALIZADA' },
      { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
      { value: 'IN70', label: 'EM DESCARGA' },
      { value: 'IN90', label: 'DESCARGA FINALIZADA' },
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
      { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
      { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
      { value: 'OT99', label: 'OFERTA NEGADA' },
    ],
  };

  return statusCodeFilterOptions[activeTabName];
};
