import React, { ReactNode } from 'react';

import UserListTableView from './user-list-table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IUsersTableProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo: PageInfo | null;
  changePage: (page: number) => void;
  goToDetailsPage: (userId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
}

const UsersTable: React.FC<IUsersTableProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  goToDetailsPage,
  isLoading,
  arrowOrder,
}) => {
  return (
    <UserListTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      clickRowAction={goToDetailsPage}
      isLoading={isLoading}
    />
  );
};

export default UsersTable;
