import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';
import InputField from 'components/input-field/input-field';
import Select from 'components/select/select';
import { Button } from 'logshare-ui-kit';
import { ChangeEvent, FC, Fragment, ReactElement, useCallback, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { invoicedByOptions } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register-initial-values.domain';
import { ISaleOfferShipperTariffRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import * as S from './sale-offer-register-tariff-form-modal-view.styled';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import AsyncSelectField from 'components/async-select/async-select';
import { Equal, Info, Plus } from 'lucide-react';
import InfoIcon from '@mui/icons-material/Info';
import TooltipComponent from 'components/Tooltip';
import { IOptionsDomain } from 'src-new/domains/options.domain';

interface ISaleOfferRegisterTariffFormModalViewProps {
  values: ISaleOfferShipperTariffRegisterPayloadDomain;
  isAdmin: boolean;
  isZMaster: boolean;
  userShippingCompany: boolean;
  indexSection: number;
  companyName: string;
  buttonDisabled: boolean;
  vehicleOptions: { vehicleType: Array<IOptionsDomain>; vehicleCategory: Array<IOptionsDomain> };
  handleChange: (name: keyof ISaleOfferShipperTariffRegisterPayloadDomain, value: any) => void;
  handleOnSubmit: (values: ISaleOfferShipperTariffRegisterPayloadDomain) => void;
  handleCloseRegisterShipperTariffModal: () => void;
}

const INPUT_CURRENCY_CONFIG = { locale: 'pt-BR', currency: 'BRL' };

export const SaleOfferRegisterTariffFormModalView: FC<ISaleOfferRegisterTariffFormModalViewProps> = ({
  values,
  isAdmin,
  isZMaster,
  userShippingCompany,
  indexSection,
  companyName,
  buttonDisabled,
  vehicleOptions,
  handleChange,
  handleOnSubmit,
  handleCloseRegisterShipperTariffModal,
}) => {
  const currentValue = useCallback((value: string): string => {
    if (value === '0' || !value) {
      return '0,00';
    }

    return value;
  }, []);

  const formatCurrentValue = useCallback(
    (value: string, fieldName: keyof ISaleOfferShipperTariffRegisterPayloadDomain) => {
      if (!!value && !/^\d+,\d{2}$/.test(value)) {
        handleChange(fieldName, `${value},00`);
      }
    },
    [handleChange],
  );

  const handleNegativeNumber = useCallback(
    (value: string, fieldName: keyof ISaleOfferShipperTariffRegisterPayloadDomain) => {
      if (Number(value) >= 0) {
        handleChange(fieldName, value);
        return;
      }

      handleChange(fieldName, '0');
    },
    [handleChange],
  );

  const renderInputShippingCompany = useMemo(() => {
    if (!userShippingCompany) {
      return (
        <LineSectionLayout columns="1fr">
          <AsyncSelectField
            placeholder=""
            label="Transportadora"
            isClearable={true}
            name="shippingCompanyId"
            id="shippingCompanyId"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleChange('shipping', { id: e && e.value, name: e && e.label });
            }}
            apiCallFunction={shippingCompanyFilterSearchService}
            loadOptions={shippingCompanyFilterSearchService}
            defaultValue={{
              label: values.shipping.name,
              value: values.shipping.id,
            }}
          />
        </LineSectionLayout>
      );
    }

    return (
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Transportadora"
          isClearable={true}
          name="shippingCompanyId"
          id="shippingCompanyId"
          cacheOptions={true}
          defaultOptions={true}
          onChange={() => null}
          apiCallFunction={shippingCompanyFilterSearchService}
          loadOptions={shippingCompanyFilterSearchService}
          value={{
            label: companyName,
            value: values.shipping.id,
          }}
          disabled
        />
      </LineSectionLayout>
    );
  }, [companyName, handleChange, userShippingCompany, values.shipping.id, values.shipping.name]);

  const renderInvoicedBy = useMemo(() => {
    const selectComponent = (
      <Select
        label="Emissor de CTe *"
        id="invoicedBy"
        name="invoicedBy"
        value={values.invoicedBy}
        setFieldValue={(_, value) => handleChange('invoicedBy', value)}
        options={invoicedByOptions}
        disabled={indexSection === 0 || userShippingCompany}
      />
    );

    if (isAdmin || isZMaster) {
      return (
        <LineSectionLayout columns="2.5fr 1fr">
          {selectComponent}
          <InputCurrency
            label="Taxa Logshare (%)"
            id="feeLogshare"
            name="feeLogshare"
            suffix={' %'}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={INPUT_CURRENCY_CONFIG}
            value={currentValue(values.feeLogshare)}
            onValueChange={(value) => handleChange('feeLogshare', value)}
          />
        </LineSectionLayout>
      );
    }

    return <LineSectionLayout columns="1fr">{selectComponent}</LineSectionLayout>;
  }, [
    currentValue,
    handleChange,
    indexSection,
    isAdmin,
    isZMaster,
    userShippingCompany,
    values.feeLogshare,
    values.invoicedBy,
  ]);

  const renderSaleOfferValues = useMemo((): ReactElement => {
    if (userShippingCompany) {
      return (
        <Fragment>
          <InputCurrency
            label={`Receita ${companyName} *`}
            id="valueShipping"
            name="valueShipping"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            onBlur={() => formatCurrentValue(values.valueShipping, 'valueShipping')}
            intlConfig={INPUT_CURRENCY_CONFIG}
            value={currentValue(values.valueShipping)}
            onValueChange={(value) => handleChange('valueShipping', value)}
          />

          <S.ContainerIcon>
            <Plus strokeWidth={3.5} size={20} color={'gray'} />
          </S.ContainerIcon>

          <InputCurrency
            label="Receita EMBARCADOR *"
            id="valueShipper"
            name="valueShipper"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            onBlur={() => formatCurrentValue(values.valueShipper, 'valueShipper')}
            intlConfig={INPUT_CURRENCY_CONFIG}
            value={currentValue(values.valueShipper)}
            onValueChange={(value) => handleChange('valueShipper', value)}
            disabled={values.invoicedBy === 'transportadora'}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <InputCurrency
          label={`Receita ${companyName} *`}
          id="valueShipper"
          name="valueShipper"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          decimalSeparator=","
          step={1}
          groupSeparator="."
          onBlur={() => formatCurrentValue(values.valueShipper, 'valueShipper')}
          intlConfig={INPUT_CURRENCY_CONFIG}
          value={currentValue(values.valueShipper)}
          onValueChange={(value) => handleChange('valueShipper', value)}
          disabled={values.invoicedBy === 'transportadora'}
        />
        <S.ContainerIcon>
          <Plus strokeWidth={3.5} size={20} color={'gray'} />
        </S.ContainerIcon>

        <InputCurrency
          label="Receita TRANSPORTADORA *"
          id="valueShipping"
          name="valueShipping"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          decimalSeparator=","
          step={1}
          groupSeparator="."
          onBlur={() => formatCurrentValue(values.valueShipping, 'valueShipping')}
          intlConfig={INPUT_CURRENCY_CONFIG}
          value={currentValue(values.valueShipping)}
          onValueChange={(value) => handleChange('valueShipping', value)}
        />
      </Fragment>
    );
  }, [
    companyName,
    currentValue,
    formatCurrentValue,
    handleChange,
    userShippingCompany,
    values.invoicedBy,
    values.valueShipper,
    values.valueShipping,
  ]);

  return (
    <ModalBark
      size={'md'}
      title={<S.ModalTitle>Informe os Detalhes da Tarifa</S.ModalTitle>}
      handleClose={handleCloseRegisterShipperTariffModal}
    >
      <SectionForm>
        <S.ContainerContent>
          <LineSectionLayout columns="1fr 1fr">
            <S.Div>
              <S.SectionWrapper padding="0 0 5px 0">
                <S.SectionTitle>TIPO DE VEÍCULO</S.SectionTitle>
              </S.SectionWrapper>

              <LineSectionLayout columns="1.7fr 1.7fr 1fr">
                <Select
                  label="Veículo *"
                  id="vehicleType"
                  name="vehicleType"
                  value={values.vehicleType}
                  setFieldValue={(_, value) => handleChange('vehicleType', value)}
                  options={vehicleOptions.vehicleType}
                />
                <Select
                  label="Categoria *"
                  id="vehicleCategory"
                  name="vehicleCategory"
                  value={values.vehicleCategory}
                  setFieldValue={(_, value) => handleChange('vehicleCategory', value)}
                  options={vehicleOptions.vehicleCategory}
                />
                <InputCurrency
                  label="Frequência"
                  id="frequency"
                  name="frequency"
                  type="text"
                  allowDecimals={false}
                  decimalScale={0}
                  decimalsLimit={0}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value) => handleNegativeNumber(value ?? '', 'frequency')}
                  value={values.frequency}
                  suffix="/Mês"
                />
              </LineSectionLayout>
            </S.Div>

            <S.Div>
              <S.SectionWrapper padding="0 0 5px 0">
                <S.SectionTitle>CAPACIDADE</S.SectionTitle>
              </S.SectionWrapper>

              <LineSectionLayout columns="1fr 1fr 1fr">
                <InputCurrency
                  label="Peso (Kg)"
                  id="weight"
                  name="weight"
                  type="text"
                  allowDecimals={false}
                  decimalScale={0}
                  decimalsLimit={0}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value) => handleChange('weight', value)}
                  value={values.weight}
                  suffix=" Kg"
                />
                <InputField
                  label="Paletes (Qtd)"
                  id="pallets"
                  name="pallets"
                  type="number"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleNegativeNumber(event.target.value, 'pallets')
                  }
                  value={values.pallets}
                />
                <InputCurrency
                  label="Cubagem (m3)"
                  id="cubage"
                  name="cubage"
                  type="number"
                  suffix=" m³"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value) => handleChange('cubage', value)}
                  value={values.cubage}
                />
              </LineSectionLayout>
            </S.Div>
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr">
            <S.Div>
              <S.SectionWrapper>
                <S.SectionTitle>FATURADO POR</S.SectionTitle>
              </S.SectionWrapper>

              {renderInvoicedBy}
            </S.Div>

            <S.Div>
              <S.SectionWrapper>
                <S.SectionTitle>OPERADO POR</S.SectionTitle>
              </S.SectionWrapper>

              <LineSectionLayout columns="1fr">{renderInputShippingCompany} </LineSectionLayout>
            </S.Div>
          </LineSectionLayout>

          <S.SectionWrapper>
            <S.SectionTitle>COMPOSIÇÃO DO FRETE</S.SectionTitle>
          </S.SectionWrapper>
        </S.ContainerContent>

        <LineSectionLayout columns="1fr 0.1fr 1fr 0.1fr 1.11fr 0.1fr 1.11fr">
          {renderSaleOfferValues}

          <S.ContainerIcon>
            <Plus strokeWidth={3.5} size={20} color={'gray'} />
          </S.ContainerIcon>

          <InputCurrency
            label={
              <S.InfoSectionFeeLogshare>
                Receita Logshare *
                <TooltipComponent
                  title={<span>Taxa Logshare {values.feeLogshare}%</span>}
                  location="top"
                  icon={<Info size={14} color="#6e6e6e" />}
                />
              </S.InfoSectionFeeLogshare>
            }
            id="valueLogshare"
            name="valueLogshare"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            onBlur={() => formatCurrentValue(values.valueLogshare, 'valueLogshare')}
            intlConfig={INPUT_CURRENCY_CONFIG}
            value={currentValue(values.valueLogshare)}
            onValueChange={(value) => handleChange('valueLogshare', value)}
            disabled
          />

          <S.ContainerIcon>
            <Equal strokeWidth={3.5} size={20} color={'gray'} />
          </S.ContainerIcon>

          <InputCurrency
            label="Frete Total *"
            id="valueTotal"
            name="valueTotal"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={INPUT_CURRENCY_CONFIG}
            value={values.valueTotal.toFixed(2)}
            onValueChange={(value) => handleChange('valueTotal', value)}
            disabled
          />
        </LineSectionLayout>

        <S.WrapperButtonSubmit>
          <S.InfoSection>
            <InfoIcon sx={{ display: 'flex', width: '15px' }} />
            <S.TermsText>O frete total informado não deverá conter pedágio e icms.</S.TermsText>
          </S.InfoSection>
          <Button
            label="Salvar"
            color="pink"
            onPress={handleOnSubmit}
            size="md"
            variant="solid"
            disabled={buttonDisabled}
          />
        </S.WrapperButtonSubmit>
      </SectionForm>
    </ModalBark>
  );
};
