import { createContext, useMemo, useReducer } from 'react';
import { useSaleOfferDetailsContextValues } from './context-values/sale-offer-details/sale-offer-details-values.context';
import { useSaleOfferFreetimeContextValues } from './context-values/sale-offer-freetime/sale-offer-freetime-values.context';
import { useSaleOfferListContextValues } from './context-values/sale-offer-list/sale-offer-list-values.context';
import { useSaleOfferMatchListContextValues } from './context-values/sale-offer-match-list/sale-offer-match-list-values.context';
import { useSaleOfferModalContextValues } from './context-values/sale-offer-modals/sale-offer-modals-values.context';
import { saleOfferReducer } from './reducers/sale-offer.reducer';
import { saleOfferInitialState } from './sale-offer.constants';
import { ISaleOfferContextProps } from './sale-offer.types';
import { ISaleOfferDetails } from './types/sale-offer-details.types';
import { ISaleOfferFreetime } from './types/sale-offer-freetime.types';
import { ISaleOfferList } from './types/sale-offer-list.types';
import { ISaleOfferMatchList } from './types/sale-offer-match-list.types';
import { ISaleOfferModals } from './types/sale-offer-modals.types';
import { ISaleOfferContext } from './types/sale-offer.types';
import { useSaleOfferUpdateContextValues } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/context-values/sale-offer-update/sale-offer-update-values.context';
import { useSaleOfferListDownloadReportContextValues } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/context-values/sale-offer-list-download-report/sale-offer-list-download-report-values.context';
import { ISaleOfferListDownloadReport } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-list-download-report.types';

export const SaleOfferContext = createContext<ISaleOfferContext>(saleOfferInitialState);

export const SaleOfferContextProvider = ({ children }: ISaleOfferContextProps) => {
  const [state] = useReducer(saleOfferReducer, saleOfferInitialState);

  const saleOfferModals: ISaleOfferModals = useSaleOfferModalContextValues();
  const saleOfferDetails: ISaleOfferDetails = useSaleOfferDetailsContextValues();
  const saleOfferList: ISaleOfferList = useSaleOfferListContextValues();
  const saleOfferUpdate = useSaleOfferUpdateContextValues();
  const saleOfferMatchList: ISaleOfferMatchList = useSaleOfferMatchListContextValues();
  const saleOfferFreetime: ISaleOfferFreetime = useSaleOfferFreetimeContextValues();
  const saleOfferListDownloadReport: ISaleOfferListDownloadReport = useSaleOfferListDownloadReportContextValues();

  const value: ISaleOfferContext = useMemo(
    (): ISaleOfferContext => ({
      ...state,
      saleOfferModals,
      saleOfferDetails,
      saleOfferList,
      saleOfferUpdate,
      saleOfferMatchList,
      saleOfferFreetime,
      saleOfferListDownloadReport,
    }),
    [
      saleOfferDetails,
      saleOfferFreetime,
      saleOfferList,
      saleOfferListDownloadReport,
      saleOfferMatchList,
      saleOfferModals,
      saleOfferUpdate,
      state,
    ],
  );

  return <SaleOfferContext.Provider value={value}>{children}</SaleOfferContext.Provider>;
};
