import styled from 'styled-components';
import { Form, Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;
