import React, { ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './vehicle-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface IVehicleTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (vehicleId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'vehicle-table-view';

const VehicleTableView: React.FC<IVehicleTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  arrowOrder,
  isLoading,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}: IVehicleTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <ContainerPaginationStyled data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
      {renderPagination}
    </ContainerTableStyled>
  );
};

export default VehicleTableView;
