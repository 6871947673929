import React, { useEffect, useState } from 'react';

import DeliveryTrackingDetailFormView from './delivery-tracking-details-view';

import { initialValues } from 'pages/delivery-tracking/types/types';
import { useFilterDeliveryTracking } from 'pages/delivery-tracking/hooks/use-filter.hook';
import { FilterService } from 'domain/filter-delivery-tracking';

interface PortSantosForm {
  schedulingCode: string;
}

const DeliveryTrackingDetailForm: React.FC<PortSantosForm> = ({ schedulingCode }) => {
  const [result, setResult] = useState<FilterService>(initialValues);

  const resultFilter = useFilterDeliveryTracking();

  useEffect(() => {
    async function run() {
      const response = await resultFilter({
        schedulingCode: schedulingCode,
        id: 0,
        companyId: 0,
        scheduleDetails: {
          acceptDate: '',
          startDate: '',
          checkinDate: '',
          checkoutDate: '',
          finishDate: '',
          levyInitialDate: '',
          levyInitialTime: '',
          deliveryInitialDate: '',
          deliveryInitialTime: '',
          levyEndDate: '',
          levyEndTime: '',
          deliveryEndDate: '',
          deliveryEndTime: '',
          loadDate: '',
        },
        scheduleClientOrigin: {
          id: 0,
          client: undefined,
          locality: undefined,
        },
        scheduleClientDestiny: {
          id: 0,
          client: undefined,
          locality: undefined,
        },
        scheduleCargo: {
          capacity: 0,
          id: 0,
          inPalet: false,
          invoice: '',
          needBodyGuard: false,
          needHelper: '',
          operationType: '',
          orderNumber: '',
          pallet: 0,
          shippingReturn: '',
          shippingValue: '',
          vehicleCategoryType: '',
          vehicleType: '',
          weight: 0,
        },
        typeSchedule: '',
        statusCode: '',
        total_freight: 0,
        updated_at: '',
        created_at: '',
      });
      setResult(response);
    }
    run();
  }, []);

  return <DeliveryTrackingDetailFormView result={result} />;
};

export default DeliveryTrackingDetailForm;
