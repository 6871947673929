import { useCallback, useReducer } from 'react';
import { chatButtonInitialState } from '../chat-button.constants';
import { ChatActionType } from '../chat-button.action';
import { IChatButtonContext, ISetChatOpenProps, IUserDetailsProps } from '../types/chat-button.types';
import { chatButtonReducer } from 'src-new/contexts/chat-button/reducers/chat-button.reducer';

export const useChatButtonContextValues = (): IChatButtonContext => {
  const [state, dispatch] = useReducer(chatButtonReducer, chatButtonInitialState);

  const setChatRequest = useCallback(() => {
    dispatch({ type: ChatActionType.CHAT_REQUEST, payload: undefined });
  }, []);

  const setChatSuccess = useCallback((userDetails: IUserDetailsProps) => {
    dispatch({ type: ChatActionType.CHAT_SUCCESS, payload: userDetails });
  }, []);

  const setChatError = useCallback(() => {
    dispatch({ type: ChatActionType.CHAT_ERROR, payload: undefined });
  }, []);

  const setChatOpen = useCallback((props: ISetChatOpenProps) => {
    dispatch({ type: ChatActionType.CHAT_OPEN, payload: props });
  }, []);

  const setChatClose = useCallback(() => {
    dispatch({ type: ChatActionType.CHAT_CLOSE, payload: undefined });
  }, []);

  const setChatReset = useCallback(() => {
    dispatch({ type: ChatActionType.CHAT_RESET, payload: undefined });
  }, []);

  return {
    ...state,
    setChatRequest,
    setChatSuccess,
    setChatError,
    chatActions: {
      ...state.chatActions,
      setChatOpen,
      setChatClose,
    },
    setChatReset,
  };
};
