import { useCallback } from 'react';

import { hireBackhaulDetailService } from 'pages/hire-backhaul/services/hire-backhaul-details/hire-backhaul-details.service';
import { IHireBackhaulCreated } from 'domain/hire-backhaul';

const useHireBackhaulDetailHook = () => {
  return useCallback(async (hireBackhaulId: string): Promise<IHireBackhaulCreated> => {
    const hireBackhaul = await hireBackhaulDetailService(hireBackhaulId);
    return hireBackhaul;
  }, []);
};

export { useHireBackhaulDetailHook };
