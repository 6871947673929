/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';

import * as S from './styled';

import { convertScheduleTypeColor } from 'utils-v2/converters/status-code';
import { convertHaulType } from 'utils-v2/converters/haul-type';
import { convertModalityType } from 'utils-v2/converters/freight-modality';
import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { convertStatusCode, convertStatusCodeColor } from 'utils/status-code';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { formatDecimal } from 'utils/format-numbers';
import { DownloadFile } from 'utils/download-file/download-file';
import { createCO2Tags, createTag } from 'utils/create-tags';
import { ColorsV2Type } from 'styles/types/colors';
import theme from 'styles/theme';
import { quotationScheduleService } from 'pages-v2/schedule/services/quotation';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { RequirementTypes } from 'domain-v2/schedule/cargo';
import { ScheduleType } from 'domain-v2/inputs/schedule-type';
import { Operation } from 'domain-v2/inputs/operation';
import { ModalityType } from 'domain-v2/inputs/modality-type';
import { IUser } from 'domain/login';
import pdf from 'assets/pdf.svg';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';
import { CardResumeIcon } from 'assets/icons/card-resume-icon';

interface CardResumeHeaderProps {
  id?: number;
  statusCode?: string;
  scheduleType: ScheduleType;
  operationType: Operation;
  co2?: number;
  requirementTags: RequirementTypes;
  values?: ScheduleRegisterInput;
  user?: IUser;
  hideQuotation?: boolean;
  freightModality?: ModalityType;
}

type Color = keyof typeof theme.colorsV2;

const initialValuesQuotation = {} as ScheduleRegisterInput;

const backhaulTypes = ['BACKHAUL EXTERNO', 'BACKHAUL PARCEIRO', 'BACKHAUL INTERNO'];

export const CardResumeHeader: React.FC<CardResumeHeaderProps> = ({
  id,
  co2,
  statusCode = '',
  scheduleType,
  operationType,
  requirementTags,
  values,
  user,
  hideQuotation,
  freightModality,
}) => {
  const renderTags = useMemo(() => {
    const labelStatus = convertStatusCode(statusCode, user);
    const colorStatus = convertStatusCodeColor(labelStatus.toLowerCase());

    const { quality, security } = requirementTags;

    const renderTags = (values?: Array<string>) => {
      if (values?.length === 0) return undefined;

      return values?.map((tag) => (
        <Tag key={tag} label={tag} color={colorStatus} variant="solid" display="inline-flex" size="lg" />
      ));
    };

    return (
      <S.WrapperTags>
        {statusCode && <Tag label={labelStatus} color={colorStatus} variant="solid" display="inline-flex" size="lg" />}
        <Tag
          label={convertHaulType(operationType).label}
          color={convertHaulType(operationType).color}
          variant="solid"
          display="inline-flex"
          size="lg"
        />
        <Tag
          label={scheduleType}
          color={convertScheduleTypeColor(scheduleType)}
          variant="solid"
          display="inline-flex"
          size="lg"
        />
        {freightModality && (
          <Tag
            label={convertModalityType(freightModality).label}
            color={convertModalityType(freightModality).color}
            variant="solid"
            display="inline-flex"
            size="lg"
          />
        )}
        {renderTags(quality?.values)} {renderTags(security?.values)}
        {backhaulTypes.includes(operationType) && (
          <Tag
            LeftIcon={
              <S.TagText>
                <DoubleArrowDownIcon />
                CO₂
              </S.TagText>
            }
            label={`${formatDecimal(co2 || 0)} KG`}
            color={ColorsV2Type.Aqua}
            variant="ghost"
            display="inline-flex"
            size="lg"
          />
        )}
      </S.WrapperTags>
    );
  }, [statusCode, operationType, scheduleType, requirementTags, co2]);

  const handleQuotation = useCallback(async () => {
    if (!hideQuotation) return;

    try {
      const pdf = await quotationScheduleService(values || initialValuesQuotation, tagsPdf, id);

      DownloadFile(pdf, 'pdf', fileNamePdf);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [values, hideQuotation]);

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_cotacao_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const tagsPdf = useMemo(() => {
    const labelStatus = convertStatusCode(statusCode, user);
    const colorStatus = convertStatusCodeColor(labelStatus.toLowerCase());
    const convertHaulTypeResult = convertHaulType(operationType);

    const { quality, security } = requirementTags;

    const haulTypeTags = [createTag(convertHaulTypeResult.label, convertHaulTypeResult.color as Color)];
    const scheduleTypeTags = [createTag(scheduleType, convertScheduleTypeColor(scheduleType) as Color)];

    const qualityTags =
      quality && quality.values ? quality.values.map((tag) => createTag(tag, colorStatus as Color)) : [];

    const securityTags =
      security && security.values ? security.values.map((tag) => createTag(tag, colorStatus as Color)) : [];

    const statusCodeTags = statusCode ? [createTag(labelStatus, colorStatus as Color)] : [];

    const co2Tags = backhaulTypes.includes(operationType) ? createCO2Tags(co2) : [];

    return [...haulTypeTags, ...scheduleTypeTags, ...qualityTags, ...securityTags, ...co2Tags, ...statusCodeTags];
  }, [statusCode, operationType, scheduleType, requirementTags, co2]);

  return (
    <S.Content>
      <S.HeaderSection>
        <S.HeaderTitleSection>
          <CardResumeIcon />
          <S.Text blueColor fontWeight size="16px" padding="1px 0 2px 10px">
            {id ? `VIAGEM ${id}` : 'Resumo do Frete'}
          </S.Text>
        </S.HeaderTitleSection>

        <S.HeaderButtons>
          {!id && operationType === 'SPOT' && hideQuotation && (
            <S.CotationContainer onClick={handleQuotation}>
              <S.Text size="11px" fontWeight textColor={!hideQuotation}>
                <span style={{ color: '#000' }}>Ver Cotação</span>
              </S.Text>

              <S.CotationImageContainer hideQuotation={hideQuotation}>
                <S.CotationImage src={pdf} hideQuotation={hideQuotation} />
              </S.CotationImageContainer>
            </S.CotationContainer>
          )}
        </S.HeaderButtons>
      </S.HeaderSection>

      <S.TagContainer>{renderTags}</S.TagContainer>
    </S.Content>
  );
};
