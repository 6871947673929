import API from 'utils/API/API';
import { converterDriverDomainToFormData } from 'pages/driver/mappers/convert-driver-domain-to-form-data/convert-driver-domain-to-form-data.mapper';
import { IDriverNewDomain } from 'src-new/pages/registrations/domains/driver.domain';

export const driverRegisterService = async (driver: IDriverNewDomain) => {
  const formData = converterDriverDomainToFormData(driver);
  const { data } = await API.post('drivers', formData);

  return data;
};
