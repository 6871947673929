import { IWeighingListFilters } from 'src-new/pages/execution/pages/weighing/types/weighing-list-filters/weighing-list-filters.types';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { convertDomainToEntity } from './create-weighing-excel.mapper';

export const createWeighingExcelService = async (params: IWeighingListFilters): Promise<BlobPart | undefined> => {
  const dataRequest = convertDomainToEntity(params);
  const { data } = await API_TRAVELS_SVC.get('v1/weighing/export-excel', {
    params: {
      ...dataRequest,
    },
    responseType: 'arraybuffer',
  });

  return data;
};
