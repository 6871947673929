import React from 'react';

import ClientUpdateForm from './components/client-update-form/client-update-form';
import { ClientUpdateContainerStyled } from './client-update.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const ClientUpdateView: React.FC = () => {
  return (
    <ClientUpdateContainerStyled>
      <HeaderPage breadcrumbList={['Cadastro', 'Parceiros']} hasBackButton />
      <Title title="Atualização Cadastral" hasTrace isForm />
      <ClientUpdateForm />
    </ClientUpdateContainerStyled>
  );
};

export default ClientUpdateView;
