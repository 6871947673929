import { useCallback, useReducer } from 'react';
import { purchaseOfferInitialState } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.constants';
import { PurchaseOfferBaitRouteActionType } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.action';
import { IPurchaseOfferBaitRoute } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-bait-route.types';
import { purchaseOfferBaitRouteReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-bait-route/purchase-offer-bait-route.reducer';

export const usePurchaseOfferBaitRouteContextValues = (): IPurchaseOfferBaitRoute => {
  const [state, dispatch] = useReducer(purchaseOfferBaitRouteReducer, purchaseOfferInitialState);

  const setPurchaseOfferBaitRouteRequest = useCallback(() => {
    dispatch({
      type: PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_REQUEST,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferBaitRouteSuccess = useCallback(() => {
    dispatch({
      type: PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_SUCCESS,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferBaitRouteError = useCallback(() => {
    dispatch({
      type: PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_ERROR,
      payload: undefined,
    });
  }, []);

  const setPurchaseOfferBaitRouteReset = useCallback(() => {
    dispatch({
      type: PurchaseOfferBaitRouteActionType.PURCHASE_OFFER_BAIT_ROUTE_RESET,
      payload: undefined,
    });
  }, []);

  return {
    ...state.purchaseOfferBaitRoute,
    setPurchaseOfferBaitRouteRequest,
    setPurchaseOfferBaitRouteSuccess,
    setPurchaseOfferBaitRouteError,
    setPurchaseOfferBaitRouteReset,
  };
};
