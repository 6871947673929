import { FC } from 'react';

export const AlertBiddingCreated: FC = () => {
  return (
    <svg width="30" height="32" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.69 23.9943L23.1 16.4443C23.22 15.9743 22.99 15.4843 22.53 15.2843L18.14 13.4243L19.37 10.3143L19.87 10.5143C19.99 10.5643 20.11 10.5843 20.24 10.5843C20.63 10.5843 21.01 10.3443 21.17 9.95434L22.78 5.88434C22.88 5.63434 22.87 5.36434 22.77 5.11434C22.66 4.87434 22.46 4.68434 22.22 4.58434L10.81 0.0743385C10.29 -0.135661 9.71998 0.114339 9.50998 0.634339L7.89998 4.70434C7.79998 4.95434 7.80998 5.22434 7.90998 5.47434C8.01998 5.71434 8.21998 5.90434 8.45998 6.00434L8.95998 6.20434L3.88998 19.0043L3.38998 18.8043C2.87998 18.6043 2.29998 18.8543 2.09998 19.3743L0.479983 23.4443C0.389983 23.6943 0.389983 23.9643 0.499983 24.2043C0.599983 24.4543 0.799983 24.6443 1.03998 24.7443L12.45 29.2543C12.57 29.3043 12.69 29.3243 12.82 29.3243C12.95 29.3243 13.09 29.2943 13.22 29.2443C13.46 29.1343 13.65 28.9443 13.75 28.6943L15.36 24.6243C15.56 24.1043 15.31 23.5243 14.8 23.3243L14.31 23.1243L15.48 20.1743L19.8 22.0043C19.93 22.0643 20.06 22.0943 20.2 22.0943C20.33 22.0943 20.46 22.0643 20.58 22.0143C20.8 21.9243 20.97 21.7643 21.08 21.5543L40.67 29.1143C41 29.2543 41.34 29.3143 41.68 29.3143C42.78 29.3143 43.82 28.6543 44.24 27.5743C44.81 26.1643 44.12 24.5543 42.69 23.9943Z"
        fill="#5D5D5D"
      />
    </svg>
  );
};
