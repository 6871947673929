import styled, { css } from 'styled-components';

interface StyleProps {
  color: 'green' | 'red';
}

export const Wrapper = styled.div<StyleProps>`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colorsV2[color]};
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text3};
  `}
`;
