import React, { Fragment, useMemo } from 'react';

import {
  TabsContentStyled,
  TabsHeaderStyled,
  TabsNameLineStyled,
  TabTitleStyled,
  TabWrapperStyled,
  TraceStyled,
} from './tabs.styled';
import { Loading } from 'src-new/components/loading/loading.component';

export interface ITabContent {
  name: string;
  content: JSX.Element;
}

interface ITabsViewProps {
  tabsContent: Array<ITabContent>;
  activeTab: ITabContent;
  setActiveTab: (tab: ITabContent) => void;
  setActiveTabName: (name: string) => void;
  icon?: JSX.Element;
}

const dataTestId = 'tabs';

const TabsView: React.FC<ITabsViewProps> = ({
  tabsContent,
  activeTab,
  setActiveTab,
  setActiveTabName,
  icon,
}: ITabsViewProps) => {
  const renderTabs = useMemo(() => {
    return (
      <TabsHeaderStyled data-testid={`${dataTestId}-header`}>
        {tabsContent.map((tab, index) => (
          <TabWrapperStyled key={index}>
            <TabTitleStyled
              data-testid={`${dataTestId}-name`}
              key={index}
              active={activeTab === tab}
              onClick={() => {
                setActiveTabName(tab.name);
                setActiveTab(tab);
              }}
            >
              {tab.name}
            </TabTitleStyled>
            <TabsNameLineStyled data-testid={`${dataTestId}-name-line`} active={activeTab === tab} />
          </TabWrapperStyled>
        ))}
      </TabsHeaderStyled>
    );
  }, [activeTab, setActiveTab, setActiveTabName, tabsContent]);

  return (
    <Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {renderTabs}
        <span style={{ cursor: 'pointer' }}>{icon}</span>
      </div>
    
      <TraceStyled data-testid={`${dataTestId}-header-line`} />

      {!activeTab?.content? <Loading />: <TabsContentStyled data-testid={`${dataTestId}-tab-content`}>{activeTab?.content}</TabsContentStyled>}
     
    </Fragment>
  );
};

export { TabsView };
