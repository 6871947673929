import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { IModalMatchesKanban, ModalMatches } from '../../types/modal-matches-kanban.types';
import { modalMatchesKanbanReducer } from '../../reducers/modal-matches-kanban/modal-matches-kanban.reducer';
import { ModalMatchesKanbanActionType } from '../../matches.action';

export const useModalMatchesKanbanContextValues = (): IModalMatchesKanban => {
  const [state, dispatch] = useReducer(modalMatchesKanbanReducer, matchesInitialState);

  const setModalMatchesOpen = useCallback((options: ModalMatches) => {
    dispatch({ type: ModalMatchesKanbanActionType.MODAL_MATCHES_KANBAN, payload: options });
  }, []);

  return {
    ...state.modalMatchesKanban,
    setModalMatchesOpen,
  };
};
