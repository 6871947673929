import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import * as S from './styled';

import CheckIcon from 'assets/checked.svg';

type AccordionContent = {
  section: string;
  content: ReactNode;
  rightText?: string;
  isButtonAction?: boolean;
  openSection?: boolean;
  buttonAction?: () => void;
  checked?: boolean;
};

interface IAccordionProps {
  contents: Array<AccordionContent>;
}

const Accordion: React.FC<IAccordionProps> = ({ contents }) => {
  const [isOpen, setIsOpen] = useState<{
    index: number;
    isOpen: boolean;
  }>();

  useEffect(() => {
    const initialOpenIndex = contents.findIndex((accordion) => accordion.openSection);

    if (initialOpenIndex !== -1) {
      setIsOpen({ index: initialOpenIndex, isOpen: true });
    }
  }, []);

  const openAccordion = useCallback((index: number) => {
    setIsOpen({ index, isOpen: true });
  }, []);

  const renderAccordions = useMemo(() => {
    const mountAccordion = contents.map((accordion, index) => {
      const accordionKey = accordion.section;

      if (accordion.section === 'Origem' && !isOpen?.isOpen) {
        openAccordion(index);
      }

      return (
        <div key={accordionKey}>
          <S.SummaryAccordion onClick={() => openAccordion(index)}>
            <S.SummaryTitleAccordion>
              {accordion.section}
              {accordion.checked && <img style={{ width: 20, height: 20 }} src={CheckIcon} alt="checked-icon" />}
            </S.SummaryTitleAccordion>

            {accordion.rightText ? (
              <S.RightText>{accordion.rightText}</S.RightText>
            ) : (
              <>
                {accordion.isButtonAction && isOpen?.index === index ? (
                  <div>
                    <SearchIcon onClick={accordion.buttonAction} sx={{ width: 18, height: 18 }} htmlColor="white" />
                  </div>
                ) : (
                  isOpen?.index !== index && <AddIcon sx={{ width: 18, height: 18 }} htmlColor="white" />
                )}
              </>
            )}
          </S.SummaryAccordion>

          <S.DetailsAccordion isOpen={isOpen?.index === index}>{accordion.content}</S.DetailsAccordion>
        </div>
      );
    });

    return mountAccordion;
  }, [isOpen, contents]);

  return <S.WrapperAccordion>{renderAccordions}</S.WrapperAccordion>;
};

export default Accordion;
