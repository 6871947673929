import { IWeighingDetailsActions } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-details.types';
import { IWeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing.types';
import { WeighingDetailsActionType } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.action';
import { weighingFormDetailsInitialValuesDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details-initial-values.domain';

export const weighingDetailsReducer = (state: IWeighingContext, action: IWeighingDetailsActions): IWeighingContext => {
  switch (action.type) {
    case WeighingDetailsActionType.WEIGHING_DETAILS_REQUEST:
      return {
        ...state,
        weighingDetails: {
          ...state.weighingDetails,
          statusRequest: 'PROCESSING',
          weighingDetails: weighingFormDetailsInitialValuesDomain,
        },
      };
    case WeighingDetailsActionType.WEIGHING_DETAILS_SUCCESS:
      return {
        ...state,
        weighingDetails: {
          ...state.weighingDetails,
          statusRequest: 'SUCCESS',
          weighingDetails: action.payload,
        },
      };
    case WeighingDetailsActionType.WEIGHING_DETAILS_ERROR:
      return {
        ...state,
        weighingDetails: {
          ...state.weighingDetails,
          statusRequest: 'ERROR',
          weighingDetails: weighingFormDetailsInitialValuesDomain,
        },
      };
    case WeighingDetailsActionType.WEIGHING_DETAILS_RESET:
      return {
        ...state,
        weighingDetails: {
          ...state.weighingDetails,
          statusRequest: 'INITIAL',
          weighingDetails: weighingFormDetailsInitialValuesDomain,
        },
      };
    default:
      return state;
  }
};
