import React from 'react';

interface IAlertTriangleLargeProps {
  color?: string;
  height?: string;
  width?: string;
}

const AlertTriangleLarge: React.FC<IAlertTriangleLargeProps> = ({ color, width, height }) => {
  return (
    <svg
      width={width ?? '22'}
      height={height ?? '20'}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67471 0.360855C10.0793 0.124282 10.5357 0 11 0C11.4643 0 11.9207 0.124282 12.3253 0.360855C12.7299 0.597427 13.0689 0.938304 13.3097 1.3506L13.3118 1.35423L21.6381 15.7913C21.8739 16.2155 21.9987 16.6966 22 17.1865C22.0013 17.6764 21.8791 18.1581 21.6455 18.5838C21.4119 19.0094 21.0751 19.3641 20.6686 19.6125C20.2621 19.861 19.8 19.9945 19.3283 19.9999L19.3202 20L2.67168 20C2.20002 19.9946 1.73794 19.861 1.33141 19.6125C0.924874 19.3641 0.588063 19.0094 0.354481 18.5838C0.1209 18.1581 -0.00131013 17.6764 1.05923e-05 17.1865C0.00133132 16.6966 0.126137 16.2156 0.362009 15.7913L0.367913 15.7807L8.68822 1.35424L8.69033 1.3506C8.93112 0.938304 9.27015 0.597427 9.67471 0.360855ZM11 1.53039C10.789 1.53039 10.5815 1.58689 10.3976 1.69442C10.2142 1.80165 10.0605 1.95601 9.95109 2.1427C9.95077 2.14324 9.95046 2.14377 9.95015 2.1443L1.63542 16.5613C1.52989 16.753 1.47408 16.9699 1.47348 17.1908C1.47288 17.4135 1.52843 17.6324 1.6346 17.8259C1.74078 18.0194 1.89387 18.1806 2.07866 18.2935C2.26241 18.4058 2.47113 18.4665 2.68427 18.4696H19.3157C19.5289 18.4665 19.7376 18.4058 19.9213 18.2935C20.1061 18.1806 20.2592 18.0194 20.3654 17.8259C20.4716 17.6324 20.5271 17.4135 20.5265 17.1908C20.5259 16.9699 20.4701 16.753 20.3646 16.5613L12.0499 2.1443C12.0495 2.14377 12.0492 2.14324 12.0489 2.1427C11.9395 1.95601 11.7858 1.80165 11.6024 1.69442C11.4185 1.58689 11.211 1.53039 11 1.53039ZM11 6.2264C11.4069 6.2264 11.7367 6.56899 11.7367 6.9916V11.0727C11.7367 11.4953 11.4069 11.8378 11 11.8378C10.5931 11.8378 10.2633 11.4953 10.2633 11.0727V6.9916C10.2633 6.56899 10.5931 6.2264 11 6.2264ZM10.2633 15.1537C10.2633 14.7311 10.5931 14.3885 11 14.3885H11.0098C11.4167 14.3885 11.7466 14.7311 11.7466 15.1537C11.7466 15.5763 11.4167 15.9189 11.0098 15.9189H11C10.5931 15.9189 10.2633 15.5763 10.2633 15.1537Z"
        fill={`${color ? color : '#424242'}`}
      />
    </svg>
  );
};

export default AlertTriangleLarge;
