import React from 'react';

import ResponsibleSolutionView from './responsible-solution-view';
interface ResponsibleSolutionParams {
  addResponsable: (values: Array<string>) => void;
  responsables?: Array<string> | [];
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
}

const ResponsibleSolution: React.FC<ResponsibleSolutionParams> = ({
  addResponsable,
  responsables = [],
  hasError,
  errorMessage,
  placeholder,
}) => {
  const handleAddResponsable = (responsable: string) => {
    if (responsable) {
      const aux = [...responsables];
      aux.push(responsable);
      addResponsable(aux);
    }
  };

  const handleRemoveResponsable = (responsable: string) => {
    const found = responsables.filter((r) => r != responsable);
    addResponsable(found);
  };

  return (
    <ResponsibleSolutionView
      responsables={Array.isArray(responsables) ? responsables : []}
      handleAddResponsable={handleAddResponsable}
      handleRemoveResponsable={handleRemoveResponsable}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={placeholder}
    />
  );
};

export default ResponsibleSolution;
