import { FC, useMemo } from 'react';
import { ChevronLeft } from 'lucide-react';
import { IBreadcrumbsView } from './breadcrumb.types';
import * as S from './breadcrumbs-view.styled';

export const BreadcrumbView: FC<IBreadcrumbsView> = ({ breadcrumb, separator, hasBackButton, handleBackScreen }) => {
  const renderBackButton = useMemo(() => {
    if (hasBackButton) {
      return (
        <S.Back onClick={handleBackScreen}>
          <ChevronLeft color="#000000" />
        </S.Back>
      );
    }
  }, [hasBackButton, handleBackScreen]);

  const renderBreadcrumb = useMemo(() => {
    const isFirstItem = 0;
    const renderSubs = breadcrumb.map((text, index) => {
      const isLastItem = index === breadcrumb.length - 1;

      if (breadcrumb.length > 1 && isLastItem) {
        return (
          <S.Wrapper key={index}>
            {renderBackButton}
            <S.Text>{breadcrumb[isFirstItem]}</S.Text>
            <S.Separator>{separator}</S.Separator>
            <S.SubText>{text}</S.SubText>
          </S.Wrapper>
        );
      }
      return null;
    });

    const renderFirstItem = <S.ListText>{breadcrumb[isFirstItem]}</S.ListText>;

    return breadcrumb.length === 1 ? renderFirstItem : renderSubs;
  }, [breadcrumb, renderBackButton, separator]);

  return <S.ContainerBreadcrumbStyled>{renderBreadcrumb}</S.ContainerBreadcrumbStyled>;
};
