import { transportReportExcelService } from 'pages/transport-company/services/transport-report-excel/transport-report-excel.service';
import { ITransportCompanyStatus } from 'domain/transport-company';

export interface ITransportReportExcelProps {
  search: string;
  status: ITransportCompanyStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: ITransportReportExcelProps): Promise<BlobPart | undefined> => {
    return await transportReportExcelService(params);
  };
};

export { useReportExcelHook };
