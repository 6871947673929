import styled, { css } from 'styled-components';

export const WrapperButton = styled.div`
  margin-top: 18px;
`;
export const WrapperInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;

    .MuiButtonBase-root {
      svg {
        width: 18px;
      }
      margin-right: 8px;
    }
  `}
`;
