import { Formik } from 'formik';
import React, { Fragment, ReactElement, useMemo } from 'react';
import { ScheduleListModals } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { ICancelAllocation, IDenyAllocation } from '../../schedule-page-body.types';
import { ModalScheduleCancel } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-page-body/components/modal-schedule-cancel/modal-schedule-cancel.component';
import { ScheduleFilter } from '../schedule-filter/schedule-filter.component';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ScheduleOccurrenceModal } from '../../../schedule-table-tabs/components/schedule-occurrence-modal/schedule-occurrence-modal.component';
import { ScheduleDuplicateModal } from '../schedule-duplicate-modal/schedule-duplicate-modal.component';
import { ScheduleDenyModal } from '../schedule-deny-modal/schedule-deny-modal.component';
import { ScheduleImportModal } from '../schedule-import-modal/schedule-import-modal.component';

interface IHeaderModalsViewProps {
  modalOptions: ScheduleListModals;
  isAdmin: boolean;
  cancelButtonLabel: 'Negar' | 'Cancelar';
  scheduleFilters: Array<IParamsFilter>;
  handleTripCancel: (params: ICancelAllocation) => void;
  handleTripDeny: (params: IDenyAllocation) => void;
  handleModalClose: () => void;
}

export const HeaderModalsView: React.FC<IHeaderModalsViewProps> = ({
  modalOptions,
  isAdmin,
  cancelButtonLabel,
  scheduleFilters,
  handleTripCancel,
  handleTripDeny,
  handleModalClose,
}) => {
  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      if (modalOptions.modalType === 'cancel') {
        return (
          <Formik
            initialValues={{ descriptionCancel: '', ids: modalOptions.ids, withCost: isAdmin }}
            onSubmit={handleTripCancel}
          >
            {({ handleSubmit }) => {
              return (
                <ModalScheduleCancel
                  subtitle="Você tem certeza que deseja cancelar essa viagem?"
                  buttonLabel={cancelButtonLabel}
                  closeModal={handleModalClose}
                  handleConfirm={handleSubmit}
                />
              );
            }}
          </Formik>
        );
      }

      if (modalOptions.modalType === 'deny') {
        return (
          <Formik initialValues={{ scheduleIds: modalOptions.ids, refusalReason: '' }} onSubmit={handleTripDeny}>
            {({ handleSubmit }) => {
              return <ScheduleDenyModal closeModal={handleModalClose} handleConfirm={handleSubmit} />;
            }}
          </Formik>
        );
      }

      if (modalOptions.modalType === 'filter') {
        return <ScheduleFilter scheduleFilters={scheduleFilters} handleModalFilter={handleModalClose} />;
      }

      if (modalOptions.modalType === 'newFreight') {
        return <ModalNewFreight handleCreateSchedulePage={handleModalClose} />;
      }

      if (modalOptions.modalType === 'occurrences') {
        return <ScheduleOccurrenceModal />;
      }

      if (modalOptions.modalType === 'duplicate') {
        return <ScheduleDuplicateModal handleModalClose={handleModalClose} />;
      }

      if (modalOptions.modalType === 'importExcel') {
        return <ScheduleImportModal handleModalClose={handleModalClose} />;
      }
    }
  }, [cancelButtonLabel, handleModalClose, handleTripCancel, handleTripDeny, isAdmin, modalOptions, scheduleFilters]);

  return <Fragment>{renderModals}</Fragment>;
};
