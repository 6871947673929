type StatusEntity = 'active' | 'inactive' | 'suspended' | 'blocked' | null;
type StatusDomain = 'ativo' | 'inativo' | 'suspenso' | 'bloqueado' | null;

export const convertStatusDomainToEntityMapper = (statusDomain: StatusDomain): StatusEntity => {
  switch (statusDomain) {
    case 'ativo':
      return 'active';
    case 'inativo':
      return 'inactive';
    case 'bloqueado':
      return 'blocked';
    case 'suspenso':
      return 'suspended';
    default:
      return null;
  }
};
