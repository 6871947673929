import { FooterPage } from 'components-v2/common/footer-page';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import MultiSelect from 'components/multi-select/multi-select';
import Select from 'components/select/select';
import { FormikErrors } from 'formik';
import { Button } from 'logshare-ui-kit';
import { ChangeEvent, FC, Fragment, useMemo } from 'react';
import SectionFormDivider from 'src-new/components/section-form-divider/section-form-divider';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { ISaleOfferModals } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-modals.types';
import { SaleOfferFormMountLocations } from './components/sale-offer-update-form-mount-locations/sale-offer-update-form-mount-locations.component';
import { SaleOfferFormMountSection } from './components/sale-offer-update-form-mount-section/sale-offer-update-form-mount-section.component';
import { SaleOfferUpdateShipperFormModal } from './components/sale-offer-update-shipper-form-modal/sale-offer-update-shipper-form-modal.component';
import { SaleOfferUpdateTariffFormModal } from './components/sale-offer-update-tariff-form-modal/sale-offer-update-tariff-form-modal.component';
import * as S from './sale-offer-update-form-mount-view.styled';
import { ISaleOfferDomain } from '../../../../domains/sale-offer-details.domain';
import { cargoTypeOptions, prohibitedCargoOptions } from '../../../../domains/sale-offer-details-initial-values.domain';
import { MultiValue } from 'react-select';
import { IOptionsDomain } from 'src-new/domains/options.domain';

interface ISaleOfferUpdateFormMountViewProps {
  values: ISaleOfferDomain;
  saleOfferModals: ISaleOfferModals;
  buttonIsLoading: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<ISaleOfferDomain>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  handleOpenOfferShipperModal: () => void;
  handleGoBackPage: () => void;
}

export const SaleOfferUpdateFormMountView: FC<ISaleOfferUpdateFormMountViewProps> = ({
  values,
  saleOfferModals,
  buttonIsLoading,
  setFieldValue,
  handleChange,
  handleOnSubmit,
  handleOpenOfferShipperModal,
  handleGoBackPage,
}) => {
  const renderOfferShipperModal = useMemo(() => {
    if (saleOfferModals.isOpen && saleOfferModals.modalType === 'offer_shipper') {
      return <SaleOfferUpdateShipperFormModal />;
    }
  }, [saleOfferModals.isOpen, saleOfferModals.modalType]);

  const renderOfferTariffModal = useMemo(() => {
    if (saleOfferModals.isOpen && saleOfferModals.modalType === 'offer_shipper_tariff') {
      return <SaleOfferUpdateTariffFormModal />;
    }
  }, [saleOfferModals.isOpen, saleOfferModals.modalType]);

  return (
    <Fragment>
      {renderOfferShipperModal}
      {renderOfferTariffModal}
      <S.WrapperForm>
        <SectionForm>
          <LineSectionLayout columns={'0.48fr 0.5fr 3fr 1fr'}>
            <InputField
              label="ID da Oferta"
              id="id"
              name="id"
              type="text"
              onChange={handleChange}
              value={values.id}
              disabled
            />

            <Select
              label="Tipo de Carga *"
              id="cargoTypet"
              name="cargoTypet"
              value={values.cargoType}
              setFieldValue={(field, value) => setFieldValue(field, value)}
              options={cargoTypeOptions}
              disabled
            />

            <MultiSelect
              label="Cargas Não Permitidas"
              options={prohibitedCargoOptions}
              menuPosition="fixed"
              onChange={(event: MultiValue<IOptionsDomain>) =>
                setFieldValue(
                  'prohibitedCargo',
                  event.map((option: IOptionsDomain) => option.value),
                )
              }
              values={prohibitedCargoOptions.filter((option) => values.prohibitedCargo?.includes(option.value))}
            />

            <InputField
              label="Outros"
              id="prohibitedOtherCargo"
              name="prohibitedOtherCargo"
              type="text"
              value={values.prohibitedOtherCargo}
              onChange={handleChange}
              disabled={!values.prohibitedCargo?.includes('Outros')}
            />
          </LineSectionLayout>

          <SectionFormDivider label={'Origem'} hidePinLocation={false} />
          <SaleOfferFormMountLocations type={'origin'} values={values} setFieldValue={setFieldValue} />

          <SectionFormDivider label={'Destino'} hidePinLocation={false} />
          <SaleOfferFormMountLocations type={'destination'} values={values} setFieldValue={setFieldValue} />

          <SectionFormDivider
            label={'Detalhes da Oferta'}
            secondItem={
              <Button
                label="Adicionar Parceiro"
                color="green"
                onPress={handleOpenOfferShipperModal}
                size="md"
                variant="solid"
              />
            }
          />
          <SaleOfferFormMountSection />
        </SectionForm>

        <FooterPage.Root withoutTrace>
          <FooterPage.RightContent>
            <FooterPage.Button
              label="Salvar"
              color="pink"
              isLoading={false}
              onPress={handleOnSubmit}
              disabled={buttonIsLoading}
            />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBackPage} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </S.WrapperForm>
    </Fragment>
  );
};
