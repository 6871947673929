import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { IValidateDates } from 'src-new/utils/validate-dates/validate-dates.types';
import { DetailsBackhaulPurchaseFormView } from './details-backhaul-purchase-form-view.component';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { formatAndCalcDate } from 'src-new/utils/format-date';

const HOURS_IN_A_DAY = 24;

interface IDetailsBackhaulPurchaseFormProps {
  datesError: Array<IValidateDates>;
  handleDatesError: (dates: Array<IValidateDates>) => void;
}

export const DetailsBackhaulPurchaseForm: React.FC<IDetailsBackhaulPurchaseFormProps> = ({
  datesError,
  handleDatesError,
}) => {
  const { values, setFieldValue } = useFormikContext<IModalBackhaulPurchaseForm>();

  const handleAddDate = useCallback(
    (initialValues: IValidateDates) => setFieldValue('dates', [...values['dates'], initialValues]),
    [values, setFieldValue],
  );

  const handleRemoveDate = useCallback(
    (index: number) => {
      const updateDates = values['dates'].filter((_, i) => i !== index);
      setFieldValue('dates', updateDates);
    },
    [values, setFieldValue],
  );

  const getMinDate = useCallback((date: string): dayjs.Dayjs => (dayjs(date).isValid() ? dayjs(date) : dayjs()), []);

  const calcLeadtimeDate = useCallback(
    (date: string): dayjs.Dayjs => {
      if (values.leadTime > 0) {
        const leadTimeHours = values.leadTime * HOURS_IN_A_DAY;

        const minDate = formatAndCalcDate(date, 'add', leadTimeHours);

        if (dayjs(minDate, 'DD/MM/YYYY HH:mm').isValid()) {
          const isoDate = dayjs(minDate, 'DD/MM/YYYY HH:mm').toISOString();
          return getMinDate(isoDate);
        }
      }

      return getMinDate(date);
    },
    [getMinDate, values.leadTime],
  );

  const handleFieldDateChange = useCallback(
    (fieldName: string, date: dayjs.Dayjs, index: number) => {
      setFieldValue(`dates[${index}].${fieldName}`, date?.toISOString());

      const leadTimeDate = calcLeadtimeDate(date.toISOString());

      switch (fieldName) {
        case 'initialLevyDate': {
          setFieldValue(`dates[${index}].finalLevyDate`, date.toISOString());
          setFieldValue(`dates[${index}].initialDeliveryDate`, leadTimeDate.toISOString());
          setFieldValue(`dates[${index}].finalDeliveryDate`, leadTimeDate.toISOString());
          const updatedDate = [...(datesError ?? [])];
          updatedDate[index] = {
            ...datesError[index],
            initialLevyDate: '',
            finalLevyDate: '',
          };

          handleDatesError(updatedDate);
          break;
        }
        case 'initialDeliveryDate': {
          setFieldValue(`dates[${index}].finalDeliveryDate`, dayjs(date).toISOString());
          const updatedDate = [...(datesError ?? [])];
          updatedDate[index] = {
            ...datesError[index],
            initialDeliveryDate: '',
            finalDeliveryDate: '',
          };

          handleDatesError(updatedDate);
          break;
        }
        case 'finalLevyDate': {
          setFieldValue(`dates[${index}].initialDeliveryDate`, leadTimeDate.toISOString());
          setFieldValue(`dates[${index}].finalDeliveryDate`, leadTimeDate.toISOString());
          const updatedDate = [...(datesError ?? [])];
          updatedDate[index] = {
            ...datesError[index],
            finalLevyDate: '',
          };

          handleDatesError(updatedDate);
          break;
        }
        default: {
          const updatedDate = [...(datesError ?? [])];
          updatedDate[index] = {
            ...datesError[index],
            finalDeliveryDate: '',
          };

          handleDatesError(updatedDate);
          break;
        }
      }
    },
    [calcLeadtimeDate, datesError, handleDatesError, setFieldValue],
  );

  const getMinDateForInitialDate = useCallback((): dayjs.Dayjs => {
    const today = dayjs();
    const dayOfWeek = today.day();

    switch (dayOfWeek) {
      case 6:
        return today.add(6, 'hours');
      case 0:
        return today.add(6, 'hours');
      default:
        return today.add(6, 'hours');
    }
  }, []);

  const calcMinDate = useCallback(
    (inputName: string, index: number): dayjs.Dayjs => {
      switch (inputName) {
        case 'initialLevyDate':
          return getMinDateForInitialDate();
        case 'finalLevyDate':
          return getMinDate(values.dates[index].initialLevyDate);
        case 'initialDeliveryDate':
          return calcLeadtimeDate(values.dates[index].finalLevyDate);
        default:
          return getMinDate(values.dates[index].initialDeliveryDate);
      }
    },
    [calcLeadtimeDate, getMinDate, getMinDateForInitialDate, values.dates],
  );

  return (
    <DetailsBackhaulPurchaseFormView
      dates={values.dates}
      datesError={datesError}
      handleAddDate={handleAddDate}
      handleRemoveDate={handleRemoveDate}
      handleFieldDateChange={handleFieldDateChange}
      calcMinDate={calcMinDate}
    />
  );
};
