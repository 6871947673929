import React from 'react';

import UserPermissionsView from './user-permissions-view';

import { Permission } from 'domain/user';

interface UserSubPermissions {
  subPermissions: Permission[];
  handleChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const UserPermissions: React.FC<UserSubPermissions> = ({ subPermissions, handleChecked }) => {
  return <UserPermissionsView subPermissions={subPermissions} handleChecked={handleChecked} />;
};

export default UserPermissions;
