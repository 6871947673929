import styled from 'styled-components';
import { Form } from 'formik';

export const WrapperForm = styled(Form)``;

export const WrapperInput = styled.div`
  margin-top: -3px;
`;

export const WrapperDriverAndVehicleSection = styled.section`
  display: grid;
  width: 100%;
  gap: 1rem;
`;

export const DateTimePikers = styled.div`
  display: flex;
  align-items: flex-end;
`;
