import React, { memo, useMemo } from 'react';

import { ContainerTableStyled } from './styled';

import Loading from 'components-v2/common/loading/index';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';

interface IBackhaulTableProps {
  contentTable: Array<IContentTable>;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: () => void;
}

const BackhaulTable: React.FC<IBackhaulTableProps> = ({
  contentTable,
  isLoading,
  orderSelected,
  clickOrderTableBy,
  clickSortDirection,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0,
      },
      {
        value: 'Oferta',
        flex: 0.7,
      },
      {
        value: 'Tipo',
        flex: 0.8,
      },
      {
        value: 'Origem / Destino',
        flex: 1.5,
      },
      {
        value: 'Veiculo',
        flex: 1.13,
      },

      {
        value: 'Capacidade',
        flex: 1,
      },

      {
        value: 'Classificação',
        flex: 1.8,
      },

      {
        value: 'Match Potencial',
        flex: 1.25,
      },

      {
        value: 'Frequência',
        flex: 1.2,
      },

      {
        value: 'Frete Valor',
        flex: 1,
      },
      {
        value: '',
        flex: 1.25,
      },
    ];
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );

  return (
    <ContainerTableStyled>
      <Table
        orderSelected={orderSelected}
        header={headerTable}
        content={contentTable}
        isLoading={false}
        clickOrderBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
      />
    </ContainerTableStyled>
  );
};

export default memo(BackhaulTable);
