import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { chatInitialState } from './chat.constants';
import { IChatContext } from './types/chat.types';
import { chatReducer } from 'src-new/contexts/chat/reducers/chat.reducer';
import { IChatMessageQuantity } from 'src-new/contexts/chat/types/chat-message-quantity.types';
import { useChatMessageQuantityContextValues } from 'src-new/contexts/chat/context-values/chat-message-quantity/chat-message-quantity-values.context';
import { useChatConversationListContextValues } from 'src-new/contexts/chat/context-values/chat-conversation-list/chat-conversation-list-values.context';
import { IChatConversationList } from 'src-new/contexts/chat/types/chat-conversation-list.types';
import { useChatEligibleUsersValuesContextValues } from 'src-new/contexts/chat/context-values/chat-eligible-users/chat-eligible-users-values.context';
import { IChatEligibleUsers } from 'src-new/contexts/chat/types/chat-eligible-users-chat.types';
import { useChatUnreadMessagesContextValues } from 'src-new/contexts/chat/context-values/chat-unread-messages/chat-unread-messages-values.context';
import { IChatUnreadMessages } from 'src-new/contexts/chat/types/chat-unread-messages.types';
import { useChatUserEmailsContextValues } from 'src-new/contexts/chat/context-values/chat-user-emails/chat-user-emails-values.context';
import { IChatUserEmails } from 'src-new/contexts/chat/types/chat-user-emails.types';

export const ChatContext = createContext<IChatContext>(chatInitialState);

interface IChatContextProps {
  children: ReactNode;
}

export const ChatContextProvider = ({ children }: IChatContextProps) => {
  const [state] = useReducer(chatReducer, chatInitialState);

  const chatConversationList: IChatConversationList = useChatConversationListContextValues();
  const chatMessageQuantity: IChatMessageQuantity = useChatMessageQuantityContextValues();
  const chatEligibleUsers: IChatEligibleUsers = useChatEligibleUsersValuesContextValues();
  const chatUnreadMessages: IChatUnreadMessages = useChatUnreadMessagesContextValues();
  const chatUserEmails: IChatUserEmails = useChatUserEmailsContextValues();

  const value: IChatContext = useMemo(
    (): IChatContext => ({
      ...state,
      chatConversationList,
      chatMessageQuantity,
      chatEligibleUsers,
      chatUnreadMessages,
      chatUserEmails,
    }),
    [chatConversationList, chatEligibleUsers, chatMessageQuantity, chatUnreadMessages, chatUserEmails, state],
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
