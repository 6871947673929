import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tag } from 'logshare-ui-kit';
import { Formik } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import * as S from '../styled';

import { formatDateTime } from 'utils-v2/converters/date';
import { convertChecklistStatus } from 'utils-v2/converters/checklist-status';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { mapAnswers } from 'services/checklist/mappers';
import { getChecklistQuestionsService } from 'services/checklist/get-checklist-questions';
import { getChecklistDetailsService } from 'services/checklist/get-checklist-details';
import { getChecklistAnswersService } from 'services/checklist/get-checklist-answers';
import { deleteChecklistDetailsService } from 'services/checklist/delete-checklist-details';
import { createChecklistDetailsService } from 'services/checklist/create-checklist-details';
import { ChecklistAnswer, ChecklistDetails, answerInitialValues } from 'domain-v2/checklist';
import { ScheduleDomain } from 'domain/schedule';
import { ModalFormChecklist } from 'components-v2/common/modals/checklist';
import Loading from 'components-v2/common/loading';
import { ModalForm } from 'components/modal-form/modal-form';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Button from 'components/button/button';

interface IProps {
  schedule: ScheduleDomain;
}

const FreightChecklistSection: React.FC<IProps> = ({ schedule }) => {
  const reactQueryClient = useQueryClient();

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const [openModal, setOpenModal] = useState<{ open: boolean; isEdit: boolean }>({ open: false, isEdit: false });
  const [openModalLink, setOpenModalLink] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<{ open: boolean; id: number }>({ open: false, id: 0 });
  const [checklistAnswers, setChecklistAnswers] = useState<ChecklistAnswer>(answerInitialValues);
  const [checklistDetails, setChecklistDetails] = useState<ChecklistDetails[]>();

  const { isLoading, data } = useQuery({
    queryKey: ['checklistDetails', schedule.id],
    queryFn: () => getChecklistDetailsService(Number(schedule.id ?? 0), 'schedule'),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    (async () => {
      await reactQueryClient.invalidateQueries(['checklistDetails', schedule.id]);
    })();
  }, [schedule.id, reactQueryClient]);

  useEffect(() => {
    if (!data) return;

    setChecklistDetails(data);
  }, [data]);

  const handleGetQuestions = useCallback(async () => {
    try {
      const data = await getChecklistQuestionsService('schedule', 'vehicle-checklist');

      const answerValues = mapAnswers(data, answerInitialValues);

      setChecklistAnswers({ ...answerValues, checklistModule: 'schedule' });
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, []);

  const handleGetAnswers = useCallback(async (detailsId: number) => {
    try {
      const data = await getChecklistAnswersService(detailsId);

      setChecklistAnswers(data);
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, []);

  const handleSubmit = useCallback(
    async (checklistAnswer: ChecklistAnswer) => {
      try {
        await createChecklistDetailsService(schedule.id, checklistAnswer);

        showMessageFormatted({
          message: 'Checklist preenchido com sucesso.',
          type: 'success',
        });

        await reactQueryClient.invalidateQueries(['checklistDetails']);
      } catch (error: any) {
        showMessageFormatted({
          error: error,
          type: 'error',
        });
      }
      setOpenModal({ open: false, isEdit: false });
    },
    [schedule.id, reactQueryClient],
  );

  const handleDelete = useCallback(async () => {
    try {
      await deleteChecklistDetailsService(openDeleteModal.id);

      showMessageFormatted({
        message: 'Checklist excluído com sucesso.',
        type: 'success',
      });

      await reactQueryClient.invalidateQueries(['checklistDetails']);

      setOpenDeleteModal({ open: false, id: 0 });
    } catch (error: any) {
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, [openDeleteModal, reactQueryClient]);

  const handleCopyLink = useCallback(() => {
    if (!linkRef.current) return;

    const range = document.createRange();
    range.selectNode(linkRef.current);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand('copy');

        showMessageFormatted({
          message: 'Link copiado!',
          type: 'success',
        });

        setOpenModalLink(false);
      } catch (error) {
        showMessageFormatted({
          message: 'Erro ao copiar link',
          type: 'error',
        });
      } finally {
        selection.removeAllRanges();
      }
    }
  }, [linkRef]);

  const renderChecklistDetails = useMemo(() => {
    if (!checklistDetails) return;

    return checklistDetails.map((details) => {
      return (
        <S.WrapperInfo key={details.id}>
          <S.WrapperValues columns="1fr 1fr 1fr 1fr 0.5fr">
            <S.Info>{details.name}</S.Info>

            <S.Info>{formatDateTime(details.createdAt)}</S.Info>

            <S.Info>{!!details.responsible && details.responsible.length > 0 ? details.responsible : '-'}</S.Info>

            <S.Info>
              <Tag
                label={convertChecklistStatus(details.status).label}
                color={convertChecklistStatus(details.status).color}
                display="inline-flex"
                size="md"
                variant="solid"
              />
            </S.Info>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'flex-end' }}>
              <Button
                title="Visualizar"
                callback={async () => {
                  await handleGetAnswers(details.id);
                  setOpenModal({ open: true, isEdit: true });
                }}
                bgColor="white"
                icon={<SearchIcon />}
                size="very-small"
              />
              <Button
                title="Deletar"
                callback={() => setOpenDeleteModal({ open: true, id: details.id })}
                bgColor="pink"
                icon={<DeleteIcon />}
                size="very-small"
              />
            </div>
          </S.WrapperValues>
        </S.WrapperInfo>
      );
    });
  }, [checklistDetails, handleGetAnswers]);

  const mobileLink = useMemo(() => {
    return `${import.meta.env.VITE_LINK}checklist/${checklistAnswers.moduleId}/${schedule.id}`;
  }, [checklistAnswers, schedule.id]);

  return (
    <>
      {openModal.open && (
        <ModalForm
          title="Checklist"
          description={
            <Formik initialValues={checklistAnswers} onSubmit={handleSubmit}>
              <ModalFormChecklist isEdit={openModal.isEdit} />
            </Formik>
          }
          handleCancel={() => {
            setOpenModal({ open: false, isEdit: false });
          }}
          size="large"
        />
      )}
      {openDeleteModal.open && (
        <ModalConfirmation
          secondTitle={
            <>
              <p>Tem certeza que deseja excluir o Checklist?</p>
              <p>Essa ação não poderá ser desfeita.</p>
            </>
          }
          title="Exclusão de Checklist"
          size="small"
          type="delete"
          handleCancel={() => setOpenDeleteModal({ open: false, id: 0 })}
          onConfirmation={handleDelete}
          description={undefined}
        />
      )}
      {openModalLink && (
        <ModalConfirmation
          title="Link Gerado"
          description={
            <S.InfoLink ref={linkRef} onClick={handleCopyLink}>
              {mobileLink}
            </S.InfoLink>
          }
          size="small"
          type="normal"
          buttonTitleConfirmation="Copiar"
          handleXCancel={() => setOpenModalLink(false)}
          onConfirmation={handleCopyLink}
        />
      )}
      <S.WrapperSection>
        <S.WrapperHeader>
          <S.HeaderTitle>Checklist</S.HeaderTitle>
          <S.WrapperButton>
            <Button
              title="Criar checklist para o celular"
              bgColor="blue"
              callback={async () => {
                await handleGetQuestions();
                setOpenModalLink(true);
              }}
              size="very-small"
              icon={<PhoneAndroidIcon />}
            />
            <Button
              title="Novo"
              size="very-small"
              icon={<AddIcon />}
              callback={async () => {
                await handleGetQuestions();
                setOpenModal({ open: true, isEdit: false });
              }}
              bgColor="aqua"
            />
          </S.WrapperButton>
        </S.WrapperHeader>

        <S.WrapperInfo>
          <S.WrapperValues columns="1fr 1fr 1fr 1fr 0.5fr">
            <S.InfoTitle>Nome</S.InfoTitle>
            <S.InfoTitle>Data de preenchimento</S.InfoTitle>
            <S.InfoTitle>Responsável</S.InfoTitle>
            <S.InfoTitle>Status</S.InfoTitle>
          </S.WrapperValues>
        </S.WrapperInfo>

        {isLoading ? (
          <div style={{ width: 0, margin: '0 auto' }}>
            <Loading />
          </div>
        ) : (
          renderChecklistDetails
        )}
      </S.WrapperSection>
    </>
  );
};

export default React.memo(FreightChecklistSection);
