import API from 'utils/API/API';
import { IDashboard } from 'domain/dashboard';

interface IDasboardProps {
  startDate?: string;
  endDate?: string;
  companyId?: number;
  origin?: string;
  destiny?: string;
  shippingCompanyId?: string;
}

export const dashboardDetailService = async (params: IDasboardProps): Promise<IDashboard> => {
  const response = await API.get<IDashboard>(
    `v2/dashboard?startDate=${params.startDate}&endDate=${params.endDate}&companyId=${params.companyId}&origin=${params.origin}&destiny=${params.destiny}&shippingCompanyId=${params.shippingCompanyId}`,
  );

  return response.data;
};
