import { FC, useCallback } from 'react';
import { ModalBackhaulPurchaseView } from './modal-backhaul-purchase-view.component';
import { useSendBackhaulPurchase } from 'src-new/hooks/use-send-backhaul-purchase/use-send-backhaul-purchase.hook';
import { IModalBackhaulPurchaseForm } from './types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from './types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface IModalBackhaulPurchaseProps {
  backhaulPurchase: IBackhaulPurchase;
  handleClose: () => void;
}

export const ModalBackhaulPurchase: FC<IModalBackhaulPurchaseProps> = ({ backhaulPurchase, handleClose }) => {
  const sendBackhaulPurchaseHook = useSendBackhaulPurchase();

  const handleSubmit = useCallback(
    async (values: IModalBackhaulPurchaseForm) => {
      await sendBackhaulPurchaseHook(values, backhaulPurchase);

      handleClose();
    },
    [backhaulPurchase, handleClose, sendBackhaulPurchaseHook],
  );

  return (
    <ModalBackhaulPurchaseView
      backhaulPurchase={backhaulPurchase}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};
