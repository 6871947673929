import React, { ReactNode, useMemo } from 'react';

import FreightTableView from './table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IFreightTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToDetailsPage: (freightId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  clickOrderBy?: (type: string) => void;
  clickSortDirection?: () => void;
}

const FinancialTable: React.FC<IFreightTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToDetailsPage,
  clickOrderBy,
  clickSortDirection,
  isLoading,
  arrowOrder,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.05,
      },
      {
        value: 'ID Fatura',
        flex: 0.2,
      },

      {
        value: 'Pagador',
        flex: 0.25,
      },

      {
        value: 'Recebedor',
        flex: 0.25,
      },

      {
        value: 'Data da Emissão',
        flex: 0.2,
      },

      {
        value: 'Data de Vencto',
        flex: 0.2,
      },
      {
        value: 'Total do Serviço',
        flex: 0.2,
      },
      {
        value: 'Status',
        flex: 0.2,
      },
      {
        value: 'Fatura',
        flex: 0.1,
      },
      {
        value: 'NFe',
        flex: 0.1,
      },
      {
        value: 'Boleto',
        flex: 0.1,
      },
    ];
  }, []);

  return (
    <FreightTableView
      clickOrderBy={clickOrderBy}
      clickSortDirection={clickSortDirection}
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      isLoading={isLoading}
      clickRowAction={goToDetailsPage}
      indexColumnId={1}
    />
  );
};

export default FinancialTable;
