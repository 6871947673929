import { IContentTable } from 'components/table/table.types';

export const headerDriverContent = (): IContentTable => {
  return [
    {
      value: '',
      flex: 0,
    },
    {
      value: 'ID',
      flex: 0.2,
    },
    {
      value: 'Nome',
    },
    {
      value: 'CPF',
    },
  ];
};
