import React, { useContext, useEffect } from 'react';
import LoginBody from 'pages/login/components/login-body/login-body';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';

const Login = () => {
  const { setChatReset, statusRequest } = useContext(ChatButtonContext);

  useEffect(() => {
    if (statusRequest !== 'INITIAL') {
      setChatReset();
    }
  }, [statusRequest, setChatReset]);

  return <LoginBody />;
};

export default Login;
