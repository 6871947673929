import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: ${theme.height.fullView};
    width: ${theme.width.fullView};
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: absolute;
    padding: 20px;
    background-color: ${theme.modals.background};
    color: ${theme.colorsV2.black};
    z-index: 1;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 565px;
    width: 970px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    padding: 25px;
    overflow-y: auto;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const HeaderTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text2};
    font-weight: 700;
  `}
`;

export const HeaderTag = styled.div`
  display: flex;
  gap: 5px;
`;

export const ButtonCloseContainer = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  cursor: pointer;
`;

export const CloseModal = styled.div`
  font-size: 15px;
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperLocations = styled.div`
  height: 92%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 0 0 25%;
`;

export const ContainerLocations = styled.div`
  display: flex;
`;

export const ContainerConnector = styled.div`
  margin-top: 3px;
`;

export const StepIcon = styled.div`
  height: 9px;
  width: 9px;
  background-color: #d9d9d9;
  color: #fff;
  border-radius: 50%;
`;

interface IConnectorProps {
  height: number;
}
export const Connector = styled.div<IConnectorProps>`
  ${({ height }) => css`
    height: ${height}px;
    width: 2px;
    margin-left: 4px;
    background-color: #d9d9d9;
  `}
`;

export const ContainerOriginAndDestination = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 5px;
`;

interface ITitleProps {
  isBold?: boolean;
  withoutPadding?: boolean;
}
export const Title = styled.p<ITitleProps>`
  ${({ theme, isBold, withoutPadding }) => css`
    font-size: ${isBold ? theme.fontV2.sizes.text3 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 700 : 600};
    color: ${theme.colorsV2.black};
    padding-bottom: ${withoutPadding ? 0 : 16}px;
    text-transform: uppercase;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerVehicle = styled.div`
  width: 220px;
  margin-left: 17px;
`;

export const ContainerPaymentTerm = styled.div`
  margin-left: 17px;
`;

export const WrapperDetails = styled.div`
  height: 92%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 0 0 33%;
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    display: flex;
    gap: 5px;
    font-size: ${theme.fontV2.sizes.text4};
    text-transform: uppercase;
  `}
`;

export const WrapperFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaleFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const TermsText = styled.p`
  ${({ theme }) => css`
    display: inline;
    align-items: center;
    flex-direction: column;
    font-size: ${theme.fontV2.sizes.text5};
    padding-left: 10px;
    text-transform: uppercase;
  `}
`;

export const CheckboxSection = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const LinkText = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const Div = styled.div``;
