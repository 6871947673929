import { useCallback } from 'react';

import { sendEmailAllocation } from 'pages/freight/services/send-email-allocation/send-email-allocation.service';

export const UseSendEmailAllocation = () => {
  return useCallback(async (id: number, destinyEmail: string) => {
    const sendEmail = await sendEmailAllocation(id, destinyEmail);
    return sendEmail;
  }, []);
};
