import React, { ReactElement, useMemo } from 'react';
import * as S from './schedule-occurrence-modal-view.styled';
import { IScheduleOccurrenceListDomain } from '../../../../domains/schedule-occurrence-list/schedule-occurrence-list.domain';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import { convertOccurrenceTag } from 'src-new/pages/execution/pages/schedule/utils/convert-occurrence-tag/convert-occurrence-tag.util';
import { Tag } from 'src-new/components/tag/tag.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';

interface IScheduleOccurrenceModalViewProps {
  occurrenceList: Array<IScheduleOccurrenceListDomain>;
  isLoading: boolean;
  handleClose: () => void;
  goToUpdatePage: (occurrenceId: number) => void;
}

export const ScheduleOccurrenceModalView: React.FC<IScheduleOccurrenceModalViewProps> = ({
  occurrenceList,
  isLoading,
  handleClose,
  goToUpdatePage,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID' },
      { value: 'Frete ID' },
      { value: 'Impacto' },
      { value: 'Tipo' },
      { value: 'Cliente' },
      { value: 'Data Abertura' },
      { value: 'Data Fechamento' },
      { value: 'Status' },
    ];

    const columnsConfig = '0.25fr 0.4fr 0.5fr 1fr 1.2fr 0.6fr 0.8fr 0.5fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return occurrenceList.map((occurrence) => {
      return {
        key: occurrence.id,
        onClick: () => goToUpdatePage(occurrence.id),
        valuesColumns: [
          {
            value: <S.Title>{occurrence.id}</S.Title>,
          },
          {
            value: <S.Title>{occurrence.scheduleId}</S.Title>,
          },
          {
            value: (
              <div style={{ display: 'flex', gap: 10 }}>
                <Tag
                  label={convertOccurrenceTag(occurrence.impact).label}
                  color={convertOccurrenceTag(occurrence.impact).color}
                  variant="solid"
                  display="flex"
                  size="md"
                />
              </div>
            ),
          },
          {
            value: <S.Title>{occurrence.occurrenceType}</S.Title>,
          },
          {
            value: <S.Title>{occurrence.client}</S.Title>,
          },
          {
            value: <S.Title>{occurrence.initialDate}</S.Title>,
          },
          {
            value: <S.Title>{occurrence.endDate}</S.Title>,
          },
          {
            value: <S.Title>{occurrence.status}</S.Title>,
          },
        ],
      };
    });
  }, [goToUpdatePage, occurrenceList]);

  const renderContent = useMemo((): ReactElement => {
    if (isLoading) return <Loading />;

    if (!occurrenceList.length) return <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />;

    return (
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={renderRows}
        height="300px"
      />
    );
  }, [isLoading, occurrenceList.length, renderRows, tableColumns.columnsConfig, tableColumns.headerColumns]);

  return (
    <ModalBark handleClose={handleClose} size="md" title="Ocorrências">
      <S.Wrapper>{renderContent}</S.Wrapper>
    </ModalBark>
  );
};
