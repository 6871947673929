import { createContext, useMemo, useReducer } from 'react';
import { purchaseOfferReducer } from './reducers/purchase-offer.reducer';
import { purchaseOfferInitialState } from './purchase-offer.constants';
import { IPurchaseOfferContextProps } from './purchase-offer.types';
import { IPurchaseOfferList } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list.types';
import { IPurchaseOfferMatchList } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-match-list.types';
import { IPurchaseOfferModals } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-modals.types';
import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { usePurchaseOfferModalContextValues } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/context-values/purchase-offer-modals/purchase-offer-modals-values.context';
import { usePurchaseOfferListContextValues } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/context-values/purchase-offer-list/purchase-offer-list-values.context';
import { usePurchaseOfferMatchListContextValues } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/context-values/purchase-offer-match-list/purchase-offer-match-list-values.context';
import { usePurchaseOfferBaitRouteContextValues } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/context-values/purchase-offer-bait-route/purchase-offer-bait-route-values.context';
import { IPurchaseOfferBaitRoute } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-bait-route.types';
import { IPurchaseOfferListDownloadReport } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list-download-report.types';
import { usePurchaseOfferListDownloadReportContextValues } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/context-values/purchase-offer-list-download-report/purchase-offer-list-download-report-values.context';

export const PurchaseOfferContext = createContext<IPurchaseOfferContext>(purchaseOfferInitialState);

export const PurchaseOfferContextProvider = ({ children }: IPurchaseOfferContextProps) => {
  const [state] = useReducer(purchaseOfferReducer, purchaseOfferInitialState);

  const purchaseOfferModals: IPurchaseOfferModals = usePurchaseOfferModalContextValues();
  const purchaseOfferList: IPurchaseOfferList = usePurchaseOfferListContextValues();
  const purchaseOfferMatchList: IPurchaseOfferMatchList = usePurchaseOfferMatchListContextValues();
  const purchaseOfferListDownloadReport: IPurchaseOfferListDownloadReport =
    usePurchaseOfferListDownloadReportContextValues();
  const purchaseOfferBaitRoute: IPurchaseOfferBaitRoute = usePurchaseOfferBaitRouteContextValues();

  const value: IPurchaseOfferContext = useMemo(
    (): IPurchaseOfferContext => ({
      ...state,
      purchaseOfferModals,
      purchaseOfferList,
      purchaseOfferMatchList,
      purchaseOfferListDownloadReport,
      purchaseOfferBaitRoute,
    }),
    [
      purchaseOfferBaitRoute,
      purchaseOfferList,
      purchaseOfferListDownloadReport,
      purchaseOfferMatchList,
      purchaseOfferModals,
      state,
    ],
  );

  return <PurchaseOfferContext.Provider value={value}>{children}</PurchaseOfferContext.Provider>;
};
