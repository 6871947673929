import React, { ReactElement, useMemo } from 'react';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ScheduleRefusedListModals } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/types/schedule-refused-list.types';

interface IHeaderModalsViewProps {
  modalOptions: ScheduleRefusedListModals;
  handleModalClose: () => void;
}

export const HeaderModalsView: React.FC<IHeaderModalsViewProps> = ({ modalOptions, handleModalClose }) => {
  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      if (modalOptions.modalType === 'newFreight') {
        return <ModalNewFreight handleCreateSchedulePage={handleModalClose} />;
      }
    }
  }, [handleModalClose, modalOptions]);

  return renderModals;
};
