import API from 'utils/API/API';

export const updateReofferTripService = async (
  scheduleId: number,
  shippingCompanyId: number,
  scheduleRefusedId?: number,
) => {
  const { data } = await API.post('v2/schedules/reoffer/denied/trips', {
    scheduleId,
    shippingCompanyId,
    scheduleRefusedId,
  });
  return data;
};
