import { ColorsV2Type } from 'styles/types/colors';
import { ChecklistStatusType } from 'domain-v2/checklist';

type ChecklistStatus = {
  label: ChecklistStatusType;
  color: ColorsV2Type;
};

type ChecklistStatusMap = {
  [key in ChecklistStatusType]: ChecklistStatus;
};

const defaultValue: ChecklistStatus = {
  label: 'Reprovado',
  color: ColorsV2Type.DarkGray,
};

export const convertChecklistStatus = (checklistStatus: ChecklistStatusType): ChecklistStatus => {
  const checklistStatusMap: ChecklistStatusMap = {
    Aprovado: {
      label: 'Aprovado',
      color: ColorsV2Type.Aqua,
    },
    Reprovado: {
      label: 'Reprovado',
      color: ColorsV2Type.Red,
    },
    'Aprovado com Restrição': {
      label: 'Aprovado com Restrição',
      color: ColorsV2Type.Blue,
    },
    '': {
      label: '',
      color: ColorsV2Type.Blue,
    },
  };

  return checklistStatusMap[checklistStatus] || defaultValue;
};
