import React from 'react';
import * as S from './attach-documents-modal-fields-view.styled';
import {
  DateTimeFieldContainerStyled,
  EmptySpinnerContainer,
  GroupFieldsContainerStyled,
  InputFieldContainerStyled,
  InputSelectFieldContainerStyled,
} from './attach-documents-modal-fields-view.styled';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import dayjs from 'dayjs';
import { documentTypeOptions } from './attach-documents-modal-fields.constants';
import { InputFieldFormik } from 'src-new/components/formik/input-field/input-field-formik.component';
import { InputSelectFormik } from 'src-new/components/formik/input-select/input-select-formik.component';
import { InputDateTimePikersFormik } from 'src-new/components/formik/input-date-time-pikers/input-date-time-pikers-formik.component';
import { FormikErrors } from 'formik';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IAttachDocumentsModalFieldsViewProps {
  document: IAttachmentDomain;
  hasErrorField: (name: string) => boolean;
  getErrorMessage: (name: string) => string;
  position: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputSelectChange: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<RegistrationAttachmentValues>>;
  handleInputDateTimeChange: (date?: string) => void;
}

export const AttachDocumentsModalFieldsView: React.FC<IAttachDocumentsModalFieldsViewProps> = ({
  document,
  getErrorMessage,
  hasErrorField,
  position,
  handleChange,
  handleInputSelectChange,
  handleInputDateTimeChange,
}) => {
  if (!document) return <EmptySpinnerContainer />;

  return (
    <S.Wrapper>
      <InputFieldContainerStyled>
        <InputFieldFormik
          label="Nome do Arquivo"
          id={`attachments[${position}].fileName`}
          name={`attachments[${position}].fileName`}
          type="text"
          value={document.fileName}
          onChange={handleChange}
          hasError={hasErrorField(`attachments[${position}].fileName`)}
          errorMessage={getErrorMessage(`attachments[${position}].fileName`)}
          placeholder=""
        />
      </InputFieldContainerStyled>
      <GroupFieldsContainerStyled>
        <InputSelectFieldContainerStyled>
          <InputSelectFormik
            label="Tipo do Anexo"
            id={`attachments[${position}].attachDocumentType`}
            name={`attachments[${position}].attachDocumentType`}
            value={document.attachDocumentType}
            setFieldValue={handleInputSelectChange}
            options={documentTypeOptions}
            hasError={hasErrorField(`attachments[${position}].attachDocumentType`)}
            errorMessage={getErrorMessage(`attachments[${position}].attachDocumentType`)}
            placeholder=""
          />
        </InputSelectFieldContainerStyled>
        <DateTimeFieldContainerStyled>
          <InputDateTimePikersFormik
            label="Validade"
            format="DD/MM/YY"
            views={['year', 'month', 'day']}
            onChange={(date) => handleInputDateTimeChange(date?.toISOString())}
            value={document.validateDate ? dayjs(document.validateDate) : undefined}
            errorMessage={getErrorMessage(`attachments[${position}].validateDate`)}
            validateField={hasErrorField(`attachments[${position}].validateDate`)}
          />
        </DateTimeFieldContainerStyled>
      </GroupFieldsContainerStyled>
    </S.Wrapper>
  );
};
