import styled, { css } from 'styled-components';

interface BallonStyledProps {
  firstColor?: string;
  secondColor?: string;
  balloonColor?: string;
}

export const BalloonStyled = styled.div<BallonStyledProps>`
  ${({ theme, firstColor, secondColor, balloonColor }) => css`
    position: relative;
    width: 95%;
    height: 50px;
    padding: 5px;
    border-radius: 6px;
    justify-content: center;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: ${balloonColor ? balloonColor : theme.colors.blue};
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 8px 8px 0;
      border-color: ${balloonColor ? balloonColor : theme.colors.blue} transparent transparent transparent;
      top: 100%;
      width: 3px;
      left: 15%;
      transform: translateX(-50%);
    }

    span {
      font-size: 15px;
      color: ${firstColor ? firstColor : theme.colors.white};

      b {
        font-size: 15px;
        color: ${secondColor ? secondColor : theme.colors.aqua};
      }
    }
  `}
`;
