import styled, { css } from 'styled-components';

export const ResetLoginContainerStyled = styled.div``;

export const LoginImageStyled = styled.div``;

export const TextContainerStyled = styled.div`
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const HelloStyled = styled.h2`
  ${({ theme }) => css`
    font-weight: ${theme.font.medium};
    font-size: ${theme.font.sizes.xlarge};
    margin-bottom: 15px;

    @media (max-width: 600px) {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.medium};
      margin-bottom: 10px;
    }
  `}
`;

export const EmailInformationStyled = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    text-align: left;

    strong {
      font-weight: ${theme.font.medium};
    }

    @media (max-width: 600px) {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.small};
      margin-bottom: 10px;
    }
  `}
`;

export const ResetPasswordFormStyled = styled.form`
  & > div {
    margin-bottom: 15px;
  }
`;

export const ButtonContainerStyled = styled.div`
  ${({ theme }) => css`
    margin-top: 15px;

    button {
      width: 100%;
      background-color: ${theme.colors.blue};
    }
  `}
`;
