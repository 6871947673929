import { FC } from 'react';
import { IWeighingTottenModal } from './weighing-totten-modal.types';
import { WeighingTottenModalView } from './weighing-totten-modal-view.component';

export const ModalBark: FC<IWeighingTottenModal> = ({ children }) => {
  return (
    <WeighingTottenModalView>
      {children}
    </WeighingTottenModalView>
  );
};
