import { formatDateAndTime } from 'utils-v2/converters/date';
import {
  CardResumeOriginAndDestinyInput,
  IDeliveryScheduleDetailsValues,
  IFreightValues,
  ILevyScheduleDetailsValues,
  IOperationValues,
} from 'pages-v2/schedule/components/card-resume/types';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';

export const convertResumeCreate = (schedule: ScheduleRegisterInput, freightValues?: IFreightValues) => {
  const { origin, destination, cargo, detail, cost, distance, operation } = schedule;

  const isSpot = operation === 'SPOT' || operation === 'BACKHAUL EXTERNO SPOT';
  const isSaleOfferValues = !!cost.saleOfferValue && operation === 'TRACKING';

  const originValues: CardResumeOriginAndDestinyInput = {
    tradeName: origin.tradeName,
    cnpj: origin.origin?.cnpj,
    quality_and_safety: origin.origin.quality_and_safety,
    address: {
      city: origin.origin?.address?.city,
      complement: origin.origin?.address?.complement || '',
      neighborhood: origin.origin?.address?.neighborhood,
      numberHouse: origin.origin?.address?.numberHouse,
      uf: origin.origin?.address?.uf,
      street: origin.origin?.address?.street,
      cep: origin.origin?.address?.cep,
    },
  };

  const scheduleDetailsValuesOrigin: ILevyScheduleDetailsValues = {
    levyInitialDate: formatDateAndTime(detail.levyInitialDate),
    levyEndDate: formatDateAndTime(detail.levyEndDate),
  };

  const destinationValues: CardResumeOriginAndDestinyInput = {
    tradeName: destination.tradeName,
    cnpj: destination.destination?.cnpj,
    quality_and_safety: destination.destination.quality_and_safety,
    address: {
      city: destination.destination?.address?.city,
      complement: destination.destination?.address?.complement || '',
      neighborhood: destination.destination?.address?.neighborhood,
      numberHouse: destination.destination?.address?.numberHouse,
      uf: destination.destination?.address?.uf,
      street: destination.destination?.address?.street,
      cep: destination.destination?.address?.cep,
    },
  };

  const scheduleDetailsValuesDestination: IDeliveryScheduleDetailsValues = {
    deliveryInitialDate: formatDateAndTime(detail.deliveryInitialDate),
    deliveryEndDate: formatDateAndTime(detail.deliveryEndDate),
  };

  const scheduleCargoValues: IOperationValues = {
    weight: cargo.weight || 0,
    volume: cargo.volume || 0,
    inPalet: cargo.inPallet,
    pallet: cargo.pallet || 0,
    product: cargo.product,
    merchValue: cargo.merchValue || 0,
    vehicleCategoryType: cargo.vehicleCategoryType,
    vehicleType: cargo.vehicleType,
    distance: distance || 0,
    operationSubType: cargo.operationSubType,
  };

  const scheduleCostValues: IFreightValues = {
    value: isSpot
      ? cost.valueFreight
      : isSaleOfferValues
        ? cost.saleOfferValue?.valueTotal ?? 0
        : freightValues?.value ?? 0,
    helperPrice: isSpot ? cost.helperTotal : freightValues?.helperPrice ?? 0,
    grisAdvaloremPrice: isSpot || isSaleOfferValues ? cost.totalGrisAdvalorem : freightValues?.grisAdvaloremPrice ?? 0,
    taxPrice: isSpot ? cost.taxes : freightValues?.taxPrice ?? 0,
    tollPrice: isSpot ? cost.toll : freightValues?.tollPrice ?? 0,
    totalFreight: isSpot || isSaleOfferValues ? cost.totalFreight : freightValues?.totalFreight ?? 0,
    icms: isSpot || isSaleOfferValues ? cost.icms : freightValues?.icms ?? 0,
    totalService: isSpot || isSaleOfferValues ? cost.totalService : freightValues?.totalService ?? 0,
    calcToll: isSpot && cost.calcToll,
  };

  return {
    originValues,
    scheduleDetailsValuesOrigin,
    destinationValues,
    scheduleDetailsValuesDestination,
    scheduleCargoValues,
    scheduleCostValues,
  };
};
