import React, { Fragment } from 'react';
import { Card } from './components/card/card.component';

export const HomeCardsView: React.FC = () => {
  return (
    <Fragment>
      <Card cardType="purchaseOffer" />
      <Card cardType="saleOffer" />
    </Fragment>
  );
};
