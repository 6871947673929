import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IUsersContext } from './users.types';
import { usersReducer } from './users.reducer';
import { IUsersAction, initialState } from './users.actions';

import { IListPageUser } from 'domain/user';
import { IListPage } from 'domain/page-info';

interface IUsersContextProps {
  children: ReactNode;
}

export const UsersContext = createContext<IUsersContext>(initialState);

const UsersContextProvider = ({ children }: IUsersContextProps) => {
  const [state, dispatch] = useReducer(usersReducer, initialState);

  const setUsersRequest = useCallback(() => {
    dispatch({ type: IUsersAction.USERS_REQUEST, payload: null });
  }, []);

  const setUsersSuccess = useCallback((listUsers: IListPage<IListPageUser>) => {
    dispatch({
      type: IUsersAction.USERS_SUCCESS,
      payload: listUsers,
    });
  }, []);

  const setUsersErro = useCallback((erro: string) => {
    dispatch({ type: IUsersAction.USERS_ERROR, payload: erro });
  }, []);

  const value = useMemo(() => {
    return {
      users: state.users,
      usersIsLoading: state.usersIsLoading,
      usersError: state.usersError,
      usersPageInfo: state.usersPageInfo,
      setUsersRequest,
      setUsersSuccess,
      setUsersErro,
    };
  }, [
    setUsersErro,
    setUsersRequest,
    setUsersSuccess,
    state.users,
    state.usersError,
    state.usersIsLoading,
    state.usersPageInfo,
  ]);

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export default React.memo(UsersContextProvider);
