import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const ContainerErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
`;

export const TextContainerStyled = styled.p`
  display: flex;
  align-itens: center;
  font-size: 15px;
  font-weight: 600;
  color: #7f7f7f;
`;

export const SubTextContainerStyled = styled.p`
  display: flex;
  align-itens: center;
  font-size: 13px;
  font-weight: 400;
  color: rgb(111, 111, 116);
`;
