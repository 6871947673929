import { IHireBackhaulEntityCreated } from '../entities/hire-backhaul.entity';

import API from 'utils/API/API';
import { convertHireBackhaulCreatedEntityToDomain } from 'pages/hire-backhaul/mappers/convert-hire-backhaul-created-entity-to-domain/convert-hire-backhaul-created-entity-to-domain.mapper';
import { IHireBackhaulCreated } from 'domain/hire-backhaul';

export const hireBackhaulDetailService = async (hireBackhaulId: string): Promise<IHireBackhaulCreated> => {
  const { data } = await API.get<IHireBackhaulEntityCreated>(`v2/idleness/${hireBackhaulId}`);
  return convertHireBackhaulCreatedEntityToDomain(data);
};
