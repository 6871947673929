import styled, { css } from 'styled-components';
import { createTheme } from '@mui/material/styles';

import Theme from 'styles/theme';

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const appTheme = createTheme({
  typography: {
    allVariants: {
      textTransform: 'capitalize',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '8px',
    },
  },
  palette: {
    primary: { main: '#1f2b4f' },
  },
});

export const customStyles = (disabled: boolean) => {
  const baseStyles = {
    minHeight: '36px',
    width: '100%',
    border: disabled ? 'none' : '1px solid transparent',
    borderRadius: '4px',
    marginTop: '3px',
    backgroundColor: disabled ? 'rgb(231, 231, 231)' : '#fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& input': {
      '&[value="DD/MM/AA HH:MM"]': {
        color: 'transparent',
      },
      '::placeholder': {
        color: 'black',
        opacity: '54%',
      },
    },
    '& fieldset': {
      borderWidth: disabled ? '0' : '1px',
    },
  };

  return disabled
    ? baseStyles
    : {
        ...baseStyles,
        '&:hover': {
          borderColor: `${Theme.colorsV2.blue} !important`,
          boxShadow: `inset 0 0 0 1px ${Theme.colorsV2.blue} !important`,
        },
      };
};

export const LabelStyled = styled.div`
  ${({ theme }) => css`
    font-size: 11px;
    font-weight: 400;
    font-family: ${theme.font.family};
    color: ${theme.colors.black};
  `}
`;

export const DateTimePikerStyled = styled.div`
  input {
    color: rgb(3, 5, 23);
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
    outline: none;
    padding: 10px;
    padding-right: 0;
    white-space: 'nowrap';
    overflow: 'hidden';
    text-overflow: ellipsis;
    height: 18px;
  }

  button {
    padding: 0;
    margin: 0;
    margin-right: 6px;
  }

  .MuiOutlinedInput-root {
    margin: 0;
    padding: 0;
  }
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
