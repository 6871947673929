import styled, { css } from 'styled-components';

interface StyledProps {
  isPressed: boolean;
}

export const ContainerTabs = styled.ul<StyledProps>`
  ${({ theme, isPressed }) => css`
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    gap: 5px;
    height: 40px;
    padding: 0px 40px;
    background-color: ${isPressed ? theme.colorsV2.blue : theme.colorsV2.white};
    cursor: pointer;
  `}
`;

export const LabelTabs = styled.li<StyledProps>`
  ${({ theme, isPressed }) => css`
    font-size: 13px;
    color: ${isPressed ? theme.colorsV2.white : theme.colorsV2.black};
    list-style-type: none;
    display: flex;
    gap: 5px;

    span {
      font-weight: 600;
    }
  `}
`;
