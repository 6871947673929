export const formatCurrency = (value: number | string) => {
  const currencyPattern = /^R\$\s?\d{1,3}(?:\.\d{3})*(?:,\d{2})$/;
  const valueFormatted = String(value).replace(',', '.');

  if (!currencyPattern.test(String(valueFormatted)) && !isNaN(Number(valueFormatted))) {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(valueFormatted));
  }

  return value;
};

export const formatDecimal = (value: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(value);
};

export const formatDistanceDecimal = (value: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);
};

export const formatDate = (date: string, time: string) => {
  return time ? `${date}  ${time}` : date;
};

export const formatCPF = (cpfNumber: string) => {
  if (cpfNumber.length === 11) {
    return cpfNumber
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return cpfNumber;
};

export const checkIsNaN = (value: number) => {
  if (isNaN(value)) {
    return 0;
  }

  return value;
};
