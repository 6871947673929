import React, { ReactNode } from 'react';

import { ModalConfirmationTypes } from './modal-confirmation.type';
import ModalConfirmationView from './modal-confirmation-view';

type ModalConfirmationProps = {
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: any;
  secondTitle?: ReactNode;
  description: ReactNode;
  onConfirmation?: () => void;
  buttonTitleConfirmation?: string;
  deleteTitle?: string;
  returnTitle?: string;
  handleCancel?: () => void;
  handleXCancel?: () => void;
  overflow?: boolean;
  isRight?: boolean;
  legacy?: boolean;
  rightSecondTitle?: boolean;
  fullButtonWidth?: boolean;
  size: 'large' | 'medium' | 'small' | 'very-small';
  type: ModalConfirmationTypes;
  dateIndex?: boolean;
  cancelWithCost?: () => void;
  FooterChildren?: ReactNode;
};

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  style,
  title,
  secondTitle,
  description,
  handleCancel,
  onConfirmation,
  buttonTitleConfirmation,
  handleXCancel,
  overflow,
  isRight,
  deleteTitle,
  returnTitle,
  legacy,
  rightSecondTitle,
  fullButtonWidth,
  size,
  type,
  dateIndex,
  cancelWithCost = () => null,
  FooterChildren,
}) => {
  return (
    <ModalConfirmationView
      style={style}
      cancelWithCost={cancelWithCost}
      secondTitle={secondTitle}
      title={title}
      description={description}
      overflow={overflow}
      isRight={isRight}
      handleCancel={handleCancel}
      onConfirmation={onConfirmation}
      buttonTitleConfirmation={buttonTitleConfirmation}
      handleXCancel={handleXCancel}
      legacy={legacy}
      rightSecondTitle={rightSecondTitle}
      fullButtonWidth={fullButtonWidth}
      deleteTitle={deleteTitle}
      returnTitle={returnTitle}
      size={size}
      type={type}
      dateIndex={dateIndex}
      FooterChildren={FooterChildren}
    />
  );
};

export default ModalConfirmation;
