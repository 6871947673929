/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import DownloadIcon from '@mui/icons-material/Download';

import {
  ContainerStyled,
  ContentStyled,
  TitleStyled,
  DescriptionStyled,
  ButtonContainer,
  WrapperTextsStyled,
  InputContainer,
  RightButtons,
  LeftButton,
} from './modal-upload.styled';

import { showToast } from 'components/toast/toast';
import ExelUploadIcon from 'assets/icons/excel-upload.ivon';

type ModalConfirmationViewProps = {
  title: string;
  description: string;
  isLoading?: boolean;
  handleCancel: () => void;
  handleSuccess: (formData: FormData) => void;
  handleDownload?: () => void;
};

const ModalConfirmationView: React.FC<ModalConfirmationViewProps> = ({
  title,
  description,
  isLoading = false,
  handleCancel,
  handleSuccess,
  handleDownload,
}) => {
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSendUpload = () => {
    const formData = new FormData();
    const input: any = document.getElementById('file');
    const uploadFileData = input.files[0];

    if (
      uploadFileData.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadFileData.type === 'application/vnd.ms-excel'
    ) {
      formData.append('file', uploadFileData);
      handleSuccess(formData);
    } else {
      showToast({
        message: 'Selecione um arquivo com extenção xlsx ou xls',
        type: 'error',
      });
    }
  };

  const handleInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <ContainerStyled>
        <ContentStyled>
          <WrapperTextsStyled>
            <TitleStyled>{title}</TitleStyled>
            {fileName ? (
              <DescriptionStyled>Arquivo selecionado: {fileName}</DescriptionStyled>
            ) : (
              <DescriptionStyled>{description}</DescriptionStyled>
            )}
          </WrapperTextsStyled>
          <InputContainer>
            <LeftButton>
              <Button
                label="Escolher arquivo"
                onPress={handleInput}
                color="aqua"
                size="lg"
                variant="solid"
                RightIcon={<ExelUploadIcon width="18" />}
              />
            </LeftButton>
            <RightButtons>
              <Button
                label="Baixar template"
                onPress={handleDownload}
                color="green"
                size="lg"
                variant="solid"
                RightIcon={<DownloadIcon sx={{ width: '18px' }} />}
              />
            </RightButtons>
            <input
              ref={fileInputRef}
              type="file"
              id="file"
              accept=".xlsx, .xls"
              name="file"
              style={{ display: 'none' }}
              onChange={(event: any) => setFileName(event.target.files?.[0].name)}
            />
          </InputContainer>
          <ButtonContainer>
            <Button label="Cancelar" color="white" onPress={handleCancel} size="md" variant="ghost" />
            <Button
              label="Enviar"
              color="blue"
              onPress={handleSendUpload}
              size="md"
              variant="solid"
              isLoading={isLoading}
            />
          </ButtonContainer>
        </ContentStyled>
      </ContainerStyled>
    </>
  );
};
export default ModalConfirmationView;
