import { FC, useMemo } from 'react';
import { PurchaseOfferModalMatchScoreView } from './purchase-offer-modal-match-score-view.component';
import { ScoreDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';

type ScoreColor = 'green' | 'proYellow' | 'darkGray';

interface IPurchaseOfferModalMatchScoreProps {
  label: string;
  score: ScoreDomain;
}

const scoreMatches: Record<number, ScoreColor> = {
  0: 'darkGray',
  0.5: 'proYellow',
  1: 'green',
};

export const PurchaseOfferModalMatchScore: FC<IPurchaseOfferModalMatchScoreProps> = ({ label, score }) => {
  const scoreMatchColor = useMemo(() => {
    return scoreMatches[score];
  }, [score]);

  return <PurchaseOfferModalMatchScoreView label={label} color={scoreMatchColor} />;
};
