import React, { useState } from 'react';
import 'react-simple-keyboard/build/css/index.css';
import * as S from './virtual-keyboard-view.styled';
import VirtualKeyboard from 'react-simple-keyboard';

export interface VirtualKeyboardProps {
  onChange: (input: string) => void;
  onKeyPress?: ((button: string, e?: MouseEvent | undefined) => any) | undefined;
  keyboardType?: 'numbers' | 'default';
}

export const VirtualKeyboardView: React.FC<VirtualKeyboardProps> = ({
  onChange,
  onKeyPress,
  keyboardType = 'numbers',
}) => {
  const [layoutName, setLayoutName] = useState('numbers');
  const [layoutType, setLayoutType] = useState<'numbers' | 'ABC'>();

  const handleChange = (input: string) => {
    onChange(input);
  };

  const onInternalKeyPress = (e: string) => {
    const result = e.replace(/{/g, '').replace(/}/g, '');

    if (keyboardType === 'numbers') {
      return;
    }

    onKeyPress && onKeyPress(result);

    if (layoutType === 'numbers') {
      if (result === 'abc') {
        setLayoutName('default');
        setLayoutType('ABC');
      }
      return;
    }

    switch (result) {
      case 'backspace':
        break;
      case 'numbers':
        setLayoutName('numbers');
        setLayoutType('numbers');
        break;
      case 'shift':
        setLayoutName('shift');
        break;
      default:
        setLayoutName('default');
    }
  };

  const handleInit = () => {};

  return (
    <S.Wrapper>
      <VirtualKeyboard
        onInit={handleInit}
        onKeyPress={onInternalKeyPress}
        onChange={handleChange}
        mergeDisplay={true}
        layoutName={layoutName}
        layout={{
          default: [
            'q w e r t y u i o p',
            'a s d f g h j k l',
            '{shift} z x c v b n m {backspace}',
            '{numbers} {space} {ent}',
          ],
          shift: [
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            '{shift} Z X C V B N M {backspace}',
            '{numbers} {space} {ent}',
          ],
          numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}'],
        }}
        display={{
          '{numbers}': '123',
          '{ent}': 'return',
          '{escape}': 'esc ⎋',
          '{tab}': 'tab ⇥',
          '{backspace}': '⌫',
          '{capslock}': 'caps lock ⇪',
          '{shift}': '⇧',
          '{controlleft}': 'ctrl ⌃',
          '{controlright}': 'ctrl ⌃',
          '{altleft}': 'alt ⌥',
          '{altright}': 'alt ⌥',
          '{metaleft}': 'cmd ⌘',
          '{metaright}': 'cmd ⌘',
          '{abc}': 'ABC',
        }}
      />
    </S.Wrapper>
  );
};
