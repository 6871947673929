import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const TextAreaStyled = styled.textarea`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid;
    border-radius: 3px;
    border-color: ${theme.colors.gray};
    background-color: ${theme.colors.white};
    outline: none;
    color: ${theme.colors.black};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    background-color: transparent;
    max-width: 100%;
    min-height: 150px;
    max-height: 150px;
    padding: 10px;

    box-shadow: 0 0 0 22px ${theme.colors.white} inset;

    &:focus-within {
      box-shadow: 0 0 0 2px #c4c4c434;
    }
  `}
`;
