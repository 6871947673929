import { FooterPageRoot } from './FooterPageRoot';
import { FooterPageRightContent } from './FooterPageRightContent';
import { FooterPageLeftContent } from './FooterPageLeftContent';
import { FooterPageButton } from './FooterPageButton';

export const FooterPage = {
  Root: FooterPageRoot,
  Button: FooterPageButton,
  LeftContent: FooterPageLeftContent,
  RightContent: FooterPageRightContent,
};
