import React from 'react';
import Select, { MultiValue } from 'react-select';
import { ContainerStyled, customStyles, LabelStyled, MessageErrorStyled, SelectContainer } from './multi-select.styled';

interface Option<T> {
  value: T;
  label: string;
}

interface MultiSelectViewParams<T> {
  id?: string;
  options: Array<Option<T>>;
  placeholder?: string;
  isSearchable?: boolean;
  menuPosition: 'fixed' | 'absolute';
  label: string;
  hasError?: boolean;
  errorMessage?: string;
  values?: Array<Option<T>>;
  onChange: (value: MultiValue<Option<T>>) => void;
}
const MultiSelectView = <T,>({
  options,
  hasError,
  isSearchable,
  menuPosition,
  placeholder = '',
  label = '',
  errorMessage,
  values,
  id,
  onChange,
}: MultiSelectViewParams<T>) => {
  return (
    <ContainerStyled>
      <LabelStyled htmlFor={id} hasError={!!hasError}>
        {label}
      </LabelStyled>
      <SelectContainer>
        <Select
          styles={customStyles}
          options={options}
          isMulti
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          isSearchable={isSearchable}
          menuPosition={menuPosition}
          value={values}
        />
        {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
      </SelectContainer>
    </ContainerStyled>
  );
};
export default MultiSelectView;
