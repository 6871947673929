export interface CheckDeleteResponse {
  id: number;
  name: string;
  description?: string;
  delete: boolean;
}

export const coolDownTasks = {
  UPDATE_MATCH_PROPOSAL: 'UPDATE_MATCH_PROPOSAL',
};

export type ApprovedTrackers = 'TRUCKS_CONTROL' | 'SASCAR' | 'OMNILINK' | 'AUTOTRAC';

export type VehicleType =
  | '3/4'
  | 'bitrem'
  | 'bitruck'
  | 'carreta'
  | 'carreta_ls'
  | 'carreta_sete_eixos'
  | 'complemento'
  | 'rodotrem'
  | 'romeu&julieta'
  | 'toco'
  | 'truck'
  | 'utilitario'
  | 'van'
  | 'vanderleia'
  | 'vuc'
  | '';

export type VehicleTypeName =
  | '3/4'
  | 'BITREM'
  | 'BITRUCK'
  | 'CARRETA'
  | 'CARRETA LS'
  | 'CARRETA 7 EIXOS'
  | 'COMPLEMENTO'
  | 'RODOTREM'
  | 'ROMEU & JULIETA'
  | 'TOCO'
  | 'TRUCK'
  | 'UTILITÁRIO'
  | 'VAN'
  | 'VANDERLÉIA'
  | 'VUC';

export type VehicleCategory =
  | ''
  | 'bau_seco'
  | 'bau_frigorificado'
  | 'bau_refrigerado'
  | 'bau_sider'
  | 'cacamba_basculante'
  | 'carreta'
  | 'cegonha'
  | 'complemento'
  | 'florestal'
  | 'grade_baixa'
  | 'graneleiro'
  | 'plataforma'
  | 'porta_container_20'
  | 'porta_container_40'
  | 'tanque';

export type VehicleCategoryName =
  | ''
  | 'BAÚ SECO'
  | 'BAÚ FRIGORIFICADO'
  | 'BAÚ REFRIGERADO'
  | 'SIDER'
  | 'BASCULANTE'
  | 'CARRETA'
  | 'CEGONHA'
  | 'COMPLEMENTO'
  | 'FLORESTAL'
  | 'GRADE BAIXA'
  | 'GRANELEIRO'
  | 'PLATAFORMA'
  | 'PORTA CONTAINER 20 PES'
  | 'PORTA CONTAINER 40 PES'
  | 'TANQUE';

export type VehicleTypeWithEmpty = '' | VehicleType;
export type VehicleTypeNameWithEmpty = '' | VehicleTypeName;
export type VehicleCategoryWithEmpty = '' | VehicleCategory;
export type VehicleCategoryNameWithEmpty = '' | VehicleCategoryName;

export enum VehicleTypeEnum {
  TRES_QUARTO = '3/4',
  BITREM = 'bitrem',
  BITRUCK = 'bitruck',
  CARRETA = 'carreta',
  CARRETA_SETE_EIXOS = 'carreta_sete_eixos',
  CARRETALS = 'carreta_ls',
  COMPLEMENTO = 'complemento',
  RODOTREM = 'rodotrem',
  ROMEUJULIETA = 'romeu&julieta',
  TRUCK = 'truck',
  TOCO = 'toco',
  UTILITARIO = 'utilitario',
  VAN = 'van',
  VANDERLEIA = 'vanderleia',
  VUC = 'vuc',
}

export enum VehicleCategoryEnum {
  BAU_SECO = 'bau_seco',
  BAU_FRIGORIFICADO = 'bau_frigorificado',
  BAU_REFRIGERADO = 'bau_refrigerado',
  BAU_SIDER = 'bau_sider',
  CACAMBA_BASCULANTE = 'cacamba_basculante',
  CEGONHA = 'cegonha',
  COMPLEMENTO = 'complemento',
  FLORESTAL = 'florestal',
  GRADE_BAIXA = 'grade_baixa',
  GRANELEIRO = 'graneleiro',
  PLATAFORMA = 'plataforma',
  PORTA_CONTAINER_20 = 'porta_container_20',
  PORTA_CONTAINER_40 = 'porta_container_40',
  TANQUE = 'tanque',
}
