import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';

import HeaderTable from '../header';

import { mountTransactionHeader, mountTransactionList } from 'pages-v2/transactions/utils/mount-list';
import { CalcFinanceList } from 'domain-v2/transactions';
import { PageInfo } from 'domain-v2/inputs/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import {
  mountTransferHeader,
  mountTransferList,
} from 'pages-v2/transactions/utils/mount-list/mount-transfer-list.util';
import { UserContext } from 'state/user-context';
import { mountReceiveHeader, mountReceiveList } from 'pages-v2/transactions/utils/mount-list/mount-receive-list.util';
import EmptyStateTable from 'components/empty-state-table/empty-state-table';
import SearchEmptyListIcon from 'assets/icons/search-empty-list.icon';

interface ContentTable {
  transactionList: Array<CalcFinanceList>;
  pageInfo: PageInfo;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  orderSelected: { orderBy: string; sortDirection: string };
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: (type: string) => void;
  setActiveTabName: (name: string) => void;
  goToAllocationSchedule: (id: string) => void;
  tabActive: string;
  ids: Array<number>;
  handleCheckboxAll: (clicked: boolean) => void;
  handleClearSelection: () => void;
  handleCheckboxChange: (
    event: ChangeEvent<HTMLInputElement>,
    scheduleIds: Array<number>,
    calcFinanceIds: Array<number>,
    takeNames: Array<string>,
    sellerNames: Array<string>,
    price: Array<number>,
    takerName: string,
    type: Array<string>,
  ) => void;
}

const ListTransactionTable: FC<ContentTable> = ({
  transactionList,
  pageInfo,
  isLoading,
  handlePaginate,
  orderSelected,
  clickOrderTableBy,
  clickSortDirection,
  setActiveTabName,
  tabActive,
  goToAllocationSchedule,
  ids,
  handleCheckboxChange,
  handleCheckboxAll = () => null,
  handleClearSelection,
}) => {
  const { isShippingCompany, isAdmin } = useContext(UserContext);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        handleCheckboxAll(true);
      } else {
        handleCheckboxAll(false);
      }
    },
    [handleCheckboxAll],
  );

  const isTransactionDisabled = useCallback(
    (transaction: CalcFinanceList) => {
      const isInAudit = transaction.financialStatus === 'Em Auditoria';
      const isInInvoiced = transaction.financialStatus === 'Faturado';
      const isInPay = transaction.financialStatus === 'Pago';
      const isLiquidated = transaction.financialStatus === 'Liquidado';
      const isToPay = transaction.tag !== 'venda';

      return isInAudit || isInInvoiced || isToPay || isInPay || isLiquidated;
    },
    [transactionList],
  );

  const mountTablePayable = useMemo((): { contentTable: Array<IContentTable>; headerTable: IContentTable } => {
    const contentTable = transactionList.map((transaction) => {
      return mountTransactionList(transaction, ids, isAdmin, handleCheckboxChange, isTransactionDisabled);
    });

    const headerTable = mountTransactionHeader(isAdmin, ids, handleChangeCheckbox, handleClearSelection);

    return { contentTable, headerTable };
  }, [
    transactionList,
    isAdmin,
    ids,
    handleChangeCheckbox,
    handleClearSelection,
    handleCheckboxChange,
    isTransactionDisabled,
  ]);

  const mountTableReceive = useMemo((): { contentTable: Array<IContentTable>; headerTable: IContentTable } => {
    const contentTable = transactionList.map((transaction) => {
      return mountReceiveList(transaction, ids, isAdmin, handleCheckboxChange, isTransactionDisabled);
    });

    const headerTable = mountReceiveHeader(isAdmin, ids, handleChangeCheckbox, handleClearSelection);

    return { contentTable, headerTable };
  }, [
    transactionList,
    isAdmin,
    ids,
    handleChangeCheckbox,
    handleClearSelection,
    handleCheckboxChange,
    isTransactionDisabled,
  ]);

  const mountTableTransfer = useMemo((): { contentTable: Array<IContentTable>; headerTable: IContentTable } => {
    const contentTable = transactionList.map((transaction) => {
      return mountTransferList(transaction, ids, isAdmin, handleCheckboxChange);
    });

    const headerTable = mountTransferHeader(isAdmin, handleChangeCheckbox);

    return { contentTable, headerTable };
  }, [transactionList, isAdmin, handleChangeCheckbox, ids, handleCheckboxChange]);

  const renderTransactions = useCallback(
    (contentTable: { contentTable: Array<IContentTable>; headerTable: IContentTable }) => {
      return (
        <HeaderTable
          orderSelected={orderSelected}
          contentTable={contentTable}
          pageInfo={pageInfo}
          changePage={handlePaginate}
          clickOrderTableBy={clickOrderTableBy}
          clickSortDirection={clickSortDirection}
          isLoading={isLoading}
          clickRowAction={goToAllocationSchedule}
        />
      );
    },
    [orderSelected, pageInfo, handlePaginate, clickOrderTableBy, clickSortDirection, isLoading, goToAllocationSchedule],
  );

  const tabContent = useMemo(() => {
    if (isShippingCompany) {
      return [
        {
          name: 'A Receber',
          content: renderTransactions(mountTableTransfer),
        },
        {
          name: 'A Pagar',
          content: (
            <EmptyStateTable
              isLoading={isLoading}
              text="Lista Vazia"
              subText="Nenhum registro localizado."
              icon={<SearchEmptyListIcon />}
            />
          ),
        },
      ];
    }

    if (isAdmin) {
      return [
        {
          name: 'A Receber',
          content: renderTransactions(mountTablePayable),
        },
        {
          name: 'A Pagar',
          content: renderTransactions(mountTableReceive),
        },
        {
          name: 'Crédito Transportadora',
          content: renderTransactions(mountTableTransfer),
        },
      ];
    }

    return [
      {
        name: 'A Pagar',
        content: renderTransactions(mountTablePayable),
      },
      {
        name: 'A Receber',
        content: renderTransactions(mountTableReceive),
      },
      {
        name: 'Crédito Transportadora',
        content: renderTransactions(mountTableTransfer),
      },
    ];
  }, [
    isShippingCompany,
    isAdmin,
    renderTransactions,
    mountTablePayable,
    mountTableReceive,
    mountTableTransfer,
    isLoading,
  ]);

  return <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} tabActive={tabActive} />;
};

export default ListTransactionTable;
