import { useLocation, useParams } from 'react-router-dom';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { useValidationFields } from 'pages-v2/schedule-ltl/context/validation-fields';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';

interface ScheduleOriginAndDestinyFormProps {
  type: 'origin' | 'destination';
}

type LocationDetails = {
  tradeName: string;
  cep: string;
  city: string;
  complement: string;
  neighborhood: string;
  numberHouse: string;
  street: string;
  uf: string;
};

const initialLocationDetails = {} as LocationDetails;

const ScheduleOriginAndDestinyForm: React.FC<ScheduleOriginAndDestinyFormProps> = ({ type }) => {
  const { values, setFieldValue } = useFormikContext<ScheduleLTLRegisterInput>();

  const { id } = useParams();

  const { origin, destination } = values;

  const { validateField, setScheduleLTLValues } = useValidationFields();

  const [locationsDetails, setLocationsDetails] = useState<LocationDetails>(initialLocationDetails);

  const location = useLocation();

  const typeMemo = useMemo(() => {
    return type as 'origin' | 'destination';
  }, []);

  const typeKeyMemo = useMemo(() => {
    if (type === 'origin') return 'origin';
    return 'destination';
  }, []);

  const handleCompleteInputs = useCallback(
    async (id: number, type: '' | 'CLIENT' | 'LOCALITY') => {
      if ((type === 'CLIENT' || type === 'LOCALITY') && id) {
        try {
          const details = await (type === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          setLocationsDetails({
            ...initialLocationDetails,
            ...details?.address,
          });

          const fieldValuesToUpdate = {
            [`${typeKeyMemo}.id`]: details.id,
            [`${typeKeyMemo}.${typeMemo}.address.city`]: details.address.city,
            [`${typeKeyMemo}.${typeMemo}.address.uf`]: details.address.uf,
            [`${typeKeyMemo}.${typeMemo}.address.complement`]: details.address.complement,
            [`${typeKeyMemo}.${typeMemo}.address.neighborhood`]: details.address.neighborhood,
            [`${typeKeyMemo}.${typeMemo}.address.numberHouse`]: details.address.numberHouse,
            [`${typeKeyMemo}.${typeMemo}.address.street`]: details.address.street,
            [`${typeKeyMemo}.${typeMemo}.address.cep`]: details.address.cep,
            [`${typeKeyMemo}.${typeMemo}.cnpj`]: details.cnpj,
            [`${typeKeyMemo}.${typeMemo}.lat`]: details.lat,
            [`${typeKeyMemo}.${typeMemo}.lng`]: details.lng,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [location.state, values.origin.id, values.destination.id],
  );

  const handleChangeLocations = useCallback(
    (id: number) => {
      handleCompleteInputs(id, values[typeKeyMemo].type);
    },
    [values[typeKeyMemo].type, values[typeKeyMemo].id],
  );

  useEffect(() => {
    if (location.state) {
      setFieldValue('origin.type', location.state.origin.type);
      setFieldValue('destination.type', location.state.destination.type);
      setFieldValue('origin.tradeName', location.state.origin.name);
      setFieldValue('destination.tradeName', location.state.destination.name);
      typeMemo === 'origin'
        ? handleCompleteInputs(location.state.origin.id, location.state.origin.type)
        : handleCompleteInputs(location.state.destination.id, location.state.destination.type);
    }
  }, [location.state]);

  useEffect(() => {
    if (!id) {
      setScheduleLTLValues({
        ...values,
        origin: {
          type: origin.type,
          id: origin.id,
          origin: origin.origin,
          tradeName: origin.tradeName,
        },
        destination: {
          type: destination.type,
          id: destination.id,
          destination: destination.destination,
          tradeName: destination.tradeName,
        },
      });
    }
  }, [origin.id, destination.id]);

  return (
    <SectionLayout name={`${typeMemo} information`}>
      <LineSectionLayout columns="1.04fr 4fr">
        <Select
          label="Tipo *"
          id={`${typeKeyMemo}.type`}
          name={`${typeKeyMemo}.type`}
          value={values[typeKeyMemo].type}
          setFieldValue={setFieldValue}
          options={OriginAndDestinyTypeOptions}
          hasError={validateField && !values[typeKeyMemo].type}
          errorMessage="Campo Obrigatório"
        />

        <AsyncSelectField
          placeholder=""
          label="Informe o Nome *"
          isClearable={true}
          name={`${typeMemo}.tradeName`}
          id={`${typeMemo}.tradeName`}
          disabled={!values[typeKeyMemo].type}
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            handleChangeLocations(e?.value || 0);
            setFieldValue(`${typeKeyMemo}.tradeName`, e?.label);
          }}
          apiCallFunction={async (e: string) => await searchOriginOrDestiny(e, values[typeKeyMemo].type)}
          loadOptions={async (e: string) => await searchOriginOrDestiny(e, values[typeKeyMemo].type)}
          defaultValue={{
            value: typeMemo === 'origin' ? location.state?.origin.id : location.state?.destination.id,
            label: typeMemo === 'origin' ? location.state?.origin.name : location.state?.destination.name,
          }}
          hasError={validateField && !values[typeKeyMemo].tradeName}
          errorMessage="Campo Obrigatório"
        />
      </LineSectionLayout>

      <LineSectionLayout columns="0.81fr 1.3fr 1.3fr 0.4fr ">
        <InputField
          label="CEP"
          name={`${typeMemo}.cep`}
          id={`${typeMemo}.cep`}
          type="text"
          value={locationsDetails?.cep}
          disabled
        />

        <InputField
          label="Bairro"
          name={`${typeMemo}.neighborhood`}
          id={`${typeMemo}.neighborhood`}
          type="text"
          value={locationsDetails?.neighborhood}
          disabled
        />

        <InputField
          label="Cidade"
          name={`${typeMemo}.city`}
          id={`${typeMemo}.city`}
          type="text"
          value={locationsDetails?.city}
          disabled
        />

        <InputField
          label="UF"
          name={`${typeMemo}.uf`}
          id={`${typeMemo}.uf`}
          type="text"
          value={locationsDetails?.uf}
          disabled
        />
      </LineSectionLayout>

      <LineSectionLayout columns="3fr 0.5fr 1.5fr">
        <InputField
          label="Endereço"
          name={`${typeMemo}.street`}
          id={`${typeMemo}.street`}
          type="text"
          value={locationsDetails?.street}
          disabled
        />

        <InputField
          label="Nº"
          name={`${typeMemo}.numberHouse`}
          id={`${typeMemo}.numberHouse`}
          type="text"
          value={locationsDetails?.numberHouse}
          disabled
        />

        <InputField
          label="Complemento"
          name={`${typeMemo}.complement`}
          id={`${typeMemo}.complement`}
          type="text"
          value={locationsDetails?.complement}
          disabled
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};

export default memo(ScheduleOriginAndDestinyForm);
