import { ColorsV2Type } from 'styles/types/colors';

export const convertReworkStatusCodeColor = (statusCode: string): ColorsV2Type => {
  const statusColors: { [key: string]: ColorsV2Type } = {
    'oferta negada': ColorsV2Type.Red,
    'alocação cancelada': ColorsV2Type.Red,
    'alocação cancelada com custo': ColorsV2Type.Red,
    'em negociação': ColorsV2Type.Blue,
    'em bidding': ColorsV2Type.Blue,
    'aguardando alocação': ColorsV2Type.Blue25,
    'aguardando transportadora': ColorsV2Type.Blue25,
    'ag. aceite transportadora': ColorsV2Type.Blue50,
    'aguardando motorista': ColorsV2Type.Blue75,
    'ag retrabalho': ColorsV2Type.Blue,
    'em retrabalho': ColorsV2Type.Blue25,
    'ag puxada': ColorsV2Type.Blue75,
    'ag recebimento': ColorsV2Type.Aqua25,
    'em recebimento': ColorsV2Type.Aqua100,
    concluido: ColorsV2Type.Aqua,
    'em carregamento': ColorsV2Type.Aqua50,
    cancelado: ColorsV2Type.Red,
    'em rota para descarga': ColorsV2Type.Aqua75,
    'em descarga': ColorsV2Type.Aqua100,
    'descarga finalizada': ColorsV2Type.Aqua,
  };

  return statusColors[statusCode] || ColorsV2Type.Blue;
};
