import { useCallback, useContext } from 'react';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { useGetEligibleUsersChat } from 'src-new/hooks/chat/use-get-eligible-users-chat/use-get-eligible-users-chat.hook';
import { useCheckExistingThreadsChat } from 'src-new/hooks/chat/use-check-existing-threads-chat/use-check-existing-threads-chat.hook';
import { IOpenProposalChatProps } from 'src-new/hooks/chat/use-open-proposal-chat/use-open-proposal-chat.types';

const ENVIRONMENT = import.meta.env.VITE_FIREBASE_TARGET;

export const useOpenProposalChat = () => {
  const { chatActions } = useContext(ChatButtonContext);
  const getEligibleUsers = useGetEligibleUsersChat();
  const checkExistingThreadsChat = useCheckExistingThreadsChat();

  return useCallback(
    async (props: IOpenProposalChatProps) => {
      const {
        purchaseOfferId,
        saleTariffOfferId,
        saleOfferId,
        matchId,
        saleOfferCompanyId,
        pageType,
        buyOfferCompanyId,
      } = props;
      const threadId = `${ENVIRONMENT}-proposal-${purchaseOfferId}-${saleOfferId}-${saleTariffOfferId}-${matchId}`;

      const threadHaveCreated = await checkExistingThreadsChat({
        typeChat: 'SINGLE',
        chatId: `proposal-${purchaseOfferId}-${saleOfferId}-${saleTariffOfferId}-${matchId}`,
        name: '',
      });

      if (!threadHaveCreated) {
        const purchaseOfferId: string = threadId.split('-')[2];
        const saleOfferId: string = threadId.split('-')[3];

        await getEligibleUsers({
          flowType: 'PROPOSAL',
          saleOfferId: saleOfferId,
          buyOfferId: purchaseOfferId,
        });
      }

      chatActions.setChatOpen({
        chatId: threadId,
        name: 'NEGOCIAÇÃO',
        saleOfferCompanyId: saleOfferCompanyId,
        buyOfferCompanyId: buyOfferCompanyId,
        flowConversation: pageType === 'SALE_OFFER' ? 'SALE_OFFER' : 'BUY_OFFER',
      });
    },
    [chatActions, checkExistingThreadsChat, getEligibleUsers],
  );
};
