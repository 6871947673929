import React, { useState } from 'react';

import FormRegisterLogin from '../login-validation/components/form-login/form-register-login';
import LoginDemo from '../login-demo/login-demo';

import {
  ButtonStyled,
  ContainerLogoPortStyled,
  FooterContainerStyled,
  FooterTextStyled,
  HelloStyled,
  LoginContainerStyled,
  TextContainerStyled,
  TraceStyled,
  UserInformationStyled,
} from './login-body.styled';

import ContentStyled from 'components/template-authentication/template-authentication';
import LogoPortodeSantos from 'assets/logos/logo-porto-de-santos';

interface LoginViewProps {
  handleLogin: (email: string, password: string, recaptchaLogin: string) => void;
  isLoading: boolean;
}

const LoginView: React.FC<LoginViewProps> = ({ handleLogin, isLoading }) => {
  const [modal, setModal] = useState<boolean>(false);

  return (
    <ContentStyled>
      <LoginContainerStyled>
        {modal && <LoginDemo modal={setModal} />}
        <TextContainerStyled>
          <HelloStyled>Olá,</HelloStyled>
          <UserInformationStyled>
            Informe seu <strong>usuário</strong> e <strong>senha</strong>.
          </UserInformationStyled>
        </TextContainerStyled>
        <FormRegisterLogin isLoading={isLoading} handleLogin={handleLogin} />
        <TraceStyled />

        <FooterContainerStyled>
          <FooterTextStyled>
            Novo na <strong>Logshare</strong>? Faça parte desse ecossistema!
          </FooterTextStyled>

          <ButtonStyled onClick={() => setModal(true)}>Novo Cadastro</ButtonStyled>
        </FooterContainerStyled>

        <ContainerLogoPortStyled>
          Parceiro Oficial
          <LogoPortodeSantos />
        </ContainerLogoPortStyled>
      </LoginContainerStyled>
    </ContentStyled>
  );
};

export default LoginView;
