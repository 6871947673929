import styled from 'styled-components';

interface IStyledProps {
  backgroundColor?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const ChatButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export const ChatButtonStyled = styled.button<IStyledProps>`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border-style: none;
  background-color: ${(props) => (props.isLoading ? '#8C8787FF' : 'rgb(18, 38, 78)')};
  background-position: center 17px;
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 4px 0 #00000040;
  cursor: pointer;
`;

export const GroupButtonWrapperStyled = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
`;

export const GroupButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  z-index: 1000;
`;

export const GroupButtonNameStyled = styled.div`
  margin-left: 3px;
  font-weight: bold;
`;

export const GroupButtonStyled = styled.button<IStyledProps>`
  display: flex;
  width: 280px;
  height: 33px;
  border-radius: 5px;
  border-style: none;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => (props.disabled ? '#949494' : '#fff')};
  align-items: center;
  box-shadow: 0 4px 4px 0 #00000040;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const UnreadsSingleChatContainerStyled = styled.span`
  width: 22px;
  height: 22px;
  position: absolute;
  padding-top: 2px;
  bottom: 48px;
  right: 2px;
  background-color: rgb(224, 71, 71);
  color: #fff;
  border-radius: 50%;
`;

export const UnreadsSingleChatStyled = styled.div``;

export const UnreadsGroupWrapperStyled = styled.div`
  display: flex;
  width: 10%;
`;

export const MessageCountWrapperStyled = styled.div`
  display: flex;
  width: 10%;
`;

export const MessageCountStyled = styled.div<IStyledProps>`
  width: 1px;
  color: ${(props) => (props.disabled ? '#949494' : '#FFF')};
  padding-top: 1px;
`;

export const GroupButtonNameWrapperStyled = styled.div`
  display: flex;
  width: 90%;
  padding-left: 10px;
`;

export const UnreadsGroupChatStyled = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: red;
  color: rgb(255, 255, 255);
  padding-top: 1px;
`;
