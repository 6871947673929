import React, { Fragment, ReactElement, useMemo } from 'react';
import * as S from './header-buttons-view.styled';
import { Button } from 'logshare-ui-kit';
import { IScheduleCheckbox } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { IButtonsOptions } from './header-buttons.types';

interface IHeaderButtonsViewProps {
  scheduleSelected: Array<IScheduleCheckbox>;
  buttonsOptions: IButtonsOptions;
  handleAcceptSchedule: () => void;
  handleCancelFreight: () => void;
  handleFinish: () => void;
}

export const HeaderButtonsView: React.FC<IHeaderButtonsViewProps> = ({
  scheduleSelected,
  buttonsOptions,
  handleAcceptSchedule,
  handleCancelFreight,
  handleFinish,
}) => {
  const renderButtons = useMemo((): ReactElement => {
    return (
      <Fragment>
        {buttonsOptions.thirdButton && (
          <Button
            label={buttonsOptions.thirdButton}
            size="md"
            color="red"
            onPress={handleCancelFreight}
            variant="solid"
          />
        )}
        {buttonsOptions.secondaryButton && (
          <Button
            label={buttonsOptions.secondaryButton}
            size="md"
            color="aqua"
            onPress={handleAcceptSchedule}
            variant="solid"
          />
        )}

        {scheduleSelected.length > 0 && buttonsOptions.finishButton && (
          <Button label={buttonsOptions.finishButton} size="md" color="white" onPress={handleFinish} variant="ghost" />
        )}
      </Fragment>
    );
  }, [
    buttonsOptions.finishButton,
    buttonsOptions.secondaryButton,
    buttonsOptions.thirdButton,
    handleAcceptSchedule,
    handleCancelFreight,
    handleFinish,
    scheduleSelected.length,
  ]);

  return <S.Wrapper>{renderButtons}</S.Wrapper>;
};
