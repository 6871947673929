import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { SaleOfferModalMatchView } from './sale-offer-modal-match-view.component';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { ICompany } from 'domain/company';
import { UserContext } from 'state/user-context';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';

export const SaleOfferModalMatch: FC = () => {
  const { user } = useContext(UserContext);
  const { saleOfferModals, saleOfferMatchList } = useContext(SaleOfferContext);
  const { setModalClose } = saleOfferModals;
  const { saleOfferMatchProposal, filters } = saleOfferMatchList;
  const { setSaleOfferMatchProposalReset } = saleOfferMatchProposal;
  const { setResetFilters } = filters;

  const getCompanyHook = useGetCompany();

  const [company, setCompany] = useState<ICompany>();

  const handleCloseModalMatch = useCallback(() => {
    setModalClose();
    setSaleOfferMatchProposalReset();
    setResetFilters();
  }, [setModalClose, setResetFilters, setSaleOfferMatchProposalReset]);

  useEffect(() => {
    (async () => {
      if (user) {
        const findCompany = await getCompanyHook(user.companyId);
        setCompany(findCompany);
      }
    })();
  }, [getCompanyHook, user]);

  if (company) {
    return <SaleOfferModalMatchView company={company} handleCloseModalMatch={handleCloseModalMatch} />;
  }

  return <Fragment />;
};
