/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FormikProps, withFormik } from 'formik';
import dayjs from 'dayjs';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {
  ContainerDestinyStyled,
  FormStyled,
  LineSectionStyled,
  SectionStyled,
  WrapperDateTimePikers,
} from './rework-register-form.styled';
import ReworkPhotosSectionView from './rework-photos-section';
import { destinationInitialValue, optionsServiceType } from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getScheduleByIdService } from 'services/schedule';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { ReworkFileUrl } from 'pages-v2/rework/services/rework-file-url.service';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';
import { IRework } from 'domain/rework';
import { ITransportedCargo, IYesNo } from 'domain/hire-backhaul';
import { INewClient } from 'domain/client';
import { FooterPage } from 'components-v2/common/footer-page';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import Button from 'components/button/button';
import AsyncSelectField from 'components/async-select/async-select';

interface ReworkRegisterFormViewProps {
  hireBackhaulRegisterValues: IRework;
  optionsTransportedCargo: Array<{ label: string; value: ITransportedCargo }>;
  optionsTime: Array<{ label: string; value: string }>;
  optionsYesOrNo: Array<{ label: string; value: IYesNo }>;
  optionClientType: Array<{ label: string; value: string }>;
  isLoading: boolean;
  handleGoBack: () => void;
  handleRegister: (values: IRework) => void;
  schemaValidation: object;
  setDdToCompareCompanyOrigin?: (id: number) => void;
}

const ReworkRegisterFormView: React.FC<ReworkRegisterFormViewProps> = ({
  hireBackhaulRegisterValues,
  optionClientType,
  isLoading,
  handleGoBack,
  handleRegister,
}) => {
  const InnerForm = (props: FormikProps<IRework>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;
    const [disableCollectDate, setDisableCollectDate] = useState(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [client, setClient] = useState<INewClient>();

    const handleGetScheduleByIdService = async (id: string) => {
      const schedule = await getScheduleByIdService(id);
      return schedule;
    };

    const handleCompleteInputSchedule = async () => {
      showMessageFormatted({
        message: 'Aguarde enquanto carrega as informaçoes da viagem.',
        type: 'info',
      });

      const schedule = await handleGetScheduleByIdService(String(values.scheduleId));

      if (
        schedule.scheduleClientOrigin.clientType !== 'client' ||
        schedule.scheduleClientOrigin.client?.id !== client?.id
      ) {
        showMessageFormatted({
          message: 'Cliente divergente da origem da carga',
          type: 'error',
        });
      }

      if (schedule.scheduleDetails.levyInitialDate) {
        setDisableCollectDate(true);
      }

      setFieldValue('collectDate', dayjs(schedule.scheduleDetails.levyInitialDate));

      setFieldValue('schedulingDate', dayjs(schedule.scheduleDetails.levyInitialDate));

      setFieldValue('shippingCompanyName', schedule.shippingCompany?.name);
      setFieldValue('shippingCompanyId', schedule.shippingCompany?.id);
      setFieldValue('internalNumber', schedule.scheduleDetails.internalNumber);
      setFieldValue('boardingNumber', schedule.scheduleDetails.boardingNumber);
      setFieldValue('ocInternalNumber', schedule.scheduleDetails.ocInternalNumber);
      setFieldValue('schedulingPassword', schedule.scheduleDetails.schedulingPassword);
      setFieldValue('invoice', schedule.scheduleCargo.invoice);
      setFieldValue('weight', schedule.scheduleCargo.weight);
      setFieldValue('pallet', schedule.scheduleCargo.pallet);
      setFieldValue('shippingValue', schedule.scheduleCargo.shippingValue);
      setFieldValue('cubagem', Number(schedule.cubagem));
      setFieldValue('idToCompareCompanyOrigin', schedule.scheduleClientOrigin.client?.id);
      setFieldValue('driver.name', schedule.driver?.name);
      setFieldValue('driver.cpf', schedule.driver?.cpf);
      setFieldValue('driver.rg', schedule.driver?.rg);
      setFieldValue('driver.cnh', schedule.driver?.cnhNumber);
      setFieldValue('driver.telephone', schedule.driver?.firstPhone);

      setFieldValue('vehicle.mainBoard', schedule.vehicle?.mainBoard);
      setFieldValue('vehicle.secondBoard', schedule.vehicle?.secondBoard);
      setFieldValue('vehicle.thirdBoard', schedule.vehicle?.thirdBoard);

      setFieldValue('hasOrigin', true);
    };

    const handleCompleteInputsDestiny = (id: number, clientType: string, index: number) => {
      if (clientType === 'PARCEIRO' && id) {
        clientDetailsService(String(id)).then((client) => {
          setFieldValue(`destinations[${index}].cnpj`, client.cnpj);
          setFieldValue(`destinations[${index}].zipCode`, client.address?.cep);
          setFieldValue(`destinations[${index}].city`, client.address?.city);
          setFieldValue(`destinations[${index}].state`, client.address?.uf);
          setFieldValue(`destinations[${index}].complement`, client.address?.complement);
          setFieldValue(`destinations[${index}].neighborhood`, client.address?.neighborhood);
          setFieldValue(`destinations[${index}].number`, client.address?.numberHouse);
          setFieldValue(`destinations[${index}].street`, client.address?.street);
          setFieldValue(`destinations[${index}].name`, client.fantasyName);
          setFieldValue(`destinations[${index}].id`, client.id);
          setFieldValue(`destinations[${index}].type`, 'CLIENT');
          setFieldValue(`destinationType[${index}]`, 'PARCEIRO');
        });
      } else if (clientType === 'LOCALIDADE' && id) {
        locationDetailsService(String(id)).then((locality) => {
          setFieldValue(`destinations[${index}].cnpj`, locality.cnpj);
          setFieldValue(`destinations[${index}].zipCode`, locality.address?.cep);
          setFieldValue(`destinations[${index}].city`, locality.address?.city);
          setFieldValue(`destinations[${index}].state`, locality.address?.uf);
          setFieldValue(`destinations[${index}].complement`, locality.address?.complement);
          setFieldValue(`destinations[${index}].neighborhood`, locality.address?.neighborhood);
          setFieldValue(`destinations[${index}].number`, locality.address?.numberHouse);
          setFieldValue(`destinations[${index}].street`, locality.address?.street);
          setFieldValue(`destinations[${index}].name`, locality.name);
          setFieldValue(`destinations[${index}].id`, locality.id);
          setFieldValue(`destinations[${index}].type`, 'LOCALITY');
          setFieldValue(`destinationType[${index}]`, 'LOCALIDADE');
        });
      }
    };

    const handleValidation = useCallback(() => {
      const destinationValidation = values.destinations?.some((destiny: any) => !destiny?.cnpj);

      if (
        !moment(values.conclusionDate).isValid() ||
        destinationValidation ||
        !values.serviceType ||
        !values.idOrigin
      ) {
        setHasError(true);
        return;
      }
      handleSubmit();
    }, [values.conclusionDate, values.destinations, values.serviceType, values.idOrigin, handleSubmit]);

    const handleRemoveDestinationRow = (id: number) => {
      let indexArray = 0;
      const updatedDestination = values.destinations?.filter((destiny: any, index: number) => {
        if (destiny.id !== id) {
          return destiny;
        }
        indexArray = index;
      });
      const updatedDestinationType = values.destinationType?.filter(
        (destiny: string, index: number) => index !== indexArray,
      );
      setFieldValue('destinations', updatedDestination);
      setFieldValue('destinationType', updatedDestinationType);
    };

    const handleFile = (urlPhotos: ReworkFileUrl[]) => {
      const urlsPhotosList = urlPhotos.map((photos) => photos.file);
      setFieldValue('urlPhotos', urlsPhotosList);
    };

    const renderDestinations = useMemo(() => {
      const destinationsValues = values.destinations?.map((destiny: any, index: number) => {
        const selectFieldLabel =
          values?.destinationType[index] === 'LOCALIDADE' ? 'Nome da Unidade *' : 'Nome do Parceiro *';

        const destinyType = values?.destinationType[index] === 'LOCALIDADE' ? 'LOCALITY' : 'CLIENT';

        return (
          <div key={destiny.id}>
            <LineSectionStyled
              style={{
                borderTop: index > 0 ? 'solid 1px #d1d1d1' : '',
                marginTop: 5,
              }}
              columns="1fr 4fr 1fr"
            >
              <Select
                label="Tipo *"
                id="type"
                name="type"
                value={values?.destinationType[index]}
                setFieldValue={(e, value) => {
                  setFieldValue(`destinationType[${index}]`, value);
                }}
                options={optionClientType}
              />
              <AsyncSelectField
                label={selectFieldLabel}
                id="destinationName"
                name="destinationName"
                cacheOptions={true}
                defaultOptions={true}
                isClearable={true}
                defaultValue={{
                  label: destiny?.name ?? destiny?.tradeName ?? '',
                  value: Number(destiny?.id),
                }}
                onChange={async (e) =>
                  handleCompleteInputsDestiny((e && e.value) ?? 0, values?.destinationType[index], index)
                }
                apiCallFunction={async (e: string) => await searchOriginOrDestiny(e, destinyType)}
                loadOptions={async (e: string) => await searchOriginOrDestiny(e, destinyType)}
                hasError={hasError && !destiny.name}
                errorMessage="Campo Obrigatório"
              />
              <InputFieldCnpj
                label="CNPJ"
                id="cnpj"
                name="cnpj"
                type="text"
                onChange={handleChange}
                value={destiny?.cnpj}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="0.9fr 2fr 2fr 0.4fr">
              <InputField
                label="CEP"
                id="cep"
                name="cep"
                type="text"
                onChange={handleChange}
                value={destiny?.zipCode ?? destiny?.cep}
                disabled
              />
              <InputField
                label="Bairro"
                id="bairro"
                name="bairro"
                type="text"
                onChange={handleChange}
                value={destiny?.neighborhood}
                disabled
              />
              <InputField
                label="Cidade"
                id="cidade"
                name="cidade"
                type="text"
                onChange={handleChange}
                value={destiny?.city}
                disabled
              />
              <InputField
                label="UF"
                id="uf"
                name="uf"
                type="text"
                onChange={handleChange}
                value={destiny?.state ?? destiny?.uf}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="5fr 1fr 3fr">
              <InputField
                label="Endereço"
                id="endereco"
                name="endereco"
                type="text"
                onChange={handleChange}
                value={destiny?.street}
                disabled
              />
              <InputField
                label="Nº"
                id="numero"
                name="numero"
                type="text"
                onChange={handleChange}
                value={destiny?.number ?? destiny?.houseNumber}
                disabled
              />
              <InputField
                label="Complemento"
                id="complemento"
                name="complemento"
                type="text"
                onChange={handleChange}
                value={destiny?.complement}
                disabled
              />
              <div
                style={{
                  marginTop: 33,
                  display: values.destinations.length == 1 ? 'none' : 'flex',
                }}
              >
                <Button
                  title="Deletar"
                  bgColor="newRed"
                  callback={() => handleRemoveDestinationRow(destiny?.id)}
                  icon={<DeleteIcon />}
                  size="very-small"
                />
              </div>
            </LineSectionStyled>
          </div>
        );
      });

      return destinationsValues;
    }, [values.destinations, values.destinationType, hasError]);

    const renderOrigins = useMemo(() => {
      return (
        <div key={client?.id}>
          <LineSectionStyled columns="1fr 3fr 1fr">
            <Select
              label="Tipo"
              id="type"
              name="type"
              value={client?.id ? 'PARCEIRO' : ''}
              setFieldValue={() => {}}
              options={optionClientType}
              disabled
            />
            <AsyncSelectField
              label={'Nome'}
              id="originName"
              name="originName"
              cacheOptions={true}
              defaultOptions={true}
              isClearable={false}
              defaultValue={{
                label: client?.tradeName ?? '',
                value: Number(client?.id),
              }}
              onChange={async () => {}}
              apiCallFunction={async () => ''}
              loadOptions={async () => ''}
              disabled
            />
            <InputField
              label="CEP"
              id="cep"
              name="cep"
              type="text"
              onChange={() => {}}
              value={client?.address.cep ?? ''}
              disabled
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 3fr 1fr">
            <InputField
              label="Bairro"
              id="bairro"
              name="bairro"
              type="text"
              onChange={() => {}}
              value={client?.address.neighborhood ?? ''}
              disabled
            />
            <InputField
              label="Cidade"
              id="cidade"
              name="cidade"
              type="text"
              onChange={() => {}}
              value={client?.address.city ?? ''}
              disabled
            />
            <InputField
              label="UF"
              id="uf"
              name="uf"
              type="text"
              disabled
              onChange={() => {}}
              value={client?.address.uf ?? ''}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="2fr 0.3fr 1fr">
            <InputField
              label="Endereço"
              id="endereco"
              name="endereco"
              type="text"
              disabled
              onChange={() => {}}
              value={client?.address.street ?? ''}
            />
            <InputField
              label="Nº"
              id="numero"
              name="numero"
              type="text"
              disabled
              onChange={() => {}}
              value={client?.address.numberHouse ?? ''}
            />
            <InputField
              label="Complemento"
              id="complemento"
              name="complemento"
              type="text"
              disabled
              onChange={() => {}}
              value={client?.address.complement ?? ''}
            />
          </LineSectionStyled>
        </div>
      );
    }, [client]);

    useEffect(() => {
      handleGetScheduleByIdService(String(values.scheduleId)).then((schedule) => {
        setFieldValue('idToCompareCompanyOrigin', schedule.companyId);
        setFieldValue('driver.name', schedule.driver?.name);
        setFieldValue('driver.cpf', schedule.driver?.cpf);
        setFieldValue('driver.rg', schedule.driver?.rg);
        setFieldValue('driver.cnh', schedule.driver?.cnhNumber);
        setFieldValue('driver.telephone', schedule.driver?.firstPhone);

        setFieldValue('vehicle.mainBoard', schedule.vehicle?.mainBoard);
        setFieldValue('vehicle.secondBoard', schedule.vehicle?.secondBoard);
        setFieldValue('vehicle.thirdBoard', schedule.vehicle?.thirdBoard);
      });
      if (values.collectDate) {
        setDisableCollectDate(true);
      }
    }, []);

    useEffect(() => {
      if (values.idOrigin) {
        clientDetailsService(String(values.idOrigin)).then((client) => {
          setClient(client);
        });
      }
    }, [values.idOrigin]);

    return (
      <FormStyled>
        <SectionStyled name="Serviço">
          <LineSectionStyled columns="0.3fr 0.65fr 1fr 1fr 1fr">
            <InputField label="ID" id="id" name="id" type="text" onChange={handleChange} value={values.id} disabled />

            <Select
              label="Tipo de Serviço *"
              id="serviceType"
              name="serviceType"
              value={values.serviceType}
              setFieldValue={setFieldValue}
              hasError={hasError && !values.serviceType}
              errorMessage="Campo Obrigatório"
              options={optionsServiceType}
            />

            <WrapperDateTimePikers>
              <DateTimePikers
                label="Data Programada"
                format="DD/MM/YYYY  HH:mm"
                views={['year', 'month', 'day', 'hours', 'minutes']}
                value={dayjs(values.dateScheduled)}
                minDate={dayjs().startOf('day')}
                onChange={(date) => {
                  setFieldValue('dateScheduled', date?.toISOString());
                }}
              />
            </WrapperDateTimePikers>

            <WrapperDateTimePikers>
              <DateTimePikers
                label="Data da Conclusão *"
                format="DD/MM/YYYY  HH:mm"
                views={['year', 'month', 'day', 'hours', 'minutes']}
                value={dayjs(values.conclusionDate)}
                minDate={dayjs().startOf('day')}
                onChange={(date) => {
                  setFieldValue('conclusionDate', date?.toISOString());
                }}
                validateField={hasError && !values.conclusionDate}
                errorMessage="Campo Obrigatório"
              />
            </WrapperDateTimePikers>

            <AsyncSelectField
              label="Cliente *"
              id="idOrigin"
              name="idOrigin"
              cacheOptions={true}
              defaultOptions={true}
              isClearable={true}
              defaultValue={{
                label: String(values.originName === undefined ? '' : values.originName),
                value: Number(values.idOrigin),
              }}
              onChange={async (e) => {
                setFieldValue('idOrigin', e && e.value);
                setFieldValue('originType', 'PARCEIRO');
              }}
              apiCallFunction={async (e: string) => await searchOriginOrDestiny(e, 'CLIENT')}
              loadOptions={async (e: string) => await searchOriginOrDestiny(e, 'CLIENT')}
              hasError={hasError && !values.idOrigin}
              errorMessage={!values.idOrigin ? 'Campo Obrigatório' : 'Cliente divergente da origem da carga'}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 1fr 1fr 1fr">
            <InputCurrency
              decimalSeparator=","
              groupSeparator="."
              label="Valor Total do Serviço"
              id="serviceValue"
              name="serviceValue"
              prefix={'R$ '}
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.serviceValue}
            />
            <InputField
              label="Quantidade de Paletes"
              id="reworkedPallets"
              name="reworkedPallets"
              type="number"
              value={values.reworkedPallets ?? 0}
              onChange={handleChange}
              hasError={!!errors.reworkedPallets && !!touched.reworkedPallets}
              errorMessage={String(errors.reworkedPallets)}
            />
            <InputField
              label="Nota Fiscal do Serviço"
              id="invoiceService"
              name="invoiceService"
              type="text"
              value={values.invoiceService}
              onChange={handleChange}
              hasError={!!errors.invoiceService && !!touched.invoiceService}
              errorMessage={String(errors.invoiceService)}
            />
            <InputField
              label="Senha"
              id="servicePassword"
              name="servicePassword"
              type="text"
              onChange={handleChange}
              value={values.servicePassword}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 1fr 2.05fr">
            <InputField
              label="Pedido"
              id="order"
              name="order"
              type="text"
              value={values.order}
              onChange={handleChange}
              hasError={!!errors.order && !!touched.order}
              errorMessage={String(errors.order)}
            />
            <InputField
              label="Número da TR"
              id="trNumber"
              name="trNumber"
              type="text"
              value={values.trNumber}
              onChange={handleChange}
            />

            <LineSectionStyled columns="1fr">
              <InputField
                label="Observações"
                id="observation"
                name="observation"
                type="text"
                value={values.observation}
                onChange={handleChange}
                hasError={!!errors.observation && !!touched.observation}
                errorMessage={String(errors.observation)}
              />
            </LineSectionStyled>
          </LineSectionStyled>

          <ContainerDestinyStyled>
            <SectionDivider label="Origem da Carga" />
            <SectionStyled name="origem">
              <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr 1fr">
                <LineSectionStyled columns="1fr 0.1fr">
                  <InputField
                    label="ID da Viagem"
                    id="scheduleId"
                    name="scheduleId"
                    type="text"
                    value={values.scheduleId}
                    onChange={handleChange}
                    onBlur={handleCompleteInputSchedule}
                    hasError={!!errors.scheduleId && !!touched.scheduleId}
                    errorMessage={String(errors.scheduleId)}
                  />
                  <div style={{ marginTop: 21 }}>
                    <Button
                      title="Buscar"
                      bgColor="aqua"
                      callback={handleCompleteInputSchedule}
                      icon={<SearchTwoToneIcon />}
                      size="small"
                    />
                  </div>
                </LineSectionStyled>

                <WrapperDateTimePikers>
                  <DateTimePikers
                    disabled={disableCollectDate}
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                    label="Data da Coleta"
                    format="DD/MM/YYYY  HH:mm"
                    value={dayjs(values.collectDate)}
                    minDate={dayjs().startOf('day')}
                    onChange={(date) => {
                      setFieldValue('collectDate', date?.toISOString());
                    }}
                  />
                </WrapperDateTimePikers>
              </LineSectionStyled>

              <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr 1fr">
                <InputField
                  label="Transportadora"
                  id="shippingCompanyName"
                  name="shippingCompanyName"
                  type="text"
                  onChange={handleChange}
                  value={values.shippingCompanyName}
                />
                <InputField
                  label="Número Interno"
                  id="internalNumber"
                  name="internalNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.internalNumber}
                />
                <InputField
                  label="Número OC Interna"
                  id="ocInternalNumber"
                  name="ocInternalNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.ocInternalNumber}
                />
                <InputField
                  label="Número do Embarque"
                  id="boardingNumber"
                  name="boardingNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.boardingNumber}
                />
                <InputField
                  label="Senha"
                  id="schedulingPassword"
                  name="schedulingPassword"
                  type="text"
                  onChange={handleChange}
                  value={values.schedulingPassword}
                />
                <InputField
                  label="Nota Fiscal"
                  id="invoice"
                  name="invoice"
                  type="text"
                  onChange={handleChange}
                  value={values.invoice}
                />
              </LineSectionStyled>

              <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr">
                <InputCurrency
                  label="Peso (kg)"
                  id="weight"
                  name="weight"
                  type="number"
                  suffix=" Kg"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.weight)}
                />
                <InputCurrency
                  label="Cubagem (m3)"
                  id="cubagem"
                  name="cubagem"
                  type="number"
                  suffix=" m³"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.cubagem)}
                />
                <InputCurrency
                  label="Volume(s)"
                  id="volume"
                  name="volume"
                  type="number"
                  suffix=" vol"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.volume ?? 0)}
                />

                <InputCurrency
                  decimalSeparator=","
                  groupSeparator="."
                  label="Valor da Mercadoria"
                  id="shippingValue"
                  name="shippingValue"
                  prefix={'R$ '}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={values.shippingValue}
                />
                <InputField
                  label="Paletes"
                  id="pallet"
                  name="pallet"
                  type="number"
                  value={values.pallet}
                  onChange={handleChange}
                />
              </LineSectionStyled>
            </SectionStyled>
          </ContainerDestinyStyled>

          <ContainerDestinyStyled>
            <SectionDivider label="Dados do Motorista e Veículo" />
            <SectionStyled name="origem">
              <LineSectionStyled columns="1fr 1fr 1fr 1fr">
                <InputField
                  label="Nome"
                  id="driver.name"
                  name="driver.name"
                  type="text"
                  onChange={handleChange}
                  value={values.driver?.name}
                />
                <InputField
                  label="CPF"
                  id="driver.cpf"
                  name="driver.cpf"
                  type="text"
                  onChange={handleChange}
                  value={values.driver?.cpf}
                />
                <InputField
                  label="RG"
                  id="driver?.rg"
                  name="driver?.rg"
                  type="text"
                  onChange={handleChange}
                  value={values.driver?.rg}
                />
                <InputField
                  label="CNH"
                  id="driver.cnh"
                  name="driver.cnh"
                  type="text"
                  onChange={handleChange}
                  value={values.driver?.cnh}
                />
              </LineSectionStyled>

              <LineSectionStyled columns="1fr 1fr 1fr 1fr">
                <InputField
                  label="Telefone"
                  id="driver.telephone"
                  name="driver.telephone"
                  type="text"
                  onChange={handleChange}
                  value={values.driver?.telephone}
                />
                <InputField
                  label="Placa Principal"
                  id="vehicle.mainBoard"
                  name="vehicle.mainBoard"
                  type="text"
                  onChange={handleChange}
                  value={values.vehicle?.mainBoard}
                />
                <InputField
                  label="Placa 2"
                  id="vehicle.secondBoard"
                  name="vehicle.secondBoard"
                  type="text"
                  onChange={handleChange}
                  value={values.vehicle?.secondBoard}
                />
                <InputField
                  label="Placa 3"
                  id="vehicle.thirdBoard"
                  name="vehicle.thirdBoard"
                  type="text"
                  onChange={handleChange}
                  value={values.vehicle?.thirdBoard}
                />
              </LineSectionStyled>
            </SectionStyled>
          </ContainerDestinyStyled>

          <ContainerDestinyStyled>
            <SectionDivider label="Detalhes da Origem" />
            <SectionStyled name="Detalhes da Origem">{renderOrigins}</SectionStyled>
          </ContainerDestinyStyled>

          <SectionDivider label="Destino(s)" />

          <SectionStyled name="Free Time">
            {renderDestinations}
            <div style={{ marginTop: 15 }}>
              <Button
                title="Adicionar"
                bgColor="blue"
                callback={() => {
                  setFieldValue(
                    `destinations[${values.destinations.length === 1 ? 0 + 1 : values.destinations.length}]`,

                    {
                      ...destinationInitialValue,
                      id: values.destinations.length,
                    },
                  );
                  setFieldValue('destinationType', [...values.destinationType, 'PARCEIRO']);
                }}
                icon={<AddIcon />}
                size="very-small"
              />
            </div>
          </SectionStyled>
        </SectionStyled>

        <SectionDivider label="Registro de Horários" />
        <SectionStyled name="origem">
          <LineSectionStyled columns="1fr 1fr 1fr">
            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data do Agendamento"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.schedulingDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('schedulingDate', date?.toISOString());
              }}
            />

            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data Ínicio do Retrabalho"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.startReworkDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('startReworkDate', date?.toISOString());
              }}
            />

            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data Término do Retrabalho"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.endReworkDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('endReworkDate', date?.toISOString());
              }}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 1fr 1fr">
            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data Puxada"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.pullDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('pullDate', date?.toISOString());
              }}
            />

            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data Recebimento"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.receiptDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('receiptDate', date?.toISOString());
              }}
            />

            <DateTimePikers
              views={['year', 'month', 'day', 'hours', 'minutes']}
              label="Data Conclusão"
              format="DD/MM/YYYY  HH:mm"
              value={dayjs(values.endDate)}
              minDate={dayjs().startOf('day')}
              onChange={(date) => {
                setFieldValue('endDate', date?.toISOString());
              }}
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Arquivos" />
        <SectionStyled name="arquivos">
          <ReworkPhotosSectionView reworkRegisterId={values?.id} handleFile={handleFile} />
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" onPress={handleValidation} isLoading={isLoading} />
            <FooterPage.Button label="Voltar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const BackhaulInformationForm = withFormik<IRework, IRework>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleRegister(values);
    },
  })(InnerForm);

  return <BackhaulInformationForm {...hireBackhaulRegisterValues} />;
};

export default ReworkRegisterFormView;
