import { Formik } from 'formik';
import React from 'react';
import { Filter } from 'src-new/components/filter/filter.component';
import { FilterForm } from './components/filter-form/filter-form.component';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';

interface IMatchesFilterViewProps {
  matchesFiltersValues: IMatchesListParams;
  appliedFilters: number;
  handleResetFilter: () => void;
  handleModalFilter: (open: boolean) => void;
  onFilterSubmit: (values: IMatchesListParams) => void;
}

export const MatchesFilterView: React.FC<IMatchesFilterViewProps> = ({
  matchesFiltersValues,
  appliedFilters,
  handleResetFilter,
  handleModalFilter,
  onFilterSubmit,
}) => {
  return (
    <Filter
      appliedFilters={appliedFilters}
      handleClose={() => handleModalFilter(false)}
      handleResetFilter={handleResetFilter}
    >
      <Formik initialValues={matchesFiltersValues} onSubmit={onFilterSubmit}>
        <FilterForm savedFilters={[]} />
      </Formik>
    </Filter>
  );
};
