import styled, { css, keyframes } from 'styled-components';

interface IStyledProps {
  isDisabled: boolean;
}

export const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const OverlayStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
`;

export const FilterModalWrapperStyled = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0 0 10px;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  animation: ${({ isOpen }) => (isOpen ? slideIn : '')} 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const FilterModalHeaderStyled = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
`;

export const ModalCloseButtonStyled = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const FilterModalContentStyled = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`;

export const FilterModalFooterStyled = styled.div`
  height: 60px;
  padding: 16px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearButtonStyled = styled.button<IStyledProps>`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: transparent;
          border: none;
          font-size: 14px;
          font-weight: 600;
          font-family: Montserrat, serif;
          color: rgb(198, 198, 198);
          cursor: not-allowed;
        `
      : css`
          background-color: transparent;
          border: none;
          font-size: 14px;
          font-weight: 600;
          font-family: Montserrat, serif;
          color: rgb(43, 111, 246);
          cursor: pointer;
        `}
`;

export const FooterTextStyled = styled.span`
  font-size: 14px;
  font-weight: 600;
`;
