import { includes } from 'lodash';
import { IHomeActions, IHomeContext } from '../types/home.types';
import {
  HomeActionType,
  HomeBidsActionType,
  HomeCardsActionType,
  HomeMatchesActionType,
  HomeModalsActionType,
} from '../home.action';
import { homeCardsReducer } from './home-cards/home-cards.reducer';
import { IHomeCardsActions } from '../types/home-cards.types';
import { IHomeMatchesActions } from '../types/home-matches.types';
import { IHomeBidsActions } from '../types/home-bids.types';
import { IHomeModalsActions } from '../types/home-modals.types';
import { homeMatchesReducer } from './home-matches/home-matches.reducer';
import { homeBidsReducer } from './home-bids/home-bids.reducer';
import { homeModalsReducer } from './home-modals/home-modals.reducer';
import { homeReducerResetState } from './home-reducer.constants';

export const HomeReducer = (state: IHomeContext, action: IHomeActions): IHomeContext => {
  if (includes(Object.values(HomeCardsActionType), action.type)) {
    return homeCardsReducer(state, action as IHomeCardsActions);
  }

  if (includes(Object.values(HomeMatchesActionType), action.type)) {
    return homeMatchesReducer(state, action as IHomeMatchesActions);
  }

  if (includes(Object.values(HomeBidsActionType), action.type)) {
    return homeBidsReducer(state, action as IHomeBidsActions);
  }

  if (includes(Object.values(HomeModalsActionType), action.type)) {
    return homeModalsReducer(state, action as IHomeModalsActions);
  }

  if (action.type === HomeActionType.HOME_RESET) {
    return homeReducerResetState(state);
  }

  return state;
};
