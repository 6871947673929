import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getScheduleOccurrenceListService } from '../../services/get-schedule-occurrence-list/get-schedule-occurrence-list.service';

export const useGetScheduleOccurrenceList = () => {
  const { scheduleList, scheduleOccurrenceList } = useContext(ScheduleContext);

  return useCallback(async () => {
    try {
      scheduleOccurrenceList.setScheduleOccurrenceListRequest();

      const res = await getScheduleOccurrenceListService(scheduleList.modalsProps.modalOptions.ids[0]);

      scheduleOccurrenceList.setScheduleOccurrenceListSuccess(res);
    } catch (error: any) {
      scheduleOccurrenceList.setScheduleOccurrenceListError();
      showMessageFormatted({
        type: 'error',
        message: error,
      });
    }
  }, [scheduleList.modalsProps.modalOptions.ids, scheduleOccurrenceList]);
};
