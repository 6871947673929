import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import API from 'utils/API/API';
import { IFreetimeResponse } from './get-freetime.types';
import { converterFreetimeResponseToDomain } from './mappers/converter-freetime-response-to-domain/converter-freetime-response-to-domain.mapper';

export const getFreetimeByVehicleService = async (vehicleType: string, companyId: number): Promise<IFreeTimeDomain> => {
  const { data } = await API.post<IFreetimeResponse>('v2/retention-fines/vehicle-type-company', {
    vehicleType,
    companyId,
  });

  return converterFreetimeResponseToDomain(data);
};
