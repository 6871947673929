import { WeekDaysBrazil } from 'src-new/domains/week-day.domain';
import { WeekDaysGlobal } from './convert-week-days.types';

export const convertWeekDays = (weekDays: WeekDaysGlobal): WeekDaysBrazil => {
  const lowerCaseWeekDays = weekDays.toLowerCase() as WeekDaysGlobal;

  const weekDaysMap: Record<WeekDaysGlobal, WeekDaysBrazil> = {
    sun: 'dom',
    mon: 'seg',
    tue: 'ter',
    wed: 'qua',
    thu: 'qui',
    fri: 'sex',
    sat: 'sab',
  };

  return weekDaysMap[lowerCaseWeekDays] || weekDays;
};
