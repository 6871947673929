import React, { useCallback, useContext, useMemo } from 'react';
import { HeaderModalsView } from './header-modals-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { IParamsFilter } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.types';
import { convertWeighingFilters } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.util';

export const HeaderModals: React.FC = () => {
  const { weighingList } = useContext(WeighingContext);

  const weighingFilters: Array<IParamsFilter> = useMemo(
    () => convertWeighingFilters(weighingList.filters.selectedFilter),
    [weighingList.filters.selectedFilter],
  );

  const handleModalClose = useCallback(() => {
    weighingList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });
  }, [weighingList.modalsProps]);

  return (
    <HeaderModalsView
      modalOptions={weighingList.modalsProps.modalOptions}
      weighingFilters={weighingFilters}
      handleModalClose={handleModalClose}
    />
  );
};
