import styled, { css } from 'styled-components';

export const InputFileMessage = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: bold;
    color: ${theme.colors.blue};
    cursor: pointer;
  `}
`;

export const CustomInputFile = styled.input`
  display: none;
`;
