import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { answerInitialValues } from 'domain-v2/checklist';
import { useQueryClient } from 'react-query';
import { deleteChecklistDetailsService } from 'services/checklist/delete-checklist-details';

export const useDeleteeChecklistDocuments = () => {
  const { scheduleDocuments } = useContext(ScheduleContext);
  const reactQueryClient = useQueryClient()

  return useCallback(
    async (id: number) => {
      try {
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerRequest();
        await deleteChecklistDetailsService(id);
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerSuccess(answerInitialValues);
        showMessageFormatted({
          message: 'Checklist excluído com sucesso.',
          type: 'success',
        });
        reactQueryClient.invalidateQueries(['get-checklist-documents'])
      } catch (error: any) {
        scheduleDocuments.checklistDocumentsProps.setChecklistDocumentsError();
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleDocuments.checklistAnswerProps, scheduleDocuments.checklistDocumentsProps],
  );
};
