import { ICompany, ICompanyPlan, ICompanyStatus, ICompanyType } from 'domain/company';

export const PATH_TO_LIST_COMPANIES = '/empresas';
export const PATH_TO_HOME = '/home';

export const ownerTypes = ['Inbound', 'Outbound', 'Transferências', 'Torre de Controle', 'Financeiro', 'Comercial'];

export const contactTypes = ['compras', 'logistica', 'contratacao_frete', 'torre_controle', 'financeiro', 'comercial'];

export const optionsPlan: Array<{ label: string; value: ICompanyPlan }> = [
  { label: 'ESSENTIAL', value: 'basic-buyer' },
  { label: 'TRANSPORTADORA', value: 'shipping-company' },
  { label: 'PRO', value: 'pro' },
];

export const optionsStatus: Array<{ label: string; value: ICompanyStatus }> = [
  { label: 'ATIVO', value: 'active' },
  { label: 'INATIVO', value: 'inactive' },
  { label: 'SUSPENSO', value: 'suspended' },
  { label: 'BLOQUEADO', value: 'blocked' },
];

export const optionsType: Array<{ label: string; value: ICompanyType }> = [
  { label: 'EMBARCADOR', value: 'Embarcador' },
  { label: 'CONDOMÍNIO', value: 'Condomínio' },
  { label: 'TRANSPORTADORA', value: 'Transportadora' },
];

export const optionsDeadlines: Array<{ label: string; value: string }> = Array.from({ length: 118 }, (_, index) => ({
  label: `${index + 3}`,
  value: `${index + 3}`,
}));

export const optionsPaymentMethod: Array<{ label: string; value: string }> = [
  { label: 'BOLETO', value: 'Boleto' },
  { label: 'PIX', value: 'Pix' },
  { label: 'TRANSFERÊNCIA', value: 'Transferência' },
  { label: 'ABATIMENTO', value: 'Abatimento' },
];

export const optionsReceiptMethod: Array<{ label: string; value: string }> = [
  { label: 'BOLETO', value: 'Boleto' },
  { label: 'PIX', value: 'Pix' },
  { label: 'TRANSFERÊNCIA', value: 'Transferência' },
  { label: 'CRÉDITO', value: 'Crédito' },
];

export const optionsClosingCycle: Array<{ label: string; value: string }> = [
  { label: 'SEMANAL', value: 'Semanal' },
  { label: 'QUINZENAL', value: 'Quinzenal' },
  { label: 'MENSAL', value: 'Mensal' },
];

export const optionsRiskManager: Array<{ label: string; value: string }> = [
  { label: 'ABM', value: 'ABM' },
  { label: 'APISUL', value: 'Apisul' },
  { label: 'ATLAS', value: 'Atlas' },
  { label: 'BRASIL RISK', value: 'Brasil Risk' },
  { label: 'BUONNY', value: 'Buonny' },
  { label: 'MONISAT', value: 'Monisat' },
  { label: 'MONYTOR', value: 'Monytor' },
  { label: 'MUNDIAL RISK', value: 'Mundial Risk' },
  { label: 'OPEN TECH', value: 'Open Tech' },
  { label: 'SKYMARK', value: 'Skymark' },
  { label: 'SMART RISK', value: 'Smart Risk' },
  { label: 'TECNORISK', value: 'Tecnorisk' },
  { label: 'ULTRASAT', value: 'Ultrasat' },
  { label: 'OUTRA', value: 'Outra' },
];

export const initialValues: ICompany = {
  id: '0',
  name: '',
  tradeName: '',
  cnpj: '',
  login: '',
  email: '',
  ie: '',
  hasDdr: false,
  riskManager: '',
  companysId: [],
  status: 'active',
  plan: 'basic-buyer',
  contentFile: undefined,
  type: 'Embarcador',
  retentionFines: [
    {
      id: '',
      companyId: 0,
      freeTime: '0',
      hourlyFine: 0,
      dailyFine: 0,
      vehicleType: '',
      vehicleCategory: '',
      created_at: '',
    },
  ],
  branches: [
    {
      id: '',
      companyId: '',
      cnpj: '',
      fantasyName: '',
      corporateName: '',
      cep: '',
      address: '',
      number: '',
      city: '',
      state: '',
      complement: '',
    },
  ],
  contacts: [
    {
      name: '',
      email: '',
      telephone: '',
      type: 'compras',
    },
    {
      name: '',
      email: '',
      telephone: '',
      type: 'logistica',
    },
    {
      name: '',
      email: '',
      telephone: '',
      type: 'contratacao_frete',
    },
    {
      name: '',
      email: '',
      telephone: '',
      type: 'torre_controle',
    },
    {
      name: '',
      email: '',
      telephone: '',
      type: 'financeiro',
    },
    {
      name: '',
      email: '',
      telephone: '',
      type: 'comercial',
    },
  ],
  tariff: {
    dom: {
      value: 0,
    },
    seg: {
      value: 0,
    },
    ter: {
      value: 0,
    },
    qua: {
      value: 0,
    },
    qui: {
      value: 0,
    },
    sex: {
      value: 0,
    },
    sab: {
      value: 0,
    },
  },
  cep: '',
  city: '',
  neighborhood: '',
  street: '',
  numberHouse: '',
  complement: '',
  uf: '',
  paymentTerm: '',
  modalityPayment: '',
  receiptDeadline: '',
  modalityDeadline: '',
  closingCycle: '',
  feeLogshare: '',
  feeLogshareWithoutCTe: '0',
  advalorem: '',
  gris: '',
  shippingCompany: undefined,
};
