import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  IGoogleMapsDirections,
  IGoogleMapsLocation,
  IMapsDirection,
  IMapsProps,
} from 'src-new/components/google-maps/google-maps-view.types';
import { GoogleMapRendererView } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/google-map-renderer/google-map-renderer-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IRouteInfoDetails } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/google-map-renderer/google-map-renderer.types';

interface IGoogleMapsProps {
  primaryRoute?: IMapsDirection;
  secondaryRoute?: IMapsDirection;
  tertiaryRoute?: IMapsDirection;
}

export const GoogleMapRenderer: React.FC<IGoogleMapsProps> = ({ primaryRoute, secondaryRoute, tertiaryRoute }) => {
  const { scheduleExecution } = useContext(ScheduleContext);
  const [routeInfoSelected, setRouteInfoSelected] = useState<IRouteInfoDetails>({
    routeInfo: undefined,
    historyRoutes: undefined,
  });

  const getMapProps = useMemo(
    (): IMapsProps => ({
      mapType: scheduleExecution.mapAccordionProps.mapTypeActions.mapType,
      defaultCenter: secondaryRoute ? { lat: secondaryRoute?.origin.lat, lng: secondaryRoute?.origin.lng } : undefined,
    }),
    [scheduleExecution.mapAccordionProps.mapTypeActions.mapType, secondaryRoute],
  );

  const getDirections = useMemo((): IGoogleMapsDirections | undefined => {
    const directions: IGoogleMapsDirections = {
      primaryDirection: primaryRoute,
      secondaryDirection: secondaryRoute,
      tertiaryDirection: tertiaryRoute,
    };

    return directions.primaryDirection || directions.secondaryDirection || directions.tertiaryDirection
      ? directions
      : undefined;
  }, [primaryRoute, secondaryRoute, tertiaryRoute]);

  const formatDuration = useCallback((milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    return `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}m`;
  }, []);

  const calculatePermanenceTime = useCallback(
    (routeInfoSelected: IGoogleMapsLocation, historyRoutes?: Array<IGoogleMapsLocation>): string => {
      if (historyRoutes && historyRoutes.length) {
        const minLatLng = historyRoutes.reduce(
          (min, curr) => {
            return {
              lat: Math.min(min.lat, curr.lat),
              lng: Math.min(min.lng, curr.lng),
              timestamp: min.timestamp && curr.timestamp ? Math.min(min.timestamp, curr.timestamp) : 0,
            };
          },
          { lat: Infinity, lng: Infinity, timestamp: Infinity },
        );

        if (routeInfoSelected.lat === minLatLng.lat && routeInfoSelected.lng === minLatLng.lng) {
          if (routeInfoSelected.timestamp && minLatLng.timestamp) {
            const timeDifference = routeInfoSelected.timestamp - minLatLng.timestamp;
            return formatDuration(timeDifference);
          }
        }
      }

      return 'N/A';
    },
    [formatDuration],
  );

  return (
    <GoogleMapRendererView
      directions={getDirections}
      routeInfoSelected={routeInfoSelected}
      setRouteInfoSelected={setRouteInfoSelected}
      mapProps={getMapProps}
      calculatePermanenceTime={calculatePermanenceTime}
    />
  );
};
