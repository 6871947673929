import { Formik } from 'formik';
import { FC, Fragment, useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseUpdateScheduleDetails } from '../../../../../../../../hooks/use-update-schedule-details/use-update-schedule-details.hook';
import { IUpdateScheduleCargoPayload } from '../../../../../../../../services/update-schedule-details/update-schedule-details.types';
import { ScheduleDetailsCommentsModalForm } from './components/schedule-details-comments-modal-form/schedule-details-comments-modal-form.component';
import { ScheduleDetailsCommentsView } from './schedule-details-comments.component-view';

export const ScheduleDetailsComments: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleDetailsHook = UseUpdateScheduleDetails();

  const handleOpenModalComments = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationOpen('comments');
  }, [scheduleDetails]);

  const handleCloseModalComments = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails]);

  const handleSubmit = useCallback(
    (values: IUpdateScheduleCargoPayload) => {
      updateScheduleDetailsHook(values);
      handleCloseModalComments();
    },
    [handleCloseModalComments, updateScheduleDetailsHook],
  );

  const renderModalComments = useMemo(() => {
    if (scheduleDetails.allocationActions.allocatedEntity === 'comments' && scheduleDetails.allocationActions.isOpen) {
      return (
        <Formik
          initialValues={{
            id: scheduleDetails.scheduleDetails.id,
            comments: scheduleDetails.scheduleDetails.cargo.comments,
            cargo: scheduleDetails.scheduleDetails.cargo,
            detail: scheduleDetails.scheduleDetails.detail,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleDetailsCommentsModalForm handleCloseModalComments={handleCloseModalComments} />
        </Formik>
      );
    }

    return;
  }, [
    handleCloseModalComments,
    handleSubmit,
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.allocationActions.isOpen,
    scheduleDetails.scheduleDetails.cargo,
    scheduleDetails.scheduleDetails.detail,
    scheduleDetails.scheduleDetails.id,
  ]);

  return (
    <Fragment>
      {renderModalComments}
      <ScheduleDetailsCommentsView
        cargo={scheduleDetails.scheduleDetails.cargo}
        handleOpenModalComments={handleOpenModalComments}
      />
    </Fragment>
  );
};
