import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { EquipmentVoucherUpdatePageBodyView } from './equipment-voucher-update-page-body-view.component';
import { useGetPalletById } from 'src-new/pages/financial/hooks/use-get-pallet-by-id/use-get-pallet-by-id.hook';
import { IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';
import { FinancialContext } from 'src-new/pages/financial/contexts/financial/financial.context';
import { useUpdatePalletForm } from 'src-new/pages/financial/hooks/use-update-pallet-form/use-update-pallet-form.hook';

export const EquipmentVoucherUpdatePageBody: React.FC = () => {
  const { palletForm } = useContext(FinancialContext);
  const { getPalletDetails } = palletForm;

  const getPalletByIdHook = useGetPalletById();
  const updatePalletFormHook = useUpdatePalletForm();

  const handleSubmit = useCallback((values: IPalletDomain) => updatePalletFormHook(values), [updatePalletFormHook]);

  const isLoading = useMemo(
    (): boolean => getPalletDetails.statusRequest === 'PROCESSING',
    [getPalletDetails.statusRequest],
  );

  useEffect(() => {
    getPalletByIdHook();
  }, [getPalletByIdHook]);

  return (
    <EquipmentVoucherUpdatePageBodyView
      palletDetails={getPalletDetails.palletDetails}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};
