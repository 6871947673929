import { useCallback, useState } from 'react';
import { DateOrTimeView, DateTimeValidationError, TimeView } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { InputDateTimePikersFormikView } from './input-date-time-pickers-formik-view.component';

interface IInputDateTimePikersFormikProps {
  label: string;
  format: string;
  value?: dayjs.Dayjs | null;
  placeholder?: string;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  views?: Array<DateOrTimeView>;
  paintDays?: boolean;
  weekDaysToPaint?: Array<number>;
  disabled?: boolean;
  disablePast?: boolean;
  errorMessage?: string;
  validateField?: boolean;
  onChange?: (date: dayjs.Dayjs | null) => void;
  onError?: (error: DateTimeValidationError, value?: dayjs.Dayjs) => void;
  onOpen?: () => void;
  shouldDisableTime?: (value: Dayjs, view: TimeView) => boolean;
  onWeekDaysToPaintClick?: (selectedDay?: Dayjs | null) => void;
}

export const InputDateTimePikersFormik = ({
  label,
  format,
  value,
  placeholder,
  minDate,
  maxDate,
  views,
  paintDays,
  weekDaysToPaint,
  disabled,
  disablePast,
  errorMessage,
  validateField,
  onChange,
  onError,
  onOpen,
  shouldDisableTime,
  onWeekDaysToPaintClick,
}: Readonly<IInputDateTimePikersFormikProps>) => {
  const [invalidDate, setInvalidDate] = useState<string | null>(null);
  const [hovered, setHovered] = useState<Dayjs | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const isInSameWeek = useCallback(
    (dayA: Dayjs, dayB?: Dayjs | null): boolean => !!dayB && dayA.isSame(dayB, 'days'),
    [],
  );

  const handleError = useCallback(
    (error: DateTimeValidationError) => {
      if (onError) {
        onError(error);
        setInvalidDate(error);
      }
    },
    [onError],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);

    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClick = useCallback(
    (isDisabledDay: boolean, day: dayjs.Dayjs) => {
      if (isDisabledDay && onWeekDaysToPaintClick) {
        onWeekDaysToPaintClick(day);
        setOpen(false);
      }
    },
    [onWeekDaysToPaintClick],
  );

  const handleHovered = useCallback((currentMonth: dayjs.Dayjs | null) => setHovered(currentMonth), []);

  return (
    <InputDateTimePikersFormikView
      label={label}
      format={format}
      invalidDate={invalidDate}
      open={open}
      hovered={hovered}
      value={value}
      placeholder={placeholder}
      minDate={minDate}
      maxDate={maxDate}
      views={views}
      paintDays={paintDays}
      weekDaysToPaint={weekDaysToPaint}
      disabled={disabled}
      disablePast={disablePast}
      errorMessage={errorMessage}
      validateField={validateField}
      isInSameWeek={isInSameWeek}
      onChange={onChange}
      handleError={handleError}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleClick={handleClick}
      handleHovered={handleHovered}
      shouldDisableTime={shouldDisableTime}
    />
  );
};
