import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import React from 'react';
import * as S from './schedule-deny-modal-view.styled';
import { ModalBark } from 'components-v2/layout/modal-bark';
import Select from 'components/select/select';
import { useFormikContext } from 'formik';
import { reasonDeny } from 'default_constants/reason-deny';
import { Button } from 'logshare-ui-kit';
import { IDenyAllocation } from '../../schedule-page-body.types';

interface IScheduleDenyModalViewProps {
  closeModal: () => void;
  handleConfirm: () => void;
}

export const ScheduleDenyModalView: React.FC<IScheduleDenyModalViewProps> = ({ closeModal, handleConfirm }) => {
  const { values, setFieldValue } = useFormikContext<IDenyAllocation>();

  return (
    <ModalBark
      handleClose={closeModal}
      size="sm"
      title="Recusar Oferta"
      subtitle="Você tem certeza que deseja recusar a oferta do frete?"
      icon={<WarningDeleteIcon />}
      alingText={true}
    >
      <S.Wrapper>
        <S.Main>
          <Select
            label="Motivo da Recusa"
            id="refusalReason"
            name="refusalReason"
            value={values.refusalReason}
            setFieldValue={setFieldValue}
            options={reasonDeny}
            isClearable
          />
        </S.Main>

        <S.ModalFooter>
          <S.WrapperButton>
            <Button
              label="Confirmar"
              size="md"
              onPress={handleConfirm}
              color="red"
              variant="solid"
              disabled={!values.refusalReason}
            />
          </S.WrapperButton>
        </S.ModalFooter>
      </S.Wrapper>
    </ModalBark>
  );
};
