import { ReactElement } from 'react';
import { HomeButtonView } from './home-button-view.component';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IHomeButtonProps {
  label: string;
  color: ThemeColorsV2Type;
  icon: ReactElement;
  fontColor?: ThemeColorsV2Type;
  onPress: () => void;
}
export const HomeButton: React.FC<IHomeButtonProps> = (props) => {
  return <HomeButtonView {...props} />;
};
