import * as Yup from 'yup';

export const UserUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(50, 'O Campo Deve Ter 50 Caracteres!')
    .required('Campo Obrigatório'),

  email: Yup.string().email().required('Campo Obrigatório'),

  telephone: Yup.string().required('Campo Obrigatório'),
});
