import { useCallback } from 'react';

import { deleteUsersService } from '../services';

const useDeleteUsersHook = () => {
  return useCallback(async (ids: number[]): Promise<boolean> => {
    return await deleteUsersService(ids);
  }, []);
};

export { useDeleteUsersHook };
