import React from 'react';

import { StickerProps } from './types/sticker-type';
import * as S from './sticker.styled';

const BackhaulStickerIconView: React.FC<StickerProps> = ({
  callback,
  title,
  bgColor,
  isButton,
  width,
  circle,
  icon,
}) => {
  return (
    <S.ContainerStickerStyled bgColor={bgColor} circle={circle} isButton={isButton} width={width} onClick={callback}>
      <S.ContentSticker>
        <S.Icon>{icon}</S.Icon>
        {title?.toUpperCase()}
      </S.ContentSticker>
    </S.ContainerStickerStyled>
  );
};

export default BackhaulStickerIconView;
