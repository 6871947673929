import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { UserContext } from 'state/user-context';
import { PurchaseOfferTableView } from './purchase-offer-table-view.component';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { useQueryClient } from 'react-query';
import { paginationInitialValues } from 'src-new/domains/list-page.domain';
import { PurchaseOfferListServiceResponse } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-list/get-purchase-offer-list.service';
import { convertPurchaseDomainToScheduleSpot } from '../../../../mappers/convert-purchase-domain-to-schedule-spot/convert-purchase-domain-to-schedule-spot.mapper';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';

export const PurchaseOfferTable: React.FC = () => {
  const { purchaseOfferModals, purchaseOfferList, purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const { setModalOpen } = purchaseOfferModals;
  const { selectedIds, setSelectIds } = purchaseOfferList;
  const { purchaseOfferMatchProposal } = purchaseOfferMatchList;
  const { setPurchaseOfferMatchProposalRequest, setPurchaseOfferMatchProposalSuccess } = purchaseOfferMatchProposal;
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<IPurchaseOfferListFilterDomain>();
  const { filters, setFilters } = pageHeaderFilterContext;
  const { loading } = initialLoading;
  const { isAdmin } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const filtersRef = useRef(filters);

  filtersRef.current = filters;

  const cache = queryClient.getQueryData<PurchaseOfferListServiceResponse>(['purchase-offer-list-table', filters]);

  const getMetaData = useMemo(() => {
    if (cache) return cache.meta;
    return paginationInitialValues;
  }, [cache]);

  const handleOpenModalMatch = useCallback(
    (purchaseOfferProposal: IPurchaseOfferListDomain) => {
      setPurchaseOfferMatchProposalRequest();
      setModalOpen({ modalType: 'purchase_offer_match' });
      setPurchaseOfferMatchProposalSuccess(purchaseOfferProposal);
    },
    [setModalOpen, setPurchaseOfferMatchProposalRequest, setPurchaseOfferMatchProposalSuccess],
  );

  const handleScheduleSpotPage = useCallback(
    (purchaseOfferProposal: IPurchaseOfferListDomain) => {
      const scheduleSpot = convertPurchaseDomainToScheduleSpot(purchaseOfferProposal);

      navigate('/alocacao/novo', { state: scheduleSpot });
    },
    [navigate],
  );

  const handleLineClick = useCallback(
    (id: number) => {
      navigate(`/ofertas-compra/atualizar/${id}`);
    },
    [navigate],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      setFilters({ ...filtersRef.current, orderBy: order, column: columnName });
    },
    [setFilters],
  );

  const handleSelectIds = useCallback(
    (checked: boolean, id: number) => {
      if (checked) {
        const newIds = [...selectedIds, id];
        return setSelectIds(newIds);
      }
      const removeIds = selectedIds.filter((removeId) => removeId !== id);
      setSelectIds(removeIds);
    },
    [selectedIds, setSelectIds],
  );

  const getPurchaseOffer = useMemo(() => purchaseOfferList.purchaseOfferList, [purchaseOfferList.purchaseOfferList]);

  const isLoading = useMemo(() => loading, [loading]);

  const isEmpty = useMemo(
    () => purchaseOfferList.statusRequest === 'SUCCESS' && !getPurchaseOffer.length,
    [getPurchaseOffer.length, purchaseOfferList.statusRequest],
  );

  const isLazyLoading = useMemo(
    () => purchaseOfferList.statusRequest === 'PROCESSING' && !loading,
    [loading, purchaseOfferList.statusRequest],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: filters.column,
      order: filters.orderBy,
      handleClick: handleOrdering,
    };
  }, [handleOrdering, filters.column, filters.orderBy]);

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (page < getMetaData.totalPages) {
      setFilters({ ...filtersRef.current, page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMetaData.totalPages, page]);

  return (
    <PurchaseOfferTableView
      purchaseOffer={getPurchaseOffer}
      isAdmin={isAdmin}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      infiniteScrollProps={infiniteScrollProps}
      isEmpty={isEmpty}
      selectedIds={selectedIds}
      handleLineClick={handleLineClick}
      handleSelectIds={handleSelectIds}
      handleOpenModalMatch={handleOpenModalMatch}
      handleScheduleSpotPage={handleScheduleSpotPage}
    />
  );
};
