import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDashboardDetails } from '../hooks/use-dashboard-details/use-dashboard-details.hook';

import { initialValues } from './initial-values/initial-values';
import DashboardContentView from './dashboard-content-view';

import { UserContext } from 'state/user-context';
import { IDashboard } from 'domain/dashboard';

const DashboardContent: React.FC = () => {
  const [dashboard, setDashboard] = useState<IDashboard>(initialValues);

  const { user } = useContext(UserContext);

  const dashboardDetails = useDashboardDetails();

  useEffect(() => {
    async function run() {
      const response = await dashboardDetails({
        companyId: user?.companyId,
        startDate: '2024-01-01',
        endDate: '2024-12-31',
        origin: '',
        destiny: '',
        shippingCompanyId: '',
      });
      setDashboard(response);
    }

    run();
  }, [user?.companyId]);

  const dashboardFilter = useCallback(
    async (startDate: string, endDate: string, origin: string, destiny: string, shippingCompanyId: string) => {
      const response = await dashboardDetails({
        companyId: user?.companyId,
        startDate: startDate,
        endDate: endDate,
        origin: !origin ? '' : origin,
        destiny: !destiny ? '' : destiny,
        shippingCompanyId: !shippingCompanyId ? '' : shippingCompanyId,
      });
      setDashboard(response);
    },
    [user],
  );

  return <DashboardContentView values={dashboard} dashboardFilter={dashboardFilter} />;
};

export default DashboardContent;
