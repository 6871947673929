type StatusEntity = 'active' | 'inactive';
type StatusDomain = 'ativo' | 'inativo';

export const convertStatusEntityToDomainMapper = (statusEntity: StatusEntity): StatusDomain => {
  switch (statusEntity) {
    case 'active':
      return 'ativo';
    case 'inactive':
      return 'inativo';
  }
};
