import { useCallback } from 'react';

import { occurrenceDeleteService } from 'pages/occurrence/services/occurrence-delete/occurrence-delete.service';

const useDeleteOccurrenceHook = () => {
  return useCallback(async (ids: number[]): Promise<boolean> => {
    return await occurrenceDeleteService(ids);
  }, []);
};

export { useDeleteOccurrenceHook };
