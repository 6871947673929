import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IHomeButtonProps {
  color: ThemeColorsV2Type;
  fontColor?: ThemeColorsV2Type;
}
export const ButtonWrapper = styled.button<IHomeButtonProps>`
  ${({ theme, color, fontColor = ThemeColorsV2Type.White }) => css`
    height: 45px;
    padding: 10px;
    font-family: Montserrat;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: ${theme.colorsV2[fontColor]};
    background-color: ${theme.colorsV2[color]};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
