import API from 'utils/API/API';
import { GetUploadFilesResponse } from 'src-new/pages/registrations/services/get-upload-files/get-upload-files.types';
import { UploadFileDomain } from 'src-new/pages/registrations/domains/upload-file.domain';
import { convertUploadFileResponseToDomain } from 'src-new/pages/registrations/services/get-upload-files/mappers/convert-upload-file-response-to-domain/convert-upload-file-response-to-domain.mapper';

export const getUploadFilesService = async (module: string, code: number): Promise<Array<UploadFileDomain>> => {
  const { data } = await API.get<GetUploadFilesResponse>('v2/file/management', {
    params: {
      module: module,
      codigo: code,
    },
  });

  return data.map(convertUploadFileResponseToDomain);
};
