import API from 'utils/API/API';
import { convertRiskManagerResponseToDomain } from 'src-new/pages/registrations/pages/driver/pages/driver-update/services/get-risk-manager-list/mappers/convert-risk-manager-response-to-domain/convert-risk-manager-response-to-domain.mapper';
import { IGetRiskManagerResponse } from 'src-new/pages/registrations/pages/driver/pages/driver-update/services/get-risk-manager-list/get-risk-manager-list.types';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';

export const getRiskManagerService = async (driverId: number): Promise<Array<IRiskManagementDomain>> => {
  const { data } = await API.get<Array<IGetRiskManagerResponse>>(`drivers/risk-manager/${driverId}`);

  return data.map(convertRiskManagerResponseToDomain);
};
