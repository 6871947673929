import { updateStatusCodeScheduleService } from 'pages/freight/services/use-status-code-update/use-status-code-update.service';
import { hoursDetailsUpdateService } from 'pages/freight/services/hours-details-update/cargo-details-update.service';

const dateToStatusMap: any = {
  finishDate: 'IN90',
  loadDate: 'IN70',
  checkoutDate: 'IN60',
  checkinDate: 'IN40',
  startDate: 'IN20',
  acceptDate: 'IN11',
};

const getStatusFromDates = (values: any) => {
  for (const dateField in dateToStatusMap) {
    if (values[dateField]) {
      return dateToStatusMap[dateField];
    }
  }
  return '';
};

const useUpdateHoursDetailsHook = () => {
  return async (values: any, scheduleId: number, scheduleDetailId: number) => {
    await hoursDetailsUpdateService(
      scheduleDetailId,
      values.acceptDate || '',
      values.startDate || '',
      values.checkinDate || '',
      values.checkoutDate || '',
      values.loadDate || '',
      values.finishDate || '',
    );

    await updateStatusCodeScheduleService(scheduleId, getStatusFromDates(values));
  };
};

export { useUpdateHoursDetailsHook };
