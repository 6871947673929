import API from 'utils/API/API';
import { showToast } from 'components/toast/toast';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { convertTypeEntityToDomainMapper } from 'pages/location/mappers/convert-type-entity-to-domain/convert-type-entity-to-domain.mapper';

export const locationDetailsService = async (locationId: string): Promise<ILocationDomain> => {
  try {
    const { data } = await API.get(`localities/${locationId}`);
    const convertedType = convertTypeEntityToDomainMapper(data.type);
    return { ...data, outbound: data.oubound, type: convertedType, operationType: data.operationType ?? [] };
  } catch (error) {
    showToast({
      message: 'Você não tem permissão para acessar essa unidade',
      type: 'error',
    });

    // Temporary Repair to prevent white screen when user doesn't have permission to see an unity informations.
    return { id: 0 } as ILocationDomain;
  }
};
