import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';

export const purchaseOfferMatchSelectedInitialValues: IPurchaseOfferMatchListDomain = {
  id: 0,
  isFavorite: false,
  saleOfferId: 0,
  saleOfferShipperTariffId: 0,
  invoicedBy: 'EMBARCADOR',
  matchOfferId: 0,
  scheduleType: 'FTL',
  operationType: 'IN',
  company: {
    id: 0,
    name: '',
    image: '',
  },
  origin: {
    id: 0,
    name: '',
    type: '',
    address: {
      city: '',
      uf: '',
    },
  },
  destination: {
    id: 0,
    name: '',
    type: '',
    address: {
      city: '',
      uf: '',
    },
  },
  vehicle: {
    type: '',
    category: '',
  },
  capacity: {
    weight: 0,
    pallet: 0,
    cubage: 0,
  },
  values: {
    freightValue: 0,
    logshareValue: 0,
  },
  frequency: 0,
  co2Economy: 0,
  additionalDistance: 0,
  backhaulPartner: false,
  backhaulType: '',
  predominantProduct: '',
  score: {
    origin: 0,
    destination: 0,
    vehicle: 0,
  },
};
