import { FC } from 'react';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import AsyncSelectField from 'components/async-select/async-select';
import { FormikErrors } from 'formik';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { driversFilterSearchService } from 'pages/driver/services/driver-search/driver.filter-search.service';

interface IWeighingFormMountDriversViewProps {
  values: IWeighingFormRegisterDomain;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingFormRegisterDomain>>;
}

export const WeighingFormMountDriversView: FC<IWeighingFormMountDriversViewProps> = ({
  values,
  hasError,
  setFieldValue,
}) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Motorista *"
          isClearable={true}
          name="driverId"
          id="driverId"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue('driverId', e && e.value);
          }}
          apiCallFunction={driversFilterSearchService}
          loadOptions={driversFilterSearchService}
          hasError={hasError && !values.driverId}
          errorMessage="Campo Obrigatório"
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
