import {
  IScheduleListResponse,
  ScheduleClientEntity,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-list/services/get-schedule-list/get-schedule-list.types';

import { IUser } from 'domain/login';
import {
  IDestiny,
  IScheduleListDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-list/schedule-list.domain';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { convertStatusCodeSchedule } from 'src-new/utils/convert-status-code-schedule/convert-status-code-schedule.util';

export const convertScheduleListResponseToDomain = (
  scheduleResponse: IScheduleListResponse,
  user: IUser,
): IScheduleListDomain => {
  const operationBy = scheduleResponse.operation !== 'SPOT' ? scheduleResponse.company.tradeName : 'Logshare';

  const requestedBy =
    scheduleResponse.operation.includes('BACKHAUL') && scheduleResponse.companyBuyer
      ? scheduleResponse.companyBuyer.name
      : scheduleResponse.company.name;

  const createdAt = new Date(scheduleResponse.created_at);

  const transformLocation = (location: ScheduleClientEntity): IDestiny => {
    if (location.client) {
      return {
        name: location.client.tradeName,
        city: location.client.city,
        uf: location.client.uf,
      };
    }

    return {
      name: location.locality?.name ?? '',
      city: location.locality?.city ?? '',
      uf: location.locality?.uf ?? '',
    };
  };

  return {
    id: scheduleResponse.id ?? 0,
    typeSchedule: scheduleResponse.typeSchedule,
    companyName: scheduleResponse.company?.tradeName,
    companyBuyerName: scheduleResponse.companyBuyer?.tradeName,
    origin: transformLocation(scheduleResponse.scheduleClientOrigin),
    destiny: transformLocation(scheduleResponse.scheduleClientDestiny),
    levyDate: scheduleResponse.scheduleDetails.levyInitialDate?.toString(),
    deliveryDate: scheduleResponse.scheduleDetails.deliveryInitialDate?.toString(),
    finishDate: scheduleResponse.scheduleDetails.finishDate?.toString(),
    operatedBy: operationBy,
    operationType: scheduleResponse.scheduleCargo.operationType,
    haulType: scheduleResponse.operation,
    statusCode: convertStatusCodeSchedule(scheduleResponse.statusCode, user),
    cteIssuer: scheduleResponse.cteIssuer,
    requestedBy,
    vehicle: {
      vehicleType: convertVehicleType(
        scheduleResponse.vehicle?.vehicleType ?? scheduleResponse.scheduleCargo.vehicleType,
      ),
      vehicleCategory: convertVehicleCategory(
        scheduleResponse.vehicle?.vehicleCategory ?? scheduleResponse.scheduleCargo.vehicleCategoryType,
      ),
    },
    biddings: scheduleResponse.biddings,
    companyBuyerId: scheduleResponse.companyBuyer?.id ?? 0,
    pendingOccurrences: scheduleResponse.pendingOccurrences,
    scheduleInformation: {
      orders: scheduleResponse.scheduleCargo.orderNumber || '-',
      internalNumber: scheduleResponse.scheduleDetails.internalNumber || '-',
      ocInternalNumber: scheduleResponse.scheduleDetails.ocInternalNumber || '-',
      boardingNumber: scheduleResponse.scheduleDetails.boardingNumber || '-',
      schedulingPassword: scheduleResponse.scheduleDetails.schedulingPassword || '-',
    },
    myRouterValidity: scheduleResponse.myRoute?.validity
      ? new Date(createdAt.getTime() + scheduleResponse.myRoute.validity * 60 * 60 * 1000)
      : undefined,
  };
};
