import React, { useContext, useEffect, useMemo } from 'react';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { MatchesKanbanView } from './matches-kanban-view.component';
import { useGetFavoriteMatchesKanbanList } from '../../hooks/use-get-favorite-matches-kanban-list/use-get-favorite-matches-kanban-list.hook';
import { IKanbanListDetails } from './matches-kanban.types';
import { useGetInactiveMatchesKanbanList } from '../../hooks/use-get-inactive-matches-kanban-list/use-get-stabilized-matches-kanban-list.hook';
import { useGetOperatedMatchesKanbanList } from '../../hooks/use-get-operated-matches-kanban-list/use-get-operated-matches-kanban-list.hook';
import { useGetStabilizedMatchesKanbanList } from '../../hooks/use-get-stabilized-matches-kanban-list/use-get-stabilized-matches-kanban-list.hook';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import { useGetInNegotiationMatchesKanbanList } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/hooks/use-get-in-negotiation-matches-kanban-list/use-get-in-negotiation-matches-kanban-list.hook';

export const MatchesKanban: React.FC = () => {
  const { isHovered, currentSelectedMenu } = useContext(SidebarContext);
  const {
    favoriteMatchesKanbanList,
    inNegotiationMatchesKanbanList,
    operatedMatchesKanbanList,
    stabilizedMatchesKanbanList,
    inactiveMatchesKanbanList,
  } = useContext(MatchesContext);
  const getFavoriteMatchesKanbanListHook = useGetFavoriteMatchesKanbanList();
  const getInNegotiationMatchesKanbanListHook = useGetInNegotiationMatchesKanbanList();
  const getOperatedMatchesKanbanListHook = useGetOperatedMatchesKanbanList();
  const getStabilizedMatchesKanbanListHook = useGetStabilizedMatchesKanbanList();
  const getInactiveMatchesKanbanListHook = useGetInactiveMatchesKanbanList();

  useEffect(() => {
    getFavoriteMatchesKanbanListHook();
    getInNegotiationMatchesKanbanListHook();
    getOperatedMatchesKanbanListHook();
    getStabilizedMatchesKanbanListHook();
    getInactiveMatchesKanbanListHook();
  }, [
    getFavoriteMatchesKanbanListHook,
    getInNegotiationMatchesKanbanListHook,
    getInactiveMatchesKanbanListHook,
    getOperatedMatchesKanbanListHook,
    getStabilizedMatchesKanbanListHook,
  ]);

  const kanbanListDetails = useMemo((): IKanbanListDetails => {
    return {
      favoriteMatches: {
        ...favoriteMatchesKanbanList.favoriteMatchesKanbanList,
        isLoading: favoriteMatchesKanbanList.statusRequest === 'PROCESSING',
      },
      inNegotiationMatches: {
        ...inNegotiationMatchesKanbanList.inNegotiationMatchesKanbanList,
        isLoading: inNegotiationMatchesKanbanList.statusRequest === 'PROCESSING',
      },
      operatedMatches: {
        ...operatedMatchesKanbanList.operatedKanbanList,
        isLoading: operatedMatchesKanbanList.statusRequest === 'PROCESSING',
      },
      stabilizedMatches: {
        ...stabilizedMatchesKanbanList.stabilizedKanbanList,
        isLoading: stabilizedMatchesKanbanList.statusRequest === 'PROCESSING',
      },
      inactiveMatches: {
        ...inactiveMatchesKanbanList.inactiveKanbanList,
        isLoading: inactiveMatchesKanbanList.statusRequest === 'PROCESSING',
      },
    };
  }, [
    favoriteMatchesKanbanList.favoriteMatchesKanbanList,
    favoriteMatchesKanbanList.statusRequest,
    inNegotiationMatchesKanbanList.inNegotiationMatchesKanbanList,
    inNegotiationMatchesKanbanList.statusRequest,
    inactiveMatchesKanbanList.inactiveKanbanList,
    inactiveMatchesKanbanList.statusRequest,
    operatedMatchesKanbanList.operatedKanbanList,
    operatedMatchesKanbanList.statusRequest,
    stabilizedMatchesKanbanList.stabilizedKanbanList,
    stabilizedMatchesKanbanList.statusRequest,
  ]);

  const sidebarSize = useMemo((): number => {
    const menuSize = isHovered ? 268 : 0;

    const subMenuSize = currentSelectedMenu ? 185 : 0;

    return menuSize + subMenuSize;
  }, [currentSelectedMenu, isHovered]);

  return (
    <MatchesKanbanView
      kanbanListDetails={kanbanListDetails}
      sidebarSize={sidebarSize}
      isSubMenuOpen={!!currentSelectedMenu}
    />
  );
};
