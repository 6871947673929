import { useParams } from 'react-router-dom';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';

import * as S from './styled';

import { UseUpdatePlanningShipping } from 'pages-v2/my-route/hooks/update-planning-vehicle';
import { ModalPlanningType } from 'pages-v2/my-route/components/form/types/modal';
import { planningShippingInitialValues } from 'pages-v2/my-route/components/form/initial-values';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { PlanningRouteShipping } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { ModalState } from 'domain-v2/inputs/modals';
import { SelectItem } from 'domain/select-item';
import { SectionStyled } from 'components-v2/layout/section/styled';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import AsyncSelectField from 'components/async-select/async-select';

interface PlanningRouteModalProps {
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
  handleClosePlanningShippingModal: () => void;
  planningShippingModal: ModalState<{
    planningRouteIndex: number;
    planningVehicleIndex: number;
    planningShippingIndex: number;
    type: ModalPlanningType;
    payload: PlanningRouteShipping;
  }>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const PlanningShippingModal: FC<PlanningRouteModalProps> = ({
  values,
  setFieldValue,
  handleClosePlanningShippingModal,
  planningShippingModal,
  handleSubmit,
}) => {
  const { id } = useParams();

  const [planningShippingForm, setPlanningShippingForm] =
    useState<PlanningRouteShipping>(planningShippingInitialValues);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPlanningShippingForm((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleChangeInfo: any = useCallback((name: string, value: any) => {
    setPlanningShippingForm((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const apiCallShipping = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return shippingCompanyFilterSearchService(searchValue);
    }
    return [];
  }, []);

  const buttonDisabled = useMemo(() => {
    if (!planningShippingForm.companyShippingId || !planningShippingForm.plannedTrips) {
      return true;
    }

    return false;
  }, [planningShippingForm.companyShippingId, planningShippingForm.plannedTrips]);

  const handleSavePlanningShipping = useCallback(async () => {
    if (planningShippingModal.data.type === 'update') {
      if (id) UseUpdatePlanningShipping(planningShippingForm);

      handleSubmit();
      handleClosePlanningShippingModal();

      return;
    }

    setFieldValue(
      `planningRoute[${planningShippingModal.data.planningRouteIndex}].allowedVehicles[${planningShippingModal.data.planningVehicleIndex}].allowedShipping`,
      [
        ...values.planningRoute[planningShippingModal.data.planningRouteIndex].allowedVehicles[
          planningShippingModal.data.planningVehicleIndex
        ].allowedShipping,
        planningShippingForm,
      ],
    );

    handleClosePlanningShippingModal();

    if (values.id) {
      handleSubmit();
    }
  }, [setFieldValue, planningShippingForm, values]);

  useEffect(() => {
    if (planningShippingModal.data.type === 'update') {
      setPlanningShippingForm(
        values.planningRoute[planningShippingModal.data.planningRouteIndex].allowedVehicles[
          planningShippingModal.data.planningVehicleIndex
        ].allowedShipping.filter((remove) => remove.companyShippingId)[
          planningShippingModal.data.planningShippingIndex
        ],
      );
    }
  }, [planningShippingModal.open]);

  return (
    <ModalBark size={'xsm'} title={'Transportadoras Dedicadas'} handleClose={handleClosePlanningShippingModal}>
      <SectionStyled name="Informações do Planejamento">
        <LineSectionStyled columns="1fr">
          <AsyncSelectField
            placeholder=""
            label="Transportadora *"
            isClearable={true}
            name="companyShippingName"
            id="companyShippingName"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleChangeInfo('companyShippingName', e && e.label);
              handleChangeInfo('companyShippingId', e && e.value);
            }}
            value={{ label: planningShippingForm.companyShippingName, value: planningShippingForm.companyShippingId }}
            apiCallFunction={apiCallShipping || (async () => [])}
            loadOptions={apiCallShipping || (async () => [])}
            disabled={planningShippingModal.data.type === 'update'}
          />
        </LineSectionStyled>

        <LineSectionStyled columns="1fr 1fr">
          <InputField
            label="Viagens"
            id="plannedTrips"
            name="plannedTrips"
            type="number"
            value={planningShippingForm.plannedTrips}
            onChange={handleChange}
          />

          <InputCurrency
            label="Validade das ofertas (em horas)"
            id="validity"
            name="validity"
            type="number"
            allowDecimals={false}
            suffix=" Horas"
            decimalsLimit={0}
            onValueChange={(value: any) => handleChangeInfo('validity', value)}
            value={planningShippingForm.validity}
          />
        </LineSectionStyled>
      </SectionStyled>

      <S.WrapperButton>
        <Button
          label={'Salvar'}
          color={'blue'}
          onPress={handleSavePlanningShipping}
          variant={'solid'}
          size={'md'}
          disabled={buttonDisabled}
        />
      </S.WrapperButton>
    </ModalBark>
  );
};
