import { Button } from 'logshare-ui-kit';
import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { ScheduleDetailsAllocationVehicleListModal } from './components/schedule-details-allocation-vehicle-list-modal/schedule-details-allocation-vehicle-list-modal.component';
import * as S from './schedule-details-allocation-vehicle-modal-view.styled';

interface IScheduleDetailsAllocationVehicleModalViewProps {
  vehicleSelected: {
    checked: boolean;
    vehicleId: number;
  };
  handleModalClose: () => void;
  handleVehicleAllocation: (vehicleId: number) => Promise<void>;
  handleRegisterVehicle: () => void;
}

export const ScheduleDetailsAllocationVehicleModalView: FC<IScheduleDetailsAllocationVehicleModalViewProps> = ({
  vehicleSelected,
  handleModalClose,
  handleVehicleAllocation,
  handleRegisterVehicle,
}) => {
  return (
    <ModalBark
      size={'md'}
      title={<S.AllocationModalVehicleTitle>Veículos Cadastrados</S.AllocationModalVehicleTitle>}
      handleClose={handleModalClose}
    >
      <S.AllocationModalVehicleWrapper>
        <ScheduleDetailsAllocationVehicleListModal />
        <S.WrapperButtons>
          <Button label={'Cadastrar'} color={'blue'} onPress={handleRegisterVehicle} variant={'ghost'} size={'md'} />
          <Button
            label={'Avançar'}
            color={'blue'}
            onPress={() => handleVehicleAllocation(vehicleSelected.vehicleId)}
            variant={'solid'}
            size={'md'}
          />
        </S.WrapperButtons>
      </S.AllocationModalVehicleWrapper>
    </ModalBark>
  );
};
