import React, { useEffect, useMemo, useState } from 'react';
import { TagTimerView } from './tag-timer-view.component';

interface ITagTimerProps {
  tagDate: Date;
}

export const TagTimer: React.FC<ITagTimerProps> = ({ tagDate }) => {
  const [remainingTime, setRemainingTime] = useState<number>(tagDate.getTime() - Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = tagDate.getTime() - Date.now();

      if (newRemainingTime <= 0) {
        setRemainingTime(0);
        clearInterval(intervalId);
      } else {
        setRemainingTime(newRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [tagDate]);

  const days = useMemo(() => Math.floor(remainingTime / (1000 * 60 * 60 * 24)), [remainingTime]);
  const hours = useMemo(() => Math.floor((remainingTime / (1000 * 60 * 60)) % 24), [remainingTime]);
  const minutes = useMemo(() => Math.floor((remainingTime / (1000 * 60)) % 60), [remainingTime]);
  const seconds = useMemo(() => Math.floor((remainingTime / 1000) % 60), [remainingTime]);

  return <TagTimerView days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};
