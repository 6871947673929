import { IBackhaulFilter } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { IBackhaulListValues } from 'domain/backhaul';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';

export const backhaulFilterService = async (params: IBackhaulFilter): Promise<IBackhaulListValues[]> => {
  const url = `/v1/sales-offers/home?id=${params.id}&originUf=${params.originUf}&originCity=${params.originCity}&destinationUf=${params.destinationUf}&destinationCity=${params.destinationCity}&vehicleType=${params.vehicleType}&vehicleCategory=${params.vehicleCategory}&isActive=true&page=${params.page}&limit=10`;
  const { data } = await API_TRAVELS_SVC.get(url);

  return data.items;
};
