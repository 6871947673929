import { FC, useCallback, useMemo } from 'react';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { Loading } from 'src-new/components/loading/loading.component';
import {
  ISaleOfferListDomain,
  ISaleOfferListLocationsDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list.domain';
import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './sale-offer-modal-match-header-view.styled';
import { Tag } from 'src-new/components/tag/tag.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { Table } from 'src-new/components/table/table.component';
import { HotIdeal } from 'assets/icons/hot-ideal';

interface ISaleOfferModalMatchHeaderViewProps {
  isLoading: boolean;
  proposal: ISaleOfferListDomain;
}

export const SaleOfferModalMatchHeaderView: FC<ISaleOfferModalMatchHeaderViewProps> = ({ isLoading, proposal }) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: '' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Matches Potencial' },
      { value: 'Classificação' },
      { value: 'Frete Valor' },
    ];

    const columnsConfig = '0.25fr 0.15fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string | number, subtitle: string) => {
    return (
      <S.WrapperTextsInformationHeader>
        <S.Text weight={700}>{title}</S.Text>
        <S.Text size={10} weight={400} color="#7b7b7b">
          {subtitle}
        </S.Text>
      </S.WrapperTextsInformationHeader>
    );
  }, []);

  const renderCompanyImageNode = useCallback((id: number, src: string) => {
    return (
      <S.WrapperIdAndImage>
        <S.Title>{id}</S.Title>

        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: 25,
            marginTop: 3,
          }}
          src={src}
          alt={src}
        />
      </S.WrapperIdAndImage>
    );
  }, []);

  const renderLocationText = useCallback(
    (origin: ISaleOfferListLocationsDomain, destination: ISaleOfferListLocationsDomain) => {
      return (
        <S.WrapperInformationLocationsHeader>
          <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
          <div>
            <S.Text weight={700}>
              {origin.address.city} - {origin.address.uf}
            </S.Text>
            <S.Text weight={700}>
              {destination.address.city} - {destination.address.uf}
            </S.Text>
          </div>
        </S.WrapperInformationLocationsHeader>
      );
    },
    [],
  );

  const renderRows = useMemo((): IRow => {
    return {
      key: proposal.id,
      onClick: () => undefined,
      valuesColumns: [
        { value: renderCompanyImageNode(proposal.idTariff, proposal.companyImage) },
        { value: <S.Text /> },
        { value: renderLocationText(proposal.origin, proposal.destination) },
        { value: renderTitleSubtitle(proposal.vehicle.type, proposal.vehicle.category) },
        { value: renderTitleSubtitle(`${proposal.weight} KG`, `${proposal.pallets} Pallets`) },
        { value: renderTitleSubtitle(proposal.frequency, 'VIAGENS/MÊS') },
        { value: renderTitleSubtitle(proposal.totalMatchOffers, 'ROTAS POTENCIAIS') },
        {
          value: (
            <S.WrapperTags>
              <Tag
                label={proposal.operationType}
                color={ThemeColorsV2Type.DarkGray}
                variant="ghost"
                display="inline-flex"
                size="md"
              />
              <Tag
                label={proposal.cargoType}
                color={ThemeColorsV2Type.DarkGray}
                variant="ghost"
                display="inline-flex"
                size="md"
              />
              {proposal.partnerShipper !== 'todos embarcadores' && (
                <Tag
                  label="Tarifa Exclusiva"
                  color={ThemeColorsV2Type.DarkGray}
                  LeftIcon={<HotIdeal />}
                  variant="ghost"
                  display="flex"
                  size="md"
                />
              )}
            </S.WrapperTags>
          ),
        },
        { value: renderTitleSubtitle(proposal.valueTotal, '+ IMPOSTOS') },
      ],
    };
  }, [
    proposal.cargoType,
    proposal.companyImage,
    proposal.destination,
    proposal.frequency,
    proposal.id,
    proposal.idTariff,
    proposal.operationType,
    proposal.origin,
    proposal.pallets,
    proposal.partnerShipper,
    proposal.totalMatchOffers,
    proposal.valueTotal,
    proposal.vehicle.category,
    proposal.vehicle.type,
    proposal.weight,
    renderCompanyImageNode,
    renderLocationText,
    renderTitleSubtitle,
  ]);

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={[renderRows]}
        height={'165px'}
      />
    </S.Wrapper>
  );
};
