import {
  ChecklistAnswer,
  ChecklistQuestions,
  ChecklistMobileAnswer,
  ChecklistMobileQuestions,
} from 'domain-v2/checklist';

const mapUpdatedAnswers = (questions: ChecklistQuestions['questions']) => {
  return questions.map((question) => ({
    ...question,
    answer: '',
  }));
};

export const mapAnswers = (questionsValues: ChecklistQuestions, answerValues: ChecklistAnswer): ChecklistAnswer => {
  const updatedAnswers = mapUpdatedAnswers(questionsValues.questions);

  return {
    ...answerValues,
    moduleId: questionsValues.moduleId,
    answers: updatedAnswers,
  };
};

export const mapMobileAnswers = (
  questionsValues: ChecklistMobileQuestions,
  answerValues: ChecklistMobileAnswer,
): ChecklistMobileAnswer => {
  const updatedAnswers = mapUpdatedAnswers(questionsValues.questions);

  return {
    ...answerValues,
    moduleId: questionsValues.moduleId,
    shippingName: questionsValues.shippingName,
    mainBoard: questionsValues.mainBoard,
    secondBoard: questionsValues.secondBoard,
    thirdBoard: questionsValues.thirdBoard,
    answers: updatedAnswers,
  };
};
