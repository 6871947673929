import { palletFormRegisterInitialValues } from 'src-new/pages/financial/components/pallet-form/constants/pallet-form-register-initial-values.constants';
import { PalletFormActionType } from '../../financial.action';
import { financialInitialState } from '../../financial.constants';
import { IFinancialContext } from '../../types/financial.types';
import { IPalletFormActions } from '../../types/pallet-form.types';

export const palletFormReducer = (state: IFinancialContext, action: IPalletFormActions): IFinancialContext => {
  switch (action.type) {
    case PalletFormActionType.GET_PALLET_DETAILS_REQUEST:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          getPalletDetails: {
            ...financialInitialState.palletForm.getPalletDetails,
            statusRequest: 'PROCESSING',
            palletDetails: palletFormRegisterInitialValues,
          },
        },
      };
    case PalletFormActionType.GET_PALLET_DETAILS_SUCCESS:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          getPalletDetails: {
            ...financialInitialState.palletForm.getPalletDetails,
            statusRequest: 'SUCCESS',
            palletDetails: action.payload,
          },
        },
      };
    case PalletFormActionType.GET_PALLET_DETAILS_ERROR:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          getPalletDetails: {
            ...financialInitialState.palletForm.getPalletDetails,
            statusRequest: 'ERROR',
            palletDetails: palletFormRegisterInitialValues,
          },
        },
      };
    case PalletFormActionType.PALLET_FORM_SUBMIT:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          statusRequest: 'PROCESSING',
        },
      };
    case PalletFormActionType.PALLET_FORM_SUCCESS:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          statusRequest: 'SUCCESS',
        },
      };
    case PalletFormActionType.PALLET_FORM_ERROR:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          statusRequest: 'ERROR',
        },
      };
    case PalletFormActionType.PALLET_FORM_RESET:
      return {
        ...state,
        palletForm: {
          ...financialInitialState.palletForm,
          statusRequest: 'INITIAL',
          getPalletDetails: {
            ...financialInitialState.palletForm.getPalletDetails,
            statusRequest: 'INITIAL',
            palletDetails: palletFormRegisterInitialValues,
          },
        },
      };
    default:
      return state;
  }
};
