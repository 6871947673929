import { FC } from 'react';
import * as S from './sale-offer-modal-match-score-label-view.styled';

type ScoreLabel = 'ALTO' | 'MODERADO' | 'BAIXO';

interface ISaleOfferModalMatchScoreViewProps {
  label: ScoreLabel;
}

export const SaleOfferModalMatchScoreLabelView: FC<ISaleOfferModalMatchScoreViewProps> = ({ label }) => {
  return <S.Wrapper>{label}</S.Wrapper>;
};
