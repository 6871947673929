import UploadIcon from 'assets/icons/upload.icon';
import TrackingIcon from 'assets/icons/tracking.icon';
import Settings from 'assets/icons/settings.icon';
import ScheduleIcon from 'assets/icons/schedule.icon';
import RegisterIcon from 'assets/icons/register.icon';
import ProfileImage from 'assets/icons/profile-image.icon';
import NewFreightIcon from 'assets/icons/new-freight-icon';
import LogoutIcon from 'assets/icons/logout.icon';
import logoPortSantos from 'assets/icons/logo-port-santos';
import InboundIcon from 'assets/icons/inbound.icon';
import HomeIcon from 'assets/icons/home.icon';
import Financial from 'assets/icons/financial.icon';
import EyePasswordIcon from 'assets/icons/eye-password';
import EquipmentIcon from 'assets/icons/equipment.icon';
import DashBoardIcon from 'assets/icons/dashboard.icon';
import CrownIcon from 'assets/icons/crown.icon';
import BoxTwoIcon from 'assets/icons/box2.icon';
import BoxOneIcon from 'assets/icons/box1.icon';
import BackhaulIcon from 'assets/icons/backhaul.icon';
import ArrowDown from 'assets/icons/arrow-down.icon';

export default {
  icons: {
    home: HomeIcon,
    eyePasswordIcon: EyePasswordIcon,
    register: RegisterIcon,
    backhaulIcon: BackhaulIcon,
    arrowDown: ArrowDown,
    scheduleIcon: ScheduleIcon,
    trackingIcon: TrackingIcon,
    inboundIcon: InboundIcon,
    equipmentIcon: EquipmentIcon,
    financial: Financial,
    services: RegisterIcon,
    settings: Settings,
    uploadIcon: UploadIcon,
    profileImage: ProfileImage,
    LogoutIcon: LogoutIcon,
    crownIcon: CrownIcon,
    logoSantos: logoPortSantos,
    arrow: logoPortSantos,
    dollar: logoPortSantos,
    truck: logoPortSantos,
    dashboard: DashBoardIcon,
    newFreight: NewFreightIcon,
    box: BoxOneIcon,
    box2: BoxTwoIcon,
  },
  border: {
    xxRadius: '5px',
    xRadius: '10px',
    radius: '5px',
    shadow: '0 0 0 0.2px rgb(204, 204, 204) inset',
  },
  height: {
    fullView: '100vh',
  },
  width: {
    fullView: '100vw',
  },
  fontV2: {
    family: 'Montserrat',
    sizes: {
      header1: '60px',
      header2: '48px',
      header3: '36px',
      text1: '24px',
      text2: '18px',
      text3: '14px',
      text4: '12px',
      text5: '10px',
    },
  },
  toast: {
    colors: {
      error: '#E30613',
      loading: '#119895',
      success: '#1F2B4F',
    },
  },
  colorsV2: {
    aqua: '#119895',
    blue: '#12264E',
    red: '#E30613',
    green: '#5AC742',
    proYellow: '#FFB743',
    darkGray: '#5D5D5D',
    lightGray: '#C6C6C6',
    circleCautMatch: '#E1E1E1',
    white: '#FFFFFF',
    black: '#000',
    forBorder: '#D9D9D9',
    transparent: 'transparent',
    'aqua-25': '#176876',
    'aqua-50': '#16747E',
    'aqua-75': '#148085',
    'aqua-100': '#138C8D',
    'blue-25': '#1D3757',
    'blue-50': '#1C435F',
    'blue-75': '#1A4F66',
    'blue-100': '#195B6E',
    'light-gray-25': '#F2F2F2',
  },
  modalsV2: {
    background: '#0b0a0a6b',
    radius: '0.75rem',
    padding: '1.8rem',
    width: {
      xl: '1428px',
      lg: '1248px',
      md: '840px',
      sm: '740px',
      xsm: '640px',
      xxsm: '500px',
    },
    height: {
      xl: '702px',
      lg: '702px',
      md: '450px',
      sm: '375px',
      xsm: 'fit-content',
      xxsm: '250px',
    },
  },
  radiusV2: {},
  modals: {
    background: '#0b0a0a6b',
    width: {
      xlarge: '1428px',
      large: '1248px',
      medium: '768px',
      small: '480px',
    },
    height: {
      xlarge: '972px',
      large: '702px',
      medium: '540px',
      small: '380px',
    },
  },
  font: {
    family: 'Montserrat',
    light: 100,
    regular: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    sizes: {
      xxsmall: '10px',
      xsmall: '13px',
      small: '13px',
      medium: '18px',
      xmedium: '20px',
      large: '22px',
      xlarge: '24px',
      xxlarge: '28px',
    },
    height: {
      xsmall: '10px',
      small: '14px',
    },
  },
  colors: {
    backgroundModal: '#0b0a0a6b',
    white: '#FFF',
    black: '#030517',
    pink: '#E02B2B',
    blue: '#1F2B4F', // '#1d4165',
    aqua: 'rgb(0, 152, 147)',
    gray: '#D9D9D9',
    darkGray: '#8a8a8a',
    red: '#E30613',
    middleRed: '#EB2521',
    newRed: '#E30613',
    green: '#039e3a',
    yellow: '#FFA903', // '#FFCB00',
    orange: '#ed8009',
    middleBlue: '#195e6e',
    lightBlue: '#5B9BD5',
    lightGray: '#D9D9D9',
    veryLightGray: 'rgb(231, 231, 231)',
    lightVeryGray: 'gray',
    error: '#fa3030',
    backgrounds: 'rgb(235, 235, 235)',
    forBorder: '#D9D9D9',
    transparent: 'transparent',
    lightgray: 'rgb(245 245 245)',
    purple: '#59168d',
    middlePink: '#a8a8b3',
    aqua1: '#1C445F',
    aqua2: '#147D84',
    aqua3: '#119895',
  },
  spacings: {
    xxsmall: '3px',
    xsmall: '6px',
    small: '9px',
    medium: '15px',
    large: '40px',
    xlarge: '48px',
    xxlarge: '56px',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  sidebar: {
    colors: {
      gray: '#e9e9e9',
      blue: '#12264e',
      forBorder: '#D9D9D9',
    },
    menu: {
      height: '50px',
    },
    navLink: {
      color: '#000',
      fontWeight: 'normal',
    },
    navLinkActive: {
      color: '#12264e',
      fontWeight: 'bold',
    },
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },
  palette: {
    primary: {
      main: '#1F2B4F',
      light: '#1F2B4F',
      dark: '#1F2B4F',
      contrastText: '#1F2B4F',
    },
  },
  table: {
    font: {
      family: 'Montserrat',
      style: 'normal',
      lineWeight: '400',
      lineHeight: 'normal',
      sizes: {
        header: '11px',
      },
    },
    row: {
      gridGap: '10px',
      backgroundColor: {
        onMouseEnter: '#FAFAFA',
        isZebra: '#fcfcfc',
      },
    },
    header: {
      row: {
        height: '45px',
        padding: '14px 0 14px 5px',
        borderBottom: '1px solid var(--Light-gray, #C6C6C6)',
        backgroundColor: '#FAFAFA',
      },
    },
    body: {
      row: {
        height: '67px',
        padding: '12px 0px',
        borderBottom: '1px solid #C6C6C6',
        backgroundColor: '#FAFAFA',
      },
    },
  },
  colorsV4: {
    logshareBlue: '#1F2B4F',
    white: '#FFF',
    black: '#000',
  },
  modalsV4: {
    background: '#0b0a0a6b',
    radius: '0.75rem',
    padding: '1.8rem',
    width: {
      xl: '1428px',
      lg: '1248px',
      md: '940px',
      sm: '840px',
      xsm: '740px',
      xxsm: '500px',
    },
    height: {
      xl: '702px',
      lg: '702px',
      md: 'fit-content',
      sm: 'fit-content',
      xsm: 'fit-content',
      xxsm: '350px',
    },
  },
  variants: {
    primary: {
      backgroundColor: '#1F2B4F',
      color: '#FFF',
      border: '1px solid #1F2B4F',
    },
    secondary: {
      backgroundColor: '#119895',
      color: '#FFF',
      border: '1px solid #119895',
    },
    default: {
      backgroundColor: '#FFF',
      color: '#000',
      border: '1px solid #EAEAEA',
    },
    icon: {
      backgroundColor: '#FFF',
      color: '#000',
      border: '2px solid #EAEAEA',
    },
    danger: { backgroundColor: '#E30613', color: '#FFF', border: '1px solid #E30613' },
  },
} as const;
