import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateMDFeDataService } from '../../services/update-mdfe-data/update-mdfe-data.service';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getMDFeDataService } from '../../services/get-mdfe-data/get-mdfe-data.service';

export const useUpdateMDFeData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(
    async (files: FileList) => {
      try {
        scheduleDocuments.mdfeDataProps.setMDFeDataRequest();

        await updateMDFeDataService(scheduleDetails.scheduleDetails.id, files);

        const data = await getMDFeDataService(scheduleDetails.scheduleDetails.id);

        scheduleDocuments.mdfeDataProps.setMDFeDataSuccess(data);
      } catch (error: any) {
        scheduleDocuments.mdfeDataProps.setMDFeDataError();
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    },
    [scheduleDetails.scheduleDetails.id, scheduleDocuments.mdfeDataProps],
  );
};
