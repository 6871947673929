import { includes } from 'lodash';
import {
  AttachDocumentsSectionActionType,
  ContactDetailsSectionActionType,
  RegistrationsActionType,
  RiskManagementSectionActionType,
} from '../registrations.action';
import { registrationsReducerResetState } from './registrations-reducer.constants';
import { IContactDetailsSectionActions } from '../types/contact-details-section.types';
import { contactDetailsSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/contact-details-section/contact-details-section.reducer';
import {
  IRegistrationsActions,
  IRegistrationsContext,
} from 'src-new/pages/registrations/contexts/registrations/types/registrations.types';
import { IAttachDocumentsSectionActions } from '../types/attach-documents-section.types';
import { attachDocumentsSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/attach-documents-section/attach-documents-section.reducer';
import { riskManagementSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/risk-management-section/risk-management-section.reducer';
import { IRiskManagementSectionActions } from 'src-new/pages/registrations/contexts/registrations/types/risk-management-section.types';

export const registrationsReducer = (
  state: IRegistrationsContext,
  action: IRegistrationsActions,
): IRegistrationsContext => {
  if (includes(Object.values(ContactDetailsSectionActionType), action.type)) {
    return contactDetailsSectionReducer(state, action as IContactDetailsSectionActions);
  }

  if (includes(Object.values(AttachDocumentsSectionActionType), action.type)) {
    return attachDocumentsSectionReducer(state, action as IAttachDocumentsSectionActions);
  }

  if (includes(Object.values(RiskManagementSectionActionType), action.type)) {
    return riskManagementSectionReducer(state, action as IRiskManagementSectionActions);
  }

  if (action.type === RegistrationsActionType.REGISTRATIONS_RESET) {
    return registrationsReducerResetState(state);
  }

  return state;
};
