import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { OccurrenceUpdateSchema } from './schemas-validation/schemas-validation';
import OccurrenceUpdateFormView from './occurrence-update-form-view';
import { initialValues, PATH_TO_OCCURRENCE_LIST, optionsImpact, optionsSla } from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useUpdateOccurrenceHook } from 'pages/occurrence/hooks/use-occurrence-update/use-occurrence-update.hook';
import { useOccurrenceDetailsHook } from 'pages/occurrence/hooks/use-occurrence-details/use-occurrence-details.hook';
import { IOccurrenceCreated } from 'domain/occurrence';
import { showToast } from 'components/toast/toast';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { optionsTreatmentSector } from 'pages/occurrence/occurrence-register/components/occurrence-register-form/form-values/form-values';

const OccurrenceUpdateForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<IOccurrenceCreated>(initialValues);

  const navigate = useNavigate();
  const { id } = useParams();

  const occurrenceDetailsHook = useOccurrenceDetailsHook();
  const occurrenceUpdateHook = useUpdateOccurrenceHook();

  const goBacktoOccurrenceList = useCallback(() => navigate(PATH_TO_OCCURRENCE_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (company: IOccurrenceCreated) => {
      setIsLoading(true);
      occurrenceUpdateHook(company)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,

            type: 'success',
          });
          goBacktoOccurrenceList();
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBacktoOccurrenceList, occurrenceUpdateHook],
  );

  useEffect(() => {
    async function run(ocurrenceId: string) {
      try {
        const company = await occurrenceDetailsHook(ocurrenceId);
        setCompany(company);
      } catch (err) {
        showToast({
          message: 'Você não tem permissão para acessar essa ocorrência',
          type: 'error',
        });
      }
    }

    if (typeof id === 'string') run(id);
  }, [id, occurrenceDetailsHook]);

  return (
    <OccurrenceUpdateFormView
      optionsSlaOccurrence={optionsSla}
      occurrenceRegisterValues={company}
      optionsTreatmentSector={optionsTreatmentSector}
      handleGoback={goBacktoOccurrenceList}
      optionsImpactOccurrence={optionsImpact}
      handleRegister={handleSubmit}
      schemaValidation={OccurrenceUpdateSchema}
      isLoading={isLoading}
    />
  );
};

export default OccurrenceUpdateForm;
