import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateScheduleHistoryDriverAndVehicleService } from '../../services/update-schedule-history-driver-and-vehicle/update-schedule-history-driver-and-vehicle.service';
import { IUpdateScheduleHistoryDriverAndVehiclePayload } from '../../services/update-schedule-history-driver-and-vehicle/update-schedule-history-driver-and-vehicle.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const useUpdateScheduleHistoryDriver = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payload: IUpdateScheduleHistoryDriverAndVehiclePayload) => {
      try {
        await updateScheduleHistoryDriverAndVehicleService(scheduleId, payload);
        showMessageFormatted({
          message: 'Motorista atualizado com sucesso.',
          type: 'success',
        });

        scheduleDetails.setScheduleDetailsAllocationClose();

        // INVALIDATE QUERY DATA
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
        reactQueryClient.invalidateQueries(['schedule-history-driver']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleDetails, scheduleId],
  );
};
