import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const WrapperModalMatchForm = styled.main`
  width: 100%;
  height: 88%;
`;

export const WrapperForm = styled(Form)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 25px;
`;

export const WrapperModal = styled.div`
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Info = styled.p`
  font-size: 12px;
  color: #000;
  padding: 5px 0;
`;

export const SectionForm = styled.section``;

type WrapperValuesProps = { columns: string };
export const WrapperValues = styled.div<WrapperValuesProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 15px;
  `}
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;
