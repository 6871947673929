import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.colorsV2.white};
    width: 32%;
    padding: 15px;
    border: 1px solid ${theme.colors.forBorder};
    border-radius: 5px;
  `}
`;

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    align-items: center;
    font-size: 12px;
    color: ${theme.colorsV2.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

interface IHeaderTitle {
  isAdmin?: boolean;
}

export const HeaderTitle = styled.div<IHeaderTitle>`
  ${({ theme, isAdmin }) => css`
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.black};
    padding-bottom: 5px;
    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 15px;
    margin-bottom: 15px;
    cursor: ${isAdmin ? 'pointer' : 'default'};
  `}
`;

export const InfoTitle = styled.span`
  flex-direction: column;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Info = styled.span`
  display: flex;
  font-weight: 700;
  padding: 5px 0;
  text-transform: uppercase;
`;
