import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { ILocalityFilterResponse } from '../../get-locality-filter-ids.types';

export const convertLocalityFilterResponseToSelectItem = (
  localityFilter: ILocalityFilterResponse,
): ISelectItemDomain => {
  return {
    label: localityFilter.name,
    value: localityFilter.id,
  };
};
