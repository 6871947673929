import API from 'utils/API/API';
import { convertShippingEntityToSelectItem } from 'pages/vehicle/mappers/convert-shipping-entity-to-select-item/convert-shipping-entity-to-select-item.mapper';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';

export const driversFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`v2/drivers/search?search=${searchValue}`);
  return data.map((driver: { id: number; name: string; company_id: number }) =>
    convertShippingEntityToSelectItem(driver.name.toUpperCase(), driver.id),
  );
};
