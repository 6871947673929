import { includes } from 'lodash';
import { IWeighingTottensActions, IWeighingTottensContext } from '../types/weighing-tottens.types';
import { WeighingTottensActionType, WeighingTottensDetailsActionType } from '../weighing-tottens.action';
import { weighingTottensDetailsReducer } from './weighing-tottens-details/weighing-tottens-details.reducer';
import { weighingTottensReducerResetState } from './weighing-tottens-reducer.constants';
import { IWeighingTottensDetailsActions } from '../types/weighing-tottens-details.types';

export const weighingTottensReducer = (
  state: IWeighingTottensContext,
  action: IWeighingTottensActions,
): IWeighingTottensContext => {
  if (includes(Object.values(WeighingTottensDetailsActionType), action.type)) {
    return weighingTottensDetailsReducer(state, action as IWeighingTottensDetailsActions);
  }

  if (action.type === WeighingTottensActionType.WEIGHING_TOTTENS_RESET) {
    return weighingTottensReducerResetState(state);
  }

  return state;
};
