import { ThemeColorsV2Type } from 'styles/types/colors';
import { IKanbanOccurrences } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-kanban-list/schedule-kanban.domain';

export const convertOccurrenceIconColor = (occurences: IKanbanOccurrences): ThemeColorsV2Type => {
  const { unattended, progress, completed } = occurences;

  if (Number(unattended) >= 1) {
    return ThemeColorsV2Type.Red;
  }

  if (Number(progress) >= 1) {
    return ThemeColorsV2Type.ProYellow;
  }

  if (Number(completed) >= 1) {
    return ThemeColorsV2Type.Aqua;
  }

  return ThemeColorsV2Type.LightGray;
};
