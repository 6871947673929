import { driverUpdateService } from 'pages/driver/services/driver-update/driver-update.service';
import { IDriverCreatedDomain } from 'src-new/pages/registrations/domains/driver.domain';
import { useManageAttachmentsEdit } from 'src-new/pages/registrations/hooks/use-manage-attachments-edit/use-manage-attachments-edit.hook';

const useUpdateDriverHook = () => {
  const manageAttachmentsEdit = useManageAttachmentsEdit();

  return async (driver: IDriverCreatedDomain) => {
    await manageAttachmentsEdit(driver.id, 'driver', driver.originalAttachments, driver.attachments);
    await driverUpdateService(driver);
  };
};

export { useUpdateDriverHook };
