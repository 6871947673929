import API from 'utils/API/API';
import { ICompany } from 'domain/company';
import { convertProfileUrlToFile } from 'src-new/utils/convert-profile-url-to-file.util';

export const getCompanyService = async (companyId: number): Promise<ICompany> => {
  const { data } = await API.get(`v2/companies/${companyId}`);

  let file: File = new File([], '');

  if (data.fileKey) {
    file = await convertProfileUrlToFile(data.fileKey);
  }

  return { ...data, file };
};
