import { IClientType } from 'domain/client';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';

export const initialValuesClient: IClientDomain = {
  id: 0,
  cnpj: '',
  tradeName: '',
  fantasyName: '',
  responsibleAnalyst: '',
  ie: '',
  internalCode: '',
  status: 'active',
  type_of_partner: '',
  quality_and_safety: '',
  address: {
    cep: '',
    city: '',
    uf: '',
    neighborhood: '',
    street: '',
    numberHouse: '',
    complement: '',
  },
  vehicleType: [],
  vehicleCategoryType: [],
  schedulingEmail: [],
  occurrenceEmail: [],
  localityIds: [],
  operationType: [],
  lat: '',
  lng: '',
  inbound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
  },
  outbound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
  },
  contacts: [],
  operationalRequirements: [],
  attachments: [],
  originalAttachments: [],
  contractualPremises: {
    createdAt: '',
    contractSignatureDate: '',
    contractStartDate: '',
    contractEndDate: '',
    analyst: '',
  },
  plantName: '',
};

export const optionsClient: Array<{
  label: string;
  value: IClientType;
}> = [
  { label: 'FORNECEDOR', value: '01 - FORNECEDOR' },
  { label: 'CLIENTE', value: '02 - CLIENTE' },
  { label: 'OUTROS', value: '03 - OUTROS' },
];

export const PATH_TO_LIST_CLIENT = '/parceiros';
