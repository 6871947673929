import { includes } from 'lodash';
import {
  PurchaseOfferActionType,
  PurchaseOfferBaitRouteActionType,
  PurchaseOfferListActionType,
  PurchaseOfferListDownloadReportActionType,
  PurchaseOfferMatchListActionType,
  PurchaseOfferModalsActionType,
} from '../purchase-offer.action';
import { IPurchaseOfferListActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list.types';
import {
  IPurchaseOfferActions,
  IPurchaseOfferContext,
} from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { purchaseOfferListReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-list/purchase-offer-list.reducer';
import { purchaseOfferMatchListReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-match-list/purchase-offer-match-list.reducer';
import { purchaseOfferModalsReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-modals/purchase-offer-modals.reducer';
import { purchaseOfferReducerResetState } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-reducer.constants';
import { IPurchaseOfferMatchListActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-match-list.types';
import { IPurchaseOfferModalsActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-modals.types';
import { purchaseOfferBaitRouteReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-bait-route/purchase-offer-bait-route.reducer';
import { IPurchaseOfferBaitRouteActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-bait-route.types';
import { purchaseOfferListDownloadReportReducer } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/reducers/purchase-offer-list-download-report/purchase-offer-list-download-report.reducer';
import { IPurchaseOfferListDownloadReportActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list-download-report.types';

export const purchaseOfferReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferActions,
): IPurchaseOfferContext => {
  if (includes(Object.values(PurchaseOfferModalsActionType), action.type)) {
    return purchaseOfferModalsReducer(state, action as IPurchaseOfferModalsActions);
  }

  if (includes(Object.values(PurchaseOfferListActionType), action.type)) {
    return purchaseOfferListReducer(state, action as IPurchaseOfferListActions);
  }

  if (includes(Object.values(PurchaseOfferMatchListActionType), action.type)) {
    return purchaseOfferMatchListReducer(state, action as IPurchaseOfferMatchListActions);
  }

  if (includes(Object.values(PurchaseOfferListDownloadReportActionType), action.type)) {
    return purchaseOfferListDownloadReportReducer(state, action as IPurchaseOfferListDownloadReportActions);
  }

  if (includes(Object.values(PurchaseOfferBaitRouteActionType), action.type)) {
    return purchaseOfferBaitRouteReducer(state, action as IPurchaseOfferBaitRouteActions);
  }

  if (action.type === PurchaseOfferActionType.PURCHASE_OFFER_RESET) {
    return purchaseOfferReducerResetState(state);
  }

  return state;
};
