import {
  IPalletCheckStubDomain,
  IPalletDomain,
  IPalletFreightDataDomain,
  IPalletPalletDataDomain,
} from 'src-new/pages/financial/domains/pallet.domain';

const freightDataInitialValues: IPalletFreightDataDomain = {
  scheduleId: 0,
  equipmentVoucher: '',
  operationType: '',
  collectDate: '',
  clientId: 0,
  clientPlant: '',
  clientName: '',
  distributionCenterId: 0,
  distributionCenterName: '',
};

const palletDataInitialValues: IPalletPalletDataDomain = {
  boardingNumber: '',
  palletType: '',
  palletQuantity: 0,
  equipmentVoucherDate: '',
  expirationDate: '',
  withdrawalDate: '',
  entryDate: '',
  liberationDate: '',
};

const checkStubInitialValues: IPalletCheckStubDomain = {
  status: 'nao_informado',
  analist: '',
  receiptDate: '',
  deliveryDate: '',
  withdrawalDate: '',
  entryDate: '',
  checkStubs: [
    {
      id: 0,
      number: 0,
    },
  ],
};

export const palletFormRegisterInitialValues: IPalletDomain = {
  id: 0,
  note: '',
  freightData: freightDataInitialValues,
  palletData: palletDataInitialValues,
  checkStub: checkStubInitialValues,
};
