import styled from 'styled-components';

export const WrapperPaper = styled.main`
  min-width: 25rem;
  max-height: 32rem;
  background-color: #f4f3f3;
  border-radius: 0.37rem;
  border: solid 1px #e4e4e4;
  padding: 2rem;
`;
