import React, { ReactElement } from 'react';
import { ContainerBodyStyled } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/components/equipment-voucher-list-page-body/equipment-voucher-list-page-body-view.styled';
import { EquipmentVoucherHeader } from 'src-new/pages/financial/pages/equipment-voucher/pages/equipment-voucher-list/components/equipment-voucher-list-page-body/components/equipment-voucher-header/equipment-voucher-header.component';

export const EquipmentVoucherRegisterPageBodyView: React.FC = (): ReactElement => {
  return (
    <ContainerBodyStyled>
      <EquipmentVoucherHeader />
      {/*<EquipmentVoucherListTabs />*/}
    </ContainerBodyStyled>
  );
};
