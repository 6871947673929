import ReactSelect from 'react-select';
import React from 'react';

import { customStyles, LabelStyled, MessageErrorStyled, WrapperStyled } from './input-select-formik-view.styled';

interface InputSelectViewProps {
  id: string;
  name: string;
  label?: string;
  icon?: React.ReactNode;
  options: Array<{ label: string; value: string }>;
  value: string;
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  isClearable?: boolean;
  maxMenuHeight?: number;
  onChange?: () => void;
  hidden?: boolean;
  widthContainer?: string;
}

export const InputSelectFormikView: React.FC<InputSelectViewProps> = ({
  id,
  name,
  options,
  value,
  hasError = false,
  placeholder = '',
  errorMessage = 'invalid input',
  disabled = false,
  label,
  onChange,
  setFieldValue,
  isClearable,
  maxMenuHeight,
  hidden = false,
  widthContainer,
}) => {
  return (
    <WrapperStyled width={widthContainer} hidden={hidden}>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <ReactSelect
        name={name}
        placeholder={placeholder}
        options={options}
        onChange={(option) => {
          onChange && onChange();
          setFieldValue(id, option?.value || '', true);
        }}
        styles={customStyles(disabled, hasError)}
        value={options?.find((obj) => obj.value === value)}
        isClearable={isClearable}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={disabled}
        maxMenuHeight={maxMenuHeight}
      />
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};
