import { IScheduleRefusedContext } from '../types/schedule-refused.types';

export const scheduleRefusedReducerResetState = (state: IScheduleRefusedContext): IScheduleRefusedContext => {
  return {
    ...state,
    scheduleRefusedList: {
      ...state.scheduleRefusedList,
      statusRequest: 'INITIAL',
      scheduleRefusedList: [],
    },
  };
};
