import styled from 'styled-components';

export const ContainerPaginationStyled = styled.div`
  position: absolute;
  bottom: 32.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerTableStyled = styled.div``;
