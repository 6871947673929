import { FC, ReactNode } from 'react';

import * as S from './styled';
interface ModalBarkProps {
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xsm' | 'xxsm';
  title: string;
  subtitle?: ReactNode;
  icon?: React.ReactNode;
  alingText?: boolean;
  handleClose: () => void;
  children: ReactNode;
}

export const ModalBark: FC<ModalBarkProps> = ({ children, size, title, subtitle, icon, alingText, handleClose }) => {
  return (
    <S.ModalBarkWrapper>
      <S.ModalBarkContent size={size}>
        <S.ModalBarkHeader>
          <S.ModalBarkWrapperTitles alingText={alingText}>
            {icon}
            <S.ModalBarkTitle>{title}</S.ModalBarkTitle>
            {subtitle}
          </S.ModalBarkWrapperTitles>

          <S.ModalBarkClose onClick={handleClose}>X</S.ModalBarkClose>
        </S.ModalBarkHeader>

        {children}
      </S.ModalBarkContent>
    </S.ModalBarkWrapper>
  );
};
