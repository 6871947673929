import { FC, Fragment, ReactElement, useMemo } from 'react';
import { IBoxProps } from './box.types';
import * as S from './box-view.styled';

export const BoxView: FC<IBoxProps> = ({ children, footerButtons }) => {
  const renderFooterReactNode = useMemo((): ReactElement => {
    const mapping = footerButtons.map((button, index): ReactElement => {
      return <Fragment key={index}>{button}</Fragment>;
    });

    return <S.FooterButtons>{mapping}</S.FooterButtons>;
  }, [footerButtons]);

  return (
    <S.Wrapper>
      <S.Children>{children}</S.Children>
      <S.Footer>{renderFooterReactNode}</S.Footer>
    </S.Wrapper>
  );
};
