import React, { useContext, useMemo } from 'react';

import CompanyInformationBody from './components/company-information-body/company-information-body';

import { UserContext } from 'state/user-context';
import CompanyInformationContextProvider from 'pages/company/states/company-information-state/company-information.context';
import { AccessRestricted } from 'components/access-restricted/indext';

const CompanyInformationPage: React.FC = () => {
  const { user } = useContext(UserContext);

  const shippingUser = user?.profile === 'shipping-company' && !user.plan;

  const renderPage = useMemo(() => {
    if (shippingUser) {
      return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AccessRestricted />
        </div>
      );
    }

    return (
      <CompanyInformationContextProvider>
        <CompanyInformationBody />
      </CompanyInformationContextProvider>
    );
  }, [shippingUser]);

  return renderPage;
};

export default CompanyInformationPage;
