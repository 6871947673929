import { useCallback } from 'react';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { getLocalityFilterIdsService } from 'src-new/services/get-locality-filter-ids/get-locality-filter-ids.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetLocalityFilterIds = () => {
  return useCallback(
    async (localityIds: Array<number>, search?: string): Promise<Array<ISelectItemDomain> | undefined> => {
      try {
        return getLocalityFilterIdsService(localityIds, search);
      } catch (err) {
        showMessageFormatted({
          message: 'Erro ao obter unidades.',
          type: 'error',
        });
      }
    },
    [],
  );
};
