import { FC, ReactNode } from 'react';

import * as S from './styled';

interface FooterPageLeftContentProps {
  children: ReactNode;
}

export const FooterPageRightContent: FC<FooterPageLeftContentProps> = ({ children }) => {
  return <S.RightContent>{children}</S.RightContent>;
};
