import React, { memo, useMemo } from 'react';

import * as S from './styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface HeaderTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (biddingId: string) => void;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: (type: string) => void;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  clickOrderTableBy,
  clickSortDirection,
  isLoading,
  orderSelected,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: 'ID da Oferta',
        flex: 0.6,
      },
      {
        value: 'Data da Oferta',
        flex: 0.6,
      },
      {
        value: 'Embarcador/Transportadora',
        flex: 1.8,
      },
      {
        value: 'Homologado',
        flex: 0.6,
      },
      {
        value: 'Classificação',
        flex: 0.8,
      },
      {
        value: 'Valor',
        flex: 0.6,
      },
      {
        value: '',
        flex: 0.5,
      },
      {
        value: '',
        flex: 0.5,
      },
    ];
  }, []);

  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.ContainerPagination>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </S.ContainerPagination>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <div>
      <Table
        orderSelected={orderSelected}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        clickOrderBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        zIndex
      />
      {renderPagination}
    </div>
  );
};

export default memo(HeaderTable);
