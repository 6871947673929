import React from 'react';
import { RegistrationsContextProvider } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

import ClientUpdateView from './client-update-view';

const ClientUpdate: React.FC = () => {
  return (
    <RegistrationsContextProvider>
      <ClientUpdateView />
    </RegistrationsContextProvider>
  );
};

export default ClientUpdate;
