import React, { Dispatch, SetStateAction } from 'react';

import { FreightCTESectionView } from './freight-cargo-cte-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  freightValues: ScheduleDomain;
  onRefresh: Dispatch<SetStateAction<number>>;
}

export const FreightCTESection: React.FC<IProps> = ({ freightValues, onRefresh }) => {
  return <FreightCTESectionView freightValues={freightValues} onRefresh={onRefresh} />;
};
