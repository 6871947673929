import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { ScheduleTableView } from './schedule-table-view.component';
import { convertStatusCode } from 'utils-v2/converters/status-code';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { useQueryClient } from 'react-query';
import { ScheduleListServiceResponse } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/services/get-schedule-list/get-schedule-list.service';
import { paginationInitialValues } from 'src-new/domains/list-page.domain';

export const ScheduleTable: React.FC = () => {
  const { scheduleList, scheduleListInitialLoading } = useContext(ScheduleContext);
  const { scheduleListFiltersProps } = scheduleList;
  const { filters, setFilters } = scheduleListFiltersProps;
  const { user, isAdmin, isShippingCompany } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const cache = queryClient.getQueryData<ScheduleListServiceResponse>([
    'schedule-list-table',
    { ...scheduleList.scheduleListFiltersProps.filters },
  ]);

  const getMetaData = useMemo(() => {
    if (cache) return cache.meta;
    return paginationInitialValues;
  }, [cache]);

  const getSchedules = useMemo(() => scheduleList.scheduleList, [scheduleList.scheduleList]);

  const handleCheckbox = useCallback(
    (checked: boolean, scheduleId: number, statusCode: string) => {
      if (checked) {
        const newSelectedSchedules = [
          ...scheduleList.scheduleListCheckbox.checkbox,
          { id: scheduleId, status: statusCode },
        ];
        scheduleList.scheduleListCheckbox.setScheduleListCheckbox(newSelectedSchedules);
      } else {
        const updatedSelectedSchedules = scheduleList.scheduleListCheckbox.checkbox.filter(
          (schedule) => schedule.id !== scheduleId,
        );
        scheduleList.scheduleListCheckbox.setScheduleListCheckbox(updatedSelectedSchedules);
      }
    },
    [scheduleList.scheduleListCheckbox],
  );

  const handleLineClick = useCallback(
    (id: number, statusCode: string) => {
      if (statusCode === 'Em Bidding') {
        return navigate(`/alocacao/${id}`);
      }

      navigate(`/fretes/${id}`);
    },
    [navigate],
  );

  const handleOpenModals = useCallback(
    (id: number, modalType: ScheduleListModalType) => {
      scheduleList.modalsProps.setModalOpen({ open: true, ids: [id], modalType });
    },
    [scheduleList.modalsProps],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      setFilters({
        ...filters,
        page: 1,
        column: columnName,
        order,
      });
    },
    [filters, setFilters],
  );

  const isLoading = useMemo(() => scheduleListInitialLoading.loading, [scheduleListInitialLoading.loading]);

  const isLazyLoading = useMemo(
    () => scheduleList.statusRequest === 'PROCESSING' && !isLoading,
    [isLoading, scheduleList.statusRequest],
  );

  const isEmpty = useMemo(
    () => scheduleList.statusRequest === 'SUCCESS' && !getSchedules.length,
    [getSchedules.length, scheduleList.statusRequest],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: scheduleListFiltersProps.filters.column,
      order: scheduleListFiltersProps.filters.order,
      handleClick: handleOrdering,
    };
  }, [scheduleListFiltersProps.filters.column, scheduleListFiltersProps.filters.order, handleOrdering]);

  const disabledCancelSchedule = useMemo(() => {
    const statusCode = [
      'IN20',
      'IN30',
      'IN40',
      'IN50',
      'IN60',
      'IN70',
      'IN90',
      'BH09',
      'LG90',
      'AL90',
      'AL99',
      'OT99',
      'AL90',
      'OT80',
    ];

    return statusCode.map((statusCode) => convertStatusCode(statusCode));
  }, []);

  const filtersRef = useRef(filters);
  filtersRef.current = filters;

  useEffect(() => {
    if (page < getMetaData.totalPages) {
      setFilters({ ...filtersRef.current, page });
    }
  }, [getMetaData.currentPage, getMetaData.totalPages, page, setFilters]);

  return (
    <ScheduleTableView
      schedules={getSchedules}
      isAdmin={isAdmin}
      isShippingCompany={isShippingCompany}
      user={user}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      isEmpty={isEmpty}
      disabledCancelSchedule={disabledCancelSchedule}
      infiniteScrollProps={infiniteScrollProps}
      handleLineClick={handleLineClick}
      handleCheckbox={handleCheckbox}
      handleOpenModals={handleOpenModals}
    />
  );
};
