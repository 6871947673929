import styled, { css } from 'styled-components';

export const WeighingTottenModalWrapper = styled.main`
  ${() => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: transparent;
    top: 0;
    right: 0;
    z-index: 1000;
    cursor: initial;
  `}
`;

export const WeighingTottenModalContent = styled.section`
  ${({ theme }) => css`
    width: 80vw;
    height: 83vh;
    background-color: ${theme.colorsV2.white};
    border-radius: ${theme.modalsV4.radius};
    padding: ${theme.modalsV4.padding};
    overflow: auto;
  `}
`;

export const WeighingTottenModalWrapperChildren = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;