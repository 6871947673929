/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { Tooltip } from '@mui/material';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';

import { InfoStyled } from '../freight-list/freight-list-body/freight-list-body-view.styled';

import { convertStatusCode, convertStatusCodeColor } from 'utils/status-code';
import { convertOperationType } from 'utils/operation-type';
import { TooltipOcurrences } from 'pages/freight/components/Tooltip-Ocurrences';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { IUser } from 'domain/login';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { ICompany } from 'domain/company';
import Checkbox from 'components/checkbox/checkbox';
import Button from 'components/button/button';
import TooltipComponent from 'components/Tooltip';

export interface FreightContentTableMountProps {
  hasNewOccurrence?: boolean;
  hideCheckbox?: boolean;
}

export const freightContentTableMount = (
  freight: ICurrentAllocation,
  isAdmin: boolean,
  companyValues?: ICompany,
  user?: IUser,
  filter?: FreightContentTableMountProps,
) => {
  const valuesFormatter = {
    statusCode: convertStatusCode(freight.statusCode),
  };

  const elBackground = convertStatusCodeColor(valuesFormatter?.statusCode.toLowerCase());

  const operadoPor = freight.currentFreight === 2 ? freight.company?.tradeName : 'Logshare';

  const hasNewOccurrence = filter?.hasNewOccurrence && {
    value: (
      <Link to={`/tracking-ocorrencias/novo/${freight.id}`} target="_self">
        <Button title="SELECIONAR" text="SELECIONAR" bgColor="blue" size="small" />
      </Link>
    ),
    flex: 0.48,
  };

  const formatterTime = (scheduleTime: string | undefined) => {
    if (scheduleTime?.length === 2) {
      return `${scheduleTime}:00`;
    }
    return scheduleTime;
  };

  const defaultColumns = [
    {
      value: <Checkbox id="freight.id" name="freight.id" value={String(0)} onChange={() => null} />,
      flex: 0.1,
    },
    {
      value: freight.id,
      flex: isAdmin ? 0.2 : 0.15,
    },

    isAdmin
      ? {
          value: freight.company?.tradeName,
          flex: 0.5,
        }
      : {
          value: '',
          flex: 0,
        },

    {
      value: (
        <Tag
          label={convertOperationType(freight.scheduleCargo?.operationType || '').label}
          color={convertOperationType(freight.scheduleCargo?.operationType || '').color}
          variant="ghost"
          display="inline-flex"
          size="md"
        />
      ),
      flex: 0.3,
    },

    {
      value: moment(freight.scheduleDetails?.levyInitialDate).format('DD/MM/YYYY'),
      subtitle: formatterTime(freight.scheduleDetails?.levyInitialTime) || '-',
      flex: isAdmin ? 0.5 : 0.45,
    },
    {
      value: freight.scheduleDetails?.deliveryEndDate
        ? moment(freight.scheduleDetails?.deliveryEndDate).format('DD/MM/YYYY')
        : moment(freight.scheduleDetails?.deliveryInitialDate).format('DD/MM/YYYY'),
      subtitle: freight.scheduleDetails?.deliveryInitialTime
        ? formatterTime(freight.scheduleDetails?.deliveryInitialTime)
        : formatterTime(freight.scheduleDetails?.deliveryEndTime) || '-',
      flex: isAdmin ? 0.5 : 0.45,
    },
    {
      value: freight.scheduleClientOrigin?.client?.city
        ? freight.scheduleClientOrigin?.client?.uf + ' - ' + freight.scheduleClientOrigin?.client?.city
        : freight.scheduleClientOrigin?.locality?.uf + ' - ' + freight.scheduleClientOrigin?.locality?.city,
      subtitle: freight.scheduleClientOrigin?.client?.tradeName || freight.scheduleClientOrigin?.locality?.name,
      flex: 0.8,
    },
    {
      value: freight.scheduleClientDestiny?.client?.city
        ? freight.scheduleClientDestiny?.client?.uf + ' - ' + freight.scheduleClientDestiny?.client?.city
        : freight.scheduleClientDestiny?.locality?.uf + ' - ' + freight.scheduleClientDestiny?.locality?.city,
      subtitle: freight.scheduleClientDestiny?.client?.tradeName || freight.scheduleClientDestiny?.locality?.name,
      flex: isAdmin ? 0.8 : 0.85,
    },
    {
      value: convertVehicleTypeTypeToName(freight.scheduleCargo?.vehicleType),
      subtitle: convertVehicleCategoryTypeToName(freight.scheduleCargo?.vehicleCategoryType),
      flex: isAdmin ? 0.4 : 0.45,
    },
    {
      value: operadoPor,
      subtitle: freight.operation,
      flex: 0.5,
    },
    {
      value: (
        <Tooltip title={<span style={{ fontSize: '11px' }}>{valuesFormatter.statusCode}</span>} placement="left">
          <InfoStyled>
            <Tag label={valuesFormatter.statusCode} color={elBackground} variant="solid" display="flex" size="md" />
          </InfoStyled>
        </Tooltip>
      ),
      flex: isAdmin ? 0.5 : 0.8,
    },
    {
      value: (
        <TooltipComponent
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              <strong>Dados Adicionais</strong>

              {!freight.scheduleCargo?.orderNumber &&
                !freight.scheduleDetails?.schedulingPassword &&
                !freight.scheduleDetails?.internalNumber &&
                !freight.scheduleDetails?.ocInternalNumber &&
                !freight.scheduleDetails?.boardingNumber &&
                'Sem Informações'}

              {freight.scheduleCargo?.orderNumber && <p>Pedidos: {freight.scheduleCargo?.orderNumber}</p>}
              {freight.scheduleDetails?.schedulingPassword && (
                <p>Senha: {freight.scheduleDetails?.schedulingPassword}</p>
              )}
              {freight.scheduleDetails?.internalNumber && <p>N° Interno: {freight.scheduleDetails?.internalNumber}</p>}
              {freight.scheduleDetails?.ocInternalNumber && <p>N° OC: {freight.scheduleDetails?.ocInternalNumber}</p>}
              {freight.scheduleDetails?.boardingNumber && <p>N° Embarque: {freight.scheduleDetails?.boardingNumber}</p>}
            </div>
          }
          icon={
            !freight.scheduleCargo?.orderNumber &&
            !freight.scheduleDetails?.schedulingPassword &&
            !freight.scheduleDetails?.internalNumber &&
            !freight.scheduleDetails?.ocInternalNumber &&
            !freight.scheduleDetails?.boardingNumber ? (
              <InfoSharpIcon color="disabled" sx={{ width: 18, height: 18 }} />
            ) : (
              <InfoSharpIcon sx={{ width: 18, height: 18 }} />
            )
          }
          location={'left'}
        />
      ),
      flex: 0.1,
    },
    {
      value: (
        <TooltipOcurrences
          occurrences={freight.pendingOccurrences || ([] as any)}
          collectDates={{
            initial: freight.scheduleDetails?.levyInitialDate,
            end: freight.scheduleDetails?.levyInitialDate,
          }}
          deliveryDates={{
            initial: freight.scheduleDetails?.deliveryInitialDate,
            end: freight.scheduleDetails?.deliveryEndDate,
          }}
          freightId={freight.id}
        />
      ),
      flex: 0.1,
    },
    {
      value: '',
      flex: 0,
    },
  ];

  if (hasNewOccurrence) defaultColumns.push(hasNewOccurrence);

  return defaultColumns;
};
