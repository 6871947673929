import React, { ChangeEvent, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { ISelectedSchedules } from '../../list/types';

import * as S from './styled';

import InputTextarea from 'components/input-textarea/input-textarea';
import Button from 'components/button/button';

interface IForm {
  ids: Array<ISelectedSchedules>;
  buttonLabel: 'excluir' | 'negar';
  closeModal: () => void;
  handleConfirm: () => void;
}

export const CancelAllocationForm: React.FC<IForm> = ({ ids, buttonLabel, closeModal, handleConfirm }) => {
  const { values, setFieldValue } = useFormikContext<{
    descriptionCancel: string;
  }>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const inputLabel = useMemo(() => {
    if (buttonLabel === 'excluir') return 'Motivo da Exclusão';
    if (buttonLabel === 'negar') return 'Motivo da Negação';
  }, [buttonLabel]);

  const headerLabels = {
    excluir: {
      multiple: 'Excluir Viagens',
      single: 'Excluir Viagem',
    },
    negar: {
      multiple: 'Negar Viagens',
      single: 'Negar Viagem',
    },
  };

  const headerLabel = useMemo(() => {
    const labelInfo = headerLabels[buttonLabel];

    if (labelInfo) {
      return ids.length > 1 ? labelInfo.multiple : labelInfo.single;
    }

    return '';
  }, [ids, buttonLabel]);

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Header>
            <S.CustomWrapper>
              <S.HeaderTitle>{headerLabel}</S.HeaderTitle>
            </S.CustomWrapper>

            <S.ButtonClose onClick={closeModal}>
              <S.CloseModalStyled>X</S.CloseModalStyled>
            </S.ButtonClose>
          </S.Header>

          <S.Main>
            <InputTextarea
              label={inputLabel}
              id="descriptionCancel"
              name="descriptionCancel"
              onChange={handleChange}
              value={values.descriptionCancel}
            />
          </S.Main>

          <S.ModalFooter>
            <Button title={buttonLabel} size={'medium'} callback={handleConfirm} bgColor="newRed" />
          </S.ModalFooter>
        </S.Content>
      </S.Wrapper>
    </>
  );
};
