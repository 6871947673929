import styled, { css } from 'styled-components';

import { WrapperProps } from 'components/button/button.styled';

export const WrapperContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.white};
  `}
  width: 100%;
  margin-bottom: 25px;
`;

export const SectionFormControlLabelStyled = styled.p`
  color: #ababab;
  font-size: 15px;
`;

export const SectionFormControlInfoStyled = styled.p`
  margin-top: 12px;
  font-size: 15px;
`;

export const ContainerInfosChargeStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerRegistersStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerPhotosStyled = styled.div`
  display: flex;
  max-width: 100%;
`;

const wrapperModifier = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
    }
  `,
};

export const WrapperStyled = styled.button<WrapperProps>`
  ${({ theme, bgColor = 'blue', hasIcon }) => css`
    min-width: 40px;
    max-width: ${'1000px'};

    background-color: ${!!bgColor && theme.colors[bgColor]};
    padding: 1px 20px;
    border: none;
    ${bgColor === 'white' && `border: solid 1px ${theme.colors.forBorder}`};
    border-radius: ${theme.border.radius};
    font-family: ${theme.font.family};
    font-size: ${'12px'};
    font-weight: ${bgColor === 'white' ? theme.font.normal : theme.font.semiBold};
    color: ${bgColor === 'white'
      ? 'rgb(80, 80, 80)'
      : bgColor === 'lightGray'
        ? theme.colors.black
        : theme.colors.white};

    cursor: pointer;

    ${hasIcon && wrapperModifier.withIcon()}

    :hover {
      opacity: 0.88;
    }

    :active {
      box-shadow: ${theme.border.shadow};
    }

    :disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    @media (max-width: 600px) {
      min-width: 100px;
      padding: 5px 15px;
      height: 35px;
      font-size: 12px;
    }
  `}
`;

export const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
`;
