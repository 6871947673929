import moment from 'moment';

import { IAllocationClient } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { VehicleCategoryWithEmpty } from 'domain/global';
import { convertVehicleCategoryTypeToName } from 'domain/convert';

export const formatSchedule = (schedule: any) => {
  if (!schedule?.scheduleClientDestiny) return;

  let origin = {} as IAllocationClient;
  let destiny = {} as IAllocationClient;

  if (schedule.scheduleClientDestiny !== null) {
    if (schedule.scheduleClientDestiny?.client != null) {
      delete schedule.scheduleClientDestiny?.client.fantasyName;
      destiny = schedule.scheduleClientDestiny?.client;
    }
    if (schedule.scheduleClientDestiny?.locality != null) {
      delete schedule.scheduleClientDestiny?.locality.type;
      destiny = schedule.scheduleClientDestiny?.locality;
    }
  }

  if (schedule.scheduleClientOrigin !== null) {
    if (schedule.scheduleClientOrigin?.client != null) {
      delete schedule.scheduleClientOrigin?.client.fantasyName;
      origin = schedule.scheduleClientOrigin?.client;
    }
    if (schedule.scheduleClientOrigin?.locality != null) {
      delete schedule.scheduleClientOrigin?.locality.type;
      origin = schedule.scheduleClientOrigin?.locality;
    }
  }

  return {
    id: schedule.id,
    origin,
    destiny,
    scheduleCargo: schedule.scheduleCargo,
    scheduleDetails: {
      ...schedule.scheduleDetails,
      deliveryEndDate: moment(schedule.scheduleDetails?.deliveryEndDate).format('DD/MM/YYYY'),
      deliveryInitialDate: moment(schedule.scheduleDetails?.deliveryInitialDate).format('DD/MM/YYYY'),
      levyEndDate: moment(schedule.scheduleDetails?.levyEndDate).format('DD/MM/YYYY'),
      levyInitialDate: moment(schedule.scheduleDetails?.levyInitialDate).format('DD/MM/YYYY'),
    },
    driver: {
      name: schedule.driver?.name,
      driverDocument: schedule.driver?.cpf,
      firstPhone: schedule.driver?.firstPhone,
      secondPhone: schedule.driver?.secondPhone,
    },
    vehicle: {
      mainBoard: schedule.vehicle?.mainBoard,
      secondBoard: schedule.vehicle?.secondBoard,
      thirdBoard: schedule.vehicle?.thirdBoard,
      vehicleCategory: convertVehicleCategoryTypeToName(schedule.vehicle?.vehicleCategory as VehicleCategoryWithEmpty),
      vehicleType: schedule.vehicle?.vehicleType,
    },
    shippingCompany: {
      name: schedule.shippingCompany?.name,
      shippingCompanyDocument: schedule.shippingCompany?.cnpj,
      phone: schedule.shippingCompany?.phone,
      responsavelName: schedule.shippingCompany?.responsavelName,
    },
  };
};
