import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { rntrcsInitialValues, securityInitialValues } from '../initial-values';
import { ContainerDateStyled, FormStyled } from './styled';
import { FooterPage } from 'components-v2/common/footer-page';
import Line from 'components-v2/layout/line';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import { SectionStyled } from 'components-v2/layout/section/styled';
import Button from 'components/button/button';
import { InputCurrency } from 'components/input-currency/input-currency';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import SectionDivider from 'components/section-divider/section-divider';
import Select from 'components/select/select';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { uf } from 'domain/states';
import { useCepHook } from 'hooks/cep/cep.hook';
import { useConvertAddressToGeoPositionHook } from 'hooks/maps/map-convert-address-to-geo.hook';
import { extractLoginAfterAt } from 'pages-v2/shipping-company/utils/extract-login';
import { LoginParceiroStyled } from 'pages/user/user-register/components/user-register-form/user-register-form.styled';
import { UserContext } from 'state/user-context';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import MultiSelect, {Option} from 'components/multi-select/multi-select';
import { approvedTrackers, vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import {
  IShippingCompanyDomain,
  IShippingCompanyRntrcs,
  IShippingCompanySecurity,
} from 'src-new/pages/registrations/domains/shipping-company';
import { AttachDocumentsSection } from 'src-new/pages/registrations/components/attach-documents-section/attach-documents-section.component';
import { ContactDetailsSection } from 'src-new/pages/registrations/components/contact-details-section/contact-details-section.component';
import { MultiValue } from 'react-select';

export const InnerForm = (props: FormikProps<IShippingCompanyDomain>) => {
  const { user, isAdmin } = useContext(UserContext);
  const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = props;
  const login = user?.login?.includes('@') ? '@' + extractLoginAfterAt(user?.login) : '@' + user?.login;
  const { id } = useParams();
  const navigate = useNavigate();
  const cepHook = useCepHook();
  const convertConvertToAddressToGeoHook = useConvertAddressToGeoPositionHook();

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  const onBlurCEPField = useCallback(
    async (cep: string) => {
      const address = await onBlurCep(cep);

      setFieldValue('address.cep', address.cep || values.address.cep);
      setFieldValue('address.city', address.city);
      setFieldValue('address.complement', address.complement);
      setFieldValue('address.uf', address.uf);
      setFieldValue('address.neighborhood', address.neighborhood);
      setFieldValue('address.numberHouse', address.numberHouse);
      setFieldValue('address.street', address.street);

      const geoposition = await convertConvertToAddressToGeoHook({
        address: `${values.address.cep} ${values.address.street} ${values.address.city} ${values.address.numberHouse}`,
      });

      setFieldValue('address.lat', String(geoposition?.lat));
      setFieldValue('address.lng', String(geoposition?.lng));
    },
    [values.address],
  );

  const handleAddItemInArray = useCallback(
    (field: 'rntrcs' | 'security', initialValues: IShippingCompanyRntrcs | IShippingCompanySecurity) => {
      setFieldValue(field, [...values[field], initialValues]);
    },
    [values, setFieldValue],
  );

  const handleRemoveItemInArray = useCallback(
    (field: 'rntrcs' | 'security', index: number) => {
      let updatedRntrcs: Array<IShippingCompanyRntrcs | IShippingCompanySecurity>;

      if (field === 'rntrcs') {
        updatedRntrcs = values[field].filter((_, i) => i !== index);
      } else {
        updatedRntrcs = values[field].filter((_, i) => i !== index);
      }

      setFieldValue(field, updatedRntrcs);
    },
    [values, setFieldValue],
  );

  useEffect(() => {
    const updateDates = (
      array: Array<IShippingCompanyRntrcs> | Array<IShippingCompanySecurity>,
      fieldName: 'rntrcs' | 'security',
    ) => {
      if (array?.length > 0 && id) {
        array.forEach((item, index) => {
          const fieldNamePrefix = `${fieldName}[${index}]`;
          handleDate(item.initialDate, `${fieldNamePrefix}.initialDate.date.date`);
          handleDate(item.finalDate, `${fieldNamePrefix}.finalDate.date.date`);
        });
      }
    };

    const handleDate = (dateObject: any, field: string) => {
      const date = dateObject?.date.date ? moment(dateObject.date.date) : null;
      if (date?.isValid()) {
        setFieldValue(field, date);
      }
    };

    updateDates(values.rntrcs, 'rntrcs');
    updateDates(values.security, 'security');
  }, [id, setFieldValue, values.rntrcs, values.security]);

  useEffect(() => {
    if (values.userName.includes('@')) {
      const value = values.userName.split('@');
      setFieldValue('userName', value[0]);
    }
  }, [setFieldValue, values.userName]);

  const renderRntrcsForm = useMemo(() => {
    return values?.rntrcs?.map((_, index) => {
      return (
        <div key={`anttNumber${index}`}>
          <LineSectionStyled columns="1fr 1fr 1fr 1fr 0.1fr" alignItems="">
            <InputField
              label="N° ANTT"
              id={`${index}.anntNumber`}
              name={`${index}.anntNumber`}
              type="text"
              value={Number(values.rntrcs[index]?.anntNumber || 0)}
              onChange={(e: any) => {
                setFieldValue(`rntrcs[${index}].anntNumber`, e.target.value);
              }}
            />

            <ContainerDateStyled>
              <DateTimePikers
                label="Data Inicial"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={dayjs(values?.rntrcs[index]?.initialDate?.date?.date ?? '')}
                onChange={(date) => setFieldValue(`rntrcs[${index}].initialDate.date.date`, date)}
              />
            </ContainerDateStyled>

            <ContainerDateStyled>
              <DateTimePikers
                label="Data Final"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={dayjs(values?.rntrcs[index]?.finalDate?.date?.date ?? '')}
                onChange={(date) => setFieldValue(`rntrcs[${index}].finalDate.date.date`, date)}
              />
            </ContainerDateStyled>

            <InputField
              label="Filial"
              id={`${index}.branch`}
              name={`${index}.branch`}
              type="text"
              value={values?.rntrcs[index]?.branch}
              onChange={(e: any) => {
                setFieldValue(`rntrcs[${index}].branch`, e.target.value);
              }}
            />
            <div style={{ marginTop: 24 }}>
              <Button
                title="Deletar"
                bgColor="newRed"
                icon={<DeleteIcon onClick={() => handleRemoveItemInArray('rntrcs', index)} />}
                size="very-small"
              />
            </div>
          </LineSectionStyled>

          <div style={{ marginTop: 20 }}>
            <Line />
          </div>
        </div>
      );
    });
  }, [values.rntrcs, setFieldValue, handleRemoveItemInArray]);

  const renderSecurityForm = useMemo(() => {
    return values?.security?.map((_, index) => {
      return (
        <div key={`securityNumber${index}`}>
          <LineSectionStyled columns="1fr 1fr 1fr 1fr 0.1fr" alignItems="">
            <InputField
              label="N° Apólice"
              id={`${index}.code`}
              name={`${index}.code`}
              type="text"
              value={Number(values.security[index]?.code || 0)}
              onChange={(e: any) => {
                setFieldValue(`security[${index}].code`, e.target.value);
              }}
            />
            <ContainerDateStyled>
              <DateTimePikers
                label="Data Inicial"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={dayjs(values?.security[index]?.initialDate?.date?.date ?? '')}
                onChange={(date) => setFieldValue(`security[${index}].initialDate.date.date`, date)}
              />
            </ContainerDateStyled>
            <ContainerDateStyled>
              <DateTimePikers
                label="Data Final"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={dayjs(values?.security[index]?.finalDate?.date?.date ?? '')}
                onChange={(date) => setFieldValue(`security[${index}].finalDate.date.date`, date)}
              />
            </ContainerDateStyled>

            <InputCurrency
              label="Valor de Cobertura"
              id={`${index}.coverageValue`}
              name={`${index}.coverageValue`}
              type="text"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => setFieldValue(`security[${index}].coverageValue`, value)}
              value={values?.security[index]?.coverageValue}
            />

            <div style={{ marginTop: 24 }}>
              <Button
                title="Deletar"
                bgColor="newRed"
                icon={<DeleteIcon onClick={() => handleRemoveItemInArray('security', index)} />}
                size="very-small"
              />
            </div>
          </LineSectionStyled>

          <div style={{ marginTop: 20 }}>
            <Line />
          </div>
        </div>
      );
    });
  }, [values, setFieldValue]);

  const approvedTrackersValues = useMemo(
    () =>
      approvedTrackers.filter(({ value }) => {
        if (values.approvedTrackers) {
          return values.approvedTrackers.includes(value as any);
        }
      }),
    [values.approvedTrackers],
  );

  const handleApprovedTrackers = useCallback((e: MultiValue<Option>) => {
      const appTrackers = e.map((v: { value: any; }) => v.value);
      let appTrackersToAdd = appTrackers;
      if (appTrackers.includes('ALL')) appTrackersToAdd = approvedTrackers.map((v) => v.value);
      setFieldValue('approvedTrackers', appTrackersToAdd);
    },
    [setFieldValue],
  );

  return (
    <FormStyled>
      <SectionStyled name="Informações da Transportadora">
        <LineSectionStyled columns="0.5fr 1fr 0.57fr 1fr 0.7fr">
          <InputField
            label="Código"
            id="id"
            name="id"
            type="text"
            value={values.id}
            onChange={handleChange}
            disabled={true}
          />

          <InputField
            label="Nome Transportadora *"
            id="name"
            name="name"
            type="text"
            onChange={handleChange}
            value={values.name}
            hasError={!!errors.name && !!touched.name}
            errorMessage={String(errors.name)}
          />

          <InputField
            label="Código Interno"
            id="internalCode"
            name="internalCode"
            type="text"
            onChange={handleChange}
            value={values.internalCode}
            hasError={!!errors.internalCode && !!touched.internalCode}
            errorMessage={String(errors.internalCode)}
          />

          <MultiSelect
            label="Rastreadores Homologados"
            options={[{ value: 'ALL', label: 'TODOS' }, ...approvedTrackers]}
            menuPosition="fixed"
            values={approvedTrackersValues}
            onChange={handleApprovedTrackers}
          />

          <InputFieldCnpj
            label="CNPJ *"
            id="cnpj"
            name="cnpj"
            type="text"
            onChange={handleChange}
            value={values.cnpj}
            hasError={!!errors.cnpj && !!touched.cnpj}
            errorMessage={String(errors.cnpj)}
            disabled={!!id}
          />
        </LineSectionStyled>
      </SectionStyled>

      {!isAdmin && (
        <>
          <SectionDivider label="Dados do Usuário" />
          <SectionStyled name="Criar Usuário">
            <LineSectionStyled columns="1fr 1fr">
              <InputField
                label="Login *"
                id="userName"
                name="userName"
                type="text"
                onChange={handleChange}
                value={values.userName}
                hasError={!!errors.userName && !!touched.userName}
                errorMessage={String(errors.userName)}
                reactNode={<LoginParceiroStyled>{login.toUpperCase()}</LoginParceiroStyled>}
                reactNodePosition="right"
              />

              <InputField
                label="E-mail do Usuário *"
                id="userEmail"
                name="userEmail"
                type="text"
                onChange={handleChange}
                value={values.userEmail}
                hasError={!!errors.userEmail && !!touched.userEmail}
                errorMessage={String(errors.userEmail)}
              />
            </LineSectionStyled>
          </SectionStyled>
        </>
      )}

      <ContactDetailsSection />

      <SectionDivider label="Endereço da Matriz" />
      <SectionStyled name="Endereço">
        <LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
          <InputFieldCep
            label="CEP *"
            id="address.cep"
            name="address.cep"
            type="text"
            onChange={handleChange}
            onBlur={(e) => onBlurCEPField(e.target.value)}
            value={values.address.cep}
            hasError={!!errors.address?.cep && !!touched.address?.cep}
            errorMessage={String(errors.address?.cep)}
          />

          <InputField
            label="Bairro *"
            id="address.neighborhood"
            name="address.neighborhood"
            type="text"
            onChange={handleChange}
            value={values.address?.neighborhood}
            hasError={!!errors.address?.neighborhood && !!touched.address?.neighborhood}
            errorMessage={String(errors.address?.neighborhood)}
          />

          <InputField
            label="Cidade *"
            id="address.city"
            name="address.city"
            type="text"
            onChange={handleChange}
            value={values.address.city}
            hasError={!!errors.address?.city && !!touched.address?.city}
            errorMessage={String(errors.address?.city)}
          />

          <Select
            label="UF *"
            id="address.uf"
            name="address.uf"
            value={values.address.uf}
            setFieldValue={setFieldValue}
            hasError={!!errors.address?.uf && !!touched.address?.uf}
            errorMessage={String(errors.address?.uf)}
            options={uf}
          />
        </LineSectionStyled>

        <LineSectionStyled columns="1.5fr 0.4fr 1.1fr 0.5fr 0.5fr">
          <InputField
            label="Endereço *"
            id="address.street"
            name="address.street"
            type="text"
            onChange={handleChange}
            value={values.address?.street}
            hasError={!!errors.address?.street && !!touched.address?.street}
            errorMessage={String(errors.address?.street)}
          />

          <InputField
            label="Nº *"
            id="address.numberHouse"
            name="address.numberHouse"
            type="text"
            onChange={handleChange}
            value={values.address.numberHouse}
            hasError={!!errors.address?.numberHouse && !!touched.address?.numberHouse}
            errorMessage={String(errors.address?.numberHouse)}
          />

          <InputField
            label="Complemento"
            id="address.complement"
            name="address.complement"
            type="text"
            onChange={handleChange}
            value={values.address?.complement ?? ''}
            hasError={!!errors.address?.complement && !!touched.address?.complement}
            errorMessage={String(errors.address?.complement)}
          />

          <InputField
            label="Latitude *"
            id="address.lat"
            name="address.lat"
            type="text"
            value={values.address.lat}
            onChange={handleChange}
            hasError={!!errors.address?.lat && !!touched.address?.lat}
            errorMessage={String(errors.address?.lat)}
          />

          <InputField
            label="Longitude *"
            id="address.lng"
            name="address.lng"
            type="text"
            value={values.address.lng}
            onChange={handleChange}
            hasError={!!errors.address?.lng && !!touched.address?.lng}
            errorMessage={String(errors.address?.lng)}
          />
        </LineSectionStyled>
      </SectionStyled>

      <SectionDivider label="Veículos Atendidos" />
      <MultiSelect
        label="Tipologia"
        options={[{ value: 'ALL', label: 'TODOS' }, ...vehicleTypeOptions]}
        menuPosition="fixed"
        values={vehicleTypeOptions.filter((v) => values.vehiclesServiced.typology.includes(v.value as any))}
        onChange={(e) => {
          const vehicles = e.map((option) => option.value);

          let vehiclesToAdd = vehicles;

          if (vehicles.includes('ALL')) vehiclesToAdd = vehicleTypeOptions.map((v) => v.value);

          setFieldValue('vehiclesServiced.typology', vehiclesToAdd);
        }}
      />

      <MultiSelect
        label="Categoria"
        options={[{ value: 'ALL', label: 'TODOS' }, ...vehicleCategoryOptions]}
        menuPosition="fixed"
        values={vehicleCategoryOptions.filter((v) => values.vehiclesServiced.category.includes(v.value as any))}
        onChange={(e) => {
          const vehicles = e.map((option) => option.value);

          let vehiclesToAdd = vehicles;

          if (vehicles.includes('ALL')) vehiclesToAdd = vehicleCategoryOptions.map((v) => v.value);

          setFieldValue('vehiclesServiced.category', vehiclesToAdd);
        }}
      />

      <SectionDivider label="RNTRC" />
      <SectionStyled name="RNTRC">
        {renderRntrcsForm}
        <div style={{ marginTop: 15 }}>
          <Button
            title="Adicionar"
            bgColor="blue"
            callback={() => handleAddItemInArray('rntrcs', rntrcsInitialValues)}
            icon={<AddIcon />}
            size="very-small"
          />
        </div>
      </SectionStyled>

      <SectionDivider label="Apólice de Seguro" />
      <SectionStyled name="Apólice">
        {renderSecurityForm}
        <div style={{ marginTop: 15 }}>
          <Button
            title="Adicionar"
            bgColor="blue"
            callback={() => handleAddItemInArray('security', securityInitialValues)}
            icon={<AddIcon />}
            size="very-small"
          />
        </div>
      </SectionStyled>

      <SectionDivider label="Dados Bancários" />
      <SectionStyled name="Endereço">
        <LineSectionStyled columns="1.36fr 1.36fr 2.5fr 5.5fr">
          <InputField
            label="Banco (código)"
            id="bank.code"
            name="bank.code"
            type="text"
            onChange={handleChange}
            value={values.bank.code}
            hasError={!!errors.bank?.code && !!touched.bank?.code}
            errorMessage={String(errors.bank?.code)}
          />

          <InputField
            label="Agência"
            id="bank.agency"
            name="bank.agency"
            type="text"
            onChange={handleChange}
            value={values.bank.agency}
            hasError={!!errors.bank?.agency && !!touched.bank?.agency}
            errorMessage={String(errors.bank?.agency)}
          />

          <InputField
            label="Conta Corrente (com dígito)"
            id="bank.CC"
            name="bank.CC"
            type="text"
            onChange={handleChange}
            value={values.bank.CC}
            hasError={!!errors.bank?.CC && !!touched.bank?.CC}
            errorMessage={String(errors.bank?.CC)}
          />

          <InputField
            label="Chave Pix"
            id="bank.pix"
            name="bank.pix"
            type="text"
            onChange={handleChange}
            value={values.bank.pix}
            hasError={!!errors.bank?.pix && !!touched.bank?.pix}
            errorMessage={String(errors.bank?.pix)}
          />
        </LineSectionStyled>
      </SectionStyled>

      <AttachDocumentsSection editingProps={values.id ? { code: values.id, module: 'shipping-company' } : undefined} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Salvar" color="pink" onPress={handleSubmit} />
          <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={() => navigate(-1)} />
        </FooterPage.RightContent>
        <FooterPage.LeftContent>
          <ToggleCheckboxView
            id="status"
            name="status"
            label="Ativo"
            checked={values.status === 'active'}
            onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'active' : 'inactive')}
          />
        </FooterPage.LeftContent>
      </FooterPage.Root>
    </FormStyled>
  );
};
