import React, { useCallback, useMemo } from 'react';
import * as S from './weighing-totten-modals.styled';
import { ModalBark } from '../../../../components/weighing-totten-modal/weighing-totten-modal.component';
import { WeighingTottenButton } from '../../../../components/weighing-totten-button/weighing-totten-button.component';
import { WeighingTottenModalsDetails } from './weighing-totten-modal-details/weighing-totten-modal-details.component';
import { WeighingTottenModalCheckin } from './weighing-totten-modal-checkin/weighing-totten-modal-checkin.component';
import { WeighingTottenCpfConfirmation } from '../weighing-totten-cpf-confirmation/weighing-totten-cpf-confirmation.component';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export type IModalType = 'success' | 'error' | 'details' | 'checkin' | 'cpf-confirmation' | '' | 'accomplished';

interface IWeighingTottenMessageModalView {
  url: string;
  title: ReactJSXElement;
  message?: ReactJSXElement;
  btnLabel: string;
  modalType: IModalType;
  hasError: boolean;
  setModalType: (value: IModalType) => void;
}
export const WeighingTottenMessageModalView: React.FC<IWeighingTottenMessageModalView> = ({
  url,
  btnLabel,
  title,
  message,
  modalType,
  hasError,
  setModalType,

}) => {

  if (modalType === '') {
    setModalType(hasError ? 'error' : 'success');
  }

  const renderModal = useCallback(() => {
    switch (modalType) {
      case 'error':
        window.location.reload();
        break;
      case 'success':
        setModalType('details');
        break;
      case 'checkin':
        setModalType('checkin');
        break;
      case 'accomplished':
        window.location.reload();
        break;

    }
  }, [modalType, setModalType]);

  const renderModalContent = useMemo(() => {
    
    if (modalType === 'error' || modalType === 'success' || modalType === 'accomplished') {
      return (
        <>
          <S.Text>{title}</S.Text>
          <S.Icon src={url} alt="" />
          <S.Text>{message}</S.Text>
          <WeighingTottenButton callback={renderModal}>{btnLabel}</WeighingTottenButton>
        </>
      );
    }

    if (modalType === 'details') {
      return <WeighingTottenModalsDetails callback={() => setModalType('cpf-confirmation')} />;
    }

    if (modalType === 'cpf-confirmation') {
      return <WeighingTottenCpfConfirmation callback={() => setModalType('checkin')}/>
    }

    if (modalType === 'checkin') {
      return <WeighingTottenModalCheckin />;
    }
  }, [btnLabel, message, modalType, renderModal, setModalType, title, url]);

  return (
    <ModalBark>
      <S.Wrapper>{renderModalContent}</S.Wrapper>
    </ModalBark>
  );
};
