import { useCallback, useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getSaleOfferByIdService } from '../../../sale-offer-update/services/get-sale-offer-by-id/get-sale-offer-by-id.service';
import { convertSaleDetailsToSaleRegister } from '../../mappers/convert-sale-details-to-sale-register/convert-sale-details-to-sale-register.mapper';
import { ISaleOfferFormRegisterPayloadDomain } from '../../domains/sale-offer-form-register.domain';
import { converterSaleOfferDetailsResponseToDomain } from '../../../sale-offer-update/hooks/use-get-sale-offer-by-id/mappers/converter-sale-offer-details-response-to-domain/converter-sale-offer-details-response-to-domain.mapper';

export const useGetSaleOfferById = () => {
  const { saleOfferDetails } = useContext(SaleOfferContext);
  const { setSaleOfferDetailsRequest, setSaleOfferDetailsSuccess, setSaleOfferDetailsError } = saleOfferDetails;

  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const saleOfferId = useMemo(() => {
    if (location.state) {
      return location.state.saleOfferId;
    }
    return id ?? '';
  }, [id, location.state]);

  return useCallback(async (): Promise<ISaleOfferFormRegisterPayloadDomain | undefined> => {
    try {
      setSaleOfferDetailsRequest();

      const data = await getSaleOfferByIdService(saleOfferId);

      const saleOfferDetails = converterSaleOfferDetailsResponseToDomain(data);

      setSaleOfferDetailsSuccess(saleOfferDetails);

      const saleOfferRegisterValues = convertSaleDetailsToSaleRegister(saleOfferDetails);

      return saleOfferRegisterValues;
    } catch (error: any) {
      setSaleOfferDetailsError();
      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, [saleOfferId, setSaleOfferDetailsError, setSaleOfferDetailsRequest, setSaleOfferDetailsSuccess]);
};
