import React, { FC, useMemo } from 'react';
import { ConfirmationModal } from 'src-new/components/confirmation-modal/confirmation-modal.component';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';
import {
  IModalButtonProps,
  IModalDescriptionProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';

interface IEquipmentConfirmationModalViewProps {
  primaryButtonProps: IModalPrimaryButtonProps;
  secondaryButtonProps: IModalButtonProps;
}

export const EquipmentConfirmationModalView: FC<IEquipmentConfirmationModalViewProps> = ({
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const renderDescription = useMemo((): IModalDescriptionProps => {
    return {
      primaryRow: 'Isso excluirá os vales equipamentos selecionados.',
      secondaryRow: 'Tem certeza?',
    };
  }, []);

  return (
    <ConfirmationModal
      title={'Excluir Vale Equipamento(s)'}
      description={renderDescription}
      icon={<WarningDeleteIcon />}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    />
  );
};
