import { FC, ReactNode } from 'react';

import * as S from './toogle-list-view.styled';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IToogleListViewProps {
  isPressed: boolean;
  leftIcon: ReactNode;
  rightIcon: ReactNode;
  color?: ThemeColorsV2Type;
  handleToogleClick: (active: boolean) => void;
}

export const ToogleListView: FC<IToogleListViewProps> = ({
  isPressed,
  leftIcon,
  rightIcon,
  color,
  handleToogleClick,
}) => {
  return (
    <S.ToggleContainer>
      <S.LeftButton isPressed={isPressed} color={color} onClick={() => handleToogleClick(false)}>
        {leftIcon}
      </S.LeftButton>

      <S.RightButton isPressed={!isPressed} color={color} onClick={() => handleToogleClick(true)}>
        {rightIcon}
      </S.RightButton>
    </S.ToggleContainer>
  );
};
