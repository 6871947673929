import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import dayjs from 'dayjs';
import { Button, Tag } from 'logshare-ui-kit';
import { Trash2 } from 'lucide-react';
import { FC, Fragment, ReactElement, useMemo } from 'react';
import * as S from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/components/sale-offer-register-form/components/sale-offer-register-form-mount/components/sale-offer-register-form-mount-tariff/sale-offer-register-form-mount-tariff-view.styled';
import { ISaleOfferShipperTariffRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { convertAndFormatDecimalNumber, convertDecimalFormat } from 'src-new/utils/convert-decimal-format.util';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface ISaleOfferFormMountTariffViewProps {
  values: Array<ISaleOfferShipperTariffRegisterPayloadDomain>;
  companyName: string;
  handleExcludeTariffSection: (tariffIndex: number) => void;
}

export const SaleOfferFormMountTariffView: FC<ISaleOfferFormMountTariffViewProps> = ({
  values,
  companyName,
  handleExcludeTariffSection,
}) => {
  return useMemo((): ReactElement => {
    return (
      <Fragment>
        {values.map((tariff, index) => (
          <S.Section key={index}>
            <LineSectionLayout columns="1fr 0.5fr">
              <S.Div>
                <S.TitleContent>
                  <S.TitleStrong>
                    {tariff.vehicleType ?? '-'} - {tariff.vehicleCategory}
                  </S.TitleStrong>
                </S.TitleContent>

                <S.TagContent>
                  <Tag
                    label={`FATURADO POR ${tariff.invoicedBy}`}
                    color={ThemeColorsV2Type.Blue}
                    display="flex"
                    size="md"
                    variant="ghost"
                  />

                  <Tag
                    LeftIcon={
                      <S.TagText>
                        <DoubleArrowDownBlueIcon />
                        CO₂
                      </S.TagText>
                    }
                    label="0 KG"
                    color={ThemeColorsV2Type.Blue}
                    variant="ghost"
                    display="flex"
                    size="md"
                  />
                </S.TagContent>

                <S.InfoWrapper>
                  <S.InfoContainer>
                    <S.InfoContent>
                      <S.InfoText>Transportadora</S.InfoText>
                      <S.Strong>{tariff.shipping.name === '' ? 'A DEFINIR' : tariff.shipping.name}</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Frequência</S.InfoText>
                      <S.Strong>{tariff.frequency} / Mês</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Capacidade</S.InfoText>
                      <S.Strong>{`${tariff.weight === '0' ? '0.000' : tariff.weight} KG | ${tariff.pallets} PALETES | ${
                        tariff.cubage
                      } M³`}</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Gris/Advalorem</S.InfoText>
                      <S.Strong>
                        {tariff.invoicedBy === 'logshare' ? 'POLÍTICA LOGSHARE' : 'POLÍTICA EMBARCADOR'}
                      </S.Strong>
                    </S.InfoContent>
                  </S.InfoContainer>

                  <S.InfoContainer>
                    <S.InfoContent>
                      <S.InfoText>Freetime Carga</S.InfoText>
                      <S.Strong>{dayjs().startOf('day').add(5, 'hour').format('HH:mm')} HORAS</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Freetime Descarga</S.InfoText>
                      <S.Strong>{dayjs().startOf('day').add(5, 'hour').format('HH:mm')} HORAS</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Diária</S.InfoText>
                      <S.Strong>{convertCurrencyFormat.format(tariff.freetime.dailyFine)}</S.Strong>
                    </S.InfoContent>

                    <S.InfoContent>
                      <S.InfoText>Hora Adicional</S.InfoText>
                      <S.Strong>{convertCurrencyFormat.format(tariff.freetime.hourlyFine)}</S.Strong>
                    </S.InfoContent>
                  </S.InfoContainer>
                </S.InfoWrapper>
              </S.Div>

              <S.ValuesWrapper>
                <S.Line height="100%" />

                <S.Div>
                  <S.Strong>Detalhes da oferta de venda</S.Strong>
                  <S.ValuesContainer>
                    <S.ValuesContent>
                      <S.Text>
                        RECEITA <S.Strong>{companyName}</S.Strong>
                      </S.Text>
                      <S.ValueText>
                        <S.Text>R$</S.Text>
                        <S.Text>{convertAndFormatDecimalNumber(tariff.valueShipper)}</S.Text>
                      </S.ValueText>
                    </S.ValuesContent>

                    <S.ValuesContent>
                      <S.Text>
                        RECEITA <S.Strong>TRANSPORTADORA</S.Strong>
                      </S.Text>
                      <S.ValueText>
                        <S.Text>R$</S.Text>
                        <S.Text>{convertAndFormatDecimalNumber(tariff.valueShipping)}</S.Text>
                      </S.ValueText>
                    </S.ValuesContent>

                    <S.ValuesContent>
                      <S.Text>
                        RECEITA <S.Strong>LOGSHARE</S.Strong>
                      </S.Text>
                      <S.ValueText>
                        <S.Text>R$</S.Text>
                        <S.Text>{convertAndFormatDecimalNumber(tariff.valueLogshare)}</S.Text>
                      </S.ValueText>
                    </S.ValuesContent>

                    <S.LineContainer>
                      <S.Line width="100%" />
                    </S.LineContainer>

                    <S.ValuesContent>
                      <S.Text>
                        <S.Strong>FRETE VALOR</S.Strong>
                      </S.Text>
                      <S.ValueText>
                        <S.Text>R$</S.Text>
                        <S.Text>{convertDecimalFormat.format(tariff.valueTotal)}</S.Text>
                      </S.ValueText>
                    </S.ValuesContent>
                  </S.ValuesContainer>

                  <S.Footer>
                    <S.FooterButtons>
                      <Button
                        label=""
                        color="gray-dark"
                        onPress={() => handleExcludeTariffSection(index)}
                        LeftIcon={<Trash2 size={20} color="#5d5d5d" strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                      />
                    </S.FooterButtons>
                  </S.Footer>
                </S.Div>
              </S.ValuesWrapper>
            </LineSectionLayout>
          </S.Section>
        ))}
      </Fragment>
    );
  }, [companyName, handleExcludeTariffSection, values]);
};
