import { ILocationsContext } from '../types/locations.types';

export const locationsReducerResetState = (state: ILocationsContext): ILocationsContext => {
  return {
    ...state,
    locationsList: {
      ...state.locationsList,
      statusRequest: 'INITIAL',
      locations: [],
      filterActions: {
        ...state.locationsList.filterActions,
        filters: {
          column: 'ID',
          order: 'DESC',
          status: 'active',
          isPageLimit: false,
          page: 1,
          searchValue: '',
        },
      },
    },
  };
};
