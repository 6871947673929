import React from 'react';

import {
  IconStyled,
  InputStyled,
  InputWrapperStyled,
  LabelStyled,
  MessageErrorStyled,
  WrapperStyled,
} from './input-field-cpf.styled';

export type InputFieldCPFProps = {
  id: string;
  name: string;
  value: string;
  type: 'text';
  label?: string;
  placeholder?: string;
  initialValue?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyUp: (e: React.FormEvent<HTMLInputElement>) => void;
};

const InputFieldCpfView: React.FC<InputFieldCPFProps> = ({
  id,
  name,
  value,
  type = 'text',
  label = '',
  placeholder = '',
  reactNode,
  reactNodePosition = 'left',
  disabled = false,
  hasError = false,
  errorMessage = 'Invalid value',
  onChange,
  onBlur,
  handleKeyUp,
}) => {
  return (
    <WrapperStyled>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <InputWrapperStyled iconPosition={reactNodePosition} disabled={disabled} hasError={hasError}>
        {!!reactNode && <IconStyled>{reactNode}</IconStyled>}
        <InputStyled
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={handleKeyUp}
          maxLength={14}
          hasError={hasError}
        />
      </InputWrapperStyled>
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};

export default InputFieldCpfView;
