import React from 'react';

import {
  FormikStyled,
  FormStyled,
  SectionStyled,
  WrapperSectionResetPasswordStyled,
  ForgatButtonContainerStyled,
} from './form-reset-password.styled';

import { IResetPassword } from 'domain/reset-password';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

type FormResetPasswordProps = {
  initialValues: IResetPassword;
  validationSchema: object;
  handleSubmit: (values: IResetPassword) => void;
  goBackToLogin: () => void;
};

const FormResetPasswordView: React.FC<FormResetPasswordProps> = ({
  initialValues,
  validationSchema,
  handleSubmit,
  goBackToLogin,
}) => {
  return (
    <FormikStyled
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values as IResetPassword);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <FormStyled>
          <SectionStyled name="Informações de Reset de Senha">
            <WrapperSectionResetPasswordStyled>
              <InputField
                label="Login"
                id="login"
                name="login"
                type="text"
                onChange={handleChange}
                value={values.login}
                hasError={!!errors.login && !!touched.login}
                errorMessage={String(errors.login)}
              />
              <InputField
                label="E-mail"
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                hasError={!!errors.email && !!touched.email}
                errorMessage={String(errors.email)}
                upperCase={false}
              />
            </WrapperSectionResetPasswordStyled>
          </SectionStyled>
          <ForgatButtonContainerStyled>
            <Button bgColor="white" title="Voltar" callback={goBackToLogin} size="large" />
            <Button bgColor="blue" title="Enviar" callback={handleSubmit} size="large" />
          </ForgatButtonContainerStyled>
        </FormStyled>
      )}
    </FormikStyled>
  );
};

export default FormResetPasswordView;
