import * as Yup from 'yup';

export const riskManagementSchema = Yup.object().shape({
  gr: Yup.string().required('Campo obrigatório'),
  driverId: Yup.number(),
  registerDate: Yup.string().required('Campo obrigatório'),
  validateDate: Yup.string().required('Campo obrigatório'),
  consultedBy: Yup.string().required('Campo obrigatório'),
  consultationRecord: Yup.string().required('Campo obrigatório'),
  status: Yup.mixed().oneOf(['Aprovado', 'Reprovado', 'Vencido']).required('Campo obrigatório'),
});
