import React from 'react';

export const CardResumeIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 20 25" fill="none">
      <path
        d="M5.26021 6.55954H14.5479C14.8766 6.55954 15.1506 6.2961 15.1506 5.97998V3.451C15.1506 3.13488 14.8766 2.87144 14.5479 2.87144H12.9862V2.81876C12.904 1.23815 11.5616 0 9.91775 0C8.27391 0 6.93144 1.23815 6.84925 2.81876V2.87144H5.26021C4.93144 2.87144 4.65747 3.13488 4.65747 3.451V5.97998C4.65747 6.2961 4.93144 6.55954 5.26021 6.55954ZM9.91775 1.73867C10.6027 1.73867 11.1506 2.26554 11.1506 2.92413C11.1506 3.58272 10.6027 4.10959 9.91775 4.10959C9.23281 4.10959 8.68487 3.58272 8.68487 2.92413C8.68487 2.29189 9.23281 1.73867 9.91775 1.73867Z"
        fill="#1F2B4F"
      />
      <path
        d="M18.5753 4.58374H16.5753V5.9536C16.5753 7.00734 15.6712 7.87668 14.5753 7.87668H5.28767C4.19178 7.87668 3.28767 7.00734 3.28767 5.9536V4.58374H1.39726C0.630137 4.58374 0 5.18964 0 5.92726V23.6564C0 24.3941 0.630137 25 1.39726 25H18.6027C19.3699 25 20 24.3941 20 23.6564V5.92726C19.9726 5.18964 19.3425 4.58374 18.5753 4.58374ZM15.7808 20.8113C15.7808 21.0748 15.5616 21.2592 15.3151 21.2592H4.65753C4.38356 21.2592 4.19178 21.0484 4.19178 20.8113V20.0474C4.19178 19.7839 4.41096 19.5995 4.65753 19.5995H15.2877C15.5616 19.5995 15.7534 19.8103 15.7534 20.0474V20.8113H15.7808ZM15.7808 16.57C15.7808 16.8335 15.5616 17.0179 15.3151 17.0179H4.65753C4.38356 17.0179 4.19178 16.8071 4.19178 16.57V15.8061C4.19178 15.5426 4.41096 15.3582 4.65753 15.3582H15.2877C15.5616 15.3582 15.7534 15.569 15.7534 15.8061V16.57H15.7808ZM15.7808 12.3287C15.7808 12.5922 15.5616 12.7766 15.3151 12.7766H4.65753C4.38356 12.7766 4.19178 12.5658 4.19178 12.3287V11.5648C4.19178 11.3013 4.41096 11.1169 4.65753 11.1169H15.2877C15.5616 11.1169 15.7534 11.3277 15.7534 11.5648V12.3287H15.7808Z"
        fill="#1F2B4F"
      />
    </svg>
  );
};
