import { formatDecimal } from 'utils/format-numbers';
import theme from 'styles/theme';

type Color = keyof typeof theme.colorsV2;

export const createTag = (text: string, color: Color) => {
  const label = text.toUpperCase();
  const hexColor = theme.colorsV2[`${color}`];
  return {
    text: label,
    color: hexColor,
    textColor: 'white',
    borderColor: hexColor,
  };
};

export const createCO2Tags = (co2?: number) => {
  return [
    {
      text: `CO₂, ${formatDecimal(co2 ?? 0)} KG`,
      color: 'white',
      textColor: '#119895',
      borderColor: '#119895',
    },
  ];
};
