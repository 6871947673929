import React, { FC, useMemo } from 'react';
import * as S from './kanban-section-view.styled';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { IScheduleKanbanDetails } from '../../../../domains/schedule-kanban-list/schedule-kanban.domain';
import { Button, Tag } from 'logshare-ui-kit';
import { Files, Info, Trash2 } from 'lucide-react';
import { convertFreightType } from 'src-new/utils/convert-freight-type/convert-freight-type.util';
import { OccurenceListIcon } from 'assets/icons/occurrence-list.icon';
import TooltipComponent from 'components/Tooltip';
import { convertOccurrenceIconColor } from 'src-new/utils/convert-occurrence-icon-color/convert-occurence-icon-color.util';
import { convertStatusCodeColor } from 'utils/status-code';

export interface IKanbanSectionViewProps {
  title: string;
  color: ThemeColorsV2Type;
  kanbanListDetails: Array<IScheduleKanbanDetails>;
  totalItems: number;
  handleSelectedFreight: (id: number) => void;
  handleOpenOcurrenceModel: (id: number) => void;
  handleCancelFreight: (id: number) => void;
}

export const KanbanSectionView: FC<IKanbanSectionViewProps> = ({
  title,
  color,
  kanbanListDetails,
  handleOpenOcurrenceModel,
  handleCancelFreight,
  handleSelectedFreight,
  totalItems,
}) => {
  const renderKanbanList = useMemo(() => {
    return kanbanListDetails.map((item) => {
      return (
        <S.WrapperList key={item.id}>
          <S.ContainerKanban>
            <S.HeaderCard>
              <S.LabelId>
                ID {item.id}
                <TooltipComponent
                  title={
                    <S.ContainerTooltip>
                      <S.LabelTooltip>Ocorrências</S.LabelTooltip>

                      <S.LabelTooltip>Pendentes: {item.occurrences.unattended}</S.LabelTooltip>

                      <S.LabelTooltip>Em Andamento: {item.occurrences.progress}</S.LabelTooltip>

                      <S.LabelTooltip>Finalizadas: {item.occurrences.completed}</S.LabelTooltip>
                    </S.ContainerTooltip>
                  }
                  location="top"
                  icon={
                    <S.Div onClick={() => handleOpenOcurrenceModel(item.id)}>
                      <OccurenceListIcon color={convertOccurrenceIconColor(item.occurrences)} />
                    </S.Div>
                  }
                />
              </S.LabelId>

              <TooltipComponent
                title={<span style={{ fontSize: '11px', marginBottom: '5px' }}>{item.statusCode}</span>}
                location="left"
                icon={
                  <S.ContainerTag>
                    <Tag
                      label={item.statusCode}
                      color={convertStatusCodeColor(item.statusCode?.toLowerCase())}
                      size="md"
                      display="inline-flex"
                      LeftIcon={convertFreightType(item.scheduleType).icon}
                      variant="ghost"
                    />
                  </S.ContainerTag>
                }
              />
            </S.HeaderCard>

            <S.Div onClick={() => handleSelectedFreight(item.id)}>
              <S.Title>{`${item.name?.toUpperCase()}`}</S.Title>

              <S.ContainerContent>
                <S.Content>
                  Origem <S.CardInfo>{item.origin?.toUpperCase()}</S.CardInfo>
                </S.Content>

                <S.Content>
                  Destino <S.CardInfo>{item.destiny?.toUpperCase()}</S.CardInfo>
                </S.Content>
              </S.ContainerContent>

              <S.ContainerContent>
                <S.Content>
                  Coleta <S.CardInfo>{item.collect}</S.CardInfo>
                </S.Content>

                <S.Content>
                  Entrega
                  <S.CardInfo>{item.delivery}</S.CardInfo>
                </S.Content>
              </S.ContainerContent>

              <S.Content>
                Veículo <S.CardInfo>{item.vehicle?.toUpperCase()}</S.CardInfo>
              </S.Content>
            </S.Div>
          </S.ContainerKanban>

          <S.ContainerButton>
            <S.ContainerTooltip>
              <TooltipComponent
                title={
                  <S.ContainerTooltip>
                    <S.ContainerHeaderTooltip>
                      <strong>Informações Adicionais</strong>
                    </S.ContainerHeaderTooltip>

                    <S.ContainerInfosTooltip>
                      <S.LabelTooltip>Pedidos: {item.scheduleInformation.orders}</S.LabelTooltip>
                      <S.LabelTooltip>Nº Interno: {item.scheduleInformation.internalNumber}</S.LabelTooltip>
                      <S.LabelTooltip>Nº OC: {item.scheduleInformation.ocInternalNumber}</S.LabelTooltip>
                      <S.LabelTooltip>Nº Embarque: {item.scheduleInformation.boardingNumber}</S.LabelTooltip>
                      <S.LabelTooltip>Senha: {item.scheduleInformation.schedulingPassword}</S.LabelTooltip>
                    </S.ContainerInfosTooltip>
                  </S.ContainerTooltip>
                }
                location="top"
                icon={<Info size={16} color="#6e6e6e" />}
              />
            </S.ContainerTooltip>

            <S.FooterButtons>
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                LeftIcon={<Files width={18} />}
                onPress={() => null}
                disabled
              />
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                LeftIcon={<Trash2 width={18} />}
                onPress={() => handleCancelFreight(item.id)}
              />
            </S.FooterButtons>
          </S.ContainerButton>
        </S.WrapperList>
      );
    });
  }, [kanbanListDetails, handleOpenOcurrenceModel, handleCancelFreight]);

  return (
    <S.Wrapper>
      <S.ContainerHeader color={color}>
        <S.Label>{title}</S.Label>
        <S.Label>{totalItems}</S.Label>
      </S.ContainerHeader>

      <S.ContainerScroll color={color}>
        <S.CardRouterBody>{renderKanbanList}</S.CardRouterBody>
      </S.ContainerScroll>
    </S.Wrapper>
  );
};
