import React from 'react';
import { FormikProps, withFormik } from 'formik';

import { ButtonContainer, ContainerFooterStyled, PhoneContainer, SectionStyled } from './login-demo.styled';

import { ILoginDemo } from 'pages/login/services/entities/login.entity';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

export type LoginDemoViewProps = {
  loginDemoValues: ILoginDemo;
  handleLogin: (values: ILoginDemo) => void;
  schemaValidation: object;
};

const LoginDemoView: React.FC<LoginDemoViewProps> = ({ loginDemoValues, handleLogin, schemaValidation }) => {
  const InnerForm = (props: FormikProps<ILoginDemo>) => {
    const { values, handleChange, handleSubmit, errors, touched } = props;
    return (
      <SectionStyled>
        <InputField
          label="Nome"
          id="name"
          name="name"
          type="text"
          onChange={handleChange}
          value={values.name}
          hasError={!!errors.name && !!touched.name}
          errorMessage={String(errors.name)}
          upperCase={false}
        />

        <InputField
          label="E-mail"
          id="email"
          name="email"
          type="email"
          onChange={handleChange}
          value={values.email}
          hasError={!!errors.email && !!touched.email}
          errorMessage={String(errors.email)}
          upperCase={false}
        />

        <ContainerFooterStyled>
          <PhoneContainer>
            <InputFieldPhone
              label="Telefone"
              id="phone"
              name="phone"
              type="text"
              onChange={handleChange}
              value={values.phone}
            />
          </PhoneContainer>

          <ButtonContainer>
            <Button title="Solicitar" callback={handleSubmit} bgColor="blue" size="large" />
          </ButtonContainer>
        </ContainerFooterStyled>
      </SectionStyled>
    );
  };
  const LoginDemoForm = withFormik<ILoginDemo, ILoginDemo>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleLogin(values);
    },
    validationSchema: schemaValidation,
  })(InnerForm);

  return <LoginDemoForm {...loginDemoValues} />;
};

export default LoginDemoView;
