import React, { ReactNode, useMemo } from 'react';

import { ContainerTitle, TitleStyled, TraceStyled } from './title.styled';

interface ITitleViewProps {
  dataTestId?: string;
  title: string;
  hasTrace?: boolean;
  isForm?: boolean;
  rightIcon?: ReactNode;
}

const TitleView: React.FC<ITitleViewProps> = ({ dataTestId, title, hasTrace, isForm, rightIcon }: ITitleViewProps) => {
  const renderTrace = useMemo(() => {
    if (hasTrace) {
      return <TraceStyled data-testid="title-trace" />;
    }
  }, [hasTrace]);

  return (
    <ContainerTitle data-testid={`${dataTestId}-title`}>
      <TitleStyled isForm={isForm}>
        {title} {rightIcon}
      </TitleStyled>
      {renderTrace}
    </ContainerTitle>
  );
};

export default TitleView;
