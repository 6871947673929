import React from 'react';

import OurPrivacyPoliciesModalView from './our-privacy-policies-modal-view';

interface IProps {
  title: string;
  show: boolean;
  useCallBackCloseButton?: () => void;
  children: React.ReactNode;
}

const OurPrivacyPoliciesModal: React.FC<IProps> = ({ title, show, children, useCallBackCloseButton }) => {
  return (
    <OurPrivacyPoliciesModalView title={title} show={show} useCallBackCloseButton={useCallBackCloseButton}>
      {children}
    </OurPrivacyPoliciesModalView>
  );
};

export default OurPrivacyPoliciesModal;
