import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import VehicleUpdateFormView from './vehicle-update-form-view';
import { VehicleRegisterSchema } from './schemas-validation/schemas-validation';
import {
  PATH_TO_VEHICLE_LIST,
  initialValues,
  optionsVehicleType,
  optionsVehicleCategory,
  optionsVehicleTracker,
  optionsVehicleYear,
  optionsVehicleSensors,
  optionsVehicleActuators,
  optionsFleetType,
  optionsFuel,
} from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { SelectItem } from 'pages/vehicle/services/entities/select-items.entity';
import { useUpdateVehicleHook } from 'pages/vehicle/hooks/use-vehicle-update/use-vehicle-update.hook';
import { useVehicleDetailHook } from 'pages/vehicle/hooks/use-vehicle-details/use-vehicle-details.hook';
import { useDetailsTransportHook } from 'pages/transport-company/hooks/transport-details/transport-details.hook';
import { IVehicleCreated } from 'domain/vehicle';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const VehicleUpdateForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vehicleValues, setVehicleValues] = useState<IVehicleCreated>(initialValues);
  const [shippingCompanyName, setShippingCompanyName] = useState<string>();
  const navigate = useNavigate();
  const { id } = useParams();

  const detailVehicleHook = useVehicleDetailHook();
  const updateVehicleHook = useUpdateVehicleHook();
  const detailShippingCompany = useDetailsTransportHook();

  const goBackToVehicleList = useCallback(() => navigate(PATH_TO_VEHICLE_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (vehicle: IVehicleCreated) => {
      setIsLoading(true);
      updateVehicleHook(vehicle)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToVehicleList();
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToVehicleList, updateVehicleHook],
  );

  const apiCallShipping = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return shippingCompanyFilterSearchService(searchValue);
    }
    return [];
  };

  useEffect(() => {
    async function run() {
      if (id) {
        const vehicle = await detailVehicleHook(id);
        if (vehicle.shippingCompanyId) {
          const shippingCompany = await detailShippingCompany(vehicle.shippingCompanyId);
          setShippingCompanyName(shippingCompany.name);
        }
        setVehicleValues(vehicle);
      }
    }
    run();
  }, [id, detailVehicleHook, setVehicleValues, detailShippingCompany]);

  return (
    <VehicleUpdateFormView
      vehicleUpdateValues={vehicleValues}
      optionsFuel={optionsFuel}
      optionsVehicleType={optionsVehicleType}
      optionsVehicleCategory={optionsVehicleCategory}
      optionsVehicleSensors={optionsVehicleSensors}
      optionsVehicleActuators={optionsVehicleActuators}
      optionsVehicleTracker={optionsVehicleTracker}
      optionsVehicleYear={optionsVehicleYear}
      optionFleetType={optionsFleetType}
      handleGoBack={goBackToVehicleList}
      handleRegister={handleSubmit}
      shippingApiCall={apiCallShipping}
      schemaValidation={VehicleRegisterSchema}
      shippingCompanyName={shippingCompanyName}
      isLoading={isLoading}
    />
  );
};

export default VehicleUpdateForm;
