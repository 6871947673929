import React, { useCallback, useMemo } from 'react';
import * as S from './documents-others-view.styled';
import { Table } from 'src-new/components/table/table.component';
import { IRow } from 'src-new/components/table/table.types';
import { Button, Tag } from 'logshare-ui-kit';
import { DownloadCloud, Eye, FileCheck, Trash2 } from 'lucide-react';
import { ToogleList } from 'src-new/components/toogle-list/toogle-list.component';
import { ListIcon } from 'assets/icons/list.icon';
import { KanbanListIcon } from 'assets/icons/kanban-list.icon';
import { Box } from 'src-new/components/box/box.component';
import InputUploadFile from 'pages/tracking-occurrence/tracking-occurrence-register/components/tracking-occurrence-input-upload/input-file-upload';
import { downloadDocument, downloadFromUrl } from 'src-new/utils/download-file.util';
import TooltipComponent from 'components/Tooltip';
import { Loading } from 'src-new/components/loading/loading.component';
import {
  IOthersDocuments,
  ScheduleDocumentsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { ViewModeType } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/components/execution-documents/components/documents-nfe/documents-nfe.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { extractExtensionFromUrl } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/utils/extract-extension-from-url';
import { extractNameFromUrl } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/utils/extract-name-from-url';

const extensionsType = ['png', 'jpg', 'jpeg', 'gif'];

export interface IDocumentsOthersViewProps {
  othersDocuments: Array<IOthersDocuments>;
  isLoading: boolean;
  viewMode: ViewModeType;
  isEmptyList: boolean;
  handleOpenModal: (modalType: ScheduleDocumentsModalType, id: number) => void;
  handleToggleClick: () => void;
}

export const DocumentsOthersView: React.FC<IDocumentsOthersViewProps> = ({
  othersDocuments,
  isLoading,
  handleOpenModal,
  isEmptyList,
  viewMode,
  handleToggleClick,
}) => {
  const renderExtension = useCallback((extension: string) => {
    return (
      <S.Div>
        <S.Title>Extensão</S.Title>
        <Tag color="blue" display="inline-flex" label={extension} size="md" variant="solid" />
      </S.Div>
    );
  }, []);

  const renderTitleSubtitle = useCallback(
    (title: string, subtitle?: string) => (
      <S.Div>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.Div>
    ),
    [],
  );

  const rows = useMemo((): Array<IRow> => {
    return othersDocuments?.map(({ fileKey, id }) => {
      const fileName = extractNameFromUrl(fileKey ?? '');
      const extension = extractExtensionFromUrl(fileKey ?? '');

      return {
        key: id,
        valuesColumns: [
          { value: <S.Identification>{fileName}</S.Identification> },
          { value: renderExtension(extension) },
          {
            value: (
              <S.WrapperActions key={id}>
                <TooltipComponent
                  title="Download"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        label=""
                        color="white"
                        LeftIcon={<DownloadCloud size={20} strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                        onPress={() => downloadDocument(fileKey, `${fileName}.${extension}`)}
                      />
                    </S.ContainerButtons>
                  }
                />
                <TooltipComponent
                  title="Visualizar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        label=""
                        color="white"
                        LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                        onPress={() => downloadFromUrl(fileKey)}
                      />
                    </S.ContainerButtons>
                  }
                />
                <TooltipComponent
                  title="Deletar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        label=""
                        color="white"
                        LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                        onPress={() => handleOpenModal('othersDocuments', id)}
                      />
                    </S.ContainerButtons>
                  }
                />
              </S.WrapperActions>
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [handleOpenModal, othersDocuments, renderExtension]);

  const buildBoxes = useMemo(() => {
    const boxList = othersDocuments?.map(({ fileKey, id }) => {
      const fileName = extractNameFromUrl(fileKey ?? '');
      const extension = extractExtensionFromUrl(fileKey ?? '');
      return (
        <S.Box key={id}>
          <Box
            footerButtons={[
              <TooltipComponent
                key={id}
                title="Download"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      label=""
                      color="white"
                      LeftIcon={<DownloadCloud size={20} strokeWidth={2.75} />}
                      size="xsmall"
                      variant="ghost"
                      onPress={() => downloadFromUrl(fileKey)}
                    />
                  </S.ContainerButtons>
                }
              />,
              <TooltipComponent
                key={id}
                title="Visualizar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      label=""
                      color="white"
                      LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                      size="small"
                      variant="ghost"
                      onPress={() => downloadFromUrl(fileKey)}
                    />
                  </S.ContainerButtons>
                }
              />,
              <TooltipComponent
                key={id}
                title="Deletar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      label=""
                      color="white"
                      LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                      size="small"
                      variant="ghost"
                      onPress={() => handleOpenModal('othersDocuments', id)}
                    />
                  </S.ContainerButtons>
                }
              />,
            ]}
          >
            <S.InputUploadFile>
              {extensionsType.includes(extension) ? (
                <InputUploadFile urlFile={fileKey} handleSelectedImage={() => null} />
              ) : (
                <S.DownloadCloud>
                  <FileCheck />
                </S.DownloadCloud>
              )}
            </S.InputUploadFile>

            <S.InfoWrapper>
              {renderTitleSubtitle('Nome', fileName)}
              {renderExtension(extension)}
            </S.InfoWrapper>
          </Box>
        </S.Box>
      );
    });

    return <S.BoxWrapper gridColumns="1fr 1fr 1fr">{boxList}</S.BoxWrapper>;
  }, [handleOpenModal, othersDocuments, renderExtension, renderTitleSubtitle]);

  const renderContent = useMemo(() => {
    return viewMode === 'list' ? (
      <Table
        gridColumns="1fr 1fr 160px"
        header={[{ value: '' }]}
        rows={rows}
        height="100%"
        config={{
          hideHeader: true,
          body: {
            changeLineOnMouseOver: true,
          },
        }}
      />
    ) : (
      buildBoxes
    );
  }, [buildBoxes, rows, viewMode]);

  if (isLoading) {
    return (
      <S.StateContainer>
        <Loading />
      </S.StateContainer>
    );
  }

  if (isEmptyList) {
    return (
      <S.StateContainer>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.StateContainer>
    );
  }

  return (
    <S.Wrapper>
      <S.WrapperToogle>
        <ToogleList
          isPressed={viewMode === 'list'}
          leftIcon={<ListIcon />}
          rightIcon={<KanbanListIcon />}
          handleToogleClick={handleToggleClick}
        />
      </S.WrapperToogle>
      {renderContent}
    </S.Wrapper>
  );
};
