import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { useFormikContext } from 'formik';
import {
  BiddingOfferProposedValueView,
  IAcceptancePotential,
  ITotalFreight,
} from './bidding-offer-proposed-value-view.component';
import { UserContext } from 'state/user-context';
import { IBiddingOfferForm } from '../../types/bidding-offer-form/bidding-offer-form.types';
import { ICompany } from 'domain/company';
import { useGetIcms } from 'src-new/hooks/use-get-icms/get-icms.hook';

interface IBiddingOfferProposedValueProps {
  company?: ICompany;
  userCompany?: ICompany;
  shippingHomologate: boolean;
}
export const BiddingOfferProposedValue: React.FC<IBiddingOfferProposedValueProps> = ({
  company,
  userCompany,
  shippingHomologate,
}) => {
  const { isShippingCompany } = useContext(UserContext);
  const { values, setFieldValue } = useFormikContext<IBiddingOfferForm>();

  const [icms, setIcms] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getIcmsHook = useGetIcms();

  useEffect(() => {
    (async () => {
      if (isShippingCompany && shippingHomologate && icms === 0) {
        const icmsValue = await getIcmsHook(values.origin.uf, values.destination.uf);

        if (!icmsValue) return;

        setIcms(icmsValue);
      }
    })();
  }, [getIcmsHook, icms, isShippingCompany, shippingHomologate, values.destination.uf, values.origin.uf]);

  const handleChange = useCallback(
    (value?: string, name?: string) => {
      if (!value || !name) return;

      const freightValue = Number(value.replace(',', '.'));

      if (freightValue === values.proposedValue) return;

      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setFieldValue(name, freightValue);
    },
    [setFieldValue, values.proposedValue],
  );

  const logshareFee = useMemo((): number => {
    if (shippingHomologate && values.isSaleOffer) return 62;

    if (!values.isSaleOffer && userCompany?.feeLogshare) {
      const valueFee = values.proposedValue * (Number(userCompany.feeLogshare) / 100);

      if (!isNaN(valueFee)) return valueFee;
    }

    if (!company?.feeLogshare) return 0;

    const valueFee = values.proposedValue * (Number(company.feeLogshare) / 100);

    if (!isNaN(valueFee)) return valueFee;

    return 0;
  }, [shippingHomologate, values.isSaleOffer, values.proposedValue, userCompany?.feeLogshare, company]);

  const totalFreight = useMemo((): ITotalFreight => {
    const grisAdvalorem = ((Number(company?.advalorem) + Number(company?.gris)) * values.capacity.merchValue) / 100;

    const total = (values.proposedValue || logshareFee) - logshareFee + grisAdvalorem;

    let icmsValue = 0;
    let totalService = total;

    if (isShippingCompany && shippingHomologate) {
      icmsValue = total / (1 - icms / 100) - total;
      totalService = total + icmsValue;
    }

    setFieldValue('cost.grisAdvalorem', grisAdvalorem);
    setFieldValue('cost.totalFreight', total);
    setFieldValue('cost.icms', icmsValue);
    setFieldValue('cost.totalService', totalService);
    setFieldValue('cost.feeLogshare', logshareFee);

    return { total, totalService, icmsValue, grisAdvalorem };
  }, [
    company?.advalorem,
    company?.gris,
    icms,
    isShippingCompany,
    logshareFee,
    setFieldValue,
    shippingHomologate,
    values.capacity.merchValue,
    values.proposedValue,
  ]);

  const acceptancePotential = useMemo((): IAcceptancePotential => {
    const valueFreight = totalFreight.totalService;
    const freightValue = values.cost.freightValue;

    if (valueFreight < freightValue * 0.8) {
      return { color: ThemeColorsV2Type.Green, text: 'ALTO POTENCIAL' };
    }

    if (valueFreight > freightValue * 1.05) {
      return { color: ThemeColorsV2Type.Red, text: 'BAIXO POTENCIAL' };
    }

    return { color: ThemeColorsV2Type.ProYellow, text: 'MÉDIO POTENCIAL' };
  }, [values.cost.freightValue, totalFreight.totalService]);

  return (
    <BiddingOfferProposedValueView
      values={values}
      isLoading={isLoading}
      logshareFee={logshareFee}
      totalFreight={totalFreight}
      acceptancePotential={acceptancePotential}
      handleChange={handleChange}
    />
  );
};
