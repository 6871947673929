import { BiddingFilter } from 'pages-v2/biddings/services/list-biddings';

export const defaultBiddingFilter: BiddingFilter = {
  page: 1,
  limit: 20,
  search: '',
  column: 'id',
  order: 'ASC',

  createdAt: null,
  collectDate: null,
  status: '',
  vehicle: '',
  category: '',
  originCity: '',
  destinationCity: '',
};
