import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IDenyAllocation } from '../../components/schedule-page-body/schedule-page-body.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQueryClient } from 'react-query';
import { deleteDenyScheduleService } from '../../services/delete-deny-schedule/delete-deny-schedule.service';
import { useParams } from 'react-router-dom';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';

export const useUpdateDenySchedule = () => {
  const { scheduleList } = useContext(ScheduleContext);
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);
  const { id } = useParams<{ id: string }>();
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (values: IDenyAllocation) => {
      try {
        await deleteDenyScheduleService(values);

        showMessageFormatted({
          message: 'Oferta Negada',
          type: 'success',
        });

        scheduleList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });

        reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);
        reactQueryClient.invalidateQueries([
          'schedule-refused-list-table',
          scheduleRefusedList.scheduleRefusedListFilters.filters,
        ]);
        reactQueryClient.invalidateQueries(['schedule-kanban-list', scheduleList.scheduleListFiltersProps.filters]);

        if (id) {
          reactQueryClient.invalidateQueries(['schedule-details-by-id', id]);
        }
      } catch (error) {
        showMessageFormatted({
          message: `Erro ao negar viagem(ns) ${values.scheduleIds}.`,
          type: 'error',
        });
      }
    },
    [
      id,
      reactQueryClient,
      scheduleList.modalsProps,
      scheduleList.scheduleListFiltersProps.filters,
      scheduleRefusedList.scheduleRefusedListFilters.filters,
    ],
  );
};
