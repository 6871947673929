import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ScheduleOccurrenceModalView } from './schedule-occurrence-modal-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useGetScheduleOccurrenceList } from '../../../../hooks/use-get-schedule-occurrence-list/use-get-schedule-occurrence-list.hook';
import { IScheduleOccurrenceListDomain } from '../../../../domains/schedule-occurrence-list/schedule-occurrence-list.domain';
import { useNavigate } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const ScheduleOccurrenceModal: React.FC = () => {
  const { scheduleList, scheduleOccurrenceList } = useContext(ScheduleContext);

  const navigate = useNavigate();

  const getScheduleOccurrenceListHook = useGetScheduleOccurrenceList();

  useEffect(() => {
    if (scheduleOccurrenceList.statusRequest === 'INITIAL') {
      getScheduleOccurrenceListHook();
    }
  }, [getScheduleOccurrenceListHook, scheduleOccurrenceList.statusRequest]);

  const handleClose = useCallback(() => {
    scheduleList.modalsProps.setModalOpen({ open: false, ids: [], modalType: 'occurrences' });

    scheduleOccurrenceList.setScheduleOccurrenceListReset();
  }, [scheduleList.modalsProps, scheduleOccurrenceList]);

  const goToUpdatePage = useCallback(
    (occurrenceId: number) => {
      const trackingOccurrence = scheduleOccurrenceList.scheduleOccurrenceList.find(
        (occurrence) => occurrence.id === occurrenceId,
      );

      if (trackingOccurrence) {
        navigate(`/tracking-ocorrencias/atualizar/${occurrenceId}/${trackingOccurrence.scheduleId}`, {
          state: {
            sla: trackingOccurrence.sla,
            impact: trackingOccurrence.impact,
            description: trackingOccurrence.occurrenceType,
          },
        });
      } else {
        showMessageFormatted({
          message: 'Ocorreu um erro ao tentar selecionar a ocorrência',
          type: 'error',
        });
      }
    },
    [navigate, scheduleOccurrenceList.scheduleOccurrenceList],
  );

  const occurrenceList = useMemo(
    (): Array<IScheduleOccurrenceListDomain> => scheduleOccurrenceList.scheduleOccurrenceList,
    [scheduleOccurrenceList.scheduleOccurrenceList],
  );

  const isLoading = useMemo(
    (): boolean => scheduleOccurrenceList.statusRequest === 'PROCESSING',
    [scheduleOccurrenceList.statusRequest],
  );

  return (
    <ScheduleOccurrenceModalView
      occurrenceList={occurrenceList}
      isLoading={isLoading}
      handleClose={handleClose}
      goToUpdatePage={goToUpdatePage}
    />
  );
};
