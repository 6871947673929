import React, { useCallback, useContext, useMemo } from 'react';
import { TimeRecordingModalView } from './execution-time-recording-edit-modal-view.component';
import { useFormikContext } from 'formik';
import { IScheduleDetailsComplementDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import dayjs from 'dayjs';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { LOGGED_LOTS_USER } from 'src-new/infrastructure/lots-users/lots-users.constants';

export const TimeRecordingModal: React.FC = () => {
  const { user } = useContext(UserContext);

  const { scheduleRequestStatus, scheduleDetails } = useContext(ScheduleContext);
  const { values, setFieldValue, submitForm } = useFormikContext<IScheduleDetailsComplementDomain>();

  const isLoading = useMemo(
    () => scheduleRequestStatus.statusRequest === 'PROCESSING',
    [scheduleRequestStatus.statusRequest],
  );

  const lotsUser = useMemo(() => {
    if (user) return LOGGED_LOTS_USER.includes(user.login);
    return false;
  }, [user]);

  const handleFieldValue = useCallback(
    (field: string, date: dayjs.Dayjs | null) => {
      setFieldValue(field, date?.toISOString());
    },
    [setFieldValue],
  );

  const handleFinish = useCallback(() => {
    setFieldValue('finishDate', values.finishDate ?? new Date().toISOString());
    submitForm();
  }, [setFieldValue, submitForm, values.finishDate]);

  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <TimeRecordingModalView
      values={values}
      scheduleDetails={scheduleDetails.scheduleDetails}
      isLoading={isLoading}
      lotsUser={lotsUser}
      handleFieldValue={handleFieldValue}
      handleFinish={handleFinish}
      handleSubmit={handleSubmit}
    />
  );
};
