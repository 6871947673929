import LogoIcon from 'assets/logos/logo-icon';
import { Button } from 'logshare-ui-kit';
import { FC, ReactElement, useMemo } from 'react';
import { Breadcrumb } from 'src-new/components/application-page-header/components/header-page/components/breadcrumb/breadcrumb.component';
import { ScheduleBreadcrumbEnum } from 'src-new/pages/execution/pages/schedule/utils/breadcrumbs/breadcrumbs-pages.types';
import { BreadcrumbBranches } from 'src-new/pages/execution/pages/schedule/utils/breadcrumbs/breadcrumbs-pages.util';
import { IScheduleDetailsDomain } from '../../domains/schedule-details.domain';
import * as S from './schedule-details-header-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';

interface IScheduleDetailsHeaderViewProps {
  schedule: IScheduleDetailsDomain;
  isShippingCompany: boolean;
  checkSellerOrShipping: boolean;
  checkAllocationAccordions: boolean;
  schedulingTabName: boolean;
  statusCodes: {
    accepted: boolean;
    reofferCollectOrder: boolean;
  };
  handleAllocationAcceptOrDeny: (statusCode: string) => Promise<void>;
  handleReofferTrip: () => void;
  handleResendCollectOrderEmail: () => void;
  handleOpenModal: (scheduleId: number, modalType: ScheduleListModalType) => void;
}

export const ScheduleDetailsHeaderView: FC<IScheduleDetailsHeaderViewProps> = ({
  schedule,
  isShippingCompany,
  checkSellerOrShipping,
  checkAllocationAccordions,
  schedulingTabName,
  statusCodes,
  handleAllocationAcceptOrDeny,
  handleReofferTrip,
  handleResendCollectOrderEmail,
  handleOpenModal,
}) => {
  const renderButtonReofferCollectOrder = useMemo((): ReactElement | undefined => {
    if (schedulingTabName && checkAllocationAccordions) {
      return (
        <Button
          label="Reenviar Ordem de Coleta"
          color="red"
          onPress={handleResendCollectOrderEmail}
          variant="ghost"
          size="md"
        />
      );
    }
  }, [schedulingTabName, checkAllocationAccordions, handleResendCollectOrderEmail]);

  const renderButtonAccept = useMemo(() => {
    if (statusCodes.accepted && checkSellerOrShipping) {
      return (
        <S.ContainerButton>
          <Button
            label={'Aceitar'}
            color={'aqua'}
            onPress={() => handleAllocationAcceptOrDeny('AG10')}
            variant={'solid'}
            size={'md'}
          />
        </S.ContainerButton>
      );
    }
  }, [checkSellerOrShipping, handleAllocationAcceptOrDeny, statusCodes.accepted]);

  const renderButtonDenny = useMemo(() => {
    if (statusCodes.accepted && checkSellerOrShipping) {
      return (
        <S.ContainerButton>
          <Button
            label={'Recusar'}
            color={'red'}
            onPress={() => handleOpenModal(schedule.id, 'deny')}
            variant={'solid'}
            size={'md'}
          />
        </S.ContainerButton>
      );
    }
  }, [checkSellerOrShipping, handleOpenModal, schedule.id, statusCodes.accepted]);

  const renderButtonNewOffer = useMemo((): ReactElement | undefined => {
    if (schedule.statusCode === 'OT99' && schedule.operation === 'TRACKING' && !isShippingCompany) {
      return (
        <Button label={'Reenviar Oferta'} color={'blue'} onPress={handleReofferTrip} variant={'solid'} size={'md'} />
      );
    }
  }, [handleReofferTrip, isShippingCompany, schedule.operation, schedule.statusCode]);

  const renderHeaderPage = useMemo(() => {
    if (
      (statusCodes.accepted && checkSellerOrShipping) ||
      (schedule.statusCode === 'OT99' && schedule.operation === 'TRACKING' && !isShippingCompany) ||
      (schedulingTabName && checkAllocationAccordions && statusCodes.reofferCollectOrder)
    ) {
      return (
        <S.Wrapper>
          <Breadcrumb
            breadcrumb={BreadcrumbBranches({ scheduleId: schedule.id })[ScheduleBreadcrumbEnum.SCHEDULE_DETAILS]}
            separator={': :'}
            hasBackButton
          />

          <S.WrapperButtonsAndIcon>
            {renderButtonReofferCollectOrder}
            {renderButtonDenny}
            {renderButtonAccept}
            {renderButtonNewOffer}
            <S.WrapperLogo>
              <LogoIcon />
            </S.WrapperLogo>
          </S.WrapperButtonsAndIcon>
        </S.Wrapper>
      );
    }

    return (
      <HeaderPage
        breadcrumbList={BreadcrumbBranches({ scheduleId: schedule.id })[ScheduleBreadcrumbEnum.SCHEDULE_DETAILS]}
        hasBackButton
      />
    );
  }, [
    statusCodes,
    checkSellerOrShipping,
    schedule.statusCode,
    schedule.operation,
    schedule.id,
    isShippingCompany,
    schedulingTabName,
    checkAllocationAccordions,
    renderButtonReofferCollectOrder,
    renderButtonDenny,
    renderButtonAccept,
    renderButtonNewOffer,
  ]);

  return renderHeaderPage;
};
