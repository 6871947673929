import { useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tag } from 'logshare-ui-kit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import CachedIcon from '@mui/icons-material/Cached';

import { AllocationIcon, HeaderTitle, Info, InfoTitle, SectionContainer, Wrapper } from '../styled';
import { LoadingContainer, LoadingStyled } from '../../freight-update-form.styled';
import { IScheduleHistory } from '../../freight-update-form';

import { listVehicleContent } from './content-table/list/list';
import { headerVehicleContent } from './content-table/header';

import { offeredWeight } from 'utils-v2/converters/capacity';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import AllocationModalList from 'pages-v2/schedule/components/allocation-modal-list';
import { IUpdateVehicle } from 'pages/freight/services/update-vehicle/update-vehicle.service';
import { convertVehicleTypeEntityToLabel } from 'pages/freight/mappers/convert-vehicle-type-entity-to-label/convert-vehicle-type-entity-to-label.mapper';
import { convertVehicleCategoryTypeEntityToLabel } from 'pages/freight/mappers/convert-vehicle-category-type-entity-to-label/convert-vehicle-category-type-entity-to-label.mapper';
import { useVehicleDeleteHook } from 'pages/freight/hooks/use-vehicle-delete';
import { useUpdateVehicleHook } from 'pages/freight/hooks/use-update-vehicle';
import { useScheduleHistory } from 'pages/freight/hooks/use-schedule-history/use-schedule-history.hook';
import { vehicleListService } from 'pages/allocation/services/vehicle-list/vehicle-list.service';
import { IVehicleCreated } from 'domain/vehicle';
import { ScheduleDomain } from 'domain/schedule';
import { IListPage } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Button from 'components/button/button';
import TruckLoadingIcon from 'assets/loading-v4.gif';
import { EditDisabledIcon } from 'assets/icons/edit-disabled.icon';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
interface IProps {
  schedule?: ScheduleDomain;
  type?: 'ltl' | 'ftl' | 'stl';
  scheduleHistory?: IScheduleHistory[];
  onRefresh: React.Dispatch<React.SetStateAction<number>>;
}

interface IVehicleProps {
  page: number;
  search: string;
  limitPage: number;
  order: string;
  column: string;
}

const findAllInitialState = {} as IListPage<IVehicleCreated>;

const findInitialParams: IVehicleProps = {
  search: '',
  page: 1,
  limitPage: 7,
  order: 'DESC',
  column: 'vehicle.id',
} as IVehicleProps;

const FreightVehicleSectionView: React.FC<IProps> = ({ schedule, type, scheduleHistory, onRefresh }) => {
  const { id } = useParams();
  const { isAdmin } = useContext(UserContext);
  const [modalEdit, setModalEdit] = useState(false);
  const [vehicleScheduleHistory, setVehicleScheduleHistory] = useState<IScheduleHistory[]>(scheduleHistory ?? []);

  const [findAllVehicle, setFindAllVehicle] = useState<IListPage<IVehicleCreated>>(findAllInitialState);
  const [params, setParams] = useState<IVehicleProps>(findInitialParams);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const vehicleUpdate = useUpdateVehicleHook();
  const vehicleDelete = useVehicleDeleteHook();
  const getScheduleHistory = useScheduleHistory();

  useEffect(() => {
    if (!scheduleHistory) return;
    setVehicleScheduleHistory(scheduleHistory);
  }, [scheduleHistory]);

  const handleGetScheduleHistory = useCallback(async () => {
    try {
      const res = await getScheduleHistory(Number(id), 'VEICULO');
      setVehicleScheduleHistory(res);
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
  }, [getScheduleHistory, id, setVehicleScheduleHistory]);

  const handleUpdate = useCallback(
    async (values: IUpdateVehicle) => {
      setModalEdit(false);
      setIsLoading(true);
      vehicleUpdate(id ?? '0', {
        driverId: values.driverId,
        vehicleId: values.vehicleId,
        typeUpdate: values.typeUpdate,
      })
        .then(async () => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          onRefresh((prev) => prev + 1);
          await handleGetScheduleHistory();
        })
        .catch((err) => {
          showMessageFormatted({
            message: err,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleGetScheduleHistory, id, onRefresh, vehicleUpdate],
  );

  useEffect(() => {
    (async () => {
      try {
        const findAll = await vehicleListService({
          search: params.search,
          page: params.page,
          LIMIT_PAGE: params.limitPage,
          order: params.order,
          column: params.column,
          shippingCompanyId: schedule?.shippingCompany?.id,
        });

        setFindAllVehicle(findAll);
      } catch (err: any) {
        showMessageFormatted({
          message: '',
          error: err,
          type: 'error',
        });
      }
    })();
  }, [params, schedule?.shippingCompany?.id]);

  const handleDelete = useCallback(
    async (id: number) => {
      setIsLoading(true);
      vehicleDelete(id)
        .then(async () => {
          showMessageFormatted({
            message: 'Veículo Deletado',
            type: 'success',
          });
          onRefresh((prev) => prev + 1);
          await handleGetScheduleHistory();
        })
        .catch((err: any) => {
          showMessageFormatted({
            message: err.message ? err.message : err,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleGetScheduleHistory, onRefresh, vehicleDelete],
  );

  const renderVehicle = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingStyled>
          <LoadingContainer>
            <img alt="" style={{ width: 70, height: 70 }} src={TruckLoadingIcon} />
          </LoadingContainer>
        </LoadingStyled>
      );
    }

    if (vehicleScheduleHistory && vehicleScheduleHistory.length > 0) {
      return vehicleScheduleHistory.map((v, index) => {
        return (
          <SectionContainer
            key={v.vehicle_id}
            display="grid"
            columns={isAdmin ? '1fr 0.7fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr' : '2fr 1fr 0.8fr'}
          >
            <>
              <InfoTitle>
                Veículo Contratado
                <Info>{convertVehicleTypeEntityToLabel(v.vehicle_type || '-')}</Info>
              </InfoTitle>

              <InfoTitle>
                Categoria
                <Info>{convertVehicleCategoryTypeEntityToLabel(v.vehicle_category || '-')}</Info>
              </InfoTitle>

              <InfoTitle>
                Placa Principal
                <Info>{v.vehicle_main_board || '-'}</Info>
              </InfoTitle>

              <InfoTitle>
                Placa 2<Info>{v.vehicle_second_board || '-'}</Info>
              </InfoTitle>

              <InfoTitle>
                Placa 3<Info>{v.third_board || '-'}</Info>
              </InfoTitle>

              <InfoTitle>
                Carga Máxima
                <Info>{offeredWeight(v.capacity ?? 0)}</Info>
              </InfoTitle>

              <InfoTitle>
                Paletização
                <Info>{`${v.pallet_capacity ?? '-'} Paletes`}</Info>
              </InfoTitle>

              <InfoTitle>
                Cubagem
                <Info>{`${v.cubage_capacity ?? '-'} M³`}</Info>
              </InfoTitle>
            </>
            {isAdmin && (
              <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end' }}>
                {v.vehicle_id === schedule?.vehicle?.id && index === 0 ? (
                  <InfoTitle>
                    <Info>
                      <Tag label="Ativo" color="aqua" variant="ghost" display="flex" size="md" />
                    </Info>
                  </InfoTitle>
                ) : (
                  <>
                    <Button
                      title="Trocar"
                      callback={() =>
                        handleUpdate({
                          driverId: schedule?.driver?.id ?? 0,
                          vehicleId: v.vehicle_id,
                          typeUpdate: 'VEICULO',
                        })
                      }
                      bgColor="aqua"
                      icon={<CachedIcon />}
                      size="very-small"
                    />
                    <Button
                      title="Deletar"
                      callback={() => handleDelete(v.id)}
                      bgColor="pink"
                      icon={<DeleteIcon />}
                      size="very-small"
                    />
                  </>
                )}
              </div>
            )}
          </SectionContainer>
        );
      });
    }

    return (
      <SectionContainer
        key={schedule?.vehicle?.mainBoard}
        display="grid"
        columns={isAdmin ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr' : '2fr 1fr'}
      >
        <InfoTitle>
          Veículo Contratado
          <Info>{convertVehicleTypeEntityToLabel(schedule?.vehicle?.vehicleType || '-')}</Info>
        </InfoTitle>

        <InfoTitle>
          Categoria
          <Info>{convertVehicleCategoryTypeEntityToLabel(schedule?.vehicle?.vehicleCategory || '-')}</Info>
        </InfoTitle>

        <InfoTitle>
          Placa Principal
          <Info>{schedule?.vehicle?.mainBoard || '-'}</Info>
        </InfoTitle>

        <InfoTitle>
          Placa 2<Info>{schedule?.vehicle?.secondBoard || '-'}</Info>
        </InfoTitle>

        <InfoTitle>
          Placa 3<Info>{schedule?.vehicle?.thirdBoard || '-'}</Info>
        </InfoTitle>

        <InfoTitle>
          Carga Máxima
          <Info>{offeredWeight(schedule?.vehicle?.capacity ?? 0)}</Info>
        </InfoTitle>

        <InfoTitle>
          Paletização
          <Info>{`${schedule?.vehicle?.palletCapacity ?? '-'} Paletes`}</Info>
        </InfoTitle>

        <InfoTitle>
          Cubagem
          <Info>{`${schedule?.vehicle?.cubageCapacity ?? '-'} M³`}</Info>
        </InfoTitle>
      </SectionContainer>
    );
  }, [handleDelete, handleUpdate, isAdmin, isLoading, schedule, vehicleScheduleHistory]);

  const contentTable = useMemo((): Array<IContentTable> => {
    const listContent = findAllVehicle.list?.map((v) => {
      return listVehicleContent(v);
    });

    return listContent;
  }, [findAllVehicle]);

  const handleCurrentPage = useCallback((page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  }, []);

  const handleSearch = useCallback((search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  }, []);

  return (
    <>
      {modalEdit && (
        <AllocationModalList
          name="vehicle"
          title={'Veículo'}
          contentTable={contentTable}
          headerTable={headerVehicleContent()}
          changePage={(page) => handleCurrentPage(page)}
          pageInfo={findAllVehicle?.pageInfo}
          isLoading={false}
          handleChangeSearch={(e) => handleSearch(e)}
          handleSelectCurrentLine={(id) => {
            handleUpdate({
              driverId: schedule?.driver?.id ?? 0,
              vehicleId: Number(id),
              typeUpdate: 'VEICULO',
            });
          }}
          handleClose={() => setModalEdit(false)}
        />
      )}
      <Wrapper width={100}>
        <HeaderTitle display="flex" justify="space-between" isTraced>
          Dados do Veículo
          {type === 'ftl' ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <Button
                title="Editar"
                size="very-small"
                icon={<CreateIcon />}
                callback={() => setModalEdit(true)}
                bgColor="white"
              />
            </div>
          ) : (
            <AllocationIcon>
              <EditDisabledIcon />
            </AllocationIcon>
          )}
        </HeaderTitle>
        {renderVehicle}
      </Wrapper>
    </>
  );
};

export default React.memo(FreightVehicleSectionView);
