import { FormikValues } from 'formik';
import React, { useCallback, useState } from 'react';

import { FooterPage } from 'components-v2/common/footer-page';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';
import SectionDivider from 'components/section-divider/section-divider';
import TabsPermissions from 'components/tabs-permissions/tabs-permissions';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { INewUser } from 'domain/user';
import Theme from 'styles/theme';
import UserPermissions from './components/user-permissions/user-permissions';
import {
  FormikStyled,
  FormStyled,
  LineSectionStyled,
  LoginParceiroStyled,
  SectionStyled,
} from './user-register-form.styled';

type UserRegisterFormProps = {
  initialValues: INewUser;
  validationSchema: object;
  login: string | undefined;
  isLoading: boolean;
  handleGoback: () => void;
  handleRegister: (values: INewUser) => void;
};
const UserRegisterFormView: React.FC<UserRegisterFormProps> = ({
  initialValues,
  validationSchema,
  login = '',
  isLoading,
  handleGoback,
  handleRegister,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const renderPermission = useCallback(
    (tabName: string, values: FormikValues, setFieldValue: (name: string, value: boolean) => void) => {
      return (
        <UserPermissions
          subPermissions={values[tabName]}
          handleChecked={(e) => {
            values[tabName].map((item: { label: string }, idx: any) => {
              if (item.label === e.currentTarget.name) {
                setFieldValue(`${tabName}[${idx}].value`, e.currentTarget.checked);
              }
            });
          }}
        />
      );
    },
    [],
  );

  return (
    <FormikStyled
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const userRegisterValues: INewUser = {
          name: values.name,
          login: values.login,
          email: values.email,
          telephone: values.telephone,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          resetPassword: false,
          status: values.status,
          settings: values.settings,
          home: values.home,
          register: values.register,
          schedules: values.schedules,
          tracking: values.tracking,
          opportunities: values.opportunities,
          execution: values.execution,
          financial: values.financial,
          services: values.services,
          isMaster: false,
        };
        handleRegister(userRegisterValues);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <FormStyled>
          <SectionStyled name="Informações do Usuário">
            <LineSectionStyled columns="1fr 1fr 1fr">
              <InputField
                label="Nome *"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
                errorMessage={String(errors.name)}
              />

              <InputField
                label="E-mail *"
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                hasError={!!errors.email && !!touched.email}
                errorMessage={String(errors.email)}
                upperCase={false}
              />

              <InputFieldPhone
                label="Telefone *"
                id="telephone"
                name="telephone"
                type="text"
                onChange={handleChange}
                value={values.telephone}
                hasError={!!errors.telephone && !!touched.telephone}
                errorMessage={String(errors.telephone)}
              />
            </LineSectionStyled>

            <LineSectionStyled columns="1fr 1fr 1fr">
              <InputField
                label="Login *"
                id="login"
                name="login"
                type="text"
                onChange={handleChange}
                value={values.login}
                hasError={!!errors.login && !!touched.login}
                errorMessage={String(errors.login)}
                reactNode={<LoginParceiroStyled>{login.toUpperCase()}</LoginParceiroStyled>}
                reactNodePosition="right"
              />

              <InputField
                label="Senha *"
                id="password"
                name="password"
                type="password"
                upperCase={false}
                onChange={handleChange}
                value={values.password}
                passwordVisible={showPassword}
                changePasswordVisible={() => setShowPassword(!showPassword)}
                reactNode={<Theme.icons.eyePasswordIcon />}
                reactNodePosition="right"
                hasError={!!errors.password && !!touched.password}
                errorMessage={String(errors.password)}
                autoComplete="new-password"
              />

              <InputField
                label="Confirmação de Senha *"
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                onChange={handleChange}
                upperCase={false}
                value={values.passwordConfirmation}
                passwordVisible={showConfirmPassword}
                changePasswordVisible={() => setShowConfirmPassword(!showConfirmPassword)}
                reactNode={<Theme.icons.eyePasswordIcon />}
                reactNodePosition="right"
                hasError={!!errors.passwordConfirmation && !!touched.passwordConfirmation}
                errorMessage={String(errors.passwordConfirmation)}
              />
            </LineSectionStyled>
          </SectionStyled>

          <SectionDivider label="Permissões" />
          <SectionStyled name="permissions">
            <TabsPermissions
              tabsContent={[
                {
                  name: 'Cadastro',
                  content: renderPermission('register', values, setFieldValue),
                },
                {
                  name: 'Oportunidades',
                  content: renderPermission('opportunities', values, setFieldValue),
                },

                {
                  name: 'Programação',
                  content: renderPermission('schedules', values, setFieldValue),
                },

                {
                  name: 'Execução',
                  content: renderPermission('execution', values, setFieldValue),
                },

                {
                  name: 'Tracking',
                  content: renderPermission('tracking', values, setFieldValue),
                },

                {
                  name: 'Financeiro',
                  content: renderPermission('financial', values, setFieldValue),
                },
                {
                  name: 'Configurações',
                  content: renderPermission('settings', values, setFieldValue),
                },
              ]}
            />
          </SectionStyled>

          <FooterPage.Root>
            <FooterPage.RightContent>
              <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleSubmit} />
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
            </FooterPage.RightContent>
            <FooterPage.LeftContent>
              <ToggleCheckboxView
                id="status"
                name="status"
                label="Ativo"
                checked={values.status === 'ativo'}
                onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
              />
            </FooterPage.LeftContent>
          </FooterPage.Root>
        </FormStyled>
      )}
    </FormikStyled>
  );
};

export default UserRegisterFormView;
