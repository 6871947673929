import { useCallback, useContext } from 'react';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { useGetGroupChatScheduleConversationsChat } from 'src-new/hooks/chat/use-get-group-chat-schedule-conversations-chat/use-get-group-chat-schedule-conversations-chat.hook';
import { IParticipantConversation } from 'src-new/hooks/chat/use-generate-participants-conversations-chat/use-generate-participants-conversations-chat.types';
import { IUserChat } from 'src-new/domains/user-chat.domain';
import { UserContext } from 'state/user-context';

export const useGenerateParticipantsConversationsChat = () => {
  const { chatEligibleUsers } = useContext(ChatContext);
  const { chatActions } = useContext(ChatButtonContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const { user } = useContext(UserContext);
  const getGroupChatScheduleConversations = useGetGroupChatScheduleConversationsChat();

  const limitedListing = useCallback((list: Array<IUserChat>): Array<IUserChat> => {
    return list.length <= 298 ? list.slice() : list.slice(0, 298);
  }, []);

  return useCallback((): IParticipantConversation => {
    if (scheduleDetails.scheduleDetails.id !== 0) {
      const participantsConversation: IParticipantConversation = {
        conversationId: chatActions.chatId,
        participants: [],
      };

      chatEligibleUsers.users.forEach((user) => {
        const chatList = getGroupChatScheduleConversations(scheduleDetails.scheduleDetails, user);
        const environment = participantsConversation.conversationId.split('-')[0];

        if (chatList.find((chat) => `${environment}-${chat.chatId}` === participantsConversation.conversationId)) {
          participantsConversation.participants.push({
            id: user.id.toString(),
            name: user.name,
            role: 'default',
          });
        }
      });

      participantsConversation.participants = limitedListing(participantsConversation.participants);
      participantsConversation.participants = participantsConversation.participants.filter(
        (participant) => participant.id !== user?.id.toString(),
      );

      return participantsConversation;
    } else {
      const participantsConversation: IParticipantConversation = {
        conversationId: chatActions.chatId,
        participants: [],
      };

      chatEligibleUsers.users.forEach((user) => {
        participantsConversation.participants.push({
          id: user.id.toString(),
          name: user.name,
          role: 'default',
        });
      });

      participantsConversation.participants = limitedListing(participantsConversation.participants);
      participantsConversation.participants = participantsConversation.participants.filter(
        (participant) => participant.id !== user?.id.toString(),
      );

      return participantsConversation;
    }
  }, [
    chatActions.chatId,
    chatEligibleUsers,
    getGroupChatScheduleConversations,
    limitedListing,
    scheduleDetails.scheduleDetails,
    user?.id,
  ]);
};
