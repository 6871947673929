import { Moment } from 'moment';

import API from 'utils/API/API';
import { PageInfoResponse } from 'domain-v2/inputs/page-info';
import { BiddingsList } from 'domain-v2/bidding/list';

export interface BiddingFilter {
  page: number;
  limit: number;
  search: string;
  column: string;
  order: string;

  createdAt: Moment | null;
  collectDate: Moment | null;
  status: string;
  vehicle: string;
  category: string;
  originCity: string;
  destinationCity: string;
}

export const getBiddingsList = async (filters: BiddingFilter): Promise<PageInfoResponse<BiddingsList>> => {
  const { data } = await API.get<PageInfoResponse<BiddingsList>>('v3/bidding', {
    params: filters,
  });

  return data;
};
