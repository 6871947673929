import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperSection = styled.section`
  width: 100vw;
  padding: 1.5rem;
  @media (min-width: 600px) {
    max-width: 40%;
  }
`;

export const WrapperMenu = styled.div`
  ${({ theme }) => css`
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: ${theme.sidebar.colors.blue};
  `}
`;

export const ContainerCircleMenu = styled.div`
  width: 65px;
  height: 64px;
  background-color: white;
  border: solid 3px #e9e9e9;
  border-radius: 300px;
  margin: 13px;
`;
export const LogshareImage = styled.img`
  height: 58px;
  width: 58px;
  border-radius: 50%;
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
`;

export const WrapperInfo = styled.div`
  padding-bottom: 0.62rem;
`;

export const WrapperInfoTitle = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const InfoTitle = styled.p`
  font-size: 12px;
  color: #000;
  font-weight: 700;
`;

export const Info = styled.p`
  font-size: 12px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
