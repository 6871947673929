import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getPalletByIdService } from '../../services/get-pallet-by-id/get-pallet-by-id.service';
import { converterPalletResponseToDomain } from './mappers/convert-pallet-response-to-domain/convert-pallet-response-to-domain.mapper';
import { FinancialContext } from '../../contexts/financial/financial.context';

export const useGetPalletById = () => {
  const { palletForm } = useContext(FinancialContext);
  const { setGetPalletDetailsRequest, setGetPalletDetailsSuccess, setGetPalletDetailsError } =
    palletForm.getPalletDetails;

  const { id } = useParams<{ id: string }>();

  return useCallback(async () => {
    try {
      setGetPalletDetailsRequest();

      const palletDetails = await getPalletByIdService(Number(id));

      setGetPalletDetailsSuccess(converterPalletResponseToDomain(palletDetails));
    } catch (error) {
      setGetPalletDetailsError();
      showMessageFormatted({
        message: 'Erro ao obter detalhes do palete',
        type: 'error',
      });
    }
  }, [id, setGetPalletDetailsError, setGetPalletDetailsRequest, setGetPalletDetailsSuccess]);
};
