import { FC, Fragment, useMemo } from 'react';
import { CheckCircle2, ChevronDown, ChevronUp } from 'lucide-react';
import { IAccordionView } from './accordion.types';
import * as S from './accordion-view.styled';

export const AccordionView: FC<IAccordionView> = ({ contents, accordionState, openAccordion, closeAccordion }) => {
  const renderAccordions = useMemo(() => {
    return contents.map(({ title, content, button, checked, buttonOpen = true }, index) => {
      return (
        <S.Wrapper key={index}>
          <S.Summary>
            <S.SummaryTitle>{title}</S.SummaryTitle>

            <S.WrapperIcons>
              {button && button}
              {checked && <CheckCircle2 size={24} color="#119895" />}

              {buttonOpen && (
                <S.Expander>
                  {accordionState.index !== index ? (
                    <ChevronDown size={24} onClick={() => openAccordion(index)} />
                  ) : (
                    <ChevronUp size={24} onClick={() => closeAccordion(index)} />
                  )}
                </S.Expander>
              )}
            </S.WrapperIcons>
          </S.Summary>

          <S.Details
            isOpen={accordionState.isOpen && accordionState.index === index}
            aria-expanded={accordionState.index === index}
          >
            {content}
          </S.Details>
        </S.Wrapper>
      );
    });
  }, [accordionState.index, accordionState.isOpen, closeAccordion, contents, openAccordion]);

  return <Fragment>{renderAccordions}</Fragment>;
};
