/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useDriverList } from '../../hooks/use-driver-list/use-driver-list.hook';
import { DriversContext } from '../../contexts/table-states/drivers.context';
import { IDriverStatus } from '../../../../domain/driver';

import DriverListBodyView from './driver-list-body-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile, downloadTemplate } from 'utils/download-file/download-file';
import {
  convertActiveTabToStatusDomainMapper,
} from 'pages/driver/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import { useDriverUpload } from 'pages/driver/hooks/use-driver-upload/use-driver-upload.hook';
import { useReportExcelHook } from 'pages/driver/hooks/use-driver-report-excel/use-driver-report-excel.hook';
import { useDeleteDriverHook } from 'pages/driver/hooks/use-driver-delete/use-driver-delete.hook';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';
import ModalUpload from 'components/modal-upload/modal-upload';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const OptionsOrder: Array<ReportOption> = [
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Registro mais Antigo', value: 'asc' },
];

const DriverListBody: React.FC = (): JSX.Element => {
  const { drivers, driversIsLoading, driversPageInfo } = useContext(DriversContext);
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Ativos');
  const [orderBy, setOrderBy] = useState<string>('driver.name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });
  const driverListHook = useDriverList();
  const driverUploadHook = useDriverUpload();
  const navigate = useNavigate();
  const GenerateExcelHook = useReportExcelHook();
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);
  const deleteDriverHook = useDeleteDriverHook();

  const goToCreateDriver = () => {
    navigate('/motoristas/novo');
  };

  const goToUpdatePage = useCallback(
    (driverId: string) => {
      navigate(`/motoristas/atualizar/${driverId}`);
    },
    [navigate],
  );

  const handleRemoveDriver = async (ids: number[], exclude = false) => {
    if (ids.length === 0) {
      showToast({
        message: 'Escolha pelo menos um motorista para excluir',
        type: 'error',
      });

      return;
    }

    if (exclude) {
      await deleteDriverHook(ids);
      setModalConfirmDelete({ description: '', open: false });
      driverListHook({
        searchValue: searchInput,
        currentPage,
        status: renderStatusByTabName,
        order: sortDirection,
        column: orderBy,
      });
      setIdsToRemove([]);
    } else {
      const description = (
        <>
          <p>Isso excluirá o motorista da listagem.</p>
          <p style={{ marginTop: 5 }}>Tem certeza?</p>
        </>
      );
      setModalConfirmDelete({ description, open: true });
    }
  };

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName),
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'MOTORISTA');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  const renderStatusByTabName = useMemo((): IDriverStatus | undefined => {
    if (activeTabName === 'Todos') {
      return undefined;
    }

    if (activeTabName === 'Ativos') {
      return 'ativo';
    }

    if (activeTabName === 'Inativos') {
      return 'inativo';
    }

    if (activeTabName === 'Excluídos') {
      return 'deletado';
    }

    return undefined;
  }, [activeTabName]);

  const handleOrderList = useCallback(
    async (optionOrder: ReportOption) => {
      if (optionOrder.value === 'name') {
        driverListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'name',
        });
      }
      if (optionOrder.value === 'asc') {
        driverListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'id',
        });
      }
      showToast({
        type: 'loading',
        message: 'Atualizando listagem',
      });
    },
    [currentPage, driverListHook, renderStatusByTabName, searchInput],
  );

  useEffect(() => {
    driverListHook({
      searchValue: searchInput,
      currentPage: searchInput ? 1 : currentPage,
      status: renderStatusByTabName,
      order: sortDirection,
      column: orderBy,
    });
  }, [currentPage, renderStatusByTabName, searchInput, driverListHook, orderBy, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Motoristas.xlsx');
  }, []);

  const handleOpenUploadXlsx = async () => {
    setShowModal(true);
  };

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await driverUploadHook(formData);
        if (data.uploadDate === 'error') {
          showToast({
            message: `Erro ao importar dados ${data.error.message}`,
            type: 'error',
          });
        } else {
          showToast({
            message: `Importação de ${data.total} arquivo(s) realizada com sucesso`,
            type: 'success',
          });
          driverListHook({
            searchValue: '',
            currentPage: 1,
            status: 'ativo',
            order: 'desc',
            column: 'id',
          });
          setShowModal(false);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    [driverUploadHook, setShowModal],
  );

  const handleOrderBy = (order: string) => {
    const orderMap: Record<string, string> = {
      ID: 'driver.id',
      Parceiro: 'company.tradeName',
      Nome: 'driver.name',
      Categoria: 'driver.cnhCategory',
      Cidade: 'driver.city',
      Status: 'driver.status',
    };

    const newOrderBy = orderMap[order];
    setOrderBy(newOrderBy || '');
  };

  return (
    <>
      {showModal && (
        <ModalUpload
          title="Cadastro de Motoristas"
          description="Selecione o arquivo"
          handleCancel={showModalConfirmation}
          handleSuccess={handleSuccess}
          handleDownload={handleDownload}
        />
      )}
      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Motorista(s)"
          secondTitle={modalConfirmDelete.description}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveDriver(idsToRemove, true)}
          size="small"
          type="delete"
          description={undefined}
        />
      )}
      <DriverListBodyView
        drivers={drivers}
        hasLoading={driversIsLoading}
        goToCreateDriver={goToCreateDriver}
        goToUpdatePage={goToUpdatePage}
        setCurrentPage={setCurrentPage}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        handleOpenUploadXlsx={handleOpenUploadXlsx}
        driversPageInfo={driversPageInfo}
        onHandleExport={handleGenerateExcel}
        options={Options}
        handleOrderList={handleOrderList}
        OptionsOrder={OptionsOrder}
        handleRemoveDriver={handleRemoveDriver}
        idsToRemove={idsToRemove}
        setIdsToRemove={setIdsToRemove}
        clickOrderBy={(e: string) => handleOrderBy(e)}
        clickSortDirection={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
      />
    </>
  );
};

export { DriverListBody };
