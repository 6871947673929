import { FC, useMemo } from 'react';
import { PurchaseOfferModalMatchScoreLabelView } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-score/purchase-offer-modal-match-score-label/purchase-offer-modal-match-score-label-view.component';

interface IPurchaseOfferModalMatchScoreLabelProps {
  sumScore: number;
}

export const PurchaseOfferModalMatchScoreLabel: FC<IPurchaseOfferModalMatchScoreLabelProps> = ({ sumScore }) => {
  const scoreMatchLabel = useMemo(() => {
    if (sumScore === 3) return 'ALTO';

    if (sumScore < 3 && sumScore > 1) return 'MODERADO';

    return 'BAIXO';
  }, [sumScore]);

  return <PurchaseOfferModalMatchScoreLabelView label={scoreMatchLabel} />;
};
