import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IWeighingFormRegisterDomain } from '../../../../domains/weighing-register.domain';
import { WeighingRegisterFormMountView } from './weighing-register-form-mount-view.component';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const WeighingRegisterFormMount: FC = () => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<IWeighingFormRegisterDomain>();

  const [hasError, setHasError] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleGoBackPage = useCallback(() => navigate(-1), [navigate]);

  const handleValidate = useCallback(() => {
    if (
      !values.operation ||
      !values.scheduledDateTime ||
      !values.shippingCompany.id ||
      !values.driverId ||
      !values.vehicleId ||
      !values.ticketStatus
    ) {
      setHasError(true);

      showMessageFormatted({
        message: 'Preencha todos os campos obrigatórios',
        type: 'error',
      });
    } else {
      handleSubmit();
    }
  }, [
    handleSubmit,
    values.driverId,
    values.operation,
    values.scheduledDateTime,
    values.shippingCompany.id,
    values.ticketStatus,
    values.vehicleId,
  ]);

  return (
    <WeighingRegisterFormMountView
      values={values}
      hasError={hasError}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleGoBackPage={handleGoBackPage}
      handleValidate={handleValidate}
    />
  );
};
