import styled, { css } from 'styled-components';

export const LoginContainerStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100vh;

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${theme.colors.blue};
    }
  `}
`;

export const LogoContainerStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 0 0 60%;
    justify-content: center;
    align-items: center;
    max-width: 60%;
    background-color: ${theme.colors.blue};

    svg {
      @media (max-width: 600px) {
        position: absolute;
        top: 5px;
        width: 200px;
        height: 100px;
      }
    }
  `}
`;
export const LogoBranco = styled.svg``;

export const ContentStyled = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    flex: 0 0 40%;

    @media (max-width: 600px) {
      position: absolute;
      height: 280px;
      max-width: 90%;
      flex: 0 0 90%;
      background-color: ${theme.colors.white};
      padding: 30px;
      border-radius: 25px;
    }
  `}
`;
