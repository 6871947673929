import React, { ReactNode } from 'react';

import ContentBoxView from './content-box-view';

interface IContentBoxProps {
  title: string;
  subtitle?: string;
  amount: number;
  percentValue?: string;
  prefix?: 'R$ ' | 'US$';
  sufix?: ' VOL' | ' KG' | ' TON' | '%';
  color: 'white' | 'lightgray';
  decimals: 2 | 0;
  isLarge?: boolean;
  isSmall?: boolean;
  icon?: ReactNode;
}

const ContentBox: React.FC<IContentBoxProps> = ({
  title,
  subtitle,
  amount,
  percentValue,
  prefix,
  sufix,
  color,
  decimals,
  isLarge,
  isSmall,
  icon,
}) => {
  return (
    <ContentBoxView
      color={color}
      title={title}
      subtitle={subtitle}
      amount={amount}
      percentValue={percentValue}
      prefix={prefix}
      sufix={sufix}
      decimals={decimals}
      isLarge={isLarge}
      isSmall={isSmall}
      icon={icon}
    />
  );
};

export default ContentBox;
