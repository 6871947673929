import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { WeighingTottenDetailsFormView } from './weighing-totten-details-form-view.component';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';
import { useGetWeighingTottenDetails } from '../../hooks/use-get-weighing-totten-details/use-get-weighing-totten-details.hook';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const WeighingTottenDetailsForm: FC = () => {
  const [confirmationPage, setConfirmationPage] = useState<boolean>(false);

  const { weighingTottensDetails } = useContext(WeighingTottensContext);
  const { weighingTottensDetailsFiltersProps } = weighingTottensDetails;

  const getWeighingTottenDetailsHook = useGetWeighingTottenDetails(); 

  const handleSubmit = useCallback(
    (values: IWeighingTottenDetailsForm) => {
      if(!values.collectOrder) {
        showMessageFormatted({
          message: 'Campo obrigatório',
          type: 'error',
        });
        return
      }
      const { filters, setFilters } = weighingTottensDetailsFiltersProps;
      setFilters({ ...filters, sm: values.collectOrder, driverCpf: values.driverCpf, vehicleMainPlate: values.vehicleMainPlate });

      getWeighingTottenDetailsHook(values);

      setConfirmationPage(true);
    },
    [getWeighingTottenDetailsHook, weighingTottensDetailsFiltersProps],
  );

  const isLoading = useMemo(
    (): boolean => weighingTottensDetails.statusRequest === 'PROCESSING',
    [weighingTottensDetails.statusRequest],
  );

  return (
    <WeighingTottenDetailsFormView
      confirmationPage={confirmationPage}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};
