import styled, { css } from 'styled-components';

export const FilterBackhaulContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 10px;
    align-items: center;
  `}
`;

export const ContainerRow = styled.div`
  width: 100%;
  margin-bottom: 2px;
`;
