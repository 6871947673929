import { FC } from 'react';

export const AlertBiddingWin: FC = () => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29 2H24V1C24 0.734784 23.8946 0.48043 23.7071 0.292893C23.5196 0.105357 23.2652 0 23 0L7 0C6.73478 0 6.48043 0.105357 6.29289 0.292893C6.10536 0.48043 6 0.734784 6 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3V7C0.00247181 8.69019 0.6164 10.3224 1.72842 11.5953C2.84044 12.8681 4.37545 13.6956 6.05 13.925C6.24414 15.7946 7.01879 17.5566 8.26525 18.9635C9.5117 20.3705 11.1674 21.3519 13 21.77V26H8.085C8.00818 26 7.93211 26.0151 7.86113 26.0445C7.79015 26.0739 7.72567 26.117 7.67134 26.1713C7.61702 26.2257 7.57393 26.2902 7.54453 26.3611C7.51513 26.4321 7.5 26.5082 7.5 26.585V31.415C7.5 31.4918 7.51513 31.5679 7.54453 31.6389C7.57393 31.7098 7.61702 31.7743 7.67134 31.8287C7.72567 31.883 7.79015 31.9261 7.86113 31.9555C7.93211 31.9849 8.00818 32 8.085 32H21.915C22.0702 32 22.2189 31.9384 22.3287 31.8287C22.4384 31.7189 22.5 31.5702 22.5 31.415V26.585C22.5 26.4298 22.4384 26.2811 22.3287 26.1713C22.2189 26.0616 22.0702 26 21.915 26H17V21.77C18.8326 21.3519 20.4883 20.3705 21.7348 18.9635C22.9812 17.5566 23.7559 15.7946 23.95 13.925C25.6246 13.6956 27.1596 12.8681 28.2716 11.5953C29.3836 10.3224 29.9975 8.69019 30 7V3C30 2.73478 29.8946 2.48043 29.7071 2.29289C29.5196 2.10536 29.2652 2 29 2ZM6 11.9C4.87064 11.6695 3.8556 11.0558 3.12669 10.1629C2.39778 9.26999 1.99976 8.15265 2 7V4H6V11.9ZM9.5 10.5C9.5 10.6326 9.44732 10.7598 9.35355 10.8536C9.25979 10.9473 9.13261 11 9 11C8.86739 11 8.74021 10.9473 8.64645 10.8536C8.55268 10.7598 8.5 10.6326 8.5 10.5V5.5C8.5 5.36739 8.55268 5.24021 8.64645 5.14645C8.74021 5.05268 8.86739 5 9 5C9.13261 5 9.25979 5.05268 9.35355 5.14645C9.44732 5.24021 9.5 5.36739 9.5 5.5V10.5ZM28 7C28.0002 8.15265 27.6022 9.26999 26.8733 10.1629C26.1444 11.0558 25.1294 11.6695 24 11.9V4H28V7Z"
        fill="#5D5D5D"
      />
    </svg>
  );
};
