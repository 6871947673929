import { createContext, useMemo, useReducer } from 'react';
import { weighingReducer } from './reducers/weighing.reducer';
import { weighingInitialState } from './weighing.constants';
import { IWeighingContextProps } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.types';
import { IWeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing.types';
import { IWeighingList } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { useWeighingOfferListContextValues } from 'src-new/pages/execution/pages/weighing/contexts/weighing/context-values/weighing-list/weighing-list-values.context';
import { useWeighingDetailsContextValues } from 'src-new/pages/execution/pages/weighing/contexts/weighing/context-values/weighing-details/weighing-details-values.context';
import { IWeighingDetails } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-details.types';

export const WeighingContext = createContext<IWeighingContext>(weighingInitialState);

export const WeighingContextProvider = ({ children }: IWeighingContextProps) => {
  const [state] = useReducer(weighingReducer, weighingInitialState);

  const weighingList: IWeighingList = useWeighingOfferListContextValues();
  const weighingDetails: IWeighingDetails = useWeighingDetailsContextValues();

  const value: IWeighingContext = useMemo(
    (): IWeighingContext => ({
      ...state,
      weighingList,
      weighingDetails,
    }),
    [state, weighingDetails, weighingList],
  );

  return <WeighingContext.Provider value={value}>{children}</WeighingContext.Provider>;
};
