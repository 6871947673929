import React, { Fragment, ReactElement, useMemo } from 'react';
import * as S from './company-information-freetime-view.styled';
import { ICompany, IRetention } from 'domain/company';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Select, { SelectPropsOptions } from 'components/select/select';
import { FormikErrors, FormikTouched } from 'formik';
import InputFieldTime from 'components/input-time/input-time';
import { InputCurrency } from 'components/input-currency/input-currency';
import Button from 'components/button/button';

interface ICompanyInformationFreetimeViewProps {
  retentionFines?: Array<IRetention>;
  retentionVehicleTypeOptions: Array<SelectPropsOptions>;
  errors: FormikErrors<ICompany>;
  touched: FormikTouched<ICompany>;
  disableForm: boolean;
  handleChange: (field: string, value: string, index: number) => void;
  handleAddRow: () => void;
  handleRemoveRow: (index: number) => void;
}
export const CompanyInformationFreetimeView: React.FC<ICompanyInformationFreetimeViewProps> = ({
  retentionFines,
  retentionVehicleTypeOptions,
  errors,
  touched,
  disableForm,
  handleChange,
  handleAddRow,
  handleRemoveRow,
}) => {
  const renderRetentionFines = useMemo((): Array<ReactElement> | undefined => {
    return retentionFines?.map((value: IRetention, index: number) => {
      return (
        <S.LineSection key={value.id} columns="1fr 1fr 1fr 1fr 0.1fr">
          <Select
            label="Tipo de Veículo"
            id={`retentionFines[${index}].vehicleType`}
            name={`retentionFines[${index}].vehicleType`}
            value={value.vehicleType}
            setFieldValue={(_, value) => handleChange('vehicleType', value, index)}
            hasError={!!errors.status && !!touched.status}
            errorMessage={String(errors.status)}
            options={retentionVehicleTypeOptions}
            isClearable
            disabled={disableForm}
          />

          <InputFieldTime
            label="Free Time (em horas)"
            id={`retentionFines[${index}].freeTime`}
            name={`retentionFines[${index}].freeTime`}
            type="text"
            value={value.freeTime}
            onChange={(e) => handleChange('freeTime', e.target.value, index)}
            justTime
            disabled={disableForm}
          />

          <InputCurrency
            label="Valor da Hora Parado"
            id={`retentionFines[${index}].hourlyFine`}
            name={`retentionFines[${index}].hourlyFine`}
            prefix={'R$ '}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={value.hourlyFine}
            onValueChange={(value) => handleChange('hourlyFine', value ?? '', index)}
            disabled={disableForm}
          />

          <InputCurrency
            label="Valor da Diária Parado"
            id={`retentionFines[${index}].dailyFine`}
            name={`retentionFines[${index}].dailyFine`}
            prefix={'R$ '}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            value={value.dailyFine}
            onValueChange={(value) => handleChange('dailyFine', value ?? '', index)}
            disabled={disableForm}
          />

          <S.DeleteButtonWrapper>
            <Button
              title="Deletar"
              bgColor="newRed"
              callback={() => handleRemoveRow(index)}
              icon={<DeleteIcon />}
              size="very-small"
              disabled={disableForm}
            />
          </S.DeleteButtonWrapper>
        </S.LineSection>
      );
    });
  }, [
    disableForm,
    errors.status,
    handleChange,
    handleRemoveRow,
    retentionFines,
    retentionVehicleTypeOptions,
    touched.status,
  ]);

  return (
    <Fragment>
      {renderRetentionFines}

      <S.AddButtonWrapper>
        <Button
          title="Adicionar"
          bgColor="blue"
          callback={handleAddRow}
          icon={<AddIcon />}
          size="very-small"
          disabled={disableForm}
        />
      </S.AddButtonWrapper>
    </Fragment>
  );
};
