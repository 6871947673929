import { FleetType } from 'domain-v2/inputs/fleet';

export const optionsOperationType: Array<{
  label: string;
  value: string;
}> = [
  { label: 'INBOUND', value: 'inbound' },
  { label: 'OUTBOUND', value: 'outbound' },
  { label: 'TRANSFERÊNCIA', value: 'transferencia' },
];

export const optionsFleetType: Array<{
  label: string;
  value: FleetType;
}> = [
  { label: 'FROTA DEDICADA', value: 'frota dedicada' },
  { label: 'FROTA TERCEIRIZADA', value: 'frota terceirizada' },
];

export const optionsHelper: Array<{
  label: string;
  value: string;
}> = [
  { value: 'sem_ajuda', label: 'SEM AJUDANTE' },
  { value: 'carregamento', label: 'NO CARREGAMENTO' },
  { value: 'descarga', label: 'NA DESCARGA' },
  { value: 'ambos', label: 'AMBOS' },
];

export const optionsPallet: Array<{
  label: string;
  value: string;
}> = [
  { value: 'true', label: 'SIM' },
  { value: 'false', label: 'NÃO' },
];
