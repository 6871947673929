import { ReactElement } from 'react';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { EssencialButtonView } from './essencial-button-view.component';

interface IEssencialButtonProps {
  label: string;
  size: 'small' | 'medium' | 'large' | 'full';
  color: ThemeColorsV2Type;
  icon?: ReactElement;
  disabled?: boolean;
  onClick: () => void;
}
export const EssencialButton: React.FC<IEssencialButtonProps> = (props) => {
  return <EssencialButtonView {...props} />;
};
