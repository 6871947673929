import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const ContentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const Section = styled.section`
  display: grid;
  grid-gap: 5px;
  margin-bottom: 20px;
  flex-direction: column;
`;

type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
  `}
`;

export const ContainerInputDates = styled.div`
  margin-top: 6px;
`;

export const ContainerButtons = styled.div`
  margin-top: 21px;
`;
