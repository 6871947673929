import { FileContent, useFilePicker } from 'use-file-picker';
import React, { ReactNode, useEffect, useState } from 'react';

import { ImageViewStyled, PDFViewStyled } from './input-file-upload.styled';
import InputUploadImageView from './input-file-upload-view';

import ModalImage from 'components/modal-image/modal-image';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

type InputUploadImageProps = {
  urlFile?: string;
  handleSelectedImage: (file: File, dataUrl?: FileContent) => void;
  deleteImage?: () => void;
};

const InputUploadFile: React.FC<InputUploadImageProps> = ({ urlFile, handleSelectedImage, deleteImage }) => {
  const [dataUrlImage, setDataUrlImage] = useState('');
  const [deleteFile, setDeleteFile] = useState(false);
  const [openFileSelector, { loading, plainFiles, filesContent }] = useFilePicker({
    multiple: false,
  });

  const [modalImage, setModalImage] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const [modalDeleteImage, setModalDeleteImage] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  useEffect(() => {
    setDataUrlImage(urlFile || '');
    if (loading) {
      const image = filesContent[0]?.content;
      setDataUrlImage(image);
      handleSelectedImage(plainFiles[0], filesContent[0]);
    }
  }, [filesContent, handleSelectedImage, loading, plainFiles, urlFile]);

  useEffect(() => {
    if (deleteFile) {
      setDataUrlImage('');
      deleteImage && deleteImage();
      setDeleteFile(false);
    }
  }, [deleteFile, deleteImage]);

  const goToImageView = (pdf: boolean) => {
    let description;
    pdf ? (description = <PDFViewStyled src={dataUrlImage} />) : (description = <ImageViewStyled src={dataUrlImage} />);
    setModalImage({ open: true, description: description });
  };

  const goToImageDelete = () => {
    const description = (
      <>
        <p>Isso excluirá o registro do agendamento.</p>
        <p style={{ marginTop: 5 }}>Tem certeza?</p>
      </>
    );
    setModalDeleteImage({ open: true, description: description });
  };

  return (
    <>
      {deleteImage && (
        <>
          {modalDeleteImage.open && (
            <ModalConfirmation
              title="Excluir Registro(s)"
              secondTitle={modalDeleteImage.description}
              handleCancel={() => setModalDeleteImage((old) => ({ ...old, open: false }))}
              onConfirmation={() => {
                setDeleteFile(true), setModalDeleteImage((old) => ({ ...old, open: false }));
              }}
              size="small"
              type="delete"
              description={undefined}
            />
          )}
          {modalImage.open && (
            <ModalImage
              title="Visualizar Registro"
              description={modalImage.description}
              handleCancel={() => setModalImage((old) => ({ ...old, open: false }))}
            />
          )}
        </>
      )}
      <InputUploadImageView
        goToImageView={goToImageView}
        handleClick={openFileSelector}
        dataUrlImage={dataUrlImage}
        handleDelete={deleteImage && goToImageDelete}
      />
    </>
  );
};

export default InputUploadFile;
