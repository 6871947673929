import { ChangeEvent, FC, useMemo } from 'react';
import { Button } from 'logshare-ui-kit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BackupIcon from '@mui/icons-material/Backup';
import * as S from './search-view.styled';
import { SearchFilterIcon } from 'assets/icons/search-filter.icon';
import { FilterCancelIcon } from 'assets/icons/filter-cancel.icon';
import TooltipComponent from 'components/Tooltip';

interface ISearchViewProps {
  value: string;
  placeholder: string;
  filters?: Array<string | number>;
  disabledExportButton?: boolean;
  disabledImportButton?: boolean;
  filterCount?: string | null;
  disabledInputSearch?: boolean;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  onClearValue: (index: number) => void;
  handleFilterButton?: () => void;
  handleCustomFilterButton?: () => void;
  handleExportButton?: () => void;
  handleImportButton?: () => void;
}

export const SearchView: FC<ISearchViewProps> = ({
  value,
  placeholder,
  filters,
  disabledExportButton,
  disabledImportButton,
  filterCount,
  onChangeValue,
  onClearValue,
  handleFilterButton,
  handleCustomFilterButton,
  handleExportButton,
  handleImportButton,
  disabledInputSearch,
}) => {
  const renderFilter = useMemo(() => {
    return filters?.map((filter, index) => {
      if (!filter || Array.isArray(filter)) return;

      return (
        <S.FilterSelected key={index}>
          <S.FilterTitle>{filter}</S.FilterTitle>

          <S.CleanFilter onClick={() => onClearValue(index)}>
            <FilterCancelIcon />
          </S.CleanFilter>
        </S.FilterSelected>
      );
    });
  }, [filters, onClearValue]);

  const renderButtons = useMemo(() => {
    return (
      <>
        {!!handleFilterButton && (
          <TooltipComponent
            title="Filtrar"
            location="bottom"
            icon={
              <S.ContainerFilterCount>
                {!!filterCount && <S.FilterCount>{filterCount}</S.FilterCount>}

                <Button
                  label=""
                  color={filterCount ? 'aqua' : 'white'}
                  size="x-sm"
                  variant="ghost"
                  onPress={handleFilterButton}
                  LeftIcon={<FilterAltIcon sx={{ width: 22 }} />}
                />
              </S.ContainerFilterCount>
            }
          />
        )}

        {!!handleCustomFilterButton && (
          <TooltipComponent
            title="Filtros Customizados"
            location="bottom"
            icon={
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                onPress={handleCustomFilterButton}
                LeftIcon={<BookmarkAddIcon sx={{ width: 22 }} />}
              />
            }
          />
        )}

        {!!handleImportButton && (
          <TooltipComponent
            title="Importar"
            location="bottom"
            icon={
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                onPress={handleImportButton}
                LeftIcon={<BackupIcon sx={{ width: 22 }} />}
                disabled={disabledImportButton}
              />
            }
          />
        )}

        {!!handleExportButton && (
          <TooltipComponent
            title="Exportar"
            location="bottom"
            icon={
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                onPress={handleExportButton}
                LeftIcon={<CloudDownloadIcon sx={{ width: 22 }} />}
                disabled={disabledExportButton}
              />
            }
          />
        )}
      </>
    );
  }, [
    handleFilterButton,
    filterCount,
    handleCustomFilterButton,
    handleImportButton,
    disabledImportButton,
    handleExportButton,
    disabledExportButton,
  ]);

  return (
    <S.WrapperSearch>
      <S.ContainerSearch>
        <S.ContainerInput disabledSearch={disabledInputSearch}>
          <SearchFilterIcon />
          <S.Input
            disabled={disabledInputSearch}
            disabledSearch={disabledInputSearch}
            value={value}
            placeholder={placeholder}
            onChange={onChangeValue}
          />
        </S.ContainerInput>
        {renderButtons}
      </S.ContainerSearch>
      <S.ContainerFilter>{renderFilter}</S.ContainerFilter>
    </S.WrapperSearch>
  );
};
