import { queryClient } from 'services/query-client';
import { hireBackhaulUpdateService } from 'pages/hire-backhaul/services/hire-backhaul-update/hire-backhaul-update.service';
import { IHireBackhaulCreated } from 'domain/hire-backhaul';

const useUpdateHireBackhaulHook = () => {
  return async (hireBackhaul: IHireBackhaulCreated) => {
    await hireBackhaulUpdateService(hireBackhaul);
    queryClient.invalidateQueries(['idlenessList']);
  };
};

export { useUpdateHireBackhaulHook };
