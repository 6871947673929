import API from 'utils/API/API';
import { IScheduleOccurrenceListDomain } from '../../domains/schedule-occurrence-list/schedule-occurrence-list.domain';
import { IScheduleOccurrenceListResponse } from './get-schedule-occurrence-list.types';
import { convertScheduleOccurrenceListResponseToDomain } from './mappers/convert-schedule-occurrence-list-response-to-domain.mapper';
import { IListPage } from 'src-new/domains/list-page.domain';

export const getScheduleOccurrenceListService = async (
  scheduleId: number,
): Promise<Array<IScheduleOccurrenceListDomain>> => {
  const { data } = await API.get<IListPage<IScheduleOccurrenceListResponse>>(
    `v2/occurrence-tracking/all-occurrences?freightId=${scheduleId}`,
  );

  return data.items.map(convertScheduleOccurrenceListResponseToDomain);
};
