import React from 'react';

import { OurPrivacyPoliciesModalBodyView } from './our-privacy-policies-modal-body-view';

interface IProps {
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OurPrivacyPoliciesModalBody: React.FC<IProps> = ({ title, showModal, setShowModal }) => {
  return <OurPrivacyPoliciesModalBodyView title={title} showModal={showModal} setShowModal={setShowModal} />;
};

export { OurPrivacyPoliciesModalBody };
