import { FormikErrors } from 'formik';
import { FC, useCallback, useContext } from 'react';
import { IAsyncSelectField } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/components/weighing-duplicated-form/components/weighing-duplicated-form-mount/components/weighing-duplicated-form-mount-vehicles/weighing-duplicated-form-mount-vehicles-view.component';
import { UserContext } from 'state/user-context';
import { getVehicleService } from 'src-new/pages/execution/pages/weighing/pages/services/get-vehicle.service/get-vehicle.service';
import { WeighingDuplicatedFormMountVehiclesView } from './weighing-duplicated-form-mount-vehicles-view.component';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';

interface IWeighingDuplicatedFormMountVehiclesProps {
  values: IWeighingDuplicatedDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDuplicatedDomain>>;
}

export const WeighingDuplicatedFormMountVehicles: FC<IWeighingDuplicatedFormMountVehiclesProps> = ({
  values,
  setFieldValue,
}) => {
  const { user } = useContext(UserContext);

  const onChange = useCallback(
    async (e: IAsyncSelectField) => {
      const selectedMainPlate = e?.label.split('-')[0] ? e?.label.split('-')[0] : '';
      const secondPlate = e?.label.split('-')[1] ? e?.label.split('-')[1] : '';

      const vehicles = await getVehicleService('');

      vehicles.map((vehicle) => {
        const mainPlate = vehicle?.label.split('-')[0] ? vehicle?.label.split('-')[0] : '';
        if (mainPlate == selectedMainPlate) {
          setFieldValue('vehicle.id', e && e.value);
          setFieldValue('vehicle.mainPlate', selectedMainPlate);
          setFieldValue('vehicle.secondPlate', secondPlate);
          setFieldValue('vehicle.type', vehicle.type);
          setFieldValue('vehicle.category', vehicle.category);
        }
      });
    },
    [setFieldValue],
  );

  return (
    <WeighingDuplicatedFormMountVehiclesView
      values={values}
      setFieldValue={setFieldValue}
      isMaster={!!user?.isMaster}
      onChange={onChange}
    />
  );
};
