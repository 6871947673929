import moment, { Moment } from 'moment';

export const normalizeDate = (date: Moment | null) => {
  if (!date) return null;

  if (!moment(date).isValid()) {
    const usDate = date.format('MM-DD-YYYY');

    if (!moment(usDate).isValid()) return null;

    return moment(usDate);
  }

  return moment(date);
};

export const normalizeUSDate = (date: Moment | null | undefined) => {
  if (!date) return '';

  if (moment(date).isValid()) {
    const usDate = moment(date).format('YYYY-MM-DD');

    if (!moment(usDate).isValid()) return '';

    return usDate;
  }

  return '';
};
