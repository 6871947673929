import { useCallback, useReducer } from 'react';
import { scheduleRequestStatusReducer } from '../../reducers/schedule-request-status/schedule-request-status.reducer';
import { ScheduleRequestStatusActionType } from '../../schedule.action';
import { scheduleInitialState } from '../../schedule.constants';
import { IScheduleRequestStatus } from '../../types/schedule-request-status.types';

export const useScheduleRequestStatusContextValues = (): IScheduleRequestStatus => {
  const [state, dispatch] = useReducer(scheduleRequestStatusReducer, scheduleInitialState);

  const setScheduleRequestStatus = useCallback(() => {
    dispatch({ type: ScheduleRequestStatusActionType.SCHEDULE_REQUEST_STATUS, payload: undefined });
  }, []);

  const setScheduleRequestStatusSuccess = useCallback(() => {
    dispatch({ type: ScheduleRequestStatusActionType.SCHEDULE_REQUEST_SUCCESS, payload: undefined });
  }, []);

  const setScheduleRequestStatusError = useCallback(() => {
    dispatch({ type: ScheduleRequestStatusActionType.SCHEDULE_REQUEST_ERROR, payload: undefined });
  }, []);

  const setScheduleRequestStatusReset = useCallback(() => {
    dispatch({ type: ScheduleRequestStatusActionType.SCHEDULE_REQUEST_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleRequestStatus,
    setScheduleRequestStatus,
    setScheduleRequestStatusSuccess,
    setScheduleRequestStatusError,
    setScheduleRequestStatusReset,
  };
};
