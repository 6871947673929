import styled from 'styled-components';

export const ContainerModalStyled = styled.div``;

export const ContainerUpdateStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 50px 0px;
  text-align: justify;
`;

export const ContainerAccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 14px;
`;

export const ContainerTextTermStyled = styled.div``;

export const ContainerTitleStyled = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 40px 0;
`;

export const SpacingStyled = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const ContainerTermStyled = styled.div`
  margin: 30px 5px;
  text-align: justify;
`;

export const ContainerStreetStyled = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  font-size: 12px;
`;

export const OurPrivacyPolicies = styled.div`
  cursor: pointer;
  font-weight: 700;
  margin: 0 5px;
  text-decoration: underline;
`;
