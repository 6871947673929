import React from 'react';

const DashBoardIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="19" height="19" fill="url(#dashboard0)" fillOpacity="1" />
      <defs>
        <pattern id="dashboard0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_1513_33" transform="scale(0.00195312)" />
        </pattern>
        <image
          id="image0_1513_33"
          width="512"
          height="512"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIAAQMAAADOtka5AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAEiZO6H8hYgAAAAJ0Uk5TAP9bkSK1AAABR0lEQVR4nO3aMW7CUBBFUaQUKbMEL+UvzVvLUrIEShdfdiRXHhg5HgKOIp1bPuAIyXTM5SJJknS0tmzqDwBLqP75twh8loH3CHyVgY8IXMvAEIGpDLQI1B/Dr4ExAvP5wHITAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP490LbLD/9lp0DlS2VA6SYhA0o3CRlQuknIgCFO+zcJGdDitP8YXgKMcdo/KXgJkG0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+HOgbef+AJDsJSC7KygB2V1BCcjuCkrAEPepDLS49/OBMe7z+cDRNwMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAGjDGfa5szwFafKFXtucAQ3xhqmxr2dn50W0tO3w/uq1lp/dHt+yXVNvuH0OvbZIkSTd9A4ZYgnzvH9B5AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default DashBoardIcon;
