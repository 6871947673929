import { MatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListActions } from '../../types/matches-kanban.types';
import { IMatchesContext } from '../../types/matches.types';

export const matchesKanbanListReducer = (
  state: IMatchesContext,
  action: IMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case MatchesKanbanListActionType.MATCHES_KANBAN_MODALS:
      return {
        ...state,
        matchesKanbanList: {
          ...state.matchesKanbanList,
          modalsProps: {
            ...state.matchesKanbanList.modalsProps,
            modalOptions: action.payload,
          },
        },
      };
    case MatchesKanbanListActionType.MATCHES_KANBAN_LIST_FILTERS:
      return {
        ...state,
        matchesKanbanList: {
          ...state.matchesKanbanList,
          matchesListFiltersProps: {
            ...state.matchesKanbanList.matchesListFiltersProps,
            filters: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
