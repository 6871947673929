import React from 'react';
import { Tooltip } from '@mui/material';

import { WrapperStyled } from './button.styled';

export type ButtonProps = {
  callback?: () => void;
  title: string;
  icon?: React.ReactNode;
  bgColor?:
    | 'blue'
    | 'white'
    | 'pink'
    | 'green'
    | 'orange'
    | 'darkGray'
    | 'middleBlue'
    | 'newRed'
    | 'aqua'
    | 'lightGray';
  type?: 'button' | 'submit' | 'reset';
  modalType?: 'delete';
  size: 'xlarge' | 'large' | 'medium' | 'small' | 'very-small' | 'full' | 'narrow';
  isLoading?: () => void;
  disabled?: boolean;
  goToDelete?: () => void;
  getPlan?: string;
  text?: string;
  iconPosition?: 'right' | 'left';
};

const ButtonView: React.FC<ButtonProps> = ({
  title,
  callback,
  bgColor,
  icon,
  type = 'button',
  disabled,
  modalType,
  isLoading,
  goToDelete,
  getPlan,
  size,
  text,
  iconPosition,
}) => {
  if (size === 'full') {
    return (
      <WrapperStyled
        getPlan={getPlan}
        onClick={modalType === 'delete' ? goToDelete : callback}
        bgColor={bgColor}
        hasIcon={!!icon}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        size={'full'}
        iconPosition={iconPosition}
      >
        {iconPosition === 'left' && icon}
        {!!icon && icon && iconPosition !== 'left' && getPlan === 'pro'}
        {title}
        {getPlan !== 'pro' && iconPosition !== 'left' && !!icon && icon}
      </WrapperStyled>
    );
  }
  if (size === 'xlarge') {
    return (
      <WrapperStyled
        getPlan={getPlan}
        onClick={modalType === 'delete' ? goToDelete : callback}
        bgColor={bgColor}
        hasIcon={!!icon}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        size={'xlarge'}
        iconPosition={iconPosition}
      >
        {iconPosition === 'left' && icon}
        {!!icon && icon && iconPosition !== 'left' && getPlan === 'pro'}
        {title}
        {getPlan !== 'pro' && iconPosition !== 'left' && !!icon && icon}
      </WrapperStyled>
    );
  }
  if (size === 'large') {
    return (
      <WrapperStyled
        getPlan={getPlan}
        onClick={modalType === 'delete' ? goToDelete : callback}
        bgColor={bgColor}
        hasIcon={!!icon}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        size={'large'}
        iconPosition={iconPosition}
      >
        {iconPosition === 'left' && icon}
        {!!icon && icon && iconPosition !== 'left' && getPlan === 'pro'}
        {title}
        {getPlan !== 'pro' && iconPosition !== 'left' && !!icon && icon}
      </WrapperStyled>
    );
  } else if (size === 'medium') {
    return (
      <WrapperStyled
        getPlan={getPlan}
        onClick={modalType === 'delete' ? goToDelete : callback}
        bgColor={bgColor}
        hasIcon={!!icon}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        size={'medium'}
      >
        {getPlan === 'pro' && !!icon && icon}
        {title}
        {getPlan !== 'pro' && !!icon && icon}
      </WrapperStyled>
    );
  } else if (size === 'small') {
    return (
      <Tooltip title={<span style={{ fontSize: '11px' }}>{title}</span>} placement="bottom">
        <WrapperStyled
          getPlan={getPlan}
          onClick={modalType === 'delete' ? goToDelete : callback}
          bgColor={bgColor}
          hasIcon={!!icon}
          type={type}
          disabled={disabled}
          isLoading={isLoading}
          size={'small'}
        >
          {getPlan === 'pro' && !!icon && icon}
          {text ? text : ''}
          {getPlan !== 'pro' && !!icon && icon}
        </WrapperStyled>
      </Tooltip>
    );
  } else if (size === 'narrow') {
    return (
      <WrapperStyled
        getPlan={getPlan}
        onClick={modalType === 'delete' ? goToDelete : callback}
        bgColor="white"
        borderColor={bgColor}
        hasIcon={!!icon}
        type={type}
        disabled={disabled}
        isLoading={isLoading}
        size={'narrow'}
        iconPosition={iconPosition}
      >
        {iconPosition === 'left' && icon}
        {!!icon && icon && iconPosition !== 'left' && getPlan === 'pro'}
        {title}
        {getPlan !== 'pro' && iconPosition !== 'left' && !!icon && icon}
      </WrapperStyled>
    );
  } else {
    return (
      <Tooltip title={<span style={{ fontSize: '11px' }}>{title}</span>} placement="bottom">
        <WrapperStyled
          getPlan={getPlan}
          onClick={modalType === 'delete' ? goToDelete : callback}
          bgColor={bgColor}
          hasIcon={!!icon}
          type={type}
          disabled={disabled}
          isLoading={isLoading}
          size={'very-small'}
        >
          {getPlan === 'pro' && !!icon && icon}
          {text ? text : ''}
          {getPlan !== 'pro' && !!icon && icon}
        </WrapperStyled>
      </Tooltip>
    );
  }
};

export default ButtonView;
