import { ocurrenceReportExcelService } from 'pages/occurrence/services/ocurrence-report-excel/ocurrence-report-excel.service';
import { IOccurrenceEntityStatus } from 'pages/occurrence/services/entities/occurrence.entity';

export interface IOcurrenceReportExcelProps {
  search: string;
  status: IOccurrenceEntityStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: IOcurrenceReportExcelProps): Promise<BlobPart | undefined> => {
    return await ocurrenceReportExcelService(params);
  };
};

export { useReportExcelHook };
