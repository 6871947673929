import React from 'react';

import CheckboxView from './checkbox-view';

type CheckboxProps = {
  id: string;
  name: string;
  label?: string;
  labelColor?: string;
  backgroundColor?: string;
  value?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  hasError?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  value,
  label,
  labelColor,
  backgroundColor,
  disabled,
  onChange,
  checked,
  hasError,
}) => {
  return (
    <CheckboxView
      id={id}
      name={name}
      value={value}
      label={label}
      labelColor={labelColor}
      backgroundColor={backgroundColor}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      hasError={hasError}
    />
  );
};

export default Checkbox;
