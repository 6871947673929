import { FileListUploaded, GetCTeResponse, GetCTeResponseErroAutorizacao, SendNFeResponse } from '../types';

import API from 'utils/API/API';
import { showToast } from 'components/toast/toast';

export const getFreightNFeGenericsService = async (freightId: string) => {
  try {
    const { data } = await API.get<SendNFeResponse[]>(
      `v2/file/management?module=schedule&type=NFE&codigo=${freightId}`,
    );
    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao recuperar a lista de NFe',
    });
    throw new Error('Houve um erro ao recuperar a lista de NFe');
  }
};

export const getFreightNFeService = async (freightId: string) => {
  try {
    const { data } = await API.get<SendNFeResponse[]>(`v2/gestao-nfe/${freightId}`);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao recuperar a lista de NFe',
    });
    throw new Error('Houve um erro ao recuperar a lista de NFe');
  }
};

export const sendNFeService = async (freightId: string, files: any) => {
  try {
    const formData = new FormData();

    formData.append('files[0]', files);
    formData.append('frete_id', freightId);

    const { data } = await API.post<SendNFeResponse[]>('v2/gestao-nfe', formData);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do NFe',
    });
    throw new Error('Houve um erro ao realizar o upload do NFe');
  }
};

export const deleteNFeService = async (nfeId: string) => {
  try {
    await API.delete(`v2/gestao-nfe/${nfeId}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao deletar o NFe',
    });
    throw new Error('Houve um erro ao deletar o NFe');
  }
};

export const getCTeService = async (freightId: string, info?: GetCTeResponseErroAutorizacao) => {
  try {
    const { data } = await API.get<GetCTeResponse[]>(`v2/gestao-cte/frete/${freightId}`);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: info?.mensagem_sefaz || 'Houve um erro ao recuperar o CTe',
    });
    throw new Error('Houve um erro ao recuperar o CT-e');
  }
};

export const getUpdateCTeService = async (ref: string, info?: GetCTeResponseErroAutorizacao) => {
  try {
    const { data } = await API.get<GetCTeResponse>(`v2/gestao-cte/status/${ref}`);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: info?.mensagem_sefaz || 'Houve um erro ao recuperar o CTe',
    });
    throw new Error('Houve um erro ao recuperar o CT-e');
  }
};

export const postCTeService = async (freightId: string, info?: GetCTeResponseErroAutorizacao) => {
  try {
    await API.post<GetCTeResponse>('v2/gestao-cte', {
      frete_id: Number.parseInt(freightId),
      schedule_id: Number.parseInt(freightId),
    });
    showToast({
      type: 'success',
      message: 'CTe gerado com sucesso',
    });
  } catch (error) {
    showToast({
      type: 'error',
      message: info?.mensagem_sefaz || 'Houve um erro ao gerar CTe',
    });
    throw new Error('Houve um erro ao gerar o CT-e');
  }
};

export const deleteCTeService = async (ref: string, reason: string) => {
  try {
    await API.delete(`v2/gestao-cte/${ref}`, {
      data: {
        justificativa: reason,
      },
    });
    showToast({
      type: 'success',
      message: 'CTe cancelado com sucesso',
    });
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao cancelar o CTe',
    });
    throw new Error('Houve um erro ao cancelar o CT-e');
  }
};

export const sendStatusFile = async (freightId: string, statusCode: string, files: any) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('statusCode', statusCode);

    const { data } = await API.post<any>(`v2/schedules/status/file-upload/${freightId}`, formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload da imagem',
    });
    throw new Error('Houve um erro ao realizar o upload da imagem');
  }
};

export const removeStatusFile = async (scheduleId: number, fileId: number) => {
  try {
    await API.delete<any>(`v2/schedules/status/file-upload/${scheduleId}/${fileId}`);

    showToast({
      type: 'success',
      message: 'Foto removida com sucesso',
    });
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar a remoção da imagem',
    });
  }
};

export const uploadCte = async (freightId: number | string, files: any, isSubCte?: boolean) => {
  try {
    let typeCte = 'CTE';

    if (isSubCte) typeCte = 'CTE_SUB';

    const formData = new FormData();
    formData.append('type', typeCte);
    formData.append('module', 'schedule');
    formData.append('codigo', String(freightId));

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload da CTE',
    });
    throw new Error('Houve um erro ao realizar o upload da CTE');
  }
};

export const listUploadedCte = async (freightId: number | string): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>(`v2/gestao-cte/schedule/${freightId}`);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao listar o CTe',
    });
    throw new Error('Houve um erro ao listar o CTe');
  }
};

export const deleteUploadedCte = async (cteId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/cte/${cteId}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o CTE',
    });
    throw new Error('Houve um erro ao excluir o CTE');
  }
};

export const deleteUploadedNfe = async (id: number): Promise<void> => {
  try {
    await API.delete<any>(`/v2/file/management/${id}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir a NFe',
    });
    throw new Error('Houve um erro ao excluir a NFe');
  }
};

export const getScheduleInvoice = async (scheduleId: number | string): Promise<any> => {
  const scheduleInvoicing = await API.get<any>(`v2/invoicing/schedule/${scheduleId}`);

  return scheduleInvoicing.data;
};

export const uploadMdfe = async (files: FileList, freightId: number | string) => {
  try {
    const formData = new FormData();
    formData.append('type', 'MDFe');
    formData.append('module', 'schedule');
    formData.append('codigo', String(freightId));

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const { data } = await API.post('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload da MDFe',
    });
    throw new Error('Houve um erro ao realizar o upload da MDFe');
  }
};

export const listUploadedMdfe = async (freightId: number | string): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<FileListUploaded[]>(
      `v2/file/management?module=schedule&type=MDFe&codigo=${freightId}`,
    );

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao listar o MDFe',
    });
    throw new Error('Houve um erro ao listar o MDFe');
  }
};

export const deleteUploadedMdfe = async (mdfeId: number | string): Promise<void> => {
  try {
    await API.delete(`v2/file/management/${mdfeId}`);
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o MDFe',
    });
    throw new Error('Houve um erro ao excluir o MDFe');
  }
};
