import React, { useCallback, useEffect, useState, useMemo, ReactElement } from 'react';
import { MenuStyled, TitleStyled, WrapperStyled } from './submenu.styled';
import SubMenuItem from './components/item/item';
import { UserContext } from 'state/user-context';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import { validatePermissionsService } from 'src-new/services/validate-permissions/validate-permissions.service';
import CrownIcon from 'assets/icons/crown.icon';

interface ISubmenuViewProps {
  openModals: (modalType: 'newFreight' | 'newPlan') => void;
}

const SubmenuView: React.FC<ISubmenuViewProps> = ({ openModals }) => {
  const { user } = React.useContext(UserContext);
  const { handleHover, handleSelectedMenu, menus } = React.useContext(SidebarContext);
  const [selectedMenu, setSelectedMenu] = useState('');

  const verifyProPlan = useMemo((): Array<string> => {
    return ['INTELIGÊNCIA'];
  }, []);

  const isDisabled = useMemo(() => {
    return !['pro', 'shipping-company'].includes(user?.plan ?? '');
  }, [user]);

  const mountMenus = useCallback(() => {
    return menus.map(({ submenus, title }) => {
      return submenus.map(({ name, path, profiles, routeName, icon, line, subtitles, disabled = false }) => {
        if (selectedMenu === title) {
          if (validatePermissionsService(routeName, profiles, user)) {
            const shippingTerms = user?.profile === 'shipping-company' && name === 'Termos de Uso';

            const shippingPGR = user?.profile === 'shipping-company' && name === 'PGR';

            const isNewSchedule = name === 'Novo Frete';

            return (
              <SubMenuItem
                disabled={isDisabled}
                disabledSubtitles={disabled}
                key={name}
                name={name}
                path={path}
                icon={icon}
                line={line}
                shippingTerms={shippingTerms}
                shippingPGR={shippingPGR}
                isNewSchedule={isNewSchedule}
                subtitles={subtitles}
                title={title}
                openModals={openModals}
              />
            );
          }
        }
      });
    });
  }, [isDisabled, menus, openModals, selectedMenu, user]);

  const renderTitle = useMemo((): ReactElement => {
    if (verifyProPlan.includes(selectedMenu.toLocaleUpperCase())) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TitleStyled onClick={() => handleHover(false)}>{selectedMenu.toLocaleUpperCase()}</TitleStyled>
          <CrownIcon />
        </div>
      );
    }

    return <TitleStyled onClick={() => handleHover(false)}>{selectedMenu.toLocaleUpperCase()}</TitleStyled>;
  }, [handleHover, selectedMenu, verifyProPlan]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSelectedMenu(sessionStorage.getItem('selectedMenu') || ''));

  return (
    <>
      <WrapperStyled
        onMouseLeave={() => {
          handleHover(false);
          handleSelectedMenu('');
        }}
      >
        {renderTitle}
        <MenuStyled onClick={() => handleHover(false)}>{mountMenus()}</MenuStyled>
      </WrapperStyled>
    </>
  );
};

export default SubmenuView;
