import { IScheduleDetailsComplementDomain } from '../domains/schedule-details.domain';

export const convertStatusCodeDates = (dates: IScheduleDetailsComplementDomain): string => {
  const statusMap: Record<string, string> = {
    finishDate: 'IN90',
    loadDate: 'IN70',
    arrivalUnloadingDate: 'IN62',
    startTripUnloadingDate: 'IN61',
    checkoutDate: 'IN60',
    checkinDate: 'IN40',
    arrivalLoadingDate: 'IN30',
    startDate: 'IN20',
    acceptDate: 'IN11',
    allocationVehicleDate: 'IN10',
    allocationDriverDate: 'AL20',
    allocationShippingDate: 'AL10',
  };

  const keys = Object.keys(statusMap);

  for (const key of keys) {
    if (dates[key as keyof IScheduleDetailsComplementDomain]) {
      return statusMap[key];
    }
  }

  return 'IN11';
};
