import styled, { keyframes } from 'styled-components';

interface IStyledProps {
  removeMargin?: boolean;
}

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const DropdownContainerStyled = styled.div`
  display: flex;
  width: 45px;
`;

export const NewRecorddButtonContainerStyled = styled.div<IStyledProps>`
  ${({ removeMargin }) => (removeMargin ? '' : 'margin-left: 30px')};
`;

export const NewRecordEmptyContainerStyled = styled.div`
  height: 40px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerButton = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid rgb(175, 175, 175);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${rotate} 0.7s linear infinite;
  display: inline-block;
`;
