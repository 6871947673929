import { FC } from 'react';
import * as S from './purchase-offer-modal-match-score-label-view.styled';

type ScoreLabel = 'ALTO' | 'MODERADO' | 'BAIXO';

interface IPurchaseOfferModalMatchScoreLabelViewProps {
  label: ScoreLabel;
}

export const PurchaseOfferModalMatchScoreLabelView: FC<IPurchaseOfferModalMatchScoreLabelViewProps> = ({ label }) => {
  return <S.Wrapper>{label}</S.Wrapper>;
};
