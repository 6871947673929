import React, { useCallback, useContext, useMemo } from 'react';
import { RouteReportDownloadButtonView } from './route-report-download-button-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useGenerateMapReportFile } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-generate-map-report-file/use-generate-map-report-file.hook';

export const RouteReportDownloadButton: React.FC = () => {
  const { scheduleDownloadMapReport, scheduleExecution } = useContext(ScheduleContext);
  const generateMapReportFile = useGenerateMapReportFile();

  const canGenerateReport = useMemo((): boolean => {
    const firebaseRoutesExist = scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length > 1;
    const monitoringRoutesExist = scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length > 1;

    return firebaseRoutesExist || monitoringRoutesExist;
  }, [
    scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length,
    scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length,
  ]);

  const handleReportDownload = useCallback(() => {
    if (canGenerateReport) {
      generateMapReportFile();
    }
  }, [canGenerateReport, generateMapReportFile]);

  const isLoading = useMemo(
    () => scheduleDownloadMapReport.statusRequest === 'PROCESSING',
    [scheduleDownloadMapReport.statusRequest],
  );

  return <RouteReportDownloadButtonView isLoading={isLoading} handleReportDownload={handleReportDownload} />;
};
