import React from 'react';

import SectionDividerView from './section-divider-view';

type SectionDividerProps = {
  label: string;
  children?: React.ReactNode;
  secondItem?: React.ReactElement;
  hidePin?: boolean;
};
const SectionDivider: React.FC<SectionDividerProps> = ({ label, secondItem, hidePin=true }) => {
  return <SectionDividerView label={label} secondItem={secondItem} hidePin={hidePin}/>;
};

export default SectionDivider;
