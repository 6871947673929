import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { weighingRegisterService } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/services/weighing-register/weighing-register.service';
import { convertWeighingRegisterDomainToEntity } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/hooks/use-weighing-register/mappers/convert-weighing-register-domain-to-entity/convert-weighing-register-domain-to-entity.mapper';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { useNavigate } from 'react-router-dom';

export const useRegisterWeighing = () => {
  const reactQueryClient = useQueryClient();
  const navigate = useNavigate();
  return useCallback(
    async (payload: IWeighingFormRegisterDomain) => {
      try {
        await weighingRegisterService(convertWeighingRegisterDomainToEntity(payload));
        await reactQueryClient.invalidateQueries(['weighing-list-table']);
        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });
        navigate('/pesagem');
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [navigate, reactQueryClient],
  );
};
