import React, { useMemo } from 'react';

import { CardResumeOriginAndDestinyInput, ILevyScheduleDetailsValues } from './types';
import * as S from './styled';

import { formatTime } from 'utils-v2/converters/time';
import { formatDateTime } from 'utils-v2/converters/date';

interface CardResumeCollectionProps {
  origin: CardResumeOriginAndDestinyInput;
  scheduleDetailsValues?: ILevyScheduleDetailsValues;
}

export const CardResumeCollection: React.FC<CardResumeCollectionProps> = ({ origin, scheduleDetailsValues }) => {
  const levyDate = useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { levyInitialDate, levyEndDate } = scheduleDetailsValues;

    if (levyInitialDate && levyInitialDate.toUpperCase() !== 'INVALID DATE') {
      if (levyEndDate && levyEndDate.toUpperCase() !== 'INVALID DATE') {
        return `${formatDateTime(levyInitialDate, 'DD-MM-YYYY HH:mm')} - ${formatTime(
          levyEndDate,
          'DD-MM-YYYY HH:mm',
        )}`;
      }
      return `${formatDateTime(levyInitialDate, 'DD-MM-YYYY HH:mm')}`;
    }

    if (levyEndDate && levyEndDate.toUpperCase() !== 'INVALID DATE') {
      return `${formatDateTime(levyEndDate, 'DD-MM-YYYY HH:mm')}`;
    }

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.LineSection columns="0.5fr 3.5fr 8fr">
      <S.StepIconSection>
        <S.StepIcon />
        <S.Connector size={origin.tradeName ? '110' : '150'} />
      </S.StepIconSection>

      <S.Text fontWeight uppercase>
        Origem
      </S.Text>

      {origin.tradeName ? (
        <S.TextContent uppercase>
          <S.Text fontWeight>{origin.tradeName}</S.Text>
          <S.Text>{`${origin.address.street} ${origin.address.numberHouse}`}</S.Text>
          <S.Text>{`${origin.address.city} - ${origin.address.uf}`}</S.Text>
          <S.Text>{levyDate}</S.Text>
        </S.TextContent>
      ) : (
        <S.TextContent italic>
          <S.Text uppercase>Não informado</S.Text>
        </S.TextContent>
      )}
    </S.LineSection>
  );
};
