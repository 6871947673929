import React, { ReactElement } from 'react';
import { TerritorialVisionContextProvider } from '../context/territorial-vision.context';
import { TerritorialVisionBodyView } from './territorial-vision-body-view';

export const TerritorialVisionBody: React.FC = (): ReactElement => {
  return (
    <TerritorialVisionContextProvider>
      <TerritorialVisionBodyView />
    </TerritorialVisionContextProvider>
  );
};
