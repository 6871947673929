import React from 'react';
import FreightUpdateSession from 'pages/freight/freight-update/freight-page-body/components/freight-update-session/freight-update-session.component';
import Loading from 'components-v2/common/loading';
import FreightUpdate from 'pages/freight/freight-update/freight-update-body/freight-update.component';
import { SpinnerContainerStyled } from './freight-page-body-view.styled';

interface IFreightPageBodyViewProps {
  isLoadingChat: boolean;
}

const FreightPageBodyView: React.FC<IFreightPageBodyViewProps> = ({ isLoadingChat }) => {
  if (isLoadingChat) {
    return (
      <SpinnerContainerStyled>
        <Loading />
      </SpinnerContainerStyled>
    );
  }

  return (
    <FreightUpdateSession>
      <FreightUpdate />
    </FreightUpdateSession>
  );
};

export default FreightPageBodyView;
