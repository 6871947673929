import React from 'react';

import { AlertScheduleCreated } from 'assets/icons/alerts/schedule-created';
import { AlertBiddingWin } from 'assets/icons/alerts/bidding-win';
import { AlertBiddingCreated } from 'assets/icons/alerts/bidding-created';

type IconMap = {
  [key: string]: React.ReactNode;
};

export const convertIconAlerts = (event: string): React.ReactNode => {
  const iconsMap: IconMap = {
    ACCEPT_BIDDING: <AlertBiddingWin />,
    CREATED_BIDDING: <AlertBiddingCreated />,
    CREATED_IDLENESS_BIDDING: <AlertBiddingCreated />,
    OFFER_BIDDING: <AlertBiddingCreated />,
    SCHEDULE_SPOT_CREATED: <AlertScheduleCreated />,
  };

  return iconsMap[event];
};
