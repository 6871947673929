import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { PurchaseOfferModalMatchView } from './purchase-offer-modal-match-view.component';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { companyDetailsService } from 'services/company/details';
import { UserContext } from 'state/user-context';
import { ICompany } from 'domain/company';

export const PurchaseOfferModalMatch: FC = () => {
  const { user } = useContext(UserContext);
  const { purchaseOfferModals, purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const { setModalClose } = purchaseOfferModals;
  const { purchaseOfferMatchProposal, filters } = purchaseOfferMatchList;
  const { setPurchaseOfferMatchProposalReset } = purchaseOfferMatchProposal;
  const { setResetFilters } = filters;

  const [company, setCompany] = useState<ICompany>();

  const handleCloseModalMatch = useCallback(() => {
    setModalClose();
    setPurchaseOfferMatchProposalReset();
    setResetFilters();
  }, [setModalClose, setPurchaseOfferMatchProposalReset, setResetFilters]);

  useEffect(() => {
    (async () => {
      if (user) {
        const findCompany = await companyDetailsService(user.companyId);
        setCompany(findCompany);
      }
    })();
  }, [user]);

  if (company) {
    return <PurchaseOfferModalMatchView company={company} handleCloseModalMatch={handleCloseModalMatch} />;
  }

  return <Fragment />;
};
