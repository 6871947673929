import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -13px;

  @media print {
    display: none;
  }
`;
export const AccordionBodyStyled = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.blue};
    background-color: ${theme.colors.white};
  `}
`;

export const AccordionContainerStyled = styled.div<{ hidden?: boolean }>`
  position: relative;
  margin-top: 20px;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

export const AccordionContainerLabelStyled = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.blue};
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: ${theme.colors.white};
    font-weight: ${theme.font.bold};
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    ${({ active }: { active: boolean }) =>
      active &&
      css`
        color: ${theme.colors.white};
        background-color: ${theme.colors.blue};
      `}

    &:before {
      content: '+';
      color: ${theme.colors.white};
      position: absolute;
      top: 50%;
      right: 11px;
      font-size: 22px;
      font-weight: 400;
      transform: translateY(-50%);
    }
  `}
`;

export const AccordionBody = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0px;
  overflow: hidden;

  ${({ active }: { active: boolean; bodyHeight: number }) =>
    active &&
    css`
      height: 100%;
      overflow-y: auto;
    `}
`;

export const AccordionContainerContentStyled = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: white;
    font-size: ${theme.font.sizes.small};
    text-align: justify;
    overflow: hidden;
    transition: 0.8s;
    height: 140%;
    overflow: auto;
  `}
`;
