import { FC, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import {
  ICargoInput,
  IModalBackhaulPurchaseForm,
} from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';

interface ICargoDetailsBackhaulPurchaseFormViewProps {
  cargo: ICargoInput;
  handleChange: (field: string, value: string | number) => Promise<void | FormikErrors<IModalBackhaulPurchaseForm>>;
}

export const CargoDetailsBackhaulPurchaseFormView: FC<ICargoDetailsBackhaulPurchaseFormViewProps> = ({
  cargo,
  handleChange,
}) => {
  const renderMerchValue = useMemo(
    (): string => Number.parseFloat(cargo.merchValue.toString()).toFixed(2).toString(),
    [cargo.merchValue],
  );

  return (
    <SectionLayout name="Cargo details information">
      <LineSectionLayout columns="1fr 1fr 1fr 1fr 0.07fr">
        <InputCurrency
          label="Peso (Kg)"
          id="cargo.capacity.weight"
          name="cargo.capacity.weight"
          type="text"
          suffix=" Kg"
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          allowDecimals={false}
          onValueChange={(value, name) => handleChange(name ?? '', value ?? '')}
          value={cargo.capacity.weight}
        />

        <InputField
          label="Paletes"
          id="cargo.capacity.pallets"
          name="cargo.capacity.pallets"
          type="number"
          onChange={(e: any) => handleChange('cargo.capacity.pallets', e.target.value)}
          value={cargo.capacity.pallets}
        />

        <InputCurrency
          label="Cubagem (m3)"
          id="cargo.capacity.cubage"
          name="cargo.capacity.cubage"
          type="number"
          suffix=" m³"
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          allowDecimals={false}
          onValueChange={(value, name) => handleChange(name ?? '', value ?? '')}
          value={cargo.capacity.cubage}
        />

        <InputCurrency
          label="Valor da Mercadoria"
          id="cargo.merchValue"
          name="cargo.merchValue"
          type="number"
          prefix={'R$ '}
          decimalSeparator=","
          groupSeparator="."
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => handleChange(name ?? '', converterNumberPtBrToUs(value ?? '0'))}
          value={renderMerchValue}
        />
      </LineSectionLayout>

      <LineSectionLayout columns=" 1fr 1fr 1fr 0.050fr">
        <InputField
          label="Produto Predominante"
          id="cargo.product"
          name="cargo.product"
          type="text"
          onChange={(e: { target: { value: string } }) => handleChange('cargo.product', e.target.value)}
          value={cargo.product}
        />

        <InputField
          label="Pedidos"
          id="cargo.order"
          name="cargo.order"
          type="text"
          onChange={(e: { target: { value: string } }) => handleChange('cargo.order', e.target.value)}
          value={cargo.order}
        />

        <InputField
          label="Observações Gerais"
          id="cargo.comments"
          name="cargo.comments"
          type="text"
          onChange={(e: { target: { value: string } }) => handleChange('cargo.comments', e.target.value)}
          value={cargo.comments}
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};
