import React from 'react';

import OccurrencesTrackingContextProvider from '../contexts/table-states/tracking-occurrence.context';

import { TrackingOccurrenceListBody } from './tracking-occurrence-body/tracking-occurrence-body';

const TrackingOccurrencePage: React.FC = () => {
  return (
    <OccurrencesTrackingContextProvider>
      <TrackingOccurrenceListBody />
    </OccurrencesTrackingContextProvider>
  );
};

export default TrackingOccurrencePage;
