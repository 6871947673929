import { InputMaskCPFEntityToDomain } from 'utils/remove-input-mask/remove-input-mask';
import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { IWeighingDuplicatedDomain } from '../../../../domains/weighing-duplicated.domain';
import { IWeighingDuplicatedResponse } from '../../get-weighing-by-id.types';

export const converterWeighingDetailsResponseToDomain = (
  weighingResponse: IWeighingDuplicatedResponse,
): IWeighingDuplicatedDomain => {
  const observation = weighingResponse?.observation ||  ''
  const collectionOrder = weighingResponse?.collectionOrder || ''
  const renderIfem = observation && collectionOrder && '-'
  const concatenedObservation = `${collectionOrder} ${renderIfem} ${observation}`;
  return {
    ...weighingResponse,
    ticketStatus: 'VAPORIZACAO',
    collectionOrder: '',
    observation: concatenedObservation.trim(),
    smpValidation: weighingResponse.smpValidation ?? '',
    po: weighingResponse.po ?? '',
    origin: {
      ...weighingResponse.origin,
      cep: weighingResponse.origin.cep,
    },
    destination: {
      ...weighingResponse.destination,
      cep: weighingResponse.destination.cep,
    },
    entryDate: weighingResponse.entryDate ? new Date(weighingResponse.entryDate).toString() : '',
    exitDate: weighingResponse.exitDate ? new Date(weighingResponse.exitDate).toString() : '',
    originWeight: weighingResponse.originWeight,
    scheduledDateTime: weighingResponse.scheduledDateTime
      ? new Date(weighingResponse.scheduledDateTime).toString()
      : '',
    driver: {
      ...weighingResponse.driver,
      cpf: InputMaskCPFEntityToDomain(weighingResponse.driver.cpf),
      phone: formatCelphoneNumberEntityToDomain(weighingResponse.driver.phone),
    },
    vehicle: {
      ...weighingResponse.vehicle,
      type: weighingResponse.vehicle.type,
      category: weighingResponse.vehicle.category,
    },
    item: {
      id: weighingResponse.item.id,
      description: weighingResponse.item.description,
    },
  };
};
