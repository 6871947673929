import styled from 'styled-components';
import logo from 'src/assets/microsoft.png';
import { Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled.form``;

type SectionStyledProps = { name: string };

export const SectionStyled = styled.section<SectionStyledProps>``;

export const WrapperSectionLoginStyled = styled.div`
  margin-bottom: 20px;
`;

export const InputDivider = styled.div`
  height: 15px;
`;
export const ForgatPassawordContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
export const IconWrapper = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const LoginContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const MicrosoftIcon = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${logo});
  background-size: cover;
`;
