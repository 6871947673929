import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { Formik } from 'formik';

import * as S from './styled';
import SecurityRequirementsForm from './security-requirements';
import CargoDetailsForm from './schedule-cargo';
import { OriginAndDestinyBiddingForm } from './origin-and-destiny';
import { IdlenessDetails } from './idleness-details';
import DetailsForm from './dates-and-hours';

import { handleValidateDates } from 'utils-v2/validate-date/validate-modal-dates';
import { matchRegisterInitialValues } from 'domain-v2/match/register/initial-values';
import { DatesMatchInput, MatchRegisterInput } from 'domain-v2/match/register';
import { IUser } from 'domain/login';
import { Paper } from 'components-v2/layout/paper';
import { ICompany } from 'domain/company';

interface BiddingFormProps {
  idleness?: any;
  company?: ICompany;
  userCompany?: ICompany;
  handleSubmit: (values: MatchRegisterInput) => void;
  setRequirements: Dispatch<SetStateAction<string[] | undefined>>;
  user?: IUser;
}

export const BiddingForm: FC<BiddingFormProps> = ({
  idleness,
  company,
  userCompany,
  handleSubmit,
  setRequirements,
  user,
}) => {
  const [datesError, setDatesError] = useState<DatesMatchInput[]>();

  return (
    <Formik
      initialValues={matchRegisterInitialValues}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => {
        const handleValidation = () => {
          const validation = handleValidateDates(values.dates);

          if (validation) {
            setDatesError(validation.validationDates);
          } else {
            handleSubmit();
          }
        };

        return (
          <S.WrapperModalBiddingForm>
            <S.WrapperForm>
              <S.SectionForm>
                <S.TitleForm>ORIGEM E DESTINO</S.TitleForm>

                <S.LocalityContainer>
                  <OriginAndDestinyBiddingForm idleness={idleness} type="origin" />
                  <OriginAndDestinyBiddingForm idleness={idleness} type="destination" />
                </S.LocalityContainer>
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>DETALHES DA CARGA</S.TitleForm>

                <CargoDetailsForm idleness={idleness} />
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>DETALHES DO AGENDAMENTO</S.TitleForm>

                <DetailsForm datesError={datesError} setDatesError={setDatesError} />
              </S.SectionForm>
              <S.SectionForm>
                <S.TitleForm>REQUISITOS ADICIONAIS</S.TitleForm>

                <SecurityRequirementsForm setRequirements={setRequirements} />
              </S.SectionForm>
            </S.WrapperForm>

            <S.ProposedContainer>
              <Paper>
                <IdlenessDetails
                  idleness={idleness}
                  company={company}
                  userCompany={userCompany}
                  user={user}
                  proposalValue={idleness?.proposalValue}
                />
              </Paper>

              <S.WrapperButton>
                <Button label="Agendar" color="blue" onPress={handleValidation} size="md" variant="solid" />
              </S.WrapperButton>
            </S.ProposedContainer>
          </S.WrapperModalBiddingForm>
        );
      }}
    </Formik>
  );
};
