import { IScheduleDetailsAllocationVehicleDomain } from '../../../../domains/schedule-details-allocation-vehicle.domain';
import { IScheduleDetailsAllocationVehicleResponse } from '../../get-vehicle-list.types';

export const converterScheduleDetailsAllocationVehicleResponseToDomain = (
  scheduleDetailAllocationVehicleResponse: IScheduleDetailsAllocationVehicleResponse,
): IScheduleDetailsAllocationVehicleDomain => {
  return {
    id: scheduleDetailAllocationVehicleResponse.id,
    mainBoard: scheduleDetailAllocationVehicleResponse.mainBoard,
    secondBoard: scheduleDetailAllocationVehicleResponse.secondBoard,
    thirdBoard: scheduleDetailAllocationVehicleResponse.thirdBoard,
    shippingName: scheduleDetailAllocationVehicleResponse.shippingCompany?.name,
    type: scheduleDetailAllocationVehicleResponse.vehicleType,
    status: scheduleDetailAllocationVehicleResponse.status,
  };
};
