import { useCallback, useContext } from 'react';

import { Hook } from './use-tracking-occurrence-list.types';

import { trackingOccurrenceListService } from 'pages/tracking-occurrence/services/tracking-occurrence-list/tracking-occurrence-list.service';
import { OccurrencesTrackingContext } from 'pages/tracking-occurrence/contexts/table-states/tracking-occurrence.context';

const useTrackingOccurrenceList: Hook = () => {
  const { setOccurrencesTrackingRequest, setOccurrencesTrackingSuccess, setOccurrencesTrackingError } =
    useContext(OccurrencesTrackingContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setOccurrencesTrackingRequest();

      try {
        const response = await trackingOccurrenceListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setOccurrencesTrackingSuccess(response);
      } catch (error) {
        setOccurrencesTrackingError('Ocorreu um erro ao buscar as ocorrências, tente novamente!');
      }
    },
    [setOccurrencesTrackingError, setOccurrencesTrackingRequest, setOccurrencesTrackingSuccess],
  );
};

export { useTrackingOccurrenceList };
