import API from 'utils/API/API';

export interface ScheduleFileUrl {
  id: number;
  statusCode: string;
  fileKey: string;
  scheduleId: number;
}

export const getScheduleFileUrlByIdService = async (scheduleId: string): Promise<Array<ScheduleFileUrl>> => {
  try {
    const { data } = await API.get(`v2/schedules/status/file-upload/${scheduleId}`);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao buscar detalhes da agendamento');
  }
};
