import React, { Fragment, ReactElement, useMemo } from 'react';
import * as S from './weighing-totten-details-confirmation.styled';
import { WeighingTottenMessageModal } from '../weighing-totten-modals.component';
import FooterTotten from 'src/assets/footer-totten.png'


export const WeighingTottenDetailsConfirmationView: React.FC = () => {
  const buildMessageModal = useMemo((): ReactElement => {
    return <WeighingTottenMessageModal />;
  }, []);

  return (
    <Fragment>
      {buildMessageModal}
      <S.Wrapper>
        <S.Footer>
          <img src={FooterTotten} alt="" />
        </S.Footer>
      </S.Wrapper>
    </Fragment>
  );
};
