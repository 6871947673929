import { useCallback, useContext } from 'react';

import { CompaniesContext } from 'pages/company/states/companies/companies.context';
import { listCompaniesService } from 'pages/company/services/list-companies/list-companies.service';
import { ICompanyStatus } from 'domain/company';

const useCompaniesHook = () => {
  const { setCompaniesRequest, setCompaniesErro, setCompaniesSuccess } = useContext(CompaniesContext);

  return useCallback(
    async (searchValue: string, currentPage: number, status: ICompanyStatus | null, order: string, column: string) => {
      setCompaniesRequest();

      try {
        const companiesResponse = await listCompaniesService({
          currentPage: currentPage,
          search: searchValue,
          status: status,
          order: order,
          column: column,
        });
        setCompaniesSuccess(companiesResponse);
      } catch (error) {
        setCompaniesErro('Ocorreu um erro na API, tente novamente');
      }
    },
    [setCompaniesErro, setCompaniesRequest, setCompaniesSuccess],
  );
};

export { useCompaniesHook };
