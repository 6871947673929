import React, { useContext } from 'react';
import { LocationsContextProvider } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.context';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { LocationListPageBody } from './components/location-list-page-body/location-list-page-body.component';

export const LocationListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <InfiniteScrollListTableContextProvider>
        <LocationsContextProvider>
          <LocationListPageBody />
        </LocationsContextProvider>
      </InfiniteScrollListTableContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
