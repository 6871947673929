import { useNavigate } from 'react-router-dom';
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { downloadTemplate } from 'utils/download-file/download-file';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { ClientsContext } from 'src-new/pages/registrations/pages/client/contexts/clients/clients.context';
import { IConfirmDeleteModalProps, IRemoveItemsProps } from './client-list-page-body.types';
import { ClientsListPageBodyView } from './client-list-page-body-view.component';
import { useExportReport } from 'src-new/pages/registrations/hooks/use-export-report/use-export-report.hook';
import { useImportRecordsSpreadsheet } from 'src-new/pages/registrations/hooks/use-import-records-spreadsheet/use-import-records-spreadsheet.hook';
import { useDeleteClients } from '../../hooks/use-delete-clients/use-delete-clients.hook';
import { useGetClientList } from '../../hooks/use-get-client-list/use-get-client-list.hook';
import {
  ExportReportProps,
  IButtonProps,
  UploadSpreadsheetProps,
} from 'src-new/components/application-page-header/application-page-header.types';

export const ClientsListPageBody: React.FC = (): ReactElement => {
  const { clientsList } = useContext(ClientsContext);
  const [pageInfinityScroll, setPageInfinityScroll] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [activeTabName, setActiveTabName] = useState<string>('Ativas');
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [isLoadingExclude, setIsLoadingExclude] = useState<boolean>(false);
  const navigate = useNavigate();
  const deleteClients = useDeleteClients();
  const getClientList = useGetClientList();
  const importClients = useImportRecordsSpreadsheet();
  const generateExcel = useExportReport();
  const { statusRequest } = clientsList;
  const { filters, setFilters } = clientsList.filterActions;

  const goToCreateClient = useCallback(() => {
    navigate('/parceiros/novo');
  }, [navigate]);

  const handleRemoveClient = useCallback(async () => {
    deleteClients({ ids: idsToRemove, setIdsToRemove, setIsLoadingExclude, setOpenConfirmModal });
  }, [deleteClients, idsToRemove]);

  const handleOpenModalRemove = useCallback(() => {
    setOpenConfirmModal(true);
  }, []);

  const handleCloseConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  const handleGenerateExcel = useCallback(async () => {
    setIsLoadingExport(true);
    const file: ArrayBuffer | undefined = await generateExcel(
      filters.searchValue,
      'CLIENT',
      activeTabName === 'Ativas' ? 'ativo' : 'inativo',
    );

    if (file) {
      downloadXlsxFileUtil(file, 'relatório-parceiros', 'xls');
    }
    setIsLoadingExport(false);
  }, [generateExcel, filters.searchValue, activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Unidades.xlsx');
  }, []);

  const handleOpenUploadXlsx = useCallback(async () => {
    setShowModal(true);
  }, []);

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    (formData: FormData) => {
      importClients(formData, setShowModal, 'CLIENT');
    },
    [importClients],
  );

  const handleSearchInput = useCallback(
    (value: string) => {
      setPageInfinityScroll(1);
      setFilters({ ...filters, page: 1, searchValue: value });
    },
    [filters, setFilters],
  );

  const uploadModalProps = useMemo(
    (): UploadSpreadsheetProps => ({
      showModal,
      uploadModalProps: {
        title: 'Cadastro de Parceiros',
        description: 'Selecione o arquivo',
        handleCancel: showModalConfirmation,
        handleSuccess,
        handleDownload,
      },
      handleOpenUploadXlsx: handleOpenUploadXlsx,
    }),
    [handleDownload, handleOpenUploadXlsx, handleSuccess, showModal, showModalConfirmation],
  );

  const exportReportProps = useMemo(
    (): ExportReportProps => ({
      isLoading: isLoadingExport,
      onHandleExport: handleGenerateExcel,
    }),
    [handleGenerateExcel, isLoadingExport],
  );

  const confirmDeleteModalProps = useMemo(
    (): IConfirmDeleteModalProps => ({
      isOpen: openConfirmModal,
      isLoading: isLoadingExclude,
      handleRemoveClient,
      handleCloseConfirmModal,
    }),
    [handleCloseConfirmModal, handleRemoveClient, isLoadingExclude, openConfirmModal],
  );

  const removeItemsProps = useMemo(
    (): IRemoveItemsProps => ({
      label: 'Excluir',
      isVisible: idsToRemove.length > 0,
      isLoading: false,
      setIds: setIdsToRemove,
      handleActionButton: handleOpenModalRemove,
    }),
    [handleOpenModalRemove, idsToRemove.length],
  );

  const createButtonProps = useMemo((): IButtonProps => {
    return {
      isVisible: true,
      isLoading: false,
      handleActionButton: goToCreateClient,
      label: 'Novo Registro',
    };
  }, [goToCreateClient]);

  useEffect(() => {
    if (statusRequest === 'INITIAL') {
      getClientList();
    }
  }, [getClientList, statusRequest]);

  useEffect(() => {
    if (!filters.isPageLimit && pageInfinityScroll !== filters.page) {
      setFilters({ ...filters, page: pageInfinityScroll });
    }
  }, [filters, pageInfinityScroll, setFilters]);

  return (
    <Fragment>
      <ClientsListPageBodyView
        handleSearchInput={handleSearchInput}
        setActiveTabName={setActiveTabName}
        exportReportProps={exportReportProps}
        removeItemsProps={removeItemsProps}
        activeTab={activeTabName}
        handleInfinityScroll={setPageInfinityScroll}
        uploadModalProps={uploadModalProps}
        confirmDeleteModalProps={confirmDeleteModalProps}
        createButtonProps={createButtonProps}
      />
    </Fragment>
  );
};
