import axios from 'axios';

export const convertProfileUrlToFileService = async (url: string): Promise<File> => {
  try {
    const fileName = url.split('?')[0].split('/')[3];
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    return new File([response.data], fileName);
  } catch (error) {
    throw new Error('Houve um erro ao buscar detalhes do Parceiro');
  }
};

export const convertS3PathToImage = (url: string) => {
  return `${import.meta.env.VITE_AWS_S3}${url}`;
};
