import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { deleteNFeDataService } from '../../services/delete-nfe-data/delete-nfe-data.service';
import { getNFeDataService } from '../../services/get-nfe-data/get-nfe-data.service';

export const useDeleteNFeData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(async () => {
    try {
      scheduleDocuments.nfeDataProps.setNFeDataRequest();
      await deleteNFeDataService(scheduleDocuments.modalsProps.modalOptions.id);

      const data = await getNFeDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.nfeDataProps.setNFeDataSuccess(data);
    } catch (error: any) {
      scheduleDocuments.nfeDataProps.setNFeDataError();
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
    scheduleDocuments.modalsProps.setModalOpen({ open: false, id: 0, modalType: '' });
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.modalsProps, scheduleDocuments.nfeDataProps]);
};
