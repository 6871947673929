import { ReactNode } from 'react';

import * as S from './styled';

interface CardResumeRootProps {
  readonly children: ReactNode;
  readonly resumeMap?: boolean;
}

export function CardResumeRoot({ children, resumeMap }: CardResumeRootProps) {
  return <S.RootWrapper resumeMap={resumeMap}>{children}</S.RootWrapper>;
}
