import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IOccurrencesTrackingContext } from './tracking-occurrence.types';
import { occurrencesTrackingReducer } from './tracking-occurrence.reducer';
import { initialState, IOccurrencesTrackingAction } from './tracking-occurrence.actions';

import { ITrackingOccurrence } from 'domain/tracking-occurrence';
import { IListPage } from 'domain/page-info';

interface IOccurrencesTrackingContextProps {
  children: ReactNode;
}

export const OccurrencesTrackingContext = createContext<IOccurrencesTrackingContext>(initialState);

const OccurrencesTrackingContextProvider = ({ children }: IOccurrencesTrackingContextProps) => {
  const [state, dispatch] = useReducer(occurrencesTrackingReducer, initialState);

  const setOccurrencesTrackingRequest = useCallback(() => {
    dispatch({
      type: IOccurrencesTrackingAction.OCCURRENCES_TRACKING_REQUEST,
      payload: null,
    });
  }, []);

  const setOccurrencesTrackingSuccess = useCallback((occurrenceTrackingList: IListPage<ITrackingOccurrence>) => {
    dispatch({
      type: IOccurrencesTrackingAction.OCCURRENCES_TRACKING_SUCCESS,
      payload: occurrenceTrackingList,
    });
  }, []);

  const setOccurrencesTrackingError = useCallback((error: string) => {
    dispatch({
      type: IOccurrencesTrackingAction.OCCURRENCES_TRACKING_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      occurrencesTrackingIsLoading: state.occurrencesTrackingIsLoading,
      occurrencesTracking: state.occurrencesTracking,
      occurrencesTrackingError: state.occurrencesTrackingError,
      occurrencesTrackingPageInfo: state.occurrencesTrackingPageInfo,
      setOccurrencesTrackingRequest,
      setOccurrencesTrackingSuccess,
      setOccurrencesTrackingError,
    };
  }, [
    setOccurrencesTrackingRequest,
    setOccurrencesTrackingSuccess,
    setOccurrencesTrackingError,
    state.occurrencesTrackingIsLoading,
    state.occurrencesTracking,
    state.occurrencesTrackingError,
    state.occurrencesTrackingPageInfo,
  ]);

  return <OccurrencesTrackingContext.Provider value={value}>{children}</OccurrencesTrackingContext.Provider>;
};

export default React.memo(OccurrencesTrackingContextProvider);
