import { driverRegisterService } from 'pages/driver/services/driver-register/driver-register.service';
import { IDriverNewDomain } from 'src-new/pages/registrations/domains/driver.domain';
import { useSaveUploadFile } from 'src-new/pages/registrations/hooks/use-save-upload-file/use-save-upload-file.hook';

const useDriverRegisterHook = () => {
  const saveUploadFile = useSaveUploadFile();

  return async (driver: IDriverNewDomain) => {
    const { id } = await driverRegisterService(driver);

    await saveUploadFile(id, driver.attachments, 'driver');

    return id;
  };
};

export { useDriverRegisterHook };
