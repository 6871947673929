import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { SaleOfferModalMatchHeader } from './components/sale-offer-modal-match-header/sale-offer-modal-match-header.component';
import { SaleOfferModalMatchTableTabs } from './components/sale-offer-modal-match-table-tabs/sale-offer-modal-match-table-tabs.component';
import { SaleOfferModalMatchFooter } from './components/sale-offer-modal-match-footer/sale-offer-modal-match-footer.component';
import * as S from './sale-offer-modal-match-view.styled';
import { ICompany } from 'domain/company';

interface ISaleOfferModalMatchViewProps {
  company: ICompany;
  handleCloseModalMatch: () => void;
}

export const SaleOfferModalMatchView: FC<ISaleOfferModalMatchViewProps> = ({ company, handleCloseModalMatch }) => {
  return (
    <ModalBark size={'lg'} title={`Proposta de Venda - ${company.tradeName}`} handleClose={handleCloseModalMatch}>
      <S.Wrapper>
        <SaleOfferModalMatchHeader />
        <SaleOfferModalMatchTableTabs />
        <SaleOfferModalMatchFooter />
      </S.Wrapper>
    </ModalBark>
  );
};
