import React from 'react';

import ScheduleCargoDetailsForm from '../schedule-cargo-details-form/schedule-cargo-details-form';

import { IFormSectionProps } from 'pages/schedule-ltl/types/form-section.types';
import ScheduleOriginForm from 'pages/schedule-ltl/components/schedule-origin-form/schedule-origin-form';
import ScheduleDestinyForm from 'pages/schedule/schedule-register/components/schedule-destiny-form/schedule-destiny-form';

export const renderScheduleForm = ({
  errors,
  values,
  handleChange,
  touched,
  setFieldValue,
  apiCall,
  handleChangeValues,
  selectedFreight,
  minDeliveryDate,
  autoSelectedDate,
}: IFormSectionProps): Array<{
  label: string;
  content: React.ReactNode;
  formikErrorKey?: string;
}> => {
  return [
    {
      label: 'Origem',
      formikErrorKey: 'origin',
      content: (
        <ScheduleOriginForm
          {...{
            errors,
            values,
            handleChange,
            touched,
            setFieldValue,
            apiCall,
            handleChangeValues,
            selectedFreight,
          }}
        />
      ),
    },
    {
      label: 'Destino',
      formikErrorKey: 'destiny',
      content: (
        <ScheduleDestinyForm
          {...{
            errors,
            values,
            handleChange,
            touched,
            setFieldValue,
            apiCall,
            handleChangeValues,
          }}
        />
      ),
    },
    {
      label: 'Detalhes da Carga',
      formikErrorKey: 'cargoDetails',
      content: (
        <ScheduleCargoDetailsForm
          {...{
            errors,
            values,
            handleChange,
            touched,
            setFieldValue,
            handleChangeValues,
            minDeliveryDate,
            autoSelectedDate,
          }}
        />
      ),
    },
  ];
};
