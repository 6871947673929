import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { searchOriginOrDestiny } from 'pages-v2/schedule/hooks/serch-origin-and-destiny';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';
import { MatchRegisterInput } from 'domain-v2/match/register';
import { OriginAndDestinyTypeOptions } from 'domain/global-inputs';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import AsyncSelectField from 'components/async-select/async-select';

interface ScheduleOriginAndDestinyFormProps {
  type: 'origin' | 'destination';
  valueError: boolean;
}

export const OriginAndDestinyMatchForm: React.FC<ScheduleOriginAndDestinyFormProps> = ({ type, valueError }) => {
  const { values, setFieldValue } = useFormikContext<MatchRegisterInput>();

  const typeMemo = useMemo(() => {
    return type as 'origin' | 'destination';
  }, [type]);

  const typeKeyMemo = useMemo(() => {
    return type === 'origin' ? 'origin' : 'destination';
  }, [type]);

  const handleCompleteInputs = useCallback(
    async (id: number, type: '' | 'CLIENT' | 'LOCALITY') => {
      if ((type === 'CLIENT' || type === 'LOCALITY') && id) {
        try {
          const details = await (type === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          const fieldValuesToUpdate = {
            [`${typeKeyMemo}.id`]: details.id,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue, typeKeyMemo],
  );

  const handleChangeLocations = useCallback(
    (id: number) => {
      handleCompleteInputs(id, values[typeKeyMemo].type);
    },
    [handleCompleteInputs, typeKeyMemo, values],
  );

  return (
    <SectionLayout name={`${typeMemo} information`}>
      <LineSectionLayout columns="0.93fr 3fr 0.07fr">
        <Select
          label={type === 'origin' ? 'Origem *' : 'Destino *'}
          id={`${typeKeyMemo}.type`}
          name={`${typeKeyMemo}.type`}
          value={values[typeKeyMemo].type}
          setFieldValue={setFieldValue}
          options={OriginAndDestinyTypeOptions}
          hasError={valueError && !values[typeKeyMemo].type}
          errorMessage="Campo Obrigatório"
        />

        <AsyncSelectField
          placeholder=""
          label="Informe o Nome *"
          isClearable={true}
          name={`${typeMemo}.tradeName`}
          id={`${typeMemo}.tradeName`}
          disabled={!values[typeKeyMemo].type}
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            handleChangeLocations(e?.value || 0);
            setFieldValue(`${typeKeyMemo}.tradeName`, e?.label);
          }}
          value={{
            label: values[typeKeyMemo].tradeName,
            value: values[typeKeyMemo].id,
          }}
          apiCallFunction={async (e: string) => await searchOriginOrDestiny(e, values[typeKeyMemo].type)}
          loadOptions={async (e: string) => await searchOriginOrDestiny(e, values[typeKeyMemo].type)}
          hasError={valueError && !values[typeKeyMemo].tradeName}
          errorMessage="Campo Obrigatório"
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};
