import { queryClient } from 'services/query-client';
import { allocationAccept } from 'pages/allocation/services/allocation-cancel/allocation-cancel.service';
import { showToast } from 'components/toast/toast';

export const acceptScheduleHook = async (ids: Array<number>) => {
  try {
    await allocationAccept({
      ids,
    });

    showToast({
      message: 'Agendamento aceito com sucesso',
      type: 'success',
    });
    queryClient.invalidateQueries(['scheduleList']);
  } catch (error) {
    showToast({
      message: 'Erro ao atualizar agendamento',
      type: 'error',
    });
  }
};
