/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'utils/API/API';
import { IIdlenessBidding } from 'domain-v2/bidding';
import { ISchedule } from 'domain/scheduling';
import { ScheduleDomain } from 'domain/schedule';
import { PageMeta } from 'domain/page-info';
import { IBidding } from 'domain/bidding';

export interface ListProps {
  vehicleType?: string;
  originCity?: string;
  destinationCity?: string;
  page: number | string;
}

export interface IScheduleList {
  items: Array<ScheduleDomain>;
  meta: PageMeta;
}

const LIMIT_PAGE = 30;

export const scheduleBiddingListService = async (params: ListProps): Promise<IScheduleList> => {
  const { data } = await API.get<IScheduleList>('v2/schedules/bidding/shipping', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
      order: 'DESC',
      column: 'ID',
    },
  });

  return data;
};

export const idlenessBiddingListService = async (params: ListProps): Promise<any> => {
  const { data } = await API.get<any>('v2/idleness/bidding', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
    },
  });

  return data;
};

export const createAllocationBiddingService = async (id: number, scheduleId: number): Promise<ISchedule> => {
  const { data } = await API.put(`v2/bidding/${id}/${scheduleId}`);
  return data;
};

export const getBiddingByScheduleId = async (scheduleId: number): Promise<IBidding[]> => {
  const { data } = await API.get(`v2/bidding/${scheduleId}`);
  return data;
};

export const cancelBiddingService = async (scheduleId: number): Promise<ISchedule> => {
  const { data } = await API.delete(`v2/bidding/${scheduleId}`);
  return data;
};

export const refuseBiddingService = async (biddingId: number): Promise<ISchedule> => {
  const { data } = await API.put(`v2/bidding/refuse/${biddingId}`);
  return data;
};

export const getBiddingByIdlenessId = async (idlenessId: number): Promise<IIdlenessBidding[]> => {
  const { data } = await API.get(`v2/idleness-bidding/${idlenessId}`);
  return data;
};

export const acceptIdlenessBidding = async (id: number, idlenessId: number, body: any) => {
  await API.put(`v2/idleness-bidding/${id}/${idlenessId}`, body);
};

export const discardIdlenessBidding = async (id: number) => {
  await API.delete(`v2/idleness-bidding/${id}/discard-bidding`);
};
