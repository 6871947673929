type StatusEntity = 'active' | 'inactive' | 'deleted';
type StatusDomain = 'ativo' | 'inativo' | 'deletado';

export const convertStatusEntityToDomainMapper = (statusEntity: StatusEntity): StatusDomain => {
  switch (statusEntity) {
    case 'active':
      return 'ativo';
    case 'inactive':
      return 'inativo';
    case 'deleted':
      return 'deletado';
  }
};
