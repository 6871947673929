import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const WrapperSectionClientAddressStyled = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.3fr 1fr;
  grid-gap: 20px;
`;

export const AddressWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const FooterWrapperStyled = styled.div`
  position: absolute;
  bottom: 32px;
`;

export const ContainerHoursStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
`;

export const ContainerDaysStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LabelDaysStyled = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const LabelInAndOutStyled = styled.div`
  font-size: 13px;
  font-weight: 700;
  position: relative;
  bottom: 10px;
  margin-top: 10px;
`;

export const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const LabelSemanalDaysStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerButtonStyled = styled.div`
  margin-top: 23px;
  margin-left: -9px;
`;

export const Textarea = styled.div`
  position: relative;
  top: 15px;
  margin-bottom: 30px;

  textarea {
    white-space: normal;
    overflow: auto;
    max-height: 112px;
  }
`;

export const ContractualPermissionStyled = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(4, 0.3fr) 1fr;
    grid-gap: 5px 20px;
    align-items: flex-end;
  `}
`;

export const ClientBilling = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(4, 0.3fr) 1fr;
    grid-gap: 5px 20px;
    align-items: flex-end;
    margin-bottom: 20px;
  `}
`;

export const CargoInsurance = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: 1fr repeat(2, 0.3fr);
    grid-gap: 5px 20px;
    align-items: flex-end;
  `}
`;

export const WrapperSessions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 0.3fr) 1fr;
  gap: 20px;
`;

export const WrapperSessionsFaturamento = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  margin: 10px 0;
`;

export const WrapperSessionsSeguroCarga = styled.div`
  grid-column-start: 5;
  grid-column-end: 6;
  margin: 10px 0;
`;
