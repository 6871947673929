import { createContext, useMemo, useReducer } from 'react';
import { IHomeContext, IHomeContextProps } from './types/home.types';
import { homeInitialState } from './home.constants';
import { IHomeCards } from './types/home-cards.types';
import { IHomeMatches } from './types/home-matches.types';
import { IHomeBids } from './types/home-bids.types';
import { IHomeModals } from './types/home-modals.types';
import { useHomeCardsContextValues } from './context-values/home-cards/home-cards-values.context';
import { HomeReducer } from './reducers/home.reducer';
import { useHomeMatchesContextValues } from './context-values/home-matches/home-matches-values.context';
import { useHomeBidsContextValues } from './context-values/home-bids/home-bids-values.context';
import { useHomeModalsContextValues } from './context-values/home-modals/home-modals-values.context';

export const HomeContext = createContext<IHomeContext>(homeInitialState);

export const HomeContextProvider = ({ children }: IHomeContextProps) => {
  const [state] = useReducer(HomeReducer, homeInitialState);

  const homeCards: IHomeCards = useHomeCardsContextValues();
  const homeMatches: IHomeMatches = useHomeMatchesContextValues();
  const homeBids: IHomeBids = useHomeBidsContextValues();
  const homeModals: IHomeModals = useHomeModalsContextValues();

  const value: IHomeContext = useMemo(
    (): IHomeContext => ({
      ...state,
      homeCards,
      homeMatches,
      homeBids,
      homeModals,
    }),
    [homeBids, homeCards, homeMatches, homeModals, state],
  );

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};
