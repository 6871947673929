import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getChecklistAnswersService } from '../../services/get-checklist-answer/get-checklist-answer.service';

export const useGetChecklistAnswer = () => {
  const { scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(
    async (id: number) => {
      try {
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerRequest();
        const data = await getChecklistAnswersService(id);
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerSuccess(data);
      } catch (error) {
        scheduleDocuments.checklistAnswerProps.setChecklistAnswerError();

        showMessageFormatted({
          error: error as any,
          type: 'error',
        });
      }
    },
    [scheduleDocuments.checklistAnswerProps],
  );
};
