import { IChatActions, IChatButtonContext } from 'src-new/contexts/chat-button/types/chat-button.types';
import { ChatActionType } from '../chat-button.action';

export const chatButtonReducer = (state: IChatButtonContext, action: IChatActions): IChatButtonContext => {
  switch (action.type) {
    case ChatActionType.CHAT_REQUEST:
      return {
        ...state,
        statusRequest: 'PROCESSING',
        userDetails: {
          photoUrl: '',
          companyName: '',
        },
        chatActions: {
          ...state.chatActions,
          chatId: '',
          name: '',
          isOpen: false,
        },
      };
    case ChatActionType.CHAT_SUCCESS:
      return {
        ...state,
        statusRequest: 'SUCCESS',
        userDetails: action.payload,
        chatActions: {
          ...state.chatActions,
          chatId: '',
          name: '',
          isOpen: false,
        },
      };
    case ChatActionType.CHAT_ERROR:
      return {
        ...state,
        statusRequest: 'ERROR',
        userDetails: {
          photoUrl: '',
          companyName: '',
        },
        chatActions: {
          ...state.chatActions,
          chatId: '',
          name: '',
          isOpen: false,
        },
      };
    case ChatActionType.CHAT_OPEN:
      return {
        ...state,
        chatActions: {
          ...state.chatActions,
          chatId: action.payload.chatId,
          name: action.payload.name,
          flowConversation: action.payload.flowConversation,
          saleOfferCompanyId: action.payload.saleOfferCompanyId,
          buyOfferCompanyId: action.payload.buyOfferCompanyId,
          isOpen: true,
        },
      };
    case ChatActionType.CHAT_CLOSE:
      return {
        ...state,
        chatActions: {
          ...state.chatActions,
          chatId: '',
          isOpen: false,
        },
      };
    case ChatActionType.CHAT_RESET:
      return {
        ...state,
        statusRequest: 'INITIAL',
        userDetails: {
          photoUrl: '',
          companyName: '',
        },
        chatActions: {
          ...state.chatActions,
          chatId: '',
          name: '',
          flowConversation: undefined,
          saleOfferCompanyId: undefined,
          buyOfferCompanyId: undefined,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};
