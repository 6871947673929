import { convertStatusEntityToDomainMapper } from '../convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';
import { ITransportCompanyCreatedEntity } from '../../services/entities/transport-companies.entity';

import { ITransportCompanyCreated } from 'domain/transport-company';

export const convertTransportCompanyCreatedEntityToDomainMapper = (
  entity: ITransportCompanyCreatedEntity,
): ITransportCompanyCreated => ({
  id: entity.id,
  name: entity.name,
  cnpj: entity.cnpj,
  bankAgency: entity.bankAgency,
  bankCC: entity.bankCC,
  bankCode: entity.bankCode,
  bankPix: entity.bankPix,
  lat: entity.lat,
  lng: entity.lng,
  schedulingEmail: entity.schedulingEmail,
  occurrenceEmail: entity.occurrenceEmail,
  status: convertStatusEntityToDomainMapper(entity.status),
  address: entity.address,
  rntrc: String(entity.rntrc),
  internalCode: entity.internalCode,
  userName: entity.userName,
  userEmail: entity.userEmail,
  contacts: entity.contacts,
  company: entity.company,
});
