import API from 'utils/API/API';

interface LocatioReportExcelServiceProps {
  search: string;
}

export const santosPortReportExcelService = async ({
  search,
}: LocatioReportExcelServiceProps): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('schedule-port/export-excel', {
      params: {
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar localidade');
  }
};
