import API from 'utils/API/API';
import { IShippingCompanyResponse } from './get-shipping-company.types';
import { IShippingCompanyDomain } from 'src-new/domains/shipping-company.domain';
import { convertShippingCompanyResponseToDomain } from './mappers/convert-shipping-company-response-to-domain/convert-shipping-company-response-to-domain.mapper';

export const getShippingCompanyService = async (shippingCompanyId: number): Promise<IShippingCompanyDomain> => {
  const { data } = await API.get<IShippingCompanyResponse>(`shipping-company/${shippingCompanyId}`);

  return convertShippingCompanyResponseToDomain(data);
};
