import { useParams } from 'react-router-dom';
import React from 'react';

import ReworkRegisterForm from './rework-register-form/rework-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const ReworkRegisterPage: React.FC = () => {
  const { id } = useParams();

  const pageTitle = !id ? 'Novo Serviço' : 'Atualizar Serviço';

  return (
    <React.Fragment>
      <HeaderPage dataTestId="backhaul" breadcrumbList={['Execução', 'Serviços']} hasBackButton />
      <Title title={pageTitle} hasTrace isForm />
      <ReworkRegisterForm reworkId={Number(id) || undefined} />
    </React.Fragment>
  );
};

export default ReworkRegisterPage;
