import React, { useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { SaleOfferTable } from './components/sale-offer-table/sale-offer-table.component';
import { TabContainerStyled } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-table-tabs/sale-offer-table-tabs-view.styled';

export interface ISaleOfferTableTabsViewProps {
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const SaleOfferTableTabsView: React.FC<ISaleOfferTableTabsViewProps> = ({ activeTab, handleChangeTab }) => {
  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Ativas',
        content: <SaleOfferTable />,
      },
      {
        name: 'Inativas',
        content: <SaleOfferTable />,
      },
    ];
  }, []);

  return (
    <TabContainerStyled>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
    </TabContainerStyled>
  );
};
