import styled, { css } from 'styled-components';

export const WrapperSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  border-radius: 0px;
  padding: 15px;
`;

export const HeaderTitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  p {
    ${({ theme }) => css`
      font-size: 14px;
      font-weight: bold;
      color: ${theme.colors.black};
    `}
  }

  > div {
    &:last-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  margin-bottom: 20px;
  width: 100%;
`;

export const ContainerCTEAndButtonStyled = styled.div``;

export const ContainerButtonStyled = styled.div`
  display: flex;

  button:nth-last-child(2) {
    margin-right: 10px;
  }
`;

export const SectionHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0px;
  margin-bottom: 10px;
`;

export const TitleNFEStyled = styled.p`
  font-size: 12px;
  font-weight: bold;
  align-self: center;
`;

export const NFeItemsContent = styled.div`
  padding-bottom: 10px;
`;

export const NFeItemsContentGenerics = styled.table`
  margin-top -10px;
  display: flex;
  flex-direction: column;
`;

export const NFeItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const NFeItemHeader = styled.th`
  color: #000;
  font-size: 12px;
  font-weight: normal;
  text-align: start;
`;

export const NFeItem = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 0.2fr 0fr;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
`;
