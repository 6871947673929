import React from 'react';
import ClientRegisterForm from './components/client-register-form/client-register-form';
import { ClientRegisterContainerStyled } from './client-register.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const ClientRegisterView: React.FC = () => {
  return (
    <ClientRegisterContainerStyled>
      <HeaderPage breadcrumbList={['Cadastro', 'Parceiros']} hasBackButton />
      <Title title="Cadastro de Parceiro" hasTrace isForm />
      <ClientRegisterForm />
    </ClientRegisterContainerStyled>
  );
};

export default ClientRegisterView;
