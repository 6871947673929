import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ValuesDetails } from '../../../components/transaction-values';

import { transactionInitialValues } from './initial-values';
import { TransactionEditPossibility } from './edit-possibility';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { convertTitleTransaction } from 'pages-v2/schedule/mappers/convert-title-transactions';
import { useGetRetentionFinesByVehicleHook } from 'pages/schedule/hooks/use-get-retention-fines/use-get-retention-fines';
import { TransactionDetails } from 'domain-v2/transactions/details';
import { RetentionFinesFinance } from 'domain-v2/transactions';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { IUser } from 'domain/login';

interface CalculateTransactionsProps {
  user: IUser;
  isAdmin: boolean;
  transactions: Array<TransactionDetails>;
  schedule: any; //TO TYPE
  onRefresh: Dispatch<SetStateAction<number>>;
}

interface TransactionModal {
  index: number;
}

const orderTransactions = ['Pagar', 'Receber', 'Receber Transportador'];

export const CalculateTransactions: FC<CalculateTransactionsProps> = ({
  user,
  isAdmin,
  transactions,
  schedule,
  onRefresh,
}) => {
  const [auditModal, setAuditModal] = useState<ModalState<TransactionModal>>({
    ...initialModalState,
    data: { index: 0 },
  });
  const [calculateModal, setCalculateModal] = useState<ModalState<TransactionModal>>({
    ...initialModalState,
    data: { index: 0 },
  });
  const [retentionFine, setRetentionFine] = useState<RetentionFinesFinance>({} as RetentionFinesFinance);

  const getRetentionFineHook = useGetRetentionFinesByVehicleHook();

  useEffect(() => {
    if (!schedule.company?.id) return;
    (async () => {
      try {
        if (schedule.companyIdBuyer) {
          const buyerRetentionFines = await getRetentionFineHook(
            schedule.scheduleCargo.vehicleType,
            schedule.companyIdBuyer,
          );

          setRetentionFine((prevState) => ({
            ...prevState,
            buyer: {
              dailyFine: buyerRetentionFines.dailyFine,
              freetime: buyerRetentionFines.freeTime,
              hourlyFine: buyerRetentionFines.hourlyFine,
            },
          }));
        }

        const sellerRetentionFines = await getRetentionFineHook(
          schedule.scheduleCargo.vehicleType,
          schedule.company.id,
        );

        setRetentionFine((prevState) => ({
          ...prevState,
          seller: {
            dailyFine: sellerRetentionFines.dailyFine,
            freetime: sellerRetentionFines.freeTime,
            hourlyFine: sellerRetentionFines.hourlyFine,
          },
        }));
      } catch (error: any) {
        showMessageFormatted({
          error: error,
          type: 'error',
        });
      }
    })();
  }, [schedule]);

  const scheduleValidations = useMemo(() => {
    const invoicedByLogshare = schedule.invoicedBy === 'LOGSHARE';

    const isTracking = schedule.operation === 'TRACKING';

    const isExternalBackhaul = schedule.operation === 'BACKHAUL EXTERNO' || schedule.operation === 'BACKHAUL PARCEIRO';

    const isInternalBackhaul = schedule.operation === 'BACKHAUL INTERNO';

    const isSpot = schedule.operation === 'SPOT' || schedule.operation === 'BACKHAUL EXTERNO SPOT';

    const isExternalInvoicedByLogshare =
      user.companyId === schedule.companyIdBuyer && isExternalBackhaul && invoicedByLogshare;

    const isExternalInvoicedByLogshareSeller =
      user.companyId === schedule.companyId && isExternalBackhaul && invoicedByLogshare;

    const isExternalNotInvoicedByLogshareBuyer =
      user.companyId === schedule.companyIdBuyer && isExternalBackhaul && !invoicedByLogshare;

    const isExternalNotInvoicedByLogshareSeller =
      user.companyId === schedule.companyId && isExternalBackhaul && !invoicedByLogshare;

    return {
      isSpot,
      isTracking,
      isInternalBackhaul,
      isExternalBackhaul,
      isExternalInvoicedByLogshare,
      isExternalInvoicedByLogshareSeller,
      isExternalNotInvoicedByLogshareBuyer,
      isExternalNotInvoicedByLogshareSeller,
    };
  }, [schedule]);

  const renderTransactions = useMemo(() => {
    if (!transactions) return <></>;

    const transactionsStatus = transactions.map((transaction) => convertTitleTransaction(user, transaction, schedule));

    const transactionReceive =
      transactions.find((transaction) => transaction.status === 'Receber') || transactionInitialValues;

    const transactionShipping =
      transactions.find((transaction) => transaction.status === 'Receber Transportador') || transactionInitialValues;

    const transactionPayable =
      transactions.find((transaction) => transaction.status === 'Pagar') || transactionInitialValues;

    const newTransactionsArray = transactionsStatus.sort((a, b) => {
      return orderTransactions.indexOf(a.status) - orderTransactions.indexOf(b.status);
    });

    if (scheduleValidations.isTracking && user.profile !== 'shipping-company') {
      const renderTransactions = newTransactionsArray.filter(
        (transaction) => transaction.status === 'Receber Transportador',
      );
      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (user.profile === 'shipping-company') {
      const renderTransactions = newTransactionsArray.filter(
        (transaction) => transaction.status === 'Receber Transportador',
      );

      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (scheduleValidations.isExternalNotInvoicedByLogshareBuyer) {
      const renderTransactions = newTransactionsArray.filter((transaction) => transaction.status === 'Pagar');

      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <ValuesDetails
            key={index}
            title={transaction.title}
            ownerTransaction={transaction.ownerTransaction}
            transactionIndex={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            schedule={schedule}
            retentionFine={retentionFine}
            setCalculateModal={setCalculateModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (scheduleValidations.isExternalNotInvoicedByLogshareSeller) {
      const renderTransactions = newTransactionsArray;
      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (user.companyId === schedule.companyIdBuyer && scheduleValidations.isExternalInvoicedByLogshare) {
      const renderTransactions = newTransactionsArray.filter((transaction) => transaction.status === 'Pagar');

      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <ValuesDetails
            key={index}
            title={transaction.title}
            ownerTransaction={transaction.ownerTransaction}
            transactionIndex={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            schedule={schedule}
            retentionFine={retentionFine}
            setCalculateModal={setCalculateModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (user.companyId === schedule.companyId && scheduleValidations.isExternalInvoicedByLogshareSeller) {
      const renderTransactions = newTransactionsArray.filter((transaction) => transaction.status !== 'Pagar');
      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (isAdmin && scheduleValidations.isExternalBackhaul) {
      const transaction = newTransactionsArray.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (isAdmin && scheduleValidations.isSpot) {
      const renderTransactions = newTransactionsArray.filter((transaction) => transaction.status !== 'Receber');

      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (user.companyId === schedule.companyId && scheduleValidations.isSpot) {
      const renderTransactions = newTransactionsArray.filter((transaction) => transaction.status === 'Pagar');
      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <ValuesDetails
            key={index}
            title={transaction.title}
            ownerTransaction={transaction.ownerTransaction}
            transactionIndex={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            schedule={schedule}
            retentionFine={retentionFine}
            setCalculateModal={setCalculateModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }

    if (scheduleValidations.isInternalBackhaul) {
      const renderTransactions = newTransactionsArray.filter(
        (transaction) => transaction.status !== 'Receber Transportador',
      );
      const transaction = renderTransactions.map((transaction, index: number) => {
        return (
          <TransactionEditPossibility
            key={index}
            index={index}
            user={user}
            isAdmin={isAdmin}
            transaction={transaction}
            transactionPayable={transactionPayable}
            transactionReceive={transactionReceive}
            transactionShipping={transactionShipping}
            schedule={schedule}
            retentionFine={retentionFine}
            calculateModal={calculateModal}
            setCalculateModal={setCalculateModal}
            auditModal={auditModal}
            setAuditModal={setAuditModal}
            onRefresh={onRefresh}
          />
        );
      });

      return transaction;
    }
  }, [transactions, auditModal, calculateModal, retentionFine]);

  return renderTransactions;
};
