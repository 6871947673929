import { LineSectionLayout } from 'components-v2/layout/line-section';
import AsyncSelectField from 'components/async-select/async-select';
import { Button } from 'logshare-ui-kit';
import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';
import { FC, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { ISaleOfferShipperRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import * as S from './sale-offer-register-shipper-form-modal-view.styled';

interface ISaleOfferRegisterShipperFormModalViewProps {
  values: ISaleOfferShipperRegisterPayloadDomain;
  handleChange: (name: keyof ISaleOfferShipperRegisterPayloadDomain, value: any) => void;
  handleOnSubmit: () => void;
  handleCloseRegisterShipperModal: () => void;
}

export const SaleOfferRegisterShipperFormModalView: FC<ISaleOfferRegisterShipperFormModalViewProps> = ({
  values,
  handleChange,
  handleOnSubmit,
  handleCloseRegisterShipperModal,
}) => {
  const buttonDisabled = useMemo(() => {
    return !!values.partnerShipper.id && !!values.initialDate && !!values.finalDate;
  }, [values.finalDate, values.initialDate, values.partnerShipper.id]);

  const handleListCompanyByName = (name: string) => {
    if (name.length >= 1) {
      return listCompanyByName(name);
    }

    return Promise.resolve();
  };

  return (
    <ModalBark
      size={'xsm'}
      title={<S.ModalTitle>Cadastrar Tarifa Exclusiva</S.ModalTitle>}
      handleClose={handleCloseRegisterShipperModal}
    >
      <S.ModalWrapper>
        <SectionForm>
          <LineSectionLayout columns="1fr">
            <AsyncSelectField
              placeholder=""
              label="Selecione o Embarcador"
              isClearable={true}
              name="partnerShipper"
              id="partnerShipper"
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (e) => {
                handleChange('partnerShipper', { id: e && e.value, name: e && e.label });
              }}
              apiCallFunction={handleListCompanyByName}
              loadOptions={handleListCompanyByName}
              menuPosition="fixed"
              defaultValue={{
                label: values.partnerShipper.name,
                value: values.partnerShipper.id,
              }}
            />
          </LineSectionLayout>
        </SectionForm>

        <S.WrapperButtonSubmit>
          <Button
            label="Cadastrar"
            color="pink"
            onPress={handleOnSubmit}
            size="medium"
            variant="solid"
            disabled={!buttonDisabled}
          />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
