/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReworkList, ReworkListParams } from '../types';
import { convertReworkToEntityMapper, detailsMapper, listMapper } from '../mappers';

import API from 'utils/API/API';
import { ScheduleDomain } from 'domain/schedule';
import { IRework } from 'domain/rework';
import { IListPage, PageMeta } from 'domain/page-info';

export interface ISchedulingListEntity {
  items: Array<ScheduleDomain>;
  meta: PageMeta;
}

const LIMIT_PAGE = 50;

export const reworkRegisterService = async (rework: IRework) => {
  const payload = convertReworkToEntityMapper(rework);
  const data = await API.post('v2/rework', payload);
  return data;
};

export const reworkUpdateService = async (id: number, rework: IRework) => {
  const payload = convertReworkToEntityMapper(rework);
  await API.put(`v2/rework/${id}`, payload);
};

export const getReworkByIdService = async (reworkId: number): Promise<IRework> => {
  const { data } = await API.get(`v2/rework/${reworkId}`);
  return detailsMapper(data);
};

export const reworkListV2 = async (params: ReworkListParams): Promise<IListPage<ReworkList>> => {
  const { data } = await API.get<any>('v2/rework/list-v2', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
    },
  });

  return {
    list: data.items.map(listMapper),
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};

export const updateReworkStatusService = async (id: number, newStatus: string) => {
  const payload = { newStatus };
  const { data } = await API.put(`v2/rework/update-status/${id}`, payload);
  return data;
};

export const reworkCancel = async (reworkIds: Array<number>, descriptionCancel?: string): Promise<void> => {
  const payload = {
    ids: reworkIds,
    descriptionCancel: descriptionCancel,
  };
  await API.post('v2/rework/cancel', payload);
};

export const downloadPDF = async (reworkId: number): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.post(
      `/v2/rework/${reworkId}/receipt-pdf`,
      {},
      {
        responseType: 'arraybuffer',
      },
    );
    return data;
  } catch (error) {
    throw new Error('Ocorreu um erro ao fazer o download do PDF');
  }
};

export const downloadOrder = async (reworkId: number): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.post(
      `/v2/rework/${reworkId}/service-order-pdf`,
      {},
      {
        responseType: 'arraybuffer',
      },
    );
    return data;
  } catch (error) {
    throw new Error('Ocorreu um erro ao fazer o download do PDF');
  }
};
