import { IGetTransactionsListRequest } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/get-transactions-list.types';
import { ITransactionsListFilter } from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';

export const convertFiltersDomainToRequest = (filters: ITransactionsListFilter): IGetTransactionsListRequest => {
  return {
    activeTabName: filters.activeTabName,
    column: filters.column,
    deliveryEndDate: filters.deliveryEndDate,
    deliveryInitialDate: filters.deliveryInitialDate,
    financialStatus: filters.financialStatus,
    invoiceStatus: filters.invoiceStatus,
    invoicedBy: filters.invoicedBy,
    limit: filters.limit,
    operationType: filters.operationType,
    order: filters.order,
    page: filters.page,
    search: filters.search,
    taker: filters.taker,
    takerId: filters.takerId,
  };
};
