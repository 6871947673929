import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  padding-top: 10px;
`;

export const ContainerTableStyled = styled.div`
  display: grid;
  width: fit-content;
`;

type FirstInfoContentStyled = { columns: string };
export const FirstInfoContentStyled = styled.div<FirstInfoContentStyled>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid #d9d9d9;
    text-transform: capitalize;
    margin-bottom: 10px;
  `}
`;

export const HeaderStyled = styled.div`
  ${() => css`
    width: 100%;
    position: sticky;
    top: 0;
    border-radius: 3px;
    background-color: rgb(236 236 236);
    margin-bottom: 10px;
  `}
`;

type SecondInfoContentStyled = { columns: string };
export const SecondInfoContentStyled = styled.tr<SecondInfoContentStyled>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 20px;
    padding: 10px;
  `}
`;

export const HeaderLabelStyled = styled.th`
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #5c5c5c;
`;

export const SecondContentStyled = styled.div`
  height: 55vh;
  overflow: scroll;
`;

export const InfoDobleStyled = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoLabelStyled = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: uppercase;
`;

export const InfoButtonLabelStyled = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #7b7b7b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: white;
  margin-bottom: 10px;
`;

export const ContainerButtonStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 10px;
  gap: 5px;

  button {
    height: 35px;
  }
`;

export const NegotiationLink = styled.span`
  color: rgb(9, 22, 50);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
`;

export const TimerContainerStyled = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-left: 5px;
  color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TimerLabelStyled = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: red;
`;

export const TimerIconStyled = styled.span`
  margin-right: 3px;
`;
