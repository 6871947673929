import { comparationHireBackhaulService } from 'pages/hire-backhaul/services/hire-backhaul-comparation/hire-backhaul-comparation.service';
import { IComparationHireBackhaul } from 'domain/hire-backhaul';

const useComparationHireBackhaulHook = () => {
  return async (hireBackhaul: IComparationHireBackhaul) => {
    return await comparationHireBackhaulService(hireBackhaul);
  };
};

export { useComparationHireBackhaulHook };
