import React, { useCallback } from 'react';
import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';
import { SelectItem } from 'pages/choose-company/service/entities/logshare-access';
import { FilterFormView } from './filter-form-view.component';

export const FilterForm: React.FC = () => {
  const apiCallCompany = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await listCompanyByName(searchValue);
    return [];
  }, []);

  return <FilterFormView apiCallCompany={apiCallCompany} />;
};
