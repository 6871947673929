import { IFormScheduleLTLInitialState } from 'pages/schedule-ltl/types/form-section.types';

export const initialStateSTLRegister: IFormScheduleLTLInitialState = {
  origin: {
    tradingName: '',
    cnpj: '',
    clientType: undefined,
    localityType: undefined,
    zipCode: '',
    street: '',
    neighborhood: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    clientId: undefined,
    localityId: undefined,
  },
  destiny: {
    tradingName: '',
    cnpj: '',
    clientType: undefined,
    localityType: undefined,
    zipCode: '',
    street: '',
    neighborhood: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    clientId: undefined,
    localityId: undefined,
  },
  cargoDetails: {
    id: undefined,
    weight: undefined,
    cubage: '0',
    capacity: '0',
    shippingValue: '',
    inPalet: 'yes',
    pallet: 6,
    invoice: '',
    orderNumber: '',
    pickUpDate: '',
    deliveryDate: '',
    shipmentNumber: '',
    password: '',
  },
};
