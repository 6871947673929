import React, { Fragment, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Button, Tag } from 'logshare-ui-kit';
import {
  ButtonContainerStyled,
  LabelContainerStyled,
} from 'src-new/pages/registrations/components/contact-details-section/components/contact-details-table/contact-details-table-view.styled';
import { Trash2 } from 'lucide-react';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';
import { ColorsV2Type } from 'styles/types/colors';
import { StatusType, StatusTypeMap } from './risk-management-table.types';

interface IRiskManagementTableViewProps {
  riskManagers: Array<IRiskManagementDomain>;
  removeItem: (index: number) => void;
}

export const RiskManagementTableView: React.FC<IRiskManagementTableViewProps> = ({ riskManagers, removeItem }) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'Gerenciadora' },
      { value: 'Registro da Consulta' },
      { value: 'Consultado Por' },
      { value: 'Data da Consulta' },
      { value: 'Data de Validade' },
      { value: 'Status' },
      { value: '' },
    ];

    const columnsConfig = '1fr 0.4fr 0.4fr 0.4fr 0.5fr 0.4fr 0.1fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderButton = useCallback(
    (index: number) => {
      return (
        <ButtonContainerStyled>
          <Button
            label=""
            color="white"
            onPress={() => removeItem(index)}
            LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
            size="x-sm"
            variant="ghost"
          />
        </ButtonContainerStyled>
      );
    },
    [removeItem],
  );

  const renderFormattedDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }, []);

  const isPastDate = useCallback((dateString: string): boolean => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);

    return inputDate < currentDate;
  }, []);

  const getStatusTag = useCallback(
    (statusValue: string, dateString: string): StatusType => {
      if (statusValue === 'APROVADO' && isPastDate(dateString)) {
        return {
          label: 'VENCIDO',
          color: ColorsV2Type.GrayDark,
        };
      }

      const status: StatusTypeMap = {
        APROVADO: {
          label: 'APROVADO',
          color: ColorsV2Type.Blue,
        },
        REPROVADO: {
          label: 'REPROVADO',
          color: ColorsV2Type.Red,
        },
      };

      return status[statusValue];
    },
    [isPastDate],
  );

  const renderStatusTag = useCallback(
    (status: string, dateString: string) => {
      const tag = getStatusTag(status.toUpperCase(), dateString);

      return <Tag label={tag.label} color={tag.color} variant="ghost" display="flex" size="md" />;
    },
    [getStatusTag],
  );

  const renderRows = useMemo((): Array<IRow> => {
    return riskManagers.map((riskManager, index) => {
      return {
        key: `${index}-${riskManager.gr.toLowerCase()}`,
        onClick: () => undefined,
        valuesColumns: [
          { value: riskManager.gr },
          { value: riskManager.consultationRecord },
          { value: <LabelContainerStyled>{riskManager.consultedBy}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderFormattedDate(riskManager.registerDate)}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderFormattedDate(riskManager.validateDate)}</LabelContainerStyled> },
          {
            value: (
              <LabelContainerStyled>
                {renderStatusTag(riskManager.status, riskManager.validateDate)}
              </LabelContainerStyled>
            ),
          },
          { value: renderButton(index) },
        ],
      };
    });
  }, [riskManagers, renderFormattedDate, renderStatusTag, renderButton]);

  if (!riskManagers.length) {
    return <EmptyState subTitle="Nenhum gerenciadora de risco foi cadastrada." height={'100px'} hideIcon />;
  }

  return (
    <Fragment>
      <Table
        alignLinesCenter
        columnFontBold
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={renderRows}
        height={'auto'}
      />
    </Fragment>
  );
};
