import { ISchedulingsLTLContext } from './schedulings-ltl.types';

export const initialState: ISchedulingsLTLContext = {
  schedulingsIsLoading: false,
  schedulings: [],
  schedulingsError: undefined,
  schedulingsPageInfo: undefined,
  statusCode: [],
  setSchedulingsRequest: () => undefined,
  setSchedulingsSuccess: () => undefined,
  setSchedulingsError: () => undefined,
};

export enum ISchedulingsAction {
  SCHEDULINGS_REQUEST = 'SCHEDULINGS_REQUEST',
  SCHEDULINGS_SUCCESS = 'SCHEDULINGS_SUCCESS',
  SCHEDULINGS_ERROR = 'SCHEDULINGS_ERROR',
}
