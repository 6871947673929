import React, { ReactElement, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { IBidOfferDomain } from 'src-new/pages/home/pages/home/domains/home-bids/bid-offer.domain';
import { ICell, IInfiniteScrollTable, IRow } from 'src-new/components/table/table.types';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { Button } from 'logshare-ui-kit';
import BiddingIcon from 'assets/icons/bidding-icon';
import { IModalBiddingOffer } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer.types';
import { ModalBiddingOffer } from 'src-new/components/modal-bidding-offer/modal-bidding-offer.component';
import { TimerCountdown } from 'src-new/pages/home/pages/home/components/home-bid-offers/components/home-bid-offers-table/components/timer-countdown/timer-countdown.component';
import * as S from './home-bid-offers-table-view.styled';

interface IHomeBidOffersTableViewProps {
  bidOffersList: Array<IBidOfferDomain>;
  infiniteScrollProps: IInfiniteScrollTable;
  formatDateTime: (dateValue: string) => { formattedDate: string; formattedTime: string };
  handleOpenModal: (id: number) => void;
  handleCloseModal: () => void;
  saleOfferMatch?: IModalBiddingOffer;
}

export const HomeBidOffersTableView: React.FC<IHomeBidOffersTableViewProps> = ({
  bidOffersList,
  infiniteScrollProps,
  formatDateTime,
  handleOpenModal,
  handleCloseModal,
  saleOfferMatch,
}) => {
  const renderModal = useMemo((): ReactElement | undefined => {
    if (saleOfferMatch) {
      return <ModalBiddingOffer biddingOffer={saleOfferMatch} handleClose={handleCloseModal} />;
    }
  }, [handleCloseModal, saleOfferMatch]);

  const tableColumns = useMemo((): Array<ICell> => {
    return [
      { value: 'ID' },
      { value: 'Origem/Destino' },
      { value: 'Carga' },
      { value: 'Descarga' },
      { value: 'Tempo Restante' },
      { value: '' },
    ];
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return bidOffersList.map((offer) => {
      return {
        key: offer.id,
        onClick: () => null,
        valuesColumns: [
          {
            value: (
              <S.ValueContainerWrapper>
                <S.ValueContainer fontWeight={700}>{offer.id}</S.ValueContainer>
              </S.ValueContainerWrapper>
            ),
          },
          {
            value: (
              <S.LabelWrapper>
                <S.ValueContainer fontWeight={700}>
                  {`${offer.origin.city.toUpperCase()} (${
                    offer.origin.uf
                  }) - ${offer.destination.city.toUpperCase()} (${offer.destination.uf})`}
                </S.ValueContainer>
                <S.ValueContainer color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  {offer.vehicle.type} - {offer.vehicle.category}
                </S.ValueContainer>
              </S.LabelWrapper>
            ),
          },
          {
            value: (
              <S.ValueContainerWrapper>
                <S.ValueContainer fontWeight={700}>
                  {formatDateTime(offer.detail.levyInitialDate).formattedDate}
                </S.ValueContainer>
                <S.ValueContainer color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  {formatDateTime(offer.detail.levyInitialDate).formattedTime}
                </S.ValueContainer>
              </S.ValueContainerWrapper>
            ),
          },
          {
            value: (
              <S.ValueContainerWrapper>
                <S.ValueContainer fontWeight={700}>
                  {formatDateTime(offer.detail.deliveryInitialDate).formattedDate}
                </S.ValueContainer>
                <S.ValueContainer color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  {formatDateTime(offer.detail.deliveryInitialDate).formattedTime}
                </S.ValueContainer>
              </S.ValueContainerWrapper>
            ),
          },
          {
            value: <TimerCountdown timer={offer.expirationTime} />,
          },
          {
            value: (
              <S.ButtonContainer>
                <Button
                  label=""
                  color={ThemeColorsV2Type.Red}
                  size="x-sm"
                  variant="solid"
                  onPress={() => handleOpenModal(offer.id)}
                  LeftIcon={<BiddingIcon />}
                />
              </S.ButtonContainer>
            ),
          },
        ],
      };
    });
  }, [bidOffersList, formatDateTime, handleOpenModal]);

  return (
    <S.Wrapper>
      <Table
        gridColumns="0.7fr 3.5fr 1.3fr 1.3fr 1.8fr 0.7fr"
        header={tableColumns}
        rows={renderRows}
        height={'calc(100vh - 460px)'}
        infiniteScrollProps={infiniteScrollProps}
      />
      {renderModal}
    </S.Wrapper>
  );
};
