import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { TransactionsContext } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { getTransactionReportService } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transaction-report/get-transaction-report.service';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { convertFiltersDomainToRequest } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/mappers/convert-filters-domain-to-request/convert-filters-domain-to-request.mapper';
import { showToast } from 'components/toast/toast';

export const useDownloadTransactionReport = () => {
  const { transactionsList } = useContext(TransactionsContext);
  const { filterActions } = transactionsList;
  const { filters } = filterActions;

  return useCallback(
    async (setLoading: Dispatch<SetStateAction<boolean>>) => {
      try {
        const file: ArrayBuffer | undefined = await getTransactionReportService(convertFiltersDomainToRequest(filters));

        if (file) {
          downloadXlsxFileUtil(file, 'relatório-transações');
        }

        showToast({
          message: 'Relatório gerado com sucesso.',
          type: 'success',
        });
      } catch (error) {
        showToast({
          message: 'Erro ao tentar exportar relatório.',
          type: 'error',
        });
      }
      setLoading(false);
    },
    [filters],
  );
};
