import { TransactionDetails } from 'domain-v2/transactions/details';
import { IUser } from 'domain/login';

const getTransactionTitle = (
  user: IUser,
  transaction: TransactionDetails,
  schedule: any,
): { title: string; owner: string } => {
  const invoicedByLogshare = schedule.invoicedBy === 'LOGSHARE';

  const isTracking = schedule.operation === 'TRACKING';

  const isSpot = schedule.operation === 'SPOT' || schedule.operation === 'BACKHAUL EXTERNO SPOT';

  const externalBackhaul = schedule.operation === 'BACKHAUL EXTERNO' || schedule.operation === 'BACKHAUL PARCEIRO';

  const internalBackhaul = schedule.operation === 'BACKHAUL INTERNO';

  const seller = schedule.company.tradeName?.toUpperCase();
  const buyer = schedule.companyBuyer?.tradeName?.toUpperCase();
  const shippingCompany = schedule?.shippingCompany?.name?.toUpperCase();
  const logshare = 'LOGSHARE';

  // LINHA 01 - BACKHAUL EXTERNO OU PARCEIRO | FATURADO EMBARCADOR
  if (user.profile === 'logshare' && transaction.status === 'Pagar' && !invoicedByLogshare && externalBackhaul) {
    return { title: 'Valor que Comprador Paga para o Vendedor', owner: '' };
  }

  if (
    user.companyId === schedule.companyIdBuyer &&
    transaction.status === 'Pagar' &&
    !invoicedByLogshare &&
    externalBackhaul
  ) {
    return { title: 'Valor a Pagar', owner: `para ${seller}` };
  }

  if (
    user.companyId === schedule.companyId &&
    transaction.status === 'Pagar' &&
    !invoicedByLogshare &&
    externalBackhaul
  ) {
    return { title: 'Valor a Receber', owner: `de ${buyer}` };
  }

  // LINHA 02 - BACKHAUL EXTERNO OU PARCEIRO | FATURADO EMBARCADOR
  if (user.profile === 'logshare' && transaction.status === 'Receber' && !invoicedByLogshare && externalBackhaul) {
    return { title: 'Valor a Receber do Vendedor', owner: '' };
  }

  if (
    user.companyId === schedule.companyId &&
    transaction.status === 'Receber' &&
    !invoicedByLogshare &&
    externalBackhaul
  ) {
    return { title: 'Valor a Pagar', owner: 'para Logshare' };
  }

  // LINHA 01 - BACKHAUL EXTERNO OU PARCEIRO | FATURADO LOGSHARE
  if (user.profile === 'logshare' && transaction.status === 'Pagar' && invoicedByLogshare && externalBackhaul) {
    return { title: 'Valor a Receber', owner: `de ${buyer}` };
  }

  if (
    user.companyId === schedule.companyIdBuyer &&
    transaction.status === 'Pagar' &&
    invoicedByLogshare &&
    externalBackhaul
  ) {
    return { title: 'Valor a Pagar', owner: `para ${seller} via ${logshare}` };
  }

  // LINHA 02 - BACKHAUL EXTERNO OU PARCEIRO | FATURADO LOGSHARE
  if (user.profile === 'logshare' && transaction.status === 'Receber' && invoicedByLogshare && externalBackhaul) {
    return { title: 'Valor a Pagar', owner: `para ${seller}` };
  }

  // if (
  //   user.companyId === schedule.companyId &&
  //   transaction.status === 'Pagar' &&
  //   invoicedByLogshare &&
  //   externalBackhaul
  // ) {
  //   return { title: 'Valor a Receber', owner: '' };
  // }

  if (
    user.companyId === schedule.companyId &&
    transaction.status === 'Receber' &&
    invoicedByLogshare &&
    externalBackhaul
  ) {
    return { title: 'Valor a Receber', owner: `de ${buyer} via ${logshare}` };
  }

  // LINHA 01 - SPOT
  if (user.profile === 'logshare' && transaction.status === 'Pagar' && isSpot) {
    return { title: 'Valor a Receber', owner: `de ${seller}` };
  }

  if (user.companyId === schedule.companyId && transaction.status === 'Pagar' && isSpot) {
    return { title: 'Valor a Pagar', owner: `para ${logshare}` };
  }

  // LINHA 03 - SPOT
  if (user.profile === 'logshare' && transaction.status === 'Receber Transportador' && isSpot) {
    return { title: 'Valor a Pagar', owner: `para ${shippingCompany} ` };
  }

  if (user.profile === 'shipping-company' && transaction.status === 'Receber Transportador' && isSpot) {
    return { title: 'Valor a Receber', owner: `de ${seller} via ${logshare}` };
  }

  // LINHA 01 - BACKHAUL INTERNO
  if (transaction.status === 'Pagar' && internalBackhaul) {
    return { title: 'Valor do Frete', owner: seller };
  }

  if (user.companyId === schedule.companyId && transaction.status === 'Receber' && internalBackhaul) {
    return { title: 'Valor a Pagar', owner: `para ${seller}` };
  }

  if (user.profile === 'logshare' && transaction.status === 'Receber' && internalBackhaul) {
    return { title: 'Valor a Receber', owner: `de ${seller}` };
  }

  // LINHA 01 - TRACKING
  if (isTracking && user.profile === 'logshare' && transaction.status === 'Pagar') {
    return { title: 'Valor a Receber', owner: `de ${seller}` };
  }

  // LINHA 03 - TRACKING
  if (isTracking && user.profile === 'logshare' && transaction.status === 'Receber Transportador') {
    return { title: 'Valor a Pagar', owner: `para ${shippingCompany} ` };
  }

  if (isTracking && user.companyId === schedule.companyId && transaction.status === 'Receber Transportador') {
    return { title: 'Valor a Pagar', owner: `para ${shippingCompany} ` };
  }

  // LINHA 03 - TRANSPORTADORA
  if (user.profile === 'shipping-company') {
    return { title: 'Valor a Receber', owner: `de ${seller}` };
  }

  if (invoicedByLogshare && externalBackhaul && transaction.status === 'Receber Transportador') {
    return { title: 'Valor a Pagar', owner: `para ${shippingCompany} via ${logshare}` };
  }

  return { title: 'Valor a Pagar', owner: `para ${shippingCompany}` };
};

export const convertTitleTransaction = (user: IUser, transaction: TransactionDetails, schedule: any) => {
  return {
    ...transaction,
    title: getTransactionTitle(user, transaction, schedule).title,
    ownerTransaction: getTransactionTitle(user, transaction, schedule).owner,
  };
};
