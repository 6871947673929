import { ILocationEntity } from '../entities/location.entity';

import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/location/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { convertLocationCreatedEntityToDomainMapperV2 } from 'pages/location/mappers/convert-location-created-entity-to-domain-v2/convert-location-created-entity-to-domain.mapper';
import { IListPage, PageInfo } from 'domain/page-info';
import { ILocationV2, ILocationStatus } from 'domain/location';
export interface ILocationListProps {
  search: string;
  page: number;
  status?: ILocationStatus;
  order: string;
  column: string;
}

export interface ILocationListEntity {
  list: Array<ILocationEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const locationListService = async ({
  page,
  search,
  status,
  order,
  column,
}: ILocationListProps): Promise<IListPage<ILocationV2>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/localities?&search=${search}&status=${parsedStatus}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search=&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }

    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertLocationCreatedEntityToDomainMapperV2),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar transportadora');
  }
};

export const locationAll = async () => {
  try {
    const url = 'v2/localities/map-listing';
    const { data } = await API.get(url);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar transportadora');
  }
};
