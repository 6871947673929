import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useQueryClient } from 'react-query';
import {
  removeSaleOfferTariffService,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/services/remove-sale-offer-tariff/remove-sale-offer-tariff.service';

export const useSaleOfferTariffRemove = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (tariffId: number) => {
      try {
        const removeTariff = removeSaleOfferTariffService(tariffId);
        await Promise.all([removeTariff]);


        showMessageFormatted({
          message: 'Tarifa excluida com sucesso.',
          type: 'success',
        });

        reactQueryClient.invalidateQueries(['sale-offer-details-by-id']);
        reactQueryClient.invalidateQueries(['sale-offer-list-table']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
