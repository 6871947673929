import React, { Fragment } from 'react';
import { FormikProps, withFormik } from 'formik';

import { FooterStyled, LineSectionStyled, SectionStyled } from './form-my-account.styled';

import { IMyAccount } from 'domain/my-account';
import { FooterPage } from 'components-v2/common/footer-page';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';

interface MyAccountFormViewProps {
  myAccountValues: IMyAccount;
  handleGoBack: () => void;
  handleRegister: (values: IMyAccount) => void;
  schemaValidation: object;
  isLoading: boolean;
}

const FormMyAccountView: React.FC<MyAccountFormViewProps> = ({
  myAccountValues,
  handleGoBack,
  handleRegister,
  schemaValidation,
  isLoading,
}) => {
  const InnerForm = (props: FormikProps<IMyAccount>) => {
    const { values, handleChange, handleSubmit, errors, touched } = props;

    return (
      <Fragment>
        <SectionStyled name="teste">
          <LineSectionStyled columns="1.5fr 1fr">
            <InputField
              label="Nome"
              id="name"
              name="name"
              type="text"
              onChange={handleChange}
              value={values.name}
              hasError={!!errors.name && !!touched.name}
              errorMessage={String(errors.name)}
            />
            <InputField
              label="Login"
              id="login"
              name="login"
              type="text"
              onChange={handleChange}
              value={values.login}
              hasError={!!errors.login && !!touched.login}
              errorMessage={String(errors.login)}
              disabled
            />
          </LineSectionStyled>
          <LineSectionStyled columns="2fr 1fr 1fr">
            <InputField
              label="E-mail"
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              hasError={!!errors.email && !!touched.email}
              errorMessage={String(errors.email)}
              upperCase={false}
            />
            <div style={{ marginTop: 2.5 }}>
              <InputFieldPhone
                label="Telefone"
                id="telephone"
                name="telephone"
                type="text"
                onChange={handleChange}
                value={values.telephone}
                hasError={!!errors.telephone && !!touched.telephone}
                errorMessage={String(errors.telephone)}
              />
            </div>
            <div style={{ marginTop: 2.5 }}>
              <InputFieldPhone
                label="WhatsApp"
                id="celphone"
                name="celphone"
                type="text"
                onChange={handleChange}
                value={values.celphone}
                hasError={!!errors.celphone && !!touched.celphone}
                errorMessage={String(errors.celphone)}
              />
            </div>
          </LineSectionStyled>
        </SectionStyled>
        <FooterStyled>
          <FooterPage.Root>
            <FooterPage.RightContent>
              <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleSubmit} />
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
            </FooterPage.RightContent>
          </FooterPage.Root>
        </FooterStyled>
      </Fragment>
    );
  };

  const MyAccountInformationForm = withFormik<IMyAccount, IMyAccount>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <MyAccountInformationForm {...myAccountValues} />;
};

export default FormMyAccountView;
