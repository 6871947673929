import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  background-color: #05050581;
  z-index: 2;
`;

export const Containter = styled.div`
  min-height: 190px;
  max-height: 600px;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: 20px;
    margin-right: 10px;
  `}
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: rgb(111, 111, 116);
  margin-top: 10px;
  margin-right: 10px;
`;

export const ContainerClose = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
`;

export const CloseModal = styled.div`
  font-size: 15px;
`;

export const Content = styled.h2`
  display: flex;
  gap: 20px;
  font-size: 12px;
  margin-top: 5px;
  color: black;
`;
