import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 15px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0px 15px 15px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: rgb(244, 243, 244);
`;

type LineSectionStyledProps = { flex: string };
export const ContainerInfos = styled.div<LineSectionStyledProps>`
  ${({ flex }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: ${flex};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperLabelInfo = styled.div`
  display: flex;
  gap: 8px;
`;

export const Label = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: rgb(123, 123, 123);
`;

export const LabelBold = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const LabelBoldInfo = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 9px;
  font-weight: 700;
  padding-bottom: 5px;
`;
