import moment from 'moment';
import { Tag } from 'logshare-ui-kit';

import { IInvoice } from '../models';

import { convertInvoiceStatusTag } from './invoice-status';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { ConnectedInvoices } from 'domain-v2/invoice/connected';
import { ISchedule } from 'domain/scheduling';
import Checkbox from 'components/checkbox/checkbox';
import Button from 'components/button/button';
import { InvoiceIcon } from 'assets/icons/invoice-icon';

interface FinancialTableListProps {
  item: IInvoice;
  selectInvoincing: (event: React.ChangeEvent<HTMLInputElement>, freight: number[]) => void;
  handleCreatePdf: (connectedInvoices: ConnectedInvoices[], invoiceId: number) => void;
}

export const financialTableListFormat = ({ item, selectInvoincing, handleCreatePdf }: FinancialTableListProps) => {
  const regex = /\/(\d+)\/([^/]+\.pdf)/;
  const match = item.attachment?.match(regex);
  const attachmentName = match && match[2];

  return [
    {
      value: (
        <Checkbox
          id="freight.id"
          name="freight.id"
          value={String(0)}
          onChange={(element) => selectInvoincing(element, [item.id])}
          disabled={item.invoiceStatus === 'Liquidado'}
        />
      ),
      flex: 0.05,
    },
    {
      value: item.id,
      flex: 0.2,
    },
    {
      value: item.taker?.tradeName,
      subtitle: item.taker?.cnpj,
      flex: 0.25,
    },
    {
      value: item.invoicedBy?.tradeName,
      subtitle: item.invoicedBy?.cnpj,
      flex: 0.25,
    },
    {
      value: moment(item.createdAt).format('DD/MM/YYYY'),
      flex: 0.2,
    },

    {
      value: moment(item.dueDate).format('DD/MM/YYYY'),
      flex: 0.2,
    },

    {
      value: convertCurrencyFormat.format(item.totalService),
      flex: 0.2,
    },
    {
      value: (
        <Tag
          label={convertInvoiceStatusTag(item.invoiceStatus).label}
          color={convertInvoiceStatusTag(item.invoiceStatus).color}
          display="flex"
          size="md"
          variant="solid"
        />
      ),
      flex: 0.2,
    },
    {
      value:
        attachmentName !== 'attachment_file.pdf' || !item.attachment ? (
          <Button
            title="Fatura"
            size="very-small"
            callback={() => handleCreatePdf(item.connectedInvoices, item.id)}
            icon={<InvoiceIcon />}
            disabled={!item.attachment}
          />
        ) : (
          <a href={item.attachment} target="_blank" rel="noreferrer">
            <Button title="Fatura" size="very-small" icon={<InvoiceIcon />} />
          </a>
        ),
      flex: 0.1,
    },
    {
      value: item.invoice ? (
        <a href={item.invoice} target="_blank" rel="noreferrer">
          <Button title="NFe" size="very-small" icon={<InvoiceIcon />} />
        </a>
      ) : (
        <Button title="NFe" size="very-small" disabled icon={<InvoiceIcon />} />
      ),
      flex: 0.1,
    },
    {
      value: item.bankSlip ? (
        <a href={item.bankSlip} target="_blank" rel="noreferrer">
          <Button title="Boleto" size="very-small" icon={<InvoiceIcon />} />
        </a>
      ) : (
        <Button title="Boleto" size="very-small" disabled icon={<InvoiceIcon />} />
      ),
      flex: 0.1,
    },
  ];
};

export const invoicingAvailableScheduleTableListFormat = (
  item: ISchedule,
  selectedTransactions?: number[],
  onChange?: (id: number, value: number) => void,
  calcFinance?: any,
) => {
  const origin = () => {
    const city = item?.scheduleClientOrigin?.client?.city ?? item?.scheduleClientOrigin?.locality?.city;

    const uf = item?.scheduleClientOrigin?.client?.uf ?? item?.scheduleClientOrigin?.locality?.uf;

    const name = item?.scheduleClientOrigin?.client?.tradeName ?? item?.scheduleClientOrigin?.locality?.name;
    return { city, uf, name };
  };

  const destiny = () => {
    const city = item?.scheduleClientDestiny?.client?.city ?? item?.scheduleClientDestiny?.locality?.city;

    const uf = item?.scheduleClientDestiny?.client?.uf ?? item?.scheduleClientDestiny?.locality?.uf;

    const name = item?.scheduleClientDestiny?.client?.tradeName ?? item?.scheduleClientDestiny?.locality?.name;
    return { city, uf, name };
  };

  const handleOnChange = () => {
    if (onChange) {
      onChange(calcFinance.id, calcFinance.totalService ?? 0);
    }
  };

  // row.connectedInvoices.map((invoices) => invoices.scheduleId).join(', ');

  const cte = item?.cte
    ?.filter((cte: any) => cte.type === 'PRIMARIO')
    ?.map((cte: any) => cte.key)
    .join(', ');

  const cteSub = item?.cte
    ?.filter((cte: any) => cte.type === 'SUB')
    ?.map((cte: any) => cte.key)
    .join(', ');

  const data = [
    {
      value: item.id,
      flex: 0.25,
    },
    {
      value: moment(item.deliveryDate).format('DD/MM/YYYY'),
      flex: 0.5,
    },
    {
      value: cte,
      flex: 0.8,
    },
    {
      value: cteSub,
      flex: 0.8,
    },
    {
      value: `${origin().city} - ${origin().uf}`,
      subtitle: origin().name ?? 'Não informado',
      flex: 1,
    },
    {
      value: `${destiny().city} - ${destiny().uf}`,
      subtitle: destiny().name ?? 'Não informado',
      flex: 1,
    },
    {
      value: convertCurrencyFormat.format(calcFinance.totalService),
      flex: 0.6,
    },
    {
      value: (
        <Checkbox
          id="freight.id"
          name="freight.id"
          value={String(0)}
          checked={selectedTransactions?.includes(calcFinance.id)}
          onChange={handleOnChange}
        />
      ),
      flex: 0.1,
    },
  ];

  return data;
};
