import React, { ChangeEvent, DragEvent, Fragment, RefObject, useMemo } from 'react';
import * as S from './attach-documento-input-drop-view.styled';
import { FileCheck, FileX } from 'lucide-react';
import { CloudUploadGrayIcon } from 'assets/icons/cloud-upload-gray.icon';

interface IInputDropFileViewProps {
  inputAcceptFiles: string;
  fileInputRef: RefObject<HTMLInputElement>;
  disabled?: boolean;
  allowMultipleFiles?: boolean;
  handleClick: () => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hasError: boolean;
  hasFile: boolean;
}

export const InputDropFileView: React.FC<IInputDropFileViewProps> = ({
  inputAcceptFiles,
  fileInputRef,
  disabled,
  allowMultipleFiles,
  handleClick,
  handleDrop,
  handleDragOver,
  handleFileChange,
  hasError,
  hasFile,
}) => {
  const renderUploadIcon = useMemo(() => {
    if (hasError) {
      return (
        <Fragment>
          <FileX width={50} height={50} style={{ color: 'rgb(250, 48, 48)', marginBottom: '10px', borderStyle: '' }} />
          <S.Text>O arquivo é obrigatório</S.Text>
        </Fragment>
      );
    }

    if (hasFile) {
      return (
        <Fragment>
          <FileCheck width={50} height={50} style={{ color: '#1c6ea4', marginBottom: '10px' }} />
          <S.Text>Arquivo anexado</S.Text>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <CloudUploadGrayIcon />
        <S.Text>Arraste o arquivo</S.Text>
        <S.TextItalic>ou</S.TextItalic>
        <S.Text>Clique para anexar</S.Text>
      </Fragment>
    );
  }, [hasError, hasFile]);

  return (
    <S.Wrapper>
      <S.InputContent
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        hasError={hasError}
        disabled={disabled}
      >
        <input
          type="file"
          accept={inputAcceptFiles}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
          multiple={allowMultipleFiles}
        />
        {renderUploadIcon}
      </S.InputContent>
    </S.Wrapper>
  );
};
