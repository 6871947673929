import { useCallback } from 'react';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { createBiddingService } from 'src-new/services/create-bidding/create-bidding.service';
import { ICreateBidding } from 'src-new/services/create-bidding/create-bidding.types';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useCreateBidding = () => {
  return useCallback(async (values: ICreateBidding) => {
    try {
      await createBiddingService(values);

      showMessageFormatted({
        message: TOAST_MESSAGE.REGISTER,
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        message: error.response.data.message,
        type: 'error',
      });
    }
  }, []);
};
