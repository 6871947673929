import React from 'react';
import { ListSubtitleView } from './list-subtitle-view.component';

interface IListSubtitleProps {
  dataTestId?: string;
  title: string;
  hasTrace?: boolean;
}

export const ListSubtitle: React.FC<IListSubtitleProps> = ({ dataTestId, title, hasTrace }) => {
  return <ListSubtitleView dataTestId={dataTestId} title={title} hasTrace={hasTrace} />;
};
