import React, { ReactElement, useMemo } from 'react';
import * as S from './timer-countdown-view.styled';

export interface ITimeValues {
  days: number;
  hours: string;
  minutes: string;
  seconds: string;
}

interface ITimerCountdownViewProps {
  remainingTime: number;
  timeValues: ITimeValues;
}
export const TimerCountdownView: React.FC<ITimerCountdownViewProps> = ({ remainingTime, timeValues }) => {
  const renderRemainingTime = useMemo((): ReactElement => {
    if (remainingTime > 0) {
      return (
        <S.Div>
          {timeValues.days > 0 && <S.Label>{timeValues.days}&nbsp;dias&nbsp;</S.Label>}
          <S.Label>{timeValues.hours}:</S.Label>
          <S.Label>{timeValues.minutes}:</S.Label>
          <S.Label>{timeValues.seconds}</S.Label>
        </S.Div>
      );
    }

    return <S.Div>Tempo expirado</S.Div>;
  }, [remainingTime, timeValues.days, timeValues.hours, timeValues.minutes, timeValues.seconds]);

  return <S.Wrapper>{renderRemainingTime}</S.Wrapper>;
};
