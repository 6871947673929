/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useMemo } from 'react';

import {
  IconStyled,
  InputStyled,
  InputWrapperStyled,
  LabelStyled,
  MessageErrorStyled,
  WrapperStyled,
} from './input-field.styled';

export type InputFieldProps = {
  id: string;
  name: string;
  value: string;
  type: 'text' | 'password' | 'email' | 'number' | 'file' | 'date';
  label?: string;
  placeholder?: string;
  initialValue?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  paymentStyle?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  passwordVisible?: boolean;
  className?: string;
  upperCase?: boolean;
  onChange: any;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  onPaste: React.ClipboardEventHandler<HTMLInputElement>;
  changePasswordVisible?: () => void;
  hidden?: boolean;
  autoComplete?: string;
  maxLength?: number;
  hasFocusEffect?: boolean;
};

const InputFieldView: React.FC<InputFieldProps> = ({
  id,
  name,
  value,
  type = 'text',
  label = '',
  placeholder = '',
  reactNode,
  reactNodePosition = 'left',
  disabled = false,
  paymentStyle = false,
  hasError = false,
  errorMessage = 'Invalid value',
  passwordVisible = false,
  className = '',
  upperCase = true,
  onChange,
  onBlur,
  onClick,
  onPaste,
  changePasswordVisible,
  hidden = false,
  maxLength,
  autoComplete,
}) => {
  const convertValue = useMemo(() => {
    let valueUpperCase = value;
    if (upperCase && typeof value === 'string') {
      valueUpperCase = value?.toUpperCase();
    }
    return valueUpperCase;
  }, [upperCase, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (upperCase && typeof e.target.value === 'string') {
        e.target.value = e.target.value.toUpperCase();
      }
      onChange(e);
    }
  };

  return (
    <WrapperStyled className={className} hidden={hidden}>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <InputWrapperStyled iconPosition={reactNodePosition} disabled={disabled} hasError={hasError}>
        {!!reactNode && <IconStyled onClick={changePasswordVisible}>{reactNode}</IconStyled>}
        <InputStyled
          maxLength={maxLength}
          id={id}
          name={name}
          value={convertValue}
          placeholder={placeholder}
          type={passwordVisible ? 'text' : type}
          disabled={disabled}
          paymentStyle={paymentStyle}
          onChange={handleChange}
          onBlur={onBlur}
          onClick={onClick}
          onPaste={onPaste}
          autoComplete={autoComplete}
        />
      </InputWrapperStyled>
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};

export default InputFieldView;
