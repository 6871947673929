/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';

import UploadFileView from './upload-file-view';

import { FileListUploaded } from 'pages/freight/types';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface Props {
  index: number;
  file: FileListUploaded;
  register?: boolean;
  onSuccess?: () => void;
  handleDeleteFile: () => void;
}

const UploadFile: FC<Props> = ({ index, file, register, handleDeleteFile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (modalOpen: boolean) => {
    setIsModalOpen(modalOpen);
  };

  return (
    <>
      {isModalOpen && (
        <ModalConfirmation
          secondTitle={
            <>
              <p>Tem certeza que deseja excluir o anexo?</p>
              <p>Essa ação não poderá ser desfeita.</p>
            </>
          }
          title="Exclusão de Anexo"
          size="small"
          type="delete"
          handleCancel={() => setIsModalOpen(false)}
          onConfirmation={() => handleDeleteFile()}
          description={undefined}
        />
      )}

      <UploadFileView index={index} file={file} register={register} handleModal={handleModal} />
    </>
  );
};

export default UploadFile;
