import { IDriversContext } from './drivers.types';

export const initialState: IDriversContext = {
  driversIsLoading: false,
  drivers: [],
  driversError: undefined,
  driversPageInfo: undefined,
  setDriversRequest: () => undefined,
  setDriversSuccess: () => undefined,
  setDriversError: () => undefined,
};

export enum IDriversAction {
  DRIVERS_REQUEST = 'DRIVERS_REQUEST',
  DRIVERS_SUCCESS = 'DRIVERS_SUCCESS',
  DRIVERS_ERROR = 'DRIVERS_ERROR',
}
