import { useCallback, useContext } from 'react';

import { transportCompanyListService } from '../../services/transport-company-list/transport-company-list.service';
import { TransportCompaniesContext } from '../../contexts/table-states/transport-companies.context';

import { Hook } from './use-transport-company-list.types';

const useTransportCompanyList: Hook = () => {
  const { setTransportCompaniesRequest, setTransportCompaniesSuccess, setTransportCompaniesError } =
    useContext(TransportCompaniesContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setTransportCompaniesRequest();

      try {
        const response = await transportCompanyListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setTransportCompaniesSuccess(response);
      } catch (error) {
        setTransportCompaniesError('Ocorreu um erro ao buscar as transportadoras, tente novamente!');
      }
    },
    [setTransportCompaniesError, setTransportCompaniesRequest, setTransportCompaniesSuccess],
  );
};

export { useTransportCompanyList };
