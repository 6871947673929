import { FC, Fragment, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsAllocationVehicleView } from './schedule-details-allocation-vehicle.component-view';
import { useUpdateScheduleHistoryVehicle } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-history-vehicle/use-update-schedule-history-vehicle.hook';
import { useDeleteScheduleHistoryVehicle } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-schedule-history-vehicle/use-delete-schedule-history-vehicle.hook';

export const ScheduleDetailsAllocationVehicle: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleHistoryVehicleHook = useUpdateScheduleHistoryVehicle();
  const deleteScheduleHistoryVehicleHook = useDeleteScheduleHistoryVehicle();

  const handleUpdateVehicleHistory = useCallback(
    async (vehicleId: number) => {
      updateScheduleHistoryVehicleHook({
        driverId: scheduleDetails.scheduleDetails.driverId,
        vehicleId,
        typeUpdate: 'VEICULO',
      });
    },
    [scheduleDetails.scheduleDetails.driverId, updateScheduleHistoryVehicleHook],
  );

  const handleDeleteVehicleHistory = useCallback(
    async (historyId: number) => {
      deleteScheduleHistoryVehicleHook(historyId);
    },
    [deleteScheduleHistoryVehicleHook],
  );

  if (!scheduleDetails.scheduleDetails.vehicle?.id) {
    return <Fragment />;
  }

  return (
    <ScheduleDetailsAllocationVehicleView
      vehicle={scheduleDetails.scheduleDetails.vehicle}
      historyVehicles={scheduleDetails.allocationVehicle.accordionProps.historyList.historyVehicleList}
      handleUpdateVehicleHistory={handleUpdateVehicleHistory}
      handleDeleteVehicleHistory={handleDeleteVehicleHistory}
    />
  );
};
