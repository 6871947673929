import {convertVehicleCategory} from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import {convertVehicleType} from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import {ISaleOfferDomain, ISaleOfferShipperDomain} from '../../../../domains/sale-offer-details.domain';
import {ISaleOfferResponse} from '../../../../services/get-sale-offer-by-id/get-sale-offer-by-id.types';
import {VehicleTypeResponse} from 'src-new/domains/vehicle-type.domain';
import {VehicleCategoryResponse} from 'src-new/domains/vehicle-category.domain';
import {convertOperationTypeToDomain} from 'src-new/utils/convert-operation-type/convert-operation-type.util';

export const converterSaleOfferDetailsResponseToDomain = (
  saleOfferDetailsResponse: ISaleOfferResponse,
): ISaleOfferDomain => {
  const saleOfferDetails: Array<ISaleOfferShipperDomain> = saleOfferDetailsResponse.saleOfferDetails.map(
    (saleOffer) => {
      return {
        id: saleOffer.id,
        partnerShipper: {
          id: saleOffer.patternShipper.id,
          name: saleOffer.patternShipper.name,
        },
        initialDate: saleOffer.initialDate,
        finalDate: saleOffer.finalDate,
        tariff: saleOffer.tariff.map((tariff) => {
          return {
            id: tariff.id,
            shipping: {
              id: tariff.shipping.id,
              name: tariff.shipping.id === 34 ? 'A DEFINIR' : tariff.shipping.name,
            },
            vehicleType: convertVehicleType(tariff.vehicleType.toLowerCase() as VehicleTypeResponse),
            vehicleCategory: convertVehicleCategory(tariff.vehicleCategory.toLowerCase() as VehicleCategoryResponse),
            weight: tariff.weight.toString(),
            pallets: tariff.pallets.toString(),
            cubage: tariff.cubage.toString(),
            frequency: tariff.frequency.toString(),
            invoicedBy: tariff.invoicedBy,
            gris: tariff.gris.toString(),
            advalorem: tariff.advalorem.toString(),
            valueShipping: tariff.valueShipping.toString(),
            valueShipper: tariff.valueShipper.toString(),
            feeLogshare: (tariff.feeLogshare * 100).toString(),
            valueLogshare: tariff.valueLogshare.toString(),
            valueTotal: tariff.valueTotal,
            isActive: tariff.isActive,
            freetime: {
              time: 0,
              dailyFine: 0,
              hourlyFine: 0,
            },
          };
        }),
      };
    },
  );

  return {
    id: saleOfferDetailsResponse.saleOfferId,
    companyId: saleOfferDetailsResponse.companyId,
    origin: {
      id: saleOfferDetailsResponse.origin.id,
      type: saleOfferDetailsResponse.origin.type,
      name: saleOfferDetailsResponse.origin.name,
      cnpj: saleOfferDetailsResponse.origin.cnpj,
      address: {
        city: saleOfferDetailsResponse.origin.address.city,
        uf: saleOfferDetailsResponse.origin.address.uf,
        complement: saleOfferDetailsResponse.origin.address.complement,
        neighborhood: saleOfferDetailsResponse.origin.address.neighborhood,
        numberHouse: saleOfferDetailsResponse.origin.address.numberHouse,
        street: saleOfferDetailsResponse.origin.address.street,
        cep: saleOfferDetailsResponse.origin.address.cep,
      },
    },
    destination: {
      id: saleOfferDetailsResponse.destination.id,
      type: saleOfferDetailsResponse.destination.type,
      name: saleOfferDetailsResponse.destination.name,
      cnpj: saleOfferDetailsResponse.destination.cnpj,
      address: {
        city: saleOfferDetailsResponse.destination.address.city,
        uf: saleOfferDetailsResponse.destination.address.uf,
        complement: saleOfferDetailsResponse.destination.address.complement,
        neighborhood: saleOfferDetailsResponse.destination.address.neighborhood,
        numberHouse: saleOfferDetailsResponse.destination.address.numberHouse,
        street: saleOfferDetailsResponse.destination.address.street,
        cep: saleOfferDetailsResponse.destination.address.cep,
      },
    },
    cargoType: saleOfferDetailsResponse.cargoType,
    prohibitedCargo: (saleOfferDetailsResponse.prohibitedCargo.length === 1 && saleOfferDetailsResponse.prohibitedCargo[0] === '') ? ['Sem Restrição'] : saleOfferDetailsResponse.prohibitedCargo,
    prohibitedOtherCargo: saleOfferDetailsResponse.prohibitedOtherCargo,
    operationType: convertOperationTypeToDomain(saleOfferDetailsResponse.operationType),
    saleOfferDetails: saleOfferDetails,
  };
};
