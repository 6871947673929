import React, { ReactElement } from 'react';
import { SectionDividerView } from './section-divider-view.component';

interface ISectionDividerProps {
  label: string;
  secondItem?: ReactElement;
  hidePin?: boolean;
}

export const SectionDivider: React.FC<ISectionDividerProps> = ({ label, secondItem, hidePin }) => {
  return <SectionDividerView label={label} secondItem={secondItem} hidePin={hidePin} />;
};
