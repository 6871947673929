import React from 'react';
import * as S from './schedule-kanban-list-view.styled';
import { KanbanSection } from './components/kanban-section/kanban-section.component';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { Loading } from 'src-new/components/loading/loading.component';
import { IScheduleKanbanListDomain } from '../../domains/schedule-kanban-list/schedule-kanban.domain';

interface IScheduleKanbanListViewProps {
  kanbanList: IScheduleKanbanListDomain;
  isLoading: boolean;
}

export const ScheduleKanbanListView: React.FC<IScheduleKanbanListViewProps> = ({ kanbanList, isLoading }) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <S.ContainerCards>
      <KanbanSection
        title="Em Alocação"
        color={ThemeColorsV2Type.Blue}
        kanbanListDetails={kanbanList.inAllocation.items}
        totalItems={kanbanList.inAllocation.totalItems}
      />
      <KanbanSection
        title="A Iniciar"
        color={ThemeColorsV2Type.Blue50}
        kanbanListDetails={kanbanList.toStart.items}
        totalItems={kanbanList.toStart.totalItems}
      />
      <KanbanSection
        title="Em Andamento"
        color={ThemeColorsV2Type.Aqua50}
        kanbanListDetails={kanbanList.inProgress.items}
        totalItems={kanbanList.inProgress.totalItems}
      />
      <KanbanSection
        title="Finalizados"
        color={ThemeColorsV2Type.Aqua}
        kanbanListDetails={kanbanList.finished.items}
        totalItems={kanbanList.finished.totalItems}
      />
      <KanbanSection
        title="Cancelados"
        color={ThemeColorsV2Type.Red}
        kanbanListDetails={kanbanList.canceled.items}
        totalItems={kanbanList.canceled.totalItems}
      />
    </S.ContainerCards>
  );
};
