import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import React, { useCallback, useEffect } from 'react';
import { withFormik } from 'formik';

import { RegisterMyRouteValidation } from './validation/schemas-validation';
import { InnerForm } from './inner-form';
import { initialValues } from './initial-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { myRouteDetailsService } from 'pages-v2/my-route/service/accepted-matches-details/accepted-matches-details.service';
import { UseUpdateMyRoute } from 'pages-v2/my-route/hooks/update';
import { UseRegisterMyRoute } from 'pages-v2/my-route/hooks/register';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { Loading } from 'src-new/components/loading/loading.component';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

export const FormMyRoute: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['myRouteGet', id],
    queryFn: () => (id ? myRouteDetailsService(Number(id)) : undefined),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  const handleSubmit = useCallback(async (route: MyRouterRegisterInput) => {
    try {
      if (
        route.planningRoute.find((planning) => planning.allowedVehicles.length === 0 && route.planningRoute.length > 0)
      ) {
        showMessageFormatted({
          message: 'Crie um planejamento de veículos para sua rota antes de salvar.',
          type: 'error',
        });

        return;
      }

      id ? await UseUpdateMyRoute(Number(id), route) : await UseRegisterMyRoute(route);

      if (id) {
        refetch();
      }

      showMessageFormatted({
        message: `${id ? TOAST_MESSAGE.UPDATE : TOAST_MESSAGE.REGISTER}`,
        type: 'success',
      });

      if (!id) {
        navigate(-1);
      }
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, []);

  const FormRegisterMyRouteView = withFormik<MyRouterRegisterInput, MyRouterRegisterInput>({
    validationSchema: RegisterMyRouteValidation,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleSubmit(values);
    },
  })(InnerForm);

  if (isLoading) {
    return <Loading />;
  }

  return <FormRegisterMyRouteView {...(id ? data || initialValues : initialValues)} />;
};
