import { useCallback } from 'react';

import { santosPortDetailService } from 'pages/santos-port/service/santos-port-details/santos-port-details.service';
import { IPortSchedule } from 'domain/santos-port';

const usePortSantosDetailHook = () => {
  return useCallback(async (santosPortCNPJ: string): Promise<IPortSchedule> => {
    const portSantos = await santosPortDetailService(santosPortCNPJ);
    return portSantos;
  }, []);
};

export { usePortSantosDetailHook };
