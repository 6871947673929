import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useState } from 'react';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { saleOfferShipperDetailsInitialValuesDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details-initial-values.domain';
import {
  ISaleOfferDomain,
  ISaleOfferShipperDomain,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { SaleOfferUpdateShipperFormModalView } from './sale-offer-update-shipper-form-modal-view.component';

export const SaleOfferUpdateShipperFormModal: FC = () => {
  const { values, setFieldValue } = useFormikContext<ISaleOfferDomain>();
  const { saleOfferModals } = useContext(SaleOfferContext);
  const [offerShipper, setOfferShipper] = useState<ISaleOfferShipperDomain>(saleOfferShipperDetailsInitialValuesDomain);

  const handleCloseRegisterShipperModal = useCallback(() => {
    saleOfferModals.setModalClose();
  }, [saleOfferModals]);

  const handleChange = useCallback(
    (name: keyof ISaleOfferShipperDomain, value: any) => {
      setOfferShipper((prevOfferShipper) => ({
        ...prevOfferShipper,
        [name]: value,
      }));
    },
    [setOfferShipper],
  );

  const handleAddOfferShipper = useCallback(() => {
    setFieldValue('saleOfferDetails', [...values.saleOfferDetails, offerShipper]);
    handleCloseRegisterShipperModal();
  }, [handleCloseRegisterShipperModal, offerShipper, setFieldValue, values.saleOfferDetails]);

  return (
    <SaleOfferUpdateShipperFormModalView
      values={offerShipper}
      handleChange={handleChange}
      handleOnSubmit={handleAddOfferShipper}
      handleCloseRegisterShipperModal={handleCloseRegisterShipperModal}
    />
  );
};
