import { FC } from 'react';
import * as S from './weighing-page-body-view.styled';
import { WeighingTableTabs } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-table-tabs/weighing-table-tabs.component';
import { WeighingHeader } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-page-body/components/weighing-header/weighing-header.component';

export const WeighingPageBodyView: FC = () => {
  return (
    <S.Wrapper>
      <WeighingHeader />
      <S.Content>
        <WeighingTableTabs />
      </S.Content>
    </S.Wrapper>
  );
};
