export interface IFreeTimeDomain {
  freeTime: number;
  dailyFine: number;
  hourlyFine: number;
}

export const freeTimeInitialValuesDomain: IFreeTimeDomain = {
  freeTime: 0,
  dailyFine: 0,
  hourlyFine: 0,
};
