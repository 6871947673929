import React, { Fragment } from 'react';

import { AccessRestrictedStyled, ContainerAccessStyled } from './quotes-list-body-view.styled';

import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const dataTestId = 'occurrence-tracking-list-body-view';

const QuotesListBodyView: React.FC = (): JSX.Element => {
  return (
    <Fragment>
      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Minhas Rotas', 'Bidding de Frete']} />
      <Title dataTestId={dataTestId} title="Acompanhe aqui o status das ofertas de frete realizadas." hasTrace />
      <ContainerAccessStyled>
        <AccessRestrictedStyled>Acesso Restrito</AccessRestrictedStyled>
      </ContainerAccessStyled>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Nova Cotação" color="pink" onPress={() => null} />
          <FooterPage.Button label="Excluir" color="red" onPress={() => null} />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default QuotesListBodyView;
