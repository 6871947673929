import React, { FC, ReactElement, useMemo } from 'react';
import * as S from './matches-page-body-view.styled';
import { ListSubtitle } from 'src-new/components/application-page-header/components/list-subtitle/list-subtitle.component';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import LogoIcon from 'assets/logos/logo-icon';
import { MatchesBreadcrumbEnum } from 'src-new/pages/opportunities/pages/matches/utils/breadcrumbs/breadcrumbs-pages.types';
import { BreadcrumbBranches } from 'src-new/pages/opportunities/pages/matches/utils/breadcrumbs/breadcrumbs-pages.util';
import { Breadcrumb } from 'src-new/components/application-page-header/components/header-page/components/breadcrumb/breadcrumb.component';
import { Search } from 'src-new/components/application-page-header/components/search/search.component';
import { MatchesModalType } from 'src-new/pages/opportunities/pages/matches/contexts/matches/types/matches-kanban.types';
import { Button } from 'logshare-ui-kit';
import { HeaderModals } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/components/matches-page-body/components/header-modals/header-modals.component';
import { MatchesKanban } from '../matches-kanban/matches-kanban.component';
import { IParamsFilter } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.types';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';

interface IMatchesPageBodyView {
  hasCheck?: boolean;
  isShippingCompany: boolean;
  matchesFilters: Array<IParamsFilter>;
  appliedFilters: number;
  handleChangeSearch: (search: string) => void;
  removeMatcheFilter: (indexToRemove: number) => void;
  handleModalOpen: (modalType: MatchesModalType) => void;
}

export const MatchesPageBodyView: FC<IMatchesPageBodyView> = ({
  hasCheck = false,
  isShippingCompany,
  matchesFilters,
  appliedFilters,
  handleChangeSearch,
  removeMatcheFilter,
  handleModalOpen,
}) => {
  const renderHeader = useMemo((): ReactElement => {
    if (hasCheck) {
      return (
        <S.HeaderWrapper>
          <Breadcrumb breadcrumb={BreadcrumbBranches()[MatchesBreadcrumbEnum.MATCHES_LIST]} separator={': :'} />

          <S.WrapperButtonsAndIcon>
            <S.WrapperLogo>
              <LogoIcon />
            </S.WrapperLogo>
          </S.WrapperButtonsAndIcon>
        </S.HeaderWrapper>
      );
    }

    return <HeaderPage breadcrumbList={BreadcrumbBranches()[MatchesBreadcrumbEnum.MATCHES_LIST]} />;
  }, [hasCheck]);

  const renderContent = useMemo((): ReactElement => {
    return <MatchesKanban />;
  }, []);

  const renderNewFreightButton = useMemo((): ReactElement | undefined => {
    if (!isShippingCompany) {
      return (
        <Button
          label="Novo Frete"
          size="md"
          color="blue"
          onPress={() => handleModalOpen('newFreight')}
          variant="solid"
        />
      );
    }
  }, [handleModalOpen, isShippingCompany]);

  return (
    <S.Wrapper>
      <HeaderModals />
      <S.Header>
        {renderHeader}
        <ListSubtitle title="Acompanhe a evolução e resultados dos matches encontrados." />
      </S.Header>
      <S.ContainerSearch>
        <Search
          placeholder="Pesquise pelo ID da Oferta ou Nome do Parceiro"
          filters={matchesFilters.map((item) => item.value)}
          appliedFilters={appliedFilters}
          changeValue={handleChangeSearch}
          handleClearFilters={removeMatcheFilter}
        />
        {renderNewFreightButton}
      </S.ContainerSearch>
      <S.Content>{renderContent}</S.Content>
      <ChatButton chatProps={{ typeChat: 'SINGLE', name: '' }} />
    </S.Wrapper>
  );
};
