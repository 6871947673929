import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useNavigate } from 'react-router-dom';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { IPalletDomain } from '../../domains/pallet.domain';
import { FinancialContext } from '../../contexts/financial/financial.context';
import { convertPalletFormDomainToResponse } from './mappers/convert-pallet-form-domain-to-response/convert-pallet-form-domain-to-response.mapper';
import { updatePalletFormService } from '../../services/update-pallet-form/update-pallet-form.service';

export const useUpdatePalletForm = () => {
  const { palletForm } = useContext(FinancialContext);
  const { setPalletFormSubmit, setPalletFormSuccess, setPalletFormError } = palletForm;

  const navigate = useNavigate();

  return useCallback(
    async (payload: IPalletDomain) => {
      try {
        setPalletFormSubmit();

        await updatePalletFormService(convertPalletFormDomainToResponse(payload));

        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });

        setPalletFormSuccess();

        navigate(-1);
      } catch (error) {
        setPalletFormError();

        showMessageFormatted({
          message: 'Erro ao atualizar registro',
          type: 'error',
        });
      }
    },
    [navigate, setPalletFormError, setPalletFormSubmit, setPalletFormSuccess],
  );
};
