import React, { ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './freight-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface IFreightTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (freightId: string) => void;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: () => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  notAbsolute?: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
  zIndex?: boolean;
}

const dataTestId = 'freight-table-view';

const FreightTableView: React.FC<IFreightTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  orderSelected,
  clickOrderTableBy = () => null,
  clickRowAction,
  arrowOrder,
  clickSortDirection = () => null,
  isLoading,
  notAbsolute,
  zIndex,
}: IFreightTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <ContainerPaginationStyled notAbsolute={notAbsolute} data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo, notAbsolute]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table
        orderSelected={orderSelected}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickOrderBy={clickOrderTableBy}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
        clickSortDirection={clickSortDirection}
        zIndex={zIndex}
      />

      {renderPagination}
    </ContainerTableStyled>
  );
};

export default FreightTableView;
