/* eslint-disable @typescript-eslint/no-explicit-any */
import request, { AxiosResponse } from 'axios';

import { ScheduleLTLGetListResponse, ScheduleLTLItem } from '../../schedule-ltl/types/schedule-ltl-lts.types';
import { FreightPriceLTL } from '../../schedule-ltl/types/freight-selection';

import API from 'utils/API/API';
import { CheckDeleteResponse } from 'domain/global';

interface StatusError {
  statusCode: number;
  message: string;
}

export const scheduleLTLCalculateFreight = async (payload: any): Promise<AxiosResponse<FreightPriceLTL>> => {
  try {
    return await API.post('v2/schedules-stl/freight-calculate', {
      ...payload,
      capacity: '0',
      weight: '0',
    });
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao calcular o frete');
  }
};

export const scheduleSTLGetList = async (params: any): Promise<AxiosResponse<ScheduleLTLGetListResponse>> => {
  try {
    const response = await API.get<ScheduleLTLGetListResponse>('v2/schedules', {
      params: {
        ...params,
        scheduleType: 'stl',
        limit: 50,
      },
    });

    return response;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao calcular o frete');
  }
};

export const getScheduleLTLByIdService = async (scheduleId: string): Promise<ScheduleLTLItem> => {
  try {
    const { data } = await API.get(`v2/schedules-stl/${scheduleId}`);

    return data;
  } catch (error) {
    throw new Error('Houve um erro ao buscar detalhes da agendamento');
  }
};

export const createScheduleLTLService = async (payload: any) => {
  try {
    await API.post('v2/schedules-stl', payload);
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao criar o agendamento');
  }
};

export const updateScheduleLTLService = async (scheduleId: string, payload: any) => {
  try {
    await API.patch(`v2/schedules-stl/${scheduleId}`, payload);
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao atualizar o agendamento');
  }
};

export const checkDeleteScheduleLTLService = async (payload: any): Promise<CheckDeleteResponse[]> => {
  try {
    const response = await API.post('/v2/schedules-stl/status/remove', {
      ids: payload,
    });

    return response.data;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao recuperara a lista de exclusão');
  }
};

export const deleteScheduleLTLService = async (payload: any) => {
  try {
    await API.delete('/v2/schedules-ltl', {
      data: { ids: payload },
    });
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao criar o agendamento');
  }
};
