import { initialModalState, ModalState } from 'domain-v2/inputs/modals';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IRetentionFinesDomain } from '../../../../domains/schedule-finance-retention-fines.domain';
import { ScheduleDetailsResumeFreeTimeView } from './schedule-details-resume-freetime-view.component';
import { UserContext } from 'state/user-context';

export const ScheduleDetailsResumeFreeTime: FC = () => {
  const { user } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const [modal, setModal] = useState<ModalState>(initialModalState);

  const isTracking = useMemo(() => {
    return scheduleDetails.scheduleDetails.operation === 'TRACKING';
  }, [scheduleDetails.scheduleDetails.operation]);

  const handleOpenOrCloseModalGR = useCallback((isOpen: boolean) => {
    setModal((prevState) => ({ ...prevState, open: isOpen }));
  }, []);

  const getFreeTime = useMemo((): IRetentionFinesDomain => {
    const isBackhaulOperation = scheduleDetails.scheduleDetails.operation.includes('BACKHAUL');
    const findFreeTime = scheduleDetails.scheduleDetails.retentionFines ?? {
      freeTime: '',
      hourlyFine: 0,
      dailyFine: 0,
    };

    const dailyFine = findFreeTime.dailyFine * 0.8;
    const hourlyFine = findFreeTime.hourlyFine * 0.8;

    const dailyFineShipping = findFreeTime.dailyFine * 0.8;
    const hourlyFineShipping = findFreeTime.hourlyFine * 0.8;

    const freeTimeBackhaul: IRetentionFinesDomain = {
      dailyFine: dailyFine,
      freeTime: findFreeTime.freeTime,
      hourlyFine: hourlyFine,
    };

    const freeTime: IRetentionFinesDomain = {
      dailyFine: user?.profile === 'shipping-company' ? dailyFineShipping : findFreeTime.dailyFine,
      freeTime: findFreeTime.freeTime,
      hourlyFine: user?.profile === 'shipping-company' ? hourlyFineShipping : findFreeTime.hourlyFine,
    };

    return isBackhaulOperation ? freeTimeBackhaul : freeTime;
  }, [scheduleDetails.scheduleDetails.operation, scheduleDetails.scheduleDetails.retentionFines, user?.profile]);

  return (
    <ScheduleDetailsResumeFreeTimeView
      modal={modal}
      scheduleDetails={scheduleDetails.scheduleDetails}
      freeTime={getFreeTime}
      isTracking={isTracking}
      handleOpenOrCloseModalGR={handleOpenOrCloseModalGR}
    />
  );
};
