import { IConversationChatDomain } from 'src-new/domains/conversation-chat.domain';
import { formatDate } from 'src-new/utils/format-date';
import {
  IScheduleDetailsDomain,
  OperationDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { IUser } from 'domain/login';
import {
  ChatBackhaulOperationType,
  IChatObject,
  IChatPurchaseAndSaleOfferId,
} from 'src-new/components/chat-button/chat-button.types';
import { IConversationResponse } from 'src-new/services/chat/get-conversations-list-by-user/get-conversations-list-by-user.types';

export const convertConversationResponseToDomain = (
  response: IConversationResponse,
  user: IUser,
  scheduleDetails?: IScheduleDetailsDomain,
  companyName?: string,
): IConversationChatDomain => {
  const companyPartner: string = companyName ?? '---';
  const purchaseSaleOfferId = !scheduleDetails
    ? getPurchaseAndSaleOfferId(response.custom.conversationLabel, response.id)
    : undefined;

  return {
    id: response.id,
    threadId: scheduleDetails ? response.id.split('-')[2] : response.id.split('-')[4],
    partner: scheduleDetails ? scheduleDetails.company.tradeName : companyPartner,
    typePartner: getTypePartner(user, response.id, scheduleDetails),
    startDate: response.createdAt ? formatDateConversation(response.createdAt).toLowerCase() : '---',
    messageId: response.lastMessage?.id,
    lastMessage: response?.lastMessage?.createdAt
      ? formatDateConversation(response.lastMessage.createdAt).toLowerCase()
      : '---',
    operation: scheduleDetails ? getOperation()[scheduleDetails.operation] : getBackhaulOperation(response, user),
    purchaseOfferId: purchaseSaleOfferId?.purchaseOfferId,
    saleOfferId: purchaseSaleOfferId?.saleOfferId,
  };
};
const formatDateConversation = (dateProp: number) => {
  return formatDate(dateProp, 'DD/MM/YYYY - HH:mm').replace('-', 'as');
};

const getOperation = (): { [key in OperationDomain]: string } => ({
  TRACKING: 'Tracking',
  SPOT: 'Spot',
  'BACKHAUL INTERNO': 'BackHaul Interno',
  'BACKHAUL PARCEIRO': 'BackHaul Externo',
  'BACKHAUL EXTERNO SPOT': 'Spot',
  'BACKHAUL EXTERNO': 'BackHaul Externo',
});

const getTypePartner = (user: IUser, id: string, scheduleDetails?: IScheduleDetailsDomain): IChatObject => {
  if (scheduleDetails) {
    return getChatObjectByOperation(scheduleDetails, user, id);
  }

  return {
    chatId: id,
    colorButton: 'yellow',
    name: 'Embarcador',
  };
};

const getChatObjectByOperation = (scheduleDetails: IScheduleDetailsDomain, user: IUser, id: string): IChatObject => {
  switch (scheduleDetails.operation) {
    case 'TRACKING':
      return getChatSpotAndTracking(user, id, scheduleDetails);
    case 'SPOT':
      return getChatSpotAndTracking(user, id, scheduleDetails);
    case 'BACKHAUL EXTERNO SPOT':
      return getChatSpotAndTracking(user, id, scheduleDetails);
    case 'BACKHAUL INTERNO':
      return getChatBackHaulInternal(scheduleDetails, user, id);
    default:
      return getChatBackHaulExternal(scheduleDetails, user, id);
  }
};

const getChatSpotAndTracking = (
  user: IUser,
  chatIdResponse: string,
  scheduleDetails: IScheduleDetailsDomain,
): IChatObject => {
  const isLogShareUser: boolean = user?.profile === 'logshare';
  const isShippingCompanyUser: boolean = user?.profile === 'shipping-company';
  const environment = chatIdResponse.split('-')[0];
  const scheduleId = `${chatIdResponse.split('-')[1]}-${chatIdResponse.split('-')[2]}`;
  const chatId: string = chatIdResponse.replace(`${environment}-`, '');
  const shippingId = scheduleDetails.shippingId;

  switch (chatId) {
    case `${scheduleId}-shipping-company-shipper-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };
    case `${scheduleId}-shipping-company-logshare-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };
    case `${scheduleId}-shipper-logshare`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Embarcador' : 'LogShare',
        colorButton: isLogShareUser ? '#12264EFF' : '#777777FF',
      };
    default:
      return {
        chatId: chatIdResponse,
        name: 'NOT_FOUND',
        colorButton: 'NOT_FOUND',
      };
  }
};

const getChatBackHaulInternal = (
  scheduleDetails: IScheduleDetailsDomain,
  user: IUser,
  chatIdResponse: string,
): IChatObject => {
  const isLogShareUser = user?.profile === 'logshare';
  const isShippingCompanyUser = user?.profile === 'shipping-company';
  const environment = chatIdResponse.split('-')[0];
  const scheduleId = `${chatIdResponse.split('-')[1]}-${chatIdResponse.split('-')[2]}`;
  const chatId: string = chatIdResponse.replace(`${environment}-`, '');
  const shippingId = scheduleDetails.shippingId;

  switch (chatId) {
    case `${scheduleId}-shipper-${scheduleDetails.company.id}`:
      return {
        chatId: chatIdResponse,
        name: 'Embarcador',
        colorButton: '#12264EFF',
      };
    case `${scheduleId}-shipping-company-shipper-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };
    case `${scheduleId}-shipping-company-logshare-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };
    case `${scheduleId}-shipper-logshare`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Embarcador' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };
    default:
      return {
        chatId: chatIdResponse,
        name: 'NOT_FOUND',
        colorButton: 'NOT_FOUND',
      };
  }
};

const getChatBackHaulExternal = (
  scheduleDetails: IScheduleDetailsDomain,
  user: IUser,
  chatIdResponse: string,
): IChatObject => {
  let labelChatCompanyBuyerCompanySeller: string;
  const isLogShareUser = user?.profile === 'logshare';
  const isCompanySellerUser = user?.companyId === scheduleDetails.company.id;
  const isShippingCompanyUser = user?.profile === 'shipping-company';
  const environment = chatIdResponse.split('-')[0];
  const scheduleId = `${chatIdResponse.split('-')[1]}-${chatIdResponse.split('-')[2]}`;
  const chatId: string = chatIdResponse.replace(`${environment}-`, '');
  const shippingId = scheduleDetails.shippingId;

  if (isLogShareUser) {
    labelChatCompanyBuyerCompanySeller = 'Embarcadores';
  } else if (isCompanySellerUser) {
    labelChatCompanyBuyerCompanySeller = 'Embarcador';
  } else {
    labelChatCompanyBuyerCompanySeller = 'Embarcador';
  }

  switch (chatId) {
    case `${scheduleId}-company-buyer-company-seller`:
      return {
        chatId: chatIdResponse,
        name: labelChatCompanyBuyerCompanySeller,
        colorButton: '#12264EFF',
      };
    case `${scheduleId}-shipping-company-logshare-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Transportadora' : 'LogShare',
        colorButton: isLogShareUser ? '#2c88a8' : '#777777FF',
      };
    case `${scheduleId}-logshare-company-seller`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Vendedor' : 'LogShare',
        colorButton: isLogShareUser ? '#d58d37' : '#777777FF',
      };
    case `${scheduleId}-logshare-company-buyer`:
      return {
        chatId: chatIdResponse,
        name: isLogShareUser ? 'Comprador' : 'LogShare',
        colorButton: isLogShareUser ? '#be382e' : '#777777FF',
      };
    case `${scheduleId}-shipping-company-company-seller-${shippingId}`:
      return {
        chatId: chatIdResponse,
        name: isShippingCompanyUser ? 'Embarcador' : 'Transportadora',
        colorButton: isShippingCompanyUser ? '#12264EFF' : '#2c88a8',
      };
    default:
      return {
        chatId: chatIdResponse,
        name: 'NOT_FOUND',
        colorButton: 'NOT_FOUND',
      };
  }
};

const getPurchaseAndSaleOfferId = (conversationLabel: string, id: string): IChatPurchaseAndSaleOfferId => {
  const regex = /ID (\d+)/g;
  const matches = Array.from(conversationLabel.matchAll(regex));

  if (matches.length < 2) {
    const ids = id.split('-');
    return { purchaseOfferId: ids[2], saleOfferId: ids[3] };
  }

  const purchaseOfferId = matches[0]?.[1] ?? '-';
  const saleOfferId = matches[1]?.[1] ?? '-';

  return { purchaseOfferId, saleOfferId };
};

const getBackhaulOperation = (response: IConversationResponse, user: IUser): ChatBackhaulOperationType => {
  const { buyOfferCompanyId, saleOfferCompanyId } = response.custom;

  if (Number(buyOfferCompanyId) === user.companyId) {
    return 'Negociação de Compra';
  }

  if (Number(saleOfferCompanyId) === user.companyId) {
    return 'Negociação de Venda';
  }

  return 'Negociação';
};
