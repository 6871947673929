import { useCallback } from 'react';

import { userDetailsService } from 'pages/user/services/user-details-service/user-details.service';
import { IFunctionalityEntity } from 'pages/user/services/entities/user.entity';
import { ICreatedUser } from 'domain/user';

const useDetailsCompanyHook = () => {
  return useCallback(async (userId: string, functionalities: Array<IFunctionalityEntity>): Promise<ICreatedUser> => {
    return await userDetailsService(userId, functionalities);
  }, []);
};

export { useDetailsCompanyHook };
