import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import { getCustomFieldsService } from '../services/custom-fields';
import { ValidationFieldProvider } from '../context/validation-fields';
import { ModalProvider } from '../context/modal';
import { AllocationProvider } from '../context/allocation';

import ScheduleUpdateForm from './form';

import { UserContext } from 'state/user-context';
import { schedulingKeysService } from 'services/schedule';
import { companyDetailsService } from 'services/company/details';
import { ISchedulingKeys } from 'domain/schedule';
import { ICompany } from 'domain/company';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { AccessRestricted } from 'components/access-restricted/indext';

const initialCompanyValues = {} as ICompany;

const ScheduleUpdatePageV2: React.FC = () => {
  const { user } = useContext(UserContext);

  const { id } = useParams();

  const [accessRestricted, setAccessRestricted] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>(initialCompanyValues);
  const [schedulingKeys, setSchedulingKeys] = useState<ISchedulingKeys[]>();

  useEffect(() => {
    (async () => {
      try {
        const findCompany = await companyDetailsService(user?.companyId || 0);

        let findKeys = await schedulingKeysService(id ?? '0');

        if (findKeys.length === 0) {
          findKeys = await getCustomFieldsService();
        }

        setCompany(findCompany);

        setSchedulingKeys(findKeys);
      } catch (error) {
        showToast({
          message: 'Erro ao obter detalhes da empresa',
          type: 'error',
        });
      }
    })();
  }, [id, user]);

  const handleAccessRestricted = (accessRestricted: boolean) => {
    setAccessRestricted(accessRestricted);
  };

  if (accessRestricted) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <AccessRestricted />
      </div>
    );
  }

  return (
    <>
      <HeaderPage breadcrumbList={['Alocação', 'Alocação FTL | Frete Dedicado']} hasBackButton />
      <Title title="Alocação" isForm />
      <AllocationProvider>
        <ModalProvider>
          <ValidationFieldProvider>
            <ScheduleUpdateForm
              company={company}
              user={user}
              schedulingKeys={schedulingKeys}
              handleAccessRestricted={handleAccessRestricted}
            />
          </ValidationFieldProvider>
        </ModalProvider>
      </AllocationProvider>
    </>
  );
};

export default ScheduleUpdatePageV2;
