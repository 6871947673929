import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { Formik, FormikProps } from 'formik';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { TransactionListParams } from '../../types/list';
import { convertField } from '../../mappers';
import { transactionsListHook } from '../../hooks/list';
import { TransactionsFilterForm } from '../../components/filter';
import * as S from './styled';
import ListTransactionTable from './list';
import { hiddenFilters } from 'utils-v2/filters/hidden-filters';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UseTransactionsExcelListHook } from 'pages-v2/transactions/hooks/export-excel';
import { UseInvoiceCreate } from 'pages-v2/transactions/hooks/create-invoice';
import { useFilter } from 'pages-v2/transactions/context/list';
import { CalcFinanceList } from 'domain-v2/transactions';
import { InvoiceRegisterInput } from 'domain-v2/invoice/register';
import { initialPageInfo } from 'domain-v2/inputs/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Input from 'components/input/input';
import TooltipComponent from 'components/Tooltip';
import { Button } from 'logshare-ui-kit';
import { UserContext } from 'state/user-context';
import { initialModalState, ModalState } from 'domain-v2/inputs/modals';
import { Filter } from 'src-new/components/filter/filter.component';
import { ModalCreateInvoice } from 'pages-v2/transactions/list/content-table/modal/modal-create-invoice.component';

interface ParamsArray {
  key: string;
  value: string | number;
}

export const TransactionList: React.FC = () => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const { filters, resetFilters, updateFilters } = useFilter();

  const navigate = useNavigate();

  const reactQueryClient = useQueryClient();

  const [ids, setIds] = useState<Array<number>>([]);
  const [scheduleIds, setScheduleIds] = useState<Array<number>>([]);

  const [companyTakerSelected, setCompanyTakerSelected] = useState<string>('');

  const [companyTakerNames, setCompanyTakerNames] = useState<Array<string>>([]);
  const [companySellersNames, setCompanySellersNames] = useState<Array<string>>([]);

  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const [total, setTotal] = useState<Array<number>>([]);

  const [typeSelected, setTypeSelected] = useState<Array<string>>([]);

  const [excelButtonDisabled, setExcelButtonDisabled] = useState<boolean>(false);
  const [checkboxAllState, setCheckBoxAllState] = useState<boolean>(false);
  const [alterChecked, setAlterChecked] = useState(0);

  const FilterFormRef = useRef<FormikProps<TransactionListParams>>(null);

  const { isLoading, data } = useQuery({
    queryKey: ['transactionList', filters],
    queryFn: () => transactionsListHook(filters),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const filterChip: ParamsArray[] = useMemo(() => {
    const entries = Object.entries(filters).map(([key, value]) => ({
      key,
      value: convertField(key, value),
    }));

    const filteredParams = entries.filter(({ key, value }) => !hiddenFilters.includes(key) && value);

    const convertedDateParams = filteredParams.map(({ key, value }) => {
      if (isNaN(Number(value))) {
        return { key, value: String(value).toUpperCase() };
      }

      return { key, value };
    });

    return convertedDateParams;
  }, [filters]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const removeFilterChip = useCallback(
    (indexToRemove: number) => {
      const updatedParamsArray = [...filterChip];
      updatedParamsArray.splice(indexToRemove, 1);

      if (updatedParamsArray.length === 0) {
        resetFilters(false, {
          activeTabName: filters.activeTabName,
          financialStatus: filters.financialStatus,
        });

        return;
      }

      const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
        accumulator[key as keyof ParamsArray] = filters[key as keyof TransactionListParams];
        return accumulator;
      }, {});

      updateFilters({
        ...updatedParams,
        page: 1,
        activeTabName: filters.activeTabName,
        financialStatus: filters.financialStatus,
      });
    },
    [filterChip, filters, resetFilters, updateFilters],
  );

  const handleSearch = (search: string) => {
    if (search) updateFilters({ ...filters, search, page: 1 });
  };

  const handlePaginate = (page: number) => {
    updateFilters({ ...filters, page });
  };

  const handleExportExcel = useCallback(async () => {
    try {
      setExcelButtonDisabled(true);
      await UseTransactionsExcelListHook(filters);
      setExcelButtonDisabled(false);

      showMessageFormatted({
        message: 'Relatório gerado com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    } finally {
      setExcelButtonDisabled(false);
    }
  }, [filters]);

  const tabStatusCode = (activeTabName: string) => {
    if (isShippingCompany) {
      if (activeTabName === 'A Receber') return 'receber transportador';
      if (activeTabName === 'A Pagar') return 'pagar';
    }

    if (isAdmin) {
      if (activeTabName === 'A Receber') return 'pagar';
      if (activeTabName === 'A Pagar') return 'receber';
      if (activeTabName === 'Repasse' || activeTabName === 'Crédito Transportadora') return 'receber transportador';
    }

    if (activeTabName === 'A Pagar') return 'pagar';
    if (activeTabName === 'A Receber') return 'receber';
    if (activeTabName === 'Repasse' || activeTabName === 'Crédito Transportadora') return 'receber transportador';

    return 'pagar';
  };

  const renderStatusByTabName = (activeTabName: string) => {
    const financialStatus = tabStatusCode(activeTabName);

    updateFilters({
      ...filters,
      financialStatus,
      activeTabName,
      page: 1,
    });
  };

  const goToAllocationSchedule = (id: string) => {
    navigate(`/fretes/${id}`, { state: { activeTab: 'Financeiro' } });
  };

  const handleCheckAllBoxes = (clicked: boolean, freight: Array<CalcFinanceList>) => {
    if (clicked) {
      const filteredFreight = freight.filter(
        (item) =>
          item.tag !== 'compra' && item.financialStatus !== 'Faturado' && item.financialStatus !== 'Em Auditoria',
      );

      const filteredIds = filteredFreight.map((item) => item.id);
      const filteredScheduleIds = filteredFreight.map((item) => item.schedule.id);
      const filteredTotalData = filteredFreight.map((item) => item.totalService);
      const filteredTakerNamesData = filteredFreight.map((item) => item.taker);
      const filteredSellersNamesData = filteredFreight.map((item) => item.invoicedBy);

      const uniqueIdsData = filteredIds.filter((item) => !ids.includes(item));
      const uniqueScheduleIdsData = filteredScheduleIds.filter((item) => !scheduleIds.includes(item));

      const uniqueTakersNamesData = filteredTakerNamesData.filter((item) => !companyTakerNames.includes(item));

      const uniqueSellersNamesData = filteredSellersNamesData.filter((item) => !companySellersNames.includes(item));

      const uniqueTotalData = filteredTotalData.filter((item) => !total.includes(item));

      setCompanyTakerNames([...companyTakerNames, ...uniqueTakersNamesData]);
      setCompanySellersNames([...companySellersNames, ...uniqueSellersNamesData]);
      setTotal([...total, ...uniqueTotalData]);
      setIds([...ids, ...uniqueIdsData]);
      setScheduleIds([...scheduleIds, ...uniqueScheduleIdsData]);

      return;
    }

    for (const item of freight) {
      const idArray = [item.id];
      const scheduleIdsArray = [item.id];

      const totalArray = freight.map((item) => item.totalService);
      const companyTakerName = item.taker;
      const companySellerName = item.invoicedBy;

      setIds((prevIds) => prevIds.filter((id) => !idArray.includes(id)));
      setScheduleIds((prevIds) => prevIds.filter((id) => !scheduleIdsArray.includes(id)));

      setTotal((prevIds) => prevIds.filter((id) => !totalArray.includes(id)));

      setCompanyTakerNames((prevNames) => {
        const remainingNames = [...prevNames];
        for (const n of companyTakerName) {
          const index = remainingNames.indexOf(n);
          if (index !== -1) {
            remainingNames.splice(index, 1);
            break;
          }
        }
        return remainingNames;
      });

      if (companySellerName && companySellerName.length > 0) {
        setCompanySellersNames((prevNames) => {
          const remainingNames = [...prevNames];
          for (const n of companySellerName) {
            const index = remainingNames.indexOf(n);
            if (index !== -1) {
              remainingNames.splice(index, 1);
              break;
            }
          }
          return remainingNames;
        });
      }
    }
  };

  const handleOpenModal = useCallback(() => {
    const uniqueTakerNames = new Set(companyTakerNames);
    const uniqueSellerNames = new Set(companySellersNames);
    const uniqueType = new Set(typeSelected);

    if (uniqueTakerNames.size !== 1 || uniqueSellerNames.size !== 1) {
      showMessageFormatted({
        message: 'Selecione somente transações com o mesmo pagador e recebedor.',
        type: 'error',
      });
      return;
    }

    if (uniqueType.size !== 1) {
      showMessageFormatted({
        message: 'Selecione somente um tipo de faturamento',
        type: 'error',
      });
      return;
    }

    setModal(true);
  }, [companySellersNames, companyTakerNames, typeSelected]);

  const handleClearSelection = () => {
    setCompanyTakerNames([]);
    setCompanySellersNames([]);
    setIds([]);
    setScheduleIds([]);
    setTotal([]);
    setTypeSelected([]);
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    scheduleIds: Array<number>,
    ids: Array<number>,
    takerNames: Array<string>,
    sellerNames: Array<string>,
    price: Array<number>,
    takerName: string,
    type: Array<string>,
  ) => {
    const { checked } = event.target;
    if (checked) {
      setIds((prevIds) => Array.from(new Set([...prevIds, ...ids])));
      setScheduleIds((prevIds) => Array.from(new Set([...prevIds, ...scheduleIds])));
      setCompanyTakerNames((prevNames) => [...prevNames, ...takerNames]);

      setCompanySellersNames((prevNames) => [...prevNames, ...sellerNames]);
      setTypeSelected((preTypes) => [...preTypes, ...type]);
      setCompanyTakerSelected(takerName);
      setTotal((prevTotal) => [...prevTotal, ...price]);
    } else {
      setTypeSelected((prevTypes) => {
        const remainingTypes = [...prevTypes];
        for (const n of type) {
          const index = remainingTypes.indexOf(n);
          if (index !== -1) {
            remainingTypes.splice(index, 1);
          }
        }
        return remainingTypes;
      });
      setIds((prevIds) => prevIds.filter((id) => !ids.includes(id)));
      setScheduleIds((prevIds) => prevIds.filter((id) => !scheduleIds.includes(id)));
      setCompanyTakerNames((prevNames) => {
        const remainingTotal = [...prevNames];

        for (const n of takerNames) {
          const index = remainingTotal.indexOf(n);
          if (index !== -1) {
            remainingTotal.splice(index, 1);
            break;
          }
        }
        return remainingTotal;
      });

      setCompanySellersNames((prevNames) => {
        const remainingTotal = [...prevNames];

        for (const n of sellerNames) {
          const index = remainingTotal.indexOf(n);
          if (index !== -1) {
            remainingTotal.splice(index, 1);
            break;
          }
        }
        return remainingTotal;
      });

      setCompanyTakerSelected('');
      setTotal((prevTotal) => {
        const remainingTotal = [...prevTotal];
        for (const n of price) {
          const index = remainingTotal.indexOf(n);
          if (index !== -1) {
            remainingTotal.splice(index, 1);
            break;
          }
        }
        return remainingTotal;
      });
    }
  };

  const handleCreateInvoicing = useCallback(
    async (values: InvoiceRegisterInput) => {
      try {
        showMessageFormatted({
          message: 'Aguarde, em processamento.',
          type: 'info',
        });

        await UseInvoiceCreate(values);

        await reactQueryClient.invalidateQueries(['transactionList']);

        handleClearSelection();
        setModal(false);

        showMessageFormatted({
          message: 'Fatura emitida com sucesso.',
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: 'Erro ao emitir fatura.',
          type: 'error',
        });
      }
    },
    [ids],
  );

  const handleCancelModal = useCallback(() => {
    setModal(false);
  }, []);

  const handleCreateModal = useCallback(
    (values: InvoiceRegisterInput) => {
      handleCreateInvoicing({
        ...values,
        calcFinanceIds: ids,
        paymentDay: '',
      });
      handleCancelModal;
    },
    [handleCancelModal, handleCreateInvoicing, ids],
  );

  useEffect(() => {
    if (data?.items) {
      handleCheckAllBoxes(checkboxAllState, data?.items);
    }
  }, [alterChecked]);

  const renderFooterPage = useMemo((): ReactElement | undefined => {
    if ((filters.financialStatus === 'pagar' || filters.financialStatus === 'receber') && ids.length >= 1) {
      const totalSelected = convertCurrencyFormat.format(
        Number(total.reduce((initial, current) => initial + current, 0) + 0.001),
      );

      return (
        <FooterPage.RightContent>
          <FooterPage.Button label="Faturar" color="blue" onPress={handleOpenModal} />

          <FooterPage.Button label="Limpar Seleção" color="pink" onPress={handleClearSelection} />

          <S.ContainerTotal>
            <S.TotalTitle>Total Selecionado(s)</S.TotalTitle>

            <S.TotalLabel>{totalSelected}</S.TotalLabel>
          </S.ContainerTotal>
        </FooterPage.RightContent>
      );
    }
  }, [filters.financialStatus, handleOpenModal, ids.length, total]);

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      {modal && (
        <ModalCreateInvoice
          scheduleIds={scheduleIds}
          totalService={total.reduce((initial, current) => initial + current, 0)}
          handleCancel={handleCancelModal}
          companyTakerName={companyTakerSelected}
          handleSubmit={handleCreateModal}
        />
      )}

      {modalFilter && (
        <Filter
          appliedFilters={filterChip.length}
          handleClose={() => setModalFilter(false)}
          handleResetFilter={() => {
            resetFilters();
            setModalFilter(false);
          }}
        >
          <Formik
            innerRef={FilterFormRef}
            initialValues={filters}
            onSubmit={(values) => {
              updateFilters({
                ...values,
                page: 1,
              });
              setModalFilter(false);
            }}
          >
            <TransactionsFilterForm />
          </Formik>
        </Filter>
      )}

      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pelo ID da Viagem ou Tomador"
          type="text"
          changeValue={(event) => handleSearch(event)}
          filters={filterChip.map((item) => item.value)}
          handleClearFilters={removeFilterChip}
          isClear={false}
        />

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => setModalFilter(true)}
              LeftIcon={<FilterAltIcon sx={{ width: 22 }} />}
            />
          }
          title={'Filtrar'}
          location={'bottom'}
        />

        <TooltipComponent
          icon={
            <Button
              disabled={excelButtonDisabled}
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={handleExportExcel}
              LeftIcon={<CloudDownloadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Exportar'}
          location={'bottom'}
        />

        {!isShippingCompany && (
          <Button
            label="Novo Frete"
            size="md"
            color="blue"
            onPress={() => handleCreateSchedulePage(true)}
            variant="solid"
          />
        )}
      </S.ContainerSearch>

      <ListTransactionTable
        handleCheckboxAll={(clicked) => {
          setCheckBoxAllState(clicked), setAlterChecked((initial) => initial + 1);
        }}
        ids={ids}
        handleCheckboxChange={handleCheckboxChange}
        handleClearSelection={handleClearSelection}
        transactionList={data?.items || []}
        pageInfo={data?.meta || initialPageInfo}
        isLoading={isLoading}
        handlePaginate={handlePaginate}
        orderSelected={{
          orderBy: filters.column,
          sortDirection: filters.order,
        }}
        clickOrderTableBy={(column) => {
          updateFilters({
            ...filters,
            column,
          });
        }}
        clickSortDirection={(column) => {
          updateFilters({
            ...filters,
            column,
            order: filters.order === 'ASC' ? 'DESC' : 'ASC',
          });
        }}
        setActiveTabName={renderStatusByTabName}
        tabActive={filters.activeTabName}
        goToAllocationSchedule={goToAllocationSchedule}
      />

      <FooterPage.Root>{renderFooterPage}</FooterPage.Root>
    </>
  );
};
