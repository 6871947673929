import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { getWeighingListService } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/services/get-weighing-list/get-weighing-list.service';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IWeighingListDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const useGetWeighingList = () => {
  const { weighingList } = useContext(WeighingContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { filters, setWeighingListSuccess, setWeighingListRequest, setWeighingListError } = weighingList;
  const { selectedFilter, setSelectFilters } = filters;
  const { setInitialLoadingSuccess } = initialLoading;

  return useCallback(
    async (setLimitPage: Dispatch<SetStateAction<boolean>>) => {
      setWeighingListRequest();

      try {
        const sessionFilters = sessionStorage.getItem('weighing-list-filters');
        const filters = sessionFilters ? JSON.parse(sessionFilters) : selectedFilter

        const response: IListPage<IWeighingListDomain> = await getWeighingListService(filters);
        const oldRecords: Array<IWeighingListDomain> = selectedFilter.page === 1 ? [] : weighingList.weighingList;

        setLimitPage(selectedFilter.page === response.meta.totalPages || response.meta.totalPages === 0);
        setWeighingListSuccess([...oldRecords, ...response.items]);
        setSelectFilters(filters)
      } catch (error: any) {
        setWeighingListError();
        showMessageFormatted({
          error: error,
          type: 'error',
        });
      }
      setInitialLoadingSuccess();
    },
    [
      selectedFilter,
      setInitialLoadingSuccess,
      setWeighingListError,
      setWeighingListRequest,
      setWeighingListSuccess,
      weighingList.weighingList,
    ],
  );
};
