import React, { Fragment, useMemo } from 'react';
import { IChatContextError } from 'src-new/contexts/chat/types/chat.types';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { HeaderChatModalTable } from 'src-new/components/application-page-header/components/header-page/components/header-chat-modal/components/header-chat-modal-table/header-chat-modal-table.component';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { CharModalWrapperStyled, LoadingWrapperStyled, ModalContainerStyled } from './header-chat-modal-view.styled';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

export interface IModalViewStatesProps {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
}

interface IHeaderChatModalViewProps {
  states: IModalViewStatesProps;
  error: IChatContextError;
  infiniteScrollProps: IInfiniteScrollTable;
  handleCloseModal: () => void;
}

export const HeaderChatModalView: React.FC<IHeaderChatModalViewProps> = ({
  handleCloseModal,
  states,
  error,
  infiniteScrollProps,
}) => {
  const renderContentModal = useMemo(() => {
    if (states.isLoading) {
      return (
        <LoadingWrapperStyled>
          <Loading />
        </LoadingWrapperStyled>
      );
    }

    if (states.isError) {
      return <ErrorState title={error.title} subTitle={error.description} />;
    }

    if (states.isEmpty) {
      return <EmptyState title={'Lista vazia'} subTitle={'Não há conversas disponíveis até o momento.'} />;
    }

    return <HeaderChatModalTable infiniteScrollProps={infiniteScrollProps} />;
  }, [error.description, error.title, infiniteScrollProps, states.isEmpty, states.isError, states.isLoading]);

  return (
    <Fragment>
      <ModalBark handleClose={handleCloseModal} size={'lg'} title={'Chat entre Parceiros'}>
        <ModalContainerStyled>
          <CharModalWrapperStyled>{renderContentModal}</CharModalWrapperStyled>
        </ModalContainerStyled>
      </ModalBark>
      <ChatButton chatProps={{ typeChat: 'SINGLE', name: '' }} />
    </Fragment>
  );
};
