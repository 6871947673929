import React, { useContext, useEffect } from 'react';
import { FormikProps, withFormik } from 'formik';

import { FormStyled, LineSectionStyled } from './neg-modal-form-view-styled';

import { UserContext } from 'state/user-context';
import { IOccurrenceResolution } from 'domain/tracking-occurrence';
import { IMyAccount } from 'domain/my-account';
import InputTextarea from 'components/input-textarea/input-textarea';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

interface TrackingNegotiationsRegisterFormViewProps {
  trackingOccurrenceNegotiationsRegisterValues: IOccurrenceResolution;
  handleGoBack: () => void;
  handleRegister: (values: IOccurrenceResolution) => void;
  schemaValidation: object;
  myAccountValues: IMyAccount;
}

const NegotiationsModalTableView: React.FC<TrackingNegotiationsRegisterFormViewProps> = ({
  trackingOccurrenceNegotiationsRegisterValues,
  handleRegister,
  schemaValidation,
  myAccountValues,
}) => {
  const { isZMaster, isAdmin } = useContext(UserContext);

  const InnerForm = (props: FormikProps<IOccurrenceResolution>) => {
    const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = props;

    useEffect(() => {
      setFieldValue('userResolutionNameBy', myAccountValues.name);
    }, []);

    return (
      <FormStyled>
        <InputTextarea
          id="description"
          name="description"
          placeholder="Descrição"
          onChange={handleChange}
          value={values.description}
          hasError={!!errors?.description && !!touched?.description}
          errorMessage={String(errors.description)}
        />

        <LineSectionStyled columns="2fr 2fr 0.2fr">
          <InputField
            label="Tratado com"
            id="userResolutionNameWith"
            name="userResolutionNameWith"
            placeholder="Tratado com"
            type="text"
            onChange={handleChange}
            value={values.userResolutionNameWith}
            hasError={!!errors?.userResolutionNameWith && !!touched?.userResolutionNameWith}
            errorMessage={String(errors.userResolutionNameWith)}
          />
          <InputField
            label="Tratado por"
            id="userResolutionNameBy"
            name="userResolutionNameBy"
            placeholder="Tratado por"
            type="text"
            onChange={handleChange}
            value={values.userResolutionNameBy}
            hasError={!!errors?.userResolutionNameBy && !!touched?.userResolutionNameBy}
            errorMessage={String(errors.userResolutionNameBy)}
            disabled={!(isZMaster || isAdmin)}
          />
          <div style={{ marginTop: 20 }}>
            <Button bgColor="pink" title="Salvar" callback={handleSubmit} size="large" />
          </div>
        </LineSectionStyled>
      </FormStyled>
    );
  };

  const TrackingNegotiationsRegisterFormView = withFormik<IOccurrenceResolution, IOccurrenceResolution>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister({ ...values, resolutionDate: String(new Date()) }),
    validationSchema: schemaValidation,
  })(InnerForm);
  return <TrackingNegotiationsRegisterFormView {...trackingOccurrenceNegotiationsRegisterValues} />;
};

export default NegotiationsModalTableView;
