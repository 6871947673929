import { FC, ReactNode, useMemo } from 'react';

import * as S from './filter-view.styled';

interface IFilterViewProps {
  children: ReactNode;
  appliedFilters: number;
  handleClose: () => void;
  handleResetFilter: () => void;
}

export const FilterView: FC<IFilterViewProps> = ({
                                                   children,
                                                   appliedFilters,
                                                   handleClose,
                                                   handleResetFilter,
                                                 }) => {
  const renderAppliedFilter = useMemo(() => {
    const filterNumber = appliedFilters > 0 ? appliedFilters : 'Nenhum';
    const label = appliedFilters >= 2 ? 'filtros aplicados' : 'filtro aplicado';

    return (
      <>
        <S.FilterText>{`${filterNumber} ${label}`}</S.FilterText>
        <S.ResetFilterButton disabled={appliedFilters === 0} onClick={handleResetFilter}>
          Limpar
        </S.ResetFilterButton>
      </>
    );
  }, [appliedFilters, handleResetFilter]);

  return (
    <S.Container>
      <S.Content>
        <S.ContainerHeader>
          <S.Title>Escolha o Filtro</S.Title>

          <S.CloseModal onClick={handleClose}>X</S.CloseModal>
        </S.ContainerHeader>

        <S.ContainerContent>{children}</S.ContainerContent>

        <S.ContainerFooter>{renderAppliedFilter}</S.ContainerFooter>
      </S.Content>
    </S.Container>
  );
};
