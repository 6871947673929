import React from 'react';

import DropwdownView from './dropdown-view';

type DropdownProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  isButtonOrder?: boolean;
};
const Dropwdown: React.FC<DropdownProps> = ({ title, children, isButtonOrder }) => {
  return (
    <DropwdownView title={title} isButtonOrder={isButtonOrder}>
      {children}
    </DropwdownView>
  );
};

export default Dropwdown;
