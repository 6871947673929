import React from 'react';
import ClientRegisterView from './client-register-view';
import { RegistrationsContextProvider } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

const ClientRegister: React.FC = () => {
  return (
    <RegistrationsContextProvider>
      <ClientRegisterView />
    </RegistrationsContextProvider>
  );
};

export default ClientRegister;
