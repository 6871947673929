import { InvoiceRegisterInput } from '..';

export const invoiceInitialValues: InvoiceRegisterInput = {
  calcFinanceIds: [],
  dueDate: '',
  issuanceDate: undefined,
  cnpjEmissor: '',
  paymentDay: '',
  discount: 0,
  branchId: 0,
  addition: 0,
  description: '',
};
