import styled from 'styled-components';

export const AllocationModalVehicleStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WrapperTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AllocationModalVehicleTitleStyled = styled.p`
  line-height: 1.8rem;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
`;

export const WrapperDriverInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
  width: 100%;
`;

export const WrapperButtons = styled.div`
  align-self: flex-end;
`;
