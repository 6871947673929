import React from 'react';
import { EmptyStateView } from './empty-state-view.component';

interface IEmptyStateProps {
  title?: string;
  subTitle?: string;
  height?: string;
  hideIcon?: boolean;
}

export const EmptyState: React.FC<IEmptyStateProps> = ({ title, subTitle, height, hideIcon }) => {
  return <EmptyStateView title={title} subTitle={subTitle} height={height} hideIcon={hideIcon} />;
};
