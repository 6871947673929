import styled, { css } from 'styled-components';

export const DrawerBarkerWrapper = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: right;
    align-items: right;
    position: absolute;
    background-color: ${theme.modals.background};
    top: 0;
    right: 0;
    z-index: 999;
  `}
`;

export const DrawerBarkerHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 1.5rem;
`;

export const DrawerBarkerWrapperTitles = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const DrawerBarkerTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

export const DrawerBarkerContent = styled.section`
  ${({ theme }) => css`
    width: 28rem;
    height: 100%;
    background-color: ${theme.colorsV2.white};
    padding: ${theme.modalsV2.padding};
    overflow-y: auto;
  `}
`;

export const DrawerBarkerClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 1rem;
  cursor: pointer;
`;
