import { IAcceptedMatchesStatus } from 'domain/accepted-matches';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): IAcceptedMatchesStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativas':
      return 'ativo';
    case 'Inativos':
      return 'inativo';
  }
};
