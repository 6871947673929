import React from 'react';

type TruckFtlProps = {
  width?: string;
  height?: string;
};

export const TruckFtl: React.FC<TruckFtlProps> = ({ width = '25', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.70776 2.91324C3.29821 2.91324 2.96621 3.23932 2.96621 3.64155V12.3813C2.96621 12.7835 3.29821 13.1096 3.70776 13.1096H14.4603C14.8698 13.1096 15.2018 12.7835 15.2018 12.3813V3.64155C15.2018 3.23932 14.8698 2.91324 14.4603 2.91324H3.70776Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1 0C17.2594 0 18.2 0.923398 18.2 2.0625V4.125H20.4181C21.1619 4.125 21.8356 4.41289 22.4 4.92852L25.7819 8.25C26.3069 8.76562 26.6 9.46602 26.6 10.1965V15.125C27.3744 15.125 28 15.7395 28 16.5C28 17.2605 27.3744 17.875 26.6 17.875H25.2C25.2 20.1523 23.3188 22 21 22C18.6419 22 16.8 20.1523 16.8 17.875H11.2C11.2 20.1523 9.31875 22 7 22C4.64187 22 2.8 20.1523 2.8 17.875H2.1C0.940188 17.875 0 16.9512 0 15.8125V2.0625C0 0.923398 0.940188 0 2.1 0H16.1ZM18.2 6.875V11H23.8V10.1965L20.4181 6.875H18.2ZM7 15.8125C5.84062 15.8125 4.9 16.7363 4.9 17.875C4.9 19.0137 5.84062 19.9375 7 19.9375C8.15938 19.9375 9.1 19.0137 9.1 17.875C9.1 16.7363 8.15938 15.8125 7 15.8125ZM21 19.9375C22.1594 19.9375 23.1 19.0137 23.1 17.875C23.1 16.7363 22.1594 15.8125 21 15.8125C19.8406 15.8125 18.9 16.7363 18.9 17.875C18.9 19.0137 19.8406 19.9375 21 19.9375ZM1.96003 3.01747C1.96003 2.41411 2.45804 1.925 3.07236 1.925H15.1277C15.742 1.925 16.24 2.41411 16.24 3.01747V12.9325C16.24 13.5359 15.742 14.025 15.1277 14.025H3.07236C2.45804 14.025 1.96003 13.5359 1.96003 12.9325V3.01747Z"
        fill="white"
      />
    </svg>
  );
};
