import { MatchRegisterInput } from 'domain-v2/match/register';
import { ModalMatchDetails } from '../../types';
import { VehicleCategory, VehicleType } from 'domain-v2/inputs/vehicles';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';

export const convertPurchaseOfferToMatchRegister = (
  transportRouter: ModalMatchDetails,
  purchaseOffer: IPurchaseOfferListDomain,
): MatchRegisterInput => {
  return {
    ...purchaseOffer,
    saleOfferId: transportRouter.saleOfferId,
    saleOfferShipperTarifId: transportRouter.saleOfferTariffId,
    matchOfferId: transportRouter.matchOfferId,
    companySeller: transportRouter.company.id,
    haulType: transportRouter.haulType,
    invoicedBy: transportRouter.invoicedBy,
    origin: {
      ...purchaseOffer.origin,
      tradeName: `${purchaseOffer.origin.name.toUpperCase()} - ${purchaseOffer.origin.address.city.toUpperCase()} - ${purchaseOffer.origin.address.uf.toUpperCase()}`,
      type: purchaseOffer.origin.type === 'LOCALITY' ? 'LOCALITY' : 'CLIENT',
    },
    destination: {
      ...purchaseOffer.destination,
      tradeName: `${purchaseOffer.destination.name.toUpperCase()} - ${purchaseOffer.destination.address.city.toUpperCase()} - ${purchaseOffer.destination.address.uf.toUpperCase()}`,
      type: purchaseOffer.destination.type === 'LOCALITY' ? 'LOCALITY' : 'CLIENT',
    },
    cargo: {
      comments: '',
      cubagem: 0,
      inPallet: !!purchaseOffer.capacity.pallet,
      merchValue: 0,
      order: '',
      pallet: purchaseOffer.capacity.pallet,
      product: '',
      requirements: {},
      shippingReturn: '',
      vehicleCategoryType: convertVehicleCategoryToEntity(purchaseOffer.vehicle.category) as VehicleCategory,
      vehicleType: convertVehicleTypeToEntity(purchaseOffer.vehicle.type) as VehicleType,
      volume: 0,
      weight: purchaseOffer.capacity.weight,
    },
    dates: [
      {
        initialLevyDate: '',
        finalLevyDate: '',
        initialDeliveryDate: '',
        finalDeliveryDate: '',
      },
    ],
    cost: {
      ...transportRouter,
      tollValue: 0,
      grisAdvalorem: 0,
      freightTotal: 0,
      icms: 0,
      totalService: 0,
    },
  };
};
