import { Bell } from 'components-v2/common/bell';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import React, { Fragment, useCallback, useMemo } from 'react';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { convertOperationType } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-table-tabs/components/schedule-table/schedule-table.types';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import * as S from './purchase-offer-table-view.styled';
import TooltipComponent from 'components/Tooltip';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

interface IPurchaseOfferTableViewProps {
  purchaseOffer: Array<IPurchaseOfferListDomain>;
  isAdmin: boolean;
  orderingColumns: IOrdering;
  isLoading: boolean;
  isEmpty: boolean;
  selectedIds: number[];
  infiniteScrollProps: IInfiniteScrollTable;
  handleLineClick: (id: number) => void;
  handleSelectIds: (checked: boolean, id: number) => void;
  handleOpenModalMatch: (purchaseOfferProposal: IPurchaseOfferListDomain) => void;
  handleScheduleSpotPage: (purchaseOfferProposal: IPurchaseOfferListDomain) => void;
}

export const PurchaseOfferTableView: React.FC<IPurchaseOfferTableViewProps> = ({
  purchaseOffer,
  isAdmin,
  orderingColumns,
  isLoading,
  isEmpty,
  selectedIds,
  handleLineClick,
  handleSelectIds,
  handleOpenModalMatch,
  handleScheduleSpotPage,
  infiniteScrollProps,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: isAdmin && 'Empresa' },
      { value: 'Tipo' },
      { value: 'Origem' },
      { value: 'Destino' },
      { value: 'Tipo de Veículo' },
      { value: 'Frequência' },
      { value: 'Tarifa Cadastrada' },
      { value: '' },
      { value: 'Matches' },
    ];

    const columnsConfig = isAdmin
      ? '0.2fr 0.4fr 0.8fr 0.6fr 1.5fr 1.5fr 1fr 1fr 0.75fr 0.25fr 0.5fr'
      : '0.2fr 0.4fr 0fr 0.6fr 1.5fr 1.8fr 1fr 1fr 0.75fr 0.25fr 0.5fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, [isAdmin]);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title?.toUpperCase() || '-'}</S.Title>
        <S.Subtitle>{subtitle?.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return purchaseOffer.map((offer) => {
      return {
        key: offer.id,
        onClick: () => handleLineClick(offer.id),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-${offer.id}`}
                id={'id'}
                name={'id'}
                onChange={(event) => handleSelectIds(event.target.checked, offer.id)}
                checked={selectedIds.includes(offer.id)}
              />
            ),
            isClicable: false,
          },
          { value: offer.id },
          { value: isAdmin && renderTitleSubtitle(offer.companyName, '') },
          {
            value: (
              <Tag
                label={offer.operationType}
                color={convertOperationType(offer.operationType).color}
                variant="ghost"
                display="inline-flex"
                size="md"
              />
            ),
          },
          {
            value: renderTitleSubtitle(
              offer.origin.name,
              `${offer.origin.address.uf || '-'} - ${offer.origin.address.city || '-'}`,
            ),
          },
          {
            value: renderTitleSubtitle(
              offer.destination.name,
              `${offer.destination.address.uf || '-'} - ${offer.destination.address.city || '-'}`,
            ),
          },
          {
            value: renderTitleSubtitle(offer.vehicle.type, offer.vehicle.category),
          },
          {
            value: renderTitleSubtitle(offer.frequency === 0 ? '-' : offer.frequency?.toString(), 'VIAGENS/MÊS'),
          },
          {
            value: renderTitleSubtitle(convertCurrencyFormat.format(offer.values.freight), 'SEM IMPOSTOS'),
          },
          {
            value: (
              <TooltipComponent
                title="Contratar Spot"
                location="left"
                icon={
                  <InsertInvitationIcon
                    sx={{ width: 18.5, height: 18.5 }}
                    htmlColor="grey"
                    onClick={() => handleScheduleSpotPage(offer)}
                  />
                }
              />
            ),
            isClicable: false,
          },
          {
            value: <Bell id={offer.id} count={offer.totalMatchOffers} onClick={() => handleOpenModalMatch(offer)} />,
            isClicable: false,
          },
        ],
      };
    });
  }, [
    handleLineClick,
    handleOpenModalMatch,
    handleScheduleSpotPage,
    handleSelectIds,
    isAdmin,
    purchaseOffer,
    renderTitleSubtitle,
    selectedIds,
  ]);

  if (isLoading)
    return (
      <S.Wrapper>
        <Loading />
      </S.Wrapper>
    );

  if (isEmpty)
    return (
      <S.EmptyListWrapper>
        <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
      </S.EmptyListWrapper>
    );

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={orderingColumns}
      rows={renderRows}
      height={'77vh'}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
