import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';

import * as S from './styled';
import { MobileChecklistForm } from './components/form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { mapMobileAnswers } from 'services/checklist/mappers';
import { getMobileQuestionsService } from 'services/checklist/get-checklist-questions-mobile';
import { createChecklistMobileDetailsService } from 'services/checklist/create-checklist-details-mobile';
import { ChecklistMobileAnswer, answerMobileInitialValues } from 'domain-v2/checklist';

const logshareImage = 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/logshare.png';

export const MobileChecklistSection: React.FC = () => {
  const { moduleId, id } = useParams();

  const [hasError, setHasError] = useState<boolean>(false);
  const [checklistAnswers, setChecklistAnswers] = useState<ChecklistMobileAnswer>();

  useEffect(() => {
    if (!moduleId || !id) return;

    (async () => {
      try {
        const data = await getMobileQuestionsService(+moduleId, +id);

        const answerValues = mapMobileAnswers(data, answerMobileInitialValues);

        setChecklistAnswers({ ...answerValues, checklistModule: 'schedule' });
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    })();
  }, [moduleId, id]);

  const handleSubmit = useCallback(
    async (values: ChecklistMobileAnswer) => {
      try {
        await createChecklistMobileDetailsService(Number(id ?? 0), values);

        showMessageFormatted({
          message: 'Checklist preenchido com sucesso.',
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    },
    [id],
  );

  const handleValidation = useCallback(
    (values: ChecklistMobileAnswer) => {
      const answersValidation = values.answers.some((answer) => answer.isRequired && answer.answer.length === 0);

      if (answersValidation || !values.name || !values.status) {
        setHasError(true);
        return;
      }

      handleSubmit(values);
    },
    [handleSubmit],
  );

  return (
    <S.Wrapper>
      <S.WrapperContainer>
        <S.WrapperMenu>
          <S.ContainerCircleMenu>
            <S.LogshareImage alt="" src={logshareImage} />
          </S.ContainerCircleMenu>
        </S.WrapperMenu>

        <S.WrapperSection>
          <S.WrapperHeader>
            <S.HeaderTitle>Checklist</S.HeaderTitle>
          </S.WrapperHeader>

          <S.WrapperInfo>
            <S.InfoTitle>Detalhes da Viagem</S.InfoTitle>

            <S.WrapperInfoTitle>
              <S.InfoTitle>ID:</S.InfoTitle>

              <S.Info>{id}</S.Info>
            </S.WrapperInfoTitle>

            <S.WrapperInfoTitle>
              <S.InfoTitle>Transportadora:</S.InfoTitle>

              <S.Info>{checklistAnswers?.shippingName ?? '-'}</S.Info>
            </S.WrapperInfoTitle>

            <S.WrapperInfoTitle>
              <S.InfoTitle>Placa(s):</S.InfoTitle>

              <S.Info>
                {checklistAnswers?.mainBoard} {checklistAnswers?.secondBoard} {checklistAnswers?.thirdBoard}
              </S.Info>
            </S.WrapperInfoTitle>
          </S.WrapperInfo>

          {!!checklistAnswers && (
            <Formik initialValues={checklistAnswers} onSubmit={handleValidation}>
              <MobileChecklistForm hasError={hasError} />
            </Formik>
          )}
        </S.WrapperSection>
      </S.WrapperContainer>
    </S.Wrapper>
  );
};
