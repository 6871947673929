import { scheduleRefusedListFiltersInitialValues } from '../../types/schedule-refused-list-filters/schedule-refused-list-filters-initial-values.types';
import { IScheduleRefusedContext, IScheduleRefusedContextError } from './types/schedule-refused.types';

export const initialError: IScheduleRefusedContextError = {
  title: '',
  description: '',
};

export const scheduleRefusedInitialState: IScheduleRefusedContext = {
  scheduleRefusedList: {
    statusRequest: 'INITIAL',
    scheduleRefusedList: [],
    setScheduleRefusedListRequest: () => undefined,
    setScheduleRefusedListSuccess: () => undefined,
    setScheduleRefusedListError: () => undefined,
    setScheduleRefusedListReset: () => undefined,
    scheduleRefusedListFilters: {
      filters: scheduleRefusedListFiltersInitialValues,
      setScheduleRefusedFilters: () => undefined,
    },
    scheduleRefusedListModals: {
      modalOptions: {
        modalType: '',
        open: false,
      },
      setScheduleRefusedModalOpen: () => undefined,
    },
  },
  setScheduleRefusedResetContext: () => undefined,
};
