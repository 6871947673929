import styled, { css } from 'styled-components';

interface IStyledProps {
  fontSize?: number;
  textAlign?: string;
  fontWeight?: number;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 100%;
  margin-top: 25px;
`;

export const InfoStyled = styled.p<IStyledProps>`
  ${({ fontSize, textAlign, fontWeight }) => css`
    font-size: ${fontSize ?? 10}px;
    text-align: ${textAlign ?? 'center'};
    font-weight: ${fontWeight};
    color: #000;
  `}
`;
