import styled, { css } from 'styled-components';

interface ITableStyledProps {
  flex?: number;
}

export const TableStyled = styled.table`
  ${({ theme }) => css`
    font-family: ${theme.font.family};
  `}
  width: 100%;
  height: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  table-layout: fixed;
`;

export const LineHeaderTableStyled = styled.tr`
  display: flex;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  padding: 10px;
  background-color: rgb(240 240 240);
`;

export const HeaderTableStyled = styled.th<ITableStyledProps>`
  ${({ flex }) => css`
    display: flex;
    flex: ${flex ?? 1};
    justify-content: flex-start;
    cursor: default;
    height: 16px;
    overflow: hidden;
    align-items: flex-end;
  `}
`;

export const HeaderTextStyled = styled.p<ITableStyledProps>`
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LineTableStyled = styled.div`
  display: flex;
  padding: 15px 10px 5px 10px;
  border-bottom: 1px solid #d1d1d1;

  :hover {
    background-color: #f9f9f9;
  }
`;

export const ContentTableStyled = styled.td<ITableStyledProps>`
  ${({ flex }) => css`
    display: flex;
    flex: ${flex ?? 1};
    cursor: default;
  `}
  overflow: hidden;
  text-transform: uppercase;
`;

export const InfoLocalityWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoLocalityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InfoLocality = styled.div`
  display: flex;
  gap: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextLocality = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SubTitleLocality = styled.div<ITableStyledProps>`
  font-size: 10px;
  font-weight: 500;
  color: #7b7b7b;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ContainerTextStyled = styled.div``;

export const TextStyled = styled.p<ITableStyledProps>`
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SubTitleStyled = styled.div<ITableStyledProps>`
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 5px;
  color: #7b7b7b;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ContainerLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DisplayNoneStyled = styled.div<ITableStyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableHeadStyled = styled.thead<ITableStyledProps>`
  background-color: white;
`;

export const TableBodyStyled = styled.div`
  height: calc(100vh - 342px);
  overflow: scroll;
`;

export const ConnectedPointsInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  text-transform: uppercase;
`;
