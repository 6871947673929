import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ISaleOfferFormRegisterPayloadDomain } from '../../domains/sale-offer-form-register.domain';
import {
  saleOfferRegisterService,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/services/sale-offer-register/sale-offer-register.service';
import {
  convertSaleOfferRegisterDomainToEntity,
} from './mappers/convert-sale-offer-register-domain-to-entity/convert-sale-offer-register-domain-to-entity.mapper';
import { useNavigate } from 'react-router-dom';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

export const useRegisterSaleOffer = () => {
  const navigate = useNavigate();
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payload: ISaleOfferFormRegisterPayloadDomain) => {
      try {
        if (!payload.saleOfferDetails.some((values) => values.tariff.length > 0)) {
          showMessageFormatted({
            message: 'Informe ao menos uma tarifa de frete para venda. ',
            type: 'error',
          });
          return;
        }

        await saleOfferRegisterService(convertSaleOfferRegisterDomainToEntity(payload));
        reactQueryClient.invalidateQueries(['sale-offer-list-table']);
        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });
        navigate(-1);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [navigate, reactQueryClient],
  );
};
