import { Formik } from 'formik';
import { FC, useCallback, useContext } from 'react';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { ISaleOfferDomain } from '../../domains/sale-offer-details.domain';
import { SaleOfferUpdateFormView } from './sale-offer-update-form-view.component';
import {
  useUpdateSaleOffer,
} from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/hooks/use-sale-offer-update/use-sale-offer-update.hook';
import { useNavigate } from 'react-router-dom';

export const SaleOfferUpdateForm: FC = () => {
  const { saleOfferDetails } = useContext(SaleOfferContext);

  const navigate = useNavigate();
  const saleOfferUpdateHook = useUpdateSaleOffer();

  const handleSubmit = useCallback(
    async (values: ISaleOfferDomain) => {
      await saleOfferUpdateHook(values);
      navigate(-1);
    },
    [navigate, saleOfferUpdateHook],
  );

  return (
    <Formik initialValues={saleOfferDetails.saleOfferDetails} onSubmit={handleSubmit}>
      <SaleOfferUpdateFormView />
    </Formik>
  );
};
