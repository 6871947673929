/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AcceptedMatchesListBodyView from './accepted-matches-list-body-view';
import Button from 'components/button/button';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile, downloadTemplate } from 'utils/download-file/download-file';
import { convertActiveTabToStatusDomainMapper } from 'pages/accepted-matches/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import { useAcceptedMatchesUpload } from 'pages/accepted-matches/hooks/use-accepted-matches-upload/use-accepted-matches-upload.hook';
import { useReportExcelHook } from 'pages/accepted-matches/hooks/use-accepted-matches-report-excel/use-accepted-matches-report-excel.hook';
import { useAcceptMatchesList } from 'pages/accepted-matches/hooks/use-accepted-matches-list/use-accepted-matches-list.hook';
import { useAcceptedMatchesDelete } from 'pages/accepted-matches/hooks/use-accepted-matches-delete/use-accepted-matches-delete.hook';
import { AcceptedMatchesContext } from 'pages/accepted-matches/context/table-states/accepted-matches.context';
import { ReportOption } from 'domain/report-files';
import { IAcceptedMatchesCreatedV2, IAcceptedMatchesStatus } from 'domain/accepted-matches';
import { showToast } from 'components/toast/toast';
import ModalUpload from 'components/modal-upload/modal-upload';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { TruckStl } from 'assets/icons/truck-stl';
import { TruckLtl } from 'assets/icons/truck-ltl';
import { TruckFtl } from 'assets/icons/truck-ftl';
import { useCustomNavigation } from './hooks/copy-register';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const AcceptedMatchesListBody: React.FC = (): JSX.Element => {
  const { acceptedMatches, acceptedMatchesIsLoading, acceptedMatchesPageInfo } = useContext(AcceptedMatchesContext);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeTabName, setActiveTabName] = useState<string>('Ativas');
  const [idsToRemove, setIdsToRemove] = useState<number[]>([]);

  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const [currentPage, setCurrentPage] = useState(1);

  const acceptedMatchesListHook = useAcceptMatchesList();
  const acceptedMatchesUploadHook = useAcceptedMatchesUpload();
  const GenerateExcelHook = useReportExcelHook();
  const [orderBy, setOrderBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');
  const acceptedMatchesDelete = useAcceptedMatchesDelete();

  const navigate = useNavigate();

  const goToCreatePage = useCallback(() => {
    navigate('/minhas-rotas/novo');
  }, [navigate]);

  const goToUpdatePage = useCallback(
    (acceptedMatchesId: string) => {
      navigate(`/minhas-rotas/atualizar/${acceptedMatchesId}`);
    },
    [navigate],
  );

  const [modalScheduleCreate, setModalScheduleCreate] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName),
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'MINHAS_ROTAS');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  const { handleCreateSchedule } = useCustomNavigation();

  const renderStatusByTabName = useMemo((): IAcceptedMatchesStatus | undefined => {
    if (activeTabName === 'Todos') {
      return undefined;
    }

    if (activeTabName === 'Ativas') {
      return 'ativo';
    }

    if (activeTabName === 'Inativos') {
      return 'inativo';
    }

    return undefined;
  }, [activeTabName]);

  useEffect(() => {
    acceptedMatchesListHook({
      searchValue: searchInput,
      currentPage: searchInput ? 1 : currentPage,
      status: renderStatusByTabName,
      order: sortDirection,
      column: orderBy,
    });
  }, [currentPage, renderStatusByTabName, searchInput, acceptedMatchesListHook, orderBy, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabName]);

  const handleDownload = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Template_Rotas.xlsx');
  }, []);

  const handleOpenUploadXlsx = async () => {
    setShowModal(true);
  };

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSuccess = useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await acceptedMatchesUploadHook(formData);
        if (data.uploadDate === 'error') {
          showToast({
            message: `Erro ao importar dados ${data.error.message}`,
            type: 'error',
          });
        } else {
          showToast({
            message: `Importação de ${data.total} arquivo(s) realizada com sucesso`,
            type: 'success',
          });
          acceptedMatchesListHook({
            searchValue: '',
            currentPage: 1,
            status: 'ativo',
            order: 'desc',
            column: 'id',
          });
          setShowModal(false);
        }
      } catch (error: any) {
        showMessageFormatted({
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    [acceptedMatchesListHook, acceptedMatchesUploadHook],
  );

  const handleRemoveIds = useCallback(
    async (ids: number[], exclude = false) => {
      if (ids.length === 0) {
        showToast({
          message: 'Escolha pelo menos uma oferta para excluir',
          type: 'error',
        });

        return;
      }

      if (exclude) {
        await acceptedMatchesDelete(ids);
        setModalConfirmDelete({ description: '', open: false });
        acceptedMatchesListHook({
          searchValue: searchInput,
          currentPage: currentPage,
          status: renderStatusByTabName,
          order: 'desc',
          column: 'id',
        });
        setIdsToRemove([]);
      } else {
        const description = (
          <>
            <p>Isso excluirá a oferta de compra da listagem.</p>
            <p style={{ marginTop: 5 }}>Tem certeza?</p>
          </>
        );
        setModalConfirmDelete({ description, open: true });
      }
    },
    [acceptedMatchesDelete, acceptedMatchesListHook, currentPage, renderStatusByTabName, searchInput],
  );

  const handleCopy = useCallback(() => {}, []);

  const handleCreateSchedulePage = async (value: IAcceptedMatchesCreatedV2) => {
    const description = (
      <div style={{ display: 'flex', gap: 20 }}>
        <Button
          title="FTL"
          bgColor={'blue'}
          callback={() => handleCreateSchedule('/alocacao/novo', value)}
          size="large"
          icon={<TruckFtl />}
          iconPosition="left"
        />
        <Button
          title="STL"
          bgColor={'middleBlue'}
          callback={() => handleCreateSchedule('/alocacao-stl/novo', value)}
          size="large"
          icon={<TruckStl />}
          iconPosition="left"
        />
        <Button
          title="LTL"
          bgColor={'aqua'}
          callback={() => handleCreateSchedule('/alocacao-ltl/novo', value)}
          size="large"
          icon={<TruckLtl />}
          iconPosition="left"
        />
      </div>
    );
    setModalScheduleCreate({ description, open: true });
  };

  return (
    <>
      {modalScheduleCreate.open && (
        <ModalConfirmation
          title="Novo Agendamento"
          secondTitle="Escolha a modalidade do frete."
          description={modalScheduleCreate.description}
          handleXCancel={() => setModalScheduleCreate((old) => ({ ...old, open: false }))}
          legacy
          size="small"
          type="normal"
        />
      )}
      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Rota"
          secondTitle={modalConfirmDelete.description}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveIds(idsToRemove, true)}
          size="small"
          type="delete"
          description={undefined}
        />
      )}
      {showModal && (
        <ModalUpload
          title="Cadastro de Rotas"
          description="Selecione o arquivo"
          handleCancel={showModalConfirmation}
          handleSuccess={handleSuccess}
          handleDownload={handleDownload}
        />
      )}
      <AcceptedMatchesListBodyView
        acceptedMatches={acceptedMatches}
        hasLoading={acceptedMatchesIsLoading}
        acceptedMatchesPageInfo={acceptedMatchesPageInfo}
        goToCreatePage={goToCreatePage}
        goToUpdatePage={goToUpdatePage}
        setCurrentPage={setCurrentPage}
        setSearchInput={setSearchInput}
        setActiveTabName={setActiveTabName}
        handleOpenUploadXlsx={handleOpenUploadXlsx}
        onHandleExport={handleGenerateExcel}
        options={Options}
        idsToRemove={idsToRemove}
        setIdsToRemove={setIdsToRemove}
        handleRemoveIds={handleRemoveIds}
        clickOrderBy={(e: string) => setOrderBy(e)}
        clickSortDirection={() => setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')}
        handleCopy={handleCopy}
        handleCreatePage={handleCreateSchedulePage}
      />
    </>
  );
};

export default AcceptedMatchesListBody;
