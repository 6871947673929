import React, { useMemo } from 'react';

import SantosPortTableView from './santos-port-table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface ISantosPortTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToDetailsPage: (santosPortId: string) => void;
  isLoading: boolean;
}

const SantosPortTable: React.FC<ISantosPortTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToDetailsPage,
  isLoading,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: 'Placa Veículo',
        flex: 0.8,
      },
      {
        value: 'ID',
        flex: 0.7,
      },
      {
        value: 'Status DLE',
        flex: 1.8,
      },
      {
        value: 'Tipo de Carga',
        flex: 1.8,
      },
      {
        value: 'CNPJ Origem',
        flex: 0.9,
      },
      {
        value: 'Acesso ao Porto',
        flex: 1,
      },
      {
        value: 'Saída do Terminal',
        flex: 1,
      },
    ];
  }, []);

  return (
    <SantosPortTableView
      headerTable={headerTable}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      isLoading={isLoading}
      clickRowAction={goToDetailsPage}
    />
  );
};

export default SantosPortTable;
