import { useCallback, useContext } from 'react';
import { IListPage } from 'src-new/domains/list-page.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { TransactionsContext } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { getTransactionsListService } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/get-transactions-list.service';
import { ITransactionDomain } from 'src-new/pages/financial/domains/transactions.domain';
import { convertFiltersDomainToRequest } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/mappers/convert-filters-domain-to-request/convert-filters-domain-to-request.mapper';
import { TransactionActiveTabName } from 'src-new/pages/financial/pages/transactions/contexts/transactions/types/transactions-list.types';

export const useGetTransactionsList = () => {
  const { transactionsList } = useContext(TransactionsContext);
  const {
    transactions,
    setTransactionsListRequest,
    setTransactionsListSuccess,
    setTransactionsListError,
    filterActions,
  } = transactionsList;
  const { filters, setFilters } = filterActions;
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { setInitialLoadingSuccess } = initialLoading;

  const handlePageLimit = useCallback(
    (page: number, totalPages: number) => {
      if (page === totalPages || totalPages === 0) {
        setFilters({ ...filters, isPageLimit: true });
      }
    },
    [filters, setFilters],
  );

  return useCallback(
    async (tabName: TransactionActiveTabName, financialStatusName: string) => {
      setTransactionsListRequest();

      try {
        const response: IListPage<ITransactionDomain> = await getTransactionsListService(
          convertFiltersDomainToRequest({ ...filters, activeTabName: tabName, financialStatus: financialStatusName }),
        );
        const oldClients = filters.page === 1 ? [] : transactions;

        handlePageLimit(filters.page, response.meta.totalPages);
        setTransactionsListSuccess([...oldClients, ...response.items]);
      } catch (error) {
        setTransactionsListError();
      }
      setInitialLoadingSuccess();
    },
    [
      setTransactionsListRequest,
      setInitialLoadingSuccess,
      filters,
      transactions,
      handlePageLimit,
      setTransactionsListSuccess,
      setTransactionsListError,
    ],
  );
};
