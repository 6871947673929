import { FC, Fragment, ReactElement, useMemo } from 'react';
import * as S from './schedule-details-body-view.styled';
import { ScheduleDetailsAccordions } from './components/schedule-details-accordions/schedule-details-accordions.component';
import { ScheduleDetailsAllocationAccordions } from './components/schedule-details-allocation-accordions/schedule-details-allocation-accordions.component';

interface IScheduleDetailsBodyViewProps {
  isDenied: boolean;
}

export const ScheduleDetailsBodyView: FC<IScheduleDetailsBodyViewProps> = ({ isDenied }) => {
  const renderContent = useMemo((): ReactElement => {
    if (isDenied) {
      return <ScheduleDetailsAllocationAccordions />;
    }

    return (
      <Fragment>
        <ScheduleDetailsAllocationAccordions />
        <ScheduleDetailsAccordions />
      </Fragment>
    );
  }, [isDenied]);

  return <S.Wrapper>{renderContent}</S.Wrapper>;
};
