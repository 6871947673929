import API from 'utils/API/API';
import { Moment } from 'moment/moment';

export interface ICreateInvoiceServiceParams {
  calcFinanceIds: Array<number>;
  issuanceDate?: Moment;
  dueDate?: string;
  cnpjEmissor?: string;
  paymentDay?: string;
  discount?: number;
  branchId?: number;
  addition?: number;
  description?: string;
}

export const createInvoiceService = async (params: ICreateInvoiceServiceParams) => {
  await API.post('v2/invoicing', params);
};
