import React from 'react';

import {
  CloseModalStyled,
  ContainerHeaderStyled,
  ContainerStyled,
  ContentStyled,
  TitleStyled,
} from './modal-image.styled';

export type ModalImageProps = {
  title: string;
  description: React.ReactNode;
  handleCancel?: () => void;
  overflow?: boolean;
  smallModal?: boolean;
  height?: number;
};
const ModalImageView: React.FC<ModalImageProps> = ({
  title,
  description,
  handleCancel,
  smallModal,
  height,
  overflow = false,
}) => {
  return (
    <ContainerStyled aria-label="Modal visualização de imagem">
      <ContentStyled overflow={overflow} smallModal={smallModal} height={height}>
        <ContainerHeaderStyled>
          <TitleStyled>{title}</TitleStyled>
          {handleCancel && (
            <div
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloseModalStyled onClick={handleCancel}>X</CloseModalStyled>
            </div>
          )}
        </ContainerHeaderStyled>
        {description}
      </ContentStyled>
    </ContainerStyled>
  );
};
export default ModalImageView;
