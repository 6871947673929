import { FC } from 'react';
import {
  IScheduleDetailsComplementDomain,
  IScheduleDetailsOriginAndDestinationDomain,
} from '../../../../domains/schedule-details.domain';
import * as S from './schedule-details-resume-origin-and-destination-view.styled';

interface IScheduleDetailsResumeOriginAndDestinationViewProps {
  origin: IScheduleDetailsOriginAndDestinationDomain;
  destination: IScheduleDetailsOriginAndDestinationDomain;
  detail: IScheduleDetailsComplementDomain;
  convertDates: (date: string) => string;
}

export const ScheduleDetailsResumeOriginAndDestinationView: FC<IScheduleDetailsResumeOriginAndDestinationViewProps> = ({
  origin,
  destination,
  detail,
  convertDates,
}) => {
  const connectorHeight = 137;
  return (
    <S.Wrapper>
      <S.WrapperOriginAndDestination>
        <S.StepIconSection>
          <S.StepIcon />
          <S.Connector connectorHeight={connectorHeight} />
          <S.StepIcon />
        </S.StepIconSection>

        <S.WrapperOriginAndDestinationInfo>
          <S.Div>
            <S.Text isStrong>origem</S.Text>
            <S.WrapperInfos>
              <S.Text isStrong>{origin.name}</S.Text>
              <S.Text>
                {origin.address.street} {origin.address.number}
              </S.Text>
              <S.Text>
                {origin.address.city} - {origin.address.uf}
              </S.Text>
              <S.Text>{origin.address.cep}</S.Text>
              <S.Text>
                {convertDates(detail.levyInitialDate)} - {convertDates(detail.levyEndDate)}
              </S.Text>
            </S.WrapperInfos>
          </S.Div>

          <S.Div>
            <S.Text isStrong>destino</S.Text>
            <S.WrapperInfos>
              <S.Text isStrong>{destination.name}</S.Text>
              <S.Text>
                {destination.address.street} {destination.address.number}
              </S.Text>
              <S.Text>
                {destination.address.city} - {destination.address.uf}
              </S.Text>
              <S.Text>{destination.address.cep}</S.Text>
              <S.Text>
                {convertDates(detail.deliveryInitialDate)} - {convertDates(detail.deliveryEndDate)}
              </S.Text>
            </S.WrapperInfos>
          </S.Div>
        </S.WrapperOriginAndDestinationInfo>
      </S.WrapperOriginAndDestination>
    </S.Wrapper>
  );
};
