import { FC, Fragment, ReactElement, useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { ScheduleOccurrenceModal } from '../../../schedule-list/components/schedule-table-tabs/components/schedule-occurrence-modal/schedule-occurrence-modal.component';
import { ScheduleListModals } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';

export interface IScheduleDetailsTabsViewProps {
  tabsContent: Array<ITabContent>;
  modalOptions: ScheduleListModals;
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const ScheduleDetailsTabsView: FC<IScheduleDetailsTabsViewProps> = ({
  tabsContent,
  modalOptions,
  activeTab,
  handleChangeTab,
}) => {
  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open && modalOptions.modalType === 'occurrences') {
      return <ScheduleOccurrenceModal />;
    }
  }, [modalOptions]);

  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
      {renderModals}
    </Fragment>
  );
};
