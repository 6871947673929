import React from 'react';

import { InstructionUse } from './components/instructions-use';
import { DocumentCertificate } from './components/document-certificate';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

export const DigitalCertificatePage: React.FC = () => {
  return (
    <>
      <HeaderPage breadcrumbList={['Configurações', 'Certificado Digital']} />
      <Title title="Configure ou atualize o seu certificado digital da sua empresa." hasTrace />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 20,
        }}
      >
        <InstructionUse />
        <DocumentCertificate />
      </div>
    </>
  );
};
