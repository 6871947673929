import React, { createContext, useMemo, useReducer } from 'react';
import { infiniteScrollInitialState } from './infinite-scroll-list-table.constants';
import { infiniteScrollListTableReducer } from './reducers/infinite-scroll-list-table.reducer';
import { IInfiniteScrollListTableContexttProps } from './infinite-scroll-list-table.types';
import { IInfiniteScrollListTableContext } from './types/infinite-scroll-list-table.types';
import { IInitialLoading } from 'src-new/contexts/infinite-scroll-list-table/types/initial-loading.types';
import { useInitialLoadingContextValues } from 'src-new/contexts/infinite-scroll-list-table/context-values/initial-loading/initial-loading-values.context';

export const InfiniteScrollListTableContext =
  createContext<IInfiniteScrollListTableContext>(infiniteScrollInitialState);

export const InfiniteScrollListTableContextProvider = ({ children }: IInfiniteScrollListTableContexttProps) => {
  const [state] = useReducer(infiniteScrollListTableReducer, infiniteScrollInitialState);

  const initialLoading: IInitialLoading = useInitialLoadingContextValues();

  const value: IInfiniteScrollListTableContext = useMemo(
    (): IInfiniteScrollListTableContext => ({
      ...state,
      initialLoading,
    }),
    [initialLoading, state],
  );

  return <InfiniteScrollListTableContext.Provider value={value}>{children}</InfiniteScrollListTableContext.Provider>;
};
