/* eslint-disable react-hooks/rules-of-hooks */
import { QueryCache, QueryFunction, QueryKey, UseQueryOptions, useQuery } from 'react-query';

const defaultOptions: Omit<UseQueryOptions<unknown, unknown, unknown, QueryKey>, 'queryKey' | 'queryFn'> = {
  staleTime: 60 * 10000,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export function queryBuilder<TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, unknown, TQueryFnData, QueryKey>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<TQueryFnData>(queryKey, queryFn, {
    ...defaultOptions,
    ...options,
  } as Omit<UseQueryOptions<TQueryFnData, unknown, TQueryFnData, QueryKey>, 'queryKey' | 'queryFn'>);
}

export const queryCache = new QueryCache();
