import styled, { css } from 'styled-components';

interface IResumeStyleProps {
  isOpen: boolean;
}

export const WrapperScroll = styled.main<IResumeStyleProps>`
  ${({ isOpen }) => css`
    min-width: ${isOpen ? '366px' : '80px'};
    max-width: ${isOpen ? '366px' : '80px'};
    position: relative;
  `}
`;

export const Wrapper = styled.div`
  background-color: #f3f3f3fa;
  border-radius: 0.375rem;
  padding: 2rem 1.75rem 5rem 1.75rem;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    width: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #949997;
  }
`;

export const ButtonOpenOrClose = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;

  top: 2rem;
  left: -1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 100px;
  border: 1px solid rgb(213, 213, 213);
`;

export const HeaderClose = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0rem 0rem 0.75rem 0rem;
`;

export const HeaderIconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const HeaderTitle = styled.h5`
  font-size: 0.875rem;
`;

export const WrapperInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
