import { useCallback, useContext } from 'react';
import { VehicleTypeDomain } from 'src-new/domains/vehicle-type.domain';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getFreetimeByVehicleService } from '../../services/get-freetime/get-freetime.service';

export const UseGetFreetime = () => {
  const { saleOfferFreetime } = useContext(SaleOfferContext);
  const { setSaleOfferFreetimeRequest, setSaleOfferFreetimeSuccess, setSaleOfferFreetimeError } = saleOfferFreetime;

  return useCallback(
    async (vehicleType: VehicleTypeDomain, companyId: number) => {
      try {
        setSaleOfferFreetimeRequest();
        const findFreetime = await getFreetimeByVehicleService(convertVehicleTypeToEntity(vehicleType), companyId);
        setSaleOfferFreetimeSuccess(findFreetime);
      } catch (err) {
        setSaleOfferFreetimeError();
        showMessageFormatted({
          message: 'Informe um veículo válido para calcular as tarifas.',
          type: 'error',
        });
      }
    },
    [setSaleOfferFreetimeError, setSaleOfferFreetimeRequest, setSaleOfferFreetimeSuccess],
  );
};
