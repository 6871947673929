import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  height: calc(100% - 98px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
`;

type SectionProps = { name: string };
export const Section = styled.section<SectionProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const ConatinerMultiSelect = styled.div`
  margin-top: 3px;
`;

export const WrapperFooterButton = styled.div`
  position: absolute;
  bottom: 32.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 60px);
`;
