import { IModalBackhaulPurchaseForm } from '../../types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { modalBackhaulPurchaseFormInitialValues } from '../../types/modal-backhaul-purchase-form/modal-backhaul-purchase-form-initial-values.constants';
import { IBackhaulPurchase } from '../../types/modal-backhaul-purchase/modal-backhaul-purchase.types';

export const convertBackhaulPurchaseToFormInitialValues = (
  backhaulPurchase: IBackhaulPurchase,
): IModalBackhaulPurchaseForm => {
  const { saleOffer, purchaseOffer } = backhaulPurchase;
  return {
    saleOfferId: saleOffer.id,
    saleOfferShipperTarifId: saleOffer.saleOfferTariffId,
    matchOfferId: saleOffer.matchOfferId ?? 0,
    companySellerId: saleOffer.company.id,
    invoicedBy: saleOffer.invoicedBy,
    haulType: saleOffer.haulType,
    co2Economy: saleOffer.co2Economy,
    origin: purchaseOffer?.origin ?? modalBackhaulPurchaseFormInitialValues.origin,
    destination: purchaseOffer?.destination ?? modalBackhaulPurchaseFormInitialValues.destination,
    cargo: {
      ...modalBackhaulPurchaseFormInitialValues.cargo,
      capacity: {
        inPallet: !!purchaseOffer?.capacity.pallets,
        pallets: purchaseOffer?.capacity.pallets ?? modalBackhaulPurchaseFormInitialValues.cargo.capacity.pallets,
        weight: purchaseOffer?.capacity.weight ?? modalBackhaulPurchaseFormInitialValues.cargo.capacity.weight,
        cubage: modalBackhaulPurchaseFormInitialValues.cargo.capacity.cubage,
        volume: modalBackhaulPurchaseFormInitialValues.cargo.capacity.volume,
      },
      vehicle: {
        type: purchaseOffer?.vehicle.type ?? saleOffer.vehicle.type,
        category: purchaseOffer?.vehicle.category ?? saleOffer.vehicle.category,
      },
    },
    dates: modalBackhaulPurchaseFormInitialValues.dates,
    cost: {
      ...modalBackhaulPurchaseFormInitialValues.cost,
      freightValue: saleOffer.values.freightValue ?? saleOffer.values.logshareValue,
    },
    retentionFines: {
      freeTime: '',
      hourlyFine: 0,
      dailyFine: 0,
    },
    leadTime: 0,
  };
};
