import React, { ReactElement } from 'react';
import { ApplicationPageHeaderView } from 'src-new/components/application-page-header/application-page-header-view.component';
import { IHeaderProps } from './application-page-header.types';

interface IRegistrationPageHeaderProps {
  headerProps: IHeaderProps;
}

export const ApplicationPageHeader: React.FC<IRegistrationPageHeaderProps> = ({ headerProps }): ReactElement => {
  return (
    <ApplicationPageHeaderView
      breadcrumbList={headerProps.breadcrumbList}
      title={headerProps.title}
      subtitle={headerProps.subtitle}
      primaryButtonProps={headerProps.primaryButtonProps}
      uploadModalProps={headerProps.uploadModalProps}
      inputSearchProps={headerProps.inputSearchProps}
      exportReportProps={headerProps.exportReportProps}
      secondaryButtonProps={headerProps.secondaryButtonProps}
      tertiaryButtonProps={headerProps.tertiaryButtonProps}
      filterProps={headerProps.filterProps}
      hasBackButton={headerProps.hasBackButton}
    />
  );
};
