import { userReportExcelService } from 'pages/user/services/location-report-excel/location-report-excel.service';
import { IUserStatus } from 'domain/user';

export interface ILocationReportExcelProps {
  search: string;
  status: IUserStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: ILocationReportExcelProps): Promise<BlobPart | undefined> => {
    return await userReportExcelService(params);
  };
};

export { useReportExcelHook };
