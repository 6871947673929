import Input from 'components/input/input';
import { FC, useMemo } from 'react';
import { InputRadio } from 'src-new/components/input-radio/input.radio.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { Table } from 'src-new/components/table/table.component';
import { IScheduleDetailsAllocationShippingDomain } from '../../../../../../../../domains/schedule-details-allocation-shipping.domain';
import * as S from './schedule-details-allocation-shipping-modal-view.styled';
import { Button } from 'logshare-ui-kit';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';

interface IStatusProps {
  isError: boolean;
  isLoading: boolean;
  isEmpty: boolean;
}

interface IScheduleDetailsAllocationShippingModalViewProps {
  statusProps: IStatusProps;
  shippings: Array<IScheduleDetailsAllocationShippingDomain>;
  shippingSelected: {
    checked: boolean;
    shippingId: number;
  };
  handleModalClose: () => void;
  handleSearch: (search: string) => void;
  infiniteScrollProps: IInfiniteScrollTable;
  handleShippingAllocation: (shippingId: number) => Promise<void>;
  handleSelectedShipping: (checked: boolean, shippingId: number) => void;
}

export const ScheduleDetailsAllocationShippingModalView: FC<IScheduleDetailsAllocationShippingModalViewProps> = ({
  statusProps,
  shippings,
  handleModalClose,
  handleSearch,
  infiniteScrollProps,
  handleShippingAllocation,
  handleSelectedShipping,
  shippingSelected,
}) => {
  const renderTableRows = useMemo(() => {
    return shippings.map((shipping, index) => {
      return {
        key: index,
        valuesColumns: [
          {
            value: (
              <InputRadio
                id={shipping.id ? shipping.id.toString() : '0'}
                name={`shipping_${shipping.id}`}
                value={shipping.id}
                onChange={(event) => handleSelectedShipping(event.target.checked, shipping.id)}
                checked={shippingSelected.shippingId === shipping.id}
              />
            ),
          },
          { value: shipping.name },
          { value: shipping.cnpj },
        ],
      };
    });
  }, [handleSelectedShipping, shippingSelected.shippingId, shippings]);

  const renderContent = useMemo(() => {
    if (statusProps.isLoading) return <Loading />;

    if (statusProps.isError) return <ErrorState />;

    if (statusProps.isEmpty) return <EmptyState />;

    return (
      <Table
        header={[{ value: '' }, { value: 'Nome' }, { value: 'CNPJ' }]}
        rows={renderTableRows}
        gridColumns={'0.2fr 1fr 1fr'}
        infiniteScrollProps={infiniteScrollProps}
        height="48vh"
      />
    );
  }, [infiniteScrollProps, renderTableRows, statusProps.isEmpty, statusProps.isError, statusProps.isLoading]);

  return (
    <ModalBark
      size={'md'}
      title={<S.AllocationModalShippingTitle>Transportadoras Cadastradas</S.AllocationModalShippingTitle>}
      handleClose={handleModalClose}
    >
      <S.AllocationModalShippingWrapper>
        <Input
          id="search"
          name="search"
          placeholder="Busque pelos dados cadastrais"
          type="text"
          changeValue={(text) => handleSearch(text)}
          isClear={false}
        />
        {renderContent}
        <S.WrapperButtons>
          <Button
            label={'Avançar'}
            color={'blue'}
            onPress={() => handleShippingAllocation(shippingSelected.shippingId)}
            variant={'solid'}
            size={'md'}
          />
        </S.WrapperButtons>
      </S.AllocationModalShippingWrapper>
    </ModalBark>
  );
};
