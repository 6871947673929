import * as Yup from 'yup';

export const OccurrenceUpdateSchema = Yup.object().shape({
  description: Yup.string()
    .required('Campo Obrigatório')
    .min(3, 'Mínimo de 3 Caracteres')
    .max(50, 'Máximo de 50 Caracteres'),

  impact: Yup.string().required('Campo Obrigatório'),
});
