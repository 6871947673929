import React, { createContext, useMemo, useReducer } from 'react';
import { equipmentVoucherInitialState } from './equipment-voucher.constants';
import { equipmentVoucherReducer } from './reducers/equipment-voucher.reducer';
import { IEquipmentVoucherContextProps } from './equipment-voucher.types';
import { IEquipmentVoucherContext } from './types/equipment-voucher.types';
import { IEquipmentVoucherList } from './types/equipment-voucher-list.types';
import { useEquipmentVoucherListContextValues } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/context-values/equipment-voucher-list/equipment-voucher-list-values.context';

export const EquipmentVoucherContext = createContext<IEquipmentVoucherContext>(equipmentVoucherInitialState);

export const EquipmentVoucherContextProvider = ({ children }: IEquipmentVoucherContextProps) => {
  const [state] = useReducer(equipmentVoucherReducer, equipmentVoucherInitialState);

  const equipmentVoucherList: IEquipmentVoucherList = useEquipmentVoucherListContextValues();

  const value: IEquipmentVoucherContext = useMemo(
    (): IEquipmentVoucherContext => ({
      ...state,
      equipmentVoucherList,
    }),
    [equipmentVoucherList, state],
  );

  return <EquipmentVoucherContext.Provider value={value}>{children}</EquipmentVoucherContext.Provider>;
};
