import { EquipmentVoucherListActionType } from '../../equipment-voucher.action';
import { IEquipmentVoucherListActions } from '../../types/equipment-voucher-list.types';
import { IEquipmentVoucherContext } from '../../types/equipment-voucher.types';

export const equipmentVoucherListReducer = (
  state: IEquipmentVoucherContext,
  action: IEquipmentVoucherListActions,
): IEquipmentVoucherContext => {
  switch (action.type) {
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_REQUEST:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          statusRequest: 'PROCESSING',
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          statusRequest: 'SUCCESS',
          equipmentList: action.payload,
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_ERROR:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          statusRequest: 'ERROR',
          equipmentList: [],
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SET_IDS:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          selectionProps: {
            ...state.equipmentVoucherList.selectionProps,
            selectedIds: action.payload,
          },
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_OPEN_MODAL:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          deleteModalProps: {
            ...state.equipmentVoucherList.deleteModalProps,
            isOpen: true,
          },
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_CLOSE_MODAL:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          deleteModalProps: {
            ...state.equipmentVoucherList.deleteModalProps,
            isOpen: false,
          },
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_SET_LOADING_MODAL:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          deleteModalProps: {
            ...state.equipmentVoucherList.deleteModalProps,
            isLoading: action.payload,
          },
        },
      };
    case EquipmentVoucherListActionType.EQUIPMENT_VOUCHER_LIST_RESET:
      return {
        ...state,
        equipmentVoucherList: {
          ...state.equipmentVoucherList,
          statusRequest: 'INITIAL',
          equipmentList: [],
          selectionProps: {
            ...state.equipmentVoucherList.selectionProps,
            selectedIds: [],
          },
          deleteModalProps: {
            ...state.equipmentVoucherList.deleteModalProps,
            isOpen: false,
            isLoading: false,
          },
        },
      };
    default:
      return state;
  }
};
