import { FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { PurchaseOfferModalMatchTableTabs } from './components/purchase-offer-modal-match-table-tabs/purchase-offer-modal-match-table-tabs.component';
import * as S from './purchase-offer-modal-match-view.styled';
import { PurchaseOfferModalMatchHeader } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-header/purchase-offer-modal-match-header.component';
import { PurchaseOfferModalMatchFooter } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-footer/purchase-offer-modal-match-footer.component';
import { ICompany } from 'domain/company';

interface IPurchaseOfferModalMatchViewProps {
  company: ICompany;
  handleCloseModalMatch: () => void;
}

export const PurchaseOfferModalMatchView: FC<IPurchaseOfferModalMatchViewProps> = ({
  company,
  handleCloseModalMatch,
}) => {
  return (
    <ModalBark size={'lg'} title={`Proposta de Compra - ${company.tradeName}`} handleClose={handleCloseModalMatch}>
      <S.Wrapper>
        <PurchaseOfferModalMatchHeader />
        <PurchaseOfferModalMatchTableTabs />
        <PurchaseOfferModalMatchFooter />
      </S.Wrapper>
    </ModalBark>
  );
};
