import API from 'utils/API/API';
import { CheckStubListParams, ICheckStubList } from 'domain-v2/check-stub';
import { IListPage } from 'domain/page-info';

const LIMIT_PAGE = 20;

export const checkStubListV2 = async (params: CheckStubListParams): Promise<IListPage<ICheckStubList>> => {
  const { data } = await API.get('v2/pallet/check-stub', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
    },
  });

  return {
    list: data.items,
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};
