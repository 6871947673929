type StatusEntity = 'active' | 'inactive';
type StatusDomain = 'ativo' | 'inativo';

export const convertStatusDomainToEntityMapper = (statusDomain: StatusDomain): StatusEntity => {
  switch (statusDomain) {
    case 'ativo':
      return 'active';
    case 'inativo':
      return 'inactive';
  }
};
