import styled, { css } from 'styled-components';

export const WrapperSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  border-radius: 0px;
  padding: 15px;
`;

export const CteListContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeaderTitleStyled = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 20px;
    margin-bottom: 15px;
  `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
  `}
  margin-bottom: 20px;
  width: 100%;
`;
