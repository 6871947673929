import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';
import axios, { AxiosError } from 'axios';
import { IUserChat } from 'src-new/domains/user-chat.domain';
import { IGetUserChatRequest } from './get-user-chat-by-id.types';
import { convertUserChatRequestToDomain } from './convert-user-chat-request-to-domain/convert-user-chat-request-to-domain.mapper';

export const getUserChatByIdService = async (id: string): Promise<IUserChat | undefined> => {
  const chatEnvironment = getChatEnvironment();

  try {
    const { data } = await axios.get<IGetUserChatRequest>(
      `https://api.talkjs.com/v1/${chatEnvironment.appId}/users/${id}`,
      {
        headers: {
          Authorization: `Bearer ${chatEnvironment.secretKey}`,
        },
      },
    );

    return convertUserChatRequestToDomain(data);
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw error;
    }
  }
};
