import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getShippingListService } from '../../services/get-shipping-list/get-shipping-list.service';
import { IScheduleDetailsAllocationShippingListProps } from '../../services/get-shipping-list/get-shipping-list.types';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { IScheduleDetailsAllocationShippingDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-shipping.domain';

export const UseShippingList = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const { setInitialLoadingSuccess } = initialLoading;

  return useCallback(
    async (params: IScheduleDetailsAllocationShippingListProps, setLimitPage: Dispatch<SetStateAction<boolean>>) => {
      scheduleDetails.allocationShipping.setScheduleDetailsAllocationShippingRequest();

      try {
        const scheduleListResponse = await getShippingListService({
          search: params.search,
          page: params.page,
          limitRegistry: params.limitRegistry,
          status: params.status,
        });
        const oldRecords: Array<IScheduleDetailsAllocationShippingDomain> =
          params.page === 1 ? [] : scheduleDetails.allocationShipping.shippingList;

        setLimitPage(
          scheduleListResponse.meta.totalPages === params.page || scheduleListResponse.meta.totalPages === 0,
        );
        scheduleDetails.allocationShipping.setScheduleDetailsAllocationShippingSuccess([
          ...oldRecords,
          ...scheduleListResponse.items,
        ]);
      } catch (error) {
        scheduleDetails.allocationShipping.setScheduleDetailsAllocationShippingError();
      }
      setInitialLoadingSuccess();
    },
    [scheduleDetails.allocationShipping, setInitialLoadingSuccess],
  );
};
