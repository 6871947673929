import { weighingListFilterInitialValues } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters-initial-values.domain';
import { IWeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing.types';
import { weighingFormDetailsInitialValuesDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details-initial-values.domain';

export const weighingInitialState: IWeighingContext = {
  weighingList: {
    statusRequest: 'INITIAL',
    weighingList: [],
    modalsProps: {
      modalOptions: { open: false, ids: [], modalType: '' },
      setModalOpen: () => undefined,

    },
    filters: {
      selectedFilter: weighingListFilterInitialValues,
      setSelectFilters: () => undefined,
      setResetFilters: () => undefined,
    },
    setWeighingListRequest: () => undefined,
    setWeighingListSuccess: () => undefined,
    setWeighingListError: () => undefined,
    setWeighingListReset: () => undefined,
  },
  weighingDetails: {
    statusRequest: 'INITIAL',
    weighingDetails: weighingFormDetailsInitialValuesDomain,
    setWeighingDetailsRequest: () => undefined,
    setWeighingDetailsSuccess: () => undefined,
    setWeighingDetailsError: () => undefined,
    setWeighingDetailsReset: () => undefined,
  },
  setWeighingResetContext: () => undefined,
};
