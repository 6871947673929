import styled, { css } from 'styled-components';

interface ITitleViewProps {
  isForm?: boolean;
}

export const TitleStyled = styled.h1<ITitleViewProps>`
  ${({ theme, isForm }) => css`
    font-family: ${theme.font.family};
    color: ${isForm ? 'white' : 'rgb(111, 111, 116)'};
    background-color: ${isForm ? theme.colors.blue : theme.colors.transparent};
    padding: ${isForm ? '10px 7px' : '4px 0'};
    font-weight: ${isForm ? '700' : '400'};
    line-height: ${isForm ? '10px' : '10px'};
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.transparent};
    margin-top: 0px;
  `}
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 6px;
`;
