import { IUsersContext, IUsersReducerAction } from './users.types';
import { IUsersAction } from './users.actions';

export const usersReducer = (state: IUsersContext, action: IUsersReducerAction): IUsersContext => {
  switch (action.type) {
    case IUsersAction.USERS_REQUEST: {
      return {
        ...state,
        usersIsLoading: true,
        users: [],
        usersError: null,
      };
    }
    case IUsersAction.USERS_SUCCESS: {
      return {
        ...state,
        usersIsLoading: false,
        users: action.payload.list,
        usersPageInfo: action.payload.pageInfo,
        usersError: null,
      };
    }
    case IUsersAction.USERS_ERROR: {
      return {
        ...state,
        usersIsLoading: false,
        users: [],
        usersError: action.payload,
        usersPageInfo: null,
      };
    }
    default:
      return state;
  }
};
