import API from 'utils/API/API';

interface ISaveUploadFileServiceProps {
  code: number | string;
  file: File;
  fileType: string;
  fileName: string;
  validateDate: string;
  module: 'client' | 'location' | 'shipping-company' | 'driver' | 'vehicle';
}

export const saveUploadFileService = async (props: ISaveUploadFileServiceProps): Promise<void> => {
  const formData: FormData = mountFormData(props);

  const { data } = await API.post<void>('v2/file/management/upload', formData);

  return data;
};

const mountFormData = (props: ISaveUploadFileServiceProps): FormData => {
  const formData = new FormData();

  formData.append('type', props.fileType.toUpperCase().trim());
  formData.append('module', props.module);
  formData.append('codigo', String(props.code));
  formData.append('expirationTime', String(props.validateDate));
  formData.append('fileName', String(props.fileName));
  formData.append('file', props.file);

  return formData;
};
