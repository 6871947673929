import CheckboxTag from 'components-v2/common/checkbox-tag';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { SectionLayout } from 'components-v2/layout/section';
import { InputCurrency } from 'components/input-currency/input-currency';
import InputField from 'components/input-field/input-field';
import Select from 'components/select/select';
import dayjs from 'dayjs';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { ICompany } from 'domain/company';
import { FreightPriceFTL } from 'domain/freight';
import {
  AnswerValueEnum,
  operationSubTypeOptions,
  shippingReturnOptions,
  vehicleCategoryOptions,
  vehicleTypeOptions,
  yesNoOptions,
} from 'domain/global-inputs';
import { useFormikContext } from 'formik';
import { useValidationFields } from 'pages-v2/schedule/context/validation-fields';
import { useGetFreightCalculate } from 'pages-v2/schedule/hooks/freight-calculate';
import { getScheduleOfferMatchService } from 'pages-v2/schedule/services/get-backhaul-freight';
import React, { Dispatch, memo, SetStateAction, useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { qualityRequirementsValues, securityRequirementsValues } from '../constants';
import * as S from './styled';
import { CustomLineSectionStyled } from './styled';
import { IOptionsDomain } from 'src-new/domains/options.domain';
import { useGetSaleOfferValue } from 'pages-v2/schedule/hooks/use-get-sale-offer-value/use-get-sale-offer-value.hook';
import { useGetIcms } from 'src-new/hooks/use-get-icms/get-icms.hook';
import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { VehicleTypeResponse } from 'src-new/domains/vehicle-type.domain';
import { INewClient } from 'domain/client';
import { ILocationCreated } from 'domain/location';
import { useGetFreetimeByVehicle } from 'src-new/hooks/use-get-freetime/use-get-freetime-by-vehicle.hook';
import { UserContext } from 'state/user-context';

const LOGSHARE_ID = 2;

interface IScheduleFormProps {
  company: ICompany;
  setLoadingFreightValues: Dispatch<SetStateAction<boolean>>;
}

const ScheduleCargoDetailsForm: React.FC<IScheduleFormProps> = ({ company, setLoadingFreightValues }) => {
  const { isShippingCompany } = useContext(UserContext);
  const { values, setFieldValue, errors, touched } = useFormikContext<ScheduleRegisterInput>();
  const { origin, destination, cargo, detail } = values;

  const { validateField, setScheduleValues } = useValidationFields();

  const [valueFreight, setValueFreight] = useState<FreightPriceFTL>();
  const [grisAdvalorem, setGrisAdvalorem] = useState<number>(0);

  const location = useLocation();

  const { id } = useParams();

  const getSchedulePricesHook = useGetFreightCalculate();
  const getSaleOfferValueHook = useGetSaleOfferValue();
  const getIcmsHook = useGetIcms();
  const getFreetimeByVehicleHook = useGetFreetimeByVehicle();

  useEffect(() => {
    if (!cargo.vehicleType) return;

    (async () => {
      const responseFreeTime = await getFreetimeByVehicleHook(cargo.vehicleType, LOGSHARE_ID);

      if (!responseFreeTime) return;

      let hourlyFine = responseFreeTime.hourlyFine;
      let dailyFine = responseFreeTime.dailyFine;

      if (isShippingCompany) {
        hourlyFine = hourlyFine * 0.8;
        dailyFine = dailyFine * 0.8;
      }

      setFieldValue('retentionFines.dailyFine', dailyFine);
      setFieldValue('retentionFines.hourlyFine', hourlyFine);
      setFieldValue('retentionFines.freeTime', `${responseFreeTime.freeTime} Horas`);
    })();
  }, [cargo.vehicleType, getFreetimeByVehicleHook, setFieldValue, isShippingCompany]);

  useEffect(() => {
    if (!id) {
      (async () => {
        if (
          !origin.id ||
          !destination.id ||
          !cargo.vehicleType ||
          !cargo.vehicleCategoryType ||
          !detail.levyInitialDate ||
          !detail.deliveryEndDate
        )
          return;

        setLoadingFreightValues(true);

        const responseFreight = await getSchedulePricesHook({
          originId: origin.id,
          originType: origin.type,
          destinyId: destination.id,
          destinyType: destination.type,
          weight: '0',
          capacity: '0',
          merchValue: '0',
          vehicleType: cargo.vehicleType,
          vehicleCategory: cargo.vehicleCategoryType,
          pickUpDate: dayjs(detail.levyInitialDate).format('DD/MM/YYYY'),
          dates: {
            levyInitialDate: dayjs(detail.levyInitialDate).toISOString(),
            levyFinalDate: dayjs(detail.levyEndDate).toISOString(),
            deliveryInitialDate: dayjs(detail.deliveryInitialDate).toISOString(),
            deliveryFinalDate: dayjs(detail.deliveryEndDate).toISOString(),
          },
        });

        setValueFreight(responseFreight);

        setLoadingFreightValues(false);
      })();
    }
  }, [
    cargo.vehicleCategoryType,
    cargo.vehicleType,
    destination.id,
    destination.type,
    detail.deliveryEndDate,
    detail.deliveryInitialDate,
    detail.levyEndDate,
    detail.levyInitialDate,
    getSchedulePricesHook,
    id,
    origin.id,
    origin.type,
    setLoadingFreightValues,
  ]);

  useEffect(() => {
    if (!id) {
      (async () => {
        if (!origin.id || !destination.id || !cargo.vehicleType || !cargo.vehicleCategoryType) return;

        setLoadingFreightValues(true);

        const [responseSaleOfferValue, icmsPercent] = await Promise.all([
          getSaleOfferValueHook({
            originId: origin.id,
            destinationId: destination.id,
            vehicleType: cargo.vehicleType.toUpperCase(),
            vehicleCategoryType: cargo.vehicleCategoryType.toUpperCase(),
          }),
          getIcmsHook(origin.origin.address.uf, destination.destination.address.uf),
        ]);

        if (responseSaleOfferValue) {
          const { valueTotal } = responseSaleOfferValue;
          const icmsValue = valueTotal / (1 - (icmsPercent ?? 0) / 100) - valueTotal;
          const totalServiceValue = valueTotal + icmsValue;

          setFieldValue('cost.saleOfferValue', responseSaleOfferValue);
          setFieldValue('cost.totalFreight', responseSaleOfferValue.valueTotal);
          setFieldValue('cost.icms', icmsValue);
          setFieldValue('cost.totalService', totalServiceValue);
        } else {
          setFieldValue('cost.icms', undefined);
        }

        setLoadingFreightValues(false);
      })();
    }
  }, [
    cargo.vehicleCategoryType,
    cargo.vehicleType,
    destination.destination.address.uf,
    destination.id,
    getIcmsHook,
    getSaleOfferValueHook,
    id,
    origin.id,
    origin.origin.address.uf,
    setFieldValue,
    setLoadingFreightValues,
  ]);

  useEffect(() => {
    if (!id) {
      const merchValue = String(cargo.merchValue);

      const gris = (converterNumberPtBrToUs(merchValue) * Number(company?.gris?.replace(',', '.'))) / 100;

      const advalorem = (converterNumberPtBrToUs(merchValue) * Number(company?.advalorem?.replace(',', '.'))) / 100;

      const sum = gris + advalorem;

      setGrisAdvalorem(sum);
    }
  }, [cargo.merchValue, company?.advalorem, company?.gris, id]);

  useEffect(() => {
    if (!id) {
      const { internal } = valueFreight || {};
      const { price, leadTime } = internal || {};
      const { details } = price || {};

      const { saleOfferValue, icms } = values.cost;

      const isSaleOfferValue = !!saleOfferValue && values.operation === 'TRACKING';

      const costFields = {
        'cost.valueFreight': price?.value || 0,
        'cost.valueGoods': details?.valueMerch || 0,
        'cost.valueTotalFee': details?.valueTotalFee,
        'cost.taxes': details?.taxes,
        'cost.totalFreight': isSaleOfferValue
          ? saleOfferValue.valueTotal + (grisAdvalorem ?? 0)
          : (price?.value || 0) + (grisAdvalorem || 0) + (values.cost?.toll ?? 0),
        'cost.helperTotal': price?.helperPrice,
        'cost.totalGrisAdvalorem': grisAdvalorem || 0,
        'cost.totalService': isSaleOfferValue
          ? saleOfferValue.valueTotal + (icms ?? 0) + (grisAdvalorem ?? 0)
          : (price?.value || 0) + (values.cost?.toll ?? 0) + (grisAdvalorem || 0) + (price?.icms || 0),
        'cost.leadTime': leadTime,
        'cost.icms': isSaleOfferValue ? icms : price?.icms,
        distance: valueFreight?.totalDistance?.length || 0,
      };

      Object.entries(costFields).forEach(([field, value]) => {
        setFieldValue(field, value);
      });
    }
  }, [valueFreight, id, grisAdvalorem, values.cost?.toll, setFieldValue]);

  useEffect(() => {
    if (!id) {
      (async () => {
        if (!origin.id || !destination.id || !cargo.vehicleType || !cargo.vehicleCategoryType) {
          return;
        }

        const originValues = {
          lat: origin.origin.lat,
          lng: origin.origin.lng,
        };

        const destinationValues = {
          lat: destination.destination.lat,
          lng: destination.destination.lng,
        };

        const data = await getScheduleOfferMatchService({
          originLatitude: originValues.lat,
          originLongitude: originValues.lng,
          destinationLatitude: destinationValues.lat,
          destinationLongitude: destinationValues.lng,
          vehicleType: cargo.vehicleType,
          vehicleCategory: cargo.vehicleCategoryType,
        });

        setFieldValue('backhaul.saleOfferMatches', data);
      })();
    }
  }, [
    origin,
    destination,
    cargo.vehicleType,
    cargo.vehicleCategoryType,
    id,
    cargo.weight,
    cargo.pallet,
    setFieldValue,
  ]);

  useEffect(() => {
    if (location.state?.vehicle && location.state?.capacity) {
      setFieldValue('cargo.vehicleType', location.state.vehicle.type);
      setFieldValue('cargo.vehicleCategoryType', location.state.vehicle.category);
      if (location.state?.capacity.pallet > 0) {
        setFieldValue('cargo.inPallet', true);
        setFieldValue('cargo.pallet', location.state?.capacity.pallet);
      }
      setFieldValue('cargo.weight', location.state?.capacity.weight);
    }
  }, [location.state, setFieldValue]);

  useEffect(() => {
    setScheduleValues({ ...values, cargo });
  }, [cargo]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const checkVehicleType = useCallback((location?: INewClient | ILocationCreated): Array<VehicleTypeResponse> => {
    if (!location) return [];

    if ('vehicleRestriction' in location) {
      return location.vehicleRestriction;
    }

    return location.vehicleType;
  }, []);

  const vehicleOptions = useMemo((): { vehicleType: Array<IOptionsDomain>; vehicleCategory: Array<IOptionsDomain> } => {
    const { origin } = values.origin;
    const { destination } = values.destination;

    const combinedVehicleType = [...checkVehicleType(origin), ...checkVehicleType(destination)];
    const combinedVehicleCategory = [...(origin.vehicleCategoryType ?? []), ...(destination.vehicleCategoryType ?? [])];

    const vehicleType = vehicleTypeOptions.map((option) => ({
      ...option,
      isDisabled: combinedVehicleType.length > 0 && !combinedVehicleType.some((item) => item === option.value),
    }));

    const vehicleCategory = vehicleCategoryOptions.map((option) => ({
      ...option,
      isDisabled: combinedVehicleCategory.length > 0 && !combinedVehicleCategory.some((item) => item === option.value),
    }));

    return { vehicleType, vehicleCategory };
  }, [checkVehicleType, values.destination, values.origin]);

  const renderOperationSubTypeOptions = useMemo((): Array<IOptionsDomain> => {
    if (values.origin.origin.operationSubType) {
      const operationTypeMapping: Record<string, string> = {
        ABASTECIMENTO: 'Abastecimento',
        TRANSPORTE: 'Deslocamento',
        COLETA: 'Coleta',
        DEVOLUÇÃO_DE_MERCADORIA: 'Devolução de Mercadoria',
        DEVOLUÇÃO_DE_EQUIPAMENTO: 'Devolução de Palete',
        TRANSFERENCIA: 'Transferência',
        TODOS: 'Outros',
      };

      const operationSubTypeValues = values.origin.origin.operationSubType.map((item) => operationTypeMapping[item]);

      return operationSubTypeOptions.map((option) => ({
        ...option,
        isDisabled: !operationSubTypeValues.some((item) => item === option.value),
      }));
    }

    return operationSubTypeOptions;
  }, [values.origin.origin.operationSubType]);

  return (
    <SectionLayout name="Cargo details information">
      <LineSectionLayout columns="1fr 1fr 1fr 1fr">
        <Select
          label="Tipo de Veículo *"
          id="cargo.vehicleType"
          name="cargo.vehicleType"
          value={cargo.vehicleType}
          setFieldValue={setFieldValue}
          options={vehicleOptions.vehicleType}
          hasError={
            (!!errors?.cargo?.vehicleType && !!touched?.cargo?.vehicleType) || (validateField && !cargo.vehicleType)
          }
          errorMessage="Campo Obrigatório"
          isClearable
          disabled={!!id}
        />
        <Select
          label="Categoria *"
          id="cargo.vehicleCategoryType"
          name="cargo.vehicleCategoryType"
          value={cargo.vehicleCategoryType}
          setFieldValue={setFieldValue}
          options={vehicleOptions.vehicleCategory}
          hasError={validateField && !cargo.vehicleCategoryType}
          errorMessage="Campo Obrigatório"
          isClearable
          disabled={!!id}
        />

        <Select
          label="Frete Retorno"
          id="cargo.shippingReturn"
          name="cargo.shippingReturn"
          value={cargo.shippingReturn}
          setFieldValue={setFieldValue}
          options={shippingReturnOptions}
          isClearable
        />

        <Select
          label="Tipo de Operação"
          id="cargo.operationSubType"
          name="cargo.operationSubType"
          value={cargo.operationSubType}
          setFieldValue={setFieldValue}
          options={renderOperationSubTypeOptions}
          isClearable
        />
      </LineSectionLayout>
      <LineSectionLayout columns="1fr 1fr 1fr 1fr 1fr 1fr">
        <InputCurrency
          label="Peso (Kg)"
          id="cargo.weight"
          name="cargo.weight"
          type="text"
          allowDecimals={true}
          decimalScale={2}
          decimalsLimit={2}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.weight}
          suffix=" Kg"
        />
        <InputCurrency
          label="Cubagem (m3)"
          id="cargo.cubagem"
          name="cargo.cubagem"
          type="number"
          suffix=" m³"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.cubagem}
        />
        <InputCurrency
          label="Volume(s)"
          id="cargo.volume"
          name="cargo.volume"
          type="number"
          suffix=" Vol"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.volume}
        />
        <InputCurrency
          label="Valor da Mercadoria"
          id="cargo.merchValue"
          name="cargo.merchValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          decimalSeparator=","
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.merchValue}
        />
        <Select
          label="Paletizado *"
          id="cargo.inPallet"
          name="cargo.inPallet"
          value={cargo.inPallet}
          setFieldValue={(field, value) => {
            if (value === AnswerValueEnum.SIM) {
              setFieldValue(field, value);
            } else {
              setFieldValue('pallet', '');
              setFieldValue(field, value);
            }
          }}
          options={yesNoOptions}
        />
        <InputField
          label="Paletes"
          id="cargo.pallet"
          name="cargo.pallet"
          type="number"
          onChange={handleChange}
          value={cargo.pallet}
          disabled={!cargo.inPallet}
        />
      </LineSectionLayout>
      <LineSectionLayout columns="1fr 1fr">
        <InputField
          label="Pedidos"
          id="cargo.order"
          name="cargo.order"
          type="text"
          onChange={handleChange}
          value={cargo.order}
        />

        <InputField
          label="Produto Predominante"
          id="cargo.product"
          name="cargo.product"
          type="text"
          onChange={handleChange}
          value={cargo.product}
        />
      </LineSectionLayout>
      <LineSectionLayout columns="1">
        <InputField
          label="Observações Gerais"
          id="cargo.comments"
          name="cargo.comments"
          type="text"
          onChange={handleChange}
          value={cargo.comments}
        />
      </LineSectionLayout>

      {!id && (
        <S.WrapperRequirementsStyled>
          <S.LabelRequirementsStyled>Requisitos Adicionais</S.LabelRequirementsStyled>
          <CustomLineSectionStyled columns="1fr 2fr 1fr 1fr 1fr 1fr">
            {qualityRequirementsValues.map((value, index) => {
              const qualityKey = value;
              const qualityValuesArray = cargo.requirements.quality?.values || [];
              const currentValues = Array.isArray(qualityValuesArray) ? qualityValuesArray : [];

              return (
                <CheckboxTag
                  key={qualityKey}
                  id={`cargo.requirements.quality.values.${index}`}
                  name={`cargo.requirements.quality.values.${index}`}
                  label={value}
                  bgColor={'blue'}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    setFieldValue(
                      'cargo.requirements.quality.values',
                      isChecked && cargo.requirements.quality
                        ? [...currentValues, value]
                        : currentValues.filter((v) => v !== value),
                    );
                  }}
                  value={''}
                  checked={currentValues.includes(value)}
                />
              );
            })}
          </CustomLineSectionStyled>

          <LineSectionLayout columns="0.5fr 0.6fr 0.5fr 0.7fr 0.7fr 0.5fr 0.7fr 0.5fr">
            {securityRequirementsValues?.map((value, index) => {
              const securityKey = value;
              const securityValuesArray = cargo.requirements.security?.values || [];

              const currentValues = Array.isArray(securityValuesArray) ? securityValuesArray : [];

              return (
                <CheckboxTag
                  key={securityKey}
                  id={`cargo.requirements.security.values.${index}`}
                  name={`cargo.requirements.security.values.${index}`}
                  label={value}
                  bgColor={'blue'}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    setFieldValue(
                      'cargo.requirements.security.values',
                      isChecked && cargo.requirements.security
                        ? [...currentValues, value]
                        : currentValues.filter((v) => v !== value),
                    );
                  }}
                  value={''}
                  checked={currentValues.includes(value)}
                />
              );
            })}
          </LineSectionLayout>
        </S.WrapperRequirementsStyled>
      )}
    </SectionLayout>
  );
};

export default memo(ScheduleCargoDetailsForm);
