import { HomeMatchesActionType } from '../../home.action';
import { IHomeMatchesActions } from '../../types/home-matches.types';
import { IHomeContext } from '../../types/home.types';

export const homeMatchesReducer = (state: IHomeContext, action: IHomeMatchesActions): IHomeContext => {
  switch (action.type) {
    case HomeMatchesActionType.HOME_MATCHES_REQUEST:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          statusRequest: 'PROCESSING',
        },
      };
    case HomeMatchesActionType.HOME_MATCHES_SUCCESS:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          statusRequest: 'SUCCESS',
          homeMatches: action.payload,
        },
      };
    case HomeMatchesActionType.HOME_MATCHES_ERROR:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          statusRequest: 'ERROR',
          homeMatches: [],
        },
      };
    case HomeMatchesActionType.HOME_MATCHES_NEXT_PAGE:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          paginationActions: {
            ...state.homeMatches.paginationActions,
            nextPage: action.payload,
          },
        },
      };
    case HomeMatchesActionType.HOME_MATCHES_PAGE_LIMIT:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          paginationActions: {
            ...state.homeMatches.paginationActions,
            isPageLimit: true,
          },
        },
      };
    case HomeMatchesActionType.HOME_MATCHES_RESET:
      return {
        ...state,
        homeMatches: {
          ...state.homeMatches,
          statusRequest: 'INITIAL',
          homeMatches: [],
          paginationActions: {
            ...state.homeMatches.paginationActions,
            nextPage: undefined,
            isPageLimit: false,
          },
        },
      };
    default:
      return state;
  }
};
