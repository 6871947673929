import { IDriverEntityStatus } from '../entities/driver.entity';

import API from 'utils/API/API';

export interface IDriverReportExcelProps {
  search: string;
  status: IDriverEntityStatus | undefined;
}

export const driverReportExcelService = async ({
  search,
  status,
}: IDriverReportExcelProps): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('v2/drivers/export', {
      params: {
        status,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao gerar xls de motorista');
  }
};
