import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import { convertStatusEntityToDomainMapper } from 'mapper/convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';
import { ColorsV2Type } from 'styles/types/colors';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { LabelStyled } from './location-table-view.styled';

interface ILocationTableViewProps {
  locations: Array<ILocationDomain>;
  infiniteScrollProps: IInfiniteScrollTable;
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleRowAction: (locationId: string) => void;
  ordering: IOrdering;
  isAdmin: boolean;
}

export const LocationTableView: React.FC<ILocationTableViewProps> = ({
  locations,
  ordering,
  isAdmin,
  infiniteScrollProps,
  handleIdsToRemove,
  handleRowAction,
}: ILocationTableViewProps) => {
  const renderLabel = useCallback((label: string) => {
    return <LabelStyled>{label?.toUpperCase() || '-'}</LabelStyled>;
  }, []);

  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: isAdmin && 'Parceiro' },
      { value: 'Tipo' },
      { value: 'Nome' },
      { value: 'Cidade - UF' },
      { value: 'Status' },
    ];

    const columnsConfig = `0.2fr 0.4fr ${isAdmin ? '0.8fr' : '0fr'} 0.8fr 1.5fr 1.3fr 0.4fr`;

    return {
      columnsConfig,
      headerColumns,
    };
  }, [isAdmin]);

  const renderRows = useMemo((): Array<IRow> => {
    return locations.map((location): IRow => {
      return {
        key: `${location.id}`,
        onClick: () => handleRowAction(location.id?.toString() ?? ''),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-location${location.id}`}
                id={'id'}
                name={'id'}
                value={String(location.id)}
                onChange={(e) =>
                  e.target.checked
                    ? handleIdsToRemove((prv) => [...prv, location.id ?? 0])
                    : handleIdsToRemove((prv) => [...prv.filter((id) => id !== location.id)])
                }
              />
            ),
            isClicable: false,
          },
          {
            value: location.id,
          },
          {
            value: renderLabel(isAdmin && location.company ? location.company?.tradeName : ''),
          },
          {
            value: renderLabel(location?.type ?? ''),
          },
          {
            value: renderLabel(location.name),
          },
          {
            value: renderLabel(location.address.city + ' - ' + location.address.uf),
          },
          {
            value: (
              <Tag
                label={convertStatusEntityToDomainMapper(location.status)}
                color={location.status === 'active' ? ColorsV2Type.Green : ColorsV2Type.Red}
                variant="solid"
                display="inline-flex"
                size="md"
              />
            ),
          },
        ],
      };
    });
  }, [handleIdsToRemove, handleRowAction, isAdmin, locations, renderLabel]);

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={ordering}
      rows={renderRows}
      height={'77vh'}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
