import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FormikProps, withFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';

import InputUploadFile from '../tracking-occurrence-input-upload/input-file-upload';

import {
  ContainerInfoDescriptionStyled,
  ContainerInfoStyled,
  ContainerListStyled,
  ContainerModalStyled,
  FormStyled,
  HeaderTitleDealings,
  LineSectionStyled,
  SectionStyled,
  TableStyled,
  WrapperSectionStyled,
} from './tracking-occurrence-register-form.styled';

import { sleep } from 'utils/index';
import { NegotiationsModalBody } from 'pages/tracking-occurrence/tracking-occurrence-update/components/tracking-occurrence-update-form/negotiations-modal/components/negotiations-modal-body/negotiations-modal-body';
import { occurrenceListTypesService } from 'pages/occurrence/services/occurrence-list-types/occurrence-list-types.service';
import FreightOrderDetailsSection from 'pages/freight/freight-update/freight-update-form/freight-register-section/freight-order-details-section/freight-order-details-section';
import { UploadImageStyled } from 'pages/freight/freight-update/freight-update-form/freight-register-section/freight-cargo-photos-section/freight-cargo-photos-section.styled';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { IOccurrenceResolution, ITrackingOccurrence } from 'domain/tracking-occurrence';
import { FooterPage } from 'components-v2/common/footer-page';
import { showToast } from 'components/toast/toast';
import Select, { SelectPropsOptions } from 'components/select/select';
import InputFieldTime from 'components/input-time/input-time';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

interface TrackingRegisterFormViewProps {
  trackingOccurrenceRegisterValues: ITrackingOccurrence;
  currentAllocation: ICurrentAllocation;
  schemaValidation: object;
  isLoading: boolean;
  handleGoBack: () => void;
  handleRegister: (_values: ITrackingOccurrence) => void;
}

const TrackingRegisterFormView: React.FC<TrackingRegisterFormViewProps> = ({
  trackingOccurrenceRegisterValues,
  currentAllocation,
  schemaValidation,
  handleGoBack,
  handleRegister,
}) => {
  const InnerForm = (props: FormikProps<ITrackingOccurrence>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const [title, setTitle] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [photosFiles, setPhotosFiles] = useState<File[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [occurrenceTypeError, setOccurrenceTypeError] = useState('');

    const [occurrencesTypes, setOccurrencesTypes] = useState<SelectPropsOptions[]>([]);

    const occurrenceTypesApiCall = useCallback(async () => {
      try {
        const response = await occurrenceListTypesService();

        setOccurrencesTypes(
          response.map((item) => ({
            label: item.description?.toUpperCase(),
            value: item.id,
          })),
        );
      } catch {
        showToast({
          type: 'error',
          message: 'Erro ao buscar tipos de ocorrências',
        });
      }
    }, []);

    useEffect(() => {
      occurrenceTypesApiCall();
    }, [occurrenceTypesApiCall]);

    const openModalNegotiations = () => {
      setShowModal(!showModal);
      setTitle('Adicionar Tratativa');
    };

    const handleRegisterOccurrence = (occurrenceValues: IOccurrenceResolution) => {
      if (values.occurrenceResolutions) {
        values.occurrenceResolutions.push(occurrenceValues);
        setShowModal(false);
      }
    };

    useEffect(() => {
      setFieldValue('osNumber', currentAllocation.id);
      setFieldValue('scheduleId', currentAllocation.id);
      setFieldValue('clientId', currentAllocation.scheduleClientOrigin?.client?.id ?? null);
      setFieldValue('localityId', currentAllocation.scheduleClientOrigin?.locality?.id ?? 0);
      setFieldValue('processType', currentAllocation.scheduleCargo?.operationType);
      setFieldValue('companyId', currentAllocation.companyId);
      setFieldValue(
        'cnpj',
        currentAllocation.scheduleClientOrigin?.locality?.cnpj === undefined
          ? currentAllocation.scheduleClientOrigin?.client?.cnpj
          : currentAllocation.scheduleClientOrigin?.locality?.cnpj,
      );
      setFieldValue('step', '1');
      setFieldValue('occurrenceDate', moment().format('DD/MM/YYYY HH:mm'));
      setFieldValue('occurrenceTime', moment().format('HH:mm'));
    }, [setFieldValue]);

    useEffect(() => {
      if (values.statusResolution === 'finalizadas') {
        setFieldValue('endDate', moment().format('DD/MM/YYYY'));
        setFieldValue('endTime', moment().format('HH:mm'));
      }
    }, [setFieldValue, values.statusResolution]);

    const dateDifference = useMemo((): string => {
      if (!values.occurrenceDate || !values.endDate) {
        setFieldValue('resolutionTime', '0 DIAS 0 HORAS');
        return '0 DIAS 0 HORAS';
      }

      const date1 = moment(values.occurrenceDate, 'DD/MM/YYYY');
      const date2 = moment(values.endDate, 'DD/MM/YYYY');
      const diffTime = Math.abs(Number(date2) - Number(date1));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const resolutionTime = `${diffDays.toString()} DIAS ${diffDays * 24} HORAS`;

      setFieldValue('resolutionTime', resolutionTime);

      return resolutionTime;
    }, [setFieldValue, values.endDate, values.occurrenceDate]);

    const handleAddPhoto = useCallback(async (file: File) => {
      if (file) {
        try {
          setIsLoading(true);
          const allPhotosFiles = [...photosFiles, file];
          setPhotosFiles(allPhotosFiles);
          setFieldValue('occurrenceFiles', allPhotosFiles);
          await sleep(500);
        } catch (error) {
          return;
        } finally {
          setIsLoading(false);
        }
      }
    }, []);

    const handleRemovePhoto = async (photoIndex: number) => {
      setIsLoading(true);
      setPhotosFiles((oldPhotos) => oldPhotos.filter((_, index) => index !== photoIndex));
      await sleep(500);
      setIsLoading(false);
    };

    const handleValidation = useCallback(() => {
      if (values.occurrenceTypeId < 0) {
        setOccurrenceTypeError('Campo Obrigatório');
        return;
      }
      handleSubmit();
    }, [values.occurrenceTypeId, handleSubmit]);

    return (
      <>
        <FreightOrderDetailsSection
          occurrenceStatus={'Não Atendida'}
          idSheduleValues={String(currentAllocation.id)}
          OccurrenceTime={dateDifference}
          type={currentAllocation.typeSchedule}
        />

        <FormStyled>
          <ContainerModalStyled>
            <NegotiationsModalBody
              title={title}
              setShowModal={setShowModal}
              showModal={showModal}
              submitModal={handleRegisterOccurrence}
            />
          </ContainerModalStyled>

          <SectionStyled name="Motoristas">
            <WrapperSectionStyled>
              <HeaderTitleDealings>Detalhes da Ocorrência</HeaderTitleDealings>
              <LineSectionStyled columns="0.5fr 0.5fr 0.5fr 0.5fr  0.7fr 1.3fr">
                <InputField
                  label="Data Inicial"
                  id="occurrenceDate"
                  name="occurrenceDate"
                  type="text"
                  onChange={handleChange}
                  value={values.occurrenceDate.split(' ')[0]}
                  hasError={!!errors?.occurrenceDate && !!touched?.occurrenceDate}
                  errorMessage={String(errors.occurrenceDate)}
                  disabled
                />

                <InputFieldTime
                  label="Horário Inicial"
                  id="occurrenceTime"
                  name="occurrenceTime"
                  type="text"
                  onChange={handleChange}
                  value={values.occurrenceTime}
                  hasError={!!errors?.occurrenceTime && !!touched?.occurrenceTime}
                  errorMessage={String(errors.occurrenceTime)}
                  disabled
                />

                <InputField
                  label="Data Final"
                  id="endDate"
                  name="endDate"
                  type="text"
                  onChange={handleChange}
                  value={values.endDate}
                  hasError={!!errors?.endDate && !!touched?.endDate}
                  errorMessage={String(errors.endDate)}
                  disabled
                />
                <InputFieldTime
                  label="Horário Final"
                  id="endTime"
                  name="endTime"
                  type="text"
                  onChange={handleChange}
                  value={values.endTime}
                  hasError={!!errors?.endTime && !!touched?.endTime}
                  errorMessage={String(errors.endTime)}
                  disabled
                />

                <InputField
                  label="Chamado"
                  id="ticketNumber"
                  name="ticketNumber"
                  type="text"
                  onChange={handleChange}
                  value={values.ticketNumber}
                />

                <Select
                  label="Tipo de Ocorrência"
                  id="occurrenceTypeId"
                  name="occurrenceTypeId"
                  value={values.occurrenceTypeId}
                  setFieldValue={(field: string, values: string) => {
                    setFieldValue(field, values);
                    setOccurrenceTypeError('');
                  }}
                  hasError={occurrenceTypeError ? true : false}
                  errorMessage={String(occurrenceTypeError)}
                  options={occurrencesTypes}
                />
              </LineSectionStyled>
            </WrapperSectionStyled>

            <WrapperSectionStyled>
              <HeaderTitleDealings>
                Tratativa(s)
                <div style={{ display: 'flex', gap: 15 }}>
                  <Button
                    title="Adicionar"
                    bgColor="blue"
                    callback={openModalNegotiations}
                    icon={<AddIcon />}
                    size="very-small"
                  />
                </div>
              </HeaderTitleDealings>
              {values.occurrenceResolutions &&
                values.occurrenceResolutions.map((occurrenceResolution, index) => (
                  <ContainerListStyled key={index}>
                    <LineSectionStyled columns="1fr 1fr 1fr 2fr">
                      <TableStyled>
                        Data
                        <ContainerInfoStyled>
                          {moment(occurrenceResolution.resolutionDate).format('DD/MM/YYYY HH:mm')}
                        </ContainerInfoStyled>
                      </TableStyled>

                      <TableStyled>
                        Tratada com
                        <ContainerInfoStyled>{occurrenceResolution.userResolutionNameWith}</ContainerInfoStyled>
                      </TableStyled>

                      <TableStyled>
                        Tratada por
                        <ContainerInfoStyled>{occurrenceResolution.userResolutionNameBy}</ContainerInfoStyled>
                      </TableStyled>

                      <TableStyled>
                        Descrição
                        <ContainerInfoDescriptionStyled>
                          <p style={{ wordWrap: 'break-word' }}>{occurrenceResolution.description?.toUpperCase()}</p>{' '}
                        </ContainerInfoDescriptionStyled>
                      </TableStyled>
                    </LineSectionStyled>
                  </ContainerListStyled>
                ))}
            </WrapperSectionStyled>

            <WrapperSectionStyled>
              <HeaderTitleDealings>Fotos/Registros</HeaderTitleDealings>
              <div style={{ marginTop: 20, marginRight: 20 }}>
                {isLoading ? (
                  <div style={{ fontSize: '12px', fontWeight: 600 }}>Carregando...</div>
                ) : (
                  <UploadImageStyled>
                    {photosFiles.map((photo, index) => {
                      return (
                        <div key={index} style={{ marginRight: 20 }}>
                          <InputUploadFile
                            urlFile={URL.createObjectURL(photo)}
                            handleSelectedImage={() => null}
                            deleteImage={() => handleRemovePhoto(index)}
                          />
                        </div>
                      );
                    })}
                    <InputUploadFile handleSelectedImage={handleAddPhoto} />
                  </UploadImageStyled>
                )}
              </div>
            </WrapperSectionStyled>
          </SectionStyled>

          <FooterPage.Root>
            <FooterPage.RightContent>
              <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleValidation} />
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
            </FooterPage.RightContent>
          </FooterPage.Root>
        </FormStyled>
      </>
    );
  };

  const TrackingRegisterFormView = withFormik<ITrackingOccurrence, ITrackingOccurrence>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <TrackingRegisterFormView {...trackingOccurrenceRegisterValues} />;
};

export default TrackingRegisterFormView;
