import React, { useCallback, useContext, useMemo } from 'react';
import { HeaderModalsView } from './header-modals-view.component';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IParamsFilter } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.types';
import { convertMatchesFilters } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.util';
import { useQueryClient } from 'react-query';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { modalBiddingOfferInitialValues } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer-initial-values.constants';
import { backhaulPurchaseInitialValues } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.constants';

export const HeaderModals: React.FC = () => {
  const { matchesKanbanList, modalMatchesKanban } = useContext(MatchesContext);
  const { purchaseOfferModals } = useContext(PurchaseOfferContext);
  const { saleOfferModals } = useContext(SaleOfferContext);

  const reactQueryClient = useQueryClient();

  const handleModalClose = useCallback(() => {
    matchesKanbanList.modalsProps.setModalOpen({ open: false, ids: [], modalType: '' });
  }, [matchesKanbanList.modalsProps]);

  const handleModalOfferClose = useCallback(() => {
    reactQueryClient.invalidateQueries(['favorite-matches-kanban-list']);
    reactQueryClient.invalidateQueries(['in-negociation-matches-kanban-list']);
    reactQueryClient.invalidateQueries(['operated-matches-kanban-list']);
    reactQueryClient.invalidateQueries(['stabilized-matches-kanban-list']);
    reactQueryClient.invalidateQueries(['inactive-matches-kanban-list']);

    modalMatchesKanban.setModalMatchesOpen({
      open: false,
      modalType: '',
      saleOfferMatch: modalBiddingOfferInitialValues,
      purchaseOfferMatch: backhaulPurchaseInitialValues,
    });
  }, [modalMatchesKanban, reactQueryClient]);

  const matchesFilters: Array<IParamsFilter> = useMemo(
    () => convertMatchesFilters(matchesKanbanList.matchesListFiltersProps.filters),
    [matchesKanbanList.matchesListFiltersProps.filters],
  );

  return (
    <HeaderModalsView
      modalOptions={matchesKanbanList.modalsProps.modalOptions}
      modalOfferOptions={modalMatchesKanban.modalMatchesOptions}
      purchaseOfferModalsOptions={purchaseOfferModals}
      saleOfferModalsOptions={saleOfferModals}
      matchesFilters={matchesFilters}
      handleModalClose={handleModalClose}
      handleModalOfferClose={handleModalOfferClose}
    />
  );
};
