import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { SectionDivider } from 'src-new/components/section-divider/section-divider.component';
import { SectionContentStyled } from './contact-details-section-view.styled';
import { ContactRegistrationModal } from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal.component';
import { ContactDetailsTable } from 'src-new/pages/registrations/components/contact-details-section/components/contact-details-table/contact-details-table.component';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Button } from 'logshare-ui-kit';

interface IContactDetailsSectionViewProps {
  isOpenModal: boolean;
  handleOpenRegistrationModal: () => void;
  handleCloseRegistrationModal: () => void;
}

export const ContactDetailsSectionView: React.FC<IContactDetailsSectionViewProps> = ({
  handleOpenRegistrationModal,
  handleCloseRegistrationModal,
  isOpenModal,
}) => {
  const renderModal = useCallback(
    (arrayHelpers: FieldArrayRenderProps): ReactElement | undefined => {
      if (isOpenModal) {
        return (
          <ContactRegistrationModal
            modalType={{ type: 'CREATE', arrayHelpers: arrayHelpers }}
            setModalClose={handleCloseRegistrationModal}
          />
        );
      }
    },
    [handleCloseRegistrationModal, isOpenModal],
  );

  const renderCreateButton = useMemo(() => {
    return (
      <Button label="Cadastrar Contato" color="green" onPress={handleOpenRegistrationModal} size="md" variant="solid" />
    );
  }, [handleOpenRegistrationModal]);

  return (
    <Fragment>
      <SectionDivider label="Dados para Contato" hidePin secondItem={renderCreateButton} />

      <FieldArray
        name="contacts"
        render={(arrayHelpers) => (
          <Fragment>
            {renderModal(arrayHelpers)}
            <SectionContentStyled>
              <ContactDetailsTable handleRemoveContact={arrayHelpers.remove} />
            </SectionContentStyled>
          </Fragment>
        )}
      />
    </Fragment>
  );
};
