/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import { Formik, FormikProps } from 'formik';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import BackhaulTable from '../table';
import { ModalMatchFilterForm } from '../components/filter';

import { Container, MatchPotentialCard, MatchPotentialContainer, MatchPotentialContent, Wrapper } from './styled';

import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { convertOperationType } from 'utils/operation-type';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { ISelectedBackhaul } from 'pages-v2/schedule/register/form/types/backhaul';
import { createIdlenessBiddingService } from 'pages/home/services/bidding/create-bidding/create-bidding.service';
import { LineConnectorHome } from 'pages/home/backhaul/components/connect-points';
import { getGradeSystemLabel, gradeSystemColor } from 'pages/hire-backhaul/constants/grade-system';
import { IIdlenessBiddingForm } from 'pages/biddings/idleness-list/types/form';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { IIdlenessBiddingCreate } from 'domain-v2/bidding';
import { PageMeta } from 'domain/page-info';
import { IMatchesListParams, IResponseMatches } from 'domain/matches';
import { IdlenessValues } from 'domain/idleness';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { IdlenessBiddingForm } from 'components-v2/idleness-bidding/modal-form';
import { ModalMatchForm } from 'components-v2/common/modals/match-form';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import { SubTitleStyled, TextStyled } from 'components/table/table.styled';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { TagText } from 'components/card-resume/styled';
import TooltipComponent from 'components/Tooltip';
import { HotIdeal } from 'assets/icons/hot-ideal';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';
import { DedicatedFleet } from 'assets/icons/dedicate-fleet.icon';

interface IBackhaulListBodyViewProps {
  matches: IResponseMatches[];
  idleness: IdlenessValues;
  backhaulBuyId: number;
  matchesScheduleModalStyles?: React.CSSProperties;
  matchesPickDateModalStyles?: React.CSSProperties;
  pageInfo: PageMeta;
  isLoading: boolean;
  isScheduleMatches: boolean;
  isTransportRouteMatches: boolean;
  orderSelected: { orderBy: string; sortDirection: string };
  handleFilter: (values: IMatchesListParams) => void;
  closeModal: () => void;
  setSelectedBackhaul?: React.Dispatch<React.SetStateAction<ISelectedBackhaul>>;
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
}

const initialFilterParams = {} as IMatchesListParams;
const initialValues: IIdlenessBiddingForm = {
  idlenessId: 0,
  value: 0,
  termsId: 0,
  proposalValue: 0,
};

const BackhaulListBodyView: React.FC<IBackhaulListBodyViewProps> = ({
  matches,
  idleness,
  backhaulBuyId,
  pageInfo,
  isLoading,
  isScheduleMatches,
  isTransportRouteMatches,
  orderSelected,
  handleFilter,
  closeModal,
  setSelectedBackhaul,
  clickOrderTableBy,
  clickSortDirection,
}: IBackhaulListBodyViewProps): JSX.Element => {
  const { user } = useContext(UserContext);

  const [modalFilter, setModalFilter] = useState<{
    open: boolean;
    values: IMatchesListParams;
  }>({
    open: false,
    values: initialFilterParams,
  });
  const [activeTab, setActiveTab] = useState<string>(isScheduleMatches ? 'Matches Propostos' : 'BACKHAUL EXTERNO');

  const [comprarModal, setComprarModal] = useState<{
    open: boolean;
    buyId: number;
    sellId: number;
    idleness?: IdlenessValues;
    match?: IResponseMatches;
  }>({
    open: false,
    buyId: backhaulBuyId,
    sellId: 0,
    idleness: idleness,
  });
  const [formBidding, setFormBidding] = useState<ModalState<{ idlenessId: number }>>({
    ...initialModalState,
    data: { idlenessId: 0 },
  });

  const FilterFormRef = useRef<FormikProps<IMatchesListParams>>(null);
  const biddingFormRef = useRef<FormikProps<IIdlenessBiddingCreate>>(null);

  const handleCreateBidding = useCallback(async (values: IIdlenessBiddingCreate) => {
    try {
      await createIdlenessBiddingService({
        ...values,
        value: Number(values.value),
        proposalValue: Number(values.value),
      });
      showMessageFormatted({
        message: 'Oferta enviada com sucesso.',
        type: 'success',
      });
      setFormBidding((old) => ({ ...old, open: false }));
    } catch (error: any) {
      showMessageFormatted({
        message: error.response.data.message || error.message,
        type: 'error',
      });
    }
  }, []);

  const contentTable = useMemo((): Array<IContentTable> => {
    return matches.map((match) => {
      const TotalFreight = convertCurrencyFormat.format(
        match.transportRouter?.freightValue +
          Number(match.transportRouter?.helperValue ?? 0) +
          Number(match.transportRouter?.tollAxis ?? 0),
      );

      const hasTotalFreight = TotalFreight.replace(/\s/g, '').replace('R$', '').replace(',', '.') === '0.00';

      const isInternalBackhaul =
        (match.transportRouter.origin?.companyId || match.transportRouter.destination?.companyId) === user?.companyId;

      const buttonBuyCallback = async (match: IResponseMatches) => {
        if (setSelectedBackhaul) {
          setSelectedBackhaul({
            transportRouteId: match.transportRouter.id,
            companySellerId: match.transportRouter.companyId,
            valueFreight:
              +match.transportRouter.freightValue +
              +match.transportRouter.helperValue +
              +match.transportRouter.tollAxis,
            co2Economy: match.co2Economy,
            invoicedBy: match.transportRouter.conductBy.toUpperCase(),
            cost: {
              freightValue: +match.transportRouter.freightValue,
              helperValue: +match.transportRouter.helperValue,
              tollAxis: +match.transportRouter.tollAxis,
              freightTotal: +match.transportRouter.freightTotal,
            },
          });

          closeModal();
        } else if (isTransportRouteMatches) {
          setFormBidding({
            open: true,
            data: { idlenessId: match.transportRouter?.id },
          });
        } else {
          setComprarModal((oldModal) => ({
            ...oldModal,
            match: match,
            open: true,
            sellId: match.transportRouter?.id,
          }));
        }
      };

      return [
        {
          value: '',
          flex: 0,
        },

        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{match?.transportRouter.id}</strong>

              {match.companyLogo && (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 25,
                    marginTop: 3,
                  }}
                  src={match.companyLogo}
                  alt="Company Logo"
                />
              )}
            </div>
          ),
          flex: 0.7,
        },

        {
          value: (
            <Tag
              label={convertOperationType(match.transportRouter.operationType).label}
              color={convertOperationType(match.transportRouter.operationType).color}
              variant="ghost"
              display="inline-flex"
              size="md"
            />
          ),
          flex: 0.8,
        },

        {
          value: (
            <Wrapper>
              <div style={{ paddingTop: '4px' }}>
                <LineConnectorHome height={39} bgColor="#D9D9D9" />
              </div>
              <Container>
                <div>
                  <TextStyled isStrong>
                    {`${match.transportRouter.origin.uf} - ${match.transportRouter.origin.city}`}
                  </TextStyled>

                  {isInternalBackhaul ? (
                    <SubTitleStyled>
                      {match.transportRouter.origin.tradeName ||
                        match.transportRouter.origin.fantasyName ||
                        match.transportRouter.origin.name}
                    </SubTitleStyled>
                  ) : (
                    <div style={{ paddingTop: '18px' }}></div>
                  )}
                </div>

                <div>
                  <TextStyled isStrong>
                    {`${match.transportRouter.destination.uf} - ${match.transportRouter.destination.city}`}
                  </TextStyled>

                  {isInternalBackhaul && (
                    <SubTitleStyled>
                      {match.transportRouter.destination.tradeName ||
                        match.transportRouter.destination.fantasyName ||
                        match.transportRouter.destination.name}
                    </SubTitleStyled>
                  )}
                </div>
              </Container>
            </Wrapper>
          ),
          flex: 1.5,
        },

        {
          value: convertVehicleTypeTypeToName(match.transportRouter.vehicleType),
          subtitle: convertVehicleCategoryTypeToName(
            match.transportRouter.vehicleCategory ?? match.transportRouter.category,
          ),
          flex: 0.6,
        },
        {
          value:
            match.transportRouter.fleetType === 'Frota Própria' ? (
              <TooltipComponent
                title="Frota Dedicada"
                location="right-start"
                icon={
                  <div style={{ marginTop: 2 }}>
                    <DedicatedFleet />
                  </div>
                }
              />
            ) : undefined,
          flex: 0.4,
        },

        {
          value: offeredWeight(match.transportRouter?.offeredWeight ?? 0),
          subtitle: offeredPallets(match.transportRouter?.offeredPallet ?? 0),
          flex: 1,
        },

        {
          value: (
            <MatchPotentialContainer>
              <MatchPotentialContent>
                {match.transportRouter.backhaulPartner ? (
                  <Tag
                    label="Backhaul Parceiro"
                    color={ColorsV2Type.Aqua}
                    variant="solid"
                    display="inline-flex"
                    size="md"
                  />
                ) : isInternalBackhaul ? (
                  <Tag
                    label="Backhaul Interno"
                    color={ColorsV2Type.Green}
                    variant="solid"
                    display="inline-flex"
                    size="md"
                  />
                ) : (
                  <Tag
                    label="Backhaul Externo"
                    color={ColorsV2Type.Blue}
                    variant="solid"
                    display="inline-flex"
                    size="md"
                  />
                )}
              </MatchPotentialContent>
              <div>
                <TooltipComponent
                  title="Economia Estimada"
                  location="bottom"
                  icon={
                    <Tag
                      LeftIcon={
                        <TagText>
                          <DoubleArrowDownIcon />
                          CO₂
                        </TagText>
                      }
                      label={Intl.NumberFormat('pt-BR', {
                        compactDisplay: 'short',
                      }).format(+match?.co2Economy)}
                      color={ColorsV2Type.Aqua}
                      variant="ghost"
                      display="inline-flex"
                      size="md"
                    />
                  }
                />
              </div>
              <div>
                <Tag
                  label={
                    'Desvio ' +
                    (match.synergy?.additionalDistance ? match.synergy.additionalDistance.split('.')[0] : 0) +
                    ' km'
                  }
                  color={ColorsV2Type.GrayDark}
                  variant="ghost"
                  display="inline-flex"
                  size="md"
                />
              </div>
            </MatchPotentialContainer>
          ),
          flex: 1.8,
        },

        {
          value: (
            <MatchPotentialContainer>
              <div>
                <strong>
                  {getGradeSystemLabel([
                    match.synergy.ruleVehicleGrade,
                    match.synergy.ruleOriginGrade,
                    match.synergy.ruleDestinationGrade,
                  ])}
                </strong>
              </div>
              <MatchPotentialContent>
                <TooltipComponent
                  title="Veículo"
                  location="bottom-start"
                  icon={
                    <MatchPotentialCard
                      style={{
                        backgroundColor: gradeSystemColor[match.synergy.ruleVehicleGrade],
                      }}
                    >
                      V
                    </MatchPotentialCard>
                  }
                />

                <TooltipComponent
                  title="Origem"
                  location="bottom-start"
                  icon={
                    <MatchPotentialCard
                      style={{
                        backgroundColor: gradeSystemColor[match.synergy.ruleOriginGrade],
                      }}
                    >
                      O
                    </MatchPotentialCard>
                  }
                />

                <TooltipComponent
                  title="Destino"
                  location="bottom-start"
                  icon={
                    <MatchPotentialCard
                      style={{
                        backgroundColor: gradeSystemColor[match.synergy.ruleDestinationGrade],
                      }}
                    >
                      D
                    </MatchPotentialCard>
                  }
                />
              </MatchPotentialContent>
              {match.transportRouter.backhaulPartner && (
                <Tag
                  LeftIcon={<HotIdeal />}
                  label="Tarifa Exclusiva"
                  color={ColorsV2Type.Red}
                  variant="ghost"
                  display="flex"
                  size="md"
                />
              )}
            </MatchPotentialContainer>
          ),
          flex: 1.2,
        },

        {
          value: match.transportRouter?.frequency || '-',
          subtitle: 'viagens/mês',
          flex: 1.2,
        },

        {
          value: TotalFreight,
          subtitle: '+ Impostos',
          flex: 1,
        },

        {
          value: (
            <Button
              label={setSelectedBackhaul ? 'Selecionar' : 'Tenho Interesse'}
              color="blue"
              size="md"
              variant="solid"
              onPress={() => buttonBuyCallback(match)}
              disabled={!isTransportRouteMatches && hasTotalFreight}
            />
          ),
          flex: 1.25,
        },
      ];
    });
  }, [matches]);

  const renderTransportRouteTable = useMemo(() => {
    return (
      <BackhaulTable
        isLoading={isLoading}
        contentTable={contentTable}
        orderSelected={orderSelected}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [contentTable, pageInfo, isLoading]);

  const tabsContent = [
    {
      name: isScheduleMatches ? 'Matches Propostos' : 'BACKHAUL EXTERNO',
      content: renderTransportRouteTable,
    },
    {
      name: isScheduleMatches ? '' : 'BACKHAUL INTERNO',
      content: renderTransportRouteTable,
    },
  ];

  return (
    <>
      {modalFilter.open && (
        <ModalConfirmation
          title="Escolha o Filtro"
          description={
            <>
              <Formik
                innerRef={FilterFormRef}
                initialValues={modalFilter.values}
                onSubmit={(values) => {
                  handleFilter(values);
                  setModalFilter({ open: false, values: values });
                }}
                onReset={() => {
                  handleFilter(initialFilterParams);
                  setModalFilter({ open: false, values: initialFilterParams });
                }}
              >
                <ModalMatchFilterForm />
              </Formik>
            </>
          }
          handleXCancel={() => setModalFilter((prevState) => ({ ...prevState, open: false }))}
          fullButtonWidth
          isRight
          legacy
          size="large"
          type="normal"
        />
      )}

      {comprarModal.open && (
        <ModalMatchForm
          buyId={comprarModal.buyId}
          transportRouterId={comprarModal.sellId}
          idleness={comprarModal.idleness}
          handleClose={() => setComprarModal((prevState) => ({ ...prevState, open: false }))}
          companyLogo={comprarModal?.match?.companyLogo}
          exclusiveRate
        />
      )}

      {formBidding.open && (
        <Formik
          innerRef={biddingFormRef}
          initialValues={initialValues}
          onSubmit={(values) =>
            handleCreateBidding({
              ...values,
              value: values.value,
            })
          }
          enableReinitialize
        >
          <IdlenessBiddingForm
            idlenessId={formBidding.data.idlenessId}
            closeModal={() => setFormBidding((old) => ({ ...old, open: false }))}
            isTransportRouter
          />
        </Formik>
      )}

      <div>
        <Tabs
          tabsContent={tabsContent}
          setActiveTabName={(name) => {
            setActiveTab(name);
            handleFilter({
              ...initialFilterParams,
              offerTo: name,
              column: 'match',
              order: 'ASC',
            });
          }}
          tabActive={activeTab}
          icon={
            <div
              style={{
                height: '25px',
                marginTop: '20px',
                display: isScheduleMatches ? 'none' : 'flex',
              }}
            >
              <FilterAltIcon
                onClick={() => setModalFilter((prevState) => ({ ...prevState, open: true }))}
                sx={{ height: '25px', color: '#1F2B4F' }}
              />
            </div>
          }
        />{' '}
      </div>
    </>
  );
};

export default BackhaulListBodyView;
