import { CardResumeValuesValidation } from './CardResumeValuesValidation';
import { CardResumeValues } from './CardResumeValues';
import { CardResumeRoot } from './CardResumeRoot';
import { CardResumeOperation } from './CardResumeOperation';
import { CardResumeHeader } from './CardResumeHeader';
import { CardResumeFines } from './CardResumeFines';
import { CardResumeDelivery } from './CardResumeDelivery';
import { CardResumeCollection } from './CardResumeCollection';

export const ScheduleCardResume = {
  Root: CardResumeRoot,
  Header: CardResumeHeader,
  Collection: CardResumeCollection,
  Delivery: CardResumeDelivery,
  Operation: CardResumeOperation,
  ValuesValidation: CardResumeValuesValidation,
  Values: CardResumeValues,
  Fines: CardResumeFines,
};
