import API from 'utils/API/API';

export type ImportRecordsType = 'CLIENT' | 'LOCATION';

export const importRecordsSpreadsheetService = async (formData: FormData, type: ImportRecordsType) => {
  return await API.post(`v2/${getUrlRequestByType(type)}/upload`, formData);
};

const getUrlRequestByType = (type: ImportRecordsType) => {
  switch (type) {
    case 'LOCATION':
      return 'localities';
    case 'CLIENT':
      return 'clients';
  }
};
