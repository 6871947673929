import { Tooltip } from '@mui/material';
import TrashIcon from 'assets/icons/trash.icon';
import React, { useMemo } from 'react';
import Theme from 'styles/theme';
import {
  CompanyImageStyled,
  ContainerStyled,
  FileNameStyled,
  PdfImageContainer,
  PdfImageStyled,
  TrashIconContainerStyled,
} from './input-upload-image.styled';

type InputUploadImageViewProps = {
  hasError: boolean;
  dataUrlImage: string;
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDelete?: () => void;
  goToImageView?: () => void;
  centered?: boolean;
  profilePhoto?: boolean;
  isProfileImage?: boolean;
  fileType?: string;
  fileName: string;
  goToOpenUrl: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const InputUploadImageView: React.FC<InputUploadImageViewProps> = ({
  hasError,
  dataUrlImage,
  handleClick,
  goToImageView,
  handleDelete,
  centered,
  profilePhoto,
  isProfileImage,
  fileType,
  fileName,
  goToOpenUrl,
}) => {
  const renderImageComponent = useMemo(() => {
    if (dataUrlImage) {
      if (fileType === 'image') {
        return (
          <Tooltip title={<span style={{ fontSize: '11px' }}>{fileName}</span>} placement="top">
            <CompanyImageStyled src={dataUrlImage} alt="" onClick={goToImageView} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title={<span style={{ fontSize: '11px' }}>{fileName}</span>} placement="top">
          <PdfImageContainer>
            <PdfImageStyled src={dataUrlImage} alt="" onClick={goToOpenUrl} />
            <FileNameStyled>{fileName?.length > 15 ? `${fileName?.slice(0, 20)}...` : fileName}</FileNameStyled>
          </PdfImageContainer>
        </Tooltip>
      );
    }

    if (isProfileImage) {
      return <Theme.icons.profileImage />;
    }

    return <Theme.icons.uploadIcon />;
  }, [fileType, dataUrlImage, isProfileImage, fileName, goToImageView, goToOpenUrl]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: centered ? 'center' : 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContainerStyled
          aria-label="input upload de imagem"
          onClick={(e) => {
            if (!dataUrlImage || profilePhoto) handleClick(e);
          }}
          hasError={hasError}
          dataUrlImage={dataUrlImage}
        >
          {renderImageComponent}
        </ContainerStyled>
        {dataUrlImage && handleDelete && (
          <TrashIconContainerStyled onClick={() => handleDelete()}>
            <TrashIcon />
          </TrashIconContainerStyled>
        )}
      </div>
    </div>
  );
};
export default InputUploadImageView;
