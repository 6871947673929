import React, { FC, useCallback } from 'react';

import { FileListUploaded } from 'pages/freight/types';
import { useHandleDeleteFileUploadedListHook } from 'pages/client/hooks';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface Props {
  file: FileListUploaded;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export const ModalDeleteUploadedCte: FC<Props> = ({ file, isOpen, onClose, onSuccess }) => {
  const handleDeleteCte = useHandleDeleteFileUploadedListHook();

  const handleDeleteCTe = useCallback(async () => {
    try {
      await handleDeleteCte(file.id);
      onSuccess();
    } catch (error) {
      showToast({
        message: 'Erro ao deletar CTe',
        type: 'error',
      });
    }
  }, [file, handleDeleteCte, onSuccess]);

  if (!isOpen) return null;

  return (
    <ModalConfirmation
      secondTitle={
        <>
          <p>Tem certeza que deseja excluir o anexo?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </>
      }
      title="Exclusão de Anexo"
      size="small"
      type="delete"
      handleCancel={onClose}
      onConfirmation={handleDeleteCTe}
      description={undefined}
    />
  );
};
