import {
  VehicleCategoryNameWithEmpty,
  VehicleCategoryWithEmpty,
  VehicleTypeNameWithEmpty,
  VehicleTypeWithEmpty,
} from 'domain/global';

export const convertVehicleTypeTypeToName = (
  vehicleType: VehicleTypeWithEmpty | string | undefined,
): VehicleTypeNameWithEmpty | 'NÃO MAPEADO' => {
  switch (vehicleType) {
    case '3/4':
      return '3/4';
    case 'bitrem':
      return 'BITREM';
    case 'bitruck':
      return 'BITRUCK';
    case 'carreta':
      return 'CARRETA';
    case 'carreta_sete_eixos':
      return 'CARRETA 7 EIXOS';
    case 'carreta_ls':
      return 'CARRETA LS';
    case 'complemento':
      return 'COMPLEMENTO';
    case 'rodotrem':
      return 'RODOTREM';
    case 'romeu&julieta':
      return 'ROMEU & JULIETA';
    case 'romeu_julieta':
      return 'ROMEU & JULIETA';
    case 'toco':
      return 'TOCO';
    case 'truck':
      return 'TRUCK';
    case 'utilitario':
      return 'UTILITÁRIO';
    case 'van':
      return 'VAN';
    case 'vuc':
      return 'VUC';
    case 'vanderleia':
      return 'VANDERLÉIA';
    default:
      return '';
  }
};

export const convertVehicleCategoryTypeToName = (
  vehicleCategory?: VehicleCategoryWithEmpty | string | undefined,
): VehicleCategoryNameWithEmpty => {
  if (!vehicleCategory) return '';
  const vehicleCategoryEntity = vehicleCategory.toLowerCase();
  switch (vehicleCategoryEntity) {
    case 'bau_seco':
      return 'BAÚ SECO';
    case 'bau_frigorificado':
      return 'BAÚ FRIGORIFICADO';
    case 'bau_refrigerado':
      return 'BAÚ REFRIGERADO';
    case 'bau_sider':
      return 'SIDER';
    case 'cacamba_basculante':
      return 'BASCULANTE';
    case 'carreta':
      return 'CARRETA';
    case 'cegonha':
      return 'CEGONHA';
    case 'complemento':
      return 'COMPLEMENTO';
    case 'florestal':
      return 'FLORESTAL';
    case 'grade_baixa':
      return 'GRADE BAIXA';
    case 'graneleiro':
      return 'GRANELEIRO';
    case 'plataforma':
      return 'PLATAFORMA';
    case 'porta_container_20':
      return 'PORTA CONTAINER 20 PES';
    case 'porta_container_40':
      return 'PORTA CONTAINER 40 PES';
    case 'tanque':
      return 'TANQUE';
    default:
      return '';
  }
};

export const convertVehicleCategoryNameToType = (
  vehicleCategoryEntity: VehicleCategoryNameWithEmpty,
): VehicleCategoryWithEmpty => {
  switch (vehicleCategoryEntity) {
    case 'BAÚ SECO':
      return 'bau_seco';
    case 'BAÚ FRIGORIFICADO':
      return 'bau_frigorificado';
    case 'BAÚ REFRIGERADO':
      return 'bau_refrigerado';
    case 'SIDER':
      return 'bau_sider';
    case 'BASCULANTE':
      return 'cacamba_basculante';
    case 'CARRETA':
      return 'carreta';
    case 'CEGONHA':
      return 'cegonha';
    case 'COMPLEMENTO':
      return 'complemento';
    case 'FLORESTAL':
      return 'florestal';
    case 'GRADE BAIXA':
      return 'grade_baixa';
    case 'GRANELEIRO':
      return 'graneleiro';
    case 'PLATAFORMA':
      return 'plataforma';
    case 'PORTA CONTAINER 20 PES':
      return 'porta_container_20';
    case 'PORTA CONTAINER 40 PES':
      return 'porta_container_40';
    case 'TANQUE':
      return 'tanque';
    default:
      return '';
  }
};

export const convertStatusLabelToEntity = (statusCode: string): string => {
  switch (statusCode) {
    case 'Agendado':
      return 'AG10';
    case 'Aguardando Alocação':
      return 'AL10';
    case 'Alocado':
      return 'AL20';
    case 'Aguardando Motorista':
      return 'IN10';
    case 'Aguardando Início de Rota':
      return 'IN11';
    case 'Em Rota para Carregamento':
      return 'IN20';
    case 'Aguardando Carregamento':
      return 'IN30';
    case 'Em Carregamento':
      return 'IN40';
    case 'Coleta Realizada':
      return 'IN50';
    case 'Em Rota para Descarga':
      return 'IN60';
    case 'Em Descarga':
      return 'IN70';
    case 'Descarga Finalizada':
      return 'IN90';
    case 'Ag. Aceite Transportadora':
      return 'OT01';
    case 'Alocação Cancelada':
      return 'AL99';
    case 'Alocação Cancelada com Custo':
      return 'AL90';
    default:
      return 'Não Mapeado';
  }
};

export const convertShippingReturn = (shippingReturn: string | undefined): string => {
  switch (shippingReturn) {
    case 'empty':
      return 'Vazio';
    case 'loaded':
      return 'Carregado';
    case 'not_return':
      return 'Não Retorna';
    default:
      return 'Não Mapeado';
  }
};

export const convertNeedHelper = (needHelper: string | undefined): string => {
  switch (needHelper) {
    case 'sem_ajuda':
      return 'Sem Ajudante';
    case 'carregamento':
      return 'No Carregamento';
    case 'descarga':
      return 'Na Descarga';
    case 'ambos':
      return 'Ambos';
    default:
      return 'Não Informado';
  }
};
