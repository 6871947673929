import { useCallback, useContext, useMemo } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IScheduleHistoryDriverAndVehicleDomain } from '../../domains/schedule-details-history-driver-and-vehicle.domain';
import { getScheduleHistoryDriverAndVehicleListService } from '../../services/get-schedule-history-driver-and-vehicle-list/get-schedule-history-driver-and-vehicle-list.service';

export const UseGetScheduleHistoryDriver = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const { isLoading } = useQuery<Array<IScheduleHistoryDriverAndVehicleDomain>>({
    queryKey: ['schedule-history-driver', scheduleId],
    queryFn: () => getScheduleHistoryDriverAndVehicleListService(scheduleId, 'MOTORISTA'),
    onSuccess(data) {
      scheduleDetails.allocationDriver.accordionProps.setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess(
        data,
        scheduleDetails.allocationVehicle.accordionProps.historyList.historyVehicleList,
      );
    },
    onError(error) {
      scheduleDetails.allocationDriver.accordionProps.setScheduleDetailsScheduleHistoryDriverAndVehicleError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnMount: true,
  });

  return useCallback(() => {
    if (isLoading) {
      scheduleDetails.allocationDriver.accordionProps.setScheduleDetailsScheduleHistoryDriverAndVehicleRequest();
    }
  }, [isLoading, scheduleDetails]);
};
