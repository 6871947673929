import { ICompanyStatus } from 'domain/company';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): ICompanyStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativos':
      return 'ativo';
    case 'Inativos':
      return 'inativo';
  }
};
