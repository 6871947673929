import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IScheduleImportModalDomain } from '../../domains/schedule-import-modal/schedule-import-modal.domain';
import { IScheduleImportModalResponse } from './send-selected-file.types';
import { convertScheduleImportModalResponseToDomain } from './mappers/convert-schedule-import modal-response-to-domain.mapper';

export const sendSelectedFileService = async (file: FileList): Promise<Array<IScheduleImportModalDomain>> => {
  const formData = new FormData();

  formData.append('file', file[0]);

  const { data } = await API_TRAVELS_SVC.post<IScheduleImportModalResponse>(
    'v1/route-planning/files/validate',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        file: file[0].name,
      },
    },
  );

  return data.rowsValidations.map(convertScheduleImportModalResponseToDomain);
};
