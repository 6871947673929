import { useCallback, useContext } from 'react';

import { driverListService } from '../../services/driver-list/driver-list.service';
import { DriversContext } from '../../contexts/table-states/drivers.context';

import { Hook } from './use-driver-list.types';

const useDriverList: Hook = () => {
  const { setDriversRequest, setDriversSuccess, setDriversError } = useContext(DriversContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setDriversRequest();

      try {
        const response = await driverListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setDriversSuccess(response);
      } catch (error) {
        setDriversError('Ocorreu um erro ao buscar os motoristas, tente novamente!');
      }
    },
    [setDriversError, setDriversRequest, setDriversSuccess],
  );
};

export { useDriverList };
