import styled from 'styled-components';

export const WrapperSectionStyled = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
`;

export const WrapperEmptySection = styled.div`
  display: flex;
  align-items: center;
  fontsize: 12px;
  margin-top: 20px;
  font-weight: 600;
  color: #b0b0b0;
`;
