import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { ISavedFilters } from './convert-saved-filters.types';
import dayjs from 'dayjs';

export const convertSavedFilters = (filters: Array<ISavedFilters>): Array<IScheduleListParams> => {
  return filters.map((filter) => {
    const {
      levyInitialDateStart,
      levyInitialDateEnd,
      deliveryInitialStart,
      deliveryInitialEnd,
      statusCode,
      ...values
    } = filter;

    const levyInitialDate =
      levyInitialDateStart && levyInitialDateStart !== ''
        ? dayjs(levyInitialDateStart, 'MM-DD-YYYY').format('DD/MM/YYYY')
        : null;
    const levyEndDate =
      levyInitialDateEnd && levyInitialDateEnd !== ''
        ? dayjs(levyInitialDateEnd, 'MM-DD-YYYY').format('DD/MM/YYYY')
        : null;
    const deliveryInitialDate =
      deliveryInitialStart && deliveryInitialStart !== ''
        ? dayjs(deliveryInitialStart, 'MM-DD-YYYY').format('DD/MM/YYYY')
        : null;
    const deliveryEndDate =
      deliveryInitialEnd && deliveryInitialEnd !== ''
        ? dayjs(deliveryInitialEnd, 'MM-DD-YYYY').format('DD/MM/YYYY')
        : null;

    return {
      ...values,
      levyInitialDate: levyInitialDate ?? filter.levyInitialDate,
      levyEndDate: levyEndDate ?? filter.levyEndDate,
      deliveryInitialDate: deliveryInitialDate ?? filter.deliveryInitialDate,
      deliveryEndDate: deliveryEndDate ?? filter.deliveryEndDate,
      statusCode: statusCode ?? filter.status,
      limitRegistry: 50,
    };
  });
};
