import styled, { css } from 'styled-components';

export const ModalTitle = styled.p`
  font-size: 0.85rem;
  font-weight: 600;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface ISectionWrapperProps {
  padding?: string;
}
export const SectionWrapper = styled.div<ISectionWrapperProps>`
  ${({ theme, padding }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    padding: ${padding ?? '15px 0 5px 0'};
  `}
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const WrapperButtonSubmit = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
`;

export const LineForm = styled.hr`
  border-top: 1px solid #d9d9d9;
  margin: 2rem 0rem;
`;

export const ContainerIcon = styled.div`
  margin-top: 30px;
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoSectionFeeLogshare = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const TermsText = styled.p`
  ${({ theme }) => css`
    display: inline;
    flex-direction: column;
    align-items: center;
    font-size: ${theme.fontV2.sizes.text5};
    padding-left: 10px;
    text-transform: uppercase;
  `}
`;

export const Div = styled.div``;
