import React, { useCallback, useContext, useEffect } from 'react';
import { FormikProps, withFormik } from 'formik';

import {
  EmailWrapperStyled,
  FormStyled,
  LineSectionStyled,
  NameWrapperStyled,
  SectionStyled,
  TradeNameWrapperStyled,
  UploadImageStyled,
  WrapperSectionCompanyDataStyled,
} from './company-register-form.styled';

import { UserContext } from 'state/user-context';
import { shippingCompanySearchService } from 'services/shipping-company/search';
import {
  optionsClosingCycle,
  optionsDeadlines,
  optionsPaymentMethod,
  optionsReceiptMethod,
} from 'pages/company/company-update/components/company-update-form/form-values/form-values';
import { uf } from 'domain/states';
import { ICompany, ICompanyContact, ICompanyPlan, ICompanyStatus } from 'domain/company';
import { IAddress } from 'domain/address';
import { FooterPage } from 'components-v2/common/footer-page';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import InputUploadImage from 'components/input-upload-image/input-upload-image';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import AsyncSelectField from 'components/async-select/async-select';

type FormRegisterCompanyProps = {
  initialValues: ICompany;
  optionsPlan: Array<{ label: string; value: ICompanyPlan }>;
  optionsStatus: Array<{ label: string; value: ICompanyStatus }>;
  optionsRiskManager: Array<{ label: string; value: string }>;
  validationSchema: object;
  handleGoBack: () => void;
  handleRegister: (values: ICompany) => void;
  onBlurCep: (cep: string) => Promise<IAddress>;
};

const FormRegisterCompanyView: React.FC<FormRegisterCompanyProps> = ({
  initialValues,
  optionsPlan,
  optionsStatus,
  optionsRiskManager,
  validationSchema,
  handleGoBack,
  handleRegister,
  onBlurCep,
}) => {
  const { loading, isAdmin } = useContext(UserContext);

  const InnerForm = (props: FormikProps<ICompany>) => {
    const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = props;

    const onBlurCEPField = async (cep: string) => {
      const address = await onBlurCep(cep);
      setFieldValue('cep', address.cep || values.cep);
      setFieldValue('city', address.city);
      setFieldValue('complement', address.complement);
      setFieldValue('uf', address.uf);
      setFieldValue('neighborhood', address.neighborhood);
      setFieldValue('numberHouse', address.numberHouse);
      setFieldValue('street', address.street);
    };

    useEffect(() => {
      if (values.plan === 'shipping-company') {
        setFieldValue('type', 'Transportadora');
        setFieldValue('gris', '0.20');
        setFieldValue('advalorem', '0.10');
      } else {
        setFieldValue('type', 'Embarcador');
      }
    }, [values.plan, setFieldValue]);

    const handleSelectedImage = useCallback(
      (file: File) => {
        if (file) {
          setFieldValue('fileKey', URL.createObjectURL(file));
          setFieldValue('file', file);
        }
      },
      [setFieldValue],
    );

    return (
      <FormStyled>
        <SectionStyled name="Informações do Empresa">
          <WrapperSectionCompanyDataStyled>
            <NameWrapperStyled>
              <InputField
                label="Razão Social"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
                errorMessage={String(errors.name)}
              />
            </NameWrapperStyled>

            <TradeNameWrapperStyled>
              <InputField
                label="Nome Fantasia *"
                id="tradeName"
                name="tradeName"
                type="text"
                onChange={handleChange}
                value={values.tradeName}
                hasError={!!errors.tradeName && !!touched.tradeName}
                errorMessage={String(errors.tradeName)}
              />
            </TradeNameWrapperStyled>

            <InputField
              label="Login"
              id="login"
              name="login"
              type="text"
              onChange={handleChange}
              value={values.login}
              hasError={!!errors.login && !!touched.login}
              errorMessage={String(errors.login)}
            />

            <EmailWrapperStyled>
              <InputField
                label="E-mail"
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                value={values.email}
                hasError={!!errors.email && !!touched.email}
                errorMessage={String(errors.email)}
                upperCase={false}
              />
            </EmailWrapperStyled>

            <InputField
              label="Tipo"
              id="type"
              name="type"
              type="text"
              onChange={handleChange}
              value={values.type}
              hasError={!!errors.ie && !!touched.ie}
              errorMessage={String(errors.ie)}
              disabled
            />

            <Select
              label="Planos"
              id="plan"
              name="plan"
              value={values.plan}
              setFieldValue={setFieldValue}
              hasError={!!errors.plan && !!touched.plan}
              errorMessage={String(errors.plan)}
              options={optionsPlan}
            />

            <InputFieldCnpj
              label="CNPJ"
              id="cnpj"
              name="cnpj"
              type="text"
              onChange={handleChange}
              value={values.cnpj}
              hasError={!!errors.cnpj && !!touched.cnpj}
              errorMessage={String(errors.cnpj)}
            />

            <Select
              label="Possui DDR"
              id="hasDdr"
              name="hasDdr"
              value={values.hasDdr}
              setFieldValue={setFieldValue}
              hasError={!!errors.hasDdr && !!touched.hasDdr}
              errorMessage={String(errors.hasDdr)}
              options={[
                {
                  label: 'SIM',
                  value: true,
                },
                {
                  label: 'NÃO',
                  value: false,
                },
              ]}
              disabled={!isAdmin}
            />

            <Select
              label="Gerenciadora de Risco"
              id="riskManager"
              name="riskManager"
              value={values.riskManager}
              setFieldValue={setFieldValue}
              hasError={!!errors.riskManager && !!touched.riskManager}
              errorMessage={String(errors.riskManager)}
              options={optionsRiskManager}
              disabled={!isAdmin}
            />

            <Select
              label="Status"
              id="status"
              name="status"
              value={values.status}
              setFieldValue={setFieldValue}
              hasError={!!errors.status && !!touched.status}
              errorMessage={String(errors.status)}
              options={optionsStatus}
            />

            <UploadImageStyled>
              <InputUploadImage
                urlImage={values.fileKey}
                hasError={false}
                handleSelectedImage={(_, file) => handleSelectedImage(file)}
                profilePhoto
              />
            </UploadImageStyled>
          </WrapperSectionCompanyDataStyled>
        </SectionStyled>

        <SectionDivider label="Dados da Transportadora" />
        <SectionStyled name="Transportadora">
          <LineSectionStyled columns="1fr">
            <AsyncSelectField
              placeholder=""
              label="Transportadora"
              isClearable={true}
              name="shippingCompanyId"
              id="shippingCompanyId"
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (e) => {
                setFieldValue('shippingCompanyId', e && e.value);
                const updatedContacts = [...initialValues.contacts];
                const lastIndex = updatedContacts.length - 1;
                updatedContacts[lastIndex] = {
                  ...updatedContacts[lastIndex],
                  name: e?.email?.name || '',
                  email: e?.email?.email || '',
                  telephone: e?.email?.telephone || '',
                  type: 'comercial',
                };

                setFieldValue('contacts', updatedContacts);
              }}
              apiCallFunction={shippingCompanySearchService}
              loadOptions={shippingCompanySearchService}
              disabled={values.plan !== 'shipping-company'}
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Dados Financeiros" />
        <SectionStyled name="Financeiro">
          <LineSectionStyled columns={`${values.plan !== 'shipping-company' ? 'repeat(4, 2fr)' : 'repeat(6, 1fr)'}`}>
            <InputCurrency
              label="Fee Logshare (Com CTe) *"
              id="feeLogshare"
              name="feeLogshare"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={1}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={1}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.feeLogshare}
              disabled={!isAdmin}
              hasError={!!errors.feeLogshare && !!touched.feeLogshare}
              errorMessage={String(errors.feeLogshare)}
            />

            <InputCurrency
              label="Fee Logshare (Sem CTe)"
              id="feeLogshareWithoutCTe"
              name="feeLogshareWithoutCTe"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={2}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.feeLogshareWithoutCTe}
              disabled={!isAdmin}
            />

            <InputCurrency
              label="Gris *"
              id="gris"
              name="gris"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={2}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.gris}
              hasError={!!errors.gris && !!touched.gris}
              errorMessage={String(errors.gris)}
              disabled={!isAdmin}
            />
            <InputCurrency
              label="AdValorem *"
              id="advalorem"
              name="advalorem"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={2}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.advalorem}
              hasError={!!errors.advalorem && !!touched.advalorem}
              errorMessage={String(errors.advalorem)}
              disabled={!isAdmin}
            />
            {values.plan !== 'shipping-company' && (
              <>
                <Select
                  label="Prazo de Pagamento *"
                  id="paymentTerm"
                  name="paymentTerm"
                  value={values.paymentTerm}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.paymentTerm && !!touched.paymentTerm}
                  errorMessage={String(errors.paymentTerm)}
                  options={optionsDeadlines}
                />
                <Select
                  label="Modalidade de Pagamento *"
                  id="modalityPayment"
                  name="modalityPayment"
                  value={values.modalityPayment}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.modalityPayment && !!touched.modalityPayment}
                  errorMessage={String(errors.modalityPayment)}
                  options={optionsPaymentMethod}
                />
              </>
            )}

            <Select
              label="Prazo de Recebimento *"
              id="receiptDeadline"
              name="receiptDeadline"
              value={values.receiptDeadline}
              setFieldValue={setFieldValue}
              hasError={!!errors.receiptDeadline && !!touched.receiptDeadline}
              errorMessage={String(errors.receiptDeadline)}
              options={optionsDeadlines}
            />
            <Select
              label="Modalidade de Recebimento *"
              id="modalityDeadline"
              name="modalityDeadline"
              value={values.modalityDeadline}
              setFieldValue={setFieldValue}
              hasError={!!errors.modalityDeadline && !!touched.modalityDeadline}
              errorMessage={String(errors.modalityDeadline)}
              options={optionsReceiptMethod}
            />
            <Select
              label="Ciclo de Fechamento *"
              id="closingCycle"
              name="closingCycle"
              value={values.closingCycle}
              setFieldValue={setFieldValue}
              hasError={!!errors.closingCycle && !!touched.closingCycle}
              errorMessage={String(errors.closingCycle)}
              options={optionsClosingCycle}
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Endereço de Cobrança" />
        <SectionStyled name="Cobrança">
          <LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
            <InputFieldCep
              label="CEP *"
              id="cep"
              name="cep"
              type="text"
              onChange={handleChange}
              onBlur={(e) => onBlurCEPField(e.target.value)}
              value={values.cep}
              hasError={!!errors.cep && !!touched.cep}
              errorMessage={String(errors.cep)}
            />

            <InputField
              label="Bairro *"
              id="neighborhood"
              name="neighborhood"
              type="text"
              onChange={handleChange}
              value={values.neighborhood}
              hasError={!!errors.neighborhood && !!touched.neighborhood}
              errorMessage={String(errors.neighborhood)}
            />

            <InputField
              label="Cidade *"
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={values.city}
              hasError={!!errors.city && !!touched.city}
              errorMessage={String(errors.city)}
            />

            <Select
              label="UF *"
              id="uf"
              name="uf"
              value={values.uf}
              setFieldValue={setFieldValue}
              hasError={!!errors.uf && !!touched.uf}
              errorMessage={String(errors.uf)}
              options={uf}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1.5fr 0.4fr 1.1fr">
            <InputField
              label="Endereço *"
              id="street"
              name="street"
              type="text"
              onChange={handleChange}
              value={values.street}
              hasError={!!errors.street && !!touched.street}
              errorMessage={String(errors.street)}
            />

            <InputField
              label="Nº *"
              id="numberHouse"
              name="numberHouse"
              type="text"
              onChange={handleChange}
              value={values.numberHouse}
              hasError={!!errors.numberHouse && !!touched.numberHouse}
              errorMessage={String(errors.numberHouse)}
            />

            <InputField
              label="Complemento"
              id="complement"
              name="complement"
              type="text"
              onChange={handleChange}
              value={values.complement}
              hasError={!!errors.complement && !!touched.complement}
              errorMessage={String(errors.complement)}
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Dados para Contato" />
        <SectionStyled name="Contatos">
          {values.contacts.map((contacts: ICompanyContact, index: number) => (
            <LineSectionStyled key={contacts.id} columns="1fr 1fr 3fr">
              <InputField
                label={`Nome (Contato ${index + 1})`}
                id={`contacts[${index}].name`}
                name={`contacts[${index}].name`}
                type="text"
                onChange={handleChange}
                value={contacts.name}
              />

              <InputFieldPhone
                label="Telefone"
                id={`contacts[${index}].telephone`}
                name={`contacts[${index}].telephone`}
                type="text"
                onChange={handleChange}
                value={contacts.telephone}
              />

              <InputField
                label="E-mail"
                id={`contacts[${index}].email`}
                name={`contacts[${index}].email`}
                type="text"
                onChange={handleChange}
                value={contacts.email}
              />
            </LineSectionStyled>
          ))}
        </SectionStyled>

        <SectionDivider label="Regulador Tarifário" />
        <SectionStyled name="Financeiro">
          <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr">
            <InputField
              label="Dom"
              id="tariff.dom"
              name="tariff.dom"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.dom}
            />

            <InputField
              label="Seg"
              id="tariff.seg"
              name="tariff.seg"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.seg}
            />

            <InputField
              label="Ter"
              id="tariff.ter"
              name="tariff.ter"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.ter}
            />

            <InputField
              label="Qua"
              id="tariff.qua"
              name="tariff.qua"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.qua}
            />

            <InputField
              label="Qui"
              id="tariff.qui"
              name="tariff.qui"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.qui}
            />

            <InputField
              label="Sex"
              id="tariff.sex"
              name="tariff.sex"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.sex}
            />

            <InputField
              label="Sab"
              id="tariff.sab"
              name="tariff.sab"
              type={'number'}
              onChange={handleChange}
              value={values.tariff.sab}
            />
          </LineSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={loading} onPress={handleSubmit} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const FormRegisterCompanyView = withFormik<ICompany, ICompany>({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
  })(InnerForm);

  return <FormRegisterCompanyView {...initialValues} />;
};

export default FormRegisterCompanyView;
