import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const LineSectionModalStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
  `}
`;

export const HeaderTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin: 25px 0 -10px 0;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.black};
  `}
`;

export const ContainerVehicleListStyled = styled.div`
  ${({ theme }) => css`
    padding: 20px;
    height: auto;
    border: 1px solid ${theme.colors.forBorder};
    border-radius: 7px;
    margin: 20px 0 20px 0;
  `}
`;

export const ContainerTariffAndInvoicingStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const ContainerTariffStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
  max-width: 48%;
`;

export const ContainerInvoicingStyled = styled.div`
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: column;
`;

export const ContainerDestinyStyled = styled.div`
  margin-bottom: 20px;
`;

export const OptionStyled = styled.option``;

export const ContainerSensorStyled = styled.div`
  margin-top: 10px;
`;

export const ContainerOthersStyled = styled.div`
  margin-top: -11px;
`;

export const TraceVerticalStyled = styled.div`
  width: 3px;
  height: 13px;
  background-color: #12264e;
`;
