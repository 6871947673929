import React, { FC, useMemo } from 'react';
import { SaleOfferTableTabs } from '../sale-offer-table-tabs/sale-offer-table-tabs.component';
import * as S from './sale-offer-page-body-view.styled';
import { SaleOfferModalMatch } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-modal-match/sale-offer-modal-match.component';
import { SaleOfferHeader } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-header/sale-offer-header.component';
import { Formik } from 'formik';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { saleOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-list-filters-initial-values.constant';

interface ISaleOfferPageBodyViewProps {
  isOpenModal: boolean;
  onFilterSubmit: (values: ISaleOfferListFilterDomain) => void;
}

export const SaleOfferPageBodyView: FC<ISaleOfferPageBodyViewProps> = ({ isOpenModal, onFilterSubmit }) => {
  const renderModalMatch = useMemo(() => {
    if (isOpenModal) return <SaleOfferModalMatch />;
  }, [isOpenModal]);

  return (
    <Formik initialValues={saleOfferListFilterInitialValues} onSubmit={onFilterSubmit}>
      <S.Wrapper>
        <SaleOfferHeader />
        <SaleOfferTableTabs />
        {renderModalMatch}
      </S.Wrapper>
    </Formik>
  );
};
