import React from 'react';

import ModalConfirmationView from './modal-upload-view';

type ModalConfirmationProps = {
  title: string;
  description: string;
  isLoading?: boolean;
  handleCancel: () => void;
  handleSuccess: (formData: FormData) => void;
  handleDownload?: () => void;
};
const ModalUpload: React.FC<ModalConfirmationProps> = ({
  title,
  description,
  isLoading,
  handleCancel,
  handleSuccess,
  handleDownload,
}) => {
  return (
    <ModalConfirmationView
      title={title}
      description={description}
      isLoading={isLoading}
      handleCancel={handleCancel}
      handleSuccess={handleSuccess}
      handleDownload={handleDownload}
    />
  );
};

export default ModalUpload;
