import { SelectItem } from '../entities/select-items.entity';

import API from 'utils/API/API';
import { convertLocalityEnityToSelectItem } from 'pages/schedule/mappers/convert-locality-entity-to-select-item/convert-locality-entity-to-select-item.mapper';

export const localitiesFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`localities-filter?search=${searchValue}`);

  return data.map((locality: { name: string; id: number }) => convertLocalityEnityToSelectItem(locality));
};
