import styled, { css } from 'styled-components';

import theme from 'styles/theme';

type ColorKeys = keyof typeof theme.colorsV2;

type SectionProps = {
  size?: number;
  weight?: boolean;
  padding?: string;
  date?: boolean;
  uppercase?: boolean;
  color?: ColorKeys;
  isActivated?: boolean;
  margin?: boolean;
  hasVehicle?: boolean;
};

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #05050581;
  z-index: 99999;
  color: #000;
`;

export const Container = styled.div`
  display: grid;
  height: 80%;
  width: 80%;
  grid-template-columns: 1fr 3.5fr;
  font-size: 12px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: 20px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FullMapWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: none;
`;

export const Text = styled.div<SectionProps>`
  ${({ theme, size = 13, weight, padding, uppercase, color = 'black' }) => css`
    font-size: ${size}px;
    font-weight: ${weight ? theme.font.semiBold : ''};
    padding: ${padding ? padding : 0};
    text-transform: ${uppercase ? 'uppercase' : ''};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colorsV2[color]};
  `}
`;

export const Description = styled.div<SectionProps>`
  ${({ hasVehicle }) => css`
    height: 90%;
    display: grid;
    grid-template-rows: ${hasVehicle ? '5fr 6fr 1fr' : '7fr 1fr'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const DescriptionContent = styled.div<SectionProps>`
  ${({ margin }) => css`
    margin-top: ${margin ? '50px' : 0};
    display: grid;
    grid-template-columns: 1fr 10fr;
  `}
`;

export const ConectorContent = styled.div`
  margin-top: 1px;
`;

export const TruncateText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OriginDetails = styled.div`
  padding-bottom: 30px;
`;

export const LineDescriptionSection = styled.div`
  display: grid;
  gap: 5px;
`;

export const SubtitleContent = styled.div`
  justify-items: start;
  align-items: start;
`;

export const LineSubtitleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 3fr;
  padding-bottom: 10px;
`;

export const ContainerCloseModal = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  cursor: pointer;
  background-color: white;
  box-shadow: 2px 1px 3px 0px rgba(155, 155, 155, 1);
  position: absolute;
  top: 0;
  right: 0;
`;

export const StepIcon = styled.div<SectionProps>`
  ${({ date, theme }) => css`
    background-color: ${date ? theme.colors.blue : '#d3d3d3'};
    z-index: 1;
    color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  `}
`;

export const Connector = styled.div<SectionProps>`
  ${({ date, theme }) => css`
    height: 98px;
    border: 0;
    width: 3px;
    margin-left: 5px;
    background-color: ${date ? theme.colors.blue : '#d3d3d3'};
  `}
`;

export const Tracing = styled.div<SectionProps>`
  ${({ theme, color = 'blue' }) => css`
    width: 55%;
    border: 0;
    height: 4px;
    background-color: ${theme.colorsV2[color]};
    border-radius: 10px;
    margin-top: 6px;
  `}
`;

export const DestinyDetails = styled.div``;
export const StrongText = styled.strong``;
