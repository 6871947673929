import React, { useCallback, useState } from 'react';
import { FormikProps, FormikValues, withFormik } from 'formik';

import { FormStyled, LineSectionStyled, SectionStyled } from './user-update-form.styled';
import UserPermissions from './components/user-permissions/user-permissions';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import Theme from 'styles/theme';
import { useForgotPassword } from 'pages/reset-login/hooks/forgot-password/forgot-password.hook';
import { ICreatedUser } from 'domain/user';
import { FooterPage } from 'components-v2/common/footer-page';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import TabsPermissions from 'components/tabs-permissions/tabs-permissions';
import SectionDivider from 'components/section-divider/section-divider';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';
import { yesNoOptions } from 'domain/global-inputs';
import Select from 'components/select/select';

type UserRegisterFormProps = {
  initialValues: ICreatedUser;
  login: string | undefined;
  validationSchema: object;
  isLoading: boolean;
  handleGoback: () => void;
  handleRegister: (values: ICreatedUser) => void;
};
const UserRegisterFormView: React.FC<UserRegisterFormProps> = ({
  initialValues,
  validationSchema,
  isLoading,
  handleGoback,
  handleRegister,
}) => {
  const InnerForm = (props: FormikProps<ICreatedUser>) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = props;

    const forgotPassword = useForgotPassword();

    const handleResetPassword = useCallback(async () => {
      forgotPassword(values.login, values.email)
        .then(() => {
          showMessageFormatted({
            message: 'Uma nova senha foi enviada para o seu e-mail.',
            type: 'success',
          });
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        );
    }, [forgotPassword, values.email, values.login]);

    return (
      <FormStyled autoComplete="off">
        <SectionStyled name="Informações do Usuário">
          <LineSectionStyled columns="1fr 1fr 0.71fr 0.25fr">
            <InputField
              label="Nome *"
              id="name"
              name="name"
              type="text"
              onChange={handleChange}
              value={values.name}
              hasError={!!errors.name && !!touched.name}
              errorMessage={String(errors.name)}
              disabled={values.name === 'MASTER'}
            />

            <InputField
              label="E-mail *"
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              hasError={!!errors.email && !!touched.email}
              errorMessage={String(errors.email)}
              disabled={values.name === 'MASTER'}
              autoComplete="off"
              upperCase={false}
            />

            <InputFieldPhone
              label="Telefone *"
              id="telephone"
              name="telephone"
              type="text"
              onChange={handleChange}
              value={values.telephone}
              hasError={!!errors.telephone && !!touched.telephone}
              errorMessage={String(errors.telephone)}
            />

            <Select
              label="Master *"
              id="isMaster"
              name="isMaster"
              value={values.isMaster}
              setFieldValue={setFieldValue}
              options={yesNoOptions}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 1fr 1fr">
            <InputField
              label="Login *"
              id="login"
              name="login"
              type="text"
              upperCase={false}
              onChange={handleChange}
              value={values.login.toUpperCase()}
              hasError={!!errors.login && !!touched.login}
              errorMessage={String(errors.login)}
              disabled
            />

            <InputField
              label="Senha *"
              id="password"
              name="password"
              type="password"
              upperCase={false}
              onChange={handleChange}
              value={values.password}
              passwordVisible={showPassword}
              changePasswordVisible={() => setShowPassword(!showPassword)}
              reactNode={<Theme.icons.eyePasswordIcon />}
              reactNodePosition="right"
              disabled={values.name === 'MASTER'}
              autoComplete="new-password"
            />

            <InputField
              label="Confirmação de Senha *"
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              upperCase={false}
              onChange={handleChange}
              value={values.passwordConfirmation}
              passwordVisible={showConfirmPassword}
              changePasswordVisible={() => setShowConfirmPassword(!showConfirmPassword)}
              reactNode={<Theme.icons.eyePasswordIcon />}
              reactNodePosition="right"
              disabled={values.name === 'MASTER'}
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Permissões" />
        <SectionStyled name="permissions">
          <TabsPermissions
            tabsContent={[
              {
                name: 'Cadastro',
                content: renderPermission('register', values, setFieldValue),
              },
              {
                name: 'Oportunidades',
                content: renderPermission('opportunities', values, setFieldValue),
              },

              {
                name: 'Programação',
                content: renderPermission('schedules', values, setFieldValue),
              },

              {
                name: 'Execução',
                content: renderPermission('execution', values, setFieldValue),
              },

              {
                name: 'Tracking',
                content: renderPermission('tracking', values, setFieldValue),
              },

              {
                name: 'Financeiro',
                content: renderPermission('financial', values, setFieldValue),
              },
              {
                name: 'Configurações',
                content: renderPermission('settings', values, setFieldValue),
              },
            ]}
          />
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="blue" isLoading={isLoading} onPress={handleSubmit} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoback} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <div style={{ display: 'flex', gap: 20 }}>
              <ToggleCheckboxView
                id="status"
                name="status"
                label="Ativo"
                checked={values.status === 'ativo'}
                onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
              />
              <FooterPage.Button label="Formatar Senha" color="pink" onPress={handleResetPassword} />
            </div>
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };
  const renderPermission = useCallback(
    (tabName: string, values: FormikValues, setFieldValue: (name: string, value: boolean) => void) => {
      return (
        <UserPermissions
          subPermissions={values[tabName]}
          handleChecked={(e) => {
            values[tabName].map((item: { label: string }, idx: any) => {
              if (item.label === e.currentTarget.name) {
                setFieldValue(`${tabName}[${idx}].value`, e.currentTarget.checked);
              }
            });
          }}
        />
      );
    },
    [],
  );

  const FormUpdateUser = withFormik<ICreatedUser, ICreatedUser>({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
  })(InnerForm);
  return <FormUpdateUser {...initialValues} />;
};

export default UserRegisterFormView;
