export enum FreightChatActionType {
  FREIGHT_CHAT_REQUEST = 'FREIGHT_CHAT_REQUEST',
  FREIGHT_CHAT_SUCCESS = 'FREIGHT_CHAT_SUCCESS',
  FREIGHT_CHAT_ERROR = 'FREIGHT_CHAT_ERROR',
  FREIGHT_CHAT_RESET = 'FREIGHT_CHAT_RESET',
  FREIGHT_CHAT_OPEN = 'FREIGHT_CHAT_OPEN',
  FREIGHT_CHAT_CLOSE = 'FREIGHT_CHAT_CLOSE',
}

export enum IFreightActionType {
  FREIGHT_RESET = 'FREIGHT_RESET',
}
