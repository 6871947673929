import { OperatedMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesContext } from '../../types/matches.types';
import { IOperatedMatchesKanbanListActions } from '../../types/operated-matches-kanban.types';

export const operatedMatchesKanbanListReducer = (
  state: IMatchesContext,
  action: IOperatedMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_REQUEST:
      return {
        ...state,
        operatedMatchesKanbanList: {
          ...state.operatedMatchesKanbanList,
          statusRequest: 'PROCESSING',
          operatedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_SUCCESS:
      return {
        ...state,
        operatedMatchesKanbanList: {
          ...state.operatedMatchesKanbanList,
          statusRequest: 'SUCCESS',
          operatedKanbanList: action.payload,
        },
      };
    case OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_ERROR:
      return {
        ...state,
        operatedMatchesKanbanList: {
          ...state.operatedMatchesKanbanList,
          statusRequest: 'ERROR',
          operatedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case OperatedMatchesKanbanListActionType.OPERATED_MATCHES_KANBAN_RESET:
      return {
        ...state,
        operatedMatchesKanbanList: {
          ...state.operatedMatchesKanbanList,
          statusRequest: 'INITIAL',
          operatedKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    default:
      return state;
  }
};
