import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FreightUpdateView from './freight-update-view.component';
import { IAllocationInfo } from 'pages/freight/freight-update/freight-update-body/freight-update.types';
import { ConversationBuilder, Session } from 'talkjs/types/talk.types';
import { FreightContext } from 'pages/freight/contexts/freight/freight.context';
import { useUnreads } from '@talkjs/react';

const FreightUpdate: React.FC = () => {
  const { freightChat } = useContext(FreightContext);
  const [showUnreadChat, setShowUnreadChat] = useState<boolean>(false);
  const [accessRestricted, setAccessRestricted] = useState<boolean>(false);
  const [allocationInfo, setAllocationInfo] = useState<IAllocationInfo>({
    allocationID: 0,
    statusCode: undefined,
  });
  const unreadsChat = useUnreads();
  const handleGetAllocation = (allocationID: number, statusCode?: string) => {
    setAllocationInfo({ allocationID, statusCode });
  };

  const handleAccessRestricted = (accessRestricted: boolean) => {
    setAccessRestricted(accessRestricted);
  };

  const getId = useMemo((): string => {
    if (allocationInfo.allocationID) {
      return allocationInfo.allocationID.toString();
    }

    return '';
  }, [allocationInfo.allocationID]);

  const syncConversation = useCallback(
    (session: Session): ConversationBuilder => {
      const conversation = session.getOrCreateConversation(getId);
      conversation.setParticipant(session.me);

      return conversation;
    },
    [getId],
  );

  const getSyncConversation = useMemo(() => {
    if (allocationInfo.allocationID && allocationInfo.allocationID !== 0) {
      return syncConversation;
    }
  }, [allocationInfo.allocationID, syncConversation]);

  const hasDisabledChat = useMemo(() => freightChat.statusRequest === 'ERROR', [freightChat.statusRequest]);

  const getUnreadChat = useMemo(
    () => unreadsChat?.find((unreadChat) => unreadChat.conversation.id === getId),
    [getId, unreadsChat],
  );

  useEffect(() => {
    if (getUnreadChat && getUnreadChat.unreadMessageCount && !showUnreadChat) {
      freightChat.chatActions.setChatOpen();
      setShowUnreadChat(true);
    }
  }, [freightChat.chatActions, getUnreadChat, showUnreadChat]);

  return (
    <FreightUpdateView
      accessRestricted={accessRestricted}
      allocationInfo={allocationInfo}
      handleGetAllocation={handleGetAllocation}
      handleAccessRestricted={handleAccessRestricted}
      syncConversation={getSyncConversation}
      handleOpenChat={freightChat.chatActions.setChatOpen}
      handleCloseChat={freightChat.chatActions.setChatClose}
      isShowChat={freightChat.chatActions.isOpen}
      hasDisabledChat={hasDisabledChat}
      unreadsChat={getUnreadChat}
    />
  );
};

export default FreightUpdate;
