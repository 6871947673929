import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { SearchView } from './search-view.component';

interface ISearchProps {
  placeholder: string;
  filters?: Array<string | number>;
  disabledExportButton?: boolean;
  disabledImportButton?: boolean;
  appliedFilters?: number;
  changeValue: (value: string) => void;
  handleClearFilters?: (index: number) => void;
  handleFilterButton?: () => void;
  handleCustomFilterButton?: () => void;
  handleExportButton?: () => void;
  handleImportButton?: () => void;
  disabledInputSearch?: boolean;
}

let timer: NodeJS.Timeout | undefined;

export const Search: FC<ISearchProps> = ({
  placeholder,
  filters,
  disabledExportButton,
  disabledImportButton,
  appliedFilters = 0,
  changeValue,
  handleClearFilters,
  handleFilterButton,
  handleCustomFilterButton,
  handleExportButton,
  handleImportButton,
  disabledInputSearch,
}) => {
  const [value, setValue] = useState<string>('');

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        changeValue(e.target.value);
      }, 500);

      setValue(e.target.value);
    },
    [changeValue],
  );

  const onClearValue = useCallback(
    (index: number) => {
      if (handleClearFilters) {
        handleClearFilters(index);
        clearTimeout(timer);
        setValue('');
      }
    },
    [handleClearFilters],
  );

  const filterCount = useMemo((): string | null => {
    if (appliedFilters === 0) return null;

    if (appliedFilters >= 10) return '+9';

    return String(appliedFilters);
  }, [appliedFilters]);

  return (
    <SearchView
      value={value}
      placeholder={placeholder}
      filters={filters}
      disabledExportButton={disabledExportButton}
      disabledImportButton={disabledImportButton}
      filterCount={filterCount}
      onChangeValue={onChangeValue}
      onClearValue={onClearValue}
      handleFilterButton={handleFilterButton}
      handleCustomFilterButton={handleCustomFilterButton}
      handleExportButton={handleExportButton}
      handleImportButton={handleImportButton}
      disabledInputSearch={disabledInputSearch}
    />
  );
};
