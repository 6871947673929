import React from 'react';

import theme from 'styles/theme';

export const DedicatedFleet: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_205_5)">
        <path
          d="M22 44C9.8 44 0 34.2 0 22C0 9.8 9.8 0 22 0C34.2 0 44 9.8 44 22C44 34.2 34.2 44 22 44Z"
          fill={theme.colorsV2.aqua}
        />
        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fontSize="30" fill="white">
          D
        </text>
      </g>
      <defs>
        <clipPath id="clip0_205_5">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
