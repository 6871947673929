import React, { useContext } from 'react';
import { UserContext } from 'state/user-context';
import { PrivateAppRoute } from 'src-new/pages/app/routes/private-app/private-app.route';
import { PublicAppRoute } from 'src-new/pages/app/routes/public-app/public-app.route';

export const AppPage: React.FC = () => {
  const { user } = useContext(UserContext);

  return user ? <PrivateAppRoute /> : <PublicAppRoute />;
};
