import styled from 'styled-components';
import { Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled.form``;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>``;

export const WrapperSectionResetPasswordStyled = styled.div``;

export const ForgatButtonContainerStyled = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 15px;
  justify-items: center;

  button {
    width: 95%;
  }
`;
