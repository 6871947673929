import { diffDaysAndHoursDate } from 'utils-v2/diff-date';

interface PayloadDates {
  initialDate: string;
  finalDate: string;
}

interface CheckFreeTimeValue {
  expectedCollectionFinalDate: string;
  expectedDeliveryFinalDate: string;
  collectionDate: PayloadDates;
  deliveryDate: PayloadDates;
  dailyFine: number;
  hourlyFine: number;
}

export const checkFreeTimeValue = ({
  expectedCollectionFinalDate,
  expectedDeliveryFinalDate,
  collectionDate,
  deliveryDate,
  dailyFine,
  hourlyFine,
}: CheckFreeTimeValue) => {
  const collectTime = diffDaysAndHoursDate(
    expectedCollectionFinalDate,
    collectionDate.initialDate,
    collectionDate.finalDate,
  );

  const dischargeTime = diffDaysAndHoursDate(
    expectedDeliveryFinalDate,
    deliveryDate.initialDate,
    deliveryDate.finalDate,
  );
  
  const collect = dischargeCalculator(collectTime.days, collectTime.hours, collectTime.minutes, dailyFine, hourlyFine);
  const discharge = dischargeCalculator(
    dischargeTime.days,
    dischargeTime.hours,
    dischargeTime.minutes,
    dailyFine,
    hourlyFine,
  );

  return {
    details: {
      collect: {
        collectTime,
        payableDay: collect.payableDay,
        payableHours: collect.payableHours,
        payableMinute: collect.payableMinute,
        value: collect.valueTotal,
      },
      discharge: {
        dischargeTime,
        payableDay: discharge.payableDay,
        payableHours: discharge.payableHours,
        payableMinute: discharge.payableMinute,
        value: discharge.valueTotal,
      },
    },
    freeTimeTotal: collect.valueTotal + discharge.valueTotal,
  };
};

const dischargeCalculator = (days: number, hours: number, minutes: number, dailyFine: number, hourlyFine: number) => {
  const freeTime = 5;

  const day = days > 0 ? days : 0;
  const hour = hours < freeTime ? 0 : hours;
  const minute = hours < freeTime ? 0 : minutes;

  const payableDay = day * dailyFine;
  const payableHours = hour * hourlyFine;
  const payableMinute = Number(((hourlyFine / 60) * minute).toFixed(2));
  
  const valueTotal = payableDay + payableHours + payableMinute;

  return {
    payableDay,
    payableHours,
    payableMinute,
    valueTotal,
  };
};
