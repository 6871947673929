import { IListPage } from 'src-new/domains/list-page.domain';
import API from 'utils/API/API';
import { IScheduleDetailsAllocationVehicleDomain } from '../../domains/schedule-details-allocation-vehicle.domain';
import {
  IScheduleDetailsAllocationVehicleListProps,
  IScheduleDetailsAllocationVehicleResponse,
} from './get-vehicle-list.types';
import { converterScheduleDetailsAllocationVehicleResponseToDomain } from './mappers/converter-vehicle-response-to-domain/converter-vehicle-response-to-domain.mapper';

export const getVehicleListService = async (
  params: IScheduleDetailsAllocationVehicleListProps,
): Promise<IListPage<IScheduleDetailsAllocationVehicleDomain>> => {
  const { data } = await API.get<IListPage<IScheduleDetailsAllocationVehicleResponse>>('v2/vehicles', {
    params,
  });

  return {
    items: data.items.map(converterScheduleDetailsAllocationVehicleResponseToDomain),
    meta: data.meta,
  };
};
