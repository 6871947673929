import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { FavoriteMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesKanbanListDomain } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/domains/matches-kanban-list/matches-kanban-list.domain';
import { IFavoriteMatchesKanbanList } from '../../types/favorite-matches-kanban.types';
import { favoriteMatchesKanbanListReducer } from '../../reducers/favorite-matches-kanban-list/favorite-matches-kanban-list.reducer';

export const useFavoriteMatchesKanbanListContextValues = (): IFavoriteMatchesKanbanList => {
  const [state, dispatch] = useReducer(favoriteMatchesKanbanListReducer, matchesInitialState);

  const setFavoriteMatchesKanbanListRequest = useCallback(() => {
    dispatch({ type: FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_REQUEST, payload: undefined });
  }, []);

  const setFavoriteMatchesKanbanListSuccess = useCallback((kanban: IMatchesKanbanListDomain) => {
    dispatch({ type: FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_SUCCESS, payload: kanban });
  }, []);

  const setFavoriteMatchesKanbanListError = useCallback(() => {
    dispatch({ type: FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_ERROR, payload: undefined });
  }, []);

  const setFavoriteMatchesKanbanListReset = useCallback(() => {
    dispatch({ type: FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_RESET, payload: undefined });
  }, []);

  return {
    ...state.favoriteMatchesKanbanList,
    setFavoriteMatchesKanbanListRequest,
    setFavoriteMatchesKanbanListSuccess,
    setFavoriteMatchesKanbanListError,
    setFavoriteMatchesKanbanListReset,
  };
};
