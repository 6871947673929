import { FC, useCallback, useMemo, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';

import * as S from './styled';
import { ChecklistCheckbox } from './components/checkbox';

import { ChecklistAnswer } from 'domain-v2/checklist';
import { checklistStatusOptions } from 'domain/global-inputs';
import { ModalBark } from 'components-v2/layout/modal-bark';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';

interface ModalFormChecklistProps {
  isEdit: boolean;
}

export const ModalFormChecklist: FC<ModalFormChecklistProps> = ({ isEdit }) => {
  const { values, setFieldValue, handleChange, handleSubmit } = useFormikContext<ChecklistAnswer>();

  const [openModalConfirmation, setOpenModalConfirmation] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleValidation = useCallback(() => {
    const answersValidation = values.answers.some((answer) => answer.isRequired && answer.answer?.length === 0);

    if (answersValidation || !values.name || !values.status) {
      setHasError(true);
      return;
    }

    setOpenModalConfirmation(true);
  }, [values]);

  const renderChecklistValues = useMemo(() => {
    if (values.answers.every((question) => question.answerType === 'checkbox')) {
      return <ChecklistCheckbox hasError={hasError} isEdit={isEdit} />;
    }
  }, [values.answers, hasError, isEdit]);

  return (
    <>
      {openModalConfirmation && (
        <ModalBark title="Aviso" size="xsm" handleClose={() => setOpenModalConfirmation(false)}>
          <S.WrapperModal>
            <S.Info>Alterações salvas não poderão ser modificadas. Deseja prosseguir?</S.Info>
            <S.WrapperButton>
              <Button
                label="Cancelar"
                color="white"
                onPress={() => setOpenModalConfirmation(false)}
                size="md"
                variant="ghost"
              />
              <Button
                label="Confirmar"
                color="blue"
                onPress={() => {
                  setOpenModalConfirmation(false);
                  handleSubmit();
                }}
                size="md"
                variant="solid"
              />
            </S.WrapperButton>
          </S.WrapperModal>
        </ModalBark>
      )}
      <S.WrapperModalMatchForm>
        <S.WrapperForm>
          <S.SectionForm>
            <S.WrapperValues columns="2fr 1fr">
              <InputField
                id="name"
                name="name"
                label="Nome do Checklist *"
                type="text"
                value={values.name}
                onChange={handleChange}
                hasError={hasError && !values.name}
                errorMessage="Campo Obrigatório"
                disabled={isEdit}
              />
              <Select
                id="status"
                name="status"
                label="Status *"
                value={values.status}
                setFieldValue={setFieldValue}
                options={checklistStatusOptions}
                hasError={hasError && !values.status}
                errorMessage="Campo Obrigatório"
                disabled={isEdit}
              />
            </S.WrapperValues>
          </S.SectionForm>

          {renderChecklistValues}

          <S.SectionForm>
            <InputField
              id="comment"
              name="comment"
              label="Observações"
              type="text"
              value={values.comment}
              onChange={handleChange}
              disabled={isEdit}
            />
          </S.SectionForm>

          {!isEdit && (
            <S.WrapperButton>
              <Button label="Salvar" color="blue" onPress={handleValidation} size="md" variant="solid" />
            </S.WrapperButton>
          )}
        </S.WrapperForm>
      </S.WrapperModalMatchForm>
    </>
  );
};
