import React, { Fragment, ReactElement, useMemo } from 'react';
import { Button } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import TooltipComponent from 'components/Tooltip';
import { ListSubtitle } from 'src-new/components/application-page-header/components/list-subtitle/list-subtitle.component';
import {
  ContainerSearchStyled,
  NewRecorddButtonContainerStyled,
  NewRecordEmptyContainerStyled,
  SpinnerButton,
} from 'src-new/components/application-page-header/application-page-header-view.styled';
import { Search } from 'src-new/components/application-page-header/components/search/search.component';
import {
  ExportReportProps,
  FilterProps,
  IButtonProps,
  IInputSearchProps,
  UploadSpreadsheetProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { ModalUploadSpreadsheet } from 'src-new/components/application-page-header/components/modal-upload-spreadsheet/modal-upload-spreadsheet.component';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { FilterModal } from './components/filter-modal/filter-modal.component';
import { Title } from './components/title/title.component';

const dataTestId = 'location-list-page-body-view';

interface IRegistrationPageHeaderViewProps {
  breadcrumbList: Array<string>;
  title?: string;
  subtitle?: string;
  inputSearchProps?: IInputSearchProps;
  filterProps?: FilterProps;
  primaryButtonProps?: IButtonProps;
  secondaryButtonProps?: IButtonProps;
  tertiaryButtonProps?: IButtonProps;
  uploadModalProps?: UploadSpreadsheetProps;
  exportReportProps?: ExportReportProps;
  hasBackButton?: boolean;
}

export const ApplicationPageHeaderView: React.FC<IRegistrationPageHeaderViewProps> = ({
  breadcrumbList,
  title,
  subtitle,
  primaryButtonProps,
  uploadModalProps,
  inputSearchProps,
  exportReportProps,
  secondaryButtonProps,
  tertiaryButtonProps,
  filterProps,
  hasBackButton,
}): ReactElement => {
  const renderIconImport = useMemo(() => <CloudUploadIcon color={'action'} sx={{ width: 22 }} />, []);

  const renderIconExport = useMemo(() => <CloudDownloadIcon color={'action'} sx={{ width: 22 }} />, []);

  const renderGroupButton = useMemo(() => {
    return (
      <Fragment>
        {tertiaryButtonProps && tertiaryButtonProps.isVisible && (
          <NewRecorddButtonContainerStyled>
            <Button
              label={tertiaryButtonProps.label}
              size="lg"
              color="aqua"
              onPress={tertiaryButtonProps.handleActionButton}
              variant="solid"
              type="button"
              isLoading={tertiaryButtonProps.isLoading}
            />
          </NewRecorddButtonContainerStyled>
        )}
        {secondaryButtonProps && secondaryButtonProps.isVisible && (
          <NewRecorddButtonContainerStyled removeMargin={tertiaryButtonProps?.isVisible}>
            <Button
              label={secondaryButtonProps.label}
              size="lg"
              color="red"
              onPress={secondaryButtonProps.handleActionButton}
              variant="solid"
              type="button"
              isLoading={secondaryButtonProps.isLoading}
            />
          </NewRecorddButtonContainerStyled>
        )}
        {primaryButtonProps && primaryButtonProps.isVisible ? (
          <NewRecorddButtonContainerStyled removeMargin={secondaryButtonProps?.isVisible}>
            <Button
              label={primaryButtonProps.label}
              size="lg"
              color="blue"
              onPress={primaryButtonProps.handleActionButton}
              variant="solid"
              type="button"
              isLoading={primaryButtonProps.isLoading}
            />
          </NewRecorddButtonContainerStyled>
        ) : (
          <NewRecordEmptyContainerStyled />
        )}
      </Fragment>
    );
  }, [primaryButtonProps, secondaryButtonProps, tertiaryButtonProps]);

  const renderUploadModal = useMemo(() => {
    if (uploadModalProps && uploadModalProps.showModal) {
      return (
        <ModalUploadSpreadsheet
          title="Cadastro de Parceiros"
          description="Selecione o arquivo"
          handleCancel={uploadModalProps.uploadModalProps.handleCancel}
          handleSuccess={uploadModalProps.uploadModalProps.handleSuccess}
          handleDownload={uploadModalProps.uploadModalProps.handleDownload}
        />
      );
    }
  }, [uploadModalProps]);

  const renderImportButton = useMemo(() => {
    if (uploadModalProps) {
      return (
        <Fragment>
          <TooltipComponent
            icon={
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                onPress={uploadModalProps.handleOpenUploadXlsx}
                LeftIcon={renderIconImport}
              />
            }
            title={'Importar'}
            location={'bottom'}
          />
          {renderUploadModal}
        </Fragment>
      );
    }
  }, [renderIconImport, renderUploadModal, uploadModalProps]);

  const renderExportButton = useMemo(() => {
    if (exportReportProps) {
      const icon = exportReportProps.isLoading ? <SpinnerButton /> : renderIconExport;

      return (
        <Fragment>
          <TooltipComponent
            icon={
              <Button
                label=""
                color="white"
                size="x-sm"
                variant="ghost"
                onPress={exportReportProps.onHandleExport}
                LeftIcon={icon}
                disabled={exportReportProps.isDisabledButton}
              />
            }
            title={'Exportar'}
            location={'bottom'}
          />
          {renderUploadModal}
        </Fragment>
      );
    }
  }, [exportReportProps, renderIconExport, renderUploadModal]);

  const renderFilterButton = useMemo(() => {
    if (filterProps) {
      return (
        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={filterProps.handleOpenModal}
              LeftIcon={<FilterAltIcon sx={{ width: 22 }} />}
              disabled={filterProps.isDisabledButton}
            />
          }
          title={'Filtros'}
          location={'bottom'}
        />
      );
    }
  }, [filterProps]);

  const renderButtons = useMemo((): ReactElement => {
    return (
      <Fragment>
        {renderFilterButton}
        {renderExportButton}
        {renderImportButton}
        {renderGroupButton}
      </Fragment>
    );
  }, [renderExportButton, renderFilterButton, renderGroupButton, renderImportButton]);

  const renderFilterModal = useMemo((): ReactElement | undefined => {
    if (filterProps) {
      return (
        <FilterModal
          isOpen={filterProps.isVisibleModal}
          filterCount={filterProps.filterCount}
          onCloseButton={filterProps.onCloseButton}
          onClearButton={filterProps.onClearButton}
        >
          {filterProps.filterContent}
        </FilterModal>
      );
    }
  }, [filterProps]);

  const renderInputSearch = useMemo((): ReactElement | undefined => {
    if (inputSearchProps) {
      return (
        <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
          <Search
            placeholder={inputSearchProps.searchPlaceHolder}
            changeValue={inputSearchProps.handleSearchInput}
            disabledInputSearch={inputSearchProps.isDisabled}
          />
          {renderButtons}
        </ContainerSearchStyled>
      );
    }
  }, [inputSearchProps, renderButtons]);

  const renderListSubtitle = useMemo((): ReactElement | undefined => {
    if (subtitle) {
      return <ListSubtitle title={subtitle} hasTrace />;
    }
  }, [subtitle]);

  const renderTitle = useMemo((): ReactElement | undefined => {
    if (title) {
      return <Title title={title} />;
    }
  }, [title]);

  return (
    <Fragment>
      <HeaderPage breadcrumbList={breadcrumbList} hasBackButton={hasBackButton} />
      {renderTitle}
      {renderListSubtitle}
      {renderInputSearch}
      {renderFilterModal}
    </Fragment>
  );
};
