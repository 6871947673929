import { useNavigate } from 'react-router-dom';
import React, { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { alertsListService } from 'services/alerts';
import { UserContext } from 'state/user-context';
import { initialModalState, ModalState } from 'domain-v2/inputs/modals';
import { HeaderPageView, IModalProps } from './header-page-view.component';
import { capitalize } from 'src-new/utils/capitalize.util';
import { ChatContext } from 'src-new/contexts/chat/chat.context';

export type IHeaderPageProps = {
  dataTestId?: string;
  breadcrumbList?: Array<string>;
  renderCustomBackButton?: boolean;
  customButtonRender?: React.ReactNode;
  hasBackButton?: boolean;
  handleGoBackButton?: () => void;
};

export const HeaderPage: React.FC<IHeaderPageProps> = ({
  dataTestId,
  breadcrumbList,
  renderCustomBackButton,
  customButtonRender,
  hasBackButton,
  handleGoBackButton,
}: IHeaderPageProps) => {
  const { chatConversationList } = useContext(ChatContext);
  const [modalAssignPlan, setModalAssignPlan] = useState<ModalState>(initialModalState);
  const [modalAlerts, setModalAlerts] = useState<ModalState>(initialModalState);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { data, refetch } = useQuery({
    queryKey: ['alertsList'],
    queryFn: async () => await alertsListService(),
  });

  const loggedInUser = capitalize(user?.name || '');
  const loggedInCompany = capitalize(user?.companyLogin || '');

  const goToDashboardPage = useCallback(() => navigate('/dashboard'), [navigate]);
  const goToWhatsApp = useCallback(
    () =>
      window.open(
        `https://web.whatsapp.com/send/?phone=5511970309268&text=Ol%C3%A1%2C+sou+*${loggedInUser}*+da(o)+*${loggedInCompany}*%21&type=phone_number&app_absent=0`,
      ),

    [loggedInCompany, loggedInUser],
  );

  const getData = useMemo(() => data, [data]);

  const getRefetch = useCallback(() => refetch, [refetch]);

  const modalProps = useMemo((): IModalProps => ({ modalAlerts, setModalAlerts }), [modalAlerts]);

  const handleModalAssingPlan = useCallback(
    (open: boolean) => setModalAssignPlan((prevState) => ({ ...prevState, open })),
    [],
  );

  const handleModalAlert = useCallback(
    (prevState: MouseEvent<HTMLDivElement>) => {
      if (modalAlerts.open) {
        return setModalAlerts({ ...prevState, open: false });
      }
      setModalAlerts({ ...prevState, open: true });
    },
    [modalAlerts.open],
  );

  const handleChatModalOpen = useCallback(
    () => chatConversationList.modalActions.setIsOpenModal(true),
    [chatConversationList.modalActions],
  );

  const isOpenChatModal = useMemo(
    () => chatConversationList.modalActions.isOpenModal,
    [chatConversationList.modalActions],
  );

  const isUserEssencial = useMemo(
    (): boolean => user?.plan === 'basic-seller' || user?.plan === 'basic-buyer',
    [user?.plan],
  );

  return (
    <HeaderPageView
      dataTestId={dataTestId}
      breadcrumbList={breadcrumbList}
      renderCustomBackButton={renderCustomBackButton}
      customButtomRender={customButtonRender}
      handleModalAssingPlan={handleModalAssingPlan}
      handleModalAlert={handleModalAlert}
      goToDashboardPage={goToDashboardPage}
      goToWhatsApp={goToWhatsApp}
      hasBackButton={hasBackButton}
      handleGoBackButton={handleGoBackButton}
      user={user}
      data={getData}
      refetch={getRefetch}
      modalAssignPlan={modalAssignPlan}
      modalProps={modalProps}
      handleChatModalOpen={handleChatModalOpen}
      isOpenChatModal={isOpenChatModal}
      isUserEssencial={isUserEssencial}
    />
  );
};
