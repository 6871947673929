/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import * as S from './styled';

import { convertDecimalFormat } from 'utils-v2/converters/decimal';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getIcmsService } from 'utils/get-icms/service/get-icms.service';
import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { companyDetailsService } from 'services/company/details';
import { IUser } from 'domain/login';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { ICompany } from 'domain/company';
import { getGrisAdvaloremValueHook } from 'hooks/gris-advalorem';

interface TransportRouterDetailsProps {
  idleness: any;
  company?: ICompany;
  userCompany?: ICompany;
  proposalValue: number;
  user?: IUser;
}

export const IdlenessDetails: FC<TransportRouterDetailsProps> = ({
  idleness,
  company,
  userCompany,
  proposalValue,
  user,
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const [image, setImage] = useState<any>();
  const [icms, setIcms] = useState<number>(0);
  const [grisAdvalorem, setGrisAdvalorem] = useState<number>(0);
  const [grisAdvaloremPercent, setGrisAdvaloremPercent] = useState<{
    gris: number;
    advalorem: number;
  }>();

  useEffect(() => {
    setFieldValue('proposalValue', proposalValue);
  }, [proposalValue]);

  const getIcmsValue = useCallback(async () => {
    try {
      const response = await getIcmsService({
        ufOrigin: idleness.clientOrigin ? idleness.clientOrigin?.uf : idleness.localityOrigin?.uf,
        ufDestiny: idleness.clientDestination ? idleness.clientDestination?.uf : idleness.localityDestination?.uf,
      });

      setIcms(response);
    } catch (err: any) {
      showMessageFormatted({
        message: err,
        type: 'error',
      });
    }
  }, [idleness]);

  useEffect(() => {
    getIcmsValue();
  }, [getIcmsValue]);

  useEffect(() => {
    (async () => {
      const grisAdvaloremValue = getGrisAdvaloremValueHook({
        company: {
          gris: Number(userCompany?.gris),
          advalorem: Number(userCompany?.advalorem),
        },
        mechValue: values.cargo.merchValue,
      });

      setGrisAdvaloremPercent({
        gris: Number(userCompany?.gris),
        advalorem: Number(userCompany?.advalorem),
      });

      setGrisAdvalorem(grisAdvaloremValue);
    })();
  }, [userCompany?.advalorem, userCompany?.gris, values.cargo.merchValue]);

  const freightValues = useMemo(() => {
    const icmsValue = values.proposalValue / (1 - icms / 100) - values.proposalValue;

    const freightTotal = values.proposalValue + icmsValue;

    return { icmsValue, freightTotal };
  }, [values.proposalValue, icms]);

  useEffect(() => {
    (async () => {
      if (
        idleness.company.id === user?.companyId ||
        idleness.company?.companiesIds?.includes(Number(user?.companyId)) ||
        user?.profile == 'logshare'
      ) {
        const company = await companyDetailsService(idleness.company.id);
        setImage(company.fileKey);
      } else {
        setImage('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/logshare.png');
      }
    })();
  }, [idleness]);

  const originAndDestiny = useMemo(() => {
    const origin = idleness.clientOrigin ? idleness.clientOrigin : idleness.localityOrigin;

    const destination = idleness.clientDestination ? idleness.clientDestination : idleness.localityDestination;

    return { origin, destination };
  }, []);

  return (
    <S.WrapperTransportRouteDetails>
      <S.TitleContainer>
        <S.TitleForm>DETALHES DA OFERTA</S.TitleForm>
        <S.WrapperCompanyImage src={image} />
      </S.TitleContainer>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Origem</S.TitleValues>
          <S.TextValue>
            {originAndDestiny.origin.city.toUpperCase()} - {originAndDestiny.origin.uf.toUpperCase()}
          </S.TextValue>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Destino</S.TitleValues>
          <S.TextValue>
            {originAndDestiny.destination.city.toUpperCase()} - {originAndDestiny.destination.uf.toUpperCase()}
          </S.TextValue>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr 1fr">
        <S.WrapperOfferDetails>
          <S.TitleValues>Veículo</S.TitleValues>
          <S.TextValue>
            {`${convertVehicleTypeTypeToName(idleness.vehicleType)} - ${convertVehicleCategoryTypeToName(
              idleness.category,
            )}`}
          </S.TextValue>
        </S.WrapperOfferDetails>

        <S.WrapperOfferDetails>
          <S.TitleValues>Capacidade</S.TitleValues>
          <S.TextValue>
            {`${convertDecimalFormat.format(idleness.offeredWeight)} KG | ${idleness.offeredPallet} PLTS | ${
              idleness.offeredCubage
            } M³`}
          </S.TextValue>
        </S.WrapperOfferDetails>
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperTitleProposalValue>
          <S.TitleForm>{'FRETE PROPOSTO'}</S.TitleForm>
        </S.WrapperTitleProposalValue>

        <InputCurrency
          id="proposalValue"
          name="proposalValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          decimalSeparator=","
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', converterNumberPtBrToUs(value ?? '0'))}
          value={Number.parseFloat(values.proposalValue?.toString())
            .toFixed(2)
            .toString()}
          disabled
        />
      </LineSectionLayout>

      <LineSectionLayout columns="1fr">
        <S.WrapperOfferValues>
          <S.TextValue>FRETE VALOR</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(values.proposalValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>PEDÁGIO</S.TextValue>
          <S.TextValue>R$ 0,00</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <div style={{ display: 'flex', gap: 5 }}>
            <S.TextValue>GRIS/ADVALOREM</S.TextValue>
            <Tooltip
              title={
                <div>
                  <p style={{ fontSize: '11px' }}>
                    Gris: {convertDecimalFormat.format(grisAdvaloremPercent?.gris || 0)}%
                  </p>
                  <p style={{ fontSize: '11px' }}>
                    Advalorem: {convertDecimalFormat.format(grisAdvaloremPercent?.advalorem || 0)}%
                  </p>
                </div>
              }
              placement="right"
            >
              <div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </div>
            </Tooltip>
          </div>

          <S.TextValue>{convertCurrencyFormat.format(grisAdvalorem || 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <div style={{ display: 'flex', gap: 5 }}>
            <S.TextValue>FREETIME</S.TextValue>
            <Tooltip
              title={
                <div>
                  <p style={{ fontSize: '11px' }}>Freetime: {company?.freeTime}</p>
                  <p style={{ fontSize: '11px' }}>
                    Valor da Hora Parado: {convertCurrencyFormat.format(company?.hourlyFine ?? 0)}
                  </p>
                  <p style={{ fontSize: '11px' }}>
                    Valor da Diária Parado: {convertCurrencyFormat.format(company?.dailyFine ?? 0)}
                  </p>
                </div>
              }
              placement="right"
            >
              <div style={{ display: 'flex' }}>
                <InfoIcon
                  sx={{
                    height: '14px',
                    width: '14px',
                    color: 'gray',
                  }}
                />
              </div>
            </Tooltip>
          </div>

          <S.TextValue>{convertCurrencyFormat.format(0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>FRETE TOTAL</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(values.proposalValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>ICMS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(freightValues.icmsValue)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue>OUTROS IMPOSTOS</S.TextValue>
          <S.TextValue>{convertCurrencyFormat.format(values.cost.otherTaxes ?? 0)}</S.TextValue>
        </S.WrapperOfferValues>

        <S.WrapperOfferValues>
          <S.TextValue isBold>TOTAL A PAGAR</S.TextValue>
          <S.TextValue isBold>{convertCurrencyFormat.format(freightValues.freightTotal)}</S.TextValue>
        </S.WrapperOfferValues>
      </LineSectionLayout>
    </S.WrapperTransportRouteDetails>
  );
};
