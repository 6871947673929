import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const ContainerTariffAndInvoicingStyled = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const ContainerTariffStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  max-width: 20%;
  margin-top: 20px;
`;

export const ContainerInvoicingStyled = styled.div`
  display: flex;
  flex: 0 0 42%;
  max-width: 42%;
  margin-top: 20px;
  flex-direction: column;
`;

export const ContainerDestinyStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const OptionStyled = styled.option``;

export const ContainerSensorStyled = styled.div`
  margin-top: 10px;
`;

export const WrapperDateTimePikers = styled.div`
  margin-top: 6px;
`;
