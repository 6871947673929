import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button } from 'logshare-ui-kit';
import { Pencil } from 'lucide-react';
import { FC } from 'react';
import { Box } from 'src-new/components/box/box.component';
import * as S from './schedule-details-dates-view.styled';

interface IScheduleDetailsDatesViewProps {
  dates: {
    levyInitialDate: string;
    levyEndDate: string;
    deliveryInitialDate: string;
    deliveryEndDate: string;
  };
  disableEditButton: boolean;
  convertDates: (date: string) => string;
  handleOpenModalDates: () => void;
}

export const ScheduleDetailsDatesView: FC<IScheduleDetailsDatesViewProps> = ({
  dates,
  disableEditButton,
  convertDates,
  handleOpenModalDates,
}) => {
  return (
    <Box
      footerButtons={[
        <span key={0} />,
        <S.ContainerButtons key={1} isDisabled={disableEditButton}>
          <Button
            label=""
            color="white"
            onPress={handleOpenModalDates}
            LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
            size="x-sm"
            variant="ghost"
            disabled={disableEditButton}
          />
        </S.ContainerButtons>,
      ]}
    >
      <S.Wrapper>
        <S.Title>Datas Programadas</S.Title>

        <LineSectionLayout columns={'1fr 1fr 2fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Carga</S.InformationTitle>
            <S.InformationBody> </S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Início</S.InformationTitle>
            <S.InformationBody>{convertDates(dates.levyInitialDate)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Fim</S.InformationTitle>
            <S.InformationBody>{convertDates(dates.levyEndDate)}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>

        <LineSectionLayout columns={'1fr 1fr 2fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Descarga</S.InformationTitle>
            <S.InformationBody> </S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Início</S.InformationTitle>
            <S.InformationBody>{convertDates(dates.deliveryInitialDate)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Fim</S.InformationTitle>
            <S.InformationBody>{convertDates(dates.deliveryEndDate)}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>
      </S.Wrapper>
    </Box>
  );
};
