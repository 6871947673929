import moment, { type Moment } from 'moment';

import { dateKeys } from './filter';

import { ScheduleListParams } from 'services/schedule/types';

export const normalizeDate = (date: Moment | null | undefined) => {
  if (!date) return null;

  if (!moment(date).isValid()) {
    const usDate = date.format('MM-DD-YYYY');

    if (!moment(usDate).isValid()) return null;

    return moment(usDate);
  }

  return moment(date);
};

export const nomalizeParamsDate = (params: ScheduleListParams, format?: string) => {
  const formatedDateParams = Object.entries(params).map(([key, value]) => {
    if (dateKeys.includes(key)) {
      return {
        [key]: normalizeDate(value)?.format(format ?? 'DD/MM/YYYY') ?? '',
      };
    }
    return { [key]: value ?? '' };
  });

  const formatedParams = formatedDateParams.reduce((acc, current) => {
    return { ...acc, ...current };
  }, {});

  return formatedParams as ScheduleListParams;
};
