import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { WeighingHeaderView } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-page-body/components/weighing-header/weighing-header-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { WeighingListModalType } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { IParamsFilter } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.types';
import { convertWeighingFilters } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.util';
import { IWeighingListFilters } from 'src-new/pages/execution/pages/weighing/types/weighing-list-filters/weighing-list-filters.types';
import { useCreateWeighingExcel } from '../../../../hooks/use-create-weighing-excel/use-create-weighing-excel.hook';
import { IWeighingListFilterDomain } from '../../../../domains/weighing-list-filters/weighing-list-filters.domain';
import { useQueryClient } from 'react-query';
import { weighingListFilterInitialValues } from '../../../../domains/weighing-list-filters/weighing-list-filters-initial-values.domain';

export const WeighingHeader: FC = () => {
  const navigate = useNavigate();
  const reactQueryClient = useQueryClient();
  const { weighingList } = useContext(WeighingContext);
  const { filters } = weighingList;
  const { selectedFilter, setSelectFilters } = filters;
  const createWeighingExcel = useCreateWeighingExcel();

  const handleModalOpen = useCallback(
    (modalType: WeighingListModalType) => {
      weighingList.modalsProps.setModalOpen({ open: true, ids: [], modalType });
    },
    [weighingList.modalsProps],
  );

  const handleNewWeighing = useCallback(() => {
    navigate('/pesagem/novo');
  }, [navigate]);

  const handleSearch = useCallback(
    (search: string) => {
      const mountSearchFilter: IWeighingListFilterDomain = {
        ...selectedFilter,
        search: search,
        page: 1,
      };
      setSelectFilters(mountSearchFilter);
      sessionStorage.setItem('weighing-list-filters', JSON.stringify(mountSearchFilter));
    },
    [selectedFilter, setSelectFilters],
  );

  const handleExportExcel = useCallback(async () => {
    await createWeighingExcel(selectedFilter);
  }, [createWeighingExcel, selectedFilter]);

  const removeStatusItemFilter = useCallback((value: string) => {
    switch (value) {
      case 'PORTARIA':
        return selectedFilter.status.filter((status) => status !== 'AGUARDANDO_PATIO');

      case 'AGUARDANDO CHECK-IN':
        return selectedFilter.status.filter((status) => status !== 'AGUARDANDO_CHECKING');

      case 'PESAGEM ENTRADA REALIZADA':
        return selectedFilter.status.filter((status) => status !== 'PESAGEM_ENTRADA_REALIZADA');

      case 'PESAGEM SAIDA REALIZADA':
        return selectedFilter.status.filter((status) => status !== 'PESAGEM_SAIDA_REALIZADA');
    }
  },[selectedFilter.status]);

  const weighingFilters: Array<IParamsFilter> = useMemo(() => convertWeighingFilters(selectedFilter), [selectedFilter]);

  const removeFilter = useCallback(
    async (indexToRemove: number) => {
      const updatedParamsArray = [...weighingFilters];
      const removedItem = updatedParamsArray.splice(indexToRemove, 1);
      const key = removedItem[0].key;

      const updatedFilters: IWeighingListFilters = {
        ...selectedFilter,
        page: 1,
        limit: 100,
        [key]: '',
        operation: weighingList.filters.selectedFilter.operation,
      };

      if (key === 'status') {
        const res = removeStatusItemFilter(removedItem[0].value.toString());
        if (res) updatedFilters.status = res;
      }

      if (key === 'itemDescription') {
        updatedFilters.itemId = 0;
      }

      setSelectFilters(updatedFilters);
      sessionStorage.setItem('weighing-list-filters', JSON.stringify(updatedFilters));
      await reactQueryClient.invalidateQueries(['weighing-list-table', weighingListFilterInitialValues]);
    },
    [reactQueryClient, removeStatusItemFilter, selectedFilter, setSelectFilters, weighingFilters, weighingList.filters.selectedFilter.operation],
  );

  const appliedFilters = useMemo(
    (): number => weighingFilters.filter((filter) => !Array.isArray(filter.value)).length,
    [weighingFilters],
  );

  return (
    <WeighingHeaderView
      weighingFilters={weighingFilters}
      appliedFilters={appliedFilters}
      handleNewWeighing={handleNewWeighing}
      handleSearch={handleSearch}
      removeFilter={removeFilter}
      handleModalOpen={handleModalOpen}
      handleExportExcel={handleExportExcel}
    />
  );
};
