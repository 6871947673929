import { Formik } from 'formik';
import { FC, Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseUpdateTransaction } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-transaction/use-update-transaction.hook';
import { IUpdateTransactionPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-transaction/update-transaction.types';
import { ScheduleFinanceCalculateModalForm } from './components/schedule-finance-calculate-modal-form/schedule-finance-calculate-modal-form.component';
import { ScheduleFinanceAccordionsView } from './schedule-finance-accordions-view.component';
import { ScheduleFinanceAuditModalForm } from './components/schedule-finance-audit-modal-form/schedule-finance-audit-modal-form.component';
import { UserContext } from 'state/user-context';
import { FinanceStatus, TransactionType } from './schedule-finance-accordions.types';
import { useUpdateFinanceStatus } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-finance-status/use-update-finance-status.hook';

export const ScheduleFinanceAccordions: FC = () => {
  const { user, isAdmin } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const [isOpen, setIsOpen] = useState<{
    isOpen: {
      transactionPayable: boolean;
      transactionReceive: boolean;
      transactionShippingReceive: boolean;
    };
  }>({
    isOpen: {
      transactionPayable: false,
      transactionReceive: false,
      transactionShippingReceive: false,
    },
  });

  const updateTransactionHook = UseUpdateTransaction();
  const updateFinanceStatusHook = useUpdateFinanceStatus();

  const handleOpenOrCloseFinance = useCallback(
    (isOpen: { transactionPayable: boolean; transactionReceive: boolean; transactionShippingReceive: boolean }) => {
      setIsOpen({ isOpen });
    },
    [],
  );

  const handleOpenModalFinanceCalculate = useCallback(
    (
      financeType:
        | 'transaction_payable'
        | 'transaction_receive'
        | 'transaction_shipping_receive'
        | 'audit_transaction_payable'
        | 'audit_transaction_receive'
        | 'audit_transaction_shipping_receive',
    ) => {
      scheduleDetails.setScheduleDetailsAllocationOpen(financeType);
    },
    [scheduleDetails],
  );

  const handleChangeFinanceStatus = useCallback(
    (type: TransactionType, financeStatus: FinanceStatus) => {
      const { freithgtPayable, freithgtReceivable, freithgtShipping } = scheduleDetails.scheduleDetails;

      if (financeStatus === 'Pagamento Programado' && freithgtReceivable && freithgtShipping) {
        updateFinanceStatusHook(freithgtReceivable.id, financeStatus);
        updateFinanceStatusHook(freithgtShipping.id, financeStatus);
        return;
      }

      switch (type) {
        case 'freightPayable':
          updateFinanceStatusHook(freithgtPayable.id, financeStatus);
          return;
        case 'freightReceivable':
          updateFinanceStatusHook(freithgtReceivable.id, financeStatus);
          return;
        case 'freightShipping':
          updateFinanceStatusHook(freithgtShipping.id, financeStatus);
          return;
      }
    },
    [scheduleDetails.scheduleDetails, updateFinanceStatusHook],
  );

  const handleCloseModalFinanceCalculate = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails]);

  const handleSubmit = useCallback(
    (values: IUpdateTransactionPayload) => {
      updateTransactionHook(values.id, values);
      handleCloseModalFinanceCalculate();
    },
    [handleCloseModalFinanceCalculate, updateTransactionHook],
  );

  const renderModalTransaction = useMemo(() => {
    const { freithgtPayable, freithgtReceivable, freithgtShipping } = scheduleDetails.scheduleDetails;
    if (
      scheduleDetails.allocationActions.allocatedEntity === 'transaction_payable' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return (
        <Formik
          initialValues={{
            id: freithgtPayable.id,
            scheduleId: scheduleDetails.scheduleDetails.id,
            totalFreight: freithgtPayable.totalFreight,
            valueFreight: freithgtPayable.valueFreight,
            helperTotal: freithgtPayable.helperTotal,
            discharge: freithgtPayable.discharge,
            totalGrisAdvalorem: freithgtPayable.totalGrisAdvalorem,
            surplusTime: freithgtPayable.surplusTime,
            penaltyBonus: freithgtPayable.penaltyBonus,
            totalFee: freithgtPayable.totalFee,
            toll: freithgtPayable.toll,
            otherTaxes: freithgtPayable.otherTaxes,
            totalService: freithgtPayable.totalService,
            icms: freithgtPayable.icms,
            iss: freithgtPayable.iss,
            feeAnticipation: freithgtPayable.feeAnticipation,
            margin: freithgtPayable.margin,
            status: freithgtPayable.status,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleFinanceCalculateModalForm handleCloseModalFinanceCalculate={handleCloseModalFinanceCalculate} />
        </Formik>
      );
    }

    if (
      scheduleDetails.allocationActions.allocatedEntity === 'transaction_receive' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return (
        <Formik
          initialValues={{
            id: freithgtReceivable.id,
            scheduleId: scheduleDetails.scheduleDetails.id,
            totalFreight: freithgtReceivable.totalFreight,
            valueFreight: freithgtReceivable.valueFreight,
            helperTotal: freithgtReceivable.helperTotal,
            discharge: freithgtReceivable.discharge,
            totalGrisAdvalorem: freithgtReceivable.totalGrisAdvalorem,
            surplusTime: freithgtReceivable.surplusTime,
            penaltyBonus: freithgtReceivable.penaltyBonus,
            totalFee: freithgtReceivable.totalFee,
            toll: freithgtReceivable.toll,
            otherTaxes: freithgtReceivable.otherTaxes,
            totalService: freithgtReceivable.totalService,
            icms: freithgtReceivable.icms,
            iss: freithgtReceivable.iss,
            feeAnticipation: freithgtReceivable.feeAnticipation,
            margin: freithgtReceivable.margin,
            status: freithgtReceivable.status,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleFinanceCalculateModalForm handleCloseModalFinanceCalculate={handleCloseModalFinanceCalculate} />
        </Formik>
      );
    }

    if (
      scheduleDetails.allocationActions.allocatedEntity === 'transaction_shipping_receive' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return (
        <Formik
          initialValues={{
            id: freithgtShipping.id,
            scheduleId: scheduleDetails.scheduleDetails.id,
            totalFreight: freithgtShipping.totalFreight,
            valueFreight: freithgtShipping.valueFreight,
            helperTotal: freithgtShipping.helperTotal,
            discharge: freithgtShipping.discharge,
            totalGrisAdvalorem: freithgtShipping.totalGrisAdvalorem,
            surplusTime: freithgtShipping.surplusTime,
            penaltyBonus: freithgtShipping.penaltyBonus,
            totalFee: freithgtShipping.totalFee,
            toll: freithgtShipping.toll,
            otherTaxes: freithgtShipping.otherTaxes,
            totalService: freithgtShipping.totalService,
            icms: freithgtShipping.icms,
            iss: freithgtShipping.iss,
            feeAnticipation: freithgtShipping.feeAnticipation,
            margin: freithgtShipping.margin,
            status: freithgtShipping.status,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleFinanceCalculateModalForm handleCloseModalFinanceCalculate={handleCloseModalFinanceCalculate} />
        </Formik>
      );
    }
  }, [
    handleCloseModalFinanceCalculate,
    handleSubmit,
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.allocationActions.isOpen,
    scheduleDetails.scheduleDetails,
  ]);

  const renderModalAudit = useMemo(() => {
    const { freithgtPayable, freithgtReceivable, freithgtShipping } = scheduleDetails.scheduleDetails;
    if (
      scheduleDetails.allocationActions.allocatedEntity === 'audit_transaction_payable' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return <ScheduleFinanceAuditModalForm transactionDetails={freithgtPayable} />;
    }

    if (
      scheduleDetails.allocationActions.allocatedEntity === 'audit_transaction_receive' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return <ScheduleFinanceAuditModalForm transactionDetails={freithgtReceivable} />;
    }

    if (
      scheduleDetails.allocationActions.allocatedEntity === 'audit_transaction_shipping_receive' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return <ScheduleFinanceAuditModalForm transactionDetails={freithgtShipping} />;
    }
  }, [
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.allocationActions.isOpen,
    scheduleDetails.scheduleDetails,
  ]);

  const isLogshareSeller = useMemo(
    (): boolean => scheduleDetails.scheduleDetails.company.id === 2,
    [scheduleDetails.scheduleDetails.company.id],
  );

  return (
    <Fragment>
      {renderModalAudit}
      {renderModalTransaction}
      <ScheduleFinanceAccordionsView
        user={user}
        isAdmin={isAdmin}
        schedule={scheduleDetails.scheduleDetails}
        isOpen={isOpen}
        isLogshareSeller={isLogshareSeller}
        freightPayable={scheduleDetails.scheduleDetails.freithgtPayable}
        freightReceivable={scheduleDetails.scheduleDetails.freithgtReceivable}
        freightShipping={scheduleDetails.scheduleDetails.freithgtShipping}
        handleOpenModalFinanceCalculate={handleOpenModalFinanceCalculate}
        handleChangeFinanceStatus={handleChangeFinanceStatus}
        handleOpenOrCloseFinance={handleOpenOrCloseFinance}
      />
    </Fragment>
  );
};
