import React from 'react';

import DashboardBarView from './dashboard-bar-view';

interface IBarDashboardProps {
  anos?: {
    country: string;
    compra: number;
    venda: number;
  }[];

  pedidosFinalizadosPeriodo: {
    country: string;
    pedidosFinalizados: number;
    pedidosNaoFinalizados: number;
  }[];
}

const DashboardBar: React.FC<IBarDashboardProps> = ({ pedidosFinalizadosPeriodo }) => (
  <DashboardBarView pedidosFinalizadosPeriodo={pedidosFinalizadosPeriodo} />
);

export default DashboardBar;
