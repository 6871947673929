import React, { ReactNode, useState } from 'react';

import ButtonView from './button-view';

import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

export type ButtonProps = {
  callback?: () => void;
  title: string;
  icon?: React.ReactNode;
  iconPosition?: 'right' | 'left';
  bgColor?:
    | 'blue'
    | 'white'
    | 'pink'
    | 'green'
    | 'orange'
    | 'darkGray'
    | 'middleBlue'
    | 'newRed'
    | 'aqua'
    | 'lightGray';
  type?: 'button' | 'submit' | 'reset';
  modalType?: 'delete';
  size: 'xlarge' | 'large' | 'medium' | 'small' | 'very-small' | 'full' | 'narrow';
  isLoading?: () => void;
  disabled?: boolean;
  getPlan?: string;
  text?: string;
  description?: string;
  height?: string;
};

const Button: React.FC<ButtonProps> = ({
  title,
  callback = () => null,
  bgColor,
  icon,
  type,
  disabled,
  isLoading,
  modalType,
  getPlan,
  size,
  text,
  description,
  iconPosition = 'right',
}) => {
  const [modalDelete, setModalDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const goToDelete = () => {
    const description = (
      <>
        <p>Isso excluirá o registro da listagem.</p>
        <p style={{ marginTop: 5 }}>Tem certeza?</p>
      </>
    );
    setModalDelete({ open: true, description: description });
  };

  const handleCallBack = () => {
    callback();
    setModalDelete((old) => ({ ...old, open: false }));
  };

  return (
    <>
      {modalType === 'delete' && (
        <>
          {modalDelete.open && (
            <ModalConfirmation
              title="Excluir Registro(s)"
              secondTitle={modalDelete.description}
              handleCancel={() => setModalDelete((old) => ({ ...old, open: false }))}
              onConfirmation={handleCallBack}
              size="small"
              type="delete"
              description={description}
            />
          )}
        </>
      )}
      <ButtonView
        callback={modalType === 'delete' ? goToDelete : callback}
        title={title}
        bgColor={bgColor}
        icon={icon}
        type={type}
        size={size}
        getPlan={getPlan}
        disabled={disabled}
        text={text}
        isLoading={isLoading}
        iconPosition={iconPosition}
      />
    </>
  );
};

export default Button;
