import React, { createContext, useMemo, useReducer } from 'react';
import { transactionsInitialState } from './transactions.constants';
import { transactionsReducer } from './reducers/transactions.reducer';
import { ITransactionsContextProps } from './transactions.types';
import { ITransactionsContext } from './types/transactions.types';
import { ITransactionsList } from './types/transactions-list.types';
import { useTransactionsListContextValues } from 'src-new/pages/financial/pages/transactions/contexts/transactions/context-values/transactions-list/transactions-list-values.context';

export const TransactionsContext = createContext<ITransactionsContext>(transactionsInitialState);

export const TransactionsContextProvider = ({ children }: ITransactionsContextProps) => {
  const [state] = useReducer(transactionsReducer, transactionsInitialState);

  const transactionsList: ITransactionsList = useTransactionsListContextValues();

  const value: ITransactionsContext = useMemo(
    (): ITransactionsContext => ({
      ...state,
      transactionsList,
    }),
    [transactionsList, state],
  );

  return <TransactionsContext.Provider value={value}>{children}</TransactionsContext.Provider>;
};
