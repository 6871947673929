import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { initializeApp } from 'firebase/app';
import 'react-dates/lib/css/_datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getDatabase } from 'firebase/database';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { getFirestore } from '@firebase/firestore';
import ToastContainer from 'styles/toast.styled';
import theme from 'styles/theme';
import GlobalStyle from 'styles/global';
import UserContextProvider from 'state/user-context';
import FirebaseProd from 'firebase.prod.json';
import FirebaseDev from 'firebase.dev.json';
import SidebarProvider from 'components/sidebar/contexts/sidebar.context';
import { AppPage } from 'src-new/pages/app/app.page';
import { BrowserRouter } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || Buffer;
export const app = initializeApp(import.meta.env.VITE_FIREBASE_TARGET === 'development' ? FirebaseDev : FirebaseProd);
export const database = getDatabase(app);
export const firestoreDB = getFirestore(app);
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiThemeProvider theme={() => createTheme(theme)}>
          <StyledProvider theme={theme}>
            <GlobalStyle />
            <QueryClientProvider client={queryClient}>
              <UserContextProvider>
                <SidebarProvider>
                  <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
                    <AppPage />
                  </APIProvider>
                </SidebarProvider>
              </UserContextProvider>
            </QueryClientProvider>
            <ToastContainer />
          </StyledProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
