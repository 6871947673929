import * as Yup from 'yup';

export const RegisterPalletValidation = Yup.object().shape({
  equipmentVoucher: Yup.string().required('Campo Obrigatório'),
  palletType: Yup.string().required('Campo Obrigatório'),
  palletQuantity: Yup.string().required('Campo Obrigatório'),
  equipmentVoucherDate: Yup.date().required('Campo Obrigatório'),
  withdrawalDate: Yup.date().required('Campo Obrigatório'),
  entryDate: Yup.date().required('Campo Obrigatório'),
});
