import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContainerModalTitle = styled.div`
  display: flex;
  gap: 5px;
`;

export const ModalTitle = styled.p`
  font-size: 0.85rem;
  font-weight: 500;
`;

export const ModalTitleBold = styled.p`
  font-size: 0.85rem;
  font-weight: 700;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const WrapperButtonSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
`;
