import React, { Fragment, ReactNode, useCallback, useMemo } from 'react';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IOrdering, IRow } from 'src-new/components/table/table.types';
import { PurchaseOfferModalMatchScore } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-score/purchase-offer-modal-match-score.component';
import * as S from './modal-match-table-view.styled';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';
import { PurchaseOfferModalMatchScoreLabel } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-score/purchase-offer-modal-match-score-label/purchase-offer-modal-match-score-label.component';
import { Tag } from 'src-new/components/tag/tag.component';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';

interface IScheduleOfferModalMatchTableViewProps {
  purchaseOfferMatch: Array<any>;
  orderingColumns: IOrdering;
  viewImage: boolean;
  handleMatchBuy: (backhaul: any) => void;
}

export const ScheduleOfferModalMatchTableView: React.FC<IScheduleOfferModalMatchTableViewProps> = ({
  purchaseOfferMatch,
  orderingColumns,
  handleMatchBuy,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: '' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Match Potencial' },
      { value: 'Classificação' },
      { value: 'Frete Valor' },
    ];

    const columnsConfig = '0.25fr 0.15fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string, subtitle: string) => {
    return (
      <Fragment>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle?.toUpperCase()}</S.Subtitle>
      </Fragment>
    );
  }, []);

  const renderTitleReactNode = useCallback((reactNode: ReactNode) => {
    return <Fragment>{reactNode}</Fragment>;
  }, []);

  const renderCompanyImageNode = useCallback((id: number) => {
    return (
      <S.WrapperIdAndImage>
        <S.Title>{id}</S.Title>
      </S.WrapperIdAndImage>
    );
  }, []);

  const renderLocationText = useCallback((origin: any, destination: any) => {
    return (
      <S.WrapperInformationLocations>
        <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
        <div>
          <S.Title>
            {origin.city.toUpperCase()} - {origin.uf.toUpperCase()}
          </S.Title>
          <S.Title>
            {destination.city.toUpperCase()} - {destination.uf.toUpperCase()}
          </S.Title>
        </div>
      </S.WrapperInformationLocations>
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return purchaseOfferMatch.map((match) => {
      return {
        key: match.saleOfferShipperTariffId,
        onClick: () => undefined,
        valuesColumns: [
          {
            value: renderCompanyImageNode(match.saleOfferShipperTariffId),
          },
          { value: renderTitleSubtitle('', '') },
          { value: renderLocationText(match.saleOfferDetails.origin, match.saleOfferDetails.destination) },
          { value: renderTitleSubtitle(match.saleOfferDetails.vehicle_type, match.saleOfferDetails.vehicle_category) },
          { value: renderTitleSubtitle('- KG', '- PALLETS') },
          {
            value: renderTitleSubtitle(match.saleOfferDetails.frequency, 'VIAGENS/MÊS'),
          },
          {
            value: renderTitleReactNode(
              <Fragment>
                <PurchaseOfferModalMatchScoreLabel
                  sumScore={match.vehicleScore + match.originDistanceScore + match.destinationDistanceScore}
                />
                <S.WrapperMatchScore>
                  <PurchaseOfferModalMatchScore label={'V'} score={match.vehicleScore} />
                  <PurchaseOfferModalMatchScore label={'O'} score={match.originDistanceScore} />
                  <PurchaseOfferModalMatchScore label={'D'} score={match.destinationDistanceScore} />
                </S.WrapperMatchScore>
              </Fragment>,
            ),
          },
          {
            value: (
              <S.WrapperTags>
                <S.Div>
                  <Tag
                    label={'BACKHAUL' + ' ' + match.backhaulType}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={match.purchaseOfferC02.toFixed(2)}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                    LeftIcon={
                      <S.TagText>
                        <DoubleArrowDownIcon />
                        CO₂
                      </S.TagText>
                    }
                  />
                </S.Div>

                <S.Div>
                  <Tag
                    label={'DESVIO ' + ' ' + Number(match.totalDistanceDeviation).toFixed(0) + ' KM'}
                    color={ThemeColorsV2Type.DarkGray}
                    variant="ghost"
                    display="inline-flex"
                    size="md"
                  />
                </S.Div>
              </S.WrapperTags>
            ),
          },
          {
            value: renderTitleSubtitle(convertCurrencyFormat.format(match.saleOfferDetails.value_total), '+ IMPOSTOS'),
          },
          {
            value: (
              <S.Div>
                <Button label="Comprar" size="sm" color="blue" onPress={() => handleMatchBuy(match)} variant="solid" />
              </S.Div>
            ),
          },
        ],
      };
    });
  }, [
    handleMatchBuy,
    purchaseOfferMatch,
    renderCompanyImageNode,
    renderLocationText,
    renderTitleReactNode,
    renderTitleSubtitle,
  ]);

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        ordering={orderingColumns}
        rows={renderRows}
        height={'380px'}
      />
      <ChatButton chatProps={{ typeChat: 'SINGLE', name: '' }} />
    </S.Wrapper>
  );
};
