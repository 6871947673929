import styled from 'styled-components';

export const Title = styled.div`
  color: #000;
  font-size: 11px;
  font-style: normal;
  line-height: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LoadingWrapper = styled.div`
  height: 275px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
