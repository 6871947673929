import React from 'react';

import { WrapperStyled, TitleStyled, MenuIconStyled } from './menu-item.styled';

type ItemProps = {
  title: string;
  icon: JSX.Element;
  selected: boolean;
  showText: boolean;
  handleClick: (_args: string) => void;
};

const ItemView: React.FC<ItemProps> = (params) => {
  return (
    <WrapperStyled
      selected={params.selected}
      showText={params.showText}
      onMouseEnter={() => params.handleClick(params.title)}
      onMouseLeave={() => params.handleClick(params.title)}
    >
      {!!params.icon && (
        <MenuIconStyled aria-label={`icon-${params.title}`} selected={params.selected}>
          {params.icon}
        </MenuIconStyled>
      )}
      <TitleStyled showText={params.showText}>{params.title}</TitleStyled>
    </WrapperStyled>
  );
};

export default ItemView;
