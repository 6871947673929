import styled, { css } from 'styled-components';

export const HireBackhaulBuyMatchFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`;
export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  width: 100%;
`;

interface ContainerRowProps {
  my?: number;
  mx?: number;
  px?: number;
  py?: number;
  borderBottom?: boolean;
}

export const ContainerRowStyled = styled.div<ContainerRowProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  font-weight: 500;
  margin: ${({ my }) => my ?? '0'}px ${({ mx }) => mx ?? '0'}px;
  padding: ${({ py }) => py ?? '0'}px ${({ px }) => px ?? '0'}px;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid rgba(0,0,0,0.3)' : '')};
`;
export const SecondTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  margin-bottom: 8px;
  font-weight: 600;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 12px;
  `}
`;
