import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IListPage } from '../../../../domain/page-info';

import { ISantosPortContext } from './santos-port.types';
import { santosPortReducer } from './santos-port.reducer';
import { ISantosPortAction, initialState } from './santos-port.actions';

import { IPortSchedule } from 'domain/santos-port';

interface ISantosPortContextProps {
  children: ReactNode;
}

export const SantosPortContext = createContext<ISantosPortContext>(initialState);

const SantosPortContextProvider = ({ children }: ISantosPortContextProps) => {
  const [state, dispatch] = useReducer(santosPortReducer, initialState);

  const setSantosPortRequest = useCallback(() => {
    dispatch({ type: ISantosPortAction.SANTOS_PORT_REQUEST, payload: null });
  }, []);

  const setSantosPortSuccess = useCallback((SantosPortList: IListPage<IPortSchedule>) => {
    dispatch({
      type: ISantosPortAction.SANTOS_PORT_SUCCESS,
      payload: SantosPortList,
    });
  }, []);

  const setSantosPortError = useCallback((error: string) => {
    dispatch({ type: ISantosPortAction.SANTOS_PORT_ERROR, payload: error });
  }, []);

  const value = useMemo(() => {
    return {
      santosPort: state.santosPort,
      santosPortIsLoading: state.santosPortIsLoading,
      santosPortError: state.santosPortError,
      santosPortPageInfo: state.santosPortPageInfo,
      setSantosPortRequest,
      setSantosPortSuccess,
      setSantosPortError,
    };
  }, [
    setSantosPortError,
    setSantosPortRequest,
    setSantosPortSuccess,
    state.santosPort,
    state.santosPortError,
    state.santosPortIsLoading,
    state.santosPortPageInfo,
  ]);

  return <SantosPortContext.Provider value={value}>{children}</SantosPortContext.Provider>;
};

export default React.memo(SantosPortContextProvider);
