import React, { useEffect, useState } from 'react';

import InputCircleUploadView from './input-circle-upload-image-view';

type InputUploadImageProps = {
  urlImage?: string;
  onClick?: () => void;
};

const InputCircleUpload: React.FC<InputUploadImageProps> = ({ urlImage, onClick }) => {
  const [dataUrlImage, setDataUrlImage] = useState('');

  useEffect(() => {
    setDataUrlImage(urlImage || '');
  }, [urlImage]);

  return <InputCircleUploadView dataUrlImage={dataUrlImage} onClick={onClick} />;
};

export default InputCircleUpload;
