import { IRegistrationsContext } from './types/registrations.types';

export const registrationsInitialState: IRegistrationsContext = {
  contactDetailsSection: {
    isOpenModal: false,
    setModalOpen: () => undefined,
    setModalClose: () => undefined,
    setContactDetailsSectionReset: () => undefined,
  },
  attachDocumentsSection: {
    getAttachDocuments: {
      statusRequest: 'INITIAL',
      setGetDocumentRequest: () => undefined,
      setGetDocumentSuccess: () => undefined,
      setGetDocumentError: () => undefined,
    },
    isOpenModal: false,
    setModalOpen: () => undefined,
    setModalClose: () => undefined,
    setAttachDocumentsSectionReset: () => undefined,
  },
  riskManagementSection: {
    modalProps: {
      isOpenModal: false,
      setModalOpen: () => undefined,
      setModalClose: () => undefined,
    },
    setRiskManagementSectionReset: () => undefined,
  },
  setRegistrationsResetContext: () => undefined,
};
