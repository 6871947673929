import { IManagementData } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import API from 'utils/API/API';

export const getMDFeDataService = async (scheduleId: number): Promise<Array<IManagementData>> => {
  const { data } = await API.get<Array<IManagementData>>(
    `v2/file/management?module=schedule&type=MDFe&codigo=${scheduleId}`,
  );

  return data;
};
