import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateOthersDocumentsDataService } from '../../services/update-others-documents-data/update-others-documents-data.service';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getOthersDocumentsDataService } from '../../services/get-others-documents-data/get-others-documents-data.service';

export const useUpdateOthersDocumentsData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(
    async (files: FileList) => {
      try {
        scheduleDocuments.othersDocumentsProps.setOthersDocumentsRequest();
        await updateOthersDocumentsDataService(
          scheduleDetails.scheduleDetails.id,
          files,
          scheduleDetails.scheduleDetails.statusCode,
        );

        const data = await getOthersDocumentsDataService(scheduleDetails.scheduleDetails.id);

        scheduleDocuments.othersDocumentsProps.setOthersDocumentsSuccess(data);
      } catch (error: any) {
        scheduleDocuments.othersDocumentsProps.setOthersDocumentsError();
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    },
    [
      scheduleDetails.scheduleDetails.id,
      scheduleDetails.scheduleDetails.statusCode,
      scheduleDocuments.othersDocumentsProps,
    ],
  );
};
