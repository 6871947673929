import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RiskManagementModalView } from './risk-management-modal-view.component';
import { FieldArrayRenderProps, FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { RiskManagementValues } from 'src-new/pages/registrations/pages/driver/domains/risk-management-values.domain';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';
import { riskManagementInitialValues } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/components/risk-management-modal/risk-management-modal.constants';

interface IRiskManagementModalProps {
  arrayHelpers: FieldArrayRenderProps;
  setModalClose: () => void;
  driverId?: number;
}

export const RiskManagementModal: React.FC<IRiskManagementModalProps> = ({ arrayHelpers, setModalClose, driverId }) => {
  const { getFieldProps, setFieldValue, handleChange, getFieldMeta, validateForm, setTouched, values } =
    useFormikContext<RiskManagementValues>();
  const [position, setPosition] = useState<number>(values.riskManagers?.length);
  const [existPosition, setExistPosition] = useState<boolean>(false);
  const [currenRisk, setCurrenRisk] = useState<IRiskManagementDomain | undefined>(undefined);

  const handleCloseModal = useCallback(() => {
    arrayHelpers.remove(position);
    setModalClose();
  }, [arrayHelpers, position, setModalClose]);

  const hasErrorField = useCallback(
    (name: string): boolean => !!getFieldMeta(name).error && getFieldMeta(name).touched,
    [getFieldMeta],
  );

  const getErrorMessage = useCallback((name: string): string => getFieldMeta(name).error ?? '', [getFieldMeta]);

  const riskManagers = useMemo(
    (): Array<IRiskManagementDomain> => getFieldProps('riskManagers').value ?? [],
    [getFieldProps],
  );

  const riskManager = useMemo((): IRiskManagementDomain | undefined => {
    if (riskManagers.length >= 1) {
      const risk = getFieldProps(`riskManagers[${position}]`).value;

      if (!currenRisk && risk) {
        setCurrenRisk(risk);
      }

      return risk;
    }
  }, [riskManagers.length, currenRisk, getFieldProps, position]);

  const handleSaveButton = useCallback(
    async (item: IRiskManagementDomain) => {
      const formErrors = await validateForm();
      const errorsContact = formErrors.riskManagers ? { riskManagers: formErrors.riskManagers } : undefined;

      if (errorsContact && Object.keys(errorsContact).length > 0) {
        setTouched(setNestedObjectValues<FormikTouched<RiskManagementValues>>(errorsContact, true));
      } else {
        setFieldValue(`riskManagers[${position}]`, item);
        setModalClose();
      }
    },
    [position, setFieldValue, setModalClose, setTouched, validateForm],
  );

  const handleInputDateTimeChange = useCallback(
    (field: string, date?: string): void => {
      if (date) {
        setFieldValue(`riskManagers[${position}].${field}`, date);
      }
    },
    [position, setFieldValue],
  );

  useEffect(() => {
    if (!existPosition) {
      arrayHelpers.insert(riskManagers.length, {
        ...riskManagementInitialValues,
        driverId: driverId ?? riskManagementInitialValues.driverId,
      });
      setPosition(riskManagers.length);
      setExistPosition(true);
    }
  }, [arrayHelpers, driverId, existPosition, riskManagers.length]);

  return (
    <RiskManagementModalView
      handleCloseModal={handleCloseModal}
      riskManager={riskManager}
      hasErrorField={hasErrorField}
      getErrorMessage={getErrorMessage}
      position={position}
      handleSaveButton={handleSaveButton}
      handleChange={handleChange}
      handleInputDateTimeChange={handleInputDateTimeChange}
      handleInputSelectChange={setFieldValue}
    />
  );
};
