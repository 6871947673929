import styled from 'styled-components';

export const ContainerStyled = styled.div``;

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 10px;
`;
export const OptionStyled = styled.option``;

export const ContainerAccessStyled = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const AccessRestrictedStyled = styled.div`
  color: rgb(235 235 235);
  font-weight: 700;
  font-size: 60px;
`;
