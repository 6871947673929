import { updateStatusCodeScheduleService } from '../use-status-code-update/use-status-code-update.service';

import API from 'utils/API/API';

export const finishScheduleService = async (id: number, scheduleDetailsId: number) => {
  const finishDate = await API.put(`v2/schedule-details/${scheduleDetailsId}`, {
    finishDate: new Date().toISOString(),
  });

  await updateStatusCodeScheduleService(id, 'IN90');

  return [finishDate];
};
