/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { useFormikContext } from 'formik';

import { ContainerRowStyled, HireBackhaulBuyMatchFormStyled, LineSectionStyled, SecondTitleStyled } from './styled';

import { convertStatusCodeOptions } from 'utils/status-code';
import { UserContext } from 'state/user-context';
import { defaultFilter } from 'pages-v2/schedule/utils/filter';
import { FilterProps } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';
import { SelectItem } from 'pages/choose-company/service/entities/logshare-access';
import {
  operationBy,
  operationTypeOptions,
  typeOperation,
  vehicleCategoryOptions,
  vehicleTypeOptions,
} from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import { InputCalendar } from 'components/input-calendar/input-calendar';
import Button from 'components/button/button';
import AsyncSelectField from 'components/async-select/async-select';

export const FilterForm: React.FC = () => {
  const { isAdmin } = useContext(UserContext);

  const [initialDateCollect, setInitialDateCollect] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });
  const [initialDateDeliver, setInitialDateDeliver] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  const [finalDateCollect, setFinalDateCollect] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });
  const [finalDateDeliver, setFinalDateDeliver] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  const { setFieldValue, values, resetForm, submitForm } = useFormikContext<FilterProps>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const apiCallCompany = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await listCompanyByName(searchValue);
    else return [];
  };

  const statusCodeOptions = useMemo(() => {
    return convertStatusCodeOptions(values.activeTabName);
  }, [values.activeTabName]);

  return (
    <>
      <HireBackhaulBuyMatchFormStyled>
        {isAdmin && (
          <ContainerRowStyled style={{ display: 'none' }}>
            <SecondTitleStyled>Empresa</SecondTitleStyled>
            <LineSectionStyled columns="1fr">
              <AsyncSelectField
                isClearable={true}
                name="company"
                id="company"
                cacheOptions={true}
                defaultOptions={true}
                onChange={async (e) => {
                  setFieldValue('company', e && e.value);
                  setFieldValue('name', e && e.label);
                }}
                apiCallFunction={apiCallCompany || (async () => [])}
                loadOptions={apiCallCompany || (async () => [])}
              />
            </LineSectionStyled>
          </ContainerRowStyled>
        )}

        <ContainerRowStyled>
          <SecondTitleStyled>Data Coleta</SecondTitleStyled>
          <LineSectionStyled columns="1fr 1fr">
            <InputCalendar
              placeholder="Data Inicial"
              selectPastDays
              onDateChange={(date: any) => {
                setFieldValue('levyInitialDateStart', date);
                setInitialDateCollect((prvState) => {
                  return { ...prvState, date };
                });
              }}
              onFocusChange={({ focused }) =>
                setInitialDateCollect((prvState) => {
                  return { ...prvState, focused };
                })
              }
              focused={initialDateCollect.focused}
              date={values.levyInitialDateStart}
              id="levyInitialDateStart"
            />
            <InputCalendar
              placeholder="Data Final"
              minDate={values.levyInitialDateStart}
              onDateChange={(date: any) => {
                setFieldValue('levyInitialDateEnd', date);
                setFinalDateCollect((prvState) => {
                  return { ...prvState, date };
                });
              }}
              onFocusChange={({ focused }) =>
                setFinalDateCollect((prvState) => {
                  return { ...prvState, focused };
                })
              }
              focused={finalDateCollect.focused}
              date={values.levyInitialDateEnd}
              id="levyInitialDateEnd"
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Data Entrega</SecondTitleStyled>
          <LineSectionStyled columns="1fr 1fr">
            <InputCalendar
              placeholder="Data Inicial"
              selectPastDays
              onDateChange={(date: any) => {
                setFieldValue('deliveryInitialStart', date);
                setInitialDateDeliver((prvState) => {
                  return { ...prvState, date };
                });
              }}
              onFocusChange={({ focused }) =>
                setInitialDateDeliver((prvState) => {
                  return { ...prvState, focused };
                })
              }
              focused={initialDateDeliver.focused}
              date={values.deliveryInitialStart}
              id="deliveryInitialStart"
            />
            <InputCalendar
              placeholder="Data Final"
              minDate={values.deliveryInitialStart}
              onDateChange={(date: any) => {
                setFieldValue('deliveryInitialEnd', date);
                setFinalDateDeliver((prvState) => {
                  return { ...prvState, date };
                });
              }}
              onFocusChange={({ focused }) =>
                setFinalDateDeliver((prvState) => {
                  return { ...prvState, focused };
                })
              }
              focused={finalDateDeliver.focused}
              date={values.deliveryInitialEnd}
              id="deliveryInitialEnd"
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Cidade de Origem </SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <InputField
              placeholder="Informe a cidade"
              id="originCity"
              name="originCity"
              type="text"
              onChange={handleChange}
              value={values.originCity}
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Cidade de Destino</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <InputField
              placeholder="Informe a cidade"
              id="destinationCity"
              name="destinationCity"
              type="text"
              onChange={handleChange}
              value={values.destinationCity}
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Operado Por</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Informe o responsável pela operação"
              id="operationBy"
              name="operationBy"
              value={values.operationBy}
              setFieldValue={(name, value) => {
                setFieldValue(name, value);
              }}
              options={operationBy}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Tipo de Contratação</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Informe o tipo de contratação"
              id="typeOperation"
              name="typeOperation"
              value={values.typeOperation}
              setFieldValue={(name, value) => {
                setFieldValue(name, value);
              }}
              options={typeOperation}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Status</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Informe o status"
              id="statusCode"
              name="statusCode"
              value={values.statusCode}
              setFieldValue={(name, value) => {
                setFieldValue(name, Array(value));
              }}
              options={statusCodeOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Pedido</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <InputField
              placeholder="Informe o N° do Pedido"
              id="orderNumber"
              name="orderNumber"
              type="text"
              onChange={handleChange}
              value={values.orderNumber}
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Tipo de Veículo</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Selecione o Tipo"
              id="vehicleType"
              name="vehicleType"
              value={values.vehicleType}
              setFieldValue={(name, value) => {
                setFieldValue(name, value);
              }}
              options={vehicleTypeOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Categoria do Veículo</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Selecione a Categoria"
              id="vehicleCategory"
              name="vehicleCategory"
              value={values.vehicleCategory}
              setFieldValue={(name, value) => setFieldValue(name, value)}
              options={vehicleCategoryOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Tipo de Operação</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Tipo de Operação"
              id="scheduleOperationType"
              name="scheduleOperationType"
              value={values.scheduleOperationType}
              setFieldValue={(name, value) => {
                setFieldValue(name, value);
              }}
              options={operationTypeOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 20,
            gap: 15,
          }}
        >
          <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
          <Button title="Limpar" bgColor="white" callback={() => resetForm({ values: defaultFilter })} size="large" />
        </div>
      </HireBackhaulBuyMatchFormStyled>
    </>
  );
};
