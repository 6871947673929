import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  ContainerRowStyled,
  HireBackhaulBuyMatchFormStyled,
  LineSectionStyled,
  SecondTitleStyled,
  ButtonContainer,
} from './styled';
import { IOption, operationSubTypeOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import Button from 'components/button/button';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import { ITransactionsFilter } from 'pages-v2/transactions/types/transactions-filter/transactions-filter.types';
import AsyncSelectField from 'components/async-select/async-select';
import { UserContext } from 'state/user-context';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { useGetCompanySearch } from 'src-new/hooks/use-get-company-search/use-get-company-search.hook';

export const TransactionsFilterForm: React.FC = () => {
  const { user } = useContext(UserContext);
  const { setFieldValue, values, submitForm } = useFormikContext<ITransactionsFilter>();

  const [companyTradeName, setCompanyTradeName] = useState<string>('');

  const getCompanyHook = useGetCompany();
  const getCompanySearchHook = useGetCompanySearch();

  const renderDates = useCallback(
    (label: keyof ITransactionsFilter, isInitialDate?: boolean): ReactElement => {
      return (
        <DateTimePikers
          label=""
          placeholder={`Data ${isInitialDate ? 'Inicial' : 'Final'}`}
          format="DD/MM/YYYY"
          views={['year', 'month', 'day']}
          value={values[label] ? dayjs(values[label], 'DD/MM/YYYY') : null}
          onChange={(date) => setFieldValue(label, date?.format('DD/MM/YYYY'))}
        />
      );
    },
    [setFieldValue, values],
  );

  const invoicedByOptions = useMemo((): Array<IOption> => {
    return [
      { value: 'LOGSHARE', label: 'LOGSHARE' },
      { value: 'EMBARCADOR', label: companyTradeName },
    ];
  }, [companyTradeName]);

  useEffect(() => {
    (async () => {
      const company = await getCompanyHook(user?.companyId ?? 0);

      if (!company) return;

      setCompanyTradeName(company.tradeName);
    })();
  }, [getCompanyHook, user?.companyId]);

  return (
    <>
      <HireBackhaulBuyMatchFormStyled>
        <ContainerRowStyled>
          <SecondTitleStyled>Data de Vencimento</SecondTitleStyled>
          <LineSectionStyled columns="1fr 1fr">
            {renderDates('deliveryInitialDate', true)}
            {renderDates('deliveryEndDate')}
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Parceiro / Cliente</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <AsyncSelectField
              label=""
              placeholder="Informe o tomador do serviço"
              name="taker"
              id="taker"
              isClearable={true}
              cacheOptions={true}
              defaultOptions={true}
              onChange={async (e) => setFieldValue('taker', e?.label)}
              apiCallFunction={async (e: string) => await getCompanySearchHook(e)}
              loadOptions={async (e: string) => await getCompanySearchHook(e)}
              value={
                values.taker
                  ? {
                      label: values.taker,
                      value: values.takerId,
                    }
                  : undefined
              }
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Tipo de Operação</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Informe o tipo de operação"
              id="operationType"
              name="operationType"
              value={values.operationType}
              setFieldValue={(name, value) => setFieldValue(name, value)}
              options={operationSubTypeOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>

        <ContainerRowStyled>
          <SecondTitleStyled>Faturado Por</SecondTitleStyled>
          <LineSectionStyled columns="1fr">
            <Select
              placeholder="Informe o emissor da fatura"
              id="invoicedBy"
              name="invoicedBy"
              value={values.invoicedBy}
              setFieldValue={(name, value) => setFieldValue(name, value)}
              options={invoicedByOptions}
              isClearable
            />
          </LineSectionStyled>
        </ContainerRowStyled>
      </HireBackhaulBuyMatchFormStyled>

      <ButtonContainer>
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
      </ButtonContainer>
    </>
  );
};
