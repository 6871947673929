import { useNavigate } from 'react-router-dom';
import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import AcceptedMatchesTable from './components/accepted-matches-table/accepted-matches-table';
import { ContainerSearchStyled, NewFreight } from './accepted-matches-list-body-view.styled';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { UserContext } from 'state/user-context';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { IAcceptedMatchesCreatedV2 } from 'domain/accepted-matches';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Checkbox from 'components/checkbox/checkbox';
import TooltipComponent from 'components/Tooltip';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { convertFreightType, convertOperationType } from './types/accepted-matches.types';
import * as S from './accepted-matches-list-body-view.styled';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

interface IAcceptedMatchesListBodyViewProps {
  acceptedMatches: Array<IAcceptedMatchesCreatedV2>;
  acceptedMatchesPageInfo?: PageInfo;
  hasLoading: boolean;
  goToCreatePage: () => void;
  goToUpdatePage: (acceptedMatchesId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  handleOpenUploadXlsx: () => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  idsToRemove: number[];
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
  handleRemoveIds: (ids: number[]) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
  handleCopy: (value: IAcceptedMatchesCreatedV2) => void;
  handleCreatePage: (value: IAcceptedMatchesCreatedV2) => void;
}

const dataTestId = 'occurrence-tracking-list-body-view';

const AcceptedMatchesListBodyView: React.FC<IAcceptedMatchesListBodyViewProps> = ({
  acceptedMatches,
  acceptedMatchesPageInfo,
  hasLoading,
  idsToRemove,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
  goToCreatePage,
  goToUpdatePage,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
  handleOpenUploadXlsx,
  setIdsToRemove,
  handleRemoveIds,
  handleCopy,
  handleCreatePage,
}: IAcceptedMatchesListBodyViewProps): JSX.Element => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const navigate = useNavigate();

  const renderCategory = useCallback((key: string, typeSchedule: string, operationType: string) => {
    const value = (
      <TooltipComponent
        title={'FTL'}
        location="left-end"
        icon={
          <Tag
            label={'FTL'}
            color={convertFreightType('ftl').color}
            variant="solid"
            display="flex"
            size="md"
          />
        }
      />
    );

    const secondValue = (
      <TooltipComponent
        title={operationType.toUpperCase()}
        location="left-end"
        icon={
          <Tag
            label={convertOperationType(operationType.toUpperCase()).label}
            color={convertOperationType(operationType.toUpperCase()).color}
            variant="ghost"
            display="flex"
            size="md"
          />
        }
      />
    );

    return (
      <S.Category key={`category-${key}-${typeSchedule ?? operationType}`}>
        {value}
        {secondValue}
      </S.Category>
    );
  }, []);

  const renderProcessType = (processType: string) => {
    return processType === 'frota dedicada' ? 'DEDICADA' : 'NÃO DEDICADA';
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return acceptedMatches.map((acceptedMatches) => {
      return [
        {
          value: (
            <Checkbox
              id="freight.id"
              name="freight.id"
              value={String(acceptedMatches.id)}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, +acceptedMatches.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== +acceptedMatches.id)])
              }
            />
          ),
          flex: 0.1,
        },
        {
          value: acceptedMatches.id,
          flex: 0.2,
        },
        {
          value: renderCategory(
            acceptedMatches.id.toString(),
            acceptedMatches.processType,
            acceptedMatches.operationType,
          ),
          flex: 0.3,
        },
        {
          value: acceptedMatches.internalCode,
          flex: 0.4,
        },
        {
          value: isAdmin ? acceptedMatches.companyId?.tradeName : ' ',
          flex: isAdmin ? 0.5 : 0,
        },
        {
          value: acceptedMatches.clientOrigin?.city
            ? acceptedMatches.clientOrigin?.uf + ' - ' + acceptedMatches.clientOrigin?.city
            : acceptedMatches.localityOrigin?.uf + ' - ' + acceptedMatches.localityOrigin?.city,
          subtitle: acceptedMatches.clientOrigin?.tradeName || acceptedMatches.localityOrigin?.name,
          flex: 1.3,
        },
        {
          value: acceptedMatches.clientDestination?.city
            ? acceptedMatches.clientDestination?.uf + ' - ' + acceptedMatches.clientDestination?.city
            : acceptedMatches.localityDestination?.uf + ' - ' + acceptedMatches.localityDestination?.city,
          subtitle: acceptedMatches.clientDestination?.tradeName || acceptedMatches.localityDestination?.name,
          flex: 1.3,
        },
        {
          value: acceptedMatches.operationType,
          flex: 0.6,
        },
        {
          value: renderProcessType(acceptedMatches.processType),
          flex: 0.6,
        },
        {
          value: acceptedMatches.status,
          flex: 0.35,
        },
        {
          value: (
            <TooltipComponent
              title="Duplicar"
              location="left-end"
              icon={
                <ContentCopyRoundedIcon
                  sx={{ width: 18.5, height: 18.5 }}
                  htmlColor="grey"
                  onClick={() => handleCopy(acceptedMatches)}
                />
              }
            />
          ),
          flex: 0.1,
        },
        {
          value: (
            <TooltipComponent
              title="Programar"
              location="right-end"
              icon={
                <InsertInvitationIcon
                  sx={{ width: 18.5, height: 18.5 }}
                  htmlColor="grey"
                  onClick={() => handleCreatePage(acceptedMatches)}
                />
              }
            />
          ),
          flex: 0.1,
        },
      ];
    });
  }, [acceptedMatches, navigate]);

  const renderAcceptedMatchesTableActive = useMemo(() => {
    return (
      <AcceptedMatchesTable
        contentTable={contentTable}
        meta={acceptedMatchesPageInfo}
        goToUpdatePage={goToUpdatePage}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [contentTable, acceptedMatchesPageInfo, goToUpdatePage, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Ativas',
        content: renderAcceptedMatchesTableActive,
      },
      {
        name: 'Inativos',
        content: renderAcceptedMatchesTableActive,
      },
    ];
  }, [renderAcceptedMatchesTableActive]);

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Cadastro', 'Minhas Rotas']} />
      <Title
        dataTestId={dataTestId}
        title="Cadastre ou importe suas rotas para futuras negociações com outros embarcadores."
        hasTrace
      />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input id="search" name="search" placeholder="Pesquise pela Rota" type="text" changeValue={setSearchInput} />

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => handleOpenUploadXlsx()}
              LeftIcon={<CloudUploadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Importar'}
          location={'bottom'}
        />
        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>
      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreatePage} />
          <FooterPage.Button
            label="Remover"
            color="white"
            variant="ghost"
            onPress={() => handleRemoveIds(idsToRemove)}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default AcceptedMatchesListBodyView;
