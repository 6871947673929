import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TimelineContainerStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;
