import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ScheduleDetailsResumeView } from './schedule-details-resume-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { UseGetScheduleDetailsCollectionOrder } from '../../hooks/use-get-schedule-collect-order/use-get-schedule-collect-order.hook';
import { useNavigate } from 'react-router-dom';
import { useResendCollectionOrderEmail } from '../../hooks/use-resend-collect-order-email/use-resend-collect-order-email.hook';

export const ScheduleDetailsResume: FC = () => {
  const { isAdmin } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const getScheduleDetailsCollectionOrderHook = UseGetScheduleDetailsCollectionOrder();
  const resendCollectionOrderEmailHook = useResendCollectionOrderEmail();

  const checkAllocationAccordions = useMemo(
    (): boolean =>
      !!scheduleDetails.scheduleDetails.shippingId &&
      !!scheduleDetails.scheduleDetails.vehicleId &&
      !!scheduleDetails.scheduleDetails.driverId,
    [
      scheduleDetails.scheduleDetails.driverId,
      scheduleDetails.scheduleDetails.shippingId,
      scheduleDetails.scheduleDetails.vehicleId,
    ],
  );

  const disableReofferButton = useMemo((): boolean => {
    const reofferCollectOrder = ['IN10', 'IN11', 'IN20', 'IN30', 'IN40', 'IN50'];

    return !reofferCollectOrder.includes(scheduleDetails.scheduleDetails.statusCode);
  }, [scheduleDetails.scheduleDetails.statusCode]);

  const handleOpenOrCloseResume = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
  }, []);

  const handleCollectOrderDownload = useCallback(() => {
    getScheduleDetailsCollectionOrderHook();
  }, [getScheduleDetailsCollectionOrderHook]);

  const handleResendCollectOrderEmail = useCallback(() => {
    resendCollectionOrderEmailHook();
  }, [resendCollectionOrderEmailHook]);

  const handleOpenTracking = useCallback(() => {
    window.open(`/rastreio/${scheduleDetails.scheduleDetails.schedulingCode}`);
  }, [scheduleDetails.scheduleDetails.schedulingCode]);

  const handleOpenOccurrence = useCallback(() => {
    navigate(`/tracking-ocorrencias/novo/${scheduleDetails.scheduleDetails.id}`);
  }, [navigate, scheduleDetails.scheduleDetails.id]);

  return (
    <ScheduleDetailsResumeView
      schedule={scheduleDetails.scheduleDetails}
      checkAllocationAccordions={checkAllocationAccordions}
      disableReofferButton={disableReofferButton}
      isOpen={isOpen}
      isAdmin={isAdmin}
      handleCollectOrderDownload={handleCollectOrderDownload}
      handleResendCollectOrderEmail={handleResendCollectOrderEmail}
      handleOpenOrCloseResume={handleOpenOrCloseResume}
      handleOpenOccurrence={handleOpenOccurrence}
      handleOpenTracking={handleOpenTracking}
    />
  );
};
