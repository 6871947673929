import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Button } from 'logshare-ui-kit';
import {
  ButtonContainerStyled,
  LabelContainerStyled,
} from 'src-new/pages/registrations/components/contact-details-section/components/contact-details-table/contact-details-table-view.styled';
import { Pencil, Trash2 } from 'lucide-react';
import { ContactRegistrationModal } from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal.component';

interface IContactDetailsTableViewProps {
  contacts: Array<IContactDomain>;
  handleCloseRegistrationModal: () => void;
  removeItem: (index: number) => void;
  editItem: (index: number) => void;
  isOpenModal: boolean;
  position?: number;
}

export const ContactDetailsTableView: React.FC<IContactDetailsTableViewProps> = ({
  contacts,
  removeItem,
  editItem,
  isOpenModal,
  position,
  handleCloseRegistrationModal,
}) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'Nome' },
      { value: 'Área' },
      { value: 'Telefone' },
      { value: 'Email' },
      { value: 'Agendamento' },
      { value: 'Ordem de Coleta' },
      { value: 'Ocorrências' },
      { value: 'Faturamento' },
      { value: '' },
      { value: '' },
    ];

    const columnsConfig = '1fr 0.8fr 0.6fr 1fr 0.6fr 0.6fr 0.5fr 0.8fr 0.2fr 0.2fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderAlert = useCallback((isActive: boolean) => (isActive ? 'SIM' : 'NÃO'), []);

  const renderButton = useCallback(
    (index: number, type: 'EDIT' | 'REMOVE') => {
      const isEdit = type === 'EDIT';
      const icon: ReactElement = isEdit ? (
        <Pencil size={18} strokeWidth={2.75} />
      ) : (
        <Trash2 size={18} strokeWidth={2.75} />
      );

      const onPressFunction = isEdit ? () => editItem(index) : () => removeItem(index);

      return (
        <ButtonContainerStyled>
          <Button label="" color="white" onPress={onPressFunction} LeftIcon={icon} size="x-sm" variant="ghost" />
        </ButtonContainerStyled>
      );
    },
    [editItem, removeItem],
  );

  const renderRows = useMemo((): Array<IRow> => {
    return contacts.map((contact, index) => {
      return {
        key: `${index}-${contact.name.replace(' ', '-').toLowerCase()}`,
        onClick: () => undefined,
        valuesColumns: [
          { value: contact.name },
          { value: contact.area },
          { value: <LabelContainerStyled>{contact.telephone}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{contact.email}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderAlert(contact.alerts[0].isActive)}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderAlert(contact.alerts[1].isActive)}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderAlert(contact.alerts[2].isActive)}</LabelContainerStyled> },
          { value: <LabelContainerStyled>{renderAlert(contact.alerts[3].isActive)}</LabelContainerStyled> },
          { value: renderButton(index, 'REMOVE') },
          { value: renderButton(index, 'EDIT') },
        ],
      };
    });
  }, [contacts, renderAlert, renderButton]);

  const renderModal = useMemo((): ReactElement | undefined => {
    if (isOpenModal && position !== undefined) {
      return (
        <ContactRegistrationModal
          modalType={{ type: 'EDIT', position: position }}
          setModalClose={handleCloseRegistrationModal}
        />
      );
    }
  }, [handleCloseRegistrationModal, isOpenModal, position]);

  if (!contacts.length) {
    return <EmptyState subTitle="não há contato cadastrado" height={'10px'} hideIcon />;
  }

  return (
    <Fragment>
      <Table
        alignLinesCenter
        columnFontBold
        config={{ body: { isDisabledScroll: true } }}
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={renderRows}
        height={'auto'}
      />
      {renderModal}
    </Fragment>
  );
};
