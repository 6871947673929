import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    position: relative;
    background-color: transparent;
    font-size: 5vh;
    
    .virtual-keyboard.hg-theme-default .hg-row .hg-button-container,
    .hg-theme-default .hg-row .hg-button {
      width: 20vw;
      height: 10vh;
    }
  `}
`;
