import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './home-button-view.styled';
import { ChevronsRight } from 'lucide-react';
import { ReactElement } from 'react';

interface IHomeButtonViewProps {
  label: string;
  color: ThemeColorsV2Type;
  icon: ReactElement;
  fontColor?: ThemeColorsV2Type;
  onPress: () => void;
}
export const HomeButtonView: React.FC<IHomeButtonViewProps> = ({ label, color, icon, fontColor, onPress }) => {
  return (
    <S.ButtonWrapper color={color} fontColor={fontColor} onClick={onPress}>
      <S.ButtonContainer>
        <S.ButtonContent>
          {icon}
          {label}
        </S.ButtonContent>

        <ChevronsRight size={20} />
      </S.ButtonContainer>
    </S.ButtonWrapper>
  );
};
