import API from 'utils/API/API';
import { ChecklistMobileQuestions } from 'domain-v2/checklist/index';

export const getMobileQuestionsService = async (moduleId: number, id: number): Promise<ChecklistMobileQuestions> => {
  const { data } = await API.get(`v2/checklist/questions/${moduleId}`, {
    params: {
      id,
    },
  });

  return data;
};
