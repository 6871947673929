import styled from 'styled-components';

export const ContainerTableStyled = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 14px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: 8888889c;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #12264e;
    width: 50px;
  }
`;

export const ContainerTitleStyled = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0;
`;

export const ContainerTermStyled = styled.div`
  margin: 30px 0;
  text-align: justify;
`;
