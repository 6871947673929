import pdfIcon from 'assets/img/pdf-image.png';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import ModalImage from 'components/modal-image/modal-image';
import { showToast } from 'components/toast/toast';
import React, { ReactNode, useEffect, useState } from 'react';
import { FileContent, useFilePicker } from 'use-file-picker';
import InputUploadImageView from './input-upload-image-view';
import { ImageViewStyled } from './input-upload-image.styled';

type InputUploadImageProps = {
  urlImage?: string;
  hasError: boolean;
  handleSelectedImage: (dataUrl: FileContent, file: File) => void;
  deleteImage?: () => void;
  centered?: boolean;
  profilePhoto?: boolean;
  isProfileImage?: boolean;
  fileType?: string;
};

const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg', 'application/pdf'];

const InputUploadImage: React.FC<InputUploadImageProps> = ({
  urlImage,
  hasError,
  handleSelectedImage,
  deleteImage,
  centered = true,
  profilePhoto,
  isProfileImage,
  fileType = 'image',
}) => {
  const [dataUrlImage, setDataUrlImage] = useState('');
  const [miniImage, setMiniImage] = useState('');
  const [deleteFile, setDeleteFile] = useState(false);
  const [openFileSelector, { loading, plainFiles, filesContent }] = useFilePicker({
    accept: 'image/*, application/pdf',
    multiple: false,
    readAs: 'DataURL',
  });

  const [modalImage, setModalImage] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const [modalDeleteImage, setModalDeleteImage] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const fileName = typeof urlImage === 'string' ? urlImage?.split('24%23')[1]?.split('.')[0] : '';

  useEffect(() => {
    setDataUrlImage(urlImage ?? '');
    setMiniImage(urlImage ?? '');

    if (fileType === 'file') {
      setMiniImage(pdfIcon);
    }

    if (loading || filesContent.length > 0) {
      const image = filesContent[0]?.content;
      if (plainFiles[0]?.type && !allowedImageFormats.includes(plainFiles[0].type)) {
        showToast({
          type: 'error',
          message: 'Formatos permitidos: jpeg, png, jpg e pdf',
        });
      } else {
        setDataUrlImage(image);
        setMiniImage(image);
        handleSelectedImage(filesContent[0], plainFiles[0]);
      }
    }
  }, [filesContent, plainFiles]);

  useEffect(() => {
    if (deleteFile) {
      setDataUrlImage('');
      setMiniImage('');
      deleteImage && deleteImage();
      setDeleteFile(false);
    }
  }, [deleteFile, deleteImage]);

  const goToImageView = () => {
    const description = <ImageViewStyled src={dataUrlImage} />;
    setModalImage({ open: true, description: description });
  };

  const goToImageDelete = () => {
    const description = (
      <>
        <p>Isso excluirá o registro do agendamento.</p>
        <p style={{ marginTop: 5 }}>Tem certeza?</p>
      </>
    );
    setModalDeleteImage({ open: true, description: description });
  };

  const goToOpenUrl = () => {
    window.open(dataUrlImage, '_blank');
  };

  return (
    <>
      {deleteImage && (
        <>
          {modalDeleteImage.open && (
            <ModalConfirmation
              title="Excluir Registro(s)"
              secondTitle={modalDeleteImage.description}
              handleCancel={() => setModalDeleteImage((old) => ({ ...old, open: false }))}
              onConfirmation={() => {
                setDeleteFile(true), setModalDeleteImage((old) => ({ ...old, open: false }));
              }}
              size="small"
              type="delete"
              description={undefined}
            />
          )}
          {modalImage.open && (
            <ModalImage
              title="Visualizar Registro"
              description={modalImage.description}
              handleCancel={() => setModalImage((old) => ({ ...old, open: false }))}
            />
          )}
        </>
      )}
      <InputUploadImageView
        hasError={hasError}
        goToImageView={goToImageView}
        handleClick={openFileSelector}
        dataUrlImage={miniImage}
        fileName={fileName}
        handleDelete={deleteImage && goToImageDelete}
        centered={centered}
        profilePhoto={profilePhoto}
        isProfileImage={isProfileImage}
        fileType={fileType}
        goToOpenUrl={goToOpenUrl}
      />
    </>
  );
};

export default InputUploadImage;
