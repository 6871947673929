import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { DownloadFile } from 'utils/download-file/download-file';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getDuplicatedWeighingCollectionOrderService } from '../../services/get-duplicated-weighing-collect-order/get-duplicated-weighing-collect-order.service';

export const UseGetWeighingDetailsCollectionOrder = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams<{ id: string }>();
  const weighingId = useMemo(() => id ?? '', [id]);

  const fileNamePdf = useCallback(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_cotacao_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user?.companyLogin]);

  return useCallback(async () => {
    try {
      showMessageFormatted({
        message: 'Gerando Relatório...',
        type: 'info',
      });

      const pdf = await getDuplicatedWeighingCollectionOrderService(weighingId);

      if (!pdf) return;

      const fileName = fileNamePdf();
      DownloadFile(pdf, 'pdf', fileName);

      showMessageFormatted({
        message: 'Relatório gerado com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [fileNamePdf, weighingId]);
};
