import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import InputTextArea from 'components/input-textarea/input-textarea';
import { Button } from 'logshare-ui-kit';
import { ChangeEvent, FC } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import * as S from './schedule-details-comments-modal-form-view.styled';

interface IScheduleDetailsCommentsModalFormViewProps {
  values: IUpdateScheduleCargoPayload;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  handleCloseModalComments: () => void;
}

export const ScheduleDetailsCommentsModalFormView: FC<IScheduleDetailsCommentsModalFormViewProps> = ({
  values,
  handleChange,
  handleOnSubmit,
  handleCloseModalComments,
}) => {
  return (
    <ModalBark
      size={'xsm'}
      title={<S.ModalTitle>Pedidos e Observações Gerais</S.ModalTitle>}
      handleClose={handleCloseModalComments}
    >
      <S.ModalWrapper>
        <LineSectionLayout columns="1fr">
          <S.Div>
            <InputField
              label="Pedidos"
              id="cargo.order"
              name="cargo.order"
              type="text"
              onChange={handleChange}
              value={values.cargo.order}
            />

            <InputTextArea
              label="Observações Gerais"
              id="cargo.comments"
              name="cargo.comments"
              onChange={handleChange}
              value={values.cargo.comments}
            />
          </S.Div>
        </LineSectionLayout>

        <S.WrapperButtonSubmit>
          <Button label="Atualizar" color="blue" onPress={handleOnSubmit} size="medium" variant="solid" />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
