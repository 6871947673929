import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { ICompaniesContext } from './companies.types';
import { companiesReducer } from './companies.reducer';
import { ICompaniesAction, initialState } from './companies.actions';

import { IListPage } from 'domain/page-info';
import { ICompany } from 'domain/company';

interface ICompaniesContextProps {
  children: ReactNode;
}

export const CompaniesContext = createContext<ICompaniesContext>(initialState);

const CompaniesContextProvider = ({ children }: ICompaniesContextProps) => {
  const [state, dispatch] = useReducer(companiesReducer, initialState);

  const setCompaniesRequest = useCallback(() => {
    dispatch({ type: ICompaniesAction.COMPANIES_REQUEST, payload: null });
  }, []);

  const setCompaniesSuccess = useCallback((listCompanies: IListPage<ICompany>) => {
    dispatch({
      type: ICompaniesAction.COMPANIES_SUCCESS,
      payload: listCompanies,
    });
  }, []);

  const setCompaniesErro = useCallback((erro: string) => {
    dispatch({ type: ICompaniesAction.COMPANIES_ERROR, payload: erro });
  }, []);

  const value = useMemo(() => {
    return {
      companies: state.companies,
      companiesIsLoading: state.companiesIsLoading,
      companiesError: state.companiesError,
      companiesPageInfo: state.companiesPageInfo,
      setCompaniesRequest,
      setCompaniesSuccess,
      setCompaniesErro,
    };
  }, [
    setCompaniesErro,
    setCompaniesRequest,
    setCompaniesSuccess,
    state.companies,
    state.companiesError,
    state.companiesIsLoading,
    state.companiesPageInfo,
  ]);

  return <CompaniesContext.Provider value={value}>{children}</CompaniesContext.Provider>;
};

export default React.memo(CompaniesContextProvider);
