import { FC, Fragment, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsAllocationDriverView } from './schedule-details-allocation-driver-view.component';
import { useUpdateScheduleHistoryDriver } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-history-driver/use-update-schedule-history-driver.hook';
import { UseDeleteScheduleHistoryDriver } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-schedule-history-driver/use-delete-schedule-history-driver.hook';
import { UserContext } from 'state/user-context';
import { capitalize } from 'src-new/utils/capitalize.util';

export const ScheduleDetailsAllocationDriver: FC = () => {
  const { user } = useContext(UserContext);
  const loggedInUser = capitalize(user?.name || '');
  const loggedInCompany = capitalize(user?.companyLogin || '');

  const { scheduleDetails } = useContext(ScheduleContext);
  const updateScheduleHistoryDriverHook = useUpdateScheduleHistoryDriver();
  const deleteScheduleHistoryDriverHook = UseDeleteScheduleHistoryDriver();

  const handleUpdateDriverHistory = useCallback(
    async (driverId: number) => {
      updateScheduleHistoryDriverHook({
        driverId,
        vehicleId: scheduleDetails.scheduleDetails.vehicleId,
        typeUpdate: 'MOTORISTA',
      });
    },
    [scheduleDetails.scheduleDetails.vehicleId, updateScheduleHistoryDriverHook],
  );

  const handleDeleteDriverHistory = useCallback(
    async (historyId: number) => {
      deleteScheduleHistoryDriverHook(historyId);
    },
    [deleteScheduleHistoryDriverHook],
  );

  const goToWhatsApp = useCallback(
    () =>
      window.open(
        `https://web.whatsapp.com/send/?phone=${scheduleDetails.scheduleDetails.driver?.telephone}&text=Ol%C3%A1%2C+sou+*${loggedInUser}*+da(o)+*${loggedInCompany}*%21&type=phone_number&app_absent=0`,
      ),

    [loggedInCompany, loggedInUser, scheduleDetails.scheduleDetails.driver?.telephone],
  );

  if (!scheduleDetails.scheduleDetails.driver?.id) {
    return <Fragment />;
  }

  return (
    <ScheduleDetailsAllocationDriverView
      driver={scheduleDetails.scheduleDetails.driver}
      historyDrivers={scheduleDetails.allocationDriver.accordionProps.historyList.historyDriverList}
      handleUpdateDriverHistory={handleUpdateDriverHistory}
      handleDeleteDriverHistory={handleDeleteDriverHistory}
      goToWhatsApp={goToWhatsApp}
    />
  );
};
