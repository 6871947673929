import { CompanyReportExcelService } from 'pages/company/services/location-report-excel/location-report-excel.service';
import { ICompanyStatus } from 'domain/company';

export interface ILocationReportExcelProps {
  search: string;
  status: ICompanyStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: ILocationReportExcelProps): Promise<BlobPart | undefined> => {
    return await CompanyReportExcelService(params);
  };
};

export { useReportExcelHook };
