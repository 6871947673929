import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { ScheduleDetailsActionType } from '../../schedule.action';
import { scheduleDetailsReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-details/schedule-details.reducer';
import {
  IScheduleDetails,
  IScheduleDetailsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import {
  IScheduleCustomFields,
  IScheduleDetailsDomain,
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { IScheduleDetailsAllocationShippingDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-shipping.domain';
import { IScheduleDetailsAllocationVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-vehicle.domain';
import { IScheduleDetailsAllocationDriverDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-driver.domain';
import { IScheduleHistoryDriverAndVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-history-driver-and-vehicle.domain';

export const useScheduleDetailsContextValues = (): IScheduleDetails => {
  const [state, dispatch] = useReducer(scheduleDetailsReducer, scheduleInitialState);

  const setScheduleDetailsRequest = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_DETAILS_REQUEST, payload: undefined });
  }, []);

  const setScheduleDetailsSuccess = useCallback((details: IScheduleDetailsDomain) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_DETAILS_SUCCESS, payload: details });
  }, []);

  const setScheduleDetailsError = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_DETAILS_ERROR, payload: undefined });
  }, []);

  const setScheduleDetailsReset = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_DETAILS_RESET, payload: undefined });
  }, []);

  const setScheduleCustomFieldsDataSuccess = useCallback((detailsCustomFields: Array<IScheduleCustomFields>) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_CUSTOM_FIELDS_SUCCESS, payload: detailsCustomFields });
  }, []);

  const setScheduleDetailsAllocationShippingRequest = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_REQUEST, payload: undefined });
  }, []);

  const setScheduleDetailsAllocationShippingSuccess = useCallback(
    (shippings: Array<IScheduleDetailsAllocationShippingDomain>) => {
      dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_SUCCESS, payload: shippings });
    },
    [],
  );

  const setScheduleDetailsAllocationShippingError = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SHIPPING_ERROR, payload: undefined });
  }, []);

  const setScheduleDetailsAllocationSelectedShipping = useCallback((shippingId: number) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_SELECTED_SHIPPING_ID, payload: { shippingId } });
  }, []);

  const setScheduleDetailsAllocationVehicleRequest = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_REQUEST, payload: undefined });
  }, []);

  const setScheduleDetailsAllocationVehicleSuccess = useCallback(
    (vehicles: Array<IScheduleDetailsAllocationVehicleDomain>) => {
      dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_SUCCESS, payload: vehicles });
    },
    [],
  );

  const setScheduleDetailsAllocationVehicleError = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_VEHICLE_ERROR, payload: undefined });
  }, []);

  const setScheduleDetailsScheduleHistoryDriverAndVehicleRequest = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_REQUEST, payload: undefined });
  }, []);

  const setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess = useCallback(
    (
      historyDriverList: Array<IScheduleHistoryDriverAndVehicleDomain>,
      historyVehicleList: Array<IScheduleHistoryDriverAndVehicleDomain>,
    ) => {
      dispatch({
        type: ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_SUCCESS,
        payload: { historyDriverList, historyVehicleList },
      });
    },
    [],
  );

  const setScheduleDetailsScheduleHistoryDriverAndVehicleError = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_HISTORY_DRIVER_AND_VEHICLE_ERROR, payload: undefined });
  }, []);

  const setScheduleDetailsAllocationDriverRequest = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_REQUEST, payload: undefined });
  }, []);

  const setScheduleDetailsAllocationDriverSuccess = useCallback(
    (drivers: Array<IScheduleDetailsAllocationDriverDomain>) => {
      dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_SUCCESS, payload: drivers });
    },
    [],
  );

  const setScheduleDetailsAllocationDriverError = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_DRIVER_ERROR, payload: undefined });
  }, []);

  const setScheduleDetailsSelectedDriver = useCallback((checked: boolean, driverId: number) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_SELECTED_DRIVER, payload: { checked, driverId } });
  }, []);

  const setScheduleDetailsSelectedVehicle = useCallback((checked: boolean, vehicleId: number) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_SELECTED_VEHICLE, payload: { checked, vehicleId } });
  }, []);

  const setScheduleDetailsAllocationOpen = useCallback((allocatedEntity: IScheduleDetailsModalType) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_OPEN, payload: allocatedEntity });
  }, []);

  const setScheduleDetailsAllocationClose = useCallback(() => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ALLOCATION_CLOSE, payload: undefined });
  }, []);

  const setScheduleDetailsActiveTabName = useCallback((activeTabName: string) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_ACTIVE_TAB_NAME, payload: activeTabName });
  }, []);

  const setDriverIdVehicleModal = useCallback((driverId?: number) => {
    dispatch({ type: ScheduleDetailsActionType.SCHEDULE_SET_DRIVER_ID_VEHICLE, payload: driverId });
  }, []);

  return {
    ...state.scheduleDetails,
    allocationActions: {
      ...state.scheduleDetails.allocationActions,
      setDriverIdVehicleModal,
    },
    scheduleCustomFields: {
      ...state.scheduleDetails.scheduleCustomFields,
      setScheduleCustomFieldsDataSuccess,
    },
    allocationShipping: {
      ...state.scheduleDetails.allocationShipping,
      setScheduleDetailsAllocationShippingRequest,
      setScheduleDetailsAllocationShippingSuccess,
      setScheduleDetailsAllocationShippingError,
      setScheduleDetailsAllocationSelectedShipping,
    },
    allocationVehicle: {
      modalProps: {
        ...state.scheduleDetails.allocationVehicle.modalProps,
        setScheduleDetailsAllocationVehicleRequest,
        setScheduleDetailsAllocationVehicleSuccess,
        setScheduleDetailsAllocationVehicleError,
        setScheduleDetailsSelectedVehicle,
      },
      accordionProps: {
        ...state.scheduleDetails.allocationVehicle.accordionProps,
        setScheduleDetailsScheduleHistoryDriverAndVehicleRequest,
        setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess,
        setScheduleDetailsScheduleHistoryDriverAndVehicleError,
      },
    },
    allocationDriver: {
      modalProps: {
        ...state.scheduleDetails.allocationDriver.modalProps,
        setScheduleDetailsAllocationDriverRequest,
        setScheduleDetailsAllocationDriverSuccess,
        setScheduleDetailsAllocationDriverError,
        setScheduleDetailsSelectedDriver,
      },
      accordionProps: {
        ...state.scheduleDetails.allocationDriver.accordionProps,
        setScheduleDetailsScheduleHistoryDriverAndVehicleRequest,
        setScheduleDetailsScheduleHistoryDriverAndVehicleSuccess,
        setScheduleDetailsScheduleHistoryDriverAndVehicleError,
      },
    },
    activeTabName: {
      ...state.scheduleDetails.activeTabName,
      setScheduleDetailsActiveTabName,
    },
    setScheduleDetailsRequest,
    setScheduleDetailsSuccess,
    setScheduleDetailsError,
    setScheduleDetailsReset,
    setScheduleDetailsAllocationOpen,
    setScheduleDetailsAllocationClose,
  };
};
