import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ClientListTabsView, IClientsListTabsStatus } from './client-list-tabs-view.component';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { isEqual } from 'lodash';
import { ClientsContext } from 'src-new/pages/registrations/pages/client/contexts/clients/clients.context';
import { useGetClientList } from '../../hooks/use-get-client-list/use-get-client-list.hook';
import { IClientsListFilter } from 'src-new/pages/registrations/pages/client/contexts/clients/types/clients-list.types';
import { clientsInitialState } from '../../../../contexts/clients/clients.constants';

interface IClientListTabsProps {
  activeTab: string;
  setActiveTab: (name: string) => void;
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const ClientListTabs: React.FC<IClientListTabsProps> = ({
  activeTab,
  setActiveTab,
  handleIdsToRemove,
  handleInfinityScroll,
}) => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { clientsList } = useContext(ClientsContext);
  const [currentFilter, setCurrentFilter] = useState<IClientsListFilter>(
    clientsInitialState.clientsList.filterActions.filters,
  );
  const getClientList = useGetClientList();
  const { filterActions, statusRequest, clients } = clientsList;
  const { filters, setFilters } = filterActions;
  const { loading, setInitialLoadingReset } = initialLoading;

  const handleResetRequest = useCallback(() => {
    setInitialLoadingReset();
    handleInfinityScroll(1);
  }, [handleInfinityScroll, setInitialLoadingReset]);

  const handleChangeTab = useCallback(
    (name: string) => {
      handleResetRequest();
      setActiveTab(name);
      setFilters({
        ...clientsInitialState.clientsList.filterActions.filters,
        searchValue: filters.searchValue,
        status: name === 'Ativas' ? 'active' : 'inactive',
      });
    },
    [filters.searchValue, handleResetRequest, setActiveTab, setFilters],
  );

  const statusProps = useMemo(
    (): IClientsListTabsStatus => ({
      isError: statusRequest === 'ERROR',
      isInitialLoading: loading,
      isEmpty: !clients.length,
    }),
    [loading, clients.length, statusRequest],
  );

  useEffect(() => {
    if (!isEqual(filters, currentFilter) && statusRequest !== 'ERROR') {
      setCurrentFilter(filters);
      getClientList();
    }
  }, [currentFilter, filters, getClientList, handleResetRequest, statusRequest]);

  return (
    <ClientListTabsView
      handleIdsToRemove={handleIdsToRemove}
      handleInfinityScroll={handleInfinityScroll}
      activeTab={activeTab}
      handleChangeTab={handleChangeTab}
      statusProps={statusProps}
    />
  );
};
