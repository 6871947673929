import React, { FC, useContext, useMemo } from 'react';
import { IOrdering } from 'src-new/components/table/table.types';
import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';
import { UserContext } from 'state/user-context';
import { ScheduleOfferModalMatchTableView } from 'pages-v2/schedule/components/modals/modal-match/components/purchase-offer-modal-match-table-tabs/components/purchase-offer-modal-match-table/modal-match-table-view.component';

interface IScheduleOfferModalMatchTableProps {
  saleOffersMatches: Array<any>;
  handleMatchBuy: (backhaul: any) => void;
}

export const ScheduleOfferModalMatchTable: FC<IScheduleOfferModalMatchTableProps> = ({
  saleOffersMatches,
  handleMatchBuy,
}) => {
  const { isAdmin, isZMaster } = useContext(UserContext);

  const getSaleOfferMatch = useMemo((): Array<IPurchaseOfferMatchListDomain> => {
    return saleOffersMatches;
  }, [saleOffersMatches]);

  const viewImage = useMemo(() => {
    if (isAdmin) return true;
    return isZMaster;
  }, [isAdmin, isZMaster]);

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: 'ID',
      order: 'DESC',
      handleClick: () => undefined,
    };
  }, []);

  return (
    <ScheduleOfferModalMatchTableView
      purchaseOfferMatch={getSaleOfferMatch}
      orderingColumns={orderingColumns}
      viewImage={viewImage}
      handleMatchBuy={handleMatchBuy}
    />
  );
};
