import { FC, useCallback, useMemo, useState } from 'react';
import { FormikErrors } from 'formik';

import { ModalPlanningType } from '../../types/modal';
import { planningRouteInitialValues, planningShippingInitialValues } from '../../initial-values';

import * as S from './styled';
import { SectionPlanning } from './components/section-planning';
import { PlanningVehicleModal } from './components/modals/planning-vehicle';
import { PlanningShippingModal } from './components/modals/planning-shipping';
import { PlanningScheduleModal } from './components/modals/planning-schedules';
import { PlanningRouteModal } from './components/modals/planning';

import { PlanningRouteInput, PlanningRouteShipping } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import SectionDivider from 'components/section-divider/section-divider';
import Button from 'components/button/button';

interface PlanningRouteFormProps {
  userName: string;
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const PlanningRoute: FC<PlanningRouteFormProps> = ({ userName, values, setFieldValue, handleSubmit }) => {
  const [planningRouteModal, setPlanningRouteModal] = useState<
    ModalState<{ type: ModalPlanningType; payload: PlanningRouteInput }>
  >({
    ...initialModalState,
    data: {
      type: 'register',
      payload: planningRouteInitialValues,
    },
  });

  const [planningVehicleModal, setPlanningVehicleModalModal] = useState<ModalState<{ planningRouteIndex: number }>>({
    ...initialModalState,
    data: {
      planningRouteIndex: 0,
    },
  });

  const [planningShippingModal, setPlanningShippingModalModal] = useState<
    ModalState<{
      planningRouteIndex: number;
      planningVehicleIndex: number;
      planningShippingIndex: number;
      type: ModalPlanningType;
      payload: PlanningRouteShipping;
    }>
  >({
    ...initialModalState,
    data: {
      planningRouteIndex: 0,
      planningVehicleIndex: 0,
      planningShippingIndex: 0,
      type: 'register',
      payload: planningShippingInitialValues,
    },
  });

  const [planningSchedulesModal, setPlanningSchedulesModal] = useState<
    ModalState<{ planningRouteIndex: number; allowedVehicleIndex: number; allowedShippingIndex: number }>
  >({ ...initialModalState, data: { planningRouteIndex: 0, allowedVehicleIndex: 0, allowedShippingIndex: 0 } });

  const handlePlanningRouteModal = useCallback(
    (open: boolean, type: ModalPlanningType, index?: number, data?: PlanningRouteInput) => {
      setPlanningRouteModal((prevState) => ({
        ...prevState,
        open,
        index,
        data: {
          type: type,
          payload: data ? data : planningRouteInitialValues,
        },
      }));
    },
    [],
  );

  const handlePlanningVehicleModal = useCallback((open: boolean, planningRouteIndex: number) => {
    setPlanningVehicleModalModal((prevState) => ({ ...prevState, open, data: { planningRouteIndex } }));
  }, []);

  const handlePlanningShippingModal = useCallback(
    (
      open: boolean,
      planningRouteIndex: number,
      planningVehicleIndex: number,
      planningShippingIndex: number,
      type: ModalPlanningType,
      data?: PlanningRouteShipping,
    ) => {
      setPlanningShippingModalModal((prevState) => ({
        ...prevState,
        open,
        data: {
          planningRouteIndex,
          planningVehicleIndex,
          planningShippingIndex,
          type,
          payload: data ? data : planningShippingInitialValues,
        },
      }));
    },
    [],
  );

  const handlePlanningScheduleModal = useCallback(
    (open: boolean, planningRouteIndex: number, allowedVehicleIndex: number, allowedShippingIndex: number) => {
      setPlanningSchedulesModal((prevState) => ({
        ...prevState,
        open,
        data: {
          planningRouteIndex,
          allowedVehicleIndex,
          allowedShippingIndex,
        },
      }));
    },
    [],
  );

  const handleDeletePlanningRoute = useCallback(
    (index: number) => {
      const updatedPlanningRoute = values.planningRoute.filter((_, i) => i !== index);
      setFieldValue('planningRoute', updatedPlanningRoute);
    },
    [values, setFieldValue],
  );

  const renderPlanningRoute = useMemo(() => {
    if (values.planningRoute.length === 0) {
      return <S.WrapperEmptySection>Nenhum registro localizado.</S.WrapperEmptySection>;
    }

    const mapping = values.planningRoute.map((value, indexPlanningRoute) => {
      return (
        <SectionPlanning
          routeId={values.id}
          key={indexPlanningRoute}
          indexPlanningRoute={indexPlanningRoute}
          planningRouter={value}
          deletePlanningRoute={handleDeletePlanningRoute}
          handleOpenPlanningVehicleModal={() => handlePlanningVehicleModal(true, indexPlanningRoute)}
          handleOpenPlanningShippingModal={handlePlanningShippingModal}
          handlePlanningRouteModal={handlePlanningRouteModal}
          handlePlanningScheduleModal={handlePlanningScheduleModal}
          setFieldValue={setFieldValue}
          values={values}
        />
      );
    });

    return mapping;
  }, [values.planningRoute]);

  return (
    <>
      {planningRouteModal.open && (
        <PlanningRouteModal
          userName={userName}
          values={values}
          planningRouteModal={planningRouteModal}
          setFieldValue={setFieldValue}
          handleClosePlanningRouteModal={() => handlePlanningRouteModal(false, '')}
        />
      )}
      {planningVehicleModal.open && (
        <PlanningVehicleModal
          values={values}
          setFieldValue={setFieldValue}
          handleClosePlanningVehicleModal={() =>
            handlePlanningVehicleModal(false, planningVehicleModal.data.planningRouteIndex)
          }
          planningVehicleModal={planningVehicleModal}
          handleSubmit={handleSubmit}
        />
      )}
      {planningShippingModal.open && (
        <PlanningShippingModal
          values={values}
          setFieldValue={setFieldValue}
          handleClosePlanningShippingModal={() =>
            handlePlanningShippingModal(
              false,
              planningShippingModal.data.planningRouteIndex,
              planningShippingModal.data.planningVehicleIndex,
              planningShippingModal.data.planningShippingIndex,
              '',
            )
          }
          planningShippingModal={planningShippingModal}
          handleSubmit={handleSubmit}
        />
      )}

      {planningSchedulesModal.open && (
        <PlanningScheduleModal
          values={values}
          setFieldValue={setFieldValue}
          handleClosePlanningScheduleModal={() =>
            handlePlanningScheduleModal(
              false,
              planningSchedulesModal.data.planningRouteIndex,
              planningSchedulesModal.data.allowedVehicleIndex,
              planningSchedulesModal.data.allowedShippingIndex,
            )
          }
          planningSchedulesModal={planningSchedulesModal}
        />
      )}

      <S.WrapperSectionStyled>
        <SectionDivider
          label="Planejamento de Rota"
          secondItem={
            <Button
              title="Adicionar"
              bgColor="green"
              text=" + Planejamento"
              size="very-small"
              callback={() => handlePlanningRouteModal(true, 'register')}
            />
          }
        />

        {renderPlanningRoute}
      </S.WrapperSectionStyled>
    </>
  );
};
