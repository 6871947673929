import React from 'react';

import * as S from './styled';

export const AccessRestricted: React.FC = () => {
  return (
    <S.ContainerAccessStyled>
      <S.AccessRestrictedStyled>Acesso Restrito</S.AccessRestrictedStyled>
    </S.ContainerAccessStyled>
  );
};
