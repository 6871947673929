import React, { ReactElement } from 'react';
import { ConfirmationModalView } from './confirmation-modal-view.component';
import {
  IModalButtonProps,
  IModalDescriptionProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';

interface IConfirmationModalProps {
  title: string;
  description: IModalDescriptionProps;
  icon: ReactElement;
  primaryButtonProps: IModalPrimaryButtonProps;
  secondaryButtonProps: IModalButtonProps;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  title,
  description,
  icon,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  return (
    <ConfirmationModalView
      title={title}
      description={description}
      icon={icon}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    />
  );
};
