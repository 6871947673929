import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';

import { ResetPasswordSchema } from '../schemas-validation/schemas-validation';
import { useForgotPassword } from '../../../hooks/forgot-password/forgot-password.hook';

import FormResetPasswordView from './form-reset-password-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { IResetPassword } from 'domain/reset-password';

const initialValues: IResetPassword = {
  login: '',
  email: '',
};

const PATH_TO_HOME = '/login';
const TELA_ANTERIOR = -1;

const FormResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const forgotPassword = useForgotPassword();
  const goBackToHome = useCallback(() => navigate(PATH_TO_HOME), [navigate]);

  const goBackToLogin = useCallback(() => navigate(TELA_ANTERIOR), [TELA_ANTERIOR, navigate]);

  const handleSubmit = useCallback(
    async (form: IResetPassword) => {
      forgotPassword(form.login, form.email)
        .then(() => {
          showMessageFormatted({
            message: 'Uma nova senha foi enviada para o seu e-mail.',
            type: 'success',
          });
          goBackToHome();
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        );
    },
    [forgotPassword, goBackToHome],
  );

  return (
    <FormResetPasswordView
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      handleSubmit={handleSubmit}
      goBackToLogin={goBackToLogin}
    />
  );
};

export default FormResetPassword;
