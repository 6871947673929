import React from 'react';

import {
  ContainerTableStyled,
  ContainerTermStyled,
  ContainerTitleStyled,
} from './our-privacy-policies-modal-table-view-styled';

const dataTestId = 'allocation-modal';

const OurPrivacyPoliciesModalTableView: React.FC = () => {
  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <ContainerTitleStyled>1. Termos.</ContainerTitleStyled>
      <ContainerTermStyled>
        Ao acessar ao site Logshare, concorda em cumprir estes termos de serviço, todas as leis e regulamentos
        aplicáveis e concorda ser responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar
        com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são
        protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
      </ContainerTermStyled>

      <ContainerTitleStyled> 2. Uso de Licença.</ContainerTitleStyled>
      <ContainerTermStyled>
        É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site da
        Logshare, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não
        uma transferência de título e, sob esta licença, você não pode: modificar ou copiar os materiais; usar os
        materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); tentar
        descompilar ou fazer engenharia reversa de qualquer software contido no site Logshare; remover quaisquer
        direitos autorais ou outras notações de propriedade dos materiais; ou transferir os materiais para outra pessoa
        ou {'espelhe'} os materiais em qualquer outro servidor. Esta licença será automaticamente rescindida se você
        violar alguma dessas restrições e poderá ser rescindida pela Logshare a qualquer momento. Ao encerrar a
        visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em
        sua posse, seja em formato eletrônico ou impresso.
      </ContainerTermStyled>

      <ContainerTitleStyled>3. Isenção de responsabilidade.</ContainerTitleStyled>
      <ContainerTermStyled>
        Os materiais no site da Logshare são fornecidos {'como estão'}. A Logshare não oferece garantias, expressas ou
        implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias
        implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade
        intelectual, ou outra violação de direitos. Além disso, a Logshare não garante ou efetua qualquer representação
        relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais em seu site, ou de outra
        forma relacionado a esses materiais, ou em sites vinculados a este site.
      </ContainerTermStyled>

      <ContainerTitleStyled>4. Limitações.</ContainerTitleStyled>
      <ContainerTermStyled>
        Em nenhum caso a Logshare ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação,
        danos por perda de dados ou lucro, ou devido à interrupção dos negócios) decorrentes do uso ou da incapacidade
        de usar os materiais na Logshare, mesmo que a Logshare ou um representante autorizado da Logshare tenha sido
        notificado oralmente, ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem
        limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes, ou incidentais,
        essas limitações podem não se aplicar a você.
      </ContainerTermStyled>

      <ContainerTitleStyled>5. Precisão dos materiais.</ContainerTitleStyled>
      <ContainerTermStyled>
        Os materiais exibidos no site da Logshare podem incluir erros técnicos, tipográficos ou fotográficos. A Logshare
        não garante que qualquer material em seu site seja preciso, completo ou atual. A Logshare pode efetuar
        alterações nos materiais contidos em seu site a qualquer momento, sem aviso. No entanto, a Logshare não se
        compromete a atualizar os materiais.
      </ContainerTermStyled>

      <ContainerTitleStyled>6. Links.</ContainerTitleStyled>
      <ContainerTermStyled>
        A Logshare não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site
        vinculado. A inclusão de qualquer link não implica endosso pela Logshare do site. O uso de qualquer site
        vinculado é por conta e risco do usuário.
      </ContainerTermStyled>

      <ContainerTitleStyled>Modificações.</ContainerTitleStyled>
      <ContainerTermStyled>
        A Logshare pode revisar estes termos de serviço do site a qualquer momento, sem aviso. Ao usar este site, você
        concorda em ficar vinculado à versão atual desses termos de serviço.
      </ContainerTermStyled>

      <ContainerTitleStyled>Lei aplicável.</ContainerTitleStyled>
      <ContainerTermStyled>
        Estes termos e condições são regidos e interpretados conforme as leis da Logshare e você se submete
        irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.
      </ContainerTermStyled>
    </ContainerTableStyled>
  );
};

export default OurPrivacyPoliciesModalTableView;
