import { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { CargoDetailsBackhaulPurchaseFormView } from './cargo-details-backhaul-purchase-form-view.component';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';

export const CargoDetailsBackhaulPurchaseForm: FC = () => {
  const { values, setFieldValue } = useFormikContext<IModalBackhaulPurchaseForm>();

  const handleChange = useCallback(
    (field: string, value: string | number) => {
      if (field === 'cargo.capacity.pallets') {
        return setFieldValue(field, +value);
      }
      return setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return <CargoDetailsBackhaulPurchaseFormView cargo={values.cargo} handleChange={handleChange} />;
};
