import React, { useCallback, useRef, useState } from 'react';
import { showToast } from 'components/toast/toast';
import { ModalUploadSpreadsheetView } from './modal-upload-spreadsheet-view.component';

export interface IModalUploadSpreadsheetProps {
  title: string;
  description: string;
  isLoading?: boolean;
  handleCancel: () => void;
  handleSuccess: (formData: FormData) => void;
  handleDownload?: () => void;
}

export const ModalUploadSpreadsheet: React.FC<IModalUploadSpreadsheetProps> = ({
  title,
  description,
  isLoading,
  handleCancel,
  handleSuccess,
  handleDownload,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');

  const handleSendUpload = useCallback(() => {
    const formData = new FormData();
    const input: any = document.getElementById('file');
    const uploadFileData = input.files[0];

    if (
      uploadFileData.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      uploadFileData.type === 'application/vnd.ms-excel'
    ) {
      formData.append('file', uploadFileData);
      handleSuccess(formData);
    } else {
      showToast({
        message: 'Selecione um arquivo com extenção xlsx ou xls',
        type: 'error',
      });
    }
  }, [handleSuccess]);

  const handleInput = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <ModalUploadSpreadsheetView
      title={title}
      fileNameProps={{ fileName, setFileName }}
      description={description}
      fileInputRef={fileInputRef}
      isLoading={isLoading}
      handleCancel={handleCancel}
      handleSendUpload={handleSendUpload}
      handleInput={handleInput}
      handleDownload={handleDownload}
    />
  );
};
