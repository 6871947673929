import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { ITransportCompanyV2 } from '../../../../domain/transport-company';
import { IListPage } from '../../../../domain/page-info';

import { ITransportCompaniesContext } from './transport-companies.types';
import { transportCompaniesReducer } from './transport-companies.reducer';
import { initialState, ITransportCompaniesAction } from './transport-companies.actions';

interface ITransportCompaniesContextProps {
  children: ReactNode;
}

export const TransportCompaniesContext = createContext<ITransportCompaniesContext>(initialState);

const TransportCompaniesContextProvider = ({ children }: ITransportCompaniesContextProps) => {
  const [state, dispatch] = useReducer(transportCompaniesReducer, initialState);

  const setTransportCompaniesRequest = useCallback(() => {
    dispatch({
      type: ITransportCompaniesAction.TRANSPORT_COMPANIES_REQUEST,
      payload: null,
    });
  }, []);

  const setTransportCompaniesSuccess = useCallback((transportCompanyList: IListPage<ITransportCompanyV2>) => {
    dispatch({
      type: ITransportCompaniesAction.TRANSPORT_COMPANIES_SUCCESS,
      payload: transportCompanyList,
    });
  }, []);

  const setTransportCompaniesError = useCallback((error: string) => {
    dispatch({
      type: ITransportCompaniesAction.TRANSPORT_COMPANIES_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      transportCompaniesIsLoading: state.transportCompaniesIsLoading,
      transportCompanies: state.transportCompanies,
      transportCompaniesError: state.transportCompaniesError,
      transportCompaniesPageInfo: state.transportCompaniesPageInfo,
      setTransportCompaniesRequest,
      setTransportCompaniesSuccess,
      setTransportCompaniesError,
    };
  }, [
    setTransportCompaniesError,
    setTransportCompaniesRequest,
    setTransportCompaniesSuccess,
    state.transportCompanies,
    state.transportCompaniesError,
    state.transportCompaniesIsLoading,
    state.transportCompaniesPageInfo,
  ]);

  return <TransportCompaniesContext.Provider value={value}>{children}</TransportCompaniesContext.Provider>;
};

export default React.memo(TransportCompaniesContextProvider);
