import React, { FC, Fragment, useMemo } from 'react';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import { IHeaderProps } from 'src-new/components/application-page-header/application-page-header.types';
import { PurchaseConfirmationModal } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-header/components/purchase-confirmation-modal/purchase-confirmation-modal.component';

interface IModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

interface IPurchaseOfferHeaderViewProps {
  headerProps: IHeaderProps;
  deleteModalProps: IModalProps;
}

export const PurchaseOfferHeaderView: FC<IPurchaseOfferHeaderViewProps> = ({ headerProps, deleteModalProps }) => {
  const renderDeleteModal = useMemo(() => {
    if (deleteModalProps.isOpen) {
      return <PurchaseConfirmationModal handleCloseModal={deleteModalProps.handleCloseModal} />;
    }
  }, [deleteModalProps.handleCloseModal, deleteModalProps.isOpen]);

  return (
    <Fragment>
      <ApplicationPageHeader headerProps={headerProps} />
      {renderDeleteModal}
    </Fragment>
  );
};
