import React from 'react';
import * as S from './execution-time-recording-check-modal-view.styled';
import { Button } from 'logshare-ui-kit';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';

interface ITimeRecordingCheckModalViewProps {
  handleSubmit: () => void;
  handleCloseModal: () => void;
}

export const TimeRecordingCheckModalView: React.FC<ITimeRecordingCheckModalViewProps> = ({
  handleSubmit,
  handleCloseModal,
}) => {
  return (
    <ModalBark title="Registro de Horários" size="xxsm" handleClose={handleCloseModal}>
      <S.Wrapper>
        <S.Text>Confirmar o registro do horário?</S.Text>

        <S.ContainerButton>
          <Button label="Confirmar" color="blue" size="md" onPress={handleSubmit} variant="solid" />
        </S.ContainerButton>
      </S.Wrapper>
    </ModalBark>
  );
};
