import { IDriverEntityStatus } from 'pages/driver/services/entities/driver.entity';
import { driverReportExcelService } from 'pages/driver/services/driver-report-excel/driver-report-excel.service';

export interface IDriverReportExcelProps {
  search: string;
  status: IDriverEntityStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: IDriverReportExcelProps): Promise<BlobPart | undefined> => {
    return await driverReportExcelService(params);
  };
};

export { useReportExcelHook };
