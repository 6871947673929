import { allocationCancel } from 'pages/allocation/services/allocation-cancel/allocation-cancel.service';
import { showToast } from 'components/toast/toast';

export const cancelScheduleHook = async (ids: Array<number>, descriptionCancel: string, type: 'spot' | 'backhaul') => {
  try {
    await allocationCancel(ids, type, descriptionCancel);

    showToast({
      message: 'Agendamento atualizado com sucesso',
      type: 'success',
    });
  } catch (error) {
    showToast({
      message: 'Erro ao atualizar agendamento',
      type: 'error',
    });
  }
};
