import React, { useCallback, useContext } from 'react';
import { DocumentsModalsDeleteView } from './documents-modals-delete-view.components';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useDeleteNFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-nfe-data/use-delete-nfe-data.hook';
import { useDeleteCTeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-cte-data/use-delete-cte-data.hook';
import { useDeleteMDFeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-mdfe-data/use-delete-mdfe-data.hook';
import { useDeleteOthersDocumentsData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-delete-others-documents-data/use-delete-others-documents-data.hook';

export const DocumentsModalsDelete: React.FC = () => {
  const { scheduleDocuments } = useContext(ScheduleContext);
  const deleteNFeData = useDeleteNFeData();
  const deleteCTeData = useDeleteCTeData();
  const deleteMDFeData = useDeleteMDFeData();
  const deleteOthersDocumentsData = useDeleteOthersDocumentsData();

  const handleClose = useCallback(
    () => scheduleDocuments.modalsProps.setModalOpen({ open: false, id: 0, modalType: '' }),
    [scheduleDocuments.modalsProps],
  );

  const handleDelete = useCallback(async () => {
    switch (scheduleDocuments.modalsProps.modalOptions.modalType) {
      case 'nfe':
        deleteNFeData();
        break;
      case 'cte':
        deleteCTeData();
        break;
      case 'mdfe':
        deleteMDFeData();
        break;
      default:
        deleteOthersDocumentsData();
    }
  }, [
    deleteCTeData,
    deleteMDFeData,
    deleteNFeData,
    deleteOthersDocumentsData,
    scheduleDocuments.modalsProps.modalOptions.modalType,
  ]);

  return (
    <DocumentsModalsDeleteView
      modalOptions={scheduleDocuments.modalsProps.modalOptions}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};
