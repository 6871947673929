import React from 'react';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import * as S from './home-page-body-view.styled';
import { HomeCards } from '../home-cards/home-cards.component';
import { HomeButtons } from '../home-buttons/home-buttons.component';
import { HomeBidOffers } from 'src-new/pages/home/pages/home/components/home-bid-offers/home-bid-offers.component';
import { HomeMatchesTable } from '../home-matches-table/home-matches-table.component';

export const HomePageBodyView: React.FC = () => {
  return (
    <S.Wrapper>
      <HeaderPage />
      <S.TitleContainer>
        <S.TitleTextContainer>
          <S.TitleText>Olá! Bem-vindo à LogShare!</S.TitleText>
          <S.SubtitleText>Integre sua malha logística para compra e venda de capacidades</S.SubtitleText>
        </S.TitleTextContainer>
      </S.TitleContainer>
      <S.ContentWrapper>
        <S.ContentCards>
          <HomeCards />
          <HomeButtons />
        </S.ContentCards>
        <S.TablesContainer>
          <HomeMatchesTable />
          <HomeBidOffers />
        </S.TablesContainer>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
