import { IOption } from 'domain/global-inputs';

export type VehicleTypeDomain =
  | ''
  | '3/4'
  | 'Bitrem'
  | 'Bitruck'
  | 'Carreta'
  | 'Carreta LS'
  | 'Carreta 7 Eixos'
  | 'Complemento'
  | 'Rodotrem'
  | 'Romeu & Julieta'
  | 'Toco'
  | 'Truck'
  | 'Utilitário'
  | 'Van'
  | 'Vanderleia'
  | 'Vuc';

export type VehicleTypeResponse =
  | '3/4'
  | 'tres_quartos'
  | 'bitrem'
  | 'bitruck'
  | 'carreta'
  | 'carreta_ls'
  | 'carreta_sete_eixos'
  | 'complemento'
  | 'rodotrem'
  | 'romeu&julieta'
  | 'toco'
  | 'truck'
  | 'utilitario'
  | 'van'
  | 'vanderleia'
  | 'vuc'
  | '';

export const vehicleTypeOptionsDomain: Array<IOption> = [
  { value: '3/4', label: '3/4' },
  { value: 'Bitrem', label: 'BITREM' },
  { value: 'Bitruck', label: 'BITRUCK' },
  { value: 'Carreta', label: 'CARRETA' },
  { value: 'Carreta LS', label: 'CARRETA LS' },
  { value: 'Carreta 7 Eixos', label: 'CARRETA 7 EIXOS' },
  { value: 'Rodotrem', label: 'RODOTREM' },
  { value: 'Romeu & Julieta', label: 'ROMEU & JULIETA' },
  { value: 'Toco', label: 'TOCO' },
  { value: 'Truck', label: 'TRUCK' },
  { value: 'Utilitário', label: 'UTILITÁRIO' },
  { value: 'Van', label: 'VAN' },
  { value: 'Vanderleia', label: 'VANDERLEIA' },
  { value: 'Vuc', label: 'VUC' },
];
