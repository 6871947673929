import { FC } from 'react';
import * as S from './weighing-register-page-body-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Title from 'components/title/title';
import { WeighingRegisterForm } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/weighing-register-form.component';

export const WeighingRegisterPageBodyView: FC = () => {
  return (
    <S.Wrapper>
      <HeaderPage breadcrumbList={['Programação', 'Pesagem']} hasBackButton />
      <Title title="Nova Pesagem" hasTrace isForm />
      <WeighingRegisterForm />
    </S.Wrapper>
  );
};
