import { useCallback } from 'react';
import { ILeadTimeDomain } from 'src-new/domains/leadtime.domain';
import { getLeadTimeService } from 'src-new/services/get-leadtime/get-leadtime.service';
import { ILeadTimeParams } from 'src-new/services/get-leadtime/get-leadtime.types';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetLeadTime = () => {
  return useCallback(async (params: ILeadTimeParams): Promise<ILeadTimeDomain | undefined> => {
    try {
      return await getLeadTimeService(params);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter distancia e leadtime.',
        type: 'error',
      });
    }
  }, []);
};
