import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  createInvoiceService,
  ICreateInvoiceServiceParams,
} from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/create-invoice/create-invoice.service';
import { showToast } from 'components/toast/toast';

export const useCreateInvoice = () => {
  return useCallback(async (params: ICreateInvoiceServiceParams, setShowModal: Dispatch<SetStateAction<boolean>>) => {
    try {
      showToast({
        message: 'Aguarde, em processamento.',
        type: 'info',
      });

      await createInvoiceService(params);

      showToast({
        message: 'A fatura foi emitida com sucesso.',
        type: 'success',
      });
      setShowModal(false);
    } catch (error) {
      showToast({
        message: 'Ocorreu um erro ao emitir fatura.',
        type: 'error',
      });
    }
  }, []);
};
