import { FC, Fragment, ReactElement, useMemo } from 'react';
import {
  IScheduleDetailsCompanyBuyerDomain,
  IScheduleDetailsCompanyDomain,
  IScheduleDetailsShippingDomain,
} from '../../../../domains/schedule-details.domain';
import * as S from './schedule-details-resume-operation-view.styled';

interface IScheduleDetailsResumeOperationViewProps {
  isSpot: boolean;
  isTracking: boolean;
  company: IScheduleDetailsCompanyDomain;
  companyBuyer?: IScheduleDetailsCompanyBuyerDomain;
  shipping?: IScheduleDetailsShippingDomain;
}

export const ScheduleDetailsResumeOperationView: FC<IScheduleDetailsResumeOperationViewProps> = ({
  isSpot,
  isTracking,
  company,
  companyBuyer,
  shipping,
}) => {
  const renderBuyerAndSeller = useMemo((): ReactElement => {
    if (isTracking) {
      return (
        <S.WrapperInfos>
          <S.Text isStrong>comprador</S.Text>
          <S.Text>{company.tradeName}</S.Text>
        </S.WrapperInfos>
      );
    }

    if (isSpot) {
      return (
        <Fragment>
          <S.WrapperInfos>
            <S.Text isStrong>comprador</S.Text>
            <S.Text>{company.tradeName}</S.Text>
          </S.WrapperInfos>

          <S.WrapperInfos>
            <S.Text isStrong>vendedor</S.Text>
            <S.Text>{'logshare'}</S.Text>
          </S.WrapperInfos>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <S.WrapperInfos>
          <S.Text isStrong>comprador</S.Text>
          <S.Text>{companyBuyer?.name}</S.Text>
        </S.WrapperInfos>

        <S.WrapperInfos>
          <S.Text isStrong>vendedor</S.Text>
          <S.Text>{company.tradeName}</S.Text>
        </S.WrapperInfos>
      </Fragment>
    );
  }, [company.tradeName, companyBuyer?.name, isSpot, isTracking]);

  return (
    <S.Wrapper>
      <S.WrapperOperation>
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.WrapperOperationInfo>
          <S.Div>
            <S.Text isStrong>partes</S.Text>
            <S.WrapperContent>
              {renderBuyerAndSeller}

              <S.WrapperInfos>
                <S.Text isStrong>transportador</S.Text>
                <S.Text>{shipping?.name || 'Não Informado'}</S.Text>
              </S.WrapperInfos>
            </S.WrapperContent>
          </S.Div>
        </S.WrapperOperationInfo>
      </S.WrapperOperation>
    </S.Wrapper>
  );
};
