import React, { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleRefusedContext } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/schedule-refused.context';
import { IScheduleRefusedListDomain } from '../../../../domains/schedule-refused-list/schedule-refused-list.domain';
import { ScheduleRefusedTableView } from './schedule-refused-table-view.component';
import { IOrdering } from 'src-new/components/table/table.types';

export const ScheduleRefusedTable: React.FC = () => {
  const { scheduleRefusedList } = useContext(ScheduleRefusedContext);

  const navigate = useNavigate();

  const getSchedulesRefused = useMemo(
    (): Array<IScheduleRefusedListDomain> => scheduleRefusedList.scheduleRefusedList,
    [scheduleRefusedList.scheduleRefusedList],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      const { filters, setScheduleRefusedFilters } = scheduleRefusedList.scheduleRefusedListFilters;

      setScheduleRefusedFilters({
        ...filters,
        column: columnName,
        order,
      });
    },
    [scheduleRefusedList.scheduleRefusedListFilters],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: scheduleRefusedList.scheduleRefusedListFilters.filters.column,
      order: scheduleRefusedList.scheduleRefusedListFilters.filters.order,
      handleClick: handleOrdering,
    };
  }, [
    handleOrdering,
    scheduleRefusedList.scheduleRefusedListFilters.filters.column,
    scheduleRefusedList.scheduleRefusedListFilters.filters.order,
  ]);

  const isLoading = useMemo(
    (): boolean => scheduleRefusedList.statusRequest === 'PROCESSING',
    [scheduleRefusedList.statusRequest],
  );

  const handleLineClick = useCallback(
    (scheduleRefusedId: number, scheduleId: number) =>
      navigate(`/fretes/${scheduleId}`, {
        state: { scheduleRefusedId },
      }),
    [navigate],
  );

  const handleNavigateNewFreight = useCallback(
    (scheduleId: number) =>
      navigate('/alocacao/novo/', {
        state: { scheduleId },
      }),
    [navigate],
  );

  return (
    <ScheduleRefusedTableView
      schedulesRefused={getSchedulesRefused}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      handleLineClick={handleLineClick}
      handleNavigateNewFreight={handleNavigateNewFreight}
    />
  );
};
