export enum PalletFormActionType {
  GET_PALLET_DETAILS_REQUEST = 'GET_PALLET_DETAILS_REQUEST',
  GET_PALLET_DETAILS_SUCCESS = 'GET_PALLET_DETAILS_SUCCESS',
  GET_PALLET_DETAILS_ERROR = 'GET_PALLET_DETAILS_ERROR',
  PALLET_FORM_SUBMIT = 'PALLET_FORM_SUBMIT',
  PALLET_FORM_SUCCESS = 'PALLET_FORM_SUCCESS',
  PALLET_FORM_ERROR = 'PALLET_FORM_ERROR',
  PALLET_FORM_RESET = 'PALLET_FORM_RESET',
}

export enum FinancialActionType {
  FINANCIAL_RESET = 'FINANCIAL_RESET',
}
