import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQueryClient } from 'react-query';
import { duplicateScheduleService } from '../../services/duplicate-schedule/duplicate-schedule.service';
import { useNavigate } from 'react-router-dom';

export const useDuplicateSchedule = () => {
  const { scheduleList } = useContext(ScheduleContext);

  const navigate = useNavigate();

  const reactQueryClient = useQueryClient();

  return useCallback(async () => {
    try {
      showMessageFormatted({
        message: 'Duplicando Frete',
        type: 'info',
      });

      const scheduleId = await duplicateScheduleService(scheduleList.modalsProps.modalOptions.ids[0]);

      navigate(`/fretes/${scheduleId}`);

      reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);

      showMessageFormatted({
        message: 'Frete duplicado!',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
  }, [
    navigate,
    reactQueryClient,
    scheduleList.modalsProps.modalOptions.ids,
    scheduleList.scheduleListFiltersProps.filters,
  ]);
};
