import { useNavigate } from 'react-router-dom';
import React, { useMemo, CSSProperties, memo, useCallback } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { HeaderTitle, Info, InfoTitle, SectionContainer, Wrapper } from './styled';

import { ScheduleClient } from 'domain/schedule';

interface IProps {
  values: ScheduleClient;
  type: 'Origem' | 'Destino';
  isAdmin?: boolean;
}

const ClientDetails: React.FC<IProps> = ({ values, type, isAdmin }) => {
  const navigate = useNavigate();

  const location = useMemo(() => values?.client || values?.locality, [values]);

  const goToDetailsPage = useCallback(() => {
    if (!isAdmin || !location.id) return;

    navigate(`/unidades/atualizar/${location.id}`);
  }, [navigate, location, isAdmin]);

  return (
    <Wrapper>
      <SectionContainer>
        <HeaderTitle isAdmin={isAdmin} onClick={goToDetailsPage}>
          {type}
          {isAdmin && <OpenInNewIcon sx={iconStyle} />}
        </HeaderTitle>

        <InfoTitle>
          Remetente
          <Info>{location?.name || location?.tradeName} </Info>
        </InfoTitle>

        <InfoTitle>
          CNPJ
          <Info>{location?.cnpj}</Info>
        </InfoTitle>

        <InfoTitle>
          Endereço
          <Info>
            {location?.street}, {location?.numberHouse}
          </Info>
        </InfoTitle>

        <InfoTitle>
          Bairro
          <Info>{location?.neighborhood} </Info>
        </InfoTitle>

        <InfoTitle>
          Cidade/UF
          <Info>
            {location?.city} - {location?.uf}
          </Info>
        </InfoTitle>

        <InfoTitle>
          CEP
          <Info>{location?.cep}</Info>
        </InfoTitle>
      </SectionContainer>
    </Wrapper>
  );
};

const iconStyle: CSSProperties = {
  fontSize: 14,
  color: '#000',
  marginLeft: 1,
};

export default memo(ClientDetails);
