import { IWeighingTottensDetailsDomain } from './weighing-totten-details.domain';

export const weighingTottensDetailsDomainInitialValues: IWeighingTottensDetailsDomain = {
  origin: {
    name: '',
    city: '',
    uf: '',
  },
  destiny: {
    name: '',
    city: '',
    uf: '',
  },
  driver: { name: '', cpf: '' },
  vehicle: { mainPlate: '' },
  shippingCompanyName: '',
};
