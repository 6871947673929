import styled, { css } from 'styled-components';

export const ModalFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeaderStyled = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 180px auto;
  font-size: 14px;
  font-weight: 600;
`;

export const LineSeparatorStyled = styled.div`
  background-color: #e0e0e0;
  height: 1px;
  width: 100%;
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 10px;
  `}
`;
