import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Category = styled.div`
  width: 54px;
  display: grid;
  grid-row: 1fr 1fr;
  grid-gap: 4px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.div`
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Subtitle = styled.div`
  color: #7b7b7b;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LoadingWrapper = styled.div`
 ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  `}
`;

export const EmptyListWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  `}
`;