import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

import { ValuesDisabledDetails } from '../transaction-disabled';
import * as S from '../../details/sections/styled';

import { convertFinancialStatus } from 'utils-v2/converters/financial-status';
import { convertDecimalFormat } from 'utils-v2/converters/decimal';
import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { ColorsV2Type } from 'styles/types/colors';
import { UseDisabledTransaction } from 'pages-v2/schedule/hooks/disabled-transaction';
import { TransactionDetails } from 'domain-v2/transactions/details';
import { RetentionFinesFinance } from 'domain-v2/transactions';
import { ModalState } from 'domain-v2/inputs/modals';
import { IUser } from 'domain/login';
import Button from 'components/button/button';

export interface ValuesDetailsProps {
  title: string;
  ownerTransaction: string;
  transactionIndex: number;
  user: IUser;
  isAdmin: boolean;
  transaction: TransactionDetails;
  margin?: number;
  schedule: any;
  retentionFine?: RetentionFinesFinance;
  setCalculateModal: Dispatch<SetStateAction<ModalState<{ index: number }>>>;
  setAuditModal: Dispatch<SetStateAction<ModalState<{ index: number }>>>;
  onRefresh?: Dispatch<SetStateAction<number>>;
}

export const ValuesDetails: FC<ValuesDetailsProps> = ({
  title,
  ownerTransaction,
  transactionIndex,
  user,
  isAdmin,
  transaction,
  margin = 0,
  schedule,
  retentionFine,
  setCalculateModal,
  setAuditModal,
  onRefresh,
}) => {
  const handleDisabledTransaction = useCallback(
    async (isDisabled: boolean) => {
      await UseDisabledTransaction(transaction.id, { isDisabled });
      onRefresh && onRefresh((prev) => prev + 1);
    },
    [onRefresh, transaction.id],
  );

  const isEditValuesTransaction = useMemo(() => {
    const isFinancialStatusInAudit = transaction.financialStatus === 'Em Auditoria';
    const isOperationExternalBackhaul = schedule.operation === 'BACKHAUL EXTERNO';
    const isUserCompany = schedule.companyId === user.companyId;
    const invoicedByLogshare = schedule.invoicedBy === 'LOGSHARE';

    if (
      (isAdmin && isFinancialStatusInAudit) ||
      (isFinancialStatusInAudit &&
        isOperationExternalBackhaul &&
        isUserCompany &&
        !invoicedByLogshare &&
        transaction.status === 'Pagar')
    ) {
      return true;
    }

    if (isAdmin && transaction.status === 'Pagar') return true;

    return false;
  }, [transaction, schedule.operation, schedule.companyId, schedule.invoicedBy, user.companyId, isAdmin]);

  const isEditAuditTransaction = useMemo(() => {
    const isFinancialStatusInAudit = transaction.financialStatus === 'Em Auditoria';
    const isOperationExternalBackhaul = schedule.operation === 'BACKHAUL EXTERNO';
    const isUserCompany = schedule.companyId === user.companyId;
    const invoicedByLogshare = schedule.invoicedBy === 'LOGSHARE';

    const statusCode = ['IN90', 'LG90', 'AL90'];

    if (
      statusCode.includes(schedule.statusCode) &&
      ((isAdmin && isFinancialStatusInAudit) ||
        (isFinancialStatusInAudit &&
          isOperationExternalBackhaul &&
          isUserCompany &&
          !invoicedByLogshare &&
          transaction.status === 'Pagar'))
    ) {
      return true;
    }

    return false;
  }, [
    transaction,
    schedule.operation,
    schedule.companyId,
    schedule.invoicedBy,
    schedule.statusCode,
    user.companyId,
    isAdmin,
  ]);

  const renderFreetimeTooltip = useMemo(() => {
    if (transaction.status === 'Receber Transportador') return;

    let retentionFineValues = retentionFine?.seller;

    if (transaction.status === 'Pagar' && retentionFine?.buyer) {
      retentionFineValues = retentionFine?.buyer;
    }

    return (
      <Tooltip
        title={
          <div>
            <p style={{ fontSize: '11px' }}>Freetime: {retentionFineValues?.freetime} Horas</p>
            <p style={{ fontSize: '11px' }}>
              Hora Adicional: {convertCurrencyFormat.format(retentionFineValues?.hourlyFine ?? 0)}
            </p>
            <p style={{ fontSize: '11px' }}>
              Diária: {convertCurrencyFormat.format(retentionFineValues?.dailyFine ?? 0)}
            </p>
          </div>
        }
        placement="top"
      >
        <InfoIcon sx={{ width: 13, height: 13, color: 'gray' }} />
      </Tooltip>
    );
  }, [retentionFine, transaction]);

  const renderGrisAdvTooltip = useMemo(() => {
    if (transaction.status === 'Receber Transportador') return;

    let grisValue = Number(schedule.company.gris ?? 0);
    let advaloremValue = Number(schedule.company.advalorem ?? 0);

    if (transaction.status === 'Pagar' && schedule.companyBuyer) {
      grisValue = Number(schedule.companyBuyer.gris ?? 0);
      advaloremValue = Number(schedule.companyBuyer.advalorem ?? 0);
    }

    return (
      <Tooltip
        title={
          <div>
            <p style={{ fontSize: '11px' }}>Gris: {convertDecimalFormat.format(grisValue)}%</p>
            <p style={{ fontSize: '11px' }}>Advalorem: {convertDecimalFormat.format(advaloremValue)}%</p>
          </div>
        }
        placement="top"
      >
        <InfoIcon sx={{ width: 13, height: 13, color: 'gray' }} />
      </Tooltip>
    );
  }, [schedule, transaction]);

  const renderFeeLogshareTooltip = useMemo(() => {
    if (transaction.status === 'Receber Transportador') return;

    let feeLogshare = Number(schedule.company.feeLogshare ?? 0);

    if (transaction.status === 'Pagar' && schedule.companyBuyer) {
      feeLogshare = Number(schedule.companyBuyer.feeLogshare ?? 0);
    }

    return (
      <Tooltip
        title={
          <div>
            <p style={{ fontSize: '11px' }}>Taxa LogShare: {convertDecimalFormat.format(feeLogshare)}%</p>
          </div>
        }
        placement="top"
      >
        <InfoIcon sx={{ width: 13, height: 13, color: 'gray' }} />
      </Tooltip>
    );
  }, [schedule, transaction]);

  const renderSection = useMemo(() => {
    if (transaction.isDisabled && isAdmin) return null;

    if (transaction.isDisabled && !isAdmin)
      return (
        <ValuesDisabledDetails
          title={title}
          ownerTransaction={ownerTransaction}
          isAdmin={isAdmin}
          transaction={transaction}
          onRefresh={onRefresh}
        />
      );

    return (
      <S.WrapperSection>
        <S.WrapperHeader>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <h5>{title}</h5>
            <h5 style={{ color: 'black' }}>{ownerTransaction}</h5>
          </div>

          <S.WrapperHeaderItensRight>
            <S.WrapperHeaderButtons>
              {isAdmin && transaction.status === 'Pagar' && (
                <Tag
                  label={margin.toFixed(2) + ' %'}
                  variant="solid"
                  display="flex"
                  size="md"
                  color={margin > 0 ? ColorsV2Type.Green : ColorsV2Type.Red}
                />
              )}
              {isAdmin && transaction.financialStatus === 'Em Auditoria' && (
                <Button
                  title="Desabilitar Transação"
                  size="very-small"
                  icon={<DeleteIcon />}
                  callback={() => handleDisabledTransaction(true)}
                  bgColor="newRed"
                />
              )}
              {isEditValuesTransaction && (
                <Button
                  title="Editar"
                  size="very-small"
                  icon={<CreateIcon />}
                  callback={() =>
                    setCalculateModal({
                      open: true,
                      data: { index: transactionIndex },
                    })
                  }
                  bgColor="white"
                />
              )}

              {isEditAuditTransaction && (
                <Button
                  title="Auditar"
                  size="large"
                  callback={() =>
                    setAuditModal({
                      open: true,
                      data: { index: transactionIndex },
                    })
                  }
                  bgColor="aqua"
                />
              )}
            </S.WrapperHeaderButtons>

            <S.WrapperHeaderTag padding={isAdmin}>
              <Tag
                label={convertFinancialStatus(transaction.financialStatus).label}
                color={convertFinancialStatus(transaction.financialStatus).color}
                variant="solid"
                display="flex"
                size="md"
              />
            </S.WrapperHeaderTag>
          </S.WrapperHeaderItensRight>
        </S.WrapperHeader>

        <S.WrapperValues columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <S.WrapperInfo>
            <S.InfoTitle>Frete Valor</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.valueFreight)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Ajudante</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.helperTotal)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Pedágio</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.toll)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Descarga</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.discharge)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Tempo Excedente {renderFreetimeTooltip}</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.surplusTime)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Desconto</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.penaltyBonus)}</S.Info>
          </S.WrapperInfo>
        </S.WrapperValues>

        <S.WrapperValues columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <S.WrapperInfo>
            <S.InfoTitle>Taxa Logshare {renderFeeLogshareTooltip}</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.totalFee)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Gris/Advalorem {renderGrisAdvTooltip}</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.totalGrisAdvalorem)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Frete Total</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.totalFreight)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>ICMS</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.icms)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Outros Impostos</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.otherTaxes)}</S.Info>
          </S.WrapperInfo>

          <S.WrapperInfo>
            <S.InfoTitle>Total do Serviço</S.InfoTitle>
            <S.Info>{convertCurrencyFormat.format(transaction.totalService)}</S.Info>
          </S.WrapperInfo>
        </S.WrapperValues>
      </S.WrapperSection>
    );
  }, [
    transaction,
    isAdmin,
    title,
    onRefresh,
    margin,
    isEditValuesTransaction,
    isEditAuditTransaction,
    renderFreetimeTooltip,
    renderFeeLogshareTooltip,
    handleDisabledTransaction,
    setCalculateModal,
    transactionIndex,
    setAuditModal,
  ]);

  return renderSection;
};
