import styled from 'styled-components';

import theme from 'styles/theme';

export const ContainerSearch = styled.div`
  display: flex;
  & > div {
    margin-right: 40px;
  }
`;

export const FooterText = styled.p`
  color: #4b4b4b;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-right: 12px;
  z-index: ${theme.layers.modal};
`;
