import React, { useEffect, useState } from 'react';

import TabsPermissionsView from './tabs-permissions-view';

export interface ITabContent {
  name: string;
  content: JSX.Element;
}

interface ITabsProps {
  tabsContent: Array<ITabContent>;
}

const TabsPermissions: React.FC<ITabsProps> = ({ tabsContent }: ITabsProps) => {
  const [activeTab, setActiveTab] = useState(tabsContent[0]);

  useEffect(() => {
    const indexTabActive: number = tabsContent.findIndex((tab) => tab.name === activeTab.name);
    setActiveTab(tabsContent[indexTabActive]);
  }, [activeTab.name, tabsContent]);

  return <TabsPermissionsView activeTab={activeTab} setActiveTab={setActiveTab} tabsContent={tabsContent} />;
};

export default TabsPermissions;
