import styled, { css } from 'styled-components';

export const StateContainer = styled.div`
  height: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px auto;
  font-size: 14px;
  font-weight: 600;
`;

export const LineSeparator = styled.div`
  background-color: #e0e0e0;
  height: 1px;
  width: 100%;
`;

interface ILineSectionProps {
  columns: string;
}
export const LineSection = styled.section<ILineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 25px;
  `}
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;
