import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import { OccurrenceRegisterSchema } from './schemas-validation/schemas-validation';
import OccurrenceRegisterFormView from './occurrence-register-form-view';
import { initialValues, PATH_TO_OCCURRENCE_LIST, optionsImpact, optionsSla, optionsTreatmentSector } from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useOccurrenceRegisterHook } from 'pages/occurrence/hooks/use-occurrence-register/use-occurrence-register.hook';
import { IOccurrence } from 'domain/occurrence';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const OccurrenceRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const occurrenceRegisterHook = useOccurrenceRegisterHook();

  const goBackToOccurrenceList = useCallback(() => navigate(PATH_TO_OCCURRENCE_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (occurrence: IOccurrence) => {
      setIsLoading(true);
      occurrenceRegisterHook(occurrence)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToOccurrenceList();
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToOccurrenceList, occurrenceRegisterHook],
  );

  return (
    <OccurrenceRegisterFormView
      occurrenceRegisterValues={initialValues}
      handleGoback={goBackToOccurrenceList}
      optionsImpactOccurrence={optionsImpact}
      optionsTreatmentSector={optionsTreatmentSector}
      optionsSlaOccurrence={optionsSla}
      handleRegister={handleSubmit}
      schemaValidation={OccurrenceRegisterSchema}
      isLoading={isLoading}
    />
  );
};

export default OccurrenceRegisterForm;
