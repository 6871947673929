import styled from 'styled-components';

export const WrapperTableStyled = styled.div`
  height: calc(100% - 128px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  padding: 0 30px 30px 30px;
  overflow-y: auto;
`;

export const WrapperPaginationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
