import React from 'react';
import theme from 'styles/theme';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IOccurenceListIconProps {
  color: ThemeColorsV2Type;
}

export const OccurenceListIcon: React.FC<IOccurenceListIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path
        id="Vector"
        d="M17.8887 13.571C17.8835 13.2466 17.7953 12.9289 17.6326 12.6483L10.6159 1.4015C10.0652 0.509329 8.8955 0.232523 8.00332 0.783243C7.75385 0.937247 7.54345 1.14693 7.38858 1.39585L0.360646 12.6615C-0.158688 13.5723 0.158661 14.7316 1.06948 15.251C1.34735 15.4094 1.66075 15.4951 1.98059 15.5H16.0201C17.0688 15.4833 17.9054 14.6196 17.8887 13.571ZM9.10374 13.0403C9.07124 13.0443 9.03852 13.0461 9.0058 13.0459C8.5764 13.0434 8.23031 12.6933 8.23271 12.2639C8.23491 11.8722 8.52808 11.5433 8.91695 11.4962C9.34334 11.4446 9.73082 11.7485 9.7824 12.1749C9.83397 12.6013 9.53012 12.9887 9.10374 13.0403ZM9.74919 7.06412H9.74982L9.52655 9.63107C9.51696 9.87266 9.46407 10.1106 9.37039 10.3335C9.25877 10.5518 8.99128 10.6384 8.77292 10.5267C8.69259 10.4857 8.62666 10.4211 8.58393 10.3416C8.4946 10.1109 8.44211 9.86749 8.4284 9.62041L8.26157 7.12684C8.22896 6.62511 8.05776 5.81419 8.44095 5.40652C8.73257 5.09295 9.42306 5.03901 9.63253 5.47363C9.81151 5.98413 9.85175 6.53297 9.74919 7.06412Z"
        fill={theme.colorsV2[color]}
      />
    </svg>
  );
};
