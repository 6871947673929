import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';
import { RegistrationContactValues } from 'src-new/pages/registrations/domains/registration-values.domain';
import { RiskManagementTableView } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/components/risk-management-table/risk-management-table-view.component';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';

interface IRiskManagementTableProps {
  handleRemoveContact: (index: number) => IRiskManagementDomain | undefined;
}

export const RiskManagementTable: React.FC<IRiskManagementTableProps> = ({ handleRemoveContact }) => {
  const { riskManagementSection } = useContext(RegistrationsContext);
  const { getFieldProps } = useFormikContext<RegistrationContactValues>();
  const [tableList, setTableList] = useState<Array<IRiskManagementDomain>>([]);

  const riskManagement = useMemo(
    (): Array<IRiskManagementDomain> => getFieldProps('riskManagers').value ?? [],
    [getFieldProps],
  );

  const isEqualContactList = useMemo((): boolean => isEqual(riskManagement, tableList), [riskManagement, tableList]);

  const removeItem = useCallback(
    (index: number) => {
      handleRemoveContact(index);
    },
    [handleRemoveContact],
  );

  useEffect(() => {
    if (!riskManagementSection.modalProps.isOpenModal && !isEqualContactList) {
      setTableList(riskManagement);
    }
  }, [riskManagementSection.modalProps.isOpenModal, riskManagement, isEqualContactList]);

  return <RiskManagementTableView riskManagers={tableList} removeItem={removeItem} />;
};
