import CountUp from 'react-countup';
import React, { ReactNode } from 'react';

import {
  ContainerIconStyled,
  ContainerSubtitleStyled,
  ContainerValuesAndIconStyled,
  ContentBoxContainerStyled,
  PercentValueStyled,
  SpaceStyle,
  SubTitleStyled,
  TitleStyled,
  ValuesContainerStyled,
  ValueStyled,
} from './content-box-time.styled';

interface IWalletBoxProps {
  title: string;
  subtitle?: string;
  hours: number;
  minutes: number;
  percentValue?: string;
  prefix?: 'R$ ' | 'US$';
  sufix?: ' VOL' | ' KG' | ' TON';
  color: 'white' | 'lightgray';
  decimals: 2 | 0;
  isLarge?: boolean;
  isSmall?: boolean;
  icon?: ReactNode;
}

const ContentBoxViewTime: React.FC<IWalletBoxProps> = ({
  title,
  subtitle,
  hours,
  minutes,
  percentValue,
  prefix,
  color,
  decimals,
  isLarge,
  isSmall,
  icon,
}) => {
  return (
    <ContentBoxContainerStyled color={color} isLarge={isLarge} isSmall={isSmall}>
      <ContainerValuesAndIconStyled>
        <ValuesContainerStyled isSmall={isSmall}>
          <TitleStyled>{title}</TitleStyled>
          <ValueStyled>
            <CountUp end={hours} separator="." decimal="," decimals={decimals} prefix={prefix || ''} suffix={'h'} />
            <SpaceStyle />
            <CountUp end={minutes} separator="." decimal="," decimals={decimals} prefix={prefix || ''} suffix={'min'} />
          </ValueStyled>
        </ValuesContainerStyled>

        <ContainerIconStyled>{icon}</ContainerIconStyled>
      </ContainerValuesAndIconStyled>

      <ContainerSubtitleStyled>
        <SubTitleStyled>{subtitle}</SubTitleStyled>
        <PercentValueStyled>{percentValue}</PercentValueStyled>
      </ContainerSubtitleStyled>
    </ContentBoxContainerStyled>
  );
};

export default ContentBoxViewTime;
