import React, { useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import { Tooltip } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import HeaderTable from '../header';
import { convertReworkStatusCodeColor } from '../../utils/convert-status-code';
import { ISelectedReworks } from '../../types';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { ReworkList } from 'services/rework/types';
import { PageInfo } from 'domain/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';

interface ContentTable {
  reworks: Array<ReworkList>;
  pageInfo?: PageInfo;
  isAdmin: boolean;
  setSelectedRework: React.Dispatch<React.SetStateAction<Array<ISelectedReworks>>>;
  selectedRework: Array<ISelectedReworks>;
  isLoading: boolean;
  handlePaginate: (page: number) => void;
  handleGeneratePDF: (id: number) => void;
  handleGenerateOrder: (id: number) => void;
  orderSelected: { orderBy: string; sortDirection: string };
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
  setActiveTabName: (name: string) => void;
  goToEditRework: (id: string) => void;
  tabActive: string;
  handleUpdateStatus: (id: number, statusBefore: string) => void;
}

const ListReworkTable: React.FC<ContentTable> = ({
  reworks,
  pageInfo,
  setSelectedRework,
  selectedRework,
  isLoading,
  handlePaginate,
  handleGeneratePDF,
  handleGenerateOrder,
  orderSelected,
  clickOrderTableBy,
  clickSortDirection,
  setActiveTabName,
  tabActive,
  goToEditRework,
}) => {
  const mountTable = useMemo((): Array<IContentTable> => {
    return reworks.map((rework) => {
      const statusCode = () => {
        return rework.status;
      };

      const handleCheckboxChange = (checked: boolean, allocationId: number, statusCode: string) => {
        if (checked) {
          const newSelectedReworks = [...selectedRework, { id: allocationId, status: statusCode }];
          setSelectedRework(newSelectedReworks);
        } else {
          const updatedSelectedReworks = selectedRework.filter((schedule) => schedule.id !== allocationId);
          setSelectedRework(updatedSelectedReworks);
        }
      };

      const elBackground = convertReworkStatusCodeColor(rework?.status?.toLowerCase());

      return [
        {
          value: (
            <Checkbox
              id={'check-allocation'}
              name={'check-allocation'}
              onChange={(e) => {
                const status = statusCode();
                handleCheckboxChange(e.target.checked, rework.id, status);
              }}
              checked={selectedRework?.some((selected) => selected.id === rework.id)}
            />
          ),
          flex: 0.1,
        },
        {
          value: rework.id,
          flex: 0.2,
        },

        {
          value: rework.serviceType,
          flex: 0.5,
        },

        {
          value: rework.conclusionDate,
          subtitle: rework.conclusionHour,
          flex: 0.5,
        },

        {
          value: rework.client,
          flex: 1,
        },
        {
          value: convertCurrencyFormat.format(Number(rework.serviceValue)),
          flex: 0.5,
        },
        {
          value: rework.invoiceService,
          flex: 0.5,
        },
        {
          value: rework.order,
          flex: 0.5,
        },

        {
          value: (
            <Tooltip title={<span style={{ fontSize: '12px' }}>{statusCode()}</span>} placement="left">
              <S.Info>
                <Tag label={statusCode()} color={elBackground} variant="solid" display="flex" size="md" />
              </S.Info>
            </Tooltip>
          ),
          flex: 0.5,
        },

        {
          value: (
            <S.Info
              onClick={() => {
                rework.status === 'CONCLUIDO' ? handleGeneratePDF(rework.id) : null;
              }}
            >
              <ReceiptLongIcon htmlColor="gray" />
            </S.Info>
          ),
          flex: 0.1,
        },
        {
          value: (
            <S.Info
              onClick={() => {
                handleGenerateOrder(rework.id);
              }}
            >
              <PictureAsPdfIcon htmlColor="gray" />
            </S.Info>
          ),
          flex: 0.1,
        },
        {
          value: (
            <S.Info onClick={() => null}>
              <FileCopyIcon htmlColor="gray" />
            </S.Info>
          ),
          flex: 0.1,
        },
      ];
    });
  }, [reworks, selectedRework]);

  const renderReworkTable = useMemo(() => {
    return (
      <HeaderTable
        orderSelected={orderSelected}
        contentTable={mountTable}
        pageInfo={pageInfo}
        changePage={handlePaginate}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        isLoading={isLoading}
        clickRowAction={goToEditRework}
      />
    );
  }, [mountTable, pageInfo, isLoading]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Programados',
        content: renderReworkTable,
      },
      {
        name: 'Cancelados',
        content: renderReworkTable,
      },
    ];
  }, [renderReworkTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} tabActive={tabActive} />;
};

export default ListReworkTable;
