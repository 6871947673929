import styled from 'styled-components';

export const LoadingStyled = styled.div``;

export const LoadingContainer = styled.div``;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
