import styled, { css } from 'styled-components';

export const WrapperAccordions = styled.section`
  position: relative;
  display: grid;
  grid-gap: 1rem;
`;

export const InfoLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const WrapperInfo = styled.div`
  padding-bottom: 0.62rem;
`;

type WrapperValuesProps = { columns: string };
export const WrapperValues = styled.div<WrapperValuesProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 15px;
  `}
`;

export const Info = styled.p`
  font-size: 12px;
  color: #000;
  font-weight: 700;
  padding: 5px 0;
  text-transform: uppercase;
`;

export const DropdownButtonTable = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;