/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { defaultFilter } from '../utils/filter';
import { convertReworkStatus } from '../utils/convert-rework-status';
import { downloadOrder, downloadPDF, reworkCancel, updateReworkStatusService } from '../services';
import { reworkListHook } from '../hooks/rework-list';
import { IScheduleReportExcelProps } from '../hooks/export-excel/types';
import { generateScheduleExcelHook } from '../hooks/export-excel';
import { acceptScheduleHook } from '../hooks/accept-schedule';
import { CancelAllocationForm } from '../components/modal-cancel';
import { FilterForm } from '../components/filter';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ISelectedReworks } from './types';
import * as S from './styled';
import ListReworkTable from './content-table/list';

import { addLeftZero } from 'utils-v2/converters/date/add-left-zero';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';
import { UserContext } from 'state/user-context';
import { ScheduleListParams } from 'services/schedule/types';
import { ReworkListParams } from 'services/rework/types';
import { queryClient } from 'services/query-client';
import { normalizeDate } from 'pages-v2/trips/utils/date';
import { convertField } from 'pages-v2/schedule/mappers/convert-filter';
import { IActiveTabName } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { FooterPage } from 'components-v2/common/footer-page';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import TooltipComponent from 'components/Tooltip';
import { Button } from 'logshare-ui-kit';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { NewFreight } from './styled';

const initialExcelParams = {} as IScheduleReportExcelProps;

export const ReWorkListPage: React.FC = () => {
  const getSessionStorage = sessionStorage.getItem('pagination-rework');
  const reactQueryClient = useQueryClient();

  const listParams: ScheduleListParams = getSessionStorage ? JSON.parse(getSessionStorage) : defaultFilter;

  const { user, isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const navigate = useNavigate();

  const goToCreateService = () => {
    navigate('/retrabalho/novo');
  };

  const goToEditRework = (id: string) => {
    navigate(`/retrabalho/atualizar/${id}`);
  };

  const [selectedSchedules, setSelectedSchedules] = useState<Array<ISelectedReworks>>([]);

  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [modalCancel, setModalCancel] = useState<boolean>(false);

  const [params, setParams] = useState<ScheduleListParams>(listParams);

  const handleSearch = (search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  };

  const handlePaginate = (page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  };

  const tabStatusCode = (activeTabName: string) => {
    if (activeTabName === 'Programados') {
      return ['SH01', 'OT10', 'AG10', 'OT01'];
    }

    if (activeTabName === 'Cancelados') {
      return ['AL99', 'OT99', 'AL90'];
    }

    return ['SH01', 'OT10', 'AG10', 'OT01'];
  };

  const renderStatusByTabName = (activeTabName: string) => {
    const statusCode = tabStatusCode(activeTabName);

    setParams((prevState) => ({
      ...prevState,
      statusCode,
      activeTabName: activeTabName as IActiveTabName,
      page: 1,
    }));
  };

  const { isLoading, data } = useQuery({
    queryKey: ['reworkList', params],
    queryFn: () => reworkListHook(params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const FilterFormRef = useRef<FormikProps<ReworkListParams>>(null);

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_recibo_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const fileNameOrder = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(2, 4);
    const day = addLeftZero(currentDate.getDate());
    const month = addLeftZero(currentDate.getMonth());
    const minute = addLeftZero(currentDate.getMinutes());
    const second = addLeftZero(currentDate.getSeconds());
    const formattedDate = `${year}${month}${day}_${minute}${second}`;
    return `Logshare_ordem_servico_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const handleExportExcel = useCallback(async () => {
    try {
      await generateScheduleExcelHook(initialExcelParams);
    } catch (error) {
      showToast({
        message: 'Erro ao tentar baixar o relatório',
        type: 'error',
      });
    }
  }, []);

  const handleGeneratePDF = useCallback(
    async (id: number) => {
      try {
        showMessageFormatted({
          message: 'Gerando recibo.',
          type: 'info',
        });

        const pdfFile = await downloadPDF(id);

        showMessageFormatted({
          message: 'Recibo gerado com sucesso.',
          type: 'success',
        });

        DownloadFile(pdfFile ?? '', 'pdf', fileNamePdf);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [fileNamePdf],
  );

  const handleGenerateOrder = useCallback(
    async (id: number) => {
      try {
        showMessageFormatted({
          message: 'Gerando ordem de serviço.',
          type: 'info',
        });

        const pdfFile = await downloadOrder(id);

        showMessageFormatted({
          message: 'Recibo gerado com sucesso.',
          type: 'success',
        });

        DownloadFile(pdfFile ?? '', 'pdf', fileNameOrder);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [fileNameOrder],
  );

  const acceptButton = selectedSchedules.some(
    (schedule) => schedule.status === 'Em Negociação' || schedule.status === 'Ag. Aceite Transportadora',
  );

  const cancelButton = selectedSchedules.some(
    (schedule) => schedule.status !== 'Em Negociação' && schedule.status !== 'Ag. Aceite Transportadora',
  );

  const typeCancel = useMemo(() => {
    if (!cancelButton) return 'backhaul';
    else return 'spot';
  }, [cancelButton]);

  const secondaryButton = useMemo(() => {
    if (acceptButton && !cancelButton) {
      return 'Aceitar';
    } else {
      return '';
    }
  }, [acceptButton, cancelButton]);

  const thirdButton = useMemo(() => {
    if (acceptButton && !cancelButton) {
      return 'Negar';
    } else if (!acceptButton && cancelButton && !isShippingCompany) {
      return 'Cancelar';
    } else {
      return '';
    }
  }, [acceptButton, cancelButton, isShippingCompany]);

  const paramsArray: Array<{ key: string; value: string | number }> = Object.entries(params).map(([key, value]) => ({
    key,
    value: convertField(key, value),
  }));

  const newParamsArray = paramsArray.filter(
    (item) =>
      (item.key !== 'search' || (item.key === 'search' && item.value !== '')) &&
      (item.key !== 'levyInitialDate' || (item.key === 'levyInitialDate' && item.value !== '')) &&
      (item.key !== 'levyEndDate' || (item.key === 'levyEndDate' && item.value !== '')) &&
      (item.key !== 'deliveryInitialDate' || (item.key === 'deliveryInitialDate' && item.value !== '')) &&
      (item.key !== 'deliveryEndDate' || (item.key === 'deliveryEndDate' && item.value !== '')) &&
      (item.key !== 'statusCode' || (item.key === 'statusCode' && item.value !== '')) &&
      !['page', 'column', 'order', 'activeTabName'].includes(item.key),
  );

  const deleteFilters = (indexToRemove: number) => {
    const updatedParamsArray = [...newParamsArray];
    updatedParamsArray.splice(indexToRemove, 1);
    let newStatusCode = tabStatusCode(params.activeTabName);

    if (updatedParamsArray.length === 0) {
      setParams({
        ...defaultFilter,
        activeTabName: params.activeTabName,
        statusCode: newStatusCode,
      });
      queryClient.invalidateQueries(['reworkList', defaultFilter]);
    } else {
      const updatedParams = updatedParamsArray.reduce((accumulator: any, { key }) => {
        accumulator[key] = params[key as keyof ScheduleListParams];
        return accumulator;
      }, {});

      if (updatedParams?.statusCode && updatedParams.statusCode.length === 1) {
        newStatusCode = updatedParams.statusCode;
      }

      setParams({
        ...updatedParams,
        page: 1,
        statusCode: newStatusCode,
      });

      queryClient.invalidateQueries(['reworkList', updatedParams]);
    }
  };

  const handleUpdateStatus = async (id: number, statusBefore: string) => {
    const newStatus = convertReworkStatus(statusBefore) || '';
    await updateReworkStatusService(id, newStatus).then(() => {
      showMessageFormatted({
        message: 'Status atualizado.',
        type: 'success',
      });

      reactQueryClient.invalidateQueries(['reworkList', params]);
    });
  };

  const onFilterSubmit = (values: ReworkListParams) => {
    const { levyInitialDateStart, levyInitialDateEnd, deliveryInitialStart, deliveryInitialEnd } = values;

    setParams({
      ...values,
      page: 1,
      activeTabName: params.activeTabName,
      levyInitialDateStart: normalizeDate(levyInitialDateStart),
      levyInitialDateEnd: normalizeDate(levyInitialDateEnd),
      deliveryInitialStart: normalizeDate(deliveryInitialStart),
      deliveryInitialEnd: normalizeDate(deliveryInitialEnd),
    });

    setModalFilter(false);
  };

  const handleCancelRework = async (values: { descriptionCancel: string }) => {
    try {
      await reworkCancel(
        selectedSchedules.map((selectedSchedule) => selectedSchedule.id),
        values.descriptionCancel,
      );

      showToast({
        message: 'Serviço excluido com sucesso',
        type: 'success',
      });
    } catch (error) {
      showToast({
        message: 'Erro ao excluir o serviço',
        type: 'error',
      });
    }

    queryClient.invalidateQueries(['reworkList', params]);

    setModalCancel(false);
    setSelectedSchedules([]);
  };

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      {modalFilter && (
        <ModalConfirmation
          title="Escolha o Filtro"
          description={
            <>
              <Formik innerRef={FilterFormRef} initialValues={params} onSubmit={onFilterSubmit}>
                <FilterForm />
              </Formik>
            </>
          }
          handleXCancel={() => setModalFilter(false)}
          fullButtonWidth
          isRight
          legacy
          dateIndex
          size="large"
          type="normal"
        />
      )}

      {modalCancel && (
        <Formik initialValues={{ descriptionCancel: '' }} onSubmit={handleCancelRework}>
          {(formikHelpers) => (
            <CancelAllocationForm
              ids={selectedSchedules}
              buttonLabel={typeCancel === 'spot' ? 'excluir' : 'negar'}
              closeModal={() => setModalCancel(false)}
              handleConfirm={formikHelpers.handleSubmit}
            />
          )}
        </Formik>
      )}

      <HeaderPage breadcrumbList={['Serviços']} />
      <Title title="Acompanhe o status dos serviços" hasTrace />

      <S.ContainerSearch>
        <Input
          id="search"
          name="search"
          placeholder="Pesquise pelo Cliente ou Tipo de Serviço"
          type="text"
          changeValue={(event) => handleSearch(event)}
          filters={newParamsArray.map((item) => item.value)}
          handleClearFilters={(indexToRemove) => deleteFilters(indexToRemove)}
          isClear={false}
        />

        <TooltipComponent
          icon={
            <Button
              disabled
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => setModalFilter(true)}
              LeftIcon={<FilterAltIcon sx={{ width: 22 }} />}
            />
          }
          title={'Filtrar'}
          location={'bottom'}
        />

        <TooltipComponent
          icon={
            <Button
              disabled
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={handleExportExcel}
              LeftIcon={<CloudDownloadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Exportar'}
          location={'bottom'}
        />

        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </S.ContainerSearch>

      <ListReworkTable
        handleUpdateStatus={handleUpdateStatus}
        reworks={data?.list || []}
        pageInfo={data?.pageInfo}
        isAdmin={isAdmin}
        setSelectedRework={setSelectedSchedules}
        selectedRework={selectedSchedules}
        isLoading={isLoading}
        handlePaginate={handlePaginate}
        handleGeneratePDF={handleGeneratePDF}
        handleGenerateOrder={handleGenerateOrder}
        orderSelected={{
          orderBy: params.column,
          sortDirection: params.order,
        }}
        clickOrderTableBy={(column: any) => {
          setParams((prevParams) => ({
            ...prevParams,
            column: column,
          }));
        }}
        clickSortDirection={() => {
          setParams((prevParams) => ({
            ...prevParams,
            order: params.order === 'ASC' ? 'DESC' : 'ASC',
          }));
        }}
        setActiveTabName={renderStatusByTabName}
        tabActive={params.activeTabName}
        goToEditRework={goToEditRework}
      />

      <FooterPage.Root>
        <FooterPage.RightContent>
          {!isShippingCompany && <FooterPage.Button label="Novo" color="blue" onPress={goToCreateService} />}
          {secondaryButton && (
            <FooterPage.Button
              label={secondaryButton}
              color="green"
              onPress={() => {
                acceptScheduleHook(selectedSchedules.map((selectedSchedule) => selectedSchedule.id));
                queryClient.invalidateQueries(['scheduleList', params]);
                setSelectedSchedules([]);
              }}
            />
          )}
          {thirdButton && <FooterPage.Button label={thirdButton} color="red" onPress={() => setModalCancel(true)} />}
        </FooterPage.RightContent>
      </FooterPage.Root>
    </>
  );
};
