import { IOccurrenceTrackingEntity } from '../entities/tracking-occurrence.entity';

import API from 'utils/API/API';
import { convertTrackingOccurrenceCreatedEntityToDomainMapper } from 'pages/tracking-occurrence/mappers/convert-tracking-occurrence-created-entity-to-domain/convert-tracking-occurrence-created-entity-to-domain.mapper';
import { ITrackingOccurrence, ITrackingOccurrenceStatus } from 'domain/tracking-occurrence';
import { IListPage, PageInfo } from 'domain/page-info';

export interface ITrackingOccurrenceListProps {
  search: string;
  page: number;
  status?: ITrackingOccurrenceStatus;
  order: string;
  column: string;
}

export interface ITrackingOccurrenceListEntity {
  list: Array<IOccurrenceTrackingEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const trackingOccurrenceListService = async ({
  page,
  search,
  status,
  order,
  column,
}: ITrackingOccurrenceListProps): Promise<IListPage<ITrackingOccurrence>> => {
  try {
    let url = `v2/occurrence-tracking?search=${search}&status=${status}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertTrackingOccurrenceCreatedEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar as ocorrências');
  }
};
