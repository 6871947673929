import React, { createContext, useContext, useState, useMemo } from 'react';

interface IModalContextProps {
  children: React.ReactNode;
}

interface ModalProps {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const initialState = {} as ModalProps;

const ModalContext = createContext<ModalProps>(initialState);

export const ModalProvider = ({ children }: IModalContextProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const contextValue = useMemo(
    () => ({
      isModalOpen,
      openModal,
      closeModal,
    }),
    [isModalOpen],
  );

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal deve ser usado dentro de um ModalProvider');
  }

  return context;
};
