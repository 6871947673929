import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { buyMatchService } from './service/buy-match';
import { ModalFormSubTitle } from './components/sub-title';
import { MatchForm } from './components/form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { retentionFinesService } from 'pages/company/services/retention-fines/retention-fines.service';
import { MatchRegisterInput } from 'domain-v2/match/register';
import { IdlenessValues } from 'domain/idleness';
import { ICompany, IRetention } from 'domain/company';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { modalMatchInitialValues } from 'components-v2/common/modals/match-form/types/initial-values';
import { ModalMatchDetails } from 'components-v2/common/modals/match-form/types';
import { IPurchaseOfferListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list.domain';
import { matchRegisterInitialValues } from 'domain-v2/match/register/initial-values';
import { convertPurchaseOfferToMatchRegister } from './mappers/convert-purchase-offer-to-match-register/convert-purchase-offer-to-match-register.mapper';
import { convertMatchDetailsToMatchRegister } from './mappers/convert-match-details-to-match-register/convert-match-details-to-match-register.mapper';

interface ModalMatchFormProps {
  buyId?: number;
  saleOfferValues: ModalMatchDetails;
  idleness?: IdlenessValues;
  purchaseOffer?: IPurchaseOfferListDomain;
  exclusiveRate: boolean;
  matchesKanban?: boolean;
  companyLogo?: string;
  handleClose: () => void;
}

export const ModalMatchForm: FC<ModalMatchFormProps> = ({
  buyId,
  saleOfferValues,
  idleness,
  purchaseOffer,
  exclusiveRate,
  matchesKanban,
  companyLogo,
  handleClose,
}) => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const LOGSHARE_ID = 2;

  const [company, setCompany] = useState<ICompany>();
  const [userCompany, setUserCompany] = useState<ICompany>();
  const [requirements, setRequirements] = useState<Array<string> | undefined>(undefined);

  const getRetentionByVehicleType = async (retentions: IRetention[], vehicleType: string) => {
    return retentions?.find((retention: { vehicleType: string }) => retention.vehicleType == vehicleType);
  };

  const setCompanyRetentionWithLogshareValues = useCallback(async (companyFound: ICompany, vehicleType: string) => {
    const logshareRetentions = await retentionFinesService(LOGSHARE_ID);
    const logshareRetentionFound = await getRetentionByVehicleType(logshareRetentions, vehicleType);
    companyFound.freeTime = logshareRetentionFound?.freeTime ?? '0';
    companyFound.hourlyFine = logshareRetentionFound?.hourlyFine ?? 0;
    companyFound.dailyFine = logshareRetentionFound?.dailyFine ?? 0;
  }, []);

  useEffect(() => {
    (async () => {
      const findCompany = await companyDetailsService(saleOfferValues.company.id);
      const findUserCompany = await companyDetailsService(user?.companyId ?? 0);

      const companyRetentions = await retentionFinesService(Number(findCompany.id));
      const companyRetentionFound = await getRetentionByVehicleType(companyRetentions, 'carreta');

      if (!companyRetentionFound) {
        await setCompanyRetentionWithLogshareValues(findCompany, saleOfferValues.vehicle.type);
      } else {
        findCompany.freeTime = companyRetentionFound?.freeTime ?? '0';
        findCompany.hourlyFine = companyRetentionFound?.hourlyFine ?? 0;
        findCompany.dailyFine = companyRetentionFound?.dailyFine ?? 0;
      }

      setCompany(findCompany);
      setUserCompany(findUserCompany);
    })();
  }, [
    exclusiveRate,
    saleOfferValues.company.id,
    saleOfferValues.vehicle.type,
    setCompanyRetentionWithLogshareValues,
    user,
  ]);

  const handleSubmit = useCallback(
    async (values: MatchRegisterInput) => {
      setIsLoading(true);
      try {
        showMessageFormatted({
          message: 'Realizando compra...',
          type: 'info',
        });

        await buyMatchService(
          {
            ...values,
            companySeller: saleOfferValues.company.id,
            saleOfferId: saleOfferValues.saleOfferId,
            saleOfferShipperTarifId: saleOfferValues.saleOfferTariffId,
            matchOfferId: Number(buyId),
          },
          saleOfferValues,
          idleness,
        );

        showMessageFormatted({
          message: 'Compra realizada com sucesso.',
          type: 'success',
        });

        setIsLoading(false);
        handleClose();
      } catch (error: any) {
        setIsLoading(false);
        showMessageFormatted({
          message: '',
          error,
          type: 'error',
        });
      }
    },
    [buyId, handleClose, idleness, saleOfferValues],
  );

  const matchInitialValues = useMemo((): MatchRegisterInput => {
    if (purchaseOffer) {
      return convertPurchaseOfferToMatchRegister(saleOfferValues, purchaseOffer);
    }

    if (matchesKanban) {
      return convertMatchDetailsToMatchRegister(saleOfferValues);
    }

    return matchRegisterInitialValues;
  }, [matchesKanban, purchaseOffer, saleOfferValues]);

  return (
    <ModalBark
      size="lg"
      title={`Compra de Backhaul - Oferta ID ${saleOfferValues.saleOfferTariffId}`}
      subtitle={
        <ModalFormSubTitle
          haulType={saleOfferValues.backhaulPartner ? 'BACKHAUL PARCEIRO' : saleOfferValues.haulType}
          scheduleType={saleOfferValues.typeSchedule}
          operationType={saleOfferValues.operationType}
          co2={modalMatchInitialValues.co2Economy}
          requirements={requirements}
          exclusiveRate={exclusiveRate || saleOfferValues.backhaulPartner}
        />
      }
      handleClose={handleClose}
    >
      <MatchForm
        handleSubmit={handleSubmit}
        transportRouter={saleOfferValues}
        matchInitialValues={matchInitialValues}
        idleness={idleness}
        companyLogo={companyLogo}
        setRequirements={setRequirements}
        company={company}
        userCompany={userCompany}
        user={user}
        isLoading={isLoading}
      />
    </ModalBark>
  );
};
