import { FC, useMemo } from 'react';
import * as S from './modal-backhaul-purchase-form-view.styled';
import { IValidateDates } from 'src-new/utils/validate-dates/validate-dates.types';
import { Button } from 'logshare-ui-kit';
import { OriginAndDestinyBackhaulPurchaseForm } from './components/origin-and-destiny-backhaul-purchase-form/origin-and-destiny-backhaul-purchase-form.component';
import { CargoDetailsBackhaulPurchaseForm } from './components/cargo-details-backhaul-purchase-form/cargo-details-backhaul-purchase-form.component';
import { DetailsBackhaulPurchaseForm } from './components/details-backhaul-purchase-form/details-backhaul-purchase-form.component';
import { SecurityRequirementsBackhaulPurchaseForm } from './components/security-requirements-backhaul-purchase-form/security-requirements-backhaul-purchase-form.component';
import { SaleOfferDetailsBackhaulPurchaseForm } from './components/sale-offer-backhaul-purchase-form/sale-offer-backhaul-purchase-form.component';
import { ModalBark } from 'components-v2/layout/modal-bark';
import { ModalBackhaulPurchaseSubtitle } from '../modal-backhaul-purchase-subtitle/modal-backhaul-purchase-subtitle.component';
import { OperationsDomain } from 'src-new/domains/operations.domain';
import { IModalBackhaulPurchaseForm } from '../../types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from '../../types/modal-backhaul-purchase/modal-backhaul-purchase.types';

interface IModalBackhaulPurchaseFormViewProps {
  backhaulPurchase: IBackhaulPurchase;
  values: IModalBackhaulPurchaseForm;
  hasError: boolean;
  datesError: Array<IValidateDates>;
  handleClose: () => void;
  handleValidation: () => void;
  handleDatesError: (dates: Array<IValidateDates>) => void;
}

export const ModalBackhaulPurchaseFormView: FC<IModalBackhaulPurchaseFormViewProps> = ({
  backhaulPurchase,
  values,
  hasError,
  datesError,
  handleClose,
  handleValidation,
  handleDatesError,
}) => {
  const haulType = useMemo(
    (): OperationsDomain =>
      backhaulPurchase.saleOffer.backhaulPartner ? 'BACKHAUL PARCEIRO' : backhaulPurchase.saleOffer.haulType,
    [backhaulPurchase.saleOffer.backhaulPartner, backhaulPurchase.saleOffer.haulType],
  );

  return (
    <ModalBark
      title={`Compra de Backhaul - Oferta ID ${backhaulPurchase.saleOffer.saleOfferTariffId}`}
      subtitle={
        <ModalBackhaulPurchaseSubtitle
          backhaulPurchase={backhaulPurchase}
          haulType={haulType}
          co2={values.co2Economy}
          requirements={values.cargo.requirements}
        />
      }
      size="lg"
      handleClose={handleClose}
    >
      <S.WrapperModalMatchForm>
        <S.WrapperForm>
          <S.SectionForm>
            <S.TitleForm>ORIGEM E DESTINO</S.TitleForm>
            <S.LocalityContainer>
              <OriginAndDestinyBackhaulPurchaseForm type="origin" hasError={hasError} />
              <OriginAndDestinyBackhaulPurchaseForm type="destination" hasError={hasError} />
            </S.LocalityContainer>
          </S.SectionForm>
          <S.SectionForm>
            <S.TitleForm>DETALHES DA CARGA</S.TitleForm>
            <CargoDetailsBackhaulPurchaseForm />
          </S.SectionForm>
          <S.SectionForm>
            <S.TitleForm>DETALHES DO AGENDAMENTO</S.TitleForm>
            <DetailsBackhaulPurchaseForm datesError={datesError} handleDatesError={handleDatesError} />
          </S.SectionForm>
          <S.SectionForm>
            <S.TitleForm>REQUISITOS ADICIONAIS</S.TitleForm>
            <SecurityRequirementsBackhaulPurchaseForm />
          </S.SectionForm>
        </S.WrapperForm>
        <S.ProposedContainer>
          <S.WrapperPaper>
            <SaleOfferDetailsBackhaulPurchaseForm backhaulPurchase={backhaulPurchase} hasError={hasError} />
          </S.WrapperPaper>
          <S.WrapperButton>
            <Button label="Agendar" color="blue" onPress={handleValidation} size="md" variant="solid" />
          </S.WrapperButton>
        </S.ProposedContainer>
      </S.WrapperModalMatchForm>
    </ModalBark>
  );
};
