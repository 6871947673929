import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 20px;
  background-color: #05050581;
  z-index: 9999;
  color: #000;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 20px 20px;
  min-height: 237px;
  max-height: 600px;
  width: 500px;
  overflow-y: auto;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloseModal = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.font.bold};
    padding-bottom: 10px;
  `}
`;

export const SecondTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  padding-right: 45px;
`;

export const LinkText = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    text-decoration: underline;
  }
`;

export const TermsText = styled.div`
  display: inline;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  padding-left: 10px;
`;

type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 12px;
    align-items: end;
  `}
`;

export const CheckboxSection = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 10px;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`;
