import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  padding-right: 0.75rem;

  height: 78vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
`;

export const SectionStyled = styled.div`
  margin-bottom: 1rem;
`;
