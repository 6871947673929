import { convertTypeEntityToDomainMapper } from '../convert-type-entity-to-domain/convert-type-entity-to-domain.mapper';

import { ICompanyEntity } from 'pages/company/services/entities/company.entity';
import { ICompany } from 'domain/company';

export const convertCompanyEntityToDomain = (companyEntity: ICompanyEntity): ICompany => {
  const plan = companyEntity.plan === 'basic-seller' ? 'basic-buyer' : companyEntity.plan;

  return {
    id: companyEntity.id,
    name: companyEntity.name,
    cnpj: companyEntity.cnpj,
    plan,
    companysId: companyEntity.companysId.map((item: number) => String(item)),
    status: companyEntity.status,
    type: convertTypeEntityToDomainMapper(companyEntity.type),
    contacts: companyEntity.contacts,
    tariff: companyEntity.tariff,
    email: companyEntity.email,
    ie: companyEntity.ie,
    login: companyEntity.login,
    tradeName: companyEntity.tradeName,
    contentFile: companyEntity.contentFile,
    file: companyEntity.file,
    fileKey: companyEntity.fileKey,
    cep: companyEntity.cep,
    city: companyEntity.city,
    neighborhood: companyEntity.neighborhood,
    street: companyEntity.street,
    numberHouse: companyEntity.numberHouse,
    complement: companyEntity.complement,
    uf: companyEntity.uf,
    retentionFines: companyEntity.retentionFines
      ? companyEntity.retentionFines
      : [
          {
            id: '0',
            companyId: 0,
            freeTime: '',
            hourlyFine: 0,
            dailyFine: 0,
            vehicleType: '',
            vehicleCategory: '',
            created_at: '',
          },
        ],
    paymentTerm: companyEntity.paymentTerm,
    modalityPayment: companyEntity.modalityPayment,
    receiptDeadline: companyEntity.receiptDeadline,
    modalityDeadline: companyEntity.modalityDeadline,
    closingCycle: companyEntity.closingCycle,
    shippingCompanyId: companyEntity.shippingCompanyId,
    shippingCompany: companyEntity.shippingCompany,
    feeLogshare: String(companyEntity.feeLogshare),
    feeLogshareWithoutCTe: String(companyEntity.feeLogshareWithoutCTe),
    advalorem: companyEntity.advalorem,
    gris: companyEntity.gris,
    hasDdr: companyEntity.hasDdr,
    riskManager: companyEntity.riskManager,
  };
};
