import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { deleteSchedulesService } from '../../services/delete-schedules/delete-schedules.service';
import { useQueryClient } from 'react-query';

export const useDeleteSchedules = () => {
  const { scheduleList } = useContext(ScheduleContext);

  const reactQueryClient = useQueryClient();

  return useCallback(async () => {
    try {
      const checkboxScheduleIds = scheduleList.scheduleListCheckbox.checkbox.map((checkbox) => checkbox.id);

      await deleteSchedulesService(checkboxScheduleIds);

      showMessageFormatted({
        message: 'Viagem(ns)  Finalizada(s).',
        type: 'success',
      });

      scheduleList.scheduleListCheckbox.setScheduleListCheckbox([]);

      await reactQueryClient.invalidateQueries(['schedule-list-table', scheduleList.scheduleListFiltersProps.filters]);
      await reactQueryClient.invalidateQueries(['schedule-kanban-list', scheduleList.scheduleListFiltersProps.filters]);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao tentar baixar o relatório.',
        type: 'error',
      });
    }
  }, [reactQueryClient, scheduleList.scheduleListCheckbox, scheduleList.scheduleListFiltersProps.filters]);
};
