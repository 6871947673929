import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/Info';

import * as S from './styled';
import PendingOccurrenceList from './modal-occurrence/list';

import { convertHaulType } from 'utils-v2/converters/haul-type';
import { convertStatusCode } from 'utils/status-code';
import { formatDecimal } from 'utils/format-numbers';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { getScheduleByIdService } from 'pages-v2/schedule/services/get-schedule-by-id';
import { ITrackingOccurrence } from 'domain/tracking-occurrence';
import { ScheduleDomain } from 'domain/schedule';
import Pagination from 'components/pagination/pagination';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import TooltipComponent from 'components/Tooltip';
import { PendingOccurrenceIcon } from 'assets/icons/pending-occurrence';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';

interface IProps {
  values: ScheduleDomain;
  occurrences?: {
    items: ITrackingOccurrence[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };
  handleDetailsPage: (id: string) => void;
  handleChangesPage: (page: number) => void;
}

const ServiceDetails: React.FC<IProps> = ({ values, occurrences, handleDetailsPage, handleChangesPage }) => {
  const navigate = useNavigate();
  const [descriptionCancel, setDescriptionCancel] = useState('');

  const { user, isAdmin } = useContext(UserContext);

  const [modalPendingOccurrence, setModalPendingOccurrence] = useState<boolean>(false);

  const scheduleColors: {
    [key: string]: { color: ColorsV2Type; text: string };
  } = {
    ftl: { color: ColorsV2Type.Blue, text: 'FTL' },
    stl: { color: ColorsV2Type.Blue100, text: 'STL' },
    ltl: { color: ColorsV2Type.Aqua100, text: 'LTL' },
    INBOUND: { color: ColorsV2Type.Blue, text: 'IN' },
    OUTBOUND: { color: ColorsV2Type.Aqua, text: 'OUT' },
    TRANSFERENCIA: { color: ColorsV2Type.Red, text: 'TR' },
  };

  const IsOnTime = useMemo(() => {
    const checkTime =
      values.statusCode !== 'IN90' &&
      moment(values.scheduleDetails?.deliveryEndDate || 0).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
        ? 'Em Atraso'
        : 'No Prazo';

    return checkTime;
  }, [values.scheduleDetails?.deliveryEndDate]);

  const renderHeaderTags = useMemo(() => {
    let typeSchedule = <></>;
    let operationType = <></>;
    let operation = <></>;
    let co2Economy = <></>;

    if (values.typeSchedule) {
      typeSchedule = (
        <Tag
          label={scheduleColors[values.typeSchedule].text}
          color={scheduleColors[values.typeSchedule].color}
          variant="solid"
          display="flex"
          size="md"
        />
      );
    }

    if (values.scheduleCargo?.operationType) {
      operationType = (
        <Tag
          label={scheduleColors[values.scheduleCargo?.operationType].text}
          color={scheduleColors[values.scheduleCargo?.operationType].color}
          variant="ghost"
          display="flex"
          size="md"
        />
      );
    }

    if (values.operation) {
      operation = (
        <Tag
          label={convertHaulType(values.operation).label}
          color={convertHaulType(values.operation).color}
          variant="solid"
          display="flex"
          size="md"
        />
      );
    }

    if (values.co2Economy) {
      co2Economy = (
        <Tag
          LeftIcon={
            <S.TagText>
              <DoubleArrowDownIcon />
              CO₂
            </S.TagText>
          }
          label={`${formatDecimal(Number(values.co2Economy))} KG`}
          color={ColorsV2Type.Aqua}
          variant="ghost"
          display="inline-flex"
          size="md"
        />
      );
    }

    return { typeSchedule, operationType, operation, co2Economy };
  }, [values.typeSchedule, values.scheduleCargo?.operationType, values.operation, values.co2Economy]);

  const navigateToCompanyDetails = useCallback((locationId: string) => {
    navigate(`/unidades/atualizar/${locationId}`);
  }, []);

  useEffect(() => {
    if (values.statusCode === 'AL99' || values.statusCode === 'AL90') {
      getScheduleByIdService(values.id).then((res) => {
        setDescriptionCancel(res.descriptionCancel);
      });
    }
  });

  return (
    <>
      {modalPendingOccurrence && (
        <ModalConfirmation
          title={`Ocorrências Frete ${values.id}`}
          description={
            <>
              <PendingOccurrenceList
                occurrencesTracking={occurrences?.items || []}
                hasLoading={false}
                goToUpdatePage={handleDetailsPage}
              />
              <Pagination
                activePage={occurrences?.meta.currentPage || 1}
                changePage={handleChangesPage}
                totalPage={occurrences?.meta.totalPages || 0}
              />
            </>
          }
          handleXCancel={() => setModalPendingOccurrence(false)}
          size="large"
          type="normal"
        />
      )}
      <S.Wrapper>
        <div>
          <S.HeaderTitle>Detalhes do Serviço</S.HeaderTitle>
          <S.HeaderTags>
            {renderHeaderTags.typeSchedule}

            {renderHeaderTags.operationType}

            {renderHeaderTags.operation}

            {renderHeaderTags.co2Economy}

            {values?.cteIssuer && user?.profile !== 'shipping-company' && (
              <Tag label={values?.cteIssuer} color={ColorsV2Type.Red} variant="ghost" display="flex" size="md" />
            )}
          </S.HeaderTags>
        </div>

        <S.SectionContainer>
          {values.transportRouteId && (
            <S.ContainerInfoStyled>
              <span>Oferta de Venda</span>
              <S.Info>{values.transportRouteId}</S.Info>
            </S.ContainerInfoStyled>
          )}

          {isAdmin &&
            ['BACKHAUL INTERNO', 'BACKHAUL EXTERNO', 'BACKHAUL PARCEIRO'].includes(values.operation) &&
            values?.companyBuyer && (
              <S.ContainerInfoStyled cursor="pointer" onClick={() => navigateToCompanyDetails(values.companyBuyer.id)}>
                <S.IconSpan>
                  Comprador
                  <OpenInNewIcon sx={{ fontSize: 12, marginLeft: 0.5 }} />
                </S.IconSpan>
                <S.Info>{values?.companyBuyer?.tradeName}</S.Info>
              </S.ContainerInfoStyled>
            )}

          <S.ContainerInfoStyled>
            <span>Operado Por</span>
            <S.Info>{values?.currentFreight === 2 ? values.company?.tradeName : 'Logshare' || '-'}</S.Info>
          </S.ContainerInfoStyled>

          <S.ContainerInfoStyled>
            <span>OnTime</span>
            <S.Info>{IsOnTime}</S.Info>
          </S.ContainerInfoStyled>

          <S.ContainerInfoStyled>
            <S.TitleInfo>
              <span>Status</span>
              {descriptionCancel && (
                <TooltipComponent
                  title={descriptionCancel}
                  location="bottom"
                  icon={<InfoIcon sx={{ width: 13, color: 'gray' }} />}
                />
              )}
            </S.TitleInfo>
            <S.Info>{convertStatusCode(values.statusCode)}</S.Info>
          </S.ContainerInfoStyled>

          {occurrences?.items.length ? (
            <S.ContainerInfoStyled style={{ marginLeft: -27 }} onClick={() => setModalPendingOccurrence(true)}>
              <PendingOccurrenceIcon />
            </S.ContainerInfoStyled>
          ) : undefined}
        </S.SectionContainer>
      </S.Wrapper>
    </>
  );
};

export default React.memo(ServiceDetails);
