import styled, { css } from 'styled-components';

export const WrapperAccordionsStyled = styled.main`
  width: 100%;

  display: grid;
  grid-gap: 1rem;
`;

export const DriverAccordionSubtitleStyled = styled.h5`
  ${({ theme }) => css`
    font-weight: 400;
    color: ${theme.colorsV2.red};
  `}
`;
