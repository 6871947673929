import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { generateMapReportFileService } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/generate-map-report-file/generate-map-report-file.service';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { IScheduleMapReportDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-map-report.domain';
import { convertTruckRouteToMapReportDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-generate-map-report-file/mappers/convert-truck-route-to-map-report-domain/convert-truck-route-to-map-report-domain.mapper';

export const useGenerateMapReportFile = () => {
  const { scheduleDownloadMapReport, scheduleDetails, scheduleExecution } = useContext(ScheduleContext);
  const {
    setScheduleDownloadMapReportRequest,
    setScheduleDownloadMapReportSuccess,
    setScheduleDownloadMapReportError,
  } = scheduleDownloadMapReport;

  const getFirebaseRoutes = useCallback((): Array<IScheduleMapReportDomain> => {
    const plate = scheduleDetails.scheduleDetails.vehicle?.mainBoard ?? '';
    const scheduleId = scheduleDetails.scheduleDetails.id.toString() ?? '';
    const firebaseTruckRoutes = scheduleExecution.mapAccordionProps.firebaseTruckRoutes;
    const routes = firebaseTruckRoutes.routes;

    if (routes.length) {
      const initialPosition = routes[0];
      const lastPosition = routes[routes.length - 1];
      return [
        convertTruckRouteToMapReportDomain(initialPosition, scheduleId, plate, 'APP'),
        ...firebaseTruckRoutes.routePositions.map((route) =>
          convertTruckRouteToMapReportDomain(route, scheduleId, plate, 'APP'),
        ),
        convertTruckRouteToMapReportDomain(lastPosition, scheduleId, plate, 'APP'),
      ];
    }

    return [];
  }, [
    scheduleDetails.scheduleDetails.id,
    scheduleDetails.scheduleDetails.vehicle?.mainBoard,
    scheduleExecution.mapAccordionProps.firebaseTruckRoutes,
  ]);

  const getTrackingRoutes = useCallback((): Array<IScheduleMapReportDomain> => {
    const plate = scheduleDetails.scheduleDetails.vehicle?.mainBoard ?? '';
    const scheduleId = scheduleDetails.scheduleDetails.id.toString() ?? '';
    const monitoringTruckRoutes = scheduleExecution.mapAccordionProps.monitoringTruckRoutes;
    const routes = monitoringTruckRoutes.routes;

    if (routes.length) {
      const initialPosition = routes[0];
      const lastPosition = routes[routes.length - 1];
      return [
        convertTruckRouteToMapReportDomain(initialPosition, scheduleId, plate, 'RASTREADOR'),
        ...monitoringTruckRoutes.routePositions.map((route) =>
          convertTruckRouteToMapReportDomain(route, scheduleId, plate, 'RASTREADOR'),
        ),
        convertTruckRouteToMapReportDomain(lastPosition, scheduleId, plate, 'RASTREADOR'),
      ];
    }

    return [];
  }, [
    scheduleDetails.scheduleDetails.id,
    scheduleDetails.scheduleDetails.vehicle?.mainBoard,
    scheduleExecution.mapAccordionProps.monitoringTruckRoutes,
  ]);

  const mountReportObjects = useCallback((): Array<IScheduleMapReportDomain> => {
    const firebaseRoutes: Array<IScheduleMapReportDomain> = getFirebaseRoutes();
    const trackingRoutes: Array<IScheduleMapReportDomain> = getTrackingRoutes();

    return [...firebaseRoutes, ...trackingRoutes];
  }, [getFirebaseRoutes, getTrackingRoutes]);

  return useCallback(async (): Promise<void> => {
    setScheduleDownloadMapReportRequest();

    try {
      const file: BlobPart = await generateMapReportFileService(mountReportObjects());

      downloadXlsxFileUtil(file, `relatorio-rotas-mapa-agendamento-${scheduleDetails.scheduleDetails.id}`);
      setScheduleDownloadMapReportSuccess();
      showMessageFormatted({
        message: 'Download realizado com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      setScheduleDownloadMapReportError();
      showMessageFormatted({
        message: 'Ocorreu um erro ao realizar o donwload. Por favor, tente novamente!',
        type: 'error',
      });
    }
  }, [
    mountReportObjects,
    scheduleDetails.scheduleDetails.id,
    setScheduleDownloadMapReportError,
    setScheduleDownloadMapReportRequest,
    setScheduleDownloadMapReportSuccess,
  ]);
};
