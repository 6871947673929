import React, { ReactNode, useContext, useMemo } from 'react';

import DriverTableView from './driver-table-view';

import { UserContext } from 'state/user-context';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IVehicleTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToUpdatePage: (driverId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const DriverTable: React.FC<IVehicleTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToUpdatePage,
  isLoading,
  arrowOrder,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}) => {
  const { isAdmin } = useContext(UserContext);
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.2,
      },
      {
        value: 'ID',
        flex: 0.5,
      },
      {
        value: isAdmin ? 'Parceiro' : ' ',
        flex: isAdmin ? 0.8 : 0,
      },
      {
        value: 'Nome',
        flex: 2.2,
      },
      {
        value: 'Categoria',
        flex: 0.8,
      },
      {
        value: 'Cidade',
        flex: 1.3,
      },
      {
        value: 'Status',
        flex: 0.45,
      },
    ];
  }, []);

  return (
    <DriverTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      clickRowAction={goToUpdatePage}
      isLoading={isLoading}
      clickOrderBy={clickOrderBy}
      clickSortDirection={clickSortDirection}
    />
  );
};

export default DriverTable;
