import { ScheduleRegisterInput } from 'domain-v2/schedule/register';

export const scheduleInitialValues: ScheduleRegisterInput = {
  origin: {
    id: 0,
    type: '',
    origin: {
      id: 0,
      clientIds: [],
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      outbound: undefined as any,
      internalCode: '',
      occurrenceEmail: [],
      quality_and_safety: '',
      schedulingEmail: [],
      status: 'active',
      type: '',
      name: '',
      vehicleCategoryType: [],
      vehicleRestriction: [],
      operationType: [],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
    tradeName: '',
  },
  destination: {
    id: 0,
    type: '',
    destination: {
      id: 0,
      clientIds: [],
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      outbound: undefined as any,
      internalCode: '',
      occurrenceEmail: [],
      quality_and_safety: '',
      schedulingEmail: [],
      status: 'active',
      type: '',
      name: '',
      vehicleCategoryType: [],
      vehicleRestriction: [],
      operationType: [],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
    tradeName: '',
  },
  cargo: {
    vehicleType: '',
    vehicleCategoryType: '',
    shippingReturn: '',
    product: '',
    weight: 0,
    cubagem: 0,
    volume: 0,
    merchValue: 0,
    inPallet: false,
    pallet: 0,
    order: '',
    comments: '',
    requirements: {
      quality: {
        id: 0,
        values: [],
      },
      security: {
        id: 0,
        values: [],
      },
      custom: {
        id: 0,
        values: [],
      },
    },
  },
  detail: {
    levyInitialDate: '',
    levyEndDate: '',
    deliveryInitialDate: '',
    deliveryEndDate: '',
    internalNumber: '',
    ocInternalNumber: '',
    boardingNumber: '',
    schedulingPassword: '',
    dateOfDeliveryLogShare: '',
    dateToShowDeliveryCustomTariff: '',
  },
  cost: {
    valueFreight: 0,
    valueGoods: 0,
    valueTotalFee: 0,
    taxes: 0,
    totalFreight: 0,
    helperTotal: 0,
    toll: 0,
    totalGrisAdvalorem: 0,
    totalService: 0,
    fine: 0,
    leadTime: 0,
    icms: 0,
  },
  distance: 0,
  leadTime: 0,
  operation: 'TRACKING',
  retentionFines: {
    freeTime: '',
    vehicleType: '',
    hourlyFine: 0,
    dailyFine: 0,
  },
  backhaul: {
    transportRoutes: [],
    saleOfferMatches: [],
    bestPrice: 0,
    sellId: 0,
    companySellerId: 0,
    invoicedBy: '',
    helperPrice: 0,
    tollPrice: 0,
    grisAdvaloremPrice: 0,
    taxPrice: 0,
    icms: 0,
    totalService: 0,
    totalFreight: 0,
    freightValue: 0,
    matchesProposal: {
      matches: [],
      meta: {
        currentPage: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
      },
      proposal: {
        capacity: {
          pallet: 0,
          weight: 0,
        },
        company: {
          companiesId: [],
          id: 0,
          name: '',
          image: '',
        },
        destiny: {
          city: '',
          id: 0,
          name: '',
          type: '',
          uf: '',
        },
        distance: 0,
        freightValue: 0,
        frequency: 0,
        id: 0,
        matchCounter: 0,
        operationType: '',
        origin: {
          city: '',
          id: 0,
          name: '',
          type: '',
          uf: '',
        },
        proposalType: 'backhaul',
        scheduleType: 'FTL',
        vehicle: {
          category: '',
          type: '',
        },
        fleetType: '',
      },
      totalRow: {
        externalBackhaul: 0,
        internalBackhaul: 0,
      },
    },
  },
  customFields: [],
};
