import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { IBackhaulPurchasePayload } from 'src-new/services/send-backhaul-purchase/send-backhaul-purchase.types';

export const convertFormToPayload = (
  formValues: IModalBackhaulPurchaseForm,
  backhaulPurchase: IBackhaulPurchase,
): IBackhaulPurchasePayload => {
  const { origin, destination, cargo, haulType, cost, dates, matchOfferId, retentionFines } = formValues;
  const { saleOffer } = backhaulPurchase;

  return {
    matchOfferId: matchOfferId,
    saleOfferId: saleOffer.saleOfferId,
    saleOfferTariffId: saleOffer.saleOfferTariffId,
    companySeller: saleOffer.company.id,
    origin: {
      id: origin.id,
      type: origin.type,
    },
    destination: {
      id: destination.id,
      type: destination.type,
    },
    cargo: {
      vehicleType: convertVehicleTypeToEntity(cargo.vehicle.type),
      vehicleCategoryType: convertVehicleCategoryToEntity(cargo.vehicle.category),
      shippingReturn: cargo.shippingReturn,
      product: cargo.product,
      weight: Number(cargo.capacity.weight),
      cubagem: Number(cargo.capacity.cubage),
      volume: Number(cargo.capacity.volume),
      merchValue: cargo.merchValue,
      inPallet: Number(cargo.capacity.inPallet) > 0,
      pallet: cargo.capacity.pallets,
      order: cargo.order,
      comments: cargo.comments,
      requirements: {
        quality: cargo.requirements.quality?.values ?? [],
        security: cargo.requirements.security?.values ?? [],
        custom: cargo.requirements.custom?.values ?? [],
      },
    },
    dates: dates,
    invoicedBy: saleOffer.invoicedBy.toUpperCase(),
    cost: {
      freightValue: cost.freightValue,
      tollValue: cost.tollValue,
      grisAdvalorem: cost.grisAdvalorem,
      freightTotal: cost.freightTotal,
      icms: cost.icms,
      totalService: cost.totalService,
      otherTaxes: cost.otherTaxes ?? 0,
      surplusValues: cost.surplusValue,
    },
    haulType: haulType,
    retentionFines: {
      freeTime: retentionFines.freeTime,
      dailyFine: retentionFines.dailyFine,
      hourlyFine: retentionFines.hourlyFine,
    },
  };
};
