import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const Label = styled.span`
  font-size: 0.75rem;
`;

export const DropDownButton = styled(Button)`
  ${({ theme }) => css`
    height: 36px;
    max-width: 44px !important;
    min-width: 44px !important;
    background-color: ${theme.colorsV2.white} !important;
    border: 1px solid ${theme.colorsV2.forBorder} !important;
    cursor: pointer;
    box-shadow: none !important;

    &:active {
      box-shadow: rgb(204, 204, 204) 0px 0px 0px 0.2px inset !important;
    }

    &:hover {
      opacity: 0.88 !important;
    }
  `}
`;
