import styled, { css } from 'styled-components';

interface StyleProps {
  isBold?: boolean;
}

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`;

export const WrapperTitleProposalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const TitleForm = styled.h4`
  font-size: 0.75rem;
  font-weight: 700;
  padding: -0.5rem 0 0.2rem 0;
`;

export const WrapperOfferDetails = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TitleValues = styled.h6``;

export const LocationContainer = styled.div`
  width: 157px;
`;

export const TextValue = styled.p<StyleProps>`
  ${({ isBold = false }) => css`
    font-size: 0.7rem;
    font-weight: ${isBold && 700};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const SubTitle = styled.span<StyleProps>`
  ${({ isBold = false }) => css`
    font-size: 10px;
    font-weight: ${isBold && 700};
  `}
`;

export const WrapperOfferValues = styled.section`
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
  padding-top: 0.5rem;
`;

export const WrapperCompanyImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
`;

export const WrapperTooltip = styled.div`
  display: flex;
  gap: 5px;
`;

export const Div = styled.div``;

export const TextTooltip = styled.p`
  font-size: 11px;
`;
