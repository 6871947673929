import { FC } from 'react';
import { SaleOfferRegisterForm } from '../sale-offer-register-form/sale-offer-register-form.component';
import * as S from './sale-offer-register-page-body-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Title from 'components/title/title';

export const SaleOfferRegisterPageBodyView: FC = () => {
  return (
    <S.Wrapper>
      <HeaderPage breadcrumbList={['Backhaul', 'Oferta de Venda']} hasBackButton />
      <Title title="Nova Oferta" hasTrace isForm />
      <SaleOfferRegisterForm />
    </S.Wrapper>
  );
};
