import { useCallback, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import {
  getSaleOfferListService,
  SaleOfferListServiceResponse,
} from '../../services/get-sale-offer-list/get-sale-offer-list.service';
import { convertSaleOfferListResponseToDomain } from './mappers/convert-sale-offer-list-response-to-domain/convert-sale-offer-list-response-to-domain.mapper';
import { ISaleOfferListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list.domain';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const useGetSaleOfferList = () => {
  const { saleOfferList } = useContext(SaleOfferContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const queryClient = useQueryClient();
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { filters } = pageHeaderFilterContext;
  const { setSaleOfferListSuccess, setSaleOfferListRequest, setSaleOfferListError } = saleOfferList;
  const { setInitialLoadingSuccess } = initialLoading;

  const { data } = useQuery<SaleOfferListServiceResponse>({
    queryKey: ['sale-offer-list-table', filters],
    queryFn: () => {
      setSaleOfferListRequest();

      return getSaleOfferListService(filters);
    },
    onError: (error) => {
      setSaleOfferListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const values = data.items.map((item) => convertSaleOfferListResponseToDomain(item));
      const cachedData: SaleOfferListServiceResponse[] = [];

      for (let i = 1; i <= data.meta.totalPages; i++) {
        const cache = queryClient.getQueryData<SaleOfferListServiceResponse>([
          'sale-offer-list-table',
          { ...filters, page: i },
        ]);
        if (cache) {
          cachedData.push(cache);
        }
      }

      const combinedData: ISaleOfferListDomain[] = [];

      cachedData.forEach((cache) => {
        cache.items.forEach((item) => {
          const convertedItem = convertSaleOfferListResponseToDomain(item);
          if (!combinedData.some((existingItem) => existingItem.idTariff === convertedItem.idTariff)) {
            combinedData.push(convertedItem);
          }
        });
      });

      values.forEach((sale) => {
        if (!combinedData.some((existingItem) => existingItem.idTariff === sale.idTariff)) {
          combinedData.push(sale);
        }
      });

      setSaleOfferListSuccess(combinedData);
      setInitialLoadingSuccess();
    }
  }, [data, queryClient, filters, setInitialLoadingSuccess, setSaleOfferListSuccess]);
};
