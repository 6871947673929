import styled, { css } from 'styled-components';

interface PropsStyle {
  display?: 'flex' | 'grid';
  justify?: 'center' | 'space-between' | 'flex-end';
  alignItems?: 'center' | 'left' | 'right';
  direction?: 'row' | 'column';
  gap?: number;
  columns?: string;
  isTraced?: boolean;
  width?: number;
  fontSize?: number;
  textAlign?: string;
  fontWeight?: number;
  color?: 'green' | 'gray';
  margin?: number;
  border?: boolean;
}

export const Wrapper = styled.div<PropsStyle>`
  ${({ columns, display, justify, direction, width, gap, margin, alignItems, border }) => css`
    display: ${display};
    grid-template-columns: ${columns};
    justify-content: ${justify ?? ''};
    flex-direction: ${direction};
    align-items: ${alignItems}
    align-content: right;
    width: ${width}%;
    margin-top: ${margin}px;
    height: 210px;
    gap: ${gap}px;
    color: #000;
    padding-top: 10px;
    border-top: ${border === true ? '1px solid #d9d9d9' : 'none'}
  `}
`;

export const TraceStyled = styled.hr<PropsStyle>`
  ${({ color }) => css`
    position: relative;
    bottom: 20px;
    height: 4px;
    width: 200px;
    background-color: ${color};
    border: none;
  `}
`;

export const InfoStyled = styled.p<PropsStyle>`
  ${({ fontSize, textAlign, fontWeight, margin }) => css`
    font-size: ${fontSize ?? 10}px;
    text-align: ${textAlign ?? 'center'};
    font-weight: ${fontWeight};
    margin-top: ${margin}px;
    color: #000;
  `}
`;
