import styled, { css } from 'styled-components';
import Theme from 'styles/theme';

interface IStyledProps {
  width?: string;
  hidden?: boolean;
}

export const WrapperStyled = styled.div<IStyledProps>`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  width: ${({ width }) => (width ? width : '20rem')};
  min-width: 90px;
  outline: none;
`;

export const customStyles = (disabled: boolean, hasError: boolean) => {
  return {
    menu: (provided: any) => ({
      ...provided,
      fontSize: 12,
      color: `${Theme.colorsV2.black}`,
    }),
    control: (base: any) => {
      const baseStyles = {
        ...base,
        minHeight: 36,
        color: `${Theme.colorsV2.black}`,
        fontSize: 12,
        fontWeight: '600',
        border: `1px solid ${hasError ? Theme.colors.error : Theme.colors.gray}`,
        borderRadius: 4,
        marginTop: 3,
        backgroundColor: disabled ? `${Theme.colors.veryLightGray}` : '#fff',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };

      return disabled
        ? baseStyles
        : {
            ...baseStyles,
            '&:hover, &:focus-within, &:-webkit-autofill:hover, &:-webkit-autofill:focus-within': {
              borderColor: `${Theme.colorsV2.blue}`,
              boxShadow: `inset 0 0 0 1px ${Theme.colorsV2.blue} !important`,
            },
          };
    },
    singleValue: (provided: any, base: any) => ({
      ...provided,
      color: base.isDisabled ? `${Theme.colors.black}` : `${Theme.colorsV2.black}`,
    }),
  };
};

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;

export const LabelStyled = styled.label<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    font-size: 11px;
    font-weight: 400;
    color: ${hasError ? theme.colors.error : theme.colorsV2.black};
    background-color: ${theme.colorsV2.white};
  `}
`;
