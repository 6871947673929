import { useCallback } from 'react';
import { clientDetailsService } from 'pages/client/services/client.service';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';

const useDetailsClientHook = () => {
  return useCallback(async (clientId: string): Promise<IClientDomain> => {
    const client = await clientDetailsService(clientId);
    return client;
  }, []);
};

export { useDetailsClientHook };
