import React, { ReactElement, ReactNode } from 'react';
import { FilterModalView } from 'src-new/components/application-page-header/components/filter-modal/filter-modal-view.component';

interface IRegistrationPageHeaderProps {
  isOpen: boolean;
  onCloseButton: () => void;
  onClearButton: () => void;
  children: ReactNode;
  filterCount: number;
}

export const FilterModal: React.FC<IRegistrationPageHeaderProps> = ({
  isOpen,
  filterCount,
  children,
  onCloseButton,
  onClearButton,
}): ReactElement => {
  return (
    <FilterModalView
      isOpen={isOpen}
      filterCount={filterCount}
      onCloseButton={onCloseButton}
      onClearButton={onClearButton}
    >
      {children}
    </FilterModalView>
  );
};
