import dayjs from 'dayjs';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import { IWeighingFormRegisterResponse } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/services/weighing-register/weighing-register.types';

export const convertWeighingRegisterDomainToEntity = (
  domain: IWeighingFormRegisterDomain,
): IWeighingFormRegisterResponse => {
  return {
    documentNumber: domain.documentNumber,
    entryDate: dayjs(domain.entryDate).toDate(),
    entryWeight: domain.entryWeight,
    exitDate: dayjs(domain.exitDate).toDate(),
    exitWeight: domain.exitWeight,
    netWeight: domain.netWeight,
    originWeight: domain.originWeight,
    nfWeight: domain.nfWeight,
    operation: domain.operation,
    origin: {
      id: domain.origin.id,
      type: domain.origin.type,
    },
    destination: {
      id: domain.destination.id,
      type: domain.destination.type,
    },
    shippingCompanyId: domain.shippingCompany.id,
    itemId: domain.itemId,
    driverId: domain.driverId,
    vehicleId: domain.vehicleId,
    scheduleId: domain.scheduleId,
    smpValidation: domain.smpValidation,
    po: domain.po,
    scheduledDateTime: dayjs(domain.scheduledDateTime).toDate(),
    palletCapacity: domain.palletCapacity,
    tonCapacity: domain.tonCapacity,
    collectionOrder: domain.collectionOrder,
    observation: domain.observation,
    ticketStatus: domain.ticketStatus
  };
};
