import { ColorsV2Type } from 'styles/types/colors';

type ScheduleTagType = {
  label: 'FTL' | 'STL' | 'LTL' | '---';
  color: ColorsV2Type;
};

export const getScheduleTypeTag = (freightTypes: 'FTL' | 'STL' | 'LTL' | '---' | string): ScheduleTagType => {
  const defaultValue: ScheduleTagType = {
    label: '---',
    color: ColorsV2Type.GrayDark,
  };
  const freightTypeMap: {
    [key: string]: ScheduleTagType;
  } = {
    FTL: {
      label: 'FTL',
      color: ColorsV2Type.Blue,
    },
    STL: {
      label: 'STL',
      color: ColorsV2Type.Blue100,
    },
    LTL: {
      label: 'LTL',
      color: ColorsV2Type.Aqua100,
    },
  };

  return freightTypeMap[freightTypes] || defaultValue;
};
