import { convertStatusDomainToEntityMapper } from '../convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import { IVehicleNew } from 'domain/vehicle';

export const converterVehicleDomainToFormData = (vehicle: IVehicleNew): FormData => {
  const formData = new FormData();
  const status = convertStatusDomainToEntityMapper(vehicle.status);
  formData.append('mainBoard', vehicle.mainBoard);
  formData.append('secondBoard', !vehicle.secondBoard ? '' : vehicle.secondBoard);
  formData.append('thirdBoard', !vehicle.thirdBoard ? '' : vehicle.thirdBoard);
  formData.append('capacity', vehicle.capacity);
  formData.append('palletCapacity', !vehicle.palletCapacity ? '' : vehicle.palletCapacity);
  formData.append('cubageCapacity', !vehicle.cubageCapacity ? '' : vehicle.cubageCapacity);
  formData.append('year', vehicle.year);
  formData.append('fuel', vehicle.fuel);
  formData.append('vehicleType', vehicle.vehicleType);
  formData.append('vehicleCategory', vehicle.vehicleCategory);
  formData.append('vehicleBrand', vehicle.vehicleBrand);
  formData.append('vehicleModel', vehicle.vehicleModel);
  formData.append('vehicleTracker', vehicle.vehicleTracker);
  vehicle.sensors?.forEach((sensor, index) => {
    formData.append(`sensors[${index}]`, sensor);
  });
  vehicle.actuators?.forEach((actuator, index) => {
    formData.append(`actuators[${index}]`, actuator);
  });
  formData.append('fleetType', vehicle.fleetType);
  formData.append('shippingCompanyId', !vehicle.shippingCompanyId ? '' : vehicle.shippingCompanyId.toString());
  formData.append('status', !status ? '' : status);
  if (vehicle.crlvPhoto) {
    formData.append('crlvPhoto', vehicle.crlvPhoto as Blob);
  }
  if (vehicle.vehiclePhotos1) {
    formData.append('vehiclePhotos', vehicle.vehiclePhotos1 as Blob);
  }
  if (vehicle.vehiclePhotos2) {
    formData.append('vehiclePhotos', vehicle.vehiclePhotos2 as Blob);
  }
  if (vehicle.vehiclePhotos3) {
    formData.append('vehiclePhotos', vehicle.vehiclePhotos3 as Blob);
  }
  if (vehicle.vehiclePhotos4) {
    formData.append('vehiclePhotos', vehicle.vehiclePhotos4 as Blob);
  }
  if (vehicle.vehiclePhotos5) {
    formData.append('vehiclePhotos', vehicle.vehiclePhotos5 as Blob);
  }

  return formData;
};
