import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import { getCustomFieldsService } from '../services/custom-fields';
import { ValidationFieldProvider } from '../context/validation-fields';

import { ScheduleRegisterForm } from './form';

import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { ISchedulingKeys } from 'domain/schedule';
import { ICompany } from 'domain/company';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { useLocation } from 'react-router-dom';
import { getScheduleBiddingByIdService } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-schedule-bidding-by-id/get-schedule-bidding-by-id.service';
import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { convertSchedule } from '../mappers/convert-schedule';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';

const initialCompanyValues = {
  tradeName: '',
} as ICompany;

const initialSchedulingKeysValues = [{}] as ISchedulingKeys[];

const ScheduleRegisterPageV2: React.FC = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, sethasError] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>(initialCompanyValues);
  const [schedule, setSchedule] = useState<ScheduleRegisterInput | undefined>();
  const [schedulingKeys, setSchedulingKeys] = useState<ISchedulingKeys[]>(initialSchedulingKeysValues);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const findCompany = await companyDetailsService(user?.companyId || 0);

        const findKeys = await getCustomFieldsService();

        if (location.state) {
          if (location.state.scheduleId) {
            const findSchedule = await getScheduleBiddingByIdService(location.state.scheduleId);

            const schedule = convertSchedule(findSchedule);

            setSchedule(schedule);
          }
        }

        setCompany(findCompany);

        setSchedulingKeys(findKeys);
      } catch (error) {
        sethasError(true);
        showToast({
          message: 'Erro ao obter detalhes da empresa',
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [location.state, user]);

  const renderScheduleRegister = useMemo((): ReactElement => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return <ErrorState />;
    }

    return (
      <ValidationFieldProvider>
        <ScheduleRegisterForm company={company} schedulingKeys={schedulingKeys} schedule={schedule} />
      </ValidationFieldProvider>
    );
  }, [company, hasError, isLoading, schedule, schedulingKeys]);

  return (
    <>
      <HeaderPage breadcrumbList={['Programação', 'Novo Serviço']} hasBackButton />
      <Title title="Solicite um novo frete para carga lotação (FTL)." hasTrace />
      {renderScheduleRegister}
    </>
  );
};

export default ScheduleRegisterPageV2;
