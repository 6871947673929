/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { IHireBackhaulCreated } from 'domain/hire-backhaul';

export const convertHireBackhaulCreatedEntityToDomain = (hireBackhaulEntity: any): IHireBackhaulCreated => {
  const origin = hireBackhaulEntity.clientOrigin || hireBackhaulEntity.localityOrigin;
  const destiny = hireBackhaulEntity.clientDestination || hireBackhaulEntity.localityDestination;

  const originType = hireBackhaulEntity.clientOrigin ? 'PARCEIRO' : 'LOCALIDADE';
  const destinationType = hireBackhaulEntity.clientDestination ? 'PARCEIRO' : 'LOCALIDADE';

  const freightValue = hireBackhaulEntity.freightValue?.toString().replace(',', '.');
  const convertedFreightValue = freightValue ? parseFloat(freightValue) : NaN;
  const finalFreightValue = isNaN(convertedFreightValue) ? convertedFreightValue : convertedFreightValue.toFixed(2);

  return {
    ...hireBackhaulEntity,
    initial_date: moment(hireBackhaulEntity.initial_date ?? '').format('DD/MM/YYYY'),
    offeredCubage: hireBackhaulEntity.offeredCubage || '0',
    offeredPallet: hireBackhaulEntity.offeredPallet || '0',
    offeredWeight: hireBackhaulEntity.offeredWeight || '0',

    freightValue: finalFreightValue,

    origin,
    destiny,
    originType: origin && originType,
    destinationType: destiny && destinationType,
    idDestination: origin && destiny.id,
    idOrigin: destiny && origin.id,
  } as unknown as IHireBackhaulCreated;
};
