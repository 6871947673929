/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { mapServiceType } from '../utils/map-service-type';
import { mapServiceEntityType } from '../utils/map-service-entity-type';

import { IRework } from 'domain/rework';

export const listMapper = (entity: any) => {
  return {
    id: entity.id,
    serviceType: mapServiceEntityType(entity.serviceType),
    conclusionDate: moment(entity.conclusionDateHour).format('DD/MM/yyyy HH:mm'),
    conclusionHour: moment(entity.conclusionDateHour).format('HH:mm'),
    client: entity.clientOrigin.tradeName,
    serviceValue: Number(entity.serviceValue.replace(',', '.')).toFixed(2),
    order: entity.order,
    status: entity.status,
    invoiceService: entity.invoiceService,
    observation: entity.observation,
  };
};

export const detailsMapper = (entity: any) => {
  const destinationType = entity.destinationType ?? entity.destinations.map(() => 'PARCEIRO');
  return {
    id: entity.id,
    serviceType: mapServiceEntityType(entity.serviceType) ?? '',
    pullDate: entity.pullDateHour ? entity.pullDateHour : '',
    conclusionDate: entity.conclusionDateHour ? entity.conclusionDateHour : '',
    collectDate: entity.collectDateHour ? entity.collectDateHour : '',
    schedulingDate: entity.schedulingDateHour ? entity.schedulingDateHour : '',
    startReworkDate: entity.startReworkDateHour ? entity.startReworkDateHour : '',
    endReworkDate: entity.endReworkDateHour ? entity.endReworkDateHour : '',
    receiptDate: entity.receiptDateHour ? entity.receiptDateHour : '',
    endDate: entity.endDateHour ? entity.endDateHour : '',
    serviceValue: entity.serviceValue,
    cubagem: Number(entity.cubagem),
    shippingCompanyId: entity.shippingCompanyId,
    internalNumber: entity.internalNumber,
    ocInternalNumber: entity.ocInternalNumber,
    boardingNumber: entity.boardingNumber,
    schedulingPassword: entity.schedulingPassword,
    invoice: entity.invoice,
    weight: Number(entity.weight),
    vehiclePlate: entity.vehiclePlate,
    reworkedPallets: entity.reworkedPallets,
    pallet: entity.pallet,
    shippingValue: entity.shippingValue,
    scheduleId: entity.scheduleId,
    servicePlate: entity.servicePlate,
    trNumber: entity.trNumber,
    destinations: entity.clientDestinations,
    originName: entity.clientOrigin.tradeName,
    destinationType: destinationType,
    volume: Number(entity.volume),
    observation: entity.observation,
    order: entity.order,
    invoiceService: entity.invoiceService,
    freightValue: Number(entity.freightValue.replace(',', '.')).toFixed(2),
    merchandiseValue: Number(entity.merchandiseValue),
    idClientOrigin: entity.idOrigin,
    status: entity.status,
    originType: entity.originType,
    idOrigin: entity.clientOrigin.id,
    companyId: entity.companyId,
    shippingCompanyName: entity?.shippingCompany?.name,
    dateScheduled: entity.dateScheduled ? entity.dateScheduled : '',
    servicePassword: entity.servicePassword ? entity.servicePassword : '',
  };
};

export const convertReworkToEntityMapper = (data: IRework) => {
  return {
    serviceValue: data.serviceValue,
    pullDateHour: moment(data.pullDate),
    endReworkDateHour: moment(data.endReworkDate),
    startReworkDateHour: moment(data.startReworkDate),
    receiptDateHour: moment(data.receiptDate),
    schedulingDateHour: moment(data.schedulingDate),
    collectDateHour: moment(data.collectDate),
    conclusionDateHour: moment(data.conclusionDate),
    endDateHour: moment(data.endDate),
    cubagem: Number(data.cubagem),
    shippingCompanyId: data.shippingCompanyId ?? 0,
    internalNumber: data.internalNumber ?? '',
    ocInternalNumber: data.ocInternalNumber ?? '',
    boardingNumber: data.boardingNumber ?? '',
    schedulingPassword: data.schedulingPassword ?? '',
    invoice: data.invoice ?? '',
    weight: Number(data.weight),
    vehiclePlate: data.vehiclePlate,
    reworkedPallets: data.reworkedPallets ?? 0,
    pallet: data.pallet,
    shippingValue: Number(data.shippingValue ?? 0),
    scheduleId: data.scheduleId ?? '',
    servicePlate: data.servicePlate,
    trNumber: data.trNumber,
    destinations: data.destinations,
    volume: Number(data.volume),
    merchandiseValue: Number(data.merchandiseValue),
    observation: data.observation,
    order: data.order,
    invoiceService: data.invoiceService,
    serviceType: mapServiceType(data.serviceType ?? ''),
    freightValue: Number(data.freightValue),
    idClientOrigin: data.idOrigin,
    status: data.status,
    originType: data.originType,
    destinationType: data.destinationType,
    dateScheduled: moment(data.dateScheduled),
    servicePassword: data.servicePassword ?? '',
  };
};
