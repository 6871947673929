import { FooterPage } from 'components-v2/common/footer-page';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import { FormikErrors } from 'formik';
import { ChangeEvent, FC } from 'react';
import SectionFormDivider from 'src-new/components/section-form-divider/section-form-divider';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import * as S from './weighing-register-form-mount-view.styled';
import { IWeighingFormRegisterDomain } from '../../../../domains/weighing-register.domain';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import { InputCurrency } from 'components/input-currency/input-currency';
import { WeighingFormMountLocations } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-locations/weighing-register-form-mount-locations.component';
import { WeighingFormMountItems } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-items/weighing-register-form-mount-items.component';
import { WeighingFormMountShipping } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-shipping/weighing-register-form-mount-shipping.component';
import { WeighingFormMountDrivers } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-drivers/weighing-register-form-mount-drivers.component';
import { WeighingFormMountVehicles } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-form/components/weighing-register-form-mount/components/weighing-register-form-mount-vehicles/weighing-register-form-mount-vehicles.component';
import Select from 'components/select/select';
import { weighingOperationOptions, weighingTicketStatusOptions } from 'domain/global-inputs';

interface IWeighingUpdateFormMountViewProps {
  values: IWeighingFormRegisterDomain;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingFormRegisterDomain>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleGoBackPage: () => void;
  handleValidate: () => void;
}

export const WeighingRegisterFormMountView: FC<IWeighingUpdateFormMountViewProps> = ({
  values,
  hasError,
  setFieldValue,
  handleChange,
  handleGoBackPage,
  handleValidate,
}) => {
  return (
    <S.WrapperForm>
      <SectionForm>
        <LineSectionLayout columns={'0.4fr 0.5fr 0.5fr 1fr 1fr 0.7fr'}>
          <S.WrapperInput>
            <InputField label="ID" id="id" name="id" type="text" onChange={handleChange} value={''} disabled />
          </S.WrapperInput>
          <S.WrapperInput>
            <InputField
              label="Número do Documento"
              id="documentNumber"
              name="documentNumber"
              type="text"
              onChange={handleChange}
              value={values.documentNumber}
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <InputField
              label="Ordem de Coleta"
              id="collectionOrder"
              name="collectionOrder"
              type="number"
              onChange={handleChange}
              value={values.collectionOrder}
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <Select
              label="Operação *"
              id={'operation'}
              name={'operation'}
              value={values.operation}
              setFieldValue={setFieldValue}
              options={weighingOperationOptions}
              hasError={hasError && !values.operation}
              errorMessage="Campo Obrigatório"
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Select
              label="Status"
              id={'ticketStatus'}
              name={'ticketStatus'}
              value={values.ticketStatus}
              setFieldValue={setFieldValue}
              options={weighingTicketStatusOptions}
               hasError={hasError && !values.ticketStatus}
              errorMessage="Campo Obrigatório"
            />
          </S.WrapperInput>

          <S.WrapperInputDate>
            <DateTimePikers
              label="Data da Programação *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values.scheduledDateTime)}
              onChange={(date) => setFieldValue('scheduledDateTime', date)}
              validateField={hasError && !values.scheduledDateTime}
              errorMessage="Campo Obrigatório"
            />
          </S.WrapperInputDate>
        </LineSectionLayout>

        <SectionFormDivider label={'Pesagem'} />
        <LineSectionLayout columns={'0.7fr 0.5fr 1fr 0.5fr 0.5fr'}>
          <DateTimePikers
            label="Data de Entrada"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.entryDate)}
            onChange={(date) => setFieldValue('entryDate', date)}
          />
          <S.WrapperInput>
            <InputCurrency
              label="Peso (Kg)"
              id="entryWeight"
              name="entryWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('entryWeight', value)}
              value={values.entryWeight}
              suffix=" Kg"
            />
          </S.WrapperInput>
          <DateTimePikers
            label="Data de Saída"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.exitDate)}
            onChange={(date) => setFieldValue('exitDate', date)}
          />

          <S.WrapperInput>
            <InputCurrency
              label="Peso (Kg)"
              id="exitWeight"
              name="exitWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('exitWeight', value)}
              value={values.exitWeight}
              suffix=" Kg"
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <InputCurrency
              label="Peso NF (Kg)"
              id="nfWeight"
              name="nfWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('nfWeight', value)}
              value={values.nfWeight}
              suffix=" Kg"
            />
          </S.WrapperInput>
        </LineSectionLayout>

        <LineSectionLayout columns={'1.25fr 0.5fr 0.5fr 0.5fr 0.5fr'}>
          <S.WrapperInput>
            <WeighingFormMountItems />
          </S.WrapperInput>

          <InputField
            label="SMP Validação"
            id="smpValidation"
            name="smpValidation"
            type="text"
            onChange={handleChange}
            value={values.smpValidation}
          />

          <InputField label="PO" id="po" name="po" type="text" onChange={handleChange} value={values.po} />

          <InputField
            label="Capacidade Palete"
            id="palletCapacity"
            name="palletCapacity"
            type="number"
            onChange={handleChange}
            value={values.palletCapacity}
          />

          <InputCurrency
            label="Capacidade Tonelada"
            id="tonCapacity"
            name="tonCapacity"
            type="text"
            allowDecimals
            decimalScale={0}
            decimalsLimit={0}
            decimalSeparator=","
            groupSeparator="."
            onValueChange={(value) => setFieldValue('tonCapacity', value)}
            value={values.tonCapacity}
            suffix=" Ton"
          />
        </LineSectionLayout>

        <SectionFormDivider label={'Origem'} hidePinLocation={false} />
        <WeighingFormMountLocations hasError={hasError} type={'origin'} />

        <SectionFormDivider label={'Destino'} hidePinLocation={false} />
        <WeighingFormMountLocations hasError={hasError} type={'destination'} />

        <LineSectionLayout columns={'0.8fr 1.2fr 1fr'}>
          <div>
            <SectionFormDivider label={'Transportadora'} />
            <WeighingFormMountShipping hasError={hasError} />
          </div>
          <div>
            <SectionFormDivider label={'Motorista'} />
            <WeighingFormMountDrivers hasError={hasError} />
          </div>

          <div>
            <SectionFormDivider label={'Veículo'} />
            <WeighingFormMountVehicles hasError={hasError} />
          </div>
        </LineSectionLayout>
        <div>
          <SectionFormDivider label={'Observação'} />
          <InputField
            label=""
            id="observation"
            name="observation"
            type="text"
            onChange={handleChange}
            value={values.observation}
            upperCase={false}
          />
        </div>
      </SectionForm>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Salvar" color="pink" isLoading={false} onPress={handleValidate} />
          <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBackPage} />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </S.WrapperForm>
  );
};
