import API from 'utils/API/API';
import { PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';

export const updatePlanningVehicleService = async (id: number, planningRoute: PlanningRouteVehicle) => {
  await API.put(`v2/my-router/update-planning-route/${id}`, planningRoute);
};

export const updatePlanningShippingService = async (id: number, planningRoute: PlanningRouteShipping) => {
  await API.put(`v2/my-router/update-planning-vehicle/${id}`, planningRoute);
};
