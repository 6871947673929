import { FC, ReactElement, useMemo } from 'react';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { axisNumberFixed } from 'utils/axis-number/axis-number';
import {
  IScheduleDetailsCargoDomain,
  IScheduleDetailsDriverDomain,
  IScheduleDetailsVehicleDomain,
} from '../../../../domains/schedule-details.domain';
import * as S from './schedule-details-resume-cargo-view.styled';

interface IScheduleDetailsResumeCargoViewProps {
  inPallet: string;
  cargo: IScheduleDetailsCargoDomain;
  vehicle?: IScheduleDetailsVehicleDomain;
  driver?: IScheduleDetailsDriverDomain;
  distance: number;
}

export const ScheduleDetailsResumeCargoView: FC<IScheduleDetailsResumeCargoViewProps> = ({
  inPallet,
  cargo,
  vehicle,
  driver,
  distance,
}) => {
  const renderOperationSubType = useMemo((): ReactElement | undefined => {
    if (cargo.operationSubType) {
      return (
        <S.WrapperInfos>
          <S.Text isStrong>Tipo de Operação</S.Text>
          <S.Text>{cargo.operationSubType}</S.Text>
        </S.WrapperInfos>
      );
    }
  }, [cargo.operationSubType]);

  return (
    <S.Wrapper>
      <S.WrapperOperation>
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.WrapperOperationInfo>
          <S.Div>
            <S.Text isStrong>detalhes</S.Text>
            <S.WrapperContent>
              <S.WrapperInfos>
                <S.Text isStrong>motorista</S.Text>
                <S.Text>{driver?.name || 'Não Informado'}</S.Text>
                <S.Text>{driver?.cpf || 'Não Informado'}</S.Text>
              </S.WrapperInfos>

              <S.WrapperInfos>
                <S.Text isStrong>Veículo</S.Text>
                <S.Text>
                  {convertVehicleType(vehicle?.type ?? cargo.vehicleType)}{' '}
                  {convertVehicleCategory(vehicle?.category ?? cargo.vehicleCategoryType)}
                </S.Text>
                <S.Text>
                  {vehicle?.mainBoard}
                  {vehicle?.secondBoard ? `, ${vehicle?.secondBoard}` : ''}
                  {vehicle?.thirdBoard ? `, ${vehicle?.thirdBoard}` : ''}
                </S.Text>
                <S.Text>
                  {vehicle?.brand} - {vehicle?.model}
                </S.Text>
                <S.Text>{axisNumberFixed(vehicle?.type ?? cargo.vehicleType ?? '0')} eixos</S.Text>
              </S.WrapperInfos>

              <S.WrapperInfos>
                <S.Text isStrong>Carga</S.Text>
                <S.Text>{cargo.product}</S.Text>
                <S.Text>{cargo.weight} kg</S.Text>
                <S.Text>{inPallet}</S.Text>
              </S.WrapperInfos>

              {renderOperationSubType}

              <S.WrapperInfos>
                <S.Text isStrong>distância</S.Text>
                <S.Text>{(distance ?? 0).toFixed(0)} km</S.Text>
              </S.WrapperInfos>
            </S.WrapperContent>
          </S.Div>
        </S.WrapperOperationInfo>
      </S.WrapperOperation>
    </S.Wrapper>
  );
};
