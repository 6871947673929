import { formatCelphoneNumberDomainToEntity } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { removeInputMaskDomainToEntity } from 'utils/remove-input-mask/remove-input-mask';
import dayjs from 'dayjs';
import { IDriverNewDomain } from 'src-new/pages/registrations/domains/driver.domain';

export const driveFormBlackList = [
  'values',
  'certification',
  'firstPhone',
  'secondPhone',
  'cpf',
  'shippingCompanyId',
  'status',
  'driverPhoto',
  'cnhPhoto',
  'certificationPhoto',
  'addressProofPhoto',
];

export const converterDriverDomainToFormData = (driver: IDriverNewDomain): FormData => {
  const formData = new FormData();

  if (driver.certification.length) {
    driver.certification.map((item, index) => {
      formData.append(`certification[${index}]`, item);
    });
  }

  const status = driver.status;

  Object.entries(driver).forEach(([key, value]) => {
    if (key !== 'riskManagers') {
      if (value && !driveFormBlackList.includes(key)) {
        formData.append(key, value);
      }
    }
    if (key === 'cnhValidate') {
      const isoDate = dayjs(driver.cnhValidate, 'DD/MM/YYYY').toISOString();
      formData.append(key, isoDate);
    }
    if (key === 'issueDate') {
      const isoDate = dayjs(driver.issueDate, 'DD/MM/YYYY').toISOString();
      formData.append(key, isoDate);
    }
  });

  driver.firstPhone && formData.append('firstPhone', formatCelphoneNumberDomainToEntity(driver.firstPhone));

  driver.secondPhone && formData.append('secondPhone', formatCelphoneNumberDomainToEntity(driver.secondPhone));

  driver.cpf && formData.append('cpf', removeInputMaskDomainToEntity(driver.cpf));

  formData.append('shippingCompanyId', !driver.shippingCompanyId ? '' : driver.shippingCompanyId.toString());

  status && formData.append('status', !status ? '' : status);

  driver.driverPhoto && formData.append('driverPhoto', driver.driverPhoto as Blob);

  driver.cnhPhoto && formData.append('cnhPhoto', driver.cnhPhoto as Blob);

  driver.certificationPhoto && formData.append('certificationPhoto', driver.certificationPhoto as Blob);

  driver.addressProofPhoto && formData.append('addressProofPhoto', driver.addressProofPhoto as Blob);

  driver.riskManagers?.forEach((rm, index) => {
    formData.append(`riskManagers[${index}][gr]`, rm.gr);
    formData.append(`riskManagers[${index}][driverId]`, String(rm.driverId));
    formData.append(`riskManagers[${index}][createdAt]`, rm.registerDate);
    formData.append(`riskManagers[${index}][validate]`, rm.validateDate);
    formData.append(`riskManagers[${index}][consultedBy]`, rm.consultedBy);
    formData.append(`riskManagers[${index}][consultationRecord]`, rm.consultationRecord);
    formData.append(`riskManagers[${index}][status]`, rm.status);
  });
  return formData;
};
