import React, { useCallback, useContext, useMemo } from 'react';
import { HeaderButtonsView } from './header-buttons-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UserContext } from 'state/user-context';
import { IButtonsOptions } from './header-buttons.types';
import { useUpdateAcceptSchedules } from '../../../../hooks/use-update-accept-schedules/use-update-accept-schedules.hook';
import { useDeleteSchedules } from '../../../../hooks/use-delete-schedules/use-delete-schedules.hook';

export const HeaderButtons: React.FC = () => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const { scheduleList } = useContext(ScheduleContext);

  const updateAcceptSchedules = useUpdateAcceptSchedules();
  const deleteSchedules = useDeleteSchedules();

  const buttonsOptions = useMemo((): IButtonsOptions => {
    const validStatus = [
      'Em Negociação',
      'Ag. Aceite Transportadora',
      'Em Rota para Carregamento',
      'Em Carregamento',
      'Em Rota para Descarga',
      'Em Descarga',
      'Alocação Cancelada',
      'Oferta Negada',
      'Alocação Cancelada com Custo',
      'Descarga Finalizada',
    ];

    const acceptButton = scheduleList.scheduleListCheckbox.checkbox.some(
      (schedule) => schedule.status === 'Em Negociação' || schedule.status === 'Ag. Aceite Transportadora',
    );

    const cancelButton = scheduleList.scheduleListCheckbox.checkbox.some(
      (schedule) => !validStatus.includes(schedule.status),
    );

    const secondaryButton = acceptButton && !cancelButton ? 'Aceitar' : null;

    const thirdButton =
      acceptButton && !cancelButton
        ? 'Recusar'
        : !acceptButton && cancelButton && !isShippingCompany
          ? 'Cancelar'
          : null;

    const finishButton = isAdmin ? 'Finalizar' : null;

    return {
      secondaryButton,
      thirdButton,
      finishButton,
    };
  }, [isAdmin, isShippingCompany, scheduleList.scheduleListCheckbox.checkbox]);

  const handleAcceptSchedule = useCallback(async () => {
    await updateAcceptSchedules();
    scheduleList.scheduleListCheckbox.setScheduleListCheckbox([]);
  }, [scheduleList.scheduleListCheckbox, updateAcceptSchedules]);

  const handleCancelFreight = useCallback(() => {
    const checkboxScheduleIds = scheduleList.scheduleListCheckbox.checkbox.map((checkbox) => checkbox.id);

    const modalType = buttonsOptions.thirdButton === 'Recusar' ? 'deny' : 'cancel';

    scheduleList.modalsProps.setModalOpen({ open: true, ids: checkboxScheduleIds, modalType });

    scheduleList.scheduleListCheckbox.setScheduleListCheckbox([]);
  }, [scheduleList.scheduleListCheckbox, scheduleList.modalsProps, buttonsOptions.thirdButton]);

  const handleFinish = useCallback(async () => {
    await deleteSchedules();
    scheduleList.scheduleListCheckbox.setScheduleListCheckbox([]);
  }, [deleteSchedules, scheduleList.scheduleListCheckbox]);

  return (
    <HeaderButtonsView
      scheduleSelected={scheduleList.scheduleListCheckbox.checkbox}
      buttonsOptions={buttonsOptions}
      handleAcceptSchedule={handleAcceptSchedule}
      handleCancelFreight={handleCancelFreight}
      handleFinish={handleFinish}
    />
  );
};
