import { convertTypeDomainToEntityMapper } from '../convert-type-domain-to-entity/convert-type-domain-to-entity.mapper';
import { ILocationCreatedEntity } from '../../services/entities/location.entity';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';

export const convertLocationCreatedToEntityMapper = (locationDomain: ILocationDomain): ILocationCreatedEntity => {
  return {
    id: locationDomain.id ?? 0,
    type: convertTypeDomainToEntityMapper(locationDomain.type),
    name: locationDomain.name,
    cnpj: locationDomain.cnpj,
    internalCode: locationDomain.internalCode,
    vehicleRestriction: locationDomain.vehicleRestriction,
    vehicleCategoryType: locationDomain.vehicleCategoryType,
    clientIds: locationDomain.clientIds,
    schedulingEmail: locationDomain.schedulingEmail,
    occurrenceEmail: locationDomain.occurrenceEmail,
    operationType: locationDomain.operationType,
    status: locationDomain.status,
    address: locationDomain.address,
    quality_and_safety: locationDomain.quality_and_safety,
    lat: locationDomain.lat,
    lng: locationDomain.lng,
    inbound: locationDomain.inbound,
    oubound: locationDomain.outbound,
    contacts: locationDomain.contacts,
    attachments: locationDomain.attachments,
  };
};
