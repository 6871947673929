/* eslint-disable @typescript-eslint/no-explicit-any */
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';

export const scheduleLTLInitialValues: ScheduleLTLRegisterInput = {
  origin: {
    id: 0,
    type: '',
    origin: {
      id: 0,
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      oubound: undefined as any,
      internalCode: '',
      occurrenceEmail: [''],
      quality_and_safety: '',
      schedulingEmail: [''],
      status: 'ativo',
      type: '',
      name: '',
      vehicleRestriction: [''],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
    tradeName: '',
  },
  destination: {
    id: 0,
    type: '',
    destination: {
      id: 0,
      cnpj: '',
      lat: '',
      lng: '',
      inbound: undefined as any,
      oubound: undefined as any,
      internalCode: '',
      occurrenceEmail: [''],
      quality_and_safety: '',
      schedulingEmail: [''],
      status: 'ativo',
      type: '',
      name: '',
      vehicleRestriction: [''],
      address: {
        city: '',
        uf: '',
        complement: '',
        neighborhood: '',
        numberHouse: '',
        street: '',
        cep: '',
      },
    },
    tradeName: '',
  },
  cargo: {
    vehicleType: '',
    vehicleCategoryType: '',
    weight: 0,
    cubagem: 0,
    volume: 0,
    merchValue: 0,
    inPallet: false,
    pallet: 0,
    product: '',
    order: '',
    high: 0,
    width: 0,
    length: 0,
  },
  detail: {
    levyInitialDate: '',
    deliveryEndDate: '',
    levyEndDate: '',
    deliveryInitialDate: '',
    ocInternalNumber: '',
    boardingNumber: '',
    schedulingPassword: '',
  },
  cost: {
    valueFreight: 0,
    valueGoods: 0,
    valueTotalFee: 0,
    taxes: 0,
    totalFreight: 0,
    helperTotal: 0,
    toll: 0,
    totalGrisAdvalorem: 0,
    totalService: 0,
    fine: 0,
    leadTime: 0,
    icms: 0,
  },
  distance: 0,
  operation: 'SPOT',
};
