import { useCallback, useContext } from 'react';

import { SantosPortContext } from '../../contexts/table-states/santos-port.context';

import { Hook } from './use-santos-port-list.types';

import { santosPortListService } from 'pages/santos-port/service/santos-port-list/santos-port-list.service';

const useSantosPortList: Hook = () => {
  const { setSantosPortRequest, setSantosPortSuccess, setSantosPortError } = useContext(SantosPortContext);

  return useCallback(
    async ({ searchValue, currentPage }) => {
      setSantosPortRequest();

      try {
        const response = await santosPortListService({
          search: searchValue,
          page: currentPage,
        });

        setSantosPortSuccess(response);
      } catch (error) {
        setSantosPortError('Ocorreu um erro ao buscar Porto de Santos, tente novamente!');
      }
    },
    [setSantosPortError, setSantosPortRequest, setSantosPortSuccess],
  );
};

export { useSantosPortList };
