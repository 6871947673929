import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { IClientResponse } from '../../get-clients-list.types';

export const convertClientResponseToDomain = (client: IClientResponse): IClientDomain => ({
  id: client.id,
  cnpj: '',
  fantasyName: client.fantasyName,
  plantName: '',
  tradeName: client.tradeName,
  internalCode: client.internalCode,
  ie: '',
  status: client.status,
  address: {
    cep: '',
    city: client.city,
    uf: client.uf,
    complement: '',
    street: '',
    numberHouse: '',
    neighborhood: '',
  },
  lat: '',
  lng: '',
  vehicleType: [],
  vehicleCategoryType: [],
  localityIds: [],
  operationType: [],
  type_of_partner: client.type_of_partner,
  quality_and_safety: client.quality_and_safety,
  company: client.company,
  inbound: client.inbound,
  outbound: client.outbound,
  operationalRequirements: [],
  attachments: [],
  originalAttachments: [],
  contacts: [],
});
