import { convertEntityToSelectItem } from 'utils/convert-entity-to-select';
import API from 'utils/API/API';
import { SelectItem } from 'domain/select-item';

export const shippingCompanySearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API.get(`shipping-company-name?name=${searchValue}`);

  return data.map((shipping: { name: string; id: number; emails: Array<string> }) => {
    return convertEntityToSelectItem(shipping.name.toUpperCase(), shipping.id, shipping.emails?.[0] ?? '');
  });
};
