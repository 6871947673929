import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useQueryClient } from 'react-query';
import { updateFavoriteMatchService } from '../../services/update-favorite-match/update-favorite-match.service';

export const useUpdateFavoriteMatch = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (matchId: number, isFavorite: boolean) => {
      try {
        await updateFavoriteMatchService(matchId, `${isFavorite}`);

        showMessageFormatted({
          message: `Match ${isFavorite ? 'Favoritado' : 'Desfavoritado'}.`,
          type: 'success',
        });

        reactQueryClient.invalidateQueries(['favorite-matches-kanban-list']);
        reactQueryClient.invalidateQueries(['in-negociation-matches-kanban-list']);
        reactQueryClient.invalidateQueries(['operated-matches-kanban-list']);
        reactQueryClient.invalidateQueries(['stabilized-matches-kanban-list']);
        reactQueryClient.invalidateQueries(['inactive-matches-kanban-list']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
