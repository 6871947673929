import styled, { css } from 'styled-components';

interface ModalBarkStyleProps {
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xsm' | 'xxsm';
}

export const ModalBarkWrapper = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: ${theme.modals.background};
    top: 0;
    right: 0;
    z-index: 999;
  `}
`;
export const ModalBarkContent = styled.section<ModalBarkStyleProps>`
  ${({ theme, size }) => css`
    width: ${theme.modalsV2.width[size]};
    height: ${theme.modalsV2.height[size]};
    background-color: ${theme.colorsV2.white};
    border-radius: ${theme.modalsV2.radius};
    padding: ${theme.modalsV2.padding};
    overflow-y: visible;
  `}
`;

export const ModalBarkHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 1.5rem;
`;

export const ModalBarkWrapperTitles = styled.section<{ alingText?: boolean }>`
  ${({ alingText }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: ${alingText ? 'center' : ''};
    gap: 5px;
    text-align: ${alingText ? 'center' : ''};
  `}
`;

export const ModalBarkTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

export const ModalBarkClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 1rem;
  cursor: pointer;
`;
