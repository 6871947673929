import dayjs from 'dayjs';

import {showMessageFormatted} from 'utils/message/show-message-formatted/show-message-formatted';
import {DatesMatchInput} from 'domain-v2/match/register';

const isDateEmpty = (date: string) => !date;
const isSameOrAfterDate = (dateA: string, dateB: string) => dayjs(dateA).isAfter(dateB);

export const handleValidateDates = (dates: DatesMatchInput[]) => {
  const validationDates = dates.map((date) => {
    const {initialLevyDate, initialDeliveryDate, finalLevyDate, finalDeliveryDate} = date;

    if (
      isDateEmpty(initialLevyDate) ||
      isDateEmpty(initialDeliveryDate) ||
      isDateEmpty(finalLevyDate) ||
      isDateEmpty(finalDeliveryDate)
    ) {
      return {
        initialLevyDate: isDateEmpty(initialLevyDate) ? 'Campo Obrigatório' : '',
        initialDeliveryDate: isDateEmpty(initialDeliveryDate) ? 'Campo Obrigatório' : '',
        finalLevyDate: isDateEmpty(finalLevyDate) ? 'Campo Obrigatório' : '',
        finalDeliveryDate: isDateEmpty(finalDeliveryDate) ? 'Campo Obrigatório' : '',
      };
    }

    if (
      isSameOrAfterDate(initialLevyDate, finalLevyDate) ||
      isSameOrAfterDate(finalLevyDate, initialDeliveryDate) ||
      isSameOrAfterDate(initialDeliveryDate, finalDeliveryDate)
    ) {
      return {
        initialLevyDate: '',
        finalLevyDate: isSameOrAfterDate(initialLevyDate, finalLevyDate) ? 'Campo Inválido' : '',
        initialDeliveryDate: isSameOrAfterDate(finalLevyDate, initialDeliveryDate) ? 'Campo Inválido' : '',
        finalDeliveryDate: isSameOrAfterDate(initialDeliveryDate, finalDeliveryDate) ? 'Campo Inválido' : '',
      };
    }

    return {} as DatesMatchInput;
  });

  const hasDatesError = validationDates.map((date) => Object.keys(date).length !== 0);

  if (hasDatesError.includes(true)) {
    showMessageFormatted({
      message: 'Complete os Dados Obrigatórios para Agendamento.',
      type: 'error',
    });

    return {validationDates};
  } else {
    return;
  }
};
