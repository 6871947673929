export enum MatchesKanbanListActionType {
  MATCHES_KANBAN_MODALS = 'MATCHES_KANBAN_MODALS',
  MATCHES_KANBAN_LIST_FILTERS = 'MATCHES_KANBAN_LIST_FILTERS',
}

export enum ModalMatchesKanbanActionType {
  MODAL_MATCHES_KANBAN = 'MODAL_MATCHES_KANBAN',
}

export enum FavoriteMatchesKanbanListActionType {
  FAVORITE_MATCHES_KANBAN_REQUEST = 'FAVORITE_MATCHES_KANBAN_REQUEST',
  FAVORITE_MATCHES_KANBAN_SUCCESS = 'FAVORITE_MATCHES_KANBAN_SUCCESS',
  FAVORITE_MATCHES_KANBAN_ERROR = 'FAVORITE_MATCHES_KANBAN_ERROR',
  FAVORITE_MATCHES_KANBAN_RESET = 'FAVORITE_MATCHES_KANBAN_RESET',
  FAVORITE_MATCHES_KANBAN_MODALS = 'FAVORITE_MATCHES_KANBAN_MODALS',
}

export enum InNegociationMatchesKanbanListActionType {
  IN_NEGOCIATION_MATCHES_KANBAN_REQUEST = 'IN_NEGOCIATION_MATCHES_KANBAN_REQUEST',
  IN_NEGOCIATION_MATCHES_KANBAN_SUCCESS = 'IN_NEGOCIATION_MATCHES_KANBAN_SUCCESS',
  IN_NEGOCIATION_MATCHES_KANBAN_ERROR = 'IN_NEGOCIATION_MATCHES_KANBAN_ERROR',
  IN_NEGOCIATION_MATCHES_KANBAN_RESET = 'IN_NEGOCIATION_MATCHES_KANBAN_RESET',
  IN_NEGOCIATION_MATCHES_KANBAN_MODALS = 'IN_NEGOCIATION_MATCHES_KANBAN_MODALS',
}

export enum OperatedMatchesKanbanListActionType {
  OPERATED_MATCHES_KANBAN_REQUEST = 'OPERATED_MATCHES_KANBAN_REQUEST',
  OPERATED_MATCHES_KANBAN_SUCCESS = 'OPERATED_MATCHES_KANBAN_SUCCESS',
  OPERATED_MATCHES_KANBAN_ERROR = 'OPERATED_MATCHES_KANBAN_ERROR',
  OPERATED_MATCHES_KANBAN_RESET = 'OPERATED_MATCHES_KANBAN_RESET',
  OPERATED_MATCHES_KANBAN_MODALS = 'OPERATED_MATCHES_KANBAN_MODALS',
}

export enum StabilizedMatchesKanbanListActionType {
  STABILIZED_MATCHES_KANBAN_REQUEST = 'STABILIZED_MATCHES_KANBAN_REQUEST',
  STABILIZED_MATCHES_KANBAN_SUCCESS = 'STABILIZED_MATCHES_KANBAN_SUCCESS',
  STABILIZED_MATCHES_KANBAN_ERROR = 'STABILIZED_MATCHES_KANBAN_ERROR',
  STABILIZED_MATCHES_KANBAN_RESET = 'STABILIZED_MATCHES_KANBAN_RESET',
  STABILIZED_MATCHES_KANBAN_MODALS = 'STABILIZED_MATCHES_KANBAN_MODALS',
}

export enum InactiveMatchesKanbanListActionType {
  INACTIVE_MATCHES_KANBAN_REQUEST = 'INACTIVE_MATCHES_KANBAN_REQUEST',
  INACTIVE_MATCHES_KANBAN_SUCCESS = 'INACTIVE_MATCHES_KANBAN_SUCCESS',
  INACTIVE_MATCHES_KANBAN_ERROR = 'INACTIVE_MATCHES_KANBAN_ERROR',
  INACTIVE_MATCHES_KANBAN_RESET = 'INACTIVE_MATCHES_KANBAN_RESET',
  INACTIVE_MATCHES_KANBAN_MODALS = 'INACTIVE_MATCHES_KANBAN_MODALS',
}

export enum MatchesActionType {
  MATCHES_RESET = 'MATCHES_RESET',
}
