import axios from 'axios';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const convertProfileUrlToFile = async (url: string): Promise<File> => {
  try {
    const fileName = url.split('?')[0].split('/')[3];

    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    if (response) {
      return new File([response.data], fileName);
    } else {
      return new File([], '');
    }
  } catch (error: any) {
    showMessageFormatted({
      error,
      type: 'error',
    });

    return new File([], '');
  }
};
