import { updateScheduleHoursService } from 'pages/freight/services/schedule-details-services/schedule-details-update/schedule-details-update.service';
import { ScheduleDetails } from 'domain/schedule';

const useUpdateScheduleHoursHook = () => {
  return async (id: number, values: ScheduleDetails) => {
    await updateScheduleHoursService(id, values);
  };
};

export { useUpdateScheduleHoursHook };
