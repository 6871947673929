import { FavoriteMatchesKanbanListActionType } from '../../matches.action';
import { IMatchesContext } from '../../types/matches.types';
import { IFavoriteMatchesKanbanListActions } from '../../types/favorite-matches-kanban.types';

export const favoriteMatchesKanbanListReducer = (
  state: IMatchesContext,
  action: IFavoriteMatchesKanbanListActions,
): IMatchesContext => {
  switch (action.type) {
    case FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_REQUEST:
      return {
        ...state,
        favoriteMatchesKanbanList: {
          ...state.favoriteMatchesKanbanList,
          statusRequest: 'PROCESSING',
          favoriteMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_SUCCESS:
      return {
        ...state,
        favoriteMatchesKanbanList: {
          ...state.favoriteMatchesKanbanList,
          statusRequest: 'SUCCESS',
          favoriteMatchesKanbanList: action.payload,
        },
      };
    case FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_ERROR:
      return {
        ...state,
        favoriteMatchesKanbanList: {
          ...state.favoriteMatchesKanbanList,
          statusRequest: 'ERROR',
          favoriteMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    case FavoriteMatchesKanbanListActionType.FAVORITE_MATCHES_KANBAN_RESET:
      return {
        ...state,
        favoriteMatchesKanbanList: {
          ...state.favoriteMatchesKanbanList,
          statusRequest: 'INITIAL',
          favoriteMatchesKanbanList: {
            items: [],
            totalItems: 0,
          },
        },
      };
    default:
      return state;
  }
};
