import styled, { css } from 'styled-components';

type InputUploadImageProps = {
  dataUrlImage: string;
};

export const ContainerStyled = styled.div<InputUploadImageProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 164px;
    width: 164px;
    border: solid 1px ${theme.colors.gray};
    padding: 10px;
    cursor: pointer;
  `}
`;

export const TrashIconContainerStyled = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 7px;
`;

export const CompanyImageStyled = styled.img`
  height: 164px;
  width: 164px;
  padding: 10px;
`;

export const ContainerImageViewStyled = styled.div`
  position: relative;
  top: -100%;
  height: 100%;
  cursor: zoom-in;
`;

export const ImageViewStyled = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;

export const PDFViewStyled = styled.iframe`
  width: 100%;
  height: 100vh;
  border-radius: 5px;
`;
