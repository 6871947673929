import { showToast } from 'components/toast/toast';
import { FormikErrors } from 'formik';
import { clientDetailsService } from 'pages/client/services/client.service';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { ISaleOfferDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-update/domains/sale-offer-details.domain';
import { SaleOfferFormMountLocationsView } from './sale-offer-update-form-mount-locations-view.component';
import { VehicleTypeResponse } from 'src-new/domains/vehicle-type.domain';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';

interface ISaleOfferFormMountLocationsProps {
  type: 'origin' | 'destination';
  values: ISaleOfferDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<ISaleOfferDomain>>;
}

export const SaleOfferFormMountLocations: FC<ISaleOfferFormMountLocationsProps> = ({ type, values, setFieldValue }) => {
  const typeKeyMemo = useMemo(() => {
    if (type === 'origin') return 'origin';
    return 'destination';
  }, [type]);

  const handleCompleteInputs = useCallback(async () => {
    const type = values[typeKeyMemo].type;
    const id = values[typeKeyMemo].id;

    if (
      id &&
      (type === 'CLIENT' || type === 'LOCALITY') &&
      !('vehicleRestriction' in values[typeKeyMemo] || 'vehicleCategoryType' in values[typeKeyMemo])
    ) {
      try {
        const details = await (type === 'CLIENT'
          ? clientDetailsService(String(id))
          : locationDetailsService(String(id)));

        let vehicleRestriction: Array<VehicleTypeResponse> = [];

        if ('vehicleType' in details) {
          vehicleRestriction = [...(details.vehicleType ?? [])];
        } else {
          vehicleRestriction = [...(details.vehicleRestriction ?? [])];
        }

        const fieldValuesToUpdate = {
          [`${typeKeyMemo}.id`]: details.id,
          [`${typeKeyMemo}.address.city`]: details.address.city,
          [`${typeKeyMemo}.address.uf`]: details.address.uf,
          [`${typeKeyMemo}.address.complement`]: details.address.complement,
          [`${typeKeyMemo}.address.neighborhood`]: details.address.neighborhood,
          [`${typeKeyMemo}.address.numberHouse`]: details.address.numberHouse,
          [`${typeKeyMemo}.address.street`]: details.address.street,
          [`${typeKeyMemo}.address.cep`]: details.address.cep,
          [`${typeKeyMemo}.cnpj`]: details.cnpj,
          [`${typeKeyMemo}.lat`]: details.lat,
          [`${typeKeyMemo}.lng`]: details.lng,
          [`${typeKeyMemo}.vehicleRestriction`]: vehicleRestriction?.map(convertVehicleType),
          [`${typeKeyMemo}.vehicleCategoryType`]: details.vehicleCategoryType?.map(convertVehicleCategory) ?? [],
        };

        for (const fieldName in fieldValuesToUpdate) {
          const value = fieldValuesToUpdate[fieldName];
          setFieldValue(fieldName, value);
        }
      } catch (error) {
        showToast({
          message: 'Erro ao obter dados',
          type: 'error',
        });
      }
    }
  }, [setFieldValue, typeKeyMemo, values]);

  useEffect(() => {
    handleCompleteInputs();
  }, [handleCompleteInputs]);

  return <SaleOfferFormMountLocationsView type={typeKeyMemo} values={values} setFieldValue={setFieldValue} />;
};
