import React, { ReactNode } from 'react';
import { Map } from '@vis.gl/react-google-maps';
import { IMapsProps } from './google-maps-view.types';
import { GoogleMapContainerStyled } from 'src-new/components/google-maps/google-maps-view.styled';

interface IGoogleMapsViewProps {
  children: ReactNode;
  mapProps: IMapsProps;
}

export const GoogleMapsView: React.FC<IGoogleMapsViewProps> = ({ children, mapProps }) => {
  return (
    <GoogleMapContainerStyled>
      <Map
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        mapTypeId={mapProps.mapType}
        defaultCenter={mapProps.defaultCenter}
      >
        {children}
      </Map>
    </GoogleMapContainerStyled>
  );
};
