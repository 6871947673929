import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ContactDetailsTableView } from 'src-new/pages/registrations/components/contact-details-section/components/contact-details-table/contact-details-table-view.component';
import { useFormikContext } from 'formik';
import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import { isEqual, isArray } from 'lodash';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';
import { RegistrationContactValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IContactDetailsTableProps {
  handleRemoveContact: (index: number) => IContactDomain | undefined;
}

export const ContactDetailsTable: React.FC<IContactDetailsTableProps> = ({ handleRemoveContact }) => {
  const { contactDetailsSection } = useContext(RegistrationsContext);
  const { getFieldProps } = useFormikContext<RegistrationContactValues>();
  const [tableList, setTableList] = useState<Array<IContactDomain>>([]);
  const [position, setPosition] = useState<number | undefined>(undefined);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleCloseRegistrationModal = useCallback(() => setIsOpenModal(false), []);

  const contacts = useMemo((): Array<IContactDomain> => getFieldProps('contacts').value ?? [], [getFieldProps]);

  const isEqualContactList = useMemo((): boolean => isEqual(contacts, tableList), [contacts, tableList]);

  const removeItem = useCallback(
    (index: number) => {
      handleRemoveContact(index);
    },
    [handleRemoveContact],
  );

  const editItem = useCallback(
    (index: number) => {
      if (!contactDetailsSection.isOpenModal) {
        setPosition(index);
        setIsOpenModal(true);
      }
    },
    [contactDetailsSection.isOpenModal],
  );

  useEffect(() => {
    if (isArray(contacts) && !contactDetailsSection.isOpenModal && !isOpenModal && !isEqualContactList) {
      setTableList(contacts.filter((contact) => contact.name));
    }
  }, [contactDetailsSection.isOpenModal, contacts, isEqualContactList, isOpenModal]);

  return (
    <ContactDetailsTableView
      contacts={tableList}
      removeItem={removeItem}
      editItem={editItem}
      isOpenModal={isOpenModal}
      position={position}
      handleCloseRegistrationModal={handleCloseRegistrationModal}
    />
  );
};
