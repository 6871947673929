import React from 'react';

export const FilterCancelIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M6 0C2.6961 0 0 2.6961 0 6C0 9.3039 2.6961 12 6 12C9.3039 12 12 9.3039 12 6C12 2.6961 9.3039 0 6 0ZM8.16623 8.15065C8.08831 8.22857 7.9948 8.25974 7.88571 8.25974C7.77662 8.25974 7.68312 8.22857 7.60519 8.15065L6 6.54545L4.41039 8.15065C4.33247 8.22857 4.23896 8.25974 4.12987 8.25974C4.02078 8.25974 3.92727 8.22857 3.84935 8.15065C3.69351 7.9948 3.69351 7.74545 3.84935 7.60519L5.45455 6L3.84935 4.39481C3.69351 4.25455 3.69351 4.00519 3.84935 3.84935C3.98961 3.69351 4.23896 3.69351 4.39481 3.84935L6 5.45455L7.60519 3.84935C7.74545 3.69351 7.9948 3.69351 8.15065 3.84935C8.30649 3.98961 8.30649 4.23896 8.15065 4.39481L6.54545 6L8.16623 7.60519C8.32208 7.74545 8.32208 7.9948 8.16623 8.15065Z"
        fill="#0A6260"
      />
    </svg>
  );
};
