import React, { useCallback } from 'react';
import { PalletFormView } from './pallet-form-view.component';
import { withFormik } from 'formik';

export const PalletForm: React.FC = () => {
  const handleSubmit = useCallback(async (values: any) => {
    console.log(values);
  }, []);

  const FormPalletView = withFormik<any, any>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleSubmit(values),
  })(PalletFormView);

  return <FormPalletView />;
};
