import * as Yup from 'yup';

export const BackhaulRegisterSchema = Yup.object().shape({
  offeredPallet: Yup.number().max(32, 'Máximo 99 Paletes').required('Campo Obrigatório'),
  scheduleType: Yup.string().required('Campo Obrigatório'),
  transportedCargo: Yup.string().required('Campo Obrigatório'),
  vehicleType: Yup.string().required('Campo Obrigatório'),
  category: Yup.string().required('Campo Obrigatório'),
  initialDate: Yup.string().required('Campo Obrigatório'),
  endDate: Yup.string().required('Campo Obrigatório'),
  originType: Yup.string().required('Campo Obrigatório'),
  idOrigin: Yup.string().required('Campo Obrigatório'),
  destinationType: Yup.string().required('Campo Obrigatório'),
  idDestination: Yup.string().required('Campo Obrigatório'),
});
