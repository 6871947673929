/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteUploadedFiles, listUploadedFiles, uploadFiles } from '../services/company.service';

export const useHandleUploadFileFileHook = () => {
  return async (companyId: number | string, file: any) => {
    return await uploadFiles(file, companyId);
  };
};

export const useHandleGetFileUploadedListHook = () => {
  return async (companyId: number | string) => {
    return await listUploadedFiles(companyId);
  };
};

export const useHandleDeleteFileUploadedListHook = () => {
  return async (companyId: number | string) => {
    return await deleteUploadedFiles(companyId);
  };
};
