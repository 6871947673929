import { ClientsListActionType } from '../../clients.action';
import { IClientsContext } from '../../types/clients.types';
import { IClientsListActions } from 'src-new/pages/registrations/pages/client/contexts/clients/types/clients-list.types';

export const clientsListReducer = (state: IClientsContext, action: IClientsListActions): IClientsContext => {
  switch (action.type) {
    case ClientsListActionType.CLIENTS_LIST_REQUEST:
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          statusRequest: 'PROCESSING',
        },
      };
    case ClientsListActionType.CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          statusRequest: 'SUCCESS',
          clients: action.payload,
        },
      };
    case ClientsListActionType.CLIENTS_LIST_ERROR:
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          statusRequest: 'ERROR',
          clients: [],
        },
      };
    case ClientsListActionType.CLIENTS_LIST_SET_FILTERS:
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          filterActions: {
            ...state.clientsList.filterActions,
            filters: action.payload,
          },
        },
      };
    case ClientsListActionType.CLIENTS_LIST_RESET:
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          statusRequest: 'INITIAL',
          clients: [],
        },
      };
    default:
      return state;
  }
};
