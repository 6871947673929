import { useNavigate } from 'react-router-dom';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import { useBackhaulOferedHook } from '../hooks/use-ofered-backhaul-list/use-ofered-backhaul-list.hook';
import * as S from '../home.styled';
import { initialBackhaulHomeFilter } from '../constants/backhaul-list';

import { BackhaulTypes } from './types/list';
import { IBackhaulFilter } from './types/filter/filter-backhaul';
import { ListTabs } from './components/tabs';
import { FilterForm } from './components/backhaul-filter/filter';
import { ButtonStyled, ContainerBackhaulTable } from './backhaul.style';
import { BackhaulListBodyView } from './backhaul.list';

import SpotBiddingPage from 'pages/biddings/schedule-list/bidding.page';
import { initialModalState, ModalState } from 'domain-v2/inputs/modals';
import { IUser } from 'domain/login';
import { IBackhaulListValues } from 'domain/backhaul';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { showToast } from 'components/toast/toast';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { TravelIconBlue } from 'assets/icons/travel-icon-blue';
import { ModalAssintPlan } from 'src-new/components/modal-assign-plan/modal-assign-plan.component';
import CrownIcon from 'assets/icons/crown.icon';
import { Loading } from 'src-new/components/loading/loading.component';

export interface BackhaulPageProps {
  user?: IUser;
}

const initialFilter = {
  id: '',
  company: '',
  freightValue: '',
  vehicleCategory: '',
  vehicleType: '',
  originUf: '',
  originCity: '',
  destinationUf: '',
  destinationCity: '',
  backhaulType: '',
  operationType: '',
} as IBackhaulFilter;

const BackhaulPage: React.FC<BackhaulPageProps> = ({ user }) => {
  const [modalAssignPlan, setModalAssignPlan] = useState<boolean>(false);
  const [, setHasFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backhaulFilter, setBackhaulFilter] = useState<IBackhaulFilter>(initialFilter);
  const [backhaul, setBackhaul] = useState<IBackhaulListValues[]>([]);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);
  const [page, setPage] = useState(1);

  const [activeTab, setActiveTab] = useState<BackhaulTypes>('Comprar Ociosidade');

  const navigate = useNavigate();

  const backhaulListHook = useBackhaulOferedHook();

  const handleResponse = async (page: string, values?: IBackhaulFilter) => {
    return await backhaulListHook({
      ...values,
      page: page,
    });
  };

  const handleBackhaulFilter = useCallback(async () => {
    try {
      setIsLoading(true);
      if (page === 1 && backhaulFilter) {
        const res = await handleResponse('1', backhaulFilter);
        setBackhaul(res);
      } else if (Object.keys(backhaulFilter).length) {
        const res = await handleResponse(`${page}`, backhaulFilter);
        setBackhaul((prevData) => [...prevData, ...res]);
      } else {
        const res = await handleResponse(`${page}`);
        setBackhaul((prevData) => [...prevData, ...res]);
      }
    } catch (error: any) {
      showToast({
        type: 'error',
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [page, backhaulFilter]);

  useEffect(() => {
    handleBackhaulFilter();
  }, []);

  const handleOriginCityChange = (values: any, name: string) => {
    const { value } = values.target;
    setBackhaulFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFilter = useCallback(() => {
    setHasFilter(true);
    setPage(1);
    handleBackhaulFilter();
  }, [handleBackhaulFilter]);

  const handleCleanFilter = () => {
    setBackhaulFilter(initialBackhaulHomeFilter);
    setHasFilter(false);
    setPage(1);
    window.location.reload();
  };

  const isAccessRestricted = useMemo(() => {
    if (activeTab === 'Vender Ociosidade' || user?.plan === 'basic-seller' || user?.plan === 'basic-buyer') {
      return <SpotBiddingPage />;
    }
  }, [activeTab, user?.plan]);

  const backhaulList = useMemo(() => {
    if (activeTab === 'Comprar Ociosidade' && user?.plan !== 'basic-seller' && user?.plan !== 'basic-buyer') {
      if (isLoading) return <Loading />;

      return (
        <>
          <FilterForm
            profile={user?.profile || ''}
            backhaulFilter={backhaulFilter}
            handleOriginCityChange={handleOriginCityChange}
            setBackhaulFilter={setBackhaulFilter}
            handleCleanFilter={handleCleanFilter}
            handleFilter={handleFilter}
          />

          <BackhaulListBodyView backhaul={backhaul} isLoading={isLoading} setPage={setPage} />
        </>
      );
    }
  }, [activeTab, user?.plan, user?.profile, backhaulFilter, handleFilter, backhaul, isLoading]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const handleModalAssignPlan = useCallback((value: BackhaulTypes) => {
    if (value !== 'Vender Ociosidade') {
      setModalAssignPlan(true);
    }
  }, []);

  const renderListTabs = useMemo((): ReactElement => {
    if (user?.plan === 'basic-seller' || user?.plan === 'basic-buyer') {
      return (
        <ListTabs
          contents={['Vender Ociosidade', 'Comprar Ociosidade']}
          isPressed={'Vender Ociosidade'}
          icon={{
            icon: <CrownIcon />,
            index: 1,
          }}
          setIsPressed={(value) => handleModalAssignPlan(value)}
        />
      );
    }

    return (
      <ListTabs
        contents={['Comprar Ociosidade', 'Vender Ociosidade']}
        isPressed={activeTab}
        setIsPressed={setActiveTab}
      />
    );
  }, [activeTab, handleModalAssignPlan, user?.plan]);

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}
      {modalAssignPlan && <ModalAssintPlan handleCloseModal={() => setModalAssignPlan(false)} />}
      <HeaderPage />
      <S.Container>
        <S.ContainerTextAndButton>
          <S.TextContainer>
            <S.HelloUser>Bem-vindo à LogShare!</S.HelloUser>
            <S.SalutationBackhaul>
              Integre sua malha logística com outros embarcadores para compra e venda de ociosidades.
            </S.SalutationBackhaul>
          </S.TextContainer>
        </S.ContainerTextAndButton>
      </S.Container>

      <ContainerBackhaulTable>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <div style={{ display: 'flex' }}>{renderListTabs}</div>

          <div
            style={{
              display: 'flex',
              gap: 10,
              position: 'relative',
              bottom: 10,
            }}
          >
            <ButtonStyled color="blue" variant="ghost" onClick={() => navigate('/fretes')}>
              <TravelIconBlue />
              Tracking
            </ButtonStyled>

            <ButtonStyled color="blue" variant="solid" onClick={() => handleCreateSchedulePage(true)}>
              <InsertInvitationIcon sx={{ height: '18px', width: '18px' }} />
              Novo Frete
            </ButtonStyled>
          </div>
        </div>

        {backhaulList}
        {isAccessRestricted}
      </ContainerBackhaulTable>
    </>
  );
};

export default BackhaulPage;
