import { FC, useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsHeaderView } from './schedule-details-header-view.component';
import { UserContext } from 'state/user-context';
import { UseUpdateAcceptDecline } from '../../hooks/use-update-accept-decline-by-ids/use-update-accept-decline-by-ids.hook';
import { useUpdateReofferTrip } from '../../hooks/use-update-reoffer-trip/use-update-reoffer-trip.hook';
import { useNavigate } from 'react-router-dom';
import { useResendCollectionOrderEmail } from '../../hooks/use-resend-collect-order-email/use-resend-collect-order-email.hook';
import { ScheduleListModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';

export const ScheduleDetailsHeader: FC = () => {
  const { isShippingCompany, user } = useContext(UserContext);
  const { scheduleDetails, scheduleList } = useContext(ScheduleContext);

  const navigate = useNavigate();
  const updateAcceptDeclineHook = UseUpdateAcceptDecline();
  const updateReofferTripHook = useUpdateReofferTrip();
  const resendCollectionOrderEmailHook = useResendCollectionOrderEmail();

  const handleAllocationAcceptOrDeny = useCallback(
    async (statusCode: string) => {
      await updateAcceptDeclineHook({
        ids: [Number(scheduleDetails.scheduleDetails.id)],
        statusCode: statusCode,
      });
      navigate(-1);
    },
    [navigate, scheduleDetails.scheduleDetails.id, updateAcceptDeclineHook],
  );

  const handleReofferTrip = useCallback(() => {
    updateReofferTripHook();
  }, [updateReofferTripHook]);

  const handleResendCollectOrderEmail = useCallback(() => {
    resendCollectionOrderEmailHook();
  }, [resendCollectionOrderEmailHook]);

  const handleOpenModal = useCallback(
    (scheduleId: number, modalType: ScheduleListModalType) => {
      scheduleList.modalsProps.setModalOpen({ open: true, ids: [scheduleId], modalType });
    },
    [scheduleList.modalsProps],
  );

  const checkSellerOrShipping = useMemo(() => {
    if (user?.companyId === scheduleDetails.scheduleDetails.company.id) return true;
    if (isShippingCompany) return true;

    return false;
  }, [isShippingCompany, scheduleDetails.scheduleDetails.company.id, user?.companyId]);

  const checkAllocationAccordions = useMemo(
    (): boolean =>
      !!scheduleDetails.scheduleDetails.shippingId &&
      !!scheduleDetails.scheduleDetails.vehicleId &&
      !!scheduleDetails.scheduleDetails.driverId,
    [
      scheduleDetails.scheduleDetails.driverId,
      scheduleDetails.scheduleDetails.shippingId,
      scheduleDetails.scheduleDetails.vehicleId,
    ],
  );

  const schedulingTabName = useMemo(
    (): boolean => scheduleDetails.activeTabName.activeTabName === 'Agendamento',
    [scheduleDetails.activeTabName.activeTabName],
  );

  const statusCodes = useMemo((): {
    accepted: boolean;
    reofferCollectOrder: boolean;
  } => {
    const accepted = ['OT01', 'OT10'];
    const reofferCollectOrder = ['IN10', 'IN11', 'IN20', 'IN30', 'IN40', 'IN50'];

    return {
      accepted: accepted.includes(scheduleDetails.scheduleDetails.statusCode),
      reofferCollectOrder: reofferCollectOrder.includes(scheduleDetails.scheduleDetails.statusCode),
    };
  }, [scheduleDetails.scheduleDetails.statusCode]);

  return (
    <ScheduleDetailsHeaderView
      schedule={scheduleDetails.scheduleDetails}
      isShippingCompany={isShippingCompany}
      checkSellerOrShipping={checkSellerOrShipping}
      checkAllocationAccordions={checkAllocationAccordions}
      schedulingTabName={schedulingTabName}
      statusCodes={statusCodes}
      handleAllocationAcceptOrDeny={handleAllocationAcceptOrDeny}
      handleReofferTrip={handleReofferTrip}
      handleResendCollectOrderEmail={handleResendCollectOrderEmail}
      handleOpenModal={handleOpenModal}
    />
  );
};
