import { IWeighingListFilters } from 'src-new/pages/execution/pages/weighing/types/weighing-list-filters/weighing-list-filters.types';

interface Output {
  search: string;
  page: number;
  limit: number;
  ticketStatus: Array<string>;
  driverName: string;
  shippingComanyName: string;
  vehicleMainPlate: string;
  vehicleCategory: string;
  collectOrder: string;
  operation: string;
  originCity: string;
  destinationCity: string;
  column: string;
  order: string;
  itemId: number;
}
export const convertDomainToEntity = (domain: IWeighingListFilters): Output => {
  return {
    ...domain,
    ticketStatus: domain.status,
    shippingComanyName: domain.shippingCompany,
    vehicleMainPlate: domain.vehiclePlate,
    destinationCity: domain.destinyCity,
  };
};
