import { includes } from 'lodash';
import { ClientsActionType, ClientsListActionType } from '../clients.action';
import { clientsReducerResetState } from './clients-reducer.constants';
import {
  IClientsActions,
  IClientsContext,
} from 'src-new/pages/registrations/pages/client/contexts/clients/types/clients.types';
import { IClientsListActions } from 'src-new/pages/registrations/pages/client/contexts/clients/types/clients-list.types';
import { clientsListReducer } from 'src-new/pages/registrations/pages/client/contexts/clients/reducers/clients-list/clients-list.reducer';

export const clientsReducer = (state: IClientsContext, action: IClientsActions): IClientsContext => {
  if (includes(Object.values(ClientsListActionType), action.type)) {
    return clientsListReducer(state, action as IClientsListActions);
  }

  if (action.type === ClientsActionType.CLIENTS_RESET) {
    return clientsReducerResetState(state);
  }

  return state;
};
