import { ScheduleInput } from 'domain-v2/schedule';

export const scheduleFindInitialValues: ScheduleInput = {
  id: 0,
  origin: {
    id: 0,
    type: '',
    name: '',
    cnpj: '',
    address: {
      cep: '',
      city: '',
      complement: '',
      neighborhood: '',
      number: '',
      qualityAndSafety: '',
      street: '',
      uf: '',
    },
    emails: [''],
    qualityAndSafety: '',
  },
  destination: {
    id: 0,
    type: '',
    name: '',
    cnpj: '',
    address: {
      cep: '',
      city: '',
      complement: '',
      neighborhood: '',
      number: '',
      qualityAndSafety: '',
      street: '',
      uf: '',
    },
    emails: [''],
    qualityAndSafety: '',
  },
  cargo: {
    vehicleType: '',
    vehicleCategoryType: '',
    shippingReturn: '',
    product: '',
    weight: 0,
    cubagem: 0,
    volume: 0,
    merchValue: 0,
    inPallet: false,
    pallet: 0,
    order: '',
    comments: '',
    requirements: {
      quality: {
        id: 0,
        values: [],
      },
      security: {
        id: 0,
        values: [],
      },
      custom: {
        id: 0,
        values: [],
      },
    },
  },
  detail: {
    levyInitialDate: '',
    levyEndDate: '',
    deliveryInitialDate: '',
    deliveryEndDate: '',
    internalNumber: '',
    ocInternalNumber: '',
    boardingNumber: '',
    schedulingPassword: '',
  },
  retentionFines: {
    freeTime: '',
    vehicleType: '',
    hourlyFine: 0,
    dailyFine: 0,
  },
  distance: 0,
  operation: 'TRACKING',
  co2: 0,
  invoicedBy: 'EMBARCADOR',
  operationType: 'INBOUND',
  statusCode: 'AG10',
  type: 'ftl',
  expirationTime: '',
  company: {
    id: 0,
    tradeName: '',
  },
  shippingId: 0,
  vehicleId: 0,
  driverId: 0,
  descriptionCancel: '',
  companyBuyer: {
    id: 0,
    name: '',
    gris: 0,
    advalorem: 0,
  },
  freithgtPayable: {
    id: 0,
    valueFreight: 0,
    gris: 0,
    advalorem: 0,
    totalFreight: 0,
    totalService: 0,
    helperTotal: 0,
    toll: 0,
    totalGrisAdvalorem: 0,
    icms: 0,
    discharge: 0,
    feeAnticipation: 0,
    iss: 0,
    otherTaxes: 0,
    totalFee: 0,
    surplusTime: 0,
    penaltyBonus: 0,
    status: 'Pagar',
  },
  freithgtReceivable: {
    id: 0,
    valueFreight: 0,
    gris: 0,
    advalorem: 0,
    totalFreight: 0,
    totalService: 0,
    helperTotal: 0,
    toll: 0,
    totalGrisAdvalorem: 0,
    icms: 0,
    discharge: 0,
    feeAnticipation: 0,
    iss: 0,
    otherTaxes: 0,
    totalFee: 0,
    surplusTime: 0,
    penaltyBonus: 0,
    status: 'Receber',
  },
  freithgtShipping: {
    id: 0,
    valueFreight: 0,
    gris: 0,
    advalorem: 0,
    totalFreight: 0,
    totalService: 0,
    helperTotal: 0,
    toll: 0,
    totalGrisAdvalorem: 0,
    icms: 0,
    discharge: 0,
    feeAnticipation: 0,
    iss: 0,
    otherTaxes: 0,
    totalFee: 0,
    surplusTime: 0,
    penaltyBonus: 0,
    status: 'Receber Transportador',
  },
  customFields: [],
  freightModality: 'recommended',
};
