import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { registerSaleOfferBiddingService } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/services/register-sale-offer-match-bidding/register-sale-offer-bidding.service';
import { ISaleOfferBiddingDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-bidding-register.domain';

export const useRegisterSaleOfferBidding = () => {
  return useCallback(async (payload: ISaleOfferBiddingDomain) => {
    try {
      await registerSaleOfferBiddingService({ ...payload, idlenessId: payload.purchaseId });
      showMessageFormatted({
        message: 'Proposta de venda realizada com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, []);
};
