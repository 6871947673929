import { useCallback } from 'react';

import { getAddressInfoByCep } from 'services/cep/cep.services';
import { IAddress } from 'domain/address';

const useCepHook = () => {
  return useCallback(async (cep: string): Promise<IAddress> => {
    return getAddressInfoByCep(cep);
  }, []);
};

export { useCepHook };
