import React, { useMemo, useState } from 'react';
import * as S from './documents-checklist-view.styled';
import { Table } from 'src-new/components/table/table.component';
import { IRow } from 'src-new/components/table/table.types';
import { Button } from 'logshare-ui-kit';
import { Eye, Trash2 } from 'lucide-react';
import { IDocumentschecklist } from './documents-checklist.types';
import { ToogleList } from 'src-new/components/toogle-list/toogle-list.component';
import { ListIcon } from 'assets/icons/list.icon';
import { KanbanListIcon } from 'assets/icons/kanban-list.icon';
import { Box } from 'src-new/components/box/box.component';
import TooltipComponent from 'components/Tooltip';
import { Loading } from 'src-new/components/loading/loading.component';
import dayjs from 'dayjs';

export const DocumentsChecklistView: React.FC<IDocumentschecklist> = ({
  checklistDocuments,
  isLoading,
  handleVisible,
  setShowDeleteModal,
}) => {
  const [viewMode, setViewMode] = useState<'list' | 'kanban'>('list');
  const renderStatus = (status: string) => <S.Status status={status.toLowerCase()}>{status.toUpperCase()}</S.Status>;
  const renderTitleSubtitle = (title: string, subtitle?: string) => (
    <div>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle ?? '-'}</S.Subtitle>
    </div>
  );

  const rows = useMemo((): Array<IRow> => {
    return checklistDocuments.map((params) => {
      return {
        key: params.id,
        valuesColumns: [
          { value: renderTitleSubtitle('', params.checklistName) },
          { value: renderTitleSubtitle('Preenchido em', dayjs(params.data).format('DD/MM/YYYY HH:mm')) },
          { value: renderTitleSubtitle('Resposável', params.owner) },
          { value: renderStatus(params.status) },
          {
            value: (
              <S.WrapperActions key={params.id + params.checklistName}>
                <TooltipComponent
                  title="Visualizar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        label=""
                        color="white"
                        LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                        onPress={() => handleVisible(params.id)}
                      />
                    </S.ContainerButtons>
                  }
                />
                <TooltipComponent
                  title="Deletar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        label=""
                        color="white"
                        LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                        size="x-sm"
                        variant="ghost"
                        onPress={() => {
                          setShowDeleteModal({ id: params.id, open: true });
                        }}
                      />
                    </S.ContainerButtons>
                  }
                />
              </S.WrapperActions>
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [checklistDocuments, handleVisible, setShowDeleteModal]);

  const buildBoxes = useMemo(() => {
    const boxList = checklistDocuments.map((params) => {
      return (
        <S.Box key={params.fileKey}>
          <Box
            footerButtons={[
              <TooltipComponent
                key={params.id}
                title="Visualizar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      label=""
                      color="white"
                      LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                      size="small"
                      variant="ghost"
                      onPress={() => handleVisible(params.id)}
                    />
                  </S.ContainerButtons>
                }
              />,
              <TooltipComponent
                key={params.id}
                title="Deletar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      label=""
                      color="white"
                      LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      onPress={() => {
                        setShowDeleteModal({ id: params.id, open: true });
                      }}
                    />
                  </S.ContainerButtons>
                }
              />,
            ]}
          >
            <S.BoxContent>
              <S.InfoWrapper>
                <S.Subtitle>{params.checklistName ?? '-'}</S.Subtitle>
                {renderStatus(params.status)}
              </S.InfoWrapper>
              <S.InfoWrapper>
                {renderTitleSubtitle('Preenchido em', dayjs(params.data).format('DD/MM/YYYY HH:mm'))}
                {renderTitleSubtitle('Responsável', params.owner)}
              </S.InfoWrapper>
            </S.BoxContent>
          </Box>
        </S.Box>
      );
    });

    return <S.BoxWrapper gridColumns="1fr 1fr 1fr">{boxList}</S.BoxWrapper>;
  }, [checklistDocuments, handleVisible]);

  const renderContent = useMemo(() => {
    return viewMode === 'list' ? (
      <Table
        gridColumns="1fr 1fr 1fr 1fr 106px"
        header={[{ value: '' }]}
        rows={rows}
        height="100%"
        config={{
          hideHeader: true,
          body: {
            changeLineOnMouseOver: true,
          },
        }}
      />
    ) : (
      buildBoxes
    );
  }, [buildBoxes, rows, viewMode]);

  if (isLoading) {
    return (
      <S.ContainerLoading>
        <Loading />
      </S.ContainerLoading>
    );
  }

  return (
    <S.Wrapper>
      <S.WrapperToogle>
        <ToogleList
          isPressed={viewMode === 'list'}
          leftIcon={<ListIcon />}
          rightIcon={<KanbanListIcon />}
          handleToogleClick={() => setViewMode(viewMode === 'list' ? 'kanban' : 'list')}
        />
      </S.WrapperToogle>

      {renderContent}
    </S.Wrapper>
  );
};
