import { ICompany } from 'domain/company';
import { IUser } from 'domain/login';
import { ISchedulingKeys } from 'domain/schedule';
import { BiddingRegister } from 'pages-v2/schedule/allocation-bidding';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';
import { getScheduleByIdService } from '../../services/get-schedule-by-id';
import { scheduleFindInitialValues } from './initial-values';

interface IScheduleFormProps {
  company: ICompany;
  user: IUser | undefined;
  schedulingKeys?: ISchedulingKeys[];
  handleAccessRestricted: (accessRestricted: boolean) => void;
}

const ScheduleUpdateForm: React.FC<IScheduleFormProps> = ({ user, handleAccessRestricted }) => {
  const { id } = useParams();

  const { data, refetch } = useQuery({
    queryKey: ['scheduleAllocationGet', id],
    queryFn: () => getScheduleByIdService(Number(id)),
    enabled: false,
    onError: (error: any) => {
      if (error.response.data.message[0] === 'Não autorizado') {
        handleAccessRestricted(true);
      }
    },
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  if (data && data.statusCode !== 'SH01') {
    return <Navigate to={`/fretes/${id}`} replace />;
  }

  return <BiddingRegister schedule={data || scheduleFindInitialValues} user={user} />;
};

export default ScheduleUpdateForm;
