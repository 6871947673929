import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Category = styled.div`
  ${() => css`
    display: grid;
    grid-row: 1fr 1fr;
    grid-gap: 4px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 54px;
  `}
`;

interface InfoProps {
  tagSize: boolean;
}

export const Info = styled.div<InfoProps>`
  ${({ tagSize }) => css`
    max-width: ${tagSize ? '7.5vw' : '10.5vw'};
    font-weight: 600;
  `}
`;

export const Title = styled.div`
  ${() => css`
    color: #000;
    font-family: Montserrat, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const Subtitle = styled.div`
  ${() => css`
    color: #7b7b7b;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  justify-content: space-between;

  div {
    width: min-content;
  }
`;

export const DropdownButtonTable = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ContainerTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2px;
`;

export const ContainerHeaderTooltip = styled.div``;

export const ContainerInfosTooltip = styled.div``;

export const LabelTooltip = styled.p``;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  `}
`;

export const EmptyListWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  `}
`;
