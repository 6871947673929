import { companyUpdateService } from '../../services/company-update/company-update.service';

import { ICompany } from 'domain/company';

const useUpdateCompanyHook = () => {
  return async (company: ICompany, branchesToRemove: number[]) => {
    await companyUpdateService(company, branchesToRemove);
  };
};

export { useUpdateCompanyHook };
