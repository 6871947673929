import { useCallback, useContext } from 'react';

import { getFreightByIdService } from 'pages/freight/services/get-schedule-by-id/get-schedule-by-id.service';
import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';

const useGetFreightByIdHook = () => {
  const { currentAllocationError, currentAllocationRequest, currentAllocationSuccess } =
    useContext(AllocationRegisterContext);

  return useCallback(
    async (scheduleId: string) => {
      currentAllocationRequest();

      try {
        const schedule = await getFreightByIdService(scheduleId);
        currentAllocationSuccess(schedule);
      } catch (err) {
        currentAllocationError('Ocorreu um erro ao tentar consultar a Alocação');
      }
    },
    [currentAllocationError, currentAllocationRequest, currentAllocationSuccess],
  );
};

export { useGetFreightByIdHook };

const useGetScheduleByIdHook = () => {
  return async (scheduleId: string) => {
    const scheduleDetails = await getFreightByIdService(scheduleId);
    return scheduleDetails;
  };
};

export { useGetScheduleByIdHook };
