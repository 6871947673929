import React from 'react';
import * as S from './loading-view.styled';
import TruckLoadingIcon from 'assets/loading-v4.gif';

interface LoadingViewProps {
  width?: number;
  height?: number;
}

export const LoadingView: React.FC<LoadingViewProps> = ({ width, height }) => {
  return (
    <S.LoadingWrapper>
      <S.LoadingStyled>
        <S.LoadingContainer>
          <img
            style={{ width: width ? width : 70, height: height ? height : 70 }}
            src={TruckLoadingIcon}
            alt={'spinner'}
          />
        </S.LoadingContainer>
      </S.LoadingStyled>
    </S.LoadingWrapper>
  );
};
