import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useParams } from 'react-router-dom';
import { weighingUpdateService } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/services/weighing-update/weighing-update.service';
import { convertWeighingUpdateDomainToEntity } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/hooks/use-weighing-update/mappers/convert-weighing-update-domain-to-entity/convert-weighing-update-domain-to-entity.mapper';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

export const useUpdateWeighing = () => {
  const reactQueryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const weighingId = useMemo(() => Number(id) ?? '', [id]);

  return useCallback(
    async (payload: IWeighingDomain) => {
      try {
        await weighingUpdateService(weighingId, convertWeighingUpdateDomainToEntity(payload));
        await reactQueryClient.invalidateQueries(['weighing-list-table']);
        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient, weighingId],
  );
};
