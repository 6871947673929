import { Button } from 'logshare-ui-kit';
import { Pencil, Plus } from 'lucide-react';
import { FC, Fragment, ReactElement, useMemo } from 'react';
import { Accordion } from 'src-new/components/accordion/accordion.component';
import { AccordionContentType } from 'src-new/components/accordion/accordion.types';
import { IScheduleDetailsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-details.types';
import { ScheduleDetailsAllocationDriverModal } from './components/schedule-details-allocation-driver-modal/schedule-details-allocation-driver-modal.component';
import { ScheduleDetailsAllocationDriver } from './components/schedule-details-allocation-driver/schedule-details-allocation-driver.component';
import { ScheduleDetailsAllocationShippingModal } from './components/schedule-details-allocation-shipping-modal/schedule-details-allocation-shipping-modal.component';
import { ScheduleDetailsAllocationShipping } from './components/schedule-details-allocation-shipping/schedule-details-allocation-shipping.component';
import { ScheduleDetailsAllocationVehicleModal } from './components/schedule-details-allocation-vehicle-modal/schedule-details-allocation-vehicle-modal.component';
import { ScheduleDetailsAllocationVehicle } from './components/schedule-details-allocation-vehicle/schedule-details-allocation-vehicle.component';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { OccurenceListIcon } from 'assets/icons/occurrence-list.icon';
import { ConfirmDriverModal } from './components/confirm-driver-modal/confirm-driver-modal.component';
import {
  DriverAccordionSubtitleStyled,
  WrapperAccordionsStyled,
} from './schedule-details-allocation-accordions-view.style';

interface IScheduleDetailsAllocationAccordionsViewProps {
  checkedAccordions: {
    shippingId: boolean;
    vehicleId: boolean;
    driverId: boolean;
  };
  modalState: {
    allocatedEntity: IScheduleDetailsModalType;
    isOpen: boolean;
  };
  handleOpenModalAllocation: (allocatedEntity: 'shipping' | 'vehicle' | 'driver') => void;
  isDenied: boolean;
  isShippingCompany: boolean;
  scheduleCanceled: boolean;
  disableDriver: boolean;
}

export const ScheduleDetailsAllocationAccordionsView: FC<IScheduleDetailsAllocationAccordionsViewProps> = ({
  checkedAccordions,
  modalState,
  handleOpenModalAllocation,
  isDenied,
  isShippingCompany,
  scheduleCanceled,
  disableDriver,
}) => {
  const renderDriverButton = useMemo((): ReactElement => {
    if (disableDriver) {
      return (
        <Fragment>
          <DriverAccordionSubtitleStyled>
            Informe a SMP para permitir a alocação do motorista
          </DriverAccordionSubtitleStyled>
          <OccurenceListIcon color={ThemeColorsV2Type.Red} />
          <Button
            label="Alocar"
            color="white"
            variant="ghost"
            onPress={() => handleOpenModalAllocation('driver')}
            LeftIcon={<Plus size={16} />}
            size="medium"
            disabled
          />
        </Fragment>
      );
    }

    return (
      <Button
        label={checkedAccordions.driverId ? 'Editar' : 'Alocar'}
        color="white"
        variant="ghost"
        onPress={() => handleOpenModalAllocation('driver')}
        LeftIcon={checkedAccordions.driverId ? <Pencil size={16} /> : <Plus size={16} />}
        size="medium"
        disabled={scheduleCanceled}
      />
    );
  }, [checkedAccordions.driverId, disableDriver, handleOpenModalAllocation, scheduleCanceled]);

  const mountAccordions = useMemo((): Array<AccordionContentType> => {
    if (isDenied) {
      return [
        {
          title: 'Transportadora',
          content: <ScheduleDetailsAllocationShipping />,
          buttonOpen: checkedAccordions.shippingId,
          button: (
            <Button
              label={checkedAccordions.shippingId ? 'Editar' : 'Alocar'}
              color="white"
              variant="ghost"
              onPress={() => handleOpenModalAllocation('shipping')}
              LeftIcon={checkedAccordions.shippingId ? <Pencil size={16} /> : <Plus size={16} />}
              size="medium"
              disabled={isShippingCompany}
            />
          ),
          checked: checkedAccordions.shippingId,
        },
      ];
    }

    return [
      {
        title: 'Transportadora',
        content: <ScheduleDetailsAllocationShipping />,
        buttonOpen: checkedAccordions.shippingId,
        button: (
          <Button
            label={checkedAccordions.shippingId ? 'Editar' : 'Alocar'}
            color="white"
            variant="ghost"
            onPress={() => handleOpenModalAllocation('shipping')}
            LeftIcon={checkedAccordions.shippingId ? <Pencil size={16} /> : <Plus size={16} />}
            size="medium"
            disabled={scheduleCanceled || isShippingCompany}
          />
        ),
        checked: checkedAccordions.shippingId,
      },
      {
        title: 'Veículo',
        content: <ScheduleDetailsAllocationVehicle />,
        buttonOpen: checkedAccordions.vehicleId,
        button: (
          <Button
            label={checkedAccordions.vehicleId ? 'Editar' : 'Alocar'}
            color="white"
            variant="ghost"
            onPress={() => {
              handleOpenModalAllocation('vehicle');
            }}
            LeftIcon={checkedAccordions.vehicleId ? <Pencil size={16} /> : <Plus size={16} />}
            size="medium"
            disabled={scheduleCanceled}
          />
        ),
        checked: checkedAccordions.vehicleId,
      },
      {
        title: 'Motorista',
        content: <ScheduleDetailsAllocationDriver />,
        buttonOpen: checkedAccordions.driverId,
        button: renderDriverButton,
        checked: checkedAccordions.driverId,
      },
    ];
  }, [checkedAccordions, handleOpenModalAllocation, isDenied, isShippingCompany, renderDriverButton, scheduleCanceled]);

  return (
    <Fragment>
      {modalState.isOpen && modalState.allocatedEntity === 'shipping' && <ScheduleDetailsAllocationShippingModal />}
      {modalState.isOpen && modalState.allocatedEntity === 'vehicle' && <ScheduleDetailsAllocationVehicleModal />}
      {modalState.isOpen && modalState.allocatedEntity === 'driver' && <ScheduleDetailsAllocationDriverModal />}
      {modalState.isOpen && modalState.allocatedEntity === 'confirm-driver' && <ConfirmDriverModal />}
      <WrapperAccordionsStyled>
        <Accordion contents={mountAccordions} />
      </WrapperAccordionsStyled>
    </Fragment>
  );
};
