/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'utils/API/API';
import { showToast } from 'components/toast/toast';

export interface ReworkFileUrl {
  id: number;
  fileKey: string;
  reworkId: number;
  file?: File;
  type?: string;
}

export const getReworkFileUrlByIdService = async (reworkId: string): Promise<Array<ReworkFileUrl>> => {
  try {
    const { data } = await API.get(`v2/rework/file-upload/${reworkId}`);
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao buscar detalhes da agendamento');
  }
};

export const removeReworkFile = async (fileId: number) => {
  try {
    await API.delete<any>(`v2/rework/file-upload/${fileId}`);

    showToast({
      type: 'success',
      message: 'Foto removida com sucesso',
    });
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar a remoção da imagem',
    });
  }
};

export const sendReworkFile = async (reworkId: string, files: any) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    const { data } = await API.post<any>(`v2/rework/file-upload/${reworkId}`, formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload da imagem',
    });
    throw new Error('Houve um erro ao realizar o upload da imagem');
  }
};

export const allocationCancel = async (
  allocationsId: Array<number>,
  type: 'spot' | 'backhaul',
  descriptionCancel?: string,
): Promise<void> => {
  await API.delete('v2/schedules/cancel', {
    data: {
      ids: allocationsId,
      type: type,
      descriptionCancel: descriptionCancel,
    },
  });
};
