import { IAllocationRegisterContext } from './allocation.types';

export const initialState: IAllocationRegisterContext = {
  allocation: {
    id: 0,
    status: '',
    companyId: 0,
    cubagem: '',
    comments: '',
    company: '',
    driver: undefined,
    vehicle: undefined,
    shippingCompany: undefined,
    scheduleCargo: undefined,
    scheduleClientDestiny: undefined,
    scheduleClientOrigin: undefined,
    scheduleDetails: undefined,
    multiVehiclesDrivers: [{ vehicleId: 0, driverId: 0 }],
  },
  infoRules: {},
  loading: false,
  nfeIsLoading: 0,
  nfeLoading: () => undefined,
  setDriver: () => undefined,
  setShippingCompany: () => undefined,
  setVehicle: () => undefined,
  currentAllocationError: () => undefined,
  currentAllocationRequest: () => undefined,
  currentAllocationSuccess: () => undefined,
  setResumeRules: () => undefined,
};

export enum IAllocationsAction {
  SET_RESUME_RULES = 'SET_RESUME_RULES',
  ALLOCATION_VEHICLE_LOAD = 'ALLOCATION_VEHICLE_LOAD',
  ALLOCATION_DRIVER_LOAD = 'ALLOCATION_DRIVER_LOAD',
  ALLOCATION_SHIPPING_COMPANY_LOAD = 'ALLOCATION_SHIPPING_COMPANY_LOAD',
  GET_CURRENT_ALLOCATION_REQUEST = 'GET_CURRENT_ALLOCATION_REQUEST',
  GET_CURRENT_ALLOCATION_SUCCESS = 'GET_CURRENT_ALLOCATION_SUCCESS',
  GET_CURRENT_ALLOCATION_ERROR = 'GET_CURRENT_ALLOCATION_ERROR',
}
