import { useCallback, useContext } from 'react';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { showToast } from 'components/toast/toast';
import { getOfferFileReportService } from 'src-new/pages/opportunities/services/get-offer-file-report/get-offer-file-report.service';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';

export const useDownloadSaleOfferReport = () => {
  const { saleOfferListDownloadReport } = useContext(SaleOfferContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { setSaleListDownloadReportRequest, setSaleListDownloadReportSuccess, setSaleListDownloadReportError } =
    saleOfferListDownloadReport;
  const { filters } = pageHeaderFilterContext;

  return useCallback(async () => {
    setSaleListDownloadReportRequest();
    try {
      const file: ArrayBuffer | undefined = await getOfferFileReportService(filters, 'sale');

      if (file) {
        downloadXlsxFileUtil(file, 'relatório-ofertas-venda');
      }

      setSaleListDownloadReportSuccess();
      showToast({
        message: 'Relatório gerado com sucesso.',
        type: 'success',
      });
    } catch (error) {
      setSaleListDownloadReportError();
      showToast({
        message: 'Erro ao tentar exportar relatório.',
        type: 'error',
      });
    }
  }, [filters, setSaleListDownloadReportError, setSaleListDownloadReportRequest, setSaleListDownloadReportSuccess]);
};
