import { IFreightContext } from '../types/freight.types';

export const freightReducerResetState = (state: IFreightContext): IFreightContext => {
  return {
    ...state,
    freightChat: {
      ...state.freightChat,
      chatActions: {
        ...state.freightChat.chatActions,
        isOpen: false,
      },
    },
  };
};
