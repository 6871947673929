import {
  updatePlanningShippingService,
  updatePlanningVehicleService,
} from 'pages-v2/my-route/service/update-planning-vehicle';
import { PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';

const UseUpdatePlanningVehicle = async (planningVehicle: PlanningRouteVehicle) => {
  await updatePlanningVehicleService(Number(planningVehicle.id), planningVehicle);
};

const UseUpdatePlanningShipping = async (planningShipping: PlanningRouteShipping) => {
  await updatePlanningShippingService(Number(planningShipping.id), {
    ...planningShipping,
    plannedTrips: Number(planningShipping.plannedTrips),
  });
};

export { UseUpdatePlanningShipping, UseUpdatePlanningVehicle };
