import React, { MouseEvent, ChangeEvent, useCallback, useContext, useState } from 'react';
import { ModalAssintPlanView } from './modal-assign-plan-view.component';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { modalAssignPlanInitialValues } from './modal-assign-plan.constants';
import { IAssignPlan } from './modal-assign-plan.types';
import { useSendEmailAssignPlan } from 'src-new/hooks/use-send-email-assign-plan/use-send-email-assign-plan.hook';

interface IModalAssintPlanProps {
  handleCloseModal: () => void;
}

export const ModalAssintPlan: React.FC<IModalAssintPlanProps> = ({ handleCloseModal }) => {
  const { user } = useContext(UserContext);

  const [error, setError] = useState<boolean>(false);
  const [form, setForm] = useState<IAssignPlan>({
    ...modalAssignPlanInitialValues,
    companyId: user?.companyId,
  });

  const sendEmailAssignPlanHook = useSendEmailAssignPlan();

  const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      handleCloseModal();
    }
  };

  const handleSubmit = useCallback(
    async () => sendEmailAssignPlanHook(form, handleCloseModal),
    [form, handleCloseModal, sendEmailAssignPlanHook],
  );

  const handleValidation = useCallback(() => {
    if (form.name === '' || form.email === '' || form.phone === '') {
      showMessageFormatted({
        message: 'É necessário preencher todos os campos.',
        type: 'error',
      });
      setError(true);
      return;
    }

    handleSubmit();
  }, [form.email, form.name, form.phone, handleSubmit]);

  const handleChange = useCallback(
    (value: ChangeEvent<HTMLInputElement>) => setForm({ ...form, [value.target.name]: value.target.value }),
    [form],
  );

  return (
    <ModalAssintPlanView
      form={form}
      error={error}
      handleSubmit={handleValidation}
      handleChange={handleChange}
      handleCloseModal={handleCloseModal}
      handleBackgroundClick={handleBackgroundClick}
    />
  );
};
