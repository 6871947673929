import styled, { css } from 'styled-components';

export const RootWrapper = styled.div`
  margin-top: auto;
  max-width: 100%;
  min-height: 80px;
  background-color: white;
`;

type TraceProps = { withoutButton?: boolean; withoutTrace?: boolean };
export const Trace = styled.hr<TraceProps>`
  ${({ withoutButton, withoutTrace, theme }) => css`
    margin-bottom: ${withoutButton ? '50px' : '20px'};
    border: ${withoutTrace ? 0 : '1px'} solid ${theme.colors.forBorder};
  `}
  margin-top: 18px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 15px;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
