import styled, { css } from 'styled-components';

export const WrapperHeader = styled.div`
  display: flex;
  margin-top: -20px;
  padding-bottom: 34px;
  position: relative;
  width: 100%;
  justify-content: space-between;
`;

export const WrapperTextAndButton = styled.div`
  margin-top: -11px;
  button:nth-last-child(2) {
    margin: 0px 10px;
  }
`;

export const WrapperText = styled.div`
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  justify-content: center;
  align-items: Left;
  margin-bottom: 15px;
  gap: 10px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 32px;
    font-weight: bold;
    color: ${theme.colors.blue};
    margin-bottom: -3px;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: ${theme.font.normal};
    color: #727272;
    margin-bottom: -35px;
  `}
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

export const TabsAndButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  bottom: 10px;
`;
