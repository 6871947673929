import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    background-image: url('src/assets/bg-totten.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: ${theme.colorsV4.logshareBlue};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorsV4.white};
    font-size: 1.8rem;
    text-align: center;
    margin-top: 20px;
  `}
`;

export const Form = styled.div`
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 50vw;
  flex: 0.7;
`;

export const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: 6px;
  justify-content: center;

  img {
    width: 80%;
  }
`;

export const WrapperSearching = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const Loading = styled.img`
  height: 100px;
  width: 100px;
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 40vh;
`;
