import { IEquipmentVoucherContext } from './types/equipment-voucher.types';

export const equipmentVoucherInitialState: IEquipmentVoucherContext = {
  equipmentVoucherList: {
    statusRequest: 'INITIAL',
    equipmentList: [],
    deleteModalProps: {
      isLoading: false,
      isOpen: false,
      setOpenModal: () => undefined,
      setCloseModal: () => undefined,
      setIsLoadingModal: () => undefined,
    },
    selectionProps: {
      selectedIds: [],
      setSelectedIds: () => undefined,
    },
    setEquipmentVoucherListRequest: () => undefined,
    setEquipmentVoucherListSuccess: () => undefined,
    setEquipmentVoucherListError: () => undefined,
    setEquipmentVoucherListReset: () => undefined,
  },
  setEquipmentVoucherResetContext: () => undefined,
};
