import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { SectionStyled } from '../section/section.styled';
import { LineSectionStyled } from '../line-section/line-section.styled';
import { IFormSectionProps } from '../../../types/form-section.types';

import { LocationScheduleData } from 'utils/create-schedule-location/create-schedule-location';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';

interface IProps {
  optionTypes: Array<{ label: string; value: string }>;
  optionsLocality: Array<{ label: string; value: string }>;
}

export type IFormDestinyProps = IFormSectionProps & IProps;

const ScheduleDestinyFormView: React.FC<IFormDestinyProps> = ({
  errors,
  values,
  handleChange,
  touched,
  optionTypes,
  handleCompleteClientInformation,
  apiCall,
  setFieldValue,
  handleCompleteInputs,
}) => {
  const handleChangeDestinyClientType = (value?: number) => {
    if (values.destiny.clientType === 'plant') {
      setFieldValue('destiny.localityId', value);
      setFieldValue('destiny.clientId', null);
      handleCompleteInputs && handleCompleteInputs(0, value ?? 0, 'plant');
    } else if (values.destiny.clientType === 'client') {
      setFieldValue('destiny.clientId', value);
      setFieldValue('destiny.localityId', null);
      handleCompleteInputs && handleCompleteInputs(value ?? 0, 0, 'client');
    }
  };

  const location = useLocation();

  const destinyInfoCreated = LocationScheduleData(location);

  useEffect(() => {
    if (destinyInfoCreated.destinyType === 'plant') {
      setFieldValue('destiny.localityId', destinyInfoCreated.destinyId);
      setFieldValue('destiny.clientId', null);
      handleCompleteInputs && handleCompleteInputs(0, destinyInfoCreated.destinyId ?? 0, 'plant');
    } else if (destinyInfoCreated.destinyType === 'client') {
      setFieldValue('destiny.clientId', destinyInfoCreated.destinyId);
      setFieldValue('destiny.localityId', null);
      handleCompleteInputs && handleCompleteInputs(destinyInfoCreated.destinyId ?? 0, 0, 'client');
    }

    setFieldValue('destiny.clientType', destinyInfoCreated.destinyType);
    setFieldValue('destiny.tradingName', destinyInfoCreated.destinyName);
    setFieldValue('destiny.zipCode', destinyInfoCreated.destinyCep);
    setFieldValue('destiny.neighborhood', destinyInfoCreated.destinyNeighborhood);
    setFieldValue('destiny.city', destinyInfoCreated.destinyCity);
    setFieldValue('destiny.state', destinyInfoCreated.destinyUf);
    setFieldValue('destiny.street', destinyInfoCreated.destinyStreet);
    setFieldValue('destiny.number', destinyInfoCreated.destinyNumberHouse);
    setFieldValue('destiny.complement', destinyInfoCreated.destinyComplement);
  }, []);

  return (
    <SectionStyled name="Informações do Empresa">
      <LineSectionStyled columns="1.04fr 4fr">
        <Select
          label="Tipo *"
          id="destiny.clientType"
          name="destiny.clientType"
          value={values.destiny.clientType}
          setFieldValue={setFieldValue}
          hasError={!!errors.destiny?.clientType && !!touched.destiny?.clientType}
          errorMessage={String(errors.destiny?.clientType)}
          options={optionTypes}
        />

        {destinyInfoCreated.destinyName ? (
          <InputField
            label="Informe o Nome"
            id="destiny.tradingName"
            name="destiny.tradingName"
            type="text"
            onChange={handleChange}
            value={values.destiny.tradingName}
            disabled
          />
        ) : (
          <AsyncSelectField
            label="Informe o Nome *"
            placeholder=""
            isClearable={true}
            name="destiny.tradingName"
            id="destiny.tradingName"
            isDisabled={!values.destiny.clientType && true}
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleChangeDestinyClientType(e?.value);
              setFieldValue('destiny.tradingName', e && e.label);

              handleCompleteClientInformation && (await handleCompleteClientInformation(e?.value ?? 0));
            }}
            hasError={!!errors.destiny?.tradingName && !!touched.destiny?.tradingName}
            errorMessage={String(errors.destiny?.tradingName)}
            apiCallFunction={async (e: string) => apiCall && (await apiCall(e, values.destiny.clientType))}
            loadOptions={async (e: string) => apiCall && (await apiCall(e, values.destiny.clientType))}
          />
        )}
      </LineSectionStyled>

      <LineSectionStyled columns="0.8fr 1.3fr 1.3fr 0.4fr ">
        <InputField
          label="CEP"
          id="destiny.zipCode"
          name="destiny.zipCode"
          type="text"
          onChange={handleChange}
          value={values.destiny.zipCode}
          hasError={!!errors.destiny?.zipCode && !!touched.destiny?.zipCode}
          errorMessage={String(errors.destiny?.zipCode)}
          disabled
        />

        <InputField
          label="Bairro"
          id="destiny.neighborhood"
          name="destiny.neighborhood"
          type="text"
          onChange={handleChange}
          value={values.destiny.neighborhood}
          hasError={!!errors.destiny?.neighborhood && !!touched.destiny?.neighborhood}
          errorMessage={String(errors.destiny?.neighborhood)}
          disabled
        />

        <InputField
          label="Cidade"
          id="destiny.city"
          name="destiny.city"
          type="text"
          onChange={handleChange}
          value={values.destiny.city}
          hasError={!!errors.destiny?.city && !!touched.destiny?.city}
          errorMessage={String(errors.destiny?.city)}
          disabled
        />

        <InputField
          label="UF"
          id="destiny.state"
          name="destiny.state"
          type="text"
          onChange={handleChange}
          value={values.destiny.state}
          hasError={!!errors.destiny?.state && !!touched.destiny?.state}
          errorMessage={String(errors.destiny?.state)}
          disabled
        />
      </LineSectionStyled>

      <LineSectionStyled columns="3fr 0.5fr 1.5fr">
        <InputField
          label="Endereço"
          id="destiny.street"
          name="destiny.street"
          type="text"
          onChange={handleChange}
          value={values.destiny.street}
          hasError={!!errors.destiny?.street && !!touched.destiny?.street}
          errorMessage={String(errors.destiny?.street)}
          disabled
        />

        <InputField
          label="Nº"
          id="destiny.number"
          name="destiny.number"
          type="text"
          onChange={handleChange}
          value={values.destiny.number}
          hasError={!!errors.destiny?.number && !!touched.destiny?.number}
          errorMessage={String(errors.destiny?.number)}
          disabled
        />

        <InputField
          label="Complemento"
          id="destiny.complement"
          name="destiny.complement"
          type="text"
          onChange={handleChange}
          value={values.destiny.complement}
          hasError={!!errors.destiny?.complement && !!touched.destiny?.complement}
          errorMessage={String(errors.destiny?.complement)}
          disabled
        />
      </LineSectionStyled>
    </SectionStyled>
  );
};

export default ScheduleDestinyFormView;
