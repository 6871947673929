import React from 'react';

import { InputStyled, LabelStyled, WrapperStyled } from './checkbox.styled';

type CheckboxViewProps = {
  id: string;
  name: string;
  label?: string;
  labelColor?: string;
  backgroundColor?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  hasError?: boolean;
};

const CheckboxView: React.FC<CheckboxViewProps> = ({
  id,
  name,
  label,
  labelColor,
  backgroundColor,
  onChange,
  value,
  disabled,
  checked,
  hasError,
}) => {
  return (
    <WrapperStyled>
      <InputStyled
        id={id}
        type="checkbox"
        value={value}
        backgroundColor={backgroundColor}
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        hasError={hasError}
      />
      {!!label && (
        <LabelStyled htmlFor={id} labelColor={labelColor}>
          {label}
        </LabelStyled>
      )}
    </WrapperStyled>
  );
};

export default CheckboxView;
