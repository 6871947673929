import styled, { keyframes } from 'styled-components';
import theme from 'styles/theme';

type ColorKeys = keyof typeof theme.colorsV2;

interface IStyledProps {
  color: ColorKeys;
}

export const Container = styled.div`
  flex-grow: 1;
  height: 78.7vh;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 1rem;
`;

export const Text = styled.div`
  margin-left: 8px;
  margin-top: 4px;
  width: 100%;
  color: #000;
  font-family: Montserrat, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LineSubtitle = styled.div`
  display: flex;
  gap: 1px;
  padding-bottom: 10px;
  border-right-color: rgba(240, 240, 240, 0.66);
`;

export const LineSeparator = styled.div`
  width: 1px;
  height: 41px;
  margin-bottom: 5px;
  background-color: rgba(240, 240, 240, 0.66);
  backdrop-filter: blur(10px);
`;

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 22px;
  bottom: 20px;
  padding: 16px 24px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
`;

export const Tracing = styled.div`
  width: 40px;
  gap: 10px;
  height: 4px;
  margin-top: 7px;
  align-self: center;
  justify-self: flex-end;
  border-radius: 24px;
  background-color: rgb(18 38 78 / 76%);
  backdrop-filter: blur(10px);
`;

export const TracingContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const TracingRealTimeContainer = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 7px;
  align-self: center;
`;

export const TracingRealTime = styled.div<IStyledProps>`
  width: 15px;
  height: 4px;
  margin-top: 2px;
  border-radius: 24px;
  background-color: ${(props) => theme.colorsV2[props.color]};
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerModal = styled.div`
  position: absolute;
  left: 22px;
  bottom: 20px;
  background-color: transparent;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ececec;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 0.7s linear infinite;
`;
