import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 200px;
  width: 100%;
  display: inline-grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 15px;
`;

export const InputDropFile = styled.div`
  height: 220px;
  padding-top: 7px;
`;

export const SaveButtonContainerStyled = styled.div`
  display: flex;
  width: 100%;
  margin-top: -20px;
  justify-content: end;
`;

export const SaveButtonStyled = styled.button`
  display: flex;
  width: 115px;
  height: 40px;
  border-style: none;
  border-radius: 5px;
  border-width: 1px;
  backdrop-filter: blur(5px);
  background: rgb(224 43 43 / 1);
  cursor: pointer;

  font-family: Montserrat, serif;
  font-size: 12px;
  color: #fafafa;
  font-weight: 600;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(189, 2, 2);
  }
`;
