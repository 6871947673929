import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { ScheduleDetailsAllocationAccordionsView } from './schedule-details-allocation-accordions.component-view';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseGetScheduleHistoryDriver } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-schedule-history-driver-list/use-schedule-history-driver-list.hook';
import { UseGetScheduleHistoryVehicle } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-schedule-history-vehicle-list/use-schedule-history-vehicle-list.hook';
import { UserContext } from 'state/user-context';

export const ScheduleDetailsAllocationAccordions: FC = () => {
  const { isShippingCompany } = useContext(UserContext);
  const { scheduleDetails } = useContext(ScheduleContext);

  const getScheduleHistoryDriverList = UseGetScheduleHistoryDriver();
  const getScheduleHistoryVehicleList = UseGetScheduleHistoryVehicle();

  const checkAllocationAccordions = useMemo(() => {
    return {
      shippingId: !!scheduleDetails.scheduleDetails.shippingId,
      vehicleId: !!scheduleDetails.scheduleDetails.vehicleId,
      driverId: !!scheduleDetails.scheduleDetails.driverId,
    };
  }, [
    scheduleDetails.scheduleDetails.driverId,
    scheduleDetails.scheduleDetails.shippingId,
    scheduleDetails.scheduleDetails.vehicleId,
  ]);

  const isDenied = useMemo(
    (): boolean => scheduleDetails.scheduleDetails.statusCode === 'OT99' && !isShippingCompany,
    [isShippingCompany, scheduleDetails.scheduleDetails.statusCode],
  );
  const scheduleCanceled = useMemo(() => {
    const statusCode = ['OT99', 'AL90', 'AL99'];
    return statusCode.includes(scheduleDetails.scheduleDetails.statusCode);
  }, [scheduleDetails.scheduleDetails.statusCode]);

  const disableDriver = useMemo((): boolean => {
    const { company, origin } = scheduleDetails.scheduleDetails;

    const smpField = scheduleDetails.scheduleCustomFields.scheduleCustomFields.find(
      (customField) => customField.label === 'SMP',
    );

    return company.tradeName === 'MONDELEZ' && origin.name === 'CWB - FABRICA - TRANSFER' && !smpField?.value;
  }, [scheduleDetails.scheduleCustomFields.scheduleCustomFields, scheduleDetails.scheduleDetails]);

  const handleOpenModalAllocation = useCallback(
    (allocatedEntity: 'shipping' | 'vehicle' | 'driver') => {
      scheduleDetails.setScheduleDetailsAllocationOpen(allocatedEntity);
    },
    [scheduleDetails],
  );

  useEffect(() => {
    if (scheduleDetails.allocationDriver.accordionProps.statusRequest === 'INITIAL') {
      getScheduleHistoryDriverList();
    }
  }, [getScheduleHistoryDriverList, scheduleDetails.allocationDriver.accordionProps.statusRequest]);

  useEffect(() => {
    if (scheduleDetails.allocationVehicle.accordionProps.statusRequest === 'INITIAL') {
      getScheduleHistoryVehicleList();
    }
  }, [getScheduleHistoryVehicleList, scheduleDetails.allocationVehicle.accordionProps.statusRequest]);

  return (
    <ScheduleDetailsAllocationAccordionsView
      checkedAccordions={checkAllocationAccordions}
      handleOpenModalAllocation={handleOpenModalAllocation}
      modalState={{
        allocatedEntity: scheduleDetails.allocationActions.allocatedEntity,
        isOpen: scheduleDetails.allocationActions.isOpen,
      }}
      isDenied={isDenied}
      isShippingCompany={isShippingCompany}
      scheduleCanceled={scheduleCanceled}
      disableDriver={disableDriver}
    />
  );
};
