import { IDriverEntityStatus } from 'pages/driver/services/entities/driver.entity';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): IDriverEntityStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativos':
      return 'active';
    case 'Inativos':
      return 'inactive';
    case 'Excluídos':
      return 'deleted';
  }
};
