/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { FormikProps, withFormik } from 'formik';
import dayjs from 'dayjs';

import {
  ContainerDestinyStyled,
  ContainerInvoicingStyled,
  ContainerTariffAndInvoicingStyled,
  ContainerTariffStyled,
  FormStyled,
  LineSectionStyled,
  SectionStyled,
  WrapperDateTimePikers,
} from './hire-backhaul-update-form.styled';

import { calcPriceSuggestion } from 'utils/calcPriceSuggestion/calc-price-suggestion';
import { UserContext } from 'state/user-context';
import { localitiesFilterSearchService } from 'pages/schedule/services/locality-search/locality-filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useGetFreightCalculationHook } from 'pages/schedule/hooks/use-get-freight-calculation/use-get-freight-calculation.hook';
import { optionsTypeSchedule } from 'pages/offer-backhaul/backhaul-register/backhaul-register-form/form-values/form-values';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { ContainerOfferedCapacity } from 'pages/hire-backhaul/hire-backhaul-register/hire-backhaul-register-form/hire-backhaul-register-form.styled';
import { clientDetailsService } from 'pages/client/services/client.service';
import { clientsFilterSearchService } from 'pages/backhaul/services/clients-search/clients-filter-search.service';
import { IHireBackhaulCreated, ITransportedCargo, IYesNo } from 'domain/hire-backhaul';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import { VehicleCategoryWithEmpty } from 'domain/global';
import { FooterPage } from 'components-v2/common/footer-page';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import MultiSelect from 'components/multi-select/multi-select';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import Balloon from 'components/balloon/balloon';
import AsyncSelectField from 'components/async-select/async-select';

interface BackhaulRegisterFormViewProps {
  hireBackhaulRegisterValues: IHireBackhaulCreated;
  optionsTransportedCargo: Array<{ label: string; value: ITransportedCargo }>;
  optionsTime: Array<{ label: string; value: string }>;
  optionsYesOrNo: Array<{ label: string; value: IYesNo }>;
  optionClientType: Array<{ label: string; value: string }>;
  handleGoBack: () => void;
  handleRegister: (_args: IHireBackhaulCreated) => void;
  schemaValidation: object;
  isLoading: boolean;
}

const BackhaulRegisterFormView: React.FC<BackhaulRegisterFormViewProps> = ({
  hireBackhaulRegisterValues,
  optionsTransportedCargo,
  optionsTime,
  optionsYesOrNo,
  optionClientType,
  handleGoBack,
  handleRegister,
  schemaValidation,
  isLoading,
}) => {
  const clientApiCall = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await clientsFilterSearchService(searchValue);
    else return [];
  };
  const localityApiCall = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await localitiesFilterSearchService(searchValue);
    else return [];
  };

  const InnerForm = (props: FormikProps<IHireBackhaulCreated>) => {
    const { isAdmin } = useContext(UserContext);
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;
    const getFreightCalculationHook = useGetFreightCalculationHook();
    const [showPriceSuggestion, setShowPriceSuggestion] = useState(false);
    const [valueSuggestionPrice, setSuggestionValuePrice] = useState('');
    const [initialDate, setInitialDate] = useState<{
      focused: boolean;
      date: Moment | null;
      setted: boolean;
    }>({ focused: false, date: null, setted: false });
    const [finalDate, setFinalDate] = useState<{
      focused: boolean;
      date: Moment | null;
      setted: boolean;
    }>({ focused: false, date: null, setted: false });

    useEffect(() => {
      if (values.id !== '0') {
        if (values.initialDate && !initialDate.setted) {
          const testDate = moment(values.initialDate);
          const newDate = testDate.isValid() ? moment(values.initialDate) : moment(values.initialDate);

          setInitialDate({
            focused: false,
            date: moment(newDate, 'MM/DD/YYYY HH:mm'),
            setted: true,
          });
        }
        if (values.initialDate && !finalDate.setted) {
          const testDate = moment(values.endDate);
          const newDate = testDate.isValid() ? moment(values.endDate) : moment(values.endDate);

          setFinalDate({
            focused: false,
            date: newDate,
            setted: true,
          });
        }
      }
    }, [finalDate.setted, initialDate.setted, values]);

    useEffect(() => {
      setFieldValue('initialDate', initialDate.date ? initialDate.date.format('DD/MM/YY HH:mm') : undefined);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDate]);

    useEffect(() => {
      setFieldValue('endDate', finalDate.date ? finalDate.date.format('DD/MM/YY HH:mm') : undefined);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDate]);

    const [originData, setOriginData] = useState<any>([]);
    const [destinyData, setDestinyData] = useState<any>([]);
    const [editDestiny, setEditDestiny] = useState(true);
    const [editOrigin, setEditOrigin] = useState(true);

    useEffect(() => {
      if (values.originType === 'PARCEIRO' && values.idOrigin) {
        clientDetailsService(String(values.idOrigin)).then((client) => {
          setOriginData({ ...client, name: client.tradeName });
        });
      }
      if (values.originType === 'LOCALIDADE' && values.idOrigin) {
        locationDetailsService(String(values.idOrigin)).then((locality) => {
          setOriginData({ ...locality, name: locality.name });
        });
      }
      if (values.destinationType === 'PARCEIRO' && values.idDestination) {
        clientDetailsService(String(values.idDestination)).then((client) => {
          setDestinyData({ ...client, name: client.tradeName });
        });
      } else if (values.destinationType === 'LOCALIDADE' && values.idDestination) {
        locationDetailsService(String(values.idDestination)).then((locality) => {
          setDestinyData({ ...locality, name: locality.name });
        });
      }
    }, [values.idOrigin, values.idDestination, values.originType, values.destinationType, setFieldValue]);

    const handleCompleteInputsOrigin = (clientId: number, localityId: number, clientType: string) => {
      if (clientType === 'PARCEIRO' && clientId) {
        clientDetailsService(String(clientId)).then((client) => {
          setFieldValue('origin.cnpj', client.cnpj);
          setFieldValue('origin.zipCode', client.address.cep);
          setFieldValue('origin.city', client.address.city);
          setFieldValue('origin.state', client.address.uf);
          setFieldValue('origin.complement', client.address.complement);
          setFieldValue('origin.neighborhood', client.address.neighborhood);
          setFieldValue('origin.number', client.address.numberHouse);
          setFieldValue('origin.street', client.address.street);
          setFieldValue('origin.name', client.tradeName);
        });
      } else if (clientType === 'LOCALIDADE' && localityId) {
        locationDetailsService(String(localityId)).then((locality) => {
          setFieldValue('origin.cnpj', locality.cnpj);
          setFieldValue('origin.zipCode', locality.address.cep);
          setFieldValue('origin.city', locality.address.city);
          setFieldValue('origin.state', locality.address.uf);
          setFieldValue('origin.complement', locality.address.complement);
          setFieldValue('origin.neighborhood', locality.address.neighborhood);
          setFieldValue('origin.number', locality.address.numberHouse);
          setFieldValue('origin.street', locality.address.street);
          setFieldValue('origin.name', locality.name);
        });
      }
    };

    const handleCompleteInputsDestiny = (clientId: number, localityId: number, clientType: string) => {
      if (clientType === 'PARCEIRO' && clientId) {
        clientDetailsService(String(clientId)).then((client) => {
          setFieldValue('destiny.cnpj', client.cnpj);
          setFieldValue('destiny.zipCode', client.address.cep);
          setFieldValue('destiny.city', client.address.city);
          setFieldValue('destiny.state', client.address.uf);
          setFieldValue('destiny.complement', client.address.complement);
          setFieldValue('destiny.neighborhood', client.address.neighborhood);
          setFieldValue('destiny.number', client.address.numberHouse);
          setFieldValue('destiny.street', client.address.street);
          setFieldValue('destiny.name', client.tradeName);
        });
      } else if (clientType === 'LOCALIDADE' && localityId) {
        locationDetailsService(String(localityId)).then((locality) => {
          setFieldValue('destiny.cnpj', locality.cnpj);
          setFieldValue('destiny.zipCode', locality.address.cep);
          setFieldValue('destiny.city', locality.address.city);
          setFieldValue('destiny.state', locality.address.uf);
          setFieldValue('destiny.complement', locality.address.complement);
          setFieldValue('destiny.neighborhood', locality.address.neighborhood);
          setFieldValue('destiny.number', locality.address.numberHouse);
          setFieldValue('destiny.street', locality.address.street);
          setFieldValue('destiny.name', locality.name);
        });
      }
    };

    const handleValidation = useCallback(() => {
      handleSubmit();
    }, [initialDate.date, finalDate.date, handleSubmit]);

    const getPriceSuggestion = async (data: IHireBackhaulCreated) => {
      const response = await getFreightCalculationHook({
        originId: data.idOrigin || 0,
        originType: data.originType || '',
        destinyId: data.idDestination || 0,
        destinyType: data.destinationType || '',
        weight: '',
        capacity: '',
        merchValue: '',
        vehicleType: data.vehicleType,
        vehicleCategory: data.category,
        needHelper: undefined,
        pickUpDate: undefined,
      });
      if (response) {
        setShowPriceSuggestion(false);
        const resPrice = calcPriceSuggestion(
          response.totalDistance.length,
          data.vehicleType || '',
          response.internal.price.value,
        );
        setSuggestionValuePrice(resPrice.toFixed(2).toString().replace('.', ','));
      }
    };

    useEffect(() => {
      if (values.category && values.vehicleType && values.idOrigin && values.idDestination) {
        getPriceSuggestion(values);
      }
    }, [values.category, values.vehicleType, values.idOrigin, values.idDestination, values, getPriceSuggestion]);

    return (
      <FormStyled>
        <SectionStyled name="Veículos">
          <LineSectionStyled columns="0.6fr 1.2fr 0.6fr 1.2fr 1.2fr">
            <InputField
              label="ID Oferta *"
              id="id"
              name="id"
              placeholder="ID Oferta"
              value={values.id}
              type="text"
              disabled
            />
            <Select
              label="Carga Transportada *"
              id="transportedCargo"
              name="transportedCargo"
              placeholder="Carga Transportada"
              value={values.transportedCargo}
              setFieldValue={setFieldValue}
              hasError={!!errors.transportedCargo && !!touched.transportedCargo}
              errorMessage={String(errors.transportedCargo)}
              options={optionsTransportedCargo}
            />
            <Select
              label="Tipo de Carga *"
              id="scheduleType"
              name="scheduleType"
              value={values.scheduleType}
              setFieldValue={setFieldValue}
              hasError={!!errors.scheduleType && !!touched.scheduleType}
              errorMessage={String(errors.scheduleType)}
              options={optionsTypeSchedule}
            />
            <Select
              label="Tipo de Veículo *"
              id="vehicleType"
              name="vehicleType"
              placeholder="Tipo de Veículo"
              value={values.vehicleType}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleType && !!touched.vehicleType}
              errorMessage={String(errors.vehicleType)}
              options={vehicleTypeOptions}
              isClearable
              disabled={!isAdmin}
            />
            <Select
              label="Categoria do Veículo *"
              id="category"
              name="category"
              placeholder="Categoria do Veículo"
              value={values.category}
              setFieldValue={setFieldValue}
              hasError={!!errors.category && !!touched.category}
              errorMessage={String(errors.category)}
              options={vehicleCategoryOptions}
              disabled={!isAdmin}
            />
          </LineSectionStyled>

          <div style={{ marginTop: 15 }}>
            <LineSectionStyled columns="1fr">
              <MultiSelect
                label="Categoria do Veículo (Secundária)"
                options={vehicleCategoryOptions}
                menuPosition="fixed"
                values={vehicleCategoryOptions.filter(
                  (v) => values.secondVehicleCategory?.includes(v.value as VehicleCategoryWithEmpty),
                )}
                onChange={(e) => {
                  const vehicles = e.map((option) => option.value);
                  setFieldValue('secondVehicleCategory', vehicles);
                }}
              />
            </LineSectionStyled>
          </div>

          <ContainerDestinyStyled>
            <SectionDivider label="Origem" hidePin={false}/>
            <SectionStyled name="origem">
              <LineSectionStyled columns="1fr 4fr 1fr">
                <Select
                  label="Tipo *"
                  id="originType"
                  name="originType"
                  value={values.originType || ''}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.originType && !!touched.originType}
                  errorMessage={String(errors.originType)}
                  options={optionClientType}
                  disabled={!isAdmin}
                />
                {!editOrigin && values.originType === 'PARCEIRO' && (
                  <AsyncSelectField
                    label="Parceiro, Parceiro ou Fornecedor *"
                    isClearable={true}
                    name="idOrigin"
                    id="idOrigin"
                    cacheOptions={true}
                    defaultOptions={true}
                    onChange={async (e) => {
                      handleCompleteInputsOrigin &&
                        handleCompleteInputsOrigin((e && e.value) ?? 0, 0, String(values.originType)),
                        setFieldValue('idOrigin', e && e.value);
                    }}
                    hasError={!!errors.idOrigin && !!touched.idOrigin}
                    errorMessage={String(errors.idOrigin)}
                    apiCallFunction={clientApiCall}
                    loadOptions={clientApiCall}
                    defaultValue={{
                      label: String(originData.name === undefined ? '' : originData.name),
                      value: Number(values.idOrigin),
                    }}
                    disabled={!isAdmin}
                  />
                )}
                {!editOrigin && values.originType === 'LOCALIDADE' && (
                  <AsyncSelectField
                    label="Centro de Distribuição ou Loja ou Hub *"
                    isClearable={true}
                    name="idOrigin"
                    id="idOrigin"
                    cacheOptions={true}
                    defaultOptions={true}
                    onChange={async (e) => {
                      handleCompleteInputsOrigin &&
                        handleCompleteInputsOrigin(0, (e && e.value) ?? 0, String(values.originType)),
                        setFieldValue('idOrigin', e && e.value);
                    }}
                    hasError={!!errors.idOrigin && !!touched.idOrigin}
                    errorMessage={String(errors.idOrigin)}
                    apiCallFunction={localityApiCall}
                    loadOptions={localityApiCall}
                    defaultValue={{
                      label: String(originData.name === undefined ? '' : originData.name),
                      value: Number(values.idOrigin),
                    }}
                    disabled={!isAdmin}
                  />
                )}
                {editOrigin && (
                  <InputField
                    label={
                      values.originType === 'LOCALIDADE'
                        ? 'Centro de Distribuição ou Loja ou Hub *'
                        : 'Parceiro, Parceiro ou Fornecedor *'
                    }
                    onClick={() => setEditOrigin(false)}
                    name="origin.tradingName"
                    id="origin.tradingName"
                    type="text"
                    onChange={handleChange}
                    value={originData.name}
                    hasError={!!errors.idOrigin && !!touched.idOrigin}
                    errorMessage={String(errors.idOrigin)}
                    disabled={!isAdmin}
                  />
                )}
                <InputFieldCnpj
                  label="CNPJ"
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  onChange={handleChange}
                  value={originData?.cnpj}
                  disabled
                />
              </LineSectionStyled>

              <LineSectionStyled columns="0.9fr 2fr 2fr 0.4fr">
                <InputField
                  label="CEP"
                  id="cep"
                  name="cep"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.cep}
                  disabled
                />
                <InputField
                  label="Bairro"
                  id="bairro"
                  name="bairro"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.neighborhood}
                  disabled
                />
                <InputField
                  label="Cidade"
                  id="cidade"
                  name="cidade"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.city}
                  disabled
                />
                <InputField
                  label="UF"
                  id="uf"
                  name="uf"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.uf}
                  disabled
                />
              </LineSectionStyled>

              <LineSectionStyled columns="5fr 1fr 3fr">
                <InputField
                  label="Endereço"
                  id="address?.street"
                  name="address?.street"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.street}
                  disabled
                />
                <InputField
                  label="Nº"
                  id="address?.numberHouse"
                  name="address?.numberHouse"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.numberHouse}
                  disabled
                />
                <InputField
                  label="Complemento"
                  id="address?.complement"
                  name="address?.complement"
                  type="text"
                  onChange={handleChange}
                  value={originData?.address?.complement}
                  disabled
                />
              </LineSectionStyled>
            </SectionStyled>
          </ContainerDestinyStyled>

          <SectionDivider label="Destino" hidePin={false}/>
          <SectionStyled name="destino">
            <LineSectionStyled columns="1fr 4fr 1fr">
              <Select
                label="Tipo *"
                id="destinationType"
                name="destinationType"
                value={values.destinationType || ''}
                setFieldValue={setFieldValue}
                hasError={!!errors.destinationType && !!touched.destinationType}
                errorMessage={String(errors.destinationType)}
                options={optionClientType}
                disabled={!isAdmin}
              />
              {!editDestiny && values.destinationType === 'PARCEIRO' && (
                <AsyncSelectField
                  label="Parceiro, Parceiro ou Fornecedor *"
                  isClearable={true}
                  name="idDestination"
                  id="idDestination"
                  cacheOptions={true}
                  defaultOptions={true}
                  onChange={async (e) => {
                    handleCompleteInputsDestiny &&
                      handleCompleteInputsDestiny((e && e.value) ?? 0, 0, String(values.destinationType)),
                      setFieldValue('idDestination', e && e.value);
                  }}
                  hasError={!!errors.idDestination && !!touched.idDestination}
                  errorMessage={String(errors.idDestination)}
                  apiCallFunction={clientApiCall}
                  loadOptions={clientApiCall}
                  defaultValue={{
                    label: String(destinyData.name === undefined ? '' : originData.name),
                    value: Number(values.idDestination),
                  }}
                  disabled={!isAdmin}
                />
              )}
              {!editDestiny && values.destinationType === 'LOCALIDADE' && (
                <AsyncSelectField
                  label="Centro de Distribuição ou Loja ou Hub *"
                  isClearable={true}
                  name="idDestination"
                  id="idDestination"
                  cacheOptions={true}
                  defaultOptions={true}
                  onChange={async (e) => {
                    handleCompleteInputsDestiny &&
                      handleCompleteInputsDestiny(0, (e && e.value) ?? 0, String(values.destinationType)),
                      setFieldValue('idDestination', e && e.value);
                  }}
                  hasError={!!errors.idDestination && !!touched.idDestination}
                  errorMessage={String(errors.idDestination)}
                  apiCallFunction={localityApiCall}
                  loadOptions={localityApiCall}
                  defaultValue={{
                    label: String(originData.name === undefined ? '' : originData.name),
                    value: Number(values.idDestination),
                  }}
                  disabled={!isAdmin}
                />
              )}
              {editDestiny && (
                <InputField
                  label={
                    values.destinationType === 'LOCALIDADE'
                      ? 'Centro de Distribuição ou Loja ou Hub *'
                      : 'Parceiro, Parceiro ou Fornecedor *'
                  }
                  onClick={() => setEditDestiny(false)}
                  name="destiny.tradingName"
                  id="destiny.tradingName"
                  type="text"
                  onChange={handleChange}
                  value={destinyData.name}
                  hasError={!!errors.idDestination && !!touched.idDestination}
                  errorMessage={String(errors.idDestination)}
                  disabled={!isAdmin}
                />
              )}

              <InputFieldCnpj
                label="CNPJ"
                id="cnpj"
                name="cnpj"
                type="text"
                onChange={handleChange}
                value={destinyData?.cnpj}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="0.9fr 2fr 2fr 0.4fr">
              <InputField
                label="CEP"
                id="cep"
                name="cep"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.cep}
                disabled
              />
              <InputField
                label="Bairro"
                id="bairro"
                name="bairro"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.neighborhood}
                disabled
              />
              <InputField
                label="Cidade"
                id="cidade"
                name="cidade"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.city}
                disabled
              />
              <InputField
                label="UF"
                id="uf"
                name="uf"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.uf}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="5fr 1fr 3fr">
              <InputField
                label="Endereço"
                id="endereco"
                name="endereco"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.street}
                disabled
              />
              <InputField
                label="Nº"
                id="numero"
                name="numero"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.numberHouse}
                disabled
              />
              <InputField
                label="Complemento"
                id="complemento"
                name="complemento"
                type="text"
                onChange={handleChange}
                value={destinyData?.address?.complement}
                disabled
              />
            </LineSectionStyled>
          </SectionStyled>

          <ContainerTariffAndInvoicingStyled>
            <ContainerInvoicingStyled>
              <SectionDivider label="Vigência da Operação" />
              <LineSectionStyled columns="1fr 1fr 1fr">
                <WrapperDateTimePikers>
                  <DateTimePikers
                    views={['year', 'month', 'day']}
                    label="Data Inicial *"
                    format="DD/MM/YYYY"
                    value={dayjs(initialDate.date?.toString())}
                    minDate={dayjs(initialDate.date?.toString()).startOf('day')}
                    onChange={(date) => {
                      setInitialDate((prvState) => {
                        return { ...prvState, date: moment(date?.toString()) };
                      });
                    }}
                  />
                </WrapperDateTimePikers>

                <WrapperDateTimePikers>
                  <DateTimePikers
                    views={['year', 'month', 'day']}
                    label="Data Final *"
                    format="DD/MM/YYYY"
                    value={dayjs(finalDate.date?.toString())}
                    minDate={dayjs(initialDate.date?.toString()).startOf('day')}
                    onChange={(date) => {
                      setFinalDate((prvState) => {
                        return { ...prvState, date: moment(date?.toString()) };
                      });
                    }}
                  />
                </WrapperDateTimePikers>

                <InputField
                  label="Frequência Mensal"
                  id="frequency"
                  name="frequency"
                  type="number"
                  value={values.frequency}
                  onChange={handleChange}
                  hasError={!!errors.frequency && !!touched.frequency}
                  errorMessage={String(errors.frequency)}
                />
              </LineSectionStyled>
            </ContainerInvoicingStyled>

            <ContainerTariffStyled>
              <SectionDivider label="Tarifa Teto (R$)" />
              {showPriceSuggestion && (
                <LineSectionStyled columns="1fr">
                  <Balloon firstText="Preço Sugerido" secondText={`R$ ${valueSuggestionPrice}`} open />
                  <div></div>
                </LineSectionStyled>
              )}
              <LineSectionStyled columns="1fr">
                <InputCurrency
                  label="Valor Máximo (sem impostos)"
                  id="freightValue"
                  name="freightValue"
                  prefix={'R$ '}
                  defaultValue={0}
                  decimalsLimit={2}
                  decimalSeparator=","
                  step={1}
                  groupSeparator="."
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={values?.freightValue}
                />
              </LineSectionStyled>
            </ContainerTariffStyled>

            <ContainerOfferedCapacity>
              <SectionDivider label="Capacidade Necessária" />
              <LineSectionStyled columns="1fr 1fr 1fr">
                <InputField
                  label="Paletes"
                  id="offeredPallet"
                  name="offeredPallet"
                  type="number"
                  value={values.offeredPallet}
                  onChange={handleChange}
                  hasError={!!errors.offeredPallet && !!touched.offeredPallet}
                  errorMessage={String(errors.offeredPallet)}
                />

                <InputCurrency
                  label="Cubagem (M³)"
                  id="offeredCubage"
                  name="offeredCubage"
                  type="number"
                  suffix=" m³"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.offeredCubage)}
                />

                <InputCurrency
                  label="Peso (kg)"
                  id="offeredWeight"
                  name="offeredWeight"
                  type="number"
                  suffix=" Kg"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.offeredWeight)}
                />
              </LineSectionStyled>
            </ContainerOfferedCapacity>
          </ContainerTariffAndInvoicingStyled>
        </SectionStyled>

        <SectionDivider label="Especificações da Operação" />
        <SectionStyled name="origem">
          <LineSectionStyled columns="1fr 1fr 1fr 1fr">
            <Select
              label="Tempo de Carregamento *"
              id="loadingTime"
              name="loadingTime"
              value={values.loadingTime}
              setFieldValue={setFieldValue}
              hasError={!!errors.loadingTime && !!touched.loadingTime}
              errorMessage={String(errors.loadingTime)}
              options={optionsTime}
            />
            <Select
              label="Tempo de Descarga *"
              id="timeDownload"
              name="timeDownload"
              value={values.timeDownload}
              setFieldValue={setFieldValue}
              hasError={!!errors.timeDownload && !!touched.timeDownload}
              errorMessage={String(errors.timeDownload)}
              options={optionsTime}
            />
            <Select
              label="Requer Ajudante? *"
              id="helper"
              name="helper"
              placeholder="Requer Ajudante?"
              value={values.helper}
              setFieldValue={setFieldValue}
              hasError={!!errors.helper && !!touched.helper}
              errorMessage={String(errors.helper)}
              options={optionsYesOrNo}
            />
            <Select
              label="Carga Paletizada? *"
              id="paletizado"
              name="paletizado"
              placeholder="Paletizado?"
              value={values.paletizado}
              setFieldValue={setFieldValue}
              hasError={!!errors.paletizado && !!touched.paletizado}
              errorMessage={String(errors.paletizado)}
              options={optionsYesOrNo}
            />
          </LineSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleValidation} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'active'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'active' : 'inactive')}
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const BackhaulInformationForm = withFormik<IHireBackhaulCreated, IHireBackhaulCreated>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: schemaValidation,
  })(InnerForm);

  return <BackhaulInformationForm {...hireBackhaulRegisterValues} />;
};

export default BackhaulRegisterFormView;
