import React, { useMemo } from 'react';

import { ContainerPageStyled, PageStyled } from './pagination.styled';

interface IPaginationViewProps {
  activePage: number;
  totalPage: number;
  changePage: (page: number) => void;
}

const PaginationView: React.FC<IPaginationViewProps> = ({
  activePage,
  totalPage,
  changePage,
}: IPaginationViewProps) => {
  const renderPages = useMemo(() => {
    const firstPage = 1;
    const intervalPage = 2;

    const startPage = activePage <= 3 ? firstPage : activePage - intervalPage;
    const finalPage = activePage + 2 > totalPage ? totalPage : activePage + intervalPage;

    const pages = [];
    if (startPage > 1) {
      pages.push(
        <PageStyled key={'01'} activePage={false} onClick={() => changePage(1)}>
          {('0' + 1).slice(-2)}
        </PageStyled>,
      );
      pages.push(
        <PageStyled activePage={false} key="start">
          ...
        </PageStyled>,
      );
    }

    for (let i = startPage; i <= finalPage; i++) {
      pages.push(
        <PageStyled activePage={activePage === i} key={i} onClick={() => changePage(i)}>
          {i < 10 ? ('0' + i).slice(-2) : i}
        </PageStyled>,
      );
    }

    if (finalPage < totalPage) {
      pages.push(
        <PageStyled activePage={false} key="final">
          ...
        </PageStyled>,
      );
      pages.push(
        <PageStyled activePage={false} key={totalPage} onClick={() => changePage(totalPage)}>
          {totalPage < 10 ? ('0' + totalPage).slice(-2) : totalPage}
        </PageStyled>,
      );
    }

    return pages;
  }, [activePage, changePage, totalPage]);

  return <ContainerPageStyled>{renderPages}</ContainerPageStyled>;
};

export default PaginationView;
