import styled, { css } from 'styled-components';

type LineSectionStyledProps = { columns: string; alignItems?: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns, alignItems }) => css`
    display: grid;
    ${alignItems && `align-items: ${alignItems};`};
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;
