import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { ChatUserEmailsActionType } from '../../chat.action';

export const chatUserEmailsReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  switch (action.type) {
    case ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_REQUEST:
      return {
        ...state,
        chatUserEmails: {
          ...state.chatUserEmails,
          statusRequest: 'PROCESSING',
          userEmails: undefined,
        },
      };
    case ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_SUCCESS:
      return {
        ...state,
        chatUserEmails: {
          ...state.chatUserEmails,
          statusRequest: 'SUCCESS',
          userEmails: action.payload,
        },
      };
    case ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_ERROR:
      return {
        ...state,
        chatUserEmails: {
          ...state.chatUserEmails,
          statusRequest: 'ERROR',
          userEmails: undefined,
        },
      };
    case ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_RESET:
      return {
        ...state,
        chatUserEmails: {
          ...state.chatUserEmails,
          statusRequest: 'INITIAL',
          userEmails: undefined,
        },
      };
    default:
      return state;
  }
};
