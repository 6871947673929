type StatusEntity = 'active' | 'inactive' | 'deleted';
type StatusDomain = 'ativo' | 'inativo' | 'deletado';

export const convertStatusDomainToEntityMapper = (statusDomain: StatusDomain): StatusEntity => {
  switch (statusDomain) {
    case 'ativo':
      return 'active';
    case 'inativo':
      return 'inactive';
    case 'deletado':
      return 'deleted';
  }
};
