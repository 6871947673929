import React from 'react';

import { ButtonStyled } from './styled';

export type ButtonProps = {
  label?: string;
  color: 'blue' | 'white' | 'pink' | 'green' | 'orange' | 'darkGray' | 'middleBlue' | 'newRed' | 'aqua' | 'lightGray';
  icon?: React.ReactNode;
  onPress?: () => void;
};

const BiddingsButton: React.FC<ButtonProps> = ({ label, color, icon, onPress }) => {
  return (
    <ButtonStyled onClick={onPress} color={color}>
      {icon}
      {label}
    </ButtonStyled>
  );
};

export default BiddingsButton;
