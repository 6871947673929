import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { ScheduleDetailsOriginAndDestinationModalFormView } from './schedule-details-origin-and-destination-modal-form-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useFormikContext } from 'formik';
import { clientDetailsService } from 'pages/client/services/client.service';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { showToast } from 'components/toast/toast';
import { IScheduleOriginAndDestinationPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.types';

export interface IScheduleDetailsOriginAndDestinationModalFormProps {
  handleCloseModalOriginAndDestination: () => void;
}

export const ScheduleDetailsOriginAndDestinationModalForm: FC<IScheduleDetailsOriginAndDestinationModalFormProps> = ({
  handleCloseModalOriginAndDestination,
}) => {
  const { setFieldValue, values, handleSubmit } = useFormikContext<IScheduleOriginAndDestinationPayload>();
  const { scheduleRequestStatus } = useContext(ScheduleContext);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  }, []);

  const handleOnSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleCompleteInputs = useCallback(
    async (id: number, type: '' | 'CLIENT' | 'LOCALITY') => {
      if ((type === 'CLIENT' || type === 'LOCALITY') && id) {
        try {
          const details = await (type === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          const fieldValuesToUpdate: {
            [x: string]: string | number | undefined;
          } = {
            ['id']: details.id,
            ['address.city']: details.address.city,
            ['address.uf']: details.address.uf,
            ['address.complement']: details.address.complement,
            ['address.neighborhood']: details.address.neighborhood,
            ['address.numberHouse']: details.address.numberHouse,
            ['address.street']: details.address.street,
            ['address.cep']: details.address.cep,
            ['cnpj']: details.cnpj,
            ['lat']: details.lat,
            ['lng']: details.lng,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue],
  );

  const updateIsLoading = useMemo(
    (): boolean => scheduleRequestStatus.statusRequest === 'PROCESSING',
    [scheduleRequestStatus.statusRequest],
  );

  return (
    <ScheduleDetailsOriginAndDestinationModalFormView
      values={values}
      updateIsLoading={updateIsLoading}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      handleOnSubmit={handleOnSubmit}
      handleCompleteInputs={handleCompleteInputs}
      handleCloseModalOriginAndDestination={handleCloseModalOriginAndDestination}
    />
  );
};
