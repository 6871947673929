import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'logshare-ui-kit';
import { FormikProps, withFormik } from 'formik';

import * as S from './styled';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { VehicleRegisterSchema } from 'pages/vehicle/vehicle-register/components/vehicle-register-form/schemas-validation/schemas-validation';
import {
  initialValues,
  optionsFuel,
  optionsVehicleActuators,
  optionsVehicleCategory,
  optionsVehicleTracker,
  optionsVehicleType,
  optionsVehicleYear,
} from 'pages/vehicle/vehicle-register/components/vehicle-register-form/form-values/form-values';
import { vehicleRegisterService } from 'pages/vehicle/services/vehicle-register/vehicle-register.service';
import { IVehicleNew } from 'domain/vehicle';
import Select from 'components/select/select';
import MultiSelect from 'components/multi-select/multi-select';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface VehicleRegisterFormViewProps {
  handleCloseRegister: () => void;
  handleSelectCurrentLine: (id: string) => void;
}

const VehicleRegisterFormView: React.FC<VehicleRegisterFormViewProps> = ({
  handleCloseRegister,
  handleSelectCurrentLine,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRegister = useCallback(
    async (vehicle: IVehicleNew) => {
      setIsLoading(true);
      try {
        const data = await vehicleRegisterService(vehicle);

        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });

        handleSelectCurrentLine(data.id);

        handleCloseRegister();
      } catch (error: any) {
        showMessageFormatted({
          error: error,
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [handleSelectCurrentLine, handleCloseRegister, vehicleRegisterService],
  );

  const InnerForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  }: FormikProps<IVehicleNew>) => {
    const { user } = useContext(UserContext);

    useEffect(() => {
      if (user?.shippingCompanyId) {
        setFieldValue('shippingCompanyId', user?.shippingCompanyId);
        setFieldValue('fleetType', 'Frota Terceirizada');
      } else {
        setFieldValue('fleetType', 'Frota Própria');
      }
    }, [setFieldValue, user?.shippingCompanyId]);

    return (
      <S.FormStyled>
        <S.Section name="Veículos">
          <S.LineSection columns="1fr 1fr 1fr 1fr 1fr 1fr">
            <InputField label="ID *" id="id" name="id" type="text" disabled />
            <InputField
              label="Placa Principal *"
              id="mainBoard"
              name="mainBoard"
              type="text"
              onChange={handleChange}
              value={values.mainBoard}
              hasError={!!errors.mainBoard && !!touched.mainBoard}
              errorMessage={String(errors.mainBoard)}
            />
            <InputField
              label="Placa 2"
              id="secondBoard"
              name="secondBoard"
              type="text"
              onChange={handleChange}
              value={values.secondBoard}
            />
            <InputField
              label="Placa 3"
              id="thirdBoard"
              name="thirdBoard"
              type="text"
              value={values.thirdBoard}
              onChange={handleChange}
            />
            <InputCurrency
              label="Capacidade (Kg)"
              upperCase={false}
              id="capacity"
              name="capacity"
              type="text"
              allowDecimals={false}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.capacity}
              suffix=" Kg"
              hasError={!!errors.capacity && !!touched.capacity}
              errorMessage={String(errors.capacity)}
            />
            <Select
              label="Tipo de Combustível *"
              id="fuel"
              name="fuel"
              value={values.fuel}
              setFieldValue={setFieldValue}
              hasError={!!errors.fuel && !!touched.fuel}
              errorMessage={String(errors.fuel)}
              options={optionsFuel}
            />
          </S.LineSection>

          <S.LineSection columns="1fr 1fr 1fr 1fr 1fr 1fr">
            <Select
              label="Tipo de Veículo *"
              id="vehicleType"
              name="vehicleType"
              value={values.vehicleType}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleType && !!touched.vehicleType}
              errorMessage={String(errors.vehicleType)}
              options={optionsVehicleType}
            />
            <Select
              label="Categoria *"
              id="vehicleCategory"
              name="vehicleCategory"
              value={values.vehicleCategory}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleCategory && !!touched.vehicleCategory}
              errorMessage={String(errors.vehicleCategory)}
              options={optionsVehicleCategory}
            />
            <InputField
              label="Marca"
              id="vehicleBrand"
              name="vehicleBrand"
              type="text"
              onChange={handleChange}
              value={values.vehicleBrand}
              hasError={!!errors.vehicleBrand && !!touched.vehicleBrand}
              errorMessage={String(errors.vehicleBrand)}
            />
            <InputField
              label="Modelo"
              id="vehicleModel"
              name="vehicleModel"
              type="text"
              onChange={handleChange}
              value={values.vehicleModel}
              hasError={!!errors.vehicleModel && !!touched.vehicleModel}
              errorMessage={String(errors.vehicleModel)}
            />
            <Select
              label="Ano"
              id="year"
              name="year"
              value={String(values.year)}
              setFieldValue={setFieldValue}
              hasError={!!errors.year && !!touched.year}
              errorMessage={String(errors.year)}
              options={optionsVehicleYear}
            />
            <Select
              label="Rastreador"
              id="vehicleTracker"
              name="vehicleTracker"
              value={values.vehicleTracker}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleTracker && !!touched.vehicleTracker}
              errorMessage={String(errors.vehicleTracker)}
              options={optionsVehicleTracker}
            />
          </S.LineSection>

          <S.LineSection columns="2fr 4.1fr">
            <S.ConatinerMultiSelect>
              <S.LineSection columns="1fr 1fr">
                <InputCurrency
                  label="Capacidade (Paletes)"
                  upperCase={false}
                  id="palletCapacity"
                  name="palletCapacity"
                  type="text"
                  allowDecimals={false}
                  decimalScale={0}
                  decimalsLimit={0}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={values.palletCapacity}
                  suffix=" Pallets"
                  hasError={!!errors.palletCapacity && !!touched.palletCapacity}
                  errorMessage={String(errors.palletCapacity)}
                />
                <InputCurrency
                  label="Cubagem (M³)"
                  upperCase={false}
                  id="cubageCapacity"
                  name="cubageCapacity"
                  type="text"
                  allowDecimals={false}
                  decimalScale={0}
                  decimalsLimit={0}
                  decimalSeparator=","
                  groupSeparator="."
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={values.cubageCapacity}
                  suffix=" M³"
                  hasError={!!errors.cubageCapacity && !!touched.cubageCapacity}
                  errorMessage={String(errors.cubageCapacity)}
                />
              </S.LineSection>
            </S.ConatinerMultiSelect>

            <MultiSelect
              label="Sensores e Atuadores"
              options={optionsVehicleActuators}
              menuPosition="fixed"
              onChange={(e) => {
                const vehicleActuators = e.map((option) => option.value);
                setFieldValue('sensorsActuators', vehicleActuators);
              }}
            />
          </S.LineSection>
        </S.Section>

        <S.WrapperFooterButton>
          <Button
            label="Salvar e Alocar"
            color="aqua"
            size="md"
            onPress={handleSubmit}
            isLoading={isLoading}
            variant="solid"
          />
        </S.WrapperFooterButton>
      </S.FormStyled>
    );
  };

  const VehicleInformationForm = withFormik<IVehicleNew, IVehicleNew>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: VehicleRegisterSchema,
  })(InnerForm);

  return <VehicleInformationForm {...initialValues} />;
};

export default VehicleRegisterFormView;
