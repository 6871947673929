import API from 'utils/API/API';

export const getInvoicingService = async (search?: string): Promise<Array<{ id: number; name: string }>> => {
  const { data } = await API.get('v2/companies/filter', {
    params: {
      search,
      type: 'list-select',
    },
  });

  return data;
};
