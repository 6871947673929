import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IModalContextProps {
  children: ReactNode;
}

interface AllocationProps {
  shippingId: number;
  selectShipping: (shippingId: number) => void;
}

const initialState = {
  shippingId: 0,
} as AllocationProps;

const AllocationContext = createContext<AllocationProps>({
  ...initialState,
  selectShipping: () => {},
});

export const AllocationProvider = ({ children }: IModalContextProps) => {
  const [allocation, setAllocation] = useState<AllocationProps>(initialState);

  const updateAllocation = useCallback((newAllocation: Partial<AllocationProps>) => {
    setAllocation((prevAllocation) => ({
      ...prevAllocation,
      ...newAllocation,
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...allocation,
      selectShipping: (shippingId: number) => updateAllocation({ shippingId }),
    }),
    [allocation, updateAllocation],
  );

  return <AllocationContext.Provider value={contextValue}>{children}</AllocationContext.Provider>;
};

export const useAllocation = () => {
  const context = useContext(AllocationContext);

  if (!context) {
    throw new Error('useAllocation deve ser usado dentro de um AllocationProvider');
  }

  return context;
};
