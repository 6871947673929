import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { ILeadTimeParams, ILeadTimeResponse } from './get-leadtime.types';
import { ILeadTimeDomain } from 'src-new/domains/leadtime.domain';
import { convertLeadTimeResponseToDomain } from './mappers/convert-leadtime-response-to-domain/convert-leadtime-response-to-domain.mapper';

export const getLeadTimeService = async (params: ILeadTimeParams): Promise<ILeadTimeDomain> => {
  const { data } = await API_TRAVELS_SVC.get<ILeadTimeResponse>('v1/freight/leadtime', { params: params });

  return convertLeadTimeResponseToDomain(data);
};
