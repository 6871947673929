import { useCallback } from 'react';

import { vehicleDetailsService } from 'pages/vehicle/services/vehicle-detail/vehicle-detail.service';
import { IVehicleCreated } from 'domain/vehicle';

const useVehicleDetailHook = () => {
  return useCallback(async (vehicleId: string): Promise<IVehicleCreated> => {
    return await vehicleDetailsService(vehicleId);
  }, []);
};

export { useVehicleDetailHook };
