import React, { ReactNode } from 'react';

import * as S from './styled';

type SectionProps = {
  children: ReactNode;
  name: string;
};

export const SectionLayout: React.FC<SectionProps> = ({ children, name }) => {
  return <S.SectionStyled name={name}>{children}</S.SectionStyled>;
};
