import React from 'react';

import CompanyInformationForm from '../company-information-form/company-information-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const CompanyRegisterView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage breadcrumbList={['Configurações', 'Dados da Empresa']} />
      <Title title="Consulte ou altere os dados cadastrais." hasTrace />
      <CompanyInformationForm />
    </React.Fragment>
  );
};
export default CompanyRegisterView;
