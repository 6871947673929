import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { sendSelectedFileService } from '../../services/send-selected-file/send-selected-file.service';

export const useSendSelectedFile = () => {
  const { scheduleImportModal } = useContext(ScheduleContext);
  const { setScheduleImportModalError, setScheduleImportModalRequest, setScheduleImportModalSuccess } =
    scheduleImportModal;

  return useCallback(
    async (files: FileList) => {
      try {
        setScheduleImportModalRequest();

        showMessageFormatted({
          message: 'Iniciando análise de dados.',
          type: 'info',
        });

        const data = await sendSelectedFileService(files);

        if (data.some((item) => item.status === 'refused')) {
          showMessageFormatted({
            message: 'Erro na análise de dados.',
            type: 'error',
          });
        }

        setScheduleImportModalSuccess(data);
      } catch (error) {
        setScheduleImportModalError();

        showMessageFormatted({
          message: 'Erro ao analisar dados.',
          type: 'error',
        });
      }
    },
    [setScheduleImportModalError, setScheduleImportModalRequest, setScheduleImportModalSuccess],
  );
};
