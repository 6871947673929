import { IOption } from 'domain/global-inputs';

export const programmingStatus: Array<string> = ['SH01', 'OT10', 'AG10', 'AL10', 'AL20', 'OT01'];

export const startingStatus: Array<string> = ['IN10', 'IN11'];

export const onGoingStatus: Array<string> = ['IN20', 'IN30', 'IN40', 'IN50', 'IN60', 'IN61', 'IN62', 'IN70'];

export const finishedStatus: Array<string> = ['IN90'];

export const prospectedStatus: Array<string> = ['SH02'];

export const canceledStatus: Array<string> = ['AL99', 'OT99', 'AL90', 'OT80'];

export const status: Array<string> = [
  ...programmingStatus,
  ...startingStatus,
  ...onGoingStatus,
  ...finishedStatus,
  ...prospectedStatus,
  ...canceledStatus,
];

export const programmingStatusFilter: Array<IOption> = [
  { value: 'SH01', label: 'EM BIDDING' },
  { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
  { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
  { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
  { value: 'AL10', label: 'AGUARDANDO MOTORISTA' },
  { value: 'AL20', label: 'AGUARDANDO VEÍCULO' },
];

export const startingStatusFilter: Array<IOption> = [
  { value: 'IN10', label: 'AGUARDANDO ACEITE' },
  { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
];

export const onGoingStatusFilter: Array<IOption> = [
  { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
  { value: 'IN30', label: 'AGUARDANDO CARREGAMENTO' },
  { value: 'IN40', label: 'EM CARREGAMENTO' },
  { value: 'IN50', label: 'COLETA REALIZADA' },
  { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
  { value: 'IN61', label: 'INÍCIO DA VIAGEM PARA DESCARGA' },
  { value: 'IN62', label: 'CHEGADA PARA DESCARGA' },
  { value: 'IN70', label: 'EM DESCARGA' },
];

export const finishedStatusFilter: Array<IOption> = [{ value: 'IN90', label: 'DESCARGA FINALIZADA' }];

export const prospectedStatusFilter: Array<IOption> = [{ value: 'SH02', label: 'OFERTAS' }];

export const canceledStatusFilter: Array<IOption> = [
  { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
  { value: 'OT99', label: 'OFERTA NEGADA' },
  { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
  { value: 'OT80', label: 'EXPIRADO' },
];

export const statusFilter: Array<IOption> = [
  ...programmingStatusFilter,
  ...startingStatusFilter,
  ...onGoingStatusFilter,
  ...finishedStatusFilter,
  ...prospectedStatusFilter,
  ...canceledStatusFilter,
];
