export const converterNumberPtBrToUs = (value: string): number => {
  return Number(value.replace('.', '').replace(',', '.'));
};

export const formatCurrencyNoDecimals = (current: number): string => {
  return current.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};
