import { AxiosError } from 'axios';

import { showToast } from 'components/toast/toast';
import { removeEndingPunctuation } from 'utils-v2/message/remove-ending-punctuation/remove-ending-punctuation';

interface ApiError {
  statusCode: number;
  message: Array<string>;
  error: string;
}

interface IMessage {
  type: 'error' | 'info' | 'success';
  message?: string;
  error?: AxiosError<ApiError>;
}
export const showMessageFormatted = ({ message, type, error }: IMessage) => {
  let newMessage = message;
  if (message) newMessage = removeEndingPunctuation(message);

  try {
    if (error) {
      const apiError: ApiError | undefined = error?.response?.data;
      let messages: Array<string> = ['Error ao realizar a operação'];
      if (apiError && apiError?.message) {
        messages = apiError.message;
      }
      messages.forEach((messageError) => {
        showToast({
          message: messageError,
          type,
        });
      });
    } else {
      showToast({
        message: newMessage ?? 'Operação realizada com sucesso',
        type,
      });
    }
  } catch (error) {
    showToast({
      message: 'Erro na aplicação. Contate o administrador',
      type: 'error',
    });
  }
};
