/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import CompanyInformationFileSection from './components/company-information-file-upload/company-information-file-upload';
import {
  ContainerTelephoneStyled,
  DiviserStyled,
  FormStyled,
  LineSectionStyled,
  NameWrapperStyled,
  SectionStyled,
  TradeNameWrapperStyled,
  UploadImageStyled,
  WrapperSectionCompanyDataStyled,
} from './company-information-form.styled';

import { UserContext } from 'state/user-context';
import { listCompanyService } from 'pages/company/services/company-list/company-list.service';
import {
  optionsClosingCycle,
  optionsDeadlines,
  optionsPaymentMethod,
  optionsPlan,
  optionsReceiptMethod,
  optionsRiskManager,
  optionsStatus,
  optionsType,
} from 'pages/company/company-update/components/company-update-form/form-values/form-values';
import { EmailWrapperStyled } from 'pages/company/company-update/components/company-update-form/company-update-form.styled';
import { uf } from 'domain/states';
import { ICompany, ICompanyContact } from 'domain/company';
import { IAddress } from 'domain/address';
import { FooterPage } from 'components-v2/common/footer-page';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import MultiSelect from 'components/multi-select/multi-select';
import InputUploadImage from 'components/input-upload-image/input-upload-image';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import Button from 'components/button/button';
import { CompanyInformationFreetime } from './components/company-information-freetime/company-information-freetime.component';

interface CompanyInformationFormView {
  companyValues: ICompany;
  validationSchema: object;
  isLoading: boolean;
  handleRegister: (values: ICompany) => void;
  handleGoBack: () => void;
  onBlurCep: (cep: string) => Promise<IAddress>;
  setArrayToDeleteBranches: (index: number) => void;
}

const CompanyInformationFormView: React.FC<CompanyInformationFormView> = ({
  companyValues,
  validationSchema,
  isLoading,
  handleRegister,
  setArrayToDeleteBranches,
  handleGoBack,
  onBlurCep,
}) => {
  const InnerForm = (props: FormikProps<ICompany>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;
    const { user, isAdmin } = useContext(UserContext);

    const [branches, setBranches] = useState<any>([]);

    const onBlurCEPField = async (cep: string) => {
      const address = await onBlurCep(cep);
      setFieldValue('cep', address.cep || values.cep);
      setFieldValue('city', address.city);
      setFieldValue('complement', address.complement);
      setFieldValue('uf', address.uf);
      setFieldValue('neighborhood', address.neighborhood);
      setFieldValue('numberHouse', address.numberHouse);
      setFieldValue('street', address.street);
    };

    const onBlurCEPFieldBranch = async (cep: string, index: number) => {
      const address = await onBlurCep(cep);
      setFieldValue(`branches[${index}].cep`, address.cep || values.cep);
      setFieldValue(`branches[${index}].city`, address.city);
      setFieldValue(`branches[${index}].state`, address.uf);
      setFieldValue(`branches[${index}].neighborhood`, address.neighborhood);
      setFieldValue(`branches[${index}].address`, address.street);
    };

    useEffect(() => {
      if (values.branches.length) setBranches(values.branches);
      if (isNaN(Number(values.feeLogshareWithoutCTe))) values.feeLogshareWithoutCTe = '0';
    }, []);

    interface SelectItem {
      label: string;
      value: string;
    }

    const handleRemoveBranchsRow = (index: number, id: number) => {
      const updatedbranches = branches;
      updatedbranches.splice(index, 1);
      setBranches(updatedbranches);
      setArrayToDeleteBranches(id);
    };

    const handleSelectedImage = useCallback(
      (file: File) => {
        if (file) {
          setFieldValue('fileKey', URL.createObjectURL(file));
          setFieldValue('file', file);
        }
      },
      [setFieldValue],
    );

    const renderFreetime = useMemo((): ReactElement | undefined => {
      if (user?.profile !== 'shipping-company') {
        return (
          <Fragment>
            <SectionDivider label="Free Time e Diárias" />
            <SectionStyled name="Free Time">
              <CompanyInformationFreetime />
            </SectionStyled>
          </Fragment>
        );
      }
    }, [user?.profile]);

    const renderBranchForm = (index: number, id: number) => {
      return (
        <>
          <LineSectionStyled columns="0.6fr 1fr 1.5fr">
            <InputFieldCnpj
              label="CNPJ *"
              id="cnpj"
              name="cnpj"
              type="text"
              value={values.branches[index]?.cnpj || ''}
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].cnpj`, e.target.value);
              }}
            />

            <InputField
              label="Nome Fantasia *"
              id="fantasyName"
              name="fantasyName"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].fantasyName`, e.target.value);
              }}
              value={values.branches[index]?.fantasyName || ''}
            />

            <InputField
              label="Razão Social *"
              id="corporateName"
              name="corporateName"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].corporateName`, e.target.value);
              }}
              value={values.branches[index]?.corporateName || ''}
            />
          </LineSectionStyled>
          <LineSectionStyled columns="0.45fr 1.35fr 2.2fr 0.4fr">
            <InputFieldCep
              label="CEP *"
              id="cep"
              name={`values.branches[${index}]?.cep`}
              type="text"
              value={values.branches ? values.branches[index]?.cep : ''}
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].cep`, e.target.value);
              }}
              onBlur={(e) => onBlurCEPFieldBranch(e.target.value, index)}
            />

            <InputField
              label="Bairro *"
              id="neighborhood"
              name="neighborhood"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].neighborhood`, e.target.value);
              }}
              value={values.branches[index]?.neighborhood || ''}
            />

            <InputField
              label="Cidade *"
              id="city"
              name="city"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].city`, e.target.value);
              }}
              value={values.branches[index]?.city || ''}
            />

            <Select
              label="UF *"
              id="state"
              name="state"
              value={values.branches[index]?.state || ''}
              setFieldValue={(e: any, value: any) => {
                setFieldValue(`branches[${index}].state`, value);
              }}
              options={uf}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1.5fr 0.4fr 1.1fr 0.1fr">
            <InputField
              label="Endereço *"
              id="address"
              name="address"
              type="text"
              value={values.branches[index]?.address || ''}
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].address`, e.target.value);
              }}
            />

            <InputField
              label="Nº *"
              id="number"
              name="number"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].number`, e.target.value);
              }}
              value={values.branches[index]?.number || ''}
            />

            <InputField
              label="Complemento"
              id="complement"
              name="complement"
              type="text"
              onChange={(e: any) => {
                setFieldValue(`branches[${index}].complement`, e.target.value);
              }}
              value={values.branches[index]?.complement || ''}
            />
            <div style={{ marginTop: 24 }}>
              <Button
                title="Deletar"
                bgColor="newRed"
                icon={<DeleteIcon onClick={() => handleRemoveBranchsRow(index, id)} />}
                size="very-small"
              />
            </div>
          </LineSectionStyled>
          <DiviserStyled />
        </>
      );
    };

    const [companyOptions, setCompanyOptions] = useState<SelectItem[]>([{ value: 'ALL', label: 'TODOS' }]);
    const [selectedCompanies, setSelectedCompanies] = useState<SelectItem[] | []>([]);

    useEffect(() => {
      (async () => {
        try {
          const response = await listCompanyService();

          setCompanyOptions(response);
        } catch (error) {
          console.error('Erro ao obter as opções da companies:', error);
        }
      })();
    }, []);

    useMemo(() => {
      if (values.companysId.length > 0) {
        const builtInCompanies = companyOptions.filter((company) => {
          if (values.companysId?.includes(company.value as any)) return company;
        });

        setSelectedCompanies(builtInCompanies);
      }
    }, [companyOptions]);

    const handleCompanyChange = (event: any) => {
      const partners = event.map((option: any) => option.value);

      setSelectedCompanies(event);
      setFieldValue('companysId', partners);
    };

    return (
      <FormStyled>
        <SectionStyled name="Informações do Empresa">
          <WrapperSectionCompanyDataStyled>
            <NameWrapperStyled>
              <InputField
                label="Razão Social"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                disabled
              />
            </NameWrapperStyled>

            <TradeNameWrapperStyled>
              <InputField
                label="Nome Fantasia"
                id="tradeName"
                name="tradeName"
                type="text"
                onChange={handleChange}
                value={values.tradeName}
                disabled
              />
            </TradeNameWrapperStyled>

            <InputField
              label="Login"
              id="login"
              name="login"
              type="text"
              onChange={handleChange}
              value={values.login}
              disabled
            />

            <EmailWrapperStyled>
              <InputField
                label="E-mail"
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                value={values.email}
                hasError={!!errors.email && !!touched.email}
                errorMessage={String(errors.email)}
                upperCase={false}
              />
            </EmailWrapperStyled>

            <Select
              label="Tipo"
              id="type"
              name="type"
              value={values.type}
              setFieldValue={setFieldValue}
              hasError={!!errors.type && !!touched.type}
              errorMessage={String(errors.type)}
              options={optionsType}
              disabled
            />

            <Select
              label="Planos"
              id="plan"
              name="plan"
              value={values.plan}
              setFieldValue={setFieldValue}
              hasError={!!errors.plan && !!touched.plan}
              errorMessage={String(errors.plan)}
              options={optionsPlan}
              disabled
            />

            <InputFieldCnpj
              label="CNPJ"
              id="cnpj"
              name="cnpj"
              type="text"
              onChange={handleChange}
              value={values.cnpj}
              disabled
            />

            <Select
              label="Possui DDR"
              id="hasDdr"
              name="hasDdr"
              value={values.hasDdr}
              setFieldValue={setFieldValue}
              hasError={!!errors.hasDdr && !!touched.hasDdr}
              errorMessage={String(errors.hasDdr)}
              options={[
                {
                  label: 'SIM',
                  value: true,
                },
                {
                  label: 'NÃO',
                  value: false,
                },
              ]}
              disabled={!isAdmin}
            />

            <Select
              label="Gerenciadora de Risco"
              id="riskManager"
              name="riskManager"
              value={values.riskManager}
              setFieldValue={setFieldValue}
              hasError={!!errors.riskManager && !!touched.riskManager}
              errorMessage={String(errors.riskManager)}
              options={optionsRiskManager}
              disabled={!isAdmin}
            />

            <Select
              label="Status"
              id="status"
              name="status"
              value={values.status}
              setFieldValue={setFieldValue}
              hasError={!!errors.status && !!touched.status}
              errorMessage={String(errors.status)}
              options={optionsStatus}
              disabled
            />

            <UploadImageStyled>
              <InputUploadImage
                urlImage={values.fileKey}
                hasError={false}
                handleSelectedImage={(_, file) => handleSelectedImage(file)}
                deleteImage={() => null}
                profilePhoto
              />
            </UploadImageStyled>
          </WrapperSectionCompanyDataStyled>
        </SectionStyled>

        {renderFreetime}

        <SectionDivider label="Filiais" />
        <SectionStyled name="Filiais">
          {user?.profile !== 'shipping-company' &&
            (values?.branches.length >= 1 ? (
              branches?.map((value: any, index: number) => {
                return renderBranchForm(index, value.id);
              })
            ) : (
              <></>
            ))}

          <div style={{ marginTop: 15 }}>
            <Button
              title="Adicionar"
              bgColor="blue"
              callback={() => {
                setFieldValue(`branches[${values.branches.length === 1 ? 0 + 1 : values.branches.length}]`, {
                  cnpj: '',
                  companyId: String(user?.companyId),
                  fantasyName: '',
                  corporateName: '',
                  cep: '',
                  address: '',
                  number: '',
                  city: '',
                  state: '',
                  complement: '',
                }),
                  setBranches([
                    ...branches,
                    {
                      cnpj: '',
                      companyId: String(user?.companyId),
                      fantasyName: '',
                      corporateName: '',
                      cep: '',
                      address: '',
                      number: '',
                      city: '',
                      state: '',
                      complement: '',
                    },
                  ]);
              }}
              icon={<AddIcon />}
              size="very-small"
            />
          </div>
        </SectionStyled>

        <SectionDivider label="Dados Financeiros" />
        <SectionStyled name="Financeiro">
          <LineSectionStyled columns={`${values.plan !== 'shipping-company' ? 'repeat(4, 2fr)' : 'repeat(6, 1fr)'}`}>
            <InputCurrency
              label="Fee Logshare (Com CTe) *"
              id="feeLogshare"
              name="feeLogshare"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={1}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={1}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.feeLogshare}
              disabled={!isAdmin}
            />

            <InputCurrency
              label="Fee Logshare (Sem CTe)"
              id="feeLogshareWithoutCTe"
              name="feeLogshareWithoutCTe"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={2}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.feeLogshareWithoutCTe}
              disabled={!isAdmin}
            />

            <InputCurrency
              label="Gris *"
              id="gris"
              name="gris"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={3}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={3}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.gris}
              hasError={!!errors.gris && !!touched.gris}
              errorMessage={String(errors.gris)}
              disabled={!isAdmin}
            />

            <InputCurrency
              label="AdValorem *"
              id="advalorem"
              name="advalorem"
              suffix={' %'}
              defaultValue={0}
              decimalsLimit={3}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={3}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.advalorem}
              hasError={!!errors.advalorem && !!touched.advalorem}
              errorMessage={String(errors.advalorem)}
              disabled={!isAdmin}
            />

            {values.plan !== 'shipping-company' && (
              <>
                <Select
                  label="Prazo de Pagamento *"
                  id="paymentTerm"
                  name="paymentTerm"
                  value={values.paymentTerm}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.paymentTerm && !!touched.paymentTerm}
                  errorMessage={String(errors.paymentTerm)}
                  options={optionsDeadlines}
                />
                <Select
                  label="Modalidade de Pagamento *"
                  id="modalityPayment"
                  name="modalityPayment"
                  value={values.modalityPayment}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.modalityPayment && !!touched.modalityPayment}
                  errorMessage={String(errors.modalityPayment)}
                  options={optionsPaymentMethod}
                />
              </>
            )}

            <Select
              label="Prazo de Recebimento"
              id="receiptDeadline"
              name="receiptDeadline"
              value={values.receiptDeadline}
              setFieldValue={setFieldValue}
              hasError={!!errors.status && !!touched.status}
              errorMessage={String(errors.status)}
              options={optionsDeadlines}
              disabled
            />

            <Select
              label="Modalidade de Recebimento"
              id="modalityDeadline"
              name="modalityDeadline"
              value={values.modalityDeadline}
              setFieldValue={setFieldValue}
              hasError={!!errors.status && !!touched.status}
              errorMessage={String(errors.status)}
              options={optionsReceiptMethod}
              disabled
            />

            <Select
              label="Ciclo de Fechamento"
              id="closingCycle"
              name="closingCycle"
              value={values.closingCycle}
              setFieldValue={setFieldValue}
              hasError={!!errors.status && !!touched.status}
              errorMessage={String(errors.status)}
              options={optionsClosingCycle}
              disabled
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Endereço de Cobrança" />
        <SectionStyled name="Cobrança">
          <LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
            <InputFieldCep
              label="CEP *"
              id="cep"
              name="cep"
              type="text"
              onChange={handleChange}
              onBlur={(e) => onBlurCEPField(e.target.value)}
              value={values.cep}
              hasError={!!errors.cep && !!touched.cep}
              errorMessage={String(errors.cep)}
            />

            <InputField
              label="Bairro *"
              id="neighborhood"
              name="neighborhood"
              type="text"
              onChange={handleChange}
              value={values.neighborhood}
              hasError={!!errors.neighborhood && !!touched.neighborhood}
              errorMessage={String(errors.neighborhood)}
            />

            <InputField
              label="Cidade *"
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={values.city}
              hasError={!!errors.city && !!touched.city}
              errorMessage={String(errors.city)}
            />

            <Select
              label="UF *"
              id="uf"
              name="uf"
              value={values.uf}
              setFieldValue={setFieldValue}
              hasError={!!errors.uf && !!touched.uf}
              errorMessage={String(errors.uf)}
              options={uf}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1.5fr 0.4fr 1.1fr">
            <InputField
              label="Endereço *"
              id="street"
              name="street"
              type="text"
              onChange={handleChange}
              value={values.street}
              hasError={!!errors.street && !!touched.street}
              errorMessage={String(errors.street)}
            />

            <InputField
              label="Nº *"
              id="numberHouse"
              name="numberHouse"
              type="text"
              onChange={handleChange}
              value={values.numberHouse}
              hasError={!!errors.numberHouse && !!touched.numberHouse}
              errorMessage={String(errors.numberHouse)}
            />

            <InputField
              label="Complemento"
              id="complement"
              name="complement"
              type="text"
              onChange={handleChange}
              value={values.complement}
              hasError={!!errors.complement && !!touched.complement}
              errorMessage={String(errors.complement)}
            />
          </LineSectionStyled>
        </SectionStyled>

        {user?.profile === 'shipping-company' && (
          <>
            <SectionDivider label="Dados Bancários" />
            <SectionStyled name="Endereço">
              <LineSectionStyled columns="1.36fr 1.36fr 2.5fr 5.5fr">
                <InputField
                  label="Banco (Código)"
                  id="shippingCompany.bankCode"
                  name="shippingCompany.bankCode"
                  type="text"
                  onChange={handleChange}
                  value={values.shippingCompany?.bankCode}
                  disabled
                />

                <InputField
                  label="Agência"
                  id="shippingCompany.bankAgency"
                  name="shippingCompany.bankAgency"
                  type="text"
                  onChange={handleChange}
                  value={values.shippingCompany?.bankAgency}
                  disabled
                />

                <InputField
                  label="Conta Corrente (com dígito)"
                  id="shippingCompany.bankCC"
                  name="shippingCompany.bankCC"
                  type="text"
                  onChange={handleChange}
                  value={values.shippingCompany?.bankCC}
                  disabled
                />

                <InputField
                  label="Chave Pix"
                  id="shippingCompany.bankPix"
                  name="shippingCompany.bankPix"
                  type="text"
                  onChange={handleChange}
                  value={values.shippingCompany?.bankPix}
                  disabled
                />
              </LineSectionStyled>
            </SectionStyled>
          </>
        )}

        {user?.profile !== 'shipping-company' && (
          <>
            <SectionDivider label="Parceiros de Backhaul" />
            <SectionStyled name="ParceirosBackhaul">
              <MultiSelect
                label="Parceiros de Backhaul"
                options={companyOptions}
                menuPosition="fixed"
                values={selectedCompanies}
                onChange={(e) => handleCompanyChange(e)}
              />
            </SectionStyled>
          </>
        )}

        <SectionDivider label="Dados para Contato" />
        <SectionStyled name="Contatos">
          {values.contacts.map((contacts: ICompanyContact, index: number) => (
            <LineSectionStyled key={index} columns="1fr 1fr 3fr">
              <InputField
                label={`Nome (Contato ${index + 1})`}
                id={`contacts[${index}].name`}
                name={`contacts[${index}].name`}
                type="text"
                onChange={handleChange}
                value={contacts.name}
              />

              <ContainerTelephoneStyled>
                <InputFieldPhone
                  label="Telefone"
                  id={`contacts[${index}].telephone`}
                  name={`contacts[${index}].telephone`}
                  type="text"
                  onChange={handleChange}
                  value={contacts.telephone}
                />
              </ContainerTelephoneStyled>

              <InputField
                label="E-mail"
                id={`contacts[${index}].email`}
                name={`contacts[${index}].email`}
                type="text"
                onChange={handleChange}
                value={contacts.email}
                upperCase={false}
              />
            </LineSectionStyled>
          ))}
        </SectionStyled>

        <CompanyInformationFileSection id={companyValues.id} />

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleSubmit} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const CompanyInformationForm = withFormik<ICompany, ICompany>({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
      return { ...props };
    },

    handleSubmit: (values) => {
      handleRegister(values);
    },
  })(InnerForm);

  return <CompanyInformationForm {...companyValues} />;
};

export default CompanyInformationFormView;
