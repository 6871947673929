export const downloadDocument = async (filePath?: string, name?: string) => {
  if (!filePath) return;

  const response = await fetch(filePath);
  const blob = await response.blob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name || filePath.substring(filePath.lastIndexOf('/') + 1);
  link.click();

  URL.revokeObjectURL(link.href);
};

export const downloadFromUrl = async (filePath?: string, name?: string) => {
  if (!filePath) return;

  const link = document.createElement('a');

  link.href = filePath;
  link.target = '_blank';
  link.download = name ? name : filePath.substring(filePath.lastIndexOf('/') + 1);
  link.click();
};

export const downloadFromBlob = async (blob: Blob, name?: string) => {
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.target = '_blank';
  link.download = name ? name : '';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
