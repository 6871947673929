import { LineSectionLayout } from 'components-v2/layout/line-section';
import { FC } from 'react';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import { FormikErrors } from 'formik';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';
import AsyncSelectField from 'components/async-select/async-select';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';

interface IWeighingDuplicatedFormMountShippingViewProps {
  values: IWeighingDuplicatedDomain;
  isMaster: boolean;
  hasError: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<IWeighingFormRegisterDomain>>;
}

export const WeighingDuplicatedFormMountShippingView: FC<IWeighingDuplicatedFormMountShippingViewProps> = ({
  values,
  hasError,
  isMaster,
  setFieldValue,
}) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Transportadora *"
          name="shippingCompany.name"
          id="shippingCompany.name"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue('shippingCompany', { id: e && e.value, name: e && e.label });
          }}
          apiCallFunction={shippingCompanyFilterSearchService}
          loadOptions={shippingCompanyFilterSearchService}
          defaultValue={{
            label: values.shippingCompany.name,
            value: values.shippingCompany.id,
          }}
          hasError={hasError && !values.shippingCompany.id}
          errorMessage="Campo Obrigatório"
          disabled={!isMaster}
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
