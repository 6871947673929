import styled from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const ContainerDateStyled = styled.div`
  margin-top: 4px;
`;
