import React, { useContext, useEffect } from 'react';
import { HomeCardsView } from './home-cards-view.component';
import { useGetHomeCards } from '../../hooks/use-get-home-cards/use-get-home-cards.hook';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';

export const HomeCards: React.FC = () => {
  const { homeCards } = useContext(HomeContext);

  const getHomeCardsHook = useGetHomeCards();

  useEffect(() => {
    if (homeCards.statusRequest === 'INITIAL') {
      getHomeCardsHook();
    }
  }, [getHomeCardsHook, homeCards.statusRequest]);

  return <HomeCardsView />;
};
