import React, { ChangeEvent, DragEvent, useCallback, useMemo, useRef } from 'react';
import { InputDropFileView } from './attach-documento-input-drop-view.component';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { AcceptFiles } from './attach-documento-input-drop.types';
import { useFormikContext } from 'formik';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IInputDropFileProps {
  acceptFiles: Array<AcceptFiles>;
  disabled?: boolean;
  allowMultipleFiles?: boolean;
  position: number;
}

export const InputDropFile: React.FC<IInputDropFileProps> = ({
  acceptFiles,
  disabled,
  allowMultipleFiles,
  position,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setFieldValue, getFieldMeta, getFieldProps } = useFormikContext<RegistrationAttachmentValues>();

  const handleClick = useCallback(() => {
    if (disabled) return;

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [disabled]);

  const handleSelectedFile = useCallback(
    (files: FileList) => {
      setFieldValue(`attachments[${position}].file`, files[0]);
    },
    [position, setFieldValue],
  );

  const handleValidateFileType = useCallback(
    (files: FileList): boolean => {
      const isValidFileType = acceptFiles.some((type) => files[0].name.endsWith(type));

      if (!isValidFileType) {
        showMessageFormatted({
          message: `Selecione arquivos com o(s) formato(s) ${acceptFiles.join(' ou ')}!`,
          type: 'error',
        });

        return false;
      }

      return true;
    },
    [acceptFiles],
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (disabled) return;

      const files = event.dataTransfer.files;
      const validateFileType = handleValidateFileType(files);

      if (validateFileType) {
        handleSelectedFile(files);
      }
    },
    [disabled, handleSelectedFile, handleValidateFileType],
  );

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;

      const files = event.target.files;

      if (files && files.length > 0) {
        const validateFileType = handleValidateFileType(files);

        if (validateFileType) {
          handleSelectedFile(files);
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    },
    [disabled, handleSelectedFile, handleValidateFileType],
  );

  const inputAcceptFiles = useMemo((): string => acceptFiles.join(', '), [acceptFiles]);

  const hasError = useMemo(
    (): boolean =>
      !!getFieldMeta(`attachments[${position}].file`).error && getFieldMeta(`attachments[${position}].file`).touched,
    [getFieldMeta, position],
  );

  const hasFile = useMemo(
    (): boolean => getFieldProps(`attachments[${position}].file`).value,
    [getFieldProps, position],
  );

  return (
    <InputDropFileView
      inputAcceptFiles={inputAcceptFiles}
      fileInputRef={fileInputRef}
      disabled={disabled}
      allowMultipleFiles={allowMultipleFiles}
      handleClick={handleClick}
      handleDrop={handleDrop}
      handleDragOver={handleDragOver}
      handleFileChange={handleFileChange}
      hasError={hasError}
      hasFile={hasFile}
    />
  );
};
