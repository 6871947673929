import { IOrderLocality, IOrderOriginType } from 'domain/order';

export const optionTypes: Array<{ label: string; value: IOrderOriginType }> = [
  { label: 'UNIDADE', value: 'plant' },
  { label: 'PARCEIRO', value: 'client' },
];

export const optionsLocality: Array<{ label: string; value: IOrderLocality }> = [
  { label: 'CD', value: 'distribution_center' },
  { label: 'LOJA', value: 'shop' },
  { label: 'HUB', value: 'hub' },
  { label: 'X-DOCK', value: 'others' },
];

export const optionTypesWithOther: Array<{
  label: string;
  value: string;
}> = [
  { label: 'UNIDADE', value: 'plant' },
  { label: 'PARCEIRO', value: 'client' },
  { label: 'OUTRO', value: 'other' },
];
