import { useCallback } from 'react';

import { getDistanceBetweenPoints, IHereMapsDistanceCalculate } from 'services/maps/distance.service';

const useMapDistanceCalculateHook = () => {
  return useCallback(async (params: IHereMapsDistanceCalculate): Promise<number | null> => {
    const distance = await getDistanceBetweenPoints(params);
    return distance;
  }, []);
};

export { useMapDistanceCalculateHook };
