import styled, { css } from 'styled-components';
import { Form, Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;
type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const WrapperSectionCompanyDataStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 15%;
  grid-gap: 5px 20px;
`;

export const NameWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const TradeNameWrapperStyled = styled.div`
  grid-column-start: 3;
  grid-column-end: 4px;
`;

export const EmailWrapperStyled = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const UploadImageStyled = styled.div`
  width: 190px;
  height: 190px;
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 4;
  margin-top: 32px;
`;

export const OptionStyled = styled.option``;

export const ContainerTelephoneStyled = styled.div`
  margin-top: 3px;
`;
export const ContainerButtonStyled = styled.div`
  margin-top: 35px;
`;
