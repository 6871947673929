/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import FloatingLoading from '../floating-loading/floating-loading';
import { locationAll } from '../../../location/services/location-list/location-list.service';

import { StatusCode } from './map.types';
import { WrapperMapView } from './map-view.styled';
import { MapView } from './map-view';

import { queryBuilder } from 'query/builder';
import { shippingCompanies } from 'pages/transport-company/services/transport-company-list/transport-company-list.service';
import { IAllCompanies } from 'pages/territorial-vision/territorial-vision-body/territorial-vision-body.types';
import { schedulingListService } from 'pages/freight/services/schedule-list/scheduling-list.service';
import { allClients } from 'pages/client/services/client.service';

const scheduleFilterBase = {
  search: '',
  page: 1,
  order: 'DESC',
  hasLimit: true,
};

export const Map: React.FC = () => {
  const getSchedules = React.useCallback(async () => {
    return await schedulingListService({
      ...scheduleFilterBase,
      statusCode: Object.keys(StatusCode),
    });
  }, []);

  const schedules = queryBuilder('schedules', getSchedules);

  const clients = queryBuilder<IAllCompanies[]>('clients', allClients);
  const shipping = queryBuilder<IAllCompanies[]>('shipping', shippingCompanies);
  const localities = queryBuilder<IAllCompanies[]>('localities', locationAll);

  if (schedules.isLoading || clients.isLoading || shipping.isLoading || localities.isLoading)
    return <FloatingLoading isLoading={true} />;

  return (
    <WrapperMapView>
      <MapView
        clients={clients.data as unknown as IAllCompanies[]}
        schedules={schedules.data?.list as unknown as any[]}
        localities={localities.data as unknown as IAllCompanies[]}
        shippingCompany={shipping.data as unknown as IAllCompanies[]}
      />
    </WrapperMapView>
  );
};
