import styled from 'styled-components';

export const ContainerBodyStyled = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const ContainerCountServiceStyled = styled.div`
  position: absolute;
  bottom: 15px;
  right: 35px;
`;

export const ContainerCountContentStyled = styled.div`
  display: flex;
  gap: 5px;
  height: 30px;
  align-items: center;
`;

export const CountTitleStyled = styled.div`
  font-size: 12px;
`;

export const CountLabelStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
