import { FC, useCallback, useMemo } from 'react';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { convertDecimalCurrencyFormat } from 'utils-v2/converters/decimal';
import * as S from './schedule-finance-resume-view.styled';

interface IScheduleFinanceResumeViewProps {
  schedule: IScheduleDetailsDomain;
  typeTransaction: 'freithgtPayable' | 'freithgtReceivable' | 'freithgtShipping';
}

export const ScheduleFinanceResumeView: FC<IScheduleFinanceResumeViewProps> = ({ schedule, typeTransaction }) => {
  const getTypeTransaction = useMemo(() => {
    return schedule[typeTransaction];
  }, [schedule, typeTransaction]);

  const renderDiscountValues = useCallback((value: number): string => {
    if (value > 0) {
      return `- ${convertDecimalCurrencyFormat.format(value)}`;
    }

    return convertDecimalCurrencyFormat.format(0);
  }, []);

  return (
    <S.Wrapper>
      <S.WrapperInformation>
        <S.InformationTitle>Frete Valor</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.valueFreight)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Ajudante</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.helperTotal)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Pedágio</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.toll)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Descarga</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.discharge)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Tempo Excedente</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.surplusTime)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Gris / Advalorem</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.totalGrisAdvalorem)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Taxa Logshare</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={true}>
            {renderDiscountValues(getTypeTransaction.totalFee)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Desconto</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={true}>
            {renderDiscountValues(getTypeTransaction.penaltyBonus)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle contrastValue>Frete Total</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false} contrastValue>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.totalFreight)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>ICMS</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.icms)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle>Outros Impostos</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false}>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.otherTaxes)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
      <S.WrapperInformation>
        <S.InformationTitle contrastValue>Total do Serviço</S.InformationTitle>
        <S.WrapperCurrency>
          <S.InformationTitle>R$</S.InformationTitle>
          <S.InformationValue discountValue={false} contrastValue>
            {convertDecimalCurrencyFormat.format(getTypeTransaction.totalService)}
          </S.InformationValue>
        </S.WrapperCurrency>
      </S.WrapperInformation>
    </S.Wrapper>
  );
};
