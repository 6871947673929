import React from 'react';

import { IScheduleHistory } from '../../freight-update-form';

import FreightVehicleSectionView from './freight-vehicle-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  values?: ScheduleDomain;
  idSheduleValues?: string;
  type?: 'ltl' | 'ftl' | 'stl';
  scheduleHistory?: IScheduleHistory[];
  onRefresh: React.Dispatch<React.SetStateAction<number>>;
}

const FreightVehicleSection: React.FC<IProps> = ({ values, idSheduleValues, type, scheduleHistory, onRefresh }) => {
  return (
    <FreightVehicleSectionView
      schedule={values}
      idSheduleValues={idSheduleValues}
      type={type}
      scheduleHistory={scheduleHistory}
      onRefresh={onRefresh}
    />
  );
};

export default React.memo(FreightVehicleSection);
