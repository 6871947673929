import { useCallback } from 'react';

import { scheduleDetailsService } from 'pages/freight/services/schedule-details-services/schedule-details-by-id/cargo-details-by-id.service';
import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';

const useScheduleDetailsHook = () => {
  return useCallback(async (id: number): Promise<IAllocationShippingDetails> => {
    return scheduleDetailsService(id);
  }, []);
};

export { useScheduleDetailsHook };
