import { ScheduleOccurrenceListActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';
import { IScheduleOccurrenceListActions } from '../../types/schedule-occurrence.types';

export const scheduleOccurrenceListReducer = (
  state: IScheduleContext,
  action: IScheduleOccurrenceListActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_REQUEST:
      return {
        ...state,
        scheduleOccurrenceList: {
          ...state.scheduleOccurrenceList,
          statusRequest: 'PROCESSING',
          scheduleOccurrenceList: [],
        },
      };
    case ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_SUCCESS:
      return {
        ...state,
        scheduleOccurrenceList: {
          ...state.scheduleOccurrenceList,
          statusRequest: 'SUCCESS',
          scheduleOccurrenceList: action.payload,
        },
      };
    case ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_ERROR:
      return {
        ...state,
        scheduleOccurrenceList: {
          ...state.scheduleOccurrenceList,
          statusRequest: 'ERROR',
          scheduleOccurrenceList: [],
        },
      };
    case ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_RESET:
      return {
        ...state,
        scheduleOccurrenceList: {
          ...state.scheduleOccurrenceList,
          statusRequest: 'INITIAL',
          scheduleOccurrenceList: [],
        },
      };
    default:
      return state;
  }
};
