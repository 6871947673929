import API from 'utils/API/API';
import { ScheduleDomain } from 'domain/schedule';
import { IIdlenessBiddingModalForm } from 'domain/bidding';

export const getScheduleById = async (scheduleId: number): Promise<ScheduleDomain | undefined> => {
  const { data } = await API.get(`v2/schedules/${scheduleId}`);
  return data;
};

export const getIdlenessBiddingById = async (idlenessId: number): Promise<IIdlenessBiddingModalForm> => {
  const { data } = await API.get(`v2/idleness/${idlenessId}`);
  return data;
};
