import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { deleteScheduleHistoryDriverAndVehicleService } from '../../services/delete-schedule-history-driver-and-vehicle/delete-schedule-history-driver-and-vehicle.service';

export const UseDeleteScheduleHistoryDriver = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (historyId: number) => {
      try {
        await deleteScheduleHistoryDriverAndVehicleService(historyId, 'MOTORISTA');
        showMessageFormatted({
          message: 'Motorista removido com sucesso.',
          type: 'success',
        });

        // INVALIDATE QUERY DATA
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
        reactQueryClient.invalidateQueries(['schedule-history-driver']);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
