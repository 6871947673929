import API from 'utils/API/API';
import { IScheduleCustomFields } from '../../domains/schedule-details.domain';

export const createScheduleCustomFieldService = async (
  scheduleId: number,
  customFields: Array<IScheduleCustomFields>,
): Promise<Array<IScheduleCustomFields>> => {
  const customFieldsValues = customFields.map((data) => ({ customFieldId: data.id, value: data.value }));

  const { data } = await API.post(`v3/schedule/${scheduleId}/custom-fields`, {
    customFields: customFieldsValues,
  });

  return data;
};
