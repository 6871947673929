import React, { ChangeEvent, DragEvent, ReactElement, RefObject, useMemo } from 'react';
import * as S from './input-drop-file-view.styled';
import { CloudUploadIcon } from 'assets/icons/cloud-upload.icon';

interface IInputDropFileViewProps {
  inputAcceptFiles: string;
  fileInputRef: RefObject<HTMLInputElement>;
  disabled?: boolean;
  allowMultipleFiles?: boolean;
  handleClick: () => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleTemplate?: () => void;
}

export const InputDropFileView: React.FC<IInputDropFileViewProps> = ({
  inputAcceptFiles,
  fileInputRef,
  disabled,
  allowMultipleFiles,
  handleClick,
  handleDrop,
  handleDragOver,
  handleFileChange,
  handleTemplate,
}) => {
  const renderTemplateDownload = useMemo((): ReactElement | undefined => {
    if (handleTemplate) {
      return (
        <S.TemplateContent>
          Download do
          <S.TemplateDownload onClick={handleTemplate} disabled={disabled}>
            Template
          </S.TemplateDownload>
        </S.TemplateContent>
      );
    }
  }, [disabled, handleTemplate]);

  return (
    <S.Wrapper>
      <S.InputContent onClick={handleClick} onDrop={handleDrop} onDragOver={handleDragOver} disabled={disabled}>
        <input
          type="file"
          accept={inputAcceptFiles}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
          multiple={allowMultipleFiles}
        />
        <CloudUploadIcon />
        <S.Text>Arraste o arquivo</S.Text>
        <S.TextItalic>ou</S.TextItalic>
        <S.Text>Click para anexar</S.Text>
      </S.InputContent>

      {renderTemplateDownload}
    </S.Wrapper>
  );
};
