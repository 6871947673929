import styled from 'styled-components';

export const GoogleMapContainerStyled = styled.div`
  height: 100vh;
  width: 100%;

  .gm-style .gm-style-cc {
    display: none;
  }

  .gm-style .gmnoprint {
    display: none;
  }
`;
