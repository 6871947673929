import styled, { css } from 'styled-components';

interface IProps {
  active: boolean;
}

export const TabsHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TabsContentStyled = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-top: 9px;
  overflow-x: hidden;
`;

export const TabWrapperStyled = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  margin-top: 30px;
`;

export const TabTitleStyled = styled.button<IProps>`
  ${({ theme, active }) => css`
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: ${active ? '600' : 'normal'};
    font-size: 13px;
    line-height: 18px;
    background-color: white;
    cursor: pointer;
    border: 0;
    outline: 0;
  `}
`;

export const TabsNameLineStyled = styled.div<IProps>`
  ${({ theme, active }) =>
    active &&
    css`
      width: 100%;
      position: absolute;
      bottom: -5px;
      border-bottom: 2px solid ${theme.colors.blue};
    `}
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};
    margin-top: 3px;
    width: 100%;
  `}
`;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 63vh;
  `}
`;

export const EmptyListWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `}
`;