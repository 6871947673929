import API from 'utils/API/API'
import { TransactionRegisterInput } from 'domain-v2/transactions/register'

export const transactionUpdateCteLogshareService = async (
  transactionId: number,
  transactionValues: TransactionRegisterInput,
) => {
  return await API.patch(
    `v2/financial-calculation/cte-logshare/${transactionId}`,
    transactionValues,
  )
}
