import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';

const calcCubage = (high: number, width: number, length: number) => {
  const cubage = (high / 100) * (width / 100) + length / 100;

  return cubage;
};

export const convertScheduleLTLCreate = (schedule: ScheduleLTLRegisterInput) => {
  const { origin, destination, cargo, detail, cost, distance, operation, freightModality } = schedule;

  const merchValue = cargo.merchValue.toString().replace(',', '.');
  return {
    origin: {
      id: origin.id,
      type: origin.type,
    },
    destination: {
      id: destination.id,
      type: destination.type,
    },
    cargo: {
      vehicleType: 'carreta',
      vehicleCategoryType: 'bau_seco',
      shippingReturn: '',
      product: cargo.product,
      weight: Number(cargo.weight),
      cubagem: calcCubage(cargo.high, cargo.width, cargo.length),
      volume: Number(cargo.volume),
      merchValue: Number(merchValue),
      inPallet: cargo.inPallet,
      pallet: Number(cargo.pallet),
      order: cargo.order,
      comments: '',
      requirements: {
        quality: [],
        security: [],
        custom: [],
      },
    },
    detail: {
      levyInitialDate: detail.levyInitialDate,
      levyEndDate: detail.levyEndDate,
      deliveryInitialDate: detail.deliveryInitialDate,
      deliveryEndDate: detail.deliveryEndDate,
      ocInternalNumber: detail.ocInternalNumber,
      boardingNumber: detail.boardingNumber,
      schedulingPassword: detail.schedulingPassword,
    },
    cost: {
      valueFreight: cost.valueFreight || 0,
      valueGoods: cost.valueGoods || 0,
      valueTotalFee: cost.valueTotalFee || 0,
      taxes: cost.taxes || 0,
      totalFreight: cost.totalFreight || 0,
      helperTotal: cost.helperTotal || 0,
      toll: cost.toll || 0,
      totalGrisAdvalorem: cost.totalGrisAdvalorem || 0,
      totalService: cost.totalService || 0,
      fine: cost.fine || 0,
      icms: cost.icms || 0,
    },
    type: 'ltl',
    freightModality,
    distance,
    operation,
  };
};
