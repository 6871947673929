import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UpdateCompanySchema } from '../schemas-validation/schemas-validation';

import {
  initialValues,
  PATH_TO_LIST_COMPANIES,
  optionsPlan,
  optionsStatus,
  optionsRiskManager,
} from './form-values/form-values';
import FormUpdateCompanyView from './company-update-form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { contactTypes } from 'pages/company/mappers/converter-company-domain-to-form-data/converter-company-domain-to-form-data';
import { useUpdateCompanyHook } from 'pages/company/hooks/company-update/company-update.hook';
import { useDetailsCompanyHook } from 'pages/company/hooks/company-details/company-details.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { ICompany, ICompanyContact } from 'domain/company';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const FormUpdateCompany: React.FC = () => {
  const { setLoading } = useContext(UserContext);
  const [companyValues, setCompanyValues] = useState<ICompany>(initialValues);
  const navigate = useNavigate();
  const { id } = useParams();

  const detailsCompanyHook = useDetailsCompanyHook();
  const updateCompanyHook = useUpdateCompanyHook();
  const cepHook = useCepHook();
  const goBackToListCompanies = useCallback(() => navigate(PATH_TO_LIST_COMPANIES), [navigate]);

  const handleSubmit = useCallback(
    async (company: ICompany) => {
      setLoading(true);
      updateCompanyHook(company, [])
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToListCompanies();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        )
        .finally(() => setLoading(false));
    },
    [goBackToListCompanies, setLoading, updateCompanyHook],
  );

  const onBlurCep = useCallback(
    async (cep: string) => {
      const address = await cepHook(cep);
      return address;
    },
    [cepHook],
  );

  useEffect(() => {
    async function run() {
      if (id) {
        const company = await detailsCompanyHook(Number(id));
        const contacts = mergeContactsCreatedWithInitial(company.contacts);
        const companyWithContacts = { ...company, ...{ contacts: contacts } };
        setCompanyValues(companyWithContacts);
      }
    }
    run();
  }, [detailsCompanyHook, id]);

  const mergeContactsCreatedWithInitial = useCallback((contacts: Array<ICompanyContact>): Array<ICompanyContact> => {
    const maxContacts = 6;

    const allContacts = [];
    for (let index = 0; index < maxContacts; index++) {
      const existContact = contacts?.find((contact) => contact.type === contactTypes[index]);

      if (existContact) {
        allContacts.push(existContact);
      } else {
        allContacts.push({ name: '', email: '', telephone: '' });
      }
    }
    return allContacts;
  }, []);

  return (
    <FormUpdateCompanyView
      optionsPlan={optionsPlan}
      optionsStatus={optionsStatus}
      optionsRiskManager={optionsRiskManager}
      companyValues={companyValues}
      handleGoback={goBackToListCompanies}
      handleRegister={handleSubmit}
      validationSchema={UpdateCompanySchema}
      onBlurCep={onBlurCep}
    />
  );
};

export default FormUpdateCompany;
