import { FC, Fragment, useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsAllocationShippingView } from './schedule-details-allocation-shipping.component-view';
import { IScheduleDetailsShippingDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { useUpdateScheduleShippingAllocation } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-shipping-allocation/use-update-schedule-shipping-allocation.hook';

export const ScheduleDetailsAllocationShipping: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleShippingAllocationHook = useUpdateScheduleShippingAllocation();

  const shipping = useMemo((): IScheduleDetailsShippingDomain | undefined => {
    if (scheduleDetails.allocationShipping.shippingCompanyId !== scheduleDetails.scheduleDetails.shipping?.id) {
      const shippingAllocate = scheduleDetails.allocationShipping.shippingList.find(
        (item) => item.id === scheduleDetails.allocationShipping.shippingCompanyId,
      );

      return shippingAllocate;
    }
  }, [scheduleDetails.allocationShipping, scheduleDetails.scheduleDetails.shipping?.id]);

  const handleShippingAllocation = useCallback(() => {
    if (scheduleDetails.scheduleDetails.statusCode !== 'OT99') {
      updateScheduleShippingAllocationHook();
    }

    scheduleDetails.allocationShipping.setScheduleDetailsAllocationSelectedShipping(0);
  }, [
    scheduleDetails.allocationShipping,
    scheduleDetails.scheduleDetails.statusCode,
    updateScheduleShippingAllocationHook,
  ]);

  if (!scheduleDetails.scheduleDetails.shipping?.id) {
    return <Fragment />;
  }

  return (
    <ScheduleDetailsAllocationShippingView
      shipping={shipping ?? scheduleDetails.scheduleDetails.shipping}
      statusCode={scheduleDetails.scheduleDetails.statusCode}
      handleShippingAllocation={handleShippingAllocation}
    />
  );
};
