import { queryClient } from 'services/query-client';
import { registerHireBackhaulService } from 'pages/hire-backhaul/services/hire-backhaul-register/hire-backhaul-register.service';
import { IHireBackhaul } from 'domain/hire-backhaul';

const useRegisterHireBackhaulHook = () => {
  return async (hireBackhaul: IHireBackhaul) => {
    await registerHireBackhaulService(hireBackhaul);
    queryClient.invalidateQueries(['idlenessList']);
  };
};

export { useRegisterHireBackhaulHook };
