import { useFormikContext } from 'formik';
import { FC } from 'react';
import { WeighingFormMountItemsView } from './weighing-register-form-mount-items-view.component';
import { IWeighingFormRegisterDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register.domain';

export const WeighingFormMountItems: FC = () => {
  const { setFieldValue } = useFormikContext<IWeighingFormRegisterDomain>();

  return <WeighingFormMountItemsView setFieldValue={setFieldValue} />;
};
