import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { getHomeCardsService } from '../../services/get-home-cards/get-home-cards.service';

export const useGetHomeCards = () => {
  const { homeCards } = useContext(HomeContext);
  const { setHomeCardsRequest, setHomeCardsSuccess, setHomeCardsError } = homeCards;

  return useCallback(async () => {
    setHomeCardsRequest();
    try {
      const data = await getHomeCardsService();

      setHomeCardsSuccess(data);
    } catch (error) {
      setHomeCardsError();

      showMessageFormatted({
        message: 'Erro ao obter detalhes de oferta de compra e venda.',
        type: 'error',
      });
    }
  }, [setHomeCardsError, setHomeCardsRequest, setHomeCardsSuccess]);
};
