import { IMatchesKanbanDetails } from '../../domains/matches-kanban-list/matches-kanban-list.domain';
import { IModalBiddingOffer } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer.types';

export const convertMatchesKanbanToSaleOffer = (matchesKanban: IMatchesKanbanDetails): IModalBiddingOffer => {
  return {
    ...matchesKanban,
    destination: matchesKanban.destiny,
    cost: {
      freightValue: matchesKanban.freightValue,
      feeLogshare: 0,
      grisAdvalorem: 0,
      icms: 0,
      tollValue: 0,
      totalFreight: matchesKanban.freightValue,
      totalService: matchesKanban.freightValue,
    },
    capacity: {
      cubagem: matchesKanban.capacity.cubage ?? 0,
      inPallet: matchesKanban.capacity.pallet ? 'SIM' : 'NÃO',
      merchValue: 0,
      pallets: 0,
      product: 'NÃO INFORMADO',
      volume: matchesKanban.capacity.pallet,
      weight: matchesKanban.capacity.weight,
    },
  };
};
