export interface ITimeRecording {
  label: ITimeRecordingLabel;
  planDate: string;
  realDate: string;
  agent: string;
  status: boolean;
  active: boolean;
}

export enum ITimeRecordingLabel {
  loadSchedulingDate = 'Programação da Carga',
  allocationShippingDate = 'Alocação da Transportadora',
  acceptShipping = 'Aceite da Transportadora',
  allocationDriverDate = 'Alocação do Motorista',
  allocationVehicleDate = 'Alocação do Veículo',
  startDate = 'Início da Viagem para Carregamento',
  arrivalLoadingDate = 'Chegada para Carregamento',
  checkinDate = 'Início da Coleta',
  checkoutDate = 'Fim da Coleta',
  startTripUnloadingDate = 'Início da Viagem para Descarga',
  arrivalUnloadingDate = 'Chegada para Descarga',
  loadDate = 'Início da Descarga',
  finishDate = 'Fim da Descarga',
}
