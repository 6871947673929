import React from 'react';

import { IInputCalendarProps, InputCalendarView } from './input-calendar-view';

export const InputCalendar: React.FC<IInputCalendarProps> = ({
  onDateChange,
  onFocusChange,
  focused,
  date,
  placeholder,
  id,
  disabled,
  label,
  hasError,
  errorMessage,
  showClearDate,
  selectPastDays,
  hasPicker,
  addDateBlocked: addDate,
  dateHighlight,
  minDate,
  withPortal,
}): JSX.Element => {
  return (
    <InputCalendarView
      label={label}
      id={id}
      onDateChange={onDateChange}
      onFocusChange={onFocusChange}
      focused={focused}
      date={date}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={placeholder}
      disabled={disabled}
      showClearDate={showClearDate}
      selectPastDays={selectPastDays}
      hasPicker={hasPicker}
      minDate={minDate}
      addDateBlocked={addDate}
      dateHighlight={dateHighlight}
      withPortal={withPortal}
    />
  );
};
