import styled, { css } from 'styled-components';

interface StyleProps {
  isShipping: boolean;
}

export const WrapperStyled = styled.div<StyleProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    background-color: ${theme.sidebar.colors.blue};
    padding-top: 156px;
    padding-bottom: 20px;
  `}
`;

export const MenuStyled = styled.ul`
  list-style: none;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 13px;
  gap: 20px;
`;

export const IconStyled = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  cursor: pointer;
  border: none;

  @media (max-width: 600px) {
    position: absolute;
    bottom: 20px;
  }
`;

export const LogoutIconStyled = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  cursor: pointer;
  border: none;
  padding-left: 4px;

  @media (max-width: 600px) {
    position: absolute;
    bottom: 20px;
  }
`;
