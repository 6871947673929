import { PageHeaderFilterActionType } from '../page-header-filter.action';
import { IPageHeaderFilterContext, PageHeaderFilterActions } from '../types/page-header-filter.types';

export const pageHeaderFilterReducer = <T>(
  state: IPageHeaderFilterContext<T>,
  action: PageHeaderFilterActions<T>,
): IPageHeaderFilterContext<T> => {
  switch (action.type) {
    case PageHeaderFilterActionType.PAGE_HEADER_FILTER_SET:
      return { ...state, filters: action.payload };
    case PageHeaderFilterActionType.PAGE_HEADER_FILTER_OPEN_MODAL:
      return { ...state, modalProps: { ...state.modalProps, isOpen: true } };
    case PageHeaderFilterActionType.PAGE_HEADER_FILTER_CLOSE_MODAL:
      return { ...state, modalProps: { ...state.modalProps, isOpen: false } };
    case PageHeaderFilterActionType.PAGE_HEADER_FILTER_RESET:
      return { ...state, filters: {} as T, modalProps: { ...state.modalProps, isOpen: false } };
    default:
      return state;
  }
};
