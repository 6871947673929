import React, { Fragment, ReactElement, useCallback } from 'react';
import { SectionDivider } from 'src-new/components/section-divider/section-divider.component';
import { SectionContentStyled } from './risk-management-section-view.styled';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Button } from 'logshare-ui-kit';
import AddIcon from '@mui/icons-material/Add';
import { RiskManagementTable } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/components/risk-management-table/risk-management-table.component';
import { RiskManagementModal } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/components/risk-management-modal/risk-management-modal.component';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IRiskDetailsSectionViewProps {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  driverId?: number;
}

export const RiskDetailsSectionView: React.FC<IRiskDetailsSectionViewProps> = ({
  handleOpenModal,
  handleCloseModal,
  isOpenModal,
  driverId,
}) => {
  const renderModal = useCallback(
    (arrayHelpers: FieldArrayRenderProps): ReactElement | undefined => {
      if (isOpenModal) {
        return <RiskManagementModal arrayHelpers={arrayHelpers} setModalClose={handleCloseModal} driverId={driverId} />;
      }
    },
    [driverId, handleCloseModal, isOpenModal],
  );

  return (
    <Fragment>
      <SectionDivider
        label="Gerenciadora de Risco"
        hidePin
        secondItem={
          <Button
            label=""
            color={ThemeColorsV2Type.Blue}
            size="small"
            variant="solid"
            onPress={handleOpenModal}
            LeftIcon={<AddIcon fontSize="small" style={{ color: '#fff' }} />}
          />
        }
      />
      <FieldArray
        name="riskManagers"
        render={(arrayHelpers) => (
          <Fragment>
            {renderModal(arrayHelpers)}
            <SectionContentStyled>
              <RiskManagementTable handleRemoveContact={arrayHelpers.remove} />
            </SectionContentStyled>
          </Fragment>
        )}
      />
    </Fragment>
  );
};
