import { IScheduleCustomFields } from '../../../domains/schedule-details.domain';
import { ICustomFieldsResponse } from '../get-custom-fields.types';

export const converterCustomFieldsResponseToDomain = (
  scheduleCustomFieldsResponse: Array<ICustomFieldsResponse>,
): Array<IScheduleCustomFields> => {
  return scheduleCustomFieldsResponse.map((data) => ({
    id: data.id,
    isRequired: data.isRequired,
    label: data.label,
    customField: {
      id: data.id,
      companyId: data.companyId,
      isRequired: data.isRequired,
      label: data.label,
    },
  }));
};
