import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_TO_LIST_CLIENT } from '../form-values/form-values';
import ClientRegisterFormView from './client-register-form-view';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useClientRegisterHook } from 'pages/client/hooks/use-client-register/use-client-register.hook';
import { useCepHook } from 'hooks/cep/cep.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { analystSearchService } from 'pages/client/services/analyst-search/analyst-search.filter-search.service';
import { SelectItem } from 'domain/select-item';
import { UserContext } from 'state/user-context';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';

const ClientRegisterForm: React.FC = () => {
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedCompany, setLoggedCompany] = useState<string>('');
  const navigate = useNavigate();
  const registerClient = useClientRegisterHook();
  const cepHook = useCepHook();
  const goBackToListClients = useCallback(() => navigate(PATH_TO_LIST_CLIENT), [navigate]);
  const getCompany = useGetCompany();

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  const handleSubmit = useCallback(
    async (client: IClientDomain) => {
      setIsLoading(true);
      const newClient = {
        ...client,
        lat: client.lat ?? '',
        lng: client.lng ?? '',
      };

      registerClient(newClient, newClient.attachments)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToListClients();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToListClients, registerClient],
  );

  const apiCallContractualAnelyst = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return analystSearchService(searchValue);
    }
    return [];
  };

  useEffect(() => {
    (async () => {
      if (user?.companyId) {
        const company = await getCompany(user.companyId);
        setLoggedCompany(company?.tradeName ?? '');
      }
    })();
  }, [getCompany, user?.companyId]);

  return (
    <ClientRegisterFormView
      isLoading={isLoading}
      loggedCompany={loggedCompany}
      handleRegister={handleSubmit}
      handleGoback={goBackToListClients}
      onBlurCep={onBlurCep}
      apiCallContractualAnelyst={apiCallContractualAnelyst}
    />
  );
};

export default ClientRegisterForm;
