import React from 'react';

import ToggleCheckboxView from './toogle-checkbox-view';

type CheckboxProps = {
  id: string;
  name: string;
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ToggleCheckbox: React.FC<CheckboxProps> = ({ id, name, label, checked, onChange }) => {
  return <ToggleCheckboxView id={id} name={name} label={label} checked={checked} onChange={onChange} />;
};

export default ToggleCheckbox;
