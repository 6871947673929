import React, { memo, useMemo, useState } from 'react';

import * as S from './styled';

import { PageInfo } from 'domain-v2/inputs/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';
import Checkbox from 'components/checkbox/checkbox';

interface HeaderTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (transportId: string) => void;
  isLoading: boolean;
  handleCheckboxAll: (clicked: boolean) => void;
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  changePage,
  pageInfo,
  isLoading,
  handleCheckboxAll = () => null,
  clickRowAction,
}) => {
  const [checkboxAllState, setCheckBoxAllState] = useState<boolean>(false);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (checked) {
      setCheckBoxAllState(true);
      handleCheckboxAll(true);
    } else {
      setCheckBoxAllState(false);
      handleCheckboxAll(false);
    }
  };

  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: (
          <div style={{ marginTop: -1 }}>
            <Checkbox
              id="checkboxAll"
              name="checkboxAll"
              value={String(0)}
              onChange={(event) => handleChangeCheckbox(event)}
              disabled={checkboxAllState}
            />
          </div>
        ),
        flex: 0.1,
      },
      {
        value: 'ID',
        flex: 0.2,
      },
      {
        value: 'Categoria',
        flex: 0.4,
      },

      {
        value: 'Data Entrega',
        flex: 0.4,
      },
      {
        value: 'Tomador',
        flex: 0.5,
      },
      {
        value: 'Faturado Por',
        flex: 0.5,
      },

      {
        value: 'CTe (Primário)',
        flex: 0.5,
      },
      {
        value: 'CTe (Sub)',
        flex: 0.5,
      },
      {
        value: 'Total do Serviço',
        flex: 0.5,
      },
      {
        value: 'Processo',
        flex: 0.4,
      },
      {
        value: '',
        flex: 0.5,
      },
    ];
  }, []);

  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.ContainerPagination>
          <Pagination activePage={pageInfo.currentPage} totalPage={pageInfo.totalPages} changePage={changePage} />
        </S.ContainerPagination>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <div>
      <Table header={headerTable} content={contentTable} isLoading={isLoading} clickRowAction={clickRowAction} zIndex />
      {renderPagination}
    </div>
  );
};

export default memo(HeaderTable);
