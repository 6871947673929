import { useCallback } from 'react';
import { showToast } from 'components/toast/toast';
import {
  exportReportService,
  ExportReportType,
} from 'src-new/pages/registrations/services/export-report/export-report.service';

export const useExportReport = () => {
  return useCallback(
    async (search: string, type: ExportReportType, status?: 'ativo' | 'inativo'): Promise<ArrayBuffer | undefined> => {
      try {
        const response = await exportReportService(search, type, status);

        showToast({
          message: 'Relatório gerado com sucesso.',
          type: 'success',
        });
        return response;
      } catch (error) {
        showToast({
          message: 'Erro ao tentar exportar relatório.',
          type: 'error',
        });
      }
    },
    [],
  );
};
