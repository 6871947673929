import { IVehiclesContext, IVehiclesReducerAction } from './vehicles.types';
import { IVehiclesAction } from './vehicles.actions';

export const vehiclesReducer = (state: IVehiclesContext, action: IVehiclesReducerAction): IVehiclesContext => {
  switch (action.type) {
    case IVehiclesAction.VEHICLES_REQUEST: {
      return {
        ...state,
        vehiclesIsLoading: true,
        vehicles: [],
        vehiclesError: undefined,
      };
    }
    case IVehiclesAction.VEHICLES_SUCCESS: {
      return {
        ...state,
        vehiclesIsLoading: false,
        vehicles: action.payload.list,
        vehiclesPageInfo: action.payload.pageInfo,
        vehiclesError: undefined,
      };
    }
    case IVehiclesAction.VEHICLES_ERROR: {
      return {
        ...state,
        vehiclesIsLoading: false,
        vehicles: [],
        vehiclesError: action.payload,
        vehiclesPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
