import { useCallback, useReducer } from 'react';
import { chatInitialState } from '../../chat.constants';
import { ChatConversationListActionType } from 'src-new/contexts/chat/chat.action';
import { chatConversationListReducer } from 'src-new/contexts/chat/reducers/chat-conversation-list/chat-conversation-list.reducer';
import { IChatConversationList } from 'src-new/contexts/chat/types/chat-conversation-list.types';
import { IConversationChatDomain } from 'src-new/domains/conversation-chat.domain';
import { IChatContextError } from 'src-new/contexts/chat/types/chat.types';

export const useChatConversationListContextValues = (): IChatConversationList => {
  const [state, dispatch] = useReducer(chatConversationListReducer, chatInitialState);

  const setChatConversationListRequest = useCallback(() => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_REQUEST, payload: undefined });
  }, []);

  const setChatConversationListSuccess = useCallback((conversations: Array<IConversationChatDomain>) => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_SUCCESS, payload: conversations });
  }, []);

  const setChatConversationListError = useCallback((error: IChatContextError) => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_ERROR, payload: error });
  }, []);

  const setIsOpenModal = useCallback((isOpen: boolean) => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_MODAL_OPEN, payload: isOpen });
  }, []);

  const setChatConversationListReset = useCallback(() => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_RESET, payload: undefined });
  }, []);

  const setNextPage = useCallback((page?: string) => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_NEXT_PAGE, payload: page });
  }, []);

  const setPageLimit = useCallback(() => {
    dispatch({ type: ChatConversationListActionType.CHAT_CONVERSATION_LIST_PAGE_LIMIT, payload: undefined });
  }, []);

  return {
    ...state.chatConversationList,
    setChatConversationListRequest,
    setChatConversationListSuccess,
    setChatConversationListError,
    setChatConversationListReset,
    paginationActions: {
      ...state.chatConversationList.paginationActions,
      setNextPage,
      setPageLimit,
    },
    modalActions: {
      ...state.chatConversationList.modalActions,
      setIsOpenModal,
    },
  };
};
