import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getTollValueService } from 'pages-v2/schedule/services/get-toll-value/get-toll-value.service';
import { ITollValueParams } from 'pages-v2/schedule/services/get-toll-value/get-toll-value.types';

export const useGetTollValue = () => {
  return useCallback(async (params: ITollValueParams): Promise<number | undefined> => {
    try {
      return getTollValueService(params);
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao obter valor do pedágio.',
        type: 'error',
      });
    }
  }, []);
};
