export function convertReworkStatus(status: string) {
  switch (status) {
    case 'AG RETRABALHO':
      return 'EM RETRABALHO';
    case 'EM RETRABALHO':
      return 'AG PUXADA';
    case 'AG PUXADA':
      return 'AG RECEBIMENTO';
    case 'AG RECEBIMENTO':
      return 'EM RECEBIMENTO';
    case 'EM RECEBIMENTO':
      return 'CONCLUIDO';
    default:
      return null;
  }
}
