import React, { Fragment } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { ScheduleTable } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-table-tabs/components/schedule-table/schedule-table.component';

export interface IScheduleTableTabsViewProps {
  tabsContent: Array<ITabContent>;
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const ScheduleTableTabsView: React.FC<IScheduleTableTabsViewProps> = ({
  tabsContent,
  activeTab,
  handleChangeTab,
}) => {
  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
      <ScheduleTable />
    </Fragment>
  );
};
