import { Link, useNavigate } from 'react-router-dom';
import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import ShareIcon from '@mui/icons-material/Share';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import MiniMap from '../../../../components/freight/mini-map';

import {
  ContainerInfosChargeStyled,
  ContainerPhotosStyled,
  ContainerRegistersStyled,
  WrapperContainerStyled,
} from './freight-update-form.styled';
import { IScheduleHistory } from './freight-update-form';
import { Wrapper } from './freight-register-section/styled';
import FreightVehicleSection from './freight-register-section/freight-vehicle-section/freight-vehicle-section';
import FreightShippingSection from './freight-register-section/freight-shipping-section/freight-shipping-section';
import FreightDriverSection from './freight-register-section/freight-driver-section/freight-driver-section';
import FreightCargoPhotosSection from './freight-register-section/freight-cargo-photos-section/freight-cargo-photos-section';
import FreightOperationSection from './freight-register-section/freight-cargo-operation-section/freight-cargo-operation-section';
import FreightNFESection from './freight-register-section/freight-cargo-nfe-section/freight-cargo-nfe-section';
import FreightMDFESection from './freight-register-section/freight-cargo-mdfe-section/freight-cargo-mdfe-section';
import { FreightCTESection } from './freight-register-section/freight-cargo-cte-section/freight-cargo-cte-section';
import { initialValuesSendEmail } from './components/form-destiny-email/form-values';
import SendEmailModal from './components/form-destiny-email';

import { UserContext } from 'state/user-context';
import { trackingOccurrencesListService } from 'services/ocurrence-tracking';
import { CalculateTransactions } from 'pages-v2/schedule/details/sections/transactions';
import FreightChecklistSection from 'pages-v2/schedule/details/sections/checklist';
import { ITrackingOccurrence } from 'domain/tracking-occurrence';
import { ISchedulingKeys, ScheduleDomain } from 'domain/schedule';
import { IUser } from 'domain/login';
import { FooterPage } from 'components-v2/common/footer-page';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import ServiceDetails from 'components/freight/service-details';
import DetailsSchedule from 'components/freight/schedule-details';
import ClientDetails from 'components/freight/origin-destiny-details';
import CargoDetails from 'components/freight/cargo-details';
import TooltipComponent from 'components/Tooltip';

interface IProps {
  currentAllocation: ScheduleDomain;
  schedulingKeys?: ISchedulingKeys[];
  scheduleDriverHistory? : IScheduleHistory[];
  scheduleVehicleHistory? : IScheduleHistory[];
  goToAllocationList: () => void;
  goToOccurrenceForm: () => void;
  handleSendEmailAllocation: (id: number, destinyEmail: string) => void;
  handleAllocationCancel: () => void;
  onRefresh: Dispatch<SetStateAction<number>>;
  handleOpenAuditModal?: (statusType: string) => void;
}

const userInitialUser = {} as IUser;

const FreightUpdateBodyView: React.FC<IProps> = ({
  onRefresh,
  currentAllocation,
  schedulingKeys,
  scheduleDriverHistory,
  scheduleVehicleHistory,
  goToAllocationList,
  goToOccurrenceForm,
  handleSendEmailAllocation,
  handleAllocationCancel,
}) => {
  const { user, isAdmin } = useContext(UserContext);

  const navigate = useNavigate();

  const handleReofferTrip = () => navigate(`/alocacao/${currentAllocation?.id}`);

  const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
  const [sendEmailForm, setSendEmailForm] = useState(initialValuesSendEmail);

  const SendEmailFormRef = useRef<FormikProps<{ emailAddress: string }>>(null);

  const [occurrences, setOccurrences] = useState<{
    items: ITrackingOccurrence[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  }>();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    (async (page: number) => {
      const findValues = await trackingOccurrencesListService({
        search: '',
        page: page,
        scheduleId: currentAllocation.id,
        order: 'DESC',
        column: 'id',
      });
      setOccurrences(findValues);
    })(currentPage);
  }, [currentAllocation.id > 0, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const goToDetailsPage = (occurrenceId: string, scheduleId: string, occurrences: ITrackingOccurrence[]) => {
    const trackingOccurrence = occurrences.find((item) => item.id === Number(occurrenceId));
    navigate(`/tracking-ocorrencias/atualizar/${occurrenceId}/${scheduleId}`, {
      state: {
        sla: trackingOccurrence?.occurrenceType?.sla,
        impact: trackingOccurrence?.occurrenceType?.impact,
        description: trackingOccurrence?.occurrenceType?.description,
      },
    });
  };

  const statusToHideCancelButton = ['OT10', 'OT99', 'AL90', 'AL99', 'IN90'];

  return (
    <Fragment>
      {modalSendEmail && (
        <ModalConfirmation
          title="Envio de Ordem de Coleta"
          description={
            <>
              <Formik
                innerRef={SendEmailFormRef}
                initialValues={sendEmailForm}
                onSubmit={(values) => {
                  setSendEmailForm(() => values);
                  handleSendEmailAllocation(currentAllocation.id, values.emailAddress);
                  SendEmailFormRef.current?.resetForm();
                  setModalSendEmail(false);
                }}
                onReset={() => {
                  setSendEmailForm(initialValuesSendEmail);
                }}
              >
                <SendEmailModal id={currentAllocation.id} user={user} />
              </Formik>

              <div style={{ position: 'absolute', bottom: 50 }}></div>
            </>
          }
          handleXCancel={() => setModalSendEmail(false)}
          legacy
          size="medium"
          type="normal"
        />
      )}

      <WrapperContainerStyled>
        <ServiceDetails
          values={currentAllocation}
          occurrences={occurrences}
          handleDetailsPage={(id: string) =>
            goToDetailsPage(id, String(currentAllocation.id), occurrences?.items ?? [])
          }
          handleChangesPage={handlePageChange}
        />

        <Wrapper display="flex" gap={20} border={false} padding={false}>
          <ClientDetails type="Origem" isAdmin={isAdmin} values={currentAllocation.scheduleClientOrigin} />

          <ClientDetails type="Destino" isAdmin={isAdmin} values={currentAllocation.scheduleClientDestiny} />

          <MiniMap
            origin={{
              lat:
                currentAllocation?.scheduleClientOrigin?.client?.lat ||
                currentAllocation?.scheduleClientOrigin?.locality?.lat,
              lng:
                currentAllocation?.scheduleClientOrigin?.client?.lng ||
                currentAllocation?.scheduleClientOrigin?.locality?.lng,
            }}
            destiny={{
              lat:
                currentAllocation?.scheduleClientDestiny?.client?.lat ||
                currentAllocation?.scheduleClientDestiny?.locality?.lat,
              lng:
                currentAllocation?.scheduleClientDestiny?.client?.lng ||
                currentAllocation?.scheduleClientDestiny?.locality?.lng,
            }}
            driver={currentAllocation?.driver}
            schedule={currentAllocation}
          />
        </Wrapper>

        <CargoDetails onRefresh={onRefresh} freightValues={currentAllocation} />

        <DetailsSchedule values={currentAllocation} schedulingKeys={schedulingKeys} onRefresh={onRefresh} />

        {user?.profile !== 'shipping-company' && <FreightShippingSection values={currentAllocation} />}

        <FreightDriverSection 
          values={currentAllocation} 
          scheduleHistory={scheduleDriverHistory} 
          onRefresh={onRefresh} 
        />

        <ContainerInfosChargeStyled>
          <FreightVehicleSection
            values={currentAllocation}
            idSheduleValues={String(currentAllocation.id)}
            type={currentAllocation.typeSchedule}
            scheduleHistory={scheduleVehicleHistory}
            onRefresh={onRefresh}
          />
        </ContainerInfosChargeStyled>

        <FreightOperationSection
          onRefresh={onRefresh}
          values={currentAllocation.scheduleDetails as any}
          currentAllocation={currentAllocation}
        />

        <FreightChecklistSection schedule={currentAllocation} />

        <FreightNFESection freightValues={currentAllocation} />

        <FreightCTESection freightValues={currentAllocation} onRefresh={onRefresh} />

        <FreightMDFESection freightValues={currentAllocation} />

        <CalculateTransactions
          user={user || userInitialUser}
          isAdmin={isAdmin}
          transactions={currentAllocation.calcFinanceData}
          schedule={currentAllocation}
          onRefresh={onRefresh}
        />

        <ContainerRegistersStyled>
          <ContainerPhotosStyled>
            <FreightCargoPhotosSection statusCode={currentAllocation.statusCode} />
          </ContainerPhotosStyled>
        </ContainerRegistersStyled>
      </WrapperContainerStyled>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button
            label={
              currentAllocation.statusCode === 'OT99' &&
              currentAllocation.operation === 'TRACKING' &&
              user?.profile !== 'shipping-company'
                ? 'Nova Oferta'
                : 'Voltar'
            }
            color="blue"
            onPress={
              currentAllocation.statusCode === 'OT99' &&
              currentAllocation.operation === 'TRACKING' &&
              user?.profile !== 'shipping-company'
                ? handleReofferTrip
                : goToAllocationList
            }
          />

          {statusToHideCancelButton.includes(currentAllocation.statusCode) && (
            <FooterPage.Button label="Cancelar" color="red" onPress={handleAllocationCancel} />
          )}
        </FooterPage.RightContent>
        <FooterPage.LeftContent>
          <TooltipComponent
            title="Rastreio"
            location="right"
            icon={
              <Link
                to={`/rastreio/${currentAllocation.schedulingCode}`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <FooterPage.Button
                  label=""
                  color="white"
                  variant="ghost"
                  size="x-sm"
                  onPress={() => null}
                  leftIcon={<LocationOnIcon sx={{ width: '20px' }} />}
                />
              </Link>
            }
          />

          {currentAllocation.statusCode !== 'AG10' && currentAllocation.statusCode !== 'OT01' && (
            <TooltipComponent
              title="Envio de Ordem de Coleta"
              location="right"
              icon={
                <FooterPage.Button
                  label=""
                  color="green"
                  size="x-sm"
                  onPress={() => setModalSendEmail(true)}
                  leftIcon={<ShareIcon sx={{ width: '20px' }} />}
                />
              }
            />
          )}

          <FooterPage.Button
            label="Nova Ocorrência"
            color="pink"
            onPress={goToOccurrenceForm}
            disabled={currentAllocation.statusCode === 'IN90' && !isAdmin}
          />
        </FooterPage.LeftContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default FreightUpdateBodyView;
