export function mapServiceType(serviceType: string) {
  switch (serviceType) {
    case 'Devolução de Palete':
      return 'DEVOLUCAO_DE_PALETE';
    case 'Devolução de Mercadoria':
      return 'DEVOLUCAO_DE_MERCADORIA';
    case 'Transferência':
      return 'TRANSFERENCIA';
    case 'Repaletização':
      return 'REPALETIZACAO';
    case 'Paletização':
      return 'PALETIZACAO';
    case 'Crossdocking':
      return 'CROSSDOCKING';
    case 'Deslocamento':
      return 'DESLOCAMENTO';
    case 'Coleta':
      return 'COLETA';
    case 'Repaletização SPOT':
      return 'REPALETIZACAO_SPOT';
    case 'Importados':
      return 'IMPORTADOS';
    case 'Filme Streching':
      return 'FILME_STRECHING';
    case 'Troca de Palete Quebrado':
      return 'TROCA_DE_PALETE_QUEBRADO';
    default:
      return null;
  }
}
