import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    display: grid;
    grid-template-columns: 1fr 0fr;
    padding: 20px 10px 10px 10px;
    margin-top: 10px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid ${hasError ? theme.colors.error : theme.colors.gray};
  `}
`;

export const InputStyled = styled.input`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid;
    font-size: 13px;
    font-weight: 600;
    font-family: ${theme.font.family};
    border-color: ${theme.colors.gray};
    outline: none;
  `}
`;

export const ButtonADDStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.blue};
    font-size: 20px;
    color: ${theme.colors.white};
    width: 30px;
    height: 30px;
    border-radius: 3px;
    cursor: pointer;
  `}
`;

export const ResponsableListStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const ResponsableContainerStyled = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  background-color: hsl(0, 0%, 90%);
  font-size: 13px;
  font-weight: 600;
  color: hsl(0, 0%, 20%);
  border-radius: 2px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

export const DeleteResponsableStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    font-weight: ${theme.font.medium};
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
