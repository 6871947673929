import { includes } from 'lodash';
import {
  SaleOfferActionType,
  SaleOfferDetailsActionType,
  SaleOfferFreetimeActionType,
  SaleOfferListActionType,
  SaleOfferListDownloadReportActionType,
  SaleOfferMatchListActionType,
  SaleOfferModalsActionType,
  SaleOfferUpdateActionType,
} from '../sale-offer.action';
import { ISaleOfferDetailsActions } from '../types/sale-offer-details.types';
import { ISaleOfferFreetimeActions } from '../types/sale-offer-freetime.types';
import { ISaleOfferListActions } from '../types/sale-offer-list.types';
import { ISaleOfferMatchListActions } from '../types/sale-offer-match-list.types';
import { ISaleOfferModalsActions } from '../types/sale-offer-modals.types';
import { ISaleOfferActions, ISaleOfferContext } from '../types/sale-offer.types';
import { saleOfferDetailsReducer } from './sale-offer-details/sale-offer-details.reducer';
import { saleOfferFreetimeReducer } from './sale-offer-freetime/sale-offer-freetime.reducer';
import { saleOfferListReducer } from './sale-offer-list/sale-offer-list.reducer';
import { saleOfferMatchListReducer } from './sale-offer-match-list/sale-offer-match-list.reducer';
import { saleOfferModalsReducer } from './sale-offer-modals/sale-offer-modals.reducer';
import { saleOfferReducerResetState } from './sale-offer-reducer.constants';
import { saleOfferUpdateReducer } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/reducers/sale-offer-update/sale-offer-update.reducer';
import { ISaleOfferUpdateActions } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-update.types';
import { saleOfferListDownloadReportReducer } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/reducers/sale-offer-list-download-report/sale-offer-list-download-report.reducer';
import { ISaleOfferListDownloadReportActions } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-list-download-report.types';

export const saleOfferReducer = (state: ISaleOfferContext, action: ISaleOfferActions): ISaleOfferContext => {
  if (includes(Object.values(SaleOfferModalsActionType), action.type)) {
    return saleOfferModalsReducer(state, action as ISaleOfferModalsActions);
  }

  if (includes(Object.values(SaleOfferDetailsActionType), action.type)) {
    return saleOfferDetailsReducer(state, action as ISaleOfferDetailsActions);
  }

  if (includes(Object.values(SaleOfferListActionType), action.type)) {
    return saleOfferListReducer(state, action as ISaleOfferListActions);
  }

  if (includes(Object.values(SaleOfferFreetimeActionType), action.type)) {
    return saleOfferFreetimeReducer(state, action as ISaleOfferFreetimeActions);
  }

  if (includes(Object.values(SaleOfferMatchListActionType), action.type)) {
    return saleOfferMatchListReducer(state, action as ISaleOfferMatchListActions);
  }

  if (includes(Object.values(SaleOfferUpdateActionType), action.type)) {
    return saleOfferUpdateReducer(state, action as ISaleOfferUpdateActions);
  }

  if (includes(Object.values(SaleOfferListDownloadReportActionType), action.type)) {
    return saleOfferListDownloadReportReducer(state, action as ISaleOfferListDownloadReportActions);
  }

  if (action.type === SaleOfferActionType.SALE_OFFER_RESET) {
    return saleOfferReducerResetState(state);
  }

  return state;
};
