import { useCallback, useContext } from 'react';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import { IListPage } from 'src-new/domains/list-page.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { LocationsContext } from '../../../../contexts/locations/locations.context';
import { getLocationListService } from '../../services/get-location-list/get-location-list.service';

export const useGetLocationList = () => {
  const { locationsList } = useContext(LocationsContext);
  const { locations, setLocationsListRequest, setLocationsListSuccess, setLocationsListError, filterActions } =
    locationsList;
  const { filters, setFilters } = filterActions;
  const { status, order, column, searchValue, page } = filters;
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { setInitialLoadingSuccess } = initialLoading;

  const handlePageLimit = useCallback(
    (page: number, totalPages: number) => {
      if (page === totalPages || totalPages === 0) {
        setFilters({ ...filters, isPageLimit: true });
      }
    },
    [filters, setFilters],
  );

  return useCallback(async () => {
    setLocationsListRequest();

    try {
      const response: IListPage<ILocationDomain> = await getLocationListService({
        search: searchValue,
        page: page,
        status,
        order,
        column,
      });
      const oldLocations = page === 1 ? [] : locations;

      handlePageLimit(page, response.meta.totalPages);
      setLocationsListSuccess([...oldLocations, ...response.items]);
    } catch (error) {
      setLocationsListError();
    }
    setInitialLoadingSuccess();
  }, [
    column,
    handlePageLimit,
    locations,
    order,
    page,
    searchValue,
    setInitialLoadingSuccess,
    setLocationsListError,
    setLocationsListRequest,
    setLocationsListSuccess,
    status,
  ]);
};
