/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import { BackhaulRegisterSchema } from './schemas-validation/schemas-validation';
import { LinkText } from './hire-backhaul-register-form.styled';
import BackhaulRegisterFormView from './hire-backhaul-register-form-view';
import {
  PATH_TO_HIRE_BACKHAUL_LIST,
  initialValues,
  optionsTransportedCargo,
  optionsTime,
  optionsYesOrNo,
} from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useRegisterHireBackhaulHook } from 'pages/hire-backhaul/hooks/use-hire-backhaul-register/use-hire-backhaul-register.hook';
import { useComparationHireBackhaulHook } from 'pages/hire-backhaul/hooks/use-hire-backhaul-comparation/use-hire-backhaul-comparation.hook';
import { optionTypes } from 'pages/hire-backhaul/constants/label-options';
import { IHireBackhaul } from 'domain/hire-backhaul';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Button from 'components/button/button';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const HireBackhaulRegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const hireBackhaulRegisterHook = useRegisterHireBackhaulHook();
  const hireBackhaulComparationHook = useComparationHireBackhaulHook();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasHireBackhaul, setHasHireBackhaul] = useState<{
    open: boolean;
    id: Array<number>;
    backhaul: IHireBackhaul;
  }>({ open: false, id: [], backhaul: initialValues });

  const goBackToBackhaulList = useCallback(() => navigate(PATH_TO_HIRE_BACKHAUL_LIST), [navigate]);

  const handleComparation = useCallback(
    async (backhaul: IHireBackhaul) => {
      hireBackhaulComparationHook({
        categoryVehicle: backhaul.category || '',
        origin: backhaul.idOrigin || 0,
        destination: backhaul.idDestination || 0,
        typeVehicle: backhaul.vehicleType || '',
        typeOrigin: backhaul.originType === 'LOCALIDADE' ? 'UNIDADE' : backhaul.originType,
        typeDestination: backhaul.destinationType === 'LOCALIDADE' ? 'UNIDADE' : backhaul.destinationType,
      })
        .then((response) => {
          if (response.data.length > 0) {
            const ids = response.data.map((data: any) => {
              return data.id;
            });
            setHasHireBackhaul({
              open: true,
              id: ids,
              backhaul: backhaul,
            });
          } else {
            handleSubmit(backhaul);
          }
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        );
    },
    [hireBackhaulComparationHook],
  );

  const handleSubmit = useCallback(
    async (backhaul: IHireBackhaul) => {
      setIsLoading(true);
      hireBackhaulRegisterHook(backhaul)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToBackhaulList();
        })
        .catch((error) =>
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToBackhaulList, hireBackhaulRegisterHook],
  );

  const description = (
    <>
      {hasHireBackhaul.id.map((backhaulId: number, index) => {
        return (
          <div key={index} style={{ marginTop: 5 }}>
            <LinkText href={`/ofertas-compra/atualizar/${backhaulId}`} target="_blank" rel="noreferrer">
              {`https://logshare.app/ofertas-compra/atualizar/${backhaulId}`}
            </LinkText>
          </div>
        );
      })}
      <p
        style={{
          marginTop: 15,
          fontSize: 13,
        }}
      >
        Deseja continuar?
      </p>

      <div
        style={{
          marginTop: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          title="Cancelar"
          bgColor="newRed"
          size="medium"
          callback={() => setHasHireBackhaul((prevState) => ({ ...prevState, open: false }))}
        />
        <Button title="Salvar" bgColor="aqua" size="medium" callback={() => handleSubmit(hasHireBackhaul.backhaul)} />
      </div>
    </>
  );

  return (
    <>
      {hasHireBackhaul.open && (
        <ModalConfirmation
          title="Já existe outras ofertas cadastradas com esses dados"
          description={description}
          size="small"
          type="normal"
        />
      )}
      <BackhaulRegisterFormView
        hireBackhaulRegisterValues={hasHireBackhaul.backhaul}
        optionClientType={optionTypes}
        optionsTransportedCargo={optionsTransportedCargo}
        optionsTime={optionsTime}
        optionsYesOrNo={optionsYesOrNo}
        handleGoBack={goBackToBackhaulList}
        handleRegister={handleComparation}
        schemaValidation={BackhaulRegisterSchema}
        isLoading={isLoading}
      />
    </>
  );
};

export default HireBackhaulRegisterForm;
