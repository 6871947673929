import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';

import HeaderTable from '../header';

import { convertOperationType } from 'utils-v2/converters/operation';
import { convertFinancialType } from 'utils-v2/converters/financial-type';
import { convertFinancialStatus } from 'utils-v2/converters/financial-status';
import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { convertFreightType } from 'utils/freight-type';
import { CalcFinanceList } from 'domain-v2/transactions';
import { PageInfo } from 'domain-v2/inputs/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';

interface ListTransactionModalTableProps {
  transactions: Array<CalcFinanceList>;
  pageInfo: PageInfo;
  isLoading: boolean;
  selectedIds: Array<number>;
  changePage: (page: number) => void;
  handleSelectedFreight: (id: string) => void;
  handleCheckboxAll: (clicked: boolean) => void;
  handleCheckboxChange: (
    event: ChangeEvent<HTMLInputElement>,
    scheduleIds: Array<number>,
    ids: Array<number>,
    name: Array<string>,
    price: Array<number>,
    companyName: string,
    schedule: any,
    transaction: CalcFinanceList,
  ) => void;
}

export const TransactionModalTable: FC<ListTransactionModalTableProps> = ({
  transactions,
  pageInfo,
  isLoading,
  selectedIds,
  changePage,
  handleSelectedFreight,
  handleCheckboxAll,
  handleCheckboxChange,
}) => {
  const isTransactionDisabled = useCallback(
    (transaction: CalcFinanceList) => {
      const isInAudit = transaction.financialStatus === 'Em Auditoria';
      const isInInvoiced = transaction.financialStatus === 'Faturado';
      const isToPay = transaction.tag !== 'venda';

      return isInAudit || isInInvoiced || isToPay;
    },
    [transactions],
  );

  const mountTable = useMemo((): Array<IContentTable> => {
    return transactions.map((transaction) => {
      const companyName = transaction.taker;

      return [
        {
          value: (
            <Checkbox
              checked={selectedIds.includes(transaction.id)}
              id="transaction.schedule.id"
              name="transaction.schedule.id"
              value={String(0)}
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  [transaction.schedule.id],
                  [transaction.id],
                  [companyName],
                  [transaction.totalService],
                  companyName,
                  [transaction.schedule],
                  transaction,
                );
              }}
              disabled={isTransactionDisabled(transaction)}
            />
          ),
          flex: 0.1,
        },
        {
          value: transaction.schedule.id,
          flex: 0.2,
        },
        {
          value: (
            <Tag
              label={convertFreightType(transaction.schedule.typeSchedule).label}
              color={convertFreightType(transaction.schedule.typeSchedule).color}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          secondValue: (
            <Tag
              label={convertOperationType(transaction.schedule.scheduleCargo.operationType).label}
              color={convertOperationType(transaction.schedule.scheduleCargo.operationType).color}
              variant="ghost"
              display="flex"
              size="md"
            />
          ),
          flex: 0.4,
        },
        {
          value: moment(transaction.schedule.scheduleDetails.deliveryEndDate).format('DD/MM/YYYY'),
          flex: 0.4,
        },
        {
          value: transaction.taker,
          flex: 0.5,
        },

        {
          value: transaction.invoicedBy,
          flex: 0.5,
        },

        {
          value: transaction.schedule.cte.primary,
          isStrong: true,
          flex: 0.5,
        },

        {
          value: transaction.schedule.cte.sub,
          isStrong: true,
          flex: 0.5,
        },
        {
          value: convertCurrencyFormat.format(transaction.totalService),
          flex: 0.5,
        },
        {
          value: (
            <Tag
              label={convertFinancialType(transaction.tag).label}
              color={convertFinancialType(transaction.tag).color}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.4,
        },
        {
          value: (
            <Tag
              label={convertFinancialStatus(transaction.financialStatus).label}
              color={convertFinancialStatus(transaction.financialStatus).color}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.5,
        },
      ];
    });
  }, [transactions, selectedIds]);

  const renderFreightTable = useMemo(() => {
    return (
      <HeaderTable
        pageInfo={pageInfo}
        changePage={changePage}
        handleCheckboxAll={handleCheckboxAll}
        contentTable={mountTable}
        isLoading={isLoading}
        clickRowAction={handleSelectedFreight}
      />
    );
  }, [mountTable, isLoading, transactions]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'A Faturar',
        content: renderFreightTable,
      },
    ];
  }, [renderFreightTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={() => null} />;
};
