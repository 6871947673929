import React, { Dispatch, FC, Fragment, SetStateAction, useMemo } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { WeighingTable } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-table-tabs/components/weighing-table/weighing-table.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { WrapperStateStyled } from './weighing-table-tabs-view.styled';

export interface IStatusProps {
  isError: boolean;
  isInitialLoading: boolean;
  isEmpty: boolean;
}

export interface IWeighingTableTabsViewProps {
  statusProps: IStatusProps;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const WeighingTableTabsView: FC<IWeighingTableTabsViewProps> = ({
  statusProps,
  activeTab,
  handleChangeTab,
  handleInfinityScroll,
}) => {
  const renderTableContent = useMemo(() => {
    if (statusProps.isInitialLoading) {
      return (
        <WrapperStateStyled>
          <Loading />
        </WrapperStateStyled>
      );
    }

    if (statusProps.isError) {
      return (
        <WrapperStateStyled>
          <ErrorState />
        </WrapperStateStyled>
      );
    }

    if (statusProps.isEmpty) {
      return (
        <WrapperStateStyled>
          <EmptyState title="Lista Vazia" subTitle="Nenhum registro localizado." />
        </WrapperStateStyled>
      );
    }

    return <WeighingTable handleInfinityScroll={handleInfinityScroll} />;
  }, [handleInfinityScroll, statusProps.isEmpty, statusProps.isError, statusProps.isInitialLoading]);

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Expedicao',
        content: renderTableContent,
      },
      {
        name: 'Recebimento',
        content: renderTableContent,
      },
      {
        name: 'Todos',
        content: renderTableContent,
      },
    ];
  }, [renderTableContent]);

  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
    </Fragment>
  );
};
