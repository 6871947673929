import styled, { css } from 'styled-components';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IProps {
  color: ThemeColorsV2Type;
}

export const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  overflow: hidden;
`;

export const ContainerHeader = styled.div<IProps>`
  ${({ theme, color }) => css`
    height: 32px;
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colorsV2[color]};
    padding: 16px;
    border-radius: 6px;
    font-size: ${theme.fontV2.sizes.text4};
    color: ${theme.colorsV2.white};
    cursor: default;
  `}
`;

export const Label = styled.span``;

export const ContainerScroll = styled.div<IProps>`
  ${({ theme, color }) => css`
    overflow-y: scroll;
    height: 85%;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.colorsV2[color]};
    }
  `}
`;

export const CardRouterBody = styled.div`
  & > div {
    margin-top: 20px;
    margin-right: 12px;
  }
`;

export const WrapperList = styled.div`
  ${({ theme }) => css`
    height: fit-content;
    width: 97%;
    cursor: pointer;
    background-color: ${theme.colorsV2.white};
    border: 1px solid ${theme.colorsV2.forBorder};
    border-radius: 6px;
    margin-right: 10px;
    font-family: ${theme.font.family};
    font-size: ${theme.fontV2.sizes.text4};

    &:hover {
      background-color: #f3f3f3;
    }

    &:hover ${ContainerButton} {
      display: flex;
    }
  `}
`;

export const ContainerKanban = styled.div`
  padding: 10px;
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

export const LabelId = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 10px;
    font-size: ${theme.fontV2.sizes.text4};
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerHeaderTooltip = styled.div``;

export const ContainerInfosTooltip = styled.div``;

export const ContainerTooltip = styled.div``;

export const LabelTooltip = styled.p``;

export const ContainerTag = styled.div`
  width: 120px;
  display: flex;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 7px;
    color: ${theme.colorsV2.darkGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerContent = styled.div`
  display: flex;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 50%;
    font-size: ${theme.fontV2.sizes.text5};
    font-weight: 600;
    margin-bottom: 8px;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const CardInfo = styled.div`
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const FooterButtons = styled.footer`
  display: flex;
  gap: 1rem;
`;

export const ContainerButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    gap: 7px;
    border-top: 1px solid ${theme.colorsV2.forBorder};
    cursor: default;
  `}
`;

export const Div = styled.div``;
