import {
  IFleetType,
  IVehicleCategory,
  IVehicleNew,
  IVehicleTracker,
  IVehicleType,
  IVehicleActuators,
  IFuel,
  IVehicleSensors,
} from 'domain/vehicle';

export const PATH_TO_VEHICLE_LIST = '/veiculos';

export const optionsFuel: Array<{
  label: string;
  value: IFuel;
}> = [
  { label: 'DIESEL', value: 'Diesel' },
  { label: 'ELÉTRICO', value: 'Elétrico' },
  { label: 'ETANOL', value: 'Etanol' },
  { label: 'GNV', value: 'GNV' },
  { label: 'HIDROGÊNIO', value: 'Hidrogênio' },
  { label: 'HÍBRIDO', value: 'Híbrido' },
];

export const initialValues: IVehicleNew = {
  status: 'ativo',
  mainBoard: '',
  secondBoard: '',
  thirdBoard: '',
  capacity: '',
  fuel: optionsFuel[0].value,
  vehicleType: '',
  vehicleCategory: '',
  vehicleBrand: '',
  vehicleModel: '',
  year: '',
  vehicleTracker: '',
  sensors: [],
  actuators: [],
  fleetType: '',
  crlvPhoto: undefined,
  vehiclePhotos1: undefined,
  vehiclePhotos2: undefined,
  vehiclePhotos3: undefined,
  vehiclePhotos4: undefined,
  vehiclePhotos5: undefined,
};

export const optionsVehicleSensors: Array<{
  label: string;
  value: IVehicleSensors;
}> = [
  { label: 'SENSOR DE PORTA DA CABINA', value: 'Sensor de Porta da Cabina' },
  { label: 'SENSOR DE BAÚ', value: 'Sensor de Baú' },
  { label: 'SENSOR DE DESENGATE', value: 'Sensor de Desengate' },
  { label: 'SENSOR DE VELOCIDADE', value: 'Sensor de Velocidade' },
  { label: 'SENSOR DE GRADE DE JANELA', value: 'Sensor de Grade de Janela' },
  {
    label: 'SENSOR DE VIOLAÇÃO DE PAINEL',
    value: 'Sensor de Violação de Painel',
  },
  { label: 'SENSOR DE TEMPERATURA', value: 'Sensor de Temperatura' },
];

export const optionsVehicleActuators: Array<{
  label: string;
  value: IVehicleActuators;
}> = [
  { label: 'TECLADO INTERNO', value: 'Teclado Interno' },
  { label: 'BLOQUEADOR DE COMBUSTÍVEL', value: 'Bloqueador de Combustível' },
  { label: 'TRAVA DE 5º', value: 'Trava de 5º' },
  { label: 'TRAVA DE BAÚ', value: 'Trava de Baú' },
];

export const optionsVehicleType: Array<{ label: string; value: IVehicleType }> = [
  { label: 'UTILITÁRIO', value: 'utilitario' },
  { label: 'VAN', value: 'van' },
  { label: 'VUC', value: 'vuc' },
  { label: '3/4', value: '3/4' },
  { label: 'TOCO', value: 'toco' },
  { label: 'TRUCK', value: 'truck' },
  { label: 'BITRUCK', value: 'bitruck' },
  { label: 'CARRETA', value: 'carreta' },
  { label: 'CARRETA LS', value: 'carreta_ls' },
  { label: 'CARRETA 7 EIXOS', value: 'carreta_sete_eixos' },
  { label: 'BITREM', value: 'bitrem' },
  { label: 'RODOTREM', value: 'rodotrem' },
  { label: 'ROMEU&JULIETA', value: 'romeu&julieta' },
  { label: 'VANDERLEIA', value: 'vanderleia' },
];

export const optionsVehicleCategory: Array<{
  label: string;
  value: IVehicleCategory;
}> = [
  { label: 'BASCULANTE', value: 'cacamba_basculante' },
  { label: 'BAÚ SECO', value: 'bau_seco' },
  { label: 'BAÚ FRIGORIFICADO', value: 'bau_frigorificado' },
  { label: 'BAÚ REFRIGERADO', value: 'bau_refrigerado' },
  { label: 'CEGONHA', value: 'cegonha' },
  { label: 'FLORESTAL', value: 'florestal' },
  { label: 'GRADE BAIXA', value: 'grade_baixa' },
  { label: 'GRANELEIRO', value: 'graneleiro' },
  { label: 'PLATAFORMA', value: 'plataforma' },
  { label: 'PORTA CONTAINER 20 PÉS', value: 'porta_container' },
  { label: 'PORTA CONTAINER 40 PÉS', value: 'porta_container2' },
  { label: 'SIDER', value: 'bau_sider' },
  { label: 'TANQUE', value: 'tanque' },
];

export const optionsVehicleTracker: Array<{
  label: string;
  value: IVehicleTracker;
}> = [
  { label: 'AUTOTRAC', value: 'autotrac' },
  { label: 'ITURAN', value: 'ituran' },
  { label: 'MAX TRACKER', value: 'max tracker' },
  { label: 'OMNILINK', value: 'omnilink' },
  { label: 'ONIXSAT', value: 'onixsat' },
  { label: 'POSITRON', value: 'positron' },
  { label: 'RASTRO', value: 'rastro' },
  { label: 'SASCAR', value: 'sascar' },
  { label: 'SIGASAT', value: 'sigasat' },
  { label: 'SIGHRA', value: 'sighra' },
  { label: 'TEGMA', value: 'tegma' },
  { label: 'TRACKER', value: 'tracker' },
  { label: 'OUTROS', value: 'outros' },
  { label: 'NÃO POSSUI', value: 'não possui' },
];

export const optionsFleetType: Array<{
  label: string;
  value: IFleetType;
}> = [
  { label: 'FROTA PRÓPRIA', value: 'Frota Própria' },
  { label: 'FROTA TERCEIRIZADA', value: 'Frota Terceirizada' },
];

const currentYear = new Date().getFullYear();
const startYear = 1992;

export const optionsVehicleYear: Array<{
  label: string;
  value: string;
}> = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
  const year = currentYear - index;
  return { label: year.toString(), value: year.toString() };
});
