import { convertTypeDomainToEntityMapper } from '../convert-types-occurrence-domain-to-entity/convert-types-occurrence-domain-to-entity.mapper';
import { convertStatusDomainToEntityMapper } from '../convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { IOccurrenceEntity } from '../../services/entities/occurrence.entity';
import { IOccurrence } from '../../../../domain/occurrence';

export const convertOccurrenceDomainToEntityMapper = (occurrenceDomain: IOccurrence): IOccurrenceEntity => ({
  description: occurrenceDomain.description,
  status: convertStatusDomainToEntityMapper(occurrenceDomain.status),
  impact: convertTypeDomainToEntityMapper(occurrenceDomain.impact),
  responsibleSolution: occurrenceDomain.responsibleSolution,
  impactedList: occurrenceDomain.impactedList,
  sla: occurrenceDomain.sla,
  treatmentSector: occurrenceDomain.treatmentSector
});
