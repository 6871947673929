import React, { useCallback, useContext, useMemo } from 'react';
import { KanbanSectionView } from './matches-section-view.component';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { IMatchesKanban } from '../../matches-kanban.types';
import { IMatchesKanbanDetails, MatchType } from '../../../../domains/matches-kanban-list/matches-kanban-list.domain';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { useUpdateFavoriteMatch } from '../../../../hooks/use-update-favorite-match/use-update-favorite-match.hook';
import { convertMatchesKanbanToSaleOffer } from '../../../../mappers/convert-matches-kanban-to-sale-offer/convert-matches-kanban-to-sale-offer.mapper';
import { convertMatchesKanbanToPurchaseOffer } from '../../../../mappers/convert-matches-kanban-to-purchase-offer/convert-matches-kanban-to-purchase-offer.mapper';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { useOpenProposalChat } from 'src-new/hooks/chat/use-open-proposal-chat/use-open-proposal-chat.hook';
import { backhaulPurchaseInitialValues } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.constants';
import { modalBiddingOfferInitialValues } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer-initial-values.constants';

interface IKanbanSectionProps {
  title: string;
  color: ThemeColorsV2Type;
  kanbanListDetails: IMatchesKanban;
}

export const KanbanSection: React.FC<IKanbanSectionProps> = (props) => {
  const { modalMatchesKanban } = useContext(MatchesContext);
  const { purchaseOfferModals, purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const { saleOfferModals, saleOfferMatchList } = useContext(SaleOfferContext);
  const { setModalMatchesOpen } = modalMatchesKanban;

  const openProposalChat = useOpenProposalChat();
  const updateFavoriteMatchHook = useUpdateFavoriteMatch();

  const isEmptyList = useMemo(
    (): boolean => props.kanbanListDetails.items.length === 0,
    [props.kanbanListDetails.items.length],
  );

  const handleFavoriteMatch = useCallback(
    (matchId: number, isFavorite: boolean) => updateFavoriteMatchHook(matchId, isFavorite),
    [updateFavoriteMatchHook],
  );

  const handleOpenModalMatch = useCallback(
    (matchesKanbanDetails: IMatchesKanbanDetails) => {
      if (matchesKanbanDetails.type === MatchType.SALE) {
        const { purchaseOfferMatchProposal, filters } = purchaseOfferMatchList;
        const { setPurchaseOfferMatchProposalSuccess } = purchaseOfferMatchProposal;
        const { selectedFilter, setSelectFilters } = filters;

        setPurchaseOfferMatchProposalSuccess(matchesKanbanDetails.purchaseOffer);
        setSelectFilters({ ...selectedFilter, matchId: matchesKanbanDetails.matchOffersRoutesId });

        purchaseOfferModals.setModalOpen({ modalType: 'purchase_offer_match' });
      }
      if (matchesKanbanDetails.type === MatchType.PURCHASE) {
        const { saleOfferMatchProposal, filters } = saleOfferMatchList;
        const { setSaleOfferMatchProposalSuccess } = saleOfferMatchProposal;
        const { selectedFilter, setSelectFilters } = filters;

        setSaleOfferMatchProposalSuccess(matchesKanbanDetails.saleOffer);
        setSelectFilters({ ...selectedFilter, matchId: matchesKanbanDetails.matchOffersRoutesId });

        saleOfferModals.setModalOpen({
          indexes: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
          modalType: 'sale_offer_match',
        });
      }
    },
    [purchaseOfferMatchList, purchaseOfferModals, saleOfferMatchList, saleOfferModals],
  );

  const handleOpenModalMatchBidding = useCallback(
    (item: IMatchesKanbanDetails) => {
      let saleOfferMatch = modalBiddingOfferInitialValues;
      let purchaseOfferMatch = backhaulPurchaseInitialValues;

      if (item.type === MatchType.PURCHASE) {
        saleOfferMatch = convertMatchesKanbanToSaleOffer(item);
      } else {
        purchaseOfferMatch = convertMatchesKanbanToPurchaseOffer(item);
      }

      const modalType = item.type === MatchType.PURCHASE ? 'saleOffer' : 'purchaseOffer';

      setModalMatchesOpen({
        open: true,
        modalType,
        saleOfferMatch,
        purchaseOfferMatch,
      });
    },
    [setModalMatchesOpen],
  );

  const handleOpenProposalChat = useCallback(
    async (
      purchaseOfferId: string,
      saleOfferId: string,
      tariffOfferId: string,
      matchId: string,
      saleOfferCompanyId: string,
      buyOfferCompanyId: string,
      type: MatchType,
    ): Promise<void> => {
      await openProposalChat({
        purchaseOfferId: purchaseOfferId,
        saleTariffOfferId: tariffOfferId,
        saleOfferId: saleOfferId,
        matchId: matchId,
        saleOfferCompanyId: saleOfferCompanyId,
        buyOfferCompanyId: buyOfferCompanyId,
        pageType: type === MatchType.PURCHASE ? 'PURCHASE_OFFER' : 'SALE_OFFER',
      });
    },
    [openProposalChat],
  );

  return (
    <KanbanSectionView
      {...props}
      handleFavoriteMatch={handleFavoriteMatch}
      handleOpenModalMatch={handleOpenModalMatch}
      handleOpenModalMatchBidding={handleOpenModalMatchBidding}
      isEmptyList={isEmptyList}
      handleOpenProposalChat={handleOpenProposalChat}
    />
  );
};
