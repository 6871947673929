import API from 'utils/API/API';

interface IBranch {
  address: string;
  cep: string;
  city: string;
  cnpj: string;
  companyId: number;
  complement: string;
  corporateName: string;
  fantasyName: string;
  id: string;
  neightborhood: string;
  number: string;
  state: string;
}

export const BranchListService = async (companyId: number) => {
  const { data } = await API.get(`v2/branch/${companyId}`);

  return data.map((item: IBranch) => {
    return {
      label: `${item.fantasyName} - ${item.cnpj}`,
      value: item.id,
    };
  });
};
