import { IWeighingListFilterDomain } from './weighing-list-filters.domain';

export const weighingListFilterInitialValues: IWeighingListFilterDomain = {
  ticketId: undefined,
  search: '',
  page: 1,
  limit: 100,
  order: 'DESC',
  column: 'ID',
  operation: 'EXPEDICAO',
  originCity: '',
  originUf: '',
  destinyCity: '',
  destinyUf: '',
  driverCpf: '',
  driverName: '',
  vehiclePlate: '',
  vehicleCategory: '',
  shippingCompany: '',
  collectOrder: '',
  startEntryDate: '',
  endEntryDate: '',
  startExitDate: '',
  endExitDate: '',
  startScheduledDate: '',
  endScheduledDate: '',
  itemId: 0,
  status: []
};
