import { normalizeUSDate } from 'utils-v2/converters/date/normalize-date';
import API from 'utils/API/API';
import { ScheduleListParams } from 'services/schedule/types';

const convertParams = (params: ScheduleListParams) => {
  return {
    search: params.search,
    page: params.page,
    shippingCompanyId: params.shippingCompanyId || '',
    company: params.company || '',
    scheduleCode: params.scheduleCode || '',
    driverId: '',
    vehicleId: '',
    levyInitialDate: normalizeUSDate(params.levyInitialDateStart),
    levyEndDate: normalizeUSDate(params.levyInitialDateEnd),
    deliveryInitialDate: normalizeUSDate(params.deliveryInitialStart),
    deliveryEndDate: normalizeUSDate(params.deliveryInitialEnd),
    freightValue: params.freightValue || '',
    vehicleCategory: params.vehicleCategory || '',
    vehicleType: params.vehicleType || '',
    originCity: params.originCity || '',
    destinationCity: params.destinationCity || '',
    orderNumber: params.orderNumber || '',
    scheduleOperationType: params.scheduleOperationType || '',
    operationBy: params.operationBy,
    typeOperation: params.typeOperation,
    status: params.status,
    statusCode: params.statusCode,
    order: params.order,
    column: params.column,
  };
};

export const allocationReportExcelService = async (params: ScheduleListParams): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('v2/schedules/export', {
      params: {
        ...convertParams(params),
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar localidade');
  }
};
