import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { ScheduleCardResume } from '../components/card-resume';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import {
  cancelBiddingService,
  createAllocationBiddingService,
  getBiddingByScheduleId,
  refuseBiddingService,
} from 'services/bidding';
import { convertResumeUpdate } from 'pages-v2/schedule/mappers/convert-resume/update';
import { ScheduleInput } from 'domain-v2/schedule';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';
import { IScheduleBidding, IScheduleBiddingConfirm } from 'domain-v2/bidding';
import { IUser } from 'domain/login';
import { FooterPage } from 'components-v2/common/footer-page';
import { showToast } from 'components/toast/toast';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import Button from 'components/button/button';
import { CrossMargin } from 'components/bidding/cross-margin';
import { useQueryClient } from 'react-query';

interface BiddingProps {
  schedule: ScheduleInput;
  user: IUser | undefined;
}

const biddingConfirmInitialState: IScheduleBiddingConfirm = {} as IScheduleBiddingConfirm;

export const BiddingRegister: React.FC<BiddingProps> = ({ schedule, user }) => {
  const { isHovered, currentSelectedMenu } = useContext(SidebarContext);
  const reactQueryClient = useQueryClient();

  const navigate = useNavigate();

  const [modalConfirm, setModalConfirm] = useState<ModalState<IScheduleBiddingConfirm>>({
    ...initialModalState,
    data: biddingConfirmInitialState,
  });
  const [modalCancel, setModalCancel] = useState<ModalState>(initialModalState);
  const [modalRefuse, setModalRefuse] = useState<ModalState>(initialModalState);
  const [sidebarSize, setSidebarSize] = useState<number>(0);
  const [biddingToRefuse, setBiddingToRefuse] = useState<number>(0);
  const [biddings, setBiddings] = useState<IScheduleBidding[]>();

  useEffect(() => {
    const menuSize = isHovered ? 282 : 0;

    const submenuSize = currentSelectedMenu ? 185 : 0;

    setSidebarSize(menuSize + submenuSize);
  }, [isHovered, currentSelectedMenu]);

  const goToRegisterAllocation = useCallback(
    (biddingId: number, scheduleId: number, companyName: string, value: number) => {
      const description = (
        <S.DescriptionWrapper>
          <S.LabelWrapper>
            <S.Strong>Comprador: </S.Strong>
            <S.ModalLabel style={{ fontWeight: 500 }}>{companyName}</S.ModalLabel>
          </S.LabelWrapper>

          <S.LabelWrapper>
            <S.Strong>Valor do Bid: </S.Strong>
            <S.ModalLabel style={{ fontWeight: 500 }}>{convertCurrencyFormat.format(value)}</S.ModalLabel>
          </S.LabelWrapper>
        </S.DescriptionWrapper>
      );

      setModalConfirm(() => ({
        data: { biddingId, scheduleId },
        open: true,
        text: description,
      }));
    },
    [],
  );

  const handleAllocation = useCallback(async (id: number, scheduleId: number) => {
    try {
      await createAllocationBiddingService(id, scheduleId);
      showToast({
        message: 'Alocação realizada com sucesso',
        type: 'success',
      });
      setModalConfirm((oldModal) => ({ ...oldModal, open: false }));
      reactQueryClient.invalidateQueries(['schedule-list-table']);

      navigate('/fretes');
    } catch (err) {
      showToast({
        message: 'Erro ao realizar alocação',
        type: 'error',
      });
    }
  }, []);

  const goToDelete = useCallback(() => {
    const text = (
      <>
        <p>Isso cancelará os biddings.</p>
        <p style={{ marginTop: 5 }}>Tem certeza?</p>
      </>
    );
    setModalCancel({ open: true, text });
  }, []);

  const goToRefuseBidding = useCallback((biddingId: number) => {
    const text = (
      <>
        <p>Isso recusará os biddings.</p>
        <p style={{ marginTop: 5 }}>Tem certeza?</p>
      </>
    );
    setModalRefuse({ open: true, text });
    setBiddingToRefuse(biddingId);
  }, []);

  const handleCancelBidding = useCallback(async (scheduleId: number) => {
    try {
      await cancelBiddingService(scheduleId);
      showToast({
        message: 'Bidding cancelado com sucesso',
        type: 'success',
      });
      setModalCancel((oldModal) => ({ ...oldModal, open: false }));
      reactQueryClient.invalidateQueries(['schedule-list-table']);

      navigate('/fretes');
    } catch (err) {
      showToast({
        message: 'Erro ao cancelar bid',
        type: 'error',
      });
    }
  }, []);

  const handleRefuseBidding = useCallback(async (biddingId: number) => {
    try {
      await refuseBiddingService(biddingId);
      showToast({
        message: 'Bidding recusado com sucesso',
        type: 'success',
      });
      setModalCancel((oldModal) => ({ ...oldModal, open: false }));
      reactQueryClient.invalidateQueries(['schedule-list-table']);

      navigate('/fretes');
    } catch (err) {
      showToast({
        message: 'Erro ao recusar bid',
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const bidding = await getBiddingByScheduleId(schedule.id);

        setBiddings(bidding);
      } catch (err) {
        showToast({
          message: 'Houve um erro ao buscar detalhes do bidding',
          type: 'error',
        });
      }
    })();
  }, [schedule.id]);

  const renderSubTitle = useMemo(() => {
    if (biddings?.length === 0) return <S.Info>Esse frete ainda não recebeu nenhum bid, volte mais tarde.</S.Info>;

    return <S.Info>Selecione qual transportadora ira operar esse frete.</S.Info>;
  }, [biddings]);

  const renderListBiddings = useMemo(() => {
    const listMapping = biddings?.map((item, index) => {
      return (
        <S.LineSection key={index} columns="2.5fr 0.2fr 1.5fr 1fr 1fr 1fr 0.1fr 1fr">
          <S.Info>{item.company.name}</S.Info>
          <div></div>
          <S.Info>{convertCurrencyFormat.format(item.value)}</S.Info>
          <S.Info>{moment(item.created_at).format('DD/MM/yyyy HH:mm')}</S.Info>
          <CrossMargin value={!!item.margin.toPrecision(2) && +item.margin.toPrecision(2)} />
          <Button
            title={'Alocar'}
            size={'medium'}
            bgColor="aqua"
            callback={() => goToRegisterAllocation(item.id, item.scheduleId, item.company.name, item.value)}
          />
          <div></div>
          <Button title={'Recusar'} size={'medium'} bgColor="orange" callback={() => goToRefuseBidding(item.id)} />
        </S.LineSection>
      );
    });

    return (
      <>
        <S.LineSection columns="2.5fr 0.2fr 1.5fr 1fr 1fr 1fr 0.1fr 1fr">
          <S.HeaderTable>Embarcador/Transportadora</S.HeaderTable>
          <div></div>
          <S.HeaderTable>Valor Ofertado</S.HeaderTable>
          <S.HeaderTable>Data / Hora</S.HeaderTable>
        </S.LineSection>
        {listMapping}
      </>
    );
  }, [biddings]);

  const convertedData = convertResumeUpdate(schedule);

  return (
    <>
      {modalConfirm.open && (
        <ModalConfirmation
          title="Tem certeza que deseja fazer essa alocação?"
          description={modalConfirm.text}
          size={'very-small'}
          type="normal"
          handleXCancel={() => setModalConfirm((oldModal) => ({ ...oldModal, open: false }))}
          onConfirmation={() => handleAllocation(modalConfirm.data.biddingId, modalConfirm.data.scheduleId)}
        />
      )}
      {modalCancel.open && (
        <ModalConfirmation
          title="Cancelar Bid(s)"
          secondTitle={modalCancel.text}
          handleCancel={() => setModalCancel((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleCancelBidding(schedule.id)}
          size="small"
          type="delete"
          description={undefined}
          deleteTitle="Cancelar"
        />
      )}
      {modalRefuse.open && (
        <ModalConfirmation
          title="Recusar Bid(s)"
          secondTitle={modalCancel.text}
          handleCancel={() => setModalRefuse((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRefuseBidding(biddingToRefuse)}
          size="small"
          type="delete"
          description={undefined}
          deleteTitle="Recusar"
        />
      )}
      <S.Wrapper>
        <S.TableWrapper>
          <S.HeaderWrapper>
            <S.HeaderTitle>Bids</S.HeaderTitle>
            {renderSubTitle}
          </S.HeaderWrapper>
          {renderListBiddings}
        </S.TableWrapper>

        <ScheduleCardResume.Root>
          <ScheduleCardResume.Header
            id={schedule.id}
            co2={schedule.co2}
            statusCode={schedule.statusCode}
            scheduleType={schedule.type}
            operationType={schedule.operation}
            qualityAndSafety={schedule.origin?.qualityAndSafety}
            requirementTags={schedule.cargo.requirements}
            user={user}
          />
          <ScheduleCardResume.Collection
            origin={convertedData.originValues}
            scheduleDetailsValues={convertedData.scheduleDetailsValuesOrigin}
          />
          <ScheduleCardResume.Delivery
            destinyValues={convertedData.destinationValues}
            scheduleDetailsValues={convertedData.scheduleDetailsValuesDestination}
          />
          <ScheduleCardResume.Operation
            cargoDetailsValues={convertedData.scheduleCargoValues}
            driverValue={schedule?.driver?.name}
            vehicleValues={convertedData?.scheduleVehicleValues}
            shippingValues={convertedData?.scheduleShippingValues}
            operationBy={
              schedule?.operation === 'SPOT' || schedule.operation === 'BACKHAUL EXTERNO SPOT'
                ? 'Logshare'
                : schedule.company.tradeName
            }
          />
          <ScheduleCardResume.Fines retentionFinesValues={schedule.retentionFines} />
          <ScheduleCardResume.Values user={user} valuesPayable={convertedData.scheduleCostValues} />
        </ScheduleCardResume.Root>
      </S.Wrapper>

      <S.WrapperFooterPage sidebarIsHovered={sidebarSize}>
        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Cancelar" color="red" onPress={goToDelete} />
          </FooterPage.RightContent>
        </FooterPage.Root>
      </S.WrapperFooterPage>
    </>
  );
};
