import React from 'react';
import { ErrorStateView } from 'src-new/components/error-state/error-state-view.component';

interface IErrorStateProps {
  title?: string;
  subTitle?: string;
}

export const ErrorState: React.FC<IErrorStateProps> = ({ title, subTitle }) => {
  return <ErrorStateView title={title} subTitle={subTitle} />;
};
