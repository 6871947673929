import { freeTimeInitialValuesDomain } from 'src-new/domains/fretime.domain';
import { SaleOfferFreetimeActionType } from '../../sale-offer.action';
import { ISaleOfferFreetimeActions } from '../../types/sale-offer-freetime.types';
import { ISaleOfferContext } from '../../types/sale-offer.types';

export const saleOfferFreetimeReducer = (
  state: ISaleOfferContext,
  action: ISaleOfferFreetimeActions,
): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_REQUEST:
      return {
        ...state,
        saleOfferFreetime: {
          ...state.saleOfferFreetime,
          statusRequest: 'PROCESSING',
          saleOfferFreetime: freeTimeInitialValuesDomain,
        },
      };
    case SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_SUCCESS:
      return {
        ...state,
        saleOfferFreetime: {
          ...state.saleOfferFreetime,
          statusRequest: 'SUCCESS',
          saleOfferFreetime: action.payload,
        },
      };
    case SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_ERROR:
      return {
        ...state,
        saleOfferFreetime: {
          ...state.saleOfferFreetime,
          statusRequest: 'ERROR',
          saleOfferFreetime: freeTimeInitialValuesDomain,
        },
      };
    case SaleOfferFreetimeActionType.SALE_OFFER_FREETIME_RESET:
      return {
        ...state,
        saleOfferFreetime: {
          ...state.saleOfferFreetime,
          statusRequest: 'INITIAL',
          saleOfferFreetime: freeTimeInitialValuesDomain,
        },
      };
    default:
      return state;
  }
};
