import React, { ReactElement, useMemo } from 'react';
import * as S from './weighing-totten-details-page-body.styled';
import { WeighingTottenDetailsForm } from '../weighing-totten-details-form/weighing-totten-details-form.component';
import { WeighingTottenButton } from 'src-new/mobile/pages/weighing-totten/components/weighing-totten-button/weighing-totten-button.component';
import Image from 'src/assets/footer-totten.png'

interface IWeighingTottenPageInitialViewParams {
  formPage: boolean;
  handleInitialButtonClick: () => void;
}

export const WeighingTottenDetailsPageBodyView: React.FC<IWeighingTottenPageInitialViewParams> = ({
  formPage,
  handleInitialButtonClick,
}) => {
  const renderInitialPage = useMemo((): ReactElement => {
    if (formPage) {
      return <WeighingTottenDetailsForm />;
    }

    return (
      <S.Wrapper onClick={handleInitialButtonClick}>
        <S.WrapperContent>
          <S.Title>
            Olá, bem-vindo à <strong>Mondelez</strong>!
          </S.Title>
          <WeighingTottenButton callback={() => undefined}>
            <p>
              Toque na <strong>Tela</strong> para <strong>Iniciar</strong>
            </p>
          </WeighingTottenButton>
          <S.Footer>
            <img src={Image} alt="" />
          </S.Footer>
        </S.WrapperContent>
      </S.Wrapper>
    );
  }, [formPage, handleInitialButtonClick]);

  return renderInitialPage;
};
