import {
  IConversationResponse,
  IMessageErrorResponse,
  IMessageResponse,
} from 'src-new/services/chat/get-conversation-messages-by-id/get-conversation-messages-by-id.types';
import axios, { AxiosError } from 'axios';
import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';

export const getConversationMessagesByIdService = async (
  conversationId: string,
  hasLimit?: boolean,
): Promise<Array<IMessageResponse | IMessageErrorResponse>> => {
  const chatEnvironment = getChatEnvironment();
  const params: string = hasLimit ? '?limit=50' : '';

  try {
    const { data } = await axios.get<IConversationResponse>(
      `https://api.talkjs.com/v1/${chatEnvironment.appId}/conversations/${conversationId}/messages${params}`,
      {
        headers: {
          Authorization: `Bearer ${chatEnvironment.secretKey}`,
        },
      },
    );

    return data.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      const idError: IMessageErrorResponse = { conversationId: conversationId };

      return [idError];
    } else {
      throw error;
    }
  }
};
