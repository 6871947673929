import React from 'react';

export const TimerClockIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="18px"
      height="18px"
      viewBox="0 0 638.000000 540.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,540.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
        <path d="M3699 5085 c-48 -26 -59 -55 -59 -162 0 -176 -9 -173 462 -173 466 0 458 -3 458 180 0 105 -9 129 -60 155 -42 22 -761 22 -801 0z" />
        <path d="M3930 4557 c0 -118 2 -137 20 -159 30 -39 70 -51 155 -46 69 3 78 6 108 36 l32 32 0 133 0 132 -157 3 -158 3 0 -134z" />
        <path d="M3620 4405 c-173 -42 -249 -69 -402 -143 -213 -101 -367 -211 -549 -392 -258 -255 -415 -528 -524 -914 -52 -184 -70 -523 -41 -756 73 -573 398 -1087 890 -1407 398 -259 850 -363 1341 -308 365 41 724 196 1033 447 54 44 231 225 289 295 65 80 164 239 222 356 76 155 135 332 173 522 27 134 31 171 32 345 1 202 -4 251 -50 460 -79 357 -263 690 -529 955 -287 288 -612 467 -993 550 -155 34 -157 34 -180 -17 -49 -112 -203 -166 -348 -123 -54 16 -135 88 -153 138 -14 36 -38 35 -211 -8z m773 -380 c342 -74 595 -210 827 -444 369 -372 529 -907 426 -1421 -46 -227 -118 -402 -247 -594 -89 -133 -245 -294 -379 -390 -101 -72 -338 -195 -423 -220 -245 -71 -310 -79 -558 -73 -187 4 -214 7 -324 35 -309 80 -518 196 -739 411 -243 237 -368 464 -456 826 -17 73 -25 401 -11 524 16 148 55 284 129 446 45 100 56 118 141 250 62 96 304 336 412 407 288 191 609 284 947 273 99 -4 177 -13 255 -30z" />
        <path d="M4067 3734 c-4 -4 -7 -252 -7 -551 l0 -544 -33 -14 c-48 -20 -114 -96 -123 -140 -17 -91 16 -168 96 -222 39 -27 144 -32 188 -9 34 18 75 62 96 103 18 37 21 124 5 156 -19 37 -82 98 -116 112 l-33 14 -2 548 -3 548 -30 3 c-17 2 -34 0 -38 -4z" />
        <path d="M4447 2922 c-92 -92 -167 -170 -167 -174 0 -4 18 -17 40 -28 23 -12 47 -34 56 -53 34 -66 26 -70 206 110 92 92 168 172 168 177 0 8 -124 136 -132 136 -2 0 -79 -75 -171 -168z" />
        <path d="M1243 4101 c-41 -26 -63 -60 -70 -109 -6 -45 11 -83 57 -127 l31 -30 472 -3 c260 -2 487 0 505 3 45 8 99 59 113 106 17 54 -3 110 -50 147 l-36 27 -495 3 c-477 2 -496 2 -527 -17z" />
        <path d="M720 3399 c-14 -6 -39 -24 -57 -41 -59 -57 -53 -149 13 -208 l35 -30 580 0 580 0 44 45 c42 41 45 48 45 96 0 40 -6 60 -26 86 -49 64 -39 63 -643 62 -336 0 -556 -4 -571 -10z" />
        <path d="M372 2650 c-96 -59 -95 -188 3 -245 l42 -25 654 0 c635 0 655 1 687 20 95 58 95 192 0 250 -32 19 -52 20 -693 20 -641 0 -661 -1 -693 -20z" />
        <path d="M680 1904 c-73 -62 -80 -149 -16 -213 l36 -36 586 -3 586 -2 44 43 c41 41 44 48 44 98 0 49 -3 57 -42 96 l-42 43 -582 0 -582 0 -32 -26z" />
        <path d="M1227 1190 c-85 -74 -71 -187 30 -239 15 -8 168 -11 508 -11 532 0 521 -1 565 60 45 63 29 148 -36 199 -26 21 -34 21 -530 21 l-503 0 -34 -30z" />
      </g>
    </svg>
  );
};
