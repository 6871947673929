import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getNFeDataService } from '../../services/get-nfe-data/get-nfe-data.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetNFeData = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);

  return useCallback(async () => {
    scheduleDocuments.nfeDataProps.setNFeDataRequest();

    try {
      const data = await getNFeDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.nfeDataProps.setNFeDataSuccess(data);
    } catch (error: any) {
      scheduleDocuments.nfeDataProps.setNFeDataError();

      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.nfeDataProps]);
};
