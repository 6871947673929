import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import CreateIcon from '@mui/icons-material/Create';

import * as S from './styled';
import { FreightCargoDetailsForm } from './modal-form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import {
  convertCurrencyFormat,
  convertWeightFormat,
} from 'utils/convert-to-currency-format/convert-to-currency-format';
import { transactionUpdateHook } from 'pages-v2/schedule/hooks/update-transaction';
import { useUpdateCargoDetailsHook } from 'pages/freight/hooks/schedule-cargo-hooks/use-schedule-cargo-update/use-cargo-details-update.hook';
import { useCargoDetailsHook } from 'pages/freight/hooks/schedule-cargo-hooks/use-schedule-cargo-id/use-cargo-details-id.hook';
import { ScheduleCargo, ScheduleDomain } from 'domain/schedule';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { showToast } from 'components/toast/toast';
import { ModalForm } from 'components/modal-form/modal-form';
import Button from 'components/button/button';

interface IProps {
  freightValues: ScheduleDomain;
  onRefresh: Dispatch<SetStateAction<number>>;
}

const CargoDetails: React.FC<IProps> = ({ freightValues, onRefresh }) => {
  const [scheduleCargo, setScheduleCargo] = useState<ScheduleCargo>({} as ScheduleCargo);

  const [modalForm, setModalForm] = useState<boolean>(false);

  const cargoDetailsHook = useCargoDetailsHook();

  const UseTransactionUpdate = transactionUpdateHook();

  const cargoDetailsUpdateHook = useUpdateCargoDetailsHook();

  useEffect(() => {
    (async () => {
      try {
        if (freightValues?.scheduleCargo?.id) {
          const findValues = await cargoDetailsHook(freightValues?.scheduleCargo?.id);
          setScheduleCargo(findValues);
        }
      } catch (err) {
        showToast({
          message: 'Houve um erro ao buscar os detalhes',
          type: 'error',
        });
      }
    })();
  }, [freightValues, scheduleCargo.id]);

  const handleUpdateCargoDetails = useCallback(
    async (scheduleCargo: ScheduleCargo) => {
      try {
        await cargoDetailsUpdateHook(freightValues?.scheduleCargo?.id || 0, scheduleCargo);

        const merchValue = convertCurrencyFormat.format(Number(scheduleCargo.shippingValue?.replace(',', '.') ?? 0));

        if (merchValue !== freightValueCargo && freightValues.companyBuyer) {
          const transaction = freightValues.calcFinanceData.filter(
            (calcFinance: any) => calcFinance.status === 'Pagar',
          );

          const gris = (Number(scheduleCargo.shippingValue) * Number(freightValues.companyBuyer.gris ?? 0)) / 100;

          const advalorem =
            (Number(scheduleCargo.shippingValue) * Number(freightValues.companyBuyer.advalorem ?? 0)) / 100;

          const totalGrisAdvalorem = gris + advalorem;

          await UseTransactionUpdate(transaction[0].id, {
            ...transaction[0],
            totalGrisAdvalorem: isNaN(totalGrisAdvalorem) ? 0 : totalGrisAdvalorem,
          });
        }

        onRefresh((prev) => prev + 1);

        setModalForm(false);

        showMessageFormatted({
          message: 'Carga atualizada com sucesso.',
          type: 'success',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: '',
          error: error,
          type: 'error',
        });
      }
    },
    [cargoDetailsUpdateHook, freightValues],
  );

  const weightValueCargo = convertWeightFormat.format(scheduleCargo.weight || 0);
  const volValueCargo = convertWeightFormat.format(scheduleCargo?.capacity || 0);

  const freightValueCargo = convertCurrencyFormat.format(Number(scheduleCargo?.shippingValue?.replace(',', '.')) || 0);

  const getHelperValue = useMemo(() => {
    const findHelper = freightValues.requirements
      ?.find((requirement: any) => requirement?.type === 'quality')
      ?.values.includes('Ajudante');

    if (findHelper) return 'SIM';

    return 'NÃO';
  }, [freightValues.requirements]);

  return (
    <>
      {modalForm && (
        <Formik initialValues={scheduleCargo} onSubmit={handleUpdateCargoDetails}>
          {(formikHelpers) => (
            <ModalForm
              title="Detalhes da Carga"
              description={<FreightCargoDetailsForm />}
              onConfirmation={() => formikHelpers.handleSubmit()}
              handleCancel={() => {
                formikHelpers.resetForm();
                setModalForm(false);
              }}
              size="medium"
            />
          )}
        </Formik>
      )}
      <S.Wrapper>
        <S.HeaderTitle display="flex" justify="space-between" isTraced>
          Detalhes da Carga
          <Button
            title="Editar"
            size="very-small"
            icon={<CreateIcon />}
            callback={() => setModalForm(true)}
            bgColor="white"
          />
        </S.HeaderTitle>

        <S.SectionContainer display="grid" columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <S.InfoTitle>
            Veículo Programado
            <S.Info>{convertVehicleTypeTypeToName(scheduleCargo.vehicleType || '-')}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Categoria
            <S.Info>{convertVehicleCategoryTypeToName(scheduleCargo.vehicleCategoryType || '-')}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Ajudante
            <S.Info>{getHelperValue}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Escolta
            <S.Info>{scheduleCargo.needBodyGuard === true ? 'Sim' : 'Não'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Paletizado
            <S.Info>{scheduleCargo.inPalet === true ? 'Sim' : 'Não'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Paletes
            <S.Info>{scheduleCargo.pallet || '0'}</S.Info>
          </S.InfoTitle>
        </S.SectionContainer>

        <S.SectionContainer display="grid" columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <S.InfoTitle>
            Peso
            <S.Info>{weightValueCargo + ' KG'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Volume
            <S.Info>{volValueCargo + ' Vol'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Cubagem
            <S.Info>{freightValues.cubagem} m³</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Valor da Carga
            <S.Info>{freightValueCargo || '-'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Produto
            <S.Info>{scheduleCargo.invoice || '-'}</S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Pedido(s)
            <S.Info>{scheduleCargo.orderNumber || '-'}</S.Info>
          </S.InfoTitle>
        </S.SectionContainer>
      </S.Wrapper>
    </>
  );
};

export default React.memo(CargoDetails);
