import styled, { css } from 'styled-components';

export const WrappedFullMap = styled.div`
  height: 80vh;
  position: relative;
`;

export const WrappedMenu = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 99;
  flex: 1;
  flex-direction: column;
  height: 185px;
  width: 270px;
  background-color: white;
  padding-left: 18px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 5px 5px 15px #7b7b7b;
`;

export const MenuRowStyled = styled.div`
  flex-direction: row;
  padding-bottom: 15px;
`;

export const TextStyled = styled.span`
  margin-left: 10px;
  font-size: 13px;
  position: relative;
  bottom: 3px;
`;
type ImageStyledProps = { src: string };
export const ImageStyled = styled.img<ImageStyledProps>`
  ${({ src }) => css`
    src: ${src};
    width: 29px;
    height: 23px;
  `}
`;

export const CheckboxStyled = styled.div`
  margin-left: 200px;
  margin-top: -20px;
`;

export const WrapperResume = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 90;
  flex: 1;
  flex-direction: column;
  height: fit-content;
  width: 270px;
  background-color: white;
  padding-left: 18px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 5px 5px 15px #7b7b7b;
  gap: 5px;
`;

export const CloseModalStyled = styled.span`
  cursor: pointer;
  font-size: 12px;
  margin-left: 230px;
  top: -22px;
  position: relative;
`;

export const ContainerModalStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: -20px;
`;

type TextModalStyledProps = { fontWeight?: string };
export const TextModalStyled = styled.span<TextModalStyledProps>`
  ${({ fontWeight }) => css`
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: ${fontWeight};
  `}
`;
