import { changePasswordRegisterService } from 'pages/change-password/services/change-password-register/change-password-register.service';
import { IChangePassword } from 'domain/change-password';

const useChangePasswordRegisterHook = () => {
  return async (userId: number, changePassword: IChangePassword) => {
    await changePasswordRegisterService(userId, changePassword);
  };
};

export { useChangePasswordRegisterHook };
