import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getVehicleListService } from '../../services/get-vehicle-list/get-vehicle-list.service';
import { IScheduleDetailsAllocationVehicleListProps } from '../../services/get-vehicle-list/get-vehicle-list.types';
import { IScheduleDetailsAllocationVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-vehicle.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const useVehicleList = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const { setInitialLoadingSuccess } = initialLoading;

  return useCallback(
    async (params: IScheduleDetailsAllocationVehicleListProps, setLimitPage: Dispatch<SetStateAction<boolean>>) => {
      scheduleDetails.allocationVehicle.modalProps.setScheduleDetailsAllocationVehicleRequest();

      try {
        const scheduleListResponse = await getVehicleListService({
          search: params.search,
          page: params.page,
          limitRegistry: params.limitRegistry,
          status: params.status,
          order: params.order,
          column: params.column,
          shippingCompanyId: params.shippingCompanyId,
          ...(scheduleDetails.allocationShipping.shippingCompanyId && {
            shippingCompanyId: scheduleDetails.allocationShipping.shippingCompanyId,
          }),
          driverId: scheduleDetails.allocationActions.driverId,
        });
        const oldRecords: Array<IScheduleDetailsAllocationVehicleDomain> =
          params.page === 1 ? [] : scheduleDetails.allocationVehicle.modalProps.vehicleList;

        setLimitPage(
          scheduleListResponse.meta.totalPages === params.page || scheduleListResponse.meta.totalPages === 0,
        );
        scheduleDetails.allocationVehicle.modalProps.setScheduleDetailsAllocationVehicleSuccess([
          ...oldRecords,
          ...scheduleListResponse.items,
        ]);
      } catch (error) {
        scheduleDetails.allocationVehicle.modalProps.setScheduleDetailsAllocationVehicleError();
      }
      setInitialLoadingSuccess();
      scheduleDetails.allocationActions.setDriverIdVehicleModal(undefined);
    },
    [
      scheduleDetails.allocationActions,
      scheduleDetails.allocationShipping.shippingCompanyId,
      scheduleDetails.allocationVehicle.modalProps,
      setInitialLoadingSuccess,
    ],
  );
};
