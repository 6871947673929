import { useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import CachedIcon from '@mui/icons-material/Cached';

import { AllocationIcon, HeaderTitle, Info, InfoTitle, SectionContainer, Wrapper } from '../styled';
import { LoadingContainer, LoadingStyled } from '../../freight-update-form.styled';
import { IScheduleHistory } from '../../freight-update-form';

import { listDriverContent } from './content-table/list/list';
import { headerDriverContent } from './content-table/header';

import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { formatCPF } from 'utils/format-numbers';
import { UserContext } from 'state/user-context';
import AllocationModalList from 'pages-v2/schedule/components/allocation-modal-list';
import { getFreightByIdService } from 'pages/freight/services/get-schedule-by-id/get-schedule-by-id.service';
import { useUpdateDriverHook } from 'pages/freight/hooks/use-update-driver';
import { useScheduleHistory } from 'pages/freight/hooks/use-schedule-history/use-schedule-history.hook';
import { useDriverDeleteHook } from 'pages/freight/hooks/use-driver-delete';
import { driverListService } from 'pages/allocation/services/driver-list/driver-list.service';
import { ScheduleDomain } from 'domain/schedule';
import { IListPage } from 'domain/page-info';
import { IDriverCreated } from 'domain/driver';
import { IContentTable } from 'components/table/table.types';
import Button from 'components/button/button';
import TruckLoadingIcon from 'assets/loading-v4.gif';
import { EditDisabledIcon } from 'assets/icons/edit-disabled.icon';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface IProps {
  values?: ScheduleDomain;
  scheduleHistory?: IScheduleHistory[];
  onRefresh: React.Dispatch<React.SetStateAction<number>>;
}

interface IDriverProps {
  page: number;
  search: string;
  limitPage: number;
  order: string;
  column: string;
}

const findAllInitialState = {} as IListPage<IDriverCreated>;

const findInitialParams: IDriverProps = {
  search: '',
  page: 1,
  limitPage: 7,
  order: 'DESC',
  column: 'driver.id',
} as IDriverProps;

const FreightDriverSectionView: React.FC<IProps> = ({ values, scheduleHistory, onRefresh }) => {
  const { isAdmin } = useContext(UserContext);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [findAllDrivers, setFindAllDrivers] = useState<IListPage<IDriverCreated>>(findAllInitialState);
  const [params, setParams] = useState<IDriverProps>(findInitialParams);
  const [driverScheduleHistory, setDriverScheduleHistory] = useState<IScheduleHistory[]>(scheduleHistory ?? []);

  const driverUpdate = useUpdateDriverHook();
  const driverDelete = useDriverDeleteHook();
  const getScheduleHistory = useScheduleHistory();

  useEffect(() => {
    if (!scheduleHistory) return;

    setDriverScheduleHistory(scheduleHistory);
  }, [scheduleHistory]);

  useEffect(() => {
    (async () => {
      try {
        const findAll = await driverListService({
          search: params.search,
          page: params.page,
          limit: params.limitPage,
          order: params.order,
          column: params.column,
          shippingCompanyId: values?.shippingCompany?.id,
        });

        setFindAllDrivers(findAll);
      } catch (err: any) {
        showMessageFormatted({
          message: '',
          error: err,
          type: 'error',
        });
      }
    })();
  }, [params, values?.shippingCompany?.id]);

  const handleGetScheduleHistory = useCallback(async () => {
    try {
      const res = await getScheduleHistory(Number(id), 'MOTORISTA');
      setDriverScheduleHistory(res);
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
  }, [id, getScheduleHistory, setDriverScheduleHistory]);

  const handleUpdate = useCallback(
    async (driverId: number) => {
      setModalEdit(false);
      setIsLoading(true);
      const find = await getFreightByIdService(id ?? '0');
      driverUpdate(id ?? '0', {
        driverId,
        vehicleId: find.vehicle?.id,
        typeUpdate: 'MOTORISTA',
      })
        .then(async () => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          onRefresh((prev) => prev + 1);
          await handleGetScheduleHistory();
        })
        .catch((err) => {
          showMessageFormatted({
            message: err.message ? err.message : err,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [id, driverUpdate, handleGetScheduleHistory, onRefresh],
  );

  const handleDelete = useCallback(
    async (id: number) => {
      setIsLoading(true);
      driverDelete(id)
        .then(async () => {
          showMessageFormatted({
            message: 'Motorista Deletado',
            type: 'success',
          });
          onRefresh((prev) => prev + 1);
          await handleGetScheduleHistory();
        })
        .catch((err: any) => {
          showMessageFormatted({
            message: err.message ? err.message : err,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [driverDelete, handleGetScheduleHistory, onRefresh],
  );

  const oferedByShipper = values?.currentFreight === 1 && !isAdmin;

  const renderDriver = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingStyled>
          <LoadingContainer>
            <img alt="" style={{ width: 70, height: 70 }} src={TruckLoadingIcon} />
          </LoadingContainer>
        </LoadingStyled>
      );
    }
    if (driverScheduleHistory && driverScheduleHistory.length > 0) {
      return driverScheduleHistory.map((driver, index) => {
        return (
          <SectionContainer
            key={driver.name_driver}
            display="grid"
            columns={isAdmin ? '2fr 1fr 1fr 1fr 0.8fr 0.6fr' : '2fr 1fr 0.8fr'}
          >
            <InfoTitle>
              Nome
              <Info>{driver.name_driver ?? '-'}</Info>
            </InfoTitle>

            <InfoTitle>
              Telefone
              <Info>
                {oferedByShipper ? '(**) *****-****' : formatCelphoneNumberEntityToDomain(driver.fisrt_phone) ?? '-'}
              </Info>
            </InfoTitle>

            {isAdmin && (
              <>
                <InfoTitle>
                  CPF
                  <Info>{oferedByShipper ? '***.***.***-**' : formatCPF(String(driver.cpf) ?? '-')}</Info>
                </InfoTitle>

                <InfoTitle>
                  CNH
                  <Info>{oferedByShipper ? '***********' : driver.cnh ?? '-'}</Info>
                </InfoTitle>
              </>
            )}

            <InfoTitle>
              Data de Alocação
              <Info>{moment(driver.created_at).format('DD/MM/YYYY HH:mm') ?? '-'}</Info>
            </InfoTitle>

            {isAdmin && (
              <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end' }}>
                {driver.driver_id === values?.driver?.id && index === 0 ? (
                  <InfoTitle>
                    <Info>
                      <Tag label="Ativo" color="aqua" variant="ghost" display="flex" size="md" />
                    </Info>
                  </InfoTitle>
                ) : (
                  <>
                    <Button
                      title="Trocar"
                      callback={() => handleUpdate(driver.driver_id)}
                      bgColor="aqua"
                      icon={<CachedIcon />}
                      size="very-small"
                    />
                    <Button
                      title="Deletar"
                      callback={() => handleDelete(driver.id)}
                      bgColor="pink"
                      icon={<DeleteIcon />}
                      size="very-small"
                    />
                  </>
                )}
              </div>
            )}
          </SectionContainer>
        );
      });
    }

    return (
      <SectionContainer display="grid" columns={isAdmin ? '2fr 1fr 1fr 1fr' : '2fr 1fr'}>
        <InfoTitle>
          Nome
          <Info>{values?.driver?.name ?? '-'}</Info>
        </InfoTitle>

        <InfoTitle>
          Telefone
          <Info>
            {!isAdmin ? '(**) *****-****' : formatCelphoneNumberEntityToDomain(values?.driver?.firstPhone ?? '') ?? '-'}
          </Info>
        </InfoTitle>

        {isAdmin && (
          <>
            <InfoTitle>
              CPF
              <Info>{oferedByShipper ? '***.***.***-**' : formatCPF(values?.driver?.cpf ?? '-')}</Info>
            </InfoTitle>

            <InfoTitle>
              CNH
              <Info>{oferedByShipper ? '***********' : values?.driver?.cnhNumber ?? '-'}</Info>
            </InfoTitle>
          </>
        )}
      </SectionContainer>
    );
  }, [isLoading, values, isAdmin, oferedByShipper, driverScheduleHistory, handleUpdate, handleDelete]);

  const contentTable = useMemo((): Array<IContentTable> => {
    const listContent = findAllDrivers.list?.map((driver) => {
      return listDriverContent(driver);
    });

    return listContent;
  }, [findAllDrivers]);

  const handleSearch = useCallback((search: string) => {
    setParams((prevState) => ({ ...prevState, search, page: 1 }));
  }, []);

  const handleCurrentPage = useCallback((page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  }, []);

  return (
    <>
      {modalEdit && (
        <AllocationModalList
          name="driver"
          title={'Motorista'}
          contentTable={contentTable}
          headerTable={headerDriverContent()}
          changePage={(page) => handleCurrentPage(page)}
          pageInfo={findAllDrivers?.pageInfo}
          isLoading={false}
          handleChangeSearch={(e) => handleSearch(e)}
          handleSelectCurrentLine={(id) => handleUpdate(Number(id))}
          handleClose={() => setModalEdit(false)}
        />
      )}
      <Wrapper>
        <HeaderTitle display="flex" justify="space-between" isTraced>
          Dados do Motorista
          {values?.typeSchedule === 'ftl' ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Button
                title="Editar"
                bgColor="white"
                size="very-small"
                callback={() => setModalEdit(true)}
                icon={<CreateIcon />}
                disabled={!values?.id}
              />
            </div>
          ) : (
            <AllocationIcon>
              <EditDisabledIcon />
            </AllocationIcon>
          )}
        </HeaderTitle>

        {renderDriver}
      </Wrapper>
    </>
  );
};

export default FreightDriverSectionView;
