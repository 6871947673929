import { IOption } from 'domain/global-inputs';

export type OperationTypeDomain = 'IN' | 'OUT' | 'TR';

export type OperationTypeResponse = 'INBOUND' | 'OUTBOUND' | 'TRANSFER' | 'TRANSFERENCIA';

export type OperationSubTypeDomain =
  | 'Abastecimento'
  | 'Coleta'
  | 'Devolução de Mercadoria'
  | 'Devolução de Palete'
  | 'Transferência'
  | 'Deslocamento'
  | 'Outros';

export const operationTypeOptions: IOption[] = [
  { value: 'INBOUND', label: 'INBOUND' },
  { value: 'OUTBOUND', label: 'OUTBOUND' },
  { value: 'TRANSFERENCIA', label: 'TRANSFERÊNCIA' },
];
