import API from 'utils/API/API';
import { FreeTimeDomain } from 'domain/free-time';

export const findByVehicleCompanyService = async (companyId: number, vehicleType: string): Promise<FreeTimeDomain> => {
  const { data } = await API.post('v2/retention-fines/vehicle-type-company', {
    vehicleType,
    companyId,
  });
  return {
    companyId: data.companyId,
    dailyFine: data.dailyFine ?? 0,
    freeTime: data.freeTime ?? '',
    hourlyFine: data.hourlyFine ?? 0,
    id: data.id,
    vehicleCategory: data.vehicleCategory ?? '',
    vehicleType: data.vehicleType ?? '',
  };
};
