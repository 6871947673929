import styled from 'styled-components';

export const ContainerStyled = styled.div``;

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const UploadDownStyled = styled.div`
  margin: 0 10px 0 10px;

  &
`;
