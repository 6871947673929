import { useParams } from 'react-router-dom';
import React from 'react';
import moment from 'moment';

import GoogleMaps from '../GoogleMaps';

import * as S from './styles';

import Checkbox from 'components/checkbox/checkbox';

interface ModalTrackProps {
  schedule: any;
  origin: any;
  destination: any
  firebaseTruckRoutes: {
    isChecked: boolean;
    routes: any[];
  };
  monytorTruckRoutes: {
    isChecked: boolean;
    routes: any[];
  };
  // defaultRoutes: any[];
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleMapKind: (mapKind: string) => void;
}

const ModalTrackDetails: React.FC<ModalTrackProps> = (props) => {
  const { id } = useParams();

  const {
    schedule: { scheduleDetails, scheduleClientOrigin, scheduleClientDestiny, vehicle },
    firebaseTruckRoutes,
    monytorTruckRoutes,
    // defaultRoutes,
    onClose,
    handleMapKind,
    origin,
    destination,
  } = props;

  const [plannedRoute, setPlannedRoute] = React.useState<boolean>(true);

  const clientOriginName = React.useMemo(() => {
    if (scheduleClientOrigin?.client) {
      return scheduleClientOrigin?.client?.tradeName ?? '-';
    }
    return scheduleClientOrigin?.locality?.name || '-';
  }, [scheduleClientOrigin]);

  const clientDestinyName = React.useMemo(() => {
    if (scheduleClientDestiny?.client) {
      return scheduleClientDestiny?.client?.tradeName ?? '-';
    }
    return scheduleClientDestiny?.locality?.name || '-';
  }, []);

  const clientOriginAddress = React.useMemo(() => {
    if (scheduleClientOrigin?.client) {
      return `${scheduleClientOrigin?.client?.city} - ${scheduleClientOrigin?.client?.uf}`;
    }

    return `${scheduleClientOrigin?.locality?.city}  -  ${scheduleClientOrigin?.locality?.uf} `;
  }, [scheduleClientOrigin]);

  const clientDestinyAddress = React.useMemo(() => {
    if (scheduleClientDestiny?.client) {
      return `${scheduleClientDestiny?.client?.city} - ${scheduleClientDestiny?.client?.uf}`;
    }

    return `${scheduleClientDestiny?.locality?.city}  -  ${scheduleClientDestiny?.locality?.uf} `;
  }, [scheduleClientDestiny]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Text size={17} weight>
            Frete ID {id}
          </S.Text>

          <S.Description hasVehicle={!!vehicle}>
            <S.DescriptionContent margin>
              <S.ConectorContent>
                <S.StepIcon date={scheduleDetails?.checkinDate ? true : false} />
                <S.Connector date={scheduleDetails?.loadDate ? true : false} />
                <S.StepIcon date={scheduleDetails?.loadDate ? true : false} />
              </S.ConectorContent>

              <S.TruncateText>
                <S.OriginDetails>
                  <S.Text size={11} weight padding={'0 0 0 5px'} uppercase>
                    {clientOriginName}
                  </S.Text>

                  <S.Text size={11} padding={'5px'} uppercase>
                    {clientOriginAddress}
                  </S.Text>

                  <S.Text size={11} padding={'5px'}>
                    <S.LineDescriptionSection>
                      <S.TruncateText>
                        {'Planejado '}
                        <S.StrongText>
                          {moment(scheduleDetails?.levyInitialDate).format('DD/MM/YYYY') +
                            ' ' +
                            (scheduleDetails?.levyInitialTime || '-')}
                        </S.StrongText>
                      </S.TruncateText>

                      <S.TruncateText>
                        {'Real '}
                        <S.StrongText>
                          {scheduleDetails?.checkinDate
                            ? moment(scheduleDetails?.checkinDate).format('DD/MM/YYYY HH:mm')
                            : '-'}
                        </S.StrongText>
                      </S.TruncateText>
                    </S.LineDescriptionSection>
                  </S.Text>
                </S.OriginDetails>

                <S.DestinyDetails>
                  <S.Text size={11} weight padding={'0 0 0 5px'} uppercase>
                    {clientDestinyName}
                  </S.Text>

                  <S.Text size={11} padding={'5px'} uppercase>
                    {clientDestinyAddress}
                  </S.Text>

                  <S.Text size={11} padding={'5px'}>
                    <S.LineDescriptionSection>
                      <S.TruncateText>
                        {'Planejado '}
                        <S.StrongText>
                          {moment(scheduleDetails?.deliveryInitialDate).format('DD/MM/YYYY') +
                            ' ' +
                            (scheduleDetails?.deliveryInitialTime || '-')}
                        </S.StrongText>
                      </S.TruncateText>

                      <S.TruncateText>
                        {'Real '}
                        <S.StrongText>
                          {scheduleDetails?.loadDate
                            ? moment(scheduleDetails?.loadDate).format('DD/MM/YYYY HH:mm')
                            : '-'}
                        </S.StrongText>
                      </S.TruncateText>
                    </S.LineDescriptionSection>
                  </S.Text>
                </S.DestinyDetails>
              </S.TruncateText>
            </S.DescriptionContent>

            {!!vehicle && (
              <S.DescriptionContent>
                <div style={{ marginTop: 2 }}>
                  <S.StepIcon date />
                </div>
                <S.TruncateText>
                  <S.Text padding={'0 0 0 5px'}>
                    {`Veículo: ${vehicle.mainBoard} ${vehicle.secondBoard} ${vehicle.thirdBoard}`}
                  </S.Text>
                </S.TruncateText>
              </S.DescriptionContent>
            )}

            <S.SubtitleContent>
              <S.LineSubtitleSection>
                <Checkbox
                  id="plannedRoute"
                  name="plannedRoute"
                  onChange={() => setPlannedRoute(!plannedRoute)}
                  checked={plannedRoute}
                />
                <S.Text>Rota Planejada</S.Text>
                <S.Tracing color="blue" />
              </S.LineSubtitleSection>

              <S.LineSubtitleSection>
                <Checkbox
                  id="firebase"
                  name="firebase"
                  onChange={() => handleMapKind('firebase')}
                  checked={firebaseTruckRoutes.isChecked}
                  disabled={!(firebaseTruckRoutes.routes.length > 0)}
                />
                <S.Text color={firebaseTruckRoutes.routes.length > 0 ? 'black' : 'lightGray'}>
                  Rota Executada (GPS Celular)
                </S.Text>
                <S.Tracing color="green" />
              </S.LineSubtitleSection>
              <S.LineSubtitleSection>
                <Checkbox
                  id="tracker"
                  name="tracker"
                  onChange={() => handleMapKind('monytor')}
                  checked={monytorTruckRoutes.isChecked}
                  disabled={!(monytorTruckRoutes.routes.length > 0)}
                />
                <S.Text color={monytorTruckRoutes.routes.length > 0 ? 'black' : 'lightGray'}>
                  Rota Executada (Rastreador)
                </S.Text>
                <S.Tracing color="aqua" />
              </S.LineSubtitleSection>
            </S.SubtitleContent>
          </S.Description>
        </S.Content>

        <S.FullMapWrapper>
          <GoogleMaps
            origin={origin}
            destination={destination}
            schedule={props.schedule}
            firebaseTruckRoutes={firebaseTruckRoutes}
            monytorTruckRoutes={monytorTruckRoutes}
            // defaultRoutes={defaultRoutes}
            plannedRoute={plannedRoute}
            hideModal
            handleMapKind={handleMapKind}
          />

          <S.ContainerCloseModal onClick={() => onClose(false)}>X</S.ContainerCloseModal>
        </S.FullMapWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default React.memo(ModalTrackDetails);
