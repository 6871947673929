import React, { ReactElement, useMemo } from 'react';
import * as S from './schedule-refused-page-body-view.styled';
import { Search } from 'src-new/components/application-page-header/components/search/search.component';
import { ListSubtitle } from 'src-new/components/application-page-header/components/list-subtitle/list-subtitle.component';
import { BreadcrumbBranches } from 'src-new/pages/execution/pages/schedule-refused/utils/breadcrumbs/breadcrumbs-pages.util';
import { ScheduleRefusedBreadcrumbEnum } from 'src-new/pages/execution/pages/schedule-refused/utils/breadcrumbs/breadcrumbs-pages.types';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { Button } from 'logshare-ui-kit';
import { ScheduleRefusedTableTabs } from '../schedule-refused-table-tabs/schedule-refused-table-tabs.component';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { HeaderModals } from './components/header-modals/header-modals.component';
import { ScheduleRefusedListModalType } from 'src-new/pages/execution/pages/schedule-refused/contexts/schedule-refused/types/schedule-refused-list.types';

interface IScheduleRefusedPageBodyViewProps {
  scheduleRefusedFilters: Array<IParamsFilter>;
  appliedFilters: number;
  isShippingCompany: boolean;
  handleExportExcel: () => void;
  handleChangeSearch: (search: string) => void;
  removeScheduleRefusedFilter: (indexToRemove: number) => void;
  handleOpenModal: (modalType: ScheduleRefusedListModalType) => void;
}

export const ScheduleRefusedPageBodyView: React.FC<IScheduleRefusedPageBodyViewProps> = ({
  scheduleRefusedFilters,
  appliedFilters,
  isShippingCompany,
  handleExportExcel,
  handleChangeSearch,
  removeScheduleRefusedFilter,
  handleOpenModal,
}) => {
  const renderNewFreightButton = useMemo((): ReactElement | undefined => {
    if (!isShippingCompany) {
      return (
        <Button
          label="Novo Frete"
          size="md"
          color="blue"
          onPress={() => handleOpenModal('newFreight')}
          variant="solid"
        />
      );
    }
  }, [handleOpenModal, isShippingCompany]);

  return (
    <S.Wrapper>
      <HeaderModals />
      <S.Header>
        <HeaderPage breadcrumbList={BreadcrumbBranches()[ScheduleRefusedBreadcrumbEnum.SCHEDULE_REFUSED_LIST]} />
        <ListSubtitle title="Acompanhe as cargas recusadas por transportadores." />
      </S.Header>
      <S.ContainerSearch>
        <Search
          placeholder="Pesquise pelo ID do Frete ou Transportadora"
          filters={scheduleRefusedFilters.map((item) => item.value)}
          appliedFilters={appliedFilters}
          changeValue={handleChangeSearch}
          handleClearFilters={removeScheduleRefusedFilter}
          handleExportButton={handleExportExcel}
        />
        {renderNewFreightButton}
      </S.ContainerSearch>
      <S.Content>
        <ScheduleRefusedTableTabs />
      </S.Content>
    </S.Wrapper>
  );
};
