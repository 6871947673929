import React from 'react';

export const DoubleArrowDownGrayIcon: React.FC = () => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 -2 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="8" height="10" fill="url(#double_arrow_down0)" fillOpacity="1" />
      <defs>
        <pattern id="double_arrow_down0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#double_arrow_gray" transform="scale(0.00195312)" />
        </pattern>
        <image
          id="double_arrow_gray"
          width="512"
          height="512"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABgCAMAAADcrSdwAAAAAXNSR0IB2cksfwAAATJQTFRFAAAA////XV1dXF9fXV5egP//bba2Wn19WXZ2gL+/gMzMW2ZmXGVlZszMXGNjgICAXV5eXF9fXGFhXGBgXV1dXGNjXGBgXV5eAP//X5SUXF5eXGpoXF5eXF9fXWFhXGFhZrOzXV1dXF5eXGZmW2RkXV1dWmxs29vbaby8XGNjWnBuXV5eXXd3ccbGgP+AXV5eXGFhXV9eXGVlZLy8X6OjXF9fXGFgXGBgXF9fqv//XGVlaLm5XGJi////gNW/XGFhXF9fW2JiY7i4s+bmXGtrzObmXV9fXGBgXF9fXV5eXF9eXHJyXHJyXV5eZsyzW2dnmczMXGhmgNXVXF9fXF9fXV1dXV5eacO0XV1dYba2XGpqqqqqXV9fXGNjW2tpXF9fW2trW2dnXGBgXGJiXV1dXGBgrH8WVwAAAGZ0Uk5TAAH/5ugCB4GPBAqzcAVSAtXloav0q7P6AQ7vWenEUK4K0+Nyn/ObByJ1kPUjCQL0gLCFFx3k4p+8A1QWpAIM3fDGEgpbCk7Ny+fkOkLjCpAFVwbF8ezUEfkVWQOwiXHYcZfht/rnFtEpZAAAAvpJREFUeJzdmllD00AQgNORqlUrFRSLF+KNKCCK0iqetYKK94338f//grvBtEl2dndmduNDv9fJztdks9ekSTKgBik7xupJCDsBdu0GaFhiQ/aIFbkke2ulWB1K7JMYmuUshehYOarYz1WMI0la6D3mOFC+XTcTaJLJLHwQlwAcoitsKWBqO248yRxNmqLmSFF3/wjNYYKm7cyQ9v60+xI44nMc9SRo+G4k5ZhLcdzfPklO+C8CmLEpTlJaNwg3koJ2jau/81AlALOG4hS1KV0Cp4sOekOOBODMcA44y2nHkijOnVeGC8xGXImIEZIQRmwoc6xXUQjvfZdxkTmqRHCHrlxCneZCHP5lK4imZLJjkptRsS1TDOaLu6pLVTgul5eghcXYikVs427sh8Ow7T+X4imuWBSa5TiKZYdCczVccc2jULRXAh1tv0NRux6goJ83bkgVq2SFZlKimGApFAt8xzjXoejwFMY2lgpd0ZEqFF2aouXP5OSmX3ErUJEQ1hpxZxRYcynQGoqIOZtiip/rNty5e+8+GprBFCt4mge93kM80s+3xk6IjwwHlmZ9GN4wprHy4ou0L62cWGc8Ll7ypBBETsrz2O/sZdF1LIoc8XKP5Cn2wGETS2QHP0V2s/AqGlY8oyvsr/l2vG+N/68q0XOKw70z1Fe8cF5h1AhMZj0JOr4bSXE/M397d48MsE9QlNb1ZJNymU1Da0s/xyOLBqnYxZIAvCwqXPVRuaT4BnDasU9yr9Toti4vsSQSRkjCqvPJeD0qVaI33DdeAntYiSUVW/7NEG+rdLzL5qH31TlyHxMbVTmKm8jo5RvNh/IKVEHJC6sSRSzfaLqIQvMxnuKTRRFzyDgUiq0YCv8RL7hMvOVVaD6HKJZICg3+RZqA9YMkBmnzatLnOBLR4OQqNF94CmMOIfKVrvgmVGi+0xQ/AhSK+k+CQ9IZJY1PQTy8emi5FBtRFBprdf1XNIWi/7vCJzUE2Qaw/6NDYK3au8iY/jNQ2BZXlL8fw1GRa9wSEgAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};
