import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IListPage } from '../../../../domain/page-info';

import { ITableFreightContext } from './table-freight.types';
import { tableFreightReducer } from './table-freight.reducer';
import { ITableFreightAction, initialState } from './table-freight.actions';

import { ITableFreight } from 'domain/table-freight';

interface ITableFreightContextProps {
  children: ReactNode;
}

export const TableFreightContext = createContext<ITableFreightContext>(initialState);

const TableFreightContextProvider = ({ children }: ITableFreightContextProps) => {
  const [state, dispatch] = useReducer(tableFreightReducer, initialState);

  const setTableFreightRequest = useCallback(() => {
    dispatch({ type: ITableFreightAction.TABLE_FREIGHT_REQUEST, payload: null });
  }, []);

  const setTableFreightSuccess = useCallback((TableFreightList: IListPage<ITableFreight>) => {
    dispatch({
      type: ITableFreightAction.TABLE_FREIGHT_SUCCESS,
      payload: TableFreightList,
    });
  }, []);

  const setTableFreightError = useCallback((error: string) => {
    dispatch({ type: ITableFreightAction.TABLE_FREIGHT_ERROR, payload: error });
  }, []);

  const value = useMemo(() => {
    return {
      tableFreight: state.tableFreight,
      tableFreightIsLoading: state.tableFreightIsLoading,
      tableFreightError: state.tableFreightError,
      tableFreightPageInfo: state.tableFreightPageInfo,
      setTableFreightRequest,
      setTableFreightSuccess,
      setTableFreightError,
    };
  }, [
    setTableFreightError,
    setTableFreightRequest,
    setTableFreightSuccess,
    state.tableFreight,
    state.tableFreightError,
    state.tableFreightIsLoading,
    state.tableFreightPageInfo,
  ]);

  return <TableFreightContext.Provider value={value}>{children}</TableFreightContext.Provider>;
};

export default React.memo(TableFreightContextProvider);
