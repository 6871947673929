import { useCallback, useContext } from 'react';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { IConversationChatDomain } from 'src-new/domains/conversation-chat.domain';
import { convertConversationResponseToDomain } from 'src-new/hooks/chat/use-get-conversation-list-chat/mappers/convert-conversation-response-to-domain/convert-conversation-response-to-domain';
import { AxiosError } from 'axios';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { UserContext } from 'state/user-context';
import { getCompanyNameByIdService } from 'src-new/services/get-company-name-by-id/get-company-name-by-id.service';
import { getScheduleByIdService } from 'src-new/services/get-schedule-by-id/get-schedule-by-id.service';
import { validateConversation } from 'src-new/utils/validate-conversation/validate-conversation.util';
import { IUser } from 'domain/login';
import { getConversationsListByUserService } from 'src-new/services/chat/get-conversations-list-by-user/get-conversations-list-by-user.service';
import { IConversationResponse } from 'src-new/services/chat/get-conversations-list-by-user/get-conversations-list-by-user.types';

export const useGetConversationListChat = () => {
  const { chatConversationList } = useContext(ChatContext);
  const { user } = useContext(UserContext);
  const { nextPage, setNextPage, setPageLimit } = chatConversationList.paginationActions;

  const handleSchedulingConversation = useCallback(async (conversation: IConversationResponse, user: IUser) => {
    const scheduleId: string = conversation.id.split('-')[2];
    const scheduleDetails: IScheduleDetailsDomain | undefined = await getScheduleByIdService(scheduleId);
    if (scheduleDetails) {
      const conversationDomain = convertConversationResponseToDomain(conversation, user, scheduleDetails);
      return conversationDomain.typePartner.name !== 'NOT_FOUND' ? conversationDomain : undefined;
    }
  }, []);

  const parseDateString = useCallback((dateString: string): Date => {
    const [datePart, timePart] = dateString.split(' as ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
  }, []);

  const handleProposalConversation = useCallback(async (conversation: IConversationResponse, user: IUser) => {
    if (
      conversation.custom.headerLabel &&
      conversation.custom.headerLabel.split(' ')[0].toUpperCase() === 'NEGOCIAÇÃO'
    ) {
      const companyId: string | undefined =
        user.companyId === Number(conversation.custom.buyOfferCompanyId)
          ? conversation.custom.saleOfferCompanyId
          : conversation.custom.buyOfferCompanyId;

      const companyName = await getCompanyNameByIdService(Number(companyId ?? user.companyId));

      return convertConversationResponseToDomain(conversation, user, undefined, companyName);
    }

    if (user.profile !== 'shipping-company') {
      const companyName = await getCompanyNameByIdService(user.companyId);

      return convertConversationResponseToDomain(conversation, user, undefined, companyName);
    }

    return undefined;
  }, []);

  return useCallback(async (): Promise<void> => {
    chatConversationList.setChatConversationListRequest();

    if (user) {
      try {
        const conversations: Array<IConversationChatDomain> = chatConversationList.conversations;
        const conversationListResponse: Array<IConversationResponse> = await getConversationsListByUserService(
          user.id,
          nextPage,
        );

        if (conversationListResponse.length) {
          setNextPage(conversationListResponse[conversationListResponse.length - 1].id);

          const conversationList: Array<IConversationChatDomain | undefined> = await Promise.all(
            conversationListResponse
              .filter((conversation) => validateConversation(conversation.id) && conversation.lastMessage)
              .sort((a, b) => {
                if (a.createdAt > b.createdAt) return -1;
                if (a.createdAt < b.createdAt) return 1;

                return 0;
              })
              .map(async (conversation): Promise<IConversationChatDomain | undefined> => {
                try {
                  const flow: string = conversation.id.split('-')[1];

                  if (flow === 'schedule') {
                    return handleSchedulingConversation(conversation, user);
                  }

                  return handleProposalConversation(conversation, user);
                } catch (error) {
                  if (error instanceof AxiosError && error.response && error.response.status === 400) {
                    return undefined;
                  }

                  throw error;
                }
              }),
          );

          const resultConversations: Array<IConversationChatDomain> = [
            ...conversations,
            ...conversationList.filter(
              (conversation): conversation is IConversationChatDomain => conversation !== undefined,
            ),
          ].sort((a, b) => {
            const dateA: Date = parseDateString(a.lastMessage);
            const dateB: Date = parseDateString(b.lastMessage);

            return dateB.getTime() - dateA.getTime();
          });
          chatConversationList.setChatConversationListSuccess(resultConversations);
        } else {
          chatConversationList.setChatConversationListSuccess(conversations);
          setPageLimit();
        }
      } catch (error) {
        chatConversationList.setChatConversationListError({
          title: 'Ocorreu uma falha ao carregar a lista de chat',
          description: 'Por favor, atualize a página e tente novamente.',
        });
      }
    }
  }, [
    chatConversationList,
    handleProposalConversation,
    handleSchedulingConversation,
    nextPage,
    parseDateString,
    setNextPage,
    setPageLimit,
    user,
  ]);
};
