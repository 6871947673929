import React, { ReactNode, useContext, useMemo } from 'react';
import { Session } from '@talkjs/react';
import { UserContext } from 'state/user-context';
import { useUserSessionChat } from 'pages/freight/hooks/use-user-session-chat/use-user-session-chat.hook';
import { FreightContext } from 'pages/freight/contexts/freight/freight.context';

const APP_ID = 'KdJaD5Yb';

interface IFreightUpdateSessionProps {
  children: ReactNode;
}

const FreightUpdateSession: React.FC<IFreightUpdateSessionProps> = ({ children }) => {
  const { freightChat } = useContext(FreightContext);
  const { user } = useContext(UserContext);
  const syncUser = useUserSessionChat();

  const getPhotoUser = useMemo((): string => {
    return freightChat.photoUrl;
  }, [freightChat.photoUrl]);

  return (
    <Session appId={APP_ID} syncUser={syncUser(getPhotoUser, user)}>
      {children}
    </Session>
  );
};

export default FreightUpdateSession;
