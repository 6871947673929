import React, { useCallback, useEffect, useState } from 'react';
import { FormikValues } from 'formik';

import {
  ButtonClosedStyled,
  HeaderResume,
  HeaderSectionDescriptionResume,
  HeaderSectionResume,
  InfoDescriptionNotInformedResume,
  InfoDescriptionResume,
  InfoDescriptionSectionResume,
  InfoFirtDescriptionResume,
  InfoSubTitleResume,
  SectionResume,
  TitleSectionStyled,
  WrappedResume,
  WrapperButtonIconStyled,
} from './resume.styled';
import { IResumeDescribers, IResumeInfo } from './resume-info.types';

import { rulesResumeMaps } from 'pages/territorial-vision/utils/resume-rules';
import { ShareIcon } from 'assets/icons/share.icon';

interface IProps {
  idSchedule?: number;
  createdAt?: string;
  reactNode?: React.ReactNode;
  info?: IResumeInfo;
  setInfo: React.Dispatch<React.SetStateAction<IResumeInfo>>;
  values: FormikValues;
  canShareInfoData: boolean;
  style?: React.CSSProperties;
  canClose?: boolean;
  handleCloseResume?: (close: boolean) => void;
  paddingTop?: string;
}

const numberDecimal = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const numberPrice = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Resume: React.FC<IProps> = ({
  idSchedule,
  createdAt,
  reactNode,
  values,
  canShareInfoData,
  setInfo,
  style,
  info,
  canClose,
  handleCloseResume,
  paddingTop,
}) => {
  const [labels, setLabels] = useState<IResumeDescribers[]>();

  const memorizedTriggerChangeInfo = useCallback(() => {
    const infoNewArray: IResumeInfo = info ?? rulesResumeMaps;

    infoNewArray.describes = [];

    let item: [key: string, v: string] = ['', ''];

    for (item of Object.entries(values)) {
      let lineRule = '';
      // eslint-disable-next-line prefer-const
      for (let [index, valueInfo] of Object.entries(item[1])) {
        const excludeField = infoNewArray.excludeFields && !infoNewArray.excludeFields.find((field) => field === index);

        if (valueInfo && excludeField) {
          const rules = infoNewArray.rules && infoNewArray.rules.filter((t) => t.fields?.find((x) => x === index));

          const changeEnumValue = infoNewArray.enumerables?.find((enumField) => enumField.field == index);
          const changeValues = changeEnumValue?.values.find((item) => item.value === valueInfo);

          valueInfo = changeValues?.name ?? valueInfo;

          if (rules && rules?.length === 1) {
            rules.map((rule) => {
              let formattedValue: string | undefined;

              if (rule.format) {
                const valueFormatted = Number.parseFloat(valueInfo.toString().replace(',', '.'));

                if (rule.format === 'decimal') {
                  formattedValue = numberDecimal.format(valueFormatted);
                }
                if (rule.format === 'price') {
                  formattedValue = numberPrice.format(valueFormatted);
                }
              }

              if (rule.fields?.length === 1) {
                infoNewArray.describes?.push({
                  name: item[0],
                  firstValue: rule.value?.replace('{' + index + '}', formattedValue || valueInfo) || '',
                  oneColumns: !rule.moreThanOneColumns,
                });
              }

              if (rule.fields && rule.fields?.length >= 2) {
                rules.map((rule) => (lineRule = rule.value || ''));

                const existDescribe = infoNewArray.describes?.findIndex(
                  (t) => t.ruleIndex == rule.ruleIndex && t.name == item[0],
                );
                if (existDescribe !== -1) {
                  if (infoNewArray.describes) {
                    infoNewArray.describes[existDescribe || 0].firstValue = infoNewArray.describes[
                      existDescribe || 0
                    ].firstValue.replace('{' + index + '}', valueInfo as string);
                  }
                } else {
                  infoNewArray.describes?.push({
                    name: item[0],
                    firstValue: lineRule.replace('{' + index + '}', valueInfo as string),
                    oneColumns: !rule.moreThanOneColumns,
                    ruleIndex: rule.ruleIndex,
                  });
                }
              }
            });
          } else {
            infoNewArray.describes?.push({
              name: item[0],
              firstValue: valueInfo as string,
              oneColumns: true,
            });
          }
        }
      }
    }

    const showDisplayLabels: IResumeDescribers[] = [];

    const regex = /{([^}]+)}/g;
    for (const item of infoNewArray.describes) {
      if (typeof item.firstValue === 'string')
        showDisplayLabels.push({
          ...item,
          firstValue: item.firstValue.replace(regex, ''),
        });
    }

    setLabels(showDisplayLabels);
  }, [rulesResumeMaps, setInfo, values]);

  useEffect(() => {
    if (rulesResumeMaps) {
      memorizedTriggerChangeInfo();
    }
  }, [values]);

  if (!rulesResumeMaps || !labels) return <></>;

  return (
    <WrappedResume style={style}>
      <HeaderSectionResume style={{ padding: `${paddingTop}px 0px 0px 0px` }}>
        <InfoDescriptionSectionResume>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              {idSchedule && <HeaderResume>{'ID ' + idSchedule || ''}</HeaderResume>}
              <HeaderResume data-testid="resume-header">{rulesResumeMaps.principalHeader}</HeaderResume>
            </div>

            <InfoSubTitleResume>{rulesResumeMaps.description}</InfoSubTitleResume>

            {createdAt && <InfoSubTitleResume>Solicitado em {createdAt}</InfoSubTitleResume>}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginBottom: 50,
            }}
          >
            <div>{reactNode}</div>
          </div>

          {canShareInfoData && (
            <WrapperButtonIconStyled>
              <ShareIcon />
            </WrapperButtonIconStyled>
          )}
          {canClose && (
            <ButtonClosedStyled onClick={() => handleCloseResume && handleCloseResume(true)}>X</ButtonClosedStyled>
          )}
        </InfoDescriptionSectionResume>
      </HeaderSectionResume>
      {rulesResumeMaps.sectionHeader &&
        rulesResumeMaps.sectionHeader.map((section, index) => (
          <SectionResume key={index}>
            <HeaderSectionDescriptionResume>
              <TitleSectionStyled>{section}</TitleSectionStyled>
            </HeaderSectionDescriptionResume>
            {labels?.map(
              (describe, i) =>
                rulesResumeMaps.sectionHeaderName &&
                rulesResumeMaps.sectionHeaderName[index] == describe.name &&
                (describe.oneColumns ? (
                  <InfoDescriptionResume key={i}>{describe.firstValue.toUpperCase()}</InfoDescriptionResume>
                ) : (
                  <InfoDescriptionSectionResume key={i}>
                    <InfoDescriptionResume>{describe.firstValue.toUpperCase()}</InfoDescriptionResume>
                    <InfoFirtDescriptionResume>{describe.secondValue}</InfoFirtDescriptionResume>
                  </InfoDescriptionSectionResume>
                )),
            )}
            {labels?.filter(
              (t) => t.name == (rulesResumeMaps.sectionHeaderName && rulesResumeMaps.sectionHeaderName[index]),
            ).length === 0 && <InfoDescriptionNotInformedResume>Não Informado</InfoDescriptionNotInformedResume>}
          </SectionResume>
        ))}
    </WrappedResume>
  );
};

export default Resume;
