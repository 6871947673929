import { IAllocationRegisterContext, IAllocationRegisterReducerAction } from './allocation.types';
import { IAllocationsAction } from './allocation.actions';

export const allocationRegisterReducer = (
  state: IAllocationRegisterContext,
  action: IAllocationRegisterReducerAction,
): IAllocationRegisterContext => {
  switch (action.type) {
    case IAllocationsAction.GET_CURRENT_ALLOCATION_REQUEST:
      return { ...state, loading: true };
    case IAllocationsAction.GET_CURRENT_ALLOCATION_SUCCESS:
      return { ...state, loading: false, allocation: action.payload.allocation };
    case IAllocationsAction.GET_CURRENT_ALLOCATION_ERROR:
      return {
        ...state,
        loading: false,
        allocation: { id: 0, status: '', companyId: 0, company: '' },
      };
    case IAllocationsAction.ALLOCATION_DRIVER_LOAD:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          driver: action.payload.driver,
        },
      };
    case IAllocationsAction.ALLOCATION_SHIPPING_COMPANY_LOAD:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          shippingCompany: action.payload.shippingCompany,
        },
      };
    case IAllocationsAction.ALLOCATION_VEHICLE_LOAD:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          vehicle: action.payload.vehicle,
        },
      };
    case IAllocationsAction.SET_RESUME_RULES:
      return { ...state, infoRules: action.payload };
    default:
      return {
        ...state,
      };
  }
};
