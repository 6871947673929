import { IMapsSnapshot } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/schedule-maps.types';
import { ITruckRoute } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/truck-route.domain';
import { differenceInMinutes } from 'date-fns';

export const calculateSpeed = (velocityInMs?: number): number => {
  if (velocityInMs) {
    return velocityInMs * 3.6;
  }

  return 0;
};

export const formatSnapshots = (list: Array<IMapsSnapshot>, isMonitoring?: boolean): Array<ITruckRoute> => {
  return list
    .map((snapshot): ITruckRoute | undefined => {
      if (snapshot) {
        const { latitude, longitude, speed, address, ignition } = snapshot.content;
        const created_at: number = snapshot.timestamp;

        return {
          latitude: Number(latitude),
          longitude: Number(longitude),
          speed: isMonitoring ? speed : calculateSpeed(speed),
          created_at,
          address,
          ignition,
        };
      }
    })
    .filter((route): route is ITruckRoute => route !== undefined);
};

export const filterListByFiveMinuteInterval = (
  list: Array<IMapsSnapshot>,
  isMonitoring?: boolean,
): Array<ITruckRoute> => {
  if (list.length === 0) return [];

  list.shift();
  list.pop();

  const filteredList: Array<IMapsSnapshot> = [list[0]];

  for (let i = 1; i < list.length; i++) {
    if (list[i].timestamp - filteredList[filteredList.length - 1].timestamp >= 300000) {
      filteredList.push(list[i]);
    }
  }

  return formatSnapshots(filteredList, isMonitoring);
};

export const filterTruckRoutesByTimeInterval = (routes: ITruckRoute[], intervalMinutes: number = 3): ITruckRoute[] => {
  if (routes.length === 0) return [];

  routes.sort((a, b) => a.created_at - b.created_at);

  const filteredRoutes: ITruckRoute[] = [];
  let lastAddedRoute = routes[0];
  filteredRoutes.push(lastAddedRoute);

  for (let i = 1; i < routes.length; i++) {
    const currentRoute = routes[i];
    const minutesDifference = differenceInMinutes(
      new Date(currentRoute.created_at),
      new Date(lastAddedRoute.created_at),
    );

    if (minutesDifference >= intervalMinutes) {
      filteredRoutes.push(currentRoute);
      lastAddedRoute = currentRoute;
    }
  }

  return filteredRoutes;
};
