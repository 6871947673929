import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useDriverList } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-driver-list/use-driver-list.hook';
import { ScheduleDetailsAllocationDriverListTableView } from './schedule-details-allocation-driver-list-table-view.component';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { IScheduleDetailsAllocationDriversListProps } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-driver-list/get-driver-list.types';
import { isEqual } from 'lodash';

const initialStateFilter: IScheduleDetailsAllocationDriversListProps = {
  search: '',
  page: 1,
  limitRegistry: 50,
  status: 'active',
  order: 'DESC',
  column: 'driver.id',
};

export const ScheduleDetailsAllocationDriverListTable: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { loading } = initialLoading;
  const getDriverListHook = useDriverList();
  const [isLimitRequest, setIsLimitRequest] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<IScheduleDetailsAllocationDriversListProps>(initialStateFilter);
  const [currentFilter, setCurrentFilter] = useState<IScheduleDetailsAllocationDriversListProps>({
    ...initialStateFilter,
    page: 0,
  });

  const isLoading = useMemo(() => loading, [loading]);

  const isLazyLoading = useMemo(
    () => scheduleDetails.allocationDriver.modalProps.statusRequest === 'PROCESSING' && !isLoading,
    [isLoading, scheduleDetails.allocationDriver.modalProps.statusRequest],
  );

  const isError = useMemo(
    () => scheduleDetails.allocationDriver.modalProps.statusRequest === 'ERROR',
    [scheduleDetails.allocationDriver.modalProps.statusRequest],
  );

  const isEmpty = useMemo(
    () =>
      scheduleDetails.allocationDriver.modalProps.statusRequest === 'SUCCESS' &&
      !scheduleDetails.allocationDriver.modalProps.driverList.length,
    [
      scheduleDetails.allocationDriver.modalProps.driverList.length,
      scheduleDetails.allocationDriver.modalProps.statusRequest,
    ],
  );

  const handleSearch = useCallback((search: string) => {
    const filter: IScheduleDetailsAllocationDriversListProps = { ...initialStateFilter, search };

    setPage(1);
    setCurrentFilter({ ...filter, page: 0 });
    setFilter(filter);
  }, []);

  const handleSelectedDriver = useCallback(
    (checked: boolean, driverId: number) => {
      scheduleDetails.allocationDriver.modalProps.setScheduleDetailsSelectedDriver(checked, driverId);
    },
    [scheduleDetails.allocationDriver.modalProps],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, isModal: true, onInfinityScroll: setPage }),
    [isLazyLoading],
  );

  useEffect(() => {
    if (!isEqual(filter, currentFilter)) {
      setCurrentFilter(filter);
      getDriverListHook(filter, setIsLimitRequest);
    }
  }, [currentFilter, filter, getDriverListHook]);

  useEffect(() => {
    if (!isLimitRequest && page > filter.page) {
      setFilter({ ...filter, page });
    }
  }, [filter, isLimitRequest, page, scheduleDetails.allocationDriver.modalProps.statusRequest]);

  return (
    <ScheduleDetailsAllocationDriverListTableView
      statusProps={{ isError, isLoading, isEmpty }}
      drivers={scheduleDetails.allocationDriver.modalProps.driverList}
      driverSelected={scheduleDetails.allocationDriver.modalProps.driverSelected}
      handleSearch={handleSearch}
      infiniteScrollProps={infiniteScrollProps}
      handleSelectedDriver={handleSelectedDriver}
    />
  );
};
