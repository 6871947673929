import React from 'react';
import { InputSelectFormikView } from './input-select-formik-view.component';

export interface SelectPropsOptions {
  label: string;
  value: any;
}

interface InputSelectProps {
  id: string;
  label?: string;
  name: string;
  icon?: React.ReactNode;
  value: any;
  options: SelectPropsOptions[];
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  isClearable?: boolean;
  maxMenuHeight?: number;
  widthContainer?: string;
  onChange?: () => void;
  hidden?: boolean;
}

export const InputSelectFormik: React.FC<InputSelectProps> = ({
  id,
  name,
  icon,
  options,
  value,
  hasError,
  errorMessage,
  placeholder,
  disabled,
  onChange,
  label,
  setFieldValue,
  isClearable = false,
  maxMenuHeight = 260,
  hidden = false,
  widthContainer,
}) => {
  return (
    <InputSelectFormikView
      id={id}
      name={name}
      icon={icon}
      options={options}
      value={value}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      onChange={onChange}
      setFieldValue={setFieldValue}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      hidden={hidden}
      widthContainer={widthContainer}
    />
  );
};
