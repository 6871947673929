export const blockedOccurrences = [
  'ALTO TEMPO DE ESPERA',
  'CARGA RECUSADA',
  'ENTREGA NÃO AGENDADA',
  'FALTA DE MERCADORIA',
  'FORA DO HORÁRIO AGENDADO',
  'MERCADORIA DANIFICADA',
  'PEDIDO DIVERGENTE',
  'SOBRA DE MERCADORIA',
  'VEÍCULO QUEBRADO',
  'OUTRO MOTIVO',
];

export const checkOccurrenceBlocked = (occurrence: string) => {
  return blockedOccurrences.includes(occurrence);
};
