import React from 'react';

import { CompanyImageStyled, ContainerCircleStyled, ImageNullStyled } from './input-circle-upload-image.styled';

type InputCircleUploadImageProps = {
  dataUrlImage: string;
  onClick?: () => void;
};

const InputCircleUploadView: React.FC<InputCircleUploadImageProps> = ({ dataUrlImage, onClick }) => {
  return (
    <ContainerCircleStyled dataUrlImage={dataUrlImage} onClick={onClick}>
      {dataUrlImage ? (
        <CompanyImageStyled src={dataUrlImage} alt="" dataUrlImage={dataUrlImage} />
      ) : (
        <>
          <ImageNullStyled />
        </>
      )}
    </ContainerCircleStyled>
  );
};

export default InputCircleUploadView;
