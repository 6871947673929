import { FC } from 'react';
import * as S from './weighing-update-page-body-view.styled';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import Title from 'components/title/title';
import { Loading } from 'src-new/components/loading/loading.component';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { WeighingUpdateForm } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/components/weighing-update-form/weighing-update-form.component';

interface IWeighingUpdatePageBodyViewProps {
  isLoading: boolean;
  isError: boolean;
}

export const WeighingUpdatePageBodyView: FC<IWeighingUpdatePageBodyViewProps> = ({ isLoading, isError }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <S.Wrapper>
      <HeaderPage breadcrumbList={['Programação', 'Pesagem']} hasBackButton />
      <Title title="Atualizar Pesagem" hasTrace isForm />
      <WeighingUpdateForm />
    </S.Wrapper>
  );
};
