import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { TransactionsListTabsView } from './transactions-list-tabs-view.component';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import { TransactionActiveTabName } from '../../../../contexts/transactions/types/transactions-list.types';
import { TransactionsContext } from 'src-new/pages/financial/pages/transactions/contexts/transactions/transactions.context';
import { ISelectedIdsProps } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/components/transactions-list-page-body/transactions-list-page-body.types';
import { UserContext } from 'state/user-context';

const GPA_COMPANY_ID = 10;

interface ITransactionsListTabsProps {
  activeTab: TransactionActiveTabName;
  setActiveTab: (name: TransactionActiveTabName) => void;
  selectedIdsProps: ISelectedIdsProps;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const TransactionsListTabs: React.FC<ITransactionsListTabsProps> = ({
  activeTab,
  setActiveTab,
  selectedIdsProps,
  handleInfinityScroll,
}) => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { transactionsList } = useContext(TransactionsContext);
  const { setInitialLoadingReset } = initialLoading;
  const { setTransactionsListReset } = transactionsList;
  const { user } = useContext(UserContext);

  const isCompanyGPA = useMemo(() => user?.companyId === GPA_COMPANY_ID, [user?.companyId]);

  const handleResetRequest = useCallback(() => {
    setTransactionsListReset();
    handleInfinityScroll(1);
    selectedIdsProps.setSelectedIds([]);
    setInitialLoadingReset();
  }, [handleInfinityScroll, selectedIdsProps, setInitialLoadingReset, setTransactionsListReset]);

  const getActiveTabName = useCallback((name: string): TransactionActiveTabName => {
    const activeTabMap: {
      [key: string]: TransactionActiveTabName;
    } = {
      'A Pagar': 'A Pagar',
      'A Receber': 'A Receber',
      'Crédito Transportadora': 'Crédito Transportadora',
      'CTe Pendente': 'Cte Pendente',
    };

    return activeTabMap[name];
  }, []);

  const handleChangeTab = useCallback(
    (name: string) => {
      handleResetRequest();
      setActiveTab(getActiveTabName(name));
    },
    [getActiveTabName, handleResetRequest, setActiveTab],
  );

  return (
    <TransactionsListTabsView
      getActiveTabName={getActiveTabName}
      selectedIdsProps={selectedIdsProps}
      handleInfinityScroll={handleInfinityScroll}
      activeTab={activeTab}
      handleChangeTab={handleChangeTab}
      isCompanyGPA={isCompanyGPA}
    />
  );
};
