import { IModalBiddingOffer } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer.types';
import { ISaleOfferMatchListDomain } from '../../domains/sale-offer-match-list.domain';

export const convertSaleOfferToBiddingOffer = (saleOffer: ISaleOfferMatchListDomain): IModalBiddingOffer => {
  return {
    id: saleOffer.id,
    additionalDistance: saleOffer.additionalDistance,
    company: saleOffer.company,
    origin: {
      name: saleOffer.origin.name,
      city: saleOffer.origin.address.city,
      uf: saleOffer.origin.address.uf,
    },
    destination: {
      name: saleOffer.destination.name,
      city: saleOffer.destination.address.city,
      uf: saleOffer.destination.address.uf,
    },
    capacity: {
      cubagem: 0,
      inPallet: saleOffer.capacity.pallet ? 'SIM' : 'NÃO',
      merchValue: 0,
      pallets: 0,
      product: 'NÃO INFORMADO',
      volume: saleOffer.capacity.pallet ?? 0,
      weight: saleOffer.capacity.weight,
    },
    cost: {
      feeLogshare: 0,
      freightValue: saleOffer.values.freightValue,
      grisAdvalorem: 0,
      icms: 0,
      tollValue: 0,
      totalFreight: 0,
      totalService: 0,
    },
    vehicle: {
      type: saleOffer.vehicle.type,
      category: saleOffer.vehicle.category,
    },
  };
};
