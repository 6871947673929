import { ChangeEvent, FC, ReactElement, useMemo } from 'react';
import * as S from './security-requirements-backhaul-purchase-form-view.styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckboxTag from 'components-v2/common/checkbox-tag';
import { IRequirementsDomain } from 'src-new/domains/requirements.domain';
import {
  IArrowButtons,
  RequirementsTypes,
} from 'src-new/components/modal-backhaul-purchase/types/security-requirements-backhaul-purchase-form/security-requirements-backhaul-purchase-form.types';
import { requirementsValues } from 'src-new/components/modal-backhaul-purchase/types/security-requirements-backhaul-purchase-form/security-requirements-backhaul-purchase-form-initial-values.constants';

interface ISecurityRequirementsBackhaulPurchaseFormViewProps {
  requirements: IRequirementsDomain;
  requirementsIndex: number;
  prevTags: () => void;
  nextTags: () => void;
  handleCheck: (
    event: ChangeEvent<HTMLInputElement>,
    currentValues: Array<string>,
    value: {
      label: string;
      type: RequirementsTypes;
    },
  ) => void;
}

export const SecurityRequirementsBackhaulPurchaseFormView: FC<ISecurityRequirementsBackhaulPurchaseFormViewProps> = ({
  requirements,
  requirementsIndex,
  prevTags,
  nextTags,
  handleCheck,
}) => {
  const columns = useMemo((): string => requirementsValues[requirementsIndex].columns, [requirementsIndex]);

  const disableButtons = useMemo((): IArrowButtons => {
    return { prevButton: requirementsIndex === 0, nextButton: requirementsIndex === 2 };
  }, [requirementsIndex]);

  const renderRequirementsValue = useMemo((): Array<ReactElement> => {
    return requirementsValues[requirementsIndex].tags.map((item, index) => {
      const valuesArray = requirements[item.type]?.values ?? [];

      return (
        <CheckboxTag
          key={item.label}
          id={`cargo.requirements[${item.type}].values.${index}`}
          name={`cargo.requirements[${item.type}].values.${index}`}
          label={item.label}
          bgColor="blue"
          onChange={(event) => handleCheck(event, valuesArray, item)}
          value=""
          checked={valuesArray.includes(item.label)}
        />
      );
    });
  }, [requirementsIndex, requirements, handleCheck]);

  return (
    <S.WrapperRequirements>
      <S.Requirement columns={columns}>
        <S.ArrowButton disabled={disableButtons.prevButton}>
          <ArrowBackIosIcon onClick={prevTags} />
        </S.ArrowButton>

        {renderRequirementsValue}

        <S.ArrowButton disabled={disableButtons.nextButton}>
          <ArrowForwardIosIcon onClick={nextTags} />
        </S.ArrowButton>
      </S.Requirement>
    </S.WrapperRequirements>
  );
};
