import React from 'react';
import { ScheduleDenyModalView } from './schedule-deny-modal-view.component';

interface IScheduleDenyModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
}

export const ScheduleDenyModal: React.FC<IScheduleDenyModalProps> = (props) => {
  return <ScheduleDenyModalView {...props} />;
};
