import { useCallback, useReducer } from 'react';
import { homeInitialState } from '../../home.constants';
import { IHomeModals, IHomeModalsOptions } from '../../types/home-modals.types';
import { homeModalsReducer } from '../../reducers/home-modals/home-modals.reducer';
import { HomeModalsActionType } from '../../home.action';

export const useHomeModalsContextValues = (): IHomeModals => {
  const [state, dispatch] = useReducer(homeModalsReducer, homeInitialState);

  const setHomeModalsOpen = useCallback((modalOptions: IHomeModalsOptions) => {
    dispatch({ type: HomeModalsActionType.HOME_MODALS_OPEN, payload: modalOptions });
  }, []);

  const setHomeModalsClose = useCallback(() => {
    dispatch({ type: HomeModalsActionType.HOME_MODALS_CLOSE, payload: undefined });
  }, []);

  return {
    ...state.homeModals,
    setHomeModalsOpen,
    setHomeModalsClose,
  };
};
