import { ISaleOfferListDomain } from '../domains/sale-offer-list.domain';

export const saleOfferMatchProposalInitialValues: ISaleOfferListDomain = {
  id: 0,
  idTariff: 0,
  operationType: 'IN',
  initialDate: '',
  origin: {
    id: 0,
    name: '',
    address: {
      city: '',
      uf: '',
    },
  },
  destination: {
    id: 0,
    name: '',
    address: {
      city: '',
      uf: '',
    },
  },
  vehicle: {
    type: '',
    category: '',
  },
  companyId: 0,
  companyImage: '',
  partnerShipper: '',
  shippingName: '',
  valueTotal: '',
  frequency: 0,
  weight: 0,
  cubage: 0,
  pallets: 0,
  cargoType: 'FTL',
  totalMatchOffers: 0,
  isActive: 'ativa',
  companyName: '',
};
