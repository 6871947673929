/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import SelectView from './select-view';

export interface SelectPropsOptions {
  label: string;
  value: any;
  isDisabled?: boolean;
}

type SelectProps = {
  id: string;
  label?: string;
  name: string;
  icon?: React.ReactNode;
  value: any;
  options: SelectPropsOptions[];
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  isClearable?: boolean;
  maxMenuHeight?: number;
  onChange?: () => void;
  hidden?: boolean;
};
const Select: React.FC<SelectProps> = ({
  id,
  name,
  icon,
  options,
  value,
  hasError,
  errorMessage,
  placeholder,
  disabled,
  onChange,
  label,
  setFieldValue,
  isClearable = false,
  maxMenuHeight = 260,
  hidden = false,
}) => {
  return (
    <SelectView
      id={id}
      name={name}
      icon={icon}
      options={options}
      value={value}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      onChange={onChange}
      setFieldValue={setFieldValue}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      hidden={hidden}
    />
  );
};

export default Select;
