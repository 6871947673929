export interface ICheckStubList {
  id: number;
  scheduleId?: number;
  clientName: string;
  originCity?: string;
  distributionCenterName: string;
  checkStubQuantity: number;
  receiptDate: Date;
  deliveryDate: Date;
  status: ICheckStubStatus;
  palletId: number;
}

export type ICheckStubStatus = 'nao_informado' | 'entregue_analista' | 'cliente';

export interface ICheckStub {
  status: ICheckStubStatus;
  receiptDate: string;
  deliveryDate: string;
  withdrawalDate: string;
  entryDate: string;
  checkStubs: Array<ICheckStubs>;
}

export interface ICheckStubs {
  id: number;
  number: number;
}

export interface CheckStubListParams {
  page: number;
  order: string;
  column: string;
  search?: string;
}

export interface ISelectedCheckStubs {
  id: number;
  status: string;
}

export const defaultCheckStubFilter: CheckStubListParams = {
  page: 1,
  order: 'ASC',
  column: 'ID',
};

export const checkStubsInitialValues: ICheckStubs[] = [
  {
    id: 0,
    number: 0,
  },
];
