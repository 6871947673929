import { GrisAdvaloremInput } from 'domain-v2/inputs/gris-advalorem';

export const getGrisAdvaloremValueHook = (data: GrisAdvaloremInput) => {
  const gris = (data.mechValue * data.company.gris) / 100;
  const advalorem = (data.mechValue * data.company.advalorem) / 100;

  const total = gris + advalorem;

  return total;
};
