import styled, { css } from 'styled-components';

export const LeftContainerModal = styled.div`
  ${() => css`
    display: flex;
    margin-top: 17px;
    flex-direction: column;
    padding: 12px;
    height: 93%;
    width: 50%;
    justify-content: space-betwen;
  `}
`;
export const Divider = styled.div`
  ${() => css`
    display: flex;
    border: 1px solid gray
    width: 0.1%;
    height: 100%;
  `}
`;

export const RightContainerModal = styled.div`
  ${() => css`
    border-left: 1px solid gray;
    padding: 20;
    display: flex;
    margin-top: 17px;
    width: 50%;
    height: 94%;
    flex-direction: column;
    padding: 12px;
    justify-content: center;
    align-items: center;
  `}
`;

export const InputContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-container: space-betwen;
  flex-direction: column;
  width: 90%;
  height: 100%;
`;

export const ContainerTextsWrap = styled.div`
  margin-top: 25px;
  display: flex;
  justify-container: flex-start;
  flex-direction: column;
  width: 100%;
  height: 140px;
  align-items: center;
`;

export const FirstBlockText = styled.div`
  margin-top: 20px;
  display: flex;
  justify-container: center;
  flex-direction: column;
  width: 85%;
  align-items: center;
`;

export const SecondBlockText = styled.div`
  margin-top: 20px;
  margin-left: 55px;
  display: flex;
  justify-container: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export const TextContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 8px;
  `}
`;

export const LogSharePro = styled.div`
  margin-top: -35px;
  width: 244px;
  height: 70px;
  background-color: #1f2b4f;
  margin-left: -40px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const LogShareText = styled.div`
  color: #fff;
  font-size: 24px;
  size: 12px;
  font-weight: 600;
`;

export const ProText = styled.div`
  color: #ffb743;
  font-size: 24px;
  size: 12px;
  margin-left: 8px;
  font-weight: 600;
  margin-right: 12px;
`;

export const Text = styled.span`
  font-size: 17px;
  size: 12px;
  margin-left: 8px;
  color: #5d5d5d;

  b {
    color: #1f2b4f;
  }
`;

export const WrapperModalContainerStyled = styled.div`
  z-index: 9999;
  display: ${({ show }: { show: boolean }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  flex: 1;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

export const ModalContainerStyled = styled.div`
  position: relative;
  background: white;
  width: 700px;
  height: 415px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #12264e;
  border-radius: 5px 5px 0px 0px;
  padding: 25px 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
`;

export const ModalHeaderCloseButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  pading: 5px;
  width: 100%;
  height: 30px;
`;
export const ModalHeaderTitleStyled = styled.h3`
  font-size: 17px;
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const ModalHeaderDescriptionStyled = styled.p`
  margin-right: 10px;
  margin-top: 12px;
  height: 30px;
  font-size: 15px;
  cursor: pointer;
`;

export const ModalHeaderWrapperStyled = styled.div``;

export const ModalBodyStyled = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
`;
export const ModalFooterStyled = styled.div``;
