import React, { useCallback, useMemo, useReducer } from 'react';

import { ISchedulesContext } from './schedule.types';
import { ordersReducer } from './schedule.reducer';
import { initialState, ISchedulesAction } from './schedule.action';

import { ICardProps } from 'pages/schedule/schedule-register/components/card/card.types';
import { IResumeInfo } from 'components/resume/resume-info.types';

interface ISchedulesContextProps {
  children: React.ReactNode;
}

export const SchedulesContext = React.createContext<ISchedulesContext>(initialState);

const SchedulesContextProvider: React.FC<ISchedulesContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(ordersReducer, initialState);

  const setResumeRules = useCallback((infoRules: IResumeInfo) => {
    dispatch({
      type: ISchedulesAction.SET_RESUME_RULES,
      payload: infoRules,
    });
  }, []);

  const setCardsList = useCallback((cards: ICardProps[]) => {
    dispatch({
      type: ISchedulesAction.SET_CARDS_LIST,
      payload: cards,
    });
  }, []);

  const value = useMemo(() => {
    return {
      infoRules: state.infoRules,
      cards: state.cards,
      setResumeRules: setResumeRules,
      setCardsList: setCardsList,
    };
  }, [state.infoRules, state.cards, setResumeRules, setCardsList]);

  return <SchedulesContext.Provider value={value}>{children}</SchedulesContext.Provider>;
};

export default React.memo(SchedulesContextProvider);
