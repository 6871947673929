import React, { Fragment, ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled } from './user-list-table.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface IUsersTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo: PageInfo | null;
  changePage: (page: number) => void;
  clickRowAction: (userId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
}

const UserTableView: React.FC<IUsersTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  arrowOrder,
  isLoading,
}: IUsersTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo !== null) {
      return (
        <ContainerPaginationStyled>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <Fragment>
      <Table
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
      />
      {renderPagination}
    </Fragment>
  );
};

export default UserTableView;
