import { useCallback, useContext } from 'react';
import { showToast } from 'components/toast/toast';
import { getUploadFilesService } from 'src-new/pages/registrations/services/get-upload-files/get-upload-files.service';
import { useFormikContext } from 'formik';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';

export const useGetUploadFiles = () => {
  const { setFieldValue } = useFormikContext<RegistrationAttachmentValues>();
  const { attachDocumentsSection } = useContext(RegistrationsContext);
  const { setGetDocumentRequest, setGetDocumentSuccess, setGetDocumentError } =
    attachDocumentsSection.getAttachDocuments;

  return useCallback(
    async (module: string, code: number): Promise<void> => {
      setGetDocumentRequest();

      try {
        const result = await getUploadFilesService(module, code);
        const attachments = await Promise.all(
          result.map(async (file) => {
            const blob = new Blob(['']);
            return {
              id: file.id,
              fileName: file.name,
              fileUrl: file.url,
              file: new File([blob], file.name),
              attachDocumentType: file.typeFile,
              validateDate: file.expirationTime,
            };
          }),
        );

        setFieldValue('attachments', attachments);
        setFieldValue('originalAttachments', attachments);
        setGetDocumentSuccess();
      } catch (error) {
        showToast({
          type: 'error',
          message: 'Ocorreu um erro ao consultar os arquivos',
        });
        setGetDocumentError();
      }
    },
    [setFieldValue, setGetDocumentError, setGetDocumentRequest, setGetDocumentSuccess],
  );
};
