import styled, { css } from 'styled-components';

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerAccessStyled = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const AccessRestrictedStyled = styled.div`
  color: rgb(235 235 235);
  font-weight: 700;
  font-size: 60px;
`;

export const NewFreight = styled.div`
  margin-left: 30px;
`;

export const Category = styled.div`
  ${() => css`
    display: grid;
    grid-row: 1fr 1fr;
    grid-gap: 4px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 54px;
  `}
`;