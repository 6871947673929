import { IdlenessBiddingsFilter } from 'pages-v2/idleness-biddings/services/list-idleness-biddings';

export const defaultIdlenessBiddingFilter: IdlenessBiddingsFilter = {
  page: 1,
  limit: 20,
  search: '',
  column: 'Data da Oferta',
  order: 'ASC',

  initialDate: null,
  operationType: '',
  vehicleType: '',
  vehicleCategory: '',
  originCity: '',
  destinationCity: '',
};
