import React from 'react';

import KanbanTitleView from './kanban-title-view';

import { KanbanTitleProps } from 'pages/task-vision/types/types';

const KanbanTitle: React.FC<KanbanTitleProps> = ({ title, color }) => {
  return <KanbanTitleView title={title} color={color} />;
};

export default KanbanTitle;
