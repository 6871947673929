import { TransactionsListActionType } from '../../transactions.action';
import { ITransactionsListActions } from '../../types/transactions-list.types';
import { ITransactionsContext } from '../../types/transactions.types';

export const transactionsListReducer = (
  state: ITransactionsContext,
  action: ITransactionsListActions,
): ITransactionsContext => {
  switch (action.type) {
    case TransactionsListActionType.TRANSACTIONS_LIST_REQUEST:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          statusRequest: 'PROCESSING',
        },
      };
    case TransactionsListActionType.TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          statusRequest: 'SUCCESS',
          transactions: action.payload,
        },
      };
    case TransactionsListActionType.TRANSACTIONS_LIST_ERROR:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          statusRequest: 'ERROR',
          transactions: [],
        },
      };
    case TransactionsListActionType.TRANSACTIONS_LIST_SET_FILTERS:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          filterActions: {
            ...state.transactionsList.filterActions,
            filters: action.payload,
          },
        },
      };
    case TransactionsListActionType.TRANSACTIONS_LIST_RESET:
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          statusRequest: 'INITIAL',
          transactions: [],
        },
      };
    default:
      return state;
  }
};
