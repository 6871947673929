import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { chatButtonInitialState } from './chat-button.constants';
import { IChatButtonContext } from './types/chat-button.types';
import { chatButtonReducer } from 'src-new/contexts/chat-button/reducers/chat-button.reducer';
import { useChatButtonContextValues } from 'src-new/contexts/chat-button/context-values/chat-button-values.context';

export const ChatButtonContext = createContext<IChatButtonContext>(chatButtonInitialState);

interface IChatButtonContextProps {
  children: ReactNode;
}

export const ChatButtonContextProvider = ({ children }: IChatButtonContextProps) => {
  const [state] = useReducer(chatButtonReducer, chatButtonInitialState);

  const chatButtonContext: IChatButtonContext = useChatButtonContextValues();

  const value: IChatButtonContext = useMemo(
    (): IChatButtonContext => ({
      ...state,
      ...chatButtonContext,
    }),
    [chatButtonContext, state],
  );

  return <ChatButtonContext.Provider value={value}>{children}</ChatButtonContext.Provider>;
};
