import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

import { deleteMDFeDataService } from '../../services/delete-mdfe-data/delete-mdfe-data.service';
import { getMDFeDataService } from '../../services/get-mdfe-data/get-mdfe-data.service';

export const useDeleteMDFeData = () => {
  const { scheduleDetails, scheduleDocuments } = useContext(ScheduleContext);

  return useCallback(async () => {
    try {
      scheduleDocuments.mdfeDataProps.setMDFeDataRequest();

      await deleteMDFeDataService(scheduleDocuments.modalsProps.modalOptions.id);

      const data = await getMDFeDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.mdfeDataProps.setMDFeDataSuccess(data);
    } catch (error: any) {
      scheduleDocuments.mdfeDataProps.setMDFeDataError();
      showMessageFormatted({
        message: error,
        type: 'error',
      });
    }
    scheduleDocuments.modalsProps.setModalOpen({ open: false, id: 0, modalType: '' });
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.mdfeDataProps, scheduleDocuments.modalsProps]);
};
