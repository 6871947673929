import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import { useFormikContext } from 'formik';

import { ContainerStyled, HeaderStyled, LineSectionStyled, LineSeparatorStyled, ModalFormStyled } from './index.style';

import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { ISchedulingKeys } from 'domain/schedule';
import InputFieldTime from 'components/input-time/input-time';
import InputField from 'components/input-field/input-field';
import { InputCalendar } from 'components/input-calendar/input-calendar';

interface IProps {
  schedulingKeys?: ISchedulingKeys[];
}

export const FreightCargoScheduleSectionEditModal: FC<IProps> = ({ schedulingKeys }) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<IAllocationShippingDetails>();

  const [levyInitialDate, setLevyInitialDate] = useState<{
    focused: boolean;
    date: Moment | null;
    setted: boolean;
  }>({ focused: false, date: null, setted: false });

  const [levyEndDate, setLevyEndDate] = useState<{
    focused: boolean;
    date: Moment | null;
    setted: boolean;
  }>({ focused: false, date: null, setted: false });

  const [deliveryInitialDate, setDeliveryInitialDate] = useState<{
    focused: boolean;
    date: Moment | null;
    setted: boolean;
  }>({ focused: false, date: null, setted: false });

  const [deliveryEndDate, setDeliveryEndDate] = useState<{
    focused: boolean;
    date: Moment | null;
    setted: boolean;
  }>({ focused: false, date: null, setted: false });

  useEffect(() => {
    if (values.id !== 0) {
      if (values.levyInitialDate && !levyInitialDate.setted) {
        const testDate = moment(values.levyInitialDate, 'YYYY-MM-DDTHH:mm:ss.SSS');
        const newDate = testDate.isValid()
          ? moment(new Date(values.levyInitialDate), 'YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(new Date(values.levyInitialDate), 'YYYY-MM-DDTHH:mm:ss.SSS');

        setLevyInitialDate({
          focused: false,
          date: moment(newDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
          setted: true,
        });

        setFieldValue('levyInitialTime', newDate.format('HH:mm'))
      }
      if (values.levyEndDate && !levyEndDate.setted) {
        const testDate = moment(values.levyEndDate, 'YYYY-MM-DDTHH:mm:ss.SSS');
        const newDate = testDate.isValid()
          ? moment(new Date(values.levyEndDate), 'YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(new Date(values.levyEndDate), 'YYYY-MM-DDTHH:mm:ss.SSS');

        setLevyEndDate({
          focused: false,
          date: moment(newDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
          setted: true,
        });

        setFieldValue('levyEndTime', newDate.format('HH:mm'))
      }
      if (values.deliveryInitialDate && !deliveryInitialDate.setted) {
        const testDate = moment(values.deliveryInitialDate, 'YYYY-MM-DDTHH:mm:ss.SSS');
        const newDate = testDate.isValid()
          ? moment(new Date(values.deliveryInitialDate), 'YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(new Date(values.deliveryInitialDate), 'YYYY-MM-DDTHH:mm:ss.SSS');

        setDeliveryInitialDate({
          focused: false,
          date: moment(newDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
          setted: true,
        });

        setFieldValue('deliveryInitialTime', newDate.format('HH:mm'))
        
      }
      if (values.deliveryEndDate && !deliveryEndDate.setted) {
        const testDate = moment(values.deliveryEndDate, 'YYYY-MM-DDTHH:mm:ss.SSS');
        const newDate = testDate.isValid()
          ? moment(new Date(values.deliveryEndDate), 'YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(new Date(values.deliveryEndDate), 'YYYY-MM-DDTHH:mm:ss.SSS');

        setDeliveryEndDate({
          focused: false,
          date: moment(newDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
          setted: true,
        });

        setFieldValue('deliveryEndTime', newDate.format('HH:mm'))
      }
    }
  }, [values]);

  useEffect(() => {
    if (!levyInitialDate.date) return;

    const levyInitialDateFormatted = levyInitialDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS');

    setFieldValue(
      'levyInitialDate',
      moment(`${levyInitialDateFormatted} ${values.levyInitialTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
    );
  }, [levyInitialDate, values.levyInitialTime]);

  useEffect(() => {
    if (!levyEndDate.date) return;

    const levyEndDateFormatted = levyEndDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS');

    setFieldValue(
      'levyEndDate',
      moment(`${levyEndDateFormatted} ${values.levyEndTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
    );
  }, [levyEndDate, values.levyEndTime]);

  useEffect(() => {
    if (!deliveryInitialDate.date) return;

    const deliveryInitialDateFormatted = deliveryInitialDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS');

    setFieldValue(
      'deliveryInitialDate',
      moment(`${deliveryInitialDateFormatted} ${values.deliveryInitialTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
    );
  }, [deliveryInitialDate, values.deliveryInitialTime]);

  useEffect(() => {
    if (!deliveryEndDate.date) return;

    const deliveryEndDateFormatted = deliveryEndDate.date.format('YYYY-MM-DDTHH:mm:ss.SSS');

    setFieldValue(
      'deliveryEndDate',
      moment(`${deliveryEndDateFormatted} ${values.deliveryEndTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
    );
  }, [deliveryEndDate, values.deliveryEndTime]);

  useEffect(() => {
    schedulingKeys?.forEach((schedulingKey, index) => {
      setFieldValue(`customFields[${index}].value`, schedulingKey.value || '');

      setFieldValue(`customFields[${index}].customFieldId`, schedulingKey.id || '');

      setFieldValue(
        `customFields[${index}].isRequired`,
        schedulingKey.isRequired || schedulingKey.customField?.isRequired,
      );

      setFieldValue(`customFields[${index}].scheduleId`, schedulingKey.scheduleId ?? 0);
    });
  }, [schedulingKeys]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const customFieldsValues = useMemo(() => {
    return schedulingKeys?.map(({ id, label, isRequired }, index) => {
      const asterisk = isRequired ? '*' : '';

      const inputValue = values.customFields ? values.customFields[index]?.value : '';

      return (
        <InputField
          key={id}
          label={`${label} ${asterisk}`}
          id={`customFields[${index}].value`}
          name={`customFields[${index}].value`}
          type="text"
          onChange={handleChange}
          value={inputValue}
          hasError={!inputValue && isRequired}
          errorMessage={'Campo obrigatório'}
        />
      );
    });
  }, [schedulingKeys, values]);

  return (
    <ModalFormStyled>
      <ContainerStyled>
        <HeaderStyled>
          Data e Hora da Coleta
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCalendar
            label="Data Inicial"
            onDateChange={(date) => {
              setLevyInitialDate((prvState) => {
                return { ...prvState, date };
              });
            }}
            onFocusChange={({ focused }) =>
              setLevyInitialDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={levyInitialDate.focused}
            date={levyInitialDate.date}
            id="levyInitialDate"
            hasError={!!errors.levyInitialDate && !!touched.levyInitialDate}
            errorMessage={String(errors.levyInitialDate)}
          />

          <InputCalendar
            label="Data Final"
            placeholder=""
            onDateChange={(date) =>
              setLevyEndDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setLevyEndDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={levyEndDate.focused}
            date={levyEndDate.date}
            id="levyEndDate"
            hasError={!!errors.levyEndDate && !!touched.levyEndDate}
            errorMessage={String(errors.levyEndDate)}
          />

          <InputFieldTime
            label="Horário Inicial"
            id="levyInitialTime"
            name="levyInitialTime"
            type="text"
            onChange={handleChange}
            value={values.levyInitialTime}
            hasError={!!errors.levyInitialTime && !!touched.levyInitialTime}
            errorMessage={String(errors.levyInitialTime)}
          />
          <InputFieldTime
            label="Horário Final"
            id="levyEndTime"
            name="levyEndTime"
            type="text"
            onChange={handleChange}
            value={values.levyEndTime}
            hasError={!!errors.levyEndTime && !!touched.levyEndTime}
            errorMessage={String(errors.levyEndTime)}
          />
        </LineSectionStyled>

        <HeaderStyled style={{ marginTop: 10 }}>
          Data e Hora da Entrega
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          <InputCalendar
            label="Data Inicial"
            placeholder=""
            onDateChange={(date) => {
              setDeliveryInitialDate((prvState) => {
                return { ...prvState, date };
              });
            }}
            onFocusChange={({ focused }) =>
              setDeliveryInitialDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={deliveryInitialDate.focused}
            date={deliveryInitialDate.date}
            id="deliveryInitialDate"
            hasError={!!errors.deliveryInitialDate && !!touched.deliveryInitialDate}
            errorMessage={String(errors.deliveryInitialDate)}
          />

          <InputCalendar
            label="Data Final"
            placeholder=""
            onDateChange={(date) =>
              setDeliveryEndDate((prvState) => {
                return { ...prvState, date };
              })
            }
            onFocusChange={({ focused }) =>
              setDeliveryEndDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={deliveryEndDate.focused}
            date={deliveryEndDate.date}
            id="deliveryEndDate"
            hasError={!!errors.deliveryEndDate && !!touched.deliveryEndDate}
            errorMessage={String(errors.deliveryEndDate)}
          />

          <InputFieldTime
            label="Horário Inicial"
            id="deliveryInitialTime"
            name="deliveryInitialTime"
            type="text"
            onChange={handleChange}
            value={values.deliveryInitialTime}
            hasError={!!errors.deliveryInitialTime && !!touched.deliveryInitialTime}
            errorMessage={String(errors.deliveryInitialTime)}
          />

          <InputFieldTime
            label="Horário Final"
            id="deliveryEndTime"
            name="deliveryEndTime"
            type="text"
            onChange={handleChange}
            value={values.deliveryEndTime}
            hasError={!!errors.deliveryEndTime && !!touched.deliveryEndTime}
            errorMessage={String(errors.deliveryEndTime)}
          />
        </LineSectionStyled>

        <HeaderStyled style={{ marginTop: 10 }}>
          Informações Adicionais
          <LineSeparatorStyled />
        </HeaderStyled>
        <LineSectionStyled columns="1fr 1fr 1fr 1fr">
          {schedulingKeys && schedulingKeys.length > 0 ? (
            customFieldsValues
          ) : (
            <>
              <InputField
                label="Nº Interno"
                id="internalNumber"
                name="internalNumber"
                type="text"
                onChange={handleChange}
                value={values?.internalNumber}
              />

              <InputField
                label="Nº OC Interna"
                id="ocInternalNumber"
                name="ocInternalNumber"
                type="text"
                onChange={handleChange}
                value={values?.ocInternalNumber}
              />

              <InputField
                label="Nº Embarque"
                id="boardingNumber"
                name="boardingNumber"
                type="text"
                onChange={handleChange}
                value={values?.boardingNumber}
              />

              <InputField
                label="Senha"
                id="schedulingPassword"
                name="schedulingPassword"
                type="text"
                onChange={handleChange}
                value={values?.schedulingPassword}
              />
            </>
          )}
        </LineSectionStyled>
        <LineSectionStyled columns="1fr">
          <InputField
            label="Observações Gerais"
            id="comments"
            name="comments"
            type="text"
            onChange={handleChange}
            value={values?.comments}
          />
        </LineSectionStyled>
      </ContainerStyled>
    </ModalFormStyled>
  );
};
