import { useNavigate } from 'react-router-dom';

import { IAcceptedMatchesCreatedV2 } from 'domain/accepted-matches';

export const useCustomNavigation = () => {
  const navigate = useNavigate();

  const createClientOrigin = (value: IAcceptedMatchesCreatedV2) => {
    const clientOrigin = { ...value.clientOrigin };
    return {
      type: 'CLIENT',
      id: clientOrigin?.id,
      name: clientOrigin?.tradeName,
      cep: clientOrigin?.cep,
      neighborhood: clientOrigin?.neighborhood,
      city: clientOrigin?.city,
      uf: clientOrigin?.uf,
      street: clientOrigin?.street,
      numberHouse: clientOrigin?.numberHouse,
      complement: clientOrigin?.complement,
    };
  };

  const createLocalityOrigin = (value: IAcceptedMatchesCreatedV2) => {
    const localityOrigin = { ...value.localityOrigin };
    return {
      type: 'LOCALITY',
      id: localityOrigin?.id,
      name: localityOrigin?.name,
      cep: localityOrigin?.cep,
      neighborhood: localityOrigin?.neighborhood,
      city: localityOrigin?.city,
      uf: localityOrigin?.uf,
      street: localityOrigin?.street,
      numberHouse: localityOrigin?.numberHouse,
      complement: localityOrigin?.complement,
    };
  };

  const createClientDestination = (value: IAcceptedMatchesCreatedV2) => {
    const clientDestination = { ...value.clientDestination };
    return {
      type: 'CLIENT',
      id: clientDestination?.id,
      name: clientDestination?.tradeName,
      cep: clientDestination?.cep,
      neighborhood: clientDestination?.neighborhood,
      city: clientDestination?.city,
      uf: clientDestination?.uf,
      street: clientDestination?.street,
      numberHouse: clientDestination?.numberHouse,
      complement: clientDestination?.complement,
    };
  };
  const createLocalityDestination = (value: IAcceptedMatchesCreatedV2) => {
    const localityDestination = { ...value.localityDestination };
    return {
      type: 'LOCALITY',
      id: localityDestination?.id,
      name: localityDestination?.name,
      cep: localityDestination?.cep,
      neighborhood: localityDestination?.neighborhood,
      city: localityDestination?.city,
      uf: localityDestination?.uf,
      street: localityDestination?.street,
      numberHouse: localityDestination?.numberHouse,
      complement: localityDestination?.complement,
    };
  };

  const createLocation = (value: IAcceptedMatchesCreatedV2, isOrigin: boolean) => {
    if (isOrigin) {
      return value.clientOrigin?.id ? createClientOrigin(value) : createLocalityOrigin(value);
    }

    if (!isOrigin) {
      return value.clientDestination?.id ? createClientDestination(value) : createLocalityDestination(value);
    }
  };

  const handleCreateSchedule = (scheduleURL: string, value: IAcceptedMatchesCreatedV2) => {
    const origin = createLocation(value, true);
    const destination = createLocation(value, false);

    navigate(scheduleURL, {
      state: {
        origin,
        destination,
      },
    });
  };

  return {
    handleCreateSchedule,
  };
};
