import { useCallback, useReducer } from 'react';
import { ISaleOfferMatchListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list-filters.domain';
import { saleOfferMatchListReducer } from '../../reducers/sale-offer-match-list/sale-offer-match-list.reducer';
import {
  SaleOfferMatchListActionType,
  SaleOfferMatchListSelectedActionType,
  SaleOfferMatchProposalActionType,
} from '../../sale-offer.action';
import { saleOfferInitialState } from '../../sale-offer.constants';
import { ISaleOfferMatchList } from '../../types/sale-offer-match-list.types';
import { ISaleOfferListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list.domain';
import { ISaleOfferMatchListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list.domain';
import { saleOfferMatchListFilterInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-match-list-filters-initial-values.constant';

export const useSaleOfferMatchListContextValues = (): ISaleOfferMatchList => {
  const [state, dispatch] = useReducer(saleOfferMatchListReducer, saleOfferInitialState);

  const setSaleOfferMatchListRequest = useCallback(() => {
    dispatch({ type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferMatchListSuccess = useCallback((list: Array<ISaleOfferMatchListDomain>) => {
    dispatch({ type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_SUCCESS, payload: list });
  }, []);

  const setSaleOfferMatchListError = useCallback(() => {
    dispatch({ type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_ERROR, payload: undefined });
  }, []);

  const setSaleOfferMatchListReset = useCallback(() => {
    dispatch({ type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_LIST_RESET, payload: undefined });
  }, []);

  const setSaleOfferMatchProposalRequest = useCallback(() => {
    dispatch({ type: SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferMatchProposalSuccess = useCallback((proposal: ISaleOfferListDomain) => {
    dispatch({ type: SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_SUCCESS, payload: proposal });
  }, []);

  const setSaleOfferMatchProposalError = useCallback(() => {
    dispatch({ type: SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_ERROR, payload: undefined });
  }, []);

  const setSaleOfferMatchProposalReset = useCallback(() => {
    dispatch({ type: SaleOfferMatchProposalActionType.SALE_OFFER_MATCH_PROPOSAL_RESET, payload: undefined });
  }, []);

  const setSaleOfferMatchListSelectedRequest = useCallback(() => {
    dispatch({ type: SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_REQUEST, payload: undefined });
  }, []);

  const setSaleOfferMatchListSelectedSuccess = useCallback((match: ISaleOfferMatchListDomain) => {
    dispatch({ type: SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_SUCCESS, payload: match });
  }, []);

  const setSaleOfferMatchListSelectedError = useCallback(() => {
    dispatch({ type: SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_ERROR, payload: undefined });
  }, []);

  const setSaleOfferMatchListSelectedReset = useCallback(() => {
    dispatch({ type: SaleOfferMatchListSelectedActionType.SALE_OFFER_LIST_SELECTED_RESET, payload: undefined });
  }, []);

  const setSelectFilters = useCallback((filters: ISaleOfferMatchListFilterDomain) => {
    dispatch({ type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_SELECT_FILTERS, payload: filters });
  }, []);

  const setResetFilters = useCallback(() => {
    dispatch({
      type: SaleOfferMatchListActionType.SALE_OFFER_MATCH_SELECT_FILTERS,
      payload: saleOfferMatchListFilterInitialValues,
    });
  }, []);

  return {
    ...state.saleOfferMatchList,
    setSaleOfferMatchListRequest,
    setSaleOfferMatchListSuccess,
    setSaleOfferMatchListError,
    setSaleOfferMatchListReset,
    saleOfferMatchProposal: {
      ...state.saleOfferMatchList.saleOfferMatchProposal,
      setSaleOfferMatchProposalRequest,
      setSaleOfferMatchProposalSuccess,
      setSaleOfferMatchProposalError,
      setSaleOfferMatchProposalReset,
    },
    saleOfferMatchListSelected: {
      ...state.saleOfferMatchList.saleOfferMatchListSelected,
      setSaleOfferMatchListSelectedRequest,
      setSaleOfferMatchListSelectedSuccess,
      setSaleOfferMatchListSelectedError,
      setSaleOfferMatchListSelectedReset,
    },
    filters: {
      ...state.saleOfferMatchList.filters,
      setSelectFilters,
      setResetFilters,
    },
  };
};
