import API from 'utils/API/API';
import { IScheduleDetailsAllocationDriverDomain } from '../../domains/schedule-details-allocation-driver.domain';

export const getDriverHasLinkWithVehicleService = async (
  vehicleId: number,
): Promise<IScheduleDetailsAllocationDriverDomain | undefined> => {
  const { data } = await API.get(`v2/vehicles/${vehicleId}`);

  if (!data?.driver || !data?.driver.id) return undefined;

  return {
    id: data.driver.id,
    cpf: data.driver.cpf,
    name: data.driver.name,
    telephone: data.driver.telephone,
  };
};
