import { FC, useCallback, useContext, useMemo } from 'react';
import { ScheduleDetailsResumeFinanceView } from './schedule-details-resume-finance-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { convertDecimalCurrencyFormat } from 'utils-v2/converters/decimal';
import { UserContext } from 'state/user-context';
import { IFinanceObjects } from './schedule-details-resume-finance.types';

export const ScheduleDetailsResumeFinance: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { user, isShippingCompany } = useContext(UserContext);

  const convertCurrencyFormatToCurrency = useCallback((currency?: number) => {
    if (currency) return convertDecimalCurrencyFormat.format(currency);

    return convertDecimalCurrencyFormat.format(0);
  }, []);

  const shippingCompanyId = useMemo((): number => user?.shippingCompanyId ?? 0, [user?.shippingCompanyId]);

  const userCompanyId = useMemo((): number => user?.companyId ?? 0, [user?.companyId]);

  const verifyFinanceObjects = useMemo((): IFinanceObjects => {
    const { invoicedBy, shippingId, operation, company, freithgtPayable, freithgtReceivable, freithgtShipping } =
      scheduleDetails.scheduleDetails;

    if (isShippingCompany) {
      const freightShippingView = !!freithgtShipping?.id;

      return { freightPayableView: false, freightReceivableView: false, freightShippingView };
    }

    if (
      (invoicedBy === 'EMBARCADOR' && operation !== 'TRACKING') ||
      (shippingId === shippingCompanyId && (operation === 'SPOT' || invoicedBy === 'LOGSHARE'))
    ) {
      const freightShippingView = !!freithgtShipping?.id;

      return { freightPayableView: false, freightReceivableView: false, freightShippingView };
    }

    if (
      invoicedBy === 'LOGSHARE' &&
      company.id === userCompanyId &&
      (operation === 'BACKHAUL EXTERNO' || operation === 'BACKHAUL PARCEIRO')
    ) {
      const freightReceivableView = !!freithgtReceivable?.id;
      const freightShippingView = !!freithgtShipping?.id;

      return { freightPayableView: false, freightReceivableView, freightShippingView };
    }

    if (operation !== 'TRACKING') {
      const freightPayableView = !!freithgtPayable?.id;

      return { freightPayableView, freightReceivableView: false, freightShippingView: false };
    }

    const freightPayableView = !!freithgtPayable?.id;
    const freightReceivableView = !!freithgtReceivable?.id;
    const freightShippingView = !!freithgtShipping?.id;

    return { freightPayableView, freightReceivableView, freightShippingView };
  }, [isShippingCompany, scheduleDetails.scheduleDetails, shippingCompanyId, userCompanyId]);

  return (
    <ScheduleDetailsResumeFinanceView
      freightPayable={scheduleDetails.scheduleDetails.freithgtPayable}
      freightReceivable={scheduleDetails.scheduleDetails.freithgtReceivable}
      freightShipping={scheduleDetails.scheduleDetails.freithgtShipping}
      verifyFinanceObjects={verifyFinanceObjects}
      schedule={scheduleDetails.scheduleDetails}
      shippingCompanyId={shippingCompanyId}
      userCompanyId={userCompanyId}
      isShippingCompany={isShippingCompany}
      convertCurrencyFormatToCurrency={convertCurrencyFormatToCurrency}
    />
  );
};
