export enum EquipmentVoucherListActionType {
  EQUIPMENT_VOUCHER_LIST_REQUEST = 'EQUIPMENT_VOUCHER_LIST_REQUEST',
  EQUIPMENT_VOUCHER_LIST_SUCCESS = 'EQUIPMENT_VOUCHER_LIST_SUCCESS',
  EQUIPMENT_VOUCHER_LIST_ERROR = 'EQUIPMENT_VOUCHER_LIST_ERROR',
  EQUIPMENT_VOUCHER_LIST_SET_IDS = 'EQUIPMENT_VOUCHER_LIST_SET_IDS',
  EQUIPMENT_VOUCHER_LIST_OPEN_MODAL = 'EQUIPMENT_VOUCHER_LIST_OPEN_MODAL',
  EQUIPMENT_VOUCHER_LIST_CLOSE_MODAL = 'EQUIPMENT_VOUCHER_LIST_CLOSE_MODAL',
  EQUIPMENT_VOUCHER_LIST_SET_LOADING_MODAL = 'EQUIPMENT_VOUCHER_LIST_SET_LOADING_MODAL',
  EQUIPMENT_VOUCHER_LIST_RESET = 'EQUIPMENT_VOUCHER_LIST_RESET',
}

export enum EquipmentVoucherActionType {
  EQUIPMENT_VOUCHER_RESET = 'EQUIPMENT_VOUCHER_RESET',
}
