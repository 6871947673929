import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { Formik } from 'formik';
import CreateIcon from '@mui/icons-material/Create';

import * as S from './styled';
import { FreightCargoScheduleSectionEditModal } from './modal-form';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ColorsV2Type } from 'styles/types/colors';
import { updateCommentsScheduleService } from 'pages-v2/schedule/services/update-schedule';
import { updateCustomFields } from 'pages-v2/schedule/services/update-custom-fields';
import { createScheduleCustomField } from 'pages-v2/schedule/services/custom-fields';
import { useUpdateScheduleHoursHook } from 'pages/freight/hooks/schedule-details-hooks/use-schedule-details-update/use-schedule-details-update.hook';
import { useScheduleDetailsHook } from 'pages/freight/hooks/schedule-details-hooks/use-schedule-details-id/use-schedule-details-id.hook';
import { ISchedulingKeys, ScheduleDetails, ScheduleDomain } from 'domain/schedule';
import { showToast } from 'components/toast/toast';
import { ModalForm } from 'components/modal-form/modal-form';
import Button from 'components/button/button';

interface IProps {
  values: ScheduleDomain;
  schedulingKeys?: ISchedulingKeys[];
  onRefresh: React.Dispatch<React.SetStateAction<number>>;
}

const DetailsSchedule: React.FC<IProps> = ({ values, schedulingKeys, onRefresh }) => {
  const initialValues = {} as ScheduleDetails;

  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails>(initialValues);

  const [modalForm, setModalForm] = useState<boolean>(false);

  const scheduleDetailsHook = useScheduleDetailsHook();

  const scheduleDetailsUpdateHook = useUpdateScheduleHoursHook();

  useEffect(() => {
    (async () => {
      try {
        if (values.scheduleDetails?.id) {
          const findValues = await scheduleDetailsHook(values.scheduleDetails?.id);
          setScheduleDetails({
            ...findValues,
            comments: values.comments || undefined,
          } as any);
        }
      } catch (err) {
        showToast({
          message: 'Houve um erro ao buscar os detalhes',
          type: 'error',
        });
      }
    })();
  }, [values.scheduleDetails?.id]);

  const handleUpdateCargoDetails = useCallback(
    async (scheduleDetails: ScheduleDetails) => {
      const fieldsValidation = scheduleDetails?.customFields?.every((customField) => {
        if (customField.isRequired) {
          return (
            Object.prototype.hasOwnProperty.call(customField, 'value') &&
            customField.value !== undefined &&
            customField.value !== ''
          );
        }
        return true;
      });

      if (scheduleDetails?.customFields && !fieldsValidation) {
        showMessageFormatted({
          message: 'Preencha todos os campos obrigatórios',
          type: 'error',
        });
      } else {
        try {
          if (scheduleDetails.comments) {
            await updateCommentsScheduleService(values.id, scheduleDetails.comments);
          }
          await scheduleDetailsUpdateHook(values.scheduleDetails?.id, scheduleDetails);

          if (scheduleDetails?.customFields) {
            if (scheduleDetails.customFields[0].scheduleId !== 0) {
              await updateCustomFields(values.id, scheduleDetails.customFields);
            } else {
              await createScheduleCustomField(scheduleDetails.customFields, values.id);
            }
          }

          showMessageFormatted({
            message: 'Carga atualizada com sucesso.',
            type: 'success',
          });

          onRefresh((prev) => prev + 1);

          setScheduleDetails(scheduleDetails);

          setModalForm(false);
        } catch (error: any) {
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          });
        }
      }
    },
    [onRefresh, scheduleDetailsUpdateHook, values.id, values.scheduleDetails?.id],
  );

  const customFieldsValues = useMemo(() => {
    return schedulingKeys?.map(({ id, value, label }) => {
      return (
        <S.InfoTitle key={id}>
          {label}
          <S.Info>{value || '-'}</S.Info>
        </S.InfoTitle>
      );
    });
  }, [schedulingKeys]);

  const renderTags = useMemo(() => {
    return (
      <S.HeaderTags>
        {values?.requirements
          ?.filter((requirement: any) => requirement.type === 'quality')
          .map((filteredRequirement: any) =>
            filteredRequirement.values.map((value: any) => (
              <Tag
                key={value}
                label={value}
                color={ColorsV2Type.Blue}
                variant="solid"
                display="inline-flex"
                size="md"
              />
            )),
          )}

        {values?.requirements
          ?.filter((requirement: any) => requirement.type === 'security')
          .map((filteredRequirement: any) =>
            filteredRequirement.values.map((value: any) => (
              <Tag
                key={value}
                label={value}
                color={ColorsV2Type.Blue}
                variant="solid"
                display="inline-flex"
                size="md"
              />
            )),
          )}
      </S.HeaderTags>
    );
  }, [values?.requirements]);

  return (
    <>
      {modalForm && (
        <Formik initialValues={scheduleDetails} onSubmit={handleUpdateCargoDetails}>
          {(formikHelpers) => (
            <ModalForm
              title="Dados do Agendamento"
              description={<FreightCargoScheduleSectionEditModal schedulingKeys={schedulingKeys} />}
              onConfirmation={() => formikHelpers.handleSubmit()}
              handleCancel={() => {
                formikHelpers.resetForm();
                setModalForm(false);
              }}
              size="medium"
              legacy
            />
          )}
        </Formik>
      )}
      <S.Wrapper>
        <S.HeaderTitle isTraced display="flex" justify="space-between">
          Dados do Agendamento
          <Button
            title="Editar"
            size="very-small"
            icon={<CreateIcon />}
            callback={() => setModalForm(true)}
            bgColor="white"
          />
        </S.HeaderTitle>

        <S.SectionContainer display="grid" columns="1fr 1fr 1fr 1fr">
          <S.InfoTitle>
            Data de Coleta
            <S.Info>
              {!scheduleDetails?.levyInitialDate ? '-' : moment(scheduleDetails?.levyInitialDate).format('DD/MM/yyyy')}
            </S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Horário Programado
            <S.Info>
              {moment(scheduleDetails?.levyInitialDate).format('HH:mm')}
              {' - '}
              {moment(scheduleDetails?.levyEndDate).format('HH:mm')}
            </S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Data de Entrega
            <S.Info>
              {!scheduleDetails?.deliveryInitialDate
                ? '-'
                : moment(scheduleDetails?.deliveryInitialDate).format('DD/MM/yyyy')}
            </S.Info>
          </S.InfoTitle>

          <S.InfoTitle>
            Horário Programado
            <S.Info>
              {moment(scheduleDetails?.deliveryInitialDate).format('HH:mm')}
              {' - '}
              {moment(scheduleDetails?.deliveryEndDate).format('HH:mm')}
            </S.Info>
          </S.InfoTitle>
        </S.SectionContainer>

        <S.SectionContainer display="grid" columns="1fr 1fr 1fr 1fr">
          {schedulingKeys && schedulingKeys.length > 0 ? (
            customFieldsValues
          ) : (
            <>
              <S.InfoTitle>
                N° Embarque
                <S.Info>{scheduleDetails?.boardingNumber || '-'}</S.Info>
              </S.InfoTitle>

              <S.InfoTitle>
                N° OC
                <S.Info>{scheduleDetails?.ocInternalNumber || '-'}</S.Info>
              </S.InfoTitle>

              <S.InfoTitle>
                N° Interno
                <S.Info>{scheduleDetails?.internalNumber || '-'}</S.Info>
              </S.InfoTitle>

              <S.InfoTitle>
                Senha
                <S.Info>{scheduleDetails?.schedulingPassword || '-'}</S.Info>
              </S.InfoTitle>
            </>
          )}
        </S.SectionContainer>

        <S.Line />

        <S.SectionContainer display="grid" columns="1fr">
          <S.InfoTitle>
            Observações Gerais
            {renderTags}
            <S.Info>{values.comments || '-'}</S.Info>
          </S.InfoTitle>
        </S.SectionContainer>
      </S.Wrapper>
    </>
  );
};

export default React.memo(DetailsSchedule);
