import styled, { css } from 'styled-components';

export const WrapperRequirementsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
`;

export const LabelRequirementsStyled = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: rgb(3, 5, 23);
  background-color: white;
  cursor: pointer;
`;

type CustomLineSectionStyled = { columns: string };
export const CustomLineSectionStyled = styled.section<CustomLineSectionStyled>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 15px 20px;
  `}
`;
