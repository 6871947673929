import React, { useContext } from 'react';
import { ScheduleContextProvider } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { SchedulePageBody } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/components/schedule-page-body/schedule-page-body.component';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';

export const ScheduleListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <ScheduleContextProvider>
        <SchedulePageBody />
      </ScheduleContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
