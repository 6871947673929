import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IHomeMatchesResponse } from './get-home-matches.types';
import { convertHomeMatchesResponseToDomain } from './mappers/convert-home-matches-response-to-domain/convert-home-matches-response-to-domain.mapper';
import { IHomeMatchesDomain } from '../../domains/home-matches/home-matches.domain';

export const getHomeMatchesService = async (page: number): Promise<Array<IHomeMatchesDomain>> => {
  const { data } = await API_TRAVELS_SVC.get<IListPage<IHomeMatchesResponse>>(
    `v1/matchs/perfect?page=${page}&limit=30`,
  );

  return data.items.map(convertHomeMatchesResponseToDomain);
};
