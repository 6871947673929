import { useCallback, useReducer } from 'react';
import { AttachDocumentsSectionActionType } from '../../registrations.action';
import { registrationsInitialState } from '../../registrations.constants';
import { IAttachDocumentsSection } from '../../types/attach-documents-section.types';
import { attachDocumentsSectionReducer } from 'src-new/pages/registrations/contexts/registrations/reducers/attach-documents-section/attach-documents-section.reducer';

export const useAttachDocumentsSectionContextValues = (): IAttachDocumentsSection => {
  const [state, dispatch] = useReducer(attachDocumentsSectionReducer, registrationsInitialState);

  const setGetDocumentRequest = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_REQUEST, payload: undefined });
  }, []);

  const setGetDocumentSuccess = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_SUCCESS, payload: undefined });
  }, []);

  const setGetDocumentError = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.GET_ATTACH_DOCUMENTS_ERROR, payload: undefined });
  }, []);

  const setModalOpen = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_MODAL_OPEN, payload: undefined });
  }, []);

  const setModalClose = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_MODAL_CLOSE, payload: undefined });
  }, []);

  const setAttachDocumentsSectionReset = useCallback(() => {
    dispatch({ type: AttachDocumentsSectionActionType.ATTACH_DOCUMENTS_SECTION_RESET, payload: undefined });
  }, []);

  return {
    ...state.attachDocumentsSection,
    getAttachDocuments: {
      ...state.attachDocumentsSection.getAttachDocuments,
      setGetDocumentRequest,
      setGetDocumentSuccess,
      setGetDocumentError,
    },
    setModalOpen,
    setModalClose,
    setAttachDocumentsSectionReset,
  };
};
