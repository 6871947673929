import { ILoginEntity, LoginResponse } from './entities/login.entity';

import API from 'utils/API/API';

export const login = async ({ username, password, recaptchaLogin }: ILoginEntity): Promise<LoginResponse> => {
  const { data } = await API.post('auth/login', {
    username,
    password,
    recaptchaLogin,
  });
  return {
    access_token: data.access_token,
    refresh_token: data.refresh_token,
  };
};
