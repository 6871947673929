import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { ICardProps } from './types';
import {
  CardDetailsOnLeftStyled,
  ContainerCardDetailsStyled,
  ContainerDateStyled,
  ContainerStyled,
  SubNameStyled,
  TariffCompanyStyled,
  TextTariffStyled,
} from './styled';

import TooltipComponent from 'components/Tooltip';

interface IProps {
  cards: ICardProps[];
}

export const Card: React.FC<IProps> = ({ cards }) => {
  const [checked, setChecked] = useState<{
    index: number;
    isOpen: boolean;
  }>();

  const changeCheck = useCallback((index: number, onClickAction: () => void = () => {}) => {
    const updatedChecked = {
      index,
      isOpen: true,
    };

    onClickAction();
    setChecked(updatedChecked);
  }, []);

  const isChecked = useCallback(
    (card: ICardProps, index: number): boolean => (index === checked?.index || card.checked) && !card.disabled,
    [checked?.index],
  );

  const renderCards = useMemo((): Array<ReactElement> => {
    return cards.map((card, index) => {
      if (card.isLoading) {
        return (
          <div key={card.id}>
            <Skeleton variant="rectangular" height={70} width={240} sx={{ borderRadius: '4px' }} />
          </div>
        );
      }
      if (card.disabled && index === checked?.index) {
        setChecked({ index: 0, isOpen: true });
      }
      return (
        <CardDetailsOnLeftStyled
          key={card.id}
          checked={isChecked(card, index)}
          onClick={!card.disabled ? () => changeCheck(index, card.onClickAction) : undefined}
          disabled={card.disabled}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <TariffCompanyStyled>
              <TextTariffStyled>
                Frete
                <SubNameStyled> {card.name}</SubNameStyled>
              </TextTariffStyled>
              <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                {!!card.tag && card.tag}
                {card.icon}
                {card.tooltipInfo && (
                  <TooltipComponent
                    title={card.tooltipInfo}
                    location="top"
                    icon={
                      <div style={{ display: 'flex' }}>
                        <InfoIcon
                          sx={{
                            height: '15px',
                            width: '15px',
                            color: isChecked(card, index) ? 'white' : 'gray',
                          }}
                        />
                      </div>
                    }
                  />
                )}
              </div>
            </TariffCompanyStyled>
            <ContainerDateStyled>
              {card.dateOf && <TextTariffStyled>{card.dateOf}</TextTariffStyled>}
              <TextTariffStyled>{card.tariffValue}</TextTariffStyled>
            </ContainerDateStyled>
          </div>
        </CardDetailsOnLeftStyled>
      );
    });
  }, [cards, changeCheck, checked?.index, isChecked]);

  return (
    <ContainerStyled>
      <ContainerCardDetailsStyled>{renderCards}</ContainerCardDetailsStyled>
    </ContainerStyled>
  );
};
