export const capitalize = (str: string) => {
  if (!str) {
    return '';
  }

  const words = str.toLowerCase().split(' ');

  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const toUpperCase = (str: string) => {
  if (!str) {
    return '';
  }
  const words = str.toLowerCase().split(' ');

  return words.map((word) => {
    return word.toUpperCase();
  });
};
