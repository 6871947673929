import axios, { AxiosError } from 'axios';
import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';
import { IConversationByIdResponse } from 'src-new/services/chat/get-conversation-chat-by-id/get-conversation-chat-by-id.types';

export const getConversationChatByIdService = async (
  conversationId: string,
): Promise<IConversationByIdResponse | undefined> => {
  const chatEnvironment = getChatEnvironment();

  try {
    const { data } = await axios.get<IConversationByIdResponse>(
      `https://api.talkjs.com/v1/${chatEnvironment.appId}/conversations/${conversationId}`,
      {
        headers: {
          Authorization: `Bearer ${chatEnvironment.secretKey}`,
        },
      },
    );

    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw error;
    }
  }
};
