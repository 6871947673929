import { Formik } from 'formik';
import { FC, useCallback, useContext } from 'react';
import { IWeighingDomain } from '../../domains/weighing-details.domain';
import { WeighingUpdateFormView } from './weighing-update-form-view.component';
import { useNavigate } from 'react-router-dom';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { useUpdateWeighing } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/hooks/use-weighing-update/use-weighing-update.hook';

export const WeighingUpdateForm: FC = () => {
  const { weighingDetails } = useContext(WeighingContext);

  const navigate = useNavigate();
  const weighingUpdateHook = useUpdateWeighing();

  const handleSubmit = useCallback(
    async (values: IWeighingDomain) => {
      await weighingUpdateHook(values);
      navigate(-1);
    },
    [navigate, weighingUpdateHook],
  );

  return (
    <Formik initialValues={weighingDetails.weighingDetails} onSubmit={handleSubmit}>
      <WeighingUpdateFormView />
    </Formik>
  );
};
