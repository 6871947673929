import React from 'react';

import * as S from './styled';

interface TabsProps {
  contents: Array<string>;
  isPressed: string;
  setIsPressed: (value: string) => void;
}

export const BigTabs: React.FC<TabsProps> = ({ contents, isPressed, setIsPressed }) => {
  const renderInfos = contents.map((item, index) => {
    return (
      <S.ContainerTabs key={index} onClick={() => setIsPressed(item)} isPressed={isPressed === item}>
        <S.LabelTabs isPressed={isPressed === item}>
          <span>{item.split(' ')[0]}</span> {item.split(' ').slice(1).join(' ')}
        </S.LabelTabs>
      </S.ContainerTabs>
    );
  });

  return <S.WrapperTabs>{renderInfos}</S.WrapperTabs>;
};
