export const headerValuesCalculation = (
  invoice: any,
  additionalValue: number | string,
  discountValues: number | string,
) => {
  const totalServiceSum = invoice
    .filter((item: any) => item.calcFinance.totalService)
    .reduce((sum: any, item: any) => sum + item.calcFinance.totalService, 0);

  const addition = Number(additionalValue);

  const discount = Number(discountValues);

  let total = totalServiceSum + addition - discount;

  if (isNaN(total)) {
    total = totalServiceSum ?? 0;
  }

  return { addition, discount, total };
};
