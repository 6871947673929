import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { ContainerSearchStyled, NewFreight } from './vehicle-list-body-view.styled';
import VehicleTable from './components/vehicle-table/vehicle-table';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ColorsV2Type } from 'styles/types/colors';
import { UserContext } from 'state/user-context';
import { IVehicleCreated } from 'domain/vehicle';
import { ReportOption } from 'domain/report-files';
import { PageInfo } from 'domain/page-info';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Input from 'components/input/input';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import DropDown from 'components/dropdown/dropdown';
import ContentExportReport from 'components/content-export-report/content-export-report';
import Checkbox from 'components/checkbox/checkbox';
import TooltipComponent from 'components/Tooltip';
import { ModalState, initialModalState } from 'domain-v2/inputs/modals';

interface IVehicleListBodyViewProps {
  vehicles: Array<IVehicleCreated>;
  vehiclesPageInfo?: PageInfo;
  hasLoading: boolean;
  goToCreateVehicle: () => void;
  goToUpdatePage: (vehicleId: string) => void;
  setCurrentPage: (page: number) => void;
  setSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  handleOpenUploadXlsx: () => void;
  onHandleExport: (value: ReportOption) => void;
  options: Array<ReportOption>;
  handleOrderList: (value: ReportOption) => void;
  OptionsOrder: Array<ReportOption>;
  idsToRemove: number[];
  setIdsToRemove: React.Dispatch<React.SetStateAction<number[]>>;
  handleRemoveVehicle: (ids: number[]) => void;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const dataTestId = 'vehicle-list-body-view';

const VehicleListBodyView: React.FC<IVehicleListBodyViewProps> = ({
  vehicles,
  vehiclesPageInfo,
  hasLoading,
  goToCreateVehicle,
  goToUpdatePage,
  setCurrentPage,
  setSearchInput,
  setActiveTabName,
  handleOpenUploadXlsx,
  onHandleExport,
  options,
  idsToRemove,
  setIdsToRemove,
  handleRemoveVehicle,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}: IVehicleListBodyViewProps): JSX.Element => {
  const { isAdmin, isShippingCompany } = useContext(UserContext);
  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  const contentTable = useMemo((): Array<IContentTable> => {
    return vehicles.map((vehicle) => {
      return [
        {
          value: (
            <Checkbox
              id="vehicle.id"
              name="vehicle.id"
              value={String(vehicle.id)}
              onChange={(e) =>
                e.target.checked
                  ? setIdsToRemove((prv) => [...prv, vehicle.id])
                  : setIdsToRemove((prv) => [...prv.filter((id) => id !== vehicle.id)])
              }
            />
          ),
          flex: 0.2,
        },
        {
          value: vehicle.id,
          flex: 0.5,
        },
        {
          value: isAdmin ? vehicle.company?.tradeName : ' ',
          flex: isAdmin ? 0.8 : 0,
        },
        {
          value: vehicle.vehicleType,
          flex: 1,
        },
        {
          value: vehicle.mainBoard,
          flex: 0.8,
        },
        {
          value: vehicle.secondBoard,
          flex: 0.8,
        },
        {
          value: vehicle.thirdBoard,
          flex: 0.8,
        },
        {
          value: vehicle.fleetType,
          flex: 1,
        },
        {
          value: vehicle.shippingCompany?.name || '-',
          flex: 2,
        },
        {
          value: (
            <Tag
              label={vehicle.status}
              color={vehicle.status === 'ativo' ? ColorsV2Type.Green : ColorsV2Type.Red}
              variant="solid"
              display="flex"
              size="md"
            />
          ),
          flex: 0.5,
        },
      ];
    });
  }, [vehicles]);

  const renderVehicleTable = useMemo(() => {
    return (
      <VehicleTable
        contentTable={contentTable}
        pageInfo={vehiclesPageInfo}
        goToUpdatePage={goToUpdatePage}
        changePage={setCurrentPage}
        isLoading={hasLoading}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [contentTable, vehiclesPageInfo, goToUpdatePage, setCurrentPage, hasLoading]);

  const tabContent = useMemo(() => {
    const availableTabs = [
      {
        name: 'Ativos',
        content: renderVehicleTable,
      },
      {
        name: 'Inativos',
        content: renderVehicleTable,
      },
    ];

    return availableTabs;
  }, [isAdmin, renderVehicleTable]);

  return (
    <Fragment>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Cadastro', 'Meus Veículos']} />
      <Title
        dataTestId={dataTestId}
        title="Cadastre os veículos de frota própria ou dedicada para venda de ociosidade."
        hasTrace
      />
      <ContainerSearchStyled data-testid={`${dataTestId}-container-input-search`}>
        <Input id="search" name="search" placeholder="Pesquise por Placa" type="text" changeValue={setSearchInput} />

        <DropDown title="Exportar">
          <ContentExportReport options={options} onClick={(option) => onHandleExport(option)} />
        </DropDown>

        <TooltipComponent
          icon={
            <Button
              label=""
              color="white"
              size="x-sm"
              variant="ghost"
              onPress={() => handleOpenUploadXlsx()}
              LeftIcon={<CloudUploadIcon sx={{ width: 22 }} />}
            />
          }
          title={'Importar'}
          location={'bottom'}
        />

        {!isShippingCompany && (
          <NewFreight>
            <Button
              label="Novo Frete"
              size="md"
              color="blue"
              onPress={() => handleCreateSchedulePage(true)}
              variant="solid"
            />
          </NewFreight>
        )}
      </ContainerSearchStyled>

      <Tabs tabsContent={tabContent} setActiveTabName={setActiveTabName} />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Adicionar" color="pink" onPress={goToCreateVehicle} />
          <FooterPage.Button
            label="Excluir"
            color="white"
            variant="ghost"
            onPress={() => handleRemoveVehicle(idsToRemove)}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </Fragment>
  );
};

export default VehicleListBodyView;
