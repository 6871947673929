export enum ContactDetailsSectionActionType {
  CONTACT_DETAILS_SECTION_MODAL_OPEN = 'CONTACT_DETAILS_SECTION_MODAL_OPEN',
  CONTACT_DETAILS_SECTION_MODAL_CLOSE = 'CONTACT_DETAILS_SECTION_MODAL_CLOSE',
  CONTACT_DETAILS_SECTION_RESET = 'CONTACT_DETAILS_SECTION_RESET',
}

export enum AttachDocumentsSectionActionType {
  ATTACH_DOCUMENTS_SECTION_MODAL_OPEN = 'ATTACH_DOCUMENTS_SECTION_MODAL_OPEN',
  ATTACH_DOCUMENTS_SECTION_MODAL_CLOSE = 'ATTACH_DOCUMENTS_SECTION_MODAL_CLOSE',
  GET_ATTACH_DOCUMENTS_REQUEST = 'GET_ATTACH_DOCUMENTS_REQUEST',
  GET_ATTACH_DOCUMENTS_SUCCESS = 'GET_ATTACH_DOCUMENTS_SUCCESS',
  GET_ATTACH_DOCUMENTS_ERROR = 'GET_ATTACH_DOCUMENTS_ERROR',
  ATTACH_DOCUMENTS_SECTION_RESET = 'ATTACH_DOCUMENTS_SECTION_RESET',
}

export enum RiskManagementSectionActionType {
  RISK_MANAGEMENT_SECTION_MODAL_OPEN = 'RISK_MANAGEMENT_SECTION_MODAL_OPEN',
  RISK_MANAGEMENT_SECTION_MODAL_CLOSE = 'RISK_MANAGEMENT_SECTION_MODAL_CLOSE',
  RISK_MANAGEMENT_SECTION_RESET = 'RISK_MANAGEMENT_SECTION_RESET',
}

export enum RegistrationsActionType {
  REGISTRATIONS_RESET = 'REGISTRATIONS_RESET',
}
