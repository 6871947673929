import { IListPage } from 'src-new/domains/list-page.domain';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';
import { IPurchaseOfferListResponse } from './get-purchase-offer-list.types';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';

export type PurchaseOfferListServiceResponse = IListPage<IPurchaseOfferListResponse>;

export const getPurchaseOfferListService = async (
  filters: IPurchaseOfferListFilterDomain,
): Promise<PurchaseOfferListServiceResponse> => {
  const {
    search,
    page,
    limitPage,
    orderBy,
    column,
    originUf,
    originCity,
    destinationUf,
    destinationCity,
    vehicleType,
    vehicleCategory,
    operationType,
    isActive,
  } = filters;

  const { data } = await API_TRAVELS_SVC.get<PurchaseOfferListServiceResponse>(
    `/v1/purchase-offers?search=${search}&page=${page}&limit=${limitPage}&order=${orderBy}&column=${column}&isActive=${isActive}&originUf=${originUf}&originCity=${originCity}&destinationUf=${destinationUf}&destinationCity=${destinationCity}&vehicleType=${convertVehicleTypeToEntity(
      vehicleType,
    ).toUpperCase()}&vehicleCategory=${convertVehicleCategoryToEntity(
      vehicleCategory,
    ).toUpperCase()}&operationType=${operationType}`,
  );
  return data;
};
