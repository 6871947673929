import { useCallback, useReducer } from 'react';
import { matchesInitialState } from '../../matches.constants';
import { IMatchesKanbanList, MatchesModals } from '../../types/matches-kanban.types';
import { matchesKanbanListReducer } from '../../reducers/matches-kanban-list/matches-kanban-list.reducer';
import { MatchesKanbanListActionType } from '../../matches.action';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';

export const useMatchesKanbanListContextValues = (): IMatchesKanbanList => {
  const [state, dispatch] = useReducer(matchesKanbanListReducer, matchesInitialState);

  const setModalOpen = useCallback((options: MatchesModals) => {
    dispatch({ type: MatchesKanbanListActionType.MATCHES_KANBAN_MODALS, payload: options });
  }, []);

  const setFilters = useCallback((filters: IMatchesListParams) => {
    dispatch({ type: MatchesKanbanListActionType.MATCHES_KANBAN_LIST_FILTERS, payload: filters });
  }, []);


  return {
    ...state.matchesKanbanList,
    modalsProps: {
      ...state.matchesKanbanList.modalsProps,
      setModalOpen,
    },
    matchesListFiltersProps: {
      ...state.matchesKanbanList.matchesListFiltersProps,
      setFilters,
    },
  };
};
