import React, { useMemo } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import * as S from './styled';

interface CrossMarginProps {
  value: number;
}

export const CrossMargin: React.FC<CrossMarginProps> = ({ value }) => {
  const colorPercent = useMemo(() => {
    if (value >= 10) return true;

    return false;
  }, [value]);

  return (
    <S.Wrapper color={colorPercent ? 'green' : 'red'}>
      {colorPercent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      <S.Info>{value}%</S.Info>
    </S.Wrapper>
  );
};
