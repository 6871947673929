/* eslint-disable @typescript-eslint/no-explicit-any */
import request, { AxiosResponse } from 'axios';

import API from 'utils/API/API';

interface StatusError {
  statusCode: number;
  message: string;
}

export const deleteTableFreightListService = async (payload: any) => {
  try {
    await API.delete('v2/freight', {
      data: { ids: payload },
    });
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao criar o agendamento');
  }
};
