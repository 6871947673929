import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleMapsView } from './schedule-maps-view.component';
import { IMapLocation, IMapsFireBaseProps, IMapsMonitoringProps, IMapsPlannedRouteProps } from './schedule-maps.types';
import PinLocationRed from 'assets/img/pin-logshare-red.svg';
import BlueCircle from 'assets/img/blue-circle.png';
import PinLocationLightBlue from 'assets/img/pin-logshare-light-blue.svg';
import TrackingOrange from 'assets/img/tracking-orange.svg';
import { IMapsDirection } from 'src-new/components/google-maps/google-maps-view.types';
import { useGetExecutedRouteApp } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-executed-route-app/use-get-executed-route-app.hook';
import { useGetExecutedRouteMonytor } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-executed-route-monytor/use-get-executed-route-monytor.hook';

const DISCHARGE_COMPLETED_STATUS = 'IN90';

export const ScheduleMaps: React.FC = () => {
  const { scheduleExecution, scheduleDetails } = useContext(ScheduleContext);
  const [isCheckedFireBase, setIsCheckedFireBase] = useState<boolean>(false);
  const [isCheckedMonitoring, setIsCheckedMonitoring] = useState<boolean>(false);
  const [isLoadingFireBase, setIsLoadingFireBase] = useState<boolean>(true);
  const [isLoadingMonitoring, setIsLoadingMonitoring] = useState<boolean>(true);
  const getExecutedRouteApp = useGetExecutedRouteApp();
  const getExecutedRouteMonytor = useGetExecutedRouteMonytor();

  const schedule = useMemo(() => scheduleDetails.scheduleDetails, [scheduleDetails.scheduleDetails]);

  const getPlannedTrip = useMemo((): IMapLocation => {
    return {
      origin: {
        lat: Number(scheduleDetails.scheduleDetails.origin.address.lat),
        lng: Number(scheduleDetails.scheduleDetails.origin.address.lng),
      },
      destination: {
        lat: Number(scheduleDetails.scheduleDetails.destination.address.lat),
        lng: Number(scheduleDetails.scheduleDetails.destination.address.lng),
      },
    };
  }, [
    scheduleDetails.scheduleDetails.destination.address.lat,
    scheduleDetails.scheduleDetails.destination.address.lng,
    scheduleDetails.scheduleDetails.origin.address.lat,
    scheduleDetails.scheduleDetails.origin.address.lng,
  ]);

  const handleMapKind = useCallback(
    (mapKind: 'firebase' | 'monitoring') => {
      if (mapKind === 'firebase') {
        setIsCheckedFireBase(!isCheckedFireBase);
        setIsCheckedMonitoring(false);
      } else if (mapKind === 'monitoring') {
        setIsCheckedMonitoring(!isCheckedMonitoring);
        setIsCheckedFireBase(false);
      }
    },
    [isCheckedFireBase, isCheckedMonitoring],
  );

  const plannedRoutes = useMemo(
    (): IMapsPlannedRouteProps => ({
      isChecked: scheduleExecution.mapAccordionProps.plannedRoute,
      setPlannedRoute: scheduleExecution.mapAccordionProps.setPlannedRoute,
    }),
    [scheduleExecution.mapAccordionProps.plannedRoute, scheduleExecution.mapAccordionProps.setPlannedRoute],
  );

  const routePlannedColor = useMemo(
    (): string => (plannedRoutes.isChecked ? '#1d4165' : 'transparent'),
    [plannedRoutes.isChecked],
  );

  const routeExecutedFireBaseColor = useMemo(
    (): string => (isCheckedFireBase ? '#5AC742' : 'transparent'),
    [isCheckedFireBase],
  );

  const routeExecutedMonitoringColor = useMemo(
    (): string => (isCheckedMonitoring ? '#1d4165' : 'transparent'),
    [isCheckedMonitoring],
  );

  const mountFireBaseAppDirection = useMemo((): IMapsDirection | undefined => {
    if (scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length > 1) {
      const routes = scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes;
      const initialPosition = routes[0];
      const lastPosition = routes[routes.length - 1];
      return {
        origin: {
          lat: initialPosition.latitude,
          lng: initialPosition.longitude,
          speed: initialPosition.speed,
          timestamp: initialPosition.created_at,
        },
        destination: {
          lat: lastPosition.latitude,
          lng: lastPosition.longitude,
          speed: lastPosition.speed,
          timestamp: lastPosition.created_at,
        },
        routePositions: scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routePositions
          .filter((route) => route.speed && route.speed > 10)
          .map((route) => ({
            lat: route.latitude,
            lng: route.longitude,
            speed: route.speed,
            timestamp: route.created_at,
          })),
        routeColor: 'transparent',
        iconProps: {
          hasIconGroup: true,
          originIcon: BlueCircle,
          destinationIcon: schedule.statusCode === DISCHARGE_COMPLETED_STATUS ? BlueCircle : TrackingOrange,
          completedTrip: schedule.statusCode === DISCHARGE_COMPLETED_STATUS,
        },
      };
    }
  }, [
    schedule.statusCode,
    scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routePositions,
    scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes,
  ]);

  const mountFireBaseMonytorDirection = useMemo((): IMapsDirection | undefined => {
    if (scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length > 1) {
      const routes = scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes;
      const initialPosition = routes[0];
      const lastPosition = routes[routes.length - 1];

      return {
        origin: {
          lat: initialPosition.latitude,
          lng: initialPosition.longitude,
          speed: initialPosition.speed,
          timestamp: initialPosition.created_at,
          address: initialPosition.address,
          ignition: initialPosition.ignition,
        },
        destination: {
          lat: lastPosition.latitude,
          lng: lastPosition.longitude,
          speed: lastPosition.speed,
          timestamp: lastPosition.created_at,
          address: lastPosition.address,
          ignition: lastPosition.ignition,
        },
        routePositions: scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routePositions.map((route) => ({
          lat: route.latitude,
          lng: route.longitude,
          speed: route.speed,
          timestamp: route.created_at,
          address: route.address,
          ignition: route.ignition,
        })),
        routeColor: 'transparent',
        iconProps: {
          hasIconGroup: true,
          originIcon: BlueCircle,
          destinationIcon: schedule.statusCode === DISCHARGE_COMPLETED_STATUS ? BlueCircle : TrackingOrange,
          completedTrip: schedule.statusCode === DISCHARGE_COMPLETED_STATUS,
        },
      };
    }
  }, [
    schedule.statusCode,
    scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routePositions,
    scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes,
  ]);

  const plannedRoute = useMemo((): IMapsDirection | undefined => {
    if (plannedRoutes.isChecked) {
      return {
        origin: getPlannedTrip.origin,
        destination: getPlannedTrip.destination,
        routeColor: routePlannedColor,
        iconProps: {
          hasIconGroup: true,
          originIcon: PinLocationRed,
          destinationIcon: PinLocationLightBlue,
        },
      };
    }
  }, [getPlannedTrip.destination, getPlannedTrip.origin, plannedRoutes.isChecked, routePlannedColor]);

  const fireBaseRoute = useMemo((): IMapsDirection | undefined => {
    if (isCheckedFireBase) {
      return mountFireBaseAppDirection
        ? { ...mountFireBaseAppDirection, routeColor: routeExecutedFireBaseColor }
        : undefined;
    }
  }, [isCheckedFireBase, mountFireBaseAppDirection, routeExecutedFireBaseColor]);

  const monitoringRoute = useMemo((): IMapsDirection | undefined => {
    if (isCheckedMonitoring) {
      return mountFireBaseMonytorDirection
        ? { ...mountFireBaseMonytorDirection, routeColor: routeExecutedMonitoringColor }
        : undefined;
    }
  }, [isCheckedMonitoring, mountFireBaseMonytorDirection, routeExecutedMonitoringColor]);

  const trackerRouteIsAllowed = useMemo(() => {
    const disallowedStatusCode: Array<string> = ['SH02', 'SH01', 'OT01', 'OT10', 'AG10', 'IN10', 'IN11'];

    return !disallowedStatusCode.includes(schedule.statusCode);
  }, [schedule.statusCode]);

  const isDisabledFireBase = useMemo(
    () => !scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length,
    [scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length],
  );
  const isDisabledMonitoring = useMemo(
    () => !scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length,
    [scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length],
  );

  const isLoadingCheckbox = useMemo(
    () => isLoadingFireBase || isLoadingMonitoring,
    [isLoadingFireBase, isLoadingMonitoring],
  );

  const firebaseProps = useMemo(
    (): IMapsFireBaseProps => ({
      isChecked: isCheckedFireBase,
      truckRoutes: scheduleExecution.mapAccordionProps.firebaseTruckRoutes,
    }),
    [isCheckedFireBase, scheduleExecution.mapAccordionProps.firebaseTruckRoutes],
  );
  const monitoringProps = useMemo(
    (): IMapsMonitoringProps => ({
      isChecked: isCheckedMonitoring,
      truckRoutes: scheduleExecution.mapAccordionProps.monitoringTruckRoutes,
    }),
    [isCheckedMonitoring, scheduleExecution.mapAccordionProps.monitoringTruckRoutes],
  );

  const executedRouteExist = useMemo((): boolean => {
    const firebaseRoutesExist = scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length > 1;
    const monitoringRoutesExist = scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length > 1;

    return firebaseRoutesExist || monitoringRoutesExist;
  }, [
    scheduleExecution.mapAccordionProps.firebaseTruckRoutes.routes.length,
    scheduleExecution.mapAccordionProps.monitoringTruckRoutes.routes.length,
  ]);

  const isShowDownloadButton = useMemo(
    () =>
      ![
        'SH02',
        'SH01',
        'OT01',
        'OT10',
        'AG10',
        'IN10',
        'IN11',
        'IN20',
        'IN30',
        'IN40',
        'IN60',
        'IN61',
        'IN62',
        'IN70',
      ].includes(schedule.statusCode) && executedRouteExist,
    [executedRouteExist, schedule.statusCode],
  );

  useEffect(() => {
    if (!isLoadingCheckbox) {
      if (!isDisabledMonitoring) {
        setIsCheckedMonitoring(true);
      } else if (!isDisabledFireBase) {
        setIsCheckedFireBase(true);
      }
    }
  }, [isDisabledFireBase, isDisabledMonitoring, isLoadingCheckbox]);

  useEffect(() => {
    if (isCheckedFireBase) {
      setIsCheckedMonitoring(false);
    } else if (isCheckedMonitoring) {
      setIsCheckedFireBase(false);
    }
  }, [isCheckedFireBase, isCheckedMonitoring]);

  useEffect(() => {
    if (trackerRouteIsAllowed) {
      getExecutedRouteApp(setIsLoadingFireBase);
    } else {
      setIsLoadingFireBase(false);
    }
  }, [getExecutedRouteApp, isLoadingMonitoring, trackerRouteIsAllowed]);

  useEffect(() => {
    if (trackerRouteIsAllowed) {
      getExecutedRouteMonytor(setIsLoadingMonitoring);
    } else {
      setIsLoadingMonitoring(false);
    }
  }, [getExecutedRouteMonytor, trackerRouteIsAllowed]);

  return (
    <ScheduleMapsView
      plannedRoute={plannedRoute}
      fireBaseRoute={fireBaseRoute}
      monitoringRoute={monitoringRoute}
      handleMapKind={handleMapKind}
      firebaseProps={firebaseProps}
      monitoringProps={monitoringProps}
      plannedRouteProps={plannedRoutes}
      isDisabledFireBaseCheckBox={isDisabledFireBase}
      isDisabledMonitoringCheckBox={isDisabledMonitoring}
      isLoadingCheckbox={isLoadingCheckbox}
      isShowDownloadButton={isShowDownloadButton}
    />
  );
};
