import { IMatchesListParams } from '../../types/matches-list-filters/matches-list-filters.types';
import { IParamsFilter, MatchesFilterMapping } from './convert-matches-filters.types';

export const convertMatchesFilters = (filters: IMatchesListParams): Array<IParamsFilter> => {
  const entriesBlackList = ['page', 'column', 'order', 'activeTabName', 'filterIdentifier', 'limit'];

  const entries = Object.entries(filters).map(([key, value]) => ({
    key,
    value: convertField(key, value),
  }));

  const filteredParams = entries.filter(({ key, value }) => !entriesBlackList.includes(key) && value);

  return filteredParams;
};

const convertField = (field: string, value: string | number): string | number => {
  const fieldMappings: MatchesFilterMapping = {
    operationBy: {
      '1': 'LOGSHARE',
      '2': 'EMBARCADOR',
    },
    vehicleType: {
      '3/4': '3/4',
      bitrem: 'BITREM',
      bitruck: 'BITRUCK',
      carreta: 'CARRETA',
      carreta_ls: 'CARRETA LS',
      complemento: 'COMPLEMENTO',
      rodotrem: 'RODOTREM',
      'romeu&julieta': 'ROMEU & JULIETA',
      romeu_julieta: 'ROMEU & JULIETA',
      toco: 'TOCO',
      truck: 'TRUCK',
      utilitario: 'UTILITÁRIO',
      van: 'VAN',
      vuc: 'VUC',
      vanderleia: 'VANDERLÉIA',
    },
    vehicleCategory: {
      bau_seco: 'BAÚ SECO',
      bau_frigorificado: 'BAÚ FRIGORIFICADO',
      bau_refrigerado: 'BAÚ REFRIGERADO',
      bau_sider: 'BAÚ SIDER',
      cacamba_basculante: 'BASCULANTE',
      cegonha: 'CEGONHA',
      florestal: 'FLORESTAL',
      grade_baixa: 'GRADE BAIXA',
      graneleiro: 'GRANELEIRO',
      plataforma: 'PLATAFORMA',
      porta_container_20: 'PORTA CONTAINER 20',
      porta_container_40: 'PORTA CONTAINER 40',
      tanque: 'TANQUE',
    },
    scheduleOperationType: {
      INBOUND: 'INBOUND',
      OUTBOUND: 'OUTBOUND',
      TRANSFERENCIA: 'TRANSFERÊNCIA',
    },
    statusCode: {
      SH01: 'EM BIDDING',
      OT10: 'EM NEGOCIAÇÃO',
      AG10: 'AGUARDANDO ALOCAÇÃO',
      OT01: 'AG. ACEITE TRANSPORTADORA',
      AL99: 'ALOCAÇÃO CANCELADA',
      OT99: 'OFERTA NEGADA',
      AL90: 'ALOCAÇÃO CANCELADA COM CUSTO',
      Al20: 'ALOCADO',
      IN10: 'AGUARDANDO MOTORISTA',
      IN11: 'AGUARDANDO INÍCIO DE ROTA',
      IN20: 'EM ROTA PARA CARREGAMENTO',
      IN40: 'EM CARREGAMENTO',
      IN60: 'EM ROTA PARA DESCARGA',
      IN70: 'EM DESCARGA',
      IN90: 'DESCARGA FINALIZADA',
    },
  };

  const fieldMapping = fieldMappings[field];

  return fieldMapping && fieldMapping[value] ? fieldMapping[value] : value;
};
