import React, { useContext, useEffect, useMemo } from 'react';
import { TagUnreadMessagesView } from 'src-new/components/application-page-header/components/header-page/components/tag-unread-messages/tag-unread-messages-view.component';
import { useUnreads } from '@talkjs/react';
import { ChatContext } from 'src-new/contexts/chat/chat.context';

export const TagUnreadMessages: React.FC = () => {
  const { chatUnreadMessages } = useContext(ChatContext);
  const unreadMessages = useUnreads();
  const { setUnreadMessages } = chatUnreadMessages;

  const unreadMessagesCount = useMemo(
    (): number =>
      chatUnreadMessages.unreadMessages?.reduce((count, unread) => count + unread['unreadMessageCount'], 0) ?? 0,
    [chatUnreadMessages.unreadMessages],
  );

  useEffect(() => {
    if (unreadMessages) {
      setUnreadMessages(unreadMessages);
    }
  }, [setUnreadMessages, unreadMessages]);

  return <TagUnreadMessagesView unreadMessageCount={unreadMessagesCount} />;
};
