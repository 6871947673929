import React, { useContext } from 'react';
import { UserContext } from 'state/user-context';
import { Navigate } from 'react-router-dom';
import { FinancialContextProvider } from 'src-new/pages/financial/contexts/financial/financial.context';
import { EquipmentVoucherUpdatePageBody } from './components/equipment-voucher-update-page-body/equipment-voucher-update-page-body.component';
import { EquipmentVoucherContextProvider } from 'src-new/pages/financial/pages/equipment-voucher/contexts/equipment-voucher/equipment-voucher.context';

export const EquipmentVoucherUpdatePage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <FinancialContextProvider>
        <EquipmentVoucherContextProvider>
          <EquipmentVoucherUpdatePageBody />
        </EquipmentVoucherContextProvider>
      </FinancialContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
