import { ISantosPortContext } from './santos-port.types';

export const initialState: ISantosPortContext = {
  santosPortIsLoading: false,
  santosPort: [],
  santosPortError: undefined,
  santosPortPageInfo: undefined,
  setSantosPortRequest: () => undefined,
  setSantosPortSuccess: () => undefined,
  setSantosPortError: () => undefined,
};

export enum ISantosPortAction {
  SANTOS_PORT_REQUEST = 'SANTOS_PORT_REQUEST',
  SANTOS_PORT_SUCCESS = 'SANTOS_PORT_SUCCESS',
  SANTOS_PORT_ERROR = 'SANTOS_PORT_ERROR',
}
