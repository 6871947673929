import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button, Tag } from 'logshare-ui-kit';
import { RefreshCcw, Trash2 } from 'lucide-react';
import { FC, useMemo } from 'react';
import { Box } from 'src-new/components/box/box.component';
import { IScheduleHistoryDriverAndVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-history-driver-and-vehicle.domain';
import { capitalize } from 'src-new/utils/capitalize.util';
import { formatDate } from 'src-new/utils/format-date';
import { formatCPF } from 'utils/format-numbers';
import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';
import { IScheduleDetailsDriverDomain } from '../../../../../../../../domains/schedule-details.domain';
import * as S from './schedule-details-allocation-driver-view.styled';
import { BsWhatsapp } from 'react-icons/bs';
import { Tooltip } from '@mui/material';

interface IScheduleDetailsAllocationDriverViewProps {
  driver: IScheduleDetailsDriverDomain;
  historyDrivers?: Array<IScheduleHistoryDriverAndVehicleDomain>;
  handleUpdateDriverHistory: (driverId: number) => Promise<void>;
  handleDeleteDriverHistory: (historyId: number) => Promise<void>;
  goToWhatsApp: () => void;
}

export const ScheduleDetailsAllocationDriverView: FC<IScheduleDetailsAllocationDriverViewProps> = ({
  driver,
  historyDrivers,
  handleUpdateDriverHistory,
  handleDeleteDriverHistory,
  goToWhatsApp,
}) => {
  const renderStatusActive = useMemo(() => {
    return <Tag label="Ativo" color="aqua" variant="ghost" display="flex" size="md" />;
  }, []);

  const renderHistoryDrivers = useMemo(() => {
    if (historyDrivers && historyDrivers.length > 0) {
      return historyDrivers?.map((historyDriver, index) => {
        const tagActive = historyDriver.driverId === driver?.id;

        return (
          <Box
            key={index}
            footerButtons={[
              tagActive ? renderStatusActive : <span />,
              <S.WrapperButtons key={0} width={'150px'}>
                <Tooltip title={<S.TooltipLabel>Falar com Motorista</S.TooltipLabel>}>
                  <S.ContainerButtons isDisabled={!tagActive}>
                    <Button
                      key={0}
                      label=""
                      color="white"
                      onPress={() => undefined}
                      LeftIcon={<BsWhatsapp key={0} onClick={goToWhatsApp} color="#2bc162" size={20} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={!tagActive}
                    />
                  </S.ContainerButtons>
                </Tooltip>
                <Tooltip title={<S.TooltipLabel>Trocar Motorista</S.TooltipLabel>}>
                  <S.ContainerButtons isDisabled={!tagActive}>
                    <Button
                      key={1}
                      label=""
                      color="white"
                      onPress={() =>
                        handleUpdateDriverHistory(historyDrivers[index + 1]?.driverId ?? historyDrivers[0].driverId)
                      }
                      LeftIcon={<RefreshCcw size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={!tagActive}
                    />
                  </S.ContainerButtons>
                </Tooltip>
                <Tooltip title={<S.TooltipLabel>Remover Motorista</S.TooltipLabel>}>
                  <S.ContainerButtons isDisabled={!tagActive}>
                    <Button
                      key={2}
                      label=""
                      color="white"
                      onPress={() => handleDeleteDriverHistory(historyDriver.id)}
                      LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={!tagActive}
                    />
                  </S.ContainerButtons>
                </Tooltip>
              </S.WrapperButtons>,
            ]}
          >
            <LineSectionLayout columns={'1fr 1fr 1fr 1fr 1fr'}>
              <S.WrapperInformation>
                <S.InformationTitle>Nome</S.InformationTitle>
                <S.InformationBody>{capitalize(historyDriver.driverName)}</S.InformationBody>
              </S.WrapperInformation>

              <S.WrapperInformation>
                <S.InformationTitle>CNH</S.InformationTitle>
                <S.InformationBody>{historyDriver.cnh}</S.InformationBody>
              </S.WrapperInformation>

              <S.WrapperInformation>
                <S.InformationTitle>CPF</S.InformationTitle>
                <S.InformationBody>{formatCPF(historyDriver.cpf)}</S.InformationBody>
              </S.WrapperInformation>

              <S.WrapperInformation>
                <S.InformationTitle>Telefone</S.InformationTitle>
                <S.InformationBody>{formatCelphoneNumberEntityToDomain(historyDriver.firstPhone)}</S.InformationBody>
              </S.WrapperInformation>

              <S.WrapperInformation>
                <S.InformationTitle>Horário de Alocação</S.InformationTitle>
                <S.InformationBody>{formatDate(historyDriver.createdAt, 'DD/MM/YYYY - HH:mm')}</S.InformationBody>
              </S.WrapperInformation>
            </LineSectionLayout>
          </Box>
        );
      });
    }

    return (
      <Box
        footerButtons={[
          renderStatusActive,
          <S.WrapperButtons key={0} width={'95px'}>
            <Tooltip title={<S.TooltipLabel>Falar com Motorista</S.TooltipLabel>}>
              <S.ContainerButtons>
                <Button
                  key={1}
                  label=""
                  color="white"
                  onPress={() => undefined}
                  LeftIcon={<BsWhatsapp key={0} onClick={goToWhatsApp} color="#2bc162" size={20} />}
                  size="x-sm"
                  variant="ghost"
                />
              </S.ContainerButtons>
            </Tooltip>
            <Tooltip title={<S.TooltipLabel>Remover Motorista</S.TooltipLabel>}>
              <S.ContainerButtons isDisabled>
                <Button
                  key={2}
                  label=""
                  color="white"
                  onPress={() => undefined}
                  LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                  size="x-sm"
                  variant="ghost"
                  disabled
                />
              </S.ContainerButtons>
            </Tooltip>
          </S.WrapperButtons>,
        ]}
      >
        <LineSectionLayout columns={'1fr 1fr 1fr 1fr 1fr'}>
          <S.WrapperInformation>
            <S.InformationTitle>Nome</S.InformationTitle>
            <S.InformationBody>{capitalize(driver.name)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>CNH</S.InformationTitle>
            <S.InformationBody>{driver.cnh}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>CPF</S.InformationTitle>
            <S.InformationBody>{formatCPF(driver.cpf)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Telefone</S.InformationTitle>
            <S.InformationBody>{formatCelphoneNumberEntityToDomain(driver.telephone)}</S.InformationBody>
          </S.WrapperInformation>

          <S.WrapperInformation>
            <S.InformationTitle>Horário de Alocação</S.InformationTitle>
            <S.InformationBody>{formatDate(driver.allocationDate, 'DD/MM/YYYY - HH:mm')}</S.InformationBody>
          </S.WrapperInformation>
        </LineSectionLayout>
      </Box>
    );
  }, [
    driver.allocationDate,
    driver.cnh,
    driver.cpf,
    driver?.id,
    driver.name,
    driver.telephone,
    goToWhatsApp,
    handleDeleteDriverHistory,
    handleUpdateDriverHistory,
    historyDrivers,
    renderStatusActive,
  ]);

  return <S.WrapperBoxes>{renderHistoryDrivers}</S.WrapperBoxes>;
};
