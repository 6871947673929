import React from 'react';

import OccurrencesContextProvider from '../contexts/table-states/occurrences.context';

import { OccurrenceListBody } from './occurrence-list-body/occurrence-list-body';

const OccurrencePage: React.FC = () => {
  return (
    <OccurrencesContextProvider>
      <OccurrenceListBody />
    </OccurrencesContextProvider>
  );
};

export default OccurrencePage;
