import API from 'utils/API/API';

export const tableFreightReportExcelService = async (): Promise<BlobPart | undefined> => {
  try {
    const { data } = await API.get('v2/freight/export', {
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao exportar');
  }
};
