import React, { ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './tracking-occurrence-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface ITrackingOccurrenceTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (vehicleId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  notAbsolute?: boolean;
}

const dataTestId = 'vehicle-table-view';

const TrackingOccurrenceTable: React.FC<ITrackingOccurrenceTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  arrowOrder,
  isLoading,
  notAbsolute,
}: ITrackingOccurrenceTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <ContainerPaginationStyled notAbsolute={notAbsolute} data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
      />
      {renderPagination}
    </ContainerTableStyled>
  );
};

export default TrackingOccurrenceTable;
