/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { SectionContainerStyled, UploadImageStyled } from './styled';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import {
  ReworkFileUrl,
  getReworkFileUrlByIdService,
  removeReworkFile,
  sendReworkFile,
} from 'pages-v2/rework/services/rework-file-url.service';
import { showToast } from 'components/toast/toast';
import InputUploadImage from 'components/input-upload-image/input-upload-image';

interface IReworkPhotosSectionProps {
  reworkRegisterId?: string;
  handleFile: (urlPhotos: ReworkFileUrl[]) => void;
}

const ReworkPhotosSection: React.FC<IReworkPhotosSectionProps> = ({ reworkRegisterId, handleFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [urlsPhotos, setUrlsPhotos] = useState<Array<ReworkFileUrl>>();

  useEffect(() => {
    if (!reworkRegisterId) return;

    handleGetFileUrlImage();
  }, []);

  const handleGetFileUrlImage = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getReworkFileUrlByIdService(reworkRegisterId ?? '');
      if (response) {
        setUrlsPhotos(response);
      }
    } catch (error: any) {
      showMessageFormatted({
        message: error,
        type: error,
      });
    } finally {
      setIsLoading(false);
    }
  }, [getReworkFileUrlByIdService, reworkRegisterId]);

  const handleRemovePhoto = async (photoId: number) => {
    if (photoId && reworkRegisterId) {
      try {
        setIsLoading(true);
        await removeReworkFile(photoId);
        handleGetFileUrlImage();
      } catch (error: any) {
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    } else {
      const urlsPhotosList = urlsPhotos?.filter((photo) => photo.id !== photoId);
      setUrlsPhotos(urlsPhotosList);

      handleFile(urlsPhotosList ?? []);
    }
  };

  const handleAddPhoto = async (_: object, file: File) => {
    if (file && reworkRegisterId) {
      try {
        setIsLoading(true);
        await sendReworkFile(reworkRegisterId, [file]);
        handleGetFileUrlImage();
        showToast({
          type: 'success',
          message: 'Foto adicionada com sucesso',
        });
      } catch (error: any) {
        showMessageFormatted({
          message: error,
          type: 'error',
        });
      }
    } else {
      const urlsPhotosList: ReworkFileUrl[] = [
        ...(urlsPhotos ?? []),
        {
          id: Date.now(),
          fileKey: URL.createObjectURL(file),
          file: file,
          type: file.type,
          reworkId: 0,
        },
      ];
      setUrlsPhotos(urlsPhotosList);

      handleFile(urlsPhotosList);
    }
  };

  const renderPhotos = useMemo(() => {
    return urlsPhotos?.map((photo) => {
      const separetedUrlFromKey = photo?.fileKey?.split('?');
      const fileExtention = separetedUrlFromKey?.[0]?.split('.')?.pop();
      const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];
      let fileType;

      if (photo.type) {
        fileType = allowedImageFormats.includes(photo.type) ? 'image' : 'file';
      } else if (fileExtention) {
        fileType = allowedImageFormats.includes(`image/${fileExtention}`) ? 'image' : 'file';
      }
      return (
        <SectionContainerStyled key={photo.id}>
          <InputUploadImage
            urlImage={photo.fileKey}
            hasError={false}
            handleSelectedImage={() => null}
            deleteImage={() => handleRemovePhoto(photo.id)}
            fileType={fileType}
          />
        </SectionContainerStyled>
      );
    });
  }, [urlsPhotos]);

  return (
    <div style={{ overflow: 'auto', paddingBottom: 15 }}>
      <SectionContainerStyled>
        {isLoading ? (
          <div style={{ fontSize: '12px', fontWeight: 600 }}>Carregando...</div>
        ) : (
          <UploadImageStyled>
            {renderPhotos}
            <InputUploadImage hasError={false} handleSelectedImage={handleAddPhoto} />
          </UploadImageStyled>
        )}
      </SectionContainerStyled>
    </div>
  );
};

export default React.memo(ReworkPhotosSection);
