import styled, { css } from 'styled-components';

interface IStyledProps {
  height?: string;
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase' | 'none';
}

export const TableWrapperStyled = styled.div<IStyledProps>`
  ${({ height }) => css`
    height: ${height};
    min-height: 100px;
  `};
`;

export const TableContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TableContentContainerStyled = styled.table<IStyledProps>`
  ${({ textTransform = 'capitalize' }) => css`
    position: relative;
    display: flex;
    height: 95%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    color: #000;
    text-transform: ${textTransform};
  `}
`;

export const LoadingContainerStyled = styled.div`
  ${() => css`
    display: flex;
    position: fixed;
    bottom: -5px;
    left: 50%;
    width: 90%;
    height: 5%;
  `}
`;

export const LoadingModalContainerStyled = styled.div`
  ${() => css`
    display: flex;
    position: relative;
    justify-content: center;
    bottom: -5px;
    width: 100%;
    height: 5%;
  `}
`;
