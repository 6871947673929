import API from 'utils/API/API';
import { converterCompanyDomainToFormData } from 'pages/company/mappers/converter-company-domain-to-form-data/converter-company-domain-to-form-data';
import { ICompany } from 'domain/company';

export const companyUpdateService = async (company: ICompany, branchesToRemove: number[]) => {
  const companyFormData = converterCompanyDomainToFormData(company);

  await API.post('v2/branch', { include: company.branches, remove: branchesToRemove });
  await API.put('v2/retention-fines', { retentionFines: companyFormData.retentionFines });
  await API.put(`v2/companies/${company.id}`, companyFormData.formData);
};
