import React, { useMemo } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';

import HeaderTable from '../header';

import { convertCheckStubStatus } from 'utils-v2/converters/check-stub-status';
import { ICheckStubList, ISelectedCheckStubs } from 'domain-v2/check-stub';
import { PageInfo } from 'domain/page-info';
import { Tabs } from 'components/tabs/tabs';
import { IContentTable } from 'components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';

interface ContentTable {
  checkStubs: Array<ICheckStubList>;
  pageInfo?: PageInfo;
  isLoading: boolean;
  orderSelected: { orderBy: string; sortDirection: string };
  selectedCheckStubs: Array<ISelectedCheckStubs>;
  handlePaginate: (page: number) => void;
  clickOrderTableBy: (type: string) => void;
  clickSortDirection: () => void;
  goToUpdatePallet: (id: string) => void;
  handleCheckboxChange: (checked: boolean, palletId: number, statusCode: string) => void;
}

const ListCheckStubTable: React.FC<ContentTable> = ({
  checkStubs,
  pageInfo,
  isLoading,
  orderSelected,
  selectedCheckStubs,
  handlePaginate,
  clickOrderTableBy,
  clickSortDirection,
  goToUpdatePallet,
  handleCheckboxChange,
}) => {
  const mountTable = useMemo((): Array<IContentTable> => {
    return checkStubs.map((checkStub) => {
      return [
        {
          value: (
            <Checkbox
              id="check-stubs"
              name="check-stubs"
              onChange={(e) => {
                handleCheckboxChange(e.target.checked, checkStub.palletId, checkStub.status);
              }}
              checked={selectedCheckStubs?.some((selected) => selected.id === checkStub.palletId)}
            />
          ),
          flex: 0.2,
        },
        {
          value: checkStub.palletId,
          flex: 0.4,
        },
        {
          value: checkStub.scheduleId ?? '-',
          flex: 0.8,
        },
        {
          value: checkStub.clientName ?? '-',
          flex: 2,
        },
        {
          value: checkStub.originCity ?? '-',
          flex: 1.3,
        },
        {
          value: checkStub.distributionCenterName ?? '-',
          flex: 2,
        },
        {
          value: checkStub.checkStubQuantity,
          flex: 1.5,
        },
        {
          value: checkStub.receiptDate ? moment(checkStub.receiptDate).format('DD/MM/YYYY HH:mm') : '-',
          flex: 1.5,
        },
        {
          value: checkStub.deliveryDate ? moment(checkStub.deliveryDate).format('DD/MM/YYYY HH:mm') : '-',
          flex: 1.5,
        },
        {
          value: (
            <Tag
              label={convertCheckStubStatus(checkStub.status).label}
              color={convertCheckStubStatus(checkStub.status).color}
              display="flex"
              size="md"
              variant="solid"
            />
          ),
          flex: 1.2,
        },
      ];
    });
  }, [checkStubs, selectedCheckStubs, handleCheckboxChange]);

  const renderCheckStubTable = useMemo(() => {
    return (
      <HeaderTable
        contentTable={mountTable}
        pageInfo={pageInfo}
        isLoading={isLoading}
        orderSelected={orderSelected}
        changePage={handlePaginate}
        clickRowAction={goToUpdatePallet}
        clickOrderTableBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
      />
    );
  }, [
    mountTable,
    pageInfo,
    isLoading,
    orderSelected,
    clickOrderTableBy,
    clickSortDirection,
    goToUpdatePallet,
    handlePaginate,
  ]);

  const tabContent = useMemo(() => {
    return [
      {
        name: 'Todos',
        content: renderCheckStubTable,
      },
    ];
  }, [renderCheckStubTable]);

  return <Tabs tabsContent={tabContent} setActiveTabName={() => null} tabActive="Todos" />;
};

export default ListCheckStubTable;
