import React, { Fragment } from 'react';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const TariffPage: React.FC = () => {
  return (
    <Fragment>
      <HeaderPage breadcrumbList={['Minhas Rotas', 'Tarifas']} />
      <Title title="Tarifas." hasTrace />
    </Fragment>
  );
};

export default TariffPage;
