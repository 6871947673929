import React from 'react';

import TrackingOccurrenceBodyView from './tracking-occurrence-register-body-view';

import { AllocationRegisterContextProvider } from 'pages/allocation/contexts/allocation-register/allocation.context';

const TrackingOccurrenceBody: React.FC = () => {
  return (
    <AllocationRegisterContextProvider>
      <TrackingOccurrenceBodyView />
    </AllocationRegisterContextProvider>
  );
};

export default TrackingOccurrenceBody;
