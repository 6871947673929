import { IScheduleLTLUpdateContext } from './schedule-ltl.types';

export const initialState: IScheduleLTLUpdateContext = {
  allocation: {
    id: 0,
    order: '',
    column: '',
    scheduleClientOriginId: 0,
    scheduleClientDestinyId: 0,
    scheduleLocalityOriginId: 0,
    scheduleLocalityDestinyId: 0,
    scheduleVehicleId: 0,
    scheduleDriverId: 0,
    scheduleShippingCompanyId: 0,
    companyId: 0,
    company: '',
    statusCode: '',
    capacity: 0,
    weight: 0,
    cubagem: 0,
    pallet: 0,
    shipmentNumber: '',
    password: '',
    deliveryDate: '',
    pickUpDate: '',
    currentFreight: 'recommended',
    helperQuantity: 0,
    helperAmount: 0,
    helperTotal: 0,
    toll: 0,
    gris: 0,
    advalorem: 0,
    valueMerch: 0,
    valueFreight: 0,
    valueTotalFee: 0,
    taxes: 0,
    totalFreight: 0,
  },
  infoRules: {},
  loading: false,
  setDriver: () => undefined,
  setShippingCompany: () => undefined,
  setVehicle: () => undefined,
  currentAllocationError: () => undefined,
  currentAllocationRequest: () => undefined,
  currentAllocationSuccess: () => undefined,
  setResumeRules: () => undefined,
};

export enum IAllocationsAction {
  SET_RESUME_RULES = 'SET_RESUME_RULES',
  ALLOCATION_VEHICLE_LOAD = 'ALLOCATION_VEHICLE_LOAD',
  ALLOCATION_DRIVER_LOAD = 'ALLOCATION_DRIVER_LOAD',
  ALLOCATION_SHIPPING_COMPANY_LOAD = 'ALLOCATION_SHIPPING_COMPANY_LOAD',
  GET_CURRENT_ALLOCATION_REQUEST = 'GET_CURRENT_ALLOCATION_REQUEST',
  GET_CURRENT_ALLOCATION_SUCCESS = 'GET_CURRENT_ALLOCATION_SUCCESS',
  GET_CURRENT_ALLOCATION_ERROR = 'GET_CURRENT_ALLOCATION_ERROR',
}
