import {
  ITransactionResponse,
  ITransactionScheduleResponse,
} from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/get-transactions-list.types';
import { ITransactionDomain, ITransactionScheduleDomain } from 'src-new/pages/financial/domains/transactions.domain';
import { getScheduleTypeTag } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/utils/get-schedule-type-tag/get-schedule-type-tag.util';

export const convertTransactionResponseToDomain = (transaction: ITransactionResponse): ITransactionDomain => {
  return {
    id: transaction.id,
    companyBuyerId: transaction.companyBuyerId,
    companySellerId: transaction.companySellerId,
    dueDate: transaction.dueDate,
    financialStatus: transaction.financialStatus,
    invoicedBy: transaction.invoicedBy,
    operatedBy: transaction.operatedBy,
    schedule: convertSchedule(transaction.schedule),
    status: transaction.status,
    tag: transaction.tag,
    taker: transaction.taker,
    totalService: transaction.totalService,
  };
};

const convertSchedule = (schedule: ITransactionScheduleResponse): ITransactionScheduleDomain => {
  return {
    ...schedule,
    typeSchedule: getScheduleTypeTag(schedule.typeSchedule.toUpperCase()).label,
    scheduleDetails: {
      deliveryInitialDate: new Date(schedule.scheduleDetails.deliveryInitialDate),
      deliveryEndDate: new Date(schedule.scheduleDetails.deliveryEndDate),
    },
  };
};
