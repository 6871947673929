import { FC, memo, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { ModalMatchDetails } from '../../../types';

import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { MatchRegisterInput } from 'domain-v2/match/register';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';

interface ICargoDetailsProps {
  transportRouter: ModalMatchDetails;
}

const CargoDetailsForm: FC<ICargoDetailsProps> = ({ transportRouter }) => {
  const { values, setFieldValue, handleChange } = useFormikContext<MatchRegisterInput>();

  const { cargo } = values;

  useEffect(() => {
    setFieldValue('cargo.vehicleType', transportRouter.vehicle.type);
    setFieldValue('cargo.vehicleCategoryType', transportRouter.vehicle.category);
    setFieldValue('haulType', transportRouter.haulType);
  }, [transportRouter.vehicle]);

  return (
    <SectionLayout name="Cargo details information">
      <LineSectionLayout columns="1fr 1fr 1fr 1fr 0.07fr">
        <InputCurrency
          label="Peso (Kg)"
          id="cargo.weight"
          name="cargo.weight"
          type="text"
          allowDecimals={false}
          decimalScale={0}
          decimalsLimit={0}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.weight}
          suffix=" Kg"
        />

        <InputField
          label="Paletes"
          id="cargo.pallet"
          name="cargo.pallet"
          type="number"
          onChange={handleChange}
          value={cargo.pallet}
        />

        <InputCurrency
          label="Cubagem (m3)"
          id="cargo.cubagem"
          name="cargo.cubagem"
          type="number"
          suffix=" m³"
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
          decimalScale={0}
          decimalsLimit={0}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={cargo.cubagem}
        />

        <InputCurrency
          label="Valor da Mercadoria"
          id="cargo.merchValue"
          name="cargo.merchValue"
          type="number"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          decimalSeparator=","
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', converterNumberPtBrToUs(value ?? '0'))}
          value={Number.parseFloat(cargo.merchValue.toString()).toFixed(2).toString()}
        />
      </LineSectionLayout>

      <LineSectionLayout columns=" 1fr 1fr 1fr 0.050fr">
        <InputField
          label="Produto Predominante"
          id="cargo.product"
          name="cargo.product"
          type="text"
          onChange={handleChange}
          value={cargo.product}
        />

        <InputField
          label="Pedidos"
          id="cargo.order"
          name="cargo.order"
          type="text"
          onChange={handleChange}
          value={cargo.order}
        />

        <InputField
          label="Observações Gerais"
          id="cargo.comments"
          name="cargo.comments"
          type="text"
          onChange={handleChange}
          value={cargo.comments}
        />
      </LineSectionLayout>
    </SectionLayout>
  );
};

export default memo(CargoDetailsForm);
