import { palletFormRegisterInitialValues } from 'src-new/pages/financial/components/pallet-form/constants/pallet-form-register-initial-values.constants';
import { IFinancialContext } from '../types/financial.types';

export const financialReducerResetState = (state: IFinancialContext): IFinancialContext => {
  return {
    ...state,
    palletForm: {
      ...state.palletForm,
      statusRequest: 'INITIAL',
      getPalletDetails: {
        ...state.palletForm.getPalletDetails,
        statusRequest: 'INITIAL',
        palletDetails: palletFormRegisterInitialValues,
      },
    },
  };
};
