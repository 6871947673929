import React, { Fragment, ReactElement, useMemo } from 'react';
import { ITableProps } from './table.types';
import {
  LoadingContainerStyled,
  LoadingModalContainerStyled,
  TableContainerStyled,
  TableContentContainerStyled,
  TableWrapperStyled,
} from './table-view.styled';
import { Header } from './components/header/header.component';
import { Body } from './components/body/body.component';
import { Loading } from 'src-new/components/loading/loading.component';

export const TableView: React.FC<ITableProps> = ({
  header,
  ordering,
  rows,
  gridColumns,
  config,
  height,
  alignLinesCenter,
  infiniteScrollProps,
  columnFontBold,
}) => {
  const renderHeader = useMemo((): ReactElement | undefined => {
    if (!config?.hideHeader) {
      return <Header header={header} ordering={ordering} gridColumns={gridColumns} columnFontBold={columnFontBold} />;
    }
  }, [columnFontBold, config?.hideHeader, gridColumns, header, ordering]);

  const renderLazyLoading = useMemo(() => {
    const loadingComponent = infiniteScrollProps?.isLoading ? <Loading width={30} height={30} /> : <Fragment />;

    if (infiniteScrollProps?.isModal) {
      return <LoadingModalContainerStyled>{loadingComponent}</LoadingModalContainerStyled>;
    }

    return <LoadingContainerStyled>{loadingComponent}</LoadingContainerStyled>;
  }, [infiniteScrollProps?.isLoading, infiniteScrollProps?.isModal]);

  const renderTableContent = useMemo(() => {
    return (
      <TableContainerStyled>
        <TableContentContainerStyled aria-label="infinit-table" textTransform={config?.textTransform}>
          {renderHeader}
          <Body
            rows={rows}
            gridColumns={gridColumns}
            config={config?.body}
            onInfinityScroll={infiniteScrollProps?.onInfinityScroll}
            isLoading={infiniteScrollProps?.isLoading}
            alignLinesCenter={alignLinesCenter}
          />
        </TableContentContainerStyled>
        {renderLazyLoading}
      </TableContainerStyled>
    );
  }, [
    alignLinesCenter,
    config?.body,
    config?.textTransform,
    gridColumns,
    infiniteScrollProps?.isLoading,
    infiniteScrollProps?.onInfinityScroll,
    renderHeader,
    renderLazyLoading,
    rows,
  ]);

  return <TableWrapperStyled height={height}>{renderTableContent}</TableWrapperStyled>;
};
