import { ColorsV2Type } from 'styles/types/colors';
import { BiddingStatus } from 'domain-v2/bidding';

type BiddingStatusType = {
  label: string;
  color: ColorsV2Type;
};

type BiddingStatusMap = {
  [key: string]: BiddingStatusType;
};

const defaultValue: BiddingStatusType = {
  label: 'NÃO MAPEADO',
  color: ColorsV2Type.DarkGray,
};

export const convertBiddingStatus = (biddingStatus: BiddingStatus): BiddingStatusType => {
  const biddingStatusMap: BiddingStatusMap = {
    lance: {
      label: 'EM LEILÃO',
      color: ColorsV2Type.Blue,
    },
    aceito: {
      label: 'ARREMATADO',
      color: ColorsV2Type.Aqua,
    },
    cancelado: {
      label: 'BID RECUSADO',
      color: ColorsV2Type.Red,
    },
    recusado: {
      label: 'BID RECUSADO',
      color: ColorsV2Type.Red,
    },
  };

  return biddingStatusMap[biddingStatus] || defaultValue;
};
