export enum SaleOfferModalsActionType {
  SALE_OFFER_MODAL_OPEN = 'SALE_OFFER_MODAL_OPEN',
  SALE_OFFER_MODAL_CLOSE = 'SALE_OFFER_MODAL_CLOSE',
  SALE_OFFER_MODAL_SECONDARY_CLOSE = 'SALE_OFFER_MODAL_SECONDARY_CLOSE',
}

export enum SaleOfferDetailsActionType {
  SALE_OFFER_DETAILS_REQUEST = 'SALE_OFFER_DETAILS_REQUEST',
  SALE_OFFER_DETAILS_SUCCESS = 'SALE_OFFER_DETAILS_SUCCESS',
  SALE_OFFER_DETAILS_ERROR = 'SALE_OFFER_DETAILS_ERROR',
  SALE_OFFER_DETAILS_RESET = 'SALE_OFFER_DETAILS_RESET',
}

export enum SaleOfferListActionType {
  SALE_OFFER_LIST_REQUEST = 'SALE_OFFER_LIST_REQUEST',
  SALE_OFFER_LIST_SUCCESS = 'SALE_OFFER_LIST_SUCCESS',
  SALE_OFFER_LIST_ERROR = 'SALE_OFFER_LIST_ERROR',
  SALE_OFFER_LIST_RESET = 'SALE_OFFER_LIST_RESET',
  SALE_OFFER_SELECT_IDS = 'SALE_OFFER_SELECT_IDS',
}

export enum SaleOfferUpdateActionType {
  SALE_OFFER_UPDATE_REQUEST = 'SALE_OFFER_UPDATE_REQUEST',
  SALE_OFFER_UPDATE_SUCCESS = 'SALE_OFFER_UPDATE_SUCCESS',
  SALE_OFFER_UPDATE_ERROR = 'SALE_OFFER_UPDATE_ERROR',
  SALE_OFFER_UPDATE_RESET = 'SALE_OFFER_UPDATE_RESET',
}

export enum SaleOfferMatchListActionType {
  SALE_OFFER_MATCH_LIST_REQUEST = 'SALE_OFFER_MATCH_LIST_REQUEST',
  SALE_OFFER_MATCH_LIST_SUCCESS = 'SALE_OFFER_MATCH_LIST_SUCCESS',
  SALE_OFFER_MATCH_LIST_ERROR = 'SALE_OFFER_MATCH_LIST_ERROR',
  SALE_OFFER_MATCH_LIST_RESET = 'SALE_OFFER_MATCH_LIST_RESET',
  SALE_OFFER_MATCH_SELECT_FILTERS = 'SALE_OFFER_MATCH_SELECT_FILTERS',
}

export enum SaleOfferMatchProposalActionType {
  SALE_OFFER_MATCH_PROPOSAL_REQUEST = 'SALE_OFFER_MATCH_PROPOSAL_REQUEST',
  SALE_OFFER_MATCH_PROPOSAL_SUCCESS = 'SALE_OFFER_MATCH_PROPOSAL_SUCCESS',
  SALE_OFFER_MATCH_PROPOSAL_ERROR = 'SALE_OFFER_MATCH_PROPOSAL_ERROR',
  SALE_OFFER_MATCH_PROPOSAL_RESET = 'SALE_OFFER_MATCH_PROPOSAL_RESET',
}

export enum SaleOfferMatchListSelectedActionType {
  SALE_OFFER_LIST_SELECTED_REQUEST = 'SALE_OFFER_LIST_SELECTED_REQUEST',
  SALE_OFFER_LIST_SELECTED_SUCCESS = 'SALE_OFFER_LIST_SELECTED_SUCCESS',
  SALE_OFFER_LIST_SELECTED_ERROR = 'SALE_OFFER_LIST_SELECTED_ERROR',
  SALE_OFFER_LIST_SELECTED_RESET = 'SALE_OFFER_LIST_SELECTED_RESET',
}

export enum SaleOfferFreetimeActionType {
  SALE_OFFER_FREETIME_REQUEST = 'SALE_OFFER_FREETIME_REQUEST',
  SALE_OFFER_FREETIME_SUCCESS = 'SALE_OFFER_FREETIME_SUCCESS',
  SALE_OFFER_FREETIME_ERROR = 'SALE_OFFER_FREETIME_ERROR',
  SALE_OFFER_FREETIME_RESET = 'SALE_OFFER_FREETIME_RESET',
}

export enum SaleOfferListDownloadReportActionType {
  SALE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST = 'SALE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST',
  SALE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS = 'SALE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS',
  SALE_OFFER_LIST_DOWNLOAD_REPORT_ERROR = 'SALE_OFFER_LIST_DOWNLOAD_REPORT_ERROR',
  SALE_OFFER_LIST_DOWNLOAD_REPORT_RESET = 'SALE_OFFER_LIST_DOWNLOAD_REPORT_RESET',
}

export enum SaleOfferActionType {
  SALE_OFFER_RESET = 'SALE_OFFER_RESET',
}
