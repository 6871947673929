import React, { Fragment, MouseEvent, ReactElement, useMemo } from 'react';
import * as S from './dropdown-button-table-view.styled';
import { DropdownButtonIcon } from 'assets/icons/dropdown-button.icon';
import { IOptions } from './dropdown-button-table.types';
import { MoreVertical } from 'lucide-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface IDropdownButtonViewProps {
  options: Array<IOptions>;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  iconPosition: 'vertical' | 'horizontal';
  handleToggleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClick: (option: IOptions) => void;
}

export const DropdownButtonTableView: React.FC<IDropdownButtonViewProps> = ({
  options,
  isOpen,
  anchorEl,
  iconPosition,
  handleToggleClick,
  handleClick,
}) => {
  const renderOptions = useMemo((): ReactElement | undefined => {
    if (isOpen) {
      return (
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleToggleClick}
        >
          {options.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClick(option)} disabled={option.disabled}>
              <S.Label>{option.label}</S.Label>
            </MenuItem>
          ))}
        </Menu>
      );
    }
  }, [anchorEl, handleClick, handleToggleClick, isOpen, options]);

  const renderIcon = useMemo((): ReactElement => {
    if (iconPosition === 'horizontal') {
      return <DropdownButtonIcon />;
    }

    return <MoreVertical />;
  }, [iconPosition]);

  return (
    <Fragment>
      <S.DropDownButton
        id="fade-button"
        aria-controls={isOpen ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleToggleClick}
        disableRipple
      >
        {renderIcon}
      </S.DropDownButton>

      {renderOptions}
    </Fragment>
  );
};
