import { TransactionDetails } from 'domain-v2/transactions/details';

export const transactionInitialValues: TransactionDetails = {
  id: 0,
  advalorem: 0,
  companyBuyerId: 0,
  companyId: 0,
  companySellerId: 0,
  discharge: 0,
  feeAnticipation: 0,
  financialStatus: '',
  gris: 0,
  helperTotal: 0,
  icms: 0,
  invoicedBy: '',
  iss: 0,
  margin: 0,
  operatedBy: '',
  otherTaxes: 0,
  penaltyBonus: 0,
  releaseDate: '',
  scheduleId: 0,
  valueFreight: 0,
  surplusTime: 0,
  taker: '',
  toll: 0,
  totalFee: 0,
  totalFreight: 0,
  totalGrisAdvalorem: 0,
  totalService: 0,
  shippingCompany: {
    name: '',
  },
  title: '',
  status: '',
  created_at: '',
  updated_at: '',
  isDisabled: false,
  ownerTransaction: '',
};
