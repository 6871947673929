import styled from 'styled-components';

export const UnreadContainerStyled = styled.span`
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.54rem;
  right: 0.5rem;
  color: white;
  background-color: #e04747;
  border-radius: 100%;
  text-align: center;
  font-size: 9px;
`;

export const UnreadStyled = styled.div``;
