import { useCallback, useReducer } from 'react';
import { chatInitialState } from '../../chat.constants';
import { ChatUserEmailsActionType } from 'src-new/contexts/chat/chat.action';
import { IChatUserEmails } from 'src-new/contexts/chat/types/chat-user-emails.types';
import { chatUserEmailsReducer } from 'src-new/contexts/chat/reducers/chat-user-emails/chat-user-emails.reducer';
import { IUserChat } from 'src-new/domains/user-chat.domain';

export const useChatUserEmailsContextValues = (): IChatUserEmails => {
  const [state, dispatch] = useReducer(chatUserEmailsReducer, chatInitialState);

  const setChatUserEmailsRequest = useCallback(() => {
    dispatch({ type: ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_REQUEST, payload: undefined });
  }, []);

  const setChatUserEmailsSuccess = useCallback((user?: IUserChat) => {
    dispatch({ type: ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_SUCCESS, payload: user });
  }, []);

  const setChatUserEmailsError = useCallback(() => {
    dispatch({ type: ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_ERROR, payload: undefined });
  }, []);

  const setChatUserEmailsReset = useCallback(() => {
    dispatch({ type: ChatUserEmailsActionType.CHAT_SET_USER_EMAILS_RESET, payload: undefined });
  }, []);

  return {
    ...state.chatUserEmails,
    setChatUserEmailsRequest,
    setChatUserEmailsSuccess,
    setChatUserEmailsError,
    setChatUserEmailsReset,
  };
};
