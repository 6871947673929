import dayjs from 'dayjs';
import { IWeighingListDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list.domain';
import { IWeighingListResponse } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/services/get-weighing-list/get-weighing-list.types';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { InputMaskCPFEntityToDomain } from 'utils/remove-input-mask/remove-input-mask';

export const convertWeighingResponseToDomain = (weighingResponse: IWeighingListResponse): IWeighingListDomain => {
  return {
    ...weighingResponse,
    checkingDate: weighingResponse.checkingDate ? dayjs(weighingResponse.checkingDate).format('DD/MM/YYYY HH:mm') : '',
    entryDate: weighingResponse.entryDate ? dayjs(weighingResponse.entryDate).format('DD/MM/YYYY HH:mm') : '',
    exitDate: weighingResponse.exitDate ? dayjs(weighingResponse.exitDate).format('DD/MM/YYYY HH:mm') : '',
    scheduledDateTime: weighingResponse.scheduledDateTime
      ? dayjs(weighingResponse.scheduledDateTime).format('DD/MM/YYYY HH:mm')
      : '',
    driver: {
      ...weighingResponse.driver,
      cpf: InputMaskCPFEntityToDomain(weighingResponse.driver.cpf),
    },
    vehicle: {
      ...weighingResponse.vehicle,
      category: convertVehicleCategory(weighingResponse.vehicle.category),
      type: weighingResponse.vehicle.type ? weighingResponse.vehicle.type : 'Carreta',
    },
    shippingCompany: { name: weighingResponse.shippingCompany.name },
    originWeight: weighingResponse.originWeight,
    item: {
      id: weighingResponse.item.id,
      description: weighingResponse.item.description,
    },
  };
};
