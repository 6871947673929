export const convertOperationTypeEntityToLabel = (operationType: string): string => {
  switch (operationType) {
    case '':
      return '';
    case 'INBOUND':
      return 'Inbound';
    case 'OUTBOUND':
      return 'Outbound';
    case 'BACKHAUL':
      return 'Backhaul';
    case 'TRANSFERENCIA':
      return 'Transferência';
    default:
      return 'Não Mapeado';
  }
};
