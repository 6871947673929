import { SetStateAction, Dispatch, useCallback, useContext } from 'react';
import { Buffer } from 'buffer';
import { limitToLast, off, onChildAdded, onValue, query, ref } from 'firebase/database';
import { database } from 'App';
import { ITruckRoutes } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-execution.types';
import { IMapsSnapshot } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/schedule-maps.types';
import { ITruckRoute } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/truck-route.domain';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import {
  calculateSpeed,
  filterListByFiveMinuteInterval,
  filterTruckRoutesByTimeInterval,
  formatSnapshots,
} from '../../utils/route-tracking-functions/route-tracking-functions.util';

export const useGetExecutedRouteApp = () => {
  const { scheduleExecution, scheduleDetails } = useContext(ScheduleContext);
  const { id, driver } = scheduleDetails.scheduleDetails;
  const { setFirebaseTruckRoutes } = scheduleExecution.mapAccordionProps;

  return useCallback(
    (setIsLoadingFireBase: Dispatch<SetStateAction<boolean>>) => {
      if (driver) {
        const row = Buffer.from(`${driver.cpf}:${driver.id}:${id}`).toString('base64');
        const dbRef = ref(database, 'trackings/' + row);
        const lastTruckRoute = query(dbRef, limitToLast(1));
        let firebaseTruckRoutes: ITruckRoutes = {
          routes: [],
          routePositions: [],
        };

        onValue(
          dbRef,
          (data) => {
            const snapshots = data.val();
            if (snapshots) {
              const snapshotsKeys = Object.keys(snapshots);

              if (snapshotsKeys.length > 1) {
                const orderedList: Array<IMapsSnapshot> = snapshotsKeys
                  .map((timestamp) => ({
                    timestamp: parseInt(timestamp),
                    content: snapshots[timestamp],
                  }))
                  .sort((a, b) => a.timestamp - b.timestamp);

                firebaseTruckRoutes.routes = formatSnapshots(orderedList);
                firebaseTruckRoutes.routePositions = filterListByFiveMinuteInterval(orderedList);
                setFirebaseTruckRoutes(firebaseTruckRoutes);
              }
            }
            setIsLoadingFireBase(false);
          },
          { onlyOnce: true },
        );

        onChildAdded(lastTruckRoute, (data) => {
          const { latitude, longitude, created_at, speed } = data.val();
          const lat = Number(latitude);
          const lng = Number(longitude);
          const location: ITruckRoute = {
            latitude: lat,
            longitude: lng,
            speed: calculateSpeed(speed),
            created_at: created_at ?? 0,
          };
          const routes = [...firebaseTruckRoutes.routes, location];

          firebaseTruckRoutes = {
            ...firebaseTruckRoutes,
            routes: routes,
            routePositions: filterTruckRoutesByTimeInterval(routes),
          };
          setFirebaseTruckRoutes(firebaseTruckRoutes);
        });

        return () => {
          off(dbRef);
        };
      } else {
        setIsLoadingFireBase(false);
      }
    },
    [driver, id, setFirebaseTruckRoutes],
  );
};
