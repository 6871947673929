import React, { ReactNode } from 'react';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { TagView } from './tag-view.component';

export interface ITagProps {
  label: string;
  color: ThemeColorsV2Type;
  variant: 'ghost' | 'solid';
  size?: 'x-sm' | 'md' | 'lg';
  display?: 'flex' | 'inline-flex';
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
}

export const Tag: React.FC<ITagProps> = (props) => {
  return <TagView {...props} />;
};
