import { useQueryClient } from 'react-query';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { ListBiddingsTable } from './list';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { acceptIdlenessBidding, discardIdlenessBidding, getBiddingByIdlenessId } from 'services/bidding';
import { BiddingModal, IdlenessBiddingModal } from 'pages-v2/idleness-biddings/types';
import { getIdlenessByIdService } from 'pages-v2/idleness/services/get-idleness-by-id';
import { ModalState } from 'domain-v2/inputs/modals';
import { IdlenessBiddingBuyValues } from 'domain-v2/idleness-bidding/form';
import { IIdlenessBidding } from 'domain-v2/bidding';
import { IIdlenessHeaderModal } from 'components-v2/common/modals/match/types';
import { ModalMatches } from 'components-v2/common/modals/match';
import { ModalBiddingForm } from 'components-v2/common/modals/bidding-form';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';

interface BiddingsListModalProps {
  idlenessId: number;
  setBuyModal: Dispatch<SetStateAction<ModalState<IdlenessBiddingModal>>>;
}

const initialValues = {} as IdlenessBiddingBuyValues;

export const ModalIdlenessBiddings: FC<BiddingsListModalProps> = ({ idlenessId, setBuyModal }) => {
  const [biddings, setBiddings] = useState<Array<IIdlenessBidding>>([]);
  const [idleness, setIdleness] = useState<IIdlenessHeaderModal | undefined>();

  const [buyBidding, setBuyBidding] = useState<ModalState<BiddingModal>>({
    open: false,
    data: {
      biddingId: 0,
      proposalValue: 0,
    },
  });

  const [discardBidding, setDiscardBidding] = useState<ModalState<BiddingModal>>({
    open: false,
    data: {
      biddingId: 0,
    },
  });

  const getCompanyHook = useGetCompany();

  const reactQueryClient = useQueryClient();

  const buyBiddingFormRef = useRef<FormikProps<any>>(null);

  const handleDiscardBidding = async () => {
    try {
      await discardIdlenessBidding(discardBidding.data.biddingId);

      showMessageFormatted({
        type: 'success',
        message: 'Bidding descartado',
      });

      await reactQueryClient.invalidateQueries(['idlenessBiddingList']);

      setDiscardBidding((oldModal) => ({
        ...oldModal,
        open: false,
      }));
    } catch (error: any) {
      showMessageFormatted({
        type: 'error',
        message: error.message || error,
      });
    }
  };

  const handleBuyBidding = async (body: any) => {
    try {
      await acceptIdlenessBidding(buyBidding.data.biddingId, idlenessId, body);

      showMessageFormatted({
        type: 'success',
        message: 'Agendamento Realizado',
      });

      await reactQueryClient.invalidateQueries(['idlenessBiddingList']);

      setBuyBidding((oldModal) => ({
        ...oldModal,
        open: false,
      }));
    } catch (error: any) {
      showMessageFormatted({
        message: error.response.data.message,
        type: 'error',
      });
    }
  };

  const handleCompanyImage = useCallback(
    async (biddings: Array<IIdlenessBidding>) => {
      return biddings.map(async (bidding) => {
        const company = await getCompanyHook(bidding.companyId);

        return { ...bidding, companyImage: company?.fileKey };
      });
    },
    [getCompanyHook],
  );

  useEffect(() => {
    (async () => {
      try {
        const biddings = await getBiddingByIdlenessId(idlenessId);

        const promises = await handleCompanyImage(biddings);

        const resolvedPromises: Array<IIdlenessBidding> = await Promise.all(promises);

        setBiddings(resolvedPromises);
      } catch (err) {
        showToast({
          message: 'Houve um erro ao buscar os biddings',
          type: 'error',
        });
      }
    })();
  }, [handleCompanyImage, idlenessId]);

  useEffect(() => {
    (async () => {
      try {
        const idleness = await getIdlenessByIdService(idlenessId);

        setIdleness(idleness);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    })();
  }, [idlenessId]);

  return (
    <>
      {buyBidding.open && (
        <Formik
          innerRef={buyBiddingFormRef as any}
          initialValues={initialValues}
          validateOnMount={false}
          onSubmit={handleBuyBidding}
          enableReinitialize
        >
          <ModalBiddingForm
            idleness={{
              ...idleness,
              proposalValue: buyBidding.data.proposalValue,
              biddingId: buyBidding.data.biddingId,
            }}
            handleClose={() =>
              setBuyBidding((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            handleBuyBidding={handleBuyBidding}
          />
        </Formik>
      )}
      {discardBidding.open && (
        <ModalConfirmation
          title="Descartar Bid"
          secondTitle={'Você tem certeza que deseja descartar o bid?'}
          handleCancel={() =>
            setDiscardBidding((oldModal) => ({
              ...oldModal,
              open: false,
            }))
          }
          onConfirmation={handleDiscardBidding}
          size="small"
          type="delete"
          description={undefined}
          deleteTitle="Descartar"
        />
      )}
      <ModalMatches
        closeModal={() =>
          setBuyModal((oldModal) => ({
            ...oldModal,
            open: false,
          }))
        }
        idleness={idleness}
        list={
          <ListBiddingsTable
            biddings={biddings}
            pageInfo={{
              totalItems: 0,
              itemCount: 0,
              itemsPerPage: 10,
              totalPages: 0,
              currentPage: 1,
            }}
            isLoading={false}
            handlePaginate={() => null}
            setBuyBidding={setBuyBidding}
            setDiscardBidding={setDiscardBidding}
          />
        }
      />
    </>
  );
};
