import API from 'utils/API/API';
import { IScheduleOriginAndDestinationPayload } from './update-schedule-origin-and-destination-details.types';

export const updateScheduleOriginAndDestinationDetailsService = async (
  id: string,
  payload: IScheduleOriginAndDestinationPayload,
) => {
  const call = await API.put<IScheduleOriginAndDestinationPayload>(`v3/schedules/origin-destination/${id}`, payload);

  return call;
};
