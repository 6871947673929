import { IHomeCardsDomain } from '../../../../domains/home-cards/home-cards.domain';
import { IHomeCardsResponse } from '../../get-home-cards.types';

export const convertHomeCardsResponseToDomain = (cardResponse: IHomeCardsResponse): IHomeCardsDomain => {
  return {
    purchaseOffer: {
      potencialValue: cardResponse.purchaseStats.potentialSavingReais,
      totalMatches: cardResponse.purchaseStats.totalMatches,
      totalRoutes: cardResponse.purchaseStats.registeredRoutes,
    },
    saleOffer: {
      potencialValue: cardResponse.salesOffersStats.potentialRevenueReais,
      totalMatches: cardResponse.salesOffersStats.totalMatches,
      totalRoutes: cardResponse.salesOffersStats.registeredRoutes,
    },
  };
};
