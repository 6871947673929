import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { UserContext } from 'state/user-context';
import { BodyView } from './body-view.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { useUserChatDetails } from 'src-new/hooks/use-get-company/use-user-details-chat/use-user-details-chat.hook';

export interface IBodyProps {
  children: ReactNode;
}

export const Body: React.FC<IBodyProps> = ({ children }) => {
  const { statusRequest } = useContext(ChatButtonContext);
  const { user } = useContext(UserContext);
  const userChatDetails = useUserChatDetails();

  const isLoading = useMemo(() => statusRequest === 'PROCESSING', [statusRequest]);

  useEffect(() => {
    if (user && statusRequest === 'INITIAL') {
      userChatDetails(user);
    }
  }, [userChatDetails, statusRequest, user]);

  if (isLoading) {
    return <Loading />;
  }

  return <BodyView>{children}</BodyView>;
};
