import { useCallback, useContext, useMemo } from 'react';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getScheduleBiddingByIdService } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/get-schedule-bidding-by-id/get-schedule-bidding-by-id.service';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQuery } from 'react-query';
import { IScheduleDetailsDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details.domain';
import { useParams } from 'react-router-dom';

export const useGetScheduleById = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const { isLoading } = useQuery<IScheduleDetailsDomain>({
    queryKey: ['schedule-details-by-id', scheduleId],
    queryFn: () => getScheduleBiddingByIdService(scheduleId),
    onSuccess(data) {
      scheduleDetails.setScheduleDetailsSuccess(data);
    },
    onError(error) {
      scheduleDetails.setScheduleDetailsError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnMount: true,
  });

  return useCallback(() => {
    if (isLoading) {
      scheduleDetails.setScheduleDetailsRequest();
    }
  }, [isLoading, scheduleDetails]);
};
