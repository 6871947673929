import React, { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';
import { matchesListFiltersInitialValues } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters-initial-values.types';
import { MatchesFilterView } from './matches-filter-view.component';
import { IParamsFilter } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.types';

interface IMatchesFilterProps {
  matchesFilters: Array<IParamsFilter>;
  handleModalFilter: () => void;
}

export const MatchesFilter: React.FC<IMatchesFilterProps> = ({ matchesFilters, handleModalFilter }) => {
  const { matchesKanbanList } = useContext(MatchesContext);
  const reactQueryClient = useQueryClient();

  const matchesFiltersValues = useMemo((): IMatchesListParams => {
    return { ...matchesKanbanList.matchesListFiltersProps.filters, page: 1 };
  }, [matchesKanbanList.matchesListFiltersProps.filters]);

  const appliedFilters = useMemo((): number => {
    const filters = matchesFilters.filter((filter) => !Array.isArray(filter.value));
    return filters.length;
  }, [matchesFilters]);

  const handleResetFilter = useCallback(async () => {
    matchesKanbanList.matchesListFiltersProps.setFilters(matchesListFiltersInitialValues);
    handleModalFilter();
    await reactQueryClient.invalidateQueries(['matches-kanban-list', matchesListFiltersInitialValues]);
  }, [handleModalFilter, matchesKanbanList.matchesListFiltersProps, reactQueryClient]);

  const onFilterSubmit = useCallback(
    (values: IMatchesListParams) => {
      matchesKanbanList.matchesListFiltersProps.setFilters({ ...values, page: 1 });
      sessionStorage.setItem('matches-list-filters', JSON.stringify({ ...values }));
      handleModalFilter();
    },
    [handleModalFilter, matchesKanbanList.matchesListFiltersProps],
  );

  return (
    <MatchesFilterView
      matchesFiltersValues={matchesFiltersValues}
      appliedFilters={appliedFilters}
      handleResetFilter={handleResetFilter}
      handleModalFilter={handleModalFilter}
      onFilterSubmit={onFilterSubmit}
    />
  );
};
