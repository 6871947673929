import { FC, useContext, useEffect, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleDetailsChatButtonView } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-page-body/components/schedule-details-chat-button/schedule-details-chat-button-view.component';
import { ChatType } from 'src-new/components/chat-button/chat-button.types';
import { useGetGroupChatScheduleConversationsChat } from 'src-new/hooks/chat/use-get-group-chat-schedule-conversations-chat/use-get-group-chat-schedule-conversations-chat.hook';
import { UserContext } from 'state/user-context';
import { useGetEligibleUsersChat } from 'src-new/hooks/chat/use-get-eligible-users-chat/use-get-eligible-users-chat.hook';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { useCheckExistingThreadsChat } from 'src-new/hooks/chat/use-check-existing-threads-chat/use-check-existing-threads-chat.hook';

export const ScheduleDetailsChatButton: FC = () => {
  const { chatEligibleUsers } = useContext(ChatContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const { user } = useContext(UserContext);
  const getGroupChat = useGetGroupChatScheduleConversationsChat();
  const getEligibleUsers = useGetEligibleUsersChat();
  const checkExistingThreadsChat = useCheckExistingThreadsChat();

  const isLoading = useMemo(() => chatEligibleUsers.statusRequest === 'PROCESSING', [chatEligibleUsers.statusRequest]);

  const schedule = useMemo(() => {
    return scheduleDetails.scheduleDetails;
  }, [scheduleDetails.scheduleDetails]);

  const mountChatProps = useMemo(
    (): ChatType => ({
      typeChat: 'GROUP',
      chatsObjects: getGroupChat(schedule, user),
    }),
    [getGroupChat, schedule, user],
  );

  useEffect(() => {
    if (
      scheduleDetails.statusRequest === 'SUCCESS' &&
      chatEligibleUsers.validateThreadsActions.statusRequest === 'SUCCESS' &&
      chatEligibleUsers.statusRequest === 'INITIAL'
    ) {
      const allThreadsHaveCreated = chatEligibleUsers.validateThreadsActions.haveCreated;

      if (!allThreadsHaveCreated) {
        getEligibleUsers({ flowType: 'SCHEDULE', scheduleId: scheduleDetails.scheduleDetails.id });
      }
    }
  }, [
    chatEligibleUsers.statusRequest,
    chatEligibleUsers.validateThreadsActions.haveCreated,
    chatEligibleUsers.validateThreadsActions.statusRequest,
    getEligibleUsers,
    scheduleDetails.scheduleDetails.id,
    scheduleDetails.statusRequest,
  ]);

  useEffect(() => {
    if (
      scheduleDetails.statusRequest === 'SUCCESS' &&
      chatEligibleUsers.validateThreadsActions.statusRequest === 'INITIAL'
    ) {
      checkExistingThreadsChat(mountChatProps);
    }
  }, [
    chatEligibleUsers.validateThreadsActions.statusRequest,
    checkExistingThreadsChat,
    mountChatProps,
    scheduleDetails.statusRequest,
  ]);

  return <ScheduleDetailsChatButtonView chatProps={mountChatProps} isLoading={isLoading} />;
};
