interface IOption {
  value: DocumentType;
  label: string;
}

enum DocumentType {
  CONTRATO_SOCIAL = 'Contrato Social',
  CNH = 'CNH',
  DUT = 'DUT',
  CRLV = 'CRLV',
  APOLICE = 'Apólice',
  CONTRATO = 'Contrato',
  CHECKLIST = 'Checklist',
  OUTROS = 'Outros',
  LAUDO_TECNICO = 'Laudo Técnico',
  ADITIVO = 'Aditivo',
  CONTA_DE_CONSUMO = 'Conta de Consumo',
  RG = 'RG',
  CERTIDAO_NEGATIVA = 'Certidão Negativa',
  CERTIFICADO = 'Certificado',
}

export const documentTypeOptions: IOption[] = [
  { value: DocumentType.CONTRATO_SOCIAL, label: 'Contrato Social' },
  { value: DocumentType.CNH, label: 'CNH' },
  { value: DocumentType.DUT, label: 'DUT' },
  { value: DocumentType.CRLV, label: 'CRLV' },
  { value: DocumentType.APOLICE, label: 'Apólice' },
  { value: DocumentType.CONTRATO, label: 'Contrato' },
  { value: DocumentType.CHECKLIST, label: 'Checklist' },
  { value: DocumentType.OUTROS, label: 'Outros' },
  { value: DocumentType.LAUDO_TECNICO, label: 'Laudo Técnico' },
  { value: DocumentType.ADITIVO, label: 'Aditivo' },
  { value: DocumentType.CONTA_DE_CONSUMO, label: 'Conta de Consumo' },
  { value: DocumentType.RG, label: 'RG' },
  { value: DocumentType.CERTIDAO_NEGATIVA, label: 'Certidão Negativa' },
  { value: DocumentType.CERTIFICADO, label: 'Certificado' },
];
