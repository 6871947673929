import React, { Fragment } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { SaleOfferModalMatchTable } from './components/sale-offer-modal-match-table/sale-offer-modal-match-table.component';

export interface ISaleOfferModalMatchTableTabsViewProps {
  tabsContent: Array<ITabContent>;
  activeTab: string;
  handleChangeTab: (tabName: string) => void;
}

export const SaleOfferModalMatchTableTabsView: React.FC<ISaleOfferModalMatchTableTabsViewProps> = ({
  tabsContent,
  activeTab,
  handleChangeTab,
}) => {
  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)} />
      <SaleOfferModalMatchTable />
    </Fragment>
  );
};
