/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate } from 'react-router-dom';
import React from 'react';
import moment from 'moment/moment';
import ErrorIcon from '@mui/icons-material/Error';

import TooltipComponent from 'components/Tooltip';

interface IProps {
  collectDates: {
    initial?: string;
    end?: string;
  };
  deliveryDates: {
    initial?: string;
    end?: string;
  };
  occurrences: {
    id: number;
  }[];
  freightId: number;
}
export const TooltipOcurrences: React.FC<IProps> = ({ collectDates, deliveryDates, occurrences, freightId }) => {
  const navigate = useNavigate();

  const currentDate = moment().format('YYYY/MM/DD');

  const convertCollectDate =
    moment(collectDates.end).format('YYYY/MM/DD)') || moment(collectDates.initial).format('YYYY/MM/DD)');

  const convertDeliveryDate =
    moment(deliveryDates.end).format('YYYY/MM/DD)') || moment(deliveryDates.initial).format('YYYY/MM/DD)');

  const goToCreateOccurrence = (occurrenceId: number) => {
    navigate(`/tracking-ocorrencias/atualizar/${occurrenceId}/${freightId}`);
  };

  if (occurrences.length === 0 && (currentDate > convertCollectDate || currentDate > convertDeliveryDate)) {
    return (
      <TooltipComponent
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
            }}
          >
            <strong>Carregamento em Atraso</strong>
          </div>
        }
        icon={<ErrorIcon sx={{ width: 19, height: 19 }} htmlColor="#FAD42F" />}
        location={'left'}
      />
    );
  }

  if (occurrences.length > 0) {
    return (
      <TooltipComponent
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
            }}
          >
            <strong>Ocorrência(s) em Aberta</strong>
            <div style={{ display: 'flex', gap: 5, fontWeight: 700 }}>
              ID's:{' '}
              {occurrences.map((occurrence) => (
                <span
                  key={occurrence.id}
                  onClick={() => goToCreateOccurrence(occurrence.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {occurrence.id}
                </span>
              ))}
            </div>
          </div>
        }
        icon={<ErrorIcon sx={{ width: 19, height: 19 }} htmlColor="red" />}
        location={'left'}
      />
    );
  }

  return (
    <TooltipComponent
      title={''}
      icon={<ErrorIcon sx={{ width: 19, height: 19 }} color="disabled" />}
      location={'left'}
    />
  );
};
