import styled, { keyframes } from 'styled-components';
import { CSSProperties } from 'react';

interface IStyledProps {
  isSpinnerComponent?: boolean;
}

export const ChatWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
`;

export const chatStyled: CSSProperties = {
  width: '356px',
  height: '502px',
};

export const SpinnerContainerStyled = styled.div`
  position: absolute;
  bottom: 40%;
  right: 40%;
  padding: 4px;
  z-index: 1000;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<IStyledProps>`
  background-color: ${({ isSpinnerComponent }) => (isSpinnerComponent ? 'transparent' : '#12264eff')};
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ececec;
  border-radius: 50%;
  width: ${({ isSpinnerComponent }) => (isSpinnerComponent ? '60px' : '34px')};
  height: ${({ isSpinnerComponent }) => (isSpinnerComponent ? '60px' : '34px')};
  animation: ${spin} 0.7s linear infinite;
`;

export const ChatLoadingContainerStyled = styled.div`
  position: absolute;
  right: 0;
  top: 517px;
  padding: 15px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border-style: none;
  background-color: rgb(18, 38, 78);
  background-position: center 17px;
  box-shadow: 0 4px 4px 0 #00000040;

  //width: 354px;
  //height: 500px;
  z-index: 1000;
`;
