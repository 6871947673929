import { Tag } from 'logshare-ui-kit';
import { FC, ReactElement, useMemo } from 'react';
import { ColorsV2Type } from 'styles/types/colors';
import { formatDecimal } from 'utils/format-numbers';
import * as S from './schedule-details-resume-step-step-view.styled';
import { convertFreightType } from 'src-new/utils/convert-freight-type/convert-freight-type.util';
import { convertOperationType } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';

interface IScheduleDetailsResumeStepByStepViewProps {
  statusCode: {
    labelStatus: string;
    colorStatus: ColorsV2Type;
  };
  co2: number;
  haulType: string;
  scheduleType: string;
  operationType: string;
  transportRouterId?: number;
  invoicedBy: string;
}

export const ScheduleDetailsResumeStepByStepView: FC<IScheduleDetailsResumeStepByStepViewProps> = ({
  statusCode,
  co2,
  haulType,
  scheduleType,
  operationType,
  transportRouterId,
  invoicedBy,
}) => {
  const renderContentStatusCode = useMemo((): ReactElement => {
    const invoicedByTag = invoicedBy === 'LOGSHARE' ? invoicedBy : 'TRANSPORTADORA';
    return (
      <S.TimelineContainerStatus>
        <Tag label={statusCode.labelStatus} color={statusCode.colorStatus} variant="solid" display="flex" size="md" />
        <Tag label={haulType} color={ColorsV2Type.Blue} variant="solid" display="flex" size="md" />
        <Tag
          label={convertFreightType(scheduleType).label}
          color={ColorsV2Type.Blue}
          variant="ghost"
          display="flex"
          size="md"
        />
        <Tag label={convertOperationType(operationType).label} color={ColorsV2Type.Blue} variant="ghost" display="flex" size="md" />
        <Tag
          LeftIcon={
            <S.TagText>
              <DoubleArrowDownBlueIcon />
              CO₂
            </S.TagText>
          }
          label={`${formatDecimal(co2 || 0)} KG`}
          color={ColorsV2Type.Blue}
          variant="ghost"
          display="inline-flex"
          size="lg"
        />
        {!!transportRouterId && (
          <Tag label={`V ${transportRouterId}`} color={ColorsV2Type.Blue} variant="ghost" display="flex" size="md" />
        )}
        <Tag
          label={`FATURADO POR ${invoicedByTag}`}
          color={ColorsV2Type.Blue}
          variant="ghost"
          display="flex"
          size="md"
        />
      </S.TimelineContainerStatus>
    );
  }, [
    co2,
    haulType,
    invoicedBy,
    operationType,
    scheduleType,
    statusCode.colorStatus,
    statusCode.labelStatus,
    transportRouterId,
  ]);

  return <S.Wrapper>{renderContentStatusCode}</S.Wrapper>;
};
