import { backhaulFilterService } from 'pages/home/services/backhaul/ofered-backhaul/backhaul-list.service';
import { IBackhaulFilter } from 'pages/home/backhaul/types/filter/filter-backhaul';
import { IBackhaulListValues } from 'domain/backhaul';

const useBackhaulOferedHook = () => {
  return async (params: IBackhaulFilter): Promise<IBackhaulListValues[]> => {
    const response = await backhaulFilterService(params);
    return response;
  };
};

export { useBackhaulOferedHook };
