import { useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';

import LoginMicrosoftView from './login-microsoft-view.component';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ILoginMicrsoft } from '../../../../types/login-microsof.types';
import { useBuildAuthUrlMicrosoft } from '../../hooks/use-identity-provider.hook';
import { LoginMicrosoftSchemaValidation } from '../login-microsoft-form/login-microsoft-schema-validation';

const initialValues: ILoginMicrsoft = {
  email: '',
};

const TELA_ANTERIOR = -1;

const LoginMicrosoft: React.FC = () => {
  const navigate = useNavigate();
  const goBackToLogin = useCallback(() => navigate(TELA_ANTERIOR), [TELA_ANTERIOR, navigate]);
  const buildAuthUrlMicrosoft = useBuildAuthUrlMicrosoft();

  const handleSubmit = useCallback(
    async ({email}: ILoginMicrsoft) => {
      const url = buildAuthUrlMicrosoft(email);
      if (!url) {
        showMessageFormatted({
          message: 'Dominio de e-mail inválido.',
          type: 'error',
        });
        return;
      }
      window.open(url, '_self');   
      },
    [],
  );

  return (
    <LoginMicrosoftView 
      goBackToLogin={goBackToLogin}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={LoginMicrosoftSchemaValidation}
    />
  );
};

export default LoginMicrosoft;
