import React from 'react';
import moment from 'moment';

import { CardRouterBodyStyled, ContainerScrollStyled, ContainerStyledOne } from './kanban-content.styled';
import KanbanTitle from './components/kanban-title/kanban-title';
import Kanban from './components/kanban-header/kanban-header';
import CardContent from './components/card-content/card-content';

import { IKanbanProps } from 'pages/task-vision/types/types';

const KanbanSection: React.FC<IKanbanProps> = ({ title, bgColor, totalItems, data, handleSelectedFreight }) => {
  return (
    <ContainerStyledOne>
      <KanbanTitle title={title} color={bgColor} />
      <Kanban bgColor={bgColor} title={title} totalItems={totalItems} />
      <ContainerScrollStyled>
        <CardRouterBodyStyled>
          {data?.map((item) => (
            <CardContent
              key={item.id}
              id={String(item.id)}
              name={item.scheduleClientOrigin?.client?.tradeName || item.scheduleClientOrigin?.locality?.name || ''}
              vehicle={item.scheduleCargo?.vehicleType || ''}
              vehicleCategory={item.scheduleCargo?.vehicleCategoryType || ''}
              origin={
                item.scheduleClientOrigin?.client?.city
                  ? item.scheduleClientOrigin?.client?.city + ' - ' + item.scheduleClientOrigin?.client?.uf
                  : item.scheduleClientOrigin?.locality?.city + ' - ' + item.scheduleClientOrigin?.locality?.uf
              }
              destiny={
                item.scheduleClientDestiny?.client?.city
                  ? item.scheduleClientDestiny?.client?.city + ' - ' + item.scheduleClientDestiny?.client?.uf
                  : item.scheduleClientDestiny?.locality?.city + ' - ' + item.scheduleClientDestiny?.locality?.uf
              }
              collect={moment(item.scheduleDetails?.levyInitialDate).format('DD/MM/YYYY')}
              delivery={
                item.scheduleDetails?.deliveryEndDate
                  ? moment(item.scheduleDetails?.deliveryEndDate).format('DD/MM/YYYY')
                  : moment(item.scheduleDetails?.deliveryInitialDate).format('DD/MM/YYYY')
              }
              typeSchedule={item.typeSchedule || ''}
              typeOperation={item.scheduleCargo?.operationType || ''}
              handleSelected={() => handleSelectedFreight(String(item.id))}
            />
          ))}
        </CardRouterBodyStyled>
      </ContainerScrollStyled>
    </ContainerStyledOne>
  );
};

export default React.memo(KanbanSection);
