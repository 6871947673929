import dayjs from 'dayjs';
import {
  ITimeRecording,
  ITimeRecordingLabel,
} from '../components/schedule-details-tabs/components/schedule-details-execution/components/execution-time-recording/execution-time-recording.types';
import { IScheduleDetailsComplementDomain } from '../domains/schedule-details.domain';
import { compareDates } from 'src-new/utils/compare-dates.util';
import { formatAndCalcDate } from 'src-new/utils/format-date';

export const scheduleTimeRecordingData = (timeRecording: IScheduleDetailsComplementDomain): Array<ITimeRecording> => {
  const currentDate = dayjs().toISOString();

  return [
    {
      label: ITimeRecordingLabel.loadSchedulingDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 24),
      realDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 24),
      agent: 'web',
      status: true,
      active: true,
    },
    {
      label: ITimeRecordingLabel.allocationShippingDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
      realDate: formatAndCalcDate(timeRecording.allocationShippingDate),
      agent: 'web',
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
        timeRecording.allocationShippingDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.allocationShippingDate,
    },
    {
      label: ITimeRecordingLabel.acceptShipping,
      planDate: '-',
      realDate: formatAndCalcDate(timeRecording.acceptShipping),
      agent: 'web',
      status: true,
      active: !!timeRecording.acceptShipping,
    },
    {
      label: ITimeRecordingLabel.allocationDriverDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
      realDate: formatAndCalcDate(timeRecording.allocationDriverDate),
      agent: 'web',
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
        timeRecording.allocationDriverDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.allocationDriverDate,
    },
    {
      label: ITimeRecordingLabel.allocationVehicleDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
      realDate: formatAndCalcDate(timeRecording.allocationVehicleDate),
      agent: 'web',
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 12),
        timeRecording.allocationVehicleDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.allocationVehicleDate,
    },
    {
      label: ITimeRecordingLabel.startDate,
      planDate: '-',
      realDate: formatAndCalcDate(timeRecording.startDate),
      agent: timeRecording.startDateAgent,
      status: true,
      active: !!timeRecording.startDate,
    },
    {
      label: ITimeRecordingLabel.arrivalLoadingDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'subtract', 1),
      realDate: formatAndCalcDate(timeRecording.arrivalLoadingDate),
      agent: timeRecording.arrivalLoadingAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate),
        formatAndCalcDate(timeRecording.levyEndDate),
        'isBefore',
        'DD/MM/YYYY HH:mm',
        'DD/MM/YYYY HH:mm',
        timeRecording.arrivalLoadingDate,
      ),
      active: !!timeRecording.arrivalLoadingDate,
    },
    {
      label: ITimeRecordingLabel.checkinDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate),
      realDate: formatAndCalcDate(timeRecording.checkinDate),
      agent: timeRecording.checkinDateAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate),
        timeRecording.checkinDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.checkinDate,
    },
    {
      label: ITimeRecordingLabel.checkoutDate,
      planDate: formatAndCalcDate(timeRecording.levyEndDate),
      realDate: formatAndCalcDate(timeRecording.checkoutDate),
      agent: timeRecording.checkoutDateAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.levyEndDate),
        timeRecording.checkoutDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.checkoutDate,
    },
    {
      label: ITimeRecordingLabel.startTripUnloadingDate,
      planDate: formatAndCalcDate(timeRecording.levyInitialDate, 'add', 6),
      realDate: formatAndCalcDate(timeRecording.startTripUnloadingDate),
      agent: timeRecording.startTripUnloadingAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.levyInitialDate, 'add', 6),
        timeRecording.startTripUnloadingDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.startTripUnloadingDate,
    },
    {
      label: ITimeRecordingLabel.arrivalUnloadingDate,
      planDate: formatAndCalcDate(timeRecording.deliveryInitialDate),
      realDate: formatAndCalcDate(timeRecording.arrivalUnloadingDate),
      agent: timeRecording.arrivalUnloadingAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.deliveryInitialDate),
        formatAndCalcDate(timeRecording.deliveryEndDate),
        'isBefore',
        'DD/MM/YYYY HH:mm',
        'DD/MM/YYYY HH:mm',
        timeRecording.arrivalUnloadingDate,
      ),
      active: !!timeRecording.arrivalUnloadingDate,
    },
    {
      label: ITimeRecordingLabel.loadDate,
      planDate: formatAndCalcDate(timeRecording.deliveryInitialDate),
      realDate: formatAndCalcDate(timeRecording.loadDate),
      agent: timeRecording.loadDateAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.deliveryInitialDate),
        timeRecording.loadDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.loadDate,
    },
    {
      label: ITimeRecordingLabel.finishDate,
      planDate: formatAndCalcDate(timeRecording.deliveryEndDate),
      realDate: formatAndCalcDate(timeRecording.finishDate),
      agent: timeRecording.finishDateAgent,
      status: compareDates(
        formatAndCalcDate(timeRecording.deliveryEndDate),
        timeRecording.finishDate ?? currentDate,
        'sameOrBefore',
        'DD/MM/YYYY HH:mm',
      ),
      active: !!timeRecording.finishDate,
    },
  ];
};
