/* eslint-disable @typescript-eslint/no-unused-vars */
import request, { AxiosResponse } from 'axios';

import API from 'utils/API/API';

interface StatusError {
  statusCode: number;
  message: string;
}

export const trackingOccurrenceDeleteService = async (id: number): Promise<boolean> => {
  try {
    return await API.delete(`v2/occurrence-tracking/${id}`);
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao remover as ocorrências');
  }
};
