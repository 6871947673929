/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useFormikContext } from 'formik';

import { ContainerStyled, FreightContent, LineSectionStyled, ModalFormStyled } from './index.style';

import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { UserContext } from 'state/user-context';
import { BranchListService } from 'pages/freight-payable/services/branch-list/branch-list.service';
import { InvoiceRegisterInput } from 'domain-v2/invoice/register';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import { InputCalendar } from 'components/input-calendar/input-calendar';

interface IInvoiceProps {
  schedules: number[];
  totalService: number;
  companyName: string;
}

const InvoiceModal: React.FC<IInvoiceProps> = ({ schedules, totalService, companyName }) => {
  const { user } = useContext(UserContext);

  const { values, setFieldValue } = useFormikContext<InvoiceRegisterInput>();

  const [endDateError, setEndDateError] = useState('');

  const [totalServiceCalc, setTotalServiceCalc] = useState<number>(0);

  const [optionsBranch, setOptionsBranch] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

  const [dueDate, setDueDate] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  useEffect(() => {
    async function getBranchOptions() {
      const branchOptions = await BranchListService(Number(user?.companyId));
      setOptionsBranch(branchOptions);
    }
    getBranchOptions();
  }, []);

  useEffect(() => {
    const addition = values.addition && !isNaN(values.addition) ? values.addition : 0;
    const discount = values.discount && !isNaN(values.discount) ? values.discount : 0;
    const total = totalService !== null && !isNaN(totalService) ? totalService : 0;

    setTotalServiceCalc(total + addition - discount);
  }, [values.addition, values.discount]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  useEffect(() => {
    setFieldValue('issuanceDate', moment());
  }, []);

  const scheduleIds = schedules.join(', ');

  const header = useMemo(() => {
    return (
      <FreightContent>
        <p>Frete(s): {scheduleIds}</p>
        <p>
          Total:
          {convertCurrencyFormat.format(Number(totalServiceCalc + 0.001))}
        </p>
      </FreightContent>
    );
  }, [totalServiceCalc]);

  return (
    <ModalFormStyled>
      {header}
      <ContainerStyled>
        <LineSectionStyled columns="1fr 1fr 2fr">
          <InputCalendar
            placeholder=""
            label="Data de Emissão"
            date={values.issuanceDate}
            id="issuanceDate"
            disabled
            onDateChange={() => undefined}
            onFocusChange={() => undefined}
            focused={false}
          />
          <InputCalendar
            placeholder=""
            label="Data de Vencimento"
            onDateChange={(date) => {
              setDueDate((prvState) => {
                return { ...prvState, date };
              });
              setEndDateError('');
              setFieldValue('dueDate', date);
            }}
            onFocusChange={({ focused }) =>
              setDueDate((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={dueDate.focused}
            date={dueDate.date}
            minDate={values.issuanceDate}
            id="dueDate"
            hasError={endDateError ? true : false}
            errorMessage={String(endDateError)}
          />
          <InputField label="Cliente" id="companyName" name="companyName" type="text" value={companyName} disabled />
        </LineSectionStyled>
        <LineSectionStyled columns="1fr">
          <Select
            label="Filial"
            id="branchId"
            name="branchId"
            value={values.branchId}
            setFieldValue={(event: any, value: string) => setFieldValue('branchId', value)}
            options={optionsBranch}
            isClearable
          />
        </LineSectionStyled>
        <LineSectionStyled columns="0.8fr 0.8fr 4fr">
          <InputCurrency
            label="Acréscimo"
            id="addition"
            name="addition"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value, name) => {
              setFieldValue(name ?? '', Number(value));
            }}
            value={values?.addition}
          />
          <InputCurrency
            label="Desconto"
            id="discount"
            name="discount"
            prefix={'R$ '}
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator=","
            step={1}
            groupSeparator="."
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value, name) => {
              setFieldValue(name ?? '', Number(value));
            }}
            value={values?.discount}
          />

          <InputField
            label="Descrição"
            id="description"
            name="description"
            type="text"
            onChange={handleChange}
            value={values.description}
          />
        </LineSectionStyled>
      </ContainerStyled>
    </ModalFormStyled>
  );
};

export default InvoiceModal;
