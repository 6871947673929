import { useCallback } from 'react';
import { getIcmsService } from 'src-new/services/get-icms/get-icms.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetIcms = () => {
  return useCallback(async (ufOrigin: string, ufDestiny: string) => {
    try {
      return await getIcmsService(ufOrigin, ufDestiny);
    } catch (err) {
      showMessageFormatted({
        message: 'Houve um erro ao obter seu ICMS, tente novamente! ',
        type: 'error',
      });
    }
  }, []);
};
