import { FC, useCallback, useMemo } from 'react';
import { Button as ButtonUI } from 'logshare-ui-kit';
import { FormikErrors } from 'formik';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import * as S from './styled';
import { SectionPlanningVehicle } from './components/section-planning-vehicle';

import { formatDate } from 'utils-v2/converters/date';
import { UseUpdatePlanningRoute } from 'pages-v2/my-route/hooks/update-planning-route';
import { UseDeletePlanningRoute } from 'pages-v2/my-route/hooks/delete-planning-route';
import { ModalPlanningType } from 'pages-v2/my-route/components/form/types/modal';
import { PlanningRouteInput, PlanningRouteShipping } from 'domain-v2/planning-route';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import Button from 'components/button/button';

interface SectionPlanningProps {
  routeId?: number;
  indexPlanningRoute: number;
  planningRouter: PlanningRouteInput;
  deletePlanningRoute: (index: number) => void;
  handleOpenPlanningVehicleModal: () => void;
  handleOpenPlanningShippingModal: (
    open: boolean,
    planningRouteIndex: number,
    planningVehicleIndex: number,
    planningShippingIndex: number,
    type: ModalPlanningType,
    data?: PlanningRouteShipping,
  ) => void;
  handlePlanningRouteModal: (open: boolean, type: ModalPlanningType, index: number, data: PlanningRouteInput) => void;
  handlePlanningScheduleModal: (
    open: boolean,
    planningRouteIndex: number,
    allowedVehicleIndex: number,
    allowedShippingIndex: number,
  ) => void;
  values: MyRouterRegisterInput;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<MyRouterRegisterInput>>;
}

export const SectionPlanning: FC<SectionPlanningProps> = ({
  routeId,
  indexPlanningRoute,
  planningRouter,
  deletePlanningRoute,
  handleOpenPlanningVehicleModal,
  handleOpenPlanningShippingModal,
  handlePlanningRouteModal,
  handlePlanningScheduleModal,
  values,
  setFieldValue,
}) => {
  const handleDeletePlanningVehicle = useCallback(
    (indexPlanningVehicle: number) => {
      const updatedPlanningRoute = planningRouter.allowedVehicles.filter((_, i) => i !== indexPlanningVehicle);
      setFieldValue(`planningRoute[${indexPlanningRoute}].allowedVehicles`, updatedPlanningRoute);
    },
    [planningRouter.allowedVehicles, setFieldValue],
  );

  const handleOpenModalDetails = useCallback(() => {
    handlePlanningRouteModal(true, 'update', indexPlanningRoute, planningRouter);
  }, [handlePlanningRouteModal]);

  const handleDeletePlanningRoute = useCallback(async () => {
    if (planningRouter.id) {
      await UseDeletePlanningRoute(planningRouter.id);
    }

    deletePlanningRoute(indexPlanningRoute);
  }, [planningRouter]);

  const handleExpanderPlanning = useCallback(
    async (isOpen: boolean) => {
      setFieldValue(`planningRoute[${indexPlanningRoute}].expander`, isOpen);
      await UseUpdatePlanningRoute({ ...planningRouter, routeId: routeId, expander: isOpen });
    },
    [setFieldValue, planningRouter],
  );

  const renderPlanningVehicle = useMemo(() => {
    if (planningRouter.allowedVehicles.length === 0) return;

    const mapping = planningRouter.allowedVehicles.map((value, indexPlanningVehicle) => {
      return (
        <SectionPlanningVehicle
          key={indexPlanningVehicle}
          indexPlanningRoute={indexPlanningRoute}
          indexPlanningVehicle={indexPlanningVehicle}
          planningVehicle={value}
          handleDeletePlanningVehicle={handleDeletePlanningVehicle}
          handleOpenPlanningShippingModal={handleOpenPlanningShippingModal}
          handlePlanningScheduleModal={handlePlanningScheduleModal}
          values={values}
          setFieldValue={setFieldValue}
        />
      );
    });

    return mapping;
  }, [planningRouter]);

  const planningSchedules = useMemo(() => {
    const plannedTrips = planningRouter.allowedVehicles
      .map((test) => test.allowedShipping.reduce((total, planned) => total + planned.plannedTrips, 0))
      .reduce((total, current) => total + current, 0);

    const offeredTrips = planningRouter.allowedVehicles
      .map((test) => test.allowedShipping.reduce((total, planned) => total + planned.offeredTrips, 0))
      .reduce((total, current) => total + current, 0);

    const acceptedTrips = planningRouter.allowedVehicles
      .map((test) => test.allowedShipping.reduce((total, planned) => total + planned.acceptedTrips, 0))
      .reduce((total, current) => total + current, 0);

    const refusedTrips = planningRouter.allowedVehicles
      .map((test) => test.allowedShipping.reduce((total, planned) => total + planned.refusedTrips, 0))
      .reduce((total, current) => total + current, 0);

    return { plannedTrips, offeredTrips, acceptedTrips, refusedTrips };
  }, [planningRouter]);

  const renderPlanningRoute = useMemo(() => {
    return (
      <>
        <S.HeaderDivisionLine />
        <LineSectionStyled columns="1.02fr 0.5fr 1fr 1fr 1fr 1fr 0.85fr">
          <S.TableStyled>
            Período
            <S.ContainerInfoStyled>
              {formatDate(planningRouter.initialDate)} a {formatDate(planningRouter.finalDate)}
            </S.ContainerInfoStyled>
          </S.TableStyled>

          <S.TableStyled>
            Planejado por
            <S.ContainerInfoStyled>{planningRouter.plannedBy}</S.ContainerInfoStyled>
          </S.TableStyled>

          <S.TableStyled>
            Viagens Planejadas
            <S.ContainerInfoStyled>{planningSchedules.plannedTrips}</S.ContainerInfoStyled>
          </S.TableStyled>

          <S.TableStyled>
            Viagens Ofertadas
            <S.ContainerInfoStyled>{planningSchedules.offeredTrips}</S.ContainerInfoStyled>
          </S.TableStyled>

          <S.TableStyled>
            Viagens Aceitas
            <S.ContainerInfoStyled>{planningSchedules.acceptedTrips}</S.ContainerInfoStyled>
          </S.TableStyled>

          <S.TableStyled>
            Viagens Recusadas
            <S.ContainerInfoStyled>{planningSchedules.refusedTrips}</S.ContainerInfoStyled>
          </S.TableStyled>

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 15,
                marginTop: 2,
              }}
            >
              <ButtonUI
                label={''}
                LeftIcon={<EditIcon />}
                color={'aqua'}
                onPress={handleOpenModalDetails}
                variant={'solid'}
                size={'x-sm'}
              />
              <ButtonUI
                label={''}
                LeftIcon={<DeleteIcon />}
                color={'red'}
                onPress={handleDeletePlanningRoute}
                variant={'solid'}
                size={'x-sm'}
              />
            </div>
          </div>
        </LineSectionStyled>
        {renderPlanningVehicle}

        <div style={{ marginTop: 30 }}>
          <Button
            title="Adicionar"
            bgColor="green"
            text=" + Veículo"
            size="very-small"
            callback={handleOpenPlanningVehicleModal}
          />
        </div>
      </>
    );
  }, [planningRouter]);

  return (
    <S.ContainerVehicleListStyled key={indexPlanningRoute} isExpander={planningRouter.expander}>
      <S.HeaderTitleDealings>
        <S.HeaderDescriptions>{planningRouter.name}</S.HeaderDescriptions>
        {planningRouter.expander ? (
          <ExpandLess onClick={() => handleExpanderPlanning(false)} />
        ) : (
          <ExpandMore onClick={() => handleExpanderPlanning(true)} />
        )}
      </S.HeaderTitleDealings>

      {planningRouter.expander && renderPlanningRoute}
    </S.ContainerVehicleListStyled>
  );
};
