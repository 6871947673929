/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import React, { memo, useMemo, useState } from 'react';

import * as S from './styled';
import BiddingList from './idleness-list';
import { BiddingFilterForm } from './components/filter/filter';

import { ListProps, idlenessBiddingListService } from 'services/bidding';
import { showToast } from 'components/toast/toast';
import ModalAssintPlan from 'components/modal-assign-plan/modal-assign-plan';

interface IBackhaulFilter {
  company?: string;
  freightValue?: string;
  vehicleCategory?: string;
  vehicleType?: string;
  originCity?: string;
  originUf?: string;
  destinationCity?: string;
  destinationUf?: string;
  backhaulType?: string;
  operationType?: string;
  page: string | number;
}

const initialFilter = {} as IBackhaulFilter;
const initialParams = {} as ListProps;

const IdlenessBiddingPage: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [scheduleFilter, setScheduleFilter] = useState<IBackhaulFilter>({
    ...initialFilter,
    page: 1,
  });

  const [params, setParams] = useState<ListProps>(initialParams);

  const handlePaginate = (page: number) => {
    setParams((prevState) => ({ ...prevState, page }));
  };

  const { isLoading, data } = useQuery({
    queryKey: ['idlenessBidding', params],
    queryFn: () => idlenessBiddingListService(params),
  });

  const handleSchedulesFilter = async () => {
    try {
        setParams(scheduleFilter);
    } catch (error) {
      showToast({
        message: 'Erro ao listar viagens',
        type: 'error',
      });
    }
  };

  const handleOriginCityChange = (values: any, name: string) => {
    const { value } = values.target;
    setScheduleFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const idlenessList = useMemo(() => {
    return <BiddingList idlenessList={data} isLoading={isLoading} handlePageChange={handlePaginate} />;
  }, [isLoading]);

  return (
    <>
      <ModalAssintPlan openModal={openModal} handleCloseModal={() => setOpenModal(false)} />

      <S.ContainerBackhaulTable>
        <BiddingFilterForm
          backhaulFilter={scheduleFilter}
          handleOriginCityChange={handleOriginCityChange}
          setBackhaulFilter={setScheduleFilter}
          handleFilter={handleSchedulesFilter}
          handleCleanFilter={() => {
            setParams({
              ...initialParams,
              page: params.page,
            });
            setScheduleFilter(initialParams);
          }}
        />
        {idlenessList}
      </S.ContainerBackhaulTable>
    </>
  );
};

export default memo(IdlenessBiddingPage);
