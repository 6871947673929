import React, { CSSProperties, Dispatch, Fragment, ReactElement, SetStateAction, useCallback, useMemo } from 'react';
import { InfoWindow, Marker } from '@vis.gl/react-google-maps';
import { formatTimestampToDateHourUtil } from 'src-new/utils/format-timestamp-to-date-hour/format-timestamp-to-date-hour.util';
import { GoogleMaps } from 'src-new/components/google-maps/google-maps.component';
import {
  IGoogleMapsDirections,
  IGoogleMapsLocation,
  IMapsDirection,
  IMapsProps,
} from 'src-new/components/google-maps/google-maps-view.types';
import { GoogleMapsDirections } from 'src-new/components/google-maps/components/google-maps-directions/google-maps-directions.component';
import { IRouteInfoDetails } from './google-map-renderer.types';

interface IGoogleMapsViewProps {
  directions?: IGoogleMapsDirections;
  routeInfoSelected?: IRouteInfoDetails;
  setRouteInfoSelected: Dispatch<SetStateAction<IRouteInfoDetails>>;
  mapProps: IMapsProps;
  calculatePermanenceTime: (
    routeInfoSelected: IGoogleMapsLocation,
    historyRoutes?: Array<IGoogleMapsLocation>,
  ) => string;
}

export const GoogleMapRendererView: React.FC<IGoogleMapsViewProps> = ({
  directions,
  routeInfoSelected,
  setRouteInfoSelected,
  mapProps,
  calculatePermanenceTime,
}) => {
  const renderRealTimeOriginDestination = useCallback(
    (type: 'APP' | 'MONYTOR', direction?: IMapsDirection): ReactElement | undefined => {
      if (direction && direction.iconProps.hasIconGroup) {
        return (
          <Fragment>
            <Marker
              position={direction?.origin}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: type === 'APP' ? '#5AC742' : '#1d4165',
                fillOpacity: 1,
                strokeColor: type === 'APP' ? '#5AC742' : '#1d4165',
                strokeWeight: 1.5,
                scale: 8,
              }}
              zIndex={1000}
            />
            <Marker
              position={direction?.destination}
              icon={
                direction.iconProps.completedTrip
                  ? {
                      path: google.maps.SymbolPath.CIRCLE,
                      fillColor: type === 'APP' ? '#5AC742' : '#1d4165',
                      fillOpacity: 1,
                      strokeColor: type === 'APP' ? '#5AC742' : '#1d4165',
                      strokeWeight: 1.5,
                      scale: 8,
                    }
                  : {
                      url: direction.iconProps.destinationIcon,
                      scaledSize: new window.google.maps.Size(35, 35),
                      anchor: new window.google.maps.Point(10, 10),
                    }
              }
              zIndex={1000}
              onClick={() =>
                setRouteInfoSelected({ routeInfo: direction?.destination, historyRoutes: direction?.routePositions })
              }
            />
          </Fragment>
        );
      }
    },
    [setRouteInfoSelected],
  );

  const renderPrimaryDirections = useMemo(
    (): ReactElement | undefined => renderRealTimeOriginDestination('APP', directions?.primaryDirection),
    [directions?.primaryDirection, renderRealTimeOriginDestination],
  );
  const renderTertiaryDirections = useMemo(
    (): ReactElement | undefined => renderRealTimeOriginDestination('MONYTOR', directions?.tertiaryDirection),
    [directions?.tertiaryDirection, renderRealTimeOriginDestination],
  );

  const renderSecondaryDirections = useMemo((): ReactElement | undefined => {
    const direction = directions?.secondaryDirection;

    if (direction && direction.iconProps.hasIconGroup) {
      return (
        <Fragment>
          <Marker
            position={direction?.origin}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: '#1d4165',
              fillOpacity: 1,
              strokeColor: '#ffffff',
              strokeWeight: 2,
              scale: 15,
            }}
            label={{
              text: '1',
              color: '#ffffff',
              fontWeight: '600',
            }}
            zIndex={1001}
          />
          <Marker
            position={direction?.destination}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: '#1d4165',
              fillOpacity: 1,
              strokeColor: '#ffffff',
              strokeWeight: 1.5,
              scale: 15,
            }}
            label={{
              text: '2',
              color: '#ffffff',
              fontWeight: '600',
            }}
            zIndex={1001}
          />
        </Fragment>
      );
    }
  }, [directions?.secondaryDirection]);

  const renderInfoWindow = useMemo((): ReactElement | undefined => {
    if (routeInfoSelected?.routeInfo) {
      const containerStyled: CSSProperties = { display: 'flex', width: '100%' };
      const divValueStyled: CSSProperties = { position: 'absolute', right: '17px' };

      const renderTitle = routeInfoSelected.routeInfo.address ? (
        <div style={{ position: 'absolute', width: '230px', height: '40px', top: '17px' }}>
          {routeInfoSelected.routeInfo.address}
        </div>
      ) : (
        <h3 style={{ position: 'absolute', bottom: '115px' }}>Detalhes do Marcador</h3>
      );

      const renderStatus = () => {
        if (routeInfoSelected.routeInfo?.ignition) {
          return routeInfoSelected.routeInfo.ignition !== 0 ? 'Em movimento' : 'Parado';
        }

        return routeInfoSelected.routeInfo?.speed !== 0 ? 'Em movimento' : 'Parado';
      };

      return (
        <InfoWindow
          position={routeInfoSelected.routeInfo}
          disableAutoPan={true}
          onCloseClick={() => setRouteInfoSelected({ routeInfo: undefined, historyRoutes: undefined })}
        >
          <div style={{ width: '250px', marginTop: '10px' }}>
            {renderTitle}
            <div>
              <p style={containerStyled}>
                <b>Data/Hora </b>
                <div style={divValueStyled}>{formatTimestampToDateHourUtil(routeInfoSelected.routeInfo.timestamp)}</div>
              </p>
              <p style={containerStyled}>
                <b>Velocidade </b>
                <div style={divValueStyled}>
                  {routeInfoSelected.routeInfo.speed ? Math.ceil(routeInfoSelected.routeInfo.speed) : '-'} Km/h
                </div>
              </p>
              <p style={containerStyled}>
                <b>Status </b>
                <div style={divValueStyled}>{renderStatus()}</div>
              </p>
              <p style={containerStyled}>
                <b>Tempo de Permanência </b>
                <div style={divValueStyled}>
                  {calculatePermanenceTime(routeInfoSelected.routeInfo, routeInfoSelected.historyRoutes)}
                </div>
              </p>
              <p style={containerStyled}>
                <b>Latitude </b>
                <div style={divValueStyled}>{routeInfoSelected.routeInfo.lat}</div>
              </p>
              <p style={containerStyled}>
                <b>Longitude </b>
                <div style={divValueStyled}>{routeInfoSelected.routeInfo.lng}</div>
              </p>
            </div>
          </div>
        </InfoWindow>
      );
    }
  }, [calculatePermanenceTime, routeInfoSelected?.historyRoutes, routeInfoSelected?.routeInfo, setRouteInfoSelected]);

  const renderRoutePositions = useCallback(
    (type: 'APP' | 'MONYTOR', routePositions?: Array<IGoogleMapsLocation>): ReactElement | undefined => {
      if (routePositions && routePositions.length) {
        const marks = routePositions.map((routePosition, index) => {
          return (
            <Marker
              key={`${index}-${routePosition.lat}-${routePosition.lng}`}
              position={routePosition}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#FFFFFF',
                fillOpacity: 1,
                strokeColor: type === 'APP' ? '#5AC742' : '#1d4165',
                strokeWeight: 1.5,
                scale: 4,
              }}
              onClick={() =>
                setRouteInfoSelected({
                  routeInfo: routePosition,
                  historyRoutes: routePositions,
                })
              }
            />
          );
        });

        return <Fragment>{marks}</Fragment>;
      }
    },
    [setRouteInfoSelected],
  );

  const renderRoutePositionsPrimary = useMemo(
    (): ReactElement | undefined => renderRoutePositions('APP', directions?.primaryDirection?.routePositions),
    [directions?.primaryDirection?.routePositions, renderRoutePositions],
  );
  const renderRoutePositionsTertiary = useMemo(
    (): ReactElement | undefined => renderRoutePositions('MONYTOR', directions?.tertiaryDirection?.routePositions),
    [directions?.tertiaryDirection?.routePositions, renderRoutePositions],
  );

  return (
    <GoogleMaps mapProps={mapProps}>
      <GoogleMapsDirections direction={directions?.primaryDirection} realtimeRoute={true} />
      <GoogleMapsDirections direction={directions?.secondaryDirection} />
      <GoogleMapsDirections direction={directions?.tertiaryDirection} realtimeRoute={true} />
      {renderSecondaryDirections}
      {renderPrimaryDirections}
      {renderTertiaryDirections}
      {renderRoutePositionsPrimary}
      {renderRoutePositionsTertiary}
      {renderInfoWindow}
    </GoogleMaps>
  );
};
