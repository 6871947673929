import styled, { css } from 'styled-components';

import { ButtonProps } from './index';

const wrapperModifier = {
  withIcon: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
    }
  `,
};

export const ButtonStyled = styled.button<ButtonProps>`
  ${({ theme, color = 'blue' }) => css`
    background-color: ${theme.colors[color]};
    border: none;
    ${color === 'white' && `border: solid 1px ${theme.colors.forBorder}`};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.semiBold};
    color: ${color === 'white' ? theme.colorsV2.darkGray : theme.colorsV2.white};
    cursor: pointer;
    white-space: nowrap;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 39px;
    padding: 1px 20px;
    border-radius: ${theme.border.radius};
    font-size: 12px;

    ${wrapperModifier.withIcon()}

    :hover {
      opacity: 0.88;
    }

    :active {
      box-shadow: ${theme.border.shadow};
    }

    @media (max-width: 600px) {
      min-width: 50px;
      padding: 5px 5px;
      height: 35px;
      font-size: 12px;
    }
  `}
`;
