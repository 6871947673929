import { useCallback, useReducer } from 'react';
import { LocationsListActionType } from '../../locations.action';
import { locationsInitialState } from '../../locations.constants';
import { locationsListReducer } from 'src-new/pages/registrations/pages/location/contexts/locations/reducers/locations-list/locations-list.reducer';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';
import {
  ILocationsList,
  ILocationsListFilter,
} from 'src-new/pages/registrations/pages/location/contexts/locations/types/locations-list.types';

export const useLocationsListContextValues = (): ILocationsList => {
  const [state, dispatch] = useReducer(locationsListReducer, locationsInitialState);

  const setLocationsListRequest = useCallback(() => {
    dispatch({ type: LocationsListActionType.LOCATIONS_LIST_REQUEST, payload: undefined });
  }, []);

  const setLocationsListSuccess = useCallback((locations: Array<ILocationDomain>) => {
    dispatch({ type: LocationsListActionType.LOCATIONS_LIST_SUCCESS, payload: locations });
  }, []);

  const setLocationsListError = useCallback(() => {
    dispatch({ type: LocationsListActionType.LOCATIONS_LIST_ERROR, payload: undefined });
  }, []);

  const setFilters = useCallback((filters: ILocationsListFilter) => {
    dispatch({ type: LocationsListActionType.LOCATIONS_LIST_SET_FILTERS, payload: filters });
  }, []);

  const setLocationsListReset = useCallback(() => {
    dispatch({ type: LocationsListActionType.LOCATIONS_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.locationsList,
    setLocationsListRequest,
    setLocationsListSuccess,
    setLocationsListError,
    setLocationsListReset,
    filterActions: {
      ...state.locationsList.filterActions,
      setFilters,
    },
  };
};
