import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { convertItemsEntityToSelectItem } from 'pages/vehicle/mappers/convert-items-entity-to-select-item/convert-shipping-entity-to-select-item.mapper';
import { SelectItem } from '../entities/select-items.entity';

export const itemsFilterSearchService = async (searchValue: string): Promise<SelectItem[]> => {
  const { data } = await API_TRAVELS_SVC.get(`v1/weighing/item?description=${searchValue}&page=1&limit=100`);
  return data.items.map((items: { description: string; id: number }) =>
    convertItemsEntityToSelectItem(items.description.toUpperCase(), items.id),
  );
};
