export interface ISelectedBackhaul {
  transportRouteId: number;
  companySellerId: number;
  valueFreight: number;
  co2Economy: number;
  invoicedBy: string;
  cost: {
    freightValue: number;
    helperValue: number;
    tollAxis: number;
    freightTotal: number;
    totalService?: number;
  };
}

export const initialSelectedBackhaulValues: ISelectedBackhaul = {
  transportRouteId: 0,
  companySellerId: 0,
  valueFreight: 0,
  co2Economy: 0,
  invoicedBy: '',
  cost: {
    freightValue: 0,
    helperValue: 0,
    tollAxis: 0,
    freightTotal: 0,
    totalService: 0,
  },
};
