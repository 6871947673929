export enum StatusCode {
  AG10 = 'AG10',
  IN10 = 'IN10',
  IN11 = 'IN11',
  IN20 = 'IN20',
  IN30 = 'IN30',
  IN40 = 'IN40',
  IN50 = 'IN50',
  IN60 = 'IN60',
  IN70 = 'IN70',
}

interface IMapProps {
  handleShowResume: (openResume: boolean, scheduleId?: number) => void;
}

export type { IMapProps };
