import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WrapperStyled } from './sidebar.styled';
import { SidebarContext } from './contexts/sidebar.context';
import Submenu from './components/submenu/submenu.component';
import Menu from './components/menu/menu.component';
import Header from './components/header/header';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import { ModalAssintPlan } from 'src-new/components/modal-assign-plan/modal-assign-plan.component';

const SidebarView: React.FC = () => {
  const { pathname } = useLocation();
  const { currentSelectedMenu, handleSelectedMenu } = React.useContext(SidebarContext);

  const [openModal, setOpenModal] = useState<{ modalType: 'newFreight' | 'newPlan' | ''; open: boolean }>({
    modalType: '',
    open: false,
  });

  useEffect(() => {
    handleSelectedMenu('');
  }, [pathname]);

  const handleCloseModal = useCallback(() => setOpenModal({ modalType: '', open: false }), []);

  const handleOpenModals = useCallback(
    (modalType: 'newFreight' | 'newPlan') => setOpenModal({ modalType, open: true }),
    [],
  );

  const renderModals = useMemo((): ReactElement | undefined => {
    if (openModal.open) {
      if (openModal.modalType === 'newFreight') {
        return <ModalNewFreight handleCreateSchedulePage={handleCloseModal} />;
      }

      if (openModal.modalType === 'newPlan') {
        return <ModalAssintPlan handleCloseModal={handleCloseModal} />;
      }
    }
  }, [handleCloseModal, openModal]);

  return (
    <WrapperStyled>
      {renderModals}
      <Header />
      <Menu />
      {currentSelectedMenu ? <Submenu openModals={handleOpenModals} /> : null}{' '}
    </WrapperStyled>
  );
};

export default SidebarView;
