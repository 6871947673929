import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { AttachDocumentsSectionView } from './attach-documents-section-view.component';
import { RegistrationsContext } from '../../contexts/registrations/registrations.context';
import { useGetUploadFiles } from 'src-new/pages/registrations/hooks/use-get-upload-files/use-get-upload-files.hook';

interface IEditingProps {
  module: 'client' | 'location' | 'shipping-company' | 'driver' | 'vehicle';
  code: number;
}

interface IAttachDocumentsSectionProps {
  editingProps?: IEditingProps;
}

export const AttachDocumentsSection: React.FC<IAttachDocumentsSectionProps> = ({ editingProps }) => {
  const { attachDocumentsSection } = useContext(RegistrationsContext);
  const getUploadFiles = useGetUploadFiles();
  const { isOpenModal, setModalOpen, getAttachDocuments } = attachDocumentsSection;

  const handleOpenAttachDocumentsModal = useCallback(() => setModalOpen(), [setModalOpen]);

  const isOpenOpenAttachDocumentsModal = useMemo(() => isOpenModal, [isOpenModal]);

  const isLoading = useMemo(
    () => getAttachDocuments.statusRequest === 'PROCESSING',
    [getAttachDocuments.statusRequest],
  );

  const isError = useMemo(() => getAttachDocuments.statusRequest === 'ERROR', [getAttachDocuments.statusRequest]);

  useEffect(() => {
    if (getAttachDocuments.statusRequest === 'INITIAL' && editingProps && editingProps.code !== 0) {
      getUploadFiles(editingProps.module, editingProps.code);
    }
  }, [editingProps, getAttachDocuments.statusRequest, getUploadFiles]);

  return (
    <AttachDocumentsSectionView
      handleOpenAttachDocumentsModal={handleOpenAttachDocumentsModal}
      isOpenModal={isOpenOpenAttachDocumentsModal}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
