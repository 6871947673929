import React, { Fragment, useMemo } from 'react';
import * as S from './schedule-maps-view.styled';
import { SpinnerModal } from './schedule-maps-view.styled';
import { Checkbox } from 'src-new/components/checkbox/checkbox.component';
import { IMapsDirection } from 'src-new/components/google-maps/google-maps-view.types';
import { IMapsFireBaseProps, IMapsMonitoringProps, IMapsPlannedRouteProps } from './schedule-maps.types';
import { RouteReportDownloadButton } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/route-report-download-button/route-report-download-button.component';
import { MapTypeButton } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/map-type-button/map-type-button.component';
import BlueCircle from 'assets/img/blue-circle.png';
import GreenCircle from 'assets/img/green_circle.png';
import { GoogleMapRenderer } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-maps/components/google-map-renderer/google-map-renderer.component';

interface IScheduleMapsViewProps {
  plannedRoute?: IMapsDirection;
  fireBaseRoute?: IMapsDirection;
  monitoringRoute?: IMapsDirection;
  handleMapKind: (mapKing: 'firebase' | 'monitoring') => void;
  firebaseProps: IMapsFireBaseProps;
  monitoringProps: IMapsMonitoringProps;
  plannedRouteProps: IMapsPlannedRouteProps;
  isDisabledFireBaseCheckBox: boolean;
  isDisabledMonitoringCheckBox: boolean;
  isLoadingCheckbox: boolean;
  isShowDownloadButton: boolean;
}

export const ScheduleMapsView: React.FC<IScheduleMapsViewProps> = ({
  plannedRoute,
  fireBaseRoute,
  handleMapKind,
  firebaseProps,
  monitoringProps,
  plannedRouteProps,
  isDisabledFireBaseCheckBox,
  isDisabledMonitoringCheckBox,
  isLoadingCheckbox,
  monitoringRoute,
  isShowDownloadButton,
}) => {
  const renderCheckboxGroup = useMemo(() => {
    return (
      <Fragment>
        <S.LineSubtitle>
          <Checkbox
            id="plannedRoute"
            name="plannedRoute"
            onChange={() => plannedRouteProps.setPlannedRoute(!plannedRouteProps.isChecked)}
            checked={plannedRouteProps.isChecked}
            disabled
          />
          <S.Text>Rota Planejada</S.Text>
          <S.TracingContainer>
            <S.Tracing />
          </S.TracingContainer>
        </S.LineSubtitle>
        <S.LineSeparator />
        <S.LineSubtitle>
          <Checkbox
            id="tracker"
            name="tracker"
            onChange={() => handleMapKind('monitoring')}
            checked={monitoringProps.isChecked}
            disabled={isDisabledMonitoringCheckBox}
          />
          <S.Text color={monitoringProps.truckRoutes.routes.length > 0 ? 'black' : 'lightGray'}>
            Rota Executada (Rastreador)
          </S.Text>
          <S.TracingRealTimeContainer>
            <S.TracingRealTime color="blue" />
            <img style={{ width: 10, height: 10 }} src={BlueCircle} alt={'icon'} />
            <S.TracingRealTime color="blue" />
          </S.TracingRealTimeContainer>
        </S.LineSubtitle>
        <S.LineSeparator />
        <S.LineSubtitle>
          <Checkbox
            id="firebase"
            name="firebase"
            onChange={() => handleMapKind('firebase')}
            checked={firebaseProps.isChecked}
            disabled={isDisabledFireBaseCheckBox}
          />
          <S.Text color={firebaseProps.truckRoutes.routes.length > 0 ? 'black' : 'lightGray'}>
            Rota Executada (App)
          </S.Text>
          <S.TracingRealTimeContainer>
            <S.TracingRealTime color="green" />
            <img style={{ width: 10, height: 10 }} src={GreenCircle} alt={'spinner'} />
            <S.TracingRealTime color="green" />
          </S.TracingRealTimeContainer>
        </S.LineSubtitle>
      </Fragment>
    );
  }, [
    firebaseProps.isChecked,
    firebaseProps.truckRoutes.routes.length,
    handleMapKind,
    isDisabledFireBaseCheckBox,
    isDisabledMonitoringCheckBox,
    monitoringProps.isChecked,
    monitoringProps.truckRoutes.routes.length,
    plannedRouteProps,
  ]);

  const renderButton = useMemo(() => {
    if (isShowDownloadButton) {
      return <RouteReportDownloadButton />;
    }
  }, [isShowDownloadButton]);

  const renderContentModal = useMemo(() => {
    if (isLoadingCheckbox) {
      return <SpinnerModal />;
    }

    return (
      <S.CheckboxWrapper>
        <S.CheckboxContainer>{renderCheckboxGroup}</S.CheckboxContainer>
      </S.CheckboxWrapper>
    );
  }, [isLoadingCheckbox, renderCheckboxGroup]);

  return (
    <S.Container>
      <GoogleMapRenderer primaryRoute={fireBaseRoute} secondaryRoute={plannedRoute} tertiaryRoute={monitoringRoute} />
      {renderContentModal}
      {renderButton}
      <MapTypeButton />
    </S.Container>
  );
};
