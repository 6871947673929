import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { SaleOfferContextProvider } from '../../contexts/sale-offer/sale-offer.context';
import { SaleOfferRegisterPageBody } from './components/sale-offer-register-page-body/sale-offer-register-page-body.component';

export const SaleOfferFormRegisterPage: FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <SaleOfferContextProvider>
        <SaleOfferRegisterPageBody />
      </SaleOfferContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
