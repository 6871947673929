import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import * as pdfjs from 'pdfjs-dist';

import { CompanyImageStyled, ContainerStyled, TrashIconContainerStyled } from './input-file-upload.styled';

import Theme from 'styles/theme';
import TrashIcon from 'assets/icons/trash.icon';

type InputUploadImageViewProps = {
  dataUrlImage: string;
  handleClick: (_event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  handleDelete?: () => void;
  goToImageView: (_pdf: boolean) => void;
};

const InputUploadImageView: FC<InputUploadImageViewProps> = ({
  dataUrlImage,
  handleClick,
  goToImageView,
  handleDelete,
}) => {
  const [error, setError] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const generateThumbnail = async () => {
      if (dataUrlImage) {
        try {
          const pdf = await pdfjs.getDocument(dataUrlImage).promise;
          setError(true);
          const page = await pdf.getPage(1);
          const viewport = page.getViewport({ scale: 0.24 });
          const canvas = canvasRef.current;
          if (canvas) {
            const context = canvas.getContext('2d');
            if (context) {
              canvas.width = 144;
              canvas.height = 144;
              await page.render({ canvasContext: context, viewport }).promise;
            }
          }
        } catch {
          setError(false);
        }
      }
    };

    generateThumbnail();
  }, [dataUrlImage]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContainerStyled
          aria-label="input upload de imagem"
          onClick={(e) => {
            if (!dataUrlImage) handleClick(e);
          }}
          dataUrlImage={dataUrlImage}
        >
          {dataUrlImage ? (
            error ? (
              <canvas ref={canvasRef} onClick={() => goToImageView(true)} />
            ) : (
              <CompanyImageStyled src={dataUrlImage} alt="" onClick={() => goToImageView(false)} />
            )
          ) : (
            <>
              <Theme.icons.uploadIcon />
            </>
          )}
        </ContainerStyled>
        {dataUrlImage && handleDelete && (
          <TrashIconContainerStyled onClick={() => handleDelete()}>
            <TrashIcon />
          </TrashIconContainerStyled>
        )}
      </div>
    </div>
  );
};
export default InputUploadImageView;
