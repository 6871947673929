import React, { useContext, useMemo } from 'react';
import { ImportModalTableView } from './import-modal-table-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { IScheduleImportModalDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-import-modal/schedule-import-modal.domain';

export const ImportModalTable: React.FC = () => {
  const { scheduleImportModal } = useContext(ScheduleContext);

  const filesRow = useMemo(
    (): Array<IScheduleImportModalDomain> => scheduleImportModal.scheduleImportModal,
    [scheduleImportModal.scheduleImportModal],
  );

  const isLoading = useMemo(
    (): boolean => scheduleImportModal.statusRequest === 'PROCESSING',
    [scheduleImportModal.statusRequest],
  );

  return <ImportModalTableView filesRow={filesRow} isLoading={isLoading} />;
};
