/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { FormikValues } from 'formik';

import { NewScheduleSchema } from '../service/validation-register.schema';
import { initialStateSTLRegister } from '../constants/form';
import { FreightPriceLTL, FreightPriceLTLData, FreightTLTTypes } from '../../schedule-ltl/types/freight-selection';

import { FormikStyled, FormStyled, WrapperScheduleStyled } from './register-view.styled';
import { renderScheduleForm } from './components/form/render-schedule-form';

import { ICardProps } from 'pages/schedule/schedule-register/components/card/card.types';
import CardTariff from 'pages/schedule/schedule-register/components/card/card-tariff/card-tariff';
import { FooterPage } from 'components-v2/common/footer-page';
import Title from 'components/title/title';
import { IResumeInfo } from 'components/resume/resume-info.types';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { CardResume } from 'components/card-resume';
import Accordion from 'components/accordion/accordion';

const dataTestId = 'occurrence-tracking-list-body-view';

interface IOccurrenceTrackingListBodyViewProps {
  info: IResumeInfo;
  setInfo: any;
  cards?: ICardProps[];
  setCardsList?: (cards: ICardProps[]) => void;
  apiCall: (searchValue: string, clientType: string) => Promise<Array<{ label: string; value: number }>>;
  handleScheduleRegister: (schedule: FormikValues) => Promise<void>;
  handleGoback: () => void;
  handleChangeValues?: (values: FormikValues) => Promise<FreightPriceLTLData | undefined>;
  selectedFreight?: FreightTLTTypes;
  freightLTLTotal?: Partial<FreightPriceLTL>;
  autoSelectedDate?: string;
}

const ScheduleLtlListBodyView: React.FC<IOccurrenceTrackingListBodyViewProps> = ({
  cards,
  handleScheduleRegister,
  apiCall,
  handleChangeValues,
  selectedFreight,
  freightLTLTotal,
  autoSelectedDate,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [freightCalculated, setFreightCalculated] = useState<FreightPriceLTLData>();

  const minDeliveryDate = useMemo(() => {
    if (freightLTLTotal?.early)
      return moment(freightLTLTotal.early.date, 'DD/MM/YYYY').diff(moment(moment.now()), 'days');

    return undefined;
  }, [freightLTLTotal]);

  const handleFreightResume = useCallback(
    async (values: FormikValues): Promise<void> => {
      if (handleChangeValues) {
        if (!isLoading) {
          try {
            setIsLoading(true);
            setFreightCalculated(await handleChangeValues(values));
          } catch (error) {
            return;
          } finally {
            setIsLoading(false);
          }
        }
      }
    },
    [handleChangeValues, isLoading],
  );

  return (
    <>
      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Programação', 'Novo Serviço']} renderCustomBackButton={true} />
      <Title dataTestId={dataTestId} title="Solicite um novo frete para carga fracionada (STL)." hasTrace />
      <FormikStyled
        initialValues={initialStateSTLRegister}
        validationSchema={NewScheduleSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleScheduleRegister(values);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, errors, touched, setFieldValue }) => {
          return (
            <FormStyled>
              <WrapperScheduleStyled>
                <Accordion
                  data={renderScheduleForm({
                    values,
                    handleChange,
                    errors,
                    touched,
                    setFieldValue,
                    apiCall,
                    handleChangeValues: handleFreightResume,
                    selectedFreight,
                    minDeliveryDate,
                    autoSelectedDate,
                  })}
                />
                <CardResume.Root>
                  <CardResume.Header freightType="stl" />
                  <CardResume.Collection
                    originValues={values?.origin}
                    scheduleDetailsValues={values?.cargoDetails}
                    hasConnector
                  />
                  <CardResume.Delivery destinyValues={values?.destiny} scheduleDetailsValues={values?.cargoDetails} />
                  <CardResume.Operation cargoDetailsValues={values?.cargoDetails} />
                  <CardResume.Values freightValues={freightCalculated?.price as any} />
                </CardResume.Root>
              </WrapperScheduleStyled>

              <FooterPage.Root>
                <FooterPage.RightContent>
                  <FooterPage.Button label="Agendar" color="pink" isLoading={isLoading} onPress={handleSubmit} />
                </FooterPage.RightContent>

                {!!cards && (
                  <FooterPage.LeftContent>
                    <CardTariff cards={cards} />
                  </FooterPage.LeftContent>
                )}
              </FooterPage.Root>
            </FormStyled>
          );
        }}
      </FormikStyled>
    </>
  );
};

export default ScheduleLtlListBodyView;
