import { CargoTypesResponse } from 'src-new/domains/cargo-type.domain';
import { ISaleOfferDomain } from '../../../sale-offer-update/domains/sale-offer-details.domain';
import { ISaleOfferFormRegisterPayloadDomain } from '../../domains/sale-offer-form-register.domain';

const convertCargoType = (cargoType: CargoTypesResponse): 'ftl' | 'ltl' | 'stl' => {
  const cargoTypeMap: { [key: string]: 'ftl' | 'ltl' | 'stl' } = {
    FTL: 'ftl',
    LTL: 'ltl',
    STL: 'stl',
  };

  return cargoTypeMap[cargoType];
};

export const convertSaleDetailsToSaleRegister = (
  saleDetails: ISaleOfferDomain,
): ISaleOfferFormRegisterPayloadDomain => {
  return {
    origin: {
      ...saleDetails.origin,
      address: {
        ...saleDetails.origin.address,
        lat: 0,
        lng: 0,
      },
    },
    destination: {
      ...saleDetails.destination,
      address: {
        ...saleDetails.destination.address,
        lat: 0,
        lng: 0,
      },
    },
    cargoType: convertCargoType(saleDetails.cargoType),
    prohibitedCargo: saleDetails.prohibitedCargo,
    prohibitedOtherCargo: saleDetails.prohibitedOtherCargo,
    saleOfferDetails: saleDetails.saleOfferDetails,
  };
};
