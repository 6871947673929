import styled, { css } from 'styled-components';

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>`
  ${() => css`
    display: grid;
    grid-gap: 5px;
    margin-bottom: 20px;
    flex-direction: column;
  `}
`;

type LineSectionStyledProps = { columns: string };
export const LineSectionStyled = styled.section<LineSectionStyledProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 5px 20px;
  `}
`;

export const FooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
