import React, { Fragment } from 'react';
import { Tab } from 'src-new/components/tab/tab.component';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { ScheduleRefusedTable } from './components/schedule-refused-table/schedule-refused-table.component';

export interface IScheduleRefusedTableTabsViewProps {
  tabsContent: Array<ITabContent>;
}

export const ScheduleRefusedTableTabsView: React.FC<IScheduleRefusedTableTabsViewProps> = ({ tabsContent }) => {
  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive="Cargas Recusadas" setActiveTabName={() => null} />
      <ScheduleRefusedTable />
    </Fragment>
  );
};
