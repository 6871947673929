import styled, { css } from 'styled-components';

type WrapperFooterPageProps = { sidebarIsHovered: number };

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
`;

export const WrapperFooterPage = styled.div<WrapperFooterPageProps>`
  ${({ sidebarIsHovered }) => css`
    position: fixed;
    bottom: 0;
    width: calc(100% - ${sidebarIsHovered === 0 ? '120' : sidebarIsHovered}px);
    padding-bottom: 5px;
    background-color: white;
    z-index: 2;
  `};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  padding-bottom: 40px;
`;

export const HeaderTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
`;

export const HeaderTable = styled.p`
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
`;

export const Info = styled.p`
  font-size: 14px;
`;

type ILineSection = { columns: string };
export const LineSection = styled.div<ILineSection>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
    align-items: center;
    margin-bottom: 10px;
  `}
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 35px;
`;

export const Strong = styled.strong``;

export const LabelWrapper = styled.div``;

export const ModalLabel = styled.span`
  font-weight: 500;
`;
