import { FC, useContext, useMemo } from 'react';
import { ScheduleDetailsAccordionsView } from './schedule-details-accordions.component-view';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const ScheduleDetailsAccordions: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const checkAccordion = useMemo(() => {
    const {
      origin: { id: originId },
      destination: { id: destinationId },
      cargo: { vehicleType, vehicleCategoryType },
      detail: { levyInitialDate, levyEndDate, deliveryInitialDate, deliveryEndDate },
    } = scheduleDetails.scheduleDetails;

    return [
      originId,
      destinationId,
      vehicleType,
      vehicleCategoryType,
      levyInitialDate,
      levyEndDate,
      deliveryInitialDate,
      deliveryEndDate,
    ].every(Boolean);
  }, [scheduleDetails.scheduleDetails]);

  return <ScheduleDetailsAccordionsView checkedAccordion={checkAccordion} />;
};
