import { IHomeCardsDomain } from './home-cards.domain';

export const homeCardsInitialValuesDomain: IHomeCardsDomain = {
  purchaseOffer: {
    totalMatches: 0,
    totalRoutes: 0,
    potencialValue: 0,
  },
  saleOffer: {
    totalMatches: 0,
    totalRoutes: 0,
    potencialValue: 0,
  },
};
