import { ISaleOfferMatchListDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-match-list.domain';

export const saleOfferMatchSelectedInitialValuesConstant: ISaleOfferMatchListDomain = {
  id: 0,
  matchId: 0,
  company: {
    id: 0,
    name: '',
    image: '',
  },
  origin: {
    id: 0,
    name: '',
    type: '',
    address: {
      city: '',
      uf: '',
    },
  },
  destination: {
    id: 0,
    name: '',
    type: '',
    address: {
      city: '',
      uf: '',
    },
  },
  vehicle: {
    type: '',
    category: '',
  },
  capacity: {
    weight: 0,
    pallet: 0,
  },
  values: {
    freightValue: 0,
  },
  operationType: '',
  frequency: 0,
  co2Economy: 0,
  additionalDistance: 0,
  backhaulPartner: true,
  backhaulType: '',
  predominantProduct: '',
  score: {
    origin: 0,
    destination: 0,
    vehicle: 0,
  },
};
