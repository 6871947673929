import { Method } from 'axios';
import { convertClientEntityToDomainMapperV2 } from '../mappers/convert-client-entity-to-domain-v2/convert-client-entity-to-domain-v2.mapper';
import { IClientEntity } from './entities/client.entity';
import { showToast } from 'components/toast/toast';
import { IClient, IClientStatus, IOperationalRequirement } from 'domain/client';
import { CheckDeleteResponse } from 'domain/global';
import { IListPageV2 } from 'domain/page-info';
import { FileListUploaded } from 'pages/freight/types';
import API from 'utils/API/API';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { convertClientDomainToEntityMapper } from '../mappers/convert-client-domain-to-entity/convert-client-domain-to-entity.mapper';

const clientServiceRequest = async <T = any>(method: Method, url: string, data?: any, errorMessage?: string) => {
  try {
    const response = await API({
      method,
      url,
      params: method === 'GET' ? data : undefined,
      data: method !== 'GET' ? data : undefined,
    });

    return {
      ...response,
      data: response.data as T,
    };
  } catch (error: any) {
    showMessageFormatted({
      message: '',
      error: errorMessage ? errorMessage : error,
      type: 'error',
    });
    throw new Error(errorMessage ? errorMessage : error);
  }
};

export interface IClientListProps {
  search: string;
  page: number;
  status?: IClientStatus;
  order: string;
  column: string;
}

const LIMIT_PAGE = 50;

export const clientListService = async (params: IClientListProps): Promise<IListPageV2<IClient>> => {
  const { data } = await clientServiceRequest(
    'GET',
    'v2/clients',
    { ...params, limit: LIMIT_PAGE },
    'Erro ao buscar Parceiros',
  );

  const { items, meta } = data;

  return {
    list: items.map((item: IClientEntity) => convertClientEntityToDomainMapperV2(item)),
    meta,
  };
};

export const clientDetailsService = async (clientId: string): Promise<IClientDomain> => {
  const { data } = await clientServiceRequest(
    'GET',
    `clients/${clientId}`,
    undefined,
    'Você não tem permissão para acessar esse parceiro.',
  );

  return { ...data, outbound: data.oubound };
};

export const getRequirementService = async (): Promise<Array<IOperationalRequirement>> => {
  const { data } = await API_TRAVELS_SVC.get('v1/operational-requirements?page=1&limit=100');
  return data.items;
};

export const allClients = async () => {
  const { data } = await API.get('/v2/clients/map-listing');
  return data;
};

export const clientDeleteService = async (ids: number[]): Promise<boolean> => {
  await clientServiceRequest('DELETE', 'clients', ids, 'Erro ao deletar parceiros');

  return true;
};

export const clientRegisterService = async (client: IClientDomain) => {
  const convertedClient = convertClientDomainToEntityMapper(client);
  const { data } = await clientServiceRequest('POST', 'clients', convertedClient);
  return data;
};

export interface IClientReportExcelProps {
  search: string;
  status: IClientStatus | undefined;
}

export const clientReportExcelService = async ({
  search,
  status,
}: IClientReportExcelProps): Promise<BlobPart | undefined> => {
  const { data } = await API.get('v2/clients/export-file', {
    params: {
      status,
      search,
    },
    responseType: 'arraybuffer',
  });

  return data;
};

export const clientUpdateService = async (client: IClientDomain) => {
  const convertedClient = convertClientDomainToEntityMapper(client);
  await clientServiceRequest('PUT', 'clients', convertedClient, 'Erro ao atualizar parceiros');
};

export const clientUploadService = async (formData: FormData) => {
  return await API.post('v2/clients/upload', formData);
};

export const clientsDeleteService = async (ids: number[]): Promise<boolean> => {
  await clientServiceRequest('DELETE', 'v2/clients', { ids }, 'Erro ao deletar Parceiros');

  return true;
};

export const clientCheckDelete = async (ids: number[]): Promise<CheckDeleteResponse[]> => {
  const response = await clientServiceRequest(
    'POST',
    'v2/clients/status/remove',
    { ids },
    'Erro ao checar os Parceiros',
  );

  return response.data;
};

export const uploadFiles = async (clientId: number | string, files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'ANEXO');
    formData.append('module', 'client');
    formData.append('codigo', String(clientId));

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Anexo',
    });
    throw new Error('Houve um erro ao realizar o upload do Anexo.');
  }
};

export const listUploadedFiles = async (freightId: number | string): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>(`v2/file/management?module=client&type=ANEXO&codigo=${freightId}`);

    return data;
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao listar o Anexo',
    });
    throw new Error('Houve um erro ao listar o Anexo.');
  }
};

export const deleteUploadedFiles = async (id: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${id}`);
  } catch (error) {
    console.error(error);
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o Anexo',
    });
    throw new Error('Houve um erro ao excluir o Anexo.');
  }
};

export const uploadTemplate = async (files: any) => {
  try {
    const formData = new FormData();
    formData.append('type', 'TEMPLATE-CLIENT');
    formData.append('module', 'client');
    formData.append('codigo', '01');
    formData.append('file', files);

    const { data } = await API.post<any>('v2/file/management/upload', formData);

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao realizar o upload do Template',
    });
    throw new Error('Houve um erro ao realizar o upload do Template.');
  }
};

export const listUploadedTemplate = async (): Promise<FileListUploaded[]> => {
  try {
    const { data } = await API.get<any>('v2/file/management?module=client&type=TEMPLATE-CLIENT&codigo=01');

    return data;
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao baixar o template',
    });
    throw new Error('Houve um erro ao baixar o template.');
  }
};

export const deleteUploadedTemplate = async (templateId: number | string): Promise<void> => {
  try {
    await API.delete<any>(`v2/file/management/${templateId}`);
  } catch (error) {
    showToast({
      type: 'error',
      message: 'Houve um erro ao excluir o template',
    });
    throw new Error('Houve um erro ao excluir o template.');
  }
};
