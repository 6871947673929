import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { IdlenessListInput } from 'domain/idleness';
import { IBackhaulCreated } from 'domain/backhaul';

type ValueType = 'origin' | 'destination';

interface LocationValues {
  id: number;
  type: string;
  name: string;
  city: string;
  uf: string;
}

export const getLocationValues = (
  valueType: ValueType,
  idlenessValues: IdlenessListInput,
  scheduleValues: ScheduleRegisterInput,
  transportRouteValues: IBackhaulCreated,
) => {
  const locationValues: Record<ValueType, LocationValues> = {
    origin: {
      id:
        idlenessValues?.id_locality_origin ??
        idlenessValues?.id_client_origin ??
        transportRouteValues?.localityOrigin?.id ??
        transportRouteValues?.clientOrigin?.id ??
        scheduleValues?.origin?.id ??
        0,

      type:
        idlenessValues.origin_locality_cep || transportRouteValues?.localityOrigin?.id
          ? 'LOCALITY'
          : idlenessValues?.origin_client_cep || transportRouteValues?.clientOrigin?.id
            ? 'CLIENT'
            : scheduleValues?.origin?.type ?? '',

      name:
        idlenessValues?.origin_locality_name ??
        idlenessValues?.origin_client ??
        idlenessValues?.clientOrigin?.name ??
        idlenessValues?.clientOrigin?.fantasyName ??
        idlenessValues?.localityOrigin?.name ??
        idlenessValues?.localityOrigin?.fantasyName ??
        transportRouteValues?.localityOrigin?.name ??
        transportRouteValues?.clientOrigin?.tradeName ??
        scheduleValues?.origin?.tradeName ??
        '',

      city:
        idlenessValues?.origin_client_city ??
        idlenessValues?.origin_locality_city ??
        idlenessValues?.clientOrigin?.city ??
        idlenessValues?.localityOrigin?.city ??
        transportRouteValues?.localityOrigin?.city ??
        transportRouteValues?.clientOrigin?.city ??
        scheduleValues?.origin?.origin?.address.city ??
        '',

      uf:
        idlenessValues?.origin_client_uf ??
        idlenessValues?.origin_locality_uf ??
        idlenessValues?.clientOrigin?.uf ??
        idlenessValues?.localityOrigin?.uf ??
        transportRouteValues?.localityOrigin?.uf ??
        transportRouteValues?.clientOrigin?.uf ??
        scheduleValues?.origin?.origin?.address.uf ??
        '',
    },
    destination: {
      id:
        idlenessValues?.id_locality_destination ??
        idlenessValues?.id_client_destination ??
        transportRouteValues?.localityDestination?.id ??
        transportRouteValues?.clientDestination?.id ??
        scheduleValues?.destination?.id ??
        0,

      type:
        idlenessValues.destination_locality_cep || transportRouteValues?.localityDestination?.id
          ? 'LOCALITY'
          : idlenessValues?.destination_client_cep || transportRouteValues?.clientDestination?.id
            ? 'CLIENT'
            : scheduleValues?.destination?.type ?? '',

      name:
        idlenessValues?.destination_locality_name ??
        idlenessValues?.destination_client ??
        idlenessValues?.localityDestination?.name ??
        idlenessValues?.localityDestination?.fantasyName ??
        idlenessValues?.clientDestination?.name ??
        idlenessValues?.clientDestination?.fantasyName ??
        transportRouteValues?.localityDestination?.name ??
        transportRouteValues?.clientDestination?.tradeName ??
        scheduleValues?.destination?.tradeName ??
        '',

      city:
        idlenessValues?.destination_client_city ??
        idlenessValues?.destination_locality_city ??
        idlenessValues?.localityDestination?.city ??
        idlenessValues?.clientDestination?.city ??
        transportRouteValues?.localityDestination?.city ??
        transportRouteValues?.clientDestination?.city ??
        scheduleValues?.destination?.destination?.address?.city ??
        '',

      uf:
        idlenessValues?.destination_client_uf ??
        idlenessValues?.destination_locality_uf ??
        idlenessValues?.localityDestination?.uf ??
        idlenessValues?.clientDestination?.uf ??
        transportRouteValues?.localityDestination?.uf ??
        transportRouteValues?.clientDestination?.uf ??
        scheduleValues?.destination?.destination?.address?.uf ??
        '',
    },
  };

  return locationValues[valueType];
};
