import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext } from 'react';

import { ChangePasswordSchemas } from './schemas-validation/schemas-validation';
import FormChangePasswordView from './form-change-password-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { useChangePasswordRegisterHook } from 'pages/change-password/hooks/use-change-password-register/use-change-password-register.hook';
import { IChangePassword } from 'domain/change-password';

export const initialValues: IChangePassword = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

const FormChangePassword: React.FC = () => {
  const PATH_TO_HOME = '/home';

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const changePasswordRegisterHook = useChangePasswordRegisterHook();

  const goBackToHome = useCallback(() => navigate(PATH_TO_HOME), [navigate]);

  const handleSubmit = useCallback(
    async (changePassword: IChangePassword) => {
      if (user?.id) {
        changePasswordRegisterHook(user?.id, changePassword)
          .then(() => {
            showMessageFormatted({
              message: 'Senha atualizada com sucesso.',
              type: 'success',
            });
            goBackToHome();
          })
          .catch((error) =>
            showMessageFormatted({
              message: '',
              error: error,
              type: 'error',
            }),
          );
      }
    },
    [user?.id, changePasswordRegisterHook, goBackToHome],
  );

  return (
    <FormChangePasswordView
      changePasswordValues={initialValues}
      validationSchema={ChangePasswordSchemas}
      handleGoBack={goBackToHome}
      handleSubmit={handleSubmit}
      login={user?.login}
    />
  );
};

export default FormChangePassword;
