/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHandleUploadFileFileHook } from '..';

import { vehicleRegisterService } from 'pages/vehicle/services/vehicle-register/vehicle-register.service';
import { IVehicleNew } from 'domain/vehicle';

const useVehicleRegisterHook = () => {
  const handleUploadCteFileHook = useHandleUploadFileFileHook();
  return async (vehicle: IVehicleNew, uploadedList: any) => {
    const { id } = await vehicleRegisterService(vehicle);
    uploadedList?.map(async (cte: any) => {
      await handleUploadCteFileHook(cte, id);
    });
  };
};

export { useVehicleRegisterHook };
