import { FC, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';

import * as S from './styled';

import { convertOperationType } from 'utils-v2/converters/operation';
import { convertHaulType } from 'utils-v2/converters/haul-type';
import { formatDecimal } from 'utils/format-numbers';
import { ColorsV2Type } from 'styles/types/colors';
import { ScheduleType } from 'domain-v2/inputs/schedule-type';
import { Operation, OperationType } from 'domain-v2/inputs/operation';
import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';

interface ISubtitleProps {
  haulType: Operation;
  scheduleType: ScheduleType;
  operationType: OperationType;
  co2: number;
  requirements?: Array<string>;
}

export const ModalFormSubTitle: FC<ISubtitleProps> = ({ haulType, scheduleType, operationType, co2, requirements }) => {
  const renderRequirements = useMemo(() => {
    if (requirements?.length === 0) return undefined;

    return requirements?.map((tag) => (
      <Tag key={tag} label={tag} color={ColorsV2Type.Blue} variant="solid" display="inline-flex" size="lg" />
    ));
  }, [requirements]);

  return (
    <S.WrapperTags>
      <Tag
        label={convertHaulType(haulType).label}
        color={convertHaulType(haulType).color}
        variant="solid"
        display="inline-flex"
        size="lg"
      />
      <Tag
        LeftIcon={
          <S.TagText>
            <DoubleArrowDownBlueIcon />
            CO₂
          </S.TagText>
        }
        label={`${formatDecimal(co2 || 0)} KG`}
        color={ColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />
      <Tag label={scheduleType} color={ColorsV2Type.Blue} variant="ghost" display="inline-flex" size="lg" />
      <Tag
        label={convertOperationType(operationType).label}
        color={ColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />
      {renderRequirements}
    </S.WrapperTags>
  );
};
