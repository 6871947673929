import React, { useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './table-freight-list-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface ITableFreightTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  meta?: PageInfo;
  changePage: (page: number) => void;
  isLoading: boolean;
}

const dataTestId = 'location-table-view';

const TableFreightTableView: React.FC<ITableFreightTableViewProps> = ({
  headerTable,
  contentTable,
  meta,
  changePage,
  isLoading,
}: ITableFreightTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (meta) {
      return (
        <ContainerPaginationStyled data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={meta.currentPage} changePage={changePage} totalPage={meta.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, meta]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table header={headerTable} content={contentTable} isLoading={isLoading} />
      {renderPagination}
    </ContainerTableStyled>
  );
};

export default TableFreightTableView;
