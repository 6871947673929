import React from 'react';
import { ContactRegistrationAlertsView } from './contact-registration-alerts-view.component';
import { IContactAlert } from 'src-new/pages/registrations/domains/contact.domain';
import { useFormikContext } from 'formik';
import { RegistrationContactValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IContactRegistrationAlertsProps {
  position: number;
  alerts: Array<IContactAlert>;
}

export const ContactRegistrationAlerts: React.FC<IContactRegistrationAlertsProps> = ({ position, alerts }) => {
  const { handleChange } = useFormikContext<RegistrationContactValues>();

  return <ContactRegistrationAlertsView position={position} alerts={alerts} handleChange={handleChange} />;
};
