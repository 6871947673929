import API from 'utils/API/API';

export type DeleteRecordsType = 'CLIENT' | 'LOCATION';

export const deleteRecordsService = async (ids: Array<number>, type: DeleteRecordsType): Promise<boolean> => {
  return await API.delete(`v2/${getUrlRequestByType(type)}`, { data: { ids } });
};

const getUrlRequestByType = (type: DeleteRecordsType) => {
  switch (type) {
    case 'LOCATION':
      return 'localities';
    case 'CLIENT':
      return 'clients';
  }
};
