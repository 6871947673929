import {
  CategoryType,
  CertificationsType,
  IDriverCreatedDomain,
  SSPType,
} from 'src-new/pages/registrations/domains/driver.domain';

export const PATH_TO_DRIVER_LIST = '/motoristas';

export const initialValues: IDriverCreatedDomain = {
  id: 0,
  name: '',
  status: 'active',
  firstPhone: '',
  secondPhone: '',
  cnhNumber: '',
  cnhValidate: '',
  cnhCategory: '',
  cpf: '',
  rg: '',
  ssp: '',
  issueDate: '',
  tac: '',
  certification: [],
  cep: '',
  city: '',
  uf: '',
  neighborhood: '',
  street: '',
  numberHouse: '',
  complement: '',
  driverPhoto: '',
  cnhPhoto: '',
  certificationPhoto: '',
  addressProofPhoto: '',
  fleetType: '',
  attachments: [],
  originalAttachments: [],
  createdAt: '',
  updatedAt: '',
};

export const optionsCertifications: Array<{
  label: string;
  value: CertificationsType;
}> = [
  { label: 'BUONNY', value: 'Buonny' },
  { label: 'PAMCARY', value: 'Pamcary' },
  { label: 'MOPP', value: 'Mopp' },
];

export const optionsCategory: Array<{ label: string; value: CategoryType }> = [
  { label: 'B', value: 'Categoria B' },
  { label: 'C', value: 'Categoria C' },
  { label: 'D', value: 'Categoria D' },
  { label: 'E', value: 'Categoria E' },
  { label: 'AB', value: 'Categoria AB' },
  { label: 'AC', value: 'Categoria AC' },
  { label: 'AD', value: 'Categoria AD' },
  { label: 'AE', value: 'Categoria AE' },
];

export const optionsSSP: Array<{ label: string; value: SSPType }> = [
  { label: 'SSPAL', value: 'AL' },
  { label: 'SSPAP', value: 'AP' },
  { label: 'SSPAM', value: 'AM' },
  { label: 'SSPBA', value: 'BA' },
  { label: 'SSPCE', value: 'CE' },
  { label: 'SSPDF', value: 'DF' },
  { label: 'SSPES', value: 'ES' },
  { label: 'SSPGO', value: 'GO' },
  { label: 'SSPMA', value: 'MA' },
  { label: 'SSPMT', value: 'MT' },
  { label: 'SSPMS', value: 'MS' },
  { label: 'SSPMG', value: 'MG' },
  { label: 'SSPPA', value: 'PA' },
  { label: 'SSPPB', value: 'PB' },
  { label: 'SSPPR', value: 'PR' },
  { label: 'SSPPE', value: 'PE' },
  { label: 'SSPPI', value: 'PI' },
  { label: 'SSPRJ', value: 'RJ' },
  { label: 'SSPRN', value: 'RN' },
  { label: 'SSPRS', value: 'RS' },
  { label: 'SSPRO', value: 'RO' },
  { label: 'SSPRR', value: 'RR' },
  { label: 'SSPSC', value: 'SC' },
  { label: 'SSPSP', value: 'SP' },
  { label: 'SSPSE', value: 'SE' },
  { label: 'SSPTO', value: 'TO' },
];

export const optionsRiskManager: Array<{ label: string; value: string }> = [
  { label: 'ABM', value: 'ABM' },
  { label: 'APISUL', value: 'Apisul' },
  { label: 'ATLAS', value: 'Atlas' },
  { label: 'BRASIL RISK', value: 'Brasil Risk' },
  { label: 'BUONNY', value: 'Buonny' },
  { label: 'MONISAT', value: 'Monisat' },
  { label: 'MONYTOR', value: 'Monytor' },
  { label: 'MUNDIAL RISK', value: 'Mundial Risk' },
  { label: 'OPEN TECH', value: 'Open Tech' },
  { label: 'SKYMARK', value: 'Skymark' },
  { label: 'SMART RISK', value: 'Smart Risk' },
  { label: 'TECNORISK', value: 'Tecnorisk' },
  { label: 'ULTRASAT', value: 'Ultrasat' },
  { label: 'OUTRA', value: 'Outra' },
];
