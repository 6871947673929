import React, { useMemo } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';

import { ContainerInfoStyled, HeaderTitle, Info, SectionContainer, Wrapper } from '../styled';

import { convertStatusCode } from 'utils/status-code';
import { ColorsV2Type } from 'styles/types/colors';
import { convertOperationTypeEntityToLabel } from 'pages/freight/mappers/convert-operation-type-entity-to-label/convert-operation-type-entity-to-label.mapper';
import {
  IAllocationCargo,
  IAllocationShippingDetails,
  ICurrentAllocation,
} from 'pages/allocation/contexts/allocation-register/allocation.types';

interface IProps {
  orderDetails?: IAllocationCargo;
  statusCodeValue?: string;
  idSheduleValues?: string;
  type: 'ltl' | 'ftl' | 'stl' | '';
  OccurrenceTime?: string;
  scheduleCargo?: IAllocationShippingDetails;
  occurrenceStatus?: string;
  occurrenceStartTime?: string;
  freightValues?: ICurrentAllocation;
}

const FreightVehicleSectionView: React.FC<IProps> = ({
  orderDetails,
  statusCodeValue,
  idSheduleValues,
  type,
  scheduleCargo,
  OccurrenceTime,
  occurrenceStatus,
  occurrenceStartTime,
  freightValues,
}) => {
  const isFinished = occurrenceStatus === 'Finalizada';

  const occurrenceTime = useMemo(() => {
    if (isFinished) return OccurrenceTime;

    if (occurrenceStartTime) {
      const endDateTime: moment.Moment = moment();
      const startDateTime: moment.Moment = moment(occurrenceStartTime, 'DD/MM/YYYY HH:mm');

      const duration: moment.Duration = moment.duration(endDateTime.diff(startDateTime));

      const daysDuration = duration.days();
      const hoursDuration = duration.hours();

      const resolutionTime = `${daysDuration} ${daysDuration > 1 ? 'DIAS' : 'DIA'} ${hoursDuration} ${
        hoursDuration > 1 ? 'HORAS' : 'HORA'
      }`;

      return resolutionTime;
    }

    return '0 DIA 0 HORA';
  }, [isFinished, OccurrenceTime, occurrenceStartTime]);

  const scheduleColors: {
    [key: string]: { color: ColorsV2Type; text: string };
  } = {
    ftl: { color: ColorsV2Type.Blue, text: 'FTL' },
    stl: { color: ColorsV2Type.Blue100, text: 'STL' },
    ltl: { color: ColorsV2Type.Aqua100, text: 'LTL' },
  };

  const scheduleColor = scheduleColors[type ? type : ''];

  return (
    <Wrapper display="flex" justify="space-between">
      <div>
        <HeaderTitle>Detalhes do Serviço</HeaderTitle>
        <div style={{ display: 'flex', gap: 10 }}>
          <Tag
            label={scheduleColor?.text}
            color={scheduleColor?.color}
            variant="solid"
            display="inline-flex"
            size="md"
          />
          {scheduleCargo && (
            <div>
              {statusCodeValue !== 'IN90' &&
              moment(scheduleCargo?.deliveryEndDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? (
                <Tag label="Em Atraso" color={ColorsV2Type.Red} variant="solid" display="inline-flex" size="md" />
              ) : (
                <Tag label="No Prazo" color={ColorsV2Type.Green} variant="solid" display="inline-flex" size="md" />
              )}
            </div>
          )}
        </div>
      </div>

      <SectionContainer display="flex">
        <ContainerInfoStyled>
          <span style={{ marginRight: 30 }}>Operado Por</span>
          <Info>{freightValues?.currentFreight === 2 ? freightValues.company?.tradeName : 'Logshare' || '-'}</Info>
        </ContainerInfoStyled>

        <ContainerInfoStyled>
          <span style={{ marginRight: 30 }}>Frete ID</span>
          <Info>{idSheduleValues || '-'} </Info>
        </ContainerInfoStyled>

        {orderDetails && (
          <ContainerInfoStyled>
            <span style={{ marginRight: 30 }}>Tipo de Operação</span>
            <Info>{convertOperationTypeEntityToLabel(orderDetails?.operationType || '-')}</Info>
          </ContainerInfoStyled>
        )}

        {statusCodeValue && (
          <ContainerInfoStyled>
            <span style={{ marginRight: 30 }}>Status</span>
            <Info>{convertStatusCode(statusCodeValue || '-')}</Info>
          </ContainerInfoStyled>
        )}

        {occurrenceStatus && (
          <ContainerInfoStyled>
            <span style={{ marginRight: 30 }}>Status</span>
            <Info>{occurrenceStatus}</Info>
          </ContainerInfoStyled>
        )}

        {OccurrenceTime && (
          <ContainerInfoStyled>
            <span style={{ marginRight: 4 }}>Tempo de Tratativa</span>
            <Info>{occurrenceTime} </Info>
          </ContainerInfoStyled>
        )}
      </SectionContainer>
    </Wrapper>
  );
};

export default React.memo(FreightVehicleSectionView);
