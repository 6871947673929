import styled, { css } from 'styled-components';

export const InputDateContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    margin-right: 10px;

    & svg {
      width: 100%;
    }
  `}
`;

export const StyledDatePickerWrapper = styled.div<{
  hasError?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, hasError = false, disabled = false }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    border-radius: 4px;

    & .SingleDatePicker,
    .SingleDatePickerInput {
      height: 38px;
      width: 100%;
      flex-direction: row-reverse;
      display: flex;
      border-radius: 4px;
      border-color: ${hasError && 'red'};
      align-items: center;

      .DateInput {
        width: 100%;
        background-color: transparent;
        .DateInput_input {
          min-height: 36px;
          color: #030517;
          font-family: Montserrat;
          font-weight: 600;
          font-size: 13px;
          width: 100%;
          background-color: transparent;
        }
      }

      .SingleDatePickerInput__withBorder {
        overflow: hidden;

        .CalendarDay__selected {
        }
      }

      .SingleDatePicker_picker.SingleDatePicker_picker {
        top: 40px !important;
        left: 2px;
        left: 2px !important;
        margin-bottom: 5px;
      }
    }

    ${!disabled &&
    `.SingleDatePickerInput:hover,
    .SingleDatePickerInput:focus-within {
      border: 1px solid ${theme.colorsV2.blue};
      box-shadow: inset 0 0 0 1px ${theme.colorsV2.blue};
    }`}
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;

export const LabelStyled = styled.label<{
  hasError?: boolean;
}>`
  ${({ theme, hasError = false }) => css`
    font-size: 11px;
    color: ${hasError ? theme.colors.error : theme.colors.black};
    background-color: ${hasError ? 'red' : 'white'};
  `}
`;
