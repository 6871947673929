import { memo } from 'react';

import * as S from './styled';

import { PageMeta } from 'domain/page-info';
import Status from 'components/status-circle/status-circle';
import Pagination from 'components/pagination/pagination';

interface FooterProps {
  pageInfo: PageMeta;
  changePage: (page: number) => void;
}

const FooterModalMatchList: React.FC<FooterProps> = ({ pageInfo, changePage }) => {
  return (
    <S.Wrapper>
      <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />

      <S.SynergyLegends>
        <Status bgColor="#00B050" isSquare title={'Alto Potencial'} />
        <Status bgColor="#FFC000" isSquare title={'Médio Potencial'} />
        <Status bgColor="#C00000" isSquare title={'Baixo Potencial'} />
      </S.SynergyLegends>
    </S.Wrapper>
  );
};

export default memo(FooterModalMatchList);
