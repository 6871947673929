import styled, { css } from 'styled-components';

type ContainerProps = {
  overflow?: boolean;
  smallModal?: boolean;
  height?: number;
};

export const ContainerStyled = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 20px;
  background-color: #05050581;
  z-index: 999;
`;

export const ContainerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const TitleStyled = styled.p`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: ${theme.font.bold};
  `}
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const ContentStyled = styled.div<ContainerProps>`
  ${({ overflow = false, smallModal, height }) => css`
    background-color: white;
    border-radius: 5px;
    padding: 30px;
    width: ${smallModal ? 'fit-content' : '1200px'};
    min-height: ${height ?? '237px'};
    max-height: 600px;
    overflow-y: ${overflow ? 'hidden' : 'auto'};
    display: flex;
    flex-direction: column;
  `}
`;
