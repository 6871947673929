import React, { useContext, useEffect, useState } from 'react';
import { FormikProps, withFormik } from 'formik';

import VehicleFileSection from '../../../vehicle-register/components/vehicle-file-upload/vehicle-file-upload';

import { FormStyled, LineSectionStyled, SectionStyled } from './vehicle-register-form.styled';

import { UserContext } from 'state/user-context';
import {
  IFleetType,
  IVehicleCategory,
  IVehicleNew,
  IVehicleTracker,
  IVehicleType,
  IVehicleActuators,
  IFuel,
  IVehicleSensors,
} from 'domain/vehicle';
import { FooterPage } from 'components-v2/common/footer-page';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import MultiSelect from 'components/multi-select/multi-select';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import AsyncSelectField from 'components/async-select/async-select';

interface VehicleRegisterFormViewProps {
  vehicleRegisterValues: IVehicleNew;
  optionsFuel: Array<{ label: string; value: IFuel }>;
  optionsVehicleType: Array<{ label: string; value: IVehicleType }>;
  optionsVehicleCategory: Array<{ label: string; value: IVehicleCategory }>;
  optionsVehicleSensors: Array<{ label: string; value: IVehicleSensors }>;
  optionsVehicleActuators: Array<{ label: string; value: IVehicleActuators }>;
  optionsVehicleTracker: Array<{ label: string; value: IVehicleTracker }>;
  optionsVehicleYear: Array<{ label: string; value: string }>;
  optionFleetType: Array<{ label: string; value: IFleetType }>;
  handleGoBack: () => void;
  handleRegister: (values: IVehicleNew) => void;
  shippingApiCall?: (searchValue: string) => Promise<Array<{ label: string; value: number }>>;
  handleCompleteShippingInformation?: (id: number) => Promise<void>;
  schemaValidation: object;
  isLoading: boolean;
}

const VehicleRegisterFormView: React.FC<VehicleRegisterFormViewProps> = ({
  vehicleRegisterValues,
  optionsFuel,
  optionsVehicleType,
  optionsVehicleCategory,
  optionsVehicleTracker,
  optionsVehicleSensors,
  optionsVehicleActuators,
  optionsVehicleYear,
  optionFleetType,
  handleGoBack,
  handleRegister,
  shippingApiCall,
  handleCompleteShippingInformation,
  schemaValidation,
  isLoading,
}) => {
  const InnerForm = (props: FormikProps<IVehicleNew>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const [submit, setSubmit] = useState(false);

    useEffect(() => {
      function verificarObjetoVazio(obj: any) {
        if (Object.keys(obj).length !== 0) {
          return true;
        } else {
          return false;
        }
      }

      if (verificarObjetoVazio(errors) && verificarObjetoVazio(touched)) {
        showToast({
          message: 'É necessário preencher todos os campos obrigatórios',
          type: 'error',
        });
        setSubmit(false);
      }
    }, [submit]);

    const handleVerifyInputs = () => {
      handleSubmit();
      setSubmit(true);
    };

    const [uploadedList, setUploadedList] = useState<any[]>([]);

    const handleChangeFile = (value?: any, index?: number) => {
      if (value !== undefined) {
        setUploadedList([...uploadedList, { 0: value[0], length: value.length }]);
        setFieldValue('uploadedList', [...uploadedList, { 0: value[0], length: value.length }]);
      } else if (index !== undefined) {
        const updatedItems = [...uploadedList];
        updatedItems.splice(index, 1);
        setUploadedList(updatedItems);
        setFieldValue('uploadedList', updatedItems);
      }
    };

    const { user } = useContext(UserContext);

    useEffect(() => {
      if (user?.shippingCompanyId) {
        setFieldValue('shippingCompanyId', user.shippingCompanyId);
        setFieldValue('fleetType', 'Frota Própria');
      }
    }, [setFieldValue, user?.shippingCompanyId]);

    return (
      <FormStyled>
        <SectionStyled name="Veículos">
          <LineSectionStyled columns="0.5fr 1fr 1fr 1fr 1fr 1fr 1fr">
            <InputField label="ID *" id="id" name="id" type="text" disabled />
            <InputField
              label="Placa Principal *"
              id="mainBoard"
              name="mainBoard"
              type="text"
              onChange={handleChange}
              value={values.mainBoard}
              hasError={!!errors.mainBoard && !!touched.mainBoard}
              errorMessage={String(errors.mainBoard)}
            />
            <InputField
              label="Placa 2"
              id="secondBoard"
              name="secondBoard"
              type="text"
              onChange={handleChange}
              value={values.secondBoard}
            />
            <InputField
              label="Placa 3"
              id="thirdBoard"
              name="thirdBoard"
              type="text"
              value={values.thirdBoard}
              onChange={handleChange}
            />
            <InputCurrency
              label="Carga Máxima (Kg)"
              upperCase={false}
              id="capacity"
              name="capacity"
              type="text"
              allowDecimals={false}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.capacity}
              suffix=" Kg"
              hasError={!!errors.capacity && !!touched.capacity}
              errorMessage={String(errors.capacity)}
            />
            <InputCurrency
              label="Paletes (Qtde)"
              upperCase={false}
              id="palletCapacity"
              name="palletCapacity"
              type="text"
              allowDecimals={false}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.palletCapacity}
              suffix=" "
              hasError={!!errors.palletCapacity && !!touched.palletCapacity}
              errorMessage={String(errors.palletCapacity)}
            />
            <InputCurrency
              label="Cubagem (M³)"
              upperCase={false}
              id="cubageCapacity"
              name="cubageCapacity"
              type="text"
              allowDecimals={false}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.cubageCapacity}
              suffix=" m³"
              hasError={!!errors.cubageCapacity && !!touched.cubageCapacity}
              errorMessage={String(errors.cubageCapacity)}
            />
          </LineSectionStyled>
          <LineSectionStyled columns="1fr 1fr 0.5fr 1fr 1fr 1fr 1fr">
            <Select
              label="Tipo de Veículo *"
              id="vehicleType"
              name="vehicleType"
              value={values.vehicleType}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleType && !!touched.vehicleType}
              errorMessage={String(errors.vehicleType)}
              options={optionsVehicleType}
            />
            <Select
              label="Categoria *"
              id="vehicleCategory"
              name="vehicleCategory"
              value={values.vehicleCategory}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleCategory && !!touched.vehicleCategory}
              errorMessage={String(errors.vehicleCategory)}
              options={optionsVehicleCategory}
            />
            <Select
              label="Ano"
              id="year"
              name="year"
              value={String(values.year)}
              setFieldValue={setFieldValue}
              hasError={!!errors.year && !!touched.year}
              errorMessage={String(errors.year)}
              options={optionsVehicleYear}
            />
            <InputField
              label="Marca"
              id="vehicleBrand"
              name="vehicleBrand"
              type="text"
              onChange={handleChange}
              value={values.vehicleBrand}
              hasError={!!errors.vehicleBrand && !!touched.vehicleBrand}
              errorMessage={String(errors.vehicleBrand)}
            />
            <InputField
              label="Modelo"
              id="vehicleModel"
              name="vehicleModel"
              type="text"
              onChange={handleChange}
              value={values.vehicleModel}
              hasError={!!errors.vehicleModel && !!touched.vehicleModel}
              errorMessage={String(errors.vehicleModel)}
            />
            <Select
              label="Combustível *"
              id="fuel"
              name="fuel"
              value={values.fuel}
              setFieldValue={setFieldValue}
              hasError={!!errors.fuel && !!touched.fuel}
              errorMessage={String(errors.fuel)}
              options={optionsFuel}
            />
            <Select
              label="Rastreador"
              id="vehicleTracker"
              name="vehicleTracker"
              value={values.vehicleTracker}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleTracker && !!touched.vehicleTracker}
              errorMessage={String(errors.vehicleTracker)}
              options={optionsVehicleTracker}
            />
          </LineSectionStyled>
        </SectionStyled>

        {user?.profile !== 'shipping-company' && (
          <>
            <SectionDivider label="Modelo de Contratação" />
            <LineSectionStyled columns="0.86fr 2fr" style={{ height: 75 }}>
              <Select
                label="Tipo de Frota *"
                id="fleetType"
                name="fleetType"
                value={values.fleetType}
                setFieldValue={(field, value) => {
                  if (value === 'Frota Própria') {
                    setFieldValue(field, value);
                  } else {
                    setFieldValue('values.shippingCompanyId', '');
                    setFieldValue(field, value);
                  }
                }}
                hasError={!!errors.fleetType && !!touched.fleetType}
                errorMessage={String(errors.fleetType)}
                options={optionFleetType}
                isClearable
              />
              <AsyncSelectField
                placeholder=""
                label="Transportadora"
                isClearable={true}
                name="shippingCompanyId"
                id="shippingCompanyId"
                cacheOptions={true}
                defaultOptions={true}
                onChange={async (e) => {
                  setFieldValue('shippingCompanyId', e && e.value);
                  setFieldValue('name', e && e.label);
                  handleCompleteShippingInformation && (await handleCompleteShippingInformation(e?.value ?? 0));
                }}
                hasError={!!errors.shippingCompanyId && !!touched.shippingCompanyId}
                errorMessage={String(errors.shippingCompanyId)}
                apiCallFunction={shippingApiCall || (async () => [])}
                loadOptions={shippingApiCall || (async () => [])}
                disabled={values.fleetType === 'Frota Terceirizada' ? false : true}
              />
            </LineSectionStyled>
          </>
        )}

        <SectionDivider label="Sensores e Atuadores" />
        <LineSectionStyled columns="1fr">
          <MultiSelect
            label="Sensores"
            options={optionsVehicleSensors}
            menuPosition="fixed"
            onChange={(e) => {
              const vehicleSensors = e.map((option) => option.value);
              setFieldValue('sensors', vehicleSensors);
            }}
          />
          <MultiSelect
            label="Atuadores"
            options={optionsVehicleActuators}
            menuPosition="fixed"
            onChange={(e) => {
              const vehicleActuators = e.map((option) => option.value);
              setFieldValue('actuators', vehicleActuators);
            }}
          />
        </LineSectionStyled>

        <VehicleFileSection handleChangeFile={handleChangeFile} />

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleVerifyInputs} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'ativo'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const VehicleInformationForm = withFormik<IVehicleNew, IVehicleNew>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleRegister(values);
    },
    validationSchema: schemaValidation,
  })(InnerForm);

  return <VehicleInformationForm {...vehicleRegisterValues} />;
};

export default VehicleRegisterFormView;
