/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { FileListContent } from './company-information-file-upload.styled';

import { FileListUploaded } from 'pages/freight/types';
import {
  useHandleGetFileUploadedListHook,
  useHandleUploadFileFileHook,
  useHandleDeleteFileUploadedListHook,
} from 'pages/company/hooks/index';
import Loading from 'components-v2/common/loading/index';
import UploadFile from 'components/upload-file/upload-file';
import { showToast } from 'components/toast/toast';
import SectionDivider from 'components/section-divider/section-divider';
import { InputFile } from 'components/input-file';
import Button from 'components/button/button';

interface IProps {
  id: string;
}

const CompanyInformationFileSection: React.FC<IProps> = ({ id }) => {
  const handleUploadFileHook = useHandleUploadFileFileHook();
  const handleUploadedListHook = useHandleGetFileUploadedListHook();
  const handleDeleteFileUploadedList = useHandleDeleteFileUploadedListHook();

  const [uploadedList, setUploadedList] = useState<FileListUploaded[]>();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateStatusCteData = useCallback(async () => {
    const responseUploadedList = await Promise.resolve(await handleUploadedListHook(id || ''));
    setUploadedList(responseUploadedList);
    setIsLoading(false);
  }, [id]);

  const handleUpdateFile = useCallback(async () => {
    setIsLoading(true);

    await handleUpdateStatusCteData();
  }, [handleUpdateStatusCteData]);

  const handleChangeFile = async (value: any) => {
    await handleUploadFileHook(value, id);
    await handleUpdateFile();
  };

  const handleDeleteFile = useCallback(
    async (fileId: number) => {
      try {
        id && (await handleDeleteFileUploadedList(fileId));
        setUploadedList(uploadedList?.filter((uploadedList) => uploadedList.id !== fileId));
      } catch (error) {
        showToast({
          message: 'Erro ao deletar arquivo',
          type: 'error',
        });
      }
    },
    [uploadedList, handleDeleteFileUploadedList],
  );

  useEffect(() => {
    handleUpdateFile();
  }, [handleUpdateFile]);

  const renderFilesList = useMemo(() => {
    if (isLoading)
      return (
        <div style={{ width: 40, margin: '0 auto' }}>
          <Loading />
        </div>
      );
    const list: any[] = [];

    if (uploadedList && uploadedList.length > 0) {
      uploadedList?.forEach((file, index) => {
        list.push(
          <UploadFile key={file.id} index={index + 1} file={file} handleDeleteFile={() => handleDeleteFile(file.id)} />,
        );
      });
    }

    if (list.length === 0) return <div style={{ fontSize: 12, fontWeight: 'bold' }}>Nenhum Arquivo Anexado</div>;

    return list;
  }, [uploadedList, isLoading]);

  return (
    <>
      <SectionDivider
        label="Anexos"
        secondItem={
          <InputFile onChange={handleChangeFile}>
            <Button bgColor="blue" title="Adicionar" size="very-small" icon={<AddIcon fontSize="small" />} />
          </InputFile>
        }
      />
      <FileListContent>{renderFilesList}</FileListContent>
    </>
  );
};

export default React.memo(CompanyInformationFileSection);
