import styled from 'styled-components';
import { Form } from 'formik';

export const WrapperModalBiddingForm = styled.main`
  height: 88%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
`;

export const WrapperForm = styled(Form)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 25px;
`;

export const TitleForm = styled.h4`
  font-size: 0.75rem;
  font-weight: 700;
  padding-bottom: 0.75rem;
`;

export const LocalityContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 0.1%;
`;

export const ProposedContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SectionForm = styled.section``;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
