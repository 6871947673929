import { FC, memo, useCallback, useState } from 'react';
import { Button } from 'logshare-ui-kit';

import { updateInvoiceService } from 'pages/fiscal-document/services';
import { IHourUpdate, IInvoice } from 'pages/fiscal-document/models';
import { showToast } from 'components/toast/toast';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

interface GenerateInvoiceProps {
  invoice: IInvoice;
  transactionIds: Array<number>;
  hoursUpdate?: IHourUpdate;
  onClose: () => void;
  onRefresh: (id: number) => void;
}

const GenerateInvoice: FC<GenerateInvoiceProps> = ({ onClose, onRefresh, invoice, transactionIds, hoursUpdate }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generateInvoice = useCallback(async () => {
    try {
      if (!transactionIds.length) throw new Error('Selecione ao menos um frete antes de continuar!');

      setIsLoading(true);

      const res = await updateInvoiceService(invoice.id, {
        issuanceDate: hoursUpdate?.createdAt ?? '',
        dueDate: hoursUpdate?.dueDate ?? '',
        paymentDay: hoursUpdate?.paymentDay ?? '',
        addition: Number(hoursUpdate?.addition) ?? 0,
        discount: Number(hoursUpdate?.discount) ?? 0,
        description: hoursUpdate?.description ?? '',
        branchId: invoice?.branchId ?? 0,
        calcFinanceIds: transactionIds,
      });

      showToast({
        type: 'success',
        message: 'Fatura reemitida com sucesso',
      });

      onRefresh(res.data.id);

      onClose();
    } catch (error: any) {
      showToast({
        type: 'error',
        message: error?.message ?? JSON.stringify(error),
      });
    } finally {
      setIsLoading(false);
    }
  }, [invoice, transactionIds, hoursUpdate]);

  return (
    <ModalConfirmation
      description={<p>Essa ação ira cancelar a fatura anterior e emitir uma nova, deseja prosseguir?</p>}
      type="normal"
      size="medium"
      title="Reemitir Fatura"
      handleCancel={onClose}
      legacy
      FooterChildren={
        <Button
          type="button"
          label="Confirmar"
          variant="solid"
          size="md"
          color="blue"
          onPress={generateInvoice}
          isLoading={isLoading}
        />
      }
    />
  );
};

export default memo(GenerateInvoice);
