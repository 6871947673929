import React, { useCallback, useContext, useMemo } from 'react';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';
import { RiskDetailsSectionView } from './risk-management-section-view.component';

interface IRiskDetailsSectionProps {
  driverId?: number;
}

export const RiskDetailsSection: React.FC<IRiskDetailsSectionProps> = ({ driverId }) => {
  const { riskManagementSection } = useContext(RegistrationsContext);
  const { isOpenModal, setModalOpen, setModalClose } = riskManagementSection.modalProps;

  const handleOpenModal = useCallback(() => {
    setModalOpen();
  }, [setModalOpen]);

  const handleCloseModal = useCallback(() => setModalClose(), [setModalClose]);

  const isOpenCreateModal = useMemo(() => isOpenModal, [isOpenModal]);

  return (
    <RiskDetailsSectionView
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      isOpenModal={isOpenCreateModal}
      driverId={driverId}
    />
  );
};
