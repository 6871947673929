import styled, { css } from 'styled-components';

type RootWrapperProps = { resumeMap?: boolean };
export const RootWrapper = styled.div<RootWrapperProps>`
  ${({ theme, resumeMap }) => css`
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    max-height: 65vh;
    max-width: 450px;
    overflow-y: auto;

    ${resumeMap
      ? css`
          padding: 15px;
          position: absolute;
          right: 60px;
          top: 10px;
          z-index: 999;
        `
      : css`
          margin-top: 6px;
          z-index: 1;
          padding: 10px;
        `};
  `};
`;

export const Content = styled.div`
  padding-bottom: 15px;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonClosed = styled.span`
  cursor: pointer;
  font-size: 12px;
  top: -15px;
  padding-left: 15px;
  position: relative;
`;

export const TagSection = styled.div`
  height: 27px;
  display: flex;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: inline-block;
  padding-top: 3px;
  padding-bottom: 9px;
`;

export const TagText = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;

type LineSectionProps = { columns: string };
export const LineSection = styled.section<LineSectionProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: ${columns};
  `};
`;

export const StepIcon = styled.div`
  background-color: #d3d3d3;
  z-index: 1;
  color: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
`;

type ConectorProps = { size?: string };
export const Connector = styled.div<ConectorProps>`
  ${({ size }) => css`
    height: ${size}%;
    border: 0;
    width: 2px;
    margin-left: 4px;
    background-color: #d3d3d3;
  `};
`;

type TextProps = {
  blueColor?: boolean;
  fontWeight?: boolean;
  uppercase?: boolean;
  padding?: string;
  italic?: boolean;
  size?: string;
};
export const TextContent = styled.div<TextProps>`
  ${({ uppercase, italic }) => css`
    text-transform: ${uppercase && 'uppercase'};
    font-style: ${italic && 'italic'};
  `}
`;

export const Text = styled.div<TextProps>`
  ${({ theme, blueColor, fontWeight, italic, uppercase = false, padding = '0', size = '12px' }) => css`
    padding: ${padding};
    color: ${blueColor ? theme.colorsV2.blue : theme.colorsV2.black};
    font-weight: ${fontWeight ? '700' : '0'};
    font-size: ${size};
    font-style: ${italic && 'italic'};
    text-transform: ${uppercase ? 'uppercase' : ''};
  `};
`;

export const HeaderButtons = styled.div``;
export const StepIconSection = styled.div``;
