import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import jwt from 'jwt-decode';

import { useMyAccountMicrosoftDetailsHook } from 'pages/my-account/hooks/use-my-account-details/use-my-account-details.hook';
import { IUser } from 'domain/login';

interface IUserContext {
  user: IUser | undefined;
  loading: boolean;
  isAdmin: boolean;
  isShippingCompany: boolean;
  isZMaster: boolean;
  setUser: () => void;
  clearUser: () => void;
  setLoading: (value: boolean) => void;
}

const initialValue: IUserContext = {
  user: undefined,
  loading: false,
  isAdmin: false,
  isShippingCompany: false,
  isZMaster: false,
  setUser: () => undefined,
  clearUser: () => undefined,
  setLoading: () => undefined,
};

export const UserContext = createContext<IUserContext>(initialValue);

interface UserContextProviderProps {
  children: React.ReactNode;
}
const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [state, setState] = useState<IUser>();
  const [isLoading, toggleLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isShippingCompany, setIsShippingCompany] = useState(false);
  const [isZMaster, setIsZMaster] = useState(false);
  const myAccountMicrosoftDetailsHook = useMyAccountMicrosoftDetailsHook();

  const decodeToken = async (): Promise<IUser | undefined> => {
    const token = sessionStorage.getItem('token');
    const email = sessionStorage.getItem('microsoftEmail');
    if (token) {
      const tokenDecoded = jwt(token) as any;
      if (tokenDecoded.client_id && email) {
        const user = await myAccountMicrosoftDetailsHook(email);
        return user;
      }
      return tokenDecoded;
    }
  };

  const setUser = useCallback(async () => {
    const tokenDecoded = await decodeToken();
    if (tokenDecoded) {
      const { name, profile } = tokenDecoded;
      setIsAdmin(profile.toUpperCase() === 'LOGSHARE');
      setIsShippingCompany(profile.toUpperCase() === 'SHIPPING-COMPANY');
      setIsZMaster(name.toUpperCase() === 'CONSULTOR LOGSHARE');
      setState(tokenDecoded);
    }
  }, []);

  const clearUser = useCallback(() => {
    setState(undefined);
    setIsAdmin(false);
  }, []);

  const setLoading = useCallback((value = false) => {
    toggleLoading(value);
  }, []);

  useEffect(() => {
    setUser();
  }, [setUser]);

  const value = useMemo(() => {
    return {
      user: state,
      setUser,
      clearUser,
      setLoading,
      loading: isLoading,
      isAdmin,
      isShippingCompany,
      isZMaster,
    };
  }, [state, setUser, clearUser, setLoading, isLoading, isAdmin, isShippingCompany, isZMaster]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default React.memo(UserContextProvider);
