import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  flex: 1;
  z-index: 9999;
`;

export const Container = styled.div`
  height: 415px;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  border-radius: 8px;
`;

export const ContainerCloseButton = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
`;

export const CloseButton = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
`;

export const ContainerBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding: 10px;
`;

export const ContainerLeftModal = styled.div`
  height: 93%;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding: 12px;
`;

export const ContainerLogShareText = styled.div`
  height: 70px;
  width: 244px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: -35px;
  margin-left: -40px;
  background-color: #1f2b4f;
  border-radius: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const LogShareText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  size: 12px;
`;

export const ProText = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #ffb743;
  size: 12px;
  margin-left: 8px;
  margin-right: 12px;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const ContainerTextDescription = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const Text = styled.span`
  font-size: 17px;
  color: #5d5d5d;
  size: 12px;
  margin-left: 7px;
  word-wrap: break-word;

  b {
    color: #1f2b4f;
  }
`;

export const ContainerTextList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 55px;
  margin-top: 20px;
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  margin: 8px;
`;

export const ContainerRightModal = styled.div`
  height: 94%;
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  margin-top: 17px;
  padding: 12px;
`;

export const ContainerInput = styled.div`
  height: 70%;
  width: 300px;
  margin: auto;
  margin-top: 20px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
`;
