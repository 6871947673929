import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback } from 'react';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import { ScheduleDetailsCommentsModalFormView } from './schedule-details-comments-modal-form-view.component';

export interface IScheduleDetailsCommentsModalFormProps {
  handleCloseModalComments: () => void;
}

export const ScheduleDetailsCommentsModalForm: FC<IScheduleDetailsCommentsModalFormProps> = ({
  handleCloseModalComments,
}) => {
  const { setFieldValue, values, handleSubmit } = useFormikContext<IUpdateScheduleCargoPayload>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleOnSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <ScheduleDetailsCommentsModalFormView
      values={values}
      handleChange={handleChange}
      handleOnSubmit={handleOnSubmit}
      handleCloseModalComments={handleCloseModalComments}
    />
  );
};
