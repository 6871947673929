import * as Yup from 'yup';

const freightDataSchema = Yup.object().shape({
  equipmentVoucher: Yup.string().required('Vale Equipamento é obrigatório'),
});

const palletDataSchema = Yup.object().shape({
  palletType: Yup.string().required('Tipo de palete é obrigatório'),
  palletQuantity: Yup.number().required('Quantidade de palete é obrigatório'),
  equipmentVoucherDate: Yup.string().required('Data do Vale Palete é obrigatório'),
  withdrawalDate: Yup.string().required('Data de Retirada é obrigatória'),
  entryDate: Yup.string().required('Data de Lançamento é obrigatória'),
});

export const RegisterPalletFormValidation = Yup.object().shape({
  freightData: freightDataSchema,
  palletData: palletDataSchema,
});
