import { CardResumeValues } from './CardResumeValues';
import { CardResumeRoot } from './CardResumeRoot';
import { CardResumeOperation } from './CardResumeOperation';
import { CardResumeHeader } from './CardResumeHeader';
import { CardResumeDelivery } from './CardResumeDelivery';
import { CardResumeCollection } from './CardResumeCollection';

export const ScheduleCardResume = {
  Root: CardResumeRoot,
  Header: CardResumeHeader,
  Collection: CardResumeCollection,
  Delivery: CardResumeDelivery,
  Operation: CardResumeOperation,
  Values: CardResumeValues,
};
