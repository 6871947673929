import API from 'utils/API/API';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { ILocalityFilterResponse } from './get-locality-filter-ids.types';
import { convertLocalityFilterResponseToSelectItem } from './mappers/convert-locality-filter-response-to-select-item/convert-locality-filter-response-to-select-item.mapper';

export const getLocalityFilterIdsService = async (
  localityIds: Array<number>,
  search?: string,
): Promise<Array<ISelectItemDomain>> => {
  const { data } = await API.get<Array<ILocalityFilterResponse>>('v2/localities/filter', {
    params: { localityIds, search },
  });

  return data.map(convertLocalityFilterResponseToSelectItem);
};
