import { Formik } from 'formik';
import { FC, Fragment, useCallback, useContext, useMemo } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { UseUpdateScheduleDetails } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-details/use-update-schedule-details.hook';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import { ScheduleDetailsCargoModalForm } from './components/schedule-details-cargo-modal-form/schedule-details-cargo-modal-form.component';
import { ScheduleDetailsCargoView } from './schedule-details-cargo.component-view';
import { useUpdateScheduleCustomFields } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-custom-fields/use-update-schedule-custom-fields.hook';
import { useCreateScheduleCustomFields } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-create-schedule-custom-fields/use-create-schedule-custom-fields.hook';
import { useGetScheduleCustomFields } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-schedule-custom-fields/use-get-schedule-custom-fields.hook';

export const ScheduleDetailsCargo: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const updateScheduleDetailsHook = UseUpdateScheduleDetails();
  const getScheduleCustomFieldsHook = useGetScheduleCustomFields();
  const updateScheduleCustomFieldsHook = useUpdateScheduleCustomFields();
  const createScheduleCustomFieldsHook = useCreateScheduleCustomFields();

  const handleOpenModalCargo = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationOpen('cargo_details');
  }, [scheduleDetails]);

  const handleCloseModalCargo = useCallback(() => {
    scheduleDetails.setScheduleDetailsAllocationClose();
  }, [scheduleDetails]);

  const handleSubmit = useCallback(
    async (values: IUpdateScheduleCargoPayload) => {
      await updateScheduleDetailsHook(values);

      if (values.customFields && values.customFields.length > 0) {
        if (values.customFields[0].scheduleId && values.customFields[0].scheduleId !== 0) {
          await updateScheduleCustomFieldsHook(values.customFields);
        } else {
          await createScheduleCustomFieldsHook(values.customFields);
        }
        await getScheduleCustomFieldsHook();
      }
      handleCloseModalCargo();
    },
    [
      createScheduleCustomFieldsHook,
      getScheduleCustomFieldsHook,
      handleCloseModalCargo,
      updateScheduleCustomFieldsHook,
      updateScheduleDetailsHook,
    ],
  );

  const renderModalCargo = useMemo(() => {
    if (
      scheduleDetails.allocationActions.allocatedEntity === 'cargo_details' &&
      scheduleDetails.allocationActions.isOpen
    ) {
      return (
        <Formik
          initialValues={{
            id: scheduleDetails.scheduleDetails.id,
            comments: scheduleDetails.scheduleDetails.cargo.comments,
            cargo: scheduleDetails.scheduleDetails.cargo,
            detail: scheduleDetails.scheduleDetails.detail,
            customFields: scheduleDetails.scheduleCustomFields.scheduleCustomFields,
          }}
          onSubmit={handleSubmit}
        >
          <ScheduleDetailsCargoModalForm handleCloseModalCargo={handleCloseModalCargo} />
        </Formik>
      );
    }

    return;
  }, [
    handleCloseModalCargo,
    handleSubmit,
    scheduleDetails.allocationActions.allocatedEntity,
    scheduleDetails.allocationActions.isOpen,
    scheduleDetails.scheduleCustomFields.scheduleCustomFields,
    scheduleDetails.scheduleDetails.cargo,
    scheduleDetails.scheduleDetails.detail,
    scheduleDetails.scheduleDetails.id,
  ]);

  return (
    <Fragment>
      {renderModalCargo}
      <ScheduleDetailsCargoView
        cargo={scheduleDetails.scheduleDetails.cargo}
        detail={scheduleDetails.scheduleDetails.detail}
        customFields={scheduleDetails.scheduleCustomFields.scheduleCustomFields}
        handleOpenModalCargo={handleOpenModalCargo}
      />
    </Fragment>
  );
};
