import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AttachDocumentsTableView } from './attach-documents-table-view.component';
import { RegistrationsContext } from 'src-new/pages/registrations/contexts/registrations/registrations.context';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { IAttachmentDomain } from 'src-new/pages/registrations/domains/document.domain';
import { isEqual } from 'lodash';
import { RegistrationAttachmentValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IAttachDocumentsTableProps {
  arrayHelpers: FieldArrayRenderProps;
}

export const AttachDocumentsTable: React.FC<IAttachDocumentsTableProps> = ({ arrayHelpers }) => {
  const [tableList, setTableList] = useState<Array<IAttachmentDomain>>([]);
  const { getFieldProps } = useFormikContext<RegistrationAttachmentValues>();
  const { attachDocumentsSection } = useContext(RegistrationsContext);
  const { isOpenModal } = attachDocumentsSection;
  const { remove } = arrayHelpers;

  const documents = useMemo((): Array<IAttachmentDomain> => getFieldProps('attachments').value ?? [], [getFieldProps]);

  const isEqualContactList = useMemo((): boolean => isEqual(documents, tableList), [documents, tableList]);

  const removeItem = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const viewItem = useCallback((file?: File | string) => {
    if (file) {
      const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file);

      window.open(fileUrl, '_blank');
    }
  }, []);

  useEffect(() => {
    if (!isOpenModal && !isEqualContactList) {
      setTableList(documents);
    }
  }, [documents, isEqualContactList, isOpenModal]);

  return <AttachDocumentsTableView documents={tableList} removeItem={removeItem} viewItem={viewItem} />;
};
