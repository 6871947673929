import { Formik } from 'formik';
import { FC, useCallback, useContext } from 'react';
import { WeighingDuplicatedFormView } from './weighing-duplicated-form-view.component';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { IWeighingDomain } from '../../../weighing-update/domains/weighing-details.domain';
import { useDuplicatedRegisterWeighing } from '../../hooks/use-duplicated-weighing-register/use-duplicated-weighing-register.hook';

export const WeighingDuplicatedForm: FC = () => {
  const { weighingDetails } = useContext(WeighingContext);

  const duplicatedRegisterWeighingHook = useDuplicatedRegisterWeighing();

  const handleSubmit = useCallback(
    async (values: IWeighingDomain) => {
      duplicatedRegisterWeighingHook(values);
    },
    [duplicatedRegisterWeighingHook],
  );

  return (
    <Formik initialValues={weighingDetails.weighingDetails} onSubmit={handleSubmit}>
      <WeighingDuplicatedFormView />
    </Formik>
  );
};
