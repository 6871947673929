import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    justify-content: right;
    align-items: right;
    top: 0;
    left: 0;
    background-color: ${theme.modalsV2.background};
    color: #000;
    z-index: 1050;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    width: 400px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: ${theme.colorsV2.white};
    border-radius: ${theme.modalsV2.radius};
    padding: ${theme.modalsV2.padding};
    overflow-y: auto;
  `}
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    font-weight: ${theme.font.bold};
    margin-right: 10px;
  `}
`;

export const CloseModal = styled.div`
  ${({ theme }) => css`
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${theme.colorsV2.forBorder};
    font-size: 15px;
    cursor: pointer;
  `}
`;

export const ContainerContent = styled.div`
  padding-top: 35px;
`;

export const ContainerFooter = styled.div`
  ${({ theme }) => css`
    height: 60px;
    width: 395px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 5px;
    padding: 0 ${theme.modalsV2.padding};
    background-color: ${theme.colorsV2.white};
    border-top: 1px solid ${theme.colorsV2.forBorder};
    border-radius: 0 0 ${theme.modalsV2.radius} ${theme.modalsV2.radius};
  `}
`;

export const FilterText = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

interface IResetFilterButtonProps {
  disabled: boolean;
}
export const ResetFilterButton = styled.button<IResetFilterButtonProps>`
  ${({ theme, disabled }) => css`
    background-color: ${theme.colorsV2.transparent};
    border: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.fontV2.family};
    color: ${disabled ? theme.colorsV2.lightGray : '#2b6ff6'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;
