import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Text = styled.p``;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
