import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    background-image: url('src/assets/bg-totten.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: ${theme.colorsV4.logshareBlue};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorsV4.white};
    font-size: 1.8rem;
    margin: 20vh 0;
  `}
`;

export const WrapperModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  ${() => css`
    display: block;
    position: absolute;
    bottom: 6px;

    img {
      width: 80%;
    }
  `}
`;
