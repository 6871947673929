import React, { MouseEvent, useCallback, useState } from 'react';
import { DropdownButtonTableView } from './dropdown-button-table-view.component';
import { IOptions } from './dropdown-button-table.types';

interface IDropdownButtonProps {
  options: Array<IOptions>;
  iconPosition: 'vertical' | 'horizontal';
}

export const DropdownButtonTable: React.FC<IDropdownButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleToggleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        setAnchorEl(null);
        return;
      }
      const target = event.target as HTMLElement;

      if (target.tagName === 'svg' || target.tagName === 'BUTTON') setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );

  const handleClick = useCallback((option: IOptions) => {
    if (option.disabled) return;

    setAnchorEl(null);

    option.onClick();
  }, []);

  return (
    <DropdownButtonTableView
      {...props}
      isOpen={open}
      anchorEl={anchorEl}
      iconPosition={props.iconPosition}
      handleToggleClick={handleToggleClick}
      handleClick={handleClick}
    />
  );
};
