import { TransactionDetails } from 'domain-v2/transactions/details';

export const calculateMargin = (
  transactionPayable: TransactionDetails,
  transactionReceive: TransactionDetails,
  transactionPayableShipping: TransactionDetails,
) => {
  let calculatedMargin = 0;

  if (transactionReceive.totalService > 0) {
    calculatedMargin =
      (1 -
        transactionReceive.totalService /
          (transactionPayable.totalFreight -
            (transactionPayable.totalFreight + transactionPayable.otherTaxes) * 0.0798 +
            transactionPayable.otherTaxes)) *
      100;
  }

  if (transactionPayableShipping.totalService > 0) {
    calculatedMargin =
      (1 -
        transactionPayableShipping.totalService /
          (transactionPayable.totalFreight -
            (transactionPayable.totalFreight + transactionPayable.otherTaxes) * 0.0798 +
            transactionPayable.otherTaxes)) *
      100;
  }

  if (transactionReceive.totalService > 0 && transactionPayableShipping.totalService > 0) {
    calculatedMargin =
      (1 -
        (transactionPayableShipping.totalService + transactionReceive.totalService) /
          (transactionPayable.totalFreight -
            (transactionPayable.totalFreight + transactionPayable.otherTaxes) * 0.0798 +
            transactionPayable.otherTaxes)) *
      100;
  }

  return calculatedMargin;
};
