import { useNavigate } from 'react-router-dom';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import { ISelectOperation } from './types/select-operation';
import { IScheduleLTLFormProps } from './types/form';
import * as S from './styled';
import { RenderForms } from './render';
import { scheduleLTLInitialValues } from './initial-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { createScheduleLTLService } from 'pages-v2/schedule-ltl/services/create-schedule';
import { convertResumeCreate } from 'pages-v2/schedule-ltl/mappers/convert-resume/register';
import { useValidationFields } from 'pages-v2/schedule-ltl/context/validation-fields';
import Card from 'pages-v2/schedule-ltl/components/freight-cards';
import { ScheduleCardResume } from 'pages-v2/schedule-ltl/components/card-resume';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';
import { SchedulePriceLTL, initialSchedulePriceLTLValues } from 'domain-v2/schedule-ltl/price';
import { Operation } from 'domain-v2/inputs/operation';
import { ModalityType } from 'domain-v2/inputs/modality-type';
import { FooterPage } from 'components-v2/common/footer-page';
import Accordion from 'components-v2/common/accordion';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import { Airplane } from 'assets/icons/airplane.icon';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const initialSelectOperationState = {} as ISelectOperation;

const ScheduleRegisterLTLForm: React.FC<IScheduleLTLFormProps> = ({ company, user }) => {
  const { isHovered, currentSelectedMenu } = useContext(SidebarContext);
  const { isFilled, handleValidateField } = useValidationFields();

  const [selectedOperation, setSelectedOperation] = useState<ISelectOperation>({
    ...initialSelectOperationState,
    operation: 'SPOT',
    modalityType: 'recommended',
    operationBy: '',
  });

  const [priceScheduleLTL, setPriceScheduleLTL] = useState<SchedulePriceLTL>(initialSchedulePriceLTLValues);

  const [sidebarSize, setSidebarSize] = useState<number>(0);

  const [loadingFreightValues, setLoadingFreightValues] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const menuSize = isHovered ? 282 : 0;

    const submenuSize = currentSelectedMenu ? 185 : 0;

    setSidebarSize(menuSize + submenuSize);
  }, [isHovered, currentSelectedMenu]);

  const handleFormSubmit = useCallback(
    async (values: ScheduleLTLRegisterInput) => {
      handleValidateField();
      if (!(isFilled.originValues && isFilled.destinyValues && isFilled.detailValues)) {
        showMessageFormatted({
          message: 'Preencha todos os campos obrigatórios.',
          type: 'error',
        });
        return;
      }

      try {
        await createScheduleLTLService({ ...values });

        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });

        navigate(-1);
      } catch (error: any) {
        showMessageFormatted({
          error: error,
          type: 'error',
        });
      }
    },
    [isFilled],
  );

  const handleChangeOperation = useCallback(
    (
      operation: Operation,
      operationBy: string,
      modalityType: ModalityType,
      setFieldValue: (field: string, value: any) => Promise<any>,
    ) => {
      setFieldValue('operation', operation);
      setFieldValue('freightModality', modalityType);

      setSelectedOperation({
        operation,
        modalityType,
        operationBy,
      });
    },
    [setSelectedOperation],
  );

  return (
    <Formik
      initialValues={scheduleLTLInitialValues}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <>
            <S.WrapperForm>
              <Accordion
                contents={RenderForms({
                  company,
                  isFilled,
                  freightModality: selectedOperation.modalityType,
                  priceScheduleLTL,
                  setPriceScheduleLTL,
                  setLoadingFreightValues,
                  handleChangeOperation,
                })}
              />
              <ScheduleCardResume.Root>
                <ScheduleCardResume.Header
                  scheduleType={'ltl'}
                  operationType={values.operation}
                  user={user}
                  values={values}
                  hideQuotation={isFilled.originValues && isFilled.destinyValues && isFilled.detailValues}
                />
                <ScheduleCardResume.Collection
                  origin={convertResumeCreate(values).originValues}
                  scheduleDetailsValues={convertResumeCreate(values).scheduleDetailsValuesOrigin}
                />
                <ScheduleCardResume.Delivery
                  destinyValues={convertResumeCreate(values).destinationValues}
                  scheduleDetailsValues={convertResumeCreate(values).scheduleDetailsValuesDestination}
                />
                <ScheduleCardResume.Operation
                  cargoDetailsValues={convertResumeCreate(values).scheduleCargoValues}
                  operationBy={selectedOperation.operationBy}
                />
                <ScheduleCardResume.Values user={user} valuesPayable={convertResumeCreate(values).scheduleCostValues} />
              </ScheduleCardResume.Root>
            </S.WrapperForm>

            <S.WrapperFooterPage sidebarIsHovered={sidebarSize}>
              <FooterPage.Root>
                <FooterPage.RightContent>
                  <FooterPage.Button label="Agendar" color="pink" onPress={handleSubmit} />
                  <FooterPage.Button label="Voltar" color="white" variant="ghost" onPress={() => navigate(-1)} />
                </FooterPage.RightContent>
                <FooterPage.LeftContent>
                  <Card
                    cards={[
                      {
                        id: 1,
                        name: 'Recomendado',
                        tariffValue: priceScheduleLTL.recommended.value
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(priceScheduleLTL.recommended.value)
                          : 'Sem tarifa',
                        dateOf: !priceScheduleLTL.recommended.date
                          ? '-'
                          : dayjs(priceScheduleLTL.recommended.date).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation('SPOT', 'Logshare', 'recommended', setFieldValue);
                        },
                        disabled: !priceScheduleLTL.recommended.value,
                        checked: selectedOperation.modalityType === 'recommended',
                        isLoading: loadingFreightValues,
                      },
                      {
                        id: 2,
                        name: 'Otimizado',
                        tariffValue: priceScheduleLTL.optimized.value
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(priceScheduleLTL.optimized.value)
                          : 'Sem tarifa',
                        dateOf: !priceScheduleLTL.optimized.date
                          ? '-'
                          : dayjs(priceScheduleLTL.optimized.date).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation('SPOT', 'Logshare', 'optimized', setFieldValue);
                        },
                        disabled: !priceScheduleLTL.optimized.value,
                        checked: selectedOperation.modalityType === 'optimized',
                        isLoading: loadingFreightValues,
                      },
                      {
                        id: 3,
                        name: 'Antecipado',
                        tariffValue: priceScheduleLTL.advance.value
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(priceScheduleLTL.advance.value)
                          : 'Sem tarifa',
                        dateOf: !priceScheduleLTL.advance.date
                          ? '-'
                          : dayjs(priceScheduleLTL.advance.date).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation('SPOT', 'Logshare', 'advance', setFieldValue);
                        },
                        disabled: !priceScheduleLTL.advance.value,
                        checked: selectedOperation.modalityType === 'advance',
                        isLoading: loadingFreightValues,
                      },
                      {
                        id: 4,
                        name: 'Aéreo',
                        tariffValue: priceScheduleLTL.air.value
                          ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(priceScheduleLTL.air.value)
                          : 'Sem tarifa',
                        dateOf: !priceScheduleLTL.air.date
                          ? '-'
                          : dayjs(priceScheduleLTL.air.date).format('DD/MM/YYYY'),
                        onClickAction: () => {
                          handleChangeOperation('SPOT', 'Logshare', 'air', setFieldValue);
                        },
                        disabled:
                          !priceScheduleLTL.air.value ||
                          dayjs(priceScheduleLTL.advance.date).isBefore(dayjs().add(1, 'day')),
                        checked: selectedOperation.modalityType === 'air',
                        isLoading: loadingFreightValues,
                        icon: <Airplane />,
                      },
                    ]}
                  />
                </FooterPage.LeftContent>
              </FooterPage.Root>
            </S.WrapperFooterPage>
          </>
        );
      }}
    </Formik>
  );
};

export default memo(ScheduleRegisterLTLForm);
