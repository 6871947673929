import React, { ChangeEvent, FC, useCallback } from 'react';
import { FilterFormView } from './filter-form-view.component';
import { useFormikContext } from 'formik';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';

export const FilterForm: FC = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<ISaleOfferListFilterDomain>();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onSubmitForm = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <FilterFormView
      values={values}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      onSubmitForm={onSubmitForm}
    />
  );
};
