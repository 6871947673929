import { IOccurrenceResolution } from 'domain/tracking-occurrence';

export const PATH_TO_TRACKING_OCCURRENCE_REGISTER = '/tracking-ocorrencias/novo';

export const initialValues: IOccurrenceResolution = {
  resolutionDate: String(new Date()),
  description: '',
  userResolutionNameWith: '',
  userResolutionNameBy: '',
};
