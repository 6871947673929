import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import TrackingRegisterFormView from './tracking-occurrence-register-form-view';
import { TrackingOccurrenceRegisterSchema } from './schemas-validation/schemas-validation';
import { PATH_TO_TRACKING_OCCURRENCE_LIST, initialValues } from './form-values/form-values';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ErrorMessage } from 'utils/exception';
import { useTrackingOccurrenceHook } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-register/use-tracking-occurrence-register.hook';
import { useGetFreightByIdHook } from 'pages/freight/hooks/use-allocation-by-id/use-allocation-by-id.hook';
import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { ITrackingOccurrence } from 'domain/tracking-occurrence';
import { showToast } from 'components/toast/toast';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const TrackingRegisterForm: React.FC = () => {
  const { allocation } = useContext(AllocationRegisterContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { scheduleId } = useParams();

  const getCurrentFreightHook = useGetFreightByIdHook();
  const trackingOccurrenceRegisterHook = useTrackingOccurrenceHook();

  const goBackToTrackingOccurrenceList = useCallback(() => navigate(PATH_TO_TRACKING_OCCURRENCE_LIST), [navigate]);

  const handleAllocation = useCallback(async () => {
    if (scheduleId && Number(scheduleId)) {
      await getCurrentFreightHook(scheduleId);
    }
  }, [getCurrentFreightHook, scheduleId]);

  useEffect(() => {
    handleAllocation();
  }, [handleAllocation]);

  const handleSubmit = useCallback(
    async (trackingOccurrence: ITrackingOccurrence) => {
      setIsLoading(true);
      trackingOccurrenceRegisterHook(trackingOccurrence)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToTrackingOccurrenceList();
        })
        .catch((error) => {
          const messageError = ErrorMessage(error);
          showToast({
            message: messageError.message ? `${messageError.message}` : `${messageError}`,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [trackingOccurrenceRegisterHook, goBackToTrackingOccurrenceList],
  );

  return (
    <TrackingRegisterFormView
      currentAllocation={allocation}
      trackingOccurrenceRegisterValues={{
        ...initialValues,
        occurrenceResolutions: [],
      }}
      schemaValidation={TrackingOccurrenceRegisterSchema}
      isLoading={isLoading}
      handleGoBack={goBackToTrackingOccurrenceList}
      handleRegister={handleSubmit}
    />
  );
};

export default TrackingRegisterForm;
