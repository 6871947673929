/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import dayjs from 'dayjs'

import {
  ContainerDestinyStyled,
  ContainerInvoicingStyled,
  ContainerOfferedCapacity,
  ContainerTariffAndInvoicingStyled,
  ContainerTariffStyled,
  FormStyled,
  LineSectionStyled,
  SectionStyled,
  WrapperDateTimePikers,
} from './hire-backhaul-register-form.styled';

import { calcPriceSuggestion } from 'utils/calcPriceSuggestion/calc-price-suggestion';
import { localitiesFilterSearchService } from 'pages/schedule/services/locality-search/locality-filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useGetFreightCalculationHook } from 'pages/schedule/hooks/use-get-freight-calculation/use-get-freight-calculation.hook';
import { optionsTypeSchedule } from 'pages/offer-backhaul/backhaul-register/backhaul-register-form/form-values/form-values';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { clientDetailsService } from 'pages/client/services/client.service';
import { clientsFilterSearchService } from 'pages/backhaul/services/clients-search/clients-filter-search.service';
import { IHireBackhaul, ITransportedCargo, IYesNo } from 'domain/hire-backhaul';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import { FooterPage } from 'components-v2/common/footer-page';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import MultiSelect from 'components/multi-select/multi-select';
import InputFieldCnpj from 'components/input-field-cnpj/input-field-cnpj';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import Balloon from 'components/balloon/balloon';
import AsyncSelectField from 'components/async-select/async-select';

interface BackhaulRegisterFormViewProps {
  hireBackhaulRegisterValues: IHireBackhaul;
  optionsTransportedCargo: Array<{ label: string; value: ITransportedCargo }>;
  optionsTime: Array<{ label: string; value: string }>;
  optionsYesOrNo: Array<{ label: string; value: IYesNo }>;
  optionClientType: Array<{ label: string; value: string }>;
  handleGoBack: () => void;
  handleRegister: (values: IHireBackhaul) => void;
  schemaValidation: object;
  isLoading: boolean;
}

const BackhaulRegisterFormView: React.FC<BackhaulRegisterFormViewProps> = ({
  hireBackhaulRegisterValues,
  optionsTransportedCargo,
  optionsTime,
  optionsYesOrNo,
  optionClientType,
  handleGoBack,
  handleRegister,
  schemaValidation,
  isLoading,
}) => {
  const clientApiCall = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await clientsFilterSearchService(searchValue);
    else return [];
  };
  const localityApiCall = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await localitiesFilterSearchService(searchValue);
    else return [];
  };

  const InnerForm = (props: FormikProps<IHireBackhaul>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;

    const getFreightCalculationHook = useGetFreightCalculationHook();
    const [scheduleTypeError, setScheduleTypeError] = useState('');
    const [showPriceSuggestion, setShowPriceSuggestion] = useState(false);
    const [valueSuggestionPrice, setSuggestionValuePrice] = useState('');
 
    const handleCompleteInputsOrigin = (id: number, clientType: string) => {
      if (clientType === 'PARCEIRO' && id) {
        clientDetailsService(String(id)).then((client) => {
          setFieldValue('origin.cnpj', client.cnpj);
          setFieldValue('origin.zipCode', client.address.cep);
          setFieldValue('origin.city', client.address.city);
          setFieldValue('origin.state', client.address.uf);
          setFieldValue('origin.complement', client.address.complement);
          setFieldValue('origin.neighborhood', client.address.neighborhood);
          setFieldValue('origin.number', client.address.numberHouse);
          setFieldValue('origin.street', client.address.street);
          setFieldValue('origin.fantasyName', client.fantasyName);
        });
      } else if (clientType === 'LOCALIDADE' && id) {
        locationDetailsService(String(id)).then((locality) => {
          setFieldValue('origin.cnpj', locality.cnpj);
          setFieldValue('origin.zipCode', locality.address.cep);
          setFieldValue('origin.city', locality.address.city);
          setFieldValue('origin.state', locality.address.uf);
          setFieldValue('origin.complement', locality.address.complement);
          setFieldValue('origin.neighborhood', locality.address.neighborhood);
          setFieldValue('origin.number', locality.address.numberHouse);
          setFieldValue('origin.street', locality.address.street);
          setFieldValue('origin.fantasyName', locality.name);
        });
      }
    };

    const handleCompleteInputsDestiny = (id: number, clientType: string) => {
      if (clientType === 'PARCEIRO' && id) {
        clientDetailsService(String(id)).then((client) => {
          setFieldValue('destiny.cnpj', client.cnpj);
          setFieldValue('destiny.zipCode', client.address.cep);
          setFieldValue('destiny.city', client.address.city);
          setFieldValue('destiny.state', client.address.uf);
          setFieldValue('destiny.complement', client.address.complement);
          setFieldValue('destiny.neighborhood', client.address.neighborhood);
          setFieldValue('destiny.number', client.address.numberHouse);
          setFieldValue('destiny.street', client.address.street);
          setFieldValue('destiny.name', client.fantasyName);
        });
      } else if (clientType === 'LOCALIDADE' && id) {
        locationDetailsService(String(id)).then((locality) => {
          setFieldValue('destiny.cnpj', locality.cnpj);
          setFieldValue('destiny.zipCode', locality.address.cep);
          setFieldValue('destiny.city', locality.address.city);
          setFieldValue('destiny.state', locality.address.uf);
          setFieldValue('destiny.complement', locality.address.complement);
          setFieldValue('destiny.neighborhood', locality.address.neighborhood);
          setFieldValue('destiny.number', locality.address.numberHouse);
          setFieldValue('destiny.street', locality.address.street);
          setFieldValue('destiny.name', locality.name);
        });
      }
    };

    const handleValidation = useCallback(() => {
      values.scheduleType ?? setScheduleTypeError('Campo Obrigatório');
      handleSubmit();
    }, [values.initialDate, values.endDate,  values.scheduleType ,handleSubmit]);

    const location = useLocation();

    useEffect(() => {
      if (location.state) {
        setFieldValue('vehicleType', location.state?.vehicle_type);
        setFieldValue('category', location.state?.vehicle_category);
        setFieldValue('freightValue', location.state?.freightValue);
        setFieldValue('offeredPallet', location.state?.offeredPallet);
        setFieldValue('offeredWeight', location.state?.offeredWeight);
        setFieldValue('transportedCargo', location.state?.transportedCargo);

        setFieldValue('originType', location.state?.origin.type);
        setFieldValue('idOrigin', location.state?.origin.id);
        handleCompleteInputsOrigin(location.state?.origin.id, location.state?.origin.type);
        setFieldValue('destinationType', location.state?.destination.type);
        setFieldValue('idDestination', location.state?.destination.id);
        handleCompleteInputsDestiny(location.state?.destination.id, location.state?.destination.type);
      }
    }, [location.state]);

    const getPriceSuggestion = async (data: IHireBackhaul) => {
      const response = await getFreightCalculationHook({
        originId: data.idOrigin || 0,
        originType: data.originType || '',
        destinyId: data.idDestination || 0,
        destinyType: data.destinationType || '',
        weight: '',
        capacity: '',
        merchValue: '',
        vehicleType: data.vehicleType,
        vehicleCategory: data.category,
        needHelper: undefined,
        pickUpDate: undefined,
      });
      if (response) {
        setShowPriceSuggestion(false);
        const resPrice = calcPriceSuggestion(
          response.totalDistance.length,
          data.vehicleType || '',
          response.internal.price.value,
        );
        setSuggestionValuePrice(resPrice.toFixed(2).toString().replace('.', ','));
      }
    };

    useEffect(() => {
      if (values.category && values.vehicleType && values.idOrigin && values.idDestination) {
        getPriceSuggestion(values);
      }
    }, [values.category, values.vehicleType, values.idOrigin, values.idDestination]);

    const originTypeFields = useMemo(() => {
      const selectFieldLabel = values.originType === 'PARCEIRO' ? 'Nome do Parceiro *' : 'Nome da Unidade *';

      const selectFieldOptions = values.originType === 'PARCEIRO' ? clientApiCall : localityApiCall;

      if (location.state?.origin.name) {
        return (
          <AsyncSelectField
            placeholder=""
            isClearable={true}
            label={selectFieldLabel}
            name="idOrigin"
            id="idOrigin"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleCompleteInputsOrigin && handleCompleteInputsOrigin((e && e.value) ?? 0, String(values.originType)),
                setFieldValue('idOrigin', e && e.value);
            }}
            hasError={!!errors.idOrigin && !!touched.idOrigin}
            errorMessage={String(errors.idOrigin)}
            apiCallFunction={selectFieldOptions}
            loadOptions={selectFieldOptions}
            defaultValue={{
              label: location.state.origin.name,
              value: values.idOrigin ?? 0,
            }}
          />
        );
      }

      if (values.originType === '') {
        return (
          <AsyncSelectField
            placeholder=""
            isClearable={true}
            label="Informar Tipo de Origem *"
            name="none"
            id="none"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleCompleteInputsOrigin && handleCompleteInputsOrigin((e && e.value) ?? 0, String(values.originType)),
                setFieldValue('none', e && e.value);
            }}
            apiCallFunction={clientApiCall}
            loadOptions={clientApiCall}
          />
        );
      }

      return (
        <AsyncSelectField
          placeholder=""
          isClearable={true}
          label={selectFieldLabel}
          name="idOrigin"
          id="idOrigin"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            handleCompleteInputsOrigin && handleCompleteInputsOrigin((e && e.value) ?? 0, String(values.originType)),
              setFieldValue('idOrigin', e && e.value);
          }}
          hasError={!!errors.idOrigin && !!touched.idOrigin}
          errorMessage={String(errors.idOrigin)}
          apiCallFunction={selectFieldOptions}
          loadOptions={selectFieldOptions}
        />
      );
    }, [values.originType, values.idOrigin, errors.idOrigin, touched.idOrigin, location.state?.origin.name]);

    const destinyTypeFields = useMemo(() => {
      const selectFieldLabel = values.destinationType === 'PARCEIRO' ? 'Nome do Parceiro *' : 'Nome da Unidade *';

      const selectFieldOptions = values.destinationType === 'PARCEIRO' ? clientApiCall : localityApiCall;

      if (location.state?.destination.name) {
        return (
          <AsyncSelectField
            placeholder=""
            isClearable={true}
            label={selectFieldLabel}
            name="idDestination"
            id="idDestination"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleCompleteInputsDestiny &&
                handleCompleteInputsDestiny((e && e.value) ?? 0, String(values.destinationType)),
                setFieldValue('idDestination', e && e.value);
            }}
            hasError={!!errors.idDestination && !!touched.idDestination}
            errorMessage={String(errors.idDestination)}
            apiCallFunction={selectFieldOptions}
            loadOptions={selectFieldOptions}
            defaultValue={{
              label: location.state.destination.name,
              value: values.idDestination ?? 0,
            }}
          />
        );
      }

      if (values.destinationType === '') {
        return (
          <AsyncSelectField
            placeholder=""
            isClearable={true}
            label="Informar Tipo de Destino *"
            name="none"
            id="none"
            cacheOptions={true}
            defaultOptions={true}
            onChange={async (e) => {
              handleCompleteInputsDestiny &&
                handleCompleteInputsDestiny((e && e.value) ?? 0, String(values.destinationType)),
                setFieldValue('none', e && e.value);
            }}
            apiCallFunction={clientApiCall}
            loadOptions={clientApiCall}
          />
        );
      }

      return (
        <AsyncSelectField
          placeholder=""
          isClearable={true}
          label={selectFieldLabel}
          name="idDestination"
          id="idDestination"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            handleCompleteInputsDestiny &&
              handleCompleteInputsDestiny((e && e.value) ?? 0, String(values.destinationType)),
              setFieldValue('idDestination', e && e.value);
          }}
          hasError={!!errors.idDestination && !!touched.idDestination}
          errorMessage={String(errors.idDestination)}
          apiCallFunction={selectFieldOptions}
          loadOptions={selectFieldOptions}
        />
      );
    }, [
      values.destinationType,
      values.idDestination,
      errors.idDestination,
      touched.idDestination,
      location.state?.destination.name,
    ]);

    return (
      <FormStyled>
        <SectionStyled name="Veículos">
          <LineSectionStyled columns="0.6fr 1.2fr 0.6fr 1.2fr 1.2fr">
            <InputField label="Rota ID *" id="id" name="id" type="text" disabled />
            <Select
              label="Carga Transportada *"
              id="transportedCargo"
              name="transportedCargo"
              value={values.transportedCargo}
              setFieldValue={setFieldValue}
              hasError={!!errors.transportedCargo && !!touched.transportedCargo}
              errorMessage={String(errors.transportedCargo)}
              options={optionsTransportedCargo}
            />
            <Select
              label="Tipo de Carga *"
              id="scheduleType"
              name="scheduleType"
              value={values.scheduleType}
              setFieldValue={setFieldValue}
              hasError={!!scheduleTypeError}
              errorMessage={scheduleTypeError}
              options={optionsTypeSchedule}
            />
            <Select
              label="Tipo de Veículo *"
              id="vehicleType"
              name="vehicleType"
              value={values.vehicleType}
              setFieldValue={setFieldValue}
              hasError={!!errors.vehicleType && !!touched.vehicleType}
              errorMessage={String(errors.vehicleType)}
              options={vehicleTypeOptions}
              isClearable
            />
            <Select
              label="Categoria do Veículo *"
              id="category"
              name="category"
              value={values.category}
              setFieldValue={setFieldValue}
              hasError={!!errors.category && !!touched.category}
              errorMessage={String(errors.category)}
              options={vehicleCategoryOptions}
            />
          </LineSectionStyled>

          <div style={{ marginTop: 15 }}>
            <LineSectionStyled columns="1fr">
              <MultiSelect
                label="Categoria do Veículo (Secundária)"
                options={vehicleCategoryOptions}
                menuPosition="fixed"
                values={vehicleCategoryOptions.filter((v) => values.secondVehicleCategory?.includes(v.value as any))}
                onChange={(e) => {
                  const vehicles = e.map((option) => option.value);
                  setFieldValue('secondVehicleCategory', vehicles);
                }}
              />
            </LineSectionStyled>
          </div>

          <ContainerDestinyStyled>
            <SectionDivider label="Origem" hidePin={false}/>
            <SectionStyled name="origem">
              <LineSectionStyled columns="1fr 4fr 1fr">
                <Select
                  label="Tipo *"
                  id="originType"
                  name="originType"
                  value={values.originType}
                  setFieldValue={setFieldValue}
                  hasError={!!errors.originType && !!touched.originType}
                  errorMessage={String(errors.originType)}
                  options={optionClientType}
                />

                {originTypeFields}

                <InputFieldCnpj
                  label="CNPJ"
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.cnpj}
                  disabled
                />
              </LineSectionStyled>

              <LineSectionStyled columns="0.9fr 2fr 2fr 0.4fr">
                <InputField
                  label="CEP"
                  id="cep"
                  name="cep"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.zipCode}
                  disabled
                />
                <InputField
                  label="Bairro"
                  id="bairro"
                  name="bairro"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.neighborhood}
                  disabled
                />
                <InputField
                  label="Cidade"
                  id="cidade"
                  name="cidade"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.city}
                  disabled
                />
                <InputField
                  label="UF"
                  id="uf"
                  name="uf"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.state}
                  disabled
                />
              </LineSectionStyled>

              <LineSectionStyled columns="5fr 1fr 3fr">
                <InputField
                  label="Endereço"
                  id="endereco"
                  name="endereco"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.street}
                  disabled
                />
                <InputField
                  label="Nº"
                  id="numero"
                  name="numero"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.number}
                  disabled
                />
                <InputField
                  label="Complemento"
                  id="complemento"
                  name="complemento"
                  type="text"
                  onChange={handleChange}
                  value={values.origin?.complement}
                  disabled
                />
              </LineSectionStyled>
            </SectionStyled>
          </ContainerDestinyStyled>

          <SectionDivider label="Destino" hidePin={false}/>
          <SectionStyled name="destino">
            <LineSectionStyled columns="1fr 4fr 1fr">
              <Select
                label="Tipo *"
                id="destinationType"
                name="destinationType"
                value={values.destinationType}
                setFieldValue={setFieldValue}
                hasError={!!errors.destinationType && !!touched.destinationType}
                errorMessage={String(errors.destinationType)}
                options={optionClientType}
              />

              {destinyTypeFields}

              <InputFieldCnpj
                label="CNPJ"
                id="cnpj"
                name="cnpj"
                type="text"
                onChange={handleChange}
                value={values.destiny?.cnpj}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="0.9fr 2fr 2fr 0.4fr">
              <InputField
                label="CEP"
                id="cep"
                name="cep"
                type="text"
                onChange={handleChange}
                value={values.destiny?.zipCode}
                disabled
              />
              <InputField
                label="Bairro"
                id="bairro"
                name="bairro"
                type="text"
                onChange={handleChange}
                value={values.destiny?.neighborhood}
                disabled
              />
              <InputField
                label="Cidade"
                id="cidade"
                name="cidade"
                type="text"
                onChange={handleChange}
                value={values.destiny?.city}
                disabled
              />
              <InputField
                label="UF"
                id="uf"
                name="uf"
                type="text"
                onChange={handleChange}
                value={values.destiny?.state}
                disabled
              />
            </LineSectionStyled>

            <LineSectionStyled columns="5fr 1fr 3fr">
              <InputField
                label="Endereço"
                id="endereco"
                name="endereco"
                type="text"
                onChange={handleChange}
                value={values.destiny?.street}
                disabled
              />
              <InputField
                label="Nº"
                id="numero"
                name="numero"
                type="text"
                onChange={handleChange}
                value={values.destiny?.number}
                disabled
              />
              <InputField
                label="Complemento"
                id="complemento"
                name="complemento"
                type="text"
                onChange={handleChange}
                value={values.destiny?.complement}
                disabled
              />
            </LineSectionStyled>
          </SectionStyled>

          <ContainerTariffAndInvoicingStyled>
            <ContainerInvoicingStyled>
              <SectionDivider label="Vigência da Operação" />
              <LineSectionStyled columns="1fr 1fr 1fr">
                <WrapperDateTimePikers>
                  <DateTimePikers
                    views={['year', 'month', 'day']}
                    label="Data Inicial *"
                    format='DD/MM/YYYY'
                    value={dayjs(values.initialDate).isValid()? dayjs(values.initialDate) : dayjs()}
                    minDate={dayjs().startOf('day')}
                    onChange={(date) => {
                      setFieldValue('initialDate', date?.toISOString())
                      setFieldValue('endDate', date?.add(365, 'days').toISOString())
                    }}
                  />
                </WrapperDateTimePikers>
                <WrapperDateTimePikers>
                  <DateTimePikers
                    views={['year', 'month', 'day']}
                    label="Data Final *"
                    format='DD/MM/YYYY'
                    value={dayjs(values.endDate).isValid()? dayjs(values.endDate) : dayjs()}
                    minDate={dayjs(values.initialDate)}
                    onChange={(date) => {
                      setFieldValue('endDate', date?.toISOString())
                    }}
                  />
                </WrapperDateTimePikers>
 
                <InputField
                  label="Frequência Mensal"
                  id="frequency"
                  name="frequency"
                  type="number"
                  value={values.frequency}
                  onChange={handleChange}
                  hasError={!!errors.frequency && !!touched.frequency}
                  errorMessage={String(errors.frequency)}
                />
              </LineSectionStyled>
            </ContainerInvoicingStyled>

            <ContainerTariffStyled>
              <SectionDivider label="Tarifa Teto (R$)" />
              {showPriceSuggestion && (
                <LineSectionStyled columns="1fr">
                  <Balloon firstText="Preço Sugerido" secondText={`R$ ${valueSuggestionPrice}`} open />
                  <div></div>
                </LineSectionStyled>
              )}
              <LineSectionStyled columns="1fr">
                <InputCurrency
                  label="Valor Máximo (sem impostos)"
                  id="freightValue"
                  name="freightValue"
                  prefix={'R$ '}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={values.freightValue}
                />
              </LineSectionStyled>
            </ContainerTariffStyled>

            <ContainerOfferedCapacity>
              <SectionDivider label="Capacidade Necessária" />
              <LineSectionStyled columns="1fr 1fr 1fr">
                <InputField
                  label="Paletes"
                  id="offeredPallet"
                  name="offeredPallet"
                  type="number"
                  value={values.offeredPallet}
                  onChange={handleChange}
                  hasError={!!errors.offeredPallet && !!touched.offeredPallet}
                  errorMessage={String(errors.offeredPallet)}
                />

                <InputCurrency
                  label="Cubagem (M³)"
                  id="offeredCubage"
                  name="offeredCubage"
                  type="number"
                  suffix=" m³"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.offeredCubage)}
                />

                <InputCurrency
                  label="Peso (kg)"
                  id="offeredWeight"
                  name="offeredWeight"
                  type="number"
                  suffix=" Kg"
                  allowDecimals={false}
                  decimalSeparator=","
                  groupSeparator="."
                  decimalScale={0}
                  decimalsLimit={0}
                  onValueChange={(value, name) => setFieldValue(name ?? '', value)}
                  value={Number(values.offeredWeight)}
                />
              </LineSectionStyled>
            </ContainerOfferedCapacity>
          </ContainerTariffAndInvoicingStyled>
        </SectionStyled>

        <SectionDivider label="Especificações da Operação" />
        <SectionStyled name="origem">
          <LineSectionStyled columns="1fr 1fr 1fr 1fr">
            <Select
              label="Tempo de Carregamento *"
              id="loadingTime"
              name="loadingTime"
              value={values.loadingTime}
              setFieldValue={setFieldValue}
              hasError={!!errors.loadingTime && !!touched.loadingTime}
              errorMessage={String(errors.loadingTime)}
              options={optionsTime}
            />
            <Select
              label="Tempo de Descarga *"
              id="timeDownload"
              name="timeDownload"
              value={values.timeDownload}
              setFieldValue={setFieldValue}
              hasError={!!errors.timeDownload && !!touched.timeDownload}
              errorMessage={String(errors.timeDownload)}
              options={optionsTime}
            />
            <Select
              label="Requer Ajudante? *"
              id="helper"
              name="helper"
              value={values.helper}
              setFieldValue={setFieldValue}
              hasError={!!errors.helper && !!touched.helper}
              errorMessage={String(errors.helper)}
              options={optionsYesOrNo}
            />

            <Select
              label="Carga Paletizada? *"
              id="pallets"
              name="pallets"
              value={values.paletizado}
              setFieldValue={setFieldValue}
              hasError={!!errors.paletizado && !!touched.paletizado}
              errorMessage={String(errors.paletizado)}
              options={optionsYesOrNo}
            />
          </LineSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button label="Salvar" color="pink" isLoading={isLoading} onPress={handleValidation} />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'ativo'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    );
  };

  const BackhaulInformationForm = withFormik<IHireBackhaul, IHireBackhaul>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleRegister(values)},
    validationSchema: schemaValidation,
  })(InnerForm);

  return <BackhaulInformationForm {...hireBackhaulRegisterValues} />;
};

export default BackhaulRegisterFormView;
