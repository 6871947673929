import { useMemo, ReactNode, FC, Fragment } from 'react';

import { ITabContent } from './tab.types';
import * as S from './tab-view.styled';

interface ITabProps {
  tabsContent: Array<ITabContent>;
  activeTab: ITabContent;
  icon?: ReactNode;
  handleActiveTab: (tab: ITabContent) => void;
}

export const TabView: FC<ITabProps> = ({ tabsContent, activeTab, icon, handleActiveTab }) => {
  const renderTabs = useMemo(() => {
    return tabsContent.map((tab) => {
      const isPressed = activeTab === tab;

      return (
        <Fragment key={tab.name}>
          <S.TabContainer
            isPressed={isPressed}
            hasTotalRow={tab.totalRow !== undefined}
            color={tab.color}
            onClick={() => handleActiveTab(tab)}
          >
            <S.TabLabel isPressed={isPressed}>{tab.name}</S.TabLabel>
            <S.TabRowNumber isPressed={isPressed}>{tab.totalRow}</S.TabRowNumber>
          </S.TabContainer>
          <S.TabDivider />
        </Fragment>
      );
    });
  }, [activeTab, handleActiveTab, tabsContent]);

  return (
    <Fragment>
      <S.TabWrapper>
        <S.TabHeader>{renderTabs}</S.TabHeader>
        <S.TabIcon>{icon}</S.TabIcon>
      </S.TabWrapper>
      <S.Trace />
      <S.TabContent>{activeTab.content}</S.TabContent>
    </Fragment>
  );
};
