import dayjs from 'dayjs';
import { IDuplicatedWeighingEntity } from '../../../../services/register-duplicated-weighing/register-duplicated-weighing.types';
import { IWeighingDuplicatedDomain } from '../../../../domains/weighing-duplicated.domain';

export const convertWeighingRegisterDomainToEntity = (domain: IWeighingDuplicatedDomain): IDuplicatedWeighingEntity => {
  return {
    documentNumber: domain.documentNumber,
    entryDate: dayjs(domain.entryDate).toDate(),
    entryWeight: domain.entryWeight,
    exitDate: dayjs(domain.exitDate).toDate(),
    exitWeight: domain.exitWeight,
    netWeight: domain.netWeight,
    originWeight: domain.originWeight,
    nfWeight: domain.nfWeight,
    operation: domain.operation,
    origin: {
      id: domain.origin.id,
      type: domain.origin.type,
    },
    destination: {
      id: domain.destination.id,
      type: domain.destination.type,
    },
    shippingCompanyId: domain.shippingCompany.id,
    itemId: domain.item.id,
    driverId: domain.driver.id,
    vehicleId: domain.vehicle.id,
    scheduleId: domain.schedule.id,
    smpValidation: domain.smpValidation,
    po: domain.po,
    scheduledDateTime: dayjs(domain.scheduledDateTime).toDate(),
    palletCapacity: domain.palletCapacity,
    tonCapacity: domain.tonCapacity,
    collectionOrder: domain.collectionOrder,
    ticketStatus: domain.ticketStatus,
    observation: domain.observation? domain.observation : '',
    originTicketId: domain.id,
  };
};
