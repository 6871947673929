import { IClientsContext } from '../types/clients.types';

export const clientsReducerResetState = (state: IClientsContext): IClientsContext => {
  return {
    ...state,
    clientsList: {
      ...state.clientsList,
      statusRequest: 'INITIAL',
      clients: [],
      filterActions: {
        ...state.clientsList.filterActions,
        filters: {
          column: 'ID',
          order: 'DESC',
          status: 'active',
          isPageLimit: false,
          page: 1,
          searchValue: '',
        },
      },
    },
  };
};
