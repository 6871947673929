import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import dayjs from 'dayjs';
import { DriverRegisterSchema } from './schemas-validation/schemas-validation';
import { optionsCategory, optionsCertifications, optionsSSP } from './form-values/form-values';
import * as S from './driver-register-form.styled';
import { UserContext } from 'state/user-context';
import { optionsFleetType } from 'pages/vehicle/vehicle-register/components/vehicle-register-form/form-values/form-values';
import { uf } from 'domain/states';
import { FooterPage } from 'components-v2/common/footer-page';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import { showToast } from 'components/toast/toast';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import MultiSelect from 'components/multi-select/multi-select';
import InputUploadImage from 'components/input-upload-image/input-upload-image';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputFieldCpf from 'components/input-field-cpf/input-field-cpf';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputField from 'components/input-field/input-field';
import AsyncSelectField from 'components/async-select/async-select';
import { IDriverNewDomain } from 'src-new/pages/registrations/domains/driver.domain';
import { AttachDocumentsSection } from 'src-new/pages/registrations/components/attach-documents-section/attach-documents-section.component';
import { IAddress } from 'domain/address';
import { RiskDetailsSection } from 'src-new/pages/registrations/pages/driver/components/risk-management-section/risk-management-section.component';

interface DriverRegisterFormViewProps {
  driverRegisterValues: IDriverNewDomain;
  handleGoBack: () => void;
  handleRegister: (values: IDriverNewDomain) => void;
  onBlurCep: (cep: string) => Promise<IAddress>;
  shippingApiCall?: (searchValue: string) => Promise<Array<{ label: string; value: number }>>;
  handleCompleteShippingInformation?: (id: number) => Promise<void>;
  isAllocation?: boolean;
}

const DriverRegisterFormView: React.FC<DriverRegisterFormViewProps> = ({
  driverRegisterValues,
  handleGoBack,
  handleRegister,
  onBlurCep,
  shippingApiCall,
  handleCompleteShippingInformation,
  isAllocation,
}) => {
  const InnerForm = (props: FormikProps<IDriverNewDomain>) => {
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = props;
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
      function verificarObjetoVazio(obj: any) {
        return Object.keys(obj).length !== 0;
      }

      if (verificarObjetoVazio(errors) && verificarObjetoVazio(touched)) {
        showToast({
          message: 'É necessário preencher todos os campos obrigatórios',
          type: 'error',
        });
        setSubmit(false);
      }
    }, [errors, submit, touched]);

    const handleVerifyInputs = useCallback(() => {
      handleSubmit();
      setSubmit(true);
    }, [handleSubmit]);

    const onBlurCEPField = async (cep: string) => {
      const address = await onBlurCep(cep);
      setFieldValue('address.cep', address.cep || values.cep);
      setFieldValue('city', address.city);
      setFieldValue('complement', address.complement);
      setFieldValue('neighborhood', address.neighborhood);
      setFieldValue('numberHouse', address.numberHouse);
      setFieldValue('uf', address.uf);
      setFieldValue('street', address.street);
    };

    const { user } = useContext(UserContext);

    useEffect(() => {
      if (user?.shippingCompanyId) {
        setFieldValue('shippingCompanyId', user.shippingCompanyId);
        setFieldValue('fleetType', 'Frota Própria');
      }
      const date = new Date();
      setFieldValue('createdAt', dayjs(date));
    }, [setFieldValue, user?.shippingCompanyId]);

    const handleValidation = useCallback(() => {
      handleVerifyInputs();
    }, [handleVerifyInputs]);

    return (
      <S.FormStyled isAllocation={isAllocation}>
        <div style={{ display: 'flex' }}>
          <S.GridTemplate>
            <S.GridSpan colunmStart={1} lineStart={1} colunmEnd={2}>
              <InputField label="ID" id="id" name="id" type="text" disabled />
            </S.GridSpan>

            <S.GridSpan colunmStart={2} lineStart={1} colunmEnd={8}>
              <InputField
                label="Nome Completo *"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
                errorMessage={String(errors.name)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={8} lineStart={1} colunmEnd={10}>
              <InputField
                label="Código Interno"
                id="internalCode"
                name="internalCode"
                type="text"
                onChange={handleChange}
                value={values.internalCode}
                hasError={!!errors.internalCode && !!touched.internalCode}
                errorMessage={String(errors.internalCode)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={10} lineStart={1} colunmEnd={13}>
              <InputFieldPhone
                label="Celular *"
                id="firstPhone"
                name="firstPhone"
                type="text"
                onChange={handleChange}
                value={values.firstPhone}
                hasError={!!errors.firstPhone && !!touched.firstPhone}
                errorMessage={String(errors.firstPhone)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={13} lineStart={1} colunmEnd={15}>
              <InputField
                label="TAC"
                id="tac"
                name="tac"
                type="text"
                onChange={handleChange}
                value={values.tac}
                hasError={!!errors.tac && !!touched.tac}
                errorMessage={String(errors.tac)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={15} lineStart={1} colunmEnd={18}>
              <DateTimePikers
                label="Data de Cadastro"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={values.createdAt ? dayjs(values.createdAt, 'DD/MM/YYYY') : null}
                onChange={(date) => setFieldValue('createdAt', date)}
                disabled
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={1} lineStart={2} colunmEnd={8}>
              <SectionDivider label="CNH" />
            </S.GridSpan>

            <S.GridSpan colunmStart={1} lineStart={3} colunmEnd={4}>
              <InputField
                label="Registro CNH*"
                id="cnhNumber"
                name="cnhNumber"
                type="text"
                onChange={handleChange}
                value={values.cnhNumber}
                hasError={!!errors.cnhNumber && !!touched.cnhNumber}
                errorMessage={String(errors.cnhNumber)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={4} lineStart={3} colunmEnd={6}>
              <Select
                label="Categoria*"
                id="cnhCategory"
                name="cnhCategory"
                value={values.cnhCategory}
                setFieldValue={setFieldValue}
                options={optionsCategory}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={6} lineStart={3} colunmEnd={8}>
              <DateTimePikers
                label="Validade CNH*"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={values.cnhValidate ? dayjs(values.cnhValidate, 'DD/MM/YYYY') : null}
                onChange={(date) => setFieldValue('cnhValidate', date)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={8} lineStart={2} colunmEnd={18}>
              <SectionDivider label="Documentação" />
            </S.GridSpan>
            <S.GridSpan colunmStart={8} lineStart={3} colunmEnd={10}>
              <InputFieldCpf
                label="CPF *"
                id="cpf"
                name="cpf"
                type="text"
                onChange={handleChange}
                value={values.cpf}
                hasError={!!errors.cpf && !!touched.cpf}
                errorMessage={String(errors.cpf)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={10} lineStart={3} colunmEnd={12}>
              <InputField
                label="RG"
                id="rg"
                name="rg"
                type="text"
                onChange={handleChange}
                value={values.rg}
                hasError={!!errors.rg && !!touched.rg}
                errorMessage={String(errors.rg)}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={12} lineStart={3} colunmEnd={14}>
              <Select
                label="Órgão Expedidor"
                id="ssp"
                name="ssp"
                value={values.ssp}
                setFieldValue={setFieldValue}
                hasError={!!errors?.ssp && !!touched?.ssp}
                errorMessage={String(errors.ssp)}
                options={optionsSSP}
              />
            </S.GridSpan>

            <S.GridSpan colunmStart={14} lineStart={3} colunmEnd={18}>
              <DateTimePikers
                label="Data de Emissão *"
                format="DD/MM/YYYY"
                views={['year', 'month', 'day']}
                value={values.issueDate ? dayjs(values.issueDate, 'DD/MM/YYYY') : null}
                validateField={!!errors?.issueDate && !!touched?.issueDate}
                errorMessage={String(errors.issueDate)}
                onChange={(date) => setFieldValue('issueDate', date)}
              />
            </S.GridSpan>
          </S.GridTemplate>
          <S.UploadPhotoStyled>
            <InputUploadImage
              hasError={false}
              handleSelectedImage={(dataUrl, file) => {
                setFieldValue('driverPhoto', dataUrl);
                setFieldValue('driverPhoto', file);
              }}
              isProfileImage
            />
          </S.UploadPhotoStyled>
        </div>

        <SectionDivider label="Certificações" />
        <S.CertificationWrapperStyled>
          <S.SectionStyled name="Unidades">
            <MultiSelect
              options={optionsCertifications}
              menuPosition="fixed"
              onChange={(e) => {
                const certification = e.map((option) => option.value);
                setFieldValue('certification', certification);
              }}
            />
          </S.SectionStyled>
        </S.CertificationWrapperStyled>

        <SectionDivider label="Endereço Residencial" />
        <S.SectionStyled name="Motoristas">
          <S.LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
            <InputFieldCep
              label="CEP"
              id="cep"
              name="cep"
              type="text"
              value={values.cep}
              onChange={handleChange}
              onBlur={(e) => onBlurCEPField(e.target.value)}
              hasError={!!errors.cep && !!touched.cep}
              errorMessage={String(errors.cep)}
            />

            <InputField
              label="Bairro"
              id="neighborhood"
              name="neighborhood"
              type="text"
              onChange={handleChange}
              value={values.neighborhood}
              hasError={!!errors.neighborhood && !!touched.neighborhood}
              errorMessage={String(errors.neighborhood)}
            />

            <InputField
              label="Cidade"
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={values.city}
              hasError={!!errors.city && !!touched.city}
              errorMessage={String(errors.city)}
            />

            <Select
              label="UF"
              id="uf"
              name="uf"
              value={values.uf}
              setFieldValue={setFieldValue}
              hasError={!!errors.uf && !!touched.uf}
              errorMessage={String(errors.uf)}
              options={uf}
            />
          </S.LineSectionStyled>

          <S.LineSectionStyled columns="1.5fr 0.4fr 1.1fr">
            <InputField
              label="Endereço"
              id="street"
              name="street"
              type="text"
              value={values.street}
              onChange={handleChange}
              hasError={!!errors.street && !!touched.street}
              errorMessage={String(errors.street)}
            />

            <InputField
              label="Nº"
              id="numberHouse"
              name="numberHouse"
              type="text"
              onChange={handleChange}
              value={values.numberHouse}
              hasError={!!errors.numberHouse && !!touched.numberHouse}
              errorMessage={String(errors.numberHouse)}
            />

            <InputField
              label="Complemento"
              id="complement"
              name="complement"
              type="text"
              onChange={handleChange}
              value={values.complement}
              hasError={!!errors.complement && !!touched.complement}
              errorMessage={String(errors.complement)}
            />
          </S.LineSectionStyled>
        </S.SectionStyled>

        {user?.profile !== 'shipping-company' && (
          <>
            <SectionDivider label="Transportadora" />
            <S.LineSectionStyled columns="1fr 2fr" style={{ height: 75 }}>
              <Select
                label="Tipo de Frota *"
                id="fleetType"
                name="fleetType"
                value={values.fleetType}
                setFieldValue={(field, value) => {
                  if (value === 'Frota Própria') {
                    setFieldValue(field, value);
                  } else {
                    setFieldValue('values.shippingCompanyId', '');
                    setFieldValue(field, value);
                  }
                }}
                hasError={!!errors.fleetType && !!touched.fleetType}
                errorMessage={String(errors.fleetType)}
                options={optionsFleetType}
                isClearable
              />
              <AsyncSelectField
                placeholder=""
                label="Nome da Transportadora"
                isClearable={true}
                name="shippingCompanyId"
                id="shippingCompanyId"
                cacheOptions={true}
                defaultOptions={true}
                onChange={async (e) => {
                  setFieldValue('shippingCompanyId', e && e.value);
                  setFieldValue('shippingName', e && e.label);
                  handleCompleteShippingInformation && (await handleCompleteShippingInformation(e?.value ?? 0));
                }}
                hasError={!!errors.shippingCompanyId && !!touched.shippingCompanyId}
                errorMessage={String(errors.shippingCompanyId)}
                apiCallFunction={shippingApiCall || (async () => [])}
                loadOptions={shippingApiCall || (async () => [])}
                disabled={values.fleetType !== 'Frota Terceirizada'}
              />
            </S.LineSectionStyled>
          </>
        )}
        <RiskDetailsSection />
        <AttachDocumentsSection />
        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button
              label={isAllocation ? 'Salvar e Alocar' : 'Salvar'}
              color="pink"
              onPress={handleValidation}
            />
            {!isAllocation && (
              <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
            )}
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            <ToggleCheckboxView
              id="status"
              name="status"
              label="Ativo"
              checked={values.status === 'active'}
              onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'ativo' : 'inativo')}
            />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </S.FormStyled>
    );
  };

  const DriverRegisterFormView = withFormik<IDriverNewDomain, IDriverNewDomain>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => handleRegister(values),
    validationSchema: DriverRegisterSchema,
  })(InnerForm);

  return <DriverRegisterFormView {...driverRegisterValues} />;
};

export default DriverRegisterFormView;
