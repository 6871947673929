import React, { useCallback, useContext, useMemo } from 'react';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { IWeighingListFilterDomain } from '../../../../domains/weighing-list-filters/weighing-list-filters.domain';
import { WeighingFilterView } from './weighing-filter-view.component';
import { convertWeighingFilters } from 'src-new/pages/execution/pages/weighing/utils/convert-weighing-filters/convert-weighing-filters.util';
import { weighingListFilterInitialValues } from '../../../../domains/weighing-list-filters/weighing-list-filters-initial-values.domain';
import { useQueryClient } from 'react-query';
interface IWeighingFilterProps {
  handleModalFilter: () => void;
}

export const WeighingFilter: React.FC<IWeighingFilterProps> = ({ handleModalFilter }) => {
  const { weighingList } = useContext(WeighingContext);
  const { filters } = weighingList;
  const { selectedFilter, setSelectFilters } = filters;
  const reactQueryClient = useQueryClient();

  const weighingFiltersValues = useMemo((): IWeighingListFilterDomain => {
    return { ...selectedFilter, page: 1 };
  }, [selectedFilter]);

  const appliedFilters = useMemo((): number => {
    const filters = convertWeighingFilters(selectedFilter).filter((filter) => !Array.isArray(filter.value));
    return filters.length;
  }, [selectedFilter]);

  const handleResetFilter = useCallback(async () => {
    setSelectFilters(weighingListFilterInitialValues);
    handleModalFilter();
    await reactQueryClient.invalidateQueries(['weighing-list-table', weighingListFilterInitialValues]);
  }, [handleModalFilter, reactQueryClient, setSelectFilters]);

  const onFilterSubmit = useCallback(
    (values: IWeighingListFilterDomain) => {
      const mountSearchFilter: IWeighingListFilterDomain = {
        ...values,
        page: 1,
      };
      setSelectFilters(mountSearchFilter);
      sessionStorage.setItem('weighing-list-filters', JSON.stringify(mountSearchFilter));
      handleModalFilter();
    },
    [handleModalFilter, setSelectFilters],
  );

  return (
    <WeighingFilterView
      weighingFiltersValues={weighingFiltersValues}
      appliedFilters={appliedFilters}
      handleResetFilter={handleResetFilter}
      handleModalFilter={handleModalFilter}
      onFilterSubmit={onFilterSubmit}
    />
  );
};
