import { ScheduleRegisterInput } from 'domain-v2/schedule/register';

export const convertScheduleCreate = (schedule: ScheduleRegisterInput) => {
  const { origin, destination, cargo, detail, cost, distance, operation, retentionFines } = schedule;

  const operationSpot = operation === 'SPOT' || operation === 'BACKHAUL EXTERNO SPOT';

  const merchValue = cargo.merchValue.toString().replace(',', '.');
  const weightValue = cargo.weight.toString().replace(',', '.');

  return {
    origin: {
      id: origin.id,
      type: origin.type,
    },
    destination: {
      id: destination.id,
      type: destination.type,
    },
    cargo: {
      vehicleType: cargo.vehicleType,
      vehicleCategoryType: cargo.vehicleCategoryType,
      shippingReturn: cargo.shippingReturn,
      product: cargo.product,
      weight: Number(weightValue),
      cubagem: Number(cargo.cubagem),
      volume: Number(cargo.volume),
      merchValue: Number(merchValue),
      inPallet: cargo.inPallet || false,
      pallet: Number(cargo.pallet),
      order: cargo.order,
      comments: cargo.comments,
      requirements: {
        quality: cargo.requirements.quality?.values ?? [],
        security: cargo.requirements.security?.values ?? [],
        custom: cargo.requirements.custom?.values ?? [],
      },
      operationSubType: cargo.operationSubType,
    },
    detail: {
      levyInitialDate: detail.levyInitialDate,
      levyEndDate: detail.levyEndDate,
      deliveryInitialDate: detail.deliveryInitialDate,
      deliveryEndDate: detail.deliveryEndDate,
      internalNumber: detail.internalNumber,
      ocInternalNumber: detail.ocInternalNumber,
      boardingNumber: detail.boardingNumber,
      schedulingPassword: detail.schedulingPassword,
    },
    cost: {
      valueFreight: operationSpot ? cost.valueFreight : cost.saleOfferValue ? cost.saleOfferValue.valueTotal : 0,
      valueGoods: operationSpot ? cost.valueGoods : 0,
      valueTotalFee: operationSpot ? cost.valueTotalFee : 0,
      taxes: operationSpot ? cost.taxes : 0,
      totalFreight: operationSpot || !!cost.saleOfferValue ? cost.totalFreight : 0,
      helperTotal: operationSpot ? cost.helperTotal : 0,
      toll: operationSpot ? cost.toll : 0,
      totalGrisAdvalorem: operationSpot || !!cost.saleOfferValue ? cost.totalGrisAdvalorem : 0,
      totalService: operationSpot || !!cost.saleOfferValue ? cost.totalService : 0,
      fine: operationSpot ? cost.fine : 0,
      icms: operationSpot || !!cost.saleOfferValue ? cost.icms : 0,
    },
    type: 'ftl',
    retentionFines,
    distance,
    operation,
    isSaleOfferTraking: !operationSpot && !!cost.saleOfferValue,
    saleOfferId: !operationSpot ? cost.saleOfferValue?.saleOfferId : null,
    saleOfferTariffId: !operationSpot ? cost.saleOfferValue?.saleOfferTariffId : null,
  };
};
