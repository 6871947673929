import React, { ReactNode } from 'react';
import { GoogleMapsView } from 'src-new/components/google-maps/google-maps-view.component';
import { IMapsProps } from './google-maps-view.types';

interface IGoogleMapsProps {
  children: ReactNode;
  mapProps: IMapsProps;
}

export const GoogleMaps: React.FC<IGoogleMapsProps> = ({ children, mapProps }) => {
  return <GoogleMapsView mapProps={mapProps}>{children}</GoogleMapsView>;
};
