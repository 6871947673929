import { FC, useContext, useMemo } from 'react';
import { SaleOfferModalMatchHeaderView } from './sale-offer-modal-match-header-view.component';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';

export const SaleOfferModalMatchHeader: FC = () => {
  const { saleOfferMatchList } = useContext(SaleOfferContext);
  const { saleOfferMatchProposal } = saleOfferMatchList;
  const { statusRequest, proposal } = saleOfferMatchProposal;

  const getProposalMatch = useMemo(() => {
    return proposal;
  }, [proposal]);

  const isLoading = useMemo(() => {
    return statusRequest === 'PROCESSING';
  }, [statusRequest]);

  return <SaleOfferModalMatchHeaderView isLoading={isLoading} proposal={getProposalMatch} />;
};
