import { FC, useMemo } from 'react';

import * as S from './styled';

interface Count {
  count: number;
}

export const Count: FC<Count> = ({ count }) => {
  const renderCount = useMemo(() => {
    if (count >= 10) return '+9';

    return count;
  }, [count]);

  return <S.WrapperCount>{renderCount}</S.WrapperCount>;
};
