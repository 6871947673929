import { ThemeColorsV2Type } from 'styles/types/colors';
import { FreightType, FreightTypeMap } from './convert-freight-type.types';
import { CargoTypesResponse } from 'src-new/domains/cargo-type.domain';

const defaultValue: FreightType = {
  label: 'NÃO MAPEADO',
  color: ThemeColorsV2Type.DarkGray,
};

export const convertFreightType = (freightTypes: string): FreightType => {
  const freightTypeMap: FreightTypeMap = {
    ftl: {
      label: 'FTL',
      color: ThemeColorsV2Type.Blue,
    },
    stl: {
      label: 'STL',
      color: ThemeColorsV2Type.Blue100,
    },
    ltl: {
      label: 'LTL',
      color: ThemeColorsV2Type.Aqua100,
    },
  };

  return freightTypeMap[freightTypes] || defaultValue;
};

export const convertFreightTypeToDomain = (freightType: string): CargoTypesResponse => {
  const freightTypeMap: Record<string, CargoTypesResponse> = {
    FTL: 'FTL',
    STL: 'STL',
    LTL: 'LTL',
  };

  return freightTypeMap[freightType];
};
