import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex: 0 0 40%;
  max-width: 1px;
`;

export const CardDetailsOnLeftStyled = styled.div<{
  bgColor: string;
  color: string;
  border?: string;
  disabled?: boolean;
}>`
  min-width: 235px;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  ${({ bgColor, color, border }) => {
    return css`
      background-color: ${bgColor};
      color: ${color};
      border: 1px solid ${border};
    `;
  }}

  ${({ disabled }) => {
    return css`
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      opacity: ${disabled ? 1 : 1};
    `;
  }}
  > div {
    &:first-child {
      width: 100%;
    }
  }
`;

export const CardDetailsCardModal = styled.div`
  width: 40px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerCardDetailsStyled = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  column-gap: 10px;
  cursor: pointer;
`;

export const TariffCompanyStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StrongStyled = styled.strong``;

export const TextTariffStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const ContainerPriceTariffStyled = styled.div`
  text-align: right;
`;

export const SubNameStyled = styled.p`
  margin-left: 5px;
  font-weight: 700;
`;

export const ContainerDateStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
