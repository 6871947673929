import { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';

import * as S from './styled';

import { ChecklistAnswer } from 'domain-v2/checklist';
import Checkbox from 'components/checkbox/checkbox';

interface ChecklistCheckboxProps {
  hasError: boolean;
  isEdit?: boolean;
}

export const ChecklistCheckbox: FC<ChecklistCheckboxProps> = ({ hasError, isEdit }) => {
  const { values, setFieldValue } = useFormikContext<ChecklistAnswer>();

  const renderCheckboxOptions = useMemo(() => {
    return values.answers[0].answerOptions?.map((option) => {
      return <S.TitleForm key={option}>{option}</S.TitleForm>;
    });
  }, [values.answers]);

  const renderCheckboxValues = useMemo(() => {
    return values.answers.map((question, index) => {
      const questionAsterisk = question.isRequired ? '*' : '';

      return (
        <S.WrapperValues
          key={question.questionId}
          backgroundColor={index % 2 === 0}
          columns={`repeat(${question.answerOptions?.length}, 0.8fr)`}
        >
          <S.Info>{`${question.question} ${questionAsterisk}`}</S.Info>

          {question.answerOptions?.map((option) => {
            return (
              <S.CheckboxContent key={option}>
                <Checkbox
                  id="answer"
                  name="answer"
                  onChange={() => setFieldValue(`answers[${index}].answer`, option)}
                  checked={values.answers[index].answer === option}
                  hasError={hasError && !values.answers[index].answer && values.answers[index].isRequired}
                  disabled={isEdit}
                />
              </S.CheckboxContent>
            );
          })}
        </S.WrapperValues>
      );
    });
  }, [values.answers, hasError, isEdit, setFieldValue]);

  return (
    <S.SectionForm>
      <S.WrapperValues columns={`repeat(${values.answers[0].answerOptions?.length}, 0.8fr)`}>
        <S.TitleHeaderForm>ITEM</S.TitleHeaderForm>

        {renderCheckboxOptions}
      </S.WrapperValues>
      {renderCheckboxValues}
    </S.SectionForm>
  );
};
