import { IGetRiskManagerResponse } from 'src-new/pages/registrations/pages/driver/pages/driver-update/services/get-risk-manager-list/get-risk-manager-list.types';
import { IRiskManagementDomain } from 'src-new/pages/registrations/pages/driver/domains/risk-management.domain';

export const convertRiskManagerResponseToDomain = (response: IGetRiskManagerResponse): IRiskManagementDomain => {
  return {
    consultationRecord: response.consultationRecord ?? '---',
    consultedBy: response.consultedBy ?? '---',
    driverId: response.driverId,
    gr: response.gr,
    registerDate: response.createdAt,
    status: getStatus(response.status),
    validateDate: response.validate,
  };
};

const getStatus = (status?: string) => {
  if (!status) {
    return 'Aprovado';
  }

  return status.toLowerCase() === 'aprovado' ? 'Aprovado' : 'Reprovado';
};
