/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useContext, useMemo } from 'react';

import { ModalConfirmationTypes } from './modal-confirmation.type';
import {
  ButtonContainer,
  CloseModalStyled,
  ContainerHeaderStyled,
  ContainerStyled,
  ContainerTitlesStyled,
  ContentStyled,
  DescriptionStyled,
  SecondTitleStyled,
  TitleStyled,
} from './modal-confirmation.styled';
import { ImageType } from './components/image-type/image-type';

import { UserContext } from 'state/user-context';
import Status from 'components/status-circle/status-circle';
import Button from 'components/button/button';

export type ModalConfirmationViewProps = {
  style?: React.CSSProperties;
  title: any;
  secondTitle?: ReactNode;
  description: React.ReactNode;
  onConfirmation?: () => void;
  buttonTitleConfirmation?: string;
  handleCancel?: () => void;
  handleXCancel?: () => void;
  overflow?: boolean;
  isRight?: boolean;
  legacy?: boolean;
  rightSecondTitle?: boolean;
  deleteTitle?: string;
  returnTitle?: string;
  fullButtonWidth?: boolean;
  size: 'large' | 'medium' | 'small' | 'very-small';
  type: ModalConfirmationTypes;
  dateIndex?: boolean;
  cancelWithCost?: () => void;
  FooterChildren?: ReactNode;
  options?: string[];
  setReasonCancellaton?: (value: string) => void;
};

const ModalConfirmationView: React.FC<ModalConfirmationViewProps> = ({
  style,
  title,
  secondTitle,
  description,
  overflow = false,
  isRight = false,
  legacy,
  rightSecondTitle,
  deleteTitle,
  returnTitle,
  size,
  type,
  handleCancel,
  onConfirmation,
  buttonTitleConfirmation,
  handleXCancel,
  cancelWithCost = () => null,
  dateIndex = false,
  FooterChildren,
}) => {
  const { isAdmin } = useContext(UserContext);

  const buttonColor = useMemo(() => {
    if (type === 'delete') return 'pink';
    if (type === 'e-mail') return 'aqua';
    if (type === 'financial') return 'green';
    if (type === 'finished') return 'green';
    if (type === 'bidding') return 'aqua';

    return 'blue';
  }, [type]);

  const buttonConfirm = useMemo(() => {
    if (type === 'delete') return !deleteTitle ? 'Excluir' : deleteTitle;
    if (type === 'e-mail') return 'Enviar';
    if (type === 'finished') return 'Confirmar';
    if (type === 'financial') return 'Faturar';

    return 'Confirmar';
  }, [type]);

  const buttonCancel = useMemo(() => {
    if (returnTitle) return returnTitle;

    if (type === 'delete') return 'Voltar';
    if (type === 'e-mail') return 'Cancelar';
    if (type === 'finished') return 'Voltar';

    return 'Voltar';
  }, [type]);

  return (
    <ContainerStyled
      style={style}
      aria-label="Modal Confirmação"
      isRight={isRight}
      legacy={legacy}
      dateIndex={dateIndex}
    >
      <ContentStyled overflow={overflow} isRight={isRight} legacy={legacy} size={size} type={type}>
        <ContainerHeaderStyled rightSecondTitle={rightSecondTitle}>
          {rightSecondTitle ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <TitleStyled>{title}</TitleStyled>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '30vw',
                }}
              >
                <SecondTitleStyled>{secondTitle}</SecondTitleStyled>
                <Status bgColor="#00B050" isSquare title={'Alto Potencial'} />
                <Status bgColor="#FFC000" isSquare title={'Médio Potencial'} />
                <Status bgColor="#C00000" isSquare title={'Baixo Potencial'} />
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #d9d9d9',
                  }}
                >
                  <CloseModalStyled onClick={handleXCancel}>X</CloseModalStyled>
                </div>
              </div>
            </div>
          ) : (
            <ContainerTitlesStyled type={type}>
              <ImageType type={type} />
              <TitleStyled>{title}</TitleStyled>
              {secondTitle && <SecondTitleStyled>{secondTitle}</SecondTitleStyled>}
            </ContainerTitlesStyled>
          )}
          {handleXCancel && !rightSecondTitle && (
            <div
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #d9d9d9',
              }}
            >
              <CloseModalStyled onClick={handleXCancel}>X</CloseModalStyled>
            </div>
          )}
        </ContainerHeaderStyled>

        <DescriptionStyled>{description}</DescriptionStyled>

        <ButtonContainer>
          {FooterChildren}

          {handleCancel && type != 'financial' && (
            <Button title={buttonCancel} callback={handleCancel} bgColor="white" size="medium" />
          )}
          {onConfirmation && (
            <Button
              title={buttonTitleConfirmation || buttonConfirm}
              callback={onConfirmation}
              bgColor={buttonColor}
              size="medium"
            />
          )}
          {isAdmin && title === 'Cancelar Viagem' && (
            <Button title="Cancelar com custo" callback={cancelWithCost} bgColor={buttonColor} size="medium" />
          )}
        </ButtonContainer>
      </ContentStyled>
    </ContainerStyled>
  );
};
export default ModalConfirmationView;
