import * as S from './documents-cte-view.styled';
import { Table } from 'src-new/components/table/table.component';
import { IRow } from 'src-new/components/table/table.types';
import { Button, Tag } from 'logshare-ui-kit';
import { DownloadCloud, Eye, Trash2, FileCheck } from 'lucide-react';
import { ToogleList } from 'src-new/components/toogle-list/toogle-list.component';
import React, { useCallback, useMemo, useState } from 'react';
import { ListIcon } from 'assets/icons/list.icon';
import { KanbanListIcon } from 'assets/icons/kanban-list.icon';
import { Box } from 'src-new/components/box/box.component';
import InputUploadFile from 'pages/tracking-occurrence/tracking-occurrence-register/components/tracking-occurrence-input-upload/input-file-upload';
import { downloadDocument, downloadFromUrl } from 'src-new/utils/download-file.util';
import TooltipComponent from 'components/Tooltip';
import { Loading } from 'src-new/components/loading/loading.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';
import {
  ICTeData,
  ScheduleDocumentsModalType,
} from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { ViewModeType } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/components/execution-documents/components/documents-nfe/documents-nfe.component';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';

const extensionsImageType = ['png', 'jpg', 'jpeg', 'gif'];

export interface IDocumentsCTeViewProps {
  ctes: Array<ICTeData>;
  isLoading: boolean;
  handleOpenModal: (modalType: ScheduleDocumentsModalType, id: number) => void;
  isEmptyList: boolean;
  viewMode: ViewModeType;
  handleToggleClick: () => void;
}

export const DocumentsCTeView: React.FC<IDocumentsCTeViewProps> = ({
  ctes,
  isLoading,
  handleOpenModal,
  isEmptyList,
  viewMode,
  handleToggleClick,
}) => {
  const [totalValue, setTotalValue] = useState(0);
  const renderExtension = useCallback((extension?: string) => {
    const res = extension?.toUpperCase().split('.');

    if (res?.length)
      return (
        <S.Div>
          <S.Title>Extensão</S.Title>
          <Tag
            color="blue"
            display="inline-flex"
            label={res?.length ? res[res.length - 1] : '-'}
            size="md"
            variant="solid"
          />
        </S.Div>
      );
  }, []);

  const renderTitleSubtitle = useCallback(
    (title: string, subtitle?: string) => (
      <S.Div>
        <S.Title>{title ?? '-'}</S.Title>
        <S.Subtitle>{subtitle ?? '-'}</S.Subtitle>
      </S.Div>
    ),
    [],
  );

  const rows = useMemo((): Array<IRow> => {
    setTotalValue(0);
    let total = 0;
    return ctes.map((params) => {
      const numberPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      total += params.value;
      setTotalValue(total);
      return {
        key: params.id,
        valuesColumns: [
          { value: renderTitleSubtitle('Tipo', params.type) },
          { value: renderTitleSubtitle('Número do CTe', params.key?.toString()) },
          { value: renderTitleSubtitle('Valor do CTe', `${numberPrice.format(params.value)}`) },
          { value: renderExtension(params.name) },
          {
            value: (
              <S.WrapperActions key={params.id}>
                <TooltipComponent
                  title="Download"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        key={params.id}
                        label=""
                        color="white"
                        LeftIcon={<DownloadCloud size={20} strokeWidth={2.75} />}
                        size="xsmall"
                        variant="ghost"
                        onPress={() => downloadDocument(params.url ?? '', params.name)}
                      />
                    </S.ContainerButtons>
                  }
                />
                <TooltipComponent
                  title="Visualizar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        key={params.id}
                        label=""
                        color="white"
                        LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                        size="small"
                        variant="ghost"
                        onPress={() => downloadFromUrl(params.url)}
                      />
                    </S.ContainerButtons>
                  }
                />
                <TooltipComponent
                  title="Deletar"
                  location="bottom"
                  icon={
                    <S.ContainerButtons>
                      <Button
                        key={params.id}
                        label=""
                        color="white"
                        LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                        size="small"
                        variant="ghost"
                        onPress={() => handleOpenModal('cte', params.id)}
                      />
                    </S.ContainerButtons>
                  }
                />
              </S.WrapperActions>
            ),
            isClicable: false,
          },
        ],
      };
    });
  }, [ctes, handleOpenModal, renderExtension, renderTitleSubtitle]);

  const buildBoxes = useMemo(() => {
    setTotalValue(0);
    let total = 0;
    const boxList = ctes.map((params) => {
      total += params.value;
      setTotalValue(total);

      const res = params.name?.toUpperCase().split('.');
      const extensionType = res?.length ? res[res.length - 1] : '-';

      return (
        <S.Box key={params.id}>
          <Box
            footerButtons={[
              <TooltipComponent
                key={params.id}
                title="Download"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      key={0}
                      label=""
                      color="white"
                      LeftIcon={<DownloadCloud size={20} strokeWidth={2.75} />}
                      size="xsmall"
                      variant="ghost"
                      onPress={() => downloadFromUrl(params.url ?? '', params.name)}
                    />
                  </S.ContainerButtons>
                }
              />,
              <TooltipComponent
                key={params.id}
                title="Visualizar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      key={0}
                      label=""
                      color="white"
                      LeftIcon={<Eye size={20} strokeWidth={2.75} />}
                      size="small"
                      variant="ghost"
                      onPress={() => downloadFromUrl(params.url)}
                    />
                  </S.ContainerButtons>
                }
              />,
              <TooltipComponent
                key={params.id}
                title="Deletar"
                location="bottom"
                icon={
                  <S.ContainerButtons>
                    <Button
                      key={0}
                      label=""
                      color="white"
                      LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                      size="small"
                      variant="ghost"
                      onPress={() => handleOpenModal('cte', params.id)}
                    />
                  </S.ContainerButtons>
                }
              />,
            ]}
          >
            <S.InputUploadFile>
              {extensionsImageType.includes(extensionType.toLowerCase()) ? (
                <InputUploadFile urlFile={params.url} handleSelectedImage={() => null} />
              ) : (
                <S.DownloadCloud>
                  <FileCheck />
                </S.DownloadCloud>
              )}
            </S.InputUploadFile>

            <S.InfoWrapper>
              {renderTitleSubtitle('Nome', params.name.slice(0, -4))}
              {renderExtension(params.name)}
            </S.InfoWrapper>
          </Box>
        </S.Box>
      );
    });

    return <S.BoxWrapper gridColumns="1fr 1fr 1fr">{boxList}</S.BoxWrapper>;
  }, [ctes, handleOpenModal, renderExtension, renderTitleSubtitle]);

  const renderContent = useMemo(() => {
    return viewMode === 'list' ? (
      <Table
        gridColumns="1fr 1fr 1fr 1fr 160px"
        header={[{ value: '' }]}
        rows={rows}
        height="100%"
        config={{
          hideHeader: true,
          body: {
            changeLineOnMouseOver: true,
          },
        }}
      />
    ) : (
      buildBoxes
    );
  }, [buildBoxes, rows, viewMode]);

  if (isLoading) {
    return (
      <S.StateContainer>
        <Loading />
      </S.StateContainer>
    );
  }

  if (isEmptyList) {
    return (
      <S.StateContainer>
        <EmptyState />
      </S.StateContainer>
    );
  }

  return (
    <S.Wrapper>
      <S.WrapperToogle>
        <S.TotalValue>
          Valor Total de CTe <S.TotalValueBold>{convertCurrencyFormat.format(totalValue)}</S.TotalValueBold>
        </S.TotalValue>
        <ToogleList
          isPressed={viewMode === 'list'}
          leftIcon={<ListIcon />}
          rightIcon={<KanbanListIcon />}
          handleToogleClick={handleToggleClick}
        />
      </S.WrapperToogle>
      {renderContent}
    </S.Wrapper>
  );
};
