import { convertHaulTypeOperation } from 'src-new/utils/convert-haul-type/convert-haul-type.util';
import { IHomeMatchesDomain } from '../../../../domains/home-matches/home-matches.domain';
import { IHomeMatchesResponse } from '../../../../services/get-home-matches/get-home-matches.types';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';

export const convertHomeMatchesResponseToDomain = (matchesResponse: IHomeMatchesResponse): IHomeMatchesDomain => {
  return {
    ...matchesResponse,
    company: {
      ...matchesResponse.saleOffer.company,
      image: matchesResponse.saleOffer.company.fileKey,
    },
    vehicle: {
      type: convertVehicleType(matchesResponse.vehicle.type),
      category: convertVehicleCategory(matchesResponse.vehicle.category),
    },
    operationType: convertOperationTypeToDomain(matchesResponse.saleOffer.operationType),
    haulType: convertHaulTypeOperation(matchesResponse.backhaulType),
    typeSchedule: matchesResponse.saleOffer.type,
    invoicedBy: matchesResponse.saleOffer.invoicedBy ?? '',
    capacity: matchesResponse.saleOffer.capacity,
    values: matchesResponse.saleOffer.values,
    co2Economy: matchesResponse.co2Economy ?? 0,
  };
};
