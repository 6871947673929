import styled, { css } from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import theme from 'styles/theme';

export const WrappedMenu = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 99;
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding-left: 18px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  box-shadow: 5px 5px 15px #7b7b7b;
`;

export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

export const MenuRowLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;
export const CheckboxStyled = styled(MenuRowLabel)`
  margin-left: 15px;
  justify-content: center;
`;

export const TextStyled = styled.span<any>`
  ${({ color }) => css`
    margin-left: 10px;
    font-size: 13px;
    font-weight: ${(p: any) => p.weight ?? 'normal'};
    color: ${color ?? theme.colors.black};
  `}
`;
type ImageStyledProps = { src: string };

export const ImageStyled = styled.img<ImageStyledProps>`
  ${({ src }) => css`
    src: ${src};
    width: 29px;
    height: 23px;
  `}
`;

export const MuiAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const MuiAccordionDetails = styled(AccordionDetails)``;

export const MuiAccordionSummary = styled(AccordionSummary)`
  &.Mui-expanded {
    min-height: 10px !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 5px !important;
  }

  .MuiAccordionSummary-content {
    flex-grow: 0 !important;
    margin: 10px 0 !important;
  }
`;
