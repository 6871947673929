import API from 'utils/API/API';
import { FilterService } from 'domain/filter-delivery-tracking';

export const filterDeliveryTrackingService = async (params: FilterService): Promise<FilterService> => {
  const { data } = await API.get<FilterService>('v2/schedules/public/search', {
    params: {
      code: params.schedulingCode,
    },
  });
  return data;
};
