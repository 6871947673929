import API from 'utils/API/API';
import { IGetTransactionsListRequest } from 'src-new/pages/financial/pages/transactions/pages/transactions-list/services/get-transactions-list/get-transactions-list.types';

export const getTransactionReportService = async (
  params: IGetTransactionsListRequest,
): Promise<ArrayBuffer | undefined> => {
  const { data } = await API.get('/v2/financial-calculation/transactions-excel', {
    params,
    responseType: 'arraybuffer',
  });

  return data;
};
