import { useCallback, useContext } from 'react';
import { WeighingTottensContext } from 'src-new/mobile/pages/weighing-totten/contexts/weighing-totten/weighing-tottens.context';
import { getWeighingTottenDetailsService } from '../../services/get-weighing-totten-details/get-weighing-totten-details.service';
import { convertWeighingDetailsResponseToDomain } from './mappers/convert-weighing-details-response-to-domain.mapper';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import { removeInputMaskCPF } from 'src-new/utils/remove-input-mask.util';

export const useGetWeighingTottenDetails = () => {
  const { weighingTottensDetails } = useContext(WeighingTottensContext);
  const { setWeighingTottensDetailsRequest, setWeighingTottensDetailsSuccess, setWeighingTottensDetailsError } =
    weighingTottensDetails;

  return useCallback(
    async (values: IWeighingTottenDetailsForm) => {
      try {
        setWeighingTottensDetailsRequest();

        const data = await getWeighingTottenDetailsService({
          ...values,
          driverCpf: removeInputMaskCPF(values.driverCpf),
        });

        if(data[0]){
          const value = data.map(convertWeighingDetailsResponseToDomain);
          setWeighingTottensDetailsSuccess(value[0]);
        }else {
          setWeighingTottensDetailsError();
        }

      } catch (error) {
        setWeighingTottensDetailsError();
      }
    },
    [setWeighingTottensDetailsError, setWeighingTottensDetailsRequest, setWeighingTottensDetailsSuccess],
  );
};
