/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalDeleteUploadedCte } from './modal-delete-uploaded-file';
import { RenderCTeDataStyled } from './index.style';

import { FileListUploaded } from 'pages/freight/types';
import Button from 'components/button/button';

interface Props {
  index: number;
  file: FileListUploaded;
  onSuccess?: () => void;
}

export const RenderFileUploadedData: FC<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteFile = async () => {
    setIsModalOpen(false);
    props.onSuccess?.();
  };

  const renderActions = useMemo(() => {
    return (
      <>
        <Button
          title="Deletar"
          callback={() => setIsModalOpen(true)}
          bgColor="white"
          icon={<DeleteIcon />}
          size="very-small"
        />
      </>
    );
  }, [props.file]);

  return (
    <>
      <ModalDeleteUploadedCte
        file={props.file}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleDeleteFile}
      />

      <RenderCTeDataStyled>
        <div>{renderActions}</div>
      </RenderCTeDataStyled>
    </>
  );
};
