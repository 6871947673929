import React, { useCallback, useContext, useMemo } from 'react';
import { HomeMatchesListView } from './home-matches-list-view.component';
import { HomeContext } from 'src-new/pages/home/context/home/home.context';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { convertHomeMatchToBackhaulPurchase } from 'src-new/pages/home/pages/home/mappers/convert-home-match-to-backhaul-purchase/convert-home-match-to-backhaul-purchase.mapper';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';

interface IHomeMatchesListProps {
  infiniteScrollProps: IInfiniteScrollTable;
}
export const HomeMatchesList: React.FC<IHomeMatchesListProps> = ({ infiniteScrollProps }) => {
  const { homeMatches, homeModals } = useContext(HomeContext);
  const { setHomeModalsOpen, setHomeModalsClose } = homeModals;

  const handleOpenBackhaulPurchase = useCallback(
    (id: number) => setHomeModalsOpen({ modalType: 'buyBackhaul', id }),
    [setHomeModalsOpen],
  );

  const handleCloseBackhaulPurchase = useCallback(() => setHomeModalsClose(), [setHomeModalsClose]);

  const modalContent = useMemo((): IBackhaulPurchase | undefined => {
    if (homeModals.open && homeModals.homeModalType === 'buyBackhaul' && homeModals.id) {
      const homeMatchValue = homeMatches.homeMatches.find((item) => item.saleOfferTariffId === homeModals.id);

      if (!homeMatchValue) return;

      return convertHomeMatchToBackhaulPurchase(homeMatchValue);
    }
  }, [homeMatches.homeMatches, homeModals.homeModalType, homeModals.id, homeModals.open]);

  return (
    <HomeMatchesListView
      homeList={homeMatches.homeMatches}
      modalContent={modalContent}
      infiniteScrollProps={infiniteScrollProps}
      handleOpenBackhaulPurchase={handleOpenBackhaulPurchase}
      handleCloseBackhaulPurchase={handleCloseBackhaulPurchase}
    />
  );
};
