import { ColorsV2Type } from 'styles/types/colors';
import { ICheckStubStatus } from 'domain-v2/check-stub';

type CheckStubStatus = {
  label: string;
  color: ColorsV2Type;
};

type CheckStubStatusMap = {
  [key in ICheckStubStatus]: CheckStubStatus;
};

const defaultValue: CheckStubStatus = {
  label: 'Não Informado',
  color: ColorsV2Type.Red,
};

export const convertCheckStubStatus = (checkStubStatus: ICheckStubStatus): CheckStubStatus => {
  const checkStubStatusMap: CheckStubStatusMap = {
    nao_informado: {
      label: 'Não Informado',
      color: ColorsV2Type.Red,
    },
    entregue_analista: {
      label: 'Entregue Analista',
      color: ColorsV2Type.Aqua,
    },
    cliente: {
      label: 'Cliente',
      color: ColorsV2Type.Blue,
    },
  };

  return checkStubStatusMap[checkStubStatus] || defaultValue;
};
