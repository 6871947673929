import React, { ReactElement, useContext, useMemo } from 'react';

import { IOperationValues } from './types';
import * as S from './styled';

import { formatDecimal } from 'utils/format-numbers';
import { axisNumberFixed } from 'utils/axis-number/axis-number';
import { UserContext } from 'state/user-context';
import { VehicleTypeEntity } from 'pages/schedule/services/get-freight-by-filter/get-freight-by-filter.service';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';

interface CardResumeOperationProps {
  cargoDetailsValues: IOperationValues;
  operationBy: string;
  companyBuyer?: string;
  driverValue?: {
    name: string;
    cpf: string;
  };
  vehicleValues?: {
    vehicleType: string;
    vehicleCategory: string;
    mainBoard: string;
    secondBoard: string;
    thirdBoard: string;
  };
  shippingValues?: {
    name: string;
    cnpj: string;
  };
  distance?: number;
  leadTime: number;
}

export const CardResumeOperation: React.FC<CardResumeOperationProps> = ({
  cargoDetailsValues,
  operationBy,
  companyBuyer,
  driverValue,
  vehicleValues,
  shippingValues,
  distance,
  leadTime,
}) => {
  const { user } = useContext(UserContext);

  const { vehicleType, vehicleCategoryType, pallet, weight, inPalet, product, operationSubType } = cargoDetailsValues;

  const palletValue = useMemo(() => {
    if (inPalet) return `${formatDecimal(pallet)} Pallets`;

    return <S.Text>Não Paletizado</S.Text>;
  }, [inPalet, pallet]);

  const renderVehicleValue = useMemo(() => {
    const vehicleTypeToUse = vehicleValues?.vehicleType || vehicleType || '';
    const vehicleCategoryToUse = vehicleValues?.vehicleCategory || vehicleCategoryType || '';

    const vehicleTypeText = convertVehicleTypeTypeToName(vehicleTypeToUse);
    const vehicleCategoryText = convertVehicleCategoryTypeToName(vehicleCategoryToUse);
    const vehicleAxisNumber = axisNumberFixed(vehicleTypeToUse as VehicleTypeEntity);

    if (!(vehicleTypeText || vehicleCategoryText)) {
      return (
        <S.Text italic uppercase>
          Não Informado
        </S.Text>
      );
    }

    return (
      <>
        <S.Text uppercase>{`${vehicleTypeText || ''} ${vehicleCategoryText || ''}`}</S.Text>

        <div style={{ display: 'flex' }}>
          {vehicleValues?.mainBoard && <S.Text uppercase>{vehicleValues.mainBoard}</S.Text>}
          {vehicleValues?.secondBoard && <S.Text uppercase>{`, ${vehicleValues.secondBoard}`}</S.Text>}
          {vehicleValues?.thirdBoard && <S.Text uppercase>{`, ${vehicleValues.thirdBoard}`}</S.Text>}
        </div>

        {!!vehicleAxisNumber && <S.Text uppercase>{vehicleAxisNumber} Eixos</S.Text>}
      </>
    );
  }, [vehicleType, vehicleCategoryType, vehicleValues]);

  const renderDistance = useMemo((): ReactElement => {
    if (distance) {
      return <S.Text>{`${distance.toFixed(0)} KM`}</S.Text>;
    }

    return (
      <S.TextContent italic>
        <S.Text uppercase>Não informado</S.Text>
      </S.TextContent>
    );
  }, [distance]);

  const renderLeadTime = useMemo((): ReactElement => {
    if (leadTime) {
      return <S.Text>{`${leadTime} DIAS`}</S.Text>;
    }

    return (
      <S.TextContent italic>
        <S.Text uppercase>Não informado</S.Text>
      </S.TextContent>
    );
  }, [leadTime]);

  return (
    <S.LineSection columns="0.5fr 3.5fr 8fr">
      <S.StepIconSection>
        <S.StepIcon />
      </S.StepIconSection>

      <S.Text fontWeight uppercase>
        Detalhes
      </S.Text>

      <S.OperationInformationContent>
        {user?.profile !== 'shipping-company' && (
          <div>
            <S.Text fontWeight uppercase>
              Embarcador
            </S.Text>
            <S.Text uppercase>{companyBuyer ?? operationBy}</S.Text>
          </div>
        )}

        {companyBuyer && (
          <div>
            <S.Text fontWeight uppercase>
              Vendedor
            </S.Text>
            <S.Text uppercase>{operationBy}</S.Text>
          </div>
        )}

        {shippingValues?.name && (
          <div>
            <S.Text fontWeight uppercase>
              Transportador
            </S.Text>
            <S.Text>{shippingValues.name}</S.Text>
          </div>
        )}

        {driverValue?.name && (
          <div>
            <S.Text fontWeight uppercase>
              Motorista
            </S.Text>
            <S.Text>{driverValue.name}</S.Text>
            <S.Text>{driverValue.cpf}</S.Text>
          </div>
        )}

        <div>
          <S.Text fontWeight uppercase>
            Veículo
          </S.Text>
          {renderVehicleValue}
        </div>

        <div>
          <S.Text fontWeight uppercase>
            Carga
          </S.Text>
          <S.Text uppercase>
            <S.Text>{product}</S.Text>
            <S.Text>{`${formatDecimal(weight)} kg`}</S.Text>
            <S.Text>{palletValue}</S.Text>
          </S.Text>
        </div>

        {!!operationSubType && (
          <div>
            <S.Text fontWeight uppercase>
              Tipo de Operação
            </S.Text>
            <S.Text uppercase>
              <S.Text>{operationSubType}</S.Text>
            </S.Text>
          </div>
        )}

        <div>
          <S.Text fontWeight uppercase>
            Distância
          </S.Text>

          {renderDistance}
        </div>

        <div>
          <S.Text fontWeight uppercase>
            LeadTime
          </S.Text>

          {renderLeadTime}
        </div>
      </S.OperationInformationContent>
    </S.LineSection>
  );
};
