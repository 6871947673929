import API from 'utils/API/API';
import { convertCompanyEntityToOptions } from 'pages/choose-company/mapper/convert-company-entity-to-select-item/convert-company-entity-to-options';

interface SelectItem {
  label: string;
  value: string;
}

export const listCompanyService = async (): Promise<Array<SelectItem>> => {
  const { data } = await API.get('v2/companies/list');
  return data.map((company: { name: string; id: string }) => convertCompanyEntityToOptions(company));
};
