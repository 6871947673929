import styled, { css } from 'styled-components';

import theme from '../../styles/theme';

type WrapperStyledProps = {
  isOpen?: boolean;
};

const WrapperStyledModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
  `,
};

export const WrapperStyled = styled.div<WrapperStyledProps>`
  ${({ isOpen }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${ContentStyled} {
      transition:
        transform 0.2s ease-in,
        opacity ${theme.transition.default};
      ${isOpen && WrapperStyledModifiers.open()}
      ${!isOpen && WrapperStyledModifiers.close()}
    }
  `}
`;

export const ContainerLogoStyled = styled.div`
  svg {
    width: 22px;
  }
`;

export const TitleStyled = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    color: ${theme.colors.black};
    padding: 10px 15px;
    border-radius: 22px;
    margin-left: 15px;
    cursor: pointer;
  `}
`;

export const ContentStyled = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: max-content;
    margin-top: 3px;
    right: 0;
    background-color: ${theme.colors.white};
    border: solid 1px #fff;
    border-radius: 5px;
    font-size: ${theme.font.sizes.xsmall};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    top: 40px;
    color: ${theme.colors.black};
    z-index: ${theme.layers.modal};

    /* &::before {
      content: '';
      position: absolute;
      border-right: 1.2rem solid transparent;
      border-left: 1.2rem solid transparent;
      border-bottom: 1.2rem solid ${theme.colors.backgrounds};
      top: -0.5rem;
      right: 2.4rem;
    } */
  `}
`;

export const BulletStyled = styled.div`
  width: 10px;
  height: 10px;
  background-color: #bbbbbb;
  border-radius: 200px;
  margin-right: 5px;
`;
