import { IPurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer.types';
import { IPurchaseOfferListDownloadReportActions } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/types/purchase-offer-list-download-report.types';
import { PurchaseOfferListDownloadReportActionType } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.action';

export const purchaseOfferListDownloadReportReducer = (
  state: IPurchaseOfferContext,
  action: IPurchaseOfferListDownloadReportActions,
): IPurchaseOfferContext => {
  switch (action.type) {
    case PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        purchaseOfferListDownloadReport: {
          ...state.purchaseOfferListDownloadReport,
          statusRequest: 'PROCESSING',
        },
      };
    case PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        purchaseOfferListDownloadReport: {
          ...state.purchaseOfferListDownloadReport,
          statusRequest: 'SUCCESS',
        },
      };
    case PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        purchaseOfferListDownloadReport: {
          ...state.purchaseOfferListDownloadReport,
          statusRequest: 'ERROR',
        },
      };
    case PurchaseOfferListDownloadReportActionType.PURCHASE_OFFER_LIST_DOWNLOAD_REPORT_RESET:
      return {
        ...state,
        purchaseOfferListDownloadReport: {
          ...state.purchaseOfferListDownloadReport,
          statusRequest: 'INITIAL',
        },
      };
    default:
      return state;
  }
};
