import styled from 'styled-components';

export const ContainerSearchStyled = styled.div`
  display: flex;

  gap: 10px;
`;

export const UploadDownStyled = styled.div`

  &
`;

export const ExportPDFStyled = styled.div`
  margin-left: 10px;
`;

export const ContainerFilterStyled = styled.div`
  padding-top: 30px;
`;

export const InfoStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const FilterStyled = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
