import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { WeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { WeighingTableView } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/components/weighing-table-tabs/components/weighing-table/weighing-table-view.component';
import { IOrdering } from 'src-new/components/table/table.types';

interface WeighingTableProps {
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const WeighingTable: React.FC<WeighingTableProps> = ({ handleInfinityScroll }) => {
  const { weighingList } = useContext(WeighingContext);
  const { statusRequest, filters } = weighingList;
  const { selectedFilter, setSelectFilters } = filters;

  const navigate = useNavigate();

  const handleLineClick = useCallback(
    (id: number) => {
      navigate(`/pesagem/atualizar/${id}`);
    },
    [navigate],
  );

  const handleDuplicatedClick = useCallback(
    (id: number) => {
      navigate(`/pesagem/duplicated/${id}`);
    },
    [navigate],
  );

  const getWeighing = useMemo(() => weighingList.weighingList, [weighingList.weighingList]);

  const isLoading = useMemo(() => statusRequest === 'PROCESSING', [statusRequest]);

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      setSelectFilters({ ...selectedFilter, order: order, column: columnName });
    },
    [selectedFilter, setSelectFilters],
  );

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: selectedFilter.column,
      order: selectedFilter.order,
      handleClick: handleOrdering,
    };
  }, [handleOrdering, selectedFilter.column, selectedFilter.order]);



  return (
    <WeighingTableView
      weighing={getWeighing}
      isLoading={isLoading}
      handleInfinityScroll={handleInfinityScroll}
      handleLineClick={handleLineClick}
      orderingColumns={orderingColumns}
      handleDuplicatedClick={handleDuplicatedClick}
    />
  );
};
