import { ThemeColorsV2Type } from 'styles/types/colors';
import * as S from './home-buttons-view.styled';
import { CalendarDays, Filter } from 'lucide-react';
import theme from 'styles/theme';
import { HomeButton } from './components/home-button/home-button.component';
import { IModalOptions } from './home-buttons.types';
import { ReactElement, useMemo } from 'react';
import { ModalNewFreight } from 'src-new/components/modal-new-freight/modal-new-freight.component';

interface IHomeButtonsViewProps {
  modalOptions: IModalOptions;
  handleNewFreight: (open: boolean) => void;
  handleTracking: () => void;
  handleMatches: () => void;
}
export const HomeButtonsView: React.FC<IHomeButtonsViewProps> = ({
  modalOptions,
  handleNewFreight,
  handleTracking,
  handleMatches,
}) => {
  const renderModal = useMemo((): ReactElement | undefined => {
    if (modalOptions.open && modalOptions.type === 'newFreight') {
      return <ModalNewFreight handleClose={() => handleNewFreight(false)} />;
    }
  }, [handleNewFreight, modalOptions]);

  return (
    <S.Wrapper>
      {renderModal}
      <HomeButton
        label="Novo Frete"
        color={ThemeColorsV2Type.Blue}
        icon={<CalendarDays size={20} strokeWidth={2} />}
        onPress={() => handleNewFreight(true)}
      />
      <HomeButton
        label="Tracking"
        color={ThemeColorsV2Type.Aqua}
        icon={<theme.icons.trackingIcon />}
        onPress={handleTracking}
      />
      <HomeButton
        label="Funil de Matches"
        color={ThemeColorsV2Type.LightGray25}
        icon={<Filter size={20} strokeWidth={2} />}
        onPress={handleMatches}
        fontColor={ThemeColorsV2Type.Blue}
      />
    </S.Wrapper>
  );
};
