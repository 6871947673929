import React, { useCallback, useContext, useMemo } from 'react';
import { MapTypeId } from 'src-new/components/google-maps/google-maps-view.types';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ActiveButtonType, RouteReportDownloadButtonView } from './map-type-button-view.component';

export const MapTypeButton: React.FC = () => {
  const { scheduleExecution } = useContext(ScheduleContext);
  const { mapType, setMapType } = scheduleExecution.mapAccordionProps.mapTypeActions;

  const handleMapType = useCallback((type: MapTypeId) => setMapType(type), [setMapType]);

  const getActiveButton = useMemo(
    (): ActiveButtonType => (mapType === MapTypeId.ROADMAP ? 'Primary' : 'Secondary'),
    [mapType],
  );

  return <RouteReportDownloadButtonView handleMapType={handleMapType} activeButton={getActiveButton} />;
};
