import { Button, Tag } from 'logshare-ui-kit';
import { RefreshCcw, Trash2 } from 'lucide-react';
import { FC, useMemo } from 'react';

import * as S from './schedule-details-allocation-vehicle-view.styled';

import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Box } from 'src-new/components/box/box.component';
import { IScheduleHistoryDriverAndVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-history-driver-and-vehicle.domain';
import { capitalize } from 'src-new/utils/capitalize.util';
import { formatDate } from 'src-new/utils/format-date';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { IScheduleDetailsVehicleDomain } from '../../../../../../../../domains/schedule-details.domain';
import { offeredWeight } from 'utils-v2/converters/capacity';
import { Tooltip } from '@mui/material';

interface IScheduleDetailsAllocationVehicleViewProps {
  vehicle: IScheduleDetailsVehicleDomain;
  historyVehicles?: Array<IScheduleHistoryDriverAndVehicleDomain>;
  handleUpdateVehicleHistory: (vehicleId: number) => Promise<void>;
  handleDeleteVehicleHistory: (historyId: number) => Promise<void>;
}

export const ScheduleDetailsAllocationVehicleView: FC<IScheduleDetailsAllocationVehicleViewProps> = ({
  vehicle,
  historyVehicles,
  handleUpdateVehicleHistory,
  handleDeleteVehicleHistory,
}) => {
  const renderStatusActive = useMemo(() => {
    return <Tag label="Ativo" color="aqua" variant="ghost" display="flex" size="md" />;
  }, []);

  const renderHistoryVehicles = useMemo(() => {
    if (historyVehicles && historyVehicles.length > 0) {
      return historyVehicles?.map((historyVehicle, index) => {
        const tagActive = historyVehicle.vehicleId === vehicle?.id && index === 0;

        return (
          <Box
            key={index}
            footerButtons={[
              tagActive ? renderStatusActive : <span />,
              <S.WrapperButtons key={0} width={'95px'}>
                <Tooltip title={<S.TooltipLabel>Trocar Veículo</S.TooltipLabel>}>
                  <S.ContainerButtons isDisabled={tagActive}>
                    <Button
                      key={1}
                      label=""
                      color="white"
                      onPress={() => handleUpdateVehicleHistory(historyVehicle.vehicleId)}
                      LeftIcon={<RefreshCcw size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={tagActive}
                    />
                  </S.ContainerButtons>
                </Tooltip>
                <Tooltip title={<S.TooltipLabel>Remover Veículo</S.TooltipLabel>}>
                  <S.ContainerButtons isDisabled={tagActive}>
                    <Button
                      key={2}
                      label=""
                      color="white"
                      onPress={() => handleDeleteVehicleHistory(historyVehicle.id)}
                      LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                      size="x-sm"
                      variant="ghost"
                      disabled={tagActive}
                    />
                  </S.ContainerButtons>
                </Tooltip>
              </S.WrapperButtons>,
            ]}
          >
            <S.Wrapper>
              <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
                <S.WrapperInformation>
                  <S.InformationTitle>Placa Principal</S.InformationTitle>
                  <S.InformationBody>{historyVehicle.vehicleMainBoard}</S.InformationBody>
                </S.WrapperInformation>

                <S.WrapperInformation>
                  <S.InformationTitle>Placa 2</S.InformationTitle>
                  <S.InformationBody>{historyVehicle.vehicleSecondBoard || '-'}</S.InformationBody>
                </S.WrapperInformation>

                <S.WrapperInformation>
                  <S.InformationTitle>Placa 3</S.InformationTitle>
                  <S.InformationBody>{historyVehicle.thirdBoard || '-'}</S.InformationBody>
                </S.WrapperInformation>

                <S.WrapperInformation>
                  <S.InformationTitle>Horário de Alocação</S.InformationTitle>
                  <S.InformationBody>{formatDate(historyVehicle.createdAt, 'DD/MM/YYYY - HH:mm')}</S.InformationBody>
                </S.WrapperInformation>
              </LineSectionLayout>

              <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
                <S.WrapperInformation>
                  <S.InformationTitle>Veículo</S.InformationTitle>
                  <S.InformationBody>{capitalize(convertVehicleType(historyVehicle.vehicleType))}</S.InformationBody>
                </S.WrapperInformation>

                <S.WrapperInformation>
                  <S.InformationTitle>Categoria</S.InformationTitle>
                  <S.InformationBody>
                    {capitalize(convertVehicleCategory(historyVehicle.vehicleCategory))}
                  </S.InformationBody>
                </S.WrapperInformation>

                <S.WrapperInformation>
                  <S.InformationTitle>Capacidade</S.InformationTitle>
                  <S.InformationBody>{offeredWeight(historyVehicle.capacity)}</S.InformationBody>
                </S.WrapperInformation>
              </LineSectionLayout>
            </S.Wrapper>
          </Box>
        );
      });
    }

    return (
      <Box
        footerButtons={[
          renderStatusActive,
          <Tooltip key={1} title={<S.TooltipLabel>Remover Veículo</S.TooltipLabel>}>
            <S.ContainerButtons isDisabled>
              <Button
                label=""
                color="white"
                onPress={() => null}
                LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
                size="x-sm"
                variant="ghost"
                disabled
              />
            </S.ContainerButtons>
          </Tooltip>,
        ]}
      >
        <S.Wrapper>
          <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
            <S.WrapperInformation>
              <S.InformationTitle>Placa Principal</S.InformationTitle>
              <S.InformationBody>{vehicle?.mainBoard || '-'}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Placa 2</S.InformationTitle>
              <S.InformationBody>{vehicle?.secondBoard || '-'}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Placa 3</S.InformationTitle>
              <S.InformationBody>{vehicle?.thirdBoard || '-'}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Horário de Alocação</S.InformationTitle>
              <S.InformationBody>{formatDate(vehicle.allocationDate, 'DD/MM/YYYY - HH:mm')}</S.InformationBody>
            </S.WrapperInformation>
          </LineSectionLayout>

          <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
            <S.WrapperInformation>
              <S.InformationTitle>Veículo</S.InformationTitle>
              <S.InformationBody>{capitalize(convertVehicleType(vehicle?.type))}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Categoria</S.InformationTitle>
              <S.InformationBody>{capitalize(convertVehicleCategory(vehicle?.category))}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Capacidade</S.InformationTitle>
              <S.InformationBody>{offeredWeight(vehicle?.capacity || '-')}</S.InformationBody>
            </S.WrapperInformation>
          </LineSectionLayout>
        </S.Wrapper>
      </Box>
    );
  }, [
    handleDeleteVehicleHistory,
    handleUpdateVehicleHistory,
    historyVehicles,
    renderStatusActive,
    vehicle.allocationDate,
    vehicle?.capacity,
    vehicle?.category,
    vehicle?.id,
    vehicle?.mainBoard,
    vehicle?.secondBoard,
    vehicle?.thirdBoard,
    vehicle?.type,
  ]);

  return <S.WrapperBoxes>{renderHistoryVehicles}</S.WrapperBoxes>;
};
