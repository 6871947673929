import { IModalBiddingOffer } from 'src-new/components/modal-bidding-offer/types/bidding-offer/bidding-offer.types';
import { IBidOfferDomain } from '../../domains/home-bids/bid-offer.domain';

export const convertHomeBidToBiddingOffer = (homeBid: IBidOfferDomain): IModalBiddingOffer => {
  return {
    ...homeBid,
    capacity: {
      ...homeBid.capacity,
      cubagem: 0,
      volume: 0,
    },
    cost: {
      ...homeBid.cost,
      freightValue: homeBid.cost.freightValue ?? 0,
      grisAdvalorem: 0,
      icms: 0,
      totalService: 0,
    },
  };
};
