import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useQuery } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ChecklistQuestions, answerInitialValues } from 'domain-v2/checklist';
import { getChecklistQuestionsService } from '../../services/get-checklist-questions/get-checklist-questions.service';
import { mapAnswers } from 'services/checklist/mappers';

export const useGetChecklistQuestions = () => {
  const { scheduleDocuments } = useContext(ScheduleContext);

  const { data } = useQuery<ChecklistQuestions>({
    queryKey: ['get-checklist-questions', scheduleDocuments.checklistQuestionsProps.checklistQuestions],
    queryFn: () => {
      scheduleDocuments.checklistQuestionsProps.setChecklistQuestionsRequest();
      return getChecklistQuestionsService('schedule', 'vehicle-checklist');
    },
    onSuccess: (data) => {
      scheduleDocuments.checklistQuestionsProps.setChecklistQuestionsSuccess(data);
      const answerValues = mapAnswers(data, answerInitialValues);
      scheduleDocuments.checklistAnswerProps.setChecklistAnswerSuccess(answerValues);
    },
    onError: (error) => {
      scheduleDocuments.checklistQuestionsProps.setChecklistQuestionsError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 600 * 1000,
  });

  return useCallback(() => {
    if (data) {
      scheduleDocuments.checklistQuestionsProps.setChecklistQuestionsSuccess(data);
      const answerValues = mapAnswers(data, answerInitialValues);
      scheduleDocuments.checklistAnswerProps.setChecklistAnswerSuccess(answerValues);
    }
  }, [data, scheduleDocuments.checklistAnswerProps, scheduleDocuments.checklistQuestionsProps]);
};
