import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IWeighingDomain } from '../../../../domains/weighing-details.domain';
import { WeighingUpdateFormMountView } from './weighing-update-form-mount-view.component';
import { UseGetWeighingDetailsCollectionOrder } from '../../../../hooks/use-get-weighing-collect-order/use-get-weighing-collect-order.hook';
import { UserContext } from 'state/user-context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const WeighingUpdateFormMount: FC = () => {
  const { user } = useContext(UserContext);
  const [hasError, setHasError] = useState<boolean>(false);

  const { values, setFieldValue, handleSubmit } = useFormikContext<IWeighingDomain>();
  const getWeighingDetailsCollectionOrderHook = UseGetWeighingDetailsCollectionOrder();

  const navigate = useNavigate();

  const handleValidate = useCallback(() => {
    if (!values.origin.name || !values.destination.name) {
      setHasError(true);

      showMessageFormatted({
        message: 'Preencha todos os campos obrigatórios!',
        type: 'error',
      });
    } else {
      handleSubmit();
    }
  }, [handleSubmit, values.destination.name, values.origin.name]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleGoBackPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDuplicatedClick = useCallback(
    () => {
      navigate(`/pesagem/duplicated/${values.id}`);
    },
    [navigate, values.id],
  );

  const handleCollectOrderDownload = useCallback(() => {
    getWeighingDetailsCollectionOrderHook();
  }, [getWeighingDetailsCollectionOrderHook]);

  useEffect(() => {
    values.operation === 'RECEBIMENTO'
      ? setFieldValue('netWeight', values.entryWeight - values.exitWeight)
      : setFieldValue('netWeight', values.exitWeight - values.entryWeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.entryWeight, values.exitWeight]);

  return (
    <WeighingUpdateFormMountView
      values={values}
      hasError={hasError}
      isMaster={!!user?.isMaster}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleOnSubmit={handleValidate}
      handleGoBackPage={handleGoBackPage}
      handleCollectOrderDownload={handleCollectOrderDownload}
      handleDuplicatedClick={handleDuplicatedClick}
    />
  );
};
