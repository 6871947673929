import axios from 'axios';

export interface IHereMapsConvertAddressParams {
  address: string;
}

export interface IHereMapsLngLatResponse {
  items: Array<{
    position: IHereMapsGeoPosition;
  }>;
  results: Array<{
    geometry: {
      location: IHereMapsGeoPosition;
    };
  }>;
}

export interface IHereMapsGeoPosition {
  lat: string;
  lng: string;
}

export const getConvertAddressToGeoPosition = async (
  params: IHereMapsConvertAddressParams,
): Promise<IHereMapsGeoPosition | null> => {
  const apiKey = 'AIzaSyC1JrN0KI_bcARKOEg-IGiQoaGvPZbL8tc';
  const { address } = params;
  const hereApiUrl = `https://geocode.search.hereapi.com/v1/geocode?q=${address}&apikey=lkZBF65mgOsyD19im78YKbqaNcO4Xmsp3NsO5elGsv8`;
  const googleApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
  try {
    const hereResponse = await axios.get<IHereMapsLngLatResponse>(hereApiUrl);
    if (hereResponse.data?.items[0]?.position?.lat) {
      return hereResponse.data?.items[0]?.position;
    }
  } catch (hereError) {
    console.error('Erro na chamada da API da HERE:', hereError);
  }
  try {
    const googleResponse = await axios.get<IHereMapsLngLatResponse>(googleApiUrl);
    if (googleResponse.data?.results[0]?.geometry?.location) {
      return googleResponse.data?.results[0]?.geometry?.location;
    }
  } catch (googleError) {
    console.error('Erro na chamada da API do Google:', googleError);
  }
  return null;
};
