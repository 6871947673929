import React from 'react';

import { IFreightValues } from './types';
import * as S from './styled';

import { formatCurrency } from 'utils/format-numbers';
import { IUser } from 'domain/login';

interface CardResumeValuesProps {
  user: IUser | undefined;
  valuesPayable: IFreightValues;
}

export const CardResumeValues: React.FC<CardResumeValuesProps> = ({ user, valuesPayable }) => {
  const renderValuesPayable = React.useMemo(() => {
    if (valuesPayable) {
      return (
        <>
          <S.Text fontWeight>Frete Previsto</S.Text>
          <>
            <S.WrapperJustifyInformation>
              <S.Text>Frete Valor</S.Text>
              <S.Text>{`${formatCurrency(valuesPayable.value)}`}</S.Text>
            </S.WrapperJustifyInformation>

            <S.WrapperJustifyInformation>
              <S.Text>Ajudante</S.Text>
              <S.Text>{`${formatCurrency(valuesPayable.helperPrice)}`}</S.Text>
            </S.WrapperJustifyInformation>

            {user?.plan !== 'shipping-company' && (
              <S.WrapperJustifyInformation>
                <S.Text>Gris\Advalorem</S.Text>
                <S.Text>{`${formatCurrency(valuesPayable.grisAdvaloremPrice)}`}</S.Text>
              </S.WrapperJustifyInformation>
            )}

            <S.WrapperJustifyInformation>
              <S.Text>Pedágio</S.Text>
              <S.Text>{`${formatCurrency(valuesPayable.tollPrice)}`}</S.Text>
            </S.WrapperJustifyInformation>

            <S.WrapperJustifyInformation>
              <S.Text fontWeight>Frete Total</S.Text>
              <S.Text fontWeight>{`${formatCurrency(valuesPayable.totalFreight)}`}</S.Text>
            </S.WrapperJustifyInformation>

            {user?.plan !== 'shipping-company' && (
              <>
                <S.WrapperJustifyInformation>
                  <S.Text>ICMS</S.Text>
                  <S.Text>{`${formatCurrency(valuesPayable.icms)}`}</S.Text>
                </S.WrapperJustifyInformation>

                <S.WrapperJustifyInformation>
                  <S.Text>Outros Impostos</S.Text>
                  <S.Text>R$ 0,00</S.Text>{' '}
                </S.WrapperJustifyInformation>

                <S.WrapperJustifyInformation>
                  <S.Text fontWeight>Total do Serviço</S.Text>
                  <S.Text fontWeight>{`${formatCurrency(valuesPayable.totalService)}`}</S.Text>{' '}
                </S.WrapperJustifyInformation>
              </>
            )}
          </>
        </>
      );
    }
    return <S.Text italic>Não Informado</S.Text>;
  }, [valuesPayable]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>
        <S.Text fontWeight>Valores</S.Text>

        <S.TextContent>{renderValuesPayable}</S.TextContent>
      </S.LineSection>
    </S.Content>
  );
};
