import { useCallback } from 'react';
import { IClientDomain } from 'src-new/domains/client.domain';
import { getClientService } from 'src-new/services/get-client/get-client.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetClient = () => {
  return useCallback(async (clientId: number): Promise<IClientDomain | undefined> => {
    try {
      return getClientService(clientId);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter detalhes do parceiro.',
        type: 'error',
      });
    }
  }, []);
};
