/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { ScheduleList } from '../types';

import { formatTime } from 'utils-v2/converters/time';
import { convertHaulType } from 'utils-v2/converters/haul-type';
import { convertStatusCode } from 'utils/status-code';
import { ScheduleDomain } from 'domain/schedule';
import { IUser } from 'domain/login';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';

const mapLocation = (location: any) => ({
  name: location?.client?.fantasyName || location?.locality?.name,
  city: location?.client?.city || location?.locality?.city,
  uf: location?.client?.uf || location?.locality?.uf,
});

const formatDate = (date?: string) => (date ? moment(date).format('DD/MM/YYYY') : '-');

const mapCargoInformation = (scheduleDetails: any) => ({
  orderNumber: scheduleDetails.orderNumber,
  schedulingPassword: scheduleDetails.schedulingPassword,
  internalNumber: scheduleDetails.internalNumber,
  ocInternalNumber: scheduleDetails.ocInternalNumber,
  boardingNumber: scheduleDetails.boardingNumber,
});

const mapVehicleInfo = (scheduleCargo: any) => ({
  vehicleType: convertVehicleTypeTypeToName(scheduleCargo.vehicleType),
  vehicleCategory: convertVehicleCategoryTypeToName(scheduleCargo.vehicleCategoryType),
});

export const listMapper = (entity: ScheduleDomain, user?: IUser): ScheduleList => {
  const {
    id,
    scheduleClientOrigin,
    scheduleClientDestiny,
    scheduleDetails,
    statusCode,
    company,
    companyBuyer,
    scheduleCargo,
    typeSchedule,
  } = entity;

  const levyTime = formatTime(scheduleDetails.levyInitialDate) || formatTime(scheduleDetails.levyEndDate);

  const deliveryTime = formatTime(scheduleDetails.deliveryInitialDate) || formatTime(scheduleDetails.deliveryEndDate);

  return {
    id,
    typeSchedule,
    companyName: company.tradeName,
    companyBuyerName: companyBuyer?.tradeName || '',
    origin: mapLocation(scheduleClientOrigin),
    destiny: mapLocation(scheduleClientDestiny),
    levyDate: formatDate(scheduleDetails.levyInitialDate),
    levyTime: levyTime,
    deliveryDate: formatDate(scheduleDetails.deliveryEndDate),
    deliveryTime: deliveryTime,
    operatedBy: entity.operation !== 'SPOT' ? company.tradeName : 'Logshare',
    operationType: scheduleCargo.operationType,
    ...mapVehicleInfo(scheduleCargo),
    cargoInformation: mapCargoInformation(scheduleDetails),
    operation: convertHaulType(entity.operation).label,
    statusCode: convertStatusCode(statusCode, user),
    biddings: entity.biddings?.length,
    expirationTime: entity.expiration_time,
    companyBuyerId: entity.companyIdBuyer,
    cteIssuer: entity.cteIssuer,
  };
};
