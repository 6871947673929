import React, { useCallback, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { UserContext } from 'state/user-context';
import { listCompanyByName } from 'pages/choose-company/service/list-company-by-name/list-company-by-name.service';
import { SelectItem } from 'pages/choose-company/service/entities/logshare-access';
import { FilterFormView } from './filter-form-view.component';
import { IScheduleListParams } from 'src-new/pages/execution/pages/schedule/types/schedule-list-filters/schedule-list-filters.types';
import { convertStatusCodeOptions } from 'src-new/pages/execution/pages/schedule/utils/schedule-tab-name/schedule-tab-name.util';
import { IOption } from 'domain/global-inputs';
import { useGetShippingCompanySearch } from 'src-new/hooks/use-get-shipping-company-search/use-get-shipping-company-search.hook';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';

export const FilterForm: React.FC = () => {
  const { isAdmin } = useContext(UserContext);
  const { values } = useFormikContext<IScheduleListParams>();

  const getShippingCompanySearchHook = useGetShippingCompanySearch();

  const apiCallCompany = useCallback(async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await listCompanyByName(searchValue);
    return [];
  }, []);

  const apiCallShippingCompany = useCallback(
    async (searchValue: string): Promise<Array<ISelectItemDomain>> => {
      const shippingCompanyList = await getShippingCompanySearchHook(searchValue);

      if (!shippingCompanyList) return [];

      return shippingCompanyList;
    },
    [getShippingCompanySearchHook],
  );

  const statusCodeOptions = useMemo((): Array<IOption> => {
    return convertStatusCodeOptions(values.activeTabName);
  }, [values.activeTabName]);

  return (
    <FilterFormView
      isAdmin={isAdmin}
      statusCodeOptions={statusCodeOptions}
      apiCallCompany={apiCallCompany}
      apiCallShippingCompany={apiCallShippingCompany}
    />
  );
};
