import API from 'utils/API/API';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { IShippingCompanySearchResponse } from './get-shipping-company-search.types';
import { convertShippingResponseToSelectItemDomain } from './mappers/convert-shipping-response-to-select-item-domain/convert-shipping-response-to-select-item-domain.mapper';

export const getShippingCompanySearchService = async (searchValue: string): Promise<Array<ISelectItemDomain>> => {
  const { data } = await API.get<Array<IShippingCompanySearchResponse>>(`shipping-company-name?name=${searchValue}`);

  return data.map((shipping: { name: string; id: number }) =>
    convertShippingResponseToSelectItemDomain(shipping.name.toUpperCase(), shipping.id),
  );
};
