import React, { ReactElement, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { CheckCircle, LucideX } from 'lucide-react';
import theme from 'styles/theme';
import * as S from './import-modal-table-view.styled';
import { Loading } from 'src-new/components/loading/loading.component';
import { IScheduleImportModalDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-import-modal/schedule-import-modal.domain';

interface IImportModalTableViewProps {
  filesRow: Array<IScheduleImportModalDomain>;
  isLoading: boolean;
}

export const ImportModalTableView: React.FC<IImportModalTableViewProps> = ({ filesRow, isLoading }) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: <b>#</b> },
      { value: <b>Origem</b> },
      { value: <b>Destino</b> },
      { value: <b>Veículo</b> },
      { value: <b>Transportadora</b> },
      { value: <b>Coleta</b> },
      { value: <b>Entrega</b> },
      { value: <b>Status</b> },
    ];

    const columnsConfig = '0.2fr 1fr 1fr 0.8fr 1fr 0.8fr 0.7fr 0.5fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitle = useCallback((title: string): ReactElement => {
    return <S.Title>{title}</S.Title>;
  }, []);

  const renderStatus = useCallback((status: string): ReactElement => {
    return status === 'approved' ? (
      <CheckCircle size={15} strokeWidth={3} color={theme.colorsV2.green} />
    ) : (
      <LucideX size={15} strokeWidth={3} color={theme.colorsV2.red} />
    );
  }, []);

  const renderRows = useMemo((): Array<IRow> => {
    return filesRow.map((row) => {
      return {
        key: row.id,
        valuesColumns: [
          { value: row.id },
          { value: renderTitle(row.originName) },
          { value: renderTitle(row.destinyName) },
          { value: renderTitle(row.vehicleType) },
          { value: renderTitle(row.shippingCompanyName) },
          { value: renderTitle(row.levyDate) },
          { value: renderTitle(row.deliveryDate) },
          { value: renderStatus(row.status) },
        ],
      };
    });
  }, [filesRow, renderStatus, renderTitle]);

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    );
  }

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      rows={renderRows}
      height={'275px'}
    />
  );
};
