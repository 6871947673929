import React, { useMemo } from 'react';
import {
  ContainerErrorStyled,
  ContainerStyled,
  SubTextContainerStyled,
  TextContainerStyled,
} from './error-state-view.styled';
import AlertTriangleLarge from 'assets/icons/alert-triangle-large';

interface IErrorStateViewProps {
  title?: string;
  subTitle?: string;
}

export const ErrorStateView: React.FC<IErrorStateViewProps> = ({ title, subTitle }) => {
  const renderTitle = useMemo(() => {
    return title ? title : 'Erro ao carregar conteúdo';
  }, [title]);

  const renderSubTitle = useMemo(() => {
    return subTitle ? subTitle : 'Atualize a página para tentar novamente.';
  }, [subTitle]);

  return (
    <ContainerStyled>
      <ContainerErrorStyled>
        <AlertTriangleLarge color={'rgb(84, 84, 84)'} width="73.33" height="66.67" />
        <TextContainerStyled>{renderTitle}</TextContainerStyled>
        <SubTextContainerStyled>{renderSubTitle}</SubTextContainerStyled>
      </ContainerErrorStyled>
    </ContainerStyled>
  );
};
