import React, { useEffect, useState } from 'react';

import { TimerContainerStyled, TimerLabelStyled, TimerIconStyled } from './list.style';

import { TimerClockIcon } from 'assets/icons/timer-clock.icon';

interface TimerProps {
  deliveryDate: Date;
}

export const Timer: React.FC<TimerProps> = ({ deliveryDate }) => {
  const [remainingTime, setRemainingTime] = useState<number>(deliveryDate.getTime() - Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = deliveryDate.getTime() - Date.now();

      if (newRemainingTime <= 0) {
        setRemainingTime(0);
        clearInterval(intervalId);
      } else {
        setRemainingTime(newRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [deliveryDate]);

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
  const seconds = Math.floor((remainingTime / 1000) % 60);

  return (
    <TimerContainerStyled>
      {remainingTime > 0 ? (
        <TimerContainerStyled>
          <TimerIconStyled>
            <TimerClockIcon />
          </TimerIconStyled>
          {days > 0 && <TimerLabelStyled>{days}&nbsp;dias&nbsp;</TimerLabelStyled>}
          <TimerLabelStyled>{hours.toString().padStart(2, '0')}:</TimerLabelStyled>
          <TimerLabelStyled>{minutes.toString().padStart(2, '0')}:</TimerLabelStyled>
          <TimerLabelStyled>{seconds.toString().padStart(2, '0')}</TimerLabelStyled>
        </TimerContainerStyled>
      ) : (
        <div>Tempo expirado</div>
      )}
    </TimerContainerStyled>
  );
};
