import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const WrapperStyled = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: none;
    height: ${theme.sidebar.menu.height};
    padding: 0 15px;
    border-radius: 0px 48px 48px 0px;
    cursor: pointer;
    margin: 2px 0;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;
      font-family: ${theme.font.family};
      font-weight: 600;
      font-size: ${theme.font.sizes.small};
      color: #2e2e2e;
    }

    &:hover {
      transition-duration: 0.2s;

      span {
        color: ${theme.colors.blue};
        font-weight: ${theme.font.bold};
      }
    }
  `}
`;

export const WrapperSubTitleStyled = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: none;
    height: ${theme.sidebar.menu.height};
    padding: 0 20px;
    border-radius: 0px 48px 48px 0px;
    cursor: pointer;
    margin: 0px 0px 20px 0px;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      margin: 2px 0px 30px 0px;
    }

    &:hover {
      transition-duration: 0.2s;

      span {
        color: ${theme.colors.blue};
        font-weight: ${theme.font.bold};
      }
    }
  `}
`;

export const TextStyled = styled.span`
  white-space: nowrap;
`;

export const NavigateToStyled = styled(NavLink)`
  ${({ theme }) => css`
    height: 100%;
    font-weight: normal;
    font-size: ${theme.font.sizes.small};
    &[aria-current] {
      color: ${theme.colors.blue};
      font-weight: bold;
    }
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const NewFreightStyled = styled.div`
  ${({ theme }) => css`
    height: 100%;
    color: #2e2e2e;
    font-weight: normal;
    font-size: ${theme.font.sizes.small};
    font-weight: 600;
    &[aria-current] {
      color: ${theme.colors.blue};
      font-weight: bold;
    }
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const NavigateToStyledDisabled = styled.span`
  ${({ theme }) => css`
    height: 100%;
    font-weight: normal;
    font-size: ${theme.font.sizes.small};
    &[aria-current] {
      color: ${theme.colors.blue};
      font-weight: bold;
    }
    display: flex;
    align-items: center;
    width: 100%;
    opacity: 0.5;
    color: gray;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const ContainerSubTitleStyled = styled.div``;

export const SubTitleNavigateToStyled = styled(NavLink)`
  ${({ theme }) => css`
    height: 100%;
    color: #000;
    font-weight: normal;
    font-size: ${({ theme }) => theme.font.sizes.small};
    &[aria-current] {
      color: ${theme.colors.blue};
      font-weight: bold;
    }
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const SubTitleTextStyled = styled.span``;

export const ContainerIconStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const TraceStyled = styled.hr`
  ${({ theme }) => css`
    border: 0.5px solid ${theme.colors.gray};
  `}
  width: 80%;
  margin: 0 auto;
`;

export const SubTitleStyled = styled.div`
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: 8px;
  font-style: italic;
  color: #b5b3b3;
`;
