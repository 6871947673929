import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { showMessageFormatted } from '../../../../../../../../../utils/message/show-message-formatted/show-message-formatted';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IListPage } from 'src-new/domains/list-page.domain';
import { getStabilizedMatchesKanbanListService } from '../../services/get-stabilized-matches-kanban-list/get-stabilized-matches-kanban-list.service';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { convertMatchesKanbanListResponseToDomain } from '../../mappers/convert-matches-kanban-list-response-to-domain/convert-matches-kanban-list-response-to-domain.mapper';

export const useGetStabilizedMatchesKanbanList = () => {
  const { stabilizedMatchesKanbanList, matchesKanbanList } = useContext(MatchesContext);
  const {
    setStabilizedMatchesKanbanListRequest,
    setStabilizedMatchesKanbanListSuccess,
    setStabilizedMatchesKanbanListError,
  } = stabilizedMatchesKanbanList;

  const { data } = useQuery<IListPage<IMatchesKanbanListResponse>>({
    queryKey: ['stabilized-matches-kanban-list',  matchesKanbanList.matchesListFiltersProps.filters],
    queryFn: () => {
      setStabilizedMatchesKanbanListRequest();

      return getStabilizedMatchesKanbanListService( matchesKanbanList.matchesListFiltersProps.filters);
    },
    onSuccess: (data) => {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);

      setStabilizedMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    },
    onError: (error) => {
      setStabilizedMatchesKanbanListError();

      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      const kanbanValues = data.items.map(convertMatchesKanbanListResponseToDomain);
      setStabilizedMatchesKanbanListSuccess({ items: kanbanValues, totalItems: data.meta.totalItems });
    }
  }, [data, setStabilizedMatchesKanbanListSuccess]);
};
