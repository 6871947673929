import React from 'react';

import VehicleUpdateForm from '../vehicle-update-form/vehicle-update-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const VehicleUpdateBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="vehicle-register-body" breadcrumbList={['Cadastro', 'Veículos']} hasBackButton />
      <Title title="Atualização Cadastral" hasTrace isForm />
      <VehicleUpdateForm />
    </React.Fragment>
  );
};

export default VehicleUpdateBodyView;
