import React from 'react';

export const DropdownButtonIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none">
      <path
        d="M1.60517 3.60518C2.49168 3.60518 3.21034 2.88652 3.21034 2.00001C3.21034 1.1135 2.49168 0.394836 1.60517 0.394836C0.71866 0.394836 0 1.1135 0 2.00001C0 2.88652 0.71866 3.60518 1.60517 3.60518Z"
        fill="#7F7F7F"
      />
      <path
        d="M6.00019 3.60529C6.8867 3.60529 7.60536 2.88663 7.60536 2.00012C7.60536 1.1136 6.8867 0.394943 6.00019 0.394943C5.11368 0.394943 4.39502 1.1136 4.39502 2.00012C4.39502 2.88663 5.11368 3.60529 6.00019 3.60529Z"
        fill="#7F7F7F"
      />
      <path
        d="M10.3947 3.60529C11.2812 3.60529 11.9999 2.88663 11.9999 2.00012C11.9999 1.1136 11.2812 0.394943 10.3947 0.394943C9.50821 0.394943 8.78955 1.1136 8.78955 2.00012C8.78955 2.88663 9.50821 3.60529 10.3947 3.60529Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
