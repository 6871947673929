import React, { FC, useCallback, useContext, useMemo } from 'react';
import { IOrdering } from 'src-new/components/table/table.types';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { useUpdateFavoriteMatch } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/hooks/use-update-favorite-match/use-update-favorite-match.hook';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { PurchaseOfferModalMatchTableView } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/components/purchase-offer-modal-match-table-tabs/components/purchase-offer-modal-match-table/purchase-offer-modal-match-table-view.component';
import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';
import { useOpenProposalChat } from 'src-new/hooks/chat/use-open-proposal-chat/use-open-proposal-chat.hook';

export const PurchaseOfferModalMatchTable: FC = () => {
  const { purchaseOfferModals, purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const openProposalChat = useOpenProposalChat();
  const { favoriteMatchesKanbanList } = useContext(MatchesContext);
  const { setModalOpen, modalType } = purchaseOfferModals;
  const { statusRequest, purchaseOfferMatchListSelected, purchaseOfferMatchProposal } = purchaseOfferMatchList;
  const { setPurchaseOfferMatchListSelectedRequest, setPurchaseOfferMatchListSelectedSuccess } =
    purchaseOfferMatchListSelected;

  const updateFavoriteMatchHook = useUpdateFavoriteMatch();

  const handleFavoriteMatch = useCallback(
    (matchId: number, isFavorite: boolean) => updateFavoriteMatchHook(matchId, isFavorite),
    [updateFavoriteMatchHook],
  );

  const handleOpenModalMatchBuy = useCallback(
    (matchSelected: IPurchaseOfferMatchListDomain) => {
      setPurchaseOfferMatchListSelectedRequest();
      setModalOpen({ modalType, secondModalType: 'purchase_offer_match_buy' });
      setPurchaseOfferMatchListSelectedSuccess(matchSelected);
    },
    [setPurchaseOfferMatchListSelectedRequest, setModalOpen, modalType, setPurchaseOfferMatchListSelectedSuccess],
  );

  const handleOpenProposalChat = useCallback(
    async (tariffId: string, saleOfferId: string, matchId: string) => {
      const saleOffer = purchaseOfferMatchList.purchaseOfferMatchList.filter(
        (saleOffer) => saleOffer.matchOfferId === Number(matchId),
      )[0];
      await openProposalChat({
        purchaseOfferId: purchaseOfferMatchProposal.proposal.id.toString(),
        saleTariffOfferId: tariffId,
        saleOfferId,
        matchId,
        buyOfferCompanyId: purchaseOfferMatchProposal.proposal.company.id.toString(),
        saleOfferCompanyId: saleOffer.company.id.toString(),
        pageType: 'PURCHASE_OFFER',
      });
    },
    [
      openProposalChat,
      purchaseOfferMatchList.purchaseOfferMatchList,
      purchaseOfferMatchProposal.proposal.company.id,
      purchaseOfferMatchProposal.proposal.id,
    ],
  );

  const getPurchaseOffer = useMemo((): Array<IPurchaseOfferMatchListDomain> => {
    return purchaseOfferMatchList.purchaseOfferMatchList.map((matchList) => {
      const favoriteMatchList = favoriteMatchesKanbanList.favoriteMatchesKanbanList.items.find(
        (favoriteMatch) => favoriteMatch.matchOffersRoutesId === matchList.matchOfferId,
      );

      if (favoriteMatchList) {
        return { ...matchList, isFavorite: favoriteMatchList.isFavorite };
      }

      return { ...matchList, isFavorite: false };
    });
  }, [favoriteMatchesKanbanList.favoriteMatchesKanbanList.items, purchaseOfferMatchList.purchaseOfferMatchList]);

  const isLoading = useMemo(() => statusRequest === 'PROCESSING', [statusRequest]);

  const orderingColumns = useMemo((): IOrdering => {
    return {
      columnName: 'ID',
      order: 'DESC',
      handleClick: () => undefined,
    };
  }, []);

  return (
    <PurchaseOfferModalMatchTableView
      purchaseOfferMatch={getPurchaseOffer}
      orderingColumns={orderingColumns}
      isLoading={isLoading}
      handleOpenModalMatchBuy={handleOpenModalMatchBuy}
      handleFavoriteMatch={handleFavoriteMatch}
      handleOpenProposalChat={handleOpenProposalChat}
    />
  );
};
