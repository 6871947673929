import React from 'react';
import { ExecutionTimeRecording } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/components/execution-time-recording/execution-time-recording.component';
import * as S from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-tabs/components/schedule-details-execution/schedule-details-execution-view.styled';
import { ExecutionDocuments } from './components/execution-documents/execution-documents.component';

export const ScheduleDetailsExecutionView: React.FC = () => {
  return (
    <S.Wrapper>
      <S.SectionStyled>
        <ExecutionTimeRecording />
      </S.SectionStyled>
      <S.SectionStyled>
        <ExecutionDocuments />
      </S.SectionStyled>
    </S.Wrapper>
  );
};
