import React from 'react';
import * as S from './matches-kanban-view.styled';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { KanbanSection } from './components/matches-section/matches-section.component';
import { IKanbanListDetails } from './matches-kanban.types';

interface IMatchesKanbanViewProps {
  kanbanListDetails: IKanbanListDetails;
  sidebarSize: number;
  isSubMenuOpen: boolean;
}

export const MatchesKanbanView: React.FC<IMatchesKanbanViewProps> = ({
  kanbanListDetails,
  sidebarSize,
  isSubMenuOpen,
}) => {
  return (
    <S.ContainerCards sidebarSize={sidebarSize} isSubMenuOpen={isSubMenuOpen}>
      <KanbanSection
        title="Matches em Negociação"
        color={ThemeColorsV2Type.Blue}
        kanbanListDetails={kanbanListDetails.inNegotiationMatches}
      />
      <KanbanSection
        title="Matches Favoritados"
        color={ThemeColorsV2Type.Blue50}
        kanbanListDetails={kanbanListDetails.favoriteMatches}
      />
      <KanbanSection
        title="Matches Operados"
        color={ThemeColorsV2Type.Aqua50}
        kanbanListDetails={kanbanListDetails.operatedMatches}
      />
      <KanbanSection
        title="Matches Estabilizados"
        color={ThemeColorsV2Type.Aqua}
        kanbanListDetails={kanbanListDetails.stabilizedMatches}
      />
      <KanbanSection
        title="Matches Inativos"
        color={ThemeColorsV2Type.Red}
        kanbanListDetails={kanbanListDetails.inactiveMatches}
      />
    </S.ContainerCards>
  );
};
