import {
  userTermsConditions,
  lastTermsConditions,
  termsConditionsRegister,
} from '../services/terms-conditions.service';

import { IModalTerms, ILastTerms } from 'pages/login/services/entities/login.entity';

export const useUserTermsConditionsHook = () => {
  return async (username: string) => {
    return await userTermsConditions(username);
  };
};

export const useTermsConditionsHook = () => {
  return async (): Promise<ILastTerms> => {
    return await lastTermsConditions();
  };
};

export const useTermsConditionsRegisterHook = () => {
  return async (values: IModalTerms) => {
    await termsConditionsRegister(values);
  };
};
