import { useCallback, useContext } from 'react';

import { vehicleListService } from '../../services/vehicle-list/vehicle-list.service';
import { VehiclesContext } from '../../contexts/table-states/vehicles.context';

import { Hook } from './use-vehicle-list.types';

const useVehicleList: Hook = () => {
  const { setVehiclesRequest, setVehiclesSuccess, setVehiclesError } = useContext(VehiclesContext);

  return useCallback(
    async ({ searchValue, currentPage, status, order, column }) => {
      setVehiclesRequest();

      try {
        const response = await vehicleListService({
          search: searchValue,
          page: currentPage,
          status,
          order,
          column,
        });
        setVehiclesSuccess(response);
      } catch (error) {
        setVehiclesError('Ocorreu um erro ao buscar os veículos, tente novamente!');
      }
    },
    [setVehiclesError, setVehiclesRequest, setVehiclesSuccess],
  );
};

export { useVehicleList };
