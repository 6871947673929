import React from 'react';

import VehicleRegisterForm from '../vehicle-register-form/vehicle-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const VehicleRegisterBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="vehicle" breadcrumbList={['Cadastro', 'Veículos']} hasBackButton />
      <Title title="Novo Veículo" hasTrace isForm />
      <VehicleRegisterForm />
    </React.Fragment>
  );
};

export default VehicleRegisterBodyView;
