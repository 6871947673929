/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import StatusView from './status-circle-view';

type IStatusCircle = {
  bgColor: string;
  isSquare?: boolean;
  title?: any;
};

const Status: React.FC<IStatusCircle> = ({ bgColor, isSquare, title }) => {
  return <StatusView bgColor={bgColor} isSquare={isSquare} title={title} />;
};

export default Status;
