import styled, { css } from 'styled-components';

interface IStyledProps {
  color: 'green' | 'proYellow' | 'darkGray';
}

export const Wrapper = styled.section<IStyledProps>`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;

    background-color: ${theme.colorsV2[color]};
    color: white;

    height: 22px;
    width: fit-content;
    border-radius: 5px;
    padding: 2px 7px;
  `}
`;
