import styled, { css } from 'styled-components';
import { Form } from 'formik';

type WrapperFooterPageProps = { sidebarIsHovered?: number };

export const WrapperForm = styled(Form)`
  display: flex;
  gap: 10px;
  align-items: baseline;
  height: 100%;
  margin-bottom: 110px;
`;

export const WrapperFooterPage = styled.div<WrapperFooterPageProps>`
  ${({ sidebarIsHovered }) => css`
    position: fixed;
    bottom: 0;
    width: calc(100% - ${sidebarIsHovered === 0 ? '120' : sidebarIsHovered}px);
    padding-bottom: 20px;
    background-color: white;
  `};
`;
