import { useLocation } from 'react-router-dom';
import React from 'react';

import { HeaderMenuStyled, SalutationStyled, UsernameStyled } from './header.styled';

import { IMyAccount } from 'domain/my-account';
import { ICompany } from 'domain/company';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import InputCircleUpload from 'components/input-circle-upload-image/input-circle-upload-image';

type HeaderProps = {
  updateLayout: boolean;
  showUserName: boolean;
  companyValues: ICompany;
  myAccountValues: IMyAccount;
  handleGoBack: () => void;
};

const HeaderView: React.FC<HeaderProps> = ({ updateLayout, companyValues, myAccountValues, handleGoBack }) => {
  const { pathname } = useLocation();
  const { currentSelectedMenu } = React.useContext(SidebarContext);

  if (pathname === '/dashboard') {
    return (
      <HeaderMenuStyled updateLayout={updateLayout}>
        <InputCircleUpload urlImage={companyValues.fileKey} onClick={handleGoBack} />
      </HeaderMenuStyled>
    );
  }
  return (
    <HeaderMenuStyled updateLayout={updateLayout}>
      <InputCircleUpload urlImage={companyValues.fileKey} onClick={handleGoBack} />
      <SalutationStyled disabled={!currentSelectedMenu} updateLayout={updateLayout}>
        Olá,
        <UsernameStyled>{myAccountValues.name.toUpperCase().split(' ', 1)}</UsernameStyled>
      </SalutationStyled>
    </HeaderMenuStyled>
  );
};

export default HeaderView;
