import React from 'react';

export const SearchFilterIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M0.325195 7.55469C0.325195 3.6084 3.5332 0.400391 7.47949 0.400391C11.4258 0.400391 14.6338 3.6084 14.6338 7.55469C14.6338 9.09277 14.1328 10.5166 13.2979 11.668L17.3145 15.6934C17.5518 15.9395 17.6748 16.2646 17.6748 16.6162C17.6748 17.3457 17.1387 17.9082 16.3916 17.9082C16.0488 17.9082 15.7061 17.7939 15.46 17.5391L11.417 13.4961C10.292 14.2607 8.94727 14.709 7.47949 14.709C3.5332 14.709 0.325195 11.501 0.325195 7.55469ZM2.18848 7.55469C2.18848 10.4727 4.55273 12.8457 7.47949 12.8457C10.3975 12.8457 12.7705 10.4727 12.7705 7.55469C12.7705 4.63672 10.3975 2.26367 7.47949 2.26367C4.55273 2.26367 2.18848 4.63672 2.18848 7.55469Z"
        fill="#3C3C43"
        fillOpacity="0.6"
      />
    </svg>
  );
};
