import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { Button } from 'logshare-ui-kit';
import DownloadIcon from '@mui/icons-material/Download';
import {
  ButtonContainer,
  ContainerStyled,
  ContentStyled,
  DescriptionStyled,
  InputContainer,
  LeftButton,
  RightButtons,
  TitleStyled,
  WrapperTextsStyled,
} from './modal-upload-spreadsheet-view.styled';
import ExelUploadIcon from 'assets/icons/excel-upload.ivon';

interface IFileNameProps {
  fileName: string;
  setFileName: Dispatch<SetStateAction<string>>;
}

interface IModalUploadSpreadsheetViewProps {
  title: string;
  description: string;
  fileNameProps: IFileNameProps;
  isLoading?: boolean;
  handleCancel: () => void;
  handleDownload?: () => void;
  handleInput: () => void;
  handleSendUpload: () => void;
  fileInputRef: RefObject<HTMLInputElement>;
}

export const ModalUploadSpreadsheetView: React.FC<IModalUploadSpreadsheetViewProps> = ({
  title,
  description,
  isLoading = false,
  handleCancel,
  handleDownload,
  fileNameProps,
  handleInput,
  handleSendUpload,
  fileInputRef,
}) => {
  return (
    <ContainerStyled>
      <ContentStyled>
        <WrapperTextsStyled>
          <TitleStyled>{title}</TitleStyled>
          {fileNameProps.fileName ? (
            <DescriptionStyled>Arquivo selecionado: {fileNameProps.fileName}</DescriptionStyled>
          ) : (
            <DescriptionStyled>{description}</DescriptionStyled>
          )}
        </WrapperTextsStyled>
        <InputContainer>
          <LeftButton>
            <Button
              label="Escolher arquivo"
              onPress={handleInput}
              color="aqua"
              size="lg"
              variant="solid"
              RightIcon={<ExelUploadIcon width="18" />}
            />
          </LeftButton>
          <RightButtons>
            <Button
              label="Baixar template"
              onPress={handleDownload}
              color="green"
              size="lg"
              variant="solid"
              RightIcon={<DownloadIcon sx={{ width: '18px' }} />}
            />
          </RightButtons>
          <input
            ref={fileInputRef}
            type="file"
            id="file"
            accept=".xlsx, .xls"
            name="file"
            style={{ display: 'none' }}
            onChange={(event: any) => fileNameProps.setFileName(event.target.files?.[0].name)}
          />
        </InputContainer>
        <ButtonContainer>
          <Button label="Cancelar" color="white" onPress={handleCancel} size="md" variant="ghost" />
          <Button
            label="Enviar"
            color="blue"
            onPress={handleSendUpload}
            size="md"
            variant="solid"
            isLoading={isLoading}
          />
        </ButtonContainer>
      </ContentStyled>
    </ContainerStyled>
  );
};
