import styled, { css } from 'styled-components';
import logo from 'src/assets/microsoft.png';
import { Formik } from 'formik';

export const FormikStyled = styled(Formik)``;

export const FormStyled = styled.form``;

type SectionStyledProps = { name: string };
export const SectionStyled = styled.section<SectionStyledProps>``;

export const WrapperSectionResetPasswordStyled = styled.div``;

export const LoginMicrisoftButtonContainerStyled = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 15px;
  justify-items: center;

  button {
    width: 95%;
  }
`;

export const LoginMicrosoftContainerStyled = styled.div`
  width: 65%;
`;

export const LoginImageStyled = styled.div``;

export const TextContainerStyled = styled.div`
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const EmailInformationStyled = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    text-align: left;

    strong {
      font-weight: ${theme.font.medium};
    }

    @media (max-width: 600px) {
      font-weight: ${theme.font.medium};
      font-size: ${theme.font.sizes.small};
      margin-bottom: 10px;
    }
  `}
`;

export const LoginMocrosoftFormStyled = styled.form`
  & > div {
    margin-bottom: 15px;
  }
`;

export const ButtonContainerStyled = styled.div`
  ${({ theme }) => css`
    margin-top: 15px;

    button {
      width: 100%;
      background-color: ${theme.colors.blue};
    }
  `}
`;

export const WrapperSectionLoginLogoStyled = styled.div`
 display: flex;
 margin-bottom: 10px;
`

export const MicrosoftIcon = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${logo});
  background-size: cover;
  margin-right: 5px;
`;