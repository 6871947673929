import { IMatchesKanbanDetails } from '../../domains/matches-kanban-list/matches-kanban-list.domain';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertFreightTypeToDomain } from 'src-new/utils/convert-freight-type/convert-freight-type.util';

export const convertMatchesKanbanToPurchaseOffer = (selectedMatch: IMatchesKanbanDetails): IBackhaulPurchase => {
  const invoicedBy =
    selectedMatch.invoicedBy && selectedMatch.invoicedBy === 'transportadora' ? 'EMBARCADOR' : 'LOGSHARE';

  return {
    saleOffer: {
      ...selectedMatch,
      matchOfferId: selectedMatch.matchOffersRoutesId,
      destination: selectedMatch.destiny,
      typeSchedule: convertFreightTypeToDomain(selectedMatch.scheduleType ?? 'FTL'),
      operationType: convertOperationTypeToDomain(selectedMatch.operationType ?? ''),
      haulType: 'BACKHAUL EXTERNO',
      backhaulPartner: selectedMatch.isBackhaulPartner,
      co2Economy: selectedMatch.co2Economy ?? 0,
      capacity: {
        ...selectedMatch.capacity,
        pallets: selectedMatch.capacity.pallet,
      },
      values: {
        freightValue: selectedMatch.freightValue,
        logshareValue: selectedMatch.freightValue,
      },
      invoicedBy,
    },
    purchaseOffer: {
      ...selectedMatch,
      id: selectedMatch.purchaseOfferId,
      origin: {
        ...selectedMatch.origin,
        name: `${selectedMatch.origin.name.toUpperCase()} - ${selectedMatch.origin.city.toUpperCase()} - ${selectedMatch.origin.uf.toUpperCase()}`,
      },
      destination: {
        ...selectedMatch.destiny,
        name: `${selectedMatch.destiny.name.toUpperCase()} - ${selectedMatch.destiny.city.toUpperCase()} - ${selectedMatch.destiny.uf.toUpperCase()}`,
      },
      capacity: {
        ...selectedMatch.capacity,
        pallets: selectedMatch.capacity.pallet,
      },
      operationType: convertOperationTypeToDomain(selectedMatch.operationType ?? ''),
      cargoType: convertFreightTypeToDomain(selectedMatch.scheduleType ?? 'FTL'),
      initialDate: '',
      values: {
        freightValue: selectedMatch.freightValue,
        logshareValue: selectedMatch.freightValue,
      },
      totalMatchOffers: 0,
    },
  };
};
