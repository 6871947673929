import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { WeighingContextProvider } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.context';
import { WeighingRegisterPageBody } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/components/weighing-register-page-body/weighing-register-page-body.component';

export const WeighingFormRegisterPage: FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <WeighingContextProvider>
        <WeighingRegisterPageBody />
      </WeighingContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
