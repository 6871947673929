import { CurrencyInputProps } from 'react-currency-input-field';
import React from 'react';

import { InputCurrencyView, InputFieldProps } from './input-currency-view';

export interface InputCurrencyFieldProps extends InputFieldProps, CurrencyInputProps {}

export const InputCurrency: React.FC<InputCurrencyFieldProps> = ({
  id,
  name,
  value,
  type,
  placeholder,
  className,
  label,
  reactNodePosition,
  disabled,
  hasError,
  errorMessage,
  passwordVisible,
  upperCase,
  decimalSeparator,
  decimalScale,
  decimalsLimit,
  groupSeparator,
  allowDecimals,
  changePasswordVisible,
  onValueChange,
  onChange = () => null,
  onBlur = () => null,
  suffix,
  prefix,
}) => {
  return (
    <InputCurrencyView
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      suffix={suffix}
      prefix={prefix}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      decimalsLimit={decimalsLimit}
      groupSeparator={groupSeparator}
      placeholder={placeholder}
      reactNodePosition={reactNodePosition}
      disabled={disabled}
      allowDecimals={allowDecimals}
      hasError={hasError}
      errorMessage={errorMessage}
      passwordVisible={passwordVisible}
      upperCase={upperCase}
      onChange={onChange}
      onBlur={onBlur}
      changePasswordVisible={changePasswordVisible}
      className={className}
      onValueChange={onValueChange}
    />
  );
};
