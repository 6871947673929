export enum MapTypeId {
  HYBRID = 'hybrid',
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
}

export interface LatLngLocation {
  lat: number;
  lng: number;
}

export interface IGoogleMapsLocation extends LatLngLocation {
  speed?: number;
  timestamp?: number;
  address?: string;
  ignition?: number;
}

export interface IGoogleMapsDirections {
  primaryDirection?: IMapsDirection;
  secondaryDirection?: IMapsDirection;
  tertiaryDirection?: IMapsDirection;
}

interface IMapsIconCommon {
  hasIconGroup: boolean;
}

export interface IMapsDefaultIcons extends IMapsIconCommon {
  hasIconGroup: false;
}

export interface IMapsIconCustomIcons extends IMapsIconCommon {
  hasIconGroup: true;
  originIcon: string;
  destinationIcon: string;
  completedTrip?: boolean;
}

export type IMapsIconProps = IMapsDefaultIcons | IMapsIconCustomIcons;

export interface IMapsDirection {
  origin: IGoogleMapsLocation;
  destination: IGoogleMapsLocation;
  routePositions?: Array<IGoogleMapsLocation>;
  routeColor: string;
  iconProps: IMapsIconProps;
}

export interface IMapsProps {
  mapType: MapTypeId;
  defaultCenter?: LatLngLocation;
}
