/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import dayjs from 'dayjs';

import * as S from './styled';

import { convertScheduleTypeColor } from 'utils-v2/converters/status-code';
import { convertHaulType } from 'utils-v2/converters/haul-type';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';
import { createTag } from 'utils/create-tags';
import theme from 'styles/theme';
import { quotationScheduleLTLService } from 'pages-v2/schedule-ltl/services/quotation';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';
import { ScheduleType } from 'domain-v2/inputs/schedule-type';
import { Operation } from 'domain-v2/inputs/operation';
import { IUser } from 'domain/login';
import pdf from 'assets/pdf.svg';
interface CardResumeHeaderProps {
  scheduleType: ScheduleType;
  operationType: Operation;
  values: ScheduleLTLRegisterInput;
  hideQuotation: boolean;
  user: IUser | undefined;
}

type Color = keyof typeof theme.colorsV2;

export const CardResumeHeader: React.FC<CardResumeHeaderProps> = ({
  scheduleType,
  operationType,
  values,
  hideQuotation,
  user,
}) => {
  const renderTags = useMemo(() => {
    return (
      <S.WrapperTags>
        <Tag
          label={convertHaulType(operationType).label}
          color={convertHaulType(operationType).color}
          variant="solid"
          display="inline-flex"
          size="lg"
        />
        <Tag
          label={scheduleType}
          color={convertScheduleTypeColor(scheduleType)}
          variant="solid"
          display="inline-flex"
          size="lg"
        />
      </S.WrapperTags>
    );
  }, [operationType, scheduleType]);

  const tagsPdf = useMemo(() => {
    const convertHaulTypeResult = convertHaulType(operationType);

    const haulTypeTags = [createTag(convertHaulTypeResult.label, convertHaulTypeResult.color as Color)];

    const scheduleTypeTags = [createTag(scheduleType, convertScheduleTypeColor(scheduleType) as Color)];

    return [...haulTypeTags, ...scheduleTypeTags];
  }, [operationType, scheduleType]);

  const fileNamePdf = useMemo(() => {
    const currentDate = new Date();
    const formattedDate = dayjs(currentDate).format('DD/MM/YYYY HH:mm:ss');

    return `Logshare_cotacao_ltl_${user?.companyLogin.toLowerCase()}_${formattedDate}`;
  }, [user]);

  const handleQuotation = useCallback(async () => {
    try {
      if (!hideQuotation) return;

      const pdf = await quotationScheduleLTLService(values, tagsPdf);

      DownloadFile(pdf, 'pdf', fileNamePdf);
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [values, hideQuotation]);

  return (
    <S.Content>
      <S.HeaderSection>
        <S.HeaderTitleSection>
          <S.Text blueColor fontWeight size="18px">
            Resumo
          </S.Text>
        </S.HeaderTitleSection>

        <S.HeaderButtons>
          <S.CotationContainer>
            <S.Text size="11px" fontWeight textColor={!hideQuotation}>
              Ver Cotação
            </S.Text>

            <S.CotationImageContainer onClick={handleQuotation} hideQuotation={hideQuotation}>
              <S.CotationImage src={pdf} hideQuotation={hideQuotation} />
            </S.CotationImageContainer>
          </S.CotationContainer>
        </S.HeaderButtons>
      </S.HeaderSection>

      <S.TagContainer>{renderTags}</S.TagContainer>
    </S.Content>
  );
};
