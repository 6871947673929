import React from 'react';
import { FormikProps, withFormik } from 'formik';

import { FormStyled, LineSectionStyled, SectionStyled } from './santos-port-details-form.styled';

import { IPortSchedule } from 'domain/santos-port';
import SectionDivider from 'components/section-divider/section-divider';
import InputFieldCNPJ from 'components/input-field-cnpj/input-field-cnpj';
import InputFieldCep from 'components/input-field-cep/input-field-cep';
import InputField from 'components/input-field/input-field';

interface PortSantosFormView {
  santosPortValues: IPortSchedule;
}

const PortSantosDetailFormView: React.FC<PortSantosFormView> = ({ santosPortValues }) => {
  const InnerForm = (props: FormikProps<IPortSchedule>) => {
    const { values, handleChange } = props;

    return (
      <FormStyled>
        <SectionDivider label="Informações da Empresa" />
        <SectionStyled name="Motoristas">
          <LineSectionStyled columns="2fr 1fr">
            <InputFieldCep
              label="Empresa"
              id="companyName"
              name="companyName"
              type="text"
              onChange={handleChange}
              value={values.client?.companyName}
              disabled
            />
            <InputFieldCNPJ
              label="CNPJ"
              id="cnpj"
              name="cnpj"
              type="text"
              onChange={handleChange}
              value={values.client?.cnpj}
              disabled
            />
          </LineSectionStyled>

          <LineSectionStyled columns="2fr 1fr">
            <InputFieldCep label="Proprietário" id="Proprietário" name="Proprietário" type="text" value={''} disabled />
            <InputField label="Telefone" id="Telefone" name="Telefone" type="text" value={''} disabled />
          </LineSectionStyled>

          <LineSectionStyled columns="0.5fr 0.8fr 2.3fr 0.4fr">
            <InputFieldCep
              label="CEP"
              id="cep"
              name="cep"
              type="text"
              value={values.client?.cep}
              onChange={handleChange}
              disabled
            />
            <InputField
              label="Bairro"
              id="district"
              name="district"
              type="text"
              onChange={handleChange}
              value={values.client?.district}
              disabled
            />
            <InputField
              label="Cidade"
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={values.client?.city}
              disabled
            />
            <InputField
              label="UF"
              id="sp"
              name="sp"
              type="text"
              value={values.client?.sp}
              onChange={handleChange}
              disabled
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1.5fr 0.4fr 1.1fr">
            <InputField
              label="Endereço"
              id="address"
              name="address"
              type="text"
              onChange={handleChange}
              value={values.client?.address}
              disabled
            />

            <InputField
              label="Nº"
              id="addressNumber"
              name="addressNumber"
              type="text"
              onChange={handleChange}
              value={values.client?.addressNumber}
              disabled
            />
            <InputField
              label="Complemento"
              id="complement"
              name="complement"
              type="text"
              onChange={handleChange}
              value={values.client?.complement}
              disabled
            />
          </LineSectionStyled>
        </SectionStyled>

        <SectionDivider label="Informações do Veículo" />
        <SectionStyled name="Motoristas">
          <LineSectionStyled columns="2fr 1fr">
            <InputFieldCep
              label="Nome"
              id="nome"
              name="nome"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.nome}
              disabled
            />
            <InputFieldCNPJ
              label="Placa"
              id="placa"
              name="placa"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.placa}
              disabled
            />
          </LineSectionStyled>

          <LineSectionStyled columns="0.5fr 0.8fr 2.3fr">
            <InputFieldCep
              label="Cor"
              id="cor"
              name="cor"
              type="text"
              value={values.vehicle?.cor}
              onChange={handleChange}
              disabled
            />
            <InputField
              label="Quantidade de Eixos"
              id="quantidadeEixos"
              name="quantidadeEixos"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.quantidadeEixos}
              disabled
            />
            <InputField
              label="Tipo de Veículo"
              id="tipo"
              name="tipo"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.tipo}
              disabled
            />
          </LineSectionStyled>

          <LineSectionStyled columns="0.3fr 1.5fr">
            <InputField
              label="Código do Município"
              id="codigoMunicipio"
              name="codigoMunicipio"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.codigoMunicipio}
              disabled
            />

            <InputField
              label="Município"
              id="municipio"
              name="municipio"
              type="text"
              onChange={handleChange}
              value={values.vehicle?.municipio}
              disabled
            />
          </LineSectionStyled>
        </SectionStyled>
      </FormStyled>
    );
  };

  const PortSantosForm = withFormik<IPortSchedule, IPortSchedule>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: () => null,
  })(InnerForm);

  return <PortSantosForm {...santosPortValues} />;
};

export default PortSantosDetailFormView;
