import { palletFormRegisterInitialValues } from '../../components/pallet-form/constants/pallet-form-register-initial-values.constants';
import { IFinancialContext } from './types/financial.types';

export const financialInitialState: IFinancialContext = {
  palletForm: {
    getPalletDetails: {
      statusRequest: 'INITIAL',
      palletDetails: palletFormRegisterInitialValues,
      setGetPalletDetailsRequest: () => undefined,
      setGetPalletDetailsSuccess: () => undefined,
      setGetPalletDetailsError: () => undefined,
    },
    statusRequest: 'INITIAL',
    setPalletFormSubmit: () => undefined,
    setPalletFormSuccess: () => undefined,
    setPalletFormError: () => undefined,
    setPalletFormReset: () => undefined,
  },
  setFinancialResetContext: () => undefined,
};
