import styled, { keyframes } from 'styled-components';

const animate = keyframes`
    0%{
        transform: translateX(100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const ContainerBarChart = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 15px;

  animation: ${animate} 0.5s;
`;

export const ContainerHeaderStyled = styled.div`
  display: flex;
  padding: 0px 0px 15px 0px;
  justify-content: space-between;
`;

export const ContainerStatusStyled = styled.div`
  display: flex;
  padding: 6px 0px;
`;

export const TitleStyled = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
`;

export const TraceStyled = styled.hr`
  position: relative;
  top: 190px;
  border: 1px solid #d9d9d9;
  width: 100%;
`;
