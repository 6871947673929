import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type InputTextProps = {
  iconPosition: 'left' | 'right';
  disabled: boolean;
  hasError: boolean;
};
export const InputWrapperStyled = styled.div<InputTextProps>`
  ${({ theme, iconPosition, disabled = false, hasError }) => css`
    display: flex;
    width: 21.4rem;
    flex-direction: ${iconPosition === 'right' ? 'row-reverse' : 'row'};
    margin-top: 3px;
    border-radius: 4px;
    background-color: ${disabled ? theme.colors.gray : theme.colors.white};

    ${hasError &&
    css`
      svg {
        color: ${theme.colors.error};
      }
    `}
  `}
`;

export const InputStyled = styled.input<{
  disabled: boolean;
  hasError: boolean;
}>`
  ${({ theme, disabled, hasError }) => css`
    min-height: 36px;
    width: 100%;
    color: ${theme.colors.black};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: 600;
    background-color: transparent;
    border: 1px solid;
    border-color: ${hasError ? theme.colors.error : theme.colors.gray};
    border-radius: 4px;
    outline: none;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 0 0 22px ${disabled ? theme.colors.veryLightGray : theme.colors.white} inset;

    ${!disabled &&
    `&:hover,
     &:focus-within,
     &:-webkit-autofill:hover,
     &:-webkit-autofill:focus-within {
       border-color: ${theme.colorsV2.blue};
       box-shadow: inset 0 0 0 1px ${theme.colorsV2.blue} !important;
     }`}
  `}
`;

export const LabelStyled = styled.label<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    font-size: 11px;
    font-weight: 400;
    color: ${hasError ? theme.colors.error : theme.colors.black};
  `}
`;

export const IconStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};

    //& svg {
    //  width: 100%;
    //}
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
