import { memo, ReactNode, useCallback, useMemo } from 'react';
import { Button, Tag } from 'logshare-ui-kit';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import * as S from './styled';
import { LocalityIcon } from 'assets/icons/locality.icon';
import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { capitalize } from 'utils/string/capitalize';
import { convertOperationType } from 'utils/operation-type';
import { formatCurrencyNoDecimals } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { ColorsV2Type } from 'styles/types/colors';
import { IUser } from 'domain/login';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import { IBackhaulListValues } from 'domain/backhaul';
import { BackhaulHomeTable } from 'components-v2/home-backhaul/table';
import TooltipComponent from 'components/Tooltip';
import { HotIdeal } from 'assets/icons/hot-ideal';
import DoubleArrowDownIcon from 'assets/icons/double-arrow-down.icon';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { OperationTypeResponse } from 'src-new/domains/operation-type';

export interface IBackhaulTableRowProps {
  backhaul: IBackhaulListValues[];
  isLoading: boolean;
  loggedInUser?: IUser;
  setComprarModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      description: ReactNode;
      backhaul?: IBackhaulListValues;
      operation?: string;
      sellId: number;
      exclusiveRate: boolean;
    }>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const BackhaulTableRow: React.FC<IBackhaulTableRowProps> = ({
  backhaul,
  isLoading,
  setComprarModal,
  setPage,
}) => {
  function truncateName(name: string, maxLength = 17) {
    return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
  }

  const handleComprar = useCallback((backhaul: IBackhaulListValues, operation?: string) => {
    setComprarModal((prev: any) => ({
      ...prev,
      isOpen: true,
      backhaul,
      operation,
      sellId: backhaul.id,
      exclusiveRate: backhaul.backhaulPartner,
    }));
  }, []);

  const headerTable = useMemo(() => {
    return [
      {
        value: 'ID',
        flex: 0.3,
      },
      {
        value: 'Empresa',
        flex: 0.6,
      },
      {
        value: 'Categoria',
        flex: 0.5,
      },
      {
        value: 'Origem/Destino',
        flex: 1.2,
      },
      {
        value: 'Veículo',
        flex: 0.8,
      },
      {
        value: 'Capacidade',
        flex: 0.77,
      },
      {
        value: 'Classificação',
        flex: 1,
      },
      {
        value: 'Frete Valor',
        flex: 1,
      },
    ];
  }, []);

  const contentTable = useMemo(() => {
    return backhaul?.map((item) => {
      const localityValues = (localityValue: 'origin' | 'destination') => {
        const locality = `${item[localityValue].address.city.toLowerCase()} - ${item[
          localityValue
        ].address.uf.toLowerCase()}`;

        let tooltip = undefined;

        let name = '-';

        if (item[localityValue]?.address.uf && item?.valueTotal > 0) {
          tooltip = (
            <TooltipComponent
              title="Unidade"
              location="right"
              icon={
                <div style={{ marginBottom: 0.5 }}>
                  <LocalityIcon />
                </div>
              }
            />
          );
          name = item[localityValue]?.name;
        }

        return { locality, tooltip, name };
      };

      const haulType = () => {
        if (item.backhaulPartner) {
          return { label: 'BACKHAUL PARCEIRO', color: ColorsV2Type.Aqua };
        }

        return { label: 'BACKHAUL EXTERNO', color: ColorsV2Type.Blue };
      };

      return [
        {
          value: item.idTariff,
          flex: 0.3,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{item.backhaulPartner ? truncateName(item.companyPartner.name) : 'CONFIDENCIAL'}</strong>

              {item.backhaulPartner && (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 25,
                    marginTop: 3,
                  }}
                  src={item.companyPartner.image}
                  alt={item.companyPartner.name}
                />
              )}
            </div>
          ),
          flex: 0.6,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Tag label={item.cargoType} color={ColorsV2Type.Blue} variant="solid" display="flex" size="md" />
              <Tag
                label={convertOperationTypeToDomain(item.operationType as OperationTypeResponse)}
                color={
                  convertOperationType(convertOperationTypeToDomain(item.operationType as OperationTypeResponse)).color
                }
                variant="ghost"
                display="flex"
                size="md"
              />
            </div>
          ),
          flex: 0.5,
        },
        {
          connection: {
            origin: localityValues('origin'),
            destination: localityValues('destination'),
          },
          flex: 1.2,
        },
        {
          value: capitalize(convertVehicleTypeTypeToName(item.vehicle.type.toLowerCase())),
          subtitle: capitalize(convertVehicleCategoryTypeToName(item.vehicle.category.toLowerCase())),
          flex: 0.8,
        },
        {
          value: offeredWeight(item.weight),
          subtitle: offeredPallets(item.pallets),
          flex: 0.77,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Tag label={haulType().label} color={haulType().color} variant="solid" display="flex" size="md" />
              <TooltipComponent
                title="Economia Estimada"
                location="bottom-start"
                icon={
                  <Tag
                    LeftIcon={
                      <S.TagText>
                        <DoubleArrowDownIcon />
                        CO₂
                      </S.TagText>
                    }
                    label={'CO2 KG'}
                    color={ColorsV2Type.Aqua}
                    variant="ghost"
                    display="flex"
                    size="md"
                  />
                }
              />
              {item.backhaulPartner && (
                <Tag
                  LeftIcon={<HotIdeal />}
                  label="Tarifa Exclusiva"
                  color={ColorsV2Type.Red}
                  variant="ghost"
                  display="flex"
                  size="md"
                />
              )}
            </div>
          ),
          flex: 1,
        },
        {
          value:
            item.valueTotal === 0 ? (
              <S.NegotiationLabel>A Negociar</S.NegotiationLabel>
            ) : (
              <div>
                <S.ValueLabel>{formatCurrencyNoDecimals(Number(item?.valueTotal))}</S.ValueLabel>
                <S.SubTitle>+ Impostos e Pedágio</S.SubTitle>
              </div>
            ),
          flex: 1,
        },
        {
          button: (
            <Button
              label="Comprar"
              color="blue"
              size="md"
              onPress={() =>
                handleComprar(
                  {
                    ...item,
                  },
                  haulType().label,
                )
              }
              variant="solid"
              RightIcon={<KeyboardDoubleArrowRightIcon sx={{ width: '20px' }} />}
            />
          ),
        },
      ];
    });
  }, [backhaul]);

  return (
    <BackhaulHomeTable header={headerTable} content={contentTable || []} isLoading={isLoading} setPage={setPage} />
  );
};

export default memo(BackhaulTableRow);
