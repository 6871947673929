import { RequirementTypes, RequirementValues, ScheduleCargoInput } from '..';

export const requirementsInitialValues: RequirementValues = {
  id: 0,
  values: [],
};

export const requirementsTypeInitialValues: RequirementTypes = {
  quality: requirementsInitialValues,
  security: requirementsInitialValues,
  custom: requirementsInitialValues,
};

export const scheduleCargoInitialValues: ScheduleCargoInput = {
  vehicleType: 'carreta',
  vehicleCategoryType: 'bau_seco',
  shippingReturn: '',
  product: '',
  weight: 0,
  cubagem: 0,
  volume: 0,
  merchValue: 0,
  inPallet: false,
  pallet: 0,
  order: '',
  comments: '',
  requirements: requirementsTypeInitialValues,
};
