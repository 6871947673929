import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getDriversListService } from '../../services/get-driver-list/get-driver-list.service';
import { IScheduleDetailsAllocationDriversListProps } from '../../services/get-driver-list/get-driver-list.types';
import { IScheduleDetailsAllocationDriverDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-driver.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const useDriverList = () => {
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { scheduleDetails } = useContext(ScheduleContext);
  const { setInitialLoadingSuccess } = initialLoading;

  return useCallback(
    async (params: IScheduleDetailsAllocationDriversListProps, setLimitPage: Dispatch<SetStateAction<boolean>>) => {
      scheduleDetails.allocationDriver.modalProps.setScheduleDetailsAllocationDriverRequest();

      try {
        const scheduleListResponse = await getDriversListService({
          search: params.search,
          page: params.page,
          limitRegistry: params.limitRegistry,
          status: params.status,
          order: params.order,
          column: params.column,
          ...(scheduleDetails.allocationShipping.shippingCompanyId && {
            shippingCompanyId: scheduleDetails.allocationShipping.shippingCompanyId,
          }),
        });
        const oldRecords: Array<IScheduleDetailsAllocationDriverDomain> =
          params.page === 1 ? [] : scheduleDetails.allocationDriver.modalProps.driverList;

        setLimitPage(
          scheduleListResponse.meta.totalPages === params.page || scheduleListResponse.meta.totalPages === 0,
        );
        scheduleDetails.allocationDriver.modalProps.setScheduleDetailsAllocationDriverSuccess([
          ...oldRecords,
          ...scheduleListResponse.items,
        ]);
      } catch (error) {
        scheduleDetails.allocationDriver.modalProps.setScheduleDetailsAllocationDriverError();
      }
      setInitialLoadingSuccess();
    },
    [
      scheduleDetails.allocationDriver.modalProps,
      scheduleDetails.allocationShipping.shippingCompanyId,
      setInitialLoadingSuccess,
    ],
  );
};
