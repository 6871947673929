import React from 'react';

import FreightNFESectionView from './freight-cargo-nfe-section-view';

import { ScheduleDomain } from 'domain/schedule';

interface IProps {
  freightValues: ScheduleDomain;
}

const FreightNFESection: React.FC<IProps> = ({ freightValues }) => {
  return <FreightNFESectionView freightValues={freightValues} />;
};

export default React.memo(FreightNFESection);
