import * as Yup from 'yup';
import { AttachmentsSchema } from 'src-new/pages/registrations/utils/schemas/attachment-schema-validation';
import { riskManagementSchema } from 'src-new/pages/registrations/utils/schemas/risk-management-schema-validation';

export const DriverRegisterSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Texto Muito Curto!').required('Campo Obrigatório'),
  firstPhone: Yup.string().required('Campo Obrigatório'),
  cnhNumber: Yup.string().min(11, 'CNH Tem 11 Dígitos!').max(11, 'CNH Tem 11 Dígitos!').required('Campo Obrigatório'),
  cpf: Yup.string().min(11, 'CPF Tem 11 Dígitos!').required('Campo Obrigatório'),
  fleetType: Yup.string().required('Campo Obrigatório'),
  attachments: Yup.array().of(AttachmentsSchema),
  riskManagers: Yup.array().of(riskManagementSchema),
  issueDate: Yup.date().required('Campo Obrigatório'),
});
