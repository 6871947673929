/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';

import * as S from './input.styled';

import { CancelIcon } from 'assets/icons/cancel.icon';

let timer: NodeJS.Timeout | undefined;
interface IInputProps {
  id: string;
  type: string;
  name: string;
  placeholder: string;
  changeValue: (value: string) => void;
  filters?: Array<any>;
  handleClearFilters?: (index: number) => void;
  isClear?: boolean;
}

const Input: React.FC<IInputProps> = ({
  id,
  type,
  name,
  placeholder,
  changeValue,
  filters,
  handleClearFilters = () => undefined,
  isClear = true,
}: IInputProps) => {
  const [value, setValue] = useState('');

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        changeValue(e.target.value);
      }, 500);

      setValue(e.target.value);
    },
    [changeValue],
  );

  const onClearValue = useCallback(() => {
    clearTimeout(timer);
    changeValue('');
    setValue('');
  }, [changeValue]);

  const renderFilter = useMemo(() => {
    const mapping = filters?.map((filter, index) => {
      if (!filter || Array.isArray(filter)) return undefined;
      return (
        <S.FiltersSelected key={index}>
          <S.FiltersTitle>{filter}</S.FiltersTitle>

          <S.HandleCloseFilters
            onClick={() => {
              handleClearFilters(index);
              onClearValue();
            }}
          >
            X
          </S.HandleCloseFilters>
        </S.FiltersSelected>
      );
    });

    return mapping;
  }, [filters]);

  return (
    <S.ContainerInputStyled>
      <S.InputStyled type={type} id={id} name={name} value={value} placeholder={placeholder} onChange={onChangeValue} />
      {renderFilter}
      {isClear && (
        <S.ClearValueStyled onClick={onClearValue} style={{ cursor: 'pointer' }}>
          <CancelIcon />
        </S.ClearValueStyled>
      )}
    </S.ContainerInputStyled>
  );
};

export default Input;
