/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { IHireBackhaulEntityCreated } from 'pages/hire-backhaul/services/entities/hire-backhaul.entity';
import { IHireBackhaulCreated } from 'domain/hire-backhaul';

export const convertHireBackhaulCreatedDomainToEntityMapper = (
  hireBackhaulDomain: IHireBackhaulCreated,
): IHireBackhaulEntityCreated => {
  const initialDate = moment(hireBackhaulDomain.initialDate, 'DD/MM/YYYY HH:mm').toISOString();

  const formatPrice = (value: any) => {
    return value.toString().replace(',', '.');
  };

  const endDate = moment(hireBackhaulDomain.endDate, 'DD/MM/YYYY HH:mm').toISOString();
  return {
    ...hireBackhaulDomain,
    initialDate,
    initial_date: initialDate,
    endDate,
    freightValue: Number(formatPrice(hireBackhaulDomain.freightValue)),
    offeredCubage: +hireBackhaulDomain.offeredCubage || 0,
    offeredPallet: +hireBackhaulDomain.offeredPallet || 0,
    offeredWeight: +hireBackhaulDomain.offeredWeight || 0,
  };
};
