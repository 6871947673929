import React, { useMemo } from 'react';

import { ContainerPaginationStyled, ContainerTableStyled } from './santos-port-table-view.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface ISantosPortTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (santosPortId: string) => void;
  isLoading: boolean;
}

const dataTestId = 'location-table-view';

const SantosPortTableView: React.FC<ISantosPortTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  isLoading,
}: ISantosPortTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <ContainerPaginationStyled data-testid={`${dataTestId}-container-pagination`}>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <ContainerTableStyled data-testid={`${dataTestId}-container-table`}>
      <Table header={headerTable} content={contentTable} isLoading={isLoading} clickRowAction={clickRowAction} />
      {renderPagination}
    </ContainerTableStyled>
  );
};

export default SantosPortTableView;
