import * as Yup from 'yup';

export const UserRegisterSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(50, 'O Campo Deve Ter 50 Caracteres!')
    .required('Campo Obrigatório'),

  login: Yup.string()
    .min(2, 'Texto Muito Curto!')
    .max(50, 'O Campo Deve Ter 50 Caracteres!')
    .required('Campo Obrigatório'),

  telephone: Yup.string().required('Campo Obrigatório'),

  email: Yup.string().email().required('Campo Obrigatório'),

  password: Yup.string()
    .min(8, 'Senha Deve Ter No Mínimo 8 Caracteres')
    .matches(/[A-Z]/, 'Senha Deve Conter Pelo Menos Uma Letra Maiúscula')
    .required('Campo Obrigatório'),

  passwordConfirmation: Yup.string()
    .min(8, 'Senha Deve Ter No Mínimo 8 Caracteres')
    .matches(/[A-Z]/, 'Senha Deve Conter Pelo Menos Uma Letra Maiúscula')
    .required('Campo Obrigatório'),
});
