import { IPortScheduleEntity } from '../entities/santos-port.entity';

import API from 'utils/API/API';
import { convertSantosPortEntityToDomainMapper } from 'pages/santos-port/mappers/convert-santos-port-entity-to-domain/convert-santos-port-entity-to-domain.mapper';
import { IPortSchedule } from 'domain/santos-port';
import { IListPage, PageInfo } from 'domain/page-info';

export interface ISantosPortListProps {
  search: string;
  page: number;
}

export interface ISantosPortListEntity {
  list: Array<IPortScheduleEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const santosPortListService = async ({
  page,
  search,
}: ISantosPortListProps): Promise<IListPage<IPortSchedule>> => {
  try {
    let url = `schedule-port/paginate?search=${search}&page=${page}&limit=${LIMIT_PAGE}`;

    if (search === '') {
      url = url.replace('search=&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { dataList, ...info } = data;

    return {
      list: dataList.map(convertSantosPortEntityToDomainMapper),
      pageInfo: info,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar transportadora');
  }
};
