import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.div`
  ${() => css`
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 15px;
  `}
`;

export const WrapperText = styled.div`
  margin: 5px;
`;

export const Subtitle = styled.div`
  ${() => css`
    text-align: center;
    font-size: 1rem;
  `}
`;

export const TextValue = styled.div`
  ${() => css`
    text-align: center;
    font-size: 1.2rem;
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
`;

export const GoBack = styled.div`
  margin-top: 20px;
  cursor: pointer;
`