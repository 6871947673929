import { useCallback, useReducer } from 'react';
import { IMessageChatDomain } from 'src-new/domains/message-chat.domain';
import { IChatMessageQuantity } from 'src-new/contexts/chat/types/chat-message-quantity.types';
import { chatMessageQuantityReducer } from 'src-new/contexts/chat/reducers/chat-message-quantity/chat-message-quantity.reducer';
import { chatInitialState } from '../../chat.constants';
import { ChatMessageQuantityActionType } from 'src-new/contexts/chat/chat.action';

export const useChatMessageQuantityContextValues = (): IChatMessageQuantity => {
  const [state, dispatch] = useReducer(chatMessageQuantityReducer, chatInitialState);

  const setMessageQuantitySuccess = useCallback((conversations: Array<IMessageChatDomain>) => {
    dispatch({ type: ChatMessageQuantityActionType.CHAT_MESSAGE_QUANTITY_SUCCESS, payload: conversations });
  }, []);


  return {
    ...state.chatMessageQuantity,
    setMessageQuantitySuccess,
  };
};
