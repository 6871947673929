import API from 'utils/API/API';
import { IPalletList, PalletListParams } from 'domain-v2/pallet/list';
import { IListPage } from 'domain/page-info';

const LIMIT_PAGE = 20;

export const palletListV2 = async (params: PalletListParams): Promise<IListPage<IPalletList>> => {
  const { data } = await API.get('v2/pallet', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
    },
  });

  return {
    list: data.items,
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};
