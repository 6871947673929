import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { ISurplusValueParams, ISurplusValueResponse } from './get-surplus-value.types';
import { ISurplusValueDomain } from 'src-new/domains/surplus-value.domain';
import { convertSurplusValueResponseToDomain } from './mappers/convert-surplus-value-response-to-doimain/convert-surplus-value-response-to-doimain.mapper';

export const getSurplusValueService = async (params: ISurplusValueParams): Promise<ISurplusValueDomain> => {
  const { data } = await API_TRAVELS_SVC.get<ISurplusValueResponse>('v1/freight/surplus', {
    params: params,
  });

  return convertSurplusValueResponseToDomain(data);
};
