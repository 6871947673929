import styled, { css, keyframes } from 'styled-components';

interface IStyledProps {
  name: string;
}

export const SectionContentStyled = styled.section<IStyledProps>`
  ${() => css`
    display: inline-block;
  `}
`;

export const ContainerStateStyled = styled.div`
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #7f7f7f;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerModal = styled.div`
  background-color: transparent;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ececec;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 0.7s linear infinite;
`;

export const ButtonContainerStyled = styled.div`
  button {
    height: 36px !important;
    width: 42px !important;
  }
`;
