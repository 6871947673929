import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useGetIcms } from 'src-new/hooks/use-get-icms/get-icms.hook';
import { UserContext } from 'state/user-context';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { SaleOfferDetailsBackhaulPurchaseFormView } from './sale-offer-backhaul-purchase-form-view.component';
import { IFreeTimeDomain } from 'src-new/domains/fretime.domain';
import { useGetFreetimeByVehicle } from 'src-new/hooks/use-get-freetime/use-get-freetime-by-vehicle.hook';
import { converterNumberPtBrToUs } from 'utils/converter-number-ptbr-to-us/converter-number-ptbr-to-us';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';

const LOGSHARE_ID = 2;

interface ISaleOfferDetailsBackhaulPurchaseFormProps {
  backhaulPurchase: IBackhaulPurchase;
  hasError: boolean;
}

export const SaleOfferDetailsBackhaulPurchaseForm: FC<ISaleOfferDetailsBackhaulPurchaseFormProps> = ({
  backhaulPurchase,
  hasError,
}) => {
  const { user } = useContext(UserContext);
  const { values, setFieldValue } = useFormikContext<IModalBackhaulPurchaseForm>();
  const { saleOffer } = backhaulPurchase;

  const [icms, setIcms] = useState<number>(0);
  const [freetime, setFreetime] = useState<IFreeTimeDomain>({ freeTime: 0, hourlyFine: 0, dailyFine: 0 });
  const [grisAdvalorem, setGrisAdvalorem] = useState<{ total: number; grisPercent: number; advaloremPercent: number }>({
    total: 0,
    grisPercent: 0,
    advaloremPercent: 0,
  });

  const getIcmsHook = useGetIcms();
  const getCompanyHook = useGetCompany();
  const getFreetimeByVehicleHook = useGetFreetimeByVehicle();

  const handleChange = useCallback(
    (fieldName: string, value: string) => {
      const formattedValue = converterNumberPtBrToUs(value);

      setFieldValue(fieldName, formattedValue);
    },
    [setFieldValue],
  );

  const getIcmsValue = useCallback(async () => {
    if (!saleOffer.origin.uf || !saleOffer.destination.uf) return;

    const icms = await getIcmsHook(saleOffer.origin.uf, saleOffer.destination.uf);

    if (!icms) return;

    setIcms(icms);
  }, [saleOffer.destination.uf, saleOffer.origin.uf, getIcmsHook]);

  const getGrisAdvAndFreetimeValue = useCallback(async () => {
    if (!user?.companyId) return;

    const [userCompany, freeTime] = await Promise.all([
      getCompanyHook(user.companyId),
      getFreetimeByVehicleHook('carreta', LOGSHARE_ID),
    ]);

    if (!userCompany || !freeTime) return;

    setFreetime(freeTime);
    setFieldValue('retentionFines', freeTime);

    const grisPercent = Number(userCompany.gris);
    const advaloremPercent = Number(userCompany.advalorem);
    const gris = (values.cargo.merchValue * grisPercent) / 100;
    const advalorem = (values.cargo.merchValue * advaloremPercent) / 100;
    const total = gris + advalorem;

    setFieldValue('cost.grisAdvalorem', total);

    setGrisAdvalorem({
      total,
      grisPercent,
      advaloremPercent,
    });
  }, [getCompanyHook, getFreetimeByVehicleHook, setFieldValue, user?.companyId, values.cargo.merchValue]);

  const companyImage = useMemo((): string => {
    if (saleOffer.company.image && (saleOffer.company.id === user?.companyId || user?.profile == 'logshare')) {
      return saleOffer.company.image ?? '';
    }

    return 'https://backhaul-public-images.s3.sa-east-1.amazonaws.com/logshare.png';
  }, [saleOffer.company.id, saleOffer.company.image, user?.companyId, user?.profile]);

  const freightValues = useMemo((): { totalFreight: number; icmsValue: number; totalService: number } => {
    const totalFreight = values.cost.freightValue + (grisAdvalorem.total ?? 0) + (values.cost.surplusValue[0] ?? 0);

    const icmsValue = totalFreight / (1 - icms / 100) - totalFreight;

    const totalService = totalFreight + icmsValue;

    setFieldValue('cost.freightTotal', totalFreight);
    setFieldValue('cost.icms', icmsValue);
    setFieldValue('cost.totalService', totalService);
    setFieldValue('cost.otherTaxes', 0);

    return { totalFreight, icmsValue, totalService };
  }, [values.cost.freightValue, values.cost.surplusValue, grisAdvalorem.total, icms, setFieldValue]);

  const hasPurchaseValues = useMemo((): boolean => !!backhaulPurchase.purchaseOffer, [backhaulPurchase.purchaseOffer]);

  useEffect(() => {
    getIcmsValue();
    getGrisAdvAndFreetimeValue();
  }, [getGrisAdvAndFreetimeValue, getIcmsValue]);

  return (
    <SaleOfferDetailsBackhaulPurchaseFormView
      costValues={values.cost}
      saleOffer={saleOffer}
      companyImage={companyImage}
      freightValues={freightValues}
      grisAdvalorem={grisAdvalorem}
      freetime={freetime}
      hasPurchaseValues={hasPurchaseValues}
      hasError={hasError}
      handleChange={handleChange}
    />
  );
};
