import { ITableFreightContext, ITableFreightReducerAction } from './table-freight.types';
import { ITableFreightAction } from './table-freight.actions';

export const tableFreightReducer = (
  state: ITableFreightContext,
  action: ITableFreightReducerAction,
): ITableFreightContext => {
  switch (action.type) {
    case ITableFreightAction.TABLE_FREIGHT_REQUEST: {
      return {
        ...state,
        tableFreightIsLoading: true,
        tableFreight: [],
        tableFreightError: undefined,
      };
    }
    case ITableFreightAction.TABLE_FREIGHT_SUCCESS: {
      return {
        ...state,
        tableFreightIsLoading: false,
        tableFreight: action.payload.list,
        tableFreightPageInfo: action.payload.pageInfo,
        tableFreightError: undefined,
      };
    }
    case ITableFreightAction.TABLE_FREIGHT_ERROR: {
      return {
        ...state,
        tableFreightIsLoading: false,
        tableFreight: [],
        tableFreightError: action.payload,
        tableFreightPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
