import styled, { css } from 'styled-components';

interface StyleProps {
  bgColor?: string;
  height?: number;
  width?: number;
}

export const Wrapper = styled.div``;

export const StepIcon = styled.div<StyleProps>`
  ${({ bgColor }) => css`
    background-color: ${bgColor || '#12264e'};
    z-index: 1;
    color: #fff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  `}
`;

export const Connector = styled.div<StyleProps>`
  ${({ height, width, bgColor }) => css`
    height: ${height || 100}px;
    width: ${width || 2}px;
    border: 0;
    margin-left: 3px;
    background-color: ${bgColor || '#12264e'};
  `}
`;
