import { IVehicleEntity } from '../entities/vehicle.entity';
import { convertStatusDomainToEntityMapper } from '../../mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

import API from 'utils/API/API';
import { convertVehicleCreatedEntityToDomainMapper } from 'pages/vehicle/mappers/convert-vehicle-created-entity-to-domain/convert-driver-created-entity-to-domain.mapper';
import { IVehicleCreated, IVehicleStatus } from 'domain/vehicle';
import { IListPage, PageInfo } from 'domain/page-info';
export interface IVehicleListProps {
  search: string;
  page: number;
  status?: IVehicleStatus;
  order: string;
  column: string;
}
export interface IVehicleListEntity {
  list: Array<IVehicleEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const vehicleListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IVehicleListProps): Promise<IListPage<IVehicleCreated>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/vehicles?search=${search}&status=${parsedStatus}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search =&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertVehicleCreatedEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar veículos');
  }
};
