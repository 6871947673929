import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { ISaleOfferDomain } from '../../../../domains/sale-offer-details.domain';
import { SaleOfferUpdateFormMountView } from './sale-offer-update-form-mount-view.component';

export const SaleOfferUpdateFormMount: FC = () => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<ISaleOfferDomain>();
  const { saleOfferUpdate, saleOfferModals } = useContext(SaleOfferContext);

  const navigate = useNavigate();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const handleOpenOfferShipperModal = useCallback(
    () =>
      saleOfferModals.setModalOpen({
        indexes: { saleOfferShipper: undefined, saleOfferShipperTariff: undefined },
        modalType: 'offer_shipper',
      }),
    [saleOfferModals],
  );

  const handleGoBackPage = useCallback(() => navigate(-1), [navigate]);

  const buttonIsLoading = useMemo(
    (): boolean => saleOfferUpdate.statusRequest === 'PROCESSING',
    [saleOfferUpdate.statusRequest],
  );

  return (
    <SaleOfferUpdateFormMountView
      values={values}
      saleOfferModals={saleOfferModals}
      buttonIsLoading={buttonIsLoading}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleOnSubmit={handleSubmit}
      handleOpenOfferShipperModal={handleOpenOfferShipperModal}
      handleGoBackPage={handleGoBackPage}
    />
  );
};
