import styled, { css } from 'styled-components';

interface StyledProps {
  isBold?: boolean;
  blueColor?: boolean;
  withoutPadding?: boolean;
  isSchedule?: boolean;
  isSaleOffer?: boolean;
}

export const CustomWrapper = styled.div`
  ${(p: any) => p.styles}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.width.fullView};
    height: ${theme.height.fullView};
    top: 0;
    left: 0;
    position: absolute;
    padding: 20px;
    background-color: ${theme.modals.background};
    z-index: 1;
    color: ${theme.colorsV2.black};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 565px;
    width: 970px;
    background-color: ${theme.colorsV2.white};
    border-radius: 5px;
    padding: 25px;
    overflow-y: auto;
    justify-content: space-between;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontV2.sizes.text2};
    font-weight: 700;
    padding-bottom: 10px;
  `}
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p<StyledProps>`
  ${({ theme, isBold, withoutPadding }) => css`
    font-size: ${isBold ? theme.fontV2.sizes.text3 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 700 : 600};
    color: ${theme.colorsV2.black};
    padding-bottom: ${withoutPadding ? 0 : 16}px;
    text-transform: uppercase;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Label = styled.p<StyledProps>`
  ${({ theme, isBold, blueColor }) => css`
    display: ${isBold ? '' : 'flex'};
    font-size: ${blueColor ? theme.fontV2.sizes.text5 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 600 : 400};
    color: ${blueColor ? theme.colorsV2.blue : theme.colorsV2.black};
    text-transform: uppercase;
  `}
`;

export const ScheduleDetailsBold = styled.p`
  font-weight: 600;
  color: #000;
  padding-left: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperLocations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 25%;
  height: 92%;
`;

export const WrapperScheduleDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 33%;
  height: 92%;
`;

export const ScheduleDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

export const ButtonClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
`;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;

export const CheckboxSection = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
`;

export const TermsText = styled.p<StyledProps>`
  ${({ theme }) => css`
    display: inline;
    flex-direction: column;
    align-items: center;
    font-size: ${theme.fontV2.sizes.text5};
    padding-left: 10px;
    text-transform: uppercase;
  `}
`;

export const LinkText = styled.a`
  ${({ theme }) => css`
    color: ${theme.colorsV2.blue};
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const StepIcon = styled.div`
  background-color: #d9d9d9;
  z-index: 1;
  color: #fff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const Connector = styled.div<StyledProps>`
  ${({ isSaleOffer }) => css`
    height: ${isSaleOffer ? 75 : 90}px;
    border: 0;
    width: 2px;
    margin-left: 4px;
    background-color: #d9d9d9;
  `}
`;
