import { useCallback } from 'react';

import { myAccountDetailsService, myAccountMicrosoftDetailsService } from 'pages/my-account/services/my-account-details/my-account-details.service';
import { IMyAccount } from 'domain/my-account';
import { IUser } from 'domain/login';

const useMyAccountDetailsHook = () => {
  return useCallback(async (myAccountId: number): Promise<IMyAccount> => {
    const myAccount = await myAccountDetailsService(myAccountId);
    return myAccount;
  }, []);
};

const useMyAccountMicrosoftDetailsHook = () => {
  return useCallback(async (email: string): Promise<IUser> => {
    const myAccount = await myAccountMicrosoftDetailsService(email);
    console.log('Óbtendo informaçoes do usuário', myAccount);
    return myAccount;
  }, []);
};

export { useMyAccountDetailsHook, useMyAccountMicrosoftDetailsHook };

