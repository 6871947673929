import { schedulingListService } from 'pages/task-vision/services/schedule-list/scheduling-list.service';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';

const useFreightList = () => {
  return async (): Promise<ICurrentAllocation[]> => {
    const response = await schedulingListService();
    return response;
  };
};

export { useFreightList };
