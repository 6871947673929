/* eslint-disable no-unused-vars */
import { WeighingStatus } from 'src-new/pages/execution/pages/weighing/pages/weighing-list/domains/weighing-list-filters/weighing-list-filters.domain';
import { VehicleCategoryEnum, VehicleTypeEnum } from './global';

export enum AnswerValueEnum {
  SIM = 'yes',
  NAO = 'no',
}

export enum ShippingReturnEnum {
  RETORNA_VAZIO = 'empty',
  RETORNA_CARREGADO = 'loaded',
  NAO_RETORNA = 'not_return',
}

export enum NeedHelperEnum {
  SEM_AJUDA = 'sem_ajuda',
  NO_CARREGAMENTO = 'carregamento',
  NA_DESCARGA = 'descarga',
  AMBOS = 'ambos',
}

export type AllValuesType =
  | AnswerValueEnum
  | ShippingReturnEnum
  | VehicleCategoryEnum
  | NeedHelperEnum
  | string
  | number
  | boolean
  | Array<string>;

export interface IOption {
  label: string;
  value: AllValuesType;
}

export const vehicleTypeOptions: IOption[] = [
  { value: VehicleTypeEnum.COMPLEMENTO, label: 'COMPLEMENTO' },
  { value: VehicleTypeEnum.TRES_QUARTO, label: '3/4' },
  { value: VehicleTypeEnum.BITREM, label: 'BITREM' },
  { value: VehicleTypeEnum.BITRUCK, label: 'BITRUCK' },
  { value: VehicleTypeEnum.CARRETA, label: 'CARRETA' },
  { value: VehicleTypeEnum.CARRETALS, label: 'CARRETA LS' },
  { value: VehicleTypeEnum.CARRETA_SETE_EIXOS, label: 'CARRETA 7 EIXOS' },
  { value: VehicleTypeEnum.RODOTREM, label: 'RODOTREM' },
  { value: VehicleTypeEnum.ROMEUJULIETA, label: 'ROMEU & JULIETA' },
  { value: VehicleTypeEnum.TOCO, label: 'TOCO' },
  { value: VehicleTypeEnum.TRUCK, label: 'TRUCK' },
  { value: VehicleTypeEnum.UTILITARIO, label: 'UTILITÁRIO' },
  { value: VehicleTypeEnum.VAN, label: 'VAN' },
  { value: VehicleTypeEnum.VANDERLEIA, label: 'VANDERLEIA' },
  { value: VehicleTypeEnum.VUC, label: 'VUC' },
];

export const approvedTrackers: IOption[] = [
  { value: 'TRUCKS_CONTROL', label: 'TRUCKS' },
  { value: 'SASCAR', label: 'SASCAR' },
  { value: 'OMNILINK', label: 'OMNILINK' },
  { value: 'AUTOTRAC', label: 'AUTOTRAC' },
];

export const vehicleCategoryOptions: IOption[] = [
  {
    value: VehicleCategoryEnum.CACAMBA_BASCULANTE,
    label: 'BASCULANTE',
  },
  { value: VehicleCategoryEnum.BAU_FRIGORIFICADO, label: 'BAÚ FRIGORIFICADO' },
  { value: VehicleCategoryEnum.BAU_REFRIGERADO, label: 'BAÚ REFRIGERADO' },
  { value: VehicleCategoryEnum.BAU_SECO, label: 'BAÚ SECO' },
  { value: VehicleCategoryEnum.CEGONHA, label: 'CEGONHA' },
  // { value: VehicleCategoryEnum.COMPLEMENTO, label: 'COMPLEMENTO' },
  { value: VehicleCategoryEnum.FLORESTAL, label: 'FLORESTAL' },
  { value: VehicleCategoryEnum.GRADE_BAIXA, label: 'GRADE BAIXA' },
  { value: VehicleCategoryEnum.GRANELEIRO, label: 'GRANELEIRO' },
  { value: VehicleCategoryEnum.PLATAFORMA, label: 'PLATAFORMA' },
  {
    value: VehicleCategoryEnum.PORTA_CONTAINER_20,
    label: 'PORTA CONTAINER 20',
  },
  {
    value: VehicleCategoryEnum.PORTA_CONTAINER_40,
    label: 'PORTA CONTAINER 40',
  },
  { value: VehicleCategoryEnum.BAU_SIDER, label: 'SIDER' },
  { value: VehicleCategoryEnum.TANQUE, label: 'TANQUE' },
];

export const yesNoAnswersOptions: IOption[] = [
  { value: AnswerValueEnum.SIM, label: 'SIM' },
  { value: AnswerValueEnum.NAO, label: 'NÃO' },
];

export const booleansOptions: IOption[] = [
  { value: true, label: 'SIM' },
  { value: false, label: 'NÃO' },
];

export const shippingReturnOptions: IOption[] = [
  { value: ShippingReturnEnum.RETORNA_VAZIO, label: 'RETORNA VAZIO' },
  { value: ShippingReturnEnum.RETORNA_CARREGADO, label: 'RETORNA CARREGADO' },
  { value: ShippingReturnEnum.NAO_RETORNA, label: 'NÃO RETORNA' },
];

export const operationTypeOptions: IOption[] = [
  { value: 'TODOS', label: 'TODOS' },
  { value: 'ABASTECIMENTO', label: 'ABASTECIMENTO' },
  { value: 'ARMAZENAGEM', label: 'ARMAZENAGEM' },
  { value: 'COLETA', label: 'COLETA' },
  { value: 'CROSS_DOCKING', label: 'CROSS DOCKING' },
  { value: 'DEVOLUÇÃO_DE_EQUIPAMENTO', label: 'DEVOLUÇÃO DE EQUIPAMENTO' },
  { value: 'DEVOLUÇÃO_DE_MERCADORIA', label: 'DEVOLUÇÃO DE MERCADORIA' },
  { value: 'ETIQUETAGEM', label: 'ETIQUETAGEM' },
  { value: 'PALETIZACAO', label: 'PALETIZAÇÃO' },
  { value: 'REPALETIZAÇÃO INTERNA', label: 'REPALETIZAÇÃO INTERNA' },
  { value: 'REPALETIZAÇÃO EXTERNA', label: 'REPALETIZAÇÃO EXTERNA' },
  { value: 'RETRABALHO', label: 'RETRABALHO' },
  { value: 'TRANSFERENCIA', label: 'TRANSFERÊNCIA' },
  { value: 'TRANSPORTE', label: 'TRANSPORTE' },
];

export const haulTypeOptions: IOption[] = [
  { value: 'ALL', label: 'TODOS' },
  { value: 'SPOT', label: 'SPOT' },
  { value: 'BACKHAUL INTERNO', label: 'BACKHAUL INTERNO' },
  { value: 'BACKHAUL EXTERNO', label: 'BACKHAUL EXTERNO' },
  { value: 'BACKHAUL PARCEIRO', label: 'BACKHAUL PARCEIRO' },
  { value: 'TRACKING', label: 'APENAS TRACKING' },
];

export const operationSubTypeOptions: IOption[] = [
  { value: 'Abastecimento', label: 'ABASTECIMENTO' },
  { value: 'Coleta', label: 'COLETA' },
  { value: 'Deslocamento', label: 'DESLOCAMENTO' },
  { value: 'Devolução de Mercadoria', label: 'DEVOLUÇÃO DE MERCADORIA' },
  { value: 'Devolução de Palete', label: 'DEVOLUÇÃO DE PALETE' },
  { value: 'Transferência', label: 'TRANSFERÊNCIA' },
  { value: 'Outros', label: 'OUTROS' },
];

export const fleetTypeOptions: IOption[] = [
  { value: 'Frota Terceirizada', label: 'FROTA TERCEIRIZADA' },
  { value: 'Frota Própria', label: 'FROTA DEDICADA' },
];

export const needHelperOptions: IOption[] = [
  { value: NeedHelperEnum.SEM_AJUDA, label: 'SEM AJUDANTE' },
  { value: NeedHelperEnum.NO_CARREGAMENTO, label: 'NO CARREGAMENTO' },
  { value: NeedHelperEnum.NA_DESCARGA, label: 'NA DESCARGA' },
  { value: NeedHelperEnum.AMBOS, label: 'AMBOS' },
];

export const operationBy: IOption[] = [
  { value: 1, label: 'LOGSHARE' },
  { value: 2, label: 'EMBARCADOR' },
];

export const optionsMatchType: IOption[] = [
  { value: 'COMPRA', label: 'COMPRA' },
  { value: 'VENDA', label: 'VENDA' },
];

export const invoicedBy: IOption[] = [
  { value: 'LOGSHARE', label: 'LOGSHARE' },
  { value: 'EMBARCADOR', label: 'EMBARCADOR' },
];

export const optionsInvoicedBy: IOption[] = [
  { value: 'LOGSHARE', label: 'LOGSHARE' },
  { value: 'EMBARCADOR', label: 'TRANSPORTADORA' },
];

export const financialProcess: IOption[] = [
  { value: 'Em Auditoria', label: 'EM AUDITORIA' },
  { value: 'A Vencer', label: 'A VENCER' },
  { value: 'Liberado', label: 'LIBERADO' },
  { value: 'Aguardando Pagamento', label: 'AGUARDANDO PAGAMENTO' },
  { value: 'Pago', label: 'LIQUIDADO' },
];

export const typeOperation: IOption[] = [
  { value: 'SPOT', label: 'SPOT' },
  { value: 'BACKHAUL INTERNO', label: 'BACKHAUL INTERNO' },
  { value: 'BACKHAUL EXTERNO', label: 'BACKHAUL EXTERNO' },
  { value: 'BACKHAUL PARCEIRO', label: 'BACKHAUL PARCEIRO' },
  { value: 'TRACKING', label: 'APENAS TRACKING' },
];

export const backhaulTypeOptions: IOption[] = [
  { value: 'Backhaul Interno', label: 'BACKHAUL INTERNO' },
  { value: 'Backhaul Externo', label: 'BACKHAUL EXTERNO' },
  // { value: 'Backhaul Parceiro', label: 'BACKHAUL PARCEIRO' },
];

export const statusCodeTripsOptions: IOption[] = [
  { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
  { value: 'IN11', label: 'AGUARDANDO INÍCIO DE ROTA' },
  { value: 'IN20', label: 'EM ROTA PARA CARREGAMENTO' },
  { value: 'IN40', label: 'EM CARREGAMENTO' },
  { value: 'IN60', label: 'EM ROTA PARA DESCARGA' },
  { value: 'IN70', label: 'EM DESCARGA' },
  { value: 'IN90', label: 'DESCARGA FINALIZADA' },
];

export const statusCodeScheduleOptions: IOption[] = [
  { value: 'AG10', label: 'AGUARDANDO ALOCAÇÃO' },
  { value: 'IN10', label: 'AGUARDANDO MOTORISTA' },
  { value: 'OT10', label: 'EM NEGOCIAÇÃO' },
  { value: 'OT99', label: 'OFERTA NEGADA' },
  { value: 'OT01', label: 'AG. ACEITE TRANSPORTADORA' },
  { value: 'SH01', label: 'EM BIDDING' },
  { value: 'AL90', label: 'ALOCAÇÃO CANCELADA COM CUSTO' },
  { value: 'AL99', label: 'ALOCAÇÃO CANCELADA' },
];

export const backhaulHomeTypeOptions: IOption[] = [
  { value: 'Backhaul Interno', label: 'INTERNO' },
  { value: 'Backhaul Externo', label: 'EXTERNO' },
];

export const OriginAndDestinyTypeOptions: Array<{
  label: string;
  value: string;
}> = [
  { label: 'UNIDADE', value: 'LOCALITY' },
  { label: 'PARCEIRO', value: 'CLIENT' },
];

export const yesNoOptions: IOption[] = [
  { value: true, label: 'SIM' },
  { value: false, label: 'NÃO' },
];

export const offerToOptions: IOption[] = [
  { value: 'Malha Interna', label: 'BACKHAUL INTERNO' },
  { value: 'Malha Externa', label: 'BACKHAUL EXTERNO' },
];

export const potencialMatchesOptions: IOption[] = [
  { value: 'ALTO', label: 'ALTO' },
  { value: 'MEDIO', label: 'MÉDIO' },
  { value: 'BAIXO', label: 'BAIXO' },
];

export const checklistStatusOptions: IOption[] = [
  { value: 'Aprovado', label: 'APROVADO' },
  { value: 'Aprovado com Restrição', label: 'APROVADO COM RESTRIÇÃO' },
  { value: 'Reprovado', label: 'REPROVADO' },
];

export const weighingOperationOptions: IOption[] = [
  { value: 'EXPEDICAO', label: 'EXPEDIÇÃO' },
  { value: 'RECEBIMENTO', label: 'RECEBIMENTO' },
];

export const weighingTicketStatusOptions: Array<{
  label: string;
  value: WeighingStatus;
}> = [
  { value: 'AGUARDANDO_PATIO', label: 'PORTARIA' },
  { value: 'AGUARDANDO_CHECKING', label: 'AGUARDANDO CHECK-IN' },
  { value: 'PESAGEM_ENTRADA_REALIZADA', label: 'PESAGEM DE ENTRADA REALIZADA' },
  { value: 'PESAGEM_SAIDA_REALIZADA', label: 'PESAGEM DE SAÍDA REALIZADA' },
  { value: 'VAPORIZACAO', label: 'VAPORIZAÇÃO' },
];
