import React, { useContext } from 'react';
import { MatchesPageBody } from './components/matches-page-body/matches-page-body.component';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { MatchesContextProvider } from '../../contexts/matches/matches.context';
import { PurchaseOfferContextProvider } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { SaleOfferContextProvider } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';

export const MatchesListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (!user) return <Navigate to={'/login'} replace />;

  return (
    <MatchesContextProvider>
      <PurchaseOfferContextProvider>
        <SaleOfferContextProvider>
          <MatchesPageBody />
        </SaleOfferContextProvider>
      </PurchaseOfferContextProvider>
    </MatchesContextProvider>
  );
};
