import React, { ReactNode } from 'react';
import * as S from './tag-view.styled';
import { ThemeColorsV2Type } from 'styles/types/colors';

export interface ITagViewProps {
  label: string;
  color: ThemeColorsV2Type;
  variant: 'ghost' | 'solid';
  size?: 'x-sm' | 'md' | 'lg';
  display?: 'flex' | 'inline-flex';
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
}

export const TagView: React.FC<ITagViewProps> = ({
  label,
  color,
  variant,
  size,
  display,
  LeftIcon,
  RightIcon,
}) => {
  return (
    <S.ContentTag color={color} variant={variant} size={size} display={display}>
      {LeftIcon}
      <S.Label>{label}</S.Label>
      {RightIcon}
    </S.ContentTag>
  );
};
