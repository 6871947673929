import { occurrenceUpdateService } from 'pages/occurrence/services/occurrence-update/occurrence-update.service';
import { IOccurrenceCreated } from 'domain/occurrence';

const useUpdateOccurrenceHook = () => {
  return async (company: IOccurrenceCreated) => {
    await occurrenceUpdateService(company);
  };
};

export { useUpdateOccurrenceHook };
