import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 500;
`;

export const SecondTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 8px;
`;

interface ILineSectionProps {
  columns: string;
}
export const LineSection = styled.section<ILineSectionProps>`
  ${({ columns }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: ${columns};
    grid-gap: 12px;
  `}
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20;
  padding-bottom: 50px;
`;
