import { ITransportCompaniesContext } from './transport-companies.types';

export const initialState: ITransportCompaniesContext = {
  transportCompaniesIsLoading: false,
  transportCompanies: [],
  transportCompaniesError: undefined,
  transportCompaniesPageInfo: undefined,
  setTransportCompaniesRequest: () => undefined,
  setTransportCompaniesSuccess: () => undefined,
  setTransportCompaniesError: () => undefined,
};

export enum ITransportCompaniesAction {
  TRANSPORT_COMPANIES_REQUEST = 'TRANSPORT_COMPANIES_REQUEST',
  TRANSPORT_COMPANIES_SUCCESS = 'TRANSPORT_COMPANIES_SUCCESS',
  TRANSPORT_COMPANIES_ERROR = 'TRANSPORT_COMPANIES_ERROR',
}
