import { myAccountRegisterService } from 'pages/my-account/services/my-account-register/my-account-register.service';
import { IMyAccount } from 'domain/my-account';

const useMyAccountRegisterHook = () => {
  return async (myAccountId: number, account: IMyAccount) => {
    await myAccountRegisterService(myAccountId, account);
  };
};

export { useMyAccountRegisterHook };
