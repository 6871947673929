import { FreightPriceLTL } from '../types/freight-selection';

import { FreightPriceFTLPriceData } from 'domain/scheduling';

export const initialStateScheduleLTLFreightSelection: FreightPriceFTLPriceData = {
  value: 0,
  helperPrice: 0,
  tollPrice: 0,
  grisAdvaloremPrice: 0,
  taxPrice: 0,
  total: 0,
  details: {
    advalorem: 0,
    helperQuantity: 0,
    helperAmount: 0,
    helperTotal: 0,
    gris: 0,
    valueFreight: 0,
    valueMerch: 0,
    valueTotalFee: 0,
    taxes: 0,
    toll: 0,
    totalFreight: 0,
  },
};

export const initialStateFreightLTLTotal: FreightPriceLTL = {
  recommended: {
    date: '',
    price: initialStateScheduleLTLFreightSelection,
  },
  early: {
    date: '',
    price: initialStateScheduleLTLFreightSelection,
  },
  later: {
    date: '',
    price: initialStateScheduleLTLFreightSelection,
  },
};
