import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Button } from 'logshare-ui-kit';
import { Pencil } from 'lucide-react';
import { FC, Fragment, ReactElement, useMemo } from 'react';
import { Box } from 'src-new/components/box/box.component';
import { capitalize } from 'src-new/utils/capitalize.util';
import { IScheduleDetailsOriginAndDestinationDomain } from '../../../../../../../../domains/schedule-details.domain';
import * as S from './schedule-details-origin-and-destination-view.styled';
import { Formik } from 'formik';
import { ScheduleDetailsOriginAndDestinationModalForm } from './components/schedule-details-origin-and-destination-modal-form/schedule-details-origin-and-destination-modal-form.component';
import { IScheduleOriginAndDestinationPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.types';

interface IScheduleDetailsOriginAndDestinationViewProps {
  type: 'origin' | 'destination';
  isAdmin: boolean;
  location: IScheduleDetailsOriginAndDestinationDomain;
  modalInitialValues?: IScheduleOriginAndDestinationPayload;
  handleSubmit: (values: IScheduleOriginAndDestinationPayload) => void;
  handleModalOriginAndDestination: (open: boolean, type?: 'origin' | 'destination') => void;
}

export const ScheduleDetailsOriginAndDestinyView: FC<IScheduleDetailsOriginAndDestinationViewProps> = ({
  type,
  isAdmin,
  location,
  modalInitialValues,
  handleSubmit,
  handleModalOriginAndDestination,
}) => {
  const typeOriginAndDestination = useMemo(() => {
    if (type === 'origin') return 'Origem';
    return 'Destino';
  }, [type]);

  const renderModalCargo = useMemo((): ReactElement | undefined => {
    if (modalInitialValues) {
      return (
        <Formik initialValues={modalInitialValues} onSubmit={handleSubmit}>
          <ScheduleDetailsOriginAndDestinationModalForm
            handleCloseModalOriginAndDestination={() => handleModalOriginAndDestination(false)}
          />
        </Formik>
      );
    }
  }, [handleModalOriginAndDestination, handleSubmit, modalInitialValues]);

  return (
    <Fragment>
      {renderModalCargo}
      <Box
        footerButtons={[
          <span key={0} />,
          <Button
            key={1}
            label=""
            color="white"
            onPress={() => handleModalOriginAndDestination(true, type)}
            LeftIcon={<Pencil size={20} strokeWidth={2.75} />}
            size="x-sm"
            variant="ghost"
            disabled={isAdmin}
          />,
        ]}
      >
        <S.Wrapper>
          <S.Title>{typeOriginAndDestination}</S.Title>

          <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
            <S.WrapperInformation>
              <S.InformationTitle>Nome</S.InformationTitle>
              <S.InformationBody>{capitalize(location.name)}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>CNPJ</S.InformationTitle>
              <S.InformationBody>{location.cnpj}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Tipo</S.InformationTitle>
              <S.InformationBody>{capitalize(location.type === 'CLIENT' ? 'Parceiro' : 'Unidade')}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>ID</S.InformationTitle>
              <S.InformationBody>{location.id}</S.InformationBody>
            </S.WrapperInformation>
          </LineSectionLayout>

          <LineSectionLayout columns={'1fr 1fr 1fr 1fr'}>
            <S.WrapperInformation>
              <S.InformationTitle>Bairro</S.InformationTitle>
              <S.InformationBody>{capitalize(location.address.neighborhood)}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Cidade</S.InformationTitle>
              <S.InformationBody>{capitalize(location.address.city)}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>UF</S.InformationTitle>
              <S.InformationBody>{location.address.uf}</S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>CEP</S.InformationTitle>
              <S.InformationBody>{location.address.cep}</S.InformationBody>
            </S.WrapperInformation>
          </LineSectionLayout>

          <LineSectionLayout columns={'1fr 1fr'}>
            <S.WrapperInformation>
              <S.InformationTitle>Endereço</S.InformationTitle>
              <S.InformationBody>
                {capitalize(location.address.street + ' ' + location.address.number)}
              </S.InformationBody>
            </S.WrapperInformation>

            <S.WrapperInformation>
              <S.InformationTitle>Complemento</S.InformationTitle>
              <S.InformationBody>{capitalize(location.address.complement || '-')}</S.InformationBody>
            </S.WrapperInformation>
          </LineSectionLayout>
        </S.Wrapper>
      </Box>
    </Fragment>
  );
};
