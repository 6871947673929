import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { MatchesPageBodyView } from './matches-page-body-view.component';
import { UserContext } from 'state/user-context';
import { MatchesModalType } from 'src-new/pages/opportunities/pages/matches/contexts/matches/types/matches-kanban.types';
import { MatchesContext } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { IMatchesListParams } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters.types';
import { IParamsFilter } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.types';
import { convertMatchesFilters } from 'src-new/pages/opportunities/pages/matches/utils/convert-matches-filters/convert-matches-filters.util';
import { matchesListFiltersInitialValues } from 'src-new/pages/opportunities/pages/matches/types/matches-list-filters/matches-list-filters-initial-values.types';

export const MatchesPageBody: FC = () => {
  const { isShippingCompany } = useContext(UserContext);
  const { matchesKanbanList } = useContext(MatchesContext);

  const machesFilters: Array<IParamsFilter> = useMemo(
    () => convertMatchesFilters(matchesKanbanList.matchesListFiltersProps.filters),
    [matchesKanbanList.matchesListFiltersProps.filters],
  );

  const appliedFilters = useMemo(
    (): number => machesFilters.filter((filter) => !Array.isArray(filter.value)).length,
    [machesFilters],
  );

  const handleModalOpen = useCallback(
    (modalType: MatchesModalType) => {
      matchesKanbanList.modalsProps.setModalOpen({ open: true, ids: [], modalType });
    },
    [matchesKanbanList.modalsProps],
  );

  const handleChangeSearch = useCallback(
    (search: string) => {
      const mountSearchFilter: IMatchesListParams = {
        ...matchesKanbanList.matchesListFiltersProps.filters,
        search: search,
        page: 1,
      };
      matchesKanbanList.matchesListFiltersProps.setFilters(mountSearchFilter);
      sessionStorage.setItem('matches-list-filters', JSON.stringify(mountSearchFilter));
    },
    [matchesKanbanList.matchesListFiltersProps],
  );

  const removeMatcheFilter = useCallback(
    (indexToRemove: number) => {
      const { filters, setFilters } = matchesKanbanList.matchesListFiltersProps;

      const updatedParamsArray = [...machesFilters];
      updatedParamsArray.splice(indexToRemove, 1);

      const newFilters =
        updatedParamsArray.length === 0
          ? matchesListFiltersInitialValues
          : updatedParamsArray.reduce((accumulator: any, { key }) => {
              accumulator[key as keyof IParamsFilter] = filters[key as keyof IMatchesListParams];
              return accumulator;
            }, {});

      const updatedFilters = {
        ...newFilters,
        page: 1,
      };

      setFilters(updatedFilters);
      sessionStorage.setItem('matches-list-filters', JSON.stringify(updatedFilters));
    },
    [machesFilters, matchesKanbanList.matchesListFiltersProps],
  );

  useEffect(() => {
    if (matchesKanbanList.statusRequest === 'INITIAL') {
      const cachedFilters = sessionStorage.getItem('matches-list-filters');
      if (cachedFilters) {
        matchesKanbanList.matchesListFiltersProps.setFilters(JSON.parse(cachedFilters));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MatchesPageBodyView
      isShippingCompany={isShippingCompany}
      matchesFilters={machesFilters}
      appliedFilters={appliedFilters}
      handleModalOpen={handleModalOpen}
      handleChangeSearch={handleChangeSearch}
      removeMatcheFilter={removeMatcheFilter}
    />
  );
};
