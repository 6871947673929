import moment from 'moment';

import { ScheduleRegisterInput } from 'domain-v2/schedule/register';
import { ITagsQuotation } from 'domain-v2/schedule/quotation';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';

export const convertScheduleQuotation = (schedule: ScheduleRegisterInput, tags: ITagsQuotation[]) => {
  const { origin, destination, cargo, detail, cost, retentionFines, customFields } = schedule;

  return {
    type: 'ftl',
    numero: '',
    emitido: moment().toISOString(),
    clienteOrigem: {
      nome: origin.tradeName,
      rua: origin.origin.address.street,
      numero: origin.origin.address.numberHouse,
      bairro: origin.origin.address.neighborhood,
      cidade: origin.origin.address.city,
      cep: origin.origin.address.cep,
      uf: origin.origin.address.uf,
    },
    clienteDestino: {
      nome: destination.tradeName,
      rua: destination.destination.address.street,
      numero: destination.destination.address.numberHouse,
      bairro: destination.destination.address.neighborhood,
      cidade: destination.destination.address.city,
      cep: destination.destination.address.cep,
      uf: destination.destination.address.uf,
    },
    detalheAgendamento: {
      coletaInicial: detail.levyInitialDate,
      coletaFinal: detail.levyEndDate,
      entregaInicial: detail.deliveryInitialDate,
      entregaFinal: detail.deliveryEndDate,
      numeroEmbarque: detail.boardingNumber,
      smp: detail.ocInternalNumber,
      numeroInterno: detail.internalNumber,
      senha: detail.schedulingPassword,
    },
    detalheCarga: {
      tipoVeiculo: convertVehicleTypeTypeToName(cargo.vehicleType),
      categoriaVeiculo: convertVehicleCategoryTypeToName(cargo.vehicleCategoryType),
      ajudante: false,
      escolta: false,
      paletizado: cargo.inPallet,
      paletes: cargo.pallet,
      peso: cargo.weight,
      volume: cargo.volume,
      cubagem: cargo.cubagem,
      valorCarga: cargo.merchValue,
      produtoPredominante: cargo.product,
      pedido: cargo.order,
    },
    freteDetalhe: {
      valorFrete: cost.valueFreight,
      ajudante: cost.helperTotal,
      pedagio: cost.toll,
      descarga: 0,
      tempoExcedente: 0,
      taxaLogshare: 0,
      grisAdvalorem: cost.totalGrisAdvalorem,
      desconto: 0,
      totalFrete: cost.totalFreight,
      icms: cost.icms,
      outrosImpostos: 0,
      totalServico: cost.totalService,
    },
    freeTime: {
      freeTime: retentionFines.freeTime,
      hourlyFine: retentionFines.hourlyFine,
      dailyFine: retentionFines.dailyFine,
    },
    tags: tags,
    customFields: customFields,
  };
};
