import API from 'utils/API/API';
import { AxiosError } from 'axios';
import {
  GetEligibleUsersChatServiceProps,
  IEligibleUsersChatResponse,
} from 'src-new/services/chat/get-eligible-users-chat/get-eligible-users-chat.types';
import { convertEligibleUserResponseToDomain } from './mappers/convert-eligible-user-response-to-domain/convert-eligible-user-response-to-domain';

export const getEligibleUsersChatService = async (props: GetEligibleUsersChatServiceProps) => {
  const params = mountRequestParams(props);

  try {
    const { data } = await API.get<IEligibleUsersChatResponse>(`/v2/users/eligible-chat${params}`);

    return data.map(convertEligibleUserResponseToDomain);
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      return [];
    }

    throw error;
  }
};

const mountRequestParams = (props: GetEligibleUsersChatServiceProps): string => {
  if (props.flowType === 'SCHEDULE') {
    return `?scheduleId=${props.scheduleId}`;
  } else {
    return `?saleOfferId=${props.saleOfferId}&purchaseOfferId=${props.buyOfferId}`;
  }
};
