import { useCallback, useContext } from 'react';
import { showToast } from 'components/toast/toast';
import { ChatType } from 'src-new/components/chat-button/chat-button.types';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { getConversationChatByIdService } from 'src-new/services/chat/get-conversation-chat-by-id/get-conversation-chat-by-id.service';
import { IConversationByIdResponse } from 'src-new/services/chat/get-conversation-chat-by-id/get-conversation-chat-by-id.types';

const ENVIRONMENT = import.meta.env.VITE_FIREBASE_TARGET;

export const useCheckExistingThreadsChat = () => {
  const { chatEligibleUsers } = useContext(ChatContext);
  const { validateThreadsActions } = chatEligibleUsers;
  const { setValidateThreadRequest, setValidateThreadSuccess } = validateThreadsActions;

  return useCallback(
    async (chat: ChatType): Promise<boolean> => {
      setValidateThreadRequest();

      try {
        if (chat.typeChat === 'GROUP') {
          const chatListPromises: Array<Promise<IConversationByIdResponse | undefined>> = chat.chatsObjects.map(
            (chat) => getConversationChatByIdService(chat.chatId),
          );
          const chatList = await Promise.all(chatListPromises);
          const haveCreated =
            chatList.filter((id): id is IConversationByIdResponse => id !== undefined).length ===
            chat.chatsObjects.length;

          setValidateThreadSuccess(haveCreated);

          return haveCreated;
        } else {
          const threadId = `${ENVIRONMENT}-${chat.chatId}`;
          const conversation: IConversationByIdResponse | undefined = await getConversationChatByIdService(threadId);
          const haveCreated = !!conversation;

          setValidateThreadSuccess(haveCreated);

          return haveCreated;
        }
      } catch (error) {
        showToast({
          message: 'Ocorreu um erro ao tentar validar as conversas existentes',
          type: 'error',
        });
        return true;
      }
    },
    [setValidateThreadRequest, setValidateThreadSuccess],
  );
};
