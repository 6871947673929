import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import * as S from './body.styled';
import { IConfigBody, IRow } from '../../table.types';

interface IBodyProps {
  rows: Array<IRow>;
  gridColumns: string;
  isLoading?: boolean;
  config?: IConfigBody;
  onInfinityScroll?: Dispatch<SetStateAction<number>>;
  alignLinesCenter?: boolean;
}

export const Body: React.FC<IBodyProps> = ({
  rows,
  gridColumns,
  config,
  alignLinesCenter,
  onInfinityScroll,
  isLoading,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const tableWrapperRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    const div = divRef.current;

    if (onInfinityScroll && div && rows.length > 0) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && rows.length >= 10 && !isLoading) {
              onInfinityScroll((prevState) => prevState + 1);
            }
          });
        },
        {
          root: tableWrapperRef.current,
          rootMargin: '0px',
          threshold: 0.5,
        },
      );

      observer.observe(div);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLoading, onInfinityScroll, rows.length]);

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;

    const checkScroll = () => {
      if (tableWrapper && tableWrapper.scrollHeight > tableWrapper.clientHeight) {
        tableWrapper.classList.add('active-scrollbar');
      } else {
        tableWrapper?.classList.remove('active-scrollbar');
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);

    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const buildRows = rows.map(({ valuesColumns, key }, index) => {
    const cells = valuesColumns.map(({ value, isClicable = true, width }, index) => (
      <S.TDataStyled
        alignLinesCenter={alignLinesCenter}
        onClick={(e) => !isClicable && e.stopPropagation()}
        key={`${index}-${valuesColumns}`}
        width={width}
      >
        {value}
      </S.TDataStyled>
    ));
    return (
      <S.TRowStyled
        gridColumn={gridColumns}
        isZebraLine={!!config?.isZebraLine}
        changeLineOnMouseOver={!!config?.changeLineOnMouseOver}
        key={`row-table-${key}-${index}`}
        onClick={rows[index].onClick}
        onMouseEnter={rows[index].onMouseEnter}
        cursorType={config?.cursorInitial ? 'initial' : 'pointer'}
      >
        {cells}
      </S.TRowStyled>
    );
  });

  return (
    <S.TBodyStyled disabledScroll={config?.isDisabledScroll} ref={tableWrapperRef}>
      {buildRows}
      <div ref={divRef} style={{ height: '2px' }} />
    </S.TBodyStyled>
  );
};
