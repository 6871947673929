import React from 'react';

import SubmenuView from './submenu-view.component';

interface ISubmenuProps {
  openModals: (modalType: 'newFreight' | 'newPlan') => void;
}

const Submenu: React.FC<ISubmenuProps> = ({ openModals }) => {
  return <SubmenuView openModals={openModals} />;
};

export default Submenu;
