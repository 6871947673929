import { HomeModalsActionType } from '../../home.action';
import { IHomeModalsActions } from '../../types/home-modals.types';
import { IHomeContext } from '../../types/home.types';

export const homeModalsReducer = (state: IHomeContext, action: IHomeModalsActions): IHomeContext => {
  switch (action.type) {
    case HomeModalsActionType.HOME_MODALS_OPEN:
      return {
        ...state,
        homeModals: {
          ...state.homeModals,
          open: true,
          id: action.payload.id,
          homeModalType: action.payload.modalType,
        },
      };
    case HomeModalsActionType.HOME_MODALS_CLOSE:
      return {
        ...state,
        homeModals: {
          ...state.homeModals,
          open: false,
          id: 0,
          homeModalType: '',
        },
      };

    default:
      return state;
  }
};
