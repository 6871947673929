/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { InvoincingReportExcelService } from '../services/invoincing-report-excel/invoincing-report-excel.service';
import {
  UploadedColumn,
  createFinancialService,
  createFinancialServiceTransporter,
  deleteUploadedFiles,
  excelListFinancialService,
  getInvoicingService,
  listAvailableSchedulesByCompanyId,
  listFinancialService,
  uploadFileInvoice,
  verifyCompanyOfShippingCompanyIf,
} from '../services';
import {
  FinancialCreate,
  FinancialExcelListFilter,
  FinancialListFilter,
  FinancialListSchedulesParams,
  FinancialUploadFile,
} from '../models';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { DownloadFile } from 'utils/download-file/download-file';

export const useFinancialListHook = () => {
  return useCallback(async (filter: FinancialListFilter) => {
    return await listFinancialService({
      limit: filter.limit || 50,
      page: filter.page || 1,
      search: filter.search || undefined,
      type: filter.type || undefined,
      order: filter.order || undefined,
      invoiceType: filter.invoiceType || undefined,
    });
  }, []);
};

export const UseFinancialExcelListHook = async (filter: FinancialExcelListFilter) => {
  try {
    const file = await excelListFinancialService({
      search: filter.search,
      type: filter.type,
      order: filter.order,
    });

    if (file) {
      DownloadFile(file, 'vnd.ms-excel;', 'FATURAMENTO');
    }
  } catch (error) {
    showMessageFormatted({
      message: '',
      error: error as any,
      type: 'error',
    });
  }
};

export const useGetInvoiceHook = () => {
  return useCallback(async (id: number) => {
    return await getInvoicingService(id);
  }, []);
};

export const useUploadFileInvoiceHook = () => {
  return useCallback(async (id: number, payload: FinancialUploadFile) => {
    try {
      return await uploadFileInvoice(id, payload);
    } catch (error) {
      showMessageFormatted({
        message: '',
        error: error as any,
        type: 'error',
      });
    }
  }, []);
};

export const useFinancialAvailableScheduleListHook = () => {
  return useCallback(async (filter: FinancialListSchedulesParams) => {
    try {
      return await listAvailableSchedulesByCompanyId({
        limit: filter.limit || 4,
        page: filter.page || 1,
        companyId: filter.companyId,
      });
    } catch (error) {
      showMessageFormatted({
        message: '',
        error: error as any,
        type: 'error',
      });
    }
  }, []);
};

export const useFinancialCreateHook = () => {
  return useCallback(async (payload: FinancialCreate) => {
    try {
      return await createFinancialService(payload);
    } catch (error) {
      showMessageFormatted({
        message: '',
        error: error as any,
        type: 'error',
      });
    }
  }, []);
};

export const useFinancialCreateHookTransporter = () => {
  return useCallback(async (payload: FinancialCreate) => {
    try {
      return await createFinancialServiceTransporter(payload);
    } catch (error) {
      showMessageFormatted({
        message: '',
        error: error as any,
        type: 'error',
      });
    }
  }, []);
};

export const verifyShippingCompanyIdHook = async (shippingCompanyId: number) => {
  try {
    return await verifyCompanyOfShippingCompanyIf(shippingCompanyId);
  } catch (error) {
    showMessageFormatted({
      message: '',
      error: error as any,
      type: 'error',
    });
  }
};

export const useDeleteFileUploadedHook = () => {
  return async (id: number, type: string) => {
    return await deleteUploadedFiles(id, type);
  };
};

export const useUploadedColumnHook = () => {
  return async (id: string, data: any) => {
    return await UploadedColumn(id, data);
  };
};

export const useReportExcelInvoincingHook = () => {
  return async (): Promise<BlobPart | undefined> => {
    return await InvoincingReportExcelService();
  };
};
