import { Formik } from 'formik';
import { FC, useCallback } from 'react';
import { IWeighingFormRegisterDomain } from '../../domains/weighing-register.domain';
import { WeighingRegisterFormView } from './weighing-register-form-view.component';
import { useRegisterWeighing } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/hooks/use-weighing-register/use-weighing-register.hook';
import { weighingFormRegisterInitialValuesDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-register/domains/weighing-register-initial-values.domain';

export const WeighingRegisterForm: FC = () => {

  const weighingRegisterHook = useRegisterWeighing();

  const handleSubmit = useCallback(
    async (values: IWeighingFormRegisterDomain) => {
      await weighingRegisterHook(values);
    },
    [weighingRegisterHook],
  );

  return (
    <Formik initialValues={weighingFormRegisterInitialValuesDomain} onSubmit={handleSubmit}>
      <WeighingRegisterFormView />
    </Formik>
  );
};
