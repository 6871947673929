import styled, { css } from 'styled-components';
export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const OptionStyled = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.gray};
    border-radius: -5px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;

    :hover {
      background-color: ${theme.colors.gray};
    }
  `}
`;
