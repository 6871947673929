import React from 'react';
import { ReactNode, memo, useContext, useMemo } from 'react';

import * as S from './styled';

import { UserContext } from 'state/user-context';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface HeaderTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  clickRowAction: (transportId: string) => void;
  clickOrderTableBy?: (type: string) => void;
  clickSortDirection?: () => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;
  orderSelected?: { orderBy: string; sortDirection: string };
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  clickOrderTableBy,
  clickSortDirection,
  arrowOrder,
  isLoading,
  orderSelected,
}) => {
  const { isAdmin, user } = useContext(UserContext);

  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.1,
      },
      {
        value: 'ID',
        flex: isAdmin ? 0.2 : 0.15,
      },

      isAdmin
        ? {
            value: 'Solicitado Por',
            flex: 0.5,
          }
        : {
            value: '',
            flex: 0,
          },

      {
        value: 'Categoria',
        flex: 0.3,
      },

      {
        value: 'Data Coleta',
        flex: isAdmin ? 0.5 : 0.45,
      },
      {
        value: 'Data Entrega',
        flex: isAdmin ? 0.5 : 0.45,
      },
      {
        value: 'Origem',
        flex: 0.8,
      },
      {
        value: 'Destino',
        flex: isAdmin ? 0.8 : 0.85,
      },
      {
        value: 'Veículo',
        flex: isAdmin ? 0.4 : 0.45,
      },

      user?.profile !== 'shipping-company'
        ? {
            value: 'Operado Por',
            flex: 0.5,
          }
        : {
            value: '',
            flex: 0,
          },

      {
        value: 'Status',
        flex: isAdmin ? 0.5 : 0.8,
      },
      {
        value: '',
        flex: 0.1,
      },
      {
        value: '',
        flex: 0.1,
      },
    ];
  }, []);

  const renderPagination = useMemo(() => {
    if (pageInfo) {
      return (
        <S.ContainerPagination>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </S.ContainerPagination>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <div>
      <Table
        orderSelected={orderSelected}
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        clickOrderBy={clickOrderTableBy}
        clickSortDirection={clickSortDirection}
        arrowOrder={arrowOrder}
        zIndex
      />
      {renderPagination}
    </div>
  );
};

export default memo(HeaderTable);
