import dayjs from 'dayjs';
import {useFormikContext} from 'formik';
import {
  IUpdateScheduleCargoPayload
} from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import {ScheduleDetailsDatesModalFormView} from './schedule-details-dates-modal-form-view.component';

export interface IScheduleDetailsDatesModalFormProps {
  handleCloseModalComments: () => void;
}

export const ScheduleDetailsDatesModalForm: React.FC<IScheduleDetailsDatesModalFormProps> = ({
                                                                                               handleCloseModalComments,
                                                                                             }) => {
  const {setFieldValue, values, handleSubmit} = useFormikContext<IUpdateScheduleCargoPayload>();
  const handleFieldDateChange = (fieldName: string, date: dayjs.Dayjs | null) => {
    setFieldValue(fieldName, date?.toISOString());
    switch (fieldName) {
      case 'detail.levyInitialDate':
        setFieldValue('detail.levyEndDate', date?.toISOString());
        setFieldValue('detail.deliveryInitialDate', '');
        setFieldValue('detail.deliveryEndDate', '');
        break;
      case 'detail.levyEndDate':
        setFieldValue('detail.deliveryInitialDate', '');
        setFieldValue('detail.deliveryEndDate', '');
        break;
      case 'detail.deliveryInitialDate':
        setFieldValue('detail.deliveryEndDate', date?.toISOString());
        break;
    }
  };

  const validateDateFields = () => {
    const isValid =
      dayjs(values.detail.levyInitialDate).isValid() &&
      dayjs(values.detail.levyEndDate).isValid() &&
      dayjs(values.detail.deliveryInitialDate).isValid() &&
      dayjs(values.detail.deliveryEndDate).isValid();
    return isValid;
  };

  const handleOnSubmit = () => {
    if (validateDateFields()) handleSubmit();
  };

  return (
    <ScheduleDetailsDatesModalFormView
      values={values}
      handleFieldDateChange={handleFieldDateChange}
      handleOnSubmit={handleOnSubmit}
      handleCloseModalComments={handleCloseModalComments}
    />
  );
};
