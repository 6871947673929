import Input from 'components/input/input';
import { FC, Fragment, useMemo } from 'react';
import { InputRadio } from 'src-new/components/input-radio/input.radio.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { Table } from 'src-new/components/table/table.component';
import { IScheduleDetailsAllocationVehicleDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/schedule-details-allocation-vehicle.domain';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { IInfiniteScrollTable } from 'src-new/components/table/table.types';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { EmptyState } from 'src-new/components/empty-state/empty-state.component';

interface IStatusProps {
  isError: boolean;
  isLoading: boolean;
  isEmpty: boolean;
}

interface IScheduleDetailsAllocationVehicleListModalViewProps {
  statusProps: IStatusProps;
  vehicles: Array<IScheduleDetailsAllocationVehicleDomain>;
  vehicleSelected: {
    checked: boolean;
    vehicleId: number;
  };
  handleSearch: (search: string) => void;
  infiniteScrollProps: IInfiniteScrollTable;
  handleSelectedVehicle: (checked: boolean, driverId: number) => void;
}

export const ScheduleDetailsAllocationVehicleListModalView: FC<IScheduleDetailsAllocationVehicleListModalViewProps> = ({
  statusProps,
  vehicles,
  vehicleSelected,
  handleSearch,
  infiniteScrollProps,
  handleSelectedVehicle,
}) => {
  const renderTableRows = useMemo(() => {
    return vehicles.map((vehicle, index) => {
      return {
        key: index,
        valuesColumns: [
          {
            value: (
              <InputRadio
                id={vehicle.id ? vehicle.id.toString() : '0'}
                name={`vehicle_${vehicle.id}`}
                onChange={(event) => {
                  handleSelectedVehicle(event.target.checked, vehicle.id);
                }}
                checked={vehicleSelected.vehicleId === vehicle.id}
              />
            ),
          },
          { value: vehicle.mainBoard },
          { value: vehicle.secondBoard || '-' },
          { value: vehicle.thirdBoard || '-' },
          { value: vehicle.shippingName || 'Frota Própria' },
          { value: convertVehicleType(vehicle.type) },
          { value: 'ativo' },
        ],
      };
    });
  }, [handleSelectedVehicle, vehicleSelected.vehicleId, vehicles]);

  const renderContent = useMemo(() => {
    if (statusProps.isLoading) return <Loading />;

    if (statusProps.isError) return <ErrorState />;

    if (statusProps.isEmpty) return <EmptyState />;

    return (
      <Table
        header={[
          { value: '' },
          { value: 'Placa 1' },
          { value: 'Placa 2' },
          { value: 'Placa 3' },
          { value: 'Transportadora' },
          { value: 'Tipo' },
          { value: 'Status' },
        ]}
        rows={renderTableRows}
        gridColumns={'0.5fr 1fr 1fr 1fr 1fr 1fr 1fr'}
        infiniteScrollProps={infiniteScrollProps}
        height="48vh"
      />
    );
  }, [infiniteScrollProps, renderTableRows, statusProps.isEmpty, statusProps.isError, statusProps.isLoading]);

  return (
    <Fragment>
      <Input
        id="search"
        name="search"
        placeholder="Busque pelos dados cadastrais"
        type="text"
        changeValue={(text) => handleSearch(text)}
        isClear={false}
      />
      {renderContent}
    </Fragment>
  );
};
