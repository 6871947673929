import { MatchRegisterInput } from 'domain-v2/match/register';
import { ModalMatchDetails } from '../../types';
import { VehicleCategory, VehicleType } from 'domain-v2/inputs/vehicles';

export const convertMatchDetailsToMatchRegister = (transportRouter: ModalMatchDetails): MatchRegisterInput => {
  return {
    ...transportRouter,
    saleOfferId: transportRouter.saleOfferId,
    saleOfferShipperTarifId: transportRouter.saleOfferTariffId,
    matchOfferId: transportRouter.matchOfferId,
    companySeller: transportRouter.company.id,
    haulType: transportRouter.haulType,
    invoicedBy: transportRouter.invoicedBy,
    origin: {
      ...transportRouter.origin,
      tradeName: `${transportRouter.origin.name.toUpperCase()} - ${transportRouter.origin.city.toUpperCase()} - ${transportRouter.origin.uf.toUpperCase()}`,
      type: transportRouter.origin.type === 'LOCALITY' ? 'LOCALITY' : 'CLIENT',
    },
    destination: {
      ...transportRouter.destination,
      tradeName: `${transportRouter.destination.name.toUpperCase()} - ${transportRouter.destination.city.toUpperCase()} - ${transportRouter.destination.uf.toUpperCase()}`,
      type: transportRouter.destination.type === 'LOCALITY' ? 'LOCALITY' : 'CLIENT',
    },
    cargo: {
      comments: '',
      cubagem: 0,
      inPallet: !!transportRouter.pallets,
      merchValue: 0,
      order: '',
      pallet: transportRouter.pallets,
      product: '',
      requirements: {},
      shippingReturn: '',
      vehicleCategoryType: transportRouter.vehicle.category as VehicleCategory,
      vehicleType: transportRouter.vehicle.type as VehicleType,
      volume: 0,
      weight: transportRouter.weight,
    },
    dates: [
      {
        initialLevyDate: '',
        finalLevyDate: '',
        initialDeliveryDate: '',
        finalDeliveryDate: '',
      },
    ],
    cost: {
      ...transportRouter,
      tollValue: 0,
      grisAdvalorem: 0,
      freightTotal: 0,
      icms: 0,
      totalService: 0,
    },
  };
};
