import { LineSectionLayout } from 'components-v2/layout/line-section';
import { InputCurrency } from 'components/input-currency/input-currency';
import InputField from 'components/input-field/input-field';
import Select from 'components/select/select';
import { IOption } from 'domain/global-inputs';
import { FormikErrors } from 'formik';
import { Button } from 'logshare-ui-kit';
import { ChangeEvent, FC, Fragment, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IUpdateScheduleCargoPayload } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/services/update-schedule-details/update-schedule-details.types';
import { normalizeDecimal } from 'src-new/utils/normalize-currency.util';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import * as S from './schedule-details-cargo-modal-form-view.styled';

interface IScheduleDetailsCargoModalFormViewProps {
  values: IUpdateScheduleCargoPayload;
  updateIsLoading: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IUpdateScheduleCargoPayload>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  handleCloseModalCargo: () => void;
}

const yesNoOptions: IOption[] = [
  { value: true, label: 'SIM' },
  { value: false, label: 'NÃO' },
];

export const ScheduleDetailsCargoModalFormView: FC<IScheduleDetailsCargoModalFormViewProps> = ({
  values,
  updateIsLoading,
  setFieldValue,
  handleChange,
  handleOnSubmit,
  handleCloseModalCargo,
}) => {
  const customFieldsValues = useMemo(() => {
    if (values.customFields && values.customFields.length > 0) {
      return values.customFields.map(({ id, isRequired, label }, index) => {
        const asterisk = isRequired ? '*' : '';

        const inputValue = values.customFields ? values.customFields[index]?.value : '';

        return (
          <InputField
            key={id}
            label={`${label} ${asterisk}`}
            id={`customFields[${index}].value`}
            name={`customFields[${index}].value`}
            type="text"
            onChange={handleChange}
            value={inputValue}
            hasError={!inputValue && isRequired}
            errorMessage={'Campo obrigatório'}
          />
        );
      });
    }

    return (
      <Fragment>
        <InputField
          label="Nº Interno"
          id="detail.internalNumber"
          name="detail.internalNumber"
          type="text"
          onChange={handleChange}
          value={values.detail.internalNumber}
        />

        <InputField
          label="Nº OC Interna"
          id="detail.ocInternalNumber"
          name="detail.ocInternalNumber"
          type="text"
          onChange={handleChange}
          value={values.detail.ocInternalNumber}
        />

        <InputField
          label="Nº Embarque"
          id="detail.boardingNumber"
          name="detail.boardingNumber"
          type="text"
          onChange={handleChange}
          value={values.detail.boardingNumber}
        />

        <InputField
          label="Senha"
          id="detail.schedulingPassword"
          name="detail.schedulingPassword"
          type="text"
          onChange={handleChange}
          value={values.detail.schedulingPassword}
        />
      </Fragment>
    );
  }, [
    handleChange,
    values.customFields,
    values.detail.boardingNumber,
    values.detail.internalNumber,
    values.detail.ocInternalNumber,
    values.detail.schedulingPassword,
  ]);

  return (
    <ModalBark size={'sm'} title={<S.ModalTitle>Detalhes da Carga</S.ModalTitle>} handleClose={handleCloseModalCargo}>
      <S.ModalWrapper>
        <S.FormWrapper>
          <LineSectionLayout columns="1fr 1fr 1fr 1fr">
            <InputField
              label="Tipo de Veículo"
              id="cargo.vehicleType"
              name="cargo.vehicleType"
              type="text"
              onChange={handleChange}
              value={convertVehicleType(values.cargo.vehicleType)}
              disabled
            />

            <InputField
              label="Categoria"
              id="cargo.vehicleCategoryType"
              name="cargo.vehicleCategoryType"
              type="text"
              onChange={handleChange}
              value={convertVehicleCategory(values.cargo.vehicleCategoryType)}
              disabled
            />

            <InputField
              label="Frete Retorno"
              id="cargo.shippingReturn"
              name="cargo.shippingReturn"
              type="text"
              onChange={handleChange}
              value={values.cargo.shippingReturn}
            />

            <InputField
              label="Produto Predominante"
              id="cargo.product"
              name="cargo.product"
              type="text"
              onChange={handleChange}
              value={values.cargo.product}
            />
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr 1fr 1fr">
            <InputCurrency
              label="Peso (Kg)"
              id="cargo.weight"
              name="cargo.weight"
              type="text"
              allowDecimals={false}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.cargo.weight}
              suffix=" Kg"
            />

            <InputCurrency
              label="Cubagem (m3)"
              id="cargo.cubagem"
              name="cargo.cubagem"
              type="number"
              suffix=" m³"
              allowDecimals={false}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={0}
              decimalsLimit={0}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.cargo.cubagem}
            />

            <InputCurrency
              label="Volume(s)"
              id="cargo.volume"
              name="cargo.volume"
              type="number"
              suffix=" Vol"
              allowDecimals={false}
              decimalSeparator=","
              groupSeparator="."
              decimalScale={0}
              decimalsLimit={0}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values.cargo.volume}
            />

            <InputCurrency
              label="Valor da Mercadoria"
              id="cargo.merchValue"
              name="cargo.merchValue"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => setFieldValue('cargo.merchValue', value ?? '0')}
              value={normalizeDecimal(values.cargo.merchValue)}
            />

            <Select
              label="Paletizado *"
              id="cargo.inPallet"
              name="cargo.inPallet"
              value={values.cargo.inPallet}
              setFieldValue={(field, value) => {
                if (value === 'yes') {
                  setFieldValue(field, value);
                } else {
                  setFieldValue('pallet', '');
                  setFieldValue(field, value);
                }
              }}
              options={yesNoOptions}
            />
            <InputField
              label="Paletes"
              id="cargo.pallet"
              name="cargo.pallet"
              type="number"
              onChange={handleChange}
              value={values.cargo.pallet}
              disabled={!values.cargo.inPallet}
            />
          </LineSectionLayout>

          <LineSectionLayout columns="1fr 1fr 1fr 1fr">{customFieldsValues}</LineSectionLayout>
        </S.FormWrapper>

        <S.WrapperButtonSubmit>
          <Button
            label="Atualizar"
            color="blue"
            onPress={handleOnSubmit}
            size="medium"
            variant="solid"
            isLoading={updateIsLoading}
          />
        </S.WrapperButtonSubmit>
      </S.ModalWrapper>
    </ModalBark>
  );
};
