import { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';

import { ContainerStyled, LineSectionStyled, ModalFormStyled } from './index.style';

import { IAllocationCargo } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { needHelperOptions, vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';

const booleanAnswersOptions: {
  label: string;
  value: boolean;
}[] = [
  { value: true, label: 'SIM' },
  { value: false, label: 'NÃO' },
];

export const FreightCargoDetailsForm: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<IAllocationCargo>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleInPalet = (field: string, value: string) => {
    setFieldValue(field, value || false);

    values.inPalet && setFieldValue('pallet', 0);
  };

  return (
    <>
      <ModalFormStyled>
        <ContainerStyled>
          <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr">
            <Select
              label="Tipo de Veículo"
              id="vehicleType"
              name="vehicleType"
              value={values.vehicleType}
              setFieldValue={setFieldValue}
              maxMenuHeight={100}
              options={vehicleTypeOptions}
            />

            <Select
              label="Categoria"
              id="vehicleCategoryType"
              name="vehicleCategoryType"
              value={values.vehicleCategoryType}
              setFieldValue={setFieldValue}
              maxMenuHeight={100}
              options={vehicleCategoryOptions}
            />

            <Select
              label="Ajudante"
              id="needHelper"
              name="needHelper"
              value={values.needHelper || 'sem_ajuda'}
              setFieldValue={setFieldValue}
              maxMenuHeight={100}
              options={needHelperOptions}
            />

            <Select
              label="Paletizado"
              id="inPalet"
              name="inPalet"
              value={values.inPalet}
              setFieldValue={(field, value) => handleInPalet(field, value)}
              maxMenuHeight={100}
              options={booleanAnswersOptions}
            />

            <InputCurrency
              label="Paletes"
              id="pallet"
              name="pallet"
              type="number"
              allowDecimals={false}
              defaultValue={0}
              onValueChange={(values: any) => setFieldValue('pallet', Number(values))}
              value={values.pallet || 0}
              disabled={!values.inPalet}
            />
          </LineSectionStyled>

          <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr">
            <InputCurrency
              label="Peso"
              id="weight"
              name="weight"
              type="number"
              allowDecimals={false}
              defaultValue={0}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(values: any) => setFieldValue('weight', Number(values))}
              value={values.weight || 0}
              suffix=" Kg"
            />

            <InputCurrency
              label="Volume"
              id="capacity"
              name="capacity"
              type="number"
              allowDecimals={false}
              defaultValue={0}
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(values: any) => setFieldValue('capacity', Number(values))}
              value={values.capacity || 0}
              suffix=" VOL"
            />

            <InputCurrency
              label="Valor da Carga"
              id="shippingValue"
              name="shippingValue"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value, name) => setFieldValue(name ?? '', value)}
              value={values?.shippingValue}
            />

            <InputField
              label="Produto"
              id="invoice"
              name="invoice"
              type="text"
              onChange={handleChange}
              value={values.invoice}
            />

            <InputField
              label="Pedido(s)"
              id="orderNumber"
              name="orderNumber"
              type="text"
              onChange={handleChange}
              value={values.orderNumber}
            />
          </LineSectionStyled>
        </ContainerStyled>
      </ModalFormStyled>
    </>
  );
};
