import { FC, useMemo } from 'react';
import { Tag } from 'logshare-ui-kit';
import * as S from './styled';
import { convertHaulType } from 'utils-v2/converters/haul-type';
import { formatDecimal } from 'utils/format-numbers';
import { ColorsV2Type } from 'styles/types/colors';
import { Operation } from 'domain-v2/inputs/operation';
import { HotIdeal } from 'assets/icons/hot-ideal';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { OperationTypeResponse } from 'src-new/domains/operation-type';
import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';

interface ISubtitleProps {
  haulType: Operation;
  scheduleType: string;
  operationType: string;
  co2: number;
  exclusiveRate: boolean;
  requirements?: Array<string>;
}

export const ModalFormSubTitle: FC<ISubtitleProps> = ({
  haulType,
  scheduleType,
  operationType,
  co2,
  exclusiveRate,
  requirements,
}) => {
  const renderRequirements = useMemo(() => {
    if (requirements?.length === 0) return undefined;

    return requirements?.map((tag) => (
      <Tag key={tag} label={tag} color={ColorsV2Type.Blue} variant="solid" display="inline-flex" size="lg" />
    ));
  }, [requirements]);

  const renderExclusiveRate = useMemo(() => {
    if (!exclusiveRate) return undefined;

    return (
      <Tag
        label="Tarifa Exclusiva"
        color={ColorsV2Type.Red}
        LeftIcon={<HotIdeal />}
        variant="ghost"
        display="flex"
        size="lg"
      />
    );
  }, [exclusiveRate]);

  return (
    <S.WrapperTags>
      <Tag
        label={convertHaulType(haulType).label}
        color={convertHaulType(haulType).color}
        variant="solid"
        display="inline-flex"
        size="lg"
      />
      {renderExclusiveRate}
      <Tag
        LeftIcon={
          <S.TagText>
            <DoubleArrowDownBlueIcon />
            CO₂
          </S.TagText>
        }
        label={`${formatDecimal(co2 || 0)} KG`}
        color={ColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />
      <Tag
        label={scheduleType}
        color={ColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />
      <Tag
        label={convertOperationTypeToDomain(operationType as OperationTypeResponse)}
        color={ColorsV2Type.Blue}
        variant="ghost"
        display="inline-flex"
        size="lg"
      />
      {renderRequirements}
    </S.WrapperTags>
  );
};
