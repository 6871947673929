import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { convertWeighingRegisterDomainToEntity } from './mappers/convert-weighing-register-domain-to-entity/convert-weighing-register-domain-to-entity.mapper';
import { registerDuplicatedWeighingService } from '../../services/register-duplicated-weighing/register-duplicated-weighing.service';
import { IWeighingDuplicatedDomain } from '../../domains/weighing-duplicated.domain';
import { useNavigate } from 'react-router-dom';

export const useDuplicatedRegisterWeighing = () => {
  const reactQueryClient = useQueryClient();
  const navigate = useNavigate();

  return useCallback(
    async (payload: IWeighingDuplicatedDomain) => {
      try {
        await registerDuplicatedWeighingService(convertWeighingRegisterDomainToEntity(payload));
        await reactQueryClient.invalidateQueries(['weighing-list-table']);
        showMessageFormatted({
          message: TOAST_MESSAGE.REGISTER,
          type: 'success',
        });
        navigate('/pesagem');
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [navigate, reactQueryClient],
  );
};
