import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { initialValues, PATH_TO_TRACKING_OCCURRENCE_REGISTER } from '../form-values/form-values';
import { TrackingOccurrenceRegisterSchema } from '../../../schemas-validation/schemas-validation';

import NegotiationsModalTableView from './negotiations-modal-form-view';

import { UserContext } from 'state/user-context';
import { useMyAccountDetailsHook } from 'pages/my-account/hooks/use-my-account-details/use-my-account-details.hook';
import { IOccurrenceResolution } from 'domain/tracking-occurrence';
import { IMyAccount } from 'domain/my-account';

const MyAccountValues: IMyAccount = {
  name: '',
  login: '',
  email: '',
  telephone: '',
  celphone: '',
};

interface IProps {
  submitModal: (values: IOccurrenceResolution) => void;
}

const NegotiationsModalTable: React.FC<IProps> = ({ submitModal }) => {
  const navigate = useNavigate();

  const [myAccount, setMyAccount] = useState<IMyAccount>(MyAccountValues);

  const { user } = useContext(UserContext);
  const myAccountDetailsHook = useMyAccountDetailsHook();

  useEffect(() => {
    if (user?.id) {
      myAccountDetailsHook(user?.id).then((myAccount) => setMyAccount(myAccount));
    }
  }, [myAccountDetailsHook, user?.id]);

  const goBackToTrackingOccurrenceRegister = useCallback(
    () => navigate(PATH_TO_TRACKING_OCCURRENCE_REGISTER),
    [navigate],
  );

  const handleSubmit = useCallback(
    (values: IOccurrenceResolution) => {
      if (values) {
        submitModal(values);
      }
    },
    [submitModal],
  );

  return (
    <NegotiationsModalTableView
      trackingOccurrenceNegotiationsRegisterValues={initialValues}
      myAccountValues={myAccount}
      handleGoBack={goBackToTrackingOccurrenceRegister}
      handleRegister={handleSubmit}
      schemaValidation={TrackingOccurrenceRegisterSchema}
    />
  );
};

export default NegotiationsModalTable;
