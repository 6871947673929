import React, { ReactNode, useContext, useMemo } from 'react';

import TransportCompanyTableView from './transport-company-table-view';

import { UserContext } from 'state/user-context';
import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface ITransportCompanyTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToDetailsPage: (transportId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const TransportCompanyTable: React.FC<ITransportCompanyTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToDetailsPage,
  isLoading,
  arrowOrder,
  clickOrderBy = () => null,
  clickSortDirection = () => null,
}) => {
  const { isAdmin } = useContext(UserContext);
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: '',
        flex: 0.2,
      },
      {
        value: 'ID',
        flex: 0.5,
      },
      {
        value: isAdmin ? 'Parceiro' : ' ',
        flex: isAdmin ? 0.8 : 0,
      },
      {
        value: 'Transportadora',
        flex: 2,
      },
      {
        value: 'CNPJ',
        flex: 1,
      },
      {
        value: 'Cidade - UF',
        flex: 1.3,
      },
      {
        value: 'Status',
        flex: 0.45,
      },
    ];
  }, []);

  return (
    <TransportCompanyTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      clickRowAction={goToDetailsPage}
      isLoading={isLoading}
      clickOrderBy={clickOrderBy}
      clickSortDirection={clickSortDirection}
    />
  );
};

export default TransportCompanyTable;
