import { FormikErrors } from 'formik';
import { FC, useContext } from 'react';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { WeighingFormMountShippingView } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/components/weighing-update-form/components/weighing-update-form-mount/components/weighing-update-form-mount-shipping/weighing-update-form-mount-shipping-view.component';
import { UserContext } from 'state/user-context';

interface IWeighingFormMountShippingProps {
  values: IWeighingDomain;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDomain>>;
}

export const WeighingFormMountShipping: FC<IWeighingFormMountShippingProps> = ({ values,hasError, setFieldValue }) => {
  const { user } = useContext(UserContext);

  return <WeighingFormMountShippingView hasError={hasError} values={values} setFieldValue={setFieldValue} isMaster={!!user?.isMaster}/>;
};
