export const hiddenFilters: Array<string> = [
  'page',
  'column',
  'limit',
  'order',
  'activeTabName',
  'financialStatus',
  'filterIdentifier',
  'financialStatus',
];
