import { useCallback, useReducer } from 'react';
import { ScheduleListInitialLoadingActionType } from '../../schedule.action';
import { scheduleInitialState } from '../../schedule.constants';
import { IScheduleListInitialLoading } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list-initial-loading.types';
import { scheduleListInitialLoadingReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-list-initial-loading/schedule-list-initial-loading.reducer';

export const useScheduleListInitialLoadingContextValues = (): IScheduleListInitialLoading => {
  const [state, dispatch] = useReducer(scheduleListInitialLoadingReducer, scheduleInitialState);

  const setScheduleListInitialLoadingSuccess = useCallback(() => {
    dispatch({ type: ScheduleListInitialLoadingActionType.SCHEDULE_LIST_INITIAL_LOADING_SUCCESS, payload: undefined });
  }, []);

  const setScheduleListInitialLoadingReset = useCallback(() => {
    dispatch({ type: ScheduleListInitialLoadingActionType.SCHEDULE_LIST_INITIAL_LOADING_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleListInitialLoading,
    setScheduleListInitialLoadingSuccess,
    setScheduleListInitialLoadingReset,
  };
};
