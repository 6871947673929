import GppGoodIcon from '@mui/icons-material/GppGood';
import jwt from 'jwt-decode';

import { LocalityIcon } from 'assets/icons/locality.icon';
import { TravelIcon } from 'assets/icons/travel-icon';
import { IUser } from 'domain/login';
import { Lightbulb, RefreshCw } from 'lucide-react';
import theme from 'styles/theme';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const decodeToken = (): IUser | undefined => {
  const token = sessionStorage.getItem('token');
  if (token) {
    return jwt(token);
  }
};

const token = decodeToken();

const invoiceTransporterTitle = token?.companyLogin === 'logshare' ? 'Transportadoras' : 'Fretes a Receber';

interface Submenu {
  name: string;
  path: string;
  routeName: string;
  profiles: string[];
  icon?: JSX.Element;
  line?: JSX.Element;
  subtitles?: JSX.Element;
  disabled?: boolean;
}

interface Menus {
  title: string;
  icon: JSX.Element;
  submenus: Submenu[];
  routeName: string;
  profiles: string[];
}

export const mockMenus: Menus[] = [
  {
    title: 'Fretes',
    icon: <theme.icons.scheduleIcon />,
    routeName: 'shipping-freights',
    profiles: ['shipping-company'],
    submenus: [
      {
        name: 'Contratados',
        path: '/fretes',
        routeName: 'schedule-freight',
        profiles: ['shipping-company'],
        icon: <TravelIcon />,
      },
      {
        name: 'A Receber',
        path: '/fretes-transportador',
        routeName: 'freight-transporter',
        profiles: ['shipping-company'],
      },
      {
        name: 'Ocorrências',
        path: '/tracking-ocorrencias',
        routeName: 'shipping-occurrence',
        profiles: ['shipping-company'],
        disabled: true,
      },
    ],
  },

  {
    title: 'cadastros',
    icon: <theme.icons.register />,
    routeName: 'register',
    profiles: ['logshare', 'shipper', 'condominium', 'driver', 'shipping-company'],
    submenus: [
      {
        name: 'Unidades',
        path: '/unidades',
        routeName: 'locality',
        profiles: ['shipper', 'condominium', 'driver'],
        icon: <LocalityIcon />,
      },
      {
        name: 'Parceiros',
        path: '/parceiros',
        routeName: 'client',
        profiles: ['shipper', 'condominium', 'driver'],
      },

      {
        name: 'Transportadoras',
        path: '/transportadoras',
        routeName: 'shipping-company',
        profiles: ['shipper', 'condominium', 'driver'],
      },

      {
        name: 'Veículos',
        path: '/veiculos',
        routeName: 'vehicle',
        profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
      },
      {
        name: 'Motoristas',
        path: '/motoristas',
        routeName: 'driver',
        profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
      },

      {
        name: 'Tarifas',
        path: '/ofertas-venda',
        routeName: 'branches',
        profiles: ['shipping-company'],
      },
      {
        name: 'Tipos de Ocorrência',
        path: '/tipos-ocorrencias',
        routeName: 'occurrence-type',
        profiles: ['shipper', 'condominium', 'driver'],
      },
    ],
  },

  {
    title: 'Embarcadores',
    icon: <PeopleAltIcon sx={{ width: 19, height: 19 }} />,
    routeName: 'shippers',
    profiles: ['shipping-company'],
    submenus: [
      {
        name: 'Atendidos',
        path: '/atendidos',
        routeName: 'answer',
        profiles: ['shipping-company'],
        disabled: true,
      },

      {
        name: 'Homologados',
        path: '/homologados',
        routeName: 'homologate',
        profiles: ['shipping-company'],
        disabled: true,
      },
    ],
  },

  {
    title: 'oportunidades',
    icon: <RefreshCw size={20} strokeWidth={2.5} />,
    routeName: 'opportunities',
    profiles: ['logshare', 'shipper', 'condominium', 'driver', 'shipping-company'],
    submenus: [
      {
        name: 'Funil de Matches',
        path: '/matches-realizados',
        routeName: 'matches',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Ofertas de Compra',
        path: '/ofertas-compra',
        routeName: 'idleness',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Ofertas de Venda',
        path: '/ofertas-venda',
        routeName: 'transport-route',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Ofertas Recebidas',
        path: '/ofertas-bid',
        routeName: 'bidding',
        profiles: ['shipper', 'condominium', 'driver'],
      },
    ],
  },
  {
    title: 'Execução',
    icon: <theme.icons.trackingIcon />,
    routeName: 'execution',
    profiles: ['logshare', 'shipper', 'condominium', 'driver'],
    submenus: [
      {
        name: 'Tracking',
        path: '/fretes',
        routeName: 'freight',
        profiles: ['shipper', 'condominium', 'driver'],
        icon: <TravelIcon />,
      },
      {
        name: 'Ocorrências',
        path: '/tracking-ocorrencias',
        routeName: 'occurrence',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Mapa',
        path: '/mapa-tracking',
        routeName: 'map',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Recusas',
        path: '/cargas-recusadas',
        routeName: 'refused-loads',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Vale Equipamentos',
        path: '/pallet',
        routeName: 'pallet',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Canhoto',
        path: '/check-stub',
        routeName: 'pallet',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Serviços',
        path: '/retrabalho',
        routeName: 'rework',
        profiles: ['shipper', 'condominium', 'driver', 'full'],
      },
      {
        name: 'Checklist',
        path: '/check-list',
        routeName: 'check-list',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
      {
        name: 'Pesagem',
        path: '/pesagem',
        routeName: 'weighing',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Vale Pedágio',
        path: '/vale-pedagio',
        routeName: 'toll-voucher',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
    ],
  },

  {
    title: 'financeiro',
    icon: <theme.icons.financial />,
    routeName: 'financial',
    profiles: ['logshare', 'shipper', 'condominium', 'driver'],
    submenus: [
      {
        name: 'Transações',
        path: '/transacoes',
        routeName: 'freight-payable',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: invoiceTransporterTitle,
        path: '/fretes-transportador',
        routeName: 'freight-transporter',
        profiles: ['shipping-company'],
      },

      {
        name: 'Faturas',
        path: '/faturamento',
        routeName: 'fiscal-document',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Carteira Digital',
        path: '/carteira-digital',
        routeName: 'digital-wallet',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
    ],
  },

  {
    title: 'Porto Santos',
    icon: <theme.icons.logoSantos />,
    routeName: 'company',
    profiles: ['logshare'],
    submenus: [
      {
        name: 'Registros',
        path: '/porto-santos',
        routeName: 'company',
        profiles: ['logshare'],
      },
    ],
  },

  {
    title: 'inteligência',
    icon: <Lightbulb size={20} strokeWidth={2.5} />,
    routeName: 'intelligence',
    profiles: ['logshare', 'shipper', 'condominium', 'driver', 'shipping-company'],
    submenus: [
      {
        name: 'Analise de Mercado',
        path: '/analise-mercado',
        routeName: 'market-analysis',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
      {
        name: 'Simulador de Custos',
        path: '/simulador-custos',
        routeName: 'cost-simulator',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },

      {
        name: 'Ranking Colaborativo',
        path: '/ranking-colaborativo ',
        routeName: 'collaborative-ranking',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
      {
        name: 'Tendências',
        path: '/tendencias',
        routeName: 'tendencies',
        profiles: ['shipper', 'condominium', 'driver'],
        disabled: true,
      },
    ],
  },

  {
    title: 'configurações',
    icon: <theme.icons.settings />,
    routeName: 'settings',
    profiles: ['logshare', 'shipper', 'condominium', 'driver', 'shipping-company'],
    submenus: [
      {
        name: 'Empresas',
        path: '/empresas',
        routeName: 'company',
        profiles: ['logshare'],
      },
      {
        name: 'Dados da Empresa',
        path: '/dados-do-parceiro',
        routeName: 'company-info',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Gestão de Usuários',
        path: '/usuarios',
        routeName: 'user-management',
        profiles: ['shipper', 'condominium', 'driver'],
      },
      {
        name: 'Minha Conta',
        path: '/minha-conta',
        routeName: 'account-info',
        profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
      },
      {
        name: 'Alterar Senha',
        path: '/alterar-senha',
        routeName: 'reset-password',
        profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
      },
      {
        name: 'Termos de Uso',
        path: '/termos-de-uso',
        routeName: 'reset-password',
        profiles: ['shipper', 'condominium', 'driver', 'shipping-company'],
      },
      {
        name: 'PGR',
        path: '/pgr',
        routeName: 'pgr',
        profiles: ['shipping-company'],
        icon: <GppGoodIcon color="disabled" sx={{ width: 22, height: 22 }} />,
      },
    ],
  },
];
