import styled from 'styled-components';

export const ModalContainerStyled = styled.div`
  width: 100%;
  height: 702px;
`;

export const LoadingWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
`;

export const CharModalWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
