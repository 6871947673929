/* eslint-disable */
import { IOccurrenceEntity } from '../entities/occurrence.entity';
import { IListPage, PageInfo } from '../../../../domain/page-info';
import API from 'utils/API/API';
import { IOccurrenceCreated, IOccurrenceStatus } from '../../../../domain/occurrence';
import { convertOccurrenceCreatedEntityToDomainMapper } from 'pages/occurrence/mappers/convert-occurrence-created-entity-to-domain/convert-occurrence-created-entity-to-domain.mapper';
import { convertStatusDomainToEntityMapper } from 'pages/vehicle/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';

export interface IOccurrenceListProps {
  search: string;
  page: number;
  status?: IOccurrenceStatus;
  order: string;
  column: string;
}

export interface IOccurrenceListEntity {
  list: Array<IOccurrenceEntity>;
  pageInfo: PageInfo;
}

const LIMIT_PAGE = 50;

export const occurrenceListService = async ({
  page,
  search,
  status,
  order,
  column,
}: IOccurrenceListProps): Promise<IListPage<IOccurrenceCreated>> => {
  const parsedStatus = !status ? status : convertStatusDomainToEntityMapper(status);

  try {
    let url = `v2/occurrences?search=${search}&status=${parsedStatus}&page=${page}&limit=${LIMIT_PAGE}&order=${order}&column=${column}`;

    if (search === '') {
      url = url.replace('search=&', '');
    }
    if (status === undefined) {
      url = url.replace('status=undefined&', '');
    }
    const { data } = await API.get(url);
    const { items, meta } = data;

    return {
      list: items.map(convertOccurrenceCreatedEntityToDomainMapper),
      pageInfo: meta,
    };
  } catch (error) {
    throw new Error('Houve um erro ao listar os tipos de ocorrência');
  }
};
