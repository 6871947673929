import * as Yup from 'yup';

export const InformationCompanySchema = Yup.object().shape({
  cep: Yup.string().required('Campo Obrigatório').nullable(true),
  city: Yup.string().required('Campo Obrigatório').nullable(true),
  uf: Yup.string().required('Campo Obrigatório').nullable(true),
  neighborhood: Yup.string().required('Campo Obrigatório').nullable(true),
  street: Yup.string().required('Campo Obrigatório').nullable(true),
  numberHouse: Yup.string().required('Campo Obrigatório').nullable(true),
  gris: Yup.string().required('Campo Obrigatório'),
  advalorem: Yup.string().required('Campo Obrigatório'),
});
