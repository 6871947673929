import { FreightChatActionType } from '../../freight.action';
import { IFreightChatActions } from '../../types/freight-chat.types';
import { IFreightContext } from '../../types/freight.types';

export const freightChatReducer = (state: IFreightContext, action: IFreightChatActions): IFreightContext => {
  switch (action.type) {
    case FreightChatActionType.FREIGHT_CHAT_REQUEST:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          statusRequest: 'PROCESSING',
          photoUrl: '',
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: false,
          },
        },
      };
    case FreightChatActionType.FREIGHT_CHAT_SUCCESS:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          statusRequest: 'SUCCESS',
          photoUrl: action.payload,
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: false,
          },
        },
      };
    case FreightChatActionType.FREIGHT_CHAT_ERROR:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          statusRequest: 'ERROR',
          photoUrl: '',
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: false,
          },
        },
      };
    case FreightChatActionType.FREIGHT_CHAT_OPEN:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: true,
          },
        },
      };
    case FreightChatActionType.FREIGHT_CHAT_CLOSE:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: false,
          },
        },
      };
    case FreightChatActionType.FREIGHT_CHAT_RESET:
      return {
        ...state,
        freightChat: {
          ...state.freightChat,
          statusRequest: 'INITIAL',
          photoUrl: '',
          chatActions: {
            ...state.freightChat.chatActions,
            isOpen: false,
          },
        },
      };
    default:
      return state;
  }
};
