import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { DocumentsCTeView } from './documents-cte-view.component';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleDocumentsModalType } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import { useGetCTeData } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-get-cte-data/use-get-cte-data.hook';

export const DocumentsCTe: React.FC = () => {
  const [viewMode, setViewMode] = useState<'list' | 'kanban'>('list');
  const { scheduleDocuments } = useContext(ScheduleContext);
  const getCTeData = useGetCTeData();

  const handleToggleClick = useCallback(() => setViewMode(viewMode === 'list' ? 'kanban' : 'list'), [viewMode]);

  const handleOpenModal = useCallback(
    (modalType: ScheduleDocumentsModalType, id: number) =>
      scheduleDocuments.modalsProps.setModalOpen({ open: true, id, modalType }),
    [scheduleDocuments.modalsProps],
  );

  const isLoading = useMemo(
    () => scheduleDocuments.cteDataProps.statusRequest === 'PROCESSING',
    [scheduleDocuments.cteDataProps.statusRequest],
  );

  const isEmptyList = useMemo(
    () => scheduleDocuments.cteDataProps.statusRequest === 'SUCCESS' && !scheduleDocuments.cteDataProps.cteList.length,
    [scheduleDocuments.cteDataProps.cteList.length, scheduleDocuments.cteDataProps.statusRequest],
  );

  useEffect(() => {
    if (scheduleDocuments.cteDataProps.statusRequest === 'INITIAL') {
      getCTeData();
    }
  }, [getCTeData, scheduleDocuments.cteDataProps.statusRequest]);

  return (
    <DocumentsCTeView
      ctes={scheduleDocuments.cteDataProps.cteList}
      isLoading={isLoading}
      handleOpenModal={handleOpenModal}
      isEmptyList={isEmptyList}
      viewMode={viewMode}
      handleToggleClick={handleToggleClick}
    />
  );
};
