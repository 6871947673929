import React, { Fragment, useState } from 'react';

import {
  ContainerTitleStyled,
  ContainerAccessStyled,
  ContainerModalStyled,
  ContainerStreetStyled,
  ContainerTermStyled,
  ContainerTextTermStyled,
  OurPrivacyPolicies,
  SpacingStyled,
  ContainerUpdateStyled,
} from './terms-of-use.styled';
import { OurPrivacyPoliciesModalBody } from './components/our-privacy-policies-modal/our-privacy-policies-modal-body/our-privacy-policies-modal-body';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

interface ITermsOfUseProps {
  openModalPolicies?: () => void;
}

const dataTestId = 'terms-of-use-view';

const TermsOfUseView: React.FC<ITermsOfUseProps> = () => {
  const [title, setTitle] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModalPrivacyPolicies = () => {
    setShowModal(!showModal);
    setTitle('Políticas de Privacidade');
  };
  return (
    <Fragment>
      <ContainerModalStyled>
        <OurPrivacyPoliciesModalBody title={title} setShowModal={setShowModal} showModal={showModal} />
      </ContainerModalStyled>
      <HeaderPage dataTestId={dataTestId} breadcrumbList={['Configurações', 'Termos de Uso']} />
      <Title dataTestId={dataTestId} title="Confira os critérios para a execução segura de suas operações." hasTrace />
      <ContainerAccessStyled>
        <ContainerTextTermStyled>
          <ContainerUpdateStyled>
            LogShare - Última atualização: 10.08.2022
            <OurPrivacyPolicies onClick={openModalPrivacyPolicies}>Políticas de Privacidade</OurPrivacyPolicies>
          </ContainerUpdateStyled>
          <ContainerTermStyled>
            Estes “Termos de Uso” reúnem as regras de utilização de todos os “Serviços” entre o “Usuário” e a
            “Plataforma” LogShare, disponibilizados através de site, portal, aplicativos e conteúdos fornecidos pela
            LOGSHARE TECNOLOGIA LTDA., inscrita no CNPJ sob nº 46.357.529/0001-68, estabelecida na Alameda Vicente
            Pinzon, 54 – 3º Andar – Vila Olimpia – São Paulo – SP.
          </ContainerTermStyled>

          <ContainerTermStyled>
            O acesso e utilização dos Serviços oferecidos pela LogShare implica necessariamente na ciência e aceitação
            dos presentes termos e estabelecem o relacionamento contratual entre o Usuário e a LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            Caso não esteja de acordo com os “Termos de Uso”, solicitamos que não acesse e não utilize os Serviços
            oferecidos pela LogShare. A utilização dos Serviços, parcial ou integral, resulta na imediata aceitação dos
            “Termos de Uso”.
          </ContainerTermStyled>

          <ContainerTermStyled>
            Termos adicionais são complementares e parte integrante deste “Termo de Uso”, exceto quando possuírem termos
            que declarem expressamente a aplicação dos presentes “Termos”. Em caso de conflito, os termos adicionais
            prevalecerão sobre estes “Termos de Uso”.
          </ContainerTermStyled>

          <ContainerTitleStyled>I. SERVIÇOS</ContainerTitleStyled>

          <ContainerTermStyled>
            1.1 A LogShare é uma transportadora e plataforma de intermediação de fretes, especializada em Backhaul
            Logístico, que utiliza tecnologia para integração de malhas logísticas e compartilhamento de rotas, com o
            objetivo de otimizar as capacidades de cargas para aumento de eficiência operacional e redução de custos
            logísticos, através da integração de embarcadores, transportadoras subcontratadas e motoristas autônomos.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.2 O Usuário declara e concorda expressamente que responde pela contratação dos Serviços da Pessoa Jurídica
            informada no cadastro do usuário.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.3 Os presentes Termos de Uso estabelecem obrigações, por tempo indeterminado, entre LogShare e Usuários
            para utilização da plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.4 Através da Plataforma, o Usuário poderá:
            <SpacingStyled></SpacingStyled>
            <SpacingStyled></SpacingStyled>1.4.1 Ofertar a ociosidade de rotas para comercialização de frete retorno;
            <SpacingStyled></SpacingStyled>1.4.2 Contratar a ociosidade de outros embarcadores ou transportadores;
            <SpacingStyled></SpacingStyled> 1.4.3 Solicitar a cotação e contratação de frete para o transporte de
            mercadorias;
            <SpacingStyled></SpacingStyled>
            1.4.4 Gerenciar e programar fretes de operações da frota própria ou dedicada.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.5 Em caso de solicitação da contratação de fretes para o transporte de mercadorias, por meio da
            plataforma, a LogShare poderá, a seu critério, decidir se aceitará a realização do serviço, conforme
            solicitação do usuário.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.6 Toda operação de transporte, com subcontratação realizada pela LogShare, será realizada conforme
            previsto no Conhecimento de Transporte Eletrônico (CT-e).
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.7 A LogShare utilizará sua rede de transportadoras, embarcadores e motoristas autônomos (TAC) para
            subcontratação de fretes.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.8 Após o aceite dos serviços, o Usuário está ciente de que qualquer modificação na solicitação inicial
            poderá impactar no prazo, qualidade e precificação do serviço, que será informada ao Usuário para decisão da
            continuidade do serviço.
          </ContainerTermStyled>

          <ContainerTermStyled>
            1.9 Em caso de modificação do serviço, a LogShare poderá, a seu critério, decidir se aceitará a realização
            do serviço, conforme modificações informadas pelo Usuário.
          </ContainerTermStyled>

          <ContainerTitleStyled>II. RESPONSABILIDADES</ContainerTitleStyled>

          <ContainerTermStyled>
            2.1 A pessoa jurídica, representada pelo usuário, é a única e integral responsável pelo conteúdo
            transportado, respondendo no âmbito civil, fiscal, ambiental, penal e/ou em qualquer outro âmbito por
            qualquer infração à legislação em vigor, isentando totalmente a LogShare de qualquer responsabilidade quanto
            ao produto transportado.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.2 A LogShare não será responsável por danos causados por vício próprio ou oculto da Carga ou decorrentes
            do transporte de itens proibidos pelo ordenamento jurídico aplicável.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.3 A LogShare será responsável por danos a carga, como perda, roubo, furto ou danos de qualquer natureza,
            limitada ao valor da carga declarado na solicitação do frete e registrados no Conhecimento de Transporte
            Eletrônico, quando emitido pela LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.4 A LogShare não será responsável por danos a carga, de fretes programados e gerenciados, através da
            plataforma, com transportadores parceiros do usuário, cujo contrato de prestação de serviço é firmado
            diretamente entre usuário e transportador, eximindo assim a LogShare das obrigações legais do transporte.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.5 A LogShare não será responsável por quaisquer atos indevidos ou omissões do Usuário que impactem, de
            forma negativa, na execução e qualidade do serviço, assim como na integridade da carga.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.6 O Usuário é responsável por fornecer dados corretos e atualizados para utilização dos serviços da
            LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.7 O Usuário reconhece que seus dados de acesso são individuais e intransferíveis, de modo a garantir a
            segurança dos dados e informações disponíveis na plataforma. Em caso de suspeita de acesso indevido, que
            evidencie violação de acesso, o Usuário deverá trocar a sua senha imediatamente.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.8 O Usuário é responsável pela criação e gestão de outros usuários, relacionados ao seu CNPJ, criados para
            acesso e uso da plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.9 A LogShare, nem quaisquer de seus empregados ou prepostos solicitará, por qualquer meio, físico ou
            eletrônico, que seja informada sua senha.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.10 A LogShare se exime e se exonera, de qualquer responsabilidade pelos danos de qualquer natureza, lucros
            cessantes e danos emergentes, que possam ser ocasionados pela falta de continuidade, funcionamento e
            acessibilidade da plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.11 A LogShare será responsável por emitir Conhecimento de Transporte Eletrônico (CT-e) para fretes
            contratados de parceiros LogShare, através da plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.12 A LogShare não será responsável por emitir Conhecimento de Transporte Eletrônico (CT-e) para fretes
            contratados de parceiros do Usuário, cujo contrato de prestação de serviço é firmado entre o Usuário e o
            prestador de serviço.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.13 O pagamento de fretes contratados através da LogShare, cujo Conhecimento de Transporte foi emitido pela
            LogShare, deverá ser realizado única e exclusivamente para o favorecido LogShare, cujos valores são
            apresentados na área restrita do Usuário, no momento da contratação do frete, os quais deverão ser pagos
            mediante faturamento periódico, com prazo de pagamentos acordados entre as partes, visível na plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            2.14 Toda operação de transporte, com contratação direta pelo Usuário, a sua rede de transportadores, sem a
            emissão do Conhecimento de Transporte Eletrônico (CTe) pela LogShare, isenta a LogShare de qualquer
            responsabilidade a terceiros, roubos e furtos.
          </ContainerTermStyled>

          <ContainerTitleStyled>III. PRIVACIDADE E PROTEÇÃO DE DADOS INDIVIDUAIS</ContainerTitleStyled>

          <ContainerTermStyled>
            3.1 Estes Termos de Uso devem ser analisados em conjunto com a Política de Privacidade da LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            3.2 A LogShare possui documento próprio, disponibilizado na plataforma, denominado Política de Privacidade,
            responsável por regular o tratamento dos dados coletados na Plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            3.3 A Política de Privacidade da LogShare e os Termos de Uso estabelecem as condições e regras para o uso da
            Plataforma e Serviços oferecidos pela LogShare.
          </ContainerTermStyled>

          <ContainerTitleStyled>IV. PROPRIEDADE INTELECTUAL</ContainerTitleStyled>

          <ContainerTermStyled>
            4.1 Os elementos e ferramentas encontrados na Plataforma estão sujeitos aos direitos intelectuais de acordo
            com as leis brasileiras e tratados e convenções internacionais dos quais o Brasil seja signatário. Apenas a
            título exemplificativo, entendem-se como tais: textos, softwares, scripts, imagens gráficas, fotos, sons,
            músicas, vídeos, recursos interativos e similares, marcas, marcas de serviços, logotipos e {'look and feel'}
            , entre outros, pertencendo de forma exclusiva à LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            4.2 Os Usuários estão cientes e concordam que a utilização, redistribuição, comercialização e/ou reprodução
            de conteúdo ou parte de conteúdo disponibilizado na Plataforma deverão seguir as normas concernentes às leis
            brasileiras de Direitos Autorais, sob pena de caracterização de utilização indevida e/ou infração a direitos
            intelectuais de terceiros.
          </ContainerTermStyled>

          <ContainerTitleStyled>V. ACESSO, INTERRUPÇÃO, SUSPENSÃO OU CANCELAMENTO DA CONTA</ContainerTitleStyled>

          <ContainerTermStyled>
            5.1 A LogShare poderá desativar, bloquear ou, de outro modo, encerrar unilateralmente o acesso do Usuário
            aos Serviços, com rescisão unilateral do presente contrato, sem prejuízo das medidas legais cabíveis, caso:
            (i) seja identificada qualquer violação legal e/ou às disposições destes Termos; (ii) seja identificada a
            prática de atos fraudulentos por meio do uso da Plataforma LogShare, seja de forma direta ou indireta; e
            (iii) se o Usuário causar algum dano direto ou indireto à LogShare, seus usuários ou a terceiros; (iv) seja
            verificada a inadimplência do Usuário, que se dá quando este último deixar de efetuar o pagamento de
            qualquer parcela devida em favor da LogShare nos termos pactuados entre as partes; (v) em outras hipóteses,
            por critério da LogShare, caso a LogShare entenda necessário, por violação de normas jurídicas e
            incompatibilidade com a política da Plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            5.2 Em caso de inadimplência por parte do Usuário, além de poder ensejar a rescisão do contrato entre as
            partes, resta devidamente ciente o Usuário de que a LogShare poderá tomar as medidas cabíveis para protesto
            do título em aberto em Cartório, além de inscrição do Usuário inadimplente junto a todo e qualquer órgão de
            restrição ao crédito, a único e exclusivo critério da LogShare, bem como realizar a cobrança extrajudicial
            e, inclusive, judicial do mesmo, também a seu exclusivo critério.
          </ContainerTermStyled>

          <ContainerTermStyled>
            5.3 Em caso de término da utilização dos Serviços por qualquer motivo, a LogShare poderá manter os dados e
            histórico das transações realizadas pelos Usuários arquivados por tempo indeterminado, o que desde já fica
            expressamente autorizado pelo Usuário.
          </ContainerTermStyled>

          <ContainerTitleStyled>VI. DISPOSIÇÕES GERAIS</ContainerTitleStyled>

          <ContainerTermStyled>
            6.1 Estes Termos de Uso estão sujeitos a melhorias constantes e consistem na versão atualizada de seus
            termos e condições para governar as relações entre Usuários e LogShare. A LogShare se reserva o direito de
            modificar e atualizar seus documentos jurídicos, incluindo atualizações ou alterações a estes Termos de Uso.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.2 Essa versão é responsável por dirigir as relações entre a LogShare e seus Usuários, exceto quando o
            Usuário possuir termos ou regramentos próprios com a LogShare, respeitados os direitos adquiridos, os atos
            jurídicos perfeitos e as coisas julgadas.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.3 A LogShare busca constantemente melhorias para oferecer ao Usuário a melhor experiência na utilização
            dos Serviços. No entanto, a Plataforma e todos os Serviços da LogShare são oferecidos da forma como se
            encontram, de modo que a LogShare pode implementar livremente mudanças, alterações, adições, supressões e
            quaisquer outras formas de modificação na Plataforma.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.4 Melhorias na Plataforma e os Serviços atuais da LogShare, como a disponibilização de novos recursos,
            estarão sujeitos a estes Termos de Uso.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.5 Caso qualquer disposição destes Termos de Uso for julgada inaplicável e sem efeito, por qualquer motivo,
            o restante continuará a viger normalmente.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.6 Estes Termos de Uso prevalecem sobre qualquer outra comunicação ou acordo prévio ou futuro, oral ou
            escrito, entre o Usuário e a LogShare em relação ao uso da Plataforma e os Serviços da LogShare.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.7 Qualquer concessão ou tolerância pelo não cumprimento de qualquer obrigação relacionada a este
            instrumento será considerada mera liberalidade, não constituindo em novação, precedente invocável, alteração
            tácita de seus termos, renúncia de direitos e nem direito adquirido dos Usuários.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.8 Estes Termos de Uso e a relação entre a LogShare e o Usuário serão regidos pelas leis da República
            Federativa do Brasil. As Partes elegem o Foro da Comarca da Cidade de São Paulo, como sendo o único
            competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em relação aos
            Serviços, renunciando a outro foro, por mais privilegiado que seja.
          </ContainerTermStyled>

          <ContainerTermStyled>
            6.9 As partes não poderão transferir, ceder ou onerar os direitos e obrigações decorrentes deste instrumento
            em hipótese alguma sem a anuência escrita da parte contrária.
          </ContainerTermStyled>
        </ContainerTextTermStyled>
        <ContainerStreetStyled>
          Alameda Vicente Pinzon, 54 - Vila Olímpia, São Paulo - SP, 04547-130
        </ContainerStreetStyled>
      </ContainerAccessStyled>
    </Fragment>
  );
};

export default TermsOfUseView;
