import { useCallback, useReducer } from 'react';
import { IWeighingTottensDetails } from '../../types/weighing-tottens-details.types';
import { weighingTottensInitialState } from '../../weighing-tottens.constants';
import { WeighingTottensDetailsActionType } from '../../weighing-tottens.action';
import { weighingTottensDetailsReducer } from '../../reducers/weighing-tottens-details/weighing-tottens-details.reducer';
import { IWeighingTottensDetailsDomain } from 'src-new/mobile/pages/weighing-totten/pages/weighing-totten-details/domain/weighing-totten-details/weighing-totten-details.domain';
import { IWeighingTottensDetailsParams } from 'src-new/mobile/pages/weighing-totten/pages/weighing-totten-details/types/weighing-totten-details-filter/weighing-totten-details-filter.types';

export const useWeighingTottensDetailsContextValues = (): IWeighingTottensDetails => {
  const [state, dispatch] = useReducer(weighingTottensDetailsReducer, weighingTottensInitialState);

  const setWeighingTottensDetailsRequest = useCallback(() => {
    dispatch({ type: WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_REQUEST, payload: undefined });
  }, []);

  const setWeighingTottensDetailsSuccess = useCallback((details: IWeighingTottensDetailsDomain) => {
    dispatch({ type: WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_SUCCESS, payload: details });
  }, []);

  const setWeighingTottensDetailsError = useCallback(() => {
    dispatch({ type: WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_ERROR, payload: undefined });
  }, []);

  const setWeighingTottensDetailsReset = useCallback(() => {
    dispatch({ type: WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_RESET, payload: undefined });
  }, []);

  const setFilters = useCallback((filters: IWeighingTottensDetailsParams) => {
    dispatch({ type: WeighingTottensDetailsActionType.WEIGHING_TOTTENS_DETAILS_FILTERS, payload: filters });
  }, []);

  return {
    ...state.weighingTottensDetails,
    weighingTottensDetailsFiltersProps: {
      ...state.weighingTottensDetails.weighingTottensDetailsFiltersProps,
      setFilters,
    },
    setWeighingTottensDetailsRequest,
    setWeighingTottensDetailsSuccess,
    setWeighingTottensDetailsError,
    setWeighingTottensDetailsReset,
  };
};
