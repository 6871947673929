import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import CreateIcon from '@mui/icons-material/Create';

import { HeaderTitle, Info, InfoTitle, SectionContainer, Wrapper } from '../styled';

import { UserContext } from 'state/user-context';
import { ScheduleDomain } from 'domain/schedule';
import Button from 'components/button/button';

interface IProps {
  values?: ScheduleDomain;
}

const FreightShippingSectionView: React.FC<IProps> = ({ values }) => {
  const { isAdmin } = useContext(UserContext);

  const navigate = useNavigate();

  const handleSelectedFreight = () => navigate(`/alocacao/${values?.id}`);

  const logshareTradeName = 'LOGSHARE TECNOLOGIA LTDA';
  const logshareCNPJ = '46.357.529/0001-68';

  const oferedByShipper = values?.currentFreight === 1;

  return (
    <Wrapper>
      <HeaderTitle display="flex" justify="space-between" isTraced>
        Dados da Transportadora
        {values?.typeSchedule === 'ftl' ? (
          <Button
            title="Editar"
            size="very-small"
            icon={<CreateIcon />}
            callback={handleSelectedFreight}
            bgColor="white"
          />
        ) : (
          <Button
            title="Editar"
            disabled
            size="very-small"
            icon={<CreateIcon />}
            callback={handleSelectedFreight}
            bgColor="white"
          />
        )}
      </HeaderTitle>

      <SectionContainer display="grid" columns="1.5fr 0.5fr">
        <InfoTitle>
          Nome da Transportadora
          <Info>{oferedByShipper && !isAdmin ? logshareTradeName : values?.shippingCompany?.name}</Info>
        </InfoTitle>

        <InfoTitle>
          CNPJ
          <Info>{oferedByShipper && !isAdmin ? logshareCNPJ : values?.shippingCompany?.cnpj || '-'}</Info>
        </InfoTitle>
      </SectionContainer>
    </Wrapper>
  );
};

export default React.memo(FreightShippingSectionView);
