import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  display: ${({ show }: { show: boolean }) => (show ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 20px;
  background-color: #05050581;
  z-index: 999;
`;

export const ContentStyled = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  width: 1200px;
  min-height: 237px;
  overflow-y: auto;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTitlesStyled = styled.div`
  padding-bottom: 20px;
`;
export const TitleStyled = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.bold};
  `}
`;

export const ModalHeaderCloseButtonStyled = styled.div``;

export const CloseModalStyled = styled.div`
  font-size: 15px;
  cursor: pointer;
`;
