import { FormikErrors } from 'formik';
import { ChangeEvent, FC, useCallback } from 'react';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { WeighingFormMountDriversView } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/components/weighing-update-form/components/weighing-update-form-mount/components/weighing-update-form-mount-drivers/weighing-update-form-mount-drivers-view.component';

interface IWeighingFormMountDriversProps {
  values: IWeighingDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDomain>>;
}

export const WeighingFormMountDrivers: FC<IWeighingFormMountDriversProps> = ({ values, setFieldValue }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  return <WeighingFormMountDriversView values={values} handleChange={handleChange} />;
};
