import React from 'react';

import FormChangePassword from '../form-change-password/form-change-password';

import { ChangePasswordContainerStyled } from './change-password.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const ChangePassword: React.FC = () => {
  return (
    <ChangePasswordContainerStyled>
      <HeaderPage breadcrumbList={['Configurações', 'Alterar Senha']} />
      <Title title="Garanta a sua segurança de acesso." hasTrace />
      <FormChangePassword />
    </ChangePasswordContainerStyled>
  );
};

export default ChangePassword;
