import { IChatActions, IChatContext } from 'src-new/contexts/chat/types/chat.types';
import { ChatEligibleUsersActionType } from 'src-new/contexts/chat/chat.action';

export const chatEligibleUsersReducer = (state: IChatContext, action: IChatActions): IChatContext => {
  switch (action.type) {
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_REQUEST:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          statusRequest: 'PROCESSING',
          users: [],
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_SUCCESS:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          statusRequest: 'SUCCESS',
          users: action.payload,
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_ERROR:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          statusRequest: 'ERROR',
          users: [],
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_REQUEST:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          validateThreadsActions: {
            ...state.chatEligibleUsers.validateThreadsActions,
            statusRequest: 'PROCESSING',
            haveCreated: true,
          },
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_SUCCESS:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          validateThreadsActions: {
            ...state.chatEligibleUsers.validateThreadsActions,
            statusRequest: 'SUCCESS',
            haveCreated: action.payload,
          },
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_VALIDATE_THREAD_ERROR:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          validateThreadsActions: {
            ...state.chatEligibleUsers.validateThreadsActions,
            statusRequest: 'ERROR',
            haveCreated: true,
          },
        },
      };
    case ChatEligibleUsersActionType.CHAT_ELIGIBLE_USERS_RESET:
      return {
        ...state,
        chatEligibleUsers: {
          ...state.chatEligibleUsers,
          statusRequest: 'INITIAL',
          users: [],
          validateThreadsActions: {
            ...state.chatEligibleUsers.validateThreadsActions,
            statusRequest: 'INITIAL',
            haveCreated: true,
          },
        },
      };
    default:
      return state;
  }
};
