import React, { useCallback, useContext, useMemo } from 'react';
import { ScheduleImportModalView } from './schedule-import-modal-view.component';
import { useSendSelectedFile } from '../../../../hooks/use-send-selected-file/use-send-selected-file.hook';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import {
  IDisabledButtons,
  IFilesStatus,
} from 'src-new/pages/execution/pages/schedule/types/schedule-import-modal/schedule-import-modal.types';
import { useGetTemplateFiles } from '../../../../hooks/use-get-template-files/use-get-template-files.hook';
import { useGetDownloadFile } from '../../../../hooks/use-get-download-file/use-get-download-file.hook';
import { useUploadImportFile } from '../../../../hooks/use-upload-import-file/use-upload-import-file.hook';
import { UserContext } from 'state/user-context';

interface IScheduleImportModalProps {
  handleModalClose: () => void;
}

export const ScheduleImportModal: React.FC<IScheduleImportModalProps> = ({ handleModalClose }) => {
  const { scheduleImportModal } = useContext(ScheduleContext);
  const { user } = useContext(UserContext);

  const uploadImportFileHook = useUploadImportFile();
  const sendSelectedFileHook = useSendSelectedFile();
  const getDownloadFileHook = useGetDownloadFile();
  const getTemplateFilesHook = useGetTemplateFiles();

  const handleClose = useCallback(() => {
    if (scheduleImportModal.statusRequest !== 'PROCESSING') {
      handleModalClose();
    }
  }, [handleModalClose, scheduleImportModal.statusRequest]);

  const handleUploadFile = useCallback(async () => {
    await uploadImportFileHook(scheduleImportModal.scheduleImportModal[0].processingCode);

    handleModalClose();
  }, [handleModalClose, scheduleImportModal.scheduleImportModal, uploadImportFileHook]);

  const handleTemplateFile = useCallback(
    () => user?.companyId && getTemplateFilesHook(user.companyId),
    [getTemplateFilesHook, user?.companyId],
  );

  const handleSelectedFile = useCallback((files: FileList) => sendSelectedFileHook(files), [sendSelectedFileHook]);

  const handleDownloadSelectedFile = useCallback(() => getDownloadFileHook(), [getDownloadFileHook]);

  const isLoading = useMemo(
    (): boolean => scheduleImportModal.statusRequest === 'PROCESSING',
    [scheduleImportModal.statusRequest],
  );

  const filesStatus = useMemo((): IFilesStatus => {
    const total = scheduleImportModal.scheduleImportModal.length;

    const statusCounts = scheduleImportModal.scheduleImportModal.reduce(
      (acc, item) => {
        if (item.status === 'approved') {
          acc.approved += 1;
        } else if (item.status === 'refused') {
          acc.refused += 1;
        }
        return acc;
      },
      { approved: 0, refused: 0 },
    );

    return { total, approved: statusCounts.approved, refused: statusCounts.refused };
  }, [scheduleImportModal.scheduleImportModal]);

  const disableButtons = useMemo((): IDisabledButtons => {
    const downloadButton = filesStatus.total === 0;
    const uploadButton = filesStatus.total === 0 || filesStatus.refused >= 1 || isLoading;

    return { downloadButton, uploadButton };
  }, [filesStatus.refused, filesStatus.total, isLoading]);

  return (
    <ScheduleImportModalView
      filesStatus={filesStatus}
      disableButtons={disableButtons}
      isLoading={isLoading}
      handleModalClose={handleClose}
      handleUploadFile={handleUploadFile}
      handleTemplateFile={handleTemplateFile}
      handleSelectedFile={handleSelectedFile}
      handleDownloadSelectedFile={handleDownloadSelectedFile}
    />
  );
};
