import { IChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.types';

const ENVIRONMENT = import.meta.env.VITE_FIREBASE_TARGET;

export const getChatEnvironment = (): IChatEnvironment => {
  const devEnvironment = ENVIRONMENT === 'development';
  const appId = devEnvironment ? 't9zlyDFx' : 'KdJaD5Yb';
  const secretKey = devEnvironment
    ? 'sk_test_ugbNvRBKDDbrP0ZfwH0N9H31HivxFijB'
    : 'sk_live_liVhm2E9YgJmypIhnSjdA9XrOuXXifoW';

  return {
    appId,
    secretKey,
  };
};
