import API from 'utils/API/API';
import { FreightPriceFTL } from 'domain/freight';

export interface IGetFreightCalculation {
  originId: number;
  originType: string;
  destinyId: number;
  destinyType: string;
  weight: string;
  capacity: string;
  merchValue: string;
  vehicleType: string;
  vehicleCategory: string;
  needHelper?: string;
  pickUpDate?: string;
  dates: {
    levyInitialDate?: string;
    levyFinalDate?: string;
    deliveryInitialDate?: string;
    deliveryFinalDate?: string;
  };
  pallet?: number;
  override?: any;
}

export const getFreightCalculation = async (payload: IGetFreightCalculation): Promise<FreightPriceFTL> => {
  const { data } = await API.post('v2/schedules/freight-calculate', payload);

  return data;
};
