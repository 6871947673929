import { Button } from 'logshare-ui-kit';
import { Trash2 } from 'lucide-react';
import { FC } from 'react';

import * as S from './schedule-details-allocation-shipping-view.styled';

import { LineSectionLayout } from 'components-v2/layout/line-section';
import { Box } from 'src-new/components/box/box.component';
import { capitalize } from 'src-new/utils/capitalize.util';
import { IScheduleDetailsShippingDomain } from '../../../../../../../../domains/schedule-details.domain';
import { Tooltip } from '@mui/material';

interface IScheduleDetailsAllocationShippingViewProps {
  shipping: IScheduleDetailsShippingDomain;
  statusCode: string;
  handleShippingAllocation: () => void;
}

export const ScheduleDetailsAllocationShippingView: FC<IScheduleDetailsAllocationShippingViewProps> = ({
  shipping,
  statusCode,
  handleShippingAllocation,
}) => {
  return (
    <Box
      footerButtons={[
        <span key={0} />,
        <Tooltip key={1} title={<S.TooltipLabel>Remover Transportadora</S.TooltipLabel>}>
          <S.ContainerButtons isDisabled={statusCode !== 'IN10'}>
            <Button
              label=""
              color="white"
              onPress={handleShippingAllocation}
              LeftIcon={<Trash2 size={20} strokeWidth={2.75} />}
              size="x-sm"
              variant="ghost"
              disabled={statusCode !== 'IN10'}
            />
          </S.ContainerButtons>
        </Tooltip>,
      ]}
    >
      <LineSectionLayout columns={'1fr 1fr'}>
        <S.WrapperInformation>
          <S.InformationTitle>Transportadora</S.InformationTitle>
          <S.InformationBody>{capitalize(shipping?.name || '')}</S.InformationBody>
        </S.WrapperInformation>

        <S.WrapperInformation>
          <S.InformationTitle>CNPJ</S.InformationTitle>
          <S.InformationBody>{shipping?.cnpj || ''}</S.InformationBody>
        </S.WrapperInformation>
      </LineSectionLayout>
    </Box>
  );
};
