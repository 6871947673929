import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { IMatchesKanbanListResponse } from '../../domains/matches-kanban-list/matches-kanban-list-response.domain';
import { IMatchesKanbanDetails } from '../../domains/matches-kanban-list/matches-kanban-list.domain';
import { convertMatchesOfferType } from '../convert-matches-offer-type/convert-matches-offer-type.mapper';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { convertOperationTypeToDomain } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';

export const convertMatchesKanbanListResponseToDomain = (
  matchesKanban: IMatchesKanbanListResponse,
): IMatchesKanbanDetails => {
  const matchesOfferType = convertMatchesOfferType(matchesKanban);

  return {
    ...matchesOfferType,
    matchOffersRoutesId: matchesKanban.matchOffersRoutesId,
    isFavorite: matchesKanban.isFavorite,
    totalSchedulesBackhaul: matchesKanban.totalSchedulesBackhaul,
    additionalDistance: matchesKanban.additionalDistance,
    purchaseOffer: {
      ...matchesKanban.purchaseOffer,
      operationType: convertOperationTypeToDomain(matchesKanban.purchaseOffer.operationType),
      cargoType: 'FTL',
      totalMatchOffers: 1,
      capacity: {
        pallet: matchesKanban.purchaseOffer.offeredPallet,
        weight: matchesKanban.purchaseOffer.offeredWeight,
      },
      companyName: matchesKanban.purchaseOffer.company.name,
      initialDate: '',
      destination: {
        ...matchesKanban.purchaseOffer.destination,
        address: {
          ...matchesKanban.purchaseOffer.destination,
          cep: '',
          complement: '',
          neighborhood: '',
          numberHouse: '',
          street: '',
        },
      },
      origin: {
        ...matchesKanban.purchaseOffer.origin,
        address: {
          ...matchesKanban.purchaseOffer.origin,
          cep: '',
          complement: '',
          neighborhood: '',
          numberHouse: '',
          street: '',
        },
      },
      isActive: 'ativa',
      values: {
        freight: matchesKanban.purchaseOffer.freightValue,
        logshare: matchesKanban.purchaseOffer.logshareValue,
      },
      vehicle: {
        category: convertVehicleCategory(matchesKanban.purchaseOffer.category),
        type: convertVehicleType(matchesKanban.purchaseOffer.vehicleType),
      },
    },
    saleOffer: {
      ...matchesKanban.saleOffer,
      frequency: matchesKanban.saleOffer.frequency ?? 0,
      operationType: convertOperationTypeToDomain(matchesKanban.saleOffer.operationType),
      cargoType: 'FTL',
      companyId: matchesKanban.saleOffer.company.id,
      companyImage: matchesKanban.saleOffer.company.image,
      companyName: matchesKanban.saleOffer.company.name,
      cubage: 0,
      idTariff: matchesKanban.saleOffer.saleOfferTariffId,
      initialDate: '',
      isActive: 'ativa',
      pallets: matchesKanban.saleOffer.offeredPallet,
      partnerShipper: '',
      shippingName: '',
      destination: {
        ...matchesKanban.saleOffer.destination,
        address: {
          ...matchesKanban.saleOffer.destination,
        },
      },
      origin: {
        ...matchesKanban.saleOffer.origin,
        address: {
          ...matchesKanban.saleOffer.origin,
        },
      },
      totalMatchOffers: 1,
      valueTotal: convertCurrencyFormat.format(matchesKanban.saleOffer.freightValue ?? 0),
      vehicle: {
        category: convertVehicleCategory(matchesKanban.saleOffer.category),
        type: convertVehicleType(matchesKanban.saleOffer.vehicleType),
      },
      weight: matchesKanban.saleOffer.offeredWeight,
    },
    purchaseOfferId: matchesKanban.purchaseOffer.id,
    saleOfferId: matchesKanban.saleOffer.id,
  };
};
