/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

interface StyledProps {
  isBold?: boolean;
  blueColor?: boolean;
  isTransportRouter?: boolean;
}

export const WrapperCardProposed = styled.div<StyledProps>`
  ${({ isTransportRouter }) => css`
    display: flex;

    flex-direction: ${isTransportRouter ? 'column' : ''};
    gap: ${isTransportRouter ? '1rem' : ''};
    position: ${isTransportRouter ? 'relative' : ''};
    bottom: ${isTransportRouter ? '1.8rem' : ''};
  `}
`;

export const WrapperValueProposed = styled.div`
  font-size: 12px;
  text-align: right;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 18px;
    box-shadow: 5px 5px 10px -5px #7b7b7b;
    border-radius: 5px;
    background-color: ${theme.colorsV2.blue};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipContent = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CustomWrapper = styled.div`
  ${(p: any) => p.styles}
`;

export const Title = styled.p<StyledProps>`
  ${({ theme, isBold }) => css`
    font-size: ${isBold ? theme.fontV2.sizes.text3 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 700 : 600};
    color: ${theme.colorsV2.white};
    padding-bottom: 10px;
    text-transform: uppercase;
  `}
`;

export const TitleText = styled.p<StyledProps>`
  ${({ theme, isBold }) => css`
    font-size: ${theme.fontV2.sizes.text3};
    font-weight: ${isBold && 700};
    color: ${theme.colorsV2.white};
    padding-bottom: 10px;
    text-transform: uppercase;
  `}
`;

export const Label = styled.p<StyledProps>`
  ${({ theme, isBold, blueColor }) => css`
    display: ${isBold ? '' : 'flex'};
    font-size: ${blueColor ? theme.fontV2.sizes.text5 : theme.fontV2.sizes.text4};
    font-weight: ${isBold ? 600 : 400};
    color: ${theme.colorsV2.white};
    text-transform: uppercase;
  `}
`;

export const Info = styled.p<StyledProps>`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    font-size: ${theme.fontV2.sizes.text4};
    color: ${theme.colorsV2.white};
    text-transform: uppercase;
    font-size: 9px;
    padding: 5px 0;
  `}
`;
