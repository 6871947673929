import { PageInfo } from 'domain/page-info';

export const defaultInitialPage: PageInfo = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

export const canEditFieldByStatusCode = (statusCode: string) => {
  return ['OT10', 'AL10', 'IN10', 'IN11', 'IN20', 'IN30', 'IN40', 'IN50', 'IN60', 'IN90'].includes(
    statusCode.toString().toUpperCase(),
  );
};
