import { useCallback, useContext } from 'react';
import { showToast } from 'components/toast/toast';
import { ChatContext } from 'src-new/contexts/chat/chat.context';
import { IUserChat } from 'src-new/domains/user-chat.domain';
import { getUserChatByIdService } from 'src-new/services/chat/get-user-chat-by-id/get-user-chat-by-id.service';

export const useGetUserEmailsChat = () => {
  const { chatUserEmails } = useContext(ChatContext);
  const { setChatUserEmailsRequest, setChatUserEmailsSuccess, setChatUserEmailsError } = chatUserEmails;

  return useCallback(
    async (userEmailId: string): Promise<void> => {
      setChatUserEmailsRequest();

      try {
        const userEmail: IUserChat | undefined = await getUserChatByIdService(userEmailId);

        setChatUserEmailsSuccess(userEmail);
      } catch (error) {
        setChatUserEmailsError();
        showToast({
          message: 'Ocorreu um erro ao tentar obter o usuário email',
          type: 'error',
        });
      }
    },
    [setChatUserEmailsError, setChatUserEmailsRequest, setChatUserEmailsSuccess],
  );
};
