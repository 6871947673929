import React, { FC } from 'react';

import BackhaulPage from '../../home/backhaul/backhaul.page';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const DATA_TEST_ID = 'ecosystem-offer-page';

export const EcosystemOfferPage: FC = () => {
  return (
    <>
      <HeaderPage
        dataTestId={DATA_TEST_ID}
        breadcrumbList={['Ociosidade', 'Ofertas de ecossistema']}
        renderCustomBackButton={true}
      />
      <Title dataTestId={DATA_TEST_ID} title="Dê um match com outras operações." hasTrace />

      <BackhaulPage />
    </>
  );
};
