import { normalizeCurrency } from 'src-new/utils/normalize-currency.util';

type ITotalService = {
  ICMSPercentage: number;
  ICMSField: number;
  otherTaxes: number;
  totalFreight: number;
};

export const calculateServiceTotal = (values: ITotalService) => {
  const { otherTaxes, ICMSPercentage, totalFreight, ICMSField } = values;

  const ICMSTax = calculateICMS(ICMSPercentage, totalFreight);

  const freightPlusICMS = totalFreight + ICMSTax;

  if (Number.isNaN(freightPlusICMS)) return 0;

  const freightPlusOtherTaxes = freightPlusICMS + normalizeCurrency(otherTaxes) + normalizeCurrency(ICMSField);

  if (Number.isNaN(freightPlusOtherTaxes)) return 0;

  return freightPlusOtherTaxes.toFixed(2);
};

export const calculateICMS = (ICMS: number, freightTotal: number) => {
  const tax = freightTotal / (1 - ICMS / 100) - freightTotal;

  if (Number.isNaN(tax)) return 0;

  return Number(tax.toFixed(2));
};

export const calculateFeeLogshare = (feeLogshare: number, freightValue: number) => {
  const tax = freightValue * (feeLogshare / 100);

  if (Number.isNaN(tax)) return 0;

  return Number(tax.toFixed(2));
};
