import { IScheduleDetailsAllocationDriverDomain } from '../../../../domains/schedule-details-allocation-driver.domain';
import { IScheduleDetailsAllocationDriverResponse } from '../../get-driver-list.types';

export const converterScheduleDetailsAllocationDriverResponseToDomain = (
  scheduleDetailAllocationDriverResponse: IScheduleDetailsAllocationDriverResponse,
): IScheduleDetailsAllocationDriverDomain => {
  return {
    id: scheduleDetailAllocationDriverResponse.id,
    name: scheduleDetailAllocationDriverResponse.name,
    cpf: scheduleDetailAllocationDriverResponse.cpf,
    telephone: scheduleDetailAllocationDriverResponse.firstPhone,
  };
};
