import styled, { css } from 'styled-components';

import { KanbanProps } from './kanban-header';

type ContainerProps = Pick<KanbanProps, 'bgColor'>;

export const ContainerStyled = styled.div<ContainerProps>`
  ${({ theme, bgColor = 'veryLightGray' }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: ${!!bgColor && theme.colors[bgColor]};
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    font-weight: ${bgColor === 'veryLightGray' ? theme.font.normal : theme.font.bold};
    color: ${bgColor === 'veryLightGray' ? theme.colors.black : theme.colors.white};
    cursor: default;

    :hover {
      opacity: 0.98;
    }
  `}
`;
