import React, { useMemo } from 'react';

import { CardResumeOriginAndDestinyInput, IDeliveryScheduleDetailsValues } from './types';
import * as S from './styled';

interface CardResumeDeliveryProps {
  destinyValues: CardResumeOriginAndDestinyInput;
  scheduleDetailsValues?: IDeliveryScheduleDetailsValues;
}

export const CardResumeDelivery: React.FC<CardResumeDeliveryProps> = ({ destinyValues, scheduleDetailsValues }) => {
  const deliveryDate = useMemo(() => {
    if (!scheduleDetailsValues) return null;

    const { deliveryInitialDate, deliveryEndDate } = scheduleDetailsValues;

    if (deliveryInitialDate && deliveryEndDate) {
      const formattedDeliveryInitialDate = deliveryInitialDate !== 'null ' ? deliveryInitialDate : null;
      const formattedDeliveryEndDate = deliveryEndDate !== 'null ' ? deliveryEndDate : null;

      if (formattedDeliveryInitialDate && formattedDeliveryEndDate) {
        return `${formattedDeliveryInitialDate} - ${formattedDeliveryEndDate}`;
      }

      if (formattedDeliveryInitialDate) return formattedDeliveryInitialDate;

      if (formattedDeliveryEndDate) return formattedDeliveryEndDate;
    }

    return null;
  }, [scheduleDetailsValues]);

  return (
    <S.Content>
      <S.LineSection columns="0.5fr 2.5fr 8fr">
        <S.StepIconSection>
          <S.StepIcon />
        </S.StepIconSection>

        <S.Text fontWeight>Entrega</S.Text>

        {destinyValues.tradeName ? (
          <S.TextContent uppercase>
            <S.Text fontWeight>{destinyValues.tradeName}</S.Text>
            <S.Text>{`${destinyValues.address.street} ${destinyValues.address.numberHouse}`}</S.Text>
            <S.Text>{`${destinyValues.address.neighborhood} - ${destinyValues.address.city} - ${destinyValues.address.uf}`}</S.Text>
            <S.Text>{destinyValues.address.cep}</S.Text>
            <S.Text>{destinyValues.cnpj}</S.Text>
            <S.Text>{deliveryDate}</S.Text>
          </S.TextContent>
        ) : (
          <S.TextContent italic>
            <S.Text>Não informado</S.Text>
          </S.TextContent>
        )}
      </S.LineSection>
    </S.Content>
  );
};
