import { ModalState } from 'domain-v2/inputs/modals';
import { FC, Fragment, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { IScheduleDetailsDomain } from '../../../../domains/schedule-details.domain';
import { IRetentionFinesDomain } from '../../../../domains/schedule-finance-retention-fines.domain';
import * as S from './schedule-details-resume-freetime-view.styled';
import { convertDecimalFormat } from 'src-new/utils/convert-decimal-format.util';

interface IScheduleDetailsResumeFreeTimeViewProps {
  modal: ModalState;
  scheduleDetails: IScheduleDetailsDomain;
  freeTime: IRetentionFinesDomain;
  isTracking: boolean;
  handleOpenOrCloseModalGR: (isOpen: boolean) => void;
}

export const ScheduleDetailsResumeFreeTimeView: FC<IScheduleDetailsResumeFreeTimeViewProps> = ({
  modal,
  freeTime,
  scheduleDetails,
  isTracking,
  handleOpenOrCloseModalGR,
}) => {
  const renderModalGR = useMemo(() => {
    if (modal.open) {
      return (
        <ModalBark size={'md'} title={'Requerimentos de GR'} handleClose={() => handleOpenOrCloseModalGR(false)}>
          <S.ModalGRContent>
            <S.ModalGRInfo>
              <S.Text isStrong>Origem</S.Text>
              <S.Text>{scheduleDetails.origin.qualityAndSafety || 'Não Informado'}</S.Text>
            </S.ModalGRInfo>
            <S.ModalGRInfo>
              <S.Text isStrong>Destino</S.Text>
              <S.Text>{scheduleDetails.destination.qualityAndSafety || 'Não Informado'}</S.Text>
            </S.ModalGRInfo>
          </S.ModalGRContent>
        </ModalBark>
      );
    }
  }, [
    handleOpenOrCloseModalGR,
    modal.open,
    scheduleDetails.destination.qualityAndSafety,
    scheduleDetails.origin.qualityAndSafety,
  ]);

  const renderGR = useMemo(() => {
    return (
      <S.Div>
        <S.Text isStrong></S.Text>
        <S.Div>
          <S.WrapperTransactionValues>
            <S.WrapperTitle onClick={() => handleOpenOrCloseModalGR(true)}>
              <S.TextGR isStrong>requerimentos de GR</S.TextGR>
            </S.WrapperTitle>
          </S.WrapperTransactionValues>
        </S.Div>
      </S.Div>
    );
  }, [handleOpenOrCloseModalGR]);

  if (isTracking) {
    return (
      <Fragment>
        {renderModalGR}
        <S.Wrapper>
          <S.WrapperFinance>
            <S.StepIconSection>
              <S.StepIcon />
            </S.StepIconSection>
            <S.WrapperFinanceInfo>{renderGR}</S.WrapperFinanceInfo>
          </S.WrapperFinance>
        </S.Wrapper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {renderModalGR}
      <S.Wrapper>
        <S.WrapperFinance>
          <S.StepIconSection>
            <S.StepIcon />
          </S.StepIconSection>

          <S.WrapperFinanceInfo>
            <S.Div>
              <S.Text isStrong>políticas</S.Text>
              <S.WrapperInfos>
                <S.WrapperTransactionValues>
                  <S.WrapperTitle>
                    <S.Text isStrong>freetime e diárias</S.Text>
                  </S.WrapperTitle>
                  <S.WrapperCurrency>
                    <S.Text>freetime</S.Text>
                    <S.TextValue>{freeTime.freeTime}</S.TextValue>
                  </S.WrapperCurrency>
                  <S.WrapperCurrency>
                    <S.Text>hora adicional</S.Text>
                    <S.ContainerCurrency>
                      <S.TextValue>R$</S.TextValue>
                      <S.TextValue>{convertDecimalFormat.format(freeTime.hourlyFine)}</S.TextValue>
                    </S.ContainerCurrency>
                  </S.WrapperCurrency>
                  <S.WrapperCurrency>
                    <S.Text>diária</S.Text>
                    <S.ContainerCurrency>
                      <S.TextValue>R$</S.TextValue>
                      <S.TextValue>{convertDecimalFormat.format(freeTime.dailyFine)}</S.TextValue>
                    </S.ContainerCurrency>
                  </S.WrapperCurrency>
                </S.WrapperTransactionValues>
              </S.WrapperInfos>
            </S.Div>
            {renderGR}
          </S.WrapperFinanceInfo>
        </S.WrapperFinance>
      </S.Wrapper>
    </Fragment>
  );
};
