import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const Header = styled.section`
  margin-bottom: 28px;
`;

export const ContainerSearch = styled.div`
  display: flex;
  gap: 40px;
  margin: 25px 0;
`;

export const Content = styled.section``;
