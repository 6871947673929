export const convertStatusEntityToDomainMapper = (statusEntity: string) => {
  switch (statusEntity) {
    case 'active':
      return 'ativo';
    case 'inactive':
      return 'inativo';
    case 'suspended':
      return 'suspenso';
    case 'deleted':
      return 'deletado';
    default:
      return '';
  }
};
