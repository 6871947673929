import { IWeighingDomain } from '../../../../domains/weighing-details.domain';
import { IWeighingResponse } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/services/get-weighing-by-id/get-weighing-by-id.types';
import { InputMaskCPFEntityToDomain } from 'utils/remove-input-mask/remove-input-mask';
import { formatCelphoneNumberEntityToDomain } from 'utils/remove-telephone-mask/remove-telephone-mask';

export const converterWeighingDetailsResponseToDomain = (weighingResponse: IWeighingResponse): IWeighingDomain => {
  return {
    ...weighingResponse,
    ticketId: weighingResponse.ticketId ?? 0,
    origin: {
      ...weighingResponse.origin,
      cep: weighingResponse.origin.cep,
    },
    destination: {
      ...weighingResponse.destination,
      cep: weighingResponse.destination.cep,
    },
    entryDate: weighingResponse.entryDate ? new Date(weighingResponse.entryDate).toString() : '',
    exitDate: weighingResponse.exitDate ? new Date(weighingResponse.exitDate).toString() : '',
    originWeight: weighingResponse.originWeight,
    scheduledDateTime: weighingResponse.scheduledDateTime
      ? new Date(weighingResponse.scheduledDateTime).toString()
      : '',
    driver: {
      ...weighingResponse.driver,
      cpf: InputMaskCPFEntityToDomain(weighingResponse.driver.cpf),
      phone: formatCelphoneNumberEntityToDomain(weighingResponse.driver.phone),
    },
    vehicle: {
      ...weighingResponse.vehicle,
      type: weighingResponse.vehicle.type,
      category: weighingResponse.vehicle.category,
    },
    item: {
      id: weighingResponse.item.id,
      description: weighingResponse.item.description,
    },
  };
};
