import React, { useState } from 'react';
import { FormikProps, withFormik } from 'formik';

import * as S from './modal-terms.styled';

import { IModalTerms, ILastTerms } from 'pages/login/services/entities/login.entity';
import InputFieldCpf from 'components/input-field-cpf/input-field-cpf';
import InputField from 'components/input-field/input-field';
import Checkbox from 'components/checkbox/checkbox';
import Button from 'components/button/button';

export type ModalTermsViewProps = {
  modalTermsValues: IModalTerms;
  handleRegister: (values: IModalTerms) => void;
  schemaValidation: object;
  lastTerms: ILastTerms;
};

const ModalTermsView: React.FC<ModalTermsViewProps> = ({
  modalTermsValues,
  handleRegister,
  schemaValidation,
  lastTerms,
}) => {
  const InnerForm = (props: FormikProps<IModalTerms>) => {
    const { values, handleChange, handleSubmit, errors, touched } = props;
    const [termAgree, setTermAgree] = useState(false);

    return (
      <>
        <S.LineSection columns="1fr 0.5fr">
          <InputField
            id="userName"
            name="userName"
            type="text"
            label="Nome Completo *"
            upperCase={true}
            onChange={handleChange}
            value={values.userName}
            hasError={!!errors.userName && !!touched.userName}
            errorMessage={String(errors.userName)}
          />

          <InputFieldCpf
            id="userCpf"
            name="userCpf"
            type="text"
            label="CPF *"
            onChange={handleChange}
            value={values.userCpf}
            hasError={!!errors.userCpf && !!touched.userCpf}
            errorMessage={String(errors.userCpf)}
          />
        </S.LineSection>
        <S.CheckboxSection>
          <Checkbox id="terms" name="terms" onChange={() => setTermAgree(!termAgree)} />
          <S.TermsText>
            {'Declaro que li e concordo com os '}
            <S.LinkText target="_blank" href={lastTerms.content}>
              termos de uso
            </S.LinkText>
            {' da LogShare.'}
          </S.TermsText>
        </S.CheckboxSection>
        <S.ButtonSection>
          <Button title="Confirmar" size="medium" callback={handleSubmit} disabled={!termAgree} />
        </S.ButtonSection>
      </>
    );
  };
  const LoginDemoForm = withFormik<IModalTerms, IModalTerms>({
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleRegister(values);
    },
    validationSchema: schemaValidation,
  })(InnerForm);

  return <LoginDemoForm {...modalTermsValues} />;
};

export default ModalTermsView;
