import React from 'react';
import { OutlinedTextFieldProps } from '@mui/material';

import * as S from './outline-input.style';

interface OutlineFieldProps extends OutlinedTextFieldProps {
  error?: boolean;
  upperCase?: boolean;
  errormessage?: string;
}

type InputFieldProps = Omit<OutlineFieldProps, 'variant'>;

const OutlineInput: React.FC<InputFieldProps> = (props) => {
  const { error, upperCase, errormessage } = props;

  return (
    <S.CustomOutlineInput
      {...props}
      error={error}
      size="small"
      color="primary"
      variant="outlined"
      upperCase={upperCase ?? false}
      helperText={error ? errormessage : ''}
    />
  );
};

export default OutlineInput;
