import { trackingOccurrenceRegisterService } from 'pages/tracking-occurrence/services/tracking-occurrence-register/tracking-occurrence-register.service';
import { ITrackingOccurrence } from 'domain/tracking-occurrence';

const useTrackingOccurrenceHook = () => {
  return async (trackingOccurrence: ITrackingOccurrence) => {
    await trackingOccurrenceRegisterService(trackingOccurrence);
  };
};

export { useTrackingOccurrenceHook };
