import React from 'react';
// import moment from 'moment';
// import { GoogleMap, InfoBox, InfoWindow, Marker, Polyline, useLoadScript } from '@react-google-maps/api';
import { APIProvider, Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import ModalTrackDetails from '../ModalTrackDetails';

import * as S from './styles';

import { normalizeGeopoint } from 'utils/normalizes';

interface GoogleMapsProps {
  schedule?: any;
  origin: any;
  destination: any;
  firebaseTruckRoutes: {
    isChecked: boolean;
    routes: any[];
  };
  monytorTruckRoutes: {
    isChecked: boolean;
    routes: any[];
  };
  hideModal?: boolean;
  plannedRoute?: boolean;
  handleMapKind: (mapKind: string) => void;
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({
  schedule,
  origin,
  destination,
  firebaseTruckRoutes,
  monytorTruckRoutes,
  hideModal,
  handleMapKind,
}) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  function Directions() {
    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');
    const [directionsService, setDirectionsService] = React.useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = React.useState<google.maps.DirectionsRenderer>();

    // Initialize directions service and renderer
    React.useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionsService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    // Use directions service
    React.useEffect(() => {
      if (!directionsService || !directionsRenderer) return;

      directionsService
        .route({
          origin: { lat: Number(normalizeGeopoint(origin.lat)), lng: Number(normalizeGeopoint(origin.lng)) },
          destination: {
            lat: Number(normalizeGeopoint(destination.lat)),
            lng: Number(normalizeGeopoint(destination.lng)),
          },
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        });

      return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer]);

    return <div></div>;
  }

  return (
    <>
      <APIProvider apiKey={'AIzaSyDAjn84_Cb4XHXAmSqu8dw0ogQNaSEoRJQ'}>
        <Map defaultCenter={origin} defaultZoom={8} gestureHandling={'greedy'} disableDefaultUI={true}>
          <Directions />
        </Map>
        {!hideModal && <S.ModalButton onClick={() => setOpenModal(true)}>VER TRAJETO</S.ModalButton>}
      </APIProvider>

      {openModal && (
        <ModalTrackDetails
          schedule={schedule}
          origin={origin}
          destination={destination}
          firebaseTruckRoutes={firebaseTruckRoutes}
          monytorTruckRoutes={monytorTruckRoutes}
          onClose={setOpenModal}
          handleMapKind={handleMapKind}
        />
      )}
    </>
  );
};

export default React.memo(GoogleMaps);
