import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import UserListBodyView from './user-list-body-view';

import { DownloadFile } from 'utils/download-file/download-file';
import { UsersContext } from 'pages/user/states/users.context';
import { convertActiveTabToStatusDomainMapper } from 'pages/user/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import { useUsersHook } from 'pages/user/hooks/user-list-hooks/user-list.hook';
import { useReportExcelHook } from 'pages/user/hooks/use-location-report-excel/use-location-report-excel.hook';
import { useDeleteUsersHook } from 'pages/user/hooks';
import { IUserStatus } from 'domain/user';
import { ReportOption } from 'domain/report-files';
import { showToast } from 'components/toast/toast';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const OptionsOrder: Array<ReportOption> = [
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Registro mais Antigo', value: 'asc' },
];

const UserListBody: React.FC = () => {
  const GenerateExcelHook = useReportExcelHook();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [searchInput, setSearchInput] = useState<string>('');

  const [activeTabName, setActiveTabName] = useState<string>('Ativos');

  const [orderList, setOrderList] = useState<string>('asc');

  const [columnList, setColumnList] = useState<string>('name');

  const [currentPageUsers, setCurrentPageUsers] = useState(1);

  const [idsDelete, setIdsDelete] = useState<number[]>([]);

  const usersHook = useUsersHook();
  const deleteUsersHook = useDeleteUsersHook();

  const { users, usersPageInfo, usersIsLoading } = useContext(UsersContext);

  const goToCreateUser = useCallback(() => navigate('/usuarios/novo'), [navigate]);

  const goToDetailsPage = useCallback(
    (userId: string) => {
      if (activeTabName === 'Excluídos') return undefined;
      navigate(`/usuarios/atualizar/${userId}`);
    },
    [activeTabName, navigate],
  );

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName),
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'USUARIO');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  const renderStatusByTabName = useMemo((): IUserStatus | null => {
    if (activeTabName === 'Todos' || activeTabName === 'Excluídos') {
      return null;
    }
    if (activeTabName === 'Ativos') {
      return 'ativo';
    }
    if (activeTabName === 'Inativos') {
      return 'inativo';
    }
    if (activeTabName === 'Bloqueados') {
      return 'suspenso';
    }
    return null;
  }, [activeTabName]);

  const handleOrderList = useCallback(
    async (optionOrder: ReportOption) => {
      if (optionOrder.value === 'name') {
        usersHook({
          searchValue: searchInput,
          currentPage: currentPageUsers,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'login',
          excluded: activeTabName === 'Excluídos' ? 'true' : '',
        });
      }
      if (optionOrder.value === 'asc') {
        usersHook({
          searchValue: searchInput,
          currentPage: currentPageUsers,
          status: renderStatusByTabName,
          order: 'asc',
          column: 'id',
          excluded: activeTabName === 'Excluídos' ? 'true' : '',
        });
      }
      showToast({
        type: 'info',
        message: 'Atualizando listagem',
      });
    },
    [currentPageUsers, renderStatusByTabName, searchInput, usersHook, activeTabName],
  );

  useEffect(() => {
    usersHook({
      searchValue: searchInput,
      currentPage: currentPageUsers,
      status: renderStatusByTabName,
      order: orderList,
      column: columnList,
      excluded: activeTabName === 'Excluídos' ? 'true' : '',
    });
  }, [columnList, currentPageUsers, orderList, renderStatusByTabName, searchInput, usersHook, activeTabName]);

  useEffect(() => {
    setCurrentPageUsers(1);
  }, [activeTabName]);

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleDeleteUsers = useCallback(async () => {
    if (idsDelete.length === 0) {
      showToast({
        message: 'Escolha pelo menos um usuário para excluir',
        type: 'error',
      });
      return;
    }

    const response = await deleteUsersHook(idsDelete);

    if (response) {
      showToast({
        message: 'Usuário(s) excluído(s) com sucesso',
        type: 'success',
      });
      usersHook({
        searchValue: searchInput,
        currentPage: currentPageUsers,
        status: renderStatusByTabName,
        order: orderList,
        column: columnList,
        excluded: activeTabName === 'Excluídos' ? 'true' : '',
      });
      setIdsDelete([]);
      setShowModal(false);
    }
  }, [
    activeTabName,
    columnList,
    currentPageUsers,
    idsDelete,
    orderList,
    renderStatusByTabName,
    searchInput,
    usersHook,
  ]);

  return (
    <UserListBodyView
      goToCreateUser={goToCreateUser}
      goToDetailsPage={goToDetailsPage}
      users={users}
      usersPageInfo={usersPageInfo}
      setCurrentPageUsers={setCurrentPageUsers}
      hasLoading={usersIsLoading}
      setSearchInput={setSearchInput}
      setActiveTabName={setActiveTabName}
      showModalConfirmation={showModalConfirmation}
      handleDeleteUsers={handleDeleteUsers}
      showModal={showModal}
      onHandleExport={handleGenerateExcel}
      options={Options}
      handleOrderList={handleOrderList}
      OptionsOrder={OptionsOrder}
      setOrder={setOrderList}
      setColumn={setColumnList}
      setIdsToRemove={setIdsDelete}
    />
  );
};

export default UserListBody;
