import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { InformationCompanySchema } from './schemas-validation/schemas-validation';
import CompanyInformationFormView from './company-information-form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { deleteRetentionFinesHook } from 'pages/company/hooks/delete-retention-fines/delete-retention-fines.hook';
import { useUpdateCompanyHook } from 'pages/company/hooks/company-update/company-update.hook';
import {
  initialValues,
  PATH_TO_HOME,
  contactTypes,
} from 'pages/company/company-update/components/company-update-form/form-values/form-values';
import { useCepHook } from 'hooks/cep/cep.hook';
import { IBranch, ICompany, ICompanyContact } from 'domain/company';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { useGetCompanyFreetime } from 'pages/company/hooks/use-get-company-freetime/use-get-company-freetime.hook';
import { useGetCompanyBranch } from 'pages/company/hooks/use-get-company-branch/use-get-company-branch.hook';

const CompanyInformationForm: React.FC = () => {
  const [companyInformation, setCompanyInformation] = useState<ICompany>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [arrayToDeleteBranches, setArrayToDeleteBranches] = useState<number[]>([]);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const getCompanyHook = useGetCompany();
  const getCompanyFreetimeHook = useGetCompanyFreetime();
  const getCompanyBranchHook = useGetCompanyBranch();
  const companyUpdateHook = useUpdateCompanyHook();
  const deleteRetentionFines = deleteRetentionFinesHook();
  const cepHook = useCepHook();

  const goBackToHome = useCallback(() => navigate(PATH_TO_HOME), [navigate]);

  const handleSubmit = useCallback(
    async (company: ICompany) => {
      const verifyBranches = company.branches.map((item: IBranch) => {
        if (
          item.cnpj.length < 1 ||
          item.corporateName.length < 1 ||
          item.fantasyName.length < 1 ||
          item.cep.length < 1 ||
          (item.address && item.address.length < 1) ||
          (item.city && item.city.length < 1) ||
          (item.number && item.number.length < 1) ||
          (item.state && item.state.length < 1)
        )
          return true;
        return false;
      });
      if (verifyBranches.includes(true)) {
        showMessageFormatted({
          message: 'É necessário preencher todos os dados obrigatórios para cadastrar uma filial',
          type: 'error',
        });
        return;
      }
      setIsLoading(true);
      companyUpdateHook(company, arrayToDeleteBranches)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToHome();
        })
        .catch((error) => {
          showMessageFormatted({
            message: '',
            error: error,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });

      const retentionFinesToDelete = companyInformation.retentionFines
        ?.filter((information) => !company.retentionFines?.some((values) => values.id === information.id))
        .map((item) => Number(item.id));

      if (retentionFinesToDelete && retentionFinesToDelete.length >= 1) deleteRetentionFines(retentionFinesToDelete);
    },
    [companyUpdateHook, arrayToDeleteBranches, companyInformation.retentionFines, deleteRetentionFines, goBackToHome],
  );

  const onBlurCep = useCallback(async (cep: string) => cepHook(cep), [cepHook]);

  const mergeContactsCreatedWithInitial = useCallback((contacts: Array<ICompanyContact>): Array<ICompanyContact> => {
    const maxContacts = 6;

    const allContacts = [];
    for (let index = 0; index < maxContacts; index++) {
      const existContact = contacts?.find((contact) => contact?.type === contactTypes[index]);

      if (existContact) {
        allContacts.push(existContact);
      } else {
        allContacts.push({ name: '', email: '', telephone: '' });
      }
    }
    return allContacts;
  }, []);

  useEffect(() => {
    (async () => {
      if (user?.companyId) {
        const [company, freetime, branch] = await Promise.all([
          getCompanyHook(user.companyId),
          getCompanyFreetimeHook(user.companyId),
          getCompanyBranchHook(user.companyId),
        ]);

        if (!company) return;

        const contacts = mergeContactsCreatedWithInitial(company.contacts);
        const companyWithContacts: ICompany = {
          ...company,
          contacts,
          retentionFines: freetime,
          branches: branch,
        };
        setCompanyInformation(companyWithContacts);
      }
    })();
  }, [getCompanyBranchHook, getCompanyFreetimeHook, getCompanyHook, mergeContactsCreatedWithInitial, user?.companyId]);

  return (
    <CompanyInformationFormView
      companyValues={companyInformation}
      isLoading={isLoading}
      handleRegister={handleSubmit}
      handleGoBack={goBackToHome}
      validationSchema={InformationCompanySchema}
      onBlurCep={onBlurCep}
      setArrayToDeleteBranches={(index: number) => setArrayToDeleteBranches([...arrayToDeleteBranches, index])}
    />
  );
};

export default CompanyInformationForm;
