import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { resendCollectionOrderEmailService } from '../../services/resend-collection-order-email/resend-collection-order-email.service';

export const useResendCollectionOrderEmail = () => {
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '0', [id]);

  return useCallback(async () => {
    try {
      showMessageFormatted({
        message: 'Reenviando ordem de coleta...',
        type: 'info',
      });

      await resendCollectionOrderEmailService(Number(scheduleId));

      showMessageFormatted({
        message: 'Ordem de coleta reenviada com sucesso.',
        type: 'success',
      });
    } catch (error: any) {
      showMessageFormatted({
        error,
        type: 'error',
      });
    }
  }, [scheduleId]);
};
