import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/transport-company/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { ITransportCompanyStatus } from 'domain/transport-company';

interface TransportReportExcelServiceProps {
  search: string;
  status: ITransportCompanyStatus | undefined;
}

export const transportReportExcelService = async ({
  search,
  status,
}: TransportReportExcelServiceProps): Promise<BlobPart | undefined> => {
  try {
    const convertedStatus = status ? convertStatusDomainToEntityMapper(status) : status;

    const { data } = await API.get('shipping-company-export-excel', {
      params: {
        status: convertedStatus,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar transportadora');
  }
};
