import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Tag } from 'logshare-ui-kit';
import { useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';

import {
  ContainerListStyled,
  ContainerModalStyled,
  FormStyled,
  LineSectionStyled,
  SectionStyled,
  UploadImageStyled,
} from '../tracking-occurrence-update-form.styled';
import { NegotiationsModalBody } from '../negotiations-modal/components/negotiations-modal-body/negotiations-modal-body';
import InputUploadFile from '../../../../tracking-occurrence-register/components/tracking-occurrence-input-upload/input-file-upload';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ColorsV2Type } from 'styles/types/colors';
import { checkOccurrenceOverdue } from 'pages/tracking-occurrence/utils';
import {
  ContainerInfoDescriptionStyled,
  ContainerInfoStyled,
  HeaderTitleDealings,
  TableDescriptionStyled,
  TableStyled,
  WrapperSectionStyled,
} from 'pages/tracking-occurrence/tracking-occurrence-register/components/tracking-occurrence-register-form/tracking-occurrence-register-form.styled';
import { convertStatusEntityToDomainMapper } from 'pages/tracking-occurrence/mappers/convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';
import { useUpdateTrackingOccurrence } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-update/use-tracking-occurrence-update.hook';
import { useDeleteOccurrenceImageHook } from 'pages/occurrence/hooks';
import FreightOrderDetailsSection from 'pages/freight/freight-update/freight-update-form/freight-register-section/freight-order-details-section/freight-order-details-section';
import {
  HeaderTitleStyled,
  SectionContainerStyled,
} from 'pages/freight/freight-update/freight-update-form/freight-register-section/freight-cargo-photos-section/freight-cargo-photos-section.styled';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';
import { IOccurrenceResolution, ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';
import { FooterPage } from 'components-v2/common/footer-page';
import Select, { SelectPropsOptions } from 'components/select/select';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import InputFieldTime from 'components/input-time/input-time';
import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';

interface Props {
  currentAllocation: ICurrentAllocation;
  occurrenceType: {
    sla: string;
    impact: 'low' | 'medium' | 'high';
    description: string;
  };
  occurrencesTypes: SelectPropsOptions[];
  trackingOccurrenceRegisterValues: ITrackingOccurrenceCreated;
  isLoading: boolean;
  isAdmin?: boolean;
  goToDescriptionView: (item: any) => void;
  handleGoBack: () => void;
  handleRemoveOccurrence: () => void;
  handleFinishTrackingOccurrence: () => void;
  getAllocation: () => void;
}

export const FormTrackingOccurrenceUpdate: FC<Props> = (props) => {
  const {
    currentAllocation,
    occurrencesTypes,
    trackingOccurrenceRegisterValues,
    isLoading,
    occurrenceType,
    isAdmin,
    goToDescriptionView,
    handleGoBack,
    handleRemoveOccurrence,
    handleFinishTrackingOccurrence,
    getAllocation,
  } = props;

  const formikValues = useFormikContext<ITrackingOccurrenceCreated>();
  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = formikValues;

  const trackingOccurrenceUpdateHook = useUpdateTrackingOccurrence();
  const deleteOccurrenceImageHook = useDeleteOccurrenceImageHook();

  const [isPhotoLoading, setIsPhotoLoading] = useState(false);

  const [title, setTitle] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);

  const isFinished = useMemo(() => {
    return trackingOccurrenceRegisterValues.statusResolution === 'finalizadas';
  }, [trackingOccurrenceRegisterValues.statusResolution]);

  const openModalNegotiations = () => {
    setShowModal(!showModal);
    setTitle('Adicionar Tratativa');
  };

  const handleRegisterOccurrence = (occurrenceValues: IOccurrenceResolution) => {
    if (values.occurrenceResolutions) {
      setFieldValue('occurrenceResolutions', [...values.occurrenceResolutions, occurrenceValues]);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (values.statusResolution === 'finalizadas') {
      setFieldValue('endDate', moment().format('DD/MM/YYYY'));
      setFieldValue('endTime', moment().format('HH:mm'));
    }
  }, [setFieldValue, values.statusResolution]);

  const dateDifference = useMemo((): string => {
    if (!trackingOccurrenceRegisterValues.resolutionTime) return '0 DIAS 0 HORAS';

    return trackingOccurrenceRegisterValues.resolutionTime;
  }, [trackingOccurrenceRegisterValues.resolutionTime]);

  const handleUpload = useCallback(
    async (photo: File[]) => {
      trackingOccurrenceUpdateHook({
        ...trackingOccurrenceRegisterValues,
        occurrenceNewFiles: photo,
      })
        .then(() => {
          getAllocation();
          showMessageFormatted({
            message: 'Foto/Registro atualizado com sucesso.',
            type: 'success',
          });
        })
        .catch((error) => {
          showMessageFormatted({
            error: error,
            type: 'error',
          });
        });
    },
    [trackingOccurrenceUpdateHook],
  );

  const handleRemovePhoto = async (photoId: number) => {
    if (photoId) {
      try {
        setIsPhotoLoading(true);
        await deleteOccurrenceImageHook(photoId);
        await getAllocation();
      } catch (error) {
        return;
      } finally {
        setIsPhotoLoading(false);
      }
    }
  };

  const handleAddPhoto = async (file: File) => {
    if (file) {
      setIsPhotoLoading(true);
      await handleUpload([file]);
      setIsPhotoLoading(false);
    }
  };

  const renderUploadedPhotos = useMemo(() => {
    if (trackingOccurrenceRegisterValues.occurrenceImagesUrl) {
      return trackingOccurrenceRegisterValues.occurrenceImagesUrl?.map((photo, index) => {
        const photoMetaData = trackingOccurrenceRegisterValues.occurrenceFiles?.[index];
        return (
          <SectionContainerStyled key={index}>
            <InputUploadFile
              urlFile={photo}
              handleSelectedImage={() => null}
              deleteImage={isFinished ? undefined : () => handleRemovePhoto(photoMetaData?.id || -1)}
            />
          </SectionContainerStyled>
        );
      });
    }

    return [];
  }, [
    isFinished,
    trackingOccurrenceRegisterValues.occurrenceFiles,
    trackingOccurrenceRegisterValues.occurrenceImagesUrl,
  ]);

  const isOverdue = checkOccurrenceOverdue(occurrenceType.sla, `${values.occurrenceDate} ${values.occurrenceTime}`);

  return (
    <>
      {modal && (
        <ModalConfirmation
          title="Finalizar Ocorrência"
          description={
            <div style={{ textAlign: 'center' }}>
              <p>Tem certeza que deseja finalizar</p>
              <p>a ocorrência?</p>
            </div>
          }
          size="very-small"
          type="finished"
          onConfirmation={handleFinishTrackingOccurrence}
          handleCancel={() => setModal(false)}
        />
      )}
      <FreightOrderDetailsSection
        occurrenceStatus={convertStatusEntityToDomainMapper(values.statusResolution)}
        idSheduleValues={String(currentAllocation.id)}
        type={currentAllocation.typeSchedule as any}
        occurrenceStartTime={`${trackingOccurrenceRegisterValues.occurrenceDate} ${trackingOccurrenceRegisterValues.occurrenceTime}`}
        OccurrenceTime={dateDifference}
      />
      <FormStyled>
        <ContainerModalStyled>
          <NegotiationsModalBody
            title={title}
            setShowModal={setShowModal}
            showModal={showModal}
            submitModal={handleRegisterOccurrence}
          />
        </ContainerModalStyled>

        <SectionStyled name="Motoristas">
          <WrapperSectionStyled>
            <HeaderTitleDealings>
              Detalhes da Ocorrência
              <Tag
                label={isOverdue ? 'Em Atraso' : 'No Prazo'}
                color={isOverdue ? ColorsV2Type.Red : ColorsV2Type.Green}
                variant="solid"
                display="flex"
                size="md"
              />
            </HeaderTitleDealings>

            <LineSectionStyled columns="0.5fr 0.5fr 0.5fr 0.5fr 0.7fr 1.3fr">
              <InputField
                label="Data Inicial"
                id="occurrenceDate"
                name="occurrenceDate"
                type="text"
                onChange={handleChange}
                value={values.occurrenceDate.split(' ')[0]}
                hasError={!!errors?.occurrenceDate && !!touched?.occurrenceDate}
                errorMessage={String(errors.occurrenceDate)}
                disabled
              />

              <InputFieldTime
                label="Horário Inicial"
                id="occurrenceTime"
                name="occurrenceTime"
                type="text"
                onChange={handleChange}
                value={values.occurrenceTime}
                hasError={!!errors?.occurrenceTime && !!touched?.occurrenceTime}
                errorMessage={String(errors.occurrenceTime)}
                disabled
              />

              <InputField
                label="Data Final"
                id="endDate"
                name="endDate"
                type="text"
                onChange={handleChange}
                value={values.endDate}
                hasError={!!errors?.endDate && !!touched?.endDate}
                errorMessage={String(errors.endDate)}
                disabled
              />

              <InputFieldTime
                label="Horário Final"
                id="endTime"
                name="endTime"
                type="text"
                onChange={handleChange}
                value={values.endTime}
                hasError={!!errors?.endTime && !!touched?.endTime}
                errorMessage={String(errors.endTime)}
                disabled
              />

              <InputField
                label="Chamado"
                id="ticketNumber"
                name="ticketNumber"
                type="text"
                value={values.ticketNumber}
                onChange={handleChange}
              />              

              <Select
                label="Tipo de Ocorrência"
                id="occurrenceType.description"
                name="occurrenceType.description"
                value={values.occurrenceType?.description ?? occurrenceType?.description}
                setFieldValue={setFieldValue}
                hasError={!!errors?.occurrenceType?.description && !!touched?.occurrenceType?.description}
                errorMessage={String(errors.occurrenceType?.description)}
                options={occurrencesTypes}
                disabled
              />
            </LineSectionStyled>
          </WrapperSectionStyled>

          <WrapperSectionStyled>
            <HeaderTitleDealings>
              Tratativa(s)
              <Button
                title="Adicionar"
                bgColor="blue"
                callback={openModalNegotiations}
                icon={<AddIcon />}
                size="very-small"
              />
            </HeaderTitleDealings>
            {values.occurrenceResolutions &&
              values.occurrenceResolutions.length > 0 &&
              values.occurrenceResolutions.map((occurrenceResolution, index) => (
                <>
                  <ContainerListStyled key={index}>
                    <LineSectionStyled columns="1fr 1fr 1fr 4fr">
                      <TableStyled>
                        Data
                        <ContainerInfoStyled>
                          {moment(occurrenceResolution.resolutionDate).format('DD/MM/YYYY HH:mm')}
                        </ContainerInfoStyled>
                      </TableStyled>

                      <TableStyled>
                        Tratada com
                        <ContainerInfoStyled>
                          {occurrenceResolution.userResolutionNameWith.toUpperCase()}
                        </ContainerInfoStyled>
                      </TableStyled>

                      <TableStyled>
                        Tratada por
                        <ContainerInfoStyled>
                          {occurrenceResolution.userResolutionNameBy.toUpperCase()}
                        </ContainerInfoStyled>
                      </TableStyled>

                      <TableDescriptionStyled>
                        Descrição
                        <ContainerInfoDescriptionStyled onClick={() => goToDescriptionView(index)}>
                          <p style={{ wordWrap: 'break-word' }}>{occurrenceResolution.description.toUpperCase()}</p>
                        </ContainerInfoDescriptionStyled>
                      </TableDescriptionStyled>
                    </LineSectionStyled>
                  </ContainerListStyled>
                </>
              ))}
          </WrapperSectionStyled>

          <WrapperSectionStyled>
            <HeaderTitleStyled>Fotos/Registros</HeaderTitleStyled>
            <SectionContainerStyled>
              {isPhotoLoading ? (
                <div style={{ fontSize: '12px', fontWeight: 600 }}>Carregando...</div>
              ) : (
                <UploadImageStyled>
                  {renderUploadedPhotos}
                  <InputUploadFile handleSelectedImage={handleAddPhoto} />
                </UploadImageStyled>
              )}
            </SectionContainerStyled>
          </WrapperSectionStyled>
        </SectionStyled>

        <FooterPage.Root>
          <FooterPage.RightContent>
            <FooterPage.Button
              label="Salvar"
              color="blue"
              isLoading={isLoading}
              onPress={handleSubmit}
              disabled={isFinished}
            />
            <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBack} />
          </FooterPage.RightContent>
          <FooterPage.LeftContent>
            {isAdmin && <FooterPage.Button label="Excluir" color="red" onPress={handleRemoveOccurrence} />}
            <FooterPage.Button label="Finalizar" color="green" onPress={() => setModal(true)} />
          </FooterPage.LeftContent>
        </FooterPage.Root>
      </FormStyled>
    </>
  );
};
