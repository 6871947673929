import React from 'react';

import DriversContextProvider from '../contexts/table-states/drivers.context';

import { DriverListBody } from './driver-list-body/driver-list-body';

const DriverPage: React.FC = () => {
  return (
    <DriversContextProvider>
      <DriverListBody />
    </DriversContextProvider>
  );
};

export default DriverPage;
