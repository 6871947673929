import { IBranch } from 'domain/company';
import { getCompanyBranchService } from 'pages/company/services/get-company-branch/get-company-branch.service';
import { useCallback } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetCompanyBranch = () => {
  return useCallback(async (companyId: number): Promise<Array<IBranch> | undefined> => {
    try {
      return getCompanyBranchService(companyId);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter filiais.',
        type: 'error',
      });
    }
  }, []);
};
