import { IRetentionFinesDomain } from '../../../../domains/schedule-finance-retention-fines.domain';
import { IRetentionFinesResponse } from '../../get-retention-fine-by-vehicle.types';

export const converterRetentionFinesResponseToDomain = (
  retentionFineResponse: IRetentionFinesResponse,
): IRetentionFinesDomain => {
  return {
    dailyFine: retentionFineResponse.dailyFine ?? 0,
    freeTime: retentionFineResponse.freeTime ?? '0',
    hourlyFine: retentionFineResponse.hourlyFine ?? 0,
  };
};
