import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { scheduleLTLInitialValues } from 'pages-v2/schedule-ltl/register/form/initial-values';
import { ScheduleLTLRegisterInput } from 'domain-v2/schedule-ltl/register';

interface IValidationFieldContextProps {
  children: ReactNode;
}

interface ValidationFieldProps {
  isFilled: {
    originValues: boolean;
    destinyValues: boolean;
    detailValues: boolean;
  };
  scheduleLTL: ScheduleLTLRegisterInput;
  validateField: boolean;
  setScheduleLTLValues: (input: ScheduleLTLRegisterInput) => void;
  handleValidateField: () => void;
}

const initialState = {} as ValidationFieldProps;
const initialScheduleState = scheduleLTLInitialValues;

const ValidationFieldContext = createContext<ValidationFieldProps>(initialState);

export const ValidationFieldProvider = ({ children }: IValidationFieldContextProps) => {
  const [scheduleLTL, setScheduleLTL] = useState<ScheduleLTLRegisterInput>(initialScheduleState);
  const [validateField, setValidateField] = useState<boolean>(false);

  const { origin, destination, detail } = scheduleLTL;

  const setScheduleLTLValues = useCallback((input: ScheduleLTLRegisterInput) => {
    setScheduleLTL(input);
  }, []);

  const handleValidateField = () => {
    setValidateField(true);
  };

  const isChecked = useMemo(() => {
    const originValues = !!(origin.id && origin.type);
    const destinyValues = !!(destination.id && destination.type);

    const detailValues = !!(
      detail.levyInitialDate &&
      detail.levyEndDate &&
      detail.deliveryInitialDate &&
      detail.deliveryEndDate &&
      (dayjs(detail.levyInitialDate).isBefore(detail.levyEndDate) ||
        dayjs(detail.levyInitialDate).isSame(detail.levyEndDate)) &&
      (dayjs(detail.deliveryInitialDate).isBefore(detail.deliveryEndDate) ||
        dayjs(detail.deliveryInitialDate).isSame(detail.deliveryEndDate)) &&
      dayjs(detail.levyEndDate).isBefore(detail.deliveryInitialDate)
    );

    return {
      originValues,
      destinyValues,
      detailValues,
    };
  }, [scheduleLTL]);

  const contextValue = useMemo(
    () => ({
      isFilled: isChecked,
      scheduleLTL,
      validateField,
      setScheduleLTLValues,
      handleValidateField,
    }),
    [scheduleLTL, validateField],
  );

  return <ValidationFieldContext.Provider value={contextValue}>{children}</ValidationFieldContext.Provider>;
};

export const useValidationFields = () => {
  const context = useContext(ValidationFieldContext);

  if (!context) {
    throw new Error('useValidationFields deve ser usado dentro de um ValidationFieldProvider');
  }

  return context;
};
