import { LineSectionLayout } from 'components-v2/layout/line-section';
import { ChangeEvent, FC } from 'react';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import InputField from 'components/input-field/input-field';
import { IWeighingDuplicatedDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-duplicated/domains/weighing-duplicated.domain';

interface IWeighingDuplicatedFormMountDriversViewProps {
  values: IWeighingDuplicatedDomain;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const WeighingDuplicatedFormMountDriversView: FC<IWeighingDuplicatedFormMountDriversViewProps> = ({ values, handleChange }) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr 1fr 1fr">
        <InputField
          label="Nome *"
          id="driver.name"
          name="driver.name"
          type="text"
          onChange={handleChange}
          value={values.driver.name}
          disabled
        />
        <InputField
          label="CPF *"
          id="driver.cpf"
          name="driver.cpf"
          type="text"
          onChange={handleChange}
          value={values.driver.cpf}
          disabled
        />
        <InputField
          label="Telefone *"
          id="driver.phone"
          name="driver.phone"
          type="text"
          onChange={handleChange}
          value={values.driver.phone}
          disabled
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
