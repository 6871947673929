import { ColorsV2Type } from 'styles/types/colors';

export const cargoDetailsInitialValues = {
  capacity: '',
  inPalet: '',
  invoice: '',
  needHelper: '',
  pallet: '',
  shippingValue: '',
  vehicleCategoryType: '',
  vehicleType: '',
  weight: '',
};

export const needHelperValue: { [key: string]: string } = {
  sem_ajuda: 'Sem Ajudante',
  carregamento: 'Ajudante - No Carregamento',
  descarga: 'Ajudante - Na Descarga',
  ambos: 'Ajudante - Ambos',
};

export const backhaulTypeValue: {
  [key: string]: { label: string; color: ColorsV2Type };
} = {
  'BACKHAUL INTERNO': {
    label: 'Backhaul Interno',
    color: ColorsV2Type.Green,
  },
  'BACKHAUL EXTERNO': {
    label: 'Backhaul Externo',
    color: ColorsV2Type.Blue,
  },
};
