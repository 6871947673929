import React from 'react';
import { LineConnectorView } from './connect-points-view.component';

interface ILineConnectorProps {
  bgColor: string;
  height: number;
  width?: number;
}

export const LineConnector: React.FC<ILineConnectorProps> = ({ height, width, bgColor }) => {
  return <LineConnectorView height={height} width={width} bgColor={bgColor} />;
};
