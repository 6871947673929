import { useCallback } from 'react';

import { vehicleDeleteService } from 'pages/vehicle/services/vehicle-delete/vehicle-delete.service';

const useDeleteVehicleHook = () => {
  return useCallback(async (ids: number[]): Promise<boolean> => {
    return await vehicleDeleteService(ids);
  }, []);
};

export { useDeleteVehicleHook };
