import React, { useContext, useEffect, useState } from 'react';

import { ValidationFieldProvider } from '../context/validation-fields';

import ScheduleRegisterLTLForm from './form';

import { UserContext } from 'state/user-context';
import { companyDetailsService } from 'services/company/details';
import { ICompany } from 'domain/company';
import { showToast } from 'components/toast/toast';
import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const initialCompanyValues = {} as ICompany;

const ScheduleRegisterLTLPage: React.FC = () => {
  const { user } = useContext(UserContext);

  const [company, setCompany] = useState<ICompany>(initialCompanyValues);

  useEffect(() => {
    (async () => {
      try {
        const findCompany = await companyDetailsService(user?.companyId || 0);

        setCompany(findCompany);
      } catch (error) {
        showToast({
          message: 'Erro ao obter detalhes da empresa',
          type: 'error',
        });
      }
    })();
  }, [user]);

  return (
    <>
      <HeaderPage breadcrumbList={['Programação', 'Novo Serviço']} hasBackButton />
      <Title title="Solicite um novo frete para carga fracionada (LTL)." hasTrace />
      <ValidationFieldProvider>
        <ScheduleRegisterLTLForm company={company} user={user} />
      </ValidationFieldProvider>
    </>
  );
};

export default ScheduleRegisterLTLPage;
