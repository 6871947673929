import styled, { css } from 'styled-components';

export const ContainerStyled = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    width: 97%;
    height: fits-content;
    border-radius: 5px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.veryLightGray};
    padding: 10px;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.small};
    color:#000
    cursor: default;
    margin-right: 10px;

    :hover {
      opacity: 0.8;
    }
  `}
`;

export const HeaderCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 550;
  padding-bottom: 10px;
`;

export const IdStyled = styled.div`
  font-weight: bold;
`;

export const TitleStyled = styled.p`
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 7px;
`;

export const CardInfoStyled = styled.div`
  font-weight: bold;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentStyled = styled.p`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #dd6d6;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const TagStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #12264e;
  width: 30px;
  height: 15px;
  border-radius: 5px;
  color: #fff;
`;
