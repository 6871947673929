import * as S from './styled';
import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { useCallback, useMemo } from 'react';
import { ICell, IRow } from 'src-new/components/table/table.types';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { Tag } from 'logshare-ui-kit';
import { LineConnector } from 'src-new/components/connect-points/connect-points.component';
import { Table } from 'src-new/components/table/table.component';
import { IIdlenessHeaderModal } from '../types';
import { IAddressRegistrationDomain } from 'src-new/pages/registrations/domains/address-registration.domain';
import { convertOperationType } from 'src-new/utils/convert-operation-type/convert-operation-type.util';
import { convertVehicleType } from 'utils-v2/converters/vehicle-type';
import { convertVehicleCategory } from 'utils-v2/converters/vehicle-category';

interface IHeaderBackhaulModalProps {
  idleness: IIdlenessHeaderModal;
  companyImage: string;
}

export const HeaderBackhaulModal: React.FC<IHeaderBackhaulModalProps> = ({ idleness, companyImage }) => {
  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: 'ID Oferta' },
      { value: 'Origem / Destino' },
      { value: 'Veículo' },
      { value: 'Capacidade' },
      { value: 'Frequência' },
      { value: 'Matches Potencial' },
      { value: 'Classificação' },
      { value: 'Frete Valor' },
    ];

    const columnsConfig = '0.25fr 0.7fr 0.5fr 0.5fr 0.5fr 0.6fr 0.75fr 0.4fr 0.4fr 0.4fr';

    return {
      columnsConfig,
      headerColumns,
    };
  }, []);

  const renderTitleSubtitle = useCallback((title: string | number, subtitle: string) => {
    return (
      <S.WrapperTextsInformationHeader>
        <S.Text weight={700}>{title}</S.Text>
        <S.Text size={10} weight={400} color="#7b7b7b">
          {subtitle}
        </S.Text>
      </S.WrapperTextsInformationHeader>
    );
  }, []);

  const renderCompanyImageNode = useCallback(
    (id: number) => {
      return (
        <S.WrapperIdAndImage>
          <S.Title>{id}</S.Title>

          <img
            style={{
              width: 40,
              height: 40,
              borderRadius: 25,
              marginTop: 3,
            }}
            src={companyImage}
            alt={companyImage}
          />
        </S.WrapperIdAndImage>
      );
    },
    [companyImage],
  );

  const renderLocationText = useCallback(
    (
      clientOrigin?: IAddressRegistrationDomain,
      localityOrigin?: IAddressRegistrationDomain,
      clientDestination?: IAddressRegistrationDomain,
      localityDestination?: IAddressRegistrationDomain,
    ) => {
      const origin = clientOrigin ?? localityOrigin;
      const destination = clientDestination ?? localityDestination;

      return (
        <S.WrapperInformationLocationsHeader>
          <LineConnector bgColor={'#d9d9d9'} height={42} width={1} />
          <div>
            <S.Text weight={700}>
              {origin?.city} - {origin?.uf}
            </S.Text>
            <S.Text weight={700}>
              {destination?.city} - {destination?.uf}
            </S.Text>
          </div>
        </S.WrapperInformationLocationsHeader>
      );
    },
    [],
  );

  const renderTags = useCallback((operationType: string, scheduleType?: string) => {
    return (
      <S.WrapperTags>
        <Tag
          label={convertOperationType(operationType).label}
          color={ThemeColorsV2Type.DarkGray}
          variant="ghost"
          display="inline-flex"
          size="md"
        />
        {scheduleType && (
          <Tag
            label={scheduleType}
            color={ThemeColorsV2Type.DarkGray}
            variant="ghost"
            display="inline-flex"
            size="md"
          />
        )}
      </S.WrapperTags>
    );
  }, []);

  const renderRows = useMemo((): IRow => {
    return {
      key: idleness.id,
      onClick: () => undefined,
      valuesColumns: [
        { value: renderCompanyImageNode(idleness.id) },
        {
          value: renderLocationText(
            idleness.clientOrigin,
            idleness.localityOrigin,
            idleness.clientDestination,
            idleness.localityDestination,
          ),
        },
        {
          value: renderTitleSubtitle(
            convertVehicleType(idleness.vehicleType),
            convertVehicleCategory(idleness.category),
          ),
        },
        { value: renderTitleSubtitle(`${idleness.offeredWeight} KG`, `${idleness.offeredPallet} Pallets`) },
        { value: renderTitleSubtitle(idleness.frequency, 'VIAGENS/MÊS') },
        { value: renderTitleSubtitle(0, 'ROTAS POTENCIAIS') },
        {
          value: renderTags(idleness.operationType, idleness.scheduleType),
        },
        { value: renderTitleSubtitle(convertCurrencyFormat.format(idleness.logshareValue), '+ IMPOSTOS') },
      ],
    };
  }, [idleness, renderCompanyImageNode, renderLocationText, renderTags, renderTitleSubtitle]);

  return (
    <S.Wrapper>
      <Table
        gridColumns={tableColumns.columnsConfig}
        header={tableColumns.headerColumns}
        rows={[renderRows]}
        height={'fit-content'}
      />
    </S.Wrapper>
  );
};
