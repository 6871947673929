import { IOdersReducerAction, ISchedulesContext } from './schedule.types';
import { ISchedulesAction } from './schedule.action';

export const ordersReducer = (state: ISchedulesContext, action: IOdersReducerAction) => {
  switch (action.type) {
    case ISchedulesAction.SET_RESUME_RULES:
      return { ...state, infoRules: action.payload };
    case ISchedulesAction.SET_CARDS_LIST:
      return { ...state, cards: action.payload };
    default:
      return state;
  }
};
