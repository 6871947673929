import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import ClientUpdateFormView from './client-update-form-view';
import { localitiesFilterSearchService } from 'pages/schedule/services/locality-search/locality-filter-search.service';
import { SelectItem } from 'pages/schedule/services/entities/select-items.entity';
import { useLocationDetailstHook } from 'pages/location/hooks/use-location-details/use-location-details.hook';
import { useUpdateClientHook } from 'pages/client/hooks/client-update/client-update.hook';
import { useDetailsClientHook } from 'pages/client/hooks/client-details/client-details.hook';
import {
  initialValuesClient,
  PATH_TO_LIST_CLIENT,
} from 'pages/client/client-register/components/form-values/form-values';
import { useCepHook } from 'hooks/cep/cep.hook';
import { showToast } from 'components/toast/toast';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { Loading } from 'src-new/components/loading/loading.component';
import { analystSearchService } from 'pages/client/services/analyst-search/analyst-search.filter-search.service';
import { useGetCompany } from 'src-new/hooks/use-get-company/use-get-company.hook';
import { UserContext } from 'state/user-context';

const ClientUpdateForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = React.useContext(UserContext);
  const updateClient = useUpdateClientHook();
  const getClientDetail = useDetailsClientHook();
  const [client, setClient] = useState<IClientDomain>(initialValuesClient);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedCompany, setLoggedCompany] = useState<string>('');
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const locationDetailsHook = useLocationDetailstHook();
  const cepHook = useCepHook();
  const goBackToListClients = useCallback(() => navigate(PATH_TO_LIST_CLIENT), [navigate]);
  const getCompany = useGetCompany();

  const onBlurCep = useCallback(
    async (cep: string) => {
      return await cepHook(cep);
    },
    [cepHook],
  );

  const getClientById = useCallback(
    async (id: string) => {
      setIsDetailsLoading(true);

      const client = await getClientDetail(id);

      setClient(client);
      setIsDetailsLoading(false);
    },
    [getClientDetail],
  );

  useEffect(() => {
    if (id) {
      getClientById(id);
    }
  }, [getClientById, id]);

  const handleSubmit = useCallback(
    async (client: IClientDomain) => {
      setIsLoading(true);

      updateClient(client)
        .then(() => {
          showToast({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToListClients();
        })
        .catch((err) =>
          showToast({
            message: err.message,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToListClients, updateClient],
  );

  const localityApiCall = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) return await localitiesFilterSearchService(searchValue);
    else return [];
  };

  const localityOptions = (localityIds: number[]) => {
    const new_array: { label: string; value: number }[] = [];

    if (localityIds && localityIds.length > 0) {
      localityIds.map(async (id) => {
        const locality = await locationDetailsHook(String(id));
        new_array.push({ label: locality.name, value: id });
      });
    }

    return new_array;
  };

  const apiCallContractualAnelyst = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return analystSearchService(searchValue);
    }
    return [];
  };

  useEffect(() => {
    (async () => {
      if (user?.companyId) {
        const company = await getCompany(user.companyId);
        setLoggedCompany(company?.tradeName ?? '');
      }
    })();
  }, [getCompany, user?.companyId]);

  if (isLoading || isDetailsLoading) {
    return <Loading />;
  }

  return (
    <ClientUpdateFormView
      initialValues={client}
      isLoading={isLoading}
      loggedCompany={loggedCompany}
      handleUpdate={handleSubmit}
      handleGoback={goBackToListClients}
      localityApiCall={localityApiCall}
      localityOptions={localityOptions}
      onBlurCep={onBlurCep}
      apiCallContractualAnelyst={apiCallContractualAnelyst}
    />
  );
};

export default ClientUpdateForm;
