import React, { ReactNode, useMemo } from 'react';

import FreightTableView from './table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface IFreightTableProps {
  contentTable: Array<IContentTable>;
  pageInfo?: PageInfo;
  changePage: (page: number) => void;
  goToDetailsPage: (freightId: string) => void;
  isLoading: boolean;
  arrowOrder?: string | ReactNode;
  hideSelect?: boolean;
}

export const FinancialSchedulesTable: React.FC<IFreightTableProps> = ({
  contentTable,
  pageInfo,
  changePage,
  goToDetailsPage,
  isLoading,
  arrowOrder,
  hideSelect = false,
}) => {
  const headerTable: IContentTable = useMemo(() => {
    let columns = [
      {
        value: 'Frete ID',
        flex: 0.25,
      },

      {
        value: 'Data de Entrega',
        flex: 0.5,
      },

      {
        value: 'CTe\'s (Primário)',
        flex: 0.8,
      },

      {
        value: 'CTe\'s (Subcontratação)',
        flex: 0.8,
      },

      {
        value: 'Origem',
        flex: 1,
      },
      {
        value: 'Destino',
        flex: 1,
      },
      {
        value: 'Total Serviço',
        flex: 0.6,
      },
      {
        value: '',
        flex: 0.1,
      },
    ];

    if (hideSelect) {
      columns = columns.filter((column) => column.value !== 'Faturar');
    }

    return columns;
  }, [hideSelect]);

  return (
    <FreightTableView
      headerTable={headerTable}
      arrowOrder={arrowOrder}
      contentTable={contentTable}
      pageInfo={pageInfo}
      changePage={changePage}
      isLoading={isLoading}
      clickRowAction={goToDetailsPage}
      isRelative
    />
  );
};
