import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { updateScheduleAcceptOrDenyByIdsService } from '../../services/update-accept-decline-by-ids/update-accept-decline-by-ids.service';

export const UseUpdateAcceptDecline = () => {
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payload: { ids: number[]; statusCode: string }) => {
      try {
        await updateScheduleAcceptOrDenyByIdsService(payload);
        showMessageFormatted({
          message: 'Agendamento atualizado com sucesso',
          type: 'success',
        });

        // INVALIDATE QUERY DATA
        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      } catch (error: any) {
        showMessageFormatted({
          message: 'Erro ao atualizar agendamento',
          type: 'error',
        });
      }
    },
    [reactQueryClient],
  );
};
