import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { SaleOfferHeaderView } from './sale-offer-header-view.component';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { UserContext } from 'state/user-context';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { ISaleOfferListFilterDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/domains/sale-offer-list-filters.domain';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';
import {
  ExportReportProps,
  FilterProps,
  IButtonProps,
  IHeaderProps,
  IInputSearchProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { FilterForm } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/components/sale-offer-page-body/components/sale-offer-header/components/filter-form/filter-form.component';
import { IParamsFilter } from 'src-new/pages/execution/pages/schedule/utils/convert-schedule-filters/convert-schedule-filters.types';
import { convertField } from 'pages-v2/trips/mappers';
import { useFormikContext } from 'formik';
import { saleOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-list-filters-initial-values.constant';
import { useDownloadSaleOfferReport } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/hooks/use-download-sale-offer-report/use-download-sale-offer-report.hook';

export const SaleOfferHeader: FC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { setValues, resetForm } = useFormikContext<ISaleOfferListFilterDomain>();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);
  const { saleOfferList, saleOfferListDownloadReport } = useContext(SaleOfferContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<ISaleOfferListFilterDomain>();
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const downloadSaleOfferReport = useDownloadSaleOfferReport();

  const { modalProps, filters, setFilters } = pageHeaderFilterContext;
  const { isOpen, setOpenModal, setCloseModal } = modalProps;
  const { setInitialLoadingReset } = initialLoading;
  const { selectedIds } = saleOfferList;
  const { statusRequest } = saleOfferListDownloadReport;

  const getBreadcrumbList = useMemo((): Array<string> => {
    if (user?.profile === 'shipping-company') return ['Cadastros', 'Tarifas'];
    return ['Oportunidades', 'Ofertas de Venda'];
  }, [user?.profile]);

  const isLoadingExport = useMemo((): boolean => statusRequest === 'PROCESSING', [statusRequest]);

  const getSubtitlePage = useMemo((): string => {
    if (user?.profile === 'shipping-company')
      return 'Cadastre suas tarifas de frete que deseja vender para outros embarcadores';

    return 'Configure os parâmetros do frete que deseja vender para outros embarcadores.';
  }, [user?.profile]);

  const handleNewSaleOffer = useCallback(() => {
    navigate('/ofertas-venda/novo');
  }, [navigate]);

  const handleOpenDeleteModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false);
  }, []);

  const removeSelectedOfferButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Excluir',
      isVisible: selectedIds.length > 0,
      isLoading: false,
      handleActionButton: handleOpenDeleteModal,
    }),
    [handleOpenDeleteModal, selectedIds],
  );

  const newOfferButtonProps = useMemo(
    (): IButtonProps => ({
      label: 'Nova Oferta',
      isVisible: true,
      isLoading: false,
      handleActionButton: handleNewSaleOffer,
    }),
    [handleNewSaleOffer],
  );

  const onOpenModal = useCallback(() => {
    setOpenModal();
  }, [setOpenModal]);

  const onCloseModal = useCallback(() => {
    setCloseModal();
    setValues(filters);
  }, [filters, setCloseModal, setValues]);

  const convertSaleOfferFilters = useCallback((filter: ISaleOfferListFilterDomain): Array<IParamsFilter> => {
    const entriesBlackList = ['search', 'page', 'limitPage', 'orderBy', 'column', 'isActive'];
    const entries = Object.entries(filter).map(([key, value]) => ({
      key,
      value: convertField(key, value),
    }));

    return entries.filter(({ key, value }) => !entriesBlackList.includes(key) && value);
  }, []);

  const getAppliedFiltersCount = useMemo((): number => {
    return convertSaleOfferFilters(filters).filter((filter) => !Array.isArray(filter.value)).length;
  }, [convertSaleOfferFilters, filters]);

  const clearFilters = useCallback(() => {
    setFilters(saleOfferListFilterInitialValues);
    setCloseModal();
    setInitialLoadingReset();
    resetForm();
  }, [resetForm, setCloseModal, setFilters, setInitialLoadingReset]);

  const filterProps = useMemo(
    (): FilterProps => ({
      handleOpenModal: onOpenModal,
      filterContent: <FilterForm />,
      filterCount: getAppliedFiltersCount,
      isVisibleModal: isOpen,
      onCloseButton: onCloseModal,
      onClearButton: clearFilters,
    }),
    [onOpenModal, getAppliedFiltersCount, isOpen, onCloseModal, clearFilters],
  );

  const handleExportReport = useCallback(() => {
    if (!isLoadingExport) {
      downloadSaleOfferReport();
    }
  }, [downloadSaleOfferReport, isLoadingExport]);

  const exportReportProps = useMemo(
    (): ExportReportProps => ({
      isLoading: isLoadingExport,
      onHandleExport: handleExportReport,
    }),
    [handleExportReport, isLoadingExport],
  );

  const handleSearch = useCallback(
    (search: string) => {
      setFilters({ ...filters, search, page: 1 });
      queryClient.invalidateQueries(['sale-offer-list-table']);
    },
    [queryClient, filters, setFilters],
  );

  const inputProps = useMemo(
    (): IInputSearchProps => ({
      searchPlaceHolder: 'Pesquise pelo ID, Unidade, Parceiro ou Cidade',
      handleSearchInput: handleSearch,
    }),
    [handleSearch],
  );

  const headerProps = useMemo(
    (): IHeaderProps => ({
      breadcrumbList: getBreadcrumbList,
      subtitle: getSubtitlePage,
      inputSearchProps: inputProps,
      primaryButtonProps: newOfferButtonProps,
      secondaryButtonProps: removeSelectedOfferButtonProps,
      exportReportProps,
      filterProps,
    }),
    [
      exportReportProps,
      filterProps,
      getBreadcrumbList,
      getSubtitlePage,
      inputProps,
      newOfferButtonProps,
      removeSelectedOfferButtonProps,
    ],
  );

  return (
    <SaleOfferHeaderView
      headerProps={headerProps}
      deleteModalProps={{ isOpen: openDeleteModal, handleCloseModal: handleCloseDeleteModal }}
    />
  );
};
