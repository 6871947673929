import request, { AxiosResponse } from 'axios';

import API from 'utils/API/API';
import { convertAllScheduleCreate } from 'pages-v2/my-route/mapper/create-all-schedule-map';
import { PlanningRouteShipping, PlanningRouteVehicle } from 'domain-v2/planning-route';
import { ScheduleMyRouteRegisterInput } from 'domain-v2/my-route/register-schedule';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';

interface StatusError {
  statusCode: number;
  message: string;
}

const batchSize = 5;

export const scheduleRegisterAllServiceByRoute = async (
  routeValues: MyRouterRegisterInput,
  allowedVehicle: PlanningRouteVehicle,
  allowedShipping: PlanningRouteShipping,
  scheduleAll: Array<{
    internalCode: string;
    initialDate: string;
    initialTime: string;
    finalDate: string;
    comments: string;
  }>,
): Promise<{ countScheduleCreated: number }> => {
  try {
    const countScheduleCreated = await sendInBatches(
      convertAllScheduleCreate(routeValues, allowedVehicle, allowedShipping, scheduleAll),
    );
    return { countScheduleCreated };
  } catch (error) {
    throw new Error('Houve um erro ao registrar o agendamento');
  }
};

async function sendInBatches(scheduleAll: ScheduleMyRouteRegisterInput[]): Promise<number> {
  const totalItems = scheduleAll.length;

  let countScheduleCreated = 0;

  for (let i = 0; i < totalItems; i += batchSize) {
    const batch = scheduleAll.slice(i, i + batchSize);
    const response = await createBatch(batch);
    if (response && response.data && response.data.countScheduleCreated) {
      countScheduleCreated += response.data.countScheduleCreated;
    }
  }

  return countScheduleCreated;
}

async function createBatch(scheduleBatch: ScheduleMyRouteRegisterInput[]): Promise<AxiosResponse<any>> {
  try {
    return await API.post('v2/schedules/create-by-route', scheduleBatch);
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { data } = error.response as AxiosResponse<StatusError>;
      throw new Error(data && data.message);
    }
    throw new Error('Houve um erro ao registrar o agendamento');
  }
}
