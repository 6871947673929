import API from 'utils/API/API';

export const updateStatusCodeScheduleService = async (scheduleId: number, statusCode: string) => {
  try {
    await API.put(`v2/schedules/${scheduleId}`, { statusCode });
  } catch (error) {
    throw new Error('Houve um erro ao atualizar status code da alocação');
  }
};

export const descriptionCancelService = async (scheduleIds: number[], reasonCancellation: string) => {
  try {
    await API.put('v2/schedules', { scheduleIds, reasonCancellation });
  } catch (error) {
    throw new Error('Houve um erro ao atualizar description cancellation');
  }
};
