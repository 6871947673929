export enum LocationsListActionType {
  LOCATIONS_LIST_REQUEST = 'LOCATIONS_LIST_REQUEST',
  LOCATIONS_LIST_SUCCESS = 'LOCATIONS_LIST_SUCCESS',
  LOCATIONS_LIST_ERROR = 'LOCATIONS_LIST_ERROR',
  LOCATIONS_LIST_SET_FILTERS = 'LOCATIONS_LIST_SET_FILTERS',
  LOCATIONS_LIST_RESET = 'LOCATIONS_LIST_RESET',
}

export enum LocationsActionType {
  LOCATIONS_RESET = 'LOCATIONS_RESET',
}
