import React from 'react';

import TableFreightContextProvider from '../contexts/table-states/table-freight.context';

import TableFreightListBody from './components/table-freight-list-body/table-freight-list-body';

const TableFreightListPage: React.FC = () => {
  return (
    <TableFreightContextProvider>
      <TableFreightListBody />
    </TableFreightContextProvider>
  );
};

export default TableFreightListPage;
