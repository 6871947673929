import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

interface IInputContentProps {
  disabled?: boolean;
  hasError?: boolean;
}
export const InputContent = styled.div<IInputContentProps>`
  ${({ disabled, hasError }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.75rem;
    border: 1px dashed ${hasError ? 'rgb(250, 48, 48)' : '#979797'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const Text = styled.div`
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
`;

export const TextItalic = styled.i`
  font-family: Montserrat, serif;
  font-size: 12px;
  font-weight: 400;
`;
