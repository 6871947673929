import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import { IHoursOperationDomain } from 'src-new/pages/registrations/domains/hours-operation.domain';
import { IAddressRegistrationDomain } from 'src-new/pages/registrations/domains/address-registration.domain';
import { IAttachmentDomain } from './document.domain';
import { ICompanyRegistrationDomain } from 'src-new/pages/registrations/domains/company-registration.domain';
import { VehicleCategoryResponse } from 'src-new/domains/vehicle-category.domain';
import { VehicleTypeResponse } from 'src-new/domains/vehicle-type.domain';
import { ServicesType } from './services-type.domain';
import { IMultiSelectOption } from '../components/multi-select/multi-select.component';

export type PaymentMethod = 'BOLETO' | 'CREDITO EM CONTA';
export type ClosingCycle = 'DIARIO' | 'SEMANAL' | 'QUINZENAL' | 'MENSAL';
export type ClientStatus = 'active' | 'inactive' | 'deleted';
export type ClientType = '' | '01 - FORNECEDOR' | '02 - CLIENTE' | '03 - OUTROS';
export type InsurancePolicyType = 'EMBARCADOR' | 'CLIENTE';

export interface Option<T> {
  label: string;
  value: T;
}

export const optionsPaymentMethod: Array<IMultiSelectOption<PaymentMethod>> = [
  { label: 'Boleto', value: 'BOLETO' },
  { label: 'Crédito em Conta', value: 'CREDITO EM CONTA' },
];

export const optionsClosingCycle: Array<IMultiSelectOption<ClosingCycle>> = [
  { label: 'Diario', value: 'DIARIO' },
  { label: 'Semanal', value: 'SEMANAL' },
  { label: 'Quinzenal', value: 'QUINZENAL' },
  { label: 'Mensal', value: 'MENSAL' },
];

export const optionsPaymentTerm: Array<IMultiSelectOption<number>> = [];
for (let i = 1; i <= 180; i++) {
  optionsPaymentTerm.push({
    label: i.toString(),
    value: i,
  });
}

export const optionsRequiresPreInvoice: Array<IMultiSelectOption<boolean>> = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

export const optionsInsurancePolicy: Array<IMultiSelectOption<InsurancePolicyType>> = [
  { label: 'Embarcador', value: 'EMBARCADOR' },
  { label: 'Cliente', value: 'CLIENTE' },
];

export interface IOperationalRequirementDomain {
  id: number;
  name: string;
}

export interface IClientBilling {
  paymentMethod: PaymentMethod;
  closingCycle: ClosingCycle;
  paymentTerm: number;
  requiresPreInvoice: boolean;
}

export interface ICargoInsurance {
  insurancePolicy: InsurancePolicyType | null;
  gris: string | null;
  advalorem: string | null;
}

export interface IClientDomain {
  id?: number;
  cnpj: string;
  fantasyName: string;
  plantName: string;
  tradeName: string;
  internalCode: string;
  responsibleAnalyst?: string;
  ie: string;
  status: ClientStatus;
  companyId?: number;
  address: IAddressRegistrationDomain;
  vehicleType: Array<VehicleTypeResponse>;
  vehicleCategoryType: Array<VehicleCategoryResponse>;
  localityIds: Array<number>;
  schedulingEmail?: Array<string>;
  occurrenceEmail?: Array<string>;
  operationType: Array<ServicesType>;
  type_of_partner: ClientType;
  quality_and_safety: string;
  lat: string;
  lng: string;
  company?: ICompanyRegistrationDomain;
  inbound: IHoursOperationDomain;
  outbound: IHoursOperationDomain;
  contacts: Array<IContactDomain>;
  operationalRequirements: Array<IOperationalRequirementDomain>;
  attachments: Array<IAttachmentDomain>;
  originalAttachments: Array<IAttachmentDomain>;
  contractualPremises?: ContractualPremises;
  clientBilling?: IClientBilling;
  cargoInsurance?: ICargoInsurance;
}

export interface ContractualPremises {
  createdAt?: string | null;
  contractSignatureDate: string | null;
  contractStartDate: string | null;
  contractEndDate: string | null;
  analyst: string | null;
}
