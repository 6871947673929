import { acceptedMatchesReportExcelService } from 'pages/accepted-matches/service/accepted-matches-report-excel/accepted-matches-report-excel.service';
import { IAcceptedMatchesStatus } from 'domain/accepted-matches';

export interface ILocationReportExcelProps {
  search: string;
  status: IAcceptedMatchesStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: ILocationReportExcelProps): Promise<BlobPart | undefined> => {
    return await acceptedMatchesReportExcelService(params);
  };
};

export { useReportExcelHook };
