import React, { ReactElement, useMemo } from 'react';
import * as S from './weighing-totten-cpf-confirmation.styled';
import { WeighingTottenForm } from './components/weighing-totten-details-form.component';
import { Formik } from 'formik';
import { weighingTottenDetailsFormInitialValues } from '../../types/weighing-totten-details-form/weighing-totten-details-form-initial-values.constants';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';
import FooterTotten from 'src/assets/footer-totten.png';
import LoadingV4 from 'src/assets/loading-v4-unscreen.gif';

interface IWeighingTottenCpfConfirmationViewProps {
  isLoading: boolean;
  handleSubmit: (values: IWeighingTottenDetailsForm) => void;
}

export const WeighingTottenCpfConfirmationView: React.FC<IWeighingTottenCpfConfirmationViewProps> = ({
  isLoading,
  handleSubmit,
}) => {
  const renderForm = useMemo((): ReactElement => {
    if (isLoading) {
      return (
        <S.Wrapper>
          <S.WrapperSearching>
            <S.Loading src={LoadingV4} alt="" />
          </S.WrapperSearching>

          <S.Footer>
            <img src={FooterTotten} alt="" />
          </S.Footer>
        </S.Wrapper>
      );
    }

    return (
      <S.Wrapper>
        <S.Title>Confirme o CPF do Motorista</S.Title>

        <S.Form>
          <Formik initialValues={weighingTottenDetailsFormInitialValues} onSubmit={handleSubmit}>
            <WeighingTottenForm />
          </Formik>
        </S.Form>

        <S.Footer>
          <img src={FooterTotten} alt="" />
        </S.Footer>
      </S.Wrapper>
    );
  }, [handleSubmit, isLoading]);

  return renderForm;
};
