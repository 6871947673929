import { IChecklistDocuments } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-documents';
import API from 'utils/API/API';

export interface ChecklistDetailsListEntity {
  id: number;
  name: string;
  createdAt: Date;
  status: string;
  responsible?: string;
  comment?: string;
}

const mapStatus = (status: string) => {
  if (status == 'Aprovado') return status;
  if (status == 'Aprovado com Restrição') return 'Restrição';
  return 'Reprovado';
};

const mapChelistDocumentsEntityToDomain = (entity: ChecklistDetailsListEntity): IChecklistDocuments => {
  return {
    checklistName: entity.name,
    data: entity.createdAt.toString(),
    id: entity.id,
    fileKey: '',
    owner: entity.responsible ?? '',
    status: mapStatus(entity.status),
  };
};

export const getChecklistDocumentsDataService = async (
  scheduleId: number,
  module: string,
): Promise<Array<IChecklistDocuments>> => {
  const { data } = await API.get<Array<ChecklistDetailsListEntity>>(`v2/checklist/details/${scheduleId}`, {
    params: {
      module,
    },
  });

  return data.map((checklist) => mapChelistDocumentsEntityToDomain(checklist));
};
