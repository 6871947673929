import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { IBidOfferResponse } from '../../get-bid-offers-list.types';
import { IBidOfferDomain } from 'src-new/pages/home/pages/home/domains/home-bids/bid-offer.domain';
import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';

export const convertBidOfferResponseToDomain = (bidOffer: IBidOfferResponse): IBidOfferDomain => {
  const bidOfferOrigin = bidOffer.scheduleClientOrigin.client ?? bidOffer.scheduleClientOrigin.locality;

  const bidOfferDestiny = bidOffer.scheduleClientDestiny.client ?? bidOffer.scheduleClientDestiny.locality;

  return {
    id: bidOffer.id,
    additionalDistance: bidOffer.distance,
    company: {
      id: bidOffer.companyId,
      name: '',
    },
    origin: {
      name: bidOffer.scheduleClientOrigin.client?.tradeName ?? bidOffer.scheduleClientOrigin.locality?.name ?? '',
      city: bidOfferOrigin?.city ?? '',
      uf: bidOfferOrigin?.uf ?? '',
    },
    destination: {
      name: bidOffer.scheduleClientDestiny.client?.tradeName ?? bidOffer.scheduleClientDestiny.locality?.name ?? '',
      city: bidOfferDestiny?.city ?? '',
      uf: bidOfferDestiny?.uf ?? '',
    },
    capacity: {
      inPallet: bidOffer.scheduleCargo.inPalet ? 'SIM' : 'NÃO',
      merchValue: bidOffer.scheduleCargo.shippingValue,
      pallets: bidOffer.scheduleCargo.pallet,
      product: bidOffer.scheduleCargo.invoice === '' ? 'NÃO INFORMADO' : bidOffer.scheduleCargo.invoice,
      weight: bidOffer.scheduleCargo.weight,
    },
    vehicle: {
      type: convertVehicleType(bidOffer.scheduleCargo.vehicleType),
      category: convertVehicleCategory(bidOffer.scheduleCargo.vehicleCategoryType),
    },
    cost: {
      feeLogshare: bidOffer.feeLogshare ?? 0,
      freightValue: bidOffer.value_freight ?? 0,
      tollValue: bidOffer.toll ?? 0,
      totalFreight: bidOffer.value_total_freight ?? 0,
    },
    detail: bidOffer.scheduleDetails,
    expirationTime: bidOffer.expiration_time,
  };
};
