import { useCallback, useReducer } from 'react';
import { scheduleInitialState } from '../../schedule.constants';
import { IScheduleOccurrenceListDomain } from 'src-new/pages/execution/pages/schedule/pages/schedule-list/domains/schedule-occurrence-list/schedule-occurrence-list.domain';
import { IScheduleOccurrenceList } from '../../types/schedule-occurrence.types';
import { scheduleOccurrenceListReducer } from '../../reducers/schedule-occurrence-list/schedule-occurrence-list.reducer';
import { ScheduleOccurrenceListActionType } from '../../schedule.action';

export const useScheduleOccurrenceListContextValues = (): IScheduleOccurrenceList => {
  const [state, dispatch] = useReducer(scheduleOccurrenceListReducer, scheduleInitialState);

  const setScheduleOccurrenceListRequest = useCallback(() => {
    dispatch({ type: ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_REQUEST, payload: undefined });
  }, []);

  const setScheduleOccurrenceListSuccess = useCallback((scheduleOccurrence: Array<IScheduleOccurrenceListDomain>) => {
    dispatch({ type: ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_SUCCESS, payload: scheduleOccurrence });
  }, []);

  const setScheduleOccurrenceListError = useCallback(() => {
    dispatch({ type: ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_ERROR, payload: undefined });
  }, []);

  const setScheduleOccurrenceListReset = useCallback(() => {
    dispatch({ type: ScheduleOccurrenceListActionType.SCHEDULE_OCCURRENCE_LIST_RESET, payload: undefined });
  }, []);

  return {
    ...state.scheduleOccurrenceList,
    setScheduleOccurrenceListRequest,
    setScheduleOccurrenceListSuccess,
    setScheduleOccurrenceListError,
    setScheduleOccurrenceListReset,
  };
};
