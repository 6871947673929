import { useCallback } from 'react';

import {
  getConvertAddressToGeoPosition,
  IHereMapsConvertAddressParams,
  IHereMapsGeoPosition,
} from 'services/maps/convert-address-to-geoposition.service';

const useConvertAddressToGeoPositionHook = () => {
  return useCallback(async (params: IHereMapsConvertAddressParams): Promise<IHereMapsGeoPosition | null> => {
    const distance = await getConvertAddressToGeoPosition(params);
    return distance;
  }, []);
};

export { useConvertAddressToGeoPositionHook };
