import { FC, useContext, useMemo } from 'react';
import { ScheduleDetailsResumeCargoView } from './schedule-details-resume-cargo-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';

export const ScheduleDetailsResumeCargo: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);

  const inPallet = useMemo(() => {
    const { cargo } = scheduleDetails.scheduleDetails;
    if (cargo.inPallet) {
      return `${cargo.pallet} palete(s)`;
    }

    return 'não paletizado';
  }, [scheduleDetails.scheduleDetails]);

  return (
    <ScheduleDetailsResumeCargoView
      inPallet={inPallet}
      cargo={scheduleDetails.scheduleDetails.cargo}
      vehicle={scheduleDetails.scheduleDetails.vehicle}
      driver={scheduleDetails.scheduleDetails.driver}
      distance={scheduleDetails.scheduleDetails.distance}
    />
  );
};
