import React, { ReactElement, useMemo } from 'react';

import { BackhaulTypes } from '../../types/list';

import * as S from './styled';

interface TabsProps {
  contents: Array<BackhaulTypes>;
  isPressed: string;
  icon?: {
    icon: ReactElement;
    index: number;
  };
  setIsPressed: (value: BackhaulTypes) => void;
}

export const ListTabs: React.FC<TabsProps> = ({ contents, isPressed, icon, setIsPressed }) => {
  const renderInfos = useMemo((): Array<ReactElement> => {
    return contents.map((item, index) => {
      return (
        <S.ContainerTabs key={index} onClick={() => setIsPressed(item)} isPressed={isPressed === item}>
          <S.LabelTabs isPressed={isPressed === item}>
            <span>{item.split(' ')[0]}</span> {item.split(' ').slice(1).join(' ')}
            {icon?.index === index && icon.icon}
          </S.LabelTabs>
        </S.ContainerTabs>
      );
    });
  }, [contents, icon?.icon, icon?.index, isPressed, setIsPressed]);

  return renderInfos;
};
