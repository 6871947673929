import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {ITabContent} from 'src-new/components/tab/tab.types';
import {ScheduleOfferModalMatchTableTabsView} from './modal-match-table-tabs-view.component';

interface IScheduleOfferModalMatchTableTabsProps {
  saleOffersMatches: Array<any>
  handleMatchBuy: (backhaul: any) => void
}

export const ScheduleOfferModalMatchTableTabs: React.FC<IScheduleOfferModalMatchTableTabsProps> = ({
                                                                                                     saleOffersMatches,
                                                                                                     handleMatchBuy
                                                                                                   }) => {
  const [activeTab, setActiveTab] = useState<string>('Matches Propostos');

  const handleMatchesTabs = useCallback(
    (name: string) => {
      setActiveTab(name);
    },
    [],
  );

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Matches Propostos',
        content: <Fragment/>,
      },
    ];
  }, []);

  return (
    <ScheduleOfferModalMatchTableTabsView
      tabsContent={tabsContent}
      saleOffersMatches={saleOffersMatches}
      activeTab={activeTab}
      handleMatchBuy={handleMatchBuy}
      handleChangeTab={handleMatchesTabs}
    />
  );
};
