import { IInfiniteScrollListTableContext } from './types/infinite-scroll-list-table.types';

export const infiniteScrollInitialState: IInfiniteScrollListTableContext = {
  initialLoading: {
    loading: true,
    setInitialLoadingSuccess: () => undefined,
    setInitialLoadingReset: () => undefined,
  },
  setInfiniteScrollResetContext: () => undefined,
};
