export const simpleResponseOptions: Array<{ label: string; value: string }> = [
  { label: 'SIM', value: 'true' },
  { label: 'NÃO', value: 'false' },
];

export const optionsVehicleCategory: Array<{
  label: string;
  value: vehicleCategory;
}> = [
  { label: 'BASCULANTE', value: 'cacamba_basculante' },
  { label: 'BAÚ SECO', value: 'bau_seco' },
  { label: 'BAÚ FRIGORIFICADO', value: 'bau_frigorificado' },
  { label: 'BAÚ REFRIGERADO', value: 'bau_refrigerado' },
  { label: 'CEGONHA', value: 'cegonha' },
  { label: 'FLORESTAL', value: 'florestal' },
  { label: 'GRADE BAIXA', value: 'grade_baixa' },
  { label: 'GRANELEIRO', value: 'graneleiro' },
  { label: 'PLATAFORMA', value: 'plataforma' },
  { label: 'PORTA CONTAINER 20 PÉS', value: 'porta_container' },
  { label: 'PORTA CONTAINER 40 PÉS', value: 'porta_container40' },
  { label: 'SIDER', value: 'bau_sider' },
  { label: 'TANQUE', value: 'tanque' },
];

export type vehicleCategory =
  | 'bau_seco'
  | 'bau_frigorificado'
  | 'bau_refrigerado'
  | 'bau_sider'
  | 'cacamba_basculante'
  | 'cegonha'
  | 'florestal'
  | 'grade_baixa'
  | 'graneleiro'
  | 'plataforma'
  | 'porta_container'
  | 'porta2_container'
  | 'tanque'
  | 'porta_container40';
