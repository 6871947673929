import React, { ReactNode, useMemo, useRef } from 'react';
import { Session } from '@talkjs/react';
import { useGetUserSessionChat } from 'src-new/hooks/chat/use-user-session-chat/use-user-session-chat.hook';
import { SessionContainerStyled } from 'src-new/components/chat-session/chat-session.styled';
import { getChatEnvironment } from 'src-new/utils/get-chat-environment/get-chat-environment.util';
import Talk from 'talkjs';

interface IChatSessionProps {
  children: ReactNode;
}

export const ChatSession: React.FC<IChatSessionProps> = ({ children }) => {
  const session = useRef<Talk.Session>();
  const syncUser = useGetUserSessionChat();

  const getAppId = useMemo(() => getChatEnvironment().appId, []);

  return (
    <Session sessionRef={session} appId={getAppId} syncUser={syncUser}>
      <SessionContainerStyled>{children}</SessionContainerStyled>
    </Session>
  );
};
