import { includes } from 'lodash';
import { IWeighingActions, IWeighingContext } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing.types';
import {
  WeighingActionType,
  WeighingDetailsActionType,
  WeighingListActionType,
} from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.action';
import { weighingReducerResetState } from 'src-new/pages/execution/pages/weighing/contexts/weighing/reducers/weighing-reducer.constants';
import { weighingListReducer } from 'src-new/pages/execution/pages/weighing/contexts/weighing/reducers/weighing-list/weighing-list.reducer';
import { IWeighingListActions } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-list.types';
import { weighingDetailsReducer } from 'src-new/pages/execution/pages/weighing/contexts/weighing/reducers/weighing-details/weighing-details.reducer';
import { IWeighingDetailsActions } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-details.types';

export const weighingReducer = (state: IWeighingContext, action: IWeighingActions): IWeighingContext => {
  if (includes(Object.values(WeighingListActionType), action.type)) {
    return weighingListReducer(state, action as IWeighingListActions);
  }

  if (includes(Object.values(WeighingDetailsActionType), action.type)) {
    return weighingDetailsReducer(state, action as IWeighingDetailsActions);
  }

  if (action.type === WeighingActionType.WEIGHING_RESET) {
    return weighingReducerResetState(state);
  }

  return state;
};
