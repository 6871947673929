/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, memo, useState } from 'react';
import { type Moment } from 'moment';
import { useFormikContext } from 'formik';

import { ContainerRowStyled, HireBackhaulBuyMatchFormStyled, LineSectionStyled, SecondTitleStyled } from './styled';

import { normalizeDate } from 'pages-v2/trips/utils/date';
import { BiddingFilter } from 'pages-v2/biddings/services/list-biddings';
import { defaultBiddingFilter } from 'pages-v2/biddings/constants/list';
import { vehicleCategoryOptions, vehicleTypeOptions } from 'domain/global-inputs';
import Select from 'components/select/select';
import InputField from 'components/input-field/input-field';
import { InputCalendar } from 'components/input-calendar/input-calendar';
import Button from 'components/button/button';

interface Date {
  focused: boolean;
  date: Moment | null;
}

const statusOption = [
  {
    label: 'EM LEILÃO',
    value: 'lance',
  },
  {
    label: 'BID RECUSADO',
    value: 'recusado',
  },
  {
    label: 'ARREMATADO',
    value: 'aceito',
  },
  {
    label: 'CANCELADO',
    value: 'cancelado',
  },
];

const BiddingFilterForm: FC = () => {
  const { setFieldValue, values, resetForm, submitForm } = useFormikContext<BiddingFilter>();

  const [initialDateCollect, setInitialDateCollect] = useState<Date>({
    focused: false,
    date: null,
  });

  const [initialDateDeliver, setInitialDateDeliver] = useState<Date>({
    focused: false,
    date: null,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  return (
    <HireBackhaulBuyMatchFormStyled>
      <ContainerRowStyled>
        <SecondTitleStyled>Data Coleta</SecondTitleStyled>
        <LineSectionStyled columns="1fr 1fr">
          <InputCalendar
            placeholder="Data de Envio"
            selectPastDays
            onDateChange={(date: any) => {
              setFieldValue('createdAt', date);
              setInitialDateCollect((prvState) => {
                return { ...prvState, date };
              });
            }}
            onFocusChange={({ focused }) =>
              setInitialDateCollect((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={initialDateCollect.focused}
            date={normalizeDate(initialDateCollect.date)}
            id="createdAt"
          />

          <InputCalendar
            placeholder="Data de Coleta"
            selectPastDays
            onDateChange={(date: any) => {
              setFieldValue('collectDate', date);
              setInitialDateDeliver((prvState) => {
                return { ...prvState, date };
              });
            }}
            onFocusChange={({ focused }) =>
              setInitialDateDeliver((prvState) => {
                return { ...prvState, focused };
              })
            }
            focused={initialDateDeliver.focused}
            date={normalizeDate(initialDateDeliver.date)}
            id="collectDate"
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Cidade de Origem </SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="originCity"
            name="originCity"
            type="text"
            onChange={handleChange}
            value={values.originCity}
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Cidade de Destino</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <InputField
            placeholder="Informe a cidade"
            id="destinationCity"
            name="destinationCity"
            type="text"
            onChange={handleChange}
            value={values.destinationCity}
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Status</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Informe o status"
            id="status"
            name="status"
            value={values.status}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={statusOption}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Tipo de Veículo</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Selecione o Tipo"
            id="vehicle"
            name="vehicle"
            value={values.vehicle}
            setFieldValue={(name, value) => {
              setFieldValue(name, value);
            }}
            options={vehicleTypeOptions}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <ContainerRowStyled>
        <SecondTitleStyled>Categoria do Veículo</SecondTitleStyled>
        <LineSectionStyled columns="1fr">
          <Select
            placeholder="Selecione a Categoria"
            id="category"
            name="category"
            value={values.category}
            setFieldValue={(name, value) => setFieldValue(name, value)}
            options={vehicleCategoryOptions}
            isClearable
          />
        </LineSectionStyled>
      </ContainerRowStyled>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 20,
          gap: 15,
        }}
      >
        <Button title="Filtrar" bgColor="blue" callback={submitForm} size="large" />
        <Button
          title="Limpar"
          bgColor="white"
          callback={() => resetForm({ values: defaultBiddingFilter })}
          size="large"
        />
      </div>
    </HireBackhaulBuyMatchFormStyled>
  );
};

export default memo(BiddingFilterForm);
