import React from 'react';
import { ChatButton } from 'src-new/components/chat-button/chat-button.component';
import { ChatType } from 'src-new/components/chat-button/chat-button.types';
import { Spinner, SpinnerContainerStyled } from './schedule-details-chat-button-view.styled';

interface IScheduleDetailsChatViewProps {
  chatProps: ChatType;
  isLoading: boolean;
}

export const ScheduleDetailsChatButtonView: React.FC<IScheduleDetailsChatViewProps> = ({ chatProps, isLoading }) => {
  if (isLoading) {
    return (
      <SpinnerContainerStyled>
        <Spinner />
      </SpinnerContainerStyled>
    );
  }

  return <ChatButton chatProps={chatProps} />;
};
