import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MenuItem from './components/menu-item/menu-item';
import { ContainerStyled, LogoutIconStyled, MenuStyled, WrapperStyled } from './menu.styled';
import ModalNewFreight from 'components-v2/common/modals/new-freight';
import TooltipComponent from 'components/Tooltip';
import { SidebarContext } from 'components/sidebar/contexts/sidebar.context';
import { showToast } from 'components/toast/toast';
import { initialModalState, ModalState } from 'domain-v2/inputs/modals';
import { useLogoutHook } from 'hooks/auth/logout.hook';
import { UserContext } from 'state/user-context';
import Theme from 'styles/theme';
import { validateMenuPermissionsService } from 'src-new/services/validate-menu-permissions/validate-menu-permissions.service';

const MenuView: React.FC = () => {
  const { menus, isHovered, currentSelectedMenu, handleHover } = React.useContext(SidebarContext);
  const { user, clearUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const logout = useLogoutHook();

  const [modalScheduleCreate, setModalScheduleCreate] = useState<ModalState>(initialModalState);

  const newQueryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await logout();
      handleHover(false);
      clearUser();
      newQueryClient.clear();
      navigate('/login');
    } catch (error) {
      showToast({
        message: 'Erro ao fazer logout',
        type: 'error',
      });
    }
  };

  const mountMenus = React.useMemo(() => {
    return menus.map((menu) => {
      if (validateMenuPermissionsService(menu.routeName, menu.profiles, user))
        return <MenuItem key={menu.title} title={menu.title} icon={menu.icon} />;
    });
  }, [menus, user]);

  const handleCreateSchedulePage = (openModal: boolean) => {
    setModalScheduleCreate({ open: openModal });
  };

  return (
    <>
      {modalScheduleCreate.open && <ModalNewFreight handleCreateSchedulePage={handleCreateSchedulePage} />}

      <WrapperStyled
        isShipping={user?.profile === 'shipping-company'}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => {
          !currentSelectedMenu && handleHover(false);
        }}
        style={{
          width: isHovered ? '212px' : '51px',
        }}
      >
        <MenuStyled>{mountMenus}</MenuStyled>

        <ContainerStyled>
          <LogoutIconStyled onClick={handleLogout}>
            <TooltipComponent title="Sair" location="right" icon={<Theme.icons.LogoutIcon />} />
          </LogoutIconStyled>
        </ContainerStyled>
      </WrapperStyled>
    </>
  );
};

export default MenuView;
