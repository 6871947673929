import React, { useCallback } from 'react';

import { InputFieldPhoneFormikView } from './input-field-phone-formik-view.component';

interface IInputFieldPhoneFormikProps {
  id: string;
  name: string;
  value?: string;
  type: 'text';
  label?: string;
  labelFor?: string;
  placeholder?: string;
  reactNode?: React.ReactNode;
  reactNodePosition?: 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputFieldPhoneFormik: React.FC<IInputFieldPhoneFormikProps> = ({
  id,
  name,
  value = '',
  type,
  label,
  placeholder,
  reactNode,
  reactNodePosition,
  disabled,
  hasError,
  errorMessage,
  onChange = () => null,
  onBlur = () => null,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '');

      if (value.length < 6) value = value.replace(/^(\d{2})(\d)/, '($1) $2');
      if (value.length === 6) value = value.replace(/^(\d{2})(\d{4})/, '($1) $2');
      if (value.length > 6 && value.length <= 10) value = value.replace(/^(\d{2})(\d{4})(\d)/, '($1) $2-$3');
      if (value.length > 10) value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');

      e.target.value = value;

      onChange(e);
    },
    [onChange],
  );

  return (
    <InputFieldPhoneFormikView
      id={id}
      name={name}
      type={type}
      label={label}
      value={value}
      placeholder={placeholder}
      reactNode={reactNode}
      reactNodePosition={reactNodePosition}
      disabled={disabled}
      hasError={hasError}
      errorMessage={errorMessage}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
};
