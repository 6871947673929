import { ColorsV2Type } from 'styles/types/colors';
import { ModalityType } from 'domain-v2/inputs/modality-type';

type HaulType = {
  label: string;
  color: ColorsV2Type;
};

type ModalityTypeMap = {
  // eslint-disable-next-line no-unused-vars
  [key in ModalityType]: HaulType;
};

const defaultValue: HaulType = {
  label: 'TARIFA RECOMENDADA',
  color: ColorsV2Type.DarkGray,
};

export const convertModalityType = (haulTypes: ModalityType): HaulType => {
  const modalityTypeMap: ModalityTypeMap = {
    recommended: {
      label: 'TARIFA RECOMENDADA',
      color: ColorsV2Type.Blue,
    },
    optimized: {
      label: 'TARIFA OTIMIZADA',
      color: ColorsV2Type.Blue,
    },
    advance: {
      label: 'TARIFA ANTECIPADA',
      color: ColorsV2Type.Blue,
    },
    air: {
      label: 'AÉREO',
      color: ColorsV2Type.Blue,
    },
  };

  return modalityTypeMap[haulTypes] || defaultValue;
};
