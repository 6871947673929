import React from 'react';

import FormMyAccount from '../my-account-register-form/form-my-account';

import { MyAccountContainerStyled } from './my-account.styled';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const MyAccountRegisterBodyView: React.FC = () => {
  return (
    <MyAccountContainerStyled>
      <HeaderPage breadcrumbList={['Configurações', 'Minha Conta']} />
      <Title title="Mantenha atualizados seus dados cadastrais." hasTrace />
      <FormMyAccount />
    </MyAccountContainerStyled>
  );
};

export default MyAccountRegisterBodyView;
