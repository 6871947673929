import React from 'react';

import DriverRegisterForm from '../driver-register-form/driver-register-form';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';

const DriverRegisterBodyView: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderPage dataTestId="driver-register-body" breadcrumbList={['Cadastro', 'Motoristas']} hasBackButton />
      <Title title="Novo Motorista" hasTrace isForm />
      <DriverRegisterForm />
    </React.Fragment>
  );
};

export default DriverRegisterBodyView;
