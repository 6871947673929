import { ICompaniesContext, ICompaniesReducerAction } from './companies.types';
import { ICompaniesAction } from './companies.actions';

export const companiesReducer = (state: ICompaniesContext, action: ICompaniesReducerAction): ICompaniesContext => {
  switch (action.type) {
    case ICompaniesAction.COMPANIES_REQUEST: {
      return {
        ...state,
        companiesIsLoading: true,
        companies: [],
        companiesError: null,
      };
    }
    case ICompaniesAction.COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesIsLoading: false,
        companies: action.payload.list,
        companiesPageInfo: action.payload.pageInfo,
        companiesError: null,
      };
    }
    case ICompaniesAction.COMPANIES_ERROR: {
      return {
        ...state,
        companiesIsLoading: false,
        companies: [],
        companiesError: action.payload,
        companiesPageInfo: null,
      };
    }
    default:
      return state;
  }
};
