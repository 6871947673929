/* eslint-disable @typescript-eslint/no-explicit-any */
import AsyncSelect from 'react-select/async';
import React from 'react';
import debounce from 'lodash.debounce';

import { LabelStyled, MessageErrorStyled, WrapperStyled, customStyles } from './multi-async-select-view.styled';
import { IMultiAsyncSelectFieldProps } from './multi-async-select';

const AsyncSelectView: React.FC<IMultiAsyncSelectFieldProps> = ({
  hasError,
  label,
  id,
  errorMessage,
  placeholder = '',
  cacheOptions,
  defaultOptions,
  isClearable,
  onChange,
  name,
  isDisabled,
  apiCallFunction,
  defaultValue,
}) => {
  const loadingSugestiionsItems = (e: string, callback: any): Promise<Array<{ label: string; value: number }>> =>
    new Promise(() => {
      apiCallFunction(e).then((response) => {
        callback(response);
      });
    });

  return (
    <WrapperStyled>
      {label && (
        <LabelStyled htmlFor={id} hasError={hasError}>
          {label}
        </LabelStyled>
      )}
      <AsyncSelect
        isMulti
        isClearable={isClearable}
        styles={customStyles}
        name={name}
        id={id}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        cacheOptions={cacheOptions}
        loadOptions={debounce(loadingSugestiionsItems, 500)}
        defaultOptions={defaultOptions}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={onChange}
      />
      {hasError && <MessageErrorStyled>{errorMessage}</MessageErrorStyled>}
    </WrapperStyled>
  );
};

export default AsyncSelectView;
