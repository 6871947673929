import React, { useMemo } from 'react';
import moment from 'moment';
import { Button, Tag } from 'logshare-ui-kit';

import { IBiddingList } from '../../types/list';

import { Timer } from './timer';

import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { capitalize } from 'utils/string/capitalize';
import { convertOperationType } from 'utils/operation-type';
import { convertFreightType } from 'utils/freight-type';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';
import BigTable from 'components-v2/common/big-list';
import { SendIcon } from 'assets/icons/send-icon';

export const BiddingTable: React.FC<IBiddingList> = ({
  schedule,
  openModal,
  handlePageChange,
  activePage,
  totalPages,
  isLoading,
}) => {
  const headerTable = useMemo(() => {
    return [
      {
        value: 'ID',
        flex: 0.3,
      },
      {
        value: 'Empresa',
        flex: 0.5,
      },
      {
        value: 'Categoria',
        flex: 0.4,
      },
      {
        value: 'Origem/Destino',
        flex: 1.2,
      },
      {
        value: 'Veículo',
        flex: 1,
      },
      {
        value: 'Capacidade',
        flex: 0.8,
      },
      {
        value: 'Data Coleta',
        flex: 0.5,
      },
      {
        value: 'Data Entrega',
        flex: 0.5,
      },

      {
        value: 'Tempo Restante',
        flex: 0.8,
      },
    ];
  }, []);

  const contentTable = useMemo(() => {
    return schedule?.map((item) => {
      const itemExpirationTime: Date = new Date(item.expiration_time);

      const levyTime: string =
        item.scheduleDetails?.levyInitialTime && item.scheduleDetails?.levyEndTime
          ? moment(item.scheduleDetails.levyInitialDate).format('HH:mm') +
            ' ÀS ' +
            moment(item.scheduleDetails.levyEndDate).format('HH:mm')
          : '-';

      const deliveryTime: string =
        item.scheduleDetails?.deliveryInitialTime && item.scheduleDetails?.deliveryEndTime
          ? moment(item.scheduleDetails?.deliveryInitialDate).format('HH:mm') +
            ' ÀS ' +
            moment(item.scheduleDetails.deliveryEndDate).format('HH:mm')
          : '-';

      return [
        {
          value: item.id,
          flex: 0.3,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{item.companyName}</strong>

              {item.companyLogo && (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 25,
                    marginTop: 3,
                  }}
                  src={item.companyLogo}
                  alt={item.companyName}
                />
              )}
            </div>
          ),
          flex: 0.5,
        },
        {
          value: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Tag
                label={convertFreightType(item.typeSchedule).label}
                color={convertFreightType(item.typeSchedule).color}
                variant="solid"
                display="flex"
                size="md"
              />
              <Tag
                label={convertOperationType(item.scheduleCargo.operationType).label}
                color={convertOperationType(item.scheduleCargo.operationType).color}
                variant="ghost"
                display="flex"
                size="md"
              />
            </div>
          ),
          flex: 0.4,
        },
        {
          connection: {
            first: item.scheduleClientOrigin?.locality
              ? `${item.scheduleClientOrigin?.locality?.city} - ${item.scheduleClientOrigin?.locality?.uf}`
              : `${item.scheduleClientOrigin?.client?.city} - ${item.scheduleClientOrigin?.client?.uf}`,
            second: item.scheduleClientDestiny?.locality
              ? `${item.scheduleClientDestiny?.locality.city} - ${item.scheduleClientDestiny?.locality.uf}`
              : `${item.scheduleClientDestiny?.client?.city} - ${item.scheduleClientDestiny?.client?.uf}`,
          },
          flex: 1.2,
        },
        {
          value: capitalize(convertVehicleTypeTypeToName(item.scheduleCargo?.vehicleType)),
          subtitle: capitalize(convertVehicleCategoryTypeToName(item.scheduleCargo.vehicleCategoryType)),
          flex: 1,
        },
        {
          value: offeredWeight(item.scheduleCargo.weight),
          subtitle: offeredPallets(item.scheduleCargo.pallet),
          flex: 0.8,
        },
        {
          value: moment(item.scheduleDetails?.levyEndDate).format('DD/MM/YYYY'),
          subtitle: levyTime,
          flex: 0.5,
        },
        {
          value: moment(item.scheduleDetails?.deliveryEndDate).format('DD/MM/YYYY'),
          subtitle: deliveryTime,
          flex: 0.5,
        },
        {
          value: <Timer key={item.id} deliveryDate={itemExpirationTime} />,
          flex: 0.8,
        },
        {
          button: (
            <Button
              label="Ofertar"
              color="blue"
              size="md"
              onPress={() =>
                openModal({
                  isOpen: true,
                  scheduleId: item.id,
                })
              }
              RightIcon={
                <div style={{ marginLeft: 5 }}>
                  <SendIcon />
                </div>
              }
              variant="solid"
            />
          ),
        },
      ];
    });
  }, [schedule, activePage]);

  return (
    <>
      <BigTable
        header={headerTable}
        content={contentTable || []}
        isLoading={isLoading}
        activePage={activePage}
        totalPages={totalPages}
        setPage={handlePageChange}
      />
    </>
  );
};

export default React.memo(BiddingTable);
