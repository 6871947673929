/* eslint-disable no-useless-catch */

import { finishTrackingOccurrenceService } from '../services';

import {
  ISchedulingListProps,
  schedulingListService,
} from 'pages/freight/services/schedule-list/scheduling-list.service';

export const useFinishTrackingOccurrenceHook = () => {
  return async (id: number) => {
    try {
      await finishTrackingOccurrenceService(id);
    } catch (error) {
      throw error;
    }
  };
};

export const useGetTrackingOccurrencesListHook = () => {
  return async (filter?: Partial<ISchedulingListProps>) => {
    try {
      const response = await schedulingListService({
        search: '',
        page: filter?.page || 1,
        status: 'active',
        statusCode: filter?.statusCode || [],
        column: filter?.column || 'id',
        order: filter?.order || 'desc',
        lastFinishedDays: filter?.lastFinishedDays,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
};
