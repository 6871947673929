import axios from 'axios';

import { showMessageFormatted } from '../message/show-message-formatted/show-message-formatted';

const API_TRAVELS_SVC = axios.create({
  baseURL: import.meta.env.VITE_LINK_API_VIAGENS_SVC,
  timeout: 60000,
});

API_TRAVELS_SVC?.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

API_TRAVELS_SVC?.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('token');
      setTimeout(() => {
        window.location.href = '/login';
      }, 5000);
      showMessageFormatted({
        message: 'Seu token foi expirado, faça login novamente!',
        type: 'error',
      });
    }

    return Promise.reject(error);
  },
);

export default API_TRAVELS_SVC;
