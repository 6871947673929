import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { UserContext } from 'state/user-context';
import { LocationTableView } from './location-table-view.component';
import { useNavigate } from 'react-router-dom';
import { IInfiniteScrollTable, IOrdering } from 'src-new/components/table/table.types';
import { LocationsContext } from 'src-new/pages/registrations/pages/location/contexts/locations/locations.context';
import { InfiniteScrollListTableContext } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

interface ILocationTableProps {
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const LocationTable: React.FC<ILocationTableProps> = ({ handleIdsToRemove, handleInfinityScroll }) => {
  const { isAdmin } = useContext(UserContext);
  const { locationsList } = useContext(LocationsContext);
  const { locations, statusRequest } = locationsList;
  const { filters, setFilters } = locationsList.filterActions;
  const navigate = useNavigate();
  const { initialLoading } = useContext(InfiniteScrollListTableContext);

  const goToDetailsPage = useCallback(
    (locationId: string) => {
      navigate(`/unidades/atualizar/${locationId}`);
    },
    [navigate],
  );

  const handleOrdering = useCallback(
    (columnName: string, order: 'ASC' | 'DESC') => {
      if (columnName.toUpperCase() !== 'STATUS') {
        setFilters({ ...filters, order: order, column: columnName });
      }
    },
    [filters, setFilters],
  );

  const orderTable = useMemo(
    (): IOrdering => ({ order: filters.order, columnName: filters.column, handleClick: handleOrdering }),
    [filters.column, filters.order, handleOrdering],
  );

  const isLazyLoading = useMemo(
    (): boolean => statusRequest === 'PROCESSING' && !initialLoading.loading && !filters.isPageLimit,
    [filters.isPageLimit, initialLoading.loading, statusRequest],
  );

  const infiniteScrollProps = useMemo(
    (): IInfiniteScrollTable => ({ isLoading: isLazyLoading, onInfinityScroll: handleInfinityScroll }),
    [handleInfinityScroll, isLazyLoading],
  );

  return (
    <LocationTableView
      locations={locations}
      handleRowAction={goToDetailsPage}
      isAdmin={isAdmin}
      ordering={orderTable}
      infiniteScrollProps={infiniteScrollProps}
      handleIdsToRemove={handleIdsToRemove}
    />
  );
};
