import { PdfIconNew } from 'assets/icons/pdf-new.icon';
import { PinLocationNewIcon } from 'assets/icons/pin-location-new.icon';
import { ResumeNewIcons } from 'assets/icons/resume-new.icon';
import { WarningNewIcon } from 'assets/icons/warning-new.icon';
import { Button } from 'logshare-ui-kit';
import { ChevronLeft, ChevronRight, Mail } from 'lucide-react';
import { FC, Fragment, useMemo } from 'react';
import { IScheduleDetailsDomain } from '../../domains/schedule-details.domain';
import { ScheduleDetailsResumeFinance } from './components/schedule-details-resume-finance/schedule-details-resume-finance.component';
import { ScheduleDetailsResumeOperation } from './components/schedule-details-resume-operation/schedule-details-resume-operation.component';
import { ScheduleDetailsResumeOriginAndDestination } from './components/schedule-details-resume-origin-and-destination/schedule-details-resume-origin-and-destination.component';
import * as S from './schedule-details-resume-view.styled';
import { ScheduleDetailsResumeRequirements } from './components/schedule-details-resume-requeriments/schedule-details-resume-requeriments.component';
import { ScheduleDetailsResumeStepByStep } from './components/schedule-details-resume-step-step/schedule-details-resume-step-step.component';
import { DropdownButtonTable } from 'src-new/components/dropdown-button-table/dropdown-button-table.component';
import { ScheduleDetailsResumeCargo } from './components/schedule-details-resume-cargo/schedule-details-resume-cargo.component';
import { ScheduleDetailsResumeFreeTime } from './components/schedule-details-resume-freetime/schedule-details-resume-freetime.component';
import TooltipComponent from 'components/Tooltip';

interface IScheduleDetailsResumeViewProps {
  schedule: IScheduleDetailsDomain;
  checkAllocationAccordions: boolean;
  disableReofferButton: boolean;
  isOpen: boolean;
  isAdmin: boolean;
  handleCollectOrderDownload: () => void;
  handleResendCollectOrderEmail: () => void;
  handleOpenOrCloseResume: (isOpen: boolean) => void;
  handleOpenOccurrence: () => void;
  handleOpenTracking: () => void;
}

export const ScheduleDetailsResumeView: FC<IScheduleDetailsResumeViewProps> = ({
  schedule,
  checkAllocationAccordions,
  disableReofferButton,
  isOpen,
  isAdmin,
  handleCollectOrderDownload,
  handleResendCollectOrderEmail,
  handleOpenOrCloseResume,
  handleOpenOccurrence,
  handleOpenTracking,
}) => {
  const renderButtonIcons = useMemo(() => {
    if (!isOpen) {
      return <ChevronLeft />;
    }

    return <ChevronRight />;
  }, [isOpen]);

  const renderResume = useMemo(() => {
    if (!isOpen) {
      return (
        <S.HeaderClose>
          <ResumeNewIcons />
          <TooltipComponent
            title="Gerar Ordem de Coleta"
            location="left"
            icon={
              <Button
                label=""
                color="white"
                variant="ghost"
                onPress={handleCollectOrderDownload}
                LeftIcon={<PdfIconNew />}
                size="sm"
              />
            }
          />
          <TooltipComponent
            title="Reenviar Ordem de Coleta"
            location="left"
            icon={
              <Button
                label=""
                color="white"
                variant="ghost"
                onPress={handleResendCollectOrderEmail}
                LeftIcon={<Mail color="#f3f3f3fa" fill="#7F7F7F" width={19} strokeWidth={2} />}
                size="sm"
                disabled={!checkAllocationAccordions || disableReofferButton}
              />
            }
          />
          <TooltipComponent
            title="Abrir Nova Ocorrência"
            location="left"
            icon={
              <Button
                label=""
                color="white"
                variant="ghost"
                onPress={handleOpenOccurrence}
                LeftIcon={<WarningNewIcon />}
                size="sm"
                disabled={schedule.statusCode === 'IN90' && !isAdmin}
              />
            }
          />
          <TooltipComponent
            title="Rastreio Operacional"
            location="left"
            icon={
              <Button
                label=""
                color="white"
                variant="ghost"
                onPress={handleOpenTracking}
                LeftIcon={<PinLocationNewIcon />}
                size="sm"
              />
            }
          />
        </S.HeaderClose>
      );
    }

    return (
      <Fragment>
        <S.Header>
          <S.HeaderIconTitle>
            <ResumeNewIcons />
            <S.HeaderTitle>Resumo da Viagem</S.HeaderTitle>
          </S.HeaderIconTitle>
          <DropdownButtonTable
            options={[
              { label: 'Gerar Ordem de Coleta', onClick: handleCollectOrderDownload, icon: <PdfIconNew /> },
              {
                label: 'Reenviar Ordem de Coleta',
                onClick: handleResendCollectOrderEmail,
                icon: <PdfIconNew />,
                disabled: !checkAllocationAccordions || disableReofferButton,
              },
              {
                label: 'Abrir Nova Ocorrência',
                onClick: handleOpenOccurrence,
                icon: <WarningNewIcon />,
              },
              {
                label: 'Rastreio Operacional',
                onClick: handleOpenTracking,
                icon: <PinLocationNewIcon />,
              },
            ]}
            iconPosition="vertical"
          />
        </S.Header>

        <S.WrapperInformation>
          <ScheduleDetailsResumeStepByStep />
          <ScheduleDetailsResumeOriginAndDestination />
          <ScheduleDetailsResumeOperation />
          <ScheduleDetailsResumeCargo />
          <ScheduleDetailsResumeRequirements />
          <ScheduleDetailsResumeFreeTime />
          <ScheduleDetailsResumeFinance />
        </S.WrapperInformation>
      </Fragment>
    );
  }, [
    checkAllocationAccordions,
    disableReofferButton,
    handleCollectOrderDownload,
    handleOpenOccurrence,
    handleOpenTracking,
    handleResendCollectOrderEmail,
    isAdmin,
    isOpen,
    schedule.statusCode,
  ]);

  return (
    <S.WrapperScroll isOpen={isOpen}>
      <S.ButtonOpenOrClose onClick={() => handleOpenOrCloseResume(isOpen ? false : true)}>
        {renderButtonIcons}
      </S.ButtonOpenOrClose>
      <S.Wrapper>{renderResume}</S.Wrapper>
    </S.WrapperScroll>
  );
};
