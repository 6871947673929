import API from 'utils/API/API';
import { ICompany } from 'domain/company';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { convertCompanyResponseToSelectItem } from './mappers/convert-company-response-to-select-item/convert-company-response-to-select-item.mapper';
import { IListPage } from 'src-new/domains/list-page.domain';

export const getCompanySearchService = async (companySearchValue: string): Promise<Array<ISelectItemDomain>> => {
  const { data } = await API.get<IListPage<ICompany>>(`v2/companies?search=${companySearchValue}`);

  return data.items.map((company) =>
    convertCompanyResponseToSelectItem(company.tradeName, company.city, Number(company.id)),
  );
};
