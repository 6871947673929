import { featureToggles } from 'features-toggles';
import { FeaturesToggleEnum } from 'src-new/infrastructure/feature-toggle/features-toggles.enum';
import { FeatureToggleEnvironmentType } from './features-toggles.types';

const ENVIRONMENT = import.meta.env.VITE_FIREBASE_TARGET;

const getEnvironment = (): FeatureToggleEnvironmentType => {
  const env = ENVIRONMENT || 'development';

  return env === 'development' ? 'dev' : 'prod';
};

export const isFeatureEnabled = (featureName: FeaturesToggleEnum): boolean => {
  const env = getEnvironment();

  return featureToggles[env][featureName];
};
