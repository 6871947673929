import React, { useRef, useState } from 'react';

import AccordionView, { IRenderRighticon } from './accordion-view';

export type BodyProps = {
  data: Array<{ content: React.ReactNode; label: string }>;
  renderRightIcon?: IRenderRighticon[];
};

const Accordion: React.FC<BodyProps> = ({ data, renderRightIcon }) => {
  const [currentAccordion, setCurrentAccordion] = useState(0);
  const [bodyHeight, setBodyHeight] = useState<number>(0);

  const refs = useRef(Array.from({ length: data.length }, () => React.createRef<HTMLDivElement>()));

  return (
    <AccordionView
      data={data}
      refs={refs}
      currentAccordion={currentAccordion}
      setCurrentAccordion={setCurrentAccordion}
      setBodyHeight={setBodyHeight}
      bodyHeight={bodyHeight}
      renderRightIcon={renderRightIcon}
    />
  );
};

export default Accordion;
