/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';

import Theme from 'styles/theme';

export const WrapperStyled = styled.div`
  min-width: 90px;
`;

export const customStyles = {
  option: (provided: any) => ({
    ...provided,
    color: Theme.colors.darkGray,
    fontSize: Theme.font.sizes.small,
    zIndex: -1,
    borderRadius: 0,
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 0;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
  control: (provided: any) => ({
    ...provided,
    minHeight: 36,
    width: '100%',
    color: '#000',
    fontSize: 12,
    fontWeight: '600',
    backgroundColor: '#fff',
    border: `1px solid ${Theme.colors.gray}`,
    borderRadius: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:hover, &:focus-within, &:-webkit-autofill:hover, &:-webkit-autofill:focus-within': {
      borderColor: `${Theme.colorsV2.blue}`,
      boxShadow: `inset 0 0 0 1px ${Theme.colorsV2.blue} !important`,
    },
  }),
};

export const LabelStyled = styled.label<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    font-size: 11px;
    color: ${hasError ? theme.colors.error : theme.colors.black};
  `}
`;

export const MessageErrorStyled = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: 0.5rem;
    padding-left: 2px;
  `}
`;
