import styled from 'styled-components';

export const Wrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  padding-right: 0.75rem;

  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
`;
