import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { IFormScheduleLTLInitialState, IFormSectionProps } from 'pages/schedule-ltl/types/form-section.types';
import { SectionStyled } from 'pages/schedule-ltl/components/section/section.styled';
import { LineSectionStyled } from 'pages/schedule-ltl/components/line-section/line-section.styled';
import { AnswerValueEnum } from 'domain/global-inputs';
import Select, { SelectPropsOptions } from 'components/select/select';
import InputField from 'components/input-field/input-field';
import { InputCurrency } from 'components/input-currency/input-currency';
import { InputCalendar } from 'components/input-calendar/input-calendar';

const palletOptions: SelectPropsOptions[] = [];

for (let x = 6; x <= 18; x++) {
  palletOptions.push({
    value: x,
    label: `${x} Paletes`,
  });
}

const ScheduleCargoDetailsFormView: React.FC<IFormSectionProps> = ({
  errors,
  values: unTypedValues,
  handleChange,
  touched,
  setFieldValue,
  minDeliveryDate,
  autoSelectedDate,
}) => {
  const values = unTypedValues as IFormScheduleLTLInitialState;
  const [pickUpDate, setPickUpDate] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });
  const [deliveryDate, setDeliveryDate] = useState<{
    focused: boolean;
    date: Moment | null;
  }>({ focused: false, date: null });

  useEffect(() => {
    setFieldValue('cargoDetails.inPalet', AnswerValueEnum.SIM, true);
  }, [setFieldValue]);

  useEffect(() => {
    if (autoSelectedDate) {
      const autoSelectedDeliveryDate = moment(autoSelectedDate, 'DD/MM/YYYY');

      setDeliveryDate((oldDelivery) => ({
        ...oldDelivery,
        date: autoSelectedDeliveryDate,
      }));
    }
  }, [autoSelectedDate]);

  useEffect(() => {
    setFieldValue('cargoDetails.deliveryDate', deliveryDate.date ? deliveryDate.date.format('DD/MM/YY') : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectedDate, deliveryDate]);

  useEffect(() => {
    setFieldValue('cargoDetails.pickUpDate', pickUpDate.date ? pickUpDate.date.format('DD/MM/YY') : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpDate]);

  return (
    <SectionStyled name="Informações do Empresa">
      <LineSectionStyled columns="1fr 1fr 1fr 1fr 1fr">
        <Select
          label="Paletes"
          id="cargoDetails.pallet"
          name="cargoDetails.pallet"
          value={values.cargoDetails.pallet}
          setFieldValue={setFieldValue}
          hasError={!!errors.cargoDetails?.pallet && !!touched.cargoDetails?.pallet}
          errorMessage={String(errors.cargoDetails?.pallet)}
          options={palletOptions}
        />
        <InputCurrency
          label="Peso (Kg) *"
          id="cargoDetails.weight"
          name="cargoDetails.weight"
          type="text"
          allowDecimals={false}
          decimalScale={0}
          decimalsLimit={0}
          decimalSeparator=","
          groupSeparator="."
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={values.cargoDetails.weight}
          suffix=" Kg"
          hasError={!!errors.cargoDetails?.weight && !!touched.cargoDetails?.weight}
          errorMessage={String(errors.cargoDetails?.weight)}
        />
        <InputCurrency
          label="Valor da Mercadoria *"
          id="cargoDetails.shippingValue"
          name="cargoDetails.shippingValue"
          type="text"
          prefix={'R$ '}
          defaultValue={0}
          decimalsLimit={2}
          allowDecimals={true}
          decimalSeparator=","
          groupSeparator="."
          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
          onValueChange={(value, name) => setFieldValue(name ?? '', value)}
          value={values.cargoDetails.shippingValue}
          hasError={!!errors.cargoDetails?.shippingValue && !!touched.cargoDetails?.shippingValue}
          errorMessage={String(errors.cargoDetails?.shippingValue)}
        />
        <InputCalendar
          label="Data Coleta *"
          placeholder=""
          onDateChange={(date) => {
            setPickUpDate((prvState) => {
              return { ...prvState, date };
            });

            if (deliveryDate.date) {
              setDeliveryDate((prvState) => {
                return { ...prvState, date: null };
              });
            }
          }}
          onFocusChange={({ focused }) =>
            setPickUpDate((prvState) => {
              return { ...prvState, focused };
            })
          }
          focused={pickUpDate.focused}
          date={pickUpDate.date}
          id="cargoDetails.pickUpDate"
          hasError={!!errors.cargoDetails?.pickUpDate && !!touched.cargoDetails?.pickUpDate}
          dateHighlight={{
            days: { value: 2, relation: 'before' },
          }}
          errorMessage={String(errors.cargoDetails?.pickUpDate)}
        />
        <InputCalendar
          label="Data Entrega *"
          placeholder=""
          onDateChange={(date) =>
            setDeliveryDate((prvState) => {
              return { ...prvState, date };
            })
          }
          onFocusChange={({ focused }) =>
            setDeliveryDate((prvState) => {
              return { ...prvState, focused };
            })
          }
          focused={deliveryDate.focused}
          date={deliveryDate.date}
          id="cargoDetails.deliveryDate"
          hasError={!!errors.cargoDetails?.deliveryDate && !!touched.cargoDetails?.deliveryDate}
          errorMessage={String(errors.cargoDetails?.deliveryDate)}
          addDateBlocked={{
            days: { value: minDeliveryDate || 0, relation: 'before' },
          }}
          disabled={!minDeliveryDate}
        />
      </LineSectionStyled>
      <LineSectionStyled columns="6fr 3fr 3fr">
        <InputField
          label="Produto Predominante *"
          id="cargoDetails.invoice"
          name="cargoDetails.invoice"
          type="text"
          onChange={handleChange}
          value={values.cargoDetails.invoice}
          hasError={!!errors.cargoDetails?.invoice && !!touched.cargoDetails?.invoice}
          errorMessage={String(errors.cargoDetails?.invoice)}
        />
        <InputField
          label="Nº Embarque"
          id="cargoDetails.shipmentNumber"
          name="cargoDetails.shipmentNumber"
          type="text"
          onChange={handleChange}
          value={values.cargoDetails.shipmentNumber}
          hasError={!!errors.cargoDetails?.shipmentNumber && !!touched.cargoDetails?.shipmentNumber}
          errorMessage={String(errors.cargoDetails?.shipmentNumber)}
        />

        <InputField
          label="Senha"
          id="cargoDetails.password"
          name="cargoDetails.password"
          type="text"
          onChange={handleChange}
          value={values.cargoDetails.password}
          hasError={!!errors.cargoDetails?.password && !!touched.cargoDetails?.password}
          errorMessage={String(errors.cargoDetails?.password)}
        />
      </LineSectionStyled>
      <LineSectionStyled columns="1">
        <InputField
          label="Pedidos"
          id="cargoDetails.orderNumber"
          name="cargoDetails.orderNumber"
          type="text"
          onChange={handleChange}
          value={values.cargoDetails.orderNumber}
          hasError={!!errors.cargoDetails?.orderNumber && !!touched.cargoDetails?.orderNumber}
          errorMessage={String(errors.cargoDetails?.orderNumber)}
        />
      </LineSectionStyled>
    </SectionStyled>
  );
};

export default ScheduleCargoDetailsFormView;
