import API from 'utils/API/API';
import { convertVehicleToSelectItem } from '../../mappers/convert-shipping-entity-to-select-item/convert-shipping-entity-to-select-item.mapper';

export interface IResponse {
  label: string;
  value: number;
  category?: string;
  type?: string;
}

export interface IVehicleService {
  id: number;
  main_board: string;
  second_board: string;
  company_id: number;
  vehicle_category: string;
  vehicle_type: string;
}

export const getVehicleService = async (searchValue: string): Promise<IResponse[]> => {
  const { data } = await API.get<Array<IVehicleService>>(`v2/vehicles/search?name=${searchValue}`);
  const res = data.map((vehicle) => convertVehicleToSelectItem(vehicle));
  return res;
};
