import { FC, ReactNode } from 'react';

import { FilterView } from './filter-view.component';

interface IFilterProps {
  children: ReactNode;
  appliedFilters: number;
  handleClose: () => void;
  handleResetFilter: () => void;
}

export const Filter: FC<IFilterProps> = ({ children, appliedFilters, handleClose, handleResetFilter }) => {
  return (
    <FilterView
      appliedFilters={appliedFilters}
      handleClose={handleClose}
      handleResetFilter={handleResetFilter}
    >
      {children}
    </FilterView>
  );
};
