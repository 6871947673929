import styled, { css } from 'styled-components';

interface PropsStyle {
  display?: 'flex' | 'grid';
  justify?: 'center' | 'space-between' | 'flex-end';
  direction?: 'row' | 'column';
  columns?: string;
  isTraced?: boolean;
  width?: number;
  gap?: number;
  border?: boolean;
  padding?: boolean;
  upperCase?: boolean;
}

export const Wrapper = styled.div<PropsStyle>`
  ${({ theme, justify, display, width, gap, border = true, padding = true }) => css`
    display: ${display};
    justify-content: ${justify ?? ''};
    border: ${border ? '1px solid theme.colors.forBorder' : ''};
    background-color: #fff;
    width: ${width}%;
    gap: ${gap}px;
    border-radius: 5px;

    ${padding &&
    css`
      padding: 15px;
    `}

    ${border &&
    css`
      border: 1px solid ${theme.colors.forBorder};
    `}
  `}
`;

export const HeaderTitle = styled.div<PropsStyle>`
  ${({ theme, display, justify, isTraced = false }) => css`
    display: ${display};
    justify-content: ${justify ?? ''};
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.black};
    padding-bottom: 5px;
    ${isTraced &&
    css`
      border-bottom: 1px solid ${theme.colors.forBorder};
      padding-bottom: 15px;
      margin-bottom: 15px;
    `}
  `}
`;

export const HeaderTitleToInvoice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }
`;

const invoiceBadgeColor = {
  'A Faturar': '#7d7d7d',
  Faturado: '#00B050',
  Default: '#F5A623',
} as const;

export const HeaderTitleInvoiceBadge = styled.div<{
  type?: keyof typeof invoiceBadgeColor;
}>`
  background-color: ${({ type }) => invoiceBadgeColor[type || 'Default']};
  padding: 7px 15px;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

export const SectionContainer = styled.div<PropsStyle>`
  ${({ columns, display, justify, direction, width }) => css`
    display: ${display};
    grid-template-columns: ${columns};
    justify-content: ${justify ?? ''};
    flex-direction: ${direction};
    grid-gap: 15px;
    width: ${width}%;
    align-items: center;
    font-size: 12px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const InfoTitle = styled.span`
  flex-direction: column;
  padding-bottom: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerInfoStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Info = styled.span<PropsStyle>`
  ${({ upperCase = true }) => css`
    display: flex;
    font-weight: 700;
    padding: 5px 0;
    text-transform: ${upperCase ? 'uppercase' : ''};
  `}
`;

export const AllocationIcon = styled.span`
  cursor: pointer;
`;

export const Header = styled.span`
  justify-content: end;
  display: flex;
  font-weight: 500;
  padding-top: 10px;
`;
