import { ThemeColorsV2Type } from 'styles/types/colors';
import { HaulType, HaulTypeMap, HaulTypeOperationMap } from './convert-haul-type.types';
import { OperationsDomain } from 'src-new/domains/operations.domain';

export const defaultValue: HaulType = {
  label: 'TRACKING',
  color: ThemeColorsV2Type.DarkGray,
};

export const convertHaulType = (haulTypes: OperationsDomain): HaulType => {
  const haulTypeMap: HaulTypeMap = {
    TRACKING: {
      label: 'TRACKING',
      color: ThemeColorsV2Type.Aqua,
    },
    SPOT: {
      label: 'SPOT',
      color: ThemeColorsV2Type.Blue,
    },
    'BACKHAUL EXTERNO': {
      label: 'BACKHAUL EXTERNO',
      color: ThemeColorsV2Type.Blue,
    },
    'BACKHAUL INTERNO': {
      label: 'BACKHAUL INTERNO',
      color: ThemeColorsV2Type.Aqua,
    },
    'BACKHAUL PARCEIRO': {
      label: 'BACKHAUL PARCEIRO',
      color: ThemeColorsV2Type.Aqua,
    },
    'BACKHAUL EXTERNO SPOT': {
      label: 'BACKHAUL EXTERNO',
      color: ThemeColorsV2Type.Blue,
    },
  };

  return haulTypeMap[haulTypes] || defaultValue;
};

export const convertHaulTypeOperation = (haulTypes: 'EXTERNO' | 'INTERNO' | 'PARCEIRO'): OperationsDomain => {
  const haulTypeMap: HaulTypeOperationMap = {
    EXTERNO: 'BACKHAUL EXTERNO',
    INTERNO: 'BACKHAUL INTERNO',
    PARCEIRO: 'BACKHAUL PARCEIRO',
  };

  return haulTypeMap[haulTypes] ?? 'TRACKING';
};
