import { FooterPage } from 'components-v2/common/footer-page';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import InputField from 'components/input-field/input-field';
import { FormikErrors } from 'formik';
import { ChangeEvent, FC } from 'react';
import SectionFormDivider from 'src-new/components/section-form-divider/section-form-divider';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import * as S from './weighing-duplicated-form-mount-view.styled';
import { IWeighingDuplicatedDomain } from '../../../../domains/weighing-duplicated.domain';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import dayjs from 'dayjs';
import { InputCurrency } from 'components/input-currency/input-currency';
import Select from 'components/select/select';
import { weighingOperationOptions, weighingTicketStatusOptions } from 'domain/global-inputs';
import { WeighingDuplicatedFormMountLocations } from './components/weighing-duplicated-form-mount-locations/weighing-duplicated-form-mount-locations.component';
import { WeighingDuplicatedFormMountDrivers } from './components/weighing-duplicated-form-mount-drivers/weighing-duplicated-form-mount-drivers.component';
import { WeighingDuplicatedFormMountVehicles } from './components/weighing-duplicated-form-mount-vehicles/weighing-duplicated-form-mount-vehicles.component';
import { WeighingDuplicatedFormMountShipping } from './components/weighing-duplicated-form-mount-shipping/weighing-duplicated-form-mount-shipping.component';
import { WeighingDuplicatedFormMountItems } from './components/weighing-duplicated-form-mount-items/weighing-duplicated-form-mount-items.component';

interface IWeighingDuplicatedFormMountViewProps {
  values: IWeighingDuplicatedDomain;
  isMaster?: boolean;
  hasError: boolean;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDuplicatedDomain>>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  handleGoBackPage: () => void;
  handleCollectOrderDownload: () => void;
}

export const WeighingDuplicatedFormMountView: FC<IWeighingDuplicatedFormMountViewProps> = ({
  values,
  isMaster,
  hasError,
  setFieldValue,
  handleChange,
  handleOnSubmit,
  handleGoBackPage,
  handleCollectOrderDownload,
}) => {
  return (
    <S.WrapperForm>
      <SectionForm>
        <LineSectionLayout columns={'1fr 1fr 1fr 1fr 1fr 1.7fr 1fr'} alignItems='end'>
          <InputField label="ID" id="id" name="id" type="text" onChange={handleChange} value={''} disabled />
          <InputField
            label="Número do Documento"
            id="documentNumber"
            name="documentNumber"
            type="text"
            onChange={handleChange}
            value={values.documentNumber}
            disabled={!isMaster}
          />
          <InputField
            label="Ordem de Coleta"
            id="collectionOrder"
            name="collectionOrder"
            type="number"
            onChange={handleChange}
            value={values.collectionOrder}
            disabled={!isMaster}
          />
          <Select
            label="Operação"
            id={'operation'}
            name={'operation'}
            value={values.operation}
            setFieldValue={setFieldValue}
            options={weighingOperationOptions}
            disabled={!isMaster}
          />
          <InputField
            label="Número do Ticket"
            id="ticketId"
            name="ticketId"
            type="number"
            onChange={handleChange}
            value={values.ticketId}
            disabled
          />
          <Select
            label="Status"
            id={'ticketStatus'}
            name={'ticketStatus'}
            value={values.ticketStatus}
            setFieldValue={setFieldValue}
            options={weighingTicketStatusOptions}
            disabled={!isMaster}
          />

          <S.DateTimePikers>
            <DateTimePikers
              label="Data da Programação *"
              format="DD/MM/YYYY HH:mm"
              value={dayjs(values.scheduledDateTime)}
              onChange={(date) => setFieldValue('scheduledDateTime', date)}
              errorMessage="Campo Obrigatório"
              disabled={!isMaster}
            />
          </S.DateTimePikers>
        </LineSectionLayout>

        <SectionFormDivider label={'Pesagem'} />
        <LineSectionLayout columns={'0.7fr 0.5fr 1fr 0.5fr 0.5fr'}>
          <DateTimePikers
            label="Data de Entrada"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.entryDate)}
            onChange={(date) => setFieldValue('entryDate', date)}
            disabled={!isMaster}
          />
          <S.WrapperInput>
            <InputCurrency
              label="Peso (Kg)"
              id="entryWeight"
              name="entryWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('entryWeight', value)}
              value={values.entryWeight}
              suffix=" Kg"
              disabled={!isMaster}
            />
          </S.WrapperInput>
          <DateTimePikers
            label="Data de Saída"
            format="DD/MM/YYYY HH:mm"
            value={dayjs(values.exitDate)}
            onChange={(date) => setFieldValue('exitDate', date)}
            disabled={!isMaster}
          />
          <S.WrapperInput>
            <InputCurrency
              label="Peso (Kg)"
              id="exitWeight"
              name="exitWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('exitWeight', value)}
              value={values.exitWeight}
              suffix=" Kg"
              disabled={!isMaster}
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <InputCurrency
              label="Peso NF (Kg)"
              id="nfWeight"
              name="nfWeight"
              type="text"
              allowDecimals
              decimalScale={0}
              decimalsLimit={0}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setFieldValue('nfWeight', value)}
              value={values.nfWeight}
              suffix=" Kg"
              disabled={!isMaster}
            />
          </S.WrapperInput>
        </LineSectionLayout>

        <LineSectionLayout columns={'1.25fr 0.5fr 0.5fr 0.5fr 0.5fr'}>
          <S.WrapperInput>
            <WeighingDuplicatedFormMountItems />
          </S.WrapperInput>

          <InputField
            label="SMP Validação"
            id="smpValidation"
            name="smpValidation"
            type="text"
            onChange={handleChange}
            value={values.smpValidation}
            disabled={!isMaster}
          />

          <InputField
            label="PO"
            id="po"
            name="po"
            type="text"
            onChange={handleChange}
            value={values.po}
            disabled={!isMaster}
          />

          <InputField
            label="Capacidade Palete"
            id="palletCapacity"
            name="palletCapacity"
            type="number"
            onChange={handleChange}
            value={values.palletCapacity}
            disabled={!isMaster}
          />

          <InputCurrency
            label="Capacidade Tonelada"
            id="tonCapacity"
            name="tonCapacity"
            type="text"
            allowDecimals
            decimalScale={0}
            decimalsLimit={0}
            decimalSeparator=","
            groupSeparator="."
            onValueChange={(value) => setFieldValue('tonCapacity', value)}
            value={values.tonCapacity}
            suffix=" Ton"
            disabled={!isMaster}
          />
        </LineSectionLayout>

        <SectionFormDivider label={'Origem'} hidePinLocation={false} />
        <WeighingDuplicatedFormMountLocations type={'origin'} hasError={hasError} values={values} setFieldValue={setFieldValue} />

        <SectionFormDivider label={'Destino'} hidePinLocation={false} />
        <WeighingDuplicatedFormMountLocations
          type={'destination'}
          hasError={hasError}
          values={values}
          setFieldValue={setFieldValue}
        />

        <LineSectionLayout columns={'1fr 1fr'}>
          <div>
            <SectionFormDivider label={'Transportadora'} />
            <WeighingDuplicatedFormMountShipping values={values} setFieldValue={setFieldValue} hasError={false} />
          </div>
          <div>
            <SectionFormDivider label={'Motorista'} />
            <WeighingDuplicatedFormMountDrivers values={values} setFieldValue={setFieldValue} />
          </div>
        </LineSectionLayout>

        <LineSectionLayout columns={'1fr'}>
          <div>
            <SectionFormDivider label={'Veículo'} />
            <WeighingDuplicatedFormMountVehicles values={values} setFieldValue={setFieldValue} />
          </div>
        </LineSectionLayout>
        <LineSectionLayout columns={'1fr'}>
          <div>
            <SectionFormDivider label={'Observação'} />
            <InputField
              label=""
              id="observation"
              name="observation"
              type="text"
              onChange={handleChange}
              value={values.observation}
              disabled={!isMaster}
              upperCase={false}
            />
          </div>
        </LineSectionLayout>
      </SectionForm>

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button
            label="Salvar"
            color="pink"
            isLoading={false}
            onPress={handleOnSubmit}
            disabled={!isMaster}
          />
          <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={handleGoBackPage} />
          <FooterPage.Button
            label="Imprimir Ticket"
            color="gray"
            variant="ghost"
            onPress={handleCollectOrderDownload}
          />
        </FooterPage.RightContent>
      </FooterPage.Root>
    </S.WrapperForm>
  );
};
