import React from 'react';
import * as S from './card-view.styled';
import { Button } from 'logshare-ui-kit';
import { ICardKeyType } from './card.types';
import { IPurchaseAndSaleCard } from '../../../../domains/home-cards/home-cards.domain';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { convertNumberFormat } from 'src-new/utils/convert-number-format.util';

interface ICardViewProps {
  cardKeyType: ICardKeyType;
  cardDetails: IPurchaseAndSaleCard;
  handleNavigateToList: () => void;
}

export const CardView: React.FC<ICardViewProps> = ({ cardKeyType, cardDetails, handleNavigateToList }) => {
  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.Div>
          <S.Strong fontSize={30}>{convertNumberFormat.format(cardDetails.totalMatches)}</S.Strong>
          <S.Strong> matches</S.Strong>
        </S.Div>

        <S.Div>
          <S.Strong>encontrados para </S.Strong>
          <S.Strong color={cardKeyType.color}>{cardKeyType.label}</S.Strong>
        </S.Div>
      </S.HeaderContainer>

      <S.FooterContainer>
        <Button
          label="Ver Oportunidades"
          color={cardKeyType.color}
          size="md"
          variant="solid"
          onPress={handleNavigateToList}
        />

        <S.TextContainer>
          <S.Text>
            total de
            <S.Strong fontSize={11}> {convertNumberFormat.format(cardDetails.totalRoutes)} rotas </S.Strong>
            cadastradas
          </S.Text>
          <S.Text>
            com
            <S.Strong fontSize={11}> {cardKeyType.valuePotentialType} potencial </S.Strong>
            de
            <S.Strong fontSize={11}>{` ${convertCurrencyFormat.format(cardDetails.potencialValue)}`}</S.Strong>
          </S.Text>
        </S.TextContainer>
      </S.FooterContainer>
    </S.Wrapper>
  );
};
