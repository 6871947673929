import { FC, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleFinanceResumeView } from './schedule-finance-resume-view.component';

interface IScheduleFinanceResumeProps {
  typeTransaction: 'freithgtPayable' | 'freithgtReceivable' | 'freithgtShipping';
}

export const ScheduleFinanceResume: FC<IScheduleFinanceResumeProps> = ({ typeTransaction }) => {
  const { scheduleDetails } = useContext(ScheduleContext);

  return <ScheduleFinanceResumeView schedule={scheduleDetails.scheduleDetails} typeTransaction={typeTransaction} />;
};
