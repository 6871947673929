import React, { useCallback, useMemo } from 'react';
import { ICell, IOrdering } from '../../table.types';
import * as S from './header.styled';
import ArrowOrderUpIcon from 'assets/icons/arrow-order-up.icon';
import ArrowOrderDownIcon from 'assets/icons/arrow-order-down.icon';

const dataTestId = 'header-table';

interface IHeaderProps {
  header: Array<ICell>;
  gridColumns: string;
  ordering?: IOrdering;
  columnFontBold?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ header, ordering, gridColumns, columnFontBold }) => {
  const renderOrdering = useCallback(
    (value: React.ReactNode) => {
      if (typeof value === 'string' && value.length > 0 && ordering?.columnName === value) {
        return (
          <S.TableOrdering>
            {ordering?.order === 'DESC' ? <ArrowOrderDownIcon /> : <ArrowOrderUpIcon />}
          </S.TableOrdering>
        );
      }
    },
    [ordering?.columnName, ordering?.order],
  );

  const headerLine = useMemo(
    () =>
      header.map((value, index) => {
        const order = ordering?.order === 'ASC' ? 'DESC' : 'ASC';
        const columnName = typeof value.value === 'string' ? value.value : '';

        return (
          <S.HeaderTable
            key={`${dataTestId}-${index}`}
            cursorPointer={!!ordering}
            onClick={() => ordering?.handleClick(columnName, order)}
          >
            <S.ThStyled columnFontBold={columnFontBold}>{value.value}</S.ThStyled>

            {renderOrdering(value.value)}
          </S.HeaderTable>
        );
      }),
    [columnFontBold, header, ordering, renderOrdering],
  );

  return (
    <S.THeadStyled>
      <S.TRowStyled columns={gridColumns}>{headerLine}</S.TRowStyled>
    </S.THeadStyled>
  );
};
