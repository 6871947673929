import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IWeighingTottensDetailsResponse } from './get-weighing-totten-details.types';
import { IListPage } from 'src-new/domains/list-page.domain';
import { IWeighingTottenDetailsForm } from '../../types/weighing-totten-details-form/weighing-totten-details-form.types';

export const getWeighingTottenDetailsService = async (
  params: IWeighingTottenDetailsForm,
): Promise<Array<IWeighingTottensDetailsResponse>> => {
  const { data } = await API_TRAVELS_SVC.get<IListPage<IWeighingTottensDetailsResponse>>(
    `v1/weighing?collectOrder=${params.collectOrder}&page=${1}&limit=${1}`,
  );

  return data.items;
};
