import { useCallback, useContext } from 'react';
import { IUser } from 'domain/login';
import { getCompanyChatDetailsByCompanyIdService } from 'src-new/services/chat/get-company-chat-details-by-company-id/get-company-chat-details-by-company-id.service';
import { ChatButtonContext } from 'src-new/contexts/chat-button/chat-button.context';
import { IUserChatDetailsDomain } from 'src-new/domains/company-chat-details.domain';

export const useUserChatDetails = () => {
  const { setChatRequest, setChatSuccess, setChatError } = useContext(ChatButtonContext);

  return useCallback(
    async (user: IUser): Promise<void> => {
      setChatRequest();

      try {
        const userChatDetails: IUserChatDetailsDomain = await getCompanyChatDetailsByCompanyIdService(user.companyId);

        setChatSuccess({ photoUrl: userChatDetails.photoUrl ?? 'NOT_FOUND', companyName: userChatDetails.companyName });
      } catch (error) {
        setChatError();
      }
    },
    [setChatError, setChatRequest, setChatSuccess],
  );
};
