import React from 'react';

import PaginationView from './pagination-view';

interface IPaginationProps {
  activePage: number;
  totalPage: number;
  changePage: (page: number) => void;
}

const Pagination: React.FC<IPaginationProps> = ({ activePage, totalPage, changePage }: IPaginationProps) => {
  return <PaginationView activePage={+activePage} totalPage={totalPage} changePage={changePage} />;
};

export default Pagination;
