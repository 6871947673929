import React, { Fragment, ReactElement } from 'react';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import { PalletForm } from 'src-new/pages/financial/components/pallet-form/pallet-form.component';

export const EquipmentVoucherRegisterPageBodyView: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <ApplicationPageHeader
        headerProps={{
          breadcrumbList: ['Financeiro', 'Vale Equipamentos'],
          title: 'Novo Vale Equipamento',
          hasBackButton: true,
        }}
      />
      <PalletForm />
    </Fragment>
  );
};
