import { IChatButtonContext } from 'src-new/contexts/chat-button/types/chat-button.types';

export const chatButtonInitialState: IChatButtonContext = {
  statusRequest: 'INITIAL',
  userDetails: {
    photoUrl: '',
    companyName: '',
  },
  chatActions: {
    chatId: '',
    name: '',
    flowConversation: undefined,
    saleOfferCompanyId: undefined,
    buyOfferCompanyId: undefined,
    isOpen: false,
    setChatOpen: () => undefined,
    setChatClose: () => undefined,
  },
  setChatRequest: () => undefined,
  setChatSuccess: () => undefined,
  setChatError: () => undefined,
  setChatReset: () => undefined,
};
