import API from 'utils/API/API';
import { IMatchesResponse, IMatchesListParams } from 'domain/matches';

const LIMIT_PAGE = 20;

export const MatchesListService = async (
  page: number,
  hireBackhaulId: string,
  params: IMatchesListParams,
): Promise<IMatchesResponse> => {
  const { data } = await API.get<IMatchesResponse>(`/v2/idleness-match-proposal/${hireBackhaulId}`, {
    params: {
      ...params,
      page: page,
      limit: LIMIT_PAGE,
    },
  });
  return data;
};

export const TransportRouterMatchesListService = async (
  page: number,
  transportRouteId: number,
  params: IMatchesListParams,
): Promise<IMatchesResponse> => {
  const { data } = await API.get<IMatchesResponse>(`/v2/idleness-match-proposal/transport-route/${transportRouteId}`, {
    params: {
      ...params,
      page: page,
      limit: LIMIT_PAGE,
    },
  });
  return data;
};
