/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

import { RenderFileStyled, RenderFileHeaders, RenderFileInfo } from './upload-file-view.style';

import { downloadFromUrl } from 'utils/download-file/download-file';
import { FileListUploaded } from 'pages/freight/types';
import Button from 'components/button/button';

interface Props {
  index: number;
  file: FileListUploaded;
  register?: boolean;
  onSuccess?: () => void;
  handleModal: (modalOpen: boolean) => void;
}

const UploadFileView: FC<Props> = ({ index, file, register, handleModal }) => {
  const chaveAcesso = useMemo(() => {
    return `${index}. ${file.name}`;
  }, [index, file]);

  const renderActions = useMemo(() => {
    return (
      <>
        {!register && (
          <Button
            title=""
            callback={() => downloadFromUrl(file.url)}
            bgColor="white"
            icon={<SearchIcon />}
            size="very-small"
          />
        )}
        <Button
          title="Deletar"
          callback={() => handleModal(true)}
          bgColor="pink"
          icon={<DeleteIcon />}
          size="very-small"
        />
      </>
    );
  }, [file, register]);

  return (
    <>
      <RenderFileStyled>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <RenderFileHeaders>Arquivo</RenderFileHeaders>
            <RenderFileInfo>{chaveAcesso}</RenderFileInfo>
          </div>
        </div>
        <div>{renderActions}</div>
      </RenderFileStyled>
    </>
  );
};

export default UploadFileView;
