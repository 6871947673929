/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import InfoIcon from '@mui/icons-material/Info';

import * as S from './styled';

import { convertCurrencyFormat } from 'utils-v2/converters/currency';
import { getIcmsService } from 'utils/get-icms/service/get-icms.service';
import { formatCurrency } from 'utils/format-numbers';
import { companyDetailsService } from 'services/company/details';
import { showToast } from 'components/toast/toast';
import { InputCurrency } from 'components/input-currency/input-currency';

interface ICardProposedValueProps {
  proposedValue: number;
  shippingHomologate?: boolean;
  isIdlenessBidding?: boolean;
  companyId?: number;
  merchValue?: number;
  originUf?: string;
  destinyUf?: string;
  handleProposedValue: (proposalValue?: string, value?: string) => void;

  isTransportRouter?: boolean;
  spotValue: {
    freightValue: number;
    logshareValue?: number;
  };
}

export const CardProposedValueIdleness: React.FC<ICardProposedValueProps> = ({
  proposedValue,
  shippingHomologate,
  isIdlenessBidding,
  companyId,
  merchValue,
  originUf,
  destinyUf,
  handleProposedValue,
  isTransportRouter,
  spotValue,
}) => {
  const [grisAdv, setGrisAdv] = useState<number>(0);
  const [company, setCompany] = useState<string>('');
  const [icms, setIcms] = useState<number>(0);

  useEffect(() => {
    getGrisAdvaloremValue();
  }, [companyId]);

  useEffect(() => {
    getIcmsValue();
  }, [originUf, destinyUf]);

  const getGrisAdvaloremValue = useCallback(async () => {
    if (!companyId) return;

    try {
      const company = await companyDetailsService(companyId);

      const grisAdvalorem = ((Number(company.advalorem) + Number(company.gris)) * (merchValue || 0)) / 100;

      setCompany(company.type);
      setGrisAdv(grisAdvalorem);
    } catch (err: any) {
      showToast({
        message: err,
        type: 'error',
      });
    }
  }, [companyId]);

  const getIcmsValue = useCallback(async () => {
    if (!originUf || !destinyUf) return;

    try {
      const response = await getIcmsService({
        ufOrigin: originUf,
        ufDestiny: destinyUf,
      });

      setIcms(response);
    } catch (err: any) {
      showToast({
        message: err,
        type: 'error',
      });
    }
  }, [originUf, destinyUf]);

  const logshareFee = useMemo(() => {
    if (shippingHomologate) return 62;
    const proposedValueFormatted = String(proposedValue).replace(',', '.');

    const valueFee = Number(proposedValueFormatted) * 0.1;

    if (!isNaN(valueFee)) return valueFee;

    return 0;
  }, [shippingHomologate, proposedValue]);

  const totalFreight = useMemo(() => {
    const proposedValueFormatted = String(proposedValue).replace(',', '.');
    const logshareFeeFormatted = String(logshareFee).replace(',', '.');

    let icmsValue = 0;

    const total =
      (Number(proposedValueFormatted) || Number(logshareFeeFormatted)) - Number(logshareFeeFormatted) + grisAdv;

    if (company !== 'shipper') {
      icmsValue = total / (1 - icms / 100) - total;

      const totalService = total + icmsValue;

      return { total, icmsValue, totalService };
    }

    const totalService = total;

    return { total, totalService, icmsValue };
  }, [company, logshareFee, grisAdv, proposedValue, icms, shippingHomologate, isIdlenessBidding]);

  useEffect(() => {
    if (spotValue.freightValue > 0 && isTransportRouter) {
      handleProposedValue(String(spotValue.freightValue.toFixed(2)), String(totalFreight.totalService));
    }
  }, [spotValue, isTransportRouter]);

  return (
    <S.WrapperCardProposed isTransportRouter={isTransportRouter}>
      <S.WrapperValueProposed>
        {isTransportRouter && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div
              style={{
                display: 'flex',
                gap: 5,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <PaidIcon
                sx={{
                  height: '17px',
                  width: '17px',
                  color: 'gray',
                }}
              />
              <p>Esta rota tem sido negociada</p>
            </div>

            <p>
              por um valor médio de <strong>{convertCurrencyFormat.format(spotValue?.logshareValue ?? 0)}</strong>
            </p>
          </div>
        )}
      </S.WrapperValueProposed>

      <S.Wrapper>
        <S.Content>
          <S.CustomWrapper>
            <S.CustomWrapper style={{ display: 'flex', whiteSpace: 'break-spaces' }}>
              <S.TitleText>{'Informe o '}</S.TitleText>
              <S.TitleText isBold>{'Frete Proposto'}</S.TitleText>
            </S.CustomWrapper>
            <InputCurrency
              id="proposalValue"
              name="proposalValue"
              prefix={'R$ '}
              defaultValue={0}
              decimalsLimit={2}
              decimalSeparator=","
              step={1}
              groupSeparator="."
              intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
              onValueChange={(value) => handleProposedValue(value, String(totalFreight.totalService))}
              value={proposedValue}
            />
            <S.Info>Sem ICMS e pedágio</S.Info>
          </S.CustomWrapper>

          <S.Container>
            <S.Label>Frete Valor</S.Label>
            <S.Label>{formatCurrency(proposedValue ?? 0)}</S.Label>
          </S.Container>

          <S.Container>
            <S.TooltipContent>
              <S.Label>Taxa LogShare</S.Label>
              <Tooltip
                title={
                  <span style={{ fontSize: '11px' }}>
                    PARA TRANSPORTADORAS NÃO HOMOLOGADAS, COBRAMOS UM PERCENTUAL DE 10% EM CIMA DO VALOR PROPOSTO. JÁ
                    PARA HOMOLOGADAS UMA TAXA FIXA DE R$62,00.
                  </span>
                }
                placement="right"
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon
                    sx={{
                      height: '11px',
                      width: '11px',
                      color: 'white',
                    }}
                  />
                </div>
              </Tooltip>
            </S.TooltipContent>
            <S.Label>{formatCurrency(logshareFee)}</S.Label>
          </S.Container>

          <S.Container>
            <S.Label>Pedágio</S.Label>
            <S.Label>R$ 0,00</S.Label>
          </S.Container>

          <S.Container>
            <S.Label>Gris/Advalorem</S.Label>
            <S.Label>{formatCurrency(grisAdv)}</S.Label>
          </S.Container>

          <S.Container>
            <S.Title>Frete Total</S.Title>
            <S.Title>{formatCurrency(totalFreight.total)}</S.Title>
          </S.Container>

          <S.Container>
            <S.Label>ICMS</S.Label>
            <S.Label>{formatCurrency(totalFreight.icmsValue)}</S.Label>
          </S.Container>

          <S.Container>
            <S.TooltipContent>
              <S.Title>Total a Receber</S.Title>
              <Tooltip
                title={<span style={{ fontSize: '11px' }}>O CTE SERÁ EMITIDO COM ESSE VALOR.</span>}
                placement="right"
              >
                <div style={{ display: 'flex' }}>
                  <InfoIcon sx={{ height: '11px', width: '11px', color: 'white' }} />
                </div>
              </Tooltip>
            </S.TooltipContent>
            <S.Title>{formatCurrency(totalFreight.totalService)}</S.Title>
          </S.Container>
        </S.Content>
      </S.Wrapper>
    </S.WrapperCardProposed>
  );
};
