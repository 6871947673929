import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

export const CheckIcon: React.FC<Props> = ({ color, size }) => {
  return (
    <svg
      width={size ? size : 23}
      height={size ? size : 23}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 20"
      x="0px"
      y="0px"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="m5.056 15.04c.629.622 1.658.567 2.217-.119l10.17-12.476c.523-.642.427-1.587-.215-2.11-.642-.523-1.587-.427-2.11.215l-9.111 11.208-3.453-3.41c-.589-.583-1.539-.577-2.121.012-.583.589-.577 1.539.012 2.121l4.611 4.56"
          fill={color ? color : '#5D5D5D'}
        />
      </g>
      <text
        x="0"
        y="31"
        fill="#000000"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        Created by James Kocsis
      </text>
      <text
        x="0"
        y="36"
        fill="#000000"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        from the Noun Project
      </text>
    </svg>
  );
};
