import { useFormikContext } from 'formik';
import { FC, useContext } from 'react';
import { WeighingFormMountItemsView } from './weighing-update-form-mount-items-view.component';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { UserContext } from 'state/user-context';

export const WeighingFormMountItems: FC = () => {
  const { values, setFieldValue } = useFormikContext<IWeighingDomain>();
  const { user } = useContext(UserContext);

  return <WeighingFormMountItemsView setFieldValue={setFieldValue} values={values} isMaster={!!user?.isMaster}/>;
};
