import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';

import { InfoStyled, ReactNodeStyled } from 'components/Tooltip/styled';

interface IProps {
  title: ReactNode;
  info?: string;
  icon?: ReactNode;
  location:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}
const TooltipComponent: React.FC<IProps> = ({ title, info, icon, location }) => {
  return (
    <Tooltip title={<div style={{ fontSize: '11px' }}>{title}</div>} placement={location}>
      <div>
        <InfoStyled>{info}</InfoStyled>
        <ReactNodeStyled>{icon}</ReactNodeStyled>
      </div>
    </Tooltip>
  );
};
export default TooltipComponent;
