import styled, { css } from 'styled-components';

interface IStyledProps {
  active: boolean;
}

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: transparent;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #12264e;
    width: 50px;
  }
`;

export const ButtonBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerButtons = styled.div`
  background-color: white;
  border-radius: 5px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimelineContainer = styled.div`
  display: flex;
  margin-top: 10px;
  width: 812.8px;
  align-items: center;
`;

export const TimelineLine = styled.div<IStyledProps>`
  width: 100%;
  height: 1px;
  background-color: ${(props) => (props.active ? '#12264e' : '#ccc')};
`;

export const TimelineDot = styled.div<IStyledProps>`
  width: 60px;
  height: 15px;
  border-radius: 25%;
  background-color: ${(props) => (props.active ? '#12264e' : '#fff')};
  border: 2px solid ${(props) => (props.active ? '#12264e' : '#ccc')};
`;

export const LabelTitle = styled.div`
  height: min-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
  padding-bottom: 10px;
`;

interface ITimeLineContainerProps {
  backgroundGrayColor: boolean;
}
export const TimeLineContainer = styled.div<ITimeLineContainerProps>`
  ${({ backgroundGrayColor }) => css`
    background-color: ${backgroundGrayColor ? '#EFEFEF' : 'white'};
    padding: 0 10px;
  `}
`;

export const LabelContent = styled.div`
  height: min-content;
  font-size: 0.73rem;
  font-style: normal;
  line-height: normal;
  color: #171717;
  padding-left: 15px;
  text-transform: uppercase;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LabelTime = styled.div`
  height: min-content;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #000;
`;

export const DateContainer = styled.div<IStyledProps>`
  width: 130px;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat, serif;
  color: ${(props) => (props.active ? '#1f2b4f' : '#000')};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 7px;
`;

export const TimeLineWrapper = styled.div``;

export const TimeLineHeader = styled.div`
  padding: 0 10px;
`;

export const TimeLineContent = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

export const TimeLineStatusCotent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface ITimeLineContentDot {
  active: boolean;
}
export const TimeLineContentDot = styled.div<ITimeLineContentDot>`
  ${({ active }) => css`
    height: 19px;
    width: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    background-color: ${active ? 'green' : '#ABABAB'};
    z-index: 1;
  `}
`;

interface ITimeLineContentLine {
  active: boolean;
  hide: boolean;
}
export const TimeLineContentLine = styled.div<ITimeLineContentLine>`
  ${({ hide }) => css`
    height: ${hide ? 0 : '23px'};
    width: 1px;
    position: relative;
    right: 10px;
    top: 15px;
    background-color: #e2e2e2;
  `}
`;

export const Div = styled.div``
