import styled, { css } from 'styled-components';

import { ThemeColorsV2Type } from 'styles/types/colors';

interface IProps {
  isPressed: boolean;
  color?: ThemeColorsV2Type;
}

export const ToggleContainer = styled.div`
  ${({ theme }) => css`
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.colorsV2.forBorder};
    border-radius: 6px;
  `}
`;

const ToogleButton = styled.button<IProps>`
  ${({ theme, isPressed, color = ThemeColorsV2Type.Blue }) => css`
    height: 40px;
    width: 40px;
    display: flex;
    padding: 8px;
    background-color: ${isPressed ? theme.colorsV2[color] : 'transparent'};
    cursor: ${isPressed ? 'default' : 'pointer'};
    border: none;

    &:focus {
      outline: none;
    }

    svg {
      fill: ${isPressed ? theme.colorsV2.white : theme.colorsV2.darkGray};
    }
  `}
`;

export const LeftButton = styled(ToogleButton)`
  border-radius: 6px 0 0 6px;
`;

export const RightButton = styled(ToogleButton)`
  border-radius: 0 6px 6px 0;
`;
