import React, { createContext, useMemo, useReducer } from 'react';
import { financialInitialState } from './financial.constants';
import { IFinancialContext } from './types/financial.types';
import { IFinancialContextProps } from './financial.types';
import { financialReducer } from './reducers/financial.reducer';
import { IPalletForm } from './types/pallet-form.types';
import { usePalletFormContext } from './context-values/pallet-form/pallet-form.context';

export const FinancialContext = createContext<IFinancialContext>(financialInitialState);

export const FinancialContextProvider = ({ children }: IFinancialContextProps) => {
  const [state] = useReducer(financialReducer, financialInitialState);
  const palletForm: IPalletForm = usePalletFormContext();

  const value: IFinancialContext = useMemo(
    (): IFinancialContext => ({
      ...state,
      palletForm,
    }),
    [palletForm, state],
  );

  return <FinancialContext.Provider value={value}>{children}</FinancialContext.Provider>;
};
