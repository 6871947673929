import React from 'react';

import CompanyInformationFileSectionView from './company-information-file-upload-view';

interface IProps {
  id: string;
}

const CompanyInformationFileSection: React.FC<IProps> = ({ id }) => {
  return <CompanyInformationFileSectionView id={id} />;
};

export default React.memo(CompanyInformationFileSection);
