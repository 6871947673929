import { ScheduleListInitialLoadingActionType } from '../../schedule.action';
import { IScheduleContext } from '../../types/schedule.types';
import { IScheduleListInitialLoadingActions } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list-initial-loading.types';

export const scheduleListInitialLoadingReducer = (
  state: IScheduleContext,
  action: IScheduleListInitialLoadingActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleListInitialLoadingActionType.SCHEDULE_LIST_INITIAL_LOADING_SUCCESS:
      return {
        ...state,
        scheduleListInitialLoading: {
          ...state.scheduleListInitialLoading,
          loading: false,
        },
      };
    case ScheduleListInitialLoadingActionType.SCHEDULE_LIST_INITIAL_LOADING_RESET:
      return {
        ...state,
        scheduleListInitialLoading: {
          ...state.scheduleListInitialLoading,
          loading: true,
        },
      };
    default:
      return state;
  }
};
