import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import SecurityIcon from '@mui/icons-material/Security';

import * as S from './styled';
import SpotBiddingPage from './schedule-list/bidding.page';
import IdlenessBiddingPage from './idleness-list/idleness-list.page';

import { downloadTemplate } from 'utils/download-file/download-file';
import { BigTabs } from 'components-v2/common/big-tabs';
import BiddingsButton from 'components-v2/biddings/button';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { UserContext } from 'state/user-context';

const BindingsPage: React.FC = () => {
  const { user } = useContext(UserContext);

  const [activeTab, setActiveTab] = useState<string>('Cargas Ofertadas');

  const isAccessRestricted = useMemo(() => {
    if (activeTab === 'Rotas para Bidding') {
      return <IdlenessBiddingPage />;
    }
  }, [activeTab]);

  const isAccessSpot = useMemo(() => {
    if (activeTab === 'Cargas Ofertadas') {
      return <SpotBiddingPage />;
    }
  }, [activeTab]);

  const renderLogin = useMemo((): string => {
    if (!user) {
      return 'Transportadora';
    }

    const login = user.login.split('@');

    if (login[0].toUpperCase() === 'LOGSHARE') {
      return login[1];
    }

    return login[0];
  }, [user]);

  const handleDownloadRequirements = useCallback(async () => {
    await downloadTemplate('https://backhaul-public-images.s3.sa-east-1.amazonaws.com/Regras_de_GR.pdf');
  }, []);

  return (
    <>
      <HeaderPage />
      <S.WrapperHeader>
        <S.WrapperTextAndButton>
          <S.WrapperText>
            <S.Title>Olá, {renderLogin}!</S.Title>
            <S.SubTitle>
              Encontre oferta de cargas de embarcadores homologadas para operação imediata ou bidding.
            </S.SubTitle>
          </S.WrapperText>
        </S.WrapperTextAndButton>
      </S.WrapperHeader>

      <S.WrapperList>
        <S.TabsAndButtonGroup>
          <BigTabs
            contents={['Cargas Ofertadas', 'Rotas para Bidding']}
            isPressed={activeTab}
            setIsPressed={setActiveTab}
          />

          <S.ButtonContainer>
            <BiddingsButton label="PGR" color="white" icon={<SecurityIcon />} onPress={handleDownloadRequirements} />
          </S.ButtonContainer>
        </S.TabsAndButtonGroup>
        {isAccessSpot}
        {isAccessRestricted}
      </S.WrapperList>
    </>
  );
};

export default memo(BindingsPage);
