/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ModalTermsSchema } from './schemas-validation/schemas-validation';
import * as S from './modal-terms.styled';
import ModalTermsView from './modal-terms-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ILastTerms, IModalTerms } from 'pages/login/services/entities/login.entity';
import { useTermsConditionsHook, useTermsConditionsRegisterHook } from 'pages/login/hooks/terms-conditions.hook';
import { UserContext } from 'state/user-context';
import { useNavigate } from 'react-router-dom';

type ModalTermsProps = {
  login: string;
  token: string;
  onClose: () => void;
};

export const initialValues: IModalTerms = {
  userName: '',
  userCpf: '',
  termId: 0,
  login: '',
};

const closeButton: React.CSSProperties = {
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #d9d9d9',
  alignSelf: 'flex-end',
};

const ModalTerms: React.FC<ModalTermsProps> = ({ login, token, onClose }) => {
  const { setUser } = useContext(UserContext);

  const [lastTerms, setLastTerms] = useState<ILastTerms>({
    content: '',
    id: 0,
    title: '',
  });

  const navigate = useNavigate();
  const termsConditions = useTermsConditionsHook();
  const termsConditionsRegister = useTermsConditionsRegisterHook();

  const handleTermsConditions = useCallback(async () => {
    try {
      const data = await termsConditions();
      setLastTerms(data);
    } catch (error: any) {
      showMessageFormatted({
        message: error?.message,
        type: 'error',
      });
    }
  }, []);

  const handleSubmit = useCallback(
    async (values: IModalTerms) => {
      try {
        await termsConditionsRegister({
          userName: values.userName,
          userCpf: values.userCpf,
          termId: lastTerms.id,
          login: login,
        });
        sessionStorage.setItem('token', token);
        setUser();
        navigate('/home', { state: { from: 'login' } });
      } catch (error: any) {
        showMessageFormatted({
          error: error?.message,
          type: 'error',
        });
      }
    },
    [lastTerms.id, login, navigate, setUser, termsConditionsRegister, token],
  );

  useEffect(() => {
    handleTermsConditions();
  }, []);

  return (
    <S.Container>
      <S.Content>
        <div style={closeButton}>
          <S.CloseModal onClick={onClose}>X</S.CloseModal>
        </div>
        <S.ContainerHeader>
          <S.ContainerTitles>
            <S.Title>Termos de uso LogShare</S.Title>

            <S.SecondTitle>Para utilizar a plataforma você precisa concordar com os termos de uso.</S.SecondTitle>
          </S.ContainerTitles>
        </S.ContainerHeader>
        <ModalTermsView
          handleRegister={handleSubmit}
          modalTermsValues={initialValues}
          schemaValidation={ModalTermsSchema}
          lastTerms={lastTerms}
        />
      </S.Content>
    </S.Container>
  );
};

export default ModalTerms;
