import { IAllocationShippingDetails } from 'pages/allocation/contexts/allocation-register/allocation.types';

export const initialValuesHours: IAllocationShippingDetails = {
  id: 0,
  boardingNumber: '',
  deliveryEndDate: '',
  deliveryEndTime: '',
  deliveryInitialDate: '',
  deliveryInitialTime: '',
  internalNumber: '',
  levyEndDate: '',
  levyEndTime: '',
  levyInitialDate: '',
  levyInitialTime: '',
  ocInternalNumber: '',
  schedulingPassword: '',
  acceptDate: '',
  startDate: '',
  checkinDate: '',
  checkoutDate: '',
  loadDate: '',
  finishDate: '',
};
