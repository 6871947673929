export const getDownloadFileService = async (processingCode: number): Promise<Blob> => {
  const token = sessionStorage.getItem('token');

  const url = `${
    import.meta.env.VITE_LINK_API_VIAGENS_SVC
  }v1/route-planning/files/${processingCode}/validation/download/`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  return response.blob();
};
