import { IListPage, PageMeta } from '../../../../domain/page-info';

import API from 'utils/API/API';
import { ICurrentAllocation } from 'pages/allocation/contexts/allocation-register/allocation.types';

export interface ISchedulingListProps {
  scheduleCode?: string;
  driverId?: string;
  vehicleId?: string;
  search: string;
  page: number;
  status?: string;
  statusCode: string[];
  order: string;
  column?: string;
  statusCte?: string;
  company?: string;
  levyInitialDate?: string;
  levyEndDate?: string;
  deliveryInitialDate?: string;
  deliveryEndDate?: string;
  freightValue?: string;
  vehicleCategory?: string;
  vehicleType?: string;
  originCity?: string;
  destinationCity?: string;
  scheduleType?: string;
  scheduleOperationType?: string;
  lastFinishedDays?: number;
  shippingCompanyId?: string;
  orderNumber?: string;
  hasLimit?: boolean;
  operationBy?: number;
  typeOperation?: string;
}

export interface ISchedulingListEntity {
  items: Array<ICurrentAllocation>;
  meta: PageMeta;
}

export const schedulingListService = async (params: ISchedulingListProps): Promise<IListPage<ICurrentAllocation>> => {
  const LIMIT_PAGE = params.hasLimit ? 0 : 50;
  const { data } = await API.get<ISchedulingListEntity>('v2/schedules', {
    params: {
      ...params,
      limit: LIMIT_PAGE,
    },
  });

  return {
    list: data.items,
    pageInfo: {
      totalItems: data.meta.totalItems,
      itemCount: data.meta.itemCount,
      itemsPerPage: data.meta.itemsPerPage,
      totalPages: data.meta.totalPages,
      currentPage: data.meta.currentPage,
    },
  };
};
