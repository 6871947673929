import { ICheckStub, ICheckStubStatus, checkStubsInitialValues } from 'domain-v2/check-stub';
import { ILocation } from 'domain/location';
import { IClient } from 'domain/client';

export interface IPallet {
  id?: number;
  equipmentVoucher: string;
  operationType?: PalletOperationType;
  collectDate?: string;
  clientId?: number;
  originCity?: string;
  distributionCenterId?: number;
  scheduleId?: number;
  palletType: PalletTypes;
  palletQuantity: number;
  equipmentVoucherDate: string;
  expirationDate?: string;
  withdrawalDate: string;
  entryDate: string;
  liberationDate?: string;
  checkStub?: ICheckStub;
  note?: string;
  status?: IPalletStatus;
  protocol?: number;
  client?: IClient;
  distributionCenter?: ILocation;
}

export type PalletOperationType = '' | 'coleta' | 'retrabalho';

export type PalletTypes = '' | 'pbr' | 'chep' | 'plastico_descartavel';

export type IPalletStatus = 'em_aberto' | 'liberado';

export const palletOptionsOperationType: Array<{
  label: string;
  value: PalletOperationType;
}> = [
  { label: 'COLETA', value: 'coleta' },
  { label: 'RETRABALHO', value: 'retrabalho' },
];

export const optionsPalletType: Array<{
  label: string;
  value: PalletTypes;
}> = [
  { label: 'PBR', value: 'pbr' },
  { label: 'CHEP', value: 'chep' },
  { label: 'PLÁSTICO DESCARTÁVEL', value: 'plastico_descartavel' },
];

export const optionsCheckStubStatus: Array<{
  label: string;
  value: ICheckStubStatus;
}> = [
  { label: 'NÃO INFORMADO', value: 'nao_informado' },
  { label: 'ENTREGUE ANALISTA', value: 'entregue_analista' },
  { label: 'CLIENTE', value: 'cliente' },
];

export const palletInitialValues: IPallet = {
  equipmentVoucher: '',
  palletType: '',
  palletQuantity: 0,
  equipmentVoucherDate: '',
  withdrawalDate: '',
  entryDate: '',
  checkStub: {
    status: 'nao_informado',
    receiptDate: '',
    deliveryDate: '',
    withdrawalDate: '',
    entryDate: '',
    checkStubs: checkStubsInitialValues,
  },
};
