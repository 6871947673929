import styled from 'styled-components';

export const ContainerCardsStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  overflow: hidden;
`;

export const ContainerLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -50px;
`;
