import { convertVehicleCategory } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleType } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { IPurchaseOfferMatchListResponse } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/services/get-purchase-offer-match-list/get-purchase-offer-match-list.types';
import { IPurchaseOfferMatchListDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-match-list.domain';

export const convertPurchaseOfferMatchListResponseToDomain = (
  purchaseOfferMatchResponse: IPurchaseOfferMatchListResponse,
): IPurchaseOfferMatchListDomain => {
  return {
    ...purchaseOfferMatchResponse,
    matchOfferId: purchaseOfferMatchResponse.id,
    origin: {
      ...purchaseOfferMatchResponse.origin,
      address: {
        city: purchaseOfferMatchResponse.origin.city,
        uf: purchaseOfferMatchResponse.origin.uf,
      },
    },
    destination: {
      ...purchaseOfferMatchResponse.destination,
      address: {
        city: purchaseOfferMatchResponse.destination.city,
        uf: purchaseOfferMatchResponse.destination.uf,
      },
    },
    vehicle: {
      type: convertVehicleType(purchaseOfferMatchResponse.vehicle.type),
      category: convertVehicleCategory(purchaseOfferMatchResponse.vehicle.category),
    },
  };
};
