export enum TransactionsListActionType {
  TRANSACTIONS_LIST_REQUEST = 'TRANSACTIONS_LIST_REQUEST',
  TRANSACTIONS_LIST_SUCCESS = 'TRANSACTIONS_LIST_SUCCESS',
  TRANSACTIONS_LIST_ERROR = 'TRANSACTIONS_LIST_ERROR',
  TRANSACTIONS_LIST_SET_FILTERS = 'TRANSACTIONS_LIST_SET_FILTERS',
  TRANSACTIONS_LIST_RESET = 'TRANSACTIONS_LIST_RESET',
}

export enum TransactionsActionType {
  TRANSACTIONS_RESET = 'TRANSACTIONS_RESET',
}
