import NotificationsIcon from '@mui/icons-material/Notifications';
import { BsBellFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { FC, useMemo } from 'react';

interface Props {
  id: number;
  count: number;
  onClick: () => void;
}

export const Bell: FC<Props> = ({ id, count, onClick }) => {
  const styledBellActive = useMemo(() => {
    return {
      color: '#12264e',
      size: '2em',
    };
  }, []);

  const styledBellDisabled = useMemo(() => {
    return {
      color: 'grey',
      size: '1.8em',
    };
  }, []);

  if (count > 0)
    return (
      <div key={id} style={{ position: 'relative' }} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
        <IconContext.Provider value={styledBellActive}>
          <NotificationsIcon
            style={{
              width: 20,
              height: 20,
              color: '#12264e'
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              color: 'white',
              borderRadius: '100%',
              width: 19,
              height: 19,
              top: 10,
              left: 12,
              fontSize: '9px',
              backgroundColor: '#E04747',
            }}
          >
            {count > 99 ? '99+' : count}
          </div>
        </IconContext.Provider>
      </div>
    );

  return (
    <IconContext.Provider value={styledBellDisabled}>
      <BsBellFill
        style={{
          width: 17,
          height: 17,
          color: 'gray'
        }}
      />
    </IconContext.Provider>
  );
};
