import { IListPage } from 'src-new/domains/list-page.domain';
import API from 'utils/API/API';
import { IScheduleDetailsAllocationDriverDomain } from '../../domains/schedule-details-allocation-driver.domain';
import {
  IScheduleDetailsAllocationDriverResponse,
  IScheduleDetailsAllocationDriversListProps,
} from './get-driver-list.types';
import { converterScheduleDetailsAllocationDriverResponseToDomain } from './mappers/converter-driver-response-to-domain/converter-driver-response-to-domain.mapper';

export const getDriversListService = async (
  params: IScheduleDetailsAllocationDriversListProps,
): Promise<IListPage<IScheduleDetailsAllocationDriverDomain>> => {
  const { data } = await API.get<IListPage<IScheduleDetailsAllocationDriverResponse>>('v2/drivers', {
    params,
  });

  return {
    items: data.items.map(converterScheduleDetailsAllocationDriverResponseToDomain),
    meta: data.meta,
  };
};
