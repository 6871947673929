import { useCallback, useReducer } from 'react';
import { ScheduleDownloadMapReportActionType } from '../../schedule.action';
import { scheduleInitialState } from '../../schedule.constants';
import { ITruckRoute } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/domains/truck-route.domain';
import { IScheduleDownloadMapReport } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-download-map-report.types';
import { scheduleDownloadMapReportReducer } from 'src-new/pages/execution/pages/schedule/contexts/schedule/reducers/schedule-download-map-report/schedule-download-map-report.reducer';

export const useScheduleDownloadMapReportContextValues = (): IScheduleDownloadMapReport => {
  const [state, dispatch] = useReducer(scheduleDownloadMapReportReducer, scheduleInitialState);

  const setScheduleDownloadMapReportRequest = useCallback(() => {
    dispatch({
      type: ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_REQUEST,
      payload: undefined,
    });
  }, []);

  const setScheduleDownloadMapReportSuccess = useCallback((trackingRoute?: ITruckRoute) => {
    dispatch({
      type: ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_SUCCESS,
      payload: trackingRoute,
    });
  }, []);

  const setScheduleDownloadMapReportError = useCallback(() => {
    dispatch({
      type: ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_ERROR,
      payload: undefined,
    });
  }, []);

  const setScheduleDownloadMapReportReset = useCallback(() => {
    dispatch({
      type: ScheduleDownloadMapReportActionType.SCHEDULE_DOWNLOAD_MAP_REPORT_RESET,
      payload: undefined,
    });
  }, []);

  return {
    ...state.scheduleDownloadMapReport,
    setScheduleDownloadMapReportRequest,
    setScheduleDownloadMapReportSuccess,
    setScheduleDownloadMapReportError,
    setScheduleDownloadMapReportReset,
  };
};
