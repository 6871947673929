import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { SaleOfferContextProvider } from '../../contexts/sale-offer/sale-offer.context';
import { SaleOfferPageBody } from './components/sale-offer-page-body/sale-offer-page-body.component';
import { MatchesContextProvider } from 'src-new/pages/opportunities/pages/matches/contexts/matches/matches.context';
import { PageHeaderFilterContextProvider } from 'src-new/contexts/page-header-filter/page-header-filter.context';
import { saleOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-list/constants/sale-offer-list-filters-initial-values.constant';
import { InfiniteScrollListTableContextProvider } from 'src-new/contexts/infinite-scroll-list-table/infinite-scroll-list-table.context';

export const SaleOfferListPage: React.FC = () => {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <PageHeaderFilterContextProvider initialFilters={saleOfferListFilterInitialValues}>
        <InfiniteScrollListTableContextProvider>
          <MatchesContextProvider>
            <SaleOfferContextProvider>
              <SaleOfferPageBody />
            </SaleOfferContextProvider>
          </MatchesContextProvider>
        </InfiniteScrollListTableContextProvider>
      </PageHeaderFilterContextProvider>
    );
  }

  return <Navigate to={'/login'} replace />;
};
