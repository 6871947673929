import dayjs from 'dayjs';

export const convertOccurrenceOverdue = (status: string, sla: string, dateStart: string): string => {
  if (status === 'finalizadas') {
    return 'FINALIZADA';
  }

  const today = dayjs();
  const dateStartParsed = dayjs(dateStart, 'DD/MM/YYYY HH:mm');
  const diffHours = today.diff(dateStartParsed, 'hours');

  if (diffHours >= Number(sla.split(' ')[0])) {
    return 'Atrasado';
  }

  return 'No Prazo';
};
