import { useCallback, useContext } from 'react';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getDownloadFileService } from '../../services/get-download-file/get-download-file.service';
import { downloadFromBlob } from 'src-new/utils/download-file.util';

export const useGetDownloadFile = () => {
  const { scheduleImportModal } = useContext(ScheduleContext);

  return useCallback(async () => {
    try {
      const { processingCode } = scheduleImportModal.scheduleImportModal[0];

      const file = await getDownloadFileService(processingCode);

      downloadFromBlob(file, 'Upload_Programacao');
    } catch (error) {
      showMessageFormatted({
        message: 'Erro ao baixar o arquivo.',
        type: 'error',
      });
    }
  }, [scheduleImportModal.scheduleImportModal]);
};
