import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import { ContainerBodyStyled } from './client-list-page-body-view.styled';
import { ApplicationPageHeader } from 'src-new/components/application-page-header/application-page-header.component';
import {
  IConfirmDeleteModalProps,
  IRemoveItemsProps,
} from 'src-new/pages/registrations/pages/client/pages/client-list/components/client-list-page-body/client-list-page-body.types';
import { ClientListTabs } from '../client-list-tabs/client-list-tabs.component';
import {
  ExportReportProps,
  IButtonProps,
  UploadSpreadsheetProps,
} from 'src-new/components/application-page-header/application-page-header.types';
import { ConfirmationModal } from 'src-new/components/confirmation-modal/confirmation-modal.component';
import {
  IModalButtonProps,
  IModalDescriptionProps,
  IModalPrimaryButtonProps,
} from 'src-new/components/confirmation-modal/confirmation-modal.types';
import { WarningDeleteIcon } from 'assets/icons/warning-delete.icon';

interface IClientsListPageBodyViewProps {
  handleSearchInput: (value: string) => void;
  setActiveTabName: (name: string) => void;
  activeTab: string;
  exportReportProps: ExportReportProps;
  removeItemsProps: IRemoveItemsProps;
  createButtonProps: IButtonProps;
  confirmDeleteModalProps: IConfirmDeleteModalProps;
  uploadModalProps: UploadSpreadsheetProps;
  handleInfinityScroll: Dispatch<SetStateAction<number>>;
}

export const ClientsListPageBodyView: React.FC<IClientsListPageBodyViewProps> = ({
  createButtonProps,
  handleSearchInput,
  setActiveTabName,
  exportReportProps,
  removeItemsProps,
  confirmDeleteModalProps,
  uploadModalProps,
  activeTab,
  handleInfinityScroll,
}: IClientsListPageBodyViewProps): ReactElement => {
  const renderConfirmDeleteModal = useMemo(() => {
    if (confirmDeleteModalProps.isOpen) {
      const descriptionProps: IModalDescriptionProps = {
        primaryRow: 'Isso excluirá os parceiros selecionados da listagem.',
        secondaryRow: 'Tem certeza?',
      };
      const secondaryButtonProps: IModalButtonProps = {
        label: 'Voltar',
        handleActionButton: confirmDeleteModalProps.handleCloseConfirmModal,
      };
      const primaryButtonProps: IModalPrimaryButtonProps = {
        label: 'Excluir',
        isLoading: confirmDeleteModalProps.isLoading,
        color: 'red',
        handleActionButton: confirmDeleteModalProps.handleRemoveClient,
      };

      return (
        <ConfirmationModal
          title="Excluir Parceiro(s)"
          description={descriptionProps}
          icon={<WarningDeleteIcon />}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}
        />
      );
    }
  }, [
    confirmDeleteModalProps.handleCloseConfirmModal,
    confirmDeleteModalProps.handleRemoveClient,
    confirmDeleteModalProps.isLoading,
    confirmDeleteModalProps.isOpen,
  ]);

  return (
    <ContainerBodyStyled>
      <ApplicationPageHeader
        headerProps={{
          breadcrumbList: ['Cadastros', 'Meus Parceiros'],
          subtitle: 'Realize o cadastro dos seus Parceiros para venda de ociosidades.',
          inputSearchProps: { searchPlaceHolder: 'Pesquise por Nome ou Cidade', handleSearchInput },
          primaryButtonProps: createButtonProps,
          secondaryButtonProps: removeItemsProps,
          exportReportProps,
          uploadModalProps,
        }}
      />
      <ClientListTabs
        activeTab={activeTab}
        setActiveTab={setActiveTabName}
        handleIdsToRemove={removeItemsProps.setIds}
        handleInfinityScroll={handleInfinityScroll}
      />
      {renderConfirmDeleteModal}
    </ContainerBodyStyled>
  );
};
