import React from 'react';

import theme from 'styles/theme';

const TruckLoadingIcon: React.FC = () => {
  return (
    <svg
      width="60"
      height="80"
      viewBox="0 0 60 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 25 }}
    >
      <path
        d="M57.5 0C61.6406 0 65 3.35781 65 7.5V15H72.9219C75.5781 15 77.9844 16.0469 80 17.9219L92.0781 30C93.9531 31.875 95 34.4219 95 37.0781V55C97.7656 55 100 57.2344 100 60C100 62.7656 97.7656 65 95 65H90C90 73.2812 83.2812 80 75 80C66.5781 80 60 73.2812 60 65H40C40 73.2812 33.2812 80 25 80C16.5781 80 10 73.2812 10 65H7.5C3.35781 65 0 61.6406 0 57.5V7.5C0 3.35781 3.35781 0 7.5 0H57.5ZM65 25V40H85V37.0781L72.9219 25H65ZM25 57.5C20.8594 57.5 17.5 60.8594 17.5 65C17.5 69.1406 20.8594 72.5 25 72.5C29.1406 72.5 32.5 69.1406 32.5 65C32.5 60.8594 29.1406 57.5 25 57.5ZM75 72.5C79.1406 72.5 82.5 69.1406 82.5 65C82.5 60.8594 79.1406 57.5 75 57.5C70.8594 57.5 67.5 60.8594 67.5 65C67.5 69.1406 70.8594 72.5 75 72.5Z"
        fill={theme.colorsV2.blue}
      />
    </svg>
  );
};

export default TruckLoadingIcon;
