import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  border: 1px solid #c6c6c6;
  border-radius: 0.375rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
  background-color: #fafafa;
`;

export const Children = styled.div`
  padding: 1.5rem;
`;

export const Footer = styled.footer`
  background-color: #fafafa;
  border-top: 1px solid #c6c6c6;

  height: 3rem;
  padding: 2rem 1.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterButtons = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
`;
