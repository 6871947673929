import React from 'react';

import NegotiationsModalView from './negotiations-modal-view';

interface IProps {
  title: string;
  show: boolean;
  useCallBackCloseButton?: () => void;
  children: React.ReactNode;
}

const NegotiationsModal: React.FC<IProps> = ({ title, show, children, useCallBackCloseButton }) => {
  return (
    <NegotiationsModalView title={title} show={show} useCallBackCloseButton={useCallBackCloseButton}>
      {children}
    </NegotiationsModalView>
  );
};

export default NegotiationsModal;
