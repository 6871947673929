import React, { FC, useMemo } from 'react';
import { PurchaseOfferTableTabs } from '../purchase-offer-table-tabs/purchase-offer-table-tabs.component';
import { ContainerStyled } from './purchase-offer-page-body-view.styled';
import { PurchaseOfferHeader } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-header/purchase-offer-header.component';
import { PurchaseOfferModalMatch } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/components/purchase-offer-page-body/components/purchase-offer-modal-match/purchase-offer-modal-match.component';
import { purchaseOfferListFilterInitialValues } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/constants/purchase-offer-list-filters-initial-values.constant';
import { Formik } from 'formik';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';

interface IPurchaseOfferPageBodyViewProps {
  isOpenModal: boolean;
  onFilterSubmit: (values: IPurchaseOfferListFilterDomain) => void;
}

export const PurchaseOfferPageBodyView: FC<IPurchaseOfferPageBodyViewProps> = ({ isOpenModal, onFilterSubmit }) => {
  const renderModalMatch = useMemo(() => {
    if (isOpenModal) return <PurchaseOfferModalMatch />;
  }, [isOpenModal]);

  return (
    <Formik initialValues={purchaseOfferListFilterInitialValues} onSubmit={onFilterSubmit}>
      <ContainerStyled>
        <PurchaseOfferHeader />
        <PurchaseOfferTableTabs />
        {renderModalMatch}
      </ContainerStyled>
    </Formik>
  );
};
