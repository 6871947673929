import { FC, useContext, useMemo } from 'react';

import FiltersProvider from '../context/list';

import { TransactionList } from './content-table';

import Title from 'components/title/title';
import { HeaderPage } from 'src-new/components/application-page-header/components/header-page/header-page.component';
import { UserContext } from 'state/user-context';

export const Transactions: FC = () => {
  const { isShippingCompany } = useContext(UserContext);

  const headerLabel = useMemo((): { breadcrumb: Array<string>; title: string } => {
    if (isShippingCompany) {
      return {
        breadcrumb: ['Fretes', 'A Receber'],
        title: 'Acompanhe o processo de recebimento dos fretes realizados',
      };
    }

    return {
      breadcrumb: ['Transações'],
      title: 'Acompanhe o faturamento e cobrança dos fretes realizados',
    };
  }, [isShippingCompany]);

  return (
    <>
      <HeaderPage breadcrumbList={headerLabel.breadcrumb} />
      <Title title={headerLabel.title} hasTrace />
      <FiltersProvider>
        <TransactionList />
      </FiltersProvider>
    </>
  );
};
