import { scheduleCargoUpdateService } from 'pages/freight/services/schedule-cargo-services/schedule-cargo-update/schedule-cargo-update.service';
import { ScheduleCargo } from 'domain/schedule';

const useUpdateCargoDetailsHook = () => {
  return async (id: number, values: ScheduleCargo) => {
    await scheduleCargoUpdateService(id, values);
  };
};

export { useUpdateCargoDetailsHook };
