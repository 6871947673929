import styled, { css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

export const Wrapper = styled.div`
  width: 100%;
`;

export const WrapperCompanyImage = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CompanyImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-top: 3px;
`;

interface ITextProps {
  fontSize?: number;
  fontWeight?: number;
  color?: ThemeColorsV2Type;
  secondaryLabel?: boolean;
}
export const Text = styled.p<ITextProps>`
  ${({ theme, fontSize, fontWeight, color, secondaryLabel }) => css`
    font-size: ${fontSize ?? 11}px;
    font-weight: ${fontWeight};
    color: ${theme.colorsV2[color ?? 'black']};
    margin-top: ${secondaryLabel ? 6 : 0}px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const ContainerList = styled.div`
  justify-content: left;
  align-items: normal;
`;

export const ContainerTag = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 9px;
`;

export const ContainerContent = styled.div`
  width: fit-content;
`;

export const ContainerButton = styled.div`
  button {
    max-height: 37px;
    max-width: 37px;
  }
`;

export const TagText = styled.span`
  display: flex;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
`;
