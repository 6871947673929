import { FC, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { ScheduleFinanceResumeTitleView } from './schedule-finance-title-view.component';

interface IScheduleFinanceResumeTitleProps {
  typeTransaction: 'freithgtPayable' | 'freithgtReceivable' | 'freithgtShipping';
}

export const ScheduleFinanceResumeTitle: FC<IScheduleFinanceResumeTitleProps> = ({ typeTransaction }) => {
  const { scheduleDetails } = useContext(ScheduleContext);

  return (
    <ScheduleFinanceResumeTitleView schedule={scheduleDetails.scheduleDetails} typeTransaction={typeTransaction} />
  );
};
