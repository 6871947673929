import { useReducer } from 'react';
import { IPageHeaderFilterContext } from 'src-new/contexts/page-header-filter/types/page-header-filter.types';
import { PageHeaderFilterActionType } from 'src-new/contexts/page-header-filter/page-header-filter.action';
import { pageHeaderFilterInitialState } from 'src-new/contexts/page-header-filter/page-header-filter.constants';
import { pageHeaderFilterReducer } from 'src-new/contexts/page-header-filter/reducers/page-header-filter.reducer';

export const usePageHeaderFilterContextValues = <T>(initialFilters: T): IPageHeaderFilterContext<T> => {
  const [state, dispatch] = useReducer(pageHeaderFilterReducer<T>, pageHeaderFilterInitialState<T>(initialFilters));

  const setFilters = (filters: T) => {
    dispatch({ type: PageHeaderFilterActionType.PAGE_HEADER_FILTER_SET, payload: filters });
  };

  const setOpenModal = () => {
    dispatch({ type: PageHeaderFilterActionType.PAGE_HEADER_FILTER_OPEN_MODAL, payload: undefined });
  };

  const setCloseModal = () => {
    dispatch({ type: PageHeaderFilterActionType.PAGE_HEADER_FILTER_CLOSE_MODAL, payload: undefined });
  };

  const setPageHeaderFilterReset = () => {
    dispatch({ type: PageHeaderFilterActionType.PAGE_HEADER_FILTER_RESET, payload: undefined });
  };

  return {
    ...state,
    modalProps: {
      ...state.modalProps,
      setOpenModal,
      setCloseModal,
    },
    setFilters,
    setPageHeaderFilterReset,
  };
};
