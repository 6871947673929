import React, { memo, ReactNode, useEffect, useMemo, useRef } from 'react';

import * as S from './styled';

import { LineConnectorHome } from 'pages/home/backhaul/components/connect-points';
import Loading from 'components-v2/common/loading';

interface IConnection {
  locality: string;
  tooltip: JSX.Element | undefined;
  name: string;
}

interface IContent {
  value?: string | ReactNode;
  connection?: {
    origin: IConnection;
    destination: IConnection;
  };
  subtitle?: string | ReactNode;
  button?: ReactNode;
  flex?: number;
}

export type IContentTable = Array<IContent>;

interface ITableViewProps {
  header: IContentTable;
  content: Array<IContentTable>;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const BackhaulHomeTable: React.FC<ITableViewProps> = ({ header, content, isLoading, setPage }) => {
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!listRef?.current || !listRef?.current.lastChild) return;

    const trigger = listRef.current.lastChild as HTMLDivElement;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 0 },
    );

    observer.observe(trigger);

    return () => {
      observer.unobserve(trigger);
    };
  }, [setPage]);

  const renderHeaderTable = useMemo(() => {
    return (
      <S.LineHeaderTableStyled>
        {header.map(({ button, value, flex }, index) => {
          if (!button)
            return (
              <S.HeaderTableStyled key={index} flex={flex}>
                <S.HeaderTextStyled>{value}</S.HeaderTextStyled>
              </S.HeaderTableStyled>
            );
        })}
        <div style={{ minWidth: 118 }}></div>
      </S.LineHeaderTableStyled>
    );
  }, [header]);

  const renderContentTable = useMemo(() => {
    return content?.map((contentLine, index) => {
      const renderedContent = contentLine.map(({ value, flex, subtitle, connection, button }, indexLine) => {
        if (!button) {
          return (
            <S.ContentTableStyled key={indexLine} flex={flex}>
              {React.isValidElement(value) ? (
                value
              ) : (
                <S.DisplayNoneStyled>
                  {!connection && <S.TextStyled>{value?.toString().toUpperCase()}</S.TextStyled>}

                  {connection && (
                    <div
                      style={{
                        display: 'flex',
                        gap: 5,
                        overflow: 'hidden',
                      }}
                    >
                      <div style={{ paddingTop: '2px' }}>
                        <LineConnectorHome height={55} bgColor="#D9D9D9" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 15,
                          marginLeft: 5,
                          marginRight: 10,
                          overflow: 'hidden',
                        }}
                      >
                        <S.InfoLocalityWrapper>
                          <S.InfoLocalityContainer>
                            <S.InfoLocality>
                              <S.TextLocality>{connection?.origin.locality}</S.TextLocality>
                              {connection.origin.tooltip}
                            </S.InfoLocality>
                            <S.SubTitleLocality>{connection.origin.name}</S.SubTitleLocality>
                          </S.InfoLocalityContainer>
                        </S.InfoLocalityWrapper>

                        <S.InfoLocalityWrapper>
                          <S.InfoLocalityContainer>
                            <S.InfoLocality>
                              <S.TextLocality>{connection?.destination.locality}</S.TextLocality>
                              {connection.destination.tooltip}
                            </S.InfoLocality>
                            <S.SubTitleLocality>{connection.destination.name}</S.SubTitleLocality>
                          </S.InfoLocalityContainer>
                        </S.InfoLocalityWrapper>
                      </div>
                    </div>
                  )}

                  {subtitle && <S.SubTitleStyled>{subtitle}</S.SubTitleStyled>}
                </S.DisplayNoneStyled>
              )}
            </S.ContentTableStyled>
          );
        }
        return null;
      });

      const renderedButtons = contentLine.map(({ button }, indexLine) => <div key={indexLine}>{button}</div>);

      return (
        <React.Fragment key={index}>
          <S.LineTableStyled>
            {renderedContent}
            {renderedButtons}
          </S.LineTableStyled>
        </React.Fragment>
      );
    });
  }, [content]);

  const renderTableBody = useMemo(() => {
    if (isLoading) {
      return (
        <S.ContainerLoadingStyled>
          <Loading />
        </S.ContainerLoadingStyled>
      );
    }
    return renderContentTable;
  }, [renderContentTable]);

  return (
    <S.TableStyled>
      <S.TableHeadStyled>{renderHeaderTable}</S.TableHeadStyled>
      <S.TableBodyStyled ref={listRef}>{renderTableBody}</S.TableBodyStyled>
    </S.TableStyled>
  );
};

export default memo(BackhaulHomeTable);
