import React from 'react';

import * as S from './styled';

type CheckboxViewProps = {
  id: string;
  name: string;
  label: string;
  bgColor: 'aqua' | 'blue' | 'red';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  checked?: boolean;
};

const CheckboxTag: React.FC<CheckboxViewProps> = ({ id, name, label, onChange, value, disabled, checked, bgColor }) => {
  return (
    <S.WrapperStyled>
      <S.InputStyled
        id={id}
        type="checkbox"
        value={value}
        bgColor={bgColor}
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <S.LabelStyled htmlFor={id} bgColor={bgColor}>
        {label}
      </S.LabelStyled>
    </S.WrapperStyled>
  );
};

export default CheckboxTag;
