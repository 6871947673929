import { ScheduleDocumentsActionType } from '../../schedule.action';
import { IScheduleDocumentsActions } from '../../types/schedule-documents';
import { IScheduleContext } from '../../types/schedule.types';

export const scheduleDocumentsReducer = (
  state: IScheduleContext,
  action: IScheduleDocumentsActions,
): IScheduleContext => {
  switch (action.type) {
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          nfeDataProps: {
            ...state.scheduleDocuments.nfeDataProps,
            statusRequest: 'PROCESSING',
            nfeList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          nfeDataProps: {
            ...state.scheduleDocuments.nfeDataProps,
            statusRequest: 'SUCCESS',
            nfeList: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_NFE_DATA_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          nfeDataProps: {
            ...state.scheduleDocuments.nfeDataProps,
            statusRequest: 'ERROR',
            nfeList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          cteDataProps: {
            ...state.scheduleDocuments.cteDataProps,
            statusRequest: 'PROCESSING',
            cteList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          cteDataProps: {
            ...state.scheduleDocuments.cteDataProps,
            statusRequest: 'SUCCESS',
            cteList: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CTE_DATA_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          cteDataProps: {
            ...state.scheduleDocuments.cteDataProps,
            statusRequest: 'ERROR',
            cteList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          mdfeDataProps: {
            ...state.scheduleDocuments.mdfeDataProps,
            statusRequest: 'PROCESSING',
            mdfeList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          mdfeDataProps: {
            ...state.scheduleDocuments.mdfeDataProps,
            statusRequest: 'SUCCESS',
            mdfeList: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MDFE_DATA_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          mdfeDataProps: {
            ...state.scheduleDocuments.mdfeDataProps,
            statusRequest: 'ERROR',
            mdfeList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          othersDocumentsProps: {
            ...state.scheduleDocuments.othersDocumentsProps,
            statusRequest: 'PROCESSING',
            otherDocumentsList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          othersDocumentsProps: {
            ...state.scheduleDocuments.othersDocumentsProps,
            statusRequest: 'SUCCESS',
            otherDocumentsList: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_OTHERS_DOCUMENTS_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          othersDocumentsProps: {
            ...state.scheduleDocuments.othersDocumentsProps,
            statusRequest: 'ERROR',
            otherDocumentsList: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistQuestionsProps: {
            ...state.scheduleDocuments.checklistQuestionsProps,
            statusRequest: 'PROCESSING',
            checklistQuestions: {
              moduleId: 0,
              questions: [],
            },
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistQuestionsProps: {
            ...state.scheduleDocuments.checklistQuestionsProps,
            statusRequest: 'SUCCESS',
            checklistQuestions: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_QUESTIONS_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistQuestionsProps: {
            ...state.scheduleDocuments.checklistQuestionsProps,
            statusRequest: 'ERROR',
            checklistQuestions: {
              moduleId: 0,
              questions: [],
            },
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistAnswerProps: {
            ...state.scheduleDocuments.checklistAnswerProps,
            statusRequest: 'PROCESSING',
            checklistAnswer: {
              answers: [],
              checklistModule: 'schedule',
              moduleId: 0,
              name: '',
              status: '',
            },
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistAnswerProps: {
            ...state.scheduleDocuments.checklistAnswerProps,
            statusRequest: 'SUCCESS',
            checklistAnswer: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ANSWER_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistAnswerProps: {
            ...state.scheduleDocuments.checklistAnswerProps,
            statusRequest: 'ERROR',
            checklistAnswer: {
              answers: [],
              checklistModule: 'schedule',
              moduleId: 0,
              name: '',
              status: '',
            },
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_REQUEST:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistDocumentsProps: {
            ...state.scheduleDocuments.checklistDocumentsProps,
            statusRequest: 'PROCESSING',
            checklistDocuments: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_SUCCESS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistDocumentsProps: {
            ...state.scheduleDocuments.checklistDocumentsProps,
            statusRequest: 'SUCCESS',
            checklistDocuments: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_CHECKLIST_ERROR:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          checklistDocumentsProps: {
            ...state.scheduleDocuments.checklistDocumentsProps,
            statusRequest: 'ERROR',
            checklistDocuments: [],
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_MODALS:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          modalsProps: {
            ...state.scheduleDocuments.modalsProps,
            modalOptions: action.payload,
          },
        },
      };
    case ScheduleDocumentsActionType.SCHEDULE_DOCUMENTS_RESET:
      return {
        ...state,
        scheduleDocuments: {
          ...state.scheduleDocuments,
          nfeDataProps: {
            ...state.scheduleDocuments.nfeDataProps,
            nfeList: [],
          },
          cteDataProps: {
            ...state.scheduleDocuments.cteDataProps,
            cteList: [],
          },
          mdfeDataProps: {
            ...state.scheduleDocuments.mdfeDataProps,
            mdfeList: [],
          },
          othersDocumentsProps: {
            ...state.scheduleDocuments.othersDocumentsProps,
            otherDocumentsList: [],
          },
          checklistAnswerProps: {
            ...state.scheduleDocuments.checklistAnswerProps,
            checklistAnswer: {
              answers: [],
              checklistModule: 'schedule',
              moduleId: 0,
              name: '',
              status: 'Aprovado',
            },
          },
          checklistQuestionsProps: {
            ...state.scheduleDocuments.checklistQuestionsProps,
            checklistQuestions: {
              moduleId: 0,
              questions: [],
            },
          },
          modalsProps: {
            ...state.scheduleDocuments.modalsProps,
            modalOptions: { open: false, id: 0, modalType: '' },
          },
        },
      };
    default:
      return state;
  }
};
