import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ContactRegistrationModalView } from './contact-registration-modal-view.component';
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { IContactDomain } from 'src-new/pages/registrations/domains/contact.domain';
import { ModalType } from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal.types';
import { contactInitialValues } from 'src-new/pages/registrations/components/contact-details-section/components/contact-registration-modal/contact-registration-modal.constants';
import { RegistrationContactValues } from 'src-new/pages/registrations/domains/registration-values.domain';

interface IContactRegistrationModalProps {
  modalType: ModalType;
  setModalClose: () => void;
}

export const ContactRegistrationModal: React.FC<IContactRegistrationModalProps> = ({ modalType, setModalClose }) => {
  const { getFieldProps, setFieldValue, handleChange, getFieldMeta, validateForm, setTouched } =
    useFormikContext<RegistrationContactValues>();
  const [position, setPosition] = useState<number>(0);
  const [existPosition, setExistPosition] = useState<boolean>(false);
  const [currentContact, setCurrentContact] = useState<IContactDomain | undefined>(undefined);

  const handleCloseModal = useCallback(() => {
    if (modalType.type === 'CREATE') {
      modalType.arrayHelpers.remove(position);
    } else if (currentContact) {
      setFieldValue(`contacts[${position}]`, currentContact);
    }

    setModalClose();
  }, [modalType, currentContact, setModalClose, position, setFieldValue]);

  const hasErrorField = useCallback(
    (name: string): boolean => !!getFieldMeta(name).error && getFieldMeta(name).touched,
    [getFieldMeta],
  );

  const getErrorMessage = useCallback((name: string): string => getFieldMeta(name).error ?? '', [getFieldMeta]);

  const contacts = useMemo((): Array<IContactDomain> => getFieldProps('contacts').value ?? [], [getFieldProps]);

  const contact = useMemo((): IContactDomain | undefined => {
    if (contacts.length >= 1) {
      const contact = getFieldProps(`contacts[${position}]`).value;

      if (!currentContact && contact) {
        setCurrentContact(contact);
      }

      return contact;
    }
  }, [contacts.length, currentContact, getFieldProps, position]);

  const handleSaveButton = useCallback(
    async (item: IContactDomain) => {
      const formErrors = await validateForm();
      const errorsContact = formErrors.contacts ? { contacts: formErrors.contacts } : undefined;

      if (errorsContact && Object.keys(errorsContact).length > 0) {
        setTouched(setNestedObjectValues<FormikTouched<RegistrationContactValues>>(errorsContact, true));
      } else {
        setFieldValue(`contacts[${position}]`, item);
        setModalClose();
      }
    },
    [position, setFieldValue, setModalClose, setTouched, validateForm],
  );

  useEffect(() => {
    if (!existPosition) {
      if (modalType.type === 'CREATE') {
        modalType.arrayHelpers.insert(contacts.length, contactInitialValues);

        setPosition(contacts.length);
      } else {
        setPosition(modalType.position);
      }
      setExistPosition(true);
    }
  }, [contacts.length, existPosition, modalType]);

  return (
    <ContactRegistrationModalView
      handleCloseModal={handleCloseModal}
      contact={contact}
      hasErrorField={hasErrorField}
      getErrorMessage={getErrorMessage}
      position={position}
      handleSaveButton={handleSaveButton}
      handleChange={handleChange}
    />
  );
};
