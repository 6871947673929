import styled from 'styled-components';

export const HeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  background-color: rgb(243 243 243);
  border-radius: 10px;
  padding: 20px 8px 20px 20px;
  height: 100px;
`;

export const ContainerInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TitleStyled = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const DescriptionStyled = styled.div`
  font-size: 14px;
`;
