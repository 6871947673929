export const convertVehicleCategoryTypeEntityToLabel = (vehicleCategoryType: string): string => {
  switch (vehicleCategoryType) {
    case '':
      return '';
    case 'bau_seco':
      return 'Baú Seco';
    case 'bau_refrigerado':
      return 'Baú Refrigerado';
    case 'bau_frigorificado':
      return 'Baú Frigorificado';
    case 'bau_sider':
      return 'Sider';
    case 'cacamba_basculante':
      return 'Basculante';
    case 'cegonha':
      return 'Cegonha';
    case 'florestal':
      return 'Florestal';
    case 'grade_baixa':
      return 'Grade Baixa';
    case 'graneleiro':
      return 'Graneleiro';
    case 'plataforma':
      return 'Plataforma';
    case 'porta_container':
      return 'Porta Container';
    case 'porta_container_20':
      return 'Porta Container 20';
    case 'porta_container_40':
      return 'Porta Container 40';
    case 'tanque':
      return 'Tanque';
    case 'complemento':
      return 'Complemento';
    default:
      return '-';
  }
};
