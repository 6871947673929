import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputFieldContainerStyled = styled.div`
  display: flex;
  width: 100%;
`;

export const GroupFieldsContainerStyled = styled.div`
  display: flex;
  width: 100%;
`;

export const InputSelectFieldContainerStyled = styled.div`
  display: flex;
  width: 100%;
`;

export const DateTimeFieldContainerStyled = styled.div`
  padding-top: 4px;
  padding-left: 20px;
  display: flex;
  width: 100%;
`;

export const EmptySpinnerContainer = styled.div`
  height: 324px;
  align-content: center;
`;
