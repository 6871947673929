import { FC } from 'react';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import { SectionForm } from 'src-new/components/section-form/section-form.component';
import AsyncSelectField from 'components/async-select/async-select';
import { FormikErrors } from 'formik';
import { itemsFilterSearchService } from 'pages/vehicle/services/items-search/items.filter-search.service';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';

interface IWeighingFormMountItemsViewProps {
  values: IWeighingDomain;
  isMaster: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<IWeighingDomain>>;
}

export const WeighingFormMountItemsView: FC<IWeighingFormMountItemsViewProps> = ({ setFieldValue, values, isMaster }) => {
  return (
    <SectionForm>
      <LineSectionLayout columns="1fr">
        <AsyncSelectField
          placeholder=""
          label="Item"
          isClearable={true}
          name="item.id"
          id="item.id"
          cacheOptions={true}
          defaultOptions={true}
          onChange={async (e) => {
            setFieldValue('item.id', e && e.value);
            setFieldValue('item.description', e && e.label);
          }}
          apiCallFunction={itemsFilterSearchService}
          loadOptions={itemsFilterSearchService}
          value={{
            label: values.item.description,
            value: values.item.id
          }}
          disabled={!isMaster}
        />
      </LineSectionLayout>
    </SectionForm>
  );
};
