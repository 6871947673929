import { ISantosPortContext, ISantosPortReducerAction } from './santos-port.types';
import { ISantosPortAction } from './santos-port.actions';

export const santosPortReducer = (state: ISantosPortContext, action: ISantosPortReducerAction): ISantosPortContext => {
  switch (action.type) {
    case ISantosPortAction.SANTOS_PORT_REQUEST: {
      return {
        ...state,
        santosPortIsLoading: true,
        santosPort: [],
        santosPortError: undefined,
      };
    }
    case ISantosPortAction.SANTOS_PORT_SUCCESS: {
      return {
        ...state,
        santosPortIsLoading: false,
        santosPort: action.payload.list,
        santosPortPageInfo: action.payload.pageInfo,
        santosPortError: undefined,
      };
    }
    case ISantosPortAction.SANTOS_PORT_ERROR: {
      return {
        ...state,
        santosPortIsLoading: false,
        santosPort: [],
        santosPortError: action.payload,
        santosPortPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
