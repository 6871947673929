import { ITransportCompaniesContext, ITransportCompaniesReducerAction } from './transport-companies.types';
import { ITransportCompaniesAction } from './transport-companies.actions';

export const transportCompaniesReducer = (
  state: ITransportCompaniesContext,
  action: ITransportCompaniesReducerAction,
): ITransportCompaniesContext => {
  switch (action.type) {
    case ITransportCompaniesAction.TRANSPORT_COMPANIES_REQUEST: {
      return {
        ...state,
        transportCompaniesIsLoading: true,
        transportCompanies: [],
        transportCompaniesError: undefined,
      };
    }
    case ITransportCompaniesAction.TRANSPORT_COMPANIES_SUCCESS: {
      return {
        ...state,
        transportCompaniesIsLoading: false,
        transportCompanies: action.payload.list,
        transportCompaniesPageInfo: action.payload.pageInfo,
        transportCompaniesError: undefined,
      };
    }
    case ITransportCompaniesAction.TRANSPORT_COMPANIES_ERROR: {
      return {
        ...state,
        transportCompaniesIsLoading: false,
        transportCompanies: [],
        transportCompaniesError: action.payload,
        transportCompaniesPageInfo: undefined,
      };
    }
    default:
      return state;
  }
};
