import { useNavigate } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CompanyBodyView from './company-body-view';

import { DownloadFile } from 'utils/download-file/download-file';
import { CompaniesContext } from 'pages/company/states/companies/companies.context';
import { convertActiveTabToStatusDomainMapper } from 'pages/company/mappers/convert-activetab-to-status/convert-activetab-to-status.mapper';
import { useReportExcelHook } from 'pages/company/hooks/use-location-report-excel/use-location-report-excel.hook';
import { useCompaniesHook } from 'pages/company/hooks/companies/companies.hook';
import { ReportOption } from 'domain/report-files';
import { ICompanyStatus } from 'domain/company';
import { showToast } from 'components/toast/toast';

const Options: Array<ReportOption> = [
  { label: 'Relatório Completo', value: 'all-excel' },
  { label: 'Relatório Filtrado', value: 'filtered-excel' },
];

const OptionsOrder: Array<ReportOption> = [
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Registro mais Antigo', value: 'asc' },
];

const CompanyBody: React.FC = () => {
  const GenerateExcelHook = useReportExcelHook();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [searchInput, setSearchInput] = useState<string>('');

  const [activeTabName, setActiveTabName] = useState<string>('Ativos');

  const [currentPageCompanies, setCurrentPageCompanies] = useState(1);

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const [orderBy, setOrderBy] = useState<string>('id');

  const companiesHook = useCompaniesHook();

  const { companies, companiesPageInfo, companiesIsLoading } = useContext(CompaniesContext);

  const renderStatusByTabName = useMemo((): ICompanyStatus | null => {
    if (activeTabName === 'Todos') {
      return null;
    }
    if (activeTabName === 'Ativos') {
      return 'ativo';
    }
    if (activeTabName === 'Inativos') {
      return 'inativo';
    }
    if (activeTabName === 'Bloqueados') {
      return 'suspenso';
    }
    return null;
  }, [activeTabName]);

  const goToCreateCompany = useCallback(() => {
    navigate('/empresas/novo');
  }, [navigate]);

  const goToDetailsPage = useCallback(
    (companyId: string) => {
      navigate(`/empresas/atualizar/${companyId}`);
    },
    [navigate],
  );

  const showModalConfirmation = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleDeletePartner = useCallback(() => {
    alert('Implementar Exclusão de Empresas');
  }, []);

  const handleOrderList = useCallback(
    async (optionOrder: ReportOption) => {
      if (optionOrder.value === 'name') {
        companiesHook(searchInput, currentPageCompanies, renderStatusByTabName, 'asc', 'name');
      }
      if (optionOrder.value === 'asc') {
        companiesHook(searchInput, currentPageCompanies, renderStatusByTabName, 'asc', 'id');
      }
      showToast({
        type: 'loading',
        message: 'Atualizando listagem',
      });
    },
    [companiesHook, currentPageCompanies, renderStatusByTabName, searchInput],
  );

  useEffect(() => {
    companiesHook(searchInput, currentPageCompanies, renderStatusByTabName, sortDirection, orderBy);
  }, [orderBy, companiesHook, currentPageCompanies, sortDirection, renderStatusByTabName, searchInput]);

  useEffect(() => {
    setCurrentPageCompanies(1);
  }, [activeTabName]);

  const handleGenerateExcel = useCallback(
    async (option: ReportOption) => {
      let file = null;
      switch (option.value) {
        case 'filtered-excel':
          file = await GenerateExcelHook({
            search: searchInput,
            status: convertActiveTabToStatusDomainMapper(activeTabName),
          });
          break;
        case 'all-excel':
          file = await GenerateExcelHook({
            search: '',
            status: undefined,
          });
          break;
      }

      if (file) {
        DownloadFile(file, 'vnd.ms-excel;', 'COMPANHIA');
      }
    },
    [GenerateExcelHook, activeTabName, searchInput],
  );

  return (
    <CompanyBodyView
      goToCreateCompany={goToCreateCompany}
      companies={companies}
      companiesPageInfo={companiesPageInfo}
      setCurrentPageCompanies={setCurrentPageCompanies}
      hasLoading={companiesIsLoading}
      setSearchInput={setSearchInput}
      setActiveTabName={setActiveTabName}
      showModalConfirmation={showModalConfirmation}
      handleDeletePartner={handleDeletePartner}
      showModal={showModal}
      goToDetailsPage={goToDetailsPage}
      onHandleExport={handleGenerateExcel}
      options={Options}
      handleOrderList={handleOrderList}
      OptionsOrder={OptionsOrder}
      clickOrderBy={(e: string) => setOrderBy(e)}
      clickSortDirection={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
    />
  );
};

export default CompanyBody;
