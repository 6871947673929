import styled, { css } from 'styled-components';

import theme from 'styles/theme';

interface IStyledProps {
  labelColor?: string;
}

export const WrapperStyled = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
`;

type InputProps = { backgroundColor?: string; hasError?: boolean };
export const InputStyled = styled.input<InputProps>`
  ${({ backgroundColor = '#1d4165', hasError = false }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 15px;
    min-height: 15px;
    border: 1px solid;
    border-color: ${hasError ? theme.colors.error : theme.colors.gray};
    border-radius: 4px;
    position: relative;
    outline: none;

    &::before {
      content: '';
      width: 2px;
      height: 6px;
      border: 3px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 1px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:focus {
      box-shadow: 0 0 0.5rem #fff;
    }

    &:disabled {
      background: #d8d5d57b;
      cursor: not-allowed;
    }

    &:checked {
      background: ${backgroundColor};
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  `}
`;

export const LabelStyled = styled.label<IStyledProps>`
  ${({ labelColor = '#1d4165' }) => css`
    cursor: pointer;
    padding-left: 5px;
    color: ${labelColor};
    line-height: 1;
    font-size: 11px;
  `}
`;
