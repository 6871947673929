import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { checkOccurrenceOverdue } from 'pages/tracking-occurrence/utils';
import TrackingOccurrenceTable from 'pages/tracking-occurrence/tracking-occurrence-list/tracking-occurrence-body/components/tracking-occurrence-table/tracking-occurrence-table';
import { StickerOccurrenceImpactType } from 'pages/tracking-occurrence/tracking-occurrence-list/tracking-occurrence-body/components/sticker-occurrence-impact/sticker-occurrence-impact';
import { ITrackingOccurrence } from 'domain/tracking-occurrence';
import { IContentTable } from 'components/table/table.types';
import Status from 'components/status-circle/status-circle';

interface IVehicleListBodyViewProps {
  occurrencesTracking: Array<ITrackingOccurrence> | [];
  hasLoading: boolean;
  goToUpdatePage: (occurrenceId: string) => void;
}

const PendingOccurrenceList: React.FC<IVehicleListBodyViewProps> = ({
  occurrencesTracking,
  hasLoading,
  goToUpdatePage,
}: IVehicleListBodyViewProps): JSX.Element => {
  const contentTable = useMemo((): Array<IContentTable> => {
    return occurrencesTracking.map((occurrencesTracking) => {
      const isOverdue = checkOccurrenceOverdue(
        occurrencesTracking.occurrenceType?.sla,
        `${occurrencesTracking.occurrenceDate} ${occurrencesTracking.occurrenceTime}`,
      );

      const occurrenceTime = Number(occurrencesTracking?.occurrenceType?.sla?.replace(/ horas?/, '')) || 0;

      const occurrenceExpectedTime = moment(occurrencesTracking.occurrenceTime, 'HH:mm');

      const occurrenceExpectedDate = moment(occurrencesTracking.occurrenceDate, 'DD/MM/YYYY')
        .add(occurrenceExpectedTime.hours(), 'hours')
        .add(occurrenceExpectedTime.minutes(), 'minutes')
        .add(occurrenceTime, 'hours');

      return [
        {
          value: '',
          flex: 0,
        },
        {
          value: occurrencesTracking.id,
          flex: 0.25,
        },
        {
          value: occurrencesTracking.osNumber,
          flex: 0.4,
        },

        {
          value: (
            <div style={{ display: 'flex', gap: 10 }}>
              <StickerOccurrenceImpactType impactType={occurrencesTracking?.occurrenceType?.impact || ''} />
            </div>
          ),
          flex: 0.5,
        },

        {
          value: occurrencesTracking.occurrenceType?.description,
          flex: 1,
        },

        {
          value: occurrencesTracking?.clientId
            ? occurrencesTracking.client?.tradeName
            : occurrencesTracking.locality?.name,
          flex: 1.2,
        },

        {
          value: occurrencesTracking.occurrenceDate,
          flex: 0.6,
        },
        {
          value: occurrencesTracking.endDate && `${occurrencesTracking.endDate} ${occurrencesTracking.endTime}`,
          subtitle: !occurrencesTracking.endDate ? occurrenceExpectedDate.format('DD/MM/YYYY HH:mm') : '',
          isStrong: !occurrencesTracking.endDate && true,
          flex: 0.8,
        },
        {
          value:
            occurrencesTracking.statusResolution === 'finalizadas' ? 'FINALIZADA' : isOverdue ? 'Atrasado' : 'No Prazo',
          flex: 0.5,
        },
        {
          value:
            occurrencesTracking.statusResolution === 'finalizadas' ? (
              <Status bgColor="#00DFAC" isSquare />
            ) : isOverdue ? (
              <Status bgColor="red" isSquare />
            ) : (
              <Status bgColor="#00DFAC" isSquare />
            ),
          flex: 0.1,
        },
      ];
    });
  }, [occurrencesTracking]);

  const renderTable = useMemo(() => {
    return (
      <TrackingOccurrenceTable
        contentTable={contentTable}
        goToUpdatePage={goToUpdatePage}
        changePage={() => null}
        isLoading={hasLoading}
      />
    );
  }, [contentTable, goToUpdatePage, hasLoading]);

  return <Fragment>{renderTable}</Fragment>;
};

export default PendingOccurrenceList;
