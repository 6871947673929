import React, { MouseEvent, ChangeEvent } from 'react';
import * as S from './modal-assign-plan-view.styled';
import InputFieldPhone from 'components/input-field-phone/input-field-phone';
import InputField from 'components/input-field/input-field';
import CrownIconIntern from 'assets/icons/crown.icon-intern';
import { CheckIcon } from 'assets/icons/check.icon';
import { IAssignPlan } from './modal-assign-plan.types';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';

interface IModalAssintPlanViewProps {
  form: IAssignPlan;
  error: boolean;
  handleSubmit: () => void;
  handleChange: (value: ChangeEvent<HTMLInputElement>) => void;
  handleCloseModal: () => void;
  handleBackgroundClick: (event: MouseEvent<HTMLDivElement>) => void;
}

export const ModalAssintPlanView: React.FC<IModalAssintPlanViewProps> = ({
  form,
  error,
  handleSubmit,
  handleChange,
  handleCloseModal,
  handleBackgroundClick,
}) => {
  return (
    <S.Wrapper onClick={handleBackgroundClick}>
      <S.Container>
        <S.ContainerCloseButton>
          <S.CloseButton onClick={handleCloseModal}>X</S.CloseButton>
        </S.ContainerCloseButton>

        <S.ContainerBody>
          <S.ContainerLeftModal>
            <S.ContainerLogShareText>
              <S.LogShareText>LogShare</S.LogShareText>
              <S.ProText>PRO</S.ProText>
              <CrownIconIntern />
            </S.ContainerLogShareText>

            <S.ContainerDescription>
              <S.ContainerTextDescription>
                <S.Text>
                  Usuários PRO otimizam em até <b>28% seu custo operacional </b>
                  monetizando ociosidade das suas malhas logísticas
                </S.Text>
              </S.ContainerTextDescription>

              <S.ContainerTextList>
                <S.ContainerText>
                  <CheckIcon size={15} />
                  <S.Text>Vendas de ociosidade</S.Text>
                </S.ContainerText>

                <S.ContainerText>
                  <CheckIcon size={15} />
                  <S.Text>Fretes STL e LTL</S.Text>
                </S.ContainerText>

                <S.ContainerText>
                  <CheckIcon size={15} />
                  <S.Text>Tracking</S.Text>
                </S.ContainerText>
              </S.ContainerTextList>
            </S.ContainerDescription>
          </S.ContainerLeftModal>

          <S.ContainerRightModal>
            <S.Text>
              <b>Fale com um Consultor!</b>
            </S.Text>

            <S.ContainerInput>
              <InputField
                label="Nome *"
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                value={form.name}
                hasError={error && !form.name}
                errorMessage="Campo Obrigatório"
              />
              <InputField
                label="Email *"
                id="email"
                name="email"
                type="text"
                upperCase={false}
                onChange={handleChange}
                value={form.email}
                hasError={error && !form.email}
                errorMessage="Campo Obrigatório"
              />
              <InputFieldPhone
                label="Telefone *"
                id="phone"
                name="phone"
                type="text"
                onChange={handleChange}
                value={form.phone}
                hasError={error && !form.phone}
                errorMessage="Campo Obrigatório"
              />
            </S.ContainerInput>

            <S.ContainerButton>
              <Button
                label="Tenho Interesse"
                color={ThemeColorsV2Type.Aqua}
                size="md"
                onPress={handleSubmit}
                variant="solid"
              />
            </S.ContainerButton>
          </S.ContainerRightModal>
        </S.ContainerBody>
      </S.Container>
    </S.Wrapper>
  );
};
