import styled, { css } from 'styled-components';

interface IStyleProps {
  hiddenOverFlow?: boolean;
}

interface ModalBarkStyleProps extends IStyleProps {
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xsm' | 'xxsm';
}

export const ModalBarkWrapper = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: ${theme.modals.background};
    top: 0;
    right: 0;
    z-index: 1000;
    cursor: initial;
  `}
`;

export const ModalBarkContent = styled.section<ModalBarkStyleProps>`
  ${({ theme, size, hiddenOverFlow }) => css`
    width: ${theme.modalsV4.width[size]};
    ${size === 'lg' ? `max-height: ${theme.modalsV4.height[size]}` : `height: ${theme.modalsV4.height[size]}`};
    background-color: ${theme.colorsV2.white};
    border-radius: ${theme.modalsV4.radius};
    padding: ${theme.modalsV4.padding};
    ${hiddenOverFlow ? '' : 'overflow: hidden'};
  `}
`;

export const ModalBarkWrapperChildren = styled.div<IStyleProps>`
  ${({ hiddenOverFlow }) => (hiddenOverFlow ? '' : 'overflow: auto')};
`;

export const ModalBarkHeader = styled.section`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;

export const ModalBarkWrapperTitles = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
`;

export const ModalBarkTitle = styled.span`
  font-size: 13px;
  font-weight: 700;
`;

export const ModalBarkClose = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 1rem;
`;

export const ModalCloseStyled = styled.button`
  width: 25px;
  height: 25px;
  border-style: hidden;
  background-color: transparent;
  cursor: pointer;
`;
