/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import VehicleRegisterFormView from './vehicle-register-form-view';
import { VehicleRegisterSchema } from './schemas-validation/schemas-validation';
import {
  PATH_TO_VEHICLE_LIST,
  initialValues,
  optionsVehicleType,
  optionsVehicleCategory,
  optionsVehicleTracker,
  optionsVehicleYear,
  optionsVehicleSensors,
  optionsVehicleActuators,
  optionsFleetType,
  optionsFuel,
} from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { shippingCompanyFilterSearchService } from 'pages/vehicle/services/shipping-search/shipping.filter-search.service';
import { SelectItem } from 'pages/vehicle/services/entities/select-items.entity';
import { useVehicleRegisterHook } from 'pages/vehicle/hooks/use-vehicle-register/use-vehicle-register.hook';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const VehicleRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const vehicleRegisterHook = useVehicleRegisterHook();

  const goBackToVehicleList = useCallback(() => navigate(PATH_TO_VEHICLE_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (vehicle: any) => {
      setIsLoading(true);
      vehicleRegisterHook(vehicle, vehicle.uploadedList)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });
          goBackToVehicleList();
        })
        .catch((error) =>
          showMessageFormatted({
            error: error,
            type: 'error',
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    },
    [goBackToVehicleList, vehicleRegisterHook],
  );

  const apiCallShipping = async (searchValue: string): Promise<SelectItem[]> => {
    if (searchValue) {
      return shippingCompanyFilterSearchService(searchValue);
    }
    return [];
  };

  return (
    <VehicleRegisterFormView
      vehicleRegisterValues={initialValues}
      optionsFuel={optionsFuel}
      optionsVehicleType={optionsVehicleType}
      optionsVehicleCategory={optionsVehicleCategory}
      optionsVehicleSensors={optionsVehicleSensors}
      optionsVehicleActuators={optionsVehicleActuators}
      optionsVehicleTracker={optionsVehicleTracker}
      optionsVehicleYear={optionsVehicleYear}
      optionFleetType={optionsFleetType}
      handleGoBack={goBackToVehicleList}
      handleRegister={handleSubmit}
      shippingApiCall={apiCallShipping}
      schemaValidation={VehicleRegisterSchema}
      isLoading={isLoading}
    />
  );
};

export default VehicleRegisterForm;
