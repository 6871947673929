import { vehicleReportExcelService } from 'pages/vehicle/services/vehicle-report-excel/vehicle-report-excel.service';
import { IVehicleStatus } from 'domain/vehicle';

export interface IVehicleReportExcelProps {
  search: string;
  status: IVehicleStatus | undefined;
}

const useReportExcelHook = () => {
  return async (params: IVehicleReportExcelProps): Promise<BlobPart | undefined> => {
    return await vehicleReportExcelService(params);
  };
};

export { useReportExcelHook };
