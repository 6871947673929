import {
  IKanbamItems,
  IScheduleKanbanDetails,
  IScheduleKanbanListDomain
} from '../../../../domains/schedule-kanban-list/schedule-kanban.domain';
import {IScheduleKanbanListResponse} from '../../get-kanban-list.types';
import {convertVehicleType} from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import {VehicleTypeResponse} from 'src-new/domains/vehicle-type.domain';
import {convertStatusCode} from 'utils/status-code';

const converterKanbanListDomain = (itens: Array<IScheduleKanbanDetails>) => {
  return itens.map((item) => {
    return {
      ...item,
      statusCode: convertStatusCode(item.statusCode),
      vehicle: convertVehicleType(item.vehicle as VehicleTypeResponse)
    }
  })
}

export const converterKanbanListResponseToDomain = (
  kanbanListResponse: IScheduleKanbanListResponse,
): IScheduleKanbanListDomain => {
  const convertSection = (section: IKanbamItems) => ({
    ...section,
    items: converterKanbanListDomain(section.items)
  });

  return {
    inAllocation: convertSection(kanbanListResponse.inAllocation),
    toStart: convertSection(kanbanListResponse.toStart),
    inProgress: convertSection(kanbanListResponse.inProgress),
    finished: convertSection(kanbanListResponse.finished),
    canceled: convertSection(kanbanListResponse.canceled),
  };
};
