import styled from 'styled-components';

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 10px;
`;
export const DropDownStyles = styled.div`
  margin: 0 10px;
`;

export const NewFreight = styled.div`
  margin-left: 30px;
`;