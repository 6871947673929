import dayjs from 'dayjs';

export const convertOccurrenceExpectedDate = (
  endDate: string,
  endTime: string,
  sla: string,
  occurrenceTime: string,
  occurrenceDate: string,
): string => {
  if (endDate !== '') {
    return `${endDate} ${endTime}`;
  }
  const slaTime = Number(sla.replace(/ horas?/, ''));

  const occurrenceExpectedTime = dayjs(occurrenceTime, 'HH:mm');

  return dayjs(occurrenceDate, 'DD/MM/YYYY')
    .add(occurrenceExpectedTime.hour(), 'hours')
    .add(occurrenceExpectedTime.minute(), 'minutes')
    .add(slaTime, 'hours')
    .format('DD/MM/YYYY HH:mm');
};
