export const removeInputMaskDomainToEntity = (inputMaks: string): string => {
  return `${inputMaks.replace(/\D/g, '')}`;
};

export const InputMaskCPFEntityToDomain = (inputMaks: string): string => {
  return inputMaks
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const InputMaskRGEntityToDomain = (inputMaks: string): string => {
  return inputMaks
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})(\d{3})(\d)/, '$1.$2.$3')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
};
