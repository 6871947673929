import styled, { css } from 'styled-components';

export const WrapperSection = styled.section`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.forBorder};

    border-radius: 5px;
    border: 1px solid ${theme.colors.forBorder};

    padding: 1rem;
    height: 14rem;
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.3rem;

    border-bottom: 1px solid ${theme.colors.forBorder};
    padding-bottom: 15px;
    margin-bottom: 15px;
  `}
`;

export const WrapperHeaderItensRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const WrapperInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
`;

export const Info = styled.p`
  font-size: 12px;
  color: gray;
  font-weight: 700;
  padding: 5px 0;
`;
