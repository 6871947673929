import styled, { keyframes, css } from 'styled-components';

interface IWalletBoxProps {
  color?: 'white' | 'lightgray';
  isLarge?: boolean;
  isSmall?: boolean;
}

const animate = keyframes`
    0%{
        transform: translateX(100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const ContentBoxContainerStyled = styled.div<IWalletBoxProps>`
  ${({ theme, color = 'white', isLarge, isSmall }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: ${isLarge ? '90px' : '100px'};
    background-color: ${!!color && theme.colors[color]};
    color: #000;
    border: none;
    ${color === 'white' && `border: solid 1px ${theme.colors.forBorder}`};

    border-radius: 7px;
    padding: 15px;
    word-wrap: break-word;
    animation: ${animate} 0.5s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${isSmall
      ? css`
          height: 75px;
          padding: 10px;
        `
      : ''}

    @media (max-width: 600px) {
      width: 100%;
    }
  `}
`;

export const ContainerValuesAndIconStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ValuesContainerStyled = styled.div<IWalletBoxProps>`
  ${({ isSmall }) => css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    word-wrap: break-word;

    ${isSmall
      ? css`
          gap: 3px;
        `
      : ''}
  `}
`;

export const TitleStyled = styled.span`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: ${theme.font.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ValueStyled = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: ${theme.font.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContainerIconStyled = styled.div``;

export const ContainerSubtitleStyled = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
`;

export const SubTitleStyled = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: ${theme.font.semiBold};
    word-wrap: break-word;
  `}
`;

export const PercentValueStyled = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: ${theme.font.semiBold};
    color: #7b7b7b;
    word-wrap: break-word;
  `}
`;
