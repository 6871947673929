import { ICompanyTypeEntity } from 'pages/company/services/entities/company.entity';
import { ICompanyType } from 'domain/company';

export const convertTypeEntityToDomainMapper = (typeEntity: ICompanyTypeEntity): ICompanyType => {
  switch (typeEntity) {
    case 'shipper':
      return 'Embarcador';
    case 'shipping company':
      return 'Transportadora';
    case 'condominium':
      return 'Condomínio';
    default:
      return 'Embarcador';
  }
};
