import { useCallback, useContext } from 'react';

import { Hook } from './use-resume-rules.types';

import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';

const useResumeRules: Hook = () => {
  const { setResumeRules } = useContext(AllocationRegisterContext);

  return useCallback(
    ({ infoRules }) => {
      setResumeRules(infoRules);
    },
    [setResumeRules],
  );
};

export { useResumeRules };
