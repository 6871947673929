import styled, { css } from 'styled-components';

interface IStyledText {
  fontSize?: number;
  color?: string;
}

interface IStyledWrapperBody {
  verifyTariff: boolean;
}

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: #f3f3f3;
  border: 1px solid #d9d9d9;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
`;

export const Strong = styled.strong`
  font-size: 0.85rem;
`;

export const Text = styled.p<IStyledText>`
  ${({ fontSize = 0.85, color = '#000' }) => css`
    font-size: ${fontSize}rem;
    color: ${color};
  `}
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1rem;
`;

export const WrapperHeaderShipperInfo = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const WrapperHeaderDateInfo = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const WrapperBody = styled.div<IStyledWrapperBody>`
  ${({ verifyTariff }) => css`
    ${verifyTariff &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  `}
`;

export const WrapperTariffs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
