/* eslint-disable react/prop-types */
import { memo } from 'react';

import * as S from './styled';

import { offeredPallets, offeredWeight } from 'utils-v2/converters/capacity';
import { convertCurrencyFormat } from 'utils/convert-to-currency-format/convert-to-currency-format';
import { IdlenessValues } from 'domain/idleness';
import { convertVehicleCategoryTypeToName, convertVehicleTypeTypeToName } from 'domain/convert';

interface Props {
  idleness: IdlenessValues;
}

const HeaderModalMatchList: React.FC<Props> = ({ idleness }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ContainerInfos flex="0"></S.ContainerInfos>

        <S.ContainerInfos flex="2">
          <S.WrapperLabelInfo>
            <S.WrapperInfos>
              <S.LabelBoldInfo>Origem</S.LabelBoldInfo>

              <S.LabelBold>
                {idleness.originCity}
                {' - '}
                {idleness.originUf}
              </S.LabelBold>

              <S.Label>{idleness.originName}</S.Label>
            </S.WrapperInfos>
          </S.WrapperLabelInfo>
        </S.ContainerInfos>

        <S.ContainerInfos flex="1.6">
          <S.WrapperLabelInfo>
            <S.WrapperInfos>
              <S.LabelBoldInfo>Destino</S.LabelBoldInfo>

              <S.LabelBold>
                {idleness.destinyCity}
                {' - '}
                {idleness.destinyUf}
              </S.LabelBold>

              <S.Label>{idleness.destinyName}</S.Label>
            </S.WrapperInfos>
          </S.WrapperLabelInfo>
        </S.ContainerInfos>

        <S.ContainerInfos flex="0.9">
          <S.LabelBoldInfo>Veículo</S.LabelBoldInfo>

          <S.LabelBold>{convertVehicleTypeTypeToName(idleness.vehicleType)}</S.LabelBold>

          <S.Label>{convertVehicleCategoryTypeToName(idleness.vehicleCategory)}</S.Label>
        </S.ContainerInfos>

        <S.ContainerInfos flex="0.8">
          <S.LabelBoldInfo>Tarifa Teto</S.LabelBoldInfo>

          <S.LabelBold>{convertCurrencyFormat.format(+idleness.freightValue)}</S.LabelBold>

          <S.Label>+ IMPOSTOS</S.Label>
        </S.ContainerInfos>

        <S.ContainerInfos flex="0.8">
          <S.LabelBoldInfo>Capacidade</S.LabelBoldInfo>

          <S.LabelBold>{offeredWeight(idleness.offeredWeight)}</S.LabelBold>

          <S.Label>{offeredPallets(idleness.offeredPallet)}</S.Label>
        </S.ContainerInfos>

        <S.ContainerInfos flex="1.4">
          <S.LabelBoldInfo>Frequência</S.LabelBoldInfo>

          <S.LabelBold>{idleness.frequency}</S.LabelBold>

          <S.Label>VIAGENS/MÊS</S.Label>
        </S.ContainerInfos>
      </S.Container>
    </S.Wrapper>
  );
};

export default memo(HeaderModalMatchList);
