import { useCallback, useReducer } from 'react';
import { ISaleOfferListDownloadReport } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/types/sale-offer-list-download-report.types';
import { saleOfferListDownloadReportReducer } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/reducers/sale-offer-list-download-report/sale-offer-list-download-report.reducer';
import { saleOfferInitialState } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.constants';
import { SaleOfferListDownloadReportActionType } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.action';

export const useSaleOfferListDownloadReportContextValues = (): ISaleOfferListDownloadReport => {
  const [state, dispatch] = useReducer(saleOfferListDownloadReportReducer, saleOfferInitialState);

  const setSaleListDownloadReportRequest = useCallback(() => {
    dispatch({
      type: SaleOfferListDownloadReportActionType.SALE_OFFER_LIST_DOWNLOAD_REPORT_REQUEST,
      payload: undefined,
    });
  }, []);

  const setSaleListDownloadReportSuccess = useCallback(() => {
    dispatch({
      type: SaleOfferListDownloadReportActionType.SALE_OFFER_LIST_DOWNLOAD_REPORT_SUCCESS,
      payload: undefined,
    });
  }, []);

  const setSaleListDownloadReportError = useCallback(() => {
    dispatch({
      type: SaleOfferListDownloadReportActionType.SALE_OFFER_LIST_DOWNLOAD_REPORT_ERROR,
      payload: undefined,
    });
  }, []);

  const setSaleListDownloadReportReset = useCallback(() => {
    dispatch({
      type: SaleOfferListDownloadReportActionType.SALE_OFFER_LIST_DOWNLOAD_REPORT_RESET,
      payload: undefined,
    });
  }, []);

  return {
    ...state.saleOfferListDownloadReport,
    setSaleListDownloadReportRequest,
    setSaleListDownloadReportSuccess,
    setSaleListDownloadReportError,
    setSaleListDownloadReportReset,
  };
};
