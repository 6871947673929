import React, {Fragment} from 'react';
import {Tab} from 'src-new/components/tab/tab.component';
import {ITabContent} from 'src-new/components/tab/tab.types';
import {
  ScheduleOfferModalMatchTable
} from 'pages-v2/schedule/components/modals/modal-match/components/purchase-offer-modal-match-table-tabs/components/purchase-offer-modal-match-table/modal-match-table.component';

export interface IScheduleOfferModalMatchTableTabsViewProps {
  tabsContent: Array<ITabContent>;
  saleOffersMatches: Array<any>
  activeTab: string;
  handleMatchBuy: (backhaul: any) => void
  handleChangeTab: (tabName: string) => void;
}

export const ScheduleOfferModalMatchTableTabsView: React.FC<IScheduleOfferModalMatchTableTabsViewProps> = ({
                                                                                                             tabsContent,
                                                                                                             saleOffersMatches,
                                                                                                             activeTab,
                                                                                                             handleMatchBuy,
                                                                                                             handleChangeTab,
                                                                                                           }) => {
  return (
    <Fragment>
      <Tab tabsContent={tabsContent} tabActive={activeTab} setActiveTabName={(name) => handleChangeTab(name)}/>
      <ScheduleOfferModalMatchTable saleOffersMatches={saleOffersMatches} handleMatchBuy={handleMatchBuy}/>
    </Fragment>
  );
};
