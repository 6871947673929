import { FC, ReactNode } from 'react';

import * as S from './styled';

interface DrawerBarkerProps {
  title: string;
  handleClose: () => void;
  children: ReactNode;
}

export const DrawerBarker: FC<DrawerBarkerProps> = ({ title, handleClose, children }) => {
  return (
    <S.DrawerBarkerWrapper>
      <S.DrawerBarkerContent>
        <S.DrawerBarkerHeader>
          <S.DrawerBarkerWrapperTitles>
            <S.DrawerBarkerTitle>{title}</S.DrawerBarkerTitle>
          </S.DrawerBarkerWrapperTitles>

          <S.DrawerBarkerClose onClick={handleClose}>X</S.DrawerBarkerClose>
        </S.DrawerBarkerHeader>
        {children}
      </S.DrawerBarkerContent>
    </S.DrawerBarkerWrapper>
  );
};
