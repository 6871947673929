import { ThemeProvider, styled } from '@mui/material/styles';
import { DateOrTimeView, DateTimeValidationError, PickersDay, PickersDayProps, TimeView } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { useCallback, useMemo, useState } from 'react';
import {
  ContainerStyled,
  DateTimePikerStyled,
  LabelStyled,
  MessageErrorStyled,
  appTheme,
  customStyles,
} from './styled';

interface DateTimePikersParams {
  label: string;
  placeholder?: string;
  disablePast?: boolean;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  disabled?: boolean;
  value?: dayjs.Dayjs | null | undefined;
  paintDays?: boolean;
  format: string;
  views?: DateOrTimeView[];
  onChange?: (date: dayjs.Dayjs | null) => void;
  onOpen?: () => void;
  onError?: (error: DateTimeValidationError, value?: dayjs.Dayjs) => void;
  shouldDisableTime?: (value: Dayjs, view: TimeView) => boolean;
  errorMessage?: string;
  validateField?: boolean;
}

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  paintDays: boolean;
  daysToPaint: Array<{ date: Dayjs }>;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ isSelected }) => ({
  borderRadius: '20%',

  ...(isSelected && {
    backgroundColor: '#1F2B4F',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#1F2B4F',
      color: '#fff',
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }
  return dayA.isSame(dayB, 'days');
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
    paintDays: boolean;
    daysToPaint: Array<{ date: Dayjs }>;
  },
) {
  const { day, selectedDay, ...other } = props;
  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.3, color: dayjs(day).isBefore(selectedDay) ? '#5858588d' : '' }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
    />
  );
}

export const DateTimePikers = ({
  label,
  placeholder,
  disablePast,
  minDate,
  maxDate,
  disabled = false,
  value,
  paintDays,
  format,
  views = ['year', 'month', 'day', 'hours', 'minutes'],
  shouldDisableTime,
  onChange,
  onOpen,
  onError,
  errorMessage,
  validateField = false,
}: Readonly<DateTimePikersParams>) => {
  const [hovered, setHovered] = useState<Dayjs | null>(null);
  const [invalidDate, setInvalidDate] = useState<string | null>(null);

  const messageFormated = useMemo(() => {
    if (errorMessage) {
      return errorMessage;
    }
    if (invalidDate) {
      return 'Campo Inválido';
    }
  }, [invalidDate, errorMessage]);

  const handleError = useCallback((error: DateTimeValidationError) => {
    if (onError) {
      onError(error);
      setInvalidDate(error);
    }
  }, []);

  return (
    <div>
      <ContainerStyled>
        <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
          <LabelStyled>{label}</LabelStyled>
          <DateTimePikerStyled>
            <ThemeProvider theme={appTheme}>
              <DateTimePicker
                dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}.`}
                sx={customStyles(disabled)}
                ampm={false}
                views={views}
                format={format}
                disabled={disabled}
                onChange={onChange}
                onOpen={onOpen}
                disablePast={disablePast}
                minDateTime={minDate}
                maxDateTime={maxDate}
                shouldDisableTime={shouldDisableTime}
                value={value}
                slots={{
                  day: (props) =>
                    Day({
                      ...props,
                      paintDays: !!paintDays,
                      daysToPaint: [
                        { date: dayjs() },
                        { date: dayjs().add(1, 'day') },
                        { date: dayjs().add(2, 'day') },
                      ],
                    }),
                }}
                slotProps={{
                  textField: {
                    inputProps: {
                      placeholder: placeholder,
                    },
                    focused: false,
                    size: 'small',
                    placeholder: ' ',
                    error: validateField && !!messageFormated,
                  },
                  day: (ownerState) => ({
                    selectedDay: value,
                    hovered,
                    onPointerEnter: () => setHovered(ownerState.currentMonth),
                    onPointerLeave: () => setHovered(null),
                  }),
                }}
                localeText={{
                  fieldHoursPlaceholder: () => 'HH',
                  fieldMinutesPlaceholder: () => 'MM',
                  fieldDayPlaceholder: () => 'DD',
                  fieldMonthPlaceholder: () => 'MM',
                  fieldYearPlaceholder: () => 'AA',
                }}
                onError={(error) => handleError(error)}
              />
            </ThemeProvider>
          </DateTimePikerStyled>
        </LocalizationProvider>
      </ContainerStyled>
      {messageFormated && validateField && <MessageErrorStyled>{messageFormated}</MessageErrorStyled>}
    </div>
  );
};
