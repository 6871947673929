import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { useQueryClient } from 'react-query';
import { updateScheduleOriginAndDestinationDetailsService } from '../../services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.service';
import { IScheduleOriginAndDestinationPayload } from '../../services/update-schedule-origin-and-destination-details/update-schedule-origin-and-destination-details.types';

export const useUpdateScheduleOriginAndDestinationDetails = () => {
  const { id } = useParams<{ id: string }>();
  const scheduleId = useMemo(() => id ?? '', [id]);
  const reactQueryClient = useQueryClient();

  return useCallback(
    async (payloadDomain: IScheduleOriginAndDestinationPayload) => {
      try {
        await updateScheduleOriginAndDestinationDetailsService(scheduleId, payloadDomain);

        showMessageFormatted({
          message: 'Dados atualizados com sucesso.',
          type: 'success',
        });

        reactQueryClient.invalidateQueries(['schedule-details-by-id']);
      } catch (error) {
        showMessageFormatted({
          message: 'Erro ao atualizar a origem ou destino',
          type: 'error',
        });
      }
    },
    [reactQueryClient, scheduleId],
  );
};
