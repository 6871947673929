import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'state/user-context';
import { ModalNewFreightView } from './modal-new-freight-view.component';

interface IModalNewFreightProps {
  handleClose: () => void;
}
export const ModalNewFreight: FC<IModalNewFreightProps> = ({ handleClose }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const handleNavigate = useCallback((url: string) => navigate(url), [navigate]);

  const isEssencial = useMemo((): boolean => user?.plan === 'basic-buyer' || user?.plan === 'basic-seller', [user]);

  return <ModalNewFreightView isEssencial={isEssencial} handleNavigate={handleNavigate} handleClose={handleClose} />;
};
