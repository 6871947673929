export interface Output {
  label: string;
  value: number;
  category?: string;
  type?: string;
}

export interface Input {
  id: number;
  main_board: string;
  second_board: string;
  company_id: number;
  vehicle_category: string;
  vehicle_type: string;
}

export const convertVehicleToSelectItem = (params: Input): Output => {
  const secondPlate = params.second_board ? `- ${params.second_board}` : '';
  return {
    label: `${params.main_board?.toUpperCase()} ${secondPlate.toUpperCase()}`,
    value: params.id,
    category: params.vehicle_category,
    type: params.vehicle_type,
  };
};
