import styled, { css } from 'styled-components';

export const ResetPasswordContainerStyled = styled.div``;

export const TextResetPasswordStyled = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    cursor: pointer;
    border-bottom: 1px solid #000;
    font-family: ${theme.font.family};

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: font-size: ${theme.font.semiBold};
      padding-right: 20px;
    }
  `}
`;
