/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { IFormScheduleInitialState } from 'pages/schedule/types/form-section.types';
import { IUpdateSchedule } from 'domain/scheduling';

export const convertScheduleLTLToEntity = (schedule: any): any => ({
  originId: schedule.origin.clientId || schedule.origin.localityId,
  originType: schedule.origin.clientType,
  destinyId: schedule.destiny.clientId || schedule.destiny.localityId,
  destinyType: schedule.destiny.clientType,
  capacity: Number.parseFloat(schedule.cargoDetails.capacity),
  merchValue: schedule.cargoDetails.shippingValue,
  weight: Number.parseFloat(schedule.cargoDetails.weight),
  cubage: Number.parseFloat(schedule.cargoDetails.cubage || '0'),
  selectedFreight: schedule.selectedFreight,
  inPallet: schedule.cargoDetails.inPalet,
  pallet: schedule.cargoDetails.inPalet === 'yes' ? Number.parseFloat(schedule.cargoDetails.pallet) : 0,
  pickUpDate: schedule.cargoDetails.pickUpDate,
  deliveryDate: schedule.cargoDetails.deliveryDate,
  password: schedule.cargoDetails.password,
  shipmentNumber: schedule.cargoDetails.shipmentNumber,
  invoice: schedule.cargoDetails.invoice,
  orderNumber: schedule.cargoDetails.orderNumber,
});

export const convertScheduleToEntityUpdate = (schedule: IFormScheduleInitialState): IUpdateSchedule => ({
  id: schedule.id ?? 0,
  cubagem: '',
  scheduleClientOrigin: {
    id: schedule.origin.id,
    clientId: schedule.origin.clientId ?? undefined,
    clientType: schedule.origin.clientType ?? '',
    localityType: schedule.origin.localityType ?? '',
    localityId: schedule.origin.localityId ?? undefined,
  },
  scheduleClientDestiny: {
    id: schedule.destiny.id,
    clientId: schedule.destiny.clientId ?? undefined,
    clientType: schedule.destiny.clientType ?? '',
    localityType: schedule.destiny.localityType ?? '',
    localityId: schedule.destiny.localityId ?? undefined,
  },
  scheduleCargo: {
    id: schedule.cargoDetails.id,
    weight: Number(schedule.cargoDetails.weight) ?? 0,
    capacity: Number(schedule.cargoDetails.capacity) ?? 0,
    shippingValue: schedule.cargoDetails.shippingValue,
    inPalet: schedule.cargoDetails.inPalet === 'yes' ? true : false,
    pallet: Number(schedule.cargoDetails.pallet) ?? 0,
    vehicleType: schedule.cargoDetails.vehicleType ?? '',
    vehicleCategoryType: schedule.cargoDetails.vehicleCategoryType ?? '',
    shippingReturn: schedule.cargoDetails.shippingReturn ?? '',
    operationType: schedule.cargoDetails.operationType ?? '',
    needHelper: schedule.cargoDetails.needHelper ?? '',
    needBodyGuard: schedule.cargoDetails.needBodyGuard === 'yes' ? true : false,
    invoice: schedule.cargoDetails.invoice,
    orderNumber: schedule.cargoDetails.orderNumber,
  },
  scheduleDetails: {
    id: schedule.schedulingDetails.id,
    levyInitialDate: moment(schedule.schedulingDetails.levyInitialDate, 'DD/MM/YYYY').toDate(),
    levyEndDate: moment(schedule.schedulingDetails.levyEndDate, 'DD/MM/YYYY').toDate(),
    levyInitialTime: schedule.schedulingDetails.levyInitialTime,
    levyEndTime: schedule.schedulingDetails.levyEndTime,
    deliveryInitialDate: moment(schedule.schedulingDetails.deliveryInitialDate, 'DD/MM/YYYY').toDate(),
    deliveryEndDate: moment(schedule.schedulingDetails.deliveryEndDate, 'DD/MM/YYYY').toDate(),
    deliveryInitialTime: schedule.schedulingDetails.deliveryInitialTime,
    deliveryEndTime: schedule.schedulingDetails.deliveryEndTime,
    internalNumber: schedule.schedulingDetails.internalNumber,
    ocInternalNumber: schedule.schedulingDetails.ocInternalNumber,
    boardingNumber: schedule.schedulingDetails.boardingNumber,
    schedulingPassword: schedule.schedulingDetails.schedulingPassword,
  },
  status: schedule.status ?? '',
  statusCode: schedule.statusCode ?? '',
});
