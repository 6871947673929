import React from 'react';

import FreightVehicleSectionView from './freight-order-details-section-view';

import {
  IAllocationCargo,
  IAllocationShippingDetails,
  ICurrentAllocation,
} from 'pages/allocation/contexts/allocation-register/allocation.types';

interface IProps {
  freightValues?: ICurrentAllocation;
  values?: IAllocationCargo;
  statusCodeValues?: string;
  idSheduleValues?: string;
  type: 'ltl' | 'ftl' | 'stl' | '';
  occurrenceStartTime?: string;
  OccurrenceTime?: string;
  scheduleCargo?: IAllocationShippingDetails;
  occurrenceStatus?: string;
}

const FreightOrderDetailsSection: React.FC<IProps> = ({
  values,
  statusCodeValues,
  idSheduleValues,
  type,
  OccurrenceTime,
  scheduleCargo,
  occurrenceStatus,
  occurrenceStartTime,
  freightValues,
}) => {
  return (
    <FreightVehicleSectionView
      freightValues={freightValues}
      orderDetails={values}
      statusCodeValue={statusCodeValues}
      idSheduleValues={idSheduleValues}
      type={type}
      OccurrenceTime={OccurrenceTime}
      scheduleCargo={scheduleCargo}
      occurrenceStatus={occurrenceStatus}
      occurrenceStartTime={occurrenceStartTime}
    />
  );
};

export default React.memo(FreightOrderDetailsSection);
