import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITabContent } from 'src-new/components/tab/tab.types';
import { PurchaseOfferModalMatchTableTabsView } from './purchase-offer-modal-match-table-tabs-view.component';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { useGetPurchaseOfferMatchList } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/hooks/use-purchase-offer-match-list/use-purchase-offer-match-list.hook';
import { convertPurchaseToModalBackhaulPurchaseMapper } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/mappers/convert-purchase-to-modal-match/convert-purchase-to-modal-match.mapper';
import { useGetFavoriteMatchesKanbanList } from 'src-new/pages/opportunities/pages/matches/pages/matches-list/hooks/use-get-favorite-matches-kanban-list/use-get-favorite-matches-kanban-list.hook';
import { ModalBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/modal-backhaul-purchase.component';

export const PurchaseOfferModalMatchTableTabs: React.FC = () => {
  const { purchaseOfferModals, purchaseOfferMatchList } = useContext(PurchaseOfferContext);
  const { isOpen, modalType, secondModalType, setModalSecondaryClose } = purchaseOfferModals;
  const { purchaseOfferMatchListSelected, purchaseOfferMatchProposal } = purchaseOfferMatchList;
  const { selectedMatch } = purchaseOfferMatchListSelected;
  const { filters } = purchaseOfferMatchList;
  const { selectedFilter, setSelectFilters } = filters;

  const [activeTab, setActiveTab] = useState<string>('Backhaul Externo');
  const getPurchaseOfferMatchListHook = useGetPurchaseOfferMatchList();
  const getFavoriteMatchesKanbanListHook = useGetFavoriteMatchesKanbanList();

  const handleCloseModalMatchBuy = useCallback(() => {
    setModalSecondaryClose();
  }, [setModalSecondaryClose]);

  const handleMatchesTabs = useCallback(
    (name: string) => {
      setActiveTab(name);
      setSelectFilters({ ...selectedFilter, backhaulType: name === 'Backhaul Interno' ? 'INTERNO' : 'EXTERNO' });
    },
    [selectedFilter, setSelectFilters],
  );

  const tabsContent = useMemo((): Array<ITabContent> => {
    return [
      {
        name: 'Backhaul Externo',
        content: <Fragment />,
      },
      {
        name: 'Backhaul Interno',
        content: <Fragment />,
      },
    ];
  }, []);

  useEffect(() => {
    getPurchaseOfferMatchListHook();
    getFavoriteMatchesKanbanListHook();
  }, [getPurchaseOfferMatchListHook, getFavoriteMatchesKanbanListHook]);

  const renderModalMatchBuy = useMemo(() => {
    if (isOpen && modalType === 'purchase_offer_match' && secondModalType === 'purchase_offer_match_buy')
      return (
        <ModalBackhaulPurchase
          backhaulPurchase={convertPurchaseToModalBackhaulPurchaseMapper(
            selectedMatch,
            purchaseOfferMatchProposal.proposal,
          )}
          handleClose={handleCloseModalMatchBuy}
        />
      );
  }, [
    handleCloseModalMatchBuy,
    isOpen,
    modalType,
    purchaseOfferMatchProposal.proposal,
    secondModalType,
    selectedMatch,
  ]);

  return (
    <Fragment>
      {renderModalMatchBuy}
      <PurchaseOfferModalMatchTableTabsView
        tabsContent={tabsContent}
        activeTab={activeTab}
        handleChangeTab={handleMatchesTabs}
      />
    </Fragment>
  );
};
