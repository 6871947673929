import React from 'react';

import {
  FormikStyled,
  FormStyled,
  SectionStyled,
  WrapperSectionResetPasswordStyled,
  LoginMicrisoftButtonContainerStyled,
} from '../login-microsoft-body/login-microsoft.styled';

import InputField from 'components/input-field/input-field';
import Button from 'components/button/button';
import { ILoginMicrsoft } from '../../../../types/login-microsof.types';

type LoginMicrasoftFormProps = {
  initialValues: ILoginMicrsoft;
  validationSchema: object;
  handleSubmit: (values: ILoginMicrsoft) => void;
  goBackToLogin: () => void;
};

const LoginMicrasoftFormForm: React.FC<LoginMicrasoftFormProps> = ({
  initialValues,
  validationSchema,
  handleSubmit,
  goBackToLogin,
}) => {
  return (
    <FormikStyled
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values as ILoginMicrsoft);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <FormStyled>
          <SectionStyled name="Informações de Reset de Senha">
            <WrapperSectionResetPasswordStyled>
              <InputField
                label="E-mail"
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                hasError={!!errors.email && !!touched.email}
                errorMessage={String(errors.email)}
                upperCase={false}
              />
            </WrapperSectionResetPasswordStyled>
          </SectionStyled>
          <LoginMicrisoftButtonContainerStyled>
            <Button bgColor="white" title="Voltar" callback={goBackToLogin} size="large" />
            <Button bgColor="blue" title="Enviar" callback={handleSubmit} size="large" />
          </LoginMicrisoftButtonContainerStyled>
        </FormStyled>
      )}
    </FormikStyled>
  );
};

export default LoginMicrasoftFormForm;
