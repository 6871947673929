import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { FormikProps } from 'formik';

import { optionsFleetType, optionsHelper, optionsOperationType, optionsPallet } from '../initial-values/options';
import { PlanningRoute } from '../components/planning-route';
import MyRouteOriginAndDestinyForm from '../components/origin-and-destiny';

import { FormStyled } from './styled';

import { UserContext } from 'state/user-context';
import { MyRouterRegisterInput } from 'domain-v2/my-route/register';
import { SectionStyled } from 'components-v2/layout/section/styled';
import { LineSectionStyled } from 'components-v2/layout/line-section/styled';
import { FooterPage } from 'components-v2/common/footer-page';
import ToggleCheckboxView from 'components/toggle-checkbox/toogle-checkbox-view';
import Select from 'components/select/select';
import SectionDivider from 'components/section-divider/section-divider';
import InputField from 'components/input-field/input-field';

export const InnerForm = (props: FormikProps<MyRouterRegisterInput>) => {
  const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = props;
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  return (
    <FormStyled>
      <SectionStyled name="Informações da Rota">
        <LineSectionStyled columns="0.3fr 0.49fr 1fr 1fr 0.65fr 0.85fr 0.65fr">
          <InputField label="ID" id="id" name="id" value={values?.id} type="text" disabled />

          <InputField
            label="ID Rota"
            id="internalCode"
            name="internalCode"
            type="text"
            onChange={handleChange}
            value={values.internalCode}
          />

          <Select
            label="Tipo de Operação"
            id="operationType"
            name="operationType"
            value={values.operationType}
            setFieldValue={setFieldValue}
            hasError={!!errors.operationType && !!touched.operationType}
            errorMessage={String(errors.operationType)}
            options={optionsOperationType}
          />

          <Select
            label="Tipo de Frota"
            id="processType"
            name="processType"
            value={values.processType}
            setFieldValue={setFieldValue}
            hasError={!!errors.processType && !!touched.processType}
            errorMessage={String(errors.processType)}
            options={optionsFleetType}
          />

          <InputField
            label="Leadtime (em dias)"
            id="leadTime"
            name="leadTime"
            type="number"
            onChange={handleChange}
            value={values.leadTime}
          />

          <Select
            label="Requer Ajudante?"
            id="helper"
            name="helper"
            value={values.helper}
            setFieldValue={setFieldValue}
            hasError={!!errors.helper && !!touched.helper}
            errorMessage={String(errors.helper)}
            options={optionsHelper}
          />

          <Select
            label="Carga Paletizada?"
            id="paletizado"
            name="paletizado"
            value={values.paletizado}
            setFieldValue={setFieldValue}
            hasError={!!errors.paletizado && !!touched.paletizado}
            errorMessage={String(errors.paletizado)}
            options={optionsPallet}
          />
        </LineSectionStyled>
      </SectionStyled>

      <SectionDivider label="Origem" />
      <MyRouteOriginAndDestinyForm
        type={'origin'}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
      />

      <SectionDivider label="Destino" />
      <MyRouteOriginAndDestinyForm
        type={'destination'}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
      />

      <PlanningRoute
        userName={user?.name || ''}
        values={values}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
      />

      <FooterPage.Root>
        <FooterPage.RightContent>
          <FooterPage.Button label="Salvar" color="pink" onPress={handleSubmit} />
          <FooterPage.Button label="Cancelar" color="white" variant="ghost" onPress={() => navigate(-1)} />
        </FooterPage.RightContent>
        <FooterPage.LeftContent>
          <ToggleCheckboxView
            id="status"
            name="status"
            label="Ativo"
            checked={values.status === 'active'}
            onChange={(e) => setFieldValue('status', e.currentTarget.checked ? 'active' : 'inactive')}
          />
        </FooterPage.LeftContent>
      </FooterPage.Root>
    </FormStyled>
  );
};
