import { IClientsContext } from './types/clients.types';

export const clientsInitialState: IClientsContext = {
  clientsList: {
    statusRequest: 'INITIAL',
    clients: [],
    filterActions: {
      filters: {
        column: 'Nome',
        isPageLimit: false,
        order: 'ASC',
        page: 1,
        searchValue: '',
        status: 'active',
      },
      setFilters: () => undefined,
    },
    setClientsListRequest: () => undefined,
    setClientsListSuccess: () => undefined,
    setClientsListError: () => undefined,
    setClientsListReset: () => undefined,
  },
  setClientsResetContext: () => undefined,
};
