import { Field } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { AlertWorkflowType, IContactAlert } from 'src-new/pages/registrations/domains/contact.domain';
import { Checkbox } from 'src-new/components/checkbox/checkbox.component';
import {
  AlertsContainerStyled,
  ContentContainerStyled,
  FieldContainerStyled,
  HeaderContainerStyled,
  LabelFieldContainerStyled,
} from './contact-registration-alerts-view.styled';

interface IContactRegistrationAlertsViewProps {
  position: number;
  alerts: Array<IContactAlert>;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ContactRegistrationAlertsView: React.FC<IContactRegistrationAlertsViewProps> = ({
  position,
  alerts,
  handleChange,
}) => {
  const formatLabel = useCallback((type: AlertWorkflowType): string => {
    switch (type) {
      case 'AGENDAMENTO':
        return 'Agendamento';
      case 'FATURAMENTO':
        return 'Faturamento';
      case 'OCORRENCIAS':
        return 'Ocorrências';
      default:
        return 'Ordem de Coleta';
    }
  }, []);

  const renderContactsFields = useMemo(() => {
    return alerts.map((contact, index) => {
      const value = contact.isActive;

      return (
        <FieldContainerStyled key={contact.workflow.toLowerCase()}>
          <Field
            id={`contacts[${position}].alerts[${index}].isActive`}
            name={`contacts[${position}].alerts[${index}].isActive`}
            labelColor={'#000000'}
            component={Checkbox}
            onChange={handleChange}
            value={value}
            checked={value}
          />
          <LabelFieldContainerStyled>{formatLabel(contact.workflow)}</LabelFieldContainerStyled>
        </FieldContainerStyled>
      );
    });
  }, [alerts, formatLabel, handleChange, position]);

  return (
    <AlertsContainerStyled>
      <HeaderContainerStyled>Alertas permitidos</HeaderContainerStyled>
      <ContentContainerStyled>{renderContactsFields}</ContentContainerStyled>
    </AlertsContainerStyled>
  );
};
