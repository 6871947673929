import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 55%;
  justify-content: space-between;
`;

export const Main = styled.div`
  z-index: 1050;
  margin-bottom: 10px;
`;

export const WrapperButton = styled.div`
  margin-left: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
