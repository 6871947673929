import React, { ReactElement, useMemo } from 'react';
import * as S from './home-matches-list-view.styled';
import { Table } from 'src-new/components/table/table.component';
import { IInfiniteScrollTable, IRow } from 'src-new/components/table/table.types';
import { IHomeMatchesDomain } from 'src-new/pages/home/pages/home/domains/home-matches/home-matches.domain';
import TooltipComponent from 'components/Tooltip';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { Button, Tag } from 'logshare-ui-kit';
import { DoubleArrowDownBlueIcon } from 'assets/icons/double-arrow-down-blue.icon';
import { convertDecimalFormat } from 'src-new/utils/convert-decimal-format.util';
import { convertCurrencyFormat } from 'src-new/utils/convert-currency-format/convert-currency-format.util';
import { CalendarDays } from 'lucide-react';
import { ModalBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/modal-backhaul-purchase.component';
import { IBackhaulPurchase } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase/modal-backhaul-purchase.types';
import { HotIdeal } from 'assets/icons/hot-ideal';

interface IHomeMatchesListViewProps {
  homeList: Array<IHomeMatchesDomain>;
  modalContent?: IBackhaulPurchase;
  infiniteScrollProps: IInfiniteScrollTable;
  handleOpenBackhaulPurchase: (id: number) => void;
  handleCloseBackhaulPurchase: () => void;
}

export const HomeMatchesListView: React.FC<IHomeMatchesListViewProps> = ({
  homeList,
  modalContent,
  infiniteScrollProps,
  handleOpenBackhaulPurchase,
  handleCloseBackhaulPurchase,
}) => {
  const renderModal = useMemo((): ReactElement | undefined => {
    if (modalContent) {
      return <ModalBackhaulPurchase backhaulPurchase={modalContent} handleClose={handleCloseBackhaulPurchase} />;
    }
  }, [handleCloseBackhaulPurchase, modalContent]);

  const renderRows = useMemo((): Array<IRow> => {
    return homeList.map((items) => {
      return {
        key: items.saleOfferTariffId,
        onClick: () => null,
        valuesColumns: [
          {
            value: (
              <S.WrapperCompanyImage>
                <TooltipComponent
                  title={items.company.name}
                  location="bottom"
                  icon={<S.CompanyImage src={`${items.company.image ?? ''}`} alt={items.company.name} />}
                />
                <S.Text fontSize={10} fontWeight={500} color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  ID {items.saleOfferTariffId}
                </S.Text>
              </S.WrapperCompanyImage>
            ),
          },
          {
            value: (
              <S.ContainerList>
                <S.Text fontWeight={700}>{`${items.origin.city.toUpperCase()} (${
                  items.origin.uf
                }) - ${items.destination.city.toUpperCase()} (${items.destination.uf})`}</S.Text>

                <S.Text color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  {items.vehicle.type} - {items.vehicle.category}
                </S.Text>
              </S.ContainerList>
            ),
          },
          {
            value: (
              <S.ContainerContent>
                <Tag
                  LeftIcon={
                    <S.TagText>
                      <DoubleArrowDownBlueIcon />
                      CO₂
                    </S.TagText>
                  }
                  label={`${convertDecimalFormat.format(Number(items.co2Economy))} KG`}
                  color={ThemeColorsV2Type.Blue}
                  variant="ghost"
                  display="inline-flex"
                  size="lg"
                />
                {items.backhaulPartner && (
                  <S.ContainerTag>
                    <HotIdeal iconSize={18} />
                  </S.ContainerTag>
                )}
              </S.ContainerContent>
            ),
          },
          {
            value: (
              <S.ContainerList>
                <S.Text fontWeight={700}>{convertCurrencyFormat.format(items.values.freightValue)}</S.Text>

                <S.Text color={ThemeColorsV2Type.DarkGray} secondaryLabel>
                  + Impostos
                </S.Text>
              </S.ContainerList>
            ),
          },
          {
            value: (
              <S.ContainerButton>
                <Button
                  label=""
                  color={ThemeColorsV2Type.Blue}
                  size="x-sm"
                  variant="solid"
                  onPress={() => handleOpenBackhaulPurchase(items.saleOfferTariffId)}
                  LeftIcon={<CalendarDays size={20} strokeWidth={2} />}
                />
              </S.ContainerButton>
            ),
          },
        ],
      };
    });
  }, [handleOpenBackhaulPurchase, homeList]);

  return (
    <S.Wrapper>
      {renderModal}
      <Table
        gridColumns="0.8fr 3fr 1.2fr 1.2fr 0.7fr"
        header={[]}
        rows={renderRows}
        config={{ hideHeader: true }}
        height={'calc(100vh - 460px)'}
        infiniteScrollProps={infiniteScrollProps}
      />
    </S.Wrapper>
  );
};
