import React from 'react';

import NegotiationsModalTable from '../negotiations-modal-form/negotiations-modal-form';
import NegotiationsModal from '../../negotiations-modal';

import { IOccurrenceResolution } from 'domain/tracking-occurrence';

interface IProps {
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitModal: (values: IOccurrenceResolution) => void;
}

const NegotiationsModalBodyView: React.FC<IProps> = ({ title, showModal, setShowModal, submitModal }) => {
  return (
    <NegotiationsModal title={title} show={showModal} useCallBackCloseButton={() => setShowModal(!showModal)}>
      <NegotiationsModalTable submitModal={submitModal} />
    </NegotiationsModal>
  );
};

export { NegotiationsModalBodyView };
