import { FC, useCallback, useContext } from 'react';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { ISaleOfferFormRegisterPayloadDomain } from 'src-new/pages/opportunities/pages/sale-offer/pages/sale-offer-register/domains/sale-offer-form-register.domain';
import { SaleOfferFormMountSectionView } from './sale-offer-register-form-mount-section-view.component';
import { useFormikContext } from 'formik';

export const SaleOfferFormMountSection: FC = () => {
  const { values } = useFormikContext<ISaleOfferFormRegisterPayloadDomain>();
  const { saleOfferModals } = useContext(SaleOfferContext);

  const handleOpenOfferShipperTariffModal = useCallback(
    (saleOfferShipper: number) => {
      saleOfferModals.setModalOpen({
        indexes: { saleOfferShipper, saleOfferShipperTariff: undefined },
        modalType: 'offer_shipper_tariff',
      });
    },
    [saleOfferModals],
  );

  return (
    <SaleOfferFormMountSectionView
      values={values}
      handleOpenOfferShipperTariffModal={handleOpenOfferShipperTariffModal}
    />
  );
};
