import React, { useMemo } from 'react';

import TableFreightTableView from './table-freight-list-table-view';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';

interface ISantosPortTableProps {
  contentTable: Array<IContentTable>;
  meta?: PageInfo;
  changePage: (page: number) => void;
  isLoading: boolean;
}

const TableFreightTable: React.FC<ISantosPortTableProps> = ({ contentTable, meta, changePage, isLoading }) => {
  const headerTable: IContentTable = useMemo(() => {
    return [
      {
        value: 'Veículo',
      },
      {
        value: 'Categoria',
      },
      {
        value: 'Valor (R$)',
      },
      {
        value: '(KM) Inicial',
      },
      {
        value: '(KM) Final',
      },
      {
        value: 'Peso Max',
      },
      {
        value: 'Ajudante',
      },
      {
        value: 'Gris (%)',
      },
      {
        value: 'Adv (%)',
      },
      {
        value: 'Leadtime',
        flex: 0.8,
      },
    ];
  }, []);

  return (
    <TableFreightTableView
      headerTable={headerTable}
      contentTable={contentTable}
      meta={meta}
      changePage={changePage}
      isLoading={isLoading}
    />
  );
};

export default TableFreightTable;
