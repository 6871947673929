import { FC, useCallback, useContext, useMemo } from 'react';
import { ConfirmDriverView } from './confirm-driver-modal-view.component';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { useUpdateScheduleAllocation } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/hooks/use-update-schedule-allocation/use-update-schedule-allocation.hook';

export const ConfirmDriverModal: FC = () => {
  const { scheduleDetails } = useContext(ScheduleContext);
  const updateScheduleAllocationHook = useUpdateScheduleAllocation();
  const { allocationDriver, setScheduleDetailsAllocationClose } = scheduleDetails;
  const {
    modalProps: { driverList },
  } = allocationDriver;

  const driver = useMemo(() => driverList[0], [driverList]);

  const handleModalClose = useCallback(() => {
    setScheduleDetailsAllocationClose();
  }, [setScheduleDetailsAllocationClose]);

  const handleConfirm = useCallback(() => {
    updateScheduleAllocationHook({ driverId: driver.id });
  }, [driver.id, updateScheduleAllocationHook]);

  return <ConfirmDriverView handleModalClose={handleModalClose} handleConfirm={handleConfirm} driver={driver} />;
};
