import { useCallback } from 'react';
import { ISelectItemDomain } from 'src-new/domains/select-item.domain';
import { getShippingCompanySearchService } from 'src-new/services/get-shipping-company-search/get-shipping-company-search.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetShippingCompanySearch = () => {
  return useCallback(async (searchValue: string): Promise<Array<ISelectItemDomain> | undefined> => {
    try {
      return getShippingCompanySearchService(searchValue);
    } catch (err) {
      showMessageFormatted({
        message: 'Erro ao obter transportadoras.',
        type: 'error',
      });
    }
  }, []);
};
