import React from 'react';
import { LoadingView } from 'src-new/components/loading/loading-view.component';

interface LoadingProps {
  width?: number;
  height?: number;
}

export const Loading: React.FC<LoadingProps> = ({ width, height }) => {
  return <LoadingView width={width} height={height} />;
};
