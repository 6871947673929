import { convertVehicleCategoryToEntity } from 'src-new/utils/convert-vehicle-category/convert-vehicle-category.util';
import { convertVehicleTypeToEntity } from 'src-new/utils/convert-vehicle-type/convert-vehicle-type.util';
import { ISaleOfferFormRegisterPayloadDomain } from '../../../../domains/sale-offer-form-register.domain';
import {
  ISaleOfferFormRegisterResponse,
  ISaleOfferShipperRegisterResponse,
  LocationTypeEntity,
} from '../../../../services/sale-offer-register/sale-offer-register.types';
import { convertCurrencyBRToNumber } from 'src-new/utils/currency-br-to-number.util';
import { CargoTypesResponse } from 'src-new/domains/cargo-type.domain';

export const convertSaleOfferRegisterDomainToEntity = (
  domain: ISaleOfferFormRegisterPayloadDomain,
): ISaleOfferFormRegisterResponse => {
  const mappingSaleOfferDetails: Array<ISaleOfferShipperRegisterResponse> = domain.saleOfferDetails.map((item) => {
    return {
      partnerShipperId: item.partnerShipper.id,
      initialDate: item.initialDate,
      finalDate: item.finalDate,
      tariff: item.tariff.map((tariff) => {
        return {
          shippingId: tariff.shipping.id || 34, // CARGONECT
          vehicleType: convertVehicleTypeToEntity(tariff.vehicleType),
          vehicleCategory: convertVehicleCategoryToEntity(tariff.vehicleCategory),
          weight: convertCurrencyBRToNumber(tariff.weight),
          pallets: convertCurrencyBRToNumber(tariff.pallets),
          cubage: convertCurrencyBRToNumber(tariff.cubage),
          frequency: convertCurrencyBRToNumber(tariff.frequency),
          invoicedBy: tariff.invoicedBy,
          gris: convertCurrencyBRToNumber(tariff.gris),
          advalorem: convertCurrencyBRToNumber(tariff.advalorem),
          valueShipping: convertCurrencyBRToNumber(tariff.valueShipping),
          valueShipper: convertCurrencyBRToNumber(tariff.valueShipper),
          feeLogshare: convertCurrencyBRToNumber(String(tariff.feeLogshare)) / 100,
          valueLogshare: convertCurrencyBRToNumber(tariff.valueLogshare),
          valueTotal: tariff.valueTotal,
          isActive: tariff.isActive,
        };
      }),
    };
  });

  return {
    origin: {
      id: domain.origin.id,
      type: domain.origin.type as LocationTypeEntity,
    },
    destination: {
      id: domain.destination.id,
      type: domain.destination.type as LocationTypeEntity,
    },
    cargoType: domain.cargoType.toUpperCase() as CargoTypesResponse,
    prohibitedCargo: domain.prohibitedCargo.length === 0 ? ['Outros'] : domain.prohibitedCargo,
    prohibitedOtherCargo: domain.prohibitedOtherCargo || 'Não Informado',
    saleOfferDetails: mappingSaleOfferDetails,
  };
};
