/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { ReworkRegisterSchema } from './schemas-validation/schemas-validation';
import { ButtonContainer, ButtonStyle, Wrapper } from './rework-register-form.styled';
import ReworkRegisterFormView from './rework-register-form-view';
import {
  PATH_TO_REWORK_LIST,
  initialValues,
  optionsTransportedCargo,
  optionsTime,
  optionsYesOrNo,
} from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { sendReworkFile } from 'pages-v2/rework/services/rework-file-url.service';
import { getReworkByIdService, reworkRegisterService, reworkUpdateService } from 'pages-v2/rework/services';
import { optionTypes } from 'pages/hire-backhaul/constants/label-options';
import { IRework } from 'domain/rework';
import { ModalBark } from 'components-v2/layout/modal-bark';
import Button from 'components/button/button';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
interface IReworkProps {
  reworkId: number | undefined;
}

const ReworkRegisterForm: React.FC<IReworkProps> = ({ reworkId }) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rework, setRework] = useState<IRework>();
  const [hasRework, setHasRework] = useState<{
    open: boolean;
    id: Array<number>;
    rework: IRework;
  }>({ open: false, id: [], rework: initialValues });

  const getReworkDetails = async (reworkId: number) => {
    const reworkDetails = await getReworkByIdService(reworkId);
    setHasRework({ open: false, id: [reworkId], rework: reworkDetails });
  };

  const closeModal = () => {
    setShowModal(false);
    setIsLoading(false);
  };

  const onConfirmation = () => {
    if (rework) registerRework(rework);
    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  useEffect(() => {
    if (reworkId) {
      getReworkDetails(reworkId);
    }
  }, []);

  const goBackToReworkList = useCallback(() => navigate(PATH_TO_REWORK_LIST), [navigate]);

  const registerRework = useCallback(
    async (rework: IRework) => {
      if (!reworkId) {
        try {
          const { data } = await reworkRegisterService({
            ...rework,
            destinations: rework.destinations.map((item: any) => item.id),
          });

          if (rework?.urlPhotos) {
            await sendReworkFile(data, rework.urlPhotos);
          }

          showMessageFormatted({
            message: TOAST_MESSAGE.REGISTER,
            type: 'success',
          });

          goBackToReworkList();
        } catch (error: any) {
          showMessageFormatted({
            error: error,
            type: 'error',
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        reworkUpdateService(reworkId, {
          ...rework,
          destinations: rework.destinations.map((item: any) => item.id),
        })
          .then(() => {
            showMessageFormatted({
              message: TOAST_MESSAGE.UPDATE,
              type: 'success',
            });
            goBackToReworkList();
          })
          .catch((error: any) =>
            showMessageFormatted({
              error: error,
              type: 'error',
            }),
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [goBackToReworkList, reworkId],
  );

  const handleSubmit = useCallback(
    async (rework: IRework) => {
      setIsLoading(true);
      setRework(rework);

      if (rework.hasOrigin && rework.idToCompareCompanyOrigin !== rework.idOrigin) {
        setShowModal(true);
        return;
      }

      await registerRework(rework);
    },
    [registerRework],
  );

  return (
    <>
      {showModal && (
        <ModalBark
          size="xsm"
          title="Cliente e origem da viagem não são iguais"
          subtitle="Deseja prosseguir?"
          handleClose={onCancel}
          alingText
        >
          <Wrapper>
            <ButtonContainer>
              <ButtonStyle>
                <Button title={'Salvar'} callback={onConfirmation} bgColor={'blue'} size="large" />
              </ButtonStyle>
              <ButtonStyle>
                <Button title={'Cancelar'} callback={onCancel} bgColor={'newRed'} size="large" />
              </ButtonStyle>
            </ButtonContainer>
          </Wrapper>
        </ModalBark>
      )}

      <ReworkRegisterFormView
        hireBackhaulRegisterValues={hasRework.rework}
        optionClientType={optionTypes}
        optionsTransportedCargo={optionsTransportedCargo}
        optionsTime={optionsTime}
        optionsYesOrNo={optionsYesOrNo}
        isLoading={isLoading}
        handleGoBack={goBackToReworkList}
        handleRegister={handleSubmit}
        schemaValidation={ReworkRegisterSchema}
      />
    </>
  );
};

export default ReworkRegisterForm;
