import styled, { DefaultTheme, css } from 'styled-components';
import { ThemeColorsV2Type } from 'styles/types/colors';

const wrapperModifier = {
  small: (theme: DefaultTheme) => css`
    min-width: 50px;
    max-width: 80px;
    font-size: ${theme.font.sizes.xxsmall};
  `,
  medium: () => css`
    min-width: 115px;
    font-size: 12px;
  `,
  large: () => css`
    min-width: 135px;
    font-size: 12px;
  `,
  full: () => css`
    width: 100%;
    font-size: 14px;
  `,
};

interface IWrapperProps {
  size: 'small' | 'medium' | 'large' | 'full';
  color: ThemeColorsV2Type;
}
export const Wrapper = styled.button<IWrapperProps>`
  ${({ theme, size, color }) => css`
    height: 40px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 5px;
    overflow: hidden;
    font-weight: 600;
    font-family: ${theme.fontV2.family};
    outline-width: 0px;
    filter: drop-shadow(0 0 #0000);
    cursor: pointer;
    color: white;
    background-color: ${theme.colorsV2[color]};

    &:hover {
      filter: drop-shadow(0px 0px 6px 0px #5d5d5d40);
      opacity: 0.88;
    }

    &:focus {
      filter: drop-shadow(0px 0px 6px 0px #5d5d5d40);
      opacity: 0.88;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${wrapperModifier[size](theme)};
  `}
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-items: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const Label = styled.div`
  flex-grow: 1;
  margin: 0 5px;
`;

interface IContainerIconProps {
  disabled?: boolean;
}
export const ContainerIcon = styled.div<IContainerIconProps>`
  ${({ disabled }) => css`
    height: 0px;
    width: 0px;
    position: relative;
    left: 92px;
    bottom: 35px;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`;

export const Div = styled.div``;
