import React, { ReactNode } from 'react';

import {
  ContainerEmptyStateTableStyled,
  ContainerLoadingStyled,
  ContainerStyled,
  SubTextContainerStyled,
  TextContainerStyled,
} from './empty-state-table.styled';

import Loading from 'components-v2/common/loading/index';

type EmptyStateTableViewProps = {
  icon: ReactNode;
  text: string | ReactNode;
  subText: string;
  isLoading: boolean;
};
const EmptyStateTableView: React.FC<EmptyStateTableViewProps> = ({ icon, text, subText, isLoading }) => {
  const renderEmptyStateTable = () => {
    if (isLoading) {
      return (
        <ContainerLoadingStyled>
          <Loading />
        </ContainerLoadingStyled>
      );
    }
    return (
      <ContainerEmptyStateTableStyled>
        <div>{icon}</div>
        <TextContainerStyled>{text}</TextContainerStyled>
        <SubTextContainerStyled>{subText}</SubTextContainerStyled>
      </ContainerEmptyStateTableStyled>
    );
  };

  return <ContainerStyled>{renderEmptyStateTable()}</ContainerStyled>;
};
export default EmptyStateTableView;
