import moment from 'moment';

export const diffDaysAndHoursDate = (dataChegadaProgramada: string, dataChegadaReal: string, dataSaida: string) => {
  const dataChegadaProgramadaMoment = moment(dataChegadaProgramada);
  const dataChegadaRealMoment = moment(dataChegadaReal);
  const dataSaidaMoment = moment(dataSaida);

  const dataChegadaMaisRecente = dataChegadaProgramadaMoment.isAfter(dataChegadaRealMoment)
    ? dataChegadaProgramadaMoment
    : dataChegadaRealMoment;

  const diffInMilliseconds = dataSaidaMoment.diff(dataChegadaMaisRecente);
  const duration = moment.duration(diffInMilliseconds);

  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
  };
};
