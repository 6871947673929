import API from 'utils/API/API';
import { convertStatusDomainToEntityMapper } from 'pages/location/mappers/convert-status-domain-to-entity/convert-status-domain-to-entity.mapper';
import { IAcceptedMatchesStatus } from 'domain/accepted-matches';

interface LocatioReportExcelServiceProps {
  search: string;
  status: IAcceptedMatchesStatus | undefined;
}

export const acceptedMatchesReportExcelService = async ({
  search,
  status,
}: LocatioReportExcelServiceProps): Promise<BlobPart | undefined> => {
  try {
    const convertedStatus = status ? convertStatusDomainToEntityMapper(status) : status;

    const { data } = await API.get('v2/my-router/export', {
      params: {
        status: convertedStatus,
        search,
      },
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error('Houve um erro ao listar localidade');
  }
};
