/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import TrackingUpdateFormView from './tracking-occurrence-update-form-view';
import { TrackingOccurrenceRegisterSchema } from './schemas-validation/schemas-validation';
import { initialValues, optionsStatus } from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { TableDescriptionModalStyled } from 'pages/tracking-occurrence/tracking-occurrence-register/components/tracking-occurrence-register-form/tracking-occurrence-register-form.styled';
import { useUpdateTrackingOccurrence } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-update/use-tracking-occurrence-update.hook';
import { useTrackingOccurrenceDetailHook } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-details/use-tracking-occurrence-details.hook';
import { useDeleteTrackingOccurrenceHook } from 'pages/tracking-occurrence/hooks/use-tracking-occurrence-delete/use-tracking-occurrence-delete';
import { useFinishTrackingOccurrenceHook } from 'pages/tracking-occurrence/hooks';
import { useGetFreightByIdHook } from 'pages/freight/hooks/use-allocation-by-id/use-allocation-by-id.hook';
import { AllocationRegisterContext } from 'pages/allocation/contexts/allocation-register/allocation.context';
import { ITrackingOccurrenceCreated } from 'domain/tracking-occurrence';
import ModalImage from 'components/modal-image/modal-image';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

interface IProps {
  handleGetAllocation: (allocationID: number, statusCode?: string) => void;
}

const TrackingOccurrenceUpdateForm: React.FC<IProps> = ({ handleGetAllocation }) => {
  const location = useLocation();

  const [photos, setPhotos] = useState<File[]>([]);
  const [idToRemove, setIdToRemove] = useState<number>(0);

  const [trackingOccurrence, setTrackingOccurrence] = useState<ITrackingOccurrenceCreated>(initialValues);

  const { allocation } = useContext(AllocationRegisterContext);

  const [modalDescription, setModalDescription] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const [modalConfirmDelete, setModalConfirmDelete] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });

  const handleUpdateId = useCallback(async () => {
    handleGetAllocation(+trackingOccurrence.id);
  }, [trackingOccurrence.id]);

  useEffect(() => {
    handleUpdateId();
  }, [handleUpdateId]);

  const navigate = useNavigate();
  const { id, scheduleId } = useParams();

  const getCurrentFreightHook = useGetFreightByIdHook();
  const trackingOccurrenceDetailHook = useTrackingOccurrenceDetailHook();
  const trackingOccurrenceUpdateHook = useUpdateTrackingOccurrence();
  const finishTrackingOccurrenceHook = useFinishTrackingOccurrenceHook();

  const backToLastPage = useCallback(() => navigate(-1), [navigate]);

  const finishTrackingOccurrence = useCallback(async () => {
    if (trackingOccurrence.id) {
      try {
        await finishTrackingOccurrenceHook(+trackingOccurrence.id);
        showMessageFormatted({
          message: TOAST_MESSAGE.UPDATE,
          type: 'success',
        });
        backToLastPage();
      } catch (error) {
        showMessageFormatted({
          error: error as any,
          type: 'error',
        });
      }
    }
  }, [backToLastPage, trackingOccurrence.id]);

  const deleteOccurrenceHook = useDeleteTrackingOccurrenceHook();

  const handleAllocation = useCallback(async () => {
    if (scheduleId && Number(scheduleId)) {
      await getCurrentFreightHook(scheduleId);
    }
  }, [getCurrentFreightHook, scheduleId]);

  const handleSubmit = useCallback(
    async (trackingOccurrence: ITrackingOccurrenceCreated) => {
      trackingOccurrenceUpdateHook({
        ...trackingOccurrence,
        occurrenceNewFiles: photos,
      })
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          backToLastPage();
        })
        .catch((error) => {
          showMessageFormatted({
            error: error,
            type: 'error',
          });
        });
    },
    [photos, trackingOccurrenceUpdateHook, backToLastPage],
  );

  useEffect(() => {
    handleAllocation();
  }, [handleAllocation]);

  const handleGetTrackingOccurrence = useCallback(async () => {
    if (id) {
      try {
        const details = await trackingOccurrenceDetailHook(id);
        setTrackingOccurrence(details);
      } catch (error) {
        showMessageFormatted({
          error: error as any,
          type: 'error',
        });
      }
    }
  }, [id]);

  useEffect(() => {
    handleGetTrackingOccurrence();
  }, [handleGetTrackingOccurrence]);

  const goToDescriptionView = (index: any) => {
    const description = (
      <p style={{ wordWrap: 'break-word' }}>
        <TableDescriptionModalStyled>
          {trackingOccurrence.occurrenceResolutions?.[index]?.description}
        </TableDescriptionModalStyled>
      </p>
    );
    setModalDescription({ open: true, description });
  };

  const handleRemoveOccurrence = async (exclude = false) => {
    if (exclude) {
      await deleteOccurrenceHook(trackingOccurrence.id);
      navigate('/tracking-ocorrencias');
      setIdToRemove(0);
    }
  };

  const goToOccurrenceDelete = () => {
    const description = (
      <>
        <p>Você tem certeza que deseja cancelar</p>
        <p>esse agendamento?</p>
      </>
    );
    setModalConfirmDelete({ open: true, description: description });
  };

  return (
    <>
      {modalDescription.open && (
        <ModalImage
          title="Visualizar Descrição"
          description={modalDescription.description}
          handleCancel={() => setModalDescription((old) => ({ ...old, open: false }))}
        />
      )}
      {modalConfirmDelete.open && (
        <ModalConfirmation
          title="Excluir Ocorrência"
          secondTitle={modalConfirmDelete.description}
          handleCancel={() => setModalConfirmDelete((old) => ({ ...old, open: false }))}
          onConfirmation={() => handleRemoveOccurrence(true)}
          size="small"
          type="delete"
          description={undefined}
        />
      )}
      <TrackingUpdateFormView
        trackingOccurrenceRegisterValues={trackingOccurrence}
        currentAllocation={allocation}
        occurrenceType={location?.state}
        optionsStatusType={optionsStatus}
        handleGoBack={backToLastPage}
        handleFinishTrackingOccurrence={finishTrackingOccurrence}
        handleRegister={handleSubmit}
        schemaValidation={TrackingOccurrenceRegisterSchema}
        goToDescriptionView={goToDescriptionView}
        photosFiles={photos}
        setPhotosFiles={setPhotos}
        handleRemoveOccurrence={goToOccurrenceDelete}
        idToRemove={idToRemove}
        getAllocation={handleGetTrackingOccurrence}
      />
    </>
  );
};

export default TrackingOccurrenceUpdateForm;
