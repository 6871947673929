import { occurrenceRegisterService } from 'pages/occurrence/services/occurrence-register/occurrence-register.service';
import { IOccurrence } from 'domain/occurrence';

const useOccurrenceRegisterHook = () => {
  return async (occurrence: IOccurrence) => {
    await occurrenceRegisterService(occurrence);
  };
};

export { useOccurrenceRegisterHook };
