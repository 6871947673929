/* eslint-disable no-unused-vars */
export enum ColorsV2Type {
  Pink = 'pink',
  Yellow = 'yellow',
  Orange = 'orange',
  Purple = 'purple',
  VeryLightGray = 'veryLightGray',
  MiddlePink = 'middlePink',
  MiddleRed = 'middleRed',
  MiddleBlue = 'middleBlue',
  Aqua = 'aqua',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  ProYellow = 'proYellow',
  DarkGray = 'darkGray',
  GrayDark = 'gray-dark',
  LightGray = 'lightGray',
  GrayLight = 'gray-light',
  White = 'white',
  Black = 'black',
  ForBorder = 'forBorder',
  Transparent = 'transparent',
  Aqua25 = 'aqua-25',
  Aqua50 = 'aqua-50',
  Aqua75 = 'aqua-75',
  Aqua100 = 'aqua-100',
  Aqua1 = 'aqua1',
  Aqua2 = 'aqua2',
  Aqua3 = 'aqua3',
  Blue25 = 'blue-25',
  Blue50 = 'blue-50',
  Blue75 = 'blue-75',
  Blue100 = 'blue-100',
}

export enum ThemeColorsV2Type {
  Aqua = 'aqua',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  ProYellow = 'proYellow',
  DarkGray = 'darkGray',
  LightGray = 'lightGray',
  White = 'white',
  Black = 'black',
  ForBorder = 'forBorder',
  Transparent = 'transparent',
  Aqua25 = 'aqua-25',
  Aqua50 = 'aqua-50',
  Aqua75 = 'aqua-75',
  Aqua100 = 'aqua-100',
  Blue25 = 'blue-25',
  Blue50 = 'blue-50',
  Blue75 = 'blue-75',
  Blue100 = 'blue-100',
  LightGray25 = 'light-gray-25',
}
