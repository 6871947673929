import { IPalletDomain } from 'src-new/pages/financial/domains/pallet.domain';
import { IPalletResponse } from 'src-new/pages/financial/services/get-pallet-by-id/get-pallet-by-id.types';

export const convertPalletFormDomainToResponse = (palletDomain: IPalletDomain): IPalletResponse => {
  return {
    id: palletDomain.id,
    equipmentVoucher: palletDomain.freightData.equipmentVoucher,
    operationType: palletDomain.freightData.operationType,
    collectDate: palletDomain.freightData.collectDate,
    clientId: palletDomain.freightData.clientId,
    clientPlant: palletDomain.freightData.clientPlant,
    distributionCenterId: palletDomain.freightData.distributionCenterId,
    scheduleId: palletDomain.freightData.scheduleId,
    palletType: palletDomain.palletData.palletType,
    palletQuantity: palletDomain.palletData.palletQuantity,
    equipmentVoucherDate: palletDomain.palletData.equipmentVoucherDate,
    expirationDate: palletDomain.palletData.expirationDate,
    withdrawalDate: palletDomain.palletData.withdrawalDate,
    entryDate: palletDomain.palletData.entryDate,
    liberationDate: palletDomain.palletData.liberationDate,
    checkStub: palletDomain.checkStub,
    note: palletDomain.note,
    status: palletDomain.status,
    boardingNumber: palletDomain.palletData.boardingNumber,
  };
};
