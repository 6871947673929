import styled, { css } from 'styled-components';

interface IStyledProps {
  fontSize?: number;
  textAlign?: string;
}

export const WrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #12264e;
  height: 80px;
  padding: 10px;
`;

export const InfoStyled = styled.div<IStyledProps>`
  ${({ fontSize, textAlign }) => css`
    font-size: ${fontSize ?? 10}px;
    text-align: ${textAlign ?? 'right'};
    line-height: 25px;
    color: #fff;
    font-weight: 300;
  `}
`;

export const ChildrenStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0;
`;

export const FooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #12264e;
  height: 20px;
`;
