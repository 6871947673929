import { useCallback, useContext } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { IUser } from 'domain/login';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ISavedFilters } from 'src-new/pages/execution/pages/schedule/utils/convert-saved-filters/convert-saved-filters.types';
import { convertSavedFilters } from 'src-new/pages/execution/pages/schedule/utils/convert-saved-filters/convert-saved-filters.util';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { firestoreDB } from 'App';

export const useGetSavedFilters = () => {
  const { scheduleList } = useContext(ScheduleContext);
  const { setSavedFilters } = scheduleList.scheduleSavedFiltersProps;

  return useCallback(
    async (user: IUser) => {
      try {
        const docSnap = query(collection(firestoreDB, user.companyLogin));

        const savedFilter: Array<ISavedFilters> = [];

        const snapshot = await getDocs(docSnap);

        snapshot.forEach((doc) =>
          savedFilter.push({
            ...(doc.data() as ISavedFilters),
            filterIdentifier: doc.id,
          }),
        );

        const convertedSavedFilters = convertSavedFilters(savedFilter);

        setSavedFilters(convertedSavedFilters);
      } catch (error: any) {
        showMessageFormatted({
          type: 'error',
          message: error?.message ?? JSON.stringify(error),
        });
      }
    },
    [setSavedFilters],
  );
};
