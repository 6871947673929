import React, { ReactElement, useMemo } from 'react';
import * as S from './details-backhaul-purchase-form-view.styled';
import { FormikErrors } from 'formik';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { initialValuesDate } from 'domain-v2/match/register/initial-values';
import { SectionLayout } from 'components-v2/layout/section';
import { LineSectionLayout } from 'components-v2/layout/line-section';
import Line from 'components-v2/layout/line';
import { DateTimePikers } from 'components-v2/common/date-time-pikers';
import Button from 'components/button/button';
import { IValidateDates } from 'src-new/utils/validate-dates/validate-dates.types';
import { IModalBackhaulPurchaseForm } from 'src-new/components/modal-backhaul-purchase/types/modal-backhaul-purchase-form/modal-backhaul-purchase-form.types';

interface IDetailsBackhaulPurchaseFormViewProps {
  dates: Array<IValidateDates>;
  datesError?: Array<IValidateDates | undefined>;
  handleAddDate: (initialValues: IValidateDates) => Promise<void | FormikErrors<IModalBackhaulPurchaseForm>>;
  handleRemoveDate: (index: number) => void;
  handleFieldDateChange: (fieldName: string, date: dayjs.Dayjs, index: number) => void;
  calcMinDate: (inputName: string, index: number) => dayjs.Dayjs;
}

export const DetailsBackhaulPurchaseFormView: React.FC<IDetailsBackhaulPurchaseFormViewProps> = ({
  dates,
  datesError,
  handleAddDate,
  handleRemoveDate,
  handleFieldDateChange,
  calcMinDate,
}) => {
  const renderDatesForm = useMemo((): Array<ReactElement> => {
    return dates.map((dateValue, index) => {
      return (
        <S.Div key={index}>
          <LineSectionLayout columns={'1fr'}>
            <S.WrapperInputs>
              <S.LineSectionStyled>
                <DateTimePikers
                  label="Inicio de Carregamento *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(dateValue.initialLevyDate)}
                  onChange={(date) => handleFieldDateChange('initialLevyDate', date ?? dayjs(), index)}
                  minDate={calcMinDate('initialLevyDate', index)}
                  validateField={datesError && datesError[index]?.initialLevyDate !== ''}
                  errorMessage={datesError && (datesError[index]?.initialLevyDate ?? '')}
                />

                <DateTimePikers
                  label="Fim de Carregamento *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(dateValue.finalLevyDate)}
                  onChange={(date) => handleFieldDateChange('finalLevyDate', date ?? dayjs(), index)}
                  minDate={calcMinDate('finalLevyDate', index)}
                  validateField={datesError && datesError[index]?.finalLevyDate !== ''}
                  errorMessage={datesError && (datesError[index]?.finalLevyDate ?? '')}
                />

                <DateTimePikers
                  label="Inicio da Descarga *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(dateValue.initialDeliveryDate)}
                  onChange={(date) => handleFieldDateChange('initialDeliveryDate', date ?? dayjs(), index)}
                  minDate={calcMinDate('initialDeliveryDate', index)}
                  validateField={datesError && datesError[index]?.initialDeliveryDate !== ''}
                  errorMessage={datesError && (datesError[index]?.initialDeliveryDate ?? '')}
                />

                <DateTimePikers
                  label="Fim da Descarga *"
                  format="DD/MM/YY HH:mm"
                  value={dayjs(dateValue.finalDeliveryDate)}
                  onChange={(date) => handleFieldDateChange('finalDeliveryDate', date ?? dayjs(), index)}
                  minDate={calcMinDate('finalDeliveryDate', index)}
                  validateField={datesError && datesError[index]?.finalDeliveryDate !== ''}
                  errorMessage={datesError && (datesError[index]?.finalDeliveryDate ?? '')}
                />

                <S.WrapperButton>
                  {index > 0 ? (
                    <Button
                      title="Deletar"
                      bgColor="newRed"
                      callback={() => handleRemoveDate(index)}
                      icon={<DeleteIcon />}
                      size="very-small"
                    />
                  ) : (
                    <Button
                      title="Adicionar"
                      bgColor="blue"
                      callback={() => handleAddDate(initialValuesDate)}
                      icon={<AddIcon />}
                      size="very-small"
                    />
                  )}
                </S.WrapperButton>
              </S.LineSectionStyled>
            </S.WrapperInputs>
          </LineSectionLayout>
          <S.WrapperButton>
            <LineSectionLayout columns="0.96fr">
              <Line />
            </LineSectionLayout>
          </S.WrapperButton>
        </S.Div>
      );
    });
  }, [dates, calcMinDate, datesError, handleFieldDateChange, handleRemoveDate, handleAddDate]);

  return <SectionLayout name="Details information">{renderDatesForm}</SectionLayout>;
};
