import styled, { css } from 'styled-components';

export const ContainerBreadcrumbStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-family: ${theme.font.family};

    line-height: 25px;
    font-style: normal;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 1rem;
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 99999px;

  padding: 0.1rem;

  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
    border-radius: 99999px;
  }
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: 14px;
  `}
`;

export const Separator = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.normal};
    font-size: 14px;
  `}
`;

export const SubText = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.semiBold};
    font-size: 14px;
  `}
`;

export const ListText = styled.span`
  ${({ theme }) => css`
    display: flex;
    font-weight: ${theme.font.bold};
    font-size: 17px;
    color: #000;
  `}
`;
