import { showToast } from 'components/toast/toast';
import { FormikErrors } from 'formik';
import { clientDetailsService } from 'pages/client/services/client.service';
import { locationDetailsService } from 'pages/location/services/location-details/location-details.service';
import { FC, useCallback, useContext, useMemo } from 'react';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { UserContext } from 'state/user-context';
import { WeighingDuplicatedFormMountLocationsView } from './weighing-duplicated-form-mount-locations-view.component';

interface IWeighingDuplicatedFormMountLocationsProps {
  hasError: boolean;
  type: 'origin' | 'destination';
  values: IWeighingDomain;
  setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<IWeighingDomain>>;
}

export const WeighingDuplicatedFormMountLocations: FC<IWeighingDuplicatedFormMountLocationsProps> = ({
  type,
  hasError,
  values,
  setFieldValue,
}) => {
  const { user } = useContext(UserContext);
  const typeKeyMemo = useMemo(() => {
    if (type === 'origin') return 'origin';
    return 'destination';
  }, [type]);

  const handleCompleteInputs = useCallback(
    async (id: number, type: '' | 'CLIENT' | 'LOCALITY') => {
      if ((type === 'CLIENT' || type === 'LOCALITY') && id) {
        try {
          const details = await (type === 'CLIENT'
            ? clientDetailsService(String(id))
            : locationDetailsService(String(id)));

          const fieldValuesToUpdate = {
            [`${typeKeyMemo}.id`]: details.id,
            [`${typeKeyMemo}.city`]: details.address.city,
            [`${typeKeyMemo}.uf`]: details.address.uf,
            [`${typeKeyMemo}.complement`]: details.address.complement,
            [`${typeKeyMemo}.neighborhood`]: details.address.neighborhood,
            [`${typeKeyMemo}.numberHouse`]: details.address.numberHouse,
            [`${typeKeyMemo}.street`]: details.address.street,
            [`${typeKeyMemo}.cep`]: details.address.cep,
            [`${typeKeyMemo}.cnpj`]: details.cnpj,
            [`${typeKeyMemo}.lat`]: details.lat,
            [`${typeKeyMemo}.lng`]: details.lng,
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue, typeKeyMemo],
  );

  const handleClearInputs = useCallback(
    async (type: '' | 'CLIENT' | 'LOCALITY') => {
      if (type === 'CLIENT' || type === 'LOCALITY') {
        try {
          const fieldValuesToUpdate = {
            [`${typeKeyMemo}.id`]: 0,
            [`${typeKeyMemo}.city`]: '',
            [`${typeKeyMemo}.name`]: '',
            [`${typeKeyMemo}.uf`]: '',
            [`${typeKeyMemo}.complement`]: '',
            [`${typeKeyMemo}.neighborhood`]: '',
            [`${typeKeyMemo}.numberHouse`]: '',
            [`${typeKeyMemo}.street`]: '',
            [`${typeKeyMemo}.cep`]: '',
            [`${typeKeyMemo}.cnpj`]: '',
            [`${typeKeyMemo}.lat`]: '',
            [`${typeKeyMemo}.lng`]: '',
          };

          for (const fieldName in fieldValuesToUpdate) {
            const value = fieldValuesToUpdate[fieldName];
            setFieldValue(fieldName, value);
          }
        } catch (error) {
          showToast({
            message: 'Erro ao obter dados',
            type: 'error',
          });
        }
      }
    },
    [setFieldValue, typeKeyMemo],
  );

  const handleChangeLocations = useCallback(
    async (id: number) => {
      await handleCompleteInputs(id, values[typeKeyMemo].type);
    },
    [handleCompleteInputs, typeKeyMemo, values],
  );

  const handleChangeLocationType = useCallback(async () => {
    await handleClearInputs(values[typeKeyMemo].type);
  }, [handleClearInputs, typeKeyMemo, values]);

  return (
    <WeighingDuplicatedFormMountLocationsView
      type={typeKeyMemo}
      values={values}
      setFieldValue={setFieldValue}
      isAdmin={!!user?.isMaster}
      handleChangeLocations={handleChangeLocations}
      handleChangeLocationType={handleChangeLocationType}
      hasError={hasError}
    />
  );
};
