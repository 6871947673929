import { useCallback, useReducer } from 'react';
import { weighingDetailsReducer } from 'src-new/pages/execution/pages/weighing/contexts/weighing/reducers/weighing-details/weighing-details.reducer';
import { weighingInitialState } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.constants';
import { WeighingDetailsActionType } from 'src-new/pages/execution/pages/weighing/contexts/weighing/weighing.action';
import { IWeighingDomain } from 'src-new/pages/execution/pages/weighing/pages/weighing-update/domains/weighing-details.domain';
import { IWeighingDetails } from 'src-new/pages/execution/pages/weighing/contexts/weighing/types/weighing-details.types';

export const useWeighingDetailsContextValues = (): IWeighingDetails => {
  const [state, dispatch] = useReducer(weighingDetailsReducer, weighingInitialState);

  const setWeighingDetailsRequest = useCallback(() => {
    dispatch({ type: WeighingDetailsActionType.WEIGHING_DETAILS_REQUEST, payload: undefined });
  }, []);

  const setWeighingDetailsSuccess = useCallback((details: IWeighingDomain) => {
    dispatch({ type: WeighingDetailsActionType.WEIGHING_DETAILS_SUCCESS, payload: details });
  }, []);

  const setWeighingDetailsError = useCallback(() => {
    dispatch({ type: WeighingDetailsActionType.WEIGHING_DETAILS_ERROR, payload: undefined });
  }, []);

  const setWeighingDetailsReset = useCallback(() => {
    dispatch({ type: WeighingDetailsActionType.WEIGHING_DETAILS_RESET, payload: undefined });
  }, []);

  return {
    ...state.weighingDetails,
    setWeighingDetailsRequest,
    setWeighingDetailsSuccess,
    setWeighingDetailsError,
    setWeighingDetailsReset,
  };
};
