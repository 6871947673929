import styled from 'styled-components';

interface IStyledProps {
  isBold?: boolean;
}

export const TextContainerStyled = styled.div<IStyledProps>`
  color: #000;
  font-family: Montserrat, serif;
  font-size: 11px;
  ${({ isBold }) => (isBold ? 'font-weight: 600;' : '')}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-self: start;
  align-self: center;
`;

export const ContainerTooltipStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2px;
`;

export const ContainerInfosTooltipStyled = styled.div``;

export const ContainerInfosPurchaseStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerInfosSaleStyled = styled.div`
  display: flex;
  gap: 19px;
`;

export const LabelTooltipStyled = styled.p``;

export const TagContainerStyled = styled.div`
  margin-left: 20px;
`;

export const UnreadTagContainerStyled = styled.div`
  width: 21px;
  height: 21px;
  background-color: rgb(224, 71, 71);
  border-radius: 100%;
  color: white;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  padding-top: 3px;
  margin-left: 15px;
`;

export const ChatButtonStyled = styled.button`
  font-family: Montserrat, serif;
  font-size: 12px;
  width: 100px;
  height: 40px;
  background-color: #fff;
  color: #2d2d2d;
  border-radius: 6px;
  border: 1px solid #757575;
  cursor: pointer;
`;

export const ChatButtonTextContainerStyled = styled.div<IStyledProps>`
  display: flex;
  padding-left: 18px;
  padding-top: 12px;
  width: 100%;
  height: 100%;
`;

export const ChatButtonTextBoldStyled = styled.div<IStyledProps>`
  margin-left: 3px;
  font-weight: 600;
`;

export const PartnerContainerStyled = styled.div<IStyledProps>`
  margin-left: 2px;
`;

export const OperationTypeContainerStyled = styled.div<IStyledProps>`
  margin-left: 2px;
`;

export const StartDateContainerStyled = styled.div<IStyledProps>`
  margin-left: 3px;
`;

export const EndDateContainerStyled = styled.div<IStyledProps>`
  margin-left: 5px;
`;
