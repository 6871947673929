import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { IListPage } from '../../../../domain/page-info';
import { IOccurrence } from '../../../../domain/occurrence';

import { IOccurrencesContext } from './occurrences.types';
import { occurrencesReducer } from './occurrences.reducer';
import { initialState, IOccurrencesAction } from './occurrences.actions';

interface IOccurrencesContextProps {
  children: ReactNode;
}

export const OccurrencesContext = createContext<IOccurrencesContext>(initialState);

const OccurrencesContextProvider = ({ children }: IOccurrencesContextProps) => {
  const [state, dispatch] = useReducer(occurrencesReducer, initialState);

  const setOccurrencesRequest = useCallback(() => {
    dispatch({
      type: IOccurrencesAction.OCCURRENCES_REQUEST,
      payload: null,
    });
  }, []);

  const setOccurrencesSuccess = useCallback((occurrenceList: IListPage<IOccurrence>) => {
    dispatch({
      type: IOccurrencesAction.OCCURRENCES_SUCCESS,
      payload: occurrenceList,
    });
  }, []);

  const setOccurrencesError = useCallback((error: string) => {
    dispatch({
      type: IOccurrencesAction.OCCURRENCES_ERROR,
      payload: error,
    });
  }, []);

  const value = useMemo(() => {
    return {
      occurrencesIsLoading: state.occurrencesIsLoading,
      occurrences: state.occurrences,
      occurrencesError: state.occurrencesError,
      occurrencesPageInfo: state.occurrencesPageInfo,
      setOccurrencesRequest,
      setOccurrencesSuccess,
      setOccurrencesError,
    };
  }, [
    setOccurrencesError,
    setOccurrencesRequest,
    setOccurrencesSuccess,
    state.occurrences,
    state.occurrencesError,
    state.occurrencesIsLoading,
    state.occurrencesPageInfo,
  ]);

  return <OccurrencesContext.Provider value={value}>{children}</OccurrencesContext.Provider>;
};

export default React.memo(OccurrencesContextProvider);
