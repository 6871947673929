import { formatDateAndTimeISOtoLocale } from 'utils-v2/converters/date';
import {
  CardResumeOriginAndDestinyInput,
  IDeliveryScheduleDetailsValues,
  IFreightValues,
  ILevyScheduleDetailsValues,
  IOperationValues,
} from 'pages-v2/schedule/components/card-resume/types';
import { ScheduleInput } from 'domain-v2/schedule';

export const convertResumeUpdate = (schedule: ScheduleInput) => {
  const {
    origin,
    destination,
    distance,
    cargo,
    detail,
    freithgtPayable,
    freithgtReceivable,
    freithgtShipping,
    shipping,
    vehicle,
    driver,
  } = schedule;

  const originValues: CardResumeOriginAndDestinyInput = {
    tradeName: origin.name,
    cnpj: origin?.cnpj,
    quality_and_safety: origin.qualityAndSafety,
    address: {
      city: origin?.address?.city,
      complement: origin.address?.complement ?? '',
      neighborhood: origin.address?.neighborhood,
      numberHouse: origin.address?.number,
      uf: origin.address?.uf,
      street: origin.address?.street,
      cep: origin.address?.cep,
    },
  };

  const scheduleDetailsValuesOrigin: ILevyScheduleDetailsValues = {
    levyInitialDate: formatDateAndTimeISOtoLocale(detail.levyInitialDate),
    levyEndDate: formatDateAndTimeISOtoLocale(detail.levyEndDate),
  };

  const destinationValues: CardResumeOriginAndDestinyInput = {
    tradeName: destination.name,
    cnpj: destination?.cnpj,
    quality_and_safety: destination.qualityAndSafety,
    address: {
      city: destination?.address?.city,
      complement: destination.address?.complement ?? '',
      neighborhood: destination.address?.neighborhood,
      numberHouse: destination.address?.number,
      uf: destination.address?.uf,
      street: destination.address?.street,
      cep: destination.address?.cep,
    },
  };

  const scheduleDetailsValuesDestination: IDeliveryScheduleDetailsValues = {
    deliveryInitialDate: formatDateAndTimeISOtoLocale(detail.deliveryInitialDate),
    deliveryEndDate: formatDateAndTimeISOtoLocale(detail.deliveryEndDate),
  };

  const scheduleCargoValues: IOperationValues = {
    weight: cargo.weight,
    volume: cargo.volume,
    inPalet: cargo.inPallet,
    pallet: cargo.pallet,
    product: cargo.product,
    merchValue: cargo.merchValue,
    vehicleCategoryType: cargo.vehicleCategoryType,
    vehicleType: cargo.vehicleType,
    distance,
  };

  const scheduleCostValues: IFreightValues = {
    value: freithgtPayable?.valueFreight || 0,
    helperPrice: freithgtPayable?.helperTotal || 0,
    grisAdvaloremPrice: freithgtPayable?.totalGrisAdvalorem || 0,
    taxPrice: freithgtPayable?.otherTaxes || 0,
    tollPrice: freithgtPayable?.toll || 0,
    totalFreight: freithgtPayable?.totalFreight || 0,
    icms: freithgtPayable?.icms || 0,
    totalService: freithgtPayable?.totalService || 0,
  };

  const scheduleCostValuesShipping: IFreightValues = {
    value: freithgtShipping?.valueFreight || 0,
    helperPrice: freithgtShipping?.helperTotal || 0,
    grisAdvaloremPrice: freithgtShipping?.totalGrisAdvalorem || 0,
    taxPrice: freithgtShipping?.otherTaxes || 0,
    tollPrice: freithgtShipping?.toll || 0,
    totalFreight: freithgtShipping?.totalFreight || 0,
    icms: freithgtShipping?.icms || 0,
    totalService: freithgtShipping?.totalService || 0,
  };

  const scheduleCostValuesReceive: IFreightValues = {
    value: freithgtReceivable?.valueFreight || 0,
    helperPrice: freithgtReceivable?.helperTotal || 0,
    grisAdvaloremPrice: freithgtReceivable?.totalGrisAdvalorem || 0,
    taxPrice: freithgtReceivable?.otherTaxes || 0,
    tollPrice: freithgtReceivable?.toll || 0,
    totalFreight: freithgtReceivable?.totalFreight || 0,
    icms: freithgtReceivable?.icms || 0,
    totalService: freithgtReceivable?.totalService || 0,
  };

  const scheduleDriverValues = {
    name: driver?.name ?? '',
    cpf: driver?.cpf ?? '',
  };

  const scheduleVehicleValues = {
    vehicleType: vehicle?.type ?? '',
    vehicleCategory: vehicle?.category ?? '',
    mainBoard: vehicle?.mainBoard ?? '',
    secondBoard: vehicle?.secondBoard ?? '',
    thirdBoard: vehicle?.thirdBoard ?? '',
  };

  const scheduleShippingValues = {
    name: shipping?.name ?? '',
    cnpj: shipping?.cnpj ?? '',
  };

  return {
    originValues,
    scheduleDetailsValuesOrigin,
    destinationValues,
    scheduleDetailsValuesDestination,
    scheduleCargoValues,
    scheduleCostValues,
    scheduleCostValuesReceive,
    scheduleCostValuesShipping,
    scheduleDriverValues,
    scheduleVehicleValues,
    scheduleShippingValues,
  };
};
