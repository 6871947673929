import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { BackhaulRegisterSchema } from './schemas-validation/schemas-validation';
import BackhaulRegisterFormView from './hire-backhaul-update-form-view';
import {
  PATH_TO_HIRE_BACKHAUL_LIST,
  initialValues,
  optionsTransportedCargo,
  optionsTime,
  optionsYesOrNo,
} from './form-values/form-values';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { ErrorMessage } from 'utils/exception';
import { queryClient } from 'services/query-client';
import { useUpdateHireBackhaulHook } from 'pages/hire-backhaul/hooks/use-hire-backhaul-update/use-hire-backhaul-update.hook';
import { useHireBackhaulDetailHook } from 'pages/hire-backhaul/hooks/use-backhaul-details/use-backhaul-details.hook';
import { optionTypes } from 'pages/hire-backhaul/constants/label-options';
import { IHireBackhaulCreated } from 'domain/hire-backhaul';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';

const HireBackhaulUpdateForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hireBackhaul, setHireBackhaul] = useState<IHireBackhaulCreated>(initialValues);

  const navigate = useNavigate();
  const { id } = useParams();

  const hireBackhaulDetailHook = useHireBackhaulDetailHook();
  const hireBackhaulUpdateHook = useUpdateHireBackhaulHook();

  const goBackToBackhaulList = useCallback(() => navigate(PATH_TO_HIRE_BACKHAUL_LIST), [navigate]);

  const handleSubmit = useCallback(
    async (backhaul: IHireBackhaulCreated) => {
      setIsLoading(true);
      hireBackhaulUpdateHook(backhaul)
        .then(() => {
          showMessageFormatted({
            message: TOAST_MESSAGE.UPDATE,
            type: 'success',
          });
          goBackToBackhaulList();
          queryClient.invalidateQueries(['idlenessList']);
        })
        .catch((error) => {
          const messageError = ErrorMessage(error);
          showMessageFormatted({
            message: messageError.message ? `${messageError.message}` : `${messageError}`,
            type: 'error',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [hireBackhaulUpdateHook, goBackToBackhaulList],
  );

  useEffect(() => {
    async function run() {
      if (id) {
        const location = await hireBackhaulDetailHook(id);
        setHireBackhaul(location);
      }
    }
    run();
  }, [hireBackhaulDetailHook, id]);

  return (
    <BackhaulRegisterFormView
      hireBackhaulRegisterValues={hireBackhaul}
      optionClientType={optionTypes}
      optionsTransportedCargo={optionsTransportedCargo}
      optionsTime={optionsTime}
      optionsYesOrNo={optionsYesOrNo}
      handleGoBack={goBackToBackhaulList}
      handleRegister={handleSubmit}
      schemaValidation={BackhaulRegisterSchema}
      isLoading={isLoading}
    />
  );
};

export default HireBackhaulUpdateForm;
