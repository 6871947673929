import { useCallback, useContext } from 'react';
import { downloadXlsxFileUtil } from 'src-new/utils/download-xlsx-file/download-xlsx-file.util';
import { showToast } from 'components/toast/toast';
import { getOfferFileReportService } from 'src-new/pages/opportunities/services/get-offer-file-report/get-offer-file-report.service';
import { usePageHeaderFilterContext } from 'src-new/hooks/use-page-header-filter-context/use-page-header-filter-context.hook';
import { PurchaseOfferContext } from 'src-new/pages/opportunities/pages/purchase-offer/contexts/purchase-offer/purchase-offer.context';
import { IPurchaseOfferListFilterDomain } from 'src-new/pages/opportunities/pages/purchase-offer/pages/purchase-offer-list/domains/purchase-offer-list-filters.domain';

export const useDownloadPurchaseOfferReport = () => {
  const { purchaseOfferListDownloadReport } = useContext(PurchaseOfferContext);
  const pageHeaderFilterContext = usePageHeaderFilterContext<IPurchaseOfferListFilterDomain>();
  const {
    setPurchaseOfferListDownloadReportRequest,
    setPurchaseOfferListDownloadReportSuccess,
    setPurchaseOfferListDownloadReportError,
  } = purchaseOfferListDownloadReport;
  const { filters } = pageHeaderFilterContext;

  return useCallback(async () => {
    setPurchaseOfferListDownloadReportRequest();
    try {
      const file: ArrayBuffer | undefined = await getOfferFileReportService(filters, 'purchase');

      if (file) {
        downloadXlsxFileUtil(file, 'relatório-ofertas-compra');
      }

      setPurchaseOfferListDownloadReportSuccess();
      showToast({
        message: 'Relatório gerado com sucesso.',
        type: 'success',
      });
    } catch (error) {
      setPurchaseOfferListDownloadReportError();
      showToast({
        message: 'Erro ao tentar exportar relatório.',
        type: 'error',
      });
    }
  }, [
    filters,
    setPurchaseOfferListDownloadReportError,
    setPurchaseOfferListDownloadReportRequest,
    setPurchaseOfferListDownloadReportSuccess,
  ]);
};
