import { SaleOfferListActionType } from '../../sale-offer.action';
import { ISaleOfferListActions } from '../../types/sale-offer-list.types';
import { ISaleOfferContext } from '../../types/sale-offer.types';

export const saleOfferListReducer = (state: ISaleOfferContext, action: ISaleOfferListActions): ISaleOfferContext => {
  switch (action.type) {
    case SaleOfferListActionType.SALE_OFFER_LIST_REQUEST:
      return {
        ...state,
        saleOfferList: {
          ...state.saleOfferList,
          statusRequest: 'PROCESSING',
        },
      };
    case SaleOfferListActionType.SALE_OFFER_LIST_SUCCESS:
      return {
        ...state,
        saleOfferList: {
          ...state.saleOfferList,
          statusRequest: 'SUCCESS',
          saleOfferList: action.payload,
        },
      };
    case SaleOfferListActionType.SALE_OFFER_LIST_ERROR:
      return {
        ...state,
        saleOfferList: {
          ...state.saleOfferList,
          statusRequest: 'ERROR',
          saleOfferList: [],
        },
      };
    case SaleOfferListActionType.SALE_OFFER_LIST_RESET:
      return {
        ...state,
        saleOfferList: {
          ...state.saleOfferList,
          statusRequest: 'INITIAL',
          saleOfferList: [],
        },
      };
    case SaleOfferListActionType.SALE_OFFER_SELECT_IDS:
      return {
        ...state,
        saleOfferList: {
          ...state.saleOfferList,
          selectedIds: action.payload,
        },
      };
    default:
      return state;
  }
};
