import { IVehicleEntityStatus } from 'pages/allocation/services/entities/vehicle.entity';

export const convertActiveTabToStatusDomainMapper = (statusTab: string): IVehicleEntityStatus | undefined => {
  switch (statusTab) {
    case 'Todos':
      return undefined;
    case 'Ativos':
      return 'active';
    case 'Inativos':
      return 'inactive';
    case 'Excluídos':
      return 'deleted';
  }
};
