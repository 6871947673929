import API from 'utils/API/API';
import { IScheduleCustomFields } from '../../domains/schedule-details.domain';
import { ICustomFieldsResponse } from './get-custom-fields.types';
import { converterCustomFieldsResponseToDomain } from './mappers/converter-custom-fields-response-to-domain.mapper';

export const getCustomFieldsService = async (): Promise<Array<IScheduleCustomFields>> => {
  const { data } = await API.get<Array<ICustomFieldsResponse>>('v3/custom-fields');

  return converterCustomFieldsResponseToDomain(data);
};
