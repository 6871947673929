import React, { FC } from 'react';
import * as S from './section-form-divider.styled';
import { ISectionFormDividerProps } from './section-form-divider.types';
import { PinOriginDestiny } from 'assets/icons/pin-origin-destiny';

const SectionFormDividerView: FC<ISectionFormDividerProps> = ({ label, secondItem, hidePinLocation }) => {
  return (
    <S.WrapperStyled>
      <S.TitleStyled>
        {hidePinLocation ? <S.TraceVerticalStyled /> : <PinOriginDestiny width={18} height={23} />}
        {label}
      </S.TitleStyled>
      {secondItem}
    </S.WrapperStyled>
  );
};
export default SectionFormDividerView;
