import API_TRAVELS_SVC from 'utils/API/API-TRAVELS-SVC';
import { IWeighingTottenCheckin } from '../../types/weighing-totten-checkin/weighing-totten-details-form.types';

export const putWeighingTottenCheckinService = async (params: IWeighingTottenCheckin): Promise<void> => {
  const { data } = await API_TRAVELS_SVC.put(`v1/weighing/${params.id}/checking`, {
    checkingDate: params.checkingDate,
  });

  return data.items;
};
