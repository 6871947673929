import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Table } from 'src-new/components/table/table.component';
import { ICell, IInfiniteScrollTable, IOrdering, IRow } from 'src-new/components/table/table.types';
import Checkbox from 'components/checkbox/checkbox';
import { Tag } from 'logshare-ui-kit';
import { ColorsV2Type } from 'styles/types/colors';
import { LabelStyled } from './client-table-view.styled';
import { IClientDomain } from 'src-new/pages/registrations/domains/client.domain';
import { convertStatusEntityToDomainMapper } from 'mapper/convert-status-entity-to-domain/convert-status-entity-to-domain.mapper';

interface IClientTableViewProps {
  clients: Array<IClientDomain>;
  infiniteScrollProps: IInfiniteScrollTable;
  handleIdsToRemove: Dispatch<SetStateAction<Array<number>>>;
  handleRowAction: (locationId: string) => void;
  ordering: IOrdering;
  isAdmin: boolean;
}

export const ClientTableView: React.FC<IClientTableViewProps> = ({
  clients,
  ordering,
  isAdmin,
  infiniteScrollProps,
  handleIdsToRemove,
  handleRowAction,
}: IClientTableViewProps) => {
  const renderLabel = useCallback((label: string) => {
    return <LabelStyled>{label?.toUpperCase() || '-'}</LabelStyled>;
  }, []);

  const tableColumns = useMemo((): { columnsConfig: string; headerColumns: Array<ICell> } => {
    const headerColumns = [
      { value: '' },
      { value: 'ID' },
      { value: isAdmin && 'Parceiro' },
      { value: 'Tipo' },
      { value: 'Nome' },
      { value: 'Cidade - UF' },
      { value: 'Status' },
    ];

    const columnsConfig = `0.2fr 0.4fr ${isAdmin ? '0.8fr' : '0fr'} 0.8fr 1.5fr 1.3fr 0.4fr`;

    return {
      columnsConfig,
      headerColumns,
    };
  }, [isAdmin]);

  const renderRows = useMemo((): Array<IRow> => {
    return clients.map((client): IRow => {
      return {
        key: `${client.id}`,
        onClick: () => handleRowAction(client.id?.toString() ?? ''),
        valuesColumns: [
          {
            value: (
              <Checkbox
                key={`checkbox-client-${client.id}`}
                id={'id'}
                name={'id'}
                value={String(client.id)}
                onChange={(e) =>
                  e.target.checked
                    ? handleIdsToRemove((prv) => [...prv, client.id ?? 0])
                    : handleIdsToRemove((prv) => [...prv.filter((id) => id !== client.id)])
                }
              />
            ),
            isClicable: false,
          },
          {
            value: client.id,
          },
          {
            value: renderLabel(isAdmin && client.company ? client.company?.tradeName : ''),
          },
          {
            value: renderLabel(client.type_of_partner ? client.type_of_partner.slice(5) : ' '),
          },
          {
            value: renderLabel(client.tradeName),
          },
          {
            value: renderLabel(client.address.city + ' - ' + client.address.uf),
          },
          {
            value: (
              <Tag
                label={convertStatusEntityToDomainMapper(client.status)}
                color={client.status === 'active' ? ColorsV2Type.Green : ColorsV2Type.Red}
                variant="solid"
                display="inline-flex"
                size="md"
              />
            ),
          },
        ],
      };
    });
  }, [handleIdsToRemove, handleRowAction, isAdmin, clients, renderLabel]);

  return (
    <Table
      gridColumns={tableColumns.columnsConfig}
      header={tableColumns.headerColumns}
      ordering={ordering}
      rows={renderRows}
      height={'77vh'}
      infiniteScrollProps={infiniteScrollProps}
    />
  );
};
