import { useNavigate } from 'react-router-dom';
import React, { ReactNode, useCallback, useState } from 'react';

import { FormView } from './form-view';

import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { initialValues } from 'pages/delivery-tracking/types/types';
import { useFilterDeliveryTracking } from 'pages/delivery-tracking/hooks/use-filter.hook';
import { FilterService } from 'domain/filter-delivery-tracking';
import ModalConfirmation from 'components/modal-confirmation/modal-confirmation';

export const Form: React.FC = () => {
  const [modalDetails] = useState<{
    open: boolean;
    description: ReactNode;
  }>({
    open: false,
    description: '',
  });
  const navigate = useNavigate();

  const filterDeliveryTracking = useFilterDeliveryTracking();

  const goToDetailsPage = useCallback(
    (id: string) => {
      navigate(`/rastreio/${id}`);
    },
    [navigate],
  );

  const deliveryTrackingFilter = useCallback(
    async (values: FilterService) => {
      await filterDeliveryTracking(values)
        .then(() => {
          showMessageFormatted({
            message: 'Agendamento encontrado.',
            type: 'success',
          });
          goToDetailsPage(values.schedulingCode);
        })
        .catch(() =>
          showMessageFormatted({
            message: 'Agendamento não encontrado.',
            type: 'error',
          }),
        );
    },
    [filterDeliveryTracking, goToDetailsPage],
  );

  return (
    <>
      {modalDetails.open && (
        <ModalConfirmation title="" description={modalDetails.description} size="large" type="normal" />
      )}
      <FormView filter={deliveryTrackingFilter} values={initialValues} />
    </>
  );
};
