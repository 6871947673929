import React, { Fragment, ReactNode, useMemo } from 'react';

import { ContainerPaginationStyled } from './company-table.styled';

import { PageInfo } from 'domain/page-info';
import { IContentTable } from 'components/table/table.types';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';

interface ICompaniesTableViewProps {
  headerTable: IContentTable;
  contentTable: Array<IContentTable>;
  pageInfo: PageInfo | null;
  changePage: (page: number) => void;
  clickRowAction: (companyId: string) => void;
  arrowOrder?: string | ReactNode;
  isLoading: boolean;

  clickOrderBy: (type: string) => void;
  clickSortDirection: () => void;
}

const CompaniesTableView: React.FC<ICompaniesTableViewProps> = ({
  headerTable,
  contentTable,
  pageInfo,
  changePage,
  clickRowAction,
  arrowOrder,
  isLoading,
  clickOrderBy,
  clickSortDirection,
}: ICompaniesTableViewProps) => {
  const renderPagination = useMemo(() => {
    if (pageInfo !== null) {
      return (
        <ContainerPaginationStyled>
          <Pagination activePage={pageInfo.currentPage} changePage={changePage} totalPage={pageInfo.totalPages} />
        </ContainerPaginationStyled>
      );
    }
  }, [changePage, pageInfo]);

  return (
    <Fragment>
      <Table
        header={headerTable}
        content={contentTable}
        isLoading={isLoading}
        clickRowAction={clickRowAction}
        arrowOrder={arrowOrder}
        clickOrderBy={clickOrderBy}
        clickSortDirection={clickSortDirection}
      />
      {renderPagination}
    </Fragment>
  );
};

export default CompaniesTableView;
