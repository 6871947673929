import { IProcess, ITrackingOccurrence, ITrackingOccurrenceStatus } from 'domain/tracking-occurrence';

export const PATH_TO_TRACKING_OCCURRENCE_LIST = '/tracking-ocorrencias';

export const initialValues: ITrackingOccurrence = {
  occurrenceTypeName: '',
  occurrenceDate: '',
  occurrenceTime: '',
  occurrenceTypeId: -1,
  processType: '',
  step: '',
  osNumber: '0',
  ticketNumber: '',
  statusResolution: 'nao_atendidas',
  clientId: 0,
  cnpj: '',
  endDate: '',
  resolutionTime: '',
  scheduleId: 0,
  companyId: 0,
  localityId: 0,
  occurrenceResolutions: [],
};

export const optionsProcess: Array<{ label: string; value: IProcess }> = [
  { label: 'INBOUND', value: 'inbound' },
  { label: 'OUTBOUND', value: 'outbound' },
  { label: 'BACKHAUL', value: 'backhaul' },
];

export const optionsStatus: Array<{
  label: string;
  value: ITrackingOccurrenceStatus;
}> = [
  { label: 'NÃO ATENDIDAS', value: 'nao_atendidas' },
  { label: 'EM TRATATIVA', value: 'em_tratativa' },
  { label: 'FINALIZADAS', value: 'finalizadas' },
];
