import React, { ReactElement, useMemo } from 'react';
import { ErrorState } from 'src-new/components/error-state/error-state.component';
import { Loading } from 'src-new/components/loading/loading.component';
import { ScheduleDetailsChatButton } from 'src-new/pages/execution/pages/schedule/pages/schedule-details/components/schedule-details-page-body/components/schedule-details-chat-button/schedule-details-chat-button.component';
import { ScheduleDetailsHeader } from '../schedule-details-header/schedule-details-header.component';
import { ScheduleDetailsResume } from '../schedule-details-resume/schedule-details-resume.component';
import { ScheduleDetailsTabs } from '../schedule-details-tabs/schedule-details-tabs.component';
import * as S from './schedule-details-page-body-view.styled';
import { ScheduleListModals } from 'src-new/pages/execution/pages/schedule/contexts/schedule/types/schedule-list.types';
import { Formik } from 'formik';
import { IDenyAllocation } from '../../../schedule-list/components/schedule-page-body/schedule-page-body.types';
import { ScheduleDenyModal } from '../../../schedule-list/components/schedule-page-body/components/schedule-deny-modal/schedule-deny-modal.component';

interface IScheduleDetailsPageBodyViewProps {
  isLoading: boolean;
  isError: boolean;
  modalOptions: ScheduleListModals;
  isOpenConversationListModal: boolean;
  handleTripDeny: (params: IDenyAllocation) => void;
  handleModalClose: () => void;
}

export const ScheduleDetailsPageBodyView: React.FC<IScheduleDetailsPageBodyViewProps> = ({
  isLoading,
  isError,
  modalOptions,
  isOpenConversationListModal,
  handleTripDeny,
  handleModalClose,
}) => {
  const renderChatButton = useMemo((): ReactElement | undefined => {
    if (!isOpenConversationListModal) {
      return <ScheduleDetailsChatButton />;
    }
  }, [isOpenConversationListModal]);

  const renderModals = useMemo((): ReactElement | undefined => {
    if (modalOptions.open) {
      if (modalOptions.modalType === 'deny') {
        return (
          <Formik initialValues={{ scheduleIds: modalOptions.ids, refusalReason: '' }} onSubmit={handleTripDeny}>
            {({ handleSubmit }) => {
              return <ScheduleDenyModal closeModal={handleModalClose} handleConfirm={handleSubmit} />;
            }}
          </Formik>
        );
      }
    }
  }, [handleModalClose, handleTripDeny, modalOptions.ids, modalOptions.modalType, modalOptions.open]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <S.Wrapper>
      <ScheduleDetailsHeader />
      <S.WrapperBody>
        <S.WrapperContent>
          <ScheduleDetailsTabs />
        </S.WrapperContent>
        <ScheduleDetailsResume />
      </S.WrapperBody>
      {renderModals}
      {renderChatButton}
    </S.Wrapper>
  );
};
