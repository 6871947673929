import { useState, useEffect, ReactNode, useCallback, FC } from 'react';

import { ITabContent } from './tab.types';
import { TabView } from './tab-view.component';

interface ITabProps {
  tabsContent: Array<ITabContent>;
  tabActive?: string;
  icon?: ReactNode;
  setActiveTabName: (name: string) => void;
}

export const Tab: FC<ITabProps> = ({ tabsContent, tabActive, icon, setActiveTabName }) => {
  const [activeTab, setActiveTab] = useState<ITabContent>(tabsContent[0]);

  useEffect(() => {
    if (tabActive) {
      const index = tabsContent.findIndex((item) => item.name.toUpperCase() === tabActive.toUpperCase());
      setActiveTab(tabsContent[index]);
    }
  }, [tabActive, tabsContent]);

  const handleActiveTab = useCallback(
    (tab: ITabContent) => {
      setActiveTab(tab);
      setActiveTabName(tab.name);
    },
    [setActiveTabName],
  );

  return <TabView tabsContent={tabsContent} activeTab={activeTab} icon={icon} handleActiveTab={handleActiveTab} />;
};
