import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { IListPage } from 'src-new/domains/list-page.domain';
import { SaleOfferContext } from 'src-new/pages/opportunities/pages/sale-offer/contexts/sale-offer/sale-offer.context';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { getSaleOfferMatchListService } from '../../services/get-sale-offer-match-list/get-sale-offer-match-list.service';
import { ISaleOfferMatchListResponse } from '../../services/get-sale-offer-match-list/get-sale-offer-match-list.types';
import { convertSaleOfferMatchListResponseToDomain } from './mappers/convert-sale-offer-match-list-response-to-domain/convert-sale-offer-match-list-reponse-to-domain.mapper';

export const useGetSaleOfferMatchList = () => {
  const { saleOfferMatchList } = useContext(SaleOfferContext);
  const {
    saleOfferMatchProposal,
    setSaleOfferMatchListSuccess,
    setSaleOfferMatchListRequest,
    setSaleOfferMatchListError,
  } = saleOfferMatchList;
  const { proposal } = saleOfferMatchProposal;

  const { data } = useQuery<IListPage<ISaleOfferMatchListResponse>>({
    queryKey: [
      'sale-offer-match-list-table',
      saleOfferMatchList.filters.selectedFilter,
      proposal.id,
      proposal.idTariff,
    ],
    queryFn: () => {
      setSaleOfferMatchListRequest();
      return getSaleOfferMatchListService(proposal.id, proposal.idTariff, saleOfferMatchList.filters.selectedFilter);
    },
    onSuccess: (data: IListPage<ISaleOfferMatchListResponse>) => {
      const values = data.items.map((item) => convertSaleOfferMatchListResponseToDomain(item));
      setSaleOfferMatchListSuccess(values);
    },
    onError: (error) => {
      setSaleOfferMatchListError();
      showMessageFormatted({
        error: error as any,
        type: 'error',
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 100000,
  });

  return useCallback(() => {
    if (data) {
      setSaleOfferMatchListSuccess(data.items.map((item) => convertSaleOfferMatchListResponseToDomain(item)));
    }
  }, [data, setSaleOfferMatchListSuccess]);
};
