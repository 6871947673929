import moment from 'moment';

import {
  IBackhaul,
  IBackhaulStatus,
  IFleetType,
  ILoads,
  IOfferTo,
  ITypeSchedule,
  IVehicleActuators,
} from 'domain/backhaul';

export const PATH_TO_BACKHAUL_LIST = '/ofertas-venda';

const initialDate = moment();

export const initialValues: IBackhaul = {
  secondVehicleCategory: [],
  offerTo: '',
  fleetType: '',
  vehicleType: '',
  vehicleCategory: '',
  offeredPallet: 0,
  offeredCubage: 0,
  offeredWeight: 0,
  allowedLoads: [''],
  freightValue: '0',
  helperValue: '0,00',
  tollAxis: '0,00',
  grisTax: '',
  advTax: '',
  hasICMS: 'false',
  hasISS: 'false',
  hasPISCOFINS: 'false',
  freightTotal: '',
  logshareRevenue: '',
  carrierRevenue: '0',
  companyRevenue: '',
  initialDate: initialDate.format('DD/MM/YYYY'),
  finalDate: moment(initialDate).add(1, 'years').format('DD/MM/YYYY'),
  conductBy: 'LogShare',
  feeLogshare: '',
  feeCarrier: '0',
  feeCompany: '',
  originType: '',
  destinationType: '',
  idOrigin: undefined,
  idDestination: undefined,
  companyId: '',
  status: 'ativo',
  operationType: '',
  companysId: undefined,
  typeSchedule: '',
  frequency: 1,
  shippingCompanyTransfer: '',
};

export const partnerInitialValues = {
  partnerCompany: 0,
  partnerFreightValue: '0,00',
  partnerCarrierRevenue: '0,00',
  partnerCompanyRevenue: '0,00',
  partnerLogshareRevenue: '0,00',
  partnerFreightTotal: '0,00',
  partnerStatus: 'ativo' as IBackhaulStatus,
};

export const simpleResponseOptions: Array<{ label: string; value: string }> = [
  { label: 'SIM', value: 'true' },
  { label: 'NÃO', value: 'false' },
];

export const optionsLoads: Array<{ value: ILoads; label: string }> = [
  { value: 'Alimentos Naturais', label: 'ALIMENTOS NATURAIS' },
  { value: 'Carga Viva', label: 'CARGA VIVA' },
  { value: 'Cigarros', label: 'CIGARROS' },
  { value: 'Eletrônicos', label: 'ELETRÔNICOS' },
  { value: 'Fertilizantes', label: 'FERTILIZANTES' },
  { value: 'Químicos', label: 'QUÍMICOS' },
  { value: 'Vestuário', label: 'VESTUÁRIO' },
  { value: 'Outros', label: 'OUTROS' },
];

export const optionsVehicleActuators: Array<{
  label: string;
  value: IVehicleActuators;
}> = [
  { label: 'TECLADO INTERNO', value: 'Teclado Interno' },
  { label: 'BLOQUEADOR DE COMBUSTÍVEL', value: 'Bloqueador de Combustível' },
  { label: 'TRAVA DE 5º', value: 'Trava de 5º' },
  { label: 'TRAVA DE BAÚ', value: 'Trava de Baú' },
  { label: 'SENSOR DE PORTA DA CABINA', value: 'Sensor de Porta da Cabina' },
  { label: 'SENSOR DE BAÚ', value: 'Sensor de Baú' },
  { label: 'SENSOR DE DESENGATE', value: 'Sensor de Desengate' },
  { label: 'SENSOR DE VELOCIDADE', value: 'Sensor de Velocidade' },
  { label: 'SENSOR DE GRADE DE JANELA', value: 'Sensor de Grade de Janela' },
  {
    label: 'SENSOR DE VIOLAÇÃO DE PAINEL',
    value: 'Sensor de Violação de Painel',
  },
  { label: 'SENSOR DE TEMPERATURA', value: 'Sensor de Temperatura' },
];

export const optionsOfferTo: Array<{
  label: string;
  value: IOfferTo;
}> = [
  { label: 'BACKHAUL INTERNO', value: 'Malha Interna' },
  { label: 'BACKHAUL EXTERNO', value: 'Malha Externa' },
  { label: 'AMBOS', value: 'Ambas Malhas' },
];

export const optionsFleetType: Array<{
  label: string;
  value: IFleetType;
}> = [
  { label: 'FROTA DEDICADA', value: 'Frota Própria' },
  { label: 'FROTA TERCEIRIZADA', value: 'Frota Terceirizada' },
];

export const optionsTypeSchedule: Array<{
  label: string;
  value: ITypeSchedule;
}> = [
  { label: 'FTL', value: 'FTL' },
  { label: 'STL', value: 'STL' },
];
