import styled from 'styled-components';
import { Form } from 'formik';

export const WrapperForm = styled(Form)``;

export const WrapperInput = styled.div`
  margin-top: -2px;
`;

export const WrapperInputDate = styled.div`
  margin-top: 2px;
`;

export const WrapperDriverAndVehicleSection = styled.section`
  display: grid;
  width: 100%;
  gap: 1rem;
`;
