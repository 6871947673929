import styled from 'styled-components';

export const LoadingStyled = styled.div`
  width: 100px;
  margin: 7% 0 0 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
`;
