import API from 'utils/API/API';

export const updateMDFeDataService = async (scheduleId: number, files: FileList) => {
  const formData = new FormData();
  formData.append('type', 'MDFe');
  formData.append('module', 'schedule');
  formData.append('codigo', String(scheduleId));
  formData.append('file', files[0]);

  await API.post('v2/file/management/upload', formData);
};
