import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { withFormik } from 'formik';
import { RegisterShippingCompanyValidation } from './validation/schemas-validation';
import { InnerForm } from './inner-form';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';
import { UserContext } from 'state/user-context';
import { extractLoginAfterAt } from 'pages-v2/shipping-company/utils/extract-login';
import { updateShippingCompanyService } from 'pages-v2/shipping-company/service/update';
import { registerShippingCompanyService } from 'pages-v2/shipping-company/service/register';
import { getShippingCompanyByIdService } from 'pages-v2/shipping-company/service/get-shipping-company-by-id';
import { Loading } from 'src-new/components/loading/loading.component';
import { TOAST_MESSAGE } from 'src-new/constants/toast/toast.constants';
import { IShippingCompanyDomain } from 'src-new/pages/registrations/domains/shipping-company';
import { useSaveUploadFile } from 'src-new/pages/registrations/hooks/use-save-upload-file/use-save-upload-file.hook';
import { useManageAttachmentsEdit } from 'src-new/pages/registrations/hooks/use-manage-attachments-edit/use-manage-attachments-edit.hook';
import { initialValuesCompany } from './initial-values';

export const FormShippingCompany: React.FC = () => {
  const manageAttachmentsEdit = useManageAttachmentsEdit();
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const saveUploadFile = useSaveUploadFile();
  const navigate = useNavigate();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['shippingCompanyGet', id],
    queryFn: () => (id ? getShippingCompanyByIdService(Number(id)) : undefined),
    enabled: false,
  });

  const login = user?.login?.includes('@') ? '@' + extractLoginAfterAt(user?.login) : '@' + user?.login;

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  const handleSubmit = useCallback(
    async (shippingCompany: IShippingCompanyDomain) => {
      try {
        if (!id) {
          const id = await registerShippingCompanyService(shippingCompany);

          await saveUploadFile(id, shippingCompany.attachments, 'shipping-company');
        } else {
          await manageAttachmentsEdit(
            Number(id),
            'shipping-company',
            shippingCompany.originalAttachments,
            shippingCompany.attachments,
          );
          await updateShippingCompanyService(Number(id), shippingCompany);
        }

        showMessageFormatted({
          message: `${id ? TOAST_MESSAGE.UPDATE : TOAST_MESSAGE.REGISTER}`,
          type: 'success',
        });
        navigate(-1);
      } catch (error: any) {
        showMessageFormatted({
          error,
          type: 'error',
        });
      }
    },
    [id, manageAttachmentsEdit, navigate, saveUploadFile],
  );

  const getValues = useMemo((): IShippingCompanyDomain => {
    if (id && data) {
      return {
        ...data,
        vehiclesServiced: data.vehiclesServiced ? data.vehiclesServiced : initialValuesCompany.vehiclesServiced,
      };
    }
    return initialValuesCompany;
  }, [data, id]);

  const FormRegisterTransportView = withFormik<IShippingCompanyDomain, IShippingCompanyDomain>({
    validationSchema: RegisterShippingCompanyValidation,
    mapPropsToValues: (props) => ({ ...props }),
    handleSubmit: (values) => {
      handleSubmit({
        ...values,
        userName: values.userName + login,
      });
    },
  })(InnerForm);

  if (isLoading) {
    return <Loading />;
  }

  return <FormRegisterTransportView {...getValues} />;
};
