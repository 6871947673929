import { IVehicleType } from 'domain/vehicle';
import { ILocation, LocationType } from 'domain/location';
import { ILocationDomain } from 'src-new/pages/registrations/domains/location.domain';

export const PATH_TO_LOCATION_LIST = '/unidades';

export const tollVoucherType: Array<{ label: string; value: boolean }> = [
  { label: 'SIM', value: true },
  { label: 'NÃO', value: false },
];

export const optionsType: Array<{ label: string; value: LocationType }> = [
  { label: 'ARMAZÉM', value: 'ARMAZÉM' },
  { label: 'CENTRO DE DISTRIBUIÇÃO', value: 'CD' },
  { label: 'FÁBRICA', value: 'FÁBRICA' },
  { label: 'FAZENDA', value: 'FAZENDA' },
  { label: 'HUB', value: 'HUB' },
  { label: 'LOJA', value: 'LOJA' },
  { label: 'TERMINAL PORTUÁRIO', value: 'TERMINAL PORTUÁRIO' },
  { label: 'TRANSIT POINT', value: 'TRANSIT POINT' },
  { label: 'USINA', value: 'USINA' },
  { label: 'OUTROS', value: 'OUTROS' },
];

export const optionsVehicleType: Array<{ label: string; value: IVehicleType }> = [
  { label: 'UTILITÁRIO', value: 'utilitario' },
  { label: 'VAN', value: 'van' },
  { label: 'VUC', value: 'vuc' },
  { label: '3/4', value: '3/4' },
  { label: 'TOCO', value: 'toco' },
  { label: 'TRUCK', value: 'truck' },
  { label: 'BITRUCK', value: 'bitruck' },
  { label: 'CARRETA', value: 'carreta' },
];

export const initialValues: ILocation = {
  name: '',
  cnpj: '',
  status: 'active',
  type: '',
  address: {
    cep: '',
    city: '',
    complement: '',
    neighborhood: '',
    numberHouse: '',
    street: '',
    uf: '',
  },
  vehicleRestriction: [],
  vehicleCategoryType: [],
  clientIds: [],
  schedulingEmail: [],
  occurrenceEmail: [],
  operationType: [],
  quality_and_safety: '',
  lat: '',
  lng: '',
  internalCode: '',
  inbound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
  },
  oubound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
  },
};

export const initialValuesLocation: ILocationDomain = {
  name: '',
  cnpj: '',
  status: 'active',
  type: '',
  address: {
    cep: '',
    city: '',
    complement: '',
    neighborhood: '',
    numberHouse: '',
    street: '',
    uf: '',
  },
  vehicleRestriction: [],
  vehicleCategoryType: [],
  clientIds: [],
  schedulingEmail: [],
  occurrenceEmail: [],
  operationType: [],
  quality_and_safety: '',
  lat: '',
  lng: '',
  internalCode: '',
  inbound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: true,
    },
  },
  contacts: [],
  attachments: [],
  outbound: {
    dom: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    seg: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    ter: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    qua: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    qui: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    sex: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
    sab: {
      firstTime: '',
      secondTime: '',
      able: false,
    },
  },
  originalAttachments: [],
};
