import { useCallback, useContext } from 'react';
import { ScheduleContext } from 'src-new/pages/execution/pages/schedule/contexts/schedule/schedule.context';
import { getOthersDocumentsDataService } from '../../services/get-others-documents-data/get-others-documents-data.service';
import { showMessageFormatted } from 'utils/message/show-message-formatted/show-message-formatted';

export const useGetOthersDocumentsData = () => {
  const { scheduleDocuments, scheduleDetails } = useContext(ScheduleContext);

  return useCallback(async () => {
    scheduleDocuments.othersDocumentsProps.setOthersDocumentsRequest();

    try {
      const data = await getOthersDocumentsDataService(scheduleDetails.scheduleDetails.id);

      scheduleDocuments.othersDocumentsProps.setOthersDocumentsSuccess(data);
    } catch (error: any) {
      scheduleDocuments.othersDocumentsProps.setOthersDocumentsError();

      showMessageFormatted({
        error: error,
        type: 'error',
      });
    }
  }, [scheduleDetails.scheduleDetails.id, scheduleDocuments.othersDocumentsProps]);
};
