import styled, { css } from 'styled-components';

interface IStyledProps {
  height?: string;
}

export const ContainerStyled = styled.div<IStyledProps>`
  display: flex;
  justify-content: center;
  height: ${({ height }) => (height && height !== '' ? height : '100%')};
`;

export const ContainerEmptyStateTableStyled = styled.div<IStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: ${({ height }) => (height && height !== '' ? '0%' : '7%')};
`;

export const TextContainerStyled = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #7f7f7f;
`;

export const SubTextContainerStyled = styled.p`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: rgb(111, 111, 116);
`;

export const IconStyled = styled.img<{ src: string }>`
  ${({ src }) => css`
    src: ${src};
    width: 30px;
    height: 30px;
  `}
`;
