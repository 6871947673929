import React, { ReactElement, useMemo } from 'react';
import { ModalBark } from 'src-new/components/modal-bark/modal-bark.component';
import { ImportModalTable } from './components/import-modal-table/import-modal-table.component';
import * as S from './schedule-import-modal-view.styled';
import { Button } from 'logshare-ui-kit';
import { ThemeColorsV2Type } from 'styles/types/colors';
import { InputDropFile } from 'src-new/components/input-drop-file/input-drop-file.component';
import {
  IDisabledButtons,
  IFilesStatus,
} from 'src-new/pages/execution/pages/schedule/types/schedule-import-modal/schedule-import-modal.types';

interface IScheduleImportModalViewProps {
  filesStatus: IFilesStatus;
  disableButtons: IDisabledButtons;
  isLoading: boolean;
  handleModalClose: () => void;
  handleUploadFile: () => void;
  handleTemplateFile: () => void;
  handleSelectedFile: (files: FileList) => void;
  handleDownloadSelectedFile: (processingCode: number) => void;
}

export const ScheduleImportModalView: React.FC<IScheduleImportModalViewProps> = ({
  filesStatus,
  disableButtons,
  isLoading,
  handleModalClose,
  handleUploadFile,
  handleTemplateFile,
  handleSelectedFile,
  handleDownloadSelectedFile,
}) => {
  const renderFilesStatus = useMemo((): ReactElement | undefined => {
    if (filesStatus.total > 0) {
      return (
        <S.FilesStatusContent>
          <S.FilesStatusText>{filesStatus.total} RECEBIDOS |</S.FilesStatusText>
          <S.FilesStatusText color={ThemeColorsV2Type.Green}>{filesStatus.approved} APROVADOS</S.FilesStatusText>
          <S.FilesStatusText>|</S.FilesStatusText>
          <S.FilesStatusText color={ThemeColorsV2Type.Red}>{filesStatus.refused} REPROVADOS</S.FilesStatusText>
        </S.FilesStatusContent>
      );
    }
  }, [filesStatus]);

  return (
    <ModalBark handleClose={handleModalClose} size="md" title="Upload de Programação">
      <S.Wrapper>
        <S.InputContent>
          <InputDropFile
            acceptFiles={['.xlsx', '.xls']}
            disabled={isLoading}
            handleSelectedFile={handleSelectedFile}
            handleTemplateDownload={handleTemplateFile}
          />
        </S.InputContent>

        <S.TableContent>
          <ImportModalTable />

          {renderFilesStatus}

          <S.ButtonContainer>
            <Button
              label="Fazer Download"
              color={ThemeColorsV2Type.Aqua}
              size="md"
              variant="solid"
              onPress={handleDownloadSelectedFile}
              disabled={disableButtons.downloadButton}
            />
            <Button
              label="Fazer Upload"
              color={ThemeColorsV2Type.Blue}
              size="md"
              variant="solid"
              onPress={handleUploadFile}
              disabled={disableButtons.uploadButton}
            />
          </S.ButtonContainer>
        </S.TableContent>
      </S.Wrapper>
    </ModalBark>
  );
};
